import React from 'react';
import { Button } from 'primereact/button';

const AffiliationPartner = () => {
    const handleSubmit = (event) => {
        event.preventDefault();
        document.getElementById("hiddenForm").submit();
    };

    return (
        <div>
            {/* Navbar */}
            <nav style={styles.navbar}>
                <div style={styles.logo}>MyCompany</div>
                <ul style={styles.navLinks}>
                    <li><a href="#home" style={styles.navLink}>Home</a></li>
                    <li><a href="#services" style={styles.navLink}>Services</a></li>
                    <li><a href="#about" style={styles.navLink}>About</a></li>
                    <li><a href="#contact" style={styles.navLink}>Contact</a></li>
                </ul>
            </nav>

            {/* Hero Section */}
            <section style={{ padding: '50px', textAlign: 'center', backgroundColor: '#f4f4f4' }}>
                <h1>Welcome to Our Service</h1>
                <p>Your satisfaction is our priority.</p>
                <button onClick={handleSubmit} style={{ padding: '10px 20px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                    Get Quote
                </button>
            </section>

            {/* Hidden Form */}
            <form id="hiddenForm" action="http://localhost:3000/signup" method="GET" style={{ display: 'none' }}>
                <input type="hidden" name="vendorId" value="3" />
            </form>
        </div>
    );
};

// Inline styles for the component
const styles = {
    navbar: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 20px',
        backgroundColor: '#333',
        color: '#fff',
    },
    logo: {
        fontSize: '24px',
        fontWeight: 'bold',
    },
    navLinks: {
        listStyle: 'none',
        display: 'flex',
        gap: '15px',
        margin: 0,
    },
    navLink: {
        color: '#fff',
        textDecoration: 'none',
        fontSize: '18px',
    },
    heroSection: {
        height: '100vh',
        backgroundImage: 'url("https://via.placeholder.com/1500x800")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
    },
    heroContent: {
        textAlign: 'center',
        maxWidth: '600px',
    },
    heroTitle: {
        fontSize: '48px',
        marginBottom: '20px',
    },
    heroSubtitle: {
        fontSize: '24px',
        marginBottom: '30px',
    },
    getQuoteButton: {
        fontSize: '20px',
    },
};

export default AffiliationPartner;
