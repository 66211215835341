import React, { useRef } from "react";
import RegisteredBanner from "../RegistredAgentService/RegisteredBanner.js";
import SubscriptionSec from "../AllServices/SubscriptionPage/SubscriptionSec.js";
import SubscriptionThird from "../AllServices/SubscriptionPage/SubscriptionThird.js";
import OurTrustedSection from "../CommonSection/OurTrustedSection.js";
import bannerimg from "../../../Assets/Images/subscriptionbanner.png";
import CommonRegister from "../CommonSection/CommonRegister.js";
import SubscriptionFaq from "../AllServices/SubscriptionPage/SubscriptionFaq.js";
import { Container } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle.js";
import ServiceForm from "../CommonSection/ServiceForm.js";

const SubscriptionServices = () => {
  const registeredTabRef = useRef(null);

  const handleScroll = () => {
    if (registeredTabRef.current) {
      registeredTabRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <RegisteredBanner
        title={"Stay current with"}
        highlightText={"subscription-based services"}
        subtitle={
          "Keep your business compliant with ongoing service subscriptions."
        }
        buttonLabel={"Learn More"}
        onButtonClick={handleScroll}
        bannerImage={bannerimg}
      />

      {/* <OurTrustedSection /> */}
      <SubscriptionSec />
      <div ref={registeredTabRef} id="registered-tab-section">
        <SubscriptionThird />
      </div>
      <div className="entity-formation">
        <Container>
          <div className="text-center mt-5 mb-5">
            <CommonHeadingTitle
              heading={"Interested in"}
              blueheading={"subscription services?"}
            />
          </div>
          <ServiceForm />
        </Container>
      </div>
      <CommonRegister
        title={"Stay compliant with hassle-free subscription services"}
        buttonLabel={"Start Your Business"}
        buttonLink={"/signup"}
      />
      <SubscriptionFaq />
    </div>
  );
};

export default SubscriptionServices;
