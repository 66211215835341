import React, { useEffect } from "react";
import "../CommonSection/CommonStyle/CommonStyle.css";
import { Col, Container, Row } from "react-bootstrap";
import BlueButton from "../CommonSection/BlueButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import bannerimg from "../../../../src/Assets/Images/Images/homebannerimg.png";
import user from "../../../../src/Assets/Images/Images/user.png";
import AOS from "aos";
import "aos/dist/aos.css";
import ProfileModalComponent from "../../ProfileModalComponent/ProfileModalComponent";

const AboutUsBanner = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [modalShow, setModalShow] = React.useState(false);
  return (
    <section className="main-section-class-home">
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div data-aos="fade-left" data-aos-duration="3000">
              <div className="mt-5">
                <h3 className="home-banner-heading">
                  <span className="big-blue-text">Incorporate</span> <br /> your
                  businesses effortlessly!
                </h3>
                <p className="home-banner-sub-tile mt-3">
                  We provide comprehensive business formation services to
                  confidently launch your new venture. Our experts assist you
                  through every step, across all 50 states.
                </p>
                <div className="mt-5">
                  <div className="main-class-blue">
                    <a href="/#/signup" target="_blank">
                      <button className="blue-btnnn">
                        <span>
                          Start Your Business{" "}
                          <FontAwesomeIcon icon={faArrowRightLong} />
                        </span>
                      </button>
                    </a>
                  </div>
                  {/* <div className="main-class-blue mt-3">
                    <button
                      className="blue-btnnn"
                      onClick={() => setModalShow(true)}
                    >
                      <span>
                        Profile Modal
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </span>
                    </button>
                    <ProfileModalComponent
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div> */}
                  {/* <BlueButton
                    label="Start Your Business"
                    icon={<FontAwesomeIcon icon={faArrowRightLong} />}
                    onclick={"/signup"}
                  /> */}
                </div>
                <div className="d-flex mt-5">
                  <img src={user} className="user-img mt-2" />
                  <p className="home-banner-sub-tile-subscriber ms-3 mt-3">
                    10k+ Satisfied Customers
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div data-aos="fade-right" data-aos-duration="3000">
              <div className="main-img-class">
                <img src={bannerimg} className="banner-img" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUsBanner;
