import { Accordion, AccordionTab } from 'primereact/accordion';
import { Avatar } from 'primereact/avatar';
import React, { useEffect, useState } from 'react'
import { formatISODate } from './dateUtils';
import AddressService from '../services/addressService';

const BeneficilaCard = ({data,type}) => {
    const [allState, setAllState] = useState([])

    console.log(data,"beneficial_data")

    const getInitials = (name) => {
        const nameArray = name.trim().split(" ");
        if (nameArray.length >= 2) {
            const firstNameInitial = nameArray[0].charAt(0).toUpperCase();
            const lastNameInitial = nameArray[1].charAt(0).toUpperCase();
            return firstNameInitial + lastNameInitial;
        } else {

            const initial = nameArray[0].charAt(0).toUpperCase();
            return initial + initial;
        }
    };
    useEffect(() => {
        AddressService.getAllStates()
            .then((res) => {
                console.log(res)
                if (res.data !== "") {
                    setAllState(res.data)
                }

            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const getStateBySortName = (param) => {

        const temp = allState.find((val) => val.stateShortName === param)
        if (temp !== undefined) {
            return temp.stateFullDesc;
        }
        else {
            return null
        }
    }
    const getState = (id) => {
        const temp = allState.find((val) => val.id === id)
        if (temp !== undefined) {
            return temp.stateFullDesc;
        }
        else {
            return null
        }
    }
    const val = data;
    console.log(val,"owner_info_data")
    return (
        <div>
            {val && 
                    <div className="col-12" >
                        <div className="order-contact-card-container">
                            <div className="order-contact-card-main">
                                <div className="order-contact-card-header-main">
                                    <Avatar label={getInitials(val?.firstName + "" + val?.lastName)} icon="pi pi-user" size="large" shape="circle" className="custom-avatar" />
                                    <div>
                                        <div className="order-contact-card-header-text1">
                                            {val?.firstName + "" + val?.lastName}
                                        </div>
                                        <div className="order-contact-card-header-text2">
                                            {type}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-devider"> </div>
                                <div className="order-contact-card-text-main">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path
                                                d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                stroke={val?.contactNumber ? "#175CD3" : "#98A2B3"}
                                                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div className="order-contact-card-text1">
                                        {val?.contactNumber || "Add Contact Number here"}
                                    </div>
                                </div>

                                <div className="order-contact-card-text-main">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                stroke={val?.email ? "#175CD3" : "#98A2B3"}
                                                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div className="order-contact-card-text1">
                                        {val?.email || "Add Email here"}
                                    </div>
                                </div>

                                <div className="order-contact-card-text-main">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                stroke={val?.residentialAddress || val?.city || val?.state || val?.zipCode ? "#175CD3" : "#98A2B3"}
                                                strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"
                                            />
                                            <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                stroke={val?.residentialAddress || val?.city || val?.state || val?.zipCode ? "#175CD3" : "#98A2B3"}
                                                strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"
                                            />
                                        </svg>
                                    </div>
                                    <div className="order-contact-card-text1 text-wrap">
                                        {[
                                            val?.residentialAddress,
                                            val?.city,
                                            getState(val?.state),
                                            val?.zipCode
                                        ].filter(Boolean).join(", ") || "Add Address here"}
                                    </div>
                                </div>

                                <div className="grid w-[100%] p-4 mb-2">
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        <div className="order-preview-key">
                                            Exempt Entity
                                        </div>
                                        <div className="order-preview-value">
                                            {val?.exemptEntity ? "Yes" : "No"}
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        <div className="order-preview-key">

                                            Have FinCEN ID
                                        </div>
                                        <div className="order-preview-value">
                                            {val?.haveFinCenId ? "Yes" : "No"}
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        <div className="order-preview-key">
                                            FinCEN ID Number
                                        </div>
                                        <div className="order-preview-value">
                                            {val?.finCenIdNumber}
                                        </div>
                                    </div>

                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        <div className="order-preview-key">
                                            Date Of Birth
                                        </div>
                                        <div className="order-preview-value">
                                            {val?.dateOfBirth ? formatISODate(val?.dateOfBirth) : ""}
                                        </div>
                                    </div>

                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        <div className="order-preview-key">
                                            Identify Document Number
                                        </div>
                                        <div className="order-preview-value">
                                            {val?.identifyingDocumentNo}
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        <div className="order-preview-key">
                                            Issuing Jurisdiction Country
                                        </div>
                                        <div className="order-preview-value">
                                            {val?.issuingJurisdictionCountry}
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        <div className="order-preview-key">
                                            Issuing Jurisdiction State
                                        </div>
                                        <div className="order-preview-value">
                                            {getStateBySortName(val?.issuingJurisdictionState)}
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        <div className="order-preview-key">
                                            Last Name Or Business Legal Name
                                        </div>
                                        <div className="order-preview-value">
                                            {val?.lastNameOrBusinessLegalName}
                                        </div>
                                    </div>
                                    <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        <div className="order-preview-key">
                                            Document Type
                                        </div>
                                        <div className="order-preview-value">
                                            {val?.documentType}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
             
            }
        </div>
    )
}

export default BeneficilaCard