import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BsExclamationTriangle } from 'react-icons/bs';
import './ConfirmPaymentModal.css';

const ConfirmPaymentModal = ({ show, onHide, onConfirm }) => {
    return (
        <Modal 
            show={show} 
            onHide={onHide} 
            centered 
            className="confirm-payment-modal"
            size="md"
        >
            <Modal.Body>
                <div className="text-center">
                    <div className="warning-icon-wrapper">
                        <BsExclamationTriangle className="warning-icon" />
                    </div>
                    <h5 className="confirm-title font-fam-for-all">Confirm payment</h5>
                    <p className="confirm-text font-fam-for-all">Are you sure you want to complete the payment?</p>
                    
                    <div className="d-flex justify-content-center gap-2 mt-4">
                        <Button 
                            variant="light" 
                            onClick={onHide}
                            className="px-4 font-fam-for-all"
                        >
                            Cancel
                        </Button>
                        <Button 
                        className='font-fam-for-all'
                            variant="primary" 
                            onClick={() => {
                                onConfirm();
                                onHide();
                            }}
                        >
                            Confirm
                        </Button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ConfirmPaymentModal; 