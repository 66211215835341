import React, { useEffect } from "react";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import "../CommonSection/CommonStyle/CommonStyle.css";
import AOS from "aos";
import "aos/dist/aos.css";

const RegisteredTab = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className="main-section-class" data-aos="fade-up"
    data-aos-duration="3000">
      <div className="register-tab-main">
        <Container>
          <Row className="flex-row justify-content-center">
            <Col xl={8} lg={8} md={12} sm={12} xs={12}>
              <CommonHeadingTitle blueheading={"Registered agent services"} />
              <div className="text-center">
                <p className="regiter-subtitle mb-5">
                  At vState Filings, we provide comprehensive registered agent,
                  independent director, and entity monitoring services to ensure
                  your business stays compliant and secure.
                </p>
              </div>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="first"
                className="mt-5"
              >
                <Nav
                  variant="pills"
                  className="flex-row justify-content-center"
                >
                  <Nav.Item>
                    <Nav.Link eventKey="first">Registered Agent</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Independent Director</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Entity Monitoring</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="mt-5">
                  <Tab.Pane eventKey="first">
                    <div className="grey-card-main">
                      <h4 className="card-titleee">Registered agent</h4>
                      <p className="card-textt">
                        A Registered Agent is a party, such as vState Filings,
                        designated to receive service of process notices within
                        the state in which you are doing business or have formed
                        an entity. Without a Registered Agent a business risks
                        falling out of “good standing” with the state in which
                        it is registered.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="grey-card-main">
                      <h4 className="card-titleee">Independent director</h4>
                      <p className="card-textt">
                        Many lenders require independent personnel to serve on a
                        board for larger real estate transactions to protect its
                        assets. The responsibility of the Independent Director
                        are formalized by the organization of the entity as well
                        as a separate agreement with the Independent Director.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div className="grey-card-main">
                      <h4 className="card-titleee">Entity monitoring</h4>
                      <p className="card-textt">
                        We monitor the entity to make sure they are up to date
                        on all mandates the state requires. Some examples are
                        annual reports, DBA renewal, payment of franchise and
                        sales tax renewal. Resignations, and service for
                        process.
                      </p>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default RegisteredTab;
