import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import { Button } from "primereact/button";

import { useRef } from "react";

function MaineLLC({ orderData  }) {
  const componentRef = useRef();

  if (!orderData) {
    return <div>No order data available.</div>;
  }


  const strapiOrderFormJson = orderData.items[1].orderId.strapiOrderFormJson;
  const parsedData = JSON.parse(strapiOrderFormJson)
  console.log(parsedData,"parsedData")
  const entityData = parsedData["Payload"].Entity_Formation;
  console.log(entityData,"entityData")

  const formattedDate = new Date()
  const  date = formattedDate.toLocaleDateString();
  // const {
  //   Name: { CD_LLC_Name },
  //   Principal_Address: {
  //     PA_Address_Line_1,
  //     PA_Address_Line_2,
  //     PA_City,
  //     PA_Zip_Code,
  //     PA_State,
  //   },
  //   Registered_Agent: {
  //     RA_Name,
  //     RA_Email_Address,
  //     RA_Contact_No,
  //     Address: {
  //       RA_Address_Line_1,
  //       RA_Address_Line_2,
  //       RA_City,
  //       RA_Zip_Code,
  //       RA_State,
  //     },
  //     Billing_Information: {
  //       BI_Name,
  //       BI_Email_Address,
  //       BI_Contact_No,
  //       BI_Address_Line_1,
  //       BI_Address_Line_2,
  //       BI_City,
  //       BI_Zip_Code,
  //       BI_State,
  //     },
  //     Mailing_Information: {
  //       MI_Name,
  //       MI_Email_Address,
  //       MI_Contact_No,
  //       MI_Address_Line_1,
  //       MI_Address_Line_2,
  //       MI_City,
  //       MI_Zip_Code,
  //       MI_State,
  //     },
  //   },
  //   Organizer_Information: { Org_Name, Org_Email_Address, Org_Contact_No },
  //   Member_Or_Manager_Details: [
  //     {
  //       __temp_key__,
  //       Mom_Member_Or_Manager,
  //       Mom_Name,
  //       Address: {
  //         MM_Address_Line_1,
  //         MM_City,
  //         MM_Zip_Code,
  //         MM_State,
  //       },
  //     },
  //   ],
  // } = entityData;


  const {
    Name: { CD_LLC_Name = "" } = {},
    Principal_Address: {
      PA_Address_Line_1 = "",
      PA_Address_Line_2 = "",
      PA_City = "",
      PA_Zip_Code = "",
      PA_State = "",
    } = {},
    Registered_Agent: {
      RA_Name = "",
      RA_Email_Address = "",
      RA_Contact_No = "",
      Address: {
        RA_Address_Line_1 = "",
        RA_Address_Line_2 = "",
        RA_City = "",
        RA_Zip_Code = "",
        RA_State = "",
      } = {},
      Billing_Information: {
        BI_Name = "",
        BI_Email_Address = "",
        BI_Contact_No = "",
        BI_Address_Line_1 = "",
        BI_Address_Line_2 = "",
        BI_City = "",
        BI_Zip_Code = "",
        BI_State = "",
      } = {},
      Mailing_Information: {
        MI_Name = "",
        MI_Email_Address = "",
        MI_Contact_No = "",
        MI_Address_Line_1 = "",
        MI_Address_Line_2 = "",
        MI_City = "",
        MI_Zip_Code = "",
        MI_State = "",
      } = {},
    } = {},
    Organizer_Information: {
      Org_Name = "",
      Org_Email_Address = "",
      Org_Contact_No = "",
    } = {},
    Member_Or_Manager_Details: [
      {
        __temp_key__ = "",
        Mom_Member_Or_Manager = "",
        Mom_Name = "",
        Address: {
          MM_Address_Line_1 = "",
          MM_City = "",
          MM_Zip_Code = "",
          MM_State = "",
        } = {},
      } = {},
    ] = [],
  } = entityData || {};
  


  const {
    services = "",
    cra_number = "",
    dontKnow = "",
    entity_name_2 = "",
    totalFees = "",

    
  } = orderData;
const  name_of_attensted = Org_Name

  const downloadPDF = async () => {
    const input = document.getElementById("content-to-pdf"); // Main content container
    const sections = Array.from(input.querySelectorAll(".section")); // Get all sections with class 'section'
    const pageWidth = 595.28; // A4 width in points
    const pageHeight = 841.89; // A4 height in points
    const pdfDoc = await PDFDocument.create();
    const scale = 2; // Scale for better resolution
    const pageMargin = 20; // Page margin

    // Function to render content of a section to a page
    const renderSection = async (section, startY) => {
      // Capture the section's content using html2canvas
      const canvas = await html2canvas(section, {
        scale: scale,
        y: startY, // Start rendering from the current vertical position
        height: pageHeight * scale + 20, // Limit height to the size of one page
      });

      const imgData = canvas.toDataURL("image/png");
      const img = await pdfDoc.embedPng(imgData);

      const imgWidth = img.width * (pageWidth / canvas.width) - 40; // Scale image to fit the page width
      const imgHeight = img.height * (pageHeight / canvas.height) - 150; // Scale image to fit the page height

      // Add a new page to the PDF
      const page = pdfDoc.addPage([pageWidth, pageHeight]);

      // Draw the image onto the page
      page.drawImage(img, {
        x: pageMargin,
        y: pageHeight - imgHeight - pageMargin, // Y position from the top of the page
        width: imgWidth,
        height: imgHeight,
      });
    };

    // Render each section on a new page
    for (let i = 0; i < sections.length; i++) {
      await renderSection(sections[i], 0);
    }

    // Serialize the document to bytes
    const pdfBytes = await pdfDoc.save();

    // Trigger the download of the PDF
    const link = document.createElement("a");
    link.href = URL.createObjectURL(
      new Blob([pdfBytes], { type: "application/pdf" })
    );
    link.download = "generated-document.pdf";
    link.click();
  };

  return (
    <div className="px-6" ref={componentRef} id="content-to-pdf">
{/* page one */}
      <div className="section">
      <div className="flex py-16  gap-2">
        <div className="py-8 w-1/2 flex  flex-col gap-2">
          <p className="text-gray-900 text-center font-inter text-2xl font-semibold leading-7">
            STATE OF MAINE
          </p>

          <p className="text-gray-900 text-center font-inter text-2xl font-semibold leading-4">
            LIMITED LIABILITY COMPANY
          </p>

          <p className="text-gray-900 text-center font-inter text-3xl font-semibold leading-7">
            CERTIFICATE OF FORMATION
          </p>
        </div>
        <div className="w-1/2 mt-4">
          <p className="text-gray-900 text-center font-inter text-lg font-bold leading-7">
            Filing Fee $175.00
          </p>
        </div>
      </div>
      <p className="text-gray-900 font-inter text-xl font-medium leading-7">
        Pursuant to{" "}
        <span className="text-blue-500 font-inter text-xl font-medium leading-7 underline">
          <a
            href="https://www.mainelegislature.org/legis/statutes/31/title31sec1531.html"
            target="_blank"
          >
            31 MRSA §1531,
          </a>
        </span>{" "}
        the undersigned executes and delivers the following Certificate of
        Formation
      </p>

      <div className="p-2 flex gap-12">
        <div>
          <p className="text-gray-900 font-inter text-lg font-medium leading-7">
            First &nbsp; &nbsp; &nbsp;&nbsp;
          </p>
        </div>

        <div className="w-full">
          <p className="text-gray-900 font-inter text-base font-medium leading-6">
            The name of the limited liability company is:
          </p>
          {/* <input
            type="text"
            id="company"
            className="text-blue-500 mt-2
             w-full h-6 border-b p-2 border-b-4 border-gray-900 bg-blue-50"
          /> */}

          <div
            type="text"
            id="company"
            className=" mt-2 h-10 -center w-full h-6 border-b p-2 border-b-4 border-gray-900 bg-blue-50"
          >
            {CD_LLC_Name}
          </div>

          <span className="text-gray-900 font-inter text-xs font-medium leading-6">
            (A limited liability company name must contain the words “limited
            liability company” or “limited company” or the abbreviation
            “L.L.C.,” “LLC,” “L.C.” or “LC” or, in the case of a low-profit
            limited liability company, “L3C” or “l3c” – see{" "}
            <span className="text-blue-500 font-inter text-xs font-medium leading-6 underline">
              <a
                href="https://www.mainelegislature.org/legis/statutes/31/title31sec1508.html"
                target="_blank"
              >
                31 MRSA 1508.
              </a>
            </span>
            )
          </span>
        </div>
      </div>

      {/*  */}

      <div className="p-2 flex gap-12">
        <div>
          <p className="text-gray-900 items-center font-inter text-lg font-medium leading-7">
            Second &nbsp;
          </p>
        </div>

        <div className="w-full flex items-center">
          <div className="flex">
            <p className="text-gray-900 font-inter text-base font-medium leading-6">
              Filing Date: (select one){" "}
            </p>
            <br />
            <div className="flex mx-2 gap-2">
              <input type="checkbox" id="filing" />{" "}
              <label>Date of this filing; or</label>
              <input type="checkbox" id="effective" />{" "}
              <span>Later effective date (specified here)</span>
            </div>
          </div>
          &nbsp;
          {/* <input
            type="text"
            id="company"
            className="text-blue-500 mt-2
             w-1/2 h-6 border-b p-2 border-b-4 border-gray-900 bg-blue-50"
          /> */}
          <div
            type="text"
            id="company"
            className=" mt-2
             w-1/2 h-6 border-b p-2 border-b-4 h-10  border-gray-900 bg-blue-50"
          >
            {date}
          </div>
        </div>
      </div>

      {/*  */}
      <div className="p-2 flex gap-12">
        <div>
          <p className="text-gray-900 font-inter text-lg font-medium leading-7">
            Third &nbsp; &nbsp; &nbsp;
          </p>
        </div>

        <div className="w-full ">
          <p className="text-gray-900 font-inter text-base font-medium leading-6">
            Designation as a low profit LLC (Check only if applicable):{" "}
          </p>
          <input
            type="checkbox"
            className="w-4 h-4 border mt-4 mr-2 border-gray-300 rounded"
          />

          <span className="text-gray-900 font-inter text-sm font-medium leading-12">
            This is a low-profit limited liability company pursuant to{" "}
            <span className="text-blue-500 font-inter text-sm font-medium leading-6 underline">
              <a
                href="https://www.mainelegislature.org/legis/statutes/31/title31sec1611.html"
                target="_blank"
              >
                31 MRSA §1611
              </a>
            </span>{" "}
            meeting all qualifications set forth here:
          </span>

          <span className="flex flex-col gap-2 mt-2">
            <p className="text-gray-900 font-inter text-sm font-medium leading-5">
              A. The company intends to qualify as a low-profit limited
              liability company;
            </p>

            <p className="text-gray-900 font-inter text-sm font-medium leading-5">
              B. The company must at all times significantly further the
              accomplishment of one or more of the charitable or educational
              purposes within the meaning of Section 170(c)(2)(B) of the
              Internal Revenue Code of 1986, as it may be amended, revised or
              succeeded, and must list the specific charitable or educational
              purposes the company will further
            </p>

            <p className="text-gray-900 font-inter text-sm font-medium leading-5">
              C. No significant purpose of the company is the production of
              income or the appreciation of property. The fact that a person
              produces significant income or capital appreciation is not, in the
              absence of other factors, conclusive evidence of a significant
              purpose involving the production of income or the appreciation of
              property; and{" "}
            </p>

            <p className="text-gray-900 font-inter text-sm font-medium leading-5">
              D. No purpose of the company is to accomplish one or more
              political or legislative purpose within the meaning of Section
              170(c)(2)(D) of the Internal Revenue Code of 1986, or its
              successor{" "}
            </p>
          </span>
        </div>
      </div>

      {/*  */}

      <div className="p-2 flex gap-12">
        <div>
          <p className="text-gray-900 font-inter text-lg font-medium leading-7">
            Fourth &nbsp; &nbsp; &nbsp;&nbsp;
          </p>
        </div>

        <div className="w-full">
          <p className="text-gray-900 font-inter text-base font-medium leading-6">
            Designation as a professional LLC (Check only if applicable):{" "}
          </p>
          <input
            type="checkbox"
            id="fourth"
            className="w-4 mr-2 h-4 border border-gray-300 rounded"
          />

          <span className="text-gray-900 font-inter text-base font-medium leading-6">
            This is a professional limited liability company* formed pursuant to
            <span className="text-blue-500 font-inter text-base font-medium leading-6 underline">
              {" "}
              <a
                href="https://www.mainelegislature.org/legis/statutes/31/title31sec1611.html"
                target="_blank"
              >
                13 MRSA Chapter 22-A
              </a>{" "}
            </span>
            to provide the following professional services:
          </span>

          {/* <input
            type="text"
            id="fourthinput"
            className="text-blue-500 mt-2
             w-full h-6 border-b p-2 border-b-4 border-gray-900 bg-blue-50"
          /> */}

          <div
            type="text"
            id="fourthinput"
            className="h-10  mt-2
             w-full h-6 border-b p-2 border-b-4 border-gray-900 bg-blue-50"
          >
            {services}
          </div>
        </div>
      </div>

      <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
        (Type of professional services)
      </p>

      <div className="flex flex-col justify-center items-start flex-1">
        <p className="text-gray-900 font-inter text-xs font-normal leading-6">
          Form No. MLLC-6 (1 of 2)
        </p>
      </div>

      {/*  */}

      <div className="p-2 flex mt-4 gap-12">
        <div>
          <p className="text-gray-900 font-inter text-lg font-medium leading-7">
            Fifth &nbsp; &nbsp; &nbsp;&nbsp;
          </p>
        </div>

        <div className="w-full">
          <p className="text-gray-900 font-inter text-base font-medium leading-6">
            The Registered Agent is a: (select either a Commercial or
            Noncommercial Registered Agent{" "}
          </p>

          <div className="flex gap-12  mt-2">
            <div>
              <input
                type="checkbox"
                id="reg-agent"
                className="w-4 mr-2 h-4 border border-gray-300 rounded"
              />
              <span className="text-gray-900 mr-2 font-inter text-base font-medium leading-6">
                Commercial Registered Agent
              </span>
            </div>

            <div>
              <input
                type="checkbox"
                id="pub-agent1"
                className="w-4 mr-2 h-4 border border-gray-300 rounded"
              />

              <span className="text-gray-900  font-inter text-base font-medium leading-6">
                CRA Public Number:{" "}
              </span>
            </div>
            {/* 
            <input
              type="text"
              id="comm-agent-text2"
              className="text-blue-500 mt-2
             w-1/2 h-6 border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            /> */}
            <div
              id="comm-agent-text2"
              className=" mt-2
             w-1/2 h-10 border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            >
              {cra_number}
            </div>
          </div>

          <div className="flex flex-col justify-center items-center">
            {/* <input
              type="text"
              id="fifthinput-2"
              className="text-blue-500 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            /> */}

            <div
              type="text"
              id="fifthinput-2"
              className="h-10 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            >
              {RA_Name}
            </div>

            <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
              (Name of commercial registered agent){" "}
            </p>
          </div>

          <div>
            <input
              type="checkbox"
              id="noncomm-agent"
              className="w-4 mr-2 h-4 border border-gray-300 rounded"
            />

            <span className="text-gray-900  font-inter text-base font-medium leading-6">
              Noncommercial Registered Agent{" "}
            </span>
          </div>

          <div className="flex flex-col justify-center items-center">
            {/* <input
              type="text"
              id="fifthinput-3"
              className="text-blue-500 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            /> */}

<div
              type="text"
              id="fifthinput-2"
              className="h-10 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            >
              {RA_Name}
            </div>
            
            <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
              (Name of noncommercial registered agent){" "}
            </p>
          </div>

          <div className="flex flex-col justify-center items-center">
            {/* <input
              type="text"
              id="fifthinput-4"
              className="text-blue-500 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            /> */}

<div
              type="text"
              id="fifthinput-2"
              className="h-10 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            >
              {RA_Address_Line_1}
            </div>

            <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
              (physical location, not P.O. Box – street, city, state and zip
              code){" "}
            </p>
          </div>

          <div className="flex flex-col justify-center items-center">
            {/* <input
              type="text"
              id="fifthinput-5"
              className="text-blue-500 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            /> */}

<div
              type="text"
              id="fifthinput-2"
              className="h-10 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            >
              {RA_Email_Address}
            </div>

            <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
              (mailing address if different from above){" "}
            </p>
          </div>
        </div>
      </div>

      {/* 6. */}

      <div className="p-2 flex gap-12">
        <div>
          <p className="text-gray-900 font-inter text-lg font-medium leading-7">
            Sixth &nbsp; &nbsp; &nbsp;&nbsp;
          </p>
        </div>

        <div className="w-full">
          <p className="text-gray-900 font-inter text-base font-medium leading-6">
            Pursuant to
            <span className="text-blue-500 font-inter text-base font-medium leading-6 underline">
              {" "}
              <a
                href="https://www.mainelegislature.org/legis/statutes/13/title13ch22-Asec0.html"
                target="_blank"
              >
                5 MRSA §105.2,
              </a>{" "}
            </span>
            the registered agent listed above has consented to serve as the
            registered agent for this limited liability company.{" "}
          </p>
        </div>
      </div>

      {/* 7 */}

      <div className="p-2 flex gap-12">
        <div>
          <p className="text-gray-900 font-inter text-lg font-medium leading-7">
            Seventh &nbsp; &nbsp; &nbsp;&nbsp;
          </p>
        </div>

        <div className="w-full">
          {/* <p className="text-gray-900 font-inter text-base font-medium leading-6">
            Pursuant to 5 MRSA §105.2, the registered agent listed above has
            consented to serve as the registered agent for this limited
            liability company.{" "}
          </p> */}
          <span className="text-gray-900 font-inter text-base font-bold leading-6">
            OPTIONAL
          </span>

          <input
            type="checkbox"
            id="optional-auth"
            className="w-4 m-2 h-4 border border-gray-300 rounded"
          />

          <span className="text-gray-900 font-inter text-base font-medium leading-6">
            Check if providing a statement of authority at this time
          </span>

          <br />

          <input
            type="checkbox"
            id="auth-2"
            className="w-4 m-2 h-4 border border-gray-300 rounded"
          />

          <span className="text-gray-900 font-inter text-base font-medium leading-6">
            Pursuant to{" "}
            <span className="text-blue-500 font-inter text-base font-medium leading-6 underline">
              <a
                href="https://www.mainelegislature.org/legis/statutes/31/title31sec1542.html"
                target="_blank"
              >
                31 MRSA §1542.1
              </a>
            </span>{" "}
            a statement of authority or any other matters the members determine
            to include are set forth in{" "}
          </span>

          <span className="text-gray-900 font-inter text-sm font-medium leading-5">
            the attached Exhibit
          </span>

          {/* <div className="flex  inline px-3 items-center gap-2 flex-1 flex-wrap  bg-primary-50 shadow-xs"> */}
          {/* <input
            type="text"
            id="fifthinput-5"
            className="text-blue-500 mt-2 w-1/2 h-6 border-b p-2 inline-block border-b-4 border-gray-900 bg-blue-50"
          /> */}


<div        id="fifthinput-5"
            className="h-10  mt-2 w-1/2 h-6 border-b p-2 inline-block border-b-4 border-gray-900 bg-blue-50"
          >
{dontKnow}
            </div>


          {/* </div> */}

          <span className="text-gray-900 font-inter text-sm font-medium leading-5">
            , and made part hereof{" "}
          </span>
        </div>
      </div>
      <div>
        <div className="py-28 flex justify-between px-4">
          <div className="w-full">
            <span>Dated</span>

            {/* <input
              type="text"
              id="dated-1"
              className="text-blue-500 mt-2 w-1/2 h-6 border-b p-2 inline-block border-b-4 border-gray-900 bg-blue-50"
            /> */}

<div
              type="text"
              id="signer-5"
              className="h-10 mt-2
       w-1/2 h-6 text-center  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            >
              {date}
            </div>


          </div>

          <div className="w-1/2  items-center">
            <div className="flex">
              <span className="mr-2">**By</span>
              <input
                type="text"
                id="by-2"
                className="text-blue-500 mt-2 w-full h-6 border-b p-2 border-b-4 border-gray-900"
              />
            </div>

            <div className="flex flex-col justify-center items-center">
              <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
                (original written signature of authorized person){" "}
              </p>
            </div>

            <div className="flex flex-col justify-center items-center">
              <div className="flex w-full flex-col justify-center items-center">
                {/* <input
                  type="text"
                  id="signer-5"
                  className="text-blue-500 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
                /> */}


<div
              type="text"
              id="signer-5"
              className="h-10 mt-2
       w-full h-6 text-center  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            >
              {RA_Name}
            </div>


                <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
                  (type or print name and title of signer){" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="h-2 border-t-4 my-2 border-black w-full"></div>
      </div>

      <div>
        <span className="text-gray-900 font-inter text-xl font-bold leading-7">
          *Examples{" "}
        </span>
        <span className="text-gray-900 font-inter text-lg font-normal leading-7">
          of professional service limited liability companies are accountants,
          attorneys, chiropractors, dentists, registered nurses and
          veterinarians.
          <br />
          (This is not an inclusive list – see
          <span className="text-blue-500 font-inter text-lg font-medium leading-7 underline">
            <a
              href="https://www.mainelegislature.org/legis/statutes/13/title13sec723.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              13 MRSA §723.7
            </a>
          </span>
          )
        </span>

        <div>
          <span className="text-gray-900 font-inter text-lg font-normal leading-7">
            **Pursuant to
          </span>
          <span className="text-blue-500 font-inter text-lg font-medium leading-7 underline">
            <a
              href="https://www.mainelegislature.org/legis/statutes/13/title13sec723.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              13 MRSA §723.7
            </a>
          </span>

          <span className="text-gray-900 font-inter text-lg font-normal leading-7">
            {" "}
            Certificate of Formation MUST be signed by at least one authorized
            person
          </span>
        </div>

        <p className="text-gray-900 font-inter text-lg font-normal leading-7">
          The execution of this certificate constitutes an oath or affirmation
          under the penalties of false swearing under
          <span className="text-blue-500 font-inter text-lg font-medium leading-7 underline">
            <a
              href="https://www.mainelegislature.org/legis/statutes/13/title13sec723.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              17-A MRSA §453.
            </a>
          </span>
        </p>
      </div>

      {/*  submit*/}

      <div className="flex justify-between mt-4">
        <div>
          <p className="text-gray-900 font-inter text-xl font-medium leading-7">
            Submit completed form to:
          </p>
        </div>

        <div>
          <p className="text-grey-900 font-inter text-base font-bold leading-6">
            Secretary of State
          </p>

          <p className="text-grey-900 font-inter text-base font-bold leading-6">
            Division of Corporations, UCC and Commissions
          </p>

          <p className="text-grey-900 font-inter text-base font-bold leading-6">
            101 State House Station
          </p>

          <p className="text-grey-900 font-inter text-base font-bold leading-6">
            Augusta, ME 04333-0101
          </p>
          <p className="text-grey-900 font-inter text-base font-bold leading-6">
            <span className="text-grey-900 font-inter text-base font-medium leading-6">
              Telephone Inquiries:
            </span>{" "}
            (207) 624-7752
          </p>
        </div>

        <div className="flex mt-12">
          <p>
            Email Inquiries:
            <span className="text-blue-500 font-inter text-base font-medium leading-7 underline">
              {" "}
              <a
                href="mailto:CEC.Corporations@Maine.gov"
                target="_blank"
                rel="noopener noreferrer"
              >
                CEC.Corporations@Maine.gov
              </a>
            </span>
          </p>
        </div>
      </div>
      <p className="text-grey-900 font-inter text-xs font-normal leading-6">
        Form No. MLLC-6 (2 of 2) 12/23
      </p>

      <hr className="boder border-t-2 border-grey-900 my-4" />

 </div>
{/* page two */}
      <div className="section">

      <div className="pdf-break my-16 px-8">
        <p className="text-gray-900 text-center font-inter text-2xl font-semibold leading-[30px]">
          Customer Contact Cover Letter
        </p>
      </div>

      <div>
        <p className="text-grey-900 font-inter text-base font-bold leading-6">
          Name of entity(s) on the submitted filings:
        </p>
        {/* <input
          type="text"
          id="cust-cl"
          className="text-blue-500 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
        /> */}

<div          id="cust-cl"
          className="h-10 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
        >{CD_LLC_Name}</div>
<div          id="cust-cl-2"
          className="h-10 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
        >{entity_name_2}</div>
        <p className="text-grey-900 mt-2 font-inter text-base font-bold leading-6">
          Optional special handling request(s):
          <span className="text-grey-900 mx-2 font-inter text-base font-medium leading-6 underline">
            (check only if applicable)
          </span>
        </p>
        <div className="px-4">
          <input type="checkbox" className="m-2 " />
          <span className="text-grey-900 font-inter text-base font-medium leading-6">
            Hold attested copy for pick up (will be required to pick up at our
            office in Augusta, Maine)
          </span>
        </div>
        <div className="px-4">
          <input type="checkbox" className="m-2 " />
          <span className="text-grey-900 font-inter text-base font-medium leading-6">
            24-hour expedited filing (next business day) service:
            <span className="text-grey-900 font-inter text-base font-bold leading-6">
              {" "}
              $50
            </span>{" "}
            additional filing fee per entity
          </span>
        </div>
        <div className="px-4">
          <input type="checkbox" className="m-2 " />
          <span className="text-grey-900 font-inter text-base font-medium leading-6">
            Immediate expedited filing (same business day):
            <span className="text-grey-900 font-inter text-base font-bold leading-6">
              {" "}
              $100
            </span>{" "}
            additional filing fee per entity
          </span>
        </div>
        <div>
          <p className="text-grey-900 mt-2 font-inter text-base font-bold leading-6">
            NOTE:
            <span className="text-grey-900 mx-2 font-inter text-base font-medium leading-6 ">
              Only one expedite fee is required if filing multiple documents for
              the{" "}
              <span className="text-grey-900 mt-2 font-inter text-base font-bold leading-6">
                same entity/charter number{" "}
              </span>
              at the same time.
            </span>
          </p>
        </div>
        <div>
          <p className="text-grey-900 mt-2 font-inter text-base font-bold leading-6">
            Payment can be made by check or money order
            <span className="text-grey-900 mx-2 font-inter text-base font-medium leading-6 ">
              (payable to Maine Secretary of State) or by credit card. You may
              obtain a credit card voucher at
              https://www.maine.gov/sos/cec/forms/credit.pdf.
            </span>
          </p>
        </div>
        {/*  */}

        <div className="w-full">
          <p className="text-gray-900 font-inter text-base font-medium leading-6">
            The Registered Agent is a: (select either a Commercial or
            Noncommercial Registered Agent{" "}
          </p>

          <div className="flex gap-12 justify-center items-center  mt-2">
            <div className="">
              <div className="w-full">
                <span className="text-gray-900  font-inter text-base font-medium leading-6">
                  Total fee(s) enclosed: ${" "}
                </span>
              </div>
            </div>
{/* 
            <input
              type="text"
              id="total-fee"
              className="text-blue-500 mt-2
             w-1/2 h-6 border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            /> */}

<div          id="cust-cl"
          className="h-10 mt-2
       w-1/2 h-10  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
        >{totalFees}</div>

          </div>

          <div className="w-full flex gap-4">
            <div className="flex w-1/2 flex-col justify-center items-center p-2">
              {/* <input
                type="text"
                id="contact-person"
                className="text-blue-500 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
              /> */}

              
<div          id="cust-cl"
          className="h-10 mt-2
       w-full h-10  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
        >{RA_Name}</div>


              <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
                (Name of contact person){" "}
              </p>
            </div>
            <div className="flex w-1/2 flex-col justify-center items-center p-2">
              {/* <input
                type="text"
                id="tel-num"
                className="text-blue-500 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
              /> */}

                            
<div          id="cust-cl"
          className="h-10 mt-2
       w-full h-10  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
        >{RA_Contact_No}</div>



              <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
                (Daytime telephone number){" "}
              </p>
            </div>
          </div>

          {/* email */}

          <div className="w-full flex gap-4">
            <div className="flex w-1/2 flex-col justify-center items-center p-2">
              {/* <input
                type="text"
                id="contact-person-email"
                className="text-blue-500 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
              /> */}

<div          id="contact-person-email"
          className="h-10 mt-2
       w-full h-10  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
        >{MI_Email_Address}</div>


              <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
                (Contact email address for this filing){" "}
              </p>
            </div>
            <div className="flex w-1/2 flex-col justify-center items-center p-2">
              {/* <input
                type="text"
                id="email-annual"
                className="text-blue-500 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
              /> */}

<div          id="email-annual"
          className="h-10 mt-2
       w-full h-10  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
        >{MI_Email_Address}</div>


              <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
                (Email address to use for annual report reminders){" "}
              </p>
            </div>
          </div>

          {/*  */}
          <div>
            <span className="text-gray-900  font-inter text-base font-medium leading-6">
              Name and address of person to
              <span className="text-grey-900 font-inter text-base font-bold leading-6">
                {" "}
                return the attested{" "}
              </span>
              copy of the completed filing:{" "}
            </span>
          </div>

          <div className="flex px-4 flex-col justify-center items-center">
            {/* <input
              type="text"
              id="attested-name"
              className="text-blue-500 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            /> */}

            
<div          id="attested-name"
          className="h-10 mt-2
       w-full h-10  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
        >{name_of_attensted}</div>



            <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
              (Name of attested copy recipient){" "}
            </p>
          </div>

          <div className="flex px-4 flex-col justify-center items-center">
            {/* <input
              type="text"
              id="attested-firm"
              className="text-blue-500 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            /> */}

                        
<div          id="attested-firm"
          className="h-10 mt-2
       w-full h-10  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
        >{CD_LLC_Name}</div>



            <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
              (Firm or Company)
            </p>
          </div>

          <div className="flex px-4 flex-col justify-center items-center">
            {/* <input
              type="text"
              id="attested-mail"
              className="text-blue-500 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            /> */}

<div          id="attested-firm"
          className="h-10 mt-2
       w-full h-10  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
        >{MI_Address_Line_1}</div>




            <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
              (Mailing Address){" "}
            </p>
          </div>

          <div className="flex px-4 flex-col justify-center items-center">
            {/* <input
              type="text"
              id="attested-ciry"
              className="text-blue-500 mt-2
       w-full h-6  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
            /> */}

            
<div          id="attested-city"
          className="h-10 mt-2
       w-full h-10  border-b p-2 border-b-4 border-gray-900 bg-blue-50"
        >{MI_City},{MI_State}, {MI_Zip_Code}</div>





            <p className="text-gray-900 text-center font-inter text-xs font-medium leading-6">
              (City, State & Zip){" "}
            </p>
          </div>

          <p className="text-grey-900 px-0 font-inter text-base font-bold leading-6">
            NOTE: Failure to provide a contact name and telephone number or
            email address will result in any erroneous filing(s) being returned
            to the filer by the Secretary of State’s office.
          </p>

          <div className="leading-loose px-0 mt-2">
            <span className="text-grey-900  font-inter text-base font-medium leading-6">
              For questions regarding the above filing(s), please call or email
              our office at (207) 624-7752 or{" "}
              <span className="text-blue-500 font-inter text-base font-medium leading-6">
                <a href="mail:cec.corporations@maine.gov">
                  cec.corporations@maine.gov
                </a>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div className="flex justify-evenly my-4 ">
        <div className="w-1/2">
          <p className="text-gray-900 font-inter text-lg font-medium leading-[30px]">
            Submit filings to:
          </p>
          <p className="text-grey-900 font-inter text-base font-bold leading-6">
            Mailing Address if using US Postal Service
          </p>

          <p className="text-grey-900 font-inter text-base font-medium leading-6">
            Department of the Secretary of State
          </p>

          <p className="text-grey-900 font-inter text-base font-medium leading-6">
            Corporations, UCC and Commissions
          </p>
          <p className="text-grey-900 font-inter text-base font-medium leading-6">
            101 State House Station
          </p>

          <p className="text-grey-900 font-inter text-base font-medium leading-6">
            Augusta, ME 04333-0101
          </p>
        </div>

        <div className="w-1/2">
          <p className="text-grey-900 font-inter text-base font-bold leading-6">
            Mailing Address if using FedEx/UPS
          </p>

          <p className="text-grey-900 font-inter text-base font-medium leading-6">
            Department of the Secretary of State
          </p>

          <p className="text-grey-900 font-inter text-base font-medium leading-6">
            Corporations, UCC and Commissions
          </p>
          <p className="text-grey-900 font-inter text-base font-medium leading-6">
            6 E. Chestnut Street, 5th Floor
          </p>
          <p className="text-grey-900 font-inter text-base font-medium leading-6">
            Augusta, ME 04330
          </p>
        </div>
      </div>
      <p className="text-grey-900 font-inter text-xs font-normal leading-6">
        Rev. 6/26/2024
      </p>

</div>

      <div className="flex w-full justify-content-center p-2">
        <Button
          className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
          label="Download PDF"
          icon="pi pi-download"
          onClick={downloadPDF}
        ></Button>
      </div>
    </div>
  );
}

export default MaineLLC;
