import React, { useState, useEffect, useContext, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import UserServices from "../../services/userService";
import authContext from "../../common/authContext";
import { Container, Form, Modal, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {
 
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox, ListItemText,
  Chip,
} from "@mui/material";

export default function RolesPermission({ setTotalCount }) {
  const { globalCompany, isTabRefresh, setIsTabRefresh } =
    useContext(authContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State for success modal
  const [editingRole, setEditingRole] = useState(null); // Store role being edited
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState([]);
  const [superAdminId, setSuperAdminId] = useState(null);
  const [roleName, setRoleName] = useState("");
  const [roleDesc, setRoleDesc] = useState("");
  const [selected, setSelected] = useState([]);

  let newObject = window.localStorage.getItem("user");

  let user = Object.assign({}, JSON.parse(newObject));

  const handleCloseSuccessModal = () => setShowSuccessModal(false); // Close success modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEdit = (role) => {
    setEditingRole(role);
    setRoleName(role.roleName); // ✅ Set state directly
    setRoleDesc(role.roleDesc); // ✅ Set state directly

    const selectedPermissions = options.filter((option) =>
      role.permissionMasters.split(", ").includes(option.label)
    );
    setSelected(selectedPermissions);
    handleShow();
  };

  function checkIdsExist(arr, idsToCheck) {
    // Check if any of the idsToCheck are found in arr
    const foundIds = idsToCheck.filter((idToCheck) =>
      arr.some((item) => item.id === idToCheck)
    );
    return foundIds.length > 0 ? foundIds : null;
  }

  useEffect(() => {
    if (user) {
      console.log(globalCompany);
      if (globalCompany !== null) {
        if (globalCompany.roles.length > 0) {
          // Check for both 1 and 4
          const superId = checkIdsExist(globalCompany.roles, [1]);
          if (superId) {
            setSuperAdminId(superId); // Set the array of matched superId
          }
          if (!superId || superId.length === 0) {
            setSuperAdminId(null);
          }
        }
      }
    }
  }, [globalCompany]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters({
      ...filters,
      global: { value, matchMode: "contains" },
    });
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await UserServices.getRoles();
        const formattedData =
          response.data?.map((item) => ({
            id: item.id || 0,
            roleName: item.roleName || "N/A",
            roleDesc: item.roleDesc || "N/A",
            permissionMasters: item.permissionMasters
              ? item.permissionMasters.map((p) => p.permissionName).join(", ")
              : "N/A",
          })) || [];

        console.log(response);

        if (response?.data) {
          if (superAdminId) {
            const permissionList = response?.data.find(
              (val) => val?.id === 1
            )?.permissionMasters;
            console.log(permissionList);
            const formattedOptions = permissionList.map((permission) => ({
              label: permission.permissionName, // label for the MultiSelect
              value: permission, // value for the MultiSelect
            }));
            setOptions(formattedOptions);
          } else {
            const permissionList = response?.data.find(
              (val) => val?.id === 2
            )?.permissionMasters;
            console.log(permissionList);
            const formattedOptions = permissionList.map((permission) => ({
              label: permission.permissionName, // label for the MultiSelect
              value: permission, // value for the MultiSelect
            }));
            setOptions(formattedOptions);
          }
        }

        setData(formattedData);
        setTotalCount(formattedData.length);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setTotalCount, isTabRefresh]);


  const actionsTemplate = (rowData) => {
    const disabledRoles = [
      "SUPER_ADMINISTRATOR",
      "ADMINISTRATOR",
      "EMPLOYEE",
      "USER",
    ];

    return disabledRoles.includes(rowData.roleName) ? (
      <FontAwesomeIcon
        icon={faEdit}
        style={{ color: "#ccc", cursor: "not-allowed" }}
      />
    ) : (
      <FontAwesomeIcon
        icon={faEdit}
        style={{ cursor: "pointer", color: "#344054" }}
        onClick={() => handleEdit(rowData)}
      />
    );
  };

  const renderHeader = () => (
    <div className="flex justify-content-between p-2">
      <div className="flex">
        <div>
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Clear"
            outlined
            onClick={() => {
              setFilters({
                roleName: { value: null, matchMode: "contains" },
                roleDesc: { value: null, matchMode: "contains" },
                RolesPermission: { value: null, matchMode: "contains" },
                global: { value: "", matchMode: "contains" },
              });
              setGlobalFilterValue("");
            }}
            className="me-2"
          />
        </div>
        <div>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Global Search"
          />
        </div>
      </div>
      <div>
        <Button
          type="button"
          icon="pi pi-plus"
          label="Add"
          outlined
          onClick={handleShow}
          className="me-2"
        />
      </div>
    </div>
  );

  const checkIfRoleExists = async (roleName) => {
    try {
      const response = await UserServices.getRoles();
      const roleExists = response.data.some(
        (role) => role.roleName === roleName
      );
      return roleExists;
    } catch (err) {
      console.error("Error checking role existence:", err);
      return false;
    }
  };

  // Handle Submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!roleName || !roleDesc || selected.length === 0) {
      setError("Please fill in all fields.");
      return;
    }

    try {
      if (!editingRole) {
        const roleExists = await checkIfRoleExists(roleName);
        if (roleExists) {
          setError("Role already exists. Please choose a different name.");
          return;
        }
      }

      const roleData = {
        id: editingRole?.id || null,
        roleName,
        roleDesc,
        permissionMasters: selected.map((permission) => ({
          id: permission.value.id,
          permissionName: permission.label,
        })),
      };  

      setIsTabRefresh(true);

      if (editingRole) {
        await UserServices.editRoles(roleData); // ✅ Ensure correct function call
        console.log("Role updated successfully!");
      } else {
        await UserServices.saveRoles(roleData);
        console.log("Role saved successfully!");
      }

      setRoleName("");
      setRoleDesc("");
      setSelected([]);
      setEditingRole(null); 

      handleClose();
      setShowSuccessModal(true);
      setTimeout(() => setShowSuccessModal(false), 3000);
      setIsTabRefresh(false);
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to save the role.");
      setIsTabRefresh(false);
    }
  };

  return (
    <section className=" mb-5">
      <div className="main-table-class-datatable">
        <div className="card">
          {error && <div className="alert alert-danger">{error}</div>}
          <DataTable
            value={data}
            paginator
            rows={5}
            loading={loading}
            filters={filters}
            globalFilter={globalFilterValue}
            globalFilterFields={["roleName", "roleDesc"]}
            header={renderHeader()}
            emptyMessage="No records found."
            scrollable
            scrollHeight="flex"
            scrollWidth="100%"
          >
            <Column field="roleName" header="Role Name" sortable />
            <Column field="roleDesc" header="Role Description" sortable />
            <Column
              field="permissionMasters"
              header="Permission Master"
              sortable
            />
            <Column header="Actions" body={actionsTemplate} />
          </DataTable>
        </div>
      </div>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered>
        <Modal.Body>
          <div className="text-center">
            Your role has been saved successfully!
          </div>
        </Modal.Body>
      </Modal>

      <div>
        <Offcanvas show={show} onHide={handleClose} placement="end">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Roles & Permission</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Form.Group className="mb-3" controlId="roleName">
                <Form.Label>Role Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Role Name"
                  value={roleName} // ✅ Use state value
                  onChange={(e) => setRoleName(e.target.value)} // ✅ Update state dynamically
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="roleDescription">
                <Form.Label>Role Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter Role Description"
                  value={roleDesc}
                  onChange={(e) => setRoleDesc(e.target.value)}
                />
              </Form.Group>

              <FormControl fullWidth sx={{ backgroundColor: "#f8f9fa", borderRadius: "8px", minWidth: 250 }}>
  <InputLabel>Permissions</InputLabel>
  <Select
    multiple
    value={selected}
    MenuProps={{
      PaperProps: {
        sx: { maxHeight: 300 }, // Limits the dropdown menu height
      },
    }}
    onChange={(e) => {
      const value = e.target.value;

      if (value.includes("all")) {
        setSelected(selected.length === options.length ? [] : options.map((opt) => opt.value));
      } else {
        setSelected(value);
      }
    }}
    renderValue={(selected) =>
      selected.length
        ? selected.map((id) => options.find((opt) => opt.value === id)?.label).join(", ")
        : "Select Permissions"
    }
  >
    {/* "Select All" Option */}
    <MenuItem value="all">
      <Checkbox checked={selected.length === options.length} />
      <ListItemText primary="Select All" />
    </MenuItem>

    {/* Dynamic Options */}
    {options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        <Checkbox checked={selected.includes(option.value)} />
        <ListItemText primary={option.label} />
      </MenuItem>
    ))}
  </Select>
</FormControl>


              <div className="mt-2">
                <Button
                  type="submit"
                  icon="pi pi-plus"
                  label="Save"
                  outlined
                  className="me-2"
                />
              </div>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </section>
  );
}
