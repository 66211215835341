import React from 'react';

const FormationServices = () => {
  const services = [
    { title: 'Corporation (Corp)', description: 'A corporation is a separate legal entity, distinct from its owners (shareholders). It offers strong protection against personal liability, making it a popular choice for larger businesses.' },
    { title: 'Limited Liability Company (LLC)', description: 'LLCs combine the liability protection of a corporation with the operational flexibility of a partnership. Owners are called members, and they aren’t personally liable for the company’s debts.' },
    { title: 'Professional Corporation (PC)', description: 'PCs are designed for licensed professionals (such as doctors, lawyers, and accountants). They offer liability protection while allowing professionals to own and manage the corporation.' },
    { title: 'Professional Limited Liability Company (PLLC)', description: 'Similar to an LLC, but reserved for licensed professionals, PLLCs offer personal asset protection while allowing professionals to practice within their licensed field.' }
  ];

  return (
    <section className="flex flex-col items-center self-stretch px-8 mt-7 w-full text-center max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col justify-center p-2.5 max-w-full w-[1140px]">
        <h2 className="flex flex-col w-full text-5xl font-medium tracking-tighter leading-tight text-blue-700 max-md:max-w-full">
          <span className="text-blue-700">Entity Formation</span>
          <span className="text-slate-700"> Services</span>
        </h2>
        <p className="flex flex-col px-72 mt-2 w-full text-xl text-gray-800 h-[59px] max-md:px-5 max-md:max-w-full">
        We offer comprehensive services to help you establish and operate your US Formation Services.        </p>
        <div className="flex flex-col justify-center p-2.5 mt-5 w-full min-h-[949px] text-zinc-700">
          <div className="flex flex-col w-full max-md:max-w-full">
            {/* <nav className="flex flex-col justify-center pb-5 w-full text-base font-medium leading-none text-blue-700 uppercase max-md:max-w-full">
              <ul className="flex flex-wrap gap-10 justify-center items-center w-full max-md:max-w-full">
                <li className="self-stretch px-7 py-3.5 my-auto bg-white rounded border-2 border-blue-700 border-solid shadow-[0px_0px_10px_rgba(0,0,0,0.1)] w-[193px] max-md:px-5">
                  Entity Formation
                </li>
                <li className="self-stretch px-7 py-3.5 my-auto bg-white rounded min-w-[240px] shadow-[0px_0px_10px_rgba(0,0,0,0.1)] max-md:px-5">
                  Government Filings and Compliance
                </li>
                <li className="self-stretch px-7 py-3.5 my-auto bg-white rounded shadow-[0px_0px_10px_rgba(0,0,0,0.1)] max-md:px-5">
                  Additional Services
                </li>
              </ul>
            </nav> */}
            <div className="flex flex-col items-center  w-full max-md:px-5 max-md:max-w-full">
              <div className="flex flex-wrap justify-center items-start w-full  max-md:max-w-full">
                {services.slice(0, 2).map((service, index) => (
                  <div key={index} className="flex sam flex-wrap flex-1 shrink justify-center items-start p-4 basis-0 min-w-[340px] max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px]">
                      <div className="flex overflow-hidden flex-col w-full rounded-lg shadow-lg bg-white bg-opacity-0">
                        <div className="flex overflow-hidden flex-col justify-center w-full">
                          <div className="flex overflow-hidden flex-col justify-center px-5 py-10 w-full bg-gray-50 min-h-[229px]">
                            <h3 className="flex flex-col pb-4 w-full text-3xl font-medium leading-none">
                              <div className="px-12 w-full text-wrap max-md:px-5">
                                {service.title}
                              </div>
                            </h3>
                            <p className="flex flex-col pb-4 w-full text-base font-light leading-7">
                              <div className="px-1 w-full">
                                {service.description}
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col items-center w-full max-md:px-5 max-md:max-w-full">
              <div className="flex flex-wrap justify-center items-start w-full  max-md:max-w-full">
                {services.slice(2, 4).map((service, index) => (
                  <div key={index} className="flex flex-wrap flex-1 shrink justify-center items-start p-4 basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px]">
                      <div className="flex overflow-hidden flex-col w-full rounded-lg shadow-lg bg-white bg-opacity-0">
                        <div className="flex overflow-hidden flex-col justify-center w-full">
                          <div className="flex overflow-hidden flex-col justify-center px-5 py-10 w-full bg-gray-50 min-h-[229px]">
                            <h3 className="flex flex-col pb-4 w-full text-3xl font-medium leading-none whitespace-nowrap">
                              <div className="px-12 w-full max-md:px-5 text-wrap">
                                {service.title}
                              </div>
                            </h3>
                            <p className="flex flex-col pb-4 w-full text-base font-light leading-7">
                              <div className="px-1 w-full">
                                {service.description}
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col items-center w-full max-md:px-5 max-md:max-w-full">
              <div className="flex flex-wrap justify-center items-start w-full  max-md:max-w-full">
                {services.slice(4).map((service, index) => (
                  <div key={index} className="flex flex-wrap flex-1 shrink justify-center items-start p-4 basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px]">
                      <div className="flex overflow-hidden flex-col w-full rounded-lg shadow-lg bg-white bg-opacity-0">
                        <div className="flex overflow-hidden flex-col justify-center w-full">
                          <div className="flex overflow-hidden flex-col justify-center px-5 py-10 w-full bg-gray-50 min-h-[229px]">
                            <h3 className="flex flex-col pb-4 w-full text-3xl font-medium leading-tight">
                              <div className="px-2 w-full text-wrap">{service.title}</div>
                            </h3>
                            <p className="flex flex-col pb-4 w-full text-base font-light leading-7">
                              <div className="px-3.5 w-full">
                                {service.description}
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center px-32 w-full text-base font-medium leading-none text-white max-md:px-5 max-md:max-w-full">
        <button className="px-7 py-3.5 vstate-button rounded shadow-[0px_0px_10px_rgba(0,0,0,0.5)] max-md:px-5">
          Get Quote
        </button>
      </div>
    </section>
  );
};

export default FormationServices;