import React, { useRef } from "react";
import logo from "../../../src/Assets/Images/logo.png";
import { Button, Col, Form, FormCheck, Row, Table } from "react-bootstrap";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";

const ConnecticutCorp = ({ orderData  }) => {
  const componentRef = useRef();

  if (!orderData) {
    return <div>No order data available.</div>;
  }

  
  // const item = .items;  
  const strapiOrderFormJson = orderData.items[1].orderId.strapiOrderFormJson;
  const parsedData = JSON.parse(strapiOrderFormJson)
  const entityData = parsedData["Payload"].Entity_Formation;

  const formattedDate = new Date()
  const  date = formattedDate.toLocaleDateString();

  const {
    Name,
    Principal_Address,
    Registered_Agent,
    Incorporator_Information,
    Stock_Details
  } = entityData;
  

  // const {
  //   CD_Corporation_Name
  // } = Name;
  
  // const {
  //   PA_Address_Line_1,
  //   PA_Address_Line_2,
  //   PA_City,
  //   PA_State,
  //   PA_Zip_Code
  // } = Principal_Address;
  
  // const {
  //   RA_Name,
  //   RA_Email_Address,
  //   RA_Contact_No,
  //   Address: {
  //     RA_Address_Line_1,
  //     RA_Address_Line_2,
  //     RA_City,
  //     RA_State,
  //     RA_Zip_Code
  //   }
  // } = Registered_Agent;
  
  // const {
  //   Incorporator_Details: {
  //     Inc_Name,
  //     Inc_Email_Address,
  //     Inc_Contact_No
  //   },
  //   Address: {
  //     Inc_Address_Line_1,
  //     Inc_Address_Line_2,
  //     Inc_City,
  //     Inc_State,
  //     Inc_Zip_Code,
  //   }
  // } = Incorporator_Information;

  // const {
  //   SI_Number_Of_Shares,
  //   SI_Par_Value
  // } = Stock_Details;
  

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  

  // const {
  //   signature = "",

  //   class_1 = "",
  //   class_2 = "",
  //   class_3 = "",
  //   class_4 = "",
  //   class_6 = "",
  //   class_7 = "",
  //   class_8 = "",
  //   class_9 = "",

  //   city_3 = "",
  //   city_4 = "",
  //   city_5 = "",

  //   state_3 = "",
  //   state_4 = "",
  //   state_5 = "",

  //   zipcode_3 = "",
  //   zipcode_5 = "",
  //   zipcode_6 = "",

  //   street = "",
  //   street_3 = "",
  //   street_4 = "",
  // } = orderData;

  
  const {
    CD_Corporation_Name = ''
  } = Name;
  
  const {
    PA_Address_Line_1 = '',
    PA_Address_Line_2 = '',
    PA_City = '',
    PA_State = '',
    PA_Zip_Code = ''
  } = Principal_Address;
  
  const {
    RA_Name = '',
    RA_Email_Address = '',
    RA_Contact_No = '',
    Address: {
      RA_Address_Line_1 = '',
      RA_Address_Line_2 = '',
      RA_City = '',
      RA_State = '',
      RA_Zip_Code = ''
    } = {}
  } = Registered_Agent;
  
  const {
    Incorporator_Details: {
      Inc_Name = '',
      Inc_Email_Address = '',
      Inc_Contact_No = ''
    } = {},
    Address: {
      Inc_Address_Line_1 = '',
      Inc_Address_Line_2 = '',
      Inc_City = '',
      Inc_State = '',
      Inc_Zip_Code = ''
    } = {}
  } = Incorporator_Information;

  const {
    SI_Number_Of_Shares = '',
    SI_Par_Value = ''
  } = Stock_Details;

  const {
    signature = '',

    class_1 = '',
    class_2 = '',
    class_3 = '',
    class_4 = '',
    class_6 = '',
    class_7 = '',
    class_8 = '',
    class_9 = '',

    city_3 = '',
    city_4 = '',
    city_5 = '',

    state_3 = '',
    state_4 = '',
    state_5 = '',

    zipcode_3 = '',
    zipcode_5 = '',
    zipcode_6 = '',

    street = '',
    street_3 = '',
    street_4 = ''
  } = orderData;
  


  const downloadPDF = async () => {
    const input = document.getElementById("content-to-pdf"); // Main content container
    const sections = Array.from(input.querySelectorAll(".section")); // Get all sections with class 'section'
    const pageWidth = 595.28; // A4 width in points
    const pageHeight = 841.89; // A4 height in points
    const pdfDoc = await PDFDocument.create();
    const scale = 2; // Scale for better resolution
    const pageMargin = 20; // Page margin

    // Function to render content of a section to a page
    const renderSection = async (section, startY) => {
      // Capture the section's content using html2canvas
      const canvas = await html2canvas(section, {
        scale: scale,
        y: startY, // Start rendering from the current vertical position
        height: pageHeight * scale + 20, // Limit height to the size of one page
      });

      const imgData = canvas.toDataURL("image/png");
      const img = await pdfDoc.embedPng(imgData);

      const imgWidth = img.width * (pageWidth / canvas.width) - 40; // Scale image to fit the page width
      const imgHeight = img.height * (pageHeight / canvas.height) - 100; // Scale image to fit the page height

      // Add a new page to the PDF
      const page = pdfDoc.addPage([pageWidth, pageHeight]);

      // Draw the image onto the page
      page.drawImage(img, {
        x: pageMargin,
        y: pageHeight - imgHeight - pageMargin, // Y position from the top of the page
        width: imgWidth,
        height: imgHeight,
      });
    };

    // Render each section on a new page
    for (let i = 0; i < sections.length; i++) {
      await renderSection(sections[i], 0);
    }

    // Serialize the document to bytes
    const pdfBytes = await pdfDoc.save();

    // Trigger the download of the PDF
    const link = document.createElement("a");
    link.href = URL.createObjectURL(
      new Blob([pdfBytes], { type: "application/pdf" })
    );
    link.download = "generated-document.pdf";
    link.click();
  };
  return (
    <section
      className="main-class-form-pdf"
      ref={componentRef}
      id="content-to-pdf"
    >
      {/* First page */}
      <div className="section">
        <div className="d-flex">
          <div>
            <img src={logo} className="logo-img-pdf me-3" />
          </div>
          <div className="mt-4">
            <h2 className="main-title-pdf">
              Secretary of the State of Connecticut
            </h2>
            <div className="d-flex">
              <span className="blkk-text-big me-2">
                Phone: <span className="blue-big-text">860-509-6003</span>
              </span>
              <span className="blkk-text-big me-2">
                {" "}
                Website: <span className="blue-big-text">
                  business.ct.gov
                </span>{" "}
              </span>
              <span className="blkk-text-big me-2">
                Email: <span className="blue-big-text">bsd@ct.gov</span>
              </span>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <p className="sub-main-title-pdf">
            CERTIFICATE OF INCORPORATION -
            <span className="small-blue-text">
              Accessible Version Available
            </span>
          </p>
          <p className="normal-texttt">
            STOCK CORPORATION -
            <span className="sub-normal-text">
              USE INK. PRINT OR TYPE. ATTACH 8 1/2” X 11” SHEETS IF NECESSARY
            </span>{" "}
          </p>
        </div>
        <div className="blk-border">
          <Row>
            <Col xl={8} lg={8} sm={8} xs={12}>
              <div>
                <p>FILING PARTY (CONFIRMATION WILL BE SENT TO THIS ADDRESS):</p>
                <div className="d-flex">
                  <label className="me-3">NAME:</label>
                  <input type="text" placeholder="$" value={Inc_Name} />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">ADDRESS:</label>
                  <div>
                    <input type="text" placeholder="$" value={Inc_Address_Line_1} />
                    <input
                      type="text"
                      placeholder="$"
                      className="mt-3"
                      value={Inc_Address_Line_2}
                    />
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">CITY:</label>
                  <input type="text" placeholder="$" value={Inc_City} />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STATE:</label>
                  <input type="text" placeholder="$" value={Inc_Zip_Code} />
                  <div className="d-flex">
                    <label className="me-3 ms-3">ZIP CODE:</label>
                    <input type="text" placeholder="$" value={Inc_Zip_Code} />
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">EMAIL:</label>
                  <input type="email" placeholder="$" value={Inc_Email_Address} />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">TELEPHONE NUMBER:</label>
                  <input type="tel" placeholder="$" value={Inc_Contact_No} />
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} sm={4} xs={12}>
              <div className="main-div-filling">
                <div className="text-center">
                  <h2 className="sub-main-title-pdf">Filing Fee: $120</h2>
                  <span className="sub-normal-text">
                    Includes franchise tax up to 20,000 shares
                  </span>
                  <p className="sub-normal-text">
                    Make checks payable to“Secretary of the State”
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <Row>
            <Col xl={12} lg={12} sm={12} xs={12}>
              <div>
                <p className="sub-main-title-pdf">
                  1.NAME OF CORPORATION (required)
                  <span className="normal-texttt">
                    (Must include business designation, e.g., Inc., Co., Corp.)
                  </span>
                </p>
                <input type="text" placeholder="$" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <Row>
            <Col xl={8} lg={8} sm={12} xs={12}>
              <div>
                <p className="sub-main-title-pdf">
                  2.TOTAL NUMBER OF AUTHORIZED SHARES:
                </p>
                <input type="text" value={SI_Number_Of_Shares} />
              </div>
            </Col>
            <div className=" blk-border mt-4 mb-4 ">
              <span className="normal-texttt">
                If the corporation has more than one class of shares, it must
                designate each class and the number of shares authorized within
                each class below.
              </span>
            </div>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <h2 className="sub-main-title-pdf">CLASS</h2>
              <input
                type="text"
                placeholder="$"
                className="mt-3"
                value={class_1}
              />
              <input
                type="text"
                placeholder="$"
                className="mt-3"
                value={class_2}
              />
              <input
                type="text"
                placeholder="$"
                className="mt-3"
                value={class_3}
              />
              <input
                type="text"
                placeholder="$"
                className="mt-3"
                value={class_4}
              />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className="main-class-left-border ps-4">
                <h2 className="sub-main-title-pdf">
                  NUMBER OF SHARES PER CLASS:
                </h2>
                <input
                  type="text"
                  placeholder="$"
                  className="mt-3"
                  value={class_6}
                />
                <input
                  type="text"
                  placeholder="$"
                  className="mt-3"
                  value={class_7}
                />
                <input
                  type="text"
                  placeholder="$"
                  className="mt-3"
                  value={class_8}
                />
                <input
                  type="text"
                  placeholder="$"
                  className="mt-3"
                  value={class_9}
                />
              </div>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="main-class-top-border ps-4">
                <h2 className="sub-main-title-pdf mt-4">
                  3. IF MORE THAN ONE CLASS OF SHARES, SET FORTH THE TERMS,
                  LIMITATIONS, RELATIVE RIGHTS AND PREFERENCES OF EACH CLASS OF
                  SHARES AND SERIES THEREOF PURSUANT TO CONN. GEN. STAT. SECTION
                  33-665:
                </h2>
                <input type="text" placeholder="$" className="mt-3" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <Row>
            <Col xl={8} lg={8} sm={12} xs={12}>
              <div>
                <p className="sub-main-title-pdf">
                  3.MAILING ADDRESS
                  <span className="normal-texttt">
                    (Required - Provide full address. P.O. Box acceptable.): –
                  </span>{" "}
                </p>
                <input type="text" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STREET OR P.O. BOX:</label>
                <div>
                  <input type="text" placeholder="$" />
                  <input type="text" placeholder="$" className="mt-3" />
                </div>
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">CITY:</label>
                <input type="text" placeholder="$" value={Inc_City} />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STATE:</label>
                <input type="text" placeholder="$" value={Inc_State} />
                <div className="d-flex">
                  <label className="me-3 ms-3">ZIP CODE:</label>
                  <input type="text" placeholder="$" value={Inc_Zip_Code} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <p className="blkk-bgg">
            NOTE: COMPLETE EITHER 4A OR 4B ON THE FOLLOWING PAGE, NOT BOTH
          </p>
          <Row>
            <Col xl={8} lg={8} sm={12} xs={12}>
              <div>
                <p className="sub-main-title-pdf">
                  4.APPOINTMENT OF REGISTERED AGENT
                  <span className="normal-texttt">(Required):</span>{" "}
                </p>
              </div>
              <div>
                <label>
                  If Agent is an individual, print or type full legal name:
                </label>
                <input type="text" placeholder="$" value={RA_Name} />
              </div>
              <div className="mt-3">
                <label>Signature accepting appointment</label>
                <input type="text" placeholder="$" value={signature} />
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <Row>
            <Col xl={6} lg={6} sm={12} xs={12}>
              <div className="d-flex justify-content-between">
                <p className="sub-main-title-pdf">
                  BUSINESS ADDRESS
                  <span className="normal-texttt ms-2">
                    (Required - No P.O. Box):
                  </span>
                </p>
                <div>
                  <Form.Check label="CHECK BOX IF NONE:" />
                </div>
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STREET:</label>
                <input type="text" placeholder="$" value={Inc_Address_Line_1} />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">CITY:</label>
                <input type="text" placeholder="$" value={Inc_City} />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STATE:</label>
                <input type="text" placeholder="$" value={Inc_State} />
                <div className="d-flex">
                  <label className="me-3 ms-3">ZIP CODE:</label>
                  <input type="text" placeholder="$" value={Inc_Zip_Code} />
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} sm={12} xs={12}>
              <div className="main-class-left-border ps-4">
                <div>
                  <p className="sub-main-title-pdf">
                    CONNECTICUT RESIDENCE ADDRESS
                    <span className="normal-texttt ms-2">
                      (Required - No P.O. Box):
                    </span>{" "}
                  </p>
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STREET</label>
                  <input type="text" placeholder="$" value={Inc_Address_Line_1} />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">CITY:</label>
                  <input type="text" placeholder="$" value={Inc_City} />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STATE:</label>
                  <input type="text" placeholder="$" value={Inc_State} />
                  <div className="d-flex">
                    <label className="me-3 ms-3">ZIP CODE:</label>
                    <input type="text" placeholder="$" value={Inc_Zip_Code} />
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={12} lg={12} sm={12} xs={12}>
              <div className="main-class-top-border">
                <div className="mt-3">
                  <p className="sub-main-title-pdf">
                    CONNECTICUT MAILING ADDRESS (Required):
                  </p>
                  <span className="normal-texttt ms-2">
                    (P.O. Box is acceptable):
                  </span>{" "}
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STREET: OR P.O. Box:</label>
                  <input type="text" placeholder="$" value={street_4} />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">CITY:</label>
                  <input type="text" placeholder="$" value={city_5} />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STATE:</label>
                  <input type="text" placeholder="$" value={state_5} />
                  <div className="d-flex">
                    <label className="me-3 ms-3">ZIP CODE:</label>
                    <input type="text" placeholder="$" value={zipcode_6} />
                  </div>
                </div>
              </div>
            </Col>
            <p className="blkk-bgg">
              NOTE: DO NOT COMPLETE 6B IF AGENT APPOINTED IN 6A ABOVE.
            </p>
            <h5>If Agent is a business,</h5>
            <Col xl={12} lg={12} sm={12} xs={12}>
              <div className="d-flex mt-3">
                <label className="me-3">
                  print or type name of business as it appears on our records:
                </label>
                <input type="text" placeholder="$" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">
                  Signature accepting appointment on behalf of agent:
                </label>
                <input type="text" placeholder="$" />
              </div>
            </Col>
            <Col xl={6} lg={6} sm={12} xs={12}>
              <div className="d-flex justify-content-between">
                <p className="sub-main-title-pdf">
                  CONNECTICUT BUSINESS ADDRESS (required)
                  <span className="normal-texttt ms-2">
                    (P.O. Box unacceptable)
                  </span>
                </p>
                <div>
                  <Form.Check label="CHECK BOX IF NONE:" />
                </div>
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STREET:</label>
                <input type="text" placeholder="$" value={street} />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">CITY:</label>
                <input type="text" placeholder="$" value={city_3} />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STATE:</label>
                <input type="text" placeholder="$" value={state_3} />
                <div className="d-flex">
                  <label className="me-3 ms-3">ZIP CODE:</label>
                  <input type="text" placeholder="$" value={zipcode_3} />
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} sm={12} xs={12}>
              <div className="main-class-left-border ps-4">
                <div>
                  <p className="sub-main-title-pdf">
                    CONNECTICUT MAILING ADDRESS (required):
                    <span className="normal-texttt ms-2">
                      ((P.O. Box unacceptable)):
                    </span>{" "}
                  </p>
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STREET</label>
                  <input type="text" placeholder="$" value={street_3} />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">CITY:</label>
                  <input type="text" placeholder="$" value={city_4} />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STATE:</label>
                  <input type="text" placeholder="$" value={state_4} />
                  <div className="d-flex">
                    <label className="me-3 ms-3">ZIP CODE:</label>
                    <input type="text" placeholder="$" value={zipcode_5} />
                  </div>
                </div>
              </div>
            </Col>
            <div className="main-class-top-border mt-3">
              <p className="sub-main-title-pdf mt-3">
                7.ELECTION OF BENEFIT CORPORATION STATUS (MUST check box if
                applicable):
              </p>
              <Col xl={11} lg={11} sm={12} xs={10} className="ms-3">
                <div className="d-flex">
                  <FormCheck />
                  <p>
                    The Corporation elects to be a Benefit Corporation. In
                    addition to any other stated purposes for which the
                    corporation is formed, the corporation shall also have the
                    purpose to create a general public benefit as defined in the
                    Connecticut Benefit Corporation Act. [NOTE: If the
                    corporation also seeks to have one or more specific public
                    benefit(s) in addition to the general public benefit, then
                    the corporation must set forth the specific public
                    benefit(s), if any, in Box 8, below, under “Other
                    Provisions.”]
                  </p>
                </div>
              </Col>
            </div>
            <div className="main-class-top-border mt-3">
              <p className="sub-main-title-pdf mt-3">
                8.OTHER PROVISIONS:
                <input type="text" />
              </p>
            </div>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <p className="blkk-bgg">
            NOTE: DO NOT COMPLETE 6B IF AGENT APPOINTED IN 6A ABOVE.
          </p>
          <h5>If Agent is a business,</h5>
          <Row>
            <Col xl={12} lg={12} sm={12} xs={12}>
              <div className="d-flex mt-3">
                <label className="me-3">
                  print or type name of business as it appears on our records:
                </label>
                <input type="text" placeholder="$" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">
                  Signature accepting appointment on behalf of agent:
                </label>
                <input type="text" placeholder="$" />
              </div>
            </Col>
            <Col xl={6} lg={6} sm={12} xs={12}>
              <div className="d-flex justify-content-between">
                <p className="sub-main-title-pdf">
                  CONNECTICUT BUSINESS ADDRESS (required)
                  <span className="normal-texttt ms-2">
                    (P.O. Box unacceptable)
                  </span>
                </p>
                <div>
                  <Form.Check label="CHECK BOX IF NONE:" />
                </div>
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STREET:</label>
                <input type="text" placeholder="$" value={street} />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">CITY:</label>
                <input type="text" placeholder="$" value={city_3} />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STATE:</label>
                <input type="text" placeholder="$" value={state_3} />
                <div className="d-flex">
                  <label className="me-3 ms-3">ZIP CODE:</label>
                  <input type="text" placeholder="$" value={zipcode_3} />
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} sm={12} xs={12}>
              <div className="main-class-left-border ps-4">
                <div>
                  <p className="sub-main-title-pdf">
                    CONNECTICUT MAILING ADDRESS (required):
                    <span className="normal-texttt ms-2">
                      ((P.O. Box unacceptable)):
                    </span>{" "}
                  </p>
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STREET</label>
                  <input type="text" placeholder="$" value={street_3} />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">CITY:</label>
                  <input type="text" placeholder="$" value={city_4} />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STATE:</label>
                  <input type="text" placeholder="$" value={state_4} />
                  <div className="d-flex">
                    <label className="me-3 ms-3">ZIP CODE:</label>
                    <input type="text" placeholder="$" value={zipcode_5} />
                  </div>
                </div>
              </div>
            </Col>
            <div className="main-class-top-border mt-3">
              <p className="sub-main-title-pdf mt-3">
                7.ELECTION OF BENEFIT CORPORATION STATUS (MUST check box if
                applicable):
              </p>
              <Col xl={11} lg={11} sm={12} xs={10} className="ms-3">
                <div className="d-flex">
                  <FormCheck />
                  <p>
                    The Corporation elects to be a Benefit Corporation. In
                    addition to any other stated purposes for which the
                    corporation is formed, the corporation shall also have the
                    purpose to create a general public benefit as defined in the
                    Connecticut Benefit Corporation Act. [NOTE: If the
                    corporation also seeks to have one or more specific public
                    benefit(s) in addition to the general public benefit, then
                    the corporation must set forth the specific public
                    benefit(s), if any, in Box 8, below, under “Other
                    Provisions.”]
                  </p>
                </div>
              </Col>
            </div>
            <div className="main-class-top-border mt-3">
              <p className="sub-main-title-pdf mt-3">8.OTHER PROVISIONS:</p>
              <input type="text" />
            </div>
          </Row>
        </div>

        {/* Second Page */}
        <div className="mt-5">
          <div className="d-flex">
            <div>
              <img src={logo} className="logo-img-pdf me-3" />
            </div>
            <div className="mt-4">
              <h2 className="main-title-pdf">
                Secretary of the State of Connecticut
              </h2>
              <div className="d-flex">
                <span className="blkk-text-big me-2">
                  Phone: <span className="blue-big-text">860-509-6003</span>
                </span>
                <span className="blkk-text-big me-2">
                  {" "}
                  Website:{" "}
                  <span className="blue-big-text">business.ct.gov</span>{" "}
                </span>
                <span className="blkk-text-big me-2">
                  Email: <span className="blue-big-text">bsd@ct.gov</span>
                </span>
              </div>
            </div>
          </div>
          <div className="blk-border mt-3">
            <p className="sub-main-title-pdf mt-3">
              9.EXECUTION: CERTIFICATE MUST BE SIGNED BY EACH INCORPORATOR
            </p>
            <Col xl={6}>
              <div className="d-flex mt-3">
                <label className="me-3">Date ( mm / dd / yyyy )</label>
                <input type="date" placeholder="$" />
              </div>
            </Col>
            <div>
              <Table bordered>
                <thead>
                  <tr>
                    <th>
                      NAME OF INCORPORATOR(S) <span>(print or type)</span>
                    </th>
                    <th>ADDRESS</th>
                    <th>SIGNATURE(S)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input type="text" placeholder="$" />
                    </td>
                    <td>
                      <div>
                        <div className="d-flex mt-3">
                          <label className="me-3">ADDRESS:</label>
                          <div>
                            <input
                              type="text"
                              placeholder="$"
                              value={Inc_Address_Line_1}
                            />
                            <input
                              type="text"
                              placeholder="$"
                              className="mt-3"
                              value={Inc_Address_Line_2}
                            />
                          </div>
                        </div>
                        <div className="d-flex mt-3">
                          <label className="me-3">STREET:</label>
                          <input type="text" placeholder="$" value={street} />
                        </div>
                        <div className="d-flex mt-3">
                          <label className="me-3">CITY:</label>
                          <input type="text" placeholder="$" value={Inc_City} />
                        </div>
                        <div className="d-flex mt-3">
                          <label className="me-3">STATE:</label>
                          <input type="text" placeholder="$" value={Inc_State} />
                          <div className="d-flex">
                            <label className="me-3 ms-3">ZIP CODE:</label>
                            <input
                              type="text"
                              placeholder="$"
                              value={Inc_Zip_Code}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <input type="text" placeholder="$" />
                    </td>
                    <td>
                      <div>
                        <div className="d-flex mt-3">
                          <label className="me-3">ADDRESS:</label>
                          <div>
                            <input
                              type="text"
                              placeholder="$"
                              value={Inc_Address_Line_2}
                            />
                            <input
                              type="text"
                              placeholder="$"
                              className="mt-3"
                              value={Inc_Address_Line_2}
                            />
                          </div>
                        </div>
                        <div className="d-flex mt-3">
                          <label className="me-3">STREET:</label>
                          <input type="text" placeholder="$" value={street} />
                        </div>
                        <div className="d-flex mt-3">
                          <label className="me-3">CITY:</label>
                          <input type="text" placeholder="$" value={Inc_City} />
                        </div>
                        <div className="d-flex mt-3">
                          <label className="me-3">STATE:</label>
                          <input type="text" placeholder="$" value={Inc_State} />
                          <div className="d-flex">
                            <label className="me-3 ms-3">ZIP CODE:</label>
                            <input
                              type="text"
                              placeholder="$"
                              value={Inc_Zip_Code}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>
                      <input type="text" placeholder="$" />
                    </td>
                    <td>
                      <div>
                        <div className="d-flex mt-3">
                          <label className="me-3">ADDRESS:</label>
                          <div>
                            <input
                              type="text"
                              placeholder="$"
                              value={Inc_Address_Line_1}
                            />
                            <input
                              type="text"
                              placeholder="$"
                              className="mt-3"
                              value={Inc_Address_Line_2}
                            />
                          </div>
                        </div>
                        <div className="d-flex mt-3">
                          <label className="me-3">STREET:</label>
                          <input type="text" placeholder="$" value={street} />
                        </div>
                        <div className="d-flex mt-3">
                          <label className="me-3">CITY:</label>
                          <input type="text" placeholder="$" value={Inc_City} />
                        </div>
                        <div className="d-flex mt-3">
                          <label className="me-3">STATE:</label>
                          <input type="text" placeholder="$" value={Inc_State} />
                          <div className="d-flex">
                            <label className="me-3 ms-3">ZIP CODE:</label>
                            <input
                              type="text"
                              placeholder="$"
                              value={Inc_Zip_Code}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>

        {/* Third Page  */}
        <div className="mt-5">
          <div className="blk-border mt-3">
            <div className="text-center">
              <h5 className="main-title-pdf">INSTRUCTIONS FOR COMPLETION OF THE</h5>
              <h5 className="main-title-pdf">CERTIFICATE OF INCORPORATION — STOCK CORPORATION</h5>
            </div>
            <div>
              <p className="sub-main-title-pdf">INSTRUCTIONS</p>
            </div>
            <ul>
              <li className="normal-texttt mt-3">
                1. NAME OF CORPORATION: Provide the name of the corporation. The
                name of the corporation must contain one of the following
                designations: “corporation,” “incorporated,” “company,” “Societa
                per Azioni,” or “limited,” or the abbreviation “corp.”, “inc.”,
                “co.”, “S.p.A.”, or “ltd.”, or words or abbreviations of like
                import in another language. The name must also be
                distinguishable from other business names on the records of the
                Secretary of the State.
              </li>
              <li className="normal-texttt mt-3">
                2.TOTAL NUMBER OF AUTHORIZED SHARES: Provide the total number of
                shares the corporation is authorized to issue. Refer to Fee
                Schedule for amount due if over 20,000 shares
              </li>
              <li className="normal-texttt mt-3">
                3.TERMS, LIMITATIONS, RELATIVE RIGHTS AND PREFERENCES OF EACH
                CLASS OF SHARES AND SERIES THEREOF PURSUANT TO CONN. GEN. STAT.
                SECTION 33-665: Please set forth all information required by
                section 33-665 as amended for each class of stock authorized in
                item number 3
              </li>
              <li className="normal-texttt mt-3">
                4.CORPORATION EMAIL ADDRESS (If none, select “NONE”): The
                Secretary of the State must notify entities via email when their
                Annual Reports are due
              </li>
              <li className="normal-texttt mt-3">
                5.NAICS CODE: go to www.census.gov/naics , or call
                1-888-756-2427. (business / occupation / profession code)
              </li>
              <li className="normal-texttt mt-3">
                6.APPOINTMENT OF REGISTERED AGENT: The corporation may appoint
                either a natural person who is a resident of Connecticut; a
                Connecticut corporation, limited liability company, limited
                liability partnership, or statutory trust; or a foreign
                corporation, limited liability company, limited liability
                partnership or statutory trust that is registered to transact
                business in Connecticut and has a Connecticut address. Please
                note the following: (A) if the agent being appointed is a
                natural person, that person’s business address must be provided
                under the heading Business Address; their residence address
                under the heading Residence Address and the Connecticut mailing
                address under the heading Connecticut Mailing Address. (B) If
                the agent appointed is a business, it must provide its principal
                office under the Business Address heading and the Connecticut
                mailing address under the heading Connecticut Mailing Address.
                The agent must sign accepting the appointment in the space
                provided. The signatory must print their full name and the
                capacity under which they sign if signing on behalf of a
                business; the corporation may not appoint itself as its
                registered agent, and; all addresses must include a street
                number, street name, city, state, postal code except the
                Connecticut mailing address
              </li>
              <li className="normal-texttt mt-3">
                7.ELECTION OF BENEFIT CORPORATION STATUS: This box must be
                checked if the corporation elects to be a Benefit Corporation
                under the Connecticut Benefit Corporation Act. If the Benefit
                Corporation has one or more specific public benefit(s), in
                addition to the required general public benefit, then specific
                public benefits must be set forth in Box 8 “OTHER PROVISIONS,”
                below Box 7 on the form
              </li>
              <li className="normal-texttt mt-3">
                8.OTHER PROVISIONS: Please present in the space provided or on
                an attachment any information which a stock corporation is
                permitted but not required to provide. If the corporation is a
                Benefit Corporation, any specific public benefits (beyond the
                required general public benefit) must be listed here
              </li>
              <li className="normal-texttt mt-3 mb-4">
                9.EXECUTION: The document must be executed / signed by each
                incorporator, each of whom must provide an address containing a
                street and number, city, state and a postal code. The execution
                constitutes legal statement under the penalties of false
                statement that the information provided in the document is true.
              </li>
            </ul>
          </div>
        </div>

        {/* Fourth Page */}
        <div className="mt-5">
          <div className="blk-border mt-3">
            <div className="text-center">
              <h5 className="main-title-pdf mt-5 mb-5">INCORPORATION OF A CONNECTICUT STOCK CORPORATION</h5>
            </div>
            <div>
              <p className="normal-texttt">
                We are pleased to enclose the form to incorporate a stock
                corporation in the State of Connecticut. Note that an
                Organization and First Report form must also be filed to record
                the addresses, officers and directors of the corporation. The
                fees for filing the Organization and First Report can be found
                on the fee schedule.
              </p>
              <p className="normal-texttt">
                The filing of the above referenced documents represent the bare
                essentials of incorporation in Connecticut. There are many other
                considerations to take into account when forming a corporation.
                For this reason, we recommend that an attorney and/or other
                competent advisor be consulted. Please contact the Department of
                Revenue Services or your tax advisor as to any potential tax
                liability relating to your business.
              </p>
              <p className="normal-texttt">
                The organization and first report must be filed within 90 days
                from the filing of the Certificate of Incorporation.
              </p>
              <p className="normal-textttmt-5 mb-5">
                Make checks payable to “The Secretary of the State.”
              </p>
              <div>
                <div>
                  <h3 className="sub-main-title-pdf mt-5 mb-5">
                    OFFICE OF THE SECRETARY OF THE STATE
                  </h3>
                  <p className="sub-main-title-pdf">MAILING ADDRESS:</p>
                  <p className="normal-texttt">
                    BUSINESS SERVICES DIVISION <br />
                    CONNECTICUT SECRETARY OF THE STATE <br />
                    P.O. BOX 150470 <br /> HARTFORD, CT 06115-0470
                  </p>
                  <p className="sub-main-title-pdf">DELIVERY ADDRESS:</p>
                  <p className="normal-texttt">
                    BBUSINESS SERVICES DIVISION <br /> CONNECTICUT SECRETARY OF
                    THE STATE
                    <br />
                    P.O. BOX 150470 <br /> HARTFORD, CT 06115-0470{" "}
                  </p>
                </div>
                <p className="sub-main-title-pdf">
                  PHONE: <span className="blue-big-text">860-509-6003</span>
                </p>
                <p className="sub-main-title-pdf">
                  WEBSITE:{" "}
                  <span className="blue-big-text">business.ct.gov</span>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-content-center p-2">
        <Button
          className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
          label="Download PDF"
          icon="pi pi-download"
          onClick={downloadPDF}
        ></Button>
      </div>
    </section>
  );
};

export default ConnecticutCorp;
