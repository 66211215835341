// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-modal-compnent .modal-header {
  background: #f0f5ff;
}

.profile-modal-compnent .modal-title {
  color: #101828 !important;
  font-size: 20px;
}

.profile-modal-compnent .header-subititle {
  font-size: 14px;
  color: #667085;
  margin-bottom: 0;
  font-weight: 400;
}

.profile-modal-compnent .section-title {
  color: #101828;
  font-size: 18px;
  margin-bottom: 0;
}

.profile-modal-compnent .star-img {
  color: red;
  margin-right: 5px;
}

.profile-modal-compnent .form-control {
  font-size: 14px;
}


.profile-modal-compnent .form-label {
    font-size: 14px;
  }`, "",{"version":3,"sources":["webpack://./src/components/ProfileModalComponent/ProfileModalComponent.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;;AAGA;IACI,eAAe;EACjB","sourcesContent":[".profile-modal-compnent .modal-header {\n  background: #f0f5ff;\n}\n\n.profile-modal-compnent .modal-title {\n  color: #101828 !important;\n  font-size: 20px;\n}\n\n.profile-modal-compnent .header-subititle {\n  font-size: 14px;\n  color: #667085;\n  margin-bottom: 0;\n  font-weight: 400;\n}\n\n.profile-modal-compnent .section-title {\n  color: #101828;\n  font-size: 18px;\n  margin-bottom: 0;\n}\n\n.profile-modal-compnent .star-img {\n  color: red;\n  margin-right: 5px;\n}\n\n.profile-modal-compnent .form-control {\n  font-size: 14px;\n}\n\n\n.profile-modal-compnent .form-label {\n    font-size: 14px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
