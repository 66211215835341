import { Checkbox } from "primereact/checkbox";
import React from "react";

const PaymentOption = ({ label, isSelected, onClick }) => (
  <div
    className={`flex flex-wrap gap-1 items-start px-4 py-3 w-full rounded-lg border border-solid max-md:max-w-full ${isSelected
      ? "text-blue-800 bg-sky-50 border-sky-300"
      : "bg-white border-gray-200 text-slate-700"
    }`}
    onClick={onClick} 
  >
    <div className="flex flex-1 shrink gap-4 items-start basis-0 min-w-[240px] max-md:max-w-full">
      <div className="flex-1 shrink w-full min-w-[240px] max-md:max-w-full">
        {label}
      </div>
    </div>
    <Checkbox checked={isSelected} />
  </div>
);

export default PaymentOption;
