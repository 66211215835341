import React, { useContext, useEffect, useState } from "react";
import NotificationBanner from "./NotificationBanner";
import WelcomeHeader from "./WelcomeHeader";
import DateRangeSelector from "./DateRangeSelector";
import StatCard from "./StatCard";
import Chart from "./Chart";
import dayjs from "dayjs";
import SubscriptionOverview from "./SubscriptionOverview";
import OrderOverview from "./OrderView";
import ActiveClientsMap from "./ActiveClient";
import { formatDate } from "../../../utils/dateFormat";
import { Dropdown } from "primereact/dropdown";
import UserServices from "../../../services/userService";
import authContext from "../../../common/authContext";
import OrderServices from "../../../services/orderServices";
import ClientSubscritpionTable from "../DataTable/ClientSubscritpion/ClientSubscritpionTable";
import ClintInvoicePaymentTable from "../DataTable/ClintInvoicePayment/ClintInvoicePaymentTable";
import ClientSubscritpion from "../DataTable/ClientSubscritpion/ClientSubscritpion";
import ClintInvoicePayment from "../DataTable/ClintInvoicePayment/ClintInvoicePayment";
import { Button } from "primereact/button";

function Dashboard() {
  const {
    clearData,
    setFilterStatusId,
    setAccountManagerId,
    setEntityDash,
    setStateDash,
    setRedirectedData,
  } = useContext(authContext);

  const user = JSON.parse(localStorage.getItem("user"));

  const [dashboardData, setDashboardData] = useState([]);

  const [allAccountManager, setAllAccountManager] = useState([]);

  const [accountManagerDataId, setAccountManagerDataId] = useState(null);

  const [superAdminId, setSuperAdminId] = useState(null);

  const [managerId, setManagerId] = useState(null);

  const [activeButton, setActiveButton] = useState(3);

  const [endsDate, setEndDate] = useState(null);

  const [startsDate, setStartDate] = useState(null);

  function checkIdsExist(arr, idsToCheck) {
    const foundIds = idsToCheck.filter((idToCheck) =>
      arr.some((item) => item.id === idToCheck)
    );
    return foundIds.length > 0 ? foundIds : null;
  }

  useEffect(() => {
    if (user) {
      if (user.companyRoles !== undefined) {
        if (user.companyRoles.length > 0) {
          if (user.companyRoles[0].roles.length > 0) {
            const id = checkIdsExist(user.companyRoles[0].roles, [4]);
            console.log(id);
            setSuperAdminId(id);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    setEntityDash(null);
    setStateDash(null);
    setFilterStatusId(null);
    setRedirectedData(null);
    clearData();
    if (user) {
      UserServices.getEmployeeByUserId(user.id)
        .then((res) => {
          console.log(res);
          if (res.data) {
            setAccountManagerId(res.data.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    OrderServices.getAllOrderForDashbaord()
      .then((res) => {
        console.log(res);
        console.log(res.data);
        if (Array.isArray(res.data)) {
          setDashboardData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    UserServices.getEmployeeDetails()
      .then((res) => {
        console.log(res);
        let data = res.data;
        if (Array.isArray(data)) {
          setAllAccountManager(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log("user", user);
    if (user) {
      UserServices.getEmployeeByUserId(user.id)
        .then((res) => {
          console.log(res);
          if (res.data) {
            setManagerId(res.data.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  const [statCards, setStatCards] = useState([]);

  useEffect(() => {
    if (superAdminId && managerId) {
      const filteredData = managerId
        ? dashboardData.filter((item) => item.accountManagerId === managerId) // Assuming each item has an `accountManagerId`
        : dashboardData;
      const totalOrderAmountSum = filteredData.reduce(
        (acc, curr) => acc + (curr.totalOrderAmount || 0),
        0
      );

      const totalPaymentDueSum = filteredData.reduce(
        (acc, curr) => acc + (curr.totalPaymentDue || 0),
        0
      );

      const createdCount = filteredData.reduce(
        (acc, curr) => acc + (curr.createdCount || 0),
        0
      );
      const totalDrafts = filteredData.reduce(
        (acc, curr) => acc + (curr.draftCount || 0),
        0
      );
      const totalInReview = filteredData.reduce(
        (acc, curr) => acc + (curr.inReviewCount || 0),
        0
      );
      const totalInProgress = filteredData.reduce(
        (acc, curr) => acc + (curr.inProgressCount || 0),
        0
      ); // Assuming `inProgressCount` is a field in the data
      const totalCompleted = filteredData.reduce(
        (acc, curr) => acc + (curr.completedCount || 0),
        0
      );
      const totalApprovalPending = filteredData.reduce(
        (acc, curr) => acc + (curr.approvalPendingCount || 0),
        0
      );
      const payLaterAproveCount = filteredData.reduce(
        (acc, curr) => acc + (curr.payLaterApprovedCount || 0),
        0
      );
      const vstateQueryRaisedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.vstateQueryRaisedCount || 0),
        0
      );
      const payLaterRequestedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.payLaterRequestedCount || 0),
        0
      );
      const clientRespondCount = filteredData.reduce(
        (acc, curr) => acc + (curr.clientRespondedCount || 0),
        0
      );
      const resubmittedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.resubmittedCount || 0),
        0
      );
      const govtQueryRaisedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.govtQueryRaisedCount || 0),
        0
      );
      const payLaterRejectedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.payLaterRejectedCount || 0),
        0
      );
      const submittedOrders = dashboardData.reduce(
        (acc, curr) => acc + (curr.submittedCount || 0),
        0
      );
      const respondedToQuery = dashboardData.reduce(
        (acc, curr) => acc + (curr.respondedToQueryCount || 0),
        0
      );

      const totalOrders =
        totalInReview +
        totalInProgress +
        totalCompleted +
        totalApprovalPending +
        payLaterAproveCount +
        vstateQueryRaisedCount +
        clientRespondCount +
        payLaterRequestedCount +
        resubmittedCount +
        govtQueryRaisedCount +
        payLaterRejectedCount +
        createdCount +
        submittedOrders +
        respondedToQuery;

      const pendingOrders = filteredData.reduce(
        (acc, curr) => acc + (curr.approvalPendingCount || 0),
        0
      );

      // Set the stat cards
      setStatCards([
        { title: "Total Orders", value: totalOrders.toLocaleString() },

        {
          title: "Orders Amount",
          value: `$${totalOrderAmountSum.toLocaleString()}`,
        },
        // { title: 'Total Payment Due', value: `$${totalPaymentDueSum.toLocaleString()}` },
        { title: "Orders Completed", value: totalCompleted.toLocaleString() },
        {
          title: "Orders in Progress",
          value: totalInProgress.toLocaleString(),
        },
        { title: "Pending Orders", value: pendingOrders.toLocaleString() },
      ]);
    } else {
      const totalOrderAmountSum = dashboardData.reduce(
        (acc, curr) => acc + (curr.totalOrderAmount || 0),
        0
      );
      const createdCount = dashboardData.reduce(
        (acc, curr) => acc + (curr.createdCount || 0),
        0
      );
      const totalDrafts = dashboardData.reduce(
        (acc, curr) => acc + (curr.draftCount || 0),
        0
      );
      const totalInReview = dashboardData.reduce(
        (acc, curr) => acc + (curr.inReviewCount || 0),
        0
      );
      const totalInProgress = dashboardData.reduce(
        (acc, curr) => acc + (curr.inProgressCount || 0),
        0
      ); // Assuming `inProgressCount` is a field in the data
      const totalCompleted = dashboardData.reduce(
        (acc, curr) => acc + (curr.completedCount || 0),
        0
      );
      const totalApprovalPending = dashboardData.reduce(
        (acc, curr) => acc + (curr.approvalPendingCount || 0),
        0
      );
      const payLaterAproveCount = dashboardData.reduce(
        (acc, curr) => acc + (curr.payLaterApprovedCount || 0),
        0
      );
      const vstateQueryRaisedCount = dashboardData.reduce(
        (acc, curr) => acc + (curr.vstateQueryRaisedCount || 0),
        0
      );
      const payLaterRequestedCount = dashboardData.reduce(
        (acc, curr) => acc + (curr.payLaterRequestedCount || 0),
        0
      );
      const clientRespondCount = dashboardData.reduce(
        (acc, curr) => acc + (curr.clientRespondedCount || 0),
        0
      );
      const resubmittedCount = dashboardData.reduce(
        (acc, curr) => acc + (curr.resubmittedCount || 0),
        0
      );
      const govtQueryRaisedCount = dashboardData.reduce(
        (acc, curr) => acc + (curr.govtQueryRaisedCount || 0),
        0
      );
      const payLaterRejectedCount = dashboardData.reduce(
        (acc, curr) => acc + (curr.payLaterRejectedCount || 0),
        0
      );
      const submittedOrders = dashboardData.reduce(
        (acc, curr) => acc + (curr.submittedCount || 0),
        0
      );
      const respondedToQuery = dashboardData.reduce(
        (acc, curr) => acc + (curr.respondedToQueryCount || 0),
        0
      );

      const totalOrders =
        totalInReview +
        totalInProgress +
        totalCompleted +
        totalApprovalPending +
        payLaterAproveCount +
        vstateQueryRaisedCount +
        clientRespondCount +
        payLaterRequestedCount +
        resubmittedCount +
        govtQueryRaisedCount +
        payLaterRejectedCount +
        createdCount +
        submittedOrders +
        respondedToQuery;

      const pendingOrders = vstateQueryRaisedCount + govtQueryRaisedCount;

      // Set the stat cards
      setStatCards([
        { title: "Total Orders", value: totalOrders.toLocaleString() },

        {
          title: "Orders Amount",
          value: `$${totalOrderAmountSum.toLocaleString()}`,
        },
        // { title: 'Total Payment Due', value: `$${totalPaymentDueSum.toLocaleString()}` },
        { title: "Orders Completed", value: totalCompleted.toLocaleString() },
        {
          title: "Orders in Progress",
          value: totalInProgress.toLocaleString(),
        },
        { title: "Pending Orders", value: pendingOrders.toLocaleString() },
      ]);
    }
  }, [dashboardData, managerId]);

  const [orderOverviewItems, setOrderOverviewItems] = useState([]);

  console.log(superAdminId);
  console.log(managerId);
  useEffect(() => {
    // Calculate order overview items based on the selected account manager
    if (superAdminId && managerId) {
      const filteredData = managerId
        ? dashboardData.filter((item) => item.accountManagerId === managerId) // Assuming each item has an `accountManagerId`
        : dashboardData;

      const drafts = filteredData.reduce(
        (acc, curr) => acc + (curr.draftCount || 0),
        0
      );
      const createdCount = filteredData.reduce(
        (acc, curr) => acc + (curr.createdCount || 0),
        0
      );
      const underReview = filteredData.reduce(
        (acc, curr) => acc + (curr.inReviewCount || 0),
        0
      );
      const submittedOrders = filteredData.reduce(
        (acc, curr) => acc + (curr.submittedCount || 0),
        0
      );
      const approvalPending = filteredData.reduce(
        (acc, curr) => acc + (curr.approvalPendingCount || 0),
        0
      );
      const totalInProgress = filteredData.reduce(
        (acc, curr) => acc + (curr.inProgressCount || 0),
        0
      );
      const totalCompleted = filteredData.reduce(
        (acc, curr) => acc + (curr.completedCount || 0),
        0
      );
      const payLaterAproveCount = filteredData.reduce(
        (acc, curr) => acc + (curr.payLaterApprovedCount || 0),
        0
      );
      const vstateQueryRaisedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.vstateQueryRaisedCount || 0),
        0
      );
      const payLaterRequestedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.payLaterRequestedCount || 0),
        0
      );
      const clientRespondCount = filteredData.reduce(
        (acc, curr) => acc + (curr.clientRespondedCount || 0),
        0
      );
      const resubmittedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.resubmittedCount || 0),
        0
      );
      const govtQueryRaisedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.govtQueryRaisedCount || 0),
        0
      );
      const payLaterRejectedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.payLaterRejectedCount || 0),
        0
      );
      const respondedToQuery = filteredData.reduce(
        (acc, curr) => acc + (curr.respondedToQueryCount || 0),
        0
      );

      const totalOrdersPlaced =
        underReview +
        submittedOrders +
        approvalPending +
        totalInProgress +
        totalCompleted +
        payLaterAproveCount +
        vstateQueryRaisedCount +
        clientRespondCount +
        payLaterRequestedCount +
        resubmittedCount +
        govtQueryRaisedCount +
        payLaterRejectedCount +
        createdCount +
        respondedToQuery;
      const ordersInNeedOfAttention = clientRespondCount;

      setOrderOverviewItems([
        {
          id: null,
          title: `Orders placed (${totalOrdersPlaced.toLocaleString()} Total)`,
          value: `${ordersInNeedOfAttention}`,
          subtext: "in need of attention",
        },
        // { id: 7, title: 'Drafts', value: drafts.toLocaleString() },
        { id: 9, title: "Under review", value: underReview.toLocaleString() },
        {
          id: 14,
          title: "Order Created",
          value: createdCount.toLocaleString(),
        },
        {
          id: 8,
          title: "Submitted orders",
          value: submittedOrders.toLocaleString(),
        },
        {
          id: 1000,
          title: "Approval pending",
          value: payLaterRequestedCount.toLocaleString(),
        },
        {
          id: 15,
          title: "Order In Progress",
          value: totalInProgress.toLocaleString(),
        },
        {
          id: 1002,
          title: "Pay Later Approved",
          value: payLaterAproveCount.toLocaleString(),
        },
        {
          id: 1003,
          title: "Pay Later Rejected",
          value: payLaterRejectedCount.toLocaleString(),
        },
        {
          id: 13,
          title: "vState Query Raised",
          value: vstateQueryRaisedCount.toLocaleString(),
        },
        {
          id: 1005,
          title: "Govt. Query Raised",
          value: govtQueryRaisedCount.toLocaleString(),
        },
        {
          id: 1006,
          title: "Client Responded",
          value: clientRespondCount.toLocaleString(),
        },
        {
          id: 1004,
          title: "Responded To Query",
          value: respondedToQuery.toLocaleString(),
        },
        {
          id: 1007,
          title: "Orders Resubmitted",
          value: resubmittedCount.toLocaleString(),
        },
        {
          id: 11,
          title: "Order Completed",
          value: totalCompleted.toLocaleString(),
        },
      ]);
    } else {
      const filteredData = accountManagerDataId
        ? dashboardData.filter(
            (item) => item.accountManagerId === accountManagerDataId
          ) // Assuming each item has an `accountManagerId`
        : dashboardData;

      const drafts = filteredData.reduce(
        (acc, curr) => acc + (curr.draftCount || 0),
        0
      );
      const createdCount = filteredData.reduce(
        (acc, curr) => acc + (curr.createdCount || 0),
        0
      );
      const underReview = filteredData.reduce(
        (acc, curr) => acc + (curr.inReviewCount || 0),
        0
      );
      const submittedOrders = filteredData.reduce(
        (acc, curr) => acc + (curr.submittedCount || 0),
        0
      );
      const approvalPending = filteredData.reduce(
        (acc, curr) => acc + (curr.approvalPendingCount || 0),
        0
      );
      const totalInProgress = filteredData.reduce(
        (acc, curr) => acc + (curr.inProgressCount || 0),
        0
      );
      const totalCompleted = filteredData.reduce(
        (acc, curr) => acc + (curr.completedCount || 0),
        0
      );
      const payLaterAproveCount = filteredData.reduce(
        (acc, curr) => acc + (curr.payLaterApprovedCount || 0),
        0
      );
      const vstateQueryRaisedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.vstateQueryRaisedCount || 0),
        0
      );
      const payLaterRequestedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.payLaterRequestedCount || 0),
        0
      );
      const clientRespondCount = filteredData.reduce(
        (acc, curr) => acc + (curr.clientRespondedCount || 0),
        0
      );
      const resubmittedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.resubmittedCount || 0),
        0
      );
      const govtQueryRaisedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.govtQueryRaisedCount || 0),
        0
      );
      const payLaterRejectedCount = filteredData.reduce(
        (acc, curr) => acc + (curr.payLaterRejectedCount || 0),
        0
      );
      const respondedToQuery = filteredData.reduce(
        (acc, curr) => acc + (curr.respondedToQueryCount || 0),
        0
      );

      const totalOrdersPlaced =
        underReview +
        submittedOrders +
        approvalPending +
        totalInProgress +
        totalCompleted +
        payLaterAproveCount +
        vstateQueryRaisedCount +
        clientRespondCount +
        payLaterRequestedCount +
        resubmittedCount +
        govtQueryRaisedCount +
        payLaterRejectedCount +
        createdCount +
        respondedToQuery;
      const ordersInNeedOfAttention = clientRespondCount;

      setOrderOverviewItems([
        {
          id: null,
          title: `Orders placed (${totalOrdersPlaced.toLocaleString()} Total)`,
          value: `${ordersInNeedOfAttention}`,
          subtext: "in need of attention",
        },
        // { id: 7, title: 'Drafts', value: drafts.toLocaleString() },
        { id: 9, title: "Under review", value: underReview.toLocaleString() },
        {
          id: 14,
          title: "Order Created",
          value: createdCount.toLocaleString(),
        },
        {
          id: 8,
          title: "Submitted orders",
          value: submittedOrders.toLocaleString(),
        },
        {
          id: 1000,
          title: "Approval pending",
          value: payLaterRequestedCount.toLocaleString(),
        },
        {
          id: 15,
          title: "Order In Progress",
          value: totalInProgress.toLocaleString(),
        },
        {
          id: 1002,
          title: "Pay Later Approved",
          value: payLaterAproveCount.toLocaleString(),
        },
        {
          id: 1003,
          title: "Pay Later Rejected",
          value: payLaterRejectedCount.toLocaleString(),
        },
        {
          id: 13,
          title: "vState Query Raised",
          value: vstateQueryRaisedCount.toLocaleString(),
        },
        {
          id: 1005,
          title: "Govt. Query Raised",
          value: govtQueryRaisedCount.toLocaleString(),
        },
        {
          id: 1006,
          title: "Client Responded",
          value: clientRespondCount.toLocaleString(),
        },
        {
          id: 1004,
          title: "Responded To Query",
          value: respondedToQuery.toLocaleString(),
        },
        {
          id: 1007,
          title: "Orders Resubmitted",
          value: resubmittedCount.toLocaleString(),
        },
        {
          id: 11,
          title: "Order Completed",
          value: totalCompleted.toLocaleString(),
        },
      ]);
    }
  }, [dashboardData, accountManagerDataId, managerId]);

  const handleOrderWithDate = (monthsAgo, buttonId) => {
    // Current date (end month and end year)
    const endDate = dayjs();
    const endMonth = endDate.month() + 1;
    const endYear = endDate.year();

    const startDate = endDate.subtract(monthsAgo, "month");
    const startMonth = startDate.month() + 1;
    const startYear = startDate.year();

    setEndDate(endDate);
    setStartDate(startDate);

    OrderServices.getAllOrderForDashbaordByDate(
      startMonth,
      startYear,
      endMonth,
      endYear
    )
      .then((res) => {
        console.log(res);
        setDashboardData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setActiveButton(buttonId);
  };

  return (
    <main className="flex flex-col font-fam-for-all">
      <div className="z-10 shrink-0 ml-16 max-w-full h-px bg-gray-100 border border-gray-100 border-solid  w-[852px] max-md:mt-10" />
      <div className="flex overflow-hidden flex-col  mt-0 w-full bg-gray-50 pb-[40px] max-md:pb-24 max-md:mt-0 max-md:max-w-full">
        {/* <NotificationBanner /> */}
        <WelcomeHeader />
        <hr className=" w-full bg-gray-200 min-h-[1px] max-md:max-w-full" />
        <section className="md:flex-none xl:flex flex-wrap items-start mt-3 w-full max-md:max-w-full">
          <div className="flex flex-col flex-1 shrink items-center basis-0 min-w-[240px] max-md:max-w-full">
            <div className="flex gap-10 items-start px-6 max-w-full w-[1280px] max-md:px-5">
              <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full">
                <div className="flex flex-col w-full text-sm font-medium leading-none max-md:max-w-full">
                  <div className="flex flex-wrap gap-10 justify-between items-start w-full max-md:max-w-full">
                    <div className="flex items-start md:w-[50%] lg:w-[33%] shadow-sm text-slate-700 border border-gray-300 rounded-lg overflow-hidden align-items-center">
                      <button
                        className={`flex-1 px-4 py-2 ${
                          activeButton === 12
                            ? "vstate-split-button text-white"
                            : "bg-white text-slate-800 hover:bg-gray-100"
                        }  border-r`}
                        onClick={() => handleOrderWithDate(12, 12)}
                      >
                        12 months
                      </button>
                      <button
                        className={`flex-1 px-4 py-2 ${
                          activeButton === 6
                            ? "vstate-split-button text-white"
                            : "bg-white text-slate-800 hover:bg-gray-100"
                        }  border-r`}
                        onClick={() => handleOrderWithDate(6, 6)}
                      >
                        6 months
                      </button>
                      <button
                        className={`flex-1 px-4 py-2 ${
                          activeButton === 3
                            ? "vstate-split-button text-white"
                            : "bg-white text-slate-800 hover:bg-gray-100"
                        }  border-r`}
                        onClick={() => handleOrderWithDate(3, 3)}
                      >
                        3 months
                      </button>
                      <button
                        className={`flex-1 px-4 py-2 ${
                          activeButton === 1
                            ? "vstate-split-button text-white"
                            : "bg-white text-slate-800 hover:bg-gray-100"
                        } `}
                        onClick={() => handleOrderWithDate(1, 1)}
                      >
                        1 month
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap gap-3 mt-4 items-center w-full max-md:max-w-full">
                  {statCards.map((card, index) => (
                    <StatCard key={index} {...card} />
                  ))}
                </div>
                <Chart />
                <ActiveClientsMap startDate={startsDate} endDate={endsDate} />
                <div className="mt-4">
                  <ClintInvoicePayment />
                </div>
                <div className="mt-4">
                  <ClientSubscritpion />
                </div>
              </div>
            </div>
          </div>
          <aside className="flex flex-col min-w-[240px] w-[460px] max-md:max-w-full">
            <div className="flex gap-6 items-start pr-6 w-full max-md:pr-5 max-md:max-w-full">
              <div className="flex flex-1 shrink gap-8 items-start w-full basis-0 min-w-[240px] max-md:max-w-full">
                <div className="flex overflow-hidden flex-col flex-1 shrink w-full bg-white rounded-lg border border-gray-200 shadow-sm basis-0 min-w-[240px] max-md:max-w-full">
                  <header className="flex flex-col w-full bg-blue-50 max-md:max-w-full">
                    <div className="flex gap-4 items-start p-3 w-full max-md:px-5 max-md:max-w-full">
                      <div className="flex flex-1 shrink gap-4 items-center w-full basis-0 min-w-[240px]">
                        <div className="flex flex-col flex-1 shrink self-stretch my-auto w-full basis-0 min-w-[240px]">
                          <h2 className="text-2xl font-medium leading-loose text-gray-900 m-0">
                            Orders Snapshot
                          </h2>
                          <p className="mb-0 text-base leading-none text-gray-500">
                            {formatDate(new Date())}
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr className="w-full bg-gray-200 min-h-[1px] m-0 max-md:max-w-full" />
                  </header>
                  <div className="flex flex-col p-6 w-full font-medium max-md:px-5 max-md:max-w-full">
                    <nav className="flex items-start w-full text-sm leading-none whitespace-nowrap shadow-sm text-slate-700">
                      {superAdminId === null && (
                        <Dropdown
                          style={{
                            width: "100%",
                            height: "47px",
                          }}
                          value={accountManagerDataId}
                          onChange={(e) =>
                            setAccountManagerDataId(e.target.value)
                          }
                          options={allAccountManager}
                          optionValue="id"
                          optionLabel="firstName"
                          placeholder="Select Account Manager"
                          filter
                          resetFilterOnHide
                          className="dialog-form-input-field-wizard"
                          required
                        />
                      )}
                      {superAdminId === null && (
                        <span>
                          <Button
                            type="button"
                            // icon="pi pi-plus"
                            style={{ fontSize: "14px", height: "40px" }}
                            className=" ml-2 font-fam-for-all text-center text-slate-700 vstate-reverse-button"
                            onClick={() => setAccountManagerDataId(null)}
                          >
                            Clear
                          </Button>
                        </span>
                      )}
                    </nav>
                    <OrderOverview items={orderOverviewItems} />
                    {/* <SubscriptionOverview /> */}
                  </div>
                </div>
              </div>
            </div>
          </aside>
        </section>
      </div>
    </main>
  );
}

export default Dashboard;
