import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useContext, useEffect, useState } from 'react'
import OrderServices from '../../services/orderServices'
import MasterServices from '../../services/coreServices'
import authContext from '../../common/authContext'
import { useNavigate } from 'react-router-dom'
import { formatISODate } from '../../utils/dateUtils'

const CompanyOrders = ({ data }) => {

    const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh, redirectedData, setRedirectedData } = useContext(authContext);


    const [products, setProducts] = useState([

    ])

    const [selectedProduct, setSelectedProduct] = useState(null)

    const navigate = useNavigate()

    const [allStatus, setAllStatus] = useState([])

    useEffect(() => {
        MasterServices.getAllStatus()
            .then((res) => {
                if (res.data !== "") {
                    setAllStatus(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])


    const getStatus = (id) => {
        if (allStatus.length > 0) {
            const temp = allStatus.find((val) => val.id === id)
            if (temp) {
                return temp.statusName;
            }
            else {
                return null;
            }

        }
        else {
            return null;
        }
    }

    useEffect(() => {
        if (data) {
            OrderServices.getAllOrderByCompanyId(data.id)
                .then((res) => {
                    console.log(res)
                    if (res.data !== "") {
                        setProducts(res.data.ordersWithLineItems)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [data,isTabRefresh])


    const handleView = (rowData) => {
        console.log(rowData)
        setRedirectedData(rowData.orderDetails)
        navigate("/order")
    }

    const iconBodyTemplate = (rowdata) => {
        return (
            <>
                <button className="pi pi-eye-slash image-btn-icon" data-toggle="tooltip" data-placement="bottom" title="View Order" onClick={() => handleView(rowdata)} ></button>
                {/* <button className="pi pi-trash image-btn-icon-delete" data-toggle="tooltip" data-placement="bottom" title="Delete" ></button> */}
            </>
        )
    }

    const statusBody = (rowdata) => {
        console.log(rowdata)
        const temp = getStatus(rowdata.orderDetails.statusId)
        console.log(temp)
        return (
            <div className='badge-container'>
                <div className='badge-main grid' style={{ background: '#ECFDF3' }}>
                    <div className="col-12 flex align-items-center p-0">

                        <span className='badge-label ml-1' style={{ color: '#027A48' }}>{temp}</span>
                    </div>

                </div>
            </div>
        )
    }

    const orderBody = (rowdata) => {
        return (
            <p className=' srch-cand-text1' >{`${rowdata.orderDetails.orderId ? rowdata.orderDetails.orderId : rowdata.orderDetails.id}`}</p>

        )
    }

    const itemBody = (rowdata) => {
        console.log(rowdata.orderLineItems)
        if (Array.isArray(rowdata.orderLineItems)) {
            if (rowdata.orderLineItems.length>0) {
              return  rowdata.orderLineItems.map((val) => {
                    console.log(val.productListing.displayName)
                    return (
                        <div className='webapp-sm-size'>
                            {val.productListing.displayName}
                        </div>
                    )
                })
            }
          

        }
        return (
            <div></div>
        )
    }

    const orderdateBody=(rowdata)=>(
        <div className='webapp-sm-size'>
        {rowdata.orderDetails.orderDate?formatISODate(rowdata.orderDetails.orderDate):""}
    </div>
    )

    const tentativeFulfillmentDateBody=(rowdata)=>(
        <div className='webapp-sm-size'>
        {rowdata.orderDetails.tentativeFulfillmentDate?formatISODate(rowdata.orderDetails.tentativeFulfillmentDate):""}
    </div>
    )

    const onRowSelect = (rowData) => {
        console.log(rowData)
        // setRedirectedData(rowData.data)
        // navigate("/order")
    }

    return (
        <div>
            <DataTable value={products} scrollable
                dataKey='name' paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}
                className='font-fam-for-all datatable-sm'
                globalFilterFields={["djjddjjd"]}>
                <Column header="Order Number" body={orderBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                <Column field='orderDetails.orderDate' header="Order Date" body={orderdateBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                {/* <Column field='lineItemName' header="Items" body={itemBody} className='font-fam-for-all  webapp-sm-size'></Column> */}
                <Column field='orderDetails.tentativeFulfillmentDate' body={tentativeFulfillmentDateBody} header="Expected by" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                <Column field='orderDetails.statusId' header="Status" body={statusBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                <Column body={iconBodyTemplate} header="Action" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>


            </DataTable>
        </div>
    )
}

export default CompanyOrders;