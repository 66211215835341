import React from "react";
import { Modal } from "react-bootstrap";
import CommonTourBlue from "./../CommonTourComponent/CommonTourBlue";
// import CommonTourWhite from "CommonTourComponent/CommonTourWhite";
import "./../CommonStyleTour/CommonStyleTour.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";


import { Navigation } from "swiper/modules";
import CompanyTourModal from "./../CompanyTourModal";
import order_s1_d from "../../../Assets/Images/tour/order_s1_d.png";
import order_s9_d from "../../../Assets/Images/tour/order_s9_d.png";
import order_s6_d from "../../../Assets/Images/tour/order_s6_d.png";
import order_s4_d from "../../../Assets/Images/tour/order_s4_d.png";
import order_s2_d from "../../../Assets/Images/tour/order_s2_d.png";
import order_s11_d from "../../../Assets/Images/tour/order_s11_d.png";
import order_s5_d from "../../../Assets/Images/tour/order_s5_d.png";
import order_s7_d from "../../../Assets/Images/tour/order_s7_d.png";
import order_s10_d from "../../../Assets/Images/tour/order_s10_d.png";
import order_s3_d from "../../../Assets/Images/tour/order_s3_d.png";
import order_s8_d from "../../../Assets/Images/tour/order_s8_d.png";

const UserOrderTourModal = (props) => {
  const [modalsecondShow, setModalSecondShow] = React.useState(false);

  const handleStartClick = () => {
    setModalSecondShow(true);
    props.onHide();
  };
  return (
    <section>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="common-modal-class"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 1 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading"> Orders: Manage Your Orders</h4>
                  <p className="tour-text-holder">
                    This is your central hub for all{" "}
                    <span className="blkkk-textt">order management </span>{" "}
                    activities.
                  </p>
                </div>
                <div>
                  <img  loading="lazy" 
                  // src="https://strapi.redberyltest.in/uploads/order_s1_d_316615378a.png"
                  src={order_s1_d} 
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Create Order: </span>{" "}
                      Initiate a new order for company formation or other
                      services.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Export CSV: </span>{" "}
                      Download a spreadsheet of your order history for analysis
                      or record-keeping.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Delete All Drafts: </span>{" "}
                      Remove all unsaved draft orders from your account.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 2 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: List of Orders and Payment Option</h4>
                  <p className="tour-text-holder">
                  Access a comprehensive list of all your orders. Make payment or print your order details.
                  </p>
                </div>
                <div>
                  <img loading="lazy" 
                  // src="https://strapi.redberyltest.in/uploads/order_s2_d_84bf86f406.png" 
                  src={order_s2_d}
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Filtering: </span> Narrow
                      down your orders using filters for State, Order Type,
                      Status, Payment Status and keyword search.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Sorting: </span> Arrange
                      your order list by date, order ID, or other relevant
                      criteria for easy navigation.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Pay:  </span> 
                      Make a payment for your order.
                    </li>
                    <li>
                      <span className="blkkk-textt">  Print: </span> 
                      Take a printout for your order.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 3 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading"> Orders: Order Overview</h4>
                  <p className="tour-text-holder">
                    Dive deep into the specifics of any given order.
                  </p>
                </div>
                <div>
                  <img loading="lazy" 
                  // src="https://strapi.redberyltest.in/uploads/order_s3_d_b12cecb359.png" 
                  src={order_s3_d}
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      {/* <span className="blkkk-textt"> Order Summary: </span> View
                      order ID, type, date and a summary of the included
                      services.
                    </li>
                    <li>
                      <span className="blkkk-textt">View order </span> related
                      information like order id, order type, entity type, order
                      date and more. */}
                                            View order related information like order id, order type, entity type, order date and more.

                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 4 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading"> Orders: Order Details</h4>
                  <p className="tour-text-holder">
                    Dive deep into the specifics of any given order.
                  </p>
                </div>
                <div>
                  <img loading="lazy" 
                  // src="https://strapi.redberyltest.in/uploads/order_s4_d_38dded563e.png" 
                  src={order_s4_d}
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Stay updated on your order status and access the{" "}
                      <span className="blkkk-textt"> receipt </span>
                      for quick reference.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 5 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading"> Orders: Order Preview</h4>
                  <p className="tour-text-holder">
                    This section allows you to preview the order details.{" "}
                  </p>
                </div>
                <div>
                  <img loading="lazy" 
                  // src="https://strapi.redberyltest.in/uploads/order_s5_d_94e57ef8fe.png" 
                  src={order_s5_d}
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      View a detailed breakdown of{" "}
                      <span className="blkkk-textt">
                        order items and their prices.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 6 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading"> Orders: Company Details</h4>
                  <p className="tour-text-holder">
                    This section allows you to view the company details.
                  </p>
                </div>
                <div>
                  <img loading="lazy" 
                  // src="https://strapi.redberyltest.in/uploads/order_s6_d_1b24af247b.png" 
                  src={order_s6_d}
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Access key company details such as entity name, structure,
                      states, formation date and EIN.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 7 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: Contact</h4>
                  <p className="tour-text-holder">
                    This section allows you to view the contact details.
                  </p>
                </div>
                <div>
                  <img loading="lazy" 
                  // src="https://strapi.redberyltest.in/uploads/order_s7_d_0387334944.png" 
                  src={order_s7_d}
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Find{" "}
                      <span className="blkkk-textt">contact information</span>{" "}
                      for billing, shipping and order organizers.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 8 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: Documents</h4>
                  <p className="tour-text-holder">
                    This section allows you to view and download the documents
                    related to your order.
                  </p>
                </div>
                <div>
                  <img loading="lazy" 
                  // src="https://strapi.redberyltest.in/uploads/order_s8_d_fc86d529ad.png" 
                  src={order_s8_d}
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      {/* for billing, shipping and order organizers. Manage and
                      download all documents associated with your order. */}
                      Manage and download all documents associated with your order.

                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 9 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Orders: Communication History
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to view the communication history
                    related to your order.
                  </p>
                </div>
                <div>
                  <img loading="lazy" 
                  // src="https://strapi.redberyltest.in/uploads/order_s9_d_23cd27fd2c.png" 
                  src={order_s9_d}
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      View a complete timeline of communication related to the
                      order.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 10 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: Account Rep</h4>
                  <p className="tour-text-holder">
                    This section allows you to know the account rep details
                    associated with your order.
                  </p>
                </div>
                <div>
                  <img loading="lazy" 
                  // src="https://strapi.redberyltest.in/uploads/order_s10_d_1442604acf.png" 
                  src={order_s10_d}
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Easily connect with your assigned account rep for
                      assistance.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 11 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Orders: Invoice and Sales Receipt
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to view and download the invoice and
                    sales receipt of your order.
                  </p>
                </div>
                <div>
                  <img loading="lazy" 
                  // src="https://strapi.redberyltest.in/uploads/order_s11_d_6b66be9f1e.png" 
                    src={order_s11_d}
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Download your invoice and sales receipt in a single click.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Modal.Body>
      </Modal>
      <CompanyTourModal
        show={modalsecondShow}
        onHide={() => setModalSecondShow(false)}
      />
    </section>
  );
};

export default UserOrderTourModal;
