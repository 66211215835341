import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useContext, useEffect, useState } from 'react'
import companyMasterService from '../../services/companyMasterService'
import authContext from '../../common/authContext'
import EditCompanyMember from './EditCompanyComponents/EditKeyPersonnelType'

const PrimaryContact = ({data}) => {
    const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh } = useContext(authContext);

    const [products, setProducts] = useState([

    ])

    
    const [visible,setVisible]=useState(false)

    const [keyPersonalData,setKeyPersonnelData]=useState(null)

    const [primaryContactData,setPrimaryContactData]=useState([])


    console.log(primaryContactData)

    useEffect(() => {
        if (data) {
          setPrimaryContactData([data.primaryContactId])
        }

    }, [data,isTabRefresh,isLoading])

    const handleEdit=(rowdata)=>{
        console.log(rowdata)
        setVisible(true)
        setKeyPersonnelData(rowdata)
    }

    const iconBodyTemplate = (rowdata) => {
        return (
            <>
                <button className="pi pi-pencil image-btn-icon" data-toggle="tooltip" data-placement="bottom" title="Edit" onClick={()=>handleEdit(rowdata)}></button>
                {/* <button className="pi pi-trash image-btn-icon-delete" data-toggle="tooltip" data-placement="bottom" title="Delete" ></button> */}
            </>
        )
    }

    const contactBody=(rowdata)=>{
        return(
            <>
            <div >
                {`${rowdata.mobileNo}`}
            </div>
            </>
        )
    }
    return (
        <div>
            <DataTable value={primaryContactData} scrollable
                dataKey='name'
                className='font-fam-for-all datatable-sm'
                globalFilterFields={["djjddjjd"]}>
                <Column field='displayName' header="Name" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                <Column field='emailId' header="Email Address" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                <Column  header="Contact Info" body={contactBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                {/* <Column body={iconBodyTemplate} header="Action" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column> */}
            </DataTable>
            <EditCompanyMember visible={visible} setVisible={setVisible} data={keyPersonalData}/>

        </div>
    )
}

export default PrimaryContact