// for keypersonnal
export const getInitials = (name) => {
    if (!name) return "U";
    const nameArray = name.trim().split(/\s+/);
    const firstInitial = nameArray[0]?.[0]?.toUpperCase() || "";
    const secondInitial = nameArray[1]?.[0]?.toUpperCase();
    return secondInitial ? firstInitial + secondInitial : firstInitial.repeat(2);
};



export const commonGetInitials = (name) => {
    console.log(name,"dataaa")
    // Ensure name is a nonempty string
    if (typeof name !== "string" || !name.trim()) return "";
  
    // Split the name using regex to handle multiple spaces
    const nameArray = name.trim().split(/\s+/);
  
    // If there are at least two words, use the first and last words for initials.
    if (nameArray.length >= 2) {
      const firstNameInitial = nameArray[0].charAt(0).toUpperCase();
      const lastNameInitial = nameArray[nameArray.length - 1].charAt(0).toUpperCase();
      return firstNameInitial + lastNameInitial;
    }
  
    // Otherwise, duplicate the single initial.
    const initial = nameArray[0].charAt(0).toUpperCase();
    return initial + initial;
  };
  