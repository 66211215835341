import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { DataScroller } from "primereact/datascroller";
import { Rating } from "primereact/rating";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { Chips } from "primereact/chips";
import Modal from "react-bootstrap/Modal";
import { Checkbox } from "primereact/checkbox";
import { Avatar } from "primereact/avatar";
import { PiArrowClockwiseBold } from "react-icons/pi";
import { MultiSelect } from "primereact/multiselect";
import { Accordion, AccordionTab } from "primereact/accordion";
import { SplitButton } from "primereact/splitbutton";
import dengerimg from "../Assets/Images/Featured icon.png";

import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";

import { Dropdown } from "primereact/dropdown";

import { DataView } from "primereact/dataview";

import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView, TabPanel } from "primereact/tabview";
import { Sidebar } from "primereact/sidebar";
import { OverlayPanel } from "primereact/overlaypanel";
import authContext from "../common/authContext";
import { createSearchParams, useNavigate } from "react-router-dom";
import UserServices from "../services/userService";
import { RadioButton } from "primereact/radiobutton";
import Sidesbar from "../common/Sidesbar";
import Headers from "../common/Headers";
import ContactService from "../services/ContactService";
import AddCustomUser from "./AddComponents/AddCutsomUser";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import AddEmployee from "./AddComponents/AddEmployee";
import GroupDataTable from "./GroupDataTable";
import { Switch } from "@mui/material";
import errorMessages, { globalMessages } from "../utils/errorMessages";
import UserUpload from "./AddComponents/UserUpload";
import { Toast } from "primereact/toast";
import companyMasterService from "../services/companyMasterService";
import rolesMasterService from "../services/rolesMaster";
import { InputMask } from "primereact/inputmask";
import ForgotPasswordService from "../services/forgotPasswordService";
import AddressService from "../services/addressService";
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import Documents from "./Orders/Documents";
import OrderServices from "../services/orderServices";
import MasterServices from "../services/coreServices";
import ReactToPrint from "react-to-print";
import { useSearchParams } from "react-router-dom";
import { InputTextarea } from "primereact/inputtextarea";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import OrderChat from "../utils/orderFeedBackChat";
import AddOrderDocuments from "./AddComponents/AddOrderDocuments";
import { formatISODate } from "../utils/dateUtils";
import propertiesApp from "../utils/propertiesApp";
import OrderPreview from "./Orders/OrderPreview";
import CompanyKeyPersonnelDocuments from "./Company/KeyPersonnelDocuments";
import DateRangeSelector from "../utils/DateRangePicker";
import InvoiceTemplate from "../utils/InvoiceTemplate";
import SalesReciept from "../utils/SalesReceipt";
import SplitVstateButton from "../utils/SplitButton";
import OverlayButton from "../utils/OverlayButton";
import OrderInvoiceTemplate from "../utils/OrderInvoiceTemplate";
import jsPDF from "jspdf";
import { stateHtml } from "../utils/stateHtmlJson";
import SalesReciptInvoice from "../utils/SalesReciptInvoice";
import StatePdfComponent from "./StatePdfComponent";
import PaymentComponents from "./Payment/PaymentComponent";
import useTableData from "../redux/Reducers/useTableData";
import { showToast } from "../utils/toastUtils";
import ScrollToTop from "./ScrollToTop";
import CustomeFinalPrice from "./CustomeFinalPrice";
import PaymentDone from "./Website/SuccessfulModal/PaymentDoneModal";
import { commonGetInitials } from "../utils/getInitials";

import arizona_llc from "./../Assets/paper_state/arizona_llc.pdf";
import arizona_corp from "./../Assets/paper_state/arizona_corp.pdf";
import california_corp from "./../Assets/paper_state/california_corp.pdf";
import california_llc from "./../Assets/paper_state/california_llc.pdf";
import connecticut_corp from "./../Assets/paper_state/connecticut_corp.pdf";
import connecticut_llc from "./../Assets/paper_state/connecticut_llc.pdf";
import delaware_corp from "./../Assets/paper_state/delaware_corp.pdf";
import delaware_llc from "./../Assets/paper_state/delaware_llc.pdf";
import maine_corp from "./../Assets/paper_state/maine_corp.pdf";
import maine_llc from "./../Assets/paper_state/maine_llc.pdf";
import michigan_llc from "./../Assets/paper_state/michigan_llc.pdf";
import michigan_corp from "./../Assets/paper_state/michigan_corp.pdf";
import nevada_corp from "./../Assets/paper_state/nevada_corp.pdf";
import nevada_llc from "./../Assets/paper_state/nevada_llc.pdf";
import pennsylvania_llc from "./../Assets/paper_state/pennsylvania_llc.pdf";
import pennsylvania_corp from "./../Assets/paper_state/pennsylvania_corp.pdf";
import texas_corp from "./../Assets/paper_state/texas_corp.pdf";
import texas_llc from "./../Assets/paper_state/texas_llc.pdf";
import westvirginia_corp from "./../Assets/paper_state/westvirginia_corp.pdf";
import westvirginia_llc from "./../Assets/paper_state/westvirginia_llc.pdf";
import wisconsin_corp from "./../Assets/paper_state/wisconsin_corp.pdf";
import wisconsin_llc from "./../Assets/paper_state/wisconsin_llc.pdf";


export default function OrderDashboardMainPage() {
  const {
    clearData,
    permissions,
    loginUserName,
    globalCompany,
    filterOrderStatusId,
    setFilterStatusId,
    isTabRefresh,
    setIsTabRefresh,
    editPayloadData,
    setEditPayloadData,
    redirectedData,
    setRedirectedData,
  } = useContext(authContext);

  const {
    data: fetchedStateData,
    isLoading: stateLoading,
    isError: isStateError,
    error: stateError,
    refetch: reftechStateData,
  } = useTableData("state");

  const {
    data: fetchedStatusData,
    isLoading: statusLoading,
    isError: isStatusError,
    error: statusError,
    refetch: reftechStatusData,
  } = useTableData("statusMaster");

  const {
    data: fetchedOrderTypeData,
    isLoading: orderTypeLoading,
    isError: isOrderTypeError,
    error: orderTypeError,
    refetch: reftechOrderTypeData,
  } = useTableData("orderTypeMaster");

  const {
    data: fetchedTemplateData,
    isLoading: templateLoading,
    isError: isTemplateError,
    error: templateError,
    refetch: reftechTemplateData,
  } = useTableData("templateMaster");

  const [userdataParam] = useSearchParams();

  const [orderId, setOrderId] = useState(null);

  const orderid = userdataParam.get("orderid");

  const hasPermission = (permission) => permissions.includes(permission);

  let newObject = window.localStorage.getItem("user");

  let updatedUser = Object.assign({}, JSON.parse(newObject));

  const user = JSON.parse(localStorage.getItem("user"));

  const automationJSON = JSON.parse(localStorage.getItem("automation"));

  const [loginDetails, setLoginDetails] = useState([
    { key: "Password", value: "dhdhdhdhhdhd" },
  ]);

  const [filteredData, setFilteredData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const [sortName, setSortName] = useState(null);
  const [allcompanyManager, SetAllCompanyManager] = useState([]);
  const [companyManager, SetCompanyManager] = useState(null);
  console.log(companyManager, "companyManager");

  const [chipsData, setChipsData] = useState([]);

  const [chipsData1, setChipsData1] = useState([]);

  const [filterChipData, setFilterChipData] = useState([]);

  const [selectedData, setSelectedData] = useState([]);

  const [allCities, setAllCities] = useState([]);

  const [activeIndex, setActiveIndex] = useState(0);

  const [filteredInput, setFilteredInput] = useState("");

  const [allOrderStatus, setAllOrderStatus] = useState([]);

  const [managerData, setManagerData] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const orderStatusTemp = [
    {
      createdBy: null,
      creationDate: null,
      lastModifiedBy: null,
      lastModifiedDate: null,
      id: 7,
      statusName: "DRAFT",
      isActive: true,
      statusForId: {
        createdBy: null,
        creationDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        id: 3,
        statusForName: "ORDERS",
        statusForDesc: "Orders",
      },
      vstateEmp: null,
      vstateClient: null,
      hubspotId: null,
      vstateEmpId: [],
      vstateClientId: [],
    },
    {
      createdBy: null,
      creationDate: null,
      lastModifiedBy: null,
      lastModifiedDate: null,
      id: 14,
      statusName: "ORDER CREATED",
      isActive: true,
      statusForId: {
        createdBy: null,
        creationDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        id: 3,
        statusForName: "ORDERS",
        statusForDesc: "Orders",
      },
      vstateEmp: "[9]",
      vstateClient: null,
      hubspotId: 261516975,
      vstateEmpId: [9],
      vstateClientId: [],
    },

    {
      createdBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      creationDate: "2024-09-26T09:22:33.389+0000",
      lastModifiedBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      lastModifiedDate: "2024-09-26T09:54:34.809+0000",
      id: 1000,
      statusName: "PAY LATER REQUEST RAISED",
      isActive: true,
      statusForId: {
        createdBy: null,
        creationDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        id: 3,
        statusForName: "ORDERS",
        statusForDesc: "Orders",
      },
      vstateEmp: "[1002,1003]",
      vstateClient: null,
      hubspotId: 261516977,
      vstateEmpId: [1002, 1003],
      vstateClientId: [],
    },
    {
      createdBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      creationDate: "2024-09-26T09:28:57.313+0000",
      lastModifiedBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      lastModifiedDate: "2024-09-26T09:28:57.313+0000",
      id: 1002,
      statusName: "PAY LATER REQUEST APPROVED",
      isActive: true,
      statusForId: {
        createdBy: null,
        creationDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        id: 3,
        statusForName: "ORDERS",
        statusForDesc: "Orders",
      },
      vstateEmp: "[9]",
      vstateClient: null,
      hubspotId: 261516978,
      vstateEmpId: [9],
      vstateClientId: [],
    },
    {
      createdBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      creationDate: "2024-09-26T09:30:15.124+0000",
      lastModifiedBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      lastModifiedDate: "2024-09-26T09:56:04.910+0000",
      id: 1003,
      statusName: "PAY LATER REQUEST REJECTED",
      isActive: true,
      statusForId: {
        createdBy: null,
        creationDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        id: 3,
        statusForName: "ORDERS",
        statusForDesc: "Orders",
      },
      vstateEmp: "[1000]",
      vstateClient: null,
      hubspotId: 261516985,
      vstateEmpId: [1000],
      vstateClientId: [],
    },
    {
      createdBy: null,
      creationDate: null,
      lastModifiedBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      lastModifiedDate: "2024-09-26T10:01:57.414+0000",
      id: 9,
      statusName: "IN REVIEW",
      isActive: true,
      statusForId: {
        createdBy: null,
        creationDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        id: 3,
        statusForName: "ORDERS",
        statusForDesc: "Orders",
      },
      vstateEmp: "[13,15]",
      vstateClient: null,
      hubspotId: 948709123,
      vstateEmpId: [13, 15],
      vstateClientId: [],
    },
    {
      createdBy: null,
      creationDate: null,
      lastModifiedBy: null,
      lastModifiedDate: null,
      id: 15,
      statusName: "ORDER IN PROGRESS",
      isActive: true,
      statusForId: {
        createdBy: null,
        creationDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        id: 3,
        statusForName: "ORDERS",
        statusForDesc: "Orders",
      },
      vstateEmp: null,
      vstateClient: "[8]",
      hubspotId: 261516976,
      vstateEmpId: [],
      vstateClientId: [8],
    },
    {
      createdBy: null,
      creationDate: null,
      lastModifiedBy: null,
      lastModifiedDate: null,
      id: 13,
      statusName: "VSTATE QUERY RAISED",
      isActive: true,
      statusForId: {
        createdBy: null,
        creationDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        id: 3,
        statusForName: "ORDERS",
        statusForDesc: "Orders",
      },
      vstateEmp: null,
      vstateClient: "[1004]",
      hubspotId: 261515264,
      vstateEmpId: [],
      vstateClientId: [1004],
    },
    {
      createdBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      creationDate: "2024-09-26T10:09:09.673+0000",
      lastModifiedBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      lastModifiedDate: "2024-09-26T10:09:09.673+0000",
      id: 1006,
      statusName: "CLIENT RESPONDED",
      isActive: true,
      statusForId: {
        createdBy: null,
        creationDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        id: 3,
        statusForName: "ORDERS",
        statusForDesc: "Orders",
      },
      vstateEmp: null,
      vstateClient: null,
      hubspotId: 261517057,
      vstateEmpId: [],
      vstateClientId: [],
    },
    {
      createdBy: null,
      creationDate: null,
      lastModifiedBy: null,
      lastModifiedDate: null,
      id: 8,
      statusName: "ORDER SUBMITTED",
      isActive: true,
      statusForId: {
        createdBy: null,
        creationDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        id: 3,
        statusForName: "ORDERS",
        statusForDesc: "Orders",
      },
      vstateEmp: "[1005]",
      vstateClient: null,
      hubspotId: 261515192,
      vstateEmpId: [1005],
      vstateClientId: [],
    },

    {
      createdBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      creationDate: "2024-09-26T10:08:24.236+0000",
      lastModifiedBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      lastModifiedDate: "2024-09-26T10:08:24.236+0000",
      id: 1005,
      statusName: "GOVT QUERY RAISED",
      isActive: true,
      statusForId: {
        createdBy: null,
        creationDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        id: 3,
        statusForName: "ORDERS",
        statusForDesc: "Orders",
      },
      vstateEmp: null,
      vstateClient: "[1006]",
      hubspotId: 261515265,
      vstateEmpId: [],
      vstateClientId: [1006],
    },
    {
      createdBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      creationDate: "2024-09-26T10:02:54.897+0000",
      lastModifiedBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      lastModifiedDate: "2024-09-26T10:05:24.907+0000",
      id: 1004,
      statusName: "REPONDED TO QUERY",
      isActive: true,
      statusForId: {
        createdBy: null,
        creationDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        id: 3,
        statusForName: "ORDERS",
        statusForDesc: "Orders",
      },
      vstateEmp: "[13,14]",
      vstateClient: null,
      hubspotId: 261517056,
      vstateEmpId: [13, 14],
      vstateClientId: [],
    },
    {
      createdBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      creationDate: "2024-09-26T10:11:21.984+0000",
      lastModifiedBy: "LpCFYEaT+mQpHx3eh8tCw79PT4tDoOweIGZ1SqiStmk=",
      lastModifiedDate: "2024-09-26T10:11:21.984+0000",
      id: 1007,
      statusName: "ORDER RESUBMITTED",
      isActive: true,
      statusForId: {
        createdBy: null,
        creationDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        id: 3,
        statusForName: "ORDERS",
        statusForDesc: "Orders",
      },
      vstateEmp: "[1005,11]",
      vstateClient: "[1005,11]",
      hubspotId: 261515266,
      vstateEmpId: [11, 1005],
      vstateClientId: [11, 1005],
    },
    {
      createdBy: null,
      creationDate: null,
      lastModifiedBy: null,
      lastModifiedDate: null,
      id: 11,
      statusName: "ORDER COMPLETED",
      isActive: true,
      statusForId: {
        createdBy: null,
        creationDate: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        id: 3,
        statusForName: "ORDERS",
        statusForDesc: "Orders",
      },
      vstateEmp: null,
      vstateClient: null,
      hubspotId: 261516974,
      vstateEmpId: [],
      vstateClientId: [],
    },
  ];

  const services = [
    {
      title: "Entity Formation",
      description: "Access corporate kits and necessary publications.",
      orderType: 1,
      features: [],
    },
    {
      title: "Corporate Filings",
      description: "Access corporate kits and necessary publications.",
      features: [
        {
          itemName: "Foreign Qualification",
          path: "",
          isFirst: false,
          orderType: null,
          description1: "Access corporate kits and necessary publications.",
          description:
            "Register your company online in minutes. Complete the following steps to get started.",
        },
        {
          itemName: "Limited Liability Partnership (LLP)",
          path: "",
          isFirst: false,
          orderType: null,
          description1: "Access corporate kits and necessary publications.",
          description:
            "An LLP offers liability protection for partners while allowing them to participate in the management of the business. This filing service helps establish the LLP structure and ensures compliance with state regulations.",
        },
        {
          itemName: "Limited Partnership (LP)",
          path: "",
          isFirst: false,
          orderType: null,
          description1: "Access corporate kits and necessary publications.",
          description:
            "In a Limited Partnership, there is at least one general partner who manages the business and is personally liable, and one or more limited partners who have liability protection but no management authority. This service assists in setting up an LP.",
        },
        {
          itemName: "Non-Profit",
          path: "",
          isFirst: false,
          orderType: null,
          description1: "Access corporate kits and necessary publications.",
          description:
            "Form a non-profit organization for charitable, religious, educational, or social purposes. This service helps guide you through the incorporation process and ensures compliance with applicable state and IRS regulations.",
        },
        {
          itemName: "501c3",
          path: "",
          isFirst: false,
          orderType: null,
          description1: "Access corporate kits and necessary publications.",
          description:
            "Obtain tax-exempt status for your non-profit organization by filing for 501(c)(3) status with the IRS. This service helps you complete the necessary forms and ensures compliance with federal requirements.",
        },
        {
          itemName: "Dissolution",
          path: "",
          isFirst: false,
          orderType: null,
          description1: "Access corporate kits and necessary publications.",
          description:
            "When it’s time to close your business, dissolution is a legal process that officially ends your company’s existence. This service assists in filing the necessary paperwork and fulfilling any legal obligations.",
        },
        {
          itemName: "Amendment",
          path: "",
          isFirst: false,
          orderType: null,
          description1: "Access corporate kits and necessary publications.",
          description:
            "If your company undergoes changes like a name change, adding new business activities, or changing its structure, this service helps you file the appropriate amendment with the state.",
        },
        {
          itemName: "S-Corp Election",
          path: "",
          isFirst: false,
          orderType: null,
          description1: "Access corporate kits and necessary publications.",
          description:
            "This service helps your corporation elect to be taxed as an S-Corporation, offering the benefits of pass-through taxation, which avoids double taxation on both the corporate and individual levels.",
        },
        {
          itemName: "Conversion",
          path: "",
          isFirst: false,
          orderType: null,
          description1: "Access corporate kits and necessary publications.",
          description:
            "When you want to change your business entity type (e.g., from an LLC to a corporation), this service manages the conversion process, ensuring that your new entity complies with state and federal regulations.",
        },
        {
          itemName: "Merger",
          path: "",
          isFirst: false,
          orderType: null,
          description1: "Access corporate kits and necessary publications.",
          description:
            "This service facilitates the process of merging two companies into one, handling the legal documentation and filings to complete the merger in compliance with state laws",
        },
        {
          itemName: "Reinstatement",
          path: "",
          isFirst: false,
          orderType: null,
          description1: "Access corporate kits and necessary publications.",
          description:
            "If your business has been dissolved or its status revoked, reinstatement services can bring it back into good standing with the state by filing the necessary documents and resolving any penalties.",
        },
        {
          itemName: "DBA Filing",
          path: "dba-filing",
          isFirst: true,
          orderType: 37,
          description1: "Access corporate kits and necessary publications.",
          description:
            "When operating under a Doing Business As (DBA) name, you need to register it with the state or county. This service ensures your DBA name is properly filed, allowing you to legally operate under your chosen business name.",
        },
      ],
    },
    {
      title: "Compliance",
      description:
        "Stay up-to-date with all required business filings and regulations.",
      features: [
        {
          itemName: "Beneficial Ownership (BOI)",
          path: "beneficial-ownership-information-report",
          isFirst: true,
          orderType: 18,
          description1:
            "Stay up-to-date with all required business filings and regulations.",
          description:
            "This service ensures your business complies with federal or state regulations by filing reports that disclose who has significant ownership or control over the company, promoting transparency and preventing fraud.",
        },
        {
          itemName: "Annual Report",
          path: "annual-report",
          isFirst: true,
          orderType: 6,
          description1:
            "Stay up-to-date with all required business filings and regulations.",
          description:
            "An annual report provides updated information about your business to the state, including changes in directors, officers, and address. It’s essential to keep your company in good standing and compliant with state laws.",
        },
        // {
        //   itemName: "Reinstatement",
        //   path: "",
        //   isFirst: true,
        //   orderType: null,
        //   description1:
        //     "Stay up-to-date with all required business filings and regulations.",
        //   description:
        //     "If your business has fallen out of good standing or been administratively dissolved, reinstatement services will help bring your company back into compliance by filing the necessary documents and paying any outstanding fees.",
        // },
        {
          itemName: "Biennial Report",
          path: "biennial-report",
          isFirst: true,
          orderType: 7,
          description1:
            "Stay up-to-date with all required business filings and regulations.",
          description:
            "Similar to an Annual Report, but filed every two years, a Biennial Report ensures that your business remains in compliance with state regulations by submitting updated company information.",
        },
        {
          itemName: "LLC Publication",
          path: "llc-publication",
          isFirst: true,
          orderType: 4,
          description1:
            "Stay up-to-date with all required business filings and regulations.",
          description:
            "Some states require LLCs to publish a notice of their formation in designated newspapers. This service manages the entire process, ensuring compliance with state laws and avoiding potential penalties.",
        },

        {
          itemName: "Liquor License Publication",
          path: "liquor-license-publishing",
          isFirst: true,
          orderType: 14,
          description1:
            "Stay up-to-date with all required business filings and regulations.",
          description:
            "If you're applying for a liquor license, you may be required to publish a notice in a local newspaper. This service handles the publication process, making sure you meet all local requirements.",
        },

        {
          itemName: "Foundation Publication",
          path: "",
          isFirst: true,
          orderType: null,
          description1:
            "Stay up-to-date with all required business filings and regulations.",
          description:
            "Certain states require that foundations publish a notice of their formation. This service ensures compliance with state publication requirements.",
        },

        {
          itemName: "Sidewalk Cafe Publication",
          path: "",
          isFirst: true,
          orderType: null,
          description1:
            "Stay up-to-date with all required business filings and regulations.",
          description:
            "For businesses seeking permits for sidewalk cafés, publishing a notice may be a legal requirement. This service takes care of the publication process as part of the permit application.",
        },
        {
          itemName: "DBA Publication",
          path: "dba-certificate-of-assumed-name-form",
          isFirst: true,
          orderType: 10,
          description1:
            "Stay up-to-date with all required business filings and regulations.",
          description:
            "When operating under a Doing Business As (DBA) name, some states require you to publish a notice. This service ensures your DBA name is properly registered and published",
        },
      ],
    },
    {
      title: "Subscriptions",
      description:
        "Secure ongoing legal representation and document management services.",
      features: [
        {
          itemName: "Registered Agent",
          path: "",
          isFirst: true,
          orderType: null,
          description1:
            "Secure ongoing legal representation and document management services.",
          description:
            "A registered agent receives legal documents, such as lawsuits or subpoenas, on behalf of your business and ensures you meet state compliance requirements.",
        },
        {
          itemName: "Annual Report",
          path: "annual-report",
          isFirst: true,
          orderType: 6,
          description1:
            "Secure ongoing legal representation and document management services.",
          description:
            "This service ensures that your annual reports are filed on time each year, keeping your business in good standing with the state.",
        },
        {
          itemName: "Independent Manager",
          path: "independent-manager",
          isFirst: true,
          orderType: 13,
          description1:
            "Secure ongoing legal representation and document management services.",
          description:
            "Certain LLCs may require an independent manager to meet legal obligations. This service provides qualified individuals to serve in that role.",
        },
        {
          itemName: "Independent Director",
          path: "",
          isFirst: true,
          orderType: null,
          description1:
            "Secure ongoing legal representation and document management services.",
          description:
            "This service helps you appoint an independent director, as required by law for certain companies, ensuring the company has impartial oversight.",
        },
        {
          itemName: "Biennial Report",
          path: "biennial-report",
          isFirst: true,
          orderType: 7,
          description1:
            "Secure ongoing legal representation and document management services.",
          description:
            "Similar to an annual report, but filed every two years, this service manages the timely filing of biennial reports to keep your company compliant.",
        },
      ],
    },
    {
      title: "UCC Filings",
      description: "File and manage UCC secured transactions.",
      features: [
        {
          itemName: "UCC Filing",
          path: "ucc-filing",
          isFirst: true,
          orderType: 15,
          description1: "File and manage UCC secured transactions.",
          description:
            "File a UCC-1 form to publicly declare a creditor’s interest in a debtor’s personal or business assets, securing a legal claim to those assets in case of default.",
        },

        {
          itemName: "UCC Amendment",
          path: "ucc-amendment",
          isFirst: true,
          orderType: 17,
          description1: "File and manage UCC secured transactions.",
          description:
            "Make updates to existing UCC filings, such as changing the details of the collateral or parties involved.",
        },
        {
          itemName: "UCC Search",
          path: "ucc-search",
          isFirst: true,
          orderType: 16,
          description1: "File and manage UCC secured transactions.",
          description:
            "Conduct a search to verify any existing UCC filings on a business or individual, helping assess whether assets are encumbered by liens.",
        },
        {
          itemName: "Litigation Search",
          path: "litigation-search",
          isFirst: true,
          orderType: 5,
          description1: "File and manage UCC secured transactions.",
          description:
            "This service searches public records to uncover any ongoing or past litigation involving a business, ensuring you have a clear understanding of its legal standing.",
        },
      ],
    },
    {
      title: "Document Retrieval",
      description:
        "Order certified copies of business records and legal documents.",
      features: [
        {
          itemName: "Certificate of Good Standing",
          path: "cgs",
          isFirst: false,
          orderType: 2,
          description1:
            "Order certified copies of business records and legal documents.",
          description:
            "A document issued by the state that certifies your company is in compliance with all regulations and has paid any necessary fees. It’s often required for business dealings like securing loans or entering contracts.",
        },
        {
          itemName: "Formation Documents",
          orderType: 12,
          description: "",
          path: "",
          isFirst: true,
          orderType: null,
          description1:
            "Order certified copies of business records and legal documents.",
          description:
            "Retrieve official copies of your company's original formation documents, such as articles of incorporation or organization, as proof of your company’s legal existence.",
        },
        {
          itemName: "Amendment Documents",
          path: "certificate-of-amendment",
          isFirst: true,
          orderType: null,
          description1:
            "Order certified copies of business records and legal documents.",
          description:
            "This service provides copies of any amendments filed to your company’s original formation documents, reflecting changes to its structure or name.",
        },
        {
          itemName: "Certified Copies",
          orderType: 19,
          description: "",
          path: "copie",
          isFirst: true,
          description1:
            "Order certified copies of business records and legal documents.",
          description: "",
        },

        {
          itemName: "Certificate of Change",
          path: "certificate-of-change",
          isFirst: true,
          orderType: 9,
          description1:
            "Order certified copies of business records and legal documents.",
          description: "",
        },

        {
          itemName: "Certificate of Dissolution",
          path: "certificate-of-dissolution",
          isFirst: true,
          orderType: 11,
          description1:
            "Order certified copies of business records and legal documents.",
          description: "",
        },
      ],
    },
    {
      title: "Corporate Supplies",
      description: "Manage and order essential corporate supplies.",
      features: [
        {
          itemName: "Corporate Kit",
          path: "kit",
          isFirst: false,
          orderType: 3,
          description1: "Manage and order essential corporate supplies.",
          description:
            "A corporate kit contains essential items for maintaining corporate records, including a binder, stock certificates, minutes forms, and a company seal.",
        },
        {
          itemName: "Corporate Seal Service",
          path: "",
          isFirst: false,
          orderType: null,
          description1: "Manage and order essential corporate supplies.",
          description:
            "A customized embosser that authenticates official company documents. It imprints your company’s name and details to provide a formal stamp of approval on important paperwork.",
        },

        {
          itemName: "Stock/Membership Certificates",
          path: "",
          isFirst: false,
          orderType: null,
          description1: "Manage and order essential corporate supplies.",
          description:
            "Provide shareholders or LLC members with official certificates to document their ownership stake in the company.",
        },
      ],
    },
  ];

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

    id: { value: null, matchMode: FilterMatchMode.IN },
    "orderDetails.id": { value: null, matchMode: FilterMatchMode.EQUALS },
    "contactDetails.lastName": {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
    "contactDetails.mobileNo": {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
    "contactDetails.emailId": {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
  });

  const layoutRef = useRef(null);

  const navigate = useNavigate();

  // const [city, setCity] = useState([])

  const [exp1, setExp1] = useState(null);

  const [exp2, setExp2] = useState(null);

  const printRef = useRef();

  const [skills, setSkills] = useState([]);
  const [skillError, setSkillError] = useState("");
  const [skillARR, setSkillARR] = useState([]);

  const [superAdminId, setSuperAdminId] = useState(null);

  const [qualification, setQualification] = useState([]);
  const [qualificationArr, setQualificationArr] = useState([]);

  const [visible, setVisible] = useState(false);

  const [visible1, setVisible1] = useState(false);

  const [visible2, setVisible2] = useState(false);

  const [visible4, setVisible4] = useState(false);

  const [visible5, setVisible5] = useState(false);

  const [visible6, setVisible6] = useState(false);

  const [visibleContent, setVisibleContent] = useState(false);

  const [allPosition, setAllPosition] = useState([]);

  const [position, setPosition] = useState(null);

  const [positionData, setPositionData] = useState(null);

  const [candidate, setCandidate] = useState(null);

  const [closeProgresbar, setCloseProgresbar] = useState(false);

  const [closeProgresbar1, setCloseProgresbar1] = useState(false);

  const [closeProgresbar3, setCloseProgresbar3] = useState(false);

  const [closeProgresbar4, setCloseProgresbar4] = useState(false);

  const [closeProgresbar5, setCloseProgresbar5] = useState(false);

  const [closeProgresbar6, setCloseProgresbar6] = useState(false);

  const [globalKeyValue, setGlobalKeyValue] = useState("");

  const [AllCandidate, setAllCandidate] = useState([]);

  const [showSkeleton, setShowSkeleton] = useState(false);

  const [showSkeletonData, setShowSkeletonData] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [data, setData] = useState(null);

  const [imageData, setImageData] = useState(null);

  // const [statesId, setStatesId] = useState(null);
  const [statesId, setStatesId] = useState([]);

  const op = useRef(null);

  const downloadop = useRef(null);

  const toast = useRef(null);

  const [isRemoveChip, setIsRemoveChip] = useState(false);

  const [fback, setFback] = useState([]);

  const [candidateFback, setCandidateFback] = useState(null);

  const [interviewLevel1, setInterviewLevel1] = useState([]);

  const [hrPanelList, setHrPanelList] = useState([]);

  const [hrpanel, setHrPanel] = useState(null);

  const [selectedPanelData, setSelectedPanelData] = useState([]);

  const [selectedPanelList, setSelectedPanelList] = useState([]);

  const [showPanel, setShowPanel] = useState(false);

  const [globalSearchValue, setGlobalSearchValue] = useState("");

  const history = useNavigate();

  const [isShowContent, setIsShowContent] = useState(false);

  const [isShowContent1, setIsShowContent1] = useState(false);

  const [isShowContent2, setIsShowContent2] = useState(false);

  const [isShowContent3, setIsShowContent3] = useState(false);

  const [salutation, setSalutation] = useState("");

  const [firstName, setFirstName] = useState(null);

  const [firstNameErr, setFirstNameErr] = useState("");

  const [middleName, setMiddleName] = useState(null);

  const [middleNameErr, setMiddleNameErr] = useState("");

  const [lastName, setLastName] = useState(null);

  const [lastNameErr, setLastNameErr] = useState("");

  const [phoneNumber, setPhoneNumber] = useState(null);

  const [phoneErr, setPhoneErr] = useState("");

  const [altPhoneNumber, setAltPhoneNumber] = useState(null);

  const [altPhoneErr, setAltPhoneErr] = useState("");

  const [email, setEmail] = useState(null);

  const [emailErr, setEmailErr] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const [altEmail, setAltEmail] = useState("");

  const [altEmailErr, setAltEmailErr] = useState("");

  const [fax, setFax] = useState(null);

  const [faxErr, setFaxErr] = useState("");

  const [altFax, setAltFax] = useState("");

  const [altFaxErr, setAltFaxErr] = useState("");

  const [addr1, setAddr1] = useState("");

  const [addr1Err, setAddr1Err] = useState("");

  const [addr2, setAddr2] = useState("");

  const [addr2Err, setAddr2Err] = useState("");

  const [addr3, setAddr3] = useState("");

  const [region, setRegion] = useState("");

  const [regionErr, setRegionErr] = useState("");

  const [city, setCity] = useState("");

  const [cityErr, setcityErr] = useState("");

  const [states, setStates] = useState(null);

  const [statesErr, setStatesErr] = useState("");

  const [zip, setZip] = useState("");

  const [zipErr, setZipErr] = useState("");

  const [pageRefresh, setPageRefresh] = useState(false);

  const [dataId, setDataId] = useState(null);

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [rolesName, setRolesName] = useState("");

  const [userId, setUserId] = useState(null);

  const [passwordErr, setPasswordErr] = useState("");

  const [oldpasswordErr, setOldPasswordErr] = useState("");

  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [passwordType, setPasswordType] = useState("password");

  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const [allCompanies, setAllCompanies] = useState([]);

  const [company, setCompany] = useState(null);

  const [allRoles, setAllRoles] = useState([]);

  const [role, setRole] = useState(null);

  const [tempProduct, setTempProduct] = useState([]);

  const [isSort, setIsSort] = useState(false);

  const [addressData, setAddressData] = useState(null);

  const [contactDetails, setContactDeatils] = useState(null);

  const [companyDetails, setCompanyDetails] = useState(null);

  const [allAddressType, setAllAddressType] = useState([]);

  const [addressType, setAddressType] = useState(null);

  const [allStates, setAllStates] = useState([]);

  const [allCountries, setAllCountries] = useState([]);

  const [countryData, setCountryData] = useState(null);

  const [stateValue, setStateValue] = useState(null);

  const [countryValue, setCountryValue] = useState(null);

  const [entityType, setEntityType] = useState(null);

  const [status, setStatus] = useState({ approve: false, reject: false });

  const [approvalDialog, setApprovalDialog] = useState(false);

  const [pendingStatusDialog, setPendingStatusDialog] = useState(false);

  const [orderTempdata, setOrderTempData] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [isEditing1, setIsEditing1] = useState(false);
  const [isEditing2, setIsEditing2] = useState(false);

  const [editValues, setEditValues] = useState();

  const [stripeData, setStripeData] = useState(null);

  const [allContact, setAllContact] = useState([]);

  const [contactData, setContactData] = useState(null);

  const [entityTypes, setEntityTypes] = useState([]);

  const [entityTypeData, setEntityTypeData] = useState("");

  const [updateStatus, setUpdateStatus] = useState(null);

  const [orderStatusErr, setOrderStatusErr] = useState("");

  const [orderUpdateNotesErr, setOrderUpdateNotesErr] = useState("");

  const [currentStatus, setCurrentStatus] = useState(null);

  const [superAdminMainId, setSuperAdminMainId] = useState(null);

  const [allKeyPersonnel, setAllKeyPersonal] = useState([]);

  const [customPrice, setCustomPrice] = useState(0.0);

  const [customPriceErr, setCustomPriceErr] = useState("");

  const [allTemplate, setAllTemplate] = useState([]);

  const [template, setTemplate] = useState(null);

  const [IsPriceAvail, setIsPriceAvail] = useState(true);

  const [comapnyData, setCompanyData] = useState(null);

  const [IsOrderPaid, setIsOrderPaid] = useState(null);

  const [admin, setAdmin] = useState(null);

  const [sortedResponseData, setSortedResponseData] = useState(null);

  const [managerId, setManagerId] = useState(null);

  const [orderUpdateHistoryData, setOrderUpdateHistoryData] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    SetFileErr("");
  };
  const handleShow = () => setShow(true);

  const handleCheckboxChange = (e) => {
    setOrderStatusErr(false);
    const { value } = e.target;
    if (value === "approve") {
      setStatus({ approve: true, reject: false });
    } else if (value === "reject") {
      setStatus({ approve: false, reject: true });
    }
  };

  const strapiData = {
    data: {
      Company_Detail: {
        Company_Name: "Redberyltech",
      },
      Organizer_Details: {
        Organizer_Name: "Saumyaranjan Patra",
        Organizer_Email: "saumya@gmail.com",
        Organizer_ContactNo: "7888883883",
      },
    },
  };

  const [checked, setChecked] = useState(true);

  const fileInputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);

  const [isInvoiceAvail, setIsInvoiceAvail] = useState(false);

  const fetchInvoiceAvailability = async (id) => {
    if (id) {
      try {
        const response = await OrderServices.isInvoiceAvailable(id);

        if (
          response?.data &&
          response?.data !== null &&
          response?.data !== ""
        ) {
          setIsInvoiceAvail(true);
        } else {
          setIsInvoiceAvail(false);
        }
      } catch (error) {
        console.error("Failed to fetch invoice availability", error);
        setIsInvoiceAvail(false);
      }
    } else {
      setIsInvoiceAvail(false);
    }
  };

  //   useEffect(() => {

  //     fetchInvoiceAvailability(8487);
  //     // console.log(isInvoiceAvail, "sdafdasfs")

  // }, []);

  const [fileErr, SetFileErr] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      // No file selected
      return;
    }
    if (file) {
      if (
        file.type !== "application/pdf" &&
        file.type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        SetFileErr("Please select a valid PDF or DOCX file.");
        return;
      } else {
        SetFileErr("");
      }
    }

    if (file.size > 10 * 1024 * 1024) {
      SetFileErr("File size is larget than 10MB");
      return;
    }

    const a = false;

    if (orderTempdata && orderTempdata?.orderDetails) {
      const editPayloadData = orderTempdata?.orderDetails;
      const jsonPayloadData = JSON.parse(editPayloadData.strapiOrderFormJson);
      const addressData =
        jsonPayloadData?.Payload?.Entity_Formation?.Principal_Address;
    }

    if (file && orderTempdata && orderTempdata?.orderDetails) {
      const editPayloadData = orderTempdata?.orderDetails;
      const jsonPayloadData = JSON.parse(editPayloadData.strapiOrderFormJson);

      const formData = new FormData();
      formData.append("file", file);
      if (
        orderTempdata.orderTypeID?.orderShortName === "Entity Formation" &&
        updateStatus?.statusName === "ORDER COMPLETED"
      ) {
        formData.append(
          "state",
          jsonPayloadData?.State?.stateFullDesc || "State is not defined"
        );
      }
      setSelectedFile(file);
      if (
        orderTempdata &&
        orderTempdata.orderTypeID &&
        orderTempdata.orderTypeID?.orderShortName === "Entity Formation" &&
        updateStatus?.statusName === "ORDER COMPLETED"
      ) {
        if (
          orderTempdata &&
          orderTempdata?.orderDetails &&
          orderTempdata?.companyId
        ) {
          const addressData =
            jsonPayloadData?.Payload?.Entity_Formation?.Principal_Address;

          const alternateName =
            jsonPayloadData?.Payload?.Entity_Formation?.Name
              ?.CD_Alternate_LLC_Name ||
            jsonPayloadData?.Payload?.Entity_Formation?.Name
              ?.CD_Alternate_Corporation_Name;

          const companyPayload = {
            companyName: null,
            websiteUrl: null,
            accountManagerId: managerData,
            adminContactId: null,
            primaryContactId: null,
            industryId: 149,
            companyEmail: null,
            phoneNo: null,
            fax: null,
            organizerContactId: null,
            entityType: jsonPayloadData?.EntityType?.id,
            naicsCode: null,
            formationDate: new Date(),
            boardCertifiedFlag: true,
            serviceStateId: jsonPayloadData?.State?.id,
            domesticStateId: jsonPayloadData?.State?.id,
            einNo: null,
            entityNo: null,
            dissolutionDate: null,
            taxYearEnd: null,
            registeredAgentName: null,
            registeredAgentAddress: null,
            registeredAgentEmail: null,
            registeredAgentContactNo: null,
            dbaName: null,
            fkaName: null,
            phone: null,
            addressLine1: addressData?.PA_Address_Line_1,
            addressLine2: addressData?.PA_Address_Line_2,
            city: addressData?.PA_City,
            companyAddress: null,
            postalCode: addressData?.PA_Zip_Code,
            countryId: 3,
            addressType: {
              createdBy: null,
              creationDate: null,
              lastModifiedBy: null,
              lastModifiedDate: null,
              id: 4,
              type: "PRINCIPAL",
            },
            email: null,
            statusId: 4,
            documentIds: [],
            type: null,
          };
          const demo = {
            "ENTITY NAME": "ROYCE EQUITY VENTURES LLC",
            "DOCUMENT TYPE": "ARTICLES OF ORGANIZATION",
            "ENTITY TYPE": "DOMESTIC LIMITED LIABILITY COMPANY",
            "DOS ID": "7289995",
            "FILE DATE": "03/26/2024",
            "FILE NUMBER": "240326003835",
            "TRANSACTION NUMBER": "202403260004126-3082222",
            "EXISTENCE DATE": "03/26/2024",
            "DURATION/DISSOLUTION": "PERPETUAL",
            "COUNTY ": "KINGS",
            "SERVICE OF PROCESS ADDRESS":
              "THE LLC 2071 FLATBUSH AVENUE, STE 166, BROOKLYN, NY, 11234, USA",
            EMAIL: "THEROYCEGROUPLTD@GMAIL.COM",
            FILLED_BY: "JOHN MOSELEY",
            FILLER_ADDRESS:
              "10601 CLARENCE DR. STE. 250, FRISCO, TX, 75033, USA",
            SIGNATURE: "ANDREW MOORE",
            ROLE: "ORGANIZER",
            STATE: "NY",
          };
          setIsTabRefresh(true);

          OrderServices.customCompanyUpload(formData)
            .then((res) => {
              if (
                res.data ==
                "expected string or bytes-like object, got 'NoneType'"
              ) {
                showToast("error", "Please upload valid document");
                console.log(res.data, "wahteta");

                return;
              }

              if (res.data) {
                const data = res.data;
                //                 console.log("Data ENTITY NAME:", data["ENTITY NAME"].trim().toLowerCase());
                // console.log("Order Title:", orderTempdata?.orderDetails?.orderTitle.trim().toLowerCase());
                // console.log("Alternate Name:", alternateName.trim().toLowerCase());

                console.log(
                  "Condition 1 Match:",
                  data["ENTITY NAME"].trim().toLowerCase() ===
                    orderTempdata?.orderDetails?.orderTitle.trim().toLowerCase()
                );

                if (
                  data["ENTITY NAME"].trim().toLowerCase() ===
                    orderTempdata?.orderDetails?.orderTitle
                      .trim()
                      .toLowerCase() ||
                  (typeof alternateName === "string" &&
                    data["ENTITY NAME"].trim().toLowerCase() ===
                      alternateName.trim().toLowerCase())
                ) {
                  companyPayload["companyName"] = data["ENTITY NAME"];
                  companyPayload["companyEmail"] = data["EMAIL"]
                    ? data["EMAIL"]
                    : "";
                  companyPayload["adminContactId"] =
                    orderTempdata?.companyId?.adminContactId;
                  companyPayload["primaryContactId"] =
                    orderTempdata?.companyId?.primaryContactId;

                  setCompanyData(companyPayload);
                } else {
                  showToast("error", "Please upload valid document");

                  setSelectedFile(null);
                  if (fileInputRef.current) {
                    fileInputRef.current.value = null;
                  }
                }
                setIsTabRefresh(false);
              }
            })
            .catch((err) => {
              setIsTabRefresh(false);
              setSelectedFile(null);
              showToast("error", "Please upload valid document");
            });
        }
      }
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
    setSelectedFile(null);
  };

  // Function to extract dial code from phone number
  const extractDialCode = (phoneNumber) => {
    const dialCode = phoneNumber.match(/^\+\d+/);
    return dialCode ? dialCode[0] : null;
  };

  // Function to find country by dial code
  const findCountryByDialCode = (dialCode) => {
    return countries.find((country) => country.dialCode === dialCode);
  };

  const handleCheck = (event) => {
    setChecked(event.target.checked);
    if (data && dataId) {
      const actualdata = data.contactDetails;
      actualdata["statusId"] = event.target.checked === true ? 1 : 2;

      ContactService.updateContact(actualdata, dataId)
        .then((res) => {
          setIsEditing1(false);
          setPageRefresh(!pageRefresh);
        })
        .catch((err) => {});
    }
  };

  const [products, setProducts] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [contactId, setContactId] = useState(null);
  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];

  const [allStatus, setAllStatus] = useState([]);

  const [statusName, setStatusName] = useState(null);

  const [updateNoteText, setUpdateNoteText] = useState("");

  const [isClient, setIsClient] = useState(false);

  const [totalRecords, setTotalRecords] = useState(50);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const [sortedData, setSortedData] = useState(products);

  const [sortField, setSortField] = useState("orderId");
  const [sortOrder, setSortOrder] = useState(-1); // 1 for ascending, -1 for descending

  const localStorageData = localStorage.getItem("user");
  const parsedJson = JSON.parse(localStorageData);
  const roles = parsedJson?.companyRoles[0]?.roles?.[0]?.roleName;

  const isSuperAdmin = roles === "SUPER_ADMINISTRATOR";

  const fields = [
    { label: "Order Number", value: "orderId" },
    { label: "Order Date", value: "orderDate" },
    { label: "Entity Name", value: "displayName" },
    { label: "Status", value: "statusName" },
    { label: "State", value: "stateName" },
    { label: "Order Type", value: "orderTypeName" },
    ...(isSuperAdmin
      ? [{ label: "Account Rep", value: "accountManagerName" }]
      : []),
  ];

  // useEffect(() => {
  //     if (fields.length > 0) {
  //         setSortField(fields[0]); // Set the first field as default
  //     }
  // }, []); // Runs when fields change

  const onSortFieldChange = (e) => {
    setSortField(e.value);
  };

  const toggleSortOrder = () => {
    setSortOrder((prevSortOrder) => -prevSortOrder);
  };

  useEffect(() => {
    // Sort data whenever sortField or sortOrder changes

    if (sortField) {
      const field = sortField.toString();
      const sorted = [...products].sort((a, b) => {
        if (a[field] < b[field]) return -1 * sortOrder;
        if (a[field] > b[field]) return 1 * sortOrder;
        return 0;
      });

      setSortedData(sorted);
    } else {
      setSortedData(products);
    }
  }, [sortField, sortOrder, products]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder((prevSortOrder) => -prevSortOrder);
    } else {
      setSortField(field);
      setSortOrder(1);
    }
  };

  function checkIdsExist(arr, idsToCheck) {
    // Return the found IDs or null if none are found
    return (
      idsToCheck.filter((idToCheck) =>
        arr.some((item) => item.id === idToCheck)
      ) || null
    );
  }

  useEffect(() => {
    if (user && globalCompany && globalCompany.roles.length > 0) {
      if (globalCompany.companyDetails) {
        setCompany(globalCompany.companyDetails);
      }
      const superAdminIds = checkIdsExist(globalCompany.roles, [1, 4,1013]);
      const hasSuperId = superAdminIds?.includes(1); // Check if 1 exists in the roles
      const hasOtherId = superAdminIds?.includes(4); // Check if 4 exists in the roles

      const delicatedSuperId = superAdminIds?.includes(1013);

      if (hasOtherId) {
        setSuperAdminId([4]); // Set 4 if found
      }

      if (hasSuperId || delicatedSuperId) {
        setSuperAdminMainId([1]); // Set 1 if found
      }

      if (!hasSuperId) {
        setIsClient(true);
      }
    } else if (user && Array.isArray(user.companyRoles) && managerId) {
      const globalCompany = user.companyRoles[0];
      if (globalCompany.roles.length > 0) {
        // Check for both 1 and 4
        const ids = checkIdsExist(globalCompany.roles, [1]);
        const empid = checkIdsExist(globalCompany.roles, [4]);
        const adminIds = checkIdsExist(globalCompany.roles, [1, 2, 4]);

        const superAdminIds = checkIdsExist(globalCompany.roles, [1, 4,1013]);
        const hasSuperId = superAdminIds?.includes(1); // Check if 1 exists in the roles
        const hasOtherId = superAdminIds?.includes(4);
        const delicatedSuperId = superAdminIds?.includes(1013);

        if (hasOtherId) {
          setSuperAdminId([4]); // Set 4 if found
        }

        if (hasSuperId || delicatedSuperId) {
          setSuperAdminMainId([1]); // Set 1 if found
        }

        if (!hasSuperId) {
          setIsClient(true);
        }
      }
    }
  }, [globalCompany, managerId]);

  useEffect(() => {
    if (globalCompany) {
      companyMasterService
        .getAllCompanyById(globalCompany?.companyDetails?.id)
        .then((res) => {
          if (res.data) {
            setManagerData(res.data?.accountManagerId);
          }
        })
        .catch((err) => {});
    }
  }, [globalCompany]);

  useEffect(() => {
    if (user) {
      UserServices.getEmployeeByUserId(user.id)
        .then((res) => {
          if (res.data) {
            setManagerId(res.data.id);
          }
        })
        .catch((err) => {});
    }
  }, []);

  const handleSelectedProduct = (product, allProducts) => {
    setSelectedProduct(product);

    setData(product); // Assuming setData is used for displaying selected order details
    setCurrentStatus(product.statusId); // Assuming this is used for displaying order status

    // Reorder products based on the selected product
    const reorderedData = [
      ...allProducts.filter((row) => row.orderId === product.orderDetails.id),
      ...allProducts.filter((row) => row.orderId !== product.orderDetails.id),
    ];
    setProducts(reorderedData);
    setShowSkeleton(true);
    setShowSkeletonData(true);
  };

  const fetchAllOrders = (page = 0, size = rows) => {
    // setShowSkeleton(true);
    if (isClient && globalCompany && globalCompany.companyDetails) {
      OrderServices.getAllOrderByCompanyId(globalCompany.companyDetails.id)
        .then((res) => {
          const data = res.data || [];
          setTempProduct(data);
          setProducts(data);
          setShowSkeleton(false);
          setShowSkeletonData(false);

          if (data.length > 0) {
            fetchAndSelectProduct(data);
          
          } else {
            setShowSkeleton(true);
            setShowSkeletonData(true);
            setData(null);
          }
        })
        .catch((err) => {
          console.error("Error fetching orders:", err);
          setShowSkeleton(false);
          setShowSkeletonData(false);
        });
    } else {
      OrderServices.getAllOrderByWithoutPagination(managerId && !superAdminMainId ? managerId : 0)
        .then((res) => {
          const data = res.data || [];
          setTempProduct(data);
          setProducts(data);
          setShowSkeleton(false);
          setShowSkeletonData(false);

          if (data.length > 0) {
            fetchAndSelectProduct(data);
            const managersArray = Array.from(
              new Set(data.map((order) => order.accountManagerName))
            ).map((name, index) => ({
              id: index,
              managerName: name,
            }));

            SetAllCompanyManager(managersArray);
          } else {
            setShowSkeleton(true);
            setShowSkeletonData(true);
            setData(null);
          }
        })
        .catch((err) => {
          console.error("Error fetching orders:", err);
          setShowSkeleton(false);
          setShowSkeletonData(false);
        });
    }
  };

  const fetchAndSelectProduct = async (allProducts) => {
    let selectedProductData = null;

    if (sortedResponseData) {
      selectedProductData = await fetchProductById(sortedResponseData.id);
    } else if (redirectedData) {
      selectedProductData = await fetchProductById(redirectedData);
      console.log(redirectedData);
    } else if (filterOrderStatusId) {
      const filteredProducts = allProducts.filter(
        (val) => val.statusId === filterOrderStatusId
      );
      if (filteredProducts.length > 0) {
        selectedProductData = await fetchProductById(
          filteredProducts[0].orderId
        );
        handleSelectedProduct(selectedProductData, filteredProducts);
        return;
      } else {
        setShowSkeleton(true);
        setShowSkeletonData(true);
        setProducts([]);
        return;
      }
    } else if (orderid) {
      const orderProduct = allProducts.find(
        (val) => val.orderNumber === orderid
      );
      if (orderProduct) {
        selectedProductData = await fetchProductById(orderProduct.orderId);
      }
    }

    if (!selectedProductData && allProducts.length > 0) {
      setShowSkeletonData(false);
      selectedProductData = await fetchProductById(allProducts[0].orderId);
    }

    if (selectedProductData) {
      handleSelectedProduct(selectedProductData, allProducts);
    }
    if (Array.isArray(allProducts) && allProducts.length === 0) {
      setData(null);
    }
  };

  const fetchProductById = async (id) => {
    try {
      setOrderId(id);
      const response = await OrderServices.getDataById(
        `/order/api/orderDetails/getById/${id}`
      );

      const res = await OrderServices.getOrderHistoryByOrderId(id);

      if (res.data && Array.isArray(res.data)) {
        // setOrderUpdateHistoryData(res.data)
      }
      const orderData = response.data;
      console.log(orderData);
      setData(orderData);
      setCurrentStatus(orderData.statusId);
      if (orderData.contactDetails) {
        setContactDeatils(orderData.contactDetails);
        setDataId(orderData.contactDetails.id);
        if (orderData.contactDetails.emailId) {
          setEmail(orderData.contactDetails.emailId);
        }
        if (orderData.contactDetails.mobileNo) {
          const dialCode = extractDialCode(orderData.contactDetails.mobileNo);
          if (dialCode) {
            const country = findCountryByDialCode(dialCode);
            if (country) {
              setSelectedCountry(country);
            }
          }
        }
      }

      if (orderData.companyDetails) {
        setCompanyDetails(orderData.companyDetails);
      }
      setShowSkeleton(true);
      setShowSkeletonData(true);
      return response.data;
    } catch (error) {
      console.error("Error fetching product by ID:", error);
      setShowSkeleton(true);
      setShowSkeletonData(true);
      return null;
    }
  };

  const fetchOrderDetails = async (orderId) => {
    // setShowSkeleton(false)
    setShowSkeletonData(false);
    try {
      const response = await OrderServices.getDataById(
        `/order/api/orderDetails/getById/${orderId}`
      );

      const orderData = response ? response.data : null;
      console.log(orderData);

      if (orderData) {
        setData(orderData);
        setOrderId(orderId);
        setCurrentStatus(orderData.statusId);

        if (orderData.contactDetails) {
          setContactDeatils(orderData.contactDetails);
          setDataId(orderData.contactDetails.id);
          if (orderData.contactDetails.emailId) {
            setEmail(orderData.contactDetails.emailId);
          }
          if (orderData.contactDetails.mobileNo) {
            const dialCode = extractDialCode(orderData.contactDetails.mobileNo);
            if (dialCode) {
              const country = findCountryByDialCode(dialCode);
              if (country) {
                setSelectedCountry(country);
              }
            }
          }
        }

        if (orderData.companyDetails) {
          setCompanyDetails(orderData.companyDetails);
        }
      }

      setShowSkeleton(true);
      setShowSkeletonData(true);
    } catch (error) {
      console.error("Error fetching order details:", error);
      setShowSkeleton(true);
      setShowSkeletonData(true);
    }
  };

  const handleSelectedProduct1 = (product, allProducts) => {
    setSelectedProduct(product);
    setData(product);
    setCurrentStatus(product.statusId);

    const reorderedData = [
      ...allProducts.filter(
        (row) => row.orderDetails.id === product.orderDetails.id
      ),
      ...allProducts.filter(
        (row) => row.orderDetails.id !== product.orderDetails.id
      ),
    ];
    setProducts(reorderedData);
    setShowSkeleton(true);
    setShowSkeletonData(true);
  };

  useEffect(() => {
    setShowSkeleton(false);
    setShowSkeletonData(false);
    if (superAdminMainId) {
      fetchAllOrders();
    } else if (isClient) {
      fetchAllOrders();
    }
  }, [
    pageRefresh,
    globalCompany,
    superAdminId,
    superAdminMainId,
    isClient,
    redirectedData,
    filterOrderStatusId,
    sortedResponseData,
    managerId,
  ]);

  useEffect(() => {
    clearData();
    if (fetchedStateData) {
      setAllStates(fetchedStateData);
    }

    // MasterServices.getAll("/core/api/orderTypeMaster/getAll")
    //   .then((res) => {
    //
    //     if (res.data !== "") {
    //       setEntityTypes(res.data);
    //     }
    //   })
    //   .catch((err) => {
    //
    //   });

    // MasterServices.getAllTemplate()
    //   .then((res) => {
    //
    //     if (Array.isArray(res.data)) {
    //       setAllTemplate(res.data);
    //     }
    //   })
    //   .catch((err) => {
    //
    //   });
  }, [fetchedStateData]);

  useEffect(() => {
    if (fetchedTemplateData) {
      setAllTemplate(fetchedTemplateData);
    }
  }, [fetchedTemplateData]);

  useEffect(() => {
    if (fetchedOrderTypeData) {
      setEntityTypes(fetchedOrderTypeData);
    }
  }, [fetchedOrderTypeData]);

  useEffect(() => {
    setImageData(null);
    if (data && data.contactDetails && data.contactDetails.avatarImgPath) {
      MasterServices.getFile(data.contactDetails.avatarImgPath)
        .then((res) => {
          if (res.data) {
            setImageData(res.data);
          } else {
            setImageData(null);
          }
        })
        .catch((err) => {
          setImageData(null);
        });
    } else {
      setImageData(null);
    }
  }, [data]);

  useEffect(() => {
    if (
      data &&
      Array.isArray(data.orderLineItem) &&
      data.orderLineItem.length > 0
    ) {
      const temp = data.orderLineItem.reduce(
        (acc, curr) => acc + (curr.unitPrice || 0),
        0
      );

      if (temp === 0) {
        setIsPriceAvail(false);
      } else {
        setIsPriceAvail(true);
      }
    }
  }, [data]);

  useEffect(() => {
    // MasterServices.getAll("/core/api/statusMaster/getAll")
    //   .then((res) => {
    //
    if (fetchedStatusData && Array.isArray(fetchedStatusData)) {
      const temp = fetchedStatusData.filter(
        (val) => val.statusForId.statusForName === "ORDERS"
      );
      if (temp.length > 0) {
        if (superAdminMainId) {
          const filterStatus = orderStatusTemp.filter(
            (val) => val.statusName !== "DRAFT"
          );
          setAllOrderStatus(filterStatus);
        } else {
          setAllOrderStatus(orderStatusTemp);
        }
      }

      if (Array.isArray(temp)) {
        if (
          (superAdminId || superAdminMainId) &&
          currentStatus &&
          typeof currentStatus === "object"
        ) {
          const tempArr = temp.flatMap((item) => item.vstateEmpId);

          const filteredStatus = temp.filter((val) =>
            currentStatus?.vstateEmpId.some((value) => value === val.id)
          );

          setAllStatus(filteredStatus);
        } else {
          if (currentStatus && typeof currentStatus === "object") {
            const tempArr = temp?.flatMap((item) => item.vstateClientId);

            const filteredStatus = temp?.filter((val) =>
              currentStatus?.vstateClientId?.some((value) => value === val.id)
            );

            setAllStatus(filteredStatus);
          }
        }
      }
    }
    // })
    // .catch((err) => {
    //
    // });
  }, [globalCompany, superAdminId, currentStatus, fetchedStatusData]);

  const [editLoginValues, setEditLoginValues] = useState(
    loginDetails.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {})
  );

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    if (data) {
      if (data.orderTypeId) {
        const tempdata = entityTypes.filter(
          (val) => val.id === data.orderTypeId
        );
        if (tempdata.length > 0) {
          setEntityTypeData(tempdata[0].orderShortName);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (template) {
      setUpdateNoteText(template.templateDesc);
    }
  }, [template]);

  // const handleFilter = () => {
  //   setFilteredInput("");
  //   if (products.length === 0) return;

  //   let filteredContacts = products;

  //
  //

  //   if (statesId) {
  //     filteredContacts = filteredContacts.filter(
  //       (contact) => contact.stateId && contact.stateId === statesId
  //     );
  //   }

  //   if (entityType) {
  //     filteredContacts = filteredContacts.filter(
  //       (contact) => contact.orderTypeName === entityType.orderFullDesc
  //     );
  //   }

  //   if (statusName) {
  //
  //     filteredContacts = filteredContacts.filter(
  //       (contact) => contact.statusId && contact.statusId === statusName
  //     );
  //   }

  //   if (IsOrderPaid) {
  //
  //     if (IsOrderPaid === "Paid Orders") {
  //       filteredContacts = filteredContacts.filter(
  //         (contact) => contact.transactionDate !== ""
  //       );
  //     } else {
  //
  //       filteredContacts = filteredContacts.filter(
  //         (contact) => contact.transactionDate == "" && contact.statusId !== 7
  //       );
  //
  //     }
  //   }

  //   setProducts(filteredContacts);

  //   if (filteredContacts.length > 0) {
  //     fetchOrderDetails(filteredContacts[0].orderId);

  //     setSelectedProduct(filteredContacts[0]);
  //     setData(filteredContacts[0]);
  //     setCurrentStatus(filteredContacts[0].statusId);
  //   } else {
  //     setData(null);
  //   }
  // };

  // const handleSearch = () => {
  //   if (filteredInput !== "") {
  //     const lowerCaseInput = filteredInput.toLowerCase();

  //

  //

  //     const searchNestedObject = (obj) => {
  //       for (const key in obj) {
  //         if (obj[key] !== null) {
  //           if (typeof obj[key] === "object") {
  //             if (searchNestedObject(obj[key])) {
  //               return true;
  //             }
  //           } else if (
  //             typeof obj[key] === "string" &&
  //             obj[key].toLowerCase().includes(lowerCaseInput)
  //           ) {
  //             return true;
  //           } else if (
  //             typeof obj[key] === "number" &&
  //             obj[key] == lowerCaseInput
  //           ) {
  //             return true;
  //           }
  //         }
  //       }
  //       return false;
  //     };

  //     const temp = tempProduct.filter((val) => searchNestedObject(val));

  //

  //     setProducts(temp);

  //     if (temp.length > 0) {
  //       fetchOrderDetails(temp[0].orderId);

  //       setSelectedProduct(temp[0]);
  //       setData(temp[0]);
  //       setCurrentStatus(temp[0].statusId);
  //     } else {
  //       setData(null);
  //     }
  //   } else {
  //     setProducts(tempProduct);
  //   }
  // };

  // const handleClearFilter = () => {
  //   setProducts(tempProduct);

  //   if (tempProduct.length > 0) {
  //     if (superAdminMainId) {
  //       fetchOrderDetails(tempProduct[0].orderId);
  //     } else {
  //       fetchOrderDetails(tempProduct[0].orderId);
  //       setSelectedProduct(tempProduct[0]);
  //       setData(tempProduct[0]);
  //       setCurrentStatus(tempProduct[0].statusId);
  //     }
  //   }
  //   setStatesId(null);
  //   setStatusName(null);
  //   setEntityType(null);
  //   setFilteredInput("");
  //   setIsOrderPaid(null);
  // };

  //   const handleFilter = () => {
  //     setFilteredInput("");
  //     if (tempProduct.length === 0) return; // Ensure we use the full dataset

  //     let filteredContacts = [...tempProduct]; // Start filtering from the original data

  //
  //

  //     // Handle multiple states selection
  //     if (statesId && statesId.length > 0) {
  //         filteredContacts = filteredContacts.filter(
  //             (contact) => contact.stateId && statesId.includes(contact.stateId)
  //         );
  //     }

  //     if (entityType) {
  //         filteredContacts = filteredContacts.filter(
  //             (contact) => contact.orderTypeName === entityType.orderFullDesc
  //         );
  //     }

  //     if (statusName) {
  //
  //         filteredContacts = filteredContacts.filter(
  //             (contact) => contact.statusId && contact.statusId === statusName
  //         );
  //     }

  //     if (IsOrderPaid) {
  //
  //         if (IsOrderPaid === "Paid Orders") {
  //             filteredContacts = filteredContacts.filter(
  //                 (contact) => contact.transactionDate !== ""
  //             );
  //         } else {
  //
  //             filteredContacts = filteredContacts.filter(
  //                 (contact) => contact.transactionDate === "" && contact.statusId !== 7
  //             );
  //
  //         }
  //     }

  //     setProducts(filteredContacts);

  //     if (filteredContacts.length > 0) {
  //         fetchOrderDetails(filteredContacts[0].orderId);
  //         setSelectedProduct(filteredContacts[0]);
  //         setData(filteredContacts[0]);
  //         setCurrentStatus(filteredContacts[0].statusId);
  //     } else {
  //         setData(null);
  //     }
  // };

  useEffect(() => {
    handleFilter();
  }, [filteredInput, statesId, entityType, statusName, IsOrderPaid,companyManager]);

  const handleFilter = () => {
    if (tempProduct.length === 0) return; // Ensure original dataset is available

    let filteredContacts = [...tempProduct]; // Always start from original dataset

    // Apply Live Search
    if (filteredInput.trim() !== "") {
      const lowerCaseInput = filteredInput.toLowerCase();
      const searchNestedObject = (obj) => {
        for (const key in obj) {
          if (obj[key] !== null) {
            if (typeof obj[key] === "object") {
              if (searchNestedObject(obj[key])) return true;
            } else if (
              typeof obj[key] === "string" &&
              obj[key].toLowerCase().includes(lowerCaseInput)
            ) {
              return true;
            } else if (
              typeof obj[key] === "number" &&
              obj[key].toString().includes(lowerCaseInput)
            ) {
              return true;
            }
          }
        }
        return false;
      };
      filteredContacts = filteredContacts.filter((val) =>
        searchNestedObject(val)
      );
    }

    // Apply State Filter (MultiSelect)
    if (statesId && statesId.length > 0) {
      filteredContacts = filteredContacts.filter(
        (contact) => contact.stateId && statesId.includes(contact.stateId)
      );
    }

    // Apply Entity Type Filter
    if (entityType) {
      filteredContacts = filteredContacts.filter(
        (contact) => contact.orderTypeName === entityType.orderFullDesc
      );
    }

    // Apply Order Status Filter
    if (statusName) {
      filteredContacts = filteredContacts.filter(
        (contact) => contact.statusId && contact.statusId === statusName
      );
    }

    if (companyManager) {
      filteredContacts = filteredContacts.filter((contact) =>
        companyManager?.includes(contact.accountManagerName)
      );
    }

    // Apply Payment Status Filter
    // if (IsOrderPaid) {
    //   if (IsOrderPaid === "Paid Orders") {
    //     filteredContacts = filteredContacts.filter(
    //       (contact) => contact.transactionDate != ""
    //     );
    //   } else {
    //     filteredContacts = filteredContacts.filter(
    //       (contact) => contact.transactionDate === "" && contact.statusId !== 7
    //     );
    //   }
    // }
    if (IsOrderPaid) {
      if (IsOrderPaid === "Paid Orders") {
        filteredContacts = filteredContacts.filter((contact) => {
          console.log(contact, "contact");
          return (
            contact.transactionDate !== null && contact.statusName !== "DRAFT"
          );
        });
      } else {
        filteredContacts = filteredContacts.filter((contact) => {
          //
          return (
            contact.transactionDate === null &&
            contact.statusId !== 7 &&
            contact.type !== "Draft"
          );
        });
      }
    }

    // if (IsOrderPaid) {
    //
    //     if (IsOrderPaid === "Paid Orders") {
    //       filteredContacts = filteredContacts.filter(
    //         (contact) => contact.transactionDate !== ""
    //       );
    //     } else {
    //
    //       filteredContacts = filteredContacts.filter(
    //         (contact) => contact.transactionDate == "" && contact.statusId !== 7
    //       );
    //
    //     }
    //   }

    // Update Products List
    setProducts(filteredContacts);

    // Update Selected Data
    if (filteredContacts.length > 0) {
      fetchOrderDetails(filteredContacts[0].orderId);
      fetchInvoiceAvailability(filteredContacts[0].orderId);
      setSelectedProduct(filteredContacts[0]);
      // fetchInvoiceAvailability(filteredContacts[0])
      setData(filteredContacts[0]);
      console.log(filteredContacts);
      // setCurrentStatus(filteredContacts[0].statusId);
    } else {
      setData(null);
    }
  };

  // const handleSearch = () => {
  //     if (filteredInput !== "") {
  //         const lowerCaseInput = filteredInput.toLowerCase();

  //
  //

  //         const searchNestedObject = (obj) => {
  //             for (const key in obj) {
  //                 if (obj[key] !== null) {
  //                     if (typeof obj[key] === "object") {
  //                         if (searchNestedObject(obj[key])) {
  //                             return true;
  //                         }
  //                     } else if (
  //                         typeof obj[key] === "string" &&
  //                         obj[key].toLowerCase().includes(lowerCaseInput)
  //                     ) {
  //                         return true;
  //                     } else if (
  //                         typeof obj[key] === "number" &&
  //                         obj[key] == lowerCaseInput
  //                     ) {
  //                         return true;
  //                     }
  //                 }
  //             }
  //             return false;
  //         };

  //         const temp = tempProduct.filter((val) => searchNestedObject(val));

  //

  //         setProducts(temp);

  //         if (temp.length > 0) {
  //             fetchOrderDetails(temp[0].orderId);
  //             setSelectedProduct(temp[0]);
  //             setData(temp[0]);
  //             setCurrentStatus(temp[0].statusId);
  //         } else {
  //             setData(null);
  //         }
  //     } else {
  //         setProducts(tempProduct);
  //     }
  // };

  const handleSearch = () => {
    if (filteredInput.trim() !== "") {
      const lowerCaseInput = filteredInput.toLowerCase();

      // Ensure you're always filtering from original data

      const searchNestedObject = (obj) => {
        for (const key in obj) {
          if (obj[key] !== null) {
            if (typeof obj[key] === "object") {
              if (searchNestedObject(obj[key])) {
                return true;
              }
            } else if (
              typeof obj[key] === "string" &&
              obj[key].toLowerCase().includes(lowerCaseInput)
            ) {
              return true;
            } else if (
              typeof obj[key] === "number" &&
              obj[key].toString().includes(lowerCaseInput)
            ) {
              return true;
            }
          }
        }
        return false;
      };

      const temp = tempProduct.filter((val) => searchNestedObject(val));

      setProducts(temp);

      if (temp.length > 0) {
        fetchOrderDetails(temp[0].orderId);
        setSelectedProduct(temp[0]);
        // fetchInvoiceAvailability(temp[0]);
        setData(temp[0]);
        setCurrentStatus(temp[0].statusId);
      } else {
        setData(null);
      }
    } else {
      setProducts(tempProduct);
    }
  };

  // Live Search Effect
  useEffect(() => {
    handleSearch();
  }, [filteredInput]); // Runs whenever `filteredInput` changes

  const handleClearFilter = () => {
    setProducts(tempProduct);

    if (tempProduct.length > 0) {
      if (superAdminMainId) {
        fetchOrderDetails(tempProduct[0].orderId);
      } else {
        fetchOrderDetails(tempProduct[0].orderId);
        setSelectedProduct(tempProduct[0]);
        setData(tempProduct[0]);
        setCurrentStatus(tempProduct[0].statusId);
      }
    }

    setStatesId([]);
    setStatusName(null);
    SetCompanyManager(null);
    setEntityType(null);
    setFilteredInput("");
    setIsOrderPaid(null);
  };

  const handleCustomPrice = (e) => {
    setCustomPriceErr("");
    let value = e.target.value;

    if (value === "") {
      setCustomPriceErr("Custom price required and must be greater than $0");
    } else if (value.trim() !== "") {
      if (!/^\d+(\.\d{1,2})?$/.test(value)) {
        setCustomPriceErr(
          "Invalid price format. Must be a number with up to two decimal places."
        );
      } else if (parseFloat(value) <= 0) {
        setCustomPriceErr("Custom price must be greater than $0");
      }
    }

    setCustomPrice(parseFloat(value).toFixed(2));
  };

  const sortData = (field) => {
    if (field === sortField) {
      // If clicking on the same field, toggle the sort order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // If clicking on a different field, set the new field and default to ascending order
      setSortField(field);

      setSortOrder("asc");
    }
    const sortedData = [...products].sort((a, b) => {
      if (a.contactDetails !== undefined && b.contactDetails !== undefined) {
        const valueA = a.contactDetails[field];
        const valueB = b.contactDetails[field];

        if (valueA < valueB) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortOrder === "asc" ? 1 : -1;
        }
      }

      return 0;
    });

    setProducts(sortedData);
  };

  const handleFbackHeader = () => {
    return (
      <>
        <div className="font-fam-for-all form-md-heading form-sm-close-Btn">
          Pre-Screening Feedback
          <button className="form-close-Btn" onClick={() => setVisible6(false)}>
            <i className="pi pi-times"></i>
          </button>
        </div>
      </>
    );
  };

  const handleTabChange = (e) => {
    setActiveIndex(e.index);
    setIsEditing(false);
    setIsEditing1(false);
    setIsEditing2(false);
  };

  const handleSkill = (e) => {
    setSkills(e.target.value);
  };

  const handleQualification = (e) => {
    setQualification(e.target.value);
  };

  const PasswordText = (text) => {
    const maskedText = text.replace(/./g, "*");
    return maskedText;
  };

  const handleSave1 = () => {
    const newData = loginDetails.map((item) => ({
      ...item,
      value: editLoginValues[item.key],
    }));
    setLoginDetails(newData);

    if (
      password !== "" &&
      confirmedPassword !== "" &&
      oldPassword !== "" &&
      password === confirmedPassword
    ) {
      ForgotPasswordService.resetPassword(confirmedPassword, email)
        .then((res) => {
          setIsEditing(false);
          // if (toast.current !== null) {
          //   if (res.data === "Password changed successfully!") {
          //     toast.current.show({
          //       severity: "success",
          //       summary: "Success",
          //       detail: "Password updated successfully",
          //       life: 2800,
          //     });
          //   }
          // }

          showToast("success", "Password changed successfully!");
        })
        .catch((err) => {});
    } else {
      if (confirmedPassword === "") {
        setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
      }
      if (password === "") {
        setPasswordErr(errorMessages.passwordRequired);
      }
      if (oldPassword === "") {
        setOldPasswordErr(errorMessages.oldPasswordRequired);
      }
      if (password !== confirmedPassword) {
        // setVisible(true)
        // if (toast.current !== undefined) {
        //   toast.current.show({
        //     severity: "warn",
        //     summary: "Warning",

        //     content: (props) => (
        //       <div
        //         className="flex flex-column align-items-left"
        //         style={{ flex: "1" }}
        //       >
        //         <div className="flex align-items-center gap-2">
        //           <span className="font-bold text-900">vState Filings</span>
        //         </div>
        //         <div className="font-medium text-lg my-3 text-900">
        //           {errorMessages.passwordMatched}
        //         </div>
        //       </div>
        //     ),
        //     life: 3000,
        //   });
        // }

        showToast("error", `${errorMessages.passwordMatched}`);

        // setGlobalErr(errorMessages.passwordMatched)
      }
    }
  };

  const allSortName = ["name", "location", "experienc"];

  const handleSortName = (e) => {
    setSortName(e.target.value);

    sortData(e.target.value);
  };

  const handlePosition = (e) => {
    const temp = e.target.value;
    setPosition(e.target.value);
    if (temp.jobdescription.skill !== null) {
      setSkills(temp.jobdescription.skill);
    }
    if (temp.jobdescription.experience !== null) {
      const tempexp = temp.jobdescription.experience;
      const arrexp = tempexp.split("-");

      if (arrexp.length === 2) {
        setExp1(arrexp[0]);
        setExp2(arrexp[1]);
      }
    }
  };

  const handleClearPosition = () => {
    setVisible1(false);
    setVisible2(false);
    setPosition(null);
    setPositionData(null);
  };

  const handlePositionData = (e) => {
    setProducts(AllCandidate);
    const temp = e.target.value;
    setPositionData(e.target.value);
  };

  const SortingFilter = () => {
    return (
      <>
        <div>
          <Dropdown
            value={sortName}
            options={allSortName}
            onChange={handleSortName}
            placeholder="Sorting By"
            filter
            className="filter-dropdown-field"
            required
          />
        </div>
      </>
    );
  };

  const getState = (id) => {
    const temp = allStates.find((val) => val.id === id);

    if (temp !== undefined) {
      return temp.stateFullDesc;
    } else {
      return null;
    }
  };

  const getStateUrl = (id) => {
    const temp = allStates.find((val) => val.id === id);
    if (temp !== undefined) {
      return temp.stateUrl;
    } else {
      return null;
    }
  };

  const getCountry = (id) => {
    const temp = allCountries.find((val) => val.id === id);
    if (temp !== undefined) {
      return temp.countryFullDesc;
    } else {
      return null;
    }
  };

  const handleExp1 = (e) => {
    setProducts(AllCandidate);
    setExp1(e.target.value);
  };

  const handleExp2 = (e) => {
    setProducts(AllCandidate);
    setExp2(e.target.value);
  };

  const listExp = [];
  for (let i = 0; i < 50; i++) {
    listExp.push(`${i}`);
  }

  const filterData = (event) => {
    const filteredItems = products.filter((item) => {
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const element = item[key].toString();

          if (
            element.toLowerCase().includes(event.target.value.toLowerCase())
          ) {
            return item;
          }
        }
      }
    });
    setFilteredData(filteredItems);
  };

  // effect of filter all

  function checkAllExist(a, b) {
    // Convert all elements of array b to lowercase for case-insensitive comparison
    const lowercaseB = b.map((item) => item.toLowerCase());

    for (const element of a) {
      // Convert element to lowercase for case-insensitive comparison
      const lowercaseElement = element.toLowerCase();

      if (!lowercaseB.includes(lowercaseElement)) {
        return false; // If any element of a is not found in b, return false
      }
    }
    return true; // All elements of a were found in b
  }

  function convertMonthsToYears(years) {
    return years * 12;
  }

  // event on checkbox
  const onCheckboxChange = (event, data) => {
    if (event.checked) {
      setSelectedData([...selectedData, data]);
    } else {
      setSelectedData(selectedData.filter((item) => item !== data));
    }
  };

  const handleChecked = (e) => {
    const checked = e.checked;
    if (checked) {
      setSelectedData(products);
    } else {
      setSelectedData([]);
    }
  };

  // DataTable columns
  const cols = [
    { field: "id", header: "id" },
    { field: "fullName", header: "Name" },
    { field: "skill", header: "Skills" },
    { field: "experienceInYears", header: "Experience" },
    { field: "city", header: "Location" },
  ];

  const updateData = selectedData.map((elem) => {});

  // Export DataTable data
  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const CloseProgresbarheader1 = () => {
    setCloseProgresbar1(false);
    setPendingStatusDialog(false);
    setUpdateNoteText("");
    setUpdateStatus(null);
    setOrderStatusErr("");
    setOrderUpdateNotesErr("");
    setSelectedFile(null);
    setCustomPriceErr("");
    setCustomPrice(0.0);
    setTemplate(null);
  };

  const handleClick3 = () => {
    setVisible(false);
  };

  // Function to check if object exists in array
  function objectExists(array, obj) {
    return array.some((item) => JSON.stringify(item) === JSON.stringify(obj));
  }

  const getStatus = (data) => {
    if (Array.isArray(data)) {
      if (data.length > 0) {
        return data[data.length - 1].orderStatus.statusName;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getBadgeBackgroundColor = (data) => {
    const status =
      data.orderUpdateHistory.length > 0
        ? getStatus(data.orderUpdateHistory)
        : data.statusId
        ? data.statusId.statusName
        : "";

    switch (status) {
      case "ORDER CREATED":
        return "#ECFDF3";
      case "ORDER SUBMITTED":
        return "#ECFDF3";
      case "ORDER IN PROGRESS":
        return "#ECFDF3";
      case "ORDER COMPLETED":
        return "#ECFDF3";
      case "ORDER RESUBMITTED":
        return "#ECFDF3";
      case "PAY LATER REQUEST APPROVED":
        return "#ECFDF3";
      case "PAY LATER REQUEST REJECTED":
        return "#F9FAFB";
      case "DRAFT":
        return "#F9FAFB";
      default:
        return "#FFFAEB";
    }
  };

  const getBadgeBackgroundColor1 = (status) => {
    switch (status) {
      case "ORDER CREATED":
        return "#ECFDF3";
      case "ORDER SUBMITTED":
        return "#ECFDF3";
      case "ORDER IN PROGRESS":
        return "#ECFDF3";
      case "ORDER COMPLETED":
        return "#ECFDF3";
      case "ORDER RESUBMITTED":
        return "#ECFDF3";
      case "PAY LATER REQUEST APPROVED":
        return "#ECFDF3";
      case "PAY LATER REQUEST REJECTED":
        return "#F9FAFB";
      case "DRAFT":
        return "#F9FAFB";
      default:
        return "#FFFAEB";
    }
  };

  const getBadgeTextColor = (status) => {
    switch (status) {
      case "ORDER CREATED":
        return "#027A48";
      case "ORDER SUBMITTED":
        return "#027A48";
      case "ORDER IN PROGRESS":
        return "#027A48";
      case "ORDER COMPLETED":
        return "#027A48";
      case "ORDER RESUBMITTED":
        return "#027A48";
      case "PAY LATER REQUEST APPROVED":
        return "#027A48";
      case "PAY LATER REQUEST REJECTED":
        return "#344054";
      case "DRAFT":
        return "#344054";
      default:
        return "#B54708";
    }
  };

  const itemTemplate = (data) => {
    const parsedJson = data.orderDetails
      ? JSON.parse(data.orderDetails.strapiOrderFormJson)
      : {};

    if (data) {
      return (
        <div className="srch-cand-card font-fam-for-all">
          <div className="grid w-full">
            <div
              className="col-lg-8 col-md-8 col-sm-12 col-12 flex gap-2"
              style={{ width: "250px" }}
            >
              <div className="grid">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
                  <p className=" srch-cand-text1">{`ORDER-ID ${
                    data.orderDetails.orderId
                      ? data.orderDetails.orderId
                      : data.orderDetails.id
                  }`}</p>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
                  <p className=" srch-cand-text">
                    {data.orderDetails && data.orderDetails.orderTitle !== null
                      ? data.orderDetails.orderTitle
                      : data.companyId.companyName}
                  </p>
                  <p className=" srch-cand-text">
                    {data.state && data.state.stateFullDesc !== null
                      ? data.state.stateFullDesc
                      : ""}
                  </p>
                  <p className=" srch-cand-text">
                    {data.orderDetails.orderDate
                      ? formatISODate(data.orderDetails.orderDate)
                      : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 flex align-items-center justify-content-center">
              <>
                <div className="badge-container">
                  <div
                    className="badge-main grid"
                    style={{ background: getBadgeBackgroundColor(data) }}
                  >
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 flex align-items-center p-0">
                      {
                        // data.orderUpdateHistory.length > 0 ?
                        //     <span className='badge-label ml-1' style={{ color: getBadgeTextColor(getStatus(data.orderUpdateHistory)) }}>
                        //         {getStatus(data.orderUpdateHistory)}
                        //     </span>
                        //     :
                        <span
                          className="badge-label ml-1"
                          style={{
                            color: getBadgeTextColor(
                              data.statusId ? data.statusId.statusName : ""
                            ),
                          }}
                        >
                          {data.statusId ? data.statusId.statusName : ""}
                        </span>
                      }
                      {/* <span className='badge-label ml-1' style={{ color: getBadgeTextColor(data.statusId ? data.statusId.statusName : "") }}>
                                                        {data.statusId ? data.statusId.statusName : ""}
                                                    </span> */}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const itemTemplate1 = (data) => {
    if (data) {
      console.log(data, "data");
      return (
        <div className="srch-cand-card font-fam-for-all">
          <div className="grid w-full">
            <div
              className="col-lg-8 col-md-8 col-sm-12 col-12 flex gap-2"
              style={{ width: "250px" }}
            >
              <div className="grid">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
                  <p className=" srch-cand-text1">{`ORDER-ID ${
                    data.orderNumber ? data.orderNumber : data.orderId
                  }`}</p>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 pb-0">
                  <p className=" srch-cand-text">{data.displayName}</p>
                  <p className=" srch-cand-text">
                    <span className="font-semibold">Order Type:</span>{" "}
                    {data.orderTypeName}
                  </p>
                  <p className=" srch-cand-text">
                    <span className="font-semibold">State:</span>{" "}
                    {data.stateName}
                  </p>
                  <p className=" srch-cand-text">
                    {data.orderDate ? formatISODate(data.orderDate) : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12 flex align-items-center justify-content-center">
              <>
                <div className="badge-container">
                  <div
                    className="badge-main grid"
                    style={{
                      background: getBadgeBackgroundColor1(data.statusName),
                    }}
                  >
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 flex align-items-center p-0">
                      {
                        // data.orderUpdateHistory.length > 0 ?
                        //     <span className='badge-label ml-1' style={{ color: getBadgeTextColor(getStatus(data.orderUpdateHistory)) }}>
                        //         {getStatus(data.orderUpdateHistory)}
                        //     </span>
                        //     :
                        <span
                          className="badge-label ml-1"
                          style={{ color: getBadgeTextColor(data.statusName) }}
                        >
                          {data.statusName}
                        </span>
                      }
                      {/* <span className='badge-label ml-1' style={{ color: getBadgeTextColor(data.statusId ? data.statusId.statusName : "") }}>
                                                        {data.statusId ? data.statusId.statusName : ""}
                                                    </span> */}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const CloseProgresbarheader = () => {
    setCloseProgresbar(false);
    setPendingStatusDialog(false);
    setUpdateNoteText("");
    setUpdateStatus(null);
    setOrderStatusErr("");
    setOrderUpdateNotesErr("");
    setSelectedFile(null);
    setCustomPriceErr("");
    setCustomPrice(0.0);
    setTemplate(null);
  };

  const searchCandHeader = () => {
    return (
      <div className="font-fam-for-all flex flex-column gap-3">
        <div className="sm:flex-none md:flex md:justify-between">
          <p className="srch-cand-header mb-0 table-cand">
            Orders{" "}
            <Tag
              className="badge-cand ms-1"
              value={
                filteredData.length > 0 ? filterData.length : products.length
              }
            ></Tag>
          </p>
          <div className="srch-cand-card font-fam-for-all ">
            <Dropdown
              value={sortField}
              options={fields}
              onChange={onSortFieldChange}
              // defaultValue={fields}
              // placeholder="Select a field to sort"
              className="p-mr-2"
            />
            {
              <Button
                icon={
                  sortOrder === 1
                    ? "pi pi-sort-amount-up"
                    : "pi pi-sort-amount-down"
                }
                onClick={toggleSortOrder}
                tooltip={`Sort ${sortOrder === 1 ? "Ascending" : "Descending"}`}
                className="sort-btn-main"
              />
            }
            <div>
              <button className="refresh-btnn me-2" onClick={fetchAllOrders}>
                {/* <PiArrowClockwiseBold /> */}
                <PiArrowClockwiseBold
                  className={!showSkeleton ? "animate-spin" : ""}
                />
              </button>
            </div>
          </div>
        </div>

        <div className="card-devider"></div>
      </div>
    );
  };

  const handleOnlineForm = (data) => {
    if (data) {
      const autoData = data.orderDetails.strapiOrderFormJson;
      const parsedData = JSON.parse(data.orderDetails.strapiOrderFormJson);

      setIsTabRefresh(true);
      console.log(parsedData)
      UserServices.callAutomation(parsedData)
        .then((res) => {
          setIsTabRefresh(false);
        })
        .catch((err) => {
          setIsTabRefresh(false);
        });
    }
  };

  const pdfFiles = {
    "arizona_llc": arizona_llc,
    "arizona_corp": arizona_corp,
    "california_corp": california_corp,
    "california_llc": california_llc,
    "connecticut_corp": connecticut_corp,
    "connecticut_llc": connecticut_llc,
    "delaware_corp": delaware_corp,
    "delaware_llc": delaware_llc,
    "maine_corp": maine_corp,
    "maine_llc": maine_llc,
    "michigan_corp":michigan_corp,
    "michigan_llc":michigan_llc,
    "nevada_corp": nevada_corp,
    "nevada_llc": nevada_llc,
    "pennsylvania_llc": pennsylvania_llc,
    "pennsylvania_corp": pennsylvania_corp,
    "texas_corp": texas_corp,
    "texas_llc": texas_llc,
    "westvirginia_corp": westvirginia_corp,
    "westvirginia_llc": westvirginia_llc,
    "wisconsin_corp": wisconsin_corp,
    "wisconsin_llc": wisconsin_llc
};

// const handleOfflineForm = (data, state, corpOrLlc) => {
//   console.log(corpOrLlc.entityShortName, "corporllc");
//   console.log(state, "state");

//   const fileKey = `${state?.stateFullDesc.toLowerCase()}_${corpOrLlc.entityShortName.toLowerCase()}`;
//   const fileUrl = pdfFiles[fileKey];

//   if (!fileUrl) {
//     console.error("File not found:", fileKey);
//     return;
//   }

//   // Extract automation data
//   const autoData = data.orderDetails.strapiOrderFormJson;
//   const parsedData = JSON.parse(autoData);

//   setIsTabRefresh(true);

//   // Fetch the PDF file URL and convert it to a Blob
//   fetch(fileUrl)
//     .then((response) => {
//       if (!response.ok) throw new Error("Network response was not ok");
//       return response.blob();
//     })
//     .then((fileBlob) => {
//       // Now call the service with the file Blob and form data wrapped in FormData
//       return UserServices.callOfflineAutomation(fileBlob, parsedData);
//     })
//     .then((res) => {
//       // Process the returned blob (PDF) and trigger download
//       const blob = new Blob([res], { type: 'application/pdf' });
//       const url = window.URL.createObjectURL(blob);

//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', `${data.orderDetails.orderId}_offline_form.pdf`);

//       document.body.appendChild(link);
//       link.click();

//       link.parentNode.removeChild(link);
//       window.URL.revokeObjectURL(url);
//       setIsTabRefresh(false);
//     })
//     .catch((err) => {
//       console.error('Error downloading PDF:', err);
//       setIsTabRefresh(false);
//     });
// };

  
const handleOfflineForm = (data, state, corpOrLlc) => {
  console.log(corpOrLlc.entityShortName, "corporllc");
  console.log(state, "state");

  const fileKey = `${state?.stateFullDesc.toLowerCase()}_${corpOrLlc.entityShortName.toLowerCase()}`;
  const fileUrl = pdfFiles[fileKey];

  if (!fileUrl) {
    console.error("File not found:", fileKey);
    return;
  }

  // Extract automation data
  const autoData = data.orderDetails.strapiOrderFormJson;
  const parsedData = JSON.parse(autoData);

  setIsTabRefresh(true);

  // Fetch the PDF file URL and convert it to a Blob
  fetch(fileUrl)
  .then((response) => {
    if (!response.ok) throw new Error("Network response was not ok");
    return response.blob();
  })
  .then((fileBlob) => {
    const formDataPayload = new FormData();
    formDataPayload.append("pdf_file", fileBlob, `${data.orderDetails.orderId}_offline_form.pdf`);
    formDataPayload.append("form_data", JSON.stringify(parsedData));

    // Add responseType: 'blob' to Axios config
    return axios.post(
      `${propertiesApp.chatbot_url}/offline/api/process-form`,
      formDataPayload,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: 'blob' // ⬅️ Critical fix
      }
    );
  })
  .then((res) => {
    // Use res.data which contains the BLOB
    const url = window.URL.createObjectURL(res.data); // ⬅️ Use response data directly
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${data.orderDetails.orderId}_offline_form.pdf`);
    document.body.appendChild(link);
    link.click();
    })
    .catch((err) => {
      console.error('Error downloading PDF:', err);
      setIsTabRefresh(false);
    });
};




  const handleBoiSubmit = (data) => {
    OrderServices.submitBoiApplication(data.orderDetails)
      .then((res) => {
        // if (toast.current !== undefined) {
        //   toast.current.show({
        //     severity: "success",
        //     summary: "Success",

        //     content: (props) => (
        //       <div
        //         className="flex flex-column align-items-left"
        //         style={{ flex: "1" }}
        //       >
        //         <div className="flex align-items-center gap-2">
        //           <span className="p-toast-summary">vState Filings</span>
        //         </div>
        //         <div className="p-toast-detail">BOI filed successfully</div>
        //       </div>
        //     ),
        //     life: 3000,
        //   });
        // }

        showToast("success", "BOI filed successfully");
      })
      .catch((err) => {
        // if (toast.current !== undefined) {
        //   toast.current.show({
        //     severity: "warn",
        //     summary: "Success",

        //     content: (props) => (
        //       <div
        //         className="flex flex-column align-items-left"
        //         style={{ flex: "1" }}
        //       >
        //         <div className="flex align-items-center gap-2">
        //           <span className="p-toast-summary">vState Filings</span>
        //         </div>
        //         <div className="p-toast-detail">BOI filed failed</div>
        //       </div>
        //     ),
        //     life: 3000,
        //   });
        // }
        showToast("error", "BOI filed failed");
      });
  };

  const handleEditForm = (data) => {
    if (data) {
      setEditPayloadData(data);
      if (data.orderDetails?.orderTypeId === 1) {
        navigate({
          pathname: "/edit/entity",
        });
      } else {
        navigate({
          pathname: "/edit/orderform",
        });
      }
    }
  };

  const onRowSelect = (rowData) => {
    const layoutContainer = document.getElementById("root");

    if (layoutContainer) {
      layoutContainer.scrollTo({ top: 0, behavior: "smooth" });
    }
    setData(rowData.data);
    setCurrentStatus(rowData.data.statusId);
    if (rowData.data.contactDetails !== undefined) {
      setContactDeatils(rowData.data.contactDetails);
      setDataId(rowData.data.contactDetails.id);
      if (rowData.data.contactDetails.emailId) {
        setEmail(rowData.data.contactDetails.emailId);
        if (rowData.data.contactDetails.mobileNo !== undefined) {
          const dialCode = extractDialCode(
            rowData.data.contactDetails.mobileNo
          );

          if (dialCode) {
            const country = findCountryByDialCode(dialCode);
            if (country) {
              setSelectedCountry(country);
            }
          }
        }
      }
    }
    if (rowData.data.companyDetails !== undefined) {
      setCompanyDetails(rowData.data.companyDetails);
    }
  };

  const onRowSelect1 = (event) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const selectedOrderId = event.data.orderId; // Assuming `orderId` is the identifier for orders
    setSelectedProduct(event.data);

    fetchOrderDetails(selectedOrderId); // Fetch order details by `orderId` when a row is selected
    fetchInvoiceAvailability(selectedOrderId);
  };

  useEffect(() => {
    setActiveIndex(0);
  }, [selectedProduct]);

  const sidebarHeader = () => (
    <>
      <div
        style={{
          backgroundColor: "#002592",
          width: "contentFit",
          height: "40px",
          position: "sticky",
        }}
        className="sidebar-head"
      >
        <Button
          className="pi pi-times sidebar-close-btn"
          onClick={() => setFilters(false)}
        ></Button>
      </div>
    </>
  );

  const items = Array.from({ length: 5 }, (v, i) => i);

  const htmlStringToPdf = async (htmlString, fileName) => {
    let iframe = document.createElement("iframe");
    iframe.style.visibility = "hidden";
    document.body.appendChild(iframe);
    let iframedoc = iframe.contentDocument || iframe.contentWindow.document;

    iframedoc.open();
    iframedoc.write(htmlString);
    iframedoc.close();

    const doc = new jsPDF("p", "pt", "legal");

    await doc.html(iframedoc.body, {
      callback: function (doc) {
        doc.save(`${fileName}.pdf`);
      },
      margin: [10, 10, 10, 10],
      autoPaging: "text",
      x: 0,
      y: 0,
      width: 1250,
      windowWidth: 1570,
    });

    document.body.removeChild(iframe);
  };

  const handleDownload = (data) => {
    if (data.state) {
      const match = stateHtml.find(
        (htmlItem) => htmlItem.state === data.state.stateFullDesc
      );
      if (match) {
        htmlStringToPdf(match.html, match.state);
      }
    }
  };

  const formatDataForExcel = (jsonData) => {
    return jsonData.map((item) => ({
      "Order ID": item.orderId,
      "Order Title": item.displayName,
      "Order Date": item.orderDate,
      "Order State": item.stateName,
      "Entity Type": item.orderTypeName,
      "Order Amount": parseFloat(item.orderAmount).toFixed(2) || "",
      Status: item.statusName,
    }));
  };

  const exportExcel = () => {
    const data = formatDataForExcel(products);

    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "order");
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, fileName + EXCEL_EXTENSION);
      }
    });
  };

  const events = [
    {
      status: "Ordered",
      date: "15/10/2020 10:30",
      icon: "pi pi-shopping-cart",
      color: "#9C27B0",
      image: "game-controller.jpg",
    },
    {
      status: "Processing",
      date: "15/10/2020 14:00",
      icon: "pi pi-cog",
      color: "#673AB7",
    },
    {
      status: "Shipped",
      date: "15/10/2020 16:15",
      icon: "pi pi-shopping-cart",
      color: "#FF9800",
    },
    {
      status: "Delivered",
      date: "16/10/2020 10:00",
      icon: "pi pi-check",
      color: "#607D8B",
    },
  ];

  const customizedMarker = (item) => {
    return (
      <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
        >
          <path
            d="M29.8337 14.7733V16C29.832 18.8752 28.901 21.6729 27.1794 23.9758C25.4579 26.2786 23.038 27.9633 20.2808 28.7785C17.5236 29.5938 14.5767 29.4959 11.8796 28.4994C9.18257 27.503 6.87986 25.6615 5.31494 23.2494C3.75001 20.8374 3.00671 17.9841 3.19589 15.1151C3.38508 12.2461 4.4966 9.51509 6.3647 7.32942C8.2328 5.14375 10.7574 3.6205 13.5619 2.98685C16.3664 2.35321 19.3007 2.64311 21.927 3.81333M29.8337 5.33333L16.5003 18.68L12.5003 14.68"
            stroke="#039855"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    );
  };

  const DateFormat = (data) => {
    const dateString = data;
    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    return <div>{formattedDate}</div>;
  };

  const customizedContent = (item) => {
    return (
      <div className="order-details-card-text-container">
        <div className="order-details-card-text-container-header">
          <div className="order-details-card-text-container-header-text1">
            {item.orderStatus.statusName}
          </div>
          <div className="order-details-card-text-container-header-text2">
            {DateFormat(item.orderUpdateHistory.orderUpdatedDate)}
          </div>
        </div>
        <ul>
          <li className="order-details-card-text-list">
            {item.orderUpdateHistory.orderUpdateNotes}
          </li>
          <li className="order-details-card-text-list">
            Updated by{" "}
            <span className="order-contact-card-header-text2">
              {item.orderUpdateHistory.orderUpdatedBy}
            </span>
          </li>
        </ul>
      </div>
    );
  };

  const handlePendingStatusDialog = (data) => {
    setOrderTempData(data);
    setPendingStatusDialog(true);
  };

  const handleApproveDialog = (data) => {
    setOrderTempData(data);
    setApprovalDialog(true);
  };

  const handleApproveSubmit = () => {
    const currentDate = new Date();
    const isoDate = currentDate.toISOString();

    if (
      orderTempdata &&
      updateNoteText !== "" &&
      (status.approve || status.reject)
    ) {
      if (status.approve === true) {
        const temp = orderTempdata.orderDetails;

        temp["statusId"] = 1002;
        temp["isPayLater"] = true;
        temp["orderUpdateNotes"] = updateNoteText;
        temp["orderUpdatedBy"] = superAdminId
          ? `vState-${loginUserName}`
          : loginUserName;
        temp["orderUpdatedDate"] = isoDate;
        temp["orderLineItems"] = orderTempdata.orderLineItem;
        temp["stateId"] = orderTempdata.state ? orderTempdata.state.id : null;
        temp["countyId"] = orderTempdata.orderDetails.countyId;

        if (updateNoteText !== "") {
          setIsTabRefresh(true);
          OrderServices.saveAllOrder(temp)
            .then((res) => {
              const invoiceData = {
                orderId: res.data?.orderDetails,
                invoiceNo: `INV ${res.data?.orderDetails?.orderId}`,
                totalAmount: res.data?.orderDetails?.orderAmount,
                orderLineItems: orderTempdata.orderLineItem,
              };
              if (
                !addOnKey.includes(orderTempdata.orderTypeID.orderShortName)
              ) {
                // OrderServices.saveAllInvoice(invoiceData)
                //   .then((res) => { })
                //   .catch((err) => { });
                const PaidOrderdata = {
                  // id: res.data.id,
                  orderId: res.data?.orderDetails,
                  invoiceNo: `INV ${res.data?.orderDetails.orderId}`,
                  totalAmount: res.data?.orderDetails.orderAmount,
                  statusId: res.data?.statusId.id,
                  // quickbooksId: res?.data?.quickbooksId,
                  hubspotId: res.data?.statusId.hubspotId,
                  orderLineItems: orderTempdata.orderLineItem,
                  base64Data: "",
                  invoiceUpdateNotes: "Payment Due",
                  invoiceUpdatedBy: "null",
                };
                OrderServices.saveAllInvoice(PaidOrderdata)
                  .then((res) => {
                    // modal ->" " customprice
                  })
                  .catch((err) => {});
              }

              const temp = res.data.orderDetails;
              temp["statusId"] = 9;
              temp["isPayLater"] = true;
              temp["orderUpdateNotes"] = updateNoteText;
              temp["orderUpdatedBy"] = superAdminId
                ? `vState-${loginUserName}`
                : loginUserName;
              temp["orderUpdatedDate"] = isoDate;
              temp["orderLineItems"] = orderTempdata.orderLineItem;
              temp["stateId"] = orderTempdata.state
                ? orderTempdata.state.id
                : null;
              temp["countyId"] = orderTempdata.orderDetails.countyId;

              OrderServices.saveAllOrder(temp)
                .then((res) => {
                  console.log(res);
                  setSortedResponseData(res.data.orderDetails);
                  setApprovalDialog(false);
                  setIsTabRefresh(false);
                  setUpdateNoteText("");
                  setOrderStatusErr("");
                  setOrderUpdateNotesErr("");
                  setSelectedFile(null);
                  setTemplate(null);
                  showToast("success", "Order status updated successfully");
                })
                .catch((err) => {
                  console.log(err);
                  showToast("error", "Order status update failed");
                });
            })
            .catch((err) => {
              setIsTabRefresh(false);
              showToast("error", "Order status update failed");
            });
        }
      } else if (status.reject === true) {
        const temp = orderTempdata.orderDetails;

        temp["statusId"] = 1003;
        temp["isPayLater"] = false;
        temp["orderUpdateNotes"] = updateNoteText;
        temp["orderUpdatedBy"] = superAdminId
          ? `vState-${loginUserName}`
          : loginUserName;
        temp["orderUpdatedDate"] = isoDate;
        temp["orderLineItems"] = orderTempdata.orderLineItem;
        temp["stateId"] = orderTempdata.state ? orderTempdata.state.id : null;
        temp["countyId"] = orderTempdata.orderDetails.countyId;
        const tempdata = {
          orderId: temp,
          orderUpdateNotes: updateNoteText,
          orderUpdatedBy: loginUserName,
          status: 7,
          orderJson: JSON.stringify(temp),
          strapiJson: orderTempdata.orderDetails.strapiOrderFormJson,
          orderUpdatedDate: isoDate,
        };
        if (updateNoteText !== "") {
          setIsTabRefresh(true);
          OrderServices.saveAllOrder(temp)
            .then((res) => {
              setSortedResponseData(res.data.orderDetails);
              setApprovalDialog(false);
              setIsTabRefresh(false);
              setUpdateNoteText("");
              setOrderStatusErr("");
              setOrderUpdateNotesErr("");
              setSelectedFile(null);
              setTemplate(null);
              showToast("success", "Order status updated successfully");
            })
            .catch((err) => {
              setIsTabRefresh(false);
              showToast("error", "Order status update failed");
            });
        }
      }
    } else {
      if (updateNoteText === "") {
        setOrderUpdateNotesErr("Order update notes required");
      }
      if (status.approve === false && status.reject === false) {
        setOrderStatusErr("Order status required");
      }
    }
  };

  const addOnKey = [
    "Dissolution",
    "Reinstatement",
    "Conversion",
    "Merger",
    "Sidewalk Cafe Publication",
    "Apostile",
    "Non Profit",
    "501c3",
    "Biennial Report",
    "DBA – Certificate Of Assumed Name",
    "Independent Manager",
    "Annual Report",
    "DBA Filing",
    "Corporate Seal Service",
  ];

  const allowedStatusesAfterPayLater = [
    "VSTATE QUERY RAISED",
    "CLIENT RESPONENDED",
    "RESPONENDED TO QUERY",
    "IN PROGRESS",
    "SUBMITTED",
  ];

  const handleUpdateStatusSubmit = () => {
    const currentDate = new Date();

    const isoDate = currentDate.toISOString();

    let isValid = true;

    if (!updateNoteText || updateNoteText.trim() === "") {
      setOrderUpdateNotesErr("Order update notes required");
      isValid = false;
    }

    if (!updateStatus) {
      setOrderStatusErr("Order status required");
      isValid = false;
    }

    if (
      orderTempdata &&
      orderTempdata.orderTypeID &&
      addOnKey.includes(orderTempdata.orderTypeID.orderShortName) &&
      orderTempdata?.orderDetails?.transactionId === null &&
      currentStatus &&
      (currentStatus.statusName === "IN REVIEW" ||
        currentStatus.statusName === "CLIENT RESPONDED") &&
      (!customPrice || customPrice <= 0) &&
      !isInvoiceAvail
    ) {
      setCustomPriceErr("Custom price is required and must be greater than $0");
      isValid = false;
    }
    if (
      orderTempdata &&
      orderTempdata.orderTypeID &&
      orderTempdata.orderTypeID.orderShortName === "Entity Formation" &&
      updateStatus &&
      updateStatus.statusName === "ORDER COMPLETED" &&
      !selectedFile
    ) {
      showToast("error", "Order document required");
      isValid = false;
    }

    if (!isValid) return;

    if (orderTempdata && customPriceErr === "") {
      if (selectedFile && updateStatus && updateNoteText !== "") {
        setIsTabRefresh(true);
        const formdata = new FormData();
        formdata.append("file", selectedFile);
        
        setIsTabRefresh(true);
        MasterServices.saveFile("orderdoc", formdata)
          .then((response) => {
            if (response.status === 201 || response.status === 200) {
              if (data) {
                const temp = orderTempdata.orderDetails;
                const orderLineItem = orderTempdata.orderLineItem;
                const orderDoc = {
                  documentPath: response.data,
                  documentName: selectedFile ? selectedFile.name : "file",
                };

                MasterServices.saveDocument(orderDoc)
                  .then((res) => {
                    if (Array.isArray(temp["documentsId"])) {
                      temp["documentsId"] = [...temp["documentsId"], res.data];
                    } else {
                      temp["documentsId"] = [res.data];
                    }
                    temp["statusId"] = updateStatus.id;
                    temp["orderLineItems"] = orderLineItem;
                    temp[
                      "orderUpdateNotes"
                    ] = `${selectedFile.name} file uploaded`;
                    temp["orderUpdatedBy"] =
                      superAdminId || superAdminMainId
                        ? `vState-${loginUserName}`
                        : loginUserName;
                    temp["actualCompletionDate"] =
                      updateStatus.statusName === "ORDER COMPLETED"
                        ? new Date().toISOString()
                        : null;
                    temp["orderUpdatedDate"] = isoDate;

                    if (
                      orderTempdata &&
                      orderTempdata.orderTypeID &&
                      addOnKey.includes(
                        orderTempdata.orderTypeID.orderShortName
                      ) &&
                      customPrice !== 0
                    ) {
                      temp["orderAmount"] = customPrice;
                      if (Array.isArray(orderTempdata.orderLineItem)) {
                        orderTempdata.orderLineItem.forEach((val) => {
                          val.unitPrice = customPrice;
                        });
                      }
                    }

                    temp["orderLineItems"] = orderTempdata.orderLineItem;
                    temp["stateId"] = orderTempdata.state
                      ? orderTempdata.state.id
                      : null;
                    temp["countyId"] = orderTempdata.orderDetails.countyId;

                    if (
                      orderTempdata &&
                      orderTempdata.orderTypeID &&
                      orderTempdata.orderTypeID.orderShortName ===
                        "Entity Formation" &&
                      updateStatus.statusName === "ORDER COMPLETED" &&
                      comapnyData
                    ) {
                      companyMasterService
                        .saveCompany(comapnyData)
                        .then((res) => {
                          const mergeData = {
                            parentCompanyId: orderTempdata?.companyId?.id,
                            childCompanyId: res.data.id,
                          };

                          companyMasterService
                            .saveCompanyMerge(mergeData)
                            .then((res) => {
                              showToast("success", "Entity added successfully");
                            })
                            .catch((err) => {});

                          temp["companyId"] = res.data.id;
                          temp["orderTitle"] = res.data?.companyName;
                          OrderServices.saveAllOrder(temp).then((res) => {
                            setSortedResponseData(res.data.orderDetails);
                            setPendingStatusDialog(false);
                            setUpdateNoteText("");
                            setUpdateStatus(null);
                            setSelectedFile(null);
                            setIsTabRefresh(false);
                            setVisible(false);
                            setTemplate(null);

                            if (
                              orderTempdata &&
                              orderTempdata.orderTypeID &&
                              addOnKey.includes(
                                orderTempdata.orderTypeID.orderShortName
                              ) &&
                              customPrice !== 0
                            ) {
                              const invoiceData = {
                                orderId: res.data?.orderDetails,
                                invoiceNo: `INV ${res.data?.orderDetails?.orderId}`,
                                totalAmount: customPrice,
                                orderLineItems: orderTempdata.orderLineItem,
                              };
                              //   OrderServices.saveAllInvoice(invoiceData)
                              //     .then((res) => {

                              //     })
                              //     .catch((err) => {

                              //     });
                              // }
                            }

                            // showToast("error", "Order status update failed");
                          });
                          OrderServices.updateOrderMigrateById(
                            orderTempdata?.orderDetails?.id,
                            res.data.id
                          )
                            .then((res) => {})
                            .catch((err) => {});
                        })
                        .catch((err) => {
                          showToast("error", "Order status update failed");
                        });
                    } else {
                      OrderServices.saveAllOrder(temp)
                        .then((res) => {
                          setSortedResponseData(res.data.orderDetails);
                          setPendingStatusDialog(false);
                          setUpdateNoteText("");
                          setUpdateStatus(null);
                          setSelectedFile(null);
                          setIsTabRefresh(false);
                          setVisible(false);
                          setTemplate(null);

                          if (
                            orderTempdata &&
                            orderTempdata.orderTypeID &&
                            addOnKey.includes(
                              orderTempdata.orderTypeID.orderShortName
                            ) &&
                            customPrice !== 0 &&
                            updateStatus.statusName === "ORDER IN PROGRESS"
                          ) {
                            // const invoiceData = {
                            //   orderId: res.data?.orderDetails,
                            //   invoiceNo: `INV ${res.data?.orderDetails?.orderId}`,
                            //   totalAmount: customPrice,
                            //   orderLineItems: orderTempdata.orderLineItem,
                            // };
                            // OrderServices.saveAllInvoice(invoiceData)
                            //   .then((res) => {
                            //     // modal ->" " customprice
                            //   })
                            //   .catch((err) => { });
                            const PaidOrderdata = {
                              // id: res.data.id,
                              orderId: res.data?.orderDetails,
                              invoiceNo: `INV ${res.data?.orderDetails.orderId}`,
                              totalAmount: res.data?.orderDetails.orderAmount,
                              statusId: res.data?.statusId.id,
                              // quickbooksId: res?.data?.quickbooksId,
                              hubspotId: res.data?.statusId.hubspotId,
                              orderLineItems: orderTempdata.orderLineItem,
                              base64Data: "",
                              invoiceUpdateNotes: "Payment Due",
                              invoiceUpdatedBy: "null",
                            };
                            OrderServices.saveAllInvoice(PaidOrderdata)
                              .then((res) => {
                                // modal ->" " customprice



                                
                              })
                              .catch((err) => {});
                          }
                          showToast(
                            "success",
                            "Order status updated successfully"
                          );
                        })
                        .catch((err) => {
                          setIsTabRefresh(false);
                          showToast("error", "Order status update failed");
                        });
                    }
                  })
                  .catch((err) => {
                    setIsTabRefresh(false);

                    showToast("error", "Something went wrong");
                  });
              }
            }
            if (response.status === 500) {
              showToast("error", "Network error, Please try again!");
            }
            if (response.status === 504) {
              showToast("error", "Gateway timeout!");
            }
          })
          .catch((err) => {
            setIsTabRefresh(false);
          });
      } else if (updateStatus && updateNoteText !== "") {
        setIsTabRefresh(true);

        const temp = orderTempdata.orderDetails;

        temp["statusId"] = updateStatus.id;
        temp["orderUpdateNotes"] = updateNoteText;
        temp["orderUpdatedBy"] =
          superAdminId || superAdminMainId
            ? `vState-${loginUserName}`
            : loginUserName;
        temp["actualCompletionDate"] =
          updateStatus.statusName === "ORDER COMPLETED"
            ? new Date().toISOString()
            : null;
        temp["orderUpdatedDate"] = isoDate;

        if (
          orderTempdata &&
          orderTempdata.orderTypeID &&
          addOnKey.includes(orderTempdata.orderTypeID.orderShortName) &&
          customPrice !== 0
        ) {
          temp["orderAmount"] = customPrice;
          if (Array.isArray(orderTempdata.orderLineItem)) {
            orderTempdata.orderLineItem.forEach((val) => {
              val.unitPrice = customPrice;
            });
          }
        }
        temp["orderLineItems"] = orderTempdata.orderLineItem;
        temp["stateId"] = orderTempdata.state ? orderTempdata.state.id : null;
        temp["countyId"] = orderTempdata.orderDetails.countyId;

        OrderServices.saveAllOrder(temp)
          .then((res) => {
            setSortedResponseData(res.data.orderDetails);
            setPendingStatusDialog(false);
            setUpdateNoteText("");
            setUpdateStatus(null);
            setTemplate(null);
            setIsTabRefresh(false);
            if (
              orderTempdata &&
              orderTempdata.orderTypeID &&
              addOnKey.includes(orderTempdata.orderTypeID.orderShortName) &&
              customPrice !== 0 &&
              updateStatus.statusName === "ORDER IN PROGRESS"
            ) {
              // const invoiceData = {
              //   orderId: res.data?.orderDetails,
              //   invoiceNo: `INV ${res.data?.orderDetails?.orderId}`,
              //   totalAmount: customPrice,
              //   orderLineItems: orderTempdata.orderLineItem,
              // };
              // OrderServices.saveAllInvoice(invoiceData)
              //   .then((res) => { })
              //   .catch((err) => { });

              const PaidOrderdata = {
                // id: res.data.id,
                orderId: res.data?.orderDetails,
                invoiceNo: `INV ${res.data?.orderDetails.orderId}`,
                totalAmount: res.data?.orderDetails.orderAmount,
                statusId: res.data?.statusId.id,
                // quickbooksId: res?.data?.quickbooksId,
                hubspotId: res.data?.statusId.hubspotId,
                orderLineItems: orderTempdata.orderLineItem,
                base64Data: "",
                invoiceUpdateNotes: "Payment Due",
                invoiceUpdatedBy: "null",
              };
              OrderServices.saveAllInvoice(PaidOrderdata)
                .then((res) => {
                  // modal ->" " customprice
                })
                .catch((err) => {});
            }

            showToast("success", "Order status updated successfully");
          })
          .catch((err) => {
            setIsTabRefresh(false);

            showToast("error", "Order status update failed");
          });
      } else {
        if (updateNoteText === "") {
          setOrderUpdateNotesErr("Order update notes required");
        }

        if (!updateStatus) {
          setOrderStatusErr("Order status required");
        }
      }
    }
  };
  const convertTimestampToDate1 = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toISOString();
  };

  //   const stripePay = async (item, orderData) => {

  //     try {
  //       // Search for the session
  //       setIsTabRefresh(true);
  //       OrderServices.searchSession(data?.orderDetails?.orderId).then(async (response) => {

  //         let expiry = response?.data?.expiry
  //           let metadata =response?.data?.metadata

  //           let paymentMethod = response?.data?.paymentMethodType
  //           let paymentLastFour= response.data?.last4Digits

  //           if (response.status === 204) {
  //             const stripePromise = loadStripe(
  //               "pk_test_51PyTdiKhsNfb7YuLO8XP7SHE3FUn3rspK3sI7OTbOIulKp9XdM7WtU4gqjxiKVDVBldIKIEBvL8PLjdKkW1h8f1100bw75rZPd"
  //             );

  //             try {
  //               const stripe = await stripePromise;

  //               const productPaydata = item.map((val) => ({
  //                 name: val.productListing.displayName,
  //                 unitAmount: Math.round(val.unitPrice * 100),
  //                 quantity: 1,
  //               }));

  //               const paytemp = {
  //                 orderId: orderData?.orderId ? orderData?.orderId.toString() : "",
  //                 lineItems: productPaydata,
  //                 companyName: metadata?.companyName,
  //                 emailId: metadata?.emailId,
  //                 entityName: metadata?.entityName
  //               };
  //               console.log(paytemp,"paytemp")
  //               localStorage.setItem('paytemp', JSON.stringify(paytemp));

  //               // Refresh the tab state and make payment order

  //               OrderServices.paymentOrder(paytemp)
  //                 .then((response) => {
  //                   setIsTabRefresh(false);
  //                   localStorage.setItem("sessionId", response.data.sessionId);

  //                   const localData = {
  //                     data: orderData,
  //                     res: response.data,
  //                     orderLineItem: item,
  //                     updatedBy: loginUserName,
  //                   };

  //                   localStorage.setItem("payment", JSON.stringify(localData));

  //                   // Redirect to checkout URL after a brief timeout
  //                   setTimeout(() => {
  //                     window.location.href = response.data.checkoutUrl;
  //                   }, 1000);
  //                 })
  //                 .catch((err) => {
  //                   setIsTabRefresh(false);
  //                   console.error("Payment order error:", err);
  //                 });

  //             } catch (err) {
  //               console.error("Stripe checkout initialization error:", err);
  //             }
  //           } else {
  //             OrderServices.isInvoiceAvailable(orderData?.orderDetails?.id).then((res) => {

  //               if (res.status === 204) {
  //                 const PaidOrderdata = {
  //                   // id: res?.data?.id,
  //                   orderId: orderData?.orderDetails,
  //                   invoiceNo: orderData?.orderDetails?.orderId,
  //                   totalAmount: orderData?.orderDetails?.orderAmount,
  //                   statusId: orderData?.orderDetails?.statusId,
  //                   // quickbooksId: res?.data?.quickbooksId,
  //                   hubspotId: orderData?.statusId?.hubspotId,
  //                   orderLineItems: item,
  //                   invoiceUpdateNotes: "Payment Done",
  //                   invoiceUpdatedBy: "null",
  //                   base64Data: ""
  //                 }

  //                 OrderServices.saveAllInvoice(PaidOrderdata)
  //                   .then(async (res) => {
  //                     if (res.data) {
  //                       // return;
  //                       PaidOrderdata["id"] = res.data.id
  //                       PaidOrderdata["quickbooksId"] = res?.data?.quickbooksId
  //                       OrderServices.saveAllInvoice(PaidOrderdata)
  //                       .then(async (res) => {
  //                        console.log("quickbooks successfully updated with paid")
  //                        const tempdata = orderData?.orderDetails
  //                        tempdata["transactionId"] = response.data.id;
  //                        tempdata["generateReceipt"] = true;
  //                        tempdata["orderLineItems"] = item;
  //                        tempdata["orderUpdatedBy"] = loginUserName;
  //                        tempdata["orderUpdateNotes"] = "Payment done successfully";

  //                        tempdata["transactionDate"] = convertTimestampToDate1(
  //                          response.data.created
  //                        );
  //                        OrderServices.orderUpdate(tempdata)
  //                          .then((res) => {
  //                            showToast("success", "Transaction updated successfully")
  //                            setIsTabRefresh(false);
  //                            setCloseProgresbar3(false);
  //                          })
  //                          .catch((err) => {
  //                            console.log(err)
  //                            setIsTabRefresh(false);
  //                          })
  //                       })
  //                       .catch((err) => {
  //                         console.error("Error saving invoice:", err);
  //                         setIsTabRefresh(false);
  //                       });
  //                     }
  //                   })
  //                   .catch((err) => {
  //                     console.error("Error saving invoice:", err);
  //                     setIsTabRefresh(false);
  //                   });

  //               } else {
  //                 const PaidOrderdata = {
  //                   id: res?.data?.id,
  //                   orderId: orderData?.orderDetails,
  //                   invoiceNo: orderData?.orderDetails?.orderId,
  //                   totalAmount: orderData?.orderDetails?.orderAmount,
  //                   statusId: orderData?.orderDetails?.statusId,
  //                   quickbooksId: res?.data?.quickbooksId,
  //                   hubspotId: orderData?.statusId?.hubspotId,
  //                   orderLineItems: item,
  //                   invoiceUpdateNotes: "Payment Done",
  //                   invoiceUpdatedBy: "null",
  //                   base64Data: ""
  //                 }

  // // checkpayment response.
  //                 console.log(res.data,"dsafadsfdas")

  //                 OrderServices.saveAllInvoice(PaidOrderdata)
  //                   .then(async (res) => {
  //                     if (res.data) {
  //                       // return;
  //                       const tempdata = orderData?.orderDetails
  //                       tempdata["transactionId"] = response.data.id;
  //                       tempdata["generateReceipt"] = true;
  //                       tempdata["orderLineItems"] = item;
  //                       tempdata["orderUpdatedBy"] = loginUserName;
  //                       tempdata["orderUpdateNotes"] = "Payment done successfully";

  //                       tempdata["transactionDate"] = convertTimestampToDate1(
  //                         response.data.created
  //                       );
  //                       OrderServices.orderUpdate(tempdata)
  //                         .then((res) => {
  //                           showToast("success", "Transaction updated successfully")
  //                           setIsTabRefresh(false);
  //                           setCloseProgresbar3(false);
  //                         })
  //                         .catch((err) => {
  //                           console.log(err)
  //                           setIsTabRefresh(false);
  //                         })
  //                     }
  //                   })
  //                   .catch((err) => {
  //                     console.error("Error saving invoice:", err);
  //                     setIsTabRefresh(false);
  //                   });
  //               }

  //             }).catch((res) => {
  //               setIsTabRefresh(false);
  //               return
  //             })
  //           }
  //         });
  //     } catch (err) {
  //       console.error("Error during session search or invoice save:", err);
  //       setIsTabRefresh(false);
  //     }
  //     // }

  //   }



  const stripePay = async (item, orderData) => {
    try {
      const productPaydata = item.map((val) => ({
        name: val.productListing.displayName,
        unitAmount: Math.round(val.unitPrice * 100),
        quantity: 1,
      }));

      console.log(orderData,"orderData")
      setIsTabRefresh(true);
      OrderServices.isInvoiceAvailable(orderData?.orderDetails?.id)
        .then((res) => {
          if (res.status === 204) {
            const PaidOrderdata = {
              // id: res?.data?.id,
              orderId: orderData?.orderDetails,
              invoiceNo: orderData?.orderDetails?.orderId,
              totalAmount: orderData?.orderDetails?.orderAmount,
              statusId: orderData?.orderDetails?.statusId,
              // quickbooksId: res?.data?.quickbooksId,
              hubspotId: orderData?.statusId?.hubspotId,
              orderLineItems: item,
              invoiceUpdateNotes: "Payment Done",
              invoiceUpdatedBy: "null",
              base64Data: ""
            };
            setModalShow(true);
  
            OrderServices.saveAllInvoice(PaidOrderdata)
              .then(async (res) => {
                if (res.data) {
                  PaidOrderdata["id"] = res.data.id;
                  PaidOrderdata["quickbooksId"] = res?.data?.quickbooksId;
                  const saveTransactionPayload = {
                    transactionType: "CARD",
                    transactionStatus: 30,
                    amount: res.data?.totalAmount,
                    orderDetails: res.data?.orderId,
                    invoiceDetails: res?.data
                  };



                 
                  OrderServices.saveTransaction(saveTransactionPayload)
                    .then((res) => {
                      // transaction saved
                    localStorage.setItem("transactiondata",JSON.stringify(res.data))

                      const productPaydata = item.map((val) => ({
                        name: val.productListing.displayName,
                        unitAmount: Math.round(val.unitPrice * 100),
                        quantity: 1,
                      }));
                      console.log(productPaydata,"productPaydata")
                      const paytemp = {
                        // orderId: orderData?.orderId ? orderData?.orderId.toString() : "",
                        orderId: orderData?.orderDetails?.orderId.toString() ,
           
                        lineItems: productPaydata ? productPaydata : item,
                        companyName:orderData?.billingAddress?.companyName,
                        entityName:orderData?.entityTypeId?.entityFullDesc,
                        emailId:orderData?.companyId?.accountManagerId?.email
                      };
                      OrderServices.paymentOrder(paytemp)
                        .then((response) => {
                          setIsTabRefresh(false);
                          localStorage.setItem("sessionId", response.data.sessionId);
  
                          const localData = {
                            data: orderData,
                            res: response.data,
                            orderLineItem: item,
                            updatedBy: loginUserName
                          };
  
                          localStorage.setItem("payment", JSON.stringify(localData));
                          localStorage.setItem("checkoutData",JSON.stringify(response.data))
                          // Redirect to checkout URL after a brief timeout
                          setTimeout(() => {
                            window.location.href = response.data.checkoutUrl;
                          }, 1000);
                        })
                        .catch((err) => {
                          setIsTabRefresh(false);
                          console.error("Payment order error:", err);
                        });
                    });
  
                  // setIsTabRefresh(true);
                }
              })
              .catch((err) => {
                console.error("Error saving invoice:", err);
                setIsTabRefresh(false);
              });
          } else {
            const PaidOrderdata = {
              id: res?.data?.id,
              orderId: orderData?.orderDetails,
              invoiceNo: orderData?.orderDetails?.orderId,
              totalAmount: orderData?.orderDetails?.orderAmount,
              statusId: orderData?.orderDetails?.statusId,
              quickbooksId: res?.data?.quickbooksId,
              hubspotId: orderData?.statusId?.hubspotId,
              orderLineItems: item,
              invoiceUpdateNotes: "Payment Done",
              invoiceUpdatedBy: "null",
              paymentMethod: "CARD",
              base64Data: ""
            };
            OrderServices.saveAllInvoice(PaidOrderdata)
              .then((res) => {
                if (res.data) {
                  // const tempdata = orderData?.orderDetails;
                  // tempdata["transactionId"] = res.data.id;
                  // tempdata["generateReceipt"] = true;
                  // tempdata["orderLineItems"] = item;
                  // tempdata["orderUpdatedBy"] = loginUserName;
                  // tempdata["orderUpdateNotes"] = "Payment done successfully";
                  // tempdata["paymentMethod"] ="CARD";
                  // tempdata["transactionDate"] = convertTimestampToDate1(
                  //   res?.data?.created
                  // );
  
                  const saveTransactionPayload = {
                    transactionType: "CARD",
                    transactionStatus: 30,
                    amount: res.data?.totalAmount,
                    orderDetails: res.data?.orderId,
                    invoiceDetails: res?.data
                  };
                  OrderServices.saveTransaction(saveTransactionPayload)
                    .then((res) => {
                      // transaction saved
                      localStorage.setItem("transactiondata",JSON.stringify(res.data))
                      const productPaydata = item.map((val) => ({
                        name: val.productListing.displayName,
                        unitAmount: Math.round(val.unitPrice * 100),
                        quantity: 1,
                      }));

                      const paytemp = {
                        // orderId: orderData?.orderId ? orderData?.orderId.toString() : "",
                        orderId: orderData?.orderDetails?.orderId.toString() ,
                        lineItems: productPaydata ? productPaydata : item,
                        companyName:orderData?.billingAddress?.companyName,
                        entityName:orderData?.entityTypeId?.entityFullDesc,
                        emailId:orderData?.companyId?.accountManagerId?.email
                      };

                      OrderServices.paymentOrder(paytemp)
                        .then((response) => {
                          setIsTabRefresh(false);
                          localStorage.setItem("sessionId", response.data.sessionId);
  
                          const localData = {
                            data: orderData,
                            res: response.data,
                            orderLineItem: item,
                            updatedBy: loginUserName
                          };
  
                          localStorage.setItem("payment", JSON.stringify(localData));
                          localStorage.setItem("checkoutData",JSON.stringify(response.data))
                          // Redirect to checkout URL after a brief timeout
                          setIsTabRefresh(false);
                          setTimeout(() => {
                            window.location.href = response.data.checkoutUrl;
                          }, 1000);

                          setCloseProgresbar3(false);
                        })
                        .catch((err) => {
                          console.log(err);
                          setIsTabRefresh(false);
                        });
                    })
                    .catch((res)=>{console.log(res)})
                }
              })
              .catch((err) => {
                console.error("Error saving invoice:", err);
                setIsTabRefresh(false);
              });
          }
        })
        .catch((res) => {
          setIsTabRefresh(false);
          return;
        });
    } catch (error) {
      // Reset state on error
    }
  };
  
  


  

  // no
  // redirect to stip
  // check from start

  const handlePaymentDialog = async (data) => {
    
    // check invoice aval
      // transaction
      
      setStripeData(data);
    setCloseProgresbar3(true);
  };

  const handlePayment = async () => {
    console.log("inside function");
    if (stripeData) {
      stripePay(stripeData.orderLineItem, stripeData);
    }
  };
  // const handlePayment = async () => {
  //   try {
  //     console.log("inside function");
      
  
  //     if (stripeData) {
  //       // Await the stripePay function if it's asynchronous
  //       await stripePay(stripeData.orderLineItem, stripeData);
  //     }
  
  //     // After payment is processed, set the tab refresh state to false
  //     // setIsTabRefresh(false);
  //   } catch (error) {
  //     // setIsTabRefresh(false); // Ensure to reset in case of error
  //   }
  // };

  const getKeyPersonel = (id) => {
    if (id) {
      const temp = allKeyPersonnel.filter((val) => val.id === id);

      if (temp.length > 0) {
        return temp[0].keyPersonalType;
      } else {
        return null;
      }
    }
    return null;
  };

  function formatBytes(bytes) {
    if (typeof bytes !== "number" || isNaN(bytes) || bytes < 0) {
      return "Invalid input";
    }

    const units = ["bytes", "KB", "MB", "GB"];
    let index = 0;

    while (bytes >= 1024 && index < units.length - 1) {
      bytes /= 1024;
      index++;
    }

    return `${parseFloat(bytes.toFixed(2))} ${units[index]}`;
  }

  const onSelectionChange = (e) => {
    let selectedData = e.value;
    setSelectedProduct(selectedData);
  };

  // Handle page change
  const onPageChange = (e) => {
    if (e.first !== first || e.rows !== rows) {
      setFirst(e.first);
      setRows(e.rows);
      const newPage = e.first / e.rows;
      fetchAllOrders(newPage, e.rows); // Fetch data for the selected page
    }
  };

  let billingAddress = null;

  try {
    const orderFormJson = data?.orderDetails?.strapiOrderFormJson
      ? JSON.parse(data?.orderDetails?.strapiOrderFormJson)
      : null;

    billingAddress = orderFormJson?.keyPersonalStrapiArr?.find(
      (person) => person?.type === "BILLING"
    )?.data;
  } catch (error) {
    console.error(
      "Error parsing order details or retrieving billing address:",
      error
    );
  }

  const shippingAddress = data?.companyKeyPersonal?.find(
    (person) => person?.companyKeyPersonnel?.keyPersonnelTypeId === 5
  )?.companyKeyPersonnel;

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  const orderData = {
    orderTitle: data?.orderDetails?.orderTitle ?? "",
    clientName: `${data?.contactDetails?.firstName ?? ""} ${
      data?.contactDetails?.lastName ?? ""
    }`,
    companyName: data?.companyId?.companyName ?? "",
    entityId: data?.companyId?.entityNo,
    orderNumber: data?.orderDetails?.orderId ?? "",
    orderDate: data?.orderDetails?.orderDate
      ? formatDate(data.orderDetails.orderDate)
      : "",
    transactionId: data?.orderDetails?.transactionId ?? "",
    transactionDate: data?.orderDetails?.transactionDate
      ? formatDate(data.orderDetails.transactionDate)
      : "",
    billingInfo: {
      contactName: billingAddress?.keyPersonnelName ?? "",
      address: billingAddress?.addressMasterId.addressLine1 ?? "",
      address2: billingAddress?.addressMasterId.addressLine2 ?? "",
      city: billingAddress?.addressMasterId.city ?? "",
      state: getState(billingAddress?.addressMasterId.stateId) ?? "",
      zip: billingAddress?.addressMasterId.postalCode ?? "",
    },
    shippingInfo: {
      contactName:
        shippingAddress?.keyPersonnelName ??
        billingAddress?.keyPersonnelName ??
        "",
      address:
        shippingAddress?.addressMasterId.addressLine1 ??
        billingAddress?.addressMasterId.addressLine1 ??
        "",
      city:
        shippingAddress?.addressMasterId.city ??
        billingAddress?.addressMasterId.city ??
        "",
      state:
        getState(shippingAddress?.addressMasterId.stateId) ??
        getState(billingAddress?.addressMasterId.stateId) ??
        "",
      zip:
        shippingAddress?.addressMasterId.postalCode ??
        billingAddress?.addressMasterId.postalCode ??
        "",
    },
    items: data?.orderLineItem ?? [],
    subtotal: data?.orderDetails?.orderAmount ?? 0,
    shippingHandling: 10.0,
    grandTotal: data?.orderDetails?.orderAmount ?? 0,
    state: data?.state?.stateShortName,
    paymentDialog: handlePaymentDialog,
    data: data,
    superAdminId: isClient,
    IsPriceAvail: IsPriceAvail,
  };

  const handleDeleteOrder = (companyId, orderId) => {
    setIsTabRefresh(true);
    OrderServices.deleteOrderById(companyId, orderId)
      .then((res) => {
        setIsTabRefresh(false);
        setCloseProgresbar4(false);
        setCloseProgresbar5(false);
        setPageRefresh(!pageRefresh);
        // if (toast.current !== undefined) {
        //   toast.current.show({
        //     severity: "success",
        //     summary: "Success",
        //     detail: "Order deleted successfully",
        //     life: 3000,
        //   });
        // }

        showToast("success", "Order deleted successfully");
      })
      .catch((err) => {
        setIsTabRefresh(false);
        // if (toast.current !== undefined) {
        //   toast.current.show({
        //     severity: "warn",
        //     summary: "Opps",
        //     detail: "Something went wrong",
        //     life: 3000,
        //   });
        // }
        showToast("error", "Something went wrong");
      });
  };

  // const checkStatus = (data) => {
  //   console.log(data);
  //   setIsTabRefresh(true);
  //   OrderServices.searchSession(data?.orderDetails?.id)
  //   .then((res) => {
  //       console.log(res.data,"dsafadsfdas")

  //       let metadata =res?.data?.metadata

  //       let paymentMethod = res?.data?.paymentMethodType
  //       let paymentLastFour= res.data?.last4Digits

  //       let expiry = res.data?.expiry

  //       console.log(res);
  //       setIsTabRefresh(false);
  //       setCloseProgresbar(true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsTabRefresh(false);
  //       setCloseProgresbar1(true);
  //     });
  // };

  // const item = [
  //   {
  //     label: "Check Payment Status",
  //     icon: "pi pi-eye",
  //     command: () => {
  //       checkStatus(data);
  //     },
  //   },
  //   {
  //     label: "Pay Now",
  //     icon: "pi pi-dollar",
  //     command: () => {
  //       handlePaymentDialog(data);
  //     },
  //   },
  // ];
  console.log(orderTempdata);
  console.log(currentStatus, "current status");

  //   useEffect(() => {
  //     if (fields && Array.isArray(fields) && fields.length > 0) {
  //         setSortField(fields[0]); // Prefill only if sortField is not set
  //     }
  // }, [fields]); // Runs when fields change

  return (
    <>
      <Toast ref={toast} />
      <div className="grid webapp-content-container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
          <div className="card search-cand-card2">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 grid font-fam-for-all py-3 px-3">
              <div
                className="col-12 col-sm-12 col-md-12 col-lg-5"
                style={{ paddingLeft: "20px" }}
              >
                <p className="search-cand-header mb-0">Orders</p>
                <p className="search-cand-sub-header mb-0">
                  Manage all your orders here.
                </p>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                <div className="md:flex align-items-center justify-content-end gap-2 mt-3">
                  {/* {data && (superAdminId || superAdminMainId) && (data.statusId.statusName === "ORDER IN PROGRESS") && (data.orderTypeID && data.orderTypeID.orderFullDesc === "Entity Formation") && data.orderTypeID && (data.orderTypeID.orderShortName !== "BOI") && <Button className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3" icon="pi pi-download" label='Download offline form' onClick={() => handleDownload(data)} ></Button>} */}
                  {((!superAdminId && !superAdminMainId && !managerId) ||
                    hasPermission("AllowOrderCreation")) && (
                    <OverlayButton title={"Create Order"} bgColor={""} />
                  )}
                  <Button
                    className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
                    label="Export CSV"
                    icon="pi pi-download"
                    onClick={exportExcel}
                  ></Button>
                  {((!superAdminId && !superAdminMainId) ||
                    hasPermission("AllowDeleteDraftOrders")) && (
                    <Button
                      className=" form-Btn-Label hover:text-red-700 vstate-reverse-button font-fam-for-all text-center  form-label mr-3"
                      label="Delete All Drafts"
                      icon="pi pi-trash"
                      onClick={() => setCloseProgresbar4(true)}
                    ></Button>
                  )}
                </div>
              </div>
            </div>
            <div className="card-devider"></div>
            <div
              className="col-lg-12 col-md-12 col-sm-12 col-12"
              style={{ height: "fit-content", padding: "20px 20px 0px 30px" }}
            >
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 grid">
                <div className="flex flex-wrap align-items-center justify-content-start gap-3">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    {/* <InputText
                      placeholder="Search"
                      style={{ borderRadius: "6px", width: "200px" }} // Set a width to prevent overflow
                      value={filteredInput}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleSearch();
                        }
                      }}
                      className="vstate-input-field-fixed"
                      onChange={(e) => {
                        setFilteredInput(e.target.value);
                        if (e.target.value === "") {
                          setProducts(tempProduct);
                          if (tempProduct.length > 0 && superAdminMainId) {
                            fetchOrderDetails(tempProduct[0].orderId);
                          }
                        }
                      }}
                    /> */}

                    <InputText
                      placeholder="Search"
                      style={{ borderRadius: "6px", width: "200px" }} // Prevent overflow
                      value={filteredInput}
                      className="vstate-input-field-fixed"
                      onChange={(e) => {
                        setFilteredInput(e.target.value); // Triggers live search via useEffect

                        if (e.target.value.trim() === "") {
                          setProducts(tempProduct);
                          if (tempProduct.length > 0 && superAdminMainId) {
                            fetchOrderDetails(tempProduct[0].orderId);
                          }
                        }
                      }}
                    />
                  </span>

                  <span>
                    {/* <Dropdown
                      options={allStates}
                      filter
                      className="vstate-input-field-fixed"
                      optionLabel="stateFullDesc"
                      value={statesId}
                      resetFilterOnHide
                      optionValue="id"
                      onChange={(e) => setStatesId(e.value)}
                      placeholder="Filter By State"
                      style={{ borderRadius: "8px", width: "200px" }} // Set a width to prevent overflow
                    /> */}

                    <MultiSelect
                      options={allStates}
                      className="vstate-input-field-fixed"
                      optionLabel="stateFullDesc"
                      value={statesId}
                      onChange={(e) => setStatesId(e.value)}
                      placeholder="Filter By State"
                      filter
                      display="chip" // Shows selected values as chips
                      style={{ borderRadius: "8px", width: "200px" }}
                      optionValue="id"
                    />
                  </span>

                  {
                    console.log(companyManager, "companyManager")
                    /* companyManager */
                  }

                  {/* {isSuperAdmin && (
                    <span>
                      <MultiSelect
                        options={allcompanyManager}
                        className="vstate-input-field-fixed"
                        optionLabel="managerName"
                        value={companyManager}
                        onChange={(e) => SetCompanyManager(e.value)}
                        placeholder="Filter By Account Rep"
                        filter
                        display="chip"
                        style={{ borderRadius: "8px", width: "200px" }}
                        optionValue="managerName"
                      />
                    </span>
                  )} */}

{superAdminMainId && (
  <span>
    <MultiSelect
      options={allcompanyManager}
      className="vstate-input-field-fixed"
      optionLabel="managerName"
      value={companyManager}
      onChange={(e) => SetCompanyManager(e.value)}
      placeholder="Filter By Account Rep"
      filter
      display="chip"
      style={{ borderRadius: "8px", width: "200px" }}
      optionValue="managerName"
      itemTemplate={(option) =>
        option.managerName.trim() === "" ? "vState Filings" : option.managerName
      }
    />
  </span>
)}


                  <span>
                    <Dropdown
                      options={entityTypes}
                      filter
                      className="vstate-input-field-fixed"
                      optionLabel="orderShortName"
                      value={entityType}
                      resetFilterOnHide
                      onChange={(e) => setEntityType(e.value)}
                      placeholder="Filter By Order Type"
                      style={{ borderRadius: "8px", width: "200px" }} // Set a width to prevent overflow
                    />
                  </span>

                  <span>
                    <Dropdown
                      options={allOrderStatus}
                      filter
                      className="vstate-input-field-fixed"
                      optionLabel="statusName"
                      optionValue="id"
                      value={statusName}
                      resetFilterOnHide
                      onChange={(e) => setStatusName(e.value)}
                      placeholder="Filter By Status"
                      style={{ borderRadius: "8px", width: "200px" }} // Set a width to prevent overflow
                    />
                  </span>

                  <span>
                    <Dropdown
                      options={["Paid Orders", "Payment Due Orders"]}
                      filter
                      className="vstate-input-field-fixed"
                      value={IsOrderPaid}
                      resetFilterOnHide
                      onChange={(e) => setIsOrderPaid(e.value)}
                      placeholder="Filter By Payment Status"
                      style={{ borderRadius: "8px", width: "200px" }} // Set a width to prevent overflow
                    />
                  </span>

                  <span>
                    <Button
                      type="button"
                      style={{ fontSize: "14px", marginRight: "10px" }} // Adjust margin if necessary
                      className="vstate-button font-fam-for-all text-center"
                      onClick={handleFilter}
                    >
                      Filter
                    </Button>
                  </span>

                  <span>
                    <Button
                      type="button"
                      style={{ fontSize: "14px" }}
                      className="font-fam-for-all text-center vstate-reverse-button"
                      onClick={handleClearFilter}
                    >
                      Clear Filter
                    </Button>
                  </span>
                  {superAdminMainId && (
                    <DateRangeSelector
                      setProducts={setProducts}
                      fetchAndSelectProduct={fetchAndSelectProduct}
                    />
                  )}
                </div>
              </div>
            </div>

            <div style={{ padding: "30px" }}>
              <div className="grid p-2">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 col-xxl-3 pt-0 ">
                  <div className="">
                    <div className="vstate-card">
                      {showSkeleton ? (
                        <DataTable
                          header={searchCandHeader}
                          value={sortedData}
                          paginator
                          sortField={sortField}
                          sortOrder={sortOrder}
                          rows={25}
                          rowsPerPageOptions={[25, 50, 75, 100]}
                          inline
                          emptyMessage="No Order Found"
                          filters={filters}
                          className="master-datatable datable-scrolling"
                          globalFilterFields={["orderDetails.id"]}
                          selectionMode="single"
                          selection={selectedProduct}
                          onSelectionChange={onSelectionChange}
                          onRowSelect={onRowSelect1}
                          metaKeySelection={false}
                        >
                          <Column
                            body={itemTemplate1}
                            className="datatable-body-icon"
                          ></Column>
                        </DataTable>
                      ) : (
                        <DataTable
                          header={searchCandHeader}
                          value={items}
                          rows={5}
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          inline
                          paginator
                          emptyMessage="No Candidate Found"
                          selectionMode="single"
                          selection={selectedProduct}
                          onSelectionChange={(e) => setSelectedProduct(e.value)}
                          onRowSelect={onRowSelect}
                          metaKeySelection={false}
                        >
                          <Column
                            body={<Skeleton />}
                            className="datatable-body-icon"
                          ></Column>
                        </DataTable>
                      )}
                    </div>
                  </div>
                </div>
                {data !== null &&
                data.orderDetails !== undefined &&
                showSkeletonData ? (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 pt-0">
                    <div className="vstate-card  col-12">
                      <div className="col-12 srch-cand-card">
                        <div className="col-12 grid pb-0">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                            <div className="flex">
                              <div className=" srch-cand-text2">{`ORDER-ID ${
                                data.orderDetails.orderId
                                  ? data.orderDetails.orderId
                                  : data.orderDetails.id
                              }`}</div>
                              <div className="badge-container ml-2">
                                <div
                                  className="badge-main m-0 grid"
                                  style={{
                                    background: getBadgeBackgroundColor(data),
                                  }}
                                >
                                  <div className="col-12 flex align-items-center p-0">
                                    {
                                      // data.orderUpdateHistory.length > 0 ?
                                      //     <span className='badge-label ml-1' style={{ color: getBadgeTextColor(getStatus(data.orderUpdateHistory)) }}>
                                      //         {getStatus(data.orderUpdateHistory)}
                                      //     </span>
                                      //     :
                                      <span
                                        className="badge-label ml-1"
                                        style={{
                                          color: getBadgeTextColor(
                                            data.statusId
                                              ? data.statusId.statusName
                                              : ""
                                          ),
                                        }}
                                      >
                                        {data.statusId
                                          ? data.statusId.statusName
                                          : ""}
                                      </span>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className=" srch-cand-text">
                              {data.companyId.companyName}
                            </p>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 d-flex justify-content-end align-items-center">
                            <div className="flex align-items-center justify-content-end gap-2">
                              {/* {data.statusId.statusName !==
                                "PAY LATER REQUEST RAISED" &&
                                data.statusId.statusName !== "DRAFT" &&
                                data.statusId.statusName !== "ORDER CREATED" &&
                                data.statusId.statusName !==
                                "PAY LATER REQUEST REJECTED" &&
                                data.orderDetails.transactionId === null &&
                                IsPriceAvail &&
                                superAdminMainId &&
                                hasPermission("AllowPaymentProcessing") && (
                                  <PaymentComponents
                                    data={data?.orderDetails}
                                    orderLineItem={data?.orderLineItem}
                                  />
                                )} */}

                              {/* {data.statusId.statusName !=="PAY LATER REQUEST RAISED" &&
                                data.statusId.statusName !== "DRAFT" &&
                                data.statusId.statusName !== "ORDER CREATED" &&
                                currentStatus.statusName ===
                                  "ORDER IN PROGRESS" &&
                                data.statusId.statusName !==
                                  "PAY LATER REQUEST REJECTED" &&
                                data.orderDetails.transactionId === null &&
                                data.statusId.statusName !== "ORDER COMPLETED" &&
                               
                                (IsPriceAvail && isInvoiceAvail && superAdminMainId &&
                                hasPermission("AllowPaymentProcessing") 
                                
                                && ( (addOnKey.includes(data?.orderTypeID?.orderShortName) && isInvoiceAvail) || (!addOnKey.includes(data?.orderTypeID?.orderShortName))
                              ) 
                            ) */}
                              {
                                // (
                                //   (
                                //     addOnKey.includes(data?.orderTypeID?.orderShortName) ||
                                //     (
                                //       !addOnKey.includes(data?.orderTypeID?.orderShortName) &&
                                //       data.orderDetails.transactionId === null
                                //     )
                                //   ) &&
                                //   isInvoiceAvail &&
                                //   superAdminMainId
                                // )
                                data.orderDetails.transactionId === null && isInvoiceAvail && superAdminMainId &&
                                (data.statusId.statusName !== "ORDER CREATED" )&&
                                (
                                  <PaymentComponents
                                  data={data?.orderDetails}
                                  orderLineItem={data?.orderLineItem}
                                  />
                                  
                                  
                                )
                              }
                              {console.log(data.orderDetails.transactionId, isInvoiceAvail, superAdminMainId,"whatvalue")}

                              {/* {data.statusId.statusName !==
                                "PAY LATER REQUEST RAISED" &&
                                data.statusId.statusName !== "DRAFT" &&
                                data.orderDetails.transactionId === null &&
                                IsPriceAvail &&
                                !superAdminId &&
                                !superAdminMainId &&
                                hasPermission("AllowPaymentProcessing") &&
                                ((addOnKey.includes(
                                  data?.orderTypeID?.orderShortName
                                ) &&
                                  isInvoiceAvail) ||
                                  !addOnKey.includes(
                                    data?.orderTypeID?.orderShortName
                                  )) && ( */}

                              {/* { */}

                              {/* //  || ((!addOnKey.includes(data?.orderTypeID?.orderShortName) && !isSuperAdmin && isInvoiceAvail)) && */}

                              {
                                // (
                                //   ((addOnKey.includes(data?.orderTypeID?.orderShortName) && isInvoiceAvail) || (!addOnKey.includes(data?.orderTypeID?.orderShortName)))
                                //   && !superAdminMainId && data.orderDetails.transactionId === null && data.statusId.statusName !== "PAY LATER REQUEST RAISED" && data.statusId.statusName !== "DRAFT"
                                // )

                                data.orderDetails.transactionId === null &&
                                  (isInvoiceAvail ||
                                    (!addOnKey.includes(
                                      data?.orderTypeID?.orderShortName
                                    ) &&
                                      (data.statusId.statusName ===
                                        "ORDER CREATED" || data.statusId.statusName ===
                                        "PAY LATER REQUEST REJECTED" ))) &&
                                  !superAdminMainId && (
                                    <Button
                                      className="vstate-button form-Btn-Label font-fam-for-all text-center form-label mr-3"
                                      label="Pay now"
                                      onClick={() => handlePaymentDialog(data)}
                                    />
                                  )
                              }
                              {/* {console.log(isInvoiceAvail)} */}

                              {console.log(isInvoiceAvail, "dsafadsa")}

                              {data.statusId.statusName ===
                                "PAY LATER REQUEST RAISED" &&
                              (superAdminId || superAdminMainId) ? (
                                <Button
                                  className="  vstate-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                                  icon="pi pi-pencil"
                                  label="Approval pending"
                                  onClick={() => handleApproveDialog(data)}
                                ></Button>
                              ) : (
                                <>
                                  {allStatus.length > 0 &&
                                    data.statusId.statusName !== "DRAFT" &&
                                    !(
                                      data.statusId.statusName ===
                                        "PAY LATER REQUEST REJECTED" &&
                                      data.orderDetails.transactionId ===
                                        null &&
                                      (superAdminId || superAdminMainId)
                                    ) && (
                                      //  && (hasPermission("AllowOrderStatusUpdate"))
                                      <Button
                                        className="  vstate-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                                        icon="pi pi-pencil"
                                        label="Update status"
                                        onClick={() =>
                                          handlePendingStatusDialog(data)
                                        }
                                      ></Button>
                                    )}
                                </>
                              )}

                              {currentStatus &&
                                currentStatus.statusName === "DRAFT" &&
                                hasPermission("AllowDeleteDraftOrders") && (
                                  <Button
                                    className="  vstate-reverse-button form-Btn-Label font-fam-for-all hover:text-red-700  text-center  form-label mr-3"
                                    icon="pi pi-trash"
                                    label="Delete"
                                    onClick={() => setCloseProgresbar5(true)}
                                  ></Button>
                                )}

                              {data.orderTypeID &&
                                data.orderTypeID.orderShortName === "BOI" &&
                                (superAdminId || superAdminMainId) &&
                                data.statusId.statusName ===
                                  "ORDER IN PROGRESS" && (
                                  <Button
                                    className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                                    icon="pi pi-print"
                                    label="File BOI Form"
                                    onClick={() => handleBoiSubmit(data)}
                                  ></Button>
                                )}
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                                    icon="pi pi-print"
                                    label="Print"
                                  ></Button>
                                )}
                                content={() => printRef.current}
                              />

                              {!(superAdminId || superAdminMainId) &&
                                (data.statusId.statusName ===
                                  "GOVT QUERY RAISED" ||
                                  data.statusId.statusName === "DRAFT" ||
                                  data.statusId.statusName ===
                                    "VSTATE QUERY RAISED") &&
                                hasPermission("AllowOrderUpdates") && (
                                  <Button
                                    onClick={() => handleEditForm(data)}
                                    style={{
                                      border: "none",
                                      height: "40px",
                                      backgroundColor: "transparent",
                                      color: "blue",
                                    }}
                                    className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                                    // className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center border-none h-10 bg-transparent text-#175CD3 hover:bg-#175CD3 hover:text-blue-800 transition duration-200  form-label mr-3"
                                    icon="pi pi-pencil"
                                    label="Edit"
                                  ></Button>

                                  //   style={{
                                  //     border: "none",
                                  //     height: "40px",
                                  //     backgroundColor: "transparent",
                                  //     color: "blue",
                                  //   }}
                                  //   // className=""
                                  // >
                                  //   {" "}
                                  //   <svg
                                  //     xmlns="http://www.w3.org/2000/svg"
                                  //     width="20"
                                  //     height="20"
                                  //     viewBox="0 0 20 20"
                                  //     fill="none"
                                  //   >
                                  //     <g clip-path="url(#clip0_1308_27815)">
                                  //       <path
                                  //         d="M9.16675 3.33332H3.33341C2.89139 3.33332 2.46746 3.50891 2.1549 3.82147C1.84234 4.13403 1.66675 4.55796 1.66675 4.99999V16.6667C1.66675 17.1087 1.84234 17.5326 2.1549 17.8452C2.46746 18.1577 2.89139 18.3333 3.33341 18.3333H15.0001C15.4421 18.3333 15.866 18.1577 16.1786 17.8452C16.4912 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333M15.4167 2.08332C15.7483 1.7518 16.1979 1.56555 16.6667 1.56555C17.1356 1.56555 17.5852 1.7518 17.9167 2.08332C18.2483 2.41484 18.4345 2.86448 18.4345 3.33332C18.4345 3.80216 18.2483 4.2518 17.9167 4.58332L10.0001 12.5L6.66675 13.3333L7.50008 9.99999L15.4167 2.08332Z"
                                  //         stroke="#004EEC"
                                  //         stroke-width="2"
                                  //         stroke-linecap="round"
                                  //         stroke-linejoin="round"
                                  //       />
                                  //     </g>
                                  //     <defs>
                                  //       <clipPath id="clip0_1308_27815">
                                  //         <rect
                                  //           width="20"
                                  //           height="20"
                                  //           fill="white"
                                  //         />
                                  //       </clipPath>
                                  //     </defs>
                                  //   </svg>
                                  //   <span
                                  //     style={{
                                  //       marginLeft: "5px",
                                  //     }}
                                  //     className="text-#175CD3 font-inter text-sm font-medium leading-5"
                                  //   >
                                  //     Edit
                                  //   </span>
                                  // </Button>
                                )}

                              {(superAdminId || superAdminMainId) &&
                                data.statusId.statusName ===
                                  "ORDER IN PROGRESS" &&
                                data.orderTypeID &&
                                data.orderTypeID.orderFullDesc ===
                                  "Entity Formation" &&
                                data.orderTypeID.orderShortName !== "BOI" &&
                                hasPermission("AllowFormFiling") &&
                                (getStateUrl(
                                  data?.orderDetails?.domicileStateId
                                ) ? (
                                  <Button
                                    className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center form-label mr-3"
                                    icon="pi pi-globe"
                                    label="Fill online form"
                                    onClick={() => handleOnlineForm(data)}
                                  />
                                ) : (
                                  <Button
                                    className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center form-label mr-3"
                                    icon="pi pi-book"
                                    label="View offline form"
                                    onClick={() => handleOfflineForm(data, data.state.stateFullDesc, data.entityTypeId)}
                                    // label="View offline form"
                                    // onClick={() => handleOfflineForm(data,data?.state,data?.entityTypeId)}
                                  />
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-devider"> </div>
                      <div ref={printRef}>
                        <div className="card my-2 p-4">
                          <div className="grid">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                              <div className="order-contact-card-container">
                                <div className="order-contact-card-main font-fam-for-all">
                                  {/* <div className="grid w-full p-2"> */}
                                  <div className="grid w-full p-2">
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3 "
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Order Type
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {data.orderTypeID
                                          ? data.orderTypeID.orderFullDesc
                                          : ""}
                                      </div>
                                    </div>
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Entity Type
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {data.entityTypeId
                                          ? data.entityTypeId.entityFullDesc
                                          : ""}
                                      </div>
                                    </div>
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Entity Name
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {data.orderDetails.orderTitle}
                                      </div>
                                    </div>
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Order Date
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {data.orderDetails.orderDate
                                          ? formatISODate(
                                              data.orderDetails.orderDate
                                            )
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="grid w-full p-2">
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Completion Date
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {data.orderDetails.actualCompletionDate
                                          ? formatISODate(
                                              data.orderDetails
                                                .actualCompletionDate
                                            )
                                          : ""}
                                      </div>
                                    </div>
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3 "
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      State
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {data.state
                                          ? data.state.stateFullDesc
                                          : ""}
                                      </div>
                                    </div>
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Order Placed By
                                      <div
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {`${data.contactDetails.firstName} ${data.contactDetails.lastName}`}
                                      </div>
                                    </div>
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Account Rep
                                      <div
                                        className={`${
                                          data.companyId.accountManagerId
                                            ? "capitalize"
                                            : ""
                                        } font-light`}
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {data.companyId.accountManagerId
                                          ? `${data.companyId.accountManagerId.firstName} ${data.companyId.accountManagerId.lastName}`
                                          : "vState Filings"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                              <div className="order-contact-card-container">
                                <div className="order-contact-card-main">
                                  <div className="order-contact-card-header-main">
                                    <Avatar
                                      label={
                                        imageData && data.contactDetails
                                          ? ""
                                          : commonGetInitials(
                                              `${data.contactDetails.firstName} ${data.contactDetails.lastName}`
                                            )
                                      }
                                      image={
                                        imageData
                                          ? `data:image/jpeg;base64,${imageData}`
                                          : ""
                                      }
                                      icon="pi pi-user"
                                      size="large"
                                      shape="circle"
                                      className="custom-avatar capitalize font-light"
                                    />

                                    <div>
                                      <div className="order-contact-card-header-text1 capitalize font-light">
                                        {`${data.contactDetails.firstName} ${data.contactDetails.lastName}`}
                                      </div>
                                      <div className="order-contact-card-header-text2"></div>
                                    </div>
                                  </div>
                                  <div className="card-devider"> </div>
                                  <div className="order-contact-card-content-main">
                                    <div className="order-contact-card-text-main">
                                      <div>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <g clip-path="url(#clip0_966_10329)">
                                            <path
                                              d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                              stroke="#175CD3"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_966_10329">
                                              <rect
                                                width="20"
                                                height="20"
                                                fill="white"
                                              />
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </div>
                                      <div className="order-contact-card-text1">
                                        {data.contactDetails.mobileNo}
                                      </div>
                                    </div>

                                    <div className="order-contact-card-text-main">
                                      <div>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                            stroke="#175CD3"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </div>
                                      <div className="order-contact-card-text1">
                                        {data.contactDetails.emailId}
                                      </div>
                                    </div>

                                    {/* <div className='order-contact-card-text-main'>
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className='order-contact-card-text1'>
                                                                            {addr1 !== "" ? `${addr1},${addr2},${city},${zip}` : ""}
                                                                        </div>
                                                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-devider"> </div>
                        <TabView
                          activeIndex={activeIndex}
                          onTabChange={handleTabChange}
                        >
                          <TabPanel header="Order Details" leftIcon=" mr-2">
                            <div
                              className=" grid"
                              style={{
                                backgroundColor: "#fbfcfa",
                                padding: "15px",
                              }}
                            >
                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <div className="order-details-main-card">
                                  <div className="order-details-main-card-header p-2">
                                    <div className="order-details-main-card-header-text">
                                      Receipt
                                    </div>
                                  </div>
                                  <div className="order-details-main-card-content-main">
                                    {data.orderLineItem.length > 0
                                      ? data.orderLineItem
                                          .sort(
                                            (a, b) =>
                                              a.productListing
                                                .displayPreference -
                                              b.productListing.displayPreference
                                          )
                                          .map((val) => (
                                            <div
                                              className="order-details-card-text-container"
                                              key={val.productListing.id}
                                            >
                                              <div className="order-details-card-text-container-header">
                                                <div className="order-details-card-text-container-header-text1">
                                                  {
                                                    val.productListing
                                                      .displayName
                                                  }
                                                </div>
                                                <div className="order-details-card-text-container-header-text2">
                                                  {/* ${val.unitPrice} */}$
                                                  {parseFloat(
                                                    val.unitPrice
                                                  ).toFixed(2)}
                                                </div>
                                              </div>
                                              <ul>
                                                <li className="order-details-card-text-list">
                                                  The filing fee for the
                                                  application as per the state
                                                  selected
                                                </li>
                                                <li className="order-details-card-text-list">
                                                  Government fee
                                                </li>
                                              </ul>
                                              <div className="card-devider"></div>
                                            </div>
                                          ))
                                      : ""}
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <div className="order-details-main-card">
                                  <div className="order-details-main-card-header p-2">
                                    <div className="order-details-main-card-header-text">
                                      Order history
                                    </div>
                                  </div>
                                  <div className="order-details-main-card-content-main">
                                    {data.orderUpdateHistory.length > 0 ? (
                                      <Timeline
                                        value={data.orderUpdateHistory.filter(
                                          (history) => {
                                            if (
                                              history.orderStatus
                                                ?.statusName ===
                                                "VSTATE QUERY RAISED" ||
                                              history.orderStatus
                                                ?.statusName ===
                                                "GOVT QUERY RAISED"
                                            ) {
                                              return history?.orderUpdateHistory?.orderUpdatedBy
                                                ?.toLowerCase()
                                                .includes("vstate");
                                            }
                                            return true;
                                          }
                                        )}
                                        className="customized-timeline"
                                        marker={customizedMarker}
                                        content={customizedContent}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel header="Order Preview">
                            <div
                              className="grid w-full"
                              style={{
                                backgroundColor: "#fbfcfa",
                                padding: "15px",
                              }}
                            >
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 w-full">
                                <div className="order-details-main-card">
                                  <div className="order-details-main-card-header p-2">
                                    <div className="order-details-main-card-header-text">
                                      Order preview
                                    </div>
                                  </div>
                                  <div className="w-full">
                                    <OrderPreview data={data} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel header="Company Details" leftIcon=" mr-2">
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 w-full "
                              style={{
                                backgroundColor: "#fbfcfa",
                                padding: "15px",
                              }}
                            >
                              <div>
                                <div className="card my-3 hover-card slide-in">
                                  <div
                                    className=" flex px-4 pt-4 pb-0"
                                    style={{ justifyContent: "space-between" }}
                                  >
                                    <h2 className="srch-cand-text2">
                                      Company Details
                                    </h2>
                                    <div></div>
                                  </div>

                                  <div className="grid w-full font-fam-for-all px-4 py-2">
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Entity Name
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          margintop: "5px",
                                        }}
                                      >
                                        {data.companyId.companyName
                                          ? data.companyId.companyName
                                          : "--"}
                                      </div>
                                    </div>
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3 "
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Business Structure
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          margintop: "5px",
                                        }}
                                      >
                                        {data.companyId.entityType === "1"
                                          ? "LLC"
                                          : "CORP"}
                                      </div>
                                    </div>

                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Service State
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          margintop: "5px",
                                        }}
                                      >
                                        {data.companyId.serviceStateId
                                          ? getState(
                                              data.companyId.serviceStateId
                                            )
                                          : "--"}
                                      </div>
                                    </div>
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3"
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Domicile State
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          margintop: "5px",
                                        }}
                                      >
                                        {data.companyId.domesticStateId
                                          ? getState(
                                              data.companyId.domesticStateId
                                            )
                                          : "--"}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="grid px-4 py-4">
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3"
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Formation Date
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          margintop: "5px",
                                        }}
                                      >
                                        {data.companyId.formationDate
                                          ? formatISODate(
                                              data.companyId.formationDate
                                            )
                                          : ""}
                                      </div>
                                    </div>
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3 "
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Dissolution Date
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          margintop: "5px",
                                        }}
                                      >
                                        {data.companyId.dissolutionDate
                                          ? formatISODate(
                                              data.companyId.dissolutionDate
                                            )
                                          : // : null}
                                            "--"}
                                      </div>
                                    </div>
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3"
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      Entity Number
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          margintop: "5px",
                                        }}
                                      >
                                        {data.companyId.entityNo
                                          ? data.companyId.entityNo
                                          : "--"}
                                      </div>
                                    </div>
                                    <div
                                      className="col-12 col-sm-12 col-md-6 col-lg-3"
                                      style={{
                                        fontWeight: "600",
                                        fontSize: "14px",
                                      }}
                                    >
                                      EIN Number
                                      <div
                                        style={{
                                          fontWeight: "400",
                                          margintop: "5px",
                                        }}
                                      >
                                        {data.companyId.einNo
                                          ? data.companyId.einNo
                                          : "--"}
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="grid px-4 py-4">


                                                                    <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        Company Email
                                                                    </div>
                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        Company Phone
                                                                    </div>
                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        Fax
                                                                    </div>

                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        website
                                                                    </div>

                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                        {data.companyId.companyEmail}
                                                                    </div>
                                                                    <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                        {data.companyId.phoneNo}
                                                                    </div>
                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                        {data.companyId.fax}
                                                                    </div>
                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                        {data.companyId.websiteUrl}
                                                                    </div>

                                                                </div> */}
                                </div>
                              </div>
                            </div>
                          </TabPanel>

                          <TabPanel header="Documents">
                            <div
                              className="col-12 w-full "
                              style={{
                                backgroundColor: "#fbfcfa",
                                padding: "15px",
                              }}
                            >
                              <div className="font-fam-for-all">
                                <div className="card my-3 hover-card slide-in1">
                                  <div className="flex justify-between">
                                    <div className="p-4">
                                      <h2 className="srch-cand-text2">
                                        Documents
                                      </h2>
                                      <div className="srch-cand-text"></div>
                                    </div>
                                    <div>
                                      <div className="p-4">
                                        <button
                                          className="datatable-highlight-color"
                                          onClick={() => setVisible6(true)}
                                        >
                                          <div className="flex align-items-center webapp-global-sm-size">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                            >
                                              <path
                                                d="M10.0003 4.16669V15.8334M4.16699 10H15.8337"
                                                stroke="#004EEC"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                            <div className="font-medium">
                                              Add Document
                                            </div>
                                          </div>
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="grid p-4">
                                    <div className="col-12">
                                      <Documents data={data} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>

                          <TabPanel header="Communication history">
                            <div
                              className="w-full grid"
                              style={{
                                backgroundColor: "#fbfcfa",
                                padding: "15px",
                              }}
                            >
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 w-full">
                                <div className="order-details-main-card">
                                  <div className="order-details-main-card-header p-2">
                                    <div className="order-details-main-card-header-text">
                                      Communication history
                                    </div>
                                  </div>
                                  <div className="">
                                    <OrderChat data={data.orderUpdateHistory} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>

                          {data.companyId.accountManagerId ? (
                            <TabPanel header="Account Rep">
                              <div className="grid gap-2 px-4 py-6">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                  <div className="order-contact-card-container">
                                    <div className="order-contact-card-main">
                                      <div className="order-contact-card-header-main">
                                        <Avatar
                                          label={commonGetInitials(
                                            `${data.companyId.accountManagerId.firstName} ${data.companyId.accountManagerId.lastName}`
                                          )}
                                          icon="pi pi-user"
                                          size="large"
                                          shape="circle"
                                          className="custom-avatar capitalize font-light"
                                        />

                                        <div>
                                          <div className="order-contact-card-header-text1 capitalize font-light">
                                            {`${data.companyId.accountManagerId.firstName} ${data.companyId.accountManagerId.lastName}`}
                                          </div>
                                          <div className="order-contact-card-header-text2">
                                            Account Rep
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-devider"> </div>
                                      <div className="order-contact-card-content-main">
                                        <div className="order-contact-card-text-main">
                                          <div>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                            >
                                              <g clip-path="url(#clip0_966_10329)">
                                                <path
                                                  d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                  stroke="#175CD3"
                                                  stroke-width="2"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_966_10329">
                                                  <rect
                                                    width="20"
                                                    height="20"
                                                    fill="white"
                                                  />
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </div>
                                          <div className="order-contact-card-text1">
                                            {`${data.companyId.accountManagerId.phone}`}
                                          </div>
                                        </div>

                                        <div className="order-contact-card-text-main">
                                          <div>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                            >
                                              <path
                                                d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                stroke="#175CD3"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </svg>
                                          </div>
                                          <div className="order-contact-card-text1">
                                            {`${data.companyId.accountManagerId.email}`}
                                          </div>
                                        </div>

                                        {/* <div className='order-contact-card-text-main'>
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className='order-contact-card-text1'>
                                                                            {addr1 !== "" ? `${addr1},${addr2},${city},${zip}` : ""}
                                                                        </div>
                                                                    </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>
                          ) : (
                            ""
                          )}

                          {
                            // console.log("data.statusId.statusName !== 'DRAFT':", data.statusId.statusName !== "DRAFT")
                            // console.log("orderTempdata:", orderTempdata);
                            // console.log("orderTempdata.orderTypeID:", orderTempdata?.orderTypeID);
                            // console.log(
                            //   "addOnKey.includes(orderTempdata.orderTypeID.orderShortName):",
                            //   orderTempdata?.orderTypeID
                            //     ? addOnKey.includes(orderTempdata.orderTypeID.orderShortName)
                            //     : "orderTypeID is undefined"
                            // );
                            // console.log(
                            //   "orderTempdata?.orderDetails?.transactionId === null:",
                            //   orderTempdata?.orderDetails?.transactionId === null
                            // );
                            // console.log("isInvoiceAvail:", isInvoiceAvail);
                            // console.log(
                            //   "addOnKey.includes(data?.orderTypeID?.orderShortName):",
                            //   data?.orderTypeID
                            //     ? addOnKey.includes(data.orderTypeID.orderShortName)
                            //     : "orderTypeID is undefined"
                            // );
                            // console.log(
                            //   "Final Condition:",
                            //   data.statusId.statusName !== "DRAFT" &&
                            //     !(
                            //       orderTempdata &&
                            //       orderTempdata.orderTypeID &&
                            //       addOnKey.includes(orderTempdata.orderTypeID.orderShortName) &&
                            //       orderTempdata?.orderDetails?.transactionId === null
                            //     ) &&
                            //     ((addOnKey.includes(data?.orderTypeID?.orderShortName) &&
                            //       isInvoiceAvail) ||
                            //       !addOnKey.includes(data?.orderTypeID?.orderShortName))
                            // );
                          }
                          {/* {(data.statusId.statusName !== "DRAFT" &&
                            !(orderTempdata && orderTempdata.orderTypeID && addOnKey.includes(orderTempdata.orderTypeID.orderShortName) && orderTempdata?.orderDetails?.transactionId === null) &&
                            // !isInvoiceAvail &&
                            ((addOnKey.includes(data?.orderTypeID?.orderShortName) && isInvoiceAvail) || !addOnKey.includes(data?.orderTypeID?.orderShortName))
                             && data.statusId.statusName !== "PAY LATER REQUEST RAISED" ) */}
                          {((addOnKey.includes(
                            data?.orderTypeID?.orderShortName
                          ) &&
                            isInvoiceAvail) ||
                            !addOnKey.includes(
                              data?.orderTypeID?.orderShortName
                            )) &&
                            data.statusId.statusName !==
                              "PAY LATER REQUEST RAISED" &&
                            data.statusId.statusName !== "DRAFT" && (
                              <TabPanel header="Invoice">
                                <div
                                  className="grid w-full"
                                  style={{
                                    backgroundColor: "#fbfcfa",
                                    padding: "15px",
                                  }}
                                >
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 w-full">
                                    <div className="order-details-main-card w-full">
                                      <div className="order-details-main-card-header p-2 w-full">
                                        <div className="order-details-main-card-header-text">
                                          Invoice preview
                                        </div>
                                      </div>
                                      <div>
                                        <div className="flex justify-content-center">
                                          {/* <InvoiceTemplate  {...orderData} /> */}
                                          <OrderInvoiceTemplate
                                            {...orderData}
                                            data={data}
                                            IsPriceAvail={IsPriceAvail}
                                            // superAdminId={superAdminId}
                                            handlePaymentDialog={
                                              handlePaymentDialog
                                            }
                                          />
                                        </div>
                                        {/* {((!superAdminId && !superAdminMainId && !managerId) || */}

                                        {/* <div className="flex justify-content-center">
                                        {data.statusId.statusName !==
                                          "PAY LATER REQUEST RAISED" &&
                                          data.statusId.statusName !==
                                            "DRAFT" &&
                                          data.orderDetails.transactionId ===
                                            null &&
                                          IsPriceAvail &&
                                          !superAdminId && (
                                            <div className="flex w-full justify-content-center p-2">
                                              <Button
                                                className=" form-Btn-Label vstate-reverse-button font-fam-for-all text-center  form-label mr-3"
                                                label="Pay Now"
                                                onClick={() =>
                                                  handlePaymentDialog(data)
                                                }
                                              ></Button>
                                            </div>
                                          )}
                                      </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>
                            )}

                          {/* {
                                                    data?.orderDetails?.transactionId && <TabPanel header="Sales Receipt">
                                                        <div className=' grid' style={{ backgroundColor: '#fbfcfa', padding: '15px' }}>
                                                            <div className='col-12'>
                                                                <div className='order-details-main-card'>
                                                                    <div className='order-details-main-card-header p-2'>
                                                                        <div className='order-details-main-card-header-text'>
                                                                            Invoice preview
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div >
                                                                            <SalesReciept  {...orderData} />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TabPanel>
                                                } */}

                          {/* {data?.orderDetails?.transactionId && (
                            <TabPanel header="Sales Receipt">
                              <div
                                className=" grid"
                                style={{
                                  backgroundColor: "#fbfcfa",
                                  padding: "15px",
                                }}
                              >
                                <div className="col-12">
                                  <div className="order-details-main-card">
                                    <div className="order-details-main-card-header p-2">
                                      <div className="order-details-main-card-header-text">
                                        Sales receipt preview
                                      </div>
                                    </div>
                                    <div>
                                      <div className="flex justify-content-center">
                                        <SalesReciptInvoice {...orderData} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>
                          )} */}
                        </TabView>
                      </div>
                    </div>
                  </div>
                ) : showSkeletonData ? (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 pt-0 ">
                    <div className="card col-12" style={{ height: "255px" }}>
                      <div
                        className="grid justify-content-center align-items-center"
                        style={{ height: "255px" }}
                      >
                        {tempProduct.length === 0 &&
                        !superAdminId &&
                        !superAdminMainId ? (
                          // <Button
                          //   type="button"
                          //   style={{ fontSize: "14px", marginRight: "10px" }} // Adjust margin if necessary
                          //   className="vstate-button font-fam-for-all text-center"
                          //   onClick={()=>navigate("/orderform/new")}
                          // >
                          //   Place your first order
                          // </Button>
                          <OverlayButton
                            title={"Create Order"}
                            bgColor={"blue-btnnn"}
                          />
                        ) : (
                          "No order found"
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className=" card col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 pt-0">
                    <div className="border-round border-1 surface-border p-4 surface-card">
                      <div className="flex mb-3">
                        <Skeleton
                          shape="circle"
                          size="4rem"
                          className="mr-2"
                        ></Skeleton>
                        <div>
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                          <Skeleton height=".5rem"></Skeleton>
                        </div>
                      </div>
                      <Skeleton width="100%" height="150px"></Skeleton>
                      <div className="flex justify-content-between mt-3">
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {visible6 && (
            <AddOrderDocuments
              visible={visible6}
              setVisible={setVisible6}
              data={data}
            />
          )}

          <Dialog
            visible={closeProgresbar}
            modal={false}
            onHide={CloseProgresbarheader}
            className="font-fam-for-all"
          >
            <p className="m-0 p-3">
              <div className="d-flex align-items-center">
                {/* <p className="search-cand-sub-header">
                  Payment for this order successful.  ? Payment for this order is due kindly proceed with payment

                </p> */}
                <p lassName="search-cand-sub-header">data Added Failed</p>
              </div>
              <div className="flex justify-content-end dialog-form-md-group-Btn">
                <Button
                  type="button"
                  className="p-button p-component vstate-button font-fam-for-all text-center"
                  onClick={CloseProgresbarheader}
                >
                  Ok
                </Button>
              </div>
            </p>
          </Dialog>

          {/* <Dialog
            visible={closeProgresbar1}
            modal={false}
            onHide={CloseProgresbarheader1}
            className="font-fam-for-all"
          >
            <p className="m-0 p-3">
            <div className="d-flex align-items-center">
                <p lassName="search-cand-sub-header">data Added Failed</p>
              </div>
              <div className="flex justify-content-end dialog-form-md-group-Btn">
                <Button
                  type="button"
                  className="p-button p-component vstate-button font-fam-for-all text-center"
                  onClick={CloseProgresbarheader}
                >
                  Ok
                </Button>
              </div>
            </p>
          </Dialog> */}

          <Dialog
            visible={pendingStatusDialog}
            modal={false}
            dismissableMask={true}
            onHide={CloseProgresbarheader}
            className="payment-dialog font-fam-for-all"
          >
            <div className="w-[800px]">
              <section className="overflow-hidden  items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                <div className="grid custom-sidebar-header">
                  <div className="grid col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11 px-4 pt-4 pb-0">
                    <div className="col-12 sidebar-header-text1 pb-0">
                      Update order status
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1 flex justify-content-end pt-4 pb-0">
                    <button
                      onClick={() => {
                        setPendingStatusDialog(false);
                        setUpdateNoteText("");
                        setUpdateStatus(null);
                        setOrderStatusErr("");
                        setOrderUpdateNotesErr("");
                        setSelectedFile(null);
                        setCustomPriceErr("");
                        setCustomPrice(0.0);
                        setTemplate(null);
                      }}
                    >
                      <i className="pi pi-times"></i>
                    </button>
                  </div>
                  <div className="col-12 sidebar-header-text2 px-4">
                    Track and update order status.
                  </div>
                </div>

                <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                  <div className="grid mt-3">
                    <div className="col-12" style={{ fontSize: "14px" }}>
                      <span className=" mr-3">
                        <div class="relative">
                          <Dropdown
                            filter
                            resetFilterOnHide
                            placeholder="Choose status"
                            style={{ height: "40px" }}
                            value={updateStatus}
                            options={allStatus}
                            optionLabel="statusName"
                            onChange={(e) => {
                              setOrderStatusErr("");
                              setUpdateStatus(e.value);
                            }}
                            className="w-full"
                          />
                          <label
                            htmlFor="floating_outlined25"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Status{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {orderStatusErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {orderStatusErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>

                    {orderTempdata &&
                      orderTempdata.orderTypeID &&
                      addOnKey.includes(
                        orderTempdata.orderTypeID.orderShortName
                      ) &&
                      currentStatus &&
                      (currentStatus.statusName === "IN REVIEW" ||
                        currentStatus.statusName === "CLIENT RESPONDED") &&
                      orderTempdata?.orderDetails?.transactionId === null &&
                      !isInvoiceAvail && (
                        // !isInvoiceAvail &&
                        <div className="col-12" style={{ fontSize: "14px" }}>
                          <span className=" mr-3">
                            <div class="relative">
                              <InputText
                                style={{ height: "40px" }}
                                onChange={handleCustomPrice}
                                className="w-full"
                              />
                              <label
                                htmlFor="floating_outlined25"
                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                              >
                                Custom Price (In dollar){" "}
                                <span className="form-field-mandatory">*</span>
                              </label>
                            </div>
                            {customPriceErr !== "" ? (
                              <p className="error-msg font-fam-for-all">
                                {customPriceErr}
                              </p>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      )}
                    {(superAdminId || superAdminMainId) && (
                      <div className="col-12" style={{ fontSize: "14px" }}>
                        <span className=" mr-3">
                          <div class="relative">
                            <Dropdown
                              filter
                              resetFilterOnHide
                              placeholder="Choose template"
                              style={{ height: "40px" }}
                              options={allTemplate}
                              value={template}
                              optionLabel="templateName"
                              onChange={(e) => setTemplate(e.value)}
                              className="w-full"
                            />
                            <label
                              htmlFor="floating_outlined25"
                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >
                              Template{" "}
                              <span className="form-field-mandatory"></span>
                            </label>
                          </div>
                        </span>
                      </div>
                    )}

                    <div className="col-12" style={{ fontSize: "14px" }}>
                      <span className=" mr-3">
                        <div class="relative">
                          <InputTextarea
                            rows={5}
                            cols={100}
                            value={updateNoteText}
                            placeholder="Type a description about status update "
                            onChange={(e) => {
                              setOrderUpdateNotesErr("");
                              setUpdateNoteText(e.target.value);
                            }}
                            className="w-full"
                          />
                          <label
                            htmlFor="floating_outlined25"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Notes{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {orderUpdateNotesErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {orderUpdateNotesErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <div className="col-12" style={{ fontSize: "14px" }}>
                      <div class="relative">
                        <div className="input-sidebar-card ">
                          <div className="px-6 py-4">
                            <div
                              className="flex"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div className="font-fam-for-all">
                                <span>
                                  <i className="pi pi-address-book"></i>
                                </span>
                                Upload order documents{" "}
                                {orderTempdata &&
                                  orderTempdata.orderTypeID &&
                                  orderTempdata.orderTypeID.orderShortName ===
                                    "Entity Formation" &&
                                  updateStatus &&
                                  updateStatus.statusName ===
                                    "ORDER COMPLETED" && (
                                    <span className="form-field-mandatory">
                                      *
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div>
                              Manage your orders with simple file upload.
                            </div>
                            <div className="card pt-4 px-2 mt-4">
                              <div className="flex justify-content-center align-items-center pb-2">
                                <div className="text-center">
                                  <Button
                                    icon={
                                      selectedFile
                                        ? "pi pi-upload"
                                        : "pi pi-upload"
                                    }
                                    // label={selectedFile ? selectedFile.name : ''}
                                    className="p-button-rounded bg-gray-400 border-gray-400 p-button-primary p-mb-3"
                                    aria-label="Upload"
                                    onClick={handleClick}
                                    disabled={selectedFile ? true : false}
                                  />
                                  <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    accept=".pdf, .docx"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                  />

                                  <div className="upload-rules font-fam-for-all">
                                    <p
                                      className={`${
                                        fileErr ? "error-msg" : ""
                                      } webapp-global-sm-size`}
                                    >
                                      {fileErr ? fileErr : ""}
                                      (max. 10MB)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {selectedFile ? (
                          <>
                            <div className="bulk-upload-status-container mt-4">
                              <div className="bulk-upload-status-card1 input-sidebar-card">
                                <div className="bulk-upload-status-card1-content1">
                                  <div className="bulk-upload-status-card1-content1-button-main1">
                                    <div className="bulk-upload-status-card1-content1-button-main1-base">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M8.6665 1.3335H3.99984C3.64622 1.3335 3.30708 1.47397 3.05703 1.72402C2.80698 1.97407 2.6665 2.31321 2.6665 2.66683V13.3335C2.6665 13.6871 2.80698 14.0263 3.05703 14.2763C3.30708 14.5264 3.64622 14.6668 3.99984 14.6668H11.9998C12.3535 14.6668 12.6926 14.5264 12.9426 14.2763C13.1927 14.0263 13.3332 13.6871 13.3332 13.3335V6.00016M8.6665 1.3335L13.3332 6.00016M8.6665 1.3335V6.00016H13.3332"
                                          stroke="#145EF0"
                                          stroke-width="1.33333"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="bulk-upload-status-card1-content1-button-main2">
                                    <div className="bulk-upload-status-card1-content1-button-main2-base">
                                      <div className="bulk-upload-status-text1">
                                        {selectedFile.name}
                                      </div>
                                      <div className="bulk-upload-status-text2">
                                        {formatBytes(selectedFile.size)}
                                      </div>
                                    </div>
                                    <div className="bulk-upload-status-card1-content1-button-main2-base1">
                                      <div className="progress-bar-container">
                                        {/* <div className='progress-bar-empty'>

                                                    </div> */}
                                        <div className="progress-bar-fill"></div>
                                      </div>
                                      <div className="progress-bar-label">
                                        100%
                                      </div>
                                    </div>
                                  </div>

                                  <div className="">
                                    <button
                                      onClick={() => {
                                        setSelectedFile(null);
                                        if (fileInputRef.current) {
                                          fileInputRef.current.value = null;
                                        }
                                      }}
                                    >
                                      <i className="pi pi-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-3 items-center mt-2 justify-content-end w-full text-base font-medium max-md:max-w-full">
                    <div className="flex gap-3 justify-content-end items-start self-stretch my-auto min-w-[240px]">
                      <Button
                        className="vstate-reverse-button"
                        label="Cancel"
                        onClick={() => {
                          setPendingStatusDialog(false);
                          setUpdateNoteText("");
                          setUpdateStatus(null);
                          setOrderStatusErr("");
                          setOrderUpdateNotesErr("");
                          setSelectedFile(null);
                          setCustomPriceErr("");
                          setCustomPrice(0.0);
                          setTemplate(null);
                          SetFileErr("");
                        }}
                      />
                      <Button
                        className="vstate-button"
                        label="Submit"
                        // onClick={handleUpdateStatusSubmit}

                        onClick={() => {
                          if (
                            !isInvoiceAvail &&
                            updateStatus?.statusName === "ORDER IN PROGRESS" &&
                            addOnKey.includes(
                              orderTempdata.orderTypeID.orderShortName
                            )
                          ) {
                            setPendingStatusDialog(false);
                            handleShow(true);
                            fetchAllOrders();
                          } else {
                            handleUpdateStatusSubmit();
                            fetchAllOrders();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Dialog>

          {/* Confirmation modal */}
          <div>
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Body>
                <div className="d-flex">
                  <div>
                    <img src={dengerimg} className="denger-imggg-clas " />
                  </div>
                  <div>
                    <h3 className="confirm-paymenttitle mt-3 ms-2">
                      Confirm payment
                    </h3>
                    <p className="search-cand-sub-header mt-4">
                      Are you sure you want to finalize this price for the
                      product?
                    </p>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="p-button p-component   vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="p-button p-component   vstate-button form-Btn-Label font-fam-for-all text-center  form-label"
                  // onClick={handleUpdateStatusSubmit}
                  onClick={() => {
                    handleUpdateStatusSubmit(); // Call your function
                    handleClose(); // Close the modal
                  }}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <Dialog
            visible={approvalDialog}
            modal={false}
            onHide={CloseProgresbarheader1}
            className="payment-dialog font-fam-for-all"
          >
            <div className="w-[800px]">
              <section className="overflow-hidden  items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                <div className="grid custom-sidebar-header">
                  <div className="grid col-11 px-4 pt-4 pb-0">
                    <div className="col-12 sidebar-header-text1 pb-0">
                      Approval Pending
                    </div>
                  </div>
                  <div className="col-1 flex justify-content-end pt-4 pb-0">
                    <button
                      onClick={() => {
                        setApprovalDialog(false);
                        setUpdateNoteText("");
                        setOrderStatusErr("");
                        setOrderUpdateNotesErr("");
                        setSelectedFile(null);
                        setTemplate(null);
                      }}
                    >
                      <i className="pi pi-times"></i>
                    </button>
                  </div>
                  <div className="col-12 sidebar-header-text2 px-4">
                    Approve or decline order pay later request.
                  </div>
                </div>

                <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                  <div className="grid mt-3">
                    <div className="col-12" style={{ fontSize: "14px" }}>
                      <span className="mr-3">
                        <div className="relative">
                          <div className="flex items-center">
                            <Checkbox
                              inputId="approve"
                              name="status"
                              value="approve"
                              onChange={handleCheckboxChange}
                              checked={status.approve}
                            />
                            <label htmlFor="approve" className="ml-2">
                              Approve
                            </label>
                          </div>

                          <div className="flex items-center mt-2">
                            <Checkbox
                              inputId="reject"
                              name="status"
                              value="reject"
                              onChange={handleCheckboxChange}
                              checked={status.reject}
                            />
                            <label htmlFor="reject" className="ml-2">
                              Reject
                            </label>
                          </div>
                        </div>
                        {orderStatusErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {orderStatusErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>

                    {(superAdminId || superAdminMainId) && (
                      <div className="col-12" style={{ fontSize: "14px" }}>
                        <span className=" mr-3">
                          <div class="relative">
                            <Dropdown
                              filter
                              resetFilterOnHide
                              placeholder="Choose template"
                              style={{ height: "40px" }}
                              options={allTemplate}
                              value={template}
                              optionLabel="templateName"
                              onChange={(e) => setTemplate(e.value)}
                              className="w-full"
                            />
                            <label
                              htmlFor="floating_outlined25"
                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >
                              Template{" "}
                              <span className="form-field-mandatory"></span>
                            </label>
                          </div>
                        </span>
                      </div>
                    )}
                    <div className="col-12" style={{ fontSize: "14px" }}>
                      <span className="mr-3">
                        <div className="relative">
                          <InputTextarea
                            rows={5}
                            cols={100}
                            value={updateNoteText}
                            onChange={(e) => {
                              setOrderUpdateNotesErr("");
                              setUpdateNoteText(e.target.value);
                            }}
                            placeholder="Type a description about the approval pending"
                            className="w-full"
                          />
                          <label
                            htmlFor="floating_outlined25"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Notes{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {orderUpdateNotesErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {orderUpdateNotesErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-3 items-center mt-2 justify-content-end w-full text-base font-medium max-md:max-w-full">
                    <div className="flex gap-3 justify-content-end items-start self-stretch my-auto min-w-[240px]">
                      <Button
                        className="vstate-reverse-button"
                        label="Cancel"
                        onClick={() => {
                          setApprovalDialog(false);
                          setUpdateNoteText("");
                          setOrderStatusErr("");
                          setOrderUpdateNotesErr("");
                          setSelectedFile(null);
                          setTemplate(null);
                        }}
                      />
                      <Button
                        className="vstate-button"
                        label="Submit"
                        onClick={handleApproveSubmit}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Dialog>

          {closeProgresbar3 && (
            <Dialog
              className="payment-dialog rounded-lg"
              visible={closeProgresbar3}
              onHide={() => setCloseProgresbar3(false)}
            >
              <section className="flex rounded-lg overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                <div className="payment-icon-confirm">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="24"
                    viewBox="0 0 14 24"
                    fill="none"
                  >
                    <path
                      d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1"
                      stroke="#039855"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                  <header className="flex flex-col w-full max-md:max-w-full">
                    <h2 className="text-lg mb-0 font-fam-for-all font-medium leading-loose text-red-900 max-md:max-w-full">
                      Pay Now
                    </h2>
                    <p className="mt-2 text-md leading-5 text-gray-700 w-[500px] font-fam-for-all text-wrap">
                      You selected the <strong>Pay Now</strong> option. Please
                      complete your payment to finalize your order.
                    </p>
                  </header>
                  <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                    <div className="flex justify-content-end gap-3 items-start self-stretch my-auto min-w-[240px]">
                      <Button
                        className="vstate-reverse-button"
                        label="Cancel"
                        onClick={() => setCloseProgresbar3(false)}
                      />
                      <Button
                        className="vstate-button"
                        label="Proceed to payment"
                        onClick={handlePayment}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </Dialog>
          )}

          {closeProgresbar4 && (
            <Dialog
              className="payment-dialog rounded-lg"
              visible={closeProgresbar4}
              onHide={() => setCloseProgresbar4(false)}
            >
              <section className="flex rounded-lg overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                <div className="vstate-warning-icon-main">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.9998 7.99996V12M11.9998 16H12.0098M10.2898 2.85996L1.81978 17C1.64514 17.3024 1.55274 17.6453 1.55177 17.9945C1.55079 18.3437 1.64127 18.6871 1.8142 18.9905C1.98714 19.2939 2.2365 19.5467 2.53748 19.7238C2.83847 19.9009 3.18058 19.9961 3.52978 20H20.4698C20.819 19.9961 21.1611 19.9009 21.4621 19.7238C21.7631 19.5467 22.0124 19.2939 22.1854 18.9905C22.3583 18.6871 22.4488 18.3437 22.4478 17.9945C22.4468 17.6453 22.3544 17.3024 22.1798 17L13.7098 2.85996C13.5315 2.56607 13.2805 2.32308 12.981 2.15444C12.6814 1.98581 12.3435 1.89722 11.9998 1.89722C11.656 1.89722 11.3181 1.98581 11.0186 2.15444C10.7191 2.32308 10.468 2.56607 10.2898 2.85996Z"
                      stroke="#DC6803"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                  <header className="flex flex-col w-full max-md:max-w-full">
                    <p className="text-lg mb-0 font-fam-for-all font-medium leading-loose text-red-900 max-md:max-w-full">
                      Warning
                    </p>
                    <p className="mt-2 text-md leading-5 font-fam-for-all text-gray-700 w-[500px] text-wrap">
                      Are you sure you want to delete all your drafts?
                    </p>
                  </header>
                  <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                    <div className="flex justify-content-end gap-3 items-start self-stretch my-auto min-w-[240px]">
                      <Button
                        className="vstate-reverse-button"
                        label="Cancel"
                        onClick={() => {
                          setCloseProgresbar4(false);
                        }}
                      />
                      {company && company?.id ? (
                        <Button
                          className="vstate-button"
                          label="Confirm"
                          onClick={() => handleDeleteOrder(company.id, 0)}
                          // onClick={() => handleDeleteOrder(0, 0)}
                        />
                      ) : (
                        <p>Loading...</p> // or some fallback UI
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </Dialog>
          )}

          {closeProgresbar5 && (
            <Dialog
              className="payment-dialog rounded-lg"
              visible={closeProgresbar5}
              onHide={() => setCloseProgresbar5(false)}
            >
              <section className="flex rounded-lg overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                <div className="vstate-warning-icon-main">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.9998 7.99996V12M11.9998 16H12.0098M10.2898 2.85996L1.81978 17C1.64514 17.3024 1.55274 17.6453 1.55177 17.9945C1.55079 18.3437 1.64127 18.6871 1.8142 18.9905C1.98714 19.2939 2.2365 19.5467 2.53748 19.7238C2.83847 19.9009 3.18058 19.9961 3.52978 20H20.4698C20.819 19.9961 21.1611 19.9009 21.4621 19.7238C21.7631 19.5467 22.0124 19.2939 22.1854 18.9905C22.3583 18.6871 22.4488 18.3437 22.4478 17.9945C22.4468 17.6453 22.3544 17.3024 22.1798 17L13.7098 2.85996C13.5315 2.56607 13.2805 2.32308 12.981 2.15444C12.6814 1.98581 12.3435 1.89722 11.9998 1.89722C11.656 1.89722 11.3181 1.98581 11.0186 2.15444C10.7191 2.32308 10.468 2.56607 10.2898 2.85996Z"
                      stroke="#DC6803"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                  <header className="flex flex-col w-full max-md:max-w-full">
                    <p className="text-lg mb-0 font-fam-for-all font-medium leading-loose text-red-900 max-md:max-w-full">
                      Warning
                    </p>
                    <p className="mt-2 text-md leading-5 text-gray-700 w-[500px] font-fam-for-all text-wrap">
                      Are you sure you want to delete this draft?
                    </p>
                  </header>
                  <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                    <div className="flex justify-content-end gap-3 items-start self-stretch my-auto min-w-[240px]">
                      <Button
                        className="vstate-reverse-button"
                        label="Cancel"
                        onClick={() => {
                          setCloseProgresbar5(false);
                        }}
                      />
                      {company && company?.id && dataId ? (
                        <Button
                          className="vstate-button"
                          label="Confirm"
                          onClick={() => handleDeleteOrder(0, orderId)}
                        />
                      ) : (
                        <p>Loading...</p> // or some fallback UI
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </Dialog>
          )}

          {closeProgresbar6 && (
            <StatePdfComponent
              visible={closeProgresbar6}
              setVisible={setCloseProgresbar6}
              data={data}
              orderData={orderData}
            />
          )}
        </div>
      </div>
      <PaymentDone show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}
