import React, { useContext, useEffect } from 'react'
import { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { Dropdown } from 'primereact/dropdown';
import { Sidebar } from 'primereact/sidebar';
import { Avatar } from 'primereact/avatar';
import { InputTextarea } from "primereact/inputtextarea";
import authContext from '../../../common/authContext';
import companyMasterService from '../../../services/companyMasterService';
import { MultiSelect } from 'primereact/multiselect';

const RequestCompanyOwnership = ({ visible, setVisible }) => {
    const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh } = useContext(authContext);

    const toast = useRef(null);

    const toastCenter = useRef(null);

    const [firstName, setFirstName] = useState("")

    const [firstNameErr, setFirstNameErr] = useState("")

    const [roleId, setRoleId] = useState(null)

    const [message, setMessage] = useState(null)

    const [allParentCompanies, setAllParentCompanies] = useState([])

    const [parentCompany, setParentCompany] = useState(null)

    const [parentCompanyErr, setParentCompanyErr] = useState("")

    const [allChildCompanies, setAllChildCompanies] = useState([])

    const [childCompany, setChildCompany] = useState([])

    const [childCompanyErr, setChildCompanyErr] = useState("")

    const [company, setCompany] = useState(null)

    const [messageErr, setMessageErr] = useState("")

    useEffect(() => {
        if (globalCompany) {
            setFirstName(globalCompany?.companyDetails?.companyName)
            companyMasterService.getAllChildCompanyByTransfer(globalCompany?.companyDetails?.id)
                .then((res) => {
                    console.log(res)
                    if (Array.isArray(res.data)) {
                        setAllChildCompanies(res.data)
                    }
                    else{
                        setAllChildCompanies([])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })

            companyMasterService.getAllParentCompanyList()
                .then((res) => {
                    console.log(res)
                    if (Array.isArray(res.data)) {
                        const filteredCompanies = res.data.filter((val) => val?.company?.id !== globalCompany?.companyDetails?.id)
                        console.log(filteredCompanies)
                        setAllParentCompanies(filteredCompanies)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })

            companyMasterService.getAllCompanyById(globalCompany?.companyDetails?.id)
                .then((res) => {
                    console.log(res)
                    if (res.data !== "") {
                        setCompany(res.data)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }, [globalCompany,isTabRefresh])



    const dialogView = () => setVisible(false)
    const cancelView = () => {
        setVisible(false)
        setChildCompany([])
        setParentCompany(null)
        setMessage(null)

    }

    const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none">
    <circle cx="9.79519" cy="2.00625" r="2.00625" fill="#A3D5C9"/>
<circle cx="7.67096" cy="6.13675" r="1.06213" fill="#A3D5C9"/>
<circle cx="1.23915" cy="11.5064" r="1.23915" fill="#A3D5C9"/>
<circle cx="15.2829" cy="12.4506" r="1.47518" fill="#A3D5C9"/>
<circle cx="19.0004" cy="8.26101" r="1.06213" fill="#A3D5C9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6886 0.650298C15.7808 1.14561 15.3314 1.88426 15.3314 2.88125C15.3314 3.25051 15.4132 3.69897 15.5172 3.90007C15.6192 4.09751 15.6675 4.29412 15.6244 4.33672C15.5812 4.37932 14.4255 5.56549 13.0562 6.97258L10.5663 9.5309L10.2051 9.33783C9.72642 9.08209 8.5438 9.08256 8.0643 9.33889L7.70094 9.53302L6.31214 8.14057L4.92323 6.74811L5.10155 6.40316C5.40803 5.8106 5.4491 5.28143 5.23785 4.6484C4.87201 3.5524 4.02785 2.93907 2.885 2.93907C1.62401 2.93907 0.628439 3.83469 0.499331 5.08529C0.309446 6.92561 2.08686 8.27074 3.82911 7.60537L4.36643 7.40014L5.74319 8.76298L7.11995 10.1258L6.91815 10.5489C6.61756 11.1792 6.65663 12.0617 7.01421 12.7184C8.17748 14.8542 11.3541 14.1761 11.5336 11.7535C11.5703 11.2579 11.5252 10.9367 11.3712 10.5952L11.1581 10.1225L13.751 7.52949L16.3439 4.93659L16.8074 5.13037C17.5107 5.42411 18.5064 5.31601 19.138 4.87711C20.5128 3.92202 20.5099 1.83623 19.1323 0.87901C18.7142 0.58834 18.4875 0.520836 17.8341 0.49204C17.2702 0.467257 16.9408 0.512693 16.6886 0.650298Z" fill="white"/>
</svg>
 `;



    function capitalizeFirstLetter(string) {
        if (!string) return ''; // handle empty or undefined strings

        return string
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }





    const handleSubmit = (e) => {
        e.preventDefault()
        if (company && message && parentCompany && childCompany.length > 0) {
            const date = new Date();
            const formattedDate = new Date(date.toISOString().split("T")[0] + "T00:00:00.000Z");
            const actualdata = {
                "transferFrom": company,
                "transferTo": parentCompany?.company,
                "requestMessage": message,
                "requestDate": formattedDate.toISOString(),
                "statusId": 21,
                "companiesToTransfer": childCompany
            }
            setIsTabRefresh(true)
            companyMasterService.saveCompanyTransfer(actualdata)
                .then((res) => {
                    console.log(res)
                    setIsTabRefresh(false)
                    setVisible(false)
                    setChildCompany([])
                    setParentCompany(null)
                    setMessage(null)
                })
                .catch((err) => {
                    console.log(err)
                    setIsTabRefresh(false)
                })
            console.log(actualdata)
        }
        else {
            if (!message) {
                setMessageErr("Reason for transfer required")
            }
            if (!parentCompany) {
                setParentCompanyErr("Parent company required")
            }
            if (childCompany.length === 0) {
                setChildCompanyErr("Child company required")
            }
        }
    }


    const customHeader = (
        <div className="flex align-items-center gap-2">
            <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" />
            <span className="font-bold">Amy Elsner</span>
        </div>
    );





    return (
        <div>
            <Toast ref={toast} />
            <Toast ref={toastCenter} />
            <Sidebar position='right' header={customHeader} visible={visible} className='dialog-box' onHide={cancelView}  >
                <div className='grid custom-sidebar-header'>
                    <div className='grid col-12 col-sm-12 col-md-12 col-lg-11 px-4 pt-4 pb-0'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 sidebar-header-text1 pb-0'>
                            Request Ownership Transfer
                        </div>

                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-1 flex justify-content-end pt-4 pb-0'>
                        <button onClick={dialogView}><i className='pi pi-times'></i></button>
                    </div>
                    <div className='col-12 sidebar-header-text2 px-4'>
                        Fill the below details to transfer ownership of a company.
                    </div>
                </div>



                <form id='myform' className='form-spacing mt-4' onSubmit={handleSubmit}>
                    <Toast ref={toast} />
                    <div className=' input-sidebar-card mt-4'>


                        <div className='form-content-signup px-5 pb-3 pt-5'>

                            <div className="grid  py-2">
                                <div className='col-12 col-sm-12 col-md-12 col-lg-6' style={{ fontSize: "14px" }}>
                                    <span className=" mr-3">
                                        <div class="relative">
                                            <MultiSelect
                                                value={childCompany}
                                                options={allChildCompanies}
                                                onChange={(e) => {
                                                    setChildCompanyErr("")
                                                    setChildCompany(e.value)
                                                }}
                                                optionLabel="companyName"

                                                style={{ borderRadius: '6px', width: "100%" }}
                                                className=" font-fam-for-all"
                                            />

                                            <label
                                                htmlFor="floating_outlined3"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                                Child Company Name<span className="form-field-mandatory">*</span>
                                            </label>
                                        </div>
                                        {childCompanyErr !== "" ? <p className='error-msg font-fam-for-all'>{childCompanyErr}</p> : ""}

                                    </span>
                                </div>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-6' style={{ fontSize: "14px" }}>
                                    <span className=" mr-3">
                                        <div class="relative">
                                            <InputText
                                                style={{ height: '40px', width: "100%" }}
                                                maxLength={50}
                                                value={firstName}
                                                id="floating_outlined3001"
                                                placeholder=''
                                                disabled
                                                className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                onChange={(e) => {
                                                    setFirstNameErr("")
                                                    setFirstName(e.target.value)
                                                }}
                                                name="cityName"
                                            />

                                            <label
                                                htmlFor="floating_outlined3001"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                                Current Parent Company<span className="form-field-mandatory">*</span>
                                            </label>
                                        </div>
                                        {firstNameErr !== "" ? <p className='error-msg font-fam-for-all'>{firstNameErr}</p> : ""}
                                    </span>
                                </div>

                                <div className='col-12' style={{ fontSize: "14px" }}>
                                    <span className=" mr-3">
                                        <div class="relative">
                                            <Dropdown
                                                value={parentCompany}
                                                filter
                                                resetFilterOnHide
                                                options={allParentCompanies}
                                                onChange={(e) => {
                                                    setParentCompanyErr("")
                                                    setParentCompany(e.value)
                                                }}
                                                optionLabel="company.companyName"


                                                style={{ borderRadius: '6px', width: "100%" }}
                                                className=" font-fam-for-all"
                                            />

                                            <label
                                                htmlFor="floating_outlined3"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                                New Parent Company Name<span className="form-field-mandatory">*</span>
                                            </label>
                                        </div>
                                        {parentCompanyErr !== "" ? <p className='error-msg font-fam-for-all'>{parentCompanyErr}</p> : ""}

                                    </span>
                                </div>

                                <div className='col-12' style={{ fontSize: "14px" }}>
                                    <span className=" mr-3">
                                        <div class="relative">
                                            <InputTextarea
                                                style={{ width: "100%" }}
                                                maxLength={256}
                                                rows={5}

                                                value={message}
                                                id="floating_outlined3001"
                                                placeholder=''
                                                onChange={(e) => {
                                                    setMessageErr("")
                                                    setMessage(e.target.value)
                                                }}
                                                name="cityName"
                                            />

                                            <label
                                                htmlFor="floating_outlined3"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                                Reason for transfer<span className="form-field-mandatory">*</span>
                                            </label>
                                        </div>
                                        {messageErr !== "" ? <p className='error-msg font-fam-for-all'>{messageErr}</p> : ""}

                                    </span>
                                </div>



                            </div>

                        </div>
                    </div>

                    <div className='flex justify-content-end dialog-form-md-group-Btn my-5'>
                        <Button type="button" className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3" label='Cancel' style={{ height: "40px" }} onClick={cancelView} ></Button>
                        <Button type='submit' className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Submit Transfer Request' style={{ height: "40px" }}  ></Button>
                    </div>
                </form>
            </Sidebar>
        </div>
    )
}

export default RequestCompanyOwnership