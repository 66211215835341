import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useContext, useEffect, useState } from 'react'
import OrderServices from '../../services/orderServices'
import MasterServices from '../../services/coreServices'
import authContext from '../../common/authContext'
import { useNavigate } from 'react-router-dom'
import { formatISODate } from '../../utils/dateUtils'
import { TabPanel, TabView } from 'primereact/tabview'

const CompanySubscriptions = ({ data }) => {

    const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh, redirectedData, setRedirectedData } = useContext(authContext);


    const [products, setProducts] = useState([

    ])

    const [selectedProduct, setSelectedProduct] = useState(null)

    const navigate = useNavigate()

    const [allStatus, setAllStatus] = useState([])

    useEffect(() => {
        MasterServices.getAllStatus()
            .then((res) => {
                if (res.data !== "") {
                    setAllStatus(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])


    const getStatus = (id) => {
        if (allStatus.length > 0) {
            const temp = allStatus.find((val) => val.id === id)
            if (temp) {
                return temp.statusName;
            }
            else {
                return null;
            }

        }
        else {
            return null;
        }
    }
    useEffect(() => {
        if (data) {
            OrderServices.getAllOrderByCompanyId(data.id)
                .then((res) => {
                    console.log(res);
                    if (res.data !== "") {
                        const ordersWithLineItems = res.data.ordersWithLineItems;


                        if (Array.isArray(ordersWithLineItems)) {
                            const temp = ordersWithLineItems.map((order) => {

                                if (Array.isArray(order.orderLineItem)) {

                                    return order.orderLineItem.filter(
                                        (val) =>
                                            val.productListing.productMaster.productCategory.categoryName ===
                                            "Subscription"
                                    );
                                }
                                return [];
                            }).flat();

                            console.log(temp);

                            const uniqueTemp = temp.filter(
                                (item, index, self) =>
                                    index ===
                                    self.findIndex(
                                        (t) =>
                                            t.productListing.productMaster.id ===
                                            item.productListing.productMaster.id
                                    )
                            );

                            console.log(uniqueTemp);
                            setProducts(uniqueTemp)

                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [data]);





    return (
        <div>
            <div className='grid p-4'>
                <div className='col-12'>
                    <TabView>
                        <TabPanel header="Recommended subscriptions">

                            {/* <div className="font-fam-for-all">

                                <div className='card my-3 hover-card slide-in1'>
                                    <div className=''>

                                        <div className='wizard-card-radio-container gap-0 font-fam-for-all'>
                                            <div className='wizard-card-radio-main'>
                                                {
                                                    products.map((val) => (

                                                        <button className='wizard-card-checkbox-container hover:shadow-lg transform hover:scale-105 transition-transform duration-300'>
                                                            <div className='wizard-card-checkbox-main'>
                                                                <div className='wizard-card-checkbox-text-main'>
                                                                    <div className='wizard-card-checkbox-text-sub-main align-items-center'>
                                                                        <div className='w-full grid'>
                                                                            <div className='col-11'>
                                                                                <div className='wizard-card-checkbox-text1 font-fam-for-all text-start'>
                                                                                    {val.productListing.displayName}
                                                                                </div>
                                                                                <div className='srch-cand-text pt-2 text-start'>
                                                                                    {val.productListing.productMaster.productAdditionalInfo}
                                                                                </div>
                                                                            </div>

                                                                            <div className='wizard-card-checkbox-text2 col-1 flex align-items-center'>
                                                                                ${val.productListing.unitPrice}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className='wizard-card-checkbox-action-container flex align-items-center'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                            <path d="M7.5 15L12.5 10L7.5 5" stroke="#004EEC" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    ))
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </TabPanel>
                        <TabPanel header="Your current subscriptions">

                            <div className="font-fam-for-all">

                                <div className='card my-3 hover-card slide-in1'>
                                    <div className=''>

                                        <div className='wizard-card-radio-container gap-0 font-fam-for-all'>
                                            <div className='wizard-card-radio-main'>
                                                {
                                                    products.map((val) => (

                                                        <button className='wizard-card-checkbox-container hover:shadow-lg transform hover:scale-105 transition-transform duration-300'>
                                                            <div className='wizard-card-checkbox-main'>
                                                                <div className='wizard-card-checkbox-text-main'>
                                                                    <div className='wizard-card-checkbox-text-sub-main align-items-center'>
                                                                        <div className='w-full grid'>
                                                                            <div className='col-11'>
                                                                                <div className='wizard-card-checkbox-text1 font-fam-for-all text-start'>
                                                                                    {val.productListing.displayName}
                                                                                </div>
                                                                                <div className='srch-cand-text pt-2 text-start'>
                                                                                    {val.productListing.productMaster.productAdditionalInfo}
                                                                                </div>
                                                                            </div>

                                                                            <div className='wizard-card-checkbox-text2 col-1 flex align-items-center'>
                                                                                ${val.productListing.unitPrice}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className='wizard-card-checkbox-action-container flex align-items-center'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                            <path d="M7.5 15L12.5 10L7.5 5" stroke="#004EEC" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    ))
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>

                </div>

            </div>
        </div>
    )
}

export default CompanySubscriptions;