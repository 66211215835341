import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import OrderServices from '../../../services/orderServices';
import MasterServices from '../../../services/coreServices';

export default function DoughnutChartDemo() {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const [category, setCategory] = useState([])

    const [serviceData, setServiceData] = useState([])

    useEffect(() => {
        OrderServices.getAllServiceForDashbaord()
            .then((res) => {
                console.log(res)
                if (Array.isArray(res.data)) {
                    setServiceData(res.data)

                }
            })
            .catch((err) => {
                console.log(err)
            })
        MasterServices.getAllProductCatagory()
            .then((res) => {
                console.log(res.data)
                if (Array.isArray(res.data)) {
                    setCategory(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const getCategory = (id) => {
        console.log(id)
        if (category.length > 0) {
            console.log(category)
            const temp = category.find((val) => val.id === id)
            console.log(temp)
            if (temp) {
                return temp.productName;
            }
        }
        else {
            return null;
        }
    }

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);

        // Dynamically generate labels based on productCategoryId and totalProductCount
        // const labels = serviceData.map(item => `${getCategory(item.productCategoryId)} (${item.totalProductCount})`);

        // Extract total product counts for chart values

        // Calculate total product counts by category
        const categoryTotals = serviceData.reduce((acc, item) => {
            acc[item.productCategoryId.id] = (acc[item.productCategoryId.id] || 0) + item.totalProductCount;
            return acc;
        }, {});

        // Sort categories by totalProductCount and select the top 5
        const topCategories = Object.entries(categoryTotals)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 5);

        // Prepare labels and data based on the top 5 categories
        const labels = topCategories.map(([categoryId]) => `${getCategory(parseInt(categoryId))}`);
        const dataValues = topCategories.map(([, totalProductCount]) => totalProductCount);
        const data = {
            labels: labels,
            datasets: [
                {
                    data: dataValues, // Use totalProductCount for values
                    backgroundColor: [
                        "#004EEC",
                        "#2870FF",
                        "#84ADFF",
                        "#D0DFFF"
                    ],
                    // hoverBackgroundColor: [
                    //     documentStyle.getPropertyValue('--blue-400'),
                    //     documentStyle.getPropertyValue('--yellow-400'),
                    //     documentStyle.getPropertyValue('--green-400'),
                    //     documentStyle.getPropertyValue('--green-400')
                    // ]
                }
            ]
        };

        const options = {
            cutout: '60%'
        };

        setChartData(data);
        setChartOptions(options);
    }, [serviceData, category]);

    return (
        <div className="card flex justify-content-center">
            <Chart type="doughnut" data={chartData} options={chartOptions} className="w-full" />
        </div>
    );
}
