import React from 'react';

const ServiceCard = ({ image, title, description }) => {
  return (
    <div className="flex col-6 flex-col w-6/12 max-md:ml-0 max-md:w-full p-3">
      <div className="flex flex-col grow self-stretch w-full font-medium text-center rounded-lg shadow-lg bg-white bg-opacity-0 text-zinc-700 max-md:mt-10">
        <img loading="lazy" src={image} className="w-full aspect-[1.49]" alt={title} />
        <div className="flex flex-col justify-end items-center px-3.5 py-5 bg-gray-50">
          <h3 className="text-3xl leading-9">{title}</h3>
          <p className="self-stretch mt-4 text-base font-light leading-7">
            {description}
          </p>
          <button className="justify-center px-7 py-3.5 mt-4 text-base leading-4 text-white bg-web-color rounded border-2 border-white border-solid shadow-sm max-md:px-5">
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;