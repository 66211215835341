

import React, { useState, useRef, useEffect, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import AddPermission from './AddComponents/AddPermission';
import ContactService from '../services/ContactService';
import authContext from '../common/authContext';

export default function GroupDataTable({ data }) {

    const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh } = useContext(authContext);

    const [companyData, setCompanyData] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [visible, setVisible] = useState(false);
    const [data1, setData1] = useState([])
    const [companyId, setCompanyId] = useState(null)
    const [contactDetails, setContactDetails] = useState(null)
    const [userDetailsId, setUserDetailsId] = useState(null)

    console.log(data)
    useEffect(() => {
        if (data) {
            ContactService.getAllContactById(data.contactDetails.id)
            .then((res)=>{
                console.log(res.data)
                setCompanyData(res.data.companyRoles);
                setContactDetails(res.data.contactDetails)
            })
            .catch((err)=>{
                console.log(err)
            })
         
        }
    }, [data,isTabRefresh]);

    const handleManageRoles = (companyId) => {

        console.log(contactDetails)
        if (contactDetails !== null && contactDetails !== undefined) {
            setUserDetailsId(contactDetails.userId)
        }
        console.log(companyId)
        if (companyId.companyDetails !== undefined) {
            if (companyId.companyDetails) {
                setCompanyId(companyId.companyDetails.id)
            }
          
            if (data) {
                console.log(data)
                // const roles = data.roles.find(role => role.companyId === companyId.companyDetails.id)?.roles || [];
                
                setSelectedRoles(companyId.roles);
                setData1(companyId.roles)
                setVisible(true)
             
            }
        }


    };

    // const roleNamesBodyTemplate = (rowData) => {
    //     console.log(rowData)
    //     if (data) {
    //         const roles = data.companyRoles.find(role => role.companyId === rowData.id)?.roles || [];
    //         return roles.map(role => role.roleName).join(', ');
    //     }

    // };

    const roleNamesBodyTemplate = (rowData) => {
        console.log(rowData)
        if (data) {
            // const roles = data.companyRoles.find(role => role.companyId === rowData.id)?.roles || [];
            return rowData.roles.map(role => role.roleName).join(', ');
        }

    };

    const actionButtonBodyTemplate = (rowData) => {
        console.log(rowData)
        return (

            <button className="datatable-highlight-color" onClick={() => handleManageRoles(rowData)} >Manage Permission</button>

        );
    };

    return (
        <div className="card p-fluid">
            <DataTable value={companyData} className="font-fam-for-all datatable-lg-col webapp-sm-size">
                <Column field="companyDetails.companyName" header="Company Name" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
                <Column body={roleNamesBodyTemplate} header="Access Level" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
                <Column body={actionButtonBodyTemplate} header="Action" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
            </DataTable>
            <AddPermission visible={visible} setVisible={setVisible} value={data1} companyId={companyId} userDetailsId={userDetailsId} />

        </div>
    );
}


