import { useEffect, useState, useRef } from "react";

const AutoLogout = ({ logout }) => {
    const [isActive, setIsActive] = useState(true);
    const timeoutRef = useRef(null);

    useEffect(() => {
        const resetTimer = () => {
            setIsActive(true);
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        
            timeoutRef.current = setTimeout(() => {
                setIsActive(false);
                logout();
            }, 4 * 60 * 60 * 1000); 
        };
        

        window.addEventListener("mousemove", resetTimer);
        window.addEventListener("keydown", resetTimer); 
        resetTimer(); 

        return () => {
            window.removeEventListener("mousemove", resetTimer);
            window.removeEventListener("keydown", resetTimer);
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    }, [logout]); 

    return null; 
};

export default AutoLogout;
