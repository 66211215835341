import React, { useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";

// Helper functions
const toPascalCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
      index === 0 ? match.toUpperCase() : match.toLowerCase()
    )
    .replace(/\s+/g, "");
};

function convertName(originalString) {
  if (!originalString.includes("_")) {
    return originalString;
  }

  const parts = originalString.split("_");

  const relevantParts = parts.slice(1);

  // Capitalize the first letter of each part and join them with a space
  const formattedString = relevantParts
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(" ");

  return formattedString;
}

const convertToTitleCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camel case words
    .replace(/[_\-/]/g, " ") // Replace delimiters like _, -, / with spaces
    .replace(
      /\w\S*/g,
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize first letter of each word
    );
};

const keyPersonalMemberData = [
  "Organizer_Information",
  "Billing_Information",
  "Mailing_Information",
  "Incorporator_Information",
  "Director_Information",
  "Officer_Information",
  "Filer_Information",
  "President_Information",
  "ucc_Secured_Party_Details",
  "Treasurer_Information",
  "Secretary",
  "Member_Or_Manager_Details",
  "Registered_Agent",
  "Principal_Address",
];

const PreviewDataComponent = ({ previewData }) => {
  console.log({previewData}, "previewData")
  
  const [activeIndex, setActiveIndex] = useState([]);

  useEffect(() => {
    const allIndices = Object.keys(previewData).map((_, index) => index);
    setActiveIndex(allIndices);
  }, [previewData]);

  return (
    <div className="space-y-4">
      {Object.keys(previewData).map(
        (mainkey, i) =>
          mainkey !== "undefined" && (
            <Accordion activeIndex={0}>
              <AccordionTab key={i} header={convertToTitleCase(mainkey)}>
                <div className="p-4">
                  {Object.keys(previewData[mainkey]).map(
                    (sectionKey, index) => (
                      <>
                        {!keyPersonalMemberData.includes(sectionKey) && (
                          <>
                            <div key={index} className="vstate-card m-2">
                              <div
                                className="px-4 py-2"
                                style={{
                                  backgroundColor: "#F9FAFB",
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                }}
                              >
                                <h2 className="text-lg text-gray-800">
                                  {sectionKey
                                    ? convertToTitleCase(sectionKey)
                                    : "--"}
                                </h2>
                              </div>

                              <div className="px-4 py-4">
                                {Array.isArray(
                                  previewData[mainkey][sectionKey]
                                ) ? (
                                  previewData[mainkey][sectionKey].map(
                                    (item, idx) => {
                                      const primitiveKeys = [];
                                      const objectKeys = [];
                                      const arrayKeys = [];

                                      Object.keys(item).forEach((itemKey) => {
                                        if (itemKey !== "__temp_key__") {
                                          if (
                                            typeof item[itemKey] === "object" &&
                                            item[itemKey] !== null
                                          ) {
                                            if (Array.isArray(item[itemKey])) {
                                              arrayKeys.push(itemKey);
                                            } else {
                                              objectKeys.push(itemKey);
                                            }
                                          } else {
                                            primitiveKeys.push(itemKey);
                                          }
                                        }
                                      });

                                      return (
                                        <div key={idx} className="">
                                          <h3 className="text-lg text-gray-800 mb-2">
                                            {convertToTitleCase(sectionKey)}{" "}
                                            {idx + 1}
                                          </h3>
                                          <div className="grid p-4 mb-2">
                                            {primitiveKeys.map((itemKey) => (
                                              <>
                                                <div className="col-3">
                                                  <div className="order-preview-key">
                                                    {itemKey
                                                      ? convertName(itemKey)
                                                      : "--"}
                                                  </div>
                                                  <div className="order-preview-value">
                                                    {itemKey
                                                      ? item[itemKey]
                                                      : "--"}
                                                  </div>
                                                </div>
                                                <div className="col-1"></div>
                                              </>
                                            ))}
                                          </div>

                                          {objectKeys.length > 0 && (
                                            <div className="grid  sam p-4 mb-2">
                                              {objectKeys.map((itemKey) => (
                                                <>
                                                  <div className="col-12">
                                                    <div className="order-preview-key pb-2">
                                                      {convertName(itemKey)}
                                                    </div>
                                                    <div className="order-preview-value">
                                                      <div className="grid preview-card pap p-4 mb-2">
                                                        {Object.keys(
                                                          item[itemKey]
                                                        ).map((subKey) => (
                                                          <>
                                                            <div className="col-3">
                                                              <div className="order-preview-key">
                                                                {subKey
                                                                  ? convertName(
                                                                      subKey
                                                                    )
                                                                  : "--"}
                                                              </div>
                                                              <div className="order-preview-value">
                                                                {itemKey
                                                                  ? item[
                                                                      itemKey
                                                                    ][subKey]
                                                                  : "--"}
                                                              </div>
                                                            </div>
                                                            <div className="col-1"></div>
                                                          </>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ))}
                                            </div>
                                          )}

                                          {arrayKeys.length > 0 && (
                                            <div className="grid sam1 p-4 mb-2">
                                              {arrayKeys.map((itemKey) => (
                                                <>
                                                  <div className="col-12">
                                                    <div className="order-preview-key pb-2">
                                                      {itemKey
                                                        ? convertName(itemKey)
                                                        : "--"}
                                                    </div>
                                                    <div className="order-preview-value">
                                                      {item[itemKey].map(
                                                        (subItem, subIdx) => (
                                                          <div
                                                            key={subIdx}
                                                            className="ml-4"
                                                          >
                                                            {typeof subItem ===
                                                            "object" ? (
                                                              <div className="grid preview-card pap p-4 mb-2">
                                                                {Object.keys(
                                                                  subItem
                                                                ).map(
                                                                  (subKey) => (
                                                                    <>
                                                                      <div className="col-3">
                                                                        <div className="order-preview-key">
                                                                          {subKey
                                                                            ? convertName(
                                                                                subKey
                                                                              )
                                                                            : "--"}
                                                                        </div>
                                                                        <div className="order-preview-value">
                                                                          {subKey
                                                                            ? subItem[
                                                                                subKey
                                                                              ]
                                                                            : "--"}
                                                                        </div>
                                                                      </div>
                                                                      <div className="col-1"></div>
                                                                    </>
                                                                  )
                                                                )}
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                {subItem
                                                                  ? subItem
                                                                  : "--"}
                                                              </div>
                                                            )}
                                                          </div>
                                                        )
                                                      )}
                                                    </div>
                                                  </div>
                                                </>
                                              ))}
                                            </div>
                                          )}

                                          {/* <div className="border-t border-gray-300 my-4"></div> */}
                                        </div>
                                      );
                                    }
                                  )
                                ) : previewData[mainkey][sectionKey] !== null &&
                                  typeof previewData[mainkey][sectionKey] ===
                                    "object" ? (
                                  Object.keys(
                                    previewData[mainkey][sectionKey]
                                  ).map((key, i) => (
                                    <div key={i} className="">
                                      {Array.isArray(
                                        previewData[mainkey][sectionKey][key]
                                      ) ? (
                                        <div>
                                          <h3 className="text-lg text-gray-800 mb-2">
                                            {convertToTitleCase(key)}
                                          </h3>

                                          {/* Iterate over the array elements */}
                                          {previewData[mainkey][sectionKey][
                                            key
                                          ].map((arrayItem, arrayIndex) => (
                                            <div
                                              key={arrayIndex}
                                              className="mb-2"
                                            >
                                              {typeof arrayItem === "object" ? (
                                                <div>
                                                  {/* If the array contains objects, iterate over the keys of the object */}
                                                  <div className="grid preview-card pap p-4 mb-2">
                                                    {Object.keys(arrayItem).map(
                                                      (nestedKey) => (
                                                        <>
                                                          <div className="col-3">
                                                            <div className="order-preview-key">
                                                              {nestedKey
                                                                ? convertName(
                                                                    nestedKey
                                                                  )
                                                                : "--"}
                                                            </div>
                                                            <div className="order-preview-value">
                                                              {nestedKey
                                                                ? arrayItem[
                                                                    nestedKey
                                                                  ]
                                                                : "--"}
                                                            </div>
                                                          </div>
                                                          <div className="col-1"></div>
                                                        </>
                                                      )
                                                    )}
                                                  </div>

                                                  {/* Divider for Array Item */}
                                                  {/* <div className="border-t border-gray-300 my-4"></div> */}
                                                </div>
                                              ) : (
                                                <div className="grid gap-3 mb-2">
                                                  {/* If the array contains non-object values, display them directly */}
                                                  <div className="col-2 order-preview-key">
                                                    {`Item ${arrayIndex + 1}`}
                                                  </div>
                                                  <div className="col-6 order-preview-value">
                                                    {arrayItem
                                                      ? arrayItem
                                                      : "--"}
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          ))}

                                          {/* Divider for Array Data */}
                                          {/* <div className="border-t border-gray-300 my-4"></div> */}
                                        </div>
                                      ) : previewData[mainkey][sectionKey][
                                          key
                                        ] !== null &&
                                        typeof previewData[mainkey][sectionKey][
                                          key
                                        ] === "object" ? (
                                        <div>
                                          <h3 className="text-lg text-gray-800 mb-2">
                                            {convertToTitleCase(key)}
                                          </h3>
                                          <div className="grid preview-card p-4 mb-2">
                                            {Object.keys(
                                              previewData[mainkey][sectionKey][
                                                key
                                              ]
                                            ).map((nestedKey) => (
                                              <>
                                                <div className="col-3">
                                                  <div className="order-preview-key">
                                                    {nestedKey
                                                      ? convertName(nestedKey)
                                                      : "--"}
                                                  </div>
                                                  <div className="order-preview-value">
                                                    {previewData[mainkey][
                                                      sectionKey
                                                    ][key][nestedKey]
                                                      ? previewData[mainkey][
                                                          sectionKey
                                                        ][key][nestedKey]
                                                      : "--"}
                                                  </div>
                                                </div>
                                                <div className="col-1"></div>
                                              </>
                                            ))}
                                          </div>

                                          {/* Divider for Nested Data */}
                                          {/* <div className="border-t border-gray-300 my-4"></div> */}
                                        </div>
                                      ) : (
                                        <div className="grid gap-3 mb-2">
                                          {/* Key-Value Pair for Flat Object */}
                                          <div className="col-3 order-preview-key">
                                            {key ? convertName(key) : "--"}
                                          </div>
                                          {/* <div className="col-6 order-preview-value">
                                            {previewData[mainkey][sectionKey][
                                              key
                                            ]
                                              ? previewData[mainkey][
                                                  sectionKey
                                                ][key]+ "test2" 
                                              : "--"}
                                          </div> */}
                                          <div className="col-6 order-preview-value">
                                            {previewData[mainkey][sectionKey][key] ? (
                                              key === "SI_Shares_Par_Value" ? (
                                                "$ " + previewData[mainkey][sectionKey][key]
                                              ) : (
                                                previewData[mainkey][sectionKey][key]
                                              )
                                            ) : (
                                              "--"
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ))
                                ) : (
                                  // Single Key-Value Pair if the data is neither object nor array
                                  <div className="grid gap-3 mb-2">
                                    <div className="col-3 order-preview-key">
                                      {sectionKey
                                        ? convertName(sectionKey)
                                        : "--"}
                                    </div>
                                    <div className="col-6 order-preview-value">
                                      {previewData[mainkey][sectionKey]
                                        ? previewData[mainkey][sectionKey]
                                        : "--"}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )
                  )}
                </div>
              </AccordionTab>
            </Accordion>
          )
      )}
    </div>
  );
};

export default PreviewDataComponent;
