import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import AOS from "aos";
import "aos/dist/aos.css";

const services = [
  {
    title: "Processing Time",
    description:
      "We can get your company registered within 2 business days, so your start-up plans can move forward without delay!",
  },
  {
    title: "Tax Benefits",
    description:
      "Certain entity structures provide tax advantages, allowing your business to optimize its financial efficiency.",
  },
  {
    title: "Efficient Filing Process",
    description:
      "Our streamlined service simplifies the formation process, allowing you to focus on launching your business quickly.",
  },
  {
    title: "Tailored Business Structure",
    description:
      "We guide you in selecting the appropriate legal entity (Corp, LLC, PC, PLLC) for your business, ensuring it aligns with your goals and compliance requirements.",
  },
];

const BuisnessNeeds = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className="main-section-class">
      <div>
        <div className="mb-5">
          <CommonHeadingTitle
            heading={"Why choose "}
            blueheading={"vState filings for your"}
          />
          <CommonHeadingTitle
            heading={"business formation"}
            blueheading={"needs?"}
          />
        </div>
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div data-aos="fade-left" data-aos-duration="3000">
                <Row>
                  {services.map((service, index) => (
                    <Col lg={12} md={12} sm={12} xs={12} key={index}>
                      <div className="main-class-div">
                        <h4 className="medium-level-heading">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="check-iconn me-4"
                          />
                          {service.title}
                        </h4>
                        <div className="grey-border mt-3 mb-3"></div>
                        <p className="normal-common-txt">
                          {service.description}
                        </p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div data-aos="fade-right" data-aos-duration="3000">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "https://cdn.builder.io/api/v1/image/assets/TEMP/28039c3098271ecf12c441995d6d71b369290f8ea9db2cd51da89a58fb1c7978?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
                  }
                  className="user-img-buisness"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default BuisnessNeeds;
