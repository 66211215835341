import axios from 'axios';
import authHeader, { initAuth } from "./authHeader";
import propertiesApp from '../utils/propertiesApp';
import "./../services/axiosConfig.js"

const user = JSON.parse(localStorage.getItem('user'));


const logout = () => {

    
    const payload = {
        "endpoint": "/authentication/api/user/logout",
        "payload": null,
        "type": "post",
        "auth": authHeader()
    }
    try {

        // return axios.get(`/decrypt?data=${dataToEncrypt}`,
        return axios.post(`/decrypt`, payload,

            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const logoutService = {
    logout,
}


export default logoutService;