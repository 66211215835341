import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import AOS from "aos";
import "aos/dist/aos.css";

const services = [
  {
    title: "Corporation (Corp)",
    description:
      "A corporation is a separate legal entity, distinct from its owners (shareholders). It offers strong protection against personal liability, making it a popular choice for larger businesses.",
  },
  {
    title: "Limited Liability Company (LLC)",
    description:
      "LLCs combine the liability protection of a corporation with the operational flexibility of a partnership. Owners are called members, and they aren’t personally liable for the company’s debts.",
  },
  {
    title: "Professional Corporation (PC)",
    description:
      "PCs are designed for licensed professionals (such as doctors, lawyers, and accountants). They offer liability protection while allowing professionals to own and manage the corporation.",
  },
  {
    title: "Professional Limited Liability Company (PLLC)",
    description:
      "Similar to an LLC, but reserved for licensed professionals, PLLCs offer personal asset protection while allowing professionals to practice within their licensed field.",
  },
];

const EntityFormationService = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className="main-section-class">
      <div className="entity-formation mt-5">
        <Container>
          <CommonHeadingTitle blueheading={"Entity formation"} />
          <CommonHeadingTitle heading={"services "} />
          <div className="text-center">
            <h2 className="mt-4 mb-4 medium-level-heading">
              We offer comprehensive services to help you <br />
              establish and operate your US Formation Services.
            </h2>
          </div>
          <Row>
            {services.map((service, index) => (
              <Col lg={6} md={6} sm={12} xs={12} key={index}>
                <div data-aos="fade-up" data-aos-duration="3000">
                  <div className="white-cardddd mt-4">
                    <h4 className="medium-level-heading">{service.title}</h4>
                    <p className="normal-common-txt mt-3">
                      {service.description}
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default EntityFormationService;
