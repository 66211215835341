import { Avatar } from 'primereact/avatar'
import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

const RolesWithPermission = () => {

    const [expandedRows, setExpandedRows] = useState(null);

    const data = [
        {
            id: 1,
            roleName: "Super_Admin",
            description:"",
            permissions: [
                {
                    permissionName: "AllowBlockingLoginFunctionality",
                    description: "hshshshsh"
                },
                {
                    permissionName: "AllowBlockingRegistrationFunctionality",
                    description: "hdhhdh"
                },
                {
                    permissionName: "AllowCreatingNewUser",
                    description: "dhhdhdh"
                },
                {
                    permissionName: "AllowExistingUserModification",
                    description: "ndndjdj"
                }

            ]
        },
        {
            id: 2,
            roleName: "Admin",
            description:"",
            permissions: [


            ]
        },
        {
            id: 3,
            roleName: "User",
            description:"",
            permissions: [


            ]
        },
        
    ]

    const onRowExpand = (event) => {
        console.log('kkkk')
        setExpandedRows({ [event.data]: true });
    }

    const onRowCollapse = (event) => {
        setExpandedRows(null)
    }

    const allowExpansion = (rowData) => {
        console.log(rowData)
        return rowData.permissions.length >= 0
    };

    const [selectedProduct, setSelectedProduct] = useState(null);

    const history = useNavigate()


    const onRowSelect = (event) => {
        console.log(event.data)
        const queryParams = new URLSearchParams();
        queryParams.append('id', event.data.id);
        queryParams.append('roleName', event.data.roleName);
        event.data.permissions.forEach((permission, index) => {
          queryParams.append(`permissions[${index}][permissionName]`, permission.permissionName);
          queryParams.append(`permissions[${index}][description]`, permission.description);
        });
    
        history(`/roleswithpermissionchild?${queryParams.toString()}`);
        // history({
        //     pathname: "/roleswithpermissionchild",
        //     search: createSearchParams(event.data).toString()
        // })
    };



    const expanderTemplate = (rowData, options) => {
        return (
            <div>
                <i className='pi pi-asterisk'></i>
            </div>
        )
    };


    const rowExpansionTemplate = (data) => {
        console.log(data)
        const x = data.permissions

        return (
            <>

                <DataTable value={x} editMode="row" dataKey="id" style={{ width: "100%" }}
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} className='datatable-sm  font-fam-for-all'

                    globalFilterFields={['name', 'cityName', 'id',]}   >
                    {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} className='permission-datatable'></Column> */}
                    <Column className='datatable-sm-col'></Column>

                    <Column field="permissionName" header="Permission Name" className='font-fam-for-all permission-datatable datatable-sm-col'
                        sortable ></Column>
                    <Column field="description" header="Description" className='font-fam-for-all permission-datatable datatable-sm-col'
                        sortable ></Column>
                    <Column></Column>
                    <Column></Column>



                </DataTable>

            </>
        )
    }


    return (
        <div>
            <div className='dashboard-data-header' style={{ marginBottom: "10px", marginTop: "10px" }}>
                <div className='dashboard-data-sub-header' style={{ paddingLeft: "0px", paddingRight: "15px" }}>
                    <div className='sub-header-css1'>
                        <div className='header-content'>
                            <div className='text-header-container'>
                                {/* <button onClick={() => history("/contact")}>Back</button> */}

                                <div className='text-header1'>Users</div>
                                <div className='text-header2'>Manage Your Users Here</div>
                            </div>
                            <div className='text-header-container1'>

                                <div className='action-header-container'>
                                    <Button icon="pi pi-cog" className='header-button'></Button>

                                    <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '20px', marginTop: '10px' }}>
                                        <Badge value="2"></Badge>
                                    </i>
                                </div>
                                <div className='avatar-header-container'>
                                    <Avatar icon="pi pi-user" style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} shape="circle" />
                                </div>

                            </div>

                        </div>
                    </div>


                </div>
            </div>

            <div className='divider-x my-4'></div>
            <div className='m-4'>
                <DataTable value={data} editMode="row" dataKey="id" selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)}
                    onRowSelect={onRowSelect} metaKeySelection={false} 
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} className='datatable-sm  font-fam-for-all'

                    globalFilterFields={['name', 'cityName', 'id',]}   >
                    {/* <Column expander expanderTemplate={expanderTemplate} style={{ width: '3em' }} /> */}

                    <Column field="roleName" header="Role Name" className='font-fam-for-all datatable-sm-col'
                        sortable ></Column>
                     <Column field="description" header="Role Description" className='font-fam-for-all datatable-sm-col'
                        sortable ></Column>
                    <Column></Column>
                    <Column></Column>
                    <Column></Column>

                </DataTable>
            </div>

        </div >
    )
}

export default RolesWithPermission