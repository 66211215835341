import React, { useContext, useEffect } from "react";
import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { Message } from "primereact/message";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import ShiftsServices from "../../services/demoServices";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import { Avatar } from "primereact/avatar";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";
import errorMessages, { globalMessages } from "../../utils/errorMessages";
import { InputMask } from "primereact/inputmask";
import CreditCardServices from "../../services/strapiService";
import UserServices from "../../services/userService";
import rolesMasterService from "../../services/rolesMaster";
import templateCsv from "../../Assets/users.csv";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ProgressBar } from "primereact/progressbar";

import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { Fax } from "@mui/icons-material";
import AddressService from "../../services/addressService";
import ContactService from "../../services/ContactService";
import companyMasterService from "../../services/companyMasterService";
import authContext from "../../common/authContext";
import { showToast } from "../../utils/toastUtils";
import useTableData from "../../redux/Reducers/useTableData";
import { checkDomain } from "../../utils/dnsEmailChecker";

const AddVendor = ({ visible, setVisible, pageRefresh, setPageRefresh }) => {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
  } = useContext(authContext);

  const {
    data: fetchedStateData,
    isLoading: stateLoading,
    isError: isStateError,
    error: stateError,
    refetch: reftechStateData,
  } = useTableData("state");

  const {
    data: fetchedCountryData,
    isLoading: countryLoading,
    isError: isCountryError,
    error: countryError,
    refetch: reftechCountryData,
  } = useTableData("countryMaster");

  const {
    data: fetchedIndustryData,
    isLoading: industryLoading,
    isError: isIndustryError,
    error: industryError,
    refetch: reftechIndustryData,
  } = useTableData("industryMaster");

  const {
    data: fetchedAddressTypeData,
    isLoading: addressTypeLoading,
    isError: isAddressTypeError,
    error: addressTypeError,
    refetch: reftechAddressTypeData,
  } = useTableData("addressType");

  const [value, setValue] = useState("");
  const [ingredient, setIngredient] = useState("");
  const toast = useRef(null);
  const [upload, setUpload] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isEqualAddress, setIsEqualAddress] = useState(true);
  const [allCustomer, setAllCustomer] = useState([]);

  const [allStates, setAllStates] = useState([]);

  const entityTypes = [
    { label: "LLC", value: "LLC" },
    { label: "Corp", value: "Corp" },
  ];

  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [cityErr, setcityErr] = useState("");

  const toastCenter = useRef(null);

  const [selectedSkills, setSelectedSkills] = useState(null);
  const navigate = useNavigate();

  const [salutation, setSalutation] = useState("");

  const [firstName, setFirstName] = useState("");

  const [lastName, setLastName] = useState("");

  const [website, setWebsite] = useState("");

  const [taxId, setTaxId] = useState("");

  const [affiliationCode, setAffiliationCode] = useState("");

  const [company, setCompany] = useState("");

  const [phone, setPhone] = useState("");

  const [address, setAddress] = useState("");

  const [city, setCity] = useState("");

  const [state, setState] = useState("");

  const [zip, setZip] = useState("");

  const [emailErr, setEmailErr] = useState("");

  const [passwordErr, setPasswordErr] = useState("");

  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [firstNameErr, setFirstNameErr] = useState("");

  const [lastNameErr, setLastNameErr] = useState("");

  const [phoneErr, setPhoneErr] = useState("");

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [roleId, setRoleId] = useState(null);

  const [isShowContent2, setIsShowContent2] = useState(false);

  const [isShowContent3, setIsShowContent3] = useState(false);

  const [globalErr, setGlobalErr] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const [addr1, setAddr1] = useState("");

  const [addr1Err, setAddr1Err] = useState("");

  const [addr2, setAddr2] = useState("");

  const [addr2Err, setAddr2Err] = useState("");

  const [addr3, setAddr3] = useState("");

  const [region, setRegion] = useState("");

  const [regionErr, setRegionErr] = useState("");

  const [states, setStates] = useState(null);

  const [statesErr, setStatesErr] = useState("");

  const [zipErr, setZipErr] = useState("");

  const [companyDetails, setCompanyDetails] = useState(null);

  const [allAddressType, setAllAddressType] = useState([]);

  const [addressType, setAddressType] = useState(null);

  const [allCountries, setAllCountries] = useState([]);

  const [countryData, setCountryData] = useState(null);

  const [stateValue, setStateValue] = useState(null);

  const [countryValue, setCountryValue] = useState(null);

  const [companyName, setCompanyName] = useState("");

  const [domicileSate, setdomicileSate] = useState("");

  const [DomicileCity, setDomicileCity] = useState("");

  const [FormationDate, setFormationDate] = useState(dayjs(null));

  const [DissolutionDate, setDissolutionDate] = useState("");

  const [serviceState, setServiceState] = useState(null);

  const [domesticState, setDomesticState] = useState(null);

  const [industryType, setIndustryType] = useState(null);

  const [fax, setFax] = useState("");

  const [EINNumber, setEINNumber] = useState("");

  const [entityNumber, setEntityNumber] = useState("");

  const [texYearEnd, setTaxYearEnd] = useState("");

  const [allIndustryType, setAllIndustryType] = useState([]);

  const [businessPurpose, setBusinessPurpose] = useState("");

  const [businessStructure, setBusinessStructure] = useState(null);

  const [openDateErr, setOpenDateErr] = useState("");

  const [admin, setAdmin] = useState(null);

  const [industryErr, setIndustryErr] = useState("");

  const [taxIdErr, setTaxIdErr] = useState("");

  const [industryTypeErr, setIndustryTypeErr] = useState("");

  const [addressTypeErr, setAddressTypeErr] = useState("");

  const [companyErr, setCompanyErr] = useState("");

  const [businessStructureErr, setBusinessStructureErr] = useState("");

  const [affiliationCodeErr, setAffiliationCodeErr] = useState("");

  const [affiliationUrlErr, setAffiliationUrlErr] = useState("");

  const [EINNumberErr, setEINNumberErr] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [isContentVisible, setIsContentVisible] = useState(true);

  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    // toast.current.show({
    //   severity: "info",
    //   summary: "Success",
    //   detail: "File uploaded",
    // });
    showToast("success", `File uploaded`);
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 1 MB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}
          ></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-file mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop File Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-file",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const dialogView = () => setVisible(false);
  const cancelView = () => {
    setVisible(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setIngredient("");
    setPhone("");
    setPassword("");
    setConfirmPassword("");
    setFirstNameErr("");
    setLastNameErr("");
    setPasswordErr("");
    setConfirmPasswordErr("");
    setPhoneErr("");
    setcityErr("");
    setEmailErr("");
    setVisible(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setIngredient("");
    setPhone("");
    setPassword("");
    setConfirmPassword("");
    setIsLoading(false);
    setCompanyErr("");
    setCompanyName("");
    setBusinessStructureErr("");
    setBusinessStructure(null);
    setStatesErr("");
    setState("");
    setAddr1Err("");
    setAddr1("");
    setAddr2("");
    setCity("");
    setcityErr("");
    setFirstNameErr("");
    setZip("");
    setZipErr("");
    setIngredient("");
    setEmailErr("");
    setPhoneErr("");
    setPhone("");
    setIndustryType(null);
    setIndustryErr("");
    setAffiliationCode("");
    setAffiliationCodeErr("");
    setdomicileSate("");
    setAffiliationUrlErr("");
    setIndustryErr("");
    setIndustryTypeErr("");
    setAddressTypeErr("");
    setAddressType(null);
    setFax("");
    setWebsite("");
    setStates(null);
    setEINNumber("");
    setEntityNumber("");
    setTaxYearEnd("");
    setEINNumberErr("");
  };

  useEffect(() => {
    setCountryData(3);
    if (fetchedStateData) {
      setAllStates(fetchedStateData);
    }
  }, [fetchedStateData]);

  useEffect(() => {
    if (fetchedIndustryData) {
      setAllIndustryType(fetchedIndustryData);
    }
  }, [fetchedIndustryData]);

  useEffect(() => {
    if (fetchedCountryData) {
      setAllCountries(fetchedCountryData);
    }
  }, [fetchedCountryData]);

  useEffect(() => {
    if (fetchedAddressTypeData) {
      setAllAddressType(fetchedAddressTypeData);
    }
  }, [fetchedAddressTypeData]);

  useEffect(() => {
    ContactService.getAllContactByUserId(user?.id)
      .then((res) => {
        
        let data = res.data;

        if (data.contactDetails !== undefined) {
          setAdmin(data.contactDetails);
        }
      })
      .catch((err) => {
        
      });
  }, []);

  let newObject = window.localStorage.getItem("user");
  let updatedUser = Object.assign({}, JSON.parse(newObject));

  useEffect(() => {
    setSelectedCountry({ name: "United States", code: "US", dialCode: "+1" });

    if (updatedUser) {
      if (updatedUser.companyRoles !== undefined) {
        if (updatedUser.companyRoles.length > 0) {
          if (updatedUser.companyRoles[0].roles.length > 0) {
            setRoleId(updatedUser.companyRoles[0].roles[0].id);
          }
        }
      }
    }
  }, []);

  useEffect(() => {}, []);

  // const handleCity = (e) => {
  //   setEmailErr("");
  //   const value = e.target.value;
  //   if (value === "") {
  //     setEmailErr(errorMessages.emailRequired);
  //   }
  //   if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
  //     setEmailErr(errorMessages.emailValidation);
  //   }
  //   setIngredient(e.target.value);
  // };

  const handleCity = async (e) => {
    setEmailErr("");
    const value = e.target.value;
    setIngredient(e.target.value);
    if (value === "") {
      setEmailErr(errorMessages.emailRequired);
    }
    // if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
    //     setEmailErr(errorMessages.emailValidation);
    // }
    // const domain = value.split("@")[1];
    // const isDomainValid = await checkDomain(domain);
    // if (!isDomainValid) {
    //     setEmailErr("Please enter valid email address");
    // }
  };

  const handleEmail = (e) => {
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setEmail(e.target.value);
  };

  const handleEntityNumber = (e) => {
    let value = e.target.value;
    // if (value === '') {
    //     setLastNameErr(errorMessages.lastNameRequired)

    // }
    // if (value && value.trim() !== '') {
    //     if (!/[A-Za-z]+$/.test(value)) {
    //         setLastNameErr(errorMessages.lastNameValidation)
    //     }

    // }
    setEntityNumber(e.target.value);
  };

  const handleEINNumber = (e) => {
    setEINNumberErr("");
    let value = e.target.value;
    // if (value === '') {
    //     setLastNameErr(errorMessages.lastNameRequired)

    // }
    if (value && value.trim() !== "") {
      if (!/^\d{2}-\d{7}$/.test(value)) {
        setEINNumberErr("Enter valid EIN number ex: 12-2662626");
      }
    }
    setEINNumber(e.target.value);
  };

  const handleTaxYearEnd = (e) => {
    let value = e.target.value;
    // if (value === '') {
    //     setLastNameErr(errorMessages.lastNameRequired)

    // }
    // if (value && value.trim() !== '') {
    //     if (!/[A-Za-z]+$/.test(value)) {
    //         setLastNameErr(errorMessages.lastNameValidation)
    //     }

    // }
    setTaxYearEnd(e.target.value);
  };

  const handleFax = (e) => {
    let value = e.target.value;

    setFax(e.target.value);
  };

  const handleWebsite = (e) => {
    let value = e.target.value;

    setWebsite(e.target.value);
  };

  const handleFirstName = (e) => {
    setFirstNameErr("");
    let value = e.target.value;
    if (value === "") {
      setFirstNameErr(errorMessages.firstNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setFirstNameErr(errorMessages.firstNameValidation);
      }
    }
    setFirstName(e.target.value);
  };

  const handlePassword = (e) => {
    setPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setPasswordErr(errorMessages.passwordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setPasswordErr(errorMessages.passwordValidation);
      }
    }
    setPassword(e.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const handleConfirmPassword = (e) => {
    setConfirmPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setConfirmPasswordErr(errorMessages.confirmPasswordValidation);
      }
    }
    setConfirmPassword(e.target.value);
  };

  const handleLastName = (e) => {
    setLastNameErr("");
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setLastName(e.target.value);
  };

  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
  };

  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;

    let inputValue = value.replace(/\D/g, ""); // Remove non-digit characters

    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }

    let formattedValue = inputValue;
    if (inputValue.length === 10) {
      formattedValue = inputValue.replace(
        /(\d{3})(\d{3})(\d{4})/,
        "($1) $2-$3"
      );
    }

    // Update input field value and state
    e.target.value = formattedValue;
    setPhone(formattedValue);

    // Check if input is empty
    if (value.trim() === "") {
      setPhoneErr(errorMessages.phoneRequired);
      return; // Stop further execution
    }

    

    // Validate phone number format only if length is 10
    if (inputValue.length <= 9) {
      if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formattedValue)) {
        setPhoneErr(errorMessages.phoneValidation);
      }
    }
  };

  // const handlePhone = (e) => {
  //   setPhoneErr("");
  //   let value = e.target.value;

  //   let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
  //   if (inputValue.length > 10) {
  //     inputValue = inputValue.slice(0, 10); // Limit to 10 digits
  //   }
  //   const formattedValue = inputValue.replace(
  //     /(\d{3})(\d{3})(\d{4})/,
  //     (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
  //   );
  //   if (inputValue.length === 10) {
  //     // Only show formatted value after 10 digits
  //     e.target.value = formattedValue;
  //     setPhone(formattedValue); // Update state with formatted number
  //   } else {
  //     e.target.value = inputValue;
  //     setPhone(inputValue);
  //   }

  //   if (value === "") {
  //     setPhoneErr(errorMessages.phoneRequired);
  //   }
  //   if (value && value.trim() !== "" && inputValue.length <= 9) {
  //     if (
  //       !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(value)
  //     ) {
  //       setPhoneErr(errorMessages.phoneValidation);
  //     }
  //   }
  //   setPhone(e.target.value);
  // };

  const handleOpenDate = (newValue) => {
    setOpenDateErr("");
    
    if (newValue && dayjs(newValue).isValid()) {
      // setFormationDate(newValue);
      if (newValue !== null) {
        setFormationDate(newValue);
        // const d = new Date(newValue.$d)
        // if (isNaN(d.getDay()) || isNaN(d.getMonth()) || isNaN(d.getFullYear())) {
        //     setOpenDateErr("Select Valid Position Opened Date")
        // }
      } else {
        setOpenDateErr("Position Open Date Required");
      }
    } else {
      setFormationDate(null); // or handle the invalid case
    }
  };

  

  const handleToggle2 = () => {
    setIsShowContent2(!isShowContent2);
  };

  const handleToggle3 = () => {
    setIsShowContent3(!isShowContent3);
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        {selectedCountry ? (
          <span>
            <b>{selectedCountry.name}</b> selected.
          </span>
        ) : (
          "No country selected."
        )}
      </div>
    );
  };

  const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none">
    <circle cx="9.79519" cy="2.00625" r="2.00625" fill="#A3D5C9"/>
<circle cx="7.67096" cy="6.13675" r="1.06213" fill="#A3D5C9"/>
<circle cx="1.23915" cy="11.5064" r="1.23915" fill="#A3D5C9"/>
<circle cx="15.2829" cy="12.4506" r="1.47518" fill="#A3D5C9"/>
<circle cx="19.0004" cy="8.26101" r="1.06213" fill="#A3D5C9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6886 0.650298C15.7808 1.14561 15.3314 1.88426 15.3314 2.88125C15.3314 3.25051 15.4132 3.69897 15.5172 3.90007C15.6192 4.09751 15.6675 4.29412 15.6244 4.33672C15.5812 4.37932 14.4255 5.56549 13.0562 6.97258L10.5663 9.5309L10.2051 9.33783C9.72642 9.08209 8.5438 9.08256 8.0643 9.33889L7.70094 9.53302L6.31214 8.14057L4.92323 6.74811L5.10155 6.40316C5.40803 5.8106 5.4491 5.28143 5.23785 4.6484C4.87201 3.5524 4.02785 2.93907 2.885 2.93907C1.62401 2.93907 0.628439 3.83469 0.499331 5.08529C0.309446 6.92561 2.08686 8.27074 3.82911 7.60537L4.36643 7.40014L5.74319 8.76298L7.11995 10.1258L6.91815 10.5489C6.61756 11.1792 6.65663 12.0617 7.01421 12.7184C8.17748 14.8542 11.3541 14.1761 11.5336 11.7535C11.5703 11.2579 11.5252 10.9367 11.3712 10.5952L11.1581 10.1225L13.751 7.52949L16.3439 4.93659L16.8074 5.13037C17.5107 5.42411 18.5064 5.31601 19.138 4.87711C20.5128 3.92202 20.5099 1.83623 19.1323 0.87901C18.7142 0.58834 18.4875 0.520836 17.8341 0.49204C17.2702 0.467257 16.9408 0.512693 16.6886 0.650298Z" fill="white"/>
</svg>
 `;

  

  const onFileUpload = (e) => {
    e.preventDefault();

    let formData = new FormData();

    formData.append("file", selectedFile);

    

    if (selectedFile === null) {
      // toast.current.show({
      //   severity: "error",
      //   summary: "Error",
      //   detail: "Please choose the file",
      //   life: 2500,
      // });
      showToast("error", `Please choose the file`);
    }

    UserServices.bulkUserUpload(formData, roleId)
      .then((response) => {
        
        if (response.status === 201) {
        }
        if (response.status === 500) {
          // toast.current.show({
          //   severity: "error",
          //   summary: "Failed To Upload",
          //   detail: "Network error, Please try again!",
          //   life: 3000,
          // });
          showToast("error", `Network error, Please try again!`);
        }
        if (response.status === 504) {
          // toast.current.show({
          //   severity: "error",
          //   summary: "Failed To Upload",
          //   detail: "Gateway timeout!",
          //   life: 3000,
          // });
          showToast("error", `Gateway timeout!`);
        }
      })
      .catch((e) => {
        // toast.current.show({ severity: 'success', summary: 'Uploaded', detail: e.response.data.errorMessage, life: 3000 });
        
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true);

    let hasError = false;
    if (ingredient === "") {
      setEmailErr(errorMessages.emailRequired);
      hasError = true;
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(ingredient)) {
      setEmailErr(errorMessages.emailValidation);
      hasError = true;
    }
    const trimmedEmail = ingredient.trim();
    if (trimmedEmail === "") {
      setEmailErr(errorMessages.emailRequired);
      hasError = true;
    }
    if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(trimmedEmail)
    ) {
      setEmailErr(errorMessages.emailValidation);
      hasError = true;
    }
    const domain = ingredient.split("@")[1];

    const isDomainValid = await checkDomain(domain);
    if (!isDomainValid) {
      setEmailErr("Please enter valid email address");
      hasError = true;
    }

    if (hasError) {
      setIsDisabled(false);
      return;
    }

    const tempActualData = {
      companyName: companyName,
      contactName: domicileSate,
      email: ingredient,
      mobilePhone: phone !== "" ? phone : null,
      phone: phone !== "" ? phone : null,
      firstname: null,
      lastname: null,
      industryId: industryType,
      stateId: states,
      companyAddress: null,
      taxId: taxId,
      websiteUrl: website,
      statusId: 1,
      affiliationFlag: true,
      affiliationUrl: DissolutionDate,
      affiliationCode: affiliationCode,
      addressLine1: addr1,
      addressLine2: addr2,
      city: city,
      postalCode: zip,
      countryId: countryData,
    };

    

    if (
      ingredient !== "" &&
      phone !== "" &&
      companyName !== "" &&
      businessStructure &&
      industryType &&
      addr1 !== "" &&
      city !== "" &&
      states &&
      countryData &&
      zip !== "" &&
      domicileSate !== "" &&
      EINNumberErr === ""
    ) {
      setIsLoading(true);
      companyMasterService
        .saveVendor(tempActualData)
        .then((res) => {
          
          setIsDisabled(false);

          setFirstName("");
          setLastName("");
          setEmail("");
          setIngredient("");
          setPhone("");
          setPassword("");
          setConfirmPassword("");
          setIsLoading(false);
          setCompanyErr("");
          setCompanyName("");
          setBusinessStructureErr("");
          setBusinessStructure(null);
          setStatesErr("");
          setState("");
          setAddr1Err("");
          setAddr1("");
          setAddr2("");
          setCity("");
          setcityErr("");
          setFirstNameErr("");
          setZip("");
          setZipErr("");
          setIngredient("");
          setEmailErr("");
          setPhoneErr("");
          setPhone("");
          setIndustryType(null);
          setIndustryErr("");
          setAffiliationCode("");
          setAffiliationCodeErr("");
          setdomicileSate("");
          setAffiliationUrlErr("");
          setIndustryErr("");
          setIndustryTypeErr("");
          setAddressTypeErr("");
          setAddressType(null);
          setFax("");
          setWebsite("");
          setStates(null);
          setEINNumber("");
          setEntityNumber("");
          setTaxYearEnd("");
          setEINNumberErr("");
          setTimeout(() => {
            setPageRefresh(!pageRefresh);
          }, 3000);

          // if (toast.current !== undefined) {
          //   toast.current.show({
          //     severity: "success",
          //     summary: "Vendor added successfully",
          //     life: 3000,
          //     content: (props) => (
          //       <div
          //         className="flex flex-column align-items-left"
          //         style={{ flex: "1" }}
          //       >
          //         <div className="flex align-items-center gap-2">
          //           <Avatar
          //             shape="circle"
          //             style={{ backgroundColor: "#0047FF", color: "#ffffff" }}
          //           >
          //             <div dangerouslySetInnerHTML={{ __html: svgContent }} />
          //           </Avatar>
          //           <span className="p-toast-summary">vState Filings</span>
          //         </div>
          //         <div className="p-toast-detail">{props.message.summary}</div>
          //         <Button
          //           className=" signup-btn vstate-button flex"
          //           label="Ok"
          //           severity="success"
          //           onClick={() => navigate("/vendor")}
          //         ></Button>
          //       </div>
          //     ),
          //   });
          // }

          showToast("success", `Vendor added successfully`);

          setTimeout(() => {
            setVisible(false);
          }, 3000);
          // navigate("/signin")
        })
        .catch((err) => {
          
          setIsDisabled(false);
          setIsLoading(false);
          setVisible(true);
          if (err.response !== undefined) {
            if (err.response.status === 701) {
              
              if (err.response.data !== undefined) {
                setGlobalErr(err.response.data.errorMessage);
              }
            } else {
              setGlobalErr(errorMessages.somethingWrong);
            }
          } else {
            setGlobalErr(errorMessages.somethingWrong);
          }
        });
    } else {
      setIsDisabled(false);
      if (ingredient === "") {
        
        setEmailErr(errorMessages.emailRequired);
      }
      if (password === "") {
        setPasswordErr(errorMessages.passwordRequired);
      }
      // if (firstName === "") {
      //     setFirstNameErr()
      // }
      if (lastName === "") {
        setLastNameErr(errorMessages.lastNameRequired);
      }
      if (companyName === "") {
        setCompanyErr("Company name required");
      }
      if (addr1 === "") {
        setAddr1Err("Address line 1 required");
      }
      // if (addr2 === "") {
      //     setAddr2Err("Address line 2 required")
      // }
      if (city === "") {
        setcityErr("City required");
      }
      if (zip === "") {
        setZipErr("Zip code required");
      }
      if (phone === "") {
        setPhoneErr(errorMessages.phoneRequired);
      }
      if (confirmedPassword === "") {
        setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
      }

      if (!states) {
        setStatesErr("State required");
      }
      if (!industryType) {
        setIndustryErr("Industry type required");
      }
      if (!addressType) {
        setAddressTypeErr("Address type required");
      }
      if (!businessStructure) {
        setBusinessStructureErr("Business structure required");
      }
      if (domicileSate === "") {
        setFirstNameErr("Conatct name required");
      }
      if (affiliationCode === "") {
        setAffiliationCodeErr("Affiliation code required");
      }
      if (DissolutionDate === "") {
        setAffiliationUrlErr("Affiliation url required");
      }
    }
  };

  

  const customHeader = (
    <div className="flex align-items-center gap-2">
      <Avatar
        image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
        shape="circle"
      />
      <span className="font-bold">Amy Elsner</span>
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      <Toast ref={toastCenter} position="center" />
      <Sidebar
        position="right"
        header={customHeader}
        visible={visible}
        className="dialog-box"
        onHide={cancelView}
      >
        <div className="grid custom-sidebar-header">
          <div className="grid col-12 col-sm-12 col-md-12 col-lg-11 px-4 pt-4 pb-0">
            <div className="col-12 sidebar-header-text1 pb-0">Add Vendor</div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-1 flex justify-content-end pt-4 pb-0">
            <button onClick={cancelView}>
              <i className="pi pi-times"></i>
            </button>
          </div>
          <div className="col-12 sidebar-header-text2 px-4">
            Fill in the below details to add a vendor.
          </div>
        </div>

        <form id="myform" className="form-spacing mt-4" onSubmit={handleSubmit}>
          <Toast ref={toast} />

          <div className=" input-sidebar-card mt-4">
            <div className="px-5 pt-4">
              <div className="flex" style={{ justifyContent: "space-between" }}>
                <div className="font-fam-for-all srch-cand-text2">
                  <span>
                    <i className="pi pi-address-book"></i>
                  </span>
                  General Info
                </div>
              </div>
            </div>

            <div className="form-content-signup px-5 pb-5">
              <div className="grid  py-2">
                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-4"
                  style={{ fontSize: "14px" }}
                >
                  <span className=" mr-3">
                    <div class="relative">
                      <InputText
                        style={{ height: "40px", width: "100%" }}
                        maxLength={50}
                        value={companyName}
                        id="floating_outlined3"
                        placeholder=""
                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        onChange={(e) => {
                          setCompanyErr("");
                          setCompanyName(e.target.value);
                        }}
                        name="cityName"
                      />

                      <label
                        htmlFor="floating_outlined3"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Company Name
                        <span className="form-field-mandatory">*</span>
                      </label>
                    </div>
                    {companyErr !== "" ? (
                      <p className="error-msg font-fam-for-all">{companyErr}</p>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-4"
                  style={{ fontSize: "14px" }}
                >
                  <span className=" mr-3">
                    <div class="relative">
                      <Dropdown
                        value={businessStructure}
                        filter
                        resetFilterOnHide
                        onChange={(e) => {
                          setBusinessStructureErr("");
                          setBusinessStructure(e.value);
                        }}
                        options={entityTypes}
                        placeholder="Select Buisness Structure"
                        style={{ height: "40px" }}
                        className="w-full"
                      />
                      <label
                        htmlFor="floating_outlined2500023477"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Buisness Structure{" "}
                        <span className="form-field-mandatory">*</span>
                      </label>
                    </div>
                    {businessStructureErr !== "" ? (
                      <p className="error-msg font-fam-for-all">
                        {businessStructureErr}
                      </p>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-4"
                  style={{ fontSize: "14px" }}
                >
                  <span className=" mr-3">
                    <div class="relative">
                      <InputText
                        style={{ height: "40px", width: "100%" }}
                        maxLength={50}
                        value={domicileSate}
                        id="floating_outlined4"
                        placeholder=""
                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        onChange={(e) => {
                          setFirstNameErr("");
                          if (!/[A-Za-z]+$/.test(e.target.value)) {
                            setFirstNameErr("Enter valid contact name");
                          }
                          setdomicileSate(e.target.value);
                        }}
                        name="cityName"
                      />
                      <label
                        htmlFor="floating_outlined4"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Contact Name{" "}
                        <span className="form-field-mandatory">*</span>
                      </label>
                    </div>
                    {firstNameErr !== "" ? (
                      <p className="error-msg font-fam-for-all">
                        {firstNameErr}
                      </p>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                {/* <div className='col-4' style={{ fontSize: "14px" }}>
                                    <span className=" mr-3">
                                        <div class="relative">
                                            <InputText
                                                style={{ height: '40px', width: "100%" }}
                                                maxLength={50}
                                                value={DomicileCity}
                                                id="floating_outlined5"
                                                placeholder=''
                                                className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                onChange={(e) => setDomicileCity(e.target.value)}
                                                name="cityName"
                                            />
                                            <label
                                                htmlFor="floating_outlined5"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                                Affiliation Flag <span className="form-field-mandatory"></span>
                                            </label>
                                        </div>
                                    </span>
                                </div> */}

                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-4 "
                  style={{ fontSize: "14px" }}
                >
                  {/* <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}> */}

                  <span className=" mr-3">
                    <div class="relative">
                      <InputText
                        style={{
                          height: "40px",
                          borderRadius: "0px 6px 6px 0px",
                          width: "100%",
                        }}
                        maxLength={50}
                        value={DissolutionDate}
                        id="floating_outlined11"
                        placeholder=""
                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        onChange={(e) => {
                          setAffiliationUrlErr("");
                          setDissolutionDate(e.target.value);
                        }}
                        name="cityName"
                      />
                      <label
                        htmlFor="floating_outlined11"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Affiliation Url{" "}
                        <span className="form-field-mandatory">*</span>
                      </label>
                    </div>
                    {affiliationUrlErr !== "" ? (
                      <p className="error-msg font-fam-for-all">
                        {affiliationUrlErr}
                      </p>
                    ) : (
                      ""
                    )}
                  </span>
                  {/* </div> */}
                </div>

                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-4 "
                  style={{ fontSize: "14px" }}
                >
                  {/* <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}> */}

                  <span className=" mr-3">
                    <div class="relative">
                      <InputText
                        style={{
                          height: "40px",
                          borderRadius: "0px 6px 6px 0px",
                          width: "100%",
                        }}
                        maxLength={50}
                        value={affiliationCode}
                        id="floating_outlined111"
                        placeholder=""
                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        onChange={(e) => {
                          setAffiliationCodeErr("");
                          setAffiliationCode(e.target.value);
                        }}
                        name="cityName"
                      />
                      <label
                        htmlFor="floating_outlined111"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Affiliation Code{" "}
                        <span className="form-field-mandatory">*</span>
                      </label>
                    </div>
                    {affiliationCodeErr !== "" ? (
                      <p className="error-msg font-fam-for-all">
                        {affiliationCodeErr}
                      </p>
                    ) : (
                      ""
                    )}
                  </span>
                  {/* </div> */}
                </div>

                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-4 "
                  style={{ fontSize: "14px" }}
                >
                  {/* <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}> */}

                  <span className=" mr-3">
                    <div class="relative">
                      <InputText
                        style={{
                          height: "40px",
                          borderRadius: "0px 6px 6px 0px",
                          width: "100%",
                        }}
                        maxLength={50}
                        value={taxId}
                        id="floating_outlined111"
                        placeholder=""
                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        onChange={(e) => {
                          setTaxIdErr("");
                          if (value && value.trim() !== "") {
                            if (!/^\d{2}-\d{7}$/.test(e.target.value)) {
                              setTaxIdErr("Enter valid Tax ID ex: 12-2662626");
                            }
                          }
                          setTaxId(e.value);
                        }}
                        name="cityName"
                      />
                      <label
                        htmlFor="floating_outlined111"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Tax ID <span className="form-field-mandatory"></span>
                      </label>
                    </div>
                    {taxIdErr !== "" ? (
                      <p className="error-msg font-fam-for-all">{taxIdErr}</p>
                    ) : (
                      ""
                    )}
                  </span>
                  {/* </div> */}
                </div>

                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-4"
                  style={{ fontSize: "14px" }}
                >
                  <span className=" mr-3">
                    <div class="relative">
                      <Dropdown
                        value={industryType}
                        onChange={(e) => {
                          setIndustryTypeErr("");
                          setIndustryErr("");
                          setIndustryType(e.value);
                        }}
                        options={allIndustryType}
                        filter
                        resetFilterOnHide
                        optionValue="id"
                        optionLabel="industryName"
                        placeholder="Select Industry Type"
                        style={{ height: "40px" }}
                        className="w-full"
                      />
                      <label
                        htmlFor="floating_outlined25"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Industry Type{" "}
                        <span className="form-field-mandatory">*</span>
                      </label>
                    </div>
                    {industryErr !== "" ? (
                      <p className="error-msg font-fam-for-all">
                        {industryErr}
                      </p>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className=" input-sidebar-card mt-4">
            <div className="px-5 pt-4">
              <div className="flex" style={{ justifyContent: "space-between" }}>
                <div className="font-fam-for-all srch-cand-text2">
                  <span>
                    <i className="pi pi-address-book"></i>
                  </span>
                  Contact Info
                </div>
              </div>
            </div>

            <div className="form-content-signup px-5 pb-5">
              <div className="grid  justify-content-start">
                <div className="col-12">
                  <div className=" justify-content-center dialog-form-field-space grid  mt-2">
                    <div className="field col-12 py-0">
                      <span className=" ">
                        <div class="relative">
                          <InputText
                            // className='dialog-form-input-field'
                            id="floating_outlined71"
                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            style={{ width: "100%", height: "40px" }}
                            placeholder=""
                            maxLength={50}
                            value={ingredient}
                            onChange={handleCity}
                            name="cityName"
                          />

                          <label
                            htmlFor="floating_outlined71"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Company Email{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {emailErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {emailErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid  justify-content-start">
                <div className="col-12">
                  <div className="justify-content-center dialog-form-field-space">
                    <div className="field py-0">
                      <div
                        className="p-inputgroup flex-1"
                        style={{ height: "40px", width: "100%" }}
                      >
                        <Dropdown
                          value={selectedCountry}
                          options={countries}
                          onChange={onCountryChange}
                          resetFilterOnHide
                          filter
                          optionLabel="code"
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          style={{
                            width: "160px",
                            borderRadius: "6px 0px 0px 6px",
                          }}
                          className="p-dropdown font-fam-for-all"
                        />

                        <span className="" style={{ width: "100%" }}>
                          <div className="relative custom-input-mask">
                            <input
                              type="text"
                              id="floating_outlined201"
                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                              style={{
                                height: "40px",
                                borderRadius: "0px 6px 6px 0px",
                                width: "100%",
                              }}
                              maxLength={50}
                              value={phone}
                              onChange={handlePhone}
                              autoClear={false}
                              placeholder=""
                              name="cityName"
                            />
                            <label
                              htmlFor="floating_outlined201"
                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >
                              Company Phone{" "}
                              <span className="form-field-mandatory">*</span>
                            </label>
                          </div>
                          {phoneErr !== "" ? (
                            <p className="error-msg font-fam-for-all">
                              {phoneErr}
                            </p>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                      {/* <p className='error-msg font-fam-for-all'>{phoneErr}</p> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid  justify-content-center">
                {/* <div className='col-12'>
                                    <div className="justify-content-center dialog-form-field-space">
                                        <div className="field py-0">
                                            <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}>
                                                <Dropdown
                                                    value={selectedCountry}
                                                    options={countries}
                                                    onChange={onCountryChange}
                                                    optionLabel="code"
                                                    valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}

                                                    style={{ width: '160px', borderRadius: '6px 0px 0px 6px' }}
                                                    className="p-dropdown font-fam-for-all"
                                                />

                                                <span className="" style={{ width: "100%" }}>
                                                    <div className="relative custom-input-mask">
                                                        <InputText
                                                            id="floating_outlined74"
                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px', width: "100%" }}
                                                            maxLength={16}
                                                            value={fax}
                                                            // mask={isFocused ? "(999) 999-9999" : ""}
                                                            // onFocus={() => setIsFocused(true)}
                                                            // onBlur={() => setIsFocused(false)}
                                                            onChange={handleFax}
                                                            autoClear={false}
                                                            placeholder=''
                                                            name="cityName"
                                                        />
                                                        <label
                                                            htmlFor="floating_outlined74"
                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                        >
                                                            Fax Number
                                                        </label>
                                                    </div>
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                <div className="col-12">
                  <div className=" justify-content-center dialog-form-field-space grid  mt-2">
                    <div className="field col-12 py-0">
                      <span className=" ">
                        <div class="relative">
                          <InputText
                            // className='dialog-form-input-field'
                            id="floating_outlined81"
                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            style={{ width: "100%", height: "40px" }}
                            placeholder=""
                            maxLength={50}
                            value={website}
                            onChange={handleWebsite}
                            name="cityName"
                          />

                          <label
                            htmlFor="floating_outlined81"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Website
                          </label>
                        </div>
                        {emailErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {emailErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4"></div>
              </div>
            </div>
          </div>

          <div>
            <div className=" input-sidebar-card mt-4">
              <div className="px-5 pt-4">
                <div
                  className="flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="font-fam-for-all srch-cand-text2">
                    <span>
                      <i className="pi pi-address-book"></i>
                    </span>
                    Buisness Address
                  </div>
                </div>
              </div>

              <div className="form-content-signup px-5 pb-5">
                <div className="grid  py-2">
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4 "
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          maxLength={50}
                          value={addr1}
                          onChange={(e) => {
                            setAddr1Err("");
                            setAddr1(e.target.value);
                          }}
                          name="cityName"
                          id="floating_outlined21"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        />
                        <label
                          htmlFor="floating_outlined21"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Address Line 1{" "}
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {addr1Err !== "" ? (
                        <p className="error-msg font-fam-for-all">{addr1Err}</p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          maxLength={50}
                          value={addr2}
                          onChange={(e) => setAddr2(e.target.value)}
                          name="cityName"
                          id="floating_outlined22"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        />
                        <label
                          htmlFor="floating_outlined22"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Address Line 2{" "}
                          <span className="form-field-mandatory"></span>
                        </label>
                      </div>
                      {/* {addr2Err !== "" ? <p className="error-msg font-fam-for-all">{addr2Err}</p> : ""} */}
                    </span>
                  </div>

                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4 "
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          maxLength={50}
                          value={city}
                          onChange={(e) => {
                            setcityErr("");
                            setCity(e.target.value);
                          }}
                          name="cityName"
                          id="floating_outlined24"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        />
                        <label
                          htmlFor="floating_outlined24"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          City <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {cityErr !== "" ? (
                        <p className="error-msg font-fam-for-all">{cityErr}</p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>

                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <Dropdown
                          value={states}
                          onChange={(e) => {
                            setStatesErr("");
                            setStates(e.value);
                          }}
                          resetFilterOnHide
                          filter
                          options={allStates}
                          optionValue="id"
                          optionLabel="stateFullDesc"
                          placeholder="Select a State"
                          style={{ height: "40px" }}
                          className="w-full"
                        />
                        <label
                          htmlFor="floating_outlined25"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          State <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {statesErr !== "" ? (
                        <p className="error-msg font-fam-for-all">
                          {statesErr}
                        </p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <Dropdown
                          value={countryData}
                          onChange={(e) => setCountryData(e.value)}
                          options={allCountries}
                          filter
                          resetFilterOnHide
                          optionValue="id"
                          optionLabel="countryFullDesc"
                          placeholder="Select a Country"
                          style={{ height: "40px" }}
                          className="w-full"
                        />
                        <label
                          htmlFor="floating_outlined26"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Country{" "}
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="grid py-2">
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        {/* <InputText
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          maxLength={5}
                          value={zip}
                          onChange={(e) => {
                            setZipErr("");
                            if (!/^\d+$/.test(e.target.value)) {
                              setZipErr("Enter valid zip code");
                            }
                            setZip(e.target.value);
                          }}
                          name="cityName"
                          id="floating_outlined26"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        /> */}
                        <InputText
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          maxLength={5}
                          value={zip}
                          onChange={(e) => {
                            const value = e.target.value;

                            // Allow only numeric input
                            if (!/^\d*$/.test(value)) {
                              setZipErr("Enter a valid 5-digit zip code");
                              return;
                            }

                            // Ensure length is exactly 5
                            if (value.length !== 5) {
                              setZipErr("Zip code must be exactly 5 digits");
                            } else {
                              setZipErr(""); // No error if valid
                            }

                            setZip(value);
                          }}
                          name="cityName"
                          id="floating_outlined26"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        />

                        <label
                          htmlFor="floating_outlined26"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Zip Code{" "}
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {zipErr !== "" ? (
                        <p className="error-msg font-fam-for-all">{zipErr}</p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>

                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <Dropdown
                          value={addressType}
                          onChange={(e) => {
                            setAddressTypeErr("");
                            setAddressType(e.value);
                          }}
                          filter
                          resetFilterOnHide
                          options={allAddressType}
                          optionLabel="type"
                          placeholder="Select Address Type"
                          style={{ height: "40px" }}
                          className="w-full rounded-lg "
                        />
                        <label
                          htmlFor="floating_outlined2000"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Address Type{" "}
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {addressTypeErr !== "" ? (
                        <p className="error-msg font-fam-for-all">
                          {addressTypeErr}
                        </p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className=" input-sidebar-card mt-4">
              <div className="px-5 pt-4">
                <div
                  className="flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="font-fam-for-all srch-cand-text2">
                    <span>
                      <i className="pi pi-address-book"></i>
                    </span>
                    IRS/Accounting
                  </div>
                </div>
              </div>

              <div className="form-content-signup px-5 pb-5">
                <div className="grid  py-2">
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{ height: "40px", width: "100%" }}
                          maxLength={50}
                          value={EINNumber}
                          id="floating_outlined51"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          onChange={handleEINNumber}
                          name="cityName"
                        />

                        <label
                          htmlFor="floating_outlined51"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          EIN Number
                          <span className="form-field-mandatory"></span>
                        </label>
                      </div>
                      {EINNumberErr !== "" ? (
                        <p className="error-msg font-fam-for-all">
                          {EINNumberErr}
                        </p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>

                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{ height: "40px", width: "100%" }}
                          maxLength={50}
                          value={entityNumber}
                          id="floating_outlined91"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          onChange={handleEntityNumber}
                          name="cityName"
                        />

                        <label
                          htmlFor="floating_outlined91"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Entity Number
                          <span className="form-field-mandatory"></span>
                        </label>
                      </div>
                    </span>
                  </div>
                </div>

                <div className="grid">
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{ height: "40px", width: "100%" }}
                          maxLength={50}
                          value={texYearEnd}
                          id="floating_outlined92"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          onChange={handleTaxYearEnd}
                          name="cityName"
                        />

                        <label
                          htmlFor="floating_outlined92"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Tax Year End
                          <span className="form-field-mandatory"></span>
                        </label>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-content-end dialog-form-md-group-Btn my-5">
            <Button
              type="button"
              className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
              label="Cancel"
              style={{ height: "40px" }}
              onClick={cancelView}
            ></Button>
            <Button
              type="submit"
              className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
              label="Submit"
              style={{ height: "40px" }}
            ></Button>
          </div>
        </form>
      </Sidebar>
    </div>
  );
};

export default AddVendor;
