import React from "react";
import { useLottie } from "lottie-react";
// import groovyWalkAnimation from "./success.json";
import groovyWalkAnimation from "./successful.json";

const SuccessfulLotti = () => {
  const options = {
    animationData: groovyWalkAnimation,
    loop: true,
    style: {  height: "80px", width: "80px"},
  };

  const { View } = useLottie(options);
  return <div>{View}</div>;
};

export default SuccessfulLotti;
