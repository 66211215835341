import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BlueButton from "../CommonSection/BlueButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import user from "../../../Assets/Images/Images/user.png";
import AOS from "aos";
import "aos/dist/aos.css";

const RegisteredBanner = ({
  title,
  highlightText,
  subtitle,
  buttonLabel,
  bannerImage,
  onButtonClick,
  icon,
}) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className="main-section-class-home">
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div data-aos="fade-left" data-aos-duration="3000">
              <div className="mt-5">
                <h3 className="home-banner-heading">
                  {title} <br />
                  <span className="big-blue-text">{highlightText}</span>
                </h3>
                <p>{subtitle}</p>
                <div className="main-class-blue mt-5">
                  <button
                    className="blue-btnnn"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default behavior
                      onButtonClick(); // ✅ Call the scroll function
                    }}
                  >
                    <span>
                      {" "}
                      {buttonLabel} <FontAwesomeIcon icon={faArrowRightLong} />
                    </span>
                  </button>
                </div>
                <div className="d-flex mt-5">
                  <img src={user} className="user-img mt-2" />
                  <p className="home-banner-sub-tile-subscriber ms-3 mt-3">
                    10k+ Satisfied Customers
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div data-aos="fade-right" data-aos-duration="3000">
              <img src={bannerImage} className="banner-img mt-5" alt="Banner" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RegisteredBanner;
