import { Avatar } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import companyMasterService from "../../services/companyMasterService";
import AddressService from "../../services/addressService";
import MapWithStateAndCounty from "../../utils/CountyMap";
import PreviewDataComponent from "../../utils/PreviewDataComponent";
import { Accordion, AccordionTab } from "primereact/accordion";
import KeyPersonelCard from "../../utils/KeyPersonelCard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Button } from "primereact/button";

const OrderPreview = ({ data }) => {
  const [strapiJSON, setStrapiJSON] = useState(null);

  const [previewData, setPreviewData] = useState(null);

  const [allKeyPersonnel, setAllKeyPersonal] = useState([]);

  const [allStates, setAllStates] = useState([]);
    useEffect(() => {
    if (data) {
      console.log(data?.orderDetails.orderId, "exapmle");
      const parsedJsonData = JSON.parse(
        data?.orderDetails?.strapiOrderFormJson
      );

      if (parsedJsonData) {
        setStrapiJSON(parsedJsonData);
        setPreviewData(parsedJsonData?.Payload);
      }
    }
  }, [data]);

  useEffect(() => {
    companyMasterService
      .getAllKeyPersonnelType()
      .then((res) => {
        if (Array.isArray(res.data)) {
          setAllKeyPersonal(res.data);
        }
      })
      .catch((err) => {});

    AddressService.getAllStates()
      .then((res) => {
        setAllStates(res.data);
      })
      .catch((err) => {});
  }, []);

  const getState = (id) => {
    const temp = allStates.find((val) => val.id === id);
    if (temp !== undefined) {
      return temp.stateFullDesc;
    } else {
      return null;
    }
  };

  const getKeyPersonel = (id) => {
    if (id) {
      const temp = allKeyPersonnel.filter((val) => val.id === id);

      if (temp.length > 0) {
        return temp[0].keyPersonalType;
      } else {
        return null;
      }
    }
    return null;
  };

 

  const convertToTitleCase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
      .replace(/_/g, " ");
  };

  function convertName(originalString) {
    if (!originalString.includes("_")) {
      return originalString;
    }

    const parts = originalString.split("_");

    const relevantParts = parts.slice(1);

    // Capitalize the first letter of each part and join them with a space
    const formattedString = relevantParts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");

    return formattedString;
  }

  const toPascalCase = (str) => {
    return str
      .replace(/-/g, " ")
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const keyPersonalMemberData = [
    "Beneficial_Owner_Information",
    "Company_Applicant_Information",
    "Organizer_Information",
    "Incorporator_Information",
    "Director_Information",
    "Officer_Information",
    "Filer_Information",
    "President_Information",
    "Treasurer_Information",
    "Secretary",
    "UCC_Secured_Party_Details",
    "ucc-amnd-additional-debtor",
    "Billing_Information",
    "Mailing_Information",
    "ucc-amend-additional-secured-party-details",
    "ucc-assignee-details",
    "Registered-Agent",
    "UCC_Debtor_Details",
    "UCC_Amend_What_are_we_amending",
    "Registerd_Agent",
    "Principal_Address",
  ];

  // const downloadPDF = () => {
  //   const input = componentRef.current;
  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = pdf.internal.pageSize.getHeight();
  //   const headerHeight = 20; // Height reserved for the header
  //   const footerHeight = 15; // Height reserved for the footer
  //   const contentHeight = pdfHeight - headerHeight - footerHeight; // Usable height for content

  //   html2canvas(input, { scale: 2, useCORS: true }).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const canvasWidth = canvas.width;
  //     const canvasHeight = canvas.height;
  //     const imgHeight = (canvasHeight * pdfWidth) / canvasWidth;

  //     let heightLeft = imgHeight;
  //     let position = 0;
  //     let pageNumber = 1;

  //     while (heightLeft > 0) {
  //       if (position !== 0) pdf.addPage();

  //       // Header
  //       pdf.setFontSize(12);
  //       pdf.text("Order Details Report", pdfWidth / 2, 10, { align: "center" });

  //       // Content
  //       pdf.addImage(imgData, "PNG", 0, headerHeight, pdfWidth, imgHeight);
  //       heightLeft -= contentHeight;
  //       position -= contentHeight;

  //       // Footer
  //       pdf.setFontSize(10);
  //       pdf.text(`Page ${pageNumber}`, pdfWidth - 20, pdfHeight - 5);
  //       pageNumber++;
  //     }

  //     pdf.save(
  //       `${data?.orderDetails?.orderId ? data?.orderDetails?.orderId : "Preview"}_Order.pdf`
  //     );
  //   });
  // };

  const componentRef = useRef();

  const downloadPDF = () => {
    const input = componentRef.current;
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    html2canvas(input, { scale: 3, useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const imgHeight = (canvasHeight * pdfWidth) / canvasWidth;
      let heightLeft = imgHeight;
      let position = 0;

      while (heightLeft > 0) {
        pdf.addImage(imgData, "PNG", 0, position, pdfWidth, imgHeight);
        heightLeft -= pdfHeight;
        if (heightLeft > 0) {
          pdf.addPage();
          position = -pdfHeight; // Move position up for the next page
        }
      }

      pdf.save(
        `${
          data?.orderDetails?.orderId ? data?.orderDetails?.orderId : "Preview"
        }_Order.pdf`
      );
    });
  };

  return (
    <div>
      <div className="text-end mb-3 mt-3">
        <Button
          className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
          label="Download as a PDF"
          icon="pi pi-download"
          onClick={downloadPDF}
        ></Button>
      </div>
      <div ref={componentRef}>
        {previewData && data !== null ? (
          data.orderDetails?.orderTypeId !== 1 ? (
            <>
              {console.log(previewData, "previewData")}

              <div
                className="wizard-content-sub-card-header mb-3"
                id="header"
              ></div>

              <div className="flex p-4 justify-between font-fam-for-all">
                <div className="datatable-highlight-color">
                  {data.entityTypeId ? data.entityTypeId.entityFullDesc : "--"}
                </div>
                <div className="datatable-highlight-color">
                  {data.state ? data.state.stateFullDesc : "--"}
                  <span className="ml-2">
                    {data?.county && `(${data.county?.countyName})`}
                  </span>
                </div>
                {/* <div className='card'>
                            <MapWithStateAndCounty locationName={"California"} />
                        </div> */}
              </div>
              <div className="min-h-screen bg-gray-50  flex justify-center items-start w-full ">
                <div className="w-full">
                  <div className="bg-white py-2">
                    <div className="space-y-8">
                      {Array.isArray(data.orderLineItem) && (
                        <div className="bg-white">
                          <div
                            className="px-4 py-2 rounded-t-lg"
                            style={{
                              backgroundColor: "#F9FAFB",
                              fontFamily: "Inter",
                              fontSize: "16px",
                            }}
                          >
                            <h2 className="text-lg  text-gray-800">
                              Selected Services
                            </h2>
                          </div>

                          <div className="p-4 space-y-4">
                            {data.orderLineItem.map((service, index) => (
                              <div
                                key={index}
                                className="border grid border-gray-200 rounded-md p-2"
                              >
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10">
                                  <h3
                                    className="text-md font-semibold"
                                    style={{
                                      fontFamily: "Inter",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {service.productListing.displayName
                                      ? service.productListing.displayName
                                      : "--"}
                                  </h3>
                                  <p
                                    className="text-gray-700 mt-2"
                                    style={{
                                      fontFamily: "Inter",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {/* {service.productListing.productListingMaster.additionalInfo} */}
                                  </p>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2">
                                  <div
                                    className="text-right  font-bold text-blue-800"
                                    style={{
                                      fontFamily: "Inter",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {service.productListing.unitPrice === 0
                                      ? "Included"
                                      : `$${parseFloat(
                                          service.productListing.unitPrice
                                        ).toFixed(2)}`}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {Object.keys(previewData).map((sectionKey, index) => (
                        <>
                          {!keyPersonalMemberData.includes(sectionKey) ? (
                            <div key={index} className="bg-white">
                              <div
                                className="px-4 py-2"
                                style={{
                                  backgroundColor: "#F9FAFB",
                                  fontFamily: "Inter",
                                  fontSize: "16px",
                                }}
                              >
                                <h2 className="text-lg text-gray-800">
                                  {sectionKey ? toPascalCase(sectionKey) : "--"}
                                </h2>
                              </div>

                              <div className="px-4 py-4">
                                <div className="grid gap-3 mb-2">
                                  {!(
                                    previewData[sectionKey] !== null &&
                                    typeof previewData[sectionKey] === "object"
                                  ) &&
                                    !Array.isArray(previewData[sectionKey]) && (
                                      <>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                          <div className="order-preview-key">
                                            {sectionKey
                                              ? convertName(sectionKey)
                                              : "--"}
                                          </div>
                                          <div className="order-preview-value">
                                            {sectionKey
                                              ? previewData[sectionKey]
                                              : "--"}
                                          </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3"></div>
                                      </>
                                    )}
                                </div>

                                {previewData[sectionKey] !== null &&
                                  typeof previewData[sectionKey] === "object" &&
                                  !Array.isArray(previewData[sectionKey]) && (
                                    <div>
                                      <div className="grid preview-card p-4 mb-2">
                                        {Object.keys(previewData[sectionKey])
                                          .filter(
                                            (key) =>
                                              typeof previewData[sectionKey][
                                                key
                                              ] !== "object"
                                          )
                                          .map((key, i) => (
                                            <>
                                              {}
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                <div className="order-preview-key">
                                                  {key
                                                    ? convertName(key)
                                                    : "--"}
                                                </div>
                                                <div className="order-preview-value">
                                                  {/* {previewData[sectionKey][key]
                                                    ? previewData[sectionKey][
                                                        key
                                                      ]
                                                    : "--"} */}
                                                  {key ==="EIN_Social_security_number" 
                                                  || key === "S_Employer_identification_number"
                                                    ? previewData[sectionKey][
                                                        key
                                                      ]?.replace(/\d/g, "*")
                                                    : key ===
                                                      "EIN_Enter_The_Number_of_LLC_Members"
                                                    ? parseInt(
                                                        previewData[sectionKey][
                                                          key
                                                        ] || "0",
                                                        10
                                                      ) + 1
                                                    : previewData[sectionKey][
                                                        key
                                                      ] || "--"}
                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-3"></div>
                                            </>
                                          ))}
                                      </div>

                                      {Object.keys(previewData[sectionKey])
                                        .filter((key) => {
                                          const value =
                                            previewData[sectionKey][key];
                                          // Check if the key is an object and doesn't only contain '__temp_key__'
                                          return (
                                            value !== null &&
                                            typeof value === "object" &&
                                            (Object.keys(value).length > 1 ||
                                              !("__temp_key__" in value))
                                          );
                                        })
                                        .map((key, i) => (
                                          <div key={i} className="">
                                            {Array.isArray(
                                              previewData[sectionKey][key]
                                            ) ? (
                                              <div>
                                                <h3 className="text-lg text-gray-800 mb-2">
                                                  {key
                                                    ? convertToTitleCase(key)
                                                    : "--"}
                                                </h3>

                                                {/* Iterate over the array elements */}
                                                {previewData[sectionKey][
                                                  key
                                                ].map(
                                                  (arrayItem, arrayIndex) => (
                                                    <div
                                                      key={arrayIndex}
                                                      className="mb-2"
                                                    >
                                                      {typeof arrayItem ===
                                                      "object" ? (
                                                        <div>
                                                          {/* If the array contains objects, iterate over the keys of the object */}
                                                          <div className="grid preview-card p-4 mb-2">
                                                            {Object.keys(
                                                              arrayItem
                                                            ).map(
                                                              (nestedKey) => (
                                                                <>
                                                                  {nestedKey !==
                                                                    "__temp_key__" && (
                                                                    <>
                                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                                        <div className="order-preview-key">
                                                                          {nestedKey
                                                                            ? convertName(
                                                                                nestedKey
                                                                              )
                                                                            : "--"}
                                                                        </div>
                                                                        <div className="order-preview-value">
                                                                          {nestedKey
                                                                            ? arrayItem[
                                                                                nestedKey
                                                                              ]
                                                                            : "--"}
                                                                        </div>
                                                                      </div>
                                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-3"></div>
                                                                    </>
                                                                  )}
                                                                </>
                                                              )
                                                            )}
                                                          </div>

                                                          {/* Divider for Array Item */}
                                                          <div className="border-t border-gray-300 my-4"></div>
                                                        </div>
                                                      ) : (
                                                        <div className="grid gap-3 mb-2">
                                                          {/* If the array contains non-object values, display them directly */}
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 order-preview-key">
                                                            {`Item ${
                                                              arrayIndex + 1
                                                            }`}
                                                          </div>
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 order-preview-value">
                                                            {arrayItem
                                                              ? arrayItem
                                                              : "--"}
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                  )
                                                )}

                                                {/* Divider for Array Data */}
                                                <div className="border-t border-gray-300 my-4"></div>
                                              </div>
                                            ) : previewData[sectionKey][key] !==
                                                null &&
                                              typeof previewData[sectionKey][
                                                key
                                              ] === "object" ? (
                                              <div>
                                                <h3 className="text-lg text-gray-800 mb-2">
                                                  {key
                                                    ? convertToTitleCase(key)
                                                    : "--"}
                                                </h3>
                                                <div className="grid preview-card p-4 mb-2">
                                                  {Object.keys(
                                                    previewData[sectionKey][key]
                                                  ).map((nestedKey) => (
                                                    <>
                                                      {nestedKey !==
                                                        "__temp_key__" && (
                                                        <>
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                                                            <div className="order-preview-key">
                                                              {convertName(
                                                                nestedKey
                                                              )}
                                                            </div>
                                                            <div className="order-preview-value">
                                                              

                                                              {key ===
                                                                "EIN_Signature_and_Date" &&
                                                              nestedKey ===
                                                                "EIN_Date"
                                                                ? new Date(
                                                                    previewData[
                                                                      sectionKey
                                                                    ][key][
                                                                      nestedKey
                                                                    ]
                                                                  ).toLocaleDateString(
                                                                    "en-US",
                                                                    {
                                                                      year: "numeric",
                                                                      month:
                                                                        "2-digit",
                                                                      day: "2-digit",
                                                                    }
                                                                  )
                                                                : previewData[
                                                                    sectionKey
                                                                  ][key][
                                                                    nestedKey
                                                                  ] || "--"}
                                                            </div>
                                                          </div>
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"></div>
                                                        </>
                                                      )}
                                                    </>
                                                  ))}
                                                </div>

                                                {/* Divider for Nested Data */}
                                                <div className="border-t border-gray-300 my-4"></div>
                                              </div>
                                            ) : (
                                              <div className="grid gap-3 mb-2">
                                                {/* Key-Value Pair for Flat Object */}
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 order-preview-key">
                                                  {key
                                                    ? convertName(key)
                                                    : "--"}
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 order-preview-value">
                                                  {/* {previewData[sectionKey][key]
                                                    ? previewData[sectionKey][
                                                        key
                                                      ]
                                                    : "--"} */}
                                                    
                                                    {previewData[sectionKey][key] ? (
                                                      key === "SI_Par_Value" ? (
                                                        "$ " + previewData[sectionKey][key]
                                                      ) : (
                                                        previewData[sectionKey][key]
                                                      )
                                                    ) : (
                                                      "--"
                                                    )}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        ))}
                                    </div>
                                  )}

                                {Array.isArray(previewData[sectionKey]) &&
                                  previewData[sectionKey].map((item, idx) => {
                                    const primitiveKeys = [];
                                    const objectKeys = [];
                                    const arrayKeys = [];

                                    Object.keys(item).forEach((itemKey) => {
                                      if (itemKey !== "__temp_key__") {
                                        if (
                                          typeof item[itemKey] === "object" &&
                                          item[itemKey] !== null
                                        ) {
                                          if (Array.isArray(item[itemKey])) {
                                            arrayKeys.push(itemKey);
                                          } else {
                                            objectKeys.push(itemKey);
                                          }
                                        } else {
                                          primitiveKeys.push(itemKey);
                                        }
                                      }
                                    });

                                    return (
                                      <div key={idx}>
                                        <h3 className="text-lg text-gray-800 mb-2">
                                          {convertToTitleCase(sectionKey)}{" "}
                                          {idx + 1}
                                        </h3>

                                        {/* Primitive Keys */}
                                        <div className="grid preview-card pap p-4 mb-2">
                                          {primitiveKeys.length > 0 &&
                                            primitiveKeys.map((itemKey) => (
                                              <>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                                                  <div className="order-preview-key">
                                                    {itemKey
                                                      ? convertName(itemKey)
                                                      : "--"}
                                                  </div>
                                                  <div className="order-preview-value">
                                                    {item[itemKey] !== null
                                                      ? typeof item[itemKey] ===
                                                          "string" ||
                                                        typeof item[itemKey] ===
                                                          "number"
                                                        ? item[itemKey]
                                                        : JSON.stringify(
                                                            item[itemKey]
                                                          )
                                                      : "-"}
                                                  </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1"></div>
                                              </>
                                            ))}
                                        </div>

                                        {/* Object Keys */}
                                        <div className="grid preview-card p-4 mb-2">
                                          {objectKeys.length > 0 && (
                                            <div className="grid  sam p-4 mb-2">
                                              {objectKeys.map((itemKey) => (
                                                <>
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                    <div className="order-preview-key pb-2">
                                                      {itemKey
                                                        ? convertName(itemKey)
                                                        : "--"}
                                                    </div>
                                                    <div className="order-preview-value">
                                                      <div className="grid preview-card pap p-4 mb-2">
                                                        {Object.keys(
                                                          item[itemKey]
                                                        ).map((subKey) => (
                                                          <>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                                                              <div className="order-preview-key">
                                                                {subKey
                                                                  ? convertName(
                                                                      subKey
                                                                    )
                                                                  : "--"}
                                                              </div>
                                                              <div className="order-preview-value">
                                                                {item[itemKey][
                                                                  subKey
                                                                ]
                                                                  ? item[
                                                                      itemKey
                                                                    ][subKey]
                                                                  : "--"}
                                                              </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1"></div>
                                                          </>
                                                        ))}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              ))}
                                            </div>
                                          )}
                                        </div>

                                        {/* Array Keys */}
                                        <div className="grid preview-card p-4 mb-2">
                                          {arrayKeys.length > 0 &&
                                            arrayKeys.map((itemKey) => (
                                              <div
                                                className="col-3"
                                                key={itemKey}
                                              >
                                                <div className="order-preview-key">
                                                  {itemKey
                                                    ? convertName(itemKey)
                                                    : "--"}
                                                </div>
                                                <div className="order-preview-value">
                                                  {item[itemKey].map(
                                                    (subItem, subIdx) => (
                                                      <div
                                                        key={subIdx}
                                                        className="ml-4"
                                                      >
                                                        {typeof subItem ===
                                                        "object" ? (
                                                          <div className="grid preview-card pap p-4 mb-2">
                                                            {Object.keys(
                                                              subItem
                                                            ).map((subKey) => (
                                                              <>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                                                                  <div className="order-preview-key">
                                                                    {subKey
                                                                      ? convertName(
                                                                          subKey
                                                                        )
                                                                      : "--"}
                                                                  </div>
                                                                  <div className="order-preview-value">
                                                                    {subKey
                                                                      ? subItem[
                                                                          subKey
                                                                        ]
                                                                      : "--"}
                                                                  </div>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1"></div>
                                                              </>
                                                            ))}
                                                          </div>
                                                        ) : (
                                                          <div>
                                                            {subItem
                                                              ? subItem
                                                              : "--"}
                                                          </div>
                                                        )}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            ))}
                                        </div>

                                        <div className="border-t border-gray-300 my-4"></div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          ) : null}
                        </>
                      ))}

                      <div className="bg-white">
                        <div
                          className="px-4 py-2"
                          style={{
                            backgroundColor: "#F9FAFB",
                            fontFamily: "Inter",
                            fontSize: "16px",
                          }}
                        >
                          <h2 className="text-lg  text-gray-800">Contacts</h2>
                        </div>
                        {data?.orderDetails && (
                          <KeyPersonelCard data={data?.orderDetails} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex p-4 justify-between font-fam-for-all">
                <div className="datatable-highlight-color">
                  {data.entityTypeId ? data.entityTypeId.entityFullDesc : "--"}
                </div>
                <div className="datatable-highlight-color">
                  {data.state ? data.state.stateFullDesc : "--"}
                  <span className="ml-2">
                    {data?.county && `(${data.county?.countyName})`}
                  </span>
                </div>
                {/* <div className='card'>
                            <MapWithStateAndCounty locationName={"California"} />
                        </div> */}
              </div>
              {Array.isArray(data.orderLineItem) && (
                <div className="bg-white">
                  <div
                    className="px-4 py-2 rounded-t-lg"
                    style={{
                      backgroundColor: "#F9FAFB",
                      fontFamily: "Inter",
                      fontSize: "16px",
                    }}
                  >
                    <h2 className="text-lg  text-gray-800">
                      Selected Services
                    </h2>
                  </div>

                  <div className="p-4 space-y-4">
                    {data.orderLineItem.map((service, index) => (
                      <div
                        key={index}
                        className="border grid border-gray-200 rounded-md p-2"
                      >
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10">
                          <h3
                            className="text-md font-semibold"
                            style={{ fontFamily: "Inter", fontSize: "14px" }}
                          >
                            {service.productListing.displayName
                              ? service.productListing.displayName
                              : "--"}
                          </h3>
                          <p
                            className="text-gray-700 mt-2"
                            style={{ fontFamily: "Inter", fontSize: "12px" }}
                          >
                            {/* {service.productListing.productListingMaster.additionalInfo} */}
                          </p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2">
                          <div
                            className="text-right  font-bold text-blue-800"
                            style={{ fontFamily: "Inter", fontSize: "14px" }}
                          >
                            {service.productListing.unitPrice === 0
                              ? "Included"
                              : `$${parseFloat(
                                  service.productListing.unitPrice
                                ).toFixed(2)}`}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <PreviewDataComponent previewData={previewData} />
              <div className="bg-white">
                <Accordion activeIndex={0}>
                  <AccordionTab header="Contact">
                    <div className="p-4 ">
                      {data?.orderDetails && (
                        <KeyPersonelCard data={data?.orderDetails} />
                      )}
                    </div>
                  </AccordionTab>
                </Accordion>


              </div>
            </>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default OrderPreview;
