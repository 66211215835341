import React from "react";
import OurTrustedSection from "./CommonSection/OurTrustedSection";
import AboutUsBanner from "./AboutUsSection/AboutUsBanner";
import ServiceCard from "./AboutUsSection/ServiceCard";
import SeamlesslySec from "./AboutUsSection/SeamlesslySec";
import Flexibilty from "./AboutUsSection/Flexibilty";
import TestimonialsSec from "./AboutUsSection/TestimonialsSec";

const MainContent = () => {
  return (
    <div>
      <AboutUsBanner />
      <OurTrustedSection />
      <ServiceCard />
      <SeamlesslySec />
      <Flexibilty />
      <TestimonialsSec />
    </div>
  );
};

export default MainContent;
