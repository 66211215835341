import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import CreditCardServices from "../../services/strapiService";
import AddCity from "./AddProduct";
import DownloadPdf from "../../utils/downloadPdf";
import SideBarMenu from "../../common/Sidebar";
import ShiftsServices from "../../services/demoServices";
import { Checkbox } from "primereact/checkbox";
import { TabView, TabPanel } from "primereact/tabview";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import SuccessImage from "../../Assets/Images/icons8-success-96.png";
import ErroImage from "../../Assets/Images/error-icon.png";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import encryption from "../../services/CryptoClass";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Password } from "primereact/password";
import AddressService from "../../services/addressService";
import ContactService from "../../services/ContactService";
import MasterServices from "../../services/coreServices";
import OrderServices from "../../services/orderServices";
import { useNavigate } from "react-router-dom";
import companyMasterService from "../../services/companyMasterService";
import AddCompanyMember from "../Company/AddCompanyComponets/AddMember";
import authContext from "../../common/authContext";
import { Accordion, AccordionTab } from "primereact/accordion";
import AddressTemplate from "../../utils/AddressTemplate";
import AddCompanyLocation from "../Company/AddCompanyComponets/AddLocation";
import { InputTextarea } from "primereact/inputtextarea";
import { Sidebar } from "primereact/sidebar";
import PaymentOptions from "../Payment/PaymentOptions";
import { Editor } from "primereact/editor";
import { FileUpload } from "primereact/fileupload";
import { loadStripe } from "@stripe/stripe-js";
import PaymentSuccess from "../Payment/PaymentSuccess";
import CustomModal from "../../utils/CustomModal";
import axios from "axios";
import { Switch, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import propertiesApp from "../../utils/propertiesApp";
import { Toast } from "primereact/toast";
import AddBOIMember from "../Company/AddCompanyComponets/AddBOIMember";
import validationConfig from "../../utils/UsaStatesRegex";
import AddCompany from "./AddCompany";
import { InputMask } from "primereact/inputmask";
import PreviewDataComponent from "../../utils/PreviewDataComponent";
import KeyPersonelCard from "../../utils/KeyPersonelCard";
import { Skeleton } from "primereact/skeleton";
import { showToast } from "../../utils/toastUtils";
import { commonGetInitials } from "../../utils/getInitials";
import { stateWithCounty } from "../../utils/stateWithCounty";
import KeyPersonnelIndividualCard from "../../utils/KeyPersonnelIndividualCard";

const AddBOIServiceForm = () => {
    const registeredTabRef = useRef(null);
  
    const handleScroll = () => {
      if (registeredTabRef.current) {
        registeredTabRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else {
        console.error("registeredTabRef is null");
      }
    };
    
  const {
    loginUserName,
    setLoginUserName,
    addonServiceRedirectedData,
    globalCompany,
    addOnServiceData,
    setRedirectedData,
    isLoading,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh,
    entityDash,
    stateDash,
    paymentMethod,
    setPaymentMethod,
    orderTypeId,
    setOrderTypeId,
    paymentData,
    setPaymentData,
    paymentOrderData,
    setPaymentOrderData,
    setIsNameRendered,
    setOrderContinue,
    wizardScroll,
    setWizardScroll,
  } = useContext(authContext);

  const toast = useRef(null);

  const navigate = useNavigate();

  const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none">
    <circle cx="9.79519" cy="2.00625" r="2.00625" fill="#A3D5C9"/>
<circle cx="7.67096" cy="6.13675" r="1.06213" fill="#A3D5C9"/>
<circle cx="1.23915" cy="11.5064" r="1.23915" fill="#A3D5C9"/>
<circle cx="15.2829" cy="12.4506" r="1.47518" fill="#A3D5C9"/>
<circle cx="19.0004" cy="8.26101" r="1.06213" fill="#A3D5C9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6886 0.650298C15.7808 1.14561 15.3314 1.88426 15.3314 2.88125C15.3314 3.25051 15.4132 3.69897 15.5172 3.90007C15.6192 4.09751 15.6675 4.29412 15.6244 4.33672C15.5812 4.37932 14.4255 5.56549 13.0562 6.97258L10.5663 9.5309L10.2051 9.33783C9.72642 9.08209 8.5438 9.08256 8.0643 9.33889L7.70094 9.53302L6.31214 8.14057L4.92323 6.74811L5.10155 6.40316C5.40803 5.8106 5.4491 5.28143 5.23785 4.6484C4.87201 3.5524 4.02785 2.93907 2.885 2.93907C1.62401 2.93907 0.628439 3.83469 0.499331 5.08529C0.309446 6.92561 2.08686 8.27074 3.82911 7.60537L4.36643 7.40014L5.74319 8.76298L7.11995 10.1258L6.91815 10.5489C6.61756 11.1792 6.65663 12.0617 7.01421 12.7184C8.17748 14.8542 11.3541 14.1761 11.5336 11.7535C11.5703 11.2579 11.5252 10.9367 11.3712 10.5952L11.1581 10.1225L13.751 7.52949L16.3439 4.93659L16.8074 5.13037C17.5107 5.42411 18.5064 5.31601 19.138 4.87711C20.5128 3.92202 20.5099 1.83623 19.1323 0.87901C18.7142 0.58834 18.4875 0.520836 17.8341 0.49204C17.2702 0.467257 16.9408 0.512693 16.6886 0.650298Z" fill="white"/>
</svg>
 `;

  useEffect(() => {
    const handleBeforeUnload = (event) => {};

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  const [allState, setAllState] = useState([]);

  const stripePromise = loadStripe(
    "pk_test_51PyTdiKhsNfb7YuLO8XP7SHE3FUn3rspK3sI7OTbOIulKp9XdM7WtU4gqjxiKVDVBldIKIEBvL8PLjdKkW1h8f1100bw75rZPd"
  );

  const [contactId, setContactId] = useState(null);

  const history = useNavigate();

  const steps = [
    {
      label: "Select campaign settings",
      description: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
    },
    {
      label: "Create an ad group",
      description:
        "An ad group contains one or more ads which target a shared set of keywords.",
    },
    {
      label: "Create an ad",
      description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
  ];

  const [selectedCheckBoxData, setSelectedCheckBoxData] = useState([]);
  const [checkedState, setCheckedState] = useState({});

  const [formData, setFormData] = useState(null);

  const [loading1, setLoading1] = useState(false);

  const [strapiId, setStrapiId] = useState(null);

  const [entityErr, setEntityErr] = useState("");

  const [stateErr, setStateErr] = useState("");

  const [updatedOrderLineItems, setUpdatedOrderLineItems] = useState([]);

  const [director, setDirector] = useState(null);

  const [allDirector, setAllDirector] = useState([]);

  const [officer, setOfficer] = useState(null);

  const [allOfficer, setAllOfficer] = useState([]);

  const [keyPersonalCompany, setKeyPersonalCompany] = useState(null);

  const [allAssignee, setAllAssignee] = useState([]);

  const [assigneeData, setAssigneeData] = useState(null);

  const [isFirstDraft, setIsFirstDraft] = useState(false);

  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [isFocused, setIsFocused] = useState(false);

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  useEffect(() => {
    // Check if the page is being refreshed
    const isRefreshed = sessionStorage.getItem("isRefreshed");

    if (isRefreshed) {
      // If refresh detected, redirect to '/order'
      navigate("/dashboard");
      sessionStorage.removeItem("isRefreshed"); // Clear the flag after redirect
    }

    const handleBeforeUnload = () => {
      // Set flag in sessionStorage on page unload (refresh or close)
      sessionStorage.setItem("isRefreshed", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  const tempdata = [
    {
      item: "Roles Master",
      getAllUrl: "/core/api/roleMaster/getAll",
      label: "",
      optionValue: "",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "/core/api/roleMasters/save",
    },

    {
      item: "State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },

    {
      item: "State To Search",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "debt_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Of LLC",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },

    {
      item: "County Of LLC",
      getAllUrl: "/core/api/countyMaster/getAll",
      label: "countyName",
      optionValue: "countyCode",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "ANR_State_of_Formation",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "coad_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "uccAmend_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "ad_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "sp_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Text",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Of Filing",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Of Entity",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "BNR_State_Of_Formation",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Of Formation",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Or Province",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Issuing Jurisdiction State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State or Province",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },

    {
      item: "Issuing Jurisdiction Country",
      getAllUrl: "/core/api/countryMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/countryMasters/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Country",
      getAllUrl: "/core/api/countryMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/countryMasters/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },

    {
      item: "County",
      getAllUrl: "/core/api/countyMaster/getAll",
      label: "countyName",
      optionValue: "countyCode",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "County To Search",
      getAllUrl: "/core/api/countyMaster/getAll",
      label: "countyName",
      optionValue: "countyCode",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: " COC_Please_Include_New_County",
      getAllUrl: "/core/api/countyMaster/getAll",
      label: "countyName",
      optionValue: "countyCode",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },

    {
      item: "Please Include New County",
      getAllUrl: "/core/api/countyMaster/getAll",
      label: "countyName",
      optionValue: "countyCode",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Contact Source Master",
      getAllUrl: "/core/api/contactSourceMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },

    {
      item: "Industry Master",
      getAllUrl: "/core/api/industryMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Record Type Master",
      getAllUrl: "/core/api/recordTypeMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },

    {
      item: "Permission Master",
      getAllUrl: "/core/api/permissionMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "",
        },
      ],
      saveUrl: "",
    },

    {
      item: "Status Master",
      getAllUrl: "/core/api/statusMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "statusForId",
          url: "/core/api/statusForMasters/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Record Type Master",
      getAllUrl: "/core/api/recordTypeMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },
  ];

  const [IsCheckedAgent, setIsCheckedAgent] = useState(true);

  const [entityTypes, setEntityTypes] = useState([]);

  const [visible2, setVisible2] = useState(false);

  const [visible3, setVisible3] = useState(false);

  const [visible8, setVisible8] = useState(false);

  const [visible9, setVisible9] = useState(false);

  const [previewData, setPriviewData] = useState(null);

  const [memberType, setMemberType] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const [optionsMap, setOptionsMap] = useState({});

  const [allBilingAddress, setAllBilingAddress] = useState([]);

  const [allShippingAddress, setAllShippingAddress] = useState([]);

  const [bilingAddress, setBilingAddress] = useState(null);

  const [shippingAddress, setShippingAddress] = useState(null);

  const [closeProgresbar2, setCloseProgresbar2] = useState(false);

  const [closeProgresbar3, setCloseProgresbar3] = useState(false);

  const [companyApllicant, setCompanyApplicant] = useState(null);

  const [debtor, setDebtor] = useState(null);

  const [securedParty, setSecuredParty] = useState(null);

  const [allDocuments, setAllDocuments] = useState([]);

  const [serviceDataTemp, setServiceDataTemp] = useState([]);

  const [ownerType, setAllOwnerType] = useState([]);

  const setOptionsForField = (fieldKey, options) => {
    setOptionsMap((prev) => ({
      ...prev,
      [fieldKey]: options,
    }));
  };

  useEffect(() => {
    MasterServices.getAll("/core/api/entityTypeMaster/getAll")
      .then((res) => {
        if (res.data !== "") {
          setEntityTypes(res.data);
        }
      })
      .catch((err) => {});
    ContactService.getAllContact()
      .then((res) => {
        let data = res.data;

        const tempdata = data.filter(
          (val) => val.contactDetails.emailId === user.username
        );

        if (tempdata.length > 0) {
          if (tempdata[0].contactDetails !== undefined) {
            setContactId(tempdata[0].contactDetails.id);
          }
        }
      })
      .catch((err) => {});
    MasterServices.getAllDocuments()
      .then((res) => {
        if (Array.isArray(res.data)) {
          setAllDocuments(res.data);
        }
      })
      .catch((err) => {});

    MasterServices.getAllApplicantType()
      .then((res) => {
        if (res.data !== "") {
          setAllOwnerType(res.data);
        }
      })
      .catch((err) => {});
  }, []);

  const [selectedProducts, setSelectedProducts] = React.useState({});

  const handleCheckedChange = (e, serviceData) => {
    const { product } = serviceData;

    setSelectedProducts((prevSelected) => {
      const updatedSelected = { ...prevSelected };

      if (product.orderTypeMaster === 21 || product.orderTypeMaster === 18) {
        updatedSelected[product.orderTypeMaster] = [product.displayName];
      } else {
        if (e.checked) {
          if (!updatedSelected[product.orderTypeMaster]) {
            updatedSelected[product.orderTypeMaster] = [];
          }
          updatedSelected[product.orderTypeMaster].push(product.displayName);
        } else {
          updatedSelected[product.orderTypeMaster] = (
            updatedSelected[product.orderTypeMaster] || []
          ).filter((name) => name !== product.displayName);
          if (updatedSelected[product.orderTypeMaster].length === 0) {
            delete updatedSelected[product.orderTypeMaster];
          }
        }
      }

      setSelectedCheckBoxData((prevCheckboxData) => {
        const updatedCheckboxData = [...prevCheckboxData];

        if (product.orderTypeMaster === 20 || product.orderTypeMaster === 18) {
          return [
            ...updatedCheckboxData.filter(
              (data) => data.product.orderTypeMaster !== product.orderTypeMaster
            ),
            serviceData,
          ];
        }

        if (e.checked) {
          updatedCheckboxData.push(serviceData);
        } else {
          return updatedCheckboxData.filter(
            (data) => data.product.displayName !== product.displayName
          );
        }
        return updatedCheckboxData;
      });

      return updatedSelected;
    });
  };

  // Initialize Bootstrap tooltips
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);

  // const totalAmount = selectedCheckBoxData.reduce(
  //     (acc, item) => acc + parseFloat(item.amount.replace("$", "")),
  //     0
  // );

  const [creditAll, setCreditAll] = useState([]);

  const [creditInfo, setCreditInfo] = useState(null);

  const [enityName, setEntityName] = useState(null);

  const [state, setState] = useState(null);

  const [inputValue, setInputValue] = useState("");
  const [isAvailable, setIsAvailable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [llcMember, setLLCMember] = useState([]);

  const [llc, setLlc] = useState(null);

  const [allLLC, setAllLLC] = useState([]);

  const [llcName, setLLCName] = useState(null);

  const [visible, setVisible] = useState(false);

  const [selectCheckBoxData, setSelectCheckBoxData] = useState([]);

  const [IsChecked1, setIsChecked1] = useState(false);

  const [IsChecked2, setIsChecked2] = useState(false);

  const [IsChecked3, setIsChecked3] = useState(false);

  const [IsChecked4, setIsChecked4] = useState(false);

  const [IsChecked5, setIsChecked5] = useState(false);

  const [IsChecked6, setIsChecked6] = useState(false);

  const [IsChecked7, setIsChecked7] = useState(false);

  const [IsChecked8, setIsChecked8] = useState(false);

  const [IsChecked9, setIsChecked9] = useState(false);

  const [IsChecked10, setIsChecked10] = useState(false);

  const [IsChecked11, setIsChecked11] = useState(false);

  const [currentTab, setCurrentTab] = useState(0);

  const [closeProgresbar, setCloseProgresbar] = useState(false);

  const [closeProgresbar1, setCloseProgresbar1] = useState(false);

  const [dba, setDba] = useState("");

  const [name, setName] = useState("");

  const [AlternateName, setAlternateName] = useState("");

  const [llcAddress, setLLCAddress] = useState("");

  const [mailingAddress, setMailingAddress] = useState("");

  const [initData, setInitData] = useState(null);

  const [initData1, setInitData1] = useState(null);

  const [initData2, setInitData2] = useState(null);

  const [allInitData, setAllInitData] = useState([]);

  const [inputFormData, setInputFormData] = useState([]);

  const [allModels, setAllModels] = useState([]);

  const [modelData, setModelData] = useState([]);

  const [modelData1, setModelData1] = useState([]);

  const [IsSuccessful, setIsSuccessFul] = useState(false);

  const [modelName, setModelName] = useState(null);

  const [currentStep, setCurrentStep] = useState(1);

  const [activeStep, setActiveStep] = React.useState(0);

  const [globalModel, setGlobalModel] = useState([]);

  const [allComponents, setAllComponents] = useState([]);

  const [mainModel, setMainModel] = useState(null);

  const [rawModel, setRawModel] = useState([]);

  const [groupInitialData, setGroupInitialData] = useState({});

  const [showDescription, setShowDescription] = useState(false);

  const [organizerData, setOrganizerData] = useState(null);

  const [allOrganizer, setAllOrganizer] = useState([]);

  const [pageRefresh, setPageRefresh] = useState(false);

  const [allCompany, setAllCompany] = useState([]);

  const [company, setCompany] = useState(null);

  const [allLocation, setAllLocation] = useState([]);

  const [location, setLocation] = useState(null);

  const [allInCorporator, setAllInCorporator] = useState([]);

  const [aallDebtor, setAllDebtor] = useState([]);

  const [allSecuredParty, setAllSecuredParty] = useState([]);

  const [debtorData, setDebtorData] = useState(null);

  const [incorporator, setIncorporator] = useState(null);

  const [allCountries, setAllCountries] = useState([]);

  const [agentLocation, setAgentLocation] = useState(null);

  const [allKeyPersonnelType, setAllKeyPersonnelType] = useState([]);

  const [allAddressType, setAllAddressType] = useState([]);

  const [keyPersonalAddressType, setKeyPersonalAddressType] = useState(null);

  const [allCompanyApplicant, setAllCompanyApplicant] = useState([]);

  const [allBeneficialOwner, setAllBeneficialOwner] = useState([]);

  const [servicesData, setServiceData] = useState([]);

  const [allCounty, setAllCounty] = useState([]);

  const [county, setCounty] = useState(null);

  const [countyErr, setCountyErr] = useState("");

  const [allAdminCompany, setAllAdminCompany] = useState([]);

  const [adminCompany, setAdminCompany] = useState(null);

  useEffect(() => {
    // if (servicesData.length > 0) {
    //     const temp = servicesData.filter((val) => val.product.isDefault === true)
    //     setSelectedCheckBoxData(temp)
    // }
    if (enityName && state) {
      MasterServices.getAllProduct(
        orderTypeId,
        enityName.id,
        state.id,
        county ? county.id : 0
      )
        .then((res) => {
          if (Array.isArray(res.data)) {
            const sortedData = res.data.sort(
              (a, b) =>
                a.product.displayPreference - b.product.displayPreference
            );
            setServiceData(sortedData);
            // const temp = res.data.filter((val) => val.product.isDefault === true)
            const temp = res.data.filter(
              (val) =>
                val?.product?.orderTypeMaster !== 21 &&
                val?.product?.orderTypeMaster !== 18 &&
                val?.product?.orderTypeMaster === orderTypeId
            );

            setSelectedCheckBoxData(temp);
          }
          // else {
          //     setServiceData([])
          // }
        })
        .catch((err) => {});
      AddressService.getAllCountyByState(state.id)
        .then((res) => {
          setAllCounty(res.data);
        })
        .catch((err) => {});
    }
  }, [enityName, state, county]);

  // useEffect(() => {
  //     if (stateDash, entityDash) {
  //         setEntityName(entityDash)
  //         console.log(stateDash)
  //         setState(stateDash)
  //         setValue("state", stateDash)

  //     }
  // }, [entityDash, stateDash])

  useEffect(() => {
    companyMasterService
      .getAllKeyPersonnelType()
      .then((res) => {
        if (res.data !== "") {
          setAllKeyPersonnelType(res.data);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    companyMasterService
      .getAllCompany()
      .then((res) => {
        let data = res.data;
        setAllCompany(res.data);
        if (Array.isArray(res.data)) {
          const temp = res.data.filter(
            (val) => val.companyName === "vState Fillings"
          );
          if (globalCompany) {
            if (globalCompany.companyDetails) {
              const keyCompany = res.data.find(
                (val) => val.id === globalCompany.companyDetails.id
              );
              setKeyPersonalCompany(keyCompany);
            }
          }
          if (temp.length > 0) {
            setCompany(temp[0]);
          }
        }
      })
      .catch((err) => {});

    if (globalCompany) {
      companyMasterService
        .getAllChildCompanyByParentId(globalCompany?.companyDetails?.id)
        .then((res) => {
          if (res.data !== "") {
            let data = res.data;
            setAllAdminCompany(data);
          }
        })
        .catch((err) => {});
    }

    AddressService.getAllAddressType()
      .then((res) => {
        if (res.data !== undefined) {
          if (res.data !== "") {
            if (Array.isArray(res.data)) {
              setAllAddressType(res.data);
            }
          }
        }
      })
      .catch((err) => {});
  }, [globalCompany]);

  useEffect(() => {
    setSelectedCountry("+1");
    if (globalCompany) {
      AddressService.getAllAddressByCompanyId(globalCompany.companyDetails.id)
        .then((res) => {
          if (res.data !== "") {
            if (Array.isArray(res.data)) {
              const temp = res.data.filter(
                (val) => val.addressType.type === "PRINCIPAL"
              );
              const temp1 = res.data.filter(
                (val) => val.addressType.type === "BILLING"
              );
              const temp2 = res.data.filter(
                (val) => val.addressType.type === "SHIPPING"
              );

              if (temp.length > 0) {
                setAllLocation(temp);
              }
            }
          } else {
            setAllLocation([]);
            setAllBilingAddress([]);
            setAllShippingAddress([]);
          }
        })
        .catch((err) => {});
    }
  }, [globalCompany, isTabRefresh]);

  useEffect(() => {
    tempdata.forEach((matchedItem) => {
      if (matchedItem && matchedItem.getAllUrl) {
        MasterServices.getAll(matchedItem.getAllUrl)
          .then((fetchedData) => {
            if (Array.isArray(fetchedData.data)) {
              const labelKey = matchedItem.label || "name";
              const newOptions = fetchedData.data;
              setOptionsMap((prev) => ({
                ...prev,
                [matchedItem.item]: newOptions,
              }));
            }
          })
          .catch((err) => {});
      }
    });
  }, []);

  useEffect(() => {
    if (globalCompany) {
      if (globalCompany.companyDetails) {
        companyMasterService
          .getAllKeyPersonnelByCompanyId(globalCompany.companyDetails.id)
          .then((res) => {
            if (res.data !== "") {
              if (Array.isArray(res.data)) {
                const temp = res.data.filter(
                  (val) => val.keyPersonnelTypeId === 1
                );

                const temp1 = res.data.filter(
                  (val) => val.keyPersonnelTypeId === 12
                );

                const temp2 = res.data.filter(
                  (val) => val.keyPersonnelTypeId === 4
                );

                const temp3 = res.data.filter(
                  (val) => val.keyPersonnelTypeId === 5
                );

                const temp4 = res.data.filter(
                  (val) => val.keyPersonnelTypeId === 3
                );

                const temp5 = res.data.filter(
                  (val) => val.keyPersonnelTypeId === 13
                );

                const temp6 = res.data.filter(
                  (val) => val.keyPersonnelTypeId === 10
                );

                const temp7 = res.data.filter(
                  (val) => val.keyPersonnelTypeId === 9
                );

                const temp8 = res.data.filter(
                  (val) => val.keyPersonnelTypeId === 11
                );

                const temp9 = res.data.filter(
                  (val) => val.keyPersonnelTypeId === 14
                );

                const temp10 = res.data.filter(
                  (val) => val.keyPersonnelTypeId === 15
                );

                setAllOrganizer(temp);
                setAllInCorporator(temp1);
                setAllBilingAddress(temp2);
                setAllShippingAddress(temp3);
                setAllSecuredParty(temp7);
                setAllDebtor(temp6);
                setAllAssignee(temp8);
              }
            }
          })
          .catch((err) => {});

        companyMasterService
          .getAllOwnerInformation(globalCompany.companyDetails.id)
          .then((res) => {
            if (Array.isArray(res.data)) {
              const temp1 = res.data.filter(
                (val) => val.applicantType.name === "COMPANY APPLICANT"
              );
              const temp2 = res.data.filter(
                (val) => val.applicantType.name === "BENEFICIAL OWNER"
              );

              setAllBeneficialOwner(temp2);
              setAllCompanyApplicant(temp1);
            }
          })
          .catch((err) => {});
      }
    }
  }, [visible2, globalCompany, isTabRefresh]);

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const [formCount, setFormCount] = useState(1);

  const handleRepeatForm = (key) => {
    if (
      formCount < 2 ||
      groupedData[key].form[0].apiId !== "boi-company-applicant-details"
    ) {
      setFormCount((prevCount) => prevCount + 1);
    }
  };

  const handleRemoveRepeatForm = (key) => {
    const fieldNames = groupedData[key].form.flatMap((value) =>
      Object.keys(value.schema.attributes)
    );
    if (formCount > 1) {
      fieldNames.forEach((field) => {
        unregister(`${field}-${formCount - 1}`);
      });
      setFormCount((prevCount) => prevCount - 1);
    }
  };

  const handleNext = (data) => {
    if (activeStep === data.length - 1) {
      nextPrev(1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    unregister,
    clearErrors,
  } = useForm("");

  const [sidebarVisible, setSidebarVisible] = useState(true);

  useEffect(() => {
    AddressService.getAllCountry()
      .then((res) => {
        if (res.data !== "") {
          setAllCountries(res.data);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (company) {
      AddressService.getAllAddressByCompanyId(company.id)
        .then((res) => {
          if (res.data !== "") {
            setAllLocation(res.data);
            if (Array.isArray(res.data)) {
              const temp = res.data.filter(
                (val) => val.addressType.type === "PRINCIPAL"
              );

              if (temp.length > 0) {
                setAgentLocation(temp[0]);
              }
            }
          }
        })
        .catch((err) => {});
    }
  }, [company, IsCheckedAgent, sidebarVisible]);

  const stateFilter = (id) => {
    const temp = allState.filter((val) => val.id === id);

    if (temp.length > 0) {
      return temp[0].stateShortName;
    } else {
      return "";
    }
  };

  const countryFilter = (id) => {
    const temp = allCountries.filter((val) => val.id === id);

    if (temp.length > 0) {
      return temp[0].countryFullDesc;
    } else {
      return "";
    }
  };

  function numberToString(number) {
    if (typeof number === "number") {
      return number.toString();
    }
    if (typeof number === "string") {
      return number;
    }
  }

  function extractTenDigitPhoneNumber(input) {
    if (typeof input === "string") {
      const temp3 = input.split(" ");
      if (Array.isArray(temp3)) {
        if (temp3.length > 0) {
          return temp3.slice(1).join(" ");
        }
      }
    }
  }

  useEffect(() => {
    if (organizerData) {
      setValue("Org_Name", organizerData.keyPersonnelName);
      setValue("Org_Email_Address", organizerData.emailId);
      setValue(
        "Org_Contact_No",
        extractTenDigitPhoneNumber(organizerData.contactNo)
      );
      setValue(
        "Org_Address_Line_1",
        organizerData.addressMasterId.addressLine1
      );
      setValue(
        "Org_Address_Line_2",
        organizerData.addressMasterId.addressLine2
      );
      setValue("Org_City", organizerData.addressMasterId.city);
      setValue("Org_State", stateFilter(organizerData.addressMasterId.stateId));
      setValue(
        "Org_Country",
        countryFilter(organizerData.addressMasterId.countryId)
      );
      setValue("Org_Zip_Code", organizerData.addressMasterId.postalCode);
    }
  }, [organizerData]);

  useEffect(() => {
    if (incorporator) {
      setValue("Inc_Name", incorporator.keyPersonnelName);
      setValue("Inc_Email_Address", incorporator.emailId);
      setValue(
        "Inc_Contact_No",
        extractTenDigitPhoneNumber(incorporator.contactNo)
      );
      setValue("Inc_Address_Line_1", incorporator.addressMasterId.addressLine1);
      setValue("Inc_Address_Line_2", incorporator.addressMasterId.addressLine2);
      setValue("Inc_City", incorporator.addressMasterId.city);
      setValue("Inc_State", stateFilter(incorporator.addressMasterId.stateId));
      setValue(
        "Inc_Country",
        countryFilter(incorporator.addressMasterId.countryId)
      );
      setValue("Inc_Zip_Code", incorporator.addressMasterId.postalCode);
    }
  }, [incorporator]);

  useEffect(() => {
    if (assigneeData) {
      setValue("ad_Name_of_Assignee", assigneeData.keyPersonnelName);

      setValue("ad_Address_Line_1", assigneeData.addressMasterId.addressLine1);
      setValue("ad_Address_Line_2", assigneeData.addressMasterId.addressLine2);
      setValue("ad_City", assigneeData.addressMasterId.city);
      setValue("ad_State", stateFilter(assigneeData.addressMasterId.stateId));
      setValue(
        "ad_Zip_Code",
        numberToString(assigneeData.addressMasterId.postalCode)
      );
    }
  }, [assigneeData]);

  const handleDebtorChange = (selectedDebtor, index) => {
    setValue(`debt_Name_Of_Debtor-${index}`, selectedDebtor.keyPersonnelName);
    setValue(
      `debt_Address_Line_1-${index}`,
      selectedDebtor.addressMasterId.addressLine1
    );
    setValue(
      `debt_Address_Line_2-${index}`,
      selectedDebtor.addressMasterId.addressLine2
    );
    setValue(`debt_City-${index}`, selectedDebtor.addressMasterId.city);
    setValue(
      `debt_State-${index}`,
      stateFilter(selectedDebtor.addressMasterId.stateId)
    );
    setValue(
      `debt_Zip_Code-${index}`,
      numberToString(selectedDebtor.addressMasterId.postalCode)
    );
  };

  const demoOwner = {
    id: 1000,
    firstName: "Saumya",
    middleName: "string",
    lastName: "Patra",
    suffix: "Jr",
    email: "saumya@gmail.com",
    contactNumber: "+1 (788) 898-8989",
    exemptEntity: false,
    dateOfBirth: "2024-12-22T13:15:18.396+0000",
    country: "USA",
    state: "AL",
    issuingJurisdictionState: "AL",
    issuingJurisdictionCountry: "USA",
    residentialAddress: "new jersey",
    city: "New York",
    zipCode: 89797,
    uploadIDPage: "string",
    documentType: "string",
    identifyingDocumentNo: 0,
    lastNameOrBusinessLegalName: "string",
    haveFinCenId: true,
    finCenIdDocument: "string",
    finCenIdNumber: 0,
    uploadId: "string",
    applicantType: {
      id: 1000,
      name: "Beneficial Owner",
      description: "Beneficial owner",
    },
  };

  const handleBeneficialOwnerChange = (selectedDebtor, index) => {
    setValue(`boi_First_Name-${index}`, selectedDebtor.firstName);
    setValue(`boi_Middle_Name-${index}`, selectedDebtor.middleName);
    setValue(`boi_Last_Name-${index}`, selectedDebtor.lastName);
    setValue(`boi_Suffix-${index}`, selectedDebtor.suffix);
    setValue(`boi_Email-${index}`, selectedDebtor.email);
    setValue(`boi_Date_of_Birth-${index}`, selectedDebtor.dateOfBirth);
    setValue(
      `boi_Residential_Address-${index}`,
      `${selectedDebtor.residentialAddress}`
    );
    // setValue(`debt_Address_Line_2-${index}`, selectedDebtor.addressMasterId.addressLine2);
    setValue(`boi_City-${index}`, selectedDebtor.city);
    setValue(`boi_Contact_No-${index}`, selectedDebtor.contactNumber);
    setValue(`boi_State-${index}`, selectedDebtor.state);
    setValue(`boi_Country-${index}`, selectedDebtor.country);
    setValue(`boi_Zip_Code-${index}`, numberToString(selectedDebtor.zipCode));
    setValue(`boi_Have_a_FinCEN_ID-${index}`, selectedDebtor.haveFinCenId);
    setValue(`boi_FinCEN_ID_Number-${index}`, selectedDebtor.finCenIdNumber);
    setValue(
      `boi_Last_Name_or_Business_Legal_Name-${index}`,
      selectedDebtor.lastNameOrBusinessLegalName
    );
    setValue(
      `boi_Issuing_Jurisdiction_State-${index}`,
      selectedDebtor.issuingJurisdictionState
    );
    setValue(
      `boi_Issuing_Jurisdiction_Country-${index}`,
      selectedDebtor.issuingJurisdictionCountry
    );
    setValue(
      `boi_Choose_Identity_documents_type_to_upload-${index}`,
      selectedDebtor.documentType
    );
    setValue(`boi_Upload_ID-${index}`, selectedDebtor.uploadId);
    setValue(
      `boi_Upload_the_ID_page_of_your_document-${index}`,
      selectedDebtor.uploadIDPage
    );
    setValue(
      `boi_FinCEN_ID_Document-${index}`,
      selectedDebtor.finCenIdDocument
    );
    setValue(
      `boi_Identifying_Document_No-${index}`,
      selectedDebtor.identifyingDocumentNo
    );
    setValue(`boi_Exempt_Entity-${index}`, selectedDebtor.exemptEntity);
    // if (Array.isArray(selectedDebtor.documentIds)) {
    //     const filteredDocuments = allDocuments.filter(doc => selectedDebtor.documentIds.includes(doc.id));
    //     const uploadIdDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id"));
    //     const uploadIdPageDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id_page_of_your_document"));
    //     if (uploadIdDoc) {
    //         setValue(`boi_Upload_ID-${index}`, uploadIdDoc.documentPath)
    //     }
    //     if (uploadIdPageDoc) {
    //         setValue(`boi_Upload_the_ID_page_of_your_document-${index}`, uploadIdPageDoc.documentPath)
    //     }
    // }
  };

  const handleCompanyApplicantChange = (selectedDebtor, index) => {
    setValue(`boica_First_Name-${index}`, selectedDebtor.firstName);
    setValue(`boica_Middle_Name-${index}`, selectedDebtor.middleName);
    setValue(`boica_Last_Name-${index}`, selectedDebtor.lastName);
    setValue(`boica_Suffix-${index}`, selectedDebtor.suffix);
    setValue(`boica_Email-${index}`, selectedDebtor.email);
    setValue(`boica_Date_of_Birth-${index}`, selectedDebtor.dateOfBirth);
    setValue(
      `boica_Residential_Address-${index}`,
      `${selectedDebtor.residentialAddress}`
    );
    // setValue(`debt_Address_Line_2-${index}`, selectedDebtor.addressMasterId.addressLine2);
    setValue(`boica_City-${index}`, selectedDebtor.city);
    setValue(`boica_Contact_No-${index}`, selectedDebtor.contactNumber);
    setValue(`boica_State_or_Province-${index}`, selectedDebtor.state);
    setValue(`boica_Country-${index}`, selectedDebtor.country);
    setValue(`boica_Zip_Code-${index}`, numberToString(selectedDebtor.zipCode));
    setValue(`boica_Have_a_FinCEN_ID-${index}`, selectedDebtor.haveFinCenId);
    setValue(`boica_FinCEN_ID_Number-${index}`, selectedDebtor.finCenIdNumber);
    setValue(
      `boica_Issuing_Jurisdiction_State-${index}`,
      selectedDebtor.issuingJurisdictionState
    );
    setValue(
      `boica_Issuing_Jurisdiction_Country-${index}`,
      selectedDebtor.issuingJurisdictionCountry
    );
    setValue(
      `boica_Choose_Identity_documents_type_to_upload-${index}`,
      selectedDebtor.documentType
    );
    setValue(`boica_Upload_ID-${index}`, selectedDebtor.uploadId);
    setValue(
      `boica_Upload_the_ID_page_of_your_document-${index}`,
      selectedDebtor.uploadIDPage
    );
    setValue(
      `boica_FinCEN_ID_Document-${index}`,
      selectedDebtor.finCenIdDocument
    );
    setValue(
      `boica_Identifying_Document_No-${index}`,
      selectedDebtor.identifyingDocumentNo
    );
    // if (Array.isArray(selectedDebtor.documentIds)) {
    //     const filteredDocuments = allDocuments.filter(doc => selectedDebtor.documentIds.includes(doc.id));
    //     const uploadIdDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id"));
    //     const uploadIdPageDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id_page_of_your_document"));
    //     if (uploadIdDoc) {
    //         setValue(`boica_Upload_ID-${index}`, uploadIdDoc.documentPath)
    //     }
    //     if (uploadIdPageDoc) {
    //         setValue(`boica_Upload_the_ID_page_of_your_document-${index}`, uploadIdPageDoc.documentPath)
    //     }
    // }
  };

  const handleAdditionalDebtorChange = (selectedDebtor, index) => {
    setValue(
      `uccAmend_Name_of_Debtor-${index}`,
      selectedDebtor.keyPersonnelName
    );
    setValue(
      `uccAmend_Address_Line_1-${index}`,
      selectedDebtor.addressMasterId.addressLine1
    );
    setValue(
      `uccAmend_Address_Line_2-${index}`,
      selectedDebtor.addressMasterId.addressLine2
    );
    setValue(`uccAmend_City-${index}`, selectedDebtor.addressMasterId.city);
    setValue(
      `uccAmend_State-${index}`,
      stateFilter(selectedDebtor.addressMasterId.stateId)
    );
    setValue(
      `uccAmend_Zip_Code-${index}`,
      numberToString(selectedDebtor.addressMasterId.postalCode)
    );
  };

  const handleAdditionalSecuredPartyChange1 = (selectedDebtor, index) => {
    setValue(
      `sec_Name_Of_Secured_Party-${index}`,
      selectedDebtor.keyPersonnelName
    );
    setValue(
      `sec_Address_Line_1-${index}`,
      selectedDebtor.addressMasterId.addressLine1
    );
    setValue(
      `sec_Address_Line_2-${index}`,
      selectedDebtor.addressMasterId.addressLine2
    );
    setValue(`sec_City-${index}`, selectedDebtor.addressMasterId.city);
    setValue(
      `sec_State-${index}`,
      stateFilter(selectedDebtor.addressMasterId.stateId)
    );
    setValue(
      `sec_Zip_Code-${index}`,
      numberToString(selectedDebtor.addressMasterId.postalCode)
    );
  };

  const handleAdditionalSecuredPartyChange = (selectedDebtor, index) => {
    setValue(
      `sp_Name_of_Secured_Party-${index}`,
      selectedDebtor.keyPersonnelName
    );
    setValue(
      `sp_Address_Line_1-${index}`,
      selectedDebtor.addressMasterId.addressLine1
    );
    setValue(
      `sp_Address_Line_2-${index}`,
      selectedDebtor.addressMasterId.addressLine2
    );
    setValue(`sp_City-${index}`, selectedDebtor.addressMasterId.city);
    setValue(
      `sp_State-${index}`,
      stateFilter(selectedDebtor.addressMasterId.stateId)
    );
    setValue(
      `sp_Zip_Code-${index}`,
      numberToString(selectedDebtor.addressMasterId.postalCode)
    );
  };

  useEffect(() => {
    if (location) {
      setValue("PA_Address_Line_1", location.addressLine1);
      setValue("PA_Address_Line_2", location.addressLine2);
      setValue("PA_City", location.city);
      setValue("PA_State", stateFilter(location.stateId));

      setValue("PA_Country", countryFilter(location.countryId));
      setValue("PA_Zip_Code", location.postalCode);
    }
  }, [location]);

  useEffect(() => {
    if (company && IsCheckedAgent) {
      setValue("RA_Name", company.companyName);
      setValue("RA_Email_Address", company.companyEmail);
      setValue("RA_Contact_No", extractTenDigitPhoneNumber(company.phoneNo));
      if (agentLocation) {
        setValue("RA_Address_Line_1", agentLocation.addressLine1);
        setValue("RA_Address_Line_2", agentLocation.addressLine2);
        setValue("RA_City", agentLocation.city);
        setValue("RA_State", stateFilter(agentLocation.stateId));
        setValue("RA_Country", countryFilter(agentLocation.countryId));
        setValue("RA_Zip_Code", agentLocation.postalCode);
      }
    }
  }, [company, IsCheckedAgent, agentLocation]);

  useEffect(() => {
    const keys = ["X_Name_of_Entity"];
    if (adminCompany) {
      setValue("X_Name_of_Entity", adminCompany.companyName);
      // Clear error messages for each key
      keys.forEach((fieldKey) => {
        const errorMessageTag = document.getElementById(`${fieldKey}--error`);
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      });
    }
  }, [adminCompany]);

  useEffect(() => {
    if (getValues("COC_What_Are_We_Changing") === "Address") {
      setSidebarVisible(true);
    } else if (getValues("COC_What_Are_We_Changing") === "Registered Agent") {
      setSidebarVisible(true);
    }
  }, [getValues]);

  useEffect(() => {
    const data = watch();

    // if (state) {
    //     setValue("ANR_State_of_Formation", state.stateFullDesc)
    // }
    const tem = allModels.map((val) => {
      const temps = Object.keys(data).reduce((acc, key) => {
        if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      const convertdata = val.apiId;

      if (convertdata === "boi.boi-company-details") {
        const manualEntries = {
          ActivityPartyTypeCode: "62",
          PartyNameTypeCode: "L",
          BusinessAddressIndicator: "Y",
          ResidentialAddressIndicator: "Y",
        };

        Object.assign(temps, manualEntries);
      }

      return {
        model: convertdata,
        data: temps,
      };
    });

    const tempsData = allModels.map((val) => {
      const temps = Object.keys(data).reduce((acc, key) => {
        if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
          acc[key] = data[key];
        }

        return acc;
      }, {});

      const convertdata = val.uid;
      if (convertdata === "boi.boi-company-details") {
        const manualEntries = {
          ActivityPartyTypeCode: "62",
          PartyNameTypeCode: "L",
          BusinessAddressIndicator: "Y",
          ResidentialAddressIndicator: "Y",
        };

        Object.assign(temps, manualEntries);
      }
      if (
        convertdata === "momm.name-and-address" ||
        convertdata === "momm.option-name-address"
      ) {
        const addressFields = [
          "MM_Address_Line_1",
          "MM_Address_Line_2",
          "MM_City",
          "MM_State",
          "MM_Country",
          "MM_Zip_Code",
        ];
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize an object for this index if it doesn't exist
              if (!acc[index]) {
                acc[index] = { __temp_key__: parseInt(index), Address: {} };
              }

              if (addressFields.includes(baseKey)) {
                // If it's an address field, add it to the Address object
                acc[index].Address[baseKey] = data[key];
              } else {
                // Otherwise, add it directly to the object
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "debtor-details.debtor") {
        const addressFields = [
          "debt_Address_Line_1",
          "debt_Address_Line_2",
          "debt_City",
          "debt_State",
          "debt_Country",
          "debt_Zip_Code",
        ];
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize an object for this index if it doesn't exist
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  ucc_Address_Of_Debtor: {},
                };
              }

              if (addressFields.includes(baseKey)) {
                // If it's an address field, add it to the Address object
                acc[index].ucc_Address_Of_Debtor[baseKey] = data[key];
              } else if (
                baseKey.startsWith("debt") &&
                !addressFields.includes(baseKey)
              ) {
                // Otherwise, add it directly to the object
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "ucc-amend.uuc-amnd-what-are-amending") {
        const addressFields = [
          "uccAmend_Address_Line_1",
          "uccAmend_Address_Line_2",
          "uccAmend_City",
          "uccAmend_State",
          "uccAmend_Country",
          "uccAmend_Zip_Code",
          "uccAmend_Name_of_Debtor",
        ];

        const spAddressFields = [
          "sp_Name_of_Secured_Party",
          "sp_Address_Line_1",
          "sp_Address_Line_2",
          "sp_City",
          "sp_State",
          "sp_Zip_Code",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize the uccAmend_Additional_Debtor_Details array if not already created
              if (!acc.Additional_Secured_Party_Details) {
                acc.Additional_Secured_Party_Details = [];
              }

              // Find the corresponding object in Additional_Secured_Party_Details or create a new one
              let debtorDetails1 = acc.Additional_Secured_Party_Details.find(
                (item) => item.__temp_key__ === parseInt(index)
              );

              if (!debtorDetails1) {
                debtorDetails1 = { __temp_key__: parseInt(index) };
                acc.Additional_Secured_Party_Details.push(debtorDetails1);
              }

              // Initialize the uccAmend_Additional_Debtor_Details array if not already created
              if (!acc.uccAmend_Additional_Debtor_Details) {
                acc.uccAmend_Additional_Debtor_Details = [];
              }

              // Find the corresponding object in uccAmend_Additional_Debtor_Details or create a new one
              let debtorDetails = acc.uccAmend_Additional_Debtor_Details.find(
                (item) => item.__temp_key__ === parseInt(index)
              );

              if (!debtorDetails) {
                debtorDetails = { __temp_key__: parseInt(index) };
                acc.uccAmend_Additional_Debtor_Details.push(debtorDetails);
              }
              if (baseKey.startsWith("sp")) {
                // Add address fields to debtorDetails
                if (spAddressFields.includes(baseKey)) {
                  debtorDetails1[baseKey] = data[key];
                }
              } else {
                // Add address fields to debtorDetails
                if (addressFields.includes(baseKey)) {
                  debtorDetails[baseKey] = data[key];
                }
              }
            }
          } else if (
            key.startsWith("uccAmend") &&
            !addressFields.includes(key)
          ) {
            // For non-indexed fields, add them directly to the main object
            acc[key] = data[key];
          }

          return acc;
        }, {});

        return {
          model: convertdata,
          data: formData,
        };
      } else if (convertdata === "secured-party.secured-party") {
        const addressFields = [
          "sec_Address_Line_1",
          "sec_Address_Line_2",
          "sec_City",
          "sec_State",
          "sec_Country",
          "sec_Zip_Code",
        ];
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize an object for this index if it doesn't exist
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  ucc_Address_Of_Secured_Party: {},
                };
              }

              if (addressFields.includes(baseKey)) {
                // If it's an address field, add it to the Address object
                acc[index].ucc_Address_Of_Secured_Party[baseKey] = data[key];
              } else if (
                baseKey.startsWith("sec") &&
                !addressFields.includes(baseKey)
              ) {
                // Otherwise, add it directly to the object
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      }
      // else if (convertdata === "boi.boica-fin-cen-id" || convertdata === "boi.company-applicant-det" || convertdata === "boi.cad-address" || convertdata === "boi.boica-upload-document") {
      else if (convertdata === "boi.company-applicant") {
        const companyApplicantDetails = [
          "boica_First_Name",
          "boica_Middle_Name",
          "boica_Last_Name",
          "boica_Suffix",
          "boica_Date_of_Birth",
          "boica_Email",
          "boica_Contact_No",
          "boica_Residential_Address",
          "boica_Country",
          "boica_City",
          "boica_State_or_Province",
          "boica_Zip_Code",
          "boica_Choose_Identity_document_type_to_upload",
          "boica_Upload_the_ID_page_of_your_document",
          "boica_Upload_ID",
          "boica_Have_a_FinCEN_ID",
          "boica_FinCEN_ID_Number",
          "boica_Upload_the_ID_page_of_your_document",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = { __temp_key__: parseInt(index) };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[index][baseKey] = data[key];
              } else if (
                baseKey.startsWith("boica") &&
                !baseKey.startsWith("boi") &&
                !companyApplicantDetails.includes(baseKey)
              ) {
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const manualEntries = [
          { key: "ActivityPartyTypeCode", value: "63" },
          { key: "PartyNameTypeCode", value: "L" },
          { key: "BusinessAddressIndicator", value: "Y" },
          { key: "ResidentialAddressIndicator", value: "Y" },
        ];

        manualEntries.forEach((entry) => {
          formData.forEach((formObj, index) => {
            if (!formObj[entry.key]) {
              formObj[entry.key] = entry.value;
            }
          });
        });
        formData.forEach((formObj) => {
          const identityDocumentType =
            formObj["boica_Choose_Identity_document_type_to_upload"];
          let partyIdentificationTypeCode;

          if (identityDocumentType === "State Issued Drivers License") {
            partyIdentificationTypeCode = 37;
          } else if (identityDocumentType === "US Passport") {
            partyIdentificationTypeCode = 39;
          } else if (identityDocumentType === "Foreign Passport") {
            partyIdentificationTypeCode = 40;
          }
          if (partyIdentificationTypeCode !== undefined) {
            formObj["PartyIdentificationTypeCode"] =
              partyIdentificationTypeCode;
          }
        });

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "boi-except-entity-form") {
        const companyApplicantDetails = [
          "boi_Last_Name_or_Business_Legal_Name",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  boi_Exempt_Entity_Information: {},
                };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[index].boi_Exempt_Entity_Information[baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "boi.beneficial-owner-info") {
        const companyApplicantDetails = [
          "boi_First_Name",
          "boi_Middle_Name",
          "boi_Last_Name",
          "boi_Suffix",
          "boi_Date_of_Birth",
          "boi_Email",
          "boi_Contact_No",
          "boi_Residential_Address",
          "boi_Country",
          "boi_City",
          "boi_State",
          "boi_Zip_Code",
          "boi_Choose_Identity_document_type_to_upload",
          "boi_Upload_the_ID_page_of_your_document",
          "boi_Upload_ID",
          "boi_Have_a_FinCEN_ID",
          "boi_FinCEN_ID_Number",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  boi_Beneficial_Owner_Information: {},
                };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[index].boi_Beneficial_Owner_Information[baseKey] =
                  data[key];
              } else if (
                baseKey.startsWith("boi") &&
                !baseKey.startsWith("boica") &&
                !companyApplicantDetails.includes(baseKey)
              ) {
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const manualEntries = [
          { key: "ActivityPartyTypeCode", value: "64" },
          { key: "PartyNameTypeCode", value: "L" },
          { key: "BusinessAddressIndicator", value: "Y" },
          { key: "ResidentialAddressIndicator", value: "Y" },
        ];

        manualEntries.forEach((entry) => {
          formData.forEach((formObj, index) => {
            if (!formObj[entry.key]) {
              formObj[entry.key] = entry.value;
            }
          });
        });
        formData.forEach((formObj) => {
          const identityDocumentType =
            formObj["boi_Choose_Identity_document_type_to_upload"];
          let partyIdentificationTypeCode;

          if (identityDocumentType === "State Issued Drivers License") {
            partyIdentificationTypeCode = 37;
          } else if (identityDocumentType === "US Passport") {
            partyIdentificationTypeCode = 39;
          } else if (identityDocumentType === "Foreign Passport") {
            partyIdentificationTypeCode = 40;
          }

          if (partyIdentificationTypeCode !== undefined) {
            formObj["PartyIdentificationTypeCode"] =
              partyIdentificationTypeCode;
          }
        });

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else {
        const formData = Object.keys(data).map((keys, j) => {
          if (keys.includes("-")) {
            const baseKey = keys.split("-")[0];

            if (
              baseKey &&
              Object.keys(val.schema.attributes).some((ele) => ele === baseKey)
            ) {
              return {
                __temp_key__: j + 1,
                [baseKey]: data[keys],
              };
            }
          }
        });

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      }
    });

    setModelData(tem);
    setModelData1(tempsData);
  }, [getValues, setValue, currentTab, formCount]);

  function processFormData(allModels) {
    const data = watch();

    return allModels.map((val) => {
      const temps = Object.keys(data).reduce((acc, key) => {
        if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      const convertdata = val.uid;

      if (convertdata === "boi.boi-company-details") {
        Object.assign(temps, {
          ActivityPartyTypeCode: "62",
          PartyNameTypeCode: "L",
          BusinessAddressIndicator: "Y",
          ResidentialAddressIndicator: "Y",
        });
      }

      const handleIndexedFields = (addressFields, nestedFieldName) => {
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            if (baseKey) {
              if (!acc[index]) {
                acc[index] = { __temp_key__: parseInt(index) };
              }
              if (addressFields.includes(baseKey)) {
                if (!acc[index][nestedFieldName]) {
                  acc[index][nestedFieldName] = {};
                }
                acc[index][nestedFieldName][baseKey] = data[key];
              } else {
                acc[index][baseKey] = data[key];
              }
            }
          }
          return acc;
        }, []);
        return formData.filter((val) => val !== undefined);
      };

      if (
        convertdata === "momm.name-and-address" ||
        convertdata === "momm.option-name-address"
      ) {
        const addressFields = [
          "MM_Address_Line_1",
          "MM_Address_Line_2",
          "MM_City",
          "MM_State",
          "MM_Country",
          "MM_Zip_Code",
        ];
        const formDatas = handleIndexedFields(addressFields, "Address");
        return {
          model: convertdata,
          data: formDatas.length > 0 ? formDatas : temps,
        };
      } else if (convertdata === "debtor-details.debtor") {
        const addressFields = [
          "debt_Address_Line_1",
          "debt_Address_Line_2",
          "debt_City",
          "debt_State",
          "debt_Country",
          "debt_Zip_Code",
        ];
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize an object for this index if it doesn't exist
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  ucc_Address_Of_Debtor: {},
                };
              }

              if (addressFields.includes(baseKey)) {
                // If it's an address field, add it to the Address object
                acc[index].ucc_Address_Of_Debtor[baseKey] = data[key];
              } else if (
                baseKey.startsWith("debt") &&
                !addressFields.includes(baseKey)
              ) {
                // Otherwise, add it directly to the object
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "secured-party.secured-party") {
        const addressFields = [
          "sec_Address_Line_1",
          "sec_Address_Line_2",
          "sec_City",
          "sec_State",
          "sec_Country",
          "sec_Zip_Code",
        ];
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize an object for this index if it doesn't exist
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  ucc_Address_Of_Secured_Party: {},
                };
              }

              if (addressFields.includes(baseKey)) {
                // If it's an address field, add it to the Address object
                acc[index].ucc_Address_Of_Secured_Party[baseKey] = data[key];
              } else if (
                baseKey.startsWith("sec") &&
                !addressFields.includes(baseKey)
              ) {
                // Otherwise, add it directly to the object
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      }

      if (convertdata === "boi.company-applicant") {
        const companyApplicantDetails = [
          "boica_First_Name",
          "boica_Middle_Name",
          "boica_Last_Name",
          "boica_Suffix",
          "boica_Date_of_Birth",
          "boica_Email",
          "boica_Contact_No",
          "boica_Residential_Address",
          "boica_Country",
          "boica_City",
          "boica_State_or_Province",
          "boica_Zip_Code",
          "boica_Choose_Identity_document_type_to_upload",
          "boica_Upload_the_ID_page_of_your_document",
          "boica_Upload_ID",
          "boica_Have_a_FinCEN_ID",
          "boica_FinCEN_ID_Number",
          "boica_Upload_the_ID_page_of_your_document",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = { __temp_key__: parseInt(index) };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[index][baseKey] = data[key];
              } else if (
                baseKey.startsWith("boica") &&
                !baseKey.startsWith("boi") &&
                !companyApplicantDetails.includes(baseKey)
              ) {
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const manualEntries = [
          { key: "ActivityPartyTypeCode", value: "63" },
          { key: "PartyNameTypeCode", value: "L" },
          { key: "BusinessAddressIndicator", value: "Y" },
          { key: "ResidentialAddressIndicator", value: "Y" },
        ];

        manualEntries.forEach((entry) => {
          formData.forEach((formObj, index) => {
            if (!formObj[entry.key]) {
              formObj[entry.key] = entry.value;
            }
          });
        });
        formData.forEach((formObj) => {
          const identityDocumentType =
            formObj["boica_Choose_Identity_document_type_to_upload"];
          let partyIdentificationTypeCode;

          if (identityDocumentType === "State Issued Drivers License") {
            partyIdentificationTypeCode = 37;
          } else if (identityDocumentType === "US Passport") {
            partyIdentificationTypeCode = 39;
          } else if (identityDocumentType === "Foreign Passport") {
            partyIdentificationTypeCode = 40;
          }
          if (partyIdentificationTypeCode !== undefined) {
            formObj["PartyIdentificationTypeCode"] =
              partyIdentificationTypeCode;
          }
        });

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "boi.beneficial-owner-info") {
        const companyApplicantDetails = [
          "boi_First_Name",
          "boi_Middle_Name",
          "boi_Last_Name",
          "boi_Suffix",
          "boi_Date_of_Birth",
          "boi_Email",
          "boi_Contact_No",
          "boi_Residential_Address",
          "boi_Country",
          "boi_City",
          "boi_State",
          "boi_Zip_Code",
          "boi_Choose_Identity_document_type_to_upload",
          "boi_Upload_the_ID_page_of_your_document",
          "boi_Upload_ID",
          "boi_Have_a_FinCEN_ID",
          "boi_FinCEN_ID_Number",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  boi_Beneficial_Owner_Information: {},
                };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[index].boi_Beneficial_Owner_Information[baseKey] =
                  data[key];
              } else if (
                baseKey.startsWith("boi") &&
                !baseKey.startsWith("boica") &&
                !companyApplicantDetails.includes(baseKey)
              ) {
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const manualEntries = [
          { key: "ActivityPartyTypeCode", value: "64" },
          { key: "PartyNameTypeCode", value: "L" },
          { key: "BusinessAddressIndicator", value: "Y" },
          { key: "ResidentialAddressIndicator", value: "Y" },
        ];

        manualEntries.forEach((entry) => {
          formData.forEach((formObj, index) => {
            if (!formObj[entry.key]) {
              formObj[entry.key] = entry.value;
            }
          });
        });
        formData.forEach((formObj) => {
          const identityDocumentType =
            formObj["boi_Choose_Identity_document_type_to_upload"];
          let partyIdentificationTypeCode;

          if (identityDocumentType === "State Issued Drivers License") {
            partyIdentificationTypeCode = 37;
          } else if (identityDocumentType === "US Passport") {
            partyIdentificationTypeCode = 39;
          } else if (identityDocumentType === "Foreign Passport") {
            partyIdentificationTypeCode = 40;
          }

          if (partyIdentificationTypeCode !== undefined) {
            formObj["PartyIdentificationTypeCode"] =
              partyIdentificationTypeCode;
          }
        });

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "boi-except-entity-form") {
        const companyApplicantDetails = [
          "boi_Last_Name_or_Business_Legal_Name",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  boi_Exempt_Entity_Information: {},
                };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[index].boi_Exempt_Entity_Information[baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "ucc-amend.uuc-amnd-what-are-amending") {
        const addressFields = [
          "uccAmend_Address_Line_1",
          "uccAmend_Address_Line_2",
          "uccAmend_City",
          "uccAmend_State",
          "uccAmend_Country",
          "uccAmend_Zip_Code",
          "uccAmend_Name_of_Debtor",
        ];

        const spAddressFields = [
          "sp_Name_of_Secured_Party",
          "sp_Address_Line_1",
          "sp_Address_Line_2",
          "sp_City",
          "sp_State",
          "sp_Zip_Code",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize the uccAmend_Additional_Debtor_Details array if not already created
              if (!acc.Additional_Secured_Party_Details) {
                acc.Additional_Secured_Party_Details = [];
              }

              // Find the corresponding object in Additional_Secured_Party_Details or create a new one
              let debtorDetails1 = acc.Additional_Secured_Party_Details.find(
                (item) => item.__temp_key__ === parseInt(index)
              );

              if (!debtorDetails1) {
                debtorDetails1 = { __temp_key__: parseInt(index) };
                acc.Additional_Secured_Party_Details.push(debtorDetails1);
              }

              // Initialize the uccAmend_Additional_Debtor_Details array if not already created
              if (!acc.uccAmend_Additional_Debtor_Details) {
                acc.uccAmend_Additional_Debtor_Details = [];
              }

              // Find the corresponding object in uccAmend_Additional_Debtor_Details or create a new one
              let debtorDetails = acc.uccAmend_Additional_Debtor_Details.find(
                (item) => item.__temp_key__ === parseInt(index)
              );

              if (!debtorDetails) {
                debtorDetails = { __temp_key__: parseInt(index) };
                acc.uccAmend_Additional_Debtor_Details.push(debtorDetails);
              }
              if (baseKey.startsWith("sp")) {
                // Add address fields to debtorDetails
                if (spAddressFields.includes(baseKey)) {
                  debtorDetails1[baseKey] = data[key];
                }
              } else {
                // Add address fields to debtorDetails
                if (addressFields.includes(baseKey)) {
                  debtorDetails[baseKey] = data[key];
                }
              }
            }
          } else if (
            key.startsWith("uccAmend") &&
            !addressFields.includes(key)
          ) {
            // For non-indexed fields, add them directly to the main object
            acc[key] = data[key];
          }

          return acc;
        }, {});

        return {
          model: convertdata,
          data: formData,
        };
      }

      return { model: convertdata, data: temps };
    });
  }

  useEffect(() => {
    setIsLoading(true);
    AddressService.getAllStates()
      .then((res) => {
        if (res.data !== "") {
          setAllState(res.data);
        }
      })
      .catch((err) => {});
    ShiftsServices.getInit()
      .then((res) => {
        // console.log(res.data)

        // const decryptData = encryption.decryptData(res.data);

        // const tempdata = res.data.data.contentTypes
        const tempdata = [];
        //
        setAllInitData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });

    ShiftsServices.getInit()
      .then((res) => {
        setRawModel(res.data.data);
      })
      .catch((err) => {});
    CreditCardServices.getComponent()
      .then((res) => {
        setAllComponents(res.data.data);
      })
      .catch((err) => {});
  }, []);

  function flattenArray(array) {
    return array.map((item) => {
      const result = {};
      for (const key in item) {
        if (Array.isArray(item[key])) {
          result[key] = item[key].flat();
        }
      }
      return result;
    });
  }

  // function reorderAttributes(a, b) {
  //     const aObj = a[0];

  //     return b.map(item => {
  //         const apiID = item.apiID;
  //         if (aObj[apiID]) {
  //             const order = aObj[apiID].map(attr => attr.name);
  //             const newAttributes = {};

  //             order.forEach(key => {
  //                 if (item.attributes[key]) {
  //                     newAttributes[key] = item.attributes[key];
  //                 }
  //             });

  //             for (const key in item.attributes) {
  //                 if (!newAttributes[key]) {
  //                     newAttributes[key] = item.attributes[key];
  //                 }
  //             }

  //             item.attributes = newAttributes;
  //         }

  //         return item;
  //     });
  // }

  function reorderAttributes(a, b) {
    const temp = b.map((item) => {
      for (let i = 0; i < a.length; i++) {
        const aObj = a[i];

        const apiID = item.schema.singularName;

        if (aObj[apiID]) {
          const order = aObj[apiID].map((attr) => attr.name);
          const newAttributes = {};

          order.forEach((key) => {
            if (item.schema.attributes[key] && key) {
              newAttributes[key] = item.schema.attributes[key];
            }
          });

          // for (const key in item.schema.attributes) {
          //     if (!newAttributes[key]) {
          //         newAttributes[key] = item.schema.attributes[key];
          //     }
          // }

          item.schema.attributes = newAttributes;
        }
      }
      return item;
    });

    return temp;
  }

  useEffect(() => {
    const firstData =
      state !== null && enityName !== null
        ? typeof enityName.entityShortName === "string" &&
          typeof state.strapiDisplayName === "string"
          ? state.strapiDisplayName.toLowerCase() +
            "-" +
            enityName.entityShortName.toLowerCase()
          : ""
        : "";

    const getTargetModels = (attributes) => {
      return Object.keys(attributes).flatMap((key) => {
        if (attributes[key].type === "component" && attributes[key].component) {
          const targetModel = attributes[key].component;

          return targetModel;
        }
        return [];
      });
    };

    const fetchConfigurations = async (models) => {
      const fetchPromises = models.map(async (model) => {
        try {
          const res = await CreditCardServices.getConfiguration(
            `api::${model.uid}`
          );
          const response = res.data;
          const tempdata = model.uid;

          if (response.data !== undefined) {
            return {
              [tempdata]:
                response.data.contentType.layouts.edit !== undefined
                  ? response.data.contentType.layouts.edit
                  : {},
            };
          }
        } catch (error) {}
      });

      const results = await Promise.all(fetchPromises);

      return results.filter((val) => val !== undefined);
    };

    const processModels = async (models, processedModels = new Set()) => {
      let allModelsData = [...models];

      const targetModels = models.flatMap((model) =>
        getTargetModels(model.schema.attributes)
      );

      const uniqueTargetModels = [...new Set(targetModels)];

      if (uniqueTargetModels.length === 0) return allModelsData;

      const targetModelsData = allComponents.filter(
        (val) =>
          uniqueTargetModels.includes(val.uid) && !processedModels.has(val.uid)
      );

      allModelsData = [...allModelsData, ...targetModelsData];
      targetModelsData.forEach((val) => processedModels.add(val.uid));

      const configurations = await fetchConfigurations(targetModelsData);

      const flatArr = flattenArray(configurations);

      // Replace attributes with nested relations
      const updatedModels = await replaceNestedRelations(
        flatArr,
        allModelsData
      );

      return updatedModels;
    };

    const processAttributes = async (attributes, processedModels) => {
      const result = {};
      for (let key in attributes) {
        if (attributes[key].type === "component") {
          // const targetModel = attributes[key].target.split("::")[1].split(".")[0];
          const targetModel = attributes[key].component;

          const relatedModel = allComponents.find(
            (model) => model.uid === targetModel
          );

          if (relatedModel && !processedModels.has(relatedModel.uid)) {
            processedModels.add(relatedModel.uid);
            result[key] = await processModel(relatedModel, processedModels);
          } else {
            result[key] = {};
          }
        } else {
          result[key] = attributes[key];
        }
      }
      return result;
    };

    const processModel = async (model, processedModels) => {
      const newModel = { ...model };

      newModel.schema.attributes = await processAttributes(
        newModel.schema.attributes,
        processedModels
      );
      return newModel;
    };

    const replaceNestedRelations = async (flatArr, allModelsData) => {
      const result = [];
      for (const model of allModelsData) {
        const newModel = await processModel(model, new Set());

        result.push(newModel);
      }
      return result;
    };

    if (addOnServiceData !== "") {
      if (enityName !== "" && state !== null) {
        setLoading1(false);
      }

      const initialModels = allInitData.filter(
        (val) => val.apiID === addOnServiceData
      );

      const tempInitial = rawModel.filter(
        (val) => val.apiID === addOnServiceData
      );

      if (tempInitial.length > 0) {
        setGroupInitialData(tempInitial[0].schema.attributes);
      }

      if (initialModels.length > 0) {
        setMainModel(initialModels[0]);
        processModels(initialModels)
          .then((finalModels) => {
            const temp = finalModels.filter(
              (val) => val.apiID === addOnServiceData
            );
            if (temp.length > 0) {
              setLoading1(false);
            }
            setInputFormData(temp);
          })
          .catch((err) => {});
      }
    }
  }, [enityName, state, addOnServiceData]);

  useEffect(() => {
    const firstData =
      typeof enityName === "string" && typeof state === "string"
        ? state.toLowerCase() + "-" + enityName.toLowerCase()
        : "";
    if (addOnServiceData !== "") {
      setModelName(addOnServiceData);
    }
  }, [enityName, state, addOnServiceData]);

  const fetchOptions = async (url) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const checkAvailability = async (value) => {
    setLoading(true);
    setError("");
    try {
      // const response = await axios.get(`https://api.example.com/check-availability?value=${value}`);
      // setIsAvailable(response.data.isAvailable);
    } catch (err) {
      setError("Error checking availability");
    }
    setLoading(false);
  };
  // Current tab is set to be the first tab (0)
  useEffect(() => {
    showTab(currentTab);
  }, [currentTab]);

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error-msg font-fam-for-all ">
        {errors[name].message}
      </small>
    ) : (
      <small className="error-msg "></small>
    );
  };

  function convertName(originalString) {
    if (!originalString.includes("_")) {
      return originalString;
    }

    const parts = originalString.split("_");

    const relevantParts = parts.slice(1);

    // Capitalize the first letter of each part and join them with a space
    const formattedString = relevantParts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");

    return formattedString;
  }

  const handleFile = (e, data) => {
    const errorMessageTag = document.getElementById(`${data}--error`);
    if (errorMessageTag) {
      errorMessageTag.textContent = "";
    }
    const formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    setIsTabRefresh(true);
    MasterServices.saveFile("orderdoc", formdata)
      .then((res) => {
        setValue(data, res.data);
        setIsTabRefresh(false);
      })
      .catch((err) => {
        setIsTabRefresh(false);
        // if (toast.current !== undefined) {
        //     toast.current.show({ severity: 'warn', summary: 'Warning', detail: `File failed to upload`, life: 3000 });
        // }
        showToast("error", "File failed to upload");
      });
  };

  const renderFormField = (
    column,
    key,
    val,
    value,
    modelname,
    componentName = null
  ) => {
    const errorMessageTag = document.getElementById(`${val}--error`);

    const tempval = convertName(key);

    const options = optionsMap?.[tempval] || [];

    const COA_What_Are_We_Amending = getValues("COA_What_Are_We_Amending");

    const What_Are_We_Changing = getValues("COC_What_Are_We_Changing");

    const cc_What_Type_Document = getValues("cc_What_Type_Document");

    const ucc_UCC_Collateral_Type = getValues("ucc_UCC_Collateral_Type");

    const finCenKey = `boi_Have_a_FinCEN_ID-${val.split("-")[1] || 0}`;
    const finCenKeyCA = `boica_Have_a_FinCEN_ID-${val.split("-")[1] || 0}`;
    const finCenId = getValues(finCenKey);
    const finCenIdCA = getValues(finCenKeyCA);

    const excludedValues = [
      "COA_New_Name_To_Be_Used",
      "COA_What_Are_We_Changing",
      "Please_Tell_Us_What_You_Are_Changing",
      "COA_Please_Tell_Us_What_You_Want_To_Amend",
      "COC_Please_Include_New_County",
      "COC_Please_Include_New_Address",
      "cc_Specify_Other_Document",
      "ucc_Type_Your_Collateral_Statement",
      "ucc_Collateral_Statement",
      `boi_FinCEN_ID_Number-${val.split("-")[1] || 0}`,
      `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`,
      `boica_FinCEN_ID_Number-${val.split("-")[1] || 0}`,
      `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`,
      `boi_Date_of_Birth-${val.split("-")[1] || 0}`,
      `boica_Date_of_Birth-${val.split("-")[1] || 0}`,
      // Add more values as needed
    ];

    if (!excludedValues.includes(val)) {
      switch (column.type) {
        case "string":
          switch (column.default) {
            case "drop-down":
              if (options.length > 0) {
                const matchedItem = tempdata.find(
                  (item) => item.item === tempval
                );

                return (
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}
                          {column.required !== undefined &&
                          column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className="p-float-label">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                              column.required === true &&
                              column.regex !== undefined
                                ? {
                                    required: `${convertName(key)} Required.`,
                                    pattern: {
                                      value: new RegExp(column.regex),
                                      message: `Please Enter Valid ${convertName(
                                        key
                                      )}`,
                                    },
                                  }
                                : column.required !== undefined &&
                                  column.required === true
                                ? { required: `${convertName(key)} Required.` }
                                : {}
                            }
                            render={({ field }) => {
                              const defaultValue =
                                matchedItem !== undefined
                                  ? matchedItem.label === "countyName" &&
                                    allCounty.length > 0
                                    ? allCounty
                                    : options
                                  : options;

                              return (
                                <Dropdown
                                  id={field.name}
                                  filter
                                  name={val}
                                  value={field.value}
                                  resetFilterOnHide
                                  options={defaultValue}
                                  optionValue={
                                    matchedItem !== undefined
                                      ? matchedItem.optionValue
                                      : "name"
                                  }
                                  disabled={
                                    modelname === "Principal_Address" ||
                                    modelname === "Organizer_Information" ||
                                    modelname === "Incorporator_Information" ||
                                    (IsCheckedAgent &&
                                      modelname === "Registered_Agent") ||
                                    modelname === "Director_Information" ||
                                    modelname === "Officer_Information" ||
                                    modelname === "Filer_Information" ||
                                    modelname === "Mailing_Information" ||
                                    modelname === "Billing_Information" ||
                                    modelname === "ucc_Debtor_Details" ||
                                    modelname === "ucc_Secured_Party_Details" ||
                                    modelname ===
                                      "Beneficial_Owner_Information" ||
                                    modelname ===
                                      "Company_Applicant_Information" ||
                                    componentName === "ucc-assignee-details" ||
                                    componentName ===
                                      "ucc-amend-additional-secured-party-details" ||
                                    componentName ===
                                      "ucc-amnd-additional-debtor"
                                  }
                                  optionLabel={
                                    matchedItem !== undefined
                                      ? matchedItem.label
                                      : "name"
                                  } // Dynamically set label for the dropdown
                                  onChange={(e) => {
                                    field.onChange(e);
                                    clearErrors(key);
                                    errorMessageTag &&
                                      (errorMessageTag.textContent = "");
                                  }}
                                  maxSelectedLabels={3}
                                  style={{ flexGrow: "1" }}
                                  className="dialog-form-input-field-wizard"
                                />
                              );
                            }}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                );
              }

            // case "contact":
            //     return (
            //         <>
            //             <div className="input-fields-container">
            //                 <div className="input-fields-main">
            //                     <div className="justify-content-center dialog-form-field-space w-full">
            //                         <label htmlFor="" className="form-label font-fam-for-all">
            //                             {convertName(key)}
            //                             {column.required !== undefined && column.required === true ? (
            //                                 <span className="form-field-mandatory">*</span>
            //                             ) : ''}
            //                         </label>
            //                         <span className="p-float-label">
            //                             <Controller
            //                                 name={val}
            //                                 control={control}
            //                                 rules={
            //                                     column.required !== undefined && column.required === true
            //                                         ? {
            //                                             required: `${convertName(key)} is required.`,
            //                                         }
            //                                         : {}
            //                                 }
            //                                 render={({ field, fieldState }) => (
            //                                     <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}>
            //                                         <Dropdown
            //                                             value={selectedCountry}
            //                                             options={countries}
            //                                             onChange={(e) => {
            //                                                 setSelectedCountry(e.value);

            //                                             }}
            //                                             optionLabel="dialCode"
            //                                             filter
            //                                             optionValue="dialCode"
            //                                             valueTemplate={selectedCountryTemplate}
            //                                             itemTemplate={countryOptionTemplate}
            //                                             style={{ width: '160px', borderRadius: '6px 0px 0px 6px' }}
            //                                             className="p-dropdown font-fam-for-all"
            //                                         />

            //                                         <InputMask
            //                                             id={field.name}
            //                                             {...field}
            //                                             value={field.value}
            //                                             mask={isFocused ? "(999) 999-9999" : "(999) 999-9999"}
            //                                             onFocus={() => setIsFocused(true)}
            //                                             onBlur={() => setIsFocused(false)}
            //                                             disabled={val === "RA_State" || modelname === "Principal_Address" || modelname === "Organizer_Information" || modelname === "Incorporator_Information" || (IsCheckedAgent && modelname === "Registered_Agent" || modelname === "Director_Information" || modelname === "Officer_Information" || modelname === "Filer_Information")}
            //                                             onChange={(e) => {
            //                                                 let value = e.target.value

            //                                                 const formattedValue = `${selectedCountry} ${value}`
            //                                                 e.target.value = formattedValue;
            //                                                 field.onChange(value.toString())
            //                                                 clearErrors(key);
            //                                                 errorMessageTag && (errorMessageTag.textContent = "");
            //                                             }}
            //                                             placeholder=""
            //                                             style={{ height: '40px', borderRadius: '0px 6px 6px 0px', width: "100%" }}
            //                                             className="dialog-form-input-field-wizard"
            //                                         />
            //                                     </div>
            //                                 )}
            //                             />
            //                         </span>
            //                         <small id={`${val}--error`} className="error-msg font-fam-for-all"></small>
            //                         {getFormErrorMessage(key)}
            //                     </div>
            //                 </div>
            //             </div>
            //         </>
            //     );

            case "contact":
              return (
                <>
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}
                          {column.required !== undefined &&
                          column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className="p-float-label">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                              column.required === true
                                ? {
                                    required: `${convertName(
                                      key
                                    )} is required.`,
                                  }
                                : {}
                            }
                            render={({ field, fieldState }) => (
                              <div
                                className="p-inputgroup flex-1"
                                style={{ height: "40px", width: "100%" }}
                              >
                                {/* Dropdown for Country Code */}
                                <Dropdown
                                  value={selectedCountry}
                                  options={countries}
                                  onChange={(e) => setSelectedCountry(e.value)}
                                  optionLabel="dialCode"
                                  resetFilterOnHide
                                  filter
                                  optionValue="dialCode"
                                  valueTemplate={selectedCountryTemplate}
                                  itemTemplate={countryOptionTemplate}
                                  style={{
                                    width: "160px",
                                    borderRadius: "6px 0px 0px 6px",
                                  }}
                                  className="p-dropdown font-fam-for-all"
                                />

                                {/* Input for Phone Number */}
                                <input
                                  id={field.name}
                                  {...field}
                                  value={field.value}
                                  type="text"
                                  disabled={
                                    modelname === "Principal_Address" ||
                                    modelname === "Organizer_Information" ||
                                    modelname === "Incorporator_Information" ||
                                    (IsCheckedAgent &&
                                      modelname === "Registered_Agent") ||
                                    modelname === "Director_Information" ||
                                    modelname === "Officer_Information" ||
                                    modelname === "Filer_Information" ||
                                    modelname === "Mailing_Information" ||
                                    modelname === "Billing_Information" ||
                                    modelname === "ucc_Debtor_Details" ||
                                    modelname === "ucc_Secured_Party_Details" ||
                                    modelname ===
                                      "Beneficial_Owner_Information" ||
                                    modelname ===
                                      "Company_Applicant_Information" ||
                                    componentName === "ucc-assignee-details" ||
                                    componentName ===
                                      "ucc-amend-additional-secured-party-details" ||
                                    componentName ===
                                      "ucc-amnd-additional-debtor"
                                  }
                                  onChange={(e) => {
                                    let inputValue = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    ); // Remove non-digit characters
                                    if (inputValue.length > 10) {
                                      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
                                    }
                                    const formattedValue = inputValue.replace(
                                      /(\d{3})(\d{3})(\d{4})/,
                                      (match, p1, p2, p3) =>
                                        `(${p1}) ${p2}-${p3}`
                                    );
                                    if (inputValue.length === 10) {
                                      // Only show formatted value after 10 digits
                                      e.target.value = formattedValue;
                                      field.onChange(formattedValue); // Update state with formatted number
                                    } else {
                                      e.target.value = inputValue;
                                      field.onChange(inputValue); // Keep raw number for incomplete input
                                    }
                                    clearErrors(key);
                                    errorMessageTag &&
                                      (errorMessageTag.textContent = "");
                                  }}
                                  placeholder="(999) 999-9999"
                                  style={{
                                    height: "40px",
                                    borderRadius: "0px 6px 6px 0px",
                                    width: "100%",
                                  }}
                                  className="dialog-form-input-field-wizard p-inputtext"
                                />
                              </div>
                            )}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                </>
              );

            case "media":
              return (
                <>
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}
                          {column.required !== undefined &&
                          column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className="p-float-label">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                              column.required === true &&
                              column.regex !== undefined
                                ? {
                                    required: `${convertName(key)} Required.`,
                                    pattern: {
                                      value: new RegExp(column.regex),
                                      message: `Please Enter Valid ${convertName(
                                        key
                                      )}`,
                                    },
                                  }
                                : column.required !== undefined &&
                                  column.required === true
                                ? { required: `${convertName(key)} Required.` }
                                : {}
                            }
                            render={({ field }) => (
                              // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                              <input
                                name={val}
                                type="file"
                                disabled={
                                  modelname === "Principal_Address" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  (IsCheckedAgent &&
                                    modelname === "Registered_Agent") ||
                                  modelname === "Director_Information" ||
                                  modelname === "Officer_Information" ||
                                  modelname === "Filer_Information" ||
                                  modelname === "Mailing_Information" ||
                                  modelname === "Billing_Information" ||
                                  modelname === "ucc_Debtor_Details" ||
                                  modelname === "ucc_Secured_Party_Details" ||
                                  modelname ===
                                    "Beneficial_Owner_Information" ||
                                  modelname === "Company_Applicant_Information"
                                }
                                onChange={(e) => handleFile(e, val)}
                              />
                            )}
                          />
                        </span>
                        {getValues(val) !== undefined &&
                          typeof getValues(val) === "string" && (
                            <p className="font-web-color font-fam-for-all">
                              {getValues(val).split("/").pop()}
                            </p>
                          )}
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                </>
              );

            case "date":
              return (
                <div className="input-fields-container">
                  <div className="input-fields-main">
                    <div className="justify-content-center dialog-form-field-space w-full">
                      <label htmlFor="" className="form-label font-fam-for-all">
                        {convertName(key)}
                        {column.required ? (
                          <span className="form-field-mandatory">*</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name={val}
                          control={control}
                          rules={
                            column.required
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                          }
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                className="strapi-date-picker"
                                style={{ width: "322px" }}
                                value={field.value ? dayjs(field.value) : null}
                                disabled={
                                  modelname === "Principal_Address" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  (IsCheckedAgent &&
                                    modelname === "Registered_Agent") ||
                                  modelname === "Director_Information" ||
                                  modelname === "Officer_Information" ||
                                  modelname === "Filer_Information" ||
                                  modelname === "Mailing_Information" ||
                                  modelname === "Billing_Information" ||
                                  modelname === "ucc_Debtor_Details" ||
                                  modelname === "ucc_Secured_Party_Details" ||
                                  modelname ===
                                    "Beneficial_Owner_Information" ||
                                  modelname === "Company_Applicant_Information"
                                }
                                onChange={(newValue) => {
                                  if (newValue && newValue.isValid()) {
                                    const formattedDate =
                                      newValue.format("YYYYMMDD");
                                    field.onChange(formattedDate);
                                  } else {
                                    field.onChange(null);
                                  }
                                  errorMessageTag &&
                                    (errorMessageTag.textContent = "");
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                disableFuture
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </span>
                      <small
                        id={`${val}--error`}
                        className="error-msg font-fam-for-all"
                      ></small>
                      {getFormErrorMessage(key)}
                    </div>
                  </div>
                </div>
              );

            default:
              return (
                <>
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className=" justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}{" "}
                          {column.required !== undefined &&
                          column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className="p-float-label ">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                              column.required === true &&
                              column.regex !== undefined
                                ? {
                                    required: `${convertName(key)} Required.`,
                                    pattern: {
                                      value: new RegExp(column.regex),
                                      message: `Please Enter Valid ${convertName(
                                        key
                                      )}`,
                                    },
                                  }
                                : column.required !== undefined &&
                                  column.required === true
                                ? { required: `${convertName(key)} Required.` }
                                : {}
                            }
                            render={({ field, fieldState }) => (
                              <InputText
                                id={field.name}
                                {...field}
                                autoComplete={false}
                                onChange={(e) => {
                                  field.onChange(e);
                                  clearErrors(key);
                                  errorMessageTag &&
                                    (errorMessageTag.textContent = "");
                                }}
                                disabled={
                                  val === "X_Name_of_Entity" ||
                                  modelname === "Principal_Address" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  (IsCheckedAgent &&
                                    modelname === "Registered_Agent") ||
                                  modelname === "Director_Information" ||
                                  modelname === "Officer_Information" ||
                                  modelname === "Filer_Information" ||
                                  modelname === "Mailing_Information" ||
                                  modelname === "Billing_Information" ||
                                  modelname === "ucc_Debtor_Details" ||
                                  modelname === "ucc_Secured_Party_Details" ||
                                  modelname ===
                                    "Beneficial_Owner_Information" ||
                                  modelname ===
                                    "Company_Applicant_Information" ||
                                  componentName === "ucc-assignee-details" ||
                                  componentName ===
                                    "ucc-amend-additional-secured-party-details" ||
                                  componentName === "ucc-amnd-additional-debtor"
                                }
                                style={{
                                  flexGrow: "1",
                                  paddingLeft: "10px",
                                  paddingBottom: "5px",
                                }}
                                className="dialog-form-input-field-wizard"
                                maxLength={50}
                              />
                            )}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all "
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                </>
              );
          }
        case "text":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label ">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                          column.required === true &&
                          column.regex !== undefined
                            ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: new RegExp(column.regex),
                                  message: `Please Enter Valid ${convertName(
                                    key
                                  )}`,
                                },
                              }
                            : column.required !== undefined &&
                              column.required === true
                            ? { required: `${convertName(key)} Required.` }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <InputTextarea
                            id={field.name}
                            {...field}
                            autoComplete={false}
                            rows={4}
                            cols={100}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "Director_Information" ||
                              modelname === "Officer_Information" ||
                              modelname === "Filer_Information" ||
                              modelname === "Mailing_Information" ||
                              modelname === "Billing_Information" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details" ||
                              modelname === "Beneficial_Owner_Information" ||
                              modelname === "Company_Applicant_Information"
                            }
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                            }}
                            className="dialog-form-input-area-field-wizard"
                            maxLength={50}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        // case "biginteger":
        //     return<>
        //     <input type="number" />
        //     </>
        case "biginteger":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className="justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label ">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                          column.required === true
                            ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: /^\d{1,10}$/, // Allow only up to 5 digits
                                  message:
                                    "Please Enter Valid No Of Positions (1-5 digits)",
                                },
                              }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <input
                            type="number"
                            id={field.name}
                            {...field}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "Director_Information" ||
                              modelname === "Officer_Information" ||
                              modelname === "Filer_Information" ||
                              modelname === "Mailing_Information" ||
                              modelname === "Billing_Information" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details" ||
                              modelname === "Beneficial_Owner_Information" ||
                              modelname === "Company_Applicant_Information"
                            }
                            onInput={(e) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10); // Allow only 5 digits
                              }
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                              borderRadius: "6px",
                            }}
                            className="dialog-form-input-field-wizard p-inputtext"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        case "integer":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full ">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label ">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                          column.required === true
                            ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: /^\d+$/,
                                  message: "Please Enter Valid No Of Positions",
                                },
                              }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <input
                            type="number"
                            id={field.name}
                            {...field}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "Director_Information" ||
                              modelname === "Officer_Information" ||
                              modelname === "Filer_Information" ||
                              modelname === "Mailing_Information" ||
                              modelname === "Billing_Information" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details" ||
                              modelname === "Beneficial_Owner_Information" ||
                              modelname === "Company_Applicant_Information"
                            }
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                              borderRadius: "6px",
                            }}
                            className="dialog-form-input-field-wizard p-inputtext"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        case "decimal":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label ">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                          column.required === true
                            ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: /^\d+$/,
                                  message: "Please Enter Valid No Of Positions",
                                },
                              }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <input
                            type="float"
                            id={field.name}
                            {...field}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "Director_Information" ||
                              modelname === "Officer_Information" ||
                              modelname === "Filer_Information" ||
                              modelname === "Mailing_Information" ||
                              modelname === "Billing_Information" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details" ||
                              modelname === "Beneficial_Owner_Information" ||
                              modelname === "Company_Applicant_Information"
                            }
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                              borderRadius: "6px",
                            }}
                            className="dialog-form-input-field-wizard p-inputtext"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );

        case "email":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label ">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                          column.required === true
                            ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                  message: "Please Enter Valid Email",
                                },
                              }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <input
                            type="email"
                            id={field.name}
                            {...field}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "Director_Information" ||
                              modelname === "Officer_Information" ||
                              modelname === "Filer_Information" ||
                              modelname === "Mailing_Information" ||
                              modelname === "Billing_Information" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details" ||
                              modelname === "Beneficial_Owner_Information" ||
                              modelname === "Company_Applicant_Information"
                            }
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                              borderRadius: "6px",
                            }}
                            className="dialog-form-input-field-wizard p-inputtext"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        case "password":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label ">
                      <Controller
                        control={control}
                        name={val}
                        rules={
                          column.required !== undefined &&
                          column.required === true
                            ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value:
                                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                  message:
                                    "Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.",
                                },
                              }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <Password
                            id={field.name}
                            {...field}
                            toggleMask
                            inputRef={field.ref}
                            autoComplete={false}
                            className="dialog-form-input-field-wizard"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );

        case "richtext":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label ">
                      <Controller
                        control={control}
                        name={val}
                        rules={
                          column.required !== undefined &&
                          column.required === true
                            ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value:
                                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                  message:
                                    "Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.",
                                },
                              }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          // <Password
                          //     id={field.name} {...field}
                          //     toggleMask
                          //     inputRef={field.ref}
                          //     autoComplete={false}
                          //     className='dialog-form-input-field-wizard'
                          // />
                          <Editor
                            id={field.name}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            inputRef={field.ref}
                            style={{ height: "320px" }}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        case "boolean":
          return (
            <div className="input-fields-container">
              <div className="input-fields-main">
                <div className="justify-content-center dialog-form-field-space w-full">
                  <label htmlFor="" className="form-label font-fam-for-all">
                    {convertName(key)}
                    {column.required ? (
                      <span className="form-field-mandatory">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name={val}
                      control={control}
                      defaultValue={false}
                      rules={
                        column.required
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                      }
                      render={({ field }) => (
                        <Switch
                          checked={field.value ?? false}
                          disabled={
                            modelname === "Principal_Address" ||
                            modelname === "Organizer_Information" ||
                            modelname === "Incorporator_Information" ||
                            (IsCheckedAgent &&
                              modelname === "Registered_Agent") ||
                            modelname === "Director_Information" ||
                            modelname === "Officer_Information" ||
                            modelname === "Filer_Information" ||
                            modelname === "Mailing_Information" ||
                            modelname === "Billing_Information" ||
                            modelname === "ucc_Debtor_Details" ||
                            modelname === "ucc_Secured_Party_Details" ||
                            modelname === "Beneficial_Owner_Information" ||
                            modelname === "Company_Applicant_Information"
                          }
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            errorMessageTag &&
                              (errorMessageTag.textContent = "");
                          }}
                          name={val}
                          color="primary"
                        />
                      )}
                    />
                  </span>
                  <small
                    id={`${val}--error`}
                    className="error-msg font-fam-for-all"
                  ></small>
                  {getFormErrorMessage(key)}
                </div>
              </div>
            </div>
          );

        case "date":
          return (
            <div className="input-fields-container">
              <div className="input-fields-main">
                <div className="justify-content-center dialog-form-field-space w-full">
                  <label htmlFor="" className="form-label font-fam-for-all">
                    {convertName(key)}
                    {column.required ? (
                      <span className="form-field-mandatory">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name={val}
                      control={control}
                      rules={
                        column.required
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                      }
                      render={({ field }) => (
                        <DatePicker
                          label={convertName(key)}
                          value={field.value || null}
                          disabled={
                            modelname === "Principal_Address" ||
                            modelname === "Organizer_Information" ||
                            modelname === "Incorporator_Information" ||
                            (IsCheckedAgent &&
                              modelname === "Registered_Agent") ||
                            modelname === "Director_Information" ||
                            modelname === "Officer_Information" ||
                            modelname === "Filer_Information" ||
                            modelname === "Mailing_Information" ||
                            modelname === "Billing_Information" ||
                            modelname === "ucc_Debtor_Details" ||
                            modelname === "ucc_Secured_Party_Details" ||
                            modelname === "Beneficial_Owner_Information" ||
                            modelname === "Company_Applicant_Information"
                          }
                          onChange={(newValue) => {
                            field.onChange(newValue);
                            errorMessageTag &&
                              (errorMessageTag.textContent = "");
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          disableFuture={column.disableFuture || false} // Example of a possible column config
                        />
                      )}
                    />
                  </span>
                  <small
                    id={`${val}--error`}
                    className="error-msg font-fam-for-all"
                  ></small>
                  {getFormErrorMessage(key)}
                </div>
              </div>
            </div>
          );
        case "enumeration":
          if (options.length > 0) {
            const matchedItem = tempdata.find((item) => item.item === tempval);

            return (
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className="justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                          column.required === true &&
                          column.regex !== undefined
                            ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: new RegExp(column.regex),
                                  message: `Please Enter Valid ${convertName(
                                    key
                                  )}`,
                                },
                              }
                            : column.required !== undefined &&
                              column.required === true
                            ? { required: `${convertName(key)} Required.` }
                            : {}
                        }
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            filter
                            name={val}
                            value={field.value}
                            options={options}
                            resetFilterOnHide
                            optionValue={
                              matchedItem !== undefined
                                ? matchedItem.label
                                : "name"
                            }
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "Director_Information" ||
                              modelname === "Officer_Information" ||
                              modelname === "Filer_Information" ||
                              modelname === "Mailing_Information" ||
                              modelname === "Billing_Information" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details" ||
                              modelname === "Beneficial_Owner_Information" ||
                              modelname === "Company_Applicant_Information"
                            }
                            optionLabel={
                              matchedItem !== undefined
                                ? matchedItem.label
                                : "name"
                            } // Dynamically set label for the dropdown
                            onChange={(e) => {
                              field.onChange(e.value);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            maxSelectedLabels={3}
                            style={{ flexGrow: "1" }}
                            className="dialog-form-input-field-wizard"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all"
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <>
                <div className="input-fields-container">
                  <div className="input-fields-main">
                    <div className=" justify-content-center dialog-form-field-space w-full">
                      <label htmlFor="" className="form-label font-fam-for-all">
                        {convertName(key)}{" "}
                        {column.required !== undefined &&
                        column.required === true ? (
                          <span className="form-field-mandatory">*</span>
                        ) : (
                          ""
                        )}
                      </label>

                      <span className="p-float-label ">
                        <Controller
                          name={val}
                          control={control}
                          // onChange={(e) => errorMessageTag.textContent = ''}

                          rules={
                            column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                              ? {
                                  required: `${convertName(key)} Required.`,
                                  pattern: {
                                    value: new RegExp(column.regex),
                                    message: `Please Enter Valid ${convertName(
                                      key
                                    )}`,
                                  },
                                }
                              : column.required !== undefined &&
                                column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                          }
                          render={({ field }) => (
                            <Dropdown
                              resetFilterOnHide
                              id={field.name}
                              filter
                              name="jd"
                              disabled={
                                modelname === "Principal_Address" ||
                                modelname === "Organizer_Information" ||
                                modelname === "Incorporator_Information" ||
                                (IsCheckedAgent &&
                                  modelname === "Registered_Agent") ||
                                modelname === "Director_Information" ||
                                modelname === "Officer_Information" ||
                                modelname === "Filer_Information" ||
                                modelname === "Mailing_Information" ||
                                modelname === "Billing_Information" ||
                                modelname === "ucc_Debtor_Details" ||
                                modelname === "ucc_Secured_Party_Details" ||
                                modelname === "Beneficial_Owner_Information" ||
                                modelname === "Company_Applicant_Information"
                              }
                              value={field.value}
                              options={
                                column.enum !== undefined ? column.enum : []
                              }
                              onChange={(e) => {
                                field.onChange(e.value);
                                errorMessageTag &&
                                  (errorMessageTag.textContent = "");
                              }}
                              maxSelectedLabels={3}
                              style={{ flexGrow: "1" }}
                              className="dialog-form-input-field-wizard"
                            />
                          )}
                        />
                      </span>
                      <small
                        id={`${val}--error`}
                        className="error-msg font-fam-for-all "
                      ></small>
                      {getFormErrorMessage(key)}
                    </div>
                  </div>
                </div>
              </>
            );
          }

        default:
          return null;
      }
    } else if (
      What_Are_We_Changing !== undefined &&
      val !== "COA_New_Name_To_Be_Used" &&
      val !== "COA_What_Are_We_Changing" &&
      val !== "Please_Tell_Us_What_You_Are_Changing"
    ) {
      switch (What_Are_We_Changing) {
        case "County":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("COC_Please_Include_New_County")}{" "}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>

                    <span className="p-float-label ">
                      <Controller
                        name={"COC_Please_Include_New_County"}
                        control={control}
                        // onChange={(e) => errorMessageTag.textContent = ''}

                        rules={
                          column.required !== undefined &&
                          column.required === true &&
                          column.regex !== undefined
                            ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: new RegExp(column.regex),
                                  message: `Please Enter Valid ${convertName(
                                    key
                                  )}`,
                                },
                              }
                            : column.required !== undefined &&
                              column.required === true
                            ? { required: `${convertName(key)} Required.` }
                            : {}
                        }
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            resetFilterOnHide
                            filter
                            name="jd"
                            value={field.value}
                            optionLabel="countyName"
                            optionValue="countyName"
                            options={allCounty}
                            onChange={(e) => {
                              field.onChange(e.value);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            maxSelectedLabels={3}
                            style={{ flexGrow: "1" }}
                            className="dialog-form-input-field-wizard"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );

        case "Other":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("Please_Tell_Us_What_You_Are_Changing")}{" "}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>

                    <span className="p-float-label ">
                      <Controller
                        name={"Please_Tell_Us_What_You_Are_Changing"}
                        control={control}
                        // onChange={(e) => errorMessageTag.textContent = ''}

                        rules={
                          column.required !== undefined &&
                          column.required === true &&
                          column.regex !== undefined
                            ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: new RegExp(column.regex),
                                  message: `Please Enter Valid ${convertName(
                                    key
                                  )}`,
                                },
                              }
                            : column.required !== undefined &&
                              column.required === true
                            ? { required: `${convertName(key)} Required.` }
                            : {}
                        }
                        render={({ field }) => (
                          <InputText
                            id={field.name}
                            name="jd"
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            style={{ flexGrow: "1" }}
                            className="dialog-form-input-field-wizard"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );

        default:
          break;
      }
    } else if (
      COA_What_Are_We_Amending !== undefined &&
      val !== "COA_New_Name_To_Be_Used" &&
      val !== "COA_What_Are_We_Changing"
    ) {
      switch (COA_What_Are_We_Amending) {
        case "Name":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("COA_New_Name_To_Be_Used")}{" "}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label ">
                      <Controller
                        name="COA_New_Name_To_Be_Used"
                        control={control}
                        rules={
                          column.required !== undefined &&
                          column.required === true &&
                          column.regex !== undefined
                            ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: new RegExp(column.regex),
                                  message: `Please Enter Valid ${convertName(
                                    key
                                  )}`,
                                },
                              }
                            : column.required !== undefined &&
                              column.required === true
                            ? { required: `${convertName(key)} Required.` }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            autoComplete={false}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            disabled={
                              modelname === "Principle_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              modelname === "Registered_Agent" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details"
                            }
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                            }}
                            className="dialog-form-input-field-wizard"
                            maxLength={50}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
              {setIsNameRendered(true)}
            </div>
          );

        case "Provisions":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("COA_What_Are_We_Changing")}{" "}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label ">
                      <Controller
                        name="COA_What_Are_We_Changing"
                        control={control}
                        rules={
                          column.required !== undefined &&
                          column.required === true &&
                          column.regex !== undefined
                            ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: new RegExp(column.regex),
                                  message: `Please Enter Valid ${convertName(
                                    key
                                  )}`,
                                },
                              }
                            : column.required !== undefined &&
                              column.required === true
                            ? { required: `${convertName(key)} Required.` }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            autoComplete={false}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            disabled={
                              modelname === "Principle_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              modelname === "Registered_Agent" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details"
                            }
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                            }}
                            className="dialog-form-input-field-wizard"
                            maxLength={50}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </div>
          );

        case "Other":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("COA_Please_Tell_Us_What_You_Want_To_Amend")}{" "}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label ">
                      <Controller
                        name="COA_Please_Tell_Us_What_You_Want_To_Amend"
                        control={control}
                        rules={
                          column.required !== undefined &&
                          column.required === true &&
                          column.regex !== undefined
                            ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: new RegExp(column.regex),
                                  message: `Please Enter Valid ${convertName(
                                    key
                                  )}`,
                                },
                              }
                            : column.required !== undefined &&
                              column.required === true
                            ? { required: `${convertName(key)} Required.` }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            autoComplete={false}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            disabled={
                              modelname === "Principle_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              modelname === "Registered_Agent" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details"
                            }
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                            }}
                            className="dialog-form-input-field-wizard"
                            maxLength={50}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </div>
          );
        default:
          break;
      }
    } else if (
      cc_What_Type_Document !== undefined &&
      cc_What_Type_Document === "Other - Please specify"
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className=" justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("cc_Specify_Other_Document")}{" "}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className="p-float-label ">
                  <Controller
                    name="cc_Specify_Other_Document"
                    control={control}
                    rules={
                      column.required !== undefined &&
                      column.required === true &&
                      column.regex !== undefined
                        ? {
                            required: `${convertName(
                              "cc_Specify_Other_Document"
                            )} Required.`,
                            pattern: {
                              value: new RegExp(column.regex),
                              message: `Please Enter Valid ${convertName(key)}`,
                            },
                          }
                        : column.required !== undefined &&
                          column.required === true
                        ? { required: `${convertName(key)} Required.` }
                        : {}
                    }
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoComplete={false}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors(key);
                          errorMessageTag && (errorMessageTag.textContent = "");
                        }}
                        disabled={
                          modelname === "Principle_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          modelname === "Registered_Agent" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details"
                        }
                        style={{
                          flexGrow: "1",
                          paddingLeft: "10px",
                          paddingBottom: "5px",
                        }}
                        className="dialog-form-input-field-wizard"
                        maxLength={50}
                      />
                    )}
                  />
                </span>
                <small
                  id={`${"cc_Specify_Other_Document"}--error`}
                  className="error-msg font-fam-for-all "
                ></small>
                {getFormErrorMessage("cc_Specify_Other_Document")}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      ucc_UCC_Collateral_Type !== undefined &&
      val !== "ucc_Collateral_Statement"
    ) {
      switch (ucc_UCC_Collateral_Type) {
        case "Upload Their Own Collateral Statement":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className="justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("ucc_Collateral_Statement")}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label">
                      <Controller
                        name={"ucc_Collateral_Statement"}
                        control={control}
                        rules={
                          column.required !== undefined &&
                          column.required === true &&
                          column.regex !== undefined
                            ? {
                                required: `${convertName(
                                  "ucc_Collateral_Statement"
                                )} Required.`,
                                pattern: {
                                  value: new RegExp(column.regex),
                                  message: `Please Enter Valid ${convertName(
                                    "ucc_Collateral_Statement"
                                  )}`,
                                },
                              }
                            : column.required !== undefined &&
                              column.required === true
                            ? {
                                required: `${convertName(
                                  "ucc_Collateral_Statement"
                                )} Required.`,
                              }
                            : {}
                        }
                        render={({ field }) => (
                          // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                          <input
                            type="file"
                            onChange={(e) => handleFile(e, val)}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${"ucc_Collateral_Statement"}--error`}
                      className="error-msg font-fam-for-all"
                    ></small>
                    {getFormErrorMessage("ucc_Collateral_Statement")}
                  </div>
                </div>
              </div>
            </div>
          );

        case "Type Their Own Collateral Statement":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("ucc_Type_Your_Collateral_Statement")}{" "}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label ">
                      <Controller
                        control={control}
                        name={"ucc_Type_Your_Collateral_Statement"}
                        rules={
                          column.required !== undefined &&
                          column.required === true
                            ? {
                                required: `${convertName(
                                  "ucc_Type_Your_Collateral_Statement"
                                )} Required.`,
                                pattern: {
                                  value:
                                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                  message:
                                    "Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.",
                                },
                              }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          // <Password
                          //     id={field.name} {...field}
                          //     toggleMask
                          //     inputRef={field.ref}
                          //     autoComplete={false}
                          //     className='dialog-form-input-field-wizard'
                          // />
                          <Editor
                            id={field.name}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            inputRef={field.ref}
                            style={{ height: "320px" }}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${"ucc_Type_Your_Collateral_Statement"}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage("ucc_Type_Your_Collateral_Statement")}
                  </div>
                </div>
              </div>
            </div>
          );

        default:
          break;
      }
    } else if (
      finCenId &&
      val === `boi_FinCEN_ID_Number-${val.split("-")[1] || 0}`
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className=" justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("boi_FinCEN_ID_Number")}{" "}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className="p-float-label ">
                  <Controller
                    name={`boi_FinCEN_ID_Number-${val.split("-")[1] || 0}`}
                    control={control}
                    rules={
                      column.required !== undefined &&
                      column.required === true &&
                      column.regex !== undefined
                        ? {
                            required: `${convertName(
                              "boi_FinCEN_ID_Number"
                            )} Required.`,
                            pattern: {
                              value: new RegExp(column.regex),
                              message: `Please Enter Valid ${convertName(key)}`,
                            },
                          }
                        : column.required !== undefined &&
                          column.required === true
                        ? { required: `${convertName(key)} Required.` }
                        : {}
                    }
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoComplete={false}
                        // onChange={(e) => errorMessageTag.textContent = ''}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information"
                        }
                        style={{
                          flexGrow: "1",
                          paddingLeft: "10px",
                          paddingBottom: "5px",
                        }}
                        className="dialog-form-input-field-wizard"
                        maxLength={50}
                      />
                    )}
                  />
                </span>
                <small
                  id={`${"boi_FinCEN_ID_Number"}--error`}
                  className="error-msg font-fam-for-all "
                ></small>
                {getFormErrorMessage("boi_FinCEN_ID_Number")}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      finCenId &&
      val === `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className="justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("boi_FinCEN_ID_Document")}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className="p-float-label">
                  <Controller
                    name={`boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`}
                    control={control}
                    rules={
                      column.required !== undefined &&
                      column.required === true &&
                      column.regex !== undefined
                        ? {
                            required: `${convertName(
                              "boi_FinCEN_ID_Document"
                            )} Required.`,
                            pattern: {
                              value: new RegExp(column.regex),
                              message: `Please Enter Valid ${convertName(
                                "boi_FinCEN_ID_Document"
                              )}`,
                            },
                          }
                        : column.required !== undefined &&
                          column.required === true
                        ? {
                            required: `${convertName(
                              "boi_FinCEN_ID_Document"
                            )} Required.`,
                          }
                        : {}
                    }
                    render={({ field }) => (
                      // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                      <input
                        name={`boi_FinCEN_ID_Document-${
                          val.split("-")[1] || 0
                        }`}
                        type="file"
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information"
                        }
                        onChange={(e) =>
                          handleFile(
                            e,
                            `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                          )
                        }
                      />
                    )}
                  />
                </span>
                {getValues(
                  `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                ) !== undefined &&
                  typeof getValues(
                    `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                  ) === "string" && (
                    <p className="font-web-color font-fam-for-all">
                      {getValues(
                        `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                      )
                        .split("/")
                        .pop()}
                    </p>
                  )}
                <small
                  id={`${"boi_FinCEN_ID_Document"}--error`}
                  className="error-msg font-fam-for-all"
                ></small>
                {getFormErrorMessage(key)}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      finCenIdCA &&
      val === `boica_FinCEN_ID_Number-${val.split("-")[1] || 0}`
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className=" justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("boica_FinCEN_ID_Number")}{" "}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className="p-float-label ">
                  <Controller
                    name={`boica_FinCEN_ID_Number-${val.split("-")[1] || 0}`}
                    control={control}
                    rules={
                      column.required !== undefined &&
                      column.required === true &&
                      column.regex !== undefined
                        ? {
                            required: `${convertName(
                              "boica_FinCEN_ID_Number"
                            )} Required.`,
                            pattern: {
                              value: new RegExp(column.regex),
                              message: `Please Enter Valid ${convertName(key)}`,
                            },
                          }
                        : column.required !== undefined &&
                          column.required === true
                        ? { required: `${convertName(key)} Required.` }
                        : {}
                    }
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoComplete={false}
                        // onChange={(e) => errorMessageTag.textContent = ''}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information"
                        }
                        style={{
                          flexGrow: "1",
                          paddingLeft: "10px",
                          paddingBottom: "5px",
                        }}
                        className="dialog-form-input-field-wizard"
                        maxLength={50}
                      />
                    )}
                  />
                </span>
                <small
                  id={`${`boica_FinCEN_ID_Number-${
                    val.split("-")[1] || 0
                  }`}--error`}
                  className="error-msg font-fam-for-all "
                ></small>
                {getFormErrorMessage("boica_FinCEN_ID_Number")}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      finCenIdCA &&
      val === `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className="justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("boica_FinCEN_ID_Document")}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className="p-float-label">
                  <Controller
                    name={`boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`}
                    control={control}
                    rules={
                      column.required !== undefined &&
                      column.required === true &&
                      column.regex !== undefined
                        ? {
                            required: `${convertName(
                              "boica_FinCEN_ID_Document"
                            )} Required.`,
                            pattern: {
                              value: new RegExp(column.regex),
                              message: `Please Enter Valid ${convertName(
                                "boica_FinCEN_ID_Document"
                              )}`,
                            },
                          }
                        : column.required !== undefined &&
                          column.required === true
                        ? {
                            required: `${convertName(
                              "boica_FinCEN_ID_Document"
                            )} Required.`,
                          }
                        : {}
                    }
                    render={({ field }) => (
                      // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                      <input
                        name={`boica_FinCEN_ID_Document-${
                          val.split("-")[1] || 0
                        }`}
                        type="file"
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information"
                        }
                        onChange={(e) =>
                          handleFile(
                            e,
                            `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                          )
                        }
                      />
                    )}
                  />
                </span>
                {getValues(
                  `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                ) !== undefined &&
                  typeof getValues(
                    `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                  ) === "string" && (
                    <p className="font-web-color font-fam-for-all">
                      {getValues(
                        `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                      )
                        .split("/")
                        .pop()}
                    </p>
                  )}
                <small
                  id={`${`boica_FinCEN_ID_Document-${
                    val.split("-")[1] || 0
                  }`}--error`}
                  className="error-msg font-fam-for-all"
                ></small>
                {getFormErrorMessage(key)}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      finCenId === false &&
      val === `boi_Date_of_Birth-${val.split("-")[1] || 0}`
    ) {
      return (
        <div className="input-fields-container">
          <div className="input-fields-main">
            <div className="justify-content-center dialog-form-field-space w-full">
              <label htmlFor="" className="form-label font-fam-for-all">
                {convertName(key)}
                {column.required ? (
                  <span className="form-field-mandatory">*</span>
                ) : (
                  ""
                )}
              </label>
              <span className="p-float-label">
                <Controller
                  name={val}
                  control={control}
                  rules={
                    column.required
                      ? { required: `${convertName(key)} Required.` }
                      : {}
                  }
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="strapi-date-picker"
                        style={{ width: "322px" }}
                        value={field.value ? dayjs(field.value) : null}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information"
                        }
                        onChange={(newValue) => {
                          if (newValue && newValue.isValid()) {
                            const formattedDate = newValue.format("YYYYMMDD");
                            field.onChange(formattedDate);
                          } else {
                            field.onChange(null);
                          }
                          errorMessageTag && (errorMessageTag.textContent = "");
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        disableFuture
                      />
                    </LocalizationProvider>
                  )}
                />
              </span>
              <small
                id={`${val}--error`}
                className="error-msg font-fam-for-all"
              ></small>
              {getFormErrorMessage(key)}
            </div>
          </div>
        </div>
      );
    } else if (
      finCenIdCA === false &&
      val === `boica_Date_of_Birth-${val.split("-")[1] || 0}`
    ) {
      return (
        <div className="input-fields-container">
          <div className="input-fields-main">
            <div className="justify-content-center dialog-form-field-space w-full">
              <label htmlFor="" className="form-label font-fam-for-all">
                {convertName(key)}
                {column.required ? (
                  <span className="form-field-mandatory">*</span>
                ) : (
                  ""
                )}
              </label>
              <span className="p-float-label">
                <Controller
                  name={val}
                  control={control}
                  rules={
                    column.required
                      ? { required: `${convertName(key)} Required.` }
                      : {}
                  }
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="strapi-date-picker"
                        style={{ width: "322px" }}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information"
                        }
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(newValue) => {
                          if (newValue && newValue.isValid()) {
                            const formattedDate = newValue.format("YYYYMMDD");
                            field.onChange(formattedDate);
                          } else {
                            field.onChange(null);
                          }
                          errorMessageTag && (errorMessageTag.textContent = "");
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        disableFuture
                      />
                    </LocalizationProvider>
                  )}
                />
              </span>
              <small
                id={`${val}--error`}
                className="error-msg font-fam-for-all"
              ></small>
              {getFormErrorMessage(key)}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  let currentTabs = 0;
  let currentSteps = 1;
  function showTab(n) {
    // This function will display the specified tab of the form...
    var x = document.getElementsByClassName("tab");

    if (x.length > 0) {
      if (x[n] !== undefined) {
        x[n].style.display = "block";
      }

      //... and fix the Previous/Next buttons:
      if (n == 0) {
        document.getElementById("prevBtn").style.display = "none";
      } else {
        document.getElementById("prevBtn").style.display = "flex";
      }
      if (n == x.length - 1 && inputFormData.length > 0) {
        // document.getElementById("nextBtn").innerHTML = "Submit";
        document.getElementById("nextBtn").style.display = "none";
        document.getElementById("submitBtn").style.display = "flex";
      } else {
        document.getElementById("nextBtn").innerHTML = "Save & Next";
        document.getElementById("nextBtn").style.display = "flex";
        document.getElementById("submitBtn").style.display = "none";
      }
      //... and run a function that will display the correct step indicator:
      fixStepIndicator(n);
    }
  }

  function deepCompareObjects(obj1, obj2) {
    if (typeof obj1 !== typeof obj2) return false;

    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      if (obj1.length !== obj2.length) return false;
      for (let i = 0; i < obj1.length; i++) {
        if (!deepCompareObjects(obj1[i], obj2[i])) return false;
      }
      return true;
    }

    if (typeof obj1 === "object" && obj1 !== null && obj2 !== null) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;
      for (let key of keys1) {
        if (!deepCompareObjects(obj1[key], obj2[key])) return false;
      }
      return true;
    }

    return obj1 === obj2;
  }

  const nextPrev = (n) => {
    // This function will figure out which tab to display
    setWizardScroll(!wizardScroll);
    setStateErr("");
    setEntityErr("");
    setCountyErr("");

    if (!state && !enityName) {
      setStateErr("State required");
      setEntityErr("Entity type required");
      return;
    }

    if (!state) {
      setStateErr("State required");
      return;
    }
    if (!enityName) {
      setEntityErr("Entity type required");
      return;
    }
    if (state && stateWithCounty.includes(state.stateFullDesc)) {
      if (!county) {
        setCountyErr("County required");
        return; // Exit the function if county is required and not provided for New York
      }
    }
    if (selectedCheckBoxData.length > 0) {
      if (n === 1 || n === -1) {
        var x = document.getElementsByClassName("tab");

        // Check if the user is moving to the next page
        if (n === 1) {
          if (currentStep < inputFormData.length + 5) {
            setCurrentStep(currentStep + 1);
          }
        }

        // Check if the user is moving to the previous page
        if (n === -1) {
          if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
          }
        }

        // Validate the form
        if (x.length > 0) {
          if (n === 1 && !validateForm()) return false; // Stop if form validation fails

          // Hide current tab
          if (x[currentTab] !== undefined) {
            x[currentTab].style.display = "none";
          }

          // Move to the new tab
          const newTab = currentTab + n;
          setCurrentTab(newTab);
          showTab(newTab);

          if (mainModel && n === 1) {
            // Key personnel and other processing
            const keyPersonalArr = [];
            if (incorporator?.id) keyPersonalArr.push(incorporator.id);
            if (officer?.id) keyPersonalArr.push(officer.id);
            if (director?.id) keyPersonalArr.push(director.id);
            if (organizerData?.id) keyPersonalArr.push(organizerData.id);
            if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
            if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);

            const keyPersonalStrapiArr = [];
            if (incorporator?.id)
              keyPersonalStrapiArr.push({
                data: incorporator,
                type: "INCORPORATOR",
              });
            if (officer?.id)
              keyPersonalStrapiArr.push({ data: officer, type: "OFFICER" });
            if (director?.id)
              keyPersonalStrapiArr.push({ data: director, type: "DIRECTOR" });
            if (organizerData?.id)
              keyPersonalStrapiArr.push({
                data: organizerData,
                type: "ORGANIZER",
              });
            if (bilingAddress?.id)
              keyPersonalStrapiArr.push({
                data: bilingAddress,
                type: "BILLING",
              });
            if (shippingAddress?.id)
              keyPersonalStrapiArr.push({
                data: shippingAddress,
                type: "SHIPPING",
              });

            // Date processing
            let d = new Date();
            const futureDate = new Date(d);
            futureDate.setDate(d.getDate() + 7);

            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();

            let day1 = futureDate.getDate();
            let month1 = futureDate.getMonth() + 1;
            let year1 = futureDate.getFullYear();

            if (day < 10) day = "0" + day;
            if (month < 10) month = `0${month}`;
            let newDate = year + "-" + month + "-" + day;

            if (day1 < 10) day1 = "0" + day1;
            if (month < 10) month1 = `0${month1}`;
            let newDate1 = year1 + "-" + month1 + "-" + day1;

            const upadatedProduct = selectedCheckBoxData.map((val) => {
              return {
                productListing: val.product,
                quantity: 1,
                unitPrice: val.product.unitPrice,
              };
            });

            const modelDatas = processFormData(allModels);

            const finalData1 = buildFinalData(
              mainModel.schema.attributes,
              modelDatas
            );

            const jsonAutomation = {
              EntityType: enityName,
              State: state,
              County: county,
              Payload: finalData1,
              Header: addonServiceRedirectedData
                ? addonServiceRedirectedData
                : null,
              legalName:
                getValues("X_Name_of_Entity") !== undefined
                  ? getValues("X_Name_of_Entity")
                  : globalCompany
                  ? globalCompany.companyDetails
                    ? globalCompany.companyDetails.companyName
                    : "Order"
                  : "Order",
              addOnService: addOnServiceData,
              billingAddress: bilingAddress,
              shipingAddress: shippingAddress,
              keyPersonalStrapiArr: keyPersonalStrapiArr,
            };

            const orderData = {
              contactId: contactId,
              companyId: adminCompany
                ? adminCompany.id
                : globalCompany
                ? globalCompany.companyDetails
                  ? globalCompany.companyDetails.id
                  : null
                : null,
              orderTitle:
                getValues("X_Name_of_Entity") !== undefined
                  ? getValues("X_Name_of_Entity")
                  : globalCompany
                  ? globalCompany.companyDetails
                    ? globalCompany.companyDetails.companyName
                    : "Order"
                  : "Order",
              orderId: null,
              billingAddress: bilingAddress
                ? bilingAddress.addressMasterId.id
                : null,
              statusId: 7,
              orderAmount: parseFloat(calculateTotalAmount()),
              orderDate: newDate,
              // "tentativeFulfillmentDate": newDate1,
              actualCompletionDate: null,
              strapiOrderFormJson: JSON.stringify(jsonAutomation),
              strapiOrderId: null,
              strapiOrderType: "Standard",
              orderTypeId: orderTypeId,
              stateId: state ? state.id : null,
              countyId: county ? county.id : null,
              transaction_id: null,
              entityTypeId: enityName ? enityName.id : null,
              isPayLater: paymentMethod === "Pay later" ? true : false,
              domicileStateId: state ? state.id : null,
              hubspotDealId: null,
              orderUpdateNotes: "Draft order created...",
              documentsId: null,
              orderUpdatedBy: loginUserName,
              keyPersonalId: keyPersonalArr,
              orderLineItems: upadatedProduct,
            };

            const saveOrder = () => {
              if (formData) {
                const areIdentical = deepCompareObjects(
                  serviceDataTemp,
                  selectedCheckBoxData
                );

                if (!areIdentical && serviceDataTemp.length > 0) {
                  const temp = formData;
                  temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
                  temp["orderLineItems"] = upadatedProduct;
                  temp["billingAddress"] = bilingAddress
                    ? bilingAddress.addressMasterId.id
                    : null;
                  temp["shippingAddress"] = shippingAddress
                    ? shippingAddress.addressMasterId.id
                    : bilingAddress
                    ? bilingAddress.addressMasterId.id
                    : null;
                  temp["orderTitle"] =
                    getValues("X_Name_of_Entity") !== undefined
                      ? getValues("X_Name_of_Entity")
                      : globalCompany
                      ? globalCompany.companyDetails
                        ? globalCompany.companyDetails.companyName
                        : "Order"
                      : "Order";

                  temp["stateId"] = state ? state.id : null;
                  temp["countyId"] = county ? county.id : null;
                  temp["keyPersonalId"] = keyPersonalArr;
                  setIsTabRefresh(true);
                  return OrderServices.deleteOrderLineItemById(formData.id)
                    .then((res) => {
                      // Save order data and handle error

                      return OrderServices.saveAllOrder(temp)
                        .then((res) => {
                          setPaymentOrderData(res.data);
                          setFormData(res.data.orderDetails);
                          setIsTabRefresh(false);
                          setUpdatedOrderLineItems(res.data.orderLineItem);
                        })
                        .catch((err) => {
                          setIsTabRefresh(false);
                          // if (toast.current) {
                          //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                          // }

                          showToast("error", "Something went wrong");

                          return false; // Prevent navigation on error
                        });
                    })
                    .catch((err) => {
                      setIsTabRefresh(false);
                    });
                } else {
                  const temp = formData;
                  temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
                  temp["orderLineItems"] = updatedOrderLineItems;
                  temp["billingAddress"] = bilingAddress
                    ? bilingAddress.addressMasterId.id
                    : null;
                  temp["shippingAddress"] = shippingAddress
                    ? shippingAddress.addressMasterId.id
                    : bilingAddress
                    ? bilingAddress.addressMasterId.id
                    : null;
                  temp["orderTitle"] =
                    getValues("X_Name_of_Entity") !== undefined
                      ? getValues("X_Name_of_Entity")
                      : globalCompany
                      ? globalCompany.companyDetails
                        ? globalCompany.companyDetails.companyName
                        : "Order"
                      : "Order";

                  temp["stateId"] = state ? state.id : null;
                  temp["countyId"] = county ? county.id : null;
                  temp["keyPersonalId"] = keyPersonalArr;
                  // Save order data and handle error
                  setIsTabRefresh(true);
                  return OrderServices.saveAllOrder(temp)
                    .then((res) => {
                      setPaymentOrderData(res.data);
                      setFormData(res.data.orderDetails);
                      setIsTabRefresh(false);
                      setUpdatedOrderLineItems(res.data.orderLineItem);
                    })
                    .catch((err) => {
                      setIsTabRefresh(false);
                      // if (toast.current) {
                      //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                      // }

                      showToast("error", "Something went wrong");

                      return false; // Prevent navigation on error
                    });
                }
              } else {
                setIsTabRefresh(true);
                return OrderServices.saveAllOrder(orderData)
                  .then((res) => {
                    setOrderContinue(true);
                    setPaymentOrderData(res.data);
                    setFormData(res.data.orderDetails);
                    setIsTabRefresh(false);
                    setIsFirstDraft(true);
                    setUpdatedOrderLineItems(res.data.orderLineItem);

                    if (res.status === 201) {
                      // toast.current.show({ severity: 'success', summary: 'Success', detail: `Your order is saved in draft with temporary order id ${res.data.orderDetails.id}`, life: 3000 });
                      showToast(
                        "success",
                        `Your order is saved in draft with temporary order id ${res.data.orderDetails.id}`
                      );
                    }
                  })
                  .catch((err) => {
                    setIsTabRefresh(false);
                    // if (toast.current) {
                    //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                    // }

                    showToast("error", `Something went wrong`);

                    return false; // Prevent navigation on error
                  });
              }
            };

            saveOrder().then((success) => {
              if (success !== false) {
                // Only proceed to next page if save was successful
                setCurrentTab(newTab);
                showTab(newTab);
              }
            });
          }
        }
      }
    } else {
      // if (toast.current !== undefined) {
      //     toast.current.show({ severity: 'warn', summary: 'Warning', detail: "Please select at least one product", life: 3000 });
      // }
      showToast("error", `Please select at least one product`);
    }

    // Smooth scroll to top after click
    document.getElementById("nextBtn").addEventListener("click", function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Smooth scroll
      });
    });
  };

  const stepLabels = Array.from(
    { length: inputFormData.length + 5 },
    (_, i) => `Step ${i + 1} of ${inputFormData.length + 5}`
  );

  function stripSuffix(inputName) {
    return inputName.replace(/-\d+$/, "");
  }

  function validateForm() {
    const x = document.getElementsByClassName("tab");
    let valid = true;

    if (x.length > 0 && x[currentTab] !== undefined) {
      const y = Array.from(x[currentTab].getElementsByTagName("input")).filter(
        (input) => input.type !== "file"
      );
      const dropdowns = x[currentTab].getElementsByClassName("p-dropdown");
      const fileInputs = x[currentTab].querySelectorAll('input[type="file"]');

      // A loop that checks every input field in the current tab:
      for (let i = 0; i < y.length; i++) {
        const input = y[i];
        const inputName = stripSuffix(input.name);

        const column = allModels.find((field) => {
          const temp = field.schema.attributes;

          return Object.keys(temp).includes(inputName);
        });

        if (column && column !== undefined) {
          const fieldData = column.schema.attributes[inputName];

          const errorMessageTag = document.getElementById(
            `${input.name}--error`
          );

          // Reset previous error message
          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }

          // Check if the input field is empty and required:

          if (fieldData.required && input.value.trim() === "") {
            valid = false;
            if (errorMessageTag) {
              errorMessageTag.textContent = `${convertName(
                inputName
              )} required`;
            }
          }
          if (
            input.name === "CD_LLC_Name" ||
            input.name === "CD_Corporation_Name"
          ) {
            if (enityName && state) {
              const entityRegex =
                validationConfig[enityName.entityShortName]?.[
                  state.stateFullDesc
                ];

              if (entityRegex && !entityRegex.test(input.value.trim())) {
                valid = false;
                if (errorMessageTag) {
                  const patternText = entityRegex.source
                    .split("|")
                    .join(", ")
                    .replace(/[()\\$]/g, ""); // Remove special characters: (), \, ., $

                  errorMessageTag.textContent = `Enter a valid ${convertName(
                    inputName
                  )} ending with ${patternText}`;
                }
              }
            }
          }

          if (
            (getValues("CD_Alternate_LLC_Name") &&
              input.name === "CD_Alternate_LLC_Name") ||
            (getValues("CD_Alternate_Corporation_Name") &&
              "CD_Alternate_Corporation_Name")
          ) {
            if (enityName && state) {
              const entityRegex =
                validationConfig[enityName.entityShortName]?.[
                  state.stateFullDesc
                ];

              if (entityRegex && !entityRegex.test(input.value.trim())) {
                valid = false;
                if (errorMessageTag) {
                  const patternText = entityRegex.source
                    .split("|")
                    .join(", ")
                    .replace(/[()\\$]/g, ""); // Remove special characters: (), \, ., $

                  errorMessageTag.textContent = `Enter a valid ${convertName(
                    inputName
                  )} ending with ${patternText}`;
                }
              }
            }
          }

          // Check regex validation if provided:
          if (fieldData.regex && input.value.trim() !== "") {
            const regex = new RegExp(fieldData.regex);
            if (!regex.test(input.value.trim())) {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `Enter Valid ${convertName(
                  inputName
                )} `;
              }
            }
          }
          if (input.type === "text" || input.type === "textarea") {
            if (fieldData.maxLength && input.value.trim() !== "") {
              if (input.value.length > fieldData.maxLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    inputName
                  )} should have a maximum length of ${fieldData.maxLength}`;
                }
              }
            }
            if (fieldData.minLength && input.value.trim() !== "") {
              if (input.value.length < fieldData.minLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    inputName
                  )} should have a minimum length of ${fieldData.minLength}`;
                }
              }
            }
          } else if (input.type === "number") {
            const value = parseInt(input.value, 10);

            if (fieldData.max && !isNaN(value) && value > fieldData.max) {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `${convertName(
                  inputName
                )} should be ${fieldData.max.length} digits`;
              }
            }
            if (fieldData.min && !isNaN(value) && value < fieldData.min) {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `${convertName(
                  inputName
                )} should be at least ${fieldData.min.length} digits`;
              }
            }
          }
        } else {
          if (inputFormData.length === 0) {
            valid = false;
          }
        }
      }

      // A loop that checks every dropdown field in the current tab:
      for (let i = 0; i < dropdowns.length; i++) {
        const dropdown = dropdowns[i];
        const inputName = dropdown.id;
        const inputWithoutSuffix = stripSuffix(dropdown.id);
        const inputValue = dropdown
          .querySelector(".p-dropdown-label")
          .textContent.trim();

        if (inputName) {
          const column = allModels.find((field) => {
            const temp = field.schema.attributes;
            return Object.keys(temp).includes(inputWithoutSuffix);
          });

          if (column && column !== undefined) {
            const fieldData = column.schema.attributes[inputWithoutSuffix];
            const errorMessageTag = document.getElementById(
              `${inputName}--error`
            );

            // Reset previous error message
            if (errorMessageTag) {
              errorMessageTag.textContent = "";
            }

            // Check if the dropdown field is empty and required:
            if (fieldData.required && inputValue === "empty") {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `${convertName(
                  inputWithoutSuffix
                )} required`;
              }
            }

            // Check regex validation if provided:
            if (fieldData.regex && inputValue !== "") {
              const regex = new RegExp(fieldData.regex);
              if (!regex.test(inputValue)) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `Enter Valid ${convertName(
                    inputWithoutSuffix
                  )} `;
                }
              }
            }
            if (fieldData.maxLength && inputValue !== "") {
              if (inputValue.length > fieldData.maxLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    inputWithoutSuffix
                  )} Should be Max Length ${fieldData.maxLength} `;
                }
              }
            }
            if (fieldData.minLength && inputValue !== "") {
              if (inputValue.length < fieldData.minLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    inputWithoutSuffix
                  )} Should be Min Length ${fieldData.minLength} `;
                }
              }
            }
          } else {
            if (inputFormData.length === 0) {
              valid = false;
            }
          }
        }
      }

      // A loop that checks each file input field in the current tab:
      for (let i = 0; i < fileInputs.length; i++) {
        const fileInput = fileInputs[i];
        const fileName = stripSuffix(fileInput.name);

        const column = allModels.find((field) => {
          const temp = field.schema.attributes;
          return Object.keys(temp).includes(fileName);
        });

        if (column && column !== undefined) {
          const fieldData = column.schema.attributes[fileName];
          const errorMessageTag = document.getElementById(
            `${fileInput.name}--error`
          );

          // Reset previous error message
          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }

          // Check if the file input is required and no file is selected:
          if (
            fieldData.required &&
            fileInput.files.length === 0 &&
            getValues(fileInput.name) === undefined
          ) {
            valid = false;
            if (errorMessageTag) {
              errorMessageTag.textContent = `${convertName(fileName)} required`;
            }
          }
        }
      }

      // If the valid status is true, mark the step as finished and valid:
      if (valid) {
        const stepElements = document.getElementsByClassName("step");

        if (stepElements[currentTab] !== undefined) {
          stepElements[currentTab].className += " finish";
        }
      }
    }

    return valid; // return the valid status
  }

  function fixStepIndicator(n) {
    // This function removes the "active" class of all steps...
    var i,
      x = document.getElementsByClassName("step");
    for (i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(" active", "");
    }
    //... and adds the "active" class on the current step:
    if (x[n] !== undefined) {
      x[n].className += " active";
    }
  }

  const handleComapnyName = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Only check availability if the input is not empty
    if (value) {
      checkAvailability(value);
    } else {
      setIsAvailable(null);
    }
  };

  const handleLLCMember = (e) => {
    setLlc(e.target.value);
  };

  function processSchema(schema, dataMap, depth = 0, maxDepth = 10) {
    if (depth > maxDepth) {
      console.warn("Maximum recursion depth exceeded");
      return {};
    }

    const result = {};

    if (schema !== undefined) {
      Object.keys(schema.attributes).forEach((attrKey) => {
        const attr = schema.attributes[attrKey];

        if (attr.apiId !== undefined && mainModel) {
          const mainSchema = mainModel.schema.attributes;
          const componentKeys = Object.keys(mainSchema).map((k) => {
            const attributes = mainSchema[k]?.schema?.attributes;
            if (attributes && Object.keys(attributes).length > 0) {
              return Object.keys(attributes).filter(
                (key) => attributes[key]?.uid === attr.uid
              );
            }
            return [];
          });
          const componentKey = componentKeys.flat();
          const componentData = dataMap.get(attr.uid);

          if (componentData) {
            if (mainSchema[componentKey] && mainSchema[componentKey].schema) {
              result[attrKey] = processSchema(
                mainSchema[componentKey].schema,
                dataMap,
                depth + 1,
                maxDepth
              );
              Object.assign(result[attrKey], componentData);
            } else {
              result[attrKey] = componentData;
            }
          }
        } else {
          const parentData = dataMap.get(attrKey);
          if (parentData) {
            result[attrKey] = parentData;
          }
        }
      });
    }

    return result;
  }

  function buildFinalData(temps, tempsData) {
    const finalData = {};

    const dataMap = new Map(tempsData.map((d) => [d.model, d.data]));

    if (mainModel) {
      Object.keys(temps).forEach((key) => {
        const schema = temps[key].schema;
        const data = dataMap.get(temps[key].uid);

        finalData[key] = processSchema(schema, dataMap);

        if (data) {
          if (Array.isArray(data)) {
            finalData[key] = [...data];
          } else {
            Object.assign(finalData[key], data);
          }
        }
      });
    }

    return finalData;
  }

  useEffect(() => {
    if (mainModel && modelData1.length > 0) {
      if (mainModel.schema !== undefined) {
        if (mainModel.schema.attributes !== undefined) {
          const finalData1 = buildFinalData(
            mainModel.schema.attributes,
            modelData1
          );

          setPriviewData(finalData1);
        }
      }
    }
  }, [mainModel, modelData1]);

  const getState = (id) => {
    const temp = allState.find((val) => val.id === id);
    if (temp !== undefined) {
      return temp.stateFullDesc;
    } else {
      return null;
    }
  };

  const calculatePrice = (serviceData, quantity) => {
    if (serviceData.product.isBundlePriceApplicable) {
      const { noOfItemsInBundle, priceOfBundle, priceForAddlItemsOverBundle } =
        serviceData.product;

      if (quantity <= noOfItemsInBundle) {
        return priceOfBundle;
      } else {
        const bundledPrice = priceOfBundle;
        const additionalItemsPrice =
          priceForAddlItemsOverBundle * (quantity - noOfItemsInBundle);
        return bundledPrice + additionalItemsPrice;
      }
    } else {
      return serviceData.product.unitPrice;
    }
  };

  const calculateTotalAmount = () => {
    return selectedCheckBoxData.reduce((total, val) => {
      const quantity = formCount;
      const itemTotal = calculatePrice(val, quantity);
      return total + itemTotal;
    }, 0);
  };

  // const stripePay = async (item, orderData) => {

  //     try {

  //         const stripe = await stripePromise;

  //         const data = {

  //             product: item
  //         }

  //         const response = await axios.post(`${propertiesApp.stripe_host}/checkout`, data);
  //         console.log(response)

  //         const sessionId = response.data.id;
  //         console.log(sessionId)

  //         const localData = {
  //             data: orderData,
  //             res: response.data,
  //             orderLineItem: item,
  //             updatedBy: loginUserName
  //         }
  //         localStorage.setItem("payment", JSON.stringify(localData))
  //         const { error } = await stripe.redirectToCheckout({
  //             sessionId: sessionId
  //         });
  //         if (error) {
  //             console.error("Stripe Checkout Error:", error);
  //         }
  //     } catch (err) {
  //         console.error("Error during checkout process:", err);
  //     }
  // }

  const stripePay = async (item, orderData) => {
    try {
      const stripe = await stripePromise;

      const data = {
        product: item,
      };

      const productPaydata = item.map((val) => {
        return {
          name: val.productListing.displayName,
          unitAmount: Math.round(val.productListing.unitPrice * 100),
          quantity: 1,
        };
      });

      const paytemp = {
        lineItems: productPaydata,
      };

      // const response = await axios.post(`$/checkout`, data);
      setIsLoading(true);
      setIsTabRefresh(true);
      OrderServices.paymentOrder(paytemp)
        .then((response) => {
          localStorage.setItem("sessionId", response.data.sessionId);
          // setPaySessionId(res.data.sessionId)

          // const sessionId = response.data.id;
          // console.log(sessionId)

          const localData = {
            data: orderData,
            res: response.data,
            orderLineItem: item,
            updatedBy: loginUserName,
          };
          localStorage.setItem("payment", JSON.stringify(localData));
          setIsLoading(false);
          setIsTabRefresh(false);
          setCloseProgresbar3(false);
          setTimeout(() => {
            window.location.href = response.data.checkoutUrl;
          }, 100); // Small timeout to ensure data is saved
          // const { error } = await stripe.redirectToCheckout({
          //     sessionId: sessionId
          // });
          // if (error) {
          //     console.error("Stripe Checkout Error:", error);
          // }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsTabRefresh(false);
        });
    } catch (err) {
      console.error("Error during checkout process:", err);
    }
  };

  // Function to handle confirmation
  const handleConfirm = async () => {
    if (mainModel) {
      const jsonAutomation = {
        EntityType: enityName,
        State: state,
        Payload: formData,
        legalName:
          getValues("CD_LLC_Name") !== undefined
            ? getValues("CD_LLC_Name")
            : getValues("CD_Corporation_Name") !== undefined
            ? getValues("CD_Corporation_Name")
            : null,
        addOnService: addOnServiceData,
        billingAddress: bilingAddress,
        shipingAddress: shippingAddress,
      };

      const BoiData = {
        id: 0,
        entityType: enityName,
        state: state,
        county: county,
        Payload: formData,
        Header: addonServiceRedirectedData ? addonServiceRedirectedData : null,
        legalName:
          getValues("X_Name_of_Entity") !== undefined
            ? getValues("X_Name_of_Entity")
            : globalCompany
            ? globalCompany.companyDetails
              ? globalCompany.companyDetails.companyName
              : "Order"
            : "Order",
        addOnService: addOnServiceData,
        billingAddress: bilingAddress,
      };

      const keyPersonalArr = [];

      if (incorporator?.id) keyPersonalArr.push(incorporator.id);
      if (officer?.id) keyPersonalArr.push(officer.id);
      if (director?.id) keyPersonalArr.push(director.id);
      if (organizerData?.id) keyPersonalArr.push(organizerData.id);
      if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
      if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);

      let d = new Date();
      const futureDate = new Date(d);
      futureDate.setDate(d.getDate() + 7);

      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();

      let day1 = futureDate.getDate();
      let month1 = futureDate.getMonth() + 1;
      let year1 = futureDate.getFullYear();

      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = `0${month}`;
      } else {
        month = month;
      }
      let newDate = year + "-" + month + "-" + day;

      if (day1 < 10) {
        day1 = "0" + day1;
      }
      if (month < 10) {
        month1 = `0${month1}`;
      } else {
        month1 = month1;
      }
      let newDate1 = year1 + "-" + month1 + "-" + day1;

      const upadatedProduct = selectedCheckBoxData.map((val) => {
        return {
          productListing: val.product,
          quantity: 1,
          unitPrice: calculateTotalAmount(),
        };
      });

      setIsTabRefresh(true);
      if (paymentMethod === "Pay later") {
        const temp = formData;
        temp["statusId"] = 1000;
        temp["orderLineItems"] = updatedOrderLineItems;
        temp["orderUpdateNotes"] = "Approval Pending";
        temp["orderUpdatedBy"] = loginUserName;
        temp["shippingAddress"] = shippingAddress
          ? shippingAddress.addressMasterId.id
          : bilingAddress
          ? bilingAddress.addressMasterId.id
          : null;

        temp["stateId"] = state ? state.id : null;
        temp["countyId"] = county ? county.id : null;
        temp["strapiOrderId"] = strapiId;
        temp["keyPersonalId"] = keyPersonalArr;
        setIsTabRefresh(true);
        OrderServices.saveAllOrder(temp)
          .then((res) => {
            setOrderContinue(false);
            setIsTabRefresh(false);
            setRedirectedData(res.data.orderDetails);
            history("/order");
          })
          .catch((err) => {
            setCloseProgresbar2(false);
            setCloseProgresbar3(false);
            setCloseProgresbar1(true);
            setIsTabRefresh(false);
            // if (toast.current) {
            //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
            // }
            showToast("error", "Something went wrong");
          });
      } else {
        if (formData) {
          const temp = formData;
          temp["statusId"] = 14;
          temp["orderLineItems"] = updatedOrderLineItems;
          temp["orderUpdateNotes"] = "Order created successfully.";
          temp["orderUpdatedBy"] = loginUserName;
          temp["shippingAddress"] = shippingAddress
            ? shippingAddress.addressMasterId.id
            : bilingAddress
            ? bilingAddress.addressMasterId.id
            : null;
          temp["strapiOrderId"] = strapiId;
          temp["stateId"] = state ? state.id : null;
          temp["orderDate"] = newDate;
          temp["tentativeFulfillmentDate"] = futureDate;
          temp["countyId"] = county ? county.id : null;
          temp["keyPersonalId"] = keyPersonalArr;
          setIsTabRefresh(true);
          OrderServices.saveAllOrder(temp)
            .then((res) => {
              setOrderContinue(false);
              const orderPayData = res.data;
              const tempNew = res.data.orderDetails;
              tempNew["shippingAddress"] = shippingAddress
                ? shippingAddress.addressMasterId.id
                : bilingAddress
                ? bilingAddress.addressMasterId.id
                : null;
              tempNew["orderLineItems"] = updatedOrderLineItems;
              const invoiceData = {
                orderId: tempNew,
                invoiceNo: `INV ${res.data.orderDetails.orderId}`,
                totalAmount: calculateTotalAmount(),
                orderLineItems: updatedOrderLineItems,
              };

              const temp = res.data.orderDetails;

              OrderServices.saveAllInvoice(invoiceData)
                .then((res) => {
                  // setCloseProgresbar2(false)
                  // setCloseProgresbar3(false)
                  // setCloseProgresbar(true)
                  setIsTabRefresh(false);

                  stripePay(updatedOrderLineItems, orderPayData);
                })
                .catch((err) => {
                  setCloseProgresbar2(false);
                  setCloseProgresbar3(false);
                  // setCloseProgresbar1(true)
                  setIsTabRefresh(false);
                  stripePay(updatedOrderLineItems, orderPayData);
                  // if (toast.current) {
                  //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                  // }
                });
              if (orderTypeId === 18) {
                OrderServices.submitBoiApplication(
                  BoiData,
                  res.data.orderDetails.orderId
                )
                  .then((res) => {
                    // if (toast.current !== undefined && toast.current !== null) {
                    //     toast.current.show({ severity: 'success', summary: 'Success', detail: 'BOI data successfully updated', life: 2800 });
                    // }
                    showToast("success", "BOI data successfully updated");
                  })
                  .catch((err) => {});
              }
            })
            .catch((err) => {
              setCloseProgresbar2(false);
              setCloseProgresbar3(false);
              // setCloseProgresbar1(true)
              setIsTabRefresh(false);
              // if (toast.current) {
              //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
              // }
            });
        }
      }
    }
  };

  const onSubmit = (data) => {
    if (mainModel && bilingAddress) {
      const keyPersonalArr = [];
      if (incorporator?.id) keyPersonalArr.push(incorporator.id);
      if (officer?.id) keyPersonalArr.push(officer.id);
      if (director?.id) keyPersonalArr.push(director.id);
      if (organizerData?.id) keyPersonalArr.push(organizerData.id);
      if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
      if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);

      const keyPersonalStrapiArr = [];
      if (incorporator?.id)
        keyPersonalStrapiArr.push({ data: incorporator, type: "INCORPORATOR" });
      if (officer?.id)
        keyPersonalStrapiArr.push({ data: officer, type: "OFFICER" });
      if (director?.id)
        keyPersonalStrapiArr.push({ data: director, type: "DIRECTOR" });
      if (organizerData?.id)
        keyPersonalStrapiArr.push({ data: organizerData, type: "ORGANIZER" });
      if (bilingAddress?.id)
        keyPersonalStrapiArr.push({ data: bilingAddress, type: "BILLING" });
      if (shippingAddress?.id)
        keyPersonalStrapiArr.push({ data: shippingAddress, type: "SHIPPING" });

      let d = new Date();
      const futureDate = new Date(d);
      futureDate.setDate(d.getDate() + 7);

      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();

      let day1 = futureDate.getDate();
      let month1 = futureDate.getMonth() + 1;
      let year1 = futureDate.getFullYear();

      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = `0${month}`;
      } else {
        month = month;
      }
      let newDate = year + "-" + month + "-" + day;

      if (day1 < 10) {
        day1 = "0" + day1;
      }
      if (month < 10) {
        month1 = `0${month1}`;
      } else {
        month1 = month1;
      }
      let newDate1 = year1 + "-" + month1 + "-" + day1;

      const upadatedProduct = selectedCheckBoxData.map((val) => {
        return {
          productListing: val.product,
          quantity: 1,
          unitPrice: calculateTotalAmount(),
        };
      });

      const finalData1 = buildFinalData(
        mainModel.schema.attributes,
        modelData1
      );

      const tempData = {
        data: finalData1,
      };
      const jsonAutomation = {
        EntityType: enityName,
        State: state,
        County: county,
        Payload: finalData1,
        legalName:
          getValues("CD_LLC_Name") !== undefined
            ? getValues("CD_LLC_Name")
            : getValues("CD_Corporation_Name") !== undefined
            ? getValues("CD_Corporation_Name")
            : null,
        addOnService: addOnServiceData,
        billingAddress: bilingAddress,
        shipingAddress: shippingAddress,
        keyPersonalStrapiArr: keyPersonalStrapiArr,
      };
      const BoiData = {
        id: 0,
        entityType: enityName,
        state: state,
        county: county,
        Payload: finalData1,
        Header: addonServiceRedirectedData ? addonServiceRedirectedData : null,
        legalName:
          getValues("X_Name_of_Entity") !== undefined
            ? getValues("X_Name_of_Entity")
            : globalCompany
            ? globalCompany.companyDetails
              ? globalCompany.companyDetails.companyName
              : "Order"
            : "Order",
        addOnService: addOnServiceData,
        billingAddress: bilingAddress,
      };

      setIsTabRefresh(true);
      if (orderTypeId !== 18) {
        if (paymentMethod === "Pay later") {
          const temp = formData;
          temp["statusId"] = 14;
          temp["orderLineItems"] = updatedOrderLineItems;
          temp["orderUpdateNotes"] = "Order created successfully";
          temp["orderUpdatedBy"] = loginUserName;
          temp["shippingAddress"] = shippingAddress
            ? shippingAddress.addressMasterId.id
            : bilingAddress
            ? bilingAddress.addressMasterId.id
            : null;
          temp["strapiOrderId"] = 1;
          temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
          temp["stateId"] = state ? state.id : null;
          temp["countyId"] = county ? county.id : null;
          temp["keyPersonalId"] = keyPersonalArr;
          temp["orderDate"] = newDate;
          temp["tentativeFulfillmentDate"] = futureDate;
          OrderServices.saveAllOrder(temp)
            .then((res) => {
              const tempNew = res.data.orderDetails;
              tempNew["shippingAddress"] = shippingAddress
                ? shippingAddress.addressMasterId.id
                : bilingAddress
                ? bilingAddress.addressMasterId.id
                : null;
              tempNew["orderLineItems"] = updatedOrderLineItems;
              const invoiceData = {
                orderId: tempNew,
                invoiceNo: `INV ${res.data.orderDetails.orderId}`,
                totalAmount: calculateTotalAmount(),
                orderLineItems: updatedOrderLineItems,
              };

              const temp = res.data.orderDetails;

              OrderServices.saveAllInvoice(invoiceData)
                .then((res) => {
                  // setCloseProgresbar2(false)
                  // setCloseProgresbar3(false)
                  // setCloseProgresbar(true)
                  setIsTabRefresh(false);
                  setPaymentOrderData(res.data);
                  setFormData(res.data.orderId);
                  setCloseProgresbar2(true);
                })
                .catch((err) => {
                  setCloseProgresbar2(true);
                  setCloseProgresbar3(false);
                  // setCloseProgresbar1(true)
                  setIsTabRefresh(false);
                  // if (toast.current) {
                  //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                  // }
                });
            })
            .catch((err) => {
              setCloseProgresbar2(false);
              setCloseProgresbar3(false);
              setCloseProgresbar1(true);
              setIsTabRefresh(false);
              // if (toast.current) {
              //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
              // }
            });
        } else {
          setCloseProgresbar3(true);
          setIsTabRefresh(false);
        }
      } else {
        if (paymentMethod === "Pay later") {
          if (formData) {
            const temp = formData;
            temp["statusId"] = 14;
            temp["orderLineItems"] = updatedOrderLineItems;
            temp["orderUpdateNotes"] = "Order created successfully";
            temp["orderUpdatedBy"] = loginUserName;
            temp["shippingAddress"] = shippingAddress
              ? shippingAddress.addressMasterId.id
              : bilingAddress
              ? bilingAddress.addressMasterId.id
              : null;
            temp["strapiOrderId"] = null;
            temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
            temp["stateId"] = state ? state.id : null;
            temp["countyId"] = county ? county.id : null;
            temp["keyPersonalId"] = keyPersonalArr;
            temp["orderDate"] = newDate;
            temp["tentativeFulfillmentDate"] = futureDate;

            OrderServices.saveAllOrder(temp)
              .then((res) => {
                const tempNew = res.data.orderDetails;
                tempNew["shippingAddress"] = shippingAddress
                  ? shippingAddress.addressMasterId.id
                  : bilingAddress
                  ? bilingAddress.addressMasterId.id
                  : null;
                tempNew["orderLineItems"] = updatedOrderLineItems;
                const invoiceData = {
                  orderId: tempNew,
                  invoiceNo: `INV ${res.data.orderDetails.orderId}`,
                  totalAmount: calculateTotalAmount(),
                  orderLineItems: updatedOrderLineItems,
                };

                const temp = res.data.orderDetails;

                OrderServices.saveAllInvoice(invoiceData)
                  .then((res) => {
                    // setCloseProgresbar2(false)
                    // setCloseProgresbar3(false)
                    // setCloseProgresbar(true)
                  })
                  .catch((err) => {
                    // if (toast.current) {
                    //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                    // }
                  });

                OrderServices.submitBoiApplication(
                  BoiData,
                  res.data.orderDetails.id
                )
                  .then((res) => {
                    setIsTabRefresh(false);
                    setPaymentOrderData(res.data);
                    setFormData(res.data.orderId);
                    setCloseProgresbar2(true);
                    // if (toast.current !== undefined && toast.current !== null) {
                    //     toast.current.show({ severity: 'success', summary: 'Success', detail: 'BOI data successfully updated', life: 2800 });
                    // }
                    showToast("success", "BOI data successfully updated");
                  })
                  .catch((err) => {
                    setCloseProgresbar2(true);
                    setCloseProgresbar3(false);
                    // setCloseProgresbar1(true)
                    setIsTabRefresh(false);
                  });
              })
              .catch((err) => {
                setCloseProgresbar2(false);
                setCloseProgresbar3(false);
                setCloseProgresbar1(true);
                setIsTabRefresh(false);
                // if (toast.current) {
                //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                // }
              });
          }
        } else {
          setCloseProgresbar3(true);
          setIsTabRefresh(false);
        }
      }
    } else {
      if (!bilingAddress) {
        // if (toast.current) {
        //     toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Billing address required', life: 2800 });

        // }
        showToast("error", "Billing address required");
      }
    }
  };

  const convertToTitleCase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
      .replace(/_/g, " ");
  };

  const handleChecked1 = (e, data, checkboxIndex) => {
    const { checked } = e.target;

    if (checked) {
      // Add data to selectedCheckBoxData
      setSelectedCheckBoxData((prev) => [...prev, data]);
    } else {
      // Remove data from selectedCheckBoxData
      setSelectedCheckBoxData((prev) =>
        prev.filter((item) => item.service !== data.service)
      );
    }

    // Update individual checkbox state
    if (checkboxIndex === 1) {
      setIsChecked1(checked);
    } else if (checkboxIndex === 2) {
      setIsChecked2(checked);
    } else if (checkboxIndex === 3) {
      setIsChecked3(checked);
    } else if (checkboxIndex === 4) {
      setIsChecked4(checked);
    } else if (checkboxIndex === 5) {
      setIsChecked5(checked);
    } else if (checkboxIndex === 6) {
      setIsChecked6(checked);
    } else if (checkboxIndex === 7) {
      setIsChecked7(checked);
    } else if (checkboxIndex === 8) {
      setIsChecked8(checked);
    } else if (checkboxIndex === 9) {
      setIsChecked9(checked);
    } else if (checkboxIndex === 10) {
      setIsChecked10(checked);
    }
  };

  // const totalAmount = selectCheckBoxData.reduce((total, item) => {

  //     const amount = parseFloat(item.amount.replace("$", ""));
  //     return total + amount;
  // }, 0);

  const toPascalCase = (str) => {
    return str
      .replace(/-/g, " ")
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const removeDuplicates = (data) => {
    const result = {};

    Object.keys(data).forEach((key) => {
      // Create a Set to store unique `uid` values
      const uniqueItems = new Map();

      data[key].form.forEach((item) => {
        // Use `uid` as the key to ensure uniqueness
        if (!uniqueItems.has(item.uid)) {
          uniqueItems.set(item.uid, item);
        }
      });

      // Convert the Map values back to an array
      result[key] = {
        mode: data[key].mode,
        form: Array.from(uniqueItems.values()),
      };
    });

    return result;
  };

  const keyPersonalMemberData = [
    "Organizer_Information",
    "Incorporator_Information",
    "Registered_Agent",
  ];

  const groupsedData =
    inputFormData.length > 0
      ? inputFormData.reduce((acc, value) => {
          if (typeof value.schema.attributes === "object") {
            const tmp = value.schema.attributes;
            if (tmp !== undefined) {
              const temp = Object.keys(tmp).map((key, i) => {
                Object.keys(groupInitialData).map((keys, m) => {
                  if (
                    key === keys &&
                    groupInitialData[keys].type === "component" &&
                    groupInitialData[keys].repeatable === true
                  ) {
                    const tmpAttributes = tmp[key]?.schema?.attributes;

                    if (
                      tmpAttributes &&
                      Object.keys(tmpAttributes).length > 0
                    ) {
                      if (typeof tmpAttributes === "object") {
                        const tempdata = Object.keys(tmpAttributes).map(
                          (keyval, i) => {
                            if (tmpAttributes[keyval]?.uid !== undefined) {
                              return tmpAttributes[keyval];
                            } else {
                              return tmp[key];
                            }
                          }
                        );

                        acc[key] = {
                          form: tempdata,
                          mode: {
                            repeat: groupInitialData[keys].repeatable,
                          },
                        };
                      }
                    }
                  } else {
                    if (tmp[key].schema !== undefined) {
                      if (
                        tmp[key].schema.attributes !== undefined &&
                        groupInitialData[keys].repeatable === false &&
                        key === keys
                      ) {
                        if (typeof tmp[key].schema.attributes === "object") {
                          const tempdata = Object.keys(
                            tmp[key].schema.attributes
                          ).map((keyval, i) => {
                            if (
                              tmp[key].schema.attributes[keyval].uid !==
                              undefined
                            ) {
                              return tmp[key].schema.attributes[keyval];
                            } else {
                              return tmp[key];
                            }
                          });

                          acc[key] = {
                            form: tempdata,
                            mode: null,
                          };
                        }
                      }
                    }
                  }
                });
              });
            }
          }
          return acc;
        }, {})
      : {};

  const groupedData = removeDuplicates(groupsedData);

  useEffect(() => {
    const temp = Object.keys(groupedData).map((key, i) => {
      return groupedData[key].form;
    });

    const tempdata = temp.flat();

    setAllModels(tempdata);
  }, [enityName, state, currentTab]);

  const handleKeyPersonal = (data) => {
    setVisible2(true);
    const temp = allKeyPersonnelType.filter(
      (val) => val.keyPersonalType === data
    );
    if (temp.length > 0) {
      setMemberType(temp[0]);
    }

    const temp1 = allAddressType.filter((val) => val.type === data);

    if (temp1.length > 0) {
      setKeyPersonalAddressType(temp1[0]);
    }
  };

  const handleBOIKeyPersonal = (data) => {
    setVisible8(true);
    const temp = ownerType.filter((val) => val.name === data);
    if (temp.length > 0) {
      setMemberType(temp[0]);
    }

    const temp1 = allAddressType.filter((val) => val.type === data);

    if (temp1.length > 0) {
      setKeyPersonalAddressType(temp1[0]);
    }
  };

  const RecursiveRender = ({ data, parentKey }) => {
    return (
      <>
        {Array.isArray(data) ? (
          // Handle array of objects
          data.map((item, idx) => (
            <div key={idx} className="p-6 grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <div
                  className="px-4 py-2 rounded-t-lg"
                  style={{
                    backgroundColor: "#F0F5FF",
                    fontFamily: "Inter",
                    fontSize: "16px",
                  }}
                >
                  <h2 className="text-lg text-gray-800">
                    {" "}
                    {convertToTitleCase(parentKey)}{" "}
                  </h2>
                </div>
              </div>
              {Object.keys(item).map((key, i) => (
                <div key={i} className="col-span-4">
                  {typeof item[key] === "object" ? (
                    <div className="border border-gray-300 rounded-lg shadow-sm bg-white">
                      <div
                        className="px-4 py-2 rounded-t-lg"
                        style={{
                          backgroundColor: "#F0F5FF",
                          fontFamily: "Inter",
                          fontSize: "16px",
                        }}
                      >
                        <h2 className="text-lg text-gray-800">
                          {convertToTitleCase(key)}
                        </h2>
                      </div>
                      {/* Recursively render the nested object */}
                      <RecursiveRender data={item[key]} parentKey={key} />
                    </div>
                  ) : (
                    <>
                      <div
                        className="font-medium text-gray-700 mb-1"
                        style={{ fontFamily: "Inter", fontSize: "14px" }}
                      >
                        {convertName(key)}
                      </div>
                      <div
                        className="text-gray-900"
                        style={{ fontFamily: "Inter", fontSize: "14px" }}
                      >
                        {item[key]}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))
        ) : typeof data === "object" && data !== null ? (
          <div className="p-6 grid grid-cols-12 gap-6">
            {Object.keys(data).map((key, i) => (
              <div key={i} className="col-span-4">
                {typeof data[key] === "object" ? (
                  <div className="border border-gray-300 rounded-lg shadow-sm bg-white">
                    <div
                      className="px-4 py-2 rounded-t-lg"
                      style={{
                        backgroundColor: "#F0F5FF",
                        fontFamily: "Inter",
                        fontSize: "16px",
                      }}
                    >
                      <h2 className="text-lg text-gray-800">
                        {convertToTitleCase(key)}
                      </h2>
                    </div>
                    {/* Recursively render the child object */}
                    <RecursiveRender data={data[key]} parentKey={key} />
                  </div>
                ) : (
                  <>
                    <div
                      className="font-medium text-gray-700 mb-1"
                      style={{ fontFamily: "Inter", fontSize: "14px" }}
                    >
                      {convertName(key)}
                    </div>
                    <div
                      className="text-gray-900"
                      style={{ fontFamily: "Inter", fontSize: "14px" }}
                    >
                      {data[key]}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div
            className="font-medium text-gray-700 mb-1"
            style={{ fontFamily: "Inter", fontSize: "14px" }}
          >
            {convertName(parentKey)}
            <div
              className="text-gray-900"
              style={{ fontFamily: "Inter", fontSize: "14px" }}
            >
              {data}
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (groupedData) {
      Object.keys(groupedData).map((key, i) => {
        const isRepeatMode =
          groupedData[key].mode && groupedData[key].mode.repeat === true;

        const processAttributes = (val, l = "") => {
          const tempval = convertName(val);
          const options = optionsMap?.[tempval] || [];

          if (options.length > 0) {
            const matchedItem = tempdata.find((item) => item.item === tempval);
            const defaultValue =
              matchedItem !== undefined
                ? matchedItem.label === "stateFullDesc" && state
                  ? state.stateShortName
                  : matchedItem.label === "countyName" && county
                  ? county.countyCode
                  : matchedItem.label === "countryShortName" && state
                  ? state.countryMaster.countryShortName
                  : ""
                : "";

            if (defaultValue) {
              if (l) {
                setValue(`${val}${l ? `-${l}` : ""}`, defaultValue);
              } else {
                setValue(val, defaultValue);
              }
            }
          }
        };

        if (isRepeatMode) {
          // Repeat mode case
          return Array.from({ length: formCount }).map((_, l) => {
            return groupedData[key].form.map((value, j) => {
              return Object.keys(value.schema.attributes).map((val, k) => {
                processAttributes(val, l); // Pass the index `l` for repeated fields
              });
            });
          });
        } else {
          // Non-repeat mode case
          return groupedData[key].form.map((value, j) => {
            return Object.keys(value.schema.attributes).map((val, k) => {
              processAttributes(val); // No index `l` for non-repeated fields
            });
          });
        }
      });
    }
  }, [groupedData]);

  const goToStep = (stepNumber) => {
    const totalSteps = Object.keys(groupedData).length + 3; // Total steps
    if (stepNumber >= 0 && stepNumber < totalSteps) {
      const tabs = document.getElementsByClassName("tab");
      if (tabs[currentTab]) {
        tabs[currentTab].style.display = "none";
      }

      setCurrentTab(stepNumber);
      setCurrentStep(stepNumber + 1);

      showTab(stepNumber);

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const onInvalid = (errors) => {
    const stepElements = document.getElementsByClassName("step");
    for (let index = 0; index < stepElements.length; index++) {
      if (!stepElements[index].classList.contains("finish")) {
        stepElements[index].classList.add("error");
      }
    }
    if (stepElements[currentTab] !== undefined) {
      stepElements[currentTab].classList.add("error");
    }
    showToast("error", `Please enter required information`);
  };

  useEffect(() => {
    if (state && enityName) {
      setIsLoading(true);

      if (Object.keys(groupedData).length > 0) {
        setIsLoading(false);
      }
    }
  }, [groupedData, state, enityName]);

  return (
    <div>
      <Toast ref={toast} />
      {loading1 ? (
        <div className="spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="data-container">
          <div className="grid font-fam-for-all">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <div
                className="card-container-wizard font-fam-for-all"
                id="card-container-wizard-id"
              >
                <div className="wizard-header">
                  <div className="wizard-header-container">
                    <div className="page-wizard-header">
                      <div className="page-wizard-content">
                        <div className="page-text-header">
                          <div className="page-text-header-p">
                            {addonServiceRedirectedData
                              ? toPascalCase(
                                  addonServiceRedirectedData.itemName
                                )
                              : ""}
                          </div>
                          <div className="page-text-header-p1">
                            {addonServiceRedirectedData
                              ? addonServiceRedirectedData.description1
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wizard-content-container">
                    <div className="wizard-content-main">
                      <div className="wizard-content-frame">
                        <div className="wizard-content-card">
                          <div className="wizard-form-card-header-container">
                            <div className="wizard-form-card-header-actiob-container">
                              <div className="wizard-form-card-frame">
                                {Object.keys(groupedData).length > 0 ? (
                                  <div className="wizard-form-card-header-text1">
                                    {`Step ${currentStep} of ${
                                      Object.keys(groupedData).length + 3
                                    }`}
                                  </div>
                                ) : (
                                  <div className="wizard-form-card-header-text1">
                                    {`Step 1 of 1`}
                                  </div>
                                )}
                                {inputFormData.length > 0 ? (
                                  <div className="wizard-form-card-header-text2">
                                    Total due today
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="wizard-form-card-frame">
                                <div className="wizard-form-card-header-text3">
                                  Entity Details
                                </div>
                                {inputFormData.length > 0 ? (
                                  <div className="wizard-form-card-header-text4">
                                    {`$${calculateTotalAmount()}`}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="wizard-bar">
                            <span
                              class={
                                Object.keys(groupedData).length > 0
                                  ? "step"
                                  : "step "
                              }
                              style={{ cursor: "pointer" }}
                              onClick={() => goToStep(0)}
                            >
                              <div className="mt-2 wizard-form-card-header-text3">
                                Entity Details
                              </div>
                            </span>
                            {Object.keys(groupedData).map((key, i) => (
                              <span
                                class="step"
                                onClick={() => goToStep(i + 1)}
                                style={{ cursor: "pointer" }}
                                key={`extra-${i}`}
                              >
                                <div className="mt-2 wizard-step-content wizard-form-card-header-text3">
                                  {toPascalCase(key)}
                                </div>
                              </span>
                            ))}

                            {inputFormData.length > 0 ? (
                              <>
                                <span
                                  class={
                                    Object.keys(groupedData).length > 0
                                      ? "step"
                                      : "step"
                                  }
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    goToStep(
                                      Object.keys(groupedData).length + 1
                                    )
                                  }
                                >
                                  <div className="mt-2 wizard-form-card-header-text3">
                                    Contact Address
                                  </div>
                                </span>

                                <span
                                  class={
                                    Object.keys(groupedData).length > 0
                                      ? "step"
                                      : "step"
                                  }
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    goToStep(
                                      Object.keys(groupedData).length + 2
                                    )
                                  }
                                >
                                  <div className="mt-2 wizard-form-card-header-text3">
                                    Preview
                                  </div>
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="wizard-main-content font-fam-for-all mt-4">
                            <div className="wizard-main-sub-content">
                              <form
                                className="form-wizard"
                                // onSubmit={handleSubmit(onSubmit)}
                              >
                                <div className="tab px-4 hover-card slide-in font-fam-for-all">
                                  <div
                                    className="wizard-content-sub-card-header my-3"
                                    id="header"
                                  >
                                    <div className="wizard-content-sub-card-text-frame">
                                      <div className="wizard-content-sub-card-text">
                                        Entity Details
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex flex-column justify-content-center">
                                    <div className="input-fields-main ">
                                      <div className="grid w-full justify-content-center">
                                        <div
                                          className={
                                            state &&
                                            stateWithCounty.includes(
                                              state.stateFullDesc
                                            )
                                              ? "col-4"
                                              : "col-6"
                                          }
                                        >
                                          <div className=" justify-content-center dialog-form-field-space w-full">
                                            <div className="field">
                                              <label
                                                htmlFor=""
                                                className="form-label font-fam-for-all"
                                              >
                                                Select Entity Type
                                                <span className="form-field-mandatory">
                                                  *
                                                </span>
                                              </label>
                                              <span className="p-float-label ">
                                                <Controller
                                                  name="entity"
                                                  control={control}
                                                  render={({ field }) => (
                                                    <Dropdown
                                                      value={enityName}
                                                      options={entityTypes}
                                                      onChange={(e) =>
                                                        setEntityName(e.value)
                                                      }
                                                      optionLabel="entityShortName"
                                                      placeholder="Select Entity Type"
                                                      resetFilterOnHide
                                                      disabled={isFirstDraft}
                                                      style={{
                                                        width: "100%",
                                                        height: "47px",
                                                      }}
                                                      filter
                                                      className="dialog-form-input-field-wizard"
                                                      required
                                                    />
                                                  )}
                                                />
                                              </span>
                                              <small
                                                id={`entity--error`}
                                                className="error-msg font-fam-for-all "
                                              >
                                                {entityErr}
                                              </small>
                                              {getFormErrorMessage("entity")}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={
                                            state &&
                                            stateWithCounty.includes(
                                              state.stateFullDesc
                                            )
                                              ? "col-4"
                                              : "col-6"
                                          }
                                        >
                                          <div className=" justify-content-center dialog-form-field-space w-full">
                                            <div className="field">
                                              <label
                                                htmlFor=""
                                                className="form-label font-fam-for-all"
                                              >
                                                State of Formation{" "}
                                                <span className="form-field-mandatory">
                                                  *
                                                </span>
                                              </label>
                                              <span className="p-float-label ">
                                                <Controller
                                                  name="state"
                                                  control={control}
                                                  render={({ field }) => (
                                                    <Dropdown
                                                      value={field.value}
                                                      id={field.name}
                                                      options={allState}
                                                      disabled={isFirstDraft}
                                                      resetFilterOnHide
                                                      onChange={(e) => {
                                                        field.onChange(e.value);
                                                        setState(e.value);
                                                      }}
                                                      optionLabel="stateFullDesc"
                                                      style={{
                                                        width: "100%",
                                                        height: "47px",
                                                      }}
                                                      placeholder="Select State"
                                                      filter
                                                      className="dialog-form-input-field-wizard"
                                                      required
                                                    />
                                                  )}
                                                />
                                              </span>
                                              <small
                                                id={`state--error`}
                                                className="error-msg font-fam-for-all "
                                              >
                                                {stateErr}
                                              </small>
                                              {getFormErrorMessage("state")}
                                            </div>
                                          </div>
                                        </div>

                                        {state &&
                                        stateWithCounty.includes(
                                          state.stateFullDesc
                                        ) ? (
                                          <div className="col-4">
                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                              <div className="field">
                                                <label
                                                  htmlFor=""
                                                  className="form-label font-fam-for-all"
                                                >
                                                  County
                                                  <span className="form-field-mandatory">
                                                    *
                                                  </span>
                                                </label>
                                                <span className="p-float-label ">
                                                  <Controller
                                                    name="county"
                                                    control={control}
                                                    render={({ field }) => (
                                                      <Dropdown
                                                        value={field.value}
                                                        id={field.name}
                                                        options={allCounty}
                                                        disabled={isFirstDraft}
                                                        resetFilterOnHide
                                                        onChange={(e) => {
                                                          field.onChange(
                                                            e.value
                                                          );
                                                          setCounty(e.value);
                                                        }}
                                                        optionLabel="countyName"
                                                        style={{
                                                          width: "100%",
                                                          height: "47px",
                                                        }}
                                                        placeholder="Select County"
                                                        filter
                                                        className="dialog-form-input-field-wizard"
                                                      />
                                                    )}
                                                  />
                                                </span>
                                                <small
                                                  id={`county--error`}
                                                  className="error-msg font-fam-for-all "
                                                >
                                                  {countyErr}
                                                </small>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      className="wizard-content-sub-card-header my-3"
                                      id="header"
                                    >
                                      <div className="wizard-content-sub-card-text-frame">
                                        <div className="wizard-content-sub-card-text">
                                          Choose your preferred services from
                                          the options below
                                        </div>
                                      </div>
                                    </div>

                                    {servicesData.map((serviceData, index) => {
                                      const { product } = serviceData;

                                      const isSelected = (
                                        selectedProducts[
                                          product.orderTypeMaster
                                        ] || []
                                      ).includes(product.displayName);

                                      return (
                                        <div
                                          key={index}
                                          className="wizard-card-checkbox-container hover-card slide-in"
                                        >
                                          <div className="wizard-card-checkbox-main pb-0">
                                            <div className="wizard-card-checkbox-text-main">
                                              <div className="wizard-card-checkbox-action-container">
                                                <Checkbox
                                                  className="mt-2"
                                                  disabled={
                                                    serviceData?.product
                                                      ?.orderTypeMaster ===
                                                      orderTypeId &&
                                                    serviceData?.product
                                                      ?.orderTypeMaster !==
                                                      18 &&
                                                    serviceData?.product
                                                      ?.orderTypeMaster !== 21
                                                  }
                                                  checked={
                                                    isSelected ||
                                                    (serviceData?.product
                                                      ?.orderTypeMaster ===
                                                      orderTypeId &&
                                                      serviceData?.product
                                                        ?.orderTypeMaster !==
                                                        18 &&
                                                      serviceData?.product
                                                        ?.orderTypeMaster !==
                                                        21)
                                                  }
                                                  onChange={(e) =>
                                                    handleCheckedChange(
                                                      e,
                                                      serviceData
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="w-full grid">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-11 p-3">
                                                  <div className="wizard-card-checkbox-text1 font-fam-for-all text-start">
                                                    <div className="flex align-items-center">
                                                      {product.displayName}
                                                      <button
                                                        className="ml-2 bg-transparent border-none cursor-pointer"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="right"
                                                        title={
                                                          product.productMaster
                                                            .productAdditionalInfo
                                                        }
                                                      >
                                                        <i
                                                          className="pi pi-info-circle"
                                                          style={{
                                                            fontSize: "1.2rem",
                                                            color: "#007bff",
                                                          }}
                                                        ></i>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="wizard-card-checkbox-text2 justify-content-end col-12 col-sm-12 col-md-12 col-lg-1 flex align-items-center">
                                                  {product.isCustomPriceApplicable
                                                    ? "Custom Price"
                                                    : product.isPriceIncludedInBaseProduct
                                                    ? "Included"
                                                    : `$${product.unitPrice}`}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>

                                {Object.keys(groupedData).map((key, i) => (
                                  <div className="tab px-4" key={i}>
                                    <div
                                      className="wizard-content-sub-card-header my-3"
                                      id="header"
                                    >
                                      <div className="wizard-content-sub-card-text-frame">
                                        <div className="wizard-content-sub-card-text">
                                          {toPascalCase(key)}
                                        </div>
                                      </div>
                                    </div>

                                    <div>
                                      {i === 0 &&
                                      orderTypeId !== 5 &&
                                      orderTypeId !== 18 ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Company
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="p-float-label ">
                                                    <Controller
                                                      name="orgaineriInfoId"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={adminCompany}
                                                          options={
                                                            allAdminCompany
                                                          }
                                                          resetFilterOnHide
                                                          onChange={(e) =>
                                                            setAdminCompany(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="companyName"
                                                          placeholder="Select Company Name"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Company"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  setVisible9(true)
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div></div>
                                        </>
                                      )}
                                      {key === "Organizer_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Organizer
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="p-float-label ">
                                                    <Controller
                                                      name="orgaineriInfoId"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={organizerData}
                                                          options={allOrganizer}
                                                          resetFilterOnHide
                                                          onChange={(e) =>
                                                            setOrganizerData(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Entity Type"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 justify-content-end align-items-center">
                                              <button
                                                className="datatable-highlight-color"
                                                onClick={() =>
                                                  handleKeyPersonal("owners")
                                                }
                                              >
                                                <div className="flex align-items-center webapp-global-sm-size">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                  >
                                                    <path
                                                      d="M10.0003 4.16669V15.8334M4.16699 10H15.8337"
                                                      stroke="#004EEC"
                                                      stroke-width="2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                  </svg>
                                                  <div className="font-medium">
                                                    Add Member
                                                  </div>
                                                </div>
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div></div>
                                        </>
                                      )}
                                      {key === "Principle_Address" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Principal Address
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="p-float-label ">
                                                    <Controller
                                                      name="principalAddress"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={location}
                                                          options={allLocation}
                                                          resetFilterOnHide
                                                          onChange={(e) =>
                                                            setLocation(e.value)
                                                          }
                                                          optionLabel="addressLine1"
                                                          placeholder="Select Address"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-end align-items-center">
                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("owners")}>
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Member</div>
                                                                                                </div>

                                                                                            </button> */}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Incorporator_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select InCorporator
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="p-float-label ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={incorporator}
                                                          options={
                                                            allInCorporator
                                                          }
                                                          resetFilterOnHide
                                                          onChange={(e) =>
                                                            setIncorporator(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Incorporator"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 justify-content-end align-items-center">
                                              <button
                                                className="datatable-highlight-color"
                                                onClick={() =>
                                                  handleKeyPersonal("owners")
                                                }
                                              >
                                                <div className="flex align-items-center webapp-global-sm-size">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                  >
                                                    <path
                                                      d="M10.0003 4.16669V15.8334M4.16699 10H15.8337"
                                                      stroke="#004EEC"
                                                      stroke-width="2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                  </svg>
                                                  <div className="font-medium">
                                                    Add Member
                                                  </div>
                                                </div>
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {key === "Registered_Agent" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                                              <div className="flex justify-content-start mb-3 ml-1">
                                                <Checkbox
                                                  checked={IsCheckedAgent}
                                                  onChange={(e) =>
                                                    setIsCheckedAgent(e.checked)
                                                  }
                                                  inputProps={{
                                                    "aria-label": "controlled",
                                                  }}
                                                />

                                                <label
                                                  htmlFor="binary"
                                                  className="font-fam-for-all ml-2 srch-cand-text"
                                                >
                                                  Select if registered agent is
                                                  vState User
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 justify-content-end align-items-center">
                                              {key === "Registered_Agent" ? (
                                                <Button
                                                  type="button"
                                                  id="nextBtn"
                                                  className="mt-2   vstate-reverse-button font-fam-for-all text-center  justify-content-center"
                                                  onClick={() => nextPrev(1)}
                                                >
                                                  Skip
                                                </Button>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    {groupedData[key].mode &&
                                    groupedData[key].mode.repeat === true ? (
                                      <div>
                                        <div className="">
                                          <div className="col-12 p-0">
                                            <h2 className="entity-header-strapi">
                                              {toPascalCase(
                                                groupedData[key].form[0].schema
                                                  .displayName
                                              )}
                                            </h2>
                                            {groupedData[key].mode &&
                                              groupedData[key].mode.repeat ===
                                                true}
                                          </div>

                                          {Array.from({
                                            length: formCount,
                                          }).map((_, l) => (
                                            <div className="vstate-card p-4 mb-3">
                                              {key === "ucc_Debtor_Details" ? (
                                                <>
                                                  <div className="grid w-full justify-content-between">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                      <div className=" justify-content-center dialog-form-field-space w-full">
                                                        <div className="field">
                                                          <label
                                                            htmlFor=""
                                                            className="form-label font-fam-for-all"
                                                          >
                                                            Select Debtor
                                                            <span className="form-field-mandatory"></span>
                                                          </label>
                                                          <span className="p-float-label ">
                                                            <Controller
                                                              name={`ucc_Debtor_Details_Dropdown-${l}`}
                                                              control={control}
                                                              render={({
                                                                field,
                                                              }) => (
                                                                <Dropdown
                                                                  value={
                                                                    field.value
                                                                  }
                                                                  options={
                                                                    aallDebtor
                                                                  }
                                                                  resetFilterOnHide
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    field.onChange(
                                                                      e.value
                                                                    );
                                                                    setDebtorData(
                                                                      e.value
                                                                    );
                                                                    handleDebtorChange(
                                                                      e.value,
                                                                      l
                                                                    );
                                                                  }}
                                                                  optionLabel="keyPersonnelName"
                                                                  placeholder="Select Debtor"
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                    height:
                                                                      "47px",
                                                                  }}
                                                                  filter
                                                                  className="dialog-form-input-field-wizard"
                                                                  required
                                                                />
                                                              )}
                                                            />
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                      {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                        </svg>
                                                                                                                        <div className='font-medium'>Add Debtor</div>
                                                                                                                    </div>

                                                                                                                </button> */}
                                                      <Button
                                                        type="button"
                                                        icon="pi pi-plus"
                                                        style={{
                                                          fontSize: "14px",
                                                        }}
                                                        label="Add Debtor"
                                                        className=" vstate-button font-fam-for-all text-center mt-4"
                                                        onClick={() =>
                                                          handleKeyPersonal(
                                                            "DEBTOR"
                                                          )
                                                        }
                                                      ></Button>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}

                                              {key ===
                                              "Company_Applicant_Information" ? (
                                                <>
                                                  <div className="grid w-full justify-content-between">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                      <div className=" justify-content-center dialog-form-field-space w-full">
                                                        <div className="field">
                                                          <label
                                                            htmlFor=""
                                                            className="form-label font-fam-for-all"
                                                          >
                                                            Select Company
                                                            Applicant
                                                            <span className="form-field-mandatory"></span>
                                                          </label>
                                                          <span className="p-float-label ">
                                                            <Controller
                                                              name={`company_applicant_item_${l}`}
                                                              control={control}
                                                              render={({
                                                                field,
                                                              }) => (
                                                                <Dropdown
                                                                  value={
                                                                    field.value
                                                                  }
                                                                  options={
                                                                    allCompanyApplicant
                                                                  }
                                                                  resetFilterOnHide
                                                                  onChange={(
                                                                    e
                                                                  ) => {
                                                                    field.onChange(
                                                                      e.value
                                                                    );

                                                                    handleCompanyApplicantChange(
                                                                      e.value,
                                                                      l
                                                                    );
                                                                  }}
                                                                  optionLabel="firstName"
                                                                  placeholder="Select Company Applicant"
                                                                  style={{
                                                                    width:
                                                                      "100%",
                                                                    height:
                                                                      "47px",
                                                                  }}
                                                                  filter
                                                                  className="dialog-form-input-field-wizard"
                                                                  required
                                                                />
                                                              )}
                                                            />
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                      {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                        </svg>
                                                                                                                        <div className='font-medium'>Add Debtor</div>
                                                                                                                    </div>

                                                                                                                </button> */}
                                                      <Button
                                                        type="button"
                                                        icon="pi pi-plus"
                                                        style={{
                                                          fontSize: "14px",
                                                        }}
                                                        label="Add Company Applicant"
                                                        className=" vstate-button font-fam-for-all text-center mt-4"
                                                        onClick={() =>
                                                          handleBOIKeyPersonal(
                                                            "COMPANY APPLICANT"
                                                          )
                                                        }
                                                      ></Button>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}

                                              {key ===
                                              "ucc_Secured_Party_Details" ? (
                                                <div className="grid w-full justify-content-between">
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                    <div className=" justify-content-center dialog-form-field-space w-full">
                                                      <div className="field">
                                                        <label
                                                          htmlFor=""
                                                          className="form-label font-fam-for-all"
                                                        >
                                                          Select SecuredParty
                                                          <span className="form-field-mandatory"></span>
                                                        </label>
                                                        <span className="p-float-label ">
                                                          <Controller
                                                            name={`uccAmend_Additional_secured_party_Details-${l}`}
                                                            control={control}
                                                            render={({
                                                              field,
                                                            }) => (
                                                              <Dropdown
                                                                value={
                                                                  field.value
                                                                }
                                                                options={
                                                                  allSecuredParty
                                                                }
                                                                resetFilterOnHide
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  field.onChange(
                                                                    e.value
                                                                  );

                                                                  handleAdditionalSecuredPartyChange1(
                                                                    e.value,
                                                                    l
                                                                  );
                                                                }}
                                                                optionLabel="keyPersonnelName"
                                                                placeholder="Select Secured Party"
                                                                style={{
                                                                  width: "100%",
                                                                  height:
                                                                    "47px",
                                                                }}
                                                                filter
                                                                className="dialog-form-input-field-wizard"
                                                                required
                                                              />
                                                            )}
                                                          />
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                    {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("SECUERED_PARTY")}>
                                                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                </svg>
                                                                                                                <div className='font-medium'>Add Additional Secured Party</div>
                                                                                                            </div>

                                                                                                        </button> */}
                                                    <Button
                                                      type="button"
                                                      icon="pi pi-plus"
                                                      style={{
                                                        fontSize: "14px",
                                                      }}
                                                      label="Add  Secured Party"
                                                      className=" vstate-button font-fam-for-all text-center mt-4"
                                                      onClick={() =>
                                                        handleKeyPersonal(
                                                          "SECUERED PARTY"
                                                        )
                                                      }
                                                    ></Button>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}

                                              {groupedData[key].form.map(
                                                (value, j) => (
                                                  <>
                                                    <div>
                                                      {getValues(
                                                        "uccAmend_What_are_we_amending"
                                                      ) ===
                                                        "ADDITIONAL DEBTOR" &&
                                                      value.apiId ===
                                                        "ucc-amnd-additional-debtor" ? (
                                                        <></>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {getValues(
                                                        "COC_What_Are_We_Changing"
                                                      ) === "Address" &&
                                                      value.apiId ===
                                                        "address" ? (
                                                        <div className="vstate-card p-4 mb-3">
                                                          <div
                                                            className="grid"
                                                            key={l}
                                                          >
                                                            {Object.keys(
                                                              value.schema
                                                                .attributes
                                                            ).map((val, k) => {
                                                              const content =
                                                                value.schema
                                                                  .attributes[
                                                                  val
                                                                ].type !==
                                                                undefined
                                                                  ? renderFormField(
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ],
                                                                      val,
                                                                      `${val}-${l}`,
                                                                      value,
                                                                      key
                                                                    )
                                                                  : null;

                                                              return content ? (
                                                                <div
                                                                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                  key={k}
                                                                >
                                                                  {content}
                                                                </div>
                                                              ) : null;
                                                            })}
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {getValues(
                                                        `boi_Exempt_Entity-${l}`
                                                      ) === undefined &&
                                                      value.apiId !==
                                                        "address" &&
                                                      value.apiId !==
                                                        "boi-beneficial-owwner-details" &&
                                                      value.apiId !==
                                                        "boi-except-entity-form" &&
                                                      value.apiId !==
                                                        "boi-beneficial-owner-form" &&
                                                      value.apiId !==
                                                        "ucc-amnd-additional-debtor" ? (
                                                        <div className="vstate-card p-4 mb-3">
                                                          <div
                                                            className="grid"
                                                            key={l}
                                                          >
                                                            {Object.keys(
                                                              value.schema
                                                                .attributes
                                                            ).map((val, k) => {
                                                              const content =
                                                                value.schema
                                                                  .attributes[
                                                                  val
                                                                ].type !==
                                                                undefined
                                                                  ? renderFormField(
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ],
                                                                      val,
                                                                      `${val}-${l}`,
                                                                      value,
                                                                      key
                                                                    )
                                                                  : null;

                                                              return content ? (
                                                                <div
                                                                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                  key={k}
                                                                >
                                                                  {content}
                                                                </div>
                                                              ) : null;
                                                            })}
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {getValues(
                                                        `boi_Exempt_Entity-${l}`
                                                      ) === undefined &&
                                                      value.apiId ===
                                                        "address" &&
                                                      value.apiId !==
                                                        "boi-beneficial-owwner-details" &&
                                                      value.apiId !==
                                                        "boi-except-entity-form" &&
                                                      value.apiId !==
                                                        "boi-beneficial-owner-form" ? (
                                                        <div className="vstate-card p-4 mb-3">
                                                          <div
                                                            className="grid"
                                                            key={l}
                                                          >
                                                            {Object.keys(
                                                              value.schema
                                                                .attributes
                                                            ).map((val, k) => {
                                                              const content =
                                                                value.schema
                                                                  .attributes[
                                                                  val
                                                                ].type !==
                                                                undefined
                                                                  ? renderFormField(
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ],
                                                                      val,
                                                                      `${val}-${l}`,
                                                                      value,
                                                                      key
                                                                    )
                                                                  : null;

                                                              return content ? (
                                                                <div
                                                                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                  key={k}
                                                                >
                                                                  {content}
                                                                </div>
                                                              ) : null;
                                                            })}
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {value.apiId ===
                                                      "beneficial-owner-info" ? (
                                                        <>
                                                          <div className="grid w-full justify-content-between">
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                                <div className="field">
                                                                  <label
                                                                    htmlFor=""
                                                                    className="form-label font-fam-for-all"
                                                                  >
                                                                    Select
                                                                    Beneficial
                                                                    Owner
                                                                    <span className="form-field-mandatory"></span>
                                                                  </label>
                                                                  <span className="p-float-label ">
                                                                    <Controller
                                                                      name={`ucc_Beneficial_Dropdown-${l}`}
                                                                      control={
                                                                        control
                                                                      }
                                                                      render={({
                                                                        field,
                                                                      }) => (
                                                                        <Dropdown
                                                                          value={
                                                                            field.value
                                                                          }
                                                                          options={
                                                                            allBeneficialOwner
                                                                          }
                                                                          resetFilterOnHide
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            field.onChange(
                                                                              e.value
                                                                            );
                                                                            handleBeneficialOwnerChange(
                                                                              e.value,
                                                                              l
                                                                            );
                                                                          }}
                                                                          optionLabel="firstName"
                                                                          placeholder="Select Beneficial Owner"
                                                                          style={{
                                                                            width:
                                                                              "100%",
                                                                            height:
                                                                              "47px",
                                                                          }}
                                                                          filter
                                                                          className="dialog-form-input-field-wizard"
                                                                          required
                                                                        />
                                                                      )}
                                                                    />
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                              <Button
                                                                type="button"
                                                                icon="pi pi-plus"
                                                                style={{
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                                label="Add Beneficial Owner"
                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                onClick={() =>
                                                                  handleBOIKeyPersonal(
                                                                    "BENEFICIAL OWNER"
                                                                  )
                                                                }
                                                              ></Button>
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {value.apiId ===
                                                        "beneficial-owner-info" &&
                                                      value.apiId !==
                                                        "upload-documents" &&
                                                      value.apiId !==
                                                        "boi-except-entity-form" &&
                                                      value.apiId !==
                                                        "beneficial-owner-details" &&
                                                      value.apiId !==
                                                        "address" ? (
                                                        <div className="vstate-card p-4 mb-3">
                                                          <div
                                                            className="grid"
                                                            key={l}
                                                          >
                                                            {Object.keys(
                                                              value.schema
                                                                .attributes
                                                            ).map((val, k) => {
                                                              const content =
                                                                value.schema
                                                                  .attributes[
                                                                  val
                                                                ].type !==
                                                                undefined
                                                                  ? renderFormField(
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ],
                                                                      val,
                                                                      `${val}-${l}`,
                                                                      value,
                                                                      key
                                                                    )
                                                                  : null;

                                                              return content ? (
                                                                <div
                                                                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                  key={k}
                                                                >
                                                                  {content}
                                                                </div>
                                                              ) : null;
                                                            })}
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {getValues(
                                                        `boica_Have_a_FinCEN_ID-${l}`
                                                      ) === false &&
                                                      (value.apiId ===
                                                        "boica-fin-cen-id" ||
                                                        value.apiId ===
                                                          "company-applicant-det" ||
                                                        value.apiId ===
                                                          "cad-address" ||
                                                        value.apiId ===
                                                          "boica-upload-document") ? (
                                                        <div className="vstate-card p-4 mb-3">
                                                          <div
                                                            className="grid"
                                                            key={l}
                                                          >
                                                            {Object.keys(
                                                              value.schema
                                                                .attributes
                                                            ).map((val, k) => {
                                                              const content =
                                                                value.schema
                                                                  .attributes[
                                                                  val
                                                                ].type !==
                                                                undefined
                                                                  ? renderFormField(
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ],
                                                                      val,
                                                                      `${val}-${l}`,
                                                                      value,
                                                                      key
                                                                    )
                                                                  : null;

                                                              return content ? (
                                                                <div
                                                                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                  key={k}
                                                                >
                                                                  {content}
                                                                </div>
                                                              ) : null;
                                                            })}
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {getValues(
                                                        `boica_Have_a_FinCEN_ID-${l}`
                                                      ) === true &&
                                                      (value.apiId ===
                                                        "boica-fin-cen-id" ||
                                                        value.apiId ===
                                                          "company-applicant-det") ? (
                                                        <div className="vstate-card p-4 mb-3">
                                                          <div
                                                            className="grid"
                                                            key={l}
                                                          >
                                                            {Object.keys(
                                                              value.schema
                                                                .attributes
                                                            ).map((val, k) => {
                                                              const content =
                                                                value.schema
                                                                  .attributes[
                                                                  val
                                                                ].type !==
                                                                undefined
                                                                  ? renderFormField(
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ],
                                                                      val,
                                                                      `${val}-${l}`,
                                                                      value,
                                                                      key
                                                                    )
                                                                  : null;

                                                              return content ? (
                                                                <div
                                                                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                  key={k}
                                                                >
                                                                  {content}
                                                                </div>
                                                              ) : null;
                                                            })}
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {/* boi-beneficial-owwner-details for Exempt Entity true */}

                                                      {getValues(
                                                        `boi_Exempt_Entity-${l}`
                                                      ) === false &&
                                                      getValues(
                                                        `boi_Have_a_FinCEN_ID-${l}`
                                                      ) === false &&
                                                      (value.apiId ===
                                                        "beneficial-owner-details" ||
                                                        value.apiId ===
                                                          "upload-documents" ||
                                                        value.apiId ===
                                                          "address" ||
                                                        value.apiId ===
                                                          "fin-cen-id-details") ? (
                                                        <>
                                                          <div className="vstate-card p-4 mb-3">
                                                            <div
                                                              className="grid"
                                                              key={j}
                                                            >
                                                              <div className="col-12 pb-0">
                                                                <h2 className="entity-header-strapi">
                                                                  {toPascalCase(
                                                                    value.schema
                                                                      .displayName
                                                                  )}
                                                                </h2>
                                                              </div>

                                                              {Object.keys(
                                                                value.schema
                                                                  .attributes
                                                              ).map(
                                                                (val, k) => (
                                                                  <div
                                                                    className={`col-12 col-sm-12 col-md-12 col-lg-6 dummy ${
                                                                      !renderFormField(
                                                                        value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ],
                                                                        val,
                                                                        `${val}-${l}`,
                                                                        value,
                                                                        key
                                                                      )
                                                                        ? "hidden"
                                                                        : ""
                                                                    }`}
                                                                    key={k}
                                                                  >
                                                                    {value
                                                                      .schema
                                                                      .attributes[
                                                                      val
                                                                    ].type !==
                                                                    undefined
                                                                      ? renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                            val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                      : ""}
                                                                  </div>
                                                                )
                                                              )}
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {getValues(
                                                        `boi_Exempt_Entity-${l}`
                                                      ) === false &&
                                                      getValues(
                                                        `boi_Have_a_FinCEN_ID-${l}`
                                                      ) === true &&
                                                      (value.apiId ===
                                                        "beneficial-owner-details" ||
                                                        value.apiId ===
                                                          "fin-cen-id-details") ? (
                                                        <>
                                                          <div className="vstate-card p-4 mb-3">
                                                            <div
                                                              className="grid"
                                                              key={j}
                                                            >
                                                              <div className="col-12 pb-0">
                                                                <h2 className="entity-header-strapi">
                                                                  {toPascalCase(
                                                                    value.schema
                                                                      .displayName
                                                                  )}
                                                                </h2>
                                                              </div>

                                                              {Object.keys(
                                                                value.schema
                                                                  .attributes
                                                              ).map(
                                                                (val, k) => (
                                                                  <div
                                                                    className={`col-12 col-sm-12 col-md-12 col-lg-6 dummy ${
                                                                      !renderFormField(
                                                                        value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ],
                                                                        val,
                                                                        `${val}-${l}`,
                                                                        value,
                                                                        key
                                                                      )
                                                                        ? "hidden"
                                                                        : ""
                                                                    }`}
                                                                    key={k}
                                                                  >
                                                                    {value
                                                                      .schema
                                                                      .attributes[
                                                                      val
                                                                    ].type !==
                                                                    undefined
                                                                      ? renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                            val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                      : ""}
                                                                  </div>
                                                                )
                                                              )}
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {/* boi-beneficial-owwner-details for Exempt Entity false */}
                                                      {getValues(
                                                        `boi_Exempt_Entity-${l}`
                                                      ) &&
                                                      value.apiId ===
                                                        "boi-except-entity-form" ? (
                                                        <>
                                                          <div className="vstate-card p-4 mb-3">
                                                            <div
                                                              className="grid"
                                                              key={j}
                                                            >
                                                              <div className="col-12 pb-0">
                                                                <h2 className="entity-header-strapi">
                                                                  {toPascalCase(
                                                                    value.schema
                                                                      .displayName
                                                                  )}
                                                                </h2>
                                                              </div>
                                                              {Object.keys(
                                                                value.schema
                                                                  .attributes
                                                              ).map(
                                                                (val, k) => (
                                                                  <div
                                                                    className={`col-12 col-sm-12 col-md-12 col-lg-6 dummy ${
                                                                      !renderFormField(
                                                                        value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ],
                                                                        val,
                                                                        `${val}-${l}`,
                                                                        value,
                                                                        key
                                                                      )
                                                                        ? "hidden"
                                                                        : ""
                                                                    }`}
                                                                    key={k}
                                                                  >
                                                                    {value
                                                                      .schema
                                                                      .attributes[
                                                                      val
                                                                    ].type !==
                                                                    undefined
                                                                      ? renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                            val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                      : ""}
                                                                  </div>
                                                                )
                                                              )}
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {getValues(
                                                        "uccAmend_What_are_we_amending"
                                                      ) ===
                                                        "ADDITIONAL DEBTOR" &&
                                                      value.apiId ===
                                                        "ucc-amnd-additional-debtor" ? (
                                                        <>
                                                          <div className="vstate-card p-4 mb-3">
                                                            <div
                                                              className="grid"
                                                              key={j}
                                                            >
                                                              <div className="col-12 pb-0">
                                                                <h2 className="entity-header-strapi">
                                                                  {toPascalCase(
                                                                    value.schema
                                                                      .displayName
                                                                  )}
                                                                </h2>
                                                              </div>
                                                              <div className="grid w-full justify-content-between">
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                                  <div className=" justify-content-center dialog-form-field-space w-full">
                                                                    <div className="field">
                                                                      <label
                                                                        htmlFor=""
                                                                        className="form-label font-fam-for-all"
                                                                      >
                                                                        Select
                                                                        Additional
                                                                        Debtor
                                                                        <span className="form-field-mandatory"></span>
                                                                      </label>
                                                                      <span className="p-float-label ">
                                                                        <Controller
                                                                          name={`uccAmend_Additional_Debtor_Details-${l}`}
                                                                          control={
                                                                            control
                                                                          }
                                                                          render={({
                                                                            field,
                                                                          }) => (
                                                                            <Dropdown
                                                                              value={
                                                                                field.value
                                                                              }
                                                                              options={
                                                                                aallDebtor
                                                                              }
                                                                              resetFilterOnHide
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                field.onChange(
                                                                                  e.value
                                                                                );

                                                                                handleAdditionalDebtorChange(
                                                                                  e.value,
                                                                                  l
                                                                                );
                                                                              }}
                                                                              optionLabel="keyPersonnelName"
                                                                              placeholder="Select Debtor"
                                                                              style={{
                                                                                width:
                                                                                  "100%",
                                                                                height:
                                                                                  "47px",
                                                                              }}
                                                                              filter
                                                                              className="dialog-form-input-field-wizard"
                                                                              required
                                                                            />
                                                                          )}
                                                                        />
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                                  {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                                        </svg>
                                                                                                                                                        <div className='font-medium'>Add Additional Debtor</div>
                                                                                                                                                    </div>

                                                                                                                                                </button> */}
                                                                  <Button
                                                                    type="button"
                                                                    icon="pi pi-plus"
                                                                    style={{
                                                                      fontSize:
                                                                        "14px",
                                                                    }}
                                                                    label="Add Additionaal Debtor"
                                                                    className=" vstate-button font-fam-for-all text-center mt-4"
                                                                    onClick={() =>
                                                                      handleKeyPersonal(
                                                                        "DEBTOR"
                                                                      )
                                                                    }
                                                                  ></Button>
                                                                </div>
                                                              </div>
                                                              {Object.keys(
                                                                value.schema
                                                                  .attributes
                                                              ).map(
                                                                (val, k) => (
                                                                  <div
                                                                    className={`col-12 col-sm-12 col-md-12 col-lg-6 dummy ${
                                                                      !renderFormField(
                                                                        value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ],
                                                                        val,
                                                                        `${val}-${l}`,
                                                                        value,
                                                                        key
                                                                      )
                                                                        ? "hidden"
                                                                        : ""
                                                                    }`}
                                                                    key={k}
                                                                  >
                                                                    {value
                                                                      .schema
                                                                      .attributes[
                                                                      val
                                                                    ].type !==
                                                                    undefined
                                                                      ? renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                            val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                      : ""}
                                                                  </div>
                                                                )
                                                              )}
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {getValues(
                                                        "uccAmend_What_are_we_amending"
                                                      ) ===
                                                        "ADDITIONAL SECURED" &&
                                                      value.apiId ===
                                                        "ucc-amend-additional-secured-party-details" ? (
                                                        <>
                                                          <div className="vstate-card p-4 mb-3">
                                                            <div
                                                              className="grid"
                                                              key={j}
                                                            >
                                                              <div className="col-12 pb-0">
                                                                <h2 className="entity-header-strapi">
                                                                  {toPascalCase(
                                                                    value.schema
                                                                      .displayName
                                                                  )}
                                                                </h2>
                                                              </div>
                                                              {/* {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                                            <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                                                {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) : ""}
                                                                                                                                            </div>
                                                                                                                                        ))} */}
                                                              {Object.keys(
                                                                value.schema
                                                                  .attributes
                                                              ).map(
                                                                (val, k) => {
                                                                  const content =
                                                                    value.schema
                                                                      .attributes[
                                                                      val
                                                                    ].type !==
                                                                    undefined
                                                                      ? renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                            val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                      : null;

                                                                  return content ? (
                                                                    <div
                                                                      className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                      key={k}
                                                                    >
                                                                      {content}
                                                                    </div>
                                                                  ) : null;
                                                                }
                                                              )}
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                  </>
                                                )
                                              )}
                                            </div>
                                          ))}
                                        </div>

                                        {groupedData[key].mode &&
                                          groupedData[key].mode.repeat ===
                                            true && (
                                            <div className="flex gap-2 justify-content-center w-full">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                disabled={
                                                  groupedData[key].form.length >
                                                    0 &&
                                                  groupedData[key].form[0]
                                                    .apiId ===
                                                    "boi-company-applicant-details" &&
                                                  formCount >= 2
                                                }
                                                label="Add Entry"
                                                className="vstate-button font-fam-for-all text-center  justify-content-center"
                                                onClick={() =>
                                                  handleRepeatForm(key)
                                                }
                                              ></Button>
                                              <Button
                                                type="button"
                                                icon="pi pi-minus"
                                                disabled={formCount === 1}
                                                label="Remove Entry"
                                                className="vstate-button font-fam-for-all text-center  justify-content-center"
                                                onClick={() =>
                                                  handleRemoveRepeatForm(key)
                                                }
                                              ></Button>
                                            </div>
                                          )}
                                      </div>
                                    ) : (
                                      groupedData[key].form.map((value, j) => (
                                        <>
                                          {getValues(
                                            "uccAmend_What_are_we_amending"
                                          ) === "ADDITIONAL DEBTOR" &&
                                          value.apiId ===
                                            "ucc-amnd-additional-debtor" &&
                                          value.apiId !==
                                            "uucAmnd-What-are-amending"
                                            ? Array.from({
                                                length: formCount,
                                              }).map((_, l) => (
                                                <>
                                                  <div className="vstate-card p-4 mb-3">
                                                    <div
                                                      className="grid"
                                                      key={j}
                                                    >
                                                      <div className="col-12 pb-0">
                                                        <h2 className="entity-header-strapi">
                                                          {toPascalCase(
                                                            value.schema
                                                              .displayName
                                                          )}
                                                        </h2>
                                                      </div>
                                                      <div className="grid w-full justify-content-between">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                          <div className=" justify-content-center dialog-form-field-space w-full">
                                                            <div className="field">
                                                              <label
                                                                htmlFor=""
                                                                className="form-label font-fam-for-all"
                                                              >
                                                                Select
                                                                Additional
                                                                Debtor
                                                                <span className="form-field-mandatory"></span>
                                                              </label>
                                                              <span className="p-float-label ">
                                                                <Controller
                                                                  name={`uccAmend_Additional_Debtor_Details-${l}`}
                                                                  control={
                                                                    control
                                                                  }
                                                                  render={({
                                                                    field,
                                                                  }) => (
                                                                    <Dropdown
                                                                      value={
                                                                        field.value
                                                                      }
                                                                      options={
                                                                        aallDebtor
                                                                      }
                                                                      resetFilterOnHide
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        field.onChange(
                                                                          e.value
                                                                        );

                                                                        handleAdditionalDebtorChange(
                                                                          e.value,
                                                                          l
                                                                        );
                                                                      }}
                                                                      optionLabel="keyPersonnelName"
                                                                      placeholder="Select Debtor"
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        height:
                                                                          "47px",
                                                                      }}
                                                                      filter
                                                                      className="dialog-form-input-field-wizard"
                                                                      required
                                                                    />
                                                                  )}
                                                                />
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                          {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                        </svg>
                                                                                                                        <div className='font-medium'>Add Additional Debtor</div>
                                                                                                                    </div>

                                                                                                                </button> */}
                                                          <Button
                                                            type="button"
                                                            icon="pi pi-plus"
                                                            style={{
                                                              fontSize: "14px",
                                                            }}
                                                            label="Add Additional Debtor"
                                                            className=" vstate-button font-fam-for-all text-center mt-4"
                                                            onClick={() =>
                                                              handleKeyPersonal(
                                                                "DEBTOR"
                                                              )
                                                            }
                                                          ></Button>
                                                        </div>
                                                      </div>
                                                      {Object.keys(
                                                        value.schema.attributes
                                                      ).map((val, k) => (
                                                        <div
                                                          className={`col-12 col-sm-12 col-md-12 col-lg-6 dummy ${
                                                            !renderFormField(
                                                              value.schema
                                                                .attributes[
                                                                val
                                                              ],
                                                              val,
                                                              `${val}-${l}`,
                                                              value,
                                                              key
                                                            )
                                                              ? "hidden"
                                                              : ""
                                                          }`}
                                                          key={k}
                                                        >
                                                          {value.schema
                                                            .attributes[val]
                                                            .type !== undefined
                                                            ? renderFormField(
                                                                value.schema
                                                                  .attributes[
                                                                  val
                                                                ],
                                                                val,
                                                                `${val}-${l}`,
                                                                value,
                                                                key,
                                                                value.apiId
                                                              )
                                                            : ""}
                                                        </div>
                                                      ))}
                                                    </div>
                                                  </div>

                                                  <div className="flex gap-2 justify-content-center w-full">
                                                    <Button
                                                      type="button"
                                                      icon="pi pi-plus"
                                                      label="Add Entry"
                                                      className="vstate-button font-fam-for-all text-center  justify-content-center"
                                                      onClick={() =>
                                                        handleRepeatForm()
                                                      }
                                                    ></Button>
                                                    <Button
                                                      type="button"
                                                      icon="pi pi-minus"
                                                      disabled={formCount === 1}
                                                      label="Remove Entry"
                                                      className="vstate-button font-fam-for-all text-center  justify-content-center"
                                                      onClick={() =>
                                                        handleRemoveRepeatForm()
                                                      }
                                                    ></Button>
                                                  </div>
                                                </>
                                              ))
                                            : ""}

                                          {getValues(
                                            "uccAmend_What_are_we_amending"
                                          ) === "ADDITIONAL SECURED" &&
                                          value.apiId ===
                                            "ucc-amend-additional-secured-party-details" &&
                                          value.apiId !==
                                            "uucAmnd-What-are-amending"
                                            ? Array.from({
                                                length: formCount,
                                              }).map((_, l) => (
                                                <>
                                                  <div className="vstate-card p-4 mb-3">
                                                    <div
                                                      className="grid"
                                                      key={j}
                                                    >
                                                      <div className="col-12 pb-0">
                                                        <h2 className="entity-header-strapi">
                                                          {toPascalCase(
                                                            value.schema
                                                              .displayName
                                                          )}
                                                        </h2>
                                                      </div>
                                                      <div className="grid w-full justify-content-between">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                          <div className=" justify-content-center dialog-form-field-space w-full">
                                                            <div className="field">
                                                              <label
                                                                htmlFor=""
                                                                className="form-label font-fam-for-all"
                                                              >
                                                                Select
                                                                Additional
                                                                SecuredParty
                                                                <span className="form-field-mandatory"></span>
                                                              </label>
                                                              <span className="p-float-label ">
                                                                <Controller
                                                                  name={`uccAmend_Additional_secured_party_Details-${l}`}
                                                                  control={
                                                                    control
                                                                  }
                                                                  render={({
                                                                    field,
                                                                  }) => (
                                                                    <Dropdown
                                                                      value={
                                                                        field.value
                                                                      }
                                                                      options={
                                                                        allSecuredParty
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        field.onChange(
                                                                          e.value
                                                                        );

                                                                        handleAdditionalSecuredPartyChange(
                                                                          e.value,
                                                                          l
                                                                        );
                                                                      }}
                                                                      optionLabel="keyPersonnelName"
                                                                      placeholder="Select Secured Party"
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        height:
                                                                          "47px",
                                                                      }}
                                                                      filter
                                                                      className="dialog-form-input-field-wizard"
                                                                      required
                                                                    />
                                                                  )}
                                                                />
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                          {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("SECUERED_PARTY")}>
                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                        </svg>
                                                                                                                        <div className='font-medium'>Add Additional Secured Party</div>
                                                                                                                    </div>

                                                                                                                </button> */}
                                                          <Button
                                                            type="button"
                                                            icon="pi pi-plus"
                                                            style={{
                                                              fontSize: "14px",
                                                            }}
                                                            label="Add Additional Secured Party"
                                                            className=" vstate-button font-fam-for-all text-center mt-4"
                                                            onClick={() =>
                                                              handleKeyPersonal(
                                                                "SECUERED PARTY"
                                                              )
                                                            }
                                                          ></Button>
                                                        </div>
                                                      </div>
                                                      {/* {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                            <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) : ""}
                                                                                                            </div>
                                                                                                        ))} */}
                                                      {Object.keys(
                                                        value.schema.attributes
                                                      ).map((val, k) => {
                                                        const content =
                                                          value.schema
                                                            .attributes[val]
                                                            .type !== undefined
                                                            ? renderFormField(
                                                                value.schema
                                                                  .attributes[
                                                                  val
                                                                ],
                                                                val,
                                                                `${val}-${l}`,
                                                                value,
                                                                key,
                                                                value.apiId
                                                              )
                                                            : null;

                                                        return content ? (
                                                          <div
                                                            className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                            key={k}
                                                          >
                                                            {content}
                                                          </div>
                                                        ) : null;
                                                      })}
                                                    </div>
                                                  </div>

                                                  <div className="flex gap-2 justify-content-center w-full">
                                                    <Button
                                                      type="button"
                                                      icon="pi pi-plus"
                                                      label="Add Entry"
                                                      className="vstate-button font-fam-for-all text-center  justify-content-center"
                                                      onClick={() =>
                                                        handleRepeatForm()
                                                      }
                                                    ></Button>
                                                    <Button
                                                      type="button"
                                                      icon="pi pi-minus"
                                                      disabled={formCount === 1}
                                                      label="Remove Entry"
                                                      className="vstate-button font-fam-for-all text-center  justify-content-center"
                                                      onClick={() =>
                                                        handleRemoveRepeatForm()
                                                      }
                                                    ></Button>
                                                  </div>
                                                </>
                                              ))
                                            : ""}

                                          {getValues(
                                            "uccAmend_What_are_we_amending"
                                          ) === "ASSIGNMENT" &&
                                          value.apiId ===
                                            "ucc-assignee-details" &&
                                          value.apiId !==
                                            "uucAmnd-What-are-amending" ? (
                                            <div className="vstate-card p-4 mb-3">
                                              <div className="grid " key={j}>
                                                <div className="col-12 pb-0">
                                                  <h2 className="entity-header-strapi">
                                                    {toPascalCase(
                                                      value.schema.displayName
                                                    )}
                                                  </h2>
                                                </div>
                                                <div className="grid w-full justify-content-between">
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                    <div className=" justify-content-center dialog-form-field-space w-full">
                                                      <div className="field">
                                                        <label
                                                          htmlFor=""
                                                          className="form-label font-fam-for-all"
                                                        >
                                                          Select Assignee
                                                          <span className="form-field-mandatory"></span>
                                                        </label>
                                                        <span className="p-float-label ">
                                                          <Controller
                                                            name={`uccAmend_Additional_Debtor_Details`}
                                                            control={control}
                                                            render={({
                                                              field,
                                                            }) => (
                                                              <Dropdown
                                                                value={
                                                                  assigneeData
                                                                }
                                                                options={
                                                                  allAssignee
                                                                }
                                                                resetFilterOnHide
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  setAssigneeData(
                                                                    e.value
                                                                  );
                                                                }}
                                                                optionLabel="keyPersonnelName"
                                                                placeholder="Select Assignee"
                                                                style={{
                                                                  width: "100%",
                                                                  height:
                                                                    "47px",
                                                                }}
                                                                filter
                                                                className="dialog-form-input-field-wizard"
                                                                required
                                                              />
                                                            )}
                                                          />
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                    {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("ASSIGNEE")}>
                                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                    </svg>
                                                                                                                    <div className='font-medium'>Add Assignee</div>
                                                                                                                </div>

                                                                                                            </button> */}
                                                    <Button
                                                      type="button"
                                                      icon="pi pi-plus"
                                                      style={{
                                                        fontSize: "14px",
                                                      }}
                                                      label="Add Assignee"
                                                      className=" vstate-button font-fam-for-all text-center mt-4"
                                                      onClick={() =>
                                                        handleKeyPersonal(
                                                          "ASSIGNEE"
                                                        )
                                                      }
                                                    ></Button>
                                                  </div>
                                                </div>
                                                {/* {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                        <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                            {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, val, value, key) : ""}
                                                                                                        </div>
                                                                                                    ))} */}
                                                {Object.keys(
                                                  value.schema.attributes
                                                ).map((val, k) => {
                                                  const content =
                                                    value.schema.attributes[val]
                                                      .type !== undefined
                                                      ? renderFormField(
                                                          value.schema
                                                            .attributes[val],
                                                          val,
                                                          `${val}`,
                                                          value,
                                                          key,
                                                          value.apiId
                                                        )
                                                      : null;

                                                  return content ? (
                                                    <div
                                                      className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                      key={k}
                                                    >
                                                      {content}
                                                    </div>
                                                  ) : null;
                                                })}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {getValues(
                                            "COC_What_Are_We_Changing"
                                          ) === "Address" &&
                                          value.apiId ===
                                            "coc-please-include-new-address" ? (
                                            <div className="vstate-card p-4 mb-3">
                                              <div className="grid " key={j}>
                                                <div className="col-12 pb-0">
                                                  <h2 className="entity-header-strapi">
                                                    {toPascalCase(
                                                      value.schema.displayName
                                                    )}
                                                  </h2>
                                                </div>
                                                {/* {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                    {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, val, value, key) : ""}
                                                                                                </div>
                                                                                            ))} */}
                                                {Object.keys(
                                                  value.schema.attributes
                                                ).map((val, k) => {
                                                  const content =
                                                    value.schema.attributes[val]
                                                      .type !== undefined
                                                      ? renderFormField(
                                                          value.schema
                                                            .attributes[val],
                                                          val,
                                                          `${val}`,
                                                          value,
                                                          key
                                                        )
                                                      : null;

                                                  return content ? (
                                                    <div
                                                      className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                      key={k}
                                                    >
                                                      {content}
                                                    </div>
                                                  ) : null;
                                                })}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {getValues(
                                            "COC_What_Are_We_Changing"
                                          ) === "Registered Agent" &&
                                          value.apiId === "registered-agent" ? (
                                            <>
                                              <Sidebar
                                                position="right"
                                                visible={sidebarVisible}
                                                className="dialog-box"
                                                onHide={() =>
                                                  setSidebarVisible(false)
                                                }
                                              >
                                                <div className="vstate-card p-4 mb-3">
                                                  <div
                                                    className="grid custom-sidebar-header"
                                                    key={j}
                                                  >
                                                    <div className="col-12 pb-0">
                                                      <h2 className="entity-header-strapi">
                                                        {toPascalCase(
                                                          value.schema
                                                            .displayName
                                                        )}
                                                      </h2>
                                                    </div>
                                                    {/* {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                    {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, val, value, key) : ""}
                                                                                                                </div>
                                                                                                            ))} */}
                                                    {Object.keys(
                                                      value.schema.attributes
                                                    ).map((val, k) => {
                                                      const content =
                                                        value.schema.attributes[
                                                          val
                                                        ].type !== undefined
                                                          ? renderFormField(
                                                              value.schema
                                                                .attributes[
                                                                val
                                                              ],
                                                              val,
                                                              `${val}`,
                                                              value,
                                                              key
                                                            )
                                                          : null;

                                                      return content ? (
                                                        <div
                                                          className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                          key={k}
                                                        >
                                                          {content}
                                                        </div>
                                                      ) : null;
                                                    })}
                                                  </div>
                                                </div>
                                                <div className="flex justify-content-end dialog-form-md-group-Btn my-5">
                                                  <Button
                                                    className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
                                                    label="Submit"
                                                    style={{ height: "40px" }}
                                                    onClick={() =>
                                                      setSidebarVisible(false)
                                                    }
                                                  ></Button>
                                                </div>
                                              </Sidebar>
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {value.apiId !== "address" &&
                                          value.apiId !==
                                            "ucc-amnd-additional-debtor" &&
                                          value.apiId !==
                                            "ucc-amend-additional-secured-party-details" &&
                                          value.apiId !==
                                            "ucc-assignee-details" &&
                                          value.apiId !==
                                            "coc-please-include-new-address" ? (
                                            <div className="vstate-card p-4 mb-3">
                                              <div className="grid " key={j}>
                                                <div className="col-12 pb-0">
                                                  <h2 className="entity-header-strapi">
                                                    {toPascalCase(
                                                      value.schema.displayName
                                                    )}
                                                  </h2>
                                                </div>
                                                {/* {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                        <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                            {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, val, value, key) : ""}
                                                                                                        </div>
                                                                                                    ))} */}
                                                {Object.keys(
                                                  value.schema.attributes
                                                ).map((val, k) => {
                                                  const content =
                                                    value.schema.attributes[val]
                                                      .type !== undefined
                                                      ? renderFormField(
                                                          value.schema
                                                            .attributes[val],
                                                          val,
                                                          `${val}`,
                                                          value,
                                                          key,
                                                          value.schema
                                                            .displayName
                                                        )
                                                      : null;

                                                  return content ? (
                                                    <div
                                                      className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                      key={k}
                                                    >
                                                      {content}
                                                    </div>
                                                  ) : null;
                                                })}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {value.apiId === "address" ? (
                                            <div className="vstate-card p-4 mb-3">
                                              <div className="grid " key={j}>
                                                <div className="col-12 pb-0">
                                                  <h2 className="entity-header-strapi">
                                                    {toPascalCase(
                                                      value.schema.displayName
                                                    )}
                                                  </h2>
                                                </div>
                                                {/* {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                        <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                            {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, val, value, key) : ""}
                                                                                                        </div>
                                                                                                    ))} */}
                                                {Object.keys(
                                                  value.schema.attributes
                                                ).map((val, k) => {
                                                  const content =
                                                    value.schema.attributes[val]
                                                      .type !== undefined
                                                      ? renderFormField(
                                                          value.schema
                                                            .attributes[val],
                                                          val,
                                                          `${val}`,
                                                          value,
                                                          key
                                                        )
                                                      : null;

                                                  return content ? (
                                                    <div
                                                      className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                      key={k}
                                                    >
                                                      {content}
                                                    </div>
                                                  ) : null;
                                                })}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ))
                                    )}
                                  </div>
                                ))}

                                {inputFormData.length > 0 ? (
                                  <>
                                    <div className="tab px-4">
                                      <div
                                        className="wizard-content-sub-card-header mb-3"
                                        id="header"
                                      >
                                        <div className="wizard-content-sub-card-text-frame">
                                          <div className="wizard-content-sub-card-text">
                                            Contact Address
                                          </div>
                                        </div>
                                      </div>
                                      <div className="wizard-checkoust-container hover-card slide-in">
                                        <div className="wizard-checkout-main">
                                          <div className="grid px-4  w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Billing Address Contact
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="p-float-label ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          resetFilterOnHide
                                                          value={bilingAddress}
                                                          options={
                                                            allBilingAddress
                                                          }
                                                          onChange={(e) =>
                                                            setBilingAddress(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Billing Address"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("BILLING")} >
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Billing Address</div>
                                                                                                </div>

                                                                                            </button> */}
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Billing Information"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("BILLING")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          {/* <div className="wizard-checkout-body">
                                            <AddressTemplate
                                              data={bilingAddress}
                                            />
                                          </div> */}
                                          <div className="grid wizard-checkout-body w-full">
                                            {bilingAddress && (
                                              <KeyPersonnelIndividualCard
                                                data={bilingAddress}
                                                getState={getState}
                                                type={"Billing Address"}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div
                                                                                className="wizard-content-sub-card-header mb-3 mt-3"
                                                                                id="header"
                                                                            >
                                                                                <div className="wizard-content-sub-card-text-frame">
                                                                                    <div className="wizard-content-sub-card-text">
                                                                                        Shipping Address Contact
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizard-checkoust-container hover-card slide-in">
                                                                                <div className="wizard-checkout-main">
                                                                                    <div className='grid justify-content-start ml-4 mt-4 mb-3'>
                                                                                        <div className=' p-0'>
                                                                                            <div className='flex justify-content-center'>
                                                                                                <Checkbox
                                                                                                    checked={IsCheckedAgent}
                                                                                                    onChange={(e) => {
                                                                                                        setIsCheckedAgent(e.checked)
                                                                                                    }}
                                                                                                    inputProps={{ 'aria-label': 'controlled' }} />

                                                                                                <label htmlFor="binary" className='font-fam-for-all ml-2 srch-cand-text' >Same as Billing address
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="grid px-4  w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Shipping Address
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className="p-float-label ">
                                                                                                        <Controller
                                                                                                            name="incorporator"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={shippingAddress}
                                                                                                                    options={allShippingAddress}
                                                                                                                    onChange={(e) => setShippingAddress(e.value)}
                                                                                                                    disabled={IsCheckedAgent ? true : false}
                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                    placeholder="Select Billing Address"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex" >
                                                                                          
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Shipping Address"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => handleKeyPersonal("SHIPPING")}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className="wizard-checkout-body">
                                                                                        {IsCheckedAgent ? <AddressTemplate data={bilingAddress} /> : <AddressTemplate data={shippingAddress} />}
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                    </div>

                                    <div class="tab">
                                      <div
                                        className="wizard-content-sub-card-header mb-3"
                                        id="header"
                                      >
                                        <div className="wizard-content-sub-card-text-frame">
                                          <div className="wizard-content-sub-card-text">
                                            Preview
                                          </div>
                                        </div>
                                      </div>

                                      {/* {modelData1.map((val) => (
                                                                                <div className="col-12 card mt-4">
                                                                                    <div className="grid">
                                                                                        <div className="col-3"></div>
                                                                                        <div className="col-6 text-center table-cand">
                                                                                            {val.model}
                                                                                        </div>
                                                                                        <div className="col-3"></div>
                                                                                    </div>
                                                                                    {val.data !== null ? (
                                                                                        typeof val.data === "object" ? (
                                                                                            Array.isArray(val.data) ? (
                                                                                                val.data.map((item, index) => (
                                                                                                    <div className="grid mt-2" key={index}>
                                                                                                        {Object.keys(item).map((key, i) => (

                                                                                                            <React.Fragment key={i}>
                                                                                                                {
                                                                                                                    typeof (item[key]) === "string" ? <>
                                                                                                                        <div className="col-4 ml-4 cand-text table-cand">
                                                                                                                            {convertName(key)} :
                                                                                                                        </div>
                                                                                                                        <div className="col-7 cand-text">
                                                                                                                            {item[key]}
                                                                                                                        </div>
                                                                                                                    </> : ""
                                                                                                                }

                                                                                                            </React.Fragment>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                ))
                                                                                            ) : (
                                                                                                Object.keys(val.data).map((key, i) => (
                                                                                                    <div className="grid mt-2" key={i}>
                                                                                                        <div className="col-4 ml-4 cand-text table-cand">
                                                                                                            {convertName(key)} :
                                                                                                        </div>
                                                                                                        <div className="col-7 cand-text">
                                                                                                            {val.data[key]}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            )
                                                                                        ) : (
                                                                                            "Hello"
                                                                                        )
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                            ))} */}

                                      {/* {previewData?Object.keys(previewData).map((sectionKey, index) => (
                                                                                <div className="col-12 card mt-4" key={index}>
                                                                                    <div className="grid">
                                                                                        <div className="col-3"></div>
                                                                                        <div className="col-6 text-center table-cand">
                                                                                            {convertName(sectionKey)}
                                                                                        </div>
                                                                                        <div className="col-3"></div>
                                                                                    </div>
                                                                                    <div className="grid mt-2">
                                                                                        {Object.keys(previewData[sectionKey]).map((key, i) => (
                                                                                            <div className="col-12 grid mt-2" key={i}>
                                                                                                <div className="col-4 ml-4 cand-text table-cand">
                                                                                                    {convertName(key)}: 
                                                                                                </div>
                                                                                                <div className="col-7 cand-text">
                                                                                                    {previewData[sectionKey][key]} 
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                            )):<>
                                                                            <div>Data not found</div> */}

                                      <div className="flex p-4 justify-between font-fam-for-all">
                                        <div className="datatable-highlight-color">
                                          {enityName
                                            ? enityName.entityFullDesc
                                            : ""}
                                        </div>
                                        <div className="datatable-highlight-color">
                                          {state ? state.stateFullDesc : ""}
                                          <span className="ml-2">
                                            {county?.countyName}
                                          </span>
                                        </div>
                                      </div>

                                      {previewData ? (
                                        <div className="min-h-screen bg-gray-50  flex justify-center items-start">
                                          <div className="w-full  ">
                                            <div className="bg-white py-2">
                                              <div className="space-y-8">
                                                <div className="bg-white">
                                                  <div
                                                    className="px-4 py-2 rounded-t-lg"
                                                    style={{
                                                      backgroundColor:
                                                        "#F9FAFB",
                                                      fontFamily: "Inter",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    <h2 className="text-lg  text-gray-800">
                                                      Selected Services
                                                    </h2>
                                                  </div>

                                                  <div className="p-4 space-y-4">
                                                    {selectedCheckBoxData.map(
                                                      (service, index) => (
                                                        <div
                                                          key={index}
                                                          className="border grid border-gray-200 rounded-md p-2"
                                                        >
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                                                            <h3
                                                              className="text-md font-semibold"
                                                              style={{
                                                                fontFamily:
                                                                  "Inter",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            >
                                                              {
                                                                service.product
                                                                  .displayName
                                                              }
                                                            </h3>
                                                            <p
                                                              className="text-gray-700 mt-2"
                                                              style={{
                                                                fontFamily:
                                                                  "Inter",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            >
                                                              {/* {service.product.productMaster.additionalInfo} */}
                                                            </p>
                                                          </div>
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                                                            <div
                                                              className="text-right  font-bold text-blue-800"
                                                              style={{
                                                                fontFamily:
                                                                  "Inter",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            >
                                                              {service?.product
                                                                ?.isCustomPriceApplicable
                                                                ? "Custom Price"
                                                                : service
                                                                    ?.product
                                                                    ?.isPriceIncludedInBaseProduct
                                                                ? "Included"
                                                                : `$${service.product.unitPrice}`}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                                {Object.keys(previewData).map(
                                                  (sectionKey, index) => (
                                                    <>
                                                      {!keyPersonalMemberData.includes(
                                                        sectionKey
                                                      ) ? (
                                                        <>
                                                          <div
                                                            key={index}
                                                            className="bg-white"
                                                          >
                                                            <Accordion
                                                              activeIndex={0}
                                                            >
                                                              <AccordionTab
                                                                header={convertToTitleCase(
                                                                  sectionKey
                                                                )}
                                                              >
                                                                <div className="px-4 py-4">
                                                                  {Array.isArray(
                                                                    previewData[
                                                                      sectionKey
                                                                    ]
                                                                  ) ? (
                                                                    previewData[
                                                                      sectionKey
                                                                    ].map(
                                                                      (
                                                                        item,
                                                                        idx
                                                                      ) => {
                                                                        const primitiveKeys =
                                                                          [];
                                                                        const objectKeys =
                                                                          [];
                                                                        const arrayKeys =
                                                                          [];

                                                                        Object.keys(
                                                                          item
                                                                        ).forEach(
                                                                          (
                                                                            itemKey
                                                                          ) => {
                                                                            if (
                                                                              itemKey !==
                                                                              "__temp_key__"
                                                                            ) {
                                                                              if (
                                                                                typeof item[
                                                                                  itemKey
                                                                                ] ===
                                                                                  "object" &&
                                                                                item[
                                                                                  itemKey
                                                                                ] !==
                                                                                  null
                                                                              ) {
                                                                                if (
                                                                                  Array.isArray(
                                                                                    item[
                                                                                      itemKey
                                                                                    ]
                                                                                  )
                                                                                ) {
                                                                                  arrayKeys.push(
                                                                                    itemKey
                                                                                  );
                                                                                } else {
                                                                                  objectKeys.push(
                                                                                    itemKey
                                                                                  );
                                                                                }
                                                                              } else {
                                                                                primitiveKeys.push(
                                                                                  itemKey
                                                                                );
                                                                              }
                                                                            }
                                                                          }
                                                                        );

                                                                        return (
                                                                          <div
                                                                            key={
                                                                              idx
                                                                            }
                                                                            className=""
                                                                          >
                                                                            <h3 className="text-lg text-gray-800 mb-2">
                                                                              {convertToTitleCase(
                                                                                sectionKey
                                                                              )}{" "}
                                                                              {idx +
                                                                                1}
                                                                            </h3>

                                                                            <div className="grid p-4 mb-2">
                                                                              {primitiveKeys.map(
                                                                                (
                                                                                  itemKey
                                                                                ) => (
                                                                                  <>
                                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                                                      <div className="order-preview-key">
                                                                                        {convertName(
                                                                                          itemKey
                                                                                        )}
                                                                                      </div>
                                                                                      <div className="order-preview-value">
                                                                                        {
                                                                                          item[
                                                                                            itemKey
                                                                                          ]
                                                                                        }
                                                                                      </div>
                                                                                    </div>
                                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                                                                                  </>
                                                                                )
                                                                              )}
                                                                            </div>

                                                                            {objectKeys.length >
                                                                              0 && (
                                                                              <div className="grid  sam p-4 mb-2">
                                                                                {objectKeys.map(
                                                                                  (
                                                                                    itemKey
                                                                                  ) => (
                                                                                    <>
                                                                                      <div className="col-12">
                                                                                        <div className="order-preview-key pb-2">
                                                                                          {convertName(
                                                                                            itemKey
                                                                                          )}
                                                                                        </div>
                                                                                        <div className="order-preview-value">
                                                                                          <div className="grid preview-card pap p-4 mb-2">
                                                                                            {Object.keys(
                                                                                              item[
                                                                                                itemKey
                                                                                              ]
                                                                                            ).map(
                                                                                              (
                                                                                                subKey
                                                                                              ) => (
                                                                                                <>
                                                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                                                                    <div className="order-preview-key">
                                                                                                      {convertName(
                                                                                                        subKey
                                                                                                      )}
                                                                                                    </div>
                                                                                                    <div className="order-preview-value">
                                                                                                      {
                                                                                                        item[
                                                                                                          itemKey
                                                                                                        ][
                                                                                                          subKey
                                                                                                        ]
                                                                                                      }
                                                                                                    </div>
                                                                                                  </div>
                                                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                                                                                                </>
                                                                                              )
                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </div>
                                                                            )}

                                                                            {arrayKeys.length >
                                                                              0 && (
                                                                              <div className="grid sam1 p-4 mb-2">
                                                                                {arrayKeys.map(
                                                                                  (
                                                                                    itemKey
                                                                                  ) => (
                                                                                    <>
                                                                                      <div className="col-12">
                                                                                        <div className="order-preview-key pb-2">
                                                                                          {convertName(
                                                                                            itemKey
                                                                                          )}
                                                                                        </div>
                                                                                        <div className="order-preview-value">
                                                                                          {item[
                                                                                            itemKey
                                                                                          ].map(
                                                                                            (
                                                                                              subItem,
                                                                                              subIdx
                                                                                            ) => (
                                                                                              <div
                                                                                                key={
                                                                                                  subIdx
                                                                                                }
                                                                                                className="ml-4"
                                                                                              >
                                                                                                {typeof subItem ===
                                                                                                "object" ? (
                                                                                                  <div className="grid preview-card pap p-4 mb-2">
                                                                                                    {Object.keys(
                                                                                                      subItem
                                                                                                    ).map(
                                                                                                      (
                                                                                                        subKey
                                                                                                      ) => (
                                                                                                        <>
                                                                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                                                                            <div className="order-preview-key">
                                                                                                              {convertName(
                                                                                                                subKey
                                                                                                              )}
                                                                                                            </div>
                                                                                                            <div className="order-preview-value">
                                                                                                              {
                                                                                                                subItem[
                                                                                                                  subKey
                                                                                                                ]
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                                                                                                        </>
                                                                                                      )
                                                                                                    )}
                                                                                                  </div>
                                                                                                ) : (
                                                                                                  <div>
                                                                                                    {
                                                                                                      subItem
                                                                                                    }
                                                                                                  </div>
                                                                                                )}
                                                                                              </div>
                                                                                            )
                                                                                          )}
                                                                                        </div>
                                                                                      </div>
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </div>
                                                                            )}

                                                                            {/* <div className="border-t border-gray-300 my-4"></div> */}
                                                                          </div>
                                                                        );
                                                                      }
                                                                    )
                                                                  ) : previewData[
                                                                      sectionKey
                                                                    ] !==
                                                                      null &&
                                                                    typeof previewData[
                                                                      sectionKey
                                                                    ] ===
                                                                      "object" ? (
                                                                    Object.keys(
                                                                      previewData[
                                                                        sectionKey
                                                                      ]
                                                                    ).map(
                                                                      (
                                                                        key,
                                                                        i
                                                                      ) => (
                                                                        <div
                                                                          key={
                                                                            i
                                                                          }
                                                                          className=""
                                                                        >
                                                                          {Array.isArray(
                                                                            previewData[
                                                                              sectionKey
                                                                            ][
                                                                              key
                                                                            ]
                                                                          ) ? (
                                                                            <div>
                                                                              <h3 className="text-lg text-gray-800 mb-2">
                                                                                {convertToTitleCase(
                                                                                  key
                                                                                )}
                                                                              </h3>

                                                                              {previewData[
                                                                                sectionKey
                                                                              ][
                                                                                key
                                                                              ].map(
                                                                                (
                                                                                  arrayItem,
                                                                                  arrayIndex
                                                                                ) => (
                                                                                  <div
                                                                                    key={
                                                                                      arrayIndex
                                                                                    }
                                                                                    className="mb-2"
                                                                                  >
                                                                                    {arrayItem !==
                                                                                      null &&
                                                                                    typeof arrayItem ===
                                                                                      "object" ? (
                                                                                      <div>
                                                                                        <div className="grid preview-card p-4 mb-2">
                                                                                          {Object.keys(
                                                                                            arrayItem
                                                                                          ).map(
                                                                                            (
                                                                                              nestedKey
                                                                                            ) => (
                                                                                              <>
                                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                                                                  <div className="order-preview-key">
                                                                                                    {convertName(
                                                                                                      nestedKey
                                                                                                    )}
                                                                                                  </div>
                                                                                                  <div className="order-preview-value">
                                                                                                    {
                                                                                                      arrayItem[
                                                                                                        nestedKey
                                                                                                      ]
                                                                                                    }
                                                                                                  </div>
                                                                                                </div>
                                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                                                                                              </>
                                                                                            )
                                                                                          )}
                                                                                        </div>

                                                                                        <div className="border-t border-gray-300 my-4"></div>
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div className="grid gap-3 mb-2">
                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 order-preview-key">
                                                                                          {`Item ${
                                                                                            arrayIndex +
                                                                                            1
                                                                                          }`}
                                                                                        </div>
                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-preview-value">
                                                                                          {
                                                                                            arrayItem
                                                                                          }
                                                                                        </div>
                                                                                      </div>
                                                                                    )}
                                                                                  </div>
                                                                                )
                                                                              )}

                                                                              <div className="border-t border-gray-300 my-4"></div>
                                                                            </div>
                                                                          ) : previewData[
                                                                              sectionKey
                                                                            ][
                                                                              key
                                                                            ] !==
                                                                              null &&
                                                                            typeof previewData[
                                                                              sectionKey
                                                                            ][
                                                                              key
                                                                            ] ===
                                                                              "object" ? (
                                                                            <div>
                                                                              <h3 className="text-lg text-gray-800 mb-2">
                                                                                {convertToTitleCase(
                                                                                  key
                                                                                )}
                                                                              </h3>
                                                                              <div className="grid preview-card p-4 mb-2">
                                                                                {Object.keys(
                                                                                  previewData[
                                                                                    sectionKey
                                                                                  ][
                                                                                    key
                                                                                  ]
                                                                                ).map(
                                                                                  (
                                                                                    nestedKey
                                                                                  ) => (
                                                                                    <>
                                                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 order-preview-key">
                                                                                          {convertName(
                                                                                            nestedKey
                                                                                          )}
                                                                                        </div>
                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-preview-value">
                                                                                          {
                                                                                            previewData[
                                                                                              sectionKey
                                                                                            ][
                                                                                              key
                                                                                            ][
                                                                                              nestedKey
                                                                                            ]
                                                                                          }
                                                                                        </div>
                                                                                      </div>
                                                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </div>

                                                                              <div className="border-t border-gray-300 my-4"></div>
                                                                            </div>
                                                                          ) : (
                                                                            <div className="grid gap-3 mb-2">
                                                                              <div className="col-12 col-sm-12 col-md-12 col-lg-3 order-preview-key">
                                                                                {convertName(
                                                                                  key
                                                                                )}
                                                                              </div>
                                                                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-preview-value">
                                                                                {
                                                                                  previewData[
                                                                                    sectionKey
                                                                                  ][
                                                                                    key
                                                                                  ]
                                                                                }
                                                                              </div>
                                                                            </div>
                                                                          )}
                                                                        </div>
                                                                      )
                                                                    )
                                                                  ) : (
                                                                    <div className="grid gap-3 mb-2">
                                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-3 order-preview-key">
                                                                        {convertName(
                                                                          sectionKey
                                                                        )}
                                                                      </div>
                                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-preview-value">
                                                                        {
                                                                          previewData[
                                                                            sectionKey
                                                                          ]
                                                                        }
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </AccordionTab>
                                                            </Accordion>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  )
                                                )}

                                                {formData && (
                                                  <div className="bg-white">
                                                    <div
                                                      className="px-4 py-2"
                                                      style={{
                                                        backgroundColor:
                                                          "#F9FAFB",
                                                        fontFamily: "Inter",
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      <h2 className="text-lg  text-gray-800">
                                                        Contacts
                                                      </h2>
                                                    </div>
                                                    <KeyPersonelCard
                                                      data={formData}
                                                    />
                                                  </div>
                                                )}

                                                <div className="bg-white">
                                                  <div
                                                    className="px-4 py-2"
                                                    style={{
                                                      backgroundColor:
                                                        "#F9FAFB",
                                                      fontFamily: "Inter",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    <h2 className="text-lg  text-gray-800">
                                                      Billing Contact Address
                                                    </h2>
                                                  </div>

                                                  <div className="p-4 ">
                                                    <div className="grid gap-2 justify-content-start">
                                                      {bilingAddress ? (
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                                                          <div className="order-contact-card-container">
                                                            <div className="order-contact-card-main">
                                                              <div className="order-contact-card-header-main">
                                                                <Avatar
                                                                  label={commonGetInitials(
                                                                    bilingAddress.keyPersonnelName
                                                                  )}
                                                                  icon="pi pi-user"
                                                                  size="large"
                                                                  shape="circle"
                                                                  className="custom-avatar"
                                                                />

                                                                <div>
                                                                  <div className="order-contact-card-header-text1">
                                                                    {
                                                                      bilingAddress.keyPersonnelName
                                                                    }
                                                                  </div>
                                                                  <div className="order-contact-card-header-text2">
                                                                    {IsCheckedAgent
                                                                      ? "Billing Contact"
                                                                      : "Billing Contact"}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="card-devider">
                                                                {" "}
                                                              </div>
                                                              <div className="order-contact-card-text-main">
                                                                <div>
                                                                  <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20"
                                                                    height="20"
                                                                    viewBox="0 0 20 20"
                                                                    fill="none"
                                                                  >
                                                                    <g clip-path="url(#clip0_966_10329)">
                                                                      <path
                                                                        d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                        stroke="#175CD3"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                      />
                                                                    </g>
                                                                    <defs>
                                                                      <clipPath id="clip0_966_10329">
                                                                        <rect
                                                                          width="20"
                                                                          height="20"
                                                                          fill="white"
                                                                        />
                                                                      </clipPath>
                                                                    </defs>
                                                                  </svg>
                                                                </div>
                                                                <div className="order-contact-card-text1">
                                                                  {
                                                                    bilingAddress.contactNo
                                                                  }
                                                                </div>
                                                              </div>

                                                              <div className="order-contact-card-text-main">
                                                                <div>
                                                                  <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20"
                                                                    height="20"
                                                                    viewBox="0 0 20 20"
                                                                    fill="none"
                                                                  >
                                                                    <path
                                                                      d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                      stroke="#175CD3"
                                                                      stroke-width="2"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                    />
                                                                  </svg>
                                                                </div>
                                                                <div className="order-contact-card-text1">
                                                                  {
                                                                    bilingAddress.emailId
                                                                  }
                                                                </div>
                                                              </div>

                                                              <div className="order-contact-card-text-main">
                                                                <div>
                                                                  <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20"
                                                                    height="20"
                                                                    viewBox="0 0 20 20"
                                                                    fill="none"
                                                                  >
                                                                    <path
                                                                      d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                      stroke="#175CD3"
                                                                      stroke-width="1.66667"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                      d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                      stroke="#175CD3"
                                                                      stroke-width="1.66667"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                    />
                                                                  </svg>
                                                                </div>
                                                                <div className="order-contact-card-text1 text-wrap">
                                                                  {bilingAddress
                                                                    .addressMasterId
                                                                    .addressLine1 !==
                                                                  ""
                                                                    ? `${
                                                                        bilingAddress
                                                                          .addressMasterId
                                                                          .addressLine1
                                                                      },${
                                                                        bilingAddress
                                                                          .addressMasterId
                                                                          .addressLine2
                                                                          ? `${bilingAddress.addressMasterId.addressLine2},`
                                                                          : ""
                                                                      }${
                                                                        bilingAddress
                                                                          .addressMasterId
                                                                          .city
                                                                      },${getState(
                                                                        bilingAddress
                                                                          .addressMasterId
                                                                          .stateId
                                                                      )},${
                                                                        bilingAddress
                                                                          .addressMasterId
                                                                          .postalCode
                                                                      }`
                                                                    : ""}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {/* {
                                                                                                            shippingAddress ? <div className='col-5'>

                                                                                                                <div className='order-contact-card-container'>
                                                                                                                    <div className='order-contact-card-main'>
                                                                                                                        <div className='order-contact-card-header-main'>

                                                                                                                            <Avatar label={commonGetInitials(shippingAddress.keyPersonnelName)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                                                            <div>
                                                                                                                                <div className='order-contact-card-header-text1'>
                                                                                                                                    {shippingAddress.keyPersonnelName}
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-header-text2'>
                                                                                                                                    Shipping Contact
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                        <div className='card-devider'> </div>
                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <g clip-path="url(#clip0_966_10329)">
                                                                                                                                        <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </g>
                                                                                                                                    <defs>
                                                                                                                                        <clipPath id="clip0_966_10329">
                                                                                                                                            <rect width="20" height="20" fill="white" />
                                                                                                                                        </clipPath>
                                                                                                                                    </defs>
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1'>
                                                                                                                                {shippingAddress.contactNo}
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1'>
                                                                                                                                {shippingAddress.emailId}
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1 text-wrap'>
                                                                                                                                {shippingAddress.addressMasterId.addressLine1 !== "" ? `${shippingAddress.addressMasterId.addressLine1},${shippingAddress.addressMasterId.addressLine2 ? `${shippingAddress.addressMasterId.addressLine2},` : ""}${shippingAddress.addressMasterId.city},${getState(shippingAddress.addressMasterId.stateId)},${shippingAddress.addressMasterId.postalCode}` : ""}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""
                                                                                                        } */}
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="px-4">
                                                  <PaymentOptions />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <main
                                            className="flex flex-col justify-between items-center px-4 pt-4 pb-12 rounded-lg shadow-lg font-fam-for-all max-w-[100%] max-md:px-5"
                                            style={{
                                              backgroundColor: "#F6F8FE",
                                              width: "500px",
                                              height: "800px",
                                            }}
                                          >
                                            <Skeleton className="mb-2"></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="5rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              height="2rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              height="4rem"
                                            ></Skeleton>
                                            <Skeleton className="mb-2"></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="5rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              height="2rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              height="4rem"
                                            ></Skeleton>
                                            <Skeleton className="mb-2"></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="5rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              height="2rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              height="4rem"
                                            ></Skeleton>
                                          </main>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                <div className="flex flex-col justify-content-end gap-2 px-4">
                                  {/* {
                                                                        key === "Registered_Agent" ? <Button type='button' id="nextBtn" className="mt-2   vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => nextPrev(1)} >Next</Button>
                                                                            : ""
                                                                    } */}
                                  <Button
                                    type="button"
                                    id="nextBtn"
                                    className="mt-2   vstate-button font-fam-for-all text-center  justify-content-center"
                                    onClick={() => nextPrev(1)}
                                  >
                                    Save & Next
                                  </Button>
                                  <Button
                                    type="button"
                                    id="submitBtn"
                                    className="mt-2   vstate-button font-fam-for-all text-center justify-content-center"
                                    onClick={(e) => {
                                      handleSubmit(onSubmit, onInvalid)(e);
                                      handleScroll();
                                    }}
                                  >
                                    Checkout
                                  </Button>
                                  <Button
                                    type="button"
                                    id="prevBtn"
                                    onClick={() => nextPrev(-1)}
                                    className="mt-2 vstate-reverse-button   font-fam-for-all text-center justify-content-center"
                                  >
                                    Back
                                  </Button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 flex flex-column gap-2">
              <div className=" font-fam-for-all">
                <div className="wizard-checkout-container">
                  <div className="wizard-checkout-main">
                    <div className="wizard-checkout-header justify-content-between">
                      <div className="wizard-checkout-text-main">
                        <div className="wizard-checkout-text">CART SUMMARY</div>
                      </div>
                    </div>

                    {/* <div className="wizard-checkout-body">

                                            <React.Fragment >
                                                <div className="wizard-checkout-content-main">
                                                    {selectedCheckBoxData.length > 0 ? selectedCheckBoxData.map((val, index) => (
                                                        <div className="wizard-checkout-content-price-main" key={index}>
                                                            <div className="wizard-price-content1">
                                                                {val.displayName}
                                                            </div>
                                                            <div className="wizard-price-content2">
                                                                ${val.unitPrice}
                                                            </div>
                                                        </div>
                                                    )) : <>
                                                        <div className="wizard-price-content1 flex justify-content-center">No items added</div>
                                                    </>}
                                                </div>

                                    
                                            </React.Fragment>

                                        </div>

                                        <div className="wizard-checkout-footer">
                                            <div className="wizard-checkout-text-footer">
                                                <div className="wizard-checkout-footer-txt">
                                                    TOTAL (TAX DEDUCTIBLE)
                                                </div>
                                            </div>
                                            <div className="wizard-total-price">
                                                {`$${totalAmount}`}
                                            </div>
                                        </div> */}

                    <div className="wizard-checkout-body">
                      <React.Fragment>
                        <div className="wizard-checkout-content-main">
                          {selectedCheckBoxData.length > 0 ? (
                            selectedCheckBoxData
                              .sort(
                                (a, b) =>
                                  a.product.displayPreference -
                                  b.product.displayPreference
                              )
                              .map((val, index) => {
                                const baseProduct = val.product.baseProduct;
                                const isPriceExcluded =
                                  val.product.isPriceIncludedInBaseProduct;
                                let productPrice = val.product.unitPrice;

                                // Handle bundle pricing
                                // if (val.isBundlePriceApplicable) {
                                //     const additionalItems = Math.max(0, selectedQuantity - val.noOfItemsInBundle);
                                //     productPrice = (val.priceOfBundle * val.noOfItemsInBundle) +
                                //         (additionalItems * val.priceForAddlItemsOverBundle);
                                // }

                                return (
                                  <>
                                    {!baseProduct && (
                                      <div className="wizard-checkout-content-price-main">
                                        <div className="wizard-price-content1">
                                          {val.product.displayName}
                                        </div>
                                        <div className="wizard-price-content2">
                                          {!isPriceExcluded
                                            ? val?.product
                                                ?.isCustomPriceApplicable
                                              ? "Custom Price"
                                              : val?.product
                                                  ?.isPriceIncludedInBaseProduct
                                              ? "Included"
                                              : `$${val.product.unitPrice}`
                                            : "Included"}
                                        </div>
                                      </div>
                                    )}

                                    {baseProduct && (
                                      <div className="wizard-checkout-content-price-main ml-4">
                                        <div className="wizard-price-content1">
                                          {val.product.displayName}
                                        </div>
                                        <div className="wizard-price-content2">
                                          {!isPriceExcluded
                                            ? `$${productPrice}`
                                            : `Included under ${baseProduct.displayName}`}
                                        </div>
                                      </div>
                                      // <div className="ml-4 text-muted">
                                      //     Included under {baseProduct.displayName}
                                      // </div>
                                    )}
                                  </>
                                );
                              })
                          ) : (
                            <div className="wizard-price-content1 flex justify-content-center">
                              No items added
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    </div>

                    <div className="wizard-checkout-footer">
                      <div className="wizard-checkout-text-footer">
                        <div className="wizard-checkout-footer-txt">
                          TOTAL (TAX DEDUCTIBLE)
                        </div>
                      </div>
                      <div className="wizard-total-price">
                        {`$${calculateTotalAmount()}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AddCity
              visible={visible}
              setVisible={setVisible}
              credit={creditInfo}
              llc={llc}
              state={state}
              enityName={enityName}
              llcDetails={llcName}
              modelData={modelData}
            />
            <AddCompany
              visible={visible9}
              setVisible={setVisible9}
              pageRefresh={pageRefresh}
              setPageRefresh={setPageRefresh}
            />

            {keyPersonalCompany && (
              <AddCompanyMember
                visible={visible2}
                setVisible={setVisible2}
                pageRefresh={pageRefresh}
                setPageRefresh={setPageRefresh}
                memberType={memberType}
                data={keyPersonalCompany}
                keyPersonalAddressType={keyPersonalAddressType}
              />
            )}

            {keyPersonalCompany && (
              <AddBOIMember
                visible={visible8}
                setVisible={setVisible8}
                pageRefresh={pageRefresh}
                setPageRefresh={setPageRefresh}
                memberType={memberType}
                data={keyPersonalCompany}
                keyPersonalAddressType={keyPersonalAddressType}
              />
            )}

            <AddCompanyLocation
              companyId={globalCompany ? globalCompany.companyDetails : null}
              visible={visible3}
              setVisible={setVisible3}
            />

            {closeProgresbar2 && (
              <Dialog
                className="payment-dialog rounded-lg"
                visible={closeProgresbar2}
                onHide={() => setCloseProgresbar2(false)}
              >
                <section className="flex overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                  <div className="payment-icon-confirm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="24"
                      viewBox="0 0 14 24"
                      fill="none"
                    >
                      <path
                        d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1"
                        stroke="#039855"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                    <header className="flex flex-col w-full max-md:max-w-full">
                      <h2 className="text-lg font-medium leading-loose text-gray-900 max-md:max-w-full">
                        Pay Later
                      </h2>
                      <p className="mt-2 text-sm leading-5 text-gray-500 w-[500px] text-wrap">
                        You have selected the <strong>Pay Later</strong> option.
                        Your order will be received, and upon approval from
                        vState Filings, your order will be submitted.
                      </p>
                    </header>
                    <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                      <div className="flex gap-3 items-start justify-content-end self-stretch my-auto min-w-[240px]">
                        <Button
                          className="overflow-hidden gap-2 self-stretch px-5 py-2.5 rounded-lg border border-solid shadow-sm bg-white text-gray-700 border-gray-300"
                          label="Cancel"
                          onClick={() => setCloseProgresbar2(false)}
                        />
                        <Button
                          className="overflow-hidden gap-2 self-stretch px-5 py-2.5 rounded-lg border border-solid shadow-sm bg-blue-600 text-white border-blue-600"
                          label="Submit for Approval"
                          onClick={handleConfirm}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </Dialog>
            )}
            {closeProgresbar3 && (
              <Dialog
                className="payment-dialog rounded-lg"
                visible={closeProgresbar3}
                onHide={() => setCloseProgresbar3(false)}
              >
                <section className="flex rounded-lg overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                  <div className="payment-icon-confirm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="24"
                      viewBox="0 0 14 24"
                      fill="none"
                    >
                      <path
                        d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1"
                        stroke="#039855"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                    <header className="flex flex-col w-full max-md:max-w-full">
                      <h2 className="text-lg font-medium leading-loose text-gray-900 max-md:max-w-full">
                        Pay Now
                      </h2>
                      <p className="mt-2 text-sm leading-5 text-gray-500 w-[500px] text-wrap">
                        You selected the <strong>Pay Now</strong> option. Please
                        complete your payment to finalize your order.
                      </p>
                    </header>
                    <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                      <div className="flex justify-content-end gap-3 items-start self-stretch my-auto min-w-[240px]">
                        <Button
                          className="vstate-reverse-button"
                          label="Cancel"
                          onClick={() => setCloseProgresbar3(false)}
                        />
                        <Button
                          className="vstate-button"
                          label="Proceed to payment"
                          onClick={handleConfirm}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </Dialog>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddBOIServiceForm;
