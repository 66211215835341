import React from "react";
import { Modal } from "react-bootstrap";
import CommonTourBlue from "./../CommonTourComponent/CommonTourBlue";
import CommonTourWhite from "./../CommonTourComponent/CommonTourWhite";
import "./../CommonStyleTour/CommonStyleTour.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";
import CompanyTourModal from "./../CompanyTourModal";
import EmployeeClientModal from "./EmployeeClientTourModal";
import emp_dashboard_s4 from "../../../Assets/Images/tour/emp_dashboard_s4.png";
import employee_company_s1 from "../../../Assets/Images/tour/employee_company_s1.png";
import employee_company_s2 from "../../../Assets/Images/tour/employee_company_s2.png";
import employee_company_s3 from "../../../Assets/Images/tour/employee_company_s3.png";
import employee_company_s4 from "../../../Assets/Images/tour/employee_company_s4.png";
import employee_company_s5 from "../../../Assets/Images/tour/employee_company_s5.png";
import employee_company_s6 from "../../../Assets/Images/tour/employee_company_s6.png";
import employee_company_s7 from "../../../Assets/Images/tour/employee_company_s7.png";
import employee_company_s8 from "../../../Assets/Images/tour/employee_company_s8.png";
import employee_company_s10 from "../../../Assets/Images/tour/employee_company_s10.png";

const EmployeeCompanyModal = (props) => {
  const [modalsecondShow, setModalSecondShow] = React.useState(false);

  const handleStartClick = () => {
    setModalSecondShow(true);
    props.onHide();
  };
  return (
    <section>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="common-modal-class"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 1 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    {" "}
                    Companies: Manage Your Companies
                  </h4>
                  <p className="tour-text-holder">
                    This section empowers you to efficiently manage your
                    business entities, providing centralized access to critical
                    company information and updates.{" "}
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/employee_company_s1_1a0cf282c1.png"
                    src={employee_company_s1}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Company List Overview:{" "}
                      </span>{" "}
                      Displays a list of your registered entities along with key
                      identifiers such as entity type, state of registration,
                      and current status.{" "}
                    </li>
                    <li>
                      <span className="blkkk-textt"> Search & Filters: </span>{" "}
                      Use intuitive search and filters to sort by state,
                      business structure, or other parameters for quick
                      navigation.{" "}
                    </li>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Entity Management Actions:{" "}
                      </span>{" "}
                      Add new companies, transfer ownership, or update essential
                      details effortlessly.{" "}
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 2 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">Companies: Company Details</h4>
                  <p className="tour-text-holder">
                    Easily manage and update essential details about your legal
                    entity for seamless compliance and record keeping.{" "}
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/employee_company_s2_de55ce5a0e.png"
                    src={employee_company_s2}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Entity Overview: </span>{" "}
                      View the entity name, business structure (LLC, Corp,
                      etc.), formation date, service state, and industry type to
                      stay informed about your company status at a glance
                    </li>

                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Contact Information:{" "}
                      </span>{" "}
                      Quickly access or update email, phone, and website details
                      to ensure smooth communication.
                    </li>

                    <li>
                      <span className="blkkk-textt"> IRS & Accounting: </span>{" "}
                      Keep track of your EIN, entity number, and tax year end
                      for accurate financial and legal compliance{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt"> Principal Address: </span>{" "}
                      Review or modify the official address, including street
                      details, city, state, country, and ZIP code to maintain
                      accurate records{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt"> Edit Features: </span>{" "}
                      Update outdated details with ease using the edit option to
                      stay compliant with regulations.{" "}
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 3 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">Companies: Company Details </h4>
                  <p className="tour-text-holder">
                    Effortlessly manage and organize company contacts to ensure
                    effective communication and collaboration.{" "}
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/employee_company_s3_79fde6fcaf.png"
                    src={employee_company_s3}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Add Contacts: </span>{" "}
                      Easily Easily create or manage company contacts by filling
                      in essential details such as name, email, phone number
                      (with country code), and designation for streamlined
                      communication and role assignment.
                    </li>

                    <li>
                      <span className="blkkk-textt"> User Management: </span>{" "}
                      Easily Select existing users from the dropdown or create
                      new ones while assigning specific roles to contacts for
                      improved responsibility tracking and management.
                    </li>

                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Security and credentials:{" "}
                      </span>{" "}
                      Easily Securely set up and confirm passwords for each
                      contact to ensure privacy, accuracy, and easy account
                      access.
                    </li>

                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Edit and review feature:{" "}
                      </span>{" "}
                      Easily Update or modify contact details and review
                      assigned roles anytime through a user-friendly interface
                      to maintain clear and accurate records.{" "}
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 4 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Companies: Manage Company Locations{" "}
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to manage all locations associated
                    with your company, including offices, branches, and
                    affiliates.{" "}
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/employee_company_s4_8cec57c8a3.png"
                    src={employee_company_s4}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Add Location: </span> Add a
                      new location to your company's profile.{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt"> Edit Location: </span>{" "}
                      Modify existing location details, such as address, contact
                      information, and address type.{" "}
                    </li>

                    {/* <li>
                      <span className="blkkk-textt"> Delete Location: </span>{" "}
                      Remove a location from your company's profile.{" "}
                    </li> */}
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            {/*  */}
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 5 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Companies: Manage Key Personnel{" "}
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to manage all key personnel
                    associated with your company, including owners, members,
                    directors, officers, and more.{" "}
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/employee_company_s5_75370bad92.png"
                    src={employee_company_s5}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    {/* <li>
                      <span className="blkkk-textt"> Key Actions: </span> Add,
                      edit, and remove personnel from your company's records.{" "}
                    </li> */}

                    <li>
                      <span className="blkkk-textt"> Personnel Types: </span>{" "}
                      Manage various personnel types such as owners, members,
                      directors, officers, registered agents, and beneficial
                      owners.{" "}
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 6 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Companies: Manage Company Records{" "}
                  </h4>
                  <p className="tour-text-holder">
                    This section provides a central repository for all important
                    documents related to your company.{" "}
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/employee_company_s6_5bf439e024.png"
                    src={employee_company_s6}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Add Document: </span>{" "}
                      Upload new documents, such as articles of incorporation,
                      operating agreements, or other legal filings.{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt"> View Documents: </span>{" "}
                      Access existing documents stored in the system.{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt"> Download Documents: </span>{" "}
                      Download copies of stored documents for your records.{" "}
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 7 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Companies: View and Manage Company Orders{" "}
                  </h4>
                  <p className="tour-text-holder">
                    This section provides a central hub for all orders placed
                    for your company, including filings, compliance services,
                    and other requests.
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/employee_company_s7_f710278583.png"
                    src={employee_company_s7}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> View Order Details: </span>{" "}
                      Access detailed information about each order, including
                      order number, type, date, status, and associated
                      documents.{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt"> Track Order Status: </span>{" "}
                      Monitor the progress of your orders in real-time.{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Manage Order History:{" "}
                      </span>{" "}
                      View and search through past orders for reference.{" "}
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 8 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Companies: Manage Company Subscriptions{" "}
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to view and manage all active
                    subscriptions related to your company.
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/employee_company_s8_0d0acb941d.png"
                    src={employee_company_s8}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        View Active Subscriptions:
                      </span>{" "}
                      See a list of your current subscriptions and their
                      details.{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt"> Explore New Plans:</span>{" "}
                      Discover new subscription plans that can benefit a
                      business.{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Manage Subscription Renewals:{" "}
                      </span>{" "}
                      Review and manage upcoming subscription renewals.{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Cancel Subscriptions:{" "}
                      </span>{" "}
                      Cancel existing subscriptions as needed{" "}
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 8 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Companies: Manage Company Subscriptions{" "}
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to view and manage all active
                    subscriptions related to your company.
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/emp_dashboard_s4_88e4a8f22a.png"
                    src={emp_dashboard_s4}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        View Active Subscriptions:
                      </span>{" "}
                      See a list of your current subscriptions and their
                      details.{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt"> Explore New Plans:</span>{" "}
                      Discover new subscription plans that can benefit a
                      business.{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Manage Subscription Renewals:{" "}
                      </span>{" "}
                      Review and manage upcoming subscription renewals.{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Cancel Subscriptions:{" "}
                      </span>{" "}
                      Cancel existing subscriptions as needed{" "}
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  {/* 9 replaced by 10 */}
                  <span className="step-txtt">Step 9 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Companies: Explore Available Services{" "}
                  </h4>
                  <p className="tour-text-holder">
                    This section provides an overview of the various services
                    available to support your company's needs.{" "}
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/employee_company_s10_921deb5d08.png"
                    src={employee_company_s10}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Browse Services: </span>{" "}
                      Explore a range of services, including company formation,
                      compliance filings, legal document preparation, and more.{" "}
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Modal.Body>
      </Modal>
      <EmployeeClientModal
        show={modalsecondShow}
        onHide={() => setModalSecondShow(false)}
      />
    </section>
  );
};

export default EmployeeCompanyModal;
