// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.successful-modal .modal-texttt {
  color: var(--Grey-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.successful-modal .blue-text {
  color: #0047ff;
}
`, "",{"version":3,"sources":["webpack://./src/components/Website/SuccessfulModal/SuccessfulModal.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".successful-modal .modal-texttt {\n  color: var(--Grey-800, #1d2939);\n  text-align: center;\n  font-family: Poppins;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 300;\n}\n\n.successful-modal .blue-text {\n  color: #0047ff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
