import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Skeleton } from 'primereact/skeleton'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'

import { Toast } from 'primereact/toast'
import authContext from '../common/authContext'
import { useNavigate } from 'react-router-dom'
import ProductSevice from '../services/productService'

const MyProducts = () => {
    const { globalCompany, clearData, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh, redirectedData, setRedirectedData } = useContext(authContext);

    const [superAdminId, setSuperAdminId] = useState(null)

    const [superAdminMainId, setSuperAdminMainId] = useState(null)

    const [selectedProduct, setSelectedProduct] = useState(null)

    const [products, setProducts] = useState([])

    const [data, setData] = useState(null)

    const [visible, setVisible] = useState(false)

    const user = JSON.parse(localStorage.getItem('user'));

    const [allStatus, setAllStatus] = useState([])

    const toast = useRef(null);

    const Navigate = useNavigate();

    const [showSkeleton, setShowSkeleton] = useState(false)

    const [allState,setAllState]=useState([])

    const [allOrderType,setAllOrderType]=useState([])





    const getStatus = (id) => {
        if (allStatus.length > 0) {
            const temp = allStatus.find((val) => val.id === id)
            if (temp) {
                return temp.statusName;
            }
            else {
                return null;
            }

        }
        else {
            return null;
        }
    }

    useEffect(() => {
        ProductSevice.getAllProduct()
            .then((res) => {
                console.log(res)
                if (res.data) {
                    setProducts(res.data)
                    setShowSkeleton(true)
                }
            })
            .catch((err) => {
                console.log(err)
                setShowSkeleton(true)

            })
    }, [])


    const onRowSelect = (rowData) => {
        setData(rowData.data)
    }

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: { value: null, matchMode: FilterMatchMode.IN },
        companyName: { value: null, matchMode: FilterMatchMode.EQUALS },
        lastName: { value: null, matchMode: FilterMatchMode.EQUALS },
        mobileNo: { value: null, matchMode: FilterMatchMode.EQUALS },
        emailId: { value: null, matchMode: FilterMatchMode.EQUALS },

    });


    function checkIdsExist(arr, idsToCheck) {
        // Check if any of the idsToCheck are found in arr
        const foundIds = idsToCheck.filter(idToCheck => arr.some(item => item.id === idToCheck));
        return foundIds.length > 0 ? foundIds : null;
    }





    const items = Array.from({ length: 5 }, (v, i) => i);

    const statusBody = (rowdata) => {
        return (
            <div className='badge-container'>
                <div className='badge-main grid' style={{ background: '#ECFDF3' }}>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 flex align-items-center p-0">

                        <span className='badge-label ml-1' style={{ color: '#027A48' }}>{getStatus(rowdata.statusId)}</span>
                    </div>

                </div>
            </div>
        )
    }


    const unitPriceBody = (rowdata) => {
        console.log(rowdata)
        return <div className='webapp-sm-size'>
            {`$${rowdata?.unitPrice}`}
        </div>
    }




    const childCompanyBody = (rowdata) => {
        
        if (Array.isArray(rowdata.details)) {
            return rowdata.details
                .map((val) => val?.companyToTransfer?.companyName)
                .filter(Boolean)
                .join(', ');
        }
        return '';
    };




    return (

        <div className='grid webapp-content-container'>
            <Toast ref={toast} />


            <div className='col-12 '>

                <div className='card search-cand-card2'>

                    <div className='col-12 grid font-fam-for-all py-3 px-3'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-7' style={{ paddingLeft: '20px' }}>
                            <p className='search-cand-header mb-0'>My Products</p>
                            <p className='search-cand-sub-header mb-0'>Track all requests for transferring ownership between entities.</p>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-5'>
                            <div className="flex align-items-center justify-content-end gap-2 mt-3">
                                {!(superAdminId && superAdminMainId) && <Button className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Add Products' onClick={() => Navigate("/add-products")} ></Button>}
                            </div>


                        </div>
                    </div>



                    <div className='card-devider'>

                    </div>
                    <div className='col-12' style={{ height: 'fit-content', padding: "20px 20px 0px 30px" }}>
                        <div className='col-12 grid'>
                            <div className="flex flex-wrap align-items-center justify-content-start gap-3" style={{ width: "1300px" }}>
                                <span className="p-input-icon-left" >
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search" className='vstate-input-field-fixed'
                                        // value={filteredInput}
                                        // onKeyDown={(event) => {
                                        //     if (event.key === 'Enter') {
                                        //         handleSearch()
                                        //     }
                                        // }}
                                        // onChange={(e) => {
                                        //     setFilteredInput(e.target.value)
                                        //     if (e.target.value === "") {
                                        //         setProducts(tempProduct)

                                        //     }
                                        // }} 
                                        style={{ borderRadius: "8px", }} />
                                </span>

                                <span>
                                    <Button
                                        type="button"
                                        // icon="pi pi-plus"
                                        style={{ fontSize: "14px" }}
                                        className=" vstate-button font-fam-for-all text-center"
                                    // onClick={handleFilter}
                                    >Filter</Button>
                                </span>
                                <span>
                                    <Button
                                        type="button"
                                        // icon="pi pi-plus"
                                        style={{ fontSize: "14px" }}
                                        // onClick={handleClearFilter}
                                        className="  font-fam-for-all text-center vstate-reverse-button"
                                    >Clear Filter</Button>
                                </span>
                            </div>
                        </div>
                    </div>


                    <div style={{ padding: '30px' }}>

                        <div className='grid' >
                            <div className='col-12 pt-0 '>
                                <div className="">
                                    <div className='vstate-card'>

                                        {showSkeleton ? <DataTable value={products} rows={25} rowsPerPageOptions={[5, 10, 25, 50]} inline paginator emptyMessage='No Data Found' filters={filters}
                                            globalFilterFields={['companyName', 'lastName', 'mobileNo', 'emailId']} selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)}
                                            onRowSelect={onRowSelect} metaKeySelection={false}>
                                            <Column field='displayName' header="Product Name" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                                            <Column  header="Unit Price" body={unitPriceBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                                            <Column field='requestedStatus' header="Requested Status" body={statusBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                                            {/* <Column field='approveOrRejectedBy' header="Approved/Rejected By" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column> */}
                                            <Column field='productMaster.productName' header="Product Master Name" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                                            {/* <Column header="Action" body={iconBodyTemplate} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column> */}
                                        </DataTable> : <DataTable value={items} rows={5} rowsPerPageOptions={[5, 10, 25, 50]} inline paginator emptyMessage='No Candidate Found'
                                            selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)}
                                            onRowSelect={onRowSelect} metaKeySelection={false}>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                        </DataTable>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyProducts