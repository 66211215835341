import { Dialog } from "primereact/dialog";
import React, { useContext, useEffect, useRef, useState } from "react";
import MasterServices from "../services/coreServices";
import UserServices from "../services/userService";
import { Button } from "primereact/button";
import authContext from "../common/authContext";
import { Toast } from "primereact/toast";
import { showToast } from "./toastUtils";

const GenerateRefreshToken = ({ closeProgresbar3, setCloseProgresbar3 }) => {
  const {
    setFeedbackData,
    permissions,
    loginUserName,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh,
    editPayloadData,
    setEditPayloadData,
    redirectedData,
    setRedirectedData,
  } = useContext(authContext);

  const [allConfig, setAllConfig] = useState([]);

  const [configUserName, setConfiUserName] = useState(null);

  const [configPassword, setConfigPassword] = useState(null);

  const [configFlag, setConfigFlag] = useState(false);

  const [quickbooksToken, setQuicksbooksToken] = useState(null);

  const [quickbooksRefreshToken, setQuicksbooksRefreshToken] = useState(null);

  const [dialogMessage, setDialogMessage] = useState("");

  const [flagData, setFlagData] = useState(null);

  const toast = useRef(null);

  const [isError, setIsError] = useState(false);

  const [isPending, setIsPending] = useState(true);

  useEffect(() => {
    MasterServices.getConfig()
      .then((res) => {
        
        if (Array.isArray(res.data)) {
          setAllConfig(res.data);
        }
      })
      .catch((err) => {
        
      });
  }, []);
  useEffect(() => {
    if (allConfig.length > 0) {
      const username = allConfig.find(
        (val) => val.keyName === "QuickbooksUsername"
      )?.value;
      const password = allConfig.find(
        (val) => val.keyName === "QuickbooksPassword"
      )?.value;
      const tempFlag =
        allConfig.find((val) => val.keyName === "flag")?.value === "true";
      const FlagData = allConfig.find((val) => val.keyName === "flag");
      setFlagData(FlagData);
      const quickbookstoken = allConfig.find(
        (val) => val.keyName === "QuickbooksToken"
      );
      const quickbooksRefreshtoken = allConfig.find(
        (val) => val.keyName === "QuickbooksRefreshToken"
      );

      setConfiUserName(username || null);
      setConfigPassword(password || null);
      setConfigFlag(tempFlag);
      setQuicksbooksToken(quickbookstoken || null);
      setQuicksbooksRefreshToken(quickbooksRefreshtoken || null);
    }
  }, [allConfig]);

  const handleRefreshToken = () => {
    const actualdata = {
      url: "https://accounts.intuit.com/app/sign-in?app_group=ExternalDeveloperPortal&asset_alias=Intuit.devx.devx&redirect_uri=https%3A%2F%2Fdeveloper.intuit.com%2Fapp%2Fdeveloper%2Fplayground%3Fcode%3DAB11724308357xKFPDNIW0tiPdU1rl6ABqRPXR0sJylN4kzIQU%26state%3DPlaygroundAuth%26realmId%3D9341452662074458&single_sign_on=false&partner_uid_button=google&appfabric=true",
      email: configUserName,
      password: configPassword,
    };
    setIsTabRefresh(true);
    UserServices.generateRefreshToken(actualdata)
      .then((res) => {
        

        if (res.data !== undefined) {
          const refreshtoken = res.data.refreshToken;
          const accesstoken = res.data.accessToken;
          if (quickbooksToken) {
            const updatedToken = { ...quickbooksToken, value: accesstoken };
            MasterServices.updateConfigById(updatedToken)
              .then((res) => {
                
              })
              .catch((err) => console.error(err));
          }

          if (quickbooksRefreshToken) {
            const updatedRefreshToken = {
              ...quickbooksRefreshToken,
              value: refreshtoken,
            };
            MasterServices.updateConfigById(updatedRefreshToken)
              .then((res) => console.log(res))
              .catch((err) => console.error(err));
          }

          if (flagData) {
            const flag = { ...flagData, value: "false" };

            MasterServices.updateConfigById(flag)
              .then((res) => {
                
                setIsTabRefresh(false);
              })
              .catch((err) => {
                setIsTabRefresh(false);
                console.error(err);
              });
          }
        }
        // if (toast.current !== undefined) {
        //   setIsPending(false);
        //   setIsTabRefresh(false);
        //   setDialogMessage("QuickBooks refresh token updated successfully."); // Success message
        //   setIsError(false);
        //   setTimeout(() => {
        //     setIsPending(true);
        //     setCloseProgresbar3(false);
        //   }, 3000);
        //   if (toast.current !== undefined) {
        //     toast.current.show({
        //       severity: "success",
        //       summary: "Sucesss",
        //       detail: `Quickbooks refresh token updated`,
        //       life: 3000,
        //     });
        //   }
        // }
                                    showToast("success","Quickbooks refresh token updated")
        
      })
      .catch((err) => {
        
        setIsPending(false);
        setIsTabRefresh(false);
        setDialogMessage("QuickBooks refresh token update failed."); // Error message
        setIsError(true);
        setTimeout(() => {
          setIsPending(true);
          setCloseProgresbar3(false);
        }, 3000);
        // if (toast.current !== undefined) {
        //   toast.current.show({
        //     severity: "warn",
        //     summary: "Error",
        //     detail: `Quickbooks refresh token update failed`,
        //     life: 3000,
        //   });
        // }
        showToast("error","Quickbooks refresh token update failed")
      });
  };
  return (
    <div>
      <Toast ref={toast} />
      {closeProgresbar3 && (
        <Dialog
          className="payment-dialog rounded-lg"
          visible={closeProgresbar3}
          onHide={() => setCloseProgresbar3(false)}
        >
          <section className="flex rounded-lg overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
            <div className="vstate-warning-icon-main">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M11.9998 7.99996V12M11.9998 16H12.0098M10.2898 2.85996L1.81978 17C1.64514 17.3024 1.55274 17.6453 1.55177 17.9945C1.55079 18.3437 1.64127 18.6871 1.8142 18.9905C1.98714 19.2939 2.2365 19.5467 2.53748 19.7238C2.83847 19.9009 3.18058 19.9961 3.52978 20H20.4698C20.819 19.9961 21.1611 19.9009 21.4621 19.7238C21.7631 19.5467 22.0124 19.2939 22.1854 18.9905C22.3583 18.6871 22.4488 18.3437 22.4478 17.9945C22.4468 17.6453 22.3544 17.3024 22.1798 17L13.7098 2.85996C13.5315 2.56607 13.2805 2.32308 12.981 2.15444C12.6814 1.98581 12.3435 1.89722 11.9998 1.89722C11.656 1.89722 11.3181 1.98581 11.0186 2.15444C10.7191 2.32308 10.468 2.56607 10.2898 2.85996Z"
                  stroke="#DC6803"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
              <header className="flex flex-col w-full max-md:max-w-full">
                {isPending ? (
                  <p className="text-lg mb-0 font-medium font-fam-for-all leading-loose text-red-900 max-md:max-w-full">
                    Warning
                  </p>
                ) : (
                  <p className="text-lg mb-0 font-medium font-fam-for-all leading-loose text-red-900 max-md:max-w-full">
                    Success
                  </p>
                )}
                {isPending ? (
                  <p className="mt-2 text-md font-fam-for-all leading-5 text-gray-700 w-[500px] text-wrap">
                    Are you sure you want to refresh quickbooks token?
                  </p>
                ) : (
                  <p
                    className={`${
                      isError ? "text-red-700" : "text-green-700"
                    } font-semibold mt-2 text-md leading-5 font-fam-for-all text-gray-600  text-wrap`}
                  >
                    {dialogMessage}
                  </p>
                )}
              </header>
              {isPending && (
                <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                  <div className="flex justify-content-end gap-3 items-start self-stretch my-auto min-w-[240px]">
                    <Button
                      className="vstate-reverse-button"
                      label="Cancel"
                      onClick={() => {
                        setCloseProgresbar3(false);
                        setIsPending(true);
                      }}
                    />
                    <Button
                      className="vstate-button"
                      label="Confirm"
                      onClick={() => handleRefreshToken()}
                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        </Dialog>
      )}
    </div>
  );
};

export default GenerateRefreshToken;
