// import React from "react";
// import BlueButton from "./BlueButton";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
// import imgsrc from '../../../Assets/Images/Section.png';
// import '../CommonSection/CommonStyle/CommonStyle.css'

// const CommonRegister = () => {
//   return (
//     <section className="main-common-register">
//       <img src={imgsrc} className="img-class" />
//       <div className="main-text-class text-center">
//         <h2 className="common-title">Ready to kick off your new business endeavors?</h2>
//         <p className="common-texttt">Get started with your business registration process today.</p>
//         <BlueButton label={'Register Now'}    icon={<FontAwesomeIcon icon={faArrowRightLong} />} onclick={'/signup'}/>
//       </div>
//     </section>
//   );
// };

// export default CommonRegister;

import React, { useEffect } from "react";
import BlueButton from "./BlueButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import "../CommonSection/CommonStyle/CommonStyle.css";
import imgsrc from "../../../Assets/Images/Section.png";
import AOS from "aos";
import "aos/dist/aos.css";

const CommonRegister = ({ title, text, buttonLabel, buttonLink }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section
      className="main-common-register"
      data-aos="zoom-in"
      data-aos-duration="3000"
    >
      <img src={imgsrc} className="img-class" />
      <div className="main-text-class text-center">
        <h2 className="common-title">{title}</h2>
        <p className="common-texttt">{text}</p>
        <div className="main-class-blue">
          <a href="/#/signup" target="_blank">
            <button className="blue-btnnn">
              <span>
                Start Your Business <FontAwesomeIcon icon={faArrowRightLong} />
              </span>
            </button>
          </a>
        </div>
        {/* <BlueButton
          label={buttonLabel}
          icon={<FontAwesomeIcon icon={faArrowRightLong} />}
          onclick={buttonLink}
        /> */}
      </div>
    </section>
  );
};

export default CommonRegister;
