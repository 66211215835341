import React from 'react';

const Testimonial = () => {
  return (
    <section className="flex gap-1 justify-center items-center px-5 mt-24 max-w-full w-[1240px] max-md:flex-wrap max-md:mt-10">
      {/* <div className="shrink-0 self-stretch my-auto h-10 w-[15px]" /> */}
      <div className="flex justify-center items-center self-stretch px-5 py-16 bg-violet-50 max-md:max-w-full">
        <div className="flex flex-col flex-wrap content-end px-5 max-w-full w-[885px]">
          <blockquote className="text-2xl font-medium leading-[52px] text-slate-700 max-md:max-w-full">
            vState Filings has been invaluable to our business, facilitating payroll tax registration for employee hiring. They're a responsive partner, crucial to our business expansion. I highly recommend their services!
          </blockquote>
          <div className="flex gap-5 justify-between self-start mt-14 max-md:mt-10">
            <div className="text-5xl font-medium leading-9 text-slate-700">Alex</div>
            <div className="self-start mt-3.5 text-lg font-light leading-6 text-blue-700">ABC Company Pvt Ltd</div>
          </div>
        </div>
      </div>
      {/* <div className="shrink-0 self-stretch my-auto w-4 h-10" /> */}
    </section>
  );
};

export default Testimonial;