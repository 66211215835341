import React, { useRef } from "react";
import RegisteredBanner from "../RegistredAgentService/RegisteredBanner.js";
import CorporateSuppliesSec from "../AllServices/CorporateSuppliesPage/CorporateSuppliesSec.js";
import CorporateSuppliesThird from "../AllServices/CorporateSuppliesPage/CorporateSuppliesThird.js";
import OurTrustedSection from "../CommonSection/OurTrustedSection.js";
import bannerimg from "../../../Assets/Images/supbanner.png";
import CommonRegister from "../CommonSection/CommonRegister.js";
import CorporateFaq from "../AllServices/CorporateSuppliesPage/CorporateFaq.js";
import { Container } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle.js";
import ServiceForm from "../CommonSection/ServiceForm.js";

const CorporateSuplies = () => {
  const registeredTabRef = useRef(null);

  const handleScroll = () => {
    if (registeredTabRef.current) {
      registeredTabRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <RegisteredBanner
        title={"Essential supplies for   "}
        highlightText={"every legal filing"}
        subtitle={
          "Manage all your corporate documents and filings effortlessly."
        }
        buttonLabel={"Learn More"}
        onButtonClick={handleScroll}
        bannerImage={bannerimg}
      />
      {/* <OurTrustedSection /> */}
      <CorporateSuppliesSec />
      <div ref={registeredTabRef} id="registered-tab-section">
        <CorporateSuppliesThird />
      </div>
      <div className="entity-formation">
        <Container>
          <div className="text-center mt-5 mb-5">
            <CommonHeadingTitle
              heading={"Looking for"}
              blueheading={"corporate supplies?"}
            />
          </div>
          <ServiceForm />
        </Container>
      </div>
      <CommonRegister
        title={"Corporate supplies made easy and professional"}
        buttonLabel={"Start Your Business"}
        buttonLink={"/signup"}
      />
      <CorporateFaq />
    </div>
  );
};

export default CorporateSuplies;
