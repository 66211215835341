import React, { useState, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import OrderServices from "../../../../services/orderServices";
import "./InvoicePayment.css";
import { Dialog } from "primereact/dialog";
import PaymentComponent from "../../../../utils/PaymentComponent";
import authContext from "../../../../common/authContext";
import { useNavigate } from "react-router-dom";
import { Col, Form, InputGroup, Row } from "react-bootstrap";

export default function InvoicePaymentTable({ searchQuery, setTotalCount }) {
  const { setRedirectedData, globalCompany, setIsLoading } =
    useContext(authContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [error, setError] = useState(null);
  const [closeProgresbar3, setCloseProgresbar3] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState([
    "ordernumber",
    "state",
    "ordertype",
    "entityname",
    "orderdate",
    "orderamount",
    "invoicenumber",
    "status",
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await OrderServices.getInoviceDuePayment(
          globalCompany?.companyDetails?.id,
          0
        );
        const formattedData =
          response.data?.map((item) => ({
            id: item.orderId,
            ordernumber: item.orderNumber || "N/A",
            state: item.stateName || "N/A",
            ordertype: item.orderTypeName || "N/A",
            entityname: item.displayName || "N/A",
            orderdate: item.orderDate ? new Date(item.orderDate) : null,
            orderamount: item.orderAmount || "N/A",
            invoicenumber: `INV-${item.orderNumber}` || "N/A",
            status: item.statusName || "N/A",
          })) || [];

        setData(formattedData);
        setTotalCount(formattedData.length);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    initFilters();
  }, [searchQuery, setTotalCount, globalCompany]);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: "contains" },
      ordernumber: { value: null, matchMode: "contains" },
      state: { value: null, matchMode: "contains" },
      ordertype: { value: null, matchMode: "contains" },
      entityname: { value: null, matchMode: "contains" },
      orderdate: { value: null, matchMode: "dateIs" },
      orderamount: { value: null, matchMode: "contains" },
      invoicenumber: { value: null, matchMode: "contains" },
      status: { value: null, matchMode: "contains" },
    });
    setGlobalFilterValue(""); // Reset input field
  };
  

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    const updatedFilters = {
      ...filters,
      global: { value, matchMode: "contains" },
    };

    setFilters(updatedFilters);
    setGlobalFilterValue(value);
  };

  const handleView = (rowdata) => {
    setRedirectedData(rowdata.id);
    navigate("/order");
  };

  const renderHeader = () => (
    <Row>
      {/* <Col xl={6} md={6} sm={12} xs={12}>
        <MultiSelect
          value={visibleColumns}
          options={[
            { label: "Order Number", value: "ordernumber" },
            { label: "State", value: "state" },
            { label: "Order Type", value: "ordertype" },
            { label: "Entity Name", value: "entityname" },
            { label: "Order Date", value: "orderdate" },
            { label: "Order Amount", value: "orderamount" },
            { label: "Invoice Number", value: "invoicenumber" },
            { label: "Status", value: "status" },
          ]}
          onChange={(e) => setVisibleColumns(e.value)}
          placeholder="Show/Hide Columns"
          className="w-full w-20rem sm:w-16rem ms-2"
          display="chip"
        />
      </Col> */}
      <Col xl={12} md={12} sm={12} xs={12}>
        <div className="main-dash-btnn-class flex justify-content-end p-2">
          {/* <div>
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Clear"
              onClick={initFilters}
              className="common-blue-btnn-for me-2"
            />
          </div> */}
          <div>
            {/* <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Global Search"
            /> */}
             <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
              <Form.Control
                placeholder="Global Search"
                aria-describedby="basic-addon1"
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
              />
            </InputGroup>
          </div>
        </div>
      </Col>
    </Row>
  );

  const dateFilter = (options) => (
    <Calendar
      value={options.value}
      onChange={(e) => options.filterApplyCallback(e.value)}
      dateFormat="mm/dd/yy"
      placeholder="mm/dd/yyyy"
    />
  );

  const multiSelectFilter = (options, field) => {
    const uniqueValues = Array.from(new Set(data.map((item) => item[field])));
    return (
      <MultiSelect
        value={options.value}
        options={uniqueValues}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder={`Select ${field}`}
        display="chip"
      />
    );
  };

  const statusTemplate = (rowData) => (
    <span
      style={{
        color:
          rowData.status === "Need Review"
            ? "#B54708"
            : rowData.status === "Pending"
            ? "#B42318"
            : "inherit",
        backgroundColor:
          rowData.status === "Need Review"
            ? "#FFFAEB"
            : rowData.status === "Pending"
            ? "#FEF3F2"
            : "transparent",
        fontWeight: "500",
        padding: "5px 10px",
        borderRadius: "25px",
        display: "inline-block",
      }}
    >
      {rowData.status}
    </span>
  );

  const handleRowData = (data) => {
    setCloseProgresbar3(true);
    setRowData(data);
  };

  const actionsTemplate = (rowdata) => (
    <div>
      <FontAwesomeIcon
        icon={faEye}
        onClick={() => handleView(rowdata)}
        style={{ cursor: "pointer", color: "#344054", marginRight: "10px" }}
        title="View"
      />
      <span
        style={{ color: "green", fontWeight: "bold", cursor: "pointer" }}
        onClick={() => handleRowData(rowdata)}
      >
        Pay Now
      </span>
    </div>
  );

  const formatDate = (date) =>
    date ? date.toLocaleDateString("en-US") : "N/A";

  const columns = [
    { field: "ordernumber", header: "Order Number", filter: true },
    { field: "state", header: "State", filter: true },
    // { field: "ordertype", header: "Order Type", filter: true },
    {
      field: "entityname",
      header: "Entity Name",
      filter: true,
      body: (rowData) => (
        <span className="capitalize">
          {rowData.entityname}
        </span>
      ),
    } , 
      {
      field: "orderdate",
      header: "Order Date",
      filter: true,
      body: (rowData) => formatDate(rowData.orderdate),
    },
    // {
    //   field: "orderamount",
    //   header: "Order Amount",
    //   filter: true,
    //   body: (rowData) => `$${rowData.orderamount}`,
    // },
    {
      field: "orderamount",
      header: "Order Amount",
      filter: true,
      body: (rowData) => `$${parseFloat(rowData.orderamount).toFixed(2)}`,
    }
    
    // { field: "invoicenumber", header: "Invoice Number", filter: true },
    // { field: "status", header: "Status", filter: true, body: statusTemplate },
  ];

  return (
    <div className="main-table-class-datatable">
      <div className="card">
        {error && <div className="alert alert-danger">{error}</div>}
        <DataTable
          value={data}
          paginator
          rows={5}
          loading={loading}
          filters={filters}
          globalFilterFields={[
            "ordernumber",
            "state",
            "ordertype",
            "entityname",
            "orderamount",
            "invoicenumber",
            "status",
          ]}
          header={renderHeader()}
          emptyMessage="No records found."
          scrollable
        >
          {columns
            .filter((col) => visibleColumns.includes(col.field))
            .map((col, idx) => (
              <Column
                sortable
                key={idx}
                field={col.field}
                header={col.header}
                body={col.body}
                // filter={col.filter}
                filterElement={
                  col.field === "orderdate" ? dateFilter : multiSelectFilter
                }
                className="font-fam-for-all datatable-lg-col capitalize"
              />
            ))}
             <Column
            header="Actions"
            body={actionsTemplate}
            className="font-fam-for-all datatable-lg-col"
          />
        </DataTable>
      </div>
      <PaymentComponent
        closeProgresbar3={closeProgresbar3}
        setCloseProgresbar3={setCloseProgresbar3}
        data={rowData}
      />
    </div>
  );
}
