import React from 'react';

const benefitsData = [
  {
    title: "Compliance and legal requirements",
    description: "Registered agents ensures your business meets state requirements, avoiding legal issues and penalties for non-compliance."
  },
  {
    title: "Privacy and security",
    description: "Registered agents protects your personal address by using thier own address for public records, keeping legal documents and sensitive information secure and confidential."
  },
  {
    title: "Convenience and reliability",
    description: "Registered agents provides a reliable point of contact to receive legal documents and notifications, ensuring you never miss important deadlines, even when you're not available and working in multiple states."
  },
  {
    title: "Professionalism and organization",
    description: "It adds a level of professionalism to your business operations and keeps your business filings organized, allowing you to focus on your core operations."
  }
];

function Benefits() {
  return (
    <section className="flex flex-col mt-7 w-full">
      <div className="flex flex-col justify-center pt-16 pb-20 w-full max-md:max-w-full">
        <h2 className="text-4xl font-semibold text-center text-gray-800 max-md:max-w-full">
          Why should your business have a<br />
          <span className="text-blue-700">registered agent</span>?
        </h2>
        <div className="flex flex-wrap gap-10 justify-center pt-5 mt-10 w-full max-md:max-w-full">
          <div className="flex flex-col justify-center p-5 my-auto text-gray-800 min-w-[240px] w-[511px] max-md:max-w-full">
            {benefitsData.map((benefit, index) => (
              <React.Fragment key={index}>
                <div className="flex flex-col justify-center w-full max-md:max-w-full">
                  <h3 className="gap-4 self-stretch w-full text-2xl mt-2 font-semibold leading-tight max-md:max-w-full">
                    {benefit.title}
                  </h3>
                  <p className="mt-4 text-base font-light leading-7 max-md:max-w-full">
                    {benefit.description}
                  </p>
                </div>
                {index < benefitsData.length - 1 && (
                  <div className="flex self-center mt-4 max-w-full border-t border-zinc-300 min-h-[1px] w-[471px]" />
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="flex flex-wrap justify-center items-start p-2.5 h-full min-w-[240px] w-[575px] max-md:max-w-full">
            <div className="flex flex-col grow shrink min-w-[240px] w-[444px]">
              <div className="flex flex-col items-center px-14 w-full min-h-[665px] max-md:px-5 max-md:max-w-full">
                <div className="flex justify-center items-center max-w-full w-[444px]">
                  <div className="flex overflow-hidden relative flex-col flex-1 shrink justify-center items-center self-stretch px-11 py-72 my-auto w-full rounded-3xl basis-0 max-w-[444px] min-h-[665px] min-w-[240px] shadow-[0px_0px_10px_rgba(0,0,0,0.5)] max-md:px-5 max-md:py-24 max-md:max-w-full">
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d25827044e772ced8f120d27b9829699eb72a2cf1f453dd209561109fc47155?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0" alt="Background illustration" className="object-cover absolute inset-0 size-full" />
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4ed2740f106a2282a101d299ba88904d1a06950f55059bf238ffc2fa2c968c5?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0" alt="Registered agent icon" className="object-contain w-20 aspect-square" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Benefits;