import React from 'react';

const Process = () => {
  return (
    <section className="flex flex-col px-8 pt-20 mt-24 max-w-full rounded-lg w-[1240px] max-md:px-5 max-md:mt-10">
      <h2 className="mt-4 text-5xl font-bold tracking-tighter text-blue-700 capitalize leading-[52.8px] max-md:max-w-full">
        <span className="font-medium font-web-color1">Here's how we do everything </span>
        <span className="font-medium font-web-color">seamlessly!</span>
      </h2>
      <div className="flex overflow-hidden relative flex-col items-start pt-16 pb-9 min-h-[584px] max-md:pr-5 max-md:max-w-full">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/09775194039ba405f1c5ca06aee8f919e0c2442535942ca61d0a10becbf3766e?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="object-cover absolute inset-0 size-full" alt="Process illustration" />
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a1ff47a7ecd0a1bccb48815652eb0adab5ace854cd98837d056f810a8e15c17?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="mt-80 max-w-full aspect-[0.72] w-[119px] max-md:mt-10" alt="Process step" />
      </div>
      <div className="flex flex-col px-14 py-9 bg-white text-slate-700 max-md:px-5 max-md:max-w-full">
        <h3 className="text-2xl font-medium tracking-tighter text-center capitalize max-md:max-w-full">
          With decades of proven experience, our team is prepared to help you achieve your goals, and beyond.
        </h3>
        <div className="flex grid flex-wrap gap-0 content-start items-start  text-lg font-light max-md:pr-5">
          <div className='col-4'>
            <ProcessFeature
              icon="https://cdn.builder.io/api/v1/image/assets/TEMP/8369dda86ce163ec02fbe68ab9b257660f206d38157a1978bf62f58c93d6dd70?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
              text="Your search for the most efficient way to manage your business is over."
            />
          </div>
          <div className='col-4'>

            <ProcessFeature
              icon="https://cdn.builder.io/api/v1/image/assets/TEMP/564821f794ba600b3863bbd3a0273faf2fa2c08aa8d114ed8a626f96c50aa3d2?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
              text="Spend your time where it'll bring you the most returns. Hint, it isn't in the mailroom."
            />
          </div>
          <div className='col-4'>
            <ProcessFeature
              icon="https://cdn.builder.io/api/v1/image/assets/TEMP/54ae019679577f51730fd51c96174e2be86315ed028a51f42c735cca5f12e003?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
              text="We'll take care of the admin work so that you can finally focus on the fun part of your business."
            />
          </div>


        </div>
      </div>
    </section>
  );
};

const ProcessFeature = ({ icon, text }) => (
  <div className="flex gap-4 justify-center mt-5">
    <img loading="lazy" src={icon} className="shrink-0 self-start aspect-square w-[26px]" alt="" />
    <p>{text}</p>
  </div>
);

export default Process;