import { useState } from "react";

const TooltipButton = ({ tooltipText }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="relative flex items-center">
      {/* Tooltip */}
      {showTooltip && (
        <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 bg-white text-gray-600 text-sm px-3 py-2 rounded-lg border border-gray-300 shadow-md w-max">
          {tooltipText}
        </div>
      )}

      {/* Button with Icon */}
      <button
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className="ml-2 bg-white border-none cursor-pointer p-2 rounded-md shadow"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M6.99984 4.6665V6.99984M6.99984 9.33317H7.00567M12.8332 6.99984C12.8332 10.2215 10.2215 12.8332 6.99984 12.8332C3.77818 12.8332 1.1665 10.2215 1.1665 6.99984C1.1665 3.77818 3.77818 1.1665 6.99984 1.1665C10.2215 1.1665 12.8332 3.77818 12.8332 6.99984Z"
            stroke="#004EEC"
            strokeWidth="1.16667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
};

export default TooltipButton;
