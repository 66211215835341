import React, { useEffect } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "./CommonHeadingTitle";
import "../CommonSection/CommonStyle/CommonStyle.css";
import AOS from "aos";
import "aos/dist/aos.css";

const FaqCommonSection = () => {
  const faqItems = [
    {
      key: "0",
      question: "How secure is my financial data on your platform? ",
      answer:
        "We take the security and privacy of your financial data very seriously. Our platform employs industry-standard encryption protocols to safeguard your information during transmission and storage.",
    },
    {
      key: "1",
      question: "Do you have a physical office?",
      answer:
        "Yes, we have a physical office located at: 301 Mill Road, Suite U5, Hewlett, NY 11557.  If you need assistance, you can reach us at (866) 638-3309 or (718) 569-2703.  While our platform operates online to provide seamless business filings, our support team is always available to assist with any inquiries.",
    },
    {
      key: "2",
      question: "How long does the business formation process take?",
      answer:
        "The processing time depends on the state and type of business entity you choose. Generally, it can take anywhere from a few hours (for expedited services) to several weeks. We provide estimated processing times during the application process based on your selected state.",
    },
    {
      key: "3",
      question: "What type of business entity should I choose?",
      answer:
        "The right business entity depends on various factors, including liability protection, taxation, and operational structure. Common options include: LLC (Limited Liability Company): Offers flexibility and limited liability protection. Corporation (C-Corp or S-Corp): Suitable for businesses seeking investment and structured growth. Sole Proprietorship: Simple structure but no liability protection. Partnership: Best for businesses with multiple owners. Consulting a legal or financial advisor can help determine the best fit for your business needs.",
    },
    {
      key: "4",
      question: "What is an EIN, and do I need one?",
      answer:
        "An EIN (Employer Identification Number) is a unique number issued by the IRS for tax purposes. It is required for: Hiring employees Opening a business bank account Filing business taxes Establishing business credit If you're forming an LLC, corporation, or partnership, an EIN is essential. Sole proprietors may also need one if they hire employees or open a business bank account.",
    },
    {
      key: "5",
      question:
        "Which state should I choose to register my business in?",
      answer:
        "The best state for registration depends on your business goals. Common considerations include: Your Home State: Generally the best choice if you operate locally. Delaware: Popular for corporations due to business-friendly laws. Wyoming & Nevada: Known for lower taxes and strong privacy protections. If you're unsure, consider factors like tax regulations, compliance requirements, and future expansion plans before making a decision",
    },
  ];

  // Split FAQs into two columns
  const firstColumnFaqs = faqItems.filter((_, index) => index % 2 === 0);
  const secondColumnFaqs = faqItems.filter((_, index) => index % 2 !== 0);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="main-section-class" data-aos="fade-up"
    data-aos-duration="3000">
      <div className="common-faq">
        <Container>
          <div className="mb-5 mt-5">
            <CommonHeadingTitle heading={"FAQ"} />
          </div>
          <Accordion defaultActiveKey="0">
            <Row>
              <Col lg={6} md={6} sm={12}>
                {firstColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
              <Col lg={6} md={6} sm={12}>
                {secondColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
            </Row>
          </Accordion>
        </Container>
      </div>
    </section>
  );
};

export default FaqCommonSection;
