import React, { useRef } from "react";
import RegisteredBanner from "../RegistredAgentService/RegisteredBanner.js";
import UcFillingSec from "../AllServices/UcFillingPage/UcFillingSec.js";
import UcFillingThird from "../AllServices/UcFillingPage/UcFillingThird.js";
import OurTrustedSection from "../CommonSection/OurTrustedSection.js";
import bannerimg from "../../../Assets/Images/Img - creating an llc.png";
import CommonRegister from "../CommonSection/CommonRegister.js";
import UccFillingFaq from "../AllServices/UcFillingPage/UccFillingFaq.js";
import { Container } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle.js";
import ServiceForm from "../CommonSection/ServiceForm.js";

const UccFilingsMain = () => {
  const registeredTabRef = useRef(null);

  const handleScroll = () => {
    if (registeredTabRef.current) {
      registeredTabRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <RegisteredBanner
        title={"Secure your business "}
        highlightText={"interests with UCC filings"}
        subtitle={"Secure your business transactions with easy UCC filings."}
        buttonLabel={"Learn More"}
        onButtonClick={handleScroll}
        bannerImage={bannerimg}
      />
      {/* <OurTrustedSection /> */}
      <UcFillingSec />
      <div ref={registeredTabRef} id="registered-tab-section">
        <UcFillingThird />
      </div>
      <div className="entity-formation">
        <Container>
          <div className="text-center mt-5 mb-5">
            <CommonHeadingTitle
              heading={"Need to"}
              blueheading={"file a UCC or search"}
            />
            <CommonHeadingTitle heading={"for a lien? "} />
          </div>
          <ServiceForm />
        </Container>
      </div>
      <CommonRegister
        title={"Protect your assets with quick and secure UCC filings"}
        buttonLabel={"Start Your Business"}
        buttonLink={"/signup"}
      />
      <UccFillingFaq />
    </div>
  );
};

export default UccFilingsMain;
