import React, { useContext, useEffect } from "react";
import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { Message } from "primereact/message";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import ShiftsServices from "../../services/demoServices";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import { Avatar } from "primereact/avatar";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";
import errorMessages, { globalMessages } from "../../utils/errorMessages";
import { InputMask } from "primereact/inputmask";
import CreditCardServices from "../../services/strapiService";
import UserServices from "../../services/userService";
import templateCsv from "../../Assets/users.csv";

import { ProgressBar } from "primereact/progressbar";

import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import authContext from "../../common/authContext";
import { showToast } from "../../utils/toastUtils";
import { checkDomain } from "../../utils/dnsEmailChecker";

const AddVstateEmployee = ({
  visible,
  setVisible,
  pageRefresh,
  setPageRefresh,
}) => {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh,
    redirectedData,
    setRedirectedData,
  } = useContext(authContext);

  const [value, setValue] = useState("");
  const [ingredient, setIngredient] = useState("");
  const toast = useRef(null);
  const [upload, setUpload] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isEqualAddress, setIsEqualAddress] = useState(true);
  const [allCustomer, setAllCustomer] = useState([]);

  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [cityErr, setcityErr] = useState("");

  const toastCenter = useRef(null);

  const [selectedSkills, setSelectedSkills] = useState(null);
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");

  const [lastName, setLastName] = useState("");

  const [website, setWebsite] = useState("");

  const [company, setCompany] = useState("");

  const [phone, setPhone] = useState("");

  const [address, setAddress] = useState("");

  const [city, setCity] = useState("");

  const [state, setState] = useState("");

  const [zip, setZip] = useState("");

  const [emailErr, setEmailErr] = useState("");

  const [passwordErr, setPasswordErr] = useState("");

  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [firstNameErr, setFirstNameErr] = useState("");

  const [lastNameErr, setLastNameErr] = useState("");

  const [phoneErr, setPhoneErr] = useState("");

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [roleId, setRoleId] = useState(null);

  const [isShowContent2, setIsShowContent2] = useState(false);

  const [isShowContent3, setIsShowContent3] = useState(false);

  const [globalErr, setGlobalErr] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const [companyId, setCompanyId] = useState(null);

  const [isDisabled, setIsDisabled] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [isContentVisible, setIsContentVisible] = useState(true);

  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    // toast.current.show({
    //   severity: "info",
    //   summary: "Success",
    //   detail: "File uploaded",
    // });

      showToast("success",`File uploaded`)
    
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 1 MB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}
          ></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-file mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop File Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-file",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const dialogView = () => setVisible(false);
  const cancelView = () => {
    setVisible(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setIngredient("");
    setPhone("");
    setPassword("");
    setConfirmPassword("");
    setFirstNameErr("");
    setLastNameErr("");
    setPasswordErr("");
    setConfirmPasswordErr("");
    setPhoneErr("");
    setcityErr("");
    setEmailErr("");
  };

  useEffect(() => { }, []);

  let newObject = window.localStorage.getItem("user");
  let updatedUser = Object.assign({}, JSON.parse(newObject));

  function checkIdExists(arr, idToCheck) {
    const result = arr.find((item) => item.id === idToCheck);
    return result ? result.id : null;
  }

  useEffect(() => {
    setSelectedCountry({ name: "United States", code: "US", dialCode: "+1" });

    if (updatedUser) {
      if (updatedUser.companyRoles !== undefined) {
        if (updatedUser.companyRoles.length > 0) {
          if (updatedUser.companyRoles[0].roles.length > 0) {
            const id = checkIdExists(updatedUser.companyRoles[0].roles, 2);
            setRoleId(id);
            console.log(id);
            setCompanyId(updatedUser.companyRoles[0].companyId);
          }
        }
      }
    }
  }, []);

  // const handleCity = (e) => {
  //   setEmailErr("");
  //   const value = e.target.value;
  //   if (value === "") {
  //     setEmailErr(errorMessages.emailRequired);
  //   }
  //   if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
  //     setEmailErr(errorMessages.emailValidation);
  //   }
  //   setIngredient(e.target.value);
  // };

  const handleCity = async (e) => {
    setEmailErr('')
    const value = e.target.value
    setIngredient(e.target.value)
    if (value === "") {
        setEmailErr(errorMessages.emailRequired)
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
        setEmailErr(errorMessages.emailValidation);
    }
    const domain = value.split("@")[1]; 
    const isDomainValid = await checkDomain(domain);
    if (!isDomainValid) {
        setEmailErr("Email domain is not exist");
    }
    
   
}

  const handleEmail = (e) => {
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setEmail(e.target.value);
  };

  const handleFirstName = (e) => {
    setFirstNameErr("");
    let value = e.target.value;
    if (value === "") {
      setFirstNameErr(errorMessages.firstNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/^[a-zA-Z]+([a-zA-Z]+)*$/.test(value)) {
        setFirstNameErr(errorMessages.firstNameValidation);
      }
    }
    setFirstName(e.target.value);
  };

  const handlePassword = (e) => {
    setPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setPasswordErr(errorMessages.passwordRequired);
    }
    if (value && value.trim() !== "") {
      let errors = [];

      if (!/[A-Z]/.test(value)) {
        errors.push("at least one uppercase letter");
      }
      if (!/[a-z]/.test(value)) {
        errors.push("at least one lowercase letter");
      }
      if (!/\d/.test(value)) {
        errors.push("at least one digit");
      }
      if (!/[@$!%*#?&_]/.test(value)) {
        errors.push("at least one special character (@$!%*#?&_)");
      }
      if (value.length < 8) {
        errors.push("a minimum length of 8 characters");
      }

      if (errors.length > 0) {
        setPasswordErr(`Password must contain ${errors.join(", ")}.`);
      }
    }
    setPassword(e.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const handleConfirmPassword = (e) => {
    setConfirmPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
    }
    if (value && value.trim() !== "") {
      let errors = [];

      if (!/[A-Z]/.test(value)) {
        errors.push("at least one uppercase letter");
      }
      if (!/[a-z]/.test(value)) {
        errors.push("at least one lowercase letter");
      }
      if (!/\d/.test(value)) {
        errors.push("at least one digit");
      }
      if (!/[@$!%*#?&_]/.test(value)) {
        errors.push("at least one special character (@$!%*#?&_)");
      }
      if (value.length < 8) {
        errors.push("a minimum length of 8 characters");
      }

      if (errors.length > 0) {
        setConfirmPasswordErr(`Password must contain ${errors.join(", ")}.`);
      }
    }
    setConfirmPassword(e.target.value);
  };

  const handleLastName = (e) => {
    setLastNameErr("");
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/^[a-zA-Z]+([a-zA-Z]+)*$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setLastName(e.target.value);
  };

  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
  };

  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;

    let inputValue = value.replace(/\D/g, ""); // Remove non-digit characters

    if (inputValue.length > 10) {
        inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }

    let formattedValue = inputValue;
    if (inputValue.length === 10) {
        formattedValue = inputValue.replace(
            /(\d{3})(\d{3})(\d{4})/,
            "($1) $2-$3"
        );
    }

    // Update input field value and state
    e.target.value = formattedValue;
    setPhone(formattedValue);

    // Check if input is empty
    if (value.trim() === "") {
        setPhoneErr(errorMessages.phoneRequired);
        return; // Stop further execution
    }

    console.log("Input Length:", inputValue.length);

    // Validate phone number format only if length is 10
    if (inputValue.length <= 9) {
        if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formattedValue)) {
            setPhoneErr(errorMessages.phoneValidation);
        }
    }
};

  // const handlePhone = (e) => {
  //   setPhoneErr("");
  //   let value = e.target.value;

  //   let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
  //   if (inputValue.length > 10) {
  //     inputValue = inputValue.slice(0, 10); // Limit to 10 digits
  //   }
  //   const formattedValue = inputValue.replace(
  //     /(\d{3})(\d{3})(\d{4})/,
  //     (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
  //   );
  //   if (inputValue.length === 10) {
  //     // Only show formatted value after 10 digits
  //     e.target.value = formattedValue;
  //     setPhone(formattedValue); // Update state with formatted number
  //   } else {
  //     e.target.value = inputValue;
  //     setPhone(inputValue);
  //   }

  //   if (value === "") {
  //     setPhoneErr(errorMessages.phoneRequired);
  //   }
  //   if (value && value.trim() !== "" && inputValue.length <= 9) {
  //     if (
  //       !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(value)
  //     ) {
  //       setPhoneErr(errorMessages.phoneValidation);
  //     }
  //   }
  //   setPhone(e.target.value);
  // };


  const handleToggle2 = () => {
    setIsShowContent2(!isShowContent2);
  };

  const handleToggle3 = () => {
    setIsShowContent3(!isShowContent3);
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none">
    <circle cx="9.79519" cy="2.00625" r="2.00625" fill="#A3D5C9"/>
<circle cx="7.67096" cy="6.13675" r="1.06213" fill="#A3D5C9"/>
<circle cx="1.23915" cy="11.5064" r="1.23915" fill="#A3D5C9"/>
<circle cx="15.2829" cy="12.4506" r="1.47518" fill="#A3D5C9"/>
<circle cx="19.0004" cy="8.26101" r="1.06213" fill="#A3D5C9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6886 0.650298C15.7808 1.14561 15.3314 1.88426 15.3314 2.88125C15.3314 3.25051 15.4132 3.69897 15.5172 3.90007C15.6192 4.09751 15.6675 4.29412 15.6244 4.33672C15.5812 4.37932 14.4255 5.56549 13.0562 6.97258L10.5663 9.5309L10.2051 9.33783C9.72642 9.08209 8.5438 9.08256 8.0643 9.33889L7.70094 9.53302L6.31214 8.14057L4.92323 6.74811L5.10155 6.40316C5.40803 5.8106 5.4491 5.28143 5.23785 4.6484C4.87201 3.5524 4.02785 2.93907 2.885 2.93907C1.62401 2.93907 0.628439 3.83469 0.499331 5.08529C0.309446 6.92561 2.08686 8.27074 3.82911 7.60537L4.36643 7.40014L5.74319 8.76298L7.11995 10.1258L6.91815 10.5489C6.61756 11.1792 6.65663 12.0617 7.01421 12.7184C8.17748 14.8542 11.3541 14.1761 11.5336 11.7535C11.5703 11.2579 11.5252 10.9367 11.3712 10.5952L11.1581 10.1225L13.751 7.52949L16.3439 4.93659L16.8074 5.13037C17.5107 5.42411 18.5064 5.31601 19.138 4.87711C20.5128 3.92202 20.5099 1.83623 19.1323 0.87901C18.7142 0.58834 18.4875 0.520836 17.8341 0.49204C17.2702 0.467257 16.9408 0.512693 16.6886 0.650298Z" fill="white"/>
</svg>
 `;

  console.log(roleId);

  const onFileUpload = (e) => {
    e.preventDefault();

    let formData = new FormData();

    formData.append("file", selectedFile);

    console.log(selectedFile);

    if (selectedFile === null) {
      // toast.current.show({
      //   severity: "error",
      //   summary: "Error",
      //   detail: "Please choose the file",
      //   life: 2500,
      // });
      showToast("error",`Please choose the file`)

    }

    UserServices.bulkUserUpload(formData, roleId)
      .then((response) => {
        console.log(response);
        if (response.status === 201) {
        }
        if (response.status === 500) {
          // toast.current.show({
          //   severity: "error",
          //   summary: "Failed To Upload",
          //   detail: "Network error, Please try again!",
          //   life: 3000,
          // });
      showToast("error",`Network error, Please try again!`)

        }
        if (response.status === 504) {
          // toast.current.show({
          //   severity: "error",
          //   summary: "Failed To Upload",
          //   detail: "Gateway timeout!",
          //   life: 3000,
          // });
      showToast("error",`Gateway timeout!`)
        
        }
      })
      .catch((e) => {
        // toast.current.show({ severity: 'success', summary: 'Uploaded', detail: e.response.data.errorMessage, life: 3000 });
        console.log(e);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsDisabled(true);
    const actualdata = {
      properties: [
        { property: "firstname", value: firstName },
        { property: "lastname", value: lastName },
        { property: "website", value: website },
        { property: "company", value: company },
        { property: "phone", value: phone },
        { property: "address", value: address },
        { property: "city", value: city },
        { property: "state", value: state },
        { property: "zip", value: zip },
      ],
    };

    const userdata = {
      blocked: false,
      confirmed: checked,
      email: email,
      password: password,
      username: ingredient,
      firstName: firstName,
      lastName: lastName,
      city: city,
      website: website,
      company: company,
      phone: phone,
      address: address,
      state: state,
      zip: zip,
      prefix: null,
      user_id: null,
      fullName: `${firstName} ${lastName}`,
    };

    const tempActualData = {
      salution: null,
      username: ingredient,
      password: password,
      firstName: firstName,
      lastName: lastName,
      mobileNo: phone,
      companyName: "vState Filings",
      rolesId: 4,
      statusId: 1,
    };

    console.log(tempActualData);

    console.log(roleId);
    if (roleId !== null && roleId === 1) {
      tempActualData["companyId"] = companyId;
    }

    console.log(tempActualData);
    if (
      ingredient !== "" &&
      password !== "" &&
      firstName !== "" &&
      lastName !== "" &&
      phone !== "" &&
      confirmedPassword !== "" &&
      password === confirmedPassword &&
      passwordErr === "" &&
      confirmPasswordErr === "" &&
      firstNameErr === "" &&
      lastNameErr === "" &&
      emailErr === "" &&
      phoneErr === ""
    ) {
      setIsTabRefresh(true);
      CreditCardServices.createModeldata(tempActualData, "signup")
        .then((res) => {
          console.log(res);
          setIsDisabled(false);

          setFirstName("");
          setLastName("");
          setEmail("");
          setIngredient("");
          setPhone("");
          setPassword("");
          setConfirmPassword("");
          setIsTabRefresh(false);

          // if (toast.current !== undefined) {
          //   toast.current.show({
          //     severity: "success",
          //     summary: "Employee added Successfully",
          //     life: 3000,
          //     content: (props) => (
          //       <div
          //         className="flex flex-column align-items-left"
          //         style={{ flex: "1" }}
          //       >
          //         <div className="flex align-items-center gap-2">
          //           <Avatar
          //             shape="circle"
          //             style={{ backgroundColor: "#0047FF", color: "#ffffff" }}
          //           >
          //             <div dangerouslySetInnerHTML={{ __html: svgContent }} />
          //           </Avatar>
          //           <span className="font-bold text-900">vState Filings</span>
          //         </div>
          //         <div className="font-medium text-lg my-3 text-900">
          //           {props.message.summary}
          //         </div>
          //         {/* <Button className=" signup-btn vstate-button flex" label="Ok" severity="success" onClick={() => navigate("/signin")}></Button> */}
          //       </div>
          //     ),
          //   });
          // }

          showToast("success",`Employee added Successfully`)
          setTimeout(() => {
            setVisible(false);
          }, 3000);
          // navigate("/signin")
        })
        .catch((err) => {
          console.log(err);
          setIsDisabled(false);
          setVisible(true);
          setIsTabRefresh(false);
          if (err.response !== undefined) {
            if (err.response.status === 701) {
              console.log(err.response);
              if (err.response.data !== undefined) {
                setGlobalErr(errorMessages.userAlreadyPresent);
                // if (toast.current !== undefined) {
                //   toast.current.show({
                //     severity: "warn",
                //     summary: "Warning",

                //     content: (props) => (
                //       <div
                //         className="flex flex-column align-items-left"
                //         style={{ flex: "1" }}
                //       >
                //         <div className="flex align-items-center gap-2">
                //           <Avatar
                //             shape="circle"
                //             style={{
                //               backgroundColor: "#0047FF",
                //               color: "#ffffff",
                //             }}
                //           >
                //             <div
                //               dangerouslySetInnerHTML={{ __html: svgContent }}
                //             />
                //           </Avatar>
                //           <span className="font-bold text-900">
                //             vState Filings
                //           </span>
                //         </div>
                //         <div className="font-medium text-lg my-3 text-900">
                //           Employee already present!
                //         </div>
                //       </div>
                //     ),
                //     life: 3000,
                //   });
                // }

          showToast("error",`Employee already present!`)

              }
            } else {
              setGlobalErr(errorMessages.somethingWrong);
              // if (toast.current !== undefined) {
              //   toast.current.show({
              //     severity: "warn",
              //     summary: "Warning",

              //     content: (props) => (
              //       <div
              //         className="flex flex-column align-items-left"
              //         style={{ flex: "1" }}
              //       >
              //         <div className="flex align-items-center gap-2">
              //           <Avatar
              //             shape="circle"
              //             style={{
              //               backgroundColor: "#0047FF",
              //               color: "#ffffff",
              //             }}
              //           >
              //             <div
              //               dangerouslySetInnerHTML={{ __html: svgContent }}
              //             />
              //           </Avatar>
              //           <span className="font-bold text-900">
              //             vState Filings
              //           </span>
              //         </div>
              //         <div className="font-medium text-lg my-3 text-900">
              //           {errorMessages.somethingWrong}
              //         </div>
              //       </div>
              //     ),
              //     life: 3000,
              //   });
              // }

          showToast("error",`${errorMessages.somethingWrong}`)

            }
          } else {
            setGlobalErr(errorMessages.somethingWrong);
            // if (toast.current !== undefined) {
            //   toast.current.show({
            //     severity: "warn",
            //     summary: "Warning",

            //     content: (props) => (
            //       <div
            //         className="flex flex-column align-items-left"
            //         style={{ flex: "1" }}
            //       >
            //         <div className="flex align-items-center gap-2">
            //           <Avatar
            //             shape="circle"
            //             style={{ backgroundColor: "#0047FF", color: "#ffffff" }}
            //           >
            //             <div dangerouslySetInnerHTML={{ __html: svgContent }} />
            //           </Avatar>
            //           <span className="font-bold text-900">vState Filings</span>
            //         </div>
            //         <div className="font-medium text-lg my-3 text-900">
            //           {errorMessages.somethingWrong}
            //         </div>
            //       </div>
            //     ),
            //     life: 3000,
            //   });
            // }

          showToast("error",`${errorMessages.somethingWrong}`)

          }
        });
    } else {
      setIsDisabled(false);
      if (ingredient === "") {
        console.log(errorMessages.emailRequired);
        setEmailErr(errorMessages.emailRequired);
      }
      if (password === "") {
        setPasswordErr(errorMessages.passwordRequired);
      }
      if (firstName === "") {
        setFirstNameErr(errorMessages.firstNameRequired);
      }
      if (lastName === "") {
        setLastNameErr(errorMessages.lastNameRequired);
      }
      if (phone === "") {
        setPhoneErr(errorMessages.phoneRequired);
      }
      if (confirmedPassword === "") {
        setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
      }
      if (password !== confirmedPassword) {
        // setVisible(true)
        // if (toast.current !== undefined) {
        //   toast.current.show({
        //     severity: "warn",
        //     summary: "Warning",

        //     content: (props) => (
        //       <div
        //         className="flex flex-column align-items-left"
        //         style={{ flex: "1" }}
        //       >
        //         <div className="flex align-items-center gap-2">
        //           <Avatar
        //             shape="circle"
        //             style={{ backgroundColor: "#0047FF", color: "#ffffff" }}
        //           >
        //             <div dangerouslySetInnerHTML={{ __html: svgContent }} />
        //           </Avatar>
        //           <span className="font-bold text-900">vState Filings</span>
        //         </div>
        //         <div className="font-medium text-lg my-3 text-900">
        //           {errorMessages.passwordMatched}
        //         </div>
        //       </div>
        //     ),
        //     life: 3000,
        //   });
        // }

        // showToast("error",`${errorMessages.passwordMatched}`)

        // setGlobalErr(errorMessages.passwordMatched)
      }
    }
  };

  const customHeader = (
    <div className="flex align-items-center gap-2">
      <Avatar
        image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
        shape="circle"
      />
      <span className="font-bold">Amy Elsner</span>
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      <Toast ref={toastCenter} position="center" />
      <Sidebar
        position="right"
        header={customHeader}
        visible={visible}
        className="dialog-box"
        onHide={() => setVisible(false)}
      >
        <div className="grid custom-sidebar-header">
          <div className="grid col-12 col-sm-12 col-md-12 col-lg-11 px-4 pt-4 pb-0">
            <div className="col-12 sidebar-header-text1 pb-0">Add Employee</div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-1 flex justify-content-end pt-4 pb-0">
            <button onClick={dialogView}>
              <i className="pi pi-times"></i>
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 sidebar-header-text2 px-4">
            Fill in the below details to add a new employee.
          </div>
        </div>

        {/* <form id='myform' className='form-spacing mt-4' onSubmit={onFileUpload}>
                    <div className='input-sidebar-card mt-4'>
                        <div className='px-6 py-4'>
                            <div className='flex' style={{ justifyContent: "space-between" }}>
                                <div className='font-fam-for-all'>
                                    <span><i className='pi pi-address-book'></i></span>
                                    Bulk upload contacts
                                </div>
                            </div>
                            <div>
                                Manage your company’s users and their access with simple file upload.
                            </div>
                            <div className='card pt-6 px-2 mt-3'>
                                <div className="flex justify-content-center align-items-center pb-2">
                                    <div className="text-center">
                                        <Button
                                            icon={selectedFile ? '' : 'pi pi-upload'}
                                            label={selectedFile ? selectedFile.name : ''}
                                            className="p-button-rounded bg-gray-400 border-gray-400 p-button-primary p-mb-3"
                                            aria-label="Upload"
                                            onClick={handleClick}
                                        />
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                            accept=".csv"
                                        />
                                        <div>
                                            <button type='submit' className="p-mb-3 font-fam-for-all datatable-highlight-color webapp-global-sm-size  hover:text-blue-700" >Click here to upload</button>
                                        </div>
                                        <div className="upload-rules font-fam-for-all">
                                            <p className='webapp-global-sm-size'>CSV (max. 250kb)</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-devider'></div>
                                <div className='flex align-items-center justify-content-center py-2'>
                                    <a href={templateCsv} download="template.csv" className='bulk-user-download-template-button-main'>
                                        <div className='bulk-user-download-template-button-text'>
                                            Download Template
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form> */}

        {/* <div className='input-sidebar-card mt-4'>
                    <div className='px-6 py-4'>
                        <Toast ref={toast}></Toast>

                        <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                        <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                        <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                        <FileUpload ref={fileUploadRef} name="demo[]" url="/api/upload" multiple accept="file/*" maxFileSize={1000000}
                            onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                            headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                    </div>
                </div> */}

        <form id="myform" className="form-spacing mt-4" onSubmit={handleSubmit}>
          <Toast ref={toast} />

          <div className=" input-sidebar-card mt-4">
            <div className="px-6 pt-4">
              <div className="flex" style={{ justifyContent: "space-between" }}>
                <div className="font-fam-for-all">
                  <span>
                    <i className="pi pi-address-book"></i>
                  </span>
                  Employee Details
                </div>
              </div>
            </div>

            <div className="form-content-signup pb-5">
              <div className="grid gap-3 justify-content-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                  <div className=" justify-content-center dialog-form-field-space">
                    <div className="field">
                      {/* <label htmlFor='' className='form-label font-fam-for-all'>First Name <span className='form-field-mandatory'>*</span></label> */}
                      {/* <span className="p-float-label ">

                                        <div className="floating-label-input">
                                            <span className={`p-float-label ${value ? 'p-input-filled' : ''}`}>
                                                <InputText
                                                    // className='dialog-form-input-field'
                                                    style={{ height: '40px', width: "100%" }}
                                                    maxLength={50} value={firstName} onChange={(e) => setFirstName(e.target.value)} name='cityName'
                                                />
                                                <label htmlFor='input' className='form-label font-fam-for-all'>First Name <span className='form-field-mandatory'>*</span></label>
                                            </span>
                                        </div>
                                    </span> */}

                      <span className=" ">
                        <div class="relative">
                          <InputText
                            // className='dialog-form-input-field'
                            id="floating_outlined1"
                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            style={{ height: "40px", width: "100%" }}
                            placeholder=""
                            maxLength={50}
                            value={firstName}
                            onChange={handleFirstName}
                            name="cityName"
                          />

                          <label
                            htmlFor="floating_outlined1"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            First Name
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {firstNameErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {firstNameErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                  <div className=" justify-content-center dialog-form-field-space">
                    <div className="field">
                      {/* <label htmlFor='' className='form-label font-fam-for-all'>Last Name <span className='form-field-mandatory'>*</span></label> */}

                      <div class="relative">
                        <InputText
                          // className='dialog-form-input-field'
                          id="floating_outlined2"
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          style={{ height: "40px", width: "100%" }}
                          maxLength={50}
                          value={lastName}
                          onChange={handleLastName}
                          name="cityName"
                          placeholder=""
                        />

                        <label
                          htmlFor="floating_outlined2"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Last Name
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {lastNameErr !== "" ? (
                        <p className="error-msg font-fam-for-all">
                          {lastNameErr}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className=" justify-content-center dialog-form-field-space grid  mt-2">
                <div className="field col-12 col-sm-12 col-md-12 col-lg-10 p-0">
                  {/* <label htmlFor='' className='form-label font-fam-for-all'>Email <span className='form-field-mandatory'>*</span></label> */}
                  {/* <span className="p-float-label ">



                                <div className="floating-label-input">
                                    <span className={`p-float-label ${value ? 'p-input-filled' : ''}`}>
                                        <InputText
                                            style={{ width: '100%', height: '40px' }}
                                            maxLength={50} value={ingredient} onChange={handleCity} name='cityName'
                                        />
                                        <label htmlFor='input' className='form-label font-fam-for-all'>Email <span className='form-field-mandatory'>*</span></label>
                                    </span>
                                </div>


                                <p className='error-msg font-fam-for-all'>{emailErr}</p>
                            </span> */}

                  <span className=" ">
                    <div class="relative">
                      <InputText
                        // className='dialog-form-input-field'
                        id="floating_outlined3"
                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        style={{ width: "100%", height: "40px" }}
                        placeholder=""
                        maxLength={50}
                        value={ingredient}
                        onChange={handleCity}
                        name="cityName"
                      />

                      <label
                        htmlFor="floating_outlined3"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Email<span className="form-field-mandatory">*</span>
                      </label>
                    </div>
                    {emailErr !== "" ? (
                      <p className="error-msg font-fam-for-all">{emailErr}</p>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>

              <div className="justify-content-center dialog-form-field-space grid mt-3">
                <div className="field col-12 col-sm-12 col-md-12 col-lg-10 p-0">
                  <div
                    className="p-inputgroup flex-1"
                    style={{ height: "40px", width: "100%" }}
                  >
                    <Dropdown
                      value={selectedCountry}
                      options={countries}
                      onChange={onCountryChange}
                      optionLabel="code"
                      resetFilterOnHide
                      valueTemplate={selectedCountryTemplate}
                      itemTemplate={countryOptionTemplate}
                      style={{
                        width: "160px",
                        borderRadius: "6px 0px 0px 6px",
                      }}
                      className="p-dropdown font-fam-for-all"
                    />
                    {/* <span className="p-float-label">
                                    <InputText
                                        style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                        maxLength={50}
                                        
                                        value={phone}
                                        onChange={handlePhone}
                                        name="cityName"
                                    />
                                    <label htmlFor="input" className="form-label font-fam-for-all">
                                        Phone <span className="form-field-mandatory">*</span>
                                    </label>



                                </span> */}
                    <span className="" style={{ width: "100%" }}>
                      <div className="relative custom-input-mask">
                        <input
                          type="text"
                          id="floating_outlined202"
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                            width: "100%",
                          }}
                          maxLength={50}
                          value={phone}
                          onChange={handlePhone}
                          autoClear={false}
                          placeholder=""
                          name="cityName"
                        />
                        <label
                          htmlFor="floating_outlined202"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Phone<span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {phoneErr !== "" ? (
                        <p className="error-msg font-fam-for-all">{phoneErr}</p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  {/* <p className='error-msg font-fam-for-all'>{phoneErr}</p> */}
                </div>
              </div>

              <div className="flex flex-col">
                {/* Password Input */}
                <div className="flex justify-center dialog-form-field-space grid mt-3">
                  <div className="field col-12 col-sm-12 col-md-12 col-lg-10 p-0">
                    <span className=" ">
                      <div className="relative w-full">
                        <input
                          id="floating_outlined6"
                          className={`block w-full px-2.5 pb-2.5 pt-2 pr-10 h-10 text-sm text-gray-900 bg-transparent rounded-lg border appearance-none dark:text-white focus:outline-none focus:ring-0 peer ${passwordErr
                            ? "border-red-500 focus:border-red-500 dark:border-red-500 dark:focus:border-red-500"
                            : "border-gray-300 focus:border-blue-600 dark:border-gray-600 dark:focus:border-blue-500"
                            }`}
                          placeholder=""
                          type={passwordType}
                          value={password}
                          onChange={handlePassword}
                        />

                        <label
                          htmlFor="floating_outlined6"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Password<span className="form-field-mandatory">*</span>
                        </label>

                        {/* Eye Icon */}
                        <i
                          className={`absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer pi ${passwordType === "password" ? "pi-eye" : "pi-eye-slash"
                            }`}
                          style={{ fontSize: "1rem" }}
                          onClick={togglePassword}
                        ></i>
                      </div>
                      {passwordErr && <p className="error-msg font-fam-for-all">{passwordErr}</p>}
                    </span>
                  </div>
                </div>

                {/* Confirm Password Input */}
                <div className="flex justify-center dialog-form-field-space grid mt-3">
                  <div className="field col-12 col-sm-12 col-md-12 col-lg-10 p-0">
                    <div className="relative">
                      <input
                        id="floating_outlined7"
                        className={`block w-full px-2.5 pb-2.5 pt-2 pr-10 h-10 text-sm text-gray-900 bg-transparent rounded-lg border appearance-none dark:text-white focus:outline-none focus:ring-0 peer ${confirmPasswordErr || (password !== confirmedPassword && confirmedPassword && password)
                          ? "border-red-500 focus:border-red-500 dark:border-red-500 dark:focus:border-red-500"
                          : "border-gray-300 focus:border-blue-600 dark:border-gray-600 dark:focus:border-blue-500"
                          }`}
                        placeholder=""
                        type={passwordType1}
                        value={confirmedPassword}
                        style={{ width: "100%", height: "40px" }}
                        onChange={handleConfirmPassword}
                      />
                      <label
                        htmlFor="floating_outlined7"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Confirm Password
                        <span className="form-field-mandatory">*</span>
                      </label>

                      {/* Eye Icon */}
                      <i
                        className={`absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer pi ${passwordType1 === "password" ? "pi-eye" : "pi-eye-slash"
                          }`}
                        style={{ fontSize: "1rem" }}
                        onClick={togglePassword1}
                      ></i>
                    </div>

                    {confirmPasswordErr !== "" ? (
                      <p className="error-msg font-fam-for-all">
                        {confirmPasswordErr}
                      </p>
                    ) : ((password !== confirmedPassword) && password && confirmedPassword) ? (
                      <p className="error-msg font-fam-for-all">
                        Password and confirm password doesn't matched
                      </p>
                    ) : ((password === confirmedPassword) && password && confirmedPassword) ? (<p className="text-green-500 success-msg font-fam-for-all">
                      Passwords matched
                    </p>) : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className='p-4'>
                        <h2 style={{ fontWeight: "500", fontSize: "18px" }}>Billing Access</h2>
                        <div style={{ fontSize: "14px" }}>
                            Manage Billing access here.</div>
                    </div> */}

          {/* <div className='p-4'>
                        <div className='card p-4'>

                            <div className=' pt-2'>
                                <div className="flex align-items-center" style={{ justifyContent: "space-between" }}>
                                    <div>
                                        <Checkbox inputId="ingredient1" name="pizza" value="Cheese" />
                                        <label htmlFor="ingredient1" className="ml-2 mb-0" style={{ fontWeight: "500", fontSize: "14px" }}>Grant access to billing</label>
                                    </div>
                                    <div>
                                        <button onClick={handleToggle2} style={{ color: "#1570EF" }}>Customize by Company <i className={isShowContent2 ? "pi pi-angle-up" : 'pi pi-angle-down'}></i></button>
                                    </div>
                                </div>
                                <p className='ml-4 mt-1' style={{ fontSize: "14px" }}>Users have access to view invoices of companies</p>
                                {
                                    isShowContent2 ? <>
                                        <div className='customize-email-frame mt-3'>
                                            <div className='customize-header-email'>
                                                <div className='customize-table-header-email'>
                                                    <div className='customize-table-header-email-text'>
                                                        Company
                                                    </div>

                                                </div>
                                            </div>

                                            <div className='customize-content-email'>
                                                <div className='customize-table-content-email'>
                                                    <div className='customize-table-content-email-text'>
                                                        ABC Company
                                                    </div>
                                                    <div className='flex align-items-center' style={{ gap: "12px" }}>
                                                        <Checkbox inputId="ingredient1" name="pizza" value="Cheese" />
                                                        <div className='customize-table-content-email-text1'>
                                                            Notify
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> : ""
                                }
                            </div>
                            <div className="divider-x my-2"></div>
                            <div className=' py-2'>
                                <div className="flex align-items-center" style={{ justifyContent: "space-between" }}>
                                    <div>
                                        <Checkbox inputId="ingredient1" name="pizza" value="Cheese" />
                                        <label htmlFor="ingredient1" className="ml-2 mb-0" style={{ fontWeight: "500", fontSize: "14px" }}>Notify Billing</label>
                                    </div>
                                    <div>
                                        <button onClick={handleToggle3} style={{ color: "#1570EF" }}>Customize by Company <i className={isShowContent3 ? "pi pi-angle-up" : 'pi pi-angle-down'}></i></button>
                                    </div>
                                </div>
                                <p className='ml-4 mt-1' style={{ fontSize: "14px" }}>Receive invoices and billing notifications.</p>
                                {
                                    isShowContent3 ? <>
                                        <div className='customize-email-frame mt-3'>
                                            <div className='customize-header-email'>
                                                <div className='customize-table-header-email'>
                                                    <div className='customize-table-header-email-text'>
                                                        Company
                                                    </div>

                                                </div>
                                            </div>

                                            <div className='customize-content-email'>
                                                <div className='customize-table-content-email'>
                                                    <div className='customize-table-content-email-text'>
                                                        ABC Company
                                                    </div>
                                                    <div className='flex align-items-center' style={{ gap: "12px" }}>
                                                        <Checkbox inputId="ingredient1" name="pizza" value="Cheese" />
                                                        <div className='customize-table-content-email-text1'>
                                                            Notify
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </> : ""
                                }
                            </div>



                        </div>



                    </div> */}

          <div className="flex justify-content-end dialog-form-md-group-Btn my-5">
            <Button
              type="button"
              className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
              label="Cancel"
              style={{ height: "40px" }}
              onClick={cancelView}
            ></Button>
            <Button
              type="submit"
              className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
              label="Submit"
              style={{ height: "40px" }}
            ></Button>
          </div>
        </form>
      </Sidebar>
    </div>
  );
};

export default AddVstateEmployee;
