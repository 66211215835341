import React, { useState } from 'react'
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';

const ProtectedByAdminAndUser = ({ Componet }) => {
    const navigate = useNavigate()
    let newObject = window.localStorage.getItem("user");
    let user = Object.assign({}, JSON.parse(newObject));
    const [isLogedIn, setIsLoggedIn] = useState(false)

  const [superAdminId, setSuperAdminId] = useState(null)


    function checkIdsExist(arr, idsToCheck) {
        const foundIds = idsToCheck.filter(idToCheck => arr.some(item => item.id === idToCheck));
        return foundIds.length > 0 ? foundIds : null;
    }

    useEffect(() => {

        if (user) {
            if (user.companyRoles !== undefined) {
                if (user.companyRoles.length > 0) {
                    if (user.companyRoles[0].roles.length > 0) {
                        const id = checkIdsExist(user.companyRoles[0].roles, [2,3]);
                        console.log(id)
                        setSuperAdminId(id)
                    }
                }
            }
        }
    }, [])


    useEffect(() => {
        if (newObject) {
            setIsLoggedIn(true)
        }
        else {
            navigate('/signin')
        }
    }, [newObject])
    if (isLogedIn) {
        if (superAdminId) {

            return <Componet />
        }
        else {
            navigate('/signin')
        }
    } else {
        navigate('/signin')
    }




}

export default ProtectedByAdminAndUser