import React from "react";
import { Link } from "react-router-dom";
import twitter from "../../Assets/Images/twitter.png";
import { Col, Container, Row } from "react-bootstrap";

const Footer = () => {
  return (
    <footer
      className="flex flex-col justify-center items-center px-16 py-16 w-full max-md:px-5 max-md:py-10"
      style={{ background: "#1B2032" }}
    >
      <div>
        <Container>
          <Row>
            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
              <div className="flex flex-col justify-center px-2.5 py-2 text-sm font-light leading-7 text-white text-opacity-70">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c21363d3d545cfc471d5f48394b421bd6b0aee2e219e17e9417a89bf538c3079?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
                  className="max-w-full w-[200px] max-md:w-full"
                  alt="vState Filings logo"
                />
                <p className="footer-common-text-class mt-3">
                  At vState Filings, we simplify your business journey with
                  expert management of formation, registered agent services and
                  legal support. Our efficient process lets you to focus on what
                  matters the most — growing your business.
                </p>
                <ContactInfo />
              </div>
            </Col>
            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
              <Row className="justify-content-center">
                <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                  <div>
                    <h3 className="text-lg font-medium text-white">Services</h3>
                    <ul className="footer-link mt-4 space-y-2">
                      <Link to="/webcompany">Entity Formation</Link> <br />
                      <Link to="/corporatefilings">Corporate Filings</Link>{" "}
                      <br />
                      <Link to="/compliance">Compliance</Link>
                      <br />
                      <Link to="/subscription">Subscriptions</Link> <br />
                      <Link to="/uccfilings">UCC Filings</Link> <br />
                      <Link to="/documentretrieval">
                        Document Retrieval
                      </Link>{" "}
                      <br />
                      <Link to="/corporatesuplies">Corporate Supplies</Link>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xl={4} lg={4} md={6} sm={12} xs={12}>
              <h3 className="text-lg font-medium text-white">Disclaimer</h3>
              <p className="footer-common-text-class mt-4 text-sm font-light leading-7 text-white text-opacity-70">
                vState Filings is a document filing service and can not provide
                you with legal or financial advice. The information on the
                website is designed to provide accurate and authoritative
                information in regard to the subject matter covered. vState
                Filings is not engaged in rendering legal, accounting or other
                professional services. If legal advice or other professional
                assistance is required, the services of a competent professional
                person should be sought. Use of our products and services is
                governed by our 
                <Link to="/terms">Terms</Link> of Use and 
                <Link to="/privacy">Privacy Policy </Link>.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Footer Bottom Section */}
      <div className="flex justify-center items-center self-stretch px-16 py-3 w-full border-t border-white border-opacity-20 max-md:px-5">
        <div className="flex flex-wrap justify-center items-center text-xs font-light leading-4 text-white text-opacity-70 space-x-3 mt-2">
          <div>© Copyright 2025 |</div>
          <div>vState Filings |</div>
          {/* <Link to="/privacy">Privacy </Link> */}
          <div>All Rights Reserved</div>
        </div>
        <div className="flex space-x-2.5 mt-2 max-md:mt-4">
          <SocialIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8ec4acb0c40f7048c6b99fa73e442b121c9cbdb1133c01d6f3f8928eec47977?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
            alt="Facebook"
            link="https://www.facebook.com"
          />
          <SocialIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/80071593701117e8d2fe7dab403696d8eddce7d2bc445189ac644df7c73af3fd?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
            alt="Twitter"
            link="https://www.instagram.com/"
          />
          <SocialIcon src={twitter} alt="twitter" link="https://x.com/" />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <span className="footer-last-txtt">
          <Link to="https://www.redberyltech.com/">
            Powered by RedBeryl Tech Solutions Pvt Ltd
          </Link>
        </span>
      </div>
    </footer>
  );
};

const ContactInfo = () => (
  <div className="contact-mainnn-class">
    <div className="flex gap-3 mt-2">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/36a91ec308c14bd2576e0acf5e5463fc5c137a2182ee41481767782f04fe8f0b?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
        className="aspect-square"
        alt="Email"
      />
      <a href="mailto:support@vstatefilings.com" className="underline">
      orders@vstatefilings.com
      </a>
    </div>
    <div className="flex gap-3 mt-1.5">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/e5fa235e3e1b808ce197b222fc9164fef9dae4e4eb1c5499b0960273aaeb173b?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
        className="aspect-square"
        alt="Phone"
      />
      <div>
        <a href="tel:866-638-3309" className="underline">
          (866) 638-3309
        </a>{" "}
        |{" "}
        <a href="tel:718-569-2703" className="underline">
          (718) 569-2703
        </a>
      </div>
    </div>
    <div className="flex gap-3 mt-2">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/61b22b1897a242a6b315abfaf8701f9dd6ba3c556463a94af03cf7630f6c138c?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
        className="aspect-square"
        alt="Address"
      />
      <address className="not-italic">
        <a
          href="https://www.google.com/maps?q=301+Mill+Road,+Suite+U5,+Hewlett,+NY+11557"
          target="_blank"
          rel="noopener noreferrer"
          className="underline mt-3"
        >
          301 Mill Road, Suite U5, Hewlett, NY 11557
        </a>
      </address>
    </div>
  </div>
);

const SocialIcon = ({ src, alt, link }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className="flex justify-center items-center p-2.5 bg-[#0047ff] h-[33px] rounded-[50px] w-[33px] ms-2"
  >
    <img
      loading="lazy"
      src={src}
      className="aspect-square w-[15px]"
      alt={alt}
    />
  </a>
);

export default Footer;
