import React from 'react';

const ContactInfo = () => {
  const contactItems = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c002628b9a8e1a345a9a68846ab6fe1485b605b52da7d4ef6cddcaf5e7bc92ca?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0",
      text: "orders@vstatefilings.com",
      alt: "Email icon"
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/7384022449082cb49e49e11cc333092f47d7060a46e9d649120a28da2eeb6c58?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0",
      text: "301 Mill Road, Suite U5, Hewlett, NY 11557",
      alt: "Location icon"
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/84f47c86364888c4dabe8a6bdde1c72ad38545de2232c5e7a324014587609c0a?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0",
      text: "(866) 638-3309 | (718) 569-2703",
      alt: "Phone icon"
    }
  ];

  return (
      <div className="flex flex-1 pt-5 shrink justify-center h-full basis-0 min-h-[1px] min-w-[240px] max-md:max-w-full">
        <div className="flex flex-col flex-1 shrink justify-center px-12  w-full basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full">
          {contactItems.map((item, index) => (
            <React.Fragment key={index}>
              <div className="flex flex-col justify-center pb-3 w-full text-xl font-semibold leading-tight text-center text-gray-800 whitespace-nowrap min-h-[174px] max-md:max-w-full">
                <div className="flex flex-col w-full min-h-[155px] max-md:max-w-full">
                  <div className="flex flex-col w-full max-md:max-w-full">
                    <img loading="lazy" src={item.icon} alt={item.alt} className="object-contain self-center aspect-square w-[60px]" />
                    <div className="flex flex-col items-center px-20 mt-5 w-full max-md:px-5 max-md:max-w-full">
                      <div className="py-px">{item.text}</div>
                    </div>
                  </div>
                </div>
              </div>
              {index < contactItems.length - 1 && (
                <div className="flex flex-col justify-center pb-5 w-full min-h-[51px] max-md:max-w-full">
                  <div className="flex flex-col w-full min-h-[31px] max-md:max-w-full">
                    <div className="flex justify-center py-4 w-full max-md:max-w-full">
                      <div className="flex flex-1 shrink w-full border-t border-gray-800 basis-0 min-h-[1px] min-w-[240px] max-md:max-w-full" />
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
   
  );
};

export default ContactInfo;