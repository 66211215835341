import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";
import ServiceCommonCard from "../../CommonSection/ServiceCommonCard";
import BlueButton from "../../CommonSection/BlueButton";
import AOS from "aos";
import "aos/dist/aos.css";

const UcFillingThird = () => {
  const [expanded, setExpanded] = useState(false);

  const services = [
    {
      title: "UCC Filings",
      description:
        "Filing Uniform Commercial Code (UCC) documents to secure interests in personal property. This is essential for businesses seeking financing or securing loans.",
    },
    {
      title: "UCC Amendment",
      description:
        "A filing to change or update existing UCC filings, such as changes in secured parties or collateral descriptions. This keeps the UCC record current and accurate.",
    },
    {
      title: "UCC Search",
      description:
        "A service that provides a search of UCC filings to determine existing security interests against a business’s assets. This is critical for lenders and buyers assessing risk.",
    },
    {
      title: "Litigation Search",
      description:
        "A search to uncover any pending or past litigation involving a business. This is essential for due diligence, especially when considering investments or partnerships.",
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section data-aos="fade-up" data-aos-duration="3000">
      <Container>
        <CommonHeadingTitle blueheading={"UCC filings services"} />
        <div className="text-center mt-5 mb-5">
          <h4 className="subheading">
            UCC filings and lien searches protect the rights of creditors by
            publicly recording a claim on a debtor's assets. These filings are
            essential for businesses engaged in lending, leasing, or selling on
            credit.
          </h4>
        </div>
        <Row className="justify-content-center">
          {services.slice(0, 3).map((service, index) => (
            <Col key={index} lg={4} md={4} sm={6} xs={12}>
              <ServiceCommonCard
                title={service.title}
                description={service.description}
              />
            </Col>
          ))}
          {expanded &&
            services.slice(3).map((service, index) => (
              <Col key={index + 3} lg={4} md={4} sm={6} xs={12}>
                <ServiceCommonCard
                  title={service.title}
                  description={service.description}
                />
              </Col>
            ))}
        </Row>
        <div className="text-center mt-4 mb-5">
          <button
            className="btn btn-primary"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "Read Less" : "Read More"}
          </button>
        </div>
      </Container>
    </section>
  );
};

export default UcFillingThird;
