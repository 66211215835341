import React from 'react'
import Header from '../RegistredAgentService/Header'
import Partners from '../Partners'
import ComplianceServices from './Complianc Service/ComplianceServices'
import ComplianceSection from './Compliance/ComplianceSection.js'
import FAQ from '../RegistredAgentService/Fact.js'
import ContactForm from './ContactForm.js'
import CallToAction from '../WebsiteComapny/CallToAction.js'

const complianceData = {
  "sectionTitle": "Why Compliance Matters?",
  "sectionSubtitle": "Compliance Services are essential for your business.",
  "items": [
    {
      "title": "Avoid Penalties",
      "description": "By staying compliant, your business avoids fines, penalties, or suspension due to missed deadlines."
    },
    {
      "title": "Legal Protection",
      "description": "Compliance ensures your business continues to operate within legal requirements, safeguarding it from potential legal action."
    },
    {
      "title": "Increased Credibility",
      "description": "Maintaining compliance boosts your business's credibility with regulators, clients, and partners to ensure smooth operations."
    },
    {
      "title": "Streamlined Processes",
      "description": "Professional compliance services simplify required filings, reducing the burden on your team and saving you time."
    }
  ],
  "images": {
    "backgroundImage": "https://cdn.builder.io/api/v1/image/assets/TEMP/534bb250cb442ca035f407b04dc18bd8538e5df48f97ecda01286a617bf90fd4?apiKey=136b58d08a644862944c0e5f22adbbe0&",
    "iconImage": "https://cdn.builder.io/api/v1/image/assets/TEMP/2517a6129f7ab66094c10670002e3c726e87b73dba9f0efd07fceaf970443933?apiKey=136b58d08a644862944c0e5f22adbbe0&"
  }
}


const callToActionData = {
  title: " Ready to kick off your new business endeavors?",
  description: " Get started with your business registration process today.",
  buttonText: "Register now",
  path: ""
}

const ServiceContent = () => {
  return (
    <div className="mt-24">
      <main className="flex flex-col items-center px-80 pt-16 pb-20 max-md:px-5">
        <div className="flex flex-col px-5 w-full max-w-[1240px] max-md:max-w-full">
          <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <Header />
          </div>
          <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <Partners />
          </div>
          <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <ComplianceSection complianceData={complianceData} />
          </div>
          <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <ComplianceServices />
          </div>
          {/* <div className="w-screen flex justify-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <CallToAction callToActionData={callToActionData} />
          </div> */}
          <div className="flex flex-col px-8 website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <p className="contact-form-haeding-main-text1">
              Need help to <span className="contact-form-haeding-main-text2">stay compliant?</span>
            </p>
            <ContactForm />
          </div>
          <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <FAQ />
          </div>
        </div>
      </main>
    </div>
  );
};


export default ServiceContent