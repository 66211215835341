import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button } from 'primereact/button';
import { showToast } from '../utils/toastUtils';

const RolesAndAccess = () => {
    const [data, setData] = useState([
        {
            id: 1,
            name: "payment",
            action: [
                { id: 1, action: 'Create', super_admin: false, admin: false, user: false,user_admin:false },
                { id: 2, action: 'Read', super_admin: false, admin: false, user: false,user_admin:false },
                { id: 3, action: 'Update', super_admin: false, admin: false, user: false,user_admin:false },
                { id: 4, action: 'Delete', super_admin: false, admin: false, user: false,user_admin:false },
            ]
        },
        {
            id: 2,
            name: "User management",
            action: [
                { id: 1, action: 'Create', super_admin: false, admin: false, user: false,user_admin:false },
                { id: 2, action: 'Read', super_admin: false, admin: false, user: false,user_admin:false },
                { id: 3, action: 'Update', super_admin: false, admin: false, user: false,user_admin:false },
                { id: 4, action: 'Delete', super_admin: false, admin: false, user: false,user_admin:false },
            ]
        }

    ]);

    const [expandedRows, setExpandedRows] = useState(null);
    const toast = useRef(null);


    const onCheckboxChange = (e, rowData, field) => {
        console.log(rowData)
        const updatedData = data.map((item) => {
            if (item.id === rowData.parentId) {
                const updatedAction = item.action.map((action) =>
                    action.id === rowData.id ? { ...action, [field]: e.checked } : action
                );
                return { ...item, action: updatedAction };
            }
            return item;
        });
        setData(updatedData);
    };

    const actionBodyTemplate = (rowData) => {
        return <span>{rowData.action}</span>;
    };

    const checkboxBodyTemplate = (rowData, field) => {
        return (
            <Checkbox
                checked={rowData[field]}
                onChange={(e) => onCheckboxChange(e, rowData, field)}
            />
        );
    };

    useEffect(() => {
        let _expandedRows = {};

        data.forEach((p) => (_expandedRows[`${p.id}`] = true));

        console.log(_expandedRows)

        setExpandedRows(_expandedRows);
    }, [data])

    const handleButtonClick = () => {
        
    };

    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3">
                <DataTable value={data.action.map(action => ({ ...action, parentId: data.id }))} rows={10} responsiveLayout="scroll">
                    <Column field="action" header="Action" className='data-table-row-font datatable-sm-col' body={actionBodyTemplate} />
                    <Column
                        field="super_admin"
                        header="Super_Admin"
                        className='data-table-row-font datatable-sm-col'
                        body={(rowData) => checkboxBodyTemplate(rowData, 'super_admin')}
                    />
                    <Column
                        field="admin"
                        header="Admin"
                        className='data-table-row-font datatable-sm-col'
                        body={(rowData) => checkboxBodyTemplate(rowData, 'admin')}
                    />
                    <Column
                        field="user"
                        header="User"
                        className='data-table-row-font datatable-sm-col'
                        body={(rowData) => checkboxBodyTemplate(rowData, 'user')}
                    />
                    <Column
                        field="user_admin"
                        header="User_Admin"
                        className='data-table-row-font datatable-sm-col'
                        body={(rowData) => checkboxBodyTemplate(rowData, 'user_admin')}
                    />
                    <Column
                     
                        className='data-table-row-font datatable-sm-col'
                        body={(rowData) => (<p></p>)}
                    />
                </DataTable>
            </div>
        );
    };

    const onRowExpand = (event) => {
        // toast.current.show({ severity: 'info', summary: 'Product expanded', detail: event.data.name, life: 3000 });

        showToast("success","Product expanded")

    };

    const onRowCollapse = (event) => {
        // toast.current.show({ severity: 'success', summary: 'Product collapsed', detail: event.data.name, life: 3000 });
        showToast("success","Product collapsed")

    };

    const expandAll = () => {
        let _expandedRows = {};

        data.forEach((p) => (_expandedRows[`${p.id}`] = true));

        console.log(_expandedRows)

        setExpandedRows(_expandedRows);
    };

    const collapseAll = () => {
        setExpandedRows(null);
    };

    const header = (

        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
            {/* <div className='flex justify-content-end dialog-form-md-group-Btn' >
                <Button icon="pi pi-plus" label="Expand" onClick={expandAll} text />
                <Button icon="pi pi-minus" label="Collapse" onClick={collapseAll} text />
            </div> */}
        </div>
    );

    const allowExpansion = (rowData) => {
        return rowData.action > 0;
    };

    return (
        <div>
            <div className='dashboard-data-header font-fam-for-all' style={{marginBottom:"10px",marginTop:"10px"}}>
                <div className='dashboard-data-sub-header'  style={{ paddingLeft: "0px", paddingRight: "15px" }}>
                    <div className="card p-fluid" >

                        {/* <button onClick={handleButtonClick}>Capture Data</button> */}

                        <DataTable value={data} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                            onRowExpand={onRowExpand} onRowCollapse={onRowCollapse} rowExpansionTemplate={rowExpansionTemplate}
                            dataKey="id"  tableStyle={{ minWidth: '60rem' }} className='datatable-sm font-fam-for-all'  >

                            {/* <Column expander={allowExpansion} style={{ width: '5rem' }} /> */}
                            <Column field="name" className='font-fam-for-all user-service-col'   style={{ fontWeight: "bold" }} />

                        </DataTable>


                    </div>
                    <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                        <div className='flex justify-content-end dialog-form-md-group-Btn' >
                            <Button className="mt-2  font-fam-for-all text-center text-lg bg-primary-reverse mr-2" style={{ height: "40px" }}   >Cancel</Button>
                            <Button className="mt-2  font-fam-for-all text-center text-lg mr-3" style={{ height: "40px" }} onClick={handleButtonClick} >Submit</Button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    );
};

export default RolesAndAccess;
