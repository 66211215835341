import axios from "axios";
import authHeader, { initAuth } from "./authHeader";
import encryption from "./CryptoClass";
import propertiesApp from "../utils/propertiesApp";

const getAllCompany=() => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyDetails/getAll`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const getAllVendor=() => {
    const dataToEncrypt = {
        endpoint: `/contact/api/vendorDetails/getAll`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const getAllKeyPersonnel=() => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyKeyPersonnel/getAll`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const getAllKeyPersonnelType=() => {
    const dataToEncrypt = {
        endpoint: `/core/api/keyPersonnelTypeMaster/getAll`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllKeyPersonnelByCompanyId=(companyId) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyKeyPersonnel/getByCompanyId/${companyId}`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllCompanyById=(id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyDetails/getById/${id}`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllCompanyByAccountManagerId=(id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyDetails/getByAccountManagerId/${id}`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveCompany=(data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyDetails/save`,
        payload: data,
        type:"post",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveKeyPersonnel=(data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyKeyPersonnel/save`,
        payload: data,
        type:"post",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const updateKeyPersonnel=(data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyKeyPersonnel/update`,
        payload: data,
        type:"put",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveVendor=(data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/vendorDetails/save`,
        payload: data,
        type:"post",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveCompanyContact=(data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyContactDetails/save`,
        payload: data,
        type:"post",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const updateCompany=(data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyDetails/update`,
        payload: data,
        type:"put",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllCompanyWithContact=() => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyContactDetails/getAll`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const companyMasterService={
    getAllCompany,
    getAllCompanyWithContact,
    saveCompany,
    getAllCompanyById,
    updateCompany,
    getAllVendor,
    saveVendor,
    getAllKeyPersonnel,
    getAllKeyPersonnelType,
    saveKeyPersonnel,
    getAllKeyPersonnelByCompanyId,
    updateKeyPersonnel,
    getAllCompanyByAccountManagerId,
    saveCompanyContact
}

export default companyMasterService;