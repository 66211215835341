

const extractPermissions = (data) => {
    const permissions = new Set();
    if (data.roles !== undefined && data.roles.length > 0) {
        data.roles.forEach(companyRole => {
            data.roles.forEach(role => {
                role.permissionMasters.forEach(permission => {
                    permissions.add(permission.permissionName);
                });
            });
        });
        return Array.from(permissions);
    }
    else {
        return [];
    }

};

export default extractPermissions;