import React, { useContext, useEffect, useRef, useState } from "react";
import PaymentDetails from "./PaymentDetails";
import ActionButtons from "./ActionButtons";
import ReactToPrint from "react-to-print";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import authContext from "../../common/authContext";
import OrderServices from "../../services/orderServices";
import { Toast } from "primereact/toast";

function PaymentSuccess() {

    const { paySessionId, permissions, loginUserName, globalCompany, setGlobalCompany, isLoading, setRedirectedData, isTabRefresh, setIsTabRefresh, isReviewDone, setIsReviewDone } = useContext(authContext);

    const printRef = useRef();
    const toast = useRef(null)
    const history = useNavigate()
    const paymentData = localStorage.getItem("payment")
    const sessionId = localStorage.getItem("sessionId")
    const jsonPayment = JSON.parse(paymentData)
    const [orderPayData,setOrderPayData]=useState(null)
    const [paytData, setPayData] = useState(null)
    const [isDataExist,setIsDataExist]=useState(null)
    const convertCentsToDollars = (cents) => {
        return (cents / 100).toFixed(2); // Dividing by 100 to convert to dollars and fixing to 2 decimal places
    };
    const convertTimestampToDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        console.log(date)
        return date.toISOString();
    };
    console.log(jsonPayment)
    console.log(paymentData)
    console.log(paytData)
    useEffect(() => {
        if (jsonPayment  && sessionId && !paytData && !orderPayData && !isDataExist) {
            setIsDataExist(jsonPayment)
            try {
                OrderServices.paymentSession(sessionId)
                    .then((response) => {
                        console.log(response)
                        // setPayData(response.data)
                        if (jsonPayment.data !== undefined && jsonPayment.data && !paytData && !orderPayData) {
                            const tempdata = jsonPayment.data.orderDetails
                            if (((jsonPayment.data.statusId.id) !== 14)&&((jsonPayment.data.statusId.id) !== 1003)) {

                                tempdata["transactionId"] = response.data.payment_intent
                                tempdata["generateReceipt"] = true
                                tempdata["orderLineItems"] = jsonPayment.orderLineItem
                                tempdata["orderUpdatedBy"] = jsonPayment.updatedBy
                                tempdata["orderUpdateNotes"] = "Payment done successfully"
                                tempdata["stateId"] = jsonPayment.data.state?jsonPayment.data.state.id:null
                                tempdata["countyId"] = jsonPayment.data.orderDetails?jsonPayment.data.orderDetails.countyId:null
                                tempdata["transactionDate"]=convertTimestampToDate(response.data.created)
                                console.log(tempdata)
                                setIsTabRefresh(true)
                                OrderServices.saveAllOrder(tempdata)
                                    .then((res) => {
                                        console.log(res)
                                        setRedirectedData(res.data.orderDetails)
                                        setIsTabRefresh(false)
                                        setIsReviewDone(false)
                                        setOrderPayData(res.data)
                                        setPayData(response.data)
                                        localStorage.removeItem("payment")
                                        if (toast.current !== undefined && toast.current !== null) {
                                            toast.current.show({ severity: 'success', summary: 'Success', detail: "Order updated successfully", life: 3000 });

                                        }
                                        const orderRecieptData = {
                                            id: res.data.orderDetails.id,
                                            generateReceiptValue: true
                                        }
                                        OrderServices.saveAllSalesReciept(orderRecieptData)
                                            .then((res) => {
                                                console.log(res)
                                            })
                                            .catch((err) => {
                                                console.log(err)
                                            })

                                    })
                                    .catch((err) => {
                                        console.log(err)
                                        setIsTabRefresh(false)
                                        setIsReviewDone(false)
                                        if (toast.current !== undefined && toast.current !== null) {
                                            toast.current.show({ severity: 'warn', summary: 'Warning', detail: "Order updated failed", life: 3000 });

                                        }
                                    })
                            }
                            else if (((jsonPayment.data.statusId.id) === 14)||((jsonPayment.data.statusId.id) === 1003)) {
                                tempdata["statusId"] = 9
                                tempdata["orderUpdateNotes"] = "Order is under review."
                                tempdata["transactionId"] = response.data.payment_intent
                                tempdata["generateReceipt"] = true
                                tempdata["orderLineItems"] = jsonPayment.orderLineItem
                                tempdata["orderUpdatedBy"] = jsonPayment.updatedBy
                                tempdata["stateId"] = jsonPayment.data.state?jsonPayment.data.state.id:null
                                tempdata["countyId"] = jsonPayment.data.orderDetails?jsonPayment.data.orderDetails.countyId:null
                                tempdata["transactionDate"]=convertTimestampToDate(response.data.created)

                                console.log(tempdata)
                                setIsTabRefresh(true)
                                OrderServices.saveAllOrder(tempdata)
                                    .then((res) => {
                                        console.log(res)
                                        setRedirectedData(res.data.orderDetails)
                                        setIsTabRefresh(false)
                                        setIsReviewDone(false)
                                        setOrderPayData(res.data)
                                        setPayData(response.data)
                                        localStorage.removeItem("payment")
                                        if (toast.current !== undefined && toast.current !== null) {
                                            toast.current.show({ severity: 'success', summary: 'Success', detail: "Order updated successfully", life: 3000 });

                                        }
                                        const orderRecieptData = {
                                            id: res.data.orderDetails.id,
                                            generateReceiptValue: true
                                        }
                                        OrderServices.saveAllSalesReciept(orderRecieptData)
                                            .then((res) => {
                                                console.log(res)
                                            })
                                            .catch((err) => {
                                                console.log(err)
                                            })
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                        setIsTabRefresh(false)
                                        setIsReviewDone(false)
                                        if (toast.current !== undefined && toast.current !== null) {
                                            toast.current.show({ severity: 'warn', summary: 'Warning', detail: "Order updated failed", life: 3000 });

                                        }
                                    })
                            }

                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })

            } catch (error) {
                console.log(error)
            }

        }
    }, [])
    return (
        <div  className="flex justify-content-center w-full" style={{ backdropFilter: "blur(10px)" }}>
            <Toast ref={toast} />

            {
                paytData && orderPayData ? <main className="flex flex-col justify-content-center items-center px-4 pt-4 pb-12 rounded-lg shadow-lg font-fam-for-all max-w-[550px] max-md:px-5" style={{ backgroundColor: "#F6F8FE" }}>
                    <div ref={printRef} className="flex flex-col w-full max-w-[604px] max-md:max-w-full">
                        <header className="flex flex-col w-full text-3xl font-semibold leading-none text-center text-gray-900 max-md:max-w-full">
                            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4037cf5e45d4070eeb20b065c07dc779584b236aed93f5e32daa680d965b5c3?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0" alt="" className="object-contain self-center w-14 aspect-square" />
                            <h3 className="mt-4 w-full max-md:max-w-full">Payment Successful</h3>
                        </header>
                        <section className="flex flex-col items-start mt-4 w-full text-base text-center text-gray-500 max-md:max-w-full">
                            <div className="flex flex-wrap justify-center items-start self-stretch mt-1 w-full max-md:max-w-full">
                                <div className="w-full">
                                    <p className="leading-6">
                                        Transaction ID:
                                    </p>
                                    <p className="font-bold break-words whitespace-normal">
                                        {paytData.payment_intent}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-center items-start self-stretch mt-1 w-full max-md:max-w-full">
                                <div>
                                    <p className="max-md:max-w-full mb-2">Amount</p>
                                    <p className="mt-1 mb-2 text-lg font-semibold leading-none text-gray-900 max-md:max-w-full">
                                        ${convertCentsToDollars(paytData.amount_total)}
                                    </p>
                                </div>

                            </div>
                            <div className="flex flex-wrap justify-center items-start self-stretch gap-4  w-full max-md:max-w-full">
                                <p>{convertTimestampToDate(paytData.created)}</p>
                                <p className="leading-6">
                                    Payment Method <span className="ml-1 font-semibold">Card</span>
                                </p>
                            </div>
                        </section>
                        <PaymentDetails data={orderPayData.orderLineItem} order={orderPayData.orderDetails} />
                       

                    </div>
                    <div className="flex gap-4 items-start mt-8 w-full text-sm font-medium leading-none max-md:max-w-full">
                            <div className="flex flex-wrap flex-1 shrink gap-3 items-start w-full basis-0 min-w-[240px] max-md:max-w-full" >
                                <button className="flex flex-1 shrink items-start whitespace-nowrap rounded-lg basis-0 min-w-[240px] text-slate-700" onClick={() => history("/order")}>
                                    <span className="flex overflow-hidden flex-1 shrink gap-2 justify-center items-center px-4 py-2.5 w-full bg-white-600 rounded-lg border border-blue-600 border-solid shadow-sm basis-0 min-w-[240px]">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 1 20" fill="none">
                           
                                        </svg>
                                        <span className="self-stretch my-auto">Close</span>

                                    </span>
                                </button>
                                <ReactToPrint
                                    trigger={() => <button className="flex flex-1 shrink items-start text-center text-white rounded-lg basis-0 min-w-[240px]" >
                                        <span className="flex overflow-hidden flex-1 shrink gap-2 justify-center items-center px-4 py-2.5 w-full bg-blue-600 rounded-lg border border-blue-600 border-solid shadow-sm basis-0 min-w-[240px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <g clip-path="url(#clip0_2635_44422)">
                                                    <path d="M4.99984 7.49984V1.6665H14.9998V7.49984M4.99984 14.9998H3.33317C2.89114 14.9998 2.46722 14.8242 2.15466 14.5117C1.8421 14.1991 1.6665 13.7752 1.6665 13.3332V9.1665C1.6665 8.72448 1.8421 8.30055 2.15466 7.98799C2.46722 7.67543 2.89114 7.49984 3.33317 7.49984H16.6665C17.1085 7.49984 17.5325 7.67543 17.845 7.98799C18.1576 8.30055 18.3332 8.72448 18.3332 9.1665V13.3332C18.3332 13.7752 18.1576 14.1991 17.845 14.5117C17.5325 14.8242 17.1085 14.9998 16.6665 14.9998H14.9998M4.99984 11.6665H14.9998V18.3332H4.99984V11.6665Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_2635_44422">
                                                        <rect width="20" height="20" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <span className="self-stretch my-auto">Print receipt</span>
                                        </span>
                                    </button>
                                    }
                                    content={() => printRef.current}
                                />

                            </div>
                        </div>
                </main> : ""
            }

        </div>
    );
}

export default PaymentSuccess;