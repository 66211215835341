import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import errorMessages, { globalMessages } from '../../utils/errorMessages';
import ForgotPasswordService from '../../services/forgotPasswordService';
import { Toast } from 'primereact/toast';
import authContext from '../../common/authContext';
import { showToast } from '../../utils/toastUtils';

const AddChangePassword = () => {

    const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh } = useContext(authContext);


    const [isToastShown, setIsToastShown] = useState(false);

  useEffect(() => {
    if (!isToastShown) {
      showToast("success", "Success! Your OTP is verified");
      setIsToastShown(true);
    }
  }, [isToastShown]);

    const navigate = useNavigate()

    const [userparam] = useSearchParams()

    const [password, setPassword] = useState("")
    const [newpassword, setNewPassword] = useState('');

    const [confirmpassword, setConformPassword] = useState('');

    const [newpwdErr, setNewpwdErr] = useState("")

    const [confirmPwdErr, setConfirmPwdErr] = useState("")
    const [emailErr, setemailErr] = useState('')

    const [showTimer, setShowTimer] = useState(false)

    const [showPasswordForm, setShowPasswordForm] = useState(true)

    const [showPassword, setShowPassword] = useState(true)


    const [passwordType, setPasswordType] = useState("password");

    const [passwordType1, setPasswordType1] = useState("password");

    const toastBR = useRef(null)

    const handlePassword = (e) => {
        setPassword(e.target.value)

        setNewPassword(e.target.value)
        setNewpwdErr("")
        const temp = e.target.value
        if (temp === "") {
            setNewpwdErr(errorMessages.passwordRequired)

        }
        if (temp && temp.trim() !== '') {
            let errors = [];

            if (!/[A-Z]/.test(temp)) {
                errors.push("at least one uppercase letter");
            }
            if (!/[a-z]/.test(temp)) {
                errors.push("at least one lowercase letter");
            }
            if (!/\d/.test(temp)) {
                errors.push("at least one digit");
            }
            if (!/[@$!%*#?&_]/.test(temp)) {
                errors.push("at least one special character (@$!%*#?&_)");
            }
            if (temp.length < 8) {
                errors.push("a minimum length of 8 characters");
            }

            if (errors.length > 0) {
                setNewpwdErr(`Password must contain ${errors.join(", ")}.`);
            }

        }

    }



    const handlePassword1 = (e) => {
        setConfirmPwdErr("")
        const temp = e.target.value
        if (temp === "") {
            setConfirmPwdErr(errorMessages.confirmPasswordRequired)

        }
        if (temp && temp.trim() !== '') {
            let errors = [];

            if (!/[A-Z]/.test(temp)) {
                errors.push("at least one uppercase letter");
            }
            if (!/[a-z]/.test(temp)) {
                errors.push("at least one lowercase letter");
            }
            if (!/\d/.test(temp)) {
                errors.push("at least one digit");
            }
            if (!/[@$!%*#?&_]/.test(temp)) {
                errors.push("at least one special character (@$!%*#?&_)");
            }
            if (temp.length < 8) {
                errors.push("a minimum length of 8 characters");
            }

            if (errors.length > 0) {
                setConfirmPwdErr(`Password must contain ${errors.join(", ")}.`);
            }

        }



        setConformPassword(e.target.value)

    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const togglePassword1 = () => {
        if (passwordType1 === "password") {
            setPasswordType1("text")
            return;
        }
        setPasswordType1("password")
    }


    const backbutton = () => {
        navigate("/login");
    }


    let newObject = window.localStorage.getItem("usermail");
    let updatedUser = Object.assign({}, JSON.parse(newObject));
    const handleSubmit1 = (e) => {
        e.preventDefault()

        if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(newpassword)) {
            setNewpwdErr("Please enter valid password.");
        }
        if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(confirmpassword)) {
            setConfirmPwdErr("Please enter valid confirm password.");
        }
        if (newpassword !== "" && confirmpassword !== "" && newpwdErr === "" && confirmPwdErr === "") {

            if (newpassword === confirmpassword) {
                setIsTabRefresh(true)
                const email = userparam.get("email")
                ForgotPasswordService.resetPassword(confirmpassword, email)
                    .then((res) => {
                        console.log(res)
                        setIsTabRefresh(false)
                        if (res.status === 201) {
                            setShowPasswordForm(false)

                        }
                        if (toastBR.current !== null) {
                            if (res.data === "Password changed successfully!") {
                                // toastBR.current.show({ severity: 'success', summary: 'Success', detail: 'Password updated successfully', life: 2800 });
            showToast("success", "Password changed successfully!");

                            }

                        }

                    })
                    .catch((err) => {
                        console.log(err)
                        setIsTabRefresh(false)
                        if (err.response !== undefined) {
                            if (err.response.status === 701) {
                                // if (toastBR.current !== null) {
                                //     toastBR.current.show({ severity: 'warn', summary: 'Oops', detail: 'Password used recently', life: 2800 });
                                // }
            showToast("error", "Password used recently");


                            }

                        }

                    })
            }
            else {
                if (toastBR.current !== null) {
                    // toastBR.current.show({ severity: 'warn', summary: 'Oops', detail: 'Both Passwords Should Be Same', life: 2800 });
            // showToast("error", "Both passwords should be same");

                }

            }
        }
        else {
            console.log("err")
            if (newpassword === "") {
                setNewpwdErr("New Password Required")
            }
            if (confirmpassword === "") {
                setConfirmPwdErr("Confirm Password Required")
            }
        }


    }
    return (
        <div>
            <Toast ref={toastBR} />
            {showPasswordForm ?
                <Card className="  flex justify-content-center login-card box-shadow-lg" >
                    <div className='signup-card-container'>
                        <div className='signup-card-header'>
                            <div className='signup-card-header-button'>
                                <div className='signup-card-header-button-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <path d="M18.0833 8.75001L22.1667 4.66668M24.5 2.33334L22.1667 4.66668L24.5 2.33334ZM13.2883 13.545C13.8907 14.1394 14.3696 14.8471 14.6974 15.6273C15.0252 16.4075 15.1954 17.2448 15.1982 18.0911C15.201 18.9373 15.0365 19.7758 14.7139 20.5582C14.3914 21.3406 13.9173 22.0514 13.3189 22.6498C12.7205 23.2482 12.0096 23.7224 11.2272 24.0449C10.4448 24.3674 9.60635 24.532 8.76009 24.5292C7.91382 24.5264 7.07648 24.3562 6.29627 24.0284C5.51606 23.7006 4.80839 23.2217 4.21401 22.6193C3.04517 21.4091 2.3984 19.7883 2.41302 18.1059C2.42764 16.4234 3.10248 14.814 4.29218 13.6243C5.48188 12.4346 7.09126 11.7598 8.77369 11.7452C10.4561 11.7306 12.077 12.3773 13.2872 13.5462L13.2883 13.545ZM13.2883 13.545L18.0833 8.75001L13.2883 13.545ZM18.0833 8.75001L21.5833 12.25L25.6667 8.16668L22.1667 4.66668L18.0833 8.75001Z" stroke="#145EF0" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>

                            </div>
                            <div className='signup-card-header-text1'>
                                Set new password
                            </div>
                            <div className='signup-card-header-text2'>
                                Your new password must be different to previously used passwords.
                            </div>
                        </div>




                        <div className='signup-card-header'>

                            <form onSubmit={handleSubmit1}>



                                <div className="justify-content-center dialog-form-field-space grid mt-3">
                                    <div className="field col-12 p-0">
                                        <div className="relative w-full">
                                            <input
                                                id="floating_outlined6"
                                                className={`block w-full px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border appearance-none dark:text-white focus:outline-none focus:ring-0 peer
                    ${newpwdErr
                                                        ? "border-red-500 focus:border-red-500 dark:border-red-500 dark:focus:border-red-500"
                                                        : "border-gray-300 focus:border-blue-600 dark:border-gray-600 dark:focus:border-blue-500"
                                                    }`}
                                                placeholder=""
                                                type={passwordType}
                                                value={newpassword}
                                                onChange={handlePassword}
                                                style={{ height: "40px" }}
                                            />
                                            
                                            <span
                                                className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                                                onClick={togglePassword}
                                            >
                                                <i className={`pi ${passwordType === "password" ? "pi-eye" : "pi-eye-slash"}`} style={{ fontSize: "1rem" }}></i>
                                            </span>
                                            <label
                                                htmlFor="floating_outlined6"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                                New Password*
                                            </label>
                                        </div>
                                        {newpwdErr && <p className="error-msg font-fam-for-all mb-4  w-[400px]">{newpwdErr}</p>}
                                    </div>
                                </div>

                                <div className="justify-content-center dialog-form-field-space grid mt-3">
                                    <div className="field col-12 p-0">
                                        <div className="relative">
                                            <input
                                                id="floating_outlined7"
                                                className={`block w-full px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border appearance-none dark:text-white focus:outline-none focus:ring-0 peer 
                    ${confirmPwdErr || (newpassword !== confirmpassword && confirmpassword && newpassword)
                                                        ? "border-red-500 focus:border-red-500 dark:border-red-500 dark:focus:border-red-500"
                                                        : "border-gray-300 focus:border-blue-600 dark:border-gray-600 dark:focus:border-blue-500"
                                                    }`}
                                                placeholder=""
                                                type={passwordType1}
                                                value={confirmpassword}
                                                onChange={handlePassword1}
                                                style={{ height: "40px" }}
                                            />
                                            <span
                                                className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                                                onClick={togglePassword1}
                                            >
                                                <i className={`pi ${passwordType1 === "password" ? "pi-eye" : "pi-eye-slash"}`} style={{ fontSize: "1rem" }}></i>
                                            </span>
                                            <label
                                                htmlFor="floating_outlined7"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                                Confirm New Password*
                                            </label>
                                        </div>
                                        {confirmPwdErr ? (
                                            <p className="error-msg font-fam-for-all w-[400px]">{confirmPwdErr}</p>
                                        ) : (newpassword !== confirmpassword && newpassword && confirmpassword) ? (
                                            <p className="error-msg font-fam-for-all w-[400px]">Password and confirm password don't match</p>
                                        ) : (newpassword === confirmpassword && newpassword && confirmpassword) ? (
                                            <p className="text-green-500 success-msg font-fam-for-all w-[400px]">Passwords matched</p>
                                        ) : ""}
                                    </div>
                                </div>


                                <div className='grid justify-content-center mt-3'>
                                    <div className=' col-12 p-0'>
                                        <div className='flex justify-content-center'>

                                            <Button type="submit" className="signup-btn vstate-button font-fam-for-all" style={{ width: "400px" }} >Reset password</Button>
                                            {/* <Button type='button' className="mt-2 form-Btn form-Btn-Label font-fam-for-all text-center text-lg bg-primary-reverse mr-2" onClick={cancelView} >Cancel</Button> */}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </Card> : <Card className="  flex justify-content-center login-card box-shadow-lg" >
                    <div className='signup-card-container'>
                        <div className='signup-card-header'>
                            <div className='signup-card-header-button1'>
                                <div className='signup-card-header-button-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <path d="M25.6667 12.9267V14C25.6652 16.5158 24.8506 18.9638 23.3442 20.9788C21.8378 22.9938 19.7205 24.4679 17.3079 25.1812C14.8953 25.8945 12.3168 25.8089 9.95686 24.937C7.59694 24.0652 5.58208 22.4538 4.21277 20.3433C2.84346 18.2327 2.19307 15.7361 2.3586 13.2257C2.52414 10.7153 3.49672 8.32572 5.13131 6.41326C6.76589 4.50079 8.9749 3.16795 11.4289 2.61351C13.8828 2.05907 16.4503 2.31273 18.7483 3.33667M25.6667 4.66667L14 16.345L10.5 12.845" stroke="#039855" stroke-width="2.33333" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>

                            </div>
                            <div className='signup-card-header-text1'>
                                Password reset
                            </div>
                            <div className='signup-card-header-text2 px-8 text-center'>
                                Your password has been successfully reset. Click below to log in magically.
                            </div>
                        </div>


                        <div className='signup-card-header'>






                            <div className='grid justify-content-center mt-3'>
                                <div className=' col-12 p-0'>
                                    <div className='flex justify-content-center'>

                                        <Button className="signup-btn vstate-button font-fam-for-all" style={{ width: "400px" }} onClick={() => navigate("/signin")} >Continue</Button>
                                        {/* <Button type='button' className="mt-2 form-Btn form-Btn-Label font-fam-for-all text-center text-lg bg-primary-reverse mr-2" onClick={cancelView} >Cancel</Button> */}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </Card>}
        </div>
    )
}

export default AddChangePassword