import React from "react";
import PaymentItem from "./PaymentItem";

const paymentItems = [
  {
    title: "State Filing",
    amount: "$230",
    description: "The filing fee for the application as per the state selected",
    additionalInfo: "Government fee"
  },
  {
    title: "Registered Agent Service",
    amount: "$99",
    description: "Fee to Appoint Registered Agent to handle necessary government, tax and legal correspondence about your business."
  },
  {
    title: "Same Day Service",
    amount: "$360",
    description: "Fee to complete your filing as per the turnaround time you choose."
  }
];

function PaymentDetails({data,order}) {
  return (
    <section className="flex flex-col mt-4 w-full max-md:max-w-full">
      <div className="flex flex-col w-full bg-white rounded-lg border border-gray-200 border-solid max-md:max-w-full">
        <header className="flex gap-1 items-center py-2 pr-5 pl-4 w-full text-sm font-medium leading-6 text-white bg-blue-600 rounded-lg border border-blue-300 border-solid max-md:max-w-full">
          <h6 className="flex-1 shrink gap-4 self-stretch my-auto w-full min-w-[240px] max-md:max-w-full">
            Payment Details for Order #{order.orderId}
          </h6>
        </header>

        <div className="flex flex-col p-4 w-full max-md:max-w-full">
          {data.sort((a, b) => a.productListing.displayPreference - b.productListing.displayPreference).map((item, index) => (
            <React.Fragment key={index}>
              <PaymentItem {...item} />
              {index < paymentItems.length - 1 && (
                <div className="flex mt-3 w-full bg-gray-200 min-h-[1px] max-md:max-w-full" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
}

export default PaymentDetails;