import React, { useState, useEffect } from "react";

const Ribbon = ({ message, autoHideDuration = 20000 }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, autoHideDuration);

    return () => clearTimeout(timer); // Clear the timer on unmount
  }, [autoHideDuration]);

  if (!visible) return null;

  return (
    <div
      className="fixed bottom-0 left-0 right-0 bg-web-color text-white p-4 flex justify-between items-center shadow-lg overflow-hidden"
      style={{ zIndex: 1000 }}
    >
      <div className="relative overflow-hidden flex-grow">
        <div className="marquee">
          <span>{message}</span>
        </div>
      </div>
      <button
        className="text-white ml-4"
        onClick={() => setVisible(false)}
        aria-label="Close"
      >
        <i className="pi pi-times"></i>
      </button>
    </div>
  );
};

export default Ribbon;
