import React from 'react'
import Header from '../RegistredAgentService/Header'
import Partners from '../Partners'
import ComplianceServices from './Complianc Service/ComplianceServices'
import ComplianceSection from './Compliance/ComplianceSection.js'
import FAQ from '../RegistredAgentService/Fact.js'
import ContactForm from './ContactForm.js'



const ServiceContent = () => {
    return (
        <div className='mt-24'>
            <main className="flex flex-col items-center px-80 pt-16 pb-20 max-md:px-5">
                <div className="flex flex-col px-5 w-full max-w-[1240px] max-md:max-w-full">
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <Header />
                    </div>
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <Partners />
                    </div>
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <ComplianceSection />
                    </div>
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <ComplianceServices />
                    </div>
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <FAQ />
                    </div>
                    <div className="flex flex-col px-8 website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <p className='contact-form-haeding-main-text1'>Need help to <span className='contact-form-haeding-main-text2'>stay compliant?</span></p>
                        <ContactForm />
                    </div>
                </div>
            </main>
        </div>
    )
}

export default ServiceContent