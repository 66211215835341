import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import AOS from "aos";
import "aos/dist/aos.css";
import BlueButton from "../CommonSection/BlueButton";

const serviceData = [
  {
    title: "Quick start your business",
    description:
      "Experience the fastest business registration service available. Fill out our form and our team will handle your company registration right away. Your business can be officially registered in a matter of days!",
  },
  {
    title: "We have got you covered!",
    description:
      "By choosing vState Filings to handle your company formation, you are making a strategic decision. We will protect you from potential losses. Our experts have registered thousands of businesses similar to yours across the US.",
  },
  {
    title: "Remote registration",
    description:
      "Enjoy the convenience of remote business registration with us. No need for office visits or lengthy phone waits, no matter where you are or what time it is.",
  },
  {
    title: "Free advisory sessions",
    description:
      "Explore our complimentary consultation services offered with our business formation packages. Our representatives are here to assist you, addressing all your concerns and helping you navigate the process seamlessly.",
  },
  {
    title: "Save money",
    description:
      "We provide the most efficient and cost-effective solution for business formation. Our streamlined process ensures simplicity and speed. Explore it firsthand.",
  },
  {
    title: "Personalized dashboard",
    description:
      "Your dashboard is designed for convenience. Access all your documents, register new companies, choose additional services, or cancel current ones—all from one modern and well-organized platform.",
  },
];

const RegistrationService = () => {
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const visibleServices = showAll ? serviceData : serviceData.slice(0, 3);

  return (
    <section className="main-section-class">
      <div className="entity-formation">
        <Container>
          <CommonHeadingTitle heading={"Get the best "} />
          <CommonHeadingTitle blueheading={" company registration service "} />
          <div data-aos="fade-right" data-aos-duration="3000">
            <Row className="justify-content-center">
              {visibleServices.map((service, index) => (
                <Col key={index} lg={4} md={6} sm={12} xs={12}>
                  <div className="common-card-main mt-5">
                    <h2 className="step-color">{service.title}</h2>
                    <p className="normal-common-txt mt-4">
                      {service.description}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
            <div className="text-center mt-4">
              <Button variant="primary" onClick={() => setShowAll(!showAll)}>
                {showAll ? "Read Less" : "Read More"}
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default RegistrationService;
