import React, { forwardRef, useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Button } from 'primereact/button';

const SalesReciept = ({
    clientName,
    orderNumber,
    orderDate,
    transactionId,
    transactionDate,
    billingInfo,
    shippingInfo,
    items,
    subtotal,
    shippingHandling,
    grandTotal
}) => {
    // Reference to the component
    const componentRef = useRef();

    const downloadPDF = () => {
        const input = componentRef.current;
        html2canvas(input, { scale: 3 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');

            // Calculate width and height to maintain aspect ratio
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;
            const pdfHeight = (canvasHeight * pdfWidth) / canvasWidth;

            // Add image to PDF
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`${orderNumber}_Sales_Receipt.pdf`);
        });
    };
    return (
        <div style={{ margin: 0, padding: "10px", backgroundColor: '#e1e1e1' }} className='font-fam-for-all'>

            <div ref={componentRef}>
                <table width="100%" align="center" bgcolor="#ffffff" style={{ borderRadius: '10px 10px 0 0', maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
                    <tbody>
                        <tr>
                            <td style={{ padding: '20px' }}>
                                <table width="100%" align="center" style={{ fontFamily: "'Open Sans', sans-serif", fontSize: '14px' }}>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {/* Company Logo */}
                                                <table width="50%" align="left" style={{ maxWidth: '400px', paddingBottom: '10px' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td align="left" style={{ padding: '10px' }}>
                                                                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/e31e4713bd27658ebea052a9e7bf6f260165f18de95f314758ffce8e39b6a6b1?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0" alt="Brand Logo" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontSize: '14px', color: '#5b5b5b', lineHeight: '20px', textAlign: 'left', paddingTop: '5px' }} className='font-fam-for-all'>
                                                                Hello, {clientName},<br /> Thank you for placing your order.
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                {/* Invoice and Order Info */}
                                                <table width="50%" align="right" style={{ maxWidth: '400px', paddingBottom: '10px' }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ fontSize: '24px', color: '#004EEC', lineHeight: 1.2, textAlign: 'right' }} className='font-fam-for-all'>
                                                                Sales Receipt
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontSize: '14px', color: '#5b5b5b', lineHeight: '20px', textAlign: 'right', paddingTop: '5px' }} className='font-fam-for-all'>
                                                                <small>ORDER</small> #{orderNumber}<br />
                                                                <small>{orderDate}</small>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table width="100%" align="center" bgcolor="#ffffff" style={{ marginTop: '20px', maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
                    <tbody>
                        <tr>
                            <td>
                                <table width="100%" align="center" style={{ fontFamily: "'Open Sans', sans-serif", fontSize: '14px', padding: '20px' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ fontSize: '14px', color: '#1849A9', padding: '12px 20px', textAlign: 'left',background:"#EFF8FF" }} className='font-fam-for-all'>Item</th>
                                            <th style={{ fontSize: '14px', color: '#1849A9', padding: '12px 20px', textAlign: 'center',backgroundColor:"#EFF8FF" }} className='font-fam-for-all'>Quantity</th>
                                            <th style={{ fontSize: '14px', color: '#1849A9', padding: '12px 20px', textAlign: 'right',backgroundColor:"#EFF8FF" }} className='font-fam-for-all'>Subtotal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <tr style={{borderBottom:"1px solid #e4e4e4"}}>
                                                    <td style={{ fontSize: '14px', color: '#004EEC', padding: '12px 20px' }} className='font-fam-for-all'>{item.productListing.displayName}</td>
                                                    <td style={{ fontSize: '14px', color: '#646a6e', textAlign: 'center', padding: '12px 20px' }} className='font-fam-for-all'>{item.quantity}</td>
                                                    <td style={{ fontSize: '14px', color: '#1e2b33', textAlign: 'right', padding: '12px 20px' }} className='font-fam-for-all'>${item.unitPrice.toFixed(2)}</td>
                                                </tr>
                                               
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table width="100%" align="center" bgcolor="#ffffff" style={{ marginTop: '20px', maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
                    <tbody>
                        <tr>
                            <td>
                                <table width="100%" align="center" style={{ fontFamily: "'Open Sans', sans-serif", fontSize: '14px', padding: '20px' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontSize: '12px', fontFamily: "'Open Sans', sans-serif", color: '#646a6e', textAlign: 'right', padding: '3px 20px' }} className='font-fam-for-all'>
                                                Subtotal
                                            </td>
                                            <td style={{ fontSize: '12px', fontFamily: "'Open Sans', sans-serif", color: '#646a6e', textAlign: 'right', padding: '3px 20px' }} className='font-fam-for-all'>
                                                ${subtotal.toFixed(2)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: '12px', fontFamily: "'Open Sans', sans-serif", color: '#646a6e', textAlign: 'right', padding: '3px 20px' }} className='font-fam-for-all'>
                                                Tax
                                            </td>
                                            <td style={{ fontSize: '12px', fontFamily: "'Open Sans', sans-serif", color: '#646a6e', textAlign: 'right', padding: '3px 20px' }} className='font-fam-for-all'>
                                                ${shippingHandling.toFixed(2)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontSize: '12px', fontFamily: "'Open Sans', sans-serif", color: '#000', textAlign: 'right', padding: '3px 20px' }} className='font-fam-for-all'>
                                                <strong>Grand Total (Incl. Tax)</strong>
                                            </td>
                                            <td style={{ fontSize: '12px', fontFamily: "'Open Sans', sans-serif", color: '#000', textAlign: 'right', padding: '3px 20px' }} className='font-fam-for-all'>
                                                <strong>${grandTotal.toFixed(2)}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td height="20"></td>
                        </tr>
                    </tbody >
                </table >
<div className='card-devider'></div>
                <table width="100%" align="center" bgcolor="#ffffff" style={{ marginTop: '20px', maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
                    <tbody>
                        
                        <tr>
                            <td>
                                <table width="750" border="0" cellpadding="0" cellspacing="0" align="center" className="fullPadding">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table width="220" border="0" cellpadding="0" cellspacing="0" align="left" className="col">
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ fontSize: '11px', fontFamily: "'Open Sans', sans-serif", color: '#5b5b5b', lineHeight: 1, verticalAlign: 'top' }} className='font-fam-for-all pt-4'>
                                                                <strong>BILLING INFORMATION</strong>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="100%" height="10"></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontSize: '12px', fontFamily: "'Open Sans', sans-serif", color: '#5b5b5b', lineHeight: '20px', verticalAlign: 'top' }} className='font-fam-for-all'>
                                                            {billingInfo.contactName}<br />{billingInfo.address}<br />{billingInfo.city}<br /> {billingInfo.state}<br /> {billingInfo.zip}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
        
                                            <td>
                                                <table width="220" border="0" cellpadding="0" cellspacing="0" align="right" className="col">
                                                    <tbody>
                                                       
                                                        <tr>
                                                            <td style={{ fontSize: '11px', fontFamily: "'Open Sans', sans-serif", color: '#5b5b5b', lineHeight: 1, verticalAlign: 'top' }}>
                                                                <strong>PAYMENT METHOD</strong>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="100%" height="10"></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontSize: '12px', fontFamily: "'Open Sans', sans-serif", color: '#5b5b5b', lineHeight: '20px', verticalAlign: 'top' }}>
                                                                Credit Card<br /> Credit Card Type: Visa<br /> Worldpay Transaction ID: <a style={{ color: '#004EEC', textDecoration: 'underline' }}>{transactionId}</a><br />
                                                                Transaction Date: <a style={{ color: '#004EEC', textDecoration: 'underline' }}>{transactionDate}</a><br />
                                                                <a href="#" style={{ color: '#b0b0b0' }}></a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr >
                            <td>
                                <table width="750" border="0" cellpadding="0" cellspacing="0" align="center" className="fullPadding">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table width="220" border="0" cellpadding="0" cellspacing="0" align="left" className="col">
                                                    <tbody>
                                                      
                                                        <tr>
                                                            <td style={{ fontSize: '11px', fontFamily: "'Open Sans', sans-serif", color: '#5b5b5b', lineHeight: 1, verticalAlign: 'top' }} className='font-fam-for-all pt-4'>
                                                                <strong>SHIPPING INFORMATION</strong>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width="100%" height="10"></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ fontSize: '12px', fontFamily: "'Open Sans', sans-serif", color: '#5b5b5b', lineHeight: '20px', verticalAlign: 'top' }} className='font-fam-for-all'>
                                                            {shippingInfo.contactName}<br />{shippingInfo.address}<br />{shippingInfo.city}<br /> {shippingInfo.state}<br /> {shippingInfo.zip}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr className="hiddenMobile">
                            <td height="60"></td>
                        </tr>
                        <tr className="visibleMobile">
                            <td height="30"></td>
                        </tr>
                    </tbody>
                </table>


                <table width="100%" align="center" bgcolor="#ffffff" style={{ marginTop: '20px', maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
                    <tr>
                        <td>
                            <table width="750" border="0" cellpadding="0" cellspacing="0" align="center" className="fullPadding">
                                <tbody>
                                    <tr>
                                        <td style={{ fontSize: '12px', color: '#5b5b5b', fontFamily: "'Open Sans', sans-serif", lineHeight: '18px', verticalAlign: 'top', textAlign: 'left' }} className='font-fam-for-all'>
                                            Have a nice day.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr className="spacer">
                        <td height="50"></td>
                    </tr>
                </table>


            </div >




            <div className='flex w-full justify-content-center p-2'>
                <Button className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Download Sales Receipt as PDF' icon="pi pi-download" onClick={downloadPDF} ></Button>
            </div>
        </div >
    );
};

export default SalesReciept;
