import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { ToggleButton } from "primereact/togglebutton";
import OrderServices from "../../../../services/orderServices";
import "../InvoicePayment/InvoicePayment.css";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export default function ClientSubscriptionTable({ onCountUpdate }) {
  const allColumns = [
    { field: "clientCompanyName", header: "Client Company Name" },
    { field: "subscriptionName", header: "Subscription Name" },
    { field: "entityName", header: "Entity Name" },
    { field: "subscriptionDate", header: "Subscription Date" },
    { field: "subscriptionExpiryDate", header: "Subscription Expiry Date" },
    { field: "orderNumber", header: "Order Number" },
    { field: "notification", header: "Mute Notification" },
    // { field: "actions", header: "Actions" },
  ];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [visibleColumns, setVisibleColumns] = useState(allColumns);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState(false);

  // Corrected toggleNotification function
  const toggleNotification = () => {
    setNotification((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await OrderServices.getClientSubscription(0, 0);
        const formattedData =
          response.data?.map((item) => ({
            id: item?.lineItemId || 0,
            clientCompanyName: item?.companyName || "N/A",
            subscriptionName: item?.subscriptionName || "N/A",
            entityName: item?.entityName || "N/A",
            subscriptionDate: item?.orderDate ? new Date(item.orderDate) : null,
            subscriptionExpiryDate: item?.subscriptionExpiryDate
              ? new Date(item.subscriptionExpiryDate)
              : null,
            orderNumber: item?.orderNumber || "N/A",
            notification: false,
          })) || [];

        setData(formattedData);
        if (onCountUpdate) {
          onCountUpdate(formattedData.length);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        // setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    initFilters();
  }, []);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: "contains" },
    });
    setGlobalFilterValue("");
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters({ ...filters, global: { value, matchMode: "contains" } });
    setGlobalFilterValue(value);
  };

  const onColumnToggle = (event) => {
    const selectedColumns = event.value;
    setVisibleColumns(selectedColumns);
  };

  const renderHeader = () => (
    <Row>
      {/* <Col xl={6} md={6} sm={12} xs={12}>
        <MultiSelect
          value={visibleColumns}
          options={allColumns}
          optionLabel="header"
          onChange={onColumnToggle}
          placeholder="Select Columns"
          className="w-full w-20rem sm:w-16rem ms-2"
          display="chip"
        />
      </Col> */}
      <Col xl={12} md={12} sm={12} xs={12}>
        <div className="main-dash-btnn-class flex justify-content-end p-2">
          {/* <div>
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Clear"
              outlined
              onClick={initFilters}
              className="common-blue-btnn-for me-2"
            />
          </div> */}
          <div>
            {/* <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Global Search"
            /> */}
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </InputGroup.Text>
              <Form.Control
                placeholder="Global Search"
                aria-describedby="basic-addon1"
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
              />
            </InputGroup>
          </div>
        </div>
      </Col>
    </Row>
  );

  const formatDate = (date) =>
    date ? date.toLocaleDateString("en-US") : "N/A";

  const notificationTemplate = (rowData) => (
    <ToggleButton
      checked={rowData.notification}
      onChange={() => {
        setData((prevData) =>
          prevData.map((item) =>
            item.id === rowData.id
              ? { ...item, notification: !item.notification }
              : item
          )
        );
      }}
      onIcon="pi pi-bell"
      offIcon="pi pi-bell-slash"
      style={{ width: "5.5rem", height: "2.5rem" }} // Optional for better UI
    />
  );

  const entityTemplate = (rowData) => (
    <span className="capitalize">
      {rowData.entityName}
    </span>
  );
  // return (
  //   <div className="main-table-class-datatable">
  //     <div className="card">
  //       {error && <div className="alert alert-danger">{error}</div>}
  //       <DataTable
  //         value={data}
  //         paginator
  //         rows={5}
  //         loading={loading}
  //         filters={filters}
  //         globalFilterFields={allColumns.map((col) => col.field)}
  //         header={renderHeader()}
  //         emptyMessage="No records found."
  //         scrollable
  //         scrollHeight="flex"
  //         scrollWidth="100%"
  //       >
  //         {visibleColumns.map((col) => {
  //           if (
  //             col.field === "subscriptionDate" ||
  //             col.field === "subscriptionExpiryDate"
  //           ) {
  //             return (
  //               <Column
  //                 sortable
  //                 key={col.field}
  //                 field={col.field}
  //                 header={col.header}
  //                 body={(rowData) => formatDate(rowData[col.field])}
  //                 className="font-fam-for-all datatable-lg-col"
  //               />
  //             );
  //           }
  //           if (col.field === "notification") {
  //             return (
  //               <Column
  //                 key="notification"
  //                 header="Notification"
  //                 body={notificationTemplate}
  //                 className="font-fam-for-all datatable-lg-col"
  //               />
  //             );
  //           }
  //           // if (col.field === "actions") {
  //           //   return (
  //           //     <Column
  //           //       key={col.field}
  //           //       header={col.header}
  //           //       className="font-fam-for-all datatable-lg-col"
  //           //       body={() => (
  //           //         <span style={{ color: "#B54708", fontWeight: "bold" }}>
  //           //           Send Reminder
  //           //         </span>
  //           //       )}
  //           //     />
  //           //   );
  //           // }
  //           return (
  //             <Column
  //               sortable
  //               key={col.field}
  //               field={col.field}
  //               header={col.header}
  //             />
  //           );
  //         })}
  //       </DataTable>
  //     </div>
  //   </div>
  // );

  return (
    <div className="main-table-class-datatable">
      <div className="card">
        {error && <div className="alert alert-danger">{error}</div>}
        <DataTable
          value={data}
          paginator
          rows={5}
          loading={loading}
          filters={filters}
          globalFilterFields={allColumns.map((col) => col.field)}
          header={renderHeader()}
          emptyMessage="No records found."
          scrollable
          scrollHeight="flex"
          scrollWidth="100%"
        >
          {visibleColumns.map((col) => {
            if (col.field === "subscriptionDate" || col.field === "subscriptionExpiryDate") {
              return (
                <Column
                  sortable
                  key={col.field}
                  field={col.field}
                  header={col.header}
                  body={(rowData) => formatDate(rowData[col.field])}
                  className="font-fam-for-all datatable-lg-col"
                />
              );
            }
            if (col.field === "entityName") {
              return (
                <Column
                  key="entityName"
                  field="entityName"
                  header="Entity Name"
                  body={entityTemplate}
                  className="font-fam-for-all datatable-lg-col "
                />
              );
            }
            if (col.field === "notification") {
              return (
                <Column
                  key="notification"
                  header="Notification"
                  body={notificationTemplate}
                  className="font-fam-for-all datatable-lg-col"
                />
              );
            }
            return (
              <Column
                sortable
                key={col.field}
                field={col.field}
                header={col.header}
              />
            );
          })}
        </DataTable>
      </div>
    </div>
  );
}
