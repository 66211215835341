import React, { useRef } from "react";
import RegisteredBanner from "../RegistredAgentService/RegisteredBanner.js";
import ComplienceSec from "../AllServices/CompliencePage/ComplienceSec.js";
import ComplienceThird from "../AllServices/CompliencePage/ComplienceThird.js";
import bannerimg from "../../../Assets/Images/compilancebanner.png";
import OurTrustedSection from "../CommonSection/OurTrustedSection.js";
import CommonRegister from "../CommonSection/CommonRegister.js";
import CompilenceFaq from "../AllServices/CompliencePage/CompilenceFaq.js";
import { Container } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle.js";
import ServiceForm from "../CommonSection/ServiceForm.js";

const ServiceContent = () => {
  const registeredTabRef = useRef(null);

  const handleScroll = () => {
    if (registeredTabRef.current) {
      registeredTabRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      console.error("registeredTabRef is null");
    }
  };

  return (
    <div>
      <RegisteredBanner
        title={"Stay compliant,"}
        highlightText={"stay protected"}
        subtitle={
          "Stay compliant and up-to-date with essential business filings."
        }
        buttonLabel={"Learn More"}
        onButtonClick={handleScroll} 
        bannerImage={bannerimg}
      />
      {/* <OurTrustedSection /> */}
      <ComplienceSec />
      <div ref={registeredTabRef} id="registered-tab-section">
        <ComplienceThird />
      </div>
      <div className="entity-formation">
        <Container>
          <div className="text-center mt-5 mb-5">
            <CommonHeadingTitle
              heading={"Need help to "}
              blueheading={"stay compliant?"}
            />
          </div>
          <ServiceForm />
        </Container>
      </div>
      <CommonRegister
        title={"Keep your business legally secure"}
        buttonLabel={"Start Your Business"}
        buttonLink={"/signup"}
      />
      <CompilenceFaq />
    </div>
  );
};

export default ServiceContent;
