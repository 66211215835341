import React, { useState, useRef } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'; // Correct import for DateRangePicker
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import OrderServices from '../services/orderServices';

const DateRangeSelector = ({ setProducts, fetchAndSelectProduct }) => {
    const [dateRange, setDateRange] = useState([null, null]);
    const overlayPanelRef = useRef(null);
    const [value, setValue] = useState([dayjs().subtract(7, 'month'), dayjs().subtract(6, 'month')]);
    const minDate = dayjs().subtract(6, 'month'); // Six months back from today
    const maxDate = dayjs(); // Current date
    const handleDateChange = (newDateRange) => {
        setDateRange(newDateRange);
        setValue(newDateRange)
    };

    const handleApply = () => {
        const [start, end] = value;
        console.log(start)
        console.log(end)
        if (start && end) {
            const formattedStartDate = dayjs(start).format('MM-DD-YYYY');
            const formattedEndDate = dayjs(end).format('MM-DD-YYYY');
            OrderServices.getAllOrderByStatusAndDate(11, formattedStartDate,formattedEndDate)
                .then((res) => {
                    console.log(res)
                    if (Array.isArray(res.data)) {
                        setProducts(res.data)
                        const data = res.data
                        if (data.length > 0) {
                            fetchAndSelectProduct(data)
                        }
                    }
                    else{
                        setProducts([])
                        fetchAndSelectProduct([])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })

            overlayPanelRef.current.hide();
        }
    };

    return (
        <>
            <Button
                className="vstate-reverse-button mb-2 form-Btn-Label font-fam-for-all text-center form-label mr-3"
                icon="pi pi-calendar"
                label="Search Archived Orders"
                onClick={(e) => overlayPanelRef.current.toggle(e)}
            />
            <OverlayPanel ref={overlayPanelRef} className="rounded-lg shadow-lg p-6 bg-white">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                        value={value}
                        disableFuture
                        onChange={(newValue) => {
                            setValue(newValue);
                            handleDateChange(newValue);
                        }}
                        renderInput={(startProps, endProps) => (
                            <div className="flex gap-2 mt-4 mb-6">
                                <TextField
                                    {...startProps}
                                    variant="outlined"
                                    className="w-full border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 rounded-md shadow-sm"
                                    inputProps={{ className: 'text-sm' }}
                                />
                                <TextField
                                    {...endProps}
                                    variant="outlined"
                                    className="w-full border-gray-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 rounded-md shadow-sm"
                                    inputProps={{ className: 'text-sm' }}
                                />
                            </div>
                        )}
                    />
                </LocalizationProvider>
                <div className="flex w-full justify-center">
                    <Button
                        label="Filter"
                        onClick={handleApply}
                        className="vstate-button my-3 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-lg transition duration-150"
                    />
                </div>
            </OverlayPanel>
        </>
    );
};

export default DateRangeSelector;
