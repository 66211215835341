import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Dropdown } from 'primereact/dropdown';
import MasterServices from '../../services/coreServices';
import { CSVLink } from 'react-csv';
import MasterDashboard from '../MasterDashboard';


const RolesMaster = ({ data , items }) => {
    const [products, setProducts] = useState([]);
    const [columns, setColumns] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: 'contains' },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [visibleColumns, setVisibleColumns] = useState([]);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [formData, setFormData] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [additionalOptions, setAdditionalOptions] = useState({});

    const dt=useRef(null)
    const tempdata = items;
    console.log(tempdata)
    // const tempdata = [
    //     {
    //         item: "Role Master",
    //         getAllUrl: "/core/api/roleMaster/getAll",

    //         getAditionalUrl: [
    //             {
    //                 field: "permissionMasters",
    //                 url: "/core/api/permissionMaster/getAll",
    //                 label: "permissionName"
    //             }
    //         ],
           

    //     },

    //     {
    //         item: "State",
    //         getAllUrl: "/core/api/stateMaster/getAll",
    //         getAditionalUrl: [
    //             {
    //                 field: "countryMaster",
    //                 url: "/core/api/countryMaster/getAll",
    //                 label: "countryFullDesc"
    //             }
    //         ],
    //         saveUrl: "/core/api/stateMaster/save",
    //     },
    //     {
    //         item: "Country",
    //         getAllUrl: "/core/api/countryMaster/getAll",
    //         getAditionalUrl: [
    //             {
    //                 field: "countryMaster",
    //                 url: "/core/api/permissionMaster/getAll",
    //                 label: "countryFullDesc"
    //             }
    //         ],
    //         saveUrl: "/core/api/countryMaster/save",
    //     },
    //     {
    //         item: "Contact Source Master",
    //         getAllUrl: "/core/api/contactSourceMaster/getAll",
    //         getAditionalUrl: [
    //             {
    //                 field: "permissionMaster",
    //                 url: "/core/api/permissionMaster/getAll",
    //                 label: "statusForName"
    //             }
    //         ],
    //         saveUrl: "/core/api/contactSourceMaster/save",
        

    //     },

    //     {
    //         item: "Industry Master",
    //         getAllUrl: "/core/api/industryMaster/getAll",
    //         getAditionalUrl: [
    //             {
    //                 field: "",
    //                 url: "/core/api/permissionMaster/getAll",
    //                 label: "statusForName"
    //             }
    //         ],
    //         saveUrl: "",
    //     },
    //     {
    //         item: "Record Type Master",
    //         getAllUrl: "/core/api/recordTypeMaster/getAll",
    //         getAditionalUrl: [
    //             {
    //                 field: "",
    //                 url: "/core/api/permissionMaster/getAll",
    //                 label: "statusForName"
    //             }
    //         ],
    //         saveUrl: "",
    //     },


    //     {
    //         item: "Permission Master",
    //         getAllUrl: "/core/api/permissionMaster/getAll",
    //         getAditionalUrl: [
    //             {
    //                 field: "",
    //                 url: "/core/api/permissionMaster/getAll",
    //                 label: ""
    //             }
    //         ],
    //         saveUrl: "",

    //     },

    //     {
    //         item: "Status Master",
    //         getAllUrl: "/core/api/statusMaster/getAll",
    //         getAditionalUrl: [
    //             {
    //                 field: "statusForId",
    //                 url: "/core/api/statusForMaster/getAll",
    //                 label: "statusForName"
    //             }
    //         ],
    //         saveUrl: "",
    //     },
    //     {
    //         item: "Record Type Master",
    //         getAllUrl: "/core/api/recordTypeMaster/getAll",
    //         getAditionalUrl: [
    //             {
    //                 field: "",
    //                 url: "/core/api/permissionMaster/getAll",
    //                 label: "statusForName"
    //             }
    //         ],
    //         saveUrl: "",
    //     },
// 
    // ]

    function convertToTitleCase(str) {

        const result = str.replace(/([A-Z])/g, ' $1').trim();


        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    useEffect(() => {
        if (data) {
            MasterServices.getAll(data.getAllUrl)
                .then((res) => {
                    if (res.data !== "") {
                        if (res.data && res.data.length > 0) {
                            setProducts(res.data);

                    
                            const dynamicColumns = Object.keys(res.data[0])
                                .filter(key => !['createdBy', 'creationDate', 'lastModifiedBy', 'lastModifiedDate','id'].includes(key))
                                .map(key => {
                                    const valueType = typeof res.data[0][key];
                                    return {
                                        field: key,
                                        header: key.charAt(0).toUpperCase() + key.slice(1),
                                        type: valueType === 'string' ? 'string' :
                                            Array.isArray(res.data[0][key]) ? 'array' :
                                                valueType === 'object' ? 'object' :
                                                    null
                                    };
                                })
                                .filter(col => col !== null);

                            setColumns(dynamicColumns);
                            setVisibleColumns(dynamicColumns);

                            console.log(dynamicColumns)

                        
                            dynamicColumns.forEach(col => {
                                if (col.type === 'object' || col.type === 'array') {
                                   
                                    const additionalUrlObj = tempdata.find(item => item.item === data.item)?.getAditionalUrl.find(url => url.field === col.field);
                                    console.log(col)
                                    console.log(additionalUrlObj)
                                    if (additionalUrlObj) {
                                        MasterServices.getAll(additionalUrlObj.url)
                                            .then(res => {
                                                setAdditionalOptions(prevOptions => ({
                                                    ...prevOptions,
                                                    [col.field]: {
                                                        label: additionalUrlObj.label,
                                                        value: res.data
                                                    }

                                                }));
                                            })
                                            .catch(err => console.log(err));
                                    }
                                }
                            });
                        }
                    }
                    else {
                        setProducts([])
                        setVisibleColumns([])
                        setColumns([])
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setProducts([]);
                });
        }
    }, [data]);

    console.log(additionalOptions)

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter((col) =>
            selectedColumns.some((sCol) => sCol.field === col.field)
        );
        setVisibleColumns(orderedSelectedColumns);
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div>
                <div className="flex align-items-center justify-content-between">
                    <div className="flex align-items-center gap-2">
                        <MultiSelect
                            value={visibleColumns}
                            options={columns}
                            optionLabel="header"
                            onChange={onColumnToggle}
                            filter
                            className="w-full sm:w-20rem"
                            display="chip"
                        />
                        <span className="p-input-icon-left ml-3">
                            <i className="pi pi-search" />
                            <InputText
                                value={globalFilterValue}
                                onChange={onGlobalFilterChange}
                                style={{ borderRadius: "8px" }}
                                placeholder="Keyword Search"
                            />
                        </span>
                       
                    </div>
                   
                    <div className='flex gap-3'>
                    <span>
                            {
                                products.length > 0 ? <CSVLink filename={`${data.item}.csv`} data={products}>
                                    <Button
                                        type="button"
                                        icon="pi pi-file"
                                        className="vstate-reverse-button font-fam-for-all" label='Download CSV' data-pr-tooltip="Download CSV"  tooltipOptions={{ position: 'bottom' }}
                                    ></Button>
                                </CSVLink> : <Button
                                    type="button"
                                    icon="pi pi-file"
                                    disabled={products.length > 0 ? false : true}
                                    className="vstate-reverse-button font-fam-for-all" data-pr-tooltip="Download CSV" label='Download CSV' tooltipOptions={{ position: 'bottom' }}
                                ></Button>
                            }
                        </span>
                        <Button label="Add" icon="pi pi-plus" className='vstate-button font-fam-for-all' onClick={() => openSidebar(false)} />
                    </div>
                </div>
            </div>
        );
    };

    const header = renderHeader();

    const iconBodyTemplate = (rowData) => {
        return (
            <>
                <button className="pi pi-pencil image-btn-icon" data-toggle="tooltip" data-placement="bottom" title="Edit" onClick={() => openSidebar(true, rowData)}></button>
                {/* <button className="pi pi-trash image-btn-icon-delete" data-toggle="tooltip" data-placement="bottom" title="Delete"></button> */}
            </>
        );
    };

    const openSidebar = (isEdit, data = {}) => {
        setIsEditMode(isEdit);
        setFormData(data);
        setSidebarVisible(true);
    };

    console.log(additionalOptions)


    const renderFormField = (col) => {
        console.log(col.type)

        console.log(additionalOptions)

        if (col.type === 'object' || col.type === 'array') {
            if (!additionalOptions[col.field]) {
                return null; 
            }
        }
        switch (col.type) {
            case 'string':
                return (
                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space w-full">

                                <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(col.header)} </label>
                                <span className="p-float-label ">

                                    <InputText value={formData[col.field] || ''}
                                        style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px' }}
                                        className='dialog-form-input-field-wizard p-inputtext'
                                        onChange={(e) => onInputChange(e, col.field)} />

                                </span>

                            </div>

                        </div>

                    </div>
                    // <div className="field" key={col.field}>
                    //     <label>{convertToTitleCase(col.header)}</label>
                    //     <InputText value={formData[col.field] || ''} onChange={(e) => onInputChange(e, col.field)} />
                    // </div>
                );
            case 'object':
                return (
                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space w-full">

                                <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(col.header)} </label>
                                <span className="p-float-label ">

                                    <Dropdown value={formData[col.field] || ''} filter resetFilterOnHide
                                        style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px' }}
                                        className='dialog-form-input-field-wizard'
                                        options={additionalOptions[col.field].value || []} optionLabel={additionalOptions[col.field].label} onChange={(e) => onInputChange(e, col.field)} />

                                </span>

                            </div>

                        </div>

                    </div>
                    // <div className="field" key={col.field}>
                    //     <label>{convertToTitleCase(col.header)}</label>
                    //     <Dropdown value={formData[col.field] || ''} options={additionalOptions[col.field].value || []} optionLabel={additionalOptions[col.field].label} onChange={(e) => onInputChange(e, col.field)} />
                    // </div>
                );
                case 'boolean':
                    return (
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className=" justify-content-center dialog-form-field-space w-full">
    
                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(col.header)} </label>
                                    <span className="p-float-label ">
    
                                        <Dropdown value={formData[col.field] || ''} filter resetFilterOnHide
                                            style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px' }}
                                            className='dialog-form-input-field-wizard'
                                            options={additionalOptions[col.field].value || []} optionLabel={additionalOptions[col.field].label} onChange={(e) => onInputChange(e, col.field)} />
    
                                    </span>
    
                                </div>
    
                            </div>
    
                        </div>
                        // <div className="field" key={col.field}>
                        //     <label>{convertToTitleCase(col.header)}</label>
                        //     <Dropdown value={formData[col.field] || ''} options={additionalOptions[col.field].value || []} optionLabel={additionalOptions[col.field].label} onChange={(e) => onInputChange(e, col.field)} />
                        // </div>
                    );
            case 'array':
                return (
                    <div className='input-fields-container' style={{ flexDirection: 'column', width: "50%" }}>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space w-full">

                                <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(col.header)} </label>
                                <span className="p-float-label ">

                                    <MultiSelect value={formData[col.field] || []}
                                        style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px' , width: "285px"  }}
                                        className='dialog-form-input-field-wizard multiselect-demo'
                                        options={additionalOptions[col.field].value || []} optionLabel={additionalOptions[col.field].label} onChange={(e) => onInputChange(e, col.field)} />
                                </span>

                            </div>

                        </div>

                    </div>
                    // <div className="field" key={col.field}>
                    //     <label>{convertToTitleCase(col.header)}</label>
                    //     <MultiSelect value={formData[col.field] || []} options={additionalOptions[col.field].value || []} optionLabel={additionalOptions[col.field].label} onChange={(e) => onInputChange(e, col.field)} />
                    // </div>
                );
            default:
                return null;
        }
    };

    // const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    // const exportCSV = (selectionOnly) => {
    //     dt.current.exportCSV({ selectionOnly });
    // };


    const onInputChange = (e, field) => {
        const value = e.target.value;
        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    const saveForm = () => {
        if (isEditMode) {
            MasterServices.updateById(formData, data.updateUrl)
                .then((res) => {
                    console.log(res)
                    setSidebarVisible(false);
                    window.location.reload(false)
                })
                .catch((err) => {
                    console.log(err)
                    setSidebarVisible(false);
                })
        } else {
            MasterServices.saveAll(formData, data.saveUrl)
                .then((res) => {
                    console.log(res);
                    setSidebarVisible(false);
                    window.location.reload(false)
                })
                .catch((err) => {
                    console.log(err);
                    setSidebarVisible(false);
                });
        }

    };

    const objectBodyTemplate = (rowData, field) => {
        const data = rowData[field];
        if (data) {
            const keys = Object.keys(data).filter(key => !['createdBy', 'creationDate', 'lastModifiedBy', 'lastModifiedDate', 'id'].includes(key));
            if (keys.length > 0) {
                const firstKey = keys[0]; 
                const firstValue = data[firstKey]; 

                return (
                    <div key={firstKey}>
                        {firstValue}
                    </div>
                );
            } else {
                return <></>;
            }
        } else {
            return <></>;
        }
    };

    const arrayBodyTemplate = (rowData, field) => {
        if (Array.isArray(rowData[field])) {
            console.log(rowData[field]);
    
            const filteredItems = rowData[field].map(item => {
                const filteredItem = Object.keys(item)
                    .filter(key => !['createdBy', 'creationDate', 'lastModifiedBy', 'lastModifiedDate', 'id' ].includes(key))
                    .reduce((obj, key) => {
                        obj[key] = item[key];
                        return obj;
                    }, {});
    
                return filteredItem;
            });
    
            console.log(filteredItems);
    
            if (filteredItems.length > 0) {
                const firstValues = filteredItems.map(item => {
                    const firstValue = Object.values(item)[0]; 
                    return firstValue;
                });
    
                return (
                    <div>
                        {firstValues.join(', ')}
                    </div>
                );
            }
        } else {
            console.log(rowData[field]);
            return <></>;
        }
    
        return <></>;
    };
    
    


    const toPascalCase = (str) => {
        return str.replace(/-/g, ' ').replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    };

    console.log(columns)

    return (
        <div>
            <DataTable
                value={products}
                header={header}
                editMode="row"
                dataKey="id"
                paginator
                rows={10}
                scrollable
                ref={dt}
                filters={filters}
                rowsPerPageOptions={[5, 10, 25, 50]}
                className="datatable-sm font-fam-for-all master-datatable1"
                globalFilterFields={columns.map(col => col.field)}
            >
                {visibleColumns.map(col => (
                    <Column
                        key={col.field}
                        field={col.field}
                        header={col.header}
                        sortable
                        className="font-fam-for-all datatable-sm-col"
                        body={col.type === 'object' ? (rowData) => objectBodyTemplate(rowData, col.field)
                            : col.type === 'array' ? (rowData) => arrayBodyTemplate(rowData, col.field)
                                : undefined}
                    />
                ))}
                <Column
                    body={iconBodyTemplate}
                    header="Action"
                    className="font-fam-for-all datatable-lg-col webapp-sm-size"
                />
            </DataTable>

            <Sidebar visible={sidebarVisible} position='right' className='dialog-box' onHide={() => setSidebarVisible(false)}>
                <div className='grid px-2'>
                    <div className='grid col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11 px-4 pt-4 pb-0'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sidebar-header-text1 pb-0'>
                            {isEditMode ? `Edit ${data.item}` : `Add ${data.item}`}
                        </div>

                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1 flex justify-content-end pt-4 pb-0'>
                        <button onClick={() => setSidebarVisible(false)}><i className='pi pi-times'></i></button>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sidebar-header-text2 px-4'>
                        {`Fill the below details to add ${data.item}  of your business.`}
                    </div>
                </div>
                <form>
                    <div className='p-4'>
                        <div className="vstate-card p-4 mb-3">
                            <div className="grid" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0" style={{ width: '100%' }}>
                                    <h2 className="entity-header-strapi">{toPascalCase(data.item)}</h2>
                                </div>
                                {columns.map((col, k) => {
                                    if (!isEditMode && col.field === "id") {
                                        return (<></>)
                                    }
                                    else if (col.field !== "id") {
                                        return (
                                            <div className="col-6" key={k} style={{ width: '50%' }}>
                                                {renderFormField(col)}
                                            </div>
                                        )
                                    }

                                })}
                            </div>
                            <div className='flex justify-content-end dialog-form-md-group-Btn my-2'>
                                <Button type="button" className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3" label='Cancel' style={{ height: "40px" }} onClick={() => setSidebarVisible(false)} ></Button>
                                <Button type='button' className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Submit' style={{ height: "40px" }} onClick={saveForm}  ></Button>
                            </div>
                        </div>
                    </div>

                    {/* <div className=' input-sidebar-card mt-4 px-4 py-4'>
                        <div className='grid px-3'>
                            {columns.map(col => {
                                return (
                                    <>

                                        <div className='col-6'>
                                            {renderFormField(col)}
                                        </div>

                                    </>
                                )

                            })}
                        </div>
                        <div className='flex justify-content-end dialog-form-md-group-Btn my-5'>
                            <Button type="button" className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3" label='Cancel' style={{ height: "40px" }} onClick={() => setSidebarVisible(false)} ></Button>
                            <Button type='button' className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Submit' style={{ height: "40px" }} onClick={saveForm}  ></Button>
                        </div>

                    </div> */}
                </form>
            </Sidebar>
        </div>
    );
};

export default RolesMaster;