import React, { useContext, useEffect, useState } from 'react';
import authContext from '../../../common/authContext';
import { useNavigate } from 'react-router-dom';

function OrderOverview({ items }) {
  const { filterOrderStatusId, setFilterStatusId } = useContext(authContext)
  const navigate = useNavigate()

  const [superAdminId,setSuperAdminId]=useState(null)

  const user = JSON.parse(localStorage.getItem('user'));

  function checkIdsExist(arr, idsToCheck) {
    const foundIds = idsToCheck.filter(idToCheck => arr.some(item => item.id === idToCheck));
    return foundIds.length > 0 ? foundIds : null;
}

useEffect(() => {

    if (user) {
        if (user.companyRoles !== undefined) {
            if (user.companyRoles.length > 0) {
                if (user.companyRoles[0].roles.length > 0) {
                    const id = checkIdsExist(user.companyRoles[0].roles, [4]);
                    console.log(id)
                    setSuperAdminId(id)
                }
            }
        }
    }
}, [])

  const handleOrderWithStatus = (data,value) => {
    console.log(data)
    
    if (value>0) {
      setFilterStatusId(data)
      if (superAdminId) {
        navigate("/order/accountmanager")
      }
      else{
        navigate("/order")
      } 
    }
  }

  return (
    <div className="flex flex-col mt-6 w-full">
      <h3 className="text-base text-gray-900">Order Overview</h3>
      {items.filter((val)=>val.value !== "0").map((item, index) => (
        <button key={index} className={`flex gap-6 items-start p-3 mt-3 w-full text-base bg-white rounded-lg border border-gray-200 border-solid shadow max-md:px-5 hover:shadow-lg transform ${item.value>0?"hover:scale-105 transition-transform duration-300":""}`} onClick={() => handleOrderWithStatus(item.id,item.value)}>
          <div className="flex flex-col flex-1 shrink justify-center w-full basis-0 min-w-[240px]">
            <div className="flex gap-4 items-center w-full">
              <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0 min-w-[240px]">
                <div className="leading-10 text-start text-slate-700">{item.title}</div>
                {item.subtext && (
                  <div className="leading-7 text-gray-500 text-start">
                    <span className="text-3xl text-blue-700">{item.value}</span> {item.subtext}
                  </div>
                )}
                {!item.subtext && (
                  <div className="text-3xl leading-none text-blue-700 text-start">{item.value}</div>
                )}
              </div>

              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M7.5 15L12.5 10L7.5 5" stroke="#004EEC" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </button>
      ))}
    </div>
  );
}

export default OrderOverview;