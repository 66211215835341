import React, { useContext, useEffect, useState } from 'react';
import Logo from '../components/Website/Logo';
import pic1 from "../Assets/Images/Test Joint Logo 5 (1).png"
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Avatar } from 'primereact/avatar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import logoutService from '../services/logoutService';
import ContactService from '../services/ContactService';
import authContext from './authContext';
import companyMasterService from '../services/companyMasterService';
import { Dropdown } from 'primereact/dropdown';
import UserServices from '../services/userService';
import MasterServices from '../services/coreServices';


const Headers = () => {
  const overlayPanel = useRef(null);
  const history = useNavigate();

  const { globalCompany, setGlobalCompany, loginUserName, setLoginUserName,isLoading,fileBase64,setFileBase64, setIsLoading,clearData } = useContext(authContext);

  const [data, setData] = useState(null)

  const clientName = "John Doe";
  const roles = ["Admin", "User"];

  const [products, setProducts] = useState([])

  const [userName, setUserName] = useState("")

  const [email, setEmail] = useState("")

  const user = JSON.parse(localStorage.getItem('user'));

  const [allCompany, setAllCompany] = useState([])

  const [defaultCompany, setDefaultCompany] = useState(null)

  const [IsEmployee, setIsEmployee] = useState(null)

  const [filesBase64,setFilesBase64]=useState(null)

  console.log(user)

  const [superAdminId, setSuperAdminId] = useState(null)

  function checkIdsExist(arr, idsToCheck) {
    // Check if any of the idsToCheck are found in arr
    const foundIds = idsToCheck.filter(idToCheck => arr.some(item => item.id === idToCheck));
    return foundIds.length > 0 ? foundIds : null;
  }

  console.log(filesBase64)

  useEffect(() => {

    if (user) {
      if (user.companyRoles !== undefined) {
        if (user.companyRoles.length > 0) {
          if (user.companyRoles[0].roles.length > 0) {
            const id = checkIdsExist(user.companyRoles[0].roles, [1, 4]);
            const empId = checkIdsExist(user.companyRoles[0].roles, [4]);
            console.log(id)
            setSuperAdminId(id)
            if (empId) {
              setIsEmployee(empId)
            }
          }
        }
      }
    }
  }, [])


  useEffect(() => {
    ContactService.getAllContact()
      .then((res) => {
        console.log(res)
        let data = res.data
        console.log(data)
        console.log(user)
        setProducts(res.data)
        const tempdata = data.filter((val) => val.contactDetails.emailId === user.username)
        console.log(tempdata)
        if (tempdata.length > 0) {
          if (tempdata[0].contactDetails !== undefined) {
            setUserName(`${tempdata[0].contactDetails.firstName} ${tempdata[0].contactDetails.lastName}`)
            setLoginUserName(`${tempdata[0].contactDetails.firstName} ${tempdata[0].contactDetails.lastName}`)
            setEmail(tempdata[0].contactDetails.emailId)
            setData(tempdata[0])
            if (tempdata[0].contactDetails.avatarImgPath) {
              MasterServices.getFile(tempdata[0].contactDetails.avatarImgPath)
              .then((res) => {
                console.log(res)
                setFilesBase64(res.data)
                setFileBase64(res.data)
                localStorage.setItem("avatar",res.data)
              })
              .catch((err) => {
                console.log(err)
              })
            }
            else{
              setFilesBase64(null)
              setFileBase64(null)
            }
           
          }

        }


      })
      .catch((err) => {
        console.log(err)
      })

    if (user) {
      setAllCompany(user.companyRoles)
      console.log(user.companyRoles)
      if (Array.isArray(user.companyRoles)) {
        if (user.companyRoles.length > 0) {
          console.log(user.companyRoles)
          setGlobalCompany(user.companyRoles[0])
        }
      }
    }

    if (IsEmployee && user) {
      setFilesBase64(null)
      setFileBase64(null)
      UserServices.getEmployeeByUserId(user.id)
        .then((res) => {
          console.log(res)
          if (res.data !== "") {
            if (res.data.firstName !== undefined && res.data.lastName !== undefined) {
              setLoginUserName(res.data.firstName + " " + res.data.lastName)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    console.log(defaultCompany)

  }, [IsEmployee,isLoading])


  const getInitials = (name) => {
    const nameArray = name.trim().split(" ");
    if (nameArray.length >= 2) {
      const firstNameInitial = nameArray[0].charAt(0).toUpperCase();
      const lastNameInitial = nameArray[1].charAt(0).toUpperCase();
      return firstNameInitial + lastNameInitial;
    } else {

      const initial = nameArray[0].charAt(0).toUpperCase();
      return initial + initial;
    }
  };

  function checkIdExists(arr, idToCheck) {
    const result = arr.find(item => item.id === idToCheck);
    return result ? result.id : null;
  }

  const handleLogout = () => {
    logoutService.logout()
      .then((res) => {
        console.log(res)
        localStorage.clear()
        history('/signin'); // Redirect to login page after logout

      })
      .catch((err) => {
        console.log(err)
      })
  };

  console.log(filesBase64)
  return (
    <header className="flex flex-col  self-stretch w-full  bg-white bg-opacity-0  max-md:max-w-full scroll-x" >

      <div className="flex  items-center  py-3 w-full webapp-content-header text-sm capitalize bg-white max-md:px-5 max-md:max-w-full" style={{ justifyContent: "space-between", backdropFilter: "blur(10px)", position: "fixed", top: '0', right: "0", left: "0", zIndex: "1000" }}>
        <div className="flex gap-5 pr-1 ml-3 max-w-full w-[1150px] max-md:flex-wrap" >
          <img src={pic1} alt="img" style={{ height: "58px" }} />
        </div>
        <div className='text-header-container1 pr-7'>
          <div className='action-header-container'>

            {
              globalCompany && !superAdminId ? <span className=" mr-3">
                <div class="relative">
                  <Dropdown value={globalCompany} onChange={(e) => setGlobalCompany(e.value)} options={allCompany} optionLabel="companyDetails.companyName" placeholder="Select a company" style={{ height: "40px", border: "none", fontFamily: "Inter, sans-serif", fontWeight: 500, color: "#004EEC" }}
                    className="w-full  custom-dropdown" panelClassName='custom-dropdown-panel'
                  />

                </div>

              </span> : ""
            }



            {/* <Button icon="pi pi-cog" className='header-button' ></Button> */}
            {/* <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '20px', marginTop: '10px' }}>
              <Badge value="2"></Badge>
            </i> */}
          </div>
          <div className='avatar-header-container'>
            {filesBase64 ? (
              <img
                src={`data:image/jpeg;base64,${filesBase64}`}
                alt={userName}
                className="custom-avatar-img"
                style={{ borderRadius: '50%', width: '40px', height: '40px' }}
                onClick={(e) => overlayPanel.current.toggle(e)}
              />
            ) : (
              <Avatar
                icon={!data ? "pi pi-user" : ""}
                label={data !== null && data.contactDetails !== undefined ? getInitials(userName) : ""}
                className='custom-avatar'
                onClick={(e) => overlayPanel.current.toggle(e)}
                shape="circle"
              />
            )}

          </div>


        </div>
      </div>
      <OverlayPanel ref={overlayPanel} dismissable>
        {/* <div className="user-menu">

          <div className="user-menu-item flex justify-content-center">
            <Button label="Logout" className="vstate-reverse-button" onClick={handleLogout} />
          </div>


        </div> */}
        <div className='overlay-panel-header'>
          <div className='flex gap-2'>

            <div className='avatar-header-container align-items-center'>
              <Avatar
                icon={!filesBase64 && !data ? "pi pi-user" : ""}
                image={`data:image/jpeg;base64,${filesBase64}`}
                label={!filesBase64 && data !== null && data.contactDetails !== undefined ? getInitials(userName) : ""}
                className='custom-avatar'
                onClick={(e) => overlayPanel.current.toggle(e)}
                shape="circle"
              />            </div>
            <div className=''>
              <div className='overlay-panel-header-text1'>
                {userName}
              </div>
              <div className='overlay-panel-header-text2'>
                {email}
              </div>
            </div>
          </div>
        </div>

        <div className='card-devider'></div>
        <div className='p-0'>
          <ul>
            <li className='profile-content-list-button px-3 py-2' onClick={(e) => {
              history("/profile")
              overlayPanel.current.toggle(e)
            }}><button> View Profile</button></li>
            <li className='profile-content-list-button px-3 py-2' onClick={handleLogout}><button>Logout</button></li>
          </ul>
        </div>
      </OverlayPanel>
    </header>
  );
};

export default Headers;