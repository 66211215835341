import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import OrderServices from "../../services/orderServices";
import MasterServices from "../../services/coreServices";
import authContext from "../../common/authContext";
import { useNavigate } from "react-router-dom";
import { formatISODate } from "../../utils/dateUtils";
import { TabPanel, TabView } from "primereact/tabview";
import { Col, Row } from "react-bootstrap";

const CompanySubscriptions = ({ data }) => {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh,
    redirectedData,
    setRedirectedData,
  } = useContext(authContext);

  const [products, setProducts] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      OrderServices.getClientSubscriptionByCompanyId(data.id)
        .then((res) => {
          console.log(res);
          if (Array.isArray(res.data)) {
            console.log(res.data);
            const subscriptionData = res.data.filter(
              (val) => val.isSubscription === true
            );
            setProducts(subscriptionData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [data]);

  return (
    <div>
      <div className="grid p-4">
        <div className="col-12">
          <TabView>
            <TabPanel header="Your current subscriptions">
              <Row>
                {products.length > 0 ? (
                  products.map((val, index) => (
                    <Col
                      key={index}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      className="mb-3"
                    >
                      <div className="card p-3 shadow-sm border rounded">
                        <div
                          className="wizard-card-checkbox-text1  font-fam-for-all text-start"
                          style={{
                            display: "inline-block",
                            background: "#E3F2FD",
                            padding: "8px",
                            color: "#667085",
                            fontSize: "13px",
                          }}
                        >
                          <span className="font-semibold"></span>
                          {val.productName}
                        </div>

                        <div className="srch-cand-text pt-2 text-start">
                          <span className="font-semibold">Entity Name:</span>{" "}
                          {val.entityName}
                        </div>

                        <div className="srch-cand-text pt-2 text-start">
                          <span className="font-semibold">State :</span>
                          {val.stateName}
                        </div>
                        
                        <div className="srch-cand-text pt-2 text-start">
                          <span className="font-semibold">Order Date :</span>
                          {formatISODate(val.orderDate)}
                        </div>
                        <div className="srch-cand-text pt-2 text-start">
                          <span className="font-semibold">Expiry Date :</span>
                          {formatISODate(val.expiryDate)}
                        </div>
                      </div>
                    </Col>
                  ))
                ) : (
                  <Col xs={12} className="text-center mt-3">
                    <p>No subscriptions found</p>
                  </Col>
                )}
              </Row>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
  );
};

export default CompanySubscriptions;
