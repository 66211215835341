import { Avatar } from 'primereact/avatar'
import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { TabPanel, TabView } from 'primereact/tabview'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SideBarMenu from '../common/Sidebar'
import { InputText } from 'primereact/inputtext'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import { RadioButton } from 'primereact/radiobutton'
import UserServices from '../services/userService'


const Client = () => {
    const [user] = useSearchParams()
    const history = useNavigate()

    const [isShowContent, setIsShowContent] = useState(false)

    const [isShowContent1, setIsShowContent1] = useState(false)

    const [isShowContent2, setIsShowContent2] = useState(false)

    const [isShowContent3, setIsShowContent3] = useState(false)

    const [salutation, setSalutation] = useState("")

    const [firstName, setFirstName] = useState("")

    const [middleName, setMiddleName] = useState("")

    const [lastName, setLastName] = useState("")

    const [phoneNumber, setPhoneNumber] = useState("")

    const [altPhoneNumber, setAltPhoneNumber] = useState("")

    const [email, setEmail] = useState("")

    const [altEmail, setAltEmail] = useState("")

    const [fax, setFax] = useState("")

    const [altFax, setAltFax] = useState("")

    const [addr1, setAddr1] = useState("")

    const [addr2, setAddr2] = useState("")

    const [addr3, setAddr3] = useState("")

    const [region, setRegion] = useState("")

    const [city, setCity] = useState("")

    const [states, setStates] = useState("")

    const [zip, setZip] = useState("")

    console.log(user.get("username"))
    console.log(user)

    const params = Object.fromEntries([...user]);

    console.log(params)

    useEffect(() => {
        setSalutation(params.salution)
        setFirstName(params.firstName)
        setLastName(params.lastName)
        setPhoneNumber(params.mobileNo)
        setEmail(params.emailId)
    }, [])


    // const [data, setData] = useState([
    //     { key: 'Name', value: 'John Doe' },
    //     { key: 'Email', value: 'john.doe@example.com' },
    //     { key: 'Phone', value: '123-456-7890' },
    //     { key: 'Fax', value: '' },
    //     { key: 'Address Line 1', value: 'wagholi' },
    //     { key: 'Address Line 2', value: 'wagholi' },
    //     { key: 'Address Line 3', value: 'wagholi' },
    //     { key: 'City', value: 'Pune' },
    //     { key: 'State', value: 'Maharashtra' },
    //     { key: 'Country', value: 'India' },
    // ]);

    const [selectedCountry, setSelectedCountry] = useState(null);
    const countries = [
        { name: 'Australia', code: 'AU' },
        { name: 'Brazil', code: 'BR' },
        { name: 'China', code: 'CN' },
        { name: 'Egypt', code: 'EG' },
        { name: 'France', code: 'FR' },
        { name: 'Germany', code: 'DE' },
        { name: 'India', code: 'IN' },
        { name: 'Japan', code: 'JP' },
        { name: 'Spain', code: 'ES' },
        { name: 'United States', code: 'US' }
    ];

    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    const panelFooterTemplate = () => {
        return (
            <div className="py-2 px-3">
                {selectedCountry ? (
                    <span>
                        <b>{selectedCountry.name}</b> selected.
                    </span>
                ) : (
                    'No country selected.'
                )}
            </div>
        );
    };

    const [data, setData] = useState([
        {
            name: "John Doe",
            email: "john.doe@example.com",
            phone: "3838383838383838",
            fax: "",
            Address1: "wagholi",
            Address2: "Hell",
            Address3: "Golden City",
            city: "Pune",
            state: "Maharashtra",
            country: "India"
        }
    ])



    const [isEditing, setIsEditing] = useState(false);
    const [isEditing1, setIsEditing1] = useState(false);
    const [isEditing2, setIsEditing2] = useState(false);
    const [editValues, setEditValues] = useState(
        data.reduce((acc, item) => {
            acc[item.key] = item.value;
            return acc;
        }, {})
    );

    // useEffect(() => {
    //     const params = {};
    //     user.forEach((value, key) => {
    //         params[key] = value;
    //     });

    //     console.log(params)

    //     const temp = Object.keys(params).map((key, i) => {
    //         return {
    //             "key": key,
    //             "value": params[key]
    //         }
    //     })
    //     console.log(temp)

    //     const updatedData = data.map(item => {
    //         if (params[item.key]) {
    //             return { ...item, value: params[item.key] };
    //         }
    //         return item;
    //     });

    //     console.log(updatedData)

    //     const temp1 = temp.reduce((acc, item) => {
    //         acc[item.key] = item.value;
    //         return acc;
    //     }, {})

    //     setData(temp);
    //     setEditValues(temp1)
    // }, [user]);





    console.log(editValues)

    const handleEdit = () => {
        setIsEditing1(true);
    };

    const handleEdit1 = () => {
        setIsEditing(true);
    };

    const handleEdit2 = () => {
        setIsEditing2(true);
    };

    const handleChange = (key, value) => {
        setEditValues({ ...editValues, [key]: value });
    };
    // const handleSave = () => {
    //     const newData = data.map((item) => ({
    //         ...item,
    //         value: editValues[item.key],
    //     }));
    //     setData(newData);
    //     setIsEditing(false);
    // };

    const handleSave = () => {
        const actualdata = params
        actualdata["firstName"]=firstName
        actualdata["lastName"]=lastName
        console.log(actualdata)
        UserServices.updateUserDetails(actualdata,actualdata.id)
        .then((res)=>{
            console.log(res)
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    const handleToggle = () => {
        setIsShowContent(!isShowContent)
    }

    const handleToggle1 = () => {
        setIsShowContent1(!isShowContent1)
    }

    const handleToggle2 = () => {
        setIsShowContent2(!isShowContent2)
    }

    const handleToggle3 = () => {
        setIsShowContent3(!isShowContent3)
    }


    return (
        <div>
            <div>
                <div className='grid'>

                    <div className=' card' style={{ backgroundColor: '#ffff' }}>
                        <div className='dashboard-container font-fam-for-all'>
                            <div className='dashboard-main'>
                                <div className='dashboard-data-header' style={{ marginBottom: "10px", marginTop: "10px" }}>
                                    <div className='dashboard-data-sub-header' style={{ paddingLeft: "0px", paddingRight: "15px" }}>
                                        <div className='sub-header-css1'>
                                            <div className='header-content'>
                                                <div className='text-header-container'>
                                                    <button onClick={() => history("/contact")}>Back</button>

                                                    <div className='text-header1'>
                                                        <div className='flex gap-2 align-items-center' style={{ justifyContent: "space-between" }}>
                                                            <div>
                                                                Alex
                                                            </div>
                                                            <div className='header-badge'>
                                                                <div className='header-badge-main'>
                                                                    <div className='header-badge-text'>
                                                                        Account Admin
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className='text-header2'>Manage Your Users Here</div>
                                                </div>
                                                <div className='text-header-container1'>
                                                    <div className='action-header-container'>
                                                        <Button icon="pi pi-cog" className='header-button'></Button>
                                                        {/* <Button icon="pi pi-bell" className='header-button1'></Button> */}
                                                        <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '20px', marginTop: '10px' }}>
                                                            <Badge value="2"></Badge>
                                                        </i>
                                                    </div>
                                                    <div className='avatar-header-container'>
                                                        <Avatar icon="pi pi-user" style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} shape="circle" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <TabView className='user-tabview'>
                                    <TabPanel header="Contact Information">

                                        <div className="font-fam-for-all">
                                           
                                            <div>
                                                {
                                                    isEditing ? <div className='card my-3 slide-in'>
                                                        <div className='p-4'>
                                                            <h2 style={{ fontWeight: "500", fontSize: "18px" }}>Contact Info</h2>
                                                            <div style={{ fontSize: "14px" }}>
                                                                Update your photo and personal details here.
                                                            </div>
                                                        </div>

                                                        <div className="grid px-4 py-2">

                                                            <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>

                                                                    <span className="p-float-label">
                                                                        <Dropdown
                                                                            value={salutation}
                                                                            options={["Mr", "Ms", "Mrs", "Dr"]}
                                                                            onChange={(e) => setSalutation(e.target.value)}
                                                                            // optionLabel="code"


                                                                            style={{ width: '300px' }}
                                                                            className="p-dropdown font-fam-for-all mr-3"
                                                                        />
                                                                        <label htmlFor="input" className="form-label font-fam-for-all">
                                                                            Salutation <span className="form-field-mandatory">*</span>
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                <span className="p-float-label mr-3">
                                                                    <InputText
                                                                        style={{ height: '40px', width: "300px" }}
                                                                        maxLength={50}
                                                                        value={firstName}
                                                                        onChange={(e) => setFirstName(e.target.value)}
                                                                        name="cityName"
                                                                    />
                                                                    <label htmlFor="input" className="form-label font-fam-for-all">
                                                                        First Name <span className="form-field-mandatory">*</span>
                                                                    </label>
                                                                </span>
                                                            </div>
                                                            <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                <span className="p-float-label mr-3">
                                                                    <InputText
                                                                        style={{ height: '40px', width: "300px" }}
                                                                        maxLength={50}
                                                                        value={middleName}
                                                                        onChange={(e) => setMiddleName(e.target.value)}
                                                                        name="cityName"
                                                                    />
                                                                    <label htmlFor="input" className="form-label font-fam-for-all">
                                                                        Middle Name <span className="form-field-mandatory">*</span>
                                                                    </label>
                                                                </span>
                                                            </div>
                                                            <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                <span className="p-float-label">
                                                                    <InputText
                                                                        style={{ height: '40px', width: "300px" }}
                                                                        maxLength={50}
                                                                        value={lastName}
                                                                        onChange={(e) => setLastName(e.target.value)}
                                                                        name="cityName"
                                                                    />
                                                                    <label htmlFor="input" className="form-label font-fam-for-all">
                                                                        Last Name <span className="form-field-mandatory">*</span>
                                                                    </label>
                                                                </span>
                                                            </div>



                                                        </div>
                                                        <div className="grid px-4 py-2">

                                                            <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>
                                                                    <Dropdown
                                                                        value={selectedCountry}
                                                                        options={countries}
                                                                        onChange={(e) => setSelectedCountry(e.value)}
                                                                        optionLabel="code"
                                                                        optionValue="dialCode"

                                                                        style={{ width: '80px', borderRadius: '6px 0px 0px 6px' }}
                                                                        className="p-dropdown font-fam-for-all"
                                                                    />
                                                                    <span className="p-float-label">
                                                                        <InputText
                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                            maxLength={50}
                                                                            value={phoneNumber}
                                                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                                                            name="cityName"
                                                                        />
                                                                        <label htmlFor="input" className="form-label font-fam-for-all">
                                                                            Phone <span className="form-field-mandatory">*</span>
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>
                                                                    <Dropdown
                                                                        value={selectedCountry}
                                                                        options={countries}
                                                                        onChange={(e) => setSelectedCountry(e.value)}
                                                                        optionLabel="code"
                                                                        optionValue="dialCode"

                                                                        style={{ width: '80px', borderRadius: '6px 0px 0px 6px' }}
                                                                        className="p-dropdown font-fam-for-all"
                                                                    />
                                                                    <span className="p-float-label">
                                                                        <InputText
                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                            maxLength={50}
                                                                            value={altPhoneNumber}

                                                                            onChange={(e) => {
                                                                                setAltPhoneNumber(e.value)
                                                                            }}
                                                                            name="cityName"
                                                                        />
                                                                        <label htmlFor="input" className="form-label font-fam-for-all">
                                                                            Alt Phone <span className="form-field-mandatory">*</span>
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>

                                                                    <span className="p-float-label">
                                                                        <InputText
                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                            maxLength={50}
                                                                            value={email}
                                                                            onChange={(e) => setEmail(e.target.value)}
                                                                            name="cityName"
                                                                        />
                                                                        <label htmlFor="input" className="form-label font-fam-for-all">
                                                                            Email <span className="form-field-mandatory">*</span>
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>

                                                                    <span className="p-float-label">
                                                                        <InputText
                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                            maxLength={50}
                                                                            value={altEmail}
                                                                            onChange={(e) => setAltEmail(e.target.value)}
                                                                            name="cityName"
                                                                        />
                                                                        <label htmlFor="input" className="form-label font-fam-for-all">
                                                                            Alt  Email <span className="form-field-mandatory">*</span>
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                            </div>



                                                        </div>

                                                        <div className="grid px-4 py-2">

                                                            <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>

                                                                    <span className="p-float-label">
                                                                        <InputText
                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                            maxLength={50}
                                                                            value={fax}
                                                                            onChange={(e) => setFax(e.target.value)}
                                                                            name="cityName"
                                                                        />
                                                                        <label htmlFor="input" className="form-label font-fam-for-all">
                                                                            Fax <span className="form-field-mandatory">*</span>
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>

                                                                    <span className="p-float-label">
                                                                        <InputText
                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                            maxLength={50}
                                                                            value={altFax}
                                                                            onChange={(e) => setAltFax(e.target.value)}
                                                                            name="cityName"
                                                                        />
                                                                        <label htmlFor="input" className="form-label font-fam-for-all">
                                                                            Alt Fax <span className="form-field-mandatory">*</span>
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className='col-3'>

                                                            </div>
                                                            <div className='col-3'>

                                                            </div>

                                                        </div>


                                                        <div className="mb-3">
                                                            {isEditing || isEditing1 || isEditing2 ? (
                                                                <div className='flex justify-content-end pl-3 dialog-form-md-group-Btn' >
                                                                    <Button className="mt-2  font-fam-for-all text-center text-lg bg-primary-reverse mr-2" style={{ height: "40px" }} onClick={() => {
                                                                        setIsEditing(false)
                                                                        setIsEditing1(false)
                                                                        setIsEditing2(false)
                                                                    }}  >Cancel</Button>
                                                                    <Button className="mt-2  font-fam-for-all text-center text-lg mr-3" style={{ height: "40px" }} onClick={handleSave} >Submit</Button>
                                                                </div>
                                                            ) : (
                                                                <div></div>
                                                            )}
                                                        </div>


                                                    </div> : <>
                                                        <div>
                                                            <div className='card my-3 hover-card slide-in'>


                                                                <div className=' flex px-4 pt-4 pb-0' style={{ justifyContent: 'space-between' }}>
                                                                    <h2 className='text-lg ' style={{ fontWeight: "500" }}>Contact Info</h2>
                                                                    <div >
                                                                        {isEditing ? (
                                                                            <div>

                                                                            </div>
                                                                        ) : (
                                                                            <div className='flex justify-content-end dialog-form-md-group-Btn'>
                                                                                <Button onClick={handleEdit1} className="edit-button" icon="pi pi-pencil" style={{ border: 'none', height: "40px" }}></Button>

                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="grid px-4 py-2">

                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>Salutation</div>
                                                                    <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        First Name
                                                                    </div>
                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        Middle Name
                                                                    </div>
                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        Last Name
                                                                    </div>


                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                        {params.salution}
                                                                    </div>
                                                                    <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                        {params.firstName}
                                                                    </div>
                                                                    <div className='col-3' style={{ fontSize: "14px" }}>

                                                                    </div>
                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                        {params.lastName}
                                                                    </div>

                                                                </div>
                                                                <div className="grid px-4 py-2">

                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        Phone Number
                                                                    </div>
                                                                    <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        Alt Number
                                                                    </div>
                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        Email
                                                                    </div>
                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        Alt Email
                                                                    </div>


                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                        {params.mobileNo}
                                                                    </div>
                                                                    <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                        8383838383838
                                                                    </div>
                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                        {params.emailId}
                                                                    </div>
                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                        Patra@gmail.com
                                                                    </div>

                                                                </div>

                                                                <div className="grid px-4 py-2">

                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        Fax
                                                                    </div>
                                                                    <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        Alt Fax
                                                                    </div>
                                                                    <div className='col-3'>

                                                                    </div>
                                                                    <div className='col-3'>

                                                                    </div>



                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                        774747fhfhfhhf
                                                                    </div>
                                                                    <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                        8383dhdhdhdh
                                                                    </div>
                                                                    <div className='col-3'>

                                                                    </div>
                                                                    <div className='col-3'>

                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {
                                                    isEditing1 ? <>




                                                        <div className='card my-3 hover-card slide-in1'>


                                                            <div className='p-4'>
                                                                <h2 style={{ fontWeight: "500", fontSize: "18px" }}>Home Address</h2>
                                                                <div style={{ fontSize: "14px" }}>
                                                                    Update your photo and personal details here.
                                                                </div>
                                                            </div>

                                                            <div className="grid px-4 py-2">

                                                                <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>

                                                                        <span className="p-float-label">
                                                                            <Dropdown value={selectedCountry} onChange={(e) => setSelectedCountry(e.value)} options={countries} optionLabel="name" placeholder="Select a Country"
                                                                                valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} className="w-full md:w-14rem" panelFooterTemplate={panelFooterTemplate}
                                                                            // dropdownIcon={(opts) => {
                                                                            //     return opts.iconProps['data-pr-overlay-visible'] ? <ChevronRightIcon {...opts.iconProps} /> : <ChevronDownIcon {...opts.iconProps} />;
                                                                            // }}
                                                                            />
                                                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                                                Country <span className="form-field-mandatory">*</span>
                                                                            </label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>

                                                                        <span className="p-float-label">
                                                                            <InputText
                                                                                style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                                maxLength={50}
                                                                                value={addr1}
                                                                                onChange={(e) => setAddr1(e.target.value)}
                                                                                name="cityName"
                                                                            />
                                                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                                                Address Line 1 <span className="form-field-mandatory">*</span>
                                                                            </label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>

                                                                        <span className="p-float-label">
                                                                            <InputText
                                                                                style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                                maxLength={50}
                                                                                value={addr2}
                                                                                onChange={(e) => setAddr2(e.target.value)}
                                                                                name="cityName"
                                                                            />
                                                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                                                Address Line 2 <span className="form-field-mandatory">*</span>
                                                                            </label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>

                                                                        <span className="p-float-label">
                                                                            <InputText
                                                                                style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                                maxLength={50}
                                                                                value={addr3}
                                                                                onChange={(e) => setAddr3(e.target.value)}
                                                                                name="cityName"
                                                                            />
                                                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                                                Address Line 3 <span className="form-field-mandatory">*</span>
                                                                            </label>
                                                                        </span>
                                                                    </div>
                                                                </div>




                                                            </div>
                                                            <div className="grid px-4 py-2">

                                                                <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>

                                                                        <span className="p-float-label">
                                                                            <InputText
                                                                                style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                                maxLength={50}
                                                                                value={region}
                                                                                onChange={(e) => setRegion(e.target.value)}
                                                                                name="cityName"
                                                                            />
                                                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                                                Region <span className="form-field-mandatory">*</span>
                                                                            </label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>

                                                                        <span className="p-float-label">
                                                                            <InputText
                                                                                style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                                maxLength={50}
                                                                                value={city}
                                                                                onChange={(e) => setCity(e.target.value)}
                                                                                name="cityName"
                                                                            />
                                                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                                                City <span className="form-field-mandatory">*</span>
                                                                            </label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>

                                                                        <span className="p-float-label">
                                                                            <InputText
                                                                                style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                                maxLength={50}
                                                                                value={states}
                                                                                onChange={(e) => setStates(e.target.value)}
                                                                                name="cityName"
                                                                            />
                                                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                                                State <span className="form-field-mandatory">*</span>
                                                                            </label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    <div className="p-inputgroup flex-1" style={{ height: '40px', width: '300px' }}>

                                                                        <span className="p-float-label">
                                                                            <InputText
                                                                                style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                                maxLength={50}
                                                                                value={zip}
                                                                                onChange={(e) => setZip(e.target.value)}
                                                                                name="cityName"
                                                                            />
                                                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                                                Zip Code <span className="form-field-mandatory">*</span>
                                                                            </label>
                                                                        </span>
                                                                    </div>
                                                                </div>




                                                            </div>

                                                            <div className="mb-3">
                                                                {isEditing || isEditing1 || isEditing2 ? (
                                                                    <div className='flex justify-content-end pl-3 dialog-form-md-group-Btn' >
                                                                        <Button className="mt-2  font-fam-for-all text-center text-lg bg-primary-reverse mr-2" style={{ height: "40px" }} onClick={() => {
                                                                            setIsEditing(false)
                                                                            setIsEditing1(false)
                                                                            setIsEditing2(false)
                                                                        }}  >Cancel</Button>
                                                                        <Button className="mt-2  font-fam-for-all text-center text-lg mr-3" style={{ height: "40px" }} onClick={handleSave} >Submit</Button>
                                                                    </div>
                                                                ) : (
                                                                    <div></div>
                                                                )}
                                                            </div>

                                                        </div>


                                                    </> : <>

                                                        <div className='card my-3 hover-card slide-in'>


                                                            <div className=' flex px-4 pt-4 pb-0' style={{ justifyContent: 'space-between' }}>
                                                                <h2 className='text-lg ' style={{ fontWeight: "500" }}>Home Address</h2>
                                                                <div>
                                                                    {isEditing ? (
                                                                        <div>

                                                                        </div>
                                                                    ) : (
                                                                        <div className='flex justify-content-end dialog-form-md-group-Btn'>
                                                                            <Button onClick={handleEdit} className='edit-button1' icon="pi pi-pencil" style={{ border: 'none', height: "40px" }}></Button>

                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="grid px-4 py-2">

                                                                <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    Country
                                                                </div>
                                                                <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    Address Line 1
                                                                </div>
                                                                <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    Address Line 2
                                                                </div>
                                                                <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    Address Line 3
                                                                </div>


                                                                <div className='col-3' style={{ fontSize: "14px" }}>Mr.</div>
                                                                <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                    Saumya
                                                                </div>
                                                                <div className='col-3' style={{ fontSize: "14px" }}>
                                                                    Ranjan
                                                                </div>
                                                                <div className='col-3' style={{ fontSize: "14px" }}>
                                                                    Patra
                                                                </div>

                                                            </div>
                                                            <div className="grid px-4 py-2">

                                                                <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    Region
                                                                </div>
                                                                <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    City
                                                                </div>
                                                                <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    State
                                                                </div>
                                                                <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                    Zip Code
                                                                </div>


                                                                <div className='col-3' style={{ fontSize: "14px" }}>
                                                                    77474774747474747
                                                                </div>
                                                                <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                    8383838383838
                                                                </div>
                                                                <div className='col-3' style={{ fontSize: "14px" }}>
                                                                    saumya@gmail.com
                                                                </div>
                                                                <div className='col-3' style={{ fontSize: "14px" }}>
                                                                    Patra@gmail.com
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </>
                                                }

                                                {
                                                    isEditing2 ? <div className='slide-in2'>

                                                        <div className='p-4'>
                                                            <h2 style={{ fontWeight: "500", fontSize: "18px" }}>User Access</h2>
                                                            <div style={{ fontSize: "14px" }}>
                                                                What level of access does this individual receive?                                                            </div>
                                                        </div>

                                                        <div className='px-4'>
                                                            <div className="flex align-items-center">
                                                                <RadioButton inputId="ingredient1" name="pizza" value="Cheese" />
                                                                <label htmlFor="ingredient1" className="ml-2 mb-0" style={{ fontWeight: "500", fontSize: "14px" }}>Admin User</label>
                                                            </div>
                                                            <p className='ml-4' style={{ fontSize: "14px" }}>Admin users automatically have permissions to any company you add and the ability to add or remove individuals.</p>

                                                        </div>

                                                        <div className='px-4 pt-4'>
                                                            <div className="flex align-items-center">
                                                                <RadioButton inputId="ingredient1" name="pizza" value="Cheese" />
                                                                <label htmlFor="ingredient1" className="ml-2 mb-0" style={{ fontWeight: "500", fontSize: "14px" }}>Regular User</label>
                                                            </div>
                                                            <p className='ml-4' style={{ fontSize: "14px" }}>Regular users automatically have permissions to any company you add.</p>

                                                        </div>

                                                        <div className='px-4 py-4'>
                                                            <div className="flex align-items-center">
                                                                <RadioButton inputId="ingredient1" name="pizza" value="Cheese" />
                                                                <label htmlFor="ingredient1" className="ml-2 mb-0" style={{ fontWeight: "500", fontSize: "14px" }}>User Type</label>
                                                            </div>
                                                            <p className='ml-4' style={{ fontSize: "14px" }}>Details for the user type.</p>

                                                        </div>

                                                        <div className="divider-x"></div>

                                                        <div className='p-4'>
                                                            <h2 style={{ fontWeight: "500", fontSize: "18px" }}>Email Notifications</h2>
                                                            <div style={{ fontSize: "14px" }}>
                                                                Manage email notifications settings here.                                                            </div>
                                                        </div>

                                                        <div className='p-4'>
                                                            <div className='card p-4'>
                                                                <div style={{ fontWeight: "500", fontSize: "18px" }}>
                                                                    Registered agent service emails
                                                                </div>
                                                                <div className=' pt-4'>
                                                                    <div className="flex align-items-center" style={{ justifyContent: "space-between" }}>
                                                                        <div>
                                                                            <Checkbox inputId="ingredient1" name="pizza" value="Cheese" />
                                                                            <label htmlFor="ingredient1" className="ml-2 mb-0" style={{ fontWeight: "500", fontSize: "14px" }}>Notify Service of Process</label>
                                                                        </div>
                                                                        <div>
                                                                            <button onClick={handleToggle} style={{ color: "#1570EF" }}>Customize by Company <i className={isShowContent ? "pi pi-angle-up" : 'pi pi-angle-down'}></i></button>
                                                                        </div>
                                                                    </div>
                                                                    <p className='ml-4 mt-1' style={{ fontSize: "14px" }}>Service of process deliveries initiate a lawsuit. They are hand-delivered and their content is time-sensitive.</p>
                                                                    {
                                                                        isShowContent ? <>
                                                                            <div className='customize-email-frame mt-3'>
                                                                                <div className='customize-header-email'>
                                                                                    <div className='customize-table-header-email'>
                                                                                        <div className='customize-table-header-email-text'>
                                                                                            Company
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div className='customize-content-email'>
                                                                                    <div className='customize-table-content-email'>
                                                                                        <div className='customize-table-content-email-text'>
                                                                                            ABC Company
                                                                                        </div>
                                                                                        <div className='flex align-items-center' style={{ gap: "12px" }}>
                                                                                            <Checkbox inputId="ingredient1" name="pizza" value="Cheese" />
                                                                                            <div className='customize-table-content-email-text1'>
                                                                                                Notify
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> : ""
                                                                    }
                                                                </div>
                                                                <div className=' pt-4'>
                                                                    <div className="flex align-items-center" style={{ justifyContent: "space-between" }}>
                                                                        <div>

                                                                            <Checkbox inputId="ingredient1" name="pizza" value="Cheese" />
                                                                            <label htmlFor="ingredient1" className="ml-2 mb-0" style={{ fontWeight: "500", fontSize: "14px" }}>Notify Registered Agent Regular Mail</label>
                                                                        </div>
                                                                        <div>
                                                                            <button onClick={handleToggle1} style={{ color: "#1570EF" }}>Customize by Company <i className={isShowContent1 ? "pi pi-angle-up" : 'pi pi-angle-down'}></i></button>
                                                                        </div>
                                                                    </div>
                                                                    <p className='ml-4 mt-1' style={{ fontSize: "14px" }}>Regular registered agent mail deliveries typically include government notices and filing notifications.</p>
                                                                    {
                                                                        isShowContent1 ? <>
                                                                            <div className='customize-email-frame mt-3'>
                                                                                <div className='customize-header-email'>
                                                                                    <div className='customize-table-header-email'>
                                                                                        <div className='customize-table-header-email-text'>
                                                                                            Company
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div className='customize-content-email'>
                                                                                    <div className='customize-table-content-email'>
                                                                                        <div className='customize-table-content-email-text'>
                                                                                            ABC Company
                                                                                        </div>
                                                                                        <div className='flex align-items-center' style={{ gap: "12px" }}>
                                                                                            <Checkbox inputId="ingredient1" name="pizza" value="Cheese" />
                                                                                            <div className='customize-table-content-email-text1'>
                                                                                                Notify
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> : ""
                                                                    }
                                                                </div>
                                                                <div className='py-4'>
                                                                    <div className="flex align-items-center">
                                                                        <Checkbox inputId="ingredient1" name="pizza" value="Cheese" />
                                                                        <label htmlFor="ingredient1" className="ml-2 mb-0" style={{ fontWeight: "500", fontSize: "14px" }}>User Type</label>
                                                                    </div>
                                                                    <p className='ml-4 mt-1' style={{ fontSize: "14px" }}>In many states, each company must file an annual report with the corporations division. We provide a reminder email as part of our registered agent service.</p>

                                                                </div>

                                                                <div className="divider-x"></div>

                                                                <div style={{ fontWeight: "500", fontSize: "18px" }} className='py-4'>
                                                                    Compliance report email frequency
                                                                </div>

                                                                <div className=''>
                                                                    <div className="flex align-items-center">
                                                                        <RadioButton inputId="ingredient1" name="pizza" value="Cheese" />
                                                                        <label htmlFor="ingredient1" className="ml-2 mb-0" style={{ fontWeight: "500", fontSize: "14px" }}>Monthly</label>
                                                                    </div>
                                                                    <p className='ml-4' style={{ fontSize: "14px" }}>Details for the user type.</p>

                                                                </div>

                                                                <div className='pt-4'>
                                                                    <div className="flex align-items-center">
                                                                        <RadioButton inputId="ingredient1" name="pizza" value="Cheese" />
                                                                        <label htmlFor="ingredient1" className="ml-2 mb-0" style={{ fontWeight: "500", fontSize: "14px" }}>Weekly</label>
                                                                    </div>
                                                                    <p className='ml-4' style={{ fontSize: "14px" }}>Details for the user type.</p>

                                                                </div>

                                                                <div className='py-4'>
                                                                    <div className="flex align-items-center">
                                                                        <RadioButton inputId="ingredient1" name="pizza" value="Cheese" />
                                                                        <label htmlFor="ingredient1" className="ml-2 mb-0" style={{ fontWeight: "500", fontSize: "14px" }}>Never</label>
                                                                    </div>
                                                                    <p className='ml-4' style={{ fontSize: "14px" }}>Details for the user type.</p>

                                                                </div>
                                                            </div>

                                                        </div>



                                                        <div className='p-4'>
                                                            <h2 style={{ fontWeight: "500", fontSize: "18px" }}>Billing Access</h2>
                                                            <div style={{ fontSize: "14px" }}>
                                                                Manage Billing access here.</div>
                                                        </div>

                                                        <div className='p-4'>
                                                            <div className='card p-4'>

                                                                <div className=' pt-2'>
                                                                    <div className="flex align-items-center" style={{ justifyContent: "space-between" }}>
                                                                        <div>
                                                                            <Checkbox inputId="ingredient1" name="pizza" value="Cheese" />
                                                                            <label htmlFor="ingredient1" className="ml-2 mb-0" style={{ fontWeight: "500", fontSize: "14px" }}>Grant access to billing</label>
                                                                        </div>
                                                                        <div>
                                                                            <button onClick={handleToggle2} style={{ color: "#1570EF" }}>Customize by Company <i className={isShowContent2 ? "pi pi-angle-up" : 'pi pi-angle-down'}></i></button>
                                                                        </div>
                                                                    </div>
                                                                    <p className='ml-4 mt-1' style={{ fontSize: "14px" }}>Users have access to view invoices of companies</p>
                                                                    {
                                                                        isShowContent2 ? <>
                                                                            <div className='customize-email-frame mt-3'>
                                                                                <div className='customize-header-email'>
                                                                                    <div className='customize-table-header-email'>
                                                                                        <div className='customize-table-header-email-text'>
                                                                                            Company
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div className='customize-content-email'>
                                                                                    <div className='customize-table-content-email'>
                                                                                        <div className='customize-table-content-email-text'>
                                                                                            ABC Company
                                                                                        </div>
                                                                                        <div className='flex align-items-center' style={{ gap: "12px" }}>
                                                                                            <Checkbox inputId="ingredient1" name="pizza" value="Cheese" />
                                                                                            <div className='customize-table-content-email-text1'>
                                                                                                Notify
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> : ""
                                                                    }
                                                                </div>
                                                                <div className="divider-x my-2"></div>
                                                                <div className=' py-2'>
                                                                    <div className="flex align-items-center" style={{ justifyContent: "space-between" }}>
                                                                        <div>
                                                                            <Checkbox inputId="ingredient1" name="pizza" value="Cheese" />
                                                                            <label htmlFor="ingredient1" className="ml-2 mb-0" style={{ fontWeight: "500", fontSize: "14px" }}>Notify Billing</label>
                                                                        </div>
                                                                        <div>
                                                                            <button onClick={handleToggle3} style={{ color: "#1570EF" }}>Customize by Company <i className={isShowContent3 ? "pi pi-angle-up" : 'pi pi-angle-down'}></i></button>
                                                                        </div>
                                                                    </div>
                                                                    <p className='ml-4 mt-1' style={{ fontSize: "14px" }}>Receive invoices and billing notifications.</p>
                                                                    {
                                                                        isShowContent3 ? <>
                                                                            <div className='customize-email-frame mt-3'>
                                                                                <div className='customize-header-email'>
                                                                                    <div className='customize-table-header-email'>
                                                                                        <div className='customize-table-header-email-text'>
                                                                                            Company
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div className='customize-content-email'>
                                                                                    <div className='customize-table-content-email'>
                                                                                        <div className='customize-table-content-email-text'>
                                                                                            ABC Company
                                                                                        </div>
                                                                                        <div className='flex align-items-center' style={{ gap: "12px" }}>
                                                                                            <Checkbox inputId="ingredient1" name="pizza" value="Cheese" />
                                                                                            <div className='customize-table-content-email-text1'>
                                                                                                Notify
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </> : ""
                                                                    }
                                                                </div>



                                                            </div>

                                                            <div className="">
                                                                {isEditing || isEditing1 || isEditing2 ? (
                                                                    <div className='flex justify-content-end pl-3 dialog-form-md-group-Btn' >
                                                                        <Button className="mt-2  font-fam-for-all text-center text-lg bg-primary-reverse mr-2" style={{ height: "40px" }} onClick={() => {
                                                                            setIsEditing(false)
                                                                            setIsEditing1(false)
                                                                            setIsEditing2(false)
                                                                        }}  >Cancel</Button>
                                                                        <Button className="mt-2  font-fam-for-all text-center text-lg mr-3" style={{ height: "40px" }} onClick={handleSave} >Submit</Button>
                                                                    </div>
                                                                ) : (
                                                                    <div></div>
                                                                )}
                                                            </div>

                                                        </div>
                                                    </div> : <div className='slide-in2'>
                                                        <div>
                                                            <div className='card my-3 hover-card'>


                                                                <div className=' flex p-4' style={{ justifyContent: 'space-between' }}>
                                                                    <h2 className='text-lg ' style={{ fontWeight: "500" }}>User Access</h2>
                                                                    <div>
                                                                        {isEditing ? (
                                                                            <div>

                                                                            </div>
                                                                        ) : (
                                                                            <div className='flex justify-content-end dialog-form-md-group-Btn'>
                                                                                <Button onClick={handleEdit2} className='edit-button2' icon="pi pi-pencil" style={{ border: 'none', height: "40px" }}></Button>

                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <div className="divider-x "></div>


                                                                <div className='p-4'>
                                                                    <h2 className='text-lg ' style={{ fontWeight: "500" }}>Billing Access</h2>
                                                                </div>

                                                                <div className="divider-x "></div>


                                                                <div className='p-4'>
                                                                    <h2 className='text-lg ' style={{ fontWeight: "500" }}>Email Settings</h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }


                                            </div>
                                           
                                            


                                        </div>


                                    </TabPanel>
                                    <TabPanel header="User Access">
                                        <div className='dashboard-data-header font-fam-for-all' style={{ marginLeft: "0px" }}>
                                            <div className='dashboard-data-sub-header' style={{ padding: "0px" }}>
                                                <div className='grid'>
                                                    <div className='col-3'>
                                                        <div style={{ fontWeight: "500" }}>
                                                            User Access
                                                        </div>
                                                        <div>
                                                            Select access level for the user
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className="wizard-card-checkbox-container mb-4">
                                                            <div className="wizard-card-checkbox-main">
                                                                <div className="wizard-card-checkbox-text-main">
                                                                    <div className="wizard-card-checkbox-text-sub-main">
                                                                        <div className="wizard-card-checkbox-text-container">
                                                                            <div className="wizard-card-checkbox-text1">
                                                                                Admin User
                                                                            </div>
                                                                            <div className="wizard-card-checkbox-text2">
                                                                                Admin users automatically have permissions to any entity you add and the ability to add or remove individuals.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="wizard-card-checkbox-action-container">
                                                                        <Checkbox
                                                                            className="mt-2"
                                                                        // checked={IsChecked2}
                                                                        // onChange={(e) =>
                                                                        //     handleChecked1(
                                                                        //         e,
                                                                        //         {
                                                                        //             service: "Certified Copies",
                                                                        //             amount: "$230",
                                                                        //         },
                                                                        //         2
                                                                        //     )
                                                                        // }
                                                                        ></Checkbox>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="wizard-card-checkbox-container mb-4">
                                                            <div className="wizard-card-checkbox-main">
                                                                <div className="wizard-card-checkbox-text-main">
                                                                    <div className="wizard-card-checkbox-text-sub-main">
                                                                        <div className="wizard-card-checkbox-text-container">
                                                                            <div className="wizard-card-checkbox-text1">
                                                                                Super Admin
                                                                            </div>
                                                                            <div className="wizard-card-checkbox-text2">
                                                                                Admin users automatically have permissions to any entity you add and the ability to add or remove individuals.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="wizard-card-checkbox-action-container">
                                                                        <Checkbox
                                                                            className="mt-2"
                                                                        // checked={IsChecked2}
                                                                        // onChange={(e) =>
                                                                        //     handleChecked1(
                                                                        //         e,
                                                                        //         {
                                                                        //             service: "Certified Copies",
                                                                        //             amount: "$230",
                                                                        //         },
                                                                        //         2
                                                                        //     )
                                                                        // }
                                                                        ></Checkbox>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="wizard-card-checkbox-container mb-4">
                                                            <div className="wizard-card-checkbox-main">
                                                                <div className="wizard-card-checkbox-text-main">
                                                                    <div className="wizard-card-checkbox-text-sub-main">
                                                                        <div className="wizard-card-checkbox-text-container">
                                                                            <div className="wizard-card-checkbox-text1">
                                                                                Client
                                                                            </div>
                                                                            <div className="wizard-card-checkbox-text2">
                                                                                Admin users automatically have permissions to any entity you add and the ability to add or remove individuals.
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="wizard-card-checkbox-action-container">
                                                                        <Checkbox
                                                                            className="mt-2"
                                                                        // checked={IsChecked2}
                                                                        // onChange={(e) =>
                                                                        //     handleChecked1(
                                                                        //         e,
                                                                        //         {
                                                                        //             service: "Certified Copies",
                                                                        //             amount: "$230",
                                                                        //         },
                                                                        //         2
                                                                        //     )
                                                                        // }
                                                                        ></Checkbox>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </TabPanel>
                                </TabView>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Client