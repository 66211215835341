import axios from "axios";
import authHeader, { initAuth } from "./authHeader";
import encryption from "./CryptoClass";
import propertiesApp from "../utils/propertiesApp";

const getDataById = (endpoint) => {
    const dataToEncrypt = {
        endpoint: endpoint,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}





const saveAll = (data,endpoint) => {
    const dataToEncrypt = {
        endpoint: endpoint,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
};

// const saveFile = (key,data) => {
//     const dataToEncrypt = {
//         endpoint: `/contact/api/uploadFile/${key}`,
//         payload: null,
//         type: "post",
//         auth: authHeader()
//     };

//     const encryptedData = encryption.encryptData(dataToEncrypt)
//     console.log(encryptedData)
//     try {

//         return axios.post(`/decrypt/multipart?endpoint=/contact/api/uploadFile/${key}`, data,


//             { headers: initAuth() }
//         )
//     } catch (error) {
//         return []
//     }
// }

const saveFile = async (key, formdata) => {
    try {
    
        const file = formdata.get("file");
        if (!file) {
            throw new Error("No file found in FormData.");
        }

        
        const fileAsBase64 = await convertFileToBase64(file);

        
        const payload = {
            fileName: file.name,
            base64Data: fileAsBase64.split(",")[1], 
            contentType: file.type,
            fileSize: file.size,
        };

        const dataToEncrypt = {
            endpoint: `/contact/api/uploadFileV1/${key}`,
            payload,
            type: "post",
            auth: authHeader(),
        };

        const encryptedData = encryption.encryptData(dataToEncrypt);
        console.log(encryptedData);

        // Make API call
        return axios.post(
            `/decrypt`,
            dataToEncrypt,
            { headers: initAuth() }
        );
    } catch (error) {
        console.error("Error in saveFile:", error);
        throw error;
    }
};

const saveDocument = (data) => {
    const dataToEncrypt = {
        endpoint: `/order/api/documents/save`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveLead = (data,id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/hubspot/saveLeads/${id}`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const addLead = (data) => {
  
    try {

        return axios.post(`${propertiesApp.python_url}/leads/add_leads_data`, data,
            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const updateById = (data,endpoint) => {
    const dataToEncrypt = {
        endpoint: endpoint,
        payload: data,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAll = (endpoint) => {
    const dataToEncrypt = {
        endpoint: endpoint,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const getAllDocuments = (endpoint) => {
    const dataToEncrypt = {
        endpoint: "/order/api/documents/getAll",
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getFile = (path) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/getFile?path=${path}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllProduct = (orderTypeId,entityId,stateId,countyId) => {
    const dataToEncrypt = {
        endpoint: `/order/api/productListing/filter-product/${orderTypeId}/${stateId}/${entityId}/${countyId}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllStatus = () => {
    const dataToEncrypt = {
        endpoint: "/core/api/statusMaster/getAll",
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getNaicsCodeByStateId = (id) => {
    const dataToEncrypt = {
        endpoint: `/core/api/stateWiseNaicsCodes/state/${id}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getNaicsSubCode = (id) => {
    const dataToEncrypt = {
        endpoint: `/core/api/naicsSubCodes/naics-code/${id}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllProductCatagory = () => {
    const dataToEncrypt = {
        endpoint: "/order/api/productCatalog/getAll",
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const getAllConfig = (endpoint) => {
    const dataToEncrypt = {
        endpoint: "/core/api/MasterConfig/getAll",
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllAnnouncement = () => {
    const dataToEncrypt = {
        endpoint: "/core/api/announcement/getAll",
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const getConfig = () => {
    const dataToEncrypt = {
        endpoint: "/contact/api/config/getAll",
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllMasterManager = (endpoint) => {
    const dataToEncrypt = {
        endpoint: "/core/api/MasterManager/getAll",
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const getAllTemplate = (endpoint) => {
    const dataToEncrypt = {
        endpoint: "/core/api/templateMaster/getAll",
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllApplicantType = (endpoint) => {
    const dataToEncrypt = {
        endpoint: "/order/api/applicantType/getAll",
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const updateConfigById = (data) => {
    const dataToEncrypt = {
        endpoint: "/contact/api/config/update",
        payload: data,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllPendingRequest = (endpoint) => {
    const dataToEncrypt = {
        endpoint: "/core/api/pendingRequest/getAll",
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const updatePendingRequest = (data) => {
    const dataToEncrypt = {
        endpoint: "/core/api/pendingRequest/update",
        payload: data,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const savePendingRequest = (data) => {
    const dataToEncrypt = {
        endpoint: `/core/api/pendingRequest/save`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const deletePendingRequest = (id) => {
    const dataToEncrypt = {
        endpoint: `/core/api/pendingRequest/deleteById/${id}`,
        payload: null,
        type: "delete",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}






const MasterServices = {
  getAll,
  saveAll,
  updateById,
  getDataById,
  getAllConfig,
  getAllMasterManager,
  getAllStatus,
  saveFile,
  getAllProduct,
  saveDocument,
  getAllProductCatagory,
  getAllDocuments,
  getAllTemplate,
  getFile,
  getConfig,
  updateConfigById,
  saveLead,
  getNaicsCodeByStateId,
  getNaicsSubCode,
  getAllAnnouncement,
  getAllApplicantType,
  getAllPendingRequest,
  savePendingRequest,
  updatePendingRequest,
  deletePendingRequest,
  addLead
}

export default MasterServices;