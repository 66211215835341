import React from "react";
import { Modal } from "react-bootstrap";
import CommonTourBlue from "./CommonTourComponent/CommonTourBlue";
import CommonTourWhite from "./CommonTourComponent/CommonTourWhite";
import "./CommonStyleTour/CommonStyleTour.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import my1 from "../../Assets/Images/tour/m1.png";
import my2 from "../../Assets/Images/tour/m2.png";
import my3 from "../../Assets/Images/tour/m3.png";
import my4 from "../../Assets/Images/tour/m4.png";
import my5 from "../../Assets/Images/tour/m5.png";
import my6 from "../../Assets/Images/tour/m6.png";
import my7 from "../../Assets/Images/tour/m7.png";
import my8 from "../../Assets/Images/tour/m8.png";
import my9 from "../../Assets/Images/tour/m9.png";
import { Navigation } from "swiper/modules";
import CompanyTourModal from "./CompanyTourModal";
import CommonTourModal from "./CommonTourModal";

const MyCompanyModal = (props) => {
  const [modalsecondShow, setModalSecondShow] = React.useState(false);

  const handleStartClick = () => {
    setModalSecondShow(true);
    props.onHide();
  };
  return (
    <section>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="common-modal-class"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            My Company
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 1 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading"> My Company</h4>
                  <p className="tour-text-holder">
                    Access my company from the drop down menu by clicking on the
                    profile button displayed on the top right corner.
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    src={my1}
                    className="tour-img"
                  />
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 2 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading"> My Company: Details</h4>
                  <p className="tour-text-holder">
                    Easily manage your company's vital information.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={my2} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Update critical company information such as entity
                      details, company contact information, IRS/Accounting
                      details and principal address.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 3 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">My Company: Contacts</h4>
                  <p className="tour-text-holder">
                    Easily manage your company's contact information.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={my3} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Maintain a list of </span>{" "}
                      company contacts, including phone numbers and email
                      addresses, ensuring seamless communication.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 4 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">My Company: Locations</h4>
                  <p className="tour-text-holder">
                    Easily manage your company locations information.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={my4} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Add Location:</span>
                      Add a new location to your company.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Edit Location:</span>
                      Modify existing location details, such as address, contact
                      information, and address type.
                    </li>
                    {/* <li>
                      <span className="blkkk-textt"> Delete Location:</span>
                      Remove a location from your company's profile.
                    </li> */}
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 5 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">My Company: Key Personnel</h4>
                  <p className="tour-text-holder">
                    Easily manage your company's key personnel information.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={my5} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Manage profiles </span>
                      for essential roles such as owners, organizers, primary
                      contacts, billing and shipping contacts, assignees,
                      debtors, incorporators, registered agents, officers,
                      secured parties and other personnel.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 6 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">My Company: Records</h4>
                  <p className="tour-text-holder">
                    Easily manage your company's records.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={my6} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> View and manage </span>
                      company filings and related documents to stay compliant
                      and organized.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 7 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">My Company: Orders</h4>
                  <p className="tour-text-holder">
                    Easily manage your company's order details.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={my7} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Access a detailed </span>
                      list of orders placed under the company, including order
                      statuses and key information.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 8 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">My Company: Subscriptions</h4>
                  <p className="tour-text-holder">
                    Easily manage your company's subscriptions details.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={my8} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Track and manage </span>
                      all ongoing subscriptions associated with your company,
                      ensuring timely renewals and seamless operations.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 9 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">My Company: Services</h4>
                  <p className="tour-text-holder">
                    Easily manage your company's services details.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={my9} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> View the list </span>
                      of services associated with your company, along with their
                      details and related information.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Modal.Body>
      </Modal>
      <CommonTourModal
        show={modalsecondShow}
        onHide={() => setModalSecondShow(false)}
      />
    </section>
  );
};

export default MyCompanyModal;
