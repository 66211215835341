import React from 'react';
import Hero from './Hero';
import Partners from './Partners';
import Features from './Features';
import Services from './Services';
import Process from './Process';
import Dashboard from './Dashboard';
import Testimonial from './Testimonial';
import CallToAction from './CallToAction';
import BusinessLanding from './BuisinessLanding';

const MainContent = () => {
  return (
    <div className='mt-24'>

      <div className="flex flex-col mt-5 website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
        <Hero />
      </div>
      <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
        <Partners />
      </div>
      <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
        <Services />
      </div>
      <div className="flex flex-col website-font items-center landing-feature-section-container">
        <Process />
      </div>
      <div className="flex flex-col website-font items-center landing-feature-section-container">
        <Features />
      </div>
     
     
      {/* <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
        <Dashboard />
      </div> */}
      <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
        <Testimonial />
      </div>
      <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
        <BusinessLanding />
      </div>
    </div>

  );
};

export default MainContent;