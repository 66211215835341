import React, { useContext } from 'react';
import authContext from '../../../common/authContext';

function WelcomeHeader() {
  const {loginUserName}=useContext(authContext)

  return (
    <header className="flex flex-col mt-6 w-full max-md:max-w-full">
      <div className="flex gap-4 px-4 items-start w-full max-md:max-w-full">
        <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full">
          <h1 className="text-3xl font-medium leading-none text-gray-900 max-md:max-w-full">
            Welcome, {loginUserName}!
          </h1>
          <p className="mt-1 text-base text-gray-500 max-md:max-w-full">
            Get an overview of your client updates, ongoing orders, and employees activites.
          </p>
        </div>
      </div>
    </header>
  );
}

export default WelcomeHeader;