
import React, { useState, useRef, useContext, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router';
import { Skeleton } from 'primereact/skeleton';
import ShiftsServices from '../services/demoServices';
import AddCity from './AddComponents/AddProduct';
import DownloadPdf from '../utils/downloadPdf';
import AddVendor from './AddComponents/AddInvoice';
import SideBarMenu from '../common/Sidebar';
import { Badge } from 'primereact/badge';
import { Avatar } from 'primereact/avatar';
import UserServices from '../services/userService';
import { OverlayPanel } from 'primereact/overlaypanel';
import { TabPanel, TabView } from 'primereact/tabview';
import RolesAndAccess from './RolesAndAccess';
import { createSearchParams } from 'react-router-dom';
import AddCustomUser from './AddComponents/AddCutsomUser';
import { Dropdown } from 'primereact/dropdown';
import encryption from '../services/CryptoClass';



export default function Contact() {
    const [cities, setcities] = useState([]);

    const history = useNavigate()

    // handling filters
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.IN },
        fullName: { value: null, matchMode: FilterMatchMode.EQUALS },
        contactNo: { value: null, matchMode: FilterMatchMode.EQUALS },
        city: { value: null, matchMode: FilterMatchMode.EQUALS },

    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const dt = useRef(null);
    const [df, setDf] = useState(true)
    const navigate = useNavigate()
    const [error, setError] = useState('')
    const toast = useRef(null)
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false)
    const [visible3, setVisible3] = useState(false)
    const [visible4, setVisible4] = useState(false)
    const [showSkeleton, setShowSkeleton] = useState(false)

    const [selectedProduct, setSelectedProduct] = useState(null);


    const onRowSelect = (event) => {
        console.log(event.data)
        history({
            pathname: "/client",
            search: createSearchParams(event.data).toString()
        })
    };

    // const onRowUnselect = (event) => {
    //     toast.current.show({ severity: 'warn', summary: 'Product Unselected', detail: `Name: ${event.data.name}`, life: 3000 });
    // };

    // handling toggle dialog box
    const dialogView = () => setVisible(false)
    const cancelView = () => setVisible(false)

    const downloadop = useRef(null)

    // fetch all data
    useEffect(() => {
        UserServices.getUserDetails()
            .then((res) => {
                const encryptedResponse = res.data;
                const decryptedResponse = encryption.decryptData(encryptedResponse);
                console.log(decryptedResponse)
                setcities(decryptedResponse)
                setShowSkeleton(true)
            })
            .catch((err) => {
                console.log(err)
                setShowSkeleton(true)
            })
    }, [])

    // DataTable columns
    const cols = [

        { field: 'fullName', header: 'Name' },
        { field: 'email', header: 'Email' },
        { field: 'contactNo', header: 'Contact' },
        { field: 'city', header: 'City' },

    ];


    // handling filter
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };


    // Export DataTable data
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, cities);
                doc.save('Contacts.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(cities);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Contacts');
        });
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    };


    // Table Header
    const renderHeader = () => {
        return (
            <div >
                <div className="flex align-items-center justify-content-end gap-2">

                </div>
                <div className="flex align-items-center justify-content-end gap-5">
                    <span className="p-input-icon-left" >
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" className='keyword-search' />
                    </span>

                    {/* <Button
                        type="button"
                        // icon="pi pi-plus"
                        label='Add User'
                        style={{ height: "40px", width: "200px", fontSize: "14px" }}

                        onClick={() => { setVisible(true) }}

                        className="mt-2  font-fam-for-all text-center text-lg mr-3" tooltip="Create New User" tooltipOptions={{ position: 'bottom' }}
                    ></Button> */}


                    {/* <Button
                        type="button"
                        icon="pi pi-eye"
                        onClick={() => { setVisible4(true) }}

                        className="mr-0 panel-Btn panel-Btn-Label" tooltip="View User" tooltipOptions={{ position: 'bottom' }}
                    ></Button> */}

                    {/* <Button
                        type="button"
                        icon="pi pi-file-excel"
                        onClick={exportExcel}
                        disabled={cities.length > 0 ? false : true}
                        className="mr-0 panel-Btn panel-Btn-Label" data-pr-tooltip="Download XLS" tooltip="Create A New User" tooltipOptions={{ position: 'bottom' }}
                    ></Button>
                    <Button
                        type="button"
                        icon="pi pi-file-pdf"
                        onClick={exportPdf}
                        disabled={cities.length > 0 ? false : true}
                        className="mr-0 panel-Btn panel-Btn-Label" data-pr-tooltip="Download PDF" tooltip="Create A New User" tooltipOptions={{ position: 'bottom' }}
                    ></Button> */}

                </div>
            </div>
        );
    };


    const header = renderHeader();

    // Edit row data 
    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const skillEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;;
    };


    const handleEdit = (data) => {

        // setId(data.id)
        console.log(data.id)
        // setOpen1(true)
        setVisible1(true)

    }

    // handling delete functionality
    const dlt = (rowdata) => {

        // setId(rowdata.id)
        // setOpen1(true)
        setVisible3(true)
    }


    // body icon
    const iconBodyTemplate = (rowdata) => {
        return (
            <>
                {/* <button className="pi pi-pencil image-btn-icon" data-toggle="tooltip" data-placement="bottom" title="Edit" onClick={() => handleEdit(rowdata)}></button> */}
                <Button
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Edit"
                          onClick={() => handleEdit(rowdata)}
                          // icon="pi pi-file-edit"
                          style={{
                            border: "none",
                            height: "40px",
                            backgroundColor: "transparent",
                            color: "blue",
                          }}
                          className="border-none h-10 bg-transparent text-#175CD3 hover:bg-#175CD3 hover:text-blue-800 transition duration-200"
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_1308_27815)">
                              <path
                                d="M9.16675 3.33332H3.33341C2.89139 3.33332 2.46746 3.50891 2.1549 3.82147C1.84234 4.13403 1.66675 4.55796 1.66675 4.99999V16.6667C1.66675 17.1087 1.84234 17.5326 2.1549 17.8452C2.46746 18.1577 2.89139 18.3333 3.33341 18.3333H15.0001C15.4421 18.3333 15.866 18.1577 16.1786 17.8452C16.4912 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333M15.4167 2.08332C15.7483 1.7518 16.1979 1.56555 16.6667 1.56555C17.1356 1.56555 17.5852 1.7518 17.9167 2.08332C18.2483 2.41484 18.4345 2.86448 18.4345 3.33332C18.4345 3.80216 18.2483 4.2518 17.9167 4.58332L10.0001 12.5L6.66675 13.3333L7.50008 9.99999L15.4167 2.08332Z"
                                stroke="#004EEC"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1308_27815">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span
                            style={{
                              marginLeft: "5px",
                            }}
                            className="text-#175CD3 font-inter text-sm font-medium leading-5"
                          >
                            Edit
                          </span>
                        </Button>
                <button className="pi pi-trash image-btn-icon-delete" data-toggle="tooltip" data-placement="bottom" title="Delete" onClick={() => dlt(rowdata)}></button>
            </>
        )
    }

    // body icon >
    const bodyTemplate = () => {
        return (
            <>
                <i className='pi pi-angle-right'></i>
            </>
        )
    }


    // isActive icon template
    const activeTemplate = (val) => {
        const bol = val.isActive

        return bol ? "Active" : "InActive"
    }

    const items = Array.from({ length: 5 }, (v, i) => i);

    return (
        <>
            <div className='grid'>
                
                <div className=' card' style={{ backgroundColor: '#ffff' }}>
                    <div className='dashboard-container font-fam-for-all'>
                        <div className='dashboard-main'>
                            <div className='dashboard-data-header' style={{ marginBottom: "10px", marginTop: "10px" }}>
                                <div className='dashboard-data-sub-header' style={{ paddingLeft: "0px", paddingRight: "15px" }}>
                                    <div className='sub-header-css1'>
                                        <div className='header-content'>
                                            <div className='text-header-container'>
                                                {/* <button onClick={() => history("/contact")}>Back</button> */}

                                                <div className='text-header1'>Users</div>
                                                <div className='text-header2'>Manage Your Users Here</div>
                                            </div>
                                            <div className='text-header-container1'>
                                                <Button
                                                    type="button"
                                                    // icon="pi pi-plus"

                                                    style={{ fontSize: "14px" }}

                                                    onClick={() => { setVisible(true) }}

                                                    className="mt-2  font-fam-for-all text-center mr-6" tooltip="Create New User" tooltipOptions={{ position: 'bottom' }}
                                                >Add User</Button>
                                                {/* <div className='action-header-container'>
                                                    <Button icon="pi pi-cog" className='header-button'></Button>
                                                    
                                                    <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '20px', marginTop: '10px' }}>
                                                        <Badge value="2"></Badge>
                                                    </i>
                                                </div>
                                                <div className='avatar-header-container'>
                                                    <Avatar icon="pi pi-user" style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} shape="circle" />
                                                </div> */}

                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div className='divider-x my-2'></div>

                            {/* <TabView className='user'>
                                <TabPanel header="All Users">
                                    
                                </TabPanel>
                                <TabPanel header="User Role Manager">
                                    <RolesAndAccess />
                                </TabPanel>
                            </TabView> */}

                            <div className='dashboard-data-header' style={{ marginBottom: "10px", marginTop: "10px" }}>
                                <div className='dashboard-data-sub-header' style={{ paddingLeft: "0px", paddingRight: "15px" }}>

                                    <div className="flex align-items-center justify-content-start gap-3">
                                        <span className="p-input-icon-left" >
                                            <i className="pi pi-search" />
                                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" style={{ borderRadius: "8px" }} />
                                        </span>
                                        <span>
                                            <Dropdown placeholder='Filtered By Company' style={{ borderRadius: "8px" }} />
                                        </span>
                                        <span>
                                            <Dropdown placeholder='Filtered By Access Level' style={{ borderRadius: "8px" }} />
                                        </span>
                                        <span>
                                            <Button
                                                type="button"
                                                // icon="pi pi-plus"

                                                style={{ fontSize: "14px" }}



                                                className="  font-fam-for-all text-center"
                                            >Filter</Button>
                                        </span>
                                        <span>
                                            <Button
                                                type="button"
                                                // icon="pi pi-plus"

                                                style={{ fontSize: "14px" }}

                                                className="  font-fam-for-all text-center bg-primary-reverse"
                                            >Clear Filter</Button>
                                        </span>
                                    </div>

                                    <div className="card p-fluid" style={{ minWidth: '1290px' }}>

                                        {
                                            showSkeleton ?
                                                <DataTable value={cities} ref={toast} editMode="row" dataKey="id" selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)}
                                                    onRowSelect={onRowSelect} metaKeySelection={false}
                                                    paginator rows={7} rowsPerPageOptions={[5, 10, 25, 50]} className='datatable-sm font-fam-for-all'
                                                    filters={filters}
                                                    globalFilterFields={['email', 'firstName', 'contactNo', "city"]} emptyMessage={error}  >
                                                    <Column body={bodyTemplate} className='datatable-body-icon'></Column>
                                                    <Column field="firstName" className='data-table-row-font datatable-sm-col' header="Name" sortable ></Column>
                                                    <Column field="emailId" header="Email" className='font-fam-for-all datatable-sm-col'
                                                        editor={(options) => skillEditor(options)} sortable ></Column>
                                                    <Column field="mobileNo" header="Contact No" className='font-fam-for-all datatable-sm-col'
                                                        editor={(options) => skillEditor(options)} sortable ></Column>
                                                    <Column field="city" header="City" className='font-fam-for-all datatable-sm-col'
                                                        editor={(options) => skillEditor(options)} sortable ></Column>


                                                    <Column body={iconBodyTemplate}></Column>
                                                </DataTable> : <DataTable value={items} ref={toast} editMode="row" dataKey="id"
                                                    paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} className='datatable-sm font-fam-for-all'
                                                    filters={filters}
                                                    globalFilterFields={['name', 'cityName', 'id',]} emptyMessage={error}  >
                                                    <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                                    <Column field="cityName" header="Name" className='font-fam-for-all datatable-sm-col'
                                                        editor={(options) => skillEditor(options)} body={<Skeleton />} sortable ></Column>
                                                    <Column field="cityName" header="Email" className='font-fam-for-all datatable-sm-col'
                                                        editor={(options) => skillEditor(options)} body={<Skeleton />} sortable ></Column>
                                                    <Column field="isActive" className='data-table-row-font datatable-sm-col' header="Contact No" body={<Skeleton />} editor={(options) => skillEditor(options)} sortable ></Column>
                                                    <Column field="cityName" header="City" className='font-fam-for-all datatable-sm-col'
                                                        editor={(options) => skillEditor(options)} body={<Skeleton />} sortable ></Column>

                                                    <Column body={<Skeleton />}></Column>
                                                </DataTable>
                                        }
                                        <AddCustomUser visible={visible} setVisible={setVisible} />

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </>);
}

