import React, { useContext, useEffect, useState } from "react";
import WelcomeHeader from "./WelcomeHeader";
import StatCard from "./StatCard";
import Chart from "./Chart";
import dayjs from "dayjs";
import OrderOverview from "./OrderView";
import ActiveClientsMap from "./ActiveClient";
import { formatDate } from "../../../utils/dateFormat";
import { Dropdown } from "primereact/dropdown";
import UserServices from "../../../services/userService";
import authContext from "../../../common/authContext";
import OrderServices from "../../../services/orderServices";
import { Button } from "primereact/button";
import ClintInvoicePayment from "../DataTable/ClintInvoicePayment/ClintInvoicePayment";
import ClientSubscritpion from "../DataTable/ClientSubscritpion/ClientSubscritpion";

function Dashboard() {
  const {
    clearData,
    setFilterStatusId,
    setAccountManagerId: setContextAccountManagerId,
    setEntityDash,
    setStateDash,
    setRedirectedData,
  } = useContext(authContext);

  const user = JSON.parse(localStorage.getItem("user"));

  const [dashboardData, setDashboardData] = useState([]);
  const [allAccountManager, setAllAccountManager] = useState([]);
  const [accountManagerDataId, setAccountManagerDataId] = useState(0);
  const [superAdminId, setSuperAdminId] = useState(null);
  const [managerId, setManagerId] = useState(null);
  const [activeButton, setActiveButton] = useState(3);
  const [endsDate, setEndDate] = useState(null);
  const [startsDate, setStartDate] = useState(null);
  const [selectedMonths, setSelectedMonths] = useState(3);
  const [statCards, setStatCards] = useState([]);
  const [orderOverviewItems, setOrderOverviewItems] = useState([]);

  // Helper to check if an ID exists in an array of role objects
  function checkIdsExist(arr, idsToCheck) {
    const foundIds = idsToCheck.filter((idToCheck) =>
      arr.some((item) => item.id === idToCheck)
    );
    return foundIds.length > 0 ? foundIds : null;
  }

  // Set superAdminId based on user roles on mount
  useEffect(() => {
    if (
      user &&
      user.companyRoles &&
      user.companyRoles.length > 0 &&
      user.companyRoles[0].roles &&
      user.companyRoles[0].roles.length > 0
    ) {
      const id = checkIdsExist(user.companyRoles[0].roles, [4]);
      setSuperAdminId(id);
    }
  }, []);
  const [loading, setLoading] = useState(true);


  // On mount: reset context data and get employee details
  // useEffect(() => {
  //   setEntityDash(null);
  //   setStateDash(null);
  //   setFilterStatusId(null);
  //   setRedirectedData(null);
  //   clearData();

  //   if (user) {
  //     // Fetch employee details to set the account/manager IDs
  //     UserServices.getEmployeeByUserId(user.id)
  //       .then((res) => {
  //         if (res.data) {
  //           setAccountManagerDataId(res.data.id);
  //           setManagerId(res.data.id);
  //           setContextAccountManagerId(res.data.id);
  //         }
  //       })
  //       .catch((err) => {
  //         console.error("Error fetching employee by user ID:", err);
  //       });
  //   }
  // }, [
  //   user,
  //   clearData,
  //   setEntityDash,
  //   setStateDash,
  //   setFilterStatusId,
  //   setRedirectedData,
  //   setContextAccountManagerId,
  // ]);


  const [employeeFetched, setEmployeeFetched] = useState(false);

useEffect(() => {
  if (user && !employeeFetched && !superAdminId) {
    UserServices.getEmployeeByUserId(user.id)
      .then((res) => {
        if (res.data) {
          setAccountManagerDataId(res.data.id);
          setManagerId(res.data.id);
          setContextAccountManagerId(res.data.id);
          setEmployeeFetched(true);  
        }
      })
      .catch((err) => {
        console.error("Error fetching employee by user ID:", err);
      });
  }
}, [employeeFetched,superAdminId]); 


  // Fetch all account managers only once on mount
  useEffect(() => {
    UserServices.getEmployeeDetails()
      .then((res) => {
        if (Array.isArray(res.data)) {
          setAllAccountManager(res.data);
        }
      })
      .catch((err) => {
        console.error("Error fetching employee details:", err);
      });
  }, []);

  const [stateCardData, setStateCardData] = useState([])


  useEffect(() => {
    setLoading(true);
    OrderServices.getAllOrderForDashbaordNew(accountManagerDataId, selectedMonths)
      .then((res) => {
        if (res.status === 204) {
          console.warn("No content available for the selected filters.");
          setDashboardData([]); // Ensure the state resets to empty data
        } else if (Array.isArray(res.data)) {
          setDashboardData(res.data);
        }
      })
      .catch((err) => console.error("Error fetching dashboard data:", err))
      .finally(() => setLoading(false));
  }, [accountManagerDataId, selectedMonths]);

  
  useEffect(() => {
    // OrderServices.getFinancialSummeryDashboard(managerId, selectedMonths)

    OrderServices.getFinancialSummeryDashboard(0, selectedMonths)
      .then((res) => {
        if (res.data) {
          setStateCardData(res.data);
          // setStatCards([
          //   { title: "Total Orders", value: res.data.total_orders?.toLocaleString() },
          //   { title: "Completed Orders", value: res.data.completed_orders?.toLocaleString() },
          //   { title: "Orders in Progress", value: res.data.in_progress_orders?.toLocaleString() },
          //   { title: "Amount Received", value: `$${res.data.amount_received.toFixed(2)}` },
          //   { title: "Amount Receivable", value: `$${res.data.amount_receivable.toFixed(2)}` },
          // ]);
          setStatCards([
            { title: "Total Orders", value: res.data.total_orders?.toLocaleString() },
            { title: "Completed Orders", value: res.data.completed_orders?.toLocaleString() },
            { title: "Orders in Progress", value: res.data.in_progress_orders?.toLocaleString() },
            { 
              title: "Amount Received", 
              value: `$${res.data.amount_received?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}` 
            },
            { 
              title: "Amount Receivable", 
              value: `$${res.data.amount_receivable?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}` 
            },
          ]);
    
          
        }
      })
      .catch((err) => {
        console.error("Error fetching financial summary data:", err);
      });
  }, [accountManagerDataId, selectedMonths]);
  
  


  useEffect(() => {
    const totalOrders = dashboardData.reduce(
      (sum, item) => sum + (item.total_orders || 0),
      0
    );
    const totalCompleted = dashboardData.reduce(
      (sum, item) => sum + (item.completed_count || 0),
      0
    );
    const totalInProgress = dashboardData.reduce(
      (sum, item) => sum + (item.in_progress_count || 0),
      0
    );
    const createdCount = dashboardData.reduce(
      (sum, item) => sum + (item.count_created || 0),
      0
    );
    const inReviewCount = dashboardData.reduce(
      (sum, item) => sum + (item.in_review_count || 0),
      0
    );
    const submittedCount = dashboardData.reduce(
      (sum, item) => sum + (item.submitted_count || 0),
      0
    );
    const approvalPendingCount = dashboardData.reduce(
      (sum, item) => sum + (item.approval_pending_count || 0),
      0
    );
    const payLaterApprovedCount = dashboardData.reduce(
      (sum, item) => sum + (item.pay_later_approved_count || 0),
      0
    );
    const payLaterRejectedCount = dashboardData.reduce(
      (sum, item) => sum + (item.pay_later_rejected_count || 0),
      0
    );
    const vstateQueryRaisedCount = dashboardData.reduce(
      (sum, item) => sum + (item.vstate_query_raised_count || 0),
      0
    );
    const govtQueryRaisedCount = dashboardData.reduce(
      (sum, item) => sum + (item.govt_query_raised_count || 0),
      0
    );
    const clientRespondedCount = dashboardData.reduce(
      (sum, item) => sum + (item.client_responded_count || 0),
      0
    );
    const queryRespondedCount = dashboardData.reduce(
      (sum, item) => sum + (item.query_responded_count || 0),
      0
    );
    const resubmittedCount = dashboardData.reduce(
      (sum, item) => sum + (item.resubmitted_count || 0),
      0
    );
    const payLaterRequestedCount = dashboardData.reduce(
      (sum, item) => sum + (item.pay_later_requested_count || 0),
      0
    );
  
    // Calculate "orders placed" by summing various metrics.
  
    const totalOrdersPlaced =
      inReviewCount +
      submittedCount +
      approvalPendingCount +
      totalInProgress +
      totalCompleted +
      payLaterApprovedCount +
      vstateQueryRaisedCount +
      clientRespondedCount +
      payLaterRequestedCount +
      resubmittedCount +
      govtQueryRaisedCount +
      payLaterRejectedCount +
      createdCount +
      queryRespondedCount;
  
    // For "orders in need of attention", we'll use clientRespondedCount
    const ordersInNeedOfAttention = clientRespondedCount || 0;
  
    // Update Order Overview items (with id fields as required)
    setOrderOverviewItems([
      {
        id: null,
        title: `Orders placed (${totalOrdersPlaced?.toLocaleString()} Total)`,
        value: `${ordersInNeedOfAttention}`,
        subtext: "in need of attention",
      },
      { id: 9, title: "Under review", value: inReviewCount?.toLocaleString() },
      { id: 14, title: "Order Created", value: createdCount?.toLocaleString() },
      { id: 8, title: "Submitted orders", value: submittedCount?.toLocaleString() },
      {
        id: 1000,
        title: "Approval pending",
        value: approvalPendingCount?.toLocaleString(),
      },
      { id: 15, title: "Order In Progress", value: totalInProgress?.toLocaleString() },
      {
        id: 1002,
        title: "Pay Later Approved",
        value: payLaterApprovedCount?.toLocaleString(),
      },
      {
        id: 1003,
        title: "Pay Later Rejected",
        value: payLaterRejectedCount?.toLocaleString(),
      },
      {
        id: 13,
        title: "vState Query Raised",
        value: vstateQueryRaisedCount?.toLocaleString(),
      },
      {
        id: 1005,
        title: "Govt. Query Raised",
        value: govtQueryRaisedCount?.toLocaleString(),
      },
      {
        id: 1006,
        title: "Client Responded",
        value: clientRespondedCount?.toLocaleString(),
      },
      {
        id: 1004,
        title: "Query Responded",
        value: queryRespondedCount?.toLocaleString(),
      },
      {
        id: 1007,
        title: "Orders Resubmitted",
        value: resubmittedCount?.toLocaleString(),
      },
      { id: 11, title: "Order Completed", value: totalCompleted?.toLocaleString() },
    ]);
  
    // Update Stat Cards.


    // setStatCards([
    //   { title: "Total Orders", value: totalOrders?.toLocaleString() },
    //   {
    //     title: "Orders Amount",
    //     value: `$0`,
    //   },
    //   { title: "Orders Completed", value: totalCompleted?.toLocaleString() },
    //   { title: "Orders in Progress", value: totalInProgress?.toLocaleString() },
    //   { title: "Pending Orders", value: "0" },
    // ]);
  }, [dashboardData]);
  
  
  // Update the months filter (selectedMonths) and date values when a month button is clicked
  const handleOrderWithDate = (monthsAgo, buttonId) => {
    const endDate = dayjs();
    const startDate = endDate.subtract(monthsAgo, "month");
    setEndDate(endDate);
    setStartDate(startDate);
    setSelectedMonths(monthsAgo);
    setActiveButton(buttonId);
  };

  return (
    <main className="flex flex-col font-fam-for-all">
      <div className="z-10 shrink-0 ml-16 max-w-full h-px bg-gray-100 border border-gray-100 border-solid w-[852px] max-md:mt-10" />
      <div className="flex overflow-hidden flex-col mt-0 w-full bg-gray-50 pb-[40px] max-md:pb-24 max-md:mt-0 max-md:max-w-full">
        <WelcomeHeader />
        <hr className="w-full bg-gray-200 min-h-[1px] max-md:max-w-full" />
        <section className="md:flex-none xl:flex flex-wrap items-start mt-3 w-full max-md:max-w-full">
          <div className="flex flex-col flex-1 shrink items-center basis-0 min-w-[240px] max-md:max-w-full">
            <div className="flex gap-10 items-start px-6 max-w-full w-[1280px] max-md:px-5">
              <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full">
                <div className="flex flex-col w-full text-sm font-medium leading-none max-md:max-w-full">
                  <div className="flex flex-wrap gap-10 justify-between items-start w-full max-md:max-w-full">
                    <div className="flex items-start md:w-[50%] lg:w-[33%] shadow-sm text-slate-700 border border-gray-300 rounded-lg overflow-hidden align-items-center">
                      <button
                        className={`flex-1 px-4 py-2 ${activeButton === 12 ? "vstate-split-button text-white" : "bg-white text-slate-800 hover:bg-gray-100"} border-r`}
                        onClick={() => handleOrderWithDate(12, 12)}
                      >
                        12 months
                      </button>
                      <button
                        className={`flex-1 px-4 py-2 ${activeButton === 6 ? "vstate-split-button text-white" : "bg-white text-slate-800 hover:bg-gray-100"} border-r`}
                        onClick={() => handleOrderWithDate(6, 6)}
                      >
                        6 months
                      </button>
                      <button
                        className={`flex-1 px-4 py-2 ${activeButton === 3 ? "vstate-split-button text-white" : "bg-white text-slate-800 hover:bg-gray-100"} border-r`}
                        onClick={() => handleOrderWithDate(3, 3)}
                      >
                        3 months
                      </button>
                      <button
                        className={`flex-1 px-4 py-2 ${activeButton === 1 ? "vstate-split-button text-white" : "bg-white text-slate-800 hover:bg-gray-100"}`}
                        onClick={() => handleOrderWithDate(1, 1)}
                      >
                        1 month
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap gap-3 mt-4 items-center w-full max-md:max-w-full">
                  {statCards.map((card, index) => (
                    <StatCard key={index} {...card} />
                  ))}
                </div>
                <Chart />
                <ActiveClientsMap startDate={startsDate} endDate={endsDate} />
                <div className="mt-4">
                  <ClintInvoicePayment />
                </div>
                <div className="mt-4">
                  <ClientSubscritpion />
                </div>
              </div>
            </div>
          </div>
          <aside className="flex flex-col min-w-[240px] w-[460px] max-md:max-w-full">
            <div className="flex gap-6 items-start pr-6 w-full max-md:pr-5 max-md:max-w-full">
              <div className="flex flex-1 shrink gap-8 items-start w-full basis-0 min-w-[240px] max-md:max-w-full">
                <div className="flex overflow-hidden flex-col flex-1 shrink w-full bg-white rounded-lg border border-gray-200 shadow-sm basis-0 min-w-[240px] max-md:max-w-full">
                  <header className="flex flex-col w-full bg-blue-50 max-md:max-w-full">
                    <div className="flex gap-4 items-start p-3 w-full max-md:px-5 max-md:max-w-full">
                      <div className="flex flex-1 shrink gap-4 items-center w-full basis-0 min-w-[240px]">
                        <div className="flex flex-col flex-1 shrink self-stretch my-auto w-full basis-0 min-w-[240px]">
                          <h2 className="text-2xl font-medium leading-loose text-gray-900 m-0">
                            Orders Snapshot
                          </h2>
                          <p className="mb-0 text-base leading-none text-gray-500">
                            {formatDate(new Date())}
                          </p>
                        </div>
                      </div>
                    </div>
                    <hr className="w-full bg-gray-200 min-h-[1px] m-0 max-md:max-w-full" />
                  </header>
                  <div className="flex flex-col p-6 w-full font-medium max-md:px-5 max-md:max-w-full">
                    <nav className="flex items-start w-full text-sm leading-none whitespace-nowrap shadow-sm text-slate-700">
                      {superAdminId === null && (
                        <>
                          <Dropdown
                            style={{ width: "100%", height: "47px" }}
                            value={accountManagerDataId}
                            onChange={(e) => setAccountManagerDataId(e.target.value)}
                            options={allAccountManager}
                            optionValue="id"
                            optionLabel="firstName"
                            placeholder="Select Account Rep"
                            filter
                            resetFilterOnHide
                            className="dialog-form-input-field-wizard"
                            required
                          />
                          <span>
                            <Button
                              type="button"
                              style={{ fontSize: "14px", height: "40px" }}
                              className="ml-2 font-fam-for-all text-center text-slate-700 vstate-reverse-button"
                              onClick={() => setAccountManagerDataId(0)}
                            >
                              Clear
                            </Button>
                          </span>
                        </>
                      )}
                    </nav>
                    {loading ? (
  <div className="flex justify-center items-center h-32 text-gray-900 text-lg animate-pulse">
    Fetching orders...
  </div>
) : (
  <OrderOverview items={orderOverviewItems} />
)}
          </div>
                </div>
              </div>
            </div>
          </aside>
        </section>
      </div>
    </main>
  );
}

export default Dashboard;
