import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import "../../CommonSection/CommonStyle/CommonStyle.css";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";

const SubscriptionFaq = () => {
  const faqItems = [
    {
      key: "0",
      question: "What is a registered agent?",
      answer:
        "A registered agent is responsible for receiving legal documents and state notifications on behalf of your business.",
    },
    {
      key: "1",
      question: "Why do I need subscription services?",
      answer:
        "Subscription services ensure regular compliance and take the hassle out of meeting important filing deadlines.",
    },
    {
      key: "2",
      question: "Can I customize my subscription plan?",
      answer:
        "Yes, we offer customizable plans to fit your business’s specific filing and compliance needs.",
    },
    {
      key: "3",
      question: "What happens if I miss a subscription payment?",
      answer:
        "We’ll notify you of any missed payments and work with you to resolve them quickly.",
    },
    {
      key: "4",
      question: "What is an independent manager or director?",
      answer:
        "An independent manager or director is a third party individual appointed to maintain neutrality and help ensure compliance.",
    },
    {
      key: "5",
      question:
        "Why is it important to stay compliant?",
      answer:
        "The best state for registration depends on your business goals. Common considerations include: Your Home State: Generally the best choice if you operate locally. Delaware: Popular for corporations due to business-friendly laws. Wyoming & Nevada: Known for lower taxes and strong privacy protections. If you're unsure, consider factors like tax regulations, compliance requirements, and future expansion plans before making a decision",
    },
    {
        key: "6",
        question:
          "How do I renew my subscription?",
        answer:
          "You can renew through our website or by contacting our support team before your subscription expires.",
      },
      {
        key: "7",
        question:
          "Can I cancel my subscription?",
        answer:
          "Yes, subscriptions can be canceled at any time, but we recommend maintaining them for uninterrupted service.",
      },
  ];

  // Split FAQs into two columns
  const firstColumnFaqs = faqItems.filter((_, index) => index % 2 === 0);
  const secondColumnFaqs = faqItems.filter((_, index) => index % 2 !== 0);

  return (
    <section className="main-section-class">
      <div className="common-faq">
        <Container>
          <div className="mb-5 mt-5">
            <CommonHeadingTitle heading={"FAQ"} />
          </div>
          <Accordion defaultActiveKey="0">
            <Row>
              <Col lg={6} md={6} sm={12}>
                {firstColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
              <Col lg={6} md={6} sm={12}>
                {secondColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
            </Row>
          </Accordion>
        </Container>
      </div>
    </section>
  );
};

export default SubscriptionFaq;
