import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import "../../CommonSection/CommonStyle/CommonStyle.css";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";

const DocumentFaq = () => {
  const faqItems = [
    {
      key: "0",
      question: "What types of documents can be retrieved?",
      answer:
        "We retrieve documents such as certificates of good standing, formation documents, and amendment documents.",
    },
    {
      key: "1",
      question: "How fast can I get my documents?",
      answer:
        "Document retrieval typically takes a few business days, but expedited services are available.",
    },
    {
      key: "2",
      question: "Why is a certificate of good standing important?",
      answer:
        "A certificate of good standing verifies your company’s compliance and active status, essential for business transactions.",
    },
    {
      key: "3",
      question: "Can I get certified copies of my documents?",
      answer:
        "Yes, we provide certified copies for all document retrieval requests.",
    },
    {
      key: "4",
      question: "Do I need a certificate for every business transaction?",
      answer:
        "Not all transactions require certificates, but they’re essential for legal and financial dealings.",
    },
    {
      key: "5",
      question:
        "What if I lose a critical business document?",
      answer:
        "Contact us ASAP. Our retrieval service helps recover important documents securely and quickly.",
    },
    {
        key: "6",
        question:
          "How often should I update my documents?",
        answer:
          "It’s best to update and review key documents yearly, or after significant business changes.",
      },
      {
        key: "7",
        question:
          "Are electronic copies as valid as physical copies?",
        answer:
          "Are electronic copies as valid as physical copies?",
      },
  ];

  // Split FAQs into two columns
  const firstColumnFaqs = faqItems.filter((_, index) => index % 2 === 0);
  const secondColumnFaqs = faqItems.filter((_, index) => index % 2 !== 0);

  return (
    <section className="main-section-class">
      <div className="common-faq">
        <Container>
          <div className="mb-5 mt-5">
            <CommonHeadingTitle heading={"FAQ"} />
          </div>
          <Accordion defaultActiveKey="0">
            <Row>
              <Col lg={6} md={6} sm={12}>
                {firstColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
              <Col lg={6} md={6} sm={12}>
                {secondColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
            </Row>
          </Accordion>
        </Container>
      </div>
    </section>
  );
};

export default DocumentFaq;
