import React from 'react';

const FeatureItem = ({ number, title, description, isEven }) => {
  return (
    <div className={`flex col-5 flex-col ${isEven ? 'p-0 w-6/12' : 'ml-5 w-6/12'} max-md:ml-0 max-md:w-full py-0`}>
      <div className={`flex flex-col grow  self-stretch pb-20 ${isEven ? 'border-r border-gray-300 border-solid' : ''} text-slate-700 max-md:mt-10 max-md:max-w-full`}>
        <div className="justify-center self-start px-4 py-3 text-lg font-semibold leading-5 text-white uppercase whitespace-nowrap bg-web-color rounded-[4px]">
          {number}
        </div>
        <h3 className="mt-7 text-2xl font-medium tracking-tighter capitalize max-md:max-w-full">
          {title}
        </h3>
        <p className="mt-2.5 text-lg font-light max-md:max-w-full">
          {description}
        </p>
      </div>
    </div>
  );
};

export default FeatureItem;