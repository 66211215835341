import React from 'react'
import Porfile from './Profile'
import SideBarMenu from '../common/Sidebar'

const ProfileComp = () => {
    return (
        <div className='grid'>
           
            <div className='col-12'>
                <Porfile />
            </div>
           
        </div>
    )
}

export default ProfileComp