import React from 'react'
import Header from './Header'
import Benefits from './Benefits'
import Steps from './Steps'

import FAQ from './Fact'
import ContactForm from '../ContentService/ContactForm'
import CallToAction from '../WebsiteComapny/CallToAction'


const callToActionData = {
    title: " Ready to kick off your new business endeavors?",
    description: " Get started with your business registration process today.",
    buttonText: "Register now",
    path: ""
}

const RegistredAgentMain = () => {
    return (
        <div className='mt-24'>
            <main className="flex flex-col items-center px-80 pt-16 pb-20 max-md:px-5">
                <div className="flex flex-col px-5 w-full max-w-[1240px] max-md:max-w-full">
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <Header />
                    </div>
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <Benefits />
                    </div>
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <Steps />
                    </div>

                    {/* <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <CallToAction callToActionData={callToActionData} />
                    </div> */}
                    <div className="flex flex-col px-8 website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <p className='contact-form-haeding-main-text1'>Want to know more about our <span className='contact-form-haeding-main-text2'>registered agent services</span></p>
                        <ContactForm />
                    </div>
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <FAQ />
                    </div>
                </div>
            </main>
        </div>
    )
}

export default RegistredAgentMain