import React, { useState } from 'react';
import ClientInvoicePaymentTable from './ClintInvoicePaymentTable';


const ClientInvoicePayment = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [totalCount, setTotalCount] = useState(0);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const clearFilter = () => {
        setSearchQuery('');
    };

    return (
        <div className="main-class-data-table">
            <div className="d-md-flex justify-content-between">
                <div className="d-flex mt-3">
                    <h3 className="table-heading me-2">Client Invoice Payments</h3>
                    <div>
                        <span className="count-class">{totalCount}</span>
                    </div>
                </div>
            </div>
            <div className="grey-border"></div>
            <ClientInvoicePaymentTable searchQuery={searchQuery} setTotalCount={setTotalCount} />
        </div>
    );
};

export default ClientInvoicePayment;
