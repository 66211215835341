import React, { useContext, useEffect, useRef, useState } from "react";
import AccountSummary from "./AccountSummary";
import ServiceCard from "./ServiceCard";
// import TabNavigation from "./TabNavigation";
import NotificationBanner from "./NotificationBanner";
import WelcomeSection from "./WelcomeSection.js";
import ServiceSection from "./ServiceSection";
import ContactService from "../../services/ContactService.js";
import UserServices from "../../services/userService.js";
import authContext from "../../common/authContext.js";
import { Toast } from "primereact/toast";
import InvoicePayment from "./DataTable/InvoicePayment/InvoicePayment.js";
import MySubscription from "./DataTable/MySubscription/MySubscription.js";
import TourOneModal from "../TourGuideComponent/TourOneModal.js";
import OrderServices from "../../services/orderServices.js";
import { Col, Row } from "react-bootstrap";

function DashboardMain() {
  const {
    setFeedbackData,
    accountManagerId,
    setAccountManagerId,
    clearData,
    setFilterStatusId,
    setRedirectedData,
    showModal,
    setShowModal,
  } = useContext(authContext);

  const user = JSON.parse(localStorage.getItem("user"));
  const toast = useRef(null);

  const [count, setCount] = useState(null);

  useEffect(() => {
    clearData();
    setFilterStatusId(null);
    setRedirectedData(null);
    if (user) {
      UserServices.getEmployeeByUserId(user.id)
        .then((res) => {
          console.log(res);
          if (res.data) {
            setAccountManagerId(res.data.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // if (toast.current !== undefined) {
    //   if (toast.current !== undefined) {
    //     toast.current.show({ severity: 'warn', summary: 'Failed to upload', detail: "Something went wrong", sticky: true });

    //   }
    // }
  }, []);

  useEffect(() => {
    if (user) {
      OrderServices.getLoginData(user?.username)
        .then((res) => {
          setCount(res.data);
        })
        .catch((error) => {
          console.error("Error fetching login data:", error);
        });
    }
  }, []);

  return (
    <div className=" bg-gray-50">
      <Toast ref={toast} position="bottom" />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} className="mt-3">
          <WelcomeSection />
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={9}>
          <ServiceSection />
          {/* <hr className="mt-6 w-full bg-gray-200 min-h-[1px] max-md:max-w-full" /> */}
          <div className="mt-4 px-4">
            <InvoicePayment />
          </div>
          <div className="mt-4 px-4">
            <MySubscription />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
          <div className="me-4">
            <AccountSummary />
          </div>
        </Col>
      </Row>
      {count && typeof count === "number" && count <= 2 && (
        <TourOneModal show={showModal} onHide={() => setShowModal(false)} />
      )}
    </div>
  );
}

export default DashboardMain;
