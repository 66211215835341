import React from 'react';
import FeatureCard from './FeatureCard';

 // Import JSON data


 const complianceData = {
  "pageTitle": "Document Retrieval Services",
  "pageDescription": "Document retrieval services provide you with certified copies of critical business documents, including certificates of good standing, formation documents, and amendment documents. This ensures you always have access to important paperwork for compliance and operational needs.",
  "buttonText": "Get Quote",
  "features": [
    
    {
      "title": "Certificate of Good Standing",
      "description": "A document issued by a state that certifies a business is compliant with state regulations. It may be required for various transactions, including loans and contracts.",
      "image": "https://example.com/images/annual-report.jpg"
    },
    {
      "title": "Formation Document",
      "description": "The official documents filed to establish a business entity, such as Articles of Incorporation or Organization. These documents are essential for verifying the existence of the entity.",
      "image": "https://example.com/images/reinstatement.jpg"
    },
    {
      "title": "Amendment Document",
      "description": "Documentation related to changes made to an existing business entity, such as changes in structure, ownership, or operational details. This serves as a legal record of amendments.",
      "image": "https://example.com/images/biennial-report.jpg"
    },
    
  ]
}


function DocumentRetrievalService() {
  const { pageTitle, pageDescription, buttonText, features } = complianceData;

  return (
    <main className="flex flex-col justify-center p-2.5 w-full max-md:max-w-full">
      <section className="flex flex-col justify-center pb-5 w-full max-md:max-w-full">
        <header className="flex flex-col w-full text-5xl font-medium tracking-tighter leading-tight text-center text-blue-700 max-md:max-w-full">
          <h1 className="px-64 w-full max-md:px-5 max-md:max-w-full">
            {pageTitle}
          </h1>
        </header>
        <p className="mt-5 px-5 w-full text-xl text-center text-slate-800 max-md:max-w-full">
          {pageDescription}
        </p>
        <div className="flex flex-col mt-5 w-full max-md:max-w-full">
          <div className="flex flex-wrap gap-8 w-full min-h-[271px] max-md:max-w-full">
            {features.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </div>
        </div>
      </section>
      <div className="flex flex-col items-center px-32 pt-9 w-full text-base font-medium leading-none text-center text-white max-md:px-5 max-md:max-w-full">
        <button className="px-7 py-3.5 bg-blue-700 rounded shadow-[0px_0px_10px_rgba(0,0,0,0.5)] max-md:px-5">
          {buttonText}
        </button>
      </div>
    </main>
  );
}

export default DocumentRetrievalService;
