import React, { useState } from 'react'
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';

const ProtectedByValidUser = ({ Componet }) => {
    const navigate = useNavigate()
    let newObject = window.localStorage.getItem("user");
    let user = Object.assign({}, JSON.parse(newObject));
    const [isLogedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {
        if (newObject) {
            setIsLoggedIn(true)
        }
        else {
            navigate('/signin')
        }
    }, [newObject])
    if (isLogedIn) {
            return <Componet />
    } else {
        navigate('/signin')
    }

}

export default ProtectedByValidUser