import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet"; // Import Leaflet for setting marker icons
import propertiesApp from "./propertiesApp";

const API_KEY = "YOUR_LOCATIONIQ_API_KEY"; // Replace with your LocationIQ API Key

// Fix missing marker icon issue
const customIcon = new L.Icon({
  iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const RecenterMap = ({ location }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([location.lat, location.lng], map.getZoom());
  }, [location, map]);
  return null;
};

const LeafletMap = ({ address }) => {
  const [location, setLocation] = useState({ lat: 40.7128, lng: -74.006 }); // Default: New York
  const [fullAddress, setFullAddress] = useState(address);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (address) {
      fetch(`https://us1.locationiq.com/v1/search.php?key=${propertiesApp.maps_api_key}&q=${encodeURIComponent(address)}&format=json`)
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 0) {
            setLocation({ lat: parseFloat(data[0].lat), lng: parseFloat(data[0].lon) });
            setFullAddress(data[0].display_name);
          }
        })
        .catch((error) => console.error("Error fetching location:", error));
    }
  }, [address]);

  return (
    <MapContainer center={[location.lat, location.lng]} zoom={13} style={{ height: "400px", width: "100%" }}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <RecenterMap location={location} />
      <Marker
        position={[location.lat, location.lng]}
        icon={customIcon} // Apply the fixed icon
        eventHandlers={{
          mouseover: () => setShowPopup(true),
          mouseout: () => setShowPopup(false),
        }}
      >
        {showPopup && (
          <Popup closeButton={false}>
            <div className="popup-card">
              <strong>Address:</strong>
              <p>{fullAddress}</p>
            </div>
          </Popup>
        )}
      </Marker>
    </MapContainer>
  );
};

export default LeafletMap;
