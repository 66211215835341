import React, { useContext, useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import AddressService from '../../services/addressService';
import ContactService from '../../services/ContactService';
import authContext from '../../common/authContext';
import MasterServices from '../../services/coreServices';
import { Toast } from 'primereact/toast';
import { showToast } from '../../utils/toastUtils';

import errorMessages, { globalMessages } from "../../utils/errorMessages";
import companyMasterService from '../../services/companyMasterService';

const AddServiceForm = ({ service, setVisible }) => {
  const { globalCompany, setIsLoading } = useContext(authContext);

  const [hubspotDealId, setHubspotDealId] = useState(null)
    const [phoneErr, setPhoneErr] = useState("");
  
      const [phone, setPhone] = useState("");

      const formatPhoneNumber = (phoneNumberString) => {
        // Remove all non-digit characters
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        
        // Ensure we have exactly 10 digits
        if (cleaned.length === 10) {
          const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
          if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
          }
        }
        // If formatting isn't possible, return the original string
        return phoneNumberString;
      };
      
    


  const countries = [
    { name: 'United States', code: 'US', dialCode: '+1' },


  ];

  const [selectedCountry, setSelectedCountry] = useState(null)
  const [allChildCompanyByParentId, setAllChildCompanyByParentId] = useState(null);
// console.log(allChildCompanyByParentId, "allChildCompanyByParentId");

  const toast = useRef(null)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    contactNumber: '',
    email: '',
    companyName: '',
    addressLine1: '',
    state: '',
    county: '',
    zip: '',
    entityType: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    contactNumber: '',
    email: '',
    zip: ''
  });

  function extractTenDigitPhoneNumber(input) {
    if (typeof (input) === "string") {
      const temp3 = input.split(" ")
      if (Array.isArray(temp3)) {
        console.log(temp3)
        if (temp3.length > 0) {
          return (temp3.slice(1).join(" "))
        }
      }
    }

  }

  const user = JSON.parse(localStorage.getItem('user'));
  const [allCounty, setAllCounty] = useState([]);
  const [allState, setAllState] = useState([]);
  const [allEntity, setAllEntity] = useState([]);

  const [dataId, setDataId] = useState(null)

  function formatLabel(label) {
    return label
      .replace(/([A-Z])/g, ' $1')
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase());
  }
  
  useEffect(()=> {
    companyMasterService
          .getAllChildCompanyByParentId(globalCompany?.companyDetails?.id)
          .then((res) => {
            
            if (res.data !== "") {
              let data = res.data;
              setAllChildCompanyByParentId(res.data);
              
            } 
          })
          .catch((err) => {
            
            // setShowSkeleton(true);
          });
  }, []);

  useEffect(() => {
    setSelectedCountry("+1")
    if (user && globalCompany) {
      setIsLoading(true)
      ContactService.getAllContactByUserId(user?.id)
        .then((res) => {
          const data = res.data;
          setIsLoading(false)
         
          if (res.data) {
            const contactDetails = res.data?.contactDetails || {};
            setDataId(contactDetails.id)
            setHubspotDealId(contactDetails.hubspotId)
            setFormData((prevData) => ({
              ...prevData,
              firstName: contactDetails.firstName || '',
              lastName: contactDetails.lastName || '',
              email: contactDetails.emailId || '',
              contactNumber: contactDetails.mobileNo || '',
              companyName: globalCompany && globalCompany.companyDetails ? globalCompany.companyDetails.companyName : "" || '',
            }));
          }

        })
        .catch((err) => {
          
          setIsLoading(false)
        });
    }
  }, []);


  useEffect(() => {
    if (dataId) {
      AddressService.getAllAddressByContactId(dataId)
        .then((res) => {
          if (res.data !== "") {
            const tempArr = res.data;
            const temp = tempArr.filter((val) => val.addressType.type === "CONTACT");
            if (temp.length > 0) {
              const { addressLine1, addressLine2, city, postalCode, stateId } = temp[0];
              setFormData((prevData) => ({
                ...prevData,
                addressLine1,
                city,
                zip: postalCode
              }));
              const temps = allState.find((val) => val.id === stateId);
              if (temps) {
                setFormData((prevData) => ({
                  ...prevData,
                  state: temps
                }));
              }
            }
          }
        })
        .catch((err) => {
          
        });
    }
  }, [dataId]);

  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;

    let inputValue = value.replace(/\D/g, ""); // Remove non-digit characters

    if (inputValue.length > 10) {
        inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }

    let formattedValue = inputValue;
    if (inputValue.length === 10) {
        formattedValue = inputValue.replace(
            /(\d{3})(\d{3})(\d{4})/,
            "($1) $2-$3"
        );
    }

    // Update input field value and state
    e.target.value = formattedValue;
    setPhone(formattedValue);

    // Check if input is empty
    if (value.trim() === "") {
        setPhoneErr(errorMessages.phoneRequired);
        return; // Stop further execution
    }

    

    // Validate phone number format only if length is 10
    if (inputValue.length <= 9) {
        if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formattedValue)) {
            setPhoneErr(errorMessages.phoneValidation);
        }
    }
};

useEffect(() => {
  if (formData && formData.contactNumber) {
    const formattedPhone = formatPhoneNumber(formData.contactNumber);
    setPhone(formattedPhone);
  }
}, [formData]);



  useEffect(() => {
    AddressService.getAllStates()
      .then((res) => {
        if (Array.isArray(res.data)) {
          setAllState(res.data);
        }
      })
      .catch((err) => {
        
      });
  }, []);

  useEffect(() => {
    if (formData.state) {
      AddressService.getAllCountyByState(formData.state.id)
        .then((res) => {
          if (Array.isArray(res.data)) {
            setAllCounty(res.data);
          }
          else{
            setAllCounty([])
          }
        })
        .catch((err) => {
          
        });
    } else {
      setAllCounty([]);
    }
  }, [formData.state]);

  useEffect(() => {
    MasterServices.getAll("/core/api/entityTypeMaster/getAll")
      .then((res) => {
        if (Array.isArray(res.data)) {
          setAllEntity(res.data);
        }
      })
      .catch((err) => {
        
      });
  }, []);




  const onCountryChange = (e) => {
    setSelectedCountry(e.value);

  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));

  //   if (name === 'state') {
  //     setFormData((prev) => ({
  //       ...prev,
  //       county: '',
  //       state: value,
  //     }));
  //   }

  //   if (name === 'entityType') {
  //     setFormData((prev) => ({
  //       ...prev,
  //       entityType: value,
  //     }));
  //   }

  //   // Clear errors when the user starts typing
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: '',
  //   }));
  // };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  
    if (name === "state") {
      setFormData((prev) => ({
        ...prev,
        county: "",
        state: value,
      }));
    }
  
    if (name === "entityType") {
      setFormData((prev) => ({
        ...prev,
        entityType: value,
      }));
    }
  
    // Zip code validation
    if (name === "zip") {
      const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
  
      setErrors((prevErrors) => ({
        ...prevErrors,
        zip: numericValue === ""
          ? "Postal code required"
          : numericValue.length < 5
          ? "Postal code must be 5 digits"
          : "",
      }));
  
      setFormData((prev) => ({
        ...prev,
        zip: numericValue,
      }));
    }
  
    // Clear errors when the user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    const requiredFields = ['firstName', 'lastName', 'contactNumber', 'email', 'companyName', 'addressLine1', 'state', 'zip', 'message'];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${formatLabel(field)} is required.`;
        isValid = false;
      }
    });

    // const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    // if (!phoneRegex.test(formData.contactNumber)) {
    //   newErrors.contactNumber = 'Invalid phone number format. Use (XXX) XXX-XXXX.';
    //   isValid = false;
    // }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email address.';
      isValid = false;
    }

    const zipRegex = /^\d{5}$/;
    if (!zipRegex.test(formData.zip)) {
      newErrors.zip = 'Invalid ZIP code. Must be 5 digits.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const demo = {
      "properties": {
        "hs_lead_name": "string",
        "first_name": "string",
        "last_name": "string",
        "company_name": "string",
        "county": "string",
        "email": "string",
        "entity_type": "string",
        "address": "string",
        "message": "string",
        "phone_number": "string",
        "state": "string",
        "zip_code": "string"
      },
      "associations": [
        {
          "types": [
            {
              "associationCategory": "string",
              "associationTypeId": 0
            }
          ],
          "to": {
            "id": "string"
          }
        }
      ]
    }

    const actualdata = {
      "properties": {
        "hs_lead_name": service,
        "first_name": formData.firstName,
        "last_name": formData.lastName,
        "company_name": formData.companyName,
        "county": formData.county,
        "email": formData.email,
        "entity_type": formData.entityType,
        "address": formData.addressLine1,
        "message": formData.message,
        "phone_number": parseInt(formData.contactNumber.replace(/\D/g, ""), 10),
        "state": formData.state ? formData.state.stateFullDesc : "",
        "zip_code": (formData.zip),
        "associations": [
          {
            "types": [
              {
                "associationCategory": "string",
                "associationTypeId": 0
              }
            ],
            "to": {
              "id": "string"
            }
          }
        ]
      }
    }
    if (validateForm() && hubspotDealId) {
      
      setIsLoading(true)
      MasterServices.saveLead(actualdata, hubspotDealId)
        .then((res) => {
          console.log(res)
          formData.state = ""
          formData.entityType = ""
          formData.county = ""
          formData.message = ""
          setIsLoading(false)
          // if (toast.current !== undefined) {

            // toast.current.show({ severity: 'success', summary: 'Success', detail: 'Your request has been sent successfully', life: 2800 });
            
          // }
          showToast("success",`Your request has been successfully submitted. The vState team will contact you shortly.

`)
          setTimeout(() => {
            setVisible(false)
          }, 3000);
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
          // if (toast.current !== undefined) {

          //   toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
          // }
          showToast("error",`Something went wrong`)

        })
    } else {
      
      console.log(hubspotDealId)
    }
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '28px' }} />
          <div >{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
        <div className='webapp-sm-size'>{option.code}</div>
      </div>
    );
  };



  return (
    <div className="contact-form-container px-6 py-4">
      <Toast ref={toast} />
      <div className="contact-form-main p-4 border-round">
        <form onSubmit={handleSubmit}>
          {console.log(formData, "workingformData")}
          <div className="">
            <h3 className="contact-form-header-text">Contact Information</h3>
            <div className="grid">
              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <InputText
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      disabled
                      placeholder=""
                      id="floating_outlined272828"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                    />
                    <label htmlFor="floating_outlined272828"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      First Name <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.firstName && <small className="error-msg font-fam-for-all">{errors.firstName}</small>}
                </span>
              </div>
              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <InputText
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      disabled
                      placeholder=""
                      id="floating_outlined272829"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                    />
                    <label htmlFor="floating_outlined272829"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Last Name <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.lastName && <small className="error-msg font-fam-for-all">{errors.lastName}</small>}
                </span>

              </div>
              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <InputText
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder=""
                      disabled
                      id="floating_outlined272820"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                    />
                    <label htmlFor="floating_outlined272820"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Email <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.email && <small className="error-msg font-fam-for-all">{errors.email}</small>}
                </span>
              </div>

              <div className="col-6">
                <div className="">
                  <div className="field py-0">
                    <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}>
                      <Dropdown
                        value={selectedCountry}
                        options={countries}
                        onChange={onCountryChange}
                        resetFilterOnHide
                        optionLabel="dialCode"
                        optionValue='dialCode'
                        disabled
                        valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}

                        style={{ width: '160px', height: "40px", borderRadius: '6px 0px 0px 6px' }}
                        className="p-dropdown font-fam-for-all"
                      />
                      <span className="" style={{ width: "100%" }}>
                        <div class="relative custom-input-mask">
                       { console.log(formData, 'jhioioji')}
                          {/* <InputMask
                            name="contactNumber"
                            defaultvalue={formData.contactNumber}
                            onChange={handleChange}
                            mask="(999) 999-9999"
                            disabled
                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px', width: "100%" }}
                            placeholder=""
                            id="floating_outlined272821"
                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                          /> */}
                           {/* <input
                          type="text"
                          id="floating_outlined255"
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                            width: "100%",
                          }}
                          maxLength={50}
                          value={formData.contactNumber}
                          placeholder=""
                          disabled
                          name="cityName"
                        /> */}

                            <input
                                  type="text"
                                  id="floating_outlined255"
                                  className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                                  style={{
                                    height: "40px",
                                    borderRadius: "0px 6px 6px 0px",
                                    width: "100%",
                                  }}
                                  maxLength={14} 
                                  value={phone}
                                  // placeholder="(123) 456-7890"
                                  disabled
                                  name="cityName"
                                />
                          <label htmlFor="floating_outlined255"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Phone Number <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {errors.contactNumber && <small className="error-msg font-fam-for-all">{errors.contactNumber}</small>}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="">
            <h3 className="contact-form-header-text">Entity Information</h3>
            <div className="grid">
              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    {/* <Dropdown
                      name="Service Required"
                      value={service}
                      disabled
                      // onChange={handleChange}
                      options={allEntity}
                      resetFilterOnHide
                      optionLabel="entityShortName"
                      optionValue='entityShortName'
                      style={{ height: "38px" }}
                      placeholder="Select Entity Type"
                      className='w-full'
                    /> */}
                    {/* <InputText disabled value={service}
                        className="p-dropdown font-fam-for-all"
                        >
                    </InputText> */}

                      <InputText
                      name="companyName"
                      value={service}
                      // placeholder=""
                      disabled
                      id="floating_outlined272822"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                    />
                    <label htmlFor="floating_outlined272823"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Service Required <span className="form-field-mandatory"></span>
                    </label>
                  </div>
                  {errors.entityType && <small className="error-msg font-fam-for-all">{errors.entityType}</small>}
                </span>
              </div>
              <div className="col-6">

                <span className=" mr-3">
                  {/* <div class="relative">
                    <InputText
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      placeholder=""
                      // disabled
                      id="floating_outlined272822"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                    />
                    <label htmlFor="floating_outlined272822"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Company Name <span className="form-field-mandatory">*</span>
                    </label>
                  </div> */}
                  <div className="relative">

                    <Dropdown
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      options={allChildCompanyByParentId}
                      optionValue="companyName"
                      optionLabel="companyName"
                      placeholder="Select a company"  
                      style={{ width: '100%', height: "40px", borderRadius: '6px 0px 0px 6px' }}
                      className="p-dropdown font-fam-for-all"
                    />
                    <label 
                      htmlFor="companyName" 
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Company Name <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.companyName && <small className="error-msg font-fam-for-all">{errors.companyName}</small>}
                </span>
              </div>

              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <InputText
                      name="addressLine1"
                      value={formData.addressLine1}
                      onChange={handleChange}
                      placeholder=""
                      id="floating_outlined272824"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                    />
                    <label htmlFor="floating_outlined272824"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Address <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.addressLine1 && <small className="error-msg font-fam-for-all">{errors.addressLine1}</small>}
                </span>
              </div>

              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <InputText
                      name="zip"
                      maxLength={5}
                      value={formData.zip}
                      onChange={handleChange}
                      placeholder=""
                      id="floating_outlined272827"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                    />
                    <label htmlFor="floating_outlined272827"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Zip Code <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.zip && <small className="error-msg font-fam-for-all">{errors.zip}</small>}
                </span>
              </div>

              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <Dropdown
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      resetFilterOnHide
                      options={allState}
                      style={{ height: "38px" }}
                      filter
                      optionLabel="stateFullDesc"
                      placeholder="Select State"
                      className='w-full'

                    />
                    <label htmlFor="floating_outlined272825"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      State <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.state && <small className="error-msg font-fam-for-all">{errors.state}</small>}
                </span>
              </div>
              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <Dropdown
                      name="county"
                      value={formData.county}
                      onChange={handleChange}
                      options={allCounty}
                      resetFilterOnHide
                      style={{ height: "38px" }}
                      filter
                      optionLabel="countyName"
                      optionValue='countyName'
                      placeholder="Select County"
                      className='w-full'

                      disabled={!formData.state}
                    />
                    <label htmlFor="floating_outlined272826"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      County <span className="form-field-mandatory"></span>
                    </label>
                  </div>
                  {errors.county && <small className="error-msg font-fam-for-all">{errors.county}</small>}
                </span>
              </div>

            </div>
          </div>

          <div className="mb-4">

            <span className=" mr-3">
              <div class="relative">
                <h3 className="contact-form-header-text">Message <span className="form-field-mandatory">*</span></h3>
                <InputTextarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={7}
                  placeholder="Type your message"
                  className='w-full'
                // id="floating_outlined2728289"
                // className="block w-full px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                />
                {/* <label htmlFor="floating_outlined2728289"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Message <span className="form-field-mandatory">*</span>
                </label> */}
              </div>
              {errors.message && <small className="error-msg font-fam-for-all">{errors.message}</small>}
            </span>
          </div>

          <div className="mt-4">
            <Button label="Submit" className="vstate-button w-full" type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddServiceForm;
