import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useContext, useEffect, useState } from 'react'
import OrderServices from '../../services/orderServices'
import MasterServices from '../../services/coreServices'
import authContext from '../../common/authContext'
import { useNavigate } from 'react-router-dom'
import { formatISODate } from '../../utils/dateUtils'
import { Skeleton } from 'primereact/skeleton'

const CompanyOrders = ({ data }) => {

    const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh, redirectedData, setRedirectedData } = useContext(authContext);


    const [products, setProducts] = useState([

    ])

    const [selectedProduct, setSelectedProduct] = useState(null)

    const navigate = useNavigate()

    const [allStatus, setAllStatus] = useState([])

    const [showSkeleton, setShowSkeleton] = useState(false)

    useEffect(() => {
        MasterServices.getAllStatus()
            .then((res) => {
                if (res.data !== "") {
                    setAllStatus(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])


    const getStatus = (id) => {
        if (allStatus.length > 0) {
            const temp = allStatus.find((val) => val.id === id)
            if (temp) {
                return temp.statusName;
            }
            else {
                return null;
            }

        }
        else {
            return null;
        }
    }

    useEffect(() => {
        if (data) {

            OrderServices.getAllOrderByCompanyId(data.id)
                .then((res) => {
                    console.log(res)
                    if (res.data !== "") {
                        setShowSkeleton(true)
                        setProducts(res.data)
                    }
                    else{
                        setShowSkeleton(true)
                        setProducts([]) 
                    }
                })
                .catch((err) => {
                    console.log(err)
                    setShowSkeleton(true)
                })
        }
    }, [data, isTabRefresh])


    const handleView = (rowData) => {
        console.log(rowData)
        setRedirectedData(rowData)
        navigate("/order")
    }

    const iconBodyTemplate = (rowdata) => {
        return (
            <>
                <button className="pi pi-eye-slash image-btn-icon" data-toggle="tooltip" data-placement="bottom" title="View Order" onClick={() => handleView(rowdata)} ></button>
                {/* <button className="pi pi-trash image-btn-icon-delete" data-toggle="tooltip" data-placement="bottom" title="Delete" ></button> */}
            </>
        )
    }

    const statusBody = (rowdata) => {
        return (
            <div className='badge-container'>
                <div className='badge-main grid' style={{ background: '#ECFDF3' }}>
                    <div className="col-12 flex align-items-center p-0">

                        <span className='badge-label ml-1' style={{ color: '#027A48' }}>{rowdata.statusName}</span>
                    </div>

                </div>
            </div>
        )
    }

    const orderBody = (rowdata) => {
        return (
            <p className=' srch-cand-text1' >{`${rowdata.orderNumber !== "" ? rowdata.orderNumber : rowdata.orderId}`}</p>

        )
    }


    const orderdateBody = (rowdata) => (
        <div className='webapp-sm-size'>
            {rowdata.orderDate ? formatISODate(rowdata.orderDate) : ""}
        </div>
    )

    const items = Array.from({ length: 5 }, (v, i) => i);

    console.log(products)


    return (
        <div>
            {
                showSkeleton ? <DataTable value={products} scrollable emptyMessage='No Order Found'
                    dataKey='name' paginator rows={25} rowsPerPageOptions={[5, 10, 25, 50]}
                    className='font-fam-for-all datatable-sm'
                    globalFilterFields={["djjddjjd"]}>
                    <Column header="Order Number" body={orderBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                    <Column field='displayName' header="Entity Name" className='font-fam-for-all  webapp-sm-size'></Column>
                    <Column field='stateName' header="State" className='font-fam-for-all  webapp-sm-size'></Column>
                    <Column field='orderDate' header="Order Date" body={orderdateBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                    <Column  header="Status" body={statusBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                    <Column body={iconBodyTemplate} header="Action" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>


                </DataTable> : <DataTable  value={items} rows={5} rowsPerPageOptions={[5, 10, 25, 50]} inline paginator emptyMessage='No Candidate Found'
                    selectionMode="single"
                     metaKeySelection={false}>
                    <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                </DataTable>
            }


        </div>
    )
}

export default CompanyOrders;