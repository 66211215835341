import axios from "axios";
import authHeader, { initAuth } from "./authHeader";
import encryption from "./CryptoClass";
import axiosInstance from "../utils/axiosInterceptor";
import propertiesApp from "../utils/propertiesApp";







const getComponent = () => {
    // try {
    //     return axios.get(`${propertiesApp.strapiUrl}/content-type-builder/components`,
    //     { headers: initAuth() }
    //     )
    // } catch (error) {
    //     return []
    // }
    const dataToEncrypt = {
        endpoint: `/contact/api/getAll/components`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        // return axios.get(`/decrypt?data=${dataToEncrypt}`,
        // return axios.post(`/decrypt`,dataToEncrypt,
        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}



const getConfiguration = (model) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/configuration/${model}`,
        payload: null,
        type: "get",
        xTenanatId: "wipro-rshopizer",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)


    try {

        // return axios.get(`/decrypt?data=${dataToEncrypt}`,
        return axios.post(`/decrypt`, dataToEncrypt,

            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}



const createModeldata = (data, endpoint) => {
    const dataToEncrypt = {
        endpoint: `/authentication/api/user/signup`,
        payload: data,
        type: "post",
        auth: null
    };



    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        // return axios.get(`/decrypt?data=${dataToEncrypt}`,
        return axios.post(`/decrypt`, dataToEncrypt,

            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }


}



const createPayment = (data) => {
    try {
        return axios.post("http://localhost:8000/create-payment", data,
            { headers: authHeader() }
        )
    } catch (error) {
        if (error.response?.status === 401) {
            return error.response.status;
        }
    }
}






const createAlabamaLLC = (data, model) => {

    // try {
    //         return axios.post(`/api/save/${model}`,data,

    //     { headers: initAuth() }
    //     )
    // } catch (error) {
    //     return []
    // }
    const dataToEncrypt = {
        endpoint: `/contact/api/save/${model}`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)


    try {

        // return axios.get(`/decrypt?data=${dataToEncrypt}`,
        return axios.post(`/decrypt`, dataToEncrypt,

            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const updateAlabamaLLC = (data, model, id) => {

    // try {
    //     return axios.put(`/api/update/${model}/${id}`, data,
    //     { headers : authHeader() }
    //     )
    // } catch (error) {
    //     if (error.response?.status === 401) {
    //         return error.response.status;
    //      }
    // }
    const dataToEncrypt = {
        endpoint: `/contact/api/update/${model}/${id}`,
        payload: data,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)


    try {

        // return axios.get(`/decrypt?data=${dataToEncrypt}`,
        return axios.post(`/decrypt`, dataToEncrypt,

            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const CreditCardServices = {
    createPayment,
    createModeldata,
    createAlabamaLLC,
    updateAlabamaLLC,
    getConfiguration,
    getComponent,

}


export default CreditCardServices;