import React from 'react';

const sections = [
    {
        id: 1,
        title: "Information We Collect",
        content: `We may collect the following types of information:
- Personal Information: Name, email address, phone number, and payment information when you create an account or place an order.
- Usage Data: Information about how you use our website, including your IP address, browser type, and pages visited.`
    },
    {
        id: 2,
        title: "How We Use Your Information",
        content: `We use your information for:
- Providing and improving our services.
- Processing transactions and managing your account.
- Communicating with you, including sending updates and promotional materials.
- Analyzing usage to enhance user experience.`
    },
    {
        id: 3,
        title: "Information Sharing",
        content: `We do not sell or rent your personal information to third parties. We may share your information with:
- Service providers who assist us in operating our website and conducting our business.
- Legal authorities when required by law or to protect our rights.`
    },
    {
        id: 4,
        title: "Data Security",
        content: `We implement reasonable security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is 100% secure.`
    },
    {
        id: 5,
        title: "Your Rights",
        content: `You have the right to:
- Access, correct, or delete your personal information.
- Opt out of marketing communications at any time.
- Withdraw consent for data processing where applicable.`
    },
    {
        id: 6,
        title: "Cookies",
        content: `Our website uses cookies to enhance your experience. You can manage cookie preferences through your browser settings.`
    },
    {
        id: 7,
        title: "Changes to This Policy",
        content: `We may update this policy periodically. Changes will be posted on this page with an updated effective date.`
    },
    {
        id: 8,
        title: "Contact Us",
        content: `For questions or concerns regarding this policy, please contact us at:
- Email: support@vstatefilings.com
- Phone: (866) 638-3309 | (718) 569-2703

By using our website, you consent to this privacy policy.`
    }
];

function PrivacySection() {
    return (
        <main className="flex flex-col justify-center px-80 pt-16 max-md:px-5 privacy-policy-bg">
            <article className="flex flex-col w-full max-md:max-w-full">
                <header className="flex flex-wrap gap-5 items-center w-full text-slate-800 max-md:max-w-full">
                    <h1 className="flex-1 shrink self-stretch my-auto text-5xl font-semibold basis-0 max-md:max-w-full">
                        Privacy Policy
                    </h1>
                    {/* <time className="self-stretch my-auto text-xl">
                        Effective Date: [Date]
                    </time> */}
                    <p className="w-full text-xl leading-9 text-gray-800 max-md:max-w-full">
                        At vState Filings, we are committed to protecting your privacy. This
                        policy outlines how we collect, use, and safeguard your information.
                    </p>
                </header>
                {sections.map((section) => (
                    <section className="flex flex-col mt-2 w-full max-md:max-w-full" key={section.id}>
                        <div className="flex flex-col justify-center px-2.5 w-full max-md:max-w-full">
                            <div className="flex flex-col justify-center pb-2 w-full max-md:max-w-full">
                                <h2 className="w-full text-3xl font-medium tracking-tighter text-blue-700 leading-[53px] max-md:max-w-full">
                                    {section.title}
                                </h2>
                                <div className="flex flex-col mt-2 w-full text-xl leading-9 text-gray-800 max-md:max-w-full">
                                    <ul className="pl-6">
                                        {section.content.split(/\n/).map((line, index) => (
                                            <li
                                                key={index}
                                                className="mb-2"
                                                style={{
                                                    listStyleType: index === 0 ? "disc" : "none",
                                                    paddingLeft: index === 0 ? "0" : "20px",
                                                }}
                                            >
                                                {line.trim()}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                ))}
            </article>
        </main>
    );
}

export default PrivacySection;

