import React from 'react'
import Header from '../RegistredAgentService/Header'
import Partners from '../Partners'
import ComplianceServices from './Complianc Service/ComplianceServices'
import ComplianceSection from './Compliance/ComplianceSection.js'
import FAQ from '../RegistredAgentService/Fact.js'
import ContactForm from './ContactForm.js'
import UccFilingsServices from './Complianc Service/UccFilingsService.js'
import UccFilingsPic from "../../../Assets/Images/Ucc filings.png"

const complianceData={
    "sectionTitle": "Why do UCC Filings Matter?",
    "sectionSubtitle": "Compliance Services are essential for your business.",
    "items": [
      {
        "title": "Protect Business Transactions",
        "description": "UCC filings secure your interests in commercial transactions, making it clear who holds rights to assets or collateral."
      },
      {
        "title": "Legal Confidence",
        "description": "Whether filing or searching for liens, UCC filings ensure that your transactions are protected and verified."
      },
      {
        "title": "Risk Mitigation",
        "description": "By filing UCCs, you avoid disputes over asset ownership and protect your claims in financial agreements."
      },
      {
        "title": "Comprehensive Search Solutions",
        "description": "Our UCC search services help uncover existing liens and ensure that any potential risks in transactions are identified."
      }
    ],
    "images": {
      "backgroundImage": UccFilingsPic,
      "iconImage": "https://cdn.builder.io/api/v1/image/assets/TEMP/2517a6129f7ab66094c10670002e3c726e87b73dba9f0efd07fceaf970443933?apiKey=136b58d08a644862944c0e5f22adbbe0&"
    }
  }

const UccFilingsMain = () => {
    return (
        <div className='mt-24'>
            <main className="flex flex-col items-center px-80 pt-16 pb-20 max-md:px-5">
                <div className="flex flex-col px-5 w-full max-w-[1240px] max-md:max-w-full">
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <Header />
                    </div>
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <Partners />
                    </div>
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <ComplianceSection complianceData={complianceData}/>
                    </div>
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <UccFilingsServices />
                    </div>
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <FAQ />
                    </div>
                    <div className="flex flex-col px-8 website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <p className='contact-form-haeding-main-text1'>Need help to <span className='contact-form-haeding-main-text2'>stay compliant?</span></p>
                        <ContactForm />
                    </div>
                </div>
            </main>
        </div>
    )
}

export default UccFilingsMain