import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Container, Navbar, NavDropdown } from "react-bootstrap";

import {
  faArrowRightLong,
  faEnvelope,
  faMapLocationDot,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";
import Nav from "react-bootstrap/Nav";
import {
  faFacebookF,
  faLinkedin,
  faSquareInstagram,
  faSquareXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import Logo from "./Logo";
import "./CommonSection/CommonStyle/DemoHeader.css";
import BlueButton from "./CommonSection/BlueButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function DemoHeader() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("home");

  const socialLinks = [
    {
      icon: faFacebookF,
      link: "https://www.facebook.com/RedberylTech",
      title: "Facebook",
    },
    {
      icon: faSquareInstagram,
      link: "https://www.instagram.com/info.redberyl.tech/",
      title: "Instagram",
    },
    {
      icon: faSquareXTwitter,
      link: "https://x.com/RedberylTech",
      title: "Twitter",
    },
  ];

  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  return (
    <>
      <section className="main-header">
        <div className="black-header-top">
          {/* <div className="top-header-mainnn-divvv">
            <Container>
              <div className="top-header-mainnn">
                <p className="head-toppp">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="main-head-iconnn me-2"
                  />
                  <a
                    href="mailto:info@redberyltech.com"
                    className="contact-data"
                  >
                    support@vstatefiling.com
                  </a>
                </p>
                <p className="head-toppp">
                  <FontAwesomeIcon
                    icon={faMapLocationDot}
                    className="main-head-iconnn me-2"
                  />
                  <a
                    href="https://www.google.com/maps/search/?api=1&query=507-B+Amanora+Chambers+Amanora+Mall+Hadapsar+Pune+411028"
                    target="_blank"
                    rel="noreferrer"
                    className="contact-data"
                  >
                    {" "}
                    301 Mill Road, Suite U5, Hewlett, NY 11557
                  </a>
                </p>
                <div>
                  {socialLinks.map((social, index) => (
                    <a
                      href={social.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                      title={social.title}
                      className="header-social-icon-link"
                    >
                      <FontAwesomeIcon
                        icon={social.icon}
                        className="header-scial-icon"
                      />
                    </a>
                  ))}
                </div>
              </div>
            </Container>
          </div> */}
          <div>
            <Container>
              <Navbar collapseOnSelect expand="lg">
                <Link to={"/"}>
                  <Navbar.Brand>
                    <Logo />
                  </Navbar.Brand>
                </Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse
                  id="responsive-navbar-nav"
                  className="justify-content-end"
                >
                  <Nav>
                    <Nav.Link
                      as={Link}
                      to="/"
                      className={location.pathname === "/" ? "active" : ""}
                      onClick={() => handleNavItemClick("home")}
                    >
                      Home
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to="/webcompany"
                      className={
                        location.pathname === "/webcompany" ? "active" : ""
                      }
                      onClick={() => handleNavItemClick("about")}
                    >
                      Entity Formation
                    </Nav.Link>

                    <Nav.Link
                      as={Link}
                      to="/registeredcompany"
                      className={
                        location.pathname === "/registeredcompany"
                          ? "active"
                          : ""
                      }
                      onClick={() => handleNavItemClick("webcompany")}
                    >
                      Registered Agent
                    </Nav.Link>

                    <NavDropdown title="Services" id="services-dropdown">
                      <NavDropdown.Item
                        as={Link}
                        to="/corporatefilings"
                        onClick={() => handleNavItemClick("")}
                      >
                        Corporate Filings
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as={Link}
                        to="/compliance"
                        onClick={() => handleNavItemClick("")}
                      >
                        Compliance
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to="/subscription"
                        onClick={() => handleNavItemClick("")}
                      >
                        Subscriptions
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to="/uccfilings"
                        onClick={() => handleNavItemClick("")}
                      >
                        UCC Filings
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to="/documentretrieval"
                        onClick={() => handleNavItemClick("")}
                      >
                        Document Retrieval
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to="/corporatesuplies"
                        onClick={() => handleNavItemClick("")}
                      >
                        Corporate Supplies
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link
                      as={Link}
                      to="/webcontact"
                      className={
                        location.pathname === "/webcontact" ? "active" : ""
                      }
                      onClick={() => handleNavItemClick("webcontact")}
                    >
                      Contact Us
                    </Nav.Link>
                    <div className="main-class-blue">
                      <a href="/#/signin" target="_blank">
                        <button className="blue-btnnn">
                          <span>Login/Register</span>
                        </button>
                      </a>
                      {/* <BlueButton
                        label={"Login/Register"}
                        icon={<FontAwesomeIcon icon={faArrowRightLong} />}
                        onclick={"/signin"}
                      /> */}
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Container>
          </div>
        </div>
      </section>
    </>
  );
}

export default DemoHeader;
