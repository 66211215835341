const propertiesApp={
    strapiUrl:"https://strapi.redberyltest.in",
    // strapiUrl:"http://64.202.187.199:1337/",
    strapi:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzM3MzYxMzM3LCJleHAiOjE3Mzk5NTMzMzd9.vkiZ5GPD53mSiYfhsIrn0dxvQVPNqSEOLer7KK-A7rw",
    react_host:"http://localhost:8765",
    strapi_java_host:"http://localhost:8765",
    stripe_host:"http://localhost:8000",
    chatbot_url:"https://chatbot.redberyltest.in",
    // automation_host:"http://localhost:3003",
    // quickbooks_host:"http://localhost:3003",
    // python_url:"https://chatbot.redberyltest.in",
    // automation_host:"http://localhost:3003",
    automation_host:"http://localhost:3003",
    automation_offline_host:"http://192.168.1.100:9500",
    // automation_offline_host:"https://localhost:8004",

    quickbooks_host:"http://localhost:3003",
    python_url:"https://chatbot.redberyltest.in",
    tour_url:'https://www.redberyltest.in',
    partner_code:4,
    maps_api_key:"pk.7619f6592aacb553200a15eafc918fd1"
}


export default propertiesApp;