import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { DataScroller } from "primereact/datascroller";
import { Rating } from "primereact/rating";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { Chips } from "primereact/chips";

import { Checkbox } from "primereact/checkbox";
import { Avatar } from "primereact/avatar";

import { MultiSelect } from "primereact/multiselect";
import { Accordion, AccordionTab } from "primereact/accordion";

import { SplitButton } from "primereact/splitbutton";

import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";

import { Dropdown } from "primereact/dropdown";

import { DataView } from "primereact/dataview";

import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView, TabPanel } from "primereact/tabview";
import { Sidebar } from "primereact/sidebar";
import { OverlayPanel } from "primereact/overlaypanel";
import authContext from "../common/authContext";
import { createSearchParams, useNavigate } from "react-router-dom";
import UserServices from "../services/userService";
import { RadioButton } from "primereact/radiobutton";
import Sidesbar from "../common/Sidesbar";
import Headers from "../common/Headers";
import ContactService from "../services/ContactService";
import AddCustomUser from "./AddComponents/AddCutsomUser";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import AddEmployee from "./AddComponents/AddEmployee";
import GroupDataTable from "./GroupDataTable";
import { Switch } from "@mui/material";
import errorMessages, { globalMessages } from "../utils/errorMessages";
import UserUpload from "./AddComponents/UserUpload";
import { Toast } from "primereact/toast";
import companyMasterService from "../services/companyMasterService";
import rolesMasterService from "../services/rolesMaster";
import { InputMask } from "primereact/inputmask";
import ForgotPasswordService from "../services/forgotPasswordService";
import AddressService from "../services/addressService";
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import Documents from "./Orders/Documents";
import OrderServices from "../services/orderServices";
import MasterServices from "../services/coreServices";
import ReactToPrint from "react-to-print";
import { useSearchParams } from "react-router-dom";
import { InputTextarea } from "primereact/inputtextarea";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import OrderChat from "../utils/orderFeedBackChat";
import AddOrderDocuments from "./AddComponents/AddOrderDocuments";
import { formatISODate } from "../utils/dateUtils";
import propertiesApp from "../utils/propertiesApp";
import OrderPreview from "./Orders/OrderPreview";
import CompanyKeyPersonnelDocuments from "./Company/KeyPersonnelDocuments";
import { showToast } from "../utils/toastUtils";
import ScrollToTop from "./ScrollToTop";

export default function OrderDashboard() {
  const {
    clearData,
    permissions,
    loginUserName,
    globalCompany,
    filterOrderStatusId,
    setFilterStatusId,
    isTabRefresh,
    setIsTabRefresh,
    editPayloadData,
    setEditPayloadData,
    redirectedData,
    setRedirectedData,
  } = useContext(authContext);

  console.log(permissions);

  const [userdataParam] = useSearchParams();

  const orderId = userdataParam.get("orderId");

  const orderid = userdataParam.get("orderid");

  const stripePromise = loadStripe(
    "pk_test_51PyTdiKhsNfb7YuLO8XP7SHE3FUn3rspK3sI7OTbOIulKp9XdM7WtU4gqjxiKVDVBldIKIEBvL8PLjdKkW1h8f1100bw75rZPd"
  );

  const hasPermission = (permission) => permissions.includes(permission);

  console.log(hasPermission("AllowCreateUser"));

  let newObject = window.localStorage.getItem("user");

  let updatedUser = Object.assign({}, JSON.parse(newObject));

  const [filteredData, setFilteredData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const [sortName, setSortName] = useState(null);

  const [chipsData, setChipsData] = useState([]);

  const [chipsData1, setChipsData1] = useState([]);

  const [filterChipData, setFilterChipData] = useState([]);

  const [selectedData, setSelectedData] = useState([]);

  const [allCities, setAllCities] = useState([]);

  const [activeIndex, setActiveIndex] = useState(0);

  const [filteredInput, setFilteredInput] = useState("");

  const [allOrderStatus, setAllOrderStatus] = useState([]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

    id: { value: null, matchMode: FilterMatchMode.IN },
    "orderDetails.id": { value: null, matchMode: FilterMatchMode.EQUALS },
    "contactDetails.lastName": {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
    "contactDetails.mobileNo": {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
    "contactDetails.emailId": {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
  });

  const navigate = useNavigate();

  // const [city, setCity] = useState([])

  const [exp1, setExp1] = useState(null);

  const [exp2, setExp2] = useState(null);

  const printRef = useRef();

  const [skills, setSkills] = useState([]);
  const [skillError, setSkillError] = useState("");
  const [skillARR, setSkillARR] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const [superAdminId, setSuperAdminId] = useState(null);

  const [qualification, setQualification] = useState([]);
  const [qualificationArr, setQualificationArr] = useState([]);

  const [visible, setVisible] = useState(false);

  const [visible1, setVisible1] = useState(false);

  const [visible2, setVisible2] = useState(false);

  const [visible4, setVisible4] = useState(false);

  const [visible5, setVisible5] = useState(false);

  const [visible6, setVisible6] = useState(false);

  const [visibleContent, setVisibleContent] = useState(false);

  const [allPosition, setAllPosition] = useState([]);

  const [position, setPosition] = useState(null);

  const [positionData, setPositionData] = useState(null);

  const [candidate, setCandidate] = useState(null);

  const [closeProgresbar, setCloseProgresbar] = useState(false);

  const [closeProgresbar1, setCloseProgresbar1] = useState(false);

  const [closeProgresbar3, setCloseProgresbar3] = useState(false);

  const [globalKeyValue, setGlobalKeyValue] = useState("");

  const [AllCandidate, setAllCandidate] = useState([]);

  const [showSkeleton, setShowSkeleton] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [data, setData] = useState(null);

  const [imageData, setImageData] = useState(null);

  const [statesId, setStatesId] = useState(null);

  const op = useRef(null);

  const downloadop = useRef(null);

  const toast = useRef(null);

  const [isRemoveChip, setIsRemoveChip] = useState(false);

  const [fback, setFback] = useState([]);

  const [candidateFback, setCandidateFback] = useState(null);

  const [interviewLevel1, setInterviewLevel1] = useState([]);

  const [hrPanelList, setHrPanelList] = useState([]);

  const [hrpanel, setHrPanel] = useState(null);

  const [selectedPanelData, setSelectedPanelData] = useState([]);

  const [selectedPanelList, setSelectedPanelList] = useState([]);

  const [showPanel, setShowPanel] = useState(false);

  const [globalSearchValue, setGlobalSearchValue] = useState("");

  const history = useNavigate();

  const [isShowContent, setIsShowContent] = useState(false);

  const [isShowContent1, setIsShowContent1] = useState(false);

  const [isShowContent2, setIsShowContent2] = useState(false);

  const [isShowContent3, setIsShowContent3] = useState(false);

  const [salutation, setSalutation] = useState("");

  const [firstName, setFirstName] = useState(null);

  const [firstNameErr, setFirstNameErr] = useState("");

  const [middleName, setMiddleName] = useState(null);

  const [middleNameErr, setMiddleNameErr] = useState("");

  const [lastName, setLastName] = useState(null);

  const [lastNameErr, setLastNameErr] = useState("");

  const [phoneNumber, setPhoneNumber] = useState(null);

  const [phoneErr, setPhoneErr] = useState("");

  const [altPhoneNumber, setAltPhoneNumber] = useState(null);

  const [altPhoneErr, setAltPhoneErr] = useState("");

  const [email, setEmail] = useState(null);

  const [emailErr, setEmailErr] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const [altEmail, setAltEmail] = useState("");

  const [altEmailErr, setAltEmailErr] = useState("");

  const [fax, setFax] = useState(null);

  const [faxErr, setFaxErr] = useState("");

  const [altFax, setAltFax] = useState("");

  const [altFaxErr, setAltFaxErr] = useState("");

  const [addr1, setAddr1] = useState("");

  const [addr1Err, setAddr1Err] = useState("");

  const [addr2, setAddr2] = useState("");

  const [addr2Err, setAddr2Err] = useState("");

  const [addr3, setAddr3] = useState("");

  const [region, setRegion] = useState("");

  const [regionErr, setRegionErr] = useState("");

  const [city, setCity] = useState("");

  const [cityErr, setcityErr] = useState("");

  const [states, setStates] = useState(null);

  const [statesErr, setStatesErr] = useState("");

  const [zip, setZip] = useState("");

  const [zipErr, setZipErr] = useState("");

  const [pageRefresh, setPageRefresh] = useState(false);

  const [dataId, setDataId] = useState(null);

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [rolesName, setRolesName] = useState("");

  const [userId, setUserId] = useState(null);

  const [passwordErr, setPasswordErr] = useState("");

  const [oldpasswordErr, setOldPasswordErr] = useState("");

  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [passwordType, setPasswordType] = useState("password");

  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const [allCompanies, setAllCompanies] = useState([]);

  const [company, setCompany] = useState(null);

  const [allRoles, setAllRoles] = useState([]);

  const [role, setRole] = useState(null);

  const [tempProduct, setTempProduct] = useState([]);

  const [isSort, setIsSort] = useState(false);

  const [addressData, setAddressData] = useState(null);

  const [contactDetails, setContactDeatils] = useState(null);

  const [companyDetails, setCompanyDetails] = useState(null);

  const [allAddressType, setAllAddressType] = useState([]);

  const [addressType, setAddressType] = useState(null);

  const [allStates, setAllStates] = useState([]);

  const [allCountries, setAllCountries] = useState([]);

  const [countryData, setCountryData] = useState(null);

  const [stateValue, setStateValue] = useState(null);

  const [countryValue, setCountryValue] = useState(null);

  const [entityType, setEntityType] = useState(null);

  const [status, setStatus] = useState({ approve: false, reject: false });

  const [approvalDialog, setApprovalDialog] = useState(false);

  const [pendingStatusDialog, setPendingStatusDialog] = useState(false);

  const [orderTempdata, setOrderTempData] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [isEditing1, setIsEditing1] = useState(false);
  const [isEditing2, setIsEditing2] = useState(false);

  const [editValues, setEditValues] = useState();

  const [stripeData, setStripeData] = useState(null);

  const [allContact, setAllContact] = useState([]);

  const [contactData, setContactData] = useState(null);

  const [entityTypes, setEntityTypes] = useState([]);

  const [entityTypeData, setEntityTypeData] = useState("");

  const [updateStatus, setUpdateStatus] = useState(null);

  const [orderStatusErr, setOrderStatusErr] = useState("");

  const [orderUpdateNotesErr, setOrderUpdateNotesErr] = useState("");

  const [currentStatus, setCurrentStatus] = useState(null);

  const [superAdminMainId, setSuperAdminMainId] = useState(null);

  const [allKeyPersonnel, setAllKeyPersonal] = useState([]);

  const [customPrice, setCustomPrice] = useState(0);

  const [customPriceErr, setCustomPriceErr] = useState("");

  const [allTemplate, setAllTemplate] = useState([]);

  const [template, setTemplate] = useState(null);

  const [IsPriceAvail, setIsPriceAvail] = useState(true);

  const handleCheckboxChange = (e) => {
    setOrderStatusErr(false);
    const { value } = e.target;
    if (value === "approve") {
      setStatus({ approve: true, reject: false });
    } else if (value === "reject") {
      setStatus({ approve: false, reject: true });
    }
  };

  const strapiData = {
    data: {
      Company_Detail: {
        Company_Name: "Redberyltech",
      },
      Organizer_Details: {
        Organizer_Name: "Saumyaranjan Patra",
        Organizer_Email: "saumya@gmail.com",
        Organizer_ContactNo: "7888883883",
      },
    },
  };

  const [checked, setChecked] = useState(true);

  const fileInputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  // Function to extract dial code from phone number
  const extractDialCode = (phoneNumber) => {
    const dialCode = phoneNumber.match(/^\+\d+/);
    return dialCode ? dialCode[0] : null;
  };

  // Function to find country by dial code
  const findCountryByDialCode = (dialCode) => {
    return countries.find((country) => country.dialCode === dialCode);
  };

  const handleCheck = (event) => {
    setChecked(event.target.checked);
    if (data && dataId) {
      const actualdata = data.contactDetails;
      actualdata["statusId"] = event.target.checked === true ? 1 : 2;
      console.log(actualdata);
      ContactService.updateContact(actualdata, dataId)
        .then((res) => {
          console.log(res);
          setIsEditing1(false);
          setPageRefresh(!pageRefresh);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [products, setProducts] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [contactId, setContactId] = useState(null);
  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];

  const [allStatus, setAllStatus] = useState([]);

  const [statusName, setStatusName] = useState(null);

  const [updateNoteText, setUpdateNoteText] = useState("");

  const [isClient, setIsClient] = useState(false);

  console.log(filterOrderStatusId);

  function checkIdsExist(arr, idsToCheck) {
    // Return the found IDs or null if none are found
    return (
      idsToCheck.filter((idToCheck) =>
        arr.some((item) => item.id === idToCheck)
      ) || null
    );
  }

  useEffect(() => {
    if (user && globalCompany && globalCompany.roles.length > 0) {
      const superAdminIds = checkIdsExist(globalCompany.roles, [1, 4]);
      const hasSuperId = superAdminIds?.includes(1); // Check if 1 exists in the roles
      const hasOtherId = superAdminIds?.includes(4); // Check if 4 exists in the roles

      if (hasOtherId) {
        setSuperAdminId([4]); // Set 4 if found
      }

      if (hasSuperId) {
        setSuperAdminMainId([1]); // Set 1 if found
      }

      if (!hasSuperId) {
        console.log("isClient");
        setIsClient(true);
      }
    }
  }, [globalCompany]);

  const handleSelectedProduct = (product, allProducts) => {
    setSelectedProduct(product);
    setData(product);
    setCurrentStatus(product.statusId);

    const reorderedData = [
      ...allProducts.filter(
        (row) => row.orderDetails.id === product.orderDetails.id
      ),
      ...allProducts.filter(
        (row) => row.orderDetails.id !== product.orderDetails.id
      ),
    ];
    setProducts(reorderedData);
    setShowSkeleton(true);
  };

  console.log(filterOrderStatusId);

  useEffect(() => {
    setShowSkeleton(false);
    if (superAdminMainId) {
      OrderServices.getAllOrder()
        .then((res) => {
          const data = res.data;
          setTempProduct(data);
          setShowSkeleton(true);
          if (Array.isArray(data) && data.length > 0) {
            if (redirectedData) {
              const redirectedProduct = data.find(
                (val) => val.orderDetails.id === redirectedData.id
              );
              if (redirectedProduct) {
                handleSelectedProduct(redirectedProduct, data);
                return;
              }
            }

            if (filterOrderStatusId) {
              const filteredProducts = data.filter(
                (val) => val.statusId.id === filterOrderStatusId
              );
              if (filteredProducts.length > 0) {
                handleSelectedProduct(filteredProducts[0], filteredProducts);
                return;
              } else {
                setProducts([]);
                return;
              }
            }

            if (orderid) {
              const orderProduct = data.find(
                (val) => val.orderDetails.orderId === orderid
              );
              if (orderProduct) {
                handleSelectedProduct(orderProduct, data);
                return;
              }
            }

            handleSelectedProduct(data[0], data);
          }
        })
        .catch((err) => {
          console.error(err);
          setShowSkeleton(true);
        });
    } else if (isClient) {
      if (globalCompany) {
        console.log(globalCompany);

        if (globalCompany.companyDetails) {
          OrderServices.getAllOrderByCompanyId(globalCompany.companyDetails.id)
            .then((res) => {
              console.log(res);
              let data = res.data.ordersWithLineItems;
              console.log(orderId);
              setTempProduct(res.data.ordersWithLineItems);
              console.log(data);
              if (Array.isArray(data) && data.length > 0) {
                if (redirectedData) {
                  const redirectedProduct = data.find(
                    (val) => val.orderDetails.id === redirectedData.id
                  );
                  if (redirectedProduct) {
                    handleSelectedProduct(redirectedProduct, data);
                    return;
                  }
                }

                if (filterOrderStatusId) {
                  const filteredProducts = data.filter(
                    (val) => val.statusId.id === filterOrderStatusId
                  );

                  if (filteredProducts.length > 0) {
                    handleSelectedProduct(
                      filteredProducts[0],
                      filteredProducts
                    );
                    return;
                  }
                }

                if (orderid) {
                  const orderProduct = data.find(
                    (val) => val.orderDetails.orderId === orderid
                  );
                  if (orderProduct) {
                    handleSelectedProduct(orderProduct, data);
                    return;
                  }
                }

                handleSelectedProduct(data[0], data);
              }
            })
            .catch((err) => {
              console.log(err);
              setShowSkeleton(true);
            });
        }
      }
    }
  }, [
    pageRefresh,
    globalCompany,
    superAdminId,
    superAdminMainId,
    isClient,
    redirectedData,
    filterOrderStatusId,
  ]);

  useEffect(() => {
    clearData();
    companyMasterService
      .getAllCompany()
      .then((res) => {
        console.log(res);
        setAllCompanies(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    rolesMasterService
      .getAllRoles()
      .then((res) => {
        console.log(res);
        setAllRoles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    AddressService.getAllAddressType()
      .then((res) => {
        setAllAddressType(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    AddressService.getAllCountry()
      .then((res) => {
        console.log(res);
        setAllCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    AddressService.getAllStates()
      .then((res) => {
        console.log(res);
        setAllStates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    ContactService.getAllContact()
      .then((res) => {
        console.log(res);
        if (res.data !== "") {
          setAllContact(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    companyMasterService
      .getAllKeyPersonnelType()
      .then((res) => {
        console.log(res);
        if (Array.isArray(res.data)) {
          setAllKeyPersonal(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    MasterServices.getAll("/core/api/orderTypeMaster/getAll")
      .then((res) => {
        console.log(res.data);
        if (res.data !== "") {
          setEntityTypes(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    MasterServices.getAllTemplate()
      .then((res) => {
        console.log(res);
        if (Array.isArray(res.data)) {
          setAllTemplate(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setImageData(null);
    if (data && data.contactDetails && data.contactDetails.avatarImgPath) {
      MasterServices.getFile(data.contactDetails.avatarImgPath)
        .then((res) => {
          console.log(res);
          setImageData(res.data);
        })
        .catch((err) => {
          console.log(err);
          setImageData(null);
        });
    } else {
      setImageData(null);
    }
  }, [data]);

  useEffect(() => {
    if (
      data &&
      Array.isArray(data.orderLineItem) &&
      data.orderLineItem.length > 0
    ) {
      const temp = data.orderLineItem.reduce(
        (acc, curr) => acc + (curr.unitPrice || 0),
        0
      );
      console.log(temp);
      if (temp === 0) {
        setIsPriceAvail(false);
      } else {
        setIsPriceAvail(true);
      }
    }
  }, [data]);

  console.log(IsPriceAvail);

  useEffect(() => {
    MasterServices.getAll("/core/api/statusMaster/getAll")
      .then((res) => {
        console.log(res);
        if (Array.isArray(res.data)) {
          const temp = res.data.filter(
            (val) => val.statusForId.statusForName === "ORDERS"
          );
          setAllOrderStatus(temp);
          console.log(temp);
          if (Array.isArray(temp)) {
            if ((superAdminId || superAdminMainId) && currentStatus) {
              const tempArr = temp.flatMap((item) => item.vstateEmpId);

              console.log(tempArr);
              const filteredStatus = temp.filter((val) =>
                currentStatus.vstateEmpId.some((value) => value === val.id)
              );
              console.log(filteredStatus);
              setAllStatus(filteredStatus);
            } else {
              if (currentStatus) {
                const tempArr = temp.flatMap((item) => item.vstateClientId);

                console.log(tempArr);
                const filteredStatus = temp.filter((val) =>
                  currentStatus.vstateClientId.some((value) => value === val.id)
                );
                console.log(filteredStatus);
                setAllStatus(filteredStatus);
              }
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [globalCompany, superAdminId, currentStatus]);

  console.log(currentStatus);

  useEffect(() => {
    console.log("first");
    AddressService.getAllAddressByContactId(contactId)
      .then((res) => {
        console.log(res);
        if (res.data !== "") {
          const tempArr = res.data;
          const temp = tempArr.filter(
            (val) => val.addressType.type === "Contact"
          );
          console.log(temp);
          if (temp.length > 0) {
            setAddressData(temp[0]);
            setAddr1(temp[0].addressLine1);
            setAddr2(temp[0].addressLine2);
            setCity(temp[0].city);
            setZip(temp[0].postalCode);
            setStates(temp[0].stateId);
            setCountryData(temp[0].countryId);
            setAddressType(temp[0].addressType);
            setContactDeatils(temp[0].contactDetails);
            const temps = allStates.filter((val) => val.id === temp[0].stateId);
            const temp1 = allCountries.filter(
              (val) => val.id === temp[0].countryId
            );
            console.log(temps);
            if (temps.length > 0 && temp1.length > 0) {
              setStateValue(temps[0].stateFullDesc);
              setCountryValue(temp1[0].countryFullDesc);
            }
          }
        } else {
          setAddressData(null);
          setAddr1("");
          setAddr2("");
          setCity("");
          setZip("");
          setStates(null);
          setCountryData(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dataId, contactId]);

  const user = JSON.parse(localStorage.getItem("user"));

  const automationJSON = JSON.parse(localStorage.getItem("automation"));

  const [loginDetails, setLoginDetails] = useState([
    { key: "Password", value: "dhdhdhdhhdhd" },
  ]);

  console.log(superAdminMainId);

  const [editLoginValues, setEditLoginValues] = useState(
    loginDetails.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {})
  );

  const getContact = (data) => {
    console.log(data);
    if (data && data.orderDetails !== undefined) {
      const contactArr = allContact.filter(
        (val) => val.contactDetails.id === data.orderDetails.contactId
      );
      console.log(contactArr);

      if (contactArr.length > 0) {
        const temp = contactArr[0];
        return temp;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    const temp = getContact(data);
    console.log(temp);
    if (temp && data) {
      setContactData(temp);
      // setShowSkeleton(true)
      if (temp.contactDetails !== undefined) {
        setContactId(temp.contactDetails.id);
      }
      if (data.orderTypeId) {
        const tempdata = entityTypes.filter(
          (val) => val.id === data.orderTypeId
        );
        if (tempdata.length > 0) {
          setEntityTypeData(tempdata[0].orderShortName);
        }
      }
    }
  }, [data]);

  console.log(contactData);

  useEffect(() => {
    if (data) {
      if (data.contactDetails !== undefined) {
        setFirstName(data.contactDetails.firstName);
        setLastName(data.contactDetails.lastName);
        setPhoneNumber(data.contactDetails.mobileNo);
        setEmail(data.contactDetails.emailId);
        setFax(data.contactDetails.fax);
        setSalutation(data.contactDetails.salutation);
        setAltPhoneNumber(data.contactDetails.phone1);
        if (data.contactDetails.statusId === 1) {
          setChecked(true);
        } else {
          setChecked(false);
        }
      }
    }
  }, [data, contactData]);

  useEffect(() => {
    if (template) {
      setUpdateNoteText(template.templateDesc);
    }
  }, [template]);

  console.log(products);

  const handleFilter = () => {
    if (products.length === 0) return;

    let filteredContacts = products;

    if (statesId) {
      filteredContacts = filteredContacts.filter(
        (contact) => contact.state && contact.state.id === statesId
      );
    }

    if (entityType) {
      filteredContacts = filteredContacts.filter(
        (contact) =>
          contact.orderTypeID.orderFullDesc === entityType.orderFullDesc
      );
    }

    if (statusName) {
      filteredContacts = filteredContacts.filter(
        (contact) => contact.statusId && contact.statusId.id === statusName
      );
    }

    setProducts(filteredContacts);

    if (filteredContacts.length > 0) {
      setSelectedProduct(filteredContacts[0]);
      setData(filteredContacts[0]);
      setCurrentStatus(filteredContacts[0].statusId);
    }
  };

  const handleSearch = () => {
    if (filteredInput !== "") {
      const lowerCaseInput = filteredInput.toLowerCase();

      const searchNestedObject = (obj) => {
        for (const key in obj) {
          if (obj[key] !== null) {
            if (typeof obj[key] === "object") {
              if (searchNestedObject(obj[key])) {
                return true;
              }
            } else if (
              typeof obj[key] === "string" &&
              obj[key].toLowerCase().includes(lowerCaseInput)
            ) {
              return true;
            } else if (
              typeof obj[key] === "number" &&
              obj[key] == lowerCaseInput
            ) {
              return true;
            }
          }
        }
        return false;
      };

      const temp = tempProduct.filter((val) => searchNestedObject(val));

      setProducts(temp);

      if (temp.length > 0) {
        setSelectedProduct(temp[0]);
        setData(temp[0]);
        setCurrentStatus(temp[0].statusId);
      }
    } else {
      setProducts(tempProduct);
    }
  };

  const handleClearFilter = () => {
    setProducts(tempProduct);
    if (tempProduct.length > 0) {
      setSelectedProduct(tempProduct[0]);
      setData(tempProduct[0]);
      setCurrentStatus(tempProduct[0].statusId);
    }
    setStatesId(null);
    setStatusName(null);
    setEntityType(null);
    setFilteredInput("");
  };

  const handleAddressEdit = () => {
    if (addressData) {
      const actualdata = addressData;
      actualdata["addressLine1"] = addr1;
      actualdata["addressLine2"] = addr2;
      actualdata["city"] = city;
      actualdata["stateMasterId"] = states;
      actualdata["countryMasterId"] = countryData;
      actualdata["postalCode"] = zip;
      actualdata["stateId"] = states;
      actualdata["countryId"] = countryData;

      if (
        addr1 !== "" &&
        addr2 !== "" &&
        city !== "" &&
        zip !== "" &&
        contactDetails &&
        company &&
        addressType
      ) {
        AddressService.updateAllAddress(actualdata)
          .then((res) => {
            console.log(res.data);
            setIsEditing2(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        if (addr1 === "") {
          setAddr1Err(errorMessages.address1Required);
        }
        if (addr2 === "") {
          setAddr2Err(errorMessages.address2Required);
        }
        if (city === "") {
          setcityErr(errorMessages.cityRequired);
        }
        if (zip === "") {
          setZipErr(errorMessages.zipRequired);
        }
      }
    }
  };

  const handleAddressSave = () => {
    const actualdata = {
      addressLine1: addr1,
      addressLine2: addr2,
      city: city,
      stateMasterId: states,
      postalCode: zip,
      countryMasterId: countryData,
      contactDetails: contactDetails,
      companyDetails: company,
      addressType: addressType,
    };

    if (
      addr1 !== "" &&
      addr2 !== "" &&
      city !== "" &&
      zip !== "" &&
      contactDetails &&
      company &&
      addressType
    ) {
      AddressService.saveAllAddress(actualdata)
        .then((res) => {
          console.log(res);
          setIsEditing2(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (addr1 === "") {
        setAddr1Err(errorMessages.address1Required);
      }
      if (addr2 === "") {
        setAddr2Err(errorMessages.address2Required);
      }
      if (city === "") {
        setcityErr(errorMessages.cityRequired);
      }
      if (zip === "") {
        setZipErr(errorMessages.zipRequired);
      }
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleEdit1 = (rowdata) => {
    setIsEditing1(true);
    if (rowdata.contactDetails !== undefined) {
      setDataId(rowdata.contactDetails.id);
    }
  };

  const handleEdit2 = () => {
    setIsEditing2(true);
  };

  const handlePassword = (e) => {
    setPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setPasswordErr(errorMessages.passwordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setPasswordErr(errorMessages.passwordValidation);
      }
    }
    setPassword(e.target.value);
  };

  const handleOldPassword = (e) => {
    setOldPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setOldPasswordErr(errorMessages.oldPasswordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setOldPasswordErr(errorMessages.oldPasswordValidation);
      }
    }
    setOldPassword(e.target.value);
  };

  const handleEmail = (e) => {
    setEmailErr("");
    let value = e.target.value;
    if (value === "") {
      setEmailErr(errorMessages.emailRequired);
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      setEmailErr(errorMessages.emailValidation);
    }
    setEmail(e.target.value);
  };

  const handleLastName = (e) => {
    setLastNameErr("");
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setLastName(e.target.value);
  };

  const handleFirstName = (e) => {
    setFirstNameErr("");
    let value = e.target.value;
    if (value === "") {
      setFirstNameErr(errorMessages.firstNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setFirstNameErr(errorMessages.firstNameValidation);
      }
    }
    setFirstName(e.target.value);
  };

  const handleCustomPrice = (e) => {
    setCustomPriceErr("");
    let value = e.target.value;
    // if (value === '') {
    //     setPhoneErr(errorMessages.phoneRequired)
    // }
    if (value && value.trim() !== "") {
      if (!/^\d+(\.\d{1,2})?$/.test(value)) {
        setCustomPriceErr(errorMessages.phoneValidation);
      }
    }
    setCustomPrice(e.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };

  const handleConfirmPassword = (e) => {
    setConfirmPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setConfirmPasswordErr(errorMessages.confirmPasswordValidation);
      }
    }
    setConfirmPassword(e.target.value);
  };

  const handleChange = (key, value) => {
    setEditValues({ ...editValues, [key]: value });
  };

  const handleSave2 = () => {
    console.log(addressData);
    if (addressData) {
      handleAddressEdit();
    } else {
      handleAddressSave();
    }
  };

  const handleSave = () => {
    console.log(selectedCountry);

    if (data && dataId) {
      const actualdata = data.contactDetails;
      actualdata["firstName"] = firstName;
      actualdata["lastName"] = lastName;
      actualdata["mobileNo"] = selectedCountry
        ? `${selectedCountry.dialCode} ${phoneNumber}`
        : phoneNumber;
      actualdata["emailId"] = email;
      actualdata["fax"] = fax;
      actualdata["phone1"] = altPhoneNumber;
      actualdata["salution"] = salutation;

      console.log(actualdata);

      if (firstName && lastName && phoneNumber && email) {
        ContactService.updateContact(actualdata, dataId)
          .then((res) => {
            console.log(res);
            setIsEditing1(false);
            setPageRefresh(!pageRefresh);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        if (!firstName) {
          setFirstNameErr(errorMessages.firstNameRequired);
        }
        if (!lastName) {
          setLastNameErr(errorMessages.lastNameRequired);
        }
        if (!phoneNumber) {
          setPhoneErr(errorMessages.phoneRequired);
        }
        if (!email) {
          setEmailErr(errorMessages.emailRequired);
        }
      }
    }
  };

  const handleToggle = () => {
    setIsShowContent(!isShowContent);
  };

  const handleToggle1 = () => {
    setIsShowContent1(!isShowContent1);
  };

  const handleToggle2 = () => {
    setIsShowContent2(!isShowContent2);
  };

  const handleToggle3 = () => {
    setIsShowContent3(!isShowContent3);
  };

  const sortData = (field) => {
    if (field === sortField) {
      // If clicking on the same field, toggle the sort order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // If clicking on a different field, set the new field and default to ascending order
      setSortField(field);
      setSortOrder("asc");
    }
    const sortedData = [...products].sort((a, b) => {
      console.log(a);
      if (a.contactDetails !== undefined && b.contactDetails !== undefined) {
        const valueA = a.contactDetails[field];
        const valueB = b.contactDetails[field];

        if (valueA < valueB) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortOrder === "asc" ? 1 : -1;
        }
      }

      return 0;
    });
    console.log(sortedData);

    setProducts(sortedData);
  };

  const handleFbackHeader = () => {
    return (
      <>
        <div className="font-fam-for-all form-md-heading form-sm-close-Btn">
          Pre-Screening Feedback
          <button className="form-close-Btn" onClick={() => setVisible6(false)}>
            <i className="pi pi-times"></i>
          </button>
        </div>
      </>
    );
  };

  const handleTabChange = (e) => {
    setActiveIndex(e.index);
    setIsEditing(false);
    setIsEditing1(false);
    setIsEditing2(false);
  };

  const handleSkill = (e) => {
    setSkills(e.target.value);
  };

  const handleQualification = (e) => {
    setQualification(e.target.value);
  };

  const PasswordText = (text) => {
    const maskedText = text.replace(/./g, "*");
    return maskedText;
  };

  const handleSave1 = () => {
    const newData = loginDetails.map((item) => ({
      ...item,
      value: editLoginValues[item.key],
    }));
    setLoginDetails(newData);

    if (
      password !== "" &&
      confirmedPassword !== "" &&
      oldPassword !== "" &&
      password === confirmedPassword
    ) {
      ForgotPasswordService.resetPassword(confirmedPassword, email)
        .then((res) => {
          console.log(res);
          setIsEditing(false);
          // if (toast.current !== null) {
          //   if (res.data === "Password changed successfully!") {
          //     toast.current.show({
          //       severity: "success",
          //       summary: "Success",
          //       detail: "Password updated successfully",
          //       life: 2800,
          //     });
          //   }
          // }

                              showToast("success","Password changed successfully!")
          
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (confirmedPassword === "") {
        setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
      }
      if (password === "") {
        setPasswordErr(errorMessages.passwordRequired);
      }
      if (oldPassword === "") {
        setOldPasswordErr(errorMessages.oldPasswordRequired);
      }
      if (password !== confirmedPassword) {
        // setVisible(true)
        // if (toast.current !== undefined) {
        //   toast.current.show({
        //     severity: "warn",
        //     summary: "Warning",

        //     content: (props) => (
        //       <div
        //         className="flex flex-column align-items-left"
        //         style={{ flex: "1" }}
        //       >
        //         <div className="flex align-items-center gap-2">
        //           <span className="font-bold text-900">vState Filings</span>
        //         </div>
        //         <div className="font-medium text-lg my-3 text-900">
        //           {errorMessages.passwordMatched}
        //         </div>
        //       </div>
        //     ),
        //     life: 3000,
        //   });
        // }
        showToast("error",`${errorMessages.passwordMatched}`)

        // setGlobalErr(errorMessages.passwordMatched)
      }
    }
  };

  const allSortName = ["name", "location", "experienc"];

  const handleSortName = (e) => {
    setSortName(e.target.value);
    console.log(e.target.value);
    sortData(e.target.value);
  };

  const handlePosition = (e) => {
    const temp = e.target.value;
    setPosition(e.target.value);
    if (temp.jobdescription.skill !== null) {
      setSkills(temp.jobdescription.skill);
    }
    if (temp.jobdescription.experience !== null) {
      const tempexp = temp.jobdescription.experience;
      const arrexp = tempexp.split("-");
      console.log(arrexp);
      if (arrexp.length === 2) {
        setExp1(arrexp[0]);
        setExp2(arrexp[1]);
      }
    }
  };

  const handleClearPosition = () => {
    setVisible1(false);
    setVisible2(false);
    setPosition(null);
    setPositionData(null);
  };

  const handlePositionData = (e) => {
    setProducts(AllCandidate);
    const temp = e.target.value;
    setPositionData(e.target.value);
  };

  const SortingFilter = () => {
    return (
      <>
        <div>
          <Dropdown
            value={sortName}
            options={allSortName}
            onChange={handleSortName}
            placeholder="Sorting By"
            filter
            className="filter-dropdown-field"
            required
          />
        </div>
      </>
    );
  };

  const getState = (id) => {
    const temp = allStates.find((val) => val.id === id);
    if (temp !== undefined) {
      return temp.stateFullDesc;
    } else {
      return null;
    }
  };

  const getCountry = (id) => {
    const temp = allCountries.find((val) => val.id === id);
    if (temp !== undefined) {
      return temp.countryFullDesc;
    } else {
      return null;
    }
  };

  const handleExp1 = (e) => {
    setProducts(AllCandidate);
    setExp1(e.target.value);
  };

  const handleExp2 = (e) => {
    setProducts(AllCandidate);
    setExp2(e.target.value);
  };

  const listExp = [];
  for (let i = 0; i < 50; i++) {
    listExp.push(`${i}`);
  }

  const filterData = (event) => {
    const filteredItems = products.filter((item) => {
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const element = item[key].toString();
          console.log(element);

          if (
            element.toLowerCase().includes(event.target.value.toLowerCase())
          ) {
            return item;
          }
        }
      }
    });
    setFilteredData(filteredItems);
  };

  // effect of filter all

  function checkAllExist(a, b) {
    // Convert all elements of array b to lowercase for case-insensitive comparison
    const lowercaseB = b.map((item) => item.toLowerCase());

    for (const element of a) {
      // Convert element to lowercase for case-insensitive comparison
      const lowercaseElement = element.toLowerCase();

      if (!lowercaseB.includes(lowercaseElement)) {
        return false; // If any element of a is not found in b, return false
      }
    }
    return true; // All elements of a were found in b
  }

  function convertMonthsToYears(years) {
    return years * 12;
  }

  // event on checkbox
  const onCheckboxChange = (event, data) => {
    console.log(event.checked);
    console.log(data);
    if (event.checked) {
      setSelectedData([...selectedData, data]);
    } else {
      setSelectedData(selectedData.filter((item) => item !== data));
    }
  };

  const handleChecked = (e) => {
    const checked = e.checked;
    if (checked) {
      setSelectedData(products);
    } else {
      setSelectedData([]);
    }
  };

  console.log(selectedData);

  // DataTable columns
  const cols = [
    { field: "id", header: "id" },
    { field: "fullName", header: "Name" },
    { field: "skill", header: "Skills" },
    { field: "experienceInYears", header: "Experience" },
    { field: "city", header: "Location" },
  ];

  const updateData = selectedData.map((elem) => {});

  // Export DataTable data
  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const CloseProgresbarheader1 = () => {
    setCloseProgresbar1(false);
    setPendingStatusDialog(false);
    setUpdateNoteText("");
    setUpdateStatus(null);
    setOrderStatusErr("");
    setOrderUpdateNotesErr("");
    setSelectedFile(null);
    setCustomPriceErr("");
    setCustomPrice(0);
    setTemplate(null);
  };

  const handleClick3 = () => {
    setVisible(false);
  };

  // Function to check if object exists in array
  function objectExists(array, obj) {
    return array.some((item) => JSON.stringify(item) === JSON.stringify(obj));
  }

  const getInitials = (name) => {
    const nameArray = name.trim().split(" ");
    if (nameArray.length >= 2) {
      const firstNameInitial = nameArray[0].charAt(0).toUpperCase();
      const lastNameInitial = nameArray[1].charAt(0).toUpperCase();
      return firstNameInitial + lastNameInitial;
    } else {
      const initial = nameArray[0].charAt(0).toUpperCase();
      return initial + initial;
    }
  };

  const getStatus = (data) => {
    if (Array.isArray(data)) {
      if (data.length > 0) {
        return data[data.length - 1].orderStatus.statusName;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getBadgeBackgroundColor = (data) => {
    console.log(data);
    const status =
      data.orderUpdateHistory.length > 0
        ? getStatus(data.orderUpdateHistory)
        : data.statusId
        ? data.statusId.statusName
        : "";

    switch (status) {
      case "ORDER CREATED":
        return "#ECFDF3";
      case "ORDER SUBMITTED":
        return "#ECFDF3";
      case "IN PROGRESS":
        return "#ECFDF3";
      case "ORDER COMPLETED":
        return "#ECFDF3";

      case "ORDER IN REVIEW":
        return "#ECFDF3";
      case "APPROVAL PENDING":
        return "#ECFDF3";
      case "QUERY RAISED":
        return "#FFFAEB";
      case "PAY LATER REQUEST REJECTED":
        return "#FFFAEB";
      case "DRAFT":
        return "#E4E7EB";
      default:
        return "#ECFDF3";
    }
  };

  const getBadgeTextColor = (status) => {
    switch (status) {
      case "ORDER CREATED":
        return "#027A48";
      case "ORDER SUBMITTED":
        return "#027A48";
      case "IN PROGRESS":
        return "#027A48";
      case "ORDER COMPLETED":
        return "#027A48";
      case "ORDER IN REVIEW":
        return "#027A48";
      case "APPROVAL PENDING":
        return "#027A48";
      case "PAY LATER REQUEST REJECTED":
        return "#B54708";
      case "QUERY RAISED":
        return "#027A48";
      case "DRAFT":
        return "#667085";
      default:
        return "#027A48";
    }
  };

  const itemTemplate = (data) => {
    console.log(data);
    console.log(selectedData);
    const parsedJson = data.orderDetails
      ? JSON.parse(data.orderDetails.strapiOrderFormJson)
      : {};
    console.log(parsedJson);

    const contactName = getContact(data);
    if (data) {
      return (
        <div className="srch-cand-card font-fam-for-all">
          <div className="grid w-full">
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 flex gap-2"
              style={{ width: "250px" }}
            >
              <div className="grid">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0">
                  <p className=" srch-cand-text1">{`ORDER-ID ${
                    data.orderDetails.orderId
                      ? data.orderDetails.orderId
                      : data.orderDetails.id
                  }`}</p>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0">
                  <p className=" srch-cand-text">
                    {parsedJson.legalName !== undefined &&
                    parsedJson.legalName !== null
                      ? parsedJson.legalName
                      : data.companyId.companyName}
                  </p>
                  <p className=" srch-cand-text">
                    {data.orderDetails.orderDate
                      ? formatISODate(data.orderDetails.orderDate)
                      : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-4 flex align-items-center justify-content-center">
              <>
                <div className="badge-container">
                  <div
                    className="badge-main grid"
                    style={{ background: getBadgeBackgroundColor(data) }}
                  >
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 flex align-items-center p-0">
                      {
                        // data.orderUpdateHistory.length > 0 ?
                        //     <span className='badge-label ml-1' style={{ color: getBadgeTextColor(getStatus(data.orderUpdateHistory)) }}>
                        //         {getStatus(data.orderUpdateHistory)}
                        //     </span>
                        //     :
                        <span
                          className="badge-label ml-1"
                          style={{
                            color: getBadgeTextColor(
                              data.statusId ? data.statusId.statusName : ""
                            ),
                          }}
                        >
                          {data.statusId ? data.statusId.statusName : ""}
                        </span>
                      }
                      {/* <span className='badge-label ml-1' style={{ color: getBadgeTextColor(data.statusId ? data.statusId.statusName : "") }}>
                                                        {data.statusId ? data.statusId.statusName : ""}
                                                    </span> */}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const CloseProgresbarheader = () => {
    setCloseProgresbar(false);
    setPendingStatusDialog(false);
    setUpdateNoteText("");
    setUpdateStatus(null);
    setOrderStatusErr("");
    setOrderUpdateNotesErr("");
    setSelectedFile(null);
    setCustomPriceErr("");
    setCustomPrice(0);
    setTemplate(null);
  };

  const searchCandHeader = () => {
    return (
      <div className="font-fam-for-all flex flex-column gap-3">
        <p className="srch-cand-header mb-0 table-cand">
          Orders{" "}
          <Tag
            className="badge-cand"
            value={
              filteredData.length > 0 ? filterData.length : products.length
            }
          ></Tag>
        </p>

        <div className="card-devider"></div>

        <div className="srch-cand-card font-fam-for-all ">
          {/* <Checkbox className='mb-1'
                        checked={selectedData.length === products.length}
                        onChange={handleChecked}
                    ></Checkbox> */}

          {/* <div style={{ width: '400px' }}>

                        <button style={{ backgroundColor: '#ffff' }} className='table-cand srch-cand-text' onClick={() => {
                            sortData("firstName")
                            setIsSort(!isSort)
                        }}>Name<i className={isSort ? 'pi pi-arrow-up srch-cand-text ml-1' : "pi pi-arrow-down srch-cand-text ml-1"} style={{ fontSize: "14px" }}></i></button>

                    </div> */}
        </div>
      </div>
    );
  };

  const handleOnlineForm = (data) => {
    console.log(automationJSON);

    if (data) {
      console.log(data.orderDetails.strapiOrderFormJson);
      const autoData = data.orderDetails.strapiOrderFormJson;
      const parsedData = JSON.parse(data.orderDetails.strapiOrderFormJson);
      console.log(parsedData);
      setIsTabRefresh(true);
      UserServices.callAutomation(parsedData)
        .then((res) => {
          console.log(res);
          setIsTabRefresh(false);
        })
        .catch((err) => {
          console.log(err);
          setIsTabRefresh(false);
        });
    }
  };

  const handleBoiSubmit = (data) => {
    OrderServices.submitBoiApplication(data.orderDetails)
      .then((res) => {
        console.log(res);
        // if (toast.current !== undefined) {
        //   toast.current.show({
        //     severity: "success",
        //     summary: "Success",

        //     content: (props) => (
        //       <div
        //         className="flex flex-column align-items-left"
        //         style={{ flex: "1" }}
        //       >
        //         <div className="flex align-items-center gap-2">
        //           <span className="p-toast-summary">vState Filings</span>
        //         </div>
        //         <div className="p-toast-detail">BOI filed successfully</div>
        //       </div>
        //     ),
        //     life: 3000,
        //   });
        // }
        showToast("success","BOI filed successfully")

      })
      .catch((err) => {
        console.log(err);
        // if (toast.current !== undefined) {
        //   toast.current.show({
        //     severity: "warn",
        //     summary: "Success",

        //     content: (props) => (
        //       <div
        //         className="flex flex-column align-items-left"
        //         style={{ flex: "1" }}
        //       >
        //         <div className="flex align-items-center gap-2">
        //           <span className="p-toast-summary">vState Filings</span>
        //         </div>
        //         <div className="p-toast-detail">BOI filed failed</div>
        //       </div>
        //     ),
        //     life: 3000,
        //   });
        // }

        showToast("error","BOI filed failed")

      });
  };

  const handleEditForm = (data) => {
    console.log(data);
    if (data) {
      setEditPayloadData(data);
      navigate({
        pathname: "/edit/orderform",
      });
    }
  };

  const onRowSelect = (rowData) => {
    console.log(rowData);

    setData(rowData.data);
    setCurrentStatus(rowData.data.statusId);
    if (rowData.data.contactDetails !== undefined) {
      setContactDeatils(rowData.data.contactDetails);
      setDataId(rowData.data.contactDetails.id);
      if (rowData.data.contactDetails.emailId) {
        setEmail(rowData.data.contactDetails.emailId);
        if (rowData.data.contactDetails.mobileNo !== undefined) {
          const dialCode = extractDialCode(
            rowData.data.contactDetails.mobileNo
          );
          console.log(dialCode);
          if (dialCode) {
            const country = findCountryByDialCode(dialCode);
            if (country) {
              setSelectedCountry(country);
            }
          }
        }
      }
    }
    if (rowData.data.companyDetails !== undefined) {
      setCompanyDetails(rowData.data.companyDetails);
    }
  };

  useEffect(() => {
    setActiveIndex(0);
  }, [selectedProduct]);

  const sidebarHeader = () => (
    <>
      <div
        style={{
          backgroundColor: "#002592",
          width: "contentFit",
          height: "40px",
          position: "sticky",
        }}
        className="sidebar-head"
      >
        <Button
          className="pi pi-times sidebar-close-btn"
          onClick={() => setFilters(false)}
        ></Button>
      </div>
    </>
  );

  const items = Array.from({ length: 5 }, (v, i) => i);

  console.log(products);

  const formatDataForExcel = (jsonData) => {
    return jsonData.map((item) => ({
      "Order Title": item.orderDetails.orderTitle,
      "Order Amount": item.orderDetails.orderAmount,
      "Order Date": item.orderDetails.orderDate,
      "Fulfillment Date": item.orderDetails.tentativeFulfillmentDate,
      "First Name": item.contactDetails.firstName,
      "Last Name": item.contactDetails.lastName,
      Email: item.contactDetails.emailId,
      "Mobile No": item.contactDetails.mobileNo,
      "Company Name": item.companyId.companyName,
      "Company Fax": item.companyId.fax,
      "Entity Type": item.entityTypeId.entityFullDesc,
      Status: item.statusId.statusName,
    }));
  };

  const exportExcel = () => {
    const data = formatDataForExcel(products);
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      saveAsExcelFile(excelBuffer, "order");
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, fileName + EXCEL_EXTENSION);
      }
    });
  };

  const events = [
    {
      status: "Ordered",
      date: "15/10/2020 10:30",
      icon: "pi pi-shopping-cart",
      color: "#9C27B0",
      image: "game-controller.jpg",
    },
    {
      status: "Processing",
      date: "15/10/2020 14:00",
      icon: "pi pi-cog",
      color: "#673AB7",
    },
    {
      status: "Shipped",
      date: "15/10/2020 16:15",
      icon: "pi pi-shopping-cart",
      color: "#FF9800",
    },
    {
      status: "Delivered",
      date: "16/10/2020 10:00",
      icon: "pi pi-check",
      color: "#607D8B",
    },
  ];

  const customizedMarker = (item) => {
    return (
      <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="32"
          viewBox="0 0 33 32"
          fill="none"
        >
          <path
            d="M29.8337 14.7733V16C29.832 18.8752 28.901 21.6729 27.1794 23.9758C25.4579 26.2786 23.038 27.9633 20.2808 28.7785C17.5236 29.5938 14.5767 29.4959 11.8796 28.4994C9.18257 27.503 6.87986 25.6615 5.31494 23.2494C3.75001 20.8374 3.00671 17.9841 3.19589 15.1151C3.38508 12.2461 4.4966 9.51509 6.3647 7.32942C8.2328 5.14375 10.7574 3.6205 13.5619 2.98685C16.3664 2.35321 19.3007 2.64311 21.927 3.81333M29.8337 5.33333L16.5003 18.68L12.5003 14.68"
            stroke="#039855"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    );
  };

  const DateFormat = (data) => {
    const dateString = data;
    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });

    return <div>{formattedDate}</div>;
  };

  const customizedContent = (item) => {
    console.log(item);
    return (
      <div className="order-details-card-text-container">
        <div className="order-details-card-text-container-header">
          <div className="order-details-card-text-container-header-text1">
            {item.orderStatus.statusName}
          </div>
          <div className="order-details-card-text-container-header-text2">
            {DateFormat(item.orderUpdateHistory.orderUpdatedDate)}
          </div>
        </div>
        <ul>
          <li className="order-details-card-text-list">
            {item.orderUpdateHistory.orderUpdateNotes}
          </li>
          <li className="order-details-card-text-list">
            Updated by{" "}
            <span className="order-contact-card-header-text2">
              {item.orderUpdateHistory.orderUpdatedBy}
            </span>
          </li>
        </ul>
      </div>
    );
  };

  const handlePendingStatusDialog = (data) => {
    console.log(data);
    setOrderTempData(data);
    setPendingStatusDialog(true);
  };

  const handleApproveDialog = (data) => {
    console.log(data);
    setOrderTempData(data);
    setApprovalDialog(true);
  };

  console.log(status);

  const handleApproveSubmit = () => {
    const currentDate = new Date();
    const isoDate = currentDate.toISOString();

    if (
      orderTempdata &&
      updateNoteText !== "" &&
      (status.approve || status.reject)
    ) {
      if (status.approve === true) {
        const temp = orderTempdata.orderDetails;
        console.log(orderTempdata);
        temp["statusId"] = 1002;
        temp["isPayLater"] = true;
        temp["orderUpdateNotes"] = updateNoteText;
        temp["orderUpdatedBy"] = superAdminId
          ? `vState-${loginUserName}`
          : loginUserName;
        temp["orderUpdatedDate"] = isoDate;
        temp["orderLineItems"] = orderTempdata.orderLineItem;
        temp["stateId"] = orderTempdata.state ? orderTempdata.state.id : null;
        temp["countyId"] = orderTempdata.orderDetails.countyId;
        const tempdata = {
          orderId: temp,
          orderUpdateNotes: updateNoteText,
          orderUpdatedBy: loginUserName,
          status: 9,
          orderJson: JSON.stringify(temp),
          strapiJson: orderTempdata.orderDetails.strapiOrderFormJson,
          orderUpdatedDate: isoDate,
        };
        if (updateNoteText !== "") {
          setIsTabRefresh(true);
          OrderServices.saveAllOrder(temp)
            .then((res) => {
              console.log(res);
              setRedirectedData(res.data.orderDetails);
              setApprovalDialog(false);
              setIsTabRefresh(false);
              setUpdateNoteText("");
              setOrderStatusErr("");
              setOrderUpdateNotesErr("");
              setSelectedFile(null);
              // if (toast.current !== undefined) {
              //   toast.current.show({
              //     severity: "success",
              //     summary: "Success",

              //     content: (props) => (
              //       <div
              //         className="flex flex-column align-items-left"
              //         style={{ flex: "1" }}
              //       >
              //         <div className="flex align-items-center gap-2">
              //           <span className="p-toast-summary">vState Filings</span>
              //         </div>
              //         <div className="p-toast-detail">
              //           Order status updated successfully
              //         </div>
              //       </div>
              //     ),
              //     life: 3000,
              //   });
              // }

        showToast("success","Order status updated successfully")

            })
            .catch((err) => {
              console.log(err);
              setIsTabRefresh(false);
              // if (toast.current !== undefined) {
              //   toast.current.show({
              //     severity: "warn",
              //     summary: "Warning",

              //     content: (props) => (
              //       <div
              //         className="flex flex-column align-items-left"
              //         style={{ flex: "1" }}
              //       >
              //         <div className="flex align-items-center gap-2">
              //           <span className="p-toast-summary">vState Filings</span>
              //         </div>
              //         <div className="p-toast-detail">
              //           Order status update failed
              //         </div>
              //       </div>
              //     ),
              //     life: 3000,
              //   });
              // }
        showToast("error","Order status update failed")

            });
        }
      } else if (status.reject === true) {
        const temp = orderTempdata.orderDetails;
        console.log(temp);
        temp["statusId"] = 1003;
        temp["isPayLater"] = false;
        temp["orderUpdateNotes"] = updateNoteText;
        temp["orderUpdatedBy"] = superAdminId
          ? `vState-${loginUserName}`
          : loginUserName;
        temp["orderUpdatedDate"] = isoDate;
        temp["orderLineItems"] = orderTempdata.orderLineItem;
        temp["stateId"] = orderTempdata.state ? orderTempdata.state.id : null;
        temp["countyId"] = orderTempdata.orderDetails.countyId;
        const tempdata = {
          orderId: temp,
          orderUpdateNotes: updateNoteText,
          orderUpdatedBy: loginUserName,
          status: 7,
          orderJson: JSON.stringify(temp),
          strapiJson: orderTempdata.orderDetails.strapiOrderFormJson,
          orderUpdatedDate: isoDate,
        };
        if (updateNoteText !== "") {
          setIsTabRefresh(true);
          OrderServices.saveAllOrder(temp)
            .then((res) => {
              console.log(res);
              setRedirectedData(res.data.orderDetails);
              setApprovalDialog(false);
              setIsTabRefresh(false);
              setUpdateNoteText("");
              setOrderStatusErr("");
              setOrderUpdateNotesErr("");
              setSelectedFile(null);
              // if (toast.current !== undefined) {
              //   toast.current.show({
              //     severity: "success",
              //     summary: "Success",

              //     content: (props) => (
              //       <div
              //         className="flex flex-column align-items-left"
              //         style={{ flex: "1" }}
              //       >
              //         <div className="flex align-items-center gap-2">
              //           <span className="p-toast-summary">vState Filings</span>
              //         </div>
              //         <div className="p-toast-detail">
              //           Order status updated successfully
              //         </div>
              //       </div>
              //     ),
              //     life: 3000,
              //   });
              // }

        showToast("success","Order status updated successfully")

            })
            .catch((err) => {
              console.log(err);
              setIsTabRefresh(false);
              // if (toast.current !== undefined) {
              //   toast.current.show({
              //     severity: "warn",
              //     summary: "Warning",

              //     content: (props) => (
              //       <div
              //         className="flex flex-column align-items-left"
              //         style={{ flex: "1" }}
              //       >
              //         <div className="flex align-items-center gap-2">
              //           <span className="p-toast-summary">vState Filings</span>
              //         </div>
              //         <div className="p-toast-detail">
              //           Order status update failed
              //         </div>
              //       </div>
              //     ),
              //     life: 3000,
              //   });
              // }

        showToast("error","Order status update failed")

            });
        }
      }
    } else {
      if (updateNoteText === "") {
        setOrderUpdateNotesErr("Order update notes required");
      }
      if (status.approve === false && status.reject === false) {
        setOrderStatusErr("Order status required");
      }
    }
  };

  const handleUpdateStatusSubmit = () => {
    const currentDate = new Date();

    const isoDate = currentDate.toISOString();
    console.log(orderTempdata);
    console.log(updateStatus);
    if (orderTempdata) {
      if (selectedFile && updateStatus && updateNoteText !== "") {
        setIsTabRefresh(true);
        const formdata = new FormData();
        formdata.append("file", selectedFile);
        setIsTabRefresh(true);
        MasterServices.saveFile("orderdoc", formdata)
          .then((response) => {
            if (response.status === 201 || response.status === 200) {
              if (data) {
                const temp = orderTempdata.orderDetails;
                const orderLineItem = orderTempdata.orderLineItem;
                const orderDoc = {
                  documentPath: response.data,
                  documentName: selectedFile ? selectedFile.name : "file",
                };
                console.log(temp);
                MasterServices.saveDocument(orderDoc)
                  .then((res) => {
                    console.log(res);
                    if (Array.isArray(temp["documentsId"])) {
                      temp["documentsId"] = [...temp["documentsId"], res.data];
                    } else {
                      temp["documentsId"] = [res.data];
                    }
                    temp["statusId"] = updateStatus.id;
                    temp["orderLineItems"] = orderLineItem;
                    temp[
                      "orderUpdateNotes"
                    ] = `${selectedFile.name} file uploaded`;
                    temp["orderUpdatedBy"] =
                      superAdminId || superAdminMainId
                        ? `vState-${loginUserName}`
                        : loginUserName;
                    temp["actualCompletionDate"] =
                      updateStatus.statusName === "ORDER COMPLETED"
                        ? new Date().toISOString()
                        : null;
                    temp["orderUpdatedDate"] = isoDate;

                    if (
                      orderTempdata &&
                      orderTempdata.orderTypeID &&
                      orderTempdata.orderTypeID.orderShortName ===
                        "Independent Manager" &&
                      customPrice !== 0
                    ) {
                      if (Array.isArray(orderTempdata.orderLineItem)) {
                        orderTempdata.orderLineItem.forEach((val) => {
                          val.unitPrice = customPrice;
                        });
                      }
                    }

                    temp["orderLineItems"] = orderTempdata.orderLineItem;
                    temp["stateId"] = orderTempdata.state
                      ? orderTempdata.state.id
                      : null;
                    temp["countyId"] = orderTempdata.orderDetails.countyId;

                    console.log(temp);
                    OrderServices.saveAllOrder(temp)
                      .then((res) => {
                        console.log(res);

                        setRedirectedData(res.data.orderDetails);
                        setPendingStatusDialog(false);
                        setUpdateNoteText("");
                        setUpdateStatus(null);
                        setSelectedFile(null);
                        setIsTabRefresh(false);
                        setVisible(false);
                        setTemplate(null);
                        // if (toast.current !== undefined) {
                        //   toast.current.show({
                        //     severity: "success",
                        //     summary: "Success",
                        //     content: (props) => (
                        //       <div
                        //         className="flex flex-column align-items-left"
                        //         style={{ flex: "1" }}
                        //       >
                        //         <div className="flex align-items-center gap-2">
                        //           <span className="p-toast-summary">
                        //             vState Filings
                        //           </span>
                        //         </div>
                        //         <div className="p-toast-detail">
                        //           Order status updated successfully
                        //         </div>
                        //       </div>
                        //     ),
                        //     life: 3000,
                        //   });
                        // }

        showToast("success","Order status updated successfully")

                      })
                      .catch((err) => {
                        console.log(err);
                        setIsTabRefresh(false);
                        // if (toast.current !== undefined) {
                        //   toast.current.show({
                        //     severity: "warn",
                        //     summary: "Warning",

                        //     content: (props) => (
                        //       <div
                        //         className="flex flex-column align-items-left"
                        //         style={{ flex: "1" }}
                        //       >
                        //         <div className="flex align-items-center gap-2">
                        //           <span className="p-toast-summary">
                        //             vState Filings
                        //           </span>
                        //         </div>
                        //         <div className="p-toast-detail">
                        //           Order status update failed
                        //         </div>
                        //       </div>
                        //     ),
                        //     life: 3000,
                        //   });
                        // }

        showToast("error","Order status update failed")

                      });
                  })
                  .catch((err) => {
                    console.log(err);
                    setIsTabRefresh(false);

                    // if (toast.current !== undefined) {
                    //   toast.current.show({
                    //     severity: "warn",
                    //     summary: "Failed to upload",
                    //     detail: "Something went wrong",
                    //     life: 3000,
                    //   });
                    // }
        showToast("error","Something went wrong")

                  });
              }
            }
            if (response.status === 500) {
              // toast.current.show({
              //   severity: "warn",
              //   summary: "Failed to upload",
              //   detail: "Network error, Please try again!",
              //   life: 3000,
              // });
            
        showToast("error","Network error, Please try again!")
            
            }
            if (response.status === 504) {
              // toast.current.show({
              //   severity: "warn",
              //   summary: "Failed to upload",
              //   detail: "Gateway timeout!",
              //   life: 3000,
              // });
        showToast("error","Gateway timeout!")

            }

          })
          .catch((err) => {
            console.log(err);
            setIsTabRefresh(false);
          });
      } else if (updateStatus && updateNoteText !== "") {
        setIsTabRefresh(true);
        console.log(orderTempdata);
        const temp = orderTempdata.orderDetails;
        console.log(temp);
        temp["statusId"] = updateStatus.id;
        temp["orderUpdateNotes"] = updateNoteText;
        temp["orderUpdatedBy"] =
          superAdminId || superAdminMainId
            ? `vState-${loginUserName}`
            : loginUserName;
        temp["actualCompletionDate"] =
          updateStatus.statusName === "ORDER COMPLETED"
            ? new Date().toISOString()
            : null;
        temp["orderUpdatedDate"] = isoDate;
        console.log(orderTempdata.orderLineItem);
        console.log(orderTempdata.orderLineItem);
        if (
          orderTempdata &&
          orderTempdata.orderTypeID &&
          orderTempdata.orderTypeID.orderShortName === "Independent Manager" &&
          customPrice !== 0
        ) {
          if (Array.isArray(orderTempdata.orderLineItem)) {
            orderTempdata.orderLineItem.forEach((val) => {
              val.unitPrice = customPrice;
            });
          }
        }
        temp["orderLineItems"] = orderTempdata.orderLineItem;
        temp["stateId"] = orderTempdata.state ? orderTempdata.state.id : null;
        temp["countyId"] = orderTempdata.orderDetails.countyId;

        OrderServices.saveAllOrder(temp)
          .then((res) => {
            console.log(res);
            setRedirectedData(res.data.orderDetails);
            setPendingStatusDialog(false);
            setUpdateNoteText("");
            setUpdateStatus(null);
            setTemplate(null);
            setIsTabRefresh(false);

            // if (toast.current !== undefined) {
            //   toast.current.show({
            //     severity: "success",
            //     summary: "Success",
            //     content: (props) => (
            //       <div
            //         className="flex flex-column align-items-left"
            //         style={{ flex: "1" }}
            //       >
            //         <div className="flex align-items-center gap-2">
            //           <span className="p-toast-summary">vState Filings</span>
            //         </div>
            //         <div className="p-toast-detail">
            //           Order status updated successfully
            //         </div>
            //       </div>
            //     ),
            //     life: 3000,
            //   });
            // }
        showToast("success","Order status updated successfully")

          })
          .catch((err) => {
            console.log(err);
            setIsTabRefresh(false);
            // if (toast.current !== undefined) {
            //   toast.current.show({
            //     severity: "warn",
            //     summary: "Warning",

            //     content: (props) => (
            //       <div
            //         className="flex flex-column align-items-left"
            //         style={{ flex: "1" }}
            //       >
            //         <div className="flex align-items-center gap-2">
            //           <span className="p-toast-summary">vState Filings</span>
            //         </div>
            //         <div className="p-toast-detail">
            //           Order status update failed
            //         </div>
            //       </div>
            //     ),
            //     life: 3000,
            //   });
            // }

        showToast("error","Order status update failed")

          });
      } else {
        if (updateNoteText === "") {
          setOrderUpdateNotesErr("Order update notes required");
        }

        if (!updateStatus) {
          setOrderStatusErr("Order status required");
        }
      }
    }
  };

  const stripePay = async (item, orderData) => {
    try {
      const stripe = await stripePromise;

      const data = {
        product: item,
      };

      console.log(item);

      const productPaydata = item.map((val) => {
        return {
          name: val.productListing.displayName,
          unitAmount: Math.round(val.unitPrice * 100),
          quantity: 1,
        };
      });

      const paytemp = {
        lineItems: productPaydata,
      };

      // const response = await axios.post(`$/checkout`, data);
      setIsTabRefresh(true);
      OrderServices.paymentOrder(paytemp)
        .then((response) => {
          console.log(response);
          setIsTabRefresh(false);
          localStorage.setItem("sessionId", response.data.sessionId);
          // setPaySessionId(res.data.sessionId)

          // const sessionId = response.data.id;
          // console.log(sessionId)

          const localData = {
            data: orderData,
            res: response.data,
            orderLineItem: item,
            updatedBy: loginUserName,
          };
          localStorage.setItem("payment", JSON.stringify(localData));
          setTimeout(() => {
            window.location.href = response.data.checkoutUrl;
          }, 1000); // Small timeout to ensure data is saved

          // const { error } = await stripe.redirectToCheckout({
          //     sessionId: sessionId
          // });
          // if (error) {
          //     console.error("Stripe Checkout Error:", error);
          // }
        })
        .catch((err) => {
          console.log(err);
          setIsTabRefresh(false);
        });
    } catch (err) {
      console.error("Error during checkout process:", err);
    }
  };

  // const stripePay = async (item, orderData) => {

  //     try {

  //         const stripe = await stripePromise;

  //         const data = {

  //             product: item
  //         }

  //         const response = await axios.post(`${propertiesApp.stripe_host}/checkout`, data);
  //         console.log(response)

  //         const sessionId = response.data.id;
  //         console.log(sessionId)

  //         const localData = {
  //             data: orderData,
  //             res: response.data,
  //             orderLineItem: item,
  //             updatedBy: loginUserName
  //         }
  //         localStorage.setItem("payment", JSON.stringify(localData))
  //         const { error } = await stripe.redirectToCheckout({
  //             sessionId: sessionId
  //         });
  //         if (error) {
  //             console.error("Stripe Checkout Error:", error);
  //         }
  //     } catch (err) {
  //         console.error("Error during checkout process:", err);
  //     }
  // }

  const handlePaymentDialog = (data) => {
    console.log(data);
    setStripeData(data);
    setCloseProgresbar3(true);
  };

  const handlePayment = () => {
    if (stripeData) {
      console.log(stripeData);
      stripePay(stripeData.orderLineItem, stripeData);
    }
  };

  const getKeyPersonel = (id) => {
    console.log(id);
    if (id) {
      const temp = allKeyPersonnel.filter((val) => val.id === id);
      console.log(temp);
      if (temp.length > 0) {
        return temp[0].keyPersonalType;
      } else {
        return null;
      }
    }
    return null;
  };

  function bytesToKilobytes(bytes) {
    if (typeof bytes !== "number" || isNaN(bytes) || bytes < 0) {
      console.log("Its not number");
    } else {
      return bytes / 1024;
    }
  }

  const onSelectionChange = (e) => {
    let selectedData = e.value;
    setSelectedProduct(selectedData);
  };

  console.log(data);

  return (
    <>
      <Toast ref={toast} />

      <div className="grid webapp-content-container">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
          <div className="card search-cand-card2">
            <div className="col-12 grid font-fam-for-all py-3 px-3">
              <div className="col-7" style={{ paddingLeft: "20px" }}>
                <p className="search-cand-header mb-0">Orders</p>
                <p className="search-cand-sub-header mb-0">
                  Manage all your orders here.
                </p>
              </div>
              <div className="col-5">
                <div className="flex align-items-center justify-content-end gap-2 mt-3">
                  {!superAdminId && !superAdminMainId && (
                    <Button
                      className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                      icon="pi pi-plus"
                      label="Create order"
                      onClick={(e) => history("/orderform")}
                    ></Button>
                  )}
                  {/* <Button className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3" icon="pi pi-eye" label='View Draft' onClick={(e) => setVisible4(true)} ></Button> */}
                  <Button
                    className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
                    label="Export CSV"
                    icon="pi pi-download"
                    onClick={exportExcel}
                  ></Button>
                </div>
              </div>
            </div>

            <div className="card-devider"></div>
            <div
              className="col-12"
              style={{ height: "fit-content", padding: "20px 20px 0px 30px" }}
            >
              <div className="col-12 grid">
                <div
                  className="flex flex-wrap align-items-center justify-content-start gap-3"
                  style={{ width: "1300px" }}
                >
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      placeholder="Search"
                      style={{ borderRadius: "6px", width: "200px" }} // Set a width to prevent overflow
                      value={filteredInput}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleSearch();
                        }
                      }}
                      className="vstate-input-field-fixed"
                      onChange={(e) => {
                        setFilteredInput(e.target.value);
                        if (e.target.value === "") {
                          setProducts(tempProduct);
                        }
                      }}
                    />
                  </span>

                  <span>
                    <Dropdown
                      options={allStates}
                      filter
                      className="vstate-input-field-fixed"
                      optionLabel="stateFullDesc"
                      value={statesId}
                      optionValue="id"
                      onChange={(e) => setStatesId(e.value)}
                      placeholder="Filtered By Domicile"
                      style={{ borderRadius: "8px", width: "200px" }} // Set a width to prevent overflow
                    />
                  </span>

                  <span>
                    <Dropdown
                      options={entityTypes}
                      filter
                      className="vstate-input-field-fixed"
                      optionLabel="orderShortName"
                      value={entityType}
                      onChange={(e) => setEntityType(e.value)}
                      placeholder="Filtered By Order Type"
                      style={{ borderRadius: "8px", width: "200px" }} // Set a width to prevent overflow
                    />
                  </span>

                  <span>
                    <Dropdown
                      options={allOrderStatus}
                      filter
                      className="vstate-input-field-fixed"
                      optionLabel="statusName"
                      optionValue="id"
                      value={statusName}
                      onChange={(e) => setStatusName(e.value)}
                      placeholder="Filtered By Status"
                      style={{ borderRadius: "8px", width: "200px" }} // Set a width to prevent overflow
                    />
                  </span>

                  <span>
                    <Button
                      type="button"
                      style={{ fontSize: "14px", marginRight: "10px" }} // Adjust margin if necessary
                      className="vstate-button font-fam-for-all text-center"
                      onClick={handleFilter}
                    >
                      Filter
                    </Button>
                  </span>

                  <span>
                    <Button
                      type="button"
                      style={{ fontSize: "14px" }}
                      className="font-fam-for-all text-center vstate-reverse-button"
                      onClick={handleClearFilter}
                    >
                      Clear Filter
                    </Button>
                  </span>
                </div>
              </div>
            </div>

            <div style={{ padding: "30px" }}>
              <div className="grid">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 pt-0 ">
                  <div className="">
                    <div className="vstate-card">
                      {showSkeleton ? (
                        <DataTable
                          header={searchCandHeader}
                          value={products}
                          rows={10}
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          inline
                          paginator
                          emptyMessage="No Order Found"
                          filters={filters}
                          className="master-datatable"
                          globalFilterFields={["orderDetails.id"]}
                          selectionMode="single"
                          selection={selectedProduct}
                          onSelectionChange={onSelectionChange}
                          onRowSelect={onRowSelect}
                          metaKeySelection={false}
                        >
                          <Column
                            body={itemTemplate}
                            className="datatable-body-icon"
                          ></Column>
                        </DataTable>
                      ) : (
                        <DataTable
                          header={searchCandHeader}
                          value={items}
                          rows={5}
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          inline
                          paginator
                          emptyMessage="No Candidate Found"
                          selectionMode="single"
                          selection={selectedProduct}
                          onSelectionChange={(e) => setSelectedProduct(e.value)}
                          onRowSelect={onRowSelect}
                          metaKeySelection={false}
                        >
                          <Column
                            body={<Skeleton />}
                            className="datatable-body-icon"
                          ></Column>
                        </DataTable>
                      )}
                    </div>
                  </div>
                </div>
                {data !== null &&
                data.orderDetails !== undefined &&
                showSkeleton ? (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 pt-0">
                    <div className="vstate-card  col-12" ref={printRef}>
                      <div className="col-12 srch-cand-card">
                        <div className="col-12 grid pb-0">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                            <div className="flex">
                              <div className=" srch-cand-text2">{`ORDER-ID ${
                                data.orderDetails.orderId
                                  ? data.orderDetails.orderId
                                  : data.orderDetails.id
                              }`}</div>
                              <div className="badge-container ml-2">
                                <div
                                  className="badge-main m-0 grid"
                                  style={{
                                    background: getBadgeBackgroundColor(data),
                                  }}
                                >
                                  <div className="col-12 flex align-items-center p-0">
                                    {
                                      // data.orderUpdateHistory.length > 0 ?
                                      //     <span className='badge-label ml-1' style={{ color: getBadgeTextColor(getStatus(data.orderUpdateHistory)) }}>
                                      //         {getStatus(data.orderUpdateHistory)}
                                      //     </span>
                                      //     :
                                      <span
                                        className="badge-label ml-1"
                                        style={{
                                          color: getBadgeTextColor(
                                            data.statusId
                                              ? data.statusId.statusName
                                              : ""
                                          ),
                                        }}
                                      >
                                        {data.statusId
                                          ? data.statusId.statusName
                                          : ""}
                                      </span>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <p className=' srch-cand-text' >{contactData.contactDetails.displayName}</p> */}
                            <p className=" srch-cand-text">
                              {data.companyId.companyName}
                            </p>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7 d-flex justify-content-end align-items-center">
                            <div className="flex align-items-center justify-content-end gap-2">
                              {data.statusId.statusName !==
                                "PAY LATER REQUEST RAISED" &&
                                data.statusId.statusName !== "DRAFT" &&
                                data.orderDetails.transactionId === null &&
                                IsPriceAvail && (
                                  <Button
                                    className="vstate-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                                    label="Pay now"
                                    onClick={() => handlePaymentDialog(data)}
                                  ></Button>
                                )}

                              {/* <Button className="  vstate-button form-Btn-Label font-fam-for-all text-center  form-label mr-3" label='Pay now' onClick={() => handlePaymentDialog(data)} ></Button> */}
                              {
                                // data.orderUpdateHistory.length > 0 ?
                                //     getStatus(data.orderUpdateHistory) === "PAY LATER REQUEST RAISED" ? superAdminId && <Button className="  vstate-button form-Btn-Label font-fam-for-all text-center  form-label mr-3" icon="pi pi-pencil" label='Approval pending' onClick={() => handleApproveDialog(data)} ></Button>
                                //         : allStatus.length > 0 ? <Button className="  vstate-button form-Btn-Label font-fam-for-all text-center  form-label mr-3" icon="pi pi-pencil" label='Update status' onClick={() => handlePendingStatusDialog(data)} ></Button> : ""
                                //     :
                                data.statusId.statusName ===
                                  "PAY LATER REQUEST RAISED" &&
                                (superAdminId || superAdminMainId) ? (
                                  <Button
                                    className="  vstate-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                                    icon="pi pi-pencil"
                                    label="Approval pending"
                                    onClick={() => handleApproveDialog(data)}
                                  ></Button>
                                ) : (
                                  allStatus.length > 0 &&
                                  data.statusId.statusName !== "DRAFT" &&
                                  !(
                                    data.statusId.statusName ===
                                      "PAY LATER REQUEST REJECTED" &&
                                    data.orderDetails.transactionId !== null &&
                                    !(superAdminId || superAdminMainId)
                                  ) && (
                                    <Button
                                      className="  vstate-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                                      icon="pi pi-pencil"
                                      label="Update status"
                                      onClick={() =>
                                        handlePendingStatusDialog(data)
                                      }
                                    ></Button>
                                  )
                                )
                              }
                              {!(superAdminId || superAdminMainId) &&
                                (data.statusId.statusName ===
                                  "GOVT QUERY RAISED" ||
                                  data.statusId.statusName === "DRAFT" ||
                                  data.statusId.statusName ===
                                    "VSTATE QUERY RAISED") && (
                                  <Button
                                    className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                                    icon="pi pi-pencil"
                                    label="Edit"
                                    onClick={() => handleEditForm(data)}
                                  ></Button>
                                )}

                              {(superAdminId || superAdminMainId) &&
                                data.statusId.statusName ===
                                  "ORDER IN PROGRESS" &&
                                data.orderTypeID &&
                                data.orderTypeID.orderFullDesc ===
                                  "Entity Formation" &&
                                data.orderTypeID &&
                                data.orderTypeID.orderShortName !== "BOI" && (
                                  <Button
                                    className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                                    icon="pi pi-print"
                                    label="Fill online form"
                                    onClick={() => handleOnlineForm(data)}
                                  ></Button>
                                )}
                              {data.orderTypeID &&
                                data.orderTypeID.orderShortName === "BOI" &&
                                (superAdminId || superAdminMainId) &&
                                data.statusId.statusName ===
                                  "ORDER IN PROGRESS" && (
                                  <Button
                                    className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                                    icon="pi pi-print"
                                    label="File BOI Form"
                                    onClick={() => handleBoiSubmit(data)}
                                  ></Button>
                                )}
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                                    icon="pi pi-print"
                                    label="Print"
                                  ></Button>
                                )}
                                content={() => printRef.current}
                              />
                              {/* <Button className=" form-Btn-Label vstate-reverse-button font-fam-for-all text-center  form-label mr-3" label='Save as PDF' icon="pi pi-download" onClick={() => setVisible(true)} ></Button> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-devider"> </div>
                      <div className="card my-2 p-4">
                        <div className="grid">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                            <div className="order-contact-card-container">
                              <div className="order-contact-card-main font-fam-for-all">
                                <div className="grid w-full p-2">
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Order Type
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Entity Type
                                  </div>

                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    State
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Placed By
                                  </div>

                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0 "
                                    style={{ fontSize: "14px" }}
                                  >
                                    {data.orderTypeID
                                      ? data.orderTypeID.orderFullDesc
                                      : ""}
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {data.entityTypeId
                                      ? data.entityTypeId.entityFullDesc
                                      : ""}
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {data.state ? data.state.stateFullDesc : ""}
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {`${data.contactDetails.firstName} ${data.contactDetails.lastName}`}
                                  </div>
                                </div>
                                <div className="grid w-full p-2">
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Order Date
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Expected Date
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Completion Date
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Account Manager
                                  </div>

                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {data.orderDetails.orderDate
                                      ? formatISODate(
                                          data.orderDetails.orderDate
                                        )
                                      : ""}
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {data.orderDetails.tentativeFulfillmentDate
                                      ? formatISODate(
                                          data.orderDetails
                                            .tentativeFulfillmentDate
                                        )
                                      : ""}
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {data.orderDetails.actualCompletionDate
                                      ? formatISODate(
                                          data.orderDetails.actualCompletionDate
                                        )
                                      : ""}
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {data.companyId.accountManagerId
                                      ? `${data.companyId.accountManagerId.firstName} ${data.companyId.accountManagerId.lastName}`
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                            <div className="order-contact-card-container">
                              <div className="order-contact-card-main">
                                <div className="order-contact-card-header-main">
                                  <Avatar
                                    label={
                                      imageData && data.contactDetails
                                        ? ""
                                        : getInitials(
                                            `${data.contactDetails.firstName} ${data.contactDetails.lastName}`
                                          )
                                    }
                                    image={
                                      imageData
                                        ? `data:image/jpeg;base64,${imageData}`
                                        : ""
                                    }
                                    icon="pi pi-user"
                                    size="large"
                                    shape="circle"
                                    className="custom-avatar"
                                  />

                                  <div>
                                    <div className="order-contact-card-header-text1">
                                      {`${data.contactDetails.firstName} ${data.contactDetails.lastName}`}
                                    </div>
                                    <div className="order-contact-card-header-text2"></div>
                                  </div>
                                </div>
                                <div className="card-devider"> </div>
                                <div className="order-contact-card-content-main">
                                  <div className="order-contact-card-text-main">
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_966_10329)">
                                          <path
                                            d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                            stroke="#175CD3"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_966_10329">
                                            <rect
                                              width="20"
                                              height="20"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                    <div className="order-contact-card-text1">
                                      {data.contactDetails.mobileNo}
                                    </div>
                                  </div>

                                  <div className="order-contact-card-text-main">
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                          stroke="#175CD3"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </div>
                                    <div className="order-contact-card-text1">
                                      {data.contactDetails.emailId}
                                    </div>
                                  </div>

                                  {/* <div className='order-contact-card-text-main'>
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className='order-contact-card-text1'>
                                                                            {addr1 !== "" ? `${addr1},${addr2},${city},${zip}` : ""}
                                                                        </div>
                                                                    </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-devider"> </div>
                      <TabView
                        activeIndex={activeIndex}
                        onTabChange={handleTabChange}
                      >
                        <TabPanel header="Order Details" leftIcon=" mr-2">
                          <div
                            className=" grid"
                            style={{
                              backgroundColor: "#fbfcfa",
                              padding: "15px",
                            }}
                          >
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                              <div className="order-details-main-card">
                                <div className="order-details-main-card-header p-2">
                                  <div className="order-details-main-card-header-text">
                                    Receipt
                                  </div>
                                </div>
                                <div className="order-details-main-card-content-main">
                                  {data.orderLineItem.length > 0
                                    ? data.orderLineItem
                                        .sort(
                                          (a, b) =>
                                            a.productListing.displayPreference -
                                            b.productListing.displayPreference
                                        )
                                        .map((val) => (
                                          <div
                                            className="order-details-card-text-container"
                                            key={val.productListing.id}
                                          >
                                            <div className="order-details-card-text-container-header">
                                              <div className="order-details-card-text-container-header-text1">
                                                {val.productListing.displayName}
                                              </div>
                                              <div className="order-details-card-text-container-header-text2">
                                                ${val.unitPrice}
                                              </div>
                                            </div>
                                            <ul>
                                              <li className="order-details-card-text-list">
                                                The filing fee for the
                                                application as per the state
                                                selected
                                              </li>
                                              <li className="order-details-card-text-list">
                                                Government fee
                                              </li>
                                            </ul>
                                            <div className="card-devider"></div>
                                          </div>
                                        ))
                                    : ""}
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                              <div className="order-details-main-card">
                                <div className="order-details-main-card-header p-2">
                                  <div className="order-details-main-card-header-text">
                                    Order history
                                  </div>
                                </div>
                                <div className="order-details-main-card-content-main">
                                  {data.orderUpdateHistory.length > 0 ? (
                                    <Timeline
                                      value={data.orderUpdateHistory}
                                      className="customized-timeline"
                                      marker={customizedMarker}
                                      content={customizedContent}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel header="Order Preview">
                          <div
                            className=" grid"
                            style={{
                              backgroundColor: "#fbfcfa",
                              padding: "15px",
                            }}
                          >
                            <div className="col-12">
                              <div className="order-details-main-card">
                                <div className="order-details-main-card-header p-2">
                                  <div className="order-details-main-card-header-text">
                                    Order preview
                                  </div>
                                </div>
                                <div className="">
                                  <OrderPreview data={data} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                        <TabPanel header="Company Details" leftIcon=" mr-2">
                          <div
                            className="col-12 "
                            style={{
                              backgroundColor: "#fbfcfa",
                              padding: "15px",
                            }}
                          >
                            <div>
                              <div className="card my-3 hover-card slide-in">
                                <div
                                  className=" flex px-4 pt-4 pb-0"
                                  style={{ justifyContent: "space-between" }}
                                >
                                  <h2 className="srch-cand-text2">
                                    Company Details
                                  </h2>
                                  <div></div>
                                </div>

                                <div className="grid font-fam-for-all px-4 py-2">
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Entity Name
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Business Structure
                                  </div>

                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Service State
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Domestic State
                                  </div>

                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {data.companyId.companyName}
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {data.companyId.entityType}
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {getState(data.companyId.serviceStateId)}
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {getState(data.companyId.domesticStateId)}
                                  </div>
                                </div>
                                <div className="grid px-4 py-4">
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Formation Date
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Dissolution Date
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Entity Number
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                    style={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                    }}
                                  >
                                    EIN Number
                                  </div>

                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {formatISODate(
                                      data.companyId.formationDate
                                    )}
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {data.companyId.dissolutionDate
                                      ? formatISODate(
                                          data.companyId.dissolutionDate
                                        )
                                      : null}
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {data.companyId.entityNo}
                                  </div>
                                  <div
                                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 py-0"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {data.companyId.einNo}
                                  </div>
                                </div>
                                {/* <div className="grid px-4 py-4">


                                                                    <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        Company Email
                                                                    </div>
                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        Company Phone
                                                                    </div>
                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        Fax
                                                                    </div>

                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                        website
                                                                    </div>

                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                        {data.companyId.companyEmail}
                                                                    </div>
                                                                    <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                        {data.companyId.phoneNo}
                                                                    </div>
                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                        {data.companyId.fax}
                                                                    </div>
                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                        {data.companyId.websiteUrl}
                                                                    </div>

                                                                </div> */}
                              </div>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel header="Contact">
                          <div className="grid gap-2 px-4 py-6">
                            {data.companyKeyPersonal.length > 0
                              ? data.companyKeyPersonal.map((val) => {
                                  if (val) {
                                    return (
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                        <div className="order-contact-card-container">
                                          <div className="order-contact-card-main p-4">
                                            <div className="order-contact-card-header-main">
                                              <Avatar
                                                label={getInitials(
                                                  val.companyKeyPersonnel
                                                    .keyPersonnelName
                                                )}
                                                icon="pi pi-user"
                                                size="large"
                                                shape="circle"
                                                className="custom-avatar"
                                              />

                                              <div>
                                                <div className="order-contact-card-header-text1">
                                                  {
                                                    val.companyKeyPersonnel
                                                      .keyPersonnelName
                                                  }
                                                </div>
                                                <div className="order-contact-card-header-text2">
                                                  {getKeyPersonel(
                                                    val.companyKeyPersonnel
                                                      .keyPersonnelTypeId
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="card-devider">
                                              {" "}
                                            </div>
                                            <div className="order-contact-card-text-main">
                                              <div>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="20"
                                                  height="20"
                                                  viewBox="0 0 20 20"
                                                  fill="none"
                                                >
                                                  <g clip-path="url(#clip0_966_10329)">
                                                    <path
                                                      d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                      stroke="#175CD3"
                                                      stroke-width="2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                  </g>
                                                  <defs>
                                                    <clipPath id="clip0_966_10329">
                                                      <rect
                                                        width="20"
                                                        height="20"
                                                        fill="white"
                                                      />
                                                    </clipPath>
                                                  </defs>
                                                </svg>
                                              </div>
                                              <div className="order-contact-card-text1">
                                                {
                                                  val.companyKeyPersonnel
                                                    .contactNo
                                                }
                                              </div>
                                            </div>

                                            <div className="order-contact-card-text-main">
                                              <div>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="20"
                                                  height="20"
                                                  viewBox="0 0 20 20"
                                                  fill="none"
                                                >
                                                  <path
                                                    d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                    stroke="#175CD3"
                                                    stroke-width="2"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </svg>
                                              </div>
                                              <div className="order-contact-card-text1">
                                                {
                                                  val.companyKeyPersonnel
                                                    .emailId
                                                }
                                              </div>
                                            </div>

                                            <div className="order-contact-card-text-main">
                                              <div>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="20"
                                                  height="20"
                                                  viewBox="0 0 20 20"
                                                  fill="none"
                                                >
                                                  <path
                                                    d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                    stroke="#175CD3"
                                                    stroke-width="1.66667"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                  <path
                                                    d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                    stroke="#175CD3"
                                                    stroke-width="1.66667"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  />
                                                </svg>
                                              </div>
                                              <div className="order-contact-card-text1">
                                                {val.companyKeyPersonnel
                                                  .addressMasterId
                                                  .addressLine1 !== ""
                                                  ? `${
                                                      val.companyKeyPersonnel
                                                        .addressMasterId
                                                        .addressLine1
                                                    },${
                                                      val.companyKeyPersonnel
                                                        .addressMasterId
                                                        .addressLine1
                                                    },${
                                                      val.companyKeyPersonnel
                                                        .addressMasterId.city
                                                    },${getState(
                                                      val.companyKeyPersonnel
                                                        .addressMasterId.stateId
                                                    )} ${
                                                      val.companyKeyPersonnel
                                                        .addressMasterId
                                                        .postalCode
                                                    }`
                                                  : ""}
                                              </div>
                                            </div>
                                            {/* <div>
                                                                                        <CompanyKeyPersonnelDocuments data={val.companyKeyPersonal}/>
                                                                                    </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                              : ""}
                          </div>
                        </TabPanel>

                        <TabPanel header="Documents">
                          <div
                            className="col-12 "
                            style={{
                              backgroundColor: "#fbfcfa",
                              padding: "15px",
                            }}
                          >
                            <div className="font-fam-for-all">
                              <div className="card my-3 hover-card slide-in1">
                                <div className="flex justify-between">
                                  <div className="p-4">
                                    <h2 className="srch-cand-text2">
                                      Documents
                                    </h2>
                                    <div className="srch-cand-text"></div>
                                  </div>
                                  <div>
                                    <div className="p-4">
                                      <button
                                        className="datatable-highlight-color"
                                        onClick={() => setVisible6(true)}
                                      >
                                        <div className="flex align-items-center webapp-global-sm-size">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                          >
                                            <path
                                              d="M10.0003 4.16669V15.8334M4.16699 10H15.8337"
                                              stroke="#004EEC"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                          <div className="font-medium">
                                            Add Document
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="grid p-4">
                                  <div className="col-12">
                                    <Documents data={data} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel header="Status history">
                          <div
                            className=" grid"
                            style={{
                              backgroundColor: "#fbfcfa",
                              padding: "15px",
                            }}
                          >
                            <div className="col-12">
                              <div className="order-details-main-card">
                                <div className="order-details-main-card-header p-2">
                                  <div className="order-details-main-card-header-text">
                                    Order status history
                                  </div>
                                </div>
                                <div className="">
                                  <OrderChat data={data.orderUpdateHistory} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPanel>

                        {data.companyId.accountManagerId ? (
                          <TabPanel header="Account Manager">
                            <div className="grid gap-2 px-4 py-6">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                <div className="order-contact-card-container">
                                  <div className="order-contact-card-main">
                                    <div className="order-contact-card-header-main">
                                      <Avatar
                                        label={getInitials(
                                          `${data.companyId.accountManagerId.firstName} ${data.companyId.accountManagerId.lastName}`
                                        )}
                                        icon="pi pi-user"
                                        size="large"
                                        shape="circle"
                                        className="custom-avatar"
                                      />

                                      <div>
                                        <div className="order-contact-card-header-text1">
                                          {`${data.companyId.accountManagerId.firstName} ${data.companyId.accountManagerId.lastName}`}
                                        </div>
                                        <div className="order-contact-card-header-text2">
                                          Account Manager
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-devider"> </div>
                                    <div className="order-contact-card-content-main">
                                      <div className="order-contact-card-text-main">
                                        <div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                          >
                                            <g clip-path="url(#clip0_966_10329)">
                                              <path
                                                d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                stroke="#175CD3"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_966_10329">
                                                <rect
                                                  width="20"
                                                  height="20"
                                                  fill="white"
                                                />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </div>
                                        <div className="order-contact-card-text1">
                                          {`${data.companyId.accountManagerId.phone}`}
                                        </div>
                                      </div>

                                      <div className="order-contact-card-text-main">
                                        <div>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                          >
                                            <path
                                              d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                              stroke="#175CD3"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </div>
                                        <div className="order-contact-card-text1">
                                          {`${data.companyId.accountManagerId.email}`}
                                        </div>
                                      </div>

                                      {/* <div className='order-contact-card-text-main'>
                                                                        <div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className='order-contact-card-text1'>
                                                                            {addr1 !== "" ? `${addr1},${addr2},${city},${zip}` : ""}
                                                                        </div>
                                                                    </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                        ) : (
                          ""
                        )}
                      </TabView>
                    </div>
                  </div>
                ) : showSkeleton ? (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 pt-0 ">
                    <div className="card col-12" style={{ height: "255px" }}>
                      <div
                        className="grid justify-content-center align-items-center"
                        style={{ height: "255px" }}
                      >
                        {products?.length > 1 ? (
                          <Button
                            className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
                            label="Add New Company"
                            icon="pi pi-plus"
                            onClick={() => setVisible(true)}
                          ></Button>
                        ) : (
                          "No order found"
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className=" card col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 pt-0">
                    <div className="border-round border-1 surface-border p-4 surface-card">
                      <div className="flex mb-3">
                        <Skeleton
                          shape="circle"
                          size="4rem"
                          className="mr-2"
                        ></Skeleton>
                        <div>
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                          <Skeleton height=".5rem"></Skeleton>
                        </div>
                      </div>
                      <Skeleton width="100%" height="150px"></Skeleton>
                      <div className="flex justify-content-between mt-3">
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <AddOrderDocuments
            visible={visible6}
            setVisible={setVisible6}
            data={data}
          />

          <Dialog
            visible={closeProgresbar}
            modal={false}
            onHide={CloseProgresbarheader}
            className="font-fam-for-all"
          >
            <p className="m-0 p-3">
              <div className="d-flex align-items-center">
                {/* <img src={SuccessImage} width='60px' height='60px' /> */}
                {/* <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
                <p className="font-fam-for-all successMessage-font">
                  Data Added Successfully
                </p>
              </div>
              <div className="flex justify-content-end dialog-form-md-group-Btn">
                <Button
                  type="button"
                  className="mt-2 form-Btn form-Btn-Label font-fam-for-all  text-lg  mr-2"
                  onClick={CloseProgresbarheader}
                >
                  Ok
                </Button>
              </div>
            </p>
          </Dialog>

          <Dialog
            visible={closeProgresbar1}
            modal={false}
            onHide={CloseProgresbarheader1}
            className="font-fam-for-all"
          >
            <p className="m-0 p-3">
              <div className="d-flex align-items-center">
                {/* <img src={ErroImage} width='60px' height='60px' /> */}
                {/* <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
                <p className="font-fam-for-all successMessage-font">
                  Data Added Failed
                </p>
              </div>
              <div className="flex justify-content-end dialog-form-md-group-Btn">
                <Button
                  type="button"
                  className="mt-2 form-Btn form-Btn-Label font-fam-for-all  text-lg  mr-2"
                  onClick={CloseProgresbarheader1}
                >
                  Ok
                </Button>
              </div>
            </p>
          </Dialog>

          <Dialog
            visible={pendingStatusDialog}
            modal={false}
            onHide={CloseProgresbarheader}
            className="payment-dialog font-fam-for-all"
          >
            <div className="w-[800px]">
              <section className="overflow-hidden  items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                <div className="grid custom-sidebar-header">
                  <div className="grid col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11 px-4 pt-4 pb-0">
                    <div className="col-12 sidebar-header-text1 pb-0">
                      Update order status
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1 flex justify-content-end pt-4 pb-0">
                    <button
                      onClick={() => {
                        setPendingStatusDialog(false);
                        setUpdateNoteText("");
                        setUpdateStatus(null);
                        setOrderStatusErr("");
                        setOrderUpdateNotesErr("");
                        setSelectedFile(null);
                        setCustomPriceErr("");
                        setCustomPrice(0);
                        setTemplate(null);
                      }}
                    >
                      <i className="pi pi-times"></i>
                    </button>
                  </div>
                  <div className="col-12 sidebar-header-text2 px-4">
                    Track and update order status.
                  </div>
                </div>

                <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                  <div className="grid mt-3">
                    <div className="col-12" style={{ fontSize: "14px" }}>
                      <span className=" mr-3">
                        <div class="relative">
                          <Dropdown
                            filter
                            placeholder="Choose status"
                            style={{ height: "40px" }}
                            value={updateStatus}
                            options={allStatus}
                            optionLabel="statusName"
                            onChange={(e) => {
                              setOrderStatusErr("");
                              setUpdateStatus(e.value);
                            }}
                            className="w-full"
                          />
                          <label
                            htmlFor="floating_outlined25"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Status{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {orderStatusErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {orderStatusErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    {orderTempdata &&
                      orderTempdata.orderTypeID &&
                      orderTempdata.orderTypeID.orderShortName ===
                        "Independent Manager" &&
                      currentStatus &&
                      (currentStatus.statusName === "IN REVIEW" ||
                        currentStatus.statusName === "CLIENT RESPONDED") && (
                        <div className="col-12" style={{ fontSize: "14px" }}>
                          <span className=" mr-3">
                            <div class="relative">
                              <InputText
                                style={{ height: "40px" }}
                                onChange={handleCustomPrice}
                                className="w-full"
                              />
                              <label
                                htmlFor="floating_outlined25"
                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                              >
                                Custom Price{" "}
                                <span className="form-field-mandatory"></span>
                              </label>
                            </div>
                            {customPriceErr !== "" ? (
                              <p className="error-msg font-fam-for-all">
                                {customPriceErr}
                              </p>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      )}
                    {(superAdminId || superAdminMainId) && (
                      <div className="col-12" style={{ fontSize: "14px" }}>
                        <span className=" mr-3">
                          <div class="relative">
                            <Dropdown
                              filter
                              placeholder="Choose template"
                              style={{ height: "40px" }}
                              options={allTemplate}
                              value={template}
                              optionLabel="templateName"
                              onChange={(e) => setTemplate(e.value)}
                              className="w-full"
                            />
                            <label
                              htmlFor="floating_outlined25"
                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >
                              Template{" "}
                              <span className="form-field-mandatory"></span>
                            </label>
                          </div>
                        </span>
                      </div>
                    )}

                    <div className="col-12" style={{ fontSize: "14px" }}>
                      <span className=" mr-3">
                        <div class="relative">
                          <InputTextarea
                            rows={5}
                            cols={100}
                            value={updateNoteText}
                            placeholder="Type a description about status update "
                            onChange={(e) => {
                              setOrderUpdateNotesErr("");
                              setUpdateNoteText(e.target.value);
                            }}
                            className="w-full"
                          />
                          <label
                            htmlFor="floating_outlined25"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Notes{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {orderUpdateNotesErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {orderUpdateNotesErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <div className="col-12" style={{ fontSize: "14px" }}>
                      <div class="relative">
                        <div className="input-sidebar-card ">
                          <div className="px-6 py-4">
                            <div
                              className="flex"
                              style={{ justifyContent: "space-between" }}
                            >
                              <div className="font-fam-for-all">
                                <span>
                                  <i className="pi pi-address-book"></i>
                                </span>
                                Upload order documents
                              </div>
                            </div>
                            <div>
                              Manage your orders with simple file upload.
                            </div>
                            <div className="card pt-4 px-2 mt-4">
                              <div className="flex justify-content-center align-items-center pb-2">
                                <div className="text-center">
                                  <Button
                                    icon={
                                      selectedFile
                                        ? "pi pi-upload"
                                        : "pi pi-upload"
                                    }
                                    // label={selectedFile ? selectedFile.name : ''}
                                    className="p-button-rounded bg-gray-400 border-gray-400 p-button-primary p-mb-3"
                                    aria-label="Upload"
                                    onClick={handleClick}
                                    disabled={selectedFile ? true : false}
                                  />
                                  <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    accept=".csv"
                                  />

                                  <div className="upload-rules font-fam-for-all">
                                    <p className="webapp-global-sm-size">
                                      (max. 10MB)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {selectedFile ? (
                          <>
                            <div className="bulk-upload-status-container mt-4">
                              <div className="bulk-upload-status-card1 input-sidebar-card">
                                <div className="bulk-upload-status-card1-content1">
                                  <div className="bulk-upload-status-card1-content1-button-main1">
                                    <div className="bulk-upload-status-card1-content1-button-main1-base">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                      >
                                        <path
                                          d="M8.6665 1.3335H3.99984C3.64622 1.3335 3.30708 1.47397 3.05703 1.72402C2.80698 1.97407 2.6665 2.31321 2.6665 2.66683V13.3335C2.6665 13.6871 2.80698 14.0263 3.05703 14.2763C3.30708 14.5264 3.64622 14.6668 3.99984 14.6668H11.9998C12.3535 14.6668 12.6926 14.5264 12.9426 14.2763C13.1927 14.0263 13.3332 13.6871 13.3332 13.3335V6.00016M8.6665 1.3335L13.3332 6.00016M8.6665 1.3335V6.00016H13.3332"
                                          stroke="#145EF0"
                                          stroke-width="1.33333"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="bulk-upload-status-card1-content1-button-main2">
                                    <div className="bulk-upload-status-card1-content1-button-main2-base">
                                      <div className="bulk-upload-status-text1">
                                        {selectedFile.name}
                                      </div>
                                      <div className="bulk-upload-status-text2">
                                        {bytesToKilobytes(selectedFile.size)}Kb
                                      </div>
                                    </div>
                                    <div className="bulk-upload-status-card1-content1-button-main2-base1">
                                      <div className="progress-bar-container">
                                        {/* <div className='progress-bar-empty'>

                                                    </div> */}
                                        <div className="progress-bar-fill"></div>
                                      </div>
                                      <div className="progress-bar-label">
                                        100%
                                      </div>
                                    </div>
                                  </div>

                                  <div className="">
                                    <button
                                      onClick={() => {
                                        setSelectedFile(null);
                                        if (fileInputRef.current) {
                                          fileInputRef.current.value = null;
                                        }
                                      }}
                                    >
                                      <i className="pi pi-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-3 items-center mt-2 justify-content-end w-full text-base font-medium max-md:max-w-full">
                    <div className="flex gap-3 justify-content-end items-start self-stretch my-auto min-w-[240px]">
                      <Button
                        className="vstate-reverse-button"
                        label="Cancel"
                        onClick={() => {
                          setPendingStatusDialog(false);
                          setUpdateNoteText("");
                          setUpdateStatus(null);
                          setOrderStatusErr("");
                          setOrderUpdateNotesErr("");
                          setSelectedFile(null);
                          setCustomPriceErr("");
                          setCustomPrice(0);
                          setTemplate(null);
                        }}
                      />
                      <Button
                        className="vstate-button"
                        label="Submit"
                        onClick={handleUpdateStatusSubmit}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Dialog>

          <Dialog
            visible={approvalDialog}
            modal={false}
            onHide={CloseProgresbarheader1}
            className="payment-dialog font-fam-for-all"
          >
            <div className="w-[800px]">
              <section className="overflow-hidden  items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                <div className="grid custom-sidebar-header">
                  <div className="grid col-12 col-sm-12 col-md-12 col-lg-12 col-xl-11 px-4 pt-4 pb-0">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 sidebar-header-text1 pb-0">
                      Approval Pending
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1 flex justify-content-end pt-4 pb-0">
                    <button
                      onClick={() => {
                        setApprovalDialog(false);
                        setUpdateNoteText("");
                        setOrderStatusErr("");
                        setOrderUpdateNotesErr("");
                        setSelectedFile(null);
                        setTemplate(null);
                      }}
                    >
                      <i className="pi pi-times"></i>
                    </button>
                  </div>
                  <div className="col-12 sidebar-header-text2 px-4">
                    Approve or decline order pay later request.
                  </div>
                </div>

                <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                  <div className="grid mt-3">
                    <div className="col-12" style={{ fontSize: "14px" }}>
                      <span className="mr-3">
                        <div className="relative">
                          <div className="flex items-center">
                            <Checkbox
                              inputId="approve"
                              name="status"
                              value="approve"
                              onChange={handleCheckboxChange}
                              checked={status.approve}
                            />
                            <label htmlFor="approve" className="ml-2">
                              Approve
                            </label>
                          </div>

                          <div className="flex items-center mt-2">
                            <Checkbox
                              inputId="reject"
                              name="status"
                              value="reject"
                              onChange={handleCheckboxChange}
                              checked={status.reject}
                            />
                            <label htmlFor="reject" className="ml-2">
                              Reject
                            </label>
                          </div>
                        </div>
                        {orderStatusErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {orderStatusErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>

                    {(superAdminId || superAdminMainId) && (
                      <div className="col-12" style={{ fontSize: "14px" }}>
                        <span className=" mr-3">
                          <div class="relative">
                            <Dropdown
                              filter
                              placeholder="Choose template"
                              style={{ height: "40px" }}
                              options={allTemplate}
                              value={template}
                              optionLabel="templateName"
                              onChange={(e) => setTemplate(e.value)}
                              className="w-full"
                            />
                            <label
                              htmlFor="floating_outlined25"
                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >
                              Template{" "}
                              <span className="form-field-mandatory"></span>
                            </label>
                          </div>
                        </span>
                      </div>
                    )}

                    <div className="col-12" style={{ fontSize: "14px" }}>
                      <span className="mr-3">
                        <div className="relative">
                          <InputTextarea
                            rows={5}
                            cols={100}
                            value={updateNoteText}
                            onChange={(e) => {
                              setOrderUpdateNotesErr("");
                              setUpdateNoteText(e.target.value);
                            }}
                            placeholder="Type a description about the approval pending"
                            className="w-full"
                          />
                          <label
                            htmlFor="floating_outlined25"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Notes{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {orderUpdateNotesErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {orderUpdateNotesErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="flex gap-3 items-center mt-2 justify-content-end w-full text-base font-medium max-md:max-w-full">
                    <div className="flex gap-3 justify-content-end items-start self-stretch my-auto min-w-[240px]">
                      <Button
                        className="vstate-reverse-button"
                        label="Cancel"
                        onClick={() => {
                          setApprovalDialog(false);
                          setUpdateNoteText("");
                          setOrderStatusErr("");
                          setOrderUpdateNotesErr("");
                          setSelectedFile(null);
                          setTemplate(null);
                        }}
                      />
                      <Button
                        className="vstate-button"
                        label="Submit"
                        onClick={handleApproveSubmit}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </Dialog>

          {closeProgresbar3 && (
            <Dialog
              className="payment-dialog rounded-lg"
              visible={closeProgresbar3}
              onHide={() => setCloseProgresbar3(false)}
            >
              <section className="flex rounded-lg overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                <div className="payment-icon-confirm">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="24"
                    viewBox="0 0 14 24"
                    fill="none"
                  >
                    <path
                      d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1"
                      stroke="#039855"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                  <header className="flex flex-col w-full max-md:max-w-full">
                    <h2 className="text-lg font-medium leading-loose text-gray-900 max-md:max-w-full">
                      Pay Now
                    </h2>
                    <p className="mt-2 text-sm leading-5 text-gray-500 w-[500px] text-wrap">
                      You selected the <strong>Pay Now</strong> option. Please
                      complete your payment to finalize your order.
                    </p>
                  </header>
                  <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                    <div className="flex justify-content-end gap-3 items-start self-stretch my-auto min-w-[240px]">
                      <Button
                        className="vstate-reverse-button"
                        label="Cancel"
                        onClick={() => setCloseProgresbar3(false)}
                      />
                      <Button
                        className="vstate-button"
                        label="Proceed to payment"
                        onClick={handlePayment}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </Dialog>
          )}

          {/* <EditCandidateDetails visible={visible5} setVisible={setVisible5} setCloseProgresbar={setCloseProgresbar} setCloseProgresbar1={setCloseProgresbar1} /> */}
          {/* <AddCandidate visible={visible4} setVisible={setVisible4} /> */}
        </div>
      </div>
    </>
  );
}
