import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Button } from "primereact/button";
import React, { useRef } from "react";
 
import { PDFDocument } from "pdf-lib";
 
const DelawareCorp = ({ orderData }) => {
  const componentRef = useRef();
 
  if (!orderData) {
    return <div>No order data available.</div>;
  }
 
  const strapiOrderFormJson = orderData.items[1].orderId.strapiOrderFormJson;
  const parsedData = JSON.parse(strapiOrderFormJson)
  const entityData = parsedData["Payload"].Entity_Formation;

  const formattedDate = new Date()
  const  date = formattedDate.toLocaleDateString();



  {
    console.log(orderData, "orderData");
  }
  const {
    line1 = "", //addresss
    line2 = "", //addresss
    line3 = "", //addresss
    llc_name = "",
    orderTitle = "",
 
    corp_name = "",
    regAddress = "",
    orderDate = "",
 
    share = "",
    llcName = "",
 
    billingInfo = {},
    clientName = "",
    
  } = orderData;

  const {
    Name,
    Principal_Address,
    Registered_Agent,
    Incorporator_Information,
    Stock_Details
  } = entityData;
  

  const {
    SI_Number_Of_Shares,
    SI_Par_Value
  } = Stock_Details;
  
 
  const {
    Incorporator_Details: {
      Inc_Name,
      Inc_Email_Address,
      Inc_Contact_No
    },
    Address: {
      Inc_Address_Line_1,
      Inc_Address_Line_2,
      Inc_City,
      Inc_Zip_Code,
      Inc_State
    }
  } = Incorporator_Information;



  const billingAddress = [billingInfo.city, billingInfo.state, billingInfo.zip]
    .filter(Boolean)
    .join(", ");
 
  const downloadPDF = async () => {
    const input = document.getElementById("content-to-pdf"); // Main content container
 
    const sections = Array.from(input.querySelectorAll(".section")); // Get all sections with class 'section'
 
    // Define A4 page size (in points)
    // const pageWidth = 595.28; // default
    const pageWidth = 595.28; // A4 width in points
 
    const pageHeight = 841.89; // A4 height in points
 
    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();
 
    const scale = 2; // Scale for better resolution
    const pageMargin = 20; // Page margin
 
    // Function to render content of a section to a page
    const renderSection = async (section, startY) => {
      // Capture the section's content using html2canvas
      const canvas = await html2canvas(section, {
        scale: scale,
        y: startY, // Start rendering from the current vertical position
        height: pageHeight * scale + 800, // Limit height to the size of one page
      });
 
      const imgData = canvas.toDataURL("image/png");
      const img = await pdfDoc.embedPng(imgData);
 
      const imgWidth = img.width * (pageWidth / canvas.width) - 30; // Scale image to fit the page width
      const imgHeight = img.height * (pageHeight / canvas.height); // Scale image to fit the page height
 
      // Add a new page to the PDF
      const page = pdfDoc.addPage([pageWidth, pageHeight]);
 
      // Draw the image onto the page
      page.drawImage(img, {
        x: pageMargin,
        y: pageHeight - imgHeight - pageMargin, // Y position from the top of the page
        width: imgWidth,
        height: imgHeight,
      });
    };
 
    // Render each section on a new page
    for (let i = 0; i < sections.length; i++) {
      await renderSection(sections[i], 0);
    }
 
    // Serialize the document to bytes
    const pdfBytes = await pdfDoc.save();
 
    // Trigger the download of the PDF
    const link = document.createElement("a");
    link.href = URL.createObjectURL(
      new Blob([pdfBytes], { type: "application/pdf" })
    );
    link.download = "generated-document.pdf";
    link.click();
  };
  return (
    <>
      <div
        id="content-to-pdf"
        style={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          fontFamily: "Inter, sans-serif",
          // maxWidth: '1200px',
        }}
        ref={componentRef}
      >
        <div
          className="section"
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {/* Header Section */}
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "start",
              color: "#101828",
              fontWeight: 600,
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                gap: "11px",
                justifyContent: "center",
                flex: 1,
                padding: "80px 120px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "start",
                  flex: 1,
                  margin: "auto 0",
                }}
              >
                <div
                  style={{
                    fontSize: "24px",
                    lineHeight: "normal",
                  }}
                >
                  CERTIFICATE OF INCORPORATION
                  <br />
                  OF
                </div>
                <div
                  className="font-bold"
                  style={{
                    fontSize: "28px",
                    lineHeight: 1,
                    marginTop: "24px",
                  }}
                >
                  {/* <input
                      type="text"
                      placeholder="$llc_name"
                      value={llc_name}
                      className="text-center"
                    /> */}
                  {orderTitle}
                </div>
              </div>
            </div>
          </div>
 
          {/* Main Content */}
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              padding: "16px 120px 48px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontWeight: 500,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "40px 64px",
                    justifyContent: "start",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      color: "#101828",
                      whiteSpace: "nowrap",
                      lineHeight: 2,
                      width: "80px",
                    }}
                  >
                    First
                  </div>
                  <div
                    style={{
                      display: "flex",
                      minWidth: "240px",
                      // alignItems: 'start',
                      gap: "6px",
                      fontSize: "16px",
                      color: "#101828",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      flex: 1,
                      margin: "auto 0",
                    }}
                  >
                    <div>The name of this corporation shall be</div>
                    <div
                      className="font-bold"
                      style={{
                        alignItems: "center",
                        borderBottom: "1px solid #d0d5dd",
                        background: "#fff",
                        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                        display: "flex",
                        minWidth: "240px",
                        gap: "8px",
                        fontSize: "14px",
                        color: "#667085",
                        // fontWeight: 400,
                        lineHeight: "24px",
                        justifyContent: "start",
                        padding: "0 14px",
                        flex: 1,
                      }}
                    >
                      {orderTitle}
                    </div>
                    <div>.</div>
                  </div>
                </div>
 
                {/* Address Section */}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "40px 64px",
                    justifyContent: "start",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      color: "#101828",
                      whiteSpace: "nowrap",
                      lineHeight: 2,
                      width: "80px",
                    }}
                  >
                    Second
                  </div>
                  <div
                    style={{
                      display: "flex",
                      minWidth: "240px",
                      // alignItems: 'start',
                      gap: "6px",
                      fontSize: "16px",
                      color: "#101828",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      flex: 1,
                      margin: "auto 0",
                    }}
                  >
                    <div>
                      Its registered office in the State of Delaware is to be
                      located at
                    </div>
                    <div
                      className="font-bold"
                      style={{
                        alignItems: "center",
                        borderBottom: "1px solid #d0d5dd",
                        background: "#fff",
                        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                        display: "flex",
                        minWidth: "240px",
                        gap: "8px",
                        fontSize: "14px",
                        color: "#667085",
                        // fontWeight: 800,
                        lineHeight: "24px",
                        justifyContent: "start",
                        padding: "0 14px",
                        // fontWeight:7
                        flex: 1,
                      }}
                    >
                      {billingInfo.address || ""}
                    </div>
                    <div>.</div>
                  </div>
                </div>
 
                {/* Third */}
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "40px 64px",
                    justifyContent: "start",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      color: "#101828",
                      whiteSpace: "nowrap",
                      lineHeight: 2,
                      width: "80px",
                    }}
                  >
                    Third
                  </div>
                  <div
                    style={{
                      display: "flex",
                      minWidth: "240px",
                      // alignItems: 'start',
                      gap: "6px",
                      fontSize: "16px",
                      color: "#101828",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      flex: 1,
                      margin: "auto 0",
                    }}
                  >
                    <div>
                      The purpose of the Company is to engage in any lawful act
                      or activity for which corporations may be organized under
                      the Delaware General Corporation Law, as the same exists
                      or as may hereafter be amended from time to time (the
                      “DGCL”).
                    </div>
                  </div>
                </div>
 
                {/* Fourth */}
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "40px 64px",
                    justifyContent: "start",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      color: "#101828",
                      whiteSpace: "nowrap",
                      lineHeight: 2,
                      width: "80px",
                    }}
                  >
                    Fourth{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      minWidth: "240px",
                      // alignItems: 'start',
                      gap: "6px",
                      fontSize: "16px",
                      color: "#101828",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      flex: 1,
                      margin: "auto 0",
                    }}
                  >
                    <div>
                      This Company is authorized to issue one class of shares to
                      be designated Common Stock. The total number of shares of
                      Common Stock the Company has authority to issue is{" "}
                      <div className="text-gray-500 text-base border-b-2 font-normal inline leading-6 ">
                        {/* <input
                            className="w-1/6  mt-2 text-center"
                            type="text"
                            placeholder="$shares"
                            value={share}
                          /> */}
 
                        <span className="w-1/6 p-2  font-bold m-2 text-center">
                          {SI_Number_Of_Shares ? SI_Number_Of_Shares : " "}
                        </span>
                      </div>
                      with no par value
                    </div>
                  </div>
                </div>
 
                {/* Fifth */}
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "40px 64px",
                    justifyContent: "start",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      color: "#101828",
                      whiteSpace: "nowrap",
                      lineHeight: 2,
                      width: "80px",
                    }}
                  >
                    Fifth{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      minWidth: "240px",
                      // alignItems: 'start',
                      gap: "6px",
                      fontSize: "16px",
                      color: "#101828",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      flex: 1,
                      margin: "auto 0",
                    }}
                  >
                    <div>
                      The name and address of the incorporator(s) are as
                      follows:{" "}
                    </div>
                  </div>
                </div>
 
                {/* inputs */}
                <div className="flex flex-col gap-4 mt-2">
                  <div className="text-gray-500 border-b-2  text-sm font-normal text-center  leading-6">
                    <span className="w-full font-bold text-base p-2 text-center ">
                      {Inc_Address_Line_1 ? Inc_Address_Line_1 : "    "}
                    </span>
                  </div>
 
                  <div className="text-gray-500 border-b-2  text-sm font-normal text-center  leading-6">
                    <span className="w-full font-bold text-base p-2 text-center ">
                      {Inc_City}, { Inc_State}
                    </span>
                  </div>
 
                  <div className="text-gray-500 border-b-2  text-sm font-normal text-center  leading-6">
                    <span className="w-full font-bold text-base p-2 text-center ">
                      {Inc_Zip_Code ? Inc_Zip_Code : "   "}
                    </span>
                  </div>
                </div>
 
                {/* Witness Section */}
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    gap: "5px",
                    lineHeight: "24px",
                  }}
                >
                  <div>
                    <strong>IN WITNESS WHEREOF</strong>, the undersigned, being
                    the incorporator herein before named, has executed signed
                    and acknowledged this certificate of incorporation this
                  </div>
                </div>
                <div
                  className="w-1/6"
                  style={{
                    alignItems: "center",
                    borderBottom: "1px solid #d0d5dd",
                    background: "#fff",
                    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                    display: "flex",
                    gap: "8px",
                    fontSize: "14px",
                    color: "#667085",
                    fontWeight: 400,
                    lineHeight: "24px",
                    justifyContent: "start",
                    padding: "0 14px",
                    flex: 1,
                  }}
                >
                  {date}
                </div>
 
                {/* Signature Section */}
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    flexDirection: "column",
                    justifyContent: "end",
                    padding: "24px 0",
                    maxWidth: "600px",
                  }}
                >
                  <div className="flex">
                    <div>By: /S/</div>
                    <div
                      className="font-bold"
                      style={{
                        width: "50%",
                        borderBottom: "1px solid #d0d5dd",
                        padding: "0 14px",
                        textAlign: "center",
                      }}
                    >
                      {clientName}
                    </div>
                  </div>
                  <div className="flex">
                    <div>Name:</div>
                    <div
                      className="font-bold"
                      style={{
                        width: "50%",
                        borderBottom: "1px solid #d0d5dd",
                        padding: "0 14px",
                        textAlign: "center",
                      }}
                    >
                      {clientName}
                    </div>
                    <div> ,Authorized Person</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
 
        <div
          style={{ background: "#e4e7ec", minHeight: "1px", width: "100%" }}
        />
      </div>
 
      <div className="flex w-full justify-content-center p-2">
        <Button
          className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
          label="Download PDF"
          icon="pi pi-download"
          onClick={downloadPDF}
        ></Button>
      </div>
    </>
  );
};
 
export default DelawareCorp;
