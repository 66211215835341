import axios from "axios";
import authHeader, { initAuth } from "./authHeader";
import encryption from "./CryptoClass";
import propertiesApp from "../utils/propertiesApp";
import dayjs from "dayjs";

const getDataById = (endpoint) => {
  const dataToEncrypt = {
    endpoint: endpoint,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const saveAllOrder = (data) => {
  const dataToEncrypt = {
    endpoint: "/order/api/orderDetails/save",
    payload: data,
    type: "post",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const saveUpdateHistory = (data) => {
  const dataToEncrypt = {
    endpoint: "/order/api/orderUpdateHistory/save",
    payload: data,
    type: "post",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const putUpdateHistory = (data) => {
  const dataToEncrypt = {
    endpoint: "/order/api/orderUpdateHistory/update",
    payload: data,
    type: "put",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const saveAllInvoice = (data) => {
  const dataToEncrypt = {
    endpoint: "/order/api/invoiceDetails/save",
    payload: data,
    type: "post",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const saveAllSalesReciept = (data) => {
  const dataToEncrypt = {
    endpoint: "/order/api/orderDetails/update",
    payload: data,
    type: "put",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const paymentOrder = (data) => {
  const dataToEncrypt = {
    endpoint: "/order/api/stripe/create-checkout-session",
    payload: data,
    type: "post",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const saveTransaction = (data) => {
  const dataToEncrypt = {
    endpoint: "/order/api/transactions/save",
    payload: data,
    type: "post",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const submitBoiApplication = (data, orderNumber) => {
  const dataToEncrypt = {
    endpoint: `/order/api/boi/submitBoiApplication/${orderNumber}`,
    payload: data,
    type: "post",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(`/decrypt`, dataToEncrypt, { headers: initAuth() });
  } catch (error) {
    return [];
  }
};

const paymentSession = (id) => {
  const dataToEncrypt = {
    endpoint: `/order/api/stripe/transactionId-checkout-session/${id}`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const updateById = (data, endpoint) => {
  const dataToEncrypt = {
    endpoint: endpoint,
    payload: data,
    type: "put",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const updateOrderMigrateById = (orderId, companyId) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderDetails/migrate/${orderId}/${companyId}`,
    payload: null,
    type: "put",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const getAllOrder = () => {
  const dataToEncrypt = {
    endpoint: "/order/api/orderDetails/getAll",
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const getAllOrderByPagination = (id, page, size) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderDetails/getAll/${id}?page=${page}&size=${size}`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const getAllOrderByWithoutPagination = (id) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderDetails/getAll/${id}`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const getAllOrderByStatusAndDate = (id, startDate, endDate) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderDetails/getByStatusId/${id}/${startDate}/${endDate}`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const deleteOrderById = (companyId, orderId) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderDetails/deleteOrders/${companyId}/${orderId}/${1}`,
    payload: null,
    type: "delete",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const deleteOrderId = (companyId, orderId) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderDetails/deleteOrders/${companyId}/${orderId}`,
    payload: null,
    type: "delete",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const deleteOrderLineItemById = async (orderId) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderLineItems/delete/${orderId}`,
    payload: null,
    type: "delete",
    auth: authHeader(),
  };

  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const getAllOrderForDashbaord = () => {
  const dataToEncrypt = {
    endpoint: "/order/api/reportOrderDashboard/getAll",
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const getStrapiIdByProduct = () => {
  const dataToEncrypt = {
    endpoint: "/order/api/productStrapiAPI/getAll",
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const getAllOrderForDashbaordByDate = (
  startMonth,
  startYear,
  endMonth,
  endYear
) => {
  const dataToEncrypt = {
    endpoint: `/order/api/reportOrderDashboard/getAllByMonth/${startMonth}/${startYear}/${endMonth}/${endYear}`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const getAllOrderCompanyByDate = (startYear) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderDetails/distinctCompaniesByState/?startDate=${startYear}`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};
const getAllServiceForDashbaord = (startdate, enddate) => {

  const formattedStartDate = dayjs(startdate).format("YYYY-MM-DDTHH:mm:ss");
  const formattedEndDate = dayjs(enddate).format("YYYY-MM-DDTHH:mm:ss");

  const dataToEncrypt = {
    endpoint: `/order/api/reportServiceOrderDashboard/getAll/${startdate}/${enddate}`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const getAllOrderByCompanyId = (companyId) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderDetails/getByParentCompanyId/${companyId}`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    // return axios.get(`/decrypt?data=${dataToEncrypt}`,
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const getAllOrderByAccountManagerId = (id) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderDetails/getByByAccountManagerId/${id}`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    // return axios.get(`/decrypt?data=${dataToEncrypt}`,
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

// Datatable

const getInoviceDuePayment = (companyId, accountManagerID) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderDetails/getDueData/${companyId}/${accountManagerID}`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    // return axios.get(`/decrypt?data=${dataToEncrypt}`,
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const getClientSubscription = (companyId, accountManagerID) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderDetails/getSubscriptionData/${companyId}/${accountManagerID}`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const getLoginData = (username) => {
  const dataToEncrypt = {
    endpoint: `/authentication/api/auth/loginDetails/${username}`,
    payload: null,
    type: "get",
    auth: null,
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const getOrderCountByCompanyId = (id) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderDetails/getStatusCount/${id}`,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const customCompanyUpload = (data) => {
  const dataToEncrypt = {
    endpoint: `${propertiesApp.python_url}/multipart/extract_form_data`,
    payload: data,
    type: "post",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(data);
  try {
    return axios.post(
      `${propertiesApp.python_url}/multipart/extract_form_data`,
      data,
      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const customBOIUpload = (data) => {

  try {
    return axios.post(
      `${propertiesApp.python_url}/extract/extract-text`,
      data,
      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const getClientSubscriptionByCompanyId = (companyId) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderDetails/getSubscriptionsAndServicesByCompany/${companyId}`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};


const getOrderHistoryByOrderId = (id) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderUpdateHistory/getByOrderId/${id}`,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const orderUpdate = (data) => {
  const dataToEncrypt = {
    endpoint: `/order/api/orderDetails/updateOrder`,
    payload: data,
    type: "put",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(data);
  try {
    return axios.post(
      `/decrypt`, dataToEncrypt,
      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const OrderServices = {
  getAllOrder,
  saveAllOrder,
  updateById,
  getDataById,
  saveAllInvoice,
  getAllOrderByCompanyId,
  saveUpdateHistory,
  putUpdateHistory,
  saveAllSalesReciept,
  getAllOrderByAccountManagerId,
  paymentOrder,
  paymentSession,
  getAllOrderForDashbaord,
  getAllServiceForDashbaord,
  getOrderCountByCompanyId,
  submitBoiApplication,
  getAllOrderByPagination,
  getAllOrderByWithoutPagination,
  getAllOrderForDashbaordByDate,
  getAllOrderCompanyByDate,
  getAllOrderByStatusAndDate,
  customCompanyUpload,
  deleteOrderById,
  deleteOrderLineItemById,
  getStrapiIdByProduct,
  getInoviceDuePayment,
  getClientSubscription,
  getClientSubscriptionByCompanyId,
  getLoginData,
  getOrderHistoryByOrderId,
  updateOrderMigrateById,
  customBOIUpload,
  saveTransaction,
  orderUpdate
};

export default OrderServices;
