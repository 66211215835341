import React from 'react';

function SubscriptionOverview() {
  return (
    <div className="flex flex-col mt-6 w-full text-base">
      <h3 className="text-base text-gray-900">Subscription Overview</h3>
      <div className="flex gap-6 items-start p-3 mt-3 w-full bg-white rounded-lg border border-gray-200 border-solid shadow max-md:px-5">
        <div className="flex flex-col flex-1 shrink justify-center w-full basis-0 min-w-[240px]">
          <div className="flex gap-4 items-center w-full">
            <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0 min-w-[240px]">
              <div className="leading-10 text-slate-700">Registered agent (0 Total)</div>
              <div className="leading-7 text-gray-500">
                <span className="text-3xl text-blue-700">0</span> to resolve
              </div>
            </div>
            {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/5d288fb7ddaf69edf36a325c272af9969ed08d65104b8dc9b30304cda9d8b841?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0" className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square" alt="" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionOverview;