import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";
import imgsrc from '../../../../Assets/Images/document.png'
import AOS from "aos";
import "aos/dist/aos.css";

const services = [
  {
    title: "Legal Verification",
    description:
      "Certified documents confirm your business’s legal status and help with transactions like mergers, loans, or partnerships.",
  },
  {
    title: "Timely Access",
    description:
      "Quick retrieval of essential documents helps avoid delays in business processes that require official documentation.",
  },
  {
    title: "Compliance Support",
    description:
      "Access to the right documents ensures you meet regulatory and filing requirements, keeping your business compliant.",
  },
  {
    title: "Secure and Reliable",
    description:
      "Our services guarantee that your documents are safely stored and retrieved without risk of errors or misplacement.",
  },
];

const DocumentRetrivalSec = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  
  return (
    <section className="main-section-class">
      <div className="entity-formation">
        <Container>
          <CommonHeadingTitle
            heading={"Why choose"}
            blueheading={" document retrieval services?"}
          />
          <Row className="mt-5">
            <Col lg={6} md={6} sm={12} xs={12}   data-aos="fade-left"
              data-aos-duration="3000">
              <Row>
                {services.map((service, index) => (
                  <Col lg={12} md={12} sm={12} xs={12} key={index}>
                    <div className="main-class-div">
                      <h4 className="medium-level-heading">{service.title}</h4>
                      <div className="grey-border mt-3 mb-3"></div>
                      <p className="normal-common-txt">{service.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}   data-aos="fade-right"
              data-aos-duration="3000">
              <img
                loading="lazy"
                className="user-img-buisness"
                src={imgsrc}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default DocumentRetrivalSec;
