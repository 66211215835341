import React from 'react';

const Features = () => {
  const features = [
    { title: 'Processing time', description: 'We can get your company registered within 2 business days, so your start-up plans can move forward without delay!' },
    { title: 'Price', description: 'vState Filings provides affordable services to help you quickly form your company. Select from our range of packages to find the perfect support for your needs.' },
    { title: 'Registered agent', description: 'In many states, a registered agent is required for forming a company. They receive legal documents on your companys behalf. All our vState Filings packages include this service.' },
    { title: 'Security', description: 'When forming an LLC, personal information is required. With vState Filings, we prioritize security to protect your data.' }
  ];

  return (
    <section className="flex flex-col justify-center items-center py-20 pr-96 pl-96 mt-7 w-full text-center bg-white max-w-[1903px] max-md:px-5 max-md:max-w-full">
      <div className="flex justify-center w-full">
        <div className="flex flex-col flex-1 shrink justify-center px-2.5 pt-2.5 pb-2.5 w-full basis-0 min-w-[240px] max-md:max-w-full">
          <h2 className="text-4xl font-semibold text-center text-gray-800 max-md:max-w-full">
            Why choose{" "}
            <span className="leading-10 text-blue-700">vState Filings</span>{" "}
            for your <br />
            <span className="leading-10 text-blue-700"> business formation </span>{" "}
            needs?
          </h2>
          <div className="flex flex-wrap gap-2 justify-center self-center pt-5 mt-10 max-md:max-w-full">
            <div className="flex flex-col justify-center items-center p-5 my-auto min-w-[240px] w-[511px] max-md:max-w-full">
              {features.map((feature, index) => (
                <React.Fragment key={index}>
                  <div className="flex flex-col justify-center max-w-full w-[431px]">
                    <div className="flex gap-4 mt-2 items-center w-full max-md:max-w-full">
                      <div className=" my-auto text-xl font-black leading-none text-center text-blue-700">✓</div>
                      <h3 className="flex-1 shrink text-start my-auto text-2xl font-semibold leading-tight text-gray-800 basis-0">
                        {feature.title}
                      </h3>
                    </div>
                    <p className="mt-4 text-base text-start font-light leading-7 text-gray-800 max-md:max-w-full">
                      {feature.description}
                    </p>
                  </div>
                  {index < features.length - 1 && (
                    <div className="flex mt-4 max-w-full border-t border-zinc-300 min-h-[1px] w-[471px]" />
                  )}
                </React.Fragment>
              ))}
            </div>
            <div className="flex flex-wrap justify-center items-start p-2.5 h-full min-w-[240px] w-[575px] max-md:max-w-full">
              <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full">
                <div className="flex flex-col items-center px-14 w-full min-h-[665px] max-md:px-5 max-md:max-w-full">
                  <div className="flex justify-center items-center max-w-full w-[444px]">
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/28039c3098271ecf12c441995d6d71b369290f8ea9db2cd51da89a58fb1c7978?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0" alt="Business formation illustration" className="object-contain flex-1 shrink self-stretch my-auto w-full rounded-3xl aspect-[0.67] basis-0 max-w-[444px] min-w-[240px] shadow-[0px_0px_10px_rgba(0,0,0,0.5)] max-md:max-w-full" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;