import React, { useState, useRef, useEffect } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import AddressService from "../../../services/addressService";
import OrderServices from "../../../services/orderServices";
import UserServices from "../../../services/userService";


const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

// const clientData = {
//   Alabama: { totalOrders: 50, pendingOrders: 10, submittedOrders: 25, subscriptionOrders: 5, paymentDue: 15, paymentAmount: 500 },
//   Florida: { totalOrders: 60, pendingOrders: 20, submittedOrders: 20, subscriptionOrders: 20, paymentDue: 23, paymentAmount: 999 },
//   California: { totalOrders: 100, pendingOrders: 40, submittedOrders: 40, subscriptionOrders: 20, paymentDue: 50, paymentAmount: 2000 },

// };


const stateFlags = {
  Alabama: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Alabama.svg",
  Alaska: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Alaska.svg",
  Arizona: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Arizona.svg",
  Arkansas: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Arkansas.svg",
  California: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_California.svg",
  Colorado: "https://upload.wikimedia.org/wikipedia/commons/4/46/Flag_of_Colorado.svg",
  Connecticut: "https://upload.wikimedia.org/wikipedia/commons/9/96/Flag_of_Connecticut.svg",
  Delaware: "https://upload.wikimedia.org/wikipedia/commons/c/c6/Flag_of_Delaware.svg",
  Florida: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Flag_of_Florida.svg",
  Georgia: "https://upload.wikimedia.org/wikipedia/commons/7/78/Georgia_flag_icon.png",
  Hawaii: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Hawaii.svg",
  Idaho: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_Idaho.svg",
  Illinois: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Illinois.svg",
  Indiana: "https://upload.wikimedia.org/wikipedia/commons/a/ac/Flag_of_Indiana.svg",
  Iowa: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Iowa.svg",
  Kansas: "https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Kansas.svg",
  Kentucky: "https://upload.wikimedia.org/wikipedia/commons/8/8d/Flag_of_Kentucky.svg",
  Louisiana: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_Louisiana.svg",
  Maine: "https://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_Maine.svg",
  Maryland: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Maryland.svg",
  Massachusetts: "https://upload.wikimedia.org/wikipedia/commons/f/f2/Flag_of_Massachusetts.svg",
  Michigan: "https://upload.wikimedia.org/wikipedia/commons/b/b5/Flag_of_Michigan.svg",
  Minnesota: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Flag_of_Minnesota.svg",
  Mississippi: "https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_Mississippi.svg",
  Missouri: "https://upload.wikimedia.org/wikipedia/commons/5/5a/Flag_of_Missouri.svg",
  Montana: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_Montana.svg",
  Nebraska: "https://upload.wikimedia.org/wikipedia/commons/4/4d/Flag_of_Nebraska.svg",
  Nevada: "https://upload.wikimedia.org/wikipedia/commons/f/f1/Flag_of_Nevada.svg",
  New_Hampshire: "https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_New_Hampshire.svg",
  New_Jersey: "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_New_Jersey.svg",
  New_Mexico: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_New_Mexico.svg",
  New_York: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_New_York.svg",
  North_Carolina: "https://upload.wikimedia.org/wikipedia/commons/b/bb/Flag_of_North_Carolina.svg",
  North_Dakota: "https://upload.wikimedia.org/wikipedia/commons/e/ee/Flag_of_North_Dakota.svg",
  Ohio: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Ohio.svg",
  Oklahoma: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_Oklahoma.svg",
  Oregon: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Flag_of_Oregon.svg",
  Pennsylvania: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Flag_of_Pennsylvania.svg",
  Rhode_Island: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Rhode_Island.svg",
  South_Carolina: "https://upload.wikimedia.org/wikipedia/commons/6/69/Flag_of_South_Carolina.svg",
  South_Dakota: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_South_Dakota.svg",
  Tennessee: "https://upload.wikimedia.org/wikipedia/commons/9/9e/Flag_of_Tennessee.svg",
  Texas: "https://upload.wikimedia.org/wikipedia/commons/f/f7/Flag_of_Texas.svg",
  Utah: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Utah.svg",
  Vermont: "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Vermont.svg",
  Virginia: "https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Virginia.svg",
  Washington: "https://upload.wikimedia.org/wikipedia/commons/5/54/Flag_of_Washington.svg",
  West_Virginia: "https://upload.wikimedia.org/wikipedia/commons/2/22/Flag_of_West_Virginia.svg",
  Wisconsin: "https://upload.wikimedia.org/wikipedia/commons/2/22/Flag_of_Wisconsin.svg",
  Wyoming: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Wyoming.svg"
};


const ClientMap = ({ onStateHover }) => {

  const [allState, setAllState] = useState([])

  const [dashboardData, setDashboardData] = useState([])


  const [superAdminId, setSuperAdminId] = useState(null)

  const [managerId, setManagerId] = useState(null)

  const user = JSON.parse(localStorage.getItem('user'));

  function checkIdsExist(arr, idsToCheck) {
    const foundIds = idsToCheck.filter(idToCheck => arr.some(item => item.id === idToCheck));
    return foundIds.length > 0 ? foundIds : null;
  }

  useEffect(() => {

    if (user) {
      if (user.companyRoles !== undefined) {
        if (user.companyRoles.length > 0) {
          if (user.companyRoles[0].roles.length > 0) {
            const id = checkIdsExist(user.companyRoles[0].roles, [4]);
            console.log(id)
            setSuperAdminId(id)
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    console.log("user", user)
    if (user) {
      UserServices.getEmployeeByUserId(user.id)
        .then((res) => {
          console.log(res)
          if (res.data) {
            setManagerId(res.data.id)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [])


  useEffect(() => {
    AddressService.getAllStates()
      .then((res) => {
        console.log(res)
        if (res.data !== "") {
          setAllState(res.data)
        }

      })
      .catch((err) => {
        console.log(err)
      })
    OrderServices.getAllOrderForDashbaord()
      .then((res) => {
        console.log(res)
        console.log(res.data)
        setDashboardData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])


  const [clientData, setClientData] = useState({});

  useEffect(() => {
    const aggregatedData = {};
    if (superAdminId && managerId && dashboardData.length>0) {
      const filteredData = managerId
        ? dashboardData.filter(item => item.accountManagerId === managerId) // Assuming each item has an `accountManagerId`
        : dashboardData;
      console.log(filteredData)
      if (filteredData.length > 0 && allState.length > 0) {


        filteredData.forEach((data) => {
          const matchedState = allState.find((s) => s.id === data.stateId);
          if (matchedState) {
            const stateName = matchedState.stateFullDesc;


            if (!aggregatedData[stateName]) {
              aggregatedData[stateName] = {
                totalOrders: 0,
                pendingOrders: 0,
                submittedOrders: 0,
                subscriptionOrders: 0,
                paymentDue: 0,
                feedbackPending: 0,
                total:0
              };
            }

            

            aggregatedData[stateName].totalOrders += data.completedCount || 0;
            aggregatedData[stateName].pendingOrders += data.inProgressCount || 0;
            aggregatedData[stateName].submittedOrders += data.payLaterRequestedCount || 0;
            aggregatedData[stateName].subscriptionOrders += data.resubmittedCount || 0;
            aggregatedData[stateName].paymentDue += data.totalPaymentDue || 0;
            aggregatedData[stateName].feedbackPending += data.clientRespondedCount || 0;
          }
        });
        setClientData(aggregatedData);
      }
    }
    else {
      if (dashboardData.length > 0 && allState.length > 0) {


        dashboardData.forEach((data) => {
          const matchedState = allState.find((s) => s.id === data.stateId);
          if (matchedState) {
            const stateName = matchedState.stateFullDesc;


            if (!aggregatedData[stateName]) {
              aggregatedData[stateName] = {
                totalOrders: 0,
                pendingOrders: 0,
                submittedOrders: 0,
                subscriptionOrders: 0,
                paymentDue: 0,
                feedbackPending: 0,
                total:0
              };
            }

            

            aggregatedData[stateName].totalOrders += data.completedCount || 0;
            aggregatedData[stateName].pendingOrders += data.inProgressCount || 0;
            aggregatedData[stateName].submittedOrders += data.payLaterRequestedCount || 0;
            aggregatedData[stateName].subscriptionOrders += data.resubmittedCount || 0;
            aggregatedData[stateName].paymentDue += data.totalPaymentDue || 0;
            aggregatedData[stateName].feedbackPending += data.clientRespondedCount || 0;
          }
        });
        setClientData(aggregatedData);
      }
    }
  }, [dashboardData, allState, managerId]);

  console.log(clientData)
  const [hoveredState, setHoveredState] = useState(null);
  const [cardPosition, setCardPosition] = useState({ x: 0, y: 0 });
  const mapRef = useRef(null);

  const onMouseEnter = (stateName, evt) => {
    const mapBoundingRect = mapRef.current.getBoundingClientRect();
    const x = evt.clientX - mapBoundingRect.left;
    const y = evt.clientY - mapBoundingRect.top;

    setHoveredState(stateName);
    setCardPosition({ x, y });
    onStateHover(stateName, { ...clientData[stateName], flag: stateFlags[stateName.replace(" ", "_")] });
  };

  const onMouseLeave = () => {
    setHoveredState(null);
    onStateHover(null, {});
  };

  return (
    <div style={{ position: "relative", width: "600px" }}>
      <div ref={mapRef} style={{ position: "relative" }}>
        <ComposableMap projection="geoAlbersUsa">
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const stateName = geo.properties.name;
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onMouseEnter={(evt) => onMouseEnter(stateName, evt)}
                    onMouseLeave={onMouseLeave}
                    style={{
                      default: { fill: "#EAF1FF", outline: "none" },
                      hover: { fill: "#C3D7FF", outline: "none" },
                      pressed: { fill: "#2870FF", outline: "none" },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>

        {hoveredState && (
          <div
            style={{
              position: "absolute",
              top: `${cardPosition.y}px`,
              left: `${cardPosition.x + 20}px`,
              padding: "10px",
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              borderRadius: "8px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              pointerEvents: "none",
              zIndex: 10,
              width: "100px",
            }}
          >
            <div className="flex justify-content-center">
              {stateFlags[hoveredState.replace(" ", "_")] && (
                <img
                  src={stateFlags[hoveredState.replace(" ", "_")]}
                  alt={`${hoveredState} flag`}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    marginTop: "10px",
                    objectFit: "cover",
                    border: "2px solid #ccc",
                  }}
                />
              )}
            </div>

            <p className="dashboard-map-card-text1 mb-0">{hoveredState}</p>
            {/* <p className="dashboard-map-card-text3 text-center mb-0">
              {clientData[hoveredState]?.totalOrders || 0}
            </p>
            <p className="dashboard-map-card-text2">Total Orders</p> */}

          </div>
        )}
      </div>
    </div>
  );
};

export default ClientMap;
