import React from 'react';
import FeatureCard from './FeatureCard';
import { complianceData } from './complianceData';
 // Import JSON data

function ComplianceServices() {
  const { pageTitle, pageDescription, buttonText, features } = complianceData;

  return (
    <main className="flex flex-col justify-center p-2.5 w-full max-md:max-w-full">
      <section className="flex flex-col justify-center pb-5 w-full max-md:max-w-full">
        <header className="flex flex-col w-full text-5xl font-medium tracking-tighter leading-tight text-center text-blue-700 max-md:max-w-full">
          <h1 className="px-64 w-full max-md:px-5 max-md:max-w-full">
            {pageTitle}
          </h1>
        </header>
        <p className="mt-5 px-5 w-full text-xl text-center text-slate-800 max-md:max-w-full">
          {pageDescription}
        </p>
        <div className="flex flex-col mt-5 w-full max-md:max-w-full">
          <div className="flex flex-wrap gap-8 w-full min-h-[271px] max-md:max-w-full">
            {features.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </div>
        </div>
      </section>
      <div className="flex flex-col items-center px-32 pt-9 w-full text-base font-medium leading-none text-center text-white max-md:px-5 max-md:max-w-full">
        <button className="px-7 py-3.5 bg-blue-700 rounded shadow-[0px_0px_10px_rgba(0,0,0,0.5)] max-md:px-5">
          {buttonText}
        </button>
      </div>
    </main>
  );
}

export default ComplianceServices;
