import React from 'react';

const steps = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/39037a34bc3ffa913c20ac921150ecd03c2574e0b71d1595136b3182334f3267?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0",
    title: "Step 1: Tell us your business name",
    description: "Start the process by sharing your legal business name."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/987840d08ebffe29dd2406d4c47e146b4db0a581daa2c77833148d7479698dd3?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0",
    title: "Step 2: Answer a few questions",
    description: "We'll ask you to provide some details, such as your contact info."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/2247de3139823c1f7066243977dc8a31da253a0115866b7e69dc5bf414541288?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0",
    title: "Step 3: We'll complete & file your paperwork",
    description: "We'll let your state know that we're your new registered agent. And if you're switching registered agents, we'll cover those costs."
  }
];

function Steps() {
  return (
    <section className="flex flex-col justify-center items-center py-20 mt-7 max-w-full text-center bg-white w-[1140px]">
      <div className="flex justify-center w-full">
        <div className="flex flex-col justify-center px-2.5 pt-2.5 pb-2.5 min-w-[240px] w-[1140px]">
          <h2 className="flex flex-col justify-center pb-5 w-full text-4xl font-semibold text-gray-800 h-[140px] max-md:max-w-full">
            How to get a registered agent with{" "}
            <span className="text-blue-700">vState Filings</span> <br /> in{" "}
            <span className="text-blue-700">3 easy steps</span>
          </h2>
          <div className="flex flex-col justify-center pt-5 w-full min-h-[423px] max-md:max-w-full">
            <div className="flex flex-col items-center w-full min-h-[403px] max-md:max-w-full">
              <div className="flex overflow-hidden flex-wrap justify-center w-full max-w-[1140px] max-md:max-w-full">
                {steps.map((step, index) => (
                  <div key={index} className="flex flex-1 shrink justify-center p-2.5 h-full basis-0 min-h-[1px] min-w-[240px]">
                    <div className="flex flex-wrap flex-1 shrink justify-center p-2.5 bg-white rounded basis-0 min-w-[240px] shadow-[0px_0px_10px_rgba(0,0,0,0.5)] size-full">
                      <div className="flex flex-col flex-1 shrink justify-center px-4 py-9 w-full basis-0 min-w-[240px]">
                        <div className="flex flex-col w-full">
                          <img loading="lazy" src={step.icon} alt={`Step ${index + 1} icon`} className="object-contain self-center aspect-square w-[50px]" />
                          <div className="flex flex-col mt-6 w-full">
                            <h3 className="px-10 w-full text-2xl font-bold leading-8 text-indigo-600 max-md:px-5">
                              <span className="text-blue-700">{step.title.split(":")[0]}: </span>
                              <span className="font-semibold text-gray-800">{step.title.split(":")[1]}</span>
                            </h3>
                            <p className="px-2 w-full text-lg font-light leading-7 text-gray-800">
                              {step.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Steps;