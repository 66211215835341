import * as React from "react";
import { useNavigate } from "react-router-dom";


const BusinessLanding = () => {
    const navigate=useNavigate()
    return (
        <main className="flex flex-wrap justify-center items-start pb-24">
            <article className="flex flex-1 shrink justify-center w-full basis-0 min-w-[240px] max-md:max-w-full">
                <div className="flex flex-col flex-1 shrink justify-center w-full basis-0 min-w-[240px] max-md:max-w-full">
                    <section className="flex flex-col pb-2 w-full text-5xl font-bold tracking-tighter text-center leading-[65px] text-slate-700 max-md:max-w-full">
                        <h1 className="px-4 pb-px w-full max-md:max-w-full">
                            <span className="font-medium text-slate-800">Want to </span>
                            <span className="font-medium font-web-color">start a business</span>
                            <span className="font-medium text-slate-800">?</span>
                            <br />
                            <span className="font-medium text-slate-800">let's get it done, </span>
                            <span className="font-medium font-web-color">together</span>
                            <span className="font-medium text-slate-800">.</span>
                        </h1>
                    </section>
                    <section className="flex flex-col items-center px-14 pb-9 w-full text-xl leading-7 text-center text-slate-700 max-md:px-5 max-md:max-w-full">
                        <p className="px-1.5 pb-px w-[700px] max-md:max-w-full">
                            Streamline your business essentials under one roof. Stay informed with timely reminders and compliance notifications. Extend your business reach with adaptable additional services.
                        </p>
                    </section>
                    <div className="flex flex-col self-center max-w-full w-[800px]">
                        <div className="flex flex-col items-center text-white px-72 w-full max-md:px-5 max-md:max-w-full">

                            <button className="px-7 py-3.5 vstate-button rounded shadow-[0px_0px_10px_rgba(0,0,0,0.5)] max-md:px-5" onClick={()=>navigate("/webcontact")}>
                                Contact Us
                            </button>

                        </div>
                    </div>
                </div>
            </article>
        </main>
    );
};

export default BusinessLanding;