import React, { useState } from 'react'
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';

const ProtectedBySuperAdminAndEmployee = ({ Componet }) => {
    const navigate = useNavigate()
    let newObject = window.localStorage.getItem("user");
    let user = Object.assign({}, JSON.parse(newObject));
    const [isLogedIn, setIsLoggedIn] = useState(false)

  const [superAdminId, setSuperAdminId] = useState(null)


    function checkIdsExist(arr, idsToCheck) {
        const foundIds = idsToCheck.filter(idToCheck => arr.some(item => item.id === idToCheck));
        return foundIds.length > 0 ? foundIds : null;
    }

    useEffect(() => {
        if (user?.companyRoles?.length > 0) {
            const foundIds = user.companyRoles
                .flatMap(role => role.roles.map(r => r.id)) 
                .filter(id => [1, 4].includes(id)); 
    
            if (foundIds.length > 0) {
                setSuperAdminId(foundIds);
            }
        }
    }, []);    
    


    useEffect(() => {
        if (newObject) {
            setIsLoggedIn(true)
        }
        else {
            navigate('/signin')
        }
    }, [newObject])
    if (isLogedIn) {
        
        if (superAdminId) {

            return <Componet />
        }
        else {
            navigate('/signin')
        }
    } else {
        navigate('/signin')
    }




}

export default ProtectedBySuperAdminAndEmployee