import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import AOS from "aos";
import "aos/dist/aos.css";

const Flexibility = () => {
  // Data for the flexibility items
  const flexibilityData = [
    {
      count: "1",
      heading: "A modern, easy-to-use, and intuitive platform all in one place",
      text: "Say goodbye to tracking multiple websites and wasting time switching between them. With a single sign-in, you can handle all your company's administrative tasks in one place.",
    },
    {
      count: "2",
      heading: "Register your company in minutes.",
      text: "vState Filings specializes in expediting the business formation process, offering a streamlined approach that allows you to submit your application within minutes. With our expert guidance and efficient workflow, we ensure that all necessary paperwork is completed accurately and promptly. ",
    },
    {
      count: "3",
      heading: "Comprehensive Expertise",
      text: "vState Filings offers in-depth knowledge and expertise in regulatory compliance across various industries, ensuring accurate and timely filings to reduce the risk of non-compliance and penalties.",
    },
    {
      count: "4",
      heading: "Personalized Customer Support",
      text: "vState Filings offers dedicated account managers and 24/7 customer support, ensuring clients receive tailored assistance and prompt responses for a smooth and stress-free filing experience.",
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section
      className="main-section-class"
      data-aos="fade-up"
      data-aos-duration="3000"
    >
      <div className="flexibility">
        <Container>
          <CommonHeadingTitle
            heading={"Helping businesses to operate with "}
            blueheading={"flexibility"}
          />
          <div className="mt-5">
            <Row>
              {flexibilityData.map((item, index) => (
                <Col lg={6} md={6} sm={12} xs={12} key={index}>
                  <div className={index % 2 === 0 ? "main-div-class" : ""}>
                    <div className="main-div-class-sub">
                      <p className="count-number">{item.count}</p>
                      <div>
                        <h4 className="medium-level-heading">{item.heading}</h4>
                        <p className="normal-common-txt">{item.text}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Flexibility;
