import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from "primereact/radiobutton";
import { Message } from 'primereact/message'
import { useForm, Controller } from "react-hook-form";
import { classNames } from 'primereact/utils';
import ShiftsServices from '../../services/demoServices';
import { Dropdown } from 'primereact/dropdown';
import { Sidebar } from 'primereact/sidebar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Checkbox } from 'primereact/checkbox';
import { Avatar } from 'primereact/avatar';
import { Card } from 'primereact/card';

const AddCustomUser = ({ visible, setVisible }) => {

    const [value, setValue] = useState('');
    const [ingredient, setIngredient] = useState('');
    const toast = useRef(null);
    const [upload, setUpload] = useState('')
    const [isSuccessful, setIsSuccessful] = useState(false)
    const [checked, setChecked] = useState(true)
    const [isEqualAddress, setIsEqualAddress] = useState(true)
    const [allCustomer, setAllCustomer] = useState([])
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const {
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        reset
    } = useForm('');

    const [isContentVisible, setIsContentVisible] = useState(true);

    const toggleContent = () => {
        setIsContentVisible(!isContentVisible);
    };



    const dialogView = () => setVisible(false)
    const cancelView = () => {
        setVisible(false)
        reset();
    }

    useEffect(() => {
        ShiftsServices.getCustomer()
            .then((res) => {
                console.log(res)
                setAllCustomer(res.data.QueryResponse.Customer)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])




    const show = () => {
        toast.current.show({ severity: 'success', summary: 'Form Submitted', detail: getValues('value') });
    };

    const defaultValues = {
        value: ''
    };



    const onSubmit = (data) => {

        const actualdata = {
            vendorName: data.vendorName,
            contactPerson: data.contactPerson,
            cin: data.cin,
            gstn: data.gstn,
            address: data.address,
            isActive: true,
            contactNo: data.contactNo,
            altContactNo: data.altContactNo


        }
        console.log(actualdata)
        console.log(typeof (actualdata.isInternal))

        setVisible(false)

        reset();
    };

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="error-msg font-fam-for-all ">{errors[name].message}</small> : <small className="error-msg "></small>;
    };


    // handle header
    const handleClick = () => {
        reset()
        setVisible(false)
    }

    const handleHeader = () => {
        return <>

            <div className='font-fam-for-all form-sm-heading form-sm-close-Btn' ><button className='form-close-Btn' onClick={handleClick}><i className='pi pi-times'></i></button></div>

        </>
    }



    const customHeader = (
        <div className="flex align-items-center gap-2">
            <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" />
            <span className="font-bold">Amy Elsner</span>
        </div>
    );



    return (
        <div>
            <Sidebar position='right' header={customHeader} visible={visible} className='dialog-box'  >
                <div className='grid custom-sidebar-header'>
                    <div className='grid col-11 px-4 pt-4 pb-0'>
                        <div className='col-12 sidebar-header-text1 pb-0'>
                            Add User
                        </div>

                    </div>
                    <div className='col-1 flex justify-content-end pt-4 pb-0'>
                        <button onClick={dialogView}><i className='pi pi-times'></i></button>
                    </div>
                    <div className='col-12 sidebar-header-text2 px-4'>
                        Fill in the below details to add a new user.
                    </div>
                </div>



                <form id='myform' className='form-spacing mt-4'>
                    <div className=' input-sidebar-card mt-4'>
                        <div className='px-6 py-4'>
                            <div className='flex' style={{ justifyContent: "space-between" }}>
                                <div className='font-fam-for-all'>
                                    <span><i className='pi pi-address-book'></i></span>
                                    Bulk upload contacts
                                </div>
                            </div>
                            <div>
                                Manage your company’s users and their access with simple file upload.
                            </div>
                            <div className='card py-6 px-2 mt-3 '>
                                <div className="flex justify-content-center align-items-center">
                                    {/* <Card className="upload-card"> */}
                                        <div className="text-center">
                                            <Button
                                                icon="pi pi-upload"
                                                className="p-button-rounded bg-gray-400 border-gray-400 p-button-primary p-mb-3"
                                                aria-label="Upload"
                                            />
                                            <div>
                                                <a href="#" className="p-mb-3">Click here to upload</a>
                                            </div>
                                            <div className="upload-rules">
                                                <p>CSV (max. 250kb)</p>
                                            </div>
                                        </div>
                                    {/* </Card> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>


                <form id='myform' className='form-spacing mt-4' onSubmit={handleSubmit(onSubmit)}>

                    <Toast ref={toast} />



                    <div className=' input-sidebar-card mt-4'>
                        <div className='px-6 pt-4'>
                            <div className='flex' style={{ justifyContent: "space-between" }}>
                                <div className='font-fam-for-all'>
                                    <span><i className='pi pi-address-book'></i></span>

                                    Contact

                                </div>


                            </div>
                        </div>

                        <div >
                            <div className='px-6 grid pt-4  grid card-content'>
                                <div className='col-3'>
                                    <div className=" justify-content-center dialog-form-md-field-space">
                                        <div className="field">

                                            <span className="p-float-label mr-3">
                                                <Controller name="vendorName"
                                                    control={control}
                                                    rules={{ required: 'Vendor Name Required.', pattern: { value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, message: 'Please Enter Valid Vendor Name' } }}
                                                    render={({ field, fieldState }) => (
                                                        <InputText id={field.name} {...field}

                                                            style={{ width: "155px", height: "40px" }}
                                                            maxLength={50} />
                                                    )} />
                                                <label htmlFor="input" className="form-label font-fam-for-all">
                                                    Salutation <span className="form-field-mandatory">*</span>
                                                </label>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className=" justify-content-center dialog-form-md-field-space">
                                        <div className="field">

                                            <span className="p-float-label mr-3">
                                                <Controller name="vendorName"
                                                    control={control}
                                                    rules={{ required: 'Vendor Name Required.', pattern: { value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, message: 'Please Enter Valid Vendor Name' } }}
                                                    render={({ field, fieldState }) => (
                                                        <InputText id={field.name} {...field}

                                                            style={{ width: "155px", height: "40px" }}
                                                            maxLength={50} />
                                                    )} />
                                                <label htmlFor="input" className="form-label font-fam-for-all">
                                                    First Name <span className="form-field-mandatory">*</span>
                                                </label>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-3'>
                                    <div className=" justify-content-center dialog-form-md-field-space">
                                        <div className="field">

                                            <span className="p-float-label mr-3">
                                                <Controller name="vendorName"
                                                    control={control}
                                                    rules={{ required: 'Vendor Name Required.', pattern: { value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, message: 'Please Enter Valid Vendor Name' } }}
                                                    render={({ field, fieldState }) => (
                                                        <InputText id={field.name} {...field}

                                                            style={{ width: "155px", height: "40px" }}
                                                            maxLength={50} />
                                                    )} />
                                                <label htmlFor="input" className="form-label font-fam-for-all">
                                                    Middle Name <span className="form-field-mandatory">*</span>
                                                </label>
                                            </span>

                                        </div>
                                    </div>
                                </div>

                                <div className='col-3'>
                                    <div className=" justify-content-center dialog-form-md-field-space">
                                        <div className="field">

                                            <span className="p-float-label mr-3">
                                                <Controller name="vendorName"
                                                    control={control}
                                                    rules={{ required: 'Vendor Name Required.', pattern: { value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, message: 'Please Enter Valid Vendor Name' } }}
                                                    render={({ field, fieldState }) => (
                                                        <InputText id={field.name} {...field}

                                                            style={{ width: "155px", height: "40px" }}
                                                            maxLength={50} />
                                                    )} />
                                                <label htmlFor="input" className="form-label font-fam-for-all">
                                                    Last Name <span className="form-field-mandatory">*</span>
                                                </label>
                                            </span>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='px-6 pt-0 pb-6 grid card-content'>

                            <div className='col-6'>

                                <div className=" justify-content-center dialog-form-md-field-space">
                                    <div className="field">

                                        <span className="p-float-label mr-3">
                                            <Controller name="vendorName"
                                                control={control}
                                                rules={{ required: 'Vendor Name Required.', pattern: { value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, message: 'Please Enter Valid Vendor Name' } }}
                                                render={({ field, fieldState }) => (
                                                    <InputText id={field.name} {...field}

                                                        className='dialog-form-input-field'
                                                        maxLength={50} />
                                                )} />
                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                Email <span className="form-field-mandatory">*</span>
                                            </label>
                                        </span>

                                    </div>
                                </div>

                                <div className=" justify-content-center dialog-form-md-field-space mt-5">
                                    <div className="field">

                                        <span className="p-float-label mr-3">
                                            <Controller name="contactNo"
                                                control={control}
                                                rules={{ required: 'Contact No Required.', pattern: { value: /^\d+$/, message: 'Please Enter Valid Contact' } }}
                                                render={({ field, fieldState }) => (
                                                    <InputText id={field.name} {...field}

                                                        className='dialog-form-input-field'
                                                        maxLength={10} />
                                                )} />
                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                Phone <span className="form-field-mandatory">*</span>
                                            </label>
                                        </span>

                                    </div>
                                </div>





                                <div className=" justify-content-center dialog-form-md-field-space mt-5">
                                    <div className="field">

                                        <span className="p-float-label mr-3">
                                            <Controller name="contactNo"
                                                control={control}
                                                rules={{ required: 'Contact No Required.', pattern: { value: /^\d+$/, message: 'Please Enter Valid Contact' } }}
                                                render={({ field, fieldState }) => (
                                                    <InputText id={field.name} {...field}

                                                        className='dialog-form-input-field'
                                                        maxLength={10} />
                                                )} />
                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                Fax <span className="form-field-mandatory">*</span>
                                            </label>
                                        </span>

                                    </div>
                                </div>




                            </div>
                            <div className='col-6'>

                                <div className=" justify-content-center dialog-form-md-field-space" >

                                    <span className="p-float-label mr-3">
                                        <Controller name="contactPerson"
                                            control={control}
                                            rules={{ required: 'Contact Person Required.', pattern: { value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, message: 'Please Enter Valid Contact Person Name.' } }}
                                            render={({ field, fieldState }) => (

                                                <InputText
                                                    id={field.name} name="jd" value={field.value} onChange={(e) => field.onChange(e.value)}
                                                    style={{ width: '100%', height: '40px' }}

                                                    className='dialog-form-input-field' required />
                                            )} />
                                        <label htmlFor="input" className="form-label font-fam-for-all">
                                            Alt Email <span className="form-field-mandatory">*</span>
                                        </label>
                                    </span>

                                </div>

                                <div className=" justify-content-center dialog-form-md-field-space mt-5">
                                    <div className="field">

                                        <span className="p-float-label mr-3">
                                            <Controller name="altContactNo"
                                                control={control}
                                                rules={{ required: 'Alternate Contact No Required.', pattern: { value: /^\d+$/, message: 'Please Enter Valid Contact' } }}
                                                render={({ field, fieldState }) => (
                                                    <InputText id={field.name} {...field}

                                                        className='dialog-form-input-field'
                                                        maxLength={10} />
                                                )} />
                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                Alt Phone <span className="form-field-mandatory">*</span>
                                            </label>
                                        </span>

                                    </div>
                                </div>





                                <div className=" justify-content-center dialog-form-md-field-space mt-5" >

                                    <span className="p-float-label mr-3">
                                        <Controller name="contactPerson"
                                            control={control}
                                            rules={{ required: 'Contact Person Required.', pattern: { value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, message: 'Please Enter Valid Contact Person Name.' } }}
                                            render={({ field, fieldState }) => (

                                                <InputText
                                                    id={field.name} name="jd" value={field.value} onChange={(e) => field.onChange(e.value)}
                                                    style={{ width: '100%', height: '40px' }}

                                                    className='dialog-form-input-field' required />
                                            )} />
                                        <label htmlFor="input" className="form-label font-fam-for-all">
                                            Alt Fax <span className="form-field-mandatory">*</span>
                                        </label>
                                    </span>

                                </div>



                            </div>

                        </div>

                    </div>

                    <div className=' input-sidebar-card mt-4'>
                        <div className='px-6 pt-4'>
                            <div className='flex'>
                                <i className='pi pi-address-book'></i>
                                <div className='font-fam-for-all'>
                                    Home Address
                                </div>
                            </div>
                        </div>
                        <div className='px-6 pt-4 pb-6 grid'>
                            <div className='col-6'>

                                <div className=" justify-content-center dialog-form-md-field-space">
                                    <div className="field">

                                        <span className="p-float-label mr-3">
                                            <Controller name="vendorName"
                                                control={control}
                                                rules={{ required: 'Vendor Name Required.', pattern: { value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, message: 'Please Enter Valid Vendor Name' } }}
                                                render={({ field, fieldState }) => (
                                                    <InputText id={field.name} {...field}

                                                        className='dialog-form-input-field'
                                                        maxLength={50} />
                                                )} />
                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                Address Line 1 <span className="form-field-mandatory">*</span>
                                            </label>
                                        </span>

                                    </div>
                                </div>

                                <div className=" justify-content-center dialog-form-md-field-space mt-4">
                                    <div className="field">

                                        <span className="p-float-label mr-3">
                                            <Controller name="contactNo"
                                                control={control}
                                                rules={{ required: 'Contact No Required.', pattern: { value: /^\d+$/, message: 'Please Enter Valid Contact' } }}
                                                render={({ field, fieldState }) => (
                                                    <InputText id={field.name} {...field}

                                                        className='dialog-form-input-field'
                                                        maxLength={10} />
                                                )} />
                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                Address Line 3 <span className="form-field-mandatory">*</span>
                                            </label>
                                        </span>

                                    </div>
                                </div>

                                <div className=" justify-content-center  dialog-form-md-field-space mt-4">
                                    <div className="field">

                                        <span className="p-float-label mr-3">
                                            <Controller name="address"
                                                control={control}
                                                rules={{ required: 'Address Required.', }}
                                                render={({ field, fieldState }) => (
                                                    <InputText id={field.name} {...field}

                                                        className='dialog-form-input-field'
                                                        maxLength={50} />
                                                )} />
                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                City <span className="form-field-mandatory">*</span>
                                            </label>
                                        </span>

                                    </div>
                                </div>

                                <div className=" justify-content-center  dialog-form-md-field-space mt-4">
                                    <div className="field">

                                        <span className="p-float-label mr-3">
                                            <Controller name="address"
                                                control={control}
                                                rules={{ required: 'Address Required.', }}
                                                render={({ field, fieldState }) => (
                                                    <InputText id={field.name} {...field}

                                                        className='dialog-form-input-field'
                                                        maxLength={50} />
                                                )} />
                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                Zip Code <span className="form-field-mandatory">*</span>
                                            </label>
                                        </span>

                                    </div>
                                </div>


                            </div>
                            <div className='col-6'>

                                <div className=" justify-content-center dialog-form-md-field-space" >

                                    <span className="p-float-label mr-3">
                                        <Controller name="contactPerson"
                                            control={control}
                                            rules={{ required: 'Contact Person Required.', pattern: { value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, message: 'Please Enter Valid Contact Person Name.' } }}
                                            render={({ field, fieldState }) => (

                                                <InputText
                                                    id={field.name} name="jd" value={field.value} onChange={(e) => field.onChange(e.value)}
                                                    style={{ width: '100%', height: '40px' }}

                                                    className='dialog-form-input-field' required />
                                            )} />
                                        <label htmlFor="input" className="form-label font-fam-for-all">
                                            Address Line 2 <span className="form-field-mandatory">*</span>
                                        </label>
                                    </span>

                                </div>

                                <div className=" justify-content-center dialog-form-md-field-space mt-4">
                                    <div className="field">

                                        <span className="p-float-label mr-3">
                                            <Controller name="altContactNo"
                                                control={control}
                                                rules={{ required: 'Alternate Contact No Required.', pattern: { value: /^\d+$/, message: 'Please Enter Valid Contact' } }}
                                                render={({ field, fieldState }) => (
                                                    <InputText id={field.name} {...field}

                                                        className='dialog-form-input-field'
                                                        maxLength={10} />
                                                )} />
                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                Region <span className="form-field-mandatory">*</span>
                                            </label>
                                        </span>

                                    </div>
                                </div>

                                <div className=" justify-content-center dialog-form-md-field-space mt-4">
                                    <div className="field">

                                        <span className="p-float-label mr-3">
                                            <Controller name="altContactNo"
                                                control={control}
                                                rules={{ required: 'Alternate Contact No Required.', pattern: { value: /^\d+$/, message: 'Please Enter Valid Contact' } }}
                                                render={({ field, fieldState }) => (
                                                    <InputText id={field.name} {...field}

                                                        className='dialog-form-input-field'
                                                        maxLength={10} />
                                                )} />
                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                State <span className="form-field-mandatory">*</span>
                                            </label>
                                        </span>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>


                    <div className=' input-sidebar-card mt-4'>
                        <div className='px-6 pt-4'>
                            <div className='flex'>
                                <i className='pi pi-address-book'></i>
                                <div className='font-fam-for-all'>
                                    Billing Address
                                </div>
                            </div>
                        </div>
                        <div className='px-6 pt-4 pb-6 grid'>
                            <div className='col-6'>

                                <div className=" justify-content-center dialog-form-md-field-space">
                                    <div className="field">

                                        <span className="p-float-label mr-3">
                                            <Controller name="vendorName"
                                                control={control}
                                                rules={{ required: 'Vendor Name Required.', pattern: { value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, message: 'Please Enter Valid Vendor Name' } }}
                                                render={({ field, fieldState }) => (
                                                    <InputText id={field.name} {...field}

                                                        className='dialog-form-input-field'
                                                        maxLength={50} />
                                                )} />
                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                Street <span className="form-field-mandatory">*</span>
                                            </label>
                                        </span>

                                    </div>
                                </div>

                                <div className=" justify-content-center dialog-form-md-field-space mt-4">
                                    <div className="field">

                                        <span className="p-float-label mr-3">
                                            <Controller name="contactNo"
                                                control={control}
                                                rules={{ required: 'Contact No Required.', pattern: { value: /^\d+$/, message: 'Please Enter Valid Contact' } }}
                                                render={({ field, fieldState }) => (
                                                    <InputText id={field.name} {...field}

                                                        className='dialog-form-input-field'
                                                        maxLength={10} />
                                                )} />
                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                State <span className="form-field-mandatory">*</span>
                                            </label>
                                        </span>

                                    </div>
                                </div>



                            </div>
                            <div className='col-6'>

                                <div className=" justify-content-center dialog-form-md-field-space" >

                                    <span className="p-float-label mr-3">
                                        <Controller name="contactPerson"
                                            control={control}
                                            rules={{ required: 'Contact Person Required.', pattern: { value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, message: 'Please Enter Valid Contact Person Name.' } }}
                                            render={({ field, fieldState }) => (

                                                <InputText
                                                    id={field.name} name="jd" value={field.value} onChange={(e) => field.onChange(e.value)}
                                                    style={{ width: '100%', height: '40px' }}

                                                    className='dialog-form-input-field' required />
                                            )} />
                                        <label htmlFor="input" className="form-label font-fam-for-all">
                                            City <span className="form-field-mandatory">*</span>
                                        </label>
                                    </span>

                                </div>

                                <div className=" justify-content-center dialog-form-md-field-space mt-4">
                                    <div className="field">

                                        <span className="p-float-label mr-3">
                                            <Controller name="altContactNo"
                                                control={control}
                                                rules={{ required: 'Alternate Contact No Required.', pattern: { value: /^\d+$/, message: 'Please Enter Valid Contact' } }}
                                                render={({ field, fieldState }) => (
                                                    <InputText id={field.name} {...field}

                                                        className='dialog-form-input-field'
                                                        maxLength={10} />
                                                )} />
                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                Postal Code <span className="form-field-mandatory">*</span>
                                            </label>
                                        </span>

                                    </div>
                                </div>




                            </div>
                        </div>



                        <div className='px-6 py-4'>
                            <div className='flex'>
                                <i className='pi pi-address-book'></i>
                                <div className='font-fam-for-all'>
                                    Shipping Address
                                </div>
                            </div>
                            <div className='flex mt-3'>
                                <div>
                                    <Checkbox checked={isEqualAddress} onChange={(e) => setIsEqualAddress(e.checked)} />

                                </div>
                                <div className='ml-2 font-fam-for-all'>
                                    Click here, If Shipping address same as Billing address
                                </div>
                            </div>
                        </div>

                        {
                            !isEqualAddress && <div className='px-6 pt-4 pb-6 grid'>
                                <div className='col-6'>

                                    <div className=" justify-content-center dialog-form-md-field-space">
                                        <div className="field">

                                            <span className="p-float-label mr-3">
                                                <Controller name="vendorName"
                                                    control={control}
                                                    rules={{ required: 'Vendor Name Required.', pattern: { value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, message: 'Please Enter Valid Vendor Name' } }}
                                                    render={({ field, fieldState }) => (
                                                        <InputText id={field.name} {...field}

                                                            className='dialog-form-input-field'
                                                            maxLength={50} />
                                                    )} />
                                                <label htmlFor="input" className="form-label font-fam-for-all">
                                                    Street <span className="form-field-mandatory">*</span>
                                                </label>
                                            </span>

                                        </div>
                                    </div>

                                    <div className=" justify-content-center dialog-form-md-field-space mt-4">
                                        <div className="field">

                                            <span className="p-float-label mr-3">
                                                <Controller name="contactNo"
                                                    control={control}
                                                    rules={{ required: 'Contact No Required.', pattern: { value: /^\d+$/, message: 'Please Enter Valid Contact' } }}
                                                    render={({ field, fieldState }) => (
                                                        <InputText id={field.name} {...field}

                                                            className='dialog-form-input-field'
                                                            maxLength={10} />
                                                    )} />
                                                <label htmlFor="input" className="form-label font-fam-for-all">
                                                    State <span className="form-field-mandatory">*</span>
                                                </label>
                                            </span>

                                        </div>
                                    </div>



                                </div>
                                <div className='col-6'>

                                    <div className=" justify-content-center dialog-form-md-field-space" >

                                        <span className="p-float-label mr-3">
                                            <Controller name="contactPerson"
                                                control={control}
                                                rules={{ required: 'Contact Person Required.', pattern: { value: /^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/, message: 'Please Enter Valid Contact Person Name.' } }}
                                                render={({ field, fieldState }) => (

                                                    <InputText
                                                        id={field.name} name="jd" value={field.value} onChange={(e) => field.onChange(e.value)}
                                                        style={{ width: '100%', height: '40px' }}

                                                        className='dialog-form-input-field' required />
                                                )} />
                                            <label htmlFor="input" className="form-label font-fam-for-all">
                                                City <span className="form-field-mandatory">*</span>
                                            </label>
                                        </span>

                                    </div>

                                    <div className=" justify-content-center dialog-form-md-field-space mt-4">
                                        <div className="field">

                                            <span className="p-float-label mr-3">
                                                <Controller name="altContactNo"
                                                    control={control}
                                                    rules={{ required: 'Alternate Contact No Required.', pattern: { value: /^\d+$/, message: 'Please Enter Valid Contact' } }}
                                                    render={({ field, fieldState }) => (
                                                        <InputText id={field.name} {...field}

                                                            className='dialog-form-input-field'
                                                            maxLength={10} />
                                                    )} />
                                                <label htmlFor="input" className="form-label font-fam-for-all">
                                                    Postal Code <span className="form-field-mandatory">*</span>
                                                </label>
                                            </span>

                                        </div>
                                    </div>




                                </div>
                            </div>
                        }

                    </div>












                    <div className='flex justify-content-center dialog-form-md-group-Btn m-5'>
                        <Button type="button" className="mt-2  font-fam-for-all text-center text-lg bg-primary-reverse mr-2" style={{ height: "40px" }} onClick={cancelView} >Cancel</Button>
                        <Button type='submit' className="mt-2  font-fam-for-all text-center text-lg mr-3" style={{ height: "40px" }}  >Submit</Button>
                    </div>
                </form>
            </Sidebar>
        </div>
    )
}

export default AddCustomUser