import React, { useEffect } from "react";
import { useState, useRef, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { Message } from "primereact/message";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";

import { Password } from "primereact/password";
import { useNavigate } from "react-router";

import Switch from "@mui/material/Switch";
import SignIn from "../../services/SignInServices";
import errorMessages from "../../utils/errorMessages";
import OTPInputBox from "../../utils/OtpInputBox";
import ForgotPasswordService from "../../services/forgotPasswordService";
import { Card } from "primereact/card";
import { createSearchParams, useSearchParams } from "react-router-dom";
import Logo from "../../Assets/Images/New_logo.png";
import Pic2 from "../../Assets/Images/new_onboardding_image.png";
import authContext from "../../common/authContext";
import CreditCardServices from "../../services/strapiService";
import { Avatar } from "primereact/avatar";
import { Link } from "react-router-dom";
import { showToast } from "../../utils/toastUtils";

export const AddOtpForm = ({ visible1, setVisible1 }) => {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    signUpPayload,
    setSignUpPayload,
    setIsTabRefresh,
  } = useContext(authContext);

  

  const [value, setValue] = useState("");
  const [ingredient, setIngredient] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [cityErr, setcityErr] = useState("");
  const toast = useRef(null);
  const [upload, setUpload] = useState("");
  const [selectedSkills, setSelectedSkills] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);

  const [otp, setOtp] = useState("");

  const [newpassword, setNewPassword] = useState("");

  const [confirmpassword, setConformPassword] = useState("");

  const [newpwdErr, setNewpwdErr] = useState("");

  const [confirmPwdErr, setConfirmPwdErr] = useState("");

  const [loading2, setLoading2] = useState(false);

  const [minutes, setMinutes] = useState(1);

  const [seconds, setSeconds] = useState(30);

  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({});

  const [passwordType, setPasswordType] = useState("password");

  const [passwordType1, setPasswordType1] = useState("password");

  const [resend, SetResend] = useState(false);

  const [sendOtpButton, setSendOtpButton] = useState(false);

  const [submitbutton, setsubmitbutton] = useState();

  const [isOtpForm, setIsOtpForm] = useState(true);

  const [emailErr, setemailErr] = useState("");

  const [showTimer, setShowTimer] = useState(false);

  const [showPasswordForm, setShowPasswordForm] = useState(false);

  const [showPassword, setShowPassword] = useState(true);

  const [otpErr, setOtpErr] = useState("");

  const [showOtpTimer, setShowOtpTimer] = useState(false);

  const [isShowOtp, setIsShowOtp] = useState(false);

  const toastBR = useRef(null);

  const [clearOtp, setClearOtp] = useState(false);

  const [userdataParam] = useSearchParams();

  const name = userdataParam.get("name");

  const [otpAttempts, setOtpAttempts] = useState(() => {
    const savedAttempts = localStorage.getItem("otpAttempts");
    return savedAttempts ? parseInt(savedAttempts, 10) : 0;
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [isResendData, setIsResendData] = useState(false);

  
  useEffect(() => {
    const isRefreshed = sessionStorage.getItem("isRefreshed");

    if (isRefreshed) {
      navigate("/signup");
      sessionStorage.removeItem("isRefreshed");
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("isRefreshed", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  useEffect(() => {
    if (otpAttempts >= 3) {
      setIsButtonDisabled(true);
      const timeout = setTimeout(() => {
        setOtpAttempts(0);
        localStorage.setItem("otpAttempts", 0);
        setIsButtonDisabled(false);
      }, 15 * 60 * 1000);
      return () => clearTimeout(timeout);
    }
  }, [otpAttempts]);

  useEffect(() => {
    setMinutes(1);
    setSeconds(59);
    SetResend(false);
    setShowTimer(true);
    setShowOtpTimer(true);
  }, [signUpPayload]);

  const handleOtpChange = (value) => {
    setOtp(value);
    if (value !== "" && value.length === 6 && signUpPayload) {
      setIsLoading(true);
      ForgotPasswordService.sendOtp(value, signUpPayload.username)
        .then((res) => {
          

          setShowPassword(false);

          if (res.status === 201) {
            // toastBR.current.show({ severity: 'warn', summary: 'Oops', detail: 'Both passwords must be same', life: 2800 });
            setShowOtpTimer(false);
            setShowPasswordForm(true);

            CreditCardServices.createModeldata(signUpPayload, "signup")
              .then((res) => {
                
                setIsLoading(false);
                setIsTabRefresh(false);
                setIsOtpForm(false);
              })
              .catch((err) => {
                

                setIsTabRefresh(false);
                setIsLoading(false);
                if (err.response !== undefined) {
                  if (
                    err.response.status === 701 ||
                    err.response.status === 500
                  ) {
                    
                    if (err.response.data !== undefined) {
                      // if (toast.current !== undefined) {
                      //   toast.current.show({
                      //     severity: "warn",
                      //     summary: "Warning",

                      //     content: (props) => (
                      //       <div
                      //         className="flex flex-column align-items-left"
                      //         style={{ flex: "1" }}
                      //       >
                      //         <div className="flex align-items-center gap-2">
                      //           <Avatar
                      //             shape="circle"
                      //             style={{
                      //               backgroundColor: "#0047FF",
                      //               color: "#ffffff",
                      //             }}
                      //           >
                      //             <div
                      //               dangerouslySetInnerHTML={{
                      //                 __html: svgContent,
                      //               }}
                      //             />
                      //           </Avatar>
                      //           <span className="font-bold text-900">
                      //             vState Filings
                      //           </span>
                      //         </div>
                      //         <div className="font-medium text-lg my-3 text-900">
                      //           {errorMessages.userAlreadyPresent}
                      //         </div>
                      //       </div>
                      //     ),
                      //     life: 3000,
                      //   });
                      // }

            showToast("error", `${errorMessages.userAlreadyPresent}`);

                    }
                  }
                }
              });
          }
        })
        .catch((err) => {
          
          setIsLoading(false);
          if (err.response !== undefined) {
            if (err.response.status !== undefined) {
              if (err.response.status === 400) {
                // if (toastBR.current !== undefined) {
                //   toastBR.current.show({
                //     severity: "warn",
                //     summary: "Oops",
                //     detail: "You have entered wrong OTP",
                //     life: 2800,
                //   });
                // }
            showToast("error", "You have entered wrong OTP");             }
            }
          }
        });
    }
    
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          /* resend button enable*/
          SetResend(true);

          clearInterval(interval);
        } else {
          SetResend(false);

          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [resend, seconds]);

  // const ResendOtpButton = () => {

  //     setClearOtp(!clearOtp)
  //     setOtpErr("")
  //     let username = JSON.parse(localStorage.getItem("usermail"))

  //     if (username !== null && name) {
  //         setIsTabRefresh(true)
  //         setIsLoading(true)
  //         ForgotPasswordService.validateEmail(username,name)
  //             .then((res) => {
  //                 console.log(res)
  //                 if (res.status === 201) {
  //                     setIsTabRefresh(false)
  //                     setIsLoading(false)
  //                     setMinutes(1);
  //                     setSeconds(30);
  //                     SetResend(false)
  //                     setShowTimer(true)
  //                     setShowOtpTimer(true)
  //                 }

  //             })
  //             .catch((err) => {
  //                 console.log(err)
  //                 setIsTabRefresh(false)
  //                 setIsLoading(false)
  //                 if (err.response !== undefined) {
  //                     if (err.response.status === 700) {
  //                         if (err.response.data !== undefined) {

  //                             if (toast.current !== undefined) {
  //                                 toast.current.show({ severity: 'warn', summary: 'Warning', detail: `${errorMessages.emailValidation}`, life: 3000 });

  //                             }
  //                         }
  //                     }
  //                 }
  //             })
  //     }

  // }

  const ResendOtpButton = () => {
    let username = JSON.parse(localStorage.getItem("usermail"));

    setClearOtp(!clearOtp);
    setOtpErr("");
    if (username && otpAttempts < 2) {
      setOtpAttempts((prev) => {
        const newAttempts = prev + 1;
        localStorage.setItem("otpAttempts", newAttempts);
        return newAttempts;
      });

      setIsButtonDisabled(true);
      setTimeout(() => setIsButtonDisabled(false), 3000); // Disable button for 3 seconds
      setIsLoading(true);

      ForgotPasswordService.validateEmail(username, name)
        .then((res) => {
          
          if (res.status === 201) {
            // Reset timer and show OTP timer
            setMinutes(1);
            setSeconds(59);
            setShowTimer(true);
            SetResend(false);
            setShowOtpTimer(true);
            setIsLoading(false);
            // toast.current?.show({
            //   severity: "success",
            //   summary: "OTP Resent",
            //   detail: "A new OTP has been sent to your registered email.",
            //   life: 3000,
            // });

            showToast("success", "A new OTP has been sent to your registered email");
            
            setIsResendData(true);
          }
        })
        .catch((err) => {
          
          setIsLoading(false);
          if (err.response?.status === 700 && err.response.data) {
            // toast.current?.show({
            //   severity: "warn",
            //   summary: "Warning",
            //   detail: "Invalid email address",
            //   life: 3000,
            // });
            showToast("error", "Invalid email address");

          }
        });
    } else if (otpAttempts >= 2) {
      // toast.current?.show({
      //   severity: "warn",
      //   summary: "Warning",
      //   detail:
      //     "You have reached the maximum OTP resend attempts. Please try again after 15 minutes.",
      //   life: 3000,
      // });
      showToast("error", "You have reached the maximum OTP resend attempts. Please try again after 15 minutes.");

    }
  };

  const dialogView = () => setVisible1(false);
  const cancelView = () => {
    setcityErr("");
    setVisible1(false);
  };

  let newObject = window.localStorage.getItem("user");
  let updatedUser = Object.assign({}, JSON.parse(newObject));

  const handleCity = (e) => {
    setemailErr("");
    let City = e.target.value;
    if (City === "") {
      setemailErr(errorMessages.emailRequired);
    }
    if (City && City.trim() !== "") {
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(City)) {
        setemailErr(errorMessages.emailValidation);
      }
    }
    setIngredient(e.target.value);
    setEmail(e.target.value);
  };

  const handleChangeOtp = (e) => {
    setOtpErr("");
    const otp = e.target.value;
    if (otp && otp.trim() !== "") {
      if (!/^\d+$/.test(otp)) {
        setOtpErr("Please Enter Valid 6 Digits OTP");
      }
    }
    if (otp.length < 6) {
      setOtpErr("Please Enter Valid 6 Digits OTP");
    }
    setOtp(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none">
    <circle cx="9.79519" cy="2.00625" r="2.00625" fill="#A3D5C9"/>
<circle cx="7.67096" cy="6.13675" r="1.06213" fill="#A3D5C9"/>
<circle cx="1.23915" cy="11.5064" r="1.23915" fill="#A3D5C9"/>
<circle cx="15.2829" cy="12.4506" r="1.47518" fill="#A3D5C9"/>
<circle cx="19.0004" cy="8.26101" r="1.06213" fill="#A3D5C9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6886 0.650298C15.7808 1.14561 15.3314 1.88426 15.3314 2.88125C15.3314 3.25051 15.4132 3.69897 15.5172 3.90007C15.6192 4.09751 15.6675 4.29412 15.6244 4.33672C15.5812 4.37932 14.4255 5.56549 13.0562 6.97258L10.5663 9.5309L10.2051 9.33783C9.72642 9.08209 8.5438 9.08256 8.0643 9.33889L7.70094 9.53302L6.31214 8.14057L4.92323 6.74811L5.10155 6.40316C5.40803 5.8106 5.4491 5.28143 5.23785 4.6484C4.87201 3.5524 4.02785 2.93907 2.885 2.93907C1.62401 2.93907 0.628439 3.83469 0.499331 5.08529C0.309446 6.92561 2.08686 8.27074 3.82911 7.60537L4.36643 7.40014L5.74319 8.76298L7.11995 10.1258L6.91815 10.5489C6.61756 11.1792 6.65663 12.0617 7.01421 12.7184C8.17748 14.8542 11.3541 14.1761 11.5336 11.7535C11.5703 11.2579 11.5252 10.9367 11.3712 10.5952L11.1581 10.1225L13.751 7.52949L16.3439 4.93659L16.8074 5.13037C17.5107 5.42411 18.5064 5.31601 19.138 4.87711C20.5128 3.92202 20.5099 1.83623 19.1323 0.87901C18.7142 0.58834 18.4875 0.520836 17.8341 0.49204C17.2702 0.467257 16.9408 0.512693 16.6886 0.650298Z" fill="white"/>
</svg>
 `;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (signUpPayload) {
      localStorage.setItem("usermail", JSON.stringify(signUpPayload.username));
      ForgotPasswordService.sendEmail(signUpPayload.username)
        .then((res) => {
          
          if (res.status === 201) {
            if (res.data === "We have send OTP to your email") {
              // if (toast.current !== null) {
              //   toast.current.show({
              //     severity: "success",
              //     summary: "Info",
              //     detail: "OTP Sent  To Your Register Mail Address",
              //     life: 2800,
              //   });
              // }
      showToast("success", "OTP sent to your registered mail address.");


            }
            setIsShowOtp(true);
            setShowOtpTimer(true);
            setIsShowOtp(true);

            setMinutes(1);
            setSeconds(30);
            SetResend(false);
            setShowTimer(true);
          }
        })
        .catch((err) => {
          
          if (err.response.status !== undefined) {
            if (err.response.status === 404) {
              // if (toast.current !== null) {
              //   toast.current.show({
              //     severity: "warn",
              //     summary: "Oops",
              //     detail: "Email is not registered",
              //     life: 2800,
              //   });
              // }
      showToast("error", "Email is not registered");

            }
          }

          if (err.response !== undefined) {
            if (err.response.status === 700) {
              if (err.response.data !== undefined) {
                // if (toast.current !== undefined) {
                //   toast.current.show({
                //     severity: "warn",
                //     summary: "Warning",
                //     detail: `${errorMessages.emailValidation}`,
                //     life: 3000,
                //   });
                // }
      showToast("error", `${errorMessages.emailValidation}`);

              }
            }
          }
        });
    } else {
      if (email === "") {
        setemailErr(errorMessages.emailRequired);
      }
      if (email && email.trim() !== "") {
        // if (!validator.isEmail(email)) {
        //     setemailErr('Please Enter Valid Email Address. E.g. example@email.com');
        // }
      }
    }
  };

  

  const handleOTP = (e) => {
    e.preventDefault();

    if (otp !== "" && signUpPayload) {
      setIsLoading(true);
      ForgotPasswordService.sendOtp(otp, signUpPayload.username)
        .then((res) => {
          

          setShowPassword(false);

          if (res.status === 201) {
            // toastBR.current.show({ severity: 'warn', summary: 'Oops', detail: 'Both passwords must be same', life: 2800 });
            setShowOtpTimer(false);
            setShowPasswordForm(true);

            CreditCardServices.createModeldata(signUpPayload, "signup")
              .then((res) => {
                
                setIsLoading(false);
                setIsTabRefresh(false);
                setIsOtpForm(false);
              })
              .catch((err) => {
                

                setIsTabRefresh(false);
                setIsLoading(false);
                if (err.response !== undefined) {
                  if (err.response.status === 701) {
                    
                    if (err.response.data !== undefined) {
                      // if (toast.current !== undefined) {
                      //   toast.current.show({
                      //     severity: "warn",
                      //     summary: "Warning",

                      //     content: (props) => (
                      //       <div
                      //         className="flex flex-column align-items-left"
                      //         style={{ flex: "1" }}
                      //       >
                      //         <div className="flex align-items-center gap-2">
                      //           <Avatar
                      //             shape="circle"
                      //             style={{
                      //               backgroundColor: "#0047FF",
                      //               color: "#ffffff",
                      //             }}
                      //           >
                      //             <div
                      //               dangerouslySetInnerHTML={{
                      //                 __html: svgContent,
                      //               }}
                      //             />
                      //           </Avatar>
                      //           <span className="font-bold text-900">
                      //             vState Filings
                      //           </span>
                      //         </div>
                      //         <div className="font-medium text-lg my-3 text-900">
                      //           {errorMessages.userAlreadyPresent}
                      //         </div>
                      //       </div>
                      //     ),
                      //     life: 3000,
                      //   });
                      // }

      showToast("error", `${errorMessages.userAlreadyPresent}`);

                    }
                  } else {
                    // if (toast.current !== undefined) {
                    //     toast.current.show({
                    //         severity: 'warn', summary: 'Warning',
                    //         content: (props) => (
                    //             <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
                    //                 <div className="flex align-items-center gap-2">
                    //                     <Avatar shape="circle" style={{ backgroundColor: '#0047FF', color: '#ffffff' }} >
                    //                         <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                    //                     </Avatar>
                    //                     <span className="font-bold text-900">vState Filings</span>
                    //                 </div>
                    //                 <div className="font-medium text-lg my-3 text-900">{errorMessages.somethingWrong}</div>
                    //             </div>
                    //         ),
                    //         life: 3000,
                    //     });
                    // }
                  }
                } else {
                  // if (toast.current !== undefined) {
                  //     toast.current.show({
                  //         severity: 'warn', summary: 'Warning',
                  //         content: (props) => (
                  //             <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
                  //                 <div className="flex align-items-center gap-2">
                  //                     <Avatar shape="circle" style={{ backgroundColor: '#0047FF', color: '#ffffff' }} >
                  //                         <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                  //                     </Avatar>
                  //                     <span className="font-bold text-900">vState Filings</span>
                  //                 </div>
                  //                 <div className="font-medium text-lg my-3 text-900">{errorMessages.somethingWrong}</div>
                  //             </div>
                  //         ),
                  //         life: 3000,
                  //     });
                  // }
                }
              });
          }
        })
        .catch((err) => {
          
          setIsLoading(false);
          if (err.response !== undefined) {
            if (err.response.status !== undefined) {
              if (err.response.status === 400) {
                // if (toastBR.current !== undefined) {
                //   toastBR.current.show({
                //     severity: "warn",
                //     summary: "Oops",
                //     detail: "You have entered wrong OTP",
                //     life: 2800,
                //   });
                // }
      showToast("error", `You have entered wrong OTP`);
              }
            }
          }
        });
    } else {
      setOtpErr("OTP Required");
    }
  };

  // const getFormErrorMessage = (name) => {
  //     return errors[name] && <small className="p-error font-fam-for-all">{errors[name].message}</small>
  // };

  const backbutton = () => {
    navigate("/login");
  };

  const handleClick = () => {
    setcityErr("");
    setVisible1(false);
  };

  return (
    <div>
      <Toast ref={toastBR} />
      <Toast ref={toast} />
      {isLoading && (
        <div className="overlay">
          <div className="loader-card">
            {/* <div className="card-content"> */}
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="42" height="29" viewBox="0 0 42 29" fill="none">
                                <circle cx="20.175" cy="4.13223" r="4.13223" fill="#A3D5C9" />
                                <circle cx="15.7997" cy="12.6398" r="2.18765" fill="#A3D5C9" />
                                <circle cx="2.55226" cy="23.6995" r="2.55226" fill="#A3D5C9" />
                                <circle cx="31.4779" cy="25.6441" r="3.0384" fill="#A3D5C9" />
                                <circle cx="39.1346" cy="17.015" r="2.18765" fill="#A3D5C9" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M34.3734 1.33923C32.5037 2.35941 31.5781 3.8808 31.5781 5.93427C31.5781 6.69484 31.7466 7.61852 31.9607 8.03271C32.171 8.43937 32.2704 8.84433 32.1817 8.93208C32.0927 9.01983 29.7123 11.4629 26.8919 14.3611L21.7636 19.6304L21.0195 19.2328C20.0336 18.706 17.5978 18.707 16.6102 19.2349L15.8618 19.6348L13.0013 16.7668L10.1406 13.8988L10.5079 13.1883C11.1391 11.9678 11.2237 10.8779 10.7886 9.57403C10.0351 7.31662 8.29641 6.05338 5.9425 6.05338C3.34527 6.05338 1.29471 7.89805 1.02879 10.4739C0.637691 14.2644 4.2986 17.0349 7.88708 15.6645L8.99378 15.2417L11.8295 18.0487L14.6651 20.8557L14.2495 21.7272C13.6304 23.0254 13.7108 24.8431 14.4474 26.1956C16.8433 30.5947 23.3861 29.198 23.7558 24.2082C23.8314 23.1875 23.7385 22.5259 23.4213 21.8224L22.9823 20.8489L28.3229 15.5082L33.6634 10.1676L34.6182 10.5667C36.0667 11.1717 38.1175 10.9491 39.4184 10.0451C42.2502 8.07792 42.2441 3.78187 39.4067 1.81031C38.5455 1.21162 38.0786 1.07258 36.7329 1.01327C35.5713 0.962228 34.8929 1.05581 34.3734 1.33923Z" fill="white" />
                            </svg> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="29"
              viewBox="0 0 42 29"
              fill="none"
            >
              <circle cx="20.175" cy="4.13223" r="4.13223" fill="#A3D5C9" />
              <circle cx="15.7997" cy="12.6398" r="2.18765" fill="#A3D5C9" />
              <circle cx="2.55226" cy="23.6995" r="2.55226" fill="#A3D5C9" />
              <circle cx="31.4779" cy="25.6441" r="3.0384" fill="#A3D5C9" />
              <circle cx="39.1347" cy="17.015" r="2.18765" fill="#A3D5C9" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M34.3734 1.33923C32.5037 2.35941 31.5781 3.8808 31.5781 5.93427C31.5781 6.69484 31.7466 7.61852 31.9607 8.03271C32.171 8.43937 32.2704 8.84433 32.1817 8.93208C32.0927 9.01983 29.7123 11.4629 26.8919 14.3611L21.7636 19.6304L21.0195 19.2328C20.0336 18.706 17.5978 18.707 16.6102 19.2349L15.8618 19.6348L13.0013 16.7668L10.1406 13.8988L10.5079 13.1883C11.1391 11.9678 11.2237 10.8779 10.7886 9.57403C10.0351 7.31662 8.29641 6.05338 5.9425 6.05338C3.34527 6.05338 1.29471 7.89805 1.02879 10.4739C0.637691 14.2644 4.2986 17.0349 7.88708 15.6645L8.99378 15.2417L11.8295 18.0487L14.6651 20.8557L14.2495 21.7272C13.6304 23.0254 13.7108 24.8431 14.4474 26.1956C16.8433 30.5947 23.3861 29.198 23.7558 24.2082C23.8314 23.1875 23.7385 22.5259 23.4213 21.8224L22.9823 20.8489L28.3229 15.5082L33.6634 10.1676L34.6182 10.5667C36.0667 11.1717 38.1175 10.9491 39.4184 10.0451C42.2502 8.07792 42.2441 3.78187 39.4067 1.81031C38.5455 1.21162 38.0786 1.07258 36.7329 1.01327C35.5713 0.962228 34.8929 1.05581 34.3734 1.33923Z"
                fill="white"
              />
            </svg>
            <span className="text-white"></span>
            {/* </div> */}
          </div>
        </div>
      )}
      <div className="signup-user-container">
        <div className="signin-user-main">
          <div className="signup-content1-container">
            <div className="signin-header-container">
              <div className="signin-header-frame">
                <Link to="/">
                  <img
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/33812dbb1791d1818a5ada5906027cb4594dea186b3774c8184ded2410021718?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
                    className="flex-1 shrink-0 w-full aspect-[3.7]"
                    style={{ height: "48px" }}
                    alt="logo"
                  />
                </Link>
              </div>
            </div>

            <div className="signin-hero-container">
              <div className="signin-hero-main">
                <div className="signin-hero-header">
                  <div className="signin-hero-header-text1">
                    One platform for all your legal needs
                  </div>
                  <div className="signin-hero-header-text2">
                    Apply for legal documents, handle compliance and get legal
                    support instantly. Reduce stress, save time and enhance
                    business efficiency.
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <img src={Pic2} alt="logo" />
              </div>
            </div>

            <div className="signin-hero-footer-container">
              <div className="signin-hero-footer-text1">
                © vState Filings 2025
              </div>
              {/* <div className="signin-hero-footer-text-main">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M14.6667 4C14.6667 3.26666 14.0667 2.66666 13.3334 2.66666H2.66671C1.93337 2.66666 1.33337 3.26666 1.33337 4M14.6667 4V12C14.6667 12.7333 14.0667 13.3333 13.3334 13.3333H2.66671C1.93337 13.3333 1.33337 12.7333 1.33337 12V4M14.6667 4L8.00004 8.66666L1.33337 4"
                    stroke="#E9D7FE"
                    stroke-width="1.33333"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div className="signin-hero-footer-text2">
                  help@vstatefilings.com
                </div>
              </div> */}
            </div>
          </div>
          <div className="signup-content2-container">
            <div className="signup-content2-main">
              <div className="signup-content-main1 ">
                <div className="signup-content-main2 ">
                  {isOtpForm ? (
                    <Card className="  flex justify-content-center login-card box-shadow-lg">
                      <div className="signup-card-container">
                        <div className="signup-card-header">
                          <div className="signup-card-header-button">
                            <div className="signup-card-header-button-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                              >
                                <path
                                  d="M25.6666 7.00002C25.6666 5.71669 24.6166 4.66669 23.3333 4.66669H4.66665C3.38331 4.66669 2.33331 5.71669 2.33331 7.00002M25.6666 7.00002V21C25.6666 22.2834 24.6166 23.3334 23.3333 23.3334H4.66665C3.38331 23.3334 2.33331 22.2834 2.33331 21V7.00002M25.6666 7.00002L14 15.1667L2.33331 7.00002"
                                  stroke="#145EF0"
                                  stroke-width="2.33333"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>

                          <div className="signup-card-header-text1 text-center">
                            Check your email
                          </div>
                          <div className="signup-card-header-text2">
                            {isResendData ? (
                              <div className="signup-card-header-text2 text-blue-500">
                                {`A new OTP has been sent to ${signUpPayload?.username}`}
                              </div>
                            ) : (
                              <div className="signup-card-header-text2">
                                {`Please enter OTP code sent to ${signUpPayload?.username}`}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="signup-card-header">
                          <form onSubmit={handleOTP}>
                            <OTPInputBox
                              length={6}
                              onChange={handleOtpChange}
                              clearOtp={clearOtp}
                            />
                            {/* <p className='error-msg font-fam-for-all'>{otpErr}</p> */}

                            <div className="grid">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-8 mt-1">
                                <p className="countdown-text">
                                  {showTimer && showOtpTimer ? (
                                    seconds > 0 || minutes > 0 ? (
                                      <p className="ml-2 font-fam-for-all ">
                                        Time Remaining:{" "}
                                        {minutes < 10 ? `0${minutes}` : minutes}
                                        :
                                        {seconds < 10 ? `0${seconds}` : seconds}
                                      </p>
                                    ) : (
                                      <p className="ml-2 font-fam-for-all">
                                        Didn't receive OTP?
                                      </p>
                                    )
                                  ) : (
                                    ""
                                  )}{" "}
                                </p>
                              </div>

                              {resend && showOtpTimer ? (
                                <div className="col-12 flex justify-content-end">
                                  <Button
                                    label="Resend OTP"
                                    className="signup-btn vstate-button font-fam-for-all"
                                    onClick={ResendOtpButton}
                                    disabled={
                                      (resend ? false : true) ||
                                      isButtonDisabled
                                    }
                                    style={{ boxShadow: "none" }}
                                  />
                                </div>
                              ) : (
                                <div className="col-12 flex justify-content-end">
                                  <Button
                                    label="Verify OTP"
                                    type="submit"
                                    className="signup-btn vstate-button font-fam-for-all"
                                    style={{ boxShadow: "none" }}
                                  />
                                </div>
                              )}
                            </div>
                          </form>


                          <div className="flex justify-content-center dialog-form-md-group-Btn mt-3">
                  <span
                    className="font-fam-for-all"
                    style={{ fontSize: "14px" }}
                  >
                    Did not receive OTP?
                    {/* <a
                      href="mailto:support@vstatefilings.com"
                      className="inline-flex"
                    > */}
                    <Link to={"/signup"}>
                      <button
                        type="button"
                        // onClick={()=>setIsShowOtp(false)}
                        className="font-fam-for-all text-blue-500 hover:text-blue-700 ms-1"
                        >
                        Change Email
                      </button>
                        </Link>
                    {/* </a> */}
                  </span>
                </div>
                                        </div>
                      </div>
                    </Card>
                  ) : (
                    <Card className="  flex justify-content-center login-card box-shadow-lg">
                      <div className="signup-card-container">
                        <div className="signup-card-header">
                          <div className="signup-card-header-button1">
                            <div className="signup-card-header-button-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                              >
                                <path
                                  d="M25.6667 12.9267V14C25.6652 16.5158 24.8506 18.9638 23.3442 20.9788C21.8378 22.9938 19.7205 24.4679 17.3079 25.1812C14.8953 25.8945 12.3168 25.8089 9.95686 24.937C7.59694 24.0652 5.58208 22.4538 4.21277 20.3433C2.84346 18.2327 2.19307 15.7361 2.3586 13.2257C2.52414 10.7153 3.49672 8.32572 5.13131 6.41326C6.76589 4.50079 8.9749 3.16795 11.4289 2.61351C13.8828 2.05907 16.4503 2.31273 18.7483 3.33667M25.6667 4.66667L14 16.345L10.5 12.845"
                                  stroke="#039855"
                                  stroke-width="2.33333"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="signup-card-header-text1">
                            Registration successful
                          </div>
                          <div className="signup-card-header-text2 px-8 text-center">
                            Your account has been registered successfully. Click
                            below to log in.
                          </div>
                        </div>

                        <div className="signup-card-header">
                          <div className="grid justify-content-center mt-3">
                            <div className=" col-12 p-0">
                              <div className="flex justify-content-center">
                                <Button
                                  className="signup-btn vstate-button font-fam-for-all"
                                  style={{ width: "400px" }}
                                  onClick={() => navigate("/signin")}
                                >
                                  Continue
                                </Button>
                                {/* <Button type='button' className="mt-2 form-Btn form-Btn-Label font-fam-for-all text-center text-lg bg-primary-reverse mr-2" onClick={cancelView} >Cancel</Button> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
