import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import BlueButton from "../CommonSection/BlueButton";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import user1 from "../../../Assets/Images/Images/user/u1.png";
import user2 from "../../../Assets/Images/Images/user/u2.png";
import user3 from "../../../Assets/Images/Images/user/u3.png";
import user4 from "../../../Assets/Images/Images/user/u4.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import "../CommonSection/CommonStyle/CommonStyle.css";
import ReactStars from "react-rating-stars-component";
import AOS from "aos";
import "aos/dist/aos.css";

const ratingChanged = (newRating) => {
  
};

const testimonials = [
  {
    id: 1,
    img: user1,
    name: "Neil Sims",
    position: "CEO, Company_Name",
    text: "vState Filings has been invaluable to our business, facilitating payroll tax registration for employee hiring. They're a responsive partner, crucial to our business expansion. I highly recommend their services!",
  },
  {
    id: 2,
    img: user2,
    name: "Sarah Johnson",
    position: "Founder, StartupX",
    text: "vState Filings has been invaluable to our business, facilitating payroll tax registration for employee hiring. They're a responsive partner, crucial to our business expansion. I highly recommend their services!",
  },
  {
    id: 3,
    img: user3,
    name: "James Carter",
    position: "Manager, TechCorp",
    text: "vState Filings has been invaluable to our business, facilitating payroll tax registration for employee hiring. They're a responsive partner, crucial to our business expansion. I highly recommend their services!",
  },
  {
    id: 4,
    img: user4,
    name: "Emily Watson",
    position: "Director, Innovate Ltd.",
    text: "vState Filings has been invaluable to our business, facilitating payroll tax registration for employee hiring. They're a responsive partner, crucial to our business expansion. I highly recommend their services!",
  },
];

const TestimonialsSec = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section
      className="main-section-class"
      data-aos="fade-up"
      data-aos-duration="3000"
    >
      <div className="testimonials">
        <div className="main-tesimonial">
          <CommonHeadingTitle blueheading={"Testimonials"} />
          <div className="text-center">
            <h4 className="normal-common-txt">
              Trusted by businesses nationwide for seamless filing & compliance
              solutions.
            </h4>
          </div>
          <Container className="mt-4">
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true, dynamicBullets: true }}
              breakpoints={{
                0: { slidesPerView: 1 },
                768: { slidesPerView: 2 },
                991: { slidesPerView: 2 },
                1440: { slidesPerView: 3 },
              }}
              modules={[Pagination, Autoplay]}
              className="myswiper"
            >
              {testimonials.map((testimonial) => (
                <SwiperSlide key={testimonial.id}>
                  <div className="main-slide-class mb-5 mt-3">
                    {/* <ReactStars
                      count={5}
                      onChange={ratingChanged}
                      size={24}
                      activeColor="#ffd700"
                    /> */}
                    <p className="normal-common-txt mt-4">{testimonial.text}</p>
                    <div className="d-flex mt-5 justify-content-start">
                      <img
                        src={testimonial.img}
                        className="user-img me-3"
                        alt={testimonial.name}
                      />
                      <div className="mt-3">
                        <h3 className="user-name">{testimonial.name}</h3>
                        <p className="company-name">{testimonial.position}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Container>
        </div>
        <Container>
          <div className="mt-5">
            <Row>
              <div className="main-section-class mt-5">
                <CommonHeadingTitle
                  heading={"Want to"}
                  blueheading={"start a business?"}
                />
                <CommonHeadingTitle
                  heading={"lets get it done,"}
                  blueheading={"together."}
                />
                <div className="d-flex justify-content-center">
                  <Col xl={7} lg={7} md={12} sm={12} xs={12}>
                    <p className="normal-common-txt mt-4">
                      Streamline your business essentials under one roof. Stay
                      informed with timely reminders and compliance
                      notifications. Extend your business reach with adaptable
                      additional services.
                    </p>
                  </Col>
                </div>
                <div className="text-center mt-5">
                  <Link to="/webcontact">
                    <BlueButton
                      label="Contact Us"
                      icon={<FontAwesomeIcon icon={faArrowRightLong} />}
                      onclick={"/webcontact"}
                    />
                  </Link>
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default TestimonialsSec;
