import React from 'react';

function FeatureCard({ title, description, image }) {
  return (
    <article className="flex flex-col flex-1 shrink gap-4 justify-center p-6 bg-white rounded-lg shadow basis-0 min-w-[240px] max-md:px-5">
      {/* <img src={image} alt={title} className="w-full h-40 object-cover rounded-t-lg" /> */}
      <div className="flex flex-col w-full">
        <h2 className="text-lg font-semibold leading-none text-gray-900">
          {title}
        </h2>
        <p className="mt-2 text-base font-light leading-6 text-gray-500">
          {description}
        </p>
      </div>
    </article>
  );
}

export default FeatureCard;
