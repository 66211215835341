import axios from "axios";
import authHeader, { initAuth } from "./authHeader";
import encryption from "./CryptoClass";
import propertiesApp from "../utils/propertiesApp";

const getCustomer = () => {
  try {
    return axios.get("http://localhost:8000/customer");
  } catch (error) {
    return [];
  }
};

const getInvoice = () => {
  try {
    return axios.get("http://localhost:8000/invoice");
  } catch (error) {
    return [];
  }
};

const createShifts = (data) => {
  try {
    return axios.post("http://localhost:1337/api/llcs", data, {
      headers: authHeader(),
    });
  } catch (error) {
    if (error.response?.status === 401) {
      return error.response.status;
    }
  }
};

const createInvoice = (data) => {
  try {
    return axios.post("http://localhost:8000/create-invoice", data, {
      headers: authHeader(),
    });
  } catch (error) {
    if (error.response?.status === 401) {
      return error.response.status;
    }
  }
};

const createCustomer = (data) => {
  try {
    return axios.post("http://localhost:8000/create-customer", data, {
      headers: authHeader(),
    });
  } catch (error) {
    if (error.response?.status === 401) {
      return error.response.status;
    }
  }
};

const callAutomation = (data) => {
  const actualdata = {
    data: data,
  };
  try {
    return axios.post(
      `${propertiesApp.automation_host}/run-puppeteer`,
      actualdata,
      { headers: authHeader() }
    );
  } catch (error) {
    if (error.response?.status === 401) {
      return error.response.status;
    }
  }
};

const generateRefreshToken = (data) => {
  try {
    return axios.post(`${propertiesApp.quickbooks_host}/generate-token`, data, {
      headers: authHeader(),
    });
  } catch (error) {
    if (error.response?.status === 401) {
      return error.response.status;
    }
  }
};

const getUserDetails = () => {
  console.log(authHeader());
  const dataToEncrypt = {
    endpoint: `/contact/api/contactDetails/getAll`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.get(
      `/decrypt?data=${encryptedData}`,

      { headers: authHeader() }
    );
  } catch (error) {
    return [];
  }
};

const getEmployeeDetails = () => {
  console.log(authHeader());
  const dataToEncrypt = {
    endpoint: `/contact/api/employeeDetails/getAll`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: authHeader() }
    );
  } catch (error) {
    return [];
  }
};

const getEmployeeDetailsActive = () => {
  console.log(authHeader());
  const dataToEncrypt = {
    endpoint: `/contact/api/employeeDetails/getAll/active`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: authHeader() }
    );
  } catch (error) {
    return [];
  }
};

const getEmployeeByUserId = (id) => {
  const dataToEncrypt = {
    endpoint: `/contact/api/employeeDetails/getUserId/${id}`,
    payload: null,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: initAuth() }
    );
  } catch (error) {
    return [];
  }
};

const updateEmployee = (data) => {
  const dataToEncrypt = {
    endpoint: `/contact/api/employeeDetails/update`,
    payload: data,
    type: "put",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: authHeader() }
    );
  } catch (error) {
    return [];
  }
};

const updateUserDetails = (data, id) => {
  const dataToEncrypt = {
    endpoint: `/contact/api/contactDetails/update/${id}`,
    payload: data,
    type: "put",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: authHeader() }
    );
  } catch (error) {
    return [];
  }
};

const updateUserStatus = (userid, id) => {
  const dataToEncrypt = {
    endpoint: `/authentication/api/auth/update/userStatus/${userid}/${id}`,
    payload: null,
    type: "post",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: authHeader() }
    );
  } catch (error) {
    return [];
  }
};

const updateUserRoles = (data) => {
  const dataToEncrypt = {
    endpoint: `"/authentication/api/auth/updateUserRoleDetails"`,
    payload: data,
    type: "put",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: authHeader() }
    );
  } catch (error) {
    return [];
  }
};

const blockedUser = (id) => {
  const dataToEncrypt = {
    endpoint: `/authentication/api/auth/blockUser/${id}`,
    payload: null,
    type: "put",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: authHeader() }
    );
  } catch (error) {
    return [];
  }
};

const saveEmployee = (data) => {
  const dataToEncrypt = {
    endpoint: `/contact/api/employeeDetails/save`,
    payload: data,
    type: "post",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: authHeader() }
    );
  } catch (error) {
    return [];
  }
};

const bulkUserUpload = (data, id, companyId) => {
  const dataToEncrypt = {
    endpoint: `/authentication/api/user/signup`,
    payload: data,
    type: "post",
    auth: null,
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);

  if (id === 1) {
    try {
      return axios.post(
        `/decrypt/multipart?endpoint=/authentication/api/auth/upload-csv/2`,
        data,

        { headers: initAuth() }
      );
    } catch (error) {
      return [];
    }
  } else if (id === 2) {
    try {
      return axios.post(
        `/decrypt/multipart?endpoint=/authentication/api/auth/upload-csv/3/${companyId}`,
        data,

        { headers: initAuth() }
      );
    } catch (error) {
      return [];
    }
  }
};

const bulkUserUploadEmployee = (data, id, companyId) => {
  const dataToEncrypt = {
    endpoint: `/authentication/api/user/signup`,
    payload: data,
    type: "post",
    auth: null,
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);

  if (id === 1) {
    try {
      return axios.post(
        `/decrypt/multipart?endpoint=/authentication/api/auth/upload-csv/4/${companyId}`,
        data,

        { headers: initAuth() }
      );
    } catch (error) {
      return [];
    }
  } else if (id === 2) {
    try {
      return axios.post(
        `/decrypt/multipart?endpoint=/authentication/api/auth/upload-csv/3/${companyId}`,
        data,

        { headers: initAuth() }
      );
    } catch (error) {
      return [];
    }
  }
};

// Roles & permission

const saveRoles = (data) => {
  const dataToEncrypt = {
    endpoint: `/core/api/roleMaster/save`,
    payload: data,
    type: "post",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: authHeader() }
    );
  } catch (error) {
    return [];
  }
};

// Roles & permission

const getRoles = (data) => {
  const dataToEncrypt = {
    endpoint: `/core/api/roleMaster/getAll`,
    payload: data,
    type: "get",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log(encryptedData);
  try {
    return axios.post(
      `/decrypt`,
      dataToEncrypt,

      { headers: authHeader() }
    );
  } catch (error) {
    return [];
  }
};

const editRoles = async (data) => {
  const dataToEncrypt = {
    endpoint: `/core/api/roleMaster/update`, // Ensure this is correct
    payload: data,
    type: "put",
    auth: authHeader(),
  };

  const encryptedData = encryption.encryptData(dataToEncrypt);
  console.log("Encrypted Data: ", encryptedData);

  try {
    const response = await axios.post(`/decrypt`, dataToEncrypt, {
      headers: authHeader(),
    });
    console.log("Edit Role API Response:", response.data); // Debugging
    return response.data;
  } catch (error) {
    console.error("Error updating role:", error);
    return null;
  }
};


const UserServices = {
  getCustomer,
  getInvoice,
  createInvoice,
  createCustomer,
  getUserDetails,
  updateUserDetails,
  bulkUserUpload,
  callAutomation,
  getEmployeeDetails,
  saveEmployee,
  updateEmployee,
  bulkUserUploadEmployee,
  updateUserStatus,
  updateUserRoles,
  getEmployeeByUserId,
  generateRefreshToken,
  getEmployeeDetailsActive,
  saveRoles,
  getRoles,
  editRoles,
  blockedUser
};

export default UserServices;
