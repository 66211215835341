import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import OrderServices from '../../../services/orderServices';
import MasterServices from '../../../services/coreServices';
import dayjs from 'dayjs';

export default function DoughnutChartDemo({ startDate, endDate }) {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const [category, setCategory] = useState([])

    const [serviceData, setServiceData] = useState([])


    useEffect(() => {

        if (startDate && endDate) {
            const startsDate = startDate.format("YYYY-MM-DD");
            const endsDate = endDate.add(1, "day").format("YYYY-MM-DD");
            OrderServices.getAllServiceForDashbaord(startsDate, endsDate)
                .then((res) => {
                    if (Array.isArray(res.data)) {
                        setServiceData(res.data);
                    }
                })
                .catch((err) =>  console.log(err))
        }
        else {
            const startDate = dayjs().subtract(3, 'month').format("YYYY-MM-DD");
            const endDate = dayjs().add(1, "day").format("YYYY-MM-DD");
            OrderServices.getAllServiceForDashbaord(startDate, endDate)
                .then((res) => {
                    if (Array.isArray(res.data)) {
                        setServiceData(res.data);
                    }
                })
                .catch((err) =>  console.log(err))
        }


        MasterServices.getAllProductCatagory()
            .then((res) => {
                if (Array.isArray(res.data)) {
                    setCategory(res.data);
                }
            })
            .catch((err) =>  console.log(err))
    }, [startDate, endDate]);

    const getCategory = (id) => {
        console.log(id)
        if (category.length > 0) {
            console.log(category)
            const temp = category.find((val) => val.id === id)
            console.log(temp)
            if (temp) {
                return temp.productName;
            }
        }
        else {
            return null;
        }
    }

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);



        const categoryTotals = serviceData.reduce((acc, item) => {
            acc[item.productMasterId?.id] = (acc[item.productMasterId?.id] || 0) + item.productCount;
            return acc;
        }, {});

        const topCategories = Object.entries(categoryTotals)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 5);

        const labels = topCategories.map(([categoryId]) => `${getCategory(parseInt(categoryId))}`);
        const dataValues = topCategories.map(([, productCount]) => productCount);
        const totalProductCount = dataValues.reduce((sum, value) => sum + value, 0);
        const data = {
            labels: labels,
            datasets: [
                {
                    data: dataValues, // Use totalProductCount for values
                    backgroundColor: [
                        "#004EEC",
                        "#2870FF",
                        "#84ADFF",
                        "#D0DFFF"
                    ],
                    // hoverBackgroundColor: [
                    //     documentStyle.getPropertyValue('--blue-400'),
                    //     documentStyle.getPropertyValue('--yellow-400'),
                    //     documentStyle.getPropertyValue('--green-400'),
                    //     documentStyle.getPropertyValue('--green-400')
                    // ]
                }
            ]
        };

        const options = {
            cutout: '60%',
            plugins: {
                title: {
                    display: true,
                    text: `Total Product Count`, 
                    font: {
                        size: 16
                    },
                    padding: {
                        top: 10,
                        bottom: 20
                    }
                },
                legend: {
                    position: 'bottom'
                }
            },
        };

        setChartData(data);
        setChartOptions(options);
    }, [serviceData, category]);

    return (
        <div className="card flex justify-content-center">
            <Chart type="doughnut" data={chartData} options={chartOptions} className="w-full" />
        </div>
    );
}
