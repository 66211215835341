import React, { useContext, useEffect } from 'react'
import { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from "primereact/radiobutton";
import { Message } from 'primereact/message'
import { useForm, Controller } from "react-hook-form";
import { classNames } from 'primereact/utils';
import ShiftsServices from '../../services/demoServices';
import { Dropdown } from 'primereact/dropdown';
import { Sidebar } from 'primereact/sidebar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Checkbox } from 'primereact/checkbox';
import { Avatar } from 'primereact/avatar';
import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';
import errorMessages, { globalMessages } from '../../utils/errorMessages';
import { InputMask } from 'primereact/inputmask';
import CreditCardServices from '../../services/strapiService';
import UserServices from '../../services/userService';
import templateCsv from "../../Assets/users.csv"


import { ProgressBar } from 'primereact/progressbar';

import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import authContext from '../../common/authContext';
import companyMasterService from '../../services/companyMasterService';

const AssignCompanyAccountManager = ({ visible, setVisible, data}) => {

    const { permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh } = useContext(authContext);

    const [value, setValue] = useState('');
    const [ingredient, setIngredient] = useState('');
    const toast = useRef(null);
    const [upload, setUpload] = useState('')
    const [isSuccessful, setIsSuccessful] = useState(false)
    const [checked, setChecked] = useState(true)
    const [isEqualAddress, setIsEqualAddress] = useState(true)
    const [allCustomer, setAllCustomer] = useState([])



    const countries = [
        { name: 'United States', code: 'US', dialCode: '+1' },
    ];
    const [passwordType, setPasswordType] = useState("password");
    const [passwordType1, setPasswordType1] = useState("password");

    const [password, setPassword] = useState("")
    const [confirmedPassword, setConfirmPassword] = useState("")
    const [email, setEmail] = useState("")
    const [cityErr, setcityErr] = useState('')

    const toastCenter = useRef(null);

    const [selectedSkills, setSelectedSkills] = useState(null);
    const navigate = useNavigate()


    const [firstName, setFirstName] = useState("")

    const [lastName, setLastName] = useState("")

    const [website, setWebsite] = useState("")

    const [company, setCompany] = useState("")

    const [phone, setPhone] = useState("")

    const [address, setAddress] = useState("")

    const [city, setCity] = useState("")

    const [state, setState] = useState("")

    const [zip, setZip] = useState("")

    const [emailErr, setEmailErr] = useState("")

    const [passwordErr, setPasswordErr] = useState("")

    const [confirmPasswordErr, setConfirmPasswordErr] = useState("")

    const [firstNameErr, setFirstNameErr] = useState("")

    const [lastNameErr, setLastNameErr] = useState("")

    const [phoneErr, setPhoneErr] = useState("")

    const [selectedCountry, setSelectedCountry] = useState(null);

    const [roleId, setRoleId] = useState(null)

    const [isShowContent2, setIsShowContent2] = useState(false)

    const [isShowContent3, setIsShowContent3] = useState(false)

    const [globalErr, setGlobalErr] = useState("")

    const [isFocused, setIsFocused] = useState(false);

    const [companyId, setCompanyId] = useState(null)

    const [isDisabled, setIsDisabled] = useState(false)
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const [isContentVisible, setIsContentVisible] = useState(true);


    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);

    const [allAccountManager, setAllAccountManager] = useState([])

    const [accountManager, setAccountManager] = useState([])

    useEffect(() => {
        companyMasterService.getAllCompany()
            .then((res) => {
                console.log(res)
                let data = res.data
                setAllAccountManager(res.data)

            })
            .catch((err) => {
                console.log(err)
            })
    }, [])



    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleClick = () => {
        document.getElementById('fileInput').click();
    };



    const dialogView = () => setVisible(false)
    const cancelView = () => {
        setVisible(false)
        setFirstName("")
        setLastName("")
        setEmail("")
        setIngredient("")
        setPhone("")
        setPassword("")
        setConfirmPassword("")
        setFirstNameErr("")
        setLastNameErr("")
        setPasswordErr("")
        setConfirmPasswordErr("")
        setPhoneErr("")
        setcityErr("")
        setEmailErr("")
        setAccountManager(null)
    }





    let newObject = window.localStorage.getItem("user");
    let updatedUser = Object.assign({}, JSON.parse(newObject));



    function checkIdExists(arr, idToCheck) {
        const result = arr.find(item => item.id === idToCheck);
        return result ? result.id : null;
    }







    useEffect(() => {
        setSelectedCountry({ name: 'United States', code: 'US', dialCode: '+1' })

        if (updatedUser) {
            if (updatedUser.companyRoles !== undefined) {
                if (updatedUser.companyRoles.length > 0) {
                    if (updatedUser.companyRoles[0].roles.length > 0) {


                        const id = checkIdExists(updatedUser.companyRoles[0].roles, 2);
                        setRoleId(id)
                        console.log(id);

                        setCompanyId(updatedUser.companyRoles[0].companyId)
                    }
                }
            }

        }

    }, [])





    const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none">
    <circle cx="9.79519" cy="2.00625" r="2.00625" fill="#A3D5C9"/>
<circle cx="7.67096" cy="6.13675" r="1.06213" fill="#A3D5C9"/>
<circle cx="1.23915" cy="11.5064" r="1.23915" fill="#A3D5C9"/>
<circle cx="15.2829" cy="12.4506" r="1.47518" fill="#A3D5C9"/>
<circle cx="19.0004" cy="8.26101" r="1.06213" fill="#A3D5C9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6886 0.650298C15.7808 1.14561 15.3314 1.88426 15.3314 2.88125C15.3314 3.25051 15.4132 3.69897 15.5172 3.90007C15.6192 4.09751 15.6675 4.29412 15.6244 4.33672C15.5812 4.37932 14.4255 5.56549 13.0562 6.97258L10.5663 9.5309L10.2051 9.33783C9.72642 9.08209 8.5438 9.08256 8.0643 9.33889L7.70094 9.53302L6.31214 8.14057L4.92323 6.74811L5.10155 6.40316C5.40803 5.8106 5.4491 5.28143 5.23785 4.6484C4.87201 3.5524 4.02785 2.93907 2.885 2.93907C1.62401 2.93907 0.628439 3.83469 0.499331 5.08529C0.309446 6.92561 2.08686 8.27074 3.82911 7.60537L4.36643 7.40014L5.74319 8.76298L7.11995 10.1258L6.91815 10.5489C6.61756 11.1792 6.65663 12.0617 7.01421 12.7184C8.17748 14.8542 11.3541 14.1761 11.5336 11.7535C11.5703 11.2579 11.5252 10.9367 11.3712 10.5952L11.1581 10.1225L13.751 7.52949L16.3439 4.93659L16.8074 5.13037C17.5107 5.42411 18.5064 5.31601 19.138 4.87711C20.5128 3.92202 20.5099 1.83623 19.1323 0.87901C18.7142 0.58834 18.4875 0.520836 17.8341 0.49204C17.2702 0.467257 16.9408 0.512693 16.6886 0.650298Z" fill="white"/>
</svg>
 `;



    console.log(accountManager)




    const handleSubmit = (e) => {
        e.preventDefault()
        setIsDisabled(true)


        if (data) {
           
            const actualdata = {
                "companyId": accountManager,
                "accountManagerId": data ? data.id : null
            }
            setIsTabRefresh(true)
            companyMasterService.saveCompanyAccountManager(actualdata)
            .then((res)=>{
                console.log(res)
                setIsTabRefresh(false)
                setAccountManager(null)
                if (toast.current !== undefined) {
                    toast.current.show({
                        severity: 'success', summary: 'Companies assigned successfully', life: 3000

                    })
                }

                setTimeout(() => {
                    setVisible(false)
                    // window.location.reload(false)
                }, 3000);
            })
            .catch((err)=>{
                console.log(err)
                setIsTabRefresh(false)
                if (toast.current !== undefined) {
                    toast.current.show({
                        severity: 'warn', summary: 'Warning',

                        content: (props) => (
                            <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
                                <div className="flex align-items-center gap-2">
                                    <Avatar shape="circle" style={{ backgroundColor: '#0047FF', color: '#ffffff' }} >
                                        <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                                    </Avatar>
                                    <span className="font-bold text-900">vState Filings</span>
                                </div>
                                <div className="font-medium text-lg my-3 text-900">{errorMessages.somethingWrong}</div>
                            </div>
                        ),
                        life: 3000
                    });

                }
            })
            console.log(actualdata)
        }
    }















    const customHeader = (
        <div className="flex align-items-center gap-2">
            <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" />
            <span className="font-bold">Amy Elsner</span>
        </div>
    );



    return (
        <div>
            <Toast ref={toast} />
            <Toast ref={toastCenter} position='center' />
            <Sidebar position='right' header={customHeader} visible={visible} className='dialog-box' onHide={cancelView}  >
                <div className='grid custom-sidebar-header'>
                    <div className='grid col-11 px-4 pt-4 pb-0'>
                        <div className='col-12 sidebar-header-text1 pb-0'>
                            Assign Company 
                        </div>

                    </div>
                    <div className='col-1 flex justify-content-end pt-4 pb-0'>
                        <button onClick={cancelView}><i className='pi pi-times'></i></button>
                    </div>
                    <div className='col-12 sidebar-header-text2 px-4'>
                        Fill in the below details to assign companies.
                    </div>
                </div>





                <form id='myform' className='form-spacing mt-4' onSubmit={handleSubmit}>

                    <Toast ref={toast} />



                    <div className=' input-sidebar-card mt-4'>
                        <div className='px-6 pt-4'>
                            <div className='flex' style={{ justifyContent: "space-between" }}>
                                <div className='font-fam-for-all'>
                                    <span><i className='pi pi-address-book'></i></span>

                                    Company Details

                                </div>
                            </div>
                        </div>

                        <div className='form-content-signup pb-5'>
                            <div className='grid gap-3 justify-content-center mt-2'>
                                <div className='col-5'>
                                    <div className=" justify-content-center dialog-form-field-space">
                                        <div className="field">
                                            <span className=" ">
                                                <div class="relative">
                                                    <MultiSelect
                                                        onChange={(e) => setAccountManager(e.target.value)}
                                                        options={allAccountManager}
                                                        value={accountManager}
                                                        filter
                                                        optionLabel='companyName'
                                                        optionValue='id'
                                                        style={{ borderRadius: '6px', width: "100%" }}
                                                        className=" font-fam-for-all"
                                                    />

                                                    <label
                                                        htmlFor="floating_outlined900"
                                                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                    >
                                                        Company<span className="form-field-mandatory"></span>
                                                    </label>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-5'>

                                </div>
                            </div>
                           
                        </div>

                    </div>



                    <div className='flex justify-content-end dialog-form-md-group-Btn my-5'>
                        <Button type="button" className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3" label='Cancel' style={{ height: "40px" }} onClick={cancelView} ></Button>
                        <Button type='submit' className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Submit' style={{ height: "40px" }}  ></Button>
                    </div>
                </form>
            </Sidebar>
        </div>
    )
}

export default AssignCompanyAccountManager