import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useContext, useEffect, useState } from 'react'
import companyMasterService from '../../services/companyMasterService'
import authContext from '../../common/authContext'
import EditCompanyMember from './EditCompanyComponents/EditKeyPersonnelType'
import useTableData from '../../redux/Reducers/useTableData'
import { Button } from 'primereact/button'
import { Skeleton } from 'primereact/skeleton'

const OrganizerContact = ({ data }) => {
  const { 
    setFeedbackData, 
    permissions, 
    globalCompany, 
    setGlobalCompany, 
    isLoading, 
    setIsLoading, 
    isTabRefresh, 
    setIsTabRefresh 
  } = useContext(authContext);

  const { 
    data: fetchedStateData, 
    isLoading: stateLoading, 
    isError: isStateError, 
    error: stateError, 
    refetch: reftechStateData 
  } = useTableData("state");

  // Helper to get state full description
  const getState = (id) => {
    if (fetchedStateData && Array.isArray(fetchedStateData)) {
      const stateObj = fetchedStateData.find((val) => val.id === id);
      return stateObj ? stateObj.stateFullDesc : "";
    }
    return "";
  };

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const hasPermission = (permission) => permissions.includes(permission);

  const [visible, setVisible] = useState(false);
  const [keyPersonalData, setKeyPersonnelData] = useState(null);

  useEffect(() => {
    if (data) {
      setLoading(true);
      companyMasterService
        .getAllKeyPersonnelByCompanyId(data.id)
        .then((res) => {
          
          if (res.data !== "" && Array.isArray(res.data)) {
            // Filter key personnel of type 1
            const temp = res.data.filter((val) => val.keyPersonnelTypeId === 1);
            setProducts(temp);
          }
          setLoading(false);
        })
        .catch((err) => {
          
          setLoading(false);
        });
    }
  }, [data, isTabRefresh]);

  const handleEdit = (rowdata) => {
    
    setVisible(true);
    setKeyPersonnelData(rowdata);
  };

  // Render a skeleton placeholder if loading, else render the contact info
  const contactBody = (rowdata) => {
    return loading ? (
      <Skeleton width="100%" height="2rem" />
    ) : (
      <div>{`${rowdata.emailId}, ${rowdata.contactNo}`}</div>
    );
  };

  const addressBody = (rowdata) => {
    return loading ? (
      <Skeleton width="100%" height="2rem" />
    ) : (
      <div>
        {[
          rowdata?.addressMasterId?.addressLine1,
          rowdata?.addressMasterId?.addressLine2,
          rowdata?.addressMasterId?.city,
          getState(rowdata?.addressMasterId?.stateId),
          rowdata?.addressMasterId?.postalCode
        ]
          .filter(Boolean)
          .join(", ")}
      </div>
    );
  };

  const iconBodyTemplate = (rowdata) => {
    return loading ? (
      <Skeleton width="100%" height="2rem" />
    ) : (
      <>
        <Button
          data-toggle="tooltip"
          data-placement="bottom"
          title="Edit"
          onClick={() => handleEdit(rowdata)}
          style={{
            border: "none",
            height: "40px",
            backgroundColor: "transparent",
            color: "blue",
          }}
          className="border-none h-10 bg-transparent text-#175CD3 hover:bg-#175CD3 hover:text-blue-800 transition duration-200"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clipPath="url(#clip0_1308_27815)">
              <path
                d="M9.16675 3.33332H3.33341C2.89139 3.33332 2.46746 3.50891 2.1549 3.82147C1.84234 4.13403 1.66675 4.55796 1.66675 4.99999V16.6667C1.66675 17.1087 1.84234 17.5326 2.1549 17.8452C2.46746 18.1577 2.89139 18.3333 3.33341 18.3333H15.0001C15.4421 18.3333 15.866 18.1577 16.1786 17.8452C16.4912 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333M15.4167 2.08332C15.7483 1.7518 16.1979 1.56555 16.6667 1.56555C17.1356 1.56555 17.5852 1.7518 17.9167 2.08332C18.2483 2.41484 18.4345 2.86448 18.4345 3.33332C18.4345 3.80216 18.2483 4.2518 17.9167 4.58332L10.0001 12.5L6.66675 13.3333L7.50008 9.99999L15.4167 2.08332Z"
                stroke="#004EEC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1308_27815">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span
            style={{ marginLeft: "5px" }}
            className="text-#175CD3 font-inter text-sm font-medium leading-5"
          >
            Edit
          </span>
        </Button>
      </>
    );
  };

  // Create dummy rows for loading state
  const dummyRows = Array.from({ length: 3 }, (_, index) => ({ id: index }));

  return (
    <div>
      <DataTable
        value={loading ? dummyRows : products}
        scrollable
        dataKey="keyPersonnelName"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        className="font-fam-for-all"
        scrollHeight="flex"
        scrollWidth="100%"
        globalFilterFields={["djjddjjd"]}
      >
        <Column
          header="Name"
          className="font-fam-for-all datatable-lg-col webapp-sm-size"
          body={(rowData) =>
            loading ? (
              <Skeleton width="100%" height="2rem" />
            ) : (
              rowData.keyPersonnelName
            )
          }
        ></Column>
        <Column
          header="Title"
          className="font-fam-for-all datatable-lg-col webapp-sm-size"
          body={(rowData) =>
            loading ? (
              <Skeleton width="100%" height="2rem" />
            ) : (
              rowData.keyPersonnelTitle
            )
          }
        ></Column>
        <Column
          header="Contact Info"
          body={contactBody}
          className="font-fam-for-all datatable-lg-col webapp-sm-size"
        ></Column>
        <Column
          header="Address"
          body={addressBody}
          className="font-fam-for-all datatable-lg-col webapp-sm-size"
        ></Column>
        {hasPermission("AllowViewAndUpdateCompanies") && (
          <Column
            body={iconBodyTemplate}
            header="Action"
            className="font-fam-for-all datatable-lg-col webapp-sm-size"
          ></Column>
        )}
      </DataTable>
      {visible && (
        <EditCompanyMember
          visible={visible}
          setVisible={setVisible}
          data={keyPersonalData}
        />
      )}
    </div>
  );
};

export default OrganizerContact;
