import React, { useRef } from "react";
import DocumentPic from "../../../Assets/Images/dcument retrival.png";
import RegisteredBanner from "../RegistredAgentService/RegisteredBanner.js";
import DocumentRetrivalSec from "../AllServices/DocumentRetrival/DocumentRetrivalSec.js";
import DocumentRetrivalThird from "../AllServices/DocumentRetrival/DocumentRetrivalThird.js";
import OurTrustedSection from "../CommonSection/OurTrustedSection.js";
import bannerimg from "../../../Assets/Images/docbanner.png";
import CommonRegister from "../CommonSection/CommonRegister.js";
import DocumentFaq from "../AllServices/DocumentRetrival/DocumentFaq.js";
import { Container } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle.js";
import ServiceForm from "../CommonSection/ServiceForm.js";

const DocumentRetrievalMain = () => {
  const registeredTabRef = useRef(null);

  const handleScroll = () => {
    if (registeredTabRef.current) {
      registeredTabRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div>
      <RegisteredBanner
        title={"Quick access to essential  "}
        highlightText={"business documents"}
        subtitle={
          "Get the important legal documents your business needs, fast."
        }
        buttonLabel={"Learn More"}
        onButtonClick={handleScroll}
        bannerImage={bannerimg}
      />
      {/* <OurTrustedSection /> */}
      <DocumentRetrivalSec />
      <div ref={registeredTabRef} id="registered-tab-section">
        <DocumentRetrivalThird />
      </div>
      <div className="entity-formation">
        <Container>
          <div className="text-center mt-5 mb-5">
            <CommonHeadingTitle
              heading={"Need important"}
              blueheading={"business documents?"}
            />
          </div>
          <ServiceForm />
        </Container>
      </div>
      <CommonRegister
        title={"Certified documents, delivered fast and secure"}
        buttonLabel={"Start Your Business"}
        buttonLink={"/signup"}
      />
      <DocumentFaq />
    </div>
  );
};

export default DocumentRetrievalMain;
