import axios from "axios";
import authHeader, { initAuth } from "./authHeader";
import encryption from "./CryptoClass";
import propertiesApp from "../utils/propertiesApp";



const getAllProduct =()=>{
   
    const dataToEncrypt = {
        endpoint: `/order/api/productListing/getAll`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}





const ProductSevice = {
  getAllProduct,
}


export default ProductSevice;