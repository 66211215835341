// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.common-card-component {
  margin: 5% 0;
}

.common-card-component .grey-border {
  margin-top: 10px;
  border-bottom: 1px solid #f0efef;
}

.common-card-component .order-contact-card-text1 {
    margin-bottom: 10;
}

.common-card-component .card-title {
  background: var(--Grey-50, #e7eaec);
  color: var(--Gray-900, #101828);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  padding: 20px;
  text-align: start;
}

.common-card-component .main-card-class {
  padding: 0px 0px 20px 0px;
  cursor: pointer;
  padding: 20px;
  border-radius: 8px;
  background: var(--White, #fff);
  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
    border: 1px solid #ccc;
}

`, "",{"version":3,"sources":["webpack://./src/components/CommonCardComponent/CommonCardComponent.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gCAAgC;AAClC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;EACE,mCAAmC;EACnC,+BAA+B;EAC/B,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,eAAe;EACf,aAAa;EACb,kBAAkB;EAClB,8BAA8B;EAC9B;0CACwC;IACtC,sBAAsB;AAC1B","sourcesContent":[".common-card-component {\n  margin: 5% 0;\n}\n\n.common-card-component .grey-border {\n  margin-top: 10px;\n  border-bottom: 1px solid #f0efef;\n}\n\n.common-card-component .order-contact-card-text1 {\n    margin-bottom: 10;\n}\n\n.common-card-component .card-title {\n  background: var(--Grey-50, #e7eaec);\n  color: var(--Gray-900, #101828);\n  font-family: Inter;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 500;\n  padding: 20px;\n  text-align: start;\n}\n\n.common-card-component .main-card-class {\n  padding: 0px 0px 20px 0px;\n  cursor: pointer;\n  padding: 20px;\n  border-radius: 8px;\n  background: var(--White, #fff);\n  box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),\n    0px 1px 2px 0px rgba(16, 24, 40, 0.06);\n    border: 1px solid #ccc;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
