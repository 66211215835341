import React from 'react';
import ComplianceItem from './ComplianceItem';
import ComplianceImage from './ComplianceImage';
const complianceData={
  "sectionTitle": "Why Compliance Matters?",
  "sectionSubtitle": "Compliance Services are essential for your business.",
  "items": [
    {
      "title": "Avoid Penalties",
      "description": "By staying compliant, your business avoids fines, penalties, or suspension due to missed deadlines."
    },
    {
      "title": "Legal Protection",
      "description": "Compliance ensures your business continues to operate within legal requirements, safeguarding it from potential legal action."
    },
    {
      "title": "Increased Credibility",
      "description": "Maintaining compliance boosts your business's credibility with regulators, clients, and partners to ensure smooth operations."
    },
    {
      "title": "Streamlined Processes",
      "description": "Professional compliance services simplify required filings, reducing the burden on your team and saving you time."
    }
  ],
  "images": {
    "backgroundImage": "https://cdn.builder.io/api/v1/image/assets/TEMP/534bb250cb442ca035f407b04dc18bd8538e5df48f97ecda01286a617bf90fd4?apiKey=136b58d08a644862944c0e5f22adbbe0&",
    "iconImage": "https://cdn.builder.io/api/v1/image/assets/TEMP/2517a6129f7ab66094c10670002e3c726e87b73dba9f0efd07fceaf970443933?apiKey=136b58d08a644862944c0e5f22adbbe0&"
  }
}


function ComplianceSection() {
  return (
    <section className="flex flex-col justify-center pt-16 pb-20 w-full max-md:max-w-full">
      <h2 className="text-5xl font-semibold text-center text-blue-700 max-md:max-w-full">
        {complianceData.sectionTitle}
      </h2>
      <p className="text-center text-blue-500 max-md:max-w-full">
        {complianceData.sectionSubtitle}
      </p>
      <div className="flex flex-wrap gap-10 justify-center pt-5 mt-10 w-full max-md:max-w-full">
        <div className="flex flex-col justify-center p-5 my-auto min-w-[240px] text-slate-800 w-[511px] max-md:max-w-full">
          {complianceData.items.map((item, index) => (
            <ComplianceItem
              key={index}
              title={item.title}
              description={item.description}
              isLast={index === complianceData.items.length - 1}
            />
          ))}
        </div>
        <ComplianceImage images={complianceData.images} />
      </div>
    </section>
  );
}

export default ComplianceSection;
