import React from 'react';

const Footer = () => {
  return (
    <footer className="flex flex-col justify-center items-center self-stretch px-16 py-16 w-full  max-md:px-5 max-md:max-w-full" style={{background:"#1B2032"}}>
      <div className="justify-center px-0.5 max-w-full w-[1140px]">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[36%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow justify-center self-stretch px-2.5 py-2 text-sm font-light leading-7 text-white text-opacity-70 max-md:mt-10">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c21363d3d545cfc471d5f48394b421bd6b0aee2e219e17e9417a89bf538c3079?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="max-w-full aspect-[4] w-[258px]" alt="vState Filings logo" />
              <p className="mt-5">
                We effectively manage your business formation, registered agent, provide legal and advisory assistance. vState Filings sets up your company in a few steps.
              </p>
              <div className="flex gap-5 mt-5 whitespace-nowrap">
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/36a91ec308c14bd2576e0acf5e5463fc5c137a2182ee41481767782f04fe8f0b?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="shrink-0 my-auto  aspect-square" alt="" />
                <div>support@vstatefilings.com</div>
              </div>
              <div className="flex gap-5 mt-1.5">
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e5fa235e3e1b808ce197b222fc9164fef9dae4e4eb1c5499b0960273aaeb173b?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="shrink-0 my-auto  aspect-square" alt="" />
                <div className="underline">
                  <a href="tel:866-638-3309" className="underline" target="_blank" rel="noopener noreferrer">
                    (866) 638-3309
                  </a> | 
                  <a href="tel:718-569-2703" className="underline" target="_blank" rel="noopener noreferrer">
                    (718) 569-2703
                  </a>
                </div>
              </div>
              <div className="flex gap-5">
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/61b22b1897a242a6b315abfaf8701f9dd6ba3c556463a94af03cf7630f6c138c?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="shrink-0 my-auto  aspect-square" alt="" />
                <address className="not-italic">301 Mill Road, Suite U5, Hewlett, NY 11557</address>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-[64%] max-md:ml-0 max-md:w-full">
            <div className="grow max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow self-stretch p-2.5 text-sm font-light leading-7 text-white text-opacity-70">
                    <h3 className="text-lg font-medium leading-6 text-white">Services</h3>
                    <nav className="mt-5">
                      <ul>
                        <li>C-Corp</li>
                        <li>PC or PLLC</li>
                        <li>LP</li>
                        <li>LLP</li>
                        <li>Conversions</li>
                        <li>Tax ID - EIN</li>
                        <li>UCC Searches & Filings</li>
                        <li>S-Corp</li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow self-stretch p-2.5">
                    <h3 className="text-lg font-medium leading-6 text-white">Disclaimer</h3>
                    <p className="mt-5 text-sm font-light leading-7 text-white text-opacity-70">
                      The information contained on this site is not and should not be construed as legal advice. vState Filings provides business registration, registered agent, Attorney and remote online services based on a variety of legal, economic, geographic and other criteria.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center self-stretch px-16 py-px w-full border-t border-solid border-white border-opacity-20 max-md:px-5 max-md:max-w-full" style={{background:"#1B2032"}}>
        <div className="flex gap-5 justify-center p-2.5 max-md:flex-wrap">
          <div className="flex gap-0 pb-px my-auto text-xs font-light leading-4 text-white text-opacity-70 max-md:flex-wrap">
            <div>© Copyright 2024 | </div>
            <div>vState Filings </div>
            <div>| </div>
            <div>Terms</div>
            <div> | All Rights Reserved</div>
          </div>
          <div className="flex gap-2.5 pl-20 max-md:flex-wrap">
            <SocialIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8ec4acb0c40f7048c6b99fa73e442b121c9cbdb1133c01d6f3f8928eec47977?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="Facebook" />
            <SocialIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/80071593701117e8d2fe7dab403696d8eddce7d2bc445189ac644df7c73af3fd?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="Twitter" />
            <SocialIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc86d398facc9332deac33816253932de09a4383ab7f3126267ee894d15058b8?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="LinkedIn" />
          </div>
        </div>
      </div>
    </footer>
  );
};

const SocialIcon = ({ src, alt }) => (
  <div className="flex justify-center items-center p-2.5 bg-blue-700 h-[33px] rounded-[50px] w-[33px]">
    <img loading="lazy" src={src} className="aspect-square w-[15px]" alt={alt} />
  </div>
);

export default Footer;