import React from 'react';

const CallToAction = () => {
  return (
    <section className="flex flex-col justify-center mt-24 py-2 max-w-full font-medium text-white capitalize bg-gray-800 rounded-lg w-[900px] max-md:mt-10 mb-4">
      <div className="flex gap-5 justify-center  pl-9 max-w-[1140px] max-md:flex-wrap max-md:px-5 max-md:max-w-full">
        <h2 className="my-auto text-2xl tracking-tighter leading-7 text-right max-md:max-w-full">
          Launch your business in just a few clicks
        </h2>
        <button className="flex flex-col justify-center px-4 py-2 text-sm leading-4 text-center bg-web-color rounded-lg border-2 border-white border-solid max-md:px-5">
          <div className="flex gap-1 justify-center py-px">
            <div>Get Started</div>
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/305fdea5901df6eb457a02477216dc22e8f8240bc948000196dcc7a4b003f9cb?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="shrink-0 self-start w-3.5 aspect-square" alt="" />
          </div>
        </button>
      </div>
    </section>
  );
};

export default CallToAction;