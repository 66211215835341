const validationConfig = {
    // LLC: {
    //     "Alabama":/(?<=\s)LLC$|(?<=\s)L\.L\.C\.$|(?<=\s)Limited Liability Company$/i,
    //     "Alaska": /(LLC|L\.L\.C\.|Ltd\. Liability Co\.|Ltd\. Liability Company|Limited Liability Co\.|Limited Liability Company)$/i,
    //     "Arizona": /(LC|L\.C\.|Limited Company|Limited Liability Company|LLC|L\.L\.C\.)$/i,
    //     "Arkansas": /^(?=.*\b(LLC|L\.L\.C\.|LC|L\.C\.|Limited Liability Company|Limited Company|Ltd\. Liability Company|Ltd\. Co\.|Ltd\. Liability Co\.|Professional Limited Liability Company|Professional Limited Company|P\.L\.L\.C\.|P\.L\.C\.|PLLC|PLC|Professional Ltd\. Liability Company|Professional Ltd\. Co\.|Professional Ltd\. Liability Co\.)\b)(?!.*\b(?:deceased|member)\b).*$/i,
    //     "California":/(LLC|L\.L\.C\.|LC|L\.C\.|Limited Liability Company|Limited Company|Ltd\. Liability Company|Ltd\. Company|Ltd\. Liability Co\.|Ltd\. Co\.|Professional Limited Liability Company|Professional Limited Company|P\.L\.L\.C\.|P\.L\.C\.|PLLC|PLC|Professional Ltd\. Liability Company|Professional Ltd\. Co\.|Professional Ltd\. Liability Co\.)$/i,
    //     "Colorado": /(Limited Liability Company|Ltd\. Liability Company|Limited Liability Co\.|Ltd\. Liability Co\.|Limited|L\.L\.C\.|LLC|Ltd\.)$/i,
    //     "Connecticut": /(Limited Liability Company|Limited Liability Law Company|LLC|L\.L\.C\.|LLLC|L\.L\.L\.C\.)$/i,

    //     // Not provided
    //     "Delaware": /(LLC|Limited Liability Company|L\.L\.C\.)$/i,
    //     "Florida": /(LLC|Limited Liability Company|L\.L\.C\.)$/i,

    //     "Georgia": /(Limited Liability Company|LLC|LLC\.|L\.L\.C\.|L\.C\.|LC|Ltd\. Liability Company|Ltd Liability Company|Ltd\. Company|Ltd Company|Ltd\. Liability Co\.|Ltd Liability Co|Limited Company|Limited Co\.|Limited Co|Ltd\. Co\.|Ltd Co|L\.L\.C)$/i,
    //     "Hawaii": /(Limited Liability Company|Limited Liability Law Company|LLC|L\.L\.C\.|LLLC|L\.L\.L\.C\.)$/i,
    //     "Idaho": /(Limited Liability Company|Limited Company|LLC|L\.L\.C\.|L\.C\.|Professional Limited Liability Company|P\.L\.L\.C\.|PLLC)$/i,
    //     "Illinois": /^(?!(.*\b(Corporation|Corp\.|Incorporated|Inc\.|Ltd\.|Co\.|Limited Partnership|L\.P\.)\b)).*(LLC|Limited Liability Company|L\.L\.C\.)$/i,
    //     "Indiana": /(Limited Liability Company|LLC|L\.L\.C\.|L L C)$/i,
    //     "Iowa": /(Ltd\.? Liability Company|Limited Liability Company|Limited Company|LLC|L\.L\.C\.|L\.C\.|LC)$/i,
    //     "Kansas": /(LLC|Limited Liability Company|L\.L\.C\.|L\.C\.|LC)$/i,
    //     "Kentucky": /(LLC|L\.L\.C\.|LC|L\.C\.|Limited Liability Company|Limited Liability Co\.|Ltd\. Liability Company|Ltd\. Liability Co\.|Limited Company|Ltd\. Company|Limited Co\.|Ltd\. Co\.)$/i,
    //     "Louisiana": /(Limited Liability Company|L\.L\.C\.|LLC|L\.C\.|LC)$/i,
        
    //     // Not provided
    //     "Maine": /(LLC|Limited Liability Company|L\.L\.C\.)$/i,

    //     "Maryland": /(Limited Liability Company|L\.L\.C\.|LLC|L\.C\.|LC)$/i,
    //     "Massachusetts": /(Limited Liability Company|Limited Company|L\.L\.C\.|L\.C\.|LLC|LC)$/i,
    //     "Michigan": /(Limited Liability Company|L\.L\.C\.|L\.C\.|L3C)$/i,
    //     "Minnesota": /(L\.? L\.? C\.?|Limited Liability Company|LLC)$/i,
    //     "Mississippi": /(Limited Liability Company|LLC|L\.L\.C\.)$/i,
    //     "Missouri": /(L C|L L C|L\. C\.|L\. L\. C\.|L\.C\.|L\.L\.C\.|LC|Limited Company|Limited Liability Co|Limited Liability Co\.|Limited Liability Company|LLC|Ltd Co|Ltd Co\.|Ltd\. Co|Ltd\. Co\.)$/i,
    //     "Montana": /(L C|L L C|L\. C\.|L\. L\. C\.|L\.C\.|L\.L\.C\.|LC|Limited Company|Limited Liability Co|Limited Liability Co\.|Limited Liability Company|LLC|Ltd Co|Ltd Co\.|Ltd\. Co|Ltd\. Co\.)$/i,

    //     // Not provided
    //     "Nebraska": /(LLC|Limited Liability Company|L\.L\.C\.)$/i,


    //     "Nevada": /(Limited Liability Company|Limited Company|LLC|L\.L\.C\.|LC|L\.C\.|LTD\.?|Limited|Limited Co|Limited Co\.|L\.L\.C\.|Limited-Liability Company)$/i,
    //     "New Hampshire": /(Limited Liability Company|L\.L\.C\.|LLC)$/i,
    //     "New Jersey": /(?!.*(Corp\.|Ltd\.|LLC|Etc\.))/,
    //     "New Mexico": /(Limited Liability Company|Limited Company|Limited Liability Co\.|Limited Co\.|Ltd\. Company|L\.L\.C\.|LLC|L\.C\.|LC\.|Ltd\. Co\.|LC|Ltd Co)$/i,
    //     "New York": /(Limited Liability Company|LLC|L\.L\.C\.)$/i,
    //     "North Carolina": /(Limited Liability Company|LLC|L\.L\.C\.|Ltd\. Liability Co\.|Limited Liability Co\.|Ltd\. Liability Company)$/i,
    //     "North Dakota": /(limited liability company|LLC|L\.L\.C\.)$/i,
    //     "Ohio": /(limited liability company|limited|LLC|L\.L\.C\.|LTD\.?|LTD)$/i,
    //     "Oklahoma": /(Limited Liability Company|Limited Company|LLC|LC|L\.L\.C\.|L\.C\.)$/i,
    //     "Oregon": /(LLC|Limited Liability Company)$/i,
    //     "Pennsylvania": /(Limited Liability Company|Limited Company|L\.L\.C\.|LLC|L\.C\.|LC\.|Ltd\. Co\.)$/i,
    //     "Rhode Island": /(Limited Liability Company|Limited Company|LLC|LC|Ltd Co|LTD Liability Co)$/i,
    //     "South Carolina": /(Limited Liability Company|Limited Company|LLC|LC|Ltd Co|LTD Liability Co)$/i,
    //     "South Dakota": /(PROF\.\s?L\.L\.C\.|L\.L\.C\.|PROF\.\s?LLC|LLC\.|LTD\.|P\.L\.L\.C\.|PLLC|PLC|LIMITED LIABILITY COMPANY|LLC|L\.L\.C\.|LTD\.\s?CO\.|LTD\.\s?COMPANY|LIMITED\s?CO\.|LC|L\.C\.)$/i,
    //     "Tennessee": /(Limited Liability Company|LLC)$/i,

    //      // Not provided
    //     "Texas": /(LLC|Limited Liability Company|L\.L\.C\.)$/i,


    //     "Utah": /(Limited Liability Company|Limited Company|L\.L\.C\.|LLC|L\.C\.|LC\.|Ltd\. Co\.)$/i,
    //     "Vermont": /(Limited Liability Company|Limited Company|L\.L\.C\.|LLC|L\.C\.|LC\.|Ltd\. Co\.)$/i,

    //     // Not provided
    //     "Virginia": /(LLC|Limited Liability Company|L\.L\.C\.)$/i,


    //     "Washington": /(Limited Liability Company|Limited Liability|Co\.|L\.L\.C\.|LLC)$/i,

    //      // Not provided
    //     "West Virginia": /(LLC|Limited Liability Company|L\.L\.C\.)$/i,

    //     "Wisconsin": /(L\.C\.|L\.L\.C\.|L3C|LC|Limited Company|Limited Liability Co\.|Limited Liability Company|LLC|Ltd\. Liability Co\.|Ltd\. Liability Company)$/i,
    //     "Wyoming": /(L\.C\.|L\.L\.C\.|L3C|LC|Limited Company|Limited Liability Co\.|Limited Liability Company|LLC|Ltd\. Liability Co\.|Ltd\. Liability Company)$/i,
    // },

    LLC: {
        "Alabama": /(?<=\s)(LLC|L\.L\.C\.|Limited Liability Company)$/i,
        "Alaska": /(?<=\s)(LLC|L\.L\.C\.|Ltd\. Liability Co\.|Ltd\. Liability Company|Limited Liability Co\.|Limited Liability Company)$/i,
        "Arizona": /(?<=\s)(LC|L\.C\.|Limited Company|Limited Liability Company|LLC|L\.L\.C\.)$/i,
        "Arkansas": /(?<=\s)(LLC|L\.L\.C\.|LC|L\.C\.|Limited Liability Company|Limited Company|Ltd\. Liability Company|Ltd\. Co\.|Ltd\. Liability Co\.|Professional Limited Liability Company|Professional Limited Company|P\.L\.L\.C\.|P\.L\.C\.|PLLC|PLC|Professional Ltd\. Liability Company|Professional Ltd\. Co\.|Professional Ltd\. Liability Co\.)$/i,
        "California": /(?<=\s)(LLC|L\.L\.C\.|LC|L\.C\.|Limited Liability Company|Limited Company|Ltd\. Liability Company|Ltd\. Company|Ltd\. Liability Co\.|Ltd\. Co\.|Professional Limited Liability Company|Professional Limited Company|P\.L\.L\.C\.|P\.L\.C\.|PLLC|PLC|Professional Ltd\. Liability Company|Professional Ltd\. Co\.|Professional Ltd\. Liability Co\.)$/i,
        "Colorado": /(?<=\s)(Limited Liability Company|Ltd\. Liability Company|Limited Liability Co\.|Ltd\. Liability Co\.|Limited|L\.L\.C\.|LLC|Ltd\.)$/i,
        "Connecticut": /(?<=\s)(Limited Liability Company|Limited Liability Law Company|LLC|L\.L\.C\.|LLLC|L\.L\.L\.C\.)$/i,
        "Delaware": /(?<=\s)(LLC|Limited Liability Company|L\.L\.C\.)$/i,
        "DC": /(?<=\s)(limited liability company|limited company|L\.L\.C\.|LLC|L\.C\.|LC|professional limited liability company|P\.L\.L\.C\.|PLLC)$/i,
        "Florida": /(?<=\s)(LLC|Limited Liability Company|L\.L\.C\.)$/i,
        "Georgia": /(?<=\s)(Limited Liability Company|LLC|LLC\.|L\.L\.C\.|L\.C\.|LC|Ltd\. Liability Company|Ltd Liability Company|Ltd\. Company|Ltd Company|Ltd\. Liability Co\.|Ltd Liability Co|Limited Company|Limited Co\.|Limited Co|Ltd\. Co\.|Ltd Co|L\.L\.C)$/i,
        "Hawaii": /(?<=\s)(Limited Liability Company|Limited Liability Law Company|LLC|L\.L\.C\.|LLLC|L\.L\.L\.C\.)$/i,
        "Idaho": /(?<=\s)(Limited Liability Company|Limited Company|LLC|L\.L\.C\.|L\.C\.|Professional Limited Liability Company|P\.L\.L\.C\.|PLLC)$/i,
        "Illinois": /(?<=\s)(LLC|Limited Liability Company|L\.L\.C\.)$/i,
        "Indiana": /(?<=\s)(Limited Liability Company|LLC|L\.L\.C\.|L L C)$/i,
        "Iowa": /(?<=\s)(Ltd\.? Liability Company|Limited Liability Company|Limited Company|LLC|L\.L\.C\.|L\.C\.|LC)$/i,
        "Kansas": /(?<=\s)(LLC|Limited company|Limited Liability Company|L\.L\.C\.|L\.C\.|LC|LLC|Inc)$/i,
        "Kentucky": /(?<=\s)(LLC|L\.L\.C\.|LC|L\.C\.|Limited Liability Company|Limited Liability Co\.|Ltd\. Liability Company|Ltd\. Liability Co\.|Limited Company|Ltd\. Company|Limited Co\.|Ltd\. Co\.)$/i,
        "Louisiana": /(?<=\s)(Limited Liability Company|L\.L\.C\.|LLC|L\.C\.|LC)$/i,
        "Maine": /(?<=\s)(Limited Liability Company|Limited Company|L\.L\.C\.|LLC|L\.C\.|LC)$/i,
        "Maryland": /(?<=\s)(Limited Liability Company|L\.L\.C\.|LLC|L\.C\.|LC)$/i,
        "Massachusetts": /(?<=\s)(Limited Liability Company|Limited Company|L\.L\.C\.|L\.C\.|LLC|LC)$/i,
        "Michigan": /(?<=\s)(Limited Liability Company |LLC|L\.L\.C\.|L\.C\.)$/i,
        "Minnesota": /(?<=\s)(L\.? L\.? C\.?|Limited Liability Company|LLC)$/i,
        "Mississippi": /(?<=\s)(Limited Liability Company|Professional Limited Liability Company|LLC|L\.L\.C\.)$/i,
        "Missouri": /(?<=\s)(L C|L L C|L\. C\.|L\. L\. C\.|L\.C\.|L\.L\.C\.|LC|Limited Company|Limited Liability Co|Limited Liability Co\.|Limited Liability Company|LLC|Ltd Co|Ltd Co\.|Ltd\. Co|Ltd\. Co\.)$/i,
        "Montana": /(?<=\s)(L C|L L C|L\. C\.|L\. L\. C\.|L\.C\.|L\.L\.C\.|LC|Limited Company|Limited Liability Co|Limited Liability Co\.|Limited Liability Company|LLC|Ltd Co|Ltd Co\.|Ltd\. Co|Ltd\. Co\.)$/i,
        "Nebraska": /(?<=\s)(LLC|Limited Liability Company|L\.L\.C\.)$/i,
        "Nevada": /(?<=\s)(Limited Liability Company|Limited Company|LLC|L\.L\.C\.|LC|L\.C\.|LTD\.?|Limited|Limited Co|Limited Co\.|L\.L\.C\.|Limited-Liability Company)$/i,
        "New Hampshire": /(?<=\s)(Limited Liability Company|L\.L\.C\.|LLC)$/i,
        "New Jersey": /(?<=\s)(Limited Liability Company|L\.L\.C\.|LLC)$/i,
        // "New Jersey": /(?<=\s)(?!.*(Corp\.|Ltd\.|LLC|Etc\.)).*$/i,
        "New Mexico": /(?<=\s)(Limited Liability Company|Limited Company|Limited Liability Co\.|Limited Co\.|Ltd\. Company|L\.L\.C\.|LLC|L\.C\.|LC\.|Ltd\. Co\.|LC|Ltd Co)$/i,
        "New York": /(?<=\s)(Limited Liability Company|LLC|L\.L\.C\.)$/i,
        "North Carolina": /(?<=\s)(Limited Liability Company|LLC|L\.L\.C\.|Ltd\. Liability Co\.|Limited Liability Co\.|Ltd\. Liability Company)$/i,
        "North Dakota": /(?<=\s)(limited liability company|LLC|L\.L\.C\.)$/i,
        "Ohio": /(?<=\s)(limited liability company|limited|LLC|L\.L\.C\.|LTD\.?|LTD)$/i,
        "Oklahoma": /(?<=\s)(Limited Liability Company|Limited Company|LLC|LC|L\.L\.C\.|L\.C\.)$/i,
        "Oregon": /(?<=\s)(LLC|Limited Liability Company)$/i,
        "Pennsylvania": /(?<=\s)(Limited Liability Company|Limited Company|L\.L\.C\.|LLC|L\.C\.|LC\.|Ltd\. Co\.)$/i,
        "Rhode Island": /(?<=\s)(Limited Liability Company|Limited Company|LLC|LC|Ltd Co|LTD Liability Co)$/i,
        "South Carolina": /(?<=\s)(Limited Liability Company|Limited Company|LLC|LC|Ltd Co|LTD Liability Co)$/i,
        "South Dakota": /(?<=\s)(PROF\.\s?L\.L\.C\.|L\.L\.C\.|PROF\.\s?LLC|LLC\.|LTD\.|P\.L\.L\.C\.|PLLC|PLC|LIMITED LIABILITY COMPANY|LLC|L\.L\.C\.|LTD\.\s?CO\.|LTD\.\s?COMPANY|LIMITED\s?CO\.|LC|L\.C\.)$/i,
        "Tennessee": /(?<=\s)(Limited Liability Company|LLC)$/i,
        "Texas": /(?<=\s)(LLC|Limited Liability Company|L\.L\.C\.)$/i,
        "Utah": /(?<=\s)(Limited Liability Company|Limited Company|L\.L\.C\.|LLC|L\.C\.|LC\.|Ltd\. Co\.)$/i,
        "Vermont": /(?<=\s)(Limited Liability Company|Limited Company|L\.L\.C\.|LLC|L\.C\.|LC|Ltd\. Co\.)$/i,
        "Virginia": /(?<=\s)(LLC|Limited Liability Company|Limited Company|L\.L\.C\.|L\.C\.)$/i,
        "Washington": /(?<=\s)(Limited Liability Company|Limited Liability|Co\.|L\.L\.C\.|LLC)$/i,
        "West Virginia": /(?<=\s)(LLC|Limited Liability Company|L\.L\.C\.)$/i,
        "Wisconsin": /(?<=\s)(L\.C\.|L\.L\.C\.|LC|Limited Company|Limited Liability Co\.|Limited Liability Company|LLC|Ltd\. Liability Co\.|Ltd\. Liability Company)$/i,
        "Wyoming": /(?<=\s)(L\.C\.|L\.L\.C\.|LC|Limited Company|Limited Liability Co\.|Limited Liability Company|LLC|Ltd\. Liability Co\.|Ltd\. Liability Company)$/i,
      },

      


    // CORP: {
    //     "Alabama": /(corporation|Corp\.|incorporated|Inc\.)$/i,
    //     "Alaska": /(corporation|Corp\.|company|co\.|limited|ltd\.|incorporated|inc\.)$/i,
    //     "Arizona": /(Assoc|Association|Bank|CO|Company|Corp\.|Corporation|Incorp\.|Incorporated|Limited|LTD)$/i,
    //     "Arkansas": /(Corporation|Corp\.|Company|Co\.|Incorporated|Inc\.)$/i,
    //     "California": /(corporation|Corp\.|company|co\.|incorporated|inc\.|limited|ltd\.|professional corporation|P\.C\.)$/i,
    //     "Colorado": /(corporation|incorporated|company|limited|Corp\.|inc\.|co\.|ltd\.)$/i,
    //     "Connecticut": /(corporation|Corp\.|company|co\.|incorporated|inc\.|limited|ltd\.|professional corporation|P\.C\.)$/i,

    //     // Not Provided
    //     "Delaware": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
    //     "Florida": /(Corporation|Corp\.|Incorporated|Inc\.)$/i,


    //     "Georgia": /(professional corporation|professional Corp\.|professional Corp|PC|P\.C\.|associated|professional association|PA|P\.A\.|corporation|Corp\.|limited|ltd\.|incorporated|inc\.|company|co\.|inc)$/i,
    //     "Hawaii": /(Corporation|Incorporated|Limited|Corp\.|Inc\.|Ltd\.)$/i,
    //     "Idaho": /(Incorporated|Inc\.|Corp\.|Corporation)$/i,
    //     "Illinois": /(COMPANY|CO\.|CORPORATION|CORP\.|INCORPORATED|INC\.|LTD\.|LIMITED)$/i,
    //     "Indiana": /(Professional Corporation|Professional Service Corporation|P\.C\.|P\.S\.C\.|PC|PSC)$/i,
    //     "Iowa": /(Corporation|Corp\.|Company|Co\.|Incorporated|Inc\.|Limited|Ltd\.|P\.C\.)$/i,
    //     "Kansas": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
    //     "Kentucky": /(Co\.|Company|Corp\.|Corporation|Inc\.|Incorporated|Ltd\.|Limited)$/i,
    //     "Louisiana": /(Incorporated|Corporation|Limited|Company|Inc\.|Corp\.|Ltd\.|Co\.)$/i,

    //      // Not Provided
    //     "Maine": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,


    //     "Maryland": /(Incorporated|Corporation|Limited|Company|Inc\.|Corp\.|Ltd\.|Co\.)$/i,
    //     "Massachusetts": /(Corporation|Corp\.|Inc\.|Incorporated|Ltd\.|Limited|Company|Co\.)$/i,
    //     "Michigan": /(Corporation|Corp\.|Corp|Incorporated|Inc\.|Inc|Ltd\.|Ltd|Limited|Company|Co\.|Co)$/i,
    //     "Minnesota": /(Corporation|Corp\.|Corp|Incorporated|Inc\.|Inc|Ltd\.|Ltd|Limited|Company|Co\.|Co)$/i,
    //     "Mississippi": /(Corporation|Incorporated|Company|Limited|Corp|Inc\.|Co\.|Ltd\.)$/i,
    //     "Missouri": /(Co\.?|Company|Corp\.?|Corporation|Inc\.?|Incorporated|L?T?D?\.?)$/i,
    //     "Montana": /(Co\.?|Company|Corp\.?|Corporation|Inc\.?|Incorporated|L\s?T\s?D\.?|Limited|LTD\.?)$/i,

    //      // Not Provided
    //     "Nebraska": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,

    //     "Nevada": /(Professional\sCorporation|Professional\sCorp\.?|Prof\.?\sCorp\.?|Limited|Chartered|PC|P\.C\.|LTD\.?|Chtd\.?|Copy)$/i,
    //     "New Hampshire": /(Corporation|Incorporated|Limited|Corp\.|Inc\.|Ltd\.)$/i,

    //     "New Jersey": /^(?!.*\b(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.|LLC)\b).+$/i,
        
    //     "New Mexico": /^(?!.*\b(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)\b).*\b(LLC|L\.L\.C\.)\b$/i,
    //     "New York": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.?)$/i,
    //     "North Carolina": /(Corporation|Company|Limited|Incorporated|Corp\.|Co\.|Ltd\.|Inc\.?)$/i,
    //     "North Dakota": /(Company|Corporation|Corp\.|Incorporated|Inc\.?|Limited|Ltd\.?)$/i,

    //      // Not Provided
    //     "Ohio": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,

    //     "Oklahoma": /(Corporation|Company|Incorporated|Limited)$/i,
    //     "Oregon": /(Corporation|Company|Incorporated|Limited)$/i,
    //     "Pennsylvania": /(Corporation|Incorporated|Company|Corp|Inc|Co)$/i,
    //     "Rhode Island":/(Corporation|Corp\.?|Limited|Ltd\.?|Incorporated|Inc\.?|Company|Co\.?)$/i,
    //     "South Carolina": /(Corporation|Incorporated|Company|Limited|Corp\.?|Inc\.?|Co\.?|Ltd\.?|Professional Corporation|Professional Association|PC|PA|Trust)$/i,
    //     "South Dakota": /(Association|P\.C\.|Ltd\.?|Corporation|Incorporated|Company|Corp\.?|Inc\.?|Co\.?|Limited)$/i,
    //     "Tennessee": /(Corporation|Incorporated|Company|Corp\.?|Inc\.?|Co\.?)$/i,
        
    //       // Not Provided
    //     "Texas": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
    //     "Utah": /(Corporation|Incorporated|Company|Limited|Corp\.|Inc\.|Co\.|Ltd\.)$/i,
    //     "Vermont": /(Corporation|Incorporated|Company|Limited|Corp\.|Inc\.|Co\.|Ltd\.)$/i,
         
    //     // Not Provided
    //     "Virginia": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,

    //     "Washington": /(Corporation|Company|Incorporated|Limited|Corp\.|Co\.|Inc\.|Ltd\.)$/i,
    //     "West Virginia": /(Corporation|Corp\.?|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        
    //     // Not Provided
    //     "Wisconsin": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,

    //     "Wyoming": /(Corporation|Corp\.?|Limited|Ltd\.|Incorporated|Inc\.)$/i
    // }

    CORP: {
        "Alabama": /(?<=\s)(corporation|Corp\.|incorporated|Inc\.|Corp)$/i,
        "Alaska": /(?<=\s)(corporation|Corp\.|Corp|company|co\.|limited|ltd\.|incorporated|inc\.)$/i,
        "Arizona": /(?<=\s)(Assoc|Association|Bank|CO|Inc|Company|Corp\.|Corp|Corporation|Incorp\.|Incorporated|Limited|LTD)$/i,
        "Arkansas": /(?<=\s)(Corporation|Corp\.|Corp|Company|Co\.|Incorporated|Inc\.)$/i,
        "California": /(?<=\s)(corporation|Corp\.|Corp|company|co\.|incorporated|inc\.|Inc|limited|ltd\.|professional corporation|P\.C\.)$/i,
        "Colorado": /(?<=\s)(corporation|incorporated|company|limited|Corp\.|inc\.|co\.|Corp|ltd\.)$/i,
        "Connecticut": /(?<=\s)(corporation|Corp\.|company|Inc|co\.|incorporated|Corp|inc\.|limited|ltd\.|professional corporation|P\.C\.)$/i,
      
        // Not Provided
        "Delaware": /(?<=\s)(Association|Company|Corporation|Club|Foundation|Fund|Corp\.|Corp|Institute|Society|Union|Syndicate|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Florida": /(?<=\s)(Corporation|Corp\.|Corp|Incorporated|Inc\.)$/i,
        "DC": /(?<=\s)(?:corporation|incorporated|company|limited|Corp\.|Corp|inc\.|Co\.|ltd\.)$/i,
        "Georgia": /(?<=\s)(professional corporation|professional Corp\.|professional corp|PC|P\.C\.|associated|professional association|PA|P\.A\.|corporation|Corp\.|Corp|limited|ltd\.|incorporated|inc\.|company|co\.|inc)$/i,
        "Hawaii": /(?<=\s)(Corporation|Incorporated|Limited|Corp\.|Corp|Inc\.|Ltd\.)$/i,
        "Idaho": /(?<=\s)(Incorporated|Inc\.|Corp\.|Corp|Corporation)$/i,
        "Illinois": /(?<=\s)(COMPANY|CO\.|CORPORATION|CORP\.|Corp|INCORPORATED|INC\.|LTD\.|LIMITED)$/i,
        "Indiana": /(?<=\s)(Corporation|Incorporated|Company|Limited|Corp\.|Inc.|Co.|Ltd.|Corp|Inc|Co|Ltd.)$/i,
        "Iowa": /(?<=\s)(Corporation|Corp\.|Inc|Corp|Company|Co\.|Incorporated|Inc\.|Limited|Ltd\.|P\.C\.)$/i,
        "Kansas": /(?<=\s)(Abbey|Association|Chapel|Church|Club|Co\.?|College|Company|Corp\.?|Corp|Corporation|Foundation|Fund|Inc\.?|Incorporated|Institute|Limited|Ltd\.?|Ministry|Mission|Mosque|Sanctuary|Shrine|Society|Synagogue|Syndicate|Tabernacle|Temple|Union|University)$/i,
        "Kentucky": /(?<=\s)(Co\.|Company|Corp\.|Corp|Corporation|Inc\.|Incorporated|Ltd\.|Limited)$/i,
        "Louisiana": /(?<=\s)(Incorporated|Corporation|Limited|Company|Inc\.|Corp\.|Corp|Ltd\.|Co\.)$/i,
        
        // Not Provided
        "Maine": /(?<=\s)(Corporation|Corp\.|Corp|Limited|Ltd\.|Incorporated|Inc\.|Inc)$/i,
      
        "Maryland": /(?<=\s)(Incorporated|Corporation|Limited|Company|Inc\.|Corp\.|Corp|Ltd\.|Co\.|Co|Ltd)$/i,
        "Massachusetts": /(?<=\s)(Corporation|Corp\.|Corp|Inc\.|Inc|Incorporated|Ltd\.|Limited|Company|Co\.)$/i,
        "Michigan": /(?<=\s)(Corporation|Corp\.|Corp|Incorporated|Inc\.|Inc|Ltd\.|Ltd|Limited|Company|Co\.|Co)$/i,
        "Minnesota": /(?<=\s)(Corporation|Corp\.|Corp|Incorporated|Inc\.|Inc|Ltd\.|Ltd|Limited|Company|Co\.|Co|LTD|LTD\.|INC|INC\.|COMPANY)$/i,
        "Mississippi": /(?<=\s)(Corporation|Incorporated|Company|Professional|Corporation|Professional|Association|Limited|Corp|Corp\.|Inc\.|Co\.|P\.C\.|P\.A\.|Ltd\.)$/i,
        "Missouri": /(?<=\s)(Co\.?|Company|Corp\.?|Corp|Corporation|Inc\.?|Incorporated|L?T?D?\.?)$/i,
        "Montana": /(?<=\s)(Co\.?|Company|Corp\.?|Corp|Corporation|Inc\.?|Incorporated|L\s?T\s?D\.?|Limited|LTD\.?)$/i,
        
        // Not Provided
        "Nebraska": /(?<=\s)(Corporation|Corp\.|Corp|Limited|Ltd\.|Incorporated|Inc\.)$/i,
      
        "Nevada": /(?<=\s)(Professional\sCorporation|Professional|Corp|Prof\.?|Corp\.?|Limited|Chartered|PC|P\.C\.|LTD\.?|Chtd\.?|Copy)$/i,
        "New Hampshire": /(?<=\s)(Corporation|Incorporated|Limited|Corp\.|Corp|Inc\.|Ltd\.)$/i,
      
        "New Jersey": /(?<=\s)(Corporation|Corp\.|Corp|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        
        "New Mexico": /(?<=\s)(Corporation|Corp\.|Corp|Limited|Ltd\.|Inc.|Incorporated|Inc\.)$/i,
        "New York": /(?<=\s)(Corporation|Corp\.|Corp|Limited|Ltd\.|Incorporated|Inc\.?)$/i,
        "North Carolina": /(?<=\s)(Corporation|Company|Limited|Incorporated|Corp\.|Corp|Co\.|Ltd\.|Inc\.?)$/i,
        "North Dakota": /(?<=\s)(Company|Corporation|Corp\.|Corp|Incorporated|Inc\.?|Limited|Ltd\.?)$/i,
        
        // Not Provided
        "Ohio": /(?<=\s)(Corporation|Corp\.|Corp|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        
        "Oklahoma": /(?<=\s)(Corporation|Company|Incorporated|Limited|Corp\.|Corp)$/i,
        "Oregon": /(?<=\s)(Corporation|Company|Incorporated|Inc|Limited|Corp\.|Corp)$/i,
        "Pennsylvania": /(?<=\s)(Corporation|Incorporated|Company|Corp\.|Corp|Inc|Inc\.|Co)$/i,
        "Rhode Island": /(?<=\s)(Corporation|Corp\.?|Corp|Limited|Ltd\.?|Incorporated|Inc\.?|Company|Co\.?)$/i,
        "South Carolina": /(?<=\s)(Corporation|Incorporated|Company|Limited|Corp\.?|Corp|Inc\.?|Co\.?|Ltd\.?|Professional Corporation|Professional Association|PC|PA|Trust)$/i,
        "South Dakota": /(?<=\s)(Association|P\.C\.|Ltd\.?|Corporation|Incorporated|Company|Corp\.?|Corp|Inc\.?|Co\.?|Limited)$/i,
        "Tennessee": /(?<=\s)(Corporation|Incorporated|Company|Corp\.?|Corp|Inc\.?|Co\.?)$/i,
        
        // Not Provided
        "Texas": /(?<=\s)(Corporation|Corp\.|Corp|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Utah": /(?<=\s)(Corporation|Incorporated|Company|Limited|Corp\.|Corp|Inc\.|Co\.|Ltd\.)$/i,
        "Vermont": /(?<=\s)(Corporation|Incorporated|Company|Limited|Corp\.|Corp|Inc\.|Co\.|Ltd\.)$/i,
         
        // Not Provided
        "Virginia": /(?<=\s)(Corporation|Corp\.|Corp|Limited|Ltd\.|Incorporated|Inc\.|company|co\.)$/i,
      
        "Washington": /(?<=\s)(Corporation|Company|Incorporated|Limited|Corp\.|Corp|Co\.|Inc\.|Ltd\.)$/i,
        "West Virginia": /(?<=\s)(Corporation|Corp\.?|Corp|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        
        // Not Provided
        "Wisconsin": /(?<=\s)(Corporation|Corp\.|Corp|Limited|Ltd\.|Incorporated|Inc\.)$/i,
      
        "Wyoming": /(?<=\s)(Corporation|Corp\.?|Co\.|Corp|Limited|Ltd\.|Incorporated|Inc\.)$/i
      }

      
};


export default validationConfig;