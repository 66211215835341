import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import axios from 'axios';

const MapWithStateAndCounty = ({ locationName }) => {
  const [coordinates, setCoordinates] = useState(null);

  // Fetch coordinates when location name changes
  React.useEffect(() => {
    const fetchCoordinates = async () => {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${locationName}&key=f6c7fbf886404deebe9498bf54b3da20`
      );
      if (response.data.results.length > 0) {
        const { lat, lng } = response.data.results[0].geometry;
        setCoordinates({ lat, lng });
      }
    };
    fetchCoordinates();
  }, [locationName]);

  const openGoogleMaps = () => {
    if (coordinates) {
      window.open(`https://www.google.com/maps?q=${coordinates.lat},${coordinates.lng}`, '_blank');
    }
  };

  return (
    <MapContainer center={[37.8, -96]} zoom={4} style={{ height: '100%', width: '100%' }}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {coordinates && (
        <Marker position={[coordinates.lat, coordinates.lng]} onClick={openGoogleMaps}>
          <Popup>{locationName}</Popup>
        </Marker>
      )}
    </MapContainer>
  );
};

export default MapWithStateAndCounty;
