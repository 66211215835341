import React, { useEffect } from "react";
import "./SuccessfulModal.css";
import { Modal } from "react-bootstrap";
import UnsuccessfulLotti from "./UnsuccessfulLotti";

const UnsuccessfulModal = (props) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.onHide(); // Close the modal after 4 seconds
    }, 5000);

    return () => clearTimeout(timer); // Cleanup function to clear timeout
  }, [props.onHide]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="successful-modal"
    >
      <Modal.Body>
        <div className="d-flex justify-content-center mt-2 mb-3">
          <UnsuccessfulLotti />
        </div>
        <p className="modal-texttt">
          Unfortunately, your message could not be sent. Please try again later. If the issue persists, feel free to reach out to us directly at
          <span className="blue-text"> orders@vstatefilings.com.</span>
        </p>

      </Modal.Body>
    </Modal>
  );
};

export default UnsuccessfulModal;
