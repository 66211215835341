import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import CreditCardServices from "../../services/strapiService";
import AddCity from "./AddProduct";
import DownloadPdf from "../../utils/downloadPdf";
import SideBarMenu from "../../common/Sidebar";
import ShiftsServices from "../../services/demoServices";
import { Checkbox } from "primereact/checkbox";
import { TabView, TabPanel } from "primereact/tabview";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import SuccessImage from "../../Assets/Images/icons8-success-96.png";
import ErroImage from "../../Assets/Images/error-icon.png";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import encryption from "../../services/CryptoClass";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Password } from "primereact/password";
import AddressService from "../../services/addressService";
import ContactService from "../../services/ContactService";
import MasterServices from "../../services/coreServices";
import OrderServices from "../../services/orderServices";
import { useNavigate } from "react-router-dom";

const AddOrderDemo = () => {
    const [allState, setAllState] = useState([

    ]);

    const [contactId, setContactId] = useState(null)

    const history = useNavigate()

    const steps = [
        {
            label: 'Select campaign settings',
            description: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
        },
        {
            label: 'Create an ad group',
            description:
                'An ad group contains one or more ads which target a shared set of keywords.',
        },
        {
            label: 'Create an ad',
            description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
        },
    ];


    const [selectedCheckBoxData, setSelectedCheckBoxData] = useState([]);
    const [checkedState, setCheckedState] = useState({});

    const [loading1, setLoading1] = useState(false);

    const servicesData = [
        {
            service: "EIN / TAX ID#",
            description:
                "This is state-issued document with certification on it. The document has an official seal and authentication by the state. Often times, a certified copy is required when attempting to obtain financing for your business.",
            amount: "$230",
        },
        {
            service: "Certified Copy",
            description:
                "This is state-issued document with certification on it. The document has an official seal and authentication by the state.",
            amount: "$230",
        },
        {
            service: "LLC Publication",
            description:
                "Many states mandate that a legal notice of the entity be published in certain papers for six weeks.",
            amount: "$230",
        },
        {
            service:
                "Preparation of S Corp Election Form (Form 2553 for IRS)",
            description:
                "This is especially pertinent in real estate transaction.",
            amount: "$100",
        },
        {
            service:
                "BOI (Beneficial Ownership Information) with initial filling - after filling $99 (Required within 90 days of formation)",
            description:
                "Name change of business, address Change, mission of the business, updating the Capital of the entity, & having the Corporate Shares par value altered.",
            amount: "$59",
        },
    ];



    const [entityTypes, setEntityTypes] = useState([])

    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        MasterServices.getAll("/core/api/orderTypeMaster/getAll")
            .then((res) => {
                console.log(res.data)
                if (res.data !== "") {
                    setEntityTypes(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
        ContactService.getAllContact()
            .then((res) => {
                console.log(res)
                let data = res.data

                const tempdata = data.filter((val) => val.contactDetails.emailId === user.username)
                console.log(tempdata)
                if (tempdata.length > 0) {
                    if (tempdata[0].contactDetails !== undefined) {
                        setContactId(tempdata[0].contactDetails.id)
                    }

                }


            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const handleCheckedChange = (e, serviceData) => {
        const { checked } = e.target;

        setCheckedState((prev) => ({
            ...prev,
            [serviceData.service]: checked,
        }));

        if (checked) {
            setSelectedCheckBoxData((prev) => [...prev, serviceData]);
        } else {
            setSelectedCheckBoxData((prev) =>
                prev.filter((item) => item.service !== serviceData.service)
            );
        }
    };

    // Initialize Bootstrap tooltips
    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltipTriggerList.forEach((tooltipTriggerEl) => {
            new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
    }, []);

    const totalAmount = selectedCheckBoxData.reduce(
        (acc, item) => acc + parseFloat(item.amount.replace("$", "")),
        0
    );



    const [creditAll, setCreditAll] = useState([]);

    const [creditInfo, setCreditInfo] = useState(null);

    const [enityName, setEntityName] = useState(null);

    const [state, setState] = useState(null);

    const [inputValue, setInputValue] = useState("");
    const [isAvailable, setIsAvailable] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const [llcMember, setLLCMember] = useState([]);

    const [llc, setLlc] = useState(null);

    const [allLLC, setAllLLC] = useState([]);

    const [llcName, setLLCName] = useState(null);

    const [visible, setVisible] = useState(false);

    const [selectCheckBoxData, setSelectCheckBoxData] = useState([]);

    const [IsChecked1, setIsChecked1] = useState(false);

    const [IsChecked2, setIsChecked2] = useState(false);

    const [IsChecked3, setIsChecked3] = useState(false);

    const [IsChecked4, setIsChecked4] = useState(false);

    const [IsChecked5, setIsChecked5] = useState(false);

    const [IsChecked6, setIsChecked6] = useState(false);

    const [IsChecked7, setIsChecked7] = useState(false);

    const [IsChecked8, setIsChecked8] = useState(false);

    const [IsChecked9, setIsChecked9] = useState(false);

    const [IsChecked10, setIsChecked10] = useState(false);

    const [IsChecked11, setIsChecked11] = useState(false);

    const [currentTab, setCurrentTab] = useState(0);

    const [closeProgresbar, setCloseProgresbar] = useState(false);

    const [closeProgresbar1, setCloseProgresbar1] = useState(false);

    const [dba, setDba] = useState("");

    const [name, setName] = useState("");

    const [AlternateName, setAlternateName] = useState("");

    const [llcAddress, setLLCAddress] = useState("");

    const [mailingAddress, setMailingAddress] = useState("");

    const [initData, setInitData] = useState(null);

    const [initData1, setInitData1] = useState(null);

    const [initData2, setInitData2] = useState(null);

    const [allInitData, setAllInitData] = useState([]);

    const [inputFormData, setInputFormData] = useState([]);

    const [allModels, setAllModels] = useState([]);

    const [modelData, setModelData] = useState([]);

    const [modelData1, setModelData1] = useState([]);

    const [IsSuccessful, setIsSuccessFul] = useState(false);

    const [modelName, setModelName] = useState(null);

    const [currentStep, setCurrentStep] = useState(1);

    const [activeStep, setActiveStep] = React.useState(0);

    const [globalModel, setGlobalModel] = useState([])


    const [allComponents, setAllComponents] = useState([])

    const [mainModel, setMainModel] = useState(null)

    const [rawModel, setRawModel] = useState([])

    const [groupInitialData, setGroupInitialData] = useState({})

    const [showDescription, setShowDescription] = useState(false);

    const toggleDescription = () => {
        setShowDescription(!showDescription);
    };

    const [formCount, setFormCount] = useState(
        1
    );

    const handleRepeatForm = () => {
        setFormCount(prevCount => prevCount + 1);
    };

    const handleNext = (data) => {
        console.log(errors)
        if (activeStep === data.length - 1) {
            nextPrev(1);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        setValue,
        watch,
        reset,
    } = useForm("");


    useEffect(() => {
        const data = watch();
        console.log(data);

        const tem = allModels.map((val) => {
            console.log(val);
            const temps = Object.keys(data).reduce((acc, key) => {
                if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
                    acc[key] = data[key];
                }
                return acc;
            }, {});

            const convertdata = val.apiId;

            return {
                model: convertdata,
                data: temps,
            };
        });


        console.log(allModels)
        const tempsData = allModels.map((val) => {
            console.log(val);
            const temps = Object.keys(data).reduce((acc, key) => {
                console.log(key)

                if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
                    acc[key] = data[key];
                }


                return acc;
            }, {});

            console.log(temps)

            const formData = Object.keys(data).map((keys, j) => {

                // Only process the key if it contains an underscore
                if (keys.includes('-')) {
                    const baseKey = keys.split('-')[0];  // Extract the base key before the underscore
                    console.log(baseKey)

                    if (baseKey && Object.keys(val.schema.attributes).some((ele) => ele === baseKey)) {
                        console.log(data[keys])
                        return {
                            __temp_key__: j + 1,
                            [baseKey]: data[keys]
                        }
                    }

                }

            });

            console.log(formData)

            const formDatas = formData.filter((val) => val !== undefined)
            console.log(formDatas)

            const convertdata = val.uid;
            if (formDatas.length > 0) {
                return {
                    model: convertdata,
                    data: formDatas,
                };
            }
            else {
                return {
                    model: convertdata,
                    data: temps,
                };
            }

        });

        console.log(tem);
        console.log(tempsData)
        setModelData(tem);
        setModelData1(tempsData);

    }, [getValues, setValue, currentTab, formCount]);

    useEffect(() => {
        AddressService.getAllStates()
            .then((res) => {
                console.log(res)
                if (res.data !== "") {
                    setAllState(res.data)
                }

            })
            .catch((err) => {
                console.log(err)
            })
        ShiftsServices.getInit()
            .then((res) => {
                // console.log(res.data)
                console.log(res);
                // const decryptData = encryption.decryptData(res.data);

                // const tempdata = res.data.data.contentTypes
                const tempdata = []
                // console.log(tempdata);
                setAllInitData(res.data.data);
                // const tempdata = res.data.data.contentTypes
                // const tempdata = res.data.data
                // console.log(tempdata)
                // setAllInitData(tempdata)

                if (tempdata.length > 0) {
                    const temp = tempdata.filter((val) => val.apiID === "llc");
                    const temp1 = tempdata.filter((val) => val.apiID === "llc-member");
                    const temp2 = tempdata.filter(
                        (val) => val.apiID === "credit-card-info"
                    );
                    console.log(temp2);
                    if (temp.length > 0) {
                        temp.map((val) => {
                            delete val.attributes.id;
                        });
                        const temps = temp[0].attributes;

                        setInitData(temps);
                    }
                    if (temp1.length > 0) {
                        temp1.map((val) => {
                            delete val.attributes.id;
                        });
                        const temps = temp1[0].attributes;

                        setInitData1(temps);
                    }
                    if (temp2.length > 0) {
                        temp2.map((val) => {
                            delete val.attributes.id;
                        });
                        const temps = temp2[0].attributes;

                        setInitData2(temps);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });

        CreditCardServices.getCreditInfo()
            .then((res) => {
                console.log(res.data);
                setCreditAll(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
        ShiftsServices.getInit()
            .then((res) => {
                console.log(res)
                setRawModel(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
        CreditCardServices.getComponent()
            .then((res) => {
                console.log(res)
                setAllComponents(res.data.data)
            })
            .catch((err) => {
                console.log(err)


            })
    }, []);

    function flattenArray(array) {
        return array.map((item) => {
            const result = {};
            for (const key in item) {
                if (Array.isArray(item[key])) {
                    result[key] = item[key].flat();
                }
            }
            return result;
        });
    }

    // function reorderAttributes(a, b) {
    //     const aObj = a[0];

    //     return b.map(item => {
    //         const apiID = item.apiID;
    //         if (aObj[apiID]) {
    //             const order = aObj[apiID].map(attr => attr.name);
    //             const newAttributes = {};

    //             order.forEach(key => {
    //                 if (item.attributes[key]) {
    //                     newAttributes[key] = item.attributes[key];
    //                 }
    //             });

    //             for (const key in item.attributes) {
    //                 if (!newAttributes[key]) {
    //                     newAttributes[key] = item.attributes[key];
    //                 }
    //             }

    //             item.attributes = newAttributes;
    //         }

    //         return item;
    //     });
    // }

    function reorderAttributes(a, b) {
        console.log(a);

        const temp = b.map((item) => {
            for (let i = 0; i < a.length; i++) {
                const aObj = a[i];
                console.log(aObj);
                console.log(item);
                const apiID = item.schema.singularName;
                console.log(aObj[apiID]);
                if (aObj[apiID]) {
                    const order = aObj[apiID].map((attr) => attr.name);
                    const newAttributes = {};

                    console.log(order);
                    order.forEach((key) => {
                        console.log(key);
                        console.log(item.schema.attributes);
                        if (item.schema.attributes[key] && key) {
                            newAttributes[key] = item.schema.attributes[key];
                            console.log(newAttributes[key]);
                        }
                    });

                    console.log(newAttributes);

                    // for (const key in item.schema.attributes) {
                    //     if (!newAttributes[key]) {
                    //         newAttributes[key] = item.schema.attributes[key];
                    //     }
                    // }

                    item.schema.attributes = newAttributes;
                }
            }
            return item;
        });

        console.log(temp);
        return temp;
    }

    useEffect(() => {
        const firstData =
            state !== null && enityName !== null ?
                typeof enityName.orderShortName === "string" && typeof state.stateFullDesc === "string"
                    ? state.stateFullDesc.toLowerCase() + "-" + enityName.orderShortName.toLowerCase()
                    : "" : "";

        const getTargetModels = (attributes) => {
            return Object.keys(attributes).flatMap((key) => {
                if (attributes[key].type === "component" && attributes[key].component) {
                    const targetModel = attributes[key].component;
                    console.log(targetModel)
                    return targetModel;
                }
                return [];
            });
        };

        const fetchConfigurations = async (models) => {
            const fetchPromises = models.map(async (model) => {
                try {
                    const res = await CreditCardServices.getConfiguration(
                        `api::${model.uid}`
                    );
                    const response = res.data;
                    const tempdata = model.uid;

                    if (response.data !== undefined) {
                        return {
                            [tempdata]:
                                response.data.contentType.layouts.edit !== undefined
                                    ? response.data.contentType.layouts.edit
                                    : {},
                        };
                    }
                } catch (error) {
                    console.log(`Error fetching configuration for ${model.uid}:`, error);
                }
            });

            const results = await Promise.all(fetchPromises);
            return results.filter((val) => val !== undefined);
        };

        const processModels = async (models, processedModels = new Set()) => {
            let allModelsData = [...models];
            console.log(allModelsData)
            const targetModels = models.flatMap((model) => getTargetModels(model.schema.attributes));

            console.log(targetModels)
            const uniqueTargetModels = [...new Set(targetModels)];
            console.log(uniqueTargetModels)
            if (uniqueTargetModels.length === 0) return allModelsData;
            console.log(allComponents)
            const targetModelsData = allComponents.filter((val) =>
                uniqueTargetModels.includes(val.uid) && !processedModels.has(val.uid)
            );
            console.log(targetModelsData)

            allModelsData = [...allModelsData, ...targetModelsData];
            targetModelsData.forEach(val => processedModels.add(val.uid));
            console.log(targetModelsData)

            const configurations = await fetchConfigurations(targetModelsData);
            console.log(configurations)
            const flatArr = flattenArray(configurations);
            console.log(flatArr)

            // Replace attributes with nested relations
            const updatedModels = await replaceNestedRelations(flatArr, allModelsData);
            console.log(updatedModels)
            return updatedModels;
        };

        const processAttributes = async (attributes, processedModels) => {
            const result = {};
            for (let key in attributes) {
                if (attributes[key].type === "component") {
                    // const targetModel = attributes[key].target.split("::")[1].split(".")[0];
                    const targetModel = attributes[key].component;

                    const relatedModel = allComponents.find((model) => model.uid === targetModel);
                    console.log(relatedModel)

                    if (relatedModel && !processedModels.has(relatedModel.uid)) {
                        processedModels.add(relatedModel.uid);
                        result[key] = await processModel(relatedModel, processedModels);
                    } else {
                        result[key] = {};
                    }
                } else {
                    result[key] = attributes[key];
                }
            }
            return result;
        };

        const processModel = async (model, processedModels) => {
            const newModel = { ...model };
            console.log(newModel)
            newModel.schema.attributes = await processAttributes(newModel.schema.attributes, processedModels);
            return newModel;
        };

        const replaceNestedRelations = async (flatArr, allModelsData) => {
            const result = [];
            for (const model of allModelsData) {
                const newModel = await processModel(model, new Set());
                console.log(newModel)
                result.push(newModel);
            }
            return result;
        };

        if (firstData !== "") {
            console.log(allInitData)
            if (enityName !== "" && state !== null) {
                setLoading1(false)
            }

            console.log(firstData)

            const initialModels = allInitData.filter((val) => val.apiID === firstData);
            console.log(allInitData)
            console.log(initialModels)
            const tempInitial = rawModel.filter((val) => val.apiID === firstData)
            console.log(tempInitial)
            if (tempInitial.length > 0) {
                setGroupInitialData(tempInitial[0].schema.attributes)
            }

            if (initialModels.length > 0) {
                setMainModel(initialModels[0])
                processModels(initialModels)
                    .then((finalModels) => {
                        console.log(finalModels)
                        // const cleanedData = finalModels.reduce((acc, model) => {
                        //     acc[model.apiID] = model;
                        //     return acc;
                        // }, {});
                        const temp = finalModels.filter((val) => val.apiID === firstData)
                        if (temp.length > 0) {
                            setLoading1(false)
                        }
                        setInputFormData(temp);
                        console.log('Final data:', temp);
                    })
                    .catch((err) => {
                        console.log('Error processing models:', err);
                    });
            }
        }
    }, [enityName, state]);



    // useEffect(() => {
    //     const firstData =
    //         typeof enityName === "string" && typeof state === "string"
    //             ? state.toLowerCase() + "-" + enityName.toLowerCase()
    //             : "";

    //     const getTargetModels = (attributes) => {
    //         return Object.keys(attributes).flatMap((key) => {
    //             if (attributes[key].type === "relation" && attributes[key].target) {
    //                 const targetModel = attributes[key].target.split("::")[1].split(".")[0];
    //                 return targetModel;
    //             }
    //             return [];
    //         });
    //     };

    //     const fetchConfigurations = async (models) => {
    //         const fetchPromises = models.map(async (model) => {
    //             try {
    //                 const res = await CreditCardServices.getConfiguration(
    //                     `api::${model.apiID}.${model.apiID}`
    //                 );
    //                 const response = res.data;
    //                 const tempdata = model.apiID;

    //                 if (response.data !== undefined) {
    //                     return {
    //                         [tempdata]:
    //                             response.data.contentType.layouts.edit !== undefined
    //                                 ? response.data.contentType.layouts.edit
    //                                 : {},
    //                     };
    //                 }
    //             } catch (error) {
    //                 console.log(`Error fetching configuration for ${model.apiID}:`, error);
    //             }
    //         });

    //         const results = await Promise.all(fetchPromises);
    //         return results.filter((val) => val !== undefined);
    //     };

    //     const processModels = async (models, processedModels = new Set()) => {
    //         let allModelsData = [...models];
    //         console.log(allModelsData)
    //         const targetModels = models.flatMap((model) => getTargetModels(model.schema.attributes));
    //         console.log(targetModels)

    //         const tempFieldArr = allInitData.filter((val) => val.apiID === firstData);

    //         // const temps = Object.keys(tempAtributes).map((key, i) => {
    //         //     if (tempAtributes[key].target !== undefined) {
    //         //         const temp = tempAtributes[key].target.split("::");
    //         //         if (temp.length > 0) {
    //         //             const temps = temp[1].split(".");
    //         //             if (temps.length > 0) {
    //         //                 return temps[0];
    //         //             }
    //         //         }
    //         //     }


    //         // });





    //         // const tempsFormData = temps.filter((val) => val !== undefined);
    //         const uniqueTargetModels = [...new Set(targetModels)];

    //         if (uniqueTargetModels.length === 0) return allModelsData;

    //         const targetModelsData = allInitData.filter((val) =>
    //             uniqueTargetModels.includes(val.apiID) && !processedModels.has(val.apiID)
    //         );

    //         allModelsData = [...allModelsData, ...targetModelsData];
    //         targetModelsData.forEach(val => processedModels.add(val.apiID));

    //         const configurations = await fetchConfigurations(targetModelsData);
    //         const flatArr = flattenArray(configurations);

    //         // Reorder attributes and clean up
    //         const updatedB = reorderAttributes(flatArr, allModelsData);
    //         const finalAttributes = updatedB.map((val) => {
    //             return val.schema.attributes;
    //         });

    //         const finalData = finalAttributes.map((attributes) => {
    //             delete attributes.id;
    //             return attributes;
    //         });

    //         console.log(targetModelsData)
    //         console.log(processedModels)

    //         // Recursive call for nested relations

    //         return processModels(targetModelsData, processedModels);
    //     };

    //     if (firstData !== "") {
    //         console.log(`Processing data for: ${firstData}`);
    //         const initialModels = allInitData.filter((val) => val.apiID === firstData);

    //         if (initialModels.length > 0) {
    //             processModels(initialModels)
    //                 .then((finalModels) => {
    //                     console.log('Final models:', finalModels);

    //                     // Collect all attributes including nested ones
    //                     const finalAttributes = finalModels.map((model) => model.schema.attributes);
    //                     const cleanedData = finalAttributes.map((attributes) => {
    //                         delete attributes.id;
    //                         return attributes;
    //                     });

    //                     console.log('Final data:', cleanedData);
    //                     // setAllModels(finalModels);
    //                     const tempFieldArr = allInitData.filter((val) => val.apiID === firstData);
    //                     console.log(tempFieldArr);
    //                     if (tempFieldArr.length > 0) {
    //                         const tempAtributes = tempFieldArr[0].schema.attributes;
    //                         console.log(tempAtributes);

    //                         const temps = Object.keys(tempAtributes).map((key, i) => {
    //                             if (tempAtributes[key].target !== undefined) {
    //                                 const temp = tempAtributes[key].target.split("::");
    //                                 if (temp.length > 0) {
    //                                     const temps = temp[1].split(".");
    //                                     if (temps.length > 0) {
    //                                         return temps[0];
    //                                     }
    //                                 }
    //                             }

    //                             // return tempAtributes[key].model
    //                         });

    //                         console.log(temps);

    //                         const tempsFormData = temps.filter((val) => val !== undefined);
    //                         console.log(tempsFormData);

    //                         const tempsFormDataType = allInitData.filter((val) => {
    //                             return tempsFormData.some((ele) => val.apiID === ele);
    //                         });





    //                         setAllModels(tempsFormDataType);

    //                         const tempd = tempsFormDataType.map(async (val) => {
    //                             try {
    //                                 const res = await CreditCardServices.getConfiguration(
    //                                     `api::${val.apiID}.${val.apiID}`
    //                                 );

    //                                 const response = (res.data);
    //                                 const tempdata = val.apiID;
    //                                 console.log(response);
    //                                 console.log(tempdata);

    //                                 if (response.data !== undefined) {
    //                                     return {
    //                                         [tempdata]:
    //                                             response.data.contentType.layouts.edit !== undefined
    //                                                 ? response.data.contentType.layouts.edit
    //                                                 : {},
    //                                     };
    //                                 }
    //                             } catch (error) {
    //                                 console.log(
    //                                     `Error fetching configuration for ${val.apiID}:`,
    //                                     error
    //                                 );
    //                                 // return { [val.apiID]: {} };
    //                             }
    //                         });

    //                         console.log(tempd);

    //                         Promise.all(tempd)
    //                             .then((results) => {
    //                                 console.log(results);
    //                                 const tempres = results.filter((val) => val !== undefined);

    //                                 const flatArr = flattenArray(tempres);
    //                                 console.log(flatArr);
    //                                 console.log(tempsFormDataType);

    //                                 const updatedB = reorderAttributes(flatArr, tempsFormDataType);
    //                                 console.log(updatedB);
    //                                 const tempsFormDataTypeAtribute = updatedB.map((val) => {
    //                                     return val.schema.attributes;
    //                                 });

    //                                 console.log(tempsFormDataTypeAtribute);

    //                                 const finalData = updatedB.map((val) => {
    //                                     delete val.schema.attributes.id;
    //                                     return val;
    //                                 });

    //                                 console.log(finalData);
    //                                 console.log(cleanedData)
    //                                 const finalTempData = cleanedData.concat(finalData)
    //                                 console.log(finalTempData)
    //                                 setInputFormData(finalData);
    //                                 console.log(JSON.stringify(updatedB, null, 2));
    //                             })
    //                             .catch((err) => {
    //                                 console.log(err);
    //                             });
    //                     }

    //                 })
    //                 .catch((err) => {
    //                     console.log('Error processing models:', err);
    //                 });
    //         }
    //     }
    // }, [enityName, state]);

    console.log(inputFormData)


    useEffect(() => {
        const firstData =
            typeof enityName === "string" && typeof state === "string"
                ? state.toLowerCase() + "-" + enityName.toLowerCase()
                : "";
        if (firstData !== "") {
            console.log(firstData);
            console.log(allInitData);
            setModelName(firstData);

        }
    }, [enityName, state]);





    const checkAvailability = async (value) => {
        setLoading(true);
        setError("");
        try {
            // const response = await axios.get(`https://api.example.com/check-availability?value=${value}`);
            // setIsAvailable(response.data.isAvailable);
        } catch (err) {
            setError("Error checking availability");
        }
        setLoading(false);
    };
    // Current tab is set to be the first tab (0)
    useEffect(() => {
        showTab(currentTab);
    }, [currentTab]);

    useEffect(() => {
        CreditCardServices.getCreditInfo()
            .then((res) => {
                console.log(res.data);
                setCreditAll(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        CreditCardServices.getLLCmember()
            .then((res) => {
                console.log(res.data);
                setLLCMember(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        CreditCardServices.getLLC()
            .then((res) => {
                console.log(res.data);
                setAllLLC(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

        ShiftsServices.getCustomer()
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const getFormErrorMessage = (name) => {
        return errors[name] ? (
            <small className="error-msg font-fam-for-all ">
                {errors[name].message}
            </small>
        ) : (
            <small className="error-msg "></small>
        );
    };

    function convertName(originalString) {

        if (!originalString.includes('_')) {
            return originalString;
        }


        const parts = originalString.split('_');


        const relevantParts = parts.slice(1);

        // Capitalize the first letter of each part and join them with a space
        const formattedString = relevantParts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');

        return formattedString;
    }


    const renderFormField = (column, key, val, value) => {

        const errorMessageTag = document.getElementById(`${key}--error`);

        // if (column.default) {
        //     setValue(key,column.default)
        // }

        console.log(value)
        switch (column.type) {
            case 'string':
                return <>
                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space w-full">

                                <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                <span className="p-float-label ">

                                    <Controller name={val}
                                        control={control}
                                        rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertName(key)} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.` } : {}}
                                        render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field}
                                                autoComplete={false}
                                                // onChange={(e) => errorMessageTag.textContent = ''}

                                                style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', }}
                                                className='dialog-form-input-field-wizard'
                                                maxLength={50} />
                                        )} />



                                </span>
                                <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
                                {getFormErrorMessage(key)}

                            </div>

                        </div>

                    </div>
                </>;
            // case "biginteger":
            //     return<>
            //     <input type="number" />
            //     </>
            case 'biginteger':
                return <>
                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space w-full ">

                                <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                <span className="p-float-label ">

                                    <Controller name={val}
                                        control={control}
                                        rules={column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.`, pattern: { value: /^\d+$/, message: 'Please Enter Valid No Of Positions' } } : {}}
                                        render={({ field, fieldState }) => (
                                            <input type='number' id={field.name} {...field}

                                                // onChange={(e) => errorMessageTag.textContent = ''}
                                                style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px' }}
                                                className='dialog-form-input-field-wizard p-inputtext'
                                            />
                                        )} />



                                </span>
                                <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
                                {getFormErrorMessage(key)}

                            </div>

                        </div>

                    </div>
                </>;

            case 'decimal':
                return <>
                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space w-full">

                                <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                <span className="p-float-label ">

                                    <Controller name={val}
                                        control={control}
                                        rules={column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.`, pattern: { value: /^\d+$/, message: 'Please Enter Valid No Of Positions' } } : {}}
                                        render={({ field, fieldState }) => (
                                            <input type='number' id={field.name} {...field}

                                                // onChange={(e) => errorMessageTag.textContent = ''}
                                                style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px' }}
                                                className='dialog-form-input-field-wizard p-inputtext'
                                            />
                                        )} />



                                </span>
                                <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
                                {getFormErrorMessage(key)}

                            </div>

                        </div>

                    </div>
                </>;

            case 'email':
                return <>
                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space w-full">

                                <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                <span className="p-float-label ">

                                    <Controller name={val}
                                        control={control}
                                        rules={column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.`, pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Please Enter Valid Email' } } : {}}
                                        render={({ field, fieldState }) => (
                                            <input type='email' id={field.name} {...field}

                                                // onChange={(e) => errorMessageTag.textContent = ''}
                                                style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px' }}
                                                className='dialog-form-input-field-wizard p-inputtext'
                                            />
                                        )} />



                                </span>
                                <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
                                {getFormErrorMessage(key)}

                            </div>

                        </div>

                    </div>
                </>;
            case "password":
                return <>

                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space w-full">

                                <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                <span className="p-float-label " >
                                    <Controller
                                        control={control}
                                        name={val}
                                        rules={column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.`, pattern: { value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, message: 'Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.' } } : {}}
                                        render={({ field, fieldState }) => (
                                            <Password
                                                id={field.name} {...field}
                                                toggleMask
                                                inputRef={field.ref}
                                                autoComplete={false}
                                                className='dialog-form-input-field-wizard'
                                            />
                                        )} />



                                </span>
                                <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
                                {getFormErrorMessage(key)}

                            </div>
                        </div>
                    </div>
                </>
            // case "date":
            //     return <>
            //         <div className=" justify-content-center dialog-form-field-space ml-1">
            //             <div className="field">
            //                 <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label><span className="p-float-label ">
            //                     <Controller name={key}
            //                         control={control}
            //                         rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertName(key)} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.` } : {}}
            //                         render={({ field, fieldState }) => (
            //                             <LocalizationProvider dateAdapter={AdapterDayjs} >

            //                                 <DatePicker
            //                                     id={field.name} {...field}
            //                                     // value={values}
            //                                     // onChange={handleOpenDate}
            //                                     // required

            //                                     slotProps={{
            //                                         textField: {
            //                                             size: 'small',
            //                                             error: false,
            //                                             fullWidth: true
            //                                         },
            //                                     }}


            //                                     className='dialog-form-dropdown-field'

            //                                 />

            //                             </LocalizationProvider>
            //                         )} />





            //                 </span>
            //                 {/* {getFormErrorMessage('opendate')} */}
            //                 <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
            //                 {getFormErrorMessage(key)}
            //             </div>
            //         </div>
            //     </>
            case "enumeration":
                return <>
                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space w-full">

                                <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>

                                <span className="p-float-label ">
                                    <Controller
                                        name={val}
                                        control={control}
                                        // onChange={(e) => errorMessageTag.textContent = ''}

                                        rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertName(key)} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.` } : {}}
                                        render={({ field }) => <Dropdown id={field.name} filter name="jd" value={field.value} options={column.enum !== undefined ? column.enum : []} onChange={(e) => {
                                            field.onChange(e.value)
                                            errorMessageTag.textContent = ''
                                        }
                                        } maxSelectedLabels={3} style={{ flexGrow: '1' }}
                                            className='dialog-form-input-field-wizard' />}
                                    />
                                </span>
                                <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
                                {getFormErrorMessage(key)}
                            </div>
                        </div>
                    </div>

                </>

            default:
                return null;
        }
    };

    const renderFormField1 = (column, key) => {
        switch (column.type) {
            case "string":
                return (
                    <>
                        <div className="input-fields-container">
                            <div className="input-fields-main">
                                <div className=" justify-content-center dialog-form-field-space ">
                                    <div className="">
                                        <label htmlFor="" className="form-label font-fam-for-all">
                                            {convertName(key)}{" "}
                                            {column.required !== undefined &&
                                                column.required === true ? (
                                                <span className="form-field-mandatory">*</span>
                                            ) : (
                                                ""
                                            )}
                                        </label>
                                        <span className="p-float-label ">
                                            <Controller
                                                name={key}
                                                control={control}
                                                rules={
                                                    column.required !== undefined &&
                                                        column.required === true
                                                        ? {
                                                            required: `${convertToTitleCase(
                                                                key
                                                            )} Required.`,
                                                        }
                                                        : {}
                                                }
                                                render={({ field, fieldState }) => (
                                                    <InputText
                                                        id={field.name}
                                                        {...field}
                                                        autoComplete={false}
                                                        style={{
                                                            flexGrow: "1",
                                                            paddingLeft: "10px",
                                                            paddingBottom: "5px",
                                                            fontWeight: "bold",
                                                            width: "435px",
                                                            height: "40px",
                                                        }}
                                                        maxLength={50}
                                                    />
                                                )}
                                            />
                                        </span>
                                        {getFormErrorMessage(key)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            case "number":
                return <input type="number" id={key} />;

            default:
                return null;
        }
    };

    console.log(selectCheckBoxData);

    let currentTabs = 0;
    let currentSteps = 1;
    function showTab(n) {
        // This function will display the specified tab of the form...
        var x = document.getElementsByClassName("tab");
        console.log(x)
        if (x.length > 0) {
            if (x[n] !== undefined) {
                x[n].style.display = "block";
            }

            //... and fix the Previous/Next buttons:
            if (n == 0) {
                document.getElementById("prevBtn").style.display = "none";
            } else {
                document.getElementById("prevBtn").style.display = "flex";
            }
            if (n == (x.length - 1) && inputFormData.length > 0) {
                // document.getElementById("nextBtn").innerHTML = "Submit";
                document.getElementById("nextBtn").style.display = "none"
                document.getElementById("submitBtn").style.display = "flex"

            } else {
                document.getElementById("nextBtn").innerHTML = "Next";
                document.getElementById("nextBtn").style.display = "flex"
                document.getElementById("submitBtn").style.display = "none"

            }
            //... and run a function that will display the correct step indicator:
            fixStepIndicator(n)
        }

    }

    const nextPrev = (n) => {
        // This function will figure out which tab to display
        var x = document.getElementsByClassName("tab");
        if (n === 1) {
            if (currentStep < inputFormData.length + 5) {
                setCurrentStep(currentStep + 1);
            }
        }

        if (n === -1) {
            if (currentStep > 1) {
                setCurrentStep(currentStep - 1);
            }
        }

        // Exit the function if any field in the current tab is invalid:
        console.log(n)
        console.log(x)
        if (x.length > 0) {
            console.log(!validateForm())
            if (n == 1 && !validateForm()) return false;
            // Hide the current tab:
            console.log(currentTab)
            if (x[currentTab] !== undefined) {
                x[currentTab].style.display = "none";
            }

            // Increase or decrease the current tab by 1:
            const newTab = currentTab + n;

            console.log(newTab)
            setCurrentTab(newTab)

            showTab(newTab);


        }
        document.getElementById('nextBtn').addEventListener('click', function () {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Smooth scroll
            });
        });
    }

    const stepLabels = Array.from({ length: inputFormData.length + 5 }, (_, i) => `Step ${i + 1} of ${inputFormData.length + 5}`);

    console.log(currentTab)


    function validateForm() {
        console.log(currentTab);
        const x = document.getElementsByClassName("tab");
        let valid = true;

        console.log(x);

        if (x.length > 0 && x[currentTab] !== undefined) {
            const y = x[currentTab].getElementsByTagName("input");
            const dropdowns = x[currentTab].getElementsByClassName("p-dropdown");

            console.log(y);
            console.log(dropdowns);

            // A loop that checks every input field in the current tab:
            for (let i = 0; i < y.length; i++) {
                const input = y[i];
                console.log(input);
                console.log(inputFormData);
                const column = allModels.find(field => {
                    const temp = field.schema.attributes;
                    console.log(temp);
                    return Object.keys(temp).includes(input.name);
                });
                console.log(column);

                if (column && column !== undefined) {
                    const fieldData = column.schema.attributes[input.name];
                    console.log(fieldData);
                    const errorMessageTag = document.getElementById(`${input.name}--error`);

                    // Reset previous error message
                    if (errorMessageTag) {
                        errorMessageTag.textContent = "";
                    }

                    // Check if the input field is empty and required:
                    console.log(fieldData.required);
                    console.log(input.value.trim());
                    if (fieldData.required && input.value.trim() === "") {
                        valid = false;
                        if (errorMessageTag) {
                            errorMessageTag.textContent = `${input.name} required`;
                        }
                        console.log(valid);
                    }

                    // Check regex validation if provided:
                    if (fieldData.regex && input.value.trim() !== "") {
                        const regex = new RegExp(fieldData.regex);
                        if (!regex.test(input.value.trim())) {
                            valid = false;
                            if (errorMessageTag) {
                                errorMessageTag.textContent = `Enter Valid ${input.name} `;
                            }
                        }
                    }
                    if (fieldData.maxLength && input.value.trim() !== "") {
                        if (input.value.length > fieldData.maxLength) {
                            valid = false;
                            if (errorMessageTag) {
                                errorMessageTag.textContent = `${input.name} Should be Max Length ${fieldData.maxLength} `;
                            }
                        }
                    }
                    if (fieldData.minLength && input.value.trim() !== "") {
                        if (input.value.length < fieldData.minLength) {
                            valid = false;
                            if (errorMessageTag) {
                                errorMessageTag.textContent = `${input.name} Should be Min Length ${fieldData.minLength} `;
                            }
                        }
                    }
                } else {
                    console.log(column);
                    console.log(inputFormData);
                    console.log(valid);
                    if (inputFormData.length === 0) {
                        valid = false;
                    }
                }
            }

            // A loop that checks every dropdown field in the current tab:
            for (let i = 0; i < dropdowns.length; i++) {
                const dropdown = dropdowns[i];
                const inputName = dropdown.id;
                const inputValue = dropdown.querySelector('.p-dropdown-label').textContent.trim();
                console.log(inputValue)

                if (inputName) {
                    const column = allModels.find(field => {
                        const temp = field.schema.attributes;
                        return Object.keys(temp).includes(inputName);
                    });

                    if (column && column !== undefined) {
                        const fieldData = column.schema.attributes[inputName];
                        const errorMessageTag = document.getElementById(`${inputName}--error`);

                        // Reset previous error message
                        if (errorMessageTag) {
                            errorMessageTag.textContent = "";
                        }

                        // Check if the dropdown field is empty and required:
                        if (fieldData.required && inputValue === "empty") {
                            valid = false;
                            if (errorMessageTag) {
                                errorMessageTag.textContent = `${inputName} required`;
                            }
                        }

                        // Check regex validation if provided:
                        if (fieldData.regex && inputValue !== "") {
                            const regex = new RegExp(fieldData.regex);
                            if (!regex.test(inputValue)) {
                                valid = false;
                                if (errorMessageTag) {
                                    errorMessageTag.textContent = `Enter Valid ${inputName} `;
                                }
                            }
                        }
                        if (fieldData.maxLength && inputValue !== "") {
                            if (inputValue.length > fieldData.maxLength) {
                                valid = false;
                                if (errorMessageTag) {
                                    errorMessageTag.textContent = `${inputName} Should be Max Length ${fieldData.maxLength} `;
                                }
                            }
                        }
                        if (fieldData.minLength && inputValue !== "") {
                            if (inputValue.length < fieldData.minLength) {
                                valid = false;
                                if (errorMessageTag) {
                                    errorMessageTag.textContent = `${inputName} Should be Min Length ${fieldData.minLength} `;
                                }
                            }
                        }
                    } else {
                        if (inputFormData.length === 0) {
                            valid = false;
                        }
                    }
                }
            }

            // If the valid status is true, mark the step as finished and valid:
            if (valid) {
                const stepElements = document.getElementsByClassName("step");

                if (stepElements[currentTab] !== undefined) {
                    stepElements[currentTab].className += " finish";


                }


            }
        }

        console.log(valid);

        return valid; // return the valid status
    }



    function fixStepIndicator(n) {
        // This function removes the "active" class of all steps...
        var i, x = document.getElementsByClassName("step");
        for (i = 0; i < x.length; i++) {
            x[i].className = x[i].className.replace(" active", "");
        }
        //... and adds the "active" class on the current step:
        if (x[n] !== undefined) {
            x[n].className += " active";
        }

    }


    const handleComapnyName = (e) => {
        const value = e.target.value;
        setInputValue(value);

        // Only check availability if the input is not empty
        if (value) {
            checkAvailability(value);
        } else {
            setIsAvailable(null);
        }
    };

    const handleLLCMember = (e) => {
        setLlc(e.target.value);
    };

    console.log(modelData);


    function processSchema(schema, dataMap) {
        const result = {};

        console.log(schema.attributes)

        Object.keys(schema.attributes).forEach(attrKey => {
            const attr = schema.attributes[attrKey];

            console.log(attr)

            if (attr.apiId !== undefined && mainModel) {
                const mainSchema = mainModel.schema.attributes;

                console.log(mainSchema)

                // Use find to get the first matching key instead of filtering
                const componentKeys = Object.keys(mainSchema).map(k =>
                    Object.keys(mainSchema[k].schema.attributes).filter(
                        (key) => mainSchema[k].schema.attributes[key].uid === attr.uid
                    )
                );
                console.log(componentKeys)
                const componentKey = componentKeys.flat()
                console.log(componentKey.flat())

                if (componentKey) {
                    const componentData = dataMap.get(attr.uid);
                    console.log(componentData)

                    if (componentData) {
                        // Ensure mainSchema[componentKey] exists before recursion
                        if (mainSchema[componentKey] && mainSchema[componentKey].schema) {
                            result[attrKey] = processSchema(mainSchema[componentKey].schema, dataMap);
                            Object.assign(result[attrKey], componentData);
                            console.log(result[attrKey])
                        } else {
                            result[attrKey] = componentData;
                            console.log(result[attrKey])
                        }
                    }
                }
            } else {
                const parentData = dataMap.get(attrKey);

                if (parentData) {
                    console.log(result[attrKey])
                    result[attrKey] = parentData;
                }
            }
        });
        console.log(result)
        return result;
    }

    function buildFinalData(temps, tempsData) {
        const finalData = {};

        const dataMap = new Map(tempsData.map(d => [d.model, d.data]));
        if (mainModel) {

            console.log(temps)
            Object.keys(temps).forEach(key => {
                const schema = temps[key].schema;
                console.log(schema)
                console.log(dataMap)
                console.log(temps[key].uid)
                const data = dataMap.get(temps[key].uid);

                console.log(data)
                finalData[key] = processSchema(schema, dataMap);
                if (data) {
                    console.log(data)

                    console.log(finalData[key])
                    if (Array.isArray(data)) {
                        console.log(key);
                        console.log(data);
                        finalData[key] = [...data];
                    } else {
                        Object.assign(finalData[key], data);
                    }
                }
            });
        }
        console.log(finalData)
        return finalData;
    }

    const onSubmit = (data) => {
        console.log(data);

        if (mainModel) {
            console.log(mainModel.schema.attributes)
            console.log(modelData1)
            const finalData1 = buildFinalData(mainModel.schema.attributes, modelData1);

            console.log(finalData1)
            console.log(mainModel)
            const tempData = {
                data: finalData1,
            };
            const jsonAutomation = {
                "EntityType": enityName,
                "State": state,
                "Payload": finalData1
            }



            let d = new Date();
            const futureDate = new Date(d);
            futureDate.setDate(d.getDate() + 7);
            console.log(futureDate)

            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();

            let day1 = futureDate.getDate();
            let month1 = futureDate.getMonth() + 1;
            let year1 = futureDate.getFullYear();


            if (day < 10) {
                day = '0' + day;
            }
            if (month < 10) {
                month = `0${month}`;
            }
            else {
                month = month
            }
            let newDate = year + "-" + month + "-" + day


            if (day1 < 10) {
                day1 = '0' + day1;
            }
            if (month < 10) {
                month1 = `0${month1}`;
            }
            else {
                month1 = month1
            }
            let newDate1 = year1 + "-" + month1 + "-" + day1
            CreditCardServices.createAlabamaLLC(tempData, mainModel.schema.pluralName)
                .then((res) => {
                    console.log(res)

                    localStorage.setItem("automation", JSON.stringify(jsonAutomation))
                    const appdbdata = {
                        "contactId": contactId,
                        "statusId": 8,
                        "orderAmount": parseFloat(totalAmount),
                        "orderDate": newDate,
                        "tentativeFulfillmentDate": newDate1,
                        "actualCompletionDate": null,
                        "strapiOrderFormJson": JSON.stringify(jsonAutomation),
                        "strapiOrderId": res.data.data.id,
                        "strapiOrderType": "Standard",
                        "orderTypeId": enityName ? enityName.id : null,
                        "entityTypeId": enityName ? enityName.id : null,
                        "domicileStateId": state ? state.id : null,
                        "lineItemValue": "LLC",
                        "lineItemName": "19"

                    }
                    OrderServices.saveAllOrder(appdbdata)
                        .then((res) => {
                            console.log(res)
                            const invoiceData = {
                                "orderId": res.data,
                                "invoiceNo": 1234567890,
                                "totalAmount": parseFloat(totalAmount),
                                "statusId": 9
                            }
                            console.log(invoiceData)
                            OrderServices.saveAllInvoice(invoiceData)
                                .then((res) => {
                                    console.log(res)
                                    setCloseProgresbar(true)
                                })
                                .catch((err) => {
                                    console.log(err)
                                    setCloseProgresbar1(true)
                                })
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
                .catch((err) => {
                    console.log(err)

                })
        }

        // const temps = modelData1.map(async (val) => {
        //     const tempData = {
        //         data: val.data,
        //     };
        //     const response = await CreditCardServices.createAlabamaLLC(
        //         tempData,
        //         val.model
        //     );

        //     const tempdata = val.model.replace(/-/g, "_");
        //     console.log(response);
        //     return {
        //         [tempdata]: response.data !== undefined ? response.data : {},
        //     };
        // });

        // console.log(temps);

        // Promise.all(temps)
        //     .then((results) => {
        //         console.log(results);

        //         const result = results.reduce((acc, obj) => {
        //             const key = Object.keys(obj)[0];




        //             acc[key] = obj[key];
        //             return acc;
        //         }, {});
        //         console.log(result);
        //         const tempArr = Object.keys(result).map((key, i) => {
        //             return result[key]
        //         })
        //         if (tempArr.length > 0) {
        //             console.log(tempArr[0])
        //             // const decryptData = encryption.decryptData(tempArr[0]);
        //             // console.log(decryptData.data)
        //             // const temp = {
        //             //     data: decryptData.data,
        //             // };
        //             // console.log(temp)
        //             CreditCardServices.createAlabamaLLC(tempArr[0], `${modelName}s`)
        //                 .then((res) => {
        //                     console.log(res);

        //                 })
        //                 .catch((err) => {
        //                     console.log(err);

        //                 });
        //         }

        //     })
        //     .catch((err) => {
        //         console.log(err);
        //         setCloseProgresbar1(true)
        //     });
    };

    const convertToTitleCase = (str) => {
        return str
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2").replace(/_/g, ' ');
    };

    const handleChecked1 = (e, data, checkboxIndex) => {
        const { checked } = e.target;

        if (checked) {
            // Add data to selectedCheckBoxData
            setSelectedCheckBoxData((prev) => [...prev, data]);
        } else {
            // Remove data from selectedCheckBoxData
            setSelectedCheckBoxData((prev) =>
                prev.filter((item) => item.service !== data.service)
            );
        }

        // Update individual checkbox state
        if (checkboxIndex === 1) {
            setIsChecked1(checked);
        } else if (checkboxIndex === 2) {
            setIsChecked2(checked);
        } else if (checkboxIndex === 3) {
            setIsChecked3(checked);
        } else if (checkboxIndex === 4) {
            setIsChecked4(checked);
        } else if (checkboxIndex === 5) {
            setIsChecked5(checked);
        } else if (checkboxIndex === 6) {
            setIsChecked6(checked);
        } else if (checkboxIndex === 7) {
            setIsChecked7(checked);
        } else if (checkboxIndex === 8) {
            setIsChecked8(checked);
        } else if (checkboxIndex === 9) {
            setIsChecked9(checked);
        } else if (checkboxIndex === 10) {
            setIsChecked10(checked);
        }
    };

    // const totalAmount = selectCheckBoxData.reduce((total, item) => {

    //     const amount = parseFloat(item.amount.replace("$", ""));
    //     return total + amount;
    // }, 0);


    const toPascalCase = (str) => {
        return str.replace(/-/g, ' ').replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const removeDuplicates = (data) => {
        const result = {};

        Object.keys(data).forEach((key) => {
            // Create a Set to store unique `uid` values
            const uniqueItems = new Map();

            data[key].form.forEach(item => {
                // Use `uid` as the key to ensure uniqueness
                if (!uniqueItems.has(item.uid)) {
                    uniqueItems.set(item.uid, item);
                }
            });

            // Convert the Map values back to an array
            result[key] = {
                mode: data[key].mode,
                form: Array.from(uniqueItems.values())
            }
        });

        return result;
    };


    const groupsedData = inputFormData.reduce((acc, value) => {
        console.log(value)
        if (typeof (value.schema.attributes) === "object") {
            console.log(value.schema.attributes)
            const tmp = value.schema.attributes
            if (tmp !== undefined) {
                const temp = Object.keys(tmp).map((key, i) => {
                    console.log(key)
                    Object.keys(groupInitialData).map((keys, m) => {
                        if (key === keys && groupInitialData[keys].type === "component" && groupInitialData[keys].repeatable === true) {
                            console.log('first')
                            if (tmp[key].schema.attributes !== undefined) {
                                if (typeof (tmp[key].schema.attributes) === "object") {
                                    const tempdata = Object.keys(tmp[key].schema.attributes).map((keyval, i) => {
                                        if (tmp[key].schema.attributes[keyval].uid !== undefined) {
                                            return tmp[key].schema.attributes[keyval]

                                        }
                                        else {
                                            return tmp[key]
                                        }
                                    })
                                    console.log(tempdata)
                                    acc[key] = {
                                        form: tempdata,
                                        mode: {
                                            repeat: groupInitialData[keys].repeatable
                                        }
                                    }
                                    console.log(acc)
                                }
                            }
                        }
                        else {
                            if (tmp[key].schema.attributes !== undefined && groupInitialData[keys].repeatable === false && key === keys) {
                                if (typeof (tmp[key].schema.attributes) === "object") {
                                    const tempdata = Object.keys(tmp[key].schema.attributes).map((keyval, i) => {
                                        if (tmp[key].schema.attributes[keyval].uid !== undefined) {
                                            return tmp[key].schema.attributes[keyval]

                                        }
                                        else {
                                            return tmp[key]
                                        }
                                    })
                                    console.log(tempdata)
                                    acc[key] = {
                                        form: tempdata,
                                        mode: null
                                    }
                                }
                            }
                        }

                    })

                })
            }

        }
        // if (value.apiID === "entity-detail" || value.apiID === "address") {
        //     if (!acc["contact_and_address"]) {
        //         acc["contact_and_address"] = [];
        //     }
        //     acc["contact_and_address"].push(value);
        // } else {
        //     acc[value.apiID] = [value];
        // }

        return acc;
    }, {});

    console.log(groupsedData)
    console.log(groupInitialData)



    const groupedData = removeDuplicates(groupsedData);
    console.log(groupedData)
    useEffect(() => {
        const temp = Object.keys(groupedData).map((key, i) => {
            console.log(groupedData[key])
            return groupedData[key].form
        })
        console.log(temp)
        const tempdata = temp.flat()
        console.log(tempdata)
        setAllModels(tempdata)
    }, [enityName, state, currentTab])


    console.log(formCount)


    return (
        <div>
            {loading1 ? (
                <div className="spinner">

                    <div className="loader"></div>
                </div>
            ) : (
                <div className="data-container">



                    <div className="grid font-fam-for-all">

                        <div className="col-12 col-sm-12 col-md-12 col-lg-8" >
                            <div className="card-container-wizard font-fam-for-all">
                                <div className="wizard-header">
                                    <div className="wizard-header-container">
                                        <div className="page-wizard-header">
                                            <div className="page-wizard-content">
                                                <div className="page-text-header">
                                                    <div className="page-text-header-p">Entity Formation</div>
                                                    <div className="page-text-header-p1">
                                                        Register your company online in minutes. Complete the
                                                        following steps to get started.
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="wizard-content-container">
                                        <div className="wizard-content-main">
                                            <div className="wizard-content-frame">
                                                <div className="wizard-content-card">

                                                    <div className="wizard-form-card-header-container">
                                                        <div className="wizard-form-card-header-actiob-container">
                                                            <div className="wizard-form-card-frame">
                                                                {inputFormData.length > 0 ? (
                                                                    <div className="wizard-form-card-header-text1">
                                                                        {`Step ${currentStep} of ${inputFormData.length + 5
                                                                            }`}
                                                                    </div>
                                                                ) : (
                                                                    <div className="wizard-form-card-header-text1">
                                                                        {`Step 1 of 1`}
                                                                    </div>
                                                                )}
                                                                {inputFormData.length > 0 ? (
                                                                    <div className="wizard-form-card-header-text2">
                                                                        Total due today
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className="wizard-form-card-frame">
                                                                <div className="wizard-form-card-header-text3">
                                                                    Entity Details
                                                                </div>
                                                                {inputFormData.length > 0 ? (
                                                                    <div className="wizard-form-card-header-text4">
                                                                        {`$${totalAmount}`}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            marginTop: "12px",
                                                            textAlign: "center",
                                                            marginBottom: "10px",
                                                            display: "flex",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <span class="step">
                                                            <div className="mt-2">Entity Details</div>
                                                        </span>
                                                        {Object.keys(groupedData).map((key, i) => (
                                                            <span class="step">
                                                                <div className="mt-2 wizard-step-content">{toPascalCase(key)}</div>
                                                            </span>
                                                        ))}

                                                        {inputFormData.length > 0 ? (
                                                            <>


                                                                <span class="step">
                                                                    <div className="mt-2">Preview</div>
                                                                </span>

                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>

                                                    <div className="wizard-main-content font-fam-for-all mt-4">
                                                        <div className="wizard-main-sub-content">
                                                            <form
                                                                className="form-wizard"
                                                                onSubmit={handleSubmit(onSubmit)}
                                                            >
                                                                <div className="tab hover-card slide-in font-fam-for-all">
                                                                    <div
                                                                        className="wizard-content-sub-card-header my-3"
                                                                        id="header"
                                                                    >
                                                                        <div className="wizard-content-sub-card-text-frame">
                                                                            <div className="wizard-content-sub-card-text">
                                                                                Select State & Entity
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="flex flex-column justify-content-center">


                                                                        <div className="input-fields-main ">
                                                                            <div className="grid w-full justify-content-center">
                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                                                    <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                        <div className="field">
                                                                                            <label
                                                                                                htmlFor=""
                                                                                                className="form-label font-fam-for-all"
                                                                                            >
                                                                                                Select Entity Type
                                                                                                <span className="form-field-mandatory">
                                                                                                    *
                                                                                                </span>
                                                                                            </label>
                                                                                            <span className="p-float-label ">
                                                                                                <Controller
                                                                                                    name="state"
                                                                                                    control={control}
                                                                                                    render={({ field }) => (
                                                                                                        <Dropdown

                                                                                                            value={enityName}
                                                                                                            options={entityTypes}
                                                                                                            onChange={(e) => setEntityName(e.value)}
                                                                                                            optionLabel="orderShortName"
                                                                                                            placeholder="Select Entity Type"
                                                                                                            style={{
                                                                                                                width: "100%",
                                                                                                                height: "47px",
                                                                                                            }}

                                                                                                            filter
                                                                                                            className="dialog-form-input-field-wizard"
                                                                                                            required
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                                                    <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                        <div className="field">
                                                                                            <label
                                                                                                htmlFor=""
                                                                                                className="form-label font-fam-for-all"
                                                                                            >
                                                                                                State of formation{" "}
                                                                                                <span className="form-field-mandatory">
                                                                                                    *
                                                                                                </span>
                                                                                            </label>
                                                                                            <span className="p-float-label ">
                                                                                                <Controller
                                                                                                    name="state"
                                                                                                    control={control}
                                                                                                    render={({ field }) => (
                                                                                                        <Dropdown
                                                                                                            value={field.value}
                                                                                                            id={field.name}
                                                                                                            options={allState}
                                                                                                            onChange={(e) => {
                                                                                                                field.onChange(e.value);
                                                                                                                setState(e.value);
                                                                                                            }}
                                                                                                            optionLabel="stateFullDesc"
                                                                                                            style={{
                                                                                                                width: "100%",
                                                                                                                height: "47px",
                                                                                                            }}
                                                                                                            placeholder="Select State"
                                                                                                            filter
                                                                                                            className="dialog-form-input-field-wizard"
                                                                                                            required
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div
                                                                            className="wizard-content-sub-card-header my-3"
                                                                            id="header"
                                                                        >
                                                                            <div className="wizard-content-sub-card-text-frame">
                                                                                <div className="wizard-content-sub-card-text">
                                                                                    Select services you want to avail
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {servicesData.map((serviceData, index) => (
                                                                            <div
                                                                                key={index}
                                                                                className="wizard-card-checkbox-container hover-card slide-in"
                                                                            >
                                                                                <div className="wizard-card-checkbox-main pb-0">
                                                                                    <div className="wizard-card-checkbox-text-main">
                                                                                        <div className="wizard-card-checkbox-action-container">
                                                                                            <Checkbox
                                                                                                className="mt-2"
                                                                                                checked={checkedState[serviceData.service] || false}
                                                                                                onChange={(e) =>
                                                                                                    handleCheckedChange(e, serviceData)
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        <div className="w-full grid">
                                                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-11 p-3">
                                                                                                <div className="wizard-card-checkbox-text1 font-fam-for-all text-start">
                                                                                                    <div className="flex align-items-center">
                                                                                                        {serviceData.service}
                                                                                                        <button
                                                                                                            className="ml-2 bg-transparent border-none cursor-pointer"
                                                                                                            data-bs-toggle="tooltip"
                                                                                                            data-bs-placement="right"
                                                                                                            title={serviceData.description}
                                                                                                        >
                                                                                                            <i
                                                                                                                className="pi pi-info-circle"
                                                                                                                style={{
                                                                                                                    fontSize: "1.2rem",
                                                                                                                    color: "#007bff",
                                                                                                                }}
                                                                                                            ></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="wizard-card-checkbox-text2 col-12 col-sm-12 col-md-12 col-lg-1 flex align-items-center">
                                                                                                {serviceData.amount}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>



                                                                {Object.keys(groupedData).map((key, i) => (
                                                                    <div className="tab" key={i}>
                                                                        <div className="wizard-content-sub-card-header my-3" id="header">
                                                                            <div className="wizard-content-sub-card-text-frame">
                                                                                <div className="wizard-content-sub-card-text">
                                                                                    To comply with state filling requirements,
                                                                                    please provide the following details
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        {
                                                                            groupedData[key].mode && groupedData[key].mode.repeat === true ? <div>
                                                                                <div className="vstate-card p-4 mb-3" >
                                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0">
                                                                                        <h2 className="entity-header-strapi">{toPascalCase(groupedData[key].form[0].apiId)}</h2>
                                                                                        {groupedData[key].mode && groupedData[key].mode.repeat === true}
                                                                                    </div>
                                                                                    {
                                                                                        Array.from({ length: formCount }).map((_, l) => (
                                                                                            groupedData[key].form.map((value, j) => (
                                                                                                <>
                                                                                                    <div>

                                                                                                        {

                                                                                                            <div className="vstate-card p-4 mb-3">
                                                                                                                <div className="grid" key={l}>

                                                                                                                    {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6" key={k}>
                                                                                                                            {value.schema.attributes[val].type !== undefined
                                                                                                                                ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value)
                                                                                                                                : ""}
                                                                                                                        </div>
                                                                                                                    ))}
                                                                                                                </div>
                                                                                                            </div>

                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="card-devider"></div>
                                                                                                </>


                                                                                            ))
                                                                                        ))
                                                                                    }

                                                                                </div>


                                                                                {groupedData[key].mode && groupedData[key].mode.repeat === true && (
                                                                                    <div className="flex justify-content-center w-full">
                                                                                        <Button type="button" icon="pi pi-plus" label="Add Entry" className="vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => handleRepeatForm()}></Button>

                                                                                    </div>
                                                                                )}
                                                                            </div> : groupedData[key].form.map((value, j) => (
                                                                                <>
                                                                                    <div className="vstate-card p-4 mb-3">


                                                                                        <div className="grid " key={j}>

                                                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 pb-0">
                                                                                                <h2 className="entity-header-strapi">{toPascalCase(value.apiId)}</h2>

                                                                                            </div>
                                                                                            {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6" key={k}>
                                                                                                    {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, val, value) : ""}
                                                                                                </div>
                                                                                            ))}
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            ))}
                                                                        {/* <Box >
                                                                <Stepper activeStep={activeStep} orientation="vertical">
                                                                    {groupedData[key].map((value, j) => (
                                                                        <Step key={value.apiID}>
                                                                            <StepLabel
                                                                                optional={
                                                                                    j === 2 ? (
                                                                                        <Typography variant="caption">Last step</Typography>
                                                                                    ) : null
                                                                                }
                                                                            >
                                                                                {toPascalCase(value.apiID)}
                                                                            </StepLabel>
                                                                            <StepContent>
                                                                                <div className="grid">
                                                                                    {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                        <div className="col-4" key={k}>
                                                                                            {renderFormField(value.schema.attributes[val], val)}
                                                                                        </div>
                                                                                    ))}
                                                                                </div>

                                                                                <Box sx={{ mb: 2 }}>
                                                                                    <div className="flex justify-content-end pl-3 gap-2 dialog-form-md-group-Btn">
                                                                                        <Button
                                                                                            className="vstate-button"
                                                                                            id="nextBtn"
                                                                                            onClick={() => handleNext(groupedData[key])}
                                                                                            sx={{ mt: 1, mr: 1 }}
                                                                                        >
                                                                                            {j === groupedData[key].length - 1 ? 'Next Page' : 'Next'}
                                                                                        </Button>
                                                                                        <Button
                                                                                            disabled={j === 0}
                                                                                            id="prevBtn"
                                                                                            onClick={handleBack}
                                                                                            className="vstate-reverse-button"
                                                                                            sx={{ mt: 1, mr: 1 }}
                                                                                        >
                                                                                            Back
                                                                                        </Button>
                                                                                    </div>
                                                                                </Box>
                                                                            </StepContent>
                                                                        </Step>
                                                                    ))}
                                                                </Stepper>
                                                                {activeStep === steps.length && (
                                                                    <Paper square elevation={0} sx={{ p: 3 }}>
                                                                        <Typography>All steps completed - you&apos;re finished</Typography>
                                                                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                                                                            Reset
                                                                        </Button>
                                                                    </Paper>
                                                                )}
                                                            </Box> */}
                                                                    </div>
                                                                ))}

                                                                {inputFormData.length > 0 ? (
                                                                    <>

                                                                        {/* <div class="tab">
                                                                <div
                                                                    className="wizard-content-sub-card-header my-3"
                                                                    id="header"
                                                                >
                                                                    <div className="wizard-content-sub-card-text-frame">
                                                                        <div className="wizard-content-sub-card-text">
                                                                            Registered Agent Service{" "}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="wizard-card-checkbox-container">
                                                                    <div className="wizard-card-checkbox-main">
                                                                        <div className="wizard-card-checkbox-text-main">
                                                                            <div className="wizard-card-checkbox-text-sub-main">
                                                                                <div className="wizard-card-checkbox-text-container">
                                                                                    <div className="wizard-card-checkbox-text1">
                                                                                        Check here if you would like vState
                                                                                        Fillings to act as your Registered
                                                                                        Agent (Annual renewal applied)
                                                                                    </div>
                                                                                    <div className="wizard-card-checkbox-text2">
                                                                                        $99
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizard-card-checkbox-action-container">
                                                                                <Checkbox
                                                                                    className="mt-2"
                                                                                    checked={IsChecked10}
                                                                                    onChange={(e) =>
                                                                                        handleChecked1(
                                                                                            e,
                                                                                            {
                                                                                                service:
                                                                                                    "Registered Agent Service",
                                                                                                amount: "$99",
                                                                                            },
                                                                                            10
                                                                                        )
                                                                                    }
                                                                                ></Checkbox>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {IsChecked10 === false ? (
                                                                    <>
                                                                        <div className="input-fields-container">
                                                                            <div className="input-fields-main">
                                                                                <div className=" justify-content-center dialog-form-field-space ">
                                                                                    <div className="field">
                                                                                        <label
                                                                                            htmlFor=""
                                                                                            className="form-label font-fam-for-all"
                                                                                        >
                                                                                            Name of Registered Agent{" "}
                                                                                            <span className="form-field-mandatory">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <span className="p-float-label ">
                                                                                            <InputText
                                                                                                className="dialog-form-input-field-wizard"
                                                                                                maxLength={50}
                                                                                                name="cityName"
                                                                                            />
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="input-fields-main">
                                                                                <div className=" justify-content-center dialog-form-field-space ">
                                                                                    <div className="field">
                                                                                        <label
                                                                                            htmlFor=""
                                                                                            className="form-label font-fam-for-all"
                                                                                        >
                                                                                            Address Registered Agent{" "}
                                                                                            <span className="form-field-mandatory">
                                                                                                *
                                                                                            </span>
                                                                                        </label>
                                                                                        <span className="p-float-label ">
                                                                                            <InputText
                                                                                                className="dialog-form-input-field-wizard"
                                                                                                maxLength={50}
                                                                                                name="cityName"
                                                                                            />
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="wizard-content-checkbox-container">
                                                                            <div>
                                                                                <Checkbox
                                                                                    className="mt-2"
                                                                                    checked={IsChecked11}
                                                                                    onChange={(e) =>
                                                                                        setIsChecked11(!IsChecked11)
                                                                                    }
                                                                                ></Checkbox>
                                                                            </div>
                                                                            <div className="wizard-content-checkbox-text">
                                                                                Autorize Vstate Fillings LLC to charge
                                                                                the credit card provided below an annual
                                                                                recurring fee of $99 for Registered
                                                                                Agent services. Non-payment of the
                                                                                annual fee will result in Interstate
                                                                                Filings' resignation as the
                                                                                corporation’s Registered Agent.
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div> */}
                                                                        {/* <div class="tab">
                                                                <div
                                                                    className="wizard-content-sub-card-header my-3"
                                                                    id="header"
                                                                >
                                                                    <div className="wizard-content-sub-card-text-frame">
                                                                        <div className="wizard-content-sub-card-text">
                                                                            LLC Formation Fee Schedule{" "}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="wizard-card-checkbox-container mb-4 hover-card slide-in">
                                                                    <div className="wizard-card-checkbox-main">
                                                                        <div className="wizard-card-checkbox-text-main">
                                                                            <div className="wizard-card-checkbox-text-sub-main">
                                                                                <div className="wizard-card-checkbox-text-container">
                                                                                    <div className="wizard-card-checkbox-text1">
                                                                                        State Filling Fee
                                                                                    </div>
                                                                                    <div className="wizard-card-checkbox-text2">
                                                                                        $230
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizard-card-checkbox-action-container">
                                                                                <Checkbox
                                                                                    className="mt-2"
                                                                                    checked={IsChecked1}
                                                                                    onChange={(e) =>
                                                                                        handleChecked1(
                                                                                            e,
                                                                                            {
                                                                                                service: "State Filling Fee",
                                                                                                amount: "$230",
                                                                                            },
                                                                                            1
                                                                                        )
                                                                                    }
                                                                                ></Checkbox>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="wizard-card-radio-container mb-4">
                                                                    <div className="wizard-card-radio-main">
                                                                        <div className="wizard-card-radio-main1">
                                                                            <div className="wizard-card-radio">
                                                                                <div className="wizard-card-content">
                                                                                    <div className="wizard-card-radio-sub-content">
                                                                                        <div className="wizard-card-radio-text-main">
                                                                                            <div className="wizard-card-radio-text1">
                                                                                                24-hour Expedited Service
                                                                                            </div>
                                                                                            <div className="wizard-card-radio-text2">
                                                                                                $50
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Checkbox
                                                                                        className="mt-2"
                                                                                        checked={IsChecked7}
                                                                                        onChange={(e) =>
                                                                                            handleChecked1(
                                                                                                e,
                                                                                                {
                                                                                                    service:
                                                                                                        "24-hour Expedited Service",
                                                                                                    amount: "$50",
                                                                                                },
                                                                                                7
                                                                                            )
                                                                                        }
                                                                                    ></Checkbox>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="wizard-card-radio-main">
                                                                        <div className="wizard-card-radio-main1">
                                                                            <div className="wizard-card-radio">
                                                                                <div className="wizard-card-content">
                                                                                    <div className="wizard-card-radio-sub-content">
                                                                                        <div className="wizard-card-radio-text-main">
                                                                                            <div className="wizard-card-radio-text1">
                                                                                                Same Day Service
                                                                                            </div>
                                                                                            <div className="wizard-card-radio-text2">
                                                                                                $360
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Checkbox
                                                                                        className="mt-2"
                                                                                        checked={IsChecked8}
                                                                                        onChange={(e) =>
                                                                                            handleChecked1(
                                                                                                e,
                                                                                                {
                                                                                                    service: "Same Day Service",
                                                                                                    amount: "$360",
                                                                                                },
                                                                                                8
                                                                                            )
                                                                                        }
                                                                                    ></Checkbox>{" "}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="wizard-card-radio-main">
                                                                        <div className="wizard-card-radio-main1">
                                                                            <div className="wizard-card-radio">
                                                                                <div className="wizard-card-content">
                                                                                    <div className="wizard-card-radio-sub-content">
                                                                                        <div className="wizard-card-radio-text-main">
                                                                                            <div className="wizard-card-radio-text1">
                                                                                                2-hour Service
                                                                                            </div>
                                                                                            <div className="wizard-card-radio-text2">
                                                                                                $660
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Checkbox
                                                                                        className="mt-2"
                                                                                        checked={IsChecked9}
                                                                                        onChange={(e) =>
                                                                                            handleChecked1(
                                                                                                e,
                                                                                                {
                                                                                                    service: "2-hour Service",
                                                                                                    amount: "$660",
                                                                                                },
                                                                                                9
                                                                                            )
                                                                                        }
                                                                                    ></Checkbox>{" "}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="wizard-card-checkbox-container mb-4">
                                                                    <div className="wizard-card-checkbox-main">
                                                                        <div className="wizard-card-checkbox-text-main">
                                                                            <div className="wizard-card-checkbox-text-sub-main">
                                                                                <div className="wizard-card-checkbox-text-container">
                                                                                    <div className="wizard-card-checkbox-text1">
                                                                                        Certified Copies{" "}
                                                                                    </div>
                                                                                    <div className="wizard-card-checkbox-text2">
                                                                                        $230
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizard-card-checkbox-action-container">
                                                                                <Checkbox
                                                                                    className="mt-2"
                                                                                    checked={IsChecked2}
                                                                                    onChange={(e) =>
                                                                                        handleChecked1(
                                                                                            e,
                                                                                            {
                                                                                                service: "Certified Copies",
                                                                                                amount: "$230",
                                                                                            },
                                                                                            2
                                                                                        )
                                                                                    }
                                                                                ></Checkbox>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="wizard-card-checkbox-container mb-4">
                                                                    <div className="wizard-card-checkbox-main">
                                                                        <div className="wizard-card-checkbox-text-main">
                                                                            <div className="wizard-card-checkbox-text-sub-main">
                                                                                <div className="wizard-card-checkbox-text-container">
                                                                                    <div className="wizard-card-checkbox-text1">
                                                                                        EIN (Tax ID #){" "}
                                                                                    </div>
                                                                                    <div className="wizard-card-checkbox-text2">
                                                                                        $45
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizard-card-checkbox-action-container">
                                                                                <Checkbox
                                                                                    className="mt-2"
                                                                                    checked={IsChecked3}
                                                                                    onChange={(e) =>
                                                                                        handleChecked1(
                                                                                            e,
                                                                                            {
                                                                                                service: "EIN (Tax ID #)",
                                                                                                amount: "$45",
                                                                                            },
                                                                                            3
                                                                                        )
                                                                                    }
                                                                                ></Checkbox>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="wizard-card-checkbox-container mb-4">
                                                                    <div className="wizard-card-checkbox-main">
                                                                        <div className="wizard-card-checkbox-text-main">
                                                                            <div className="wizard-card-checkbox-text-sub-main">
                                                                                <div className="wizard-card-checkbox-text-container">
                                                                                    <div className="wizard-card-checkbox-text1">
                                                                                        Preparation of S Corp Election Form
                                                                                        (Form 2553 for IRS){" "}
                                                                                    </div>
                                                                                    <div className="wizard-card-checkbox-text2">
                                                                                        $100
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizard-card-checkbox-action-container">
                                                                                <Checkbox
                                                                                    className="mt-2"
                                                                                    checked={IsChecked4}
                                                                                    onChange={(e) =>
                                                                                        handleChecked1(
                                                                                            e,
                                                                                            {
                                                                                                service:
                                                                                                    "Preparation of S Corp Election Form (Form 2553 for IRS)",
                                                                                                amount: "$100",
                                                                                            },
                                                                                            4
                                                                                        )
                                                                                    }
                                                                                ></Checkbox>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="wizard-card-checkbox-container mb-4">
                                                                    <div className="wizard-card-checkbox-main">
                                                                        <div className="wizard-card-checkbox-text-main">
                                                                            <div className="wizard-card-checkbox-text-sub-main">
                                                                                <div className="wizard-card-checkbox-text-container">
                                                                                    <div className="wizard-card-checkbox-text1">
                                                                                        BOI (Beneficial Ownership
                                                                                        Information) with initial filling -
                                                                                        after filling $99 (Required within
                                                                                        90 days of formation){" "}
                                                                                    </div>
                                                                                    <div className="wizard-card-checkbox-text2">
                                                                                        $59
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizard-card-checkbox-action-container">
                                                                                <Checkbox
                                                                                    className="mt-2"
                                                                                    checked={IsChecked5}
                                                                                    onChange={(e) =>
                                                                                        handleChecked1(
                                                                                            e,
                                                                                            {
                                                                                                service:
                                                                                                    "BOI (Beneficial Ownership Information) with initial filling - after filling $99 (Required within 90 days of formation)",
                                                                                                amount: "$59",
                                                                                            },
                                                                                            5
                                                                                        )
                                                                                    }
                                                                                ></Checkbox>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div> */}
                                                                        {/* <div class="tab">
                                                                <div
                                                                    className="wizard-content-sub-card-header my-3"
                                                                    id="header"
                                                                >
                                                                    <div className="wizard-content-sub-card-text-frame">
                                                                        <div className="wizard-content-sub-card-text">
                                                                            What's included in your package{" "}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="wizard-checkout-container">
                                                                    <div className="wizard-checkout-main">
                                                                        <div className="wizard-checkout-header justify-content-between">
                                                                            <div className="wizard-checkout-text-main">
                                                                                <div className="wizard-checkout-text">
                                                                                    CART SUMMARY
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex">
                                                                                <i className="pi pi-pencil"></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="wizard-checkout-body">
                                                                            {selectCheckBoxData.map((val) => (
                                                                                <>
                                                                                    <div className="wizard-checkout-content-main">
                                                                                        <div className="wizard-checkout-content-price-main">
                                                                                            <div className="wizard-price-content1">
                                                                                                {val.service}
                                                                                            </div>
                                                                                            <div className="wizard-price-content2">
                                                                                                {val.amount}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="wizard-card-devider"></div>
                                                                                </>
                                                                            ))}
                                                                        </div>

                                                                        <div className="wizard-checkout-footer">
                                                                            <div className="wizard-checkout-text-footer">
                                                                                <div className="wizard-checkout-footer-txt">
                                                                                    TOTAL (TAX DEDUCTABLE)
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizard-total-price">
                                                                                {`$${totalAmount}`}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                                        {/* <div className="tab">
                                                                <div
                                                                    className="wizard-content-sub-card-header mb-3"
                                                                    id="header"
                                                                >
                                                                    <div className="wizard-content-sub-card-text-frame">
                                                                        <div className="wizard-content-sub-card-text">
                                                                            Make Payment{" "}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="wizard-checkoust-container hover-card slide-in">
                                                                    <div className="wizard-checkout-main">
                                                                        <div className="wizard-checkout-header">
                                                                            <div
                                                                                className="wizard-checkout-text-main"
                                                                                style={{ flex: "1 0 0" }}
                                                                            >
                                                                                <div className="wizard-checkout-footer-txt">
                                                                                    TOTAL (TAX DEDUCTABLE)
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizard-total-price">
                                                                                {`$${totalAmount}`}
                                                                            </div>
                                                                        </div>

                                                                        <div className="wizard-checkout-body">
                                                                            <TabView>
                                                                                <TabPanel header="Credit Card">
                                                                                    <div className="wizard-payment-container">
                                                                                        <div className="grid">
                                                                                            {initData2 !== null
                                                                                                ? Object.keys(initData2).map(
                                                                                                    (val, i) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <div className="col-6">
                                                                                                                    {renderFormField1(
                                                                                                                        initData2[val],
                                                                                                                        val
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </>
                                                                                                        );
                                                                                                    }
                                                                                                )
                                                                                                : ""}
                                                                                        </div>
                                                                                    </div>
                                                                                </TabPanel>
                                                                                <TabPanel header="Debit Card">
                                                                                    <div className="wizard-payment-container">
                                                                                        <div className="input-fields-container">
                                                                                            <div className="input-fields-main">
                                                                                                <div className=" justify-content-center dialog-form-field-space ">
                                                                                                    <div className="">
                                                                                                        <label
                                                                                                            htmlFor=""
                                                                                                            className="form-label font-fam-for-all"
                                                                                                        >
                                                                                                            Name on card{" "}
                                                                                                            <span className="form-field-mandatory">
                                                                                                                *
                                                                                                            </span>
                                                                                                        </label>
                                                                                                        <span className="p-float-label ">
                                                                                                            <InputText
                                                                                                                style={{
                                                                                                                    width: "435px",
                                                                                                                    height: "40px",
                                                                                                                }}
                                                                                                                maxLength={50}
                                                                                                                name="cityName"
                                                                                                            />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="input-fields-main">
                                                                                                <div className=" justify-content-center dialog-form-field-space ">
                                                                                                    <div className="">
                                                                                                        <label
                                                                                                            htmlFor=""
                                                                                                            className="form-label font-fam-for-all"
                                                                                                        >
                                                                                                            Card number{" "}
                                                                                                            <span className="form-field-mandatory">
                                                                                                                *
                                                                                                            </span>
                                                                                                        </label>
                                                                                                        <span className="p-float-label ">
                                                                                                            <span className="p-input-icon-left">
                                                                                                                <i className="pi pi-credit-card" />
                                                                                                                <InputText
                                                                                                                    style={{
                                                                                                                        width: "435px",
                                                                                                                        height: "40px",
                                                                                                                    }}
                                                                                                                    maxLength={50}
                                                                                                                    name="cityName"
                                                                                                                />
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="input-fields-container">
                                                                                            <div className="input-fields-main">
                                                                                                <div className=" justify-content-center dialog-form-field-space ">
                                                                                                    <div className="">
                                                                                                        <label
                                                                                                            htmlFor=""
                                                                                                            className="form-label font-fam-for-all"
                                                                                                        >
                                                                                                            Expiry Date{" "}
                                                                                                            <span className="form-field-mandatory">
                                                                                                                *
                                                                                                            </span>
                                                                                                        </label>
                                                                                                        <span className="p-float-label ">
                                                                                                            <InputText
                                                                                                                style={{
                                                                                                                    width: "435px",
                                                                                                                    height: "40px",
                                                                                                                }}
                                                                                                                maxLength={50}
                                                                                                                name="cityName"
                                                                                                            />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="input-fields-main">
                                                                                                <div className=" justify-content-center dialog-form-field-space ">
                                                                                                    <div className="">
                                                                                                        <label
                                                                                                            htmlFor=""
                                                                                                            className="form-label font-fam-for-all"
                                                                                                        >
                                                                                                            CVC{" "}
                                                                                                            <span className="form-field-mandatory">
                                                                                                                *
                                                                                                            </span>
                                                                                                        </label>
                                                                                                        <span className="p-float-label ">
                                                                                                            <InputText
                                                                                                                style={{
                                                                                                                    width: "435px",
                                                                                                                    height: "40px",
                                                                                                                }}
                                                                                                                maxLength={50}
                                                                                                                name="cityName"
                                                                                                            />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="input-fields-container">
                                                                                            <div className="input-fields-main">
                                                                                                <div className=" justify-content-center dialog-form-field-space ">
                                                                                                    <div className="">
                                                                                                        <label
                                                                                                            htmlFor=""
                                                                                                            className="form-label font-fam-for-all"
                                                                                                        >
                                                                                                            Billing Address{" "}
                                                                                                            <span className="form-field-mandatory">
                                                                                                                *
                                                                                                            </span>
                                                                                                        </label>
                                                                                                        <span className="p-float-label ">
                                                                                                            <InputText
                                                                                                                style={{
                                                                                                                    width: "435px",
                                                                                                                    height: "40px",
                                                                                                                }}
                                                                                                                maxLength={50}
                                                                                                                name="cityName"
                                                                                                            />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="input-fields-main">
                                                                                                <div className=" justify-content-center dialog-form-field-space ">
                                                                                                    <div className="">
                                                                                                        <label
                                                                                                            htmlFor=""
                                                                                                            className="form-label font-fam-for-all"
                                                                                                        >
                                                                                                            City{" "}
                                                                                                            <span className="form-field-mandatory">
                                                                                                                *
                                                                                                            </span>
                                                                                                        </label>
                                                                                                        <span className="p-float-label ">
                                                                                                            <InputText
                                                                                                                style={{
                                                                                                                    width: "435px",
                                                                                                                    height: "40px",
                                                                                                                }}
                                                                                                                maxLength={50}
                                                                                                                name="cityName"
                                                                                                            />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="input-fields-container">
                                                                                            <div className="input-fields-main">
                                                                                                <div className=" justify-content-center dialog-form-field-space ">
                                                                                                    <div className="">
                                                                                                        <label
                                                                                                            htmlFor=""
                                                                                                            className="form-label font-fam-for-all"
                                                                                                        >
                                                                                                            Billing phone{" "}
                                                                                                            <span className="form-field-mandatory">
                                                                                                                *
                                                                                                            </span>
                                                                                                        </label>
                                                                                                        <span className="p-float-label ">
                                                                                                            <InputText
                                                                                                                style={{
                                                                                                                    width: "435px",
                                                                                                                    height: "40px",
                                                                                                                }}
                                                                                                                maxLength={50}
                                                                                                                name="cityName"
                                                                                                            />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="input-fields-main">
                                                                                                <div className=" justify-content-center dialog-form-field-space ">
                                                                                                    <div className="">
                                                                                                        <label
                                                                                                            htmlFor=""
                                                                                                            className="form-label font-fam-for-all"
                                                                                                        >
                                                                                                            Billing email{" "}
                                                                                                            <span className="form-field-mandatory">
                                                                                                                *
                                                                                                            </span>
                                                                                                        </label>
                                                                                                        <span className="p-float-label ">
                                                                                                            <InputText
                                                                                                                style={{
                                                                                                                    width: "435px",
                                                                                                                    height: "40px",
                                                                                                                }}
                                                                                                                maxLength={50}
                                                                                                                name="cityName"
                                                                                                            />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </TabPanel>
                                                                            </TabView>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                                        <div class="tab">
                                                                            <div
                                                                                className="wizard-content-sub-card-header mb-3"
                                                                                id="header"
                                                                            >
                                                                                <div className="wizard-content-sub-card-text-frame">
                                                                                    <div className="wizard-content-sub-card-text">
                                                                                        To comply with state filling requirements,
                                                                                        please provide following details
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {modelData1.map((val) => (
                                                                                <div className="col-12">
                                                                                    <div className="grid">
                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3"></div>
                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-center table-cand">
                                                                                            {val.model}
                                                                                        </div>
                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3"></div>
                                                                                    </div>
                                                                                    {val.data !== null ? (
                                                                                        typeof val.data === "object" ? (
                                                                                            Array.isArray(val.data) ? (
                                                                                                val.data.map((item, index) => (
                                                                                                    <div className="grid mt-2" key={index}>
                                                                                                        {Object.keys(item).map((key, i) => (
                                                                                                            <React.Fragment key={i}>
                                                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-4 ml-4 cand-text table-cand">
                                                                                                                    {convertToTitleCase(key)} :
                                                                                                                </div>
                                                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-7 cand-text">
                                                                                                                    {item[key]}
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                ))
                                                                                            ) : (
                                                                                                Object.keys(val.data).map((key, i) => (
                                                                                                    <div className="grid mt-2" key={i}>
                                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 ml-4 cand-text table-cand">
                                                                                                            {convertToTitleCase(key)} :
                                                                                                        </div>
                                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-7 cand-text">
                                                                                                            {val.data[key]}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))
                                                                                            )
                                                                                        ) : (
                                                                                            "Hello"
                                                                                        )
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </div>
                                                                            ))}

                                                                            <div className='flex justify-content-end pl-3 dialog-form-md-group-Btn'>

                                                                                <Button type='button' className="mt-2 vstate-button font-fam-for-all text-center   justify-content-center" onClick={() => setVisible(true)} >Preview</Button>

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}

                                                                <div className='flex justify-content-end gap-2 pl-3 dialog-form-md-group-Btn' >
                                                                    <Button type='button' id="nextBtn" className="mt-2   vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => nextPrev(1)} >Next</Button>
                                                                    <Button type='submit' id="submitBtn" className="mt-2   vstate-button font-fam-for-all text-center justify-content-center"   >Submit</Button>
                                                                    <Button type='button' id="prevBtn" onClick={() => nextPrev(-1)} className="mt-2 vstate-reverse-button   font-fam-for-all text-center justify-content-center"   >Back</Button>


                                                                </div>


                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 flex flex-column gap-2">
                            <div className=" font-fam-for-all">

                                <div className="wizard-checkout-container">
                                    <div className="wizard-checkout-main">
                                        <div className="wizard-checkout-header justify-content-between">
                                            <div className="wizard-checkout-text-main">
                                                <div className="wizard-checkout-text">
                                                    CART SUMMARY
                                                </div>
                                            </div>
                                            <div className="flex">
                                                <i className="pi pi-pencil"></i>
                                            </div>
                                        </div>

                                        <div className="wizard-checkout-body">

                                            <React.Fragment >
                                                <div className="wizard-checkout-content-main">
                                                    {selectedCheckBoxData.length > 0 ? selectedCheckBoxData.map((val, index) => (
                                                        <div className="wizard-checkout-content-price-main" key={index}>
                                                            <div className="wizard-price-content1">
                                                                {val.service}
                                                            </div>
                                                            <div className="wizard-price-content2">
                                                                {val.amount}
                                                            </div>
                                                        </div>
                                                    )) : <>
                                                        <div className="wizard-price-content1 flex justify-content-center">No items added</div>
                                                    </>}
                                                </div>

                                                {/* <div className="wizard-card-devider"></div> */}
                                            </React.Fragment>

                                        </div>

                                        <div className="wizard-checkout-footer">
                                            <div className="wizard-checkout-text-footer">
                                                <div className="wizard-checkout-footer-txt">
                                                    TOTAL (TAX DEDUCTIBLE)
                                                </div>
                                            </div>
                                            <div className="wizard-total-price">
                                                {`$${totalAmount}`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className=" font-fam-for-all">

                                <div className="wizard-checkout-container">
                                    <div className="wizard-checkout-main">
                                        <div className="wizard-checkout-header justify-content-between">

                                        </div>


                                        <div className="wizard-checkout-footer">

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <AddCity
                            visible={visible}
                            setVisible={setVisible}
                            credit={creditInfo}
                            llc={llc}
                            state={state}
                            enityName={enityName}
                            llcDetails={llcName}
                            modelData={modelData}
                        />
                        <Dialog
                            visible={closeProgresbar}
                            modal={false}
                            onHide={() => setCloseProgresbar(false)}
                            className="font-fam-for-all"
                        >
                            <p className="m-0 p-3">
                                <div className="d-flex align-items-center">
                                    <img src={SuccessImage} width="60px" height="60px" />
                                    {/* <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
                                    <p className="font-fam-for-all successMessage-font">
                                        Order Created Successfully
                                    </p>
                                </div>
                                <div className="flex justify-content-end dialog-form-md-group-Btn">
                                    <Button
                                        type="button"
                                        className="mt-2 form-Btn form-Btn-Label font-fam-for-all  text-lg  mr-2"
                                        onClick={() => history("/order")}
                                    >
                                        Ok
                                    </Button>
                                </div>
                            </p>
                        </Dialog>

                        <Dialog
                            visible={closeProgresbar1}
                            modal={false}
                            onHide={() => setCloseProgresbar1(false)}
                            className="font-fam-for-all"
                        >
                            <p className="m-0 p-3">
                                <div className="d-flex align-items-center">
                                    <img src={ErroImage} width="60px" height="60px" />
                                    {/* <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
                                    <p className="font-fam-for-all successMessage-font">
                                        Invoice Failed
                                    </p>
                                </div>
                                <div className="flex justify-content-end dialog-form-md-group-Btn">
                                    <Button
                                        type="button"
                                        className="mt-2 form-Btn form-Btn-Label font-fam-for-all  text-lg  mr-2"
                                        onClick={() => setCloseProgresbar1(false)}
                                    >
                                        Ok
                                    </Button>
                                </div>
                            </p>
                        </Dialog>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddOrderDemo;
