import React from 'react';

const CompanyRegistrationBenefits = () => {
  const benefits = [
    { title: 'Quick start your business', description: 'Experience the fastest business registration service available. Fill out our form and our team will handle your company registration right away. Your business can be officially registered in a matter of days!' },
    { title: 'We have got you covered!', description: 'By choosing vState Filings to handle your company formation, you are making a strategic decision. We will protect you from potential losses. Our experts have registered thousands of businesses similar to yours across the US.' },
    { title: 'Remote registration', description: 'Enjoy the convenience of remote business registration with us. No need for office visits or lengthy phone waits, no matter where you are or what time it is.' },
    { title: 'Free advisory sessions', description: 'Explore our complimentary consultation services offered with our business formation packages. Our representatives are here to assist you, addressing all your concerns and helping you navigate the process seamlessly.' },
    { title: 'Save money', description: 'We provide the most efficient and cost-effective solution for business formation. Our streamlined process ensures simplicity and speed. Explore it firsthand.' },
    { title: 'Personalized dashboard', description: 'Your dashboard is designed for convenience. Access all your documents, register new companies, choose additional services, or cancel current ones—all from one modern and well-organized platform.' }
  ];

  return (
    <section className="flex flex-col justify-center items-center py-20 mt-7 w-full">
      <div className="flex flex-col max-w-[1140px] w-[1140px] max-md:max-w-full">
        <h2 className="flex flex-col w-full text-4xl font-semibold text-center text-gray-800 max-md:max-w-full">
          <div className="flex flex-col justify-center w-full max-md:max-w-full">
            <div className="flex flex-col w-full max-md:max-w-full">
              <div className="pr-64 pl-64 w-full max-md:px-5 max-md:max-w-full">
                <span className="leading-10">Get the best </span>
                <br />
                <span className="leading-10 text-blue-700"> company registration service </span>
              </div>
            </div>
          </div>
        </h2>
        <div className="flex flex-col w-full max-md:max-w-full">
          <div className="flex flex-col pt-10 w-full max-md:max-w-full">
            <div className="flex flex-wrap justify-center items-start pt-5 w-full max-md:max-w-full">
              {benefits.map((benefit, index) => (
                <div key={index} className="flex flex-1 shrink justify-center px-3 basis-0  mb-10">
                  <div className="flex overflow-hidden  flex-1 shrink justify-center p-2.5 bg-white rounded shadow-lg basis-0  size-full" style={{height:"450px"}}>
                    <div className="flex flex-col flex-1 shrink justify-start p-5 w-full basis-0 min-w-[240px]">
                      <div className="flex flex-col w-full">
                        <h3 className="w-full text-2xl font-semibold leading-8 text-blue-700">
                          {benefit.title}
                        </h3>
                        <p className="pb-px mt-4 w-full text-base font-light leading-7 text-gray-800">
                          {benefit.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyRegistrationBenefits;