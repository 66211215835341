import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import "../../CommonSection/CommonStyle/CommonStyle.css";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";

const CorporateFaq = () => {
  const faqItems = [
    {
      key: "0",
      question: "What comes in a corporate kit?",
      answer:
        "A corporate kit typically includes stock certificates, a corporate seal, and essential documents like bylaws.",
    },
    {
      key: "1",
      question: "Why is a corporate seal necessary?",
      answer:
        "A corporate seal is used to authenticate official company documents, adding a formal touch to legal paperwork.",
    },
    {
      key: "2",
      question: "Can I order customized corporate supplies?",
      answer:
        "Yes, we offer customizable kits, seals, and certificates based on your company’s needs.",
    },
    {
      key: "3",
      question: "Do I need a stock certificate for my LLC?",
      answer:
        "No, LLCs often use membership certificates instead of stock certificates to represent ownership.",
    },
    {
      key: "4",
      question: "How do I maintain corporate records?",
      answer:
        "A corporate kit keeps all necessary legal and operational documents organized and in one place.",
    },
    {
      key: "5",
      question:
        "What’s the difference between a corporate seal and a notary seal?",
      answer:
        "A corporate seal is specific to your business, whereas a notary seal is used by a licensed notary public.",
    },
    {
        key: "6",
        question:
          "Can I get replacements for lost corporate supplies?",
        answer:
          "Yes, we can provide replacements for lost seals, certificates, or kits.",
      },
      {
        key: "7",
        question:
          "How often do I need to update my corporate supplies?",
        answer:
          "You should review and update supplies as your business changes or after major milestones like a merger.",
      },
  ];

  // Split FAQs into two columns
  const firstColumnFaqs = faqItems.filter((_, index) => index % 2 === 0);
  const secondColumnFaqs = faqItems.filter((_, index) => index % 2 !== 0);

  return (
    <section className="main-section-class">
      <div className="common-faq">
        <Container>
          <div className="mb-5 mt-5">
            <CommonHeadingTitle heading={"FAQ"} />
          </div>
          <Accordion defaultActiveKey="0">
            <Row>
              <Col lg={6} md={6} sm={12}>
                {firstColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
              <Col lg={6} md={6} sm={12}>
                {secondColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
            </Row>
          </Accordion>
        </Container>
      </div>
    </section>
  );
};

export default CorporateFaq;
