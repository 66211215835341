import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";
import imgsrc from "../../../../Assets/Images/corporate.png";
import AOS from "aos";
import "aos/dist/aos.css";

const services = [
  {
    title: "Legal Compliance",
    description:
      "Corporate filings ensure your business remains compliant with regulations in all states or countries where you operate.",
  },
  {
    title: "Operational Flexibility",
    description:
      "Filings such as amendments or conversions allow your business to evolve as it grows, ensuring you meet current and future needs.",
  },
  {
    title: "Avoid Disruptions",
    description:
      "Timely and accurate filings prevent business disruptions due to non-compliance or incorrect documentation.",
  },
  {
    title: "Streamlined Process",
    description:
      "Our services simplify the filing process, reducing paperwork and helping you avoid errors or delays.",
  },
];

const CorporateFillingSec = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="main-section-class">
      <div className="entity-formation">
        <Container>
          <CommonHeadingTitle
            heading={"Why"}
            blueheading={"corporate filings matter?"}
          />
          <Row className="mt-5">
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <Row>
                {services.map((service, index) => (
                  <Col lg={12} md={12} sm={12} xs={12} key={index}>
                    <div className="main-class-div">
                      <h4 className="medium-level-heading">{service.title}</h4>
                      <div className="grey-border mt-3 mb-3"></div>
                      <p className="normal-common-txt">{service.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12} data-aos="fade-right"
              data-aos-duration="3000">
              <img loading="lazy" className="user-img-buisness" src={imgsrc} />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default CorporateFillingSec;
