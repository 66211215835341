import React from 'react'
import PendingRequestCard from '../utils/PendingRequestCard'

const PendingRequest = () => {
  return (
    <div className='card'>
        <PendingRequestCard/>
    </div>
  )
}

export default PendingRequest