import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const CustomeFinalPrice = (props) => {
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <p>Are you sure you want to finalize this price for the product</p>
          <div className="text-end">
            <Button variant="secondary" onClick={props.onHide}>
              Cancel
            </Button>
            <Button variant="primary">Confirm</Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CustomeFinalPrice;
