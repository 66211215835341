import axios from "axios";
import authHeader, { initAuth } from "./authHeader";
import encryption from "./CryptoClass";
import propertiesApp from "../utils/propertiesApp";

const getDataById = (endpoint) => {
    const dataToEncrypt = {
        endpoint: endpoint,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}





const saveAllOrder = (data) => {
    const dataToEncrypt = {
        endpoint: "/order/api/orderDetails/save",
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveUpdateHistory = (data) => {
    const dataToEncrypt = {
        endpoint: "/order/api/orderUpdateHistory/save",
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const putUpdateHistory = (data) => {
    const dataToEncrypt = {
        endpoint: "/order/api/orderUpdateHistory/update",
        payload: data,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveAllInvoice = (data) => {
    const dataToEncrypt = {
        endpoint: "/order/api/invoiceDetails/save",
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const saveAllSalesReciept = (data) => {
    const dataToEncrypt = {
        endpoint: "/order/api/orderDetails/update",
        payload: data,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const paymentOrder = (data) => {
    const dataToEncrypt = {
        endpoint: "/order/api/stripe/create-checkout-session",
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const submitBoiApplication = (data,orderNumber) => {
    const dataToEncrypt = {
        endpoint: `/order/api/boi/submitBoiApplication/${orderNumber}`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,
            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const paymentSession = (id) => {
    const dataToEncrypt = {
        endpoint: `/order/api/stripe/transactionId-checkout-session/${id}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const updateById = (data,endpoint) => {
    const dataToEncrypt = {
        endpoint: endpoint,
        payload: data,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const updateOrderById = (data) => {
    const dataToEncrypt = {
        endpoint: "",
        payload: data,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllOrder = () => {
    const dataToEncrypt = {
        endpoint: "/order/api/orderDetails/getAll",
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllOrderByPagination = (id,page,size) => {
    const dataToEncrypt = {
        endpoint: `/order/api/orderDetails/getAll/${id}?page=${page}&size=${size}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllOrderByWithoutPagination = (id) => {
    const dataToEncrypt = {
        endpoint: `/order/api/orderDetails/getAll/${id}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllOrderByStatusAndDate = (id,startDate,endDate) => {
    const dataToEncrypt = {
        endpoint: `/order/api/orderDetails/getByStatusId/${id}/${startDate}/${endDate}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllOrderForDashbaord = () => {
    const dataToEncrypt = {
        endpoint: "/order/api/reportOrderDashboard/getAll",
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllOrderForDashbaordByDate = (startMonth,startYear,endMonth,endYear) => {
    const dataToEncrypt = {
        endpoint: `/order/api/reportOrderDashboard/getAllByMonth/${startMonth}/${startYear}/${endMonth}/${endYear}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllOrderCompanyByDate = (startYear) => {
    const dataToEncrypt = {
        endpoint: `/order/api/orderDetails/distinctCompaniesByState/?startDate=${startYear}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}
const getAllServiceForDashbaord = () => {
    const dataToEncrypt = {
        endpoint: "/order/api/reportServiceOrderDashboard/getAll",
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllOrderByCompanyId = (companyId) => {
    const dataToEncrypt = {
        endpoint: `/order/api/orderDetails/getByCompanyId/${companyId}`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            // return axios.get(`/decrypt?data=${dataToEncrypt}`,
            return axios.post(`/decrypt`,dataToEncrypt,

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllOrderByAccountManagerId = (id) => {
    const dataToEncrypt = {
        endpoint: `/order/api/orderDetails/getByByAccountManagerId/${id}`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            // return axios.get(`/decrypt?data=${dataToEncrypt}`,
            return axios.post(`/decrypt`,dataToEncrypt,

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getOrderCountByCompanyId=(id) => {
    const dataToEncrypt = {
        "endpoint": `/order/api/orderDetails/getStatusCount/${id}`,
        "type": "get",
        "auth": authHeader()
    }

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const customCompanyUpload = (data) => {
    const dataToEncrypt = {
        endpoint: `${propertiesApp.python_url}/multipart/extract_form_data`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`${propertiesApp.python_url}/multipart/extract_form_data`, data,
            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}









const OrderServices = {
  getAllOrder,
  saveAllOrder,
  updateById,
  getDataById,
  saveAllInvoice,
  getAllOrderByCompanyId,
  saveUpdateHistory,
  putUpdateHistory,
  saveAllSalesReciept,
  getAllOrderByAccountManagerId,
  paymentOrder,
  paymentSession,
  getAllOrderForDashbaord,
  getAllServiceForDashbaord,
  getOrderCountByCompanyId,
  submitBoiApplication,
  getAllOrderByPagination,
  getAllOrderByWithoutPagination,
  getAllOrderForDashbaordByDate,
  getAllOrderCompanyByDate,
  getAllOrderByStatusAndDate,
  customCompanyUpload
}

export default OrderServices;