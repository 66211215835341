import React from 'react';

function DateRangeSelector() {
  return (
    <div className="flex flex-col w-full text-sm font-medium leading-none max-md:max-w-full">
      <div className="flex flex-wrap gap-10 justify-between items-start w-full max-md:max-w-full">
        <div className="flex items-start whitespace-nowrap shadow-sm text-slate-700">
          <button className="overflow-hidden self-stretch px-4 py-2.5 bg-white rounded-lg border border-gray-300 border-solid">12 months</button>
          <button className="flex flex-col justify-center bg-gray-50 text-slate-800 w-[79px]">
            <div className="flex w-full bg-gray-300 min-h-[1px]" />
            <div className="gap-2.5 self-center px-4 py-2.5">6 months</div>
            <div className="flex w-full bg-gray-300 min-h-[1px]" />
          </button>
          <div className="flex shrink-0 self-stretch w-px h-10 bg-gray-300" />
          <button className="flex flex-col justify-center bg-white w-[79px]">
            <div className="flex w-full bg-gray-300 min-h-[1px]" />
            <div className="gap-2.5 self-center px-4 py-2.5">3 months</div>
            <div className="flex w-full bg-gray-300 min-h-[1px]" />
          </button>
          <button className="overflow-hidden self-stretch px-4 py-2.5 bg-white rounded-none border border-gray-300 border-solid">1 month</button>
        </div>
        <div className="flex gap-3 items-center min-w-[240px]">
         
        </div>
      </div>
    </div>
  );
}

export default DateRangeSelector;