import React from "react";
import { useNavigate } from "react-router-dom";

function FailedActionButtons({setCloseProgresbar1}) {
    const history = useNavigate()
    return (
        <div className="flex gap-4 items-start mt-8 w-full text-sm font-medium leading-none max-md:max-w-full">
            <div className="flex flex-wrap flex-1 shrink gap-3 items-start w-full basis-0 min-w-[240px] max-md:max-w-full">
                <button className="flex flex-1 shrink items-start whitespace-nowrap rounded-lg basis-0 min-w-[240px] text-slate-700" onClick={()=>setCloseProgresbar1(false)} >
                    <span className="flex overflow-hidden flex-1 shrink gap-2 justify-center items-center px-4 py-2.5 w-full bg-white-600 rounded-lg border border-blue-600 border-solid shadow-sm basis-0 min-w-[240px]">
                        <svg xmlns="http://www.w3.org/2000/svg"  height="20" viewBox="0 0 1 20" fill="none">
                            
                        </svg>
                        <span className="self-stretch my-auto">Cancel</span>

                    </span>
                </button>
                <button className="flex flex-1 shrink items-start text-center text-white rounded-lg basis-0 min-w-[240px]" onClick={() => history("/order")}>
                    <span className="flex overflow-hidden flex-1 shrink gap-2 justify-center items-center px-4 py-2.5 w-full bg-blue-600 rounded-lg border border-blue-600 border-solid shadow-sm basis-0 min-w-[240px]">
                        <svg xmlns="http://www.w3.org/2000/svg"  height="20" viewBox="0 0 1 20" fill="none">
                           
                        </svg>
                        <span className="self-stretch my-auto">Try Again</span>
                    </span>
                </button>
            </div>
        </div>
    );
}

export default FailedActionButtons;