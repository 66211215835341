import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import MasterServices from "../../services/coreServices";
import { Skeleton } from "primereact/skeleton";

const Documents = ({ data }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  

  useEffect(() => {
    if (data) {
      if (Array.isArray(data.orderDetails.documentsId)) {
        if (data.orderDetails.documentsId.length > 0) {
          setProducts(data.orderDetails.documentsId);
        }
      }
      setLoading(false);
    }
  }, [data]);

  function downloadPDFFromBase64(base64String, filename) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  const handleFileDownload = (rowdata) => {
    if (rowdata.documentPath) {
      // setLoading(true);
      MasterServices.getFile(rowdata.documentPath)
        .then((res) => {
          
          downloadPDFFromBase64(res.data, rowdata.documentName);
          setLoading(false);
        })
        .catch((err) => {
          
          setLoading(false);
        });
    }
  };

  // Action column template
  const iconBodyTemplate = (rowData) => {
    return loading ? (
      <Skeleton width="100%" height="2rem" />
    ) : (
      <button
        className="datatable-highlight-color"
        onClick={() => handleFileDownload(rowData)}
      >
        <span>
          <i className="pi pi-download mr-1"></i>
        </span>
        Download
      </button>
    );
  };

  return (
    <div>
      <DataTable
        value={loading ? [] : products}
        scrollable
        dataKey="name"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        className="font-fam-for-all "
        scrollHeight="flex"
        scrollWidth="100%"
        globalFilterFields={["djjddjjd"]}
      >
        <Column
          field="documentName"
          header="Name"
          className="font-fam-for-all datatable-lg-col webapp-sm-size"
          body={(rowData) =>
            loading ? <Skeleton width="100%" height="2rem" /> : rowData.documentName
          }
        ></Column>
        <Column
          body={iconBodyTemplate}
          header="Action"
          className="font-fam-for-all datatable-lg-col webapp-sm-size"
        ></Column>
      </DataTable>
    </div>
  );
};

export default Documents;
