import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";
import ServiceCommonCard from "../../CommonSection/ServiceCommonCard";
import BlueButton from "../../CommonSection/BlueButton";
import AOS from "aos";
import "aos/dist/aos.css";

const CorporateFillingsThird = () => {
  const [showAll, setShowAll] = useState(false);

  const services = [
    {
      title: "Foreign Qualification",
      description:
        "The process of registering an out-of-state business to legally operate in another state. It involves filing specific documents and paying fees to comply with state regulations.",
    },
    {
      title: "LLP (Limited Liability Partnership)",
      description:
        "A partnership structure that provides limited liability to partners, protecting personal assets from business debts. Forming an LLP requires a formal registration process with the state.",
    },
    {
      title: "LP (Limited Partnership)",
      description:
        "A partnership consisting of general partners with unlimited liability and limited partners with liability protection. Establishing an LP involves filing a Certificate of Limited Partnership.",
    },
    {
      title: "Non-Profit",
      description:
        "An organization formed for charitable, educational, or social purposes that qualifies for tax-exempt status. Formation includes filing Articles of Incorporation and obtaining 501(c)(3) status.",
    },
    {
      title: "501(c)(3)",
      description:
        "A specific type of non-profit organization recognized by the IRS as tax-exempt. It involves rigorous compliance and application procedures to maintain tax benefits.",
    },
    {
      title: "Dissolution",
      description:
        "The formal process of closing a business entity. This involves filing necessary documents with the state and ensuring all obligations are settled.",
    },
    {
      title: "Amendment",
      description:
        "A filing to change specific details of an existing business entity, such as its name or structure. Amendments require submitting the appropriate forms and fees to state authorities.",
    },
    {
      title: "S-Corp Election",
      description:
        "A tax designation allowing a corporation to be taxed as an S corporation, enabling pass-through taxation. Filing Form 2553 with the IRS is required to elect this status.",
    },
    {
      title: "Conversion",
      description:
        "The process of changing a business entity from one type to another (e.g., from an LLC to a Corporation). This involves specific filings and compliance with state laws.",
    },
    {
      title: "Merger",
      description:
        "The legal combination of two or more entities into one, requiring detailed documentation and approval by state authorities.",
    },
    {
      title: "Reinstatement",
      description:
        "The process of restoring a business’s status after it has been revoked due to non-compliance. This typically involves filing reinstatement forms and paying fees.",
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section data-aos="fade-left" data-aos-duration="3000">
      <Container>
        <CommonHeadingTitle blueheading={"Corporate filings services"} />
        <div className="text-center mt-5 mb-5">
          <h4 className="subheading">
            Corporate filings include essential services like foreign
            qualifications, mergers, conversions, and dissolutions. These are
            required for major changes and expansions, ensuring your business
            operates legally across jurisdictions.
          </h4>
        </div>
        <Row className="justify-content-center">
          {(showAll ? services : services.slice(0, 3)).map((service, index) => (
            <Col key={index} lg={4} md={4} sm={6} xs={12}>
              <ServiceCommonCard
                title={service.title}
                description={service.description}
              />
            </Col>
          ))}
        </Row>
        <div className="text-center mt-4 mb-4">
          <button
            className="btn btn-primary"
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? "Read Less" : "Read More"}
          </button>
        </div>
      </Container>
    </section>
  );
};

export default CorporateFillingsThird;
