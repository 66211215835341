import { FilterMatchMode } from 'primereact/api';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import React, { useContext, useEffect, useRef, useState } from 'react';
import rolesMasterService from '../../services/rolesMaster';
import authContext from '../../common/authContext';
import { Toast } from 'primereact/toast';

const AddPermission = ({ visible, setVisible, value, companyId, userDetailsId }) => {
    const { permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh } = useContext(authContext);

    const [allRoles, setAllRoles] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState(new Set());
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [viewingRoleId, setViewingRoleId] = useState(null);

    const [selectedRolesData, setSelectedRolesData] = useState([])

    const user = JSON.parse(localStorage.getItem('user'));

    const [superAdminId, setSuperAdminId] = useState(null)

    function checkIdsExist(arr, idsToCheck) {
      // Check if any of the idsToCheck are found in arr
      const foundIds = idsToCheck.filter(idToCheck => arr.some(item => item.id === idToCheck));
      return foundIds.length > 0 ? foundIds : null;
    }
  
    useEffect(() => {
  
      if (user) {
        if (user.companyRoles !== undefined) {
          if (user.companyRoles.length > 0) {
            if (user.companyRoles[0].roles.length > 0) {
              const id = checkIdsExist(user.companyRoles[0].roles, [1, 4]);
              console.log(id)
              setSuperAdminId(id)
            }
          }
        }
      }
    }, [])

    const toast = useRef(null)

    useEffect(() => {
        rolesMasterService.getAllRoles()
            .then((res) => {
                setAllRoles(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        const initialSelections = new Set();
        value.forEach(role => {
            initialSelections.add(role.id);
        });
        setSelectedRoles(initialSelections);
    }, [value]);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const header = () => (
        <div className="flex align-items-center justify-content-start gap-3">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText placeholder="Keyword Search" value={globalFilterValue} onChange={onGlobalFilterChange} style={{ borderRadius: "8px" }} />
            </span>
        </div>
    );

    const handleToggle = (roleId) => {
        setViewingRoleId(viewingRoleId === roleId ? null : roleId);
    };

    console.log(user)

    const handleRoleChange = (roleId) => {
        const updatedRoles = new Set(selectedRoles);
        if (updatedRoles.has(roleId)) {
            updatedRoles.delete(roleId);
        } else {
            updatedRoles.add(roleId);
        }
        setSelectedRoles(updatedRoles);

        // Prepare data for the update API
        const updatedRolesArray = Array.from(updatedRoles);
        const updatedRolesData = allRoles.filter(role => updatedRolesArray.includes(role.id));

        console.log(updatedRolesData);
        setSelectedRolesData(updatedRolesData)
        const temps = updatedRolesData.map(role => role.id)
        const temp = temps.filter((val) => (val !== 1) && (val !== 2) && (val !== 3))
        console.log(temp)

        // Call the update API


    };

    console.log(selectedRolesData)

    const handleSave = () => {

        const selectedRoleIds = selectedRolesData.map(role => role.id);
        const elementsToRemove = [1, 2, 3, 4, 5];
        const result = selectedRoleIds.filter(item => !elementsToRemove.includes(item));
        console.log(result)

        const actualdata = {
            "companyId": companyId,
            "roleId": result.length > 0 ? result : [],
            "statusId": 1,
            "userId": userDetailsId ? userDetailsId : ""
        }
        if (result.length > 0) {
            setIsTabRefresh(true)
            rolesMasterService.updateRoles(actualdata)
                .then(response => {
                    console.log('Roles updated successfully', response);
                    setVisible(false)
                    if (response.status === 201) {
                        toast.current.show({ severity: 'success', summary: 'Success', detail: "Roles updated successfully", life: 3000 });
                    }
                    setIsTabRefresh(false)

                })
                .catch(error => {

                    console.error('Error updating roles', error);
                    if (error.response !== undefined) {
                        if (error.response.status === 701) {
                            if (toast.current !== undefined) {
                                console.log('first')
                                toast.current.show({ severity: 'warn', summary: 'Error', detail: "Roles already exist", life: 3000 });

                            }
                        }
                        else {
                            if (toast.current !== undefined) {
                                toast.current.show({ severity: 'warn', summary: 'Error', detail: "Something went wrong", life: 3000 });

                            }
                        }


                    }
                    setIsTabRefresh(false)
                });
        }
        else {
            if (toast.current !== undefined) {
                toast.current.show({ severity: 'warn', summary: 'Error', detail: "Select minimum one role", life: 3000 });

            }
        }

    }

    return (
        <div>
            <Toast ref={toast} />

            <Sidebar position='right' visible={visible} className='sidebar-content-card' onHide={() => setVisible(false)}>
                <div className='grid custom-sidebar-header'>
                    <div className='grid col-11 px-4 pt-4 pb-0'>
                        <div className='col-12 sidebar-header-text1 pb-0'>
                            Manage Permission
                        </div>
                    </div>
                    <div className='col-1 flex justify-content-end pt-4 pb-0'>
                        <button onClick={() => setVisible(false)}><i className='pi pi-times'></i></button>
                    </div>
                    <div className='col-12 sidebar-header-text2 px-4'>
                        Manage all user permissions here.
                    </div>
                </div>
                <div className='divider-x my-4'></div>
                <div className='px-4 py-2'>
                    <div className='card p-4'>
                        {
                            allRoles.map((role) => {
                                // Debug log to check the role names
                                console.log(`Role Name: ${role.roleName.trim()}`);
                                return (

                                    <div key={role.id} className=' pt-3 font-fam-for-all'>
                                        <div className="flex grid ">
                                            <div className='col-1'>
                                                <Checkbox
                                                    inputId={`role-${role.id}`}
                                                    checked={selectedRoles.has(role.id)}
                                                    onChange={() => handleRoleChange(role.id)}
                                                    disabled={role.roleName.trim().toLowerCase() === 'administrator' || role.roleName.trim().toLowerCase() === 'super_administrator' || role.roleName.trim().toLowerCase() === 'user' || role.roleName.trim().toLowerCase() === 'employee' || role.roleName.trim().toLowerCase() === 'vendor'}
                                                />
                                            </div>
                                            <div className='col-7'>
                                                <label htmlFor={`role-${role.id}`} className=" mb-0" style={{ fontWeight: "500", fontSize: "14px" }}>{role.roleName}</label>
                                                <p className=' mt-1' style={{ fontSize: "14px" }}>{role.roleDesc}</p>

                                            </div>
                                            <div className='col-4'>
                                                <button onClick={() => handleToggle(role.id)}
                                                    disabled={superAdminId===null && (role.roleName.trim().toLowerCase() === 'administrator' || role.roleName.trim().toLowerCase() === 'super_administrator' || role.roleName.trim().toLowerCase() === 'user' || role.roleName.trim().toLowerCase() === 'employee' || role.roleName.trim().toLowerCase() === 'vendor')}
                                                    style={{ color: "#1570EF", fontSize: "14px" }}>View permission <i className={viewingRoleId === role.id ? "pi pi-angle-up" : 'pi pi-angle-down'}></i></button>
                                            </div>
                                        </div>
                                        {
                                            role.permissionMasters.length > 0 && viewingRoleId === role.id &&
                                            <div className='customize-email-frame mt-3'>
                                                <div className='customize-header-email'>
                                                    <div className='customize-table-header-email'>
                                                        <div className='customize-table-header-email-text'>
                                                            Permission Name
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    role.permissionMasters.map((permission) => (
                                                        <div key={permission.id} className='customize-content-email'>
                                                            <div className='customize-table-content-email'>
                                                                <div className='customize-table-content-email-text'>
                                                                    {permission.permissionName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        }
                                    </div>

                                );
                            })
                        }
                    </div>
                </div>
                <div className='flex justify-content-end dialog-form-md-group-Btn'>
                    <Button className="mt-2 vstate-reverse-button font-fam-for-all text-center mr-2" style={{ height: "40px" }} onClick={() => setVisible(false)}>Cancel</Button>
                    <Button className="mt-2 vstate-button font-fam-for-all text-center mr-3" style={{ height: "40px" }} onClick={handleSave}>Submit</Button>
                </div>
            </Sidebar>
        </div>
    );
};

export default AddPermission;
