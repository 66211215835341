import React, { useState } from 'react';

const PendingRequestCard = () => {
  const [requests, setRequests] = useState([
    { id: 1, name: 'Apurva requested for admin role', status: 'pending' },
    { id: 2, name: 'Prathamesh requested for super admin role', status: 'pending' },
    { id: 3, name: 'Pranab requested for super admin role', status: 'pending' },
    { id: 4, name: 'Gaurav requested for super admin role', status: 'pending' },
    { id: 5, name: 'Sanket requested for super admin role', status: 'pending' },
  ]);

  const handleAction = (id, action) => {
    setRequests((prevRequests) =>
      prevRequests.map((req) =>
        req.id === id ? { ...req, status: action } : req
      )
    );
  };

  return (
    <div className="flex flex-col items-center space-y-4 p-4 w-full">
      {requests.map((request) => (
        <div
          key={request.id}
          className="w-full  bg-white shadow-lg rounded-lg p-4 flex justify-between items-center"
        >
          <div>
            <h3 className="text-lg font-semibold">{request.name}</h3>
            <p className="text-gray-500">Status: {request.status}</p>
          </div>
          {request.status === 'pending' ? (
            <div className="flex space-x-2">
              <button
                onClick={() => handleAction(request.id, 'accepted')}
                className="vstate-button text-white px-3 py-1 rounded"
              >
                Accept
              </button>
              <button
                onClick={() => handleAction(request.id, 'rejected')}
                className="vstate-reverse-button px-3 py-1 rounded "
              >
                Reject
              </button>
            </div>
          ) : (
            <span
              className={`${
                request.status === 'accepted'
                  ? 'text-green-600'
                  : 'text-red-600'
              } font-semibold`}
            >
              {request.status.charAt(0).toUpperCase() +
                request.status.slice(1)}
            </span>
          )}
        </div>
      ))}
    </div>
  );
};

export default PendingRequestCard;
