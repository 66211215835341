import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import MasterServices from "../../services/coreServices";
import AddressService from "../../services/addressService";

const AddProductListing = () => {
    const [formData, setFormData] = useState({
        displayName: '',
        baseProduct: '',
        unitPrice: '',
        taxAmount: '',
        taxPercent: '',
        noOfItemsInBundle: '',
        priceOfBundle: '',
        priceForAddlItemsOverBundle: '',
        isTaxable: false,
        isCustomPriceApplicable: false,
        isBundlePriceApplicable: false,
        isDefault: false,
        isPriceIncludedInBaseProduct: false,
        statusMaster: null,
        displayPreference: null,
        entityTypeMaster: null,
        stateMaster: null,
        orderTypeMaster: null,
        countyMaster: null,
        hubSpotProductId: null,
        quickBooksProductId: null,
        isStateBased: false,
        isCountyBased: false
    });

    console.log(formData)

    const [errors, setErrors] = useState({});

    const [dropdownOptions, setDropdownOptions] = useState({
        statusMaster: [{ label: "Active", value: 1 }, { label: "Inactive", value: 0 }],
        entityTypeMaster: [{ label: "Individual", value: 1 }, { label: "Company", value: 2 }],
        orderTypeMaster: [{ label: "Standard", value: 1 }, { label: "Express", value: 2 }],
        baseProduct: [{ label: "Standard", value: 1 }, { label: "Express", value: 2 }],
    });

    const [multiSelectOptions, setMultiSelectOptions] = useState({
        stateMaster: [{ label: "New York", value: 1 }, { label: "California", value: 2 }],
        countyMaster: [{ label: "Los Angeles", value: 1 }, { label: "Miami-Dade", value: 2 }],
    })

    useEffect(() => {
        AddressService.getAllStates()
            .then((res) => {
                if (res.data && Array.isArray(res.data)) {
                    multiSelectOptions.stateMaster = res.data
                }
            })
            .catch((err) => {
                console.log(err)
            })

        MasterServices.getAllStatus()
            .then((res) => {
                if (res.data && Array.isArray(res.data)) {
                    dropdownOptions.statusMaster = res.data
                }
            })
            .catch((err) => {
                console.log(err)
            })

        MasterServices.getAll("/core/api/orderTypeMaster/getAll")
            .then((res) => {
                console.log(res.data);
                if (res.data !== "") {
                    dropdownOptions.orderTypeMaster = res.data
                }
            })
            .catch((err) => {
                console.log(err);
            });

        MasterServices.getAll("/core/api/entityTypeMaster/getAll")
            .then((res) => {
                console.log(res.data);
                if (res.data !== "") {
                    dropdownOptions.entityTypeMaster = res.data
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }, [])

    const handleChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.displayName) {
            formErrors.displayName = "Display Name is required.";
        }
        if (!formData.baseProduct) {
            formErrors.baseProduct = "Base Product is required.";
        }
        if (!formData.unitPrice || isNaN(formData.unitPrice)) {
            formErrors.unitPrice = "Unit Price must be a valid number.";
        }
        // Add other validation as needed
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log("Form Data Submitted:", formData);
        }
    };

    const inputStyles = "block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border focus:outline-none focus:ring-0 focus:border-blue-600 peer w-full";


    return (
        <div className="px-4 pb-8 pt-2 vstate-card">
            <div className="grid">
                <div className="col-8">
                    <form onSubmit={onSubmit} className="p-fluid">
                        <div className="">

                            <div className="input-sidebar-card mt-4">
                                <div className="px-5 pt-4">
                                    <div className="flex" style={{ justifyContent: "space-between" }}>
                                        <div className="font-fam-for-all srch-cand-text2">
                                            <span>
                                                <i className="pi pi-address-book"></i>
                                            </span>
                                            Basic Details
                                        </div>
                                    </div>
                                </div>
                                <div className="form-content-signup px-5 pb-5">
                                    <div className="grid mt-2 justify-content-start">
                                        {["displayName"].map((field) => (
                                            <div className="col-6 mb-3" key={field}>
                                                <div className="relative">
                                                    <InputText
                                                        name={field}
                                                        value={formData[field]}
                                                        onChange={(e) => handleChange(field, e.target.value)}
                                                        placeholder=""
                                                        id={`floating_outlined_${field}`}
                                                        className={`${inputStyles} ${errors[field] ? 'border-red-500' : 'border-gray-300'}`}
                                                    />
                                                    <label
                                                        htmlFor={`floating_outlined_${field}`}
                                                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                    >
                                                        {field} <span className="form-field-mandatory">*</span>
                                                    </label>
                                                </div>
                                                {errors[field] && <small className="error-msg font-fam-for-all">{errors[field]}</small>}
                                            </div>
                                        ))}
                                        <div className="col-6 mb-3 ">
                                            <div className="relative">
                                                <Dropdown
                                                    value={formData.baseProduct}
                                                    options={dropdownOptions.baseProduct}
                                                    resetFilterOnHide
                                                    onChange={(e) => handleChange("baseProduct", e.value)}
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    className="w-full"
                                                    id={`floating_outlined_${"baseProduct"}`}
                                                />
                                                <label
                                                    htmlFor={`floating_outlined_${"baseProduct"}`}
                                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                >
                                                    {"baseProduct"} <span className="form-field-mandatory">*</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="input-sidebar-card mt-4">
                                <div className="px-5 pt-4">
                                    <div className="flex" style={{ justifyContent: "space-between" }}>
                                        <div className="font-fam-for-all srch-cand-text2">
                                            <span>
                                                <i className="pi pi-address-book"></i>
                                            </span>
                                            Pricing
                                        </div>
                                    </div>
                                </div>
                                <div className="form-content-signup px-5 pb-5">
                                    <div className="grid mt-2 justify-content-start">
                                        {["unitPrice", "taxAmount", "taxPercent", "noOfItemsInBundle", "priceOfBundle", "priceForAddlItemsOverBundle", "displayPreference"].map((field) => (
                                            <div className="col-6 mb-3" key={field}>
                                                <div className="relative">
                                                    <input
                                                        type="number"
                                                        value={formData[field]}
                                                        onChange={(e) => handleChange(field, e.target.value)}
                                                        id={`floating_outlined_${field}`}
                                                        className={inputStyles}
                                                    />
                                                    <label
                                                        htmlFor={`floating_outlined_${field}`}
                                                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                    >
                                                        {field} <span className="form-field-mandatory">*</span>
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className="input-sidebar-card mt-4">
                                <div className="px-5 pt-4">
                                    <div className="flex" style={{ justifyContent: "space-between" }}>
                                        <div className="font-fam-for-all srch-cand-text2">
                                            <span>
                                                <i className="pi pi-address-book"></i>
                                            </span>
                                            Product Dependencies
                                        </div>
                                    </div>
                                </div>
                                <div className="form-content-signup px-5 pb-5">
                                    <div className="grid mt-2 justify-content-start">
                                        {["isTaxable", "isCustomPriceApplicable", "isBundlePriceApplicable", "isDefault", "isPriceIncludedInBaseProduct"].map((field) => (
                                            <div className="col-6 mb-3 p-field-checkbox" key={field}>
                                                <div className="relative">
                                                    <Checkbox checked={formData[field]} onChange={(e) => handleChange(field, e.target.checked)} />
                                                    <label htmlFor={field} className="font-fam-for-all px-4 mb-0">{field}</label>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>


                            <div className="input-sidebar-card mt-4">
                                <div className="px-5 pt-4">
                                    <div className="flex" style={{ justifyContent: "space-between" }}>
                                        <div className="font-fam-for-all srch-cand-text2">
                                            <span>
                                                <i className="pi pi-address-book"></i>
                                            </span>
                                            Product Additional Details
                                        </div>
                                    </div>
                                </div>
                                <div className="form-content-signup px-5 pb-5">
                                    <div className="grid mt-2 justify-content-start">



                                        <div className="col-6 mb-3 ">
                                            <div className="relative">
                                                <Dropdown
                                                    value={formData.entityTypeMaster}
                                                    resetFilterOnHide
                                                    options={dropdownOptions.entityTypeMaster}
                                                    onChange={(e) => handleChange("entityTypeMaster", e.value)}
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    className="w-full"
                                                    id={`floating_outlined_${"entityTypeMaster"}`}
                                                />
                                                <label
                                                    htmlFor={`floating_outlined_${"entityTypeMaster"}`}
                                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                >
                                                    {"entityTypeMaster"} <span className="form-field-mandatory">*</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-6 mb-3 ">
                                            <div className="relative">
                                                <Dropdown
                                                    value={formData.orderTypeMaster}
                                                    options={dropdownOptions.orderTypeMaster}
                                                    onChange={(e) => handleChange("orderTypeMaster", e.value)}
                                                    resetFilterOnHide
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    className="w-full"
                                                    id={`floating_outlined_${"orderTypeMaster"}`}
                                                />
                                                <label
                                                    htmlFor={`floating_outlined_${"orderTypeMaster"}`}
                                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                >
                                                    {"orderTypeMaster"} <span className="form-field-mandatory">*</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-6 mb-3 ">
                                            <div className="relative">
                                                <Dropdown
                                                    value={formData.statusMaster}
                                                    options={dropdownOptions.statusMaster}
                                                    resetFilterOnHide
                                                    onChange={(e) => handleChange("statusMaster", e.value)}
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    className="w-full"
                                                    id={`floating_outlined_${"statusMaster"}`}
                                                />
                                                <label
                                                    htmlFor={`floating_outlined_${"statusMaster"}`}
                                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                >
                                                    {"statusMaster"} <span className="form-field-mandatory">*</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="input-sidebar-card mt-4">
                                <div className="px-5 pt-4">
                                    <div className="flex" style={{ justifyContent: "space-between" }}>
                                        <div className="font-fam-for-all srch-cand-text2">
                                            <span>
                                                <i className="pi pi-address-book"></i>
                                            </span>
                                            Product's States & Counties
                                        </div>
                                    </div>
                                </div>
                                <div className="form-content-signup px-5 pb-5">
                                    <div className="grid mt-2 justify-content-start">
                                        {["isStateBased", "isCountyBased"].map((field) => (
                                            <div className="col-6 mb-3 p-field-checkbox" key={field}>
                                                <div className="relative">
                                                    <Checkbox checked={formData[field]} onChange={(e) => handleChange(field, e.target.checked)} />
                                                    <label htmlFor={field} className="font-fam-for-all px-4 mb-0">{field}</label>
                                                </div>
                                            </div>
                                        ))}

                                        <div className="col-6 mb-3 ">
                                            <div className="relative">
                                                <MultiSelect
                                                    value={formData.stateMaster}
                                                    options={multiSelectOptions.stateMaster}
                                                    onChange={(e) => handleChange("stateMaster", e.value)}
                                                    optionLabel="stateFullDesc"
                                                    optionValue="stateShortName"
                                                    className="w-full"
                                                    id={`floating_outlined_${"stateMaster"}`}
                                                />
                                                <label
                                                    htmlFor={`floating_outlined_${"stateMaster"}`}
                                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                >
                                                    {"stateMaster"} <span className="form-field-mandatory">*</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-6 mb-3 ">
                                            <div className="relative">
                                                <MultiSelect
                                                    value={formData.countyMaster}
                                                    options={multiSelectOptions.countyMaster}
                                                    onChange={(e) => handleChange("countyMaster", e.value)}
                                                    optionLabel="label"
                                                    optionValue="value"
                                                    className="w-full"
                                                    id={`floating_outlined_${"countyMaster"}`}
                                                />
                                                <label
                                                    htmlFor={`floating_outlined_${"countyMaster"}`}
                                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                >
                                                    {"countyMaster"} <span className="form-field-mandatory">*</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="mt-4 flex justify-content-end">
                            <Button type="submit" label="Submit" className="p-button-primary" />
                        </div>
                    </form>
                </div>
                <div className="col-6"></div>
            </div>
        </div>
    );
};

export default AddProductListing;
