import React from 'react';
import ComplianceItem from './ComplianceItem';
import ComplianceImage from './ComplianceImage';



function ComplianceSection({complianceData}) {
  return (
    <section className="flex flex-col justify-center pt-16 pb-20 w-full max-md:max-w-full">
      <h2 className="text-5xl font-semibold text-center text-blue-700 max-md:max-w-full">
        {complianceData.sectionTitle}
      </h2>
      <p className="text-center text-blue-500 max-md:max-w-full">
        {complianceData.sectionSubtitle}
      </p>
      <div className="flex flex-wrap gap-10 justify-center pt-5 mt-10 w-full max-md:max-w-full">
        <div className="flex flex-col justify-center p-5 my-auto min-w-[240px] text-slate-800 w-[511px] max-md:max-w-full">
          {complianceData.items.map((item, index) => (
            <ComplianceItem
              key={index}
              title={item.title}
              description={item.description}
              isLast={index === complianceData.items.length - 1}
            />
          ))}
        </div>
        <ComplianceImage images={complianceData.images} />
      </div>
    </section>
  );
}

export default ComplianceSection;
