import axios from "axios";
import encryption from "./CryptoClass";
import { initAuth } from "./authHeader";
import propertiesApp from "../utils/propertiesApp";

const sendEmail = (data) => {
    const dataToEncrypt = {
        endpoint: `/authentication/api/user/resetPassword/${data}`,
        payload: null,
        type: "post",
        auth: null
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        // return axios.get(`/decrypt?data=${encryptedData}`,
        return axios.post(`/decrypt`,dataToEncrypt,
        // return axios.post(`http://localhost:7000/api/auth/resetPassword/${data}`, 

            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const validateEmail = (data) => {
    const dataToEncrypt = {
        endpoint: `/authentication/api/user/validateEmail/${data}`,
        payload: null,
        type: "get",
        auth: null
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        // return axios.get(`/decrypt?data=${encryptedData}`,
        return axios.post(`/decrypt`,dataToEncrypt,
        // return axios.post(`http://localhost:7000/api/auth/resetPassword/${data}`, 

            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}



const sendOtp = (data,email) => {
    const dataToEncrypt = {
        endpoint: `/authentication/api/user/verify-otp/${data}/${email}`,
        payload: null,
        type: "post",
        auth: null
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`,dataToEncrypt,
        // return axios.post(`http://localhost:7000/api/auth/verify-otp/${data}`, 

            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const resetPassword = (data,email) => {
    const dataToEncrypt = {
        endpoint: `/authentication/api/user/reset-password/${data}/${email}`,
        payload: null,
        type: "post",
        auth: null
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`,dataToEncrypt,
        // return axios.post(`http://localhost:7000/api/auth/reset-password`,dataToEncrypt, 

            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const ForgotPasswordService = {
    sendEmail,
    sendOtp,
    resetPassword,
    validateEmail
}

export default ForgotPasswordService;