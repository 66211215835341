import React from 'react'
import Partners from '../Partners'
import Features from './Features'
import FormationServices from './FormationService'
import RegistrationSteps from './RegistrationStep'
import CompanyRegistrationBenefits from './CompanyRegistrationBenifits'
import CallToAction from './CallToAction'
import FAQ from './FAQ'
import ContactForm from '../ContentService/ContactForm'

const callToActionData = {
    title: " Ready to kick off your new business endeavors?",
    description: " Get started with your business registration process today.",
    buttonText: "Register now",
    path: ""
}

const WebsiteCompanyMain = () => {
    return (
        <div className='mt-24'>
            <div className="flex flex-col mt-5 website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                <header className="flex flex-col items-center px-80 pt-16 pb-20 max-md:px-5">
                    <div className="flex flex-col px-5 w-full max-w-[1240px] max-md:max-w-full">
                        <div className="flex flex-col justify-center items-center w-full max-md:max-w-full">
                            <div className="flex flex-col items-center self-stretch px-8 w-full max-md:px-5 max-md:max-w-full">
                                <div className="flex justify-center items-center w-full max-w-[1140px] max-md:max-w-full">
                                    <div className="flex justify-center self-stretch my-auto min-h-[1px] min-w-[240px] w-[616px]">
                                        <div className="flex flex-col flex-1 shrink justify-center py-2.5 pl-2.5 w-full basis-0 min-w-[240px] max-md:max-w-full">
                                            <h1 className="flex flex-col justify-center pb-5 w-full text-6xl font-bold tracking-tighter text-center text-black leading-[64px] min-h-[148px] max-md:max-w-full max-md:text-4xl max-md:leading-10">
                                                <span className="text-black">One-stop business</span>
                                                <span className="font-bold font-web-color">formation  <span className="text-black"> service</span></span>

                                            </h1>
                                            <p className="flex flex-col justify-center pb-5 w-full text-2xl font-semibold leading-8 text-center min-h-[82px] text-slate-800 max-md:max-w-full">
                                                Register your online business effortlessly in all
                                                <br />
                                                50 US states!
                                            </p>

                                            <form className="flex flex-col w-full text-sm min-h-[53px] max-md:max-w-full">
                                                <div className="flex flex-col justify-center py-1 w-full rounded-lg border-2 border-blue-700 border-solid max-md:max-w-full">
                                                    <div className="flex z-10 flex-wrap justify-center mb-0 max-md:max-w-full">
                                                        <div className="flex flex-col flex-1 shrink justify-center items-start  font-semibold text-gray-800 basis-0 min-h-[11px] min-w-[240px] max-md:max-w-full">
                                                            <div className="flex flex-wrap justify-center items-center p-0.5 max-w-full min-h-[43px] w-[300px]">
                                                                <label htmlFor="businessName" className="sr-only">Enter name of your business</label>
                                                                <input
                                                                    id="businessName"
                                                                    type="text"
                                                                    className="flex flex-col flex-1 shrink justify-center self-stretch px-4 py-2.5 my-auto w-full bg-white rounded basis-0 max-w-[300px] min-h-[40px] min-w-[240px] shadow-[0px_1px_2px_rgba(0,0,0,0.05)]"
                                                                    placeholder="Enter name of your business"
                                                                    aria-label="Enter name of your business"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col justify-center  font-medium leading-none text-center text-white min-h-[11px] w-[150px]">
                                                            <div className="flex flex-wrap items-end px-0.5 w-full min-h-[43px]">
                                                                <button type="submit" className="flex flex-col flex-1 shrink justify-center py-2 w-full vstate-button rounded-md basis-0 min-h-[43px] shadow-[0px_1px_2px_rgba(0,0,0,0.05)]">
                                                                    <span className="flex justify-center items-center px-2.5 w-full">
                                                                        Register now
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>


            <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                <Partners />
            </div>

            <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                <Features />
            </div>

            <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                <FormationServices />
            </div>

            <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                <RegistrationSteps />
            </div>

            <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                <CompanyRegistrationBenefits />
            </div>

            <div className="flex flex-col px-8 website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                <p className='contact-form-haeding-main-text1'>Need help <span className='contact-form-haeding-main-text2'>forming your business?</span></p>
                <ContactForm />
            </div>

            <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                <CallToAction callToActionData={callToActionData}/>
            </div>


            <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                <FAQ />
            </div>

        </div>
    )
}

export default WebsiteCompanyMain