import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../CommonSection/CommonStyle/CommonStyle.css";
import ReCAPTCHA from "react-google-recaptcha";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import AOS from "aos";
import "aos/dist/aos.css";
import SuccessfulModal from "../SuccessfulModal/SuccessfulModal";
import errorMessages from "../../../utils/errorMessages";

const ServiceForm = () => {
  const [modalShow, setModalShow] = useState(false);
  const [phoneErr, setPhoneErr] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    website: "",
    message: "",
    city: "",
    pincode: "",
    address: "",
    state: "", // renamed field for the dropdown
    captcha: null,
  });
  const [errors, setErrors] = useState({});

  const states = [
    { label: "DC", value: "dc" },
    { label: "Kansas", value: "kansas" },
    { label: "Missouri", value: "missouri" },
    { label: "Louisiana", value: "louisiana" },
    { label: "Mississippi", value: "mississippi" },
    { label: "South Carolina", value: "south-carolina" },
    { label: "Idaho", value: "idaho" },
    { label: "Massachusetts", value: "massachusetts" },
    { label: "Hawaii", value: "hawaii" },
    { label: "North Carolina", value: "north-carolina" },
    { label: "Tennessee", value: "tennessee" },
    { label: "Arkansas", value: "arkansas" },
    { label: "Indiana", value: "indiana" },
    { label: "Iowa", value: "iowa" },
    { label: "Georgia", value: "georgia" },
    { label: "Washington", value: "washington" },
    { label: "Connecticut", value: "connecticut" },
    { label: "Nebraska", value: "nebraska" },
    { label: "Florida", value: "florida" },
    { label: "Maine", value: "maine" },
    { label: "Nevada", value: "nevada" },
    { label: "Pennsylvania", value: "pennsylvania" },
    { label: "Texas", value: "texas" },
    { label: "Wisconsin", value: "wisconsin" },
    { label: "Colorado", value: "colorado" },
    { label: "Alaska", value: "alaska" },
    { label: "Alabama", value: "alabama" },
    { label: "New Jersey", value: "new-jersey" },
    { label: "West Virginia", value: "west-virginia" },
    { label: "Illinois", value: "illinois" },
    { label: "Michigan", value: "michigan" },
    { label: "Wyoming", value: "wyoming" },
    { label: "Rhode Island", value: "rhode-island" },
    { label: "South Dakota", value: "south-dakota" },
    { label: "Arizona", value: "arizona" },
    { label: "New York", value: "new-york" },
    { label: "Ohio", value: "ohio" },
    { label: "Minnesota", value: "minnesota" },
    { label: "Virginia", value: "virginia" },
    { label: "Delaware", value: "delaware" },
    { label: "Utah", value: "utah" },
    { label: "Vermont", value: "vermont" },
    { label: "New Hampshire", value: "new-hampshire" },
    { label: "Kentucky", value: "kentucky" },
    { label: "Maryland", value: "maryland" },
    { label: "Montana", value: "montana" },
    { label: "Oklahoma", value: "oklahoma" },
    { label: "Oregon", value: "oregon" },
    { label: "North Dakota", value: "north-dakota" },
    { label: "New Mexico", value: "new-mexico" },
    { label: "California", value: "california" },
  ];

  const isValidUSPhoneNumber = (phone) => {
    const phoneRegex =
      /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phoneRegex.test(phone);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Validate a field and return an error message if needed.
  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "firstName":
        if (!value.trim()) error = "First Name is required";
        break;
      case "lastName":
        if (!value.trim()) error = "Last Name is required";
        break;
      case "email":
        if (!value.trim()) error = "Email is required";
        else if (!isValidEmail(value)) error = "Invalid email format";
        break;
      case "phone":
        if (!value.trim()) error = "Phone number is required";
        else if (!isValidUSPhoneNumber(value)) error = "Invalid phone number";
        break;
      case "company":
        if (!value.trim()) error = "Company Name is required";
        break;
      // Add other cases if needed
      default:
        break;
    }
    return error;
  };

  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  // const handlePhone = (e) => {
  //   setPhoneErr("");
  //   let value = e.target.value;

  //   let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
  //   if (inputValue.length > 10) {
  //     inputValue = inputValue.slice(0, 10); // Limit to 10 digits
  //   }
  //   const formattedValue = inputValue.replace(
  //     /(\d{3})(\d{3})(\d{4})/,
  //     (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
  //   );
  //   if (inputValue.length === 10) {
  //     // Only show formatted value after 10 digits
  //     e.target.value = formattedValue;
  //     setPhone(formattedValue); // Update state with formatted number
  //   } else {
  //     e.target.value = inputValue;
  //     setPhone(inputValue);
  //   }

  //   if (value === "") {
  //     setPhoneErr(errorMessages.phoneRequired);
  //   }
  //   if (value && value.trim() !== "" && inputValue.length <= 9) {
  //     if (
  //       !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
  //         value
  //       )
  //     ) {
  //       setPhoneErr(errorMessages.phoneValidation);
  //     }
  //   }
  //   setPhone(e.target.value);
  // };




  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;
    let inputValue = value.replace(/\D/g, ""); // Remove non-digit characters
  
    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }
  
    const formattedValue = inputValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
    );
  
    // Only show formatted value after 10 digits
    const newPhoneValue = inputValue.length === 10 ? formattedValue : inputValue;
    e.target.value = newPhoneValue;
  
    // Update both the local state and formData state
    setPhone(newPhoneValue);
    setFormData((prevState) => ({
      ...prevState,
      phone: newPhoneValue,
    }));
  
    // Validate phone number and set errors if needed
    if (value === "") {
      setPhoneErr(errorMessages.phoneRequired);
    } else if (value && value.trim() !== "" && inputValue.length <= 9) {
      if (
        !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
          value
        )
      ) {
        setPhoneErr(errorMessages.phoneValidation);
      }
    }
  };
  
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  // Updated handleChange for live validation.
  const handleChange = (e) => {
    // For Primereact components, the event might not be a standard event.
    const fieldName = e.target ? e.target.name : e.name;
    const fieldValue = e.target ? e.target.value : e.value;

    // Update the form data.
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));

    // Validate the field immediately.
    const errorMessage = validateField(fieldName, fieldValue);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));
  };

  const handleRecaptchaChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      captcha: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, captcha: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let validationErrors = {};

    // Validate on submit as well.
    if (!formData.firstName.trim())
      validationErrors.firstName = "First Name is required";
    if (!formData.lastName.trim())
      validationErrors.lastName = "Last Name is required";
    if (!formData.email.trim()) validationErrors.email = "Email is required";
    else if (!isValidEmail(formData.email))
      validationErrors.email = "Invalid email format";

    if (!formData.phone.trim())
      validationErrors.phone = "Phone number is required";
    else if (!isValidUSPhoneNumber(formData.phone))
      validationErrors.phone = "Invalid US phone number";

    if (!formData.company.trim())
      validationErrors.company = "Company Name is required";

    if (!formData.captcha)
      validationErrors.captcha = "Please complete the CAPTCHA";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const postData = {
      lead_name: formData.company || "N/A",
      first_name: formData.firstName,
      last_name: formData.lastName || null,
      email: formData.email || null,
      phone_number: formData.phone.replace(/\D/g, ""), // Remove non-digit characters
      token: formData.captcha || null,
    };

    console.log(postData, "postdata");

    try {
      const response = await fetch(
        "https://chatbot.redberyltest.in/leads/add_leads_data",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(postData),
        }
      );

      console.log(response, "response");
      if (response.ok) {
        setModalShow(true);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          company: "",
          website: "",
          message: "",
          city: "",
          pincode: "",
          address: "",
          state: "",
          captcha: null,
        });
        setErrors({});
      } else {
        console.error("Error: API response not OK");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    setSelectedCountry({ name: "United States", code: "US", dialCode: "+1" });
    AOS.init();
  }, []);

  return (
    <section
      className="service-form"
      data-aos="fade-up"
      data-aos-duration="3000"
    >
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} md={10} sm={12} xs={12}>
            <Form onSubmit={handleSubmit}>
              <Row>
                <h4 className="service-form-title">Contact Information</h4>
                <div className="grid w-full">
                  {[
                    { name: "firstName", label: "* First Name", type: "text" },
                    { name: "lastName", label: "* Last Name", type: "text" },
                    { name: "email", label: "* Email Address", type: "email" },
                    // { name: "phone", label: "* Phone Number", type: "number" },
                  ].map((input) => (
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6 w-full p-1"
                      key={input.name}
                    >
                      <label
                        htmlFor={input.name}
                        className="block font-medium text-gray-700"
                      >
                        {input.label}
                      </label>
                      <InputText
                        id={input.name}
                        name={input.name}
                        type={input.type}
                        value={formData[input.name]}
                        onChange={handleChange}
                        required={input.required}
                        className="p-inputtext-sm w-full"
                      />
                      {errors[input.name] && (
                        <p className="text-red-500 text-sm">
                          {errors[input.name]}
                        </p>
                      )}
                    </div>
                  ))}
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 w-full">
                    <label
                      htmlFor="service"
                      className="block font-medium text-gray-700"
                    >
                      Phone Number
                    </label>
                    <div
                      className="p-inputgroup flex-1"
                      style={{ height: "40px", width: "100%" }}
                    >
                      <Dropdown
                        value={selectedCountry}
                        options={countries}
                        resetFilterOnHide
                        onChange={onCountryChange}
                        filter
                        optionLabel="code"
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                        style={{
                          width: "160px",
                          borderRadius: "6px 0px 0px 6px",
                        }}
                        className="p-dropdown font-fam-for-all"
                      />
                      <span className="" style={{ width: "100%" }}>
                        <div className="relative custom-input-mask">
                          <input
                            type="text"
                            id="floating_outlined101"
                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                            style={{
                              height: "40px",
                              borderRadius: "0px 6px 6px 0px",
                              width: "100%",
                            }}
                            maxLength={50}
                            value={phone}
                            onChange={handlePhone}
                            autoClear={false}
                            placeholder=""
                            name="cityName"
                          />
                          <label
                            htmlFor="floating_outlined101"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Phone<span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {phoneErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {phoneErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <h4 className="service-form-title mt-4">Entity Information</h4>
                <div className="grid w-full">
                  {[
                    { name: "company", label: "* Company Name", type: "text" },
                    {
                      name: "address",
                      label: "Residential Address",
                      type: "text",
                      required: false,
                    },
                    {
                      name: "city",
                      label: "City",
                      type: "text",
                      required: false,
                    },
                    {
                      name: "pincode",
                      label: "Pincode",
                      type: "number",
                      required: false,
                    },
                  ].map((input) => (
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6 w-full p-1"
                      key={input.name}
                    >
                      <label
                        htmlFor={input.name}
                        className="block font-medium text-gray-700"
                      >
                        {input.label}
                      </label>
                      <InputText
                        id={input.name}
                        name={input.name}
                        type={input.type}
                        value={formData[input.name]}
                        onChange={handleChange}
                        required={input.required}
                        className="p-inputtext-sm w-full"
                      />
                      {errors[input.name] && (
                        <p className="text-red-500 text-sm">
                          {errors[input.name]}
                        </p>
                      )}
                    </div>
                  ))}
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-1">
                  <label
                    htmlFor="state"
                    className="block font-medium text-gray-700"
                  >
                    State
                  </label>
                  <Dropdown
                    id="state"
                    name="state"
                    options={states}
                    optionLabel="label"
                    optionValue="value"
                    value={formData.state}
                    onChange={handleChange}
                    placeholder="Select a state"
                    className="w-full"
                  />
                  {errors.state && (
                    <p className="text-red-500 text-sm">{errors.state}</p>
                  )}
                </div>

                <div className="col-12 w-full p-1">
                  <label
                    htmlFor="message"
                    className="block font-medium text-gray-700"
                  >
                    Message
                  </label>
                  <InputTextarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows={5}
                    cols={30}
                    autoResize
                    className="w-full"
                  />
                  {errors.message && (
                    <p className="text-red-500 text-sm">{errors.message}</p>
                  )}
                </div>
              </Row>
              <div>
                <ReCAPTCHA
                  sitekey="6LeYwM0qAAAAAC-arhPe0I2PEaCHs0AjXEBMjnNh"
                  onChange={handleRecaptchaChange}
                />
                {errors.captcha && (
                  <p className="text-red-500 text-sm">{errors.captcha}</p>
                )}
              </div>
              <div className="main-class-submit text-center mt-4 mb-5">
                <Button
                  label="Submit"
                  type="submit"
                  className="vstate-button w-full"
                />
              </div>
            </Form>
          </Col>
        </Row>
        <SuccessfulModal show={modalShow} onHide={() => setModalShow(false)} />
      </Container>
    </section>
  );
};

export default ServiceForm;
