import React, { useState, useEffect } from "react";
import { Checkbox } from "primereact/checkbox";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const ProductCatalog = () => {
    const [selectedCheckBoxData, setSelectedCheckBoxData] = useState([]);
    const [checkedState, setCheckedState] = useState({});

    const servicesData = [
        {
            service: "EIN / TAX ID#",
            description:
                "This is state-issued document with certification on it. The document has an official seal and authentication by the state. Often times, a certified copy is required when attempting to obtain financing for your business.",
            amount: "$230",
        },
        {
            service: "Certified Copy",
            description:
                "This is state-issued document with certification on it. The document has an official seal and authentication by the state.",
            amount: "$230",
        },
        {
            service: "LLC Publication",
            description:
                "Many states mandate that a legal notice of the entity be published in certain papers for six weeks.",
            amount: "$230",
        },
        {
            service:
                "Preparation of S Corp Election Form (Form 2553 for IRS)",
            description:
                "This is especially pertinent in real estate transaction.",
            amount: "$100",
        },
        {
            service:
                "BOI (Beneficial Ownership Information) with initial filling - after filling $99 (Required within 90 days of formation)",
            description:
                "Name change of business, address Change, mission of the business, updating the Capital of the entity, & having the Corporate Shares par value altered.",
            amount: "$59",
        },
    ];

    const handleCheckedChange = (e, serviceData) => {
        const { checked } = e.target;

        setCheckedState((prev) => ({
            ...prev,
            [serviceData.service]: checked,
        }));

        if (checked) {
            setSelectedCheckBoxData((prev) => [...prev, serviceData]);
        } else {
            setSelectedCheckBoxData((prev) =>
                prev.filter((item) => item.service !== serviceData.service)
            );
        }
    };

    // Initialize Bootstrap tooltips
    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltipTriggerList.forEach((tooltipTriggerEl) => {
            new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
    }, []);

    const totalAmount = selectedCheckBoxData.reduce(
        (acc, item) => acc + parseFloat(item.amount.replace("$", "")),
        0
    );

    return (
        <div className="container">
            <div
                className="wizard-content-sub-card-header my-3"
                id="header"
            >
                <div className="wizard-content-sub-card-text-frame">
                    <div className="wizard-content-sub-card-text">
                        Select services you want to avail
                    </div>
                </div>
            </div>

            {servicesData.map((serviceData, index) => (
                <div
                    key={index}
                    className="wizard-card-checkbox-container mb-4 hover-card slide-in"
                >
                    <div className="wizard-card-checkbox-main">
                        <div className="wizard-card-checkbox-text-main">
                            <div className="wizard-card-checkbox-action-container">
                                <Checkbox
                                    className="mt-2"
                                    checked={checkedState[serviceData.service] || false}
                                    onChange={(e) =>
                                        handleCheckedChange(e, serviceData)
                                    }
                                />
                            </div>
                            <div className="w-full grid">
                                <div className="col-11">
                                    <div className="wizard-card-checkbox-text1 font-fam-for-all text-start">
                                        <div className="flex align-items-center">
                                            {serviceData.service}
                                            <button
                                                className="ml-2 bg-transparent border-none cursor-pointer"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="right"
                                                title={serviceData.description}
                                            >
                                                <i
                                                    className="pi pi-info-circle"
                                                    style={{
                                                        fontSize: "1.2rem",
                                                        color: "#007bff",
                                                    }}
                                                ></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="wizard-card-checkbox-text2 col-1 flex align-items-center">
                                    {serviceData.amount}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <div className="wizard-checkout-container">
                <div className="wizard-checkout-main">
                    <div className="wizard-checkout-header justify-content-between">
                        <div className="wizard-checkout-text-main">
                            <div className="wizard-checkout-text">
                                CART SUMMARY
                            </div>
                        </div>
                        <div className="flex">
                            <i className="pi pi-pencil"></i>
                        </div>
                    </div>

                    <div className="wizard-checkout-body">
                        {selectedCheckBoxData.map((val, index) => (
                            <React.Fragment key={index}>
                                <div className="wizard-checkout-content-main">
                                    <div className="wizard-checkout-content-price-main">
                                        <div className="wizard-price-content1">
                                            {val.service}
                                        </div>
                                        <div className="wizard-price-content2">
                                            {val.amount}
                                        </div>
                                    </div>
                                </div>

                                <div className="wizard-card-devider"></div>
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="wizard-checkout-footer">
                        <div className="wizard-checkout-text-footer">
                            <div className="wizard-checkout-footer-txt">
                                TOTAL (TAX DEDUCTIBLE)
                            </div>
                        </div>
                        <div className="wizard-total-price">
                            {`$${totalAmount}`}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCatalog;
