import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import authContext from "../../common/authContext";
import AddServiceForm from "../AddComponents/AddServiceForm";
import { Sidebar } from 'primereact/sidebar';


function ServiceCard({ title, description, features }) {
  const { setAddOnServiceData, setOrderTypeId, addonServiceRedirectedData, setAddOnServiceRedirectedData } = useContext(authContext);
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false)

  const [servieFetchData, setServiceFetchData] = useState(null)

  // State to track the tooltip visibility for each feature
  const [tooltipVisible, setTooltipVisible] = useState({});

  const handleNavigate = (data, orderType, addOnData) => {
    if (data !== "" && orderType !== null) {
      setAddOnServiceData(data);
      setOrderTypeId(orderType);
      setAddOnServiceRedirectedData(addOnData)
      navigate("/addonservice");
    }
    else {
      setVisible(true)
      setServiceFetchData(addOnData)
    }

  };

  const handleNavigate1 = (data, orderType, addOnData) => {
    if (data !== "" && orderType !== null) {
      setAddOnServiceData(data);
      setOrderTypeId(orderType);
      setAddOnServiceRedirectedData(addOnData)
      navigate("/orderformcgs");
    }
    else {
      setVisible(true)
      setServiceFetchData(addOnData)
    }

  };

  // Handle tooltip show/hide for individual feature
  const showTooltip = (index) => {
    setTooltipVisible((prevState) => ({ ...prevState, [index]: true }));
  };

  const hideTooltip = (index) => {
    setTooltipVisible((prevState) => ({ ...prevState, [index]: false }));
  };

  return (
    <div className="flex gap-6 items-start mt-4 w-full max-md:max-w-full">
      <div className="flex overflow-hidden flex-wrap flex-1 shrink gap-5 justify-center p-4 w-full bg-white-50 rounded-lg shadow-md basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col flex-1 shrink my-auto basis-0 min-w-[240px] max-md:max-w-full">
          <div className="flex flex-col w-full max-md:max-w-full">
            <div className="flex flex-col w-full max-md:max-w-full">
              <p className="text-2xl mb-0 font-medium tracking-tight leading-loose text-gray-900 max-md:max-w-full">
                {title}
              </p>
              <p className="text-base text-gray-500 max-md:max-w-full">{description}</p>
              <div className="card-devider"></div>
            </div>
            <div className="flex flex-wrap gap-3 items-start mt-3 w-full max-md:max-w-full">
              <div className="grid w-full">
                {features.map((feature, index) => (
                  <div key={index} className="col-3 flex align-item-center">
                    <button
                      className="flex gap-1 items-center"

                      onClick={() =>
                        feature.isFirst
                          ? handleNavigate(feature.path, feature.orderType, feature)
                          : handleNavigate1(feature.path, feature.orderType, feature)
                      }
                    >
                      <span className="self-stretch my-auto text-sm font-medium leading-6 bulk-upload-card-header-text">
                        {feature.itemName}
                      </span>

                      <div
                      >
                        <button data-toggle="tooltip" data-placement="bottom" title={feature.description} className="ml-2 bg-transparent border-none cursor-pointer button-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M6.99984 4.6665V6.99984M6.99984 9.33317H7.00567M12.8332 6.99984C12.8332 10.2215 10.2215 12.8332 6.99984 12.8332C3.77818 12.8332 1.1665 10.2215 1.1665 6.99984C1.1665 3.77818 3.77818 1.1665 6.99984 1.1665C10.2215 1.1665 12.8332 3.77818 12.8332 6.99984Z"
                              stroke="#004EEC"
                              strokeWidth="1.16667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>

                      </div>

                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        servieFetchData && visible && <Sidebar position='right' visible={visible} className='dialog-box' onHide={() => setVisible(false)}  >
          <div className='grid custom-sidebar-header'>
            <div className='grid col-11 px-4 pt-4 pb-0'>
              <div className='col-12 sidebar-header-text1 pb-0'>
                Request for {servieFetchData.itemName} service
              </div>

            </div>
            <div className='col-1 flex justify-content-end pt-4 pb-0'>
              <button onClick={() => setVisible(false)}><i className='pi pi-times'></i></button>
            </div>
            <div className='col-12 sidebar-header-text2 px-4'>
              {servieFetchData.description1}
            </div>
          </div>
          <AddServiceForm service={servieFetchData.itemName} setVisible={setVisible}/>
        </Sidebar>
      }

    </div>

  );
}

export default ServiceCard;
