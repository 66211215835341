import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { BsX } from 'react-icons/bs';
import './PaymentModal.css';
import ConfirmPaymentModal from './ConfirmPaymentModal';
import { Sidebar } from 'primereact/sidebar';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import PaymentComponent from '../../utils/PaymentComponent';
import OrderServices from '../../services/orderServices';
import { Toast } from 'primereact/toast';
import authContext from '../../common/authContext';

const PaymentModal = ({ show, onHide, orderId, amount, data, orderLineItem }) => {
  const { loginUserName, isTabRefresh, setIsTabRefresh } = useContext(authContext);
  const toast = useRef(null)
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [FormationDate, setFormationDate] = useState(dayjs(new Date()));
  const [visible, setVisible] = useState(false)
  const [formData, setFormData] = useState({
    transactionId: '',
    transactionDate: '',
    amount: '',
    bankName: '',
    checkNumber: ''
  });
  const [showConfirm, setShowConfirm] = useState(false);

  const [openDateErr, setOpenDateErr] = useState("");

  const [errors, setErrors] = useState({

  });

  const [activeButton, setActiveButton] = useState("cash")


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  function formatLabel(label) {
    return label
      .replace(/([A-Z])/g, ' $1')
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase());
  }

  const handleOpenDate = (newValue) => {
    setOpenDateErr("");
    errors['transactionDate'] = ''
    const currentDate = dayjs();
    console.log(newValue);

    if (newValue && dayjs(newValue).isValid()) {
      if (dayjs(newValue).isAfter(currentDate)) {
        setOpenDateErr("Transaction date cannot be in the future");
        setFormationDate(null);
      } else {
        setFormationDate(newValue);
        formData.transactionDate = newValue
      }
    } else {
      setFormationDate(null);
      setOpenDateErr("Transaction date required");
    }
  };
  useEffect(() => {
    if (orderId) {
      const timestamp = dayjs().format("YYYYMMDDHHmmss");
      formData.transactionId = `TX_${orderId}_${timestamp}`
    }
    if (amount) {
      formData.amount = `$${amount}`
      formData.transactionDate = dayjs(new Date())
    }

  }, [orderId, amount])

  useEffect(() => {
    if (paymentMethod === "online") {
      onHide();
      setVisible(true)
      setPaymentMethod("cash")
      setActiveButton("cash")
    }
  }, [paymentMethod])

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (paymentMethod === "check") {
      const requiredFields = ['bankName', 'checkNumber'];
      requiredFields.forEach((field) => {
        if (!formData[field]) {
          newErrors[field] = `${formatLabel(field)} is required.`;
          isValid = false;
        }
      });
      setErrors(newErrors);
    }

    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (paymentMethod === "online") {
      onHide();
      setVisible(true)
    }
    else {
      if (validateForm() && openDateErr === "") {
        onHide();
        setShowConfirm(true);
      }
    }
  };



  const handleCancel = () => {
    formData.bankName = ""
    formData.checkNumber = ""
    formData.transactionDate = ""
    setFormationDate(dayjs(new Date()))
    setOpenDateErr("")
    setErrors({})
    setPaymentMethod("cash")
    setActiveButton("cash")
    onHide();
  }

  const handleConfirmPayment = () => {
    console.log('Payment confirmed:', { paymentMethod, ...formData });
    const actualdata = {
      "bankName": formData.bankName !== "" ? formData.bankName : null,
      "checkNumber": formData.checkNumber !== "" ? formData.checkNumber : null,
      "transactionDate": formData.transactionDate,
      "transactionId": formData.transactionId,
      "amount": amount,
      "orderDetails": data
    }
    setIsTabRefresh(true)
    OrderServices.saveTransaction(actualdata)
      .then((res) => {
        console.log(res)
        if (data && orderLineItem) {
          data["orderLineItems"] = orderLineItem
          data["transactionDate"] = formData.transactionDate
          data["transactionId"] = formData.transactionId
          data["generateReceipt"] = true
          data["orderUpdatedBy"] = loginUserName
          data["orderUpdateNotes"] = "Payment done successfully"
          data["stateId"] = data?.domicileStateId
          data["countyId"] = data?.countyId

          OrderServices.saveAllOrder(data)
            .then((res) => {
              console.log(res)
              formData.bankName = ""
              formData.checkNumber = ""
              formData.transactionDate = ""
              setFormationDate(dayjs(new Date()))
              setOpenDateErr("")
              setErrors({})

              setIsTabRefresh(false)
              const orderRecieptData = {
                id: res.data?.orderDetails?.id,
                generateReceiptValue: true
              }
              OrderServices.saveAllSalesReciept(orderRecieptData)
                .then((res) => {
                  console.log(res)
                })
                .catch((err) => {
                  console.log(err)
                })
              toast?.current.show({
                severity: "success",
                summary: "Success",
                content: (props) => (
                  <div
                    className="flex flex-column align-items-left"
                    style={{ flex: "1" }}
                  >
                    <div className="flex align-items-center gap-2">
                      <span className="p-toast-summary">
                        vState Filings
                      </span>
                    </div>
                    <div className="p-toast-detail">
                      Transaction updated successfully
                    </div>
                  </div>
                ),
                life: 3000,
              });
            })
            .catch((err) => {
              console.log(err)
              setIsTabRefresh(false)
            })
        }

      })
      .catch((err) => {
        console.log(err)
        formData.bankName = ""
        formData.checkNumber = ""
        formData.transactionDate = ""
        setFormationDate(dayjs(new Date()))
        setOpenDateErr("")
        setErrors({})
        setIsTabRefresh(false)
        toast?.current?.show({
          severity: "error",
          summary: "Transaction failed",
          detail: "Transaction update failed",
          life: 3000,
        });
      })

  };


  return (
    <>
      <Toast ref={toast} />
      <Sidebar
        position="right"
        visible={show}
        className="dialog-box"
        onHide={handleCancel}
      >
        <div className="grid custom-sidebar-header px-4">
          <div className="grid col-12 col-sm-12 col-md-12 col-lg-11  pt-4 pb-0">
            <div className="col-12 sidebar-header-text1 pb-0">
              Payment for <span className="text-primary">Order# {orderId}</span>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-1 flex justify-content-end pt-4 pb-0">
            <button onClick={handleCancel}>
              <i className="pi pi-times"></i>
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 sidebar-header-text2 ">
            Pay for your order here.
          </div>
        </div>


        <Modal.Body className='p-4'>
          <p className="text-muted font-fam-for-all">Select your desired payment method.</p>
          <div className="flex pb-4 flex-col w-full text-sm font-medium leading-none max-md:max-w-full">
            <div className="flex flex-wrap gap-10 justify-between items-start w-full max-md:max-w-full">
              <div className="flex items-start w-[50%] h-10 shadow-sm text-slate-700 border border-gray-300 rounded-lg overflow-hidden align-items-center">
                <button
                  className={`flex-1 px-4 py-2 ${activeButton === "cash" ? 'vstate-split-button text-white ' : 'bg-white  text-slate-800 hover:bg-gray-100'}  border-r h-full`}
                  onClick={() => {
                    setPaymentMethod('cash')
                    setActiveButton("cash")
                  }}
                >
                  Cash
                </button>
                <button
                  className={`flex-1 px-4 py-2 ${activeButton === "check" ? 'vstate-split-button text-white' : 'bg-white  text-slate-800 hover:bg-gray-100'}  border-r h-full`}
                  onClick={() => {
                    setPaymentMethod('check')
                    setActiveButton("check")

                  }}
                >
                  Check
                </button>
                <button
                  className={`flex-1 px-4 py-2 ${activeButton === "online" ? 'vstate-split-button text-white' : 'bg-white  text-slate-800 hover:bg-gray-100'}  border-r h-full`}
                  onClick={() => {
                    setPaymentMethod('online')
                    setActiveButton("online")

                  }}
                >
                  Online
                </button>

              </div>
            </div>
          </div>
          <Form onSubmit={handleSubmit}>


            {paymentMethod === 'cash' && (
              <div className='p-4 vstate-card'>
                {/* <div className="d-flex justify-content-center mb-3">
                  <img src="/path/to/cash-image.jpg" alt="Cash Payment" className="cash-image" />
                </div> */}
                <Form.Group className="mb-3">
                  <Form.Label className="font-fam-for-all">Transaction ID <span className="form-field-mandatory">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="transactionId"
                    disabled
                    value={formData.transactionId}
                    onChange={handleInputChange}
                    placeholder="#1234567"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="font-fam-for-all">Transaction Date <span className="form-field-mandatory">*</span></Form.Label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={FormationDate}
                      onChange={handleOpenDate}
                      disableFuture
                      slotProps={{
                        textField: {
                          size: "small",
                          error: false,
                          fullWidth: true,
                        },
                      }}
                      className="dialog-form-dropdown-field"
                    />
                  </LocalizationProvider>
                  {openDateErr && <small className="error-msg font-fam-for-all">{openDateErr}</small>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="font-fam-for-all">Amount <span className="form-field-mandatory">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="amount"
                    disabled
                    value={formData.amount}
                    onChange={handleInputChange}
                    placeholder="$4321"
                  />
                </Form.Group>
              </div>
            )}

            {paymentMethod === 'check' && (
              <div className='p-4 vstate-card'>
                <Form.Group className="mb-3">
                  <Form.Label className="font-fam-for-all">Bank Name <span className="form-field-mandatory">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="bankName"
                    value={formData.bankName}
                    onChange={handleInputChange}
                    placeholder="Bank Name"
                  />
                  {errors.bankName && <small className="error-msg font-fam-for-all">{errors.bankName}</small>}

                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="font-fam-for-all">Check Number <span className="form-field-mandatory">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="checkNumber"
                    value={formData.checkNumber}
                    onChange={handleInputChange}
                    placeholder="123456"
                  />
                  {errors.checkNumber && <small className="error-msg font-fam-for-all">{errors.checkNumber}</small>}

                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="font-fam-for-all">Transaction ID <span className="form-field-mandatory">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="transactionId"
                    disabled
                    value={formData.transactionId}
                    onChange={handleInputChange}
                    placeholder="#1234567"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="font-fam-for-all">Transaction Date <span className="form-field-mandatory">*</span></Form.Label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={FormationDate}
                      onChange={handleOpenDate}
                      disableFuture
                      slotProps={{
                        textField: {
                          size: "small",
                          error: false,
                          fullWidth: true,
                        },
                      }}
                      className="dialog-form-dropdown-field"
                    />
                  </LocalizationProvider>
                  {openDateErr && <small className="error-msg font-fam-for-all">{openDateErr}</small>}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="font-fam-for-all">Amount <span className="form-field-mandatory">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="amount"
                    disabled
                    value={formData.amount}
                    onChange={handleInputChange}
                    placeholder="$4321"
                  />
                </Form.Group>
              </div>
            )}

            {/* {paymentMethod === 'online' && (
              <p className="text-muted font-fam-for-all">Online payment details will be captured later.</p>
            )} */}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <div className="flex justify-content-end dialog-form-md-group-Btn">
            <Button className="vstate-reverse-button  font-fam-for-all text-center mr-3" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className=" vstate-button font-fam-for-all text-center  form-label" onClick={handleSubmit}>
              {paymentMethod === 'online' ? "Pay now" : "Submit"}
            </Button>
          </div>
        </Modal.Footer>
      </Sidebar>

      <ConfirmPaymentModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        onConfirm={handleConfirmPayment}
      />
      <PaymentComponent closeProgresbar3={visible} setCloseProgresbar3={setVisible} data={data} />
    </>
  );
};

export default PaymentModal;
