const validationConfig = {
    LLC: {
        "Alabama": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Alaska": /(LLC|limited liability company|LL\.C\.)$/i,
        "Arizona": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Arkansas": /(LLC|Limited Liability Company|Limited Company|LL\.C\.)$/i,
        "California": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Colorado": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Connecticut": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Delaware": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Florida": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Georgia": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Hawaii": /(LLC|Limited Liability Company|Limited Liability Law Company|LL\.C\.)$/i,
        "Idaho": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Illinois": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Indiana": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Iowa": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Kansas": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Kentucky": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Louisiana": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Maine": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Maryland": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Massachusetts": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Michigan": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Minnesota": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Mississippi": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Missouri": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Montana": /(LLC|Limited Liability Company|Limited Company|Limited Liability Co\.|LL\.C\.)$/i,
        "Nebraska": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Nevada": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "New Hampshire": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "New Jersey": "",
        "New Mexico": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "New York": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "North Carolina": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "North Dakota": /(LLC|limited liability company|LL\.C\.)$/i,
        "Ohio": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Oklahoma": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Oregon": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Pennsylvania": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Rhode Island": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "South Carolina": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "South Dakota": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Tennessee": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Texas": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Utah": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Vermont": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Virginia": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Washington": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "West Virginia": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Wisconsin": /(LLC|Limited Liability Company|LL\.C\.)$/i,
        "Wyoming": /(LLC|Limited Liability Company|LL\.C\.)$/i
    },
    Corp: {
        "Alabama": /(corporation|corp\.|incorporated|Inc\.)$/i,
        "Alaska": /(corporation|corp\.|limited|ltd\.|incorporated|inc\.)$/i,
        "Arizona": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Arkansas": /(Corporation|Corp\.|Company|Co\.|Incorporated|Inc\.)$/i,
        "California": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Colorado": /(corporation|corp\.|limited|Ltd\.|incorporated|Inc\.|company)$/i,
        "Connecticut": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Delaware": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Florida": /(Corporation|Corp\.|Incorporated|Inc\.)$/i,
        "Georgia": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Hawaii": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Idaho": /(Corporation|Corp\.|Incorporated|Inc\.)$/i,
        "Illinois": /(CORPORATION|CORP\.|LIMITED|LTD\.|INCORPORATED|INC\.)$/i,
        "Indiana": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Iowa": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Kansas": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Kentucky": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Louisiana": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Maine": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Maryland": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Massachusetts": /(CORPORATION|CORP\.|LIMITED|LTD\.|INCORPORATED|INC\.|COMPANY)$/i,
        "Michigan": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.|Company)$/i,
        "Minnesota": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Mississippi": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Missouri": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Montana": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.|Company)$/i,
        "Nebraska": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Nevada": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "New Hampshire": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "New Jersey": "",
        "New Mexico": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "New York": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "North Carolina": /(corporation|corp\.|limited|ltd\.|incorporated|inc\.)$/i,
        "North Dakota": /(corporation|corp\.|limited|ltd\.|incorporated|inc\.)$/i,
        "Ohio": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Oklahoma": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Oregon": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Pennsylvania": /(CORPORATION|CORP\.|LIMITED|LTD\.|INCORPORATED|INC\.)$/i,
        "Rhode Island": /(CORPORATION|CORP\.|LIMITED|LTD\.|INCORPORATED|INC\.)$/i,
        "South Carolina": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "South Dakota": /(CORPORATION|CORP\.|LIMITED|LTD\.|INCORPORATED|INC\.|ASSOCIATION)$/i,
        "Tennessee": /(CORPORATION|CORP\.|LIMITED|LTD\.|INCORPORATED|INC\.)$/i,
        "Texas": /(Corporation|corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Utah": /(corporation|corp\.|limited|Ltd\.|incorporated|inc\.)$/i,
        "Vermont": /(corporation|corp\.|limited|ltd\.|incorporated|inc\.)$/i,
        "Virginia": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Washington": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "West Virginia": /(Corp\.Ltd\.Inc\.)$/i,
        "Wisconsin": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i,
        "Wyoming": /(Corporation|Corp\.|Limited|Ltd\.|Incorporated|Inc\.)$/i
    }
};


export default validationConfig;