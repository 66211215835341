export const tableJson = {
    state: "/core/api/stateMaster/getAll",
    parentCompanyListing: "/contact/api/parentCompanyListings/getAll",
    annoucement: "/core/api/announcement/getAll",
    employee: "/contact/api/employeeDetails/getAll",
    company: "/contact/api/companyDetails/getAll",
    roleMaster: "/core/api/roleMaster/getAll",
    addressType: "/contact/api/addressType/getAll",
    countryMaster: "/core/api/countryMaster/getAll",
    keyPersonnelType: "/core/api/keyPersonnelTypeMaster/getAll",
    orderTypeMaster: "/core/api/orderTypeMaster/getAll",
    templateMaster: "/core/api/templateMaster/getAll",
    statusMaster: "/core/api/statusMaster/getAll",
    entityTypeMaster: "/core/api/entityTypeMaster/getAll",
    contentType: "/contact/api/content-types",
    strapiComponents: "/contact/api/getAll/components",
    productStrapi: "/order/api/productStrapiAPI/getAll",
    applicantType: "/order/api/applicantType/getAll",
    countyMaster: "/core/api/countyMaster/getAll",
    naicsSubCode: "/core/api/naicsSubCodes/getAll",
    naicsCode: "/core/api/stateWiseNaicsCodes/getAll",
    industryMaster: "/core/api/industryMaster/getAll",
    activeEmployee: "/contact/api/employeeDetails/getAll/active",
    config: "/contact/api/config/getAll",
    productCatalog: "/order/api/productCatalog/getAll"
};
