// axiosConfig.js
import axios from 'axios';

// Define your whitelist endpoints that should bypass the 401 redirect.
const whitelist = ['/authentication/',];

// Set up the interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401 && error.response?.data === 'Unauthorized access') {
      // Access the URL of the request that caused the error.
      const requestUrl = error.config.url;
      // Check if the URL is in the whitelist.
      const isWhitelisted = whitelist.some(endpoint => requestUrl.includes(endpoint));
      
      if (!isWhitelisted) {
        console.log("401 intercepted");
        // window.location.href = '#/test';
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
