import axios from "axios";
import authHeader, { initAuth } from "./authHeader";
import encryption from "./CryptoClass";
import propertiesApp from "../utils/propertiesApp";
import "./../services/axiosConfig.js"

const getAllAddressByContactId = (id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/address/getByContactId/${id}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,
            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const getAllAddressByCompanyId = (id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/address/getByCompanyId//${id}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const saveAllAddress = (data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/address/save`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const updateAllAddress = (data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/address/update`,
        payload: data,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllAddressType = () => {
    const dataToEncrypt = {
        endpoint: `/contact/api/addressType/getAll`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllStates = () => {
    const dataToEncrypt = {
        endpoint: `/core/api/stateMaster/getAll`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllCountry = () => {
    const dataToEncrypt = {
        endpoint: `/core/api/countryMaster/getAll`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllCountyByState = (id) => {
    const dataToEncrypt = {
        endpoint: `/core/api/countyMaster/getByState/${id}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}




const AddressService = {
    getAllAddressByContactId,
    saveAllAddress,
    updateAllAddress,
    getAllAddressType,
    getAllStates,
    getAllCountry,
    getAllAddressByCompanyId,
    getAllCountyByState
}

export default AddressService;