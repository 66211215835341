import React from 'react';

const FormationServices = () => {
  const services = [
    { title: 'C corporation', description: 'A C corporation, under United States federal income tax law, refers to any corporation that is taxed separately from its owners.' },
    { title: 'S corporation', description: 'S corporation are businesses that prefer to pass their income, deductions, losses, and credit through shareholders.' },
    { title: 'LLC', description: 'A Limited liability company is similar in many ways to an S Corporation but is more flexible and faces fewer rules and regulations.' },
    { title: 'LLP', description: 'LLPs can be formed by state-licensed professionals like accountants, doctors, and attorneys.' },
    { title: 'LP', description: 'A limited partnership (LP) is similar to a general partnership which offers limited liability protection to some of the partners.' },
    { title: 'PC or PLLC', description: 'PLLC is a type of limited liability company that is owned and operated by members of the same profession.' }
  ];

  return (
    <section className="flex flex-col items-center self-stretch px-8 mt-7 w-full text-center max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col justify-center p-2.5 max-w-full w-[1140px]">
        <h2 className="flex flex-col w-full text-5xl font-medium tracking-tighter leading-tight text-blue-700 max-md:max-w-full">
          <span className="text-blue-700">Formation</span>
          <span className="text-slate-700"> Services</span>
        </h2>
        <p className="flex flex-col px-72 mt-5 w-full text-xl text-gray-800 h-[59px] max-md:px-5 max-md:max-w-full">
          We offer comprehensive services to help you establish and operate your US Formation Services.
        </p>
        <div className="flex flex-col justify-center p-2.5 mt-5 w-full min-h-[949px] text-zinc-700">
          <div className="flex flex-col w-full max-md:max-w-full">
            <nav className="flex flex-col justify-center pb-5 w-full text-base font-medium leading-none text-blue-700 uppercase max-md:max-w-full">
              <ul className="flex flex-wrap gap-10 justify-center items-center w-full max-md:max-w-full">
                <li className="self-stretch px-7 py-3.5 my-auto bg-white rounded border-2 border-blue-700 border-solid shadow-[0px_0px_10px_rgba(0,0,0,0.1)] w-[193px] max-md:px-5">
                  Entity Formation
                </li>
                <li className="self-stretch px-7 py-3.5 my-auto bg-white rounded min-w-[240px] shadow-[0px_0px_10px_rgba(0,0,0,0.1)] max-md:px-5">
                  Government Filings and Compliance
                </li>
                <li className="self-stretch px-7 py-3.5 my-auto bg-white rounded shadow-[0px_0px_10px_rgba(0,0,0,0.1)] max-md:px-5">
                  Additional Services
                </li>
              </ul>
            </nav>
            <div className="flex flex-col items-center  w-full max-md:px-5 max-md:max-w-full">
              <div className="flex flex-wrap justify-center items-start w-full  max-md:max-w-full">
                {services.slice(0, 2).map((service, index) => (
                  <div key={index} className="flex flex-wrap flex-1 shrink justify-center items-start p-4 basis-0 min-w-[340px] max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px]">
                      <div className="flex overflow-hidden flex-col w-full rounded-lg shadow-lg bg-white bg-opacity-0">
                        <div className="flex overflow-hidden flex-col justify-center w-full">
                          <div className="flex overflow-hidden flex-col justify-center px-5 py-10 w-full bg-gray-50 min-h-[229px]">
                            <h3 className="flex flex-col pb-4 w-full text-3xl font-medium leading-none">
                              <div className="px-12 w-full max-md:px-5">
                                {service.title}
                              </div>
                            </h3>
                            <p className="flex flex-col pb-4 w-full text-base font-light leading-7">
                              <div className="px-1 w-full">
                                {service.description}
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col items-center w-full max-md:px-5 max-md:max-w-full">
              <div className="flex flex-wrap justify-center items-start w-full  max-md:max-w-full">
                {services.slice(2, 4).map((service, index) => (
                  <div key={index} className="flex flex-wrap flex-1 shrink justify-center items-start p-4 basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px]">
                      <div className="flex overflow-hidden flex-col w-full rounded-lg shadow-lg bg-white bg-opacity-0">
                        <div className="flex overflow-hidden flex-col justify-center w-full">
                          <div className="flex overflow-hidden flex-col justify-center px-5 py-10 w-full bg-gray-50 min-h-[229px]">
                            <h3 className="flex flex-col pb-4 w-full text-3xl font-medium leading-none whitespace-nowrap">
                              <div className="px-12 w-full max-md:px-5">
                                {service.title}
                              </div>
                            </h3>
                            <p className="flex flex-col pb-4 w-full text-base font-light leading-7">
                              <div className="px-1 w-full">
                                {service.description}
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col items-center w-full max-md:px-5 max-md:max-w-full">
              <div className="flex flex-wrap justify-center items-start w-full  max-md:max-w-full">
                {services.slice(4).map((service, index) => (
                  <div key={index} className="flex flex-wrap flex-1 shrink justify-center items-start p-4 basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full">
                    <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px]">
                      <div className="flex overflow-hidden flex-col w-full rounded-lg shadow-lg bg-white bg-opacity-0">
                        <div className="flex overflow-hidden flex-col justify-center w-full">
                          <div className="flex overflow-hidden flex-col justify-center px-5 py-10 w-full bg-gray-50 min-h-[229px]">
                            <h3 className="flex flex-col pb-4 w-full text-3xl font-medium leading-tight">
                              <div className="px-2 w-full">{service.title}</div>
                            </h3>
                            <p className="flex flex-col pb-4 w-full text-base font-light leading-7">
                              <div className="px-3.5 w-full">
                                {service.description}
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center px-32 w-full text-base font-medium leading-none text-white max-md:px-5 max-md:max-w-full">
        <button className="px-7 py-3.5 vstate-button rounded shadow-[0px_0px_10px_rgba(0,0,0,0.5)] max-md:px-5">
          Get Quote
        </button>
      </div>
    </section>
  );
};

export default FormationServices;