import React from "react";
import { Container } from "react-bootstrap";

const ContactLocation = () => {
  return (
    <section>
      <Container>
        <div className="mb-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10183.087349016072!2d-73.70654138678151!3d40.642010225214115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c26455b8e5de63%3A0x182836676bff3aa2!2s301%20Mill%20Rd%20u5%2C%20Hewlett%2C%20NY%2011557%2C%20USA!5e0!3m2!1sen!2sin!4v1739071161720!5m2!1sen!2sin"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="w-full"
          ></iframe>
        </div>
      </Container>
    </section>
  );
};

export default ContactLocation;
