import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";
import ServiceCommonCard from "../../CommonSection/ServiceCommonCard";
import AOS from "aos";
import "aos/dist/aos.css";

const DocumentRetrivalThird = () => {
  const [showMore, setShowMore] = useState(false);
  
  const services = [
    {
      title: "Certificate of Good Standing",
      description:
        "A document issued by a state that certifies a business is compliant with state regulations. It may be required for various transactions, including loans and contracts.",
    },
    {
      title: "Formation Document",
      description:
        "The official documents filed to establish a business entity, such as Articles of Incorporation or Organization. These documents are essential for verifying the existence of the entity.",
    },
    {
      title: "Amendment Document",
      description:
        "Documentation related to changes made to an existing business entity, such as changes in structure, ownership, or operational details. This serves as a legal record of amendments.",
    },
    {
      title: "Certified Copies",
      description:
        "This is state-issued document with certification on it. The document has an official seal and authentication by the state. Often times, a certified copy is required when attempting to obtain financing for your business.",
    },
    {
      title: "Certificate of Change",
      description:
        "A Certificate of Change is a legal document filed with the state to update specific details of a business entity.",
    },
    {
      title: "Certificate of Dissolution",
      description:
        "Articles of dissolution are formed in order to terminate a company.",
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section data-aos="fade-up" data-aos-duration="3000">
      <Container>
        <CommonHeadingTitle blueheading={"Document retrieval services"} />
        <div className="text-center mt-5 mb-5">
          <h4 className="subheading">
            Document retrieval services provide you with certified copies of
            critical business documents, including certificates of good
            standing, formation documents, and amendment documents. This ensures
            you always have access to important paperwork for compliance and
            operational needs.
          </h4>
        </div>
        <Row className="justify-content-center">
          {services.slice(0, showMore ? services.length : 3).map((service, index) => (
            <Col key={index} lg={4} md={4} sm={6} xs={12}>
              <ServiceCommonCard
                title={service.title}
                description={service.description}
              />
            </Col>
          ))}
        </Row>
        <div className="text-center mt-4 mb-5">
          <button
            className="btn btn-primary"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? "Read Less" : "Read More"}
          </button>
        </div>
      </Container>
    </section>
  );
};

export default DocumentRetrivalThird;
