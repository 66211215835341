import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import CreditCardServices from "../../services/strapiService";
import AddCity from "./AddProduct";
import ShiftsServices from "../../services/demoServices";
import { Checkbox } from "primereact/checkbox";
import { TabView, TabPanel } from "primereact/tabview";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Avatar } from "primereact/avatar";

import { Password } from "primereact/password";
import AddressService from "../../services/addressService";
import ContactService from "../../services/ContactService";
import MasterServices from "../../services/coreServices";
import OrderServices from "../../services/orderServices";
import { useNavigate } from "react-router-dom";
import companyMasterService from "../../services/companyMasterService";
import AddCompanyMember from "../Company/AddCompanyComponets/AddMember";
import authContext from "../../common/authContext";
import { Accordion, AccordionTab } from "primereact/accordion";
import AddressTemplate from "../../utils/AddressTemplate";
import AddCompanyLocation from "../Company/AddCompanyComponets/AddLocation";
import { InputTextarea } from "primereact/inputtextarea";
import PaymentSuccess from "../Payment/PaymentSuccess";
import PaymentFailure from "../Payment/PaymentFailure";
import CustomModal from "../../utils/CustomModal";
import PaymentOptions from "../Payment/PaymentOptions";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import Payment from "../../utils/PaymentElements";
import { Elements } from "@stripe/react-stripe-js";
import propertiesApp from "../../utils/propertiesApp";
import { Toast } from "primereact/toast";
import AddOrderLocation from "../Company/AddCompanyComponets/AddOrderLocation";
import validationConfig from "../../utils/UsaStatesRegex";
import { InputMask } from "primereact/inputmask";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Switch, TextField } from "@mui/material";
import { Editor } from "primereact/editor";
import dayjs from "dayjs";
import AddCompany from "./AddCompany";
import AddBOIMember from "../Company/AddCompanyComponets/AddBOIMember";
import PreviewDataComponent from "../../utils/PreviewDataComponent";
import KeyPersonelCard from "../../utils/KeyPersonelCard";
import { Skeleton } from "primereact/skeleton";
import KeyPersonnelIndividualCard from "../../utils/KeyPersonnelIndividualCard";
import RegisteredAgentCard from "../../utils/RegisteredAgentCard";
import { showToast } from "../../utils/toastUtils";
import PrincipalAddressCard from "../../utils/principalAddress";
import useTableData from "../../redux/Reducers/useTableData";
import PreviewKeyPersonelCard from "../../utils/previewKeyPersonnelCard";
import KeyPersonnelDataTable from "../../utils/keyPersonnelDataTable";
import KeyPersonnelOrderDataTable from "../../utils/keyPersonnelOrderDataTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { DataArrayOutlined } from "@mui/icons-material";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { commonGetInitials } from "../../utils/getInitials";
import { stateWithCounty } from "../../utils/stateWithCounty";

const AddNewEntityFormation = () => {
  const registeredTabRef = useRef(null);
  
  const handleScroll = () => {
    if (registeredTabRef.current) {
      registeredTabRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      console.error("registeredTabRef is null");
    }
  };
  const {
    loginUserName,
    setLoginUserName,
    permissions,
    globalCompany,
    setRedirectedData,
    isLoading,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh,
    entityDash,
    stateDash,
    setEntityDash,
    setStateDash,
    paymentMethod,
    setPaymentMethod,
    setOrderContinue,
    setPaymentOrderData,
    wizardScroll,
    setWizardScroll,
  } = useContext(authContext);

  // const { data: fetchedContentTypeData, isLoading: contentTypeLoading, isError: isContentTypeError, error: contentTypeError, refetch: reftechContentTypeData } = useTableData("contentType");

  // const { data: fetchedComponentsData, isLoading: componentsLoading, isError: isComponentsError, error: componentsError, refetch: reftechComponentsData } = useTableData("strapiComponents");

  const {
    data: fetchedStateData,
    isLoading: stateLoading,
    isError: isStateError,
    error: stateError,
    refetch: reftechStateData,
  } = useTableData("state");

  const {
    data: fetchedCountyData,
    isLoading: countyLoading,
    isError: isCountyError,
    error: countyError,
    refetch: reftechCountyData,
  } = useTableData("countyMaster");

  const {
    data: fetchedCountryData,
    isLoading: countryLoading,
    isError: isCountryError,
    error: countryError,
    refetch: reftechCountryData,
  } = useTableData("countryMaster");

  const {
    data: fetchedNaicsSubCodeData,
    isLoading: naicsSubCodeLoading,
    isError: isNaicsSubCodeError,
    error: naicsSubCodeError,
    refetch: reftechNaicsSubCodeData,
  } = useTableData("naicsSubCode");

  const {
    data: fetchedNaicsCodeData,
    isLoading: naicsCodeLoading,
    isError: isNaicsCodeError,
    error: naicsCodeError,
    refetch: reftechNaicsCodeData,
  } = useTableData("naicsCode");

  const {
    data: fetchedEntityTypeData,
    isLoading: entityTypeLoading,
    isError: isEntityTypeError,
    error: entityTypeError,
    refetch: reftechEntityTypeData,
  } = useTableData("entityTypeMaster");

  const {
    data: fetchedAddressTypeData,
    isLoading: addressTypeLoading,
    isError: isAddressTypeError,
    error: addressTypeError,
    refetch: reftechAddressTypeData,
  } = useTableData("addressType");

  const {
    data: fetchedProductStrapiData,
    isLoading: productStrapiLoading,
    isError: isProductStrapiError,
    error: productStrapiError,
    refetch: reftechProductStrapiData,
  } = useTableData("productStrapi");

  const {
    data: fetchedApplicantData,
    isLoading: applicantTypeLoading,
    isError: isApplicantError,
    error: applicantTypeError,
    refetch: reftechApplicantData,
  } = useTableData("applicantType");

  const {
    data: fetchedKeyPersonnelTypeData,
    isLoading: keyPersonnelTypeLoading,
    isError: isKeyPersonnelTypeError,
    error: keyPersonnelTypeError,
    refetch: reftechKeyPersonnelTypeData,
  } = useTableData("keyPersonnelType");

  const [allState, setAllState] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  const toast = useRef(null);

  const stripePromise = loadStripe(
    "pk_test_51PyTdiKhsNfb7YuLO8XP7SHE3FUn3rspK3sI7OTbOIulKp9XdM7WtU4gqjxiKVDVBldIKIEBvL8PLjdKkW1h8f1100bw75rZPd"
  );

  const [allCounty, setAllCounty] = useState([]);

  const [county, setCounty] = useState(null);

  const [contactId, setContactId] = useState(null);

  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];

  const [selectedCountry, setSelectedCountry] = useState(null);

  const history = useNavigate();

  const [selectedCheckBoxData, setSelectedCheckBoxData] = useState([]);
  const [checkedState, setCheckedState] = useState({});

  const [loading1, setLoading1] = useState(false);

  const [formData, setFormData] = useState(null);

  const [entityErr, setEntityErr] = useState("");

  const [stateErr, setStateErr] = useState("");

  const [servicesData, setServiceData] = useState([]);

  const [strapiId, setStrapiId] = useState(null);

  const [orderPayId, setOrderPayId] = useState(null);

  const [visible9, setVisible9] = useState(false);

  const [keyPersonnelArray, setKeyPersonnelArray] = useState([]);

  const [memberData, setMemberData] = useState(null);

  const [strapiOrderData, setStrapiOrderData] = useState(null);

  const [currentKey, setCurrentKey] = useState("");

  const [showSkeleton, setShowSkeleton] = useState(false);

  const tempdata = [
    {
      item: "State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },

    {
      item: "State To Search",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "debt_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Of LLC",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },

    {
      item: "County Of LLC",
      getAllUrl: "/core/api/countyMaster/getAll",
      label: "countyName",
      optionValue: "countyCode",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "ANR_State_of_Formation",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "coad_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "uccAmend_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "ad_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "sp_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Text",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Of Filing",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Of Entity",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "BNR_State_Of_Formation",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Of Formation",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Country",
      getAllUrl: "/core/api/countryMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",

      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/countryMasters/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },
    {
      item: "County",
      getAllUrl: "/core/api/countyMaster/getAll",
      label: "countyName",
      optionValue: "countyCode",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "County To Search",
      getAllUrl: `/core/api/countyMaster/getAll`,
      label: "countyName",
      optionValue: "countyCode",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },

    {
      item: "State Or Province",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Issuing Jurisdiction State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Issuing Jurisdiction Country",
      getAllUrl: "/core/api/countryMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/countryMasters/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },

    {
      item: "NAICS Sub Code",
      getAllUrl: "/core/api/naicsSubCodes/getAll",
      label: "naicsSubCodeDesc",
      optionValue: "naicsSubCodeDesc",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },
    {
      item: "NAICS Code",
      getAllUrl: "/core/api/stateWiseNaicsCodes/getAll",
      label: "naicsDesc",
      optionValue: "naicsDesc",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },
  ];

  const [addOnServiceArray, setAddOnServiceArray] = useState([]);

  const [allAddressType, setAllAddressType] = useState([]);

  const [keyPersonalAddressType, setKeyPersonalAddressType] = useState(null);

  const [IsCheckedAgent, setIsCheckedAgent] = useState(true);

  const [IsCheckedAgent1, setIsCheckedAgent1] = useState(false);

  const [entityTypes, setEntityTypes] = useState([]);

  const [visible2, setVisible2] = useState(false);

  const [visible3, setVisible3] = useState(false);

  const [previewData, setPriviewData] = useState(null);

  const [memberType, setMemberType] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const [ownerType, setAllOwnerType] = useState([]);

  const [optionsMap, setOptionsMap] = useState({});

  const [allBilingAddress, setAllBilingAddress] = useState([]);

  const [allShippingAddress, setAllShippingAddress] = useState([]);

  const [bilingAddress, setBilingAddress] = useState(null);

  const [shippingAddress, setShippingAddress] = useState(null);

  const [updatedOrderLineItems, setUpdatedOrderLineItems] = useState([]);

  const [allFiler, setAllFiler] = useState([]);

  const [filer, setFiler] = useState(null);

  const [allPresident, setAllPresident] = useState([]);

  const [president, setPresident] = useState(null);

  const [allTreasurer, setAllTreasurer] = useState([]);

  const [treasurer, setTreasurer] = useState(null);

  const [allSecretary, setAllSecretary] = useState([]);

  const [secretary, setSecretary] = useState(null);

  const [allBilingInfo, setAllBilingInfo] = useState([]);

  const [bilingInfo, setBilingInfo] = useState(null);

  const [member, setMember] =useState(null)

  const [allMailingInfo, setAllMailingInfo] = useState([]);

  const [mailingInfo, setMailingInfo] = useState(null);

  const [allStateNaicsCode, setAllStateNaicsCode] = useState([]);

  const [naicsCode, setNaicsCode] = useState(null);

  const [allSubNaicsCode, setAllSubNaicsCode] = useState([]);

  const [subNaicsCode, setSubNaicsCode] = useState(null);

  const [serviceDataTemp, setServiceDataTemp] = useState([]);

  const [allContactInfo, setAllContactInfo] = useState([]);

  const [contactInfo, setContactInfo] = useState(null);

  const [initialFormAttributes, setInitialFormAttributes] = useState([]);

  const [creditInfo, setCreditInfo] = useState(null);

  const [enityName, setEntityName] = useState(null);

  const [state, setState] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [llc, setLlc] = useState(null);

  const [allLLC, setAllLLC] = useState([]);

  const [llcName, setLLCName] = useState(null);

  const [visible, setVisible] = useState(false);

  const [selectCheckBoxData, setSelectCheckBoxData] = useState([]);

  const [currentTab, setCurrentTab] = useState(0);

  const [closeProgresbar, setCloseProgresbar] = useState(false);

  const [closeProgresbar1, setCloseProgresbar1] = useState(false);

  const [closeProgresbar2, setCloseProgresbar2] = useState(false);

  const [closeProgresbar3, setCloseProgresbar3] = useState(false);

  const [dba, setDba] = useState("");

  const [name, setName] = useState("");

  const [AlternateName, setAlternateName] = useState("");

  const [llcAddress, setLLCAddress] = useState("");

  const [mailingAddress, setMailingAddress] = useState("");

  const [initData, setInitData] = useState(null);

  const [initData1, setInitData1] = useState(null);

  const [initData2, setInitData2] = useState(null);

  const [allInitData, setAllInitData] = useState([]);

  const [inputFormData, setInputFormData] = useState([]);

  const [allModels, setAllModels] = useState([]);

  const [modelData, setModelData] = useState([]);

  const [modelData1, setModelData1] = useState([]);

  const [IsSuccessful, setIsSuccessFul] = useState(false);

  const [modelName, setModelName] = useState(null);

  const [currentStep, setCurrentStep] = useState(1);

  const [activeStep, setActiveStep] = React.useState(0);

  const [globalModel, setGlobalModel] = useState([]);

  const [allComponents, setAllComponents] = useState([]);

  const [mainModel, setMainModel] = useState(null);

  const [rawModel, setRawModel] = useState([]);

  const [groupInitialData, setGroupInitialData] = useState({});

  const [showDescription, setShowDescription] = useState(false);

  const [organizerData, setOrganizerData] = useState(null);

  const [allOrganizer, setAllOrganizer] = useState([]);

  const [allMember, setAllMember] = useState([]);

  const [pageRefresh, setPageRefresh] = useState(false);

  const [allLocation, setAllLocation] = useState([]);

  const [location, setLocation] = useState(null);

  const [allInCorporator, setAllInCorporator] = useState([]);

  const [director, setDirector] = useState(null);

  const [allDirector, setAllDirector] = useState([]);

  const [officer, setOfficer] = useState(null);

  const [allOfficer, setAllOfficer] = useState([]);

  const [incorporator, setIncorporator] = useState(null);

  const [allCountries, setAllCountries] = useState([]);

  const [agentLocation, setAgentLocation] = useState(null);

  const [keyPersonalCompany, setKeyPersonalCompany] = useState(null);

  const [countyErr, setCountyErr] = useState("");

  const [isFirstDraft, setIsFirstDraft] = useState(false);

  const [IsCheckedAgent2, setIsCheckedAgent2] = useState(false);

  const [registeredAgent, setRegisteredAgent] = useState(null);

  const [aallDebtor, setAllDebtor] = useState([]);

  const [allSecuredParty, setAllSecuredParty] = useState([]);

  const [debtorData, setDebtorData] = useState(null);

  const [allAssignee, setAllAssignee] = useState([]);

  const [assigneeData, setAssigneeData] = useState(null);

  const [allDocuments, setAllDocuments] = useState([]);

  const [allCompanyApplicant, setAllCompanyApplicant] = useState([]);

  const [allBeneficialOwner, setAllBeneficialOwner] = useState([]);

  const [visible8, setVisible8] = useState(false);

  const [wizardTabData, setWizardTabData] = useState(null);

  const [totalStep, setTotalStep] = useState(1);

  const [allMemberOrManager, setAllMemberOrManager] = useState([]);

  const [keyPersonnelKey, setKeyPersonnelKey] = useState(null);

  const [keyPersonnelValue, setKeyPersonnelValue] = useState(null);

  // Global object to track timeouts for each input field by id.
  const maskTimeouts = {};

  /**
   * attachDelayedMask
   * Attaches a delayed mask by switching the input type to "password".
   *
   * @param {string} fieldId - The id of the input field to mask.
   * @param {number} delay - Delay in milliseconds (default: 2000ms).
   */
  function attachDelayedMask(fieldId, delay = 2000) {
    // Use a small delay to allow React to complete rendering
    setTimeout(() => {
      const inputElement = document.getElementById(fieldId);
      if (!inputElement) return;

      // Clear any previously scheduled timeout for this field.
      if (maskTimeouts[fieldId]) {
        clearTimeout(maskTimeouts[fieldId]);
      }

      maskTimeouts[fieldId] = setTimeout(() => {
        // Only mask if the input isn't focused.
        if (document.activeElement !== inputElement) {
          inputElement.type = "password";
        }
      }, delay);
    }, 0);
  }

  useEffect(() => {
    setIsLoading(true);
    ShiftsServices.getInit()
      .then((res) => {
        
        const data = res.data.data;

        if (Array.isArray(data)) {
          
          setAllInitData(data);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        
        setIsLoading(false);
      });

    ShiftsServices.getInit()
      .then((res) => {
        
        setRawModel(res.data.data);
      })
      .catch((err) => {
        
      });
  }, []);

  useEffect(() => {
    if (fetchedStateData) {
      setAllState(fetchedStateData);
    }
  }, [fetchedStateData]);

  useEffect(() => {
    if (fetchedEntityTypeData) {
      setEntityTypes(fetchedEntityTypeData);
    }
  }, [fetchedEntityTypeData]);

  useEffect(() => {
    if (fetchedAddressTypeData) {
      setAllAddressType(fetchedAddressTypeData);
    }
  }, [fetchedAddressTypeData]);

  useEffect(() => {
    if (fetchedProductStrapiData) {
      setInitialFormAttributes(fetchedProductStrapiData);
    }
  }, [fetchedProductStrapiData]);

  useEffect(() => {
    ContactService.getAllContactByUserId(user?.id)
      .then((res) => {
        
        let data = res.data;

        
        if (res.data && res.data !== "") {
          if (res.data.contactDetails !== undefined) {
            setContactId(res.data?.contactDetails?.id);
          }
        }
      })
      .catch((err) => {
        
      });
  }, []);

  const [allKeyPersonnelType, setAllKeyPersonnelType] = useState([]);

  useEffect(() => {
    if (fetchedKeyPersonnelTypeData) {
      setAllKeyPersonnelType(fetchedKeyPersonnelTypeData);
    }
  }, [fetchedKeyPersonnelTypeData]);

  useEffect(() => {
    if (fetchedApplicantData) {
      setAllOwnerType(fetchedApplicantData);
    }
  }, [fetchedApplicantData]);

  useEffect(() => {
    if (globalCompany && state) {
      if (globalCompany.companyDetails) {
        companyMasterService
          .getAllKeyPersonnelByCompanyId(globalCompany.companyDetails.id)
          .then((res) => {
            
            if (res.data !== "") {
              if (Array.isArray(res.data)) {
                const tempData = res.data.filter(
                  (val) =>
                    val?.addressMasterId &&
                    val?.addressMasterId?.stateId === state?.id
                );
                const data = res.data.filter(
                  (val) => val?.addressMasterId !== null
                );
                setAllOrganizer(data);
                setAllMember(data);
                setAllInCorporator(data);
                setAllBilingAddress(data);
                setAllShippingAddress(data);
                setAllOfficer(data);
                setAllDirector(data);
                setAllSecuredParty(data);
                setAllDebtor(data);
                setAllAssignee(data);
                setAllFiler(data);
                setAllPresident(data);
                setAllTreasurer(data);
                setAllSecretary(data);
                setAllBilingInfo(data);
                setAllMailingInfo(data);
              }
            }
          })
          .catch((err) => {
            
          });

        companyMasterService
          .getAllOwnerInformation(globalCompany.companyDetails.id)
          .then((res) => {
            
            if (Array.isArray(res.data)) {
              const temp1 = res.data.filter(
                (val) => val.applicantType.name === "COMPANY APPLICANT"
              );
              const temp2 = res.data.filter(
                (val) => val.applicantType.name === "BENEFICIAL OWNER"
              );
              
              setAllBeneficialOwner(temp2);
              setAllCompanyApplicant(temp1);
            }
          })
          .catch((err) => {
            
          });
      }
    }
  }, [visible2, globalCompany, isTabRefresh, state]);

  const [selectedProducts, setSelectedProducts] = React.useState({});

  const handleCheckedChange = (e, serviceData) => {
    const { product } = serviceData;

    if (product?.orderTypeMaster === 24) {
      if (e.checked) {
        setIsCheckedAgent(true);
      } else {
        setIsCheckedAgent(false);
      }
    }

    setSelectedProducts((prevSelected) => {
      const updatedSelected = { ...prevSelected };

      if (product.orderTypeMaster === 21 || product.orderTypeMaster === 18) {
        if (e.checked) {
          updatedSelected[product.orderTypeMaster] = [product.displayName];
        } else {
          delete updatedSelected[product.orderTypeMaster];
        }
      } else {
        if (e.checked) {
          if (!updatedSelected[product.orderTypeMaster]) {
            updatedSelected[product.orderTypeMaster] = [];
          }
          updatedSelected[product.orderTypeMaster].push(product.displayName);
        } else {
          updatedSelected[product.orderTypeMaster] = (
            updatedSelected[product.orderTypeMaster] || []
          ).filter((name) => name !== product.displayName);
          if (updatedSelected[product.orderTypeMaster].length === 0) {
            delete updatedSelected[product.orderTypeMaster];
          }
        }
      }

      setSelectedCheckBoxData((prevCheckboxData) => {
        const updatedCheckboxData = [...prevCheckboxData];

        if (product.orderTypeMaster === 21 || product.orderTypeMaster === 18) {
          if (e.checked) {
            return [
              ...updatedCheckboxData.filter(
                (data) =>
                  data.product.orderTypeMaster !== product.orderTypeMaster
              ),
              serviceData,
            ];
          } else {
            return updatedCheckboxData.filter(
              (data) => data.product.orderTypeMaster !== product.orderTypeMaster
            );
          }
        }

        if (e.checked) {
          updatedCheckboxData.push(serviceData);
        } else {
          return updatedCheckboxData.filter(
            (data) => data.product.displayName !== product.displayName
          );
        }
        return updatedCheckboxData;
      });

      return updatedSelected;
    });
  };

  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);

  const calculatePrice = (serviceData, quantity) => {
    if (serviceData?.product?.isBundlePriceApplicable) {
      const { noOfItemsInBundle, priceOfBundle, priceForAddlItemsOverBundle } =
        serviceData?.product;

      if (quantity <= noOfItemsInBundle) {
        return priceOfBundle;
      } else {
        const bundledPrice = priceOfBundle;
        const additionalItemsPrice =
          priceForAddlItemsOverBundle * (quantity - noOfItemsInBundle);
        return bundledPrice + additionalItemsPrice;
      }
    } else {
      return serviceData?.product?.unitPrice;
    }
  };

  const calculateTotalAmount = () => {
    return selectedCheckBoxData.reduce((total, val) => {
      const quantity = 4;
      const itemTotal = calculatePrice(val, quantity);
      return total + itemTotal;
    }, 0);
  };

  function deepCompareObjects(obj1, obj2) {
    if (typeof obj1 !== typeof obj2) return false;

    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      if (obj1.length !== obj2.length) return false;
      for (let i = 0; i < obj1.length; i++) {
        if (!deepCompareObjects(obj1[i], obj2[i])) return false;
      }
      return true;
    }

    if (typeof obj1 === "object" && obj1 !== null && obj2 !== null) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;
      for (let key of keys1) {
        if (!deepCompareObjects(obj1[key], obj2[key])) return false;
      }
      return true;
    }

    return obj1 === obj2;
  }

  useEffect(() => {
    if (enityName && state) {
      // const restrictedStates = [
      //   "New York",
      //   "Alabama",
      //   "Illinois",
      //   "Maryland",
      //   "North Carolina",
      //   "New Hampshire",
      //   // "Georgia",
      // ];
      // const isRestrictedState = restrictedStates.includes(state.stateFullDesc);
      const isRestrictedState = stateWithCounty.includes(state.stateFullDesc);
      if (isRestrictedState && !county) {
        
      } else {
        setShowSkeleton(false);

        MasterServices.getAllProduct(
          "1",
          enityName.id,
          state?.id,
          county ? county.id : 0
        )
          .then((res) => {
            
            if (Array.isArray(res.data)) {
              const sortedData = res.data.sort(
                (a, b) =>
                  a.product.displayPreference - b.product.displayPreference
              );
              setServiceData(sortedData);
              setSelectedProducts({});

              const temp = res.data.filter(
                (val) => val?.product?.orderTypeMaster === 1
              );
        setShowSkeleton(true);

              
              setSelectedCheckBoxData(temp);
            }
          })
          .catch((err) => {
            
            setShowSkeleton(true);
          });
      }
      AddressService.getAllCountyByState(state?.id)
        .then((res) => {
          
          setAllCounty(res.data);
        })
        .catch((err) => {
          
        });

      MasterServices.getNaicsCodeByStateId(state?.id)
        .then((res) => {
          
          if (Array.isArray(res.data)) {
            setAllStateNaicsCode(res.data);
          }
        })
        .catch((err) => {
          
        });

      companyMasterService
        .getRegisteredAgentByStateId(state?.id)
        .then((res) => {
          
          const data = res.data;
          if (Array.isArray(data) && data.length > 0) {
            setRegisteredAgent(data[0]);
          }
        })
        .catch((err) => {
          
        });
    }
  }, [enityName, state, county]);

  useEffect(() => {
    if ((stateDash, entityDash)) {
      setEntityName(entityDash);
      
      setState(stateDash);
      setValue("state", stateDash);
    }
  }, [entityDash, stateDash]);

  useEffect(() => {
    if (globalCompany?.companyDetails?.id) {
      companyMasterService
        .getAllCompanyById(globalCompany?.companyDetails?.id)
        .then((res) => {
          setKeyPersonalCompany(res.data);
        })
        .catch((err) => {
          
        });
    }
  }, [globalCompany]);

  useEffect(() => {
    if (globalCompany) {
      setSelectedCountry("+1");
      if (globalCompany.companyDetails) {
        AddressService.getAllAddressByCompanyId(globalCompany.companyDetails.id)
          .then((res) => {
            
            if (res.data !== "") {
              
              if (Array.isArray(res.data)) {
                const temp = res.data.filter(
                  (val) => val.addressType.type === "PRINCIPAL"
                );
                if (temp.length > 0) {
                  setAllLocation(temp);
                } else {
                  setAllLocation([]);
                }
              }
            } else {
              setAllLocation([]);
              setAllBilingAddress([]);
              setAllShippingAddress([]);
            }
          })
          .catch((err) => {
            
          });
      }
    }
  }, [globalCompany, isTabRefresh]);

  // useEffect(() => {
  //     tempdata.forEach(matchedItem => {
  //         if (matchedItem && matchedItem.getAllUrl) {
  //             MasterServices.getAll(matchedItem.getAllUrl)
  //                 .then(fetchedData => {
  //                     if (Array.isArray(fetchedData.data)) {
  //                         const labelKey = matchedItem.label || "name";
  //                         const newOptions = fetchedData.data;
  //                         setOptionsMap(prev => ({ ...prev, [matchedItem.item]: newOptions }));
  //                     }
  //                 })
  //                 .catch((err) => {
  //                     
  //                 });
  //         }
  //     });
  // }, []);

  useEffect(() => {
    const uniqueUrls = {};

    tempdata.forEach((matchedItem) => {
      if (matchedItem?.getAllUrl) {
        if (!uniqueUrls[matchedItem.getAllUrl]) {
          uniqueUrls[matchedItem.getAllUrl] = [];
        }
        uniqueUrls[matchedItem.getAllUrl].push(matchedItem.item);
      }
    });

    Object.entries(uniqueUrls).forEach(([url, items]) => {
      if (url === "/core/api/stateMaster/getAll" && fetchedStateData) {
        const newOptions = fetchedStateData;
        setOptionsMap((prev) => {
          const updatedMap = { ...prev };
          items.forEach((item) => {
            updatedMap[item] = newOptions;
          });
          return updatedMap;
        });
      } else if (
        url === "/core/api/countryMaster/getAll" &&
        fetchedCountryData
      ) {
        const newOptions = fetchedCountryData;
        setOptionsMap((prev) => {
          const updatedMap = { ...prev };
          items.forEach((item) => {
            updatedMap[item] = newOptions;
          });
          return updatedMap;
        });
      } else if (url === "/core/api/countyMaster/getAll" && fetchedCountyData) {
        const newOptions = fetchedCountyData;
        setOptionsMap((prev) => {
          const updatedMap = { ...prev };
          items.forEach((item) => {
            updatedMap[item] = newOptions;
          });
          return updatedMap;
        });
      } else if (
        url === "/core/api/naicsSubCodes/getAll" &&
        fetchedNaicsSubCodeData
      ) {
        const newOptions = fetchedNaicsSubCodeData;
        setOptionsMap((prev) => {
          const updatedMap = { ...prev };
          items.forEach((item) => {
            updatedMap[item] = newOptions;
          });
          return updatedMap;
        });
      } else if (
        url === "/core/api/stateWiseNaicsCodes/getAll" &&
        fetchedNaicsCodeData
      ) {
        const newOptions = fetchedNaicsCodeData;
        setOptionsMap((prev) => {
          const updatedMap = { ...prev };
          items.forEach((item) => {
            updatedMap[item] = newOptions;
          });
          return updatedMap;
        });
      } else {
        MasterServices.getAll(url)
          .then((fetchedData) => {
            if (Array.isArray(fetchedData.data)) {
              const newOptions = fetchedData.data;
              setOptionsMap((prev) => {
                const updatedMap = { ...prev };
                items.forEach((item) => {
                  updatedMap[item] = newOptions;
                });
                return updatedMap;
              });
            }
          })
          .catch((err) =>  console.log(err))
      }
    });
  }, [
    fetchedCountyData,
    fetchedCountyData,
    fetchedStateData,
    fetchedNaicsCodeData,
    fetchedNaicsSubCodeData,
  ]);

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const [minValue, setMinValue] = useState(null);

  const [maxValue, setMaxValue] = useState(null);

  const [formCounts, setFormCounts] = useState({});

  const handleRepeatForm = (key) => {
    setFormCounts((prevCounts) => ({
      ...prevCounts,
      [key]: (prevCounts[key] || 1) + 1,
    }));
  };

  const handleRemoveRepeatForm = (key) => {
    const currentCount = formCounts[key] || 1;
    if (currentCount > 1) {
      const fieldNames = groupedData[key].form.flatMap((value) =>
        Object.keys(value.schema.attributes)
      );
      fieldNames.forEach((field) => {
        unregister(`${field}-${currentCount - 1}`);
      });

      setFormCounts((prevCounts) => ({
        ...prevCounts,
        [key]: currentCount - 1,
      }));
      setAllMemberOrManager((prevData) => {
        return prevData.filter(
          (data) => !data[`member_or_manger_details-${currentCount - 1}`]
        );
      });
    }
  };

  useEffect(() => {
    const boiData = selectedCheckBoxData.filter((item) =>
      item?.product?.displayName.includes("BOI")
    );

    if (boiData.length > 0) {
      const numbers = boiData.flatMap((item) => {
        const matches = item?.product?.displayName.match(/\d+/g);
        return matches ? matches.map(Number) : [];
      });

      if (numbers.length > 0) {
        setMinValue(Math.min(...numbers));
        setFormCounts((prevCounts) => ({
          ...prevCounts,
          ["Beneficial_Owner_Information"]: Math.min(...numbers),
        }));
        setMaxValue(Math.max(...numbers));
      }
    }
  }, [selectedCheckBoxData]);

  

  

  const handleNext = (data) => {
    
    if (activeStep === data.length - 1) {
      nextPrev(1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    unregister,
    clearErrors,
  } = useForm("");

  useEffect(() => {
    if (naicsCode) {
      MasterServices.getNaicsSubCode(naicsCode?.id)
        .then((res) => {
          if (Array.isArray(res.data)) {
            setAllSubNaicsCode(res.data);
          }
        })
        .catch((err) => {
          
        });
    }
  }, [naicsCode]);

  const stateFilter = (id) => {
    const temp = allState.filter((val) => val.id === id);
    
    if (temp.length > 0) {
      return temp[0].stateShortName;
    } else {
      return "";
    }
  };

  function numberToString(number) {
    if (typeof number === "number") {
      return number.toString();
    }
    if (typeof number === "string") {
      return number;
    }
  }
  function extractTenDigitPhoneNumber(input) {
    if (typeof input === "string") {
      const temp3 = input.split(" ");
      if (Array.isArray(temp3)) {
        
        if (temp3.length > 0) {
          return temp3.slice(1).join(" ");
        }
      }
    }
  }

  // function extractTenDigitPhoneNumber(phoneNumber) {
  //     if (phoneNumber !== undefined && typeof (phoneNumber) === "string") {
  //         const digitsOnly = phoneNumber.replace(/\D/g, '');

  //         const tenDigitNumber = digitsOnly.slice(-10);

  //         return Number(tenDigitNumber);
  //     }

  // }

  function setDropdownError(fieldKey, message = "") {
    const errorMessageTag = document.getElementById(`${fieldKey}--error`);
    if (errorMessageTag) {
      errorMessageTag.textContent = "";
    }
  }

  useEffect(() => {
    if (organizerData) {
      const keys = [
        "Org_Name",
        "Org_Email_Address",
        "Org_Contact_No",
        "Org_Address_Line_1",
        "Org_Address_Line_2",
        "Org_City",
        "Org_State",
        "Org_Country",
        "Org_Zip_Code",
        "Organizer",
      ];
      
      setValue("Org_Name", organizerData.keyPersonnelName);
      setValue("Org_Email_Address", organizerData.emailId);
      setValue(
        "Org_Contact_No",
        extractTenDigitPhoneNumber(organizerData.contactNo)
      );
      setValue(
        "Org_Address_Line_1",
        organizerData.addressMasterId.addressLine1
      );
      setValue(
        "Org_Address_Line_2",
        organizerData.addressMasterId.addressLine2
          ? organizerData.addressMasterId.addressLine2
          : ""
      );
      setValue("Org_City", organizerData.addressMasterId.city);
      setValue("Org_State", stateFilter(organizerData.addressMasterId.stateId));
      setValue(
        "Org_Zip_Code",
        numberToString(organizerData.addressMasterId.postalCode)
      );
      setDropdownError("Organizer");
      // Clear error messages for each key
      keys.forEach((fieldKey) => {
        const errorMessageTag = document.getElementById(`${fieldKey}--error`);
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      });
    }
  }, [setValue, organizerData]);

  useEffect(() => {
    if (incorporator) {
      
      setValue("Inc_Name", incorporator.keyPersonnelName);
      setValue("Inc_Email_Address", incorporator.emailId);
      setValue(
        "Inc_Contact_No",
        extractTenDigitPhoneNumber(incorporator.contactNo)
      );
      setValue("Inc_Address_Line_1", incorporator.addressMasterId.addressLine1);
      setValue(
        "Inc_Address_Line_2",
        incorporator.addressMasterId.addressLine2
          ? incorporator.addressMasterId.addressLine2
          : ""
      );
      setValue("Inc_City", incorporator.addressMasterId.city);
      setValue("Inc_State", stateFilter(incorporator.addressMasterId.stateId));
      setValue(
        "Inc_Zip_Code",
        numberToString(incorporator.addressMasterId.postalCode)
      );
      setDropdownError("Incorporator");
    }
  }, [setValue, incorporator]);



  
  useEffect(() => {
    if (member) {
      
      setValue("Mom_Name", member?.keyPersonnelName);
      setValue("MM_Address_Line_1",  member?.addressMasterId.addressLine1);
      setValue("MM_Address_Line_2", member?.addressMasterId.addressLine1);
      setValue("MM_City", member?.addressMasterId.city);
      setValue("MM_State", stateFilter(member?.addressMasterId.stateId));
      setValue(
        "MM_Zip_Code",
        numberToString(member?.addressMasterId.postalCode)
      );
      setDropdownError("Member");
    }
  }, [member]);



  useEffect(() => {
    if (keyPersonnelValue && keyPersonnelKey) {
      setValue(keyPersonnelKey, keyPersonnelValue);

      const match = keyPersonnelKey.match(/-(\d+)$/);
      const index = match ? match[1] : null;

      if (
        index !== null &&
        keyPersonnelKey.includes("member_or_manger_details")
      ) {
        setAllMemberOrManager((prevData) => {
          const updatedMember = {
            [`member_or_manger_details-${index}`]: keyPersonnelValue,
          };
          return [
            ...prevData.filter(
              (data) => !data[`member_or_manger_details-${index}`]
            ),
            updatedMember,
          ];
        });

        setValue(`Mom_Name-${index}`, keyPersonnelValue.keyPersonnelName);
        setValue(
          `MM_Address_Line_1-${index}`,
          keyPersonnelValue.addressMasterId.addressLine1
        );
        setValue(
          `MM_Address_Line_2-${index}`,
          keyPersonnelValue.addressMasterId.addressLine2 || ""
        );
        setValue(`MM_City-${index}`, keyPersonnelValue.addressMasterId.city);
        setValue(
          `MM_State-${index}`,
          stateFilter(keyPersonnelValue.addressMasterId.stateId)
        );
        setValue(
          `MM_Zip_Code-${index}`,
          numberToString(keyPersonnelValue.addressMasterId.postalCode)
        );

        const errorMessageTag = document.getElementById(
          `Member_Or_Manager_Details-${Number(index) + 1}--error`
        );
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
        setKeyPersonnelKey(null);
      }
    }
  }, [keyPersonnelValue]);

  useEffect(() => {
    if (allMemberOrManager && allMemberOrManager.length > 0) {
      
      allMemberOrManager.forEach((data, index) => {
        const keyPersonnelDetails = data[`member_or_manger_details-${index}`];
        
        
        if (keyPersonnelDetails) {
          setValue(
            `Mom_Member_Or_Manager-${index}`,
            keyPersonnelDetails?.member_or_manager_value
          );
          setValue(`member_or_manger_details-${index}`, keyPersonnelDetails);
          const address = keyPersonnelDetails?.addressMasterId;

          setValue(`Mom_Name-${index}`, keyPersonnelDetails?.keyPersonnelName);
          setValue(`MM_Address_Line_1-${index}`, address?.addressLine1);
          setValue(`MM_Address_Line_2-${index}`, address?.addressLine2 || "");
          setValue(`MM_City-${index}`, address?.city);
          setValue(`MM_State-${index}`, stateFilter(address?.stateId));
          setValue(`MM_Zip_Code-${index}`, numberToString(address?.postalCode));

          const errorMessageTag = document.getElementById(
            `Member_Or_Manager_Details-${index + 1}--error`
          );
          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }
        }
      });
    }
  }, [allMemberOrManager]);

  useEffect(() => {
    if (officer) {
      
      setValue("Off_Name", officer.keyPersonnelName);
      setValue("Off_Email_Address", officer.emailId);
      setValue("Off_Contact_No", extractTenDigitPhoneNumber(officer.contactNo));
      setValue("Of_Address_Line_1", officer.addressMasterId.addressLine1);
      setValue(
        "Of_Address_Line_2",
        officer.addressMasterId.addressLine2
          ? officer.addressMasterId.addressLine2
          : ""
      );
      setValue("Of_City", officer.addressMasterId.city);
      setValue("Of_State", stateFilter(officer.addressMasterId.stateId));
      setValue(
        "Of_Zip_Code",
        numberToString(officer.addressMasterId.postalCode)
      );
      setValue("O_Name", officer.keyPersonnelName);
      setValue("O_Email_Address", officer.emailId);
      setValue("O_Contact_No", extractTenDigitPhoneNumber(officer.contactNo));
      setValue("O_Address_Line_1", officer.addressMasterId.addressLine1);
      setValue(
        "O_Address_Line_2",
        officer.addressMasterId.addressLine2
          ? officer.addressMasterId.addressLine2
          : ""
      );
      setValue("O_City", officer.addressMasterId.city);
      setValue("O_State", stateFilter(officer.addressMasterId.stateId));
      setValue(
        "O_Zip_Code",
        numberToString(officer.addressMasterId.postalCode)
      );
      setDropdownError("Officer");
    }
  }, [setValue, officer]);

  useEffect(() => {
    if (filer) {
      setValue("Filer_Name", filer.keyPersonnelName);
      setValue("Filer_Email_Address", filer.emailId);
      setValue("Filer_Contact_No", extractTenDigitPhoneNumber(filer.contactNo));
      setValue("filer_Address_Line_1", filer.addressMasterId.addressLine1);
      setValue(
        "filer_Address_Line_2",
        filer.addressMasterId.addressLine2
          ? filer.addressMasterId.addressLine2
          : ""
      );
      setValue("filer_City", filer.addressMasterId.city);
      setValue("Filer_State", stateFilter(filer.addressMasterId.stateId));
      setValue(
        "filer_Zip_Code",
        numberToString(filer.addressMasterId.postalCode)
      );
      setDropdownError("Filer");
    }
  }, [setValue, filer]);

  useEffect(() => {
    if (president) {
      setValue("Pre_Name", president.keyPersonnelName);
      setValue("Pre_Email_Address", president.emailId);
      setValue(
        "Pre_Contact_No",
        extractTenDigitPhoneNumber(president.contactNo)
      );
      setValue("Pre_Address_Line_1", president.addressMasterId.addressLine1);
      setValue(
        "Pre_Address_Line_2",
        president.addressMasterId.addressLine2
          ? president.addressMasterId.addressLine2
          : ""
      );
      setValue("Pre_City", president.addressMasterId.city);
      setValue("Pre_State", stateFilter(president.addressMasterId.stateId));
      setValue(
        "Pre_Zip_Code",
        numberToString(president.addressMasterId.postalCode)
      );
      setDropdownError("President");
    }
  }, [setValue, president]);

  useEffect(() => {
    if (treasurer) {
      setValue("Tre_Name", treasurer.keyPersonnelName);
      setValue("Tre_Email_Address", treasurer.emailId);
      setValue(
        "Tre_Contact_No",
        extractTenDigitPhoneNumber(treasurer.contactNo)
      );
      setValue("Tre_Address_Line_1", treasurer.addressMasterId.addressLine1);
      setValue(
        "Tre_Address_Line_2",
        treasurer.addressMasterId.addressLine2
          ? treasurer.addressMasterId.addressLine2
          : ""
      );
      setValue("Tre_City", treasurer.addressMasterId.city);
      setValue("Tre_State", stateFilter(treasurer.addressMasterId.stateId));
      setValue(
        "Tre_Zip_Code",
        numberToString(treasurer.addressMasterId.postalCode)
      );
      setDropdownError("Treasurer");
    }
  }, [setValue, treasurer]);

  useEffect(() => {
    if (secretary) {
      setValue("Sec_Name", secretary.keyPersonnelName);
      setValue("Sec_Email_Address", secretary.emailId);
      setValue(
        "Sec_Contact_No",
        extractTenDigitPhoneNumber(secretary.contactNo)
      );
      setValue("Sec_Address_Line_1", secretary.addressMasterId.addressLine1);
      setValue(
        "Sec_Address_Line_2",
        secretary.addressMasterId.addressLine2
          ? secretary.addressMasterId.addressLine2
          : ""
      );
      setValue("Sec_City", secretary.addressMasterId.city);
      setValue("Sec_State", stateFilter(secretary.addressMasterId.stateId));
      setValue(
        "Sec_Zip_Code",
        numberToString(secretary.addressMasterId.postalCode)
      );
      setDropdownError("Secretary");
    }
  }, [setValue, secretary]);

  useEffect(() => {
    if (director) {
      
      setValue("Dir_Name", director.keyPersonnelName);
      setValue("Dir_Email_Address", director.emailId);
      setValue(
        "Dir_Contact_No",
        extractTenDigitPhoneNumber(director.contactNo)
      );
      setValue("Dir_Address_Line_1", director.addressMasterId.addressLine1);
      setValue(
        "Dir_Address_Line_2",
        director.addressMasterId.addressLine2
          ? director.addressMasterId.addressLine2
          : ""
      );
      setValue("Dir_City", director.addressMasterId.city);
      setValue("Dir_State", stateFilter(director.addressMasterId.stateId));
      setValue(
        "Dir_Zip_Code",
        numberToString(director.addressMasterId.postalCode)
      );
      setDropdownError("Director");
    }
  }, [setValue, director]);

  useEffect(() => {
    const keys = [
      "BI_Name",
      "BI_Email_Address",
      "BI_Contact_No",
      "BI_Address_Line_1",
      "BI_Address_Line_2",
      "BI_City",
      "BI_State",
      "BI_Country",
      "BI_Zip_Code",
    ];
    if (bilingInfo) {
      
      setValue("BI_Name", bilingInfo.keyPersonnelName);
      setValue("BI_Email_Address", bilingInfo.emailId);
      setValue(
        "BI_Contact_No",
        extractTenDigitPhoneNumber(bilingInfo.contactNo)
      );
      setValue("BI_Address_Line_1", bilingInfo.addressMasterId.addressLine1);
      setValue(
        "BI_Address_Line_2",
        bilingInfo.addressMasterId.addressLine2
          ? bilingInfo.addressMasterId.addressLine2
          : ""
      );
      setValue("BI_City", bilingInfo.addressMasterId.city);
      setValue("BI_State", stateFilter(bilingInfo.addressMasterId.stateId));
      setValue(
        "BI_Zip_Code",
        numberToString(bilingInfo.addressMasterId.postalCode)
      );
      keys.forEach((fieldKey) => {
        const errorMessageTag = document.getElementById(`${fieldKey}--error`);
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      });
      setDropdownError("RA_Billing_Information");
    }
  }, [setValue, bilingInfo]);

  useEffect(() => {
    if (mailingInfo) {
      
      setValue("MI_Name", mailingInfo.keyPersonnelName);
      setValue("MI_Email_Address", mailingInfo.emailId);
      setValue(
        "MI_Contact_No",
        extractTenDigitPhoneNumber(mailingInfo.contactNo)
      );
      setValue("MI_Address_Line_1", mailingInfo.addressMasterId.addressLine1);
      setValue(
        "MI_Address_Line_2",
        mailingInfo.addressMasterId.addressLine2
          ? mailingInfo.addressMasterId.addressLine2
          : ""
      );
      setValue("MI_City", mailingInfo.addressMasterId.city);
      setValue("MI_State", stateFilter(mailingInfo.addressMasterId.stateId));
      setValue(
        "MI_Zip_Code",
        numberToString(mailingInfo.addressMasterId.postalCode)
      );
      setDropdownError("RA_Mailing_Information");
    }
  }, [setValue, mailingInfo]);

  useEffect(() => {
    setValue("CI_Name", "vState Filings");
    setValue("CI_Email_Address", "orders@vstatefilings.com");
    setValue("CI_Contact_No", "866-638-3309");
    setValue("CI_Address_Line_1", "301 Mill Road");
    setValue("CI_Address_Line_2", "Suite U5");
    setValue("CI_City", "Hewlett");
    setValue("CI_State", "NY");
    setValue("CI_Zip_Code", "11557");
  }, []);

  useEffect(() => {
    const keys = [
      "MI_Name",
      "MI_Email_Address",
      "MI_Contact_No",
      "MI_Address_Line_1",
      "MI_Address_Line_2",
      "MI_City",
      "MI_State",
      "MI_Country",
      "MI_Zip_Code",
    ];
    if (getValues("BI_Name") !== undefined && IsCheckedAgent1) {
      setValue("MI_Name", getValues("BI_Name"));
      setValue("MI_Email_Address", getValues("BI_Email_Address"));
      setValue("MI_Contact_No", getValues("BI_Contact_No"));
      setValue("MI_Address_Line_1", getValues("BI_Address_Line_1"));
      setValue("MI_Address_Line_2", getValues("BI_Address_Line_2"));
      setValue("MI_City", getValues("BI_City"));
      setValue("MI_State", getValues("BI_State"));
      setValue("MI_Country", getValues("BI_Country"));
      setValue("MI_Zip_Code", getValues("BI_Zip_Code"));
      setDropdownError("RA_Mailing_Information");

      keys.forEach((fieldKey) => {
        const errorMessageTag = document.getElementById(`${fieldKey}--error`);
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      });
    } else {
      if (!IsCheckedAgent1 && !IsCheckedAgent2) {
        keys.forEach((fieldKey) => setValue(fieldKey, ""));
      }
    }
  }, [setValue, IsCheckedAgent1, getValues]);

  // useEffect(() => {
  //     if (location) {
  //         const updatedLocation = allLocation.find(loc => loc.id === location.id);
  //         if (updatedLocation) {
  //             setLocation(updatedLocation);
  //         }
  //     }
  // }, [allLocation]);

  // useEffect(() => {
  //     if (organizerData) {
  //         const updatedData = allOrganizer.find(loc => loc.id === organizerData.id);
  //         if (updatedData) {
  //             setOrganizerData(updatedData);
  //         }
  //     }
  // }, [allOrganizer]);

  const typeMappings = {
    location: { list: allLocation, setter: setLocation },
    organizerData: { list: allOrganizer, setter: setOrganizerData },
    incorporator: { list: allInCorporator, setter: setIncorporator },
    bilingAddress: { list: allBilingAddress, setter: setBilingAddress },
    shippingAddress: { list: allShippingAddress, setter: setShippingAddress },
    officer: { list: allOfficer, setter: setOfficer },
    director: { list: allDirector, setter: setDirector },
    // securedParty: { list: allSecuredParty, setter: setSecuredParty },
    // debtor: { list: allDebtor, setter: setDebtor },
    // assignee: { list: allAssignee, setter: setAssignee },
    filer: { list: allFiler, setter: setFiler },
    president: { list: allPresident, setter: setPresident },
    treasurer: { list: allTreasurer, setter: setTreasurer },
    secretary: { list: allSecretary, setter: setSecretary },
    bilingInfo: { list: allBilingInfo, setter: setBilingInfo },
    mailingInfo: { list: allMailingInfo, setter: setMailingInfo },
  };

  useEffect(() => {
    Object.entries(typeMappings).forEach(([key, { list, setter }]) => {
      if (list && setter) {
        const currentData = eval(key);
        if (currentData) {
          const updatedData = list.find((item) => item.id === currentData.id);
          if (updatedData) {
            setter(updatedData);
          }
        }
      }
    });
  }, [
    allLocation,
    allOrganizer,
    allInCorporator,
    allBilingAddress,
    allShippingAddress,
    allOfficer,
    allDirector,
    allSecuredParty,
    allAssignee,
    allFiler,
    allPresident,
    allTreasurer,
    allSecretary,
    allBilingInfo,
    allMailingInfo,
  ]);

  // useEffect(() => {
  //     const fieldCount = Object.keys(formCounts).length > 0 ? formCounts["MEMBER_OR_MANAGER"] || 1 : 1;

  //     for (let i = 0; i < fieldCount; i++) {
  //         const fieldName = `member_or_manger_details-${i + 1}`;
  //         const existingValue = memberData

  //         console.log(memberData)

  //         if (existingValue) {
  //             const updatedValue = allMember.find((item) => item.id === existingValue.id);
  //             console.log(updatedValue)
  //             if (updatedValue) {
  //                 setValue(fieldName, updatedValue);
  //             }
  //         }
  //     }
  // }, [allMember, formCounts, setValue, getValues]);

  useEffect(() => {
    const keys = [
      "MI_Name",
      "MI_Email_Address",
      "MI_Contact_No",
      "MI_Address_Line_1",
      "MI_Address_Line_2",
      "MI_City",
      "MI_State",
      "MI_Country",
      "MI_Zip_Code",
    ];

    if (getValues("RA_Name") !== undefined && IsCheckedAgent2) {
      setValue("MI_Name", getValues("RA_Name"));
      setValue("MI_Email_Address", getValues("RA_Email_Address"));
      setValue("MI_Contact_No", getValues("RA_Contact_No"));
      setValue("MI_Address_Line_1", getValues("RA_Address_Line_1"));
      setValue("MI_Address_Line_2", getValues("RA_Address_Line_2"));
      setValue("MI_City", getValues("RA_City"));
      setValue("MI_State", getValues("RA_State"));
      setValue("MI_Country", getValues("RA_Country"));
      setValue("MI_Zip_Code", getValues("RA_Zip_Code"));

      keys.forEach((fieldKey) => {
        const errorMessageTag = document.getElementById(`${fieldKey}--error`);
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      });
    } else {
      if (!IsCheckedAgent1 && !IsCheckedAgent2) {
        keys.forEach((fieldKey) => setValue(fieldKey, ""));
      }
    }
  }, [IsCheckedAgent2, getValues]);

  useEffect(() => {
    if (location) {
      
      setValue("PA_Address_Line_1", location.addressLine1);
      setValue(
        "PA_Address_Line_2",
        location.addressLine2 ? location.addressLine2 : ""
      );
      setValue("PA_City", location.city);
      setValue("PA_State", stateFilter(location.stateId));
      setValue("PA_Zip_Code", numberToString(location.postalCode));
      setDropdownError("Principal_Address");
    }
  }, [setValue, location]);

  useEffect(() => {
    const keys = [
      "RA_Name",
      "RA_Email_Address",
      "RA_Contact_No",
      "RA_Address_Line_1",
      "RA_Address_Line_2",
      "RA_City",
      "RA_State",
      "RA_Country",
      "RA_Zip_Code",
    ];

    if (registeredAgent && IsCheckedAgent && servicesData.length > 0) {
      setValue("RA_Name", registeredAgent.registeredAgencyName);
      setValue("RA_Email_Address", registeredAgent.emailId);
      setValue(
        "RA_Contact_No",
        extractTenDigitPhoneNumber(registeredAgent.phoneNo)
      );
      setValue("RA_Address_Line_1", registeredAgent.addressLine1);
      setValue("RA_Address_Line_2", registeredAgent.addressLine2 || "");
      setValue("RA_City", registeredAgent.city);
      setValue("RA_State", registeredAgent.stateMasterId.stateShortName);
      setValue("RA_Zip_Code", numberToString(registeredAgent.zipcode));

      keys.forEach((fieldKey) => {
        const errorMessageTag = document.getElementById(`${fieldKey}--error`);
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      });

      setSelectedProducts((prevSelected) => ({
        ...prevSelected,
        24: ["Registered Agent"],
      }));

      setSelectedCheckBoxData((prevCheckboxData) => {
        const updatedCheckboxData = [...prevCheckboxData];

        const orderType24Data = servicesData.find(
          (val) => val?.product?.orderTypeMaster === 24
        );

        if (
          !updatedCheckboxData?.some(
            (data) => data?.product?.orderTypeMaster === 24 && orderType24Data
          )
        ) {
          updatedCheckboxData.push(orderType24Data);
        }

        return updatedCheckboxData;
      });
    } else {
      // Clear values when conditions aren't met
      keys.forEach((fieldKey) => setValue(fieldKey, ""));
      if (state) {
        setValue("RA_State", stateFilter(state.id));
      }
      setSelectedProducts((prevSelected) => {
        const updatedSelected = { ...prevSelected };
        delete updatedSelected[24];
        return updatedSelected;
      });

      setSelectedCheckBoxData((prevCheckboxData) =>
        prevCheckboxData.filter((data) => data?.product?.orderTypeMaster !== 24)
      );
    }
  }, [IsCheckedAgent, registeredAgent, state, servicesData]);

  const handleRegisteredAgent = (e) => {
    setIsCheckedAgent(e.checked);
  };

  useEffect(() => {
    if (location) {
      setValue("Principal_Address", location);
    }
  }, [location]);

  useEffect(() => {
    if (assigneeData) {
      setValue("ad_Name_of_Assignee", assigneeData.keyPersonnelName);

      setValue("ad_Address_Line_1", assigneeData.addressMasterId.addressLine1);
      setValue(
        "ad_Address_Line_2",
        assigneeData.addressMasterId.addressLine2
          ? assigneeData.addressMasterId.addressLine2
          : ""
      );
      setValue("ad_City", assigneeData.addressMasterId.city);
      setValue("ad_State", stateFilter(assigneeData.addressMasterId.stateId));
      setValue(
        "ad_Zip_Code",
        numberToString(assigneeData.addressMasterId.postalCode)
      );
    }
  }, [assigneeData]);

  const handleDebtorChange = (selectedDebtor, index) => {
    
    setValue(`debt_Name_Of_Debtor-${index}`, selectedDebtor.keyPersonnelName);
    setValue(
      `debt_Address_Line_1-${index}`,
      selectedDebtor.addressMasterId.addressLine1
    );
    setValue(
      `debt_Address_Line_2-${index}`,
      selectedDebtor.addressMasterId.addressLine2
        ? selectedDebtor.addressMasterId.addressLine2
        : ""
    );
    setValue(`debt_City-${index}`, selectedDebtor.addressMasterId.city);
    setValue(
      `debt_State-${index}`,
      stateFilter(selectedDebtor.addressMasterId.stateId)
    );
    setValue(
      `debt_Zip_Code-${index}`,
      numberToString(selectedDebtor.addressMasterId.postalCode)
    );
  };

  const handleMemberOrManagerType = (value, l) => {
    setValue(`Mom_Member_Or_Manager-${l}`, getValues(`member_or_manger-${l}`));
  };

  const handleMemberOrManager = (selectedMember, index) => {
    setAllMemberOrManager((prevData) => {
      const updatedMember = {
        [`member_or_manger_details-${index}`]: selectedMember,
      };
      return [
        ...prevData.filter(
          (data) => !data[`member_or_manger_details-${index}`]
        ),
        updatedMember,
      ];
    });
    setValue(`Mom_Name-${index}`, selectedMember.keyPersonnelName);
    setValue(
      `MM_Address_Line_1-${index}`,
      selectedMember.addressMasterId.addressLine1
    );
    setValue(
      `MM_Address_Line_2-${index}`,
      selectedMember.addressMasterId.addressLine2
        ? selectedMember.addressMasterId.addressLine2
        : ""
    );
    setValue(`MM_City-${index}`, selectedMember.addressMasterId.city);
    setValue(
      `MM_State-${index}`,
      stateFilter(selectedMember.addressMasterId.stateId)
    );
    setValue(
      `MM_Zip_Code-${index}`,
      numberToString(selectedMember.addressMasterId.postalCode)
    );

    const errorMessageTag = document.getElementById(
      `Member_Or_Manager_Details-${index + 1}--error`
    );
    if (errorMessageTag) {
      errorMessageTag.textContent = "";
    }
  };

  const handleBeneficialOwnerChange = (selectedDebtor, index) => {
    
    setValue(`boi_First_Name-${index}`, selectedDebtor.firstName);
    setValue(`boi_Middle_Name-${index}`, selectedDebtor.middleName);
    setValue(`boi_Last_Name-${index}`, selectedDebtor.lastName);
    setValue(`boi_Suffix-${index}`, selectedDebtor.suffix);
    setValue(`boi_Email-${index}`, selectedDebtor.email);
    setValue(`boi_Date_of_Birth-${index}`, selectedDebtor.dateOfBirth);
    setValue(
      `boi_Residential_Address-${index}`,
      `${selectedDebtor.residentialAddress}`
    );
    // setValue(`debt_Address_Line_2-${index}`, selectedDebtor.addressMasterId.addressLine2);
    setValue(`boi_City-${index}`, selectedDebtor.city);
    setValue(`boi_Contact_No-${index}`, selectedDebtor.contactNumber);
    setValue(`boi_State-${index}`, selectedDebtor.state);
    setValue(`boi_Country-${index}`, selectedDebtor.country);
    setValue(`boi_Zip_Code-${index}`, numberToString(selectedDebtor.zipCode));
    setValue(`boi_Have_a_FinCEN_ID-${index}`, selectedDebtor.haveFinCenId);
    setValue(`boi_FinCEN_ID_Number-${index}`, selectedDebtor.finCenIdNumber);
    setValue(
      `boi_Last_Name_or_Business_Legal_Name-${index}`,
      selectedDebtor.lastNameOrBusinessLegalName
    );
    setValue(
      `boi_Issuing_Jurisdiction_State-${index}`,
      selectedDebtor.issuingJurisdictionState
    );
    setValue(
      `boi_Issuing_Jurisdiction_Country-${index}`,
      selectedDebtor.issuingJurisdictionCountry
    );
    setValue(
      `boi_Choose_Identity_documents_type_to_upload-${index}`,
      selectedDebtor.documentType
    );
    setValue(`boi_Upload_ID-${index}`, selectedDebtor.uploadId);
    setValue(
      `boi_Upload_the_ID_page_of_your_document-${index}`,
      selectedDebtor.uploadIDPage
    );
    setValue(
      `boi_FinCEN_ID_Document-${index}`,
      selectedDebtor.finCenIdDocument
    );
    setValue(
      `boi_Identifying_Document_No-${index}`,
      selectedDebtor.identifyingDocumentNo
    );
    setValue(`boi_Exempt_Entity-${index}`, selectedDebtor.exemptEntity);
    // if (Array.isArray(selectedDebtor.documentIds)) {
    //     const filteredDocuments = allDocuments.filter(doc => selectedDebtor.documentIds.includes(doc.id));
    //     const uploadIdDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id"));
    //     const uploadIdPageDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id_page_of_your_document"));
    //     if (uploadIdDoc) {
    //         setValue(`boi_Upload_ID-${index}`, uploadIdDoc.documentPath)
    //     }
    //     if (uploadIdPageDoc) {
    //         setValue(`boi_Upload_the_ID_page_of_your_document-${index}`, uploadIdPageDoc.documentPath)
    //     }
    // }
  };

  const handleCompanyApplicantChange = (selectedDebtor, index) => {
    
    setValue(`boica_First_Name-${index}`, selectedDebtor.firstName);
    setValue(`boica_Middle_Name-${index}`, selectedDebtor.middleName);
    setValue(`boica_Last_Name-${index}`, selectedDebtor.lastName);
    setValue(`boica_Suffix-${index}`, selectedDebtor.suffix);
    setValue(`boica_Email-${index}`, selectedDebtor.email);
    setValue(`boica_Date_of_Birth-${index}`, selectedDebtor.dateOfBirth);
    setValue(
      `boica_Residential_Address-${index}`,
      `${selectedDebtor.residentialAddress}`
    );
    // setValue(`debt_Address_Line_2-${index}`, selectedDebtor.addressMasterId.addressLine2);
    setValue(`boica_City-${index}`, selectedDebtor.city);
    setValue(`boica_Contact_No-${index}`, selectedDebtor.contactNumber);
    setValue(`boica_State_or_Province-${index}`, selectedDebtor.state);
    setValue(`boica_Country-${index}`, selectedDebtor.country);
    setValue(`boica_Zip_Code-${index}`, numberToString(selectedDebtor.zipCode));
    setValue(`boica_Have_a_FinCEN_ID-${index}`, selectedDebtor.haveFinCenId);
    setValue(`boica_FinCEN_ID_Number-${index}`, selectedDebtor.finCenIdNumber);
    setValue(
      `boica_Issuing_Jurisdiction_State-${index}`,
      selectedDebtor.issuingJurisdictionState
    );
    setValue(
      `boica_Issuing_Jurisdiction_Country-${index}`,
      selectedDebtor.issuingJurisdictionCountry
    );
    setValue(
      `boica_Choose_Identity_documents_type_to_upload-${index}`,
      selectedDebtor.documentType
    );
    setValue(`boica_Upload_ID-${index}`, selectedDebtor.uploadId);
    setValue(
      `boica_Upload_the_ID_page_of_your_document-${index}`,
      selectedDebtor.uploadIDPage
    );
    setValue(
      `boica_FinCEN_ID_Document-${index}`,
      selectedDebtor.finCenIdDocument
    );
    setValue(
      `boica_Identifying_Document_No-${index}`,
      selectedDebtor.identifyingDocumentNo
    );
    // if (Array.isArray(selectedDebtor.documentIds)) {
    //     const filteredDocuments = allDocuments.filter(doc => selectedDebtor.documentIds.includes(doc.id));
    //     const uploadIdDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id"));
    //     const uploadIdPageDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id_page_of_your_document"));
    //     if (uploadIdDoc) {
    //         setValue(`boica_Upload_ID-${index}`, uploadIdDoc.documentPath)
    //     }
    //     if (uploadIdPageDoc) {
    //         setValue(`boica_Upload_the_ID_page_of_your_document-${index}`, uploadIdPageDoc.documentPath)
    //     }
    // }
  };

  const handleAdditionalDebtorChange = (selectedDebtor, index) => {
    setValue(
      `uccAmend_Name_of_Debtor-${index}`,
      selectedDebtor.keyPersonnelName
    );
    setValue(
      `uccAmend_Address_Line_1-${index}`,
      selectedDebtor.addressMasterId.addressLine1
    );
    setValue(
      `uccAmend_Address_Line_2-${index}`,
      selectedDebtor.addressMasterId.addressLine2
        ? selectedDebtor.addressMasterId.addressLine2
        : ""
    );
    setValue(`uccAmend_City-${index}`, selectedDebtor.addressMasterId.city);
    setValue(
      `uccAmend_State-${index}`,
      stateFilter(selectedDebtor.addressMasterId.stateId)
    );
    setValue(
      `uccAmend_Zip_Code-${index}`,
      numberToString(selectedDebtor.addressMasterId.postalCode)
    );
  };

  const handleAdditionalSecuredPartyChange1 = (selectedDebtor, index) => {
    
    setValue(
      `sec_Name_Of_Secured_Party-${index}`,
      selectedDebtor.keyPersonnelName
    );
    setValue(
      `sec_Address_Line_1-${index}`,
      selectedDebtor.addressMasterId.addressLine1
    );
    setValue(
      `sec_Address_Line_2-${index}`,
      selectedDebtor.addressMasterId.addressLine2
        ? selectedDebtor.addressMasterId.addressLine2
        : ""
    );
    setValue(`sec_City-${index}`, selectedDebtor.addressMasterId.city);
    setValue(
      `sec_State-${index}`,
      stateFilter(selectedDebtor.addressMasterId.stateId)
    );
    setValue(
      `sec_Zip_Code-${index}`,
      numberToString(selectedDebtor.addressMasterId.postalCode)
    );
  };

  const handleAdditionalSecuredPartyChange = (selectedDebtor, index) => {
    
    setValue(
      `sp_Name_of_Secured_Party-${index}`,
      selectedDebtor.keyPersonnelName
    );
    setValue(
      `sp_Address_Line_1-${index}`,
      selectedDebtor.addressMasterId.addressLine1
    );
    setValue(
      `sp_Address_Line_2-${index}`,
      selectedDebtor.addressMasterId.addressLine2
        ? selectedDebtor.addressMasterId.addressLine2
        : ""
    );
    setValue(`sp_City-${index}`, selectedDebtor.addressMasterId.city);
    setValue(
      `sp_State-${index}`,
      stateFilter(selectedDebtor.addressMasterId.stateId)
    );
    setValue(
      `sp_Zip_Code-${index}`,
      numberToString(selectedDebtor.addressMasterId.postalCode)
    );
  };

  useEffect(() => {
    const data = watch();
    

    // if (state) {
    //     setValue("ANR_State_of_Formation", state.stateFullDesc)
    // }
    const tem = allModels.map((val) => {
      
      const temps = Object.keys(data).reduce((acc, key) => {
        if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      const convertdata = val.apiId;

      if (convertdata === "boi.boi-company-details") {
        const manualEntries = {
          ActivityPartyTypeCode: "62",
          PartyNameTypeCode: "L",
          BusinessAddressIndicator: "Y",
          ResidentialAddressIndicator: "Y",
        };

        Object.assign(temps, manualEntries);
      }

      return {
        model: convertdata,
        data: temps,
      };
    });

    
    const tempsData = allModels.map((val) => {
      
      const temps = Object.keys(data).reduce((acc, key) => {
        

        if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
          acc[key] = data[key];
        }

        return acc;
      }, {});

      const convertdata = val.uid;
      if (convertdata === "boi.boi-company-details") {
        const manualEntries = {
          ActivityPartyTypeCode: "62",
          PartyNameTypeCode: "L",
          BusinessAddressIndicator: "Y",
          ResidentialAddressIndicator: "Y",
        };

        Object.assign(temps, manualEntries);
      }
      if (
        convertdata === "momm.name-and-address" ||
        convertdata === "momm.option-name-address"
      ) {
        const addressFields = [
          "MM_Address_Line_1",
          "MM_Address_Line_2",
          "MM_City",
          "MM_State",
          "MM_Country",
          "MM_Zip_Code",
        ];
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = { __temp_key__: parseInt(index), Address: {} };
              }

              if (addressFields.includes(baseKey)) {
                acc[index].Address[baseKey] = data[key];
              } else {
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        

        const formDatas = formData.filter((val) => val !== undefined);
        

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "debtor-details.debtor") {
        const addressFields = [
          "debt_Address_Line_1",
          "debt_Address_Line_2",
          "debt_City",
          "debt_State",
          "debt_Country",
          "debt_Zip_Code",
        ];
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  ucc_Address_Of_Debtor: {},
                };
              }

              if (addressFields.includes(baseKey)) {
                acc[index].ucc_Address_Of_Debtor[baseKey] = data[key];
              } else if (
                baseKey.startsWith("debt") &&
                !addressFields.includes(baseKey)
              ) {
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        

        const formDatas = formData.filter((val) => val !== undefined);
        

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "ucc-amend.uuc-amnd-what-are-amending") {
        const addressFields = [
          "uccAmend_Address_Line_1",
          "uccAmend_Address_Line_2",
          "uccAmend_City",
          "uccAmend_State",
          "uccAmend_Country",
          "uccAmend_Zip_Code",
          "uccAmend_Name_of_Debtor",
        ];

        const spAddressFields = [
          "sp_Name_of_Secured_Party",
          "sp_Address_Line_1",
          "sp_Address_Line_2",
          "sp_City",
          "sp_State",
          "sp_Zip_Code",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            

            if (baseKey) {
              if (!acc.Additional_Secured_Party_Details) {
                acc.Additional_Secured_Party_Details = [];
              }

              let debtorDetails1 = acc.Additional_Secured_Party_Details.find(
                (item) => item.__temp_key__ === parseInt(index)
              );

              if (!debtorDetails1) {
                debtorDetails1 = { __temp_key__: parseInt(index) };
                acc.Additional_Secured_Party_Details.push(debtorDetails1);
              }

              if (!acc.uccAmend_Additional_Debtor_Details) {
                acc.uccAmend_Additional_Debtor_Details = [];
              }

              let debtorDetails = acc.uccAmend_Additional_Debtor_Details.find(
                (item) => item.__temp_key__ === parseInt(index)
              );
              

              if (!debtorDetails) {
                debtorDetails = { __temp_key__: parseInt(index) };
                acc.uccAmend_Additional_Debtor_Details.push(debtorDetails);
              }
              if (baseKey.startsWith("sp")) {
                if (spAddressFields.includes(baseKey)) {
                  debtorDetails1[baseKey] = data[key];
                }
              } else {
                if (addressFields.includes(baseKey)) {
                  debtorDetails[baseKey] = data[key];
                }
              }
            }
          } else if (
            key.startsWith("uccAmend") &&
            !addressFields.includes(key)
          ) {
            acc[key] = data[key];
          }

          return acc;
        }, {});

        

        return {
          model: convertdata,
          data: formData,
        };
      } else if (convertdata === "secured-party.secured-party") {
        const addressFields = [
          "sec_Address_Line_1",
          "sec_Address_Line_2",
          "sec_City",
          "sec_State",
          "sec_Country",
          "sec_Zip_Code",
        ];
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  ucc_Address_Of_Secured_Party: {},
                };
              }

              if (addressFields.includes(baseKey)) {
                acc[index].ucc_Address_Of_Secured_Party[baseKey] = data[key];
              } else if (
                baseKey.startsWith("sec") &&
                !addressFields.includes(baseKey)
              ) {
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        

        const formDatas = formData.filter((val) => val !== undefined);
        

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "boi.company-applicant") {
        const companyApplicantDetails = [
          "boica_First_Name",
          "boica_Middle_Name",
          "boica_Last_Name",
          "boica_Suffix",
          "boica_Date_of_Birth",
          "boica_Email",
          "boica_Contact_No",
          "boica_Residential_Address",
          "boica_Country",
          "boica_City",
          "boica_State_or_Province",
          "boica_Zip_Code",
          "boica_Choose_Identity_document_type_to_upload",
          "boica_Upload_the_ID_page_of_your_document",
          "boica_Upload_ID",
          "boica_Have_a_FinCEN_ID",
          "boica_FinCEN_ID_Number",
          "boica_Upload_the_ID_page_of_your_document",
        ];
        
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = { __temp_key__: parseInt(index) };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[index][baseKey] = data[key];
              } else if (
                baseKey.startsWith("boica") &&
                !baseKey.startsWith("boi") &&
                !companyApplicantDetails.includes(baseKey)
              ) {
                
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        
        const manualEntries = [
          { key: "ActivityPartyTypeCode", value: "63" },
          { key: "PartyNameTypeCode", value: "L" },
          { key: "BusinessAddressIndicator", value: "Y" },
          { key: "ResidentialAddressIndicator", value: "Y" },
        ];

        manualEntries.forEach((entry) => {
          formData.forEach((formObj, index) => {
            if (!formObj[entry.key]) {
              formObj[entry.key] = entry.value;
            }
          });
        });
        formData.forEach((formObj) => {
          const identityDocumentType =
            formObj["boica_Choose_Identity_document_type_to_upload"];
          let partyIdentificationTypeCode;

          if (identityDocumentType === "State Issued Drivers License") {
            partyIdentificationTypeCode = 37;
          } else if (identityDocumentType === "US Passport") {
            partyIdentificationTypeCode = 39;
          } else if (identityDocumentType === "Foreign Passport") {
            partyIdentificationTypeCode = 40;
          }
          if (partyIdentificationTypeCode !== undefined) {
            formObj["PartyIdentificationTypeCode"] =
              partyIdentificationTypeCode;
          }
        });

        const formDatas = formData.filter((val) => val !== undefined);
        

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "boi-except-entity-form") {
        const companyApplicantDetails = [
          "boi_Last_Name_or_Business_Legal_Name",
        ];
        
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  boi_Exempt_Entity_Information: {},
                };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[index].boi_Exempt_Entity_Information[baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        

        const formDatas = formData.filter((val) => val !== undefined);
        

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "boi.beneficial-owner-info") {
        const companyApplicantDetails = [
          "boi_First_Name",
          "boi_Middle_Name",
          "boi_Last_Name",
          "boi_Suffix",
          "boi_Date_of_Birth",
          "boi_Email",
          "boi_Contact_No",
          "boi_Residential_Address",
          "boi_Country",
          "boi_City",
          "boi_State",
          "boi_Zip_Code",
          "boi_Choose_Identity_document_type_to_upload",
          "boi_Upload_the_ID_page_of_your_document",
          "boi_Upload_ID",
          "boi_Have_a_FinCEN_ID",
          "boi_FinCEN_ID_Number",
        ];
        
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  boi_Beneficial_Owner_Information: {},
                };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                
                acc[index].boi_Beneficial_Owner_Information[baseKey] =
                  data[key];
              } else if (
                baseKey.startsWith("boi") &&
                !baseKey.startsWith("boica") &&
                !companyApplicantDetails.includes(baseKey)
              ) {
                
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        
        const manualEntries = [
          { key: "ActivityPartyTypeCode", value: "64" },
          { key: "PartyNameTypeCode", value: "L" },
          { key: "BusinessAddressIndicator", value: "Y" },
          { key: "ResidentialAddressIndicator", value: "Y" },
        ];

        manualEntries.forEach((entry) => {
          formData.forEach((formObj, index) => {
            if (!formObj[entry.key]) {
              formObj[entry.key] = entry.value;
            }
          });
        });
        formData.forEach((formObj) => {
          const identityDocumentType =
            formObj["boi_Choose_Identity_document_type_to_upload"];
          let partyIdentificationTypeCode;

          if (identityDocumentType === "State Issued Drivers License") {
            partyIdentificationTypeCode = 37;
          } else if (identityDocumentType === "US Passport") {
            partyIdentificationTypeCode = 39;
          } else if (identityDocumentType === "Foreign Passport") {
            partyIdentificationTypeCode = 40;
          }

          if (partyIdentificationTypeCode !== undefined) {
            formObj["PartyIdentificationTypeCode"] =
              partyIdentificationTypeCode;
          }
        });

        const formDatas = formData.filter((val) => val !== undefined);
        

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else {
        const formData = Object.keys(data).map((keys, j) => {
          if (keys.includes("-")) {
            
            const baseKey = keys.split("-")[0];
            

            if (
              baseKey &&
              Object.keys(val.schema.attributes).some((ele) => ele === baseKey)
            ) {
              
              return {
                __temp_key__: j + 1,
                [baseKey]: data[keys],
              };
            }
          }
        });

        const formDatas = formData.filter((val) => val !== undefined);
        

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      }
    });

    

    setModelData(tem);
    setModelData1(tempsData);
  }, [getValues, setValue, currentTab, formCounts]);

  function processFormData(allModels) {
    const data = watch();
    
    return allModels.map((val) => {
      const temps = Object.keys(data).reduce((acc, key) => {
        if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      const convertdata = val.uid;

      if (convertdata === "boi.boi-company-details") {
        Object.assign(temps, {
          ActivityPartyTypeCode: "62",
          PartyNameTypeCode: "L",
          BusinessAddressIndicator: "Y",
          ResidentialAddressIndicator: "Y",
        });
      }

      const handleIndexedFields = (addressFields, nestedFieldName) => {
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            if (baseKey) {
              if (!acc[index]) {
                acc[index] = { __temp_key__: parseInt(index) };
              }
              if (addressFields.includes(baseKey)) {
                if (!acc[index][nestedFieldName]) {
                  acc[index][nestedFieldName] = {};
                }
                acc[index][nestedFieldName][baseKey] = data[key];
              } else {
                acc[index][baseKey] = data[key];
              }
            }
          }
          return acc;
        }, []);
        return formData.filter((val) => val !== undefined);
      };

      if (
        convertdata === "momm.name-and-address" ||
        convertdata === "momm.option-name-address"
      ) {
        const addressFields = [
          "MM_Address_Line_1",
          "MM_Address_Line_2",
          "MM_City",
          "MM_State",
          "MM_Country",
          "MM_Zip_Code",
        ];
        const formDatas = handleIndexedFields(addressFields, "Address");
        return {
          model: convertdata,
          data: formDatas.length > 0 ? formDatas : temps,
        };
      }

      if (convertdata === "debtor-details.debtor") {
        const addressFields = [
          "debt_Address_Line_1",
          "debt_Address_Line_2",
          "debt_City",
          "debt_State",
          "debt_Country",
          "debt_Zip_Code",
        ];
        const formDatas = handleIndexedFields(
          addressFields,
          "ucc_Address_Of_Debtor"
        );
        return {
          model: convertdata,
          data: formDatas.length > 0 ? formDatas : temps,
        };
      }

      if (convertdata === "secured-party.secured-party") {
        const addressFields = [
          "sec_Address_Line_1",
          "sec_Address_Line_2",
          "sec_City",
          "sec_State",
          "sec_Country",
          "sec_Zip_Code",
        ];
        const formDatas = handleIndexedFields(
          addressFields,
          "ucc_Address_Of_Secured_Party"
        );
        return {
          model: convertdata,
          data: formDatas.length > 0 ? formDatas : temps,
        };
      }

      if (convertdata === "boi.company-applicant") {
        const companyApplicantDetails = [
          "boica_First_Name",
          "boica_Middle_Name",
          "boica_Last_Name",
          "boica_Suffix",
          "boica_Date_of_Birth",
          "boica_Email",
          "boica_Contact_No",
          "boica_Residential_Address",
          "boica_Country",
          "boica_City",
          "boica_State_or_Province",
          "boica_Zip_Code",
          "boica_Choose_Identity_document_type_to_upload",
          "boica_Upload_the_ID_page_of_your_document",
          "boica_Upload_ID",
          "boica_Have_a_FinCEN_ID",
          "boica_FinCEN_ID_Number",
          "boica_Upload_the_ID_page_of_your_document",
        ];
        
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = { __temp_key__: parseInt(index) };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[index][baseKey] = data[key];
              } else if (
                baseKey.startsWith("boica") &&
                !baseKey.startsWith("boi") &&
                !companyApplicantDetails.includes(baseKey)
              ) {
                
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        
        const manualEntries = [
          { key: "ActivityPartyTypeCode", value: "63" },
          { key: "PartyNameTypeCode", value: "L" },
          { key: "BusinessAddressIndicator", value: "Y" },
          { key: "ResidentialAddressIndicator", value: "Y" },
        ];

        manualEntries.forEach((entry) => {
          formData.forEach((formObj, index) => {
            if (!formObj[entry.key]) {
              formObj[entry.key] = entry.value;
            }
          });
        });
        formData.forEach((formObj) => {
          const identityDocumentType =
            formObj["boica_Choose_Identity_document_type_to_upload"];
          let partyIdentificationTypeCode;

          if (identityDocumentType === "State Issued Drivers License") {
            partyIdentificationTypeCode = 37;
          } else if (identityDocumentType === "US Passport") {
            partyIdentificationTypeCode = 39;
          } else if (identityDocumentType === "Foreign Passport") {
            partyIdentificationTypeCode = 40;
          }
          if (partyIdentificationTypeCode !== undefined) {
            formObj["PartyIdentificationTypeCode"] =
              partyIdentificationTypeCode;
          }
        });

        const formDatas = formData.filter((val) => val !== undefined);
        

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "boi.beneficial-owner-info") {
        const companyApplicantDetails = [
          "boi_First_Name",
          "boi_Middle_Name",
          "boi_Last_Name",
          "boi_Suffix",
          "boi_Date_of_Birth",
          "boi_Email",
          "boi_Contact_No",
          "boi_Residential_Address",
          "boi_Country",
          "boi_City",
          "boi_State",
          "boi_Zip_Code",
          "boi_Choose_Identity_document_type_to_upload",
          "boi_Upload_the_ID_page_of_your_document",
          "boi_Upload_ID",
          "boi_Have_a_FinCEN_ID",
          "boi_FinCEN_ID_Number",
        ];
        
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  boi_Beneficial_Owner_Information: {},
                };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                
                acc[index].boi_Beneficial_Owner_Information[baseKey] =
                  data[key];
              } else if (
                baseKey.startsWith("boi") &&
                !baseKey.startsWith("boica") &&
                !companyApplicantDetails.includes(baseKey)
              ) {
                
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        
        const manualEntries = [
          { key: "ActivityPartyTypeCode", value: "64" },
          { key: "PartyNameTypeCode", value: "L" },
          { key: "BusinessAddressIndicator", value: "Y" },
          { key: "ResidentialAddressIndicator", value: "Y" },
        ];

        manualEntries.forEach((entry) => {
          formData.forEach((formObj, index) => {
            if (!formObj[entry.key]) {
              formObj[entry.key] = entry.value;
            }
          });
        });
        formData.forEach((formObj) => {
          const identityDocumentType =
            formObj["boi_Choose_Identity_document_type_to_upload"];
          let partyIdentificationTypeCode;

          if (identityDocumentType === "State Issued Drivers License") {
            partyIdentificationTypeCode = 37;
          } else if (identityDocumentType === "US Passport") {
            partyIdentificationTypeCode = 39;
          } else if (identityDocumentType === "Foreign Passport") {
            partyIdentificationTypeCode = 40;
          }

          if (partyIdentificationTypeCode !== undefined) {
            formObj["PartyIdentificationTypeCode"] =
              partyIdentificationTypeCode;
          }
        });

        const formDatas = formData.filter((val) => val !== undefined);
        

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "boi-except-entity-form") {
        const companyApplicantDetails = [
          "boi_Last_Name_or_Business_Legal_Name",
        ];
        
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  boi_Exempt_Entity_Information: {},
                };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[index].boi_Exempt_Entity_Information[baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        

        const formDatas = formData.filter((val) => val !== undefined);
        

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "ucc-amend.uuc-amnd-what-are-amending") {
        const addressFields = [
          "uccAmend_Address_Line_1",
          "uccAmend_Address_Line_2",
          "uccAmend_City",
          "uccAmend_State",
          "uccAmend_Country",
          "uccAmend_Zip_Code",
          "uccAmend_Name_of_Debtor",
        ];

        const spAddressFields = [
          "sp_Name_of_Secured_Party",
          "sp_Address_Line_1",
          "sp_Address_Line_2",
          "sp_City",
          "sp_State",
          "sp_Zip_Code",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            

            if (baseKey) {
              if (!acc.Additional_Secured_Party_Details) {
                acc.Additional_Secured_Party_Details = [];
              }

              let debtorDetails1 = acc.Additional_Secured_Party_Details.find(
                (item) => item.__temp_key__ === parseInt(index)
              );

              if (!debtorDetails1) {
                debtorDetails1 = { __temp_key__: parseInt(index) };
                acc.Additional_Secured_Party_Details.push(debtorDetails1);
              }

              if (!acc.uccAmend_Additional_Debtor_Details) {
                acc.uccAmend_Additional_Debtor_Details = [];
              }

              let debtorDetails = acc.uccAmend_Additional_Debtor_Details.find(
                (item) => item.__temp_key__ === parseInt(index)
              );
              

              if (!debtorDetails) {
                debtorDetails = { __temp_key__: parseInt(index) };
                acc.uccAmend_Additional_Debtor_Details.push(debtorDetails);
              }
              if (baseKey.startsWith("sp")) {
                if (spAddressFields.includes(baseKey)) {
                  debtorDetails1[baseKey] = data[key];
                }
              } else {
                if (addressFields.includes(baseKey)) {
                  debtorDetails[baseKey] = data[key];
                }
              }
            }
          } else if (
            key.startsWith("uccAmend") &&
            !addressFields.includes(key)
          ) {
            acc[key] = data[key];
          }

          return acc;
        }, {});

        

        return {
          model: convertdata,
          data: formData,
        };
      }

      return { model: convertdata, data: temps };
    });
  }

  useEffect(() => {
    AddressService.getAllStates()
      .then((res) => {
        
        if (res.data !== "") {
          setAllState(res.data);
        }
      })
      .catch((err) => {
        
      });

    CreditCardServices.getComponent()
      .then((res) => {
        
        setAllComponents(res.data.data);
      })
      .catch((err) => {
        
      });
  }, []);

  function flattenArray(array) {
    return array.map((item) => {
      const result = {};
      for (const key in item) {
        if (Array.isArray(item[key])) {
          result[key] = item[key].flat();
        }
      }
      return result;
    });
  }

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  function reorderAttributes(a, b) {
    

    const temp = b.map((item) => {
      for (let i = 0; i < a.length; i++) {
        const aObj = a[i];
        
        
        const apiID = item.schema.singularName;
        
        if (aObj[apiID]) {
          const order = aObj[apiID].map((attr) => attr.name);
          const newAttributes = {};

          
          order.forEach((key) => {
            
            
            if (item.schema.attributes[key] && key) {
              newAttributes[key] = item.schema.attributes[key];
              
            }
          });

          

          // for (const key in item.schema.attributes) {
          //     if (!newAttributes[key]) {
          //         newAttributes[key] = item.schema.attributes[key];
          //     }
          // }

          item.schema.attributes = newAttributes;
        }
      }
      return item;
    });

    
    return temp;
  }

  useEffect(() => {
    if (state) {
      setBilingAddress(null);
      setSelectedProducts({});
    }
  }, [state]);

  useEffect(() => {
    if (!bilingAddress) {
      const addOnServiceArray = initialFormAttributes
        .filter((addOnService) =>
          selectedCheckBoxData.some(
            (item) =>
              item?.product?.productMaster?.id &&
              addOnService?.productMaster?.id &&
              item?.product?.productMaster.id ===
              addOnService?.productMaster?.id
          )
        )
        .map((addOnService) => {
          
          if (addOnService?.strapiApiId === "cgs") {
            return `${state?.strapiDisplayName?.toLowerCase()}-${enityName?.entityShortName?.toLowerCase()}-cgs`;
          } else if (addOnService?.strapiApiId === "kit") {
            return `${state?.strapiDisplayName?.toLowerCase()}-${enityName?.entityShortName?.toLowerCase()}-kit`;
          } else {
            return addOnService?.strapiApiId;
          }
        })
        .filter(Boolean);

      const getFirstData = () => {
        if (stateDash && entityDash) {
          if (
            stateDash !== null &&
            entityDash !== null &&
            typeof entityDash.entityShortName === "string" &&
            typeof stateDash.strapiDisplayName === "string"
          ) {
            return (
              stateDash.strapiDisplayName.toLowerCase() +
              "-" +
              entityDash.entityShortName.toLowerCase()
            );
          }
        } else if (state !== null && enityName !== null) {
          if (
            typeof enityName.entityShortName === "string" &&
            typeof state.strapiDisplayName === "string"
          ) {
            return (
              state.strapiDisplayName.toLowerCase() +
              "-" +
              enityName.entityShortName.toLowerCase()
            );
          }
        }
        return "";
      };

      const getTargetModels = (attributes) => {
        return Object.keys(attributes).flatMap((key) => {
          if (
            attributes[key].type === "component" &&
            attributes[key].component
          ) {
            return attributes[key].component;
          }
          return [];
        });
      };

      const fetchConfigurations = async (models) => {
        const fetchPromises = models.map(async (model) => {
          return model;
        });

        const results = await Promise.all(fetchPromises);
        return results.filter((val) => val !== undefined);
      };

      const processAttributes = async (attributes, processedModels) => {
        const result = {};
        for (const key in attributes) {
          if (attributes[key].type === "component") {
            const targetModel = attributes[key].component;

            const relatedModel = allComponents.find(
              (model) => model.uid === targetModel
            );

            if (relatedModel && !processedModels.has(relatedModel.uid)) {
              processedModels.add(relatedModel.uid);
              result[key] = await processModel(relatedModel, processedModels);
            } else {
              result[key] = {};
            }
          } else {
            result[key] = attributes[key];
          }
        }
        return result;
      };

      const processModel = async (model, processedModels) => {
        const newModel = { ...model };
        newModel.schema.attributes = await processAttributes(
          newModel.schema.attributes,
          processedModels
        );
        return newModel;
      };

      const replaceNestedRelations = async (flatArr, allModelsData) => {
        const result = [];
        for (const model of allModelsData) {
          const newModel = await processModel(model, new Set());
          result.push(newModel);
        }
        return result;
      };

      const processModels = async (models, processedModels = new Set()) => {
        let allModelsData = [...models];
        const targetModels = models.flatMap((model) =>
          getTargetModels(model.schema.attributes)
        );
        
        
        const uniqueTargetModels = [...new Set(targetModels)];

        

        if (uniqueTargetModels.length === 0) return allModelsData;

        const targetModelsData = allComponents.filter(
          (val) =>
            uniqueTargetModels.includes(val.uid) &&
            !processedModels.has(val.uid)
        );

        

        allModelsData = [...allModelsData, ...targetModelsData];
        
        targetModelsData.forEach((val) => processedModels.add(val.uid));

        

        const configurations = await fetchConfigurations(targetModelsData);
        const flatArr = configurations.flatMap((config) =>
          Object.values(config)
        );
        
        
        const updatedModels = await replaceNestedRelations(
          flatArr,
          allModelsData
        );
        
        return updatedModels;
      };

      const processModels1 = async (models, processedModels = new Set()) => {
        let allModelsData = [...models];
        const targetModels = models.flatMap((model) =>
          getTargetModels(model.schema.attributes)
        );
        
        
        const uniqueTargetModels = [...new Set(targetModels)];

        

        if (uniqueTargetModels.length === 0) return allModelsData;

        const targetModelsData = allComponents.filter(
          (val) =>
            uniqueTargetModels.includes(val.uid) &&
            !processedModels.has(val.uid)
        );

        allModelsData = [...allModelsData, ...targetModelsData];
        targetModelsData.forEach((val) => processedModels.add(val.uid));

        

        const configurations = await fetchConfigurations(targetModelsData);
        const flatArr = configurations.flatMap((config) =>
          Object.values(config)
        );
        
        
        const updatedModels = await replaceNestedRelations(
          flatArr,
          allModelsData
        );
        
        return updatedModels;
      };

      const fetchAndCombineData = async () => {
        const firstData = getFirstData();

        if (firstData !== "" && allInitData.length > 0) {
          
          const initialModels = allInitData.find(
            (val) => val.apiID === firstData
          );
          const tempInitial = rawModel.filter((val) => val.apiID === firstData);
          const addOnTempInitial = rawModel.filter((val) =>
            addOnServiceArray.includes(val.apiID)
          );

          const isInitialModelValid =
            initialModels?.schema?.attributes &&
            Object.entries(initialModels.schema.attributes).every(
              ([key, value]) =>
                value &&
                typeof value === "object" &&
                Object.keys(value).length > 0
            );

          const attributesToUse = isInitialModelValid
            ? [initialModels]
            : tempInitial;

          const initialAttributes = tempInitial.reduce((acc, model) => {
            return { ...acc, ...model.schema.attributes };
          }, {});

          const dynamicAttributes = addOnTempInitial.reduce((acc, model) => {
            return { ...acc, ...model.schema.attributes };
          }, {});

          

          const combinedGroupInitialData = {
            ...initialAttributes,
            ...dynamicAttributes,
          };

          setGroupInitialData(combinedGroupInitialData);

          
          // }
          if (initialModels !== undefined) {
            try {
              const firstDataFinal = await processModels(attributesToUse);
              

              const addOnModels = allInitData.filter((val) =>
                addOnServiceArray.includes(val.apiID)
              );

              const addOnFinal = await processModels1(addOnModels);

              const combinedFinalData = [...firstDataFinal, ...addOnFinal];

              const mainModelSchema = {
                schema: {
                  attributes: {
                    Entity_Formation: {
                      ...firstDataFinal[0]?.schema?.attributes,
                    },
                    ...addOnFinal.reduce((acc, model) => {
                      const serviceKey = model.apiID;
                      acc[serviceKey] = model.schema.attributes;
                      return acc;
                    }, {}),
                  },
                },
              };

              

              setMainModel(mainModelSchema);

              setInputFormData(combinedFinalData);
              setLoading1(false);

              
              
              console.log(
                "Combined Final Data:",
                JSON.stringify(combinedFinalData, null, 2)
              );
            } catch (err) {
              console.error("Error processing models:", err);
            }
          }
        }
      };

      fetchAndCombineData();
    }
  }, [
    enityName,
    state,
    stateDash,
    entityDash,
    allInitData,
    selectedCheckBoxData,
    bilingAddress,
  ]);

  useEffect(() => {
    const firstData =
      typeof enityName === "string" && typeof state === "string"
        ? state.toLowerCase() + "-" + enityName.toLowerCase()
        : "";
    if (firstData !== "") {
      
      
      setModelName(firstData);
    }
  }, [enityName, state]);

  const fetchOptions = async (url) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  useEffect(() => {
    showTab(currentTab);
  }, [currentTab]);

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error-msg font-fam-for-all ">
        {errors[name].message}
      </small>
    ) : (
      <small className="error-msg "></small>
    );
  };

  function convertName(originalString) {
    if (typeof originalString === "string") {
      if (!originalString.includes("_")) {
        return originalString;
      }

      const parts = originalString.split("_");

      const relevantParts = parts.slice(1);

      const formattedString = relevantParts
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join(" ");

      return formattedString;
    }
  }

  const handleFile = (e, data) => {
    
    const errorMessageTag = document.getElementById(`${data}--error`);
    if (errorMessageTag) {
      errorMessageTag.textContent = "";
    }
    const formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    setIsTabRefresh(true);
    MasterServices.saveFile("orderdoc", formdata)
      .then((res) => {
        
        setValue(data, res.data);
        if (res.status === 201 || res.status === 200) {
          if (formData) {
            const temp = formData;
            const orderLineItem = updatedOrderLineItems;
            const orderDoc = {
              documentPath: res.data,
              documentName: e.target.files[0]
                ? e.target.files[0]?.name
                : "file",
            };
            
            MasterServices.saveDocument(orderDoc)
              .then((res) => {
                
                if (Array.isArray(temp["documentsId"])) {
                  temp["documentsId"] = [...temp["documentsId"], res.data];
                } else {
                  temp["documentsId"] = [res.data];
                }
                temp["orderLineItems"] = orderLineItem;
                temp[
                  "orderUpdateNotes"
                ] = `${e.target.files[0]?.name} file uploaded`;
                temp["orderUpdatedBy"] = loginUserName;

                
                OrderServices.saveAllOrder(temp)
                  .then((res) => {
                    
                    setIsLoading(false);
                    setIsTabRefresh(false);
                 
                    showToast("success", "File uploaded successfully");
                  })
                  .catch((err) => {
                    
                    setIsLoading(false);
                    setIsTabRefresh(false);
                    showToast("error", "Something went wrong");
                  });
              })
              .catch((err) => {
                
                setIsLoading(false);
                setIsTabRefresh(false);

                showToast("error", "Something went wrong");
              });
          } else {
            setIsTabRefresh(false);
          }
        }
      })
      .catch((err) => {
        
        setIsTabRefresh(false);
     
        showToast("error", "File failed to upload");
      });
  };

  const renderEntityValidationMessage = (val, entityName, state) => {
    if (
      ["CD_LLC_Name", "CD_Corporation_Name"].includes(val) &&
      entityName &&
      state
    ) {
      const entityRegex =
        validationConfig[entityName.entityShortName]?.[state.stateFullDesc];

      if (entityRegex) {
        
        // const patternText = entityRegex.source
        //   .split("|")
        //   .join(", ")
        //   .replace(/[()\\$]/g, "");

        const patternTextold = entityRegex.source.split("|").join(", ").replace(/[()\\$?:+<=^!*]/g, "");
      
        const patternText = patternTextold.replace(/^[.sb]+/, "");



        return (
          <ul>
            <li
              className="font-fam-for-all pt-2"
              style={{ fontSize: "12px", color: "#667085" }}
            >
              {`Enter a valid ${convertName(val)} ending with ${patternText}`}
            </li>
          </ul>
        );
      }
    }

    if (
      (val === "CD_Alternate_LLC_Name" && getValues("CD_Alternate_LLC_Name")) ||
      (val === "CD_Alternate_Corporation_Name" &&
        getValues("CD_Alternate_Corporation_Name"))
    ) {
      if (entityName && state) {
        const entityRegex =
          validationConfig[entityName.entityShortName]?.[state.stateFullDesc];

        if (entityRegex) {
          // const patternText = entityRegex.source
          //   .split("|")
          //   .join(", ")
          //   .replace(/[()\\$]/g, "");

          const patternTextold = entityRegex.source.split("|").join(", ").replace(/[()\\$?:+<=^!*]/g, "");
      
          const patternText = patternTextold.replace(/^[.sb]+/, "");



          return (
            <ul>
              <li
                className="font-fam-for-all pt-2"
                style={{ fontSize: "12px", color: "#667085" }}
              >
                {`Enter a valid ${convertName(val)} ending with ${patternText}`}
              </li>
            </ul>
          );
        }
      }
    }

    return null;
  };

  // TODO: 03-3-2025
  // const renderEntityValidationMessage = (val, entityName, state) => {
  //   if (
  //     ["CD_LLC_Name", "CD_Corporation_Name"].includes(val) &&
  //     entityName &&
  //     state
  //   ) {
  //     const entityRegex =
  //       validationConfig[entityName.entityShortName]?.[state.stateFullDesc];
  
  //     // 🚀 Check if state is "New Jersey" and entity is LLC or Corporation
  //     if (state.stateFullDesc === "New Jersey" && ["LLC", "Corporation"].includes(entityName.entityShortName)) {
  //       return (
  //         <ul>
  //           <li
  //             className="font-fam-for-all pt-2"
  //             style={{ fontSize: "12px", color: "#667085" }}
  //           >
  //             {`DO NOT enter a designator such as Corp., Ltd., LLC, Etc. You will add the designator later in the filing process.`}
  //           </li>
  //         </ul>
  //       );
  //     }
  
  //     if (entityRegex) {
  //       const patternTextold = entityRegex.source
  //         .split("|")
  //         .join(", ")
  //         .replace(/[()\\$?:+<=^!*]/g, "");
  
  //       const patternText = patternTextold.replace(/^[.sb]+/, "");
  
  //       return (
  //         <ul>
  //           <li
  //             className="font-fam-for-all pt-2"
  //             style={{ fontSize: "12px", color: "#667085" }}
  //           >
  //             {`Enter a valid ${convertName(val)} ending with ${patternText}`}
  //           </li>
  //         </ul>
  //       );
  //     }
  //   }
  
  //   if (
  //     (val === "CD_Alternate_LLC_Name" && getValues("CD_Alternate_LLC_Name")) ||
  //     (val === "CD_Alternate_Corporation_Name" &&
  //       getValues("CD_Alternate_Corporation_Name"))
  //   ) {
  //     if (entityName && state) {
  //       const entityRegex =
  //         validationConfig[entityName.entityShortName]?.[state.stateFullDesc];
  
  //       // 🚀 Check if state is "New Jersey" and entity is LLC or Corporation
  //       if (state.stateFullDesc === "New Jersey" && ["LLC", "Corporation"].includes(entityName.entityShortName)) {
  //         return (
  //           <ul>
  //             <li
  //               className="font-fam-for-all pt-2"
  //               style={{ fontSize: "12px", color: "#667085" }}
  //             >
  //               {`DO NOT enter a designator such as Corp., Ltd., LLC, Etc. You will add the designator later in the filing process.`}
  //             </li>
  //           </ul>
  //         );
  //       }
  
  //       if (entityRegex) {
  //         const patternTextold = entityRegex.source
  //           .split("|")
  //           .join(", ")
  //           .replace(/[()\\$?:+<=^!*]/g, "");
  
  //         const patternText = patternTextold.replace(/^[.sb]+/, "");
  
  //         return (
  //           <ul>
  //             <li
  //               className="font-fam-for-all pt-2"
  //               style={{ fontSize: "12px", color: "#667085" }}
  //             >
  //               {`Enter a valid ${convertName(val)} ending with ${patternText}`}
  //             </li>
  //           </ul>
  //         );
  //       }
  //     }
  //   }
  
  //   return null;
  // };

  
  const renderFormField = (
    column,
    key,
    val,
    value,
    modelname,
    componentName = null
  ) => {
    const errorMessageTag = document.getElementById(`${val}--error`);

    const tempval = convertName(key);

    const options = optionsMap?.[tempval] || [];

    const COA_What_Are_We_Amending = getValues("COA_What_Are_We_Amending");

    const What_Are_We_Changing = getValues("COC_What_Are_We_Changing");

    const cc_What_Type_Document = getValues("cc_What_Type_Document");

    const pricipal_activity = getValues("EIN_Principal_activity_of_business");

    const ucc_UCC_Collateral_Type = getValues("ucc_UCC_Collateral_Type");

    const purpose = getValues("CD_Business_Purpose_Details");

    const finCenKey = `boi_Have_a_FinCEN_ID-${val.split("-")[1] || 0}`;
    const finCenKeyCA = `boica_Have_a_FinCEN_ID-${val.split("-")[1] || 0}`;
    const finCenId = getValues(finCenKey);
    const finCenIdCA = getValues(finCenKeyCA);

    const excludedValues = [
      "COA_New_Name_To_Be_Used",
      "COA_What_Are_We_Changing",
      "Please_Tell_Us_What_You_Are_Changing",
      "COA_Please_Tell_Us_What_You_Want_To_Amend",
      "COC_Please_Include_New_County",
      "COC_Please_Include_New_Address",
      "cc_Specify_Other_Document",
      "ucc_Type_Your_Collateral_Statement",
      "ucc_Collateral_Statement",
      "CD_Please_specify",
      "EIN_Other",
      "NC_NAICS_Code",
      "NC_NAICS_Sub_Code",
      `boi_FinCEN_ID_Number-${val.split("-")[1] || 0}`,
      `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`,
      `boica_FinCEN_ID_Number-${val.split("-")[1] || 0}`,
      `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`,
      `boi_Date_of_Birth-${val.split("-")[1] || 0}`,
      `boica_Date_of_Birth-${val.split("-")[1] || 0}`,
    ];

    if (!excludedValues.includes(val)) {
      switch (column.type) {
        case "string":
          switch (column.default) {
            case "drop-down":
              if (options.length > 0) {
                const matchedItem = tempdata.find(
                  (item) => item.item === tempval
                );

                
                return (
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}
                          {column.required !== undefined &&
                            column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className="p-float-label ">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                                column.required === true &&
                                column.regex !== undefined
                                ? {
                                  required: `${convertName(key)} Required.`,
                                  pattern: {
                                    value: new RegExp(column.regex),
                                    message: `Please Enter Valid ${convertName(
                                      key
                                    )}`,
                                  },
                                }
                                : column.required !== undefined &&
                                  column.required === true
                                  ? { required: `${convertName(key)} Required.` }
                                  : {}
                            }
                            render={({ field }) => {
                              const defaultValue =
                                matchedItem !== undefined
                                  ? matchedItem.label === "countyName" &&
                                    allCounty.length > 0
                                    ? allCounty
                                    : options
                                  : options;

                              return (
                                <Dropdown
                                  id={field.name}
                                  filter
                                  name={val}
                                  value={field.value}
                                  options={defaultValue}
                                  optionValue={
                                    matchedItem !== undefined
                                      ? matchedItem.optionValue
                                      : "name"
                                  }
                                  disabled={
                                    modelname === "Principal_Address" ||
                                    modelname === "President_Information" ||
                                    modelname === "Treasurer_Information" ||
                                    modelname === "Secretary_Information" ||
                                    modelname === "Organizer_Information" ||
                                    modelname === "Incorporator_Information" ||
                                    (IsCheckedAgent &&
                                      modelname === "Registered_Agent") ||
                                    modelname === "Director_Information" ||
                                    modelname === "Officer_Information" ||
                                    modelname === "Filer_Information" ||
                                    componentName === "Mailing_Information" ||
                                    componentName === "Billing_Information" ||
                                    modelname === "ucc_Debtor_Details" ||
                                    modelname === "ucc_Secured_Party_Details" ||
                                    modelname ===
                                    "Beneficial_Owner_Information" ||
                                    modelname ===
                                    "Company_Applicant_Information" ||
                                    modelname === "Contact_Information" ||
                                    val === "RA_State"
                                  }
                                  optionLabel={
                                    matchedItem !== undefined
                                      ? matchedItem.label
                                      : "name"
                                  }
                                  onChange={(e) => {
                                    field.onChange(e);
                                    clearErrors(key);
                                    errorMessageTag &&
                                      (errorMessageTag.textContent = "");
                                  }}
                                  maxSelectedLabels={3}
                                  style={{ flexGrow: "1" }}
                                  className="dialog-form-input-field-wizard"
                                />
                              );
                            }}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                );
              }
              case "signer":
                return (
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label htmlFor="" className="form-label font-fam-for-all">
                          {convertName(key)}
                          {column.required !== undefined &&
                          column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className=" ">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                              column.required === true &&
                              column.regex !== undefined
                                ? {
                                    required: `${convertName(key)} Required.`,
                                    pattern: {
                                      value: new RegExp(column.regex),
                                      message: `Please Enter Valid ${convertName(
                                        key
                                      )}`,
                                    },
                                  }
                                : column.required !== undefined &&
                                  column.required === true
                                ? { required: `${convertName(key)} Required.` }
                                : {}
                            }
                            render={({ field }) => {
                              return (
                                <div className="flex justify-between items-center gap-4 text-start">
                                  <div className="w-full">
                                    <Dropdown
                                      id={field.name}
                                      filter
                                      name={val}
                                      value={field.value}
                                      resetFilterOnHide
                                      options={allOrganizer}
                                      optionValue="keyPersonnelName"
                                      optionLabel="keyPersonnelName"
                                      disabled={
                                        val === "Reg_Name" ||
                                        val === "Reg_Email_Address" ||
                                        val === "Reg_Contact_No" ||
                                        val === "Reg_Address_Line_1" ||
                                        val === "Reg_Address_Line_2" ||
                                        val === "Reg_City" ||
                                        val === "Reg_State" ||
                                        val === "Reg_Zip_Code" ||
                                        modelname === "Principal_Address" ||
                                        modelname === "Organizer_Information" ||
                                        modelname ===
                                          "Incorporator_Information" ||
                                        (IsCheckedAgent &&
                                          modelname === "Registered_Agent") ||
                                        modelname === "Director_Information" ||
                                        modelname === "Officer_Information" ||
                                        modelname === "Filer_Information" ||
                                        modelname === "Mailing_Information" ||
                                        modelname === "Billing_Information" ||
                                        modelname === "UCC_Debtor_Details" ||
                                        modelname ===
                                          "ucc_Secured_Party_Details" ||
                                        modelname ===
                                          "Beneficial_Owner_Information" ||
                                        modelname ===
                                          "Company_Applicant_Information" ||
                                        componentName ===
                                          "ucc-assignee-details" ||
                                        componentName ===
                                          "ucc-amend-additional-secured-party-details" ||
                                        componentName ===
                                          "ucc-amnd-additional-debtor" ||
                                        val === "LLP_State_Of_LLC" ||
                                        val === "LLP_County_Of_LLC"
                                      }
                                      onChange={(e) => {
                                        field.onChange(e);
                                        clearErrors(key);
                                        errorMessageTag &&
                                          (errorMessageTag.textContent = "");
                                      }}
                                      maxSelectedLabels={3}
                                      style={{ flexGrow: "1" }}
                                      className="dialog-form-input-field-wizard"
                                    />
                                  </div>
  
                                  <div className="w-full">
                                    <Button
                                      type="button"
                                      icon="pi pi-plus"
                                      style={{ fontSize: "14px" }}
                                      label="Add Signer"
                                      className=" vstate-button font-fam-for-all text-center"
                                      onClick={() => handleKeyPersonal("SIGNER")}
                                    ></Button>
                                  </div>
                                </div>
                              );
                            }}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                );

                case "responsible-party":
                  return (
                    <div className="input-fields-container">
                      <div className="input-fields-main">
                        <div className="justify-content-center dialog-form-field-space w-full">
                          <label htmlFor="" className="form-label font-fam-for-all">
                            {convertName(key)}
                            {column.required !== undefined &&
                            column.required === true ? (
                              <span className="form-field-mandatory">*</span>
                            ) : (
                              ""
                            )}
                          </label>
                          <span className=" ">
                            <Controller
                              name={val}
                              control={control}
                              rules={
                                column.required !== undefined &&
                                column.required === true &&
                                column.regex !== undefined
                                  ? {
                                      required: `${convertName(key)} Required.`,
                                      pattern: {
                                        value: new RegExp(column.regex),
                                        message: `Please Enter Valid ${convertName(
                                          key
                                        )}`,
                                      },
                                    }
                                  : column.required !== undefined &&
                                    column.required === true
                                  ? { required: `${convertName(key)} Required.` }
                                  : {}
                              }
                              render={({ field }) => {
                                return (
                                  <div className="flex justify-between items-center gap-4 text-start">
                                    <div className="w-full">
                                      <Dropdown
                                        id={field.name}
                                        filter
                                        name={val}
                                        value={field.value}
                                        resetFilterOnHide
                                        options={allOrganizer}
                                        optionValue="keyPersonnelName"
                                        optionLabel="keyPersonnelName"
                                        disabled={
                                          val === "Reg_Name" ||
                                          val === "Reg_Email_Address" ||
                                          val === "Reg_Contact_No" ||
                                          val === "Reg_Address_Line_1" ||
                                          val === "Reg_Address_Line_2" ||
                                          val === "Reg_City" ||
                                          val === "Reg_State" ||
                                          val === "Reg_Zip_Code" ||
                                          modelname === "Principal_Address" ||
                                          modelname === "Organizer_Information" ||
                                          modelname ===
                                            "Incorporator_Information" ||
                                          (IsCheckedAgent &&
                                            modelname === "Registered_Agent") ||
                                          modelname === "Director_Information" ||
                                          modelname === "Officer_Information" ||
                                          modelname === "Filer_Information" ||
                                          modelname === "Mailing_Information" ||
                                          modelname === "Billing_Information" ||
                                          modelname === "UCC_Debtor_Details" ||
                                          modelname ===
                                            "ucc_Secured_Party_Details" ||
                                          modelname ===
                                            "Beneficial_Owner_Information" ||
                                          modelname ===
                                            "Company_Applicant_Information" ||
                                          componentName ===
                                            "ucc-assignee-details" ||
                                          componentName ===
                                            "ucc-amend-additional-secured-party-details" ||
                                          componentName ===
                                            "ucc-amnd-additional-debtor" ||
                                          val === "LLP_State_Of_LLC" ||
                                          val === "LLP_County_Of_LLC"
                                        }
                                        onChange={(e) => {
                                          field.onChange(e);
                                          clearErrors(key);
                                          errorMessageTag &&
                                            (errorMessageTag.textContent = "");
                                        }}
                                        maxSelectedLabels={3}
                                        style={{ flexGrow: "1" }}
                                        className="dialog-form-input-field-wizard"
                                      />
                                    </div>
    
                                    <div className="w-full">
                                      <Button
                                        type="button"
                                        icon="pi pi-plus"
                                        style={{ fontSize: "14px" }}
                                        label="Add Responsible Party"
                                        className=" vstate-button font-fam-for-all text-center"
                                        onClick={() => handleKeyPersonal("RESPONSIBLE PARTY")}
                                      ></Button>
                                    </div>
                                  </div>
                                );
                              }}
                            />
                          </span>
                          <small
                            id={`${val}--error`}
                            className="error-msg font-fam-for-all"
                          ></small>
                          {getFormErrorMessage(key)}
                        </div>
                      </div>
                    </div>
                  );

                  

                case "contact":
              return (
                <>
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}
                          {column.required !== undefined &&
                            column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className="p-float-label">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                                column.required === true
                                ? {
                                  required: `${convertName(
                                    key
                                  )} is required.`,
                                }
                                : {}
                            }
                            render={({ field, fieldState }) => (
                              <div
                                className="p-inputgroup flex-1"
                                style={{ height: "40px", width: "100%" }}
                              >
                                <Dropdown
                                  value={selectedCountry}
                                  options={countries}
                                  onChange={(e) => setSelectedCountry(e.value)}
                                  optionLabel="dialCode"
                                  filter
                                  optionValue="dialCode"
                                  valueTemplate={selectedCountryTemplate}
                                  itemTemplate={countryOptionTemplate}
                                  style={{
                                    width: "160px",
                                    borderRadius: "6px 0px 0px 6px",
                                  }}
                                  className="p-dropdown font-fam-for-all"
                                />

                                <input
                                  id={field.name}
                                  {...field}
                                  value={field.value}
                                  type="text"
                                  disabled={
                                    modelname === "Principal_Address" ||
                                    modelname === "President_Information" ||
                                    modelname === "Treasurer_Information" ||
                                    modelname === "Secretary_Information" ||
                                    modelname === "Organizer_Information" ||
                                    modelname === "Incorporator_Information" ||
                                    (IsCheckedAgent &&
                                      modelname === "Registered_Agent") ||
                                    modelname === "Director_Information" ||
                                    modelname === "Officer_Information" ||
                                    modelname === "Filer_Information" ||
                                    componentName === "Mailing_Information" ||
                                    componentName === "Billing_Information" ||
                                    modelname === "ucc_Debtor_Details" ||
                                    modelname === "ucc_Secured_Party_Details" ||
                                    modelname ===
                                    "Beneficial_Owner_Information" ||
                                    modelname ===
                                    "Company_Applicant_Information" ||
                                    modelname === "Contact_Information"
                                  }
                                  onChange={(e) => {
                                    let inputValue = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    );
                                    if (inputValue.length > 10) {
                                      inputValue = inputValue.slice(0, 10);
                                    }
                                    const formattedValue = inputValue.replace(
                                      /(\d{3})(\d{3})(\d{4})/,
                                      (match, p1, p2, p3) =>
                                        `(${p1}) ${p2}-${p3}`
                                    );
                                    if (inputValue.length === 10) {
                                      e.target.value = formattedValue;
                                      field.onChange(formattedValue);
                                    } else {
                                      e.target.value = inputValue;
                                      field.onChange(inputValue);
                                    }
                                    clearErrors(key);
                                    errorMessageTag &&
                                      (errorMessageTag.textContent = "");
                                  }}
                                  placeholder="(999) 999-9999"
                                  style={{
                                    height: "40px",
                                    borderRadius: "0px 6px 6px 0px",
                                    width: "100%",
                                  }}
                                  className="dialog-form-input-field-wizard p-inputtext"
                                />
                              </div>
                            )}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                </>
              );


            case "SSN":
              return (
                <>
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}
                          {column.required ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className="p-float-label">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required
                                ? {
                                  required: `${convertName(
                                    key
                                  )} is required.`,
                                }
                                : {}
                            }
                            render={({ field }) => (
                              <div className="p-inputgroup flex-1" style={{ height: "40px", width: "100%", position: "relative" }}>
                                <input
                                  id={field.name}
                                  {...field}
                                  value={field.value}
                                  type={isVisible ? "text" : "password"}
                                  disabled={
                                    val === "Reg_Name" ||
                                    val === "Reg_Email_Address" ||
                                    val === "Reg_Contact_No" ||
                                    val === "Reg_Address_Line_1" ||
                                    val === "Reg_Address_Line_2" ||
                                    val === "Reg_City" ||
                                    val === "Reg_State" ||
                                    val === "Reg_Zip_Code" ||
                                    modelname === "Principal_Address" ||
                                    modelname === "Organizer_Information" ||
                                    modelname === "Incorporator_Information" ||
                                    (IsCheckedAgent && modelname === "Registered_Agent") ||
                                    modelname === "Director_Information" ||
                                    modelname === "Officer_Information" ||
                                    modelname === "Filer_Information" ||
                                    modelname === "Mailing_Information" ||
                                    modelname === "Billing_Information" ||
                                    modelname === "ucc_Debtor_Details" ||
                                    modelname === "ucc_Secured_Party_Details" ||
                                    modelname === "Beneficial_Owner_Information" ||
                                    modelname === "Company_Applicant_Information" ||
                                    componentName === "ucc-assignee-details" ||
                                    componentName === "ucc-amend-additional-secured-party-details" ||
                                    componentName === "ucc-amnd-additional-debtor"
                                  }
                                  onChange={(e) => {
                                    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                                    if (inputValue.length > 9) {
                                      inputValue = inputValue.slice(0, 9); // Limit to 9 digits
                                    }
                                    const formattedValue = inputValue.replace(/^(\d{3})(\d{2})(\d{4})$/, "$1-$2-$3");
                                    field.onChange(formattedValue);
                                    clearErrors(key);
                                    errorMessageTag && (errorMessageTag.textContent = "");

                                    // Set the type to text immediately so user sees their input
                                    e.target.type = "text";
                                    // Then schedule masking after 2 seconds.
                                    attachDelayedMask(field.name, 2000);
                                  }}
                                  onBlur={() => setIsVisible(false)}

                                  placeholder="XXX-XX-XXXX"
                                  style={{
                                    height: "40px",
                                    borderRadius: "6px",
                                    width: "100%",
                                    paddingRight: "35px",
                                  }}
                                  className="dialog-form-input-field-wizard p-inputtext"
                                  maxLength={11}
                                />
                                <button
                                  type="button"
                                  onClick={toggleVisibility}
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    background: "transparent",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                >
                                  {isVisible ? <FaEyeSlash size={18} /> : <FaEye size={18} />}
                                </button>
                              </div>
                            )}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                </>
              );

        
              case "media":
              return (
                <>
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}
                          {column.required !== undefined &&
                            column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className="p-float-label">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                                column.required === true &&
                                column.regex !== undefined
                                ? {
                                  required: `${convertName(key)} Required.`,
                                  pattern: {
                                    value: new RegExp(column.regex),
                                    message: `Please Enter Valid ${convertName(
                                      key
                                    )}`,
                                  },
                                }
                                : column.required !== undefined &&
                                  column.required === true
                                  ? { required: `${convertName(key)} Required.` }
                                  : {}
                            }
                            render={({ field }) => (
                              // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                              <input
                                name={val}
                                type="file"
                                disabled={
                                  modelname === "Principal_Address" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  (IsCheckedAgent &&
                                    modelname === "Registered_Agent") ||
                                  modelname === "Director_Information" ||
                                  modelname === "Officer_Information" ||
                                  modelname === "Filer_Information" ||
                                  modelname === "Mailing_Information" ||
                                  modelname === "Billing_Information" ||
                                  modelname === "ucc_Debtor_Details" ||
                                  modelname === "ucc_Secured_Party_Details" ||
                                  modelname ===
                                  "Beneficial_Owner_Information" ||
                                  modelname === "Company_Applicant_Information"
                                }
                                onChange={(e) => handleFile(e, val)}
                              />
                            )}
                          />
                        </span>
                        {getValues(val) !== undefined &&
                          typeof getValues(val) === "string" && (
                            <p className="font-web-color font-fam-for-all">
                              {getValues(val).split("/").pop()}
                            </p>
                          )}
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                </>
              );



              
            case "currency":
              return (
                <>
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}
                          {column.required !== undefined &&
                            column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className="p-float-label">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                                column.required === true
                                ? {
                                  required: `${convertName(key)} Required.`,
                                }
                                : {}
                            }
                            // render={({ field, fieldState }) => (
                            //   <input
                            //     type="text"
                            //     id={field.name}
                            //     {...field}
                            //     disabled={
                            //       modelname === "Principal_Address" ||
                            //       modelname === "Organizer_Information" ||
                            //       modelname === "Incorporator_Information" ||
                            //       (IsCheckedAgent &&
                            //         modelname === "Registered_Agent") ||
                            //       modelname === "Director_Information" ||
                            //       modelname === "Officer_Information" ||
                            //       modelname === "Filer_Information" ||
                            //       componentName === "Mailing_Information" ||
                            //       componentName === "Billing_Information" ||
                            //       modelname === "ucc_Debtor_Details" ||
                            //       modelname === "ucc_Secured_Party_Details" ||
                            //       modelname ===
                            //       "Beneficial_Owner_Information" ||
                            //       modelname === "Company_Applicant_Information"
                            //     }
                            //     onInput={(e) => {
                            //       let value = e.target.value.replace(/,/g, "");
                            //       if (value.length > 5) {
                            //         value = value.slice(0, 5);
                            //       }
                            //       const formattedValue = new Intl.NumberFormat(
                            //         "en-US"
                            //       ).format(value);
                            //       
                            //       e.target.value = formattedValue;
                            //       if (formattedValue !== NaN) {
                            //         field.onChange(value.toString());
                            //       }
                            //       clearErrors(key);
                            //       errorMessageTag &&
                            //         (errorMessageTag.textContent = "");
                            //     }}
                            //     style={{
                            //       flexGrow: "1",
                            //       paddingLeft: "10px",
                            //       paddingBottom: "5px",
                            //       borderRadius: "6px",
                            //     }}
                            //     className="dialog-form-input-field-wizard p-inputtext"
                            //   />
                            // )}

                            render={({ field, fieldState }) => (
                              <input
                                type="text"
                                id={field.name}
                                {...field}
                                disabled={
                                  modelname === "Principal_Address" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  (IsCheckedAgent &&
                                    modelname === "Registered_Agent") ||
                                  modelname === "Director_Information" ||
                                  modelname === "Officer_Information" ||
                                  modelname === "Filer_Information" ||
                                  componentName === "Mailing_Information" ||
                                  componentName === "Billing_Information" ||
                                  modelname === "ucc_Debtor_Details" ||
                                  modelname === "ucc_Secured_Party_Details" ||
                                  modelname ===
                                  "Beneficial_Owner_Information" ||
                                  modelname === "Company_Applicant_Information"
                                }
                                onInput={(e) => {
                                  let value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Allow only numbers
                                  if (value.length > 5) {
                                    value = value.slice(0, 5); // Limit to 5 digits
                                  }
                                  const formattedValue = value
                                    ? new Intl.NumberFormat("en-US").format(
                                      value
                                    )
                                    : "";

                                  e.target.value = formattedValue;
                                  field.onChange(value.toString()); // Store raw number value

                                  clearErrors(key);
                                  if (errorMessageTag)
                                    errorMessageTag.textContent = "";
                                }}
                                style={{
                                  flexGrow: "1",
                                  paddingLeft: "10px",
                                  paddingBottom: "5px",
                                  borderRadius: "6px",
                                }}
                                className="dialog-form-input-field-wizard p-inputtext"
                              />
                            )}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                </>
              );

            // case "date":
            //   return (
            //     <div className="input-fields-container">
            //       <div className="input-fields-main">
            //         <div className="justify-content-center dialog-form-field-space w-full">
            //           <label htmlFor="" className="form-label font-fam-for-all">
            //             {convertName(key)}
            //             {column.required ? (
            //               <span className="form-field-mandatory">*</span>
            //             ) : (
            //               ""
            //             )}
            //           </label>
            //           <span className="p-float-label">
            //             <Controller
            //               name={val}
            //               control={control}
            //               // defaultValue={dayjs().format("YYYY-MM-DD")}
            //               rules={
            //                 column.required
            //                   ? { required: `${convertName(key)} Required.` }
            //                   : {}
            //               }

            //               render={({ field }) => (
            //                 <LocalizationProvider dateAdapter={AdapterDayjs}>
            //                   <DatePicker
            //                     className="strapi-date-picker"
            //                     style={{ width: "322px" }}
            //                     value={field.value ? dayjs(field.value) : null}
            //                     // defaultValue={dayjs().format("YYYY-MM-DD")}
            //                     disabled={
            //                       modelname === "Principal_Address" ||
            //                       modelname === "Organizer_Information" ||
            //                       modelname === "Incorporator_Information" ||
            //                       (IsCheckedAgent &&
            //                         modelname === "Registered_Agent") ||
            //                       modelname === "Director_Information" ||
            //                       modelname === "Officer_Information" ||
            //                       modelname === "Filer_Information" ||
            //                       modelname === "Mailing_Information" ||
            //                       modelname === "Billing_Information" ||
            //                       modelname === "ucc_Debtor_Details" ||
            //                       modelname === "ucc_Secured_Party_Details" ||
            //                       modelname ===
            //                       "Beneficial_Owner_Information" ||
            //                       modelname === "Company_Applicant_Information"
            //                     }
            //                     onChange={(newValue) => {
            //                       if (newValue && newValue.isValid()) {
            //                         const formattedDate =
            //                           newValue.format("YYYY-MM-DD");
            //                         field.onChange(formattedDate);
            //                       } else {
            //                         field.onChange(null);
            //                       }
            //                       errorMessageTag &&
            //                         (errorMessageTag.textContent = "");
            //                     }}
            //                     renderInput={(params) => (
            //                       <TextField {...params} />
            //                     )}
            //                     disableFuture
            //                   />
            //                 </LocalizationProvider>
            //               )}
            //             />
            //           </span>
            //           <small
            //             id={`${val}--error`}
            //             className="error-msg font-fam-for-all"
            //           ></small>
            //           {getFormErrorMessage(key)}
            //         </div>
            //       </div>
            //     </div>
            //   );



            case "date":
              const today = dayjs();
             const isPrefilledDisabled =
             key === "CF_Year_of_formation" || key === "EIN_Date";
             return (
               <div className="input-fields-container">
                 <div className="input-fields-main">
                   <div className="justify-content-center dialog-form-field-space w-full">
                     <label htmlFor="" className="form-label font-fam-for-all">
                       {convertName(key)}
                       {column.required ? (
                         <span className="form-field-mandatory">*</span>
                       ) : (
                         ""
                       )}
                     </label>
                     <span className="p-float-label">
                       <Controller
                         name={val}
                         control={control}
defaultValue={isPrefilledDisabled ? today.format("YYYY-MM-DD") : ""}

                         rules={
                           column.required
                             ? { required: `${convertName(key)} Required.` }
                             : {}
                         }
                         render={({ field }) => {
                           // Check if the field should be prefilled and disabled
                       

                           return (
                             <LocalizationProvider dateAdapter={AdapterDayjs}>
                               <DatePicker
                                 className="strapi-date-picker"
                                 style={{ width: "322px" }}
                                 value={
                                   isPrefilledDisabled
                                     ? dayjs() // Always set today's date
                                     : field.value
                                       ? dayjs(field.value)
                                       : null
                                 }
                                 disabled={
                                   isPrefilledDisabled ||
                                   modelname === "Principal_Address" ||
                                   modelname === "Organizer_Information" ||
                                   modelname === "Incorporator_Information" ||
                                   (IsCheckedAgent && modelname === "Registered_Agent") ||
                                   modelname === "Director_Information" ||
                                   modelname === "Officer_Information" ||
                                   modelname === "Filer_Information" ||
                                   modelname === "Mailing_Information" ||
                                   modelname === "Billing_Information" ||
                                   modelname === "ucc_Debtor_Details" ||
                                   modelname === "ucc_Secured_Party_Details" ||
                                   modelname === "Beneficial_Owner_Information" ||
                                   modelname === "Company_Applicant_Information"
                                 }
                                 onChange={(newValue) => {
                                   if (!isPrefilledDisabled) {
                                     field.onChange(
                                       newValue && newValue.isValid()
                                         ? newValue.format("YYYY-MM-DD")
                                         : null
                                     );
                                   }
                                   errorMessageTag && (errorMessageTag.textContent = "");
                                 }}
                                 renderInput={(params) => <TextField {...params} />}
                                 disableFuture
                               />
                             </LocalizationProvider>
                           );
                         }}
                       />
                     </span>
                     <small
                       id={`${val}--error`}
                       className="error-msg font-fam-for-all"
                     ></small>
                     {getFormErrorMessage(key)}
                   </div>
                 </div>
               </div>
             );

            default:
              return (
                <>
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className=" justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}{" "}
                          {column.required !== undefined &&
                            column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className="  ">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                                column.required === true &&
                                column.regex !== undefined
                                ? {
                                  required: `${convertName(key)} Required.`,
                                  pattern: {
                                    value: new RegExp(column.regex),
                                    message: `Please Enter Valid ${convertName(
                                      key
                                    )}`,
                                  },
                                }
                                : column.required !== undefined &&
                                  column.required === true
                                  ? { required: `${convertName(key)} Required.` }
                                  : {}
                            }
                            render={({ field, fieldState }) => (
                              <InputText
                                id={field.name}
                                {...field}
                                autoComplete={false}
                                onChange={(e) => {
                                  field.onChange(e);
                                  clearErrors(key);
                                  errorMessageTag &&
                                    (errorMessageTag.textContent = "");
                                }}
                                disabled={
                                  modelname === "Principal_Address" ||
                                  modelname === "President_Information" ||
                                  modelname === "Treasurer_Information" ||
                                  modelname === "Secretary_Information" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  (IsCheckedAgent &&
                                    modelname === "Registered_Agent") ||
                                  modelname === "Director_Information" ||
                                  modelname === "Officer_Information" ||
                                  modelname === "Filer_Information" ||
                                  componentName === "Mailing_Information" ||
                                  componentName === "Billing_Information" ||
                                  modelname === "ucc_Debtor_Details" ||
                                  modelname === "ucc_Secured_Party_Details" ||
                                  modelname ===
                                  "Beneficial_Owner_Information" ||
                                  modelname ===
                                  "Company_Applicant_Information" ||
                                  val === "X_Name_of_Entity" ||
                                  modelname === "Contact_Information"
                                }
                                style={{
                                  flexGrow: "1",
                                  paddingLeft: "10px",
                                  paddingBottom: "5px",
                                }}
                                className="dialog-form-input-field-wizard"
                                maxLength={50}
                              />
                            )}
                          />
                        </span>

                        {!document.getElementById(`${val}--error`)?.innerText &&
                          renderEntityValidationMessage(val, enityName, state)}
                        {column.default && (
                          <ul>
                            <li
                              className=" font-fam-for-all pt-2"
                              style={{ fontSize: "12px", color: "#667085" }}
                            >
                              {column.default}
                            </li>
                          </ul>
                        )}
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all "
                        ></small>

                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                </>
              );
          }
        case "text":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="  ">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                            : column.required !== undefined &&
                              column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                        }
                        render={({ field, fieldState }) => (
                          <InputTextarea
                            id={field.name}
                            {...field}
                            autoComplete={false}
                            rows={4}
                            cols={100}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "Director_Information" ||
                              modelname === "Officer_Information" ||
                              modelname === "Filer_Information" ||
                              modelname === "Mailing_Information" ||
                              modelname === "Billing_Information" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details" ||
                              modelname === "Beneficial_Owner_Information" ||
                              modelname === "Company_Applicant_Information"
                            }
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                            }}
                            className="dialog-form-input-area-field-wizard"
                            maxLength={50}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        // case "biginteger":
        //     return<>
        //     <input type="number" />
        //     </>
        case "biginteger":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className="justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="  ">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: /^\d{1,10}$/,
                                message:
                                  "Please Enter Valid No Of Positions (1-5 digits)",
                              },
                            }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <input
                            type="number"
                            id={field.name}
                            {...field}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "Director_Information" ||
                              modelname === "Officer_Information" ||
                              modelname === "Filer_Information" ||
                              modelname === "Mailing_Information" ||
                              modelname === "Billing_Information" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details" ||
                              modelname === "Beneficial_Owner_Information" ||
                              modelname === "Company_Applicant_Information"
                            }
                            onInput={(e) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10);
                              }
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                              borderRadius: "6px",
                            }}
                            className="dialog-form-input-field-wizard p-inputtext"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        case "integer":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full ">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="  ">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: /^\d+$/,
                                message: "Please Enter Valid No Of Positions",
                              },
                            }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <input
                            type="number"
                            id={field.name}
                            {...field}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "Director_Information" ||
                              modelname === "Officer_Information" ||
                              modelname === "Filer_Information" ||
                              modelname === "Mailing_Information" ||
                              modelname === "Billing_Information" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details" ||
                              modelname === "Beneficial_Owner_Information" ||
                              modelname === "Company_Applicant_Information"
                            }
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                              borderRadius: "6px",
                            }}
                            className="dialog-form-input-field-wizard p-inputtext"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        case "decimal":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="  ">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: /^\d+$/,
                                message: "Please Enter Valid No Of Positions",
                              },
                            }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <input
                            type="float"
                            id={field.name}
                            {...field}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "Director_Information" ||
                              modelname === "Officer_Information" ||
                              modelname === "Filer_Information" ||
                              modelname === "Mailing_Information" ||
                              modelname === "Billing_Information" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details" ||
                              modelname === "Beneficial_Owner_Information" ||
                              modelname === "Company_Applicant_Information"
                            }
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                              borderRadius: "6px",
                            }}
                            className="dialog-form-input-field-wizard p-inputtext"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );

        case "email":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="  ">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Please Enter Valid Email",
                              },
                            }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <input
                            type="email"
                            id={field.name}
                            {...field}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "Director_Information" ||
                              modelname === "Officer_Information" ||
                              modelname === "Filer_Information" ||
                              modelname === "Mailing_Information" ||
                              modelname === "Billing_Information" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details" ||
                              modelname === "Beneficial_Owner_Information" ||
                              modelname === "Company_Applicant_Information"
                            }
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                              borderRadius: "6px",
                            }}
                            className="dialog-form-input-field-wizard p-inputtext"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        case "password":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="  ">
                      <Controller
                        control={control}
                        name={val}
                        rules={
                          column.required !== undefined &&
                            column.required === true
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value:
                                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                message:
                                  "Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.",
                              },
                            }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <Password
                            id={field.name}
                            {...field}
                            toggleMask
                            inputRef={field.ref}
                            autoComplete={false}
                            className="dialog-form-input-field-wizard"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );

        case "richtext":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="  ">
                      <Controller
                        control={control}
                        name={val}
                        rules={
                          column.required !== undefined &&
                            column.required === true
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value:
                                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                message:
                                  "Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.",
                              },
                            }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          // <Password
                          //     id={field.name} {...field}
                          //     toggleMask
                          //     inputRef={field.ref}
                          //     autoComplete={false}
                          //     className='dialog-form-input-field-wizard'
                          // />
                          <Editor
                            id={field.name}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            inputRef={field.ref}
                            style={{ height: "320px" }}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        case "boolean":
          return (
            <div className="input-fields-container">
              <div className="input-fields-main">
                <div className="justify-content-center dialog-form-field-space w-full">
                  <label htmlFor="" className="form-label font-fam-for-all">
                    {convertName(key)}
                    {column.required ? (
                      <span className="form-field-mandatory">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <span className="p-float-label">
                    <Controller
                      name={val}
                      control={control}
                      defaultValue={false}
                      rules={
                        column.required
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                      }
                      render={({ field }) => (
                        <Switch
                          checked={field.value ?? false}
                          disabled={
                            modelname === "Principal_Address" ||
                            modelname === "Organizer_Information" ||
                            modelname === "Incorporator_Information" ||
                            (IsCheckedAgent &&
                              modelname === "Registered_Agent") ||
                            modelname === "Director_Information" ||
                            modelname === "Officer_Information" ||
                            modelname === "Filer_Information" ||
                            modelname === "Mailing_Information" ||
                            modelname === "Billing_Information" ||
                            modelname === "ucc_Debtor_Details" ||
                            modelname === "ucc_Secured_Party_Details" ||
                            modelname === "Beneficial_Owner_Information" ||
                            modelname === "Company_Applicant_Information"
                          }
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            errorMessageTag &&
                              (errorMessageTag.textContent = "");
                          }}
                          name={val}
                          color="primary"
                        />
                      )}
                    />
                  </span>
                  <small
                    id={`${val}--error`}
                    className="error-msg font-fam-for-all"
                  ></small>
                  {getFormErrorMessage(key)}
                </div>
              </div>
            </div>
          );

        case "enumeration":
          if (options.length > 0) {
            const matchedItem = tempdata.find((item) => item.item === tempval);
            
            return (
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className="justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                            : column.required !== undefined &&
                              column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                        }
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            placeholder={field.name}
                            filter
                            name={val}
                            value={field.value}
                            options={options}
                            optionValue={
                              matchedItem !== undefined
                                ? matchedItem.label
                                : "name"
                            }
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "Director_Information" ||
                              modelname === "Officer_Information" ||
                              modelname === "Filer_Information" ||
                              modelname === "Mailing_Information" ||
                              modelname === "Billing_Information" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details" ||
                              modelname === "Beneficial_Owner_Information" ||
                              modelname === "Company_Applicant_Information"
                            }
                            optionLabel={
                              matchedItem !== undefined
                                ? matchedItem.label
                                : "name"
                            }
                            onChange={(e) => {
                              field.onChange(e.value);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            maxSelectedLabels={3}
                            style={{ flexGrow: "1" }}
                            className="dialog-form-input-field-wizard"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all"
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <>
                <div className="input-fields-container">
                  <div className="input-fields-main">
                    <div className=" justify-content-center dialog-form-field-space w-full">
                      <label htmlFor="" className="form-label font-fam-for-all">
                        {convertName(key)}{" "}
                        {column.required !== undefined &&
                          column.required === true ? (
                          <span className="form-field-mandatory">*</span>
                        ) : (
                          ""
                        )}
                      </label>

                      <span className="  ">
                        <Controller
                          name={val}
                          control={control}
                          // onChange={(e) => errorMessageTag.textContent = ''}

                          rules={
                            column.required !== undefined &&
                              column.required === true &&
                              column.regex !== undefined
                              ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: new RegExp(column.regex),
                                  message: `Please Enter Valid ${convertName(
                                    key
                                  )}`,
                                },
                              }
                              : column.required !== undefined &&
                                column.required === true
                                ? { required: `${convertName(key)} Required.` }
                                : {}
                          }
                          render={({ field }) => (
                            <Dropdown
                              id={field.name}
                              filter
                              name="jd"
                              value={field.value}
                              options={
                                column.enum !== undefined ? column.enum : []
                              }
                              onChange={(e) => {
                                field.onChange(e.value);
                                errorMessageTag &&
                                  (errorMessageTag.textContent = "");
                              }}
                              maxSelectedLabels={3}
                              style={{ flexGrow: "1" }}
                              className="dialog-form-input-field-wizard"
                            />
                          )}
                        />
                      </span>
                      <small
                        id={`${val}--error`}
                        className="error-msg font-fam-for-all "
                      ></small>
                      {getFormErrorMessage(key)}
                    </div>
                  </div>
                </div>
              </>
            );
          }

        default:
          return null;
      }
    } else if (
      What_Are_We_Changing !== undefined &&
      val !== "COA_New_Name_To_Be_Used" &&
      val !== "COA_What_Are_We_Changing" &&
      val !== "Please_Tell_Us_What_You_Are_Changing"
    ) {
      
      switch (What_Are_We_Changing) {
        case "County":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("COC_Please_Include_New_County")}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>

                    <span className="  ">
                      <Controller
                        name={"COC_Please_Include_New_County"}
                        control={control}
                        // onChange={(e) => errorMessageTag.textContent = ''}

                        rules={
                          column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                            : column.required !== undefined &&
                              column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                        }
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            filter
                            name="jd"
                            value={field.value}
                            optionLabel="countyName"
                            optionValue="countyName"
                            options={allCounty}
                            onChange={(e) => {
                              field.onChange(e.value);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            maxSelectedLabels={3}
                            style={{ flexGrow: "1" }}
                            className="dialog-form-input-field-wizard"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );

        case "Other":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("Please_Tell_Us_What_You_Are_Changing")}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>

                    <span className="  ">
                      <Controller
                        name={"Please_Tell_Us_What_You_Are_Changing"}
                        control={control}
                        // onChange={(e) => errorMessageTag.textContent = ''}

                        rules={
                          column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                            : column.required !== undefined &&
                              column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                        }
                        render={({ field }) => (
                          <InputText
                            id={field.name}
                            name="jd"
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.value);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            style={{ flexGrow: "1" }}
                            className="dialog-form-input-field-wizard"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );

        default:
          break;
      }
    } else if (
      COA_What_Are_We_Amending !== undefined &&
      val !== "COA_New_Name_To_Be_Used" &&
      val !== "COA_What_Are_We_Changing"
    ) {
      switch (COA_What_Are_We_Amending) {
        case "Name":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("COA_New_Name_To_Be_Used")}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="  ">
                      <Controller
                        name="COA_New_Name_To_Be_Used"
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                            : column.required !== undefined &&
                              column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                        }
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            autoComplete={false}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            disabled={
                              modelname === "Principle_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details"
                            }
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                            }}
                            className="dialog-form-input-field-wizard"
                            maxLength={50}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </div>
          );

        case "Provisions":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("COA_What_Are_We_Changing")}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="  ">
                      <Controller
                        name="COA_What_Are_We_Changing"
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                            : column.required !== undefined &&
                              column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                        }
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            autoComplete={false}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            disabled={
                              modelname === "Principle_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details"
                            }
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                            }}
                            className="dialog-form-input-field-wizard"
                            maxLength={50}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </div>
          );

        case "Other":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("COA_Please_Tell_Us_What_You_Want_To_Amend")}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="  ">
                      <Controller
                        name="COA_Please_Tell_Us_What_You_Want_To_Amend"
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                            : column.required !== undefined &&
                              column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                        }
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            autoComplete={false}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            disabled={
                              modelname === "Principle_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details"
                            }
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                            }}
                            className="dialog-form-input-field-wizard"
                            maxLength={50}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </div>
          );
        default:
          break;
      }
    } else if (
      cc_What_Type_Document !== undefined &&
      cc_What_Type_Document === "Other - Please specify"
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className=" justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("cc_Specify_Other_Document")}{" "}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className="  ">
                  <Controller
                    name="cc_Specify_Other_Document"
                    control={control}
                    rules={
                      column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                        ? {
                          required: `${convertName(
                            "cc_Specify_Other_Document"
                          )} Required.`,
                          pattern: {
                            value: new RegExp(column.regex),
                            message: `Please Enter Valid ${convertName(key)}`,
                          },
                        }
                        : column.required !== undefined &&
                          column.required === true
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                    }
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoComplete={false}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors(key);
                          errorMessageTag && (errorMessageTag.textContent = "");
                        }}
                        disabled={
                          modelname === "Principle_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details"
                        }
                        style={{
                          flexGrow: "1",
                          paddingLeft: "10px",
                          paddingBottom: "5px",
                        }}
                        className="dialog-form-input-field-wizard"
                        maxLength={50}
                      />
                    )}
                  />
                </span>
                <small
                  id={`${"cc_Specify_Other_Document"}--error`}
                  className="error-msg font-fam-for-all "
                ></small>
                {getFormErrorMessage("cc_Specify_Other_Document")}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      pricipal_activity !== undefined &&
      pricipal_activity === "Other (specify)"
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className=" justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("EIN_Other")}{" "}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className="  ">
                  <Controller
                    name="EIN_Other"
                    control={control}
                    rules={
                      column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                        ? {
                          required: `${convertName("EIN_Other")} Required.`,
                          pattern: {
                            value: new RegExp(column.regex),
                            message: `Please Enter Valid ${convertName(key)}`,
                          },
                        }
                        : column.required !== undefined &&
                          column.required === true
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                    }
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoComplete={false}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors(key);
                          errorMessageTag && (errorMessageTag.textContent = "");
                        }}
                        disabled={
                          modelname === "Principle_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details"
                        }
                        style={{
                          flexGrow: "1",
                          paddingLeft: "10px",
                          paddingBottom: "5px",
                        }}
                        className="dialog-form-input-field-wizard"
                        maxLength={50}
                      />
                    )}
                  />
                </span>
                <small
                  id={`${"EIN_Other"}--error`}
                  className="error-msg font-fam-for-all "
                ></small>
                {getFormErrorMessage("EIN_Other")}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      ucc_UCC_Collateral_Type !== undefined &&
      val !== "ucc_Collateral_Statement"
    ) {
      switch (ucc_UCC_Collateral_Type) {
        case "Upload Their Own Collateral Statement":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className="justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("ucc_Collateral_Statement")}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="p-float-label">
                      <Controller
                        name={"ucc_Collateral_Statement"}
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                            ? {
                              required: `${convertName(
                                "ucc_Collateral_Statement"
                              )} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  "ucc_Collateral_Statement"
                                )}`,
                              },
                            }
                            : column.required !== undefined &&
                              column.required === true
                              ? {
                                required: `${convertName(
                                  "ucc_Collateral_Statement"
                                )} Required.`,
                              }
                              : {}
                        }
                        render={({ field }) => (
                          // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                          <input
                            type="file"
                            // onChange={(e) => handleFile(e, val)}
                            onChange={(e) =>
                              handleFile(
                                e,
                                `ucc_Collateral_Statement-${val.split("-")[1] || 0}`
                              )
                            }
                          />

                          
                        )}
                      />
                    </span>
                    <small
                      id={`${"ucc_Collateral_Statement"}--error`}
                      className="error-msg font-fam-for-all"
                    ></small>
                    {getFormErrorMessage("ucc_Collateral_Statement")}
                  </div>
                </div>
              </div>
            </div>
          );

        case "Type Their Own Collateral Statement":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("ucc_Type_Your_Collateral_Statement")}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="  ">
                      <Controller
                        control={control}
                        name={"ucc_Type_Your_Collateral_Statement"}
                        rules={
                          column.required !== undefined &&
                            column.required === true
                            ? {
                              required: `${convertName(
                                "ucc_Type_Your_Collateral_Statement"
                              )} Required.`,
                              pattern: {
                                value:
                                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                message:
                                  "Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.",
                              },
                            }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          // <Password
                          //     id={field.name} {...field}
                          //     toggleMask
                          //     inputRef={field.ref}
                          //     autoComplete={false}
                          //     className='dialog-form-input-field-wizard'
                          // />
                          <Editor
                            id={field.name}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            inputRef={field.ref}
                            style={{ height: "320px" }}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${"ucc_Type_Your_Collateral_Statement"}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage("ucc_Type_Your_Collateral_Statement")}
                  </div>
                </div>
              </div>
            </div>
          );

        default:
          break;
      }
    } else if (
      finCenId &&
      val === `boi_FinCEN_ID_Number-${val.split("-")[1] || 0}`
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className=" justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("boi_FinCEN_ID_Number")}{" "}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className="  ">
                  <Controller
                    name={`boi_FinCEN_ID_Number-${val.split("-")[1] || 0}`}
                    control={control}
                    rules={
                      column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                        ? {
                          required: `${convertName(
                            "boi_FinCEN_ID_Number"
                          )} Required.`,
                          pattern: {
                            value: new RegExp(column.regex),
                            message: `Please Enter Valid ${convertName(key)}`,
                          },
                        }
                        : column.required !== undefined &&
                          column.required === true
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                    }
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoComplete={false}
                        // onChange={(e) => errorMessageTag.textContent = ''}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information"
                        }
                        style={{
                          flexGrow: "1",
                          paddingLeft: "10px",
                          paddingBottom: "5px",
                        }}
                        className="dialog-form-input-field-wizard"
                        maxLength={50}
                      />
                    )}
                  />
                </span>
                <small
                  id={`${"boi_FinCEN_ID_Number"}--error`}
                  className="error-msg font-fam-for-all "
                ></small>
                {getFormErrorMessage("boi_FinCEN_ID_Number")}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      finCenId &&
      val === `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className="justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("boi_FinCEN_ID_Document")}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className="p-float-label">
                  <Controller
                    name={`boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`}
                    control={control}
                    rules={
                      column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                        ? {
                          required: `${convertName(
                            "boi_FinCEN_ID_Document"
                          )} Required.`,
                          pattern: {
                            value: new RegExp(column.regex),
                            message: `Please Enter Valid ${convertName(
                              "boi_FinCEN_ID_Document"
                            )}`,
                          },
                        }
                        : column.required !== undefined &&
                          column.required === true
                          ? {
                            required: `${convertName(
                              "boi_FinCEN_ID_Document"
                            )} Required.`,
                          }
                          : {}
                    }
                    render={({ field }) => (
                      // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                      <input
                        name={`boi_FinCEN_ID_Document-${val.split("-")[1] || 0
                          }`}
                        type="file"
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information"
                        }
                        onChange={(e) =>
                          handleFile(
                            e,
                            `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                          )
                        }
                      />
                    )}
                  />
                </span>
                {getValues(
                  `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                ) !== undefined &&
                  typeof getValues(
                    `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                  ) === "string" && (
                    <p className="font-web-color font-fam-for-all">
                      {getValues(
                        `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                      )
                        .split("/")
                        .pop()}
                    </p>
                  )}
                <small
                  id={`${"boi_FinCEN_ID_Document"}--error`}
                  className="error-msg font-fam-for-all"
                ></small>
                {getFormErrorMessage(key)}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      finCenIdCA &&
      val === `boica_FinCEN_ID_Number-${val.split("-")[1] || 0}`
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className=" justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("boica_FinCEN_ID_Number")}{" "}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className="  ">
                  <Controller
                    name={`boica_FinCEN_ID_Number-${val.split("-")[1] || 0}`}
                    control={control}
                    rules={
                      column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                        ? {
                          required: `${convertName(
                            "boica_FinCEN_ID_Number"
                          )} Required.`,
                          pattern: {
                            value: new RegExp(column.regex),
                            message: `Please Enter Valid ${convertName(key)}`,
                          },
                        }
                        : column.required !== undefined &&
                          column.required === true
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                    }
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoComplete={false}
                        // onChange={(e) => errorMessageTag.textContent = ''}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information"
                        }
                        style={{
                          flexGrow: "1",
                          paddingLeft: "10px",
                          paddingBottom: "5px",
                        }}
                        className="dialog-form-input-field-wizard"
                        maxLength={50}
                      />
                    )}
                  />
                </span>
                <small
                  id={`${`boica_FinCEN_ID_Number-${val.split("-")[1] || 0
                    }`}--error`}
                  className="error-msg font-fam-for-all "
                ></small>
                {getFormErrorMessage("boica_FinCEN_ID_Number")}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      finCenIdCA &&
      val === `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className="justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("boica_FinCEN_ID_Document")}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className="p-float-label">
                  <Controller
                    name={`boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`}
                    control={control}
                    rules={
                      column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                        ? {
                          required: `${convertName(
                            "boica_FinCEN_ID_Document"
                          )} Required.`,
                          pattern: {
                            value: new RegExp(column.regex),
                            message: `Please Enter Valid ${convertName(
                              "boica_FinCEN_ID_Document"
                            )}`,
                          },
                        }
                        : column.required !== undefined &&
                          column.required === true
                          ? {
                            required: `${convertName(
                              "boica_FinCEN_ID_Document"
                            )} Required.`,
                          }
                          : {}
                    }
                    render={({ field }) => (
                      // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                      <input
                        name={`boica_FinCEN_ID_Document-${val.split("-")[1] || 0
                          }`}
                        type="file"
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information"
                        }
                        onChange={(e) =>
                          handleFile(
                            e,
                            `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                          )
                        }
                      />
                    )}
                  />
                </span>
                {getValues(
                  `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                ) !== undefined &&
                  typeof getValues(
                    `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                  ) === "string" && (
                    <p className="font-web-color font-fam-for-all">
                      {getValues(
                        `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                      )
                        .split("/")
                        .pop()}
                    </p>
                  )}
                <small
                  id={`${`boica_FinCEN_ID_Document-${val.split("-")[1] || 0
                    }`}--error`}
                  className="error-msg font-fam-for-all"
                ></small>
                {getFormErrorMessage(key)}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      finCenId === false &&
      val === `boi_Date_of_Birth-${val.split("-")[1] || 0}`
    ) {
      return (
        <div className="input-fields-container">
          <div className="input-fields-main">
            <div className="justify-content-center dialog-form-field-space w-full">
              <label htmlFor="" className="form-label font-fam-for-all">
                {convertName(key)}
                {column.required ? (
                  <span className="form-field-mandatory">*</span>
                ) : (
                  ""
                )}
              </label>
              <span className="p-float-label">
                <Controller
                  name={`boi_Date_of_Birth-${val.split("-")[1] || 0}`}
                  control={control}
                  rules={
                    column.required
                      ? {
                        required: `${convertName(
                          `boi_Date_of_Birth-${val.split("-")[1] || 0}`
                        )} Required.`,
                      }
                      : {}
                  }
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="strapi-date-picker"
                        style={{ width: "322px" }}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information"
                        }
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(newValue) => {
                          if (newValue && newValue.isValid()) {
                            const formattedDate = newValue.format("YYYYMMDD");
                            field.onChange(formattedDate);
                          } else {
                            field.onChange(null);
                          }
                          errorMessageTag && (errorMessageTag.textContent = "");
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        disableFuture
                      />
                    </LocalizationProvider>
                  )}
                />
              </span>
              <small
                id={`${`boi_Date_of_Birth-${val.split("-")[1] || 0}`}--error`}
                className="error-msg font-fam-for-all"
              ></small>
              {getFormErrorMessage(key)}
            </div>
          </div>
        </div>
      );
    } else if (
      finCenIdCA === false &&
      val === `boica_Date_of_Birth-${val.split("-")[1] || 0}`
    ) {
      return (
        <div className="input-fields-container">
          <div className="input-fields-main">
            <div className="justify-content-center dialog-form-field-space w-full">
              <label htmlFor="" className="form-label font-fam-for-all">
                {convertName(key)}
                {column.required ? (
                  <span className="form-field-mandatory">*</span>
                ) : (
                  ""
                )}
              </label>
              <span className="p-float-label">
                <Controller
                  name={`boica_Date_of_Birth-${val.split("-")[1] || 0}`}
                  control={control}
                  rules={
                    column.required
                      ? {
                        required: `${convertName(
                          `boica_Date_of_Birth-${val.split("-")[1] || 0}`
                        )} Required.`,
                      }
                      : {}
                  }
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="strapi-date-picker"
                        style={{ width: "322px" }}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information"
                        }
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(newValue) => {
                          if (newValue && newValue.isValid()) {
                            const formattedDate = newValue.format("YYYYMMDD");
                            field.onChange(formattedDate);
                          } else {
                            field.onChange(null);
                          }
                          errorMessageTag && (errorMessageTag.textContent = "");
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        disableFuture
                      />
                    </LocalizationProvider>
                  )}
                />
              </span>
              <small
                id={`${`boica_Date_of_Birth-${val.split("-")[1] || 0}`}--error`}
                className="error-msg font-fam-for-all"
              ></small>
              {getFormErrorMessage(key)}
            </div>
          </div>
        </div>
      );
    } else if (purpose === "Other" && val === "CD_Please_specify") {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className=" justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName(key)}{" "}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className="  ">
                  <Controller
                    name="CD_Please_specify"
                    control={control}
                    // placeholder="test"
                    rules={
                      column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                        ? {
                          required: `${convertName(
                            "CD_Please_specify"
                          )} Required.`,
                          pattern: {
                            value: new RegExp(column.regex),
                            message: `Please Enter Valid ${convertName(key)}`,
                          },
                        }
                        : column.required !== undefined &&
                          column.required === true
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                    }
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        placeholder="Please specify purpose"
                        {...field}
                        autoComplete={false}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors(key);
                          errorMessageTag && (errorMessageTag.textContent = "");
                        }}
                        disabled={
                          modelname === "Principle_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          val === "X_Name_of_Entity"
                        }
                        style={{
                          flexGrow: "1",
                          paddingLeft: "10px",
                          paddingBottom: "5px",
                        }}
                        className="dialog-form-input-field-wizard"
                        maxLength={50}
                      />
                    )}
                  />
                </span>

                <small
                  id={`${"CD_Please_specify"}--error`}
                  className="error-msg font-fam-for-all "
                ></small>
                {getFormErrorMessage(key)}
              </div>
            </div>
          </div>
        </>
      );
    } else if (val === "NC_NAICS_Code") {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className="justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("NC_NAICS_Code")}{" "}
                  {column.required && column.required === true && (
                    <span className="form-field-mandatory">*</span>
                  )}
                </label>

                <span className="">
                  <Controller
                    name={"NC_NAICS_Code"}
                    control={control}
                    rules={
                      column.required
                        ? column.regex
                          ? {
                            required: `${convertName(key)} Required.`,
                            pattern: {
                              value: new RegExp(column.regex),
                              message: `Please Enter Valid ${convertName(
                                key
                              )}`,
                            },
                          }
                          : {
                            required: `${convertName(key)} Required.`,
                          }
                        : {}
                    }
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        filter
                        name="jd"
                        value={field.value}
                        optionLabel="naicsDesc"
                        optionValue="naicsDesc"
                        placeholder="Select NAICS Code"
                        options={allStateNaicsCode}
                        onChange={(e) => {
                          // Set the value for NC_NAICS_Code using naicsDesc
                          field.onChange(e.value);

                          const selectedOption = allStateNaicsCode.find(
                            (item) => item.naicsDesc === e.value
                          );
                          

                          if (selectedOption) {
                            setNaicsCode(selectedOption);
                          }

                          if (errorMessageTag) {
                            errorMessageTag.textContent = "";
                          }
                        }}
                        maxSelectedLabels={3}
                        style={{ flexGrow: "1" }}
                        className="dialog-form-input-field-wizard"
                      />
                    )}
                  />
                </span>
                <small
                  id={`${val}--error`}
                  className="error-msg font-fam-for-all"
                ></small>
                {getFormErrorMessage(key)}
              </div>
            </div>
          </div>
        </>
      );
    } else if (val === "NC_NAICS_Sub_Code" && getValues("NC_NAICS_Code") !== "Unknown" && getValues("NC_NAICS_Code") !== "Any Illegal Purpose") {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className=" justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("NC_NAICS_Sub_Code")}{" "}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>

                <span className="  ">
                  <Controller
                    name={"NC_NAICS_Sub_Code"}
                    control={control}
                    // onChange={(e) => errorMessageTag.textContent = ''}

                    rules={
                      column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                        ? {
                          required: `${convertName(key)} Required.`,
                          pattern: {
                            value: new RegExp(column.regex),
                            message: `Please Enter Valid ${convertName(key)}`,
                          },
                        }
                        : column.required !== undefined &&
                          column.required === true
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                    }
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        filter
                        name="jd"
                        value={field.value}
                        optionLabel="naicsSubCodeDesc"
                        optionValue="naicsSubCodeDesc"
                        // placeholder="Select NAICS Code"
                        options={allSubNaicsCode}
                        onChange={(e) => {
                          field.onChange(e.value);
                          errorMessageTag && (errorMessageTag.textContent = "");
                        }}
                        maxSelectedLabels={3}
                        style={{ flexGrow: "1" }}
                        className="dialog-form-input-field-wizard"
                      />
                    )}
                  />
                </span>
                <small
                  id={`${val}--error`}
                  className="error-msg font-fam-for-all "
                ></small>
                {getFormErrorMessage(key)}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      
      return null;
    }
  };

  // function processSchema(schema, dataMap, depth = 0, maxDepth = 10) {
  //     if (depth > maxDepth) {
  //         console.warn("Maximum recursion depth exceeded");
  //         return {};
  //     }

  //     const result = {};

  //     if (schema) {
  //         Object.keys(schema.attributes).forEach((attrKey) => {
  //             const attr = schema.attributes[attrKey];

  //             if (attr.uid && dataMap.has(attr.uid)) {
  //                 const componentData = dataMap.get(attr.uid);
  //                 if (attr.schema) {
  //                     // Recursively process nested schemas
  //                     result[attrKey] = processSchema(attr.schema, dataMap, depth + 1, maxDepth);

  //                     // Merge component data with the schema's processed result
  //                     Object.assign(result[attrKey], componentData);
  //                 } else {
  //                     // Directly assign the component data for non-nested attributes
  //                     result[attrKey] = componentData;
  //                 }
  //             }
  //             //  else if (attr.type) {
  //             //     // Handle simple attributes without a UID (e.g., primitive types like strings)
  //             //     result[attrKey] = attr.default || null;
  //             // }
  //         });
  //     }

  //     return result;
  // }

  // function buildFinalData(attributes, modelData) {
  //     const finalData = {};

  //     const dataMap = new Map(modelData.map((data) => [data.model, data.data]));

  //     Object.keys(attributes).forEach((categoryKey) => {
  //         finalData[categoryKey] = {};

  //         const categoryAttributes = attributes[categoryKey];
  //         Object.keys(categoryAttributes).forEach((key) => {
  //             const attr = categoryAttributes[key];
  //             const schema = attr?.schema;
  //             const data = dataMap.get(attr?.uid);

  //             if (schema) {

  //                 finalData[categoryKey][key] = processSchema(schema, dataMap);
  //                 if (data) {
  //                     Object.assign(finalData[categoryKey][key], data);
  //                 }
  //             } else if (data) {

  //                 finalData[categoryKey][key] = data;
  //             }
  //         });
  //     });

  //     
  //     return finalData;
  // }

  function removeUndefinedKeys(obj) {
    if (Array.isArray(obj)) {
      return obj.map(removeUndefinedKeys).filter((item) => item !== undefined);
    } else if (typeof obj === "object" && obj !== null) {
      const cleanedObj = {};
      for (const [key, value] of Object.entries(obj)) {
        if (key !== undefined && value !== undefined) {
          cleanedObj[key] = removeUndefinedKeys(value);
        }
      }
      return cleanedObj;
    }
    return obj;
  }

  function processSchema(schema, dataMap, depth = 0, maxDepth = 10) {
    if (depth > maxDepth) {
      console.warn("Maximum recursion depth exceeded");
      return {};
    }

    const result = {};

    if (schema) {
      Object.keys(schema.attributes).forEach((attrKey) => {
        const attr = schema.attributes[attrKey];

        if (attr.uid && dataMap.has(attr.uid)) {
          const componentData = dataMap.get(attr.uid);
          

          if (attr.schema) {
            
            result[attrKey] = processSchema(
              attr.schema,
              dataMap,
              depth + 1,
              maxDepth
            );
            
            Object.assign(result[attrKey], componentData);
          } else {
            
            result[attrKey] = componentData;
          }
        } else if (attr.type) {
          if (attr.type === "string") {
            result[attrKey] = "";
          } else if (attr.type === "array") {
            result[attrKey] = [];
          } else if (attr.type === "object") {
            result[attrKey] = {};
          } else {
            result[attrKey] = null;
          }
        }
      });
    }

    return result;
  }

  function buildFinalData(attributes, modelData) {
    const finalData = {};

    const dataMap = new Map(
      (modelData || []).map((data) => [data.model, data.data])
    );

    Object.keys(attributes).forEach((categoryKey) => {
      finalData[categoryKey] = {};

      const categoryAttributes = attributes[categoryKey];
      Object.keys(categoryAttributes).forEach((key) => {
        const attr = categoryAttributes[key];
        const schema = attr?.schema;
        const data = dataMap.get(attr?.uid);
        

        

        if (schema) {
          finalData[categoryKey][key] = processSchema(schema, dataMap);

          

          if (Array.isArray(data)) {
            finalData[categoryKey][key] = [...data];
          } else {
            Object.assign(finalData[categoryKey][key], data);
          }
        } else if (data) {
          finalData[categoryKey][key] = data;
        } else {
          if (attr?.type === "string") {
            finalData[categoryKey][key] = "";
          } else if (attr?.type === "array") {
            finalData[categoryKey][key] = [];
          } else if (attr?.type === "object") {
            finalData[categoryKey][key] = {};
          } else {
            finalData[categoryKey][key] = null;
          }
        }
      });
    });

    return finalData;
  }

  useEffect(() => {
    if (mainModel && modelData1.length > 0 && bilingAddress) {
      if (mainModel.schema?.attributes) {
        setTimeout(() => {
          const finalData1 = buildFinalData(
            mainModel.schema.attributes,
            modelData1
          );

          
          const cleanedFinalOutput = removeUndefinedKeys(finalData1);
          
          setPriviewData(finalData1);
        }, 10000);
      }
    }
    if (mainModel) {
      const finalData = buildFinalData(mainModel.schema.attributes, []);
      
      setWizardTabData(finalData);
    }
  }, [mainModel, modelData1, bilingAddress]);

  let currentTabs = 0;
  let currentSteps = 1;
  function showTab(n) {
    
    var x = document.getElementsByClassName("tab");
    
    if (x.length > 0) {
      
      if (x[n] !== undefined) {
        
        x[n].style.display = "block";
      }

      if (n == 0) {
        document.getElementById("prevBtn").style.display = "none";
      } else {
        document.getElementById("prevBtn").style.display = "flex";
      }
      if (n == x.length - 1 && inputFormData.length > 0) {
        // document.getElementById("nextBtn").innerHTML = "Submit";
        document.getElementById("nextBtn").style.display = "none";
        document.getElementById("submitBtn").style.display = "flex";
      } else {
        document.getElementById("nextBtn").innerHTML = "Save & Next";
        document.getElementById("nextBtn").style.display = "flex";
        document.getElementById("submitBtn").style.display = "none";
      }
      fixStepIndicator(n);
    }
  }

  const handleBOIKeyPersonal = (data) => {
    setVisible8(true);
    const temp = ownerType.filter((val) => val.name === data);
    if (temp.length > 0) {
      setMemberType(temp[0]);
    }

    const temp1 = allAddressType.filter((val) => val.type === data);
    
    if (temp1.length > 0) {
      setKeyPersonalAddressType(temp1[0]);
    }
  };

  const nextPrev = (n) => {
    setWizardScroll(!wizardScroll);
    setStateErr("");
    setEntityErr("");
    setCountyErr("");

    if (!state && !enityName) {
      setStateErr("State required");
      setEntityErr("Entity type required");
      return;
    }

    if (!state) {
      setStateErr("State required");
      return;
    }
    if (!enityName) {
      setEntityErr("Entity type required");
      return;
    }
    if (state && (stateWithCounty.includes(state.stateFullDesc))) {
      if (!county) {
        setCountyErr("County required");
        return;
      }
    }

    if (selectedCheckBoxData.length > 0) {
      var x = document.getElementsByClassName("tab");

      if (x.length > 0) {
        if (n === 1 && !validateForm()) return false;

        if (x[currentTab] !== undefined) {
          x[currentTab].style.display = "none";
        }
        if (n === 1) {
          if (currentStep < inputFormData.length + 5) {
            setCurrentStep(currentStep + 1);
          }
        }

        if (n === -1) {
          if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
          }
        }
        const newTab = currentTab + n;
        setCurrentTab(newTab);
        showTab(newTab);

        

        if (mainModel && n === 1) {
          const keyPersonalArr = [];
          if (incorporator?.id) keyPersonalArr.push(incorporator.id);
          if (officer?.id) keyPersonalArr.push(officer.id);
          if (director?.id) keyPersonalArr.push(director.id);
          if (organizerData?.id) keyPersonalArr.push(organizerData.id);
          if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
          if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);
          if (president?.id) keyPersonalArr.push(president.id);
          if (treasurer?.id) keyPersonalArr.push(treasurer.id);
          if (secretary?.id) keyPersonalArr.push(secretary.id);

          const keyPersonalStrapiArr = [];
          if (incorporator?.id)
            keyPersonalStrapiArr.push({
              data: incorporator,
              type: "INCORPORATOR",
            });
          if (officer?.id)
            keyPersonalStrapiArr.push({ data: officer, type: "OFFICER" });
          if (director?.id)
            keyPersonalStrapiArr.push({ data: director, type: "DIRECTOR" });
          if (organizerData?.id)
            keyPersonalStrapiArr.push({
              data: organizerData,
              type: "ORGANIZER",
            });
          if (bilingAddress?.id)
            keyPersonalStrapiArr.push({ data: bilingAddress, type: "BILLING" });
          if (location?.id)
            keyPersonalStrapiArr.push({
              data: location,
              type: "PRINCIPAL_ADDRESS",
            });

          if (registeredAgent?.id && IsCheckedAgent) {
            keyPersonalStrapiArr.push({ data: registeredAgent, type: "REGISTER_AGENT" });
          }

          if (registeredAgent && IsCheckedAgent)
            keyPersonalStrapiArr.push({
              data: registeredAgent,
              type: "REGISTER_AGENT",
            });

          if (president?.id)
            keyPersonalStrapiArr.push({ data: president, type: "PRESIDENT" });
          if (treasurer?.id)
            keyPersonalStrapiArr.push({ data: treasurer, type: "TREASURER" });
          if (secretary?.id)
            keyPersonalStrapiArr.push({ data: secretary, type: "SECRETARY" });
          if (filer?.id)
            keyPersonalStrapiArr.push({ data: filer, type: "FILER" });
          if (bilingInfo?.id)
            keyPersonalStrapiArr.push({ data: bilingInfo, type: "RA-BILLING" });
          if (mailingInfo?.id)
            keyPersonalStrapiArr.push({
              data: mailingInfo,
              type: "RA-MAILING",
            });

          if (allMemberOrManager.length > 0) {
            const allCompanyApplicant = allMemberOrManager
              .map((party, index) => party[`member_or_manger_details-${index}`])
              .filter((party) => party?.id);
            if (allCompanyApplicant.length > 0) {
              keyPersonalStrapiArr.push({
                data: allCompanyApplicant,
                type: "MEMBER OR MANAGER",
              });
            }
          }

          let d = new Date();
          const futureDate = new Date(d);
          futureDate.setDate(d.getDate() + 7);

          let day = d.getDate();
          let month = d.getMonth() + 1;
          let year = d.getFullYear();

          let day1 = futureDate.getDate();
          let month1 = futureDate.getMonth();
          let year1 = futureDate.getFullYear();

          if (day < 10) day = "0" + day;
          if (month < 10) month = `0${month}`;
          let newDate = year + "-" + month + "-" + day;

          if (day1 < 10) day1 = "0" + day1;
          if (month < 10) month1 = `0${month1}`;
          let newDate1 = year1 + "-" + month1 + "-" + day1;

          const upadatedProduct = selectedCheckBoxData.map((val) => {
            return {
              productListing: val.product,
              quantity: 1,
              unitPrice: val.product.unitPrice,
            };
          });

          

          

          const modelDatas = processFormData(allModels);

          

          const finalData1 = buildFinalData(
            mainModel.schema.attributes,
            modelDatas
          );

          

          setValue(
            "X_Name_of_Entity",
            getValues("CD_LLC_Name") !== undefined
              ? getValues("CD_LLC_Name")
              : getValues("CD_Corporation_Name") !== undefined
                ? getValues("CD_Corporation_Name")
                : globalCompany
                  ? globalCompany.companyDetails
                    ? globalCompany.companyDetails.companyName
                    : "Order"
                  : "Order"
          );

          const jsonAutomation = {
            EntityType: enityName,
            State: state,
            County: county,
            Payload: finalData1,
            Header: {
              itemName: "Entity Formation",
              path: "",
              isFirst: false,
              orderType: 1,
              description1:
                " Register your company online in minutes. Complete the following steps to get started.",
              description:
                "Register your company online in minutes. Complete the following steps to get started.",
            },
            addOnService: null,
            legalName:
              getValues("CD_LLC_Name") !== undefined
                ? getValues("CD_LLC_Name")
                : getValues("CD_Corporation_Name") !== undefined
                  ? getValues("CD_Corporation_Name")
                  : globalCompany
                    ? globalCompany.companyDetails
                      ? globalCompany.companyDetails.companyName
                      : "Order"
                    : "Order",
            billingAddress: bilingAddress,
            shipingAddress: shippingAddress,
            keyPersonalStrapiArr: keyPersonalStrapiArr,
            formCount: formCounts,
            step: currentStep,
            totalStep: totalStep,
          };

          const orderData = {
            contactId: contactId,
            // admin
            // super - >selectedcompany id ->required
            companyId: globalCompany
              ? globalCompany.companyDetails
                ? globalCompany.companyDetails.id
                : null
              : null,
            orderTitle:
              getValues("CD_LLC_Name") !== undefined
                ? getValues("CD_LLC_Name")
                : getValues("CD_Corporation_Name") !== undefined
                  ? getValues("CD_Corporation_Name")
                  : globalCompany
                    ? globalCompany.companyDetails
                      ? globalCompany.companyDetails.companyName
                      : "Order"
                    : "Order",
            orderId: null,
            billingAddress: bilingAddress
              ? bilingAddress.addressMasterId.id
              : null,
            statusId: 7,
            orderAmount: parseFloat(calculateTotalAmount()),
            orderDate: newDate,
            // "tentativeFulfillmentDate": newDate1,
            actualCompletionDate: null,
            strapiOrderFormJson: JSON.stringify(jsonAutomation),
            strapiOrderId: null,
            strapiOrderType: "Standard",
            orderTypeId: 1,
            stateId: state ? state.id : null,
            county: county ? county.id : null,
            transaction_id: null,
            entityTypeId: enityName ? enityName.id : null,
            isPayLater: paymentMethod === "Pay later" ? true : false,
            domicileStateId: state ? state.id : null,
            hubspotDealId: null,
            orderUpdateNotes: "Draft order created...",
            documentsId: null,
            orderUpdatedBy: loginUserName,
            keyPersonalId: keyPersonalArr,
            orderLineItems: upadatedProduct,
          };

          const saveOrder = () => {
            setKeyPersonnelArray(keyPersonalStrapiArr);
            if (formData) {
              const areIdentical = deepCompareObjects(
                serviceDataTemp,
                selectedCheckBoxData
              );
              
              
              if (!areIdentical && serviceDataTemp.length > 0) {
                const temp = formData;
                temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
                temp["orderLineItems"] = upadatedProduct;
                temp["billingAddress"] = bilingAddress
                  ? bilingAddress.addressMasterId.id
                  : null;
                temp["shippingAddress"] = shippingAddress
                  ? shippingAddress.addressMasterId.id
                  : null;
                temp["stateId"] = state ? state.id : null;
                temp["countyId"] = county ? county.id : null;
                temp["keyPersonalId"] = keyPersonalArr;
                temp["orderAmount"] = parseFloat(calculateTotalAmount());
                temp["orderTitle"] =
                  getValues("CD_LLC_Name") !== undefined
                    ? getValues("CD_LLC_Name")
                    : getValues("CD_Corporation_Name") !== undefined
                      ? getValues("CD_Corporation_Name")
                      : globalCompany
                        ? globalCompany.companyDetails
                          ? globalCompany.companyDetails.companyName
                          : "Order"
                        : "Order";
                setIsTabRefresh(true);
                return OrderServices.deleteOrderLineItemById(formData.id)
                  .then((res) => {
                    
                    // Save order data and handle error

                    return OrderServices.saveAllOrder(temp)
                      .then((res) => {
                        
                        setPaymentOrderData(res.data);
                        setFormData(res.data.orderDetails);
                        setServiceDataTemp(selectedCheckBoxData);
                        setUpdatedOrderLineItems(res.data.orderLineItem);
                        setIsTabRefresh(false);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      })
                      .catch((err) => {
                        
                        setIsTabRefresh(false);

                        return false;
                      });
                  })
                  .catch((err) => {
                    
                    setIsTabRefresh(false);
                  });
              } else {
                const temp = formData;
                temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
                temp["orderLineItems"] = updatedOrderLineItems;
                temp["billingAddress"] = bilingAddress
                  ? bilingAddress.addressMasterId.id
                  : null;
                temp["shippingAddress"] = shippingAddress
                  ? shippingAddress.addressMasterId.id
                  : null;
                temp["stateId"] = state ? state.id : null;
                temp["countyId"] = county ? county.id : null;
                temp["keyPersonalId"] = keyPersonalArr;
                temp["orderAmount"] = parseFloat(calculateTotalAmount());
                temp["orderTitle"] =
                  getValues("CD_LLC_Name") !== undefined
                    ? getValues("CD_LLC_Name")
                    : getValues("CD_Corporation_Name") !== undefined
                      ? getValues("CD_Corporation_Name")
                      : globalCompany
                        ? globalCompany.companyDetails
                          ? globalCompany.companyDetails.companyName
                          : "Order"
                        : "Order";

                setIsTabRefresh(true);
                return OrderServices.saveAllOrder(temp)
                  .then((res) => {
                    
                    setPaymentOrderData(res.data);
                    setFormData(res.data.orderDetails);
                    setServiceDataTemp(selectedCheckBoxData);
                    setUpdatedOrderLineItems(res.data.orderLineItem);
                    setIsTabRefresh(false);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  })
                  .catch((err) => {
                    
                    setIsTabRefresh(false);

                    return false;
                  });
              }
            } else {
              setIsTabRefresh(true);
              return OrderServices.saveAllOrder(orderData)
                .then((res) => {
                  
                  setPaymentOrderData(res.data);
                  setFormData(res.data.orderDetails);
                  setServiceDataTemp(selectedCheckBoxData);
                  setUpdatedOrderLineItems(res.data.orderLineItem);
                  setIsTabRefresh(false);
                  setIsFirstDraft(true);
                  setOrderContinue(true);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth", // Smooth scroll
                  });

                  if (res.status === 201) {
                    // toast.current.show({
                    //   severity: "success",
                    //   summary: "Success",
                    //   detail: `Your order is saved in draft with temporary order id ${res.data.orderDetails.id}`,
                    //   life: 3000,
                    // });
                    showToast(
                      "success",
                      `Your order is saved in draft with temporary order id ${res.data.orderDetails.id}`
                    );
                  }
                })
                .catch((err) => {
                  
                  setIsTabRefresh(false);
                  // if (toast.current) {
                  //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                  // }
                  return false; // Prevent navigation on error
                });
            }
          };

          saveOrder().then((success) => {
            if (success !== false) {
              setCurrentTab(newTab);
              showTab(newTab);
            }
          });
        }
      }
    } else {
      if (toast.current !== undefined) {
        // toast.current.show({
        //   severity: "warn",
        //   summary: "Warning",
        //   detail: "Please select at least one product",
        //   life: 3000,
        // });
        showToast("error", "Please select at least one product");
      }
    }

    // Smooth scroll to top after click
    // document.getElementById('nextBtn').addEventListener('click', function () {
    //     window.scrollTo({
    //         top: 0,
    //         behavior: 'smooth'
    //     });
    // });
  };

  const stepLabels = Array.from(
    { length: inputFormData.length + 5 },
    (_, i) => `Step ${i + 1} of ${inputFormData.length + 5}`
  );

  

  function stripSuffix(inputName) {
    return inputName.replace(/-\d+$/, "");
  }

  const getActiveStepValue = () => {
    const activeStep = document.querySelector(
      ".step.active .wizard-step-content"
    );
    return activeStep ? activeStep.textContent : null;
  };

  function validateForm() {
    const x = document.getElementsByClassName("tab");
    let valid = true;

    const dropdownArray = [
      "Organizer",
      "Principal_Address",
      "Incorporator",
      "Director",
      "Officer",
      "Filer",
      "President",
      "Treasurer",
      "Secretary",
      "RA_Billing_Information",
      "RA_Mailing_Information",
    ];

    const keys = [];
    // const keys = ["Member_Or_Manager_Details", "Beneficial_Owner", "Company_Applicant"];
    const multiDropdownArray = [];

    keys.forEach((key) => {
      if (formCounts && formCounts[key] && formCounts[key] > 0) {
        for (let i = 1; i <= formCounts[key]; i++) {
          multiDropdownArray.push(`${key}-${i}`);
        }
      } else {
        multiDropdownArray.push(`${key}-1`);
      }
    });

    const activeStepValue = getActiveStepValue();
    if (
      activeStepValue &&
      activeStepValue === "Member Or Manager Details" &&
      allMemberOrManager.length === 0
    ) {
      showToast("error", "Select at least one member");
      valid = false;
    }
    if (
      activeStepValue &&
      activeStepValue === "Member Or Manager Details" &&
      allMemberOrManager.length > 5
    ) {
      showToast("error", "You can select maximum 5 members");
      valid = false;
    }

    if (x.length > 0 && x[currentTab] !== undefined) {
      const y = Array.from(x[currentTab].getElementsByTagName("input")).filter(
        (input) => input.type !== "file"
      );
      const dropdowns = x[currentTab].getElementsByClassName("p-dropdown");
      const fileInputs = x[currentTab].querySelectorAll('input[type="file"]');

      for (let i = 0; i < y.length; i++) {
        const input = y[i];
        const inputName = stripSuffix(input.name);

        
        const column = allModels.find((field) => {
          const temp = field.schema.attributes;
          
          return Object.keys(temp).includes(inputName);
        });
        

        if (column && column !== undefined) {
          const fieldData = column.schema.attributes[inputName];
          
          const errorMessageTag = document.getElementById(
            `${input.name}--error`
          );

          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }

          
          
          if (fieldData.required && input.value.trim() === "") {
            valid = false;
            if (errorMessageTag) {
              errorMessageTag.textContent = `${convertName(
                inputName
              )} required`;
            }
            
          }
          if (
            (input.name === "CD_LLC_Name" ||
              input.name === "CD_Corporation_Name") &&
            enityName &&
            state
          ) {
            const entityRegex =
              validationConfig[enityName.entityShortName]?.[
              state.stateFullDesc
              ];
            

            if (entityRegex && !entityRegex.test(input.value.trim())) {
              valid = false;
              if (errorMessageTag) {
                // const patternText = entityRegex.source
                //   .split("|")
                //   .join(", ")
                //   .replace(/[()\\$]/g, "");

                const patternTextold = entityRegex.source.split("|").join(", ").replace(/[()\\$?:+<=^!*]/g, "");
      
                const patternText = patternTextold.replace(/^[.sb]+/, "");


                errorMessageTag.textContent = `Enter a valid ${convertName(
                  input.name
                )} ending with ${patternText}`;
              }
            }
          }

          // SSN validation
          if (
            input.name === "EIN_Social_security_number_and_ITI_number" &&
            getValues("EIN_Social_security_number_and_ITI_number")
          ) {
            // Remove non-digit characters
            const ssnValue = input.value.replace(/\D/g, ""); 
          
            if (ssnValue.length !== 9) {
              valid = false;
              
              if (errorMessageTag) {
                errorMessageTag.textContent = `${convertName(inputName)} must be exactly 9 digits`;
              }
            } else {
              valid = true;
          
              // If valid, clear the error message
              if (errorMessageTag) {
                errorMessageTag.textContent = "";
              }
            }
          }

          if (
            (input.name === "CD_Alternate_LLC_Name" &&
              getValues("CD_Alternate_LLC_Name")) ||
            (input.name === "CD_Alternate_Corporation_Name" &&
              getValues("CD_Alternate_Corporation_Name"))
          ) {
            if (enityName && state) {
              const entityRegex =
                validationConfig[enityName.entityShortName]?.[
                state.stateFullDesc
                ];
              

              if (entityRegex && !entityRegex.test(input.value.trim())) {
                valid = false;
                if (errorMessageTag) {
                  // const patternText = entityRegex.source
                  //   .split("|")
                  //   .join(", ")
                  //   .replace(/[()\\$]/g, "");
                  const patternTextold = entityRegex.source.split("|").join(", ").replace(/[()\\$?:+<=^!*]/g, "");
      
                  const patternText = patternTextold.replace(/^[.sb]+/, "");


                  errorMessageTag.textContent = `Enter a valid ${convertName(
                    input.name
                  )} ending with ${patternText}`;
                }
              }
            }
          }

          if (fieldData.regex && input.value.trim() !== "") {
            const regex = new RegExp(fieldData.regex);
            if (!regex.test(input.value.trim())) {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `Enter Valid ${convertName(
                  inputName
                )} `;
              }
            }
          }
          if (input.type === "text" || input.type === "textarea") {
            if (fieldData.maxLength && input.value.trim() !== "") {
              if (input.value.length > fieldData.maxLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    inputName
                  )} should have a maximum length of ${fieldData.maxLength}`;
                }
              }
            }
            if (fieldData.minLength && input.value.trim() !== "") {
              if (input.value.length < fieldData.minLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    inputName
                  )} should have a minimum length of ${fieldData.minLength}`;
                }
              }
            }
          } else if (input.type === "number") {
            
            const value = parseInt(input.value, 10);
            
            if (fieldData.max && !isNaN(value) && value > fieldData.max) {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `${convertName(
                  inputName
                )} should be ${fieldData.max.length} digits`;
              }
            }
            if (fieldData.min && !isNaN(value) && value < fieldData.min) {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `${convertName(
                  inputName
                )} should be at least ${fieldData.min.length} digits`;
              }
            }
          }
        } else {
          
          
          
          if (inputFormData.length === 0) {
            valid = false;
          }
        }
      }

      for (let i = 0; i < dropdowns.length; i++) {
        const dropdown = dropdowns[i];
        const inputName = dropdown.id;
        const inputWithoutSuffix = stripSuffix(dropdown.id);
        const inputValue = dropdown
          .querySelector(".p-dropdown-label")
          .textContent.trim();

        if (inputName) {
          const matchedDropdown = dropdownArray.find((val) =>
            val.includes(inputName)
          );
          
          const errorMessageTag = document.getElementById(
            `${inputName}--error`
          );

          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }
          if (
            matchedDropdown &&
            matchedDropdown !== "RA_Mailing_Information" &&
            matchedDropdown !== "RA_Billing_Information" &&
            inputValue === "empty"
          ) {
            
            
            valid = false;
            if (errorMessageTag) {
              errorMessageTag.textContent = `${convertName(
                inputName
              )} required`;
            }
          }
          if (
            matchedDropdown &&
            matchedDropdown === "RA_Mailing_Information" &&
            IsCheckedAgent &&
            !IsCheckedAgent1 &&
            inputValue === "empty"
          ) {
            
            
            valid = false;
            if (errorMessageTag) {
              errorMessageTag.textContent = `${convertName(
                inputName
              )} required`;
            }
          }
          if (
            matchedDropdown &&
            matchedDropdown === "RA_Billing_Information" &&
            IsCheckedAgent &&
            inputValue === "empty"
          ) {
            
            
            valid = false;
            if (errorMessageTag) {
              errorMessageTag.textContent = `${convertName(
                inputName
              )} required`;
            }
          }
        }

        if (multiDropdownArray.some((val) => inputName.includes(val))) {
          

          const errorMessageTag = document.getElementById(
            `${inputName}--error`
          );

          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }
          if (inputValue === "empty") {
            
            valid = false;
            if (errorMessageTag) {
              errorMessageTag.textContent = `${toPascalCase(
                inputName
              )} required`;
            }
          }
        }

        if (inputName) {
          const column = allModels.find((field) => {
            const temp = field.schema.attributes;
            return Object.keys(temp).includes(inputWithoutSuffix);
          });

          if (column && column !== undefined) {
            const fieldData = column.schema.attributes[inputWithoutSuffix];
            const errorMessageTag = document.getElementById(
              `${inputName}--error`
            );

            if (errorMessageTag) {
              errorMessageTag.textContent = "";
            }

            if (fieldData.required && inputValue === "empty") {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `${convertName(
                  inputWithoutSuffix
                )} required`;
              }
            }

            if (fieldData.regex && inputValue !== "") {
              const regex = new RegExp(fieldData.regex);
              if (!regex.test(inputValue)) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `Enter Valid ${convertName(
                    inputWithoutSuffix
                  )} `;
                }
              }
            }
            if (fieldData.maxLength && inputValue !== "") {
              if (inputValue.length > fieldData.maxLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    inputWithoutSuffix
                  )} Should be Max Length ${fieldData.maxLength} `;
                }
              }
            }
            if (fieldData.minLength && inputValue !== "") {
              if (inputValue.length < fieldData.minLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    inputWithoutSuffix
                  )} Should be Min Length ${fieldData.minLength} `;
                }
              }
            }
          } else {
            if (inputFormData.length === 0) {
              valid = false;
            }
          }
        }
      }

      for (let i = 0; i < fileInputs.length; i++) {
        const fileInput = fileInputs[i];
        const fileName = stripSuffix(fileInput.name);
        
        const column = allModels.find((field) => {
          const temp = field.schema.attributes;
          return Object.keys(temp).includes(fileName);
        });

        if (column && column !== undefined) {
          const fieldData = column.schema.attributes[fileName];
          const errorMessageTag = document.getElementById(
            `${fileInput.name}--error`
          );

          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }

          if (
            fieldData.required &&
            fileInput.files.length === 0 &&
            getValues(fileInput.name) === undefined
          ) {
            valid = false;
            if (errorMessageTag) {
              errorMessageTag.textContent = `${convertName(fileName)} required`;
            }
          }
        }
      }

      if (valid) {
        const stepElements = document.getElementsByClassName("step");

        const stepElements1 = document.getElementsByClassName("step-circle");

        if (stepElements[currentTab] !== undefined) {
          stepElements[currentTab].className += " finish";
        }
        if (stepElements1[currentTab] !== undefined) {
          stepElements1[currentTab].className += " finish";
        }
      }
    }

    

    return valid;
  }

  function fixStepIndicator(n) {
    
    var i,
      x = document.getElementsByClassName("step");
    var y = document.getElementsByClassName("step-circle");
    for (i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(" active", "");
    }
    if (x[n] !== undefined) {
      x[n].className += " active";
    }
    for (i = 0; i < y.length; i++) {
      y[i].className = y[i].className.replace(" active", "");
    }
    if (y[n] !== undefined) {
      y[n].className += " active";
    }
  }



  const getState = (id) => {
    const temp = allState.find((val) => val.id === id);
    if (temp !== undefined) {
      return temp.stateFullDesc;
    } else {
      return null;
    }
  };

  const stripePay = async (item, orderData) => {
    try {
      const stripe = await stripePromise;

      const data = {
        product: item,
      };

      const productPaydata = item.map((val) => {
        return {
          name: val.productListing.displayName,
          unitAmount: Math.round(val.productListing.unitPrice * 100),
          quantity: 1,
        };
      });

      const paytemp = {
        // orderId: orderData?.orderDetails?.orderId ? (orderData?.orderDetails?.orderId).toString() : "",
orderId :orderData?.orderDetails?.orderId.toString(),
        lineItems: productPaydata,
      };

      // const response = await axios.post(`$/checkout`, data);

      // console.log(paytemp,"paytemp")
      


      OrderServices.paymentOrder(paytemp)
        .then((response) => {
          
          localStorage.setItem("sessionId", response.data.sessionId);
          // setPaySessionId(res.data.sessionId)

          // const sessionId = response.data.id;
          // console.log(sessionId)
          
          setIsLoading(false);
          setIsTabRefresh(false);
          setCloseProgresbar3(false);
          const localData = {
            data: orderData,
            res: response.data,
            orderLineItem: item,
            updatedBy: loginUserName,
          };
          localStorage.setItem("payment", JSON.stringify(localData));
          setIsLoading(false);
          setIsTabRefresh(false);
          setTimeout(() => {
            window.location.href = response.data.checkoutUrl;
          }, 100); // Small timeout to ensure data is saved
          // const { error } = await stripe.redirectToCheckout({
          //     sessionId: sessionId
          // });
          // if (error) {
          //     console.error("Stripe Checkout Error:", error);
          // }
        })
        .catch((err) => {
          
          setIsLoading(false);
          setIsTabRefresh(false);
        });
    } catch (err) {
      console.error("Error during checkout process:", err);
    }
  };

  

  // Function to handle confirmation
  const handleConfirm = async () => {
    if (mainModel && bilingAddress) {
      const keyPersonalArr = [];
      if (incorporator?.id) keyPersonalArr.push(incorporator.id);
      if (officer?.id) keyPersonalArr.push(officer.id);
      if (director?.id) keyPersonalArr.push(director.id);
      if (filer?.id) keyPersonalArr.push(filer.id);
      if (organizerData?.id) keyPersonalArr.push(organizerData.id);
      if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
      if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);

      

      const keyPersonalStrapiArr = [];
      if (incorporator?.id)
        keyPersonalStrapiArr.push({ data: incorporator, type: "INCORPORATOR" });
      if (officer?.id)
        keyPersonalStrapiArr.push({ data: officer, type: "OFFICER" });
      if (director?.id)
        keyPersonalStrapiArr.push({ data: director, type: "DIRECTOR" });
      if (organizerData?.id)
        keyPersonalStrapiArr.push({ data: organizerData, type: "ORGANIZER" });
      if (bilingAddress?.id)
        keyPersonalStrapiArr.push({ data: bilingAddress, type: "BILLING" });

      if (president?.id)
        keyPersonalStrapiArr.push({ data: president, type: "PRESIDENT" });
      if (treasurer?.id)
        keyPersonalStrapiArr.push({ data: treasurer, type: "TREASURER" });
      if (secretary?.id)
        keyPersonalStrapiArr.push({ data: secretary, type: "SECRETARY" });
      if (filer?.id) keyPersonalStrapiArr.push({ data: filer, type: "FILER" });
      if (bilingInfo?.id)
        keyPersonalStrapiArr.push({ data: bilingInfo, type: "RA-BILLING" });
      if (mailingInfo?.id)
        keyPersonalStrapiArr.push({ data: mailingInfo, type: "RA-MAILING" });
      if(registeredAgent?.id && IsCheckedAgent){
        keyPersonalStrapiArr.push({ data: registeredAgent, type: "REGISTER_AGENT" });
        }

      if (location?.id)
        keyPersonalStrapiArr.push({
          data: location,
          type: "PRINCIPAL_ADDRESS",
        });

      if (registeredAgent && IsCheckedAgent)
        keyPersonalStrapiArr.push({
          data: registeredAgent,
          type: "REGISTER_AGENT",
        });

      if (allMemberOrManager.length > 0) {
        const allCompanyApplicant = allMemberOrManager
          .map((party, index) => party[`member_or_manger_details-${index}`])
          .filter((party) => party?.id);
        if (allCompanyApplicant.length > 0) {
          keyPersonalStrapiArr.push({
            data: allCompanyApplicant,
            type: "MEMBER OR MANAGER",
          });
        }
      }

      let d = new Date();
      const futureDate = new Date(d);
      futureDate.setDate(d.getDate() + 7);
      

      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();

      let day1 = futureDate.getDate();
      let month1 = futureDate.getMonth() + 1;
      let year1 = futureDate.getFullYear();

      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = `0${month}`;
      } else {
        month = month;
      }
      let newDate = year + "-" + month + "-" + day;

      if (day1 < 10) {
        day1 = "0" + day1;
      }
      if (month < 10) {
        month1 = `0${month1}`;
      } else {
        month1 = month1;
      }
      let newDate1 = year1 + "-" + month1 + "-" + day1;

      const upadatedProduct = selectedCheckBoxData.map((val) => {
        return {
          productListing: val.product,
          quantity: 1,
          unitPrice: calculateTotalAmount(),
        };
      });

      const finalData1 = buildFinalData(
        mainModel.schema.attributes,
        modelData1
      );

      const jsonAutomation = {
        EntityType: enityName,
        State: state,
        County: county,
        Payload: finalData1,
        legalName:
          getValues("CD_LLC_Name") !== undefined
            ? getValues("CD_LLC_Name")
            : getValues("CD_Corporation_Name") !== undefined
              ? getValues("CD_Corporation_Name")
              : null,
        addOnService: null,
        billingAddress: bilingAddress,
        shipingAddress: shippingAddress,
        keyPersonalStrapiArr: keyPersonalStrapiArr,
        formCount: formCounts,

      };

      if (paymentMethod === "Pay later") {
        if (formData) {
          const temp = formData;
          temp["statusId"] = 14;
          temp["orderLineItems"] = updatedOrderLineItems;
          temp["orderUpdateNotes"] = "Order created successfully";
          temp["orderUpdatedBy"] = loginUserName;
          temp["shippingAddress"] = shippingAddress
            ? shippingAddress.addressMasterId.id
            : bilingAddress
              ? bilingAddress.addressMasterId.id
              : null;
          temp["stateId"] = state ? state.id : null;
          temp["countyId"] = county ? county.id : null;
          temp["orderDate"] = newDate;
          temp["tentativeFulfillmentDate"] = futureDate;
          temp["orderAmount"] = parseFloat(calculateTotalAmount());
          temp["strapiOrderId"] = 1;
          temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
          temp["keyPersonalId"] = keyPersonalArr;
          setIsTabRefresh(true);
          OrderServices.saveAllOrder(temp)
            .then((res) => {
              
              const tempNew = res.data.orderDetails;
              setStrapiOrderData(res.data);
              tempNew["shippingAddress"] = shippingAddress
                ? shippingAddress.addressMasterId.id
                : bilingAddress
                  ? bilingAddress.addressMasterId.id
                  : null;
              tempNew["orderLineItems"] = updatedOrderLineItems;
              const invoiceData = {
                orderId: tempNew,
                invoiceNo: `INV ${res.data.orderDetails.orderId}`,
                totalAmount: calculateTotalAmount(),
                orderLineItems: updatedOrderLineItems,
              };

              const temp = res.data.orderDetails;

              // OrderServices.saveAllInvoice(invoiceData)
              //   .then((res) => {
              //     

              //     setIsTabRefresh(false);
              //     setPaymentOrderData(res.data);
              //     setFormData(res.data.orderId);
              //     setCloseProgresbar2(true);
              //   })
              //   .catch((err) => {
              //     
              //     setCloseProgresbar2(true);
              //     setCloseProgresbar3(false);
              //     setIsTabRefresh(false);

              //   });

              temp["statusId"] = 1000;
              temp["orderLineItems"] = updatedOrderLineItems;
              temp["orderUpdateNotes"] = "Approval Pending";
              temp["orderUpdatedBy"] = loginUserName;
              temp["shippingAddress"] = shippingAddress
                ? shippingAddress.addressMasterId.id
                : bilingAddress
                  ? bilingAddress.addressMasterId.id
                  : null;
              temp["stateId"] = state ? state.id : null;
              temp["countyId"] = county ? county.id : null;
              temp["strapiOrderId"] = 1;
              temp["keyPersonalId"] = keyPersonalArr;

              OrderServices.saveAllOrder(temp)
                .then((res) => {
                  
                  setIsTabRefresh(false);
                  setRedirectedData(res.data.orderDetails);
                  setOrderContinue(false);
                  history("/order");
                })
                .catch((err) => {
                  
                  setCloseProgresbar2(false);
                  setCloseProgresbar3(false);
                  setCloseProgresbar1(true);
                  setIsTabRefresh(false);
                  // if (toast.current) {
                  //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                  // }
                });
            })
            .catch((err) => {
              
              setCloseProgresbar2(false);
              setCloseProgresbar3(false);
              setCloseProgresbar1(true);
              setIsTabRefresh(false);
              // if (toast.current) {
              //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
              // }
            });
        }
      } else {
        if (formData) {
          if (formData?.statusId === 14 && strapiOrderData) {
            stripePay(updatedOrderLineItems, strapiOrderData);
          } else {
            const temp = formData;
            temp["statusId"] = 14;
            temp["orderLineItems"] = updatedOrderLineItems;
            temp["orderUpdateNotes"] = "Order created successfully.";
            temp["orderUpdatedBy"] = loginUserName;
            temp["shippingAddress"] = shippingAddress
              ? shippingAddress.addressMasterId.id
              : bilingAddress
                ? bilingAddress.addressMasterId.id
                : null;
            temp["stateId"] = state ? state.id : null;
            temp["countyId"] = county ? county.id : null;
            temp["orderDate"] = newDate;
            temp["tentativeFulfillmentDate"] = futureDate;
            temp["strapiOrderId"] = 1;
            temp["keyPersonalId"] = keyPersonalArr;
            setIsTabRefresh(true);
            OrderServices.saveAllOrder(temp)
              .then((res) => {
                
                setOrderContinue(false);
                const orderPayData = res.data;
                setStrapiOrderData(res.data);
                const tempNew = res.data.orderDetails;

                tempNew["orderLineItems"] = updatedOrderLineItems;



                
                const invoiceData = {
                  orderId: tempNew,
                  invoiceNo: `INV ${res.data.orderDetails.orderId}`,
                  totalAmount: calculateTotalAmount(),
                  orderLineItems: updatedOrderLineItems,
                };

                const temp = res.data.orderDetails;

                // OrderServices.saveAllInvoice(invoiceData)
                //   .then((res) => {
                //     
                //     stripePay(updatedOrderLineItems, orderPayData);
                //   })
                //   .catch((err) => {
                //     

                //     // if (toast.current) {
                //     //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                //     // }
                //   });
                // setCloseProgresbar2(false);
                // setCloseProgresbar3(false);
                stripePay(updatedOrderLineItems, orderPayData);
              })
              .catch((err) => {
                
                setCloseProgresbar2(false);
                setCloseProgresbar3(false);
                // setCloseProgresbar1(true)
                setIsTabRefresh(false);
                // if (toast.current) {
                //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                // }
              });
          }
        }
      }
    }
    
  };

  

  const onSubmit = (data) => {
    setIsTabRefresh(true);
    if (paymentMethod === "Pay later") {
      
      setCloseProgresbar2(true);
      setIsTabRefresh(false);
    } else {
      setCloseProgresbar3(true);
      setIsTabRefresh(false);
    }

    if (!bilingAddress) {
      showToast("error", "Billing address required");
    }
  };

  const convertToTitleCase = (str) => {
    return str
      .split(/[-_]/)
      .map((word) => {
        return word.toUpperCase() === word
          ? word
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  // const totalAmount = selectCheckBoxData.reduce((total, item) => {

  //     const amount = parseFloat(item.amount.replace("$", ""));
  //     return total + amount;
  // }, 0);

  const toPascalCase = (str) => {
    const keepUpperCase = ["LLC", "CGS", "KIT", "CORP", "EIN", "UCC"];

    return str
      .split(/[-_]/)
      .map((word) => {
        const upperWord = word.toUpperCase();
        return keepUpperCase.includes(upperWord)
          ? upperWord
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  const removeDuplicates = (data) => {
    const result = {};

    Object.keys(data).forEach((key) => {
      const uniqueItems = new Map();

      data[key].form.forEach((item) => {
        if (!uniqueItems.has(item.uid)) {
          uniqueItems.set(item.uid, item);
        }
      });

      result[key] = {
        mode: data[key].mode,
        form: Array.from(uniqueItems.values()),
      };
    });

    return result;
  };

  

  const groupsedData =
    inputFormData.length > 0
      ? inputFormData.reduce((acc, value) => {
        if (typeof value.schema.attributes === "object") {
          const tmp = value.schema.attributes;
          if (tmp !== undefined) {
            const temp = Object.keys(tmp).map((key, i) => {
              Object.keys(groupInitialData).map((keys, m) => {
                if (
                  key === keys &&
                  groupInitialData[keys].type === "component" &&
                  groupInitialData[keys].repeatable === true
                ) {
                  const tmpAttributes = tmp[key]?.schema?.attributes;

                  if (
                    tmpAttributes &&
                    Object.keys(tmpAttributes).length > 0
                  ) {
                    if (typeof tmpAttributes === "object") {
                      const tempdata = Object.keys(tmpAttributes).map(
                        (keyval, i) => {
                          if (tmpAttributes[keyval]?.uid !== undefined) {
                            return tmpAttributes[keyval];
                          } else {
                            return tmp[key];
                          }
                        }
                      );

                      acc[key] = {
                        form: tempdata,
                        mode: {
                          repeat: groupInitialData[keys].repeatable,
                        },
                      };
                    }
                  }
                } else {
                  if (tmp[key].schema !== undefined) {
                    if (
                      tmp[key].schema.attributes !== undefined &&
                      groupInitialData[keys].repeatable === false &&
                      key === keys
                    ) {
                      if (typeof tmp[key].schema.attributes === "object") {
                        const tempdata = Object.keys(
                          tmp[key].schema.attributes
                        ).map((keyval, i) => {
                          if (
                            tmp[key].schema.attributes[keyval].uid !==
                            undefined
                          ) {
                            return tmp[key].schema.attributes[keyval];
                          } else {
                            return tmp[key];
                          }
                        });
                        acc[key] = {
                          form: tempdata,
                          mode: null,
                        };
                      }
                    }
                  }
                }
              });
            });
          }
        }
        return acc;
      }, {})
      : {};

  // const groupedTabData =
  //   inputFormData.length > 0
  //     ? inputFormData.reduce((acc, value) => {
  //       const apiID = value.api;
  //       if (typeof value.schema.attributes === "object") {
  //         const tmp = value.schema.attributes;
  //         if (tmp !== undefined) {
  //           const temp = Object.keys(tmp).map((key, i) => {
  //             Object.keys(groupInitialData).map((keys, m) => {
  //               if (
  //                 key === keys &&
  //                 groupInitialData[keys].type === "component" &&
  //                 groupInitialData[keys].repeatable === true
  //               ) {
  //                 const tmpAttributes = tmp[key]?.schema?.attributes;

  //                 if (
  //                   tmpAttributes &&
  //                   Object.keys(tmpAttributes).length > 0
  //                 ) {
  //                   if (typeof tmpAttributes === "object") {
  //                     const tempdata = Object.keys(tmpAttributes).map(
  //                       (keyval, i) => {
  //                         if (tmpAttributes[keyval]?.uid !== undefined) {
  //                           return tmpAttributes[keyval];
  //                         } else {
  //                           return tmp[key];
  //                         }
  //                       }
  //                     );

  //                     acc[key] = {
  //                       form: tempdata,
  //                       mode: {
  //                         repeat: groupInitialData[keys].repeatable,
  //                       },
  //                     };
  //                   }
  //                 }
  //               } else {
  //                 if (tmp[key].schema !== undefined) {
  //                   if (
  //                     tmp[key].schema.attributes !== undefined &&
  //                     groupInitialData[keys].repeatable === false &&
  //                     key === keys
  //                   ) {
  //                     if (typeof tmp[key].schema.attributes === "object") {
  //                       const tempdata = Object.keys(
  //                         tmp[key].schema.attributes
  //                       ).map((keyval, i) => {
  //                         if (
  //                           tmp[key].schema.attributes[keyval].uid !==
  //                           undefined
  //                         ) {
  //                           return tmp[key].schema.attributes[keyval];
  //                         } else {
  //                           return tmp[key];
  //                         }
  //                       });
  //                       acc[key] = {
  //                         form: tempdata,
  //                         mode: null,
  //                       };
  //                     }
  //                   }
  //                 }
  //               }
  //             });
  //           });
  //         }
  //       }
  //       return acc;
  //     }, {})
  //     : {};

  // const groupTabData = removeDuplicates(groupedTabData);

  const groupedData = removeDuplicates(groupsedData);

  useEffect(() => {
    const temp = Object.keys(groupedData).map((key, i) => {
      
      return groupedData[key].form;
    });
    
    const tempdata = temp.flat();
    
    setAllModels(tempdata);
  }, [enityName, state, currentTab]);

  const handleKeyPersonal = (data, key) => {
    setVisible2(true);
    const temp = allKeyPersonnelType.filter(
      (val) => val.keyPersonalType === data
    );
    
    if (temp.length > 0) {
      setMemberType(temp[0]);
    }

    const temp1 = allAddressType.filter((val) => val.type === data);
    
    if (temp1.length > 0) {
      setKeyPersonalAddressType(temp1[0]);
    }
    if (key !== undefined && key !== null) {
      setKeyPersonnelKey(key);
    }
  };

  const RecursiveRender = ({ data, parentKey }) => {
    return (
      <>
        {Array.isArray(data) ? (
          data.map((item, idx) => (
            <div key={idx} className="p-6 grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <div
                  className="px-4 py-2 rounded-t-lg"
                  style={{
                    backgroundColor: "#F0F5FF",
                    fontFamily: "Inter",
                    fontSize: "16px",
                  }}
                >
                  <h2 className="text-lg text-gray-800">
                    {" "}
                    {convertToTitleCase(parentKey)}{" "}
                  </h2>
                </div>
              </div>
              {Object.keys(item).map((key, i) => (
                <div key={i} className="col-span-4">
                  {typeof item[key] === "object" ? (
                    <div className="border border-gray-300 rounded-lg shadow-sm bg-white">
                      <div
                        className="px-4 py-2 rounded-t-lg"
                        style={{
                          backgroundColor: "#F0F5FF",
                          fontFamily: "Inter",
                          fontSize: "16px",
                        }}
                      >
                        <h2 className="text-lg text-gray-800">
                          {convertToTitleCase(key)}
                        </h2>
                      </div>
                      <RecursiveRender data={item[key]} parentKey={key} />
                    </div>
                  ) : (
                    <>
                      <div
                        className="font-medium text-gray-700 mb-1"
                        style={{ fontFamily: "Inter", fontSize: "14px" }}
                      >
                        {convertName(key)}
                      </div>
                      <div
                        className="text-gray-900"
                        style={{ fontFamily: "Inter", fontSize: "14px" }}
                      >
                        {item[key]}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))
        ) : typeof data === "object" && data !== null ? (
          <div className="p-6 grid grid-cols-12 gap-6">
            {Object.keys(data).map((key, i) => (
              <div key={i} className="col-span-4">
                {typeof data[key] === "object" ? (
                  <div className="border border-gray-300 rounded-lg shadow-sm bg-white">
                    <div
                      className="px-4 py-2 rounded-t-lg"
                      style={{
                        backgroundColor: "#F0F5FF",
                        fontFamily: "Inter",
                        fontSize: "16px",
                      }}
                    >
                      <h2 className="text-lg text-gray-800">
                        {convertToTitleCase(key)}
                      </h2>
                    </div>
                    <RecursiveRender data={data[key]} parentKey={key} />
                  </div>
                ) : (
                  <>
                    <div
                      className="font-medium text-gray-700 mb-1"
                      style={{ fontFamily: "Inter", fontSize: "14px" }}
                    >
                      {convertName(key)}
                    </div>
                    <div
                      className="text-gray-900"
                      style={{ fontFamily: "Inter", fontSize: "14px" }}
                    >
                      {data[key]}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div
            className="font-medium text-gray-700 mb-1"
            style={{ fontFamily: "Inter", fontSize: "14px" }}
          >
            {convertName(parentKey)}
            <div
              className="text-gray-900"
              style={{ fontFamily: "Inter", fontSize: "14px" }}
            >
              {data}
            </div>
          </div>
        )}
      </>
    );
  };
  const exemptState = [
    "RA_State",
    "BI_State",
    "MI_State",
    "PA_State",
    "Dir_State",
    "Org_State",
    "Inc_State",
    "Of_State",
    "Filer_State",
  ];

  useEffect(() => {
    if (groupedData) {
      Object.keys(groupedData).map((key, i) => {
        const isRepeatMode =
          groupedData[key].mode && groupedData[key].mode.repeat === true;

        const processAttributes = (val, l = "") => {
          if (exemptState.includes(val)) {
            return;
          }

          const tempval = convertName(val);
          const options = optionsMap?.[tempval] || [];

          if (options.length > 0) {
            const matchedItem = tempdata.find((item) => item.item === tempval);
            const defaultValue =
              matchedItem !== undefined
                ? matchedItem.label === "stateFullDesc" && state
                  ? state.stateShortName
                  : matchedItem.label === "countyName" && county
                    ? county.countyCode
                    : matchedItem.label === "countryShortName" && state
                      ? state.countryMaster.countryShortName
                      : ""
                : "";

            if (defaultValue) {
              
              if (l) {
                setValue(`${val}${l ? `-${l}` : ""}`, defaultValue);
              } else {
                setValue(val, defaultValue);
              }
            }
          }
        };

        if (isRepeatMode) {
          return Array.from({ length: formCounts[key] || 1 }).map((_, l) => {
            return groupedData[key].form.map((value, j) => {
              return Object.keys(value.schema.attributes).map((val, k) => {
                processAttributes(val, l);
              });
            });
          });
        } else {
          return groupedData[key].form.map((value, j) => {
            return Object.keys(value.schema.attributes).map((val, k) => {
              processAttributes(val);
            });
          });
        }
      });
    }
  }, [groupedData]);

  const goToStep = (stepNumber) => {
    
    const totalSteps = Object.keys(groupedData).length + 2;
    if (stepNumber >= 0 && stepNumber < totalSteps) {
      const tabs = document.getElementsByClassName("tab");
      if (tabs[currentTab]) {
        tabs[currentTab].style.display = "none";
      }

      setCurrentTab(stepNumber);
      setCurrentStep(stepNumber + 1);

      showTab(stepNumber);

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const onInvalid = (errors) => {
    const stepElements = document.getElementsByClassName("step");
    for (let index = 0; index < stepElements.length; index++) {
      if (!stepElements[index].classList.contains("finish")) {
        stepElements[index].classList.add("error");
      }
    }
    if (stepElements[currentTab] !== undefined) {
      stepElements[currentTab].classList.add("error");
    }
    showToast("error", `Please enter required information`);
  };

  let cumulativeIndex = 0;

  

  useEffect(() => {
    if (state && enityName) {
      setIsLoading(true);
      
      if (Object.keys(groupedData).length > 0) {
        setIsLoading(false);
        setTotalStep(Object.keys(groupedData).length + 3);
      }
    }
  }, [groupedData, state, enityName]);

  const handlers = {
    setOrganizerData,
    setBilingInfo,
    setMailingInfo,
    setBilingAddress,
    setOfficer,
    setIncorporator,
    setFiler,
    setDirector,
    setPresident,
    setTreasurer,
    setSecretary,
    setMemberData,
    setKeyPersonnelValue,
  };

  const keyPersonalMemberData = [
    "Organizer_Information",
    "Incorporator_Information",
    "Director_Information",
    "Officer_Information",
    // "Officer_Details",
    "Filer_Information",
    "President_Information",
    "Treasurer_Information",
    "Secretary",
    "ucc_Secured_Party_Details",
    "ucc-amnd-additional-debtor",
    "Billing_Information",
    "Mailing_Information",
    "ucc-amend-additional-secured-party-details",
    "ucc-assignee-details",
    // "Member_Or_Manager_Details",
    "Contact_Information",
    "Principal_Address",
  ];

  const items = Array.from({ length: 5 }, (v, i) => i);

  return (
    <div>
      <Toast ref={toast} />
      {loading1 ? (
        <div className="spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="data-container">
          <div className="grid font-fam-for-all">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <div
                className="card-container-wizard font-fam-for-all"
                id="card-container-wizard-id"
              >
                <div className="wizard-header">
                  <div className="wizard-header-container">
                    <div className="page-wizard-header">
                      <div className="page-wizard-content">
                        <div className="page-text-header">
                          <div className="page-text-header-p">
                            Entity Formation
                          </div>
                          <div className="page-text-header-p1">
                            Register your company online in minutes. Complete
                            the following steps to get started.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wizard-content-container">
                    <div className="wizard-content-main">
                      <div className="wizard-content-frame">
                        <div
                          className="wizard-content-card"
                          id="wizard-content-id"
                        >
                          <div className="wizard-form-card-header-container">
                            <div className="wizard-form-card-header-actiob-container">
                              <div className="wizard-form-card-frame">
                                {Object.keys(groupedData).length > 0 ? (
                                  <div className="wizard-form-card-header-text1">
                                    {`Step ${currentStep} of ${Object.keys(groupedData).length + 3
                                      }`}
                                  </div>
                                ) : (
                                  <div className="wizard-form-card-header-text1">
                                    {`Step 1 of 1`}
                                  </div>
                                )}
                                {inputFormData.length > 0 ? (
                                  <div className="wizard-form-card-header-text2">
                                    Total order amount
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="wizard-form-card-frame mx-4">
                            <div className="wizard-form-card-header-text3 ">
                              Entity Information
                            </div>
                            {inputFormData.length > 0 ? (
                              <div className="wizard-form-card-header-text4">
                                {`$${parseFloat(calculateTotalAmount()).toFixed(
                                  2
                                )}`}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="wizard-bar">
                            <span
                              class={
                                Object.keys(groupedData).length > 0
                                  ? "step"
                                  : "step "
                              }
                              style={{ cursor: "pointer" }}
                              onClick={() => goToStep(0)}
                            >
                              <div className="mt-2 wizard-form-card-header-text3">
                                Entity Details
                              </div>
                            </span>
                          </div>

                          {wizardTabData &&
                            Object.keys(wizardTabData).map(
                              (parentKey, parentIndex) =>
                                parentKey !== "undefined" && (
                                  <div key={`parent-${parentIndex}`}>
                                    <div className="wizard-form-card-header-text3 mx-4 mt-4">
                                      {toPascalCase(parentKey)}
                                    </div>
                                    <div className="wizard-bar">
                                      {Object.keys(
                                        wizardTabData[parentKey]
                                      ).map((formItem, formIndex) => {
                                        const currentIndex =
                                          cumulativeIndex + formIndex;
                                        return (
                                          <span
                                            className="step"
                                            onClick={() =>
                                              goToStep(currentIndex + 1)
                                            }
                                            style={{ cursor: "pointer" }}
                                            key={`child-${parentIndex}-${formIndex}`}
                                          >
                                            <div className="mt-2 wizard-step-content wizard-form-card-header-text3">
                                              {toPascalCase(formItem)}
                                            </div>
                                          </span>
                                        );
                                      })}
                                      <div style={{ display: "none" }}>
                                        {
                                          (cumulativeIndex += Object.keys(
                                            wizardTabData[parentKey]
                                          ).length)
                                        }
                                      </div>{" "}
                                    </div>
                                  </div>
                                )
                            )}

                          {inputFormData.length > 0 && wizardTabData ? (
                            <>
                              <div className="wizard-form-card-header-text3 mx-4 mt-4">
                                Contact & Preview
                              </div>
                              <div className="wizard-bar">
                                <span
                                  class={
                                    Object.keys(groupedData).length > 0
                                      ? "step"
                                      : "step"
                                  }
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    goToStep(
                                      Object.keys(groupedData).length + 1
                                    )
                                  }
                                >
                                  <div className="mt-2 wizard-form-card-header-text3">
                                    Billing Address Information
                                  </div>
                                </span>

                                <span
                                  class={
                                    Object.keys(groupedData).length > 0
                                      ? "step"
                                      : "step"
                                  }
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    goToStep(
                                      Object.keys(groupedData).length + 2
                                    )
                                  }
                                >
                                  <div className="mt-2 wizard-form-card-header-text3">
                                    Preview
                                  </div>
                                </span>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {/* {inputFormData.length > 0 && wizardTabData && <div className="wizard-wrapper">
                            <div className="wizard-container">
                              <div className="step-wrapper" onClick={() => goToStep(0)}>
                                <div className="step-circle">1</div>
                                <div className="tooltip">Entity Details</div>
                              </div>

                              <div className="wizard-bars step"></div>
                            
                              {wizardTabData && (() => {
                                let stepCounter = 1;

                                return Object.keys(wizardTabData).map((parentKey, parentIndex) =>
                                  parentKey !== "undefined"
                                    ? Object.keys(wizardTabData[parentKey]).map((formItem, formIndex) => {
                                      const currentStep = stepCounter;
                                      stepCounter++;

                                      return (
                                        <React.Fragment key={`child-${parentIndex}-${formIndex}`}>
                                          <div
                                            className="step-wrapper"
                                            onClick={() => goToStep(currentStep)}
                                          >
                                            <div className="step-circle">{currentStep +1}</div>
                                            <div className="tooltip">{toPascalCase(formItem)}</div>
                                          </div>

                                          <div className="wizard-bars step"></div>
                                        </React.Fragment>
                                      );
                                    })
                                    : null
                                );
                              })()}



                              {inputFormData.length > 0 && wizardTabData && (
                                <>
                                  <div className="step-wrapper" onClick={() =>
                                    goToStep(
                                      Object.keys(groupedData).length + 1
                                    )
                                  }>
                                    <div className="step-circle">{Object.keys(groupedData).length + 2}</div>
                                    <div className="tooltip">Billing Address Information</div>
                                  </div>

                                  <div className="wizard-bars step"></div>
                                </>
                              )}

                              {inputFormData.length > 0 && wizardTabData && (
                                <div className="step-wrapper" onClick={() =>
                                  goToStep(
                                    Object.keys(groupedData).length + 2
                                  )
                                }>
                                  <div className="step-circle">{Object.keys(groupedData).length + 3}</div>
                                  <div className="tooltip">Preview</div>
                                </div>
                              )}
                            </div>
                          </div>
                          } */}

                          <div className="wizard-main-content font-fam-for-all mt-4">
                            <div className="wizard-main-sub-content">
                              <form
                                className="form-wizard"
                              // onSubmit={handleSubmit(onSubmit)}
                              >
                                <div className="tab px-4 hover-card slide-in font-fam-for-all">
                                  <div
                                    className="wizard-content-sub-card-header my-3"
                                    id="header"
                                  >
                                    <div className="wizard-content-sub-card-text-frame">
                                      <div className="wizard-content-sub-card-text text-center">
                                        Entity Details
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex flex-column justify-content-center">
                                    <div className="input-fields-main ">
                                      <div className="flex w-full justify-content-center">
                                        <div
                                          className={
                                            state && (stateWithCounty.includes(state.stateFullDesc))
                                              ? "col-4"
                                              : "col-6"
                                          }
                                        >
                                          <div className=" justify-content-center dialog-form-field-space w-full">
                                            <div className="field">
                                              <label
                                                htmlFor=""
                                                className="form-label font-fam-for-all"
                                              >
                                                Select Entity Type
                                                <span className="form-field-mandatory">
                                                  *
                                                </span>
                                              </label>
                                              <span className="">
                                                <Controller
                                                  name="entity"
                                                  control={control}
                                                  render={({ field }) => (
                                                    <Dropdown
                                                      value={enityName}
                                                      options={entityTypes}
                                                      disabled={isFirstDraft}
                                                      onChange={(e) => {
                                                        setEntityErr("");
                                                        setEntityName(e.value);
                                                        setEntityDash(null);
                                                      }}
                                                      // appendTo="self"
                                                      // pt={{ panel: { style: { position: "fixed", zIndex: 100001 } } }}
                                                      optionLabel="entityShortName"
                                                      placeholder="Select Entity Type"
                                                      style={{
                                                        width: "100%",
                                                        height: "47px",
                                                      }}
                                                      filter
                                                      className="dialog-form-input-field-wizard"
                                                      required
                                                    />
                                                  )}
                                                />
                                              </span>
                                              <small
                                                id={`entity--error`}
                                                className="error-msg font-fam-for-all "
                                              >
                                                {entityErr}
                                              </small>
                                              {getFormErrorMessage("entity")}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={
                                            state && (stateWithCounty.includes(state.stateFullDesc))
                                              ? "col-4"
                                              : "col-6"
                                          }
                                        >
                                          <div className=" justify-content-center dialog-form-field-space w-full">
                                            <div className="field">
                                              <label
                                                htmlFor=""
                                                className="form-label font-fam-for-all"
                                              >
                                                State of Formation{" "}
                                                <span className="form-field-mandatory">
                                                  *
                                                </span>
                                              </label>
                                              <span className="">
                                                <Controller
                                                  name="state"
                                                  control={control}
                                                  render={({ field }) => (
                                                    <Dropdown
                                                      value={field.value}
                                                      id={field.name}
                                                      options={allState}
                                                      disabled={isFirstDraft}
                                                      onChange={(e) => {
                                                        field.onChange(e.value);
                                                        setStateErr("");
                                                        setState(e.value);
                                                        setStateDash(null);
                                                      }}
                                                      optionLabel="stateFullDesc"
                                                      style={{
                                                        width: "100%",
                                                        height: "47px",
                                                      }}
                                                      placeholder="Select State"
                                                      filter
                                                      className="dialog-form-input-field-wizard"
                                                      required
                                                    />
                                                  )}
                                                />
                                              </span>
                                              <small
                                                id={`state--error`}
                                                className="error-msg font-fam-for-all "
                                              >
                                                {stateErr}
                                              </small>
                                              {getFormErrorMessage("state")}
                                            </div>
                                          </div>
                                        </div>
                                        {state && (stateWithCounty.includes(state.stateFullDesc)) ? (
                                          <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                              <div className="field">
                                                <label
                                                  htmlFor=""
                                                  className="form-label font-fam-for-all"
                                                >
                                                  County
                                                  <span className="form-field-mandatory">
                                                    *
                                                  </span>
                                                </label>
                                                <span className="  ">
                                                  <Controller
                                                    name="county"
                                                    control={control}
                                                    render={({ field }) => (
                                                      <Dropdown
                                                        value={field.value}
                                                        id={field.name}
                                                        options={allCounty}
                                                        disabled={isFirstDraft}
                                                        onChange={(e) => {
                                                          field.onChange(
                                                            e.value
                                                          );
                                                          setCountyErr("");
                                                          setCounty(e.value);
                                                        }}
                                                        optionLabel="countyName"
                                                        style={{
                                                          width: "100%",
                                                          height: "47px",
                                                        }}
                                                        placeholder="Select County"
                                                        filter
                                                        className="dialog-form-input-field-wizard"
                                                      />
                                                    )}
                                                  />
                                                </span>
                                                <small
                                                  id={`county--error`}
                                                  className="error-msg font-fam-for-all "
                                                >
                                                  {countyErr}
                                                </small>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>

                                    {state && enityName && (
                                      <div
                                        className="wizard-content-sub-card-header my-3"
                                        id="header"
                                      >
                                        <div className="wizard-content-sub-card-text-frame">
                                          <div className="wizard-content-sub-card-text">
                                            Choose your preferred services from
                                            the options below
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {state && enityName ? (
                                      showSkeleton ? (
                                        servicesData.map(
                                          (serviceData, index) => {
                                            const { product } = serviceData;

                                            const isSelected = (
                                              selectedProducts[
                                              product.orderTypeMaster
                                              ] || []
                                            ).includes(product.displayName);

                                            return (
                                              <div
                                                key={index}
                                                className="wizard-card-checkbox-container hover-card slide-in"
                                              >
                                                <div className="wizard-card-checkbox-main pb-0">
                                                  <div className="wizard-card-checkbox-text-main">
                                                    <div className="wizard-card-checkbox-action-container">
                                                      <Checkbox
                                                        className="mt-2"
                                                        disabled={
                                                          serviceData?.product
                                                            ?.orderTypeMaster ===
                                                          1
                                                        }
                                                        checked={
                                                          isSelected ||
                                                          serviceData?.product
                                                            ?.orderTypeMaster ===
                                                          1
                                                        }
                                                        onChange={(e) =>
                                                          handleCheckedChange(
                                                            e,
                                                            serviceData
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    <div className="w-full grid">
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-11 p-3">
                                                        <div className="wizard-card-checkbox-text1 font-fam-for-all text-start">
                                                          <div className="flex align-items-center">
                                                            {
                                                              product.displayName
                                                            }
                                                            <button
                                                              className="ml-2 bg-transparent border-none cursor-pointer"
                                                              data-bs-toggle="tooltip"
                                                              data-bs-placement="right"
                                                              title={
                                                                product
                                                                  .productMaster
                                                                  .productAdditionalInfo
                                                              }
                                                            >
                                                              <i
                                                                className="pi pi-info-circle"
                                                                style={{
                                                                  fontSize:
                                                                    "1.2rem",
                                                                  color:
                                                                    "#007bff",
                                                                }}
                                                              ></i>
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="wizard-card-checkbox-text2 justify-content-end col-12 col-sm-12 col-md-12 col-lg-1 flex align-items-center">
                                                        {product.isCustomPriceApplicable
                                                          ? "Custom Price"
                                                          : product.isPriceIncludedInBaseProduct
                                                            ? "Included"
                                                            : `$${parseFloat(
                                                              product.unitPrice
                                                            ).toFixed(2)}`}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                      ) : (
                                        <>
                                          <DataTable
                                            value={items}
                                            inline
                                            emptyMessage="No Candidate Found"
                                            selectionMode="single"
                                            metaKeySelection={false}
                                          >
                                            <Column
                                              body={<Skeleton />}
                                              className="datatable-body-icon"
                                            ></Column>
                                          </DataTable>
                                        </>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                {Object.keys(groupedData).map((key, i) => (
                                  <div className="tab px-4" key={i}>
                                    <div
                                      className="wizard-content-sub-card-header my-3"
                                      id="header"
                                    >
                                      <div className="wizard-content-sub-card-text-frame">
                                        <div className="wizard-content-sub-card-text">
                                          {toPascalCase(key)}
                                        </div>
                                      </div>
                                    </div>

                                    <div>
                                      {key === "Organizer_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Organizer
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="  ">
                                                    <Controller
                                                      name="Organizer"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          name="Organizer"
                                                          id="Organizer"
                                                          value={organizerData}
                                                          options={allOrganizer}
                                                          onChange={(e) =>
                                                            setOrganizerData(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                  <small
                                                    id={`Organizer--error`}
                                                    className="error-msg font-fam-for-all"
                                                  ></small>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Organizer"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("ORGANIZER")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          <div>
                                            {organizerData && (
                                              <KeyPersonnelIndividualCard
                                                data={organizerData}
                                                getState={getState}
                                                type={"Organizer"}
                                              />
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div></div>
                                        </>
                                      )}

                                      {key === "Contact_Information" && (
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="grid">
                                            <div className="col-12">
                                              <div className="order-contact-card-container">
                                                <div className="order-contact-card-main">
                                                  <div className="order-contact-card-header-main">
                                                    <Avatar
                                                      label={commonGetInitials(
                                                        "vState Filings"
                                                      )}
                                                      icon="pi pi-user"
                                                      size="large"
                                                      shape="circle"
                                                      className="custom-avatar"
                                                    />

                                                    <div>
                                                      <div className="order-contact-card-header-text1">
                                                        vState Filings
                                                      </div>
                                                      <div className="order-contact-card-header-text2"></div>
                                                    </div>
                                                  </div>
                                                  <div className="card-devider">
                                                    {" "}
                                                  </div>
                                                  <div className="order-contact-card-text-main">
                                                    <div>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                      >
                                                        <g clip-path="url(#clip0_966_10329)">
                                                          <path
                                                            d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                            stroke="#175CD3"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                          />
                                                        </g>
                                                        <defs>
                                                          <clipPath id="clip0_966_10329">
                                                            <rect
                                                              width="20"
                                                              height="20"
                                                              fill="white"
                                                            />
                                                          </clipPath>
                                                        </defs>
                                                      </svg>
                                                    </div>
                                                    <div className="order-contact-card-text1">
                                                      866-638-3309
                                                    </div>
                                                  </div>

                                                  <div className="order-contact-card-text-main">
                                                    <div>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                      >
                                                        <path
                                                          d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                          stroke="#175CD3"
                                                          stroke-width="2"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                        />
                                                      </svg>
                                                    </div>
                                                    <div className="order-contact-card-text1">
                                                      orders@vstatefilings.com
                                                    </div>
                                                  </div>

                                                  <div className="order-contact-card-text-main">
                                                    <div>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                      >
                                                        <path
                                                          d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                          stroke="#175CD3"
                                                          stroke-width="1.66667"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                        />
                                                        <path
                                                          d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                          stroke="#175CD3"
                                                          stroke-width="1.66667"
                                                          stroke-linecap="round"
                                                          stroke-linejoin="round"
                                                        />
                                                      </svg>
                                                    </div>
                                                    <div className="order-contact-card-text1 text-wrap">
                                                      301 Mill Road,Suit
                                                      US,Hewlett,New York,11557{" "}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      {key === "Principal_Address" ? (
                                        <>
                                          <div className="grid w-full justify-content-start">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Principal Address
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="  ">
                                                    <Dropdown
                                                      name="Principal_Address"
                                                      id="Principal_Address"
                                                      placeholder="Select Principal Address"
                                                      value={location}
                                                      options={allLocation}
                                                      onChange={(e) =>
                                                        setLocation(e.value)
                                                      }
                                                      optionLabel="addressLine1"
                                                      style={{
                                                        width: "100%",
                                                        height: "47px",
                                                      }}
                                                      filter
                                                      className="dialog-form-input-field-wizard"
                                                      required
                                                    />
                                                  </span>
                                                  <small
                                                    id={`Principal_Address--error`}
                                                    className="error-msg font-fam-for-all"
                                                  ></small>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-5 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Principal Address"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  setVisible3(true)
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          <div>
                                            {location && (
                                              <PrincipalAddressCard
                                                data={location}
                                                getState={getState}
                                              />
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {key === "Incorporator_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Incorporator
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="  ">
                                                    <Controller
                                                      name="Incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          id="Incorporator"
                                                          placeholder="Select Incorporator"
                                                          value={incorporator}
                                                          options={
                                                            allInCorporator
                                                          }
                                                          onChange={(e) =>
                                                            setIncorporator(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                  <small
                                                    id={`Incorporator--error`}
                                                    className="error-msg font-fam-for-all"
                                                  ></small>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("INCORPORATOR")}>
                                                                                                                                                                          <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                                                                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                                                                  <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                                                              </svg>
                                                                                                                                                                              <div className='font-medium'>Add Incorporator</div>
                                                                                                                                                                          </div>
                                                                          
                                                                                                                                                                      </button> */}
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Incorporator"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal(
                                                    "INCORPORATOR"
                                                  )
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          <div>
                                            {incorporator && (
                                              <KeyPersonnelIndividualCard
                                                data={incorporator}
                                                getState={getState}
                                                type={"Incorporator"}
                                              />
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Director_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Director
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="  ">
                                                    <Controller
                                                      name="Director"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          id="Director"
                                                          placeholder="Select Director"
                                                          value={director}
                                                          options={allDirector}
                                                          onChange={(e) =>
                                                            setDirector(e.value)
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                  <small
                                                    id={`Director--error`}
                                                    className="error-msg font-fam-for-all"
                                                  ></small>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Director"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("DIRECTOR")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          <div>
                                            {director && (
                                              <KeyPersonnelIndividualCard
                                                data={director}
                                                getState={getState}
                                                type={"Director"}
                                              />
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Officer_Information" ||
                                        key === "Officer_Details" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Officer
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="  ">
                                                    <Controller
                                                      name="Officer"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          id="Officer"
                                                          placeholder="Select Officer"
                                                          value={officer}
                                                          options={allOfficer}
                                                          onChange={(e) =>
                                                            setOfficer(e.value)
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                  <small
                                                    id={`Officer--error`}
                                                    className="error-msg font-fam-for-all"
                                                  ></small>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("OFFICER")}>
                                                                                                                                                                          <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                                                                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                                                                  <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                                                              </svg>
                                                                                                                                                                              <div className='font-medium'>Add Officer</div>
                                                                                                                                                                          </div>
                                                                          
                                                                                                                                                                      </button> */}
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Officer"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("OFFICER")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          {key === "Officer_Information" && (
                                            <div>
                                              {officer && (
                                                <KeyPersonnelIndividualCard
                                                  data={officer}
                                                  getState={getState}
                                                  type={"Officer"}
                                                />
                                              )}
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Filer_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Filer
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="  ">
                                                    <Controller
                                                      name="Filer"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          id="Filer"
                                                          placeholder="Select Filer"
                                                          value={filer}
                                                          options={allFiler}
                                                          onChange={(e) =>
                                                            setFiler(e.value)
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                  <small
                                                    id={`Filer--error`}
                                                    className="error-msg font-fam-for-all"
                                                  ></small>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Filer"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("FILER")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          <div>
                                            {filer && (
                                              <KeyPersonnelIndividualCard
                                                data={filer}
                                                getState={getState}
                                                type={"Filer"}
                                              />
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "President_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select President
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="  ">
                                                    <Controller
                                                      name="President"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          id="President"
                                                          placeholder="Select President"
                                                          value={president}
                                                          options={allPresident}
                                                          onChange={(e) =>
                                                            setPresident(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                  <small
                                                    id={`President--error`}
                                                    className="error-msg font-fam-for-all"
                                                  ></small>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add President"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("PRESIDENT")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          <div>
                                            {president && (
                                              <KeyPersonnelIndividualCard
                                                data={president}
                                                getState={getState}
                                                type={"President"}
                                              />
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Treasurer_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Treasurer
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="  ">
                                                    <Controller
                                                      name="Treasurer"
                                                      placeholder="Select Treasurer"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          id="Treasurer"
                                                          value={treasurer}
                                                          options={allTreasurer}
                                                          onChange={(e) =>
                                                            setTreasurer(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                  <small
                                                    id={`Treasurer--error`}
                                                    className="error-msg font-fam-for-all"
                                                  ></small>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Treasurer"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("TREASURER")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          <div>
                                            {treasurer && (
                                              <KeyPersonnelIndividualCard
                                                data={treasurer}
                                                getState={getState}
                                                type={"Treasurer"}
                                              />
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Secretary_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Secretary
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="  ">
                                                    <Controller
                                                      name="Secretary"
                                                      placeholder="Select Secretary"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          id="Secretary"
                                                          value={secretary}
                                                          options={allSecretary}
                                                          onChange={(e) =>
                                                            setSecretary(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                  <small
                                                    id={`Secretary--error`}
                                                    className="error-msg font-fam-for-all"
                                                  ></small>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Secretary"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("SECRETARY")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          <div>
                                            {secretary && (
                                              <KeyPersonnelIndividualCard
                                                data={secretary}
                                                getState={getState}
                                                type={"Secretary"}
                                              />
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Registered_Agent" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                                              <div className="flex justify-content-start mb-3 ml-1">
                                                <Checkbox
                                                  checked={IsCheckedAgent}
                                                  onChange={(e) =>
                                                    handleRegisteredAgent(e)
                                                  }
                                                  inputProps={{
                                                    "aria-label": "controlled",
                                                  }}
                                                />

                                                <label
                                                  htmlFor="binary"
                                                  className="font-fam-for-all ml-2 srch-cand-text"
                                                >
                                                  Select vState as your
                                                  Registered Agent
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {key === "Registered_Agent" ? (
                                        <div>
                                          {registeredAgent &&
                                            IsCheckedAgent && (
                                              <RegisteredAgentCard
                                                data={registeredAgent}
                                                getState={getState}
                                                type={"Registered Agent"}
                                              />
                                            )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    {groupedData[key].mode &&
                                      groupedData[key].mode.repeat === true ? (
                                      <div>
                                        <div className="">
                                          {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0">
                                                                                        <h2 className="entity-header-strapi">
                                                                                            {toPascalCase(
                                                                                                groupedData[key].form[0].schema
                                                                                                    .displayName
                                                                                            )}
                                                                                        </h2>
                                                                                        {groupedData[key].mode &&
                                                                                            groupedData[key].mode.repeat ===
                                                                                            true}
                                                                                    </div> */}

                                          {key ===
                                            "Member_Or_Manager_Details" && (
                                              <>
                                                <div className="flex justify-content-end mb-4">
                                                  <Button
                                                    type="button"
                                                    icon="pi pi-plus"
                                                    style={{
                                                      fontSize: "14px",
                                                    }}
                                                    label="Add Member Or Manager"
                                                    className=" vstate-button font-fam-for-all text-center mt-2"
                                                    onClick={() =>
                                                      handleKeyPersonal(
                                                        "MEMBER_OR_MANAGER",
                                                        `member_or_manger_details-${formCounts[key] + 1 || 1
                                                        }`
                                                      )
                                                    }
                                                  ></Button>
                                                </div>
                                                <KeyPersonnelOrderDataTable
                                                  data={allMemberOrManager}
                                                  setterFunction={
                                                    setAllMemberOrManager
                                                  }
                                                  responseValue={
                                                    keyPersonnelValue
                                                  }
                                                  keyValue={key}
                                                  formCounts={formCounts}
                                                  setFormCounts={setFormCounts}
                                                />
                                              </>
                                            )}

                                          {/* {key ===
                                                                                        "Member_Or_Manager_Details" && <>
                                                                                            <KeyPersonnelDataTable data={allMemberOrManager} handleRemoveRepeatForm={handleRemoveRepeatForm} keyValue={key} formCounts={formCounts} />
                                                                                        </>
                                                                                    } */}

                                          {Array.from({
                                            length: formCounts[key] - 1,
                                          }).map((_, l) => (
                                            <div className="mb-3 vstate-visbiliy-hidden">
                                              <Accordion activeIndex={0}>
                                                <AccordionTab
                                                  header={`${toPascalCase(
                                                    groupedData[key].form[0]
                                                      .schema.displayName
                                                  )}-${l + 1}`}
                                                >
                                                  {key ===
                                                    "ucc_Debtor_Details" ? (
                                                    <>
                                                      <div className="grid w-full justify-content-between">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                          <div className=" justify-content-center dialog-form-field-space w-full">
                                                            <div className="field">
                                                              <label
                                                                htmlFor=""
                                                                className="form-label font-fam-for-all"
                                                              >
                                                                Select Debtor
                                                                <span className="form-field-mandatory"></span>
                                                              </label>
                                                              <span className="  ">
                                                                <Controller
                                                                  name={`ucc_Debtor_Details_Dropdown-${l}`}
                                                                  control={
                                                                    control
                                                                  }
                                                                  render={({
                                                                    field,
                                                                  }) => (
                                                                    <Dropdown
                                                                      // placeholder="Select Debtor"
                                                                      value={
                                                                        field.value
                                                                      }
                                                                      options={
                                                                        aallDebtor
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        field.onChange(
                                                                          e.value
                                                                        );
                                                                        setDebtorData(
                                                                          e.value
                                                                        );
                                                                        handleDebtorChange(
                                                                          e.value,
                                                                          l
                                                                        );
                                                                      }}
                                                                      optionLabel="keyPersonnelName"
                                                                      placeholder="Select Debtor"
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        height:
                                                                          "47px",
                                                                      }}
                                                                      filter
                                                                      className="dialog-form-input-field-wizard"
                                                                      required
                                                                    />
                                                                  )}
                                                                />
                                                              </span>

                                                              <small
                                                                id={`ucc_Debtor_Details-${l + 1
                                                                  }--error`}
                                                                className="error-msg font-fam-for-all"
                                                              ></small>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                          <Button
                                                            type="button"
                                                            icon="pi pi-plus"
                                                            style={{
                                                              fontSize: "14px",
                                                            }}
                                                            label="Add Debtor"
                                                            className=" vstate-button font-fam-for-all text-center mt-4"
                                                            onClick={() =>
                                                              handleKeyPersonal(
                                                                "DEBTOR"
                                                              )
                                                            }
                                                          ></Button>
                                                        </div>
                                                      </div>
                                                      <div>
                                                        {getValues(
                                                          `ucc_Debtor_Details_Dropdown-${l}`
                                                        ) && (
                                                            <KeyPersonnelIndividualCard
                                                              data={getValues(
                                                                `ucc_Debtor_Details_Dropdown-${l}`
                                                              )}
                                                              getState={getState}
                                                              type={"Debtor"}
                                                            />
                                                          )}
                                                      </div>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}

                                                  {key ===
                                                    "Member_Or_Manager_Details" ? (
                                                    <>
                                                      <div className="grid w-full justify-content-between">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                                                          <div className="justify-content-center dialog-form-field-space w-full">
                                                            <div className="field">
                                                              <label
                                                                htmlFor=""
                                                                className="form-label font-fam-for-all"
                                                              >
                                                                Member Or
                                                                Manager
                                                                <span className="form-field-mandatory">
                                                                  *
                                                                </span>
                                                              </label>
                                                              <div className="flex gap-4">
                                                                <Controller
                                                                  name={`member_or_manger-${l}`}
                                                                  defaultValue="Member"
                                                                  control={
                                                                    control
                                                                  }
                                                                  render={({
                                                                    field,
                                                                  }) => (
                                                                    <>
                                                                      <div className="flex items-center">
                                                                        <RadioButton
                                                                          inputId={`member-${l}`}
                                                                          name={`member_or_manger-${l}`}
                                                                          value="Member"
                                                                          checked={
                                                                            field.value ===
                                                                            "Member"
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            field.onChange(
                                                                              e.value
                                                                            );
                                                                            handleMemberOrManagerType(
                                                                              e.value,
                                                                              l
                                                                            );
                                                                          }}
                                                                          className="mr-2"
                                                                        />
                                                                        <label
                                                                          htmlFor={`member-${l}`}
                                                                          className="cursor-pointer"
                                                                        >
                                                                          Member
                                                                        </label>
                                                                      </div>
                                                                      <div className="flex items-center">
                                                                        <RadioButton
                                                                          inputId={`manager-${l}`}
                                                                          name={`member_or_manger-${l}`}
                                                                          value="Manager"
                                                                          checked={
                                                                            field.value ===
                                                                            "Manager"
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            field.onChange(
                                                                              e.value
                                                                            );
                                                                            handleMemberOrManagerType(
                                                                              e.value,
                                                                              l
                                                                            );
                                                                          }}
                                                                          className="mr-2"
                                                                        />
                                                                        <label
                                                                          htmlFor={`manager-${l}`}
                                                                          className="cursor-pointer"
                                                                        >
                                                                          Manager
                                                                        </label>
                                                                      </div>
                                                                    </>
                                                                  )}
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                          <div className=" justify-content-center dialog-form-field-space w-full">
                                                            <div className="field">
                                                              <label
                                                                htmlFor=""
                                                                className="form-label font-fam-for-all"
                                                              >
                                                                Select Member Or
                                                                Manager
                                                                <span className="form-field-mandatory">
                                                                  *
                                                                </span>
                                                              </label>
                                                              <span className="  ">
                                                                <Controller
                                                                  name={`member_or_manger_details-${l}`}
                                                                  control={
                                                                    control
                                                                  }
                                                                  render={({
                                                                    field,
                                                                  }) => (
                                                                    <Dropdown
                                                                      placeholder="Select Member Or Manager"
                                                                      id={`Member_Or_Manager_Details-${l + 1
                                                                        }`}
                                                                      value={
                                                                        field.value
                                                                      }
                                                                      options={
                                                                        allMember
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        field.onChange(
                                                                          e.value
                                                                        );
                                                                        handleMemberOrManager(
                                                                          e.value,
                                                                          l
                                                                        );
                                                                      }}
                                                                      optionLabel="keyPersonnelName"
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        height:
                                                                          "47px",
                                                                      }}
                                                                      filter
                                                                      className="dialog-form-input-field-wizard"
                                                                      required
                                                                    />
                                                                  )}
                                                                />
                                                              </span>
                                                              <small
                                                                id={`Member_Or_Manager_Details-${l + 1
                                                                  }--error`}
                                                                className="error-msg font-fam-for-all"
                                                              ></small>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 justify-content-start align-items-center mt-3">
                                                          <Button
                                                            type="button"
                                                            icon="pi pi-plus"
                                                            style={{
                                                              fontSize: "14px",
                                                            }}
                                                            label="Add Member Or Manager"
                                                            className=" vstate-button font-fam-for-all text-center mt-4"
                                                            onClick={() =>
                                                              handleKeyPersonal(
                                                                "MEMBER_OR_MANAGER",
                                                                `member_or_manger_details-${l}`
                                                              )
                                                            }
                                                          ></Button>
                                                        </div>
                                                      </div>
                                                      <div>
                                                        {getValues(
                                                          `member_or_manger_details-${l}`
                                                        ) && (
                                                            <KeyPersonnelIndividualCard
                                                              data={getValues(
                                                                `member_or_manger_details-${l}`
                                                              )}
                                                              getState={getState}
                                                              type={getValues(
                                                                `member_or_manger-${l}`
                                                              )}
                                                            />
                                                          )}
                                                      </div>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}

                                                  {key ===
                                                    "Company_Applicant_Information" ? (
                                                    <>
                                                      <div className="grid w-full justify-content-between">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                          <div className=" justify-content-center dialog-form-field-space w-full">
                                                            <div className="field">
                                                              <label
                                                                htmlFor=""
                                                                className="form-label font-fam-for-all"
                                                              >
                                                                Select Company
                                                                Applicant
                                                                <span className="form-field-mandatory"></span>
                                                              </label>
                                                              <span className="  ">
                                                                <Controller
                                                                  name={`company_applicant_item_${l}`}
                                                                  control={
                                                                    control
                                                                  }
                                                                  render={({
                                                                    field,
                                                                  }) => (
                                                                    <Dropdown
                                                                      value={
                                                                        field.value
                                                                      }
                                                                      options={
                                                                        allCompanyApplicant
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        field.onChange(
                                                                          e.value
                                                                        );

                                                                        handleCompanyApplicantChange(
                                                                          e.value,
                                                                          l
                                                                        );
                                                                      }}
                                                                      optionLabel="firstName"
                                                                      placeholder="Select Company Applicant"
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        height:
                                                                          "47px",
                                                                      }}
                                                                      filter
                                                                      className="dialog-form-input-field-wizard"
                                                                      required
                                                                    />
                                                                  )}
                                                                />
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                          {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                                                                                  <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                                                                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                                                                          <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                                                                      </svg>
                                                                                                                                                                                      <div className='font-medium'>Add Debtor</div>
                                                                                                                                                                                  </div>
                                                              
                                                                                                                                                                              </button> */}
                                                          <Button
                                                            type="button"
                                                            icon="pi pi-plus"
                                                            style={{
                                                              fontSize: "14px",
                                                            }}
                                                            label="Add Company Applicant"
                                                            className=" vstate-button font-fam-for-all text-center mt-4"
                                                            onClick={() =>
                                                              handleBOIKeyPersonal(
                                                                "COMPANY APPLICANT"
                                                              )
                                                            }
                                                          ></Button>
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}

                                                  {key ===
                                                    "ucc_Secured_Party_Details" ? (
                                                    <div className="grid w-full justify-content-between">
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                        <div className=" justify-content-center dialog-form-field-space w-full">
                                                          <div className="field">
                                                            <label
                                                              htmlFor=""
                                                              className="form-label font-fam-for-all"
                                                            >
                                                              Select Secured
                                                              Party
                                                              <span className="form-field-mandatory"></span>
                                                            </label>
                                                            <span className="  ">
                                                              <Controller
                                                                name={`uccAmend_Additional_secured_party_Details-${l}`}
                                                                control={
                                                                  control
                                                                }
                                                                render={({
                                                                  field,
                                                                }) => (
                                                                  <Dropdown
                                                                    value={
                                                                      field.value
                                                                    }
                                                                    options={
                                                                      allSecuredParty
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      field.onChange(
                                                                        e.value
                                                                      );

                                                                      handleAdditionalSecuredPartyChange1(
                                                                        e.value,
                                                                        l
                                                                      );
                                                                    }}
                                                                    optionLabel="keyPersonnelName"
                                                                    placeholder="Select Secured Party"
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      height:
                                                                        "47px",
                                                                    }}
                                                                    filter
                                                                    className="dialog-form-input-field-wizard"
                                                                    required
                                                                  />
                                                                )}
                                                              />
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                        <Button
                                                          type="button"
                                                          icon="pi pi-plus"
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                          label="Add  Secured Party"
                                                          className=" vstate-button font-fam-for-all text-center mt-4"
                                                          onClick={() =>
                                                            handleKeyPersonal(
                                                              "SECUERED PARTY"
                                                            )
                                                          }
                                                        ></Button>
                                                      </div>
                                                      <div>
                                                        {getValues(
                                                          `uccAmend_Additional_secured_party_Details-${l}`
                                                        ) && (
                                                            <KeyPersonnelIndividualCard
                                                              data={getValues(
                                                                `uccAmend_Additional_secured_party_Details-${l}`
                                                              )}
                                                              getState={getState}
                                                              type={
                                                                "Secured Party"
                                                              }
                                                            />
                                                          )}
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    ""
                                                  )}

                                                  {groupedData[key].form.map(
                                                    (value, j) => (
                                                      <>
                                                        <div>
                                                          {getValues(
                                                            "uccAmend_What_are_we_amending"
                                                          ) ===
                                                            "ADDITIONAL DEBTOR" &&
                                                            value.apiId ===
                                                            "ucc-amnd-additional-debtor" ? (
                                                            <></>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {getValues(
                                                            "COC_What_Are_We_Changing"
                                                          ) === "Address" &&
                                                            value.apiId ===
                                                            "address" ? (
                                                            <div className="vstate-card p-4 mb-3">
                                                              <div
                                                                className="grid"
                                                                key={l}
                                                              >
                                                                {Object.keys(
                                                                  value.schema
                                                                    .attributes
                                                                ).map(
                                                                  (val, k) => {
                                                                    const content =
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ].type !==
                                                                        undefined
                                                                        ? renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                          val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                        : null;

                                                                    return content ? (
                                                                      <div
                                                                        className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                        key={k}
                                                                      >
                                                                        {
                                                                          content
                                                                        }
                                                                      </div>
                                                                    ) : null;
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {key ===
                                                            "Member_Or_Manager_Details" &&
                                                            value.apiId !==
                                                            "address" &&
                                                            value.apiId !==
                                                            "boi-beneficial-owwner-details" &&
                                                            value.apiId !==
                                                            "boi-except-entity-form" &&
                                                            value.apiId !==
                                                            "boi-beneficial-owner-form" &&
                                                            value.apiId !==
                                                            "ucc-amnd-additional-debtor" &&
                                                            value.apiId !==
                                                            "boica-fin-cen-id" &&
                                                            value.apiId !==
                                                            "company-applicant-det" &&
                                                            value.apiId !==
                                                            "cad-address" &&
                                                            value.apiId !==
                                                            "boica-upload-document" ? (
                                                            <div
                                                              className="vstate-card p-4 mb-3"
                                                              style={
                                                                keyPersonalMemberData.includes(
                                                                  key
                                                                )
                                                                  ? {
                                                                    display:
                                                                      "none",
                                                                  }
                                                                  : {}
                                                              }
                                                            >
                                                              <div
                                                                className="grid"
                                                                key={l}
                                                              >
                                                                {Object.keys(
                                                                  value.schema
                                                                    .attributes
                                                                ).map(
                                                                  (val, k) => {
                                                                    const content =
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ].type !==
                                                                        undefined
                                                                        ? renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                          val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                        : null;

                                                                    return content ? (
                                                                      <div
                                                                        className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                        key={k}
                                                                      >
                                                                        {
                                                                          content
                                                                        }
                                                                      </div>
                                                                    ) : null;
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {key ===
                                                            "Member_Or_Manager_Details" &&
                                                            value.apiId ===
                                                            "address" &&
                                                            value.apiId !==
                                                            "boi-beneficial-owwner-details" &&
                                                            value.apiId !==
                                                            "boi-except-entity-form" &&
                                                            value.apiId !==
                                                            "boi-beneficial-owner-form" &&
                                                            value.apiId !==
                                                            "company-applicant-det" ? (
                                                            <div
                                                              className="vstate-card p-4 mb-3"
                                                              style={
                                                                keyPersonalMemberData.includes(
                                                                  key
                                                                )
                                                                  ? {
                                                                    display:
                                                                      "none",
                                                                  }
                                                                  : {}
                                                              }
                                                            >
                                                              <div
                                                                className="grid"
                                                                key={l}
                                                              >
                                                                {Object.keys(
                                                                  value.schema
                                                                    .attributes
                                                                ).map(
                                                                  (val, k) => {
                                                                    const content =
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ].type !==
                                                                        undefined
                                                                        ? renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                          val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                        : null;

                                                                    return content ? (
                                                                      <div
                                                                        className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                        key={k}
                                                                      >
                                                                        {
                                                                          content
                                                                        }
                                                                      </div>
                                                                    ) : null;
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {key !==
                                                            "Member_Or_Manager_Details" &&
                                                            getValues(
                                                              `boi_Exempt_Entity-${l}`
                                                            ) === undefined &&
                                                            value.apiId !==
                                                            "address" &&
                                                            value.apiId !==
                                                            "boi-beneficial-owwner-details" &&
                                                            value.apiId !==
                                                            "boi-except-entity-form" &&
                                                            value.apiId !==
                                                            "boi-beneficial-owner-form" &&
                                                            value.apiId !==
                                                            "ucc-amnd-additional-debtor" &&
                                                            value.apiId !==
                                                            "boica-fin-cen-id" &&
                                                            value.apiId !==
                                                            "company-applicant-det" &&
                                                            value.apiId !==
                                                            "cad-address" &&
                                                            value.apiId !==
                                                            "boica-upload-document" ? (
                                                            <div className="vstate-card p-4 mb-3">
                                                              <div
                                                                className="grid"
                                                                key={l}
                                                              >
                                                                {Object.keys(
                                                                  value.schema
                                                                    .attributes
                                                                ).map(
                                                                  (val, k) => {
                                                                    const content =
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ].type !==
                                                                        undefined
                                                                        ? renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                          val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                        : null;

                                                                    return content ? (
                                                                      <div
                                                                        className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                        key={k}
                                                                      >
                                                                        {
                                                                          content
                                                                        }
                                                                      </div>
                                                                    ) : null;
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {key !==
                                                            "Member_Or_Manager_Details" &&
                                                            getValues(
                                                              `boi_Exempt_Entity-${l}`
                                                            ) === undefined &&
                                                            value.apiId ===
                                                            "address" &&
                                                            value.apiId !==
                                                            "boi-beneficial-owwner-details" &&
                                                            value.apiId !==
                                                            "boi-except-entity-form" &&
                                                            value.apiId !==
                                                            "boi-beneficial-owner-form" &&
                                                            value.apiId !==
                                                            "company-applicant-det" ? (
                                                            <div className="vstate-card p-4 mb-3">
                                                              <div
                                                                className="grid"
                                                                key={l}
                                                              >
                                                                {Object.keys(
                                                                  value.schema
                                                                    .attributes
                                                                ).map(
                                                                  (val, k) => {
                                                                    const content =
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ].type !==
                                                                        undefined
                                                                        ? renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                          val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                        : null;

                                                                    return content ? (
                                                                      <div
                                                                        className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                        key={k}
                                                                      >
                                                                        {
                                                                          content
                                                                        }
                                                                      </div>
                                                                    ) : null;
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {value.apiId ===
                                                            "beneficial-owner-info" ? (
                                                            <>
                                                              <div className="grid w-full justify-content-between">
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                                  <div className=" justify-content-center dialog-form-field-space w-full">
                                                                    <div className="field">
                                                                      <label
                                                                        htmlFor=""
                                                                        className="form-label font-fam-for-all"
                                                                      >
                                                                        Select
                                                                        Beneficial
                                                                        Owner
                                                                        <span className="form-field-mandatory"></span>
                                                                      </label>
                                                                      <span className="  ">
                                                                        <Controller
                                                                          name={`ucc_Beneficial_Dropdown-${l}`}
                                                                          control={
                                                                            control
                                                                          }
                                                                          render={({
                                                                            field,
                                                                          }) => (
                                                                            <Dropdown
                                                                              value={
                                                                                field.value
                                                                              }
                                                                              options={
                                                                                allBeneficialOwner
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                field.onChange(
                                                                                  e.value
                                                                                );

                                                                                handleBeneficialOwnerChange(
                                                                                  e.value,
                                                                                  l
                                                                                );
                                                                              }}
                                                                              optionLabel="firstName"
                                                                              placeholder="Select Beneficial Owner"
                                                                              style={{
                                                                                width:
                                                                                  "100%",
                                                                                height:
                                                                                  "47px",
                                                                              }}
                                                                              filter
                                                                              className="dialog-form-input-field-wizard"
                                                                              required
                                                                            />
                                                                          )}
                                                                        />
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                                  <Button
                                                                    type="button"
                                                                    icon="pi pi-plus"
                                                                    style={{
                                                                      fontSize:
                                                                        "14px",
                                                                    }}
                                                                    label="Add Beneficial Owner"
                                                                    className=" vstate-button font-fam-for-all text-center mt-4"
                                                                    onClick={() =>
                                                                      handleBOIKeyPersonal(
                                                                        "BENEFICIAL OWNER"
                                                                      )
                                                                    }
                                                                  ></Button>
                                                                </div>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {value.apiId ===
                                                            "beneficial-owner-info" &&
                                                            value.apiId !==
                                                            "upload-documents" &&
                                                            value.apiId !==
                                                            "boi-except-entity-form" &&
                                                            value.apiId !==
                                                            "beneficial-owner-details" &&
                                                            value.apiId !==
                                                            "address" ? (
                                                            <div className="vstate-card p-4 mb-3">
                                                              <div
                                                                className="grid"
                                                                key={l}
                                                              >
                                                                {Object.keys(
                                                                  value.schema
                                                                    .attributes
                                                                ).map(
                                                                  (val, k) => {
                                                                    const content =
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ].type !==
                                                                        undefined
                                                                        ? renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                          val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                        : null;

                                                                    return content ? (
                                                                      <div
                                                                        className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                        key={k}
                                                                      >
                                                                        {
                                                                          content
                                                                        }
                                                                      </div>
                                                                    ) : null;
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {getValues(
                                                            `boica_Have_a_FinCEN_ID-${l}`
                                                          ) === undefined &&
                                                            (value.apiId ===
                                                              "boica-fin-cen-id" ||
                                                              value.apiId ===
                                                              "company-applicant-det" ||
                                                              value.apiId ===
                                                              "cad-address" ||
                                                              value.apiId ===
                                                              "boica-upload-document") ? (
                                                            <div className="vstate-card p-4 mb-3">
                                                              <div
                                                                className="grid"
                                                                key={l}
                                                              >
                                                                {Object.keys(
                                                                  value.schema
                                                                    .attributes
                                                                ).map(
                                                                  (val, k) => {
                                                                    const content =
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ].type !==
                                                                        undefined
                                                                        ? renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                          val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                        : null;

                                                                    return content ? (
                                                                      <div
                                                                        className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                        key={k}
                                                                      >
                                                                        {
                                                                          content
                                                                        }
                                                                      </div>
                                                                    ) : null;
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}
                                                          {getValues(
                                                            `boica_Have_a_FinCEN_ID-${l}`
                                                          ) === false &&
                                                            (value.apiId ===
                                                              "boica-fin-cen-id" ||
                                                              value.apiId ===
                                                              "company-applicant-det" ||
                                                              value.apiId ===
                                                              "cad-address" ||
                                                              value.apiId ===
                                                              "boica-upload-document") ? (
                                                            <div className="vstate-card p-4 mb-3">
                                                              <div
                                                                className="grid"
                                                                key={l}
                                                              >
                                                                {Object.keys(
                                                                  value.schema
                                                                    .attributes
                                                                ).map(
                                                                  (val, k) => {
                                                                    const content =
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ].type !==
                                                                        undefined
                                                                        ? renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                          val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                        : null;

                                                                    return content ? (
                                                                      <div
                                                                        className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                        key={k}
                                                                      >
                                                                        {
                                                                          content
                                                                        }
                                                                      </div>
                                                                    ) : null;
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {getValues(
                                                            `boica_Have_a_FinCEN_ID-${l}`
                                                          ) === true &&
                                                            (value.apiId ===
                                                              "boica-fin-cen-id" ||
                                                              value.apiId ===
                                                              "company-applicant-det") ? (
                                                            <div className="vstate-card p-4 mb-3">
                                                              <div
                                                                className="grid"
                                                                key={l}
                                                              >
                                                                {Object.keys(
                                                                  value.schema
                                                                    .attributes
                                                                ).map(
                                                                  (val, k) => {
                                                                    const content =
                                                                      value
                                                                        .schema
                                                                        .attributes[
                                                                        val
                                                                      ].type !==
                                                                        undefined
                                                                        ? renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                          val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                        : null;

                                                                    return content ? (
                                                                      <div
                                                                        className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                        key={k}
                                                                      >
                                                                        {
                                                                          content
                                                                        }
                                                                      </div>
                                                                    ) : null;
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {/* boi-beneficial-owwner-details for Exempt Entity true */}

                                                          {key !==
                                                            "Member_Or_Manager_Details" &&
                                                            getValues(
                                                              `boi_Exempt_Entity-${l}`
                                                            ) === false &&
                                                            getValues(
                                                              `boi_Have_a_FinCEN_ID-${l}`
                                                            ) === false &&
                                                            (value.apiId ===
                                                              "beneficial-owner-details" ||
                                                              value.apiId ===
                                                              "upload-documents" ||
                                                              value.apiId ===
                                                              "address" ||
                                                              value.apiId ===
                                                              "fin-cen-id-details") ? (
                                                            <>
                                                              <div className="vstate-card p-4 mb-3">
                                                                <div
                                                                  className="grid"
                                                                  key={j}
                                                                >
                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 pb-0">
                                                                    <h2 className="entity-header-strapi">
                                                                      {toPascalCase(
                                                                        value
                                                                          .schema
                                                                          .displayName
                                                                      )}
                                                                    </h2>
                                                                  </div>

                                                                  {Object.keys(
                                                                    value.schema
                                                                      .attributes
                                                                  ).map(
                                                                    (
                                                                      val,
                                                                      k
                                                                    ) => (
                                                                      <div
                                                                        className={`col-12 col-sm-12 col-md-12 col-lg-6 dummy ${!renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                          val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                            ? "hidden"
                                                                            : ""
                                                                          }`}
                                                                        key={k}
                                                                      >
                                                                        {value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ]
                                                                          .type !==
                                                                          undefined
                                                                          ? renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                          : ""}
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {getValues(
                                                            `boi_Exempt_Entity-${l}`
                                                          ) === false &&
                                                            getValues(
                                                              `boi_Have_a_FinCEN_ID-${l}`
                                                            ) === true &&
                                                            (value.apiId ===
                                                              "beneficial-owner-details" ||
                                                              value.apiId ===
                                                              "fin-cen-id-details") ? (
                                                            <>
                                                              <div className="vstate-card p-4 mb-3">
                                                                <div
                                                                  className="grid"
                                                                  key={j}
                                                                >
                                                                  <div className="col-12 pb-0">
                                                                    <h2 className="entity-header-strapi">
                                                                      {toPascalCase(
                                                                        value
                                                                          .schema
                                                                          .displayName
                                                                      )}
                                                                    </h2>
                                                                  </div>

                                                                  {Object.keys(
                                                                    value.schema
                                                                      .attributes
                                                                  ).map(
                                                                    (
                                                                      val,
                                                                      k
                                                                    ) => (
                                                                      <div
                                                                        className={`col-12 col-sm-12 col-md-12 col-lg-6 dummy ${!renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                          val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                            ? "hidden"
                                                                            : ""
                                                                          }`}
                                                                        key={k}
                                                                      >
                                                                        {value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ]
                                                                          .type !==
                                                                          undefined
                                                                          ? renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                          : ""}
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {/* boi-beneficial-owwner-details for Exempt Entity false */}
                                                          {getValues(
                                                            `boi_Exempt_Entity-${l}`
                                                          ) &&
                                                            value.apiId ===
                                                            "boi-except-entity-form" ? (
                                                            <>
                                                              <div className="vstate-card p-4 mb-3">
                                                                <div
                                                                  className="grid"
                                                                  key={j}
                                                                >
                                                                  <div className="col-12 pb-0">
                                                                    <h2 className="entity-header-strapi">
                                                                      {toPascalCase(
                                                                        value
                                                                          .schema
                                                                          .displayName
                                                                      )}
                                                                    </h2>
                                                                  </div>
                                                                  {Object.keys(
                                                                    value.schema
                                                                      .attributes
                                                                  ).map(
                                                                    (
                                                                      val,
                                                                      k
                                                                    ) => (
                                                                      <div
                                                                        className={`col-12 col-sm-12 col-md-12 col-lg-6 dummy ${!renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                          val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                            ? "hidden"
                                                                            : ""
                                                                          }`}
                                                                        key={k}
                                                                      >
                                                                        {value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ]
                                                                          .type !==
                                                                          undefined
                                                                          ? renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                          : ""}
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {getValues(
                                                            "uccAmend_What_are_we_amending"
                                                          ) ===
                                                            "ADDITIONAL DEBTOR" &&
                                                            value.apiId ===
                                                            "ucc-amnd-additional-debtor" ? (
                                                            <>
                                                              <div className="vstate-card p-4 mb-3">
                                                                <div
                                                                  className="grid"
                                                                  key={j}
                                                                >
                                                                  <div className="col-12 pb-0">
                                                                    <h2 className="entity-header-strapi">
                                                                      {toPascalCase(
                                                                        value
                                                                          .schema
                                                                          .displayName
                                                                      )}
                                                                    </h2>
                                                                  </div>
                                                                  <div className="grid w-full justify-content-between">
                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                                      <div className=" justify-content-center dialog-form-field-space w-full">
                                                                        <div className="field">
                                                                          <label
                                                                            htmlFor=""
                                                                            className="form-label font-fam-for-all"
                                                                          >
                                                                            Select
                                                                            Additional
                                                                            Debtor
                                                                            <span className="form-field-mandatory"></span>
                                                                          </label>
                                                                          <span className="  ">
                                                                            <Controller
                                                                              name={`uccAmend_Additional_Debtor_Details-${l}`}
                                                                              control={
                                                                                control
                                                                              }
                                                                              render={({
                                                                                field,
                                                                              }) => (
                                                                                <Dropdown
                                                                                  value={
                                                                                    field.value
                                                                                  }
                                                                                  options={
                                                                                    aallDebtor
                                                                                  }
                                                                                  onChange={(
                                                                                    e
                                                                                  ) => {
                                                                                    field.onChange(
                                                                                      e.value
                                                                                    );

                                                                                    handleAdditionalDebtorChange(
                                                                                      e.value,
                                                                                      l
                                                                                    );
                                                                                  }}
                                                                                  optionLabel="keyPersonnelName"
                                                                                  placeholder="Select Debtor"
                                                                                  style={{
                                                                                    width:
                                                                                      "100%",
                                                                                    height:
                                                                                      "47px",
                                                                                  }}
                                                                                  filter
                                                                                  className="dialog-form-input-field-wizard"
                                                                                  required
                                                                                />
                                                                              )}
                                                                            />
                                                                          </span>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                                      {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                                                                                                                  <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                                                                                                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                                                                                                          <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                                                                                                      </svg>
                                                                                                                                                                                                                      <div className='font-medium'>Add Additional Debtor</div>
                                                                                                                                                                                                                  </div>
                                                              
                                                                                                                                                                                                              </button> */}
                                                                      <Button
                                                                        type="button"
                                                                        icon="pi pi-plus"
                                                                        style={{
                                                                          fontSize:
                                                                            "14px",
                                                                        }}
                                                                        label="Add Additionaal Debtor"
                                                                        className=" vstate-button font-fam-for-all text-center mt-4"
                                                                        onClick={() =>
                                                                          handleKeyPersonal(
                                                                            "DEBTOR"
                                                                          )
                                                                        }
                                                                      ></Button>
                                                                    </div>
                                                                  </div>
                                                                  {Object.keys(
                                                                    value.schema
                                                                      .attributes
                                                                  ).map(
                                                                    (
                                                                      val,
                                                                      k
                                                                    ) => (
                                                                      <div
                                                                        className={`col-12 col-sm-12 col-md-12 col-lg-6 dummy ${!renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                          val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                            ? "hidden"
                                                                            : ""
                                                                          }`}
                                                                        style={
                                                                          keyPersonalMemberData.includes(
                                                                            value?.apiId
                                                                          )
                                                                            ? {
                                                                              display:
                                                                                "none",
                                                                            }
                                                                            : {}
                                                                        }
                                                                        key={k}
                                                                      >
                                                                        {value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ]
                                                                          .type !==
                                                                          undefined
                                                                          ? renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                          : ""}
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              </div>
                                                              <div>
                                                                {getValues(
                                                                  `uccAmend_Additional_Debtor_Details-${l}`
                                                                ) && (
                                                                    <KeyPersonnelIndividualCard
                                                                      data={getValues(
                                                                        `uccAmend_Additional_Debtor_Details-${l}`
                                                                      )}
                                                                      getState={
                                                                        getState
                                                                      }
                                                                      type={
                                                                        "Debtor"
                                                                      }
                                                                    />
                                                                  )}
                                                              </div>
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {getValues(
                                                            "uccAmend_What_are_we_amending"
                                                          ) ===
                                                            "ADDITIONAL SECURED" &&
                                                            value.apiId ===
                                                            "ucc-amend-additional-secured-party-details" ? (
                                                            <>
                                                              <div className="vstate-card p-4 mb-3">
                                                                <div
                                                                  className="grid"
                                                                  key={j}
                                                                >
                                                                  <div className="col-12 pb-0">
                                                                    <h2 className="entity-header-strapi">
                                                                      {toPascalCase(
                                                                        value
                                                                          .schema
                                                                          .displayName
                                                                      )}
                                                                    </h2>
                                                                  </div>
                                                                  {/* {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                                                                                                          <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                                                                                                              {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) : ""}
                                                                                                                                                                                                          </div>
                                                                                                                                                                                                      ))} */}
                                                                  {Object.keys(
                                                                    value.schema
                                                                      .attributes
                                                                  ).map(
                                                                    (
                                                                      val,
                                                                      k
                                                                    ) => {
                                                                      const content =
                                                                        value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ]
                                                                          .type !==
                                                                          undefined
                                                                          ? renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                          : null;

                                                                      return content ? (
                                                                        <div
                                                                          className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                                          key={
                                                                            k
                                                                          }
                                                                        >
                                                                          {
                                                                            content
                                                                          }
                                                                        </div>
                                                                      ) : null;
                                                                    }
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      </>
                                                    )
                                                  )}
                                                </AccordionTab>
                                              </Accordion>
                                            </div>
                                          ))}
                                        </div>

                                        {/* {groupedData[key].mode &&
                                                                                    groupedData[key].mode.repeat ===
                                                                                    true && (
                                                                                        <div className="flex gap-2 justify-content-center w-full">
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                disabled={
                                                                                                    maxValue &&
                                                                                                    key ===
                                                                                                    "Beneficial_Owner_Information" &&
                                                                                                    (formCounts[key] || 1) >=
                                                                                                    maxValue
                                                                                                }
                                                                                                label="Add Entry"
                                                                                                className="vstate-button font-fam-for-all text-center justify-content-center"
                                                                                                onClick={() =>
                                                                                                    handleRepeatForm(key)
                                                                                                }
                                                                                            />
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-minus"
                                                                                                disabled={
                                                                                                    (maxValue &&
                                                                                                        key ===
                                                                                                        "Beneficial_Owner_Information" &&
                                                                                                        (formCounts[key] || 1) ===
                                                                                                        minValue) ||
                                                                                                    (formCounts[key] || 1) === 1
                                                                                                }
                                                                                                label="Remove Entry"
                                                                                                className="vstate-button font-fam-for-all text-center justify-content-center"
                                                                                                onClick={() =>
                                                                                                    handleRemoveRepeatForm(key)
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    )} */}
                                      </div>
                                    ) : (
                                      groupedData[key].form.map((value, j) => (
                                        <>
{
                                          key === "Member_Or_Manager_Details" &&  <>
                                          <div className="card-devider"></div>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Member Or Manager
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="  ">
                                                    <Controller
                                                      name="Billing_Information"
                                                      control={control}
                                                      render={({
                                                        field,
                                                      }) => (
                                                        <Dropdown
                                                          value={member}
                                                          id="RA_Billing_Information"
                                                          name="Billing_Information"
                                                          placeholder="Select Billing Info"
                                                          options={
                                                            allBilingInfo
                                                          }
                                                          onChange={(e) =>
                                                            setBilingInfo(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                  <small
                                                    id={`RA_Billing_Information--error`}
                                                    className="error-msg font-fam-for-all"
                                                  ></small>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Billing Info"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal(
                                                    "MEMBER_OR_MANAGER"
                                                  )
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          <div>
                                            {member ? (
                                              <KeyPersonnelIndividualCard
                                                data={member}
                                                getState={getState}
                                                type={
                                                  "Member Or Manager"
                                                }
                                              />
                                            ) : (
                                              <div className="my-8 py-6"></div>
                                            )}
                                          </div>
                                        </>
                                        }

                                          {value.schema.displayName ===
                                            "Billing_Information" && 
                                            IsCheckedAgent ? (
                                            <>
                                              <div className="card-devider"></div>
                                              <div className="grid w-full justify-content-between">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                  <div className=" justify-content-center dialog-form-field-space w-full">
                                                    <div className="field">
                                                      <label
                                                        htmlFor=""
                                                        className="form-label font-fam-for-all"
                                                      >
                                                        Select Billing Info
                                                        <span className="form-field-mandatory">
                                                          *
                                                        </span>
                                                      </label>
                                                      <span className="  ">
                                                        <Controller
                                                          name="Billing_Information"
                                                          control={control}
                                                          render={({
                                                            field,
                                                          }) => (
                                                            <Dropdown
                                                              value={bilingInfo}
                                                              id="RA_Billing_Information"
                                                              name="Billing_Information"
                                                              placeholder="Select Billing Info"
                                                              options={
                                                                allBilingInfo
                                                              }
                                                              onChange={(e) =>
                                                                setBilingInfo(
                                                                  e.value
                                                                )
                                                              }
                                                              optionLabel="keyPersonnelName"
                                                              style={{
                                                                width: "100%",
                                                                height: "47px",
                                                              }}
                                                              filter
                                                              className="dialog-form-input-field-wizard"
                                                              required
                                                            />
                                                          )}
                                                        />
                                                      </span>
                                                      <small
                                                        id={`RA_Billing_Information--error`}
                                                        className="error-msg font-fam-for-all"
                                                      ></small>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                                  <Button
                                                    type="button"
                                                    icon="pi pi-plus"
                                                    style={{ fontSize: "14px" }}
                                                    label="Add Billing Info"
                                                    className=" vstate-button font-fam-for-all text-center mt-4"
                                                    onClick={() =>
                                                      handleKeyPersonal(
                                                        "RA-BILLING"
                                                      )
                                                    }
                                                  ></Button>
                                                </div>
                                              </div>
                                              <div>
                                                {bilingInfo ? (
                                                  <KeyPersonnelIndividualCard
                                                    data={bilingInfo}
                                                    getState={getState}
                                                    type={
                                                      "Registered Agent Billing"
                                                    }
                                                  />
                                                ) : (
                                                  <div className="my-8 py-6"></div>
                                                )}
                                              </div>
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          <div className="card-devider"></div>
                                          {value.schema.displayName ===
                                            "Mailing_Information" &&
                                            IsCheckedAgent &&
                                            bilingInfo ? (
                                            <>
                                              <div className="grid w-full justify-content-between">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                  <div className=" justify-content-center dialog-form-field-space w-full">
                                                    <div className="field">
                                                      <label
                                                        htmlFor=""
                                                        className="form-label font-fam-for-all"
                                                      >
                                                        Select Mailing Info
                                                        <span className="form-field-mandatory">
                                                          *
                                                        </span>
                                                      </label>
                                                      <span className="  ">
                                                        <Controller
                                                          name="Mailing_Information"
                                                          control={control}
                                                          render={({
                                                            field,
                                                          }) => (
                                                            <Dropdown
                                                              placeholder="Select Mailing Info"
                                                              disabled={
                                                                IsCheckedAgent1
                                                                  ? true
                                                                  : false
                                                              }
                                                              name="Mailing_Information"
                                                              id="RA_Mailing_Information"
                                                              value={
                                                                mailingInfo
                                                              }
                                                              options={
                                                                allMailingInfo
                                                              }
                                                              onChange={(e) =>
                                                                setMailingInfo(
                                                                  e.value
                                                                )
                                                              }
                                                              optionLabel="keyPersonnelName"
                                                              style={{
                                                                width: "100%",
                                                                height: "47px",
                                                              }}
                                                              filter
                                                              className="dialog-form-input-field-wizard"
                                                              required
                                                            />
                                                          )}
                                                        />
                                                      </span>
                                                      <small
                                                        id={`RA_Mailing_Information--error`}
                                                        className="error-msg font-fam-for-all"
                                                      ></small>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                                  <Button
                                                    type="button"
                                                    icon="pi pi-plus"
                                                    disabled={
                                                      IsCheckedAgent1
                                                        ? true
                                                        : false
                                                    }
                                                    style={{ fontSize: "14px" }}
                                                    label="Add Mailing Info"
                                                    className=" vstate-button font-fam-for-all text-center mt-4"
                                                    onClick={() =>
                                                      handleKeyPersonal(
                                                        "RA-MAILING"
                                                      )
                                                    }
                                                  ></Button>
                                                </div>
                                              </div>
                                              {value.schema.displayName ===
                                                "Mailing_Information" && (
                                                  <div className="grid justify-content-start ml-2 mt-2 mb-3">
                                                    <div className="p-0">
                                                      <div className="flex justify-content-center">
                                                        <Checkbox
                                                          checked={
                                                            IsCheckedAgent1
                                                          }
                                                          value={mailingInfo}
                                                          onChange={(e) => {
                                                            setIsCheckedAgent1(
                                                              e.checked
                                                            );
                                                            setMailingInfo(null);
                                                            if (e.checked) {
                                                              setIsCheckedAgent2(
                                                                false
                                                              );
                                                            }
                                                          }}
                                                          inputProps={{
                                                            "aria-label":
                                                              "controlled",
                                                          }}
                                                        />
                                                        <label
                                                          htmlFor="binary"
                                                          className="font-fam-for-all ml-2 srch-cand-text"
                                                        >
                                                          Same as Billing Info
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              <div>
                                                {mailingInfo && (
                                                  <KeyPersonnelIndividualCard
                                                    data={mailingInfo}
                                                    getState={getState}
                                                    type={
                                                      "Registered Agent Mailing"
                                                    }
                                                  />
                                                )}
                                              </div>
                                              <div>
                                                {IsCheckedAgent1 &&
                                                  bilingInfo && (
                                                    <KeyPersonnelIndividualCard
                                                      data={bilingInfo}
                                                      getState={getState}
                                                      type={
                                                        "Registered Agent Mailing"
                                                      }
                                                    />
                                                  )}
                                              </div>
                                              {!(
                                                (IsCheckedAgent1 &&
                                                  bilingInfo) ||
                                                mailingInfo
                                              ) && (
                                                  <div className="my-8 py-6"></div>
                                                )}
                                              <div className="card-devider"></div>
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {getValues(
                                            "uccAmend_What_are_we_amending"
                                          ) === "ADDITIONAL DEBTOR" &&
                                            value.apiId ===
                                            "ucc-amnd-additional-debtor" &&
                                            value.apiId !==
                                            "uucAmnd-What-are-amending"
                                            ? Array.from({
                                              length: formCounts[key] || 1,
                                            }).map((_, l) => (
                                              <>
                                                <div className="vstate-card p-4 mb-3">
                                                  <div
                                                    className="grid"
                                                    key={j}
                                                  >
                                                    <div className="col-12 pb-0">
                                                      <h2 className="entity-header-strapi">
                                                        {toPascalCase(
                                                          value.schema
                                                            .displayName
                                                        )}
                                                      </h2>
                                                    </div>
                                                    <div className="grid w-full justify-content-between">
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                        <div className=" justify-content-center dialog-form-field-space w-full">
                                                          <div className="field">
                                                            <label
                                                              htmlFor=""
                                                              className="form-label font-fam-for-all"
                                                            >
                                                              Select
                                                              Additional
                                                              Debtor
                                                              <span className="form-field-mandatory"></span>
                                                            </label>
                                                            <span className="  ">
                                                              <Controller
                                                                name={`uccAmend_Additional_Debtor_Details-${l}`}
                                                                control={
                                                                  control
                                                                }
                                                                render={({
                                                                  field,
                                                                }) => (
                                                                  <Dropdown
                                                                    value={
                                                                      field.value
                                                                    }
                                                                    options={
                                                                      aallDebtor
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      field.onChange(
                                                                        e.value
                                                                      );

                                                                      handleAdditionalDebtorChange(
                                                                        e.value,
                                                                        l
                                                                      );
                                                                    }}
                                                                    optionLabel="keyPersonnelName"
                                                                    placeholder="Select Debtor"
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      height:
                                                                        "47px",
                                                                    }}
                                                                    filter
                                                                    className="dialog-form-input-field-wizard"
                                                                    required
                                                                  />
                                                                )}
                                                              />
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                        {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                                                                                  <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                                                                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                                                                          <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                                                                      </svg>
                                                                                                                                                                                      <div className='font-medium'>Add Additional Debtor</div>
                                                                                                                                                                                  </div>
                                                              
                                                                                                                                                                              </button> */}
                                                        <Button
                                                          type="button"
                                                          icon="pi pi-plus"
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                          label="Add Additional Debtor"
                                                          className=" vstate-button font-fam-for-all text-center mt-4"
                                                          onClick={() =>
                                                            handleKeyPersonal(
                                                              "DEBTOR"
                                                            )
                                                          }
                                                        ></Button>
                                                      </div>
                                                    </div>
                                                    {Object.keys(
                                                      value.schema.attributes
                                                    ).map((val, k) => (
                                                      <div
                                                        className={`col-12 col-sm-12 col-md-12 col-lg-6 dummy ${!renderFormField(
                                                          value.schema
                                                            .attributes[
                                                          val
                                                          ],
                                                          val,
                                                          `${val}-${l}`,
                                                          value,
                                                          key
                                                        )
                                                            ? "hidden"
                                                            : ""
                                                          }`}
                                                        style={
                                                          keyPersonalMemberData.includes(
                                                            key
                                                          )
                                                            ? {
                                                              display:
                                                                "none",
                                                            }
                                                            : {}
                                                        }
                                                        key={k}
                                                      >
                                                        {value.schema
                                                          .attributes[val]
                                                          .type !== undefined
                                                          ? renderFormField(
                                                            value.schema
                                                              .attributes[
                                                            val
                                                            ],
                                                            val,
                                                            `${val}-${l}`,
                                                            value,
                                                            key
                                                          )
                                                          : ""}
                                                      </div>
                                                    ))}
                                                    <div>
                                                      {getValues(
                                                        `uccAmend_Additional_Debtor_Details-${l}`
                                                      ) && (
                                                          <KeyPersonnelIndividualCard
                                                            data={getValues(
                                                              `uccAmend_Additional_Debtor_Details-${l}`
                                                            )}
                                                            getState={getState}
                                                            type={"Debtor"}
                                                          />
                                                        )}
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="flex gap-2 justify-content-center w-full">
                                                  <Button
                                                    type="button"
                                                    icon="pi pi-plus"
                                                    disabled={
                                                      groupedData[key].form
                                                        .length > 0 &&
                                                      groupedData[key].form[0]
                                                        .apiId ===
                                                      "boi-company-applicant-details" &&
                                                      (formCounts[key] ||
                                                        1) >= 2
                                                    }
                                                    label="Add Entry"
                                                    className="vstate-button font-fam-for-all text-center justify-content-center"
                                                    onClick={() =>
                                                      handleRepeatForm(key)
                                                    }
                                                  />
                                                  <Button
                                                    type="button"
                                                    icon="pi pi-minus"
                                                    disabled={
                                                      (formCounts[key] ||
                                                        1) === 1
                                                    }
                                                    label="Remove Entry"
                                                    className="vstate-button font-fam-for-all text-center justify-content-center"
                                                    onClick={() =>
                                                      handleRemoveRepeatForm(
                                                        key
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </>
                                            ))
                                            : ""}

                                          {getValues(
                                            "uccAmend_What_are_we_amending"
                                          ) === "ADDITIONAL SECURED" &&
                                            value.apiId ===
                                            "ucc-amend-additional-secured-party-details" &&
                                            value.apiId !==
                                            "uucAmnd-What-are-amending"
                                            ? Array.from({
                                              length: formCounts[key] || 1,
                                            }).map((_, l) => (
                                              <>
                                                <div className="vstate-card p-4 mb-3">
                                                  <div
                                                    className="grid"
                                                    key={j}
                                                  >
                                                    <div className="col-12 pb-0">
                                                      <h2 className="entity-header-strapi">
                                                        {toPascalCase(
                                                          value.schema
                                                            .displayName
                                                        )}
                                                      </h2>
                                                    </div>
                                                    <div className="grid w-full justify-content-between">
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                        <div className=" justify-content-center dialog-form-field-space w-full">
                                                          <div className="field">
                                                            <label
                                                              htmlFor=""
                                                              className="form-label font-fam-for-all"
                                                            >
                                                              Select
                                                              Additional
                                                              SecuredParty
                                                              <span className="form-field-mandatory"></span>
                                                            </label>
                                                            <span className="  ">
                                                              <Controller
                                                                name={`uccAmend_Additional_secured_party_Details-${l}`}
                                                                control={
                                                                  control
                                                                }
                                                                render={({
                                                                  field,
                                                                }) => (
                                                                  <Dropdown
                                                                    value={
                                                                      field.value
                                                                    }
                                                                    options={
                                                                      allSecuredParty
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) => {
                                                                      field.onChange(
                                                                        e.value
                                                                      );

                                                                      handleAdditionalSecuredPartyChange(
                                                                        e.value,
                                                                        l
                                                                      );
                                                                    }}
                                                                    optionLabel="keyPersonnelName"
                                                                    placeholder="Select Secured Party"
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      height:
                                                                        "47px",
                                                                    }}
                                                                    filter
                                                                    className="dialog-form-input-field-wizard"
                                                                    required
                                                                  />
                                                                )}
                                                              />
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                        {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("SECUERED_PARTY")}>
                                                                                                                                                                                  <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                                                                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                                                                          <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                                                                      </svg>
                                                                                                                                                                                      <div className='font-medium'>Add Additional Secured Party</div>
                                                                                                                                                                                  </div>
                                                              
                                                                                                                                                                              </button> */}
                                                        <Button
                                                          type="button"
                                                          icon="pi pi-plus"
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                          label="Add Additional Secured Party"
                                                          className=" vstate-button font-fam-for-all text-center mt-4"
                                                          onClick={() =>
                                                            handleKeyPersonal(
                                                              "SECUERED PARTY"
                                                            )
                                                          }
                                                        ></Button>
                                                      </div>
                                                    </div>
                                                    {/* {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                                                                          <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                                                                              {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) : ""}
                                                                                                                                                                          </div>
                                                                                                                                                                      ))} */}
                                                    {Object.keys(
                                                      value.schema.attributes
                                                    ).map((val, k) => {
                                                      const content =
                                                        value.schema
                                                          .attributes[val]
                                                          .type !== undefined
                                                          ? renderFormField(
                                                            value.schema
                                                              .attributes[
                                                            val
                                                            ],
                                                            val,
                                                            `${val}-${l}`,
                                                            value,
                                                            key
                                                          )
                                                          : null;

                                                      return content ? (
                                                        <div
                                                          className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                          key={k}
                                                          style={
                                                            keyPersonalMemberData.includes(
                                                              value?.apiId
                                                            )
                                                              ? {
                                                                display:
                                                                  "none",
                                                              }
                                                              : {}
                                                          }
                                                        >
                                                          {content}
                                                        </div>
                                                      ) : null;
                                                    })}
                                                    <div>
                                                      {getValues(
                                                        `uccAmend_Additional_secured_party_Details-${l}`
                                                      ) && (
                                                          <KeyPersonnelIndividualCard
                                                            data={getValues(
                                                              `uccAmend_Additional_secured_party_Details-${l}`
                                                            )}
                                                            getState={getState}
                                                            type={
                                                              "Secured Party"
                                                            }
                                                          />
                                                        )}
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="flex gap-2 justify-content-center w-full">
                                                  <Button
                                                    type="button"
                                                    icon="pi pi-plus"
                                                    disabled={
                                                      groupedData[key].form
                                                        .length > 0 &&
                                                      groupedData[key].form[0]
                                                        .apiId ===
                                                      "boi-company-applicant-details" &&
                                                      (formCounts[key] ||
                                                        1) >= 2
                                                    }
                                                    label="Add Entry"
                                                    className="vstate-button font-fam-for-all text-center justify-content-center"
                                                    onClick={() =>
                                                      handleRepeatForm(key)
                                                    }
                                                  />
                                                  <Button
                                                    type="button"
                                                    icon="pi pi-minus"
                                                    disabled={
                                                      (formCounts[key] ||
                                                        1) === 1
                                                    }
                                                    label="Remove Entry"
                                                    className="vstate-button font-fam-for-all text-center justify-content-center"
                                                    onClick={() =>
                                                      handleRemoveRepeatForm(
                                                        key
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </>
                                            ))
                                            : ""}

                                          {getValues(
                                            "uccAmend_What_are_we_amending"
                                          ) === "ASSIGNMENT" &&
                                            value.apiId ===
                                            "ucc-assignee-details" &&
                                            value.apiId !==
                                            "uucAmnd-What-are-amending" ? (
                                            <div className="vstate-card p-4 mb-3">
                                              <div className="grid " key={j}>
                                                <div className="col-12 pb-0">
                                                  <h2 className="entity-header-strapi">
                                                    {toPascalCase(
                                                      value.schema.displayName
                                                    )}
                                                  </h2>
                                                </div>
                                                <div className="grid w-full justify-content-between">
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                    <div className=" justify-content-center dialog-form-field-space w-full">
                                                      <div className="field">
                                                        <label
                                                          htmlFor=""
                                                          className="form-label font-fam-for-all"
                                                        >
                                                          Select Assignee
                                                          <span className="form-field-mandatory"></span>
                                                        </label>
                                                        <span className="  ">
                                                          <Controller
                                                            name={`uccAmend_Additional_Debtor_Details`}
                                                            control={control}
                                                            render={({
                                                              field,
                                                            }) => (
                                                              <Dropdown
                                                                value={
                                                                  assigneeData
                                                                }
                                                                options={
                                                                  allAssignee
                                                                }
                                                                onChange={(
                                                                  e
                                                                ) => {
                                                                  setAssigneeData(
                                                                    e.value
                                                                  );
                                                                }}
                                                                optionLabel="keyPersonnelName"
                                                                placeholder="Select Assignee"
                                                                style={{
                                                                  width: "100%",
                                                                  height:
                                                                    "47px",
                                                                }}
                                                                filter
                                                                className="dialog-form-input-field-wizard"
                                                                required
                                                              />
                                                            )}
                                                          />
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center mt-3">
                                                    {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("ASSIGNEE")}>
                                                                                                                                                                              <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                                                                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                                                                      <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                                                                  </svg>
                                                                                                                                                                                  <div className='font-medium'>Add Assignee</div>
                                                                                                                                                                              </div>
                                                              
                                                                                                                                                                          </button> */}
                                                    <Button
                                                      type="button"
                                                      icon="pi pi-plus"
                                                      style={{
                                                        fontSize: "14px",
                                                      }}
                                                      label="Add Assignee"
                                                      className=" vstate-button font-fam-for-all text-center mt-4"
                                                      onClick={() =>
                                                        handleKeyPersonal(
                                                          "ASSIGNEE"
                                                        )
                                                      }
                                                    ></Button>
                                                  </div>
                                                </div>

                                                {Object.keys(
                                                  value.schema.attributes
                                                ).map((val, k) => {
                                                  const content =
                                                    value.schema.attributes[val]
                                                      .type !== undefined
                                                      ? renderFormField(
                                                        value.schema
                                                          .attributes[val],
                                                        val,
                                                        `${val}`,
                                                        value,
                                                        key
                                                      )
                                                      : null;

                                                  return content ? (
                                                    <div
                                                      className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                      key={k}
                                                      style={
                                                        keyPersonalMemberData.includes(
                                                          value?.apiId
                                                        )
                                                          ? { display: "none" }
                                                          : {}
                                                      }
                                                    >
                                                      {content}
                                                    </div>
                                                  ) : null;
                                                })}
                                                <div>
                                                  {assigneeData && (
                                                    <KeyPersonnelIndividualCard
                                                      data={assigneeData}
                                                      getState={getState}
                                                      type={"Assignee"}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {getValues(
                                            "COC_What_Are_We_Changing"
                                          ) === "Address" &&
                                            value.apiId ===
                                            "coc-please-include-new-address" ? (
                                            <div className="vstate-card p-4 mb-3">
                                              <div className="grid " key={j}>
                                                <div className="col-12 pb-0">
                                                  <h2 className="entity-header-strapi">
                                                    {toPascalCase(
                                                      value.schema.displayName
                                                    )}
                                                  </h2>
                                                </div>
                                                {/* {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                                                              <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                                                                  {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, val, value, key) : ""}
                                                                                                                                                              </div>
                                                                                                                                                          ))} */}
                                                {Object.keys(
                                                  value.schema.attributes
                                                ).map((val, k) => {
                                                  const content =
                                                    value.schema.attributes[val]
                                                      .type !== undefined
                                                      ? renderFormField(
                                                        value.schema
                                                          .attributes[val],
                                                        val,
                                                        `${val}`,
                                                        value,
                                                        key
                                                      )
                                                      : null;

                                                  return content ? (
                                                    <div
                                                      className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                      key={k}
                                                    >
                                                      {content}
                                                    </div>
                                                  ) : null;
                                                })}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {getValues(
                                            "COC_What_Are_We_Changing"
                                          ) === "Registered Agent" &&
                                            value.apiId ===
                                            "coc-registered-agent" ? (
                                            <div className="vstate-card p-4 mb-3">
                                              <div className="grid " key={j}>
                                                <div className="col-12 pb-0">
                                                  <h2 className="entity-header-strapi">
                                                    {toPascalCase(
                                                      value.schema.displayName
                                                    )}
                                                  </h2>
                                                </div>

                                                {Object.keys(
                                                  value.schema.attributes
                                                ).map((val, k) => {
                                                  const content =
                                                    value.schema.attributes[val]
                                                      .type !== undefined
                                                      ? renderFormField(
                                                        value.schema
                                                          .attributes[val],
                                                        val,
                                                        `${val}`,
                                                        value,
                                                        key
                                                      )
                                                      : null;

                                                  return content ? (
                                                    <div
                                                      className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                      key={k}
                                                    >
                                                      {content}
                                                    </div>
                                                  ) : null;
                                                })}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {value.apiId !== "address" &&
                                            value.apiId !==
                                            "ucc-amnd-additional-debtor" &&
                                            value.apiId !==
                                            "ucc-amend-additional-secured-party-details" &&
                                            value.apiId !==
                                            "ucc-assignee-details" &&
                                            value.apiId !==
                                            "coc-please-include-new-address" &&
                                            value.apiId !==
                                            "coc-registered-agent" ? (
                                            <div
                                              className="vstate-card p-4 mb-3"
                                              style={
                                                keyPersonalMemberData.includes(
                                                  key
                                                ) ||
                                                  value?.schema?.displayName ===
                                                  "Billing_Information" ||
                                                  value?.schema?.displayName ===
                                                  "Mailing_Information" ||
                                                  (key === "Registered_Agent" &&
                                                    IsCheckedAgent)
                                                  ? { display: "none" }
                                                  : {}
                                              }
                                            >
                                              <div className="grid " key={j}>
                                                <div className="col-12 pb-0">
                                                  <h2 className="entity-header-strapi">
                                                    {toPascalCase(
                                                      value.schema.displayName
                                                    )}
                                                  </h2>
                                                </div>

                                                {Object.keys(
                                                  value.schema.attributes
                                                ).map((val, k) => {
                                                  const content =
                                                    value.schema.attributes[val]
                                                      .type !== undefined
                                                      ? renderFormField(
                                                        value.schema
                                                          .attributes[val],
                                                        val,
                                                        `${val}`,
                                                        value,
                                                        key,
                                                        value.schema
                                                          .displayName
                                                      )
                                                      : null;

                                                  return content ? (
                                                    <div
                                                      className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                      key={k}
                                                    >
                                                      {content}
                                                    </div>
                                                  ) : null;
                                                })}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {value.apiId === "address" ? (
                                            <div
                                              className="vstate-card p-4 mb-3"
                                              style={
                                                keyPersonalMemberData.includes(
                                                  key
                                                ) ||
                                                  value?.schema?.displayName ===
                                                  "Billing_Information" ||
                                                  value?.schema?.displayName ===
                                                  "Mailing_Information" ||
                                                  (key === "Registered_Agent" &&
                                                    IsCheckedAgent)
                                                  ? { display: "none" }
                                                  : {}
                                              }
                                            >
                                              <div className="grid " key={j}>
                                                <div className="col-12 pb-0">
                                                  <h2 className="entity-header-strapi">
                                                    {toPascalCase(
                                                      value.schema.displayName
                                                    )}
                                                  </h2>
                                                </div>
                                                {/* {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                                                                      <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                                                                          {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, val, value, key) : ""}
                                                                                                                                                                      </div>
                                                                                                                                                                  ))} */}
                                                {Object.keys(
                                                  value.schema.attributes
                                                ).map((val, k) => {
                                                  const content =
                                                    value.schema.attributes[val]
                                                      .type !== undefined
                                                      ? renderFormField(
                                                        value.schema
                                                          .attributes[val],
                                                        val,
                                                        `${val}`,
                                                        value,
                                                        key
                                                      )
                                                      : null;

                                                  return content ? (
                                                    <div
                                                      className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                      key={k}
                                                    >
                                                      {content}
                                                    </div>
                                                  ) : null;
                                                })}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ))
                                    )}
                                  </div>
                                ))}

                                {inputFormData.length > 0 ? (
                                  <>
                                    <div className="tab px-4">
                                      <div
                                        className="wizard-content-sub-card-header mb-3"
                                        id="header"
                                      >
                                        <div className="wizard-content-sub-card-text-frame">
                                          <div className="wizard-content-sub-card-text">
                                            Billing Address Information
                                          </div>
                                        </div>
                                      </div>
                                      <div className="wizard-checkoust-container hover-card slide-in">
                                        <div className="wizard-checkout-main">
                                          <div className="grid px-4  w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Billing Address Contact
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="  ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={bilingAddress}
                                                          options={
                                                            allBilingAddress
                                                          }
                                                          onChange={(e) =>
                                                            setBilingAddress(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Billing Address"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("BILLING")} >
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Billing Address</div>
                                                                                                </div>

                                                                                            </button> */}
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Billing Information"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("BILLING")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          <div className="grid w-full wizard-checkout-body">
                                            {bilingAddress && (
                                              <KeyPersonnelIndividualCard
                                                data={bilingAddress}
                                                getState={getState}
                                                type={"Billing Address"}
                                              />
                                            )}
                                          </div>
                                          {/* <div className="wizard-checkout-body vstate-visbiliy-hidden">
                                                                                        <AddressTemplate data={bilingAddress} />
                                                                                    </div> */}
                                        </div>
                                      </div>
                                      {/* <div
                                                                                className="wizard-content-sub-card-header mb-3 mt-3"
                                                                                id="header"
                                                                            >
                                                                                <div className="wizard-content-sub-card-text-frame">
                                                                                    <div className="wizard-content-sub-card-text">
                                                                                        Shipping Address Contact
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizard-checkoust-container hover-card slide-in">
                                                                                <div className="wizard-checkout-main">
                                                                                    <div className='grid justify-content-start ml-4 mt-4 mb-3'>
                                                                                        <div className=' p-0'>
                                                                                            <div className='flex justify-content-center'>
                                                                                                <Checkbox
                                                                                                    checked={IsCheckedAgent1}
                                                                                                    onChange={(e) => {
                                                                                                        setIsCheckedAgent1(e.checked)
                                                                                                    }}
                                                                                                    inputProps={{ 'aria-label': 'controlled' }} />

                                                                                                <label htmlFor="binary" className='font-fam-for-all ml-2 srch-cand-text' >Same as Billing address
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="grid px-4  w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Shipping Address Contact
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className="  ">
                                                                                                        <Controller
                                                                                                            name="incorporator"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={shippingAddress}
                                                                                                                    options={allShippingAddress}
                                                                                                                    disabled={IsCheckedAgent1}
                                                                                                                    onChange={(e) => setShippingAddress(e.value)}
                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                    placeholder="Select Billing Address"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex" >
                                                                                            
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                disabled={IsCheckedAgent1}
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Shipping Address"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => handleKeyPersonal("SHIPPING")}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className="wizard-checkout-body">
                                                                                        {IsCheckedAgent1 ? <AddressTemplate data={bilingAddress} /> : <AddressTemplate data={shippingAddress} />}
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                    </div>

                                    <div class="tab">
                                      <div
                                        className="wizard-content-sub-card-header mb-3"
                                        id="header"
                                      >
                                        <div className="wizard-content-sub-card-text-frame">
                                          <div className="wizard-content-sub-card-text">
                                            Preview
                                          </div>
                                        </div>
                                      </div>

                                      <div className="flex p-4 justify-between font-fam-for-all">
                                        <div className="datatable-highlight-color">
                                          {enityName
                                            ? enityName.entityFullDesc
                                            : ""}
                                        </div>
                                        <div className="datatable-highlight-color">
                                          {state ? state.stateFullDesc : ""}
                                          <span className="ml-2">
                                            {county &&
                                              `(${county?.countyName})`}
                                          </span>
                                        </div>
                                      </div>

                                      {previewData ? (
                                        <div className="min-h-screen wizard-preview-main bg-gray-50  flex justify-center items-start">
                                          <div className="w-full  ">
                                            <div className="bg-white py-2">
                                              <div className="space-y-8">
                                                <div className="bg-white">
                                                  <div
                                                    className="px-4 py-2 rounded-t-lg"
                                                    style={{
                                                      backgroundColor:
                                                        "#F9FAFB",
                                                      fontFamily: "Inter",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    <h2 className="text-lg  text-gray-800">
                                                      Selected Services
                                                    </h2>
                                                  </div>

                                                  <div className="p-4 space-y-4">
                                                    {selectedCheckBoxData.map(
                                                      (service, index) => (
                                                        <div
                                                          key={index}
                                                          className="border grid border-gray-200 rounded-md p-2"
                                                        >
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                                                            <h3
                                                              className="text-md font-semibold"
                                                              style={{
                                                                fontFamily:
                                                                  "Inter",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            >
                                                              {
                                                                service.product
                                                                  .displayName
                                                              }
                                                            </h3>
                                                            <p
                                                              className="text-gray-700 mt-2"
                                                              style={{
                                                                fontFamily:
                                                                  "Inter",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            >
                                                              {/* {service.product.productMaster.additionalInfo} */}
                                                            </p>
                                                          </div>
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                                                            <div
                                                              className="text-right  font-bold text-blue-800"
                                                              style={{
                                                                fontFamily:
                                                                  "Inter",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            >
                                                              {service?.product
                                                                ?.isCustomPriceApplicable
                                                                ? "Custom Price"
                                                                : service
                                                                  ?.product
                                                                  ?.isPriceIncludedInBaseProduct
                                                                  ? "Included"
                                                                  : `$${parseFloat(
                                                                    service
                                                                      .product
                                                                      .unitPrice
                                                                  ).toFixed(
                                                                    2
                                                                  )}`}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>

                                                <PreviewDataComponent
                                                  previewData={previewData}
                                                />
                                                <div className="bg-white">
                                                  <div
                                                    className="px-4 py-2"
                                                    style={{
                                                      backgroundColor:
                                                        "#F9FAFB",
                                                      fontFamily: "Inter",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    <h2 className="text-lg  text-gray-800">
                                                      Contacts
                                                    </h2>
                                                  </div>
                                                  <PreviewKeyPersonelCard
                                                    data={keyPersonnelArray}
                                                  />
                                                </div>

                                                {/* <div className="bg-white">
                                                                                                    <div
                                                                                                        className="px-4 py-2"
                                                                                                        style={{
                                                                                                            backgroundColor:
                                                                                                                "#F9FAFB",
                                                                                                            fontFamily: "Inter",
                                                                                                            fontSize: "16px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <h2 className="text-lg  text-gray-800">
                                                                                                            Contacts
                                                                                                        </h2>
                                                                                                    </div>

                                                                                                    <div className="p-4 ">
                                                                                                        <div className="grid gap-2 justify-content-start">
                                                                                                            {organizerData ||
                                                                                                                officer ||
                                                                                                                !director ||
                                                                                                                incorporator ||
                                                                                                                filer
                                                                                                                ? ""
                                                                                                                : "No data found"}
                                                                                                            {organizerData ? (
                                                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                                                                                                                    <div className="order-contact-card-container">
                                                                                                                        <div className="order-contact-card-main">
                                                                                                                            <div className="order-contact-card-header-main">
                                                                                                                                <Avatar
                                                                                                                                    label={commonGetInitials(
                                                                                                                                        organizerData.keyPersonnelName
                                                                                                                                    )}
                                                                                                                                    icon="pi pi-user"
                                                                                                                                    size="large"
                                                                                                                                    shape="circle"
                                                                                                                                    className="custom-avatar"
                                                                                                                                />

                                                                                                                                <div>
                                                                                                                                    <div className="order-contact-card-header-text1">
                                                                                                                                        {
                                                                                                                                            organizerData.keyPersonnelName
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    <div className="order-contact-card-header-text2">
                                                                                                                                        Organizer
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="card-devider">
                                                                                                                                {" "}
                                                                                                                            </div>
                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path
                                                                                                                                                d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                                                                                                stroke="#175CD3"
                                                                                                                                                stroke-width="2"
                                                                                                                                                stroke-linecap="round"
                                                                                                                                                stroke-linejoin="round"
                                                                                                                                            />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect
                                                                                                                                                    width="20"
                                                                                                                                                    height="20"
                                                                                                                                                    fill="white"
                                                                                                                                                />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        organizerData.contactNo
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="2"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        organizerData.emailId
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                        <path
                                                                                                                                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1 text-wrap">
                                                                                                                                    {organizerData
                                                                                                                                        .addressMasterId
                                                                                                                                        .addressLine1 !==
                                                                                                                                        ""
                                                                                                                                        ? `${organizerData
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine1
                                                                                                                                        },${organizerData
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine2
                                                                                                                                            ? `${organizerData.addressMasterId.addressLine2},`
                                                                                                                                            : ""
                                                                                                                                        }${organizerData
                                                                                                                                            .addressMasterId
                                                                                                                                            .city
                                                                                                                                        },${getState(
                                                                                                                                            organizerData
                                                                                                                                                .addressMasterId
                                                                                                                                                .stateId
                                                                                                                                        )},${organizerData
                                                                                                                                            .addressMasterId
                                                                                                                                            .postalCode
                                                                                                                                        }`
                                                                                                                                        : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}

                                                                                                            {incorporator ? (
                                                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                                                                                                                    <div className="order-contact-card-container">
                                                                                                                        <div className="order-contact-card-main">
                                                                                                                            <div className="order-contact-card-header-main">
                                                                                                                                <Avatar
                                                                                                                                    label={commonGetInitials(
                                                                                                                                        incorporator.keyPersonnelName
                                                                                                                                    )}
                                                                                                                                    icon="pi pi-user"
                                                                                                                                    size="large"
                                                                                                                                    shape="circle"
                                                                                                                                    className="custom-avatar"
                                                                                                                                />

                                                                                                                                <div>
                                                                                                                                    <div className="order-contact-card-header-text1">
                                                                                                                                        {
                                                                                                                                            incorporator.keyPersonnelName
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    <div className="order-contact-card-header-text2">
                                                                                                                                        Incorporator
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="card-devider">
                                                                                                                                {" "}
                                                                                                                            </div>
                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path
                                                                                                                                                d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                                                                                                stroke="#175CD3"
                                                                                                                                                stroke-width="2"
                                                                                                                                                stroke-linecap="round"
                                                                                                                                                stroke-linejoin="round"
                                                                                                                                            />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect
                                                                                                                                                    width="20"
                                                                                                                                                    height="20"
                                                                                                                                                    fill="white"
                                                                                                                                                />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        incorporator.contactNo
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="2"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        incorporator.emailId
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                        <path
                                                                                                                                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1 text-wrap">
                                                                                                                                    {incorporator
                                                                                                                                        .addressMasterId
                                                                                                                                        .addressLine1 !==
                                                                                                                                        ""
                                                                                                                                        ? `${incorporator
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine1
                                                                                                                                        },${incorporator
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine2
                                                                                                                                            ? `${incorporator.addressMasterId.addressLine2},`
                                                                                                                                            : ""
                                                                                                                                        }${incorporator
                                                                                                                                            .addressMasterId
                                                                                                                                            .city
                                                                                                                                        },${getState(
                                                                                                                                            incorporator
                                                                                                                                                .addressMasterId
                                                                                                                                                .stateId
                                                                                                                                        )},${incorporator
                                                                                                                                            .addressMasterId
                                                                                                                                            .postalCode
                                                                                                                                        }`
                                                                                                                                        : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}

                                                                                                            {officer ? (
                                                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                                                                                                                    <div className="order-contact-card-container">
                                                                                                                        <div className="order-contact-card-main">
                                                                                                                            <div className="order-contact-card-header-main">
                                                                                                                                <Avatar
                                                                                                                                    label={commonGetInitials(
                                                                                                                                        officer.keyPersonnelName
                                                                                                                                    )}
                                                                                                                                    icon="pi pi-user"
                                                                                                                                    size="large"
                                                                                                                                    shape="circle"
                                                                                                                                    className="custom-avatar"
                                                                                                                                />

                                                                                                                                <div>
                                                                                                                                    <div className="order-contact-card-header-text1">
                                                                                                                                        {
                                                                                                                                            officer.keyPersonnelName
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    <div className="order-contact-card-header-text2">
                                                                                                                                        Officer
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="card-devider">
                                                                                                                                {" "}
                                                                                                                            </div>
                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path
                                                                                                                                                d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                                                                                                stroke="#175CD3"
                                                                                                                                                stroke-width="2"
                                                                                                                                                stroke-linecap="round"
                                                                                                                                                stroke-linejoin="round"
                                                                                                                                            />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect
                                                                                                                                                    width="20"
                                                                                                                                                    height="20"
                                                                                                                                                    fill="white"
                                                                                                                                                />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        officer.contactNo
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="2"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        officer.emailId
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                        <path
                                                                                                                                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1 text-wrap">
                                                                                                                                    {officer
                                                                                                                                        .addressMasterId
                                                                                                                                        .addressLine1 !==
                                                                                                                                        ""
                                                                                                                                        ? `${officer
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine1
                                                                                                                                        },${officer
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine2
                                                                                                                                            ? `${officer.addressMasterId.addressLine2},`
                                                                                                                                            : ""
                                                                                                                                        }${officer
                                                                                                                                            .addressMasterId
                                                                                                                                            .city
                                                                                                                                        },${getState(
                                                                                                                                            officer
                                                                                                                                                .addressMasterId
                                                                                                                                                .stateId
                                                                                                                                        )},${officer
                                                                                                                                            .addressMasterId
                                                                                                                                            .postalCode
                                                                                                                                        }`
                                                                                                                                        : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}

                                                                                                            {director ? (
                                                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                                                                                                                    <div className="order-contact-card-container">
                                                                                                                        <div className="order-contact-card-main">
                                                                                                                            <div className="order-contact-card-header-main">
                                                                                                                                <Avatar
                                                                                                                                    label={commonGetInitials(
                                                                                                                                        director.keyPersonnelName
                                                                                                                                    )}
                                                                                                                                    icon="pi pi-user"
                                                                                                                                    size="large"
                                                                                                                                    shape="circle"
                                                                                                                                    className="custom-avatar"
                                                                                                                                />

                                                                                                                                <div>
                                                                                                                                    <div className="order-contact-card-header-text1">
                                                                                                                                        {
                                                                                                                                            director.keyPersonnelName
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    <div className="order-contact-card-header-text2">
                                                                                                                                        Director
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="card-devider">
                                                                                                                                {" "}
                                                                                                                            </div>
                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path
                                                                                                                                                d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                                                                                                stroke="#175CD3"
                                                                                                                                                stroke-width="2"
                                                                                                                                                stroke-linecap="round"
                                                                                                                                                stroke-linejoin="round"
                                                                                                                                            />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect
                                                                                                                                                    width="20"
                                                                                                                                                    height="20"
                                                                                                                                                    fill="white"
                                                                                                                                                />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        director.contactNo
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="2"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        director.emailId
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                        <path
                                                                                                                                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1 text-wrap">
                                                                                                                                    {director
                                                                                                                                        .addressMasterId
                                                                                                                                        .addressLine1 !==
                                                                                                                                        ""
                                                                                                                                        ? `${director
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine1
                                                                                                                                        },${director
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine2
                                                                                                                                            ? `${director.addressMasterId.addressLine2},`
                                                                                                                                            : ""
                                                                                                                                        }${director
                                                                                                                                            .addressMasterId
                                                                                                                                            .city
                                                                                                                                        },${getState(
                                                                                                                                            director
                                                                                                                                                .addressMasterId
                                                                                                                                                .stateId
                                                                                                                                        )},${director
                                                                                                                                            .addressMasterId
                                                                                                                                            .postalCode
                                                                                                                                        }`
                                                                                                                                        : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}

                                                                                                            {filer ? (
                                                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                                                                                                                    <div className="order-contact-card-container">
                                                                                                                        <div className="order-contact-card-main">
                                                                                                                            <div className="order-contact-card-header-main">
                                                                                                                                <Avatar
                                                                                                                                    label={commonGetInitials(
                                                                                                                                        filer.keyPersonnelName
                                                                                                                                    )}
                                                                                                                                    icon="pi pi-user"
                                                                                                                                    size="large"
                                                                                                                                    shape="circle"
                                                                                                                                    className="custom-avatar"
                                                                                                                                />

                                                                                                                                <div>
                                                                                                                                    <div className="order-contact-card-header-text1">
                                                                                                                                        {
                                                                                                                                            filer.keyPersonnelName
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    <div className="order-contact-card-header-text2">
                                                                                                                                        Filer
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="card-devider">
                                                                                                                                {" "}
                                                                                                                            </div>
                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path
                                                                                                                                                d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                                                                                                stroke="#175CD3"
                                                                                                                                                stroke-width="2"
                                                                                                                                                stroke-linecap="round"
                                                                                                                                                stroke-linejoin="round"
                                                                                                                                            />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect
                                                                                                                                                    width="20"
                                                                                                                                                    height="20"
                                                                                                                                                    fill="white"
                                                                                                                                                />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        filer.contactNo
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="2"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        filer.emailId
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                        <path
                                                                                                                                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1 text-wrap">
                                                                                                                                    {filer
                                                                                                                                        .addressMasterId
                                                                                                                                        .addressLine1 !==
                                                                                                                                        ""
                                                                                                                                        ? `${filer
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine1
                                                                                                                                        },${filer
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine2
                                                                                                                                            ? `${filer.addressMasterId.addressLine2},`
                                                                                                                                            : ""
                                                                                                                                        }${filer
                                                                                                                                            .addressMasterId
                                                                                                                                            .city
                                                                                                                                        },${getState(
                                                                                                                                            filer
                                                                                                                                                .addressMasterId
                                                                                                                                                .stateId
                                                                                                                                        )},${filer
                                                                                                                                            .addressMasterId
                                                                                                                                            .postalCode
                                                                                                                                        }`
                                                                                                                                        : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}

                                                                                                            {president ? (
                                                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                                                                                                                    <div className="order-contact-card-container">
                                                                                                                        <div className="order-contact-card-main">
                                                                                                                            <div className="order-contact-card-header-main">
                                                                                                                                <Avatar
                                                                                                                                    label={commonGetInitials(
                                                                                                                                        president.keyPersonnelName
                                                                                                                                    )}
                                                                                                                                    icon="pi pi-user"
                                                                                                                                    size="large"
                                                                                                                                    shape="circle"
                                                                                                                                    className="custom-avatar"
                                                                                                                                />

                                                                                                                                <div>
                                                                                                                                    <div className="order-contact-card-header-text1">
                                                                                                                                        {
                                                                                                                                            president.keyPersonnelName
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    <div className="order-contact-card-header-text2">
                                                                                                                                        President
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="card-devider">
                                                                                                                                {" "}
                                                                                                                            </div>
                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path
                                                                                                                                                d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                                                                                                stroke="#175CD3"
                                                                                                                                                stroke-width="2"
                                                                                                                                                stroke-linecap="round"
                                                                                                                                                stroke-linejoin="round"
                                                                                                                                            />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect
                                                                                                                                                    width="20"
                                                                                                                                                    height="20"
                                                                                                                                                    fill="white"
                                                                                                                                                />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        president.contactNo
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="2"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        president.emailId
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                        <path
                                                                                                                                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1 text-wrap">
                                                                                                                                    {president
                                                                                                                                        .addressMasterId
                                                                                                                                        .addressLine1 !==
                                                                                                                                        ""
                                                                                                                                        ? `${president
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine1
                                                                                                                                        },${president
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine2
                                                                                                                                            ? `${president.addressMasterId.addressLine2},`
                                                                                                                                            : ""
                                                                                                                                        }${president
                                                                                                                                            .addressMasterId
                                                                                                                                            .city
                                                                                                                                        },${getState(
                                                                                                                                            president
                                                                                                                                                .addressMasterId
                                                                                                                                                .stateId
                                                                                                                                        )},${president
                                                                                                                                            .addressMasterId
                                                                                                                                            .postalCode
                                                                                                                                        }`
                                                                                                                                        : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}

                                                                                                            {treasurer ? (
                                                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                                                                                                                    <div className="order-contact-card-container">
                                                                                                                        <div className="order-contact-card-main">
                                                                                                                            <div className="order-contact-card-header-main">
                                                                                                                                <Avatar
                                                                                                                                    label={commonGetInitials(
                                                                                                                                        treasurer.keyPersonnelName
                                                                                                                                    )}
                                                                                                                                    icon="pi pi-user"
                                                                                                                                    size="large"
                                                                                                                                    shape="circle"
                                                                                                                                    className="custom-avatar"
                                                                                                                                />

                                                                                                                                <div>
                                                                                                                                    <div className="order-contact-card-header-text1">
                                                                                                                                        {
                                                                                                                                            treasurer.keyPersonnelName
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    <div className="order-contact-card-header-text2">
                                                                                                                                        Treasurer
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="card-devider">
                                                                                                                                {" "}
                                                                                                                            </div>
                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path
                                                                                                                                                d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                                                                                                stroke="#175CD3"
                                                                                                                                                stroke-width="2"
                                                                                                                                                stroke-linecap="round"
                                                                                                                                                stroke-linejoin="round"
                                                                                                                                            />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect
                                                                                                                                                    width="20"
                                                                                                                                                    height="20"
                                                                                                                                                    fill="white"
                                                                                                                                                />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        treasurer.contactNo
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="2"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        treasurer.emailId
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                        <path
                                                                                                                                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1 text-wrap">
                                                                                                                                    {treasurer
                                                                                                                                        .addressMasterId
                                                                                                                                        .addressLine1 !==
                                                                                                                                        ""
                                                                                                                                        ? `${treasurer
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine1
                                                                                                                                        },${treasurer
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine2
                                                                                                                                            ? `${treasurer.addressMasterId.addressLine2},`
                                                                                                                                            : ""
                                                                                                                                        }${treasurer
                                                                                                                                            .addressMasterId
                                                                                                                                            .city
                                                                                                                                        },${getState(
                                                                                                                                            treasurer
                                                                                                                                                .addressMasterId
                                                                                                                                                .stateId
                                                                                                                                        )},${treasurer
                                                                                                                                            .addressMasterId
                                                                                                                                            .postalCode
                                                                                                                                        }`
                                                                                                                                        : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}

                                                                                                            {secretary ? (
                                                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                                                                                                                    <div className="order-contact-card-container">
                                                                                                                        <div className="order-contact-card-main">
                                                                                                                            <div className="order-contact-card-header-main">
                                                                                                                                <Avatar
                                                                                                                                    label={commonGetInitials(
                                                                                                                                        secretary.keyPersonnelName
                                                                                                                                    )}
                                                                                                                                    icon="pi pi-user"
                                                                                                                                    size="large"
                                                                                                                                    shape="circle"
                                                                                                                                    className="custom-avatar"
                                                                                                                                />

                                                                                                                                <div>
                                                                                                                                    <div className="order-contact-card-header-text1">
                                                                                                                                        {
                                                                                                                                            secretary.keyPersonnelName
                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                    <div className="order-contact-card-header-text2">
                                                                                                                                        Secretary
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="card-devider">
                                                                                                                                {" "}
                                                                                                                            </div>
                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path
                                                                                                                                                d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                                                                                                stroke="#175CD3"
                                                                                                                                                stroke-width="2"
                                                                                                                                                stroke-linecap="round"
                                                                                                                                                stroke-linejoin="round"
                                                                                                                                            />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect
                                                                                                                                                    width="20"
                                                                                                                                                    height="20"
                                                                                                                                                    fill="white"
                                                                                                                                                />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        secretary.contactNo
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="2"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1">
                                                                                                                                    {
                                                                                                                                        secretary.emailId
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className="order-contact-card-text-main">
                                                                                                                                <div>
                                                                                                                                    <svg
                                                                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                                                                        width="20"
                                                                                                                                        height="20"
                                                                                                                                        viewBox="0 0 20 20"
                                                                                                                                        fill="none"
                                                                                                                                    >
                                                                                                                                        <path
                                                                                                                                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                        <path
                                                                                                                                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                                                                                            stroke="#175CD3"
                                                                                                                                            stroke-width="1.66667"
                                                                                                                                            stroke-linecap="round"
                                                                                                                                            stroke-linejoin="round"
                                                                                                                                        />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className="order-contact-card-text1 text-wrap">
                                                                                                                                    {secretary
                                                                                                                                        .addressMasterId
                                                                                                                                        .addressLine1 !==
                                                                                                                                        ""
                                                                                                                                        ? `${secretary
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine1
                                                                                                                                        },${secretary
                                                                                                                                            .addressMasterId
                                                                                                                                            .addressLine2
                                                                                                                                            ? `${secretary.addressMasterId.addressLine2},`
                                                                                                                                            : ""
                                                                                                                                        }${secretary
                                                                                                                                            .addressMasterId
                                                                                                                                            .city
                                                                                                                                        },${getState(
                                                                                                                                            secretary
                                                                                                                                                .addressMasterId
                                                                                                                                                .stateId
                                                                                                                                        )},${secretary
                                                                                                                                            .addressMasterId
                                                                                                                                            .postalCode
                                                                                                                                        }`
                                                                                                                                        : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                ""
                                                                                                            )}

                                                                                                          
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div> */}

                                                <div className="px-4">
                                                  <PaymentOptions />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <main
                                            className="flex flex-col justify-between items-center px-4 pt-4 pb-12 rounded-lg shadow-lg font-fam-for-all max-w-[100%] max-md:px-5"
                                            style={{
                                              backgroundColor: "#F6F8FE",
                                              width: "500px",
                                              height: "800px",
                                            }}
                                          >
                                            <Skeleton className="mb-2"></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="5rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              height="2rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              height="4rem"
                                            ></Skeleton>
                                            <Skeleton className="mb-2"></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="5rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              height="2rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              height="4rem"
                                            ></Skeleton>
                                            <Skeleton className="mb-2"></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="5rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              height="2rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              height="4rem"
                                            ></Skeleton>
                                          </main>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                <div className="flex flex-col justify-content-end gap-2 px-4">
                                  {/* {
                                                                        key === "Registered_Agent" ? <Button type='button' id="nextBtn" className="mt-2   vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => nextPrev(1)} >Next</Button>
                                                                            : ""
                                                                    } */}
                                  <Button
                                    type="button"
                                    id="nextBtn"
                                    className="mt-2   vstate-button font-fam-for-all text-center  justify-content-center"
                                    disabled={showSkeleton ? false : true}
                                    onClick={() => nextPrev(1)}
                                  >
                                    Save & Next
                                  </Button>
                                  <Button
                                    type="button"
                                    id="submitBtn"
                                    className="mt-2   vstate-button font-fam-for-all text-center justify-content-center"
                                    onClick={(e) => {
                                      handleSubmit(onSubmit, onInvalid)(e);
                                      handleScroll();
                                    }}
                                  >
                                    Checkout
                                  </Button>
                                  <Button
                                    type="button"
                                    id="prevBtn"
                                    onClick={() => nextPrev(-1)}
                                    className="mt-2 vstate-reverse-button   font-fam-for-all text-center justify-content-center"
                                  >
                                    Back
                                  </Button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 flex flex-column gap-2">
              <div className="main-cart-summary">
                <div className=" font-fam-for-all">
                  <div className="wizard-checkout-container">
                    <div className="wizard-checkout-main">
                      <div className="wizard-checkout-header justify-content-between">
                        <div className="wizard-checkout-text-main">
                          <div className="wizard-checkout-text">
                            CART SUMMARY
                          </div>
                        </div>
                      </div>

                      <div className="wizard-checkout-body">
                        <React.Fragment>
                          <div className="wizard-checkout-content-main">
                            {selectedCheckBoxData.length > 0 ? (
                              selectedCheckBoxData
                                .sort(
                                  (a, b) =>
                                    a?.product?.displayPreference -
                                    b?.product?.displayPreference
                                )
                                .map((val, index) => {
                                  const baseProduct = val?.product?.baseProduct;
                                  const isPriceExcluded =
                                    val?.product?.isPriceIncludedInBaseProduct;
                                  let productPrice = val?.product?.unitPrice;

                                  return (
                                    <>
                                      {!baseProduct && (
                                        <div className="wizard-checkout-content-price-main">
                                          <div className="wizard-price-content1">
                                            {val?.product?.displayName}
                                          </div>
                                          <div className="wizard-price-content2">
                                            {!isPriceExcluded
                                              ? val?.product
                                                ?.isCustomPriceApplicable
                                                ? "Custom Price"
                                                : val?.product
                                                  ?.isBundlePriceApplicable
                                                  ? "Included"
                                                  : `$${parseFloat(
                                                    val?.product?.unitPrice
                                                  ).toFixed(2)}`
                                              : "Included"}
                                          </div>
                                        </div>
                                      )}

                                      {baseProduct && (
                                        <div className="wizard-checkout-content-price-main ml-4">
                                          <div className="wizard-price-content1">
                                            {val?.product?.displayName}
                                          </div>
                                          <div className="wizard-price-content2">
                                            {!isPriceExcluded
                                              ? `$${productPrice}`
                                              : `Included under ${baseProduct.displayName}`}
                                          </div>
                                        </div>
                                        // <div className="ml-4 text-muted">
                                        //     Included under {baseProduct.displayName}
                                        // </div>
                                      )}
                                    </>
                                  );
                                })
                            ) : (
                              <div className="wizard-price-content1 flex justify-content-center">
                                No items added
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      </div>

                      <div className="wizard-checkout-footer">
                        <div className="wizard-checkout-text-footer">
                          <div className="wizard-checkout-footer-txt">
                            TOTAL
                          </div>
                        </div>
                        <div className="wizard-total-price">
                          {`$${parseFloat(calculateTotalAmount()).toFixed(2)}`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {visible && (
              <AddCity
                visible={visible}
                setVisible={setVisible}
                credit={creditInfo}
                llc={llc}
                state={state}
                enityName={enityName}
                llcDetails={llcName}
                modelData={modelData}
              />
            )}

            {visible9 && (
              <AddCompany
                visible={visible9}
                setVisible={setVisible9}
                pageRefresh={pageRefresh}
                setPageRefresh={setPageRefresh}
              />
            )}

            {visible2 && (
              <AddCompanyMember
                visible={visible2}
                setVisible={setVisible2}
                pageRefresh={pageRefresh}
                setPageRefresh={setPageRefresh}
                memberType={memberType}
                data={keyPersonalCompany}
                keyPersonalAddressType={keyPersonalAddressType}
                handlers={handlers}
                state={state}
              />
            )}

            {visible3 && (
              <AddOrderLocation
                companyId={globalCompany ? globalCompany.companyDetails : null}
                visible={visible3}
                setVisible={setVisible3}
                setLocation={setLocation}
                state={state}
              />
            )}

            {keyPersonalCompany && visible8 && (
              <AddBOIMember
                visible={visible8}
                setVisible={setVisible8}
                pageRefresh={pageRefresh}
                setPageRefresh={setPageRefresh}
                memberType={memberType}
                data={keyPersonalCompany}
                keyPersonalAddressType={keyPersonalAddressType}
                handlers={handlers}
                state={state}
              />
            )}

            {closeProgresbar2 && (
              <Dialog
                className="payment-dialog rounded-lg"
                visible={closeProgresbar2}
                onHide={() => setCloseProgresbar2(false)}
              >
                <section className="flex overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                  <div className="payment-icon-confirm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="24"
                      viewBox="0 0 14 24"
                      fill="none"
                    >
                      <path
                        d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1"
                        stroke="#039855"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                    <header className="flex flex-col w-full max-md:max-w-full">
                      <h2 className="text-lg font-medium leading-loose text-gray-900 max-md:max-w-full">
                        Pay Later
                      </h2>
                      <p className="mt-2 text-sm leading-5 text-gray-500 w-[500px] text-wrap">
                        You have selected the <strong>Pay Later</strong> option.
                        Your order will be received, and upon approval from
                        vState Filings, your order will be submitted.
                      </p>
                    </header>
                    <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                      <div className="flex gap-3 items-start justify-content-end self-stretch my-auto min-w-[240px]">
                        <Button
                          className="overflow-hidden gap-2 self-stretch px-5 py-2.5 rounded-lg border border-solid shadow-sm bg-white text-gray-700 border-gray-300"
                          label="Cancel"
                          onClick={() => setCloseProgresbar2(false)}
                        />
                        <Button
                          className="overflow-hidden gap-2 self-stretch px-5 py-2.5 rounded-lg border border-solid shadow-sm bg-blue-600 text-white border-blue-600"
                          label="Submit for Approval"
                          onClick={() => handleConfirm()}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </Dialog>
            )}
            {closeProgresbar3 && (
              <Dialog
                className="payment-dialog rounded-lg"
                visible={closeProgresbar3}
                onHide={() => setCloseProgresbar3(false)}
              >
                <section className="flex rounded-lg overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                  <div className="payment-icon-confirm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="24"
                      viewBox="0 0 14 24"
                      fill="none"
                    >
                      <path
                        d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1"
                        stroke="#039855"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                    <header className="flex flex-col w-full max-md:max-w-full">
                      <h2 className="text-lg font-medium leading-loose text-gray-900 max-md:max-w-full">
                        Pay Now
                      </h2>
                      <p className="mt-2 text-sm leading-5 text-gray-500 w-[500px] text-wrap">
                        You selected the <strong>Pay Now</strong> option. Please
                        complete your payment to finalize your order.
                      </p>
                    </header>
                    <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                      <div className="flex justify-content-end gap-3 items-start self-stretch my-auto min-w-[240px]">
                        <Button
                          className="vstate-reverse-button"
                          label="Cancel"
                          onClick={() => setCloseProgresbar3(false)}
                        />
                        <Button
                          className="vstate-button"
                          label="Proceed to payment"
                          onClick={() => handleConfirm()}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </Dialog>
            )}
            {/* {
                            closeProgresbar3 && (
                                <Dialog className="payment-dialog rounded-lg" visible={closeProgresbar3} onHide={() => setCloseProgresbar3(false)}>
                                    <Elements stripe={stripePromise}>
                                        <Payment />
                                    </Elements>
                                </Dialog>
                            )
                        } */}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddNewEntityFormation;