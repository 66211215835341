// utils/encryption.js

import CryptoJS from 'crypto-js';

const secretKey = process.env.HASH_SECRET_KEY;

 const encryptData = (data) => {
  const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), CryptoJS.enc.Utf8.parse(secretKey), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }).toString();
  return encodeURIComponent(ciphertext); // Encode to make it URL-safe
};

//  const encryptData = (data) => {
//   const iv = CryptoJS.lib.WordArray.random(16);
//   const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey, {
//     mode: CryptoJS.mode.GCM, // Use GCM
//     iv: iv,
//     padding: CryptoJS.pad.Pkcs7
//   });

//   // Combine IV + Encrypted Data and Encode as Base64
//   return CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));
// };

export const decryptData = (ciphertext) => {
  
  const bytes = CryptoJS.AES.decrypt(decodeURIComponent(ciphertext), CryptoJS.enc.Utf8.parse(secretKey), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  
  return JSON.parse(decryptedData);
};

// export const decryptData = (ciphertext) => {
//   const rawData = CryptoJS.enc.Base64.parse(ciphertext);
//   const iv = rawData.clone().words.slice(0, 4); // Extract IV
//   const encryptedData = rawData.clone().words.slice(4);

//   const decrypted = CryptoJS.AES.decrypt(
//     { ciphertext: CryptoJS.lib.WordArray.create(encryptedData) },
//     secretKey,
//     {
//       mode: CryptoJS.mode.GCM,
//       iv: CryptoJS.lib.WordArray.create(iv),
//       padding: CryptoJS.pad.Pkcs7
//     }
//   );
// };

const encryption ={
  encryptData,
  decryptData
}

export default encryption;