import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import CreditCardServices from "../../services/strapiService";
import DownloadPdf from "../../utils/downloadPdf";
import SideBarMenu from "../../common/Sidebar";
import ShiftsServices from "../../services/demoServices";
import { Checkbox } from "primereact/checkbox";
import { TabView, TabPanel } from "primereact/tabview";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import SuccessImage from "../../Assets/Images/icons8-success-96.png";
import ErroImage from "../../Assets/Images/error-icon.png";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import encryption from "../../services/CryptoClass";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Password } from "primereact/password";
import AddressService from "../../services/addressService";
import ContactService from "../../services/ContactService";
import MasterServices from "../../services/coreServices";
import OrderServices from "../../services/orderServices";
import { useNavigate, useSearchParams } from "react-router-dom";
import companyMasterService from "../../services/companyMasterService";
import AddCompanyMember from "../Company/AddCompanyComponets/AddMember";
import authContext from "../../common/authContext";
import { Accordion, AccordionTab } from "primereact/accordion";
import AddressTemplate from "../../utils/AddressTemplate";
import AddCompanyLocation from "../Company/AddCompanyComponets/AddLocation";
import { InputTextarea } from "primereact/inputtextarea";
import PaymentOptions from "../Payment/PaymentOptions";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import propertiesApp from "../../utils/propertiesApp";
import { Toast } from "primereact/toast";
import { Sidebar } from "primereact/sidebar";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Switch, TextField } from "@mui/material";
import { Editor } from "primereact/editor";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddBOIMember from "../Company/AddCompanyComponets/AddBOIMember";
import validationConfig from "../../utils/UsaStatesRegex";
import AddCompany from "../AddComponents/AddCompany";
import { InputMask } from "primereact/inputmask";
import PreviewDataComponent from "../../utils/PreviewDataComponent";
import { Skeleton } from "primereact/skeleton";
import { MultiSelect } from "primereact/multiselect";
import KeyPersonelCard from "../../utils/KeyPersonelCard";
import KeyPersonnelIndividualCard from "../../utils/KeyPersonnelIndividualCard";
import BeneficilaCard from "../../utils/BeneficilaCard";
import { showToast } from "../../utils/toastUtils";
import KeyPersonnelOrderBOIDataTable from "../../utils/keyPersonnelOrderBOITable";
import KeyPersonnelOrderDataTable from "../../utils/keyPersonnelOrderDataTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Eye, EyeOff } from "lucide-react";
import PrincipalAddressCard from "../../utils/principalAddress";
import AddOrderLocation from "../Company/AddCompanyComponets/AddOrderLocation";
import { commonGetInitials } from "../../utils/getInitials";
import { stateWithCounty } from "../../utils/stateWithCounty";
import templateCsv from "../../Assets/Collateral_Statement.docx";

const EditOrderForm = () => {
  const registeredTabRef = useRef(null);

  const handleScroll = () => {
    if (registeredTabRef.current) {
      registeredTabRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      console.error("registeredTabRef is null");
    }
  };

  const {
    setRedirectedData,
    globalCompany,
    loginUserName,
    isLoading,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh,
    editPayloadData,
    setEditPayloadData,
    paymentMethod,
    setOrderContinue,
    setPaymentOrderData,
    wizardScroll,
    setWizardScroll,
    setAddOnServiceData,
  } = useContext(authContext);

  const [allState, setAllState] = useState([]);
  const [showSSN, setShowSSN] = useState(false);

  const [contactId, setContactId] = useState(null);

  const history = useNavigate();

  const [selectedCheckBoxData, setSelectedCheckBoxData] = useState([]);
  const [checkedState, setCheckedState] = useState({});

  const [loading1, setLoading1] = useState(false);

  const stripePromise = loadStripe(
    "pk_test_51PyTdiKhsNfb7YuLO8XP7SHE3FUn3rspK3sI7OTbOIulKp9XdM7WtU4gqjxiKVDVBldIKIEBvL8PLjdKkW1h8f1100bw75rZPd"
  );

  const [county, setCounty] = useState(null);

  const [isTransactionIdExist, setIsTransactionIdExist] = useState(null);

  const [showSkeleton, setShowSkeleton] = useState(false);

  const navigate = useNavigate();

  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [isFocused, setIsFocused] = useState(false);

  const [isDataFetched, setIsDataFetched] = useState(false);

  const [keyPersonnelKey, setKeyPersonnelKey] = useState(null);

  const [keyPersonnelValue, setKeyPersonnelValue] = useState(null);

  const [noOfBundle, setNoOfBundle] = useState(0);

    const [issavedisabled, setIsSavedDisabled] = useState(false);
  

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  useEffect(() => {
    // Check if the page is being refreshed
    const isRefreshed = sessionStorage.getItem("isRefreshed");

    if (isRefreshed) {
      // If refresh detected, redirect to '/order'
      navigate("/order");
      sessionStorage.removeItem("isRefreshed"); // Clear the flag after redirect
    }

    const handleBeforeUnload = () => {
      // Set flag in sessionStorage on page unload (refresh or close)
      sessionStorage.setItem("isRefreshed", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  const toast = useRef(null);
  const tempdata = [
    {
      item: "State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },

    {
      item: "State To Search",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "debt_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "ANR_State_of_Formation",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "coad_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "uccAmend_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "ad_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "sp_State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Text",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Of Filing",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Of Entity",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "BNR_State_Of_Formation",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "State Of Formation",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Country",
      getAllUrl: "/core/api/countryMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",

      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/countryMasters/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },
    {
      item: "County",
      getAllUrl: "/core/api/countyMaster/getAll",
      label: "countyName",
      optionValue: "countyCode",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "County To Search",
      getAllUrl: `/core/api/countyMaster/getAll`,
      label: "countyName",
      optionValue: "countyCode",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },

    {
      item: "State Of LLC",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },

    {
      item: "County Of LLC",
      getAllUrl: "/core/api/countyMaster/getAll",
      label: "countyName",
      optionValue: "countyCode",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },

    {
      item: "State Or Province",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Issuing Jurisdiction State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Issuing Jurisdiction Country",
      getAllUrl: "/core/api/countryMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/countryMasters/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },

    {
      item: "NAICS Sub Code",
      getAllUrl: "/core/api/naicsSubCodes/getAll",
      label: "naicsSubCodeDesc",
      optionValue: "naicsSubCodeDesc",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },
    {
      item: "NAICS Code",
      getAllUrl: "/core/api/stateWiseNaicsCodes/getAll",
      label: "naicsDesc",
      optionValue: "naicsDesc",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },
  ];

  // Global object to track timeouts for each input field by id.
  const maskTimeouts = {};

  /**
   * attachDelayedMask
   * Attaches a delayed mask by switching the input type to "password".
   *
   * @param {string} fieldId - The id of the input field to mask.
   * @param {number} delay - Delay in milliseconds (default: 2000ms).
   */
  function attachDelayedMask(fieldId, delay = 2000) {
    // Use a small delay to allow React to complete rendering
    setTimeout(() => {
      const inputElement = document.getElementById(fieldId);
      if (!inputElement) return;

      // Clear any previously scheduled timeout for this field.
      if (maskTimeouts[fieldId]) {
        clearTimeout(maskTimeouts[fieldId]);
      }

      maskTimeouts[fieldId] = setTimeout(() => {
        // Only mask if the input isn't focused.
        if (document.activeElement !== inputElement) {
          inputElement.type = "password";
        }
      }, delay);
    }, 0);
  }

  const [IsCheckedAgent, setIsCheckedAgent] = useState(false);
  const [IsCheckedAgent1, setIsCheckedAgent1] = useState(false);

  const [entityTypes, setEntityTypes] = useState([]);

  const [visible2, setVisible2] = useState(false);

  const [visible3, setVisible3] = useState(false);

  const [previewData, setPriviewData] = useState(null);

  const [memberType, setMemberType] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  const [keyPersonalCompany, setKeyPersonalCompany] = useState(null);

  const [optionsMap, setOptionsMap] = useState({});

  const [allBilingAddress, setAllBilingAddress] = useState([]);

  const [allShippingAddress, setAllShippingAddress] = useState([]);

  const [bilingAddress, setBilingAddress] = useState(null);

  const [shippingAddress, setShippingAddress] = useState(null);

  const [strapiId, setStrapiId] = useState(null);

  const [formData, setFormData] = useState(null);

  const [updatedOrderLineItems, setUpdatedOrderLineItems] = useState([]);

  const [visible8, setVisible8] = useState(false);

  const [closeProgresbar2, setCloseProgresbar2] = useState(false);

  const [closeProgresbar3, setCloseProgresbar3] = useState(false);

  const [isPaymentDone, setIsPaymentDone] = useState(null);

  const [orderTypeId, setOrderTypeId] = useState(0);

  const [allAssignee, setAllAssignee] = useState([]);

  const [assigneeData, setAssigneeData] = useState(null);

  const [sidebarVisible, setSidebarVisible] = useState(true);

  const [allDocuments, setAllDocuments] = useState([]);

  const [orderHeader, setOrderHeader] = useState(null);

  const [visible9, setVisible9] = useState(false);

  const [IsCheckedAgent2, setIsCheckedAgent2] = useState(false);

  const [serviceDataTemp, setServiceDataTemp] = useState([]);

  const [registeredAgent, setRegisteredAgent] = useState(null);

  const [ownerType, setAllOwnerType] = useState([]);

  const [keyPersonnelStrapiArray, setKeyPersonnelStrapiArray] = useState([]);

  const [moveToStepData, setMoveToStepData] = useState(1);

  const [totalStepData, setTotalStepData] = useState(1);

  const [initialGroupData, setInitialGroupedData] = useState(null);

  const [totalStep, setTotalStep] = useState(1);

  const [creditAll, setCreditAll] = useState([]);

  const [creditInfo, setCreditInfo] = useState(null);

  const [enityName, setEntityName] = useState(null);

  const [state, setState] = useState(null);

  const [inputValue, setInputValue] = useState("");
  const [isAvailable, setIsAvailable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [llcMember, setLLCMember] = useState([]);

  const [llc, setLlc] = useState(null);

  const [allLLC, setAllLLC] = useState([]);

  const [llcName, setLLCName] = useState(null);

  const [visible, setVisible] = useState(false);

  const [selectCheckBoxData, setSelectCheckBoxData] = useState([]);

  const [IsChecked1, setIsChecked1] = useState(false);

  const [IsChecked2, setIsChecked2] = useState(false);

  const [IsChecked3, setIsChecked3] = useState(false);

  const [IsChecked4, setIsChecked4] = useState(false);

  const [IsChecked5, setIsChecked5] = useState(false);

  const [IsChecked6, setIsChecked6] = useState(false);

  const [IsChecked7, setIsChecked7] = useState(false);

  const [IsChecked8, setIsChecked8] = useState(false);

  const [IsChecked9, setIsChecked9] = useState(false);

  const [IsChecked10, setIsChecked10] = useState(false);

  const [IsChecked11, setIsChecked11] = useState(false);

  const [currentTab, setCurrentTab] = useState(0);

  const [closeProgresbar, setCloseProgresbar] = useState(false);

  const [closeProgresbar1, setCloseProgresbar1] = useState(false);

  const [dba, setDba] = useState("");

  const [name, setName] = useState("");

  const [AlternateName, setAlternateName] = useState("");

  const [llcAddress, setLLCAddress] = useState("");

  const [mailingAddress, setMailingAddress] = useState("");

  const [initData, setInitData] = useState(null);

  const [initData1, setInitData1] = useState(null);

  const [initData2, setInitData2] = useState(null);

  const [allInitData, setAllInitData] = useState([]);

  const [inputFormData, setInputFormData] = useState([]);

  const [allModels, setAllModels] = useState([]);

  const [modelData, setModelData] = useState([]);

  const [modelData1, setModelData1] = useState([]);

  const [IsSuccessful, setIsSuccessFul] = useState(false);

  const [modelName, setModelName] = useState(null);

  const [currentStep, setCurrentStep] = useState(1);

  const [activeStep, setActiveStep] = React.useState(0);

  const [globalModel, setGlobalModel] = useState([]);

  const [allComponents, setAllComponents] = useState([]);

  const [mainModel, setMainModel] = useState(null);

  const [rawModel, setRawModel] = useState([]);

  const [groupInitialData, setGroupInitialData] = useState({});

  const [showDescription, setShowDescription] = useState(false);

  const [organizerData, setOrganizerData] = useState(null);

  const [allOrganizer, setAllOrganizer] = useState([]);

  const [director, setDirector] = useState(null);

  const [allDirector, setAllDirector] = useState([]);

  const [officer, setOfficer] = useState(null);

  const [allOfficer, setAllOfficer] = useState([]);

  const [pageRefresh, setPageRefresh] = useState(false);

  const [allCompany, setAllCompany] = useState([]);

  const [company, setCompany] = useState(null);

  const [allLocation, setAllLocation] = useState([]);

  const [location, setLocation] = useState(null);
  const [einLocation, setEinLocation] = useState(null);

  const [allInCorporator, setAllInCorporator] = useState([]);

  const [incorporator, setIncorporator] = useState(null);

  const [allCountries, setAllCountries] = useState([]);

  const [agentLocation, setAgentLocation] = useState(null);

  const [allCounty, setAllCounty] = useState([]);

  const [servicesData, setServiceData] = useState([]);

  const [addOnData, setAddOnData] = useState(null);

  const [aallDebtor, setAllDebtor] = useState([]);

  const [allSecuredParty, setAllSecuredParty] = useState([]);

  const [allCompanyApplicant, setAllCompanyApplicant] = useState([]);

  const [allBeneficialOwner, setAllBeneficialOwner] = useState([]);

  const [allFiler, setAllFiler] = useState([]);

  const [filer, setFiler] = useState(null);

  const [allPresident, setAllPresident] = useState([]);

  const [president, setPresident] = useState(null);

  const [allTreasurer, setAllTreasurer] = useState([]);

  const [treasurer, setTreasurer] = useState(null);

  const [allSecretary, setAllSecretary] = useState([]);

  const [secretary, setSecretary] = useState(null);

  const [allAdminCompany, setAllAdminCompany] = useState([]);

  const [adminCompany, setAdminCompany] = useState(null);

  const [allBilingInfo, setAllBilingInfo] = useState([]);

  const [bilingInfo, setBilingInfo] = useState(null);

  const [allMailingInfo, setAllMailingInfo] = useState([]);

  const [mailingInfo, setMailingInfo] = useState(null);

  const [allContactInfo, setAllContactInfo] = useState([]);

  const [contactInfo, setContactInfo] = useState(null);

  const [whatAreChanging, setWhatAreChanging] = useState([]);

  const [groupedData, setGroupedData] = useState({});
  

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    unregister,
    clearErrors,
  } = useForm("");
  
  const ucc_UCC_Collateral_Type = getValues("ucc_UCC_Collateral_Type");
  const ucc_Type_Your_Collateral_Statement = getValues("ucc_Type_Your_Collateral_Statement");
  console.log(ucc_Type_Your_Collateral_Statement, "ucc_Type_Your_Collateral_Statement")
  const [editorValue, setEditorValue] = useState(ucc_Type_Your_Collateral_Statement);
  
  
  useEffect(() => {
      if (ucc_UCC_Collateral_Type) {
        // Reset all relevant fields when the collateral type changes
        // setValue("ucc_Collateral_Statement", ""); 
        setValue("ucc_Collateral_Statement", "");  
  
      // Clear the file input field
      const fileInput = document.querySelector('input[name="ucc_Collateral_Statement"]');
      if (fileInput) {
        fileInput.value = "";  
      }
  
        setValue("ucc_Type_Your_Collateral_Statement", ""); 
        clearErrors("ucc_Collateral_Statement");
        clearErrors("ucc_Type_Your_Collateral_Statement");
      }
      // setEditorValue(getValues("ucc_Type_Your_Collateral_Statement"))
    }, [ucc_UCC_Collateral_Type]);

  useEffect(() => {
    setIsLoading(true);
    AddressService.getAllStates()
      .then((res) => {
        if (res.data !== "") {
          setAllState(res.data);
        }
      })
      .catch((err) => { });

    CreditCardServices.getComponent()
      .then((res) => {
        setAllComponents(res.data.data);
      })
      .catch((err) => { });
  }, []);

  useEffect(() => {
    if (!isDataFetched) {
      ShiftsServices.getInit()
        .then((res) => {
          setAllInitData(res.data.data);
        })
        .catch((err) => {
          setIsLoading(false);
        });

      ShiftsServices.getInit()
        .then((res) => {
          setRawModel(res.data.data);
        })
        .catch((err) => { });
    }
  }, [inputFormData, isDataFetched]);

  useEffect(() => {
    setSelectedCountry("+1");
    MasterServices.getAll("/core/api/entityTypeMaster/getAll")
      .then((res) => {
        if (res.data !== "") {
          setEntityTypes(res.data);
        }
      })
      .catch((err) => { });
    ContactService.getAllContactByUserId(user?.id)
      .then((res) => {
        let data = res.data;

        if (res.data && res.data !== "") {
          if (res.data.contactDetails !== undefined) {
            setContactId(res.data?.contactDetails?.id);
          }
        }
      })
      .catch((err) => { });
    AddressService.getAllAddressType()
      .then((res) => {
        if (res.data !== undefined) {
          if (res.data !== "") {
            if (Array.isArray(res.data)) {
              setAllAddressType(res.data);
            }
          }
        }
      })
      .catch((err) => { });

    MasterServices.getAllDocuments()
      .then((res) => {
        if (Array.isArray(res.data)) {
          setAllDocuments(res.data);
        }
      })
      .catch((err) => { });
  }, []);

     
    

  const [allKeyPersonnelType, setAllKeyPersonnelType] = useState([]);

  const [allAddressType, setAllAddressType] = useState([]);

  const [keyPersonalAddressType, setKeyPersonalAddressType] = useState(null);

  useEffect(() => {
    companyMasterService
      .getAllKeyPersonnelType()
      .then((res) => {
        if (res.data !== "") {
          setAllKeyPersonnelType(res.data);
        }
      })
      .catch((err) => { });
  }, []);

  const [selectedProducts, setSelectedProducts] = React.useState({});

  const handleCheckedChange = (e, serviceData) => {
    const { product } = serviceData;

    setSelectedProducts((prevSelected) => {
      const updatedSelected = { ...prevSelected };

      if (product.orderTypeMaster === 21 || product.orderTypeMaster === 18) {
        if (e.checked) {
          updatedSelected[product.orderTypeMaster] = [product.displayName];
        } else {
          delete updatedSelected[product.orderTypeMaster];
        }
      } else {
        if (e.checked) {
          if (!updatedSelected[product.orderTypeMaster]) {
            updatedSelected[product.orderTypeMaster] = [];
          }
          updatedSelected[product.orderTypeMaster].push(product.displayName);
        } else {
          updatedSelected[product.orderTypeMaster] = (
            updatedSelected[product.orderTypeMaster] || []
          ).filter((name) => name !== product.displayName);
          if (updatedSelected[product.orderTypeMaster].length === 0) {
            delete updatedSelected[product.orderTypeMaster];
          }
        }
      }

      setSelectedCheckBoxData((prevCheckboxData) => {
        const updatedCheckboxData = [...prevCheckboxData];

        if (product.orderTypeMaster === 21 || product.orderTypeMaster === 18) {
          if (e.checked) {
            return [
              ...updatedCheckboxData.filter(
                (data) =>
                  data.product.orderTypeMaster !== product.orderTypeMaster
              ),
              serviceData,
            ];
          } else {
            return updatedCheckboxData.filter(
              (data) => data.product.orderTypeMaster !== product.orderTypeMaster
            );
          }
        }

        if (e.checked) {
          updatedCheckboxData.push(serviceData);
        } else {
          return updatedCheckboxData.filter(
            (data) => data.product.displayName !== product.displayName
          );
        }
        return updatedCheckboxData;
      });

      return updatedSelected;
    });
  };

  // Initialize Bootstrap tooltips
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);

  const calculatePrice = (serviceData, quantity) => {
    if (serviceData.product.isBundlePriceApplicable) {
      const { noOfItemsInBundle, priceOfBundle, priceForAddlItemsOverBundle } =
        serviceData.product;

      if (quantity <= noOfItemsInBundle) {
        return priceOfBundle;
      } else {
        const bundledPrice = priceOfBundle;
        const additionalItemsPrice =
          priceForAddlItemsOverBundle * (quantity - noOfItemsInBundle);
        return bundledPrice + additionalItemsPrice;
      }
    } else {
      if (serviceData?.product?.isDefault === false) {
        return serviceData.product.unitPrice;
      } else {
        return 0;
      }
    }
  };

  const calculateTotalAmount = () => {
    return selectedCheckBoxData.reduce((total, val) => {
      const quantity = allDebtorData.length;
      const itemTotal = calculatePrice(val, quantity);
      return total + itemTotal;
    }, 0);
  };

  useEffect(() => {
    companyMasterService
      .getAllCompany()
      .then((res) => {
        let data = res.data;
        setAllCompany(res.data);
        if (Array.isArray(res.data)) {
          const temp = res.data.filter(
            (val) => val.companyName === "vState Filings"
          );

          if (globalCompany) {
            if (globalCompany.companyDetails) {
              const keyCompany = res.data.find(
                (val) => val.id === globalCompany.companyDetails.id
              );
              setKeyPersonalCompany(keyCompany);
            }
          }
          if (temp.length > 0) {
            setCompany(temp[0]);
          }
        }
      })
      .catch((err) => { });
  }, [globalCompany]);

  useEffect(() => {
    if (globalCompany) {
      companyMasterService
        .getAllChildCompanyByParentId(globalCompany?.companyDetails?.id)
        .then((res) => {
          if (res.data !== "") {
            let data = res.data;
            setAllAdminCompany(data);
          }
        })
        .catch((err) => { });
    }
  }, [globalCompany, isTabRefresh, isLoading]);

  useEffect(() => {
    if (enityName && state && selectedCheckBoxData.length === 0) {
      MasterServices.getAllProduct(
        orderTypeId,
        enityName.id,
        state.id,
        county ? county.id : 0
      )
        .then((res) => {
          setShowSkeleton(true);
          if (Array.isArray(res.data)) {
            let registeredAgentProducts = res.data.filter(
              (val) => val?.product?.orderTypeMaster === 24
            );

            let otherProducts = res.data.filter(
              (val) => val?.product?.orderTypeMaster !== 24
            );

            if (
              registeredAgent?.registeredAgencyName
                ?.toLowerCase()
                .includes("corporate creations")
            ) {
              registeredAgentProducts = registeredAgentProducts.filter(
                (val) =>
                  val?.product?.displayName?.toLowerCase().includes("cc")
              );
            } else {
              registeredAgentProducts = registeredAgentProducts.filter(
                (val) =>
                  !val?.product?.displayName?.toLowerCase().includes("cc")
              );
            }

            const finalData = [
              ...otherProducts,
              ...registeredAgentProducts,
            ];
            const sortedData = res.data.sort(
              (a, b) =>
                a.product.displayPreference - b.product.displayPreference
            );
            if (orderTypeId === 9) {
              if (Array.isArray(whatAreChanging)) {
                const filterproducts = res.data.filter((val) =>
                  whatAreChanging.some((value) =>
                    val?.product?.displayName
                      .toLowerCase()
                      .includes(value.toLowerCase().trim())
                  )
                );
                setServiceData(filterproducts);
              }
            } else {
              setServiceData(sortedData);
              const temp = res.data.filter(
                (val) => val.product.isDefault === true
              );
            }

            // setSelectedCheckBoxData(temp)
          }
          // else {
          //     setServiceData([])
          // }
        })
        .catch((err) => {
          setShowSkeleton(true);
        });

    }
  }, [enityName, state, orderTypeId, registeredAgent]);

  useEffect(() => {
    if (state) {
      AddressService.getAllCountyByState(state.id)
        .then((res) => {
          setAllCounty(res.data);
        })
        .catch((err) => { });
      companyMasterService
        .getRegisteredAgentByStateId(state.id)
        .then((res) => {
          const data = res.data;
          if (Array.isArray(data) && data.length > 0) {
            setRegisteredAgent(data[0]);
          }
        })
        .catch((err) => { });
    }
  }, [state])

  // useEffect(() => {
  //   if (editPayloadData) {
  //     if (Array.isArray(editPayloadData.orderLineItem)) {
  //       if (editPayloadData.orderLineItem.length > 0) {
  //
  //
  //         const temp = servicesData.filter((val) =>
  //           editPayloadData.orderLineItem.some(
  //             (ele) => ele.productListing.id === val.product.id
  //           )
  //         );
  //
  //         if (temp.length > 0) {
  //           temp.forEach((serviceData) => {
  //             setCheckedState((prev) => ({
  //               ...prev,
  //               [serviceData.product.displayName]: true,
  //             }));
  //           });
  //         }
  //         setSelectedCheckBoxData(temp);
  //       }
  //     }
  //   }
  // }, [servicesData]);

  useEffect(() => {
    if (editPayloadData?.orderLineItem?.length > 0) {
      const temp = servicesData.filter((val) =>
        editPayloadData.orderLineItem.some(
          (ele) => ele.productListing.id === val.product.id
        )
      );

      if (temp.length > 0) {
        setSelectedProducts((prevSelected) => {
          const updatedSelected = { ...prevSelected };
          temp.forEach((serviceData) => {
            const { product } = serviceData;
            if (!updatedSelected[product.orderTypeMaster]) {
              updatedSelected[product.orderTypeMaster] = [];
            }
            updatedSelected[product.orderTypeMaster].push(product.displayName);
          });
          return updatedSelected;
        });

        const bundlePrice = temp.find(
          (val) => val?.product?.isBundlePriceApplicable
        );
        console.log(bundlePrice, "bundlePrice");
        if (bundlePrice) {
          setNoOfBundle(bundlePrice?.product?.noOfItemsInBundle);
        }

        setSelectedCheckBoxData(temp);
      }
    }
  }, [servicesData]);

  useEffect(() => {
    if (globalCompany) {
      AddressService.getAllAddressByCompanyId(globalCompany.companyDetails.id)
        .then((res) => {
          if (res.data !== "") {
            if (Array.isArray(res.data)) {
              const temp = res.data.filter(
                (val) => val.addressType.type === "PRINCIPAL"
              );

              if (temp.length > 0) {
                setAllLocation(temp);
              }
            }
          } else {
            setAllLocation([]);
            setAllBilingAddress([]);
            setAllShippingAddress([]);
          }
        })
        .catch((err) => { });
    }
  }, [globalCompany, isTabRefresh]);

  useEffect(() => {
    MasterServices.getAllApplicantType()
      .then((res) => {
        if (res.data !== "") {
          setAllOwnerType(res.data);
        }
      })
      .catch((err) => { });
  }, []);

  const items = Array.from({ length: 5 }, (v, i) => i);

  useEffect(() => {
    const uniqueUrls = {};

    tempdata.forEach((matchedItem) => {
      if (matchedItem?.getAllUrl) {
        if (!uniqueUrls[matchedItem.getAllUrl]) {
          uniqueUrls[matchedItem.getAllUrl] = [];
        }
        uniqueUrls[matchedItem.getAllUrl].push(matchedItem.item);
      }
    });

    Object.entries(uniqueUrls).forEach(([url, items]) => {
      MasterServices.getAll(url)
        .then((fetchedData) => {
          if (Array.isArray(fetchedData.data)) {
            const newOptions = fetchedData.data;
            setOptionsMap((prev) => {
              const updatedMap = { ...prev };
              items.forEach((item) => {
                updatedMap[item] = newOptions;
              });
              return updatedMap;
            });
          }
        })
        .catch((err) => console.log(err));
    });
  }, []);

  useEffect(() => {
    if (globalCompany && state) {
      if (globalCompany.companyDetails) {
        companyMasterService
          .getAllKeyPersonnelByCompanyId(globalCompany.companyDetails.id)
          .then((res) => {
            if (res.data !== "") {
              if (Array.isArray(res.data)) {
                // const data = res.data.filter((val) => val?.addressMasterId?.stateId === state?.id)
                const data = res.data.filter(
                  (val) => val?.addressMasterId !== null
                );
                setAllOrganizer(data);
                setAllInCorporator(data);
                setAllBilingAddress(data);
                setAllShippingAddress(data);
                setAllOfficer(data);
                setAllDirector(data);
                setAllSecuredParty(data);
                setAllDebtor(data);

                setAllAssignee(data);
                setAllFiler(data);
                setAllPresident(data);
                setAllTreasurer(data);
                setAllSecretary(data);
                setAllBilingInfo(data);
                setAllMailingInfo(data);
              }
            }
          })
          .catch((err) => { });

        companyMasterService
          .getAllOwnerInformation(globalCompany.companyDetails.id)
          .then((res) => {
            if (Array.isArray(res.data)) {
              const temp1 = res.data.filter(
                (val) => val.applicantType.name === "COMPANY APPLICANT"
              );
              const temp2 = res.data.filter(
                (val) => val.applicantType.name === "BENEFICIAL OWNER"
              );

              setAllBeneficialOwner(temp2);
              setAllCompanyApplicant(temp1);
            }
          })
          .catch((err) => { });
      }
    }
  }, [visible2, globalCompany, isTabRefresh, state]);

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const [minValue, setMinValue] = useState(null);

  const [maxValue, setMaxValue] = useState(null);

  const [formCounts, setFormCounts] = useState({});

  const [allDebtorData, setAllDebtorData] = useState([]);

  const [allAdditionalDebtors, setAllAdditionalDebtors] = useState([]);

  const [allSecuredParties, setAllSecuredParties] = useState([]);

  const [allAdditionalSecuredParties, setAllAdditionalSecuredParties] =
    useState([]);

  const [allBeneficialData, setAllBeneficialData] = useState([]);

  const [allCompanyApplicantData, setAllCompanyApplicantData] = useState([]);

  const handleRepeatForm = (key) => {
    setFormCounts((prevCounts) => ({
      ...prevCounts,
      [key]: (prevCounts[key] || 1) + 1,
    }));
  };

  const handleRemoveRepeatForm = (key) => {
    const currentCount = formCounts[key] || 1;
    if (currentCount > 1) {
      const fieldNames = groupedData[key].form.flatMap((value) =>
        Object.keys(value.schema.attributes)
      );
      fieldNames.forEach((field) => {
        unregister(`${field}-${currentCount - 1}`);
      });

      setFormCounts((prevCounts) => ({
        ...prevCounts,
        [key]: currentCount - 1,
      }));
    }
  };

  useEffect(() => {
    const boiData = selectedCheckBoxData.filter((item) =>
      item.product.displayName.includes("BOI")
    );

    if (boiData.length > 0) {
      const numbers = boiData.flatMap((item) => {
        const matches = item.product.displayName.match(/\d+/g);
        return matches ? matches.map(Number) : [];
      });

      if (numbers.length > 0) {
        setMinValue(Math.min(...numbers));
        // setFormCounts((prevCounts) => ({
        //     ...prevCounts,
        //     ["Beneficial_Owner_Information"]: Math.min(...numbers),
        // }))
        setMaxValue(Math.max(...numbers));
      }
    }
  }, [selectedCheckBoxData]);

  const handleNext = (data) => {
    if (activeStep === data.length - 1) {
      nextPrev(1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  

  const stateFilter = (id) => {
    const temp = allState.filter((val) => val.id === id);

    if (temp.length > 0) {
      return temp[0].stateShortName;
    } else {
      return "";
    }
  };

  const countryFilter = (id) => {
    const temp = allCountries.filter((val) => val.id === id);

    if (temp.length > 0) {
      return temp[0].countryShortName;
    } else {
      return "";
    }
  };

  function numberToString(number) {
    if (typeof number === "number") {
      return number.toString();
    }
    if (typeof number === "string") {
      return number;
    }
  }

  function extractTenDigitPhoneNumber(input) {
    if (typeof input === "string") {
      const temp3 = input.split(" ");
      if (Array.isArray(temp3)) {
        if (temp3.length > 0) {
          return temp3.slice(1).join(" ");
        }
      }
    }
  }

  const [initialRenderData, setInitialRendered] = useState(false);

  useEffect(() => {
    if (totalStepData !== 1 && initialGroupData && !initialRenderData) {
      if (totalStepData !== moveToStepData + 1) {
        const totalSteps = totalStepData;
        if (moveToStepData >= 0 && moveToStepData < totalSteps) {
          const tabs = document.getElementsByClassName("tab");
          if (tabs[currentTab]) {
            tabs[currentTab].style.display = "none";
          }

          setCurrentTab(moveToStepData);
          setCurrentStep(moveToStepData + 1);

          showTab(moveToStepData);

          const stepElements = document.getElementsByClassName("step");
          for (let i = 0; i < moveToStepData; i++) {
            if (
              stepElements[i] &&
              !stepElements[i].className.includes("finish")
            ) {
              stepElements[i].className += " finish";
            }
          }

          window.scrollTo({ top: 0, behavior: "smooth" });
          setInitialRendered(true);
          setIsSavedDisabled(true);
        }
      } else {
        const totalSteps = totalStepData;
        if (moveToStepData >= 0 && moveToStepData < totalSteps) {
          const tabs = document.getElementsByClassName("tab");
          if (tabs[currentTab]) {
            tabs[currentTab].style.display = "none";
          }

          setCurrentTab(moveToStepData - 1);
          setCurrentStep(moveToStepData);

          showTab(moveToStepData - 1);

          const stepElements = document.getElementsByClassName("step");
          for (let i = 0; i < moveToStepData; i++) {
            if (
              stepElements[i] &&
              !stepElements[i].className.includes("finish")
            ) {
              stepElements[i].className += " finish";
            }
          }

          window.scrollTo({ top: 0, behavior: "smooth" });
          setInitialRendered(true);
        }
        const stepElements = document.getElementsByClassName("step");
        for (let i = 0; i < moveToStepData; i++) {
          if (
            stepElements[i] &&
            !stepElements[i].className.includes("finish")
          ) {
            stepElements[i].className += " finish";
          }
        }

        window.scrollTo({ top: 0, behavior: "smooth" });
        setInitialRendered(true);
        setIsSavedDisabled(true);
      }
    }
  }, [moveToStepData, totalStepData, initialGroupData, initialRenderData]);

  function setDropdownError(fieldKey, message = "") {
    const errorMessageTag = document.getElementById(`${fieldKey}--error`);
    if (errorMessageTag) {
      errorMessageTag.textContent = "";
    }
  }

  useEffect(() => {
    if (einLocation) {
      setValue("EIN_Address_Line_1", einLocation.addressLine1);
      setValue("EIN_Address_Line_2", einLocation.addressLine2);
      setValue("EIN_City", einLocation.city);
      setValue("EIN_State", stateFilter(einLocation.stateId));
      // setValue("EIN_Country", countryFilter(location.countryId));
      setValue("EIN_Zip_Code", numberToString(einLocation.postalCode));
    }
  }, [setValue, einLocation]);

  useEffect(() => {
    if (organizerData) {
      setValue("Org_Name", organizerData.keyPersonnelName);
      setValue("Org_Email_Address", organizerData.emailId);
      setValue(
        "Org_Contact_No",
        extractTenDigitPhoneNumber(organizerData.contactNo)
      );
      setValue(
        "Org_Address_Line_1",
        organizerData.addressMasterId.addressLine1
      );
      setValue(
        "Org_Address_Line_2",
        organizerData.addressMasterId.addressLine2
      );
      setValue("Org_City", organizerData.addressMasterId.city);
      setValue("Org_State", stateFilter(organizerData.addressMasterId.stateId));
      setValue(
        "Org_Country",
        countryFilter(organizerData.addressMasterId.countryId)
      );
      setValue(
        "Org_Zip_Code",
        numberToString(organizerData.addressMasterId.postalCode)
      );
    }
  }, [setValue, organizerData]);

  useEffect(() => {
    if (incorporator) {
      setValue("Inc_Name", incorporator.keyPersonnelName);
      setValue("Inc_Email_Address", incorporator.emailId);
      setValue(
        "Inc_Contact_No",
        extractTenDigitPhoneNumber(incorporator.contactNo)
      );
      setValue("Inc_Address_Line_1", incorporator.addressMasterId.addressLine1);
      setValue("Inc_Address_Line_2", incorporator.addressMasterId.addressLine2);
      setValue("Inc_City", incorporator.addressMasterId.city);
      setValue("Inc_State", stateFilter(incorporator.addressMasterId.stateId));
      setValue(
        "Inc_Country",
        countryFilter(incorporator.addressMasterId.countryId)
      );
      setValue(
        "Inc_Zip_Code",
        numberToString(incorporator.addressMasterId.postalCode)
      );
    }
  }, [setValue, incorporator]);

  useEffect(() => {
    if (officer) {
      setValue("Off_Name", officer.keyPersonnelName);
      setValue("Off_Email_Address", officer.emailId);
      setValue("Off_Contact_No", extractTenDigitPhoneNumber(officer.contactNo));
      setValue("Of_Address_Line_1", officer.addressMasterId.addressLine1);
      setValue("Of_Address_Line_2", officer.addressMasterId.addressLine2);
      setValue("Of_City", officer.addressMasterId.city);
      setValue("Of_State", stateFilter(officer.addressMasterId.stateId));
      setValue("Of_Country", countryFilter(officer.addressMasterId.countryId));
      setValue(
        "Of_Zip_Code",
        numberToString(officer.addressMasterId.postalCode)
      );
    }
  }, [setValue, officer]);

  useEffect(() => {
    if (director) {
      setValue("Dir_Name", director.keyPersonnelName);
      setValue("Dir_Email_Address", director.emailId);
      setValue(
        "Dir_Contact_No",
        extractTenDigitPhoneNumber(director.contactNo)
      );
      setValue("Dir_Address_Line_1", director.addressMasterId.addressLine1);
      setValue("Dir_Address_Line_2", director.addressMasterId.addressLine2);
      setValue("Dir_City", director.addressMasterId.city);
      setValue("Dir_State", stateFilter(director.addressMasterId.stateId));
      setValue(
        "Dir_Country",
        countryFilter(director.addressMasterId.countryId)
      );
      setValue(
        "Dir_Zip_Code",
        numberToString(director.addressMasterId.postalCode)
      );
    }
  }, [setValue, director]);

  useEffect(() => {
    if (location) {
      setValue("PA_Address_Line_1", location.addressLine1);
      setValue("PA_Address_Line_2", location.addressLine2);
      setValue("PA_City", location.city);
      setValue("PA_State", stateFilter(location.stateId));

      setValue("PA_Country", countryFilter(location.countryId));
      setValue("PA_Zip_Code", numberToString(location.postalCode));
    }
  }, [setValue, location]);

  useEffect(() => {
    if (assigneeData) {
      setValue("ad_Name_of_Assignee", assigneeData.keyPersonnelName);

      setValue("ad_Address_Line_1", assigneeData.addressMasterId.addressLine1);
      setValue("ad_Address_Line_2", assigneeData.addressMasterId.addressLine2);
      setValue("ad_City", assigneeData.addressMasterId.city);
      setValue("ad_State", stateFilter(assigneeData.addressMasterId.stateId));
      setValue(
        "ad_Zip_Code",
        numberToString(assigneeData.addressMasterId.postalCode)
      );
    }
  }, [setValue, assigneeData]);

  useEffect(() => {
    if (filer) {
      setValue("Filer_Name", filer.keyPersonnelName);
      setValue("Filer_Email_Address", filer.emailId);
      setValue("Filer_Contact_No", extractTenDigitPhoneNumber(filer.contactNo));
      setValue("filer_Address_Line_1", filer.addressMasterId.addressLine1);
      setValue("filer_Address_Line_2", filer.addressMasterId.addressLine2);
      setValue("filer_City", filer.addressMasterId.city);
      setValue("Filer_State", stateFilter(filer.addressMasterId.stateId));
      setValue("Filer_Country", countryFilter(filer.addressMasterId.countryId));
      setValue(
        "filer_Zip_Code",
        numberToString(filer.addressMasterId.postalCode)
      );
    }
  }, [setValue, filer]);

  const handleDebtorChange = (selectedDebtor, index) => {
    setAllDebtorData((prevData) => {
      const updatedDebtor = { [`debtor_${index}`]: selectedDebtor };
      return [
        ...prevData.filter((data) => !data[`debtor_${index}`]),
        updatedDebtor,
      ];
    });

    setValue(`debt_Name_Of_Debtor-${index}`, selectedDebtor.keyPersonnelName);
    setValue(
      `debt_Address_Line_1-${index}`,
      selectedDebtor.addressMasterId.addressLine1
    );
    setValue(
      `debt_Address_Line_2-${index}`,
      selectedDebtor.addressMasterId.addressLine2
    );
    setValue(`debt_City-${index}`, selectedDebtor.addressMasterId.city);
    setValue(
      `debt_State-${index}`,
      stateFilter(selectedDebtor.addressMasterId.stateId)
    );
    setValue(
      `debt_Zip_Code-${index}`,
      numberToString(selectedDebtor.addressMasterId.postalCode)
    );
    const errorMessageTag = document.getElementById(
      `ucc_Debtor_Details-${index + 1}--error`
    );
    if (errorMessageTag) {
      errorMessageTag.textContent = "";
    }
  };

  const handleBeneficialOwnerChange = (selectedDebtor, index) => {
    setAllBeneficialData((prevData) => {
      const updatedDebtor = { [`beneficial_${index}`]: selectedDebtor };
      return [
        ...prevData.filter((data) => !data[`beneficial_${index}`]),
        updatedDebtor,
      ];
    });
    setValue(`boi_First_Name-${index}`, selectedDebtor.firstName);
    setValue(`boi_Middle_Name-${index}`, selectedDebtor.middleName);
    setValue(`boi_Last_Name-${index}`, selectedDebtor.lastName);
    setValue(`boi_Suffix-${index}`, selectedDebtor.suffix);
    setValue(`boi_Email-${index}`, selectedDebtor.email);
    setValue(`boi_Date_of_Birth-${index}`, selectedDebtor.dateOfBirth);
    setValue(
      `boi_Residential_Address-${index}`,
      `${selectedDebtor.residentialAddress}`
    );
    // setValue(`debt_Address_Line_2-${index}`, selectedDebtor.addressMasterId.addressLine2);
    setValue(`boi_City-${index}`, selectedDebtor.city);
    setValue(`boi_Contact_No-${index}`, selectedDebtor.contactNumber);
    setValue(`boi_State-${index}`, selectedDebtor.state);
    setValue(`boi_Country-${index}`, selectedDebtor.country);
    setValue(`boi_Zip_Code-${index}`, numberToString(selectedDebtor.zipCode));
    setValue(`boi_Have_a_FinCEN_ID-${index}`, selectedDebtor.haveFinCenId);
    setValue(`boi_FinCEN_ID_Number-${index}`, selectedDebtor.finCenIdNumber);
    setValue(
      `boi_Last_Name_or_Business_Legal_Name-${index}`,
      selectedDebtor.lastNameOrBusinessLegalName
    );
    setValue(
      `boi_Issuing_Jurisdiction_State-${index}`,
      selectedDebtor.issuingJurisdictionState
    );
    setValue(
      `boi_Issuing_Jurisdiction_Country-${index}`,
      selectedDebtor.issuingJurisdictionCountry
    );
    setValue(
      `boi_Choose_Identity_documents_type_to_upload-${index}`,
      selectedDebtor.documentType
    );
    setValue(`boi_Upload_ID-${index}`, selectedDebtor.uploadId);
    setValue(
      `boi_Upload_the_ID_page_of_your_document-${index}`,
      selectedDebtor.uploadIDPage
    );
    setValue(
      `boi_FinCEN_ID_Document-${index}`,
      selectedDebtor.finCenIdDocument
    );
    setValue(
      `boi_Identifying_Document_No-${index}`,
      selectedDebtor.identifyingDocumentNo
    );
    setValue(`boi_Exempt_Entity-${index}`, selectedDebtor.exemptEntity);

    const errorMessageTag = document.getElementById(
      `Beneficial_Owner_Information-${index + 1}--error`
    );
    if (errorMessageTag) {
      errorMessageTag.textContent = "";
    }
    // if (Array.isArray(selectedDebtor.documentIds)) {
    //     const filteredDocuments = allDocuments.filter(doc => selectedDebtor.documentIds.includes(doc.id));
    //     const uploadIdDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id"));
    //     const uploadIdPageDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id_page_of_your_document"));
    //     if (uploadIdDoc) {
    //         setValue(`boi_Upload_ID-${index}`, uploadIdDoc.documentPath)
    //     }
    //     if (uploadIdPageDoc) {
    //         setValue(`boi_Upload_the_ID_page_of_your_document-${index}`, uploadIdPageDoc.documentPath)
    //     }
    // }
  };

  const handleCompanyApplicantChange = (selectedDebtor, index) => {
    setAllCompanyApplicantData((prevData) => {
      const updatedDebtor = { [`company_applicant_${index}`]: selectedDebtor };
      return [
        ...prevData.filter((data) => !data[`company_applicant_${index}`]),
        updatedDebtor,
      ];
    });
    setValue(`boica_First_Name-${index}`, selectedDebtor.firstName);
    setValue(`boica_Middle_Name-${index}`, selectedDebtor.middleName);
    setValue(`boica_Last_Name-${index}`, selectedDebtor.lastName);
    setValue(`boica_Suffix-${index}`, selectedDebtor.suffix);
    setValue(`boica_Email-${index}`, selectedDebtor.email);
    setValue(`boica_Date_of_Birth-${index}`, selectedDebtor.dateOfBirth);
    setValue(
      `boica_Residential_Address-${index}`,
      `${selectedDebtor.residentialAddress}`
    );
    // setValue(`debt_Address_Line_2-${index}`, selectedDebtor.addressMasterId.addressLine2);
    setValue(`boica_City-${index}`, selectedDebtor.city);
    setValue(`boica_Contact_No-${index}`, selectedDebtor.contactNumber);
    setValue(`boica_State_or_Province-${index}`, selectedDebtor.state);
    setValue(`boica_Country-${index}`, selectedDebtor.country);
    setValue(`boica_Zip_Code-${index}`, numberToString(selectedDebtor.zipCode));
    setValue(`boica_Have_a_FinCEN_ID-${index}`, selectedDebtor.haveFinCenId);
    setValue(`boica_FinCEN_ID_Number-${index}`, selectedDebtor.finCenIdNumber);
    setValue(
      `boica_Issuing_Jurisdiction_State-${index}`,
      selectedDebtor.issuingJurisdictionState
    );
    setValue(
      `boica_Issuing_Jurisdiction_Country-${index}`,
      selectedDebtor.issuingJurisdictionCountry
    );
    setValue(
      `boica_Choose_Identity_documents_type_to_upload-${index}`,
      selectedDebtor.documentType
    );
    setValue(`boica_Upload_ID-${index}`, selectedDebtor.uploadId);
    setValue(
      `boica_Upload_the_ID_page_of_your_document-${index}`,
      selectedDebtor.uploadIDPage
    );
    setValue(
      `boica_FinCEN_ID_Document-${index}`,
      selectedDebtor.finCenIdDocument
    );
    setValue(
      `boica_Identifying_Document_No-${index}`,
      selectedDebtor.identifyingDocumentNo
    );
    const errorMessageTag = document.getElementById(
      `Company_Applicant_Information-${index + 1}--error`
    );
    if (errorMessageTag) {
      errorMessageTag.textContent = "";
    }
    // if (Array.isArray(selectedDebtor.documentIds)) {
    //     const filteredDocuments = allDocuments.filter(doc => selectedDebtor.documentIds.includes(doc.id));
    //     const uploadIdDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id"));
    //     const uploadIdPageDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id_page_of_your_document"));
    //     if (uploadIdDoc) {
    //         setValue(`boica_Upload_ID-${index}`, uploadIdDoc.documentPath)
    //     }
    //     if (uploadIdPageDoc) {
    //         setValue(`boica_Upload_the_ID_page_of_your_document-${index}`, uploadIdPageDoc.documentPath)
    //     }
    // }
  };

  const handleAdditionalDebtorChange = (selectedDebtor, index) => {
    setAllAdditionalDebtors((prevData) => {
      const updatedDebtor = { [`additionalDebtor_${index}`]: selectedDebtor };
      return [
        ...prevData.filter((data) => !data[`additionalDebtor_${index}`]),
        updatedDebtor,
      ];
    });
    setValue(
      `uccAmend_Name_of_Debtor-${index}`,
      selectedDebtor.keyPersonnelName
    );
    setValue(
      `uccAmend_Address_Line_1-${index}`,
      selectedDebtor.addressMasterId.addressLine1
    );
    setValue(
      `uccAmend_Address_Line_2-${index}`,
      selectedDebtor.addressMasterId.addressLine2
    );
    setValue(`uccAmend_City-${index}`, selectedDebtor.addressMasterId.city);
    setValue(
      `uccAmend_State-${index}`,
      stateFilter(selectedDebtor.addressMasterId.stateId)
    );
    setValue(
      `uccAmend_Zip_Code-${index}`,
      numberToString(selectedDebtor.addressMasterId.postalCode)
    );
  };

  const handleAdditionalSecuredPartyChange = (selectedDebtor, index) => {
    setAllSecuredParties((prevData) => {
      const updatedParty = { [`securedParty1_${index}`]: selectedDebtor };
      return [
        ...prevData.filter((data) => !data[`securedParty1_${index}`]),
        updatedParty,
      ];
    });
    setValue(
      `sp_Name_of_Secured_Party-${index}`,
      selectedDebtor.keyPersonnelName
    );
    setValue(
      `sp_Address_Line_1-${index}`,
      selectedDebtor.addressMasterId.addressLine1
    );
    setValue(
      `sp_Address_Line_2-${index}`,
      selectedDebtor.addressMasterId.addressLine2
    );
    setValue(`sp_City-${index}`, selectedDebtor.addressMasterId.city);
    setValue(
      `sp_State-${index}`,
      stateFilter(selectedDebtor.addressMasterId.stateId)
    );
    setValue(
      `sp_Zip_Code-${index}`,
      numberToString(selectedDebtor.addressMasterId.postalCode)
    );
    const errorMessageTag = document.getElementById(
      `ucc_Secured_Party_Details-${index + 1}--error`
    );
    if (errorMessageTag) {
      errorMessageTag.textContent = "";
    }
  };

  const handleAdditionalSecuredPartyChange1 = (selectedDebtor, index) => {
    setAllAdditionalSecuredParties((prevData) => {
      const updatedParty = { [`securedParty2_${index}`]: selectedDebtor };
      return [
        ...prevData.filter((data) => !data[`securedParty2_${index}`]),
        updatedParty,
      ];
    });

    setValue(
      `sec_Name_Of_Secured_Party-${index}`,
      selectedDebtor.keyPersonnelName
    );
    setValue(
      `sec_Address_Line_1-${index}`,
      selectedDebtor.addressMasterId.addressLine1
    );
    setValue(
      `sec_Address_Line_2-${index}`,
      selectedDebtor.addressMasterId.addressLine2
    );
    setValue(`sec_City-${index}`, selectedDebtor.addressMasterId.city);
    setValue(
      `sec_State-${index}`,
      stateFilter(selectedDebtor.addressMasterId.stateId)
    );
    setValue(
      `sec_Zip_Code-${index}`,
      numberToString(selectedDebtor.addressMasterId.postalCode)
    );
    const errorMessageTag = document.getElementById(
      `ucc_Secured_Party_Details-${index + 1}--error`
    );
    if (errorMessageTag) {
      errorMessageTag.textContent = "";
    }
  };

  useEffect(() => {
    if (president) {
      setValue("Pre_Name", president.keyPersonnelName);
      setValue("Pre_Address", president.addressMasterId.addressLine1);
      setValue("Pre_City", president.addressMasterId.city);
      setValue(
        "Pre_Zip_Code",
        numberToString(president.addressMasterId.postalCode)
      );
    }
  }, [setValue, president]);

  useEffect(() => {
    if (treasurer) {
      setValue("Tre_Name", treasurer.keyPersonnelName);
      setValue("Tre_Address", treasurer.addressMasterId.addressLine1);
      setValue("Tre_City", treasurer.addressMasterId.city);
      setValue(
        "Tre_Zip_Code",
        numberToString(treasurer.addressMasterId.postalCode)
      );
    }
  }, [setValue, treasurer]);

  useEffect(() => {
    if (secretary) {
      setValue("Sec_Name", secretary.keyPersonnelName);
      setValue("Sec_Address", secretary.addressMasterId.addressLine1);
      setValue("Sec_City", secretary.addressMasterId.city);
      setValue(
        "Sec_Zip_Code",
        numberToString(secretary.addressMasterId.postalCode)
      );
    }
  }, [setValue, secretary]);

  useEffect(() => {
    if (bilingInfo) {
      setValue("BI_Name", bilingInfo.keyPersonnelName);
      setValue("BI_Email_Address", bilingInfo.emailId);
      setValue(
        "BI_Contact_No",
        extractTenDigitPhoneNumber(bilingInfo.contactNo)
      );
      setValue("BI_Address_Line_1", bilingInfo.addressMasterId.addressLine1);
      setValue("BI_Address_Line_2", bilingInfo.addressMasterId.addressLine2);
      setValue("BI_City", bilingInfo.addressMasterId.city);
      setValue("BI_State", stateFilter(bilingInfo.addressMasterId.stateId));
      setValue(
        "BI_Country",
        countryFilter(bilingInfo.addressMasterId.countryId)
      );
      setValue(
        "BI_Zip_Code",
        numberToString(bilingInfo.addressMasterId.postalCode)
      );
    }
  }, [setValue, bilingInfo]);

  useEffect(() => {
    if (mailingInfo) {
      setValue("MI_Name", mailingInfo.keyPersonnelName);
      setValue("MI_Email_Address", mailingInfo.emailId);
      setValue(
        "MI_Contact_No",
        extractTenDigitPhoneNumber(mailingInfo.contactNo)
      );
      setValue("MI_Address_Line_1", mailingInfo.addressMasterId.addressLine1);
      setValue("MI_Address_Line_2", mailingInfo.addressMasterId.addressLine2);
      setValue("MI_City", mailingInfo.addressMasterId.city);
      setValue("MI_State", stateFilter(mailingInfo.addressMasterId.stateId));
      setValue(
        "MI_Country",
        countryFilter(mailingInfo.addressMasterId.countryId)
      );
      setValue(
        "MI_Zip_Code",
        numberToString(mailingInfo.addressMasterId.postalCode)
      );
    }
  }, [setValue, mailingInfo]);

  useEffect(() => {
    const keys = [
      "MI_Name",
      "MI_Email_Address",
      "MI_Contact_No",
      "MI_Address_Line_1",
      "MI_Address_Line_2",
      "MI_City",
      "MI_State",
      "MI_Country",
      "MI_Zip_Code",
    ];
    if (bilingInfo && IsCheckedAgent1) {
      setValue("MI_Name", bilingInfo.keyPersonnelName);
      setValue("MI_Email_Address", bilingInfo.emailId);
      setValue(
        "MI_Contact_No",
        extractTenDigitPhoneNumber(bilingInfo.contactNo)
      );
      setValue("MI_Address_Line_1", bilingInfo.addressMasterId.addressLine1);
      setValue("MI_Address_Line_2", bilingInfo.addressMasterId.addressLine2);
      setValue("MI_City", bilingInfo.addressMasterId.city);
      setValue("MI_State", stateFilter(bilingInfo.addressMasterId.stateId));
      setValue(
        "MI_Country",
        countryFilter(bilingInfo.addressMasterId.countryId)
      );
      setValue(
        "MI_Zip_Code",
        numberToString(bilingInfo.addressMasterId.postalCode)
      );
      keys.forEach((fieldKey) => {
        const errorMessageTag = document.getElementById(`${fieldKey}--error`);
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      });
    } else {
      // Clear values when conditions aren't met
      keys.forEach((fieldKey) => setValue(fieldKey, ""));
      // if (state) {
      //     setValue("RA_State", stateFilter(state.id));
      // }
    }
  }, [setValue, IsCheckedAgent1]);

  useEffect(() => {
    const keys = [
      "RA_Name",
      "RA_Email_Address",
      "RA_Contact_No",
      "RA_Address_Line_1",
      "RA_Address_Line_2",
      "RA_City",
      "RA_State",
      "RA_Country",
      "RA_Zip_Code",
    ];

    if (registeredAgent && IsCheckedAgent) {
      setValue("RA_Name", registeredAgent.registeredAgencyName);
      setValue("RA_Email_Address", registeredAgent.emailId);
      setValue(
        "RA_Contact_No",
        extractTenDigitPhoneNumber(registeredAgent.phoneNo)
      );
      setValue("RA_Address_Line_1", registeredAgent.addressLine1);
      setValue("RA_Address_Line_2", registeredAgent.addressLine2);
      setValue("RA_City", registeredAgent.city);
      setValue("RA_State", registeredAgent.stateMasterId.stateShortName);
      setValue("RA_Zip_Code", numberToString(registeredAgent.zipcode));

      // Clear error messages for each key
      keys.forEach((fieldKey) => {
        const errorMessageTag = document.getElementById(`${fieldKey}--error`);
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      });
    }
    if (
      registeredAgent &&
      !IsCheckedAgent &&
      getValues("RA_Name") === registeredAgent.registeredAgencyName
    ) {
      keys.forEach((fieldKey) => setValue(fieldKey, ""));
    }
  }, [IsCheckedAgent, registeredAgent]);

  useEffect(() => {
    if (officer) {
      setValue("Off_Name", officer.keyPersonnelName);
      setValue("Off_Email_Address", officer.emailId);
      setValue("Off_Contact_No", extractTenDigitPhoneNumber(officer.contactNo));
      setValue("Of_Address_Line_1", officer.addressMasterId.addressLine1);
      setValue(
        "Of_Address_Line_2",
        officer.addressMasterId.addressLine2
          ? officer.addressMasterId.addressLine2
          : ""
      );
      setValue("Of_City", officer.addressMasterId.city);
      setValue("Of_State", stateFilter(officer.addressMasterId.stateId));
      setValue(
        "Of_Zip_Code",
        numberToString(officer.addressMasterId.postalCode)
      );
      setValue("O_Name", officer.keyPersonnelName);
      setValue("O_Email_Address", officer.emailId);
      setValue("O_Contact_No", extractTenDigitPhoneNumber(officer.contactNo));
      setValue("O_Address_Line_1", officer.addressMasterId.addressLine1);
      setValue(
        "O_Address_Line_2",
        officer.addressMasterId.addressLine2
          ? officer.addressMasterId.addressLine2
          : ""
      );
      setValue("O_City", officer.addressMasterId.city);
      setValue("O_State", stateFilter(officer.addressMasterId.stateId));
      setValue(
        "O_Zip_Code",
        numberToString(officer.addressMasterId.postalCode)
      );
      setDropdownError("Officer");
    }
  }, [setValue, officer]);

  useEffect(() => {
    if (keyPersonnelValue && keyPersonnelKey) {
      setValue(keyPersonnelKey, keyPersonnelValue);

      const match = keyPersonnelKey.match(/-(\d+)$/);
      const index = match ? match[1] : null;

      if (
        index !== null &&
        keyPersonnelKey.includes("ucc_Beneficial_Dropdown")
      ) {
        setAllBeneficialData((prevData) => {
          const updatedDebtor = { [`beneficial_${index}`]: keyPersonnelValue };
          return [
            ...prevData.filter((data) => !data[`beneficial_${index}`]),
            updatedDebtor,
          ];
        });
        setValue(`boi_First_Name-${index}`, keyPersonnelValue.firstName);
        setValue(`boi_Middle_Name-${index}`, keyPersonnelValue.middleName);
        setValue(`boi_Last_Name-${index}`, keyPersonnelValue.lastName);
        setValue(`boi_Suffix-${index}`, keyPersonnelValue.suffix);
        setValue(`boi_Email-${index}`, keyPersonnelValue.email);
        setValue(`boi_Date_of_Birth-${index}`, keyPersonnelValue.dateOfBirth);
        setValue(
          `boi_Residential_Address-${index}`,
          `${keyPersonnelValue.residentialAddress}`
        );
        // setValue(`debt_Address_Line_2-${index}`, keyPersonnelValue.addressMasterId.addressLine2);
        setValue(`boi_City-${index}`, keyPersonnelValue.city);
        setValue(`boi_Contact_No-${index}`, keyPersonnelValue.contactNumber);
        setValue(`boi_State-${index}`, keyPersonnelValue.state);
        setValue(`boi_Country-${index}`, keyPersonnelValue.country);
        setValue(
          `boi_Zip_Code-${index}`,
          numberToString(keyPersonnelValue.zipCode)
        );
        setValue(
          `boi_Have_a_FinCEN_ID-${index}`,
          keyPersonnelValue.haveFinCenId
        );
        setValue(
          `boi_FinCEN_ID_Number-${index}`,
          keyPersonnelValue.finCenIdNumber
        );
        setValue(
          `boi_Last_Name_or_Business_Legal_Name-${index}`,
          keyPersonnelValue.lastNameOrBusinessLegalName
        );
        setValue(
          `boi_Issuing_Jurisdiction_State-${index}`,
          keyPersonnelValue.issuingJurisdictionState
        );
        setValue(
          `boi_Issuing_Jurisdiction_Country-${index}`,
          keyPersonnelValue.issuingJurisdictionCountry
        );
        setValue(
          `boi_Choose_Identity_documents_type_to_upload-${index}`,
          keyPersonnelValue.documentType
        );
        setValue(`boi_Upload_ID-${index}`, keyPersonnelValue.uploadId);
        setValue(
          `boi_Upload_the_ID_page_of_your_document-${index}`,
          keyPersonnelValue.uploadIDPage
        );
        setValue(
          `boi_FinCEN_ID_Document-${index}`,
          keyPersonnelValue.finCenIdDocument
        );
        setValue(
          `boi_Identifying_Document_No-${index}`,
          keyPersonnelValue.identifyingDocumentNo
        );
        setValue(`boi_Exempt_Entity-${index}`, keyPersonnelValue.exemptEntity);

        const errorMessageTag = document.getElementById(
          `Beneficial_Owner_Information-${index + 1}--error`
        );
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      } else if (
        index !== null &&
        keyPersonnelKey.includes("uccAmend_Additional_secured_party_Details")
      ) {
        setAllAdditionalSecuredParties((prevData) => {
          const updatedParty = {
            [`securedParty2_${index}`]: keyPersonnelValue,
          };
          return [
            ...prevData.filter((data) => !data[`securedParty2_${index}`]),
            updatedParty,
          ];
        });

        setValue(
          `sp_Name_of_Secured_Party-${index}`,
          keyPersonnelValue.keyPersonnelName
        );
        setValue(
          `sp_Address_Line_1-${index}`,
          keyPersonnelValue.addressMasterId.addressLine1
        );
        setValue(
          `sp_Address_Line_2-${index}`,
          keyPersonnelValue.addressMasterId.addressLine2
        );
        setValue(`sp_City-${index}`, keyPersonnelValue.addressMasterId.city);
        setValue(
          `sp_State-${index}`,
          stateFilter(keyPersonnelValue.addressMasterId.stateId)
        );
        setValue(
          `sp_Zip_Code-${index}`,
          numberToString(keyPersonnelValue.addressMasterId.postalCode)
        );
      } else if (
        index !== null &&
        keyPersonnelKey.includes("company_applicant_item")
      ) {
        setAllCompanyApplicantData((prevData) => {
          const updatedDebtor = {
            [`company_applicant_${index}`]: keyPersonnelValue,
          };
          return [
            ...prevData.filter((data) => !data[`company_applicant_${index}`]),
            updatedDebtor,
          ];
        });
        setValue(`boica_First_Name-${index}`, keyPersonnelValue.firstName);
        setValue(`boica_Middle_Name-${index}`, keyPersonnelValue.middleName);
        setValue(`boica_Last_Name-${index}`, keyPersonnelValue.lastName);
        setValue(`boica_Suffix-${index}`, keyPersonnelValue.suffix);
        setValue(`boica_Email-${index}`, keyPersonnelValue.email);
        setValue(`boica_Date_of_Birth-${index}`, keyPersonnelValue.dateOfBirth);
        setValue(
          `boica_Residential_Address-${index}`,
          `${keyPersonnelValue.residentialAddress}`
        );
        // setValue(`debt_Address_Line_2-${index}`, keyPersonnelValue.addressMasterId.addressLine2);
        setValue(`boica_City-${index}`, keyPersonnelValue.city);
        setValue(`boica_Contact_No-${index}`, keyPersonnelValue.contactNumber);
        setValue(`boica_State_or_Province-${index}`, keyPersonnelValue.state);
        setValue(`boica_Country-${index}`, keyPersonnelValue.country);
        setValue(
          `boica_Zip_Code-${index}`,
          numberToString(keyPersonnelValue.zipCode)
        );
        setValue(
          `boica_Have_a_FinCEN_ID-${index}`,
          keyPersonnelValue.haveFinCenId
        );
        setValue(
          `boica_FinCEN_ID_Number-${index}`,
          keyPersonnelValue.finCenIdNumber
        );
        setValue(
          `boica_Issuing_Jurisdiction_State-${index}`,
          keyPersonnelValue.issuingJurisdictionState
        );
        setValue(
          `boica_Issuing_Jurisdiction_Country-${index}`,
          keyPersonnelValue.issuingJurisdictionCountry
        );
        setValue(
          `boica_Choose_Identity_documents_type_to_upload-${index}`,
          keyPersonnelValue.documentType
        );
        setValue(`boica_Upload_ID-${index}`, keyPersonnelValue.uploadId);
        setValue(
          `boica_Upload_the_ID_page_of_your_document-${index}`,
          keyPersonnelValue.uploadIDPage
        );
        setValue(
          `boica_FinCEN_ID_Document-${index}`,
          keyPersonnelValue.finCenIdDocument
        );
        setValue(
          `boica_Identifying_Document_No-${index}`,
          keyPersonnelValue.identifyingDocumentNo
        );

        const errorMessageTag = document.getElementById(
          `Company_Applicant_Information-${index + 1}--error`
        );
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      } else if (
        index !== null &&
        keyPersonnelKey.includes("ucc_Debtor_Details_Dropdown")
      ) {
        setAllDebtorData((prevData) => {
          const updatedDebtor = { [`debtor_${index}`]: keyPersonnelValue };
          return [
            ...prevData.filter((data) => !data[`debtor_${index}`]),
            updatedDebtor,
          ];
        });

        setValue(
          `debt_Name_Of_Debtor-${index}`,
          keyPersonnelValue.keyPersonnelName
        );
        setValue(
          `debt_Address_Line_1-${index}`,
          keyPersonnelValue.addressMasterId.addressLine1
        );
        setValue(
          `debt_Address_Line_2-${index}`,
          keyPersonnelValue.addressMasterId.addressLine2
        );
        setValue(`debt_City-${index}`, keyPersonnelValue.addressMasterId.city);
        setValue(
          `debt_State-${index}`,
          stateFilter(keyPersonnelValue.addressMasterId.stateId)
        );
        setValue(
          `debt_Zip_Code-${index}`,
          numberToString(keyPersonnelValue.addressMasterId.postalCode)
        );
        const errorMessageTag = document.getElementById(
          `ucc_Debtor_Details-${index + 1}--error`
        );
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      }
    }
  }, [keyPersonnelValue]);

  useEffect(() => {
    if (allSecuredParties && allSecuredParties.length > 0) {
      allSecuredParties.forEach((data, index) => {
        const keyPersonnelDetails = data[`securedParty1_${index}`];

        if (keyPersonnelDetails) {
          setValue(
            `sec_Name_Of_Secured_Party-${index}`,
            keyPersonnelDetails.keyPersonnelName
          );
          setValue(
            `sec_Address_Line_1-${index}`,
            keyPersonnelDetails.addressMasterId.addressLine1
          );
          setValue(
            `sec_Address_Line_2-${index}`,
            keyPersonnelDetails.addressMasterId.addressLine2
          );
          setValue(
            `sec_City-${index}`,
            keyPersonnelDetails.addressMasterId.city
          );
          setValue(
            `sec_State-${index}`,
            stateFilter(keyPersonnelDetails.addressMasterId.stateId)
          );
          setValue(
            `sec_Zip_Code-${index}`,
            numberToString(keyPersonnelDetails.addressMasterId.postalCode)
          );

          const errorMessageTag = document.getElementById(
            `ucc_Secured_Party_Details-${index + 1}--error`
          );
          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }
        }
      });
    }
  }, [allSecuredParties]);

  useEffect(() => {
    if (allAdditionalDebtors && allAdditionalDebtors.length > 0) {
      allAdditionalDebtors.forEach((data, index) => {
        const keyPersonnelDetails = data[`additionalDebtor_${index}`];

        if (keyPersonnelDetails) {
          setValue(
            `uccAmend_Name_of_Debtor-${index}`,
            keyPersonnelDetails.keyPersonnelName
          );
          setValue(
            `uccAmend_Address_Line_1-${index}`,
            keyPersonnelDetails.addressMasterId.addressLine1
          );
          setValue(
            `uccAmend_Address_Line_2-${index}`,
            keyPersonnelDetails.addressMasterId.addressLine2
          );
          setValue(
            `uccAmend_City-${index}`,
            keyPersonnelDetails.addressMasterId.city
          );
          setValue(
            `uccAmend_State-${index}`,
            stateFilter(keyPersonnelDetails.addressMasterId.stateId)
          );
          setValue(
            `uccAmend_Zip_Code-${index}`,
            numberToString(keyPersonnelDetails.addressMasterId.postalCode)
          );

          const errorMessageTag = document.getElementById(
            `ucc_Secured_Party_Details-${index + 1}--error`
          );
          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }
        }
      });
    }
  }, [allAdditionalDebtors]);

  useEffect(() => {
    if (allAdditionalSecuredParties && allAdditionalSecuredParties.length > 0) {
      allAdditionalSecuredParties.forEach((data, index) => {
        const keyPersonnelDetails = data[`securedParty2_${index}`];

        if (keyPersonnelDetails) {
          setValue(
            `sp_Name_of_Secured_Party-${index}`,
            keyPersonnelDetails.keyPersonnelName
          );
          setValue(
            `sp_Address_Line_1-${index}`,
            keyPersonnelDetails.addressMasterId.addressLine1
          );
          setValue(
            `sp_Address_Line_2-${index}`,
            keyPersonnelDetails.addressMasterId.addressLine2
          );
          setValue(
            `sp_City-${index}`,
            keyPersonnelDetails.addressMasterId.city
          );
          setValue(
            `sp_State-${index}`,
            stateFilter(keyPersonnelDetails.addressMasterId.stateId)
          );
          setValue(
            `sp_Zip_Code-${index}`,
            numberToString(keyPersonnelDetails.addressMasterId.postalCode)
          );
        }
      });
    }
  }, [allAdditionalSecuredParties]);

  useEffect(() => {
    if (allDebtorData && allDebtorData.length > 0) {
      allDebtorData.forEach((data, index) => {
        const keyPersonnelDetails = data[`debtor_${index}`];

        if (keyPersonnelDetails) {
          setValue(`ucc_Debtor_Details_Dropdown-${index}`, keyPersonnelDetails);
          const address = keyPersonnelDetails?.addressMasterId;

          setValue(
            `debt_Name_Of_Debtor-${index}`,
            keyPersonnelDetails?.keyPersonnelName
          );
          setValue(
            `debt_Address_Line_1-${index}`,
            keyPersonnelDetails?.addressMasterId?.addressLine1
          );
          setValue(
            `debt_Address_Line_2-${index}`,
            keyPersonnelDetails?.addressMasterId?.addressLine2
          );
          setValue(
            `debt_City-${index}`,
            keyPersonnelDetails.addressMasterId.city
          );
          setValue(
            `debt_State-${index}`,
            stateFilter(keyPersonnelDetails?.addressMasterId?.stateId)
          );
          setValue(
            `debt_Zip_Code-${index}`,
            numberToString(keyPersonnelDetails?.addressMasterId?.postalCode)
          );

          const errorMessageTag = document.getElementById(
            `ucc_Debtor_Details-${index + 1}--error`
          );
          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }
        }
      });
    }
  }, [allDebtorData]);

  useEffect(() => {
    if (allBeneficialData && allBeneficialData.length > 0) {
      allBeneficialData.forEach((data, index) => {
        const keyPersonnelDetails = data[`beneficial_${index}`];

        if (keyPersonnelDetails) {
          setValue(`boi_First_Name-${index}`, keyPersonnelDetails.firstName);
          setValue(`boi_Middle_Name-${index}`, keyPersonnelDetails.middleName);
          setValue(`boi_Last_Name-${index}`, keyPersonnelDetails.lastName);
          setValue(`boi_Suffix-${index}`, keyPersonnelDetails.suffix);
          setValue(`boi_Email-${index}`, keyPersonnelDetails.email);
          setValue(
            `boi_Date_of_Birth-${index}`,
            keyPersonnelDetails.dateOfBirth
          );
          setValue(
            `boi_Residential_Address-${index}`,
            `${keyPersonnelDetails.residentialAddress}`
          );
          // setValue(`debt_Address_Line_2-${index}`, keyPersonnelDetails.addressMasterId.addressLine2);
          setValue(`boi_City-${index}`, keyPersonnelDetails.city);
          setValue(
            `boi_Contact_No-${index}`,
            keyPersonnelDetails.contactNumber
          );
          setValue(`boi_State-${index}`, keyPersonnelDetails.state);
          setValue(`boi_Country-${index}`, keyPersonnelDetails.country);
          setValue(
            `boi_Zip_Code-${index}`,
            numberToString(keyPersonnelDetails.zipCode)
          );
          setValue(
            `boi_Have_a_FinCEN_ID-${index}`,
            keyPersonnelDetails.haveFinCenId
          );
          setValue(
            `boi_FinCEN_ID_Number-${index}`,
            keyPersonnelDetails.finCenIdNumber
          );
          setValue(
            `boi_Last_Name_or_Business_Legal_Name-${index}`,
            keyPersonnelDetails.lastNameOrBusinessLegalName
          );
          setValue(
            `boi_Issuing_Jurisdiction_State-${index}`,
            keyPersonnelDetails.issuingJurisdictionState
          );
          setValue(
            `boi_Issuing_Jurisdiction_Country-${index}`,
            keyPersonnelDetails.issuingJurisdictionCountry
          );
          setValue(
            `boi_Choose_Identity_documents_type_to_upload-${index}`,
            keyPersonnelDetails.documentType
          );
          setValue(`boi_Upload_ID-${index}`, keyPersonnelDetails.uploadId);
          setValue(
            `boi_Upload_the_ID_page_of_your_document-${index}`,
            keyPersonnelDetails.uploadIDPage
          );
          setValue(
            `boi_FinCEN_ID_Document-${index}`,
            keyPersonnelDetails.finCenIdDocument
          );
          setValue(
            `boi_Identifying_Document_No-${index}`,
            keyPersonnelDetails.identifyingDocumentNo
          );
          setValue(
            `boi_Exempt_Entity-${index}`,
            keyPersonnelDetails.exemptEntity
          );

          const errorMessageTag = document.getElementById(
            `Beneficial_Owner_Information-${index + 1}--error`
          );
          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }
        }
      });
    }
  }, [allBeneficialData]);

  useEffect(() => {
    if (allCompanyApplicantData && allCompanyApplicantData.length > 0) {
      allCompanyApplicantData.forEach((data, index) => {
        const keyPersonnelDetails = data[`company_applicant_${index}`];

        if (keyPersonnelDetails) {
          setValue(`boica_First_Name-${index}`, keyPersonnelDetails.firstName);
          setValue(
            `boica_Middle_Name-${index}`,
            keyPersonnelDetails.middleName
          );
          setValue(`boica_Last_Name-${index}`, keyPersonnelDetails.lastName);
          setValue(`boica_Suffix-${index}`, keyPersonnelDetails.suffix);
          setValue(`boica_Email-${index}`, keyPersonnelDetails.email);
          setValue(
            `boica_Date_of_Birth-${index}`,
            keyPersonnelDetails.dateOfBirth
          );
          setValue(
            `boica_Residential_Address-${index}`,
            `${keyPersonnelDetails.residentialAddress}`
          );
          // setValue(`debt_Address_Line_2-${index}`, keyPersonnelDetails.addressMasterId.addressLine2);
          setValue(`boica_City-${index}`, keyPersonnelDetails.city);
          setValue(
            `boica_Contact_No-${index}`,
            keyPersonnelDetails.contactNumber
          );
          setValue(
            `boica_State_or_Province-${index}`,
            keyPersonnelDetails.state
          );
          setValue(`boica_Country-${index}`, keyPersonnelDetails.country);
          setValue(
            `boica_Zip_Code-${index}`,
            numberToString(keyPersonnelDetails.zipCode)
          );
          setValue(
            `boica_Have_a_FinCEN_ID-${index}`,
            keyPersonnelDetails.haveFinCenId
          );
          setValue(
            `boica_FinCEN_ID_Number-${index}`,
            keyPersonnelDetails.finCenIdNumber
          );
          setValue(
            `boica_Issuing_Jurisdiction_State-${index}`,
            keyPersonnelDetails.issuingJurisdictionState
          );
          setValue(
            `boica_Issuing_Jurisdiction_Country-${index}`,
            keyPersonnelDetails.issuingJurisdictionCountry
          );
          setValue(
            `boica_Choose_Identity_documents_type_to_upload-${index}`,
            keyPersonnelDetails.documentType
          );
          setValue(`boica_Upload_ID-${index}`, keyPersonnelDetails.uploadId);
          setValue(
            `boica_Upload_the_ID_page_of_your_document-${index}`,
            keyPersonnelDetails.uploadIDPage
          );
          setValue(
            `boica_FinCEN_ID_Document-${index}`,
            keyPersonnelDetails.finCenIdDocument
          );
          setValue(
            `boica_Identifying_Document_No-${index}`,
            keyPersonnelDetails.identifyingDocumentNo
          );

          const errorMessageTag = document.getElementById(
            `Company_Applicant_Information-${index + 1}--error`
          );
          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }
        }
      });
    }
  }, [allCompanyApplicantData]);

  useEffect(() => {
    const keys = [
      "MI_Name",
      "MI_Email_Address",
      "MI_Contact_No",
      "MI_Address_Line_1",
      "MI_Address_Line_2",
      "MI_City",
      "MI_State",
      "MI_Country",
      "MI_Zip_Code",
    ];

    if (getValues("RA_Name") !== undefined && IsCheckedAgent2) {
      setValue("MI_Name", getValues("RA_Name"));
      setValue("MI_Email_Address", getValues("RA_Email_Address"));
      setValue("MI_Contact_No", getValues("RA_Contact_No"));
      setValue("MI_Address_Line_1", getValues("RA_Address_Line_1"));
      setValue("MI_Address_Line_2", getValues("RA_Address_Line_2"));
      setValue("MI_City", getValues("RA_City"));
      setValue("MI_State", getValues("RA_State"));
      setValue("MI_Country", getValues("RA_Country"));
      setValue("MI_Zip_Code", getValues("RA_Zip_Code"));

      // Clear error messages for each key
      keys.forEach((fieldKey) => {
        const errorMessageTag = document.getElementById(`${fieldKey}--error`);
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      });
    } else {
      if (!IsCheckedAgent1 && !IsCheckedAgent2) {
        // Clear values when conditions aren't met
        keys.forEach((fieldKey) => setValue(fieldKey, ""));
      }
    }
  }, [company, IsCheckedAgent2, agentLocation, getValues]);

  useEffect(() => {
    if (adminCompany) {
      setValue("X_Name_of_Entity", adminCompany.companyName);

      if (adminCompany.formationDate) {
        setValue("CF_Year_of_formation", adminCompany.formationDate);
      }

      // Clear error messages
      const keys = ["X_Name_of_Entity"];
      keys.forEach((fieldKey) => {
        const errorMessageTag = document.getElementById(`${fieldKey}--error`);
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      });
    }
  }, [adminCompany, setValue]);

  useEffect(() => {
    if (adminCompany) {
      setValue("boicd_Company_Legal_Name", adminCompany.companyName);
      // setAdminCompany(getValues("X_Name_of_Entity"))
      if (adminCompany.formationDate) {
        setValue("CF_Year_of_formation", adminCompany.formationDate);
      }

      try {
        AddressService.getAllAddressByCompanyId(adminCompany?.id)
          .then((res) => {
            const data = res.data;
            if (Array.isArray(data) && data.length > 0) {
              setValue("boicd_City", data[0]?.city);
              setValue("boicd_Company_Address", data[0]?.addressLine1);
              setValue("boicd_Zip_code", data[0]?.postalCode);
              setValue("tmp_City", data[0]?.city);
              setValue("tmp_Address_Line_1", data[0]?.addressLine1);
              setValue(
                "tmp_Address_Line_2",
                data[0]?.addressLine2 ? data[0]?.addressLine2 : null
              );
              setValue("tmp_Zip_Code", data[0]?.postalCode);
            }
          })
          .catch((err) => { });
      } catch (error) { }
      // Clear error messages
      const keys = [
        "X_Name_of_Entity",
        "CF_Year_of_formation",
        "boicd_Company_Legal_Name",
        "boicd_City",
        "boicd_Company_Address",
        "boicd_Zip_code",
      ];
      keys.forEach((fieldKey) => {
        const errorMessageTag = document.getElementById(`${fieldKey}--error`);
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      });
    }
  }, [adminCompany]);

  useEffect(() => {
    if (getValues("X_Name_of_Entity") !== undefined) {
      setAdminCompany(getValues("X_Name_of_Entity"));
      const keys = ["X_Name_of_Entity"];
      keys.forEach((fieldKey) => {
        const errorMessageTag = document.getElementById(`${fieldKey}--error`);
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      });
    }
  }, [setValue, getValues]);

  function flattenObject(obj) {
    let result = {};
    let localFormCount;

    function recurse(current, tempKeySuffix = "") {
      for (let key in current) {
        if (current.hasOwnProperty(key)) {
          if (Array.isArray(current[key])) {
            localFormCount = current[key].length;

            current[key].forEach((item) => {
              if (item.hasOwnProperty("__temp_key__")) {
                const tempKey = item["__temp_key__"];
                recurse(item, `-${tempKey}`);
              }
            });
          } else if (
            typeof current[key] === "object" &&
            current[key] !== null
          ) {
            recurse(current[key], tempKeySuffix);
            localFormCount = 1;
          } else {
            result[`${key}${tempKeySuffix}`] = current[key];
            localFormCount = 1;
          }
        }
      }
    }

    recurse(obj);

    return { flattenedData: result, localFormCount };
  }

  // const handleFile = (e, data) => {
  //
  //   const errorMessageTag = document.getElementById(`${data}--error`);
  //   if (errorMessageTag) {
  //     errorMessageTag.textContent = "";
  //   }
  //   const formdata = new FormData();
  //   formdata.append("file", e.target.files[0]);
  //   setIsTabRefresh(true);
  //   MasterServices.saveFile("orderdoc", formdata)
  //     .then((res) => {
  //
  //       setValue(data, res.data);
  //       if (res.status === 201 || res.status === 200) {
  //         if (formData) {
  //           const temp = formData;
  //           const orderLineItem = updatedOrderLineItems;
  //           const orderDoc = {
  //             documentPath: res.data,
  //             documentName: e.target.files[0]
  //               ? e.target.files[0]?.name
  //               : "file",
  //           };
  //
  //           MasterServices.saveDocument(orderDoc)
  //             .then((res) => {
  //
  //               if (Array.isArray(temp["documentsId"])) {
  //                 temp["documentsId"] = [...temp["documentsId"], res.data];
  //               } else {
  //                 temp["documentsId"] = [res.data];
  //               }
  //               temp["orderLineItems"] = orderLineItem;
  //               temp[
  //                 "orderUpdateNotes"
  //               ] = `${e.target.files[0]?.name} file uploaded`;
  //               temp["orderUpdatedBy"] = loginUserName;

  //
  //               OrderServices.saveAllOrder(temp)
  //                 .then((res) => {
  //
  //                   setIsLoading(false);
  //                   setIsTabRefresh(false);
  //                   // toast.current.show({
  //                   //   severity: "success",
  //                   //   summary: "Success",
  //                   //   detail: "File uploaded successfully",
  //                   //   life: 3000,
  //                   // });
  //                   showToast("success", `File uploaded successfully`);
  //                 })
  //                 .catch((err) => {
  //
  //                   setIsLoading(false);
  //                   setIsTabRefresh(false);
  //                   // if (toast.current !== undefined) {
  //                   //   toast.current.show({
  //                   //     severity: "warn",
  //                   //     summary: "Failed to upload",
  //                   //     detail: "Something went wrong",
  //                   //     life: 3000,
  //                   //   });
  //                   // }
  //                   showToast("error", `Something went wrong`);
  //                 });
  //             })
  //             .catch((err) => {
  //
  //               setIsLoading(false);
  //               setIsTabRefresh(false);

  //               // if (toast.current !== undefined) {
  //               //   toast.current.show({
  //               //     severity: "warn",
  //               //     summary: "Failed to upload",
  //               //     detail: "Something went wrong",
  //               //     life: 3000,
  //               //   });
  //               // }
  //               showToast("error", `Something went wrong`);
  //             });
  //         } else {
  //           setIsTabRefresh(false);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //
  //     });
  // };

  const [docs, setDocs] = useState({});

  const handleFile = (e, data) => {
    const file = e.target.files[0];
    if (!file) return;

    const errorMessageTag = document.getElementById(`${data}--error`);
    if (errorMessageTag) {
      errorMessageTag.textContent = "";
    }

    setDocs((prevDocs) => ({
      ...prevDocs,
      [data]: file,
    }));

    // e.target.value = "";
    setIsTabRefresh(true);
    let uploadedDocs = [];

    Promise.all(
      Object.entries({ ...docs, [data]: file }).map(([key, file]) => {
        const formData = new FormData();
        formData.append("file", file);

        return MasterServices.saveFile("orderdoc", formData)
          .then((res) => {
            setValue(key, res.data);

            if (res.status === 201 || res.status === 200) {
              uploadedDocs.push({
                documentPath: res.data,
                documentName: file.name || "file",
              });

              if (formData) {
                const temp = { ...formData };
                const orderLineItem = updatedOrderLineItems;

                MasterServices.saveDocument({
                  documentPath: res.data,
                  documentName: file.name || "file",
                })
                  .then((resDoc) => {
                    temp["documentsId"] = Array.isArray(temp["documentsId"])
                      ? [...temp["documentsId"], resDoc.data]
                      : [resDoc.data];

                    temp["orderLineItems"] = orderLineItem;
                    temp["orderUpdateNotes"] = `${file.name} file uploaded`;
                    temp["orderUpdatedBy"] = loginUserName;

                    OrderServices.saveAllOrder(temp)
                      .then(() => {
                        setIsLoading(false);
                        setIsTabRefresh(false);
                        showToast("success", `Files uploaded successfully`);
                        setDocs({}); // Reset state after upload
                      })
                      .catch(() => {
                        setIsLoading(false);
                        setIsTabRefresh(false);
                        showToast("error", `Something went wrong`);
                      });
                  })
                  .catch(() => {
                    setIsLoading(false);
                    setIsTabRefresh(false);
                    showToast("error", `Something went wrong`);
                  });
              } else {
                setIsTabRefresh(false);
              }
            }
          })
          .catch((err) => {
            showToast("error", `File failed to upload`);
            setIsTabRefresh(false);
          });
      })
    );
  };

  const getCounty = (id) => {
    if (id) {
      const temp = allCounty.find((val) => val.id === id);

      if (temp) {
        return temp;
      }
    }
    return null;
  };

  useEffect(() => {
    if (editPayloadData) {
      const jsonPayloadData = JSON.parse(
        editPayloadData?.orderDetails?.strapiOrderFormJson
      );

      if (jsonPayloadData) {
        setFormCounts(jsonPayloadData?.formCount);
      }
    }
  }, [editPayloadData]);

  useEffect(() => {
    if (editPayloadData && allInitData.length > 0) {
      setFormData(editPayloadData.orderDetails);
      setUpdatedOrderLineItems(editPayloadData.orderLineItem);
      setServiceDataTemp(editPayloadData.orderLineItem);
      const jsonPayloadData = JSON.parse(
        editPayloadData?.orderDetails?.strapiOrderFormJson
      );

      if (jsonPayloadData) {
        setAdminCompany(jsonPayloadData?.adminCompany);
        setEntityName(jsonPayloadData.EntityType);
        setOrderHeader(jsonPayloadData.Header);
        setState(jsonPayloadData.State);
        setCounty(jsonPayloadData.County);
        setValue("state", jsonPayloadData.State);
        setValue("county", jsonPayloadData.County);
        setValue("COC_What_Are_We_Changing-X", jsonPayloadData.WhatAreChanging);
        setWhatAreChanging(jsonPayloadData.WhatAreChanging);
        
        setIsPaymentDone(editPayloadData.orderDetails.transactionId);
        const strapiData = jsonPayloadData.Payload;
        setStrapiId(editPayloadData.orderDetails.strapiOrderId);
        setOrderTypeId(editPayloadData.orderDetails.orderTypeId);
        // 
        setEinLocation(editPayloadData?.editPayloadData)
        setBilingAddress(jsonPayloadData.billingAddress);
        setShippingAddress(
          jsonPayloadData.shipingAddress
            ? jsonPayloadData.shipingAddress
            : jsonPayloadData.billingAddress
          );
        setAddOnData(jsonPayloadData.addOnService);
        setAddOnServiceData(jsonPayloadData.addOnService);
        if (
          jsonPayloadData?.step &&
          jsonPayloadData?.totalStep &&
          typeof jsonPayloadData?.step === "number"
        ) {
          setMoveToStepData(jsonPayloadData?.step);
          setTotalStepData(jsonPayloadData?.totalStep);
        }
        console.log(jsonPayloadData, "jsonPayloadData")
        const { flattenedData, localFormCount } = flattenObject(strapiData);


        if (typeof flattenedData === "object") {
          Object.keys(flattenedData).map((key, i) => {
            setValue(key, flattenedData[key]); 
            console.log(key,"ucc type colateral")
            if (key === "ucc_Type_Your_Collateral_Statement") {
              const editorElement = document.querySelector(".ql-editor");
              if (editorElement) {
                editorElement.innerHTML = flattenedData[key]; 
              }
            }
          });
        }
        if (
          jsonPayloadData?.keyPersonalStrapiArr &&
          Array.isArray(jsonPayloadData.keyPersonalStrapiArr)
        ) {
          setKeyPersonnelStrapiArray(jsonPayloadData.keyPersonalStrapiArr);
          jsonPayloadData.keyPersonalStrapiArr.forEach((val) => {
            if (val?.type === "ORGANIZER") {
              setOrganizerData(val?.data);
            }

            if (val?.type === "OFFICER") {
              setOfficer(val?.data);
            }
            if (val?.type === "INCORPORATOR") {
              setIncorporator(val?.data);
            }
            if (val?.type === "DIRECTOR") {
              setDirector(val?.data);
            }
            if (val?.type === "FILER") {
              setFiler(val?.data);
            }
            if (val?.type === "PRESIDENT") {
              setPresident(val?.data);
            }
            if (val?.type === "TREASURER") {
              setTreasurer(val?.data);
            }
            if (val?.type === "SECRETARY") {
              setSecretary(val?.data);
            }

            if (val?.type === "DEBTOR") {
              if (Array.isArray(val?.data)) {
                val?.data.forEach((elem, l) => {
                  setValue(`ucc_Debtor_Details_Dropdown-${l}`, elem);
                });
                const tempData = val?.data.map((elem, index) => {
                  const key = `debtor_${index}`;
                  setValue(key, elem);
                  return { [key]: elem };
                });
                setAllDebtorData(tempData);
              }
            }

            if (val?.type === "ADDITIONAL DEBTOR") {
              if (Array.isArray(val?.data)) {
                val?.data.forEach((elem, l) => {
                  setValue(`ucc_Debtor_Details_Dropdown-${l}`, elem);
                });
                const tempData = val?.data.map((elem, index) => {
                  const key = `additionalDebtor_${index}`;
                  setValue(key, elem);
                  return { [key]: elem };
                });
                setAllAdditionalDebtors(tempData);
              }
            }

            if (val?.type === "SECURED PARTY") {
              if (Array.isArray(val?.data)) {
                val?.data.forEach((elem, l) => {
                  setValue(
                    `uccAmend_Additional_secured_party_Details-${l}`,
                    elem
                  );
                });
                const tempData = val?.data.map((elem, index) => {
                  const key = `securedParty1_${index}`;
                  setValue(key, elem);
                  return { [key]: elem };
                });
                setAllSecuredParties(tempData);
              }
            }

            if (val?.type === "ADDITIONAL SECURED PARTY") {
              if (Array.isArray(val?.data)) {
                val?.data.forEach((elem, l) => {
                  setValue(
                    `uccAmend_Additional_secured_party_Details-${l}`,
                    elem
                  );
                });
                const tempData = val?.data.map((elem, index) => {
                  const key = `securedParty2_${index}`;
                  setValue(key, elem);
                  return { [key]: elem };
                });
                setAllAdditionalSecuredParties(tempData);
              }
            }

            if (val?.type === "BENEFICIAL OWNER") {
              if (Array.isArray(val?.data)) {
                val?.data.forEach((elem, l) => {
                  setValue(`ucc_Beneficial_Dropdown-${l}`, elem);
                });
                const tempData = val?.data.map((elem, index) => {
                  const key = `beneficial_${index}`;
                  setValue(key, elem);
                  return { [key]: elem };
                });

                setAllBeneficialData(tempData);
              }
            }

            if (val?.type === "COMPANY APPLICANT") {
              if (Array.isArray(val?.data)) {
                val?.data.forEach((elem, l) => {
                  setValue(`company_applicant_item_${l}`, elem);
                });
                const tempData = val?.data.map((elem, index) => {
                  const key = `company_applicant_${index}`;
                  setValue(key, elem);
                  return { [key]: elem };
                });

                setAllCompanyApplicantData(tempData);
              }
            }
          });
        }
        
      }
    }
  }, [editPayloadData, allInitData]);

  useEffect(() => {
    const data = watch();

    const tem = allModels.map((val) => {
      const temps = Object.keys(data).reduce((acc, key) => {
        if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      const convertdata = val.apiId;

      return {
        model: convertdata,
        data: temps,
      };
    });

    const tempsData = allModels.map((val) => {
      const temps = Object.keys(data).reduce((acc, key) => {
        if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
          acc[key] = data[key];
        }

        return acc;
      }, {});

      const convertdata = val.uid;

      if (
        convertdata === "momm.name-and-address" ||
        convertdata === "momm.option-name-address"
      ) {
        const addressFields = [
          "MM_Address_Line_1",
          "MM_Address_Line_2",
          "MM_City",
          "MM_State",
          "MM_Country",
          "MM_Zip_Code",
        ];
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize an object for this index if it doesn't exist
              if (!acc[index]) {
                acc[index] = { __temp_key__: parseInt(index), Address: {} };
              }

              if (addressFields.includes(baseKey)) {
                // If it's an address field, add it to the Address object
                acc[index].Address[baseKey] = data[key];
              } else {
                // Otherwise, add it directly to the object
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "debtor-details.debtor") {
        const addressFields = [
          "debt_Address_Line_1",
          "debt_Address_Line_2",
          "debt_City",
          "debt_State",
          "debt_Country",
          "debt_Zip_Code",
        ];
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize an object for this index if it doesn't exist
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  ucc_Address_Of_Debtor: {},
                };
              }

              if (addressFields.includes(baseKey)) {
                // If it's an address field, add it to the Address object
                acc[index].ucc_Address_Of_Debtor[baseKey] = data[key];
              } else if (
                baseKey.startsWith("debt") &&
                !addressFields.includes(baseKey)
              ) {
                // Otherwise, add it directly to the object
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "ucc-amend.uuc-amnd-what-are-amending") {
        const addressFields = [
          "uccAmend_Address_Line_1",
          "uccAmend_Address_Line_2",
          "uccAmend_City",
          "uccAmend_State",
          "uccAmend_Country",
          "uccAmend_Zip_Code",
          "uccAmend_Name_of_Debtor",
        ];

        const spAddressFields = [
          "sp_Name_of_Secured_Party",
          "sp_Address_Line_1",
          "sp_Address_Line_2",
          "sp_City",
          "sp_State",
          "sp_Zip_Code",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize the uccAmend_Additional_Debtor_Details array if not already created
              if (!acc.Additional_Secured_Party_Details) {
                acc.Additional_Secured_Party_Details = [];
              }

              // Find the corresponding object in Additional_Secured_Party_Details or create a new one
              let debtorDetails1 = acc.Additional_Secured_Party_Details.find(
                (item) => item.__temp_key__ === parseInt(index)
              );

              if (!debtorDetails1) {
                debtorDetails1 = { __temp_key__: parseInt(index) };
                acc.Additional_Secured_Party_Details.push(debtorDetails1);
              }

              // Initialize the uccAmend_Additional_Debtor_Details array if not already created
              if (!acc.uccAmend_Additional_Debtor_Details) {
                acc.uccAmend_Additional_Debtor_Details = [];
              }

              // Find the corresponding object in uccAmend_Additional_Debtor_Details or create a new one
              let debtorDetails = acc.uccAmend_Additional_Debtor_Details.find(
                (item) => item.__temp_key__ === parseInt(index)
              );

              if (!debtorDetails) {
                debtorDetails = { __temp_key__: parseInt(index) };
                acc.uccAmend_Additional_Debtor_Details.push(debtorDetails);
              }
              if (baseKey.startsWith("sp")) {
                // Add address fields to debtorDetails
                if (spAddressFields.includes(baseKey)) {
                  debtorDetails1[baseKey] = data[key];
                }
              } else {
                // Add address fields to debtorDetails
                if (addressFields.includes(baseKey)) {
                  debtorDetails[baseKey] = data[key];
                }
              }
            }
          } else if (
            key.startsWith("uccAmend") &&
            !addressFields.includes(key)
          ) {
            // For non-indexed fields, add them directly to the main object
            acc[key] = data[key];
          }

          return acc;
        }, {});

        return {
          model: convertdata,
          data: formData,
        };
      } else if (convertdata === "secured-party.secured-party") {
        const addressFields = [
          "sec_Address_Line_1",
          "sec_Address_Line_2",
          "sec_City",
          "sec_State",
          "sec_Country",
          "sec_Zip_Code",
        ];
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize an object for this index if it doesn't exist
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  ucc_Address_Of_Secured_Party: {},
                };
              }

              if (addressFields.includes(baseKey)) {
                // If it's an address field, add it to the Address object
                acc[index].ucc_Address_Of_Secured_Party[baseKey] = data[key];
              } else if (
                baseKey.startsWith("sec") &&
                !addressFields.includes(baseKey)
              ) {
                // Otherwise, add it directly to the object
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "boi.boi-company-applicant-details") {
        const companyApplicantDetails = [
          "boica_First_Name",
          "boica_Middle_Name",
          "boica_Last_Name",
          "boica_Suffix",
          "boica_Date_of_Birth",
          "boica_Email",
          "boica_Contact_No",
          "boica_Residential_Address",
          "boica_Country",
          "boica_City",
          "boica_State_or_Province",
          "boica_Zip_Code",
          "boica_Choose_Identity_document_type_to_upload",
          "boica_Upload_the_ID_page_of_your_document",
          "boica_Upload_ID",
          "boica_Have_a_FinCEN_ID",
          "boica_FinCEN_ID_Number",
          "boica_Upload_the_ID_page_of_your_document",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = { __temp_key__: parseInt(index) };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "boi-except-entity-form") {
        const companyApplicantDetails = [
          "boi_Last_Name_or_Business_Legal_Name",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  boi_Exempt_Entity_Information: {},
                };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[index].boi_Exempt_Entity_Information[baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "boi.boi-beneficial-owner-form") {
        const companyApplicantDetails = [
          "boi_First_Name",
          "boi_Middle_Name",
          "boi_Last_Name",
          "boi_Suffix",
          "boi_Date_of_Birth",
          "boi_Email",
          "boi_Contact_No",
          "boi_Residential_Address",
          "boi_Country",
          "boi_City",
          "boi_State",
          "boi_Zip_Code",
          "boi_Choose_Identity_document_type_to_upload",
          "boi_Upload_the_ID_page_of_your_document",
          "boi_Upload_ID",
          "boi_Have_a_FinCEN_ID",
          "boi_FinCEN_ID",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  boi_Beneficial_Owner_Information: {},
                };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[index].boi_Beneficial_Owner_Information[baseKey] =
                  data[key];
              } else if (
                baseKey.startsWith("boi") &&
                !baseKey.startsWith("boica") &&
                !companyApplicantDetails.includes(baseKey)
              ) {
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else {
        const formData = Object.keys(data).map((keys, j) => {
          if (keys.includes("-")) {
            const baseKey = keys.split("-")[0];

            if (
              baseKey &&
              Object.keys(val.schema.attributes).some((ele) => ele === baseKey)
            ) {
              return {
                __temp_key__: j + 1,
                [baseKey]: data[keys],
              };
            }
          }
        });

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      }
    });

    setModelData(tem);
    setModelData1(tempsData);
  }, [getValues, setValue, currentTab, formCounts]);

  function processFormData(allModels) {
    const data = watch();

    return allModels.map((val) => {
      const temps = Object.keys(data).reduce((acc, key) => {
        if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      const convertdata = val.uid;

      if (convertdata === "boi.boi-company-details") {
        Object.assign(temps, {
          ActivityPartyTypeCode: "62",
          PartyNameTypeCode: "L",
          BusinessAddressIndicator: "Y",
          ResidentialAddressIndicator: "Y",
        });
      }

      const handleIndexedFields = (addressFields, nestedFieldName) => {
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            if (baseKey) {
              if (!acc[index]) {
                acc[index] = { __temp_key__: parseInt(index) };
              }
              if (addressFields.includes(baseKey)) {
                if (!acc[index][nestedFieldName]) {
                  acc[index][nestedFieldName] = {};
                }
                acc[index][nestedFieldName][baseKey] = data[key];
              } else {
                acc[index][baseKey] = data[key];
              }
            }
          }
          return acc;
        }, []);
        return formData.filter((val) => val !== undefined);
      };

      if (
        convertdata === "momm.name-and-address" ||
        convertdata === "momm.option-name-address"
      ) {
        const addressFields = [
          "MM_Address_Line_1",
          "MM_Address_Line_2",
          "MM_City",
          "MM_State",
          "MM_Country",
          "MM_Zip_Code",
        ];
        const formDatas = handleIndexedFields(addressFields, "Address");
        return {
          model: convertdata,
          data: formDatas.length > 0 ? formDatas : temps,
        };
      } else if (convertdata === "debtor-details.debtor") {
        const addressFields = [
          "debt_Address_Line_1",
          "debt_Address_Line_2",
          "debt_City",
          "debt_State",
          "debt_Country",
          "debt_Zip_Code",
        ];
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize an object for this index if it doesn't exist
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  ucc_Address_Of_Debtor: {},
                };
              }

              if (addressFields.includes(baseKey)) {
                // If it's an address field, add it to the Address object
                acc[index].ucc_Address_Of_Debtor[baseKey] = data[key];
              } else if (
                baseKey.startsWith("debt") &&
                !addressFields.includes(baseKey)
              ) {
                // Otherwise, add it directly to the object
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "secured-party.secured-party") {
        const addressFields = [
          "sec_Address_Line_1",
          "sec_Address_Line_2",
          "sec_City",
          "sec_State",
          "sec_Country",
          "sec_Zip_Code",
        ];
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize an object for this index if it doesn't exist
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  ucc_Address_Of_Secured_Party: {},
                };
              }

              if (addressFields.includes(baseKey)) {
                // If it's an address field, add it to the Address object
                acc[index].ucc_Address_Of_Secured_Party[baseKey] = data[key];
              } else if (
                baseKey.startsWith("sec") &&
                !addressFields.includes(baseKey)
              ) {
                // Otherwise, add it directly to the object
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      }

      if (convertdata === "boi.company-applicant") {
        const companyApplicantDetails = [
          "boica_First_Name",
          "boica_Middle_Name",
          "boica_Last_Name",
          "boica_Suffix",
          "boica_Date_of_Birth",
          "boica_Email",
          "boica_Contact_No",
          "boica_Residential_Address",
          "boica_Country",
          "boica_City",
          "boica_State_or_Province",
          "boica_Zip_Code",
          "boica_Choose_Identity_document_type_to_upload",
          "boica_Upload_the_ID_page_of_your_document",
          "boica_Upload_ID",
          "boica_Have_a_FinCEN_ID",
          "boica_FinCEN_ID_Number",
          "boica_Upload_the_ID_page_of_your_document",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            const parsedIndex = parseInt(index);

            if (
              !isNaN(parsedIndex) &&
              parsedIndex < (formCounts["Company_Applicant_Information"] || 1)
            ) {
              if (!acc[parsedIndex]) {
                acc[parsedIndex] = { __temp_key__: parsedIndex };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[parsedIndex][baseKey] = data[key];
              } else if (
                baseKey.startsWith("boica") &&
                !baseKey.startsWith("boi") &&
                !companyApplicantDetails.includes(baseKey)
              ) {
                acc[parsedIndex][baseKey] = data[key];
              }
            }
          }
          return acc;
        }, []);

        const manualEntries = [
          { key: "ActivityPartyTypeCode", value: "63" },
          { key: "PartyNameTypeCode", value: "L" },
          { key: "BusinessAddressIndicator", value: "Y" },
          { key: "ResidentialAddressIndicator", value: "Y" },
        ];

        manualEntries.forEach((entry) => {
          formData.forEach((formObj, index) => {
            if (!formObj[entry.key]) {
              formObj[entry.key] = entry.value;
            }
          });
        });
        formData.forEach((formObj) => {
          const identityDocumentType =
            formObj["boica_Choose_Identity_document_type_to_upload"];
          let partyIdentificationTypeCode;

          if (identityDocumentType === "State Issued Drivers License") {
            partyIdentificationTypeCode = 37;
          } else if (identityDocumentType === "US Passport") {
            partyIdentificationTypeCode = 39;
          } else if (identityDocumentType === "Foreign Passport") {
            partyIdentificationTypeCode = 40;
          }
          if (partyIdentificationTypeCode !== undefined) {
            formObj["PartyIdentificationTypeCode"] =
              partyIdentificationTypeCode;
          }
        });

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "boi.beneficial-owner-info") {
        const companyApplicantDetails = [
          "boi_First_Name",
          "boi_Middle_Name",
          "boi_Last_Name",
          "boi_Suffix",
          "boi_Date_of_Birth",
          "boi_Email",
          "boi_Contact_No",
          "boi_Residential_Address",
          "boi_Country",
          "boi_City",
          "boi_State",
          "boi_Zip_Code",
          "boi_Choose_Identity_document_type_to_upload",
          "boi_Upload_the_ID_page_of_your_document",
          "boi_Upload_ID",
          "boi_Have_a_FinCEN_ID",
          "boi_FinCEN_ID_Number",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");
            const parsedIndex = parseInt(index);

            if (
              !isNaN(parsedIndex) &&
              parsedIndex < (formCounts["Beneficial_Owner_Information"] || 1)
            ) {
              if (!acc[parsedIndex]) {
                acc[parsedIndex] = {
                  __temp_key__: parsedIndex,
                  boi_Beneficial_Owner_Information: {},
                };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[parsedIndex].boi_Beneficial_Owner_Information[baseKey] =
                  data[key];
              } else if (
                baseKey.startsWith("boi") &&
                !baseKey.startsWith("boica") &&
                !companyApplicantDetails.includes(baseKey)
              ) {
                acc[parsedIndex][baseKey] = data[key];
              }
            }
          }
          return acc;
        }, []);

        const manualEntries = [
          { key: "ActivityPartyTypeCode", value: "64" },
          { key: "PartyNameTypeCode", value: "L" },
          { key: "BusinessAddressIndicator", value: "Y" },
          { key: "ResidentialAddressIndicator", value: "Y" },
        ];

        manualEntries.forEach((entry) => {
          formData.forEach((formObj, index) => {
            if (!formObj[entry.key]) {
              formObj[entry.key] = entry.value;
            }
          });
        });
        formData.forEach((formObj) => {
          const identityDocumentType =
            formObj["boi_Choose_Identity_document_type_to_upload"];
          let partyIdentificationTypeCode;

          if (identityDocumentType === "State Issued Drivers License") {
            partyIdentificationTypeCode = 37;
          } else if (identityDocumentType === "US Passport") {
            partyIdentificationTypeCode = 39;
          } else if (identityDocumentType === "Foreign Passport") {
            partyIdentificationTypeCode = 40;
          }

          if (partyIdentificationTypeCode !== undefined) {
            formObj["PartyIdentificationTypeCode"] =
              partyIdentificationTypeCode;
          }
        });

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "boi-except-entity-form") {
        const companyApplicantDetails = [
          "boi_Last_Name_or_Business_Legal_Name",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              if (!acc[index]) {
                acc[index] = {
                  __temp_key__: parseInt(index),
                  boi_Exempt_Entity_Information: {},
                };
              }

              if (companyApplicantDetails.includes(baseKey)) {
                acc[index].boi_Exempt_Entity_Information[baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else if (convertdata === "ucc-amend.uuc-amnd-what-are-amending") {
        const addressFields = [
          "uccAmend_Address_Line_1",
          "uccAmend_Address_Line_2",
          "uccAmend_City",
          "uccAmend_State",
          "uccAmend_Country",
          "uccAmend_Zip_Code",
          "uccAmend_Name_of_Debtor",
        ];

        const spAddressFields = [
          "sp_Name_of_Secured_Party",
          "sp_Address_Line_1",
          "sp_Address_Line_2",
          "sp_City",
          "sp_State",
          "sp_Zip_Code",
        ];

        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize the uccAmend_Additional_Debtor_Details array if not already created
              if (!acc.Additional_Secured_Party_Details) {
                acc.Additional_Secured_Party_Details = [];
              }

              // Find the corresponding object in Additional_Secured_Party_Details or create a new one
              let debtorDetails1 = acc.Additional_Secured_Party_Details.find(
                (item) => item.__temp_key__ === parseInt(index)
              );

              if (!debtorDetails1) {
                debtorDetails1 = { __temp_key__: parseInt(index) };
                acc.Additional_Secured_Party_Details.push(debtorDetails1);
              }

              // Initialize the uccAmend_Additional_Debtor_Details array if not already created
              if (!acc.uccAmend_Additional_Debtor_Details) {
                acc.uccAmend_Additional_Debtor_Details = [];
              }

              // Find the corresponding object in uccAmend_Additional_Debtor_Details or create a new one
              let debtorDetails = acc.uccAmend_Additional_Debtor_Details.find(
                (item) => item.__temp_key__ === parseInt(index)
              );

              if (!debtorDetails) {
                debtorDetails = { __temp_key__: parseInt(index) };
                acc.uccAmend_Additional_Debtor_Details.push(debtorDetails);
              }
              if (baseKey.startsWith("sp")) {
                // Add address fields to debtorDetails
                if (spAddressFields.includes(baseKey)) {
                  debtorDetails1[baseKey] = data[key];
                }
              } else {
                // Add address fields to debtorDetails
                if (addressFields.includes(baseKey)) {
                  debtorDetails[baseKey] = data[key];
                }
              }
            }
          } else if (
            key.startsWith("uccAmend") &&
            !addressFields.includes(key)
          ) {
            // For non-indexed fields, add them directly to the main object
            acc[key] = data[key];
          }

          return acc;
        }, {});

        return {
          model: convertdata,
          data: formData,
        };
      }

      return { model: convertdata, data: temps };
    });
  }

  function flattenArray(array) {
    return array.map((item) => {
      const result = {};
      for (const key in item) {
        if (Array.isArray(item[key])) {
          result[key] = item[key].flat();
        }
      }
      return result;
    });
  }

  // function reorderAttributes(a, b) {
  //     const aObj = a[0];

  //     return b.map(item => {
  //         const apiID = item.apiID;
  //         if (aObj[apiID]) {
  //             const order = aObj[apiID].map(attr => attr.name);
  //             const newAttributes = {};

  //             order.forEach(key => {
  //                 if (item.attributes[key]) {
  //                     newAttributes[key] = item.attributes[key];
  //                 }
  //             });

  //             for (const key in item.attributes) {
  //                 if (!newAttributes[key]) {
  //                     newAttributes[key] = item.attributes[key];
  //                 }
  //             }

  //             item.attributes = newAttributes;
  //         }

  //         return item;
  //     });
  // }

  function reorderAttributes(a, b) {
    const temp = b.map((item) => {
      for (let i = 0; i < a.length; i++) {
        const aObj = a[i];

        const apiID = item.schema.singularName;

        if (aObj[apiID]) {
          const order = aObj[apiID].map((attr) => attr.name);
          const newAttributes = {};

          order.forEach((key) => {
            if (item.schema.attributes[key] && key) {
              newAttributes[key] = item.schema.attributes[key];
            }
          });

          // for (const key in item.schema.attributes) {
          //     if (!newAttributes[key]) {
          //         newAttributes[key] = item.schema.attributes[key];
          //     }
          // }

          item.schema.attributes = newAttributes;
        }
      }
      return item;
    });

    return temp;
  }

  useEffect(() => {
    const firstData = addOnData
      ? addOnData === "kit" || addOnData === "cgs"
        ? state !== null && enityName !== null
          ? typeof enityName.entityShortName === "string" &&
            typeof state.strapiDisplayName === "string"
            ? state.strapiDisplayName.toLowerCase() +
            "-" +
            enityName.entityShortName.toLowerCase() +
            "-" +
            addOnData
            : ""
          : ""
        : addOnData
      : state !== null && enityName !== null
        ? typeof enityName.entityShortName === "string" &&
          typeof state.strapiDisplayName === "string"
          ? state.strapiDisplayName.toLowerCase() +
          "-" +
          enityName.entityShortName.toLowerCase()
          : ""
        : "";

    const getTargetModels = (attributes) => {
      return Object.keys(attributes).flatMap((key) => {
        if (attributes[key].type === "component" && attributes[key].component) {
          const targetModel = attributes[key].component;

          return targetModel;
        }
        return [];
      });
    };

    const fetchConfigurations = async (models) => {
      const fetchPromises = models.map(async (model) => {
        return model;
      });

      const results = await Promise.all(fetchPromises);
      return results.filter((val) => val !== undefined);
    };

    const processModels = async (models, processedModels = new Set()) => {
      let allModelsData = [...models];

      const targetModels = models.flatMap((model) =>
        getTargetModels(model.schema.attributes)
      );

      const uniqueTargetModels = [...new Set(targetModels)];

      if (uniqueTargetModels.length === 0) return allModelsData;

      const targetModelsData = allComponents.filter(
        (val) =>
          uniqueTargetModels.includes(val.uid) && !processedModels.has(val.uid)
      );

      allModelsData = [...allModelsData, ...targetModelsData];
      targetModelsData.forEach((val) => processedModels.add(val.uid));

      const configurations = await fetchConfigurations(targetModelsData);

      const flatArr = flattenArray(configurations);

      // Replace attributes with nested relations
      const updatedModels = await replaceNestedRelations(
        flatArr,
        allModelsData
      );

      return updatedModels;
    };

    const processAttributes = async (attributes, processedModels) => {
      const result = {};
      for (let key in attributes) {
        if (attributes[key].type === "component") {
          // const targetModel = attributes[key].target.split("::")[1].split(".")[0];
          const targetModel = attributes[key].component;

          const relatedModel = allComponents.find(
            (model) => model.uid === targetModel
          );

          if (relatedModel && !processedModels.has(relatedModel.uid)) {
            processedModels.add(relatedModel.uid);
            result[key] = await processModel(relatedModel, processedModels);
          } else {
            result[key] = {};
          }
        } else {
          result[key] = attributes[key];
        }
      }
      return result;
    };

    const processModel = async (model, processedModels) => {
      const newModel = { ...model };

      newModel.schema.attributes = await processAttributes(
        newModel.schema.attributes,
        processedModels
      );
      return newModel;
    };

    const replaceNestedRelations = async (flatArr, allModelsData) => {
      const result = [];
      for (const model of allModelsData) {
        const newModel = await processModel(model, new Set());

        result.push(newModel);
      }
      return result;
    };

    if (firstData !== "") {
      if (enityName !== "" && state !== null) {
        setLoading1(false);
      }

      const initialModels = allInitData.filter(
        (val) => val.apiID === firstData
      );

      // console.log(rawModel)
      // const tempInitial = rawModel.filter((val) => val.apiID === firstData)
      // console.log(tempInitial)
      if (initialModels.length > 0) {
        setGroupInitialData(initialModels[0].schema.attributes);
      }

      if (initialModels.length > 0) {
        setMainModel(initialModels[0]);
        processModels(initialModels)
          .then((finalModels) => {
            // const cleanedData = finalModels.reduce((acc, model) => {
            //     acc[model.apiID] = model;
            //     return acc;
            // }, {});
            const temp = finalModels.filter((val) => val.apiID === firstData);
            if (temp.length > 0) {
              setLoading1(false);
            }
            setInputFormData(temp);
          })
          .catch((err) => { });
      }
    }
  }, [enityName, state, allInitData, rawModel, editPayloadData, addOnData]);

  useEffect(() => {
    const firstData =
      typeof enityName === "string" && typeof state === "string"
        ? state.toLowerCase() + "-" + enityName.toLowerCase()
        : "";
    if (firstData !== "") {
      setModelName(firstData);
    }
  }, [enityName, state]);

  const fetchOptions = async (url) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const checkAvailability = async (value) => {
    setLoading(true);
    setError("");
    try {
      // const response = await axios.get(`https://api.example.com/check-availability?value=${value}`);
      // setIsAvailable(response.data.isAvailable);
    } catch (err) {
      setError("Error checking availability");
    }
    setLoading(false);
  };
  // Current tab is set to be the first tab (0)
  useEffect(() => {
    showTab(currentTab);
  }, [currentTab]);

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error-msg font-fam-for-all ">
        {errors[name].message}
      </small>
    ) : (
      <small className="error-msg "></small>
    );
  };

  function convertName(originalString) {
    if (!originalString.includes("_")) {
      return originalString;
    }

    const parts = originalString.split("_");

    const relevantParts = parts.slice(1);

    // Capitalize the first letter of each part and join them with a space
    const formattedString = relevantParts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");

    return formattedString;
  }

  const renderFormField = (
    column,
    key,
    val,
    value,
    modelname,
    componentName = null
  ) => {
    const errorMessageTag = document.getElementById(`${val}--error`);

    const tempval = convertName(key);

    const options = optionsMap?.[tempval] || [];

    // if (options.length > 0) {
    //     console.log(options)
    //     const matchedItem = tempdata.find(item => item.item === tempval);

    //     if (matchedItem) {
    //         if (matchedItem.label==="stateFullDesc") {
    //             if (state) {
    //                 setValue(val,state.stateFullDesc)
    //             }
    //         }
    //         if (matchedItem.label==="countyName") {
    //             if (county) {
    //                 setValue(val,county.countyName)
    //             }
    //         }
    //         if (matchedItem.label==="countryShortName") {
    //             if (state) {
    //                 setValue(val,state.countryMaster.countryShortName)
    //             }
    //         }
    //     }
    // }

    const COA_What_Are_We_Amending = getValues("COA_What_Are_We_Amending");

    const What_Are_We_Changing = getValues("COC_What_Are_We_Changing-X");

    const cc_What_Type_Document = getValues("cc_What_Type_Document");

    const pricipal_activity = getValues("EIN_Principal_activity_of_business");

    const ucc_UCC_Collateral_Type = getValues("ucc_UCC_Collateral_Type");
    const ucc_Collateral_Statement = getValues("ucc_Collateral_Statement");
    // const ucc_Type_Your_Collateral_Statement = getValues("ucc_Type_Your_Collateral_Statement");
    // const ucc_Type_Your_Collateral_Statement = watch("ucc_Type_Your_Collateral_Statement");
    const finCenKey = `boi_Have_a_FinCEN_ID-${val.split("-")[1] || 0}`;
    const finCenKeyCA = `boica_Have_a_FinCEN_ID-${val.split("-")[1] || 0}`;
    const finCenId = getValues(finCenKey);
    const finCenIdCA = getValues(finCenKeyCA);


    const excludedValues = [
      "COA_New_Name_To_Be_Used",
      "COA_What_Are_We_Changing",
      "COA_Please_Tell_Us_What_You_Want_To_Amend",
      "COC_Please_Include_New_County",
      "COC_Please_Include_New_Address",
      "Please_Tell_Us_What_You_Are_Changing",
      "cc_Specify_Other_Document",
      "ucc_Type_Your_Collateral_Statement",
      "ucc_Collateral_Statement",
      "EIN_Other",
      `boi_FinCEN_ID_Number-${val.split("-")[1] || 0}`,
      `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`,
      `boi_Date_of_Birth-${val.split("-")[1] || 0}`,
      `boica_FinCEN_ID_Number-${val.split("-")[1] || 0}`,
      `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`,
      `boica_Date_of_Birth-${val.split("-")[1] || 0}`,
      // Add more values as needed
    ];

    if (!excludedValues.includes(val)) {
      switch (column.type) {
        case "string":
          switch (column.default) {
            case "drop-down":
              if (options.length > 0) {
                const matchedItem = tempdata.find(
                  (item) => item.item === tempval
                );

                return (
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}
                          {column.required !== undefined &&
                            column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className="">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                                column.required === true &&
                                column.regex !== undefined
                                ? {
                                  required: `${convertName(key)} Required.`,
                                  pattern: {
                                    value: new RegExp(column.regex),
                                    message: `Please Enter Valid ${convertName(
                                      key
                                    )}`,
                                  },
                                }
                                : column.required !== undefined &&
                                  column.required === true
                                  ? { required: `${convertName(key)} Required.` }
                                  : {}
                            }
                            render={({ field }) => {
                              const defaultValue =
                                matchedItem !== undefined
                                  ? matchedItem.label === "countyName" &&
                                    allCounty.length > 0
                                    ? allCounty
                                    : options
                                  : options;

                              return (
                                <Dropdown
                                  id={field.name}
                                  filter
                                  resetFilterOnHide
                                  name={val}
                                  // value={field.value || matchedItem !== undefined ? matchedItem.label === "stateFullDesc" ? state ? state.stateFullDesc : "" : matchedItem.label === "countyName" ? county ? county.countyName : "" : matchedItem.label === "countryShortName" ? state ? state.countryMaster.countryShortName : "" : "" : ""}
                                  value={field.value}
                                  options={defaultValue}
                                  optionValue={
                                    matchedItem !== undefined
                                      ? matchedItem.optionValue
                                      : "name"
                                  }
                                  disabled={
                                    val === "Reg_Name" ||
                                    val === "Reg_Email_Address" ||
                                    val === "Reg_Contact_No" ||
                                    val === "Reg_Address_Line_1" ||
                                    val === "Reg_Address_Line_2" ||
                                    val === "Reg_City" ||
                                    val === "Reg_State" ||
                                    val === "Reg_Zip_Code" ||
                                    val === "X_Name_of_Entity" ||
                                    modelname === "Principal_Address" ||
                                    modelname === "Organizer_Information" ||
                                    modelname === "Incorporator_Information" ||
                                    (IsCheckedAgent &&
                                      modelname === "Registered_Agent") ||
                                    modelname === "Director_Information" ||
                                    modelname === "Officer_Information" ||
                                    modelname === "Filer_Information" ||
                                    modelname === "Mailing_Information" ||
                                    modelname === "Billing_Information" ||
                                    modelname === "ucc_Debtor_Details" ||
                                    modelname === "ucc_Secured_Party_Details" ||
                                    modelname ===
                                    "Beneficial_Owner_Information" ||
                                    modelname ===
                                    "Company_Applicant_Information" ||
                                    componentName === "ucc-assignee-details" ||
                                    componentName ===
                                    "ucc-amend-additional-secured-party-details" ||
                                    componentName ===
                                    "ucc-amnd-additional-debtor"
                                  }
                                  optionLabel={
                                    matchedItem !== undefined
                                      ? matchedItem.label
                                      : "name"
                                  } // Dynamically set label for the dropdown
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                    errorMessageTag &&
                                      (errorMessageTag.textContent = "");
                                  }}
                                  maxSelectedLabels={3}
                                  style={{ flexGrow: "1" }}
                                  className="dialog-form-input-field-wizard"
                                />
                              );
                            }}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                );
              }

            case "signer":
              return (
                <div className="input-fields-container">
                  <div className="input-fields-main">
                    <div className="justify-content-center dialog-form-field-space w-full">
                      <label htmlFor="" className="form-label font-fam-for-all">
                        {convertName(key)}
                        {column.required !== undefined &&
                          column.required === true ? (
                          <span className="form-field-mandatory">*</span>
                        ) : (
                          ""
                        )}
                      </label>
                      {/* <span className=" ">
                        <Controller
                          name={val}
                          control={control}
                          rules={
                            column.required !== undefined &&
                              column.required === true &&
                              column.regex !== undefined
                              ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: new RegExp(column.regex),
                                  message: `Please Enter Valid ${convertName(
                                    key
                                  )}`,
                                },
                              }
                              : column.required !== undefined &&
                                column.required === true
                                ? { required: `${convertName(key)} Required.` }
                                : {}
                          }
                          render={({ field }) => {
                            return (
                              <Dropdown
                                id={field.name}
                                filter
                                name={val}
                                value={field.value}
                                resetFilterOnHide
                                options={allOrganizer}
                                optionValue="keyPersonnelName"
                                optionLabel="keyPersonnelName"
                                disabled={
                                  val === "Reg_Name" ||
                                  val === "Reg_Email_Address" ||
                                  val === "Reg_Contact_No" ||
                                  val === "Reg_Address_Line_1" ||
                                  val === "Reg_Address_Line_2" ||
                                  val === "Reg_City" ||
                                  val === "Reg_State" ||
                                  val === "Reg_Zip_Code" ||
                                  modelname === "Principal_Address" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  (IsCheckedAgent &&
                                    modelname === "Registered_Agent") ||
                                  modelname === "Director_Information" ||
                                  modelname === "Officer_Information" ||
                                  modelname === "Filer_Information" ||
                                  modelname === "Mailing_Information" ||
                                  modelname === "Billing_Information" ||
                                  modelname === "UCC_Debtor_Details" ||
                                  modelname === "ucc_Secured_Party_Details" ||
                                  modelname ===
                                  "Beneficial_Owner_Information" ||
                                  modelname ===
                                  "Company_Applicant_Information" ||
                                  componentName === "ucc-assignee-details" ||
                                  componentName ===
                                  "ucc-amend-additional-secured-party-details" ||
                                  componentName ===
                                  "ucc-amnd-additional-debtor" ||
                                  val === "LLP_State_Of_LLC" ||
                                  val === "LLP_County_Of_LLC"
                                }
                                onChange={(e) => {
                                  field.onChange(e);
                                  clearErrors(key);
                                  errorMessageTag &&
                                    (errorMessageTag.textContent = "");
                                }}
                                maxSelectedLabels={3}
                                style={{ flexGrow: "1" }}
                                className="dialog-form-input-field-wizard"
                              />
                            );
                          }}
                        />
                      </span> */}
                      <span className=" ">
                        <Controller
                          name={val}
                          control={control}
                          rules={
                            column.required !== undefined &&
                              column.required === true &&
                              column.regex !== undefined
                              ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: new RegExp(column.regex),
                                  message: `Please Enter Valid ${convertName(
                                    key
                                  )}`,
                                },
                              }
                              : column.required !== undefined &&
                                column.required === true
                                ? { required: `${convertName(key)} Required.` }
                                : {}
                          }
                          render={({ field }) => {
                            return (
                              <div className="flex justify-between items-center gap-4 text-start">
                                <div className="w-full">
                                  <Dropdown
                                    id={field.name}
                                    filter
                                    name={val}
                                    value={field.value}
                                    resetFilterOnHide
                                    options={allOrganizer}
                                    optionValue="keyPersonnelName"
                                    optionLabel="keyPersonnelName"
                                    disabled={
                                      val === "Reg_Name" ||
                                      val === "Reg_Email_Address" ||
                                      val === "Reg_Contact_No" ||
                                      val === "Reg_Address_Line_1" ||
                                      val === "Reg_Address_Line_2" ||
                                      val === "Reg_City" ||
                                      val === "Reg_State" ||
                                      val === "Reg_Zip_Code" ||
                                      modelname === "Principal_Address" ||
                                      modelname === "Organizer_Information" ||
                                      modelname ===
                                      "Incorporator_Information" ||
                                      (IsCheckedAgent &&
                                        modelname === "Registered_Agent") ||
                                      modelname === "Director_Information" ||
                                      modelname === "Officer_Information" ||
                                      modelname === "Filer_Information" ||
                                      modelname === "Mailing_Information" ||
                                      modelname === "Billing_Information" ||
                                      modelname === "UCC_Debtor_Details" ||
                                      modelname ===
                                      "ucc_Secured_Party_Details" ||
                                      modelname ===
                                      "Beneficial_Owner_Information" ||
                                      modelname ===
                                      "Company_Applicant_Information" ||
                                      componentName ===
                                      "ucc-assignee-details" ||
                                      componentName ===
                                      "ucc-amend-additional-secured-party-details" ||
                                      componentName ===
                                      "ucc-amnd-additional-debtor" ||
                                      val === "LLP_State_Of_LLC" ||
                                      val === "LLP_County_Of_LLC"
                                    }
                                    onChange={(e) => {
                                      field.onChange(e);
                                      clearErrors(key);
                                      errorMessageTag &&
                                        (errorMessageTag.textContent = "");
                                    }}
                                    maxSelectedLabels={3}
                                    style={{ flexGrow: "1" }}
                                    className="dialog-form-input-field-wizard"
                                  />
                                </div>

                                <div className="w-full">
                                  <Button
                                    type="button"
                                    icon="pi pi-plus"
                                    style={{ fontSize: "14px" }}
                                    label="Add Signer"
                                    className=" vstate-button font-fam-for-all text-center"
                                    onClick={() => handleKeyPersonal("SIGNER")}
                                  ></Button>
                                </div>
                              </div>
                            );
                          }}
                        />
                      </span>
                      <small
                        id={`${val}--error`}
                        className="error-msg font-fam-for-all"
                      ></small>
                      {getFormErrorMessage(key)}
                    </div>
                  </div>
                </div>
              );

            // dasfasd

            case "zipcode": {
              return (
                <>
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}{" "}
                          {column.required ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span>
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required
                                ? {
                                  required: `${convertName(key)} Required.`,
                                  validate: (value) =>
                                    value.length === 5 ||
                                    "Please enter a valid 5-digit zipcode.",
                                }
                                : {}
                            }
                            render={({ field, fieldState }) => (
                              <input
                                type="text"
                                id={field.name}
                                {...field}
                                maxLength={5}
                                disabled={
                                  val === "Reg_Name" ||
                                  val === "Reg_Email_Address" ||
                                  val === "Reg_Contact_No" ||
                                  val === "Reg_Address_Line_1" ||
                                  val === "Reg_Address_Line_2" ||
                                  val === "Reg_City" ||
                                  val === "Reg_State" ||
                                  val === "Reg_Zip_Code" ||
                                  modelname === "Principal_Address" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  (IsCheckedAgent &&
                                    modelname === "Registered_Agent") ||
                                  modelname === "Director_Information" ||
                                  modelname === "Officer_Information" ||
                                  modelname === "Filer_Information" ||
                                  modelname === "Mailing_Information" ||
                                  modelname === "Billing_Information" ||
                                  modelname === "ucc_Debtor_Details" ||
                                  modelname === "ucc_Secured_Party_Details" ||
                                  modelname ===
                                  "Beneficial_Owner_Information" ||
                                  modelname === "Company_Applicant_Information"
                                }
                                onChange={(e) => {
                                  const inputValue = e.target.value.replace(
                                    /\D/g,
                                    ""
                                  ); // Remove all non-digit characters
                                  field.onChange(inputValue);
                                  clearErrors(key);
                                  if (errorMessageTag)
                                    errorMessageTag.textContent = "";
                                }}
                                style={{
                                  flexGrow: "1",
                                  paddingLeft: "10px",
                                  paddingBottom: "5px",
                                  borderRadius: "6px",
                                }}
                                className="dialog-form-input-field-wizard p-inputtext"
                              />
                            )}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                </>
              );
            }

            case "SSN":
              return (
                <>
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}
                          {column.required && (
                            <span className="form-field-mandatory">*</span>
                          )}
                        </label>
                        <span className="">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required
                                ? {
                                  required: `${convertName(
                                    key
                                  )} is required.`,
                                }
                                : {}
                            }
                            render={({ field }) => (
                              <div
                                className="p-inputgroup flex-1"
                                style={{
                                  height: "40px",
                                  width: "100%",
                                  position: "relative",
                                }}
                              >
                                <input
                                  id={field.name}
                                  {...field}
                                  value={field.value}
                                  type={showSSN ? "text" : "password"}
                                  disabled={
                                    val === "Reg_Name" ||
                                    val === "Reg_Email_Address" ||
                                    val === "Reg_Contact_No" ||
                                    val === "Reg_Address_Line_1" ||
                                    val === "Reg_Address_Line_2" ||
                                    val === "Reg_City" ||
                                    val === "Reg_State" ||
                                    val === "Reg_Zip_Code" ||
                                    modelname === "Principal_Address" ||
                                    modelname === "Organizer_Information" ||
                                    modelname === "Incorporator_Information" ||
                                    (IsCheckedAgent &&
                                      modelname === "Registered_Agent") ||
                                    modelname === "Director_Information" ||
                                    modelname === "Officer_Information" ||
                                    modelname === "Filer_Information" ||
                                    modelname === "Mailing_Information" ||
                                    modelname === "Billing_Information" ||
                                    modelname === "ucc_Debtor_Details" ||
                                    modelname === "ucc_Secured_Party_Details" ||
                                    modelname ===
                                    "Beneficial_Owner_Information" ||
                                    modelname ===
                                    "Company_Applicant_Information" ||
                                    componentName === "ucc-assignee-details" ||
                                    componentName ===
                                    "ucc-amend-additional-secured-party-details" ||
                                    componentName ===
                                    "ucc-amnd-additional-debtor"
                                  }
                                  onChange={(e) => {
                                    let inputValue = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    ); // Remove non-digit characters
                                    if (inputValue.length > 9) {
                                      inputValue = inputValue.slice(0, 9); // Limit to 9 digits
                                    }
                                    const formattedValue = inputValue.replace(
                                      /^(\d{3})(\d{2})(\d{4})$/,
                                      "$1-$2-$3"
                                    );
                                    field.onChange(formattedValue);
                                    clearErrors(key);
                                  }}
                                  onBlur={() => setShowSSN(false)}
                                  placeholder="XXX-XX-XXXX"
                                  style={{
                                    height: "40px",
                                    borderRadius: "6px",
                                    width: "100%",
                                    paddingRight: "40px", // Space for the eye icon
                                  }}
                                  className="dialog-form-input-field-wizard p-inputtext"
                                  maxLength={11}
                                />
                                {/* Eye Icon for Show/Hide */}
                                <button
                                  type="button"
                                  onClick={() => setShowSSN((prev) => !prev)}
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    background: "none",
                                    border: "none",
                                    cursor: "pointer",
                                  }}
                                >
                                  {showSSN ? (
                                    <EyeOff size={20} />
                                  ) : (
                                    <Eye size={20} />
                                  )}
                                </button>
                              </div>
                            )}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                </>
              );

            case "media":
              return (
                <>
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}
                          {column.required !== undefined &&
                            column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className="">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                                column.required === true &&
                                column.regex !== undefined
                                ? {
                                  required: `${convertName(key)} Required.`,
                                  pattern: {
                                    value: new RegExp(column.regex),
                                    message: `Please Enter Valid ${convertName(
                                      key
                                    )}`,
                                  },
                                }
                                : column.required !== undefined &&
                                  column.required === true
                                  ? { required: `${convertName(key)} Required.` }
                                  : {}
                            }
                            render={({ field }) => (
                              // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                              <input
                                type="file"
                                disabled={
                                  val === "Reg_Name" ||
                                  val === "Reg_Email_Address" ||
                                  val === "Reg_Contact_No" ||
                                  val === "Reg_Address_Line_1" ||
                                  val === "Reg_Address_Line_2" ||
                                  val === "Reg_City" ||
                                  val === "Reg_State" ||
                                  val === "Reg_Zip_Code" ||
                                  modelname === "Principal_Address" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  (IsCheckedAgent &&
                                    modelname === "Registered_Agent") ||
                                  modelname === "Director_Information" ||
                                  modelname === "Officer_Information" ||
                                  modelname === "Filer_Information" ||
                                  modelname === "Mailing_Information" ||
                                  modelname === "Billing_Information" ||
                                  modelname === "ucc_Debtor_Details" ||
                                  modelname === "ucc_Secured_Party_Details" ||
                                  modelname ===
                                  "Beneficial_Owner_Information" ||
                                  modelname ===
                                  "Company_Applicant_Information" ||
                                  componentName === "ucc-assignee-details" ||
                                  componentName ===
                                  "ucc-amend-additional-secured-party-details" ||
                                  componentName === "ucc-amnd-additional-debtor"
                                }
                                onChange={(e) => {
                                  handleFile(e, val, convertName(key));
                                  // e.target.value = "";
                                }}
                              />
                            )}
                          />
                        </span>
                        {/* {getValues(val) !== undefined &&
                        
                          typeof getValues(val) === "string" && (
                            <p className="font-web-color font-fam-for-all">
                              {getValues(val).split("/").pop()}
                            </p>
                          )}
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)} */}

                        {getValues(val) &&
                          typeof getValues(val) === "string" && (
                            <div className="flex items-baseline  justify-between gap-2">
                              <p className="font-web-color font-fam-for-all">
                                {getValues(val).split("/").pop()}
                              </p>
                              <Button
                                type="button"
                                icon="pi pi-trash"
                                className="text-black bg-transparent rounded border-none outline-transparent border-none hover:text-red-500"
                                onClick={() => {
                                  setValue(val, ""); // Clear form field value
                                  // document.getElementById(`${val}--error`).textContent = "";
                                }}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </>
              );

              // case "date":
              return (
                <div className="input-fields-container">
                  <div className="input-fields-main">
                    <div className="justify-content-center dialog-form-field-space w-full">
                      <label htmlFor="" className="form-label font-fam-for-all">
                        {convertName(key)}
                        {column.required ? (
                          <span className="form-field-mandatory">*</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <span className="">
                        <Controller
                          name={val}
                          control={control}
                          rules={
                            column.required
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                          }
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                className="strapi-date-picker"
                                style={{ width: "322px" }}
                                disabled={
                                  val === "Reg_Name" ||
                                  val === "Reg_Email_Address" ||
                                  val === "Reg_Contact_No" ||
                                  val === "Reg_Address_Line_1" ||
                                  val === "Reg_Address_Line_2" ||
                                  val === "Reg_City" ||
                                  val === "Reg_State" ||
                                  val === "Reg_Zip_Code" ||
                                  val === "CF_Year_of_formation" ||
                                  modelname === "Principal_Address" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  (IsCheckedAgent &&
                                    modelname === "Registered_Agent") ||
                                  modelname === "Director_Information" ||
                                  modelname === "Officer_Information" ||
                                  modelname === "Filer_Information" ||
                                  modelname === "Mailing_Information" ||
                                  modelname === "Billing_Information" ||
                                  modelname === "ucc_Debtor_Details" ||
                                  modelname === "ucc_Secured_Party_Details" ||
                                  modelname ===
                                  "Beneficial_Owner_Information" ||
                                  modelname ===
                                  "Company_Applicant_Information" ||
                                  componentName === "ucc-assignee-details" ||
                                  componentName ===
                                  "ucc-amend-additional-secured-party-details" ||
                                  componentName === "ucc-amnd-additional-debtor"
                                }
                                value={field.value ? dayjs(field.value) : null}
                                onChange={(newValue) => {
                                  if (newValue && newValue.isValid()) {
                                    if (orderTypeId === 18) {
                                      const formattedDate =
                                        newValue.format("YYYYMMDD");
                                      field.onChange(formattedDate);
                                    } else {
                                      field.onChange(
                                        newValue.format("YYYY-MM-DD")
                                      );
                                    }
                                  } else {
                                    field.onChange(null);
                                  }
                                  errorMessageTag &&
                                    (errorMessageTag.textContent = "");
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                disableFuture
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </span>
                      <small
                        id={`${val}--error`}
                        className="error-msg font-fam-for-all"
                      ></small>
                      {getFormErrorMessage(key)}
                    </div>
                  </div>
                </div>
              );

            case "date":
              const isPrefilledDisabled =
                key === "CF_Year_of_formation" || key === "EIN_Date";
              const today = dayjs();

              return (
                <div className="input-fields-container">
                  <div className="input-fields-main">
                    <div className="justify-content-center dialog-form-field-space w-full">
                      <label htmlFor="" className="form-label font-fam-for-all">
                        {convertName(key)}
                        {column.required ? (
                          <span className="form-field-mandatory">*</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <span className="">
                        {/* <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required
                                ? { required: `${convertName(key)} Required.` }
                                : {}
                            }
                            render={({ field }) => {
                              // Check if the key should be prefilled and disabled
                              const isPrefilledDisabled =
                                key === "CF_Year_of_formation" || key === "EIN_Date";
                              // Use today's date if needed
                              const today = dayjs();
  
                              return (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    className="strapi-date-picker"
                                    style={{ width: "322px" }}
                                    value={
                                      isPrefilledDisabled
                                        ? today
                                        : field.value
                                          ? dayjs(field.value)
                                          : null
                                    }
                                    // Disable the date picker if prefilled or based on existing conditions
                                    disabled={
                                      isPrefilledDisabled ||
                                      modelname === "Principal_Address" ||
                                      modelname === "Organizer_Information" ||
                                      modelname === "Incorporator_Information" ||
                                      (IsCheckedAgent &&
                                        modelname === "Registered_Agent") ||
                                      modelname === "Director_Information" ||
                                      modelname === "Officer_Information" ||
                                      modelname === "Filer_Information" ||
                                      componentName === "Mailing_Information" ||
                                      componentName === "Billing_Information" ||
                                      modelname === "ucc_Debtor_Details" ||
                                      modelname === "ucc_Secured_Party_Details" ||
                                      modelname === "Beneficial_Owner_Information" ||
                                      modelname === "Company_Applicant_Information" ||
                                      modelname === "Contact_Information"
                                    }
                                    onChange={(newValue) => {
                                      // If prefilled and disabled, do not update the value
                                      if (isPrefilledDisabled) {
                                        return;
                                      }
                                      if (newValue && newValue.isValid()) {
                                        if (orderTypeId === 18) {
                                          const formattedDate = newValue.format("YYYYMMDD");
                                          field.onChange(formattedDate);
                                        } else {
                                          field.onChange(newValue.format("YYYY-MM-DD"));
                                        }
                                      } else {
                                        field.onChange(null);
                                      }
                                      errorMessageTag &&
                                        (errorMessageTag.textContent = "");
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    disableFuture
                                  />
                                </LocalizationProvider>
                              );
                            }}
                              
                          /> */}

                        <Controller
                          name={val}
                          control={control}
                          // If prefilled/disabled, assign today's date as the default value
                          defaultValue={
                            isPrefilledDisabled
                              ? today.format("YYYY-MM-DD")
                              : ""
                          }
                          // Only apply required validation if the field isn’t prefilled/disabled
                          rules={
                            !isPrefilledDisabled && column.required
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                          }
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                              name={val}
                                className="strapi-date-picker"
                                style={{ width: "322px" }}
                                value={
                                  isPrefilledDisabled
                                    ? today
                                    : field.value
                                      ? dayjs(field.value)
                                      : null
                                }
                                disabled={
                                  isPrefilledDisabled ||
                                  modelname === "Principal_Address" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  (IsCheckedAgent &&
                                    modelname === "Registered_Agent") ||
                                  modelname === "Director_Information" ||
                                  modelname === "Officer_Information" ||
                                  modelname === "Filer_Information" ||
                                  componentName === "Mailing_Information" ||
                                  componentName === "Billing_Information" ||
                                  modelname === "ucc_Debtor_Details" ||
                                  modelname === "ucc_Secured_Party_Details" ||
                                  modelname ===
                                  "Beneficial_Owner_Information" ||
                                  modelname ===
                                  "Company_Applicant_Information" ||
                                  modelname === "Contact_Information"
                                }
                                onChange={(newValue) => {
                                  // If the field is prefilled/disabled, don't change the value
                                  if (isPrefilledDisabled) return;
                                  if (newValue && newValue.isValid()) {
                                    const formattedDate =
                                      orderTypeId === 18
                                        ? newValue.format("YYYYMMDD")
                                        : newValue.format("YYYY-MM-DD");
                                    field.onChange(formattedDate);
                                  } else {
                                    field.onChange(null);
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                disableFuture
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </span>
                      <small
                        id={`${val}--error`}
                        className="error-msg font-fam-for-all"
                      ></small>
                      {getFormErrorMessage(key)}
                    </div>
                  </div>
                </div>
              );

            case "responsible-party":
              return (
                <div className="input-fields-container">
                  <div className="input-fields-main">
                    <div className="justify-content-center dialog-form-field-space w-full">
                      <label htmlFor="" className="form-label font-fam-for-all">
                        {convertName(key)}
                        {column.required !== undefined &&
                          column.required === true ? (
                          <span className="form-field-mandatory">*</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <span className=" ">
                        <Controller
                          name={val}
                          control={control}
                          rules={
                            column.required !== undefined &&
                              column.required === true &&
                              column.regex !== undefined
                              ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: new RegExp(column.regex),
                                  message: `Please Enter Valid ${convertName(
                                    key
                                  )}`,
                                },
                              }
                              : column.required !== undefined &&
                                column.required === true
                                ? { required: `${convertName(key)} Required.` }
                                : {}
                          }
                          render={({ field }) => {
                            return (
                              <div className="flex justify-between items-center gap-4 text-start">
                                <div className="w-full">
                                  <Dropdown
                                    id={field.name}
                                    filter
                                    name={val}
                                    value={field.value}
                                    resetFilterOnHide
                                    options={allOrganizer}
                                    optionValue="keyPersonnelName"
                                    optionLabel="keyPersonnelName"
                                    disabled={
                                      val === "Reg_Name" ||
                                      val === "Reg_Email_Address" ||
                                      val === "Reg_Contact_No" ||
                                      val === "Reg_Address_Line_1" ||
                                      val === "Reg_Address_Line_2" ||
                                      val === "Reg_City" ||
                                      val === "Reg_State" ||
                                      val === "Reg_Zip_Code" ||
                                      modelname === "Principal_Address" ||
                                      modelname === "Organizer_Information" ||
                                      modelname ===
                                      "Incorporator_Information" ||
                                      (IsCheckedAgent &&
                                        modelname === "Registered_Agent") ||
                                      modelname === "Director_Information" ||
                                      modelname === "Officer_Information" ||
                                      modelname === "Filer_Information" ||
                                      modelname === "Mailing_Information" ||
                                      modelname === "Billing_Information" ||
                                      modelname === "UCC_Debtor_Details" ||
                                      modelname ===
                                      "ucc_Secured_Party_Details" ||
                                      modelname ===
                                      "Beneficial_Owner_Information" ||
                                      modelname ===
                                      "Company_Applicant_Information" ||
                                      componentName ===
                                      "ucc-assignee-details" ||
                                      componentName ===
                                      "ucc-amend-additional-secured-party-details" ||
                                      componentName ===
                                      "ucc-amnd-additional-debtor" ||
                                      val === "LLP_State_Of_LLC" ||
                                      val === "LLP_County_Of_LLC"
                                    }
                                    onChange={(e) => {
                                      field.onChange(e);
                                      clearErrors(key);
                                      errorMessageTag &&
                                        (errorMessageTag.textContent = "");
                                    }}
                                    maxSelectedLabels={3}
                                    style={{ flexGrow: "1" }}
                                    className="dialog-form-input-field-wizard"
                                  />
                                </div>

                                <div className="w-full">
                                  <Button
                                    type="button"
                                    icon="pi pi-plus"
                                    style={{ fontSize: "14px" }}
                                    label="Add Responsible Party"
                                    className=" vstate-button font-fam-for-all text-center"
                                    onClick={() =>
                                      handleKeyPersonal("RESPONSIBLE PARTY")
                                    }
                                  ></Button>
                                </div>
                              </div>
                            );
                          }}
                        />
                      </span>
                      <small
                        id={`${val}--error`}
                        className="error-msg font-fam-for-all"
                      ></small>
                      {getFormErrorMessage(key)}
                    </div>
                  </div>
                </div>
              );

            case "currency":
              return (
                <>
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}
                          {column.required !== undefined &&
                            column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className="">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                                column.required === true
                                ? {
                                  required: `${convertName(key)} Required.`,
                                }
                                : {}
                            }
                            render={({ field, fieldState }) => (
                              <input
                                type="text"
                                id={field.name}
                                {...field}
                                disabled={
                                  val === "Reg_Name" ||
                                  val === "Reg_Email_Address" ||
                                  val === "Reg_Contact_No" ||
                                  val === "Reg_Address_Line_1" ||
                                  val === "Reg_Address_Line_2" ||
                                  val === "Reg_City" ||
                                  val === "Reg_State" ||
                                  val === "Reg_Zip_Code" ||
                                  modelname === "Principal_Address" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  (IsCheckedAgent &&
                                    modelname === "Registered_Agent") ||
                                  modelname === "Director_Information" ||
                                  modelname === "Officer_Information" ||
                                  modelname === "Filer_Information" ||
                                  modelname === "Mailing_Information" ||
                                  modelname === "Billing_Information" ||
                                  modelname === "ucc_Debtor_Details" ||
                                  modelname === "ucc_Secured_Party_Details" ||
                                  modelname ===
                                  "Beneficial_Owner_Information" ||
                                  modelname ===
                                  "Company_Applicant_Information" ||
                                  componentName === "ucc-assignee-details" ||
                                  componentName ===
                                  "ucc-amend-additional-secured-party-details" ||
                                  componentName === "ucc-amnd-additional-debtor"
                                }
                                onInput={(e) => {
                                  let value = e.target.value.replace(/,/g, ""); // Remove existing commas
                                  if (value.length > 10) {
                                    value = value.slice(0, 10); // Allow only 10 digits
                                  }
                                  const formattedValue = new Intl.NumberFormat(
                                    "en-US"
                                  ).format(value); // Add commas

                                  e.target.value = formattedValue;
                                  field.onChange(value.toString()); // Save raw value as a string
                                  clearErrors(key);
                                  errorMessageTag &&
                                    (errorMessageTag.textContent = "");
                                }}
                                style={{
                                  flexGrow: "1",
                                  paddingLeft: "10px",
                                  paddingBottom: "5px",
                                  borderRadius: "6px",
                                }}
                                className="dialog-form-input-field-wizard p-inputtext"
                              />
                            )}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                </>
              );

            // case "contact":
            //     return (
            //         <>
            //             <div className="input-fields-container">
            //                 <div className="input-fields-main">
            //                     <div className="justify-content-center dialog-form-field-space w-full">
            //                         <label htmlFor="" className="form-label font-fam-for-all">
            //                             {convertName(key)}
            //                             {column.required !== undefined && column.required === true ? (
            //                                 <span className="form-field-mandatory">*</span>
            //                             ) : ''}
            //                         </label>
            //                         <span className="">
            //                             <Controller
            //                                 name={val}
            //                                 control={control}
            //                                 rules={
            //                                     column.required !== undefined && column.required === true
            //                                         ? {
            //                                             required: `${convertName(key)} is required.`,
            //                                         }
            //                                         : {}
            //                                 }
            //                                 render={({ field, fieldState }) => (
            //                                     <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}>
            //                                         <Dropdown
            //                                             value={selectedCountry}
            //                                             options={countries}
            //                                             onChange={(e) => {
            //                                                 setSelectedCountry(e.value);

            //                                             }}
            //                                             optionLabel="dialCode"
            //                                             filter
            //                                             optionValue="dialCode"
            //                                             valueTemplate={selectedCountryTemplate}
            //                                             itemTemplate={countryOptionTemplate}
            //                                             style={{ width: '160px', borderRadius: '6px 0px 0px 6px' }}
            //                                             className="p-dropdown font-fam-for-all"
            //                                         />

            //                                         <InputMask
            //                                             id={field.name}
            //                                             {...field}
            //                                             value={field.value}
            //                                             mask={isFocused ? "(999) 999-9999" : "(999) 999-9999"}
            //                                             onFocus={() => setIsFocused(true)}
            //                                             onBlur={() => setIsFocused(false)}
            //                                             disabled={val === "RA_State" || modelname === "Principal_Address" || modelname === "Organizer_Information" || modelname === "Incorporator_Information" || (IsCheckedAgent && modelname === "Registered_Agent" || modelname === "Director_Information" || modelname === "Officer_Information" || modelname === "Filer_Information")}
            //                                             onChange={(e) => {
            //                                                 let value = e.target.value

            //                                                 const formattedValue = `${selectedCountry} ${value}`
            //                                                 e.target.value = formattedValue;
            //                                                 field.onChange(value.toString())
            //                                                 clearErrors(key);
            //                                                 errorMessageTag && (errorMessageTag.textContent = "");
            //                                             }}
            //                                             placeholder=""
            //                                             style={{ height: '40px', borderRadius: '0px 6px 6px 0px', width: "100%" }}
            //                                             className="dialog-form-input-field-wizard"
            //                                         />
            //                                     </div>
            //                                 )}
            //                             />
            //                         </span>
            //                         <small id={`${val}--error`} className="error-msg font-fam-for-all"></small>
            //                         {getFormErrorMessage(key)}
            //                     </div>
            //                 </div>
            //             </div>
            //         </>
            //     );

            case "contact":
              return (
                <>
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}
                          {column.required !== undefined &&
                            column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className="">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                                column.required === true
                                ? {
                                  required: `${convertName(
                                    key
                                  )} is required.`,
                                }
                                : {}
                            }
                            render={({ field, fieldState }) => (
                              <div
                                className="p-inputgroup flex-1"
                                style={{ height: "40px", width: "100%" }}
                              >
                                {/* Dropdown for Country Code */}
                                <Dropdown
                                  value={selectedCountry}
                                  options={countries}
                                  resetFilterOnHide
                                  onChange={(e) => setSelectedCountry(e.value)}
                                  optionLabel="dialCode"
                                  filter
                                  optionValue="dialCode"
                                  valueTemplate={selectedCountryTemplate}
                                  itemTemplate={countryOptionTemplate}
                                  style={{
                                    width: "160px",
                                    borderRadius: "6px 0px 0px 6px",
                                  }}
                                  className="p-dropdown font-fam-for-all"
                                />

                                {/* Input for Phone Number */}
                                <input
                                  id={field.name}
                                  {...field}
                                  value={field.value}
                                  type="text"
                                  disabled={
                                    val === "Reg_Name" ||
                                    val === "Reg_Email_Address" ||
                                    val === "Reg_Contact_No" ||
                                    val === "Reg_Address_Line_1" ||
                                    val === "Reg_Address_Line_2" ||
                                    val === "Reg_City" ||
                                    val === "Reg_State" ||
                                    val === "Reg_Zip_Code" ||
                                    modelname === "Principal_Address" ||
                                    modelname === "Organizer_Information" ||
                                    modelname === "Incorporator_Information" ||
                                    (IsCheckedAgent &&
                                      modelname === "Registered_Agent") ||
                                    modelname === "Director_Information" ||
                                    modelname === "Officer_Information" ||
                                    modelname === "Filer_Information" ||
                                    modelname === "Mailing_Information" ||
                                    modelname === "Billing_Information" ||
                                    modelname === "ucc_Debtor_Details" ||
                                    modelname === "ucc_Secured_Party_Details" ||
                                    modelname ===
                                    "Beneficial_Owner_Information" ||
                                    modelname ===
                                    "Company_Applicant_Information" ||
                                    componentName === "ucc-assignee-details" ||
                                    componentName ===
                                    "ucc-amend-additional-secured-party-details" ||
                                    componentName ===
                                    "ucc-amnd-additional-debtor"
                                  }
                                  onChange={(e) => {
                                    let inputValue = e.target.value.replace(
                                      /\D/g,
                                      ""
                                    ); // Remove non-digit characters
                                    if (inputValue.length > 10) {
                                      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
                                    }
                                    const formattedValue = inputValue.replace(
                                      /(\d{3})(\d{3})(\d{4})/,
                                      (match, p1, p2, p3) =>
                                        `(${p1}) ${p2}-${p3}`
                                    );
                                    if (inputValue.length === 10) {
                                      // Only show formatted value after 10 digits
                                      e.target.value = formattedValue;
                                      field.onChange(formattedValue); // Update state with formatted number
                                    } else {
                                      e.target.value = inputValue;
                                      field.onChange(inputValue); // Keep raw number for incomplete input
                                    }
                                    clearErrors(key);
                                    errorMessageTag &&
                                      (errorMessageTag.textContent = "");
                                  }}
                                  placeholder="(999) 999-9999"
                                  style={{
                                    height: "40px",
                                    borderRadius: "0px 6px 6px 0px",
                                    width: "100%",
                                  }}
                                  className="dialog-form-input-field-wizard p-inputtext"
                                />
                              </div>
                            )}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all"
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                </>
              );

            default:
              return (
                <>
                  <div className="input-fields-container">
                    <div className="input-fields-main">
                      <div className=" justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}{" "}
                          {column.required !== undefined &&
                            column.required === true ? (
                            <span className="form-field-mandatory">*</span>
                          ) : (
                            ""
                          )}
                        </label>
                        <span className=" ">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required !== undefined &&
                                column.required === true &&
                                column.regex !== undefined
                                ? {
                                  required: `${convertName(key)} Required.`,
                                  pattern: {
                                    value: new RegExp(column.regex),
                                    message: `Please Enter Valid ${convertName(
                                      key
                                    )}`,
                                  },
                                }
                                : column.required !== undefined &&
                                  column.required === true
                                  ? { required: `${convertName(key)} Required.` }
                                  : {}
                            }
                            render={({ field, fieldState }) => (
                              <InputText
                                id={field.name}
                                {...field}
                                autoComplete={false}
                                onChange={(e) => {
                                  field.onChange(e);
                                  clearErrors(key);
                                  errorMessageTag &&
                                    (errorMessageTag.textContent = "");
                                }}
                                disabled={
                                  val === "Reg_Name" ||
                                  val === "Reg_Email_Address" ||
                                  val === "Reg_Contact_No" ||
                                  val === "Reg_Address_Line_1" ||
                                  val === "Reg_Address_Line_2" ||
                                  val === "Reg_City" ||
                                  val === "Reg_State" ||
                                  val === "Reg_Zip_Code" ||
                                  val === "X_Name_of_Entity" ||
                                  val === "boicd_Company_Legal_Name" ||
                                  modelname === "Principal_Address" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  (IsCheckedAgent &&
                                    modelname === "Registered_Agent") ||
                                  modelname === "Director_Information" ||
                                  modelname === "Officer_Information" ||
                                  modelname === "Filer_Information" ||
                                  modelname === "Mailing_Information" ||
                                  modelname === "Billing_Information" ||
                                  modelname === "ucc_Debtor_Details" ||
                                  modelname === "ucc_Secured_Party_Details" ||
                                  modelname ===
                                  "Beneficial_Owner_Information" ||
                                  modelname ===
                                  "Company_Applicant_Information" ||
                                  componentName === "ucc-assignee-details" ||
                                  componentName ===
                                  "ucc-amend-additional-secured-party-details" ||
                                  componentName === "ucc-amnd-additional-debtor"
                                }
                                style={{
                                  flexGrow: "1",
                                  paddingLeft: "10px",
                                  paddingBottom: "5px",
                                }}
                                className="dialog-form-input-field-wizard"
                                maxLength={50}
                              />
                            )}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all "
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                </>
              );
          }
        case "text":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className=" ">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                            : column.required !== undefined &&
                              column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                        }
                        render={({ field, fieldState }) => (
                          <InputTextarea
                            id={field.name}
                            {...field}
                            autoComplete={false}
                            rows={4}
                            cols={100}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent")
                            }
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                            }}
                            className="dialog-form-input-area-field-wizard"
                            maxLength={50}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        // case "biginteger":
        //     return<>
        //     <input type="number" />
        //     </>
        case "biginteger":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className="justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className=" ">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: /^\d{1,10}$/, // Allow only up to 5 digits
                                message:
                                  "Please Enter Valid No Of Positions (1-5 digits)",
                              },
                            }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <input
                            type="number"
                            id={field.name}
                            {...field}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "Director_Information" ||
                              modelname === "Officer_Information" ||
                              modelname === "Filer_Information" ||
                              modelname === "Mailing_Information" ||
                              modelname === "Billing_Information" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details" ||
                              modelname === "Beneficial_Owner_Information" ||
                              modelname === "Company_Applicant_Information" ||
                              componentName === "ucc-assignee-details" ||
                              componentName ===
                              "ucc-amend-additional-secured-party-details" ||
                              componentName === "ucc-amnd-additional-debtor"
                            }
                            onInput={(e) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10); // Allow only 5 digits
                              }
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                              borderRadius: "6px",
                            }}
                            className="dialog-form-input-field-wizard p-inputtext"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        // case "integer":
        //   return (
        //     <>
        //       <div className="input-fields-container">
        //         <div className="input-fields-main">
        //           <div className=" justify-content-center dialog-form-field-space w-full ">
        //             <label htmlFor="" className="form-label font-fam-for-all">
        //               {convertName(key)}{" "}
        //               {column.required !== undefined &&
        //               column.required === true ? (
        //                 <span className="form-field-mandatory">*</span>
        //               ) : (
        //                 ""
        //               )}
        //             </label>
        //             <span className=" ">
        //               <Controller
        //                 name={val}
        //                 control={control}
        //                 rules={
        //                   column.required !== undefined &&
        //                   column.required === true
        //                     ? {
        //                         required: `${convertName(key)} Required.`,
        //                         pattern: {
        //                           value: /^\d+$/,
        //                           message: "Please Enter Valid No Of Positions",
        //                         },
        //                       }
        //                     : {}
        //                 }
        //                 render={({ field, fieldState }) => (
        //                   <input
        //                     type="number"
        //                     id={field.name}
        //                     {...field}
        //                     disabled={
        //                       modelname === "Principal_Address" ||
        //                       modelname === "Organizer_Information" ||
        //                       modelname === "Incorporator_Information" ||
        //                       (IsCheckedAgent &&
        //                         modelname === "Registered_Agent")
        //                     }
        //                     onChange={(e) => {
        //                       field.onChange(e);
        //                       clearErrors(key);
        //                       errorMessageTag &&
        //                         (errorMessageTag.textContent = "");
        //                     }}
        //                     style={{
        //                       flexGrow: "1",
        //                       paddingLeft: "10px",
        //                       paddingBottom: "5px",
        //                       borderRadius: "6px",
        //                     }}
        //                     className="dialog-form-input-field-wizard p-inputtext"
        //                   />
        //                 )}
        //               />
        //             </span>
        //             <small
        //               id={`${val}--error`}
        //               className="error-msg font-fam-for-all "
        //             ></small>
        //             {getFormErrorMessage(key)}
        //           </div>
        //         </div>
        //       </div>
        //     </>
        //   );

        case "integer":
          return (
            <>
              {key === "EIN_Enter_The_Number_of_LLC_Members" ? (
                hasLLC && (
                  <div className="input-fields-container">
                    {/* form content */}
                    <div className="input-fields-main">
                      <div className="justify-content-center dialog-form-field-space w-full">
                        <label
                          htmlFor=""
                          className="form-label font-fam-for-all"
                        >
                          {convertName(key)}{" "}
                          {column.required && (
                            <span className="form-field-mandatory">*</span>
                          )}
                        </label>
                        <span className="p-float-label">
                          <Controller
                            name={val}
                            control={control}
                            rules={
                              column.required
                                ? {
                                  required: `${convertName(key)} Required.`,
                                  pattern: {
                                    value: /^\d+$/,
                                    message:
                                      "Please Enter Valid No Of Positions",
                                  },
                                }
                                : {}
                            }
                            render={({ field, fieldState }) => (
                              <input
                                type="number"
                                id={field.name}
                                {...field}
                                required
                                disabled={
                                  modelname === "Principal_Address" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Governor_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  (IsCheckedAgent &&
                                    modelname === "Registered_Agent") ||
                                  modelname === "Director_Information" ||
                                  modelname === "Officer_Information" ||
                                  modelname === "Filer_Information" ||
                                  modelname === "Mailing_Information" ||
                                  modelname === "Billing_Information" ||
                                  modelname === "ucc_Debtor_Details" ||
                                  modelname === "ucc_Secured_Party_Details" ||
                                  modelname ===
                                  "Beneficial_Owner_Information" ||
                                  modelname === "Company_Applicant_Information"
                                }
                                onChange={(e) => {
                                  field.onChange(e);
                                  clearErrors(key);
                                  if (errorMessageTag)
                                    errorMessageTag.textContent = "";
                                }}
                                style={{
                                  flexGrow: "1",
                                  paddingLeft: "10px",
                                  paddingBottom: "5px",
                                  borderRadius: "6px",
                                }}
                                className="dialog-form-input-field-wizard p-inputtext"
                              />
                            )}
                          />
                        </span>
                        <small
                          id={`${val}--error`}
                          className="error-msg font-fam-for-all "
                        ></small>
                        {getFormErrorMessage(key)}
                      </div>
                    </div>
                  </div>
                )
              ) : (
                // For any other key, always render the form
                <div className="input-fields-container">
                  {/* form content (same as above) */}
                  <div className="input-fields-main">
                    <div className="justify-content-center dialog-form-field-space w-full">
                      <label htmlFor="" className="form-label font-fam-for-all">
                        {convertName(key)}{" "}
                        {column.required && (
                          <span className="form-field-mandatory">*</span>
                        )}
                      </label>
                      <span className="p-float-label">
                        <Controller
                          name={val}
                          control={control}
                          rules={
                            column.required
                              ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: /^\d+$/,
                                  message:
                                    "Please Enter Valid No Of Positions",
                                },
                              }
                              : {}
                          }
                          render={({ field, fieldState }) => (
                            <input
                              type="number"
                              id={field.name}
                              {...field}
                              disabled={
                                modelname === "Principal_Address" ||
                                modelname === "Organizer_Information" ||
                                modelname === "Governor_Information" ||
                                modelname === "Incorporator_Information" ||
                                (IsCheckedAgent &&
                                  modelname === "Registered_Agent") ||
                                modelname === "Director_Information" ||
                                modelname === "Officer_Information" ||
                                modelname === "Filer_Information" ||
                                modelname === "Mailing_Information" ||
                                modelname === "Billing_Information" ||
                                modelname === "ucc_Debtor_Details" ||
                                modelname === "ucc_Secured_Party_Details" ||
                                modelname === "Beneficial_Owner_Information" ||
                                modelname === "Company_Applicant_Information"
                              }
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors(key);
                                if (errorMessageTag)
                                  errorMessageTag.textContent = "";
                              }}
                              style={{
                                flexGrow: "1",
                                paddingLeft: "10px",
                                paddingBottom: "5px",
                                borderRadius: "6px",
                              }}
                              className="dialog-form-input-field-wizard p-inputtext"
                            />
                          )}
                        />
                      </span>
                      <small
                        id={`${val}--error`}
                        className="error-msg font-fam-for-all "
                      ></small>
                      {getFormErrorMessage(key)}
                    </div>
                  </div>
                </div>
              )}
            </>
          );

        case "decimal":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className=" ">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: /^\d+$/,
                                message: "Please Enter Valid No Of Positions",
                              },
                            }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <input
                            type="float"
                            id={field.name}
                            {...field}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent")
                            }
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                              borderRadius: "6px",
                            }}
                            className="dialog-form-input-field-wizard p-inputtext"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );

        case "email":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className=" ">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Please Enter Valid Email",
                              },
                            }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <input
                            type="email"
                            id={field.name}
                            {...field}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent")
                            }
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                              borderRadius: "6px",
                            }}
                            className="dialog-form-input-field-wizard p-inputtext"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        case "password":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className=" ">
                      <Controller
                        control={control}
                        name={val}
                        rules={
                          column.required !== undefined &&
                            column.required === true
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value:
                                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                message:
                                  "Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.",
                              },
                            }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          <Password
                            id={field.name}
                            {...field}
                            toggleMask
                            inputRef={field.ref}
                            autoComplete={false}
                            className="dialog-form-input-field-wizard"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );

        case "richtext":
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className=" ">
                      <Controller
                        control={control}
                        name={val}
                        rules={
                          column.required !== undefined &&
                            column.required === true
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value:
                                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                message:
                                  "Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.",
                              },
                            }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          // <Password
                          //     id={field.name} {...field}
                          //     toggleMask
                          //     inputRef={field.ref}
                          //     autoComplete={false}
                          //     className='dialog-form-input-field-wizard'
                          // />
                          <Editor
                          value={field.value}
                            id={field.name}
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag && (errorMessageTag.textContent = "");
                            }}
                            inputRef={field.ref}
                            style={{ height: "320px" }}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        case "boolean":
          return (
            <div className="input-fields-container">
              <div className="input-fields-main">
                <div className="justify-content-center dialog-form-field-space w-full">
                  <label htmlFor="" className="form-label font-fam-for-all">
                    {convertName(key)}
                    {column.required ? (
                      <span className="form-field-mandatory">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <span className="">
                    <Controller
                      name={val}
                      control={control}
                      defaultValue={false}
                      rules={
                        column.required
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                      }
                      render={({ field }) => (
                        <Switch
                          checked={field.value ?? false}
                          disabled={
                            modelname === "Principal_Address" ||
                            modelname === "Organizer_Information" ||
                            modelname === "Incorporator_Information" ||
                            (IsCheckedAgent &&
                              modelname === "Registered_Agent") ||
                            modelname === "Director_Information" ||
                            modelname === "Officer_Information" ||
                            modelname === "Filer_Information" ||
                            modelname === "Mailing_Information" ||
                            modelname === "Billing_Information" ||
                            modelname === "ucc_Debtor_Details" ||
                            modelname === "ucc_Secured_Party_Details" ||
                            modelname === "Beneficial_Owner_Information" ||
                            modelname === "Company_Applicant_Information" ||
                            componentName === "ucc-assignee-details" ||
                            componentName ===
                            "ucc-amend-additional-secured-party-details" ||
                            componentName === "ucc-amnd-additional-debtor"
                          }
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                          }}
                          name={val}
                          color="primary"
                        />
                      )}
                    />
                  </span>
                  <small
                    id={`${val}--error`}
                    className="error-msg font-fam-for-all"
                  ></small>
                  {getFormErrorMessage(key)}
                </div>
              </div>
            </div>
          );

        case "date":
          const isPrefilledDisabled =
            key === "CF_Year_of_formation" || key === "EIN_Date";
          const today = dayjs();

          return (
            <div className="input-fields-container">
              <div className="input-fields-main">
                <div className="justify-content-center dialog-form-field-space w-full">
                  <label htmlFor="" className="form-label font-fam-for-all">
                    {convertName(key)}
                    {column.required ? (
                      <span className="form-field-mandatory">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <span className="">
                    {/* <Controller
                           name={val}
                           control={control}
                           rules={
                             column.required
                               ? { required: `${convertName(key)} Required.` }
                               : {}
                           }
                           render={({ field }) => {
                             // Check if the key should be prefilled and disabled
                             const isPrefilledDisabled =
                               key === "CF_Year_of_formation" || key === "EIN_Date";
                             // Use today's date if needed
                             const today = dayjs();
 
                             return (
                               <LocalizationProvider dateAdapter={AdapterDayjs}>
                                 <DatePicker
                                   className="strapi-date-picker"
                                   style={{ width: "322px" }}
                                   value={
                                     isPrefilledDisabled
                                       ? today
                                       : field.value
                                         ? dayjs(field.value)
                                         : null
                                   }
                                   // Disable the date picker if prefilled or based on existing conditions
                                   disabled={
                                     isPrefilledDisabled ||
                                     modelname === "Principal_Address" ||
                                     modelname === "Organizer_Information" ||
                                     modelname === "Incorporator_Information" ||
                                     (IsCheckedAgent &&
                                       modelname === "Registered_Agent") ||
                                     modelname === "Director_Information" ||
                                     modelname === "Officer_Information" ||
                                     modelname === "Filer_Information" ||
                                     componentName === "Mailing_Information" ||
                                     componentName === "Billing_Information" ||
                                     modelname === "ucc_Debtor_Details" ||
                                     modelname === "ucc_Secured_Party_Details" ||
                                     modelname === "Beneficial_Owner_Information" ||
                                     modelname === "Company_Applicant_Information" ||
                                     modelname === "Contact_Information"
                                   }
                                   onChange={(newValue) => {
                                     // If prefilled and disabled, do not update the value
                                     if (isPrefilledDisabled) {
                                       return;
                                     }
                                     if (newValue && newValue.isValid()) {
                                       if (orderTypeId === 18) {
                                         const formattedDate = newValue.format("YYYYMMDD");
                                         field.onChange(formattedDate);
                                       } else {
                                         field.onChange(newValue.format("YYYY-MM-DD"));
                                       }
                                     } else {
                                       field.onChange(null);
                                     }
                                     errorMessageTag &&
                                       (errorMessageTag.textContent = "");
                                   }}
                                   renderInput={(params) => <TextField {...params} />}
                                   disableFuture
                                 />
                               </LocalizationProvider>
                             );
                           }}
                             
                         /> */}

                    <Controller
                      name={val}
                      control={control}
                      // If prefilled/disabled, assign today's date as the default value
                      defaultValue={
                        isPrefilledDisabled ? today.format("YYYY-MM-DD") : ""
                      }
                      // Only apply required validation if the field isn’t prefilled/disabled
                      rules={
                        !isPrefilledDisabled && column.required
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                      }
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="strapi-date-picker"
                            style={{ width: "322px" }}
                            value={
                              isPrefilledDisabled
                                ? today
                                : field.value
                                  ? dayjs(field.value)
                                  : null
                            }
                            disabled={
                              isPrefilledDisabled ||
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent") ||
                              modelname === "Director_Information" ||
                              modelname === "Officer_Information" ||
                              modelname === "Filer_Information" ||
                              componentName === "Mailing_Information" ||
                              componentName === "Billing_Information" ||
                              modelname === "ucc_Debtor_Details" ||
                              modelname === "ucc_Secured_Party_Details" ||
                              modelname === "Beneficial_Owner_Information" ||
                              modelname === "Company_Applicant_Information" ||
                              modelname === "Contact_Information"
                            }
                            onChange={(newValue) => {
                              // If the field is prefilled/disabled, don't change the value
                              if (isPrefilledDisabled) return;
                              if (newValue && newValue.isValid()) {
                                const formattedDate =
                                  orderTypeId === 18
                                    ? newValue.format("YYYYMMDD")
                                    : newValue.format("YYYY-MM-DD");
                                field.onChange(formattedDate);
                              } else {
                                field.onChange(null);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            disableFuture
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </span>
                  <small
                    id={`${val}--error`}
                    className="error-msg font-fam-for-all"
                  ></small>
                  {getFormErrorMessage(key)}
                </div>
              </div>
            </div>
          );

        //       case "date":
        // return (
        //   <div className="input-fields-container">
        //     <div className="input-fields-main">
        //       <div className="justify-content-center dialog-form-field-space w-full">
        //         <label htmlFor="" className="form-label font-fam-for-all">
        //           {convertName(key)}
        //           {column.required ? (
        //             <span className="form-field-mandatory">*</span>
        //           ) : (
        //             ""
        //           )}
        //         </label>
        //         <span className="">
        //           <Controller
        //             name={val}
        //             control={control}
        //             rules={
        //               column.required
        //                 ? { required: `${convertName(key)} Required.` }
        //                 : {}
        //             }
        //             render={({ field }) => {
        //               // Check if the field should be prefilled and disabled
        //               const isPrefilledDisabled =
        //                 key === "CF_Year_of_formation" || key === "EIN_Date";

        //               return (
        //                 <DatePicker
        //                   label={convertName(key)}
        //                   value={
        //                     isPrefilledDisabled
        //                       ? dayjs() // Prefill today's date
        //                       : field.value || null
        //                   }
        //                   disabled={isPrefilledDisabled} // Make field uneditable
        //                   onChange={(newValue) => {
        //                     if (!isPrefilledDisabled) {
        //                       field.onChange(newValue);
        //                     }
        //                     errorMessageTag && (errorMessageTag.textContent = "");
        //                   }}
        //                   renderInput={(params) => <TextField {...params} />}
        //                   disableFuture={column.disableFuture || false} // Example of a possible column config
        //                 />
        //               );
        //             }}
        //           />
        //         </span>
        //         <small
        //           id={`${val}--error`}
        //           className="error-msg font-fam-for-all"
        //         ></small>
        //         {getFormErrorMessage(key)}
        //       </div>
        //     </div>
        //   </div>
        // );

        case "enumeration":
          if (options.length > 0) {
            const matchedItem = tempdata.find((item) => item.item === tempval);

            return (
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className="justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="">
                      <Controller
                        name={val}
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                            : column.required !== undefined &&
                              column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                        }
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            filter
                            resetFilterOnHide
                            name={val}
                            value={field.value}
                            options={options}
                            optionValue={
                              matchedItem !== undefined
                                ? matchedItem.label
                                : "name"
                            }
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent")
                            }
                            optionLabel={
                              matchedItem !== undefined
                                ? matchedItem.label
                                : "name"
                            } // Dynamically set label for the dropdown
                            onChange={(e) => {
                              field.onChange(e.value);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            maxSelectedLabels={3}
                            style={{ flexGrow: "1" }}
                            className="dialog-form-input-field-wizard"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all"
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <>
                <div className="input-fields-container">
                  <div className="input-fields-main">
                    <div className=" justify-content-center dialog-form-field-space w-full">
                      <label htmlFor="" className="form-label font-fam-for-all">
                        {convertName(key)}{" "}
                        {column.required !== undefined &&
                          column.required === true ? (
                          <span className="form-field-mandatory">*</span>
                        ) : (
                          ""
                        )}
                      </label>

                      <span className=" ">
                        <Controller
                          name={val}
                          control={control}
                          rules={
                            column.required !== undefined &&
                              column.required === true &&
                              column.regex !== undefined
                              ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: new RegExp(column.regex),
                                  message: `Please Enter Valid ${convertName(
                                    key
                                  )}`,
                                },
                              }
                              : column.required !== undefined &&
                                column.required === true
                                ? { required: `${convertName(key)} Required.` }
                                : {}
                          }
                          render={({ field }) => (
                            <Dropdown
                              id={field.name}
                              filter
                              resetFilterOnHide
                              name="jd"
                              value={field.value}
                              options={
                                column.enum !== undefined ? column.enum : []
                              }
                              onChange={(e) => {
                                field.onChange(e.value);
                                errorMessageTag &&
                                  (errorMessageTag.textContent = "");
                              }}
                              maxSelectedLabels={3}
                              style={{ flexGrow: "1" }}
                              className="dialog-form-input-field-wizard"
                            />
                          )}
                        />
                      </span>
                      <small
                        id={`${val}--error`}
                        className="error-msg font-fam-for-all "
                      ></small>
                      {getFormErrorMessage(key)}
                    </div>
                  </div>
                </div>
              </>
            );
          }

        default:
          return null;
      }
    }
    //  else if (
    //   What_Are_We_Changing !== undefined &&
    //   ![
    //     "COA_New_Name_To_Be_Used",
    //     "COA_What_Are_We_Changing",
    //     "Please_Tell_Us_What_You_Are_Changing",
    //   ].includes(val)
    // ) {

    //   if (Array.isArray(What_Are_We_Changing)) {
    //     if (What_Are_We_Changing.includes("County")) {
    //       return (
    //         <>
    //           <div className="input-fields-container">
    //             <div className="input-fields-main">
    //               <div className="justify-content-center dialog-form-field-space w-full">
    //                 <label htmlFor="" className="form-label font-fam-for-all">
    //                   {convertName("COC_Please_Include_New_County")}
    //                   {column.required ? (
    //                     <span className="form-field-mandatory">*</span>
    //                   ) : (
    //                     ""
    //                   )}
    //                 </label>

    //                 <span className="">
    //                   <Controller
    //                     name={"COC_Please_Include_New_County"}
    //                     control={control}
    //                     rules={
    //                       column.required && column.regex
    //                         ? {
    //                           required: `${convertName(key)} Required.`,
    //                           pattern: {
    //                             value: new RegExp(column.regex),
    //                             message: `Please Enter Valid ${convertName(
    //                               key
    //                             )}`,
    //                           },
    //                         }
    //                         : column.required
    //                           ? { required: `${convertName(key)} Required.` }
    //                           : {}
    //                     }
    //                     render={({ field }) => (
    //                       <Dropdown
    //                         id={field.name}
    //                         filter
    //                         resetFilterOnHide
    //                         name="jd"
    //                         value={field.value}
    //                         optionLabel="countyName"
    //                         optionValue="countyName"
    //                         options={allCounty}
    //                         onChange={(e) => {
    //                           field.onChange(e.value);
    //                           errorMessageTag &&
    //                             (errorMessageTag.textContent = "");
    //                         }}
    //                         maxSelectedLabels={3}
    //                         style={{ flexGrow: "1" }}
    //                         className="dialog-form-input-field-wizard"
    //                       />
    //                     )}
    //                   />
    //                 </span>
    //                 <small
    //                   id={`${val}--error`}
    //                   className="error-msg font-fam-for-all"
    //                 ></small>
    //                 {getFormErrorMessage(key)}
    //               </div>
    //             </div>
    //           </div>
    //         </>
    //       );
    //     }

    //     // if (What_Are_We_Changing.includes("Other")) {
    //     //     return (
    //     //         <>
    //     //             <div className="input-fields-container">
    //     //                 <div className="input-fields-main">
    //     //                     <div className="justify-content-center dialog-form-field-space w-full">
    //     //                         <label htmlFor="" className="form-label font-fam-for-all">
    //     //                             {convertName("Please_Tell_Us_What_You_Are_Changing")}
    //     //                             {column.required ? (
    //     //                                 <span className="form-field-mandatory">*</span>
    //     //                             ) : (
    //     //                                 ""
    //     //                             )}
    //     //                         </label>

    //     //                         <span className="">
    //     //                             <Controller
    //     //                                 name={"Please_Tell_Us_What_You_Are_Changing"}
    //     //                                 control={control}
    //     //                                 rules={
    //     //                                     column.required && column.regex
    //     //                                         ? {
    //     //                                             required: `${convertName(key)} Required.`,
    //     //                                             pattern: {
    //     //                                                 value: new RegExp(column.regex),
    //     //                                                 message: `Please Enter Valid ${convertName(key)}`,
    //     //                                             },
    //     //                                         }
    //     //                                         : column.required
    //     //                                             ? { required: `${convertName(key)} Required.` }
    //     //                                             : {}
    //     //                                 }
    //     //                                 render={({ field }) => (
    //     //                                     <InputText
    //     //                                         id={field.name}
    //     //                                         name="jd"
    //     //                                         value={field.value}
    //     //                                         onChange={(e) => {
    //     //                                             field.onChange(e.target.value);
    //     //                                             errorMessageTag && (errorMessageTag.textContent = "");
    //     //                                         }}
    //     //                                         style={{ flexGrow: "1" }}
    //     //                                         className="dialog-form-input-field-wizard"
    //     //                                     />
    //     //                                 )}
    //     //                             />
    //     //                         </span>
    //     //                         <small id={`${val}--error`} className="error-msg font-fam-for-all"></small>
    //     //                         {getFormErrorMessage(key)}
    //     //                     </div>
    //     //                 </div>
    //     //             </div>
    //     //         </>
    //     //     );
    //     // }
    //   }
    // }
    else if (
      COA_What_Are_We_Amending !== undefined &&
      val !== "COA_New_Name_To_Be_Used" &&
      val !== "COA_What_Are_We_Changing"
    ) {
      switch (COA_What_Are_We_Amending) {
        case "Name":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("COA_New_Name_To_Be_Used")}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className=" ">
                      <Controller
                        name="COA_New_Name_To_Be_Used"
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                            : column.required !== undefined &&
                              column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                        }
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            autoComplete={false}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent")
                            }
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                            }}
                            className="dialog-form-input-field-wizard"
                            maxLength={50}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </div>
          );

        case "Provisions":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("COA_What_Are_We_Changing")}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className=" ">
                      <Controller
                        name="COA_What_Are_We_Changing"
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                            : column.required !== undefined &&
                              column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                        }
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            autoComplete={false}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent")
                            }
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                            }}
                            className="dialog-form-input-field-wizard"
                            maxLength={50}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </div>
          );

        case "Other":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("COA_Please_Tell_Us_What_You_Want_To_Amend")}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className=" ">
                      <Controller
                        name="COA_Please_Tell_Us_What_You_Want_To_Amend"
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                            ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                            : column.required !== undefined &&
                              column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                        }
                        render={({ field, fieldState }) => (
                          <InputText
                            id={field.name}
                            {...field}
                            autoComplete={false}
                            onChange={(e) => {
                              field.onChange(e);
                              clearErrors(key);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            disabled={
                              modelname === "Principal_Address" ||
                              modelname === "Organizer_Information" ||
                              modelname === "Incorporator_Information" ||
                              (IsCheckedAgent &&
                                modelname === "Registered_Agent")
                            }
                            style={{
                              flexGrow: "1",
                              paddingLeft: "10px",
                              paddingBottom: "5px",
                            }}
                            className="dialog-form-input-field-wizard"
                            maxLength={50}
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </div>
          );
        default:
          break;
      }
    } else if (
      cc_What_Type_Document !== undefined &&
      cc_What_Type_Document === "Other - Please specify"
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className=" justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("cc_Specify_Other_Document")}{" "}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className=" ">
                  <Controller
                    name="cc_Specify_Other_Document"
                    control={control}
                    rules={
                      column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                        ? {
                          required: `${convertName(
                            "cc_Specify_Other_Document"
                          )} Required.`,
                          pattern: {
                            value: new RegExp(column.regex),
                            message: `Please Enter Valid ${convertName(key)}`,
                          },
                        }
                        : column.required !== undefined &&
                          column.required === true
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                    }
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoComplete={false}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors(key);
                          errorMessageTag && (errorMessageTag.textContent = "");
                        }}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent && modelname === "Registered_Agent")
                        }
                        style={{
                          flexGrow: "1",
                          paddingLeft: "10px",
                          paddingBottom: "5px",
                        }}
                        className="dialog-form-input-field-wizard"
                        maxLength={50}
                      />
                    )}
                  />
                </span>
                <small
                  id={`${"cc_Specify_Other_Document"}--error`}
                  className="error-msg font-fam-for-all "
                ></small>
                {getFormErrorMessage("cc_Specify_Other_Document")}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      pricipal_activity !== undefined &&
      pricipal_activity === "Other (specify)"
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className=" justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("EIN_Other")}{" "}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className=" ">
                  <Controller
                    name="EIN_Other"
                    control={control}
                    rules={
                      column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                        ? {
                          required: `${convertName("EIN_Other")} Required.`,
                          pattern: {
                            value: new RegExp(column.regex),
                            message: `Please Enter Valid ${convertName(key)}`,
                          },
                        }
                        : column.required !== undefined &&
                          column.required === true
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                    }
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoComplete={false}
                        onChange={(e) => {
                          field.onChange(e);
                          clearErrors(key);
                          errorMessageTag && (errorMessageTag.textContent = "");
                        }}
                        disabled={
                          modelname === "Principle_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details"
                        }
                        style={{
                          flexGrow: "1",
                          paddingLeft: "10px",
                          paddingBottom: "5px",
                        }}
                        className="dialog-form-input-field-wizard"
                        maxLength={50}
                      />
                    )}
                  />
                </span>
                <small
                  id={`${"EIN_Other"}--error`}
                  className="error-msg font-fam-for-all "
                ></small>
                {getFormErrorMessage("EIN_Other")}
              </div>
            </div>
          </div>
        </>
      );
    } 
    else if (
      ucc_UCC_Collateral_Type !== undefined &&
      val !== "ucc_Collateral_Statement"
    ) {
      switch (ucc_UCC_Collateral_Type) {
        case "Upload Their Own Collateral Statement":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className="justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("ucc_Collateral_Statement")}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="">
                      <Controller
                        name={"ucc_Collateral_Statement"}
                        control={control}
                        rules={
                          column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                            ? {
                              required: `${convertName(
                                "ucc_Collateral_Statement"
                              )} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  "ucc_Collateral_Statement"
                                )}`,
                              },
                            }
                            : column.required !== undefined &&
                              column.required === true
                              ? {
                                required: `${convertName(
                                  "ucc_Collateral_Statement"
                                )} Required.`,
                              }
                              : {}
                        }
                        render={({ field }) => (
                          // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                          <input
                            type="file"
                            name="ucc_Collateral_Statement"
                            onChange={(e) =>{
                              handleFile(e, "ucc_Collateral_Statement")
                            }
                              
                            }
                          />
                        )}
                      />
                    </span>
                    {getValues("ucc_Collateral_Statement") &&
                      typeof getValues("ucc_Collateral_Statement") ===
                      "string" && (
                        <div className="flex items-baseline  justify-between gap-2">
                          <p className="font-web-color font-fam-for-all">
                            {getValues("ucc_Collateral_Statement")
                              .split("/")
                              .pop()}
                          </p>
                          <Button
                            type="button"
                            icon="pi pi-trash"
                            className="text-black bg-transparent rounded border-none outline-transparent border-none hover:text-red-500"
                            onClick={() => {
                              setValue("ucc_Collateral_Statement", ""); // Clear form field value
                            // document.getElementById(`${val}--error`).textContent = "";
                            const fileInput = document.querySelector('input[name="ucc_Collateral_Statement"]');
                              if (fileInput) {
                                fileInput.value = "";  
                              }
                          }}
                          />
                        </div>
                      )}
                    <small
                      id={`${"ucc_Collateral_Statement"}--error`}
                      className="error-msg font-fam-for-all"
                    ></small>
                    {getFormErrorMessage("ucc_Collateral_Statement")}
                  </div>
                </div>
              </div>
            </div>
          );


        case "Type Their Own Collateral Statement":
          return (
            <div>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName("ucc_Type_Your_Collateral_Statement")}{" "}
                      {column.required !== undefined &&
                        column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className=" ">
                      <Controller
                        control={control}
                        name={"ucc_Type_Your_Collateral_Statement"}
                        rules={
                          column.required !== undefined &&
                            column.required === true
                            ? {
                              required: `${convertName(
                                "ucc_Type_Your_Collateral_Statement"
                              )} Required.`,
                              pattern: {
                                value:
                                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                message:
                                  "Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.",
                              },
                            }
                            : {}
                        }
                        render={({ field, fieldState }) => (
                          // <Password
                          //     id={field.name} {...field}
                          //     toggleMask
                          //     inputRef={field.ref}
                          //     autoComplete={false}
                          //     className='dialog-form-input-field-wizard'
                          // />
                          <Editor
                          key={field.value}
                          value={field.value}
                            id={field.name}
                            {...field}
                          
                            // value={editorValue}
                            onTextChange={(e) => {
                              console.log("Editor Value:", e.htmlValue); // Log HTML value
                              const newValue = e.textValue; // Get the HTML content of the editor

                              field.onChange(newValue); // Update the form field value
                              setValue(
                                "ucc_Type_Your_Collateral_Statement",
                                newValue
                              ); // Update value in the form
                              setValue("ucc_Collateral_Statement", "")
                              // clearErrors("ucc_Type_Your_Collateral_Statement"); // Clear errors
                              clearErrors("ucc_Collateral_Statement"); 
                            }}
                            
                            inputRef={field.ref}
                            style={{ height: "320px" }}
                          />
                          
                        )}
                      />
                    </span>
                    <small
                      id={`${"ucc_Type_Your_Collateral_Statement"}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage("ucc_Type_Your_Collateral_Statement")}
                  </div>
                </div>
              </div>
            </div>
          );

          case "Use Our Collateral Statement":
                    return (
                      <>
                        <div className="">
                          <div className="input-fields-container">
                            <div className="input-fields-main">
                              <div className="justify-content-center dialog-form-field-space w-full">
                                <label htmlFor="" className="form-label font-fam-for-all">
                                  {convertName("ucc_Collateral_Statement")}
                                  {column.required !== undefined &&
                                  column.required === true ? (
                                    <span className="form-field-mandatory">*</span>
                                  ) : (
                                    ""
                                  )}
                                </label>
                                <span className=" ">
                                  <Controller
                                    name="ucc_Collateral_Statement"
                                    control={control}
                                    rules={{
                                      required: `${convertName(
                                        "ucc_Collateral_Statement"
                                      )} Required.`,
                                    }}
                                    render={({ field }) => (
                                      // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                                      <input
                                        type="file"
                                        name="ucc_Collateral_Statement"
                                        required
                                        onChange={(e) => { 
                                          
                                          handleFile(e, "ucc_Collateral_Statement")
                                          const file = e.target.files[0];
            
                                          if (file) {
                                            setValue("ucc_Collateral_Statement", file); // Store the file in React Hook Form
                                            clearErrors("ucc_Collateral_Statement"); // Clear previous errors if any
                                          }
            
                                          handleFile(e, "ucc_Collateral_Statement");
                                        }}
                                      />
                                    )}
                                  />
                                </span>
                                {/* {getValues(val) && typeof getValues(val) === "string" && (
                                  <div className="flex items-baseline  justify-between gap-2">
                                    <p className="font-web-color font-fam-for-all">
                                      {getValues(val).split("/").pop()}
                                    </p>
                                    <Button
                                      type="button"
                                      icon="pi pi-trash"
                                      className="text-black bg-transparent rounded border-none outline-transparent border-none hover:text-red-500"
                                      onClick={() => {
                                        // setValue(val, ""); // Clear form field value
                                        setValue("ucc_Collateral_Statement", ""); 
                                        setValue("ucc_Type_Your_Collateral_Statement", "");
                                        const fileInput = document.querySelector('input[name="ucc_Collateral_Statement"]');
                                        if (fileInput) {
                                          fileInput.value = "";  
                                        } 
                                        // document.getElementById(`${val}--error`).textContent = "";
                                      }}
                                    />
                                  </div>
                                )} */}
                                {getValues("ucc_Collateral_Statement") &&
                      typeof getValues("ucc_Collateral_Statement") ===
                        "string" && (
                        <div className="flex items-baseline  justify-between gap-2">
                          <p className="font-web-color font-fam-for-all">
                            {getValues("ucc_Collateral_Statement")
                              .split("/")
                              .pop()}
                          </p>
                          <Button
                            type="button"
                            icon="pi pi-trash"
                            className="text-black bg-transparent rounded border-none outline-transparent border-none hover:text-red-500"
                            onClick={() => {
                              setValue("ucc_Collateral_Statement", ""); // Clear form field value
                            // document.getElementById(`${val}--error`).textContent = "";
                            const fileInput = document.querySelector('input[name="ucc_Collateral_Statement"]');
                              if (fileInput) {
                                fileInput.value = "";  
                              }
                          }}
                          />
                        </div>
                      )}
                                <small
                                  id={`${"ucc_Collateral_Statement"}--error`}
                                  className="error-msg font-fam-for-all"
                                ></small>
                                {getFormErrorMessage("ucc_Collateral_Statement")}
                              </div>
                            </div>
                          </div>
                          <div className="flex align-items-center justify-content-start py-2">
                            <a
                              href={templateCsv}
                              download="template.docx"
                              className="flex gap-1"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5M5.83333 8.33333L10 12.5M10 12.5L14.1667 8.33333M10 12.5V2.5"
                                  stroke="#004EEC"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <div className="bulk-upload-card-header-text">
                                Download Template
                              </div>
                            </a>
                          </div>
                        </div>
                      </>
                    );
                  
        default:
          break;
      }
    } 
    else if (
      finCenId &&
      val === `boi_FinCEN_ID_Number-${val.split("-")[1] || 0}`
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className=" justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("boi_FinCEN_ID_Number")}{" "}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className=" ">
                  <Controller
                    name={`boi_FinCEN_ID_Number-${val.split("-")[1] || 0}`}
                    control={control}
                    rules={
                      column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                        ? {
                          required: `${convertName(
                            "boi_FinCEN_ID_Number"
                          )} Required.`,
                          pattern: {
                            value: new RegExp(column.regex),
                            message: `Please Enter Valid ${convertName(key)}`,
                          },
                        }
                        : column.required !== undefined &&
                          column.required === true
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                    }
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoComplete={false}
                        // onChange={(e) => errorMessageTag.textContent = ''}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information" ||
                          componentName === "ucc-assignee-details" ||
                          componentName ===
                          "ucc-amend-additional-secured-party-details" ||
                          componentName === "ucc-amnd-additional-debtor"
                        }
                        style={{
                          flexGrow: "1",
                          paddingLeft: "10px",
                          paddingBottom: "5px",
                        }}
                        className="dialog-form-input-field-wizard"
                        maxLength={50}
                      />
                    )}
                  />
                </span>
                <small
                  id={`${"boi_FinCEN_ID_Number"}--error`}
                  className="error-msg font-fam-for-all "
                ></small>
                {getFormErrorMessage("boi_FinCEN_ID_Number")}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      finCenIdCA &&
      val === `boica_FinCEN_ID_Number-${val.split("-")[1] || 0}`
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className=" justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("boica_FinCEN_ID_Number")}{" "}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className=" ">
                  <Controller
                    name={`boica_FinCEN_ID_Number-${val.split("-")[1] || 0}`}
                    control={control}
                    rules={
                      column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                        ? {
                          required: `${convertName(
                            "boica_FinCEN_ID_Number"
                          )} Required.`,
                          pattern: {
                            value: new RegExp(column.regex),
                            message: `Please Enter Valid ${convertName(key)}`,
                          },
                        }
                        : column.required !== undefined &&
                          column.required === true
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                    }
                    render={({ field, fieldState }) => (
                      <InputText
                        id={field.name}
                        {...field}
                        autoComplete={false}
                        // onChange={(e) => errorMessageTag.textContent = ''}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information" ||
                          componentName === "ucc-assignee-details" ||
                          componentName ===
                          "ucc-amend-additional-secured-party-details" ||
                          componentName === "ucc-amnd-additional-debtor"
                        }
                        style={{
                          flexGrow: "1",
                          paddingLeft: "10px",
                          paddingBottom: "5px",
                        }}
                        className="dialog-form-input-field-wizard"
                        maxLength={50}
                      />
                    )}
                  />
                </span>
                <small
                  id={`${`boica_FinCEN_ID_Number-${val.split("-")[1] || 0
                    }`}--error`}
                  className="error-msg font-fam-for-all "
                ></small>
                {getFormErrorMessage("boica_FinCEN_ID_Number")}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      finCenIdCA &&
      val === `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className="justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("boica_FinCEN_ID_Document")}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className="">
                  <Controller
                    name={`boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`}
                    control={control}
                    rules={
                      column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                        ? {
                          required: `${convertName(
                            "boica_FinCEN_ID_Document"
                          )} Required.`,
                          pattern: {
                            value: new RegExp(column.regex),
                            message: `Please Enter Valid ${convertName(
                              "boica_FinCEN_ID_Document"
                            )}`,
                          },
                        }
                        : column.required !== undefined &&
                          column.required === true
                          ? {
                            required: `${convertName(
                              "boica_FinCEN_ID_Document"
                            )} Required.`,
                          }
                          : {}
                    }
                    render={({ field }) => (
                      // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                      <input
                        name={`boica_FinCEN_ID_Document-${val.split("-")[1] || 0
                          }`}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information" ||
                          componentName === "ucc-assignee-details" ||
                          componentName ===
                          "ucc-amend-additional-secured-party-details" ||
                          componentName === "ucc-amnd-additional-debtor"
                        }
                        type="file"
                        onChange={(e) =>
                          handleFile(
                            e,
                            `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                          )
                        }
                      />
                    )}
                  />
                </span>
                {getValues(
                  `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                ) !== undefined &&
                  typeof getValues(
                    `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                  ) === "string" && (
                    <p className="font-web-color font-fam-for-all">
                      {getValues(
                        `boica_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                      )
                        .split("/")
                        .pop()}
                    </p>
                  )}
                <small
                  id={`${`boica_FinCEN_ID_Document-${val.split("-")[1] || 0
                    }`}--error`}
                  className="error-msg font-fam-for-all"
                ></small>
                {getFormErrorMessage(key)}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      finCenId &&
      val === `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
    ) {
      return (
        <>
          <div className="input-fields-container">
            <div className="input-fields-main">
              <div className="justify-content-center dialog-form-field-space w-full">
                <label htmlFor="" className="form-label font-fam-for-all">
                  {convertName("boi_FinCEN_ID_Document")}
                  {column.required !== undefined && column.required === true ? (
                    <span className="form-field-mandatory">*</span>
                  ) : (
                    ""
                  )}
                </label>
                <span className="">
                  <Controller
                    name={`boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`}
                    control={control}
                    rules={
                      column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                        ? {
                          required: `${convertName(
                            "boi_FinCEN_ID_Document"
                          )} Required.`,
                          pattern: {
                            value: new RegExp(column.regex),
                            message: `Please Enter Valid ${convertName(
                              "boi_FinCEN_ID_Document"
                            )}`,
                          },
                        }
                        : column.required !== undefined &&
                          column.required === true
                          ? {
                            required: `${convertName(
                              "boi_FinCEN_ID_Document"
                            )} Required.`,
                          }
                          : {}
                    }
                    render={({ field }) => (
                      // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                      <input
                        name={`boi_FinCEN_ID_Document-${val.split("-")[1] || 0
                          }`}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information" ||
                          componentName === "ucc-assignee-details" ||
                          componentName ===
                          "ucc-amend-additional-secured-party-details" ||
                          componentName === "ucc-amnd-additional-debtor"
                        }
                        type="file"
                        onChange={(e) =>
                          handleFile(
                            e,
                            `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                          )
                        }
                      />
                    )}
                  />
                </span>
                {getValues(
                  `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                ) !== undefined &&
                  typeof getValues(
                    `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                  ) === "string" && (
                    <p className="font-web-color font-fam-for-all">
                      {getValues(
                        `boi_FinCEN_ID_Document-${val.split("-")[1] || 0}`
                      )
                        .split("/")
                        .pop()}
                    </p>
                  )}
                <small
                  id={`${"boi_FinCEN_ID_Document"}--error`}
                  className="error-msg font-fam-for-all"
                ></small>
                {getFormErrorMessage(key)}
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      finCenId === false &&
      val === `boi_Date_of_Birth-${val.split("-")[1] || 0}`
    ) {
      return (
        <div className="input-fields-container">
          <div className="input-fields-main">
            <div className="justify-content-center dialog-form-field-space w-full">
              <label htmlFor="" className="form-label font-fam-for-all">
                {convertName(key)}
                {column.required ? (
                  <span className="form-field-mandatory">*</span>
                ) : (
                  ""
                )}
              </label>
              <span className="">
                <Controller
                  name={val}
                  control={control}
                  rules={
                    column.required
                      ? { required: `${convertName(key)} Required.` }
                      : {}
                  }
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="strapi-date-picker"
                        style={{ width: "322px" }}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information" ||
                          componentName === "ucc-assignee-details" ||
                          componentName ===
                          "ucc-amend-additional-secured-party-details" ||
                          componentName === "ucc-amnd-additional-debtor"
                        }
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(newValue) => {
                          if (newValue && newValue.isValid()) {
                            const formattedDate = newValue.format("YYYYMMDD");
                            field.onChange(formattedDate);
                          } else {
                            field.onChange(null);
                          }
                          errorMessageTag && (errorMessageTag.textContent = "");
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        disableFuture
                      />
                    </LocalizationProvider>
                  )}
                />
              </span>
              <small
                id={`${val}--error`}
                className="error-msg font-fam-for-all"
              ></small>
              {getFormErrorMessage(key)}
            </div>
          </div>
        </div>
      );
    } else if (
      finCenIdCA === false &&
      val === `boica_Date_of_Birth-${val.split("-")[1] || 0}`
    ) {
      return (
        <div className="input-fields-container">
          <div className="input-fields-main">
            <div className="justify-content-center dialog-form-field-space w-full">
              <label htmlFor="" className="form-label font-fam-for-all">
                {convertName(key)}
                {column.required ? (
                  <span className="form-field-mandatory">*</span>
                ) : (
                  ""
                )}
              </label>
              <span className="">
                <Controller
                  name={val}
                  control={control}
                  rules={
                    column.required
                      ? { required: `${convertName(key)} Required.` }
                      : {}
                  }
                  render={({ field }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className="strapi-date-picker"
                        style={{ width: "322px" }}
                        value={field.value ? dayjs(field.value) : null}
                        disabled={
                          modelname === "Principal_Address" ||
                          modelname === "Organizer_Information" ||
                          modelname === "Incorporator_Information" ||
                          (IsCheckedAgent &&
                            modelname === "Registered_Agent") ||
                          modelname === "Director_Information" ||
                          modelname === "Officer_Information" ||
                          modelname === "Filer_Information" ||
                          modelname === "Mailing_Information" ||
                          modelname === "Billing_Information" ||
                          modelname === "ucc_Debtor_Details" ||
                          modelname === "ucc_Secured_Party_Details" ||
                          modelname === "Beneficial_Owner_Information" ||
                          modelname === "Company_Applicant_Information" ||
                          componentName === "ucc-assignee-details" ||
                          componentName ===
                          "ucc-amend-additional-secured-party-details" ||
                          componentName === "ucc-amnd-additional-debtor"
                        }
                        onChange={(newValue) => {
                          if (newValue && newValue.isValid()) {
                            const formattedDate = newValue.format("YYYYMMDD");
                            field.onChange(formattedDate);
                          } else {
                            field.onChange(null);
                          }
                          errorMessageTag && (errorMessageTag.textContent = "");
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        disableFuture
                      />
                    </LocalizationProvider>
                  )}
                />
              </span>
              <small
                id={`${val}--error`}
                className="error-msg font-fam-for-all"
              ></small>
              {getFormErrorMessage(key)}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  let currentTabs = 0;
  let currentSteps = 1;
  function showTab(n) {
    // This function will display the specified tab of the form...
    var x = document.getElementsByClassName("tab");

    if (x.length > 0) {
      if (x[n] !== undefined) {
        x[n].style.display = "block";
      }

      //... and fix the Previous/Next buttons:
      if (n == 0) {
        document.getElementById("prevBtn").style.display = "none";
      } else {
        document.getElementById("prevBtn").style.display = "flex";
      }
      if (n == x.length - 1 && inputFormData.length > 0) {
        // document.getElementById("nextBtn").innerHTML = "Submit";
        document.getElementById("nextBtn").style.display = "none";
        document.getElementById("submitBtn").style.display = "flex";
      } else {
        document.getElementById("nextBtn").innerHTML = "Save & Next";
        document.getElementById("nextBtn").style.display = "flex";
        document.getElementById("submitBtn").style.display = "none";
      }
      //... and run a function that will display the correct step indicator:
      fixStepIndicator(n);
    }
  }

  function deepCompareObjects(obj1, obj2) {
    if (typeof obj1 !== typeof obj2) return false;

    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      if (obj1.length !== obj2.length) return false;
      for (let i = 0; i < obj1.length; i++) {
        if (!deepCompareObjects(obj1[i], obj2[i])) return false;
      }
      return true;
    }

    if (typeof obj1 === "object" && obj1 !== null && obj2 !== null) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;
      for (let key of keys1) {
        if (!deepCompareObjects(obj1[key], obj2[key])) return false;
      }
      return true;
    }

    return obj1 === obj2;
  }

  const updateOrAddData = (dataArray, type) => {
    if (dataArray.length > 0) {
      const existingIndex = keyPersonnelStrapiArray.findIndex(
        (item) => item.type === type
      );

      if (existingIndex !== -1) {
        keyPersonnelStrapiArray[existingIndex].data = dataArray;
      } else {
        keyPersonnelStrapiArray.push({
          data: dataArray,
          type: type,
        });
      }
    }
  };

  const updateOrAddSingleData = (data, type) => {
    if (data?.id) {
      const existingIndex = keyPersonnelStrapiArray.findIndex(
        (item) => item.type === type
      );

      if (existingIndex !== -1) {
        keyPersonnelStrapiArray[existingIndex].data = data;
      } else {
        keyPersonnelStrapiArray.push({ data, type });
      }
    }
  };

  // const updateOrAddData = (dataArray, type) => {
  //   if (dataArray.length > 0) {
  //     setKeyPersonnelStrapiArray((prevArray) => {
  //       const existingIndex = prevArray.findIndex((item) => item.type === type);

  //       if (existingIndex !== -1) {
  //         const updatedArray = [...prevArray];
  //         updatedArray[existingIndex] = { ...updatedArray[existingIndex], data: dataArray };
  //         return updatedArray;
  //       } else {
  //         return [...prevArray, { data: dataArray, type }];
  //       }
  //     });
  //   }
  // };

  // const updateOrAddSingleData = (data, type) => {
  //   if (data?.id) {
  //     setKeyPersonnelStrapiArray((prevArray) => {
  //       const existingIndex = prevArray.findIndex((item) => item.type === type);

  //       if (existingIndex !== -1) {
  //         const updatedArray = [...prevArray];
  //         updatedArray[existingIndex] = { ...updatedArray[existingIndex], data };
  //         return updatedArray;
  //       } else {
  //         return [...prevArray, { data, type }];
  //       }
  //     });
  //   }
  // };

  const [isSecondDraft, setIsSecondDraft] = useState(false);

  const nextPrev = (n) => {
    setWizardScroll(!wizardScroll);
    setOrderContinue(true);
    if (strapiId) {
      console.log("in if");
      var x = document.getElementsByClassName("tab");

      if (x.length > 0) {
        if (n == 1 && !validateForm()) return false;

        if (x[currentTab] !== undefined) {
          x[currentTab].style.display = "none";
        }

        if (n === 1) {
          if (currentStep < inputFormData.length + 5) {
            setCurrentStep(currentStep + 1);
          }
        }

        if (n === -1) {
          if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
          }
        }

        const newTab = currentTab + n;

        setCurrentTab(newTab);

        showTab(newTab);
      }

      const keyPersonalArr = [];
      if (incorporator?.id) keyPersonalArr.push(incorporator.id);
      if (officer?.id) keyPersonalArr.push(officer.id);
      if (director?.id) keyPersonalArr.push(director.id);
      if (organizerData?.id) keyPersonalArr.push(organizerData.id);
      if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
      if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);
      if (president?.id) keyPersonalArr.push(president.id);
      if (treasurer?.id) keyPersonalArr.push(treasurer.id);
      if (secretary?.id) keyPersonalArr.push(secretary.id);

      if (registeredAgent?.id && IsCheckedAgent && orderTypeId === 24) {
        // keyPersonalArr.push({ data: registeredAgent, type: "REGISTER_AGENT" });
        updateOrAddSingleData(registeredAgent, "REGISTER_AGENT");
      }
      // handle confirm, on submit - >

      // const keyPersonalStrapiArr = [];
      // if (incorporator?.id) keyPersonalStrapiArr.push({ "data": incorporator, "type": "INCORPORATOR" });
      // if (officer?.id) keyPersonalStrapiArr.push({ "data": officer, "type": "OFFICER" });
      // if (director?.id) keyPersonalStrapiArr.push({ "data": director, "type": "DIRECTOR" });
      // if (organizerData?.id) keyPersonalStrapiArr.push({ "data": organizerData, "type": "ORGANIZER" });
      // if (bilingAddress?.id) keyPersonalStrapiArr.push({ "data": bilingAddress, "type": "BILLING" });

      // if (allDebtorData.length > 0) {
      //     const allDebtor = allDebtorData.map((debtor, index) => debtor[`debtor_${index}`]).filter((debtor) => debtor?.id);
      //     if (allDebtor.length > 0) {
      //         keyPersonalStrapiArr.push({
      //             data: allDebtor,
      //             type: "DEBTOR",
      //         });
      //     }
      // }

      // if (allAdditionalDebtors.length > 0) {
      //     const allAdditionalDebtor = allAdditionalDebtors.map((debtor, index) => debtor[`additionalDebtor_${index}`]).filter((debtor) => debtor?.id);
      //     if (allAdditionalDebtor.length > 0) {
      //         keyPersonalStrapiArr.push({
      //             data: allAdditionalDebtor,
      //             type: "ADDITIONAL DEBTOR",
      //         });
      //     }
      // }

      // if (allSecuredParties.length > 0) {
      //     const allSecuredPartie = allSecuredParties
      //         .map((party, index) => party[`securedParty1_${index}`])
      //         .filter((party) => party?.id);
      //     if (allSecuredPartie.length > 0) {
      //         keyPersonalStrapiArr.push({
      //             data: allSecuredPartie,
      //             type: "SECURED PARTY",
      //         });
      //     }
      // }

      // if (allAdditionalSecuredParties.length > 0) {
      //     const allAdditionalSecuredPartie = allAdditionalSecuredParties
      //         .map((party, index) => party[`securedParty2_${index}`])
      //         .filter((party) => party?.id);
      //     if (allAdditionalSecuredPartie.length > 0) {
      //         keyPersonalStrapiArr.push({
      //             data: allAdditionalSecuredPartie,
      //             type: "ADDITIONAL SECURED PARTY",
      //         });
      //     }
      // }

      // if (allBeneficialData.length > 0) {
      //     const allBeneficial = allBeneficialData
      //         .map((party, index) => party[`beneficial_${index}`])
      //         .filter((party) => party?.id);
      //     if (allBeneficial.length > 0) {
      //         keyPersonalStrapiArr.push({
      //             data: allBeneficial,
      //             type: "BENEFICIAL OWNER",
      //         });
      //     }
      // }

      // if (allCompanyApplicantData.length > 0) {
      //     const allCompanyApplicant = allCompanyApplicantData
      //         .map((party, index) => party[`company_applicant_${index}`])
      //         .filter((party) => party?.id);
      //     if (allCompanyApplicant.length > 0) {
      //         keyPersonalStrapiArr.push({
      //             data: allCompanyApplicant,
      //             type: "COMPANY APPLICANT",
      //         });
      //     }
      // }

      updateOrAddSingleData(incorporator, "INCORPORATOR");
      updateOrAddSingleData(officer, "OFFICER");
      updateOrAddSingleData(director, "DIRECTOR");
      updateOrAddSingleData(organizerData, "ORGANIZER");
      updateOrAddSingleData(bilingAddress, "BILLING");
      updateOrAddSingleData(assigneeData, "ASSIGNEE");
      updateOrAddSingleData(location, "PRINCIPAL_ADDRESS");

      const typeMappings = [
        {
          data: allBeneficialData,
          key: "beneficial",
          type: "BENEFICIAL OWNER",
        },
        {
          data: allCompanyApplicantData,
          key: "company_applicant",
          type: "COMPANY APPLICANT",
        },
        { data: allDebtorData, key: "debtor", type: "DEBTOR" },
        {
          data: allAdditionalDebtors,
          key: "additionalDebtor",
          type: "ADDITIONAL DEBTOR",
        },
        {
          data: allSecuredParties,
          key: "securedParty1",
          type: "SECURED PARTY",
        },
        {
          data: allAdditionalSecuredParties,
          key: "securedParty2",
          type: "ADDITIONAL SECURED PARTY",
        },
      ];

      typeMappings.forEach(({ data, key, type }) => {
        const filteredData = data.map((item, index) => item[`${key}_${index}`]);
        // .filter((item) => item?.id);
        updateOrAddData(filteredData, type);
      });

      const areIdentical = deepCompareObjects(
        serviceDataTemp,
        selectedCheckBoxData
      );

      const upadatedProduct = selectedCheckBoxData.map((val) => {
        return {
          productListing: val.product,
          quantity: 1,
          unitPrice: val.product.unitPrice,
        };
      });
      const modelDatas = processFormData(allModels);

      const finalData1 = buildFinalData(
        mainModel.schema.attributes,
        modelDatas
      );

      const jsonAutomation = {
        EntityType: enityName,
        State: state,
        County: county,
        Header: orderHeader,
        Payload: finalData1,
        legalName:
          getValues("CD_LLC_Name") !== undefined
            ? getValues("CD_LLC_Name")
            : getValues("CD_Corporation_Name") !== undefined
              ? getValues("CD_Corporation_Name")
              : getValues("X_Name_of_Entity") !== undefined
                ? getValues("X_Name_of_Entity")
                : globalCompany
                  ? globalCompany.companyDetails
                    ? globalCompany.companyDetails.companyName
                    : "Order"
                  : "Order",
        addOnService: addOnData,
        billingAddress: bilingAddress,
        shipingAddress: shippingAddress,
        keyPersonalStrapiArr: keyPersonnelStrapiArray,
        WhatAreChanging: whatAreChanging,
        formCount: formCounts,
        adminCompany: adminCompany,
        step: currentStep,
        totalStep: totalStep,
einAddress:einLocation

      };
      // if (!areIdentical && serviceDataTemp.length > 0 && isPaymentDone === null) {

      //     if (editPayloadData) {
      //         const temp = editPayloadData.orderDetails
      //         temp["orderLineItems"] = upadatedProduct
      //         setIsTabRefresh(true);
      //         return OrderServices.deleteOrderLineItemById(temp.id)
      //             .then((res) => {
      //                 console.log(res)
      //                 // Save order data and handle error
      //                 return OrderServices.saveAllOrder(temp)
      //                     .then((res) => {
      //
      //                         setPaymentOrderData(res.data);
      //                         setFormData(res.data.orderDetails);
      //                         setUpdatedOrderLineItems(res.data.orderLineItem);
      //                         setIsTabRefresh(false);

      //                     })
      //                     .catch((err) => {
      //
      //                         setIsTabRefresh(false);
      //                         if (toast.current) {
      //                             toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
      //                         }
      //                         return false; // Prevent navigation on error
      //                     })
      //             })
      //             .catch((err) => {
      //                 console.log(err)
      //                 setIsTabRefresh(false);
      //             })
      //     }
      // }
      // else {
      if (editPayloadData?.orderDetails?.statusId !== 7) {
        const temp = editPayloadData.orderDetails;
        temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
        temp["orderLineItems"] = updatedOrderLineItems;
        temp["billingAddress"] = bilingAddress
          ? bilingAddress?.addressMasterId?.id
          : null;
        temp["shippingAddress"] = shippingAddress
          ? shippingAddress?.addressMasterId?.id
          : null;
        temp["stateId"] = state ? state.id : null;
        temp["countyId"] = county ? county.id : null;
        temp["keyPersonalId"] = keyPersonalArr;
        temp["orderUpdateNotes"] = "Order data updated";
        temp["orderAmount"] = parseFloat(calculateTotalAmount());
        temp["companyId"] = adminCompany
          ? adminCompany.id
          : globalCompany
            ? globalCompany.companyDetails
              ? globalCompany.companyDetails.id
              : null
            : null;
        temp["orderUpdatedBy"] = loginUserName;
        temp["orderTitle"] =
          getValues("CD_LLC_Name") !== undefined
            ? getValues("CD_LLC_Name")
            : getValues("CD_Corporation_Name") !== undefined
              ? getValues("CD_Corporation_Name")
              : getValues("X_Name_of_Entity") !== undefined
                ? getValues("X_Name_of_Entity")
                : globalCompany
                  ? globalCompany.companyDetails
                    ? globalCompany.companyDetails.companyName
                    : "Order"
                  : "Order";

        setIsTabRefresh(true);

        return OrderServices.orderUpdate(temp)
          .then((res) => {
            setPaymentOrderData(res.data);
            setFormData(res.data.orderDetails);
            setUpdatedOrderLineItems(res.data.orderLineItem);
            setIsTabRefresh(false);
          })
          .catch((err) => {
            setIsTabRefresh(false);
            // if (toast.current) {
            //   toast.current.show({
            //     severity: "warn",
            //     summary: "Oops",
            //     detail: "Something went wrong",
            //     life: 2800,
            //   });
            // }
            showToast("error", `Something went wrong`);

            return false; // Prevent navigation on error
          });
      }
      // }
      document.getElementById("nextBtn").addEventListener("click", function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Smooth scroll
        });
      });
    } else {
      console.log("in else");

      setIsSecondDraft(true);
      if (selectedCheckBoxData.length > 0) {
        var x = document.getElementsByClassName("tab");

        // Validate the form
        if (x.length > 0) {
          if (n === 1 && !validateForm()) return false; // Stop if form validation fails

          // Hide current tab
          if (x[currentTab] !== undefined) {
            x[currentTab].style.display = "none";
          }
          // Check if the user is moving to the next page
          if (n === 1) {
            if (currentStep < inputFormData.length + 5) {
              setCurrentStep(currentStep + 1);
            }
          }

          // Check if the user is moving to the previous page
          if (n === -1) {
            if (currentStep > 1) {
              setCurrentStep(currentStep - 1);
            }
          }
          // Move to the new tab
          const newTab = currentTab + n;
          setCurrentTab(newTab);
          showTab(newTab);

          if (mainModel && n === 1) {
            // Key personnel and other processing
            const keyPersonalArr = [];
            if (incorporator?.id) keyPersonalArr.push(incorporator.id);
            if (officer?.id) keyPersonalArr.push(officer.id);
            if (director?.id) keyPersonalArr.push(director.id);
            if (organizerData?.id) keyPersonalArr.push(organizerData.id);
            if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
            if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);
            if (president?.id) keyPersonalArr.push(president.id);
            if (treasurer?.id) keyPersonalArr.push(treasurer.id);
            if (secretary?.id) keyPersonalArr.push(secretary.id);

            // const keyPersonalStrapiArr = [];
            // if (incorporator?.id) keyPersonalStrapiArr.push({ "data": incorporator, "type": "INCORPORATOR" });
            // if (officer?.id) keyPersonalStrapiArr.push({ "data": officer, "type": "OFFICER" });
            // if (director?.id) keyPersonalStrapiArr.push({ "data": director, "type": "DIRECTOR" });
            // if (organizerData?.id) keyPersonalStrapiArr.push({ "data": organizerData, "type": "ORGANIZER" });
            // if (bilingAddress?.id) keyPersonalStrapiArr.push({ "data": bilingAddress, "type": "BILLING" });

            // if (allDebtorData.length > 0) {
            //     const allDebtor = allDebtorData.map((debtor, index) => debtor[`debtor_${index}`]).filter((debtor) => debtor?.id);
            //     if (allDebtor.length > 0) {
            //         keyPersonalStrapiArr.push({
            //             data: allDebtor,
            //             type: "DEBTOR",
            //         });
            //     }
            // }

            // if (allAdditionalDebtors.length > 0) {
            //     const allAdditionalDebtor = allAdditionalDebtors.map((debtor, index) => debtor[`additionalDebtor_${index}`]).filter((debtor) => debtor?.id);
            //     if (allAdditionalDebtor.length > 0) {
            //         keyPersonalStrapiArr.push({
            //             data: allAdditionalDebtor,
            //             type: "ADDITIONAL DEBTOR",
            //         });
            //     }
            // }

            // if (allSecuredParties.length > 0) {
            //     const allSecuredPartie = allSecuredParties
            //         .map((party, index) => party[`securedParty1_${index}`])
            //         .filter((party) => party?.id);
            //     if (allSecuredPartie.length > 0) {
            //         keyPersonalStrapiArr.push({
            //             data: allSecuredPartie,
            //             type: "SECURED PARTY",
            //         });
            //     }
            // }

            // if (allAdditionalSecuredParties.length > 0) {
            //     const allAdditionalSecuredPartie = allAdditionalSecuredParties
            //         .map((party, index) => party[`securedParty2_${index}`])
            //         .filter((party) => party?.id);
            //     if (allAdditionalSecuredPartie.length > 0) {
            //         keyPersonalStrapiArr.push({
            //             data: allAdditionalSecuredPartie,
            //             type: "ADDITIONAL SECURED PARTY",
            //         });
            //     }
            // }

            // if (allBeneficialData.length > 0) {
            //     const allBeneficial = allBeneficialData
            //         .map((party, index) => party[`beneficial_${index}`])
            //         .filter((party) => party?.id);
            //     if (allBeneficial.length > 0) {
            //         keyPersonalStrapiArr.push({
            //             data: allBeneficial,
            //             type: "BENEFICIAL OWNER",
            //         });
            //     }
            // }

            // if (allCompanyApplicantData.length > 0) {
            //     const allCompanyApplicant = allCompanyApplicantData
            //         .map((party, index) => party[`company_applicant_${index}`])
            //         .filter((party) => party?.id);
            //     if (allCompanyApplicant.length > 0) {
            //         keyPersonalStrapiArr.push({
            //             data: allCompanyApplicant,
            //             type: "COMPANY APPLICANT",
            //         });
            //     }
            // }

            updateOrAddSingleData(incorporator, "INCORPORATOR");
            updateOrAddSingleData(officer, "OFFICER");
            updateOrAddSingleData(director, "DIRECTOR");
            updateOrAddSingleData(organizerData, "ORGANIZER");
            updateOrAddSingleData(bilingAddress, "BILLING");
            updateOrAddSingleData(assigneeData, "ASSIGNEE");

            const typeMappings = [
              {
                data: allBeneficialData,
                key: "beneficial",
                type: "BENEFICIAL OWNER",
              },
              {
                data: allCompanyApplicantData,
                key: "company_applicant",
                type: "COMPANY APPLICANT",
              },
              { data: allDebtorData, key: "debtor", type: "DEBTOR" },
              {
                data: allAdditionalDebtors,
                key: "additionalDebtor",
                type: "ADDITIONAL DEBTOR",
              },
              {
                data: allSecuredParties,
                key: "securedParty1",
                type: "SECURED PARTY",
              },
              {
                data: allAdditionalSecuredParties,
                key: "securedParty2",
                type: "ADDITIONAL SECURED PARTY",
              },
            ];

            typeMappings.forEach(({ data, key, type }) => {
              const filteredData = data.map(
                (item, index) => item[`${key}_${index}`]
              );
              // .filter((item) => item?.id);
              updateOrAddData(filteredData, type);
            });
            let d = new Date();
            const futureDate = new Date(d);
            futureDate.setDate(d.getDate() + 7);

            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();

            let day1 = futureDate.getDate();
            let month1 = futureDate.getMonth();
            let year1 = futureDate.getFullYear();

            if (day < 10) day = "0" + day;
            if (month < 10) month = `0${month}`;
            let newDate = year + "-" + month + "-" + day;

            if (day1 < 10) day1 = "0" + day1;
            if (month < 10) month1 = `0${month1}`;
            let newDate1 = year1 + "-" + month1 + "-" + day1;

            const upadatedProduct = selectedCheckBoxData
              .map((val) => {
                if (val?.product?.isDefault) {
                  console.log(allDebtorData.length, "debtor length");
                  console.log(noOfBundle, "No of bundle");
                  if (allDebtorData.length > noOfBundle && noOfBundle !== 0) {
                    return {
                      productListing: val.product,
                      quantity: allDebtorData.length - noOfBundle,
                      unitPrice: val.product.unitPrice,
                    };
                  }
                  return null;
                }

                return {
                  productListing: val.product,
                  quantity: 1,
                  unitPrice: val.product.unitPrice,
                };
              })
              .filter(Boolean);

            const modelDatas = processFormData(allModels);

            const finalData1 = buildFinalData(
              mainModel.schema.attributes,
              modelDatas
            );

            const jsonAutomation = {
              EntityType: enityName,
              State: state,
              County: county,
              Header: orderHeader,
              Payload: finalData1,
              legalName:
                getValues("CD_LLC_Name") !== undefined
                  ? getValues("CD_LLC_Name")
                  : getValues("CD_Corporation_Name") !== undefined
                    ? getValues("CD_Corporation_Name")
                    : getValues("X_Name_of_Entity") !== undefined
                      ? getValues("X_Name_of_Entity")
                      : globalCompany
                        ? globalCompany.companyDetails
                          ? globalCompany.companyDetails.companyName
                          : "Order"
                        : "Order",
              addOnService: addOnData,
              billingAddress: bilingAddress,
              shipingAddress: shippingAddress,
              formCount: formCounts,
              keyPersonalStrapiArr: keyPersonnelStrapiArray,
              WhatAreChanging: whatAreChanging,
              adminCompany: adminCompany,
              step: currentStep,
              totalStep: totalStep,
              einAddress:einLocation
            };

            const orderData = {
              contactId: contactId,
              companyId: adminCompany
                ? adminCompany.id
                : globalCompany
                  ? globalCompany.companyDetails
                    ? globalCompany.companyDetails.id
                    : null
                  : null,
              orderTitle:
                getValues("CD_LLC_Name") !== undefined
                  ? getValues("CD_LLC_Name")
                  : getValues("CD_Corporation_Name") !== undefined
                    ? getValues("CD_Corporation_Name")
                    : getValues("X_Name_of_Entity") !== undefined
                      ? getValues("X_Name_of_Entity")
                      : globalCompany
                        ? globalCompany.companyDetails
                          ? globalCompany.companyDetails.companyName
                          : "Order"
                        : "Order",
              orderId: null,
              billingAddress: bilingAddress
                ? bilingAddress.addressMasterId.id
                : null,
              statusId: 7,
              orderAmount: parseFloat(calculateTotalAmount()),
              orderDate: newDate,
              // "tentativeFulfillmentDate": newDate1,
              actualCompletionDate: null,
              strapiOrderFormJson: JSON.stringify(jsonAutomation),
              strapiOrderId: null,
              strapiOrderType: "Standard",
              orderTypeId: 1,
              stateId: state ? state.id : null,
              county: county ? county.id : null,
              transaction_id: null,
              entityTypeId: enityName ? enityName.id : null,
              isPayLater: paymentMethod === "Pay later" ? true : false,
              domicileStateId: state ? state.id : null,
              hubspotDealId: null,
              orderUpdateNotes: "Draft order created...",
              documentsId: null,
              orderUpdatedBy: loginUserName,
              keyPersonalId: keyPersonalArr,
              orderLineItems: upadatedProduct,
            };

            const saveOrder = () => {
              if (formData) {
                const areIdentical = deepCompareObjects(
                  serviceDataTemp,
                  selectedCheckBoxData
                );

                if (
                  (!areIdentical && serviceDataTemp.length > 0) ||
                  (allDebtorData.length > noOfBundle && noOfBundle !== 0)
                ) {
                  const temp = formData;
                  temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
                  temp["orderLineItems"] = upadatedProduct;
                  temp["billingAddress"] = bilingAddress
                    ? bilingAddress.addressMasterId.id
                    : null;
                  temp["shippingAddress"] = shippingAddress
                    ? shippingAddress.addressMasterId.id
                    : null;
                  temp["stateId"] = state ? state.id : null;
                  temp["countyId"] = county ? county.id : null;
                  temp["keyPersonalId"] = keyPersonalArr;
                  temp["orderAmount"] = parseFloat(calculateTotalAmount());
                  temp["orderUpdateNotes"] = "Draft order created";
                  temp["orderUpdatedBy"] = loginUserName;
                  temp["companyId"] = adminCompany
                    ? adminCompany.id
                    : globalCompany
                      ? globalCompany.companyDetails
                        ? globalCompany.companyDetails.id
                        : null
                      : null;
                  temp["orderTitle"] =
                    getValues("CD_LLC_Name") !== undefined
                      ? getValues("CD_LLC_Name")
                      : getValues("CD_Corporation_Name") !== undefined
                        ? getValues("CD_Corporation_Name")
                        : getValues("X_Name_of_Entity") !== undefined
                          ? getValues("X_Name_of_Entity")
                          : globalCompany
                            ? globalCompany.companyDetails
                              ? globalCompany.companyDetails.companyName
                              : "Order"
                            : "Order";

                  // Save order data and handle error
                  setIsTabRefresh(true);
                  return OrderServices.deleteOrderLineItemById(formData.id)
                    .then((res) => {
                      // Save order data and handle error
                      return OrderServices.saveAllOrder(temp)
                        .then((res) => {
                          setPaymentOrderData(res.data);
                          setFormData(res.data.orderDetails);
                          setUpdatedOrderLineItems(res.data.orderLineItem);
                          setIsTabRefresh(false);
                        })
                        .catch((err) => {
                          setIsTabRefresh(false);
                          //   if (toast.current) {
                          //     toast.current.show({
                          //       severity: "warn",
                          //       summary: "Oops",
                          //       detail: "Something went wrong",
                          //       life: 2800,
                          //     });
                          //   }
                          showToast("error", `Something went wrong`);

                          return false; // Prevent navigation on error
                        });
                    })
                    .catch((err) => {
                      setIsTabRefresh(false);
                    });
                } else {
                  const temp = formData;
                  temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
                  temp["orderLineItems"] = updatedOrderLineItems;
                  temp["billingAddress"] = bilingAddress
                    ? bilingAddress.addressMasterId.id
                    : null;
                  temp["shippingAddress"] = shippingAddress
                    ? shippingAddress.addressMasterId.id
                    : null;
                  temp["stateId"] = state ? state.id : null;
                  temp["countyId"] = county ? county.id : null;
                  temp["keyPersonalId"] = keyPersonalArr;
                  temp["orderUpdateNotes"] = "Draft order created";
                  temp["orderUpdatedBy"] = loginUserName;
                  temp["orderAmount"] = parseFloat(calculateTotalAmount());
                  temp["companyId"] = adminCompany
                    ? adminCompany.id
                    : globalCompany
                      ? globalCompany.companyDetails
                        ? globalCompany.companyDetails.id
                        : null
                      : null;
                  temp["orderTitle"] =
                    getValues("CD_LLC_Name") !== undefined
                      ? getValues("CD_LLC_Name")
                      : getValues("CD_Corporation_Name") !== undefined
                        ? getValues("CD_Corporation_Name")
                        : getValues("X_Name_of_Entity") !== undefined
                          ? getValues("X_Name_of_Entity")
                          : globalCompany
                            ? globalCompany.companyDetails
                              ? globalCompany.companyDetails.companyName
                              : "Order"
                            : "Order";

                  // Save order data and handle error
                  setIsTabRefresh(true);
                  return OrderServices.saveAllOrder(temp)
                    .then((res) => {
                      setPaymentOrderData(res.data);
                      setFormData(res.data.orderDetails);
                      setUpdatedOrderLineItems(res.data.orderLineItem);
                      setIsTabRefresh(false);
                    })
                    .catch((err) => {
                      setIsTabRefresh(false);
                      //   if (toast.current) {
                      //     toast.current.show({
                      //       severity: "warn",
                      //       summary: "Oops",
                      //       detail: "Something went wrong",
                      //       life: 2800,
                      //     });
                      //   }
                      showToast("error", `Something went wrong`);

                      return false; // Prevent navigation on error
                    });
                }
              } else {
                setIsTabRefresh(true);
                return OrderServices.saveAllOrder(orderData)
                  .then((res) => {
                    setPaymentOrderData(res.data);
                    setFormData(res.data.orderDetails);
                    setUpdatedOrderLineItems(res.data.orderLineItem);
                    setIsTabRefresh(false);

                    if (res.status === 201) {
                      //   toast.current.show({
                      //     severity: "success",
                      //     summary: "Success",
                      //     detail: `Your order is saved in draft with temporary order id ${res.data.orderDetails.id}`,
                      //     life: 3000,
                      //   });
                      showToast(
                        "success",
                        `Your order is saved in draft with temporary order id ${res.data.orderDetails.id}`
                      );
                    }
                  })
                  .catch((err) => {
                    setIsTabRefresh(false);
                    // if (toast.current) {
                    //   toast.current.show({
                    //     severity: "warn",
                    //     summary: "Oops",
                    //     detail: "Something went wrong",
                    //     life: 2800,
                    //   });
                    // }
                    showToast("error", `Something went wrong`);

                    return false; // Prevent navigation on error
                  });
              }
            };

            saveOrder().then((success) => {
              if (success !== false) {
                // Only proceed to next page if save was successful
                setCurrentTab(newTab);
                showTab(newTab);
              }
            });
          }
        }
      } else {
        // if (toast.current !== undefined) {
        //   toast.current.show({
        //     severity: "warn",
        //     summary: "Warning",
        //     detail: "Please select at least one product",
        //     life: 3000,
        //   });
        // }
        showToast("error", `Please select at least one product`);
      }
    }
  };

  const stepLabels = Array.from(
    { length: inputFormData.length + 5 },
    (_, i) => `Step ${i + 1} of ${inputFormData.length + 5}`
  );

  function stripSuffix(inputName) {
    return inputName.replace(/-\d+$/, "");
  }

  const getActiveStepValue = () => {
    const activeStep = document.querySelector(
      ".step.active .wizard-step-content"
    );
    return activeStep ? activeStep.textContent : null;
  };

  function validateForm() {
    const x = document.getElementsByClassName("tab");
    let valid = true;

    const dropdownArray = [
      "Organizer",
      "Principal_Address",
      "Incorporator",
      "Director",
      "Officer",
      "Filer",
      "President",
      "Treasurer",
      "Secretary",
      "RA_Billing_Information",
      "RA_Mailing_Information",
      "Member_Or_Manager_Details-1",
    ];

    // const keys = ["Member_Or_Manager_Details", "ucc_Debtor_Details",
    //   "Company_Applicant_Information",
    //   "ucc_Secured_Party_Details",
    //   "Beneficial_Owner_Information",];
    const keys = [];
    const multiDropdownArray = [];

    keys.forEach((key) => {
      if (formCounts && formCounts[key] && formCounts[key] > 0) {
        for (let i = 1; i <= formCounts[key]; i++) {
          multiDropdownArray.push(`${key}-${i}`);
        }
      } else {
        multiDropdownArray.push(`${key}-1`);
      }
    });

    const activeStepValue = getActiveStepValue();

    if (
      activeStepValue &&
      activeStepValue === "Beneficial Owner Information" &&
      allBeneficialData.length < minValue
    ) {
      showToast("error", `Select at least ${minValue} beneficial owner`);
      valid = false;
    }
    if (
      activeStepValue &&
      activeStepValue === "Beneficial Owner Information" &&
      allBeneficialData.length > maxValue
    ) {
      showToast("error", `You can select maximum ${maxValue} beneficial owner`);
      valid = false;
    }
    if (
      activeStepValue &&
      activeStepValue === "Company Applicant Information" &&
      allCompanyApplicantData.length === 0
    ) {
      showToast("error", `Select at least one company applicant`);
      valid = false;
    }
    if (
      activeStepValue &&
      activeStepValue === "Company Applicant Information" &&
      allCompanyApplicantData.length > 2
    ) {
      showToast("error", `You can select maximum 2 company applicant`);
      valid = false;
    }

    if (
      activeStepValue &&
      activeStepValue === "UCC Debtor Details" &&
      allDebtorData.length === 0
    ) {
      showToast("error", `Select at least one debtor`);
      valid = false;
    }

    if (
      activeStepValue &&
      activeStepValue === "UCC Secured Party Details" &&
      allSecuredParties.length === 0
    ) {
      showToast("error", `Select at least one secured parties`);
      valid = false;
    }

    if (
      activeStepValue &&
      activeStepValue === "UCC Amend What Are We Amending" &&
      getValues("uccAmend_What_are_we_amending") === "ADDITIONAL DEBTOR" &&
      allAdditionalDebtors.length === 0
    ) {
      showToast("error", `Select at least one additional debtor`);
      valid = false;
    }

    if (
      activeStepValue &&
      activeStepValue === "UCC Amend What Are We Amending" &&
      getValues("uccAmend_What_are_we_amending") === "ADDITIONAL SECURED" &&
      allAdditionalSecuredParties.length === 0
    ) {
      showToast("error", `Select at least one additional secured parties`);
      valid = false;
    }

    if (x.length > 0 && x[currentTab] !== undefined) {
      const y = Array.from(x[currentTab].getElementsByTagName("input")).filter(
        (input) => input.type !== "file"
      );
      const dropdowns = x[currentTab].getElementsByClassName("p-dropdown");
      const fileInputs = x[currentTab].querySelectorAll('input[type="file"]');

      // A loop that checks every input field in the current tab:
      for (let i = 0; i < y.length; i++) {
        const input = y[i];
        const inputName = stripSuffix(input.name);

        const column = allModels.find((field) => {
          const temp = field.schema.attributes;

          return Object.keys(temp).includes(inputName);
        });

        if (column && column !== undefined) {
          const fieldData = column.schema.attributes[inputName];

          const errorMessageTag = document.getElementById(
            `${input.name}--error`
          );

          // Reset previous error message
          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }

          // Check if the input field is empty and required:

          if (fieldData.required && input.value.trim() === "") {
            valid = false;
            if (errorMessageTag) {
              errorMessageTag.textContent = `${convertName(
                inputName
              )} required`;
            }
          }
          if (
            input.name === "CD_LLC_Name" ||
            input.name === "CD_Corporation_Name"
          ) {
            if (enityName && state) {
              const entityRegex =
                validationConfig[enityName.entityShortName]?.[
                state.stateFullDesc
                ];

              if (entityRegex && !entityRegex.test(input.value.trim())) {
                valid = false;
                if (errorMessageTag) {
                  const patternText = entityRegex.source
                    .split("|")
                    .join(", ")
                    .replace(/[()\\$]/g, ""); // Remove special characters: (), \, ., $

                  errorMessageTag.textContent = `Enter a valid ${convertName(
                    inputName
                  )} ending with ${patternText}`;
                }
              }
            }
          }

          // SSN validation
          if (
            input.name === "EIN_Social_security_number_and_ITI_number" &&
            getValues("EIN_Social_security_number_and_ITI_number")
          ) {
            // Remove non-digit characters
            const ssnValue = input.value.replace(/\D/g, "");

            if (ssnValue.length !== 9) {
              valid = false;

              if (errorMessageTag) {
                errorMessageTag.textContent = `${convertName(
                  inputName
                )} must be exactly 9 digits`;
              }
            } else {
              valid = true;

              // If valid, clear the error message
              if (errorMessageTag) {
                errorMessageTag.textContent = "";
              }
            }
          }

          if (
            (getValues("CD_Alternate_LLC_Name") &&
              input.name === "CD_Alternate_LLC_Name") ||
            (getValues("CD_Alternate_Corporation_Name") &&
              "CD_Alternate_Corporation_Name")
          ) {
            if (enityName && state) {
              const entityRegex =
                validationConfig[enityName.entityShortName]?.[
                state.stateFullDesc
                ];

              if (entityRegex && !entityRegex.test(input.value.trim())) {
                valid = false;
                if (errorMessageTag) {
                  const patternText = entityRegex.source
                    .split("|")
                    .join(", ")
                    .replace(/[()\\$]/g, ""); // Remove special characters: (), \, ., $

                  errorMessageTag.textContent = `Enter a valid ${convertName(
                    inputName
                  )} ending with ${patternText}`;
                }
              }
            }
          }

          // Check regex validation if provided:
          if (fieldData.regex && input.value.trim() !== "") {
            const regex = new RegExp(fieldData.regex);
            if (!regex.test(input.value.trim())) {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `Enter Valid ${convertName(
                  inputName
                )} `;
              }
            }
          }
          if (input.type === "text" || input.type === "textarea") {
            if (fieldData.maxLength && input.value.trim() !== "") {
              if (input.value.length > fieldData.maxLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    inputName
                  )} should have a maximum length of ${fieldData.maxLength}`;
                }
              }
            }
            if (fieldData.minLength && input.value.trim() !== "") {
              if (input.value.length < fieldData.minLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    inputName
                  )} should have a minimum length of ${fieldData.minLength}`;
                }
              }
            }
          } else if (input.type === "number") {
            const value = parseInt(input.value, 10);

            if (fieldData.max && !isNaN(value) && value > fieldData.max) {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `${convertName(
                  inputName
                )} should be ${fieldData.max.length} digits`;
              }
            }
            if (fieldData.min && !isNaN(value) && value < fieldData.min) {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `${convertName(
                  inputName
                )} should be at least ${fieldData.min.length} digits`;
              }
            }
          }
        } else {
          if (inputFormData.length === 0) {
            valid = false;
          }
        }
      }

      for (let i = 0; i < dropdowns.length; i++) {
        const dropdown = dropdowns[i];
        const inputName = dropdown.id;
        const inputWithoutSuffix = stripSuffix(dropdown.id);
        const inputValue = dropdown
          .querySelector(".p-dropdown-label")
          .textContent.trim();

        if (inputName) {
          const matchedDropdown = dropdownArray.find((val) =>
            val.includes(inputName)
          );

          const errorMessageTag = document.getElementById(
            `${inputName}--error`
          );

          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }
          if (
            matchedDropdown &&
            matchedDropdown !== "RA_Mailing_Information" &&
            inputValue === "empty"
          ) {
            valid = false;
            if (errorMessageTag) {
              errorMessageTag.textContent = `${convertName(
                inputName
              )} required`;
            }
          }
          if (
            matchedDropdown &&
            matchedDropdown === "RA_Mailing_Information" &&
            !IsCheckedAgent1 &&
            inputValue === "empty"
          ) {
            valid = false;
            if (errorMessageTag) {
              errorMessageTag.textContent = `${convertName(
                inputName
              )} required`;
            }
          }
        }

        if (multiDropdownArray.some((val) => inputName.includes(val))) {
          const errorMessageTag = document.getElementById(
            `${inputName}--error`
          );

          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }
          if (inputValue === "empty") {
            valid = false;
            if (errorMessageTag) {
              errorMessageTag.textContent = `${toPascalCase(
                inputName
              )} required`;
            }
          }
        }

        if (inputName) {
          const column = allModels.find((field) => {
            const temp = field.schema.attributes;
            return Object.keys(temp).includes(inputWithoutSuffix);
          });

          if (column && column !== undefined) {
            const fieldData = column.schema.attributes[inputWithoutSuffix];
            const errorMessageTag = document.getElementById(
              `${inputName}--error`
            );

            // Reset previous error message
            if (errorMessageTag) {
              errorMessageTag.textContent = "";
            }

            // Check if the dropdown field is empty and required:
            if (fieldData.required && inputValue === "empty") {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `${convertName(
                  inputWithoutSuffix
                )} required`;
              }
            }

            // Check regex validation if provided:
            if (fieldData.regex && inputValue !== "") {
              const regex = new RegExp(fieldData.regex);
              if (!regex.test(inputValue)) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `Enter Valid ${convertName(
                    inputWithoutSuffix
                  )} `;
                }
              }
            }
            if (fieldData.maxLength && inputValue !== "") {
              if (inputValue.length > fieldData.maxLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    inputWithoutSuffix
                  )} Should be Max Length ${fieldData.maxLength} `;
                }
              }
            }
            if (fieldData.minLength && inputValue !== "") {
              if (inputValue.length < fieldData.minLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    inputWithoutSuffix
                  )} Should be Min Length ${fieldData.minLength} `;
                }
              }
            }
          } else {
            if (inputFormData.length === 0) {
              valid = false;
            }
          }
        }
      }

      // A loop that checks each file input field in the current tab:
      for (let i = 0; i < fileInputs.length; i++) {
        const fileInput = fileInputs[i];
        const fileName = stripSuffix(fileInput.name);

        const column = allModels.find((field) => {
          const temp = field.schema.attributes;
          return Object.keys(temp).includes(fileName);
        });

        if (column && column !== undefined) {
          const fieldData = column.schema.attributes[fileName];
          const errorMessageTag = document.getElementById(
            `${fileInput.name}--error`
          );

          // Reset previous error message
          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }

          // Check if the file input is required and no file is selected:
          if (
            fieldData.required &&
            fileInput.files.length === 0 &&
            getValues(fileInput.name) === undefined
          ) {
            valid = false;
            if (errorMessageTag) {
              errorMessageTag.textContent = `${convertName(fileName)} required`;
            }
          }
        }
      }

      // If the valid status is true, mark the step as finished and valid:
      if (valid) {
        const stepElements = document.getElementsByClassName("step");

        if (stepElements[currentTab] !== undefined) {
          stepElements[currentTab].className += " finish";
        }
      }
    }

    return valid; // return the valid status
  }

  function fixStepIndicator(n) {
    var i,
      x = document.getElementsByClassName("step");
    for (i = 0; i < x.length; i++) {
      if (x[i].classList.contains("finish") || x[i].classList.contains("active")) {
        x[i].classList.remove("error");
      }
      x[i].className = x[i].className
        .replace(" active", "")
           }

    const hasError =
      document.querySelectorAll(`[data-step="${n}"] .error-message`).length > 0;

    if (x[n] !== undefined) {
      x[n].className += " active";
    }

    if (hasError) {
      x[n].className += " error";
    }
  }

  const handleComapnyName = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Only check availability if the input is not empty
    if (value) {
      checkAvailability(value);
    } else {
      setIsAvailable(null);
    }
  };

  const handleLLCMember = (e) => {
    setLlc(e.target.value);
  };

  function processSchema(schema, dataMap, depth = 0, maxDepth = 10) {
    if (depth > maxDepth) {
      console.warn("Maximum recursion depth exceeded");
      return {};
    }

    const result = {};

    if (schema !== undefined) {
      Object.keys(schema.attributes).forEach((attrKey) => {
        const attr = schema.attributes[attrKey];

        if (attr.apiId !== undefined && mainModel) {
          const mainSchema = mainModel.schema.attributes;
          const componentKeys = Object.keys(mainSchema).map((k) => {
            const attributes = mainSchema[k]?.schema?.attributes;
            if (attributes && Object.keys(attributes).length > 0) {
              return Object.keys(attributes).filter(
                (key) => attributes[key]?.uid === attr.uid
              );
            }
            return [];
          });
          const componentKey = componentKeys.flat();
          const componentData = dataMap.get(attr.uid);

          if (componentData) {
            if (mainSchema[componentKey] && mainSchema[componentKey].schema) {
              result[attrKey] = processSchema(
                mainSchema[componentKey].schema,
                dataMap,
                depth + 1,
                maxDepth
              );
              Object.assign(result[attrKey], componentData);
            } else {
              result[attrKey] = componentData;
            }
          }
        } else {
          const parentData = dataMap.get(attrKey);
          if (parentData) {
            result[attrKey] = parentData;
          }
        }
      });
    }

    return result;
  }

  function buildFinalData(temps, tempsData) {
    const finalData = {};

    // Create a map for efficient data lookup
    const dataMap = new Map(tempsData.map((d) => [d.model, d.data]));

    if (mainModel) {
      Object.keys(temps).forEach((key) => {
        const schema = temps[key].schema;
        const data = dataMap.get(temps[key].uid);

        // Process the schema directly without memoization
        finalData[key] = processSchema(schema, dataMap);

        if (data) {
          if (Array.isArray(data)) {
            finalData[key] = [...data]; // Copy array
          } else {
            Object.assign(finalData[key], data); // Merge object data
          }
        }
      });
    }

    return finalData;
  }

  useEffect(() => {
    if (mainModel && modelData1.length > 0) {
      if (mainModel.schema !== undefined) {
        if (mainModel.schema.attributes !== undefined) {
          const finalData1 = buildFinalData(
            mainModel.schema.attributes,
            modelData1
          );
          setPriviewData(finalData1);
        }
      }
    }
  }, [mainModel, modelData1]);

  const getState = (id) => {
    const temp = allState.find((val) => val.id === id);
    if (temp !== undefined) {
      return temp.stateFullDesc;
    } else {
      return null;
    }
  };

  const stripePay = async (item, orderData) => {
    console.log(orderData, "orderData");
    try {
      const stripe = await stripePromise;

      const data = {
        product: item,
      };

      const productPaydata = item.map((val) => {
        return {
          name: val.productListing.displayName,
          unitAmount: Math.round(val.productListing.unitPrice * 100),
          quantity: 1,
        };
      });

      const paytemp = {
        // orderId: orderData?.orderDetails?.orderId ? (orderData?.orderDetails?.orderId).toString() : "",
        orderId: orderData?.orderDetails?.orderId.toString(),
        lineItems: productPaydata,
        companyName: orderData?.billingAddress?.companyName,
        entityName: orderData?.entityTypeId?.entityFullDesc,
        emailId: orderData?.companyId?.accountManagerId?.email,
      };

      // const response = await axios.post(`$/checkout`, data);
      setIsLoading(true);
      setIsTabRefresh(true);
      OrderServices.paymentOrder(paytemp)
        .then((response) => {
          localStorage.setItem("sessionId", response.data.sessionId);
          // setPaySessionId(res.data.sessionId)

          // const sessionId = response.data.id;
          // console.log(sessionId)

          const localData = {
            data: orderData,
            res: response.data,
            orderLineItem: item,
            updatedBy: loginUserName,
          };
          localStorage.setItem("payment", JSON.stringify(localData));
          setIsLoading(false);
          setIsTabRefresh(false);
          setCloseProgresbar3(false);
          localStorage.setItem("checkoutData", JSON.stringify(response.data));
          setTimeout(() => {
            window.location.href = response.data.checkoutUrl;
          }, 100); // Small timeout to ensure data is saved
          // const { error } = await stripe.redirectToCheckout({
          //     sessionId: sessionId
          // });
          // if (error) {
          //     console.error("Stripe Checkout Error:", error);
          // }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsTabRefresh(false);
        });
    } catch (err) {
      console.error("Error during checkout process:", err);
    }
  };

  const [hasLLC, setHasLLC] = useState(false);

  useEffect(() => {
    if (selectedCheckBoxData.length > 0) {
      console.log(enityName, "hasLLC");
      const containsLLC = enityName.entityShortName === "LLC";
      setHasLLC(containsLLC);
    } else {
      setHasLLC(false);
    }
  }, [selectedCheckBoxData]);

  console.log(hasLLC, "hasLLC");

  // Function to handle confirmation
  const handleConfirm = async () => {
    if (mainModel) {
      const jsonAutomation = {
        EntityType: enityName,
        State: state,
        Payload: formData,
        addOnService: null,
        legalName:
          getValues("CD_LLC_Name") !== undefined
            ? getValues("CD_LLC_Name")
            : getValues("CD_Corporation_Name") !== undefined
              ? getValues("CD_Corporation_Name")
              : null,
        billingAddress: bilingAddress,
        shipingAddress: shippingAddress,
        formCount: formCounts,
        adminCompany: adminCompany,
        WhatAreChanging: whatAreChanging,
        adminCompany: adminCompany,
        einAddress:einLocation
      };

      const keyPersonalArr = [];

      if (incorporator?.id) keyPersonalArr.push(incorporator.id);
      if (officer?.id) keyPersonalArr.push(officer.id);
      if (director?.id) keyPersonalArr.push(director.id);
      if (organizerData?.id) keyPersonalArr.push(organizerData.id);
      if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
      if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);
      if (registeredAgent?.id && IsCheckedAgent && orderTypeId === 24) {
        // keyPersonalArr.push({ data: registeredAgent, type: "REGISTER_AGENT" });
        updateOrAddSingleData(registeredAgent, "REGISTER_AGENT");
      }

      let d = new Date();
      const futureDate = new Date(d);
      futureDate.setDate(d.getDate() + 7);

      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();

      let day1 = futureDate.getDate();
      let month1 = futureDate.getMonth() + 1;
      let year1 = futureDate.getFullYear();

      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = `0${month}`;
      } else {
        month = month;
      }
      let newDate = year + "-" + month + "-" + day;

      if (day1 < 10) {
        day1 = "0" + day1;
      }
      if (month < 10) {
        month1 = `0${month1}`;
      } else {
        month1 = month1;
      }
      let newDate1 = year1 + "-" + month1 + "-" + day1;

      const upadatedProduct = selectedCheckBoxData.map((val) => {
        return {
          productListing: val.product,
          quantity: 1,
          unitPrice: calculateTotalAmount(),
        };
      });

      setIsTabRefresh(true);
      if (paymentMethod === "Pay later") {
        if (formData) {
          if (orderTypeId !== 18) {
            if (paymentMethod === "Pay later") {
              if (editPayloadData) {
                const temp = editPayloadData.orderDetails;
                temp["statusId"] = 14;
                temp["orderLineItems"] = updatedOrderLineItems;
                temp["orderUpdateNotes"] = "Order created successfully";
                temp["orderUpdatedBy"] = loginUserName;
                temp["shippingAddress"] = shippingAddress
                  ? shippingAddress.addressMasterId.id
                  : bilingAddress
                    ? bilingAddress.addressMasterId.id
                    : null;
                temp["orderAmount"] = parseFloat(calculateTotalAmount());

                temp["stateId"] = state ? state.id : null;
                temp["billingAddress"] = bilingAddress
                  ? bilingAddress.addressMasterId.id
                  : null;
                temp["countyId"] = county ? county.id : null;
                temp["keyPersonalId"] = keyPersonalArr;
                temp["orderDate"] = newDate;
                temp["tentativeFulfillmentDate"] = futureDate;
                temp["strapiOrderId"] = 1;
                temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
                OrderServices.saveAllOrder(temp)
                  .then((res) => {
                    const tempNew = res.data.orderDetails;
                    tempNew["shippingAddress"] = shippingAddress
                      ? shippingAddress.addressMasterId.id
                      : bilingAddress
                        ? bilingAddress.addressMasterId.id
                        : null;
                    tempNew["orderLineItems"] = updatedOrderLineItems;
                    const invoiceData = {
                      orderId: tempNew,
                      invoiceNo: `INV ${res.data.orderDetails.orderId}`,
                      totalAmount: calculateTotalAmount(),
                      orderLineItems: updatedOrderLineItems,
                    };

                    // const sessionRes = a  OrderServices.searchSession(res?.data?.orderDetails?.orderId)

                    // if(sessionRes.status===208){
                    //   // show modal -> payment done->paymentalreadyDoneModal(true)
                    // }else{

                    // }

                    const temp = res.data.orderDetails;

                    temp["statusId"] = 1000;
                    temp["orderLineItems"] = updatedOrderLineItems;
                    temp["orderUpdateNotes"] = "Approval Pending";
                    temp["orderUpdatedBy"] = loginUserName;
                    temp["shippingAddress"] = shippingAddress
                      ? shippingAddress.addressMasterId.id
                      : bilingAddress
                        ? bilingAddress.addressMasterId.id
                        : null;
                    temp["stateId"] = state ? state.id : null;
                    temp["countyId"] = county ? county.id : null;
                    temp["strapiOrderId"] = 1;
                    temp["keyPersonalId"] = keyPersonalArr;
                    setIsTabRefresh(true);
                    OrderServices.saveAllOrder(temp)
                      .then((res) => {
                        setOrderContinue(false);
                        setIsTabRefresh(false);
                        history("/order");
                      })
                      .catch((err) => {
                        setCloseProgresbar2(false);
                        setCloseProgresbar3(false);
                        setCloseProgresbar1(true);
                        setIsTabRefresh(false);
                        // if (toast.current) {
                        //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                        // }
                      });
                    setIsTabRefresh(false);
                    setPaymentOrderData(res.data);
                    setFormData(res.data.orderId);
                    setCloseProgresbar2(true);
                  })
                  .catch((err) => {
                    setCloseProgresbar2(false);
                    setCloseProgresbar3(false);
                    setCloseProgresbar1(true);
                    setIsTabRefresh(false);
                    // if (toast.current) {
                    //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                    // }
                  });
              }
            }
          } else {
            if (paymentMethod === "Pay later") {
              if (editPayloadData) {
                const temp = editPayloadData.orderDetails;
                temp["statusId"] = 14;
                temp["orderLineItems"] = updatedOrderLineItems;
                temp["orderUpdateNotes"] = "Order created successfully";
                temp["orderUpdatedBy"] = loginUserName;
                temp["shippingAddress"] = shippingAddress
                  ? shippingAddress.addressMasterId.id
                  : bilingAddress
                    ? bilingAddress.addressMasterId.id
                    : null;
                temp["stateId"] = state ? state.id : null;
                temp["billingAddress"] = bilingAddress
                  ? bilingAddress.addressMasterId.id
                  : null;
                temp["countyId"] = county ? county.id : null;
                temp["keyPersonalId"] = keyPersonalArr;
                temp["orderDate"] = newDate;
                temp["tentativeFulfillmentDate"] = futureDate;
                temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
                OrderServices.saveAllOrder(temp)
                  .then((res) => {
                    const tempNew = res.data.orderDetails;
                    tempNew["shippingAddress"] = shippingAddress
                      ? shippingAddress.addressMasterId.id
                      : bilingAddress
                        ? bilingAddress.addressMasterId.id
                        : null;
                    tempNew["orderLineItems"] = updatedOrderLineItems;
                    const invoiceData = {
                      orderId: tempNew,
                      invoiceNo: `INV ${res.data.orderDetails.orderId}`,
                      totalAmount: calculateTotalAmount(),
                      orderLineItems: updatedOrderLineItems,
                    };

                    const temp = res.data.orderDetails;

                    temp["statusId"] = 1000;
                    temp["orderLineItems"] = updatedOrderLineItems;
                    temp["orderUpdateNotes"] = "Approval Pending";
                    temp["orderUpdatedBy"] = loginUserName;
                    temp["shippingAddress"] = shippingAddress
                      ? shippingAddress.addressMasterId.id
                      : bilingAddress
                        ? bilingAddress.addressMasterId.id
                        : null;
                    temp["stateId"] = state ? state.id : null;
                    temp["countyId"] = county ? county.id : null;
                    temp["strapiOrderId"] = 1;
                    temp["keyPersonalId"] = keyPersonalArr;
                    setIsTabRefresh(true);
                    OrderServices.saveAllOrder(temp)
                      .then((res) => {
                        setOrderContinue(false);
                        setIsTabRefresh(false);
                        history("/order");
                      })
                      .catch((err) => {
                        setCloseProgresbar2(false);
                        setCloseProgresbar3(false);
                        setCloseProgresbar1(true);
                        setIsTabRefresh(false);
                        // if (toast.current) {
                        //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                        // }
                      });

                    setIsTabRefresh(false);
                    setPaymentOrderData(res.data);
                    setFormData(res.data.orderId);
                    setCloseProgresbar2(true);
                  })
                  .catch((err) => {
                    setCloseProgresbar2(false);
                    setCloseProgresbar3(false);
                    setCloseProgresbar1(true);
                    setIsTabRefresh(false);
                    // if (toast.current) {
                    //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                    // }
                  });
              }
            } else {
              setCloseProgresbar3(true);
              setIsTabRefresh(false);
            }
          }
        }
      } else {
        if (editPayloadData) {
          const temp = formData;
          temp["statusId"] = 14;
          temp["orderLineItems"] = updatedOrderLineItems;
          temp["orderUpdateNotes"] = "Order created successfully.";
          temp["orderUpdatedBy"] = loginUserName;
          temp["shippingAddress"] = shippingAddress
            ? shippingAddress.addressMasterId.id
            : bilingAddress
              ? bilingAddress.addressMasterId.id
              : null;
          temp["stateId"] = state ? state.id : null;
          temp["billingAddress"] = bilingAddress
            ? bilingAddress.addressMasterId.id
            : null;
          temp["countyId"] = county ? county.id : null;
          temp["orderDate"] = newDate;
          temp["tentativeFulfillmentDate"] = futureDate;
          temp["strapiOrderId"] = 1;
          temp["keyPersonalId"] = keyPersonalArr;
          setIsTabRefresh(true);
          OrderServices.saveAllOrder(temp)
            .then((res) => {
              setOrderContinue(false);
              const orderPayData = res.data;
              const tempNew = res.data.orderDetails;
              tempNew["shippingAddress"] = shippingAddress
                ? shippingAddress.addressMasterId.id
                : bilingAddress
                  ? bilingAddress.addressMasterId.id
                  : null;
              tempNew["orderLineItems"] = updatedOrderLineItems;

              const invoiceData = {
                orderId: tempNew,
                invoiceNo: `INV ${res.data.orderDetails.orderId}`,
                totalAmount: calculateTotalAmount(),
                orderLineItems: updatedOrderLineItems,
              };

              const PaidOrderdata = {
                // id: res?.data?.id,
                orderId: tempNew,
                invoiceNo: `INV ${res.data.orderDetails.orderId}`,
                totalAmount: calculateTotalAmount(),

                statusId: res.data.statusId.id,
                // quickbooksId: res?.data?.quickbooksId,
                // hubspotId: jsonPayment.data.statusId.hubspotId,
                orderLineItems: updatedOrderLineItems,
                base64Data: "",
                invoiceUpdateNotes: "Payment Done",
                invoiceUpdatedBy: "null",
              };

              const temp = res.data.orderDetails;
              if (orderTypeId !== 13) {
                OrderServices.saveAllInvoice(PaidOrderdata)
                  .then((res) => {
                    localStorage.setItem("invoiceData", JSON.stringify(res));

                    // setCloseProgresbar2(false)
                    // setCloseProgresbar3(false)
                    // setCloseProgresbar(true)

                    const saveTransactionPayload = {
                      // bankName:"",
                      // checkNumber:null,
                      // transactionId:null,
                      // transactionDate:"",
                      // "PAY NOW"
                      transactionType: "CARD",
                      transactionStatus: 30,
                      amount: res.data?.totalAmount,
                      orderDetails: res.data?.orderId,
                      invoiceDetails: res?.data,
                    };
                    OrderServices.saveTransaction(saveTransactionPayload).then(
                      (res) => {
                        localStorage.setItem(
                          "transactiondata",
                          JSON.stringify(res.data)
                        );
                      }
                    );
                  })
                  .catch((err) => {
                    setCloseProgresbar2(false);
                    setCloseProgresbar3(false);
                    // setCloseProgresbar1(true)
                    setIsTabRefresh(false);
                    stripePay(updatedOrderLineItems, orderPayData);
                    // if (toast.current) {
                    //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                    // }
                  });
              }
              setIsTabRefresh(false);
              stripePay(updatedOrderLineItems, orderPayData);
            })
            .catch((err) => {
              setCloseProgresbar2(false);
              setCloseProgresbar3(false);
              // setCloseProgresbar1(true)
              setIsTabRefresh(false);
              // if (toast.current) {
              //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
              // }
            });
        }
      }
    }
  };

  const onSubmitNew = (data) => {
    console.log(data, "Data BIO");

    if (mainModel && bilingAddress) {
      const keyPersonalArr = [];
      if (incorporator?.id) keyPersonalArr.push(incorporator.id);
      if (officer?.id) keyPersonalArr.push(officer.id);
      if (director?.id) keyPersonalArr.push(director.id);
      if (filer?.id) keyPersonalArr.push(filer.id);
      if (organizerData?.id) keyPersonalArr.push(organizerData.id);
      if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
      if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);

      updateOrAddSingleData(incorporator, "INCORPORATOR");
      updateOrAddSingleData(officer, "OFFICER");
      updateOrAddSingleData(director, "DIRECTOR");
      updateOrAddSingleData(organizerData, "ORGANIZER");
      updateOrAddSingleData(bilingAddress, "BILLING");
      updateOrAddSingleData(assigneeData, "ASSIGNEE");
      updateOrAddSingleData(location, "PRINCIPAL_ADDRESS");

      const typeMappings = [
        {
          data: allBeneficialData,
          key: "beneficial",
          type: "BENEFICIAL OWNER",
        },
        {
          data: allCompanyApplicantData,
          key: "company_applicant",
          type: "COMPANY APPLICANT",
        },
        { data: allDebtorData, key: "debtor", type: "DEBTOR" },
        {
          data: allAdditionalDebtors,
          key: "additionalDebtor",
          type: "ADDITIONAL DEBTOR",
        },
        {
          data: allSecuredParties,
          key: "securedParty1",
          type: "SECURED PARTY",
        },
        {
          data: allAdditionalSecuredParties,
          key: "securedParty2",
          type: "ADDITIONAL SECURED PARTY",
        },
      ];

      typeMappings.forEach(({ data, key, type }) => {
        const filteredData = data.map((item, index) => item[`${key}_${index}`]);
        // .filter((item) => item?.id);
        updateOrAddData(filteredData, type);
      });

      const modelDatas = processFormData(allModels);
      const finalData1 = buildFinalData(
        mainModel.schema.attributes,
        modelDatas
      );

      const tempData = {
        data: finalData1,
      };
      const jsonAutomation = {
        EntityType: enityName,
        State: state,
        County: county,
        Payload: finalData1,
        legalName:
          getValues("CD_LLC_Name") !== undefined
            ? getValues("CD_LLC_Name")
            : getValues("CD_Corporation_Name") !== undefined
              ? getValues("CD_Corporation_Name")
              : null,
        addOnService: addOnData,
        billingAddress: bilingAddress,
        shipingAddress: shippingAddress,
        formCount: formCounts,
        WhatAreChanging: whatAreChanging,
        keyPersonalStrapiArr: keyPersonnelStrapiArray,
        adminCompany: adminCompany,
        einAddress:einLocation
      };

      let d = new Date();
      const futureDate = new Date(d);
      futureDate.setDate(d.getDate() + 7);

      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();

      let day1 = futureDate.getDate();
      let month1 = futureDate.getMonth() + 1;
      let year1 = futureDate.getFullYear();

      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = `0${month}`;
      } else {
        month = month;
      }
      let newDate = year + "-" + month + "-" + day;

      if (day1 < 10) {
        day1 = "0" + day1;
      }
      if (month < 10) {
        month1 = `0${month1}`;
      } else {
        month1 = month1;
      }
      let newDate1 = year1 + "-" + month1 + "-" + day1;

      const upadatedProduct = selectedCheckBoxData.map((val) => {
        return {
          productListing: val.product,
          quantity: 1,
          unitPrice: calculateTotalAmount(),
        };
      });
      if (editPayloadData?.orderDetails?.statusId !== 7) {
        setIsTabRefresh(true);

        if (editPayloadData) {
          const temp = editPayloadData.orderDetails;
          // temp["statusId"] = 14
          temp["orderLineItems"] = updatedOrderLineItems;
          temp["orderUpdateNotes"] = "Strapi data updated successfully";
          temp["orderUpdatedBy"] = loginUserName;
          // temp["shippingAddress"] = shippingAddress
          //   ? shippingAddress.addressMasterId.id
          //   : bilingAddress
          //     ? bilingAddress.addressMasterId.id
          //     : null;
          temp["keyPersonalId"] = keyPersonalArr;
          temp["orderAmount"] = parseFloat(calculateTotalAmount());
          temp["billingAddress"] = bilingAddress
            ? bilingAddress.addressMasterId.id
            : null;
          temp["stateId"] = state ? state.id : null;
          temp["countyId"] = county ? county.id : null;
          temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
          OrderServices.orderUpdate(temp)
            .then((res) => {
              const tempNew = res.data.orderDetails;
              tempNew["shippingAddress"] = shippingAddress
                ? shippingAddress.addressMasterId.id
                : bilingAddress
                  ? bilingAddress.addressMasterId.id
                  : null;
              tempNew["orderLineItems"] = updatedOrderLineItems;
              const invoiceData = {
                orderId: tempNew,
                invoiceNo: `INV ${res.data.orderDetails.orderId}`,
                totalAmount: calculateTotalAmount(),
                orderLineItems: updatedOrderLineItems,
              };
              const temp = res.data.orderDetails;

              setIsTabRefresh(false);
              setRedirectedData(temp);
              navigate("/order");
              setRedirectedData(temp);
            })
            .catch((err) => {
              // setCloseProgresbar2(false)
              // setCloseProgresbar3(false)
              setCloseProgresbar1(true);
              setIsTabRefresh(false);
              // if (toast.current) {
              //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
              // }
            });
        }
      } else {
        setIsTabRefresh(true);
        if (paymentMethod === "Pay later") {
          setCloseProgresbar2(true);
          setIsTabRefresh(false);
        } else {
          setCloseProgresbar3(true);
          setIsTabRefresh(false);
        }

        if (!bilingAddress) {
          showToast("error", "Billing address required");
        }
      }
    } else {
      if (!bilingAddress) {
        // if (toast.current) {
        //   toast.current.show({
        //     severity: "warn",
        //     summary: "Warning",
        //     detail: "Billing address required",
        //     life: 2800,
        //   });
        // }

        showToast("error", `Billing address required`);
      }
    }
  };

  // const onSubmit = (data) => {
  //   if (mainModel && bilingAddress) {
  //     const keyPersonalArr = [];
  //     if (incorporator?.id) keyPersonalArr.push(incorporator.id);
  //     if (officer?.id) keyPersonalArr.push(officer.id);
  //     if (director?.id) keyPersonalArr.push(director.id);
  //     if (filer?.id) keyPersonalArr.push(filer.id);
  //     if (organizerData?.id) keyPersonalArr.push(organizerData.id);
  //     if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
  //     if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);

  //     updateOrAddSingleData(incorporator, "INCORPORATOR");
  //     updateOrAddSingleData(officer, "OFFICER");
  //     updateOrAddSingleData(director, "DIRECTOR");
  //     updateOrAddSingleData(organizerData, "ORGANIZER");
  //     updateOrAddSingleData(bilingAddress, "BILLING");
  //     updateOrAddSingleData(assigneeData, "ASSIGNEE");
  //     updateOrAddSingleData(location, "PRINCIPAL_ADDRESS");

  //     const typeMappings = [
  //       {
  //         data: allBeneficialData,
  //         key: "beneficial",
  //         type: "BENEFICIAL OWNER",
  //       },
  //       {
  //         data: allCompanyApplicantData,
  //         key: "company_applicant",
  //         type: "COMPANY APPLICANT",
  //       },
  //       { data: allDebtorData, key: "debtor", type: "DEBTOR" },
  //       {
  //         data: allAdditionalDebtors,
  //         key: "additionalDebtor",
  //         type: "ADDITIONAL DEBTOR",
  //       },
  //       {
  //         data: allSecuredParties,
  //         key: "securedParty1",
  //         type: "SECURED PARTY",
  //       },
  //       {
  //         data: allAdditionalSecuredParties,
  //         key: "securedParty2",
  //         type: "ADDITIONAL SECURED PARTY",
  //       },
  //     ];

  //     typeMappings.forEach(({ data, key, type }) => {
  //       const filteredData = data.map((item, index) => item[`${key}_${index}`]);
  //       // .filter((item) => item?.id);
  //       updateOrAddData(filteredData, type);
  //     });

  //     const modelDatas = processFormData(allModels);
  //     const finalData1 = buildFinalData(
  //       mainModel.schema.attributes,
  //       modelDatas
  //     );

  //     const tempData = {
  //       data: finalData1,
  //     };
  //     const jsonAutomation = {
  //       EntityType: enityName,
  //       State: state,
  //       County: county,
  //       Payload: finalData1,
  //       legalName:
  //         getValues("CD_LLC_Name") !== undefined
  //           ? getValues("CD_LLC_Name")
  //           : getValues("CD_Corporation_Name") !== undefined
  //           ? getValues("CD_Corporation_Name")
  //           : null,
  //       addOnService: addOnData,
  //       billingAddress: bilingAddress,
  //       shipingAddress: shippingAddress,
  //       formCount: formCounts,
  //       WhatAreChanging: whatAreChanging,
  //       keyPersonalStrapiArr: keyPersonnelStrapiArray,
  //       adminCompany: adminCompany,
  //     };

  //     let d = new Date();
  //     const futureDate = new Date(d);
  //     futureDate.setDate(d.getDate() + 7);

  //     let day = d.getDate();
  //     let month = d.getMonth() + 1;
  //     let year = d.getFullYear();

  //     let day1 = futureDate.getDate();
  //     let month1 = futureDate.getMonth() + 1;
  //     let year1 = futureDate.getFullYear();

  //     if (day < 10) {
  //       day = "0" + day;
  //     }
  //     if (month < 10) {
  //       month = `0${month}`;
  //     } else {
  //       month = month;
  //     }
  //     let newDate = year + "-" + month + "-" + day;

  //     if (day1 < 10) {
  //       day1 = "0" + day1;
  //     }
  //     if (month < 10) {
  //       month1 = `0${month1}`;
  //     } else {
  //       month1 = month1;
  //     }
  //     let newDate1 = year1 + "-" + month1 + "-" + day1;

  //     const upadatedProduct = selectedCheckBoxData.map((val) => {
  //       return {
  //         productListing: val.product,
  //         quantity: 1,
  //         unitPrice: calculateTotalAmount(),
  //       };
  //     });
  //     if (editPayloadData?.orderDetails?.statusId !== 7) {
  //       setIsTabRefresh(true);

  //       if (editPayloadData) {
  //         const temp = editPayloadData.orderDetails;
  //         // temp["statusId"] = 14
  //         temp["orderLineItems"] = updatedOrderLineItems;
  //         temp["orderUpdateNotes"] = "Strapi data updated successfully";
  //         temp["orderUpdatedBy"] = loginUserName;
  //         // temp["shippingAddress"] = shippingAddress
  //         //   ? shippingAddress.addressMasterId.id
  //         //   : bilingAddress
  //         //     ? bilingAddress.addressMasterId.id
  //         //     : null;
  //         temp["keyPersonalId"] = keyPersonalArr;
  //         temp["orderAmount"] = parseFloat(calculateTotalAmount());
  //         temp["billingAddress"] = bilingAddress
  //           ? bilingAddress.addressMasterId.id
  //           : null;
  //         temp["stateId"] = state ? state.id : null;
  //         temp["countyId"] = county ? county.id : null;
  //         temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
  //         OrderServices.orderUpdate(temp)
  //           .then((res) => {
  //             const tempNew = res.data.orderDetails;
  //             tempNew["shippingAddress"] = shippingAddress
  //               ? shippingAddress.addressMasterId.id
  //               : bilingAddress
  //               ? bilingAddress.addressMasterId.id
  //               : null;
  //             tempNew["orderLineItems"] = updatedOrderLineItems;
  //             const invoiceData = {
  //               orderId: tempNew,
  //               invoiceNo: `INV ${res.data.orderDetails.orderId}`,
  //               totalAmount: calculateTotalAmount(),
  //               orderLineItems: updatedOrderLineItems,
  //             };
  //             const temp = res.data.orderDetails;

  //             setIsTabRefresh(false);
  //             setRedirectedData(temp);
  //             navigate("/order");
  //             setRedirectedData(temp);
  //           })
  //           .catch((err) => {
  //             // setCloseProgresbar2(false)
  //             // setCloseProgresbar3(false)
  //             setCloseProgresbar1(true);
  //             setIsTabRefresh(false);
  //             // if (toast.current) {
  //             //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
  //             // }
  //           });
  //       }
  //     } else {
  //       setIsTabRefresh(true);
  //       if (orderTypeId !== 18) {
  //         if (paymentMethod === "Pay later") {
  //           if (editPayloadData) {
  //             const temp = editPayloadData.orderDetails;
  //             temp["statusId"] = 14;
  //             temp["orderLineItems"] = updatedOrderLineItems;
  //             temp["orderUpdateNotes"] = "Order created successfully";
  //             temp["orderUpdatedBy"] = loginUserName;
  //             temp["shippingAddress"] = shippingAddress
  //               ? shippingAddress.addressMasterId.id
  //               : bilingAddress
  //               ? bilingAddress.addressMasterId.id
  //               : null;
  //             temp["orderAmount"] = parseFloat(calculateTotalAmount());

  //             temp["stateId"] = state ? state.id : null;
  //             temp["billingAddress"] = bilingAddress
  //               ? bilingAddress.addressMasterId.id
  //               : null;
  //             temp["countyId"] = county ? county.id : null;
  //             temp["keyPersonalId"] = keyPersonalArr;
  //             temp["orderDate"] = newDate;
  //             temp["tentativeFulfillmentDate"] = futureDate;
  //             temp["strapiOrderId"] = 1;
  //             temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
  //             OrderServices.saveAllOrder(temp)
  //               .then((res) => {
  //                 const tempNew = res.data.orderDetails;
  //                 tempNew["shippingAddress"] = shippingAddress
  //                   ? shippingAddress.addressMasterId.id
  //                   : bilingAddress
  //                   ? bilingAddress.addressMasterId.id
  //                   : null;
  //                 tempNew["orderLineItems"] = updatedOrderLineItems;
  //                 const invoiceData = {
  //                   orderId: tempNew,
  //                   invoiceNo: `INV ${res.data.orderDetails.orderId}`,
  //                   totalAmount: calculateTotalAmount(),
  //                   orderLineItems: updatedOrderLineItems,
  //                 };

  //                 const temp = res.data.orderDetails;

  //                 // OrderServices.saveAllInvoice(invoiceData)
  //                 //   .then((res) => {

  //                 //     // setCloseProgresbar2(false)
  //                 //     // setCloseProgresbar3(false)
  //                 //     // setCloseProgresbar(true)
  //                 //     setIsTabRefresh(false);
  //                 //     setPaymentOrderData(res.data);
  //                 //     setFormData(res.data.orderId);
  //                 //     setCloseProgresbar2(true);
  //                 //   })
  //                 //   .catch((err) => {

  //                 //     setCloseProgresbar2(true);
  //                 //     setCloseProgresbar3(false);
  //                 //     // setCloseProgresbar1(true)
  //                 //     setIsTabRefresh(false);
  //                 //     // if (toast.current) {
  //                 //     //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
  //                 //     // }
  //                 //   });
  //                 setIsTabRefresh(false);
  //                 setPaymentOrderData(res.data);
  //                 setFormData(res.data.orderId);
  //                 setCloseProgresbar2(true);
  //               })
  //               .catch((err) => {
  //                 setCloseProgresbar2(false);
  //                 setCloseProgresbar3(false);
  //                 setCloseProgresbar1(true);
  //                 setIsTabRefresh(false);
  //                 // if (toast.current) {
  //                 //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
  //                 // }
  //               });
  //           }
  //         } else {
  //           setCloseProgresbar3(true);
  //           setIsTabRefresh(false);
  //         }
  //       } else {
  //         if (paymentMethod === "Pay later") {
  //           if (editPayloadData) {
  //             const temp = editPayloadData.orderDetails;
  //             temp["statusId"] = 14;
  //             temp["orderLineItems"] = updatedOrderLineItems;
  //             temp["orderUpdateNotes"] = "Order created successfully";
  //             temp["orderUpdatedBy"] = loginUserName;
  //             temp["shippingAddress"] = shippingAddress
  //               ? shippingAddress.addressMasterId.id
  //               : bilingAddress
  //               ? bilingAddress.addressMasterId.id
  //               : null;
  //             temp["stateId"] = state ? state.id : null;
  //             temp["billingAddress"] = bilingAddress
  //               ? bilingAddress.addressMasterId.id
  //               : null;
  //             temp["countyId"] = county ? county.id : null;
  //             temp["keyPersonalId"] = keyPersonalArr;
  //             temp["orderDate"] = newDate;
  //             temp["tentativeFulfillmentDate"] = futureDate;
  //             temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
  //             OrderServices.saveAllOrder(temp)
  //               .then((res) => {
  //                 const tempNew = res.data.orderDetails;
  //                 tempNew["shippingAddress"] = shippingAddress
  //                   ? shippingAddress.addressMasterId.id
  //                   : bilingAddress
  //                   ? bilingAddress.addressMasterId.id
  //                   : null;
  //                 tempNew["orderLineItems"] = updatedOrderLineItems;
  //                 const invoiceData = {
  //                   orderId: tempNew,
  //                   invoiceNo: `INV ${res.data.orderDetails.orderId}`,
  //                   totalAmount: calculateTotalAmount(),
  //                   orderLineItems: updatedOrderLineItems,
  //                 };

  //                 const temp = res.data.orderDetails;

  //                 // OrderServices.saveAllInvoice(invoiceData)
  //                 //   .then((res) => {
  //                 // OrderServices.saveAllInvoice(invoiceData)
  //                 //   .then((res) => {

  //                 //     // setCloseProgresbar2(false)
  //                 //     // setCloseProgresbar3(false)
  //                 //     // setCloseProgresbar(true)
  //                 //     setIsTabRefresh(false);
  //                 //     setPaymentOrderData(res.data);
  //                 //     setFormData(res.data.orderId);
  //                 //     setCloseProgresbar2(true);
  //                 //   })
  //                 //   .catch((err) => {

  //                 //     setCloseProgresbar2(true);
  //                 //     setCloseProgresbar3(false);
  //                 //     // setCloseProgresbar1(true)
  //                 //     setIsTabRefresh(false);
  //                 //     // if (toast.current) {
  //                 //     //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
  //                 //     // }
  //                 //   });
  //                 setIsTabRefresh(false);
  //                 setPaymentOrderData(res.data);
  //                 setFormData(res.data.orderId);
  //                 setCloseProgresbar2(true);
  //               })
  //               .catch((err) => {
  //                 setCloseProgresbar2(false);
  //                 setCloseProgresbar3(false);
  //                 setCloseProgresbar1(true);
  //                 setIsTabRefresh(false);
  //                 // if (toast.current) {
  //                 //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
  //                 // }
  //               });
  //           }
  //         } else {
  //           setCloseProgresbar3(true);
  //           setIsTabRefresh(false);
  //         }
  //       }
  //     }
  //   } else {
  //     if (!bilingAddress) {
  //       // if (toast.current) {
  //       //   toast.current.show({
  //       //     severity: "warn",
  //       //     summary: "Warning",
  //       //     detail: "Billing address required",
  //       //     life: 2800,
  //       //   });
  //       // }
  //       showToast("error", `Billing address required`);
  //     }
  //   }
  // };

  const convertToTitleCase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
      .replace(/_/g, " ");
  };

  // const totalAmount = selectCheckBoxData.reduce((total, item) => {

  //     const amount = parseFloat(item.amount.replace("$", ""));
  //     return total + amount;
  // }, 0);

  const toPascalCase = (str) => {
    return str
      .replace(/-/g, " ")
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const removeDuplicates = (data) => {
    const result = {};

    Object.keys(data).forEach((key) => {
      // Create a Set to store unique `uid` values
      const uniqueItems = new Map();

      data[key].form.forEach((item) => {
        // Use `uid` as the key to ensure uniqueness
        if (!uniqueItems.has(item.uid)) {
          uniqueItems.set(item.uid, item);
        }
      });

      // Convert the Map values back to an array
      result[key] = {
        mode: data[key].mode,
        form: Array.from(uniqueItems.values()),
      };
    });

    return result;
  };

  const handlers = {
    setOrganizerData,
    setBilingInfo,
    setMailingInfo,
    setBilingAddress,
    setOfficer,
    setIncorporator,
    setFiler,
    setDirector,
    setPresident,
    setTreasurer,
    setSecretary,
  };
  const groupsedData = inputFormData.reduce((acc, value) => {
    if (typeof value.schema.attributes === "object") {
      const tmp = value.schema.attributes;
      if (tmp !== undefined) {
        const temp = Object.keys(tmp).map((key, i) => {
          Object.keys(groupInitialData).map((keys, m) => {
            if (
              key === keys &&
              groupInitialData[keys].type === "component" &&
              groupInitialData[keys].repeatable === true
            ) {
              if (
                tmp[key].schema !== undefined &&
                tmp[key].schema.attributes !== undefined
              ) {
                if (typeof tmp[key].schema.attributes === "object") {
                  const tempdata = Object.keys(tmp[key].schema.attributes).map(
                    (keyval, i) => {
                      if (
                        tmp[key].schema.attributes[keyval].uid !== undefined
                      ) {
                        return tmp[key].schema.attributes[keyval];
                      } else {
                        return tmp[key];
                      }
                    }
                  );

                  acc[key] = {
                    form: tempdata,
                    mode: {
                      repeat: groupInitialData[keys].repeatable,
                    },
                  };
                }
              }
            } else {
              if (
                tmp[key].schema !== undefined &&
                tmp[key].schema.attributes !== undefined &&
                groupInitialData[keys].repeatable === false &&
                key === keys
              ) {
                if (typeof tmp[key].schema.attributes === "object") {
                  const tempdata = Object.keys(tmp[key].schema.attributes).map(
                    (keyval, i) => {
                      if (
                        tmp[key].schema.attributes[keyval].uid !== undefined
                      ) {
                        return tmp[key].schema.attributes[keyval];
                      } else {
                        return tmp[key];
                      }
                    }
                  );

                  acc[key] = {
                    form: tempdata,
                    mode: null,
                  };
                }
              }
            }
          });
        });
      }
    }

    return acc;
  }, {});

  const groupedTempData = removeDuplicates(groupsedData);

  useEffect(() => {
    const temp = Object.keys(groupedData).map((key, i) => {
      return groupedData[key].form;
    });

    const tempdata = temp.flat();

    setAllModels(tempdata);
  }, [enityName, state, currentTab]);

  const handleKeyPersonal = (data, key) => {
    setVisible2(true);
    const temp = allKeyPersonnelType.filter(
      (val) => val.keyPersonalType === data
    );
    if (temp.length > 0) {
      setMemberType(temp[0]);
    }

    const temp1 = allAddressType.filter((val) => val.type === data);
    if (temp1.length > 0) {
      setKeyPersonalAddressType(temp1[0]);
    }

    if (key !== undefined && key !== null) {
      setKeyPersonnelKey(key);
    }
  };

  const handleBOIKeyPersonal = (data, key) => {
    setVisible8(true);
    const temp = ownerType.filter((val) => val.name === data);
    if (temp.length > 0) {
      setMemberType(temp[0]);
    }

    const temp1 = allAddressType.filter((val) => val.type === data);

    if (temp1.length > 0) {
      setKeyPersonalAddressType(temp1[0]);
    }
    if (key !== undefined && key !== null) {
      setKeyPersonnelKey(key);
    }
  };

  const RecursiveRender = ({ data, parentKey }) => {
    return (
      <>
        {Array.isArray(data) ? (
          // Handle array of objects
          data.map((item, idx) => (
            <div key={idx} className="p-6 grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <div
                  className="px-4 py-2 rounded-t-lg"
                  style={{
                    backgroundColor: "#F0F5FF",
                    fontFamily: "Inter",
                    fontSize: "16px",
                  }}
                >
                  <h2 className="text-lg text-gray-800">
                    {" "}
                    {convertToTitleCase(parentKey)}{" "}
                  </h2>
                </div>
              </div>
              {Object.keys(item).map((key, i) => (
                <div key={i} className="col-span-4">
                  {typeof item[key] === "object" ? (
                    <div className="border border-gray-300 rounded-lg shadow-sm bg-white">
                      <div
                        className="px-4 py-2 rounded-t-lg"
                        style={{
                          backgroundColor: "#F0F5FF",
                          fontFamily: "Inter",
                          fontSize: "16px",
                        }}
                      >
                        <h2 className="text-lg text-gray-800">
                          {convertToTitleCase(key)}
                        </h2>
                      </div>
                      {/* Recursively render the nested object */}
                      <RecursiveRender data={item[key]} parentKey={key} />
                    </div>
                  ) : (
                    <>
                      <div
                        className="font-medium text-gray-700 mb-1"
                        style={{ fontFamily: "Inter", fontSize: "14px" }}
                      >
                        {convertName(key)}
                      </div>
                      <div
                        className="text-gray-900"
                        style={{ fontFamily: "Inter", fontSize: "14px" }}
                      >
                        {item[key]}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))
        ) : typeof data === "object" && data !== null ? (
          <div className="p-6 grid grid-cols-12 gap-6">
            {Object.keys(data).map((key, i) => (
              <div key={i} className="col-span-4">
                {typeof data[key] === "object" ? (
                  <div className="border border-gray-300 rounded-lg shadow-sm bg-white">
                    <div
                      className="px-4 py-2 rounded-t-lg"
                      style={{
                        backgroundColor: "#F0F5FF",
                        fontFamily: "Inter",
                        fontSize: "16px",
                      }}
                    >
                      <h2 className="text-lg text-gray-800">
                        {convertToTitleCase(key)}
                      </h2>
                    </div>
                    {/* Recursively render the child object */}
                    <RecursiveRender data={data[key]} parentKey={key} />
                  </div>
                ) : (
                  <>
                    <div
                      className="font-medium text-gray-700 mb-1"
                      style={{ fontFamily: "Inter", fontSize: "14px" }}
                    >
                      {convertName(key)}
                    </div>
                    <div
                      className="text-gray-900"
                      style={{ fontFamily: "Inter", fontSize: "14px" }}
                    >
                      {data[key]}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div
            className="font-medium text-gray-700 mb-1"
            style={{ fontFamily: "Inter", fontSize: "14px" }}
          >
            {convertName(parentKey)}
            <div
              className="text-gray-900"
              style={{ fontFamily: "Inter", fontSize: "14px" }}
            >
              {data}
            </div>
          </div>
        )}
      </>
    );
  };

  const keyPersonalMemberData = [
    "Organizer_Information",
    "Incorporator_Information",
    "Director_Information",
    // "Officer_Details",
    "Officer_Information",
    "Filer_Information",
    "President_Information",
    "Treasurer_Information",
    "Secretary",
    "ucc_Secured_Party_Details",
    "ucc-amnd-additional-debtor",
    "Billing_Information",
    "Mailing_Information",
    "ucc-amend-additional-secured-party-details",
    "ucc-assignee-details",
    "Registered_Agent",
    "UCC_Debtor_Details",
    "Principal_Address",
  ];

  const keyPersonalMemberDataPreview = [
    "Organizer_Information",
    "Incorporator_Information",
    "Company_Applicant_Information",
    "Beneficial_Owner_Information",
    "Director_Information",
    "Officer_Information",
    // "Officer_Details",
    "Filer_Information",
    "President_Information",
    "Treasurer_Information",
    "Secretary",
    "UCC_Secured_Party_Details",
    "ucc-amnd-additional-debtor",
    "Billing_Information",
    "Mailing_Information",
    "ucc-amend-additional-secured-party-details",
    "ucc-assignee-details",
    "Registered_Agent",
    "UCC_Debtor_Details",
    "UCC_Amend_What_are_we_amending",
    "Principal_Address",
    // "EIN_Details"
  ];

  const goToStep = (stepNumber) => {
    const totalSteps = Object.keys(groupedData).length + 3; // Total steps
    if (stepNumber >= 0 && stepNumber < totalSteps) {
      const tabs = document.getElementsByClassName("tab");
      if (tabs[currentTab]) {
        tabs[currentTab].style.display = "none";
      }

      setCurrentTab(stepNumber);
      setCurrentStep(stepNumber + 1);

      showTab(stepNumber);

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  // const onInvalid = (errors) => {
  //   // if (toast && toast.current) {
  //   //   toast.current.show({
  //   //     severity: "warn",
  //   //     summary: "Warning",
  //   //     detail: "Please enter required information",
  //   //     life: 2800,
  //   //   });
  //   // }
  //   showToast("error", "Please enter required information");
  // };

  const onInvalid = (errors) => {
    const stepElements = document.getElementsByClassName("step");
    for (let index = 0; index < stepElements.length; index++) {
      if (!stepElements[index].classList.contains("finish")) {
        stepElements[index].classList.add("error");
      }
    }
    if (stepElements[currentTab] !== undefined) {
      stepElements[currentTab].className += "error";
    }
    showToast("error", `Please enter required information`);
  };

  useEffect(() => {
    if (Object.keys(groupedTempData).length > 0) {
      setGroupedData(groupedTempData);
      setTotalStep(Object.keys(groupedTempData).length + 3);
    }
    if (!initialGroupData && Object.keys(groupedTempData).length > 0) {
      setIsLoading(false);
      setInitialGroupedData(groupedTempData);
      setIsDataFetched(true);
    }
  }, [groupedTempData, selectedCheckBoxData]);

  return (
    <div>
      <Toast ref={toast} />
      {loading1 ? (
        <div className="spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="data-container">
          <div className="grid font-fam-for-all">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
              <div
                className="card-container-wizard font-fam-for-all"
                id="card-container-wizard-id"
              >
                <div className="wizard-header">
                  <div className="wizard-header-container">
                    <div className="page-wizard-header">
                      <div className="page-wizard-content">
                        <div className="page-text-header">
                          <div className="page-text-header-p">
                            {orderHeader
                              ? toPascalCase(orderHeader.itemName)
                              : ""}
                          </div>
                          <div className="page-text-header-p1">
                            {orderHeader ? orderHeader.description : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="wizard-content-container">
                    <div className="wizard-content-main">
                      <div className="wizard-content-frame">
                        <div className="wizard-content-card">
                          <div className="wizard-form-card-header-container">
                            <div className="wizard-form-card-header-actiob-container">
                              <div className="wizard-form-card-frame">
                                {Object.keys(groupedData).length > 0 ? (
                                  <div className="wizard-form-card-header-text1">
                                    {`Step ${currentStep} of ${Object.keys(groupedData).length + 3
                                      }`}
                                  </div>
                                ) : (
                                  <div className="wizard-form-card-header-text1">
                                    {`Step 1 of 1`}
                                  </div>
                                )}
                                {inputFormData.length > 0 ? (
                                  <div className="wizard-form-card-header-text2">
                                    Total order amount
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="wizard-form-card-frame">
                                <div className="wizard-form-card-header-text3">
                                  Entity Details
                                </div>
                                {inputFormData.length > 0 ? (
                                  <div className="wizard-form-card-header-text4">
                                    {`$${parseFloat(
                                      calculateTotalAmount()
                                    ).toFixed(2)}`}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="wizard-bar">
                            <span
                              class={
                                Object.keys(groupedData).length > 0
                                  ? "step"
                                  : "step "
                              }
                              style={{ cursor: "pointer" }}
                              onClick={() => goToStep(0)}
                            >
                              <div className="mt-2 wizard-form-card-header-text3">
                                Entity Details
                              </div>
                            </span>
                            {initialGroupData
                              ? Object.keys(initialGroupData).map((key, i) => (
                                <span
                                  class="step"
                                  
                                  onClick={() => goToStep(i + 1)}
                                  style={{ cursor: "pointer" }}
                                  key={`extra-${i}`}
                                >
                                  <div className="mt-2 wizard-step-content wizard-form-card-header-text3">
                                    {toPascalCase(key)}
                                  </div>
                                </span>
                              ))
                              : ""}

                            {inputFormData.length > 0 ? (
                              <>
                                <span
                                  class={
                                    Object.keys(groupedData).length > 0
                                      ? "step"
                                      : "step"
                                  }
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    goToStep(
                                      Object.keys(groupedData).length + 1
                                    )
                                  }
                                >
                                  <div className="mt-2 wizard-form-card-header-text3">
                                    Contact Address
                                  </div>
                                </span>

                                <span
                                  class={
                                    Object.keys(groupedData).length > 0
                                      ? "step"
                                      : "step"
                                  }
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    goToStep(
                                      Object.keys(groupedData).length + 2
                                    )
                                  }
                                >
                                  <div className="mt-2 wizard-form-card-header-text3">
                                    Preview
                                  </div>
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="wizard-main-content font-fam-for-all mt-4">
                            <div className="wizard-main-sub-content">
                              <form
                                className="form-wizard"
                              // onSubmit={handleSubmit(onSubmit)}
                              >
                                <div className="tab px-4 hover-card slide-in font-fam-for-all">
                                  <div
                                    className="wizard-content-sub-card-header my-3"
                                    id="header"
                                  >
                                    <div className="wizard-content-sub-card-text-frame">
                                      <div className="wizard-content-sub-card-text">
                                        Select State & Entity
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex flex-column justify-content-center">
                                    <div className="input-fields-main ">
                                      <div className="grid w-full justify-content-center">
                                        <div
                                          className={
                                            state &&
                                              stateWithCounty.includes(
                                                state.stateFullDesc
                                              )
                                              ? "col-4"
                                              : "col-6"
                                          }
                                        >
                                          <div className=" justify-content-center dialog-form-field-space w-full">
                                            <div className="field">
                                              <label
                                                htmlFor=""
                                                className="form-label font-fam-for-all"
                                              >
                                                Select Entity Type
                                                <span className="form-field-mandatory">
                                                  *
                                                </span>
                                              </label>
                                              <span className=" ">
                                                <Controller
                                                  name="state"
                                                  control={control}
                                                  render={({ field }) => (
                                                    <Dropdown
                                                      value={enityName}
                                                      options={entityTypes}
                                                      onChange={(e) =>
                                                        setEntityName(e.value)
                                                      }
                                                      optionLabel="entityShortName"
                                                      placeholder="Select Entity Type"
                                                      disabled
                                                      style={{
                                                        width: "100%",
                                                        height: "47px",
                                                      }}
                                                      filter
                                                      resetFilterOnHide
                                                      className="dialog-form-input-field-wizard"
                                                      required
                                                    />
                                                  )}
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={
                                            state &&
                                              stateWithCounty.includes(
                                                state.stateFullDesc
                                              )
                                              ? "col-4"
                                              : "col-6"
                                          }
                                        >
                                          <div className=" justify-content-center dialog-form-field-space w-full">
                                            <div className="field">
                                              <label
                                                htmlFor=""
                                                className="form-label font-fam-for-all"
                                              >
                                                State of Formation{" "}
                                                <span className="form-field-mandatory">
                                                  *
                                                </span>
                                              </label>
                                              <span className=" ">
                                                <Controller
                                                  name="state"
                                                  control={control}
                                                  render={({ field }) => (
                                                    <Dropdown
                                                      value={field.value}
                                                      id={field.name}
                                                      options={allState}
                                                      onChange={(e) => {
                                                        field.onChange(e.value);
                                                        setState(e.value);
                                                      }}
                                                      disabled
                                                      optionLabel="stateFullDesc"
                                                      style={{
                                                        width: "100%",
                                                        height: "47px",
                                                      }}
                                                      placeholder="Select State"
                                                      filter
                                                      resetFilterOnHide
                                                      className="dialog-form-input-field-wizard"
                                                      required
                                                    />
                                                  )}
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </div>

                                        {state &&
                                          stateWithCounty.includes(
                                            state.stateFullDesc
                                          ) ? (
                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                              <div className="field">
                                                <label
                                                  htmlFor=""
                                                  className="form-label font-fam-for-all"
                                                >
                                                  County
                                                  <span className="form-field-mandatory">
                                                    *
                                                  </span>
                                                </label>
                                                <span className=" ">
                                                  <Controller
                                                    name="county"
                                                    control={control}
                                                    render={({ field }) => (
                                                      <Dropdown
                                                        value={field.value}
                                                        id={field.name}
                                                        options={allCounty}
                                                        onChange={(e) => {
                                                          field.onChange(
                                                            e.value
                                                          );
                                                          setCounty(e.value);
                                                        }}
                                                        disabled
                                                        optionLabel="countyName"
                                                        style={{
                                                          width: "100%",
                                                          height: "47px",
                                                        }}
                                                        placeholder="Select County"
                                                        filter
                                                        resetFilterOnHide
                                                        className="dialog-form-input-field-wizard"
                                                      />
                                                    )}
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        {orderTypeId === 9 && (
                                          <div
                                            className={
                                              state &&
                                                stateWithCounty.includes(
                                                  state.stateFullDesc
                                                )
                                                ? "col-6"
                                                : "col-6"
                                            }
                                          >
                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                              <div className="field">
                                                <label
                                                  htmlFor=""
                                                  className="form-label font-fam-for-all"
                                                >
                                                  What Are Changing
                                                  <span className="form-field-mandatory">
                                                    *
                                                  </span>
                                                </label>
                                                <span className=" ">
                                                  <Controller
                                                    name="COC_What_Are_We_Changing-X"
                                                    control={control}
                                                    render={({ field }) => (
                                                      <MultiSelect
                                                        value={field.value}
                                                        id={field.name}
                                                        options={[
                                                          "County",
                                                          "Address",
                                                          "Agent",
                                                        ]}
                                                        disabled
                                                        onChange={(e) => {
                                                          field.onChange(
                                                            e.value
                                                          );
                                                          setWhatAreChanging(
                                                            e.value
                                                          );
                                                        }}
                                                        style={{
                                                          width: "100%",
                                                          height: "47px",
                                                        }}
                                                        placeholder="Select What We Are Changing"
                                                        filter
                                                        className="dialog-form-input-field-wizard"
                                                        required
                                                      />
                                                    )}
                                                  />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    {state && enityName && (
                                      <div
                                        className="wizard-content-sub-card-header my-3"
                                        id="header"
                                      >
                                        <div className="wizard-content-sub-card-text-frame">
                                          <div className="wizard-content-sub-card-text">
                                            Choose your preferred services from
                                            the options below
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {state && enityName ? (
                                      showSkeleton ? (
                                        servicesData
                                          .filter(
                                            (val) => !val?.product?.isDefault
                                          )
                                          .map((serviceData, index) => {
                                            const { product } = serviceData;

                                            const isSelected = (
                                              product.displayName || []
                                            ).includes(
                                              selectedProducts[
                                                product.orderTypeMaster
                                              ]
                                            );

                                            return (
                                              <div
                                                key={index}
                                                className="wizard-card-checkbox-container hover-card slide-in"
                                              >
                                                <div className="wizard-card-checkbox-main pb-0">
                                                  <div className="wizard-card-checkbox-text-main">
                                                    <div className="wizard-card-checkbox-action-container">
                                                      <Checkbox
                                                        className="mt-2"
                                                        disabled={
                                                          (serviceData?.product
                                                            ?.orderTypeMaster ===
                                                            orderTypeId &&
                                                            serviceData?.product
                                                              ?.orderTypeMaster !==
                                                            18 &&
                                                            serviceData?.product
                                                              ?.orderTypeMaster !==
                                                            21) ||
                                                          editPayloadData
                                                            ?.orderDetails
                                                            ?.transactionId !==
                                                          null ||
                                                          editPayloadData
                                                            ?.statusId
                                                            ?.statusName ===
                                                          "VSTATE QUERY RAISED" ||
                                                          editPayloadData
                                                            ?.statusId
                                                            ?.statusName ===
                                                          "GOVT QUERY RAISED"
                                                        }
                                                        checked={
                                                          isSelected ||
                                                          (serviceData?.product
                                                            ?.orderTypeMaster ===
                                                            orderTypeId &&
                                                            serviceData?.product
                                                              ?.orderTypeMaster !==
                                                            18 &&
                                                            serviceData?.product
                                                              ?.orderTypeMaster !==
                                                            21)
                                                        }
                                                        onChange={(e) =>
                                                          handleCheckedChange(
                                                            e,
                                                            serviceData
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                    <div className="w-full grid">
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-11 p-3">
                                                        <div className="wizard-card-checkbox-text1 font-fam-for-all text-start">
                                                          <div className="flex align-items-center">
                                                            {
                                                              product.displayName
                                                            }
                                                            <button
                                                              className="ml-2 bg-transparent border-none cursor-pointer"
                                                              data-bs-toggle="tooltip"
                                                              data-bs-placement="right"
                                                              title={
                                                                product
                                                                  .productMaster
                                                                  .productAdditionalInfo
                                                              }
                                                            >
                                                              <i
                                                                className="pi pi-info-circle"
                                                                style={{
                                                                  fontSize:
                                                                    "1.2rem",
                                                                  color:
                                                                    "#007bff",
                                                                }}
                                                              ></i>
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="wizard-card-checkbox-text2 justify-content-end col-12 col-sm-12 col-md-12 col-lg-1 flex align-items-center">
                                                        {product.isCustomPriceApplicable
                                                          ? "Custom Price"
                                                          : product.isPriceIncludedInBaseProduct
                                                            ? "Included"
                                                            : `$${parseFloat(
                                                              product.unitPrice
                                                            ).toFixed(2)}`}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })
                                      ) : (
                                        <>
                                          <DataTable
                                            value={items}
                                            inline
                                            emptyMessage="No Candidate Found"
                                            selectionMode="single"
                                            metaKeySelection={false}
                                          >
                                            <Column
                                              body={<Skeleton />}
                                              className="datatable-body-icon"
                                            ></Column>
                                          </DataTable>
                                        </>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                {Object.keys(groupedData).map((key, i) => (
                                  <div className="tab px-4" key={i}>
                                    <div
                                      className="wizard-content-sub-card-header my-3"
                                      id="header"
                                    >
                                      <div className="wizard-content-sub-card-text-frame">
                                        <div className="wizard-content-sub-card-text">
                                          {toPascalCase(key)}
                                        </div>
                                      </div>
                                    </div>

                                    <div>
                                      {i === 0 &&
                                        orderTypeId !== 5 &&
                                        orderTypeId !== 1 ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Company
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="orgaineriInfoId"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={adminCompany}
                                                          // disabled={isSecondDraft}

                                                          options={
                                                            allAdminCompany
                                                          }
                                                          onChange={(e) =>
                                                            setAdminCompany(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="companyName"
                                                          // optionValue="companyName"
                                                          placeholder="Select Company Name"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          resetFilterOnHide
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Company"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  setVisible9(true)
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div></div>
                                        </>
                                      )}
                                      {key === "Organizer_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Organizer
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="orgaineriInfoId"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={organizerData}
                                                          options={allOrganizer}
                                                          onChange={(e) =>
                                                            setOrganizerData(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Entity Type"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          resetFilterOnHide
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center flex">
                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("ORGANIZER")}>
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Organizer</div>
                                                                                                </div>

                                                                                            </button> */}
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Organizer"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("ORGANIZER")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div></div>
                                        </>
                                      )}
                                      {key === "Officer_Details" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Officer
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="Officer"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          id="Officer"
                                                          value={officer}
                                                          options={allOfficer}
                                                          onChange={(e) =>
                                                            setOfficer(e.value)
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                  <small
                                                    id={`Officer--error`}
                                                    className="error-msg font-fam-for-all"
                                                  ></small>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("OFFICER")}>
                                                                                                                                                                           <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                                                                               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                                                                   <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                                                               </svg>
                                                                                                                                                                               <div className='font-medium'>Add Officer</div>
                                                                                                                                                                           </div>
                                                                           
                                                                                                                                                                       </button> */}
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Officer"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("OFFICER")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          {/* <div>
                                            {officer && (
                                              <KeyPersonnelIndividualCard
                                                data={officer}
                                                getState={getState}
                                                type={"Officer"}
                                              />
                                            )}
                                          </div> */}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {key === "Principal_Address" ? (
                                        <>
                                          <div className="grid w-full justify-content-start">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Principal Address
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="principalAddress"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={location}
                                                          options={allLocation}
                                                          onChange={(e) =>
                                                            setLocation(e.value)
                                                          }
                                                          optionLabel="addressLine1"
                                                          placeholder="Select Address"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          resetFilterOnHide
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 justify-content-start align-items-center flex">
                                              {/* <button className="datatable-highlight-color" onClick={() => setVisible3(true)}>
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Principle Address</div>
                                                                                                </div>

                                                                                            </button> */}
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Principal Address"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  setVisible3(true)
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          <div>
                                            {location && (
                                              <PrincipalAddressCard
                                                data={location}
                                                getState={getState}
                                              />
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "EIN_Details" ? (
                                        <>
                                          <div className="grid w-full justify-content-start">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                              <div className="justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label className="form-label font-fam-for-all">
                                                    Select Address
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className="p-float-label">
                                                    <Controller
                                                      name="principalAddress"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={einLocation}
                                                          options={allLocation}
                                                          onChange={(e) =>
                                                            setEinLocation(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="addressLine1"
                                                          placeholder="Select Address"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          resetFilterOnHide
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Address"
                                                className="vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  setVisible3(true)
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div>
                                            {einLocation && (
                                              <PrincipalAddressCard
                                                data={einLocation}
                                                getState={getState}
                                              />
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Incorporator_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Incorporator
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={incorporator}
                                                          options={
                                                            allInCorporator
                                                          }
                                                          onChange={(e) =>
                                                            setIncorporator(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Incorporator"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          resetFilterOnHide
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center flex">
                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("INCORPORATOR")}>
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Incorporator</div>
                                                                                                </div>

                                                                                            </button> */}
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Incorporator"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal(
                                                    "INCORPORATOR"
                                                  )
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Director_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Director
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={director}
                                                          options={allDirector}
                                                          onChange={(e) =>
                                                            setDirector(e.value)
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Director"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          resetFilterOnHide
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center flex">
                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DIRECTOR")}>
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Director</div>
                                                                                                </div>

                                                                                            </button> */}
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Director"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("DIRECTOR")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Officer_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Officer
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={officer}
                                                          options={allOfficer}
                                                          onChange={(e) =>
                                                            setOfficer(e.value)
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Incorporator"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          resetFilterOnHide
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center flex">
                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("OFFICER")}>
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Officer</div>
                                                                                                </div>

                                                                                            </button> */}
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Officer"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("OFFICER")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Filer_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Filer
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={filer}
                                                          options={allFiler}
                                                          onChange={(e) =>
                                                            setFiler(e.value)
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Incorporator"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          resetFilterOnHide
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center flex">
                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("OFFICER")}>
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Officer</div>
                                                                                                </div>

                                                                                            </button> */}
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Filer"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("FILER")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "President_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select President
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={president}
                                                          options={allPresident}
                                                          onChange={(e) =>
                                                            setPresident(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select President"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          resetFilterOnHide
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add President"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("PRESIDENT")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Contact_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Contact
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={contactInfo}
                                                          options={
                                                            allContactInfo
                                                          }
                                                          onChange={(e) =>
                                                            setBilingInfo(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Contact Info"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          resetFilterOnHide
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Contact"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("CONTACT")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Treasurer_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Treasurer
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={treasurer}
                                                          options={allTreasurer}
                                                          onChange={(e) =>
                                                            setTreasurer(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Treasurer"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          resetFilterOnHide
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Treasurer"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("TREASURER")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Secretary" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Secretary
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={secretary}
                                                          options={allSecretary}
                                                          onChange={(e) =>
                                                            setSecretary(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Secretary"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          resetFilterOnHide
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Secretary"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("SECRETARY")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {key === "Registered_Agent" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                                              <div className="flex justify-content-start mb-3 ml-1">
                                                <Checkbox
                                                  checked={IsCheckedAgent}
                                                  onChange={(e) =>
                                                    setIsCheckedAgent(e.checked)
                                                  }
                                                  inputProps={{
                                                    "aria-label": "controlled",
                                                  }}
                                                />

                                                <label
                                                  htmlFor="binary"
                                                  className="font-fam-for-all ml-2 srch-cand-text"
                                                >
                                                  Select vState as your
                                                  Registered Agent
                                                </label>
                                              </div>
                                            </div>
                                            {/* <div className="col-2 justify-content-end align-items-center flex">
                                                                                            {
                                                                                                key === "Registered_Agent" ? <Button type='button' className="mt-2   vstate-reverse-button font-fam-for-all text-center  justify-content-center" onClick={() => nextPrev(1)} >Skip</Button>
                                                                                                    : ""
                                                                                            }
                                                                                        </div> */}
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {orderTypeId === 1 ? (
                                      <>
                                        {groupedData[key].mode &&
                                          groupedData[key].mode.repeat ===
                                          true ? (
                                          <div>
                                            <div className="vstate-card p-4 mb-3">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                                                <h2 className="entity-header-strapi">
                                                  {toPascalCase(
                                                    groupedData[key].form[0]
                                                      .schema.displayName
                                                  )}
                                                </h2>
                                                {groupedData[key].mode &&
                                                  groupedData[key].mode
                                                    .repeat === true}
                                              </div>
                                              {Array.from({
                                                length: formCounts[key] - 1,
                                              }).map((_, l) =>
                                                groupedData[key].form.map(
                                                  (value, j) => (
                                                    <>
                                                      <div>
                                                        {
                                                          <div className="vstate-card p-4 mb-3">
                                                            <div
                                                              className="grid"
                                                              key={l}
                                                            >
                                                              {Object.keys(
                                                                value.schema
                                                                  .attributes
                                                              ).map(
                                                                (val, k) => {
                                                                  const content =
                                                                    value.schema
                                                                      .attributes[
                                                                      val
                                                                    ].type !==
                                                                      undefined
                                                                      ? renderFormField(
                                                                        value
                                                                          .schema
                                                                          .attributes[
                                                                        val
                                                                        ],
                                                                        val,
                                                                        `${val}-${l}`,
                                                                        value,
                                                                        key
                                                                      )
                                                                      : null;

                                                                  return content ? (
                                                                    <div
                                                                      className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"
                                                                      key={k}
                                                                    >
                                                                      {content}
                                                                    </div>
                                                                  ) : null;
                                                                }
                                                              )}
                                                            </div>
                                                          </div>
                                                        }
                                                      </div>
                                                      <div className="card-devider"></div>
                                                    </>
                                                  )
                                                )
                                              )}
                                            </div>

                                            {/* {groupedData[key].mode &&
                                              groupedData[key].mode.repeat ===
                                              true && (
                                                <div className="flex gap-2 justify-content-center w-full">
                                                  <Button
                                                    type="button"
                                                    icon="pi pi-plus"
                                                    disabled={
                                                      maxValue &&
                                                      key ===
                                                      "Beneficial_Owner_Information" &&
                                                      (formCounts[key] || 1) >=
                                                      maxValue
                                                    }
                                                    label="Add Entry"
                                                    className="vstate-button font-fam-for-all text-center justify-content-center"
                                                    onClick={() =>
                                                      handleRepeatForm(key)
                                                    }
                                                  />
                                                  <Button
                                                    type="button"
                                                    icon="pi pi-minus"
                                                    disabled={
                                                      (maxValue &&
                                                        key ===
                                                        "Beneficial_Owner_Information" &&
                                                        (formCounts[key] ||
                                                          1) === minValue) ||
                                                      (formCounts[key] || 1) ===
                                                      1
                                                    }
                                                    label="Remove Entry"
                                                    className="vstate-button font-fam-for-all text-center justify-content-center"
                                                    onClick={() =>
                                                      handleRemoveRepeatForm(
                                                        key
                                                      )
                                                    }
                                                  />
                                                </div>
                                              )} */}
                                          </div>
                                        ) : (
                                          groupedData[key].form.map(
                                            (value, j) => (
                                              <>
                                                {value.schema.displayName ===
                                                  "Billing_Information" ? (
                                                  <>
                                                    <div className="grid w-full justify-content-between">
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                        <div className=" justify-content-center dialog-form-field-space w-full">
                                                          <div className="field">
                                                            <label
                                                              htmlFor=""
                                                              className="form-label font-fam-for-all"
                                                            >
                                                              Select Billing
                                                              Info
                                                              <span className="form-field-mandatory">
                                                                *
                                                              </span>
                                                            </label>
                                                            <span className=" ">
                                                              <Controller
                                                                name="incorporator"
                                                                control={
                                                                  control
                                                                }
                                                                render={({
                                                                  field,
                                                                }) => (
                                                                  <Dropdown
                                                                    value={
                                                                      bilingInfo
                                                                    }
                                                                    options={
                                                                      allBilingInfo
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      setBilingInfo(
                                                                        e.value
                                                                      )
                                                                    }
                                                                    optionLabel="keyPersonnelName"
                                                                    placeholder="Select Billing info"
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      height:
                                                                        "47px",
                                                                    }}
                                                                    filter
                                                                    resetFilterOnHide
                                                                    className="dialog-form-input-field-wizard"
                                                                    required
                                                                  />
                                                                )}
                                                              />
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center flex">
                                                        <Button
                                                          type="button"
                                                          icon="pi pi-plus"
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                          label="Add Billing Info"
                                                          className=" vstate-button font-fam-for-all text-center mt-4"
                                                          onClick={() =>
                                                            handleKeyPersonal(
                                                              "RA-BILLING"
                                                            )
                                                          }
                                                        ></Button>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}

                                                {value.schema.displayName ===
                                                  "Mailing_Information" ? (
                                                  <>
                                                    <div className="grid w-full justify-content-between">
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                        <div className=" justify-content-center dialog-form-field-space w-full">
                                                          <div className="field">
                                                            <label
                                                              htmlFor=""
                                                              className="form-label font-fam-for-all"
                                                            >
                                                              Select Mailing
                                                              Info
                                                              <span className="form-field-mandatory">
                                                                *
                                                              </span>
                                                            </label>
                                                            <span className=" ">
                                                              <Controller
                                                                name="incorporator"
                                                                control={
                                                                  control
                                                                }
                                                                render={({
                                                                  field,
                                                                }) => (
                                                                  <Dropdown
                                                                    value={
                                                                      mailingInfo
                                                                    }
                                                                    options={
                                                                      allMailingInfo
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      setMailingInfo(
                                                                        e.value
                                                                      )
                                                                    }
                                                                    optionLabel="keyPersonnelName"
                                                                    placeholder="Select Mailing Info"
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      height:
                                                                        "47px",
                                                                    }}
                                                                    filter
                                                                    resetFilterOnHide
                                                                    className="dialog-form-input-field-wizard"
                                                                    required
                                                                  />
                                                                )}
                                                              />
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center flex">
                                                        <Button
                                                          type="button"
                                                          icon="pi pi-plus"
                                                          style={{
                                                            fontSize: "14px",
                                                          }}
                                                          label="Add Mailing Info"
                                                          className=" vstate-button font-fam-for-all text-center mt-4"
                                                          onClick={() =>
                                                            handleKeyPersonal(
                                                              "RA-MAILING"
                                                            )
                                                          }
                                                        ></Button>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                                {value.schema.displayName ===
                                                  "Mailing_Information" && (
                                                    <div className="grid justify-content-start ml-2 mt-2 mb-3">
                                                      <div className=" p-0">
                                                        <div className="flex justify-content-center">
                                                          <Checkbox
                                                            checked={
                                                              IsCheckedAgent2
                                                            }
                                                            onChange={(e) => {
                                                              setIsCheckedAgent2(
                                                                e.checked
                                                              );
                                                            }}
                                                            inputProps={{
                                                              "aria-label":
                                                                "controlled",
                                                            }}
                                                          />

                                                          <label
                                                            htmlFor="binary"
                                                            className="font-fam-for-all ml-2 srch-cand-text"
                                                          >
                                                            Same as Registered
                                                            Agent
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                {value.schema.displayName ===
                                                  "Mailing_Information" && (
                                                    <div className="grid justify-content-start ml-2 mt-2 mb-3">
                                                      <div className=" p-0">
                                                        <div className="flex justify-content-center">
                                                          <Checkbox
                                                            checked={
                                                              IsCheckedAgent1
                                                            }
                                                            onChange={(e) => {
                                                              setIsCheckedAgent1(
                                                                e.checked
                                                              );
                                                            }}
                                                            inputProps={{
                                                              "aria-label":
                                                                "controlled",
                                                            }}
                                                          />

                                                          <label
                                                            htmlFor="binary"
                                                            className="font-fam-for-all ml-2 srch-cand-text"
                                                          >
                                                            Same as Billing Info
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                <div className="vstate-card p-4 mb-3">
                                                  <div
                                                    className="grid "
                                                    key={j}
                                                  >
                                                    <div className="col-12 pb-0">
                                                      <h2 className="entity-header-strapi">
                                                        {toPascalCase(
                                                          value.schema
                                                            .displayName
                                                        )}
                                                      </h2>
                                                    </div>
                                                    {Object.keys(
                                                      value.schema.attributes
                                                    ).map((val, k) => (
                                                      <div
                                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"
                                                        key={k}
                                                      >
                                                        {value.schema
                                                          .attributes[val]
                                                          .type !== undefined
                                                          ? renderFormField(
                                                            value.schema
                                                              .attributes[
                                                            val
                                                            ],
                                                            val,
                                                            val,
                                                            value,
                                                            key
                                                          )
                                                          : ""}
                                                      </div>
                                                    ))}
                                                  </div>
                                                </div>
                                              </>
                                            )
                                          )
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {key === "UCC_Debtor_Details" && (
                                          <>
                                            <div className="flex justify-content-end mb-2">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{
                                                  fontSize: "14px",
                                                }}
                                                label="Add DEBTOR"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal(
                                                    "DEBTOR",
                                                    `ucc_Debtor_Details_Dropdown-${formCounts[key] + 1 || 1
                                                    }`
                                                  )
                                                }
                                              ></Button>
                                            </div>
                                            <KeyPersonnelOrderDataTable
                                              data={allDebtorData}
                                              setterFunction={setAllDebtorData}
                                              responseValue={keyPersonnelValue}
                                              keyValue={key}
                                              formCounts={formCounts}
                                              setFormCounts={setFormCounts}
                                            />
                                          </>
                                        )}

                                        {key ===
                                          "UCC_Secured_Party_Details" && (
                                            <>
                                              <div className="flex justify-content-end mb-2">
                                                <Button
                                                  type="button"
                                                  icon="pi pi-plus"
                                                  style={{
                                                    fontSize: "14px",
                                                  }}
                                                  label="Add Secured Party"
                                                  className=" vstate-button font-fam-for-all text-center mt-4"
                                                  onClick={() =>
                                                    handleKeyPersonal(
                                                      "SECUERED PARTY"
                                                    )
                                                  }
                                                ></Button>
                                              </div>
                                              <KeyPersonnelOrderDataTable
                                                data={allSecuredParties}
                                                setterFunction={
                                                  setAllSecuredParties
                                                }
                                                responseValue={keyPersonnelValue}
                                                keyValue={key}
                                                formCounts={formCounts}
                                                setFormCounts={setFormCounts}
                                              />
                                            </>
                                          )}

                                        {key ===
                                          "Beneficial_Owner_Information" && (
                                            <>
                                              <div className="flex justify-content-end mb-2">
                                                <Button
                                                  type="button"
                                                  icon="pi pi-plus"
                                                  style={{
                                                    fontSize: "14px",
                                                  }}
                                                  label="Add Beneficial Owner"
                                                  className=" vstate-button font-fam-for-all text-center mt-4"
                                                  onClick={() =>
                                                    handleBOIKeyPersonal(
                                                      "BENEFICIAL OWNER",
                                                      `ucc_Beneficial_Dropdown-${formCounts[key] + 1 || 1
                                                      }`
                                                    )
                                                  }
                                                ></Button>
                                              </div>
                                              <KeyPersonnelOrderBOIDataTable
                                                data={allBeneficialData}
                                                setterFunction={
                                                  setAllBeneficialData
                                                }
                                                responseValue={keyPersonnelValue}
                                                keyValue={key}
                                                formCounts={formCounts}
                                                setFormCounts={setFormCounts}
                                              />
                                            </>
                                          )}

                                        {key ===
                                          "Company_Applicant_Information" && (
                                            <>
                                              <div className="flex justify-content-end mb-2">
                                                <Button
                                                  type="button"
                                                  icon="pi pi-plus"
                                                  style={{
                                                    fontSize: "14px",
                                                  }}
                                                  label="Add Company Applicant"
                                                  className=" vstate-button font-fam-for-all text-center mt-4"
                                                  onClick={() =>
                                                    handleBOIKeyPersonal(
                                                      "COMPANY APPLICANT",
                                                      `company_applicant_item_${formCounts[key] + 1 || 1
                                                      }`
                                                    )
                                                  }
                                                ></Button>
                                              </div>
                                              <KeyPersonnelOrderBOIDataTable
                                                data={allCompanyApplicantData}
                                                setterFunction={
                                                  setAllCompanyApplicantData
                                                }
                                                responseValue={keyPersonnelValue}
                                                keyValue={key}
                                                formCounts={formCounts}
                                                setFormCounts={setFormCounts}
                                              />
                                            </>
                                          )}

                                        {getValues(
                                          "uccAmend_What_are_we_amending"
                                        ) === "ADDITIONAL SECURED" &&
                                          key ===
                                          "UCC_Amend_What_are_we_amending" && (
                                            <>
                                              <div className="flex justify-content-end mb-2">
                                                <Button
                                                  type="button"
                                                  icon="pi pi-plus"
                                                  style={{
                                                    fontSize: "14px",
                                                  }}
                                                  label="Add Additional Secured Party"
                                                  className=" vstate-button font-fam-for-all text-center mt-4"
                                                  onClick={() =>
                                                    handleKeyPersonal(
                                                      "SECUERED PARTY"
                                                    )
                                                  }
                                                ></Button>
                                              </div>
                                              <KeyPersonnelOrderDataTable
                                                data={
                                                  allAdditionalSecuredParties
                                                }
                                                setterFunction={
                                                  setAllAdditionalSecuredParties
                                                }
                                                responseValue={
                                                  keyPersonnelValue
                                                }
                                                keyValue={key}
                                                formCounts={formCounts}
                                                setFormCounts={setFormCounts}
                                                type="Additional_SecuredParties"
                                              />
                                            </>
                                          )}

                                        {getValues(
                                          "uccAmend_What_are_we_amending"
                                        ) === "ADDITIONAL DEBTOR" &&
                                          key ===
                                          "UCC_Amend_What_are_we_amending" && (
                                            <>
                                              <div className="flex justify-content-end mb-2">
                                                <Button
                                                  type="button"
                                                  icon="pi pi-plus"
                                                  style={{
                                                    fontSize: "14px",
                                                  }}
                                                  label="Add DEBTOR"
                                                  className=" vstate-button font-fam-for-all text-center mt-4"
                                                  onClick={() =>
                                                    handleKeyPersonal(
                                                      "DEBTOR",
                                                      `ucc_Debtor_Details_Dropdown-${formCounts[key] + 1 || 1
                                                      }`
                                                    )
                                                  }
                                                ></Button>
                                              </div>
                                              <KeyPersonnelOrderDataTable
                                                data={allAdditionalDebtors}
                                                setterFunction={
                                                  setAllAdditionalDebtors
                                                }
                                                responseValue={
                                                  keyPersonnelValue
                                                }
                                                keyValue={key}
                                                formCounts={formCounts}
                                                setFormCounts={setFormCounts}
                                                type="Additional_Debtors"
                                              />
                                            </>
                                          )}

                                        {groupedData[key].mode &&
                                          groupedData[key].mode.repeat ===
                                          true ? (
                                          <div>
                                            <div className="vstate-card p-4 mb-3 vstate-visbiliy-hidden">
                                              <div className="col-12 p-0">
                                                <h2 className="entity-header-strapi">
                                                  {toPascalCase(
                                                    groupedData[key].form[0]
                                                      .schema.displayName
                                                  )}
                                                </h2>
                                                {groupedData[key].mode &&
                                                  groupedData[key].mode
                                                    .repeat === true}
                                              </div>

                                              {Array.from({
                                                length: formCounts[key] - 1,
                                              }).map((_, l) => (
                                                <>
                                                  <Accordion activeIndex={0}>
                                                    <AccordionTab
                                                      header={`${toPascalCase(
                                                        groupedData[key].form[0]
                                                          .schema.displayName
                                                      )}-${l + 1}`}
                                                    >
                                                      {key ===
                                                        "UCC_Debtor_Details" ? (
                                                        <>
                                                          <div className="grid w-full justify-content-between">
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                                <div className="field">
                                                                  <label
                                                                    htmlFor=""
                                                                    className="form-label font-fam-for-all"
                                                                  >
                                                                    Select
                                                                    Debtor
                                                                    <span className="form-field-mandatory">
                                                                      *
                                                                    </span>
                                                                  </label>
                                                                  <span className=" ">
                                                                    <Controller
                                                                      name={`ucc_Debtor_Details_Dropdown-${l}`}
                                                                      control={
                                                                        control
                                                                      }
                                                                      render={({
                                                                        field,
                                                                      }) => (
                                                                        <Dropdown
                                                                          value={
                                                                            field.value
                                                                          }
                                                                          options={
                                                                            aallDebtor
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            field.onChange(
                                                                              e.value
                                                                            );

                                                                            handleDebtorChange(
                                                                              e.value,
                                                                              l
                                                                            );
                                                                          }}
                                                                          optionLabel="keyPersonnelName"
                                                                          style={{
                                                                            width:
                                                                              "100%",
                                                                            height:
                                                                              "47px",
                                                                          }}
                                                                          filter
                                                                          resetFilterOnHide
                                                                          className="dialog-form-input-field-wizard"
                                                                          required
                                                                        />
                                                                      )}
                                                                    />
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center mt-3">
                                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                        </svg>
                                                                                                                        <div className='font-medium'>Add Debtor</div>
                                                                                                                    </div>

                                                                                                                </button> */}
                                                              <Button
                                                                type="button"
                                                                icon="pi pi-plus"
                                                                style={{
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                                label="Add Debtor"
                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                onClick={() =>
                                                                  handleKeyPersonal(
                                                                    "DEBTOR",
                                                                    `ucc_Debtor_Details_Dropdown-${l}`
                                                                  )
                                                                }
                                                              ></Button>
                                                            </div>
                                                          </div>
                                                          <div>
                                                            {getValues(
                                                              `ucc_Debtor_Details_Dropdown-${l}`
                                                            ) && (
                                                                <KeyPersonnelIndividualCard
                                                                  data={getValues(
                                                                    `ucc_Debtor_Details_Dropdown-${l}`
                                                                  )}
                                                                  getState={
                                                                    getState
                                                                  }
                                                                  type={"Debtor"}
                                                                />
                                                              )}
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {key ===
                                                        "Company_Applicant_Information" ? (
                                                        <>
                                                          <div className="grid w-full justify-content-between">
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                                <div className="field">
                                                                  <label
                                                                    htmlFor=""
                                                                    className="form-label font-fam-for-all"
                                                                  >
                                                                    Select
                                                                    Company
                                                                    Applicant
                                                                    <span className="form-field-mandatory"></span>
                                                                  </label>
                                                                  <span className=" ">
                                                                    <Controller
                                                                      name={`company_applicant_item_${l}`}
                                                                      control={
                                                                        control
                                                                      }
                                                                      render={({
                                                                        field,
                                                                      }) => (
                                                                        <Dropdown
                                                                          value={
                                                                            field.value
                                                                          }
                                                                          options={
                                                                            allCompanyApplicant
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            field.onChange(
                                                                              e.value
                                                                            );

                                                                            handleCompanyApplicantChange(
                                                                              e.value,
                                                                              l
                                                                            );
                                                                          }}
                                                                          optionLabel="firstName"
                                                                          style={{
                                                                            width:
                                                                              "100%",
                                                                            height:
                                                                              "47px",
                                                                          }}
                                                                          filter
                                                                          resetFilterOnHide
                                                                          className="dialog-form-input-field-wizard"
                                                                          required
                                                                        />
                                                                      )}
                                                                    />
                                                                  </span>

                                                                  <small
                                                                    id={`"Company_Applicant_Information"-${l + 1
                                                                      }--error`}
                                                                    className="error-msg font-fam-for-all"
                                                                  ></small>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center mt-3">
                                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                                                                                                                           <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                                                                                                                               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                                                                                                                   <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                                                                                                               </svg>
                                                                                                                                                                                                                               <div className='font-medium'>Add Debtor</div>
                                                                                                                                                                                                                           </div>
                                                                                                       
                                                                                                                                                                                                                       </button> */}
                                                              <Button
                                                                type="button"
                                                                icon="pi pi-plus"
                                                                style={{
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                                label="Add Company Applicant"
                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                onClick={() =>
                                                                  handleBOIKeyPersonal(
                                                                    "COMPANY APPLICANT",
                                                                    `company_applicant_item_${l}`
                                                                  )
                                                                }
                                                              ></Button>
                                                            </div>
                                                          </div>
                                                          <div>
                                                            {getValues(
                                                              `company_applicant_item_${l}`
                                                            ) && (
                                                                <BeneficilaCard
                                                                  data={getValues(
                                                                    `company_applicant_item_${l}`
                                                                  )}
                                                                  type={
                                                                    "Company Applicant"
                                                                  }
                                                                />
                                                              )}
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {key ===
                                                        "ucc_Secured_Party_Details" ? (
                                                        <div className="grid w-full justify-content-between">
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                              <div className="field">
                                                                <label
                                                                  htmlFor=""
                                                                  className="form-label font-fam-for-all"
                                                                >
                                                                  Select
                                                                  Additional
                                                                  SecuredParty
                                                                  <span className="form-field-mandatory">
                                                                    *
                                                                  </span>
                                                                </label>
                                                                <span className=" ">
                                                                  <Controller
                                                                    name={`uccAmend_Additional_secured_party_Details-${l}`}
                                                                    control={
                                                                      control
                                                                    }
                                                                    render={({
                                                                      field,
                                                                    }) => (
                                                                      <Dropdown
                                                                        value={
                                                                          field.value
                                                                        }
                                                                        options={
                                                                          allSecuredParty
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) => {
                                                                          field.onChange(
                                                                            e.value
                                                                          );

                                                                          handleAdditionalSecuredPartyChange1(
                                                                            e.value,
                                                                            l
                                                                          );
                                                                        }}
                                                                        optionLabel="keyPersonnelName"
                                                                        style={{
                                                                          width:
                                                                            "100%",
                                                                          height:
                                                                            "47px",
                                                                        }}
                                                                        filter
                                                                        resetFilterOnHide
                                                                        className="dialog-form-input-field-wizard"
                                                                        required
                                                                      />
                                                                    )}
                                                                  />
                                                                </span>

                                                                <small
                                                                  id={`"ucc_Secured_Party_Details"-${l + 1
                                                                    }--error`}
                                                                  className="error-msg font-fam-for-all"
                                                                ></small>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center mt-3">
                                                            {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("SECUERED_PARTY")}>
                                                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                </svg>
                                                                                                                <div className='font-medium'>Add Additional Secured Party</div>
                                                                                                            </div>

                                                                                                        </button> */}
                                                            <Button
                                                              type="button"
                                                              icon="pi pi-plus"
                                                              style={{
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                              label="Add Additional Secured Party"
                                                              className=" vstate-button font-fam-for-all text-center mt-4"
                                                              onClick={() =>
                                                                handleKeyPersonal(
                                                                  "SECUERED PARTY",
                                                                  `uccAmend_Additional_secured_party_Details-${l}`
                                                                )
                                                              }
                                                            ></Button>
                                                          </div>
                                                          <div>
                                                            {getValues(
                                                              `uccAmend_Additional_secured_party_Details-${l}`
                                                            ) && (
                                                                <KeyPersonnelIndividualCard
                                                                  data={getValues(
                                                                    `uccAmend_Additional_secured_party_Details-${l}`
                                                                  )}
                                                                  getState={
                                                                    getState
                                                                  }
                                                                  type={
                                                                    "Secured Party"
                                                                  }
                                                                />
                                                              )}
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {groupedData[
                                                        key
                                                      ].form.map((value, j) => (
                                                        <>
                                                          <div>
                                                            {getValues(
                                                              "COC_What_Are_We_Changing"
                                                            ) === "Address" &&
                                                              value.apiId ===
                                                              "address" ? (
                                                              <div className="vstate-card p-4 mb-3">
                                                                <div
                                                                  className="grid"
                                                                  key={l}
                                                                >
                                                                  {Object.keys(
                                                                    value.schema
                                                                      .attributes
                                                                  ).map(
                                                                    (
                                                                      val,
                                                                      k
                                                                    ) => (
                                                                      <div
                                                                        className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 dummy ${!renderFormField(
                                                                          value
                                                                            .schema
                                                                            .attributes[
                                                                          val
                                                                          ],
                                                                          val,
                                                                          `${val}-${l}`,
                                                                          value,
                                                                          key
                                                                        )
                                                                            ? "hidden"
                                                                            : ""
                                                                          }`}
                                                                      >
                                                                        {value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ]
                                                                          .type !==
                                                                          undefined
                                                                          ? renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                          : ""}
                                                                      </div>
                                                                    )
                                                                  )}
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {getValues(
                                                              `boi_Exempt_Entity-${l}`
                                                            ) === undefined &&
                                                              value.apiId !==
                                                              "address" &&
                                                              value.apiId !==
                                                              "boi-beneficial-owwner-details" &&
                                                              value.apiId !==
                                                              "boi-except-entity-form" &&
                                                              value.apiId !==
                                                              "boi-beneficial-owner-form" &&
                                                              value.apiId !==
                                                              "ucc-amnd-additional-debtor" &&
                                                              value.apiId !==
                                                              "boica-fin-cen-id" &&
                                                              value.apiId !==
                                                              "company-applicant-det" &&
                                                              value.apiId !==
                                                              "cad-address" &&
                                                              value.apiId !==
                                                              "boica-upload-document" ? (
                                                              <div
                                                                className="vstate-card p-4 mb-3"
                                                                style={
                                                                  keyPersonalMemberData.includes(
                                                                    key
                                                                  ) ||
                                                                    keyPersonalMemberData.includes(
                                                                      value.apiId
                                                                    )
                                                                    ? {
                                                                      display:
                                                                        "none",
                                                                    }
                                                                    : {}
                                                                }
                                                              >
                                                                <div
                                                                  className="grid"
                                                                  key={l}
                                                                >
                                                                  {Object.keys(
                                                                    value.schema
                                                                      .attributes
                                                                  ).map(
                                                                    (
                                                                      val,
                                                                      k
                                                                    ) => {
                                                                      const content =
                                                                        value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ]
                                                                          .type !==
                                                                          undefined
                                                                          ? renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                          : null;

                                                                      return content ? (
                                                                        <div
                                                                          className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"
                                                                          key={
                                                                            k
                                                                          }
                                                                        >
                                                                          {
                                                                            content
                                                                          }
                                                                        </div>
                                                                      ) : null;
                                                                    }
                                                                  )}
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {getValues(
                                                              `boi_Exempt_Entity-${l}`
                                                            ) === undefined &&
                                                              value.apiId ===
                                                              "address" &&
                                                              value.apiId !==
                                                              "boi-beneficial-owwner-details" &&
                                                              value.apiId !==
                                                              "boi-except-entity-form" &&
                                                              value.apiId !==
                                                              "boi-beneficial-owner-form" ? (
                                                              <div
                                                                className="vstate-card p-4 mb-3"
                                                                style={
                                                                  keyPersonalMemberData.includes(
                                                                    key
                                                                  ) ||
                                                                    keyPersonalMemberData.includes(
                                                                      value.apiId
                                                                    )
                                                                    ? {
                                                                      display:
                                                                        "none",
                                                                    }
                                                                    : {}
                                                                }
                                                              >
                                                                <div
                                                                  className="grid"
                                                                  key={l}
                                                                >
                                                                  {Object.keys(
                                                                    value.schema
                                                                      .attributes
                                                                  ).map(
                                                                    (
                                                                      val,
                                                                      k
                                                                    ) => {
                                                                      const content =
                                                                        value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ]
                                                                          .type !==
                                                                          undefined
                                                                          ? renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                          : null;

                                                                      return content ? (
                                                                        <div
                                                                          className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"
                                                                          key={
                                                                            k
                                                                          }
                                                                        >
                                                                          {
                                                                            content
                                                                          }
                                                                        </div>
                                                                      ) : null;
                                                                    }
                                                                  )}
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {value.apiId ===
                                                              "beneficial-owner-info" ? (
                                                              <>
                                                                <div className="grid w-full justify-content-between">
                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                                    <div className=" justify-content-center dialog-form-field-space w-full">
                                                                      <div className="field">
                                                                        <label
                                                                          htmlFor=""
                                                                          className="form-label font-fam-for-all"
                                                                        >
                                                                          Select
                                                                          Beneficial
                                                                          Owner
                                                                          <span className="form-field-mandatory"></span>
                                                                        </label>
                                                                        <span className=" ">
                                                                          <Controller
                                                                            name={`ucc_Beneficial_Dropdown-${l}`}
                                                                            control={
                                                                              control
                                                                            }
                                                                            render={({
                                                                              field,
                                                                            }) => (
                                                                              <Dropdown
                                                                                id={`Beneficial_Owner_Information"-${l +
                                                                                  1
                                                                                  }`}
                                                                                value={
                                                                                  field.value
                                                                                }
                                                                                options={
                                                                                  allBeneficialOwner
                                                                                }
                                                                                onChange={(
                                                                                  e
                                                                                ) => {
                                                                                  field.onChange(
                                                                                    e.value
                                                                                  );
                                                                                  handleBeneficialOwnerChange(
                                                                                    e.value,
                                                                                    l
                                                                                  );
                                                                                }}
                                                                                optionLabel="firstName"
                                                                                style={{
                                                                                  width:
                                                                                    "100%",
                                                                                  height:
                                                                                    "47px",
                                                                                }}
                                                                                filter
                                                                                resetFilterOnHide
                                                                                className="dialog-form-input-field-wizard"
                                                                                required
                                                                              />
                                                                            )}
                                                                          />
                                                                        </span>

                                                                        <small
                                                                          id={`Beneficial_Owner_Information"-${l +
                                                                            1
                                                                            }--error`}
                                                                          className="error-msg font-fam-for-all"
                                                                        ></small>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center mt-3">
                                                                    <Button
                                                                      type="button"
                                                                      icon="pi pi-plus"
                                                                      style={{
                                                                        fontSize:
                                                                          "14px",
                                                                      }}
                                                                      label="Add Beneficial Owner"
                                                                      className=" vstate-button font-fam-for-all text-center mt-4"
                                                                      onClick={() =>
                                                                        handleBOIKeyPersonal(
                                                                          "BENEFICIAL OWNER",
                                                                          `ucc_Beneficial_Dropdown-${l}`
                                                                        )
                                                                      }
                                                                    ></Button>
                                                                  </div>
                                                                </div>
                                                                <div>
                                                                  {getValues(
                                                                    `ucc_Beneficial_Dropdown-${l}`
                                                                  ) !==
                                                                    undefined && (
                                                                      <BeneficilaCard
                                                                        data={getValues(
                                                                          `ucc_Beneficial_Dropdown-${l}`
                                                                        )}
                                                                        type={
                                                                          "Beneficial Owner"
                                                                        }
                                                                      />
                                                                    )}
                                                                </div>
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {value.apiId ===
                                                              "beneficial-owner-info" &&
                                                              value.apiId !==
                                                              "upload-documents" &&
                                                              value.apiId !==
                                                              "boi-except-entity-form" &&
                                                              value.apiId !==
                                                              "beneficial-owner-details" &&
                                                              value.apiId !==
                                                              "address" ? (
                                                              <div className="vstate-card p-4 mb-3 vstate-visbiliy-hidden">
                                                                <div
                                                                  className="grid"
                                                                  key={l}
                                                                >
                                                                  {Object.keys(
                                                                    value.schema
                                                                      .attributes
                                                                  ).map(
                                                                    (
                                                                      val,
                                                                      k
                                                                    ) => {
                                                                      const content =
                                                                        value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ]
                                                                          .type !==
                                                                          undefined
                                                                          ? renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                          : null;

                                                                      return content ? (
                                                                        <div
                                                                          className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"
                                                                          key={
                                                                            k
                                                                          }
                                                                        >
                                                                          {
                                                                            content
                                                                          }
                                                                        </div>
                                                                      ) : null;
                                                                    }
                                                                  )}
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}
                                                            {getValues(
                                                              `boica_Have_a_FinCEN_ID-${l}`
                                                            ) === undefined &&
                                                              (value.apiId ===
                                                                "boica-fin-cen-id" ||
                                                                value.apiId ===
                                                                "company-applicant-det" ||
                                                                value.apiId ===
                                                                "cad-address" ||
                                                                value.apiId ===
                                                                "boica-upload-document") ? (
                                                              <div className="vstate-card p-4 mb-3 vstate-visbiliy-hidden">
                                                                <div
                                                                  className="grid"
                                                                  key={l}
                                                                >
                                                                  {Object.keys(
                                                                    value.schema
                                                                      .attributes
                                                                  ).map(
                                                                    (
                                                                      val,
                                                                      k
                                                                    ) => {
                                                                      const content =
                                                                        value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ]
                                                                          .type !==
                                                                          undefined
                                                                          ? renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                          : null;

                                                                      return content ? (
                                                                        <div
                                                                          className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"
                                                                          key={
                                                                            k
                                                                          }
                                                                        >
                                                                          {
                                                                            content
                                                                          }
                                                                        </div>
                                                                      ) : null;
                                                                    }
                                                                  )}
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {getValues(
                                                              `boica_Have_a_FinCEN_ID-${l}`
                                                            ) === false &&
                                                              (value.apiId ===
                                                                "boica-fin-cen-id" ||
                                                                value.apiId ===
                                                                "company-applicant-det" ||
                                                                value.apiId ===
                                                                "cad-address" ||
                                                                value.apiId ===
                                                                "boica-upload-document") ? (
                                                              <div className="vstate-card p-4 mb-3 vstate-visbiliy-hidden">
                                                                <div
                                                                  className="grid"
                                                                  key={l}
                                                                >
                                                                  {Object.keys(
                                                                    value.schema
                                                                      .attributes
                                                                  ).map(
                                                                    (
                                                                      val,
                                                                      k
                                                                    ) => {
                                                                      const content =
                                                                        value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ]
                                                                          .type !==
                                                                          undefined
                                                                          ? renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                          : null;

                                                                      return content ? (
                                                                        <div
                                                                          className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"
                                                                          key={
                                                                            k
                                                                          }
                                                                        >
                                                                          {
                                                                            content
                                                                          }
                                                                        </div>
                                                                      ) : null;
                                                                    }
                                                                  )}
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {getValues(
                                                              `boica_Have_a_FinCEN_ID-${l}`
                                                            ) === true &&
                                                              (value.apiId ===
                                                                "boica-fin-cen-id" ||
                                                                value.apiId ===
                                                                "company-applicant-det") ? (
                                                              <div className="vstate-card p-4 mb-3 vstate-visbiliy-hidden">
                                                                <div
                                                                  className="grid"
                                                                  key={l}
                                                                >
                                                                  {Object.keys(
                                                                    value.schema
                                                                      .attributes
                                                                  ).map(
                                                                    (
                                                                      val,
                                                                      k
                                                                    ) => {
                                                                      const content =
                                                                        value
                                                                          .schema
                                                                          .attributes[
                                                                          val
                                                                        ]
                                                                          .type !==
                                                                          undefined
                                                                          ? renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                          : null;

                                                                      return content ? (
                                                                        <div
                                                                          className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"
                                                                          key={
                                                                            k
                                                                          }
                                                                        >
                                                                          {
                                                                            content
                                                                          }
                                                                        </div>
                                                                      ) : null;
                                                                    }
                                                                  )}
                                                                </div>
                                                              </div>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {/* boi-beneficial-owwner-details for Exempt Entity true */}

                                                            {getValues(
                                                              `boi_Exempt_Entity-${l}`
                                                            ) === false &&
                                                              getValues(
                                                                `boi_Have_a_FinCEN_ID-${l}`
                                                              ) === false &&
                                                              (value.apiId ===
                                                                "beneficial-owner-details" ||
                                                                value.apiId ===
                                                                "upload-documents" ||
                                                                value.apiId ===
                                                                "address" ||
                                                                value.apiId ===
                                                                "fin-cen-id-details") ? (
                                                              <>
                                                                <div className="vstate-card p-4 mb-3 vstate-visbiliy-hidden">
                                                                  <div
                                                                    className="grid"
                                                                    key={j}
                                                                  >
                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0">
                                                                      <h2 className="entity-header-strapi">
                                                                        {toPascalCase(
                                                                          value
                                                                            .schema
                                                                            .displayName
                                                                        )}
                                                                      </h2>
                                                                    </div>

                                                                    {Object.keys(
                                                                      value
                                                                        .schema
                                                                        .attributes
                                                                    ).map(
                                                                      (
                                                                        val,
                                                                        k
                                                                      ) => (
                                                                        <div
                                                                          className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 dummy ${!renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                              ? "hidden"
                                                                              : ""
                                                                            }`}
                                                                          key={
                                                                            k
                                                                          }
                                                                        >
                                                                          {value
                                                                            .schema
                                                                            .attributes[
                                                                            val
                                                                          ]
                                                                            .type !==
                                                                            undefined
                                                                            ? renderFormField(
                                                                              value
                                                                                .schema
                                                                                .attributes[
                                                                              val
                                                                              ],
                                                                              val,
                                                                              `${val}-${l}`,
                                                                              value,
                                                                              key
                                                                            )
                                                                            : ""}
                                                                        </div>
                                                                      )
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {getValues(
                                                              `boi_Exempt_Entity-${l}`
                                                            ) === false &&
                                                              getValues(
                                                                `boi_Have_a_FinCEN_ID-${l}`
                                                              ) === true &&
                                                              (value.apiId ===
                                                                "beneficial-owner-details" ||
                                                                value.apiId ===
                                                                "fin-cen-id-details") ? (
                                                              <>
                                                                <div className="vstate-card p-4 mb-3 vstate-visbiliy-hidden">
                                                                  <div
                                                                    className="grid"
                                                                    key={j}
                                                                  >
                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0">
                                                                      <h2 className="entity-header-strapi">
                                                                        {toPascalCase(
                                                                          value
                                                                            .schema
                                                                            .displayName
                                                                        )}
                                                                      </h2>
                                                                    </div>

                                                                    {Object.keys(
                                                                      value
                                                                        .schema
                                                                        .attributes
                                                                    ).map(
                                                                      (
                                                                        val,
                                                                        k
                                                                      ) => (
                                                                        <div
                                                                          className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 dummy ${!renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                              ? "hidden"
                                                                              : ""
                                                                            }`}
                                                                          key={
                                                                            k
                                                                          }
                                                                        >
                                                                          {value
                                                                            .schema
                                                                            .attributes[
                                                                            val
                                                                          ]
                                                                            .type !==
                                                                            undefined
                                                                            ? renderFormField(
                                                                              value
                                                                                .schema
                                                                                .attributes[
                                                                              val
                                                                              ],
                                                                              val,
                                                                              `${val}-${l}`,
                                                                              value,
                                                                              key
                                                                            )
                                                                            : ""}
                                                                        </div>
                                                                      )
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {/* boi-beneficial-owwner-details for Exempt Entity false */}
                                                            {getValues(
                                                              `boi_Exempt_Entity-${l}`
                                                            ) &&
                                                              value.apiId ===
                                                              "boi-except-entity-form" ? (
                                                              <>
                                                                <div className="vstate-card p-4 mb-3 vstate-visbiliy-hidden">
                                                                  <div
                                                                    className="grid"
                                                                    key={j}
                                                                  >
                                                                    <div className="col-12 pb-0">
                                                                      <h2 className="entity-header-strapi">
                                                                        {toPascalCase(
                                                                          value
                                                                            .schema
                                                                            .displayName
                                                                        )}
                                                                      </h2>
                                                                    </div>
                                                                    {Object.keys(
                                                                      value
                                                                        .schema
                                                                        .attributes
                                                                    ).map(
                                                                      (
                                                                        val,
                                                                        k
                                                                      ) => (
                                                                        <div
                                                                          className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 dummy ${!renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                              ? "hidden"
                                                                              : ""
                                                                            }`}
                                                                          key={
                                                                            k
                                                                          }
                                                                        >
                                                                          {value
                                                                            .schema
                                                                            .attributes[
                                                                            val
                                                                          ]
                                                                            .type !==
                                                                            undefined
                                                                            ? renderFormField(
                                                                              value
                                                                                .schema
                                                                                .attributes[
                                                                              val
                                                                              ],
                                                                              val,
                                                                              `${val}-${l}`,
                                                                              value,
                                                                              key
                                                                            )
                                                                            : ""}
                                                                        </div>
                                                                      )
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {getValues(
                                                              "uccAmend_What_are_we_amending"
                                                            ) ===
                                                              "ADDITIONAL DEBTOR" &&
                                                              value.apiId ===
                                                              "ucc-amnd-additional-debtor" ? (
                                                              <>
                                                                <div className="vstate-card p-4 mb-3 vstate-visbiliy-hidden">
                                                                  <div
                                                                    className="grid"
                                                                    key={j}
                                                                  >
                                                                    <div className="col-12 pb-0">
                                                                      <h2 className="entity-header-strapi">
                                                                        {toPascalCase(
                                                                          value
                                                                            .schema
                                                                            .displayName
                                                                        )}
                                                                      </h2>
                                                                    </div>
                                                                    <div className="grid w-full justify-content-between">
                                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                                        <div className=" justify-content-center dialog-form-field-space w-full">
                                                                          <div className="field">
                                                                            <label
                                                                              htmlFor=""
                                                                              className="form-label font-fam-for-all"
                                                                            >
                                                                              Select
                                                                              Additional
                                                                              Debtor
                                                                              <span className="form-field-mandatory">
                                                                                *
                                                                              </span>
                                                                            </label>
                                                                            <span className=" ">
                                                                              <Controller
                                                                                name={`uccAmend_Additional_Debtor_Details-${l}`}
                                                                                control={
                                                                                  control
                                                                                }
                                                                                render={({
                                                                                  field,
                                                                                }) => (
                                                                                  <Dropdown
                                                                                    value={
                                                                                      field.value
                                                                                    }
                                                                                    options={
                                                                                      aallDebtor
                                                                                    }
                                                                                    onChange={(
                                                                                      e
                                                                                    ) => {
                                                                                      field.onChange(
                                                                                        e.value
                                                                                      );

                                                                                      handleAdditionalDebtorChange(
                                                                                        e.value,
                                                                                        l
                                                                                      );
                                                                                    }}
                                                                                    optionLabel="keyPersonnelName"
                                                                                    placeholder="Select Debtor"
                                                                                    style={{
                                                                                      width:
                                                                                        "100%",
                                                                                      height:
                                                                                        "47px",
                                                                                    }}
                                                                                    filter
                                                                                    resetFilterOnHide
                                                                                    className="dialog-form-input-field-wizard"
                                                                                    required
                                                                                  />
                                                                                )}
                                                                              />
                                                                            </span>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center mt-3">
                                                                        {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                                        </svg>
                                                                                                                                                        <div className='font-medium'>Add Additional Debtor</div>
                                                                                                                                                    </div>

                                                                                                                                                </button> */}
                                                                        <Button
                                                                          type="button"
                                                                          icon="pi pi-plus"
                                                                          style={{
                                                                            fontSize:
                                                                              "14px",
                                                                          }}
                                                                          label="Add Additionaal Debtor"
                                                                          className=" vstate-button font-fam-for-all text-center mt-4"
                                                                          onClick={() =>
                                                                            handleKeyPersonal(
                                                                              "DEBTOR"
                                                                            )
                                                                          }
                                                                        ></Button>
                                                                      </div>
                                                                    </div>
                                                                    {Object.keys(
                                                                      value
                                                                        .schema
                                                                        .attributes
                                                                    ).map(
                                                                      (
                                                                        val,
                                                                        k
                                                                      ) => (
                                                                        <div
                                                                          className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 dummy ${!renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                              ? "hidden"
                                                                              : ""
                                                                            }`}
                                                                          key={
                                                                            k
                                                                          }
                                                                        >
                                                                          {value
                                                                            .schema
                                                                            .attributes[
                                                                            val
                                                                          ]
                                                                            .type !==
                                                                            undefined
                                                                            ? renderFormField(
                                                                              value
                                                                                .schema
                                                                                .attributes[
                                                                              val
                                                                              ],
                                                                              val,
                                                                              `${val}-${l}`,
                                                                              value,
                                                                              key
                                                                            )
                                                                            : ""}
                                                                        </div>
                                                                      )
                                                                    )}
                                                                  </div>
                                                                </div>
                                                                <div className="grid w-full">
                                                                  {getValues(
                                                                    `uccAmend_Additional_Debtor_Details-${l}`
                                                                  ) && (
                                                                      <KeyPersonnelIndividualCard
                                                                        data={getValues(
                                                                          `uccAmend_Additional_Debtor_Details-${l}`
                                                                        )}
                                                                        getState={
                                                                          getState
                                                                        }
                                                                        type={
                                                                          "Debtor"
                                                                        }
                                                                      />
                                                                    )}
                                                                </div>
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}

                                                            {getValues(
                                                              "uccAmend_What_are_we_amending"
                                                            ) ===
                                                              "ADDITIONAL SECURED" &&
                                                              value.apiId ===
                                                              "ucc-amend-additional-secured-party-details" ? (
                                                              <>
                                                                <div className="vstate-card p-4 mb-3 vstate-visbiliy-hidden">
                                                                  <div
                                                                    className="grid"
                                                                    key={j}
                                                                  >
                                                                    <div className="col-12 pb-0">
                                                                      <h2 className="entity-header-strapi">
                                                                        {toPascalCase(
                                                                          value
                                                                            .schema
                                                                            .displayName
                                                                        )}
                                                                      </h2>
                                                                    </div>
                                                                    {Object.keys(
                                                                      value
                                                                        .schema
                                                                        .attributes
                                                                    ).map(
                                                                      (
                                                                        val,
                                                                        k
                                                                      ) => (
                                                                        <div
                                                                          className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 dummy ${!renderFormField(
                                                                            value
                                                                              .schema
                                                                              .attributes[
                                                                            val
                                                                            ],
                                                                            val,
                                                                            `${val}-${l}`,
                                                                            value,
                                                                            key
                                                                          )
                                                                              ? "hidden"
                                                                              : ""
                                                                            }`}
                                                                          key={
                                                                            k
                                                                          }
                                                                        >
                                                                          {value
                                                                            .schema
                                                                            .attributes[
                                                                            val
                                                                          ]
                                                                            .type !==
                                                                            undefined
                                                                            ? renderFormField(
                                                                              value
                                                                                .schema
                                                                                .attributes[
                                                                              val
                                                                              ],
                                                                              val,
                                                                              `${val}-${l}`,
                                                                              value,
                                                                              key
                                                                            )
                                                                            : ""}
                                                                        </div>
                                                                      )
                                                                    )}
                                                                  </div>
                                                                </div>
                                                              </>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </div>
                                                        </>
                                                      ))}
                                                    </AccordionTab>
                                                  </Accordion>
                                                </>
                                              ))}
                                            </div>

                                            {/* {groupedData[key].mode &&
                                              groupedData[key].mode.repeat ===
                                              true && (
                                                <div className="flex gap-2 justify-content-center w-full">
                                                  <Button
                                                    type="button"
                                                    icon="pi pi-plus"
                                                    disabled={
                                                      maxValue &&
                                                      key ===
                                                      "Beneficial_Owner_Information" &&
                                                      (formCounts[key] || 1) >=
                                                      maxValue
                                                    }
                                                    label="Add Entry"
                                                    className="vstate-button font-fam-for-all text-center justify-content-center"
                                                    onClick={() =>
                                                      handleRepeatForm(key)
                                                    }
                                                  />
                                                  <Button
                                                    type="button"
                                                    icon="pi pi-minus"
                                                    disabled={
                                                      (maxValue &&
                                                        key ===
                                                        "Beneficial_Owner_Information" &&
                                                        (formCounts[key] ||
                                                          1) === minValue) ||
                                                      (formCounts[key] || 1) ===
                                                      1
                                                    }
                                                    label="Remove Entry"
                                                    className="vstate-button font-fam-for-all text-center justify-content-center"
                                                    onClick={() =>
                                                      handleRemoveRepeatForm(
                                                        key
                                                      )
                                                    }
                                                  />
                                                </div>
                                              )} */}
                                          </div>
                                        ) : (
                                          groupedData[key].form.map(
                                            (value, j) => (
                                              <>
                                                {getValues(
                                                  "uccAmend_What_are_we_amending"
                                                ) === "ADDITIONAL DEBTOR" &&
                                                  value.apiId ===
                                                  "ucc-amnd-additional-debtor" &&
                                                  value.apiId !==
                                                  "uucAmnd-What-are-amending"
                                                  ? Array.from({
                                                    length:
                                                      formCounts[key] - 1,
                                                  }).map((_, l) => (
                                                    <>
                                                      <div className="vstate-card p-4 mb-3 vstate-visbiliy-hidden">
                                                        <div
                                                          className="grid"
                                                          key={j}
                                                        >
                                                          <div className="col-12 pb-0">
                                                            <h2 className="entity-header-strapi">
                                                              {toPascalCase(
                                                                value.schema
                                                                  .displayName
                                                              )}
                                                            </h2>
                                                          </div>
                                                          <div className="grid w-full justify-content-between">
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                                <div className="field">
                                                                  <label
                                                                    htmlFor=""
                                                                    className="form-label font-fam-for-all"
                                                                  >
                                                                    Select
                                                                    Additional
                                                                    Debtor
                                                                    <span className="form-field-mandatory">
                                                                      *
                                                                    </span>
                                                                  </label>
                                                                  <span className=" ">
                                                                    <Controller
                                                                      name={`uccAmend_Additional_Debtor_Details-${l}`}
                                                                      control={
                                                                        control
                                                                      }
                                                                      render={({
                                                                        field,
                                                                      }) => (
                                                                        <Dropdown
                                                                          value={
                                                                            field.value
                                                                          }
                                                                          options={
                                                                            aallDebtor
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            field.onChange(
                                                                              e.value
                                                                            );

                                                                            handleAdditionalDebtorChange(
                                                                              e.value,
                                                                              l
                                                                            );
                                                                          }}
                                                                          optionLabel="keyPersonnelName"
                                                                          placeholder="Select Debtor"
                                                                          style={{
                                                                            width:
                                                                              "100%",
                                                                            height:
                                                                              "47px",
                                                                          }}
                                                                          filter
                                                                          resetFilterOnHide
                                                                          className="dialog-form-input-field-wizard"
                                                                          required
                                                                        />
                                                                      )}
                                                                    />
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center mt-3">
                                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                        </svg>
                                                                                                                        <div className='font-medium'>Add Additional Debtor</div>
                                                                                                                    </div>

                                                                                                                </button> */}
                                                              <Button
                                                                type="button"
                                                                icon="pi pi-plus"
                                                                style={{
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                                label="Add Additional Debtor"
                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                onClick={() =>
                                                                  handleKeyPersonal(
                                                                    "DEBTOR"
                                                                  )
                                                                }
                                                              ></Button>
                                                            </div>
                                                          </div>
                                                          <div className="grid w-full">
                                                            {getValues(
                                                              `uccAmend_Additional_Debtor_Details-${l}`
                                                            ) && (
                                                                <KeyPersonnelIndividualCard
                                                                  data={getValues(
                                                                    `uccAmend_Additional_Debtor_Details-${l}`
                                                                  )}
                                                                  getState={
                                                                    getState
                                                                  }
                                                                  type={
                                                                    "Debtor"
                                                                  }
                                                                />
                                                              )}
                                                          </div>
                                                          {Object.keys(
                                                            value.schema
                                                              .attributes
                                                          ).map((val, k) => (
                                                            <div
                                                              className={`vstate-visbiliy-hidden col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 dummy ${!renderFormField(
                                                                value.schema
                                                                  .attributes[
                                                                val
                                                                ],
                                                                val,
                                                                `${val}-${l}`,
                                                                value,
                                                                key
                                                              )
                                                                  ? "hidden"
                                                                  : ""
                                                                }`}
                                                              key={k}
                                                            >
                                                              {value.schema
                                                                .attributes[
                                                                val
                                                              ].type !==
                                                                undefined
                                                                ? renderFormField(
                                                                  value
                                                                    .schema
                                                                    .attributes[
                                                                  val
                                                                  ],
                                                                  val,
                                                                  `${val}-${l}`,
                                                                  value,
                                                                  key,
                                                                  value.apiId
                                                                )
                                                                : ""}
                                                            </div>
                                                          ))}
                                                        </div>
                                                      </div>

                                                      {/* <div className="flex gap-2 justify-content-center w-full">
                                                        <Button
                                                          type="button"
                                                          icon="pi pi-plus"
                                                          disabled={
                                                            groupedData[key]
                                                              .form.length >
                                                            0 &&
                                                            groupedData[key]
                                                              .form[0]
                                                              .apiId ===
                                                            "boi-company-applicant-details" &&
                                                            (formCounts[
                                                              key
                                                            ] || 1) >= 2
                                                          }
                                                          label="Add Entry"
                                                          className="vstate-button font-fam-for-all text-center justify-content-center"
                                                          onClick={() =>
                                                            handleRepeatForm(
                                                              key
                                                            )
                                                          }
                                                        />
                                                        <Button
                                                          type="button"
                                                          icon="pi pi-minus"
                                                          disabled={
                                                            (formCounts[
                                                              key
                                                            ] || 1) === 1
                                                          }
                                                          label="Remove Entry"
                                                          className="vstate-button font-fam-for-all text-center justify-content-center"
                                                          onClick={() =>
                                                            handleRemoveRepeatForm(
                                                              key
                                                            )
                                                          }
                                                        />
                                                      </div> */}
                                                    </>
                                                  ))
                                                  : ""}

                                                {getValues(
                                                  "uccAmend_What_are_we_amending"
                                                ) === "ADDITIONAL SECURED" &&
                                                  value.apiId ===
                                                  "ucc-amend-additional-secured-party-details" &&
                                                  value.apiId !==
                                                  "uucAmnd-What-are-amending"
                                                  ? Array.from({
                                                    length:
                                                      formCounts[key] - 1,
                                                  }).map((_, l) => (
                                                    <>
                                                      <div className="vstate-card p-4 mb-3 vstate-visbiliy-hidden">
                                                        <div
                                                          className="grid"
                                                          key={j}
                                                        >
                                                          <div className="col-12 pb-0">
                                                            <h2 className="entity-header-strapi">
                                                              {toPascalCase(
                                                                value.schema
                                                                  .displayName
                                                              )}
                                                            </h2>
                                                          </div>
                                                          <div className="grid w-full justify-content-between">
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                                <div className="field">
                                                                  <label
                                                                    htmlFor=""
                                                                    className="form-label font-fam-for-all"
                                                                  >
                                                                    Select
                                                                    Additional
                                                                    SecuredParty
                                                                    <span className="form-field-mandatory">
                                                                      *
                                                                    </span>
                                                                  </label>
                                                                  <span className=" ">
                                                                    <Controller
                                                                      name={`uccAmend_Additional_secured_party_Details-${l}`}
                                                                      control={
                                                                        control
                                                                      }
                                                                      render={({
                                                                        field,
                                                                      }) => (
                                                                        <Dropdown
                                                                          value={
                                                                            field.value
                                                                          }
                                                                          options={
                                                                            allSecuredParty
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) => {
                                                                            field.onChange(
                                                                              e.value
                                                                            );

                                                                            handleAdditionalSecuredPartyChange(
                                                                              e.value,
                                                                              l
                                                                            );
                                                                          }}
                                                                          optionLabel="keyPersonnelName"
                                                                          placeholder="Select Secured Party"
                                                                          style={{
                                                                            width:
                                                                              "100%",
                                                                            height:
                                                                              "47px",
                                                                          }}
                                                                          filter
                                                                          resetFilterOnHide
                                                                          className="dialog-form-input-field-wizard"
                                                                          required
                                                                        />
                                                                      )}
                                                                    />
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center mt-3">
                                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("SECUERED_PARTY")}>
                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                        </svg>
                                                                                                                        <div className='font-medium'>Add Additional Secured Party</div>
                                                                                                                    </div>

                                                                                                                </button> */}
                                                              <Button
                                                                type="button"
                                                                icon="pi pi-plus"
                                                                style={{
                                                                  fontSize:
                                                                    "14px",
                                                                }}
                                                                label="Add Additional Secured Party"
                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                onClick={() =>
                                                                  handleKeyPersonal(
                                                                    "SECUERED PARTY"
                                                                  )
                                                                }
                                                              ></Button>
                                                            </div>
                                                          </div>
                                                          {Object.keys(
                                                            value.schema
                                                              .attributes
                                                          ).map((val, k) => (
                                                            <div
                                                              className={`vstate-visbiliy-hidden col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 dummy ${!renderFormField(
                                                                value.schema
                                                                  .attributes[
                                                                val
                                                                ],
                                                                val,
                                                                `${val}-${l}`,
                                                                value,
                                                                key
                                                              )
                                                                  ? "hidden"
                                                                  : ""
                                                                }`}
                                                              key={k}
                                                            >
                                                              {value.schema
                                                                .attributes[
                                                                val
                                                              ].type !==
                                                                undefined
                                                                ? renderFormField(
                                                                  value
                                                                    .schema
                                                                    .attributes[
                                                                  val
                                                                  ],
                                                                  val,
                                                                  `${val}-${l}`,
                                                                  value,
                                                                  key,
                                                                  value.apiId
                                                                )
                                                                : ""}
                                                            </div>
                                                          ))}
                                                          <div className="grid w-full">
                                                            {getValues(
                                                              `uccAmend_Additional_secured_party_Details-${l}`
                                                            ) && (
                                                                <KeyPersonnelIndividualCard
                                                                  data={getValues(
                                                                    `uccAmend_Additional_secured_party_Details-${l}`
                                                                  )}
                                                                  getState={
                                                                    getState
                                                                  }
                                                                  type={
                                                                    "Secured Party"
                                                                  }
                                                                />
                                                              )}
                                                          </div>
                                                        </div>
                                                      </div>

                                                      {/* <div className="flex gap-2 justify-content-center w-full">
                                                        <Button
                                                          type="button"
                                                          icon="pi pi-plus"
                                                          disabled={
                                                            groupedData[key]
                                                              .form.length >
                                                            0 &&
                                                            groupedData[key]
                                                              .form[0]
                                                              .apiId ===
                                                            "boi-company-applicant-details" &&
                                                            (formCounts[
                                                              key
                                                            ] || 1) >= 2
                                                          }
                                                          label="Add Entry"
                                                          className="vstate-button font-fam-for-all text-center justify-content-center"
                                                          onClick={() =>
                                                            handleRepeatForm(
                                                              key
                                                            )
                                                          }
                                                        />
                                                        <Button
                                                          type="button"
                                                          icon="pi pi-minus"
                                                          disabled={
                                                            (formCounts[
                                                              key
                                                            ] || 1) === 1
                                                          }
                                                          label="Remove Entry"
                                                          className="vstate-button font-fam-for-all text-center justify-content-center"
                                                          onClick={() =>
                                                            handleRemoveRepeatForm(
                                                              key
                                                            )
                                                          }
                                                        />
                                                      </div> */}
                                                    </>
                                                  ))
                                                  : ""}

                                                {getValues(
                                                  "uccAmend_What_are_we_amending"
                                                ) === "ASSIGNMENT" &&
                                                  value.apiId ===
                                                  "ucc-assignee-details" &&
                                                  value.apiId !==
                                                  "uucAmnd-What-are-amending" ? (
                                                  <div className="vstate-card p-4 mb-3">
                                                    <div
                                                      className="grid "
                                                      key={j}
                                                    >
                                                      <div className="col-12 pb-0">
                                                        <h2 className="entity-header-strapi">
                                                          {toPascalCase(
                                                            value.schema
                                                              .displayName
                                                          )}
                                                        </h2>
                                                      </div>
                                                      <div className="grid w-full justify-content-between">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                                          <div className=" justify-content-center dialog-form-field-space w-full">
                                                            <div className="field">
                                                              <label
                                                                htmlFor=""
                                                                className="form-label font-fam-for-all"
                                                              >
                                                                Select Assignee
                                                                <span className="form-field-mandatory">
                                                                  *
                                                                </span>
                                                              </label>
                                                              <span className=" ">
                                                                <Controller
                                                                  name={`uccAmend_Additional_Debtor_Details`}
                                                                  control={
                                                                    control
                                                                  }
                                                                  render={({
                                                                    field,
                                                                  }) => (
                                                                    <Dropdown
                                                                      value={
                                                                        assigneeData
                                                                      }
                                                                      options={
                                                                        allAssignee
                                                                      }
                                                                      onChange={(
                                                                        e
                                                                      ) => {
                                                                        setAssigneeData(
                                                                          e.value
                                                                        );
                                                                      }}
                                                                      optionLabel="keyPersonnelName"
                                                                      placeholder="Select Assignee"
                                                                      style={{
                                                                        width:
                                                                          "100%",
                                                                        height:
                                                                          "47px",
                                                                      }}
                                                                      filter
                                                                      resetFilterOnHide
                                                                      className="dialog-form-input-field-wizard"
                                                                      required
                                                                    />
                                                                  )}
                                                                />
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center mt-3">
                                                          {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("ASSIGNEE")}>
                                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                    </svg>
                                                                                                                    <div className='font-medium'>Add Assignee</div>
                                                                                                                </div>

                                                                                                            </button> */}
                                                          <Button
                                                            type="button"
                                                            icon="pi pi-plus"
                                                            style={{
                                                              fontSize: "14px",
                                                            }}
                                                            label="Add Assignee"
                                                            className=" vstate-button font-fam-for-all text-center mt-4"
                                                            onClick={() =>
                                                              handleKeyPersonal(
                                                                "ASSIGNEE"
                                                              )
                                                            }
                                                          ></Button>
                                                        </div>
                                                      </div>
                                                      {Object.keys(
                                                        value.schema.attributes
                                                      ).map((val, k) => (
                                                        <div
                                                          className={`vstate-visbiliy-hidden col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 dummy ${!renderFormField(
                                                            value.schema
                                                              .attributes[
                                                            val
                                                            ],
                                                            val,
                                                            `${val}`,
                                                            value,
                                                            key
                                                          )
                                                              ? "hidden"
                                                              : ""
                                                            }`}
                                                          key={k}
                                                        >
                                                          {value.schema
                                                            .attributes[val]
                                                            .type !== undefined
                                                            ? renderFormField(
                                                              value.schema
                                                                .attributes[
                                                              val
                                                              ],
                                                              val,
                                                              val,
                                                              value,
                                                              key,
                                                              value.apiId
                                                            )
                                                            : ""}
                                                        </div>
                                                      ))}
                                                      <div>
                                                        {assigneeData && (
                                                          <KeyPersonnelIndividualCard
                                                            data={assigneeData}
                                                            getState={getState}
                                                            type={"Assignee"}
                                                          />
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}

                                                {Array.isArray(
                                                  getValues(
                                                    "COC_What_Are_We_Changing-X"
                                                  )
                                                ) &&
                                                  getValues(
                                                    "COC_What_Are_We_Changing-X"
                                                  ).includes("Address") &&
                                                  value.apiId ===
                                                  "coc-please-include-new-address" ? (
                                                  <div className="vstate-card p-4 mb-3">
                                                    <div
                                                      className="grid "
                                                      key={j}
                                                    >
                                                      <div className="col-12 pb-0">
                                                        <h2 className="entity-header-strapi">
                                                          {toPascalCase(
                                                            value.schema
                                                              .displayName
                                                          )}
                                                        </h2>
                                                      </div>
                                                      {Object.keys(
                                                        value.schema.attributes
                                                      ).map((val, k) => {
                                                        const content =
                                                          value.schema
                                                            .attributes[val]
                                                            .type !== undefined
                                                            ? renderFormField(
                                                              value.schema
                                                                .attributes[
                                                              val
                                                              ],
                                                              val,
                                                              `${val}`,
                                                              value,
                                                              key
                                                            )
                                                            : null;

                                                        return content ? (
                                                          <div
                                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"
                                                            key={k}
                                                          >
                                                            {content}
                                                          </div>
                                                        ) : null;
                                                      })}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}

                                                {Array.isArray(
                                                  getValues(
                                                    "COC_What_Are_We_Changing-X"
                                                  )
                                                ) &&
                                                  getValues(
                                                    "COC_What_Are_We_Changing-X"
                                                  ).includes("Agent") &&
                                                  value.apiId ===
                                                  "coc-registered-agent" ? (
                                                  <div className="vstate-card p-4 mb-3">
                                                    <div
                                                      className="grid "
                                                      key={j}
                                                    >
                                                      <div className="col-12 pb-0">
                                                        <h2 className="entity-header-strapi">
                                                          {toPascalCase(
                                                            value.schema
                                                              .displayName
                                                          )}
                                                        </h2>
                                                      </div>
                                                      {Object.keys(
                                                        value.schema.attributes
                                                      ).map((val, k) => {
                                                        const content =
                                                          value.schema
                                                            .attributes[val]
                                                            .type !== undefined
                                                            ? renderFormField(
                                                              value.schema
                                                                .attributes[
                                                              val
                                                              ],
                                                              val,
                                                              `${val}`,
                                                              value,
                                                              key
                                                            )
                                                            : null;

                                                        return content ? (
                                                          <div
                                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6"
                                                            key={k}
                                                          >
                                                            {content}
                                                          </div>
                                                        ) : null;
                                                      })}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}

                                                {value.apiId !== "address" &&
                                                  value.apiId !==
                                                  "ucc-amnd-additional-debtor" &&
                                                  value.apiId !==
                                                  "ucc-amend-additional-secured-party-details" &&
                                                  value.apiId !==
                                                  "ucc-assignee-details" &&
                                                  value.apiId !==
                                                  "coc-registered-agent" &&
                                                  value.apiId !==
                                                  "coc-please-include-new-address" ? (
                                                  <div
                                                    className="vstate-card p-4 mb-3"
                                                    style={
                                                      keyPersonalMemberData.includes(
                                                        key
                                                      )
                                                        ? { display: "none" }
                                                        : {}
                                                    }
                                                  >
                                                    <div
                                                      className="grid "
                                                      key={j}
                                                    >
                                                      <div className="col-12 pb-0">
                                                        <h2 className="entity-header-strapi">
                                                          {toPascalCase(
                                                            value.schema
                                                              .displayName
                                                          )}
                                                        </h2>
                                                      </div>
                                                      {Object.keys(
                                                        value.schema.attributes
                                                      ).map((val, k) => (
                                                        <div
                                                          className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 dummy ${!renderFormField(
                                                            value.schema
                                                              .attributes[
                                                            val
                                                            ],
                                                            val,
                                                            `${val}`,
                                                            value,
                                                            key
                                                          )
                                                              ? "hidden"
                                                              : ""
                                                            }`}
                                                          key={k}
                                                        >
                                                          {value.schema
                                                            .attributes[val]
                                                            .type !== undefined
                                                            ? renderFormField(
                                                              value.schema
                                                                .attributes[
                                                              val
                                                              ],
                                                              val,
                                                              val,
                                                              value,
                                                              key,
                                                              value.schema
                                                                .displayName
                                                            )
                                                            : ""}
                                                        </div>
                                                      ))}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}

                                                {value.apiId === "address" &&
                                                  value.uid !== "ein.address" ? (
                                                  <div
                                                    className="vstate-card p-4 mb-3"
                                                    style={
                                                      keyPersonalMemberData.includes(
                                                        key
                                                      ) ||
                                                        keyPersonalMemberData.includes(
                                                          value.apiId
                                                        )
                                                        ? { display: "none" }
                                                        : {}
                                                    }
                                                  >
                                                    <div
                                                      className="grid "
                                                      key={j}
                                                    >
                                                      <div className="col-12 pb-0">
                                                        <h2 className="entity-header-strapi">
                                                          {toPascalCase(
                                                            value.schema
                                                              .displayName
                                                          )}
                                                        </h2>
                                                      </div>
                                                      {Object.keys(
                                                        value.schema.attributes
                                                      ).map((val, k) => (
                                                        <div
                                                          className={`col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 dummy ${!renderFormField(
                                                            value.schema
                                                              .attributes[
                                                            val
                                                            ],
                                                            val,
                                                            `${val}`,
                                                            value,
                                                            key
                                                          )
                                                              ? "hidden"
                                                              : ""
                                                            }`}
                                                          key={k}
                                                        >
                                                          {value.schema
                                                            .attributes[val]
                                                            .type !== undefined
                                                            ? renderFormField(
                                                              value.schema
                                                                .attributes[
                                                              val
                                                              ],
                                                              val,
                                                              val,
                                                              value,
                                                              key
                                                            )
                                                            : ""}
                                                        </div>
                                                      ))}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            )
                                          )
                                        )}
                                      </>
                                    )}
                                  </div>
                                ))}

                                {inputFormData.length > 0 ? (
                                  <>
                                    <div className="tab px-4">
                                      <div
                                        className="wizard-content-sub-card-header mb-3"
                                        id="header"
                                      >
                                        <div className="wizard-content-sub-card-text-frame">
                                          <div className="wizard-content-sub-card-text">
                                            Contact
                                          </div>
                                        </div>
                                      </div>
                                      <div className="wizard-checkoust-container hover-card slide-in">
                                        <div className="wizard-checkout-main">
                                          <div className="grid px-4  w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Billing Address Contact
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={bilingAddress}
                                                          options={
                                                            allBilingAddress
                                                          }
                                                          onChange={(e) =>
                                                            setBilingAddress(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Billing Address"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          resetFilterOnHide
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 justify-content-start align-items-center flex">
                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("BILLING")} >
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Billing Address</div>
                                                                                                </div>

                                                                                            </button> */}
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Billing Information"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("BILLING")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          <div className="grid wizard-checkout-body w-full">
                                            {bilingAddress && (
                                              <KeyPersonnelIndividualCard
                                                data={bilingAddress}
                                                getState={getState}
                                                type={"Billing Address"}
                                              />
                                            )}
                                          </div>
                                          {/* <div className="wizard-checkout-body">
                                                                                        <AddressTemplate data={bilingAddress} />
                                                                                    </div> */}
                                        </div>
                                      </div>
                                      {/* <div
                                                                                className="wizard-content-sub-card-header mb-3 mt-3"
                                                                                id="header"
                                                                            >
                                                                                <div className="wizard-content-sub-card-text-frame">
                                                                                    <div className="wizard-content-sub-card-text">
                                                                                        Shipping Address Contact
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizard-checkoust-container hover-card slide-in">
                                                                                <div className="wizard-checkout-main">
                                                                                    <div className='grid justify-content-start ml-4 mt-4 mb-3'>
                                                                                        <div className=' p-0'>
                                                                                            <div className='flex justify-content-center'>
                                                                                                <Checkbox
                                                                                                    checked={IsCheckedAgent1}
                                                                                                    onChange={(e) => {
                                                                                                        setIsCheckedAgent1(e.checked)
                                                                                                    }}
                                                                                                    inputProps={{ 'aria-label': 'controlled' }} />

                                                                                                <label htmlFor="binary" className='font-fam-for-all ml-2 srch-cand-text' >Same as Billing address
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="grid px-4  w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Shipping Address
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className=" ">
                                                                                                        <Controller
                                                                                                            name="incorporator"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={shippingAddress}
                                                                                                                    options={allShippingAddress}
                                                                                                                    onChange={(e) => setShippingAddress(e.value)}
                                                                                                                    disabled={IsCheckedAgent1}
                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                    placeholder="Select Billing Address"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex" >
                                                                                   
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Shipping Address"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => handleKeyPersonal("SHIPPING")}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className="wizard-checkout-body">
                                                                                        {IsCheckedAgent1 ? <AddressTemplate data={bilingAddress} /> : <AddressTemplate data={shippingAddress} />}
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                    </div>

                                    <div class="tab">
                                      <div
                                        className="wizard-content-sub-card-header mb-3"
                                        id="header"
                                      >
                                        <div className="wizard-content-sub-card-text-frame">
                                          <div className="wizard-content-sub-card-text">
                                            Preview
                                          </div>
                                        </div>
                                      </div>

                                      <div className="flex p-4 justify-between font-fam-for-all">
                                        <div className="datatable-highlight-color">
                                          {enityName
                                            ? enityName.entityFullDesc
                                            : ""}
                                        </div>
                                        <div className="datatable-highlight-color">
                                          {state ? state.stateFullDesc : ""}
                                          <span className="ml-2">
                                            {county &&
                                              `(${county?.countyName})`}
                                          </span>
                                        </div>
                                      </div>

                                      {previewData ? (
                                        <div className="min-h-screen bg-gray-50  flex justify-center items-start">
                                          <div className="w-full  ">
                                            <div className="bg-white py-2">
                                              <div className="space-y-8">
                                                <div className="bg-white">
                                                  <div
                                                    className="px-4 py-2 rounded-t-lg"
                                                    style={{
                                                      backgroundColor:
                                                        "#F9FAFB",
                                                      fontFamily: "Inter",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    <h2 className="text-lg  text-gray-800">
                                                      Selected Services
                                                    </h2>
                                                  </div>

                                                  <div className="p-4 space-y-4">
                                                    {selectedCheckBoxData.map(
                                                      (service, index) => (
                                                        <div
                                                          key={index}
                                                          className="border-bottom grid border-gray-200 rounded-md p-2"
                                                        >
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10">
                                                            <h3
                                                              className="text-md font-semibold"
                                                              style={{
                                                                fontFamily:
                                                                  "Inter",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            >
                                                              {
                                                                service.product
                                                                  .displayName
                                                              }
                                                            </h3>
                                                            <p
                                                              className="text-gray-700 mt-2"
                                                              style={{
                                                                fontFamily:
                                                                  "Inter",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            >
                                                              {/* {service.product.productMaster.additionalInfo} */}
                                                            </p>
                                                          </div>
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2">
                                                            <div
                                                              className="text-right  font-bold text-blue-800"
                                                              style={{
                                                                fontFamily:
                                                                  "Inter",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            >
                                                              {service?.product
                                                                ?.isCustomPriceApplicable
                                                                ? "Custom Price"
                                                                : service
                                                                  ?.product
                                                                  ?.isPriceIncludedInBaseProduct
                                                                  ? "Included"
                                                                  : `$${parseFloat(
                                                                    service
                                                                      ?.product
                                                                      ?.unitPrice
                                                                  ).toFixed(
                                                                    2
                                                                  )}`}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>

                                                {Object.keys(previewData).map(
                                                  (sectionKey, index) => (
                                                    <>
                                                      {!keyPersonalMemberDataPreview.includes(
                                                        sectionKey
                                                      ) ? (
                                                        <>
                                                          <div
                                                            key={index}
                                                            className="bg-white"
                                                          >
                                                            <Accordion
                                                              activeIndex={0}
                                                            >
                                                              <AccordionTab
                                                                header={convertToTitleCase(
                                                                  sectionKey
                                                                )}
                                                              >
                                                                <div className="px-4 py-4">
                                                                  {Array.isArray(
                                                                    previewData[
                                                                    sectionKey
                                                                    ]
                                                                  ) ? (
                                                                    previewData[
                                                                      sectionKey
                                                                    ].map(
                                                                      (
                                                                        item,
                                                                        idx
                                                                      ) => {
                                                                        const primitiveKeys =
                                                                          [];
                                                                        const objectKeys =
                                                                          [];
                                                                        const arrayKeys =
                                                                          [];

                                                                        Object.keys(
                                                                          item
                                                                        ).forEach(
                                                                          (
                                                                            itemKey
                                                                          ) => {
                                                                            if (
                                                                              itemKey !==
                                                                              "__temp_key__"
                                                                            ) {
                                                                              if (
                                                                                typeof item[
                                                                                itemKey
                                                                                ] ===
                                                                                "object" &&
                                                                                item[
                                                                                itemKey
                                                                                ] !==
                                                                                null
                                                                              ) {
                                                                                if (
                                                                                  Array.isArray(
                                                                                    item[
                                                                                    itemKey
                                                                                    ]
                                                                                  )
                                                                                ) {
                                                                                  arrayKeys.push(
                                                                                    itemKey
                                                                                  );
                                                                                } else {
                                                                                  objectKeys.push(
                                                                                    itemKey
                                                                                  );
                                                                                }
                                                                              } else {
                                                                                primitiveKeys.push(
                                                                                  itemKey
                                                                                );
                                                                              }
                                                                            }
                                                                          }
                                                                        );

                                                                        return (
                                                                          <div
                                                                            key={
                                                                              idx
                                                                            }
                                                                            className=""
                                                                          >
                                                                            <h3 className="text-lg text-gray-800 mb-2">
                                                                              {convertToTitleCase(
                                                                                sectionKey
                                                                              )}{" "}
                                                                              {idx +
                                                                                1}
                                                                            </h3>

                                                                            <div className="grid p-4 mb-2">
                                                                              {primitiveKeys.map(
                                                                                (
                                                                                  itemKey
                                                                                ) => (
                                                                                  <>
                                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                                                                                      <div className="order-preview-key">
                                                                                        {convertName(
                                                                                          itemKey
                                                                                        )}
                                                                                      </div>
                                                                                      <div className="order-preview-value">
                                                                                        {
                                                                                          item[
                                                                                          itemKey
                                                                                          ]
                                                                                        }
                                                                                      </div>
                                                                                    </div>
                                                                                    {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1"></div> */}
                                                                                  </>
                                                                                )
                                                                              )}
                                                                            </div>

                                                                            {objectKeys.length >
                                                                              0 && (
                                                                                <div className="grid  sam p-4 mb-2">
                                                                                  {objectKeys.map(
                                                                                    (
                                                                                      itemKey
                                                                                    ) => (
                                                                                      <>
                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                                          <div className="order-preview-key pb-2">
                                                                                            {convertName(
                                                                                              itemKey
                                                                                            )}
                                                                                          </div>
                                                                                          <div className="order-preview-value">
                                                                                            <div className="grid preview-card pap p-2 mt-3 mb-2">
                                                                                              {Object.keys(
                                                                                                item[
                                                                                                itemKey
                                                                                                ]
                                                                                              ).map(
                                                                                                (
                                                                                                  subKey
                                                                                                ) => (
                                                                                                  <>
                                                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                                                                                                      <div className="order-preview-key">
                                                                                                        {convertName(
                                                                                                          subKey
                                                                                                        )}
                                                                                                      </div>
                                                                                                      <div className="order-preview-value">
                                                                                                        {
                                                                                                          item[
                                                                                                          itemKey
                                                                                                          ][
                                                                                                          subKey
                                                                                                          ]
                                                                                                        }
                                                                                                      </div>
                                                                                                    </div>
                                                                                                    {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1"></div> */}
                                                                                                  </>
                                                                                                )
                                                                                              )}
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      </>
                                                                                    )
                                                                                  )}
                                                                                </div>
                                                                              )}

                                                                            {arrayKeys.length >
                                                                              0 && (
                                                                                <div className="grid sam1 p-4 mb-2">
                                                                                  {arrayKeys.map(
                                                                                    (
                                                                                      itemKey
                                                                                    ) => (
                                                                                      <>
                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                                                          <div className="order-preview-key pb-2">
                                                                                            {convertName(
                                                                                              itemKey
                                                                                            )}
                                                                                          </div>
                                                                                          <div className="order-preview-value">
                                                                                            {item[
                                                                                              itemKey
                                                                                            ].map(
                                                                                              (
                                                                                                subItem,
                                                                                                subIdx
                                                                                              ) => (
                                                                                                <div
                                                                                                  key={
                                                                                                    subIdx
                                                                                                  }
                                                                                                  className="ml-4"
                                                                                                >
                                                                                                  {typeof subItem ===
                                                                                                    "object" ? (
                                                                                                    <div className="grid preview-card pap p-4 mb-2">
                                                                                                      {Object.keys(
                                                                                                        subItem
                                                                                                      ).map(
                                                                                                        (
                                                                                                          subKey
                                                                                                        ) => (
                                                                                                          <>
                                                                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                                                                                                              <div className="order-preview-key">
                                                                                                                {convertName(
                                                                                                                  subKey
                                                                                                                )}
                                                                                                              </div>
                                                                                                              <div className="order-preview-value">
                                                                                                                {
                                                                                                                  subItem[
                                                                                                                  subKey
                                                                                                                  ]
                                                                                                                }
                                                                                                              </div>
                                                                                                            </div>
                                                                                                            {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1"></div> */}
                                                                                                          </>
                                                                                                        )
                                                                                                      )}
                                                                                                    </div>
                                                                                                  ) : (
                                                                                                    <div>
                                                                                                      {
                                                                                                        subItem
                                                                                                      }
                                                                                                    </div>
                                                                                                  )}
                                                                                                </div>
                                                                                              )
                                                                                            )}
                                                                                          </div>
                                                                                        </div>
                                                                                      </>
                                                                                    )
                                                                                  )}
                                                                                </div>
                                                                              )}

                                                                            {/* <div className="border-t border-gray-300 my-4"></div> */}
                                                                          </div>
                                                                        );
                                                                      }
                                                                    )
                                                                  ) : previewData[
                                                                    sectionKey
                                                                  ] !==
                                                                    null &&
                                                                    typeof previewData[
                                                                    sectionKey
                                                                    ] ===
                                                                    "object" ? (
                                                                    Object.keys(
                                                                      previewData[
                                                                      sectionKey
                                                                      ]
                                                                    ).map(
                                                                      (
                                                                        key,
                                                                        i
                                                                      ) => (
                                                                        <div
                                                                          key={
                                                                            i
                                                                          }
                                                                          className=""
                                                                        >
                                                                          {Array.isArray(
                                                                            previewData[
                                                                            sectionKey
                                                                            ][
                                                                            key
                                                                            ]
                                                                          ) ? (
                                                                            <div>
                                                                              <h3 className="text-lg text-gray-800 mb-2">
                                                                                {convertToTitleCase(
                                                                                  key
                                                                                )}
                                                                              </h3>

                                                                              {previewData[
                                                                                sectionKey
                                                                              ][
                                                                                key
                                                                              ].map(
                                                                                (
                                                                                  arrayItem,
                                                                                  arrayIndex
                                                                                ) => (
                                                                                  <div
                                                                                    key={
                                                                                      arrayIndex
                                                                                    }
                                                                                    className="mb-2"
                                                                                  >
                                                                                    {arrayItem !==
                                                                                      null &&
                                                                                      typeof arrayItem ===
                                                                                      "object" ? (
                                                                                      <div>
                                                                                        <div className="grid preview-card p-4 mb-2">
                                                                                          {Object.keys(
                                                                                            arrayItem
                                                                                          ).map(
                                                                                            (
                                                                                              nestedKey
                                                                                            ) => (
                                                                                              <>
                                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                                                                                                  <div className="order-preview-key">
                                                                                                    {convertName(
                                                                                                      nestedKey
                                                                                                    )}
                                                                                                  </div>
                                                                                                  <div className="order-preview-value">
                                                                                                    {
                                                                                                      arrayItem[
                                                                                                      nestedKey
                                                                                                      ]
                                                                                                    }
                                                                                                  </div>
                                                                                                </div>
                                                                                                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1"></div> */}
                                                                                              </>
                                                                                            )
                                                                                          )}
                                                                                        </div>

                                                                                        <div className="border-t border-gray-300 my-4"></div>
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div className="grid gap-3 mb-2">
                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 ">
                                                                                          <div className="order-preview-key">
                                                                                            {`Item ${arrayIndex +
                                                                                              1
                                                                                              }`}
                                                                                          </div>
                                                                                          <div className="order-preview-value">
                                                                                            {
                                                                                              arrayItem
                                                                                            }
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    )}
                                                                                  </div>
                                                                                )
                                                                              )}

                                                                              <div className="border-t border-gray-300 my-4"></div>
                                                                            </div>
                                                                          ) : previewData[
                                                                            sectionKey
                                                                          ][
                                                                            key
                                                                          ] !==
                                                                            null &&
                                                                            typeof previewData[
                                                                            sectionKey
                                                                            ][
                                                                            key
                                                                            ] ===
                                                                            "object" ? (
                                                                            <div>
                                                                              <h3 className="text-lg text-gray-800 mb-2">
                                                                                {convertToTitleCase(
                                                                                  key
                                                                                )}
                                                                              </h3>
                                                                              <div className="grid preview-card p-4 mb-2">
                                                                                {Object.keys(
                                                                                  previewData[
                                                                                  sectionKey
                                                                                  ][
                                                                                  key
                                                                                  ]
                                                                                ).map(
                                                                                  (
                                                                                    nestedKey
                                                                                  ) => (
                                                                                    <>
                                                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3">
                                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                                                                                          <div className="order-preview-key">
                                                                                            {convertName(
                                                                                              nestedKey
                                                                                            )}
                                                                                          </div>
                                                                                          <div className="order-preview-value">
                                                                                            {
                                                                                              previewData[
                                                                                              sectionKey
                                                                                              ][
                                                                                              key
                                                                                              ][
                                                                                              nestedKey
                                                                                              ]
                                                                                            }
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                      {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1"></div> */}
                                                                                    </>
                                                                                  )
                                                                                )}
                                                                              </div>

                                                                              <div className="border-t border-gray-300 my-4"></div>
                                                                            </div>
                                                                          ) : (
                                                                            <div className="grid gap-3 mb-2">
                                                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 order-preview-key">
                                                                                {convertName(
                                                                                  key
                                                                                )}
                                                                              </div>
                                                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 order-preview-value">
                                                                                {
                                                                                  previewData[
                                                                                  sectionKey
                                                                                  ][
                                                                                  key
                                                                                  ]
                                                                                }
                                                                              </div>
                                                                            </div>
                                                                          )}
                                                                        </div>
                                                                      )
                                                                    )
                                                                  ) : (
                                                                    <div className="grid gap-3 mb-2">
                                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 order-preview-key">
                                                                        {convertName(
                                                                          sectionKey
                                                                        )}
                                                                      </div>
                                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 order-preview-value">
                                                                        {
                                                                          previewData[
                                                                          sectionKey
                                                                          ]
                                                                        }
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </div>
                                                              </AccordionTab>
                                                            </Accordion>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  )
                                                )}

                                                {(organizerData ||
                                                  incorporator ||
                                                  director ||
                                                  officer ||
                                                  president ||
                                                  secretary ||
                                                  treasurer ||
                                                  filer) && (
                                                    <div className="bg-white">
                                                      <div
                                                        className="px-4 py-2"
                                                        style={{
                                                          backgroundColor:
                                                            "#F9FAFB",
                                                          fontFamily: "Inter",
                                                          fontSize: "16px",
                                                        }}
                                                      >
                                                        <h2 className="text-lg  text-gray-800">
                                                          Contacts
                                                        </h2>
                                                      </div>

                                                      <div className="p-4 ">
                                                        <div className="grid gap-2 justify-content-start">
                                                          {organizerData ? (
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                                                              <div className="order-contact-card-container">
                                                                <div className="order-contact-card-main">
                                                                  <div className="order-contact-card-header-main">
                                                                    <Avatar
                                                                      label={commonGetInitials(
                                                                        organizerData.keyPersonnelName
                                                                      )}
                                                                      icon="pi pi-user"
                                                                      size="large"
                                                                      shape="circle"
                                                                      className="custom-avatar"
                                                                    />

                                                                    <div>
                                                                      <div className="order-contact-card-header-text1">
                                                                        {
                                                                          organizerData.keyPersonnelName
                                                                        }
                                                                      </div>
                                                                      <div className="order-contact-card-header-text2">
                                                                        Organizer
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="card-devider">
                                                                    {" "}
                                                                  </div>
                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                          <path
                                                                            d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                            stroke="#175CD3"
                                                                            stroke-width="2"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                          />
                                                                        </g>
                                                                        <defs>
                                                                          <clipPath id="clip0_966_10329">
                                                                            <rect
                                                                              width="20"
                                                                              height="20"
                                                                              fill="white"
                                                                            />
                                                                          </clipPath>
                                                                        </defs>
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        organizerData.contactNo
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                          stroke="#175CD3"
                                                                          stroke-width="2"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        organizerData.emailId
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                          d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1 text-wrap">
                                                                      {organizerData
                                                                        .addressMasterId
                                                                        .addressLine1 !==
                                                                        ""
                                                                        ? `${organizerData
                                                                          .addressMasterId
                                                                          .addressLine1
                                                                        },${organizerData
                                                                          .addressMasterId
                                                                          .addressLine2
                                                                          ? `${organizerData.addressMasterId.addressLine2},`
                                                                          : ""
                                                                        }${organizerData
                                                                          .addressMasterId
                                                                          .city
                                                                        },${getState(
                                                                          organizerData
                                                                            .addressMasterId
                                                                            .stateId
                                                                        )},${organizerData
                                                                          .addressMasterId
                                                                          .postalCode
                                                                        }`
                                                                        : ""}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {incorporator ? (
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                                                              <div className="order-contact-card-container">
                                                                <div className="order-contact-card-main">
                                                                  <div className="order-contact-card-header-main">
                                                                    <Avatar
                                                                      label={commonGetInitials(
                                                                        incorporator.keyPersonnelName
                                                                      )}
                                                                      icon="pi pi-user"
                                                                      size="large"
                                                                      shape="circle"
                                                                      className="custom-avatar"
                                                                    />

                                                                    <div>
                                                                      <div className="order-contact-card-header-text1">
                                                                        {
                                                                          incorporator.keyPersonnelName
                                                                        }
                                                                      </div>
                                                                      <div className="order-contact-card-header-text2">
                                                                        Incorporator
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="card-devider">
                                                                    {" "}
                                                                  </div>
                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                          <path
                                                                            d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                            stroke="#175CD3"
                                                                            stroke-width="2"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                          />
                                                                        </g>
                                                                        <defs>
                                                                          <clipPath id="clip0_966_10329">
                                                                            <rect
                                                                              width="20"
                                                                              height="20"
                                                                              fill="white"
                                                                            />
                                                                          </clipPath>
                                                                        </defs>
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        incorporator.contactNo
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                          stroke="#175CD3"
                                                                          stroke-width="2"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        incorporator.emailId
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                          d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1 text-wrap">
                                                                      {incorporator
                                                                        .addressMasterId
                                                                        .addressLine1 !==
                                                                        ""
                                                                        ? `${incorporator
                                                                          .addressMasterId
                                                                          .addressLine1
                                                                        },${incorporator
                                                                          .addressMasterId
                                                                          .addressLine2
                                                                          ? `${incorporator.addressMasterId.addressLine2},`
                                                                          : ""
                                                                        }${incorporator
                                                                          .addressMasterId
                                                                          .city
                                                                        },${getState(
                                                                          incorporator
                                                                            .addressMasterId
                                                                            .stateId
                                                                        )},${incorporator
                                                                          .addressMasterId
                                                                          .postalCode
                                                                        }`
                                                                        : ""}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {officer ? (
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                                                              <div className="order-contact-card-container">
                                                                <div className="order-contact-card-main">
                                                                  <div className="order-contact-card-header-main">
                                                                    <Avatar
                                                                      label={commonGetInitials(
                                                                        officer.keyPersonnelName
                                                                      )}
                                                                      icon="pi pi-user"
                                                                      size="large"
                                                                      shape="circle"
                                                                      className="custom-avatar"
                                                                    />

                                                                    <div>
                                                                      <div className="order-contact-card-header-text1">
                                                                        {
                                                                          officer.keyPersonnelName
                                                                        }
                                                                      </div>
                                                                      <div className="order-contact-card-header-text2">
                                                                        Officer
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="card-devider">
                                                                    {" "}
                                                                  </div>
                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                          <path
                                                                            d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                            stroke="#175CD3"
                                                                            stroke-width="2"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                          />
                                                                        </g>
                                                                        <defs>
                                                                          <clipPath id="clip0_966_10329">
                                                                            <rect
                                                                              width="20"
                                                                              height="20"
                                                                              fill="white"
                                                                            />
                                                                          </clipPath>
                                                                        </defs>
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        officer.contactNo
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                          stroke="#175CD3"
                                                                          stroke-width="2"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        officer.emailId
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                          d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1 text-wrap">
                                                                      {officer
                                                                        .addressMasterId
                                                                        .addressLine1 !==
                                                                        ""
                                                                        ? `${officer
                                                                          .addressMasterId
                                                                          .addressLine1
                                                                        },${officer
                                                                          .addressMasterId
                                                                          .addressLine2
                                                                          ? `${officer.addressMasterId.addressLine2},`
                                                                          : ""
                                                                        }${officer
                                                                          .addressMasterId
                                                                          .city
                                                                        },${getState(
                                                                          officer
                                                                            .addressMasterId
                                                                            .stateId
                                                                        )},${officer
                                                                          .addressMasterId
                                                                          .postalCode
                                                                        }`
                                                                        : ""}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {director ? (
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                                                              <div className="order-contact-card-container">
                                                                <div className="order-contact-card-main">
                                                                  <div className="order-contact-card-header-main">
                                                                    <Avatar
                                                                      label={commonGetInitials(
                                                                        director.keyPersonnelName
                                                                      )}
                                                                      icon="pi pi-user"
                                                                      size="large"
                                                                      shape="circle"
                                                                      className="custom-avatar"
                                                                    />

                                                                    <div>
                                                                      <div className="order-contact-card-header-text1">
                                                                        {
                                                                          director.keyPersonnelName
                                                                        }
                                                                      </div>
                                                                      <div className="order-contact-card-header-text2">
                                                                        Director
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="card-devider">
                                                                    {" "}
                                                                  </div>
                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                          <path
                                                                            d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                            stroke="#175CD3"
                                                                            stroke-width="2"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                          />
                                                                        </g>
                                                                        <defs>
                                                                          <clipPath id="clip0_966_10329">
                                                                            <rect
                                                                              width="20"
                                                                              height="20"
                                                                              fill="white"
                                                                            />
                                                                          </clipPath>
                                                                        </defs>
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        director.contactNo
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                          stroke="#175CD3"
                                                                          stroke-width="2"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        director.emailId
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                          d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1 text-wrap">
                                                                      {director
                                                                        .addressMasterId
                                                                        .addressLine1 !==
                                                                        ""
                                                                        ? `${director
                                                                          .addressMasterId
                                                                          .addressLine1
                                                                        },${director
                                                                          .addressMasterId
                                                                          .addressLine2
                                                                          ? `${director.addressMasterId.addressLine2},`
                                                                          : ""
                                                                        }${director
                                                                          .addressMasterId
                                                                          .city
                                                                        },${getState(
                                                                          director
                                                                            .addressMasterId
                                                                            .stateId
                                                                        )},${director
                                                                          .addressMasterId
                                                                          .postalCode
                                                                        }`
                                                                        : ""}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {filer ? (
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                                                              <div className="order-contact-card-container">
                                                                <div className="order-contact-card-main">
                                                                  <div className="order-contact-card-header-main">
                                                                    <Avatar
                                                                      label={commonGetInitials(
                                                                        filer.keyPersonnelName
                                                                      )}
                                                                      icon="pi pi-user"
                                                                      size="large"
                                                                      shape="circle"
                                                                      className="custom-avatar"
                                                                    />

                                                                    <div>
                                                                      <div className="order-contact-card-header-text1">
                                                                        {
                                                                          filer.keyPersonnelName
                                                                        }
                                                                      </div>
                                                                      <div className="order-contact-card-header-text2">
                                                                        Director
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="card-devider">
                                                                    {" "}
                                                                  </div>
                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                          <path
                                                                            d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                            stroke="#175CD3"
                                                                            stroke-width="2"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                          />
                                                                        </g>
                                                                        <defs>
                                                                          <clipPath id="clip0_966_10329">
                                                                            <rect
                                                                              width="20"
                                                                              height="20"
                                                                              fill="white"
                                                                            />
                                                                          </clipPath>
                                                                        </defs>
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        filer.contactNo
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                          stroke="#175CD3"
                                                                          stroke-width="2"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        filer.emailId
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                          d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1 text-wrap">
                                                                      {filer
                                                                        .addressMasterId
                                                                        .addressLine1 !==
                                                                        ""
                                                                        ? `${filer
                                                                          .addressMasterId
                                                                          .addressLine1
                                                                        },${filer
                                                                          .addressMasterId
                                                                          .addressLine2
                                                                          ? `${filer.addressMasterId.addressLine2},`
                                                                          : ""
                                                                        }${filer
                                                                          .addressMasterId
                                                                          .city
                                                                        },${getState(
                                                                          filer
                                                                            .addressMasterId
                                                                            .stateId
                                                                        )},${filer
                                                                          .addressMasterId
                                                                          .postalCode
                                                                        }`
                                                                        : ""}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {president ? (
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                                                              <div className="order-contact-card-container">
                                                                <div className="order-contact-card-main">
                                                                  <div className="order-contact-card-header-main">
                                                                    <Avatar
                                                                      label={commonGetInitials(
                                                                        president.keyPersonnelName
                                                                      )}
                                                                      icon="pi pi-user"
                                                                      size="large"
                                                                      shape="circle"
                                                                      className="custom-avatar"
                                                                    />

                                                                    <div>
                                                                      <div className="order-contact-card-header-text1">
                                                                        {
                                                                          president.keyPersonnelName
                                                                        }
                                                                      </div>
                                                                      <div className="order-contact-card-header-text2">
                                                                        President
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="card-devider">
                                                                    {" "}
                                                                  </div>
                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                          <path
                                                                            d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                            stroke="#175CD3"
                                                                            stroke-width="2"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                          />
                                                                        </g>
                                                                        <defs>
                                                                          <clipPath id="clip0_966_10329">
                                                                            <rect
                                                                              width="20"
                                                                              height="20"
                                                                              fill="white"
                                                                            />
                                                                          </clipPath>
                                                                        </defs>
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        president.contactNo
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                          stroke="#175CD3"
                                                                          stroke-width="2"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        president.emailId
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                          d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1 text-wrap">
                                                                      {president
                                                                        .addressMasterId
                                                                        .addressLine1 !==
                                                                        ""
                                                                        ? `${president
                                                                          .addressMasterId
                                                                          .addressLine1
                                                                        },${president
                                                                          .addressMasterId
                                                                          .addressLine2
                                                                          ? `${president.addressMasterId.addressLine2},`
                                                                          : ""
                                                                        }${president
                                                                          .addressMasterId
                                                                          .city
                                                                        },${getState(
                                                                          president
                                                                            .addressMasterId
                                                                            .stateId
                                                                        )},${president
                                                                          .addressMasterId
                                                                          .postalCode
                                                                        }`
                                                                        : ""}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {treasurer ? (
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                                                              <div className="order-contact-card-container">
                                                                <div className="order-contact-card-main">
                                                                  <div className="order-contact-card-header-main">
                                                                    <Avatar
                                                                      label={commonGetInitials(
                                                                        treasurer.keyPersonnelName
                                                                      )}
                                                                      icon="pi pi-user"
                                                                      size="large"
                                                                      shape="circle"
                                                                      className="custom-avatar"
                                                                    />

                                                                    <div>
                                                                      <div className="order-contact-card-header-text1">
                                                                        {
                                                                          treasurer.keyPersonnelName
                                                                        }
                                                                      </div>
                                                                      <div className="order-contact-card-header-text2">
                                                                        Treasurer
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="card-devider">
                                                                    {" "}
                                                                  </div>
                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                          <path
                                                                            d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                            stroke="#175CD3"
                                                                            stroke-width="2"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                          />
                                                                        </g>
                                                                        <defs>
                                                                          <clipPath id="clip0_966_10329">
                                                                            <rect
                                                                              width="20"
                                                                              height="20"
                                                                              fill="white"
                                                                            />
                                                                          </clipPath>
                                                                        </defs>
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        treasurer.contactNo
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                          stroke="#175CD3"
                                                                          stroke-width="2"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        treasurer.emailId
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                          d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1 text-wrap">
                                                                      {treasurer
                                                                        .addressMasterId
                                                                        .addressLine1 !==
                                                                        ""
                                                                        ? `${treasurer
                                                                          .addressMasterId
                                                                          .addressLine1
                                                                        },${treasurer
                                                                          .addressMasterId
                                                                          .addressLine2
                                                                          ? `${treasurer.addressMasterId.addressLine2},`
                                                                          : ""
                                                                        }${treasurer
                                                                          .addressMasterId
                                                                          .city
                                                                        },${getState(
                                                                          treasurer
                                                                            .addressMasterId
                                                                            .stateId
                                                                        )},${treasurer
                                                                          .addressMasterId
                                                                          .postalCode
                                                                        }`
                                                                        : ""}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}

                                                          {secretary ? (
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                                                              <div className="order-contact-card-container">
                                                                <div className="order-contact-card-main">
                                                                  <div className="order-contact-card-header-main">
                                                                    <Avatar
                                                                      label={commonGetInitials(
                                                                        secretary.keyPersonnelName
                                                                      )}
                                                                      icon="pi pi-user"
                                                                      size="large"
                                                                      shape="circle"
                                                                      className="custom-avatar"
                                                                    />

                                                                    <div>
                                                                      <div className="order-contact-card-header-text1">
                                                                        {
                                                                          secretary.keyPersonnelName
                                                                        }
                                                                      </div>
                                                                      <div className="order-contact-card-header-text2">
                                                                        Secretary
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="card-devider">
                                                                    {" "}
                                                                  </div>
                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                          <path
                                                                            d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                            stroke="#175CD3"
                                                                            stroke-width="2"
                                                                            stroke-linecap="round"
                                                                            stroke-linejoin="round"
                                                                          />
                                                                        </g>
                                                                        <defs>
                                                                          <clipPath id="clip0_966_10329">
                                                                            <rect
                                                                              width="20"
                                                                              height="20"
                                                                              fill="white"
                                                                            />
                                                                          </clipPath>
                                                                        </defs>
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        secretary.contactNo
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                          stroke="#175CD3"
                                                                          stroke-width="2"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1">
                                                                      {
                                                                        secretary.emailId
                                                                      }
                                                                    </div>
                                                                  </div>

                                                                  <div className="order-contact-card-text-main">
                                                                    <div>
                                                                      <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="20"
                                                                        height="20"
                                                                        viewBox="0 0 20 20"
                                                                        fill="none"
                                                                      >
                                                                        <path
                                                                          d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                        <path
                                                                          d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                          stroke="#175CD3"
                                                                          stroke-width="1.66667"
                                                                          stroke-linecap="round"
                                                                          stroke-linejoin="round"
                                                                        />
                                                                      </svg>
                                                                    </div>
                                                                    <div className="order-contact-card-text1 text-wrap">
                                                                      {secretary
                                                                        .addressMasterId
                                                                        .addressLine1 !==
                                                                        ""
                                                                        ? `${secretary
                                                                          .addressMasterId
                                                                          .addressLine1
                                                                        },${secretary
                                                                          .addressMasterId
                                                                          .addressLine2
                                                                          ? `${secretary.addressMasterId.addressLine2},`
                                                                          : ""
                                                                        }${secretary
                                                                          .addressMasterId
                                                                          .city
                                                                        },${getState(
                                                                          secretary
                                                                            .addressMasterId
                                                                            .stateId
                                                                        )},${secretary
                                                                          .addressMasterId
                                                                          .postalCode
                                                                        }`
                                                                        : ""}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                {formData && (
                                                  <div className="bg-white">
                                                    <div
                                                      className="px-4 py-2"
                                                      style={{
                                                        backgroundColor:
                                                          "#F9FAFB",
                                                        fontFamily: "Inter",
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      <h2 className="text-lg  text-gray-800">
                                                        Contacts
                                                      </h2>
                                                    </div>
                                                    <KeyPersonelCard
                                                      data={formData}
                                                    />
                                                  </div>
                                                )}

                                                {/* <div className="bg-white">

                                                                                                <div className="px-4 py-2" style={{ backgroundColor: '#F9FAFB', fontFamily: 'Inter', fontSize: '16px' }}>
                                                                                                    <h2 className="text-lg  text-gray-800">Billing Contact Address</h2>
                                                                                                </div>

                                                                                                <div className="p-4 ">
                                                                                                    <div className='grid gap-2 justify-content-start'>
                                                                                                        {
                                                                                                            bilingAddress ? <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5'>

                                                                                                                <div className='order-contact-card-container'>
                                                                                                                    <div className='order-contact-card-main'>
                                                                                                                        <div className='order-contact-card-header-main'>

                                                                                                                            <Avatar label={commonGetInitials(bilingAddress.keyPersonnelName)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                                                            <div>
                                                                                                                                <div className='order-contact-card-header-text1'>
                                                                                                                                    {bilingAddress.keyPersonnelName}
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-header-text2'>
                                                                                                                                    {IsCheckedAgent1 ? "Billing Contact" : "Billing Contact"}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                        <div className='card-devider'> </div>
                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <g clip-path="url(#clip0_966_10329)">
                                                                                                                                        <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </g>
                                                                                                                                    <defs>
                                                                                                                                        <clipPath id="clip0_966_10329">
                                                                                                                                            <rect width="20" height="20" fill="white" />
                                                                                                                                        </clipPath>
                                                                                                                                    </defs>
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1'>
                                                                                                                                {bilingAddress.contactNo}
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1'>
                                                                                                                                {bilingAddress.emailId}
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1 text-wrap'>
                                                                                                                                {bilingAddress.addressMasterId.addressLine1 !== "" ? `${bilingAddress.addressMasterId.addressLine1},${bilingAddress.addressMasterId.addressLine2 ? `${bilingAddress.addressMasterId.addressLine2},` : ""}${bilingAddress.addressMasterId.city},${getState(bilingAddress.addressMasterId.stateId)},${bilingAddress.addressMasterId.postalCode}` : ""}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""
                                                                                                        }




                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> */}

                                                {isPaymentDone || strapiId ? (
                                                  ""
                                                ) : (
                                                  <div className="px-4">
                                                    <PaymentOptions />
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <main
                                            className="flex flex-col justify-between items-center px-4 pt-4 pb-12 rounded-lg shadow-lg font-fam-for-all max-w-[100%] max-md:px-5"
                                            style={{
                                              backgroundColor: "#F6F8FE",
                                              width: "100%",
                                              height: "800px",
                                            }}
                                          >
                                            <Skeleton className="mb-2"></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="5rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              height="2rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              height="4rem"
                                            ></Skeleton>
                                            <Skeleton className="mb-2"></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="5rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              height="2rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              height="4rem"
                                            ></Skeleton>
                                            <Skeleton className="mb-2"></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="5rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              height="2rem"
                                              className="mb-2"
                                            ></Skeleton>
                                            <Skeleton
                                              width="10rem"
                                              height="4rem"
                                            ></Skeleton>
                                          </main>
                                        </>
                                      )}

                                      {/* <div className='flex justify-content-end pl-3 dialog-form-md-group-Btn'>

                                                                                <Button type='button' className="mt-2 vstate-button font-fam-for-all text-center   justify-content-center" onClick={() => setVisible(true)} >Preview</Button>

                                                                            </div> */}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                <div className="flex flex-col justify-content-end gap-2 px-4">
                                  {/* {
                                                                        key === "Registered_Agent" ? <Button type='button' id="nextBtn" className="mt-2   vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => nextPrev(1)} >Next</Button>
                                                                            : ""
                                                                    } */}
                                  <Button
                                    type="button"
                                    id="nextBtn"
                                    disabled={showSkeleton && issavedisabled ? false : true}
                                    className="mt-2   vstate-button font-fam-for-all text-center  justify-content-center"
                                    onClick={() => nextPrev(1)}
                                  >
                                    Save & Next
                                  </Button>
                                  <Button
                                    type="button"
                                    id="submitBtn"
                                    className="mt-2   vstate-button font-fam-for-all text-center justify-content-center"
                                    // onClick={handleSubmit(
                                    //   onSubmitNew,
                                    //   onInvalid
                                    // )}
                                    onClick={(e) => {
                                      handleSubmit(onSubmitNew, onInvalid)(e);
                                      handleScroll();
                                    }}
                                  >
                                    {isPaymentDone ? "Submit" : "Checkout"}
                                  </Button>
                                  <Button
                                    type="button"
                                    id="prevBtn"
                                    onClick={() => nextPrev(-1)}
                                    className="mt-2 vstate-reverse-button   font-fam-for-all text-center justify-content-center"
                                  >
                                    Back
                                  </Button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 flex flex-column gap-2">
              <div className="main-cart-summary">
                <div className=" font-fam-for-all">
                  <div className="wizard-checkout-container">
                    <div className="wizard-checkout-main">
                      <div className="wizard-checkout-header justify-content-between">
                        <div className="wizard-checkout-text-main">
                          <div className="wizard-checkout-text">
                            CART SUMMARY
                          </div>
                        </div>
                      </div>

                      <div className="wizard-checkout-body">
                        <React.Fragment>
                          <div className="wizard-checkout-content-main">
                            {selectedCheckBoxData.length > 0 ? (
                              selectedCheckBoxData
                                .sort(
                                  (a, b) =>
                                    a?.product?.displayPreference -
                                    b?.product?.displayPreference
                                )
                                .map((val, index) => {
                                  const baseProduct = val?.product?.baseProduct;
                                  const isPriceExcluded =
                                    val?.product?.isPriceIncludedInBaseProduct;
                                  let productPrice = val?.product?.unitPrice;
                                  const isBundledPrice =
                                    val?.product?.isDefault;

                                  // Handle bundle pricing
                                  // if (val.isBundlePriceApplicable) {
                                  //     const additionalItems = Math.max(0, selectedQuantity - val.noOfItemsInBundle);
                                  //     productPrice = (val.priceOfBundle * val.noOfItemsInBundle) +
                                  //         (additionalItems * val.priceForAddlItemsOverBundle);
                                  // }

                                  return (
                                    <>
                                      {!baseProduct && !isBundledPrice && (
                                        <div className="wizard-checkout-content-price-main">
                                          <div className="wizard-price-content1">
                                            {val.product.displayName}
                                          </div>
                                          <div className="wizard-price-content2">
                                            {!isPriceExcluded
                                              ? val?.product
                                                ?.isCustomPriceApplicable
                                                ? "Custom Price"
                                                : val?.product
                                                  ?.isPriceIncludedInBaseProduct
                                                  ? "Included"
                                                  : `$${parseFloat(
                                                    val?.product?.unitPrice
                                                  ).toFixed(2)}`
                                              : "Included"}
                                          </div>
                                        </div>
                                      )}

                                      {baseProduct && !isBundledPrice && (
                                        <div className="wizard-checkout-content-price-main ml-4">
                                          <div className="wizard-price-content1">
                                            {val.product.displayName}
                                          </div>
                                          <div className="wizard-price-content2">
                                            {!isPriceExcluded
                                              ? `$${productPrice}`
                                              : `Included under ${baseProduct.displayName}`}
                                          </div>
                                        </div>
                                        // <div className="ml-4 text-muted">
                                        //     Included under {baseProduct.displayName}
                                        // </div>
                                      )}

                                      {isBundledPrice &&
                                        noOfBundle !== 0 &&
                                        allDebtorData.length > noOfBundle && (
                                          <div className="wizard-checkout-content-price-main">
                                            <div className="wizard-price-content1">
                                              {val.product.displayName} (
                                              {allDebtorData.length -
                                                noOfBundle}{" "}
                                              * $
                                              {parseFloat(productPrice).toFixed(
                                                2
                                              )}
                                              )
                                            </div>
                                            <div className="wizard-price-content2">
                                              {!isPriceExcluded
                                                ? `$${parseFloat(
                                                  (allDebtorData.length -
                                                    noOfBundle) *
                                                  productPrice
                                                ).toFixed(2)}`
                                                : `Included under ${baseProduct.displayName}`}
                                            </div>
                                          </div>
                                        )}
                                    </>
                                  );
                                })
                            ) : (
                              <div className="wizard-price-content1 flex justify-content-center">
                                No items added
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      </div>

                      <div className="wizard-checkout-footer">
                        <div className="wizard-checkout-text-footer">
                          <div className="wizard-checkout-footer-txt">
                            TOTAL
                          </div>
                        </div>
                        <div className="wizard-total-price">
                          {`$${parseFloat(calculateTotalAmount()).toFixed(2)}`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {visible9 && (
              <AddCompany
                visible={visible9}
                setVisible={setVisible9}
                pageRefresh={pageRefresh}
                setPageRefresh={setPageRefresh}
              />
            )}

            {visible2 && (
              <AddCompanyMember
                visible={visible2}
                setVisible={setVisible2}
                pageRefresh={pageRefresh}
                setPageRefresh={setPageRefresh}
                memberType={memberType}
                data={keyPersonalCompany}
                keyPersonalAddressType={keyPersonalAddressType}
                handlers={handlers}
                state={state}
              />
            )}

            {visible8 && (
              <AddBOIMember
                visible={visible8}
                setVisible={setVisible8}
                pageRefresh={pageRefresh}
                setPageRefresh={setPageRefresh}
                memberType={memberType}
                data={keyPersonalCompany}
                keyPersonalAddressType={keyPersonalAddressType}
                handlers={handlers}
                state={state}
              />
            )}

            {visible3 && (
              <AddOrderLocation
                companyId={globalCompany ? globalCompany.companyDetails : null}
                visible={visible3}
                setVisible={setVisible3}
                setLocation={setLocation}
              />
            )}

            {closeProgresbar2 && (
              <Dialog
                className="payment-dialog rounded-lg"
                visible={closeProgresbar2}
                onHide={() => setCloseProgresbar2(false)}
              >
                <section className="flex overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                  <div className="payment-icon-confirm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="24"
                      viewBox="0 0 14 24"
                      fill="none"
                    >
                      <path
                        d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1"
                        stroke="#039855"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                    <header className="flex flex-col w-full max-md:max-w-full">
                      <h2 className="text-lg font-medium leading-loose text-gray-900 max-md:max-w-full">
                        Pay Later
                      </h2>
                      <p className="mt-2 text-sm leading-5 text-gray-500 w-[500px] text-wrap">
                        You have selected the <strong>Pay Later</strong> option.
                        Your order will be received, and upon approval from
                        vState Filings, your order will be submitted.
                      </p>
                    </header>
                    <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                      <div className="flex gap-3 items-start justify-content-end self-stretch my-auto min-w-[240px]">
                        <Button
                          className="overflow-hidden gap-2 self-stretch px-5 py-2.5 rounded-lg border border-solid shadow-sm bg-white text-gray-700 border-gray-300"
                          label="Cancel"
                          onClick={() => setCloseProgresbar2(false)}
                        />
                        <Button
                          className="overflow-hidden gap-2 self-stretch px-5 py-2.5 rounded-lg border border-solid shadow-sm bg-blue-600 text-white border-blue-600"
                          label="Submit for Approval"
                          onClick={handleConfirm}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </Dialog>
            )}
            {closeProgresbar3 && (
              <Dialog
                className="payment-dialog rounded-lg"
                visible={closeProgresbar3}
                onHide={() => setCloseProgresbar3(false)}
              >
                <section className="flex rounded-lg overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                  <div className="payment-icon-confirm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="24"
                      viewBox="0 0 14 24"
                      fill="none"
                    >
                      <path
                        d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1"
                        stroke="#039855"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                    <header className="flex flex-col w-full max-md:max-w-full">
                      <h2 className="text-lg font-medium leading-loose text-gray-900 max-md:max-w-full">
                        Pay Now
                      </h2>
                      <p className="mt-2 text-sm leading-5 text-gray-500 w-[500px] text-wrap">
                        You selected the <strong>Pay Now</strong> option. Please
                        complete your payment to finalize your order.
                      </p>
                    </header>
                    <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                      <div className="flex justify-content-end gap-3 items-start self-stretch my-auto min-w-[240px]">
                        <Button
                          className="vstate-reverse-button"
                          label="Cancel"
                          onClick={() => setCloseProgresbar3(false)}
                        />
                        <Button
                          className="vstate-button"
                          label="Proceed to payment"
                          onClick={handleConfirm}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </Dialog>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EditOrderForm;
