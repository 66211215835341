
import React, { useEffect } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import "../CommonSection/CommonStyle/CommonStyle.css";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import AOS from "aos";
import "aos/dist/aos.css";


const RegisteredFaq = () => {
  const faqItems = [
    {
      key: "0",
      question: "Who needs a registered agent?",
      answer:
        "Registered agents are required for businesses in most states. But beyond legal requirements, registered agents are useful for busy entrepreneurs. They are available during business hours to receive legal notices and official state mail so you can focus on other aspects of your business.",
    },
    {
      key: "1",
      question: "Why do I need a registered agent?",
      answer:
        "A registered agent is legally required for businesses in most states to ensure that important legal documents are properly received. It helps your business stay compliant with state regulations and ensures you don’t miss critical deadlines.",
    },
    {
      key: "2",
      question: "Can I change my registered agent?",
      answer:
        "Yes, you can change your registered agent at any time. To do so, you'll need to file the appropriate form with your state's business filing office. This process may vary depending on the state, but we can assist you in making the change smoothly and ensuring that all legal requirements are met.",
    },
    {
      key: "3",
      question: "How do I appoint a registered agent?",
      answer:
        "To appoint a registered agent, list their name and address on your business formation documents when filing with your state's business office. You can choose an individual or a professional service like vState Filings’ registered agent service, and their information must be kept current.",
    },
    {
      key: "4",
      question: "What could happen to a business if it doesn’t have a registered agent?",
      answer:
        "If a business doesn't have a registered agent, it risks falling out of compliance with state regulations, which can lead to penalties, fines, or even dissolution. The business may also miss important legal documents, leading to potential legal issues.",
    },
    {
      key: "5",
      question:
        "What are the requirements for being a registered agent service provider?",
      answer:
        "To be a registered agent service provider, the individual or entity must have a physical address (not a P.O. Box) in the state where the business is registered and be available during standard business hours to receive legal documents and official notices on behalf of the business. The provider must also be authorized to do business in that state.",
    },
    {
        key: "6",
        question:
          "Is vState Filings Registered Agent service available in all states?",
        answer:
          "Yes, our registered agent service is available in all 50 states, allowing you to operate your business nationwide while ensuring compliance with state requirements.",
      },
      {
        key: "7",
        question:
          "How much does the Registered Agent service cost?",
        answer:
          "The cost of our registered agent service varies by state and plan. We offer competitive pricing with no hidden fees. Contact us for a detailed quote based on your specific needs.",
      },
      {
        key: "8",
        question:
          "Will I be notified if you receive documents on my behalf?",
        answer:
          "Yes, we will promptly notify you via email and provide online access to any documents we receive on your behalf, ensuring that you never miss an important notice.",
      },
  ];

  // Split FAQs into two columns
  const firstColumnFaqs = faqItems.filter((_, index) => index % 2 === 0);
  const secondColumnFaqs = faqItems.filter((_, index) => index % 2 !== 0);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="main-section-class" data-aos="fade-up"
    data-aos-duration="3000">
      <div className="common-faq">
        <Container>
          <div className="mb-5 mt-5">
            <CommonHeadingTitle heading={"FAQ"} />
          </div>
          <Accordion defaultActiveKey="0">
            <Row>
              <Col lg={6} md={6} sm={12}>
                {firstColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
              <Col lg={6} md={6} sm={12}>
                {secondColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
            </Row>
          </Accordion>
        </Container>
      </div>
    </section>
  );
};

export default RegisteredFaq;
