import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import useTableData from "../redux/Reducers/useTableData";
import authContext from "../common/authContext";
import EditCompanyMember from "../components/Company/EditCompanyComponents/EditKeyPersonnelType";
import companyMasterService from "../services/companyMasterService";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Skeleton } from "primereact/skeleton";

const KeyPersonnelOrderDataTable = ({
  data,
  setterFunction,
  responseValue,
  keyValue,
  formCounts,
  setFormCounts,
  type = null,
}) => {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh,
  } = useContext(authContext);

  const {
    data: fetchedStateData,
    isLoading: stateLoading,
    isError: isStateError,
    error: stateError,
    refetch: reftechStateData,
  } = useTableData("state");

  const [products, setProducts] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const [tempProduct, setTempProduct] = useState([]);

  useEffect(() => {
    if (globalCompany) {
      if (globalCompany.companyDetails) {
        companyMasterService
          .getAllKeyPersonnelByCompanyId(globalCompany.companyDetails.id)
          .then((res) => {
            if (res.data !== "") {
              if (Array.isArray(res.data)) {
                const data = res.data.filter((val) => val?.addressMasterId);
                setProducts(data);
                setTempProduct(data);
                setShowSkeleton(true);
              } else {
                setShowSkeleton(true);
              }
            } else {
              setShowSkeleton(true);
            }
          })
          .catch((err) => {
            setShowSkeleton(true);
          });
      }
    }
  }, [globalCompany, isTabRefresh]);

  const getState = (id) => {
    if (fetchedStateData && Array.isArray(fetchedStateData)) {
      const data = fetchedStateData.find((val) => val.id === id);
      if (data) {
        return data?.stateFullDesc;
      }
    }
  };

  const [allMember, setAllMember] = useState(["Member", "Manager"]);

  const [memberOrManagerValue, setMemberOrManagerValue] = useState({});

  const formattedData = data.map((item) => Object.values(item)[0]);

  const [filteredInput, setFilteredInput] = useState("");

  const [selectedData, setSelectedData] = useState([]);

  const hasPermission = (permission) => permissions.includes(permission);

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const formattedData = data.map((item) => Object.values(item)[0]);
      setSelectedData(formattedData);
    } else {
      setSelectedData([]);
    }
  }, []);

  const [visible, setVisible] = useState(false);

  const [keyPersonalData, setKeyPersonnelData] = useState(null);

  const handleEdit = (rowdata) => {
    setVisible(true);
    setKeyPersonnelData(rowdata);
  };

  const addressBody = (rowdata) => {
    const address = [
      rowdata?.addressMasterId?.addressLine1,
      rowdata?.addressMasterId?.addressLine2,
      rowdata?.addressMasterId?.city,
      getState(rowdata?.addressMasterId?.stateId),
      rowdata?.addressMasterId?.postalCode,
    ]
      .filter(Boolean)
      .join(", ");

    return <div>{address}</div>;
  };

  const iconBodyTemplate = (rowdata) => {
    return (
      <>
        <Button
          data-toggle="tooltip"
          data-placement="bottom"
          title="Edit"
          onClick={() => handleEdit(rowdata)}
          // icon="pi pi-file-edit"
          style={{
            border: "none",
            height: "40px",
            backgroundColor: "transparent",
            color: "blue",
          }}
          className="border-none h-10 bg-transparent text-#175CD3 hover:bg-#175CD3 hover:text-blue-800 transition duration-200"
        >
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g clip-path="url(#clip0_1308_27815)">
              <path
                d="M9.16675 3.33332H3.33341C2.89139 3.33332 2.46746 3.50891 2.1549 3.82147C1.84234 4.13403 1.66675 4.55796 1.66675 4.99999V16.6667C1.66675 17.1087 1.84234 17.5326 2.1549 17.8452C2.46746 18.1577 2.89139 18.3333 3.33341 18.3333H15.0001C15.4421 18.3333 15.866 18.1577 16.1786 17.8452C16.4912 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333M15.4167 2.08332C15.7483 1.7518 16.1979 1.56555 16.6667 1.56555C17.1356 1.56555 17.5852 1.7518 17.9167 2.08332C18.2483 2.41484 18.4345 2.86448 18.4345 3.33332C18.4345 3.80216 18.2483 4.2518 17.9167 4.58332L10.0001 12.5L6.66675 13.3333L7.50008 9.99999L15.4167 2.08332Z"
                stroke="#004EEC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1308_27815">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span
            style={{
              marginLeft: "5px",
            }}
            className="text-#175CD3 font-inter text-sm font-medium leading-5"
          >
            Edit
          </span>
        </Button>

        {/* <Button
                    type="button"
                    icon="pi pi-minus"
                    disabled={
                        (formCounts[key] || 1) === 1
                    }
                    className="vstate-button font-fam-for-all text-center justify-content-center"
                    onClick={() =>
                        handleRemoveRepeatForm(key)
                    }
                /> */}
      </>
    );
  };

  useEffect(() => {
    if (responseValue) {
      const currentCount = formCounts[keyValue] || 1;
      setSelectedData((prevData) => {
        return [...prevData, responseValue];
      });
      setFormCounts((prevCounts) => ({
        ...prevCounts,
        [keyValue]: (prevCounts[keyValue] || 1) + 1,
      }));
      const updatedData = {
        ...responseValue,
        member_or_manager_value: "Member",
      };
      if (currentCount > 1) {
        if (keyValue === "Member_Or_Manager_Details") {
          setterFunction((prevData) => {
            const updatedMember = {
              [`member_or_manger_details-${currentCount - 1}`]: updatedData,
            };
            return [
              ...prevData.filter(
                (data) => !data[`member_or_manger_details-${currentCount - 1}`]
              ),
              updatedMember,
            ];
          });
        }
      } else {
        if (keyValue === "Member_Or_Manager_Details") {
          setterFunction((prevData) => {
            const updatedMember = {
              [`member_or_manger_details-0`]: responseValue,
            };
            return [
              ...prevData.filter((data) => !data[`member_or_manger_details-0`]),
              updatedMember,
            ];
          });
        }
      }
    }
  }, [responseValue]);

  const contactBody = (rowdata) => {
    return (
      <>
        <div>{`${rowdata.contactNo}`}</div>
      </>
    );
  };

  const handleSearch = () => {
    if (filteredInput.trim() !== "") {
      const lowerCaseInput = filteredInput.toLowerCase();

      const searchNestedObject = (obj) => {
        for (const key in obj) {
          if (obj[key] !== null) {
            if (typeof obj[key] === "object") {
              if (searchNestedObject(obj[key])) {
                return true;
              }
            } else if (
              typeof obj[key] === "string" &&
              obj[key].toLowerCase().includes(lowerCaseInput)
            ) {
              return true;
            } else if (
              typeof obj[key] === "number" &&
              obj[key].toString().includes(lowerCaseInput)
            ) {
              return true;
            }
          }
        }
        return false;
      };

      const temp = tempProduct.filter((val) => searchNestedObject(val));
      setProducts(temp);
    } else {
      setProducts(tempProduct);
    }
  };
  useEffect(() => {
    handleSearch();
  }, [filteredInput]);

  const header = () => {
    return (
      <div className="flex flex-wrap align-items-center justify-content-start gap-3 ml-3">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            placeholder="Search"
            style={{ borderRadius: "6px", width: "200px" }}
            value={filteredInput}
            className="vstate-input-field-fixed"
            onChange={(e) => {
              setFilteredInput(e.target.value);

              if (e.target.value.trim() === "") {
                setProducts(tempProduct);
              }
            }}
          />
        </span>
      </div>
    );
  };

  const onCheckboxChange = (event, datas, index) => {
    const currentCount = formCounts[keyValue] || 1;
    // const currentCount = formCounts[keyValue] || 0;

    if (event.target.checked) {
      setSelectedData([...selectedData, datas]);

      setFormCounts((prevCounts) => ({
        ...prevCounts,
        [keyValue]: (prevCounts[keyValue] || 1) + 1,
      }));
      const updatedData = {
        ...datas,
        member_or_manager_value: memberOrManagerValue[index] || "Member",
      };

      if (currentCount > 1) {
        if (keyValue === "Member_Or_Manager_Details") {
          setterFunction((prevData) => {
            const updatedMember = {
              [`member_or_manger_details-${currentCount - 1}`]: updatedData,
            };
            return [
              ...prevData.filter(
                (data) => !data[`member_or_manger_details-${currentCount - 1}`]
              ),
              updatedMember,
            ];
          });
        } else if (keyValue === "UCC_Debtor_Details") {
          setterFunction((prevData) => {
            const updatedMember = {
              [`debtor_${currentCount - 1}`]: datas,
            };
            return [
              ...prevData.filter((data) => !data[`debtor_${currentCount - 1}`]),
              updatedMember,
            ];
          });
        } else if (keyValue === "UCC_Secured_Party_Details") {
          setterFunction((prevData) => {
            const updatedMember = {
              [`securedParty1_${currentCount - 1}`]: datas,
            };
            return [
              ...prevData.filter(
                (data) => !data[`securedParty1_${currentCount - 1}`]
              ),
              updatedMember,
            ];
          });
        } else if (type && type === "Additional_Debtors") {
          setterFunction((prevData) => {
            const updatedMember = {
              [`additionalDebtor_${currentCount - 1}`]: datas,
            };
            return [
              ...prevData.filter(
                (data) => !data[`additionalDebtor_${currentCount - 1}`]
              ),
              updatedMember,
            ];
          });
        } else if (type && type === "Additional_SecuredParties") {
          setterFunction((prevData) => {
            const updatedMember = {
              [`securedParty2_${currentCount - 1}`]: datas,
            };
            return [
              ...prevData.filter(
                (data) => !data[`securedParty2_${currentCount - 1}`]
              ),
              updatedMember,
            ];
          });
        }
      } else {
        if (keyValue === "Member_Or_Manager_Details") {
          setterFunction((prevData) => {
            const updatedMember = {
              [`member_or_manger_details-0`]: updatedData,
            };
            return [
              ...prevData.filter((data) => !data[`member_or_manger_details-0`]),
              updatedMember,
            ];
          });
        } else if (keyValue === "UCC_Debtor_Details") {
          setterFunction((prevData) => {
            const updatedMember = {
              [`debtor_0`]: datas,
            };
            return [
              ...prevData.filter((data) => !data[`debtor_0`]),
              updatedMember,
            ];
          });
        } else if (keyValue === "UCC_Secured_Party_Details") {
          setterFunction((prevData) => {
            const updatedMember = {
              [`securedParty1_0`]: datas,
            };
            return [
              ...prevData.filter((data) => !data[`securedParty1_0`]),
              updatedMember,
            ];
          });
        } else if (type && type === "Additional_Debtors") {
          setterFunction((prevData) => {
            const updatedMember = {
              [`additionalDebtor_0`]: datas,
            };
            return [
              ...prevData.filter((data) => !data[`additionalDebtor_0`]),
              updatedMember,
            ];
          });
        } else if (type && type === "Additional_SecuredParties") {
          setterFunction((prevData) => {
            const updatedMember = {
              [`securedParty2_0`]: datas,
            };
            return [
              ...prevData.filter((data) => !data[`securedParty2_0`]),
              updatedMember,
            ];
          });
        }
      }
    } else {
      setSelectedData((prevSelected) =>
        prevSelected.filter((item) => item.id !== datas.id)
      );
      setFormCounts((prevCounts) => ({
        ...prevCounts,
        [keyValue]: currentCount - 1,
      }));
      setterFunction((prevData) => {
        return prevData.filter((item, i) => {
          const itemData = Object.values(item)[0];

          return itemData?.id !== datas?.id;
        });
      });
    }
  };

  // const onCheckboxChange = (event, datas, index) => {
  //   // Use functional update to update selectedData to avoid stale closures
  //   setSelectedData((prevSelected) => {
  //     if (event.target.checked) {
  //       return [...prevSelected, datas];
  //     } else {
  //       return prevSelected.filter((item) => item.id !== datas.id);
  //     }
  //   });

  //   // Update formCounts using functional update so we have the latest value
  //   setFormCounts((prevCounts) => {
  //     // Initialize count to 0 instead of 1
  //     const currentCount = prevCounts[keyValue] ?? 0;
  //     // Determine new count based on the event
  //     const newCount = event.target.checked ? currentCount + 1 : Math.max(0, currentCount - 1);
  //     // Determine the index to use for key generation
  //     // For adding: use currentCount (since it starts at 0),
  //     // For removal: currentCount - 1 (assuming you're removing the last one)
  //     const updateIndex = event.target.checked ? currentCount : currentCount - 1;

  //     // Map keyValue (or type) to a key prefix
  //     let prefix = "";
  //     if (keyValue === "Member_Or_Manager_Details") {
  //       prefix = "member_or_manger_details";
  //     } else if (keyValue === "UCC_Debtor_Details") {
  //       prefix = "debtor";
  //     } else if (keyValue === "UCC_Secured_Party_Details") {
  //       prefix = "securedParty1";
  //     } else if (type === "Additional_Debtors") {
  //       prefix = "additionalDebtor";
  //     } else if (type === "Additional_SecuredParties") {
  //       prefix = "securedParty2";
  //     }

  //     // If checking, update the setterFunction with the new item
  //     if (event.target.checked) {
  //       const updatedData = {
  //         ...datas,
  //         member_or_manager_value: memberOrManagerValue[index] || "Member",
  //       };
  //       const updateKey = `${prefix}_${updateIndex}`;
  //       setterFunction((prevData) => {
  //         // Remove any previous item with the same updateKey and add the new one
  //         const filtered = prevData.filter((item) => !item.hasOwnProperty(updateKey));
  //         return [...filtered, { [updateKey]: prefix === "member_or_manger_details" ? updatedData : datas }];
  //       });
  //     } else {
  //       // On unchecking, remove the data from the setterFunction state
  //       setterFunction((prevData) =>
  //         prevData.filter((item) => {
  //           const key = Object.keys(item)[0];
  //           return item[key]?.id !== datas.id;
  //         })
  //       );
  //     }

  //     return { ...prevCounts, [keyValue]: newCount };
  //   });
  // };

  const checkBoxBody = (datas, index) => {
    const formattedData = data.map((item) => Object.values(item)[0]?.id);

    return (
      <div className="srch-cand-checkbox">
        <Checkbox
          onChange={(e) => onCheckboxChange(e, datas, index)}
          checked={selectedData.some((val) => val?.id === datas?.id)}
        />
      </div>
    );
  };

  const handleMemberChange = (value, index) => {
    setMemberOrManagerValue((prev) => ({
      ...prev,
      [index]: value,
    }));
  };

  const memberBody = (value, index) => {
    const formattedData = data.filter(
      (item) => Object.values(item)[0]?.id === value?.id
    );

    const memberValue =
      formattedData.length > 0
        ? formattedData[0][Object.keys(formattedData[0])[0]]
            ?.member_or_manager_value
        : "Member";

    return (
      <>
        <Dropdown
          value={memberOrManagerValue[index] || memberValue || "Member"}
          filter
          resetFilterOnHide
          disabled={selectedData.some((val) => val?.id === value?.id)?true:false}
          onChange={(e) => handleMemberChange(e.value, index)}
          options={allMember}
          style={{ height: "40px" }}
          dropdownIcon={selectedData.some((val) => val?.id === value?.id)?null:"pi pi-chevron-down"}
          className="w-full"
        />
      </>
    );
  };

  const items = Array.from({ length: 5 }, (v, i) => i);

  return (
    <div className="vstate-card">
      {showSkeleton ? (
        <DataTable
          value={products}
          rows={25}
          rowsPerPageOptions={[25, 50, 75, 100]}
          inline
          paginator
          scrollable
          // dataKey="name"
          className="font-fam-for-all"
          scrollHeight="flex"
          scrollWidth="100%"
          header={header}
          globalFilterFields={["keyPersonnelName, emailId, contactInfo, Address"]}
        >
          <Column
            body={(data, { rowIndex }) => checkBoxBody(data, rowIndex)}
            className="font-fam-for-all datatable-xs-col"
          />
          {keyValue === "Member_Or_Manager_Details" && (
            <Column
              header="Member Or Manager"
              body={(rowData, { rowIndex }) => memberBody(rowData, rowIndex)}
              className="font-fam-for-all datatable-lg-col webapp-sm-size"
            />
          )}
          {/* <Column field='id' header="ID" className='font-fam-for-all datatable-lg-col webapp-sm-size' sortable></Column> */}

          <Column
            key="keyPersonnelName"
            field="keyPersonnelName"
            header="Name"
            className="font-fam-for-all datatable-lg-col webapp-sm-size"
            sortable
          ></Column>
          <Column
            key="emailId"
            field="emailId"
            header="Email"
            className="font-fam-for-all datatable-lg-col webapp-sm-size"
            sortable
          />
          <Column
          key='contactInfo'
            field="contactInfo"
            header="Contact Info"
            body={contactBody}
            className="font-fam-for-all datatable-lg-col webapp-sm-size"
          ></Column>
          <Column
          key='Address'
            header="Address"
            body={addressBody}
            className="font-fam-for-all datatable-lg-col webapp-sm-size"
          ></Column>

          {/* <Column field="keyPersonnelName" header="Name" className='font-fam-for-all datatable-lg-col webapp-sm-size' sortable />
                <Column field="keyPersonnelTitle" header="Title" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
                <Column field="contactNo" header="Contact No" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
                <Column field="emailId" header="Email" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
                <Column field="companyName" header="Company Name" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
                <Column field="primaryContact" header="Primary Contact" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
                <Column field="companyPhone" header="Company Phone" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
                <Column field="address" header="Address" className='font-fam-for-all datatable-lg-col webapp-sm-size' /> */}

          {/* {hasPermission("AllowViewAndUpdateCompanies") && <Column body={iconBodyTemplate} header="Action" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>} */}
        </DataTable>
      ) : (
        <DataTable
          value={items}
          rows={25}
          rowsPerPageOptions={[25, 50, 75, 100]}
          inline
          paginator
          emptyMessage="No Candidate Found"
          selectionMode="single"
          metaKeySelection={false}
          scrollable
          scrollHeight="flex"
          scrollWidth="100%"
        >
          <Column body={<Skeleton />} className="datatable-body-icon"></Column>
        </DataTable>
      )}
      {visible && (
        <EditCompanyMember
          visible={visible}
          setVisible={setVisible}
          data={keyPersonalData}
        />
      )}
    </div>
  );
};

export default KeyPersonnelOrderDataTable;
