import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import "../../CommonSection/CommonStyle/CommonStyle.css";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";

const CompilenceFaq = () => {
  const faqItems = [
    {
      key: "0",
      question: "What happens if I miss a compliance filing?",
      answer:
        "Missing deadlines can result in penalties, loss of good standing, or suspension of business operations.",
    },
    {
      key: "1",
      question:
        "How do I reinstate a dissolved entity?",
      answer:
        "We help file the necessary reinstatement forms to reactivate your business after dissolution.",
    },
    {
      key: "2",
      question:
        "Why is the annual report important?",
      answer:
        "Annual reports ensure your business information is up-to-date with the state to help maintain compliance.",
    },
    {
      key: "3",
      question: "What is the BOI reporting requirement?",
      answer:
        "The Beneficial Ownership Information (BOI) reporting helps identify the individuals who control the business.",
    },
    {
      key: "4",
      question: "How often do I need to file compliance documents?",
      answer:
        "Most compliance filings are due annually or biennially, but some  industries may have specific requirements with different filing  schedules.",
    },
    {
      key: "5",
      question:
        "Do I need to publish my LLC formation?",
      answer:
        "Maybe. Some states require a published notice of their LLC formation.",
    },
    {
      key: "6",
      question: "What is a DBA publication?",
      answer:
        "A DBA publication is the process of publicly announcing your Doing Business As name in a local newspaper, which is required by some states if you're operating under a trade name.",
    },
    {
      key: "7",
      question: "How do I ensure ongoing compliance?",
      answer:
        "Our subscription services help track and manage your filing deadlines, ensuring you stay compliant year-round.",
    },
  ];

  // Split FAQs into two columns
  const firstColumnFaqs = faqItems.filter((_, index) => index % 2 === 0);
  const secondColumnFaqs = faqItems.filter((_, index) => index % 2 !== 0);

  return (
    <section className="main-section-class">
      <div className="common-faq">
        <Container>
          <div className="mb-5 mt-5">
            <CommonHeadingTitle heading={"FAQ"} />
          </div>
          <Accordion defaultActiveKey="0">
            <Row>
              <Col lg={6} md={6} sm={12}>
                {firstColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
              <Col lg={6} md={6} sm={12}>
                {secondColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
            </Row>
          </Accordion>
        </Container>
      </div>
    </section>
  );
};

export default CompilenceFaq;
