import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import companyMasterService from "../../services/companyMasterService";
import authContext from "../../common/authContext";
import EditCompanyMember from "./EditCompanyComponents/EditKeyPersonnelType";
import useTableData from "../../redux/Reducers/useTableData";
import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";

const MemberOrManager = ({ data }) => {
  const { permissions, isTabRefresh } = useContext(authContext);
  const { data: fetchedStateData } = useTableData("state");

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [keyPersonalData, setKeyPersonnelData] = useState(null);

  const hasPermission = (permission) => permissions.includes(permission);

  useEffect(() => {
    if (data) {
      setLoading(true);
      companyMasterService
        .getAllKeyPersonnelByCompanyId(data.id)
        .then((res) => {
          if (res.data && Array.isArray(res.data)) {
            const temp = res.data.filter((val) => val.keyPersonnelTypeId === 1003);
            setProducts(temp);
          }
          setLoading(false);
        })
        .catch((err) => {
          
          setLoading(false);
        });
    }
  }, [data, isTabRefresh]);

  const getState = (id) => {
    if (fetchedStateData && Array.isArray(fetchedStateData)) {
      const stateObj = fetchedStateData.find((val) => val.id === id);
      return stateObj ? stateObj.stateFullDesc : "";
    }
    return "";
  };

  const addressBody = (rowdata) => {
    return loading ? (
      <Skeleton width="100%" height="2rem" />
    ) : (
      <div>
        {[
          rowdata?.addressMasterId?.addressLine1,
          rowdata?.addressMasterId?.addressLine2,
          rowdata?.addressMasterId?.city,
          getState(rowdata?.addressMasterId?.stateId),
          rowdata?.addressMasterId?.postalCode,
        ]
          .filter(Boolean)
          .join(", ")}
      </div>
    );
  };

  const contactBody = (rowdata) => {
    return loading ? (
      <Skeleton width="100%" height="2rem" />
    ) : (
      <div>{`${rowdata.emailId}, ${rowdata.contactNo}`}</div>
    );
  };

  const handleEdit = (rowdata) => {
    setVisible(true);
    setKeyPersonnelData(rowdata);
  };

  const iconBodyTemplate = (rowdata) => {
    return loading ? (
      <Skeleton width="100%" height="2rem" />
    ) : (
      <Button
        data-toggle="tooltip"
        title="Edit"
        onClick={() => handleEdit(rowdata)}
        style={{
          border: "none",
          height: "40px",
          backgroundColor: "transparent",
          color: "blue",
        }}
        className="border-none h-10 bg-transparent text-#175CD3 hover:bg-#175CD3 hover:text-blue-800 transition duration-200"
      >
        Edit
      </Button>
    );
  };

  const dummyRows = Array.from({ length: 3 }, (_, index) => ({ id: index }));

  return (
    <div>
      <DataTable
        value={loading ? dummyRows : products}
        scrollable
        dataKey="name"
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        className="font-fam-for-all"
        scrollHeight="flex"
        scrollWidth="100%"
        globalFilterFields={["djjddjjd"]}
      >
        <Column
          field="keyPersonnelName"
          header="Name"
          className="font-fam-for-all datatable-lg-col webapp-sm-size"
          body={(rowData) =>
            loading ? <Skeleton width="100%" height="2rem" /> : rowData.keyPersonnelName
          }
        ></Column>
        <Column
          field="keyPersonnelTitle"
          header="Title"
          className="font-fam-for-all datatable-lg-col webapp-sm-size"
          body={(rowData) =>
            loading ? <Skeleton width="100%" height="2rem" /> : rowData.keyPersonnelTitle
          }
        ></Column>
        <Column
          field="contactInfo"
          header="Contact Info"
          body={contactBody}
          className="font-fam-for-all datatable-lg-col webapp-sm-size"
        ></Column>
        <Column
          header="Address"
          body={addressBody}
          className="font-fam-for-all datatable-lg-col webapp-sm-size"
        ></Column>
        {hasPermission("AllowViewAndUpdateCompanies") && (
          <Column
            body={iconBodyTemplate}
            header="Action"
            className="font-fam-for-all datatable-lg-col webapp-sm-size"
          ></Column>
        )}
      </DataTable>
      {visible && (
        <EditCompanyMember
          visible={visible}
          setVisible={setVisible}
          data={keyPersonalData}
        />
      )}
    </div>
  );
};

export default MemberOrManager;
