import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useContext, useEffect, useState } from 'react';
import MasterServices from '../../services/coreServices';
import authContext from '../../common/authContext';

const CompanyDocuments = ({ data }) => {
    const { permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading,setIsTabRefresh,isTabRefresh } = useContext(authContext);

    const [products, setProducts] = useState([]);

    

    const [allCompanyDocuments, setAllCompanyDocuments] = useState([])

    useEffect(() => {
        MasterServices.getAllDocuments()
            .then((res) => {
                console.log(res)
                if (Array.isArray(res.data)) {
                    setAllCompanyDocuments(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    // Use useEffect to filter documents based on IDs
    useEffect(() => {
        
        if (data && data.documentIds !== undefined && data.documentIds.length > 0) {
            const filteredDocuments = allCompanyDocuments.filter(doc => data.documentIds.includes(doc.id));
            setProducts(filteredDocuments);
        }

    }, [data, allCompanyDocuments,isTabRefresh]);

    function downloadPDFFromBase64(base64String, filename) {
        // Convert base64 to blob
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/file' });

        // Create URL for the blob
        const url = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;

        // Simulate click event
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    const handleFileDownload=(rowdata)=>{
        if (rowdata.documentPath) {
            MasterServices.getFile(rowdata.documentPath)
            .then((res)=>{
                console.log(res)
                downloadPDFFromBase64(res.data,rowdata.documentName)

            })
            .catch((err)=>{
                console.log(err)
            })
        }
    }

    // Action column template
    const iconBodyTemplate = (rowData) => {
        console.log(rowData)
        return (
            <>
                <button className="datatable-highlight-color" onClick={()=>handleFileDownload(rowData)}>
                    <span><i className='pi pi-download mr-1'></i></span>Download
                </button>
            </>
        );
    };

    return (
        <div>
            <DataTable
                value={products}
                scrollable
                dataKey='id'
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                className='font-fam-for-all datatable-sm'
            >
                <Column field='documentName' header="Name" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                <Column body={iconBodyTemplate} header="Action" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
            </DataTable>
        </div>
    );
};

export default CompanyDocuments;
