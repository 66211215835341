import React, { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Button } from "primereact/button";
import vstateLogo from "../Assets/Images/INVOICE-LOGO.png";
import { Col, Row, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";

const SalesReciptInvoice = ({
  orderTitle,
  clientName,
  orderNumber,
  orderDate,
  transactionId,
  transactionDate,
  companyName,
  billingInfo,
  shippingInfo,
  items,
  subtotal,
  shippingHandling,
  grandTotal,
  state,
  entityId,
}) => {
  // Reference to the component
  const componentRef = useRef();

  console.log(items);

  const downloadPDF = () => {
    const input = componentRef.current;
    html2canvas(input, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      // Calculate width and height to maintain aspect ratio
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const pdfHeight = (canvasHeight * pdfWidth) / canvasWidth;

      // Add image to PDF
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${orderNumber}_Sales_Reciept.pdf`);
    });
  };
  return (
    <div style={{ maxWidth: "628px", fontFamily: "Inter" }}>
      <div className="flex justify-content-end mt-2">
        <div className="flex gap-2">

          <ReactToPrint
            trigger={() => (
              <Button
                className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                icon="pi pi-print"
                label="Print"
              ></Button>
            )}
            content={() => componentRef.current}
          />
          <Button
            className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
            label="Download Sales Receipt as PDF"
            icon="pi pi-download"
            onClick={downloadPDF}
          ></Button>
        </div>
      </div>
      <div className="main-classs-pdfdd mt-3">
        <div ref={componentRef} className="p-4 position-relative">
          {transactionId && (
            <div className="paid-stamp">PAID</div>
          )}
          {/* <div className="invoice-container">
            <div className="company-info">
              <div className="company-header">
                <img
                  src={vstateLogo}
                  loading="lazy"
                  alt="vState Filings LLC Company Logo"
                  className="company-logo"
                />
                <div className="vertical-divider"></div>
                <div className="company-address">
                  vState Filings LLC
                  <br />
                  301 Mill Road, Suite U-5
                  <br />
                  Hewlett, NY 11557
                  <br />
                  718-569-2703
                </div>
              </div>
              <div className="bill-to-section" style={{ width: "226px" }}>
                <div className="bill-to-column">
                  <div className="bill-to-header" style={{ maxWidth: "226px" }}>
                    <div>Bill To</div>
                  </div>
                  <div className="mt-2">
                    <p className="bill-to-cell">{billingInfo.contactName}</p>
                    <p className="bill-to-cell">{billingInfo.address}</p>
                    <p className="bill-to-cell">{billingInfo.address2}</p>
                    <p className="bill-to-cell">
                      {[billingInfo.city, billingInfo.state, billingInfo.zip]
                        .filter(Boolean)
                        .join(", ")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="invoice-details">
              <div className="invoice-title">Sales Reciept</div>
              <div className="invoice-info-grid">
                <div className="date-column">
                  <div className="info-header">
                    <div>Date</div>
                  </div>
                  <div className="info-cell">
                    <div>{orderDate}</div>
                  </div>
                </div>
                <div className="invoice-number-column">
                  <div className="info-header">
                    <div>Order Number #</div>
                  </div>
                  <div className="info-cell">
                    <div>{orderNumber}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <InvoiceTable
            grandTotal={subtotal}
            items={items}
            orderTitle={orderTitle}
            state={state}
            transactionId={transactionId}
            transactionDate={transactionDate}
          /> */}

          <div className="main-classs-pdf-inovice">
            <div className="d-flex justify-content-between">
              <img
                src={vstateLogo}
                loading="lazy"
                alt="vState Filings LLC Company Logo"
                className="company-logo"
              />
              <h3 className="invoice-title">Sales Receipt</h3>
            </div>
            <div class="footer-divider mt-3 mb-3"></div>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                {/* <img
                  src={vstateLogo}
                  loading="lazy"
                  alt="vState Filings LLC Company Logo"
                  className="company-logo"
                /> */}
                {/* <div className="vertical-divider"></div> */}
                <div className="main-address ms-1">
                  <h4 className="adderess-title"> vState Filings LLC</h4>
                  <p className="address-textt"> 301 Mill Road, Suite U-5</p>
                  <p className="address-textt"> Hewlett, NY 11557</p>
                  <p className="address-textt"> 718-569-2703</p>
                </div>
              </div>
              <div>
                <div className="text-center">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Order Number #</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{orderDate}</td>
                        <td>{orderNumber}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <Row>
              <Col md={5}>
                <div className="mt-5">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>Bill To</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <p className="bill-to-text">
                            {billingInfo.contactName}
                          </p>
                          <p className="bill-to-text">{billingInfo.address}</p>
                          <p className="bill-to-text">{billingInfo.address2}</p>
                          <p className="bill-to-text mb-2">
                            {" "}
                            {[
                              billingInfo.city,
                              billingInfo.state,
                              billingInfo.zip,
                            ]
                              .filter(Boolean)
                              .join(", ")}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col md={7}>
                <div className="mt-5">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th className="text-end">Transaction ID #</th>
                        <th className="text-end">Transaction Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-end">{transactionId}</td>
                        <td className="text-end">{transactionDate}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={12}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Description</th>
                      <th>State</th>
                      <th>Entity ID</th>
                      <th className="text-end">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <ItemColumn
                          items={items}
                          field="displayName"
                          orderTitle={orderTitle}
                          state={state}
                          entityId={entityId}
                        />
                      </td>
                      <td>
                        {" "}
                        <ItemColumn
                          items={items}
                          field="description"
                          orderTitle={orderTitle}
                          state={state}
                          entityId={entityId}
                        />
                      </td>
                      <td>
                        <ItemColumn
                          items={items}
                          field="State"
                          orderTitle={orderTitle}
                          state={state}
                          entityId={entityId}
                        />
                      </td>
                      <td>
                        <ItemColumn
                          items={items}
                          field="quantity"
                          orderTitle={orderTitle}
                          state={state}
                          entityId={entityId}
                        />
                      </td>
                      <td className="text-end">
                        <ItemColumn
                          items={items}
                          field="unitPrice"
                          orderTitle={orderTitle}
                          state={state}
                          entityId={entityId}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="justify-content-end">
              <Col md={6}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Payments/Credits</th>
                      <td className="text-end">-${grandTotal.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th>Balance Due</th>
                      <td className="text-end">$0.00</td>
                    </tr>
                  </thead>
                </Table>
              </Col>
            </Row>
            <div class="footer-divider mt-4"></div>
            <div className="blue-borderrrrr mt-3">

              {/* <p className="normal-pdf-text">
                Payments can be made online at:
                https://secure.merchpay.com/vstate-filings/
              </p> */}
              <p className="normal-pdf-text">
                SERVICE FEE TERMS ARE NET 30 DAYS. A 1.5% monthly late fee is
                assessed on balances greater than 30 days.
              </p>
              <p className="normal-pdf-text">
                We reserve the right to resign as Registered Agent if your
                invoice is 30 days past due.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

const InvoiceTable = ({
  grandTotal,
  items,
  orderTitle,
  state,
  transactionId,
  transactionDate,
}) => {
  return (
    <div className="invoice-table">
      <div className="invoice-items">
        <div className="header-row">
          <div className="header-content">
            <div className="ref-column">
              <div className="table-header">
                <div className="cell-text">Transaction ID #</div>
              </div>
              <div
                className="table-cell text-wrap w-[130px]"
                style={{ wordBreak: "break-word" }}
              >
                {transactionId}
              </div>
            </div>
            <div className="ref-column">
              <div className="table-header">
                <div className="cell-text">Transaction Date</div>
              </div>
              <div className="table-cell justify-content-center">
                {transactionDate}
              </div>
            </div>
            {/* <div className="due-date-column">
              <div className="table-header">
                <div className="cell-text">Due Date</div>
              </div>
              <div className="table-cell">
                <div className="cell-text"></div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="items-grid">
          <ItemColumn
            header="Items"
            items={items}
            field="displayName"
            orderTitle={orderTitle}
            state={state}
          />
          <ItemColumn
            header="Description"
            items={items}
            field="description"
            orderTitle={orderTitle}
            state={state}
          />
          <ItemColumn
            header="State"
            items={items}
            field="State"
            orderTitle={orderTitle}
            state={state}
          />
          <ItemColumn
            header="Entity ID"
            items={items}
            field="quantity"
            orderTitle={orderTitle}
            state={state}
          />
          <ItemColumn
            header="Amount"
            items={items}
            field="unitPrice"
            orderTitle={orderTitle}
            state={state}
          />
        </div>
      </div>
      <div class="totals-section">
        <div class="totals-grid">
          <div class="totals-label-column">
            <div class="table-header-bottom">
              <div class="cell-text">Payment/Credits</div>
            </div>
            <div class="table-header-bottom">
              <div class="cell-text">Balance Due</div>
            </div>
          </div>
          <div class="totals-amount-column">
            <div class="table-cell-final">
              <div class="cell-text">-${grandTotal.toFixed(2)}</div>
            </div>
            <div class="table-cell2">
              <div class="cell-text">$0.00</div>
            </div>
          </div>
        </div>
      </div>
      <div class="invoice-footer">
        <div class="footer-details">
          <div class="footer-column">
            {/* <div class="table-cell">
              <div class="cell-text">
                Payments can be made online at:
                https://secure.merchpay.com/vstate-filings/
              </div>
            </div> */}
            <div class="table-cell">
              <div class="cell-text">
                SERVICE FEE TERMS ARE NET 30 DAYS. A 1.5% monthly late fee is
                assessed on balances greater than 30 days.
              </div>
            </div>
            <div class="table-cell">
              <div class="cell-text">
                We reserve the right to resign as Registered Agent if your
                invoice is 30 days past due.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ItemColumn = ({ header, items, field, orderTitle, state, entityId }) => {
  return (
    <div className="item-column">
      <div className="table-header">
        <div className="cell-text">{header}</div>
      </div>
      {items.map((item, index) => (
        <div
          className={field === "unitPrice" ? "table-cell2" : "table-cell1"}
          key={index}
        >
          {console.log(item, "fdsafads")
          }          <div className="cell-text">
            {field === "displayName" && item.productListing.displayName}
            {field === "description" && orderTitle}
            {field === "State" && state}
            {field === "quantity" && entityId}
            {field === "unitPrice" && `$${item.unitPrice.toFixed(2)}`}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SalesReciptInvoice;
