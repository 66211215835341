import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setTableData: (state, action) => {
      const { tableName, data } = action.payload;
      state[tableName] = data; 
    },
  },
});

export const { setTableData } = tableSlice.actions;
export default tableSlice.reducer;
