import React from 'react';
import ContactInfo from './ContactInfo';
import ContactForm from './ContactForm';

const ContactSection = () => {
  return (
    <div className='mt-24'>
    <section className="flex justify-center mt-10">
      <div className="flex flex-col flex-1 shrink justify-center p-2.5 w-full basis-0 min-w-[240px] max-md:max-w-full">
       
        <div className="flex flex-col flex-1 items-center pr-96 pl-96 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex flex-wrap w-full max-w-[1140px] max-md:max-w-full">
            <ContactInfo />
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
    </div>
  );
};

export default ContactSection;