import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import ChatMain from "../ChatMain";
import DemoHeader from "./DemoHeader";

const Layout = () => {
  return (
    <div>
      <DemoHeader />
      <Outlet />
      <ChatMain />
      <Footer />
    </div>
  );
};

export default Layout;
