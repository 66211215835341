import React, { useContext } from 'react'
import { AddUser } from './AddComponents/AddUser'
import { AddSignIn } from './AddComponents/AddSignIn'
import Pic1 from "../Assets/Images/undraw_setup_wizard_re_nday 1.png"
import Logo from "../Assets/Images/vstate-logo-new.png"
import Pic2 from "../Assets/Images/new_onboardding_image.png"
import Navbar from '../common/Navbar'
import { Card } from 'primereact/card'
import authContext from '../common/authContext'

const SignIn = () => {
    const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh } = useContext(authContext);

    return (
        <>
            {(isLoading || isTabRefresh) && (
                <div className="overlay">
                    <div className="loader-card">
                        {/* <div className="card-content"> */}
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="42" height="29" viewBox="0 0 42 29" fill="none">
                                <circle cx="20.175" cy="4.13223" r="4.13223" fill="#A3D5C9" />
                                <circle cx="15.7997" cy="12.6398" r="2.18765" fill="#A3D5C9" />
                                <circle cx="2.55226" cy="23.6995" r="2.55226" fill="#A3D5C9" />
                                <circle cx="31.4779" cy="25.6441" r="3.0384" fill="#A3D5C9" />
                                <circle cx="39.1346" cy="17.015" r="2.18765" fill="#A3D5C9" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M34.3734 1.33923C32.5037 2.35941 31.5781 3.8808 31.5781 5.93427C31.5781 6.69484 31.7466 7.61852 31.9607 8.03271C32.171 8.43937 32.2704 8.84433 32.1817 8.93208C32.0927 9.01983 29.7123 11.4629 26.8919 14.3611L21.7636 19.6304L21.0195 19.2328C20.0336 18.706 17.5978 18.707 16.6102 19.2349L15.8618 19.6348L13.0013 16.7668L10.1406 13.8988L10.5079 13.1883C11.1391 11.9678 11.2237 10.8779 10.7886 9.57403C10.0351 7.31662 8.29641 6.05338 5.9425 6.05338C3.34527 6.05338 1.29471 7.89805 1.02879 10.4739C0.637691 14.2644 4.2986 17.0349 7.88708 15.6645L8.99378 15.2417L11.8295 18.0487L14.6651 20.8557L14.2495 21.7272C13.6304 23.0254 13.7108 24.8431 14.4474 26.1956C16.8433 30.5947 23.3861 29.198 23.7558 24.2082C23.8314 23.1875 23.7385 22.5259 23.4213 21.8224L22.9823 20.8489L28.3229 15.5082L33.6634 10.1676L34.6182 10.5667C36.0667 11.1717 38.1175 10.9491 39.4184 10.0451C42.2502 8.07792 42.2441 3.78187 39.4067 1.81031C38.5455 1.21162 38.0786 1.07258 36.7329 1.01327C35.5713 0.962228 34.8929 1.05581 34.3734 1.33923Z" fill="white" />
                            </svg> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="29" viewBox="0 0 42 29" fill="none">
                            <circle cx="20.175" cy="4.13223" r="4.13223" fill="#A3D5C9" />
                            <circle cx="15.7997" cy="12.6398" r="2.18765" fill="#A3D5C9" />
                            <circle cx="2.55226" cy="23.6995" r="2.55226" fill="#A3D5C9" />
                            <circle cx="31.4779" cy="25.6441" r="3.0384" fill="#A3D5C9" />
                            <circle cx="39.1347" cy="17.015" r="2.18765" fill="#A3D5C9" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M34.3734 1.33923C32.5037 2.35941 31.5781 3.8808 31.5781 5.93427C31.5781 6.69484 31.7466 7.61852 31.9607 8.03271C32.171 8.43937 32.2704 8.84433 32.1817 8.93208C32.0927 9.01983 29.7123 11.4629 26.8919 14.3611L21.7636 19.6304L21.0195 19.2328C20.0336 18.706 17.5978 18.707 16.6102 19.2349L15.8618 19.6348L13.0013 16.7668L10.1406 13.8988L10.5079 13.1883C11.1391 11.9678 11.2237 10.8779 10.7886 9.57403C10.0351 7.31662 8.29641 6.05338 5.9425 6.05338C3.34527 6.05338 1.29471 7.89805 1.02879 10.4739C0.637691 14.2644 4.2986 17.0349 7.88708 15.6645L8.99378 15.2417L11.8295 18.0487L14.6651 20.8557L14.2495 21.7272C13.6304 23.0254 13.7108 24.8431 14.4474 26.1956C16.8433 30.5947 23.3861 29.198 23.7558 24.2082C23.8314 23.1875 23.7385 22.5259 23.4213 21.8224L22.9823 20.8489L28.3229 15.5082L33.6634 10.1676L34.6182 10.5667C36.0667 11.1717 38.1175 10.9491 39.4184 10.0451C42.2502 8.07792 42.2441 3.78187 39.4067 1.81031C38.5455 1.21162 38.0786 1.07258 36.7329 1.01327C35.5713 0.962228 34.8929 1.05581 34.3734 1.33923Z" fill="white" />
                        </svg>
                        <span className='text-white'></span>
                        {/* </div> */}
                    </div>
                </div>
            )}
            <div className='signup-user-container'>
                <div className='signin-user-main'>
                    <div className='signup-content1-container'>
                        <div className='signin-header-container'>
                            <div className='signin-header-frame'>
                                <img src={Logo} alt="logo" className='signin-logo' />
                            </div>
                        </div>

                        <div className='signin-hero-container'>
                            <div className='signin-hero-main'>
                                <div className='signin-hero-header'>
                                    <div className='signin-hero-header-text1'>
                                        Ready to Grow Your Business?
                                    </div>
                                    <div className='signin-hero-header-text2'>
                                        At vState Filings, we simplify your business journey with expert management  of formation, registered agent services, and legal support.
                                    </div>

                                </div>
                            </div>
                            <div className='d-flex justify-content-center py-4'>
                                <img src={Pic2} alt="logo" />
                            </div>
                        </div>

                        <div className='signin-hero-footer-container'>
                            <div className='signin-hero-footer-text1'>
                                © vState Filings 2024
                            </div>
                            <div className='signin-hero-footer-text-main'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M14.6667 4C14.6667 3.26666 14.0667 2.66666 13.3334 2.66666H2.66671C1.93337 2.66666 1.33337 3.26666 1.33337 4M14.6667 4V12C14.6667 12.7333 14.0667 13.3333 13.3334 13.3333H2.66671C1.93337 13.3333 1.33337 12.7333 1.33337 12V4M14.6667 4L8.00004 8.66666L1.33337 4" stroke="#E9D7FE" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div className='signin-hero-footer-text2'>
                                    help@vstatefilings.com
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='signup-content2-container bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]'>
                        <div className='signup-content2-main'>
                            <div className='signup-content-main1 '>
                                <div className='signup-content-main2 '>
                                    <Card className="  flex justify-content-center login-card box-shadow-lg" >

                                        <div className='signup-card-container font-fam-for-all'>
                                            <div className='signup-card-header'>
                                                <div className='signup-card-header-text1 motiva-sans w-[400px]'>
                                                    Login to Your Account
                                                </div>
                                                <div className='signup-card-header-text2 font-fam-for-all w-[400px]'>
                                                    Please Enter Your Details
                                                </div>
                                            </div>


                                            <div className='signup-card-header'>
                                                <AddSignIn />
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>


                    <style>
                        {`
          .error-card-container {
            position: fixed;
            top: 20px;
            right: 20px;
            z-index: 1000;
            animation: fadeIn 0.5s;
          }
          .error-card {
            width: 300px;
            
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            animation: slideIn 0.5s;
          }
          .error-card p {
            color: #ff4d4d;
          }
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @keyframes slideIn {
            from {
              transform: translateX(100%);
            }
            to {
              transform: translateX(0);
            }
          }
        `}
                    </style>

                </div>
            </div>
        </>
    )
}

export default SignIn