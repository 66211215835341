import React, { useEffect } from 'react';


const CustomModal = ({ visible, onHide, children }) => {
    // Close modal when clicking outside of it
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (visible && !e.target.closest('.custom-modal-content')) {
                onHide();
            }
        };

        if (visible) {
            document.addEventListener('click', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [visible, onHide]);

    if (!visible) return null;

    return (
        <div className="custom-modal-overlay">
            {/* <div className="custom-modal-content">
                <button className="custom-modal-close" onClick={onHide}>
                    &times;
                </button>
               
            </div> */}
            {children}
        </div>
    );
};

export default CustomModal;
