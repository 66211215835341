import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import "../CommonSection/CommonStyle/CommonStyle.css";
import ReCAPTCHA from "react-google-recaptcha";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import AOS from "aos";
import "aos/dist/aos.css";
import SuccessfulModal from "../SuccessfulModal/SuccessfulModal";
import errorMessages from "../../../utils/errorMessages";
import UnsuccessfulModal from "../SuccessfulModal/UnsuccessfulModal";
import { useLocation } from "react-router-dom";

const ServiceForm = () => {
  const location = useLocation();
  const [modalShow, setModalShow] = useState(false);
  const [modalShowUnsuccessful, setModalShowUnsuccessful] = useState(false);
  const [phoneErr, setPhoneErr] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    website: "",
    message: "",
    city: "",
    pincode: "",
    address: "",
    state: "",
    service: null,
    captcha: null,
  });
  const [errors, setErrors] = useState({});

  // const states = [
  //   { label: "Alabama", value: "alabama" },
  //   { label: "Alaska", value: "alaska" },
  //   { label: "Arizona", value: "arizona" },
  //   { label: "Arkansas", value: "arkansas" },
  //   { label: "California", value: "california" },
  //   { label: "Colorado", value: "colorado" },
  //   { label: "Connecticut", value: "connecticut" },
  //   { label: "DC", value: "dc" },
  //   { label: "Delaware", value: "delaware" },
  //   { label: "Florida", value: "florida" },
  //   { label: "Georgia", value: "georgia" },
  //   { label: "Hawaii", value: "hawaii" },
  //   { label: "Idaho", value: "idaho" },
  //   { label: "Illinois", value: "illinois" },
  //   { label: "Indiana", value: "indiana" },
  //   { label: "Iowa", value: "iowa" },
  //   { label: "Kansas", value: "kansas" },
  //   { label: "Kentucky", value: "kentucky" },
  //   { label: "Louisiana", value: "louisiana" },
  //   { label: "Maine", value: "maine" },
  //   { label: "Maryland", value: "maryland" },
  //   { label: "Massachusetts", value: "massachusetts" },
  //   { label: "Michigan", value: "michigan" },
  //   { label: "Minnesota", value: "minnesota" },
  //   { label: "Mississippi", value: "mississippi" },
  //   { label: "Missouri", value: "missouri" },
  //   { label: "Montana", value: "montana" },
  //   { label: "Nebraska", value: "nebraska" },
  //   { label: "Nevada", value: "nevada" },
  //   { label: "New Hampshire", value: "new-hampshire" },
  //   { label: "New Jersey", value: "new-jersey" },
  //   { label: "New Mexico", value: "new-mexico" },
  //   { label: "New York", value: "new-york" },
  //   { label: "North Carolina", value: "north-carolina" },
  //   { label: "North Dakota", value: "north-dakota" },
  //   { label: "Ohio", value: "ohio" },
  //   { label: "Oklahoma", value: "oklahoma" },
  //   { label: "Oregon", value: "oregon" },
  //   { label: "Pennsylvania", value: "pennsylvania" },
  //   { label: "Rhode Island", value: "rhode-island" },
  //   { label: "South Carolina", value: "south-carolina" },
  //   { label: "South Dakota", value: "south-dakota" },
  //   { label: "Tennessee", value: "tennessee" },
  //   { label: "Texas", value: "texas" },
  //   { label: "Utah", value: "utah" },
  //   { label: "Vermont", value: "vermont" },
  //   { label: "Virginia", value: "virginia" },
  //   { label: "Washington", value: "washington" },
  //   { label: "West Virginia", value: "west-virginia" },
  //   { label: "Wisconsin", value: "wisconsin" },
  //   { label: "Wyoming", value: "wyoming" },
  // ];

  const states = [
    { label: "Alabama", value: "Alabama" },
    { label: "Alaska", value: "Alaska" },
    { label: "Arizona", value: "Arizona" },
    { label: "Arkansas", value: "Arkansas" },
    { label: "California", value: "California" },
    { label: "Colorado", value: "Colorado" },
    { label: "Connecticut", value: "Connecticut" },
    { label: "DC", value: "Dc" },
    { label: "Delaware", value: "Delaware" },
    { label: "Florida", value: "Florida" },
    { label: "Georgia", value: "Georgia" },
    { label: "Hawaii", value: "Hawaii" },
    { label: "Idaho", value: "Idaho" },
    { label: "Illinois", value: "Illinois" },
    { label: "Indiana", value: "Indiana" },
    { label: "Iowa", value: "Iowa" },
    { label: "Kansas", value: "Kansas" },
    { label: "Kentucky", value: "Kentucky" },
    { label: "Louisiana", value: "Louisiana" },
    { label: "Maine", value: "Maine" },
    { label: "Maryland", value: "Maryland" },
    { label: "Massachusetts", value: "Massachusetts" },
    { label: "Michigan", value: "Michigan" },
    { label: "Minnesota", value: "Minnesota" },
    { label: "Mississippi", value: "Mississippi" },
    { label: "Missouri", value: "Missouri" },
    { label: "Montana", value: "Montana" },
    { label: "Nebraska", value: "Nebraska" },
    { label: "Nevada", value: "Nevada" },
    { label: "New Hampshire", value: "New-Hampshire" },
    { label: "New Jersey", value: "New-Jersey" },
    { label: "New Mexico", value: "New-Mexico" },
    { label: "New York", value: "New-York" },
    { label: "North Carolina", value: "North-Carolina" },
    { label: "North Dakota", value: "North-Dakota" },
    { label: "Ohio", value: "Ohio" },
    { label: "Oklahoma", value: "Oklahoma" },
    { label: "Oregon", value: "Oregon" },
    { label: "Pennsylvania", value: "Pennsylvania" },
    { label: "Rhode Island", value: "Rhode-Island" },
    { label: "South Carolina", value: "South-Carolina" },
    { label: "South Dakota", value: "South-Dakota" },
    { label: "Tennessee", value: "Tennessee" },
    { label: "Texas", value: "Texas" },
    { label: "Utah", value: "Utah" },
    { label: "Vermont", value: "Vermont" },
    { label: "Virginia", value: "Virginia" },
    { label: "Washington", value: "Washington" },
    { label: "West Virginia", value: "West-Virginia" },
    { label: "Wisconsin", value: "Wisconsin" },
    { label: "Wyoming", value: "Wyoming" },
  ];

  const serviceOptions = [
    { label: "Corporate Fillings", value: "corporatefilings" },
    { label: "Compliance", value: "compliance" },
    { label: "Subscriptions", value: "subscription" },
    { label: "UCC Filings", value: "uccfilings" },
    { label: "Document Retrieval", value: "documentretrieval" },
    { label: "Corporate Supplies", value: "corporatesuplies" },
    // Sub
    { label: "Entity Formation", value: "entity-formation" },
    {
      label: "Certificate Of Good Standing",
      value: "certificate-of-good-standing",
    },
    { label: "Corp Kit", value: "corp-kit" },
    { label: "LLC Publication", value: "llc-publication" },
    { label: "Litigation Search", value: "litigation-search" },
    { label: "Annual Report", value: "annual-report" },
    { label: "Biennial Report", value: "biennial-report" },
    { label: "Certificate Of Amendment", value: "certificate-of-amendment" },
    { label: "Certificate Of Change", value: "certificate-of-change" },
    {
      label: "DBA – Certificate Of Assumed Name",
      value: "dba-certificate-of-assumed-name",
    },
    {
      label: "Certificate Of Dissolution",
      value: "certificate-of-dissolution",
    },
    { label: "Corporate Copies", value: "corporate-copies" },
    { label: "Independent Manager", value: "independent-manager" },
    { label: "Liquor License Publishing", value: "liquor-license-publishing" },
    { label: "UCC Filing", value: "ucc-filing" },
    { label: "UCC Search", value: "ucc-search" },
    { label: "UCC Amendment", value: "ucc-amendment" },
    { label: "Certified Copies", value: "certified-copies" },
    { label: "UCC Termination", value: "ucc-termination" },
    { label: "Expedited Service", value: "expedited-service" },
    { label: "EIN", value: "ein" },
    { label: "S-Election", value: "s-election" },
    { label: "Registered Agent", value: "registered-agent" },
    { label: "App. of Authority", value: "app-of-authority" },
    { label: "Beneficial Ownership", value: "beneficial-ownership" },
  ];

  const isValidUSPhoneNumber = (phone) => {
    const phoneRegex =
      /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phoneRegex.test(phone);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Validate a field and return an error message if needed.
  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "firstName":
        if (!value.trim()) error = "First Name is required";
        break;
      case "lastName":
        if (!value.trim()) error = "Last Name is required";
        break;
      case "email":
        if (!value.trim()) error = "Email is required";
        else if (!isValidEmail(value)) error = "Invalid email format";
        break;
      case "phone":
        if (!value.trim()) error = "Phone number is required";
        else if (!isValidUSPhoneNumber(value)) error = "Invalid phone number";
        break;
      case "company":
        if (!value.trim()) error = "Company Name is required";
        break;
      // Add other cases if needed
      default:
        break;
    }
    return error;
  };

  const [zipErr, setZipErr] = useState("");

  const [zip, setZip] = useState("");

  const handlePostalCode = (e) => {
    setZipErr("");
    let value = e.target.value;
    const numericValue = value.replace(/\D/g, "");

    if (numericValue === "") {
      setZipErr("Postal code required");
    } else if (numericValue.length < 5) {
      setZipErr("Postal code must be 5 digits");
    } else {
      setZipErr("");
    }
    setZip(numericValue);
  };

  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;
    let inputValue = value.replace(/\D/g, ""); // Remove non-digit characters

    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }

    const formattedValue = inputValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
    );

    // Only show formatted value after 10 digits
    const newPhoneValue =
      inputValue.length === 10 ? formattedValue : inputValue;
    e.target.value = newPhoneValue;

    // Update both the local state and formData state
    setPhone(newPhoneValue);
    setFormData((prevState) => ({
      ...prevState,
      phone: newPhoneValue,
    }));

    // Validate phone number and set errors if needed
    if (value === "") {
      setPhoneErr(errorMessages.phoneRequired);
    } else if (value && value.trim() !== "" && inputValue.length <= 9) {
      if (
        !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
          value
        )
      ) {
        setPhoneErr(errorMessages.phoneValidation);
      }
    }
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  // Updated handleChange for live validation.
  const handleChange = (e) => {
    // For Primereact components, the event might not be a standard event.
    const fieldName = e.target ? e.target.name : e.name;
    const fieldValue = e.target ? e.target.value : e.value;

    // Update the form data.
    setFormData((prevState) => ({
      ...prevState,
      [fieldName]: fieldValue,
    }));

    // Validate the field immediately.
    const errorMessage = validateField(fieldName, fieldValue);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: errorMessage,
    }));
  };

  const handleRecaptchaChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      captcha: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, captcha: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let validationErrors = {};

    // Validate on submit as well.
    if (!formData.firstName.trim())
      validationErrors.firstName = "First Name is required";
    if (!formData.lastName.trim())
      validationErrors.lastName = "Last Name is required";
    if (!formData.email.trim()) validationErrors.email = "Email is required";
    else if (!isValidEmail(formData.email))
      validationErrors.email = "Invalid email format";

    if (!formData.phone.trim())
      validationErrors.phone = "Phone number is required";
    else if (!isValidUSPhoneNumber(formData.phone))
      validationErrors.phone = "Invalid US phone number";

    if (!formData.company.trim())
      validationErrors.company = "Company Name is required";

    if (!formData.captcha)
      validationErrors.captcha = "Please complete the CAPTCHA";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const postData = {
      lead_name: formData.company || "N/A",
      first_name: formData.firstName,
      last_name: formData.lastName || null,
      email: formData.email || null,
      company_name: formData.company || null,
      state: formData.state || null,
      message: formData.message || null,
      phone_number: formData.phone.replace(/\D/g, ""), // Remove non-digit characters
      token: formData.captcha || null,
    };

    try {
      const response = await fetch(
        "https://chatbot.redberyltest.in/leads/add_leads_data",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(postData),
        }
      );

      if (response.ok) {
        setModalShow(true);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          company: "",
          website: "",
          message: "",
          city: "",
          pincode: "",
          address: "",
          state: "",
          captcha: null,
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 3000);
        setErrors({});
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
          // window.location.reload(false);
        }, 3000);
      } else {
        console.error("Error: API response not OK");
        setModalShowUnsuccessful(true);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setModalShowUnsuccessful(true);
    }
  };

  useEffect(() => {
    setSelectedCountry({ name: "United States", code: "US", dialCode: "+1" });
    AOS.init();
  }, []);

  useEffect(() => {
    const currentPath = location.pathname.replace("/", "");

    // Check if the current path matches any value in the serviceOptions
    const matchedService = serviceOptions.find(
      (option) => option.value === currentPath
    );

    if (matchedService) {
      setFormData((prevState) => ({
        ...prevState,
        service: matchedService.value,
      }));
    }
  }, [location.pathname, setFormData]);

  return (
    <section
      className="service-form"
      data-aos="fade-up"
      data-aos-duration="3000"
    >
      <Container>
        <Row className="justify-content-center">
          <Col lg={8} md={10} sm={12} xs={12}>
            <Form onSubmit={handleSubmit}>
              <Row>
                <h4 className="service-form-title">Contact Information</h4>
                <div className="grid w-full">
                  {[
                    { name: "firstName", label: "* First Name", type: "text" },
                    { name: "lastName", label: "* Last Name", type: "text" },
                    { name: "email", label: "* Email", type: "email" },
                    // { name: "phone", label: "* Phone Number", type: "number" },
                  ].map((input) => (
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6 w-full p-1"
                      key={input.name}
                    >
                      <label
                        htmlFor={input.name}
                        className="block font-medium text-gray-700"
                      >
                        {input.label}
                      </label>
                      <InputText
                        id={input.name}
                        name={input.name}
                        type={input.type}
                        value={formData[input.name]}
                        onChange={handleChange}
                        required={input.required}
                        className="p-inputtext-sm w-full"
                      />
                      {errors[input.name] && (
                        <p className="text-red-500 text-sm">
                          {errors[input.name]}
                        </p>
                      )}
                    </div>
                  ))}
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 w-full">
                    <label
                      htmlFor="service"
                      className="block font-medium text-gray-700"
                    >
                      Phone Number
                    </label>
                    <div
                      className="p-inputgroup flex-1"
                      style={{ height: "40px", width: "100%" }}
                    >
                      <Dropdown
                        value={selectedCountry}
                        options={countries}
                        resetFilterOnHide
                        onChange={onCountryChange}
                        filter
                        optionLabel="code"
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                        style={{
                          width: "160px",
                          borderRadius: "6px 0px 0px 6px",
                        }}
                        className="p-dropdown font-fam-for-all"
                      />
                      <span className="" style={{ width: "100%" }}>
                        <div className="relative custom-input-mask">
                          <input
                            type="text"
                            id="floating_outlined101"
                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                            style={{
                              height: "40px",
                              borderRadius: "0px 6px 6px 0px",
                              width: "100%",
                            }}
                            maxLength={50}
                            value={phone}
                            onChange={handlePhone}
                            autoClear={false}
                            placeholder=""
                            name="cityName"
                          />
                          <label
                            htmlFor="floating_outlined101"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Phone<span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {phoneErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {phoneErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <h4 className="service-form-title mt-4">Entity Information</h4>
                <div className="grid w-full">
                  {[
                    { name: "company", label: "* Company Name", type: "text" },
                    {
                      name: "address",
                      label: "Address",
                      type: "text",
                      required: false,
                    },
                    {
                      name: "city",
                      label: "City",
                      type: "text",
                      required: false,
                    },
                    {
                      name: "pincode",
                      label: "Zip Code",
                      type: "number",
                      required: false,
                    },
                  ].map((input) => (
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6 w-full p-1"
                      key={input.name}
                    >
                      <label
                        htmlFor={input.name}
                        className="block font-medium text-gray-700"
                      >
                        {input.label}
                      </label>
                      <InputText
                        id={input.name}
                        name={input.name}
                        type={input.name === "pincode" ? "text" : input.type}
                        maxLength={input.name === "pincode" ? 5 : undefined}
                        // value={formData[input.name]}
                        value={
                          input.name === "pincode" ? zip : formData[input.name]
                        }
                        onChange={
                          input.name === "pincode"
                            ? handlePostalCode
                            : handleChange
                        }
                        required={input.required}
                        className="p-inputtext-sm w-full"
                      />

                      {/* 
                       <InputText
                                                style={{
                                                  height: "40px",
                                                  borderRadius: "0px 6px 6px 0px",
                                                }}
                                                maxLength={5}
                                                value={zip}
                                                onChange={handlePostalCode} */}
                      {errors[input.name] && (
                        <p className="text-red-500 text-sm">
                          {errors[input.name]}
                        </p>
                      )}
                    </div>
                  ))}
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-1">
                  <label
                    htmlFor="state"
                    className="block font-medium text-gray-700"
                  >
                    State
                  </label>
                  <Dropdown
                    id="state"
                    name="state"
                    options={states}
                    optionLabel="label"
                    optionValue="value"
                    value={formData.state}
                    onChange={handleChange}
                    placeholder="Select a state"
                    className="w-full"
                  />
                  {errors.state && (
                    <p className="text-red-500 text-sm">{errors.state}</p>
                  )}
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-1">
                  <label
                    htmlFor="service"
                    className="block font-medium text-gray-700"
                  >
                    Interested Service
                  </label>
                  <Dropdown
                    id="service"
                    name="service"
                    options={serviceOptions}
                    optionLabel="label"
                    optionValue="value"
                    value={formData.service}
                    onChange={(e) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        service: e.value,
                      }))
                    }
                    placeholder="Select a service"
                    className="w-full"
                  />
                  {errors.service && (
                    <p className="text-red-500 text-sm">{errors.service}</p>
                  )}
                </div>

                <div className="col-12 w-full p-1">
                  <label
                    htmlFor="message"
                    className="block font-medium text-gray-700"
                  >
                    Message
                  </label>
                  <InputTextarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows={5}
                    cols={30}
                    autoResize
                    className="w-full"
                  />
                  {errors.message && (
                    <p className="text-red-500 text-sm">{errors.message}</p>
                  )}
                </div>
              </Row>
              <div>
                <ReCAPTCHA
                  sitekey="6LeYwM0qAAAAAC-arhPe0I2PEaCHs0AjXEBMjnNh"
                  onChange={handleRecaptchaChange}
                />
                {errors.captcha && (
                  <p className="text-red-500 text-sm">{errors.captcha}</p>
                )}
              </div>
              <div className="main-class-submit text-center mt-4 mb-5">
                <Button
                  label="Submit"
                  type="submit"
                  className="vstate-button w-full"
                />
              </div>
            </Form>
          </Col>
        </Row>
        <SuccessfulModal show={modalShow} onHide={() => setModalShow(false)} />
        <UnsuccessfulModal
          show={modalShowUnsuccessful}
          onHide={() => setModalShowUnsuccessful(false)}
        />
      </Container>
    </section>
  );
};

export default ServiceForm;
