import React, { useRef } from "react";
import RegisteredBanner from "../RegistredAgentService/RegisteredBanner.js";
import CorporateFillingSec from "../AllServices/CorporateFillingsPage/CorporateFillingSec.js";
import CorporateFillingsThird from "../AllServices/CorporateFillingsPage/CorporateFillingsThird.js";
import OurTrustedSection from "../CommonSection/OurTrustedSection.js";
import bannerimg from "../../../Assets/Images/corporatefillingbanner.png";
import CommonRegister from "../CommonSection/CommonRegister.js";
import CorporateFillingFaq from "../AllServices/CorporateFillingsPage/CorporateFillingFaq.js";
import { Container } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle.js";
import ServiceForm from "../CommonSection/ServiceForm.js";

const CorporateFilingsMain = () => {
  const registeredTabRef = useRef(null);

  const handleScroll = () => {
    if (registeredTabRef.current) {
      registeredTabRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      console.error("registeredTabRef is null");
    }
  };
  return (
    <div>
      <RegisteredBanner
        title={"Handle all your filings ,"}
        highlightText={"with ease"}
        subtitle={
          "Manage all your corporate documents and filings effortlessly."
        }
        buttonLabel={"Learn More"}
        onButtonClick={handleScroll}
        bannerImage={bannerimg}
      />
      {/* <OurTrustedSection /> */}
      <CorporateFillingSec />
      <div ref={registeredTabRef} id="registered-tab-section">
        <CorporateFillingsThird />
      </div>
      <div className="entity-formation">
        <Container>
          <div className="text-center mt-5 mb-5">
            <CommonHeadingTitle
              heading={"Need help with "}
              blueheading={"corporate filings?"}
            />
          </div>
          <ServiceForm />
        </Container>
      </div>
      <CommonRegister
        title={"Simplified filings for hassle-free business operations"}
        buttonLabel={"Start Your Business"}
        buttonLink={"/signup"}
      />
      <CorporateFillingFaq />
    </div>
  );
};

export default CorporateFilingsMain;
