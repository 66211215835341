import React from "react";
import { useLottie } from "lottie-react";
import errorAnimation from "./unsuccessful.json"; 
const UnsuccessfulLotti = () => {
  const options = {
    animationData: errorAnimation,
    loop: true,
    style: { height: "80px", width: "80px" },
  };

  const { View } = useLottie(options);
  return <div>{View}</div>;
};

export default UnsuccessfulLotti;
