import React, { useEffect } from "react";
import "./SuccessfulModal.css";
import { Modal } from "react-bootstrap";
import SuccessfulLotti from "./SuccessfulLotti";

const SuccessfulModal = (props) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      props.onHide(); // Close the modal after 4 seconds
    }, 5000);

    return () => clearTimeout(timer); // Cleanup function to clear timeout
  }, [props.onHide]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="successful-modal"
    >
      <Modal.Body>
        <div className="d-flex justify-content-center mt-2 mb-3">
          <SuccessfulLotti />
        </div>
        <p className="modal-texttt">
          Your message has been sent successfully! We will get back to you
          shortly. For any urgent queries, contact us at
          <span className="blue-text"> support@vstatefilings.com.</span>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessfulModal;
