import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authContext from './authContext';


const AutoLogout = ({ logout }) => {
    const [inactiveTime, setInactiveTime] = useState(0);
    const { setFeedbackData, setIsLoading, setIsTabRefresh } = useContext(authContext);
    const navigate = useNavigate();
    const maxInactiveTime = 30 * 60 * 1000; // 30 minutes in milliseconds
    // const maxInactiveTime = 2 * 60 * 1000;

    useEffect(() => {
        const handleActivity = () => setInactiveTime(0);

        // Track user activity
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keydown', handleActivity);
        window.addEventListener('mousedown', handleActivity);
        window.addEventListener('touchstart', handleActivity);
        window.addEventListener('scroll', handleActivity);

        const interval = setInterval(() => {
            setInactiveTime(prevTime => {
                if (prevTime >= maxInactiveTime) {
                    logoutHandler();
                    return 0;
                }
                return prevTime + 1000;
            });
        }, 1000); 

        return () => {
            clearInterval(interval);
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keydown', handleActivity);
        };
    }, [logout]); 

    const logoutHandler = () => {
        alert('Session Expired. You have been logged out due to inactivity.');
        localStorage.removeItem('userToken'); // Clear user session
        setFeedbackData(null); 
        setIsLoading(false); 
        setIsTabRefresh(false); 
        logout(); // Call the logout function passed as a prop
        navigate('/'); // Redirect to login page
    };

    return null;
};

export default AutoLogout;
