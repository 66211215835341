import React, { useContext, useEffect } from "react";
import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { Message } from "primereact/message";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import ShiftsServices from "../../services/demoServices";
import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import { Avatar } from "primereact/avatar";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";
import errorMessages, { globalMessages } from "../../utils/errorMessages";
import { InputMask } from "primereact/inputmask";
import CreditCardServices from "../../services/strapiService";
import UserServices from "../../services/userService";
import templateCsv from "../../Assets/users.csv";
import { ProgressBar } from "primereact/progressbar";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import authContext from "../../common/authContext";
import { showToast } from "../../utils/toastUtils";


const EmployeeUpload = ({
  visible,
  setVisible,
  pageRefresh,
  setPageRefresh,
  
}) => {
  const { setFeedbackData, permissions, redirectedData, clearData, isTabRefresh, setFilterStatusId, setRedirectedData ,setIsLoading} = useContext(authContext);

  const [value, setValue] = useState("");
  const [ingredient, setIngredient] = useState("");
  const toast = useRef(null);
  const [upload, setUpload] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isEqualAddress, setIsEqualAddress] = useState(true);
  const [allCustomer, setAllCustomer] = useState([]);
  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [cityErr, setcityErr] = useState("");
  const toastCenter = useRef(null);
  const [selectedSkills, setSelectedSkills] = useState(null);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [website, setWebsite] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [firstNameErr, setFirstNameErr] = useState("");
  const [lastNameErr, setLastNameErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [globalErr, setGlobalErr] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const fileInputRef = useRef(null)

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [isContentVisible, setIsContentVisible] = useState(true);

  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    // toast.current.show({
    //   severity: "info",
    //   summary: "Success",
    //   detail: "File uploaded",
    // });

    showToast("success",`File uploaded`)

  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 1 MB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}
          ></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-file mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop File Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-file",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const dialogView = () => setVisible(false);
  const cancelView = () => {
    setVisible(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setIngredient("");
    setPhone("");
    setPassword("");
    setConfirmPassword("");
    setFirstNameErr("");
    setLastNameErr("");
    setPasswordErr("");
    setConfirmPasswordErr("");
    setPhoneErr("");
    setcityErr("");
    setEmailErr("");
    setSelectedFile(null);
  };

  useEffect(() => { }, []);

  let newObject = window.localStorage.getItem("user");
  let updatedUser = Object.assign({}, JSON.parse(newObject));

  useEffect(() => {
    setSelectedCountry({ name: "United States", code: "US", dialCode: "+1" });

    if (updatedUser) {
      if (updatedUser.companyRoles !== undefined) {
        if (updatedUser.companyRoles.length > 0) {
          if (updatedUser.companyRoles[0].roles.length > 0) {
            setRoleId(updatedUser.companyRoles[0].roles[0].id);
            setCompanyId(updatedUser.companyRoles[0]?.companyDetails?.id);
          }
        }
      }
    }
  }, []);

  const handleCity = (e) => {
    setEmailErr("");
    const value = e.target.value;
    if (value === "") {
      setEmailErr(errorMessages.emailRequired);
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      setEmailErr(errorMessages.emailValidation);
    }
    setIngredient(e.target.value);
  };

  const handleEmail = (e) => {
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setEmail(e.target.value);
  };

  const handleFirstName = (e) => {
    setFirstNameErr("");
    let value = e.target.value;
    if (value === "") {
      setFirstNameErr(errorMessages.firstNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setFirstNameErr(errorMessages.firstNameValidation);
      }
    }
    setFirstName(e.target.value);
  };

  const handlePassword = (e) => {
    setPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setPasswordErr(errorMessages.passwordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setPasswordErr(errorMessages.passwordValidation);
      }
    }
    setPassword(e.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const handleConfirmPassword = (e) => {
    setConfirmPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setConfirmPasswordErr(errorMessages.confirmPasswordValidation);
      }
    }
    setConfirmPassword(e.target.value);
  };

  const handleLastName = (e) => {
    setLastNameErr("");
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setLastName(e.target.value);
  };

  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
  };

  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;

    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }
    const formattedValue = inputValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
    );
    if (inputValue.length === 10) {
      // Only show formatted value after 10 digits
      e.target.value = formattedValue;
      setPhone(formattedValue); // Update state with formatted number
    } else {
      e.target.value = inputValue;
      setPhone(inputValue);
    }

    if (value === "") {
      setPhoneErr(errorMessages.phoneRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(value)
      ) {
        setPhoneErr(errorMessages.phoneValidation);
      }
    }
    setPhone(e.target.value);
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none">
    <circle cx="9.79519" cy="2.00625" r="2.00625" fill="#A3D5C9"/>
<circle cx="7.67096" cy="6.13675" r="1.06213" fill="#A3D5C9"/>
<circle cx="1.23915" cy="11.5064" r="1.23915" fill="#A3D5C9"/>
<circle cx="15.2829" cy="12.4506" r="1.47518" fill="#A3D5C9"/>
<circle cx="19.0004" cy="8.26101" r="1.06213" fill="#A3D5C9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6886 0.650298C15.7808 1.14561 15.3314 1.88426 15.3314 2.88125C15.3314 3.25051 15.4132 3.69897 15.5172 3.90007C15.6192 4.09751 15.6675 4.29412 15.6244 4.33672C15.5812 4.37932 14.4255 5.56549 13.0562 6.97258L10.5663 9.5309L10.2051 9.33783C9.72642 9.08209 8.5438 9.08256 8.0643 9.33889L7.70094 9.53302L6.31214 8.14057L4.92323 6.74811L5.10155 6.40316C5.40803 5.8106 5.4491 5.28143 5.23785 4.6484C4.87201 3.5524 4.02785 2.93907 2.885 2.93907C1.62401 2.93907 0.628439 3.83469 0.499331 5.08529C0.309446 6.92561 2.08686 8.27074 3.82911 7.60537L4.36643 7.40014L5.74319 8.76298L7.11995 10.1258L6.91815 10.5489C6.61756 11.1792 6.65663 12.0617 7.01421 12.7184C8.17748 14.8542 11.3541 14.1761 11.5336 11.7535C11.5703 11.2579 11.5252 10.9367 11.3712 10.5952L11.1581 10.1225L13.751 7.52949L16.3439 4.93659L16.8074 5.13037C17.5107 5.42411 18.5064 5.31601 19.138 4.87711C20.5128 3.92202 20.5099 1.83623 19.1323 0.87901C18.7142 0.58834 18.4875 0.520836 17.8341 0.49204C17.2702 0.467257 16.9408 0.512693 16.6886 0.650298Z" fill="white"/>
</svg>
 `;

  

  const onFileUpload = (e) => {
    e.preventDefault();

    let formData = new FormData();

    formData.append("file", selectedFile);

    

    // if (selectedFile === null) {
    //     toast.current.show({ severity: 'error', summary: 'Error', detail: "Please choose the file", life: 2500 });
    // }

    if (selectedFile) {
      if (selectedFile.size < 250000) {
        setIsLoading(true)
        UserServices.bulkUserUploadEmployee(formData, roleId, companyId)
          .then((response) => {
            
            setIsLoading(false)
            if (response.status === 201) {
              // toast.current.show({
              //   severity: "success",
              //   summary: "Success",
              //   detail: "File uploaded successfully",
              //   life: 3000,
              // });

    showToast("success",`File uploaded successfully`)

              setTimeout(() => {
                setVisible(false);
               
                setPageRefresh(!pageRefresh);
              }, 3000);
            }
            if (response.status === 500) {
              // toast.current.show({
              //   severity: "warn",
              //   summary: "Failed to upload",
              //   detail: "Network error, Please try again!",
              //   life: 3000,
              // });
    showToast("error",`Network error, Please try again!`)

            }
            if (response.status === 504) {
              // toast.current.show({
              //   severity: "warn",
              //   summary: "Failed to upload",
              //   detail: "Gateway timeout!",
              //   life: 3000,
              // });
    showToast("error",`Gateway timeout!`)

            }
          })
          .catch((e) => {
            setIsLoading(false)
            if (e.response !== undefined) {
              if (e.response.status === 701) {
                // if (toast.current !== undefined) {
                //   toast.current.show({
                //     severity: "warn",
                //     summary: "Uploaded",
                //     detail: "Data already exist",
                //     life: 3000,
                //   });
                // }
    showToast("error",`Data already exist`)

              }
            }
            // toast.current.show({ severity: 'success', summary: 'Uploaded', detail: e.response.data.errorMessage, life: 3000 });
            
          });
      } else {
        setIsLoading(false)
        // toast.current.show({
        //   severity: "warn",
        //   summary: "Failed to upload",
        //   detail: "File size is larger than 250kb",
        //   life: 3000,
        // });
    showToast("error",`File size is larger than 250kb`)

      }
    }
  };

  function bytesToKilobytes(bytes) {
    if (typeof bytes !== "number" || isNaN(bytes) || bytes < 0) {
      
    } else {
      return bytes / 1024;
    }
  }

  const customHeader = (
    <div className="flex align-items-center gap-2">
      <Avatar
        image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
        shape="circle"
      />
      <span className="font-bold">Amy Elsner</span>
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      <Toast ref={toastCenter} position="center" />
      <Sidebar
        position="right"
        header={customHeader}
        visible={visible}
        className="dialog-box"
        onHide={() => setVisible(false)}
      >
        <div className="grid custom-sidebar-header">
          <div className="grid col-12 col-sm-12 col-md-12 col-lg-11 px-4 pt-4 pb-0">
            <div className="col-12 sidebar-header-text1 pb-0">
              Bulk Upload Employees
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-1 flex justify-content-end pt-4 pb-0">
            <button onClick={dialogView}>
              <i className="pi pi-times"></i>
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 sidebar-header-text2 px-4">
            Upload bulk employees by listing them in a CSV file. Make sure it’s as
            per the template provided here.{" "}
          </div>
        </div>

        <form id="myform" className="form-spacing mt-4" onSubmit={onFileUpload}>
          <div className="input-sidebar-card mt-4">
            <div className="px-6 py-4">
              <div className="flex" style={{ justifyContent: "space-between" }}>
                <div className="font-fam-for-all">
                  <span>
                    <i className="pi pi-address-book"></i>
                  </span>
                  Bulk upload employees
                </div>
              </div>
              <div>
                Manage your company’s employees and their access with simple file
                upload.
              </div>
              <div className="card pt-4 px-2 mt-4">
                {/* <div className='bulk-upload-card-header'>
                                    <a href={templateCsv} download="template.csv" className='flex gap-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5M5.83333 8.33333L10 12.5M10 12.5L14.1667 8.33333M10 12.5V2.5" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <div className='bulk-upload-card-header-text'>
                                            Download Template
                                        </div>
                                    </a>

                                </div> */}
                <div className="flex justify-content-center align-items-center pb-2">
                  <div className="text-center">
                    <Button
                      icon={selectedFile ? "pi pi-upload" : "pi pi-upload"}
                      // label={selectedFile ? selectedFile.name : ''}
                      className="p-button-rounded bg-gray-400 border-gray-400 p-button-primary p-mb-3"
                      aria-label="Upload"
                      onClick={handleClick}
                    />
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      accept=".csv"
                    />
                    <div>
                      <button
                        className=" font-fam-for-all datatable-highlight-color webapp-global-sm-size  hover:text-blue-700"
                        onClick={handleClick}
                      >
                        Click here to upload
                      </button>
                    </div>
                    <div className="upload-rules font-fam-for-all">
                      <p className="webapp-global-sm-size">CSV (max. 250kb)</p>
                    </div>
                  </div>
                </div>
                <div className="card-devider"></div>
                <div className="flex align-items-center justify-content-center py-2">
                  {/* <a href={templateCsv} download="template.csv" className='bulk-user-download-template-button-main'>
                                        <div className='bulk-user-download-template-button-text'>
                                            Download Template
                                        </div>
                                    </a> */}
                  <a
                    href={templateCsv}
                    download="template.csv"
                    className="flex gap-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5M5.83333 8.33333L10 12.5M10 12.5L14.1667 8.33333M10 12.5V2.5"
                        stroke="#004EEC"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="bulk-upload-card-header-text">
                      Download Template
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {selectedFile ? (
            <>
              <div className="bulk-upload-status-container mt-4">
                <div className="bulk-upload-status-card1 input-sidebar-card">
                  <div className="bulk-upload-status-card1-content1">
                    <div className="bulk-upload-status-card1-content1-button-main1">
                      <div className="bulk-upload-status-card1-content1-button-main1-base">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8.6665 1.3335H3.99984C3.64622 1.3335 3.30708 1.47397 3.05703 1.72402C2.80698 1.97407 2.6665 2.31321 2.6665 2.66683V13.3335C2.6665 13.6871 2.80698 14.0263 3.05703 14.2763C3.30708 14.5264 3.64622 14.6668 3.99984 14.6668H11.9998C12.3535 14.6668 12.6926 14.5264 12.9426 14.2763C13.1927 14.0263 13.3332 13.6871 13.3332 13.3335V6.00016M8.6665 1.3335L13.3332 6.00016M8.6665 1.3335V6.00016H13.3332"
                            stroke="#145EF0"
                            stroke-width="1.33333"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="bulk-upload-status-card1-content1-button-main2">
                      <div className="bulk-upload-status-card1-content1-button-main2-base">
                        <div className="bulk-upload-status-text1">
                          {selectedFile.name}
                        </div>
                        <div className="bulk-upload-status-text2">
                          {bytesToKilobytes(selectedFile.size)}Kb
                        </div>
                      </div>
                      <div className="bulk-upload-status-card1-content1-button-main2-base1">
                        <div className="progress-bar-container">
                          {/* <div className='progress-bar-empty'>

                                                    </div> */}
                          <div className="progress-bar-fill"></div>
                        </div>
                        <div className="progress-bar-label">100%</div>
                      </div>
                    </div>
                  </div>
                  <div className=''>
                    <button onClick={() => {
                      setSelectedFile(null)
                      if (fileInputRef.current) {
                        fileInputRef.current.value = null;
                      }
                    }}><i className='pi pi-times'></i></button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="flex justify-content-end dialog-form-md-group-Btn my-5">
            <Button
              type="button"
              className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
              label="Cancel"
              style={{ height: "40px" }}
              onClick={cancelView}
            ></Button>
            <Button
              type="submit"
              className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
              label="Submit"
              style={{ height: "40px" }}
            ></Button>
          </div>
        </form>

        {/* <div className='input-sidebar-card mt-4'>
                    <div className='px-6 py-4'>
                        <Toast ref={toast}></Toast>

                        <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                        <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                        <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                        <FileUpload ref={fileUploadRef} name="demo[]" url="/api/upload" multiple accept="file/*" maxFileSize={1000000}
                            onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                            headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                    </div>
                </div> */}
      </Sidebar>
    </div>
  );
};

export default EmployeeUpload;
