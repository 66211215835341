import React, { useContext, useEffect } from "react";
import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { Message } from "primereact/message";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";

import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import { Avatar } from "primereact/avatar";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";

import { InputMask } from "primereact/inputmask";

import { ProgressBar } from "primereact/progressbar";

import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import UserServices from "../../../services/userService";
import CreditCardServices from "../../../services/strapiService";
import errorMessages from "../../../utils/errorMessages";
import AddressService from "../../../services/addressService";
import rolesMasterService from "../../../services/rolesMaster";
import authContext from "../../../common/authContext";
import { showToast } from "../../../utils/toastUtils";
import useTableData from "../../../redux/Reducers/useTableData";
import { checkDomain } from "../../../utils/dnsEmailChecker";

const AddCompanyLocation = ({ companyId, visible, setVisible }) => {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh,
  } = useContext(authContext);

  const {
    data: fetchedStateData,
    isLoading: stateLoading,
    isError: isStateError,
    error: stateError,
    refetch: reftechStateData,
  } = useTableData("state");

  const {
    data: fetchedCountryData,
    isLoading: countryLoading,
    isError: isCountryError,
    error: countryError,
    refetch: reftechCountryData,
  } = useTableData("countryMaster");

  const {
    data: fetchedAddressTypeData,
    isLoading: addressTypeLoading,
    isError: isAddressTypeError,
    error: addressTypeError,
    refetch: reftechAddressTypeData,
  } = useTableData("addressType");

  const [value, setValue] = useState("");
  const [ingredient, setIngredient] = useState("");
  const toast = useRef(null);
  const [upload, setUpload] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isEqualAddress, setIsEqualAddress] = useState(true);
  const [allCustomer, setAllCustomer] = useState([]);

  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [cityErr, setcityErr] = useState("");

  const toastCenter = useRef(null);

  const [selectedSkills, setSelectedSkills] = useState(null);
  const navigate = useNavigate();

  const [salutation, setSalutation] = useState("");

  const [firstName, setFirstName] = useState("");

  const [lastName, setLastName] = useState("");

  const [website, setWebsite] = useState("");

  const [company, setCompany] = useState("");

  const [phone, setPhone] = useState("");

  const [address, setAddress] = useState("");

  const [city, setCity] = useState("");

  const [state, setState] = useState("");

  const [zip, setZip] = useState("");

  const [emailErr, setEmailErr] = useState("");

  const [passwordErr, setPasswordErr] = useState("");

  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [firstNameErr, setFirstNameErr] = useState("");

  const [lastNameErr, setLastNameErr] = useState("");

  const [phoneErr, setPhoneErr] = useState("");

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [roleId, setRoleId] = useState(null);

  const [isShowContent2, setIsShowContent2] = useState(false);

  const [isShowContent3, setIsShowContent3] = useState(false);

  const [globalErr, setGlobalErr] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const [addr1, setAddr1] = useState("");

  const [addr1Err, setAddr1Err] = useState("");

  const [addr2, setAddr2] = useState("");

  const [addr2Err, setAddr2Err] = useState("");

  const [addr3, setAddr3] = useState("");

  const [region, setRegion] = useState("");

  const [regionErr, setRegionErr] = useState("");

  const [states, setStates] = useState(null);

  const [statesErr, setStatesErr] = useState("");

  const [zipErr, setZipErr] = useState("");

  const [companyDetails, setCompanyDetails] = useState(null);

  const [allAddressType, setAllAddressType] = useState([]);

  const [addressType, setAddressType] = useState(null);

  const [allStates, setAllStates] = useState([]);

  const [allCountries, setAllCountries] = useState([]);

  const [countryData, setCountryData] = useState(null);

  const [stateValue, setStateValue] = useState(null);

  const [countryValue, setCountryValue] = useState(null);

  const [companyName, setCompanyName] = useState("");

  const [domicileSate, setdomicileSate] = useState("");

  const [DomicileCity, setDomicileCity] = useState("");

  const [FormationDate, setFormationDate] = useState("");

  const [DissolutionDate, setDissolutionDate] = useState("");

  const [addressTypeErr, setAddressTypeErr] = useState("");

  const [countryErr, setCountryErr] = useState("");

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [isContentVisible, setIsContentVisible] = useState(true);

  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    // toast.current.show({ severity: 'info', summary: 'Success', detail: 'File uploaded' });
    showToast("success", `File uploaded`);
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 1 MB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}
          ></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-file mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop File Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-file",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const dialogView = () => setVisible(false);
  const cancelView = () => {
    setVisible(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setIngredient("");
    setPhone("");
    setPassword("");
    setConfirmPassword("");
    setFirstNameErr("");
    setLastNameErr("");
    setPasswordErr("");
    setConfirmPasswordErr("");
    setPhoneErr("");
    setcityErr("");
    setZip("");
    setZipErr("");
    setEmailErr("");
    setAddr2("");
    setcityErr("");
    setStatesErr("");
    setStates(null);
    // setCountryData(null)
    setCountryErr("");
    setAddr1("");
    setAddr1Err("");
    setAddr2Err("");
    setAddressType(null);
    setAddressTypeErr("");
    setCity("");
  };

  let newObject = window.localStorage.getItem("user");
  let updatedUser = Object.assign({}, JSON.parse(newObject));

  useEffect(() => {
    setSelectedCountry({ name: "United States", code: "US", dialCode: "+1" });

    if (updatedUser) {
      if (updatedUser.companyRoles !== undefined) {
        if (updatedUser.companyRoles.length > 0) {
          if (updatedUser.companyRoles[0].roles.length > 0) {
            setRoleId(updatedUser.companyRoles[0].roles[0].id);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (fetchedStateData) {
      setAllStates(fetchedStateData);
    }
  }, [fetchedStateData]);

  useEffect(() => {
    // setCountryData("US");
    setCountryData(3);
    if (fetchedCountryData) {
      setAllCountries(fetchedCountryData);
    }
  }, [fetchedCountryData]);

  useEffect(() => {
    if (fetchedAddressTypeData) {
      if (fetchedAddressTypeData !== "") {
        const validTypes = ["PRINCIPAL", "COMPANY", "BRANCH"];
        const tempdata = fetchedAddressTypeData.filter(({ type }) =>
          validTypes.includes(type)
        );
        setAllAddressType(tempdata);
      }
    }
  }, [fetchedAddressTypeData]);

  // const handleCity = (e) => {
  //     setEmailErr('')
  //     const value = e.target.value
  //     if (value === "") {
  //         setEmailErr(errorMessages.emailRequired)
  //     }
  //     if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
  //         setEmailErr(errorMessages.emailValidation);
  //     }
  //     setIngredient(e.target.value)
  // }

  const handleCity = async (e) => {
    setEmailErr("");
    const value = e.target.value;
    setIngredient(e.target.value);
    if (value === "") {
      setEmailErr(errorMessages.emailRequired);
    }
    // if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
    //     setEmailErr(errorMessages.emailValidation);
    // }
    // const domain = value.split("@")[1];
    // const isDomainValid = await checkDomain(domain);
    // if (!isDomainValid) {
    //     setEmailErr("Please enter valid email address");
    // }
  };

  const handleEmail = (e) => {
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setEmail(e.target.value);
  };

  const handleFirstName = (e) => {
    setFirstNameErr("");
    let value = e.target.value;
    if (value === "") {
      setFirstNameErr(errorMessages.firstNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setFirstNameErr(errorMessages.firstNameValidation);
      }
    }
    setFirstName(e.target.value);
  };

  const handlePassword = (e) => {
    setPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setPasswordErr(errorMessages.passwordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setPasswordErr(errorMessages.passwordValidation);
      }
    }
    setPassword(e.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const handleConfirmPassword = (e) => {
    setConfirmPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setConfirmPasswordErr(errorMessages.confirmPasswordValidation);
      }
    }
    setConfirmPassword(e.target.value);
  };

  const handleLastName = (e) => {
    setLastNameErr("");
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setLastName(e.target.value);
  };

  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
  };

  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;
    if (value === "") {
      setPhoneErr(errorMessages.phoneRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(value)
      ) {
        setPhoneErr(errorMessages.phoneValidation);
      }
    }
    setPhone(e.target.value);
  };

  const handleToggle2 = () => {
    setIsShowContent2(!isShowContent2);
  };

  const handleToggle3 = () => {
    setIsShowContent3(!isShowContent3);
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        {selectedCountry ? (
          <span>
            <b>{selectedCountry.name}</b> selected.
          </span>
        ) : (
          "No country selected."
        )}
      </div>
    );
  };

  const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none">
    <circle cx="9.79519" cy="2.00625" r="2.00625" fill="#A3D5C9"/>
<circle cx="7.67096" cy="6.13675" r="1.06213" fill="#A3D5C9"/>
<circle cx="1.23915" cy="11.5064" r="1.23915" fill="#A3D5C9"/>
<circle cx="15.2829" cy="12.4506" r="1.47518" fill="#A3D5C9"/>
<circle cx="19.0004" cy="8.26101" r="1.06213" fill="#A3D5C9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6886 0.650298C15.7808 1.14561 15.3314 1.88426 15.3314 2.88125C15.3314 3.25051 15.4132 3.69897 15.5172 3.90007C15.6192 4.09751 15.6675 4.29412 15.6244 4.33672C15.5812 4.37932 14.4255 5.56549 13.0562 6.97258L10.5663 9.5309L10.2051 9.33783C9.72642 9.08209 8.5438 9.08256 8.0643 9.33889L7.70094 9.53302L6.31214 8.14057L4.92323 6.74811L5.10155 6.40316C5.40803 5.8106 5.4491 5.28143 5.23785 4.6484C4.87201 3.5524 4.02785 2.93907 2.885 2.93907C1.62401 2.93907 0.628439 3.83469 0.499331 5.08529C0.309446 6.92561 2.08686 8.27074 3.82911 7.60537L4.36643 7.40014L5.74319 8.76298L7.11995 10.1258L6.91815 10.5489C6.61756 11.1792 6.65663 12.0617 7.01421 12.7184C8.17748 14.8542 11.3541 14.1761 11.5336 11.7535C11.5703 11.2579 11.5252 10.9367 11.3712 10.5952L11.1581 10.1225L13.751 7.52949L16.3439 4.93659L16.8074 5.13037C17.5107 5.42411 18.5064 5.31601 19.138 4.87711C20.5128 3.92202 20.5099 1.83623 19.1323 0.87901C18.7142 0.58834 18.4875 0.520836 17.8341 0.49204C17.2702 0.467257 16.9408 0.512693 16.6886 0.650298Z" fill="white"/>
</svg>
 `;

  console.log(roleId);

  const onFileUpload = (e) => {
    e.preventDefault();

    let formData = new FormData();

    formData.append("file", selectedFile);

    console.log(selectedFile);

    if (selectedFile === null) {
      // toast.current.show({ severity: 'error', summary: 'Error', detail: "Please choose the file", life: 2500 });
      showToast("error", `Please choose the file`);
    }

    UserServices.bulkUserUpload(formData, roleId)
      .then((response) => {
        if (response.status === 201) {
        }
        if (response.status === 500) {
          // toast.current.show({ severity: 'error', summary: 'Failed To Upload', detail: "Network error, Please try again!", life: 3000 });
          showToast("error", `Network error, Please try again!`);
        }
        if (response.status === 504) {
          // toast.current.show({ severity: 'error', summary: 'Failed To Upload', detail: "Gateway timeout!", life: 3000 });
          showToast("error", `Gateway timeout!`);
        }
      })
      .catch((e) => {
        // toast.current.show({ severity: 'success', summary: 'Uploaded', detail: e.response.data.errorMessage, life: 3000 });
        console.log(e);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true);

    const actualdata = {
      addressLine1: addr1,
      addressLine2: addr2 !== "" ? addr2 : null,
      city: city,
      stateId: states,
      postalCode: zip,
      countryId: countryData,
      contactDetails: null,
      companyDetails: companyId,
      addressType: addressType,
    };
    if (
      addr1 !== "" &&
      states &&
      countryData &&
      zip !== "" &&
      addressType &&
      city !== ""
    ) {
      setIsTabRefresh(true);
      AddressService.saveAllAddress(actualdata)
        .then((res) => {
          console.log(res);

          setAddr1("");
          setAddr2("");
          setCity("");
          setZip("");
          setAddressType(null);
          setStates(null);
          // if (toast.current !== undefined) {

          //     toast?.current?.show({ severity: 'success', summary: 'Success', detail: "Location added successfully", life: 4000 });
          // }
          showToast("success", `Location added successfully`);

          setIsTabRefresh(false);
          setTimeout(() => {
            setVisible(false);
          }, 4000);
        })
        .catch((err) => {
          console.log(err);
          setIsTabRefresh(false);
          // if (toast.current !== undefined) {

          //     toast.current.show({ severity: 'warn', summary: 'Warning', detail: "Something went wrong", life: 3000 });
          // }
          showToast("error", `Something went wrong`);
        });
    } else {
      if (addr1 === "") {
        setAddr1Err("Address line 1 required");
      }
      // if (addr2 === "") {
      //     setAddr2Err("Address line 2 required")
      // }
      if (city === "") {
        setcityErr("City required");
      }
      if (zip === "") {
        setZipErr("Zip code required");
      }
      if (!states) {
        setStatesErr("State required");
      }
      if (!countryData) {
        setCountryErr("Country required");
      }
      if (!addressType) {
        setAddressTypeErr("Address type required");
      }
    }
  };

  // const handlePostalCode = (e) => {
  //     setZipErr("")
  //     let value = e.target.value
  //     if (value === '') {
  //         setZipErr("Postal code required")

  //     }
  //     if (value && value.trim() !== '') {
  //         if (!/^\d+$/.test(value)) {
  //             setZipErr("Enter valid postal code")
  //         }

  //     }
  //     setZip(e.target.value)
  // }

  const handlePostalCode = (e) => {
    let value = e.target.value;

    // Reset error
    setZipErr("");

    // Prevent non-numeric input
    if (!/^\d*$/.test(value)) {
      setZipErr("Only numbers are allowed");
      return;
    }

    // Ensure length is exactly 5 digits
    if (value.length < 5) {
      setZipErr("Postal code must be exactly 5 digits");
    } else if (value.length > 5) {
      setZipErr("Only 5 digits allowed");
      return; // Prevent further updates
    }

    // Update state only if valid
    setZip(value);
  };

  const customHeader = (
    <div className="flex align-items-center gap-2">
      <Avatar
        image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
        shape="circle"
      />
      <span className="font-bold">Amy Elsner</span>
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      <Toast ref={toastCenter} position="center" />
      <Sidebar
        position="right"
        header={customHeader}
        visible={visible}
        className="dialog-box"
        onHide={cancelView}
      >
        <div className="grid custom-sidebar-header">
          <div className="grid col-12 col-sm-12 col-md-12 col-lg-11 px-4 pt-4 pb-0">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 sidebar-header-text1 pb-0">
              Add Location
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-1 flex justify-content-end pt-4 pb-0">
            <button onClick={cancelView}>
              <i className="pi pi-times"></i>
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 sidebar-header-text2 px-4">
            Fill the below details to add a location of your business.
          </div>
        </div>

        <form id="myform" className="form-spacing mt-4" onSubmit={handleSubmit}>
          <Toast ref={toast} />

          {/* 
                    <div className=' input-sidebar-card mt-4'>
                        <div className='px-5 pt-4'>
                            <div className='flex' style={{ justifyContent: "space-between" }}>
                                <div className='font-fam-for-all srch-cand-text2'>
                                    <span><i className='pi pi-address-book'></i></span>
                                    Location Details
                                </div>
                            </div>
                        </div>

                        <div className='form-content-signup px-5 pb-5'>

                            <div className="grid  py-2">

                                <div className='col-4' style={{ fontSize: "14px" }}>
                                    <span className=" mr-3">
                                        <div class="relative">
                                            <InputText
                                                style={{ height: '40px', width: "100%" }}
                                                maxLength={50}
                                                value={companyName}
                                                id="floating_outlined3"
                                                placeholder=''
                                                className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                onChange={(e) => setCompanyName(e.target.value)}
                                                name="cityName"
                                            />

                                            <label
                                                htmlFor="floating_outlined3"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                                Branch Name<span className="form-field-mandatory">*</span>
                                            </label>
                                        </div>
                                    </span>
                                </div>

                            </div>

                        </div>
                    </div> */}

          {/* <div className=' input-sidebar-card mt-4'>
                        <div className='px-5 pt-4'>
                            <div className='flex' style={{ justifyContent: "space-between" }}>
                                <div className='font-fam-for-all srch-cand-text2'>
                                    <span><i className='pi pi-address-book'></i></span>
                                    Contact Info
                                </div>
                            </div>
                        </div>

                        <div className='form-content-signup px-5 pb-5'> */}

          {/* <div className='grid  justify-content-start'>
                                <div className='col-8'>
                                    <div className=" justify-content-center dialog-form-field-space grid  mt-2">
                                        <div className="field col-12 py-0">



                                            <span className=" ">
                                                <div class="relative">
                                                    <InputText
                                                        // className='dialog-form-input-field'
                                                        id="floating_outlined3"
                                                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                        style={{ width: '100%', height: '40px' }}
                                                        placeholder=''
                                                        maxLength={50} value={ingredient} onChange={handleCity} name='cityName'
                                                    />


                                                    <label
                                                        htmlFor="floating_outlined3"
                                                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                    >
                                                        Company Email*
                                                    </label>
                                                </div>
                                                {emailErr !== "" ? <p className='error-msg font-fam-for-all'>{emailErr}</p> : ""}
                                            </span>

                                        </div>
                                    </div>

                                </div>
                            </div> */}

          {/* <div className='grid  justify-content-start'>

                                <div className='col-8'>
                                    <div className="justify-content-center dialog-form-field-space">
                                        <div className="field py-0">
                                            <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}>
                                                <Dropdown
                                                    value={selectedCountry}
                                                    options={countries}
                                                    onChange={onCountryChange}
                                                    optionLabel="code"
                                                    valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}

                                                    style={{ width: '160px', borderRadius: '6px 0px 0px 6px' }}
                                                    className="p-dropdown font-fam-for-all"
                                                />

                                                <span className="" style={{ width: "100%" }}>
                                                    <div className="relative custom-input-mask">
                                                        <InputMask
                                                            id="floating_outlined4"
                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px', width: "100%" }}
                                                            maxLength={50}
                                                            value={phone}
                                                            mask={isFocused ? "(999) 999-9999" : ""}
                                                            onFocus={() => setIsFocused(true)}
                                                            onBlur={() => setIsFocused(false)}
                                                            onChange={handlePhone}
                                                            autoClear={false}
                                                            placeholder=''
                                                            name="cityName"
                                                        />
                                                        <label
                                                            htmlFor="floating_outlined4"
                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                        >
                                                            Company  Phone*
                                                        </label>
                                                    </div>
                                                    {phoneErr !== "" ? <p className='error-msg font-fam-for-all'>{phoneErr}</p> : ""}
                                                </span>

                                            </div>
                                            <p className='error-msg font-fam-for-all'>{phoneErr}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className=" justify-content-center dialog-form-field-space">
                                        <div className="field">


                                            <span className=" ">

                                                <div class="relative">
                                                    <InputText
                                                        // className='dialog-form-input-field'
                                                        id="floating_outlined1"
                                                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                        style={{ height: '40px', width: "100%" }} placeholder=''
                                                        maxLength={50} value={firstName} onChange={handleFirstName} name='cityName'
                                                    />


                                                    <label
                                                        htmlFor="floating_outlined1"
                                                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                    >
                                                        Ext
                                                    </label>
                                                </div>
                                                {firstNameErr !== "" ? <p className='error-msg font-fam-for-all'>{firstNameErr}</p> : ""}

                                            </span>

                                        </div>
                                    </div>
                                </div>

                            </div> */}

          {/* 
                            <div className='grid  justify-content-center'>

                                <div className='col-8'>
                                    <div className="justify-content-center dialog-form-field-space">
                                        <div className="field py-0">
                                            <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}>
                                                <Dropdown
                                                    value={selectedCountry}
                                                    options={countries}
                                                    onChange={onCountryChange}
                                                    optionLabel="code"
                                                    valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}

                                                    style={{ width: '160px', borderRadius: '6px 0px 0px 6px' }}
                                                    className="p-dropdown font-fam-for-all"
                                                />

                                                <span className="" style={{ width: "100%" }}>
                                                    <div className="relative custom-input-mask">
                                                        <InputMask
                                                            id="floating_outlined4"
                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px', width: "100%" }}
                                                            maxLength={50}
                                                            value={phone}
                                                            mask={isFocused ? "(999) 999-9999" : ""}
                                                            onFocus={() => setIsFocused(true)}
                                                            onBlur={() => setIsFocused(false)}
                                                            onChange={handlePhone}
                                                            autoClear={false}
                                                            placeholder=''
                                                            name="cityName"
                                                        />
                                                        <label
                                                            htmlFor="floating_outlined4"
                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                        >
                                                            Fax Number*
                                                        </label>
                                                    </div>
                                                    {phoneErr !== "" ? <p className='error-msg font-fam-for-all'>{phoneErr}</p> : ""}
                                                </span>

                                            </div>
                                            <p className='error-msg font-fam-for-all'>{phoneErr}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-4'>
                                    <div className=" justify-content-center dialog-form-field-space">
                                        <div className="field">


                                            <span className=" ">

                                                <div class="relative">
                                                    <InputText
                                                        // className='dialog-form-input-field'
                                                        id="floating_outlined1"
                                                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                        style={{ height: '40px', width: "100%" }} placeholder=''
                                                        maxLength={50} value={firstName} onChange={handleFirstName} name='cityName'
                                                    />


                                                    <label
                                                        htmlFor="floating_outlined1"
                                                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                    >
                                                        Ext
                                                    </label>
                                                </div>
                                                {firstNameErr !== "" ? <p className='error-msg font-fam-for-all'>{firstNameErr}</p> : ""}

                                            </span>

                                        </div>
                                    </div>
                                </div>


                                <div className='col-8'>
                                    <div className=" justify-content-center dialog-form-field-space grid  mt-2">
                                        <div className="field col-12 py-0">



                                            <span className=" ">
                                                <div class="relative">
                                                    <InputText
                                                        // className='dialog-form-input-field'
                                                        id="floating_outlined3"
                                                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                        style={{ width: '100%', height: '40px' }}
                                                        placeholder=''
                                                        maxLength={50} value={ingredient} onChange={handleCity} name='cityName'
                                                    />


                                                    <label
                                                        htmlFor="floating_outlined3"
                                                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                    >
                                                        Website*
                                                    </label>
                                                </div>
                                                {emailErr !== "" ? <p className='error-msg font-fam-for-all'>{emailErr}</p> : ""}
                                            </span>

                                        </div>
                                    </div>

                                </div>
                                <div className='col-4'>

                                </div>

                            </div> */}

          {/* </div>

                    </div> */}

          <div>
            <div className=" input-sidebar-card mt-4">
              <div className="px-5 pt-4">
                <div
                  className="flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="font-fam-for-all srch-cand-text2">
                    <span>
                      <i className="pi pi-address-book"></i>
                    </span>
                    Address
                  </div>
                </div>
              </div>

              <div className="form-content-signup px-5 pb-5">
                <div className="grid  py-2">
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4 "
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          maxLength={50}
                          value={addr1}
                          onChange={(e) => {
                            setAddr1Err("");
                            setAddr1(e.target.value);
                          }}
                          name="cityName"
                          id="floating_outlined21"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        />
                        <label
                          htmlFor="floating_outlined21"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Address Line 1{" "}
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {addr1Err !== "" ? (
                        <p className="error-msg font-fam-for-all">{addr1Err}</p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          maxLength={50}
                          value={addr2}
                          onChange={(e) => setAddr2(e.target.value)}
                          name="cityName"
                          id="floating_outlined22"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        />
                        <label
                          htmlFor="floating_outlined22"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Address Line 2{" "}
                          <span className="form-field-mandatory"></span>
                        </label>
                      </div>
                      {addr2Err !== "" ? (
                        <p className="error-msg font-fam-for-all">{addr2Err}</p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  {/* <div className='col-4' style={{ fontSize: "14px" }}>


                                        <span className=" mr-3">
                                            <div class="relative">
                                                <InputText
                                                    style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                    maxLength={50}
                                                    value={region}
                                                    onChange={(e) => setRegion(e.target.value)}
                                                    name="cityName"
                                                    id="floating_outlined23"
                                                    placeholder=''
                                                    className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                />
                                                <label htmlFor="floating_outlined23"
                                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                >
                                                    County <span className="form-field-mandatory">*</span>
                                                </label>
                                            </div>
                                            {regionErr !== "" ? <p className="error-msg font-fam-for-all">{regionErr}</p> : ""}
                                        </span>

                                    </div> */}
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4 "
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          maxLength={50}
                          value={city}
                          onChange={(e) => {
                            setcityErr("");
                            setCity(e.target.value);
                          }}
                          name="cityName"
                          id="floating_outlined24"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        />
                        <label
                          htmlFor="floating_outlined24"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          City <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {cityErr !== "" ? (
                        <p className="error-msg font-fam-for-all">{cityErr}</p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>

                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <Dropdown
                          value={states}
                          onChange={(e) => {
                            setStatesErr("");
                            setStates(e.value);
                          }}
                          filter
                          resetFilterOnHide
                          options={allStates}
                          optionValue="id"
                          optionLabel="stateFullDesc"
                          placeholder="Select a State"
                          style={{ height: "40px" }}
                          className="w-full"
                        />
                        <label
                          htmlFor="floating_outlined25"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          State <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {statesErr !== "" ? (
                        <p className="error-msg font-fam-for-all">
                          {statesErr}
                        </p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <Dropdown
                          value={countryData}
                          resetFilterOnHide
                          filter
                          disabled
                          onChange={(e) => {
                            setCountryErr("");
                            setCountryData(e.value);
                          }}
                          options={allCountries}
                          optionValue="id"
                          optionLabel="countryFullDesc"
                          placeholder="Select a Country"
                          style={{ height: "40px" }}
                          className="w-full"
                        />
                        <label
                          htmlFor="floating_outlined25"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Country{" "}
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {countryErr !== "" ? (
                        <p className="error-msg font-fam-for-all">
                          {countryErr}
                        </p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>

                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          maxLength={5}
                          value={zip}
                          onChange={handlePostalCode}
                          name="cityName"
                          id="floating_outlined26"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        />
                        <label
                          htmlFor="floating_outlined26"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Zip Code{" "}
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {zipErr !== "" ? (
                        <p className="error-msg font-fam-for-all">{zipErr}</p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>

                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-4"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <Dropdown
                          value={addressType}
                          filter
                          onChange={(e) => {
                            setAddressTypeErr("");
                            setAddressType(e.value);
                          }}
                          resetFilterOnHide
                          options={allAddressType}
                          optionLabel="type"
                          placeholder="Select Address Type"
                          style={{ height: "40px" }}
                          className="w-full rounded-lg "
                        />
                        <label
                          htmlFor="floating_outlined25"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Address Type{" "}
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {addressTypeErr !== "" ? (
                        <p className="error-msg font-fam-for-all">
                          {addressTypeErr}
                        </p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-content-end dialog-form-md-group-Btn my-5">
            <Button
              type="button"
              className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
              label="Cancel"
              style={{ height: "40px" }}
              onClick={cancelView}
            ></Button>
            <Button
              type="submit"
              className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
              label="Submit"
              style={{ height: "40px" }}
            ></Button>
          </div>
        </form>
      </Sidebar>
    </div>
  );
};

export default AddCompanyLocation;
