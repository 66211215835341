import React, { useContext, useEffect, useRef, useState } from 'react';
import MasterServices from '../services/coreServices';
import authContext from '../common/authContext';
import ContactService from '../services/ContactService';
import companyMasterService from '../services/companyMasterService';
import { Avatar } from 'primereact/avatar';
import rolesMasterService from '../services/rolesMaster';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { showToast } from './toastUtils';

const PendingRequestCard = () => {
  const { permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh, loginUserName } = useContext(authContext);

  const [requests, setRequests] = useState([]);

  const [contact, setContact] = useState([])

  const [allCompany, setAllCompany] = useState([])

  const [avatars, setAvatars] = useState({});

  const [allStatus, setAllStatus] = useState([])

  const [superAdminId, setSuperAdminId] = useState(null)

  let newObject = window.localStorage.getItem("user");

  let user = Object.assign({}, JSON.parse(newObject));

  const toast = useRef(null)

  function checkIdsExist(arr, idsToCheck) {
    // Check if any of the idsToCheck are found in arr
    const foundIds = idsToCheck.filter(idToCheck => arr.some(item => item.id === idToCheck));
    return foundIds.length > 0 ? foundIds : null;
  }
  useEffect(() => {

    if (user) {
      if (user.companyRoles !== undefined) {
        if (user.companyRoles.length > 0) {
          if (user.companyRoles[0].roles.length > 0) {
            const id = checkIdsExist(user.companyRoles[0].roles, [1, 2]);
            const empId = checkIdsExist(user.companyRoles[0].roles, [4]);
            console.log(id)
            setSuperAdminId(id)

          }
        }
      }
    }
  }, [])


  useEffect(() => {
    const fetchAvatars = async () => {
      const newAvatars = {};
      for (const item of contact) {
        if (item.contactDetails?.avatarImgPath) {
          try {
            const res = await MasterServices.getFile(item.contactDetails.avatarImgPath);
            newAvatars[item.contactDetails.id] = res.data;
          } catch (error) {
            console.error(`Error fetching avatar for ${item.contactDetails.id}:`, error);
          }
        }
      }
      setAvatars(newAvatars);
    };

    fetchAvatars();
  }, [contact]);



  useEffect(() => {
    ContactService.getAllContactByCompanyId(globalCompany?.companyDetails?.id)
      .then((res) => {
        console.log(res)
        if (res.data !== "") {
          setContact(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
        setIsTabRefresh(false)
      })
  }, [globalCompany])

  useEffect(() => {
    companyMasterService.getAllCompany()
      .then((res) => {
        console.log(res)
        if (Array.isArray(res.data)) {
          setAllCompany(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
        setIsTabRefresh(false)
      })

    rolesMasterService.getAllRoles()
      .then((res) => {
        setAllStatus(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [])

  const getContact = (id) => {
    if (!contact || contact.length === 0) return 'Contact not found';

    const filterContact = contact.find((val) => val?.contactDetails?.id === id);
    if (!filterContact || !filterContact.contactDetails) return 'Contact details unavailable';

    const { firstName = '', lastName = '' } = filterContact.contactDetails;
    return `${firstName} ${lastName}`.trim() || 'Name not available';
  };

  const getUserId = (id) => {
    if (!contact || contact.length === 0) return null;

    const filterContact = contact.find((val) => val?.contactDetails?.id === id);
    if (!filterContact || !filterContact.contactDetails) return null;

    const { firstName = '', lastName = '', userId } = filterContact.contactDetails;
    return userId || null;
  };

  const getAvatarImg = (id) => {
    if (!contact || contact.length === 0) return 'Contact not found';

    const filterContact = contact.find((val) => val?.contactDetails?.id === id);
    if (!filterContact || !filterContact.contactDetails) return 'Contact details unavailable';

    const { firstName = '', lastName = '',
      avatarImgPath = null } = filterContact.contactDetails;
    return avatarImgPath || '';
  };

  const getCompany = (id) => {
    if (!allCompany || allCompany.length === 0) return 'Company not found';

    const filterContact = allCompany.find((val) => val?.id === id);
    if (!filterContact) return 'Company details unavailable';

    return filterContact.companyName || 'Company name not available';
  };

  const constructRequestSentence = (requestType, contactName) => {
    // Extract the status names for the requestType IDs
    const roleNames = requestType
      .split(",")
      .map((typeId) => {
        const status = allStatus.find((status) => status.id === parseInt(typeId, 10));
        return status ? status.roleName : null;
      })
      .filter(Boolean); // Remove null/undefined values

    if (roleNames.length > 0) {
      return `${contactName} has requested for : ${roleNames.join(", ")}`;
    } else {
      return `${contactName} has requested for roles: Unknown`;
    }
  };


  useEffect(() => {

    if (contact.length > 0 && allCompany.length > 0 && globalCompany) {
    

      MasterServices.getAllPendingRequest()
        .then((res) => {
          console.log(res);

        
          if (Array.isArray(res.data)) {
            const temp = res.data.filter((val)=>val?.companyId === globalCompany?.companyDetails?.id)

            const formattedRequests = temp.map((item) => ({
              id: item.id,
              contactId: item.contactId,
              companyId: item.companyId,
              requestType: item.requestType || 'unknown',
              avatarImgPath: getAvatarImg(item.contactId),
              name: getContact(item.contactId),
              company: getCompany(item.companyId),
              status: item.statusId?.statusName?.toLowerCase() || 'unknown',
              requestSentence: constructRequestSentence(item.requestType, getContact(item.contactId)),
            }));
            console.log(formattedRequests);
            if (superAdminId) {
              setRequests(formattedRequests);
            }
            else {
              const userRequest = formattedRequests.filter((val) => val?.name === loginUserName)
              setRequests(userRequest)
            }
          }
        })
        .catch((err) => {
          console.error('Error fetching pending requests:', err);
        
        });
    }

  }, [contact, allCompany,isTabRefresh,globalCompany]);



  const getInitials = (name) => {
    const nameArray = name.trim().split(" ");
    if (nameArray.length >= 2) {
      const firstNameInitial = nameArray[0].charAt(0).toUpperCase();
      const lastNameInitial = nameArray[1].charAt(0).toUpperCase();
      return firstNameInitial;
    } else {

      const initial = nameArray[0].charAt(0).toUpperCase();
      return initial + initial;
    }
  };


  const handleDelete = (id) => {
    setIsTabRefresh(true)
    MasterServices.deletePendingRequest(id)
      .then((res) => {
        console.log(res)
        setIsTabRefresh(false)
        // if (toast.current !== undefined) {
        //   toast.current.show({ severity: 'success', summary: 'Success', detail: "Notification deleted successfully", life: 3000 });
        // }
                showToast("success","Notification deleted successfully")
        
      })
      .catch((err) => {
        console.log(err)
        setIsTabRefresh(false)
      })
  }



  const handleAction = (id, action) => {
    const request = requests.find((req) => req.id === id);

    if (!request) return;

    const { contactId, companyId, requestType } = request;

    const data = {
      id,
      contactId,
      companyId,
      requestType,
      statusId: {
        id: action === 'accepted' ? 27 : action === 'rejected' ? 28 : 29,
        statusName: action.toUpperCase(),
        isActive: true,
        statusForId: {
          id: 8,
          statusForName: 'ROLE PENDING',
          statusForDesc: 'Pending status for roles',
        },
      },
    };
    const actualdata = {
      "companyId": companyId,
      "roleId": requestType.split(",") || [],
      "statusId": 1,
      "userId": getUserId(contactId)
    }
    setIsTabRefresh(true)

    if (action === "accepted") {
      rolesMasterService.updateRoles(actualdata)
        .then(response => {
          console.log('Roles updated successfully', response);

          if (response.status === 201) {
            // if (toast.current !== undefined) {
            //   toast.current.show({ severity: 'success', summary: 'Success', detail: "Roles updated successfully", life: 3000 });
            // }
            showToast("success","Roles updated successfully")

          }
          MasterServices.updatePendingRequest(data)
            .then((res) => {
              console.log('Request updated successfully:', res);
              setIsTabRefresh(false)
              setRequests((prevRequests) =>
                prevRequests.map((req) =>
                  req.id === id ? { ...req, status: action } : req
                )
              );

            })
            .catch((err) => {
              console.error('Error updating request:', err);
              setIsTabRefresh(false)
            });

        })
        .catch(error => {

          console.error('Error updating roles', error);
          if (error.response !== undefined) {
            if (error.response.status === 701) {
              // if (toast.current !== undefined) {
              //   console.log('first')
              //   toast.current.show({ severity: 'warn', summary: 'Error', detail: "Roles already exist", life: 3000 });

              // }
            showToast("error","Roles already exist")

            }
            else {
              // if (toast.current !== undefined) {
              //   toast.current.show({ severity: 'warn', summary: 'Error', detail: "Something went wrong", life: 3000 });

              // }
            showToast("error","Something went wrong")
            }


          }
          setIsTabRefresh(false)
        });
    }
    else {
      MasterServices.updatePendingRequest(data)
        .then((res) => {
          console.log('Request updated successfully:', res);
          setIsTabRefresh(false)
          setRequests((prevRequests) =>
            prevRequests.map((req) =>
              req.id === id ? { ...req, status: action } : req
            )
          );

        })
        .catch((err) => {
          console.error('Error updating request:', err);
          setIsTabRefresh(false)
        });

    }

  };


  return (
    <div className="flex flex-col items-center space-y-4 p-4 w-full">
      <Toast ref={toast} />
      {requests.length > 0 ? (
        requests.map((request) => (
          <div
            key={request.id}
            className="w-full bg-white shadow-lg rounded-lg p-4 flex justify-between items-center"
          >
            <div>


              <div className="col-12 flex gap-2">
                <div>
                  <Avatar label={request.avatarImgPath ? '' : getInitials(request.name)} image={avatars[request.contactId] ? `data:image/jpeg;base64,${avatars[request.contactId]}` : ''} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                </div>
                <div className="grid">
                  <div className="col-12 pb-0">
                    <p className="srch-cand-text1">{request.name}
                    </p>
                  </div>
                  <div className="col-12 pb-0">
                    <p className="srch-cand-text">
                      {request.company}
                    </p>
                  </div>
                </div>
                <div className='srch-cand-text'>
                  {request.requestSentence}
                </div>
              </div>
            </div>
            <div className='flex space-x-2'>
              {request.status === 'pending' ? (
                <>

                  {
                    superAdminId ? <div className="flex space-x-2">
                      <button
                        onClick={() => handleAction(request.id, 'accepted')}
                        className="vstate-button text-white px-3 py-1 rounded"
                      >
                        Accept
                      </button>
                      <button
                        onClick={() => handleAction(request.id, 'rejected')}
                        className="vstate-reverse-button px-3 py-1 rounded"
                      >
                        Reject
                      </button>
                    </div> : <button
                      onClick={() => handleAction(request.id, 'cancelled')}
                      className="vstate-reverse-button px-3 py-1 rounded"
                    >
                      Cancel

                      {/*  */}
                    </button>
                    // <span
                    //   className={`${request.status === 'accepted' ? 'text-green-600' : 'text-gray-600'
                    //     } font-semibold`}
                    // >
                    //   Pending
                    // </span>
                  }

                </>
              ) : (
                <div
                  className={`${request.status === 'accepted' ? 'text-green-600' : 'text-red-600'
                    } font-semibold flex align-items-center`}
                >
                  {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                </div>
              )}
              {superAdminId && request.status !== "pending" && <button className="pi pi-trash image-btn-icon-delete ml-2" data-toggle="tooltip" data-placement="bottom" title="Delete" onClick={() => handleDelete(request.id)} ></button> }

            </div>
          </div>
        ))
      ) : (
        <div
          className="w-full bg-white shadow-lg rounded-lg p-4 flex justify-between items-center"
        >
          <Skeleton className="mb-2"></Skeleton>
          <Skeleton width="10rem" className="mb-2"></Skeleton>
          <Skeleton width="5rem" className="mb-2"></Skeleton>
          <Skeleton height="2rem" className="mb-2"></Skeleton>
          <Skeleton width="10rem" height="4rem"></Skeleton>
        </div>
      )}
    </div>
  );
};

export default PendingRequestCard;