import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Avatar } from "primereact/avatar";
import Typewriter from "../utils/TypeWritter";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import html2canvas from "html2canvas";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import authContext from "../common/authContext";
import pic1 from "../Assets/Images/Frame 31284.png";
import propertiesApp from "../utils/propertiesApp";
import { Col, Row } from "react-bootstrap";
import MasterServices from "../services/coreServices";
import errorMessages from "../utils/errorMessages";

const Chatbot = ({ setResponseData }) => {
  const { isEmailExist, setIsEmailExist } = useContext(authContext);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isActive, setIsActive] = useState(false);
  const chatWindowRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestionData, setSuggestionData] = useState("");
  const [subSuggestionsData, setSubSuggestionData] = useState("");
  const [isEmailId, setIsEmailId] = useState(false);
  const [isChatStarted, setIsChatStarted] = useState(false);

  const [nameErr, setNameErr] = useState("");

  const [emailErr, setEmailErr] = useState("");

  const [phoneErr, setPhoneErr] = useState("");

  const [initialResponse, setInitialResponse] = useState(null);

  const [isFollowUp, setIsFollowUp] = useState(false);

  const followUpTimer = useRef(null);

  const predefinedSuggestions = [
    "Primary Services",
    "Entity Formation",
    "Non-Profit-Services",
    "Registration Agent",
    "Attorneys",
    "Beneficial Ownership Information",
    "Others",
  ];

  const subSuggestions = {
    "Primary Services": [
      "DBA Registration",
      "Name Availability",
      "Conversion",
      "Corporate Kit and Seal",
      "UCC Filings",
      "Dissolution",
    ],
    "Entity Formation": ["LLC", "S-Corp", "C-Corp", "Tax ID EIN", "PC or PLLC"],
    "Non-Profit-Services": [
      "Formations of Nonforprofit -501c3",
      "Application For Exemption",
      "State Tax Exemption",
      "Foreign State Registration",
    ],

    "Registration Agent": [
      "Registered Agent",
      "Independent Director",
      "Entity Monitoring",
    ],
    "Beneficial Ownership Information": [],
    Attorneys: [],
    Others: [],
  };

  const handleUserInfoChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setNameErr("");
    }
    if (name === "email") {
      setEmailErr("");
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
        setEmailErr(errorMessages.emailValidation);
      }
    }
    if (name === "phone") {
      const phoneRegex = /^(?:\+1\d{10}|\+91\d{10}|\d{10})$/;
      if (!phoneRegex.test(value)) {
        setPhoneErr("Enter a valid  phone number.");
      } else {
        setPhoneErr("");
      }
    }
    setUserInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const [userInfo, setUserInfo] = useState({
    name: null,
    email: null,
    phone: null,
  });
  const handleStartChat = async () => {
    localStorage.setItem("userInfo", JSON.stringify(userInfo)); // Save to localStorage
    if (userInfo.phone && userInfo.name && phoneErr==="" && emailErr==="") {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${propertiesApp.chatbot_url}/auth/login/`,
          // "https://chatbot.redberyltech.com/auth/login/",
          {
            input_data: `${userInfo.name},${userInfo.email},${userInfo.phone}`,
          }
        );

        const { message } = response.data;
        setIsActive(false);
        {
          console.log(response, "message");
        } // Construct the message
        const userDetails = message.split(",");
        const name = userDetails[0]?.split(":")[1]?.trim() || "";
        const email = userDetails[1]?.split(":")[1]?.trim() || "";
        const phone = userDetails[2]?.split(":")[1]?.trim() || null;

        // Create the bot's message with conditional contact
        let botMessage = `Your name: ${name}, Your email: ${email}`;
        if (phone !== "None") {
          botMessage += `, Your contact: ${phone}`;
        }

        setIsChatStarted(true);
        setIsLoading(false);

        const botResponse = {
          sender: "bot",
          type: "first-response",
          text: botMessage,
          time: getCurrentTimeWithAMPM(),
        };
        setMessages([...messages, botResponse]);
        setInitialResponse(botResponse);
      } catch (error) {
        console.error("Error sending user info", error);
        setIsLoading(false);
      }
    } else {
      if (!userInfo.email) {
        setPhoneErr("Phone number required");
      }
      if (!userInfo.name) {
        setNameErr("Name required");
      }
    }
  };
  useEffect(() => {
    if (!initialResponse) {
      setIsActive(true);
      // const botMessage = {
      //   sender: "bot",
      //   type: "first-msg",
      //   text: "Can you provide your name, email, and contact number? So that if we get disconnected, we can reach out to you.",
      //   flag: "bot-text",
      //   time: getCurrentTimeWithAMPM(),
      // };
      // setMessages((prevMessages) => [...prevMessages, botMessage]);
    }
  }, []);

  useEffect(() => {
    if (initialResponse) {
      setMessages((prevMessages) =>
        prevMessages.filter((message) => message.type !== "first-msg ")
      );

      setTimeout(() => {
        const botSuggestions = {
          sender: "bot",
          type: "suggestions",
          suggestions: predefinedSuggestions,
          time: getCurrentTimeWithAMPM(),
        };
        setMessages((prevMessages) => [...prevMessages, botSuggestions]);
      }, 5000);
    }
  }, [initialResponse]);

  useEffect(() => {
    // Scroll to bottom whenever messages change
    chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
  }, [messages]);

  function getCurrentTimeWithAMPM() {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;
    const hoursStr = hours < 10 ? "0" + hours : hours;
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;

    const timeString = hoursStr + ":" + minutesStr + " " + ampm;
    return timeString;
  }

  const handleSend = async () => {
    setIsActive(true);

    if (input.trim()) {
      const userMessage = {
        sender: "user",
        text: input,
        flag: "user-text",
        time: getCurrentTimeWithAMPM(),
      };
      setMessages([...messages, userMessage]);

      setIsLoading(true);

      const inputData = {
        question: input,
        page:
          suggestionData === "Others"
            ? "global"
            : subSuggestionsData === ""
            ? suggestionData
            : subSuggestionsData,
      };

      try {
        const response = await axios.post(
          `${propertiesApp.chatbot_url}/bot/chat_with_us`,
          inputData
        );
        const botMessage = {
          sender: "bot",
          text: response.data.answer,
          flag: "bot-text",
          time: getCurrentTimeWithAMPM(),
        };

        await new Promise((resolve) => setTimeout(resolve, 1500));

        setMessages((prevMessages) => [...prevMessages, botMessage]);

        setIsLoading(false);
        setIsActive(false);
        setInput("");

        // Reset the follow-up timer
        if (followUpTimer.current) {
          clearTimeout(followUpTimer.current);
        }

        if (!isActive) {
          followUpTimer.current = setTimeout(() => {
            const followUpMessage = {
              sender: "bot",
              text: "Do you want to know anything else?",
              flag: "bot-text",
              type: "follow-up",
              time: getCurrentTimeWithAMPM(),
            };
            setMessages((prevMessages) => [...prevMessages, followUpMessage]);
            setIsActive(true);
          }, 120000);
        }
      } catch (error) {
        console.error("Error sending message:", error);
        const botMessage = {
          sender: "bot",
          text: `Sorry for the inconvenience. We are not able to answer your query. Please contact us at 866-638-3309 for further assistance.`,
          flag: "bot-text",
          time: getCurrentTimeWithAMPM(),
        };
        setMessages((prevMessages) => [...prevMessages, botMessage]);

        setIsLoading(false);
        setIsActive(false);
        setInput("");
        // Reset the follow-up timer
        if (followUpTimer.current) {
          clearTimeout(followUpTimer.current);
        }
        if (!isActive) {
          followUpTimer.current = setTimeout(() => {
            const followUpMessage = {
              sender: "bot",
              text: "Do you want to know anything else?",
              flag: "bot-text",
              type: "follow-up",
              time: getCurrentTimeWithAMPM(),
            };
            setMessages((prevMessages) => [...prevMessages, followUpMessage]);
            setIsActive(true);
          }, 120000);
        }
      }
    }
  };

  const handleBackClick = () => {
    setSuggestionData("");
    setSubSuggestionData("");
    const botMessage = {
      sender: "bot",
      text: "Hello! Welcome To Vstate Filings",
      flag: "bot-text",
      time: getCurrentTimeWithAMPM(),
    };
    setIsActive(true);
    const botSuggestions = {
      sender: "bot",
      type: "suggestions",
      suggestions: predefinedSuggestions,
      time: getCurrentTimeWithAMPM(),
    };
    setMessages([...messages, botSuggestions]);
  };

  const handleSuggestionClick = (suggestion) => {
    setSuggestionData(suggestion);
    setIsActive(true);
    const userMessage = {
      sender: "user",
      text: suggestion,
      flag: "user-text",
      time: getCurrentTimeWithAMPM(),
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    if (subSuggestions[suggestion].length > 0) {
      const botSubSuggestions = {
        sender: "bot",
        type: "sub-suggestions",
        suggestions: subSuggestions[suggestion],
        time: getCurrentTimeWithAMPM(),
      };
      setMessages((prevMessages) => [...prevMessages, botSubSuggestions]);
    } else {
      const botMessage = {
        sender: "bot",
        text:
          suggestion === "Others"
            ? "Please specify your query so that we can assist you better."
            : `How can I help on ${suggestion}?`,
        flag: "bot-text",
        time: getCurrentTimeWithAMPM(),
      };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    }
  };

  const handleSubSuggestionClick = async (subSuggestion) => {
    setSubSuggestionData(subSuggestion);
    setIsActive(true);

    // User message
    const userMessage = {
        sender: "user",
        text: subSuggestion,
        flag: "user-text",
        time: getCurrentTimeWithAMPM(),
    };
    setMessages((prevMessages) => [...prevMessages, userMessage]);

    setIsLoading(true); // Start loading

    try {
        // Await chat export before setting response data
        const chatMessage = await exportChatText();

        const leadData = {
            lead_name: subSuggestion,
            first_name: userInfo.name,
            last_name: null,
            email: userInfo.email,
            phone_number: userInfo.phone,
            token: null,
            message: chatMessage,
        };

        setResponseData(leadData);

        // Simulating typing delay
        await new Promise((resolve) => setTimeout(resolve, 1500));

        // Bot response handling
        const botMessage = {
            sender: "bot",
            text: subSuggestion === "Others"
                ? "Please specify your query so that we can assist you better."
                : `What do you like to know about ${subSuggestion} ?`,
            flag: "bot-text",
            time: getCurrentTimeWithAMPM(),
        };
        
        setMessages((prevMessages) => [...prevMessages, botMessage]);

    } catch (error) {
        console.error("Error processing sub-suggestion:", error);
    }

    setIsLoading(false);
    setIsActive(false);
};


  const handleFollowUpResponse = async (response) => {  // Add async here
    if (response === "no") {
        setIsFollowUp(true);
        
        const thankYouMessage = {
            sender: "bot",
            text: "Thanks for your time. I hope, you got the required information. Have a nice day!",
            flag: "bot-text",
            type: "first-response",
            time: getCurrentTimeWithAMPM(),
        };

        const outChat = await exportChatText();  // Await the function call

        const leadData = {
            lead_name: subSuggestionsData,
            first_name: userInfo.name,
            last_name: null,
            email: userInfo.email,
            phone_number: userInfo.phone,
            token: null,
            message: outChat,  // Now correctly contains chat text
        };

        MasterServices.addLead(leadData)
            .then((res) => {
                console.log("Lead submitted successfully:", res);
            })
            .catch((err) => {
                console.error("Error submitting lead:", err);
            });

        setMessages((prevMessages) => [...prevMessages, thankYouMessage]);
    } else if (response === "yes") {
        const botSuggestions = {
            sender: "bot",
            type: "suggestions",
            suggestions: predefinedSuggestions,
            time: getCurrentTimeWithAMPM(),
        };
        setMessages((prevMessages) => [...prevMessages, botSuggestions]);
        setIsActive(false);
    }
};

  const exportChat = async () => {
    
    console.log("inexport");
    try {
      const chatWindow = chatWindowRef.current;
      if (!chatWindow) {
        throw new Error("Chat window not found.");
      }

      // Select all messages, suggestions, and user choices
      const messageRows = chatWindow.querySelectorAll(
        ".bot-text, .msg, .suggestion-chip, .custom-chip"
      );

      console.log("Message Rows Found:", messageRows.length); // Debugging Log

      const currentDate = new Date();
      const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;
      let chatContent = `
    <html>
      <head>
        <style>
          body {
            font-family: 'Arial', sans-serif;
            margin: 0;
            padding: 20px;
  background-image: url("../src//Assets//Images//Chatbot\ bg.png");
            color: #333;
          }

          .chat-container {
            max-width: 500px; /* Chat width like mobile screens */
            margin: auto;
            background: white;
            padding: 15px;
            border-radius: 10px;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
          }

          .header {
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            padding: 10px;
            background: #0047ff;
            color: white;
            border-radius: 8px;
            margin-bottom: 15px;
          }

          .timestamp {
            font-size: 11px;
            color: gray;
            margin-top: 3px;
            display: block;
            text-align: center;
          }

          .bot-message, .user-message {
            padding: 12px 15px;
            border-radius: 18px;
            font-size: 14px;
            word-wrap: break-word;
            width: 80%;
            margin-bottom: 10px;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
            position: relative;
          }

          .bot-message {
     background: #e3f2fd;
    color: #0047ff;
    margin: 5%;
    text-align: left;
          }

          .user-message {
       background: #d1e7dd;
    color: #155724;
    text-align: left;
    margin-left: auto;
    width: 60%;
          }

          /* Message Tail (Bubble Look) */
          .bot-message::after, .user-message::after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 0;
            height: 0;
            border-style: solid;
          }

          .bot-message::after {
       left: -5px;
    width: 150px;
    border-width: 8px 10px 0 0;
    border-color: #e3f2fd transparent transparent transparent;
    rotate: 180deg;
          }

          .user-message::after {
           right: -8px;
    width: 80%;
    border-width: 8px 0 0 10px;
    border-color: #d1e7dd transparent transparent transparent;
    rotate: 180deg;
          }

          .suggestion-chip {
            display: inline-block;
            background-color: #f0f0f0;
            padding: 8px 12px;
            margin: 5px;
            border-radius: 20px;
            font-size: 13px;
            border: 1px solid #ccc;
            cursor: pointer;
          }

          ul {
            padding-left: 20px;
            margin-top: 5px;
          }

          li {
            margin-bottom: 5px;
            font-size: 14px;
          }
        </style>
      </head>
      <body>
        <div class="chat-container">
          <div class="header">Chat with us - ${formattedDate}</div>
    `;

      // Loop through messages
      messageRows.forEach((row, index) => {
        console.log(`Processing Row ${index + 1}:`, row.innerHTML); // Debugging

        let messageText = "";
        let messageSender = row.classList.contains("bot-text") ? "bot" : "user";

        // Extract timestamp
        const timestamp = row.querySelector(".timestamp")
          ? row.querySelector(".timestamp").innerText
          : "";

        if (messageSender === "bot") {
          // Extract bot message
          const botMessageContent = row.querySelector(
            ".message.bot, .bot-text .message"
          );
          if (botMessageContent) {
            messageText = botMessageContent.innerText.trim();
          }

          // Check for suggestions and format them as a proper list
          const suggestionElements = row.querySelectorAll(".suggestion-chip");
          if (suggestionElements.length > 0) {
            messageText += `<br><br><strong>Bot suggested:</strong><ul>`;
            suggestionElements.forEach((suggestionElem) => {
              messageText += `<li>${suggestionElem.innerText.trim()}</li>`;
            });
            messageText += `</ul>`;
          }
        } else {
          // Extract user message
          const userMessageContent = row.querySelector(
            ".msg, .message, .msg .message, .user-message"
          );
          if (userMessageContent) {
            messageText = userMessageContent.innerText.trim();
          }

          // Capture user-selected option if present
          const userChoice = row.querySelector(
            ".custom-chip, .suggestion-chip.selected"
          );
          if (userChoice) {
            messageText = `<strong>User selected:</strong> ${userChoice.innerText.trim()}`;
          }
        }

        const messagesToIgnore = ["Back To Main Menu", "Main Menu", "Go Back"];
        if (messagesToIgnore.includes(messageText.trim())) {
          console.log(`⚠️ Skipping unwanted message: ${messageText}`);
          return;
        }

        if (!messageText.trim()) {
          console.log(`⚠️ Skipping empty row ${index + 1}`);
          return;
        }

        chatContent += `
          <div class="${messageSender}-message">
            <div class="timestamp">${timestamp}</div>
            <div>${messageText}</div>
          </div>
        `;
      });

      chatContent += `</body></html>`;

      console.log("Final Chat Content:", chatContent);

      const blob = new Blob([chatContent], { type: "text/html" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "chat_transcript.html";
      link.click();

      
    } catch (error) {
      console.error("Error exporting chat:", error);
    }
  };


  const exportChatText = async () => {
    try {
        const chatWindow = chatWindowRef.current;
        if (!chatWindow) {
            throw new Error("Chat window not found.");
        }

        // Select all messages
        const messageRows = chatWindow.querySelectorAll(".bot-text, .msg");
        console.log("Message Rows Found:", messageRows.length);

        let chatText = "";

        messageRows.forEach((row, index) => {
            console.log(`Processing Row ${index + 1}:`, row.innerText); // Debugging
            
            let messageText = "";
            let messageSender = row.classList.contains("bot-text") ? "Bot" : "User";
            
            if (messageSender === "Bot") {
                const botMessageContent = row.querySelector(".message.bot, .bot-text .message");
                if (botMessageContent) {
                    messageText = botMessageContent.innerText.trim();
                }
            } else {
                const userMessageContent = row.querySelector(".msg, .message, .msg .message, .user-message");
                if (userMessageContent) {
                    messageText = userMessageContent.innerText.trim();
                }
            }

            // Ignore empty messages
            if (!messageText.trim()) {
                console.log(`⚠️ Skipping empty row ${index + 1}`);
                return;
            }

            chatText += `${messageSender}: ${messageText}\n`;
        });

        return chatText;
    } catch (error) {
        console.error("Error exporting chat text:", error);
    }
};
  console.log(messages);
//   const handleUserInfoChange = (e) => {
//   const { name, value } = e.target;

//   // If updating phone, validate it
//   if (name === "phone") {
//     const phoneRegex = /^(?:\+1\d{10}|\+91\d{10}|\d{10})$/;
//     if (!phoneRegex.test(value)) {
//       setPhoneErr("Enter a valid  phone number.");
//     } else {
//       setPhoneErr(""); // Clear error if valid
//     }
//   }

//   setUserInfo((prev) => ({ ...prev, [name]: value }));
// };


  return (
    <div className="chatbot">
      <div className="chat-window" ref={chatWindowRef}>
        <div className="bot-text grid">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1">
            <img
              src={pic1}
              crossOrigin="anonymous"
              alt="Profile"
              className="vstate-avatar-image"
            />
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10">
            <div className="mt-1 ml-1">
              {/* <h6 >vState</h6> */}

              <div className={`message bot font-fam-for-all`}>
                Hello! Welcome To vState Filings
              </div>
              {!isChatStarted && (
                <div className="message bot font-fam-for-all">
                  Can you provide your name, email, and contact number? So that
                  if we get disconnected, we can reach out to you.
                </div>
              )}
              {!isChatStarted && (
                <Row className="bot-text bot-message-container p-3 mt-3 ">
                  <Col className="message-col">
                    <div className="user-info-form">
                      <label>
                        Name: <span className="form-mandetory-field">*</span>
                      </label>
                      <InputText
                        name="name"
                        value={userInfo.name}
                        onChange={handleUserInfoChange}
                        placeholder="Enter your name"
                        className="input-field"
                      />
                      {nameErr && (
                        <p className="error-msg font-fam-for-all">{nameErr}</p>
                      )}
                      <label className="mt-3">
                        Email: <span className="form-mandetory-field"></span>
                      </label>
                      <InputText
                        name="email"
                        value={userInfo.email}
                        onChange={handleUserInfoChange}
                        placeholder="Enter your email"
                        className="input-field"
                      />
                      {emailErr && (
                        <p className="error-msg font-fam-for-all">{emailErr} </p>
                      )}
                      <label className="mt-3">Phone: <span className="form-mandetory-field">*</span></label>
                      <InputText
                        name="phone"
                        value={userInfo.phone}
                        onChange={handleUserInfoChange}
                        placeholder="Enter your phone number"
                        className="input-field"
                      />
                      {phoneErr  && (
                        <p className="error-msg font-fam-for-all">{phoneErr}</p>
                      )}
                      <Button
                        label={
                          isLoading ? (
                            <span className="loading-dots">
                              <span>.</span>
                              <span>.</span>
                              <span>.</span>
                            </span>
                          ) : (
                            "Start Chat"
                          )
                        }
                        onClick={handleStartChat}
                        onTouchStart={handleStartChat}
                        className="start-chat-button mt-3"
                        style={{ backgroundColor: "#0047ff" }}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>
        {messages.map((msg, index) => (
          <React.Fragment key={index}>
            {msg.sender === "bot" &&
            msg.type !== "suggestions" &&
            msg.type !== "sub-suggestions" &&
            msg.type !== "first-msg" &&
            msg.type !== "follow-up" ? (
              <div className="bot-text grid">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1">
                  <img
                    src={pic1}
                    crossOrigin="anonymous"
                    alt="Profile"
                    className="vstate-avatar-image"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 pb-0">
                  <div className="mt-1 ml-1">
                    <div className={`message bot font-fam-for-all p-0`}>
                      <div className="p-3">
                        <Typewriter
                          text={msg.text}
                          speed={50}
                          setIsActive={setIsActive}
                        />
                      </div>

                      {msg.type !== "first-response" && (
                        <div
                          className="flex justify-content-center"
                          style={{ borderTop: "1px solid #ccc" }}
                        >
                          <Button
                            label="Back To Main Menu"
                            disabled={isFollowUp}
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "blue",
                              fontSize: "12px",
                            }}
                            onClick={handleBackClick}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : msg.sender === "bot" && msg.type === "first-msg" ? (
              <>
                <div className="bot-text grid">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1">
                    <img
                      src={pic1}
                      crossOrigin="anonymous"
                      alt="Profile"
                      className="vstate-avatar-image"
                    />
                  </div>
                  <div className="col-10 pb-0">
                    <div className="mt-1 ml-1">
                      <div className={`message bot font-fam-for-all p-0`}>
                        <div className="p-3">
                          <Typewriter
                            text={msg.text}
                            speed={50}
                            setIsActive={setIsActive}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : msg.sender === "bot" && msg.type === "first-response" ? (
              <>
                <div className="bot-text grid">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1">
                    <img
                      src={pic1}
                      crossOrigin="anonymous"
                      alt="Profile"
                      className="vstate-avatar-image"
                    />
                  </div>
                  <div className="col-10 pb-0">
                    <div className="mt-1 ml-1">
                      <div className={`message bot font-fam-for-all p-0`}>
                        <div className="p-3">
                          <Typewriter
                            text={msg.text}
                            speed={50}
                            setIsActive={setIsActive}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : msg.sender === "bot" && msg.type === "suggestions" ? (
              <div className="bot-text grid">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1">
                  <img
                    src={pic1}
                    crossOrigin="anonymous"
                    alt="Profile"
                    className="vstate-avatar-image"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 pb-0">
                  <div className="mt-1 ml-1">
                    {/* <h6>vState</h6> */}
                    <div className={`message bot font-fam-for-all`}>
                      <p>
                        How can I assist you with following vState Filings
                        Services?
                      </p>
                      {msg.suggestions.map((suggestion, idx) => (
                        <div key={idx} className="p-1">
                          <div className="flex justify-content-center chip-main font-fam-for-all">
                            {!isFollowUp ? (
                              <Chip
                                label={suggestion}
                                onClick={() =>
                                  handleSuggestionClick(suggestion)
                                }
                                className="suggestion-chip  flex-grow-1"
                              />
                            ) : (
                              <div className="custom-chip flex-grow-1 text-center">
                                {suggestion}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : msg.sender === "bot" && msg.type === "sub-suggestions" ? (
              <div className="bot-text grid">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1">
                  <img
                    src={pic1}
                    crossOrigin="anonymous"
                    alt="Profile"
                    className="vstate-avatar-image"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 pb-0">
                  <div className="mt-1 ml-1">
                    {/* <h6>vState</h6> */}
                    <div className={`message bot font-fam-for-all`}>
                      <p>
                        Which of the below services, you would like to know
                        more?
                      </p>
                      {msg.suggestions.map((subSuggestion, idx) => (
                        <div key={idx} className="p-1">
                          <div className="flex justify-content-center chip-main font-fam-for-all">
                            {!isFollowUp ? (
                              <Chip
                                label={subSuggestion}
                                onClick={() =>
                                  handleSubSuggestionClick(subSuggestion)
                                }
                                aria-disabled={msg.type === "follow-up"}
                                className="suggestion-chip flex-grow-1"
                              />
                            ) : (
                              <div className="custom-chip flex-grow-1 text-center">
                                {subSuggestion}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <div
                        className="flex justify-content-center"
                        style={{ borderTop: "1px solid #ccc" }}
                      >
                        <Button
                          label="Back To Main Menu"
                          disabled={isFollowUp}
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            color: "blue",
                            fontSize: "12px",
                          }}
                          onClick={handleBackClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : msg.sender === "bot" && msg.type === "follow-up" ? (
              <>
                <div className="bot-text grid">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1">
                    <img
                      src={pic1}
                      crossOrigin="anonymous"
                      alt="Profile"
                      className="vstate-avatar-image"
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 pb-0">
                    <div className="mt-1 ml-1">
                      <div className={`message bot font-fam-for-all p-0`}>
                        <div className="p-3">
                          We haven’t heard from you for a while, can you confirm
                          if you want to continue?
                        </div>

                        {msg.type === "follow-up" && (
                          <>
                            <div className="p-1">
                              <div className="flex justify-content-center chip-main font-fam-for-all">
                                {!isFollowUp ? (
                                  <Chip
                                    label="Yes"
                                    onClick={() =>
                                      handleFollowUpResponse("yes")
                                    }
                                    className="suggestion-chip flex-grow-1"
                                  />
                                ) : (
                                  <div className="custom-chip flex-grow-1 text-center">
                                    Yes
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="p-1">
                              <div className="flex justify-content-center chip-main font-fam-for-all">
                                {!isFollowUp ? (
                                  <Chip
                                    label="No"
                                    onClick={() => handleFollowUpResponse("no")}
                                    className="suggestion-chip flex-grow-1"
                                  />
                                ) : (
                                  <div className="custom-chip flex-grow-1 text-center">
                                    No
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        )}

                        {msg.type !== "first-response" &&
                          msg.type !== "follow-up" && (
                            <div
                              className="flex justify-content-center"
                              style={{ borderTop: "1px solid #ccc" }}
                            >
                              <Button
                                label="Back To Main Menu"
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  color: "blue",
                                  fontSize: "12px",
                                }}
                                onClick={handleBackClick}
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="grid">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2"></div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 pb-0">
                  <div className={`msg ${msg.flag} font-fam-for-all`}>
                    <div className={`message ${msg.sender} font-fam-for-all`}>
                      {msg.text}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={`msg ${msg.flag} mb-2 font-fam-for-all`}>
              <small
                style={
                  msg.sender === "user"
                    ? { alignSelf: "flex-end", fontSize: "12px" }
                    : {
                        alignSelf: "flex-start",
                        fontSize: "12px",
                        marginLeft: "40px",
                      }
                }
              >
                {msg.time}
              </small>
            </div>

            {index === messages.length - 1 && isLoading ? (
              <div className="bot-text grid">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1">
                  <img
                    src={pic1}
                    crossOrigin="anonymous"
                    alt="Profile"
                    className="vstate-avatar-image"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 pb-0">
                  <div className="mt-1 ">
                    {/* <h6>vState</h6> */}

                    <div className="is-typing bot-text ml-6">
                      <div className="jump1"></div>
                      <div className="jump2"></div>
                      <div className="jump3"></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        ))}
      </div>

      <div className="input-area">
        {!isEmailExist && (
          <>
            <InputTextarea
              type="text"
              value={input}
              disabled={suggestionData === "" || isActive || isFollowUp}
              cols={35}
              rows={1}
              style={{ border: "none", fontSize: "14px" }}
              className="font-fam-for-all"
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && !isLoading && !e.shiftKey) {
                  handleSend();
                }
              }}
              placeholder="Type a message..."
            />
            <Button
              icon="pi pi-send"
              className="mr-0 "
              tooltip="Send"
              tooltipOptions={{ position: "bottom" }}
              style={{ backgroundColor: "transparent", color: "#8F9195" }}
              disabled={isActive || input === "" || isFollowUp}
              onClick={handleSend}
            ></Button>
            <Button
              className="mr-0 p-0"
              icon="pi pi-download"
              disabled={suggestionData === "" || isActive}
              style={{ backgroundColor: "transparent", color: "#8F9195" }}
              tooltip="Download"
              tooltipOptions={{ position: "left" }}
              onClick={exportChatText}
            ></Button>
          </>
        )}

        {isEmailExist && (
          <>
            <InputTextarea
              type="text"
              value={input}
              disabled={suggestionData === "" || isActive || isFollowUp}
              style={{ border: "none", fontSize: "14px" }}
              className="font-fam-for-all"
              cols={35}
              rows={1}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && !isLoading && !e.shiftKey) {
                  handleSend();
                }
              }}
              placeholder="Type a message..."
            />
            <Button
              icon="pi pi-send"
              className="mr-0 "
              tooltip="Send"
              tooltipOptions={{ position: "bottom" }}
              style={{
                backgroundColor: "transparent",
                color: "#475467",
                fontWeight: "700",
                fontSize: "16px",
              }}
              disabled={isActive || input === "" || isFollowUp}
              onClick={handleSend}
            ></Button>
            <Button
              className="mr-0 p-0"
              icon="pi pi-download"
              style={{ backgroundColor: "transparent", color: "#475467" }}
              tooltip="Download"
              disabled={suggestionData === "" || isActive}
              tooltipOptions={{ position: "left" }}
              onClick={exportChat}
            ></Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Chatbot;