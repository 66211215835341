
// import axios from 'axios';
// import encryption from '../services/CryptoClass';



// const axiosInstance = axios.create();


// axiosInstance.interceptors.request.use((config) => {
    
//     if (config.data.payload) {
//         if (config.data.payload.username) {
//             config.data.payload.username = encryption.encryptData(config.data.payload.username);
//         }
//         if (config.data.payload.mobileNo) {
//             config.data.payload.mobileNo = encryption.encryptData(config.data.payload.mobileNo);
//         }
//     }
//     return config;
// }, (error) => {
    
//     return Promise.reject(error);
// });

// export default axiosInstance;

import axios from "axios";
import encryption from "../services/CryptoClass";
import { sanitizePayload } from "../services/sqlSanitizerService"; // Import the sanitizer

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.data) {
        // Sanitize all input fields before processing
        config.data = sanitizePayload(config.data);

        // Encrypt specific sensitive fields after sanitization
        if (config.data.payload) {
            ["username", "mobileNo"].forEach((field) => {
                if (config.data.payload[field]) {
                    config.data.payload[field] = encryption.encryptData(config.data.payload[field]);
                }
            });
        }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
