import axios from "axios";
import authHeader, { initAuth } from "./authHeader";
import encryption from "./CryptoClass";
import propertiesApp from "../utils/propertiesApp";



const getInit =()=>{
   

    // try {
    //         return axios.get(`/api/content-types`,
    //     { headers: initAuth() }
    //     )
    // } catch (error) {
    //     return []
    // }

    const dataToEncrypt = {
        endpoint: `/contact/api/content-types`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            // return axios.get(`/decrypt?data=${dataToEncrypt}`,
            return axios.post(`/decrypt`,dataToEncrypt,

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}





const ShiftsServices = {
  getInit,
}


export default ShiftsServices;