import React, { useContext, useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Dropdown } from 'primereact/dropdown';
import MasterServices from '../../services/coreServices';
import { CSVLink } from 'react-csv';
import MasterDashboard from '../MasterDashboard';
import { Switch } from '@mui/material';
import authContext from '../../common/authContext';


const RoleMasterDemo = ({ data, items }) => {

    const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh } = useContext(authContext);

    const [products, setProducts] = useState([]);
    const [columns, setColumns] = useState([]);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: 'contains' },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [visibleColumns, setVisibleColumns] = useState([]);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [formData, setFormData] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [additionalOptions, setAdditionalOptions] = useState({});
    const [allConfig, setAllConfig] = useState([])
    const [errors, setErrors] = useState({});

    const dt = useRef(null)
    const tempdata = items;
    console.log(data)

    useEffect(() => {
        if (data) {
            MasterServices.getAllConfig()
                .then((res) => {
                    console.log(res)
                    if (Array.isArray(res.data)) {
                        const temp = res.data.filter((val) => val.masterManager !== null && val.masterManager.id === data.id)

                        console.log(temp)

                        setAllConfig(temp)
                        setColumns(temp)
                        setVisibleColumns(temp);
                        if (Array.isArray(temp)) {
                            temp.forEach((val) => {
                                if ((val.fieldTypeMaster.fieldTypeDesc === "enum") || (val.fieldTypeMaster.fieldTypeDesc === "array")) {
                                    MasterServices.getAll( val.getApiUrl)
                                        .then(res => {
                                            if (Array.isArray(res.data)) {
                                                setAdditionalOptions(prevOptions => ({
                                                    ...prevOptions,
                                                    [val.mappedDtoField]: res.data

                                                }));
                                            }

                                        })
                                        .catch(err => console.log(err));
                                }
                            })
                        }
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }, [data])


    function convertToTitleCase(str) {

        const result = str.replace(/([A-Z])/g, ' $1').trim();


        return result.charAt(0).toUpperCase() + result.slice(1);
    }

    useEffect(() => {
        if (data) {
            MasterServices.getAll( data.getApiUrl)
                .then((res) => {
                    if (res.data !== "") {
                        if (res.data && res.data.length > 0) {
                            setProducts(res.data);


                            const dynamicColumns = Object.keys(res.data[0])
                                .filter(key => !['createdBy', 'creationDate', 'lastModifiedBy', 'lastModifiedDate', 'id'].includes(key))
                                .map(key => {
                                    const valueType = typeof res.data[0][key];
                                    return {
                                        field: key,
                                        header: key.charAt(0).toUpperCase() + key.slice(1),
                                        type: valueType === 'string' ? 'string' :
                                            Array.isArray(res.data[0][key]) ? 'array' :
                                                valueType === 'object' ? 'object' :
                                                    null
                                    };
                                })
                                .filter(col => col !== null);

                            console.log(dynamicColumns)


                        }
                    }
                    else {
                        setProducts([])
                        setVisibleColumns([])
                        setColumns([])
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setProducts([]);
                });
        }
    }, [data,isTabRefresh]);

    console.log(additionalOptions)

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter((col) =>
            selectedColumns.some((sCol) => sCol.mappedDtoField === col.mappedDtoField)
        );
        setVisibleColumns(orderedSelectedColumns);
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const processCSVData = (data) => {
        return data.map(item => {
            const processedItem = {};
    
            // Loop through each key in the product object
            Object.keys(item).forEach(key => {
                // Exclude unwanted fields
                if (!['createdBy', 'creationDate', 'lastModifiedBy', 'lastModifiedDate', 'id'].includes(key)) {
                    const value = item[key];
    
                    // Handling objects that are not arrays
                    if (typeof value === 'object' && !Array.isArray(value)) {
                        if (value) {
                            const keys = Object.keys(value).filter(k => !['createdBy', 'creationDate', 'lastModifiedBy', 'lastModifiedDate', 'id'].includes(k));
                            if (keys.length > 0) {
                                const firstKey = keys[0];
                                processedItem[key] = value[firstKey];
                            }
                        }
    
                    // Handling arrays
                    } else if (Array.isArray(value)) {
                        if (value.length > 0) {
                            // Collect all first keys' values from each array element
                            const arrayValues = value.map(val => {
                                const keys = Object.keys(val).filter(k => !['createdBy', 'creationDate', 'lastModifiedBy', 'lastModifiedDate', 'id'].includes(k));
                                if (keys.length > 0) {
                                    return val[keys[0]]; // Get the first key's value
                                }
                                return null;
                            }).filter(v => v !== null); // Filter out null values
    
                            processedItem[key] = arrayValues; // Store the collected array
                        }
    
                    // Handling primitive values
                    } else {
                        processedItem[key] = value;
                    }
                }
            });
    
            return processedItem;
        });
    };
    

    const renderHeader = () => {
        return (
            <div>
                <div className="flex align-items-center justify-content-between">
                    <div className="flex align-items-center gap-2">
                        <MultiSelect
                            value={visibleColumns}
                            options={columns}
                            optionLabel="fieldLabel"
                            onChange={onColumnToggle}
                            filter
                            className="w-full sm:w-20rem"
                            display="chip"
                        />
                        <span className="p-input-icon-left ml-3">
                            <i className="pi pi-search" />
                            <InputText
                                value={globalFilterValue}
                                onChange={onGlobalFilterChange}
                                style={{ borderRadius: "8px" }}
                                placeholder="Keyword Search"
                            />
                        </span>

                    </div>

                    <div className='flex gap-3'>
                        <span>
                            {
                                products.length > 0 ? <CSVLink filename={`${data.displayName}.csv`} data={processCSVData(products)}>
                                    <Button
                                        type="button"
                                        icon="pi pi-file"
                                        className="vstate-reverse-button font-fam-for-all" label='Download CSV' data-pr-tooltip="Download CSV" tooltipOptions={{ position: 'bottom' }}
                                    ></Button>
                                </CSVLink> : <Button
                                    type="button"
                                    icon="pi pi-file"
                                    disabled={products.length > 0 ? false : true}
                                    className="vstate-reverse-button font-fam-for-all" data-pr-tooltip="Download CSV" label='Download CSV' tooltipOptions={{ position: 'bottom' }}
                                ></Button>
                            }
                        </span>
                        <Button label="Add" icon="pi pi-plus" className='vstate-button font-fam-for-all' onClick={() => openSidebar(false)} />
                    </div>
                </div>
            </div>
        );
    };

    const header = renderHeader();

    const iconBodyTemplate = (rowData) => {
        return (
            <>
                <button className="pi pi-pencil image-btn-icon" data-toggle="tooltip" data-placement="bottom" title="Edit" onClick={() => openSidebar(true, rowData)}></button>
                {/* <button className="pi pi-trash image-btn-icon-delete" data-toggle="tooltip" data-placement="bottom" title="Delete"></button> */}
            </>
        );
    };

    const openSidebar = (isEdit, data = {}) => {
        setIsEditMode(isEdit);
        console.log(data)
        setFormData(data);
        setSidebarVisible(true);
    };

    console.log(additionalOptions)


    const renderFormField = (col) => {

        const errorMessage = errors[col.mappedDtoField];

        switch (col.fieldTypeMaster.fieldTypeDesc) {
            case 'string':
                return (
                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space w-full">
                                <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(col.fieldLabel)}{col.isMandatory !== undefined && col.isMandatory === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                <span className="p-float-label">
                                    <InputText
                                        value={formData[col.mappedDtoField] || ''}
                                        style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px' }}
                                        className='dialog-form-input-field-wizard p-inputtext'
                                        onChange={(e) => onInputChange(e, col)}
                                    />
                                </span>
                            </div>

                            {errorMessage && <p className='error-msg font-fam-for-all'>{errorMessage}</p>}
                        </div>
                    </div>
                );

                case 'number':
                    return (
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className=" justify-content-center dialog-form-field-space w-full">
                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(col.fieldLabel)}{col.isMandatory !== undefined && col.isMandatory === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label">
                                        <InputText
                                            value={formData[col.mappedDtoField] || ''}
                                            style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px' }}
                                            className='dialog-form-input-field-wizard p-inputtext'
                                            onChange={(e) => onInputChange(e, col)}
                                        />
                                    </span>
                                </div>
    
                                {errorMessage && <p className='error-msg font-fam-for-all'>{errorMessage}</p>}
                            </div>
                        </div>
                    );

            case 'enum':
                if (col.getApiUrl !== undefined && additionalOptions[col.mappedDtoField]) {
                    return (
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className=" justify-content-center dialog-form-field-space w-full">
                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(col.fieldLabel)}{col.isMandatory !== undefined && col.isMandatory === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label">
                                        <Dropdown
                                            value={formData[col.mappedDtoField] || {}}
                                            style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px' }}
                                            className='dialog-form-input-field-wizard'
                                            options={additionalOptions[col.mappedDtoField]} // Use pre-fetched options
                                            optionLabel={col.optionLabel}
                                            onChange={(e) => onInputChange(e, col)}
                                        />
                                    </span>
                                </div>
                                {errorMessage && <p className='error-msg font-fam-for-all'>{errorMessage}</p>}

                            </div>
                        </div>
                    );
                }
                return null;

                case 'boolean':
                    return (
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className="justify-content-center dialog-form-field-space w-full">
                                    <label htmlFor='' className='form-label font-fam-for-all'>
                                        {convertToTitleCase(col.fieldLabel)}
                                        {col.isMandatory !== undefined && col.isMandatory === true ? (
                                            <span className='form-field-mandatory'>*</span>
                                        ) : null}
                                    </label>
                                    <span className="p-float-label">
                                        <Switch
                                            checked={formData[col.mappedDtoField]}
                                            onChange={(e) => {
                                                console.log(e)
                                                onInputChecked(e, col)
                                            }}  
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </span>
                                </div>
                                {errorMessage && <p className='error-msg font-fam-for-all'>{errorMessage}</p>}
                            </div>
                        </div>
                    );
                

            case 'array':
                return (
                    <div className='input-fields-container' style={{ flexDirection: 'column', width: '50%' }}>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space w-full">
                                <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(col.fieldLabel)}{col.isMandatory !== undefined && col.isMandatory === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                <span className="p-float-label">
                                    <MultiSelect
                                        value={formData[col.mappedDtoField] || []}
                                        style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px', width: '285px' }}
                                        className='dialog-form-input-field-wizard multiselect-demo'
                                        options={additionalOptions[col.mappedDtoField]?.value || []}
                                        optionLabel={additionalOptions[col.mappedDtoField]?.label}
                                        onChange={(e) => onInputChange(e, col)}
                                    />
                                </span>
                            </div>
                            {errorMessage && <p className='error-msg font-fam-for-all'>{errorMessage}</p>}

                        </div>
                    </div>
                );

            default:
                return null;
        }
    };


    // const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    // const exportCSV = (selectionOnly) => {
    //     dt.current.exportCSV({ selectionOnly });
    // };


    const onInputChange = (e, field) => {
        const value = e.target.value;
        let errorMsg = '';
    
    
        setFormData((prevData) => ({ ...prevData, [field.mappedDtoField]: value }));
    
    
        if (field.isMandatory && !value) {
            errorMsg = `${convertToTitleCase(field.fieldLabel)} is required.`;
        }
    

        if (field.minLength > 0 && value.length < field.minLength) {
            errorMsg = `${convertToTitleCase(field.fieldLabel)} must be at least ${field.minLength} characters.`;
        }
    
        
        if (field.maxLength > 0 && value.length > field.maxLength) {
            errorMsg = `${convertToTitleCase(field.fieldLabel)} cannot exceed ${field.maxLength} characters.`;
        }
    
        
        if (field.validationRegex && !new RegExp(field.validationRegex).test(value)) {
            errorMsg = `${convertToTitleCase(field.fieldLabel)} is invalid.`;
        }
    
    
        setErrors((prevErrors) => ({ ...prevErrors, [field.mappedDtoField]: errorMsg }));
    };

    const onInputChecked = (e, field) => {
        const value = e.target.checked;
        let errorMsg = '';


        console.log(e)
    
    
        setFormData((prevData) => ({ ...prevData, [field.mappedDtoField]: value }));
    
    
        // if (field.isMandatory && !value) {
        //     errorMsg = `${convertToTitleCase(field.fieldLabel)} is required.`;
        // }
    

        if (field.minLength > 0 && value.length < field.minLength) {
            errorMsg = `${convertToTitleCase(field.fieldLabel)} must be at least ${field.minLength} characters.`;
        }
    
        
        if (field.maxLength > 0 && value.length > field.maxLength) {
            errorMsg = `${convertToTitleCase(field.fieldLabel)} cannot exceed ${field.maxLength} characters.`;
        }
    
        
        if (field.validationRegex && !new RegExp(field.validationRegex).test(value)) {
            errorMsg = `${convertToTitleCase(field.fieldLabel)} is invalid.`;
        }
    
    
        setErrors((prevErrors) => ({ ...prevErrors, [field.mappedDtoField]: errorMsg }));
    };
    

    const saveForm = () => {
        let formValid = true;
        const newErrors = {};

        columns.forEach((col) => {
            const value = formData[col.mappedDtoField] || '';
            let errorMsg = '';


            if (col.isMandatory && !value) {
                errorMsg = `${convertToTitleCase(col.fieldLabel)} is required.`;
                formValid = false;
            }


            if (col.minLength > 0 && value.length < col.minLength) {
                errorMsg = `${convertToTitleCase(col.fieldLabel)} must be at least ${col.minLength} characters.`;
                formValid = false;
            }


            if (col.maxLength > 0 && value.length > col.maxLength) {
                errorMsg = `${convertToTitleCase(col.fieldLabel)} cannot exceed ${col.maxLength} characters.`;
                formValid = false;
            }


            if (errorMsg) {
                newErrors[col.mappedDtoField] = errorMsg;
            }
        });

        setErrors(newErrors);

        if (formValid) {

            console.log("Form is valid, submitting data: ", formData);
setIsTabRefresh(true)
            if (isEditMode) {
                MasterServices.updateById(formData,  data.updateApiUrl)
                    .then((res) => {
                        console.log(res)
                        setSidebarVisible(false);
                        // window.location.reload(false)
                        setIsTabRefresh(false)
                    })
                    .catch((err) => {
                        console.log(err)
                        setSidebarVisible(false);
                        setIsTabRefresh(false)
                    })
            } else {
                MasterServices.saveAll(formData,  data.saveApiUrl)
                    .then((res) => {
                        console.log(res);
                        setSidebarVisible(false);
                        // window.location.reload(false)
                        setIsTabRefresh(false)
                    })
                    .catch((err) => {
                        console.log(err);
                        setSidebarVisible(false);
                        setIsTabRefresh(false)
                    });
            }

        } else {
            console.log("Form contains errors.");
        }
    };



    const objectBodyTemplate = (rowData, field) => {
        const data = rowData[field];
        console.log(data)
        if (data) {
            const keys = Object.keys(data).filter(key => !['createdBy', 'creationDate', 'lastModifiedBy', 'lastModifiedDate', 'id'].includes(key));
            if (keys.length > 0) {
                const firstKey = keys[0];
                const firstValue = data[firstKey];

                return (
                    <div key={firstKey}>
                        {firstValue}
                    </div>
                );
            } else {
                return <></>;
            }
        } else {
            return <></>;
        }
    };

    const arrayBodyTemplate = (rowData, field) => {
        if (Array.isArray(rowData[field])) {
            console.log(rowData[field]);

            const filteredItems = rowData[field].map(item => {
                const filteredItem = Object.keys(item)
                    .filter(key => !['createdBy', 'creationDate', 'lastModifiedBy', 'lastModifiedDate', 'id'].includes(key))
                    .reduce((obj, key) => {
                        obj[key] = item[key];
                        return obj;
                    }, {});

                return filteredItem;
            });

            console.log(filteredItems);

            if (filteredItems.length > 0) {
                const firstValues = filteredItems.map(item => {
                    const firstValue = Object.values(item)[0];
                    return firstValue;
                });

                return (
                    <div>
                        {firstValues.join(', ')}
                    </div>
                );
            }
        } else {
            console.log(rowData[field]);
            return <></>;
        }

        return <></>;
    };




    // const toPascalCase = (str) => {
    //     return str.replace(/-/g, ' ').replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    // };

    console.log(visibleColumns)
    console.log(products)
    console.log(columns)

    return (
        <div>
            <DataTable
                value={products}
                header={header}
                editMode="row"
                dataKey="id"
                paginator
                rows={10}
                scrollable
                ref={dt}
                filters={filters}
                rowsPerPageOptions={[5, 10, 25, 50]}
                className="datatable-sm font-fam-for-all master-datatable1"
                globalFilterFields={columns.map(col => col.mappedDtoField)}
            >
                {visibleColumns.map(col => (
                    <Column
                        key={col.mappedDtoField}
                        field={col.mappedDtoField}
                        header={col.fieldLabel}
                        sortable
                        className="font-fam-for-all datatable-sm-col datatable-lg-col"
                        body={col.fieldTypeMaster.fieldTypeDesc === 'enum' ? (rowData) => objectBodyTemplate(rowData, col.mappedDtoField)
                            : col.fieldTypeMaster.fieldTypeDesc === 'array' ? (rowData) => arrayBodyTemplate(rowData, col.mappedDtoField)
                                : undefined}
                    />
                ))}
                <Column
                    body={iconBodyTemplate}
                    header="Action"
                    className="font-fam-for-all datatable-lg-col webapp-sm-size"
                />
            </DataTable>

            <Sidebar visible={sidebarVisible} position='right' className='dialog-box' onHide={() => setSidebarVisible(false)}>
                <div className='grid custom-sidebar-header px-2'>
                    <div className='grid col-11 px-4 pt-4 pb-0'>
                        <div className='col-12 sidebar-header-text1 pb-0'>
                            {isEditMode ? `Edit ${data.displayName}` : `Add ${data.displayName}`}
                        </div>

                    </div>
                    <div className='col-1 flex justify-content-end pt-4 pb-0'>
                        <button onClick={() => setSidebarVisible(false)}><i className='pi pi-times'></i></button>
                    </div>
                    <div className='col-12 sidebar-header-text2 px-4'>
                        {`Fill the below details to add ${data.displayName}  of your business.`}
                    </div>
                </div>
                <form>
                    <div className='p-4'>
                        <div className="vstate-card p-4 mb-3">
                            <div className="grid" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div className="col-12 pb-0" style={{ width: '100%' }}>
                                    <h2 className="entity-header-strapi">{data.displayName}</h2>
                                </div>
                                {columns.map((col, k) => {
                                    if (!isEditMode && col.mappedDtoField === "id") {
                                        return (<></>)
                                    }
                                    else if (col.mappedDtoField !== "id") {
                                        return (
                                            <div className="col-6" key={k} style={{ width: '50%' }}>
                                                {renderFormField(col)}
                                            </div>
                                        )
                                    }

                                })}
                            </div>
                            <div className='flex justify-content-end dialog-form-md-group-Btn my-2'>
                                <Button type="button" className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3" label='Cancel' style={{ height: "40px" }} onClick={() => {
                                    setSidebarVisible(false)
                                    setFormData({});
                                    setErrors({});
                                    }} ></Button>
                                <Button type='button' className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Submit' style={{ height: "40px" }} onClick={saveForm}  ></Button>
                            </div>
                        </div>
                    </div>

                    {/* <div className=' input-sidebar-card mt-4 px-4 py-4'>
                        <div className='grid px-3'>
                            {columns.map(col => {
                                return (
                                    <>

                                        <div className='col-6'>
                                            {renderFormField(col)}
                                        </div>

                                    </>
                                )

                            })}
                        </div>
                        <div className='flex justify-content-end dialog-form-md-group-Btn my-5'>
                            <Button type="button" className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3" label='Cancel' style={{ height: "40px" }} onClick={() => setSidebarVisible(false)} ></Button>
                            <Button type='button' className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Submit' style={{ height: "40px" }} onClick={saveForm}  ></Button>
                        </div>

                    </div> */}
                </form>
            </Sidebar>
        </div>
    );
};

export default RoleMasterDemo;