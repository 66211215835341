import React, { useContext, useEffect, useState } from "react";
import { formatDate } from "../../utils/dateFormat";
import OrderServices from "../../services/orderServices";
import authContext from "../../common/authContext";
import { useNavigate } from "react-router-dom";

function AccountSummary() {
  const { globalCompany, setGlobalCompany, loginUserName, setLoginUserName,setFilterStatusId } = useContext(authContext);

  const navigate = useNavigate()

  const [dashboardData, setDashboardData] = useState([])

  const [totalOrderCount,setTotalOrderCount]=useState(0)

  useEffect(() => {
    if (globalCompany !== null) {
      console.log(globalCompany)
      if (globalCompany.companyDetails && globalCompany.companyDetails !== undefined) {
        OrderServices.getOrderCountByCompanyId(globalCompany.companyDetails.id)
          .then((res) => {
            console.log(res)
            if (Array.isArray(res.data)) {
              setDashboardData(res.data)
              const totalOrder = res.data.reduce((acc, curr) => acc + (curr.orderCount || 0), 0);
              setTotalOrderCount(totalOrder)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }

  }, [globalCompany])

  
  
  const handleOrderWithStatus = (data) => {
    console.log(data)
    setFilterStatusId(data)
    navigate("/order")
  }

  return (
    <div className="flex overflow-hidden flex-col w-full bg-white rounded-lg border border-gray-200 shadow-sm max-md:max-w-full">    
      <header className="flex flex-col w-full  max-md:max-w-full" style={{ background: "#F0F5FF" }}>
        <div className="flex gap-4 items-start p-4 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex flex-1 shrink gap-4 items-center w-full basis-0 min-w-[240px]">
            <div className="flex flex-col flex-1 shrink self-stretch my-auto w-full basis-0 min-w-[240px]">
              <h2 className="m-0 text-2xl font-medium leading-loose text-gray-900">Orders Snapshot</h2>
              <p className="mb-0 text-base leading-none text-gray-500">{formatDate(new Date())}</p>
            </div>
          </div>
        </div>
        <hr className="w-full m-0 bg-gray-200 min-h-[1px] max-md:max-w-full" />
      </header>
      <div className="flex flex-col p-4 w-full font-medium max-md:px-5 max-md:max-w-full">
        <section className="flex flex-col w-full">
          {/* <h3 className="text-base text-gray-900">Order Overview</h3> */}
          <button className="flex gap-6 items-start p-3 mt-3 w-full text-base bg-white rounded-lg border border-gray-200 border-solid shadow max-md:px-5 hover:shadow-lg transform hover:scale-105 transition-transform duration-300" onClick={()=>handleOrderWithStatus(null)}>
          <div className="flex flex-col flex-1 shrink justify-center w-full basis-0 min-w-[240px]">
              <div className="flex gap-4 items-center w-full">
                <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0 min-w-[240px]">
                  <p className="mb-0 leading-10 text-slate-700 text-start">Total Orders</p>
                  <p className=" mb-0 leading-7 text-gray-500 text-start">
                    <span className="text-3xl text-blue-700">{totalOrderCount}</span>
                  </p>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M7.5 15L12.5 10L7.5 5" stroke="#004EEC" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </button>
          {
            dashboardData && dashboardData.length > 0 ? dashboardData.map((val) => (
              <button className="flex gap-6 items-start p-3 mt-3 w-full text-base bg-white rounded-lg border border-gray-200 border-solid shadow max-md:px-5 hover:shadow-lg transform hover:scale-105 transition-transform duration-300" onClick={()=>handleOrderWithStatus(val.statusId)}>
                <div className="flex flex-col flex-1 shrink justify-center w-full basis-0 min-w-[240px]">
                  <div className="flex gap-4 items-center w-full">
                    <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0 min-w-[240px]">
                      <p className="mb-0 text-base leading-10 text-slate-700 text-start">{val.statusName}</p>
                      <p className=" mb-0 text-3xl leading-none text-blue-700 text-start">{val.orderCount}</p>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M7.5 15L12.5 10L7.5 5" stroke="#004EEC" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                </div>
              </button>
            )) : ""
          }
        </section>
        {/* <section className="flex flex-col mt-6 w-full text-base">
          <h3 className="text-base text-gray-900">Subscription Overview</h3>
          <div className="flex gap-6 items-start p-4 mt-3 w-full bg-white rounded-lg border border-gray-200 border-solid shadow max-md:px-5">
            <div className="flex flex-col flex-1 shrink justify-center w-full basis-0 min-w-[240px]">
              <div className="flex gap-4 items-center w-full">
                <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0 min-w-[240px]">
                  <p className="mb-0 leading-10 text-slate-700">Registered Agent (0 Total)</p>
                  <p className="mb-0 leading-7 text-gray-500">
                    <span className="text-3xl text-blue-700">0</span> to resolve
                  </p>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M7.5 15L12.5 10L7.5 5" stroke="#004EEC" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>              </div>
            </div>
          </div>
        </section> */}
      </div>
    </div>
  );
}

export default AccountSummary;