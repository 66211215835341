import React from "react";
import { Modal } from "react-bootstrap";
import CommonTourBlue from "./CommonTourComponent/CommonTourBlue";
import CommonTourWhite from "./CommonTourComponent/CommonTourWhite";
import "./CommonStyleTour/CommonStyleTour.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import ord1 from "../../Assets/Images/tour/o1.png";
import ord2 from "../../Assets/Images/tour/o2.png";
import ord3 from "../../Assets/Images/tour/ord3.png";
import ord4 from "../../Assets/Images/tour/ord4.png";
import ord5 from "../../Assets/Images/tour/ord5.png";
import ord6 from "../../Assets/Images/tour/ord6.png";
import ord7 from "../../Assets/Images/tour/ord7.png";
import ord8 from "../../Assets/Images/tour/ord8.png";
import ord9 from "../../Assets/Images/tour/ord9.png";
import ord10 from "../../Assets/Images/tour/ord10.png";
import ord11 from "../../Assets/Images/tour/ord11.png";

import { Navigation } from "swiper/modules";
import CompanyTourModal from "./CompanyTourModal";

const OrderTourModal = (props) => {
  const [modalsecondShow, setModalSecondShow] = React.useState(false);

  const handleStartClick = () => {
    setModalSecondShow(true);
    props.onHide();
  };
  return (
    <section>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="common-modal-class"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 1 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading"> Orders: Manage Your Orders</h4>
                  <p className="tour-text-holder">
                    This is your central hub for all{" "}
                    <span className="blkkk-textt">order management </span>{" "}
                    activities.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={ord1} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Create Order: </span>{" "}
                      Initiate a new order for company formation or other
                      services.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Export CSV: </span>{" "}
                      Download a spreadsheet of your order history for analysis
                      or record-keeping.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Delete All Drafts: </span>{" "}
                      Remove all unsaved draft orders from your account.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 2 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders:List of Orders</h4>
                  <p className="tour-text-holder">
                    Access a comprehensive list of all your orders.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={ord2} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Filtering: </span> Narrow
                      down your orders using filters for State, Order Type,
                      Status, Payment Status and keyword search.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Sorting: </span> Arrange
                      your order list by date, order ID, or other relevant
                      criteria for easy navigation.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 3 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading"> Orders: Order Overview</h4>
                  <p className="tour-text-holder">
                  Get important information of your order right on top. 
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={ord3} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      View order related information like order id, order type,
                      entity type, order date and more.
                      {/* <span className="blkkk-textt"> Order Summary: </span> View
                      order ID, type, date and a summary of the included
                      services.
                    </li>
                    <li>
                      <span className="blkkk-textt">View order </span> related
                      information like order id, order type, entity type, order
                      date and more. */}
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 4 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading"> Orders: Order Details</h4>
                  <p className="tour-text-holder">
                  Get updated information regarding your order history.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={ord4} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Stay updated on your order status and access the{" "}
                      <span className="blkkk-textt"> receipt </span>
                      for quick reference.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 5 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading"> Orders: Order Preview</h4>
                  <p className="tour-text-holder">
                    This section allows you to preview the order details.{" "}
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={ord5} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      View a detailed breakdown of{" "}
                      <span className="blkkk-textt">
                        order items and their prices.
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 6 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading"> Orders: Company Details</h4>
                  <p className="tour-text-holder">
                    This section allows you to view the company details.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={ord6} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Access key company details such as entity name, structure,
                      states, formation date and EIN.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 7 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: Contact</h4>
                  <p className="tour-text-holder">
                    This section allows you to view the contact details.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={ord7} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Find{" "}
                      <span className="blkkk-textt">contact information</span>{" "}
                      for billing, shipping and order organizers.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 8 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: Documents</h4>
                  <p className="tour-text-holder">
                    This section allows you to view and download the documents
                    related to your order.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={ord8} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Manage and download all documents associated with your
                      order.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 9 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Orders: Communication History
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to view the communication history
                    related to your order.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={ord9} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      View a complete timeline of communication related to the
                      order.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 10 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: Account Manager</h4>
                  <p className="tour-text-holder">
                    This section allows you to know the account manager details
                    associated with your order.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={ord10} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Easily connect with your assigned account manager for
                      assistance.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 11 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Orders: Invoice and Sales Receipt
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to view and download the invoice and
                    sales receipt of your order.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={ord11} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Download your invoice and sales receipt in a single click.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Modal.Body>
      </Modal>
      <CompanyTourModal
        show={modalsecondShow}
        onHide={() => setModalSecondShow(false)}
      />
    </section>
  );
};

export default OrderTourModal;
