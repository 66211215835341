import React, { useContext, useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import AddressService from '../../services/addressService';
import ContactService from '../../services/ContactService';
import authContext from '../../common/authContext';
import MasterServices from '../../services/coreServices';

const AddServiceForm = () => {
  const { globalCompany,setIsLoading } = useContext(authContext);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    contactNumber: '',
    email: '',
    companyName: '',
    addressLine1: '',
    state: '',
    county: '',
    zip: '',
    entityType: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    contactNumber: '',
    email: '',
    zip: ''
  });

  const user = JSON.parse(localStorage.getItem('user'));
  const [allCounty, setAllCounty] = useState([]);
  const [allState, setAllState] = useState([]);
  const [allEntity, setAllEntity] = useState([]);

  const [dataId, setDataId] = useState(null)

  function formatLabel(label) {
    return label
      .replace(/([A-Z])/g, ' $1')
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase());
  }

  useEffect(() => {
    if (user && globalCompany) {
      setIsLoading(true)
      ContactService.getAllContact()
        .then((res) => {
          const data = res.data;
          setIsLoading(false)
          const tempData = data.find((val) => val.contactDetails.emailId === user.username);
          if (tempData) {
            const contactDetails = tempData.contactDetails || {};
            setDataId(contactDetails.id)
            setFormData((prevData) => ({
              ...prevData,
              firstName: contactDetails.firstName || '',
              lastName: contactDetails.lastName || '',
              email: contactDetails.emailId || '',
              contactNumber: contactDetails.mobileNo || '',
              companyName: globalCompany && globalCompany.companyDetails ? globalCompany.companyDetails.companyName : "" || '',
            }));
          }
          
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false)
        });
    }
  }, []);


  useEffect(() => {
    if (dataId) {
      AddressService.getAllAddressByContactId(dataId)
        .then((res) => {
          if (res.data !== "") {
            const tempArr = res.data;
            const temp = tempArr.filter((val) => val.addressType.type === "CONTACT");
            if (temp.length > 0) {
              const { addressLine1, addressLine2, city, postalCode, stateId } = temp[0];
              setFormData((prevData) => ({
                ...prevData,
                addressLine1,
                city,
                zip: postalCode
              }));
              const temps = allState.find((val) => val.id === stateId);
              if (temps) {
                setFormData((prevData) => ({
                  ...prevData,
                  state: temps
                }));
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dataId]);

  useEffect(() => {
    AddressService.getAllStates()
      .then((res) => {
        if (Array.isArray(res.data)) {
          setAllState(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (formData.state) {
      AddressService.getAllCountyByState(formData.state.id)
        .then((res) => {
          if (Array.isArray(res.data)) {
            setAllCounty(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAllCounty([]);
    }
  }, [formData.state]);

  useEffect(() => {
    MasterServices.getAll("/core/api/entityTypeMaster/getAll")
      .then((res) => {
        if (Array.isArray(res.data)) {
          setAllEntity(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'state') {
      setFormData((prev) => ({
        ...prev,
        county: '',
        state: value,
      }));
    }

    if (name === 'entityType') {
      setFormData((prev) => ({
        ...prev,
        entityType: value,
      }));
    }

    // Clear errors when the user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };


  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    const requiredFields = ['firstName', 'lastName', 'contactNumber', 'email', 'companyName', 'addressLine1', 'state', 'zip', 'entityType', 'message'];
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${formatLabel(field)} is required.`;
        isValid = false;
      }
    });

    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (!phoneRegex.test(formData.contactNumber)) {
      newErrors.contactNumber = 'Invalid phone number format. Use (XXX) XXX-XXXX.';
      isValid = false;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email address.';
      isValid = false;
    }

    const zipRegex = /^\d{5}$/;
    if (!zipRegex.test(formData.zip)) {
      newErrors.zip = 'Invalid ZIP code. Must be 5 digits.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form Submitted', formData);
    } else {
      console.log('Form has errors');
    }
  };



  return (
    <div className="contact-form-container px-6 py-4">
      <div className="contact-form-main p-4 border-round">
        <form onSubmit={handleSubmit}>
          <div className="">
            <h3 className="contact-form-header-text">Contact Information</h3>
            <div className="grid">
              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <InputText
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder=""
                      id="floating_outlined272828"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                    />
                    <label htmlFor="floating_outlined272828"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      First Name <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.firstName && <small className="error-msg font-fam-for-all">{errors.firstName}</small>}
                </span>
              </div>
              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <InputText
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder=""
                      id="floating_outlined272829"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                    />
                    <label htmlFor="floating_outlined272829"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Last Name <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.lastName && <small className="error-msg font-fam-for-all">{errors.lastName}</small>}
                </span>

              </div>
              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <InputText
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder=""
                      id="floating_outlined272820"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                    />
                    <label htmlFor="floating_outlined272820"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Email <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.email && <small className="error-msg font-fam-for-all">{errors.email}</small>}
                </span>
              </div>
              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <InputMask
                      name="contactNumber"
                      value={formData.contactNumber}
                      onChange={handleChange}
                      mask="(999) 999-9999"
                      placeholder=""
                      id="floating_outlined272821"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                    />
                    <label htmlFor="floating_outlined272821"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Phone Number <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.contactNumber && <small className="error-msg font-fam-for-all">{errors.contactNumber}</small>}
                </span>
              </div>
            </div>
          </div>


          <div className="">
            <h3 className="contact-form-header-text">Entity Information</h3>
            <div className="grid">
              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <Dropdown
                      name="entityType"
                      value={formData.entityType}
                      onChange={handleChange}
                      options={allEntity}
                      optionLabel="entityShortName"
                      style={{ height: "38px" }}
                      placeholder="Select Entity Type"
                      className='w-full'
                    />
                    <label htmlFor="floating_outlined272823"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Entity Type <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.entityType && <small className="error-msg font-fam-for-all">{errors.entityType}</small>}
                </span>
              </div>
              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <InputText
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      placeholder=""
                      id="floating_outlined272822"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                    />
                    <label htmlFor="floating_outlined272822"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Company Name <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.companyName && <small className="error-msg font-fam-for-all">{errors.companyName}</small>}
                </span>
              </div>

              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <InputText
                      name="addressLine1"
                      value={formData.addressLine1}
                      onChange={handleChange}
                      placeholder=""
                      id="floating_outlined272824"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                    />
                    <label htmlFor="floating_outlined272824"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Address <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.addressLine1 && <small className="error-msg font-fam-for-all">{errors.addressLine1}</small>}
                </span>
              </div>

              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <InputText
                      name="zip"

                      value={formData.zip}
                      onChange={handleChange}
                      placeholder=""
                      id="floating_outlined272827"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                    />
                    <label htmlFor="floating_outlined272827"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Zip Code <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.zip && <small className="error-msg font-fam-for-all">{errors.zip}</small>}
                </span>
              </div>

              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <Dropdown
                      name="state"
                      value={formData.state}
                      onChange={handleChange}
                      options={allState}
                      style={{ height: "38px" }}
                      filter
                      optionLabel="stateFullDesc"
                      placeholder="Select State"
                      className='w-full'

                    />
                    <label htmlFor="floating_outlined272825"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      State <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {errors.state && <small className="error-msg font-fam-for-all">{errors.state}</small>}
                </span>
              </div>
              <div className="col-6">

                <span className=" mr-3">
                  <div class="relative">
                    <Dropdown
                      name="county"
                      value={formData.county}
                      onChange={handleChange}
                      options={allCounty}
                      style={{ height: "38px" }}
                      filter
                      optionLabel="countyName"
                      placeholder="Select County"
                      className='w-full'

                      disabled={!formData.state}
                    />
                    <label htmlFor="floating_outlined272826"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      County <span className="form-field-mandatory"></span>
                    </label>
                  </div>
                  {errors.county && <small className="error-msg font-fam-for-all">{errors.county}</small>}
                </span>
              </div>

            </div>
          </div>

          <div className="mb-4">

            <span className=" mr-3">
              <div class="relative">
                <h3 className="contact-form-header-text">Message</h3>
                <InputTextarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={7}
                  placeholder="Type your message"
                  className='w-full'
                // id="floating_outlined2728289"
                // className="block w-full px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                />
                {/* <label htmlFor="floating_outlined2728289"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Message <span className="form-field-mandatory">*</span>
                </label> */}
              </div>
              {errors.message && <small className="error-msg font-fam-for-all">{errors.message}</small>}
            </span>
          </div>

          <div className="mt-4">
            <Button label="Submit" className="vstate-button w-full" type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddServiceForm;
