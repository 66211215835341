import React from 'react';
import { useNavigate } from 'react-router-dom';

const LoginButton = () => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col justify-center my-auto text-center text-white whitespace-nowrap leading-[100%]">
      <button className="flex flex-col justify-center px-6 py-3 w-full bg-gray-800 rounded max-md:pl-5" onClick={() => navigate("/signin")}>
        <div className="flex gap-1 justify-between py-px">
          <div className='website-font'>Login/Register</div>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/057f8ee2f8a1c38b45783635c913171986ebc8a4191e30e47a7d870a46a61ffa?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="shrink-0 self-start w-3.5 aspect-square" alt="" />
        </div>
      </button>
    </div>
  );
};

export default LoginButton;