import axios from 'axios';
import { Button } from 'primereact/button'
import React from 'react'


const Invoice = () => {

  const jsonData =
  {
    "nameField": "505050jar llc",
    "checked": true,
    "sop": {
      "name": "John Doe",
      "addr1": "123 Main Street",
      "addr2": "Suite 456",
      "city": "Albany",
      "postal_code": "11557"
    },
    "organizer": {
      "name": "Alex Englard",
      "addr1": "301 Mill Road, Suite U-5",
      "city": "Hewlett",
      "postal_code": "11557",
      "signature": "alex englard"
    },
    "filer": {
      "name": "Alex Englard",
      "addr1": "301 Mill Road, Suite U-5",
      "city": "Hewlett",
      "postal_code": "11557"
    }
  };



  const handleClick = async () => {
    try {
      const response = await axios.post('http://192.168.1.6:3001/run-puppeteer',jsonData);
      alert(response.data);
    } catch (error) {
      console.error("Error triggering Puppeteer script:", error);
      alert('Error triggering Puppeteer script');
    }
  }


  return (
    <div>
      <div class="split left">
        <div class="centered">
          <h6>Subscription</h6>

        </div>
      </div>

      <div class="split right">
        <div class="centered">
          <Button onClick={handleClick}>Send Orders</Button>

        </div>
      </div>
    </div>
  )
}

export default Invoice