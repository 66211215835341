import React from "react";
import { Modal } from "react-bootstrap";
import CommonTourBlue from "./../CommonTourComponent/CommonTourBlue";
import CommonTourWhite from "./../CommonTourComponent/CommonTourWhite";
import "./../CommonStyleTour/CommonStyleTour.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

// import OrderTourModal from "./../OrderTourModal";
import UserTourModal from "./../UserTourModal";
import EmployeeCompanyModal from "./EmployeeCompanyModal";

const EmployeeOrderTourModal = (props) => {
  const [modalsecondShow, setModalSecondShow] = React.useState(false);

  const handleStartClick = () => {
    setModalSecondShow(true);
    props.onHide();
  };
  return (
    <section>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="common-modal-class"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Orders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 1 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: Manage Your Orders </h4>
                  <p className="tour-text-holder">
                    This is your central hub for all order management
                    activities.
                  </p>
                </div>
                <div>
                  <img
                    src="https://strapi.redberyltest.in/uploads/emp_order_s1_74ba435617.png"
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Filtering: </span> Narrow
                      down your orders using filters for State, Order Type,
                      Status, Payment Status, and keyword search.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Search & Filters: </span>{" "}
                      Use intuitive search and filters to sort by state,
                      business structure, or other parameters for quick
                      navigation.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Export CSV: </span>{" "}
                      Download a spreadsheet of your order history for analysis
                      or record-keeping.
                    </li>

                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Search Achieved Orders:{" "}
                      </span>{" "}
                      Select a date range and get all the your achieved orders
                      for those dates.{" "}
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 2 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading"> Orders: List of Orders</h4>
                  <p className="tour-text-holder">
                    Access a comprehensive list of all your orders.
                  </p>
                  <p className="tour-text-holder">
                    [User option will be available when superadmin add the permission].
                  </p>
                </div>
                <div>
                  <img
                    src="https://strapi.redberyltest.in/uploads/emp_order_s2_dd545023ee.png"
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Filtering: </span> Narrow
                      down your orders using filters for State, Order Type,
                      Status, Payment Status, and keyword search.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Sorting: </span> Arrange
                      your order list by date, order ID, or other relevant
                      criteria for easy navigation.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Pay:</span> Make payment
                      for your order.
                    </li>
                    <li>
                      <span className="blkkk-textt">Print:</span> Take a
                      printout for your order.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 3 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: Order Overview</h4>
                  <p className="tour-text-holder">
                    Dive deep into the specifics of any given order.
                  </p>
                </div>
                <div>
                  <img
                    src="https://strapi.redberyltest.in/uploads/emp_order_s3_4a6b6355f1.png"
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      View order related information like order id, order type,
                      entity type, order date and more.
                    </li>
                    <li>
                      Approve or reject orders from clients, opted for pay later
                      option while placing order.
                    </li>
                    <li>
                      Get a print copy of a order including all the important
                      details.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 4 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: Order Details</h4>
                  <p className="tour-text-holder">
                    Dive deep into the specifics of any given order.
                  </p>
                </div>
                <div>
                  <img
                    src="https://strapi.redberyltest.in/uploads/emp_order_s4_5e542e923d.png"
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Stay updated on your order status and access the receipt
                      for quick reference.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 5 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: Order Preview</h4>
                  <p className="tour-text-holder">
                    This section allows you to preview the order details.
                  </p>
                </div>
                <div>
                  <img
                    src="https://strapi.redberyltest.in/uploads/emp_order_s5_dbe2b8cbf2.png"
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      View a detailed breakdown of order items and their prices.{" "}
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 6 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: Company Details</h4>
                  <p className="tour-text-holder">
                    This section allows you to view the company details.
                  </p>
                </div>
                <div>
                  <img
                    src="https://strapi.redberyltest.in/uploads/emp_order_s6_753e0622cb.png"
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Access key company details such as entity name, structure,
                      states, formation date, and EIN.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 7 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: Contact</h4>
                  <p className="tour-text-holder">
                    This section allows you to view the contact details.
                  </p>
                </div>
                <div>
                  <img
                    src="https://strapi.redberyltest.in/uploads/emp_order_s7_edea85028c.png"
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Find contact information for billing, shipping, and order
                      organizers.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 8 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: Documents</h4>
                  <p className="tour-text-holder">
                    This section allows you to view and download the documents
                    related to your order.
                  </p>
                </div>
                <div>
                  <img
                    src="https://strapi.redberyltest.in/uploads/emp_order_s8_54948346a2.png"
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Manage and download all documents associated with your
                      order.{" "}
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 9 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Orders: Communication History
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to view the communication history
                    related to your order.
                  </p>
                </div>
                <div>
                  <img
                    src="https://strapi.redberyltest.in/uploads/emp_order_s9_3b489e53bf.png"
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      View a complete timeline of communication related to the
                      order.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 10 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">Orders: Account Manager</h4>
                  <p className="tour-text-holder">
                    This section allows you to know the account manager details
                    associated with your order.
                  </p>
                </div>
                <div>
                  <img
                    src="https://strapi.redberyltest.in/uploads/emp_order_s10_8ee02114ee.png"
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Easily connect with your assigned account manager for
                      assistance.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 11 Of 11</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Orders: Invoice and Sales Receipt{" "}
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to view and download the invoice and
                    sales receipt of your order.{" "}
                  </p>
                </div>
                <div>
                  <img
                    src="https://strapi.redberyltest.in/uploads/emp_order_s11_bfbb3fd9e1.png"
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Download your invoice and sales receipt in a single click.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Modal.Body>
      </Modal>
      <EmployeeCompanyModal
        show={modalsecondShow}
        onHide={() => setModalSecondShow(false)}
      />
    </section>
  );
};

export default EmployeeOrderTourModal;
