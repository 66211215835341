import React, { useEffect } from "react";
import { useState, useRef, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { Message } from "primereact/message";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";

import { Password } from "primereact/password";
import { useNavigate } from "react-router";

import Switch from "@mui/material/Switch";
import SignIn from "../../services/SignInServices";
import errorMessages from "../../utils/errorMessages";
import OTPInputBox from "../../utils/OtpInputBox";
import ForgotPasswordService from "../../services/forgotPasswordService";
import { Card } from "primereact/card";
import { createSearchParams } from "react-router-dom";
import authContext from "../../common/authContext";
import { showToast } from "../../utils/toastUtils";
import { Link } from "react-router-dom";

export const AddFogotPassword = ({ visible1, setVisible1 }) => {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    signUpPayload,
    setSignUpPayload,
    setIsTabRefresh,
  } = useContext(authContext);

  const [value, setValue] = useState("");
  const [ingredient, setIngredient] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [cityErr, setcityErr] = useState("");

  const [upload, setUpload] = useState("");
  const [selectedSkills, setSelectedSkills] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);

  const [otp, setOtp] = useState("");

  const [newpassword, setNewPassword] = useState("");

  const [confirmpassword, setConformPassword] = useState("");

  const [newpwdErr, setNewpwdErr] = useState("");

  const [confirmPwdErr, setConfirmPwdErr] = useState("");

  const [loading2, setLoading2] = useState(false);

  const [minutes, setMinutes] = useState(1);

  const [seconds, setSeconds] = useState(59);

  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({});

  const [passwordType, setPasswordType] = useState("password");

  const [passwordType1, setPasswordType1] = useState("password");

  const [resend, SetResend] = useState(false);

  const [sendOtpButton, setSendOtpButton] = useState(false);

  const [submitbutton, setsubmitbutton] = useState();

  const toast = useRef(null);

  const [emailErr, setemailErr] = useState("");

  const [showTimer, setShowTimer] = useState(false);

  const [showPasswordForm, setShowPasswordForm] = useState(false);

  const [showPassword, setShowPassword] = useState(true);

  const [otpErr, setOtpErr] = useState("");

  const [showOtpTimer, setShowOtpTimer] = useState(false);

  const [isShowOtp, setIsShowOtp] = useState(false);

  const toastBR = useRef(null);

  const [clearOtp, setClearOtp] = useState(false);

  const [isResendData, setIsResendData] = useState(false);

  const [otpAttempts, setOtpAttempts] = useState(() => {
    const savedAttempts = localStorage.getItem("otpAttempts");
    return savedAttempts ? parseInt(savedAttempts, 10) : 0;
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [trimmedEmail, setTrimmedEmail] = useState(null);


  useEffect(() => {
    if (otpAttempts >= 3) {
      setIsButtonDisabled(true);
      const timeout = setTimeout(() => {
        setOtpAttempts(0);
        localStorage.setItem("otpAttempts", 0);
        setIsButtonDisabled(false);
      }, 15 * 60 * 1000);
      return () => clearTimeout(timeout);
    }
  }, [otpAttempts]);

  const handleOtpChange = (value) => {
    setOtp(value);
    if (value !== "" && value.length === 6) {
      setIsTabRefresh(true);
      setIsLoading(true);
      ForgotPasswordService.sendOtp(value, email)
        .then((res) => {
          

          setShowPassword(false);
          setIsTabRefresh(false);
          setIsLoading(false);
          if (res.status === 201) {
            // toastBR.current.show({ severity: 'warn', summary: 'Oops', detail: 'Both passwords must be same', life: 2800 });
            setShowOtpTimer(false);
            setShowPasswordForm(true);
            navigate({
              pathname: "/changepwd",
              search: createSearchParams({
                email: email,
              }).toString(),
            });
          }
        })
        .catch((err) => {
          
          setIsTabRefresh(false);
          setIsLoading(false);
          if (err.response !== undefined) {
            if (err.response.status !== undefined) {
              if (err.response.status === 400) {
                // toastBR.current.show({
                //   severity: "warn",
                //   summary: "Oops",
                //   detail: "You have entered wrong OTP",
                //   life: 2800,
                // });
                showToast("error", "You have entered wrong OTP");
              }
            }
          }
        });
    }
    
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          /* resend button enable*/
          SetResend(true);

          clearInterval(interval);
        } else {
          SetResend(false);

          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [resend, seconds]);

  // const ResendOtpButton = () => {
  //     setMinutes(1);
  //     setSeconds(30);
  //     SetResend(false)
  //     setShowTimer(true)
  //     setClearOtp(!clearOtp)
  //     setOtpErr("")
  //     let username = JSON.parse(localStorage.getItem("usermail"))
  //     if (username !== null) {
  //         setIsTabRefresh(true)
  //         setIsLoading(true)
  //         ForgotPasswordService.sendEmail(username)
  //             .then((res) => {
  //                 console.log(res)
  //                 setIsTabRefresh(false)
  //                 setIsLoading(false)
  //                 if (res.status === 201) {

  //                     setShowOtpTimer(true)
  //                 }

  //             })
  //             .catch((err) => {
  //                 console.log(err)
  //                 setIsTabRefresh(false)
  //                 setIsLoading(false)
  //                 if (err.response !== undefined) {
  //                     if (err.response.status === 700) {
  //                         if (err.response.data !== undefined) {

  //                             if (toast.current !== undefined) {
  //                                 toast.current.show({ severity: 'warn', summary: 'Warning', detail: `${errorMessages.emailValidation}`, life: 3000 });

  //                             }
  //                         }
  //                     }
  //                 }
  //             })
  //     }

  // }

  //   const ResendOtpButton = () => {
  //     let username = JSON.parse(localStorage.getItem("usermail"));
  //     setOtpErr("");
  //     setClearOtp(!clearOtp);
  //     if (username && otpAttempts < 3) {
  //       setOtpAttempts((prev) => {
  //         const newAttempts = prev + 1;
  //         localStorage.setItem("otpAttempts", newAttempts);
  //         return newAttempts;
  //       });

  //       setIsButtonDisabled(true);
  //       setTimeout(() => setIsButtonDisabled(false), 3000); // Disable button for 3 seconds
  //       setIsLoading(true);
  //       ForgotPasswordService.validateEmail(username)
  //         .then((res) => {
  //           
  //           //   toast?.current?.show({
  //           //     severity: "success",
  //           //     summary: "OTP Resent",
  //           //     detail: "A new OTP has been sent to your registered email.",
  //           //     life: 3000,
  //           //   });

  //           showToast(
  //             "success",
  //             "A new OTP has been sent to your registered email."
  //           );

  //           if (res.status === 201) {
  //             // Reset timer and show OTP timer

  //             setMinutes(1);
  //             setSeconds(59);
  //             setShowTimer(true);
  //             SetResend(false);
  //             setShowOtpTimer(true);
  //             setIsLoading(false);
  //             setIsResendData(true);
  //           }
  //         })
  //         .catch((err) => {
  //           
  //           setIsLoading(false);
  //           if (err.response?.status === 700 && err.response.data) {
  //             // toast.current?.show({
  //             //   severity: "warn",
  //             //   summary: "Warning",
  //             //   detail: "Invalid email address",
  //             //   life: 3000,
  //             // });
  //             showToast("error", "Invalid email address");
  //           }
  //         });
  //     } else if (otpAttempts >= 3) {
  //       //   toast.current?.show({
  //       //     severity: "warn",
  //       //     summary: "Warning",
  //       //     detail:
  //       //       "You have reached the maximum OTP resend attempts. Please try again after 15 minutes.",
  //       //     life: 3000,
  //       //   });
  //       showToast(
  //         "error",
  //         "You have reached the maximum OTP resend attempts. Please try again after 15 minutes."
  //       );

  //       //   toastBR.current?.show({
  //       //     severity: "warn",
  //       //     summary: "Warning",
  //       //     detail:
  //       //       "You have reached the maximum OTP resend attempts. Please try again after 15 minutes.",
  //       //     life: 3000,
  //       //   });
  //       showToast(
  //         "error",
  //         "You have reached the maximum OTP resend attempts. Please try again after 15 minutes."
  //       );
  //     }
  //   };

  const ResendOtpButton = () => {
    let username = JSON.parse(localStorage.getItem("usermail"));
    setOtpErr("");
    setOtp(""); 
    setClearOtp(!clearOtp);

    if (username && otpAttempts < 3) {
      setOtpAttempts((prev) => {
        const newAttempts = prev + 1;
        localStorage.setItem("otpAttempts", newAttempts);
        return newAttempts;
      });

      setIsButtonDisabled(true);
      setTimeout(() => setIsButtonDisabled(false), 3000);

      setIsLoading(true);
      console.log(trimmedEmail, "trimmedEmail");
      ForgotPasswordService.sendEmail(trimmedEmail, )
        .then((res) => {
          
          showToast(
            "success",
            "A new OTP has been sent to your registered email."
          );

          if (res.status === 201) {
            setMinutes(1);
            setSeconds(59);
            setShowTimer(true);
            SetResend(false);
            setShowOtpTimer(true);
            setIsLoading(false);
            setIsResendData(true);
          }
        })
        .catch((err) => {
          
          setIsLoading(false);
          if (err.response?.status === 700 && err.response.data) {
            showToast("error", "Invalid email address");
          }
        });
    } else if (otpAttempts >= 3) {
      showToast(
        "error",
        "You have reached the maximum OTP resend attempts. Please try again after 15 minutes."
      );
    }
  };

  const dialogView = () => setVisible1(false);
  const cancelView = () => {
    setcityErr("");
    setVisible1(false);
  };

  let newObject = window.localStorage.getItem("user");
  let updatedUser = Object.assign({}, JSON.parse(newObject));

  const handleCity = (e) => {
    setemailErr("");
    let City = e.target.value;
    if (City === "") {
      setemailErr(errorMessages.emailRequired);
    }
    if (City && City.trim() !== "") {
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(City)) {
        setemailErr(errorMessages.emailValidation);
      }
    }
    setIngredient(e.target.value);
    setEmail(e.target.value);
  };

  const handleChangeOtp = (e) => {
    setOtpErr("");
    const otp = e.target.value;
    if (otp && otp.trim() !== "") {
      if (!/^\d+$/.test(otp)) {
        setOtpErr("Please Enter Valid 6 Digits OTP");
      }
    }
    if (otp.length < 6) {
      setOtpErr("Please Enter Valid 6 Digits OTP");
    }
    setOtp(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   setIsTabRefresh(true);
  //   setIsLoading(true);

  //   SetResend(false);
  //   setShowTimer(true);
  //   if (email !== "") {
  //     localStorage.setItem("usermail", JSON.stringify(email));
  //     ForgotPasswordService.sendEmail(email)
  //       .then((res) => {
  //         
  //         setIsTabRefresh(false);
  //         setIsLoading(false);
  //         if (res.status === 201) {
  //           if (res.data === "We have send OTP to your email") {
  //             //   if (toast.current !== null) {
  //             //     toast.current.show({
  //             //       severity: "success",
  //             //       summary: "Info",
  //             //       detail: "OTP Sent  To Your Register Mail Address",
  //             //       life: 2800,
  //             //     });
  //             //   }
  //             showToast(
  //               "success",
  //               "We have sent an OTP to your registered email address."
  //             );
  //           }
  //           localStorage.removeItem("otpAttempts");
  //           setOtpAttempts(0);
  //           setIsShowOtp(true);
  //           setShowOtpTimer(true);
  //           setIsShowOtp(true);
  //           setMinutes(1);
  //           setSeconds(59);
  //         }
  //       })
  //       .catch((err) => {
  //         
  //         setIsTabRefresh(false);
  //         setIsLoading(false);
  //         if (err.response.status !== undefined) {
  //           if (err.response.status === 404 || err.response.status === 500) {
  //             //   if (toast.current !== null) {
  //             //     toast.current.show({
  //             //       severity: "warn",
  //             //       summary: "Oops",
  //             //       detail: "Email is not registered",
  //             //       life: 2800,
  //             //     });
  //             //   }
  //             showToast("error", "Email is not registered");
  //           }
  //         }

  //         if (err.response !== undefined) {
  //           if (err.response.status === 700) {
  //             if (err.response.data !== undefined) {
  //               // if (toast.current !== undefined) {
  //               //   toast.current.show({
  //               //     severity: "warn",
  //               //     summary: "Warning",
  //               //     detail: `${errorMessages.emailValidation}`,
  //               //     life: 3000,
  //               //   });
  //               // }

  //               showToast("error", `${errorMessages.emailValidation}`);
  //             }
  //           }
  //         }
  //       });
  //   } else {
  //     if (email === "") {
  //       setemailErr(errorMessages.emailRequired);
  //     }
  //     if (email && email.trim() !== "") {
  //       // if (!validator.isEmail(email)) {
  //       //     setemailErr('Please Enter Valid Email Address. E.g. example@email.com');
  //       // }
  //     }
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsTabRefresh(true);
    setIsLoading(true);
    SetResend(false);
    setShowTimer(true);
  
    // Trim and validate email before proceeding
    setTrimmedEmail(email.trim());
    if (!trimmedEmail) {
      setemailErr(errorMessages.emailRequired);
      setIsTabRefresh(false);
      setIsLoading(false);
      return;
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(trimmedEmail)) {
      setemailErr(errorMessages.emailValidation);
      setIsTabRefresh(false);
      setIsLoading(false);
      return;
    }
  
    localStorage.setItem("usermail", JSON.stringify(trimmedEmail));
    ForgotPasswordService.sendEmail(trimmedEmail)
      .then((res) => {
        
        setIsTabRefresh(false);
        setIsLoading(false);
        if (res.status === 201) {
          showToast("success", "We have sent an OTP to your registered email address.");
          localStorage.removeItem("otpAttempts");
          setOtpAttempts(0);
          setIsShowOtp(true);
          setShowOtpTimer(true);
          setMinutes(1);
          setSeconds(59);
        }
      })
      .catch((err) => {
        
        setIsTabRefresh(false);
        setIsLoading(false);
        if (err.response?.status === 404 || err.response?.status === 500) {
          showToast("error", "Email is not registered");
        } else if (err.response?.status === 700) {
          showToast("error", errorMessages.emailValidation);
        }
      });
  };
  

  const handleOTP = (e) => {
    e.preventDefault();

    if (otp !== "") {
      setIsTabRefresh(true);
      setIsLoading(true);
      ForgotPasswordService.sendOtp(otp, email)
        .then((res) => {
          

          setShowPassword(false);
          setIsTabRefresh(false);
          setIsLoading(false);
          if (res.status === 201) {
            // toastBR.current.show({ severity: 'warn', summary: 'Oops', detail: 'Both passwords must be same', life: 2800 });
            // showToast("success", "Success! Your OTP is verified.");

            setShowOtpTimer(false);
            setShowPasswordForm(true);
            navigate({
              pathname: "/changepwd",
              search: createSearchParams({
                email: email,
              }).toString(),
            });
          }
        })
        .catch((err) => {
          
          setIsTabRefresh(false);
          setIsLoading(false);
          if (err.response !== undefined) {
            if (err.response.status !== undefined) {
              if (err.response.status === 400) {
                // toastBR.current.show({
                //   severity: "warn",
                //   summary: "Oops",
                //   detail: "You have entered wrong OTP",
                //   life: 2800,
                // });
                showToast("error", "You have entered wrong OTP");
              }
            }
          }
        });
    } else {
      setOtpErr("OTP Required");
      !resend && showToast("error", "Please enter OTP");

    }
  };

  // const getFormErrorMessage = (name) => {
  //     return errors[name] && <small className="p-error font-fam-for-all">{errors[name].message}</small>
  // };

  const backbutton = () => {
    navigate("/login");
  };

  const handleClick = () => {
    setcityErr("");
    setVisible1(false);
  };

  // handle header form
  const handleHeader = () => {
    return (
      <>
        <div className="font-fam-for-all form-sm-heading form-sm-close-Btn">
          Update City
          <button className="form-close-Btn" onClick={handleClick}>
            <i className="pi pi-times"></i>
          </button>
        </div>
      </>
    );
  };

  return (
    <div>
      <Toast ref={toastBR} />
      <Toast ref={toast} />

      {isShowOtp ? (
        <Card className="  flex justify-content-center login-card box-shadow-lg">
          <div className="signup-card-container">
            <div className="signup-card-header">
              <div className="signup-card-header-button">
                <div className="signup-card-header-button-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M25.6666 7.00002C25.6666 5.71669 24.6166 4.66669 23.3333 4.66669H4.66665C3.38331 4.66669 2.33331 5.71669 2.33331 7.00002M25.6666 7.00002V21C25.6666 22.2834 24.6166 23.3334 23.3333 23.3334H4.66665C3.38331 23.3334 2.33331 22.2834 2.33331 21V7.00002M25.6666 7.00002L14 15.1667L2.33331 7.00002"
                      stroke="#145EF0"
                      stroke-width="2.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="signup-card-header-text1 text-center">
                Check your email
              </div>
              {isResendData ? (
                <div className="signup-card-header-text2 text-blue-500">
                  {`A new OTP has been sent to ${email}`}
                </div>
              ) : (
                <div className="signup-card-header-text2">
                  {`Please enter OTP code sent to ${email}`}
                  {/* <div className="text-end">
                    <button className="font-fam-for-all text-blue-500 hover:text-blue-700 ms-1" onClick={()=>setIsShowOtp(false)}> Change Email?</button>
                  </div> */}
                </div>
              )}
            </div>

            <div className="signup-card-header">
              <form onSubmit={handleOTP}>
                <OTPInputBox
                  length={6}
                  onChange={handleOtpChange}
                  clearOtp={clearOtp}
                />
                {/* <p className='error-msg font-fam-for-all'>{otpErr}</p> */}

                <div className="grid">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-8 mt-1">
                    <p className="countdown-text">
                      {showTimer && showOtpTimer ? (
                        seconds > 0 || minutes > 0 ? (
                          <p className="ml-2 font-fam-for-all ">
                            Time Remaining:{" "}
                            {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                          </p>
                        ) : (
                          <p className="ml-2 font-fam-for-all">
                            Didn't receive OTP?
                          </p>
                        )
                      ) : (
                        ""
                      )}{" "}
                    </p>
                  </div>

                  {resend && showOtpTimer ? (
                    <div className="col-12 flex justify-content-end">
                      <Button
                        label="Resend OTP"
                        className="signup-btn vstate-button font-fam-for-all"
                        onClick={ResendOtpButton}
                        disabled={resend ? false : true}
                        style={{ boxShadow: "none" }}
                      />
                    </div>
                  ) : (
                    <div className="col-12 flex justify-content-end">
                      <Button
                        label="Verify OTP"
                        type="submit"
                        className="signup-btn vstate-button font-fam-for-all"
                        style={{ boxShadow: "none" }}
                      />
                    </div>
                  )}
                </div>

                <div className="flex justify-content-center dialog-form-md-group-Btn mt-3">
                  <span
                    className="font-fam-for-all"
                    style={{ fontSize: "14px" }}
                  >
                    Did not receive OTP?
                    <a
                      href="mailto:orders@vstatefilings.com"
                      className="inline-flex"
                    >
                      <button
                        type="button"
                        onClick={()=>setIsShowOtp(false)}
                        className="font-fam-for-all text-blue-500 hover:text-blue-700 ms-1"
                      >
                        Change Email
                      </button>
                    </a>
                  </span>
                </div>


                <div className="flex justify-content-center dialog-form-md-group-Btn mt-3">
                  <span
                    className="font-fam-for-all"
                    style={{ fontSize: "14px" }}
                  >
                    Need Support?
                    <a
                      href="mailto:orders@vstatefilings.com"
                      className="inline-flex"
                    >
                      <button
                        type="button"
                        className="font-fam-for-all text-blue-500 hover:text-blue-700 ms-1"
                      >
                        Contact us
                      </button>
                    </a>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </Card>
      ) : (
        <Card className="  flex justify-content-center login-card box-shadow-lg">
          <div className="signup-card-container">
            <div className="signup-card-header">
              <div className="signup-card-header-button">
                <div className="signup-card-header-button-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M18.0833 8.75001L22.1667 4.66668M24.5 2.33334L22.1667 4.66668L24.5 2.33334ZM13.2883 13.545C13.8907 14.1394 14.3696 14.8471 14.6974 15.6273C15.0252 16.4075 15.1954 17.2448 15.1982 18.0911C15.201 18.9373 15.0365 19.7758 14.7139 20.5582C14.3914 21.3406 13.9173 22.0514 13.3189 22.6498C12.7205 23.2482 12.0096 23.7224 11.2272 24.0449C10.4448 24.3674 9.60635 24.532 8.76009 24.5292C7.91382 24.5264 7.07648 24.3562 6.29627 24.0284C5.51606 23.7006 4.80839 23.2217 4.21401 22.6193C3.04517 21.4091 2.3984 19.7883 2.41302 18.1059C2.42764 16.4234 3.10248 14.814 4.29218 13.6243C5.48188 12.4346 7.09126 11.7598 8.77369 11.7452C10.4561 11.7306 12.077 12.3773 13.2872 13.5462L13.2883 13.545ZM13.2883 13.545L18.0833 8.75001L13.2883 13.545ZM18.0833 8.75001L21.5833 12.25L25.6667 8.16668L22.1667 4.66668L18.0833 8.75001Z"
                      stroke="#145EF0"
                      stroke-width="2.33333"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="signup-card-header-text1">Forgot password?</div>
              <div className="signup-card-header-text2">
                We’ll send you OTP to reset password.
              </div>
            </div>

            <div className="signup-card-header">
              <form id="myform" onSubmit={handleSubmit}>
                <Toast ref={toast} />
                <div className=" justify-content-center dialog-form-field-space grid  mt-2">
                  <div className="field col-12 p-0">
                    <span className=" ">
                      <div class="relative">
                        <InputText
                          // className='dialog-form-input-field'
                          id="floating_outlined3"
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          style={{ width: "100%", height: "40px" }}
                          placeholder=""
                          maxLength={50}
                          value={email}
                          onChange={handleCity}
                          name="cityName"
                        />

                        <label
                          htmlFor="floating_outlined3"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Enter your registered email
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {emailErr !== "" ? (
                        <p className="error-msg font-fam-for-all">{emailErr}</p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
                <div className="grid justify-content-center mt-3">
                  <div className=" col-12 p-0">
                    <div className="flex justify-content-center">
                      <Button
                        type="submit"
                        className="signup-btn vstate-button font-fam-for-all"
                        style={{ width: "400px" }}
                      >
                        Get OTP
                      </Button>
                      {/* <Button type='button' className="mt-2 form-Btn form-Btn-Label font-fam-for-all text-center text-lg bg-primary-reverse mr-2" onClick={cancelView} >Cancel</Button> */}
                    </div>
                  </div>
                </div>

                <div className="flex justify-content-center dialog-form-md-group-Btn mt-3">
                  <span
                    className="font-fam-for-all"
                    style={{ fontSize: "14px" }}
                  >
                    {" "}
                    <a
                      href="/#/signin"
                      className="font-fam-for-all text-blue-500 hover:text-blue-700"
                    >
                      <i
                        className="pi pi-arrow-left mr-2"
                        style={{ fontSize: "14px" }}
                      ></i>{" "}
                      Back to login
                    </a>
                  </span>
                </div>
                
                <div className="flex justify-content-center dialog-form-md-group-Btn mt-3">
                  <span
                    className="font-fam-for-all"
                    style={{ fontSize: "14px" }}
                  >
                    Need Support? 
                    <a
                      href="mailto:orders@vstatefilings.com"
                      className="inline-flex"
                    >
                      <button
                        type="button"
                        className="font-fam-for-all text-blue-500 hover:text-blue-700 ms-1"
                      >
                        Contact us
                      </button>
                    </a>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};
