import React, { useContext, useEffect, useState } from 'react';
import { Avatar } from 'primereact/avatar';
import authContext from '../common/authContext';
import { commonGetInitials } from './getInitials';

const OrderChat = ({ data }) => {
    const { globalCompany } = useContext(authContext);
    const [chatData, setChatData] = useState([]);
    const [superAdminId, setSuperAdminId] = useState(null);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const isToday = date.toDateString() === now.toDateString();
        const isYesterday = date.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString();

        if (isToday) return 'Today';
        if (isYesterday) return 'Yesterday';

        const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay())); // Start of this week
        if (date >= startOfWeek) return date.toLocaleDateString('en-US', { weekday: 'long' });

        return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
    };

    

    function checkIdsExist(arr, idsToCheck) {
        const foundIds = idsToCheck.filter(idToCheck => arr.some(item => item.id === idToCheck));
        return foundIds.length > 0 ? foundIds : null;
    }

    useEffect(() => {
        if (globalCompany !== null && globalCompany.roles.length > 0) {
            const ids = checkIdsExist(globalCompany.roles, [1, 4]);
            setSuperAdminId(ids ? ids : null);
        }
    }, [globalCompany]);

    useEffect(() => {
        if (Array.isArray(data) && data.length > 0) {
            console.log("chatdata", data)
            const value = data.filter((history) => {
                if ((history.orderStatus?.statusName === "VSTATE QUERY RAISED") || (history.orderStatus?.statusName === "GOVT QUERY RAISED") ) {
                    return history?.orderUpdateHistory?.orderUpdatedBy?.toLowerCase().includes("vstate");
                }
                return true;
            })
            const temp = value.filter((val) =>
                (val.orderStatus.statusName === "VSTATE QUERY RAISED") ||
                (val.orderStatus.statusName === "RESPONDED TO QUERY") ||
                (val.orderStatus.statusName === "GOVT QUERY RAISED") ||
                (val.orderStatus.statusName === "CLIENT RESPONDED")

            );
            setChatData(temp);
        }
    }, [data]);

    const chatContainerStyle = { maxWidth: '1200px', margin: '0 auto', padding: '20px', height: '600px', overflowY: 'scroll', fontFamily: "Inter" };
    const chatMessageStyle = { display: 'flex', padding: '10px', margin: '10px 0', position: 'relative', alignItems: 'flex-start' };
    const messageBubbleStyle = { padding: '10px', borderRadius: '8px', backgroundColor: '#fff', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', maxWidth: '100%', wordWrap: 'break-word', position: 'relative' };
    const userMessageBubbleStyle = { backgroundColor: '#0047FF', alignSelf: 'flex-end', color: '#FFF', padding: "10px", minWidth: "250px", maxWidth: "500px" };
    const otherMessageBubbleStyle = { backgroundColor: '#F2F2F2', minWidth: "250px" };
    const avatarStyle = { marginRight: '10px', alignSelf: 'center', marginLeft: "10px" };
    const dividerStyle = { textAlign: 'center', margin: '20px 0', fontWeight: 'bold', color: '#555' };
    const messageBodyStyle = { fontSize: '1em', fontFamily: "Inter" };
    const messageStatusStyle = { marginTop: '5px', fontSize: '0.75em', textAlign: 'right' };

    let previousDay = null;

    return (
        <div style={chatContainerStyle} className='order-chat'>
            {chatData.map((historyItem, index) => {
                const { orderUpdateNotes, orderUpdatedBy, orderUpdatedDate } = historyItem.orderUpdateHistory;
                const { statusName } = historyItem.orderStatus;

                const displayName = orderUpdatedBy.includes("vState-")
                    ? orderUpdatedBy.replace("vState-", "").trim()
                    : orderUpdatedBy;

                const isVStateMessage = orderUpdatedBy.includes("vState");
                const isClientMessage = !isVStateMessage;
                const currentDay = formatDate(orderUpdatedDate);
                const showDivider = previousDay !== currentDay;
                previousDay = currentDay;

                // Determine message position based on superAdminId
                const isMessageReversed = (isClientMessage && !superAdminId) || (!isClientMessage && superAdminId);

                return (
                    <React.Fragment key={index}>
                        {showDivider && (
                            <div style={dividerStyle}>
                                <span>{currentDay}</span>
                            </div>
                        )}
                        <div
                            style={{
                                ...chatMessageStyle,
                                flexDirection: isMessageReversed ? 'row-reverse' : 'row',
                            }}
                        >
                            <div style={avatarStyle}>
                                <Avatar
                                    label={commonGetInitials(orderUpdatedBy.includes('-') ? orderUpdatedBy.split('-')[1] : orderUpdatedBy)}
                                    // icon="pi pi-user"
                                    size="large"
                                    shape="circle"
                                    className="custom-avatar "
                                />
                            </div>
                            <div>
                                <div className='flex justify-between'>
                                    <div style={{ fontSize: "14px" }}>
                                    <strong className="capitalize">{displayName}</strong>
                                    </div>
                                    <div style={{ fontSize: "12px" }}>
                                        {new Date(orderUpdatedDate).toLocaleTimeString()}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        ...messageBubbleStyle,
                                        backgroundColor: isMessageReversed ? '#0047FF' : '#F2F2F2',
                                        ...(isMessageReversed ? userMessageBubbleStyle : otherMessageBubbleStyle),
                                    }}
                                >
                                    <div style={messageBodyStyle}>
                                        <p>{orderUpdateNotes}</p>
                                    </div>
                                    <div style={messageStatusStyle}>
                                        <small>{statusName}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default OrderChat;
