import React from "react";
import PaymentDetails from "./PaymentDetails";
import ActionButtons from "./ActionButtons";
import FailedActionButtons from "./FailedActionButtons";

function PaymentFailure({setCloseProgresbar1}) {
    return (
        <main className="flex flex-col items-center px-4 pt-4 pb-12 rounded-lg shadow-lg font-fam-for-all max-w-[550px] max-md:px-5" style={{ backgroundColor: "#F6F8FE" }}>
            <div className="flex flex-col w-full max-w-[604px] max-md:max-w-full">
                <header className="flex flex-col w-full text-3xl font-semibold leading-none text-center text-gray-900 max-md:max-w-full">
                    <div className="flex justify-content-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                            <path d="M17.5002 10.5002L10.5002 17.5002M10.5002 10.5002L17.5002 17.5002M25.6668 14.0002C25.6668 20.4435 20.4435 25.6668 14.0002 25.6668C7.55684 25.6668 2.3335 20.4435 2.3335 14.0002C2.3335 7.55684 7.55684 2.3335 14.0002 2.3335C20.4435 2.3335 25.6668 7.55684 25.6668 14.0002Z" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>

                    <h3 className="mt-4 mb-0 w-full max-md:max-w-full">Payment failed</h3>
                </header>
                <section className="flex flex-col items-start mt-4 w-full text-base text-center text-gray-500 max-md:max-w-full">
                    {/* <div className="flex flex-wrap justify-center items-start self-stretch mt-1 w-full max-md:max-w-full">
                        <div>
                            <p className="leading-6">
                                Transaction ID: <span className="font-bold">123456789</span>
                            </p>
                        </div>

                    </div> */}
                    <div className="flex flex-wrap justify-center items-start self-stretch mt-1 w-full max-md:max-w-full">
                        <div>
                            <p className="max-md:max-w-full mb-2">Amount</p>
                            <p className="mt-1 mb-2 text-lg font-semibold leading-none text-gray-900 max-md:max-w-full">
                                $689.00
                            </p>
                        </div>

                    </div>
                    {/* <div className="flex flex-wrap justify-center items-start self-stretch gap-4  w-full max-md:max-w-full">
                        <p>12 May 2024</p>
                        <p className="leading-6">
                            Payment Method
                        </p>
                    </div> */}
                </section>
                <PaymentDetails />
                <FailedActionButtons setCloseProgresbar1={setCloseProgresbar1}/>
            </div>
        </main>
    );
}

export default PaymentFailure;