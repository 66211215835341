const sqlInjectionPatterns = [
    /(\b(SELECT|INSERT|DELETE|UPDATE|DROP|ALTER|TRUNCATE|EXEC|UNION|OR|AND|--|#)\b)/gi,
    /(\*\/|\*|\;|--|#|\/\*)/g, // Common SQL meta-characters
    /('|"|`)/g, // Prevent single/double/backticks to avoid injections
  ];
  

  export const sanitizeInput = (input) => {
    if (typeof input !== "string") return input; // Only sanitize strings
  
    let sanitizedInput = input;
  
    sqlInjectionPatterns.forEach((pattern) => {
      sanitizedInput = sanitizedInput.replace(pattern, "");
    });
  
    return sanitizedInput.trim();
  };
  
 
  export const sanitizePayload = (payload) => {
    if (typeof payload !== "object" || payload === null) return payload;
  
    const sanitizedPayload = {};
  
    Object.keys(payload).forEach((key) => {
      if (typeof payload[key] === "string") {
        sanitizedPayload[key] = sanitizeInput(payload[key]);
      } else if (typeof payload[key] === "object") {
        sanitizedPayload[key] = sanitizePayload(payload[key]); // Recursive sanitization
      } else {
        sanitizedPayload[key] = payload[key];
      }
    });
  
    return sanitizedPayload;
  };
  
