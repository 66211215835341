import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import AOS from "aos";
import "aos/dist/aos.css";

const serviceData = [
  {
    title: "Secure delivery of critical legal notices",
    description:
      "vState Filings receives critical legal notices on your behalf at our secure address, keeping your personal and workplace addresses private. This ensures that sensitive documents are handled discreetly.",
  },
  {
    title: "Alerts for important and time-sensitive mail",
    description:
      "vState Filings promptly notifies you when we receive critical or urgent mail, ensuring you stay informed of important deadlines and actions needed.",
  },
  {
    title: "Availability",
    description:
      "We are available during standard business hours to receive and handle official mail, ensuring compliance with legal requirements. This guarantees that all important documents are processed efficiently.",
  },
  {
    title: "Out-of-state operations",
    description:
      "vState Filings helps businesses with operations or remote employees in multiple states that often need to ensure compliance and timely receipt of important documents.",
  },
  {
    title: "Facilitates change of address",
    description:
      "If a business relocates, the registered agent's address can remain unchanged, forwarding official documents to the new location and simplifying state updates.",
  },
  {
    title: "Digital access",
    description:
      "Our registered agent service enables you to respond quickly by giving you digital access to physical mail via a real-time dashboard.",
  },
];

const RegisteredAgentService = () => {
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const visibleServices = showAll ? serviceData : serviceData.slice(0, 3);

  return (
    <section className="main-section-class">
      <div className="entity-formation mb-5">
        <Container>
          <CommonHeadingTitle
            heading={"What are the benefits of our "}
            blueheading={"registered agent services?"}
          />
          <Row
            className="justify-content-center"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            {visibleServices.map((service, index) => (
              <Col key={index} lg={4} md={6} sm={12} xs={12}>
                <div className="common-card-main mt-5">
                  <h2 className="step-color">{service.title}</h2>
                  <p className="normal-common-txt mt-4">
                    {service.description}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
          <div className="text-center mt-4">
            <Button variant="primary" onClick={() => setShowAll(!showAll)}>
              {showAll ? "Read Less" : "Read More"}
            </Button>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default RegisteredAgentService;
