import React from "react";
import ServicesBanner from "../ServicesPage/ServicesBanner";
import BuisnessNeeds from "../ServicesPage/BuisnessNeeds";
import EntityFormationService from "../ServicesPage/EntityFormationService";
import RegisterStep from "../ServicesPage/RegisterStep";
import RegistrationService from "../ServicesPage/RegistrationService";
import OurTrustedSection from "../CommonSection/OurTrustedSection";
import FaqCommonSection from "../CommonSection/FaqCommonSection";
import CommonRegister from "../CommonSection/CommonRegister";
import { Container } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import ServiceForm from "../CommonSection/ServiceForm";

const WebsiteCompanyMain = () => {
  return (
    <div>
      <ServicesBanner />
      <EntityFormationService />
      <BuisnessNeeds />
      <RegistrationService />
      <RegisterStep />
      {/* <OurTrustedSection /> */}
      <div className="entity-formation">
        <Container>
          <div className="text-center mt-5 mb-5">
            <CommonHeadingTitle heading={"Need help forming your business?"} />
          </div>
          <ServiceForm />
        </Container>
      </div>
      <CommonRegister
        title={"Ready to kick off your new business endeavors?"}
        text={"Get started with your business registration process today."}
        buttonLabel={"Start Your Business"}
        buttonLink={"/signup"}
      />
      <div>
        <FaqCommonSection />
      </div>
    </div>
  );
};

export default WebsiteCompanyMain;
