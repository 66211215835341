import React, { useContext, useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
// import AddGroup from './AddComponents/AddGroup';
import GroupDataTable from "./GroupDataTable";
import UploadImage from "./AddComponents/UploadImage";
// import AddContact from './AddComponents/AddContact';
import DynamicForm from "./AddComponents/DynamicForm";
import { Avatar, IconButton } from "@mui/material";
import { Dropdown } from "primereact/dropdown";
import profileBg from "../Assets/Images/prof-bg.png";
import ProfilePic from "../Assets/Images/Avatar profile photo.png";
import { CameraAlt } from "@mui/icons-material";
import ContactService from "../services/ContactService";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import errorMessages, { globalMessages } from "../utils/errorMessages";
import { Toast } from "primereact/toast";
import ForgotPasswordService from "../services/forgotPasswordService";
import AddressService from "../services/addressService";
import authContext from "../common/authContext";
import { InputMask } from "primereact/inputmask";
import { showToast } from "../utils/toastUtils";
import { getInitials } from "../utils/getInitials";

export default function Profile() {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    fileBase64,
    setIsTabRefresh,
  } = useContext(authContext);

  const [visible, setVisible] = useState(false);
  const [isImageVisible, setImageVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");

  const [passwordErr, setPasswordErr] = useState("");

  const [oldpasswordErr, setOldPasswordErr] = useState("");

  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  console.log(user,"dfaslfdasfadsf")

  const [addressData, setAddressData] = useState(null);

  const handleDialogHide = () => {
    setVisible(false);
  };

  const handleImageClick = () => {
    setImageVisible(true);
  };

  const hideImageDialog = () => {
    setImageVisible(false);
  };

  const [userName, setUserName] = useState("");

  const [email, setEmail] = useState("");

  const [isShowContent, setIsShowContent] = useState(false);

  const [isShowContent1, setIsShowContent1] = useState(false);

  const [isShowContent2, setIsShowContent2] = useState(false);

  const [isShowContent3, setIsShowContent3] = useState(false);

  const [addr1, setAddr1] = useState("");

  
  const [addr2, setAddr2] = useState("");
  
  
  const [addr3, setAddr3] = useState("");
  
  const [region, setRegion] = useState("");
  
  const [regionErr, setRegionErr] = useState("");
  
  const [city, setCity] = useState("");

  const [addr1Err, setAddr1Err] = useState("");
  const [addr2Err, setAddr2Err] = useState("");
  const [cityErr, setcityErr] = useState("");
  const [statesErr, setStatesErr] = useState("");
  const [zipErr, setZipErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [altPhoneErr, setAltPhoneErr] = useState("");
  const [faxErr, setFaxErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  
  const [lastNameErr, setLastNameErr] = useState("");

  const [firstNameErr, setFirstNameErr] = useState("");

  const [states, setStates] = useState(null);

  
  const [zip, setZip] = useState("");


  const [salutation, setSalutation] = useState("");

  const [firstName, setFirstName] = useState(null);

  const [middleName, setMiddleName] = useState("");

  const [lastName, setLastName] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState(null);

  const [altPhoneNumber, setAltPhoneNumber] = useState(null);
  const [phone, setPhone] = useState("");

  const [altEmail, setAltEmail] = useState("");
  
  const [fax, setFax] = useState(null);
  
  const [altFax, setAltFax] = useState("");
  
  const [data, setData] = useState(null);
  
  const [phoneErr, setPhoneErr] = useState("");
  
  

  const [dataId, setDataId] = useState(null);

  const [contactDetails, setContactDeatils] = useState(null);

  const [companyDetails, setCompanyDetails] = useState(null);

  const [allAddressType, setAllAddressType] = useState([]);

  const [addressType, setAddressType] = useState(null);

  const [allStates, setAllStates] = useState([]);

  const [allCountries, setAllCountries] = useState([]);

  const [countryData, setCountryData] = useState(null);

  const [stateValue, setStateValue] = useState(null);

  const [countryValue, setCountryValue] = useState(null);

  const [allCompanies, setAllCompanies] = useState([]);

  const [company, setCompany] = useState(null);

  const [allRoles, setAllRoles] = useState([]);

  const [role, setRole] = useState(null);

  const [tempProduct, setTempProduct] = useState([]);

  const [isSort, setIsSort] = useState(false);

  
  const [addressTypeErr, setAddressTypeErr] = useState("");
  
  const [companyErr, setCompanyErr] = useState("");
  

  const [selectedCountry, setSelectedCountry] = useState(null);
  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];
  const [superAdminId, setSuperAdminId] = useState(null);




      
  
        const handlePostalCode = (e) => {
          setZipErr(""); 
          let value = e.target.value;
              const numericValue = value.replace(/\D/g, ''); 
        
          if (numericValue === "") {
            setZipErr("Postal code required");
          } else if (numericValue.length < 5) { 
            setZipErr("Postal code must be 5 digits");
          } else {
            setZipErr("");}
          setZip(numericValue);
        };
        
  function checkIdsExist(arr, idsToCheck) {
    // Check if any of the idsToCheck are found in arr
    const foundIds = idsToCheck.filter((idToCheck) =>
      arr.some((item) => item.id === idToCheck)
    );
    return foundIds.length > 0 ? foundIds : null;
  }

  useEffect(() => {
    if (user) {
      if (user.companyRoles !== undefined) {
        if (user.companyRoles.length > 0) {
          if (user.companyRoles[0].roles.length > 0) {
            const id = checkIdsExist(user.companyRoles[0].roles, [1, 4, 2]);
            const empId = checkIdsExist(user.companyRoles[0].roles, [2]);
            
            setSuperAdminId(empId);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    
    setSelectedCountry("+1");
    setCountryData(3);
    AddressService.getAllAddressByContactId(dataId)
      .then((res) => {
        
        if (res.data !== "") {
          const tempArr = res.data;
          const temp = tempArr.filter(
            (val) => val.addressType.type === "CONTACT"
          );
          
          if (temp.length > 0) {
            setAddressData(temp[0]);
            setAddr1(temp[0].addressLine1);
            setAddr2(temp[0].addressLine2);
            setCity(temp[0].city);
            setZip(temp[0].postalCode);
            setStates(temp[0].stateId);
            // setCountryData(temp[0].countryId)
            setAddressType(temp[0].addressType);
            setContactDeatils(temp[0].contactDetails);
            const temps = allStates.filter((val) => val.id === temp[0].stateId);
            const temp1 = allCountries.filter(
              (val) => val.id === temp[0].countryId
            );
            
            if (temps.length > 0 && temp1.length > 0) {
              setStateValue(temps[0].stateFullDesc);
              setCountryValue(temp1[0].countryFullDesc);
            }
          }
        } else {
          setAddressData(null);
          setAddr1("");
          setAddr2("");
          setCity("");
          setZip("");
          setStates(null);
          // setCountryData(null)
        }
      })
      .catch((err) => {
        
      });
  }, [dataId]);

  useEffect(() => {
    // companyMasterService.getAllCompany()
    //     .then((res) => {
    //         console.log(res)
    //         setAllCompanies(res.data)
    //     })
    //     .catch((err) => {
    //         console.log(err)
    //     })

    AddressService.getAllAddressType()
      .then((res) => {
        setAllAddressType(res.data);
        
        if (Array.isArray(res.data)) {
          const temp = res.data.filter((val) => val.type === "CONTACT");
          if (temp.length > 0) {
            setAddressType(temp[0]);
          }
        }
      })
      .catch((err) => {
        
      });

    AddressService.getAllCountry()
      .then((res) => {
        
        setAllCountries(res.data);
      })
      .catch((err) => {
        
      });

    AddressService.getAllStates()
      .then((res) => {
        
        setAllStates(res.data);
      })
      .catch((err) => {
        
      });
  }, []);

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        {selectedCountry ? (
          <span>
            <b>{selectedCountry.name}</b> selected.
          </span>
        ) : (
          "No country selected."
        )}
      </div>
    );
  };

  const [loginDetails, setLoginDetails] = useState([
    { key: "Email Address", value: `${user ? user.username : ""}` },
    { key: "Password", value: "dhdhdhdhhdhd" },
  ]);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    setIsTabRefresh(true);
    if (user?.id) {
      ContactService.getAllContactByUserId(user?.id)
        .then((res) => {
          
          let data = res.data;

          if (res.data) {
            if (res.data?.contactDetails !== undefined) {
              setDataId(res.data?.contactDetails?.id);
              setUserName(
                `${res.data?.contactDetails?.firstName} ${res.data?.contactDetails?.lastName}`
              );
              setEmail(res.data?.contactDetails?.emailId);
              setData(res.data);
              setContactDeatils(res.data?.contactDetails);
            }

            if (res.data?.companyDetails !== undefined) {
              setCompanyDetails(res.data?.companyDetails);
              if (Array.isArray(res.data?.companyDetails)) {
                
                if (globalCompany) {
                  const temp = res.data?.companyDetails.filter(
                    (val) => val.id === globalCompany.companyDetails.id
                  );
                  
                  if (temp.length > 0) {
                    setCompany(temp);
                  }
                }
              }
            }
            setTimeout(() => {
              setIsTabRefresh(false);
            }, 800);
          }
        })
        .catch((err) => {
          
          setIsTabRefresh(false);
        });
    }
  }, [globalCompany, isLoading]);

  useEffect(() => {
    if (data) {
      if (data.contactDetails !== undefined) {
        setFirstName(data.contactDetails.firstName);
        setLastName(data.contactDetails.lastName);

        if (data.contactDetails.mobileNo) {
          // const temp3 = data.contactDetails.mobileNo.split(" ")
          // if (Array.isArray(temp3)) {
          //     if (temp3.length > 0) {
          //         setPhone(temp3.slice(1).join(" "))
          //     }
          // }
          setPhone(data.contactDetails.mobileNo);
        }
        if (data.contactDetails.fax) {
          const temp3 = data.contactDetails.fax.split(" ");
          if (Array.isArray(temp3)) {
            if (temp3.length > 0) {
              setFax(temp3.slice(1).join(" "));
            }
          }
        }
        if (data.contactDetails.phone) {
          const temp3 = data.contactDetails.phone.split(" ");
          if (Array.isArray(temp3)) {
            if (temp3.length > 0) {
              setAltPhoneNumber(temp3.slice(1).join(" "));
            }
          }
        }
        setEmail(data.contactDetails.emailId);
        setSalutation(data.contactDetails.salutation);
      }
    }
  }, [data]);


  const [isEditing, setIsEditing] = useState(false);
  const [isEditing1, setIsEditing1] = useState(false);
  const [isEditing2, setIsEditing2] = useState(false);

  const toast = useRef(null);

  const handleEdit1 = () => {
    setIsEditing1(true);
  };

  const handleEdit2 = () => {
    setIsEditing2(true);
  };

  const [editLoginValues, setEditLoginValues] = useState(
    loginDetails.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {})
  );

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handlePassword = (e) => {
    setPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setPasswordErr(errorMessages.passwordRequired);
    }
    if (value && value.trim() !== "") {
      let errors = [];

      if (!/[A-Z]/.test(value)) {
        errors.push("at least one uppercase letter");
      }
      if (!/[a-z]/.test(value)) {
        errors.push("at least one lowercase letter");
      }
      if (!/\d/.test(value)) {
        errors.push("at least one digit");
      }
      if (!/[@$!%*#?&_]/.test(value)) {
        errors.push("at least one special character (@$!%*#?&_)");
      }
      if (value.length < 8) {
        errors.push("a minimum length of 8 characters");
      }

      if (errors.length > 0) {
        setPasswordErr(`Password must contain ${errors.join(", ")}.`);
      }
    }
    setPassword(e.target.value);
  };

  const handleOldPassword = (e) => {
    setOldPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setOldPasswordErr(errorMessages.oldPasswordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setOldPasswordErr(errorMessages.oldPasswordValidation);
      }
    }
    setOldPassword(e.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };

  const handleConfirmPassword = (e) => {
    setConfirmPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
    }
    if (value && value.trim() !== "") {
      let errors = [];

      if (!/[A-Z]/.test(value)) {
        errors.push("at least one uppercase letter");
      }
      if (!/[a-z]/.test(value)) {
        errors.push("at least one lowercase letter");
      }
      if (!/\d/.test(value)) {
        errors.push("at least one digit");
      }
      if (!/[@$!%*#?&_]/.test(value)) {
        errors.push("at least one special character (@$!%*#?&_)");
      }
      if (value.length < 8) {
        errors.push("a minimum length of 8 characters");
      }

      if (errors.length > 0) {
        setConfirmPasswordErr(`Password must contain ${errors.join(", ")}.`);
      }
    }
    setConfirmPassword(e.target.value);
  };

  const handleEmail = (e) => {
    setEmailErr("");
    let value = e.target.value;
    if (value === "") {
      setEmailErr(errorMessages.emailRequired);
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      setEmailErr(errorMessages.emailValidation);
    }
    setEmail(e.target.value);
  };

  const handleLastName = (e) => {
    setLastNameErr("");
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setLastName(e.target.value);
  };

  const handleFirstName = (e) => {
    setFirstNameErr("");
    let value = e.target.value;
    if (value === "") {
      setFirstNameErr(errorMessages.firstNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setFirstNameErr(errorMessages.firstNameValidation);
      }
    }
    setFirstName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;

    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }
    const formattedValue = inputValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
    );
    if (inputValue.length === 10) {
      // Only show formatted value after 10 digits
      e.target.value = formattedValue;
      setPhone(formattedValue); // Update state with formatted number
    } else {
      e.target.value = inputValue;
      setPhone(inputValue);
    }
    if (value === "") {
      setPhoneErr(errorMessages.phoneRequired);
    }
    if (value && value.trim() !== "" && inputValue.length <= 9) {
      if (
        !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
          value
        )
      ) {
        setPhoneErr(errorMessages.phoneValidation);
      }
    }
    setPhone(e.target.value);
  };

  // const handleAlternatePhone = (e) => {
  //     setPhoneErr("")
  //     let value = e.target.value

  //     if (value && value.trim() !== '') {
  //         if (!/^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(value)) {
  //             setPhoneErr(errorMessages.phoneValidation)
  //         }

  //     }
  //     setAltPhoneNumber(e.target.value)
  // }

  const handleAlternatePhone = (e) => {
    setAltPhoneErr("");
    let value = e.target.value;

    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }
    const formattedValue = inputValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
    );
    if (inputValue.length === 10) {
      // Only show formatted value after 10 digits
      e.target.value = formattedValue;
      setAltPhoneNumber(formattedValue); // Update state with formatted number
    } else {
      e.target.value = inputValue;
      setAltPhoneNumber(inputValue);
    }

    // if (value === "") {
    //   setAltPhoneErr(errorMessages.phoneRequired);
    // }
    if (value && value.trim() !== "" && inputValue.length !== 10) {
      if (
        !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
          value
        )
      ) {
        setAltPhoneErr(errorMessages.phoneValidation);
      }
    }
    if (inputValue.length !== 10) {
      setAltPhoneErr(errorMessages.phoneValidation);  // Show error if not 10 digits
    }
    setAltPhoneNumber(e.target.value);
  };

  // const handleFax = (e) => {
  //   setFaxErr("");
  //   let value = e.target.value;

  //   if (value && value.trim() !== "") {
  //     if (
  //       !/^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(value)
  //     ) {
  //       setFaxErr(errorMessages.faxValidation);
  //     }
  //   }
  //   setFax(e.target.value);
  // };
  const handleFax = (e) => {
    setFaxErr("");
    let value = e.target.value;

    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }
    const formattedValue = inputValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
    );
    if (inputValue.length === 10) {
      // Only show formatted value after 10 digits
      e.target.value = formattedValue;
      setFax(formattedValue); // Update state with formatted number
    } else {
      e.target.value = inputValue;
      setFax(inputValue);
    }

    // if (value === "") {
    //   setFaxErr(errorMessages.faxRequired);
    // }
    if (value && value.trim() !== "" && inputValue.length <= 9) {
      if (
        !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
          value
        )
      ) {
        setFaxErr("Enter valid fax number");
      }
    }
    if (inputValue.length !== 10) {
      setFaxErr("Enter valid fax number");  // Show error if not 10 digits
    }
    setFax(e.target.value);
  };
//   const handleZip = (e) => {
//     setZipErr("");
//     let value = e.target.value.replace(/\D/g, "");
//     if (value === "") {
//         setZipErr("Postal code is required");
//     } else if (!/^\d{5,9}$/.test(value)) {
//         setZipErr("Enter a valid postal code");
//     }

//     setZip(value);
// };

const handleZip = (e) => {
  let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters

  // Restrict input to exactly 5 digits
  if (value.length > 5) {
    value = value.slice(0, 5);
  }

  setZip(value);
  
  // Validation checks
  if (!value) {
    setZipErr("Zip code is required");
  } else if (value.length < 5) {
    setZipErr("Zip code must be exactly 5 digits");
  } else {
    setZipErr(""); // Clear error if valid
  }
};



  const handleSave = (e) => {
    

    setIsEditing(false);
    
    if (data) {
      const actualdata = data.contactDetails;
      actualdata["firstName"] = firstName;
      actualdata["lastName"] = lastName;
      actualdata["mobileNo"] = phoneNumber;
      actualdata["emailId"] = email;
      actualdata["fax"] = fax;
      actualdata["phone1"] = altPhoneNumber;
      actualdata["salutation"] = salutation;

      

      const actualdata1 = {
        id: data.contactDetails.id,
        salutation: salutation,
        jobTitle: "Java Developer",
        contactSourceId: null,
        recordTypeId: null,
        avatarImgPath: null,
        phone: altPhoneNumber ? `${selectedCountry} ${altPhoneNumber}` : null,
        fax: fax ? `${selectedCountry} ${fax}` : null,
        statusId: 1,
      };

      if (
        firstName &&
        lastName &&
        phone &&
        email &&
        altPhoneErr === "" &&
        faxErr === ""
      ) {
        setIsLoading(true);
        ContactService.updateContact(actualdata1, "")
          .then((res) => {
            
            setIsEditing1(false);
            setIsLoading(false);
            // if (toast.current !== undefined) {
            //   toast.current.show({
            //     severity: "success",
            //     summary: "Profile updated successfully",
            //     life: 3000,
            //     content: (props) => (
            //       <div
            //         className="flex flex-column align-items-left"
            //         style={{ flex: "1" }}
            //       >
            //         <div className="flex align-items-center gap-2">
            //           {/* <Avatar shape="circle" style={{ backgroundColor: '#0047FF', color: '#ffffff' }}>
            //                                     <div dangerouslySetInnerHTML={{ __html: svgContent }} />
            //                                 </Avatar> */}
            //           <span className="p-toast-summary">vState Filings</span>
            //         </div>
            //         <div className="p-toast-detail">
            //           {props.message.summary}
            //         </div>
            //         {/* <Button className=" signup-btn vstate-button flex" label="Ok" severity="success" onClick={() => navigate("/signin")}></Button> */}
            //       </div>
            //     ),
            //   });
            // }
                                showToast("success","Profile updated successfully")
            
          })
          .catch((err) => {
            
            setIsLoading(false);
            // if (toast.current !== undefined) {
            //   toast.current.show({
            //     severity: "warn",
            //     summary: "Something went wrong",
            //     life: 3000,
            //   });
            // }
            showToast("error","Something went wrong")

          });
      } else {
        if (!firstName) {
          setFirstNameErr(errorMessages.firstNameRequired);
        }
        if (!lastName) {
          setLastNameErr(errorMessages.lastNameRequired);
        }
        if (!phoneNumber) {
          setPhoneErr(errorMessages.phoneRequired);
        }
        if (!email) {
          setEmailErr(errorMessages.emailRequired);
        }
      }
    }
  };

  const handleSave2 = () => {
    let hasError = false;
  
    if (!zip)  {
      setZipErr("Postal code is required");
      hasError = true;
    }
    if(zipErr){
      setZipErr("Enter a valid postal code");
      hasError = true;
    }
    if (!firstName) {
      setFirstNameErr("First name is required");
      hasError = true;
    }
    if (!lastName) {
      setLastNameErr("Last name is required");
      hasError = true;
    }
    if (!addr1) {
      setAddr1Err("Address Line 1 is required");
      hasError = true;
    }
    if (!city) {
      setcityErr("City is required");
      hasError = true;
    }
    if (!email) {
      setEmailErr("Email is required");
      hasError = true;
    }
  
    if (hasError) return;
  
    if (addressData) {
      handleAddressEdit();
    } else {
      handleAddressSave();
    }
  };
  

  const handleAddressEdit = () => {
    if (addressData) {
      const actualdata = addressData;
      actualdata["addressLine1"] = addr1;
      actualdata["addressLine2"] = addr2;
      actualdata["city"] = city;
      actualdata["stateMasterId"] = states;
      actualdata["countryMasterId"] = countryData;
      actualdata["postalCode"] = zip;
      actualdata["stateId"] = states;
      actualdata["countryId"] = countryData;

      if (
        addr1 !== "" &&
        city !== "" &&
        zip !== "" &&
        contactDetails &&
        company &&
        addressType &&
        states &&
        countryData
      ) {
        setIsLoading(true);
        AddressService.updateAllAddress(actualdata)
          .then((res) => {
            
            setIsEditing2(false);
            setIsLoading(false);
         showToast("success","Address updated successfully")

          })
          .catch((err) => {
            
            setIsLoading(false);
          showToast("error","Something went wrong")

          });
      } else {
        if (addr1 === "") {
          setAddr1Err(errorMessages.address1Required);
        }
        // if (addr2 === "") {
        //     setAddr2Err(errorMessages.address2Required)
        // }
        if (city === "") {
          setcityErr(errorMessages.cityRequired);
        }
        if (zip === "") {
          setZipErr(errorMessages.zipRequired);
        }
        if (!company) {
          setCompanyErr(errorMessages.companyRequired);
        }
        if (!addressType) {
          setAddressTypeErr(errorMessages.addressTypeRequired);
        }
        if (!states) {
          setStatesErr(errorMessages.statesRequired);
        }
        if (!countryData) {
          setCountryErr(errorMessages.countryRequired);
        }
      }
    }
  };

  const handleAddressSave = () => {
    const actualdata = {
      addressLine1: addr1,
      addressLine2: addr2,
      city: city,
      stateId: states,
      postalCode: zip,
      countryId: countryData,
      contactDetails: contactDetails,
      companyDetails: null,
      addressType: addressType,
    };

    

    if (
      addr1 !== "" &&
      city !== "" &&
      zip !== "" &&
      contactDetails &&
      company &&
      addressType &&
      states &&
      countryData
    ) {
      setIsLoading(true);
      AddressService.saveAllAddress(actualdata)
        .then((res) => {
          
          setIsEditing2(false);
          setIsLoading(false);
          // if (toast.current !== undefined) {
          //   toast.current.show({
          //     severity: "success",
          //     summary: "Address added successfully",
          //     life: 3000,
          //     content: (props) => (
          //       <div
          //         className="flex flex-column align-items-left"
          //         style={{ flex: "1" }}
          //       >
          //         <div className="flex align-items-center gap-2">
          //           {/* <Avatar shape="circle" style={{ backgroundColor: '#0047FF', color: '#ffffff' }}>
          //                                   <div dangerouslySetInnerHTML={{ __html: svgContent }} />
          //                               </Avatar> */}
          //           <span className="p-toast-summary">vState Filings</span>
          //         </div>
          //         <div className="p-toast-detail">{props.message.summary}</div>
          //         {/* <Button className=" signup-btn vstate-button flex" label="Ok" severity="success" onClick={() => navigate("/signin")}></Button> */}
          //       </div>
          //     ),
          //   });
          // }

          showToast("success","Address added successfully")

        })
        .catch((err) => {
          
          setIsLoading(false);
          // if (toast.current !== undefined) {
          //   toast.current.show({
          //     severity: "warn",
          //     summary: "Something went wrong",
          //     life: 3000,
          //   });
          // }

          showToast("error","Something went wrong")

        });
    } else {
      if (addr1 === "") {
        setAddr1Err(errorMessages.address1Required);
      }
      // if (addr2 === "") {
      //     setAddr2Err(errorMessages.address2Required)
      // }
      if (city === "") {
        setcityErr(errorMessages.cityRequired);
      }
      if (zip === "") {
        setZipErr(errorMessages.zipRequired);
      }
      if (!company) {
        setCompanyErr(errorMessages.companyRequired);
      }
      if (!addressType) {
        setAddressTypeErr(errorMessages.addressTypeRequired);
      }

      if (!states) {
        setStatesErr(errorMessages.statesRequired);
      }
      if (!countryData) {
        setCountryErr(errorMessages.countryRequired);
      }
    }
  };

  const handleSave1 = () => {
    const newData = loginDetails.map((item) => ({
      ...item,
      value: editLoginValues[item.key],
    }));
    setLoginDetails(newData);

    if (
      password !== "" &&
      confirmedPassword !== "" &&
      password === confirmedPassword
    ) {
      setIsLoading(true);
      ForgotPasswordService.resetPassword(confirmedPassword, email)
        .then((res) => {
          
          setIsEditing(false);
          setIsLoading(false);
          // if (toast.current !== null) {
          //   if (res.data === "Password changed successfully!") {
          //     toast.current.show({
          //       severity: "success",
          //       summary: "Success",
          //       detail: "Password updated successfully",
          //       life: 2800,
          //     });
          //   }
          // }

          showToast("success","Password changed successfully")

        })
        .catch((err) => {
          
          setIsLoading(false);
          if (err.response !== undefined) {
            if (err.response.status !== undefined) {
              if (err.response.status === 701) {
                // if (toast.current !== undefined) {
                //   toast.current.show({
                //     severity: "warn",
                //     summary: "Warning",

                //     content: (props) => (
                //       <div
                //         className="flex flex-column align-items-left"
                //         style={{ flex: "1" }}
                //       >
                //         <div className="flex align-items-center gap-2">
                //           <span className="p-toast-summary">
                //             vState Filings
                //           </span>
                //         </div>
                //         <div className="p-toast-detail">
                //           Password has been used recently
                //         </div>
                //       </div>
                //     ),
                //     life: 3000,
                //   });
                // }

          showToast("error","Password has been used recently")

              }
            }
          }
        });
    } else {
      if (confirmedPassword === "") {
        setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
      }
      if (password === "") {
        setPasswordErr(errorMessages.passwordRequired);
      }
      // if (oldPassword === "") {
      //     setOldPasswordErr(errorMessages.oldPasswordRequired)
      // }
      if (password !== confirmedPassword) {
        // setVisible(true)
        // if (toast.current !== undefined) {
        //   toast.current.show({
        //     severity: "warn",
        //     summary: "Warning",

        //     content: (props) => (
        //       <div
        //         className="flex flex-column align-items-left"
        //         style={{ flex: "1" }}
        //       >
        //         <div className="flex align-items-center gap-2">
        //           <span className="p-toast-summary">vState Filings</span>
        //         </div>
        //         <div className="p-toast-detail">
        //           {errorMessages.passwordMatched}
        //         </div>
        //       </div>
        //     ),
        //     life: 3000,
        //   });
        // }

        showToast("error",`${errorMessages.passwordMatched}`)

        // setGlobalErr(errorMessages.passwordMatched)
      }
    }
  };

  const handleLoginChange = (key, value) => {
    setEditLoginValues({ ...editLoginValues, [key]: value });
  };

  const header = (
    <>
      <div className="header-container relative">
        {/* Background Image */}
        <img alt="Card" src={profileBg} className="header-image w-full" />

        {/* Avatar + Profile Details */}
        <div className="absolute left-8 top-3/4  flex items-center">
          {/* Avatar Container */}
          <div className="relative">
            <Avatar
              alt="Remy Sharp"
              src={`data:image/jpeg;base64,${fileBase64}`}
              className="custom-avatar border-8 border-#F4EBFF text-3xl shadow-lg"
              sx={{ width: 140, height: 140 }}
              onClick={handleImageClick}
            >
              {fileBase64 ? "" : getInitials(userName)}
            </Avatar>

            {/* Pencil Icon - Positioned at the top-right of the Avatar */}
            <IconButton
              className="absolute top-4 right-1 bg-white shadow-md rounded-full p-1"
              onClick={handleImageClick}
            >
              <i className="pi pi-pencil p-1 text-[#175CD3]"></i>
            </IconButton>
          </div>

          {/* Profile Details */}
          <div className="ml-4  pt-6">
            {/* text-[#101828] font-Inter font-medium  text-normal */}
            <h1 className="text-left  text-[#101828] font-Inter text-normal  text-3xl capitalize ">
              {data?.contactDetails?.salutation
                ? `${data?.contactDetails?.salutation+"."} `
                : ""}
              {userName}
            </h1>
            <p className="text-gray-500 text-left font-Inter text-base font-normal">
              {email}
            </p>
          </div>
        </div>
      </div>
    </>
  );

  const PasswordText = (text) => {
    const maskedText = text.replace(/./g, "*");
    return maskedText;
  };

  const getState = (id) => {
    const stateval = allStates.find((val) => val?.id === id);
    if (stateval) {
      return stateval?.stateFullDesc;
    }
  };

  

  return (
    <div className="card flex justify-content-center font-fam-for-all">
      <Toast ref={toast} />

      <Card
        title=""
        subTitle=""
        header={header}
        className="profile-card"
        style={{ backgroundColor: "#FCFCFD" }}
      >
        <div className="mt-6 p-4 font-fam-for-all">
          <h1 className="text-[#101828] font-Inter font-medium  text-normal text-2xl profile1 user_myprofile ">
            My Profile
          </h1>
          <p className="text-#667085 font-Inter text-sm font-normal ">
            Manage your login, contact information, and account settings.
          </p>
          <div className="" style={{ backgroundColor: "#FCFCFD" }}>
            {data && data.contactDetails !== undefined ? (
              <TabView>
                <TabPanel header="Login Details">
                  {isEditing ? (
                    <>
                      <div className=" grid- font-fam-for-all slide-in1 card my-3">
                        <div className="px-4 pt-4 pb-0">
                          <div className="grid">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-10 mt-2">
                              <h6
                                style={{ fontWeight: "bold", fontSize: "18px" }}
                              >
                                Change Password
                              </h6>
                              {/* <p>Change Password Here</p> */}
                            </div>
                          </div>
                        </div>
                        <div className="grid">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                            <div className="justify-content-center dialog-form-field-space grid mt-3">
                              <div className="field col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 p-0">
                                <div className="relative w-full">
                                  <input
                                    id="floating_outlined9"
                                    className={`block w-full px-2.5 pb-2.5 pt-2 pr-10 h-10 text-sm text-gray-900 bg-transparent rounded-lg border appearance-none dark:text-white focus:outline-none focus:ring-0 peer ${
                                      passwordErr
                                        ? "border-red-500 focus:border-red-500 dark:border-red-500 dark:focus:border-red-500"
                                        : "border-gray-300 focus:border-blue-600 dark:border-gray-600 dark:focus:border-blue-500"
                                    }`}
                                    placeholder=""
                                    type={passwordType}
                                    value={password}
                                    onChange={handlePassword}
                                  />

                                  <label
                                    htmlFor="floating_outlined9"
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                  >
                                    Password
                                    <span className="form-field-mandatory">
                                      *
                                    </span>
                                  </label>

                                  {/* Eye Icon */}
                                  <i
                                    className={`absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer pi ${
                                      passwordType === "password"
                                        ? "pi-eye"
                                        : "pi-eye-slash"
                                    }`}
                                    style={{ fontSize: "1rem" }}
                                    onClick={togglePassword}
                                  ></i>
                                </div>

                                {passwordErr !== "" ? (
                                  <p className="error-msg font-fam-for-all">
                                    {passwordErr}
                                  </p>
                                ) : (
                                  ""
                                )}

                                {/* <ul >
                                                                                        <li className=' font-fam-for-all pt-2' style={{ fontSize: "12px", color: "#667085" }}>{globalMessages.passwordValidText1}</li>
                                                                                        <li className=' font-fam-for-all pt-2' style={{ fontSize: "12px", color: "#667085" }}>{globalMessages.passwordValidText2}</li>
                                                                                    </ul> */}
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                            <div className="justify-content-center dialog-form-field-space grid mt-3">
                              <div className="field col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 p-0">
                                <div className="relative">
                                  <input
                                    id="floating_outlined7"
                                    className={`block w-full px-2.5 pb-2.5 pt-2 pr-10 h-10 text-sm text-gray-900 bg-transparent rounded-lg border appearance-none dark:text-white focus:outline-none focus:ring-0 peer ${
                                      confirmPasswordErr ||
                                      (password !== confirmedPassword &&
                                        confirmedPassword &&
                                        password)
                                        ? "border-red-500 focus:border-red-500 dark:border-red-500 dark:focus:border-red-500"
                                        : "border-gray-300 focus:border-blue-600 dark:border-gray-600 dark:focus:border-blue-500"
                                    }`}
                                    placeholder=""
                                    type={passwordType1}
                                    value={confirmedPassword}
                                    style={{ width: "100%", height: "40px" }}
                                    onChange={handleConfirmPassword}
                                  />
                                  <label
                                    htmlFor="floating_outlined7"
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                  >
                                    Confirm Password
                                    <span className="form-field-mandatory">
                                      *
                                    </span>
                                  </label>

                                  {/* Eye Icon */}
                                  <i
                                    className={`absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer pi ${
                                      passwordType1 === "password"
                                        ? "pi-eye"
                                        : "pi-eye-slash"
                                    }`}
                                    style={{ fontSize: "1rem" }}
                                    onClick={togglePassword1}
                                  ></i>
                                </div>
                                {confirmPasswordErr !== "" ? (
                                  <p className="error-msg font-fam-for-all">
                                    {confirmPasswordErr}
                                  </p>
                                ) : password !== confirmedPassword &&
                                  password &&
                                  confirmedPassword ? (
                                  <p className="error-msg font-fam-for-all">
                                    Password and confirm password doesn't
                                    matched
                                  </p>
                                ) : password === confirmedPassword &&
                                  password &&
                                  confirmedPassword ? (
                                  <p className="text-green-500 success-msg font-fam-for-all">
                                    Passwords matched
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-2">
                          <div className="flex justify-content-end dialog-form-md-group-Btn">
                            <Button
                              className="mt-2  font-fam-for-all text-center vstate-reverse-button mr-2"
                              style={{ height: "40px" }}
                              onClick={() => {
                                setIsEditing(false);
                                setOldPassword("");
                                setPassword("");
                                setConfirmPassword("");
                                setOldPasswordErr("");
                                setPasswordErr("");
                                setConfirmPasswordErr("");
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="mt-2  font-fam-for-all text-center vstate-button mr-3"
                              style={{ height: "40px" }}
                              onClick={handleSave1}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className=" font-fam-for-all slide-in hover-card card my-3">
                        <div className="p-3">
                          <div className="grid">
                            <div className="col-10 mt-2">
                              <h6
                                style={{ fontWeight: "bold", fontSize: "18px" }}
                              >
                                Login Details
                              </h6>
                            </div>

                            <div className="col-2 flex justify-content-end">
                              <div className="flex justify-content-end dialog-form-md-group-Btn">
                                <button
                                  className="datatable-highlight-color webapp-global-sm-size"
                                  onClick={handleEdit}
                                >
                                  Change password?
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {loginDetails.map((item, index) => (
                          <div key={index} className="grid-row">
                            <div className="grid">
                              <div
                                className="col-2 p-4"
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                {item.key}
                              </div>
                              <div className="col-6 p-4 webapp-global-sm-size">
                                {`${
                                  item.key === "Password"
                                    ? PasswordText(item.value)
                                    : item.value
                                }`}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </TabPanel>
                <TabPanel header="Profile Info" className="user-tabpanel">
                  {/* <div cd */}

                  {isEditing1 ? (
                    <div className="card my-3 slide-in">
                      <div className="p-4">
                        <h2 style={{ fontWeight: "bold", fontSize: "18px" }}>
                          Profile Information
                        </h2>
                        <div style={{ fontSize: "14px" }}>
                          Update Your Personal details here
                        </div>
                      </div>

                      <div className="grid px-4 py-2">
                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-3"
                          style={{ fontWeight: "bold", fontSize: "15px" }}
                        >
                          <span className=" mr-3">
                            <div class="relative">
                              <Dropdown
                                value={salutation}
                                options={["Mr", "Ms", "Mrs", "Dr"]}
                                filter
                                resetFilterOnHide
                                onChange={(e) => setSalutation(e.target.value)}
                                // optionLabel="code"
                                className="p-dropdown font-fam-for-all vstate-input-field"
                              />
                              <label
                                htmlFor="floating_outlined25"
                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                              >
                                Salutation{" "}
                                <span className="form-field-mandatory"></span>
                              </label>
                            </div>
                            {/* {statesErr !== "" ? <p className="error-msg font-fam-for-all">{statesErr}</p> : ""} */}
                          </span>
                        </div>
                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-3 "
                          style={{ fontWeight: "bold", fontSize: "15px" }}
                        >
                          <span className=" mr-3">
                            <div class="relative">
                              <InputText
                                style={{ height: "40px", width: "100%" }}
                                maxLength={50}
                                value={firstName}
                                id="floating_outlined3"
                                placeholder=""
                                className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                onChange={handleFirstName}
                                disabled
                                name="cityName"
                              />

                              <label
                                htmlFor="floating_outlined3"
                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                              >
                                First Name
                                <span className="form-field-mandatory">*</span>
                              </label>
                            </div>
                            {firstNameErr !== "" ? (
                              <p className="error-msg font-fam-for-all">
                                {firstNameErr}
                              </p>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>

                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-3"
                          style={{ fontWeight: "bold", fontSize: "15px" }}
                        >
                          <span className=" mr-3">
                            <div class="relative">
                              <InputText
                                style={{ height: "40px", width: "100%" }}
                                maxLength={50}
                                value={lastName}
                                id="floating_outlined5"
                                placeholder=""
                                className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                onChange={handleLastName}
                                disabled
                                name="cityName"
                              />
                              <label
                                htmlFor="floating_outlined5"
                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                              >
                                Last Name{" "}
                                <span className="form-field-mandatory">*</span>
                              </label>
                            </div>
                            {lastNameErr !== "" ? (
                              <p className="error-msg font-fam-for-all">
                                {lastNameErr}
                              </p>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-3"
                          style={{ fontWeight: "bold", fontSize: "15px" }}
                        >
                          {/* <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}> */}

                          <span className=" mr-3">
                            <div class="relative">
                              <InputText
                                style={{
                                  height: "40px",
                                  borderRadius: "0px 6px 6px 0px",
                                }}
                                maxLength={50}
                                value={email}
                                onChange={handleEmail}
                                name="cityName"
                                disabled
                                id="floating_outlined6"
                                placeholder=""
                                className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              />
                              <label
                                htmlFor="floating_outlined6"
                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                              >
                                Email Address{" "}
                                <span className="form-field-mandatory">*</span>
                              </label>
                            </div>
                            {emailErr !== "" ? (
                              <p className="error-msg font-fam-for-all">
                                {emailErr}
                              </p>
                            ) : (
                              ""
                            )}
                          </span>
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="grid px-4 py-2">
                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-3"
                          style={{ fontSize: "15px" }}
                        >
                          <div
                            className="p-inputgroup flex-1"
                            style={{ height: "40px", width: "100%" }}
                          >
                            <Dropdown
                              value={selectedCountry}
                              options={countries}
                              onChange={(e) => setSelectedCountry(e.value)}
                              optionLabel="code"
                              optionValue="dialCode"
                              disabled
                              filter
                              resetFilterOnHide
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              style={{
                                width: "80px",
                                borderRadius: "6px 0px 0px 6px",
                              }}
                              className="p-dropdown font-fam-for-all"
                            />
                            <span className="">
                              <div class="relative">
                                {/* <InputMask
                                                                    maxLength={50}
                                                                    value={phoneNumber}
                                                                    id="floating_outlined10"
                                                                    placeholder=''
                                                                    mask={isFocused ? "(999) 999-9999" : "(999) 999-9999"}
                                                                    onFocus={() => setIsFocused(true)}
                                                                    onBlur={() => setIsFocused(false)}
                                                                    className="block vstate-input-field px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                    disabled
                                                                    onChange={handlePhone}
                                                                    name="cityName"
                                                                /> */}

                                {/* <input
                                                                    type='text'
                                                                        id="floating_outlined93"
                                                                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                                                                        style={{ height: '40px', borderRadius: '0px 6px 6px 0px', width: "100%" }}
                                                                        maxLength={50}
                                                                        value={data.contactDetails.mobileNo}                                                    
                                                                        onChange={handlePhone}
                                                                        autoClear={false}
                                                                        placeholder=''
                                                                        name="cityName"
                                                                    /> */}

                                <input
                                  type="text"
                                  id="floating_outlined93"
                                  className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                                  style={{
                                    height: "40px",
                                    borderRadius: "0px 6px 6px 0px",
                                    width: "100%",
                                  }}
                                  maxLength={50}
                                  disabled
                                  value={phone}
                                  // defaultValue={data.contactDetails.mobileNo}
                                  onChange={handlePhone}
                                  placeholder=""
                                  name="cityName"
                                />

                                <label
                                  htmlFor="floating_outlined93"
                                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                >
                                  Phone Number{" "}
                                  <span className="form-field-mandatory">
                                    *
                                  </span>
                                </label>
                              </div>
                              {/* {phoneErr !== "" ? <p className='error-msg font-fam-for-all'>{phoneErr}</p> : ""} */}
                            </span>
                          </div>
                        </div>

                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-3 "
                          style={{ fontSize: "15px" }}
                        >
                          <div
                            className="p-inputgroup flex-1"
                            style={{ height: "40px", width: "100%" }}
                          >
                            <Dropdown
                              value={selectedCountry}
                              options={countries}
                              onChange={(e) => setSelectedCountry(e.value)}
                              optionLabel="code"
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              optionValue="dialCode"
                              filter
                              resetFilterOnHide
                              style={{
                                width: "80px",
                                borderRadius: "6px 0px 0px 6px",
                              }}
                              className="p-dropdown font-fam-for-all"
                            />
                            <span className="">
                              <div class="relative">
                                {/* <InputMask

                                                                    maxLength={50}
                                                                    value={altPhoneNumber}
                                                                    id="floating_outlined11"
                                                                    placeholder=''
                                                                    className="block vstate-input-field px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                    mask={isFocused ? "(999) 999-9999" : "(999) 999-9999"}
                                                                    onFocus={() => setIsFocused(true)}
                                                                    onBlur={() => setIsFocused(false)}
                                                                    onChange={handleAlternatePhone}
                                                                    name="cityName"
                                                                /> */}
                                <input
                                  type="text"
                                  id="floating_outlined92"
                                  className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                                  style={{
                                    height: "40px",
                                    borderRadius: "0px 6px 6px 0px",
                                    width: "100%",
                                  }}
                                  maxLength={50}
                                  value={altPhoneNumber}
                                  onChange={handleAlternatePhone}
                                  autoClear={false}
                                  placeholder=""
                                  name="cityName"
                                />
                                <label
                                  htmlFor="floating_outlined92"
                                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                >
                                  Alt. Phone Number{" "}
                                  <span className="form-field-mandatory"></span>
                                </label>
                              </div>
                              {altPhoneErr !== "" ? (
                                <p className="error-msg font-fam-for-all">
                                  {altPhoneErr}
                                </p>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>

                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-3"
                          style={{ fontSize: "15px" }}
                        >
                          <div
                            className="p-inputgroup flex-1"
                            style={{ height: "40px", width: "100%" }}
                          >
                            <Dropdown
                              value={selectedCountry}
                              options={countries}
                              onChange={(e) => setSelectedCountry(e.value)}
                              optionLabel="code"
                              filter
                              resetFilterOnHide
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              optionValue="dialCode"
                              style={{
                                width: "40px",
                                borderRadius: "6px 0px 0px 6px",
                              }}
                              className="p-dropdown font-fam-for-all"
                            />
                            <span className=" mr-3">
                              <div class="relative">
                                {/* <InputMask
                                                                    maxLength={16}
                                                                    value={fax}
                                                                    onChange={handleFax}
                                                                    name="cityName"
                                                                    id="floating_outlined8"
                                                                    mask={isFocused ? "(999) 999-9999" : "(999) 999-9999"}
                                                                    onFocus={() => setIsFocused(true)}
                                                                    onBlur={() => setIsFocused(false)}
                                                                    placeholder=''
                                                                    className="block vstate-input-field px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                                /> */}
                                <input
                                  type="text"
                                  id="floating_outlined55"
                                  className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                                  style={{
                                    height: "40px",
                                    borderRadius: "0px 6px 6px 0px",
                                    width: "100%",
                                  }}
                                  maxLength={50}
                                  value={fax}
                                  onChange={handleFax}
                                  autoClear={false}
                                  placeholder=""
                                  name="cityName"
                                />
                                <label
                                  htmlFor="floating_outlined55"
                                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                >
                                  Fax{" "}
                                  <span className="form-field-mandatory"></span>
                                </label>
                              </div>
                              {faxErr !== "" ? (
                                <p className="error-msg font-fam-for-all">
                                  {faxErr}
                                </p>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        {isEditing || isEditing1 || isEditing2 ? (
                          <div className="flex justify-content-end pl-3 dialog-form-md-group-Btn">
                            <Button
                              className="mt-2  font-fam-for-all text-center vstate-reverse-button mr-2"
                              style={{ height: "40px" }}
                              onClick={() => {
                                setIsEditing(false);
                                setIsEditing1(false);
                                setIsEditing2(false);
                                setFirstNameErr("");
                                setLastNameErr("");
                                setPhoneErr("");
                                setEmailErr("");
                                setFaxErr("");
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="mt-2  font-fam-for-all text-center vstate-button mr-3"
                              style={{ height: "40px" }}
                              type="submit"
                              onClick={handleSave}
                            >
                              Submit{" "}
                            </Button>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        <div className="card my-3 hover-card slide-in">
                          <div
                            className=" flex px-4 pt-4 pb-0"
                            style={{ justifyContent: "space-between" }}
                          >
                            <h2
                              className="text-lg "
                              style={{ fontWeight: "600" }}
                            >
                              Profile Information
                            </h2>
                            <div className="flex items-center">
                              {isEditing ? (
                                <div></div>
                              ) : (
                                <div className="flex justify-content-end dialog-form-md-group-Btn">
                                  {/* // icon="pi pi-file-edit" */}
                                  <Button
                                    onClick={handleEdit1}
                                    style={{
                                      border: "none",
                                      height: "40px",
                                      backgroundColor: "transparent",
                                      color: "blue",
                                    }}
                                    className="border-none h-10 bg-transparent text-#175CD3 hover:bg-#175CD3 hover:text-blue-800 transition duration-200"
                                  >
                                    {" "}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_1308_27815)">
                                        <path
                                          d="M9.16675 3.33332H3.33341C2.89139 3.33332 2.46746 3.50891 2.1549 3.82147C1.84234 4.13403 1.66675 4.55796 1.66675 4.99999V16.6667C1.66675 17.1087 1.84234 17.5326 2.1549 17.8452C2.46746 18.1577 2.89139 18.3333 3.33341 18.3333H15.0001C15.4421 18.3333 15.866 18.1577 16.1786 17.8452C16.4912 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333M15.4167 2.08332C15.7483 1.7518 16.1979 1.56555 16.6667 1.56555C17.1356 1.56555 17.5852 1.7518 17.9167 2.08332C18.2483 2.41484 18.4345 2.86448 18.4345 3.33332C18.4345 3.80216 18.2483 4.2518 17.9167 4.58332L10.0001 12.5L6.66675 13.3333L7.50008 9.99999L15.4167 2.08332Z"
                                          stroke="#004EEC"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1308_27815">
                                          <rect
                                            width="20"
                                            height="20"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                    <span
                                      style={{ marginLeft: "5px" }}
                                      className="text-#175CD3 font-inter text-sm font-medium leading-5"
                                    >
                                      Edit
                                    </span>
                                  </Button>

                                  {/* <Button
                                    onClick={handleEdit1}
                                    className="edit-button1"
                                    icon="pi pi-pencil"
                                    style={{ border: "none", height: "40px" }}
                                  ></Button> */}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="grid px-4 py-2">
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontWeight: "bold", fontSize: "15px" }}
                            >
                              Salutation
                            </div>
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3 "
                              style={{ fontWeight: "bold", fontSize: "15px" }}
                            >
                              First Name
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontWeight: "bold", fontSize: "15px" }}
                            >
                              Last Name
                            </div>
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontWeight: "bold", fontSize: "15px" }}
                            >
                              Email Address
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontSize: "15px" }}
                            >
                              {data.contactDetails.salutation
                                ? data.contactDetails.salutation
                                : "--"}
                            </div>
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3  capitalize "
                              style={{ fontSize: "15px" }}
                            >
                              {data.contactDetails.firstName ? data.contactDetails.firstName : "--"}
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3 capitalize"
                              style={{ fontSize: "15px" }}
                            >
                              {data.contactDetails.lastName ? data.contactDetails.lastName : "--"}
                            </div>
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontSize: "15px" }}
                            >
                              {data.contactDetails.emailId ? data.contactDetails.emailId : "--"}
                            </div>
                          </div>
                          <div className="grid px-4 py-2">
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontWeight: "bold", fontSize: "15px" }}
                            >
                              Phone Number
                            </div>
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3 "
                              style={{ fontWeight: "bold", fontSize: "15px" }}
                            >
                              Alt. Phone Number
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontWeight: "bold", fontSize: "15px" }}
                            >
                              Fax
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontWeight: "bold", fontSize: "15px" }}
                            ></div>

                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontSize: "15px" }}
                            >
                              {data.contactDetails.mobileNo ? data.contactDetails.mobileNo : "--"}
                            </div>
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3 "
                              style={{ fontSize: "15px" }}
                            >
                              {data.contactDetails.phone ? data.contactDetails.phone : "--"}
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontSize: "15px" }}
                            >
                              {data.contactDetails.fax ? data.contactDetails.fax :"--"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </TabPanel>

                <TabPanel header="Address Details">
                  <div
                    className="col-12 "
                    style={{ backgroundColor: "#fbfcfa", padding: "15px" }}
                  >
                    <div className="font-fam-for-all">
                      {isEditing2 ? (
                        <>
                          <div className="card my-3 hover-card slide-in">
                            <div className="p-4">
                              <h2
                                style={{ fontWeight: "bold", fontSize: "18px" }}
                              >
                                Address Details
                              </h2>
                            </div>

                            <div className="grid px-4 py-2">
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3 "
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                <span className=" mr-3">
                                  <div class="relative">
                                    <InputText
                                      style={{
                                        height: "40px",
                                        borderRadius: "0px 6px 6px 0px",
                                      }}
                                      maxLength={50}
                                      value={addr1}
                                      onChange={(e) => {
                                        setAddr1Err("");
                                        setAddr1(e.target.value);
                                      }}
                                      name="cityName"
                                      id="floating_outlined21"
                                      placeholder=""
                                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    />
                                    <label
                                      htmlFor="floating_outlined21"
                                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                      Address Line 1{" "}
                                      <span className="form-field-mandatory">
                                        *
                                      </span>
                                    </label>
                                  </div>
                                  {addr1Err !== "" ? (
                                    <p className="error-msg font-fam-for-all">
                                      {addr1Err}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                <span className=" mr-3">
                                  <div class="relative">
                                    <InputText
                                      style={{
                                        height: "40px",
                                        borderRadius: "0px 6px 6px 0px",
                                      }}
                                      maxLength={50}
                                      value={addr2}
                                      onChange={(e) => setAddr2(e.target.value)}
                                      name="cityName"
                                      id="floating_outlined22"
                                      placeholder=""
                                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    />
                                    <label
                                      htmlFor="floating_outlined22"
                                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                      Address Line 2{" "}
                                      <span className="form-field-mandatory"></span>
                                    </label>
                                  </div>
                                  {addr2Err !== "" ? (
                                    <p className="error-msg font-fam-for-all">
                                      {addr2Err}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>

                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3 "
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                <span className=" mr-3">
                                  <div class="relative">
                                    <InputText
                                      style={{
                                        height: "40px",
                                        borderRadius: "0px 6px 6px 0px",
                                      }}
                                      maxLength={50}
                                      value={city}
                                      onChange={(e) => {
                                        setcityErr("");
                                        setCity(e.target.value);
                                      }}
                                      name="cityName"
                                      id="floating_outlined24"
                                      placeholder=""
                                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    />
                                    <label
                                      htmlFor="floating_outlined24"
                                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                      City{" "}
                                      <span className="form-field-mandatory">
                                        *
                                      </span>
                                    </label>
                                  </div>
                                  {cityErr !== "" ? (
                                    <p className="error-msg font-fam-for-all">
                                      {cityErr}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>

                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                <span className=" mr-3">
                                  <div class="relative">
                                    <Dropdown
                                      value={states}
                                      filter
                                      resetFilterOnHide
                                      onChange={(e) => {
                                        setStatesErr("");
                                        setStates(e.value);
                                      }}
                                      options={allStates}
                                      optionValue="id"
                                      optionLabel="stateFullDesc"
                                      placeholder="Select a State"
                                      className="w-full "
                                      style={{
                                        height: "40px",
                                        borderRadius: "6px",
                                      }}
                                    />
                                    <label
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "15px",
                                      }}
                                      htmlFor="floating_outlined25"
                                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                      State{" "}
                                      <span className="form-field-mandatory">
                                        *
                                      </span>
                                    </label>
                                  </div>
                                  {statesErr !== "" ? (
                                    <p className="error-msg font-fam-for-all">
                                      {statesErr}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                <span className=" mr-3">
                                  <div class="relative">
                                    <Dropdown
                                      value={countryData}
                                      disabled
                                      filter
                                      resetFilterOnHide
                                      onChange={(e) => setCountryData(e.value)}
                                      options={allCountries}
                                      optionValue="id"
                                      optionLabel="countryFullDesc"
                                      placeholder="Select a Country"
                                      className="w-full"
                                      style={{
                                        height: "40px",
                                        borderRadius: "0px 6px 6px 0px",
                                      }}
                                    />
                                    <label
                                      htmlFor="floating_outlined25"
                                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                      Country{" "}
                                      <span className="form-field-mandatory">
                                        *
                                      </span>
                                    </label>
                                  </div>
                                  {countryErr !== "" ? (
                                    <p className="error-msg font-fam-for-all">
                                      {countryErr}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                <span className=" mr-3">
                                  <div class="relative">
                                    <InputText
                                      style={{
                                        height: "40px",
                                        borderRadius: "0px 6px 6px 0px",
                                      }}
                                      maxLength={5}
                                      value={zip}
                                      type={"text"}
                                      onChange={handleZip}
                                      name="cityName"
                                      id="floating_outlined26"
                                      placeholder=""
                                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    />
                                    <label
                                      htmlFor="floating_outlined26"
                                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                      Zip Code{" "}
                                      <span className="form-field-mandatory">
                                        *
                                      </span>
                                    </label>
                                  </div>
                                  {zipErr !== "" ? (
                                    <p className="error-msg font-fam-for-all">
                                      {zipErr}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            </div>

                            <div className="mb-3">
                              {isEditing || isEditing1 || isEditing2 ? (
                                <div className="flex justify-content-end pl-3 dialog-form-md-group-Btn">
                                  <Button
                                    className="mt-2  font-fam-for-all text-center  vstate-reverse-button mr-2"
                                    style={{ height: "40px" }}
                                    onClick={() => {
                                      setIsEditing(false);
                                      setIsEditing1(false);
                                      setIsEditing2(false);
                                      // setAddr1("")
                                      setAddr1Err("");
                                      // setAddr2("")
                                      setAddr2Err("");
                                      // setCity("")
                                      setcityErr("");
                                      // setRegion("")
                                      setRegionErr("");
                                      // setStates("")
                                      setStatesErr("");
                                      // setZip("")
                                      setZipErr("");
                                      // setAddressType(null)
                                      setCountryErr("");
                                      setAddressTypeErr("");
                                      setCompanyErr("");
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    className="mt-2  font-fam-for-all text-center vstate-button mr-3"
                                    style={{ height: "40px" }}
                                    onClick={handleSave2}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="card my-3 hover-card slide-in">
                            <div
                              className=" flex px-4 pt-4 pb-0"
                              style={{ justifyContent: "space-between" }}
                            >
                              <h2
                                className="text-lg "
                                style={{ fontWeight: "bold" }}
                              >
                                Address Details
                              </h2>
                              <div className="flex items-center">
                                {/* <div className=""> */}
                                {isEditing ? (
                                  <div></div>
                                ) : (
                                  // className="edit-button1"
                                  <div className="flex justify-content-end dialog-form-md-group-Btn">
                                    <Button
                                      onClick={handleEdit2}
                                      // icon="pi pi-file-edit"
                                      style={{
                                        border: "none",
                                        height: "40px",
                                        backgroundColor: "transparent",
                                        color: "blue",
                                      }}
                                      className="border-none h-10 bg-transparent text-#175CD3 hover:bg-#175CD3 hover:text-blue-800 transition duration-200"
                                    >
                                      {" "}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_1308_27815)">
                                          <path
                                            d="M9.16675 3.33332H3.33341C2.89139 3.33332 2.46746 3.50891 2.1549 3.82147C1.84234 4.13403 1.66675 4.55796 1.66675 4.99999V16.6667C1.66675 17.1087 1.84234 17.5326 2.1549 17.8452C2.46746 18.1577 2.89139 18.3333 3.33341 18.3333H15.0001C15.4421 18.3333 15.866 18.1577 16.1786 17.8452C16.4912 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333M15.4167 2.08332C15.7483 1.7518 16.1979 1.56555 16.6667 1.56555C17.1356 1.56555 17.5852 1.7518 17.9167 2.08332C18.2483 2.41484 18.4345 2.86448 18.4345 3.33332C18.4345 3.80216 18.2483 4.2518 17.9167 4.58332L10.0001 12.5L6.66675 13.3333L7.50008 9.99999L15.4167 2.08332Z"
                                            stroke="#004EEC"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_1308_27815">
                                            <rect
                                              width="20"
                                              height="20"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                      <span
                                        style={{ marginLeft: "5px" }}
                                        className="text-#175CD3 font-inter text-sm font-medium leading-5"
                                      >
                                        Edit
                                      </span>
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="grid px-4 py-2">
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                Address Line 1
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3 "
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                Address Line 2
                              </div>

                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                City
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                State
                              </div>

                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontSize: "15px" }}
                              >
                                {addr1 ? addr1 : "--"}
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3 "
                                style={{ fontSize: "15px" }}
                              >
                                {addr2 ? addr2 : "--"}
                              </div>

                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontSize: "15px" }}
                              >
                                {city ? city : "--"}
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontSize: "15px" }}
                              >
                                {getState(states) ? getState(states) : "--"}
                              </div>
                            </div>
                            <div className="grid px-4 py-2">
                              {/* <div className='col-12 col-sm-12 col-md-12 col-lg-3' style={{ fontWeight: "bold", fontSize: "15px" }}>
                                                                State
                                                            </div> */}
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3 "
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                Country
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                Zip Code
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              ></div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "bold", fontSize: "15px" }}
                              ></div>

                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3 "
                                style={{ fontSize: "15px" }}
                              >
                                United States
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontSize: "15px" }}
                              >
                                {zip ? zip : "--"}
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontSize: "15px" }}
                              ></div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </TabPanel>
                {/* {!superAdminId && (
                  <TabPanel header="My Permission">
                    <div
                      className="col-12 "
                      style={{ backgroundColor: "#fbfcfa" }}
                    >
                      <div className="font-fam-for-all">
                        <div className="card my-3 hover-card slide-in1">
                          <div className="p-4">
                            <h2 style={{ fontWeight: "bold", fontSize: "18px" }}>
                              Manage Permission
                            </h2>
                          </div>
                          <div className="grid p-4">
                            <div className="col-12">
                              <GroupDataTable data={data} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                )} */}
              </TabView>
            ) : (
              ""
            )}
          </div>
        </div>
      </Card>

      <Dialog
        visible={isImageVisible}
        className="dialog-box profile-view-dialog-box"
        onHide={hideImageDialog}
      >
        <UploadImage setImageVisible={setImageVisible} data={data} />
      </Dialog>
    </div>
  );
}
