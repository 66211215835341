import React from 'react';
import FeatureCard from './FeatureCard';

 // Import JSON data


 const complianceData = {
  "pageTitle": "Subscriptions Services",
  "pageDescription": "Subscription services, including registered agent services, annual reports, and independent managers or directors, provide ongoing support to ensure your business remains compliant and operational without disruption.",
  "buttonText": "Get Quote",
  "features": [
    {
      "title": "Registered Agent",
      "description": "A service that provides a designated individual or entity to receive legal documents on behalf of a business. This service ensures that the business is compliant with state regulations.",
      "image": "https://example.com/images/boi.jpg"
    },
    {
      "title": "Annual Report",
      "description": "A subscription service that manages the filing of annual reports required by the state to maintain business compliance. It ensures timely submissions to avoid penalties.",
      "image": "https://example.com/images/annual-report.jpg"
    },
    {
      "title": "Independent Manager",
      "description": "A service that provides an independent manager for LLCs to assist with decision-making and compliance requirements. This can be beneficial for liability protection and operational efficiency.",
      "image": "https://example.com/images/reinstatement.jpg"
    },
    {
      "title": "Independent Director",
      "description": "Similar to an independent manager, this service provides an external director to enhance corporate governance and compliance. It adds an objective viewpoint to the board.",
      "image": "https://example.com/images/biennial-report.jpg"
    },
    {
      "title": "Biennial Report",
      "description": "A service that handles the filing of biennial reports required by some states, ensuring compliance and providing updated business information to state authorities.",
      "image": "https://example.com/images/llc-publication.jpg"
    }
    
  ]
}


function SubscriptionsServices() {
  const { pageTitle, pageDescription, buttonText, features } = complianceData;

  return (
    <main className="flex flex-col justify-center p-2.5 w-full max-md:max-w-full">
      <section className="flex flex-col justify-center pb-5 w-full max-md:max-w-full">
        <header className="flex flex-col w-full text-5xl font-medium tracking-tighter leading-tight text-center text-blue-700 max-md:max-w-full">
          <h1 className="px-64 w-full max-md:px-5 max-md:max-w-full">
            {pageTitle}
          </h1>
        </header>
        <p className="mt-5 px-5 w-full text-xl text-center text-slate-800 max-md:max-w-full">
          {pageDescription}
        </p>
        <div className="flex flex-col mt-5 w-full max-md:max-w-full">
          <div className="flex flex-wrap gap-8 w-full min-h-[271px] max-md:max-w-full">
            {features.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </div>
        </div>
      </section>
      <div className="flex flex-col items-center px-32 pt-9 w-full text-base font-medium leading-none text-center text-white max-md:px-5 max-md:max-w-full">
        <button className="px-7 py-3.5 bg-blue-700 rounded shadow-[0px_0px_10px_rgba(0,0,0,0.5)] max-md:px-5">
          {buttonText}
        </button>
      </div>
    </main>
  );
}

export default SubscriptionsServices;
