import axios from "axios";
import authHeader, { initAuth } from "./authHeader";
import encryption from "./CryptoClass";
import propertiesApp from "../utils/propertiesApp";
import "./../services/axiosConfig.js"

const getAllRoles=() => {
    const dataToEncrypt = {
        endpoint: `/core/api/roleMaster/getAll`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const getAllState=() => {
    const dataToEncrypt = {
        endpoint: `/core/api/stateMaster/getAll`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllCountry=() => {
    const dataToEncrypt = {
        endpoint: `/core/api/countryMaster/getAll`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllIndustryType=() => {
    const dataToEncrypt = {
        "endpoint": "/core/api/industryMaster/getAll",
        "type": "get",
        "auth": authHeader()
    }

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}




const updateRoles=(data) => {
    const dataToEncrypt = {
        "endpoint":"/authentication/api/auth/updateUserRoleDetails",
            "payload": 
             data,
            "type": "put",
            "auth": authHeader()
        }

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            return axios.post(`/decrypt`,dataToEncrypt,
            

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}



const rolesMasterService={
    getAllRoles,
    updateRoles,
    getAllState,
    getAllIndustryType,
    getAllCountry
}

export default rolesMasterService;