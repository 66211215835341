import React, { useEffect, useState } from "react";
import { Avatar } from "primereact/avatar"; // Import Avatar component
import AddressService from "../services/addressService";
import { Accordion, AccordionTab } from "primereact/accordion";
import { formatISODate } from "./dateUtils";
import PrincipalAddressCard from "./principalAddress";
import { commonGetInitials } from "./getInitials";

const KeyPersonelCard = ({ data }) => {   
  console.log(data,"raissue")
  const [products, setProducts] = useState([]);

  const [allBeneficialOwner, setAllBeneficialOwner] = useState([]);

  const [allCompanyApplicant, setAllCompanyApplicant] = useState([]);

  const [allState, setAllState] = useState([]);

  const [allKeyPersonalArray, setAllKeyPersonalArray] = useState([]);
  const [registeredAgent, setRegisteredAgent] = useState(null);
  const [principleAddress, setPrincipleAddress] = useState(null);

  const [additionalRegisteredAgentData, setAdditionalRegisteredAgentData] =
    useState([]);

  const [organizerData, setOrganizerData] = useState(null);

  const [tresureData, seTresureData] = useState(null);

  const [secretaryData, setSecretaryData] = useState(null);

  const [presidentData, setPresidentData] = useState(null);

  const [filerData, setFilerData] = useState(null);

  const [incorporateData, setIncorporateData] = useState(null);

  const [officerData, setOfficerData] = useState(null);

  const [directorData, setDirectorData] = useState(null);

  useEffect(() => {
    if (data?.strapiOrderFormJson) {
      try {
        const jsonData = JSON.parse(data.strapiOrderFormJson);
        
        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.filter(
            (val) =>
              val?.type !== "COMPANY APPLICANT" &&
              val?.type !== "BENEFICIAL OWNER" &&
              val?.type !== "ADDITIONAL SECURED PARTY" &&
              val?.type !== "SECURED PARTY" &&
              val?.type !== "ADDITIONAL DEBTOR" &&
              val?.type !== "DEBTOR" &&
              val?.type !== "MEMBER OR MANAGER" &&
              val?.type !== "PRINCIPAL_ADDRESS" &&
              val?.type !== "REGISTER_AGENT" &&
              val?.type !== "RA-BILLING" &&
              val?.type !== "RA-MAILING" &&
              val?.type !== "ORGANIZER" &&
              val?.type !== "TREASURER" &&
              val?.type !== "OFFICER" &&
              val?.type !== "INCORPORATOR" &&
              val?.type !== "DIRECTOR" &&
              val?.type !== "PRESIDENT" &&
              val?.type !== "SECRETARY" &&
              val?.type !== "FILER"
          );
          setProducts(filteredProduct);
        }
        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.filter(
            (val) => val?.type === "RA-BILLING" || val?.type === "RA-MAILING"
          );
          if (filteredProduct) {
            setAdditionalRegisteredAgentData(filteredProduct);
          }
        }

        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.find(
            (val) => val?.type === "REGISTER_AGENT"
          );
          if (filteredProduct) {
            
            setRegisteredAgent(filteredProduct?.data);
          }
        }

        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.find(
            (val) => val?.type === "ORGANIZER"
          );
          if (filteredProduct) {
            setOrganizerData(filteredProduct?.data);
          }
        }

        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.find(
            (val) => val?.type === "PRESIDENT"
          );
          if (filteredProduct) {
            setPresidentData(filteredProduct?.data);
          }
        }

        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.find(
            (val) => val?.type === "FILER"
          );
          if (filteredProduct) {
            setFilerData(filteredProduct?.data);
          }
        }

        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.find(
            (val) => val?.type === "INCORPORATOR"
          );
          if (filteredProduct) {
            setIncorporateData(filteredProduct?.data);
          }
        }

        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.find(
            (val) => val?.type === "OFFICER"
          );
          if (filteredProduct) {
            setOfficerData(filteredProduct?.data);
          }
        }

        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.find(
            (val) => val?.type === "DIRECTOR"
          );
          if (filteredProduct) {
            setDirectorData(filteredProduct?.data);
          }
        }

        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.find(
            (val) => val?.type === "TREASURER"
          );
          if (filteredProduct) {
            seTresureData(filteredProduct?.data);
          }
        }

        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.find(
            (val) => val?.type === "SECRETARY"
          );
          if (filteredProduct) {
            
            setSecretaryData(filteredProduct?.data);
          }
        }
        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.find(
            (val) => val?.type === "PRINCIPAL_ADDRESS"
          );
          if (filteredProduct) {
            
            setPrincipleAddress(filteredProduct?.data);
          }
        }
        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.find(
            (val) => val?.type === "BENEFICIAL OWNER"
          );
          if (filteredProduct) {
            setAllBeneficialOwner(filteredProduct?.data);
          }
        }

        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.filter(
            (val) =>
              val?.type === "ADDITIONAL SECURED PARTY" ||
              val?.type === "SECURED PARTY" ||
              val?.type === "ADDITIONAL DEBTOR" ||
              val?.type === "DEBTOR" ||
              val?.type === "MEMBER OR MANAGER"
          );
          if (filteredProduct) {
            const data = filteredProduct.flatMap((val) => val?.data);
            
            setAllKeyPersonalArray(filteredProduct);
          }
        }
        if (
          jsonData?.keyPersonalStrapiArr &&
          Array.isArray(jsonData.keyPersonalStrapiArr)
        ) {
          const filteredProduct = jsonData.keyPersonalStrapiArr.find(
            (val) => val?.type === "COMPANY APPLICANT"
          );
          if (filteredProduct) {
            setAllCompanyApplicant(filteredProduct?.data);
          }
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, [data]);

  useEffect(() => {
    AddressService.getAllStates()
      .then((res) => {
        
        if (res.data !== "") {
          setAllState(res.data);
        }
      })
      .catch((err) => {
        
      });
  }, []);



  const getState = (id) => {
    const temp = allState.find((val) => val.id === id);
    if (temp !== undefined) {
      return temp.stateFullDesc;
    } else {
      return null;
    }
  };

  const getStateBySortName = (param) => {
    const temp = allState.find((val) => val.stateShortName === param);
    if (temp !== undefined) {
      return temp.stateFullDesc;
    } else {
      return null;
    }
  };

  return (
    <div className="p-4">
      <div className=" mb-4">
        {allBeneficialOwner.map((val, index) => (
          <>
            <Accordion activeIndex={0}>
              <AccordionTab header={`Beneficial Owner ${index + 1}`}>
                <div className="col-12" key={index}>
                  <div className="order-contact-card-container">
                    <div className="order-contact-card-main">
                      <div className="order-contact-card-header-main capitalize">
                        <Avatar
                          label={commonGetInitials(
                            val?.firstName + "" + val?.lastName
                          )}
                          icon="pi pi-user"
                          size="large"
                          shape="circle"
                          className="custom-avatar"
                        />
                        <div>
                          <div className="order-contact-card-header-text1 capitalize ">
                            {val?.firstName + "" + val?.lastName}
                          </div>
                          <div className="order-contact-card-header-text2">
                            Beneficial Owner
                          </div>
                        </div>
                      </div>
                      <div className="card-devider"> </div>
                      <div className="order-contact-card-text-main">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                              stroke={
                                val?.contactNumber ? "#175CD3" : "#98A2B3"
                              }
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="order-contact-card-text1">
                          {val?.contactNumber || "Add Contact Number here"}
                        </div>
                      </div>

                      <div className="order-contact-card-text-main">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                              stroke={val?.email ? "#175CD3" : "#98A2B3"}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="order-contact-card-text1">
                          {val?.email || "Add Email here"}
                        </div>
                      </div>

                      <div className="order-contact-card-text-main">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                              stroke={
                                val?.residentialAddress ||
                                  val?.city ||
                                  val?.state ||
                                  val?.zipCode
                                  ? "#175CD3"
                                  : "#98A2B3"
                              }
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                              stroke={
                                val?.residentialAddress ||
                                  val?.city ||
                                  val?.state ||
                                  val?.zipCode
                                  ? "#175CD3"
                                  : "#98A2B3"
                              }
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="order-contact-card-text1 text-wrap">
                          {[
                            val?.residentialAddress,
                            val?.city,
                            getState(val?.state),
                            val?.zipCode,
                          ]
                            .filter(Boolean)
                            .join(", ") || "Add Address here"}
                        </div>
                      </div>

                      <div className="grid preview-card w-[100%] p-4 mb-2">
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">Exempt Entity</div>
                          <div className="order-preview-value">
                            {val?.exemptEntity ? "Yes" : "No"}
                          </div>
                        </div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">
                            Have FinCEN ID
                          </div>
                          <div className="order-preview-value">
                            {val?.haveFinCenId ? "Yes" : "No"}
                          </div>
                        </div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">
                            FinCEN ID Number
                          </div>
                          <div className="order-preview-value">
                            {val?.finCenIdNumber ? val?.finCenIdNumber : "--"}
                          </div>
                        </div>

                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">Date Of Birth</div>
                          <div className="order-preview-value">
                            {val?.dateOfBirth
                              ? formatISODate(val?.dateOfBirth)
                              : "--"}
                          </div>
                        </div>

                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">
                            Identify Document Number
                          </div>
                          <div className="order-preview-value">
                            {val?.identifyingDocumentNo
                              ? val?.identifyingDocumentNo
                              : "--"}
                          </div>
                        </div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">
                            Issuing Jurisdiction Country
                          </div>
                          <div className="order-preview-value">
                            {val?.issuingJurisdictionCountry
                              ? val?.issuingJurisdictionCountry
                              : "--"}
                          </div>
                        </div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">
                            Issuing Jurisdiction State
                          </div>
                          <div className="order-preview-value">
                            {val?.issuingJurisdictionState
                              ? getStateBySortName(
                                val?.issuingJurisdictionState
                              )
                              : "--"}
                          </div>
                        </div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">
                            Last Name Or Business Legal Name
                          </div>
                          <div className="order-preview-value">
                            {val?.lastNameOrBusinessLegalName
                              ? val?.lastNameOrBusinessLegalName
                              : "--"}
                          </div>
                        </div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">Document Type</div>
                          <div className="order-preview-value">
                            {val?.documentType ? val?.documentType : "--"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
          </>
        ))}
      </div>
      <div className=" mb-4">
        {allCompanyApplicant.map((val, index) => (
          <>
            <Accordion activeIndex={0}>
              <AccordionTab header={`Company Applicant ${index + 1}`}>
                <div className="col-12" key={index}>
                  <div className="order-contact-card-container">
                    <div className="order-contact-card-main">
                      <div className="order-contact-card-header-main capitalize">
                        <Avatar
                          label={commonGetInitials(
                            val?.firstName + "" + val?.lastName
                          )}
                          icon="pi pi-user"
                          size="large"
                          shape="circle"
                          className="custom-avatar"
                        />
                        <div>
                          <div className="order-contact-card-header-text1 capitalize">
                            {val?.firstName + "" + val?.lastName}
                          </div>
                          <div className="order-contact-card-header-text2">
                            Company Applicant
                          </div>
                        </div>
                      </div>
                      <div className="card-devider"> </div>
                      <div className="order-contact-card-text-main">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                              stroke={
                                val?.contactNumber ? "#175CD3" : "#98A2B3"
                              }
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="order-contact-card-text1">
                          {val?.contactNumber || "Add Contact Number here"}
                        </div>
                      </div>

                      <div className="order-contact-card-text-main">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                              stroke={val?.email ? "#175CD3" : "#98A2B3"}
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="order-contact-card-text1">
                          {val?.email || "Add Email here"}
                        </div>
                      </div>

                      <div className="order-contact-card-text-main">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                              stroke={
                                val?.residentialAddress ||
                                  val?.city ||
                                  val?.state ||
                                  val?.zipCode
                                  ? "#175CD3"
                                  : "#98A2B3"
                              }
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                              stroke={
                                val?.residentialAddress ||
                                  val?.city ||
                                  val?.state ||
                                  val?.zipCode
                                  ? "#175CD3"
                                  : "#98A2B3"
                              }
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        <div className="order-contact-card-text1 text-wrap">
                          {[
                            val?.residentialAddress,
                            val?.city,
                            getState(val?.state),
                            val?.zipCode,
                          ]
                            .filter(Boolean)
                            .join(", ") || "Add Address here"}
                        </div>
                      </div>

                      <div className="grid preview-card w-[100%] p-4 mb-2">
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">Exempt Entity</div>
                          <div className="order-preview-value">
                            {val?.exemptEntity ? "Yes" : "No"}
                          </div>
                        </div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">
                            Have FinCEN ID
                          </div>
                          <div className="order-preview-value">
                            {val?.haveFinCenId ? "Yes" : "No"}
                          </div>
                        </div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">
                            FinCEN ID Number
                          </div>
                          <div className="order-preview-value">
                            {val?.finCenIdNumber ? val?.finCenIdNumber : "--"}
                          </div>
                        </div>

                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">Date Of Birth</div>
                          <div className="order-preview-value">
                            {val?.dateOfBirth
                              ? formatISODate(val?.dateOfBirth)
                              : "--"}
                          </div>
                        </div>

                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">
                            Identify Document Number
                          </div>
                          <div className="order-preview-value">
                            {val?.identifyingDocumentNo
                              ? val?.identifyingDocumentNo
                              : "--"}
                          </div>
                        </div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">
                            Issuing Jurisdiction Country
                          </div>
                          <div className="order-preview-value">
                            {val?.issuingJurisdictionCountry
                              ? val?.issuingJurisdictionCountry
                              : "--"}
                          </div>
                        </div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">
                            Issuing Jurisdiction State
                          </div>
                          <div className="order-preview-value">
                            {val?.issuingJurisdictionState
                              ? getStateBySortName(
                                val?.issuingJurisdictionState
                              )
                              : "--"}
                          </div>
                        </div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">
                            Last Name Or Business Legal Name
                          </div>
                          <div className="order-preview-value">
                            {val?.lastNameOrBusinessLegalName
                              ? val?.lastNameOrBusinessLegalName
                              : "--"}
                          </div>
                        </div>
                        <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                          <div className="order-preview-key">Document Type</div>
                          <div className="order-preview-value">
                            {val?.documentType ? val?.documentType : "--"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AccordionTab>
            </Accordion>
          </>
        ))}
      </div>

      {/* Principal Address */}
      {principleAddress && (
        <Accordion activeIndex={0}>
          <AccordionTab header={`Principal Address`}>
            <div className="grid gap-2 justify-content-start">
              <PrincipalAddressCard
                data={principleAddress}
                getState={getState}
              />
            </div>
          </AccordionTab>
        </Accordion>
      )}

      {/* INCORPORATOR */}

      {incorporateData && (
        <Accordion activeIndex={0} className="mt-3">
          <AccordionTab header={`Incorportator`}>
            <div className="grid gap-2 justify-content-start">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="order-contact-card-container">
                  <div className="order-contact-card-main">
                    <div className="order-contact-card-header-main">
                      <Avatar
                        label={commonGetInitials(incorporateData?.keyPersonnelName)}
                        icon="pi pi-user"
                        size="large"
                        shape="circle"
                        className="custom-avatar"
                      />
                      <div>
                        <div className="order-contact-card-header-text1">
                          {incorporateData?.keyPersonnelName
                            ? incorporateData?.keyPersonnelName
                            : "--"}
                        </div>
                        <div className="order-contact-card-header-text2">
                          INCORPORATOR
                        </div>
                      </div>
                    </div>
                    <div className="card-devider"> </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_966_10329)">
                            <path
                              d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                              stroke="#175CD3"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_966_10329">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {incorporateData?.contactNo
                          ? incorporateData?.contactNo
                          : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                            stroke="#175CD3"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {incorporateData?.emailId
                          ? incorporateData?.emailId
                          : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1 text-wrap">
                        {incorporateData?.addressMasterId?.addressLine1
                          ? `${incorporateData?.addressMasterId.addressLine1
                          }, ${incorporateData?.addressMasterId.addressLine2 ||
                          ""
                          } ${incorporateData?.addressMasterId.city
                          }, ${getState(
                            incorporateData?.addressMasterId.stateId
                          )}, ${incorporateData?.addressMasterId.postalCode}`
                          : "No Address Available"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      )}

      {/* OFFICER */}

      {officerData && (
        <Accordion activeIndex={0} className="mt-3">
          <AccordionTab header={`OFFICER`}>
            <div className="grid gap-2 justify-content-start">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="order-contact-card-container">
                  <div className="order-contact-card-main">
                    <div className="order-contact-card-header-main">
                      <Avatar
                        label={commonGetInitials(officerData?.keyPersonnelName)}
                        icon="pi pi-user"
                        size="large"
                        shape="circle"
                        className="custom-avatar"
                      />
                      <div>
                        <div className="order-contact-card-header-text1">
                          {officerData?.keyPersonnelName
                            ? officerData?.keyPersonnelName
                            : "--"}
                        </div>
                        <div className="order-contact-card-header-text2">
                          OFFICER
                        </div>
                      </div>
                    </div>
                    <div className="card-devider"> </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_966_10329)">
                            <path
                              d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                              stroke="#175CD3"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_966_10329">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {officerData?.contactNo
                          ? officerData?.contactNo
                          : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                            stroke="#175CD3"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {officerData?.emailId
                          ? officerData?.emailId
                          : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1 text-wrap">
                        {officerData?.addressMasterId?.addressLine1
                          ? `${officerData?.addressMasterId.addressLine1
                          }, ${officerData?.addressMasterId.addressLine2 ||
                          ""
                          } ${officerData?.addressMasterId.city
                          }, ${getState(
                            officerData?.addressMasterId.stateId
                          )}, ${officerData?.addressMasterId.postalCode}`
                          : "No Address Available"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      )}

      {/* DIRECTOR */}

      {directorData && (
        <Accordion activeIndex={0} className="mt-3">
          <AccordionTab header={`DIRECTOR`}>
            <div className="grid gap-2 justify-content-start">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="order-contact-card-container">
                  <div className="order-contact-card-main">
                    <div className="order-contact-card-header-main">
                      <Avatar
                        label={commonGetInitials(directorData?.keyPersonnelName)}
                        icon="pi pi-user"
                        size="large"
                        shape="circle"
                        className="custom-avatar"
                      />
                      <div>
                        <div className="order-contact-card-header-text1">
                          {directorData?.keyPersonnelName
                            ? directorData?.keyPersonnelName
                            : "--"}
                        </div>
                        <div className="order-contact-card-header-text2">
                          DIRECTOR
                        </div>
                      </div>
                    </div>
                    <div className="card-devider"> </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_966_10329)">
                            <path
                              d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                              stroke="#175CD3"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_966_10329">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {directorData?.contactNo
                          ? directorData?.contactNo
                          : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                            stroke="#175CD3"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {directorData?.emailId
                          ? directorData?.emailId
                          : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1 text-wrap">
                        {directorData?.addressMasterId?.addressLine1
                          ? `${directorData?.addressMasterId.addressLine1
                          }, ${directorData?.addressMasterId.addressLine2 ||
                          ""
                          } ${directorData?.addressMasterId.city
                          }, ${getState(
                            directorData?.addressMasterId.stateId
                          )}, ${directorData?.addressMasterId.postalCode}`
                          : "No Address Available"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      )}

      {/* Registered Agent */}
      {registeredAgent && (
        <Accordion activeIndex={0} className="mt-3">
          <AccordionTab header={`Registered Agent`}>
            <div className="grid gap-2 justify-content-start">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="order-contact-card-container">
                  <div className="order-contact-card-main">
                    <div className="order-contact-card-header-main">
                      <Avatar
                        label={commonGetInitials(
                          registeredAgent?.registeredAgencyName
                        )}
                        icon="pi pi-user"
                        size="large"
                        shape="circle"
                        className="custom-avatar"
                      />
                      <div>
                        <div className="order-contact-card-header-text1">
                          {registeredAgent?.registeredAgencyName
                            ? registeredAgent?.registeredAgencyName
                            : "--"}
                        </div>
                        <div className="order-contact-card-header-text2">
                          REGISTER AGENT
                        </div>
                        <div className='order-contact-card-header-text2'>
                          vState Filings
                        </div>
                      </div>
                    </div>
                    <div className="card-devider"> </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_966_10329)">
                            <path
                              d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                              stroke="#175CD3"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_966_10329">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {registeredAgent?.phoneNo
                          ? registeredAgent?.phoneNo
                          : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                            stroke="#175CD3"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {registeredAgent?.emailId
                          ? registeredAgent?.emailId
                          : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1 text-wrap">
                        {registeredAgent.addressLine1
                          ? `${registeredAgent.addressLine1}, ${registeredAgent.addressLine2 || ""
                          } ${registeredAgent?.city
                          }, ${
                            registeredAgent.stateMasterId?.stateFullDesc
                          }, ${registeredAgent.zipcode}`
                          : "No Address Available"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {additionalRegisteredAgentData.map(
                (val, index) =>
                  val?.data && (
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6"
                      key={index}
                    >
                      <div className="order-contact-card-container">
                        <div className="order-contact-card-main">
                          <div className="order-contact-card-header-main">
                            <Avatar
                              label={commonGetInitials(val?.data?.keyPersonnelName)}
                              icon="pi pi-user"
                              size="large"
                              shape="circle"
                              className="custom-avatar"
                            />
                            <div>
                              <div className="order-contact-card-header-text1">
                                {val?.data?.keyPersonnelName
                                  ? val?.data?.keyPersonnelName
                                  : "--"}
                              </div>
                              <div className="order-contact-card-header-text2">
                                {val?.type ? val?.type : "--"}
                              </div>
                            </div>
                          </div>
                          <div className="card-devider"> </div>
                          <div className="order-contact-card-text-main">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_966_10329)">
                                  <path
                                    d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                    stroke="#175CD3"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_966_10329">
                                    <rect width="20" height="20" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div className="order-contact-card-text1">
                              {val?.data?.contactNo
                                ? val?.data?.contactNo
                                : "--"}
                            </div>
                          </div>
                          <div className="order-contact-card-text-main">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                  stroke="#175CD3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="order-contact-card-text1">
                              {val?.data?.emailId ? val?.data?.emailId : "--"}
                            </div>
                          </div>
                          <div className="order-contact-card-text-main">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                  stroke="#175CD3"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                  stroke="#175CD3"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="order-contact-card-text1 text-wrap">
                              {val?.data?.addressMasterId?.addressLine1
                                ? `${val?.data?.addressMasterId.addressLine1
                                }, ${val?.data?.addressMasterId.addressLine2 ||
                                ""
                                } ${val?.data?.addressMasterId.city
                                }, ${getState(
                                  val?.data?.addressMasterId.stateId
                                )}, ${val?.data?.addressMasterId.postalCode}`
                                : "No Address Available"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </AccordionTab>
        </Accordion>
      )}

      {/* Oragnizer Information */}

      {organizerData && (
        <Accordion activeIndex={0} className="mt-3">
          <AccordionTab header={`Organizer Information`}>
            <div className="grid gap-2 justify-content-start">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="order-contact-card-container">
                  <div className="order-contact-card-main">
                    <div className="order-contact-card-header-main">
                      <Avatar
                        label={commonGetInitials(organizerData?.keyPersonnelName)}
                        icon="pi pi-user"
                        size="large"
                        shape="circle"
                        className="custom-avatar"
                      />
                      <div>
                        <div className="order-contact-card-header-text1">
                          {organizerData?.keyPersonnelName
                            ? organizerData?.keyPersonnelName
                            : "--"}
                        </div>
                        <div className="order-contact-card-header-text2">
                          ORGANIZER
                        </div>
                      </div>
                    </div>
                    <div className="card-devider"> </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_966_10329)">
                            <path
                              d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                              stroke="#175CD3"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_966_10329">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {organizerData?.contactNo
                          ? organizerData?.contactNo
                          : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                            stroke="#175CD3"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {organizerData?.emailId ? organizerData?.emailId : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1 text-wrap">
                        {organizerData?.addressMasterId?.addressLine1
                          ? `${organizerData?.addressMasterId.addressLine1}, ${organizerData?.addressMasterId.addressLine2 || ""
                          } ${organizerData?.addressMasterId.city
                          }, ${getState(
                            organizerData?.addressMasterId.stateId
                          )}, ${organizerData?.addressMasterId.postalCode}`
                          : "No Address Available"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      )}

      {/* President */}

      {presidentData && (
        <Accordion activeIndex={0} className="mt-3">
          <AccordionTab header={`PRESIDENT`}>
            <div className="grid gap-2 justify-content-start">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="order-contact-card-container">
                  <div className="order-contact-card-main">
                    <div className="order-contact-card-header-main">
                      <Avatar
                        label={commonGetInitials(presidentData?.keyPersonnelName)}
                        icon="pi pi-user"
                        size="large"
                        shape="circle"
                        className="custom-avatar"
                      />
                      <div>
                        <div className="order-contact-card-header-text1">
                          {presidentData?.keyPersonnelName
                            ? presidentData?.keyPersonnelName
                            : "--"}
                        </div>
                        <div className="order-contact-card-header-text2">
                          PRESIDENT
                        </div>
                      </div>
                    </div>
                    <div className="card-devider"> </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_966_10329)">
                            <path
                              d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                              stroke="#175CD3"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_966_10329">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {presidentData?.contactNo
                          ? presidentData?.contactNo
                          : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                            stroke="#175CD3"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {presidentData?.emailId ? presidentData?.emailId : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1 text-wrap">
                        {presidentData?.addressMasterId?.addressLine1
                          ? `${presidentData?.addressMasterId.addressLine1}, ${presidentData?.addressMasterId.addressLine2 || ""
                          } ${presidentData?.addressMasterId.city
                          }, ${getState(
                            presidentData?.addressMasterId.stateId
                          )}, ${presidentData?.addressMasterId.postalCode}`
                          : "No Address Available"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      )}

      {/* TREASURER */}

      {tresureData && (
        <Accordion activeIndex={0} className="mt-3">
          <AccordionTab header={`Tresurer`}>
            <div className="grid gap-2 justify-content-start">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="order-contact-card-container">
                  <div className="order-contact-card-main">
                    <div className="order-contact-card-header-main">
                      <Avatar
                        label={commonGetInitials(tresureData?.keyPersonnelName)}
                        icon="pi pi-user"
                        size="large"
                        shape="circle"
                        className="custom-avatar"
                      />
                      <div>
                        <div className="order-contact-card-header-text1">
                          {tresureData?.keyPersonnelName
                            ? tresureData?.keyPersonnelName
                            : "--"}
                        </div>
                        <div className="order-contact-card-header-text2">
                          TREASURER
                        </div>
                      </div>
                    </div>
                    <div className="card-devider"> </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_966_10329)">
                            <path
                              d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                              stroke="#175CD3"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_966_10329">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {tresureData?.contactNo ? tresureData?.contactNo : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                            stroke="#175CD3"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {tresureData?.emailId ? tresureData?.emailId : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1 text-wrap">
                        {tresureData?.addressMasterId?.addressLine1
                          ? `${tresureData?.addressMasterId.addressLine1}, ${tresureData?.addressMasterId.addressLine2 || ""
                          } ${tresureData?.addressMasterId.city}, ${getState(
                            tresureData?.addressMasterId.stateId
                          )}, ${tresureData?.addressMasterId.postalCode}`
                          : "No Address Available"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      )}

      {/* SECRETARY */}

      {secretaryData && (
        <Accordion activeIndex={0} className="mt-3" >
          <AccordionTab header={`Secretary`}>
            <div className="grid gap-2 justify-content-start">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="order-contact-card-container">
                  <div className="order-contact-card-main">
                    <div className="order-contact-card-header-main">
                      <Avatar
                        label={commonGetInitials(secretaryData?.keyPersonnelName)}
                        icon="pi pi-user"
                        size="large"
                        shape="circle"
                        className="custom-avatar"
                      />
                      <div>
                        <div className="order-contact-card-header-text1">
                          {secretaryData?.keyPersonnelName
                            ? secretaryData?.keyPersonnelName
                            : "--"}
                        </div>
                        <div className="order-contact-card-header-text2">
                          SECRETARY
                        </div>
                      </div>
                    </div>
                    <div className="card-devider"> </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_966_10329)">
                            <path
                              d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                              stroke="#175CD3"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_966_10329">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {secretaryData?.contactNo
                          ? secretaryData?.contactNo
                          : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                            stroke="#175CD3"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {secretaryData?.emailId ? secretaryData?.emailId : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1 text-wrap">
                        {secretaryData?.addressMasterId?.addressLine1
                          ? `${secretaryData?.addressMasterId.addressLine1}, ${secretaryData?.addressMasterId.addressLine2 || ""
                          } ${secretaryData?.addressMasterId.city
                          }, ${getState(
                            secretaryData?.addressMasterId.stateId
                          )}, ${secretaryData?.addressMasterId.postalCode}`
                          : "No Address Available"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      )}

      {/* Filer */}

      {filerData && (
        <Accordion activeIndex={0} className="mt-3">
          <AccordionTab header={`Filer`}>
            <div className="grid gap-2 justify-content-start">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="order-contact-card-container">
                  <div className="order-contact-card-main">
                    <div className="order-contact-card-header-main">
                      <Avatar
                        label={commonGetInitials(filerData?.keyPersonnelName)}
                        icon="pi pi-user"
                        size="large"
                        shape="circle"
                        className="custom-avatar"
                      />
                      <div>
                        <div className="order-contact-card-header-text1">
                          {filerData?.keyPersonnelName
                            ? filerData?.keyPersonnelName
                            : "--"}
                        </div>
                        <div className="order-contact-card-header-text2">
                          FILER
                        </div>
                      </div>
                    </div>
                    <div className="card-devider"> </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_966_10329)">
                            <path
                              d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                              stroke="#175CD3"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_966_10329">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {filerData?.contactNo ? filerData?.contactNo : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                            stroke="#175CD3"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1">
                        {filerData?.emailId ? filerData?.emailId : "--"}
                      </div>
                    </div>
                    <div className="order-contact-card-text-main">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                            stroke="#175CD3"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="order-contact-card-text1 text-wrap">
                        {filerData?.addressMasterId?.addressLine1
                          ? `${filerData?.addressMasterId.addressLine1}, ${filerData?.addressMasterId.addressLine2 || ""
                          } ${filerData?.addressMasterId.city}, ${getState(
                            filerData?.addressMasterId.stateId
                          )}, ${filerData?.addressMasterId.postalCode}`
                          : "No Address Available"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      )}

      {/* Additional Contact */}

      <Accordion activeIndex={0} className="mt-3">
        <AccordionTab header={`Additional Contacts`}>
          <div className="grid gap-2 justify-content-start">
            {products.map(
              (val, index) =>
                val?.data && (
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-6"
                    key={index}
                  >
                    <div className="order-contact-card-container">
                      <div className="order-contact-card-main">
                        <div className="order-contact-card-header-main">
                          <Avatar
                            label={commonGetInitials(val?.data?.keyPersonnelName)}
                            icon="pi pi-user"
                            size="large"
                            shape="circle"
                            className="custom-avatar"
                          />
                          <div>
                            <div className="order-contact-card-header-text1">
                              {val?.data?.keyPersonnelName
                                ? val?.data?.keyPersonnelName
                                : "--"}
                            </div>
                            <div className="order-contact-card-header-text2">
                              {val?.type ? val?.type : "--"}
                            </div>
                          </div>
                        </div>
                        <div className="card-devider"> </div>
                        <div className="order-contact-card-text-main">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <g clip-path="url(#clip0_966_10329)">
                                <path
                                  d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                  stroke="#175CD3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_966_10329">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div className="order-contact-card-text1">
                            {val?.data?.contactNo ? val?.data?.contactNo : "--"}
                          </div>
                        </div>
                        <div className="order-contact-card-text-main">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                stroke="#175CD3"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="order-contact-card-text1">
                            {val?.data?.emailId ? val?.data?.emailId : "--"}
                          </div>
                        </div>
                        <div className="order-contact-card-text-main">
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <path
                                d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                stroke="#175CD3"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                stroke="#175CD3"
                                stroke-width="1.66667"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          <div className="order-contact-card-text1 text-wrap">
                            {val?.data?.addressMasterId?.addressLine1
                              ? `${val?.data?.addressMasterId.addressLine1}, ${val?.data?.addressMasterId.addressLine2 || ""
                              } ${val?.data?.addressMasterId.city
                              }, ${getState(
                                val?.data?.addressMasterId.stateId
                              )}, ${val?.data?.addressMasterId.postalCode}`
                              : "No Address Available"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </AccordionTab>
      </Accordion>

      <div className="my-2"></div>
      {allKeyPersonalArray.map((ele) => (
        <Accordion activeIndex={0} className="mt-3">
          <AccordionTab header={`${ele?.type}`}>
            <div className="grid gap-2 justify-content-start">
              {ele?.data.map(
                (val, index) =>
                  val && (
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6"
                      key={index}
                    >
                      <div className="order-contact-card-container">
                        <div className="order-contact-card-main">
                          <div className="order-contact-card-header-main">
                            <Avatar
                              label={commonGetInitials(val?.keyPersonnelName)}
                              icon="pi pi-user"
                              size="large"
                              shape="circle"
                              className="custom-avatar"
                            />
                            <div>
                              <div className="order-contact-card-header-text1">
                                {val?.keyPersonnelName
                                  ? val?.keyPersonnelName
                                  : "--"}
                              </div>
                              <div className="order-contact-card-header-text2">
                                {ele?.type
                                  ? val?.member_or_manager_value || ele?.type
                                  : "--"}
                              </div>
                            </div>
                          </div>
                          <div className="card-devider"> </div>
                          <div className="order-contact-card-text-main">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_966_10329)">
                                  <path
                                    d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                    stroke="#175CD3"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_966_10329">
                                    <rect width="20" height="20" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <div className="order-contact-card-text1">
                              {val?.contactNo ? val?.contactNo : "--"}
                            </div>
                          </div>
                          <div className="order-contact-card-text-main">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                  stroke="#175CD3"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="order-contact-card-text1">
                              {val?.emailId ? val?.emailId : "--"}
                            </div>
                          </div>
                          <div className="order-contact-card-text-main">
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                  stroke="#175CD3"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                  stroke="#175CD3"
                                  stroke-width="1.66667"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                            <div className="order-contact-card-text1 text-wrap">
                              {val?.addressMasterId?.addressLine1
                                ? `${val?.addressMasterId.addressLine1}, ${val?.addressMasterId.addressLine2 || ""
                                } ${val?.addressMasterId.city}, ${getState(
                                  val?.addressMasterId.stateId
                                )}, ${val?.addressMasterId.postalCode}`
                                : "No Address Available"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </AccordionTab>
        </Accordion>
      ))}
    </div>
  );
};

export default KeyPersonelCard;
