import React from 'react';
import { useNavigate } from 'react-router-dom';

function SidesbarItem({ icon, label ,path}) {
  const history=useNavigate()
  return (
    <button onClick={()=>history(path)} className='sidesbar-button'>

      <div className="flex flex-col px-3 pt-2 w-full">
        <div className="flex justify-center items-center self-center px-3 w-12 h-12 rounded-md">
          <img loading="lazy" src={icon} alt={`${label} icon`} width={24} height={24} className=" aspect-square" />
        </div>
        
      </div>
      <div className="mt-1 w-full leading-6 text-center sidebar-items-text">
          {label}
        </div>
    </button>
  );
}

export default SidesbarItem;