import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React from 'react'

const PrincipalAddressCard = ({data,getState}) => {

    const stateBody =(rowData)=>{
        return (
            <>{getState(rowData?.stateId)}</>
        )
    }
  return (
    <div className='mb-4'>
         <DataTable
                  value={[data]}
                  scrollable
                  emptyMessage="No Order Found"
                  dataKey="name"
                //   paginator
                //   rows={25}
                //   rowsPerPageOptions={[5, 10, 25, 50]}
                  className='font-fam-for-all '
                  scrollHeight="flex"
                  scrollWidth="100%"
                  globalFilterFields={["djjddjjd"]}
                >
                
                  <Column
                    field="addressLine1"
                    header="Address Line 1"
                    className="font-fam-for-all datatable-lg-col webapp-sm-size"
                  ></Column>
                  <Column
                    field="addressLine2"
                    header="Address Line 2"
                    className="font-fam-for-all  webapp-sm-size"
                  ></Column>
                  <Column
                    field="city"
                    header="City"
                    className="font-fam-for-all  webapp-sm-size"
                  ></Column>
                  <Column
                    header="State"
                    body={stateBody}
                    className="font-fam-for-all datatable-lg-col webapp-sm-size"
                  ></Column>
                  <Column
                    header="Zip Code"
                    field='postalCode'
                    className="font-fam-for-all datatable-lg-col webapp-sm-size"
                  ></Column>
                 
                </DataTable>
    </div>
  )
}

export default PrincipalAddressCard;