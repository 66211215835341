import React from 'react';
import FeatureCard from './FeatureCard';

 // Import JSON data


 const complianceData = {
  "pageTitle": "Corporate Filings Services",
  "pageDescription": "Corporate filings include essential services like foreign qualifications, mergers, conversions, and dissolutions. These are required for major changes and expansions, ensuring your business operates legally across jurisdictions.",
  "buttonText": "Get Quote",
  "features": [
    {
      "title": "Foreign Qualification",
      "description": "The process of registering an out-of-state business to legally operate in another state. It involves filing specific documents and paying fees to comply with state regulations.",
      "image": "https://example.com/images/boi.jpg"
    },
    {
      "title": "LLP (Limited Liability Partnership)",
      "description": "A partnership structure that provides limited liability to partners, protecting personal assets from business debts. Forming an LLP requires a formal registration process with the state.",
      "image": "https://example.com/images/annual-report.jpg"
    },
    {
      "title": "LP (Limited Partnership)",
      "description": "A partnership consisting of general partners with unlimited liability and limited partners with liability protection. Establishing an LP involves filing a Certificate of Limited Partnership.",
      "image": "https://example.com/images/reinstatement.jpg"
    },
    {
      "title": "Non-Profit",
      "description": "An organization formed for charitable, educational, or social purposes that qualifies for tax-exempt status. Formation includes filing Articles of Incorporation and obtaining 501(c)(3) status.",
      "image": "https://example.com/images/biennial-report.jpg"
    },
    {
      "title": "501(c)(3)",
      "description": "A specific type of non-profit organization recognized by the IRS as tax-exempt. It involves rigorous compliance and application procedures to maintain tax benefits.",
      "image": "https://example.com/images/llc-publication.jpg"
    },
    {
      "title": "Dissolution",
      "description": "The formal process of closing a business entity. This involves filing necessary documents with the state and ensuring all obligations are settled.",
      "image": "https://example.com/images/liquor-license.jpg"
    },
    {
      "title": "Amendment",
      "description": "A filing to change specific details of an existing business entity, such as its name or structure. Amendments require submitting the appropriate forms and fees to state authorities.",
      "image": "https://example.com/images/foundation-publication.jpg"
    },
    {
      "title": "S-Corp Election",
      "description": "A tax designation allowing a corporation to be taxed as an S corporation, enabling pass-through taxation. Filing Form 2553 with the IRS is required to elect this status.",
      "image": "https://example.com/images/sidewalk-cafe.jpg"
    },
    {
      "title": "Conversion",
      "description": "The process of changing a business entity from one type to another (e.g., from an LLC to a Corporation). This involves specific filings and compliance with state laws.",
      "image": "https://example.com/images/dba-publication.jpg"
    },
    {
        "title": "Merger",
        "description": "The legal combination of two or more entities into one, requiring detailed documentation and approval by state authorities.",
        "image": "https://example.com/images/dba-publication.jpg"
      },
      {
        "title": "Reinstatement",
        "description": "The process of restoring a business’s status after it has been revoked due to non-compliance. This typically involves filing reinstatement forms and paying fees.",
        "image": "https://example.com/images/dba-publication.jpg"
      }
  ]
}


function CorporateFilingsServices() {
  const { pageTitle, pageDescription, buttonText, features } = complianceData;

  return (
    <main className="flex flex-col justify-center p-2.5 w-full max-md:max-w-full">
      <section className="flex flex-col justify-center pb-5 w-full max-md:max-w-full">
        <header className="flex flex-col w-full text-5xl font-medium tracking-tighter leading-tight text-center text-blue-700 max-md:max-w-full">
          <h1 className="px-64 w-full max-md:px-5 max-md:max-w-full">
            {pageTitle}
          </h1>
        </header>
        <p className="mt-5 px-5 w-full text-xl text-center text-slate-800 max-md:max-w-full">
          {pageDescription}
        </p>
        <div className="flex flex-col mt-5 w-full max-md:max-w-full">
          <div className="flex flex-wrap gap-8 w-full min-h-[271px] max-md:max-w-full">
            {features.map((feature, index) => (
              <FeatureCard key={index} {...feature} />
            ))}
          </div>
        </div>
      </section>
      <div className="flex flex-col items-center px-32 pt-9 w-full text-base font-medium leading-none text-center text-white max-md:px-5 max-md:max-w-full">
        <button className="px-7 py-3.5 bg-blue-700 rounded shadow-[0px_0px_10px_rgba(0,0,0,0.5)] max-md:px-5">
          {buttonText}
        </button>
      </div>
    </main>
  );
}

export default CorporateFilingsServices;
