import React, { useEffect, useState } from "react";
import ClientMap from "./ClientMap";
import OrderServices from "../../../services/orderServices";
import UserServices from "../../../services/userService";

function Chart() {
  const [selectedState, setSelectedState] = useState(null);
  const [stateData, setStateData] = useState({});

  const [dashboardData, setDashboardData] = useState([]);

  const [defaultDashboardData, setDefaultDashboardData] = useState({});

  const [superAdminId, setSuperAdminId] = useState(null);

  const [managerId, setManagerId] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));

  function checkIdsExist(arr, idsToCheck) {
    const foundIds = idsToCheck.filter((idToCheck) =>
      arr.some((item) => item.id === idToCheck)
    );
    return foundIds.length > 0 ? foundIds : null;
  }

  useEffect(() => {
    if (user) {
      if (user.companyRoles !== undefined) {
        if (user.companyRoles.length > 0) {
          if (user.companyRoles[0].roles.length > 0) {
            const id = checkIdsExist(user.companyRoles[0].roles, [4]);
            console.log(id);
            setSuperAdminId(id);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    console.log("user", user);
    if (user) {
      UserServices.getEmployeeByUserId(user.id)
        .then((res) => {
          console.log(res);
          if (res.data) {
            setManagerId(res.data.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    OrderServices.getAllOrderForDashbaord()
      .then((res) => {
        console.log(res);
        console.log(res.data);
        setDashboardData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (superAdminId && managerId && dashboardData.length > 0) {
      const filteredData = managerId
        ? dashboardData.filter((item) => item.accountManagerId === managerId) // Assuming each item has an `accountManagerId`
        : dashboardData;
      if (filteredData.length > 0) {
        const drafts = filteredData.reduce(
          (acc, curr) => acc + (curr.draftCount || 0),
          0
        );
        const createdCount = filteredData.reduce(
          (acc, curr) => acc + (curr.createdCount || 0),
          0
        );
        const underReview = filteredData.reduce(
          (acc, curr) => acc + (curr.inReviewCount || 0),
          0
        );
        const submittedOrders = filteredData.reduce(
          (acc, curr) => acc + (curr.submittedCount || 0),
          0
        );
        const approvalPending = filteredData.reduce(
          (acc, curr) => acc + (curr.approvalPendingCount || 0),
          0
        );
        const feedbackPending = filteredData.reduce(
          (acc, curr) => acc + (curr.clientRespondedCount || 0),
          0
        );
        const totalInProgress = filteredData.reduce(
          (acc, curr) => acc + (curr.inProgressCount || 0),
          0
        );
        const totalCompleted = filteredData.reduce(
          (acc, curr) => acc + (curr.completedCount || 0),
          0
        );
        const payLaterAproveCount = filteredData.reduce(
          (acc, curr) => acc + (curr.payLaterApprovedCount || 0),
          0
        );
        const vstateQueryRaisedCount = filteredData.reduce(
          (acc, curr) => acc + (curr.vstateQueryRaisedCount || 0),
          0
        );
        const payLaterRequestedCount = filteredData.reduce(
          (acc, curr) => acc + (curr.payLaterRequestedCount || 0),
          0
        );
        const clientRespondCount = filteredData.reduce(
          (acc, curr) => acc + (curr.clientRespondedCount || 0),
          0
        );
        const resubmittedCount = filteredData.reduce(
          (acc, curr) => acc + (curr.resubmittedCount || 0),
          0
        );
        const govtQueryRaisedCount = filteredData.reduce(
          (acc, curr) => acc + (curr.govtQueryRaisedCount || 0),
          0
        );
        const payLaterRejectedCount = filteredData.reduce(
          (acc, curr) => acc + (curr.payLaterRejectedCount || 0),
          0
        );
        const payDueCount = filteredData.reduce(
          (acc, curr) => acc + (curr.totalPaymentDue || 0),
          0
        );
        const payAmmountCount = filteredData.reduce(
          (acc, curr) => acc + (curr.totalOrderAmount || 0),
          0
        );

        const totalOrdersPlaced =
          drafts +
          underReview +
          submittedOrders +
          approvalPending +
          feedbackPending +
          totalInProgress +
          totalCompleted +
          payLaterAproveCount +
          vstateQueryRaisedCount +
          clientRespondCount +
          payLaterRequestedCount +
          resubmittedCount +
          govtQueryRaisedCount +
          payLaterRejectedCount +
          createdCount;
        const tempadata = {
          totalOrders: totalCompleted || 0,
          pendingOrders: totalInProgress || 0,
          submittedOrders: payLaterRequestedCount || 0,
          subscriptionOrders: resubmittedCount || 0,
          paymentDue: payDueCount || 0,
          feedbackPending: feedbackPending || 0,
          total: totalOrdersPlaced || 0,
        };
        setDefaultDashboardData(tempadata);
      }
    } else {
      if (dashboardData.length > 0) {
        const drafts = dashboardData.reduce(
          (acc, curr) => acc + (curr.draftCount || 0),
          0
        );
        const createdCount = dashboardData.reduce(
          (acc, curr) => acc + (curr.createdCount || 0),
          0
        );
        const underReview = dashboardData.reduce(
          (acc, curr) => acc + (curr.inReviewCount || 0),
          0
        );
        const submittedOrders = dashboardData.reduce(
          (acc, curr) => acc + (curr.submittedCount || 0),
          0
        );
        const approvalPending = dashboardData.reduce(
          (acc, curr) => acc + (curr.approvalPendingCount || 0),
          0
        );
        const feedbackPending = dashboardData.reduce(
          (acc, curr) => acc + (curr.clientRespondedCount || 0),
          0
        );
        const totalInProgress = dashboardData.reduce(
          (acc, curr) => acc + (curr.inProgressCount || 0),
          0
        );
        const totalCompleted = dashboardData.reduce(
          (acc, curr) => acc + (curr.completedCount || 0),
          0
        );
        const payLaterAproveCount = dashboardData.reduce(
          (acc, curr) => acc + (curr.payLaterApprovedCount || 0),
          0
        );
        const vstateQueryRaisedCount = dashboardData.reduce(
          (acc, curr) => acc + (curr.vstateQueryRaisedCount || 0),
          0
        );
        const payLaterRequestedCount = dashboardData.reduce(
          (acc, curr) => acc + (curr.payLaterRequestedCount || 0),
          0
        );
        const clientRespondCount = dashboardData.reduce(
          (acc, curr) => acc + (curr.clientRespondedCount || 0),
          0
        );
        const resubmittedCount = dashboardData.reduce(
          (acc, curr) => acc + (curr.resubmittedCount || 0),
          0
        );
        const govtQueryRaisedCount = dashboardData.reduce(
          (acc, curr) => acc + (curr.govtQueryRaisedCount || 0),
          0
        );
        const payLaterRejectedCount = dashboardData.reduce(
          (acc, curr) => acc + (curr.payLaterRejectedCount || 0),
          0
        );
        const payDueCount = dashboardData.reduce(
          (acc, curr) => acc + (curr.totalPaymentDue || 0),
          0
        );
        const payAmmountCount = dashboardData.reduce(
          (acc, curr) => acc + (curr.totalOrderAmount || 0),
          0
        );

        const totalOrdersPlaced =
          drafts +
          underReview +
          submittedOrders +
          approvalPending +
          feedbackPending +
          totalInProgress +
          totalCompleted +
          payLaterAproveCount +
          vstateQueryRaisedCount +
          clientRespondCount +
          payLaterRequestedCount +
          resubmittedCount +
          govtQueryRaisedCount +
          payLaterRejectedCount +
          createdCount;
        const tempadata = {
          totalOrders: totalCompleted || 0,
          pendingOrders: totalInProgress || 0,
          submittedOrders: payLaterRequestedCount || 0,
          subscriptionOrders: resubmittedCount || 0,
          paymentDue: payDueCount || 0,
          feedbackPending: feedbackPending || 0,
          total: totalOrdersPlaced || 0,
        };
        setDefaultDashboardData(tempadata);
      }
    }
  }, [dashboardData, managerId]);

  const handleStateHover = (stateName, data) => {
    setSelectedState(stateName);
    setStateData(data || {});
  };

  console.log(stateData);

  return (
    <div className="flex flex-col mt-4 w-full max-md:max-w-full">
      <div className="flex overflow-hidden flex-col w-full bg-white rounded-lg border border-gray-200 shadow-sm max-md:max-w-full">
        <div className="flex flex-col p-4 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col pb-px w-full font-medium max-md:max-w-full">
            <div className="flex flex-wrap gap-4 items-center w-full max-md:max-w-full">
              <h3 className="flex-1 shrink self-stretch my-auto text-lg leading-loose text-gray-900 basis-0 max-md:max-w-full">
                Active Clients:{" "}
                {selectedState ? selectedState : "Select a state"}
              </h3>
              <div className="flex gap-3 items-center self-stretch my-auto text-sm leading-none text-slate-700">
                {/* <button className="flex items-start self-stretch my-auto rounded-lg">
                  <div className="overflow-hidden gap-2 self-stretch px-4 py-2.5 bg-white rounded-lg border border-gray-300 border-solid shadow-sm">
                    Real-Time Report
                  </div>
                </button> */}
              </div>
            </div>
            <hr className="mt-4 w-full bg-gray-200 min-h-[1px] max-md:max-w-full" />
          </div>
          <div className="flex flex-wrap gap-10 items-start  w-full max-md:max-w-full">
            <ClientMap onStateHover={handleStateHover} />
            <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px]">
              <h4 className="text-2xl font-semibold tracking-tight leading-10 text-gray-900 flex items-center">
                {stateData.flag && (
                  <img
                    src={stateData.flag}
                    alt={`${selectedState} flag`}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />
                )}
                {selectedState || ""}
              </h4>
              <div className="flex flex-col mt-2 w-full">
                <div className="flex overflow-hidden gap-5 items-center p-3 w-full font-medium rounded-xl bg-slate-50">
                  <div className="flex-1 shrink text-sm leading-none text-gray-900">
                    Orders Completed
                  </div>
                  <div className="flex items-center text-xs text-blue-700 bg-blue-50 rounded px-2 py-0.5">
                    {selectedState
                      ? stateData.totalOrders !== undefined
                        ? stateData.totalOrders
                        : 0
                      : defaultDashboardData.totalOrders || 0}
                  </div>
                </div>

                <div className="flex overflow-hidden gap-5 items-center p-3 mt-3 w-full rounded-xl bg-slate-50">
                  <div className="flex-1 shrink text-sm leading-none text-gray-900">
                    Orders in Progress
                  </div>
                  <div className="flex items-center text-xs text-blue-700 bg-blue-50 rounded px-2 py-0.5">
                    {selectedState
                      ? stateData.pendingOrders !== undefined
                        ? stateData.pendingOrders
                        : 0
                      : defaultDashboardData.pendingOrders || 0}
                  </div>
                </div>

                <div className="flex overflow-hidden gap-5 items-center p-3 mt-3 w-full rounded-xl bg-slate-50">
                  <div className="flex-1 shrink text-sm leading-none text-gray-900">
                    Orders Pending for Approval
                  </div>
                  <div className="flex items-center text-xs text-blue-700 bg-blue-50 rounded px-2 py-0.5">
                    {selectedState
                      ? stateData.submittedOrders !== undefined
                        ? stateData.submittedOrders
                        : 0
                      : defaultDashboardData.submittedOrders || 0}
                  </div>
                </div>

                <div className="flex overflow-hidden gap-5 items-center p-3 mt-3 w-full rounded-xl bg-slate-50">
                  <div className="flex-1 shrink text-sm leading-none text-gray-900">
                    Pending Payment for Orders
                  </div>
                  <div className="flex items-center text-xs text-blue-700 bg-blue-50 rounded px-2 py-0.5">
                    $
                    {selectedState
                      ? stateData.paymentDue !== undefined
                        ? stateData.paymentDue
                        : 0
                      : defaultDashboardData.paymentDue || 0}
                  </div>
                </div>

                <div className="flex overflow-hidden gap-5 items-center p-3 mt-3 w-full rounded-xl bg-slate-50">
                  <div className="flex-1 shrink text-sm leading-none text-gray-900">
                    Orders Pending for Client Feedback
                  </div>
                  <div className="flex items-center text-xs text-blue-700 bg-blue-50 rounded px-2 py-0.5">
                    {selectedState
                      ? stateData.feedbackPending !== undefined
                        ? stateData.feedbackPending
                        : 0
                      : defaultDashboardData.feedbackPending || 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chart;
