import React from "react";
import { Link } from "react-router-dom";

const BlueButton = ({ label, icon, onclick }) => {
  return (
    <section className="main-class-blue">
      <Link to={onclick}>
        <button className="blue-btnnn">
          <span>
            {label} {icon}
          </span>
        </button>
      </Link>
    </section>
  );
};

export default BlueButton;
