import React, { useEffect } from "react";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import { Col, Container, Row } from "react-bootstrap";
import imgsrc from "../../../Assets/Images/digital.png";
import AOS from "aos";
import "aos/dist/aos.css";

const services = [
  {
    title: "Compliance and legal requirements",
    description:
      "Registered agents ensures your business meets state requirements, avoiding legal issues and penalties for non-compliance.",
  },
  {
    title: "Privacy and security",
    description:
      "Registered agents protects your personal address by using thier own address for public records, keeping legal documents and sensitive information secure and confidential.",
  },
  {
    title: "Convenience and reliability",
    description:
      "Registered agents provides a reliable point of contact to receive legal documents and notifications, ensuring you never miss important deadlines, even when you’re not available and  working in multiple states.",
  },
  {
    title: "Professionalism and organization",
    description:
      "It adds a level of professionalism to your business operations and keeps your business filings organized, allowing you to focus on your core operations.",
  },
];

const RegisteredSecond = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className="main-section-class" id="learnmore">
      <div className="entity-formation">
        <Container>
          <CommonHeadingTitle heading={"Why should your business have a"} />
          <CommonHeadingTitle blueheading={"registered agent?"} />
          <Row className="mt-5">
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <Row>
                {services.map((service, index) => (
                  <Col lg={12} md={12} sm={12} xs={12} key={index}>
                    <div className="main-class-div">
                      <h4 className="medium-level-heading">{service.title}</h4>
                      <div className="grey-border mt-3 mb-3"></div>
                      <p className="normal-common-txt">{service.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <img loading="lazy" className="user-img-buisness" src={imgsrc} />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default RegisteredSecond;
