import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import ReCAPTCHA from 'react-google-recaptcha';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    contactNumber: '',
    email: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    state: '',
    country: '',
    zip: '',
    accountNumber: '',
    entityType: '',
    postService: '',
    speedOfService: '',
    captcha: '',
  });

  const countries = [
    { label: 'United States', value: 'US' },
    { label: 'Canada', value: 'CA' },
    { label: 'India', value: 'IN' },
    { label: 'Australia', value: 'AU' },
    // Add more countries here
  ];

  const states = [
    { label: 'California', value: 'CA' },
    { label: 'Texas', value: 'TX' },
    { label: 'New York', value: 'NY' },
    { label: 'Florida', value: 'FL' },
    // Add more states here
  ];

  const entityTypes = [
    { label: 'Individual', value: 'individual' },
    { label: 'Company', value: 'company' },
    { label: 'Organization', value: 'organization' },
    // Add more entity types here
  ];

  const postServices = [
    { label: 'Standard', value: 'standard' },
    { label: 'Express', value: 'express' },
    { label: 'Overnight', value: 'overnight' },
    // Add more post services here
  ];

  const speedOfServices = [
    { label: 'Slow', value: 'slow' },
    { label: 'Medium', value: 'medium' },
    { label: 'Fast', value: 'fast' },
    // Add more speed options here
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCaptchaChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      captcha: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Submitted', formData);
    // Add form submission logic here (e.g., sending to an API)
  };

  return (
    <div className="contact-form-container px-8 py-4">
      <div className="contact-form-main shadow-2 p-8 border-round">
        <form onSubmit={handleSubmit}>
          {/* Contact Information Section */}
          <div className="mb-4">
            <h3 className="contact-form-header-text">Contact Information</h3>
            <div className="grid">
              <div className="col-6">
                <InputText
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First Name"
                  className="w-full"
                />
              </div>
              <div className="col-6">
                <InputText
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name"
                  className="w-full"
                />
              </div>
              <div className="col-6">
                <InputText
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="w-full"
                />
              </div>
              <div className="col-6">
                <InputMask
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                  mask="(999) 999-9999"
                  placeholder="Phone Number"
                  className="w-full"
                />
              </div>
            
            </div>
          </div>

          {/* Entity Information Section */}
          <div className="mb-4">
            <h3 className="contact-form-header-text">Entity Information</h3>
            <div className="grid">
              <div className="col-6">
                <InputText
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Company Name"
                  className="w-full"
                />
              </div>
              <div className="col-6">
                <Dropdown
                  name="entityType"
                  value={formData.entityType}
                  onChange={handleChange}
                  options={entityTypes}
                  optionLabel="label"
                  placeholder="Select Entity Type"
                  className="w-full"
                />
              </div>
              <div className="col-6">
                <InputText
                  name="addressLine1"
                  value={formData.addressLine1}
                  onChange={handleChange}
                  placeholder="Address Line 1"
                  className="w-full"
                />
              </div>
              <div className="col-6">
                <InputText
                  name="addressLine2"
                  value={formData.addressLine2}
                  onChange={handleChange}
                  placeholder="Address Line 2"
                  className="w-full"
                />
              </div>
              <div className="col-6">
                <Dropdown
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  options={states}
                  optionLabel="label"
                  placeholder="State"
                  className="w-full"
                />
              </div>
              <div className="col-6">
                <Dropdown
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  options={countries}
                  optionLabel="label"
                  placeholder="Country"
                  className="w-full"
                />
              </div>
              <div className="col-6">
                <InputText
                  name="zip"
                  value={formData.zip}
                  onChange={handleChange}
                  placeholder="ZIP Code"
                  className="w-full"
                />
              </div>
             
            </div>
          </div>

          {/* Additional Information Section */}
          <div className="mb-4">
            <h3 className="contact-form-header-text">Additional Information</h3>
            <div className="grid">
              <div className="col-6">
                <InputText
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange}
                  placeholder="Account Number"
                  className="w-full"
                />
              </div>
              <div className="col-6">
                <Dropdown
                  name="postService"
                  value={formData.postService}
                  onChange={handleChange}
                  options={postServices}
                  optionLabel="label"
                  placeholder="Select Post Service"
                  className="w-full"
                />
              </div>
              <div className="col-6">
                <Dropdown
                  name="speedOfService"
                  value={formData.speedOfService}
                  onChange={handleChange}
                  options={speedOfServices}
                  optionLabel="label"
                  placeholder="Select Speed of Service"
                  className="w-full"
                />
              </div>
            </div>
          </div>

          {/* Captcha */}
          <div className="mb-4">
            <ReCAPTCHA
              sitekey="your-recaptcha-site-key"
              onChange={handleCaptchaChange}
            />
          </div>

          {/* Submit Button */}
          <Button type="submit" label="Submit" className="vstate-button w-full" />
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
