import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import OrderServices from '../../../services/orderServices';
import MasterServices from '../../../services/coreServices';
import dayjs from 'dayjs';

export default function VerticalBarDemo({ startDate, endDate }) {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [serviceData, setServiceData] = useState([]);
    const [category, setCategory] = useState([]);


    useEffect(() => {

        if (startDate && endDate) {
            const startsDate = startDate.format("YYYY-MM-DD");
            const endsDate = endDate.add(1, "day").format("YYYY-MM-DD");
            OrderServices.getAllServiceForDashbaord(startsDate, endsDate)
                .then((res) => {
                    if (Array.isArray(res.data)) {
                        setServiceData(res.data);
                    }
                })
                .catch((err) =>  console.log(err))
        }
        else {
            const startDate = dayjs().subtract(3, 'month').format("YYYY-MM-DD");
            const endDate = dayjs().add(1, "day").format("YYYY-MM-DD");
            OrderServices.getAllServiceForDashbaord(startDate, endDate)
                .then((res) => {
                    if (Array.isArray(res.data)) {
                        setServiceData(res.data);
                    }
                })
                .catch((err) =>  console.log(err))
        }


        MasterServices.getAllProductCatagory()
            .then((res) => {
                if (Array.isArray(res.data)) {
                    setCategory(res.data);
                }
            })
            .catch((err) =>  console.log(err))
    }, [startDate, endDate]);

    const getCategory = (id) => {
        const foundCategory = category.find((val) => val.id === id);
        return foundCategory ? foundCategory.productName : "Unknown";
    };

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        const colorPalette = ["#02359F", "#145EF0", "#518CFE", "#84ADFF", "#B3CDFF"];

console.log(serviceData)

        const categoryTotals = serviceData.reduce((acc, order) => {
            const categoryId = order.productMasterId?.id;
            acc[categoryId] = (acc[categoryId] || 0) + order.productCount;
            return acc;
        }, {});

        console.log(categoryTotals)


        const topCategories = Object.entries(categoryTotals)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 5)
            .map(([categoryId]) => parseInt(categoryId));

            console.log(topCategories)

        const datasets = topCategories.map((categoryId, index) => {
            const monthlyOrderCost = Array(12).fill(0);


            serviceData.forEach(order => {
                if (order.productMasterId?.id === categoryId) {
                    const monthIndex = order.orderMonth - 1;
                    monthlyOrderCost[monthIndex] += order.productCount;
                }
            });

            

            return {
                type: 'bar',
                label: getCategory(categoryId),
                backgroundColor: colorPalette[index % colorPalette.length],
                data: monthlyOrderCost,
            };
        });

        const data = {
            labels: ['Jan', 'Feb', 'Mar', 'April', 'May', 'Jun', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
            datasets,
        };

        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: false,
                },
                legend: {
                    labels: {
                        color: textColor,
                    },
                },
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                    title: {
                        display: true, 
                        text: 'Month', 
                        color: textColorSecondary, 
                        font: {
                            size: 14, 
                            weight: 'bold', 
                        },
                    },
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                    title: {
                        display: true, 
                        text: 'Total Product Count', 
                        color: textColorSecondary, 
                        font: {
                            size: 14,
                            weight: 'bold', 
                        },
                    },
                },
            },
        };

        setChartData(data);
        setChartOptions(options);
    }, [serviceData, category]);

    return (
        <div className="card">
            <Chart type="bar" data={chartData} options={chartOptions} />
        </div>
    );
}
