import React, { useContext, useEffect } from "react";
import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { Message } from "primereact/message";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";

import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import { Avatar } from "primereact/avatar";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";

import { InputMask } from "primereact/inputmask";

import { ProgressBar } from "primereact/progressbar";

import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import UserServices from "../../../services/userService";
import CreditCardServices from "../../../services/strapiService";
import errorMessages from "../../../utils/errorMessages";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import companyMasterService from "../../../services/companyMasterService";
import AddressService from "../../../services/addressService";
import rolesMasterService from "../../../services/rolesMaster";
import authContext from "../../../common/authContext";
import { showToast } from "../../../utils/toastUtils";
import useTableData from "../../../redux/Reducers/useTableData";
import { checkDomain } from "../../../utils/dnsEmailChecker";

const AddCompanyKeyPersonnel = ({
  visible,
  setVisible,
  pageRefresh,
  setPageRefresh,
  memberType,
  data,
  keyPersonalAddressType,
  handlers = {},
}) => {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh,
  } = useContext(authContext);

  const {
    data: fetchedStateData,
    isLoading: stateLoading,
    isError: isStateError,
    error: stateError,
    refetch: reftechStateData,
  } = useTableData("state");

  const {
    data: fetchedCountryData,
    isLoading: countryLoading,
    isError: isCountryError,
    error: countryError,
    refetch: reftechCountryData,
  } = useTableData("countryMaster");

  const {
    data: fetchedAddressTypeData,
    isLoading: addressTypeLoading,
    isError: isAddressTypeError,
    error: addressTypeError,
    refetch: reftechAddressTypeData,
  } = useTableData("addressType");

  const {
    data: fetchedKeyPersonnelTypeData,
    isLoading: keyPersonnelTypeLoading,
    isError: isKeyPersonnelTypeError,
    error: keyPersonnelTypeError,
    refetch: reftechKeyPersonnelTypeData,
  } = useTableData("keyPersonnelType");

  const {
    setOrganizerData,
    setBilingInfo,
    setMailingInfo,
    setBilingAddress,
    setOfficer,
    setIncorporator,
    setFiler,
    setDirector,
    setPresident,
    setTreasurer,
    setSecretary,
  } = handlers;

  const [value, setValue] = useState("");
  const [ingredient, setIngredient] = useState("");
  const toast = useRef(null);
  const [upload, setUpload] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isEqualAddress, setIsEqualAddress] = useState(true);
  const [allCustomer, setAllCustomer] = useState([]);

  

  function convertName(originalString) {
    if (typeof originalString === "string") {
      if (!originalString.includes("_")) {
        return originalString;
      }

      const parts = originalString.split("_");

      const relevantParts = parts;

      // Capitalize the first letter of each part and join them with a space
      const formattedString = relevantParts
        .map(
          (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
        )
        .join(" ");

      return formattedString;
    }
  }

  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [cityErr, setcityErr] = useState("");

  const toastCenter = useRef(null);

  const [selectedSkills, setSelectedSkills] = useState(null);
  const navigate = useNavigate();

  const [salutation, setSalutation] = useState("");

  const [firstName, setFirstName] = useState("");

  const [lastName, setLastName] = useState("");

  const [website, setWebsite] = useState("");

  const [company, setCompany] = useState("");

  const [phone, setPhone] = useState("");

  const [address, setAddress] = useState("");

  const [city, setCity] = useState("");

  const [state, setState] = useState("");

  const [zip, setZip] = useState("");

  const [emailErr, setEmailErr] = useState("");

  const [passwordErr, setPasswordErr] = useState("");

  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [firstNameErr, setFirstNameErr] = useState("");

  const [lastNameErr, setLastNameErr] = useState("");

  const [phoneErr, setPhoneErr] = useState("");

  const [selectedCountry, setSelectedCountry] = useState("+1");

  const [roleId, setRoleId] = useState(null);

  const [isShowContent2, setIsShowContent2] = useState(false);

  const [isShowContent3, setIsShowContent3] = useState(false);

  const [globalErr, setGlobalErr] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const [openDateErr, setOpenDateErr] = useState("");

  const [keyPersonnelNameErr, setkeyPersonnelNameErr] = useState("");

  const [addr1, setAddr1] = useState("");

  const [addr1Err, setAddr1Err] = useState("");

  const [addr2, setAddr2] = useState("");

  const [addr2Err, setAddr2Err] = useState("");

  const [addr3, setAddr3] = useState("");

  const [region, setRegion] = useState("");

  const [regionErr, setRegionErr] = useState("");

  const [states, setStates] = useState(null);

  const [statesErr, setStatesErr] = useState("");

  const [zipErr, setZipErr] = useState("");

  const [companyDetails, setCompanyDetails] = useState(null);

  const [allAddressType, setAllAddressType] = useState([]);

  const [addressType, setAddressType] = useState(null);

  const [allStates, setAllStates] = useState([]);

  const [allCountries, setAllCountries] = useState([]);

  const [countryData, setCountryData] = useState(null);

  const [stateValue, setStateValue] = useState(null);

  const [countryValue, setCountryValue] = useState(null);

  const [allAddressList, setAddressList] = useState([]);
  const [keyPersonnelName, setkeyPersonnelName] = useState("");

  const [keyPersonnelTitle, setkeyPersonnelTitle] = useState("");

  const [ownershipPercentage, setownershipPercentage] = useState("");

  const [FormationDate, setFormationDate] = useState("");

  const [dateOfTransfer, setDateOfTransfer] = useState(null);
  const [dateOfTransferErr, setDateOfTransferErr] = useState("");

  const [addressData, setAddressData] = useState(null);

  const [allKeyPersonnelType, setAllKeyPersonnelType] = useState([]);

  const [keyPersonnelType, setKeyPersonnelType] = useState(null);

  const [percentageErr, setPercentageErr] = useState("");

  const [countryErr, setCountryErr] = useState("");

  const [suffix, setSuffix] = useState(null);

  const [middleName, setMiddleName] = useState(null);

  const [dateOfBirth, setDateOfBirth] = useState(dayjs(null));

  useEffect(() => {
    if (!addressType && keyPersonalAddressType) {
      setAddressType(keyPersonalAddressType);
    }
  }, []);

  // useEffect(() => {
  //     if (Array.isArray(allLocation)) {
  //         setAddressList(allLocation)
  //     }
  // }, [allLocation])
  useEffect(() => {
    if (fetchedStateData) {
      setAllStates(fetchedStateData);
    }
  }, [fetchedStateData]);

  useEffect(() => {
    // setCountryData("US");
    setCountryData(3);
    if (fetchedCountryData) {
      setAllCountries(fetchedCountryData);
    }
  }, [fetchedCountryData]);

  useEffect(() => {
    if (fetchedKeyPersonnelTypeData) {
      setAllKeyPersonnelType(fetchedKeyPersonnelTypeData);
    }
  }, [fetchedKeyPersonnelTypeData]);

  useEffect(() => {
    
    if (fetchedAddressTypeData) {
      setAllAddressType(fetchedAddressTypeData);
    }
  }, [fetchedAddressTypeData]);

  
  useEffect(() => {
    if (memberType) {
      setKeyPersonnelType(memberType);
    }
  }, [memberType]);

  useEffect(() => {
    
    if (keyPersonalAddressType) {
      setAddressType(keyPersonalAddressType);
    }
  }, [keyPersonalAddressType]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [isContentVisible, setIsContentVisible] = useState(true);

  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    // toast.current.show({ severity: 'info', summary: 'Success', detail: 'File uploaded' });
    showToast("success", `File uploaded`);
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 1 MB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}
          ></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-file mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop File Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-file",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const handlePostalCode = (e) => {
    setZipErr("");
  
    let value = e.target.value;
  
    // Allow only numbers
    if (!/^\d*$/.test(value)) {
      return; // Prevents updating state if non-numeric input
    }
  
    // Set the value
    setZip(value);
  
    // Validation checks
    if (value.length === 0) {
      setZipErr("Zip code required");
    } else if (value.length !== 5) {
      setZipErr("Zip code must be exactly 5 digits");
    }
  };
  

  const dialogView = () => setVisible(false);
  const cancelView = () => {
    setVisible(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setIngredient("");
    setPhone("");
    setCity("");
    setDateOfBirth(null);
    setPassword("");
    setConfirmPassword("");
    setFirstNameErr("");
    setLastNameErr("");
    setPasswordErr("");
    setConfirmPasswordErr("");
    setkeyPersonnelName("");
    setPhoneErr("");
    setcityErr("");
    setEmailErr("");
    setPhoneErr("");
    setcityErr("");
    setZip("");
    setZipErr("");
    setEmailErr("");
    setAddr2("");
    setcityErr("");
    setStatesErr("");
    setStates(null);
    // setCountryData(null)
    setCountryErr("");
    setAddr1("");
    setAddr1Err("");
    setAddr2Err("");
    setMiddleName("");
    setCity("");
    setSuffix(null);
    setPercentageErr("");
    setkeyPersonnelNameErr("");
    setOpenDateErr("");
    setDateOfTransfer(null);
    setownershipPercentage("");
  };

  let newObject = window.localStorage.getItem("user");
  let updatedUser = Object.assign({}, JSON.parse(newObject));

  useEffect(() => {
    setSelectedCountry("+1");

    if (updatedUser) {
      if (updatedUser.companyRoles !== undefined) {
        if (updatedUser.companyRoles.length > 0) {
          if (updatedUser.companyRoles[0].roles.length > 0) {
            setRoleId(updatedUser.companyRoles[0].roles[0].id);
          }
        }
      }
    }
  }, []);

  const handleOpenDate = (newValue) => {
    setDateOfTransferErr(""); // Clear previous errors
    

    if (!newValue || !dayjs(newValue).isValid()) {
      setDateOfTransfer(null);
      setDateOfTransferErr("Date of transfer required");
      return;
    }

    setDateOfTransfer(newValue);
  };

  const handleEmail = async (e) => {
    setEmailErr("");
    const value = e.target.value;
    setIngredient(e.target.value);
    if (value === "") {
      setEmailErr(errorMessages.emailRequired);
    }
    // if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
    //     setEmailErr(errorMessages.emailValidation);
    // }
    // const domain = value.split("@")[1];
    // const isDomainValid = await checkDomain(domain);
    // if (!isDomainValid) {
    //     setEmailErr("Please enter valid email address");
    // }
  };

  const handleDOB = (newValue) => {
    setOpenDateErr("");
    
    if (newValue && dayjs(newValue).isValid()) {
      // setFormationDate(newValue);
      if (newValue !== null) {
        setDateOfBirth(newValue);
        // const d = new Date(newValue.$d)
        // if (isNaN(d.getDay()) || isNaN(d.getMonth()) || isNaN(d.getFullYear())) {
        //     setOpenDateErr("Select Valid Position Opened Date")
        // }
      } else {
        setOpenDateErr("Date of birth required");
      }
    } else {
      setDateOfBirth(null); // or handle the invalid case
    }
  };

  const handlePercentage = (e) => {
    setPercentageErr("");
    const input = e.target.value;
    const regex = /^(\d{1,2}|100)(\.\d{1,2})?%?$/;

    if (regex.test(input) || input === "") {
      setownershipPercentage(input);
    } else {
      setPercentageErr("Enter valid owner percentage");
    }
  };

  const onCountryChange = (e) => {
    
    setSelectedCountry(e.value);
  };

  // const handlePhone = (e) => {
  //     setPhoneErr("");
  //     let value = e.target.value;

  //     let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
  //     if (inputValue.length > 10) {
  //       inputValue = inputValue.slice(0, 10); // Limit to 10 digits
  //     }
  //     const formattedValue = inputValue.replace(
  //       /(\d{3})(\d{3})(\d{4})/,
  //       (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
  //     );
  //     if (inputValue.length === 10) {
  //       // Only show formatted value after 10 digits
  //       e.target.value = formattedValue;
  //       setPhone(formattedValue); // Update state with formatted number
  //     } else {
  //       e.target.value = inputValue;
  //       setPhone(inputValue);
  //     }

  //     if (value === "") {
  //       setPhoneErr(errorMessages.phoneRequired);
  //     }
  //     console.log(inputValue.length)
  //     if (value && value.trim() !== "" && inputValue.length <=9) {

  //       if (
  //         !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(value)
  //       ) {
  //         setPhoneErr(errorMessages.phoneValidation);
  //       }
  //     }
  //     setPhone(e.target.value);
  //   };

  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;

    let inputValue = value.replace(/\D/g, ""); // Remove non-digit characters

    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }

    let formattedValue = inputValue;
    if (inputValue.length === 10) {
      formattedValue = inputValue.replace(
        /(\d{3})(\d{3})(\d{4})/,
        "($1) $2-$3"
      );
    }

    // Update input field value and state
    e.target.value = formattedValue;
    setPhone(formattedValue);

    // Check if input is empty
    if (value.trim() === "") {
      setPhoneErr(errorMessages.phoneRequired);
      return; // Stop further execution
    }

    

    // Validate phone number format only if length is 10
    if (inputValue.length <= 9) {
      if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formattedValue)) {
        setPhoneErr(errorMessages.phoneValidation);
      }
    }
  };

  const handleToggle2 = () => {
    setIsShowContent2(!isShowContent2);
  };

  const handleToggle3 = () => {
    setIsShowContent3(!isShowContent3);
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        {selectedCountry ? (
          <span>
            <b>{selectedCountry.name}</b> selected.
          </span>
        ) : (
          "No country selected."
        )}
      </div>
    );
  };

  const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none">
    <circle cx="9.79519" cy="2.00625" r="2.00625" fill="#A3D5C9"/>
<circle cx="7.67096" cy="6.13675" r="1.06213" fill="#A3D5C9"/>
<circle cx="1.23915" cy="11.5064" r="1.23915" fill="#A3D5C9"/>
<circle cx="15.2829" cy="12.4506" r="1.47518" fill="#A3D5C9"/>
<circle cx="19.0004" cy="8.26101" r="1.06213" fill="#A3D5C9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6886 0.650298C15.7808 1.14561 15.3314 1.88426 15.3314 2.88125C15.3314 3.25051 15.4132 3.69897 15.5172 3.90007C15.6192 4.09751 15.6675 4.29412 15.6244 4.33672C15.5812 4.37932 14.4255 5.56549 13.0562 6.97258L10.5663 9.5309L10.2051 9.33783C9.72642 9.08209 8.5438 9.08256 8.0643 9.33889L7.70094 9.53302L6.31214 8.14057L4.92323 6.74811L5.10155 6.40316C5.40803 5.8106 5.4491 5.28143 5.23785 4.6484C4.87201 3.5524 4.02785 2.93907 2.885 2.93907C1.62401 2.93907 0.628439 3.83469 0.499331 5.08529C0.309446 6.92561 2.08686 8.27074 3.82911 7.60537L4.36643 7.40014L5.74319 8.76298L7.11995 10.1258L6.91815 10.5489C6.61756 11.1792 6.65663 12.0617 7.01421 12.7184C8.17748 14.8542 11.3541 14.1761 11.5336 11.7535C11.5703 11.2579 11.5252 10.9367 11.3712 10.5952L11.1581 10.1225L13.751 7.52949L16.3439 4.93659L16.8074 5.13037C17.5107 5.42411 18.5064 5.31601 19.138 4.87711C20.5128 3.92202 20.5099 1.83623 19.1323 0.87901C18.7142 0.58834 18.4875 0.520836 17.8341 0.49204C17.2702 0.467257 16.9408 0.512693 16.6886 0.650298Z" fill="white"/>
</svg>
 `;

  

  const onFileUpload = (e) => {
    e.preventDefault();

    let formData = new FormData();

    formData.append("file", selectedFile);

    

    if (selectedFile === null) {
      // toast.current.show({ severity: 'error', summary: 'Error', detail: "Please choose the file", life: 2500 });
      showToast("error", `Please choose the file`);
    }

    UserServices.bulkUserUpload(formData, roleId)
      .then((response) => {
        
        if (response.status === 201) {
        }
        if (response.status === 500) {
          // toast.current.show({ severity: 'error', summary: 'Failed To Upload', detail: "Network error, Please try again!", life: 3000 });
          showToast("error", `Network error, Please try again!`);
        }
        if (response.status === 504) {
          // toast.current.show({ severity: 'error', summary: 'Failed To Upload', detail: "Gateway timeout!", life: 3000 });
          showToast("error", `Gateway timeout!`);
        }
      })
      .catch((e) => {
        // toast.current.show({ severity: 'success', summary: 'Uploaded', detail: e.response.data.errorMessage, life: 3000 });
        
      });
  };

  function capitalizeFirstLetter(string) {
    if (!string) return ""; // handle empty or undefined strings
    if (string === "RA-BILLING") {
      return "RA-billing";
    }
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  function lowerAllLetter(string) {
    if (!string) return ""; // handle empty or undefined strings
    return string.charAt(0).toLowerCase() + string.slice(1).toLowerCase();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true);

    let hasError = false;
    if (ingredient === "") {
      setEmailErr(errorMessages.emailRequired);
      hasError = true;
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(ingredient)) {
      setEmailErr(errorMessages.emailValidation);
      hasError = true;
    }
    const trimmedEmail = ingredient.trim();
    if (trimmedEmail === "") {
      setEmailErr(errorMessages.emailRequired);
      hasError = true;
    }
    if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(trimmedEmail)
    ) {
      setEmailErr(errorMessages.emailValidation);
      hasError = true;
    }
    const domain = ingredient.split("@")[1];

    const isDomainValid = await checkDomain(domain);
    if (!isDomainValid) {
      setEmailErr("Please enter valid email address");
      hasError = true;
    }

    if (hasError) {
      setIsDisabled(false);
      return;
    }

    
    const actualdata1 = {
      addressLine1: addr1,
      addressLine2: addr2 !== "" ? addr2 : null,
      city: city,
      stateId: states,
      postalCode: zip,
      countryId: countryData,
      contactDetails: null,
      companyDetails: data ? data : null,
      addressType: addressType,
    };

    if (
      ingredient !== "" &&
      addr1 !== "" &&
      states &&
      countryData &&
      zip !== "" &&
      addressType &&
      city !== "" &&
      firstName !== "" &&
      lastName !== ""
    ) {
      setIsTabRefresh(true);
      AddressService.saveAllAddress(actualdata1)
        .then((res) => {
          
          const actualdata = {
            companyId: data ? data : null,
            keyPersonnelTypeId: keyPersonnelType ? keyPersonnelType.id : null,
            keyPersonnelName: null,
            keyPersonnelTitle: keyPersonnelTitle,
            dateOfTransfer: dayjs(dateOfTransfer).isValid()
              ? dateOfTransfer.toISOString()
              : null,
            ownershipPercentage: parseFloat(ownershipPercentage),
            contactNo: `${selectedCountry} ${phone}`,
            emailId: ingredient,
            addressMasterId: res.data,
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            suffix: suffix,
            dateOfBirth: dayjs(dateOfBirth).isValid()
              ? dateOfBirth.toISOString()
              : null,
          };

          companyMasterService
            .saveKeyPersonnel(actualdata)
            .then((res) => {
              
              setIsDisabled(false);

              setFirstName("");
              setLastName("");
              setEmail("");
              setIngredient("");
              setPhone("");
              setPassword("");
              setConfirmPassword("");
              setFirstNameErr("");
              setLastNameErr("");
              setPasswordErr("");
              setConfirmPasswordErr("");
              setPhoneErr("");
              setcityErr("");
              setEmailErr("");
              setPhoneErr("");
              setcityErr("");
              setZip("");
              setZipErr("");
              setEmailErr("");
              setAddr2("");
              setcityErr("");
              setStatesErr("");
              setStates(null);
              // setCountryData(null)
              setCountryErr("");
              setAddr1("");
              setAddr1Err("");
              setAddr2Err("");
              setDateOfTransfer(null);
              setownershipPercentage("");
              setPercentageErr("");
              setkeyPersonnelNameErr("");
              setOpenDateErr("");
              setIsTabRefresh(false);
              setkeyPersonnelName("");
              setkeyPersonnelTitle("");
              setMiddleName("");
              setCity("");
              setSuffix(null);
              setDateOfBirth(null);

         

              showToast(
                "success",
                `${capitalizeFirstLetter(
                  keyPersonnelType.keyPersonalType
                )} details added successfully`
              );

              setTimeout(() => {
                setVisible(false);
              }, 3000);
              // navigate("/signin")
            })
            .catch((err) => {
              
              setIsDisabled(false);
              setVisible(true);
              setIsTabRefresh(false);
            });
        })
        .catch((err) => {
          
          setIsTabRefresh(false);
          // if (toast.current !== null) {

          //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
          // }
          showToast("error", `Something went wrong`);
        });
    } else {
      setIsDisabled(false);
      if (ingredient === "") {
        
        setEmailErr(errorMessages.emailRequired);
      }
      if (password === "") {
        setPasswordErr(errorMessages.passwordRequired);
      }
      if (firstName === "") {
        setFirstNameErr(errorMessages.firstNameRequired);
      }
      if (keyPersonnelName === "") {
        setkeyPersonnelNameErr("Key personnel name required");
      }
      if (lastName === "") {
        setLastNameErr(errorMessages.lastNameRequired);
      }
      if (phone === "") {
        setPhoneErr(errorMessages.phoneRequired);
      }
      if (addr1 === "") {
        setAddr1Err("Address line 1 required");
      }
      // if (addr2 === "") {
      //     setAddr2Err("Address line 2 required")
      // }
      if (city === "") {
        setcityErr("City required");
      }
      if (zip === "") {
        setZipErr("Zip code required");
      }
      if (!states) {
        setStatesErr("State required");
      }
      if (!countryData) {
        setCountryErr("Country required");
      }
      // if (!(dayjs(dateOfBirth).isValid())) {
      //     setOpenDateErr("Date of birth required")
      // }
      if (dateOfTransfer && !dayjs(dateOfTransfer).isValid()) {
        setDateOfTransferErr("Invalid date of transfer date");
      }
    }
  };

  const customHeader = (
    <div className="flex align-items-center gap-2">
      <Avatar
        image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
        shape="circle"
      />
      <span className="font-bold">Amy Elsner</span>
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      <Toast ref={toastCenter} />
      <Sidebar
        position="right"
        header={customHeader}
        visible={visible}
        className="dialog-box"
        onHide={cancelView}
      >
        <div className="grid custom-sidebar-header">
          <div className="grid col-12 col-sm-12 col-md-12 col-lg-11 px-4 pt-4 pb-0">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 sidebar-header-text1 pb-0">
              Add{" "}
              {keyPersonnelType
                ? convertName(keyPersonnelType.keyPersonalType)
                : "Member"}{" "}
              Information
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-1 flex justify-content-end pt-4 pb-0">
            <button onClick={cancelView}>
              <i className="pi pi-times"></i>
            </button>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 sidebar-header-text2 px-4">
            Fill the below details to add a{" "}
            {keyPersonnelType
              ? lowerAllLetter(keyPersonnelType.keyPersonalType)
              : "member"}{" "}
            information of your business.
          </div>
        </div>

        <div className="grid justify-content-center w-full mt-3">
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-6"
            style={{ fontSize: "14px" }}
          >
            <span className=" mr-3">
              <div class="relative">
                <Dropdown
                  value={keyPersonnelType}
                  options={allKeyPersonnelType}
                  onChange={(e) => setKeyPersonnelType(e.value)}
                  optionLabel="keyPersonalType"
                  disabled
                  style={{ borderRadius: "6px", width: "100%" }}
                  className=" font-fam-for-all"
                />

                <label
                  htmlFor="floating_outlined3"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Key Personnel Type
                  <span className="form-field-mandatory">*</span>
                </label>
              </div>
            </span>
          </div>
        </div>

        <form id="myform" className="form-spacing mt-4" onSubmit={handleSubmit}>
          <Toast ref={toast} />
          <div className=" input-sidebar-card mt-4">
            <div className="px-5 pt-4">
              <div className="flex" style={{ justifyContent: "space-between" }}>
                <div className="font-fam-for-all srch-cand-text2">
                  <span>
                    <i className="pi pi-address-book"></i>
                  </span>
                  {keyPersonnelType
                    ? convertName(keyPersonnelType.keyPersonalType)
                    : "Member"}{" "}
                  Details
                </div>
              </div>
            </div>

            <div className="form-content-signup px-5 pb-5">
              <div className="grid  py-2">
                {/* {
                                    (keyPersonnelType && keyPersonnelType.id !== 15 && keyPersonnelType.id !== 14) ? <div className='col-6' style={{ fontSize: "14px" }}>
                                        <span className=" mr-3">
                                            <div class="relative">
                                                <InputText
                                                    style={{ height: '40px', width: "100%" }}
                                                    maxLength={50}
                                                    value={keyPersonnelName}
                                                    id="floating_outlined3"
                                                    placeholder=''
                                                    className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                    onChange={(e) => {
                                                        setkeyPersonnelNameErr("")
                                                        setkeyPersonnelName(e.target.value)
                                                    }
                                                    }
                                                    name="cityName"
                                                />

                                                <label
                                                    htmlFor="floating_outlined3"
                                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                >
                                                    Key Personnel Name<span className="form-field-mandatory">*</span>
                                                </label>
                                            </div>
                                            {keyPersonnelNameErr !== "" ? <p className='error-msg font-fam-for-all'>{keyPersonnelNameErr}</p> : ""}
                                        </span>
                                    </div> : ""
                                } */}

                {/* {
                                    keyPersonnelType && (keyPersonnelType.id === 15 || keyPersonnelType.id === 14) ? <> */}
                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                  style={{ fontSize: "14px" }}
                >
                  <span className=" mr-3">
                    <div class="relative">
                      <InputText
                        style={{ height: "40px", width: "100%" }}
                        maxLength={50}
                        value={firstName}
                        id="floating_outlined3001"
                        placeholder=""
                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        onChange={(e) => {
                          setFirstNameErr("");
                          setFirstName(e.target.value);
                        }}
                        name="cityName"
                      />

                      <label
                        htmlFor="floating_outlined3001"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        First name
                        <span className="form-field-mandatory">*</span>
                      </label>
                    </div>
                    {firstNameErr !== "" ? (
                      <p className="error-msg font-fam-for-all">
                        {firstNameErr}
                      </p>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                  style={{ fontSize: "14px" }}
                >
                  <span className=" mr-3">
                    <div class="relative">
                      <InputText
                        style={{ height: "40px", width: "100%" }}
                        maxLength={50}
                        value={middleName}
                        id="floating_outlined3003"
                        placeholder=""
                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        onChange={(e) => setMiddleName(e.target.value)}
                        name="cityName"
                      />

                      <label
                        htmlFor="floating_outlined3003"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Middle Name
                        <span className="form-field-mandatory"></span>
                      </label>
                    </div>
                  </span>
                </div>
                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                  style={{ fontSize: "14px" }}
                >
                  <span className=" mr-3">
                    <div class="relative">
                      <InputText
                        style={{ height: "40px", width: "100%" }}
                        maxLength={50}
                        value={lastName}
                        id="floating_outlined3004"
                        placeholder=""
                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        onChange={(e) => {
                          setLastNameErr("");
                          setLastName(e.target.value);
                        }}
                        name="cityName"
                      />

                      <label
                        htmlFor="floating_outlined3004"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Last Name<span className="form-field-mandatory">*</span>
                      </label>
                    </div>
                    {lastNameErr !== "" ? (
                      <p className="error-msg font-fam-for-all">
                        {lastNameErr}
                      </p>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                  style={{ fontSize: "14px" }}
                >
                  <span className=" mr-3">
                    <div class="relative">
                      <Dropdown
                        style={{ borderRadius: "6px", width: "100%" }}
                        value={suffix}
                        placeholder="Select Suffix"
                        options={["Jr.", "Sr.", "I", "II", "III"]}
                        onChange={(e) => {
                          setSuffix(e.value);
                        }}
                        name="cityName"
                      />

                      <label
                        htmlFor="floating_outlined3005"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Suffix<span className="form-field-mandatory"></span>
                      </label>
                    </div>
                  </span>
                </div>

                {/* </> : ""
                                } */}

                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                  style={{ fontSize: "14px" }}
                >
                  <span className=" mr-3">
                    <div class="relative">
                      <InputText
                        style={{ height: "40px", width: "100%" }}
                        maxLength={50}
                        value={keyPersonnelTitle}
                        id="floating_outlined4"
                        placeholder=""
                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        onChange={(e) => setkeyPersonnelTitle(e.target.value)}
                        name="cityName"
                      />

                      <label
                        htmlFor="floating_outlined4"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Key Personnel Title
                        <span className="form-field-mandatory"></span>
                      </label>
                    </div>
                  </span>
                </div>

                {/* <div className='col-6' style={{ fontSize: "14px" }}>

                                    <span className=" mr-3">
                                        <div class="relative">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                <DatePicker
                                                    value={dateOfBirth}
                                                    onChange={handleDOB}


                                                    disableFuture
                                                    slotProps={{
                                                        textField: {
                                                            size: 'small',
                                                            error: false,
                                                            fullWidth: true
                                                        },
                                                    }}

                                                    className='dialog-form-dropdown-field'

                                                />

                                            </LocalizationProvider>
                                            <label htmlFor="floating_outlined10"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                                Date Of Birth <span className="form-field-mandatory"></span>
                                            </label>
                                        </div>
                                        {openDateErr !== "" ? <p className="error-msg font-fam-for-all">{openDateErr}</p> : ""}
                                    </span>
                                </div> */}

                {keyPersonnelType && keyPersonnelType.id === 2 ? (
                  <>
                    {" "}
                    <div
                      className="col-12 col-sm-12 col-md-12 col-lg-6"
                      style={{ fontSize: "14px" }}
                    >
                      <span className=" mr-3">
                        <div class="relative">
                          <InputText
                            style={{ height: "40px", width: "100%" }}
                            maxLength={10}
                            prefix="%"
                            value={ownershipPercentage}
                            id="floating_outlined5"
                            placeholder=""
                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            onChange={handlePercentage}
                            name="cityName"
                          />

                          <label
                            htmlFor="floating_outlined5"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Ownership Percentage
                            <span className="form-field-mandatory"></span>
                          </label>
                        </div>
                        {percentageErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {percentageErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                    <div
                      className="col-12 col-sm-12 col-md-12 col-lg-6"
                      style={{ fontSize: "14px" }}
                    >
                      <span className=" mr-3">
                        <div class="relative">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={
                                dateOfTransfer ? dayjs(dateOfTransfer) : null
                              } // Ensure valid value
                              onChange={handleOpenDate}
                              slotProps={{
                                textField: {
                                  size: "small",
                                  error: false,
                                  // helperText: dateOfTransferErr || "",
                                  fullWidth: true,
                                },
                              }}
                              className="dialog-form-dropdown-field"
                            />
                          </LocalizationProvider>

                          <label
                            htmlFor="floating_outlined6"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Date Of Transfer
                            <span className="form-field-mandatory"></span>
                          </label>
                        </div>
                        {dateOfTransferErr !== "" ? (
                          <p className="error-msg font-fam-for-all">
                            {dateOfTransferErr}
                          </p>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div
                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                  style={{ fontSize: "14px" }}
                >
                  <span className=" ">
                    <div class="relative">
                      <InputText
                        // className='dialog-form-input-field'
                        id="floating_outlined7"
                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        style={{ width: "100%", height: "40px" }}
                        placeholder=""
                        maxLength={50}
                        value={ingredient}
                        onChange={handleEmail}
                        name="cityName"
                      />

                      <label
                        htmlFor="floating_outlined7"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Email <span className="form-field-mandatory">*</span>
                      </label>
                    </div>
                    {emailErr !== "" ? (
                      <p className="error-msg font-fam-for-all">{emailErr}</p>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="justify-content-center ">
                    <div className="field py-0">
                      <div
                        className="p-inputgroup flex-1"
                        style={{ height: "40px", width: "100%" }}
                      >
                        <Dropdown
                          value={selectedCountry}
                          filter
                          options={countries}
                          onChange={onCountryChange}
                          optionLabel="code"
                          optionValue="dialCode"
                          valueTemplate={selectedCountryTemplate}
                          itemTemplate={countryOptionTemplate}
                          style={{
                            width: "160px",
                            borderRadius: "6px 0px 0px 6px",
                          }}
                          className="p-dropdown font-fam-for-all"
                        />

                        <span className="" style={{ width: "100%" }}>
                          <div className="relative custom-input-mask">
                            <input
                              type="text"
                              id="floating_outlined213"
                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                              style={{
                                height: "40px",
                                borderRadius: "0px 6px 6px 0px",
                                width: "100%",
                              }}
                              maxLength={50}
                              value={phone}
                              onChange={handlePhone}
                              autoClear={false}
                              placeholder=""
                              name="cityName"
                            />
                            <label
                              htmlFor="floating_outlined213"
                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >
                              Phone{" "}
                              <span className="form-field-mandatory">*</span>
                            </label>
                          </div>
                          {phoneErr !== "" ? (
                            <p className="error-msg font-fam-for-all">
                              {phoneErr}
                            </p>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                      {/* <p className='error-msg font-fam-for-all'>{phoneErr}</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className=" input-sidebar-card mt-4">
              <div className="px-5 pt-4">
                <div
                  className="flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="font-fam-for-all srch-cand-text2">
                    <span>
                      <i className="pi pi-address-book"></i>
                    </span>
                    Address
                  </div>
                </div>
              </div>

              <div className="form-content-signup px-5 pb-5">
                <div className="grid  py-2">
                  {/* <div className='col-6' style={{ fontSize: "14px" }}>
                                        <span className=" mr-3">
                                            <div class="relative">
                                                <Dropdown
                                                    value={addressData}
                                                    options={allLocation}
                                                    onChange={(e) => setAddressData(e.value)}
                                                    optionLabel="addressLine1"


                                                    style={{ width: '100%', borderRadius: '6px' }}
                                                    className="p-dropdown font-fam-for-all"
                                                />

                                                <label
                                                    htmlFor="floating_outlined3"
                                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                >
                                                    Address<span className="form-field-mandatory">*</span>
                                                </label>
                                            </div>
                                        </span>
                                    </div>
                                    <div className='col-6'>

                                    </div> */}

                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-6 "
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          maxLength={50}
                          value={addr1}
                          onChange={(e) => {
                            setAddr1Err("");
                            setAddr1(e.target.value);
                          }}
                          name="cityName"
                          id="floating_outlined210001"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        />
                        <label
                          htmlFor="floating_outlined210001"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Address Line 1{" "}
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {addr1Err !== "" ? (
                        <p className="error-msg font-fam-for-all">{addr1Err}</p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-6"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          maxLength={50}
                          value={addr2}
                          onChange={(e) => {
                            setAddr2Err("");
                            setAddr2(e.target.value);
                          }}
                          name="cityName"
                          id="floating_outlined210002"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        />
                        <label
                          htmlFor="floating_outlined210002"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Address Line 2{" "}
                          <span className="form-field-mandatory"></span>
                        </label>
                      </div>
                      {addr2Err !== "" ? (
                        <p className="error-msg font-fam-for-all">{addr2Err}</p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  {/* <div className='col-6' style={{ fontSize: "14px" }}>

                                        <span className=" mr-3">
                                            <div class="relative">
                                                <InputText
                                                    style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                    maxLength={50}
                                                    value={region}
                                                    onChange={(e) => setRegion(e.target.value)}
                                                    name="cityName"
                                                    id="floating_outlined23"
                                                    placeholder=''
                                                    className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                />
                                                <label htmlFor="floating_outlined23"
                                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                >
                                                    County <span className="form-field-mandatory">*</span>
                                                </label>
                                            </div>
                                            {regionErr !== "" ? <p className="error-msg font-fam-for-all">{regionErr}</p> : ""}
                                        </span>

                                    </div> */}
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-6 "
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          maxLength={50}
                          value={city}
                          onChange={(e) => {
                            setcityErr("");
                            setCity(e.target.value);
                          }}
                          name="cityName"
                          id="floating_outlined210003"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        />
                        <label
                          htmlFor="floating_outlined210003"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          City <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {cityErr !== "" ? (
                        <p className="error-msg font-fam-for-all">{cityErr}</p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>

                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-6"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <Dropdown
                          value={states}
                          filter
                          onChange={(e) => {
                            setStatesErr("");
                            setStates(e.value);
                          }}
                          options={allStates}
                          optionValue="id"
                          optionLabel="stateFullDesc"
                          placeholder="Select a State"
                          style={{ height: "40px" }}
                          className="w-full"
                        />
                        <label
                          htmlFor="floating_outlined210004"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          State <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {statesErr !== "" ? (
                        <p className="error-msg font-fam-for-all">
                          {statesErr}
                        </p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-6"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <Dropdown
                          disabled
                          value={countryData}
                          filter
                          onChange={(e) => {
                            setCountryErr("");
                            setCountryData(e.value);
                          }}
                          options={allCountries}
                          optionValue="id"
                          optionLabel="countryFullDesc"
                          placeholder="Select a Country"
                          style={{ height: "40px" }}
                          className="w-full"
                        />
                        <label
                          htmlFor="floating_outlined210005"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Country{" "}
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {countryErr !== "" ? (
                        <p className="error-msg font-fam-for-all">
                          {countryErr}
                        </p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>

                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-6"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{
                            height: "40px",
                            borderRadius: "0px 6px 6px 0px",
                          }}
                          maxLength={5}
                          value={zip}
                          onChange={handlePostalCode}
                          name="cityName"
                          id="floating_outlined210006"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        />
                        <label
                          htmlFor="floating_outlined210006"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Zip Code{" "}
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {zipErr !== "" ? (
                        <p className="error-msg font-fam-for-all">{zipErr}</p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>

                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-6"
                    style={{ fontSize: "14px" }}
                  >
                    <span className=" mr-3">
                      <div class="relative">
                        <Dropdown
                          value={addressType}
                          filter
                          disabled
                          onChange={(e) => setAddressType(e.value)}
                          options={allAddressType}
                          optionLabel="type"
                          placeholder="Select Address Type"
                          style={{ height: "40px" }}
                          className="w-full rounded-lg "
                        />
                        <label
                          htmlFor="floating_outlined210007"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Address Type{" "}
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                    </span>
                  </div>
                </div>
                <div className="grid py-2">
                  {/* <div className='col-4' style={{ fontWeight: "500", fontSize: "14px" }}>


                                        <span className=" mr-3">
                                            <div class="relative">
                                                <Dropdown value={company} onChange={(e) => setCompany(e.value)} options={companyDetails} optionLabel="keyPersonnelName" placeholder="Select Company"
                                                    className="w-full rounded-lg md:w-14rem"

                                                />

                                            </div>
                                        </span>

                                    </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-content-end dialog-form-md-group-Btn my-5">
            <Button
              type="button"
              className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
              label="Cancel"
              style={{ height: "40px" }}
              onClick={cancelView}
            ></Button>
            <Button
              type="submit"
              className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
              label="Submit"
              style={{ height: "40px" }}
            ></Button>
          </div>
        </form>
      </Sidebar>
    </div>
  );
};

export default AddCompanyKeyPersonnel;
