import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import authContext from "../common/authContext";

const Tour = () => {
  const { setSelectedFeature } = useContext(authContext);
  const navigate = useNavigate();

  // Sample features with routes and keys to trigger tours
  const features = [
    { id: 1, name: "Login Functionality", key: "login", route: "/" },
    { id: 2, name: "Order Creation", key: "order", route: "/order" },
    { id: 3, name: "Payment Process", key: "payment", route: "/payment" },
  ];

  const handleStartTour = (feature) => {
    setSelectedFeature(feature);
    navigate(feature.route); // Navigate to the feature's route
  };

  return (
    <div className="min-h-screen mt-8 bg-gray-50 p-8">
      {/* Header Section */}
      <div className="text-center mb-8 font-fam-for-all">
        <h1 className="text-4xl font-bold text-gray-800 mb-2">Take a Tour</h1>
        <p className="text-gray-600 text-lg">
          Explore the key features of our application and learn how to use them efficiently.
        </p>
      </div>

      {/* Features Table */}
      <div className="bg-white shadow rounded-lg p-6">
        <DataTable
          value={features}
          responsiveLayout="scroll"
          selectionMode="single"
          className="w-full font-fam-for-all"
        >
          <Column field="name" header="Feature Name" className="text-gray-700 text-lg"></Column>
          <Column
            header="Action"
            body={(rowData) => (
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-all"
                onClick={() => handleStartTour(rowData)}
              >
                Start Tour
              </button>
            )}
            className="text-center"
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default Tour;
