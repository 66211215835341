import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import authContext from "../common/authContext";

const Tour = () => {
  const { setSelectedFeature } = useContext(authContext);
  const navigate = useNavigate();
  const userData = localStorage.getItem("user");

  // Initialize features as an empty array for any logged-in user
  let features = [];
  let roleName;
  // Check if userData is not null
  if (userData) {
    // Parse the JSON string into an object
    const userObject = JSON.parse(userData);

    // Access the roleName
    const roles = userObject.companyRoles; // Assuming companyRoles is an array
    let isAdmin = false; // Flag to check if the user is an admin

    if (roles && roles.length > 0) {
      // Check if the user has the required roles
      roles.forEach((role) => {
        if (role.roles && role.roles.length > 0) {
          roleName = role.roles[0].roleName; // Accessing the first roleName
          // Output the roleName

          // Set the isAdmin flag if the user is an admin or super admin
          if (
            roleName === "SUPER_ADMINISTRATOR" ||
            roleName === "ADMINISTRATOR"
          ) {
            isAdmin = true;
          }
        }
      });
    } else {
      
    }

    // If the user is an admin, you can add features
    if (isAdmin) {
      features.push(
        { id: 1, name: "Dashboard", key: "dashboard", route: "/dashboard" },
        { id: 2, name: "Orders", key: "orders", route: "/order" },
        { id: 3, name: "Companies", key: "companydash", route: "/companydash" },
        { id: 4, name: "Users", key: "contactdash", route: "/contactdash" },
        { id: 5, name: "My Profile", key: "profile", route: "/profile" },
        { id: 6, name: "My Company", key: "company", route: "/company/self" }
      );
    } else if (!isAdmin && roleName == "EMPLOYEE") {
      features.push(
        {
          id: 1,
          name: "Dashboard",
          key: "employee_dashboard",
          route: "/admindash",
        },
        { id: 2, name: "Orders", key: "employee_order", route: "/order" },
        { id: 3, name: "Companies", key: "emp_company", route: "/companydash" },
        { id: 4, name: "Clients", key: "emp_client", route: "/contact/accountmanager" },
        // { id: 5, name: "My Profile", key: "profile", route: "/profile" },
        // { id: 6, name: "My Company", key: "company", route: "/company/self" }
        // emp_client
      );
    } else {
      // user
      features.push(
        {
          id: 1,
          name: "User Dashboard",
          key: "user_dashboard",
          route: "/dashboard",
        },
        { id: 2, name: "Orders", key: "user_order", route: "/order" },
        // { id: 3, name: "Companies", key: "user_company", route: "/companydash" },
        { id: 4, name: "My Profile", key: "user_myprofile", route: "/profile" },
        {
          id: 5,
          name: "My Company",
          key: "user_mycompany",
          route: "/company/self",
        }
      );
    }

    // Output the updated features
  } else {
    
  }

  const handleStartTour = (feature) => {
    
    setSelectedFeature(feature);
    navigate(feature.route);
  };

  return (
    <div className="min-h-screen bg-gray-50 md:p-2 lg:p-8">
      {/* Header Section */}
      <div className="text-center mb-8 font-fam-for-all">
        <h1 className=" font-bold text-gray-800 mb-2 wlcm-heading">
          Take a Tour
        </h1>
        <p className="paragraph-textt">
          Explore the key features of our application and learn how to use them
          efficiently.
        </p>
      </div>

      {/* Features Table */}
      <div className="bg-white shadow rounded-lg p-6">
        <DataTable
          value={features}
          responsiveLayout="scroll"
          selectionMode="single"
          className="w-full font-fam-for-all"
          scrollHeight="flex"
          scrollWidth="100%"
        >
          <Column
            field="name"
            header="Feature Name"
            className="text-gray-700 text-lg"
          ></Column>
          <Column
            header="Action"
            body={(rowData) => (
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-all"
                onClick={() => handleStartTour(rowData)}
              >
                Start Tour
              </button>
            )}
            // className="text-center"
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default Tour;
