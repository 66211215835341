import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import { Button } from "primereact/button";
import { useRef, useState } from "react";

function ArizonaLLC({ orderData }) {
  // console.log(orderData)
  const componentRef = useRef();
  if (!orderData) {
    
    return <div>No order data available.</div>;
  }


  const strapiOrderFormJson = orderData.items[1].orderId.strapiOrderFormJson;
  const parsedData = JSON.parse(strapiOrderFormJson)
  console.log(parsedData,"parsedData")
  const entityData = parsedData["Payload"].Entity_Formation;
  console.log(entityData,"entityData")

  const formattedDate = new Date()
  const  date = formattedDate.toLocaleDateString();

  // const {
  //   Name: { CD_LLC_Name },
  //   Principal_Address: {
  //     PA_Address_Line_1,
  //     PA_Address_Line_2,
  //     PA_City,
  //     PA_Zip_Code,
  //     PA_State,
  //   },
  //   Registered_Agent: {
  //     RA_Name,
  //     RA_Email_Address,
  //     RA_Contact_No,
  //     Address: {
  //       RA_Address_Line_1,
  //       RA_Address_Line_2,
  //       RA_City,
  //       RA_Zip_Code,
  //       RA_State,
  //     },
  //     Billing_Information: {
  //       BI_Name,
  //       BI_Email_Address,
  //       BI_Contact_No,
  //       BI_Address_Line_1,
  //       BI_Address_Line_2,
  //       BI_City,
  //       BI_Zip_Code,
  //       BI_State,
  //     },
  //     Mailing_Information: {
  //       MI_Name,
  //       MI_Email_Address,
  //       MI_Contact_No,
  //       MI_Address_Line_1,
  //       MI_Address_Line_2,
  //       MI_City,
  //       MI_Zip_Code,
  //       MI_State,
  //     },
  //   },
  //   Organizer_Information: { Org_Name, Org_Email_Address, Org_Contact_No },
  //   Member_Or_Manager_Details: [
  //     {
  //       __temp_key__,
  //       Mom_Member_Or_Manager,
  //       Mom_Name,
  //       Address: {
  //         MM_Address_Line_1,
  //         MM_City,
  //         MM_Zip_Code,
  //         MM_State,
  //       },
  //     },
  //   ],
  // } = entityData;

  const {
    Name: { CD_LLC_Name = "" } = {},
    Principal_Address: {
      PA_Address_Line_1 = "",
      PA_Address_Line_2 = "",
      PA_City = "",
      PA_Zip_Code = "",
      PA_State = "",
    } = {},
    Registered_Agent: {
      RA_Name = "",
      RA_Email_Address = "",
      RA_Contact_No = "",
      Address: {
        RA_Address_Line_1 = "",
        RA_Address_Line_2 = "",
        RA_City = "",
        RA_Zip_Code = "",
        RA_State = "",
      } = {},
      Billing_Information: {
        BI_Name = "",
        BI_Email_Address = "",
        BI_Contact_No = "",
        BI_Address_Line_1 = "",
        BI_Address_Line_2 = "",
        BI_City = "",
        BI_Zip_Code = "",
        BI_State = "",
      } = {},
      Mailing_Information: {
        MI_Name = "",
        MI_Email_Address = "",
        MI_Contact_No = "",
        MI_Address_Line_1 = "",
        MI_Address_Line_2 = "",
        MI_City = "",
        MI_Zip_Code = "",
        MI_State = "",
      } = {},
    } = {},
    Organizer_Information: {
      Org_Name = "",
      Org_Email_Address = "",
      Org_Contact_No = "",
    } = {},
    Member_Or_Manager_Details: [
      {
        __temp_key__ = "",
        Mom_Member_Or_Manager = "",
        Mom_Name = "",
        Address: {
          MM_Address_Line_1 = "",
          MM_City = "",
          MM_Zip_Code = "",
          MM_State = "",
        } = {},
      } = {},
    ] = [],
  } = entityData || {};
  
  

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  const {
    service = "",
    attention1 = "",
    attention2 = "",

    
  } = orderData;


 const sign = Org_Name || ""

  const downloadPDF = async () => {
    const input = document.getElementById("content-to-pdf"); // Main content container
    const sections = Array.from(input.querySelectorAll(".section")); // Get all sections with class 'section'
    const pageWidth = 595.28; // A4 width in points
    const pageHeight = 841.89; // A4 height in points
    const pdfDoc = await PDFDocument.create();
    const scale = 2; // Scale for better resolution
    const pageMargin = 20; // Page margin

    // Function to render content of a section to a page
    const renderSection = async (section, startY) => {
      // Capture the section's content using html2canvas
      const canvas = await html2canvas(section, {
        scale: scale,
        y: startY, // Start rendering from the current vertical position
        height: pageHeight * scale + 20, // Limit height to the size of one page
      });

      const imgData = canvas.toDataURL("image/png");
      const img = await pdfDoc.embedPng(imgData);

      const imgWidth = img.width * (pageWidth / canvas.width) - 40; // Scale image to fit the page width
      const imgHeight = img.height * (pageHeight / canvas.height) - 100; // Scale image to fit the page height

      // Add a new page to the PDF
      const page = pdfDoc.addPage([pageWidth, pageHeight]);

      // Draw the image onto the page
      page.drawImage(img, {
        x: pageMargin,
        y: pageHeight - imgHeight - pageMargin, // Y position from the top of the page
        width: imgWidth,
        height: imgHeight,
      });
    };

    // Render each section on a new page
    for (let i = 0; i < sections.length; i++) {
      await renderSection(sections[i], 0);
    }

    // Serialize the document to bytes
    const pdfBytes = await pdfDoc.save();

    // Trigger the download of the PDF
    const link = document.createElement("a");
    link.href = URL.createObjectURL(
      new Blob([pdfBytes], { type: "application/pdf" })
    );
    link.download = "generated-document.pdf";
    link.click();
  };

  return (
    <div className="p-12" ref={componentRef} id="content-to-pdf">
      {/* page one */}
      <div className="mt-8 section">
        <div
          className="mt-4  mt-32 p-2 border-t-8 border-gray-300"
          style={{ borderColor: "#101828" }}
        >
          <h5 className="text-gray-900 text-center font-inter text-xs font-medium leading-normal">
            DO NOT WRITE ABOVE THIS LINE; RESERVED FOR ACC USE ONLY.
          </h5>

          <p className="text-gray-900 mt-1  text-center font-inter text-2xl font-semibold leading-4">
            ARTICLES OF ORGANIZATION
          </p>

          <p className="text-gray-900 mt-1 text-center font-inter text-xs font-medium leading-normal">
            Read the Instructions{" "}
            <span className="mt-1  font-inter text-xs italic font-medium leading-normal text-blue-500 underline font-inter font-normal leading-7">
              <a
                target="_blank"
                href="https://azcc.gov/docs/default-source/corps-files/instructions/l010i-instructions-articles-of-organization.pdf?sfvrsn=fb0a9180_4"
              >
                L010i
              </a>
            </span>
          </p>

          <p className="text-gray-900  mt-16 font-inter text-lg font-normal ">
            <span className="font-bold">1. ENTITY TYPE - check only one</span>{" "}
            to indicate the type of entity being formed.
          </p>
        </div>
        <div className="flex flex-wrap items-center px-10 justify-center max-w-[960px] max-md:px-5">
          <div
            key="LLC"
            className="flex flex-1 shrink gap-2 items-center self-stretch my-auto basis-0 min-w-[240px] max-md:max-w-full"
            role="radio"
            tabIndex={0}
            style={{ cursor: "pointer" }}
          >
            <div className="flex justify-center items-center self-stretch my-auto w-4">
              <input
                type="checkbox"
                id="limited-liabilty"
                className="w-4 h-4 border-gray-300 rounded"
              />
            </div>
            <div className="self-stretch my-auto text-xs font-medium leading-loose text-gray-900">
              LIMITED LIABILITY COMPANY
              <br />
              (entity name must contain
              <br />
              the words &quot;Limited Liability
              <br />
              Company&quot;, &quot;LLC&quot; or L.C.)
            </div>
          </div>

          <div
            key="Another Option"
            className="flex flex-1 shrink gap-1.5 items-center self-stretch my-auto basis-0 min-w-[240px] max-md:max-w-full"
            role="radio"
            tabIndex={0}
            style={{ cursor: "pointer" }}
          >
            <div className="flex justify-center items-center self-stretch my-auto w-4">
              <input
                type="checkbox"
                id="professional-liabilty"
                className="w-4 h-4 border-gray-300 rounded"
              />
            </div>
            <div className="self-stretch my-auto text-xs font-medium leading-loose text-gray-900">
              PROFESSIONAL LIMITED LIABILITY COMPANY
              <br />
              (entity name must contain
              <br />
              the words &quot;Professional Limited Liability
              <br />
              Company&quot; &quot;PLLC&quot; or &quot;PLC&quot;)
            </div>
          </div>
        </div>

        <p className="text-gray-900 font-inter mt-4 text-lg font-normal leading-7">
          <span className="font-bold">2. ENTITY NAME</span>{" "}
          <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
            <a
              target="_blank"
              href="https://azcc.gov/docs/default-source/corps-files/instructions/l010i-instructions-articles-of-organization.pdf?sfvrsn=fb0a9180_4"
            >
              see Instructions L010i
            </a>
          </span>{" "}
          for full naming requirements – give the exact name of the LLC:
        </p>

        {/* <div className="flex w-full justify-center inline-block">
          <input
            className=" mt-2
            w-full border-b p-2 border-b-2 border-gray-900 bg-blue-50"
            placeholder="$name"
            id="name"
          ></input>
          {name}
        </div> */}

        <div className="flex w-full text-center  inline">
          <span
            className="mt-2 w-full border-b p-6 pb-8  font-bold text-base h-6 border-gray-900"
            id="name"
          >
            {CD_LLC_Name} {/* Place the name variable here */}
          </span>
        </div>

        <div className="mt-4">
          <p className="text-gray-900 font-inter text-lg font-normal leading-7">
            <span className="font-bold">
              3. PROFESSIONAL LIMITED LIABILITY COMPANY SERVICES –
            </span>
            if and only if professional LLC is checked in number 1 above,
            describe the professional services that the professional LLC will
            provide (examples: law firm, accounting, medical):
          </p>
          {/* <div className="flex w-full justify-center">
            <input
              id="service"
              className="text-blue-500 mt-2 
            w-full border-b p-2 border-b-2 border-gray-900 bg-blue-50"
              placeholder="$service"
            ></input>
          </div> */}

          <div className="flex w-full justify-center text-center inline">
            <span
              className="mt-2 w-full border-b font-bold text-base p-6 pb-8 h-6 border-gray-900"
              id="name"
            >
              {service}
            </span>
          </div>
        </div>

        <div className="bg-white w-full mt-5">
          <div className="border border-gray-900 p-4 flex items-center space-x-2">
            <ol>
              <li>
                <span className="font-bold text-lg text-gray-900">
                  4. STATUTORY AGENT for service of process –
                </span>
                <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
                  <a
                    target="_blank"
                    href="https://azcc.gov/docs/default-source/corps-files/instructions/l010i-instructions-articles-of-organization.pdf?sfvrsn=fb0a9180_4"
                  >
                    see Instructions L010i
                  </a>
                </span>
              </li>
            </ol>
          </div>

          <div>
            <table className="w-full table-auto border-collapse">
              <tbody>
                {/* Statutory Agent Name and Mailing Address Section */}
                <tr>
                  <td className="p-4 border border-gray-900 w-1/2">
                    <p className="text-sm text-gray-900 mb-2">
                      <span className="font-bold text-lg text-gray-900">
                        4.1{" "}
                      </span>
                      REQUIRED – give the{" "}
                      <span className="font-bold"> name </span> (can be an
                      Arizona resident or an Arizona-registered entity){" "}
                      <span className="font-bold">
                        {" "}
                        and physical or street address{" "}
                      </span>
                      {/* {" "} */}
                      (not a P.O. Box) in Arizona of the statutory agent
                    </p>
                  </td>
                  <td className="p-4 border border-gray-900 w-1/2">
                    <p className="text-sm text-gray-900 mb-2">
                      <span className="font-bold text-lg text-gray-900">
                        4.2{" "}
                      </span>
                      REQUIRED – mailing address in Arizona of Statutory Agent
                      (can be a P.O. Box):
                    </p>
                    <div className="flex items-center space-x-2 mt-2">
                      <input
                        type="checkbox"
                        id="same-address"
                        className="w-4 h-4 border-gray-300 rounded"
                      />
                      <label
                        htmlFor="same-address"
                        className="text-sm text-gray-900"
                      >
                        Check box if same as physical/street address.
                      </label>
                    </div>
                  </td>
                </tr>

                {/* Address Section: Attention (Optional) */}
                <tr>
                  <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                    {/* <input
                      type="text"
                      id="agentname"
                      className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                    <div
                      type="text"
                      id="agentname"
                      className="w-full font-bold  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                      // placeholder="$"
                    >
                      {RA_Name}
                    </div>
                    <label htmlFor="agentname" className="text-sm">
                      {" "}
                      Statutory Agent Name
                    </label>
                  </td>
                  <td className="p-2 border border-gray-900 w-1/2">
                    {/* <label htmlFor="attention2" className="sr-only">Attention (Optional)</label>
          <input
            type="text"
            id="attention2"
            className="w-full p-2 border-b-2 border-gray-900 bg-blue-50"
            placeholder="Attention (Optional)"
          /> */}
                  </td>
                </tr>

                {/* Address Row 1 */}
                <tr>
                  <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                    {/* <input
                      type="text"
                      id="attention1"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                    <div
                      id="attention1"
                      className="w-full p-2 font-bold border-b-2 mb-2 border-gray-900 bg-blue-50"
                    >
                      {attention1}
                    </div>
                    <label htmlFor="attention1" className="text-sm">
                      Attention (Optional)
                    </label>
                  </td>
                  <td className="p-2 border border-gray-900 w-1/2">
                    {/* <input
                      type="text"
                      id="attention2"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      placeholder="Attention (Optional)"
                    /> */}

                    <div
                      // type="text"
                      id="attention2"
                      className="w-full p-2 font-bold border-b-2 mb-2 border-gray-900 bg-blue-50"
                      // placeholder="Attention (Optional)"
                    >
                      {attention2}
                    </div>
                    <label htmlFor="attention2" className="text-sm">
                      Attention (Optional)
                    </label>
                  </td>
                </tr>

                {/* Address Row 2 */}
                <tr>
                  <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                    {/* <input
                      type="text"
                      id="address1"
                      className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                    <div
                      // type="text"
                      id="address1"
                      className="w-full p-2 mb-2 border-b-2  font-bold border-gray-900 bg-blue-50"
                      // placeholder="$"
                    >
                      {RA_Address_Line_1}
                    </div>
                    <label htmlFor="address1" className="text-sm  ">
                      {" "}
                      Address 1
                    </label>
                  </td>
                  <td className="p-2 border border-gray-900 w-1/2">
                    {/* <input
                      type="text"
                      id="address2"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      // placeholder="Attention (Optional)"
                    /> */}

                    <div className="w-full p-2  font-bold border-b-2 mb-2 border-gray-900 bg-blue-50">
                      {RA_Address_Line_1}
                    </div>
                    <label htmlFor="address2" className="text-sm ">
                      Address 1
                    </label>
                  </td>
                </tr>

                <tr className="">
                  <td className="border border-gray-900 flex justify-center  items-center h-full w-full">
                    <td className="p-4   w-1/2">
                      {/* <input
                        type="text"
                        id="address2-1"
                        className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                        // placeholder="Address 2 (optional)"
                      /> */}
                      <div className="w-full font-bold p-2 mb-2 border-b-2 border-gray-900 bg-blue-50">
                        {RA_Address_Line_2}
                      </div>
                      <label htmlFor="address2-1" className="text-sm">
                        Address 2 (optional)
                      </label>

                      <td className="p-4 flex  gap-2 items-center  w-full">
                        <label htmlFor="city2" className="text-sm">
                          City {"   "}
                        </label>

                        {/* <input
                          type="text"
                          id="city2"
                          className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                          // placeholder="City"
                          required
                        /> */}

                        <div className="w-full font-bold p-2 mb-2 border-b-2 border-gray-900 bg-blue-50">
                          {RA_City}
                        </div>
                      </td>
                    </td>
                    <td className="w-1/3  h-48 items-center flex  justify-center text-center border border-grey-900">
                      <div>
                        <span className="text-grey-900  font-inter text-lg font-bold leading-6">
                          {" "}
                          AZ{" "}
                        </span>
                        <br></br>
                        State
                      </div>
                    </td>

                    <td className="p-6 w-1/3">
                      {/* <input
                        type="text"
                        id="zip1"
                        className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                        required
                        pattern="[0-9]{5}"
                      /> */}
                      <div className="w-full font-bold text-center  p-2 mb-2 border-b-2 border-gray-900 bg-blue-50">
                        {RA_Zip_Code}
                      </div>
                      <label htmlFor="zip1">ZIP</label>
                    </td>
                  </td>
                  <td className="   border-b border-r  border-black  ">
                    <div className="flex gap-2 h-full w-full justify-center items-center py-0">
                      <td className="px-2  w-full ">
                        {/* <input
                        type="text"
                        id="address2-2"
                        className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                        // placeholder="Address 2 (optional)"
                      /> */}

                        <div
                          id="address2-2"
                          className="  mb-2 p-2 font-bold border-b-2 border-gray-900 bg-blue-50"
                        >
                          {" "}
                          {RA_Address_Line_2}
                        </div>
                        <label htmlFor="address2-2" className="text-sm">
                          Address 2 (optional)
                        </label>

                        <td className="p-4 flex gap-2  w-full items-center">
                          <label htmlFor="citytwo" className="text-sm  ">
                            City
                          </label>

                          {/* <input
                          type="text"
                          id="citytwo"
                          className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                          // placeholder="City"
                          required
                        /> */}

                          <div
                            id="citytwo"
                            className="w-full font-bold mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                          >
                            {" "}
                            {RA_City}
                          </div>
                        </td>
                      </td>
                      <td className=" w-full  h-48 items-center p-16 px-2  text-center border  border-grey-900">
                        <div>
                          <span className="text-grey-900 font-inter text-lg font-bold leading-6">
                            {" "}
                            AZ{" "}
                          </span>
                          <br></br>
                          State
                        </div>
                      </td>
                      <td className="px-2  w-full  ">
                        {/* <input
                        type="text"
                        id="ziptwo"
                        className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                        required
                        pattern="[0-9]{5}"
                      /> */}

                        <div className="w-full text-center font-bold  p-2 mb-2 border-b-2 border-gray-900 bg-blue-50">
                          {RA_Zip_Code}
                        </div>
                        <label htmlFor="ziptwo">ZIP</label>
                      </td>
                    </div>
                  </td>{" "}
                </tr>

                {/* Footer Section */}
                <tr>
                  <td
                    colSpan="2"
                    className="border-b  border-r border-l border-gray-900 p-4"
                  >
                    <p className="text-gray-900 font-inter text-lg font-normal leading-7">
                      <span className="text-lg  font-bold text-gray-900">
                        4.3
                      </span>{" "}
                      REQUIRED - the
                      <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
                        {" "}
                        <a
                          target="_blank"
                          href="https://azcc.gov/docs/default-source/corps-files/forms/m002-statutory-agent-acceptance.pdf?sfvrsn=50c6b82b_6"
                        >
                          Statutory Agent Acceptance
                        </a>
                      </span>
                      <span className="text-lg text-gray-900">
                        {" "}
                        form M002 must be submitted along with these Articles of
                        Organization.
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="border-none my-4">
              <p className="text-gray-900 font-inter text-lg font-bold leading-7">
                5. PRINCIPAL ADDRESS:
              </p>
              <p className="text-gray-900 ml-4  font-inter text-base font-normal leading-7 mb-2">
                <span className="text-gray-900 font-inter text-lg font-bold leading-7">
                  5.1{" "}
                </span>
                Is the principal address the same as the
                <span className="text-gray-900 font-inter text-base font-bold leading-6">
                  {"  "}street address
                </span>{" "}
                of the statutory agent?
              </p>

              <div className="flex justify-center w-[80%] items-center flex-col">
                <div className="grid gap-2">
                  <div>
                    <input type="checkbox" id="saidyes" />
                    <label className="text-gray-900 font-inter text-base font-bold leading-6">
                      {" "}
                      Yes -
                    </label>
                    go to number 6 and continue
                  </div>

                  <div>
                    <input type="checkbox" id="saidno" />{" "}
                    <span className="text-gray-900 font-inter text-base font-bold leading-6">
                      No -
                    </span>{" "}
                    go to number 5.2 and continue
                  </div>
                </div>
              </div>

              <div className="flex w-full justify-between">
                <div>
                  <p className="text-gray-900 font-inter text-sm font-normal leading-6">
                    L010.007
                  </p>
                  <p className="text-gray-900 font-inter text-sm font-normal leading-6">
                    Rev: 10/2020
                  </p>
                </div>

                <div className="text-right">
                  <p className="text-gray-900 font-inter text-sm font-normal leading-6">
                    Arizona Corporation Commission – Corporations Division
                  </p>
                  <p className="text-gray-900 font-inter text-sm font-normal leading-6">
                    Page 1 of 2
                  </p>
                </div>
              </div>
            </div>
          </div>

          <hr className="border-t border-gray-300 my-8" />
        </div>
      </div>
      {/* page two */}
      <div className="section">
        <table className="w-full table-auto border-collapse ">
          <tbody>
            <tr>
              <td className="p-4 border border-gray-900 w-1/2" colSpan={2}>
                <span className="text-gray-900 font-inter text-lg font-bold leading-7">
                  5.2{" "}
                </span>
                <p className="text-gray-900 font-inter text-lg font-normal leading-7">
                  If you answered{" "}
                  <span className="text-gray-900 font-inter text-lg font-bold leading-7">
                    “No”
                  </span>{" "}
                  to number 5.1, provide the principal address below:
                </p>
              </td>
            </tr>

            {/* Address Section: Attention (Optional) */}
            <tr>
              <td className="p-4 border border-gray-900 w-1/2 " colSpan={2}>
                {/* <input
                      type="text"
                      id="attention1"
                      className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                <div
                  // type="text"
                  // id="attention1"
                  className="w-full font-bold mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                  // placeholder="$"
                >
                  {" "}
                  {PA_Address_Line_1}
                </div>
                <p className="text-sm">Attention (optional)</p>
              </td>
            </tr>

            {/* Address Row 2 */}
            <tr>
              <td className="p-4 border border-gray-900 w-1/2 " colSpan={2}>
                {/* <input
                      type="text"
                      id="attention1"
                      className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                <div className="w-full font-bold p-2 mb-2 border-b-2 border-gray-900 bg-blue-50">
                  {" "}
                  {PA_Address_Line_1}
                </div>

                <label className="text-sm">Address 1</label>
              </td>
            </tr>

            <tr className="w-full">
              <td className=" border border-gray-900 w-1/4 ">
                <td className=" w-96  p-4   ">
                  {/* <input
                        type="text"
                        id="address2-2"
                        className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                        // placeholder="Address 2 (optional)"
                      /> */}

                  <div className="font-bold  p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50">
                    {" "}
                    {PA_Address_Line_2}{" "}
                  </div>
                  <label htmlFor="address2-2" className="text-sm">
                    Address 2 (optional)
                  </label>

                  <td className="p-2 flex items-center">
                    <label htmlFor="city2" className="text-sm mr-2">
                      {" "}
                      City Country
                    </label>
                    {/* <input
                          type="text"
                          id="city2"
                          className="w-1/2  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                          //   placeholder="City"
                          required
                        /> */}

                    <div className="w-1/2 font-bold  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50">
                      {" "}
                      {PA_City}{" "}
                    </div>
                  </td>
                </td>
                <td className="w-96 p-4  border border-grey-900">
                  {/* <input
                        type="text"
                        id="city2"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        // placeholder="state"
                        required
                      /> */}

                  <div className="w-full p-2 font-bold border-b-2 mb-2 border-gray-900 bg-blue-50">
                    {PA_State}{" "}
                  </div>

                  <label htmlFor="city2" className="text-sm text-left">
                    State or Province
                  </label>
                </td>
                <td className="w-96 p-4  ">
                  <div className="flex flex-col w-full justify-center">
                    {/* <input
                          type="text"
                          id="zip1"
                          className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                          required
                          // pattern="[0-9]{5}"
                        /> */}

                    <div className="w-[80%] font-bold  p-2 border-b-2 border-gray-900 bg-blue-50">
                      {PA_Zip_Code}
                    </div>

                    <br />
                    <label htmlFor="zip1" className="text-sm">
                      ZIP
                    </label>
                  </div>
                </td>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text-gray-900 my-4 font-inter text-lg font-bold leading-7">
          COMPLETE NUMBER 6 OR NUMBER 7 – NOT BOTH.
        </p>

        <div>
          <p className="text-gray-900 inline font-inter text-lg font-bold leading-7">
            6. MANAGER-MANAGED LLC –{" "}
            <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
              <a
                target="_blank"
                href="https://azcc.gov/docs/default-source/corps-files/instructions/l010i-instructions-articles-of-organization.pdf?sfvrsn=fb0a9180_4"
              >
                see Instructions L010i{" "}
              </a>
            </span>
            – check this box
          </p>{" "}
          <input
            type="checkbox"
            id="manager"
            className="inline   h-4 w-4  rounded-sm scale-150"
          />
          <span className="text-gray-900 font-inter text-lg font-medium leading-7">
            {" "}
            if management of the LLC will be vested in a manager or managers
            (meaning one or more managers will run the company) and complete and
            attach ONLY the
            <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
              <a
                target="_blank"
                href="https://azcc.gov/docs/default-source/corps-files/forms/l040-manager-structure-attachment.pdf?sfvrsn=ca8b7deb_6"
              >
                {" "}
                Manager Structure Attachment form L040.
              </a>{" "}
            </span>
            (Both members and managers will be listed on the Manager Structure
            Attachment.) The filing will be rejected if it is submitted without
            the attachment.
          </span>
        </div>

        <div className="mt-4">
          <p className="text-gray-900 font-inter text-lg font-bold leading-7">
            7.MEMBER-MANAGED LLC –{" "}
            <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
              <a
                target="_blank"
                href="https://azcc.gov/docs/default-source/corps-files/instructions/l010i-instructions-articles-of-organization.pdf?sfvrsn=fb0a9180_4"
              >
                see Instructions L010i
              </a>
            </span>{" "}
            – check this box{" "}
            <input
              type="checkbox"
              id="member"
              className="inline   h-4 w-4  rounded-sm scale-150"
            />
            <span className="text-gray-900 font-inter text-lg font-medium leading-7">
              {" "}
              if management of the LLC will be reserved to the members (meaning
              all members will run the company together if there is no operating
              agreement stating otherwise), and complete and attach ONLY the
              <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
                <a
                  target="_blank"
                  href="https://azcc.gov/docs/default-source/corps-files/forms/l041-member-structure-attachment.pdf?sfvrsn=ceb9f754_2"
                >
                  {" "}
                  Member Structure Attachment form L041.
                </a>
              </span>
              (All members will be listed on the Member Structure Attachment.)
              The filing will be rejected if it is submitted without the
              attachment.
            </span>
          </p>
        </div>

        <div className="px-64 py-20 text-lg font-bold leading-8 text-gray-900 max-w-[960px] max-md:px-5 max-md:max-w-full">
          The person signing below declares and certifies under penalty of law
          that the information contained within this document together with any
          attachments is true and correct, and is submitted in compliance with
          Arizona law.
        </div>

        <div className="flex justify-between gap-2">
          <div className="flex flex-col w-full">
            {/* <input
                  type="text"
                  className="w-1/50  p-2 border-b-2 border-gray-900 bg-blue-50"
                /> */}

            <div
              type="text"
              className="w-1/50 font-bold  p-2 border-b-2 border-gray-900 bg-blue-50"
            >
              {" "}
              {sign}
            </div>
            <label
              htmlFor=""
              className="text-gray-900 mb-4 font-inter text-xs font-normal leading-6"
            >
              {" "}
              Signature
            </label>
          </div>

          <div className="flex flex-col">
            {/* <input
                  type="text"
                  className="w-1/50 p-2 border-b-2 border-gray-900 bg-blue-50"
                /> */}

            <div
              type="text"
              className="w-1/50 font-bold p-2 border-b-2 border-gray-900 bg-blue-50"
            >
              {date}
            </div>

            <label
              htmlFor=""
              className="text-gray-900 font-inter text-xs font-normal leading-6"
            >
              {" "}
              Date
            </label>
          </div>
        </div>
        <p className="text-gray-900 font-inter text-lg font-bold leading-7">
          Expedited or Same Day/Next Day services are available for an
          additional fee – see Instructions or Cover sheet for prices.
        </p>

        <table className="w-full table-auto my-4 border-collapse">
          <tbody>
            {/* Statutory Agent Name and Mailing Address Section */}
            <tr>
              <td className="p-4 border border-gray-900 w-1/2">
                <div className="flex gap-2  flex-col justify-center">
                  <p className="text-gray-900 font-inter text-xs font-normal leading-6">
                    Filing Fee: $50.00 (regular processing)
                  </p>

                  <p className="text-gray-900 font-inter text-xs font-normal leading-6">
                    All fees are nonrefundable - see Instructions.
                  </p>
                </div>
              </td>
              <td className="p-4 border border-gray-900 w-1/2">
                <div className="flex flex-col gap-2 justify-center">
                  <p className="text-gray-900 font-inter text-xs font-normal leading-6">
                    Mail: Arizona Corporation Commission - Examination Section
                    1300 W. Washington St., Phoenix, Arizona 85007
                  </p>

                  <p className="text-gray-900 font-inter text-xs font-normal leading-6">
                    Fax (for Regular or Expedite Service ONLY): 602-542-4100
                  </p>

                  <p className="text-gray-900 font-inter text-xs font-normal leading-6">
                    Fax (for Same Day/Next Day Service ONLY): 602-542-0900
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <p className="text-sm my-2">
          Please be advised that A.C.C. forms reflect only the
          <span className="text-gray-900 font-inter text-xs font-bold leading-6">
            {" "}
            minimum{" "}
          </span>
          provisions required by statute. You should seek private legal counsel
          for those matters that may pertain to the individual needs of your
          business. All documents filed with the Arizona Corporation Commission
          are
          <span className="text-gray-900 font-inter text-xs font-bold leading-6">
            {" "}
            public record
          </span>{" "}
          and are open for public inspection. If you have questions after
          reading the Instructions, please call 602-542-3026 or (within Arizona
          only) 800-345-5819.
        </p>

        <div className="flex px-8 w-full justify-between">
          <div>
            <p className="text-gray-900 font-inter text-sm font-normal leading-6">
              L010.007
            </p>
            <p className="text-gray-900 font-inter text-sm font-normal leading-6">
              Rev: 10/2020
            </p>
          </div>

          <div className="text-right">
            <p className="text-gray-900 font-inter text-sm font-normal leading-6">
              Arizona Corporation Commission – Corporations Division
            </p>
            <p className="text-gray-900 font-inter text-sm font-normal leading-6">
              Page 2 of 2
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-content-center p-2">
        <Button
          className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
          label="Download PDF"
          icon="pi pi-download"
          onClick={downloadPDF}
        ></Button>
      </div>
    </div>
  );
}

export default ArizonaLLC;
