import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";
import imgsrc from "../../../../Assets/Images/uccfilling.png";
import AOS from "aos";
import "aos/dist/aos.css";

const services = [
  {
    title: "Protect Business Transactions",
    description:
      "UCC filings secure your interests in commercial transactions, making it clear who holds rights to assets or collateral.",
  },
  {
    title: "Legal Confidence",
    description:
      "Whether filing or searching for liens, UCC filings ensure that your transactions are protected and verified.",
  },
  {
    title: "Risk Mitigation",
    description:
      "By filing UCCs, you avoid disputes over asset ownership and protect your claims in financial agreements.",
  },
  {
    title: "Comprehensive Search Solutions",
    description:
      "Our UCC search services help uncover existing liens and ensure that any potential risks in transactions are identified.",
  },
];

const UcFillingSec = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="main-section-class">
      <div className="entity-formation">
        <Container>
          <CommonHeadingTitle
            heading={"Why do"}
            blueheading={"UCC filings matter?"}
          />
          <Row className="mt-5">
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <Row>
                {services.map((service, index) => (
                  <Col lg={12} md={12} sm={12} xs={12} key={index}>
                    <div className="main-class-div">
                      <h4 className="medium-level-heading">{service.title}</h4>
                      <div className="grey-border mt-3 mb-3"></div>
                      <p className="normal-common-txt">{service.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <img loading="lazy" className="user-img-buisness" src={imgsrc} />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default UcFillingSec;
