import React from "react";
import { useNavigate } from "react-router-dom";

function ActionButtons() {
    const history = useNavigate()
    return (
        <div className="flex gap-4 items-start mt-8 w-full text-sm font-medium leading-none max-md:max-w-full">
            <div className="flex flex-wrap flex-1 shrink gap-3 items-start w-full basis-0 min-w-[240px] max-md:max-w-full" onClick={() => history("/order")}>
                <button className="flex flex-1 shrink items-start whitespace-nowrap rounded-lg basis-0 min-w-[240px] text-slate-700" >
                    <span className="flex overflow-hidden flex-1 shrink gap-2 justify-center items-center px-4 py-2.5 w-full bg-white-600 rounded-lg border border-blue-600 border-solid shadow-sm basis-0 min-w-[240px]">
                        <svg xmlns="http://www.w3.org/2000/svg"  height="20" viewBox="0 0 1 20" fill="none">
                            {/* <g clip-path="url(#clip0_2635_44422)">
                                <path d="M4.99984 7.49984V1.6665H14.9998V7.49984M4.99984 14.9998H3.33317C2.89114 14.9998 2.46722 14.8242 2.15466 14.5117C1.8421 14.1991 1.6665 13.7752 1.6665 13.3332V9.1665C1.6665 8.72448 1.8421 8.30055 2.15466 7.98799C2.46722 7.67543 2.89114 7.49984 3.33317 7.49984H16.6665C17.1085 7.49984 17.5325 7.67543 17.845 7.98799C18.1576 8.30055 18.3332 8.72448 18.3332 9.1665V13.3332C18.3332 13.7752 18.1576 14.1991 17.845 14.5117C17.5325 14.8242 17.1085 14.9998 16.6665 14.9998H14.9998M4.99984 11.6665H14.9998V18.3332H4.99984V11.6665Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2635_44422">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs> */}
                        </svg>
                        <span className="self-stretch my-auto">Close</span>

                    </span>
                </button>
                <button className="flex flex-1 shrink items-start text-center text-white rounded-lg basis-0 min-w-[240px]" >
                    <span className="flex overflow-hidden flex-1 shrink gap-2 justify-center items-center px-4 py-2.5 w-full bg-blue-600 rounded-lg border border-blue-600 border-solid shadow-sm basis-0 min-w-[240px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clip-path="url(#clip0_2635_44422)">
                                <path d="M4.99984 7.49984V1.6665H14.9998V7.49984M4.99984 14.9998H3.33317C2.89114 14.9998 2.46722 14.8242 2.15466 14.5117C1.8421 14.1991 1.6665 13.7752 1.6665 13.3332V9.1665C1.6665 8.72448 1.8421 8.30055 2.15466 7.98799C2.46722 7.67543 2.89114 7.49984 3.33317 7.49984H16.6665C17.1085 7.49984 17.5325 7.67543 17.845 7.98799C18.1576 8.30055 18.3332 8.72448 18.3332 9.1665V13.3332C18.3332 13.7752 18.1576 14.1991 17.845 14.5117C17.5325 14.8242 17.1085 14.9998 16.6665 14.9998H14.9998M4.99984 11.6665H14.9998V18.3332H4.99984V11.6665Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2635_44422">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span className="self-stretch my-auto">Print receipt</span>
                    </span>
                </button>
            </div>
        </div>
    );
}

export default ActionButtons;