import * as React from "react";
import { useNavigate } from "react-router-dom";
import propertiesApp from "../utils/propertiesApp";

function VstatePartner() {

  const navigate= useNavigate()

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate({
          pathname: "/signup",
          search: `?${new URLSearchParams({ vendorId: propertiesApp.partner_code }).toString()}`,
        });
    };

    const handleSubmit1 = (event) => {
      event.preventDefault();
      document.getElementById("hiddenForm1").submit();
  };
  return (
    <div className="flex flex-col">
      <div className="flex overflow-hidden flex-col w-full bg-white max-md:max-w-full">
        <div className="flex overflow-hidden flex-col w-full text-sm font-medium tracking-wide leading-loose text-center whitespace-nowrap shadow-lg h-[85px] text-teal-950 max-md:max-w-full">
          <div className="flex items-start w-full bg-white max-md:max-w-full">
            <div className="flex overflow-hidden flex-1 shrink gap-10 items-center px-8 py-6 w-full basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6db10ce24b92005ddbc782c211a5dfaae2733c37d6fc0a9b84f68ee810f51d73?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6db10ce24b92005ddbc782c211a5dfaae2733c37d6fc0a9b84f68ee810f51d73?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6db10ce24b92005ddbc782c211a5dfaae2733c37d6fc0a9b84f68ee810f51d73?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6db10ce24b92005ddbc782c211a5dfaae2733c37d6fc0a9b84f68ee810f51d73?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6db10ce24b92005ddbc782c211a5dfaae2733c37d6fc0a9b84f68ee810f51d73?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6db10ce24b92005ddbc782c211a5dfaae2733c37d6fc0a9b84f68ee810f51d73?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6db10ce24b92005ddbc782c211a5dfaae2733c37d6fc0a9b84f68ee810f51d73?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6db10ce24b92005ddbc782c211a5dfaae2733c37d6fc0a9b84f68ee810f51d73?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
                className="object-contain shrink-0 self-start max-w-full aspect-[5.81] w-[116px]"
              />
              <div className="self-stretch my-auto leading-loose">Products</div>
              <div className="self-stretch my-auto">Solutions</div>
              <div className="self-stretch leading-loose">Developers</div>
              <div className="grow shrink self-stretch my-auto w-[1352px] max-md:max-w-full">
                Resources
              </div>
            </div>
          </div>
          <div className="flex w-full bg-gray-200 fill-gray-200 min-h-[1px] max-md:max-w-full" />
          <div className="flex w-full min-h-[68px] max-md:max-w-full" />
        </div>
        <div className="flex overflow-hidden relative flex-col justify-center self-center px-80 py-36 w-full max-md:px-5 max-md:py-24 max-md:max-w-full">
          <div className="flex absolute inset-x-0 z-0 flex-col w-full bottom-[-241px] h-[555px] max-md:max-w-full">
            <div className="flex flex-col justify-center w-full max-md:max-w-full">
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/be438d8f343ca4ad74b0703e086ff0c475c17abcc84772bf98de77e5be59dfe6?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/be438d8f343ca4ad74b0703e086ff0c475c17abcc84772bf98de77e5be59dfe6?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/be438d8f343ca4ad74b0703e086ff0c475c17abcc84772bf98de77e5be59dfe6?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/be438d8f343ca4ad74b0703e086ff0c475c17abcc84772bf98de77e5be59dfe6?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/be438d8f343ca4ad74b0703e086ff0c475c17abcc84772bf98de77e5be59dfe6?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/be438d8f343ca4ad74b0703e086ff0c475c17abcc84772bf98de77e5be59dfe6?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/be438d8f343ca4ad74b0703e086ff0c475c17abcc84772bf98de77e5be59dfe6?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/be438d8f343ca4ad74b0703e086ff0c475c17abcc84772bf98de77e5be59dfe6?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
                className="object-contain flex-1 w-full aspect-[3.46] max-md:max-w-full"
              />
            </div>
          </div>
          <div className="flex z-0 flex-col px-10 w-full max-md:px-5 max-md:max-w-full">
            <div className="flex flex-wrap items-center w-full max-md:max-w-full">
              <div className="flex overflow-hidden flex-col justify-center items-start self-stretch my-auto min-w-[240px] w-[560px] max-md:max-w-full">
                <div className="flex flex-col self-stretch w-full max-md:max-w-full">
                  <div className="w-full text-5xl font-semibold tracking-tighter text-black leading-[58px] max-md:max-w-full max-md:text-4xl max-md:leading-[54px]">
                    Partner with{" "}
                    <span className="text-emerald-500">vState Filings</span> to
                    streamline business filing process.{" "}
                  </div>
                  <div className="flex flex-col mt-5 w-full text-lg font-light leading-8 text-slate-600 max-md:max-w-full">
                    <div className="pb-px w-full max-md:max-w-full">
                      vState Filings provides business insurance to your filing
                      platform, enhancing your services and providing clients
                      with tailored legal and compliance protection.
                    </div>
                  </div>
                </div>

                <div className="flex flex-col mt-9">
                  <button onClick={handleSubmit} className="flex overflow-hidden flex-col justify-center items-center p-2 bg-emerald-500 rounded max-md:px-5">
                    <div className="flex gap-4 justify-center items-center">
                      <div className="flex flex-col justify-center  text-base font-medium  text-white ">
                        Get Quote
                      </div>
                      <div className="flex overflow-hidden items-center self-stretch my-auto w-6 min-h-[24px]">
                        <div className="flex flex-col justify-center self-stretch my-auto w-6 min-h-[24px]">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3658a1ef4e7d8c88ca0bc758b69b428b06871390a80e76e48dd93ca76e1dc8ca?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
                            className="object-contain w-full aspect-square"
                          />
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="flex flex-col mt-9">
                  <button onClick={handleSubmit1} className="flex overflow-hidden flex-col justify-center items-center p-2 bg-emerald-500 rounded max-md:px-5">
                    <div className="flex gap-4 justify-center items-center">
                      <div className="flex flex-col justify-center  text-base font-medium  text-white ">
                        Get Order
                      </div>
                      <div className="flex overflow-hidden items-center self-stretch my-auto w-6 min-h-[24px]">
                        <div className="flex flex-col justify-center self-stretch my-auto w-6 min-h-[24px]">
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3658a1ef4e7d8c88ca0bc758b69b428b06871390a80e76e48dd93ca76e1dc8ca?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
                            className="object-contain w-full aspect-square"
                          />
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="flex gap-4 items-center pt-px mt-9 text-sm leading-6 text-neutral-600">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6111cbe40e11292dc54825944a69098c429c5f7a4acd033c761a0462cb41fa0a?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6111cbe40e11292dc54825944a69098c429c5f7a4acd033c761a0462cb41fa0a?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6111cbe40e11292dc54825944a69098c429c5f7a4acd033c761a0462cb41fa0a?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6111cbe40e11292dc54825944a69098c429c5f7a4acd033c761a0462cb41fa0a?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6111cbe40e11292dc54825944a69098c429c5f7a4acd033c761a0462cb41fa0a?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6111cbe40e11292dc54825944a69098c429c5f7a4acd033c761a0462cb41fa0a?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6111cbe40e11292dc54825944a69098c429c5f7a4acd033c761a0462cb41fa0a?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6111cbe40e11292dc54825944a69098c429c5f7a4acd033c761a0462cb41fa0a?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
                    className="object-contain shrink-0 self-stretch my-auto aspect-[2.62] w-[131px]"
                  />
                  <div className="flex flex-col justify-center self-stretch my-auto w-[163px]">
                    <div className="flex flex-col max-w-full w-[163px]">
                      <div className="w-full">32k Satisfied Customers</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-1 shrink items-center self-stretch my-auto basis-0 min-w-[240px] max-md:max-w-full">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a6231cd27b67e4cf64dc6fb655b92d46ed37512dad75b5e14f882603e50b386?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
                  className="object-contain self-stretch my-auto aspect-square min-w-[240px] w-[500px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <form id="hiddenForm" action="http://www.redberyltest.in/#/signup" method="GET" style={{ display: 'none' }}>
                <input type="hidden" name="vendorId" value="4" />
            </form>
            <form id="hiddenForm1" action="http://www.redberyltest.in/#/order" method="GET" style={{ display: 'none' }}>
                <input type="hidden" name="orderId" value="22" />
            </form>
    </div>
  );
}

export default VstatePartner;