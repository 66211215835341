import React, { useContext, useEffect } from 'react'
import { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from "primereact/radiobutton";
import { Message } from 'primereact/message'
import { useForm, Controller } from "react-hook-form";
import { classNames } from 'primereact/utils';
import ShiftsServices from '../../services/demoServices';
import { Dropdown } from 'primereact/dropdown';
import { Sidebar } from 'primereact/sidebar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Checkbox } from 'primereact/checkbox';
import { Avatar } from 'primereact/avatar';
import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';
import errorMessages, { globalMessages } from '../../utils/errorMessages';
import { InputMask } from 'primereact/inputmask';
import CreditCardServices from '../../services/strapiService';
import UserServices from '../../services/userService';
import templateCsv from "../../Assets/company_data.csv"


import { ProgressBar } from 'primereact/progressbar';

import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import authContext from '../../common/authContext';
import { showToast } from '../../utils/toastUtils';
import companyMasterService from '../../services/companyMasterService';


import axios from 'axios';
import { initAuth } from '../../services/authHeader';
import ContactService from '../../services/ContactService';

const BulkCompanyUpload = ({ visible, setVisible, pageRefresh, setPageRefresh }) => {
    const { permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading } = useContext(authContext);
    


    const [ingredient, setIngredient] = useState('');
    const toast = useRef(null);
    const [checked, setChecked] = useState(true)
    const [password, setPassword] = useState("")
    const [confirmedPassword, setConfirmPassword] = useState("")
    const [fileError,setFileError] = useState("")
    const [email, setEmail] = useState("")
    const [cityErr, setcityErr] = useState('')

    const toastCenter = useRef(null);



    const [firstName, setFirstName] = useState("")

    const [lastName, setLastName] = useState("")

    const [website, setWebsite] = useState("")

    const [company, setCompany] = useState("")

    const [phone, setPhone] = useState("")

    const [address, setAddress] = useState("")

    const [city, setCity] = useState("")

    const [state, setState] = useState("")

    const [zip, setZip] = useState("")

    const [emailErr, setEmailErr] = useState("")

    const [passwordErr, setPasswordErr] = useState("")

    const [confirmPasswordErr, setConfirmPasswordErr] = useState("")

    const [firstNameErr, setFirstNameErr] = useState("")

    const [lastNameErr, setLastNameErr] = useState("")

    const [phoneErr, setPhoneErr] = useState("")

    const [selectedCountry, setSelectedCountry] = useState(null);

    const [roleId, setRoleId] = useState(null)

    const [companyId, setCompanyId] = useState(null)

    const [globalErr, setGlobalErr] = useState("")

    const [isFocused, setIsFocused] = useState(false);

    const [isDisabled, setIsDisabled] = useState(false)

    const [superAdminId, setSuperAdminId] = useState(null)

  

    const fileInputRef = useRef(null)

    

    const [selectedFile, setSelectedFile] = useState(null);

 
    const handleFileChange = (e) => {
     
        const file = e.target.files[0];
        if (!file) {
            console.log("No file selected!");
            return;
        }
       
 
        console.log("File selected:", file.name, "Size:", file.size);
 
    // Check file size (must be under 250KB)
    if (file.size > 250 * 1024) {
        setFileError("File size is larger than 250KB");
        setSelectedFile(null); // Reset selected file
    } else {
        setFileError("");
        setSelectedFile(file);
    }
    };

    const user = JSON.parse(localStorage.getItem('user'));

    console.log(user,"user")
    const [userId, setUserId]=useState("")
    if(user){
        
        ContactService.getAllContactByUserId(user?.companyRoles?.[0]?.companyDetails?.primaryContactId?.userId).then((res) => {
            console.log(res,"dsafadsdfas")
            setUserId(res?.data?.contactDetails?.id)}).catch((res)=>console.log(res))

    }



    
    function checkIdsExist(arr, idsToCheck) {
        const foundIds = idsToCheck.filter(idToCheck => arr.some(item => item.id === idToCheck));
        return foundIds.length > 0 ? foundIds : null;
    }

    useEffect(() => {
        if (user) {
            
            if (globalCompany !== null) {
                if (globalCompany.roles.length > 0) {
                    const ids = checkIdsExist(globalCompany.roles, [1, 4]);
                    
                    if (ids) {
                        setSuperAdminId(ids); // Set the array of matched IDs
                    }
                    if (!ids || ids.length === 0) {

                        setSuperAdminId(null)
                    }

                }
            }
        }
    }, [globalCompany]);

    console.log(selectedFile)

    const handleClick = () => {
        document.getElementById('fileInput').click();
    };



    const dialogView = () => setVisible(false)
    const cancelView = () => {
        setVisible(false)
        setFirstName("")
        setLastName("")
        setEmail("")
        setIngredient("")
        setPhone("")
        setPassword("")
        setConfirmPassword("")
        setFirstNameErr("")
        setLastNameErr("")
        setPasswordErr("")
        setConfirmPasswordErr("")
        setPhoneErr("")
        setcityErr("")
        setEmailErr("")
        setSelectedFile(null)
    }




    let newObject = window.localStorage.getItem("user");
    let updatedUser = Object.assign({}, JSON.parse(newObject));


    useEffect(() => {
        setSelectedCountry({ name: 'United States', code: 'US', dialCode: '+1' })

        if (updatedUser) {
            if (updatedUser.companyRoles !== undefined) {
                if (updatedUser.companyRoles.length > 0) {
                    if (updatedUser.companyRoles[0].roles.length > 0) {
                        setRoleId(updatedUser.companyRoles[0].roles[0].id)
                        if (updatedUser.companyRoles[0].companyDetails) {
                            setCompanyId(updatedUser.companyRoles[0]?.companyDetails?.id)
                        }
                    }
                }
            }

        }

    }, [])

    const onFileUpload = async (e) => {
        e.preventDefault();
    
        // Ensure a file is selected
        if (!selectedFile) {
            showToast("error", "Please select a file to upload.");
            return;
        }
    
    
        try {
            setIsLoading(true);
            // Get the CSV content as a string
        let formData = new FormData();

          
            // console.log(selectedFile,"selectedFile")
            
            formData.append("file", selectedFile);
            for (let [key, value] of formData.entries()) {
                console.log(key, value);
              }
              
        
    
              const response = await  axios.post(`/decrypt/multipart?endpoint=/contact/api/companyDetails/upload-csv/${companyId}/`, formData, {
                headers: {
                  ...initAuth(),
                  "Content-Type": "multipart/form-data",
                }
              });

            // const response = await companyMasterService.saveCompanyBulkUpload(formData, companyId);
            console.log("Response received:", response);
    
            if (response.status === 201 || response.status === 200) {
                setIsLoading(false);
                console.log("File uploaded successfully");
                setSelectedFile(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
                showToast("success", "File uploaded successfully");
                return;
            } else if (response.status === 701) {
                showToast("error", "Company already exist");
                return;
            } else if (response.status === 400) {
                showToast("error", "Invalid data");
                return;
            }
    
            throw new Error(`Unexpected response status: ${response.status}`);
        } catch (error) {
            setIsLoading(false);
            if (error.response) {
                console.error("API Error:", error.response);
                const status = error.response.status;
                const errorData = error.response.data;
    
                if (errorData && Array.isArray(errorData.errors) && errorData.errors.length > 0) {
                    errorData.errors.forEach((err, index) => {
                        let message = "";
                        for (const key in err) {
                            if (Object.hasOwnProperty.call(err, key) && key !== "rowData") {
                                message += `${key}: ${err[key]} `;
                            }
                        }
                        if (message.trim()) {
                            showToast("error", `Error ${index + 1}: ${message.trim()}`);
                        }
                    });
                } else {
                    if (status === 701) {
                        showToast("error", "Username is already taken");
                    } else if (status === 400) {
                        showToast("error", "Invalid data");
                    } else {
                        showToast("error", `Upload failed: ${status}`);
                    }
                }
            } else {
                console.error("Unexpected Error:", error);
                showToast("error", "Upload failed. Try again!");
            }
        }
    };
    
    
 


    function bytesToKilobytes(bytes) {
        if (typeof bytes !== 'number' || isNaN(bytes) || bytes < 0) {
            console.log("Its not number")
        }
        else {
            return bytes / 1024;
        }
    }




    const customHeader = (
        <div className="flex align-items-center gap-2">
            <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" />
            <span className="font-bold">Amy Elsner</span>
        </div>
    );



    return (
        <div>
            <Toast ref={toast} />
            <Toast ref={toastCenter} position='center' />
            <Sidebar position='right' header={customHeader} visible={visible} className='dialog-box' onHide={() => setVisible(false)} >
                <div className='grid custom-sidebar-header'>
                    <div className='grid col-12 col-sm-12 col-md-12 col-lg-11 px-4 pt-4 pb-0'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 sidebar-header-text1 pb-0'>
                            Bulk Upload Companies
                        </div>

                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-1 flex justify-content-end pt-4 pb-0'>
                        <button onClick={dialogView}><i className='pi pi-times'></i></button>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 sidebar-header-text2 px-4'>
                        Upload bulk companies  by listing them in a CSV file. Make sure it’s as per the template provided here.</div>
                </div>



                <form id='myform' className='form-spacing mt-4' onSubmit={onFileUpload}>
                    <div className='input-sidebar-card mt-4'>
                        <div className='px-6 py-4'>
                            <div className='flex' style={{ justifyContent: "space-between" }}>
                                <div className='font-fam-for-all'>
                                    <span><i className='pi pi-address-book'></i></span>
                                    Bulk upload 
                                </div>
                            </div>
                            <div>
                                {/* Manage your company’s {superAdminId ? "clients" : "users"} and their access with simple file upload.
                                 */}
                               Create companies with simple file upload.

                            </div>
                            <div className='card pt-4 px-2 mt-4'>
                              
                                <div className="flex justify-content-center align-items-center pb-2">
                                    <div className="text-center">
       
                                        <input
                                            type="file"
                                            id="fileInput"
                                            // style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                            accept='.csv'
                                             
                                        />

                                        <div className="upload-rules font-fam-for-all">
                                            {/* <p className={`${fileErr ? "error-msg" :"" } webapp-global-sm-size`} >CSV (max. 250kb)</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='card-devider'></div>
                                <div className='flex align-items-center justify-content-center py-2'>
                                  
                                    <a href={templateCsv} download="template.csv" className='flex gap-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5M5.83333 8.33333L10 12.5M10 12.5L14.1667 8.33333M10 12.5V2.5" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <div className='bulk-upload-card-header-text'>
                                            Download Template
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        selectedFile ? <>
                            <div className='bulk-upload-status-container mt-4'>
                                <div className='bulk-upload-status-card1 input-sidebar-card'>
                                    <div className='bulk-upload-status-card1-content1'>
                                        <div className='bulk-upload-status-card1-content1-button-main1'>
                                            <div className='bulk-upload-status-card1-content1-button-main1-base'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M8.6665 1.3335H3.99984C3.64622 1.3335 3.30708 1.47397 3.05703 1.72402C2.80698 1.97407 2.6665 2.31321 2.6665 2.66683V13.3335C2.6665 13.6871 2.80698 14.0263 3.05703 14.2763C3.30708 14.5264 3.64622 14.6668 3.99984 14.6668H11.9998C12.3535 14.6668 12.6926 14.5264 12.9426 14.2763C13.1927 14.0263 13.3332 13.6871 13.3332 13.3335V6.00016M8.6665 1.3335L13.3332 6.00016M8.6665 1.3335V6.00016H13.3332" stroke="#145EF0" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className='bulk-upload-status-card1-content1-button-main2'>
                                            <div className='bulk-upload-status-card1-content1-button-main2-base'>
                                                <div className='bulk-upload-status-text1'>
                                                    {selectedFile.name}
                                                </div>
                                                <div className='bulk-upload-status-text2'>
                                                    {bytesToKilobytes(selectedFile.size)}Kb
                                                </div>
                                            </div>
                                            <div className='bulk-upload-status-card1-content1-button-main2-base1'>
                                                <div className='progress-bar-container'>
                                                    {/* <div className='progress-bar-empty'>

                                                    </div> */}
                                                    <div className='progress-bar-fill'>

                                                    </div>
                                                </div>
                                                <div className='progress-bar-label'>
                                                    100%
                                                </div>
                                            </div>
                                        </div>

                                        <div className=''>
                                            <button onClick={() => {
                                                setSelectedFile(null)
                                                if (fileInputRef.current) {
                                                    fileInputRef.current.value = null;
                                                }
                                            }}><i className='pi pi-times'></i></button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </> : <></>
                    }

                    <div className='flex justify-content-end dialog-form-md-group-Btn my-5'>
                        <Button type="button" className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3" label='Cancel' style={{ height: "40px" }} onClick={cancelView} ></Button>
                        <Button type='submit' className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Submit' style={{ height: "40px" }}  ></Button>
                    </div>
                </form>

         




            </Sidebar>
        </div>
    )
}

export default BulkCompanyUpload