import axios from 'axios';
import encryption, { encryptData } from './CryptoClass';
import propertiesApp from '../utils/propertiesApp';

const signIn = async (data) => {
  try {
    // Example of data to be encrypted, including the endpoint
    const dataToEncrypt = {
      endpoint: '/authentication/api/user/signin',
      payload: data,
      type:'post',
      auth:null
    };
    console.log(dataToEncrypt.payload)  
    const encryptedData = encryption.encryptData(dataToEncrypt);


    // const url = `/decrypt`;
    const url = `/decrypt`;



    // Making the API call
    return await axios.post(url,dataToEncrypt)
 
  } catch (error) {
    if (error.response?.status === 401) {
      return error.response.status;
    }
    throw error;
  }
};



const SignIn = {
  signIn
};

export default SignIn;