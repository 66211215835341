import React from 'react';

function ComplianceItem({ title, description, isLast }) {
  return (
    <>
      <div className="flex flex-col justify-center w-full max-md:max-w-full">
        <h3 className="gap-4 self-stretch w-full text-2xl font-semibold leading-tight max-md:max-w-full">
          {title}
        </h3>
        <p className="mt-4 text-base font-light leading-7 max-md:max-w-full">
          {description}
        </p>
      </div>
      {!isLast && (
        <div className="flex self-center mt-4 max-w-full border-t border-zinc-300 min-h-[1px] w-[471px]" />
      )}
    </>
  );
}

export default ComplianceItem;