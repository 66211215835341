// import { Column } from "primereact/column";
// import { DataTable } from "primereact/datatable";
// import React, { useContext, useEffect, useState } from "react";
// import OrderServices from "../../services/orderServices";
// import MasterServices from "../../services/coreServices";
// import authContext from "../../common/authContext";
// import { useNavigate } from "react-router-dom";
// import { formatISODate } from "../../utils/dateUtils";
// import { TabPanel, TabView } from "primereact/tabview";
// import { Col, Row } from "react-bootstrap";

// const CompanyServices = ({ data }) => {
//   const {
//     setFeedbackData,
//     permissions,
//     globalCompany,
//     setGlobalCompany,
//     isLoading,
//     setIsLoading,
//     isTabRefresh,
//     setIsTabRefresh,
//     redirectedData,
//     setRedirectedData,
//   } = useContext(authContext);

//   const [products, setProducts] = useState([]);

//   const [selectedProduct, setSelectedProduct] = useState(null);

//   const navigate = useNavigate();

//   useEffect(() => {
//     if (data) {
//       OrderServices.getClientSubscriptionByCompanyId(data.id)
//         .then((res) => {
//           
//           if (Array.isArray(res.data)) {
//             
//             const serviceData = res.data.filter(
//               (val) => val.isSubscription === false
//             );
//             setProducts(serviceData);
//           }
//         })
//         .catch((err) => {
//           
//         });
//     }
//   }, [data]);

//   return (
//     <div>
//       <div className="grid p-4">
//         <div className="col-12 col-sm-12 col-md-12 col-lg-12">
//           <TabView>
//             <TabPanel header="Your current services">
//               <div className="font-fam-for-all">
//                 <div className="card my-3 hover-card slide-in1">
//                   <div className="">
//                     <div className="wizard-card-radio-container gap-0 font-fam-for-all">
//                       <div className="wizard-card-radio-main">
//                         {products.length > 0 ? (
//                           products.map((val) => (
//                             <div className="col-12 col-md-6 col-lg-4">
//                               <button className="wizard-card-checkbox-container hover:shadow-lg transform hover:scale-105 transition-transform duration-300">
//                                 <div className="wizard-card-checkbox-main">
//                                   <div className="wizard-card-checkbox-text-main">
//                                     <div className="wizard-card-checkbox-text-sub-main">
//                                       <div className="grid">
//                                         <div className="col-12 col-sm-12 col-md-12 col-lg-12">
//                                           <div
//                                             className="wizard-card-checkbox-text1 font-fam-for-all text-start"
//                                             style={{
//                                               display: "inline-block",
//                                               background: "#E3F2FD",
//                                               padding: "8px",
//                                             }}
//                                           >
//                                             <span className="font-semibold"></span>
//                                             {val.productName}
//                                           </div>
//                                           <div className="srch-cand-text pt-2 text-start">
//                                             <span className="font-semibold">
//                                               State :
//                                             </span>
//                                             {val.stateName}
//                                           </div>
//                                           <div className="srch-cand-text pt-2 text-start">
//                                             <span className="font-semibold">
//                                               Order Date :
//                                             </span>
//                                             {formatISODate(val.orderDate)}
//                                           </div>
//                                           <div className="srch-cand-text pt-2 text-start">
//                                             <span className="font-semibold">
//                                               Expiry Date :
//                                             </span>
//                                             {formatISODate(val.expiryDate)}
//                                           </div>
//                                         </div>
//                                         {/* <div className='wizard-card-checkbox-text2 col-12 col-sm-12 col-md-12 col-lg-1 flex align-items-center'>
//                                                                                 ${val.productListing.unitPrice}
//                                                                             </div> */}
//                                       </div>
//                                     </div>
//                                     {/* <div className='wizard-card-checkbox-action-container flex align-items-center'>
//                                                                         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
//                                                                             <path d="M7.5 15L12.5 10L7.5 5" stroke="#004EEC" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
//                                                                         </svg>
//                                                                     </div> */}
//                                   </div>
//                                 </div>
//                               </button>
//                             </div>
//                           ))
//                         ) : (
//                           <p className="font-fam-for-all">No service found</p>
//                         )}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </TabPanel>
//           </TabView>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompanyServices; 



import { TabPanel, TabView } from "primereact/tabview";
import React, { useContext, useEffect, useState } from "react";
import OrderServices from "../../services/orderServices";
import authContext from "../../common/authContext";
import { formatISODate } from "../../utils/dateUtils";
import { Row, Col } from "react-bootstrap";

const CompanyServices = ({ data }) => {
  const { isLoading, setIsLoading } = useContext(authContext);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (data) {
      OrderServices.getClientSubscriptionByCompanyId(data.id)
        .then((res) => {
          if (Array.isArray(res.data)) {
            const serviceData = res.data.filter((val) => !val.isSubscription);
            setProducts(serviceData);
            console.log(serviceData)
          }
        })
        .catch((err) =>  console.log(err))
    }
  }, [data]);

  return (
    <div className="p-4">
      <TabView>
        <TabPanel header="Your current services">
          <Row>
            {products.length > 0 ? (
              products.map((val, index) => (
                <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-3">
                  <div className="card p-3 shadow-sm border rounded">
                    {console.log(products,"products")}
                    <div
                      className="font-weight-bold"
                      style={{
                        display: "inline-block",
                        background: "#E3F2FD",
                        padding: "8px",
                        color: "#667085",
                        fontSize: "13px"
                      }}
                    >
                      {val.productName}
                    </div>

                    <p className="mt-2 mb-1">
                      <span className="font-semibold">Entity Name:</span> {val.entityName}
                    </p>
                    <p className="mb-1">
                      <span className="font-semibold">State:</span> {val.stateName}
                    </p>
                    <p className="mb-1">
                      <span className="font-semibold">Order Date:</span>{" "}
                      {formatISODate(val.orderDate)}
                    </p>
                    <p className="mb-1">
                      <span className="font-semibold">Expiry Date:</span>{" "}
                      {formatISODate(val.expiryDate)}
                    </p>
                  </div>
                </Col>
              ))
            ) : (
              <Col xs={12} className="text-center mt-3">
                <p>No services found</p>
              </Col>
            )}
          </Row>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default CompanyServices;
