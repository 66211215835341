import React from "react";
import { Container } from "react-bootstrap";
import './PrivacyPolicy.css'

const PrivacyPolicy = () => {
  return (
    <section className="main-privacy-policy">
      <Container>
        <div>
          <h3 className="privacy-title">Privacy Policy</h3>
          <p className="normal-textt">At vState Filings, we are committed to protecting your privacy. This policy outlines how we collect, use, and safeguard your information.</p>
        </div>
        <div>
            <h5 className="blue-heading-textt">1. Information We Collect</h5>
            <p className="normal-textt">We may collect the following types of information:</p>
            <ul>
                <li>Personal Information: Name, email address, phone number, and payment information when you create an account or place an order.</li>
                <li>Usage Data: Information about how you use our website, including your IP address, browser type, and pages visited.</li>
            </ul>
        </div>
        <div>
            <h5 className="blue-heading-textt">2. How We Use Your Information</h5>
            <p className="normal-textt">We use your information for:</p>
            <ul>
                <li>Providing and improving our services.</li>
                <li>Processing transactions and managing your account.</li>
                <li>Communicating with you, including sending updates and promotional materials.</li>
                <li>Analyzing usage to enhance user experience.</li>
            </ul>
        </div>
        <div>
            <h5 className="blue-heading-textt">2. How We Use Your Information</h5>
            <p className="normal-textt">We use your information for:</p>
            <ul>
                <li>Providing and improving our services.</li>
                <li>Processing transactions and managing your account.</li>
                <li>Communicating with you, including sending updates and promotional materials.</li>
                <li>Analyzing usage to enhance user experience.</li>
            </ul>
        </div>
        <div>
            <h5 className="blue-heading-textt">3. Information Sharing</h5>
            <p className="normal-textt">We do not sell or rent your personal information to third parties. We may share your information with:</p>
            <ul>
                <li>Service providers who assist us in operating our website and conducting our business.</li>
                <li>Legal authorities when required by law or to protect our rights.</li>
            </ul>
        </div>
        <div>
            <h5 className="blue-heading-textt">4. Data Security</h5>
            <p className="normal-textt">We implement reasonable security measures to protect your personal information. However, no method of transmission over the internet or electronic storage is 100% secure.</p>
        </div>
        <div>
            <h5 className="blue-heading-textt">5. Your Rights</h5>
            <p>You have the right to:</p>
            <ul>
                <li>Access, correct, or delete your personal information.</li>
                <li>Opt out of marketing communications at any time.</li>
                <li>Withdraw consent for data processing where applicable.</li>
            </ul>
        </div>
        <div>
            <h5 className="blue-heading-textt">6. Cookies</h5>
            <p className="normal-textt">Our website uses cookies to enhance your experience. You can manage cookie preferences through your browser settings.</p>
            <ul>
                <li>Access, correct, or delete your personal information.</li>
                <li>Opt out of marketing communications at any time.</li>
                <li>Withdraw consent for data processing where applicable.</li>
            </ul>
        </div>
        <div>
            <h5 className="blue-heading-textt">7. Changes to This Policy</h5>
            <p className="normal-textt">We may update this policy periodically. Changes will be posted on this page with an updated effective date.</p>
        </div>
        <div>
            <h5 className="blue-heading-textt">8. Contact Us</h5>
            <p className="normal-textt">For questions or concerns regarding this policy, please contact us at:</p>
            <p className="normal-textt">Email: orders@vstatefilings.com</p>
            <p className="normal-textt">Phone: (866) 638-3309 | (718) 569-2703</p>
            <p className="normal-textt">By using our website, you consent to this privacy policy.</p>
        </div>
      </Container>
    </section>
  );
};

export default PrivacyPolicy;
