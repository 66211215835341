import React, { useEffect } from "react";
import ContactInfo from "./ContactInfo";
import ContactForm from "./ContactForm";
import { Col, Container, Row } from "react-bootstrap";
import ContactLocation from "./ContactLocation";
import AOS from "aos";
import "aos/dist/aos.css";

const ContactSection = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <Container>
        <Row>
          <Col lg={6} md={5} sm={12} xs={12}>
            <div data-aos="fade-left" data-aos-duration="3000">
              <ContactInfo />
            </div>
          </Col>
          <Col lg={6} md={7} sm={12} xs={12}>
            <div data-aos="fade-right" data-aos-duration="3000">
              <ContactForm />
            </div>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className="mt-4" data-aos="fade-up" data-aos-duration="3000">
              <ContactLocation />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactSection;
