import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { DataScroller } from "primereact/datascroller";
import { Rating } from "primereact/rating";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { Chips } from "primereact/chips";

import { Checkbox } from "primereact/checkbox";
import { Avatar } from "primereact/avatar";

import { MultiSelect } from "primereact/multiselect";
import { Accordion, AccordionTab } from "primereact/accordion";

import { SplitButton } from "primereact/splitbutton";

import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";

import { Dropdown } from "primereact/dropdown";

import { DataView } from "primereact/dataview";

import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView, TabPanel } from "primereact/tabview";
import { Sidebar } from "primereact/sidebar";
import { OverlayPanel } from "primereact/overlaypanel";
import authContext from "../common/authContext";
import { useNavigate } from "react-router-dom";
import UserServices from "../services/userService";
import { RadioButton } from "primereact/radiobutton";
import Sidesbar from "../common/Sidesbar";
import Headers from "../common/Headers";
import ContactService from "../services/ContactService";
import AddCustomUser from "./AddComponents/AddCutsomUser";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import AddEmployee from "./AddComponents/AddEmployee";
import GroupDataTable from "./GroupDataTable";
import { Switch } from "@mui/material";
import errorMessages, { globalMessages } from "../utils/errorMessages";
import UserUpload from "./AddComponents/UserUpload";
import { Toast } from "primereact/toast";
import companyMasterService from "../services/companyMasterService";
import rolesMasterService from "../services/rolesMaster";
import { InputMask } from "primereact/inputmask";
import ForgotPasswordService from "../services/forgotPasswordService";
import AddressService from "../services/addressService";
import AddVstateEmployee from "./AddComponents/AddVstateEmployee";
import EmployeeUpload from "./AddComponents/EmployeeUpload";
import EmployeeCompany from "./EmployeeCompany";
import AssignCompanyAccountManager from "./AddComponents/AssignCompanyWithManager";
import AddPermission from "./AddComponents/AddPermission";
import EmployeePermission from "./EmployeePermission";
import { showToast } from "../utils/toastUtils";
import useTableData from "../redux/Reducers/useTableData";
import { commonGetInitials } from "../utils/getInitials";

export default function EmployeeDashboard() {
  const {
    setFeedbackData,
    permissions,
    redirectedData,
    clearData,
    isTabRefresh,
    setFilterStatusId,
    setRedirectedData,
  } = useContext(authContext);


  const { data: fetchedStateData, isLoading: stateLoading, isError: isStateError, error: stateError, refetch: reftechStateData } = useTableData("state");

  const { data: fetchedCountryData, isLoading: countryLoading, isError: isCountryError, error: countryError, refetch: reftechCountryData } = useTableData("countryMaster");

  const { data: fetchedRolesData, isLoading: rolesLoading, isError: isRolesError, error: rolesError, refetch: reftechRolesData } = useTableData("roleMaster");

  const { data: fetchedAddressTypeData, isLoading: addressTypeLoading, isError: isAddressTypeError, error: addressTypeError, refetch: reftechAddressTypeData } = useTableData("addressType");

  

  const hasPermission = (permission) => permissions.includes(permission);

  

  let newObject = window.localStorage.getItem("user");

  let updatedUser = Object.assign({}, JSON.parse(newObject));

  const [filteredData, setFilteredData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const [sortName, setSortName] = useState(null);

  const [chipsData, setChipsData] = useState([]);

  const [chipsData1, setChipsData1] = useState([]);

  const [filterChipData, setFilterChipData] = useState([]);

  const [selectedData, setSelectedData] = useState([]);

  const [allCities, setAllCities] = useState([]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

    id: { value: null, matchMode: FilterMatchMode.IN },
    firstName: { value: null, matchMode: FilterMatchMode.EQUALS },
    lastName: { value: null, matchMode: FilterMatchMode.EQUALS },
    phone: { value: null, matchMode: FilterMatchMode.EQUALS },
    email: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  // const [city, setCity] = useState([])

  const [exp1, setExp1] = useState(null);

  const [exp2, setExp2] = useState(null);

  const [skills, setSkills] = useState([]);
  const [skillError, setSkillError] = useState("");
  const [skillARR, setSkillARR] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const [qualification, setQualification] = useState([]);
  const [qualificationArr, setQualificationArr] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const [visible, setVisible] = useState(false);

  const [visible1, setVisible1] = useState(false);

  const [visible2, setVisible2] = useState(false);

  const [visible4, setVisible4] = useState(false);

  const [visible5, setVisible5] = useState(false);

  const [visible6, setVisible6] = useState(false);

  const [visibleContent, setVisibleContent] = useState(false);

  const [allPosition, setAllPosition] = useState([]);

  const [position, setPosition] = useState(null);

  const [positionData, setPositionData] = useState(null);

  const [candidate, setCandidate] = useState(null);

  const [closeProgresbar, setCloseProgresbar] = useState(false);

  const [closeProgresbar1, setCloseProgresbar1] = useState(false);

  const [globalKeyValue, setGlobalKeyValue] = useState("");

  const [AllCandidate, setAllCandidate] = useState([]);

  const [showSkeleton, setShowSkeleton] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [data, setData] = useState(null);

  const op = useRef(null);

  const downloadop = useRef(null);

  const toast = useRef(null);

  const [isRemoveChip, setIsRemoveChip] = useState(false);

  const [fback, setFback] = useState([]);

  const [candidateFback, setCandidateFback] = useState(null);

  const [interviewLevel1, setInterviewLevel1] = useState([]);

  const [hrPanelList, setHrPanelList] = useState([]);

  const [hrpanel, setHrPanel] = useState(null);

  const [selectedPanelData, setSelectedPanelData] = useState([]);

  const [selectedPanelList, setSelectedPanelList] = useState([]);

  const [showPanel, setShowPanel] = useState(false);

  const [globalSearchValue, setGlobalSearchValue] = useState("");

  const history = useNavigate();

  const [isShowContent, setIsShowContent] = useState(false);

  const [isShowContent1, setIsShowContent1] = useState(false);

  const [isShowContent2, setIsShowContent2] = useState(false);

  const [isShowContent3, setIsShowContent3] = useState(false);

  const [salutation, setSalutation] = useState("");

  const [firstName, setFirstName] = useState(null);

  const [firstNameErr, setFirstNameErr] = useState("");

  const [middleName, setMiddleName] = useState(null);

  const [middleNameErr, setMiddleNameErr] = useState("");

  const [lastName, setLastName] = useState(null);

  const [lastNameErr, setLastNameErr] = useState("");

  const [phoneNumber, setPhoneNumber] = useState(null);

  const [phoneErr, setPhoneErr] = useState("");

  const [phone, setPhone] = useState("");

  const [altPhoneNumber, setAltPhoneNumber] = useState(null);

  const [altPhoneErr, setAltPhoneErr] = useState("");

  const [email, setEmail] = useState(null);

  const [emailErr, setEmailErr] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const [altEmail, setAltEmail] = useState("");

  const [altEmailErr, setAltEmailErr] = useState("");

  const [fax, setFax] = useState(null);

  const [faxErr, setFaxErr] = useState("");

  const [altFax, setAltFax] = useState("");

  const [altFaxErr, setAltFaxErr] = useState("");

  const [addr1, setAddr1] = useState("");

  const [addr1Err, setAddr1Err] = useState("");

  const [addr2, setAddr2] = useState("");

  const [addr2Err, setAddr2Err] = useState("");

  const [addr3, setAddr3] = useState("");

  const [region, setRegion] = useState("");

  const [regionErr, setRegionErr] = useState("");

  const [city, setCity] = useState("");

  const [cityErr, setcityErr] = useState("");

  const [states, setStates] = useState(null);

  const [statesErr, setStatesErr] = useState("");

  const [zip, setZip] = useState("");

  const [zipErr, setZipErr] = useState("");

  const [pageRefresh, setPageRefresh] = useState(false);

  const [dataId, setDataId] = useState(null);

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [rolesName, setRolesName] = useState("");

  const [userId, setUserId] = useState(null);

  const [passwordErr, setPasswordErr] = useState("");

  const [oldpasswordErr, setOldPasswordErr] = useState("");

  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");


  const [company, setCompany] = useState(null);

  const [allRoles, setAllRoles] = useState([]);

  const [role, setRole] = useState(null);

  const [tempProduct, setTempProduct] = useState([]);

  const [isSort, setIsSort] = useState(false);

  const [addressData, setAddressData] = useState(null);

  const [contactDetails, setContactDeatils] = useState(null);

  const [companyDetails, setCompanyDetails] = useState(null);

  const [allAddressType, setAllAddressType] = useState([]);

  const [addressType, setAddressType] = useState(null);

  const [allStates, setAllStates] = useState([]);

  const [allCountries, setAllCountries] = useState([]);

  const [countryData, setCountryData] = useState(null);

  const [stateValue, setStateValue] = useState(null);

  const [countryValue, setCountryValue] = useState(null);

  const [visible9, setVisible9] = useState(false);

  const [contactId, setContactId] = useState(null);

  useEffect(() => {
    setActiveIndex(0);
  }, [selectedProduct]);

  const [checked, setChecked] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  // Function to extract dial code from phone number
  const extractDialCode = (phoneNumber) => {
    const dialCode = phoneNumber.match(/^\+\d+/);
    return dialCode ? dialCode[0] : null;
  };

  // Function to find country by dial code
  const findCountryByDialCode = (dialCode) => {
    return countries.find((country) => country.dialCode === dialCode);
  };

  const handleCheck = (event) => {
    setChecked(event.target.checked);
    if (data && dataId) {
      const actualdata = data;
      actualdata["statusId"] = event.target.checked === true ? 1 : 2;

      

      UserServices.updateEmployee(actualdata, dataId)
        .then((res) => {
          
          setIsEditing1(false);
          setPageRefresh(!pageRefresh);
        })
        .catch((err) => {
          
        });

      const statusId = event.target.checked === true ? 1 : 2;
      UserServices.updateUserStatus(userId, statusId)
        .then((res) => {
          
        })
        .catch((err) => {
          
        });
    }
  };

  const [products, setProducts] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];


  useEffect(() => {
    // setCountryData(3);
    if (fetchedStateData) {
      setAllStates(fetchedStateData);
    }
  }, [fetchedStateData])


  useEffect(() => {
    if (fetchedCountryData) {
      setAllCountries(fetchedCountryData);
    }
  }, [fetchedCountryData])

  useEffect(() => {
    if (fetchedAddressTypeData) {

      setAllAddressType(fetchedAddressTypeData);

    }
  }, [fetchedAddressTypeData])

  useEffect(() => {
    if (fetchedRolesData) {
      setAllRoles(fetchedRolesData);
    }
  }, [fetchedRolesData])

  useEffect(() => {
    clearData();
    setFilterStatusId(null);
    setRedirectedData(null);
  }, []);

  useEffect(() => {
    if (dataId) {
      AddressService.getAllAddressByContactId(dataId)
        .then((res) => {
          
          if (res.data !== "") {
            const tempArr = res.data;
            const temp = tempArr.filter(
              (val) => val.addressType.type === "Contact"
            );
            
            if (temp.length > 0) {
              setAddressData(temp[0]);
              setAddr1(temp[0].addressLine1);
              setAddr2(temp[0].addressLine2);
              setCity(temp[0].city);
              setZip(temp[0].postalCode);
              setStates(temp[0].stateId);
              setCountryData(temp[0].countryId);
              setAddressType(temp[0].addressType);
              setContactDeatils(temp[0].contactDetails);
              const temps = allStates.filter((val) => val.id === temp[0].stateId);
              const temp1 = allCountries.filter(
                (val) => val.id === temp[0].countryId
              );
              
              if (temps.length > 0 && temp1.length > 0) {
                setStateValue(temps[0].stateFullDesc);
                setCountryValue(temp1[0].countryFullDesc);
              }
            }
          } else {
            setAddressData(null);
            setAddr1("");
            setAddr2("");
            setCity("");
            setZip("");
            setStates(null);
            setCountryData(null);
          }
        })
        .catch((err) => {
          
        });
    }
  }, [dataId]);

  const user = JSON.parse(localStorage.getItem("user"));

  const [loginDetails, setLoginDetails] = useState([
    { key: "Password", value: "dhdhdhdhhdhd" },
  ]);
  
  useEffect(() => {
    console.log("Updated loginDetails:", loginDetails);
  }, [loginDetails]);
  

  const [editLoginValues, setEditLoginValues] = useState(
    loginDetails.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {})
  );

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);

    setFilteredData(value);
    setGlobalFilterValue(value);
  };

  const handleLoginChange = (key, value) => {
    setEditLoginValues({ ...editLoginValues, [key]: value });
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        {selectedCountry ? (
          <span>
            <b>{selectedCountry.name}</b> selected.
          </span>
        ) : (
          "No country selected."
        )}
      </div>
    );
  };

  const [isEditing, setIsEditing] = useState(false);
  const [isEditing1, setIsEditing1] = useState(false);
  const [isEditing2, setIsEditing2] = useState(false);
  const [editValues, setEditValues] = useState();

  useEffect(() => {
    UserServices.getEmployeeDetails()
      .then((res) => {
        
        let data = res.data;
        setProducts(res.data);
        setTempProduct(res.data);
        setShowSkeleton(true);
        
        if (data.length > 0) {
          if (redirectedData !== null) {
            setSelectedProduct(redirectedData);
          } else {
            setSelectedProduct(data[0]);
          }

          if (data[0] !== undefined) {
            setDataId(data[0].id);
            setUserId(data[0].userId);
            setData(data[0]);
            // if (data[0].contactDetails.mobileNo !== undefined) {
            //     const dialCode = extractDialCode(data[0].contactDetails.mobileNo);
            //     console.log(dialCode)
            //     if (dialCode) {
            //         const country = findCountryByDialCode(dialCode);
            //         if (country) {
            //             setSelectedCountry(country);
            //         }
            //     }
            // }
          }

          if (data[0].companyDetails !== undefined) {
            setCompanyDetails(data[0].companyDetails);
          }
        }
      })
      .catch((err) => {
        
      });
  }, [pageRefresh, isTabRefresh]);

  useEffect(() => {
    if (data) {
      if (data !== undefined) {
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setPhoneNumber(data.phone);
        setEmail(data.email);
        setFax(data.fax);
        // setSalutation(data.contactDetails.salutation)
        // setAltPhoneNumber(data.contactDetails.phone1)
        if (data.statusId === 1) {
          setChecked(true);
        } else {
          setChecked(false);
        }
      }
    }
  }, [data]);

  const handleFilter = () => {
    if (products.length > 0 && company && !role) {
      const filteredContacts = products.filter((contact) =>
        contact.companyRoles.some((role) => role.companyId === company.id)
      );
      setProducts(filteredContacts);
    }
    if (products.length > 0 && role && !company) {
      const filteredContactsByRole = products.filter((contact) =>
        contact.companyRoles.some((companyRole) =>
          companyRole.roles.some((r) => r.id === role.id)
        )
      );
      setProducts(filteredContactsByRole);
    }
    if (products.length > 0 && company && role) {
      const filteredContactsByRole = products.filter((contact) =>
        contact.companyRoles.some(
          (companyRole) =>
            companyRole.roles.some((r) => r.id === role.id) &&
            contact.companyRoles.some((role) => role.companyId === company.id)
        )
      );
      setProducts(filteredContactsByRole);
    }
  };

  const handleClearFilter = () => {
    setProducts(tempProduct);
    setRole(null);
    setCompany(null);
  };

  const handleAddressEdit = () => {
    if (addressData) {
      const actualdata = addressData;
      actualdata["addressLine1"] = addr1;
      actualdata["addressLine2"] = addr2;
      actualdata["city"] = city;
      actualdata["stateMasterId"] = states;
      actualdata["countryMasterId"] = countryData;
      actualdata["postalCode"] = zip;
      actualdata["stateId"] = states;
      actualdata["countryId"] = countryData;

      if (
        addr1 !== "" &&
        addr2 !== "" &&
        city !== "" &&
        zip !== "" &&
        contactDetails &&
        company &&
        addressType
      ) {
        AddressService.updateAllAddress(actualdata)
          .then((res) => {
            
            setIsEditing2(false);
          })
          .catch((err) => {
            
          });
      } else {
        if (addr1 === "") {
          setAddr1Err(errorMessages.address1Required);
        }
        if (addr2 === "") {
          setAddr2Err(errorMessages.address2Required);
        }
        if (city === "") {
          setcityErr(errorMessages.cityRequired);
        }
        if (zip === "") {
          setZipErr(errorMessages.zipRequired);
        }
      }
    }
  };

  const handleAddressSave = () => {
    const actualdata = {
      addressLine1: addr1,
      addressLine2: addr2,
      city: city,
      stateMasterId: states,
      postalCode: zip,
      countryMasterId: countryData,
      contactDetails: contactDetails,
      companyDetails: company,
      addressType: addressType,
    };

    if (
      addr1 !== "" &&
      addr2 !== "" &&
      city !== "" &&
      zip !== "" &&
      contactDetails &&
      company &&
      addressType
    ) {
      AddressService.saveAllAddress(actualdata)
        .then((res) => {
          
          setIsEditing2(false);
        })
        .catch((err) => {
          
        });
    } else {
      if (addr1 === "") {
        setAddr1Err(errorMessages.address1Required);
      }
      if (addr2 === "") {
        setAddr2Err(errorMessages.address2Required);
      }
      if (city === "") {
        setcityErr(errorMessages.cityRequired);
      }
      if (zip === "") {
        setZipErr(errorMessages.zipRequired);
      }
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleEdit1 = (rowdata) => {
    setIsEditing1(true);
    if (rowdata.contactDetails !== undefined) {
      setDataId(rowdata.contactDetails.id);
    }
  };

  const handleEdit2 = () => {
    setIsEditing2(true);
  };

  const handlePassword = (e) => {
    setPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setPasswordErr(errorMessages.passwordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setPasswordErr(errorMessages.passwordValidation);
      }
    }
    setPassword(e.target.value);
  };

  const handleOldPassword = (e) => {
    setOldPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setOldPasswordErr(errorMessages.oldPasswordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setOldPasswordErr(errorMessages.oldPasswordValidation);
      }
    }
    setOldPassword(e.target.value);
  };

  const handleEmail = (e) => {
    setEmailErr("");
    let value = e.target.value;
    if (value === "") {
      setEmailErr(errorMessages.emailRequired);
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      setEmailErr(errorMessages.emailValidation);
    }
    setEmail(e.target.value);
  };

  const handleLastName = (e) => {
    setLastNameErr("");
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/^[a-zA-Z]+([a-zA-Z]+)*$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setLastName(e.target.value);
  };

  const handleFirstName = (e) => {
    setFirstNameErr("");
    let value = e.target.value;
    if (value === "") {
      setFirstNameErr(errorMessages.firstNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/^[a-zA-Z]+([a-zA-Z]+)*$/.test(value)) {
        setFirstNameErr(errorMessages.firstNameValidation);
      }
    }
    setFirstName(e.target.value);
  };

  // const handlePhone = (e) => {
  //     setPhoneErr("")
  //     let value = e.target.value
  //     if (value === '') {
  //         setPhoneErr(errorMessages.phoneRequired)

  //     }
  //     if (value && value.trim() !== '') {
  //         if (!/^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(value)) {
  //             setPhoneErr(errorMessages.phoneValidation)
  //         }

  //     }
  //     setPhoneNumber(e.target.value)
  // }

  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;

    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }
    const formattedValue = inputValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
    );
    if (inputValue.length === 10) {
      // Only show formatted value after 10 digits
      e.target.value = formattedValue;
      setPhone(formattedValue); // Update state with formatted number
    } else {
      e.target.value = inputValue;
      setPhone(inputValue);
    }

    if (value === "") {
      setPhoneErr(errorMessages.phoneRequired);
    }
    if (value && value.trim() !== "" && inputValue.length <= 9) {
      if (
        !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
          value
        )
      ) {
        setPhoneErr(errorMessages.phoneValidation);
      }
    }
    setPhone(e.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };

  const handleConfirmPassword = (e) => {
    setConfirmPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setConfirmPasswordErr(errorMessages.confirmPasswordValidation);
      }
    }
    setConfirmPassword(e.target.value);
  };

  const handleChange = (key, value) => {
    setEditValues({ ...editValues, [key]: value });
  };

  const handleSave2 = () => {
    
    if (addressData) {
      handleAddressEdit();
    } else {
      handleAddressSave();
    }
  };

  const handleSave = () => {
    

    if (data && dataId) {
      const actualdata = data;
      actualdata["firstName"] = firstName;
      actualdata["lastName"] = lastName;
      actualdata["phone"] = selectedCountry
        ? `${selectedCountry.dialCode} ${phoneNumber}`
        : phoneNumber;
      actualdata["email"] = email;
      // actualdata["fax"] = fax
      // actualdata["phone1"] = altPhoneNumber

      

      if (firstName && lastName && phoneNumber && email) {
        UserServices.updateEmployee(actualdata)
          .then((res) => {
            
            setIsEditing1(false);
            setPageRefresh(!pageRefresh);
          })
          .catch((err) => {
            
          });
      } else {
        if (!firstName) {
          setFirstNameErr(errorMessages.firstNameRequired);
        }
        if (!lastName) {
          setLastNameErr(errorMessages.lastNameRequired);
        }
        if (!phoneNumber) {
          setPhoneErr(errorMessages.phoneRequired);
        }
        if (!email) {
          setEmailErr(errorMessages.emailRequired);
        }
      }
    }
  };

  const handleToggle = () => {
    setIsShowContent(!isShowContent);
  };

  const handleToggle1 = () => {
    setIsShowContent1(!isShowContent1);
  };

  const handleToggle2 = () => {
    setIsShowContent2(!isShowContent2);
  };

  const handleToggle3 = () => {
    setIsShowContent3(!isShowContent3);
  };

  const sortData = (field) => {
    if (field === sortField) {
      // If clicking on the same field, toggle the sort order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // If clicking on a different field, set the new field and default to ascending order
      setSortField(field);
      setSortOrder("asc");
    }
    const sortedData = [...products].sort((a, b) => {
      
      if (a.contactDetails !== undefined && b.contactDetails !== undefined) {
        const valueA = a.contactDetails[field];
        const valueB = b.contactDetails[field];

        if (valueA < valueB) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortOrder === "asc" ? 1 : -1;
        }
      }

      return 0;
    });
    

    setProducts(sortedData);
  };

  const handleFbackHeader = () => {
    return (
      <>
        <div className="font-fam-for-all form-md-heading form-sm-close-Btn">
          Pre-Screening Feedback
          <button className="form-close-Btn" onClick={() => setVisible6(false)}>
            <i className="pi pi-times"></i>
          </button>
        </div>
      </>
    );
  };

  const handleSkill = (e) => {
    setSkills(e.target.value);
  };

  const handleQualification = (e) => {
    setQualification(e.target.value);
  };

  const PasswordText = (text) => {
    const maskedText = text.replace(/./g, "*");
    return maskedText;
  };

  const handleSave1 = () => {
    console.log(loginDetails,"loginDetails")
    
    // const newData = loginDetails.map((item) => ({
    //   ...item,
    //   value: editLoginValues[item.key],
    // }));
    // setLoginDetails(newData);

    const newData = loginDetails.map((item) => ({
      ...item,
      value: item.key === "Password" ? password : item.value,
    }));
    setLoginDetails(newData);
    

    if (
      password !== "" &&
      confirmedPassword !== "" &&
      password === confirmedPassword
    ) {
      ForgotPasswordService.resetPassword(confirmedPassword, email)
        .then((res) => {
          
          setIsEditing(false);
          showToast("success", "Password changed successfully!");
        })
        .catch((err) => {
          
          if (err.response !== undefined) {
            if (err.response.status !== undefined) {
              if (err.response.status === 701) {
            
                showToast("error", "Password has been used recently");
              }
            }
          }
        });
    } else {
      if (confirmedPassword === "") {
        setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
      }
      if (password === "") {
        setPasswordErr(errorMessages.passwordRequired);
      }

      if (password !== confirmedPassword) {
     
        showToast("error", `${errorMessages.passwordMatched}`);

       }
    }
  };

  const allSortName = ["name", "location", "experienc"];

  const handleSortName = (e) => {
    setSortName(e.target.value);
    
    sortData(e.target.value);
  };

  const handlePosition = (e) => {
    const temp = e.target.value;
    setPosition(e.target.value);
    if (temp.jobdescription.skill !== null) {
      setSkills(temp.jobdescription.skill);
    }
    if (temp.jobdescription.experience !== null) {
      const tempexp = temp.jobdescription.experience;
      const arrexp = tempexp.split("-");
      
      if (arrexp.length === 2) {
        setExp1(arrexp[0]);
        setExp2(arrexp[1]);
      }
    }
  };

  const handleClearPosition = () => {
    setVisible1(false);
    setVisible2(false);
    setPosition(null);
    setPositionData(null);
  };

  const handlePositionData = (e) => {
    setProducts(AllCandidate);
    const temp = e.target.value;
    setPositionData(e.target.value);
  };

  const SortingFilter = () => {
    return (
      <>
        <div>
          <Dropdown
            value={sortName}
            options={allSortName}
            onChange={handleSortName}
            placeholder="Sorting By"
            filter
            className="filter-dropdown-field"
            required
          />
        </div>
      </>
    );
  };

  const handleExp1 = (e) => {
    setProducts(AllCandidate);
    setExp1(e.target.value);
  };

  const handleExp2 = (e) => {
    setProducts(AllCandidate);
    setExp2(e.target.value);
  };

  const listExp = [];
  for (let i = 0; i < 50; i++) {
    listExp.push(`${i}`);
  }

  const filterData = (event) => {
    const filteredItems = products.filter((item) => {
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const element = item[key].toString();
          

          if (
            element.toLowerCase().includes(event.target.value.toLowerCase())
          ) {
            return item;
          }
        }
      }
    });
    setFilteredData(filteredItems);
  };

  // effect of filter all

  function checkAllExist(a, b) {
    // Convert all elements of array b to lowercase for case-insensitive comparison
    const lowercaseB = b.map((item) => item.toLowerCase());

    for (const element of a) {
      // Convert element to lowercase for case-insensitive comparison
      const lowercaseElement = element.toLowerCase();

      if (!lowercaseB.includes(lowercaseElement)) {
        return false; // If any element of a is not found in b, return false
      }
    }
    return true; // All elements of a were found in b
  }

  function convertMonthsToYears(years) {
    return years * 12;
  }

  // event on checkbox
  const onCheckboxChange = (event, data) => {
    
    
    if (event.checked) {
      setSelectedData([...selectedData, data]);
    } else {
      setSelectedData(selectedData.filter((item) => item !== data));
    }
  };

  const handleChecked = (e) => {
    const checked = e.checked;
    if (checked) {
      setSelectedData(products);
    } else {
      setSelectedData([]);
    }
  };

  

  // DataTable columns
  const cols = [
    { field: "id", header: "id" },
    { field: "fullName", header: "Name" },
    { field: "skill", header: "Skills" },
    { field: "experienceInYears", header: "Experience" },
    { field: "city", header: "Location" },
  ];

  const updateData = selectedData.map((elem) => { });

  // Export DataTable data
  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const CloseProgresbarheader1 = () => {
    setCloseProgresbar1(false);
  };

  const handleClick3 = () => {
    setVisible(false);
  };

  // Function to check if object exists in array
  function objectExists(array, obj) {
    return array.some((item) => JSON.stringify(item) === JSON.stringify(obj));
  }

  const itemTemplate = (data) => {
    
    

    if (data) {
      const fullName = `${data.firstName} ${data.lastName}`;
      return (
        <div className="srch-cand-card font-fam-for-all w-full">
          <div className="grid w-full">
            {/* <div className='col-1 flex align-items-center'>
                            <div className='srch-cand-checkbox'>

                                <Checkbox
                                    onChange={(e) => onCheckboxChange(e, data)}
                                    checked={selectedData.includes(data)}
                                ></Checkbox>
                            </div>
                        </div> */}
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 flex gap-2"
              style={{ width: "250px" }}
            >
              <div>
                <Avatar
                  label={commonGetInitials(fullName)}
                  icon="pi pi-user"
                  size="large"
                  shape="circle"
                  className="custom-avatar"
                />
              </div>
              <div className="grid">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0">
                  <p className=" srch-cand-text1">{`${data.firstName} ${data.lastName}`}</p>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-0">
                  <p className=" srch-cand-text">{data.email}</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 flex align-items-center justify-content-end">
              <>
                <div className="badge-container">
                  <div
                    className="badge-main grid"
                    style={
                      data.statusId !== 1
                        ? { background: "#FEF3F2" }
                        : { background: "#ECFDF3" }
                    }
                  >
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 flex align-items-center p-0">
                      {data.statusId !== 1 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                        >
                          <circle cx="4" cy="4" r="3" fill="#F04438" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                        >
                          <circle cx="4" cy="4" r="3" fill="#12B76A" />
                        </svg>
                      )}
                      <span
                        className="badge-label ml-1"
                        style={
                          data.statusId !== 1
                            ? { color: "#B42318" }
                            : { color: "#027A48" }
                        }
                      >
                        {data.statusId === 1 ? "Active" : "Inactive"}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const CloseProgresbarheader = () => {
    setCloseProgresbar(false);
  };

  const searchCandHeader = () => {
    return (
      <div className="font-fam-for-all flex flex-column gap-3">
        <p className="srch-cand-header table-cand">
          Employees{" "}
          <Tag
            className="badge-cand"
            value={
              filteredData.length > 0 ? filterData.length : products.length
            }
          ></Tag>
        </p>

        <div className="card-devider"></div>

        <div className="srch-cand-card font-fam-for-all ">
          {/* <Checkbox className='mb-1'
                        checked={selectedData.length === products.length}
                        onChange={handleChecked}
                    ></Checkbox> */}

          <div style={{ width: "400px", paddingLeft: "1rem" }}>
            <button
              style={{ backgroundColor: "#ffff" }}
              className="table-cand srch-cand-text"
              onClick={() => {
                sortData("firstName");
                setIsSort(!isSort);
              }}
            >
              Name
              <i
                className={
                  isSort
                    ? "pi pi-arrow-up srch-cand-text ml-1"
                    : "pi pi-arrow-down srch-cand-text ml-1"
                }
                style={{ fontSize: "14px" }}
              ></i>
            </button>

            {/* <button style={{ backgroundColor: '#ffff' }} className='table-cand' onClick={() => sortData("firstName")}> <i className='pi pi-paperclip ml-3'></i> Attach Profiles</button> */}
          </div>
        </div>
      </div>
    );
  };

  const onRowSelect = (rowData) => {
    
    setData(rowData.data);
    if (rowData.data.contactDetails !== undefined) {
      setContactDeatils(rowData.data.contactDetails);
      setDataId(rowData.data.contactDetails.id);
      if (rowData.data.contactDetails.emailId) {
        setEmail(rowData.data.contactDetails.emailId);
        if (rowData.data.contactDetails.mobileNo !== undefined) {
          const dialCode = extractDialCode(
            rowData.data.contactDetails.mobileNo
          );
          
          if (dialCode) {
            const country = findCountryByDialCode(dialCode);
            if (country) {
              setSelectedCountry(country);
            }
          }
        }
      }
    }
    if (rowData.data.companyDetails !== undefined) {
      setCompanyDetails(rowData.data.companyDetails);
    }
  };

  

  const sidebarHeader = () => (
    <>
      <div
        style={{
          backgroundColor: "#002592",
          width: "contentFit",
          height: "40px",
          position: "sticky",
        }}
        className="sidebar-head"
      >
        <Button
          className="pi pi-times sidebar-close-btn"
          onClick={() => setFilters(false)}
        ></Button>
      </div>
    </>
  );

  const items = Array.from({ length: 5 }, (v, i) => i);

  useEffect(() => {
    if (fback.length > 0) {
      const temp = fback.filter((val) => val.interviewLevelId.preference === 1);
      if (temp.length > 0) {
        setCandidateFback(temp[0]);
        setFeedbackData(temp);
      }
    }
  }, [fback]);

  

  return (
    <>
      <Toast ref={toast} />

      <div className="grid webapp-content-container">
        <div className="col-12 ">
          <div className="card search-cand-card2">
            <div className="col-12 grid font-fam-for-all py-3 px-3">
              <div
                className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7"
                style={{ paddingLeft: "20px" }}
              >
                <p className="search-cand-header mb-0">Employees</p>
                <p className="search-cand-sub-header mb-0">
                  Create a new employee, customize employee permissions and
                  add/remove employees from your account.
                </p>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                {hasPermission("AllowEmployeeWithUpdate") ? (
                  <div className="flex align-items-center justify-content-end gap-2 mt-3">
                    <Button
                      className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                      icon="pi pi-download"
                      label="Bulk Upload"
                      onClick={(e) => setVisible4(true)}
                    ></Button>
                    <Button
                      className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
                      label="Add new employee"
                      icon="pi pi-plus"
                      onClick={() => setVisible(true)}
                    ></Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/* <div className="col-12 " style={{ padding: '0px', height: '60px' }}>
                    <p className='panel-headers font-fam-for-all datatable-sm d-flex align-items-center justify-content-center'>Search Candidate</p>
                </div> */}

            <div className="card-devider"></div>
            <div
              className="col-12"
              style={{ height: "fit-content", padding: "20px 20px 0px 30px" }}
            >
              <div className="col-12 grid">
                <div className="flex align-items-center justify-content-start gap-3">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      placeholder="Employee name"
                      value={globalFilterValue}
                      onChange={onGlobalFilterChange}
                      style={{ borderRadius: "8px", width: "352px" }}
                    />
                  </span>

                </div>
              </div>
            </div>

            <div style={{ padding: "30px" }}>
              <div className="grid">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3 pt-0 ">
                  <div className="">
                    <div className="vstate-card">
                      {showSkeleton ? (
                        <DataTable
                          header={searchCandHeader}
                          value={products}
                          rows={25}
                          rowsPerPageOptions={[25, 50, 75, 100]}
                          inline
                          paginator
                          emptyMessage="No employee found"
                          filters={filters}
                          globalFilterFields={[
                            "firstName",
                            "lastName",
                            "phone",
                            "email",
                          ]}
                          selectionMode="single"
                          selection={selectedProduct}
                          onSelectionChange={(e) => setSelectedProduct(e.value)}
                          className="master-datatable"
                          onRowSelect={onRowSelect}
                          metaKeySelection={false}
                        >
                          <Column
                            body={itemTemplate}
                            className="datatable-body-icon"
                          ></Column>
                        </DataTable>
                      ) : (
                        <DataTable
                          header={searchCandHeader}
                          value={items}
                          rows={5}
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          inline
                          paginator
                          emptyMessage="No User Found"
                          selectionMode="single"
                          selection={selectedProduct}
                          onSelectionChange={(e) => setSelectedProduct(e.value)}
                          onRowSelect={onRowSelect}
                          metaKeySelection={false}
                        >
                          <Column
                            body={<Skeleton />}
                            className="datatable-body-icon"
                          ></Column>
                        </DataTable>
                      )}
                    </div>
                  </div>
                </div>
                {data !== null ? (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-9 pt-0">
                    <div className="vstate-card col-12">
                      <div className="col-12 srch-cand-card">
                        <Avatar
                          className="ml-3 custom-avatar"
                          label={commonGetInitials(
                            `${data.firstName} ${data.lastName}`
                          )}
                          size="large"
                          shape="circle"
                        />
                        <div className="col-11 grid pb-0">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                            <div className="flex">
                              <div className=" srch-cand-text2">{`${data.firstName} ${data.lastName}`}</div>
                              <div className="badge-container ml-2">
                                <div
                                  className="badge-main m-0 grid"
                                  style={
                                    data.statusId !== 1
                                      ? { background: "#FEF3F2" }
                                      : { background: "#ECFDF3" }
                                  }
                                >
                                  <div className="col-12 flex align-items-center p-0">
                                    {data.statusId !== 1 ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                      >
                                        <circle
                                          cx="4"
                                          cy="4"
                                          r="3"
                                          fill="#F04438"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                      >
                                        <circle
                                          cx="4"
                                          cy="4"
                                          r="3"
                                          fill="#12B76A"
                                        />
                                      </svg>
                                    )}
                                    <span
                                      className="badge-label ml-1"
                                      style={
                                        data.statusId !== 1
                                          ? { color: "#B42318" }
                                          : { color: "#027A48" }
                                      }
                                    >
                                      {data.statusId === 1
                                        ? "Active"
                                        : "Inactive"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className=" srch-cand-text"> {data.email}</p>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 d-flex justify-content-end align-items-center"></div>
                        </div>
                      </div>

                      <div className="card-devider"></div>
                      <TabView
                        activeIndex={activeIndex}
                        onTabChange={(e) => {
                          setActiveIndex(e.index);
                          setIsEditing(false);
                          setIsEditing1(false);
                          setIsEditing2(false);
                        }}
                      >
                        <TabPanel header="Login Details" leftIcon=" mr-2">
                          <div
                            className="col-12 "
                            style={{
                              backgroundColor: "#fbfcfa",
                              padding: "15px",
                            }}
                          >
                            {isEditing ? (
                              <>
                                <div className=" grid- font-fam-for-all slide-in1 card my-3">
                                  <div className="px-4 pt-4 pb-0">
                                    <div className="grid">
                                      <div className="col-10 mt-2">
                                        <h6
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "18px",
                                          }}
                                        >
                                          Login Details
                                        </h6>
                                        {/* <p>Change Password Here</p> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="grid">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                                      <div className="justify-content-center dialog-form-field-space grid mt-3">
                                        <div className="field col-10 p-0">
                                          <span className="relative ">
                                            <InputText
                                              id="floating_outlined9"
                                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                              placeholder=""
                                              type={passwordType}
                                              value={password}
                                              onChange={handlePassword}
                                              style={{
                                                width: "100%",
                                                height: "40px",
                                              }}
                                            />
                                            <i
                                              className="absolute right-3 top-1/2 transform -translate-y-1/2 pi"
                                              style={{
                                                fontSize: "1rem",
                                                cursor: "pointer",
                                              }}
                                              onClick={togglePassword}
                                            >
                                              <i
                                                className={
                                                  passwordType === "password"
                                                    ? "pi pi-eye"
                                                    : "pi pi-eye-slash"
                                                }
                                              ></i>
                                            </i>

                                            <label
                                              htmlFor="floating_outlined9"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              New Password
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </span>
                                          {passwordErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {passwordErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}

                                          <ul>
                                            <li
                                              className=" font-fam-for-all pt-2"
                                              style={{
                                                fontSize: "12px",
                                                color: "#667085",
                                              }}
                                            >
                                              {
                                                globalMessages.passwordValidText1
                                              }
                                            </li>
                                            <li
                                              className=" font-fam-for-all pt-2"
                                              style={{
                                                fontSize: "12px",
                                                color: "#667085",
                                              }}
                                            >
                                              {
                                                globalMessages.passwordValidText2
                                              }
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                                      <div className="justify-content-center dialog-form-field-space grid mt-3">
                                        <div className="field col-10 p-0">
                                          <span className="relative ">
                                            <InputText
                                              id="floating_outlined"
                                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                              placeholder=""
                                              type={passwordType1}
                                              value={confirmedPassword}
                                              onChange={handleConfirmPassword}
                                              style={{
                                                width: "100%",
                                                height: "40px",
                                              }}
                                            />
                                            <i
                                              className="absolute right-3 top-1/2 transform -translate-y-1/2 pi"
                                              style={{
                                                fontSize: "1rem",
                                                cursor: "pointer",
                                              }}
                                              onClick={togglePassword1}
                                            >
                                              <i
                                                className={
                                                  passwordType1 === "password"
                                                    ? "pi pi-eye"
                                                    : "pi pi-eye-slash"
                                                }
                                              ></i>
                                            </i>

                                            <label
                                              htmlFor="floating_outlined"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              Confirm Password
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </span>
                                          {confirmPasswordErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {confirmPasswordErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mb-2">
                                    <div className="flex justify-content-end dialog-form-md-group-Btn">
                                      <Button
                                        className="mt-2  font-fam-for-all text-center vstate-reverse-button mr-2"
                                        style={{ height: "40px" }}
                                        onClick={() => {
                                          setIsEditing(false);
                                          setOldPassword("");
                                          setPassword("");
                                          setConfirmPassword("");
                                          setOldPasswordErr("");
                                          setPasswordErr("");
                                          setConfirmPasswordErr("");
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        className="mt-2  font-fam-for-all text-center vstate-button mr-3"
                                        style={{ height: "40px" }}
                                        onClick={handleSave1}
                                      >
                                        Submit
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className=" font-fam-for-all slide-in hover-card card my-3">
                                  <div className="px-4 pt-4 pb-0">
                                    <div className="grid">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 mt-2">
                                        <h6
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "18px",
                                          }}
                                        >
                                          Login Details
                                        </h6>
                                        {/* <p style={{ fontSize: "14px" }}>Change Password Here</p> */}
                                      </div>

                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 flex justify-content-end">
                                        <div className="flex justify-content-end dialog-form-md-group-Btn">
                                          {hasPermission(
                                            "AllowEmployeeWithUpdate"
                                          ) && (
                                              <button
                                                className="datatable-highlight-color webapp-global-sm-size"
                                                onClick={handleEdit}
                                              >
                                                Reset password?
                                              </button>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="grid-row">
                                    <div className="grid px-2">
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 p-4"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Email
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 p-4"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {data.email}
                                      </div>
                                    </div>
                                  </div>

                                  {loginDetails.map((item, index) => (
                                    <div key={index} className="grid-row">
                                      <div className="grid px-2">
                                        <div
                                          className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 p-4"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {item.key}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 p-4">
                                          {`${item.key === "Password"
                                            ? PasswordText(item.value)
                                            : item.value
                                            }`}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  <div className="grid-row">
                                    <div className="grid px-2">
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 flex align-items-center p-4"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Status
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 p-4"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <Switch
                                          checked={checked}
                                          onChange={handleCheck}
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                        />
                                        {/* <div className='flex justify-content-center  font-fam-for-all' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                    Active
                                                                                </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </TabPanel>
                        <TabPanel header="Profile Info">
                          <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 "
                            style={{
                              backgroundColor: "#fbfcfa",
                              padding: "15px",
                            }}
                          >
                            <div className="font-fam-for-all">
                              <div>
                                {isEditing1 ? (
                                  <div className="card my-3 slide-in">
                                    <div className="p-4">
                                      <h2
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "18px",
                                        }}
                                      >
                                        Profile Information
                                      </h2>
                                      <div style={{ fontSize: "14px" }}>
                                        Update your photo and personal details
                                        here.
                                      </div>
                                    </div>

                                    <div className="grid px-4 py-2">
                                      {/* <div className='col-12 col-sm-12 col-md-6 col-lg-' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}>

                                                                                    <span className="p-float-label">
                                                                                        <Dropdown
                                                                                            value={salutation}
                                                                                            options={["Mr", "Ms", "Mrs", "Dr"]}
                                                                                            onChange={(e) => setSalutation(e.target.value)}
                                                                                            // optionLabel="code"


                                                                                            style={{ height: '40px', borderRadius: '6px', width: "100%" }}
                                                                                            className="p-dropdown font-fam-for-all"
                                                                                        />
                                                                                        <label htmlFor="input" className="form-label font-fam-for-all">
                                                                                            Salutation <span className="form-field-mandatory"></span>
                                                                                        </label>
                                                                                    </span>
                                                                                </div>
                                                                            </div> */}
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-3 "
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <span className=" mr-3">
                                          <div class="relative">
                                            <InputText
                                              style={{
                                                height: "40px",
                                                width: "100%",
                                              }}
                                              maxLength={50}
                                              value={firstName}
                                              id="floating_outlined3"
                                              placeholder=""
                                              disabled
                                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                              onChange={handleFirstName}
                                              name="cityName"
                                            />

                                            <label
                                              htmlFor="floating_outlined3"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              First Name
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          {firstNameErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {firstNameErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-3"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <span className=" mr-3">
                                          <div class="relative">
                                            <InputText
                                              style={{
                                                height: "40px",
                                                width: "100%",
                                              }}
                                              maxLength={50}
                                              value={lastName}
                                              id="floating_outlined5"
                                              placeholder=""
                                              disabled
                                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                              onChange={handleLastName}
                                              name="cityName"
                                            />
                                            <label
                                              htmlFor="floating_outlined5"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              Last Name{" "}
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          {lastNameErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {lastNameErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-3"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {/* <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}> */}

                                        <span className=" mr-3">
                                          <div class="relative">
                                            <InputText
                                              style={{
                                                height: "40px",
                                                borderRadius: "0px 6px 6px 0px",
                                              }}
                                              maxLength={50}
                                              value={email}
                                              onChange={handleEmail}
                                              name="cityName"
                                              disabled
                                              id="floating_outlined6"
                                              placeholder=""
                                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            />
                                            <label
                                              htmlFor="floating_outlined6"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              Email{" "}
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          {emailErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {emailErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                        {/* </div> */}
                                      </div>

                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-3"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        {/* <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}> */}
                                        {/* <Dropdown
                                                                                        value={selectedCountry}
                                                                                        options={countries}
                                                                                        onChange={(e) => setSelectedCountry(e.value)}
                                                                                        optionLabel="code"
                                                                                        valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}

                                                                                        style={{ width: '80px', borderRadius: '6px 0px 0px 6px' }}
                                                                                        className="p-dropdown font-fam-for-all"
                                                                                    /> */}
                                        <span className="">
                                          <div class="relative">
                                            {/* <InputText
                                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px', width: "100%" }}
                                                                                            maxLength={50}
                                                                                            value={phoneNumber}
                                                                                            id="floating_outlined10"
                                                                                            placeholder=''
                                                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                                            disabled
                                                                                            onChange={handlePhone}
                                                                                            name="cityName"
                                                                                        /> */}
                                            <input
                                              type="text"
                                              id="floating_outlined217"
                                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                                              style={{
                                                height: "40px",
                                                borderRadius: "0px 6px 6px 0px",
                                                width: "100%",
                                              }}
                                              maxLength={50}
                                              value={phone}
                                              onChange={handlePhone}
                                              autoClear={false}
                                              placeholder=""
                                              name="cityName"
                                            />
                                            <label
                                              htmlFor="floating_outlined217"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              Phone{" "}
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          {phoneErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {phoneErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                        {/* </div> */}
                                      </div>
                                    </div>

                                    <div className="grid px-4 py-2">
                                      {/* <div className='col-12 col-sm-12 col-md-6 col-lg- ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}>
                                                                                <Dropdown
                                                                                        value={selectedCountry}
                                                                                        options={countries}
                                                                                        onChange={(e) => setSelectedCountry(e.value)}
                                                                                        optionLabel="code"
                                                                                        valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}


                                                                                        style={{ width: '80px', borderRadius: '6px 0px 0px 6px' }}
                                                                                        className="p-dropdown font-fam-for-all"
                                                                                    />
                                                                                <span className="" >
                                                                                    <div class="relative">
                                                                                        <InputText
                                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px', width: "100%" }}
                                                                                            maxLength={50}
                                                                                            value={altPhoneNumber}
                                                                                            id="floating_outlined11"
                                                                                            placeholder=''
                                                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                                                            onChange={(e) => {
                                                                                                setAltPhoneNumber(e.target.value)
                                                                                            }}
                                                                                            name="cityName"
                                                                                        />
                                                                                        <label htmlFor="floating_outlined11"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Alt Phone <span className="form-field-mandatory"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                </span>
                                                                                </div>
                                                                            </div> */}

                                      {/* <div className='col-12 col-sm-12 col-md-6 col-lg-' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}>

                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <InputText
                                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                                            maxLength={16}
                                                                                            value={fax}
                                                                                            onChange={(e) => setFax(e.target.value)}
                                                                                            name="cityName"
                                                                                            id="floating_outlined8"
                                                                                            placeholder=''
                                                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                                                        />
                                                                                        <label htmlFor="floating_outlined8"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Fax <span className="form-field-mandatory"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                </span>
                                                                                </div>
                                                                            </div> */}
                                    </div>

                                    <div className="mb-3">
                                      {isEditing || isEditing1 || isEditing2 ? (
                                        <div className="flex justify-content-end pl-3 dialog-form-md-group-Btn">
                                          <Button
                                            className="mt-2  font-fam-for-all text-center vstate-reverse-button mr-2"
                                            style={{ height: "40px" }}
                                            onClick={() => {
                                              setIsEditing(false);
                                              setIsEditing1(false);
                                              setIsEditing2(false);
                                              setFirstNameErr("");
                                              setLastNameErr("");
                                              setPhoneErr("");
                                              setEmailErr("");
                                            }}
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            className="mt-2  font-fam-for-all text-center vstate-button mr-3"
                                            style={{ height: "40px" }}
                                            onClick={handleSave}
                                          >
                                            Submit
                                          </Button>
                                        </div>
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div>
                                      <div className="card my-3 hover-card slide-in">
                                        <div
                                          className=" flex px-4 pt-4 pb-0"
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <h2
                                            className="text-lg "
                                            style={{ fontWeight: "500" }}
                                          >
                                            Profile Information
                                          </h2>
                                        </div>

                                        <div className="px-4 pt-2 pb-4">
                                          <div className="grid">
                                            <div
                                              className="col-12 col-sm-12 col-md-6 col-lg- "
                                              style={{
                                                fontWeight: "500",
                                                fontSize: "14px",
                                              }}
                                            >
                                              First Name
                                            </div>
                                            <div
                                              className="col-12 col-sm-12 col-md-6 col-lg-"
                                              style={{
                                                fontWeight: "500",
                                                fontSize: "14px",
                                              }}
                                            >
                                              Last name
                                            </div>
                                            <div
                                              className="col-12 col-sm-12 col-md-6 col-lg- "
                                              style={{ fontSize: "14px" }}
                                            >
                                              {data.firstName}
                                            </div>
                                            <div
                                              className="col-12 col-sm-12 col-md-6 col-lg-"
                                              style={{ fontSize: "14px" }}
                                            >
                                              {data.lastName}
                                            </div>
                                            <div
                                              className="col-12 col-sm-12 col-md-6 col-lg-"
                                              style={{
                                                fontWeight: "500",
                                                fontSize: "14px",
                                              }}
                                            >
                                              Email Address
                                            </div>

                                            <div
                                              className="col-12 col-sm-12 col-md-6 col-lg-"
                                              style={{
                                                fontWeight: "500",
                                                fontSize: "14px",
                                              }}
                                            >
                                              Phone Number
                                            </div>

                                            <div
                                              className="col-12 col-sm-12 col-md-6 col-lg-"
                                              style={{ fontSize: "14px" }}
                                            >
                                              {data.email}
                                            </div>

                                            <div
                                              className="col-12 col-sm-12 col-md-6 col-lg-"
                                              style={{ fontSize: "14px" }}
                                            >
                                              {data.phone}
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div className="grid px-4 py-2">

                                                                                    <div className='col-12 col-sm-12 col-md-6 col-lg-' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                        Phone Number
                                                                                    </div>


                                                                                    <div className='col-12 col-sm-12 col-md-6 col-lg-' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                        Fax
                                                                                    </div>

                                                                                    <div className='col-12 col-sm-12 col-md-6 col-lg-' style={{ fontWeight: "500", fontSize: "14px" }}>

                                                                                    </div>

                                                                                    <div className='col-12 col-sm-12 col-md-6 col-lg-' style={{ fontWeight: "500", fontSize: "14px" }}>

                                                                                    </div>



                                                                                    <div className='col-12 col-sm-12 col-md-6 col-lg-' style={{ fontSize: "14px" }}>
                                                                                        {data.phone}
                                                                                    </div>
                                                                                    <div className='col-12 col-sm-12 col-md-6 col-lg- ' style={{ fontSize: "14px" }}>
                                                                                        {data.phone1}
                                                                                    </div>

                                                                                    <div className='col-12 col-sm-12 col-md-6 col-lg-' style={{ fontSize: "14px" }}>
                                                                                        {data.fax}
                                                                                    </div>

                                                                                </div> */}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                        <TabPanel header="My Company">
                          <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 "
                            style={{
                              backgroundColor: "#fbfcfa",
                              padding: "15px",
                            }}
                          >
                            <div className="font-fam-for-all">
                              <div className="card my-3 hover-card slide-in1">
                                <div className="flex justify-between">
                                  <div className="p-4">
                                    <h2 className="srch-cand-text2">
                                      My Company
                                    </h2>
                                    <div className="srch-cand-text"></div>
                                  </div>
                                  <div>
                                    <div className="p-4">
                                      {hasPermission(
                                        "AllowEmployeeWithUpdate"
                                      ) && (
                                          <button
                                            className="datatable-highlight-color"
                                            onClick={() => setVisible6(true)}
                                          >
                                            <div className="flex align-items-center webapp-global-sm-size">
                                              <i className="pi pi-paperclip mr-1"></i>

                                              <div className="font-medium">
                                                Assign Company
                                              </div>
                                            </div>
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div className="grid p-4">
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <EmployeeCompany data={data} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPanel>

                        {hasPermission("AllowViewAndUpdateUsers") && (
                          <TabPanel header="Manage Permission">
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 "
                              style={{
                                backgroundColor: "#fbfcfa",
                                padding: "15px",
                              }}
                            >
                              <div className="font-fam-for-all">
                                <div className="card my-3 hover-card slide-in1">
                                  <div className="flex justify-between gap-2">
                                    <div className="p-4">
                                      <h2
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "18px",
                                        }}
                                      >
                                        Manage Permission
                                      </h2>
                                    </div>
                                    <button
                                      className="datatable-highlight-color p-4"
                                      onClick={() => setVisible9(true)}
                                    >
                                      <div className="flex align-items-center webapp-global-sm-size">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                        >
                                          <path
                                            d="M10.0003 4.16669V15.8334M4.16699 10H15.8337"
                                            stroke="#004EEC"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                        <div className="font-medium">
                                          Assign Roles
                                        </div>
                                      </div>
                                    </button>
                                  </div>
                                  <div className="grid p-4">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                      <EmployeePermission
                                        data={selectedProduct}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                        )}
                      </TabView>
                    </div>
                  </div>
                ) : showSkeleton ? (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 pt-0 ">
                    <div className="card col-12" style={{ height: "255px" }}>
                      <div
                        className="grid justify-content-center align-items-center"
                        style={{ height: "255px" }}
                      >
                        No employee found
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className=" card col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 pt-0">
                    <div className="border-round border-1 surface-border p-4 surface-card">
                      <div className="flex mb-3">
                        <Skeleton
                          shape="circle"
                          size="4rem"
                          className="mr-2"
                        ></Skeleton>
                        <div>
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                          <Skeleton height=".5rem"></Skeleton>
                        </div>
                      </div>
                      <Skeleton width="100%" height="150px"></Skeleton>
                      <div className="flex justify-content-between mt-3">
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {visible && <AddVstateEmployee
            visible={visible}
            setVisible={setVisible}
            pageRefresh={pageRefresh}
            setPageRefresh={setPageRefresh}
          />}

          {visible4 && <EmployeeUpload
            visible={visible4}
            setVisible={setVisible4}
            pageRefresh={pageRefresh}
            setPageRefresh={setPageRefresh}
          />}

          {visible6 && <AssignCompanyAccountManager
            visible={visible6}
            setVisible={setVisible6}
            data={data}
          />}

          {visible9 && <AddPermission
            visible={visible9}
            setVisible={setVisible9}
            value={[]}
            contactId={0}
            companyId={0}
            userDetailsId={data?.userId}
          />}
        </div>
      </div>
    </>
  );
}
