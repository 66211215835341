import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useState } from "react";

function FormWithConfirmation() {
  const [formData, setFormData] = useState({ name: "", email: "" });
  const [pendingData, setPendingData] = useState([]);
  const [confirmedData, setConfirmedData] = useState([]);

  const [status, setStatus] = useState({ approve: false, reject: false });

  const handleCheckboxChange = (e) => {
    const { value } = e.target;
    if (value === 'approve') {
      setStatus({ approve: true, reject: false });
    } else if (value === 'reject') {
      setStatus({ approve: false, reject: true });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPendingData([...pendingData, formData]);
    setFormData({ name: "", email: "" }); // reset form after submitting
  };

  const handleAccept = (index) => {
    const acceptedItem = pendingData[index];
    setConfirmedData([...confirmedData, acceptedItem]);

    // Call your API here after user accepts the data
    console.log("API Call with data: ", acceptedItem);

    // Remove the accepted item from the pending list
    const updatedPendingData = pendingData.filter((_, i) => i !== index);
    setPendingData(updatedPendingData);
  };

  const handleReject = (index) => {
    const updatedPendingData = pendingData.filter((_, i) => i !== index);
    setPendingData(updatedPendingData);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          value={formData.name}
          placeholder="Name"
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          placeholder="Email"
          onChange={handleChange}
          required
        />
        <button type="submit">Submit</button>
      </form>

      <h3>Pending Submissions</h3>
      {pendingData.length > 0 ? (
        <ul>
          {pendingData.map((data, index) => (
            <li key={index}>
              {data.name} ({data.email})
              <button onClick={() => handleAccept(index)}>Accept</button>
              <button onClick={() => handleReject(index)}>Reject</button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No pending submissions.</p>
      )}

      <h3>Accepted Data</h3>
      {confirmedData.length > 0 ? (
        <ul>
          {confirmedData.map((data, index) => (
            <li key={index}>
              {data.name} ({data.email}) - Accepted
            </li>
          ))}
        </ul>
      ) : (
        <p>No data has been accepted yet.</p>
      )}

      <section className="flex overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
        <div className="payment-icon-confirm">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M11.9998 7.99996V12M11.9998 16H12.0098M10.2898 2.85996L1.81978 17C1.64514 17.3024 1.55274 17.6453 1.55177 17.9945C1.55079 18.3437 1.64127 18.6871 1.8142 18.9905C1.98714 19.2939 2.2365 19.5467 2.53748 19.7238C2.83847 19.9009 3.18058 19.9961 3.52978 20H20.4698C20.819 19.9961 21.1611 19.9009 21.4621 19.7238C21.7631 19.5467 22.0124 19.2939 22.1854 18.9905C22.3583 18.6871 22.4488 18.3437 22.4478 17.9945C22.4468 17.6453 22.3544 17.3024 22.1798 17L13.7098 2.85996C13.5315 2.56607 13.2805 2.32308 12.981 2.15444C12.6814 1.98581 12.3435 1.89722 11.9998 1.89722C11.656 1.89722 11.3181 1.98581 11.0186 2.15444C10.7191 2.32308 10.468 2.56607 10.2898 2.85996Z" stroke="#DC6803" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <div className="flex flex-col flex-1 shrink basis-0 w-[700px]">
          <header className="flex flex-col w-full max-md:max-w-full">
            <h2 className="text-lg font-medium leading-loose text-gray-900 max-md:max-w-full">
              Pay Now
            </h2>
            <p className="mt-2 text-sm leading-5 text-gray-500 w-[500px] text-wrap">
              You have selected the <strong>Pay Now</strong> option. Your order will be processed, and payment can be made at a later date. Approval for this option will be required from vState Filings.
            </p>
          </header>
          <div className="flex gap-3 items-center mt-5 w-full text-base font-medium max-md:max-w-full">
            <div className="flex gap-3 items-start self-stretch my-auto min-w-[240px]">
              <Button className="vstate-reverse-button" label="Cancel" />
              <Button className="vstate-button" label="Submit for Approval" />

            </div>
          </div>
        </div>
      </section>


      <div className="w-[800px] my-3">
        <section className="overflow-hidden  items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
          <div className='grid custom-sidebar-header'>
            <div className='grid col-11 px-4 pt-4 pb-0'>
              <div className='col-12 sidebar-header-text1 pb-0'>
                Update order status
              </div>

            </div>
            <div className='col-1 flex justify-content-end pt-4 pb-0'>
              <button ><i className='pi pi-times'></i></button>
            </div>
            <div className='col-12 sidebar-header-text2 px-4'>
              Track and update order status.
            </div>
          </div>

          <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
            <div className="grid mt-3">
              <div className='col-12' style={{ fontSize: "14px" }}>


                <span className=" mr-3">
                  <div class="relative">
                    <Dropdown filter placeholder="Choose status" style={{ height: "40px" }}
                      className="w-full"

                    />
                    <label htmlFor="floating_outlined25"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Status <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                </span>

              </div>
              <div className='col-12' style={{ fontSize: "14px" }}>


                <span className=" mr-3">
                  <div class="relative">
                    <InputTextarea rows={5} cols={100} placeholder="Type a description about status update "
                      className="w-full"

                    />
                    <label htmlFor="floating_outlined25"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Notes <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                </span>

              </div>
            </div>
            <div className="flex gap-3 items-center mt-2 justify-content-end w-full text-base font-medium max-md:max-w-full">
              <div className="flex gap-3 justify-content-end items-start self-stretch my-auto min-w-[240px]">
                <Button className="vstate-reverse-button" label="Cancel" />
                <Button className="vstate-button" label="Submit" />

              </div>
            </div>
          </div>
        </section>
      </div>


      <div className="w-[800px] my-3">
        <section className="overflow-hidden  items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
          <div className='grid custom-sidebar-header'>
            <div className='grid col-11 px-4 pt-4 pb-0'>
              <div className='col-12 sidebar-header-text1 pb-0'>
                Approval Pending
              </div>

            </div>
            <div className='col-1 flex justify-content-end pt-4 pb-0'>
              <button ><i className='pi pi-times'></i></button>
            </div>
            <div className='col-12 sidebar-header-text2 px-4'>
              Approve or decline order pay later request.
            </div>
          </div>

          <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
            <div className="grid mt-3">
              <div className='col-12' style={{ fontSize: "14px" }}>
                <span className="mr-3">
                  <div className="relative">
                    <div className="flex items-center">
                      <Checkbox
                        inputId="approve"
                        name="status"
                        value="approve"
                        onChange={handleCheckboxChange}
                        checked={status.approve}
                      />
                      <label htmlFor="approve" className="ml-2">Approve</label>
                    </div>

                    <div className="flex items-center mt-2">
                      <Checkbox
                        inputId="reject"
                        name="status"
                        value="reject"
                        onChange={handleCheckboxChange}
                        checked={status.reject}
                      />
                      <label htmlFor="reject" className="ml-2">Reject</label>
                    </div>
                  </div>
                </span>
              </div>

              <div className='col-12' style={{ fontSize: "14px" }}>
                <span className="mr-3">
                  <div className="relative">
                    <InputTextarea
                      rows={5}
                      cols={100}
                      placeholder="Type a description about the approval pending"
                      className="w-full"
                    />
                    <label
                      htmlFor="floating_outlined25"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Notes <span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                </span>
              </div>
            </div>

            <div className="flex gap-3 items-center mt-2 justify-content-end w-full text-base font-medium max-md:max-w-full">
              <div className="flex gap-3 justify-content-end items-start self-stretch my-auto min-w-[240px]">
                <Button className="vstate-reverse-button" label="Cancel" />
                <Button className="vstate-button" label="Submit" />
              </div>
            </div>
          </div>
        </section>
      </div>


    </div>
  );
}

export default FormWithConfirmation;
