import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import AddPermission from "./AddComponents/AddPermission";
import ContactService from "../services/ContactService";
import authContext from "../common/authContext";
import { Skeleton } from "primereact/skeleton";

export default function GroupDataTable({ data }) {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh,
  } = useContext(authContext);

  const [companyData, setCompanyData] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [visible, setVisible] = useState(false);
  const [data1, setData1] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [contactDetails, setContactDetails] = useState(null);
  const [userDetailsId, setUserDetailsId] = useState(null);
  const [contactId, setContactId] = useState(null);

  const user = JSON.parse(localStorage.getItem("user"));

  const [superAdminId, setSuperAdminId] = useState(null);

  const [showSkeleton, setShowSkeleton] = useState(false);

  const [employeeId, setEmployeeId] = useState(null);

  function checkIdsExist(arr, idsToCheck) {
    // Check if any of the idsToCheck are found in arr
    const foundIds = idsToCheck.filter((idToCheck) =>
      arr.some((item) => item.id === idToCheck)
    );
    return foundIds.length > 0 ? foundIds : null;
  }

  useEffect(() => {
    if (user) {
      if (user.companyRoles !== undefined) {
        if (user.companyRoles.length > 0) {
          if (user.companyRoles[0].roles.length > 0) {
            const id = checkIdsExist(user.companyRoles[0].roles, [1, 2]);
            const empId = checkIdsExist(user.companyRoles[0].roles, [4]);
            console.log(id);
            setSuperAdminId(id);
            setEmployeeId(empId);
          }
        }
      }
    }
  }, []);

  console.log(data);
  useEffect(() => {
    if (data) {
      ContactService.getAllContactById(data?.contactDetails?.id)
        .then((res) => {
          console.log(res.data);
          setCompanyData(res.data.companyRoles);
          setContactDetails(res.data.contactDetails);
          setContactId(res.data?.contactDetails?.id);
          setShowSkeleton(true);
        })
        .catch((err) => {
          console.log(err);
          setShowSkeleton(true);
        });
    }
  }, [data, isTabRefresh]);

  const handleManageRoles = (companyId) => {
    console.log(contactDetails);
    if (contactDetails !== null && contactDetails !== undefined) {
      setUserDetailsId(contactDetails.userId);
    }
    console.log(companyId);
    if (companyId.companyDetails !== undefined) {
      if (companyId.companyDetails) {
        setCompanyId(companyId.companyDetails.id);
      }

      if (data) {
        console.log(data);
        // const roles = data.roles.find(role => role.companyId === companyId.companyDetails.id)?.roles || [];

        setSelectedRoles(companyId.roles);
        setData1(companyId.roles);
        setVisible(true);
      }
    }
  };

  const roleNamesBodyTemplate = (rowData) => {
    console.log(rowData);
    if (data) {
      // const roles = data.companyRoles.find(role => role.companyId === rowData.id)?.roles || [];
      return rowData.roles.map((role) => role.roleName).join(", ");
    }
  };

  const actionButtonBodyTemplate = (rowData) => {
    console.log(rowData);
    return (
      <>
        {superAdminId ? (
          <button
            className="datatable-highlight-color"
            onClick={() => handleManageRoles(rowData)}
          >
            Manage Permission
          </button>
        ) : (
          <button
            className="datatable-highlight-color"
            onClick={() => handleManageRoles(rowData)}
          >
            Request Permission
          </button>
        )}
      </>
    );
  };

  const items = Array.from({ length: 5 }, (v, i) => i);

  return (
    <div className="card p-fluid">
      {showSkeleton ? (
        <DataTable
          value={companyData}
          className="font-fam-for-all datatable-lg-col webapp-sm-size"
          scrollHeight="flex"
          scrollWidth="100%"
        >
          <Column
            field="companyDetails.companyName"
            header="Company Name"
            className="font-fam-for-all datatable-lg-col webapp-sm-size"
          />
          <Column
            body={roleNamesBodyTemplate}
            header="Access Level"
            className="font-fam-for-all datatable-lg-col webapp-sm-size"
          />
          {!employeeId && (
            <Column
              body={actionButtonBodyTemplate}
              header="Action"
              className="font-fam-for-all datatable-lg-col webapp-sm-size"
            />
          )}
        </DataTable>
      ) : (
        <DataTable
          value={items}
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          inline
          paginator
          emptyMessage="No Candidate Found"
          selectionMode="single"
          metaKeySelection={false}
        >
          <Column body={<Skeleton />} className="datatable-body-icon"></Column>
        </DataTable>
      )}


      <AddPermission
        visible={visible}
        setVisible={setVisible}
        value={data1}
        contactId={contactId}
        companyId={companyId}
        userDetailsId={userDetailsId}
      />
    </div>
  );
}
