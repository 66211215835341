// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-payment-modal .modal-content {
    border-radius: 16px;
    border: none;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.confirm-payment-modal .modal-body {
    padding: 2rem;
}

.warning-icon-wrapper {
    width: 48px;
    height: 48px;
    background-color: #fff3e0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1rem;
}

.warning-icon {
    color: #ff9800;
    font-size: 24px;
}

.confirm-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #212529;
}

.confirm-text {
    color: #6c757d;
    margin-bottom: 0;
}

.btn-light {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    color: #212529;
}

.btn-light:hover {
    background-color: #e9ecef;
    border-color: #e9ecef;
}

.btn-primary {
    padding-left: 1rem;
    padding-right: 1rem;
} `, "",{"version":3,"sources":["webpack://./src/components/Payment/ConfirmPaymentModal.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;IACZ,0CAA0C;AAC9C;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".confirm-payment-modal .modal-content {\n    border-radius: 16px;\n    border: none;\n    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);\n}\n\n.confirm-payment-modal .modal-body {\n    padding: 2rem;\n}\n\n.warning-icon-wrapper {\n    width: 48px;\n    height: 48px;\n    background-color: #fff3e0;\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin: 0 auto 1rem;\n}\n\n.warning-icon {\n    color: #ff9800;\n    font-size: 24px;\n}\n\n.confirm-title {\n    font-size: 1.25rem;\n    font-weight: 600;\n    margin-bottom: 0.5rem;\n    color: #212529;\n}\n\n.confirm-text {\n    color: #6c757d;\n    margin-bottom: 0;\n}\n\n.btn-light {\n    background-color: #f8f9fa;\n    border-color: #f8f9fa;\n    color: #212529;\n}\n\n.btn-light:hover {\n    background-color: #e9ecef;\n    border-color: #e9ecef;\n}\n\n.btn-primary {\n    padding-left: 1rem;\n    padding-right: 1rem;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
