import React from 'react';
import { useNavigate } from 'react-router-dom';

function SidebarItem({ icon, label }) {
  const history=useNavigate()
  return (
    <button>

      <div className="flex flex-col px-3 pt-2 w-full bg-white-50">
        <div className="flex justify-center items-center self-center px-3 w-12 h-12 bg-white rounded-md">
          <img loading="lazy" src={icon} alt={`${label} icon`} width={24} height={24} className=" aspect-square" />
        </div>
        
      </div>
      <div className="mt-1 w-full text-base font-medium leading-6 text-center text-slate-700">
          {label}
        </div>
    </button>
  );
}

export default SidebarItem;