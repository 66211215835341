import React from 'react';
import bgPic from "../../../Assets/Images/Rectangle.png"
import { useNavigate } from 'react-router-dom';

const CallToAction = ({callToActionData}) => {
  const navigate=useNavigate()
  return (
    <section className="flex relative flex-col py-24 pr-96 pl-96 mt-7 w-full max-w-[1903px] min-h-[479px] max-md:px-5 max-md:max-w-full">
      <img loading="lazy" src={bgPic} alt="Background image" className="object-cover absolute inset-0 size-full" />
      <div className="flex absolute inset-0 z-0 w-full bg-gray-800 min-h-[479px] opacity-10" />
      <div className="flex relative z-10 justify-center self-center w-full">
        <div className="flex flex-col flex-1 shrink justify-center px-2.5 pt-2.5 pb-2.5 w-full basis-0 min-w-[240px] max-md:max-w-full">
          <h2 className="flex flex-col justify-center pb-6 w-full text-5xl font-semibold text-center text-white leading-[60px] min-h-[145px] max-md:max-w-full max-md:text-4xl max-md:leading-[59px]">
            <div className="flex flex-col px-40 w-full h-[120px] max-md:px-5 max-md:max-w-full max-md:text-4xl max-md:leading-[59px]">
              <div className="px-5 pb-px w-full max-md:max-w-full max-md:text-4xl max-md:leading-[59px]">
                {callToActionData.title}
              </div>
            </div>
          </h2>
          <p className="flex flex-col justify-center pb-6 w-full text-2xl font-medium tracking-wider leading-none text-center text-white min-h-[68px] max-md:max-w-full">
            <span className="flex-1 pr-44 pl-44 w-full max-md:px-5 max-md:max-w-full">
              {callToActionData.description}
            </span>
          </p>
          <div className="flex flex-col w-full min-h-[54px] max-md:max-w-full">
            <div className="flex flex-col items-center px-96 w-full max-md:px-5 max-md:max-w-full">
              <button className="flex justify-center items-start px-5 py-4 vstate-button rounded shadow-[0px_3px_11px_rgba(0,0,0,0.5)] max-md:px-5" onClick={()=>navigate(callToActionData.path)}>
                <span className="text-base font-medium leading-none text-center text-white">
                  {callToActionData.buttonText}
                </span>
                <span className="flex flex-col justify-center items-start pl-1.5 w-[21px]">
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/deb8c6c444fb75b11d62f205a274aafdde26dd2974c8c85db13fd7aaf567ae2c?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0" alt="Arrow icon" className="object-contain w-full aspect-square" />
                </span>
              </button>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default CallToAction;