import React, { useContext } from "react";
import authContext from "../../common/authContext";

function WelcomeSection() {
  const { loginUserName = "User" } = useContext(authContext);
  return (
    <section className="flex flex-col w-full max-md:max-w-full px-4">
      <div className="flex gap-4 items-start w-full max-md:max-w-full">
        <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full">
          <h1 className="wlcm-heading">
            Welcome, {loginUserName}!
          </h1>
          <p className="paragraph-textt-wel mt-1">
            Register a new entity, place orders for your entity compliance, get updates on ongoing orders and filings.
          </p>
        </div>
      </div>
    </section>
  );
}

export default WelcomeSection;
