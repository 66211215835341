import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import CommonServiceCard from "../CommonSection/CommonServiceCard";
import ser1 from "../../../../src/Assets/Images/Images/h1.png";
import ser2 from "../../../../src/Assets/Images/Images/h2.png";
import ser3 from "../../../../src/Assets/Images/Images/h3.png";
import ser4 from "../../../../src/Assets/Images/Images/h4.png";
import ser5 from "../../../../src/Assets/Images/Images/h5.png";
import ser6 from "../../../../src/Assets/Images/Images/h6.png";
import ser7 from "../../../../src/Assets/Images/Images/h7.png";
import AOS from "aos";
import "aos/dist/aos.css";

const ServiceCard = () => {
  const services = [
    {
      image: ser2,
      title: "Corporate Filings",
      description:
        "Corporate filings cover key services like foreign qualifications, mergers, conversions, and dissolutions, ensuring legal business operations across jurisdictions during major changes and expansions.",
      buttonLabel: "More Details",
      buttonLink: "/corporatefilings",
    },
    {
      image: ser3,
      title: "Compliance",
      description:
        "Compliance services help your business meet regulatory requirements, such as filing annual reports, reinstating entities, and fulfilling publication obligations.",
      buttonLabel: "More Details",
      buttonLink: "/compliance",
    },
    {
      image: ser4,
      title: "Subscriptions",
      description:
        "Subscription services, including registered agent services, annual reports, and independent managers or directors, provide ongoing support to ensure your business remains compliant.",
      buttonLabel: "More Details",
      buttonLink: "/subscription",
    },
    {
      image: ser5,
      title: "UCC Filings",
      description:
        "UCC filings and lien searches protect the rights of creditors by publicly recording a claim on a debtor's assets. These filings are essential for businesses engaged in lending, leasing, or selling on credit.",
      buttonLabel: "More Details",
      buttonLink: "/uccfilings",
    },
    {
      image: ser6,
      title: "Document Retrieval",
      description:
        "Document retrieval services provide you with certified copies of critical business documents, including certificates of good standing, formation documents, and amendment documents. ",
      buttonLabel: "More Details",
      buttonLink: "/documentretrieval",
    },
    {
      image: ser7,
      title: "Corporate Supplies",
      description:
        "Corporate supplies and publications include essential items like corporate kits, seals, and stock/membership certificates. These items are necessary for formalizing business operations and keeping official records.",
      buttonLabel: "More Details",
      buttonLink: "/corporatesuplies",
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="main-section-class">
      <div className="entity-formation">
        <Container>
          <CommonHeadingTitle
            heading={"Incorporation"}
            blueheading={"services"}
          />
          <div className="text-center mt-4 mb-4">
            <p className="home-banner-sub-tile ">
              We offer comprehensive services to help you establish <br /> and
              operate your US LLC or corporation.
            </p>
          </div>
          <div data-aos="fade-up" data-aos-duration="3000">
            <Row className="justify-content-center">
              <Col lg={8} md={8} sm={12} xs={12}>
                <div className="mt-4">
                  <CommonServiceCard
                    title="Entity Formation"
                    image={ser1}
                    description={
                      "Entity Formation services help establish businesses like Corporations (Corp), LLCs, PCs, and PLLCs, offering legal protection and tax benefits"
                    }
                    buttonLabel={"More Details"}
                    buttonLink={"/webcompany"}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div data-aos="fade-up" data-aos-duration="3000">
            <Row>
              {services.map((service, index) => (
                <Col lg={4} md={6} sm={12} xs={12}>
                  <div className="mt-4">
                    <CommonServiceCard
                      key={index}
                      title={service.title}
                      image={service.image}
                      description={service.description}
                      buttonLabel={service.buttonLabel}
                      buttonLink={service.buttonLink}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default ServiceCard;
