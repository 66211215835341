import React from 'react';

const Navigation = () => {
  const navItems = [
    { label: 'About Us', isActive: false,path:"/" },
    { label: 'Services', isActive: false,path:"/#/webcompany" },
    { label: 'Registered Agent', isActive: false,path:"/#/registeredcompany" },
    { label: 'Contact Us', isActive: false,path:"/#/webcontact" },
  ];

  return (
    <nav className="flex flex-wrap gap-4 website-font justify-center py-3.5 font-medium leading-[143%] text-indigo-950 align-items-center">
      {navItems.map((item, index) => (
        <a key={index} href={item.path} className={item.isActive ? 'text-blue-700' : ''}>
          {item.label}
        </a>
      ))}
    </nav>
  );
};

export default Navigation;