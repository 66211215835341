import React from 'react';

function Header() {
  return (
    <header className="flex flex-col justify-center items-center w-full max-md:max-w-full">
      <div className="flex flex-col self-stretch px-10 w-full max-md:px-5 max-md:max-w-full">
        <div className="flex flex-wrap items-center w-full max-md:max-w-full">
          <div className="flex overflow-hidden flex-col flex-1 shrink justify-center items-start self-stretch my-auto basis-0 min-w-[240px] max-md:max-w-full">
            <h1 className="w-full text-5xl font-bold tracking-tighter text-slate-700 max-md:max-w-full max-md:text-4xl">
              <span className="font-semibold text-black leading-[58px]">
                Get the most reliable{" "}
              </span>
              <span className="font-semibold text-blue-700 leading-[58px]">
                registered agent services
              </span>
              <span className="font-semibold text-black leading-[58px]">
                {" "}
                for your business
              </span>
            </h1>
            <div className="flex flex-col mt-9">
             
                <button className="flex justify-center items-start px-4 py-3 vstate-button rounded shadow-[0px_3px_11px_rgba(0,0,0,0.5)] max-md:px-5">
                  <span className="text-base font-medium leading-none text-center text-white">
                    Signup now
                  </span>
                  <span className="flex flex-col justify-center items-start pl-1.5 w-[21px]">
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/deb8c6c444fb75b11d62f205a274aafdde26dd2974c8c85db13fd7aaf567ae2c?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0" alt="Arrow icon" className="object-contain w-full aspect-square" />
                  </span>
                </button>
            
            </div>
            <div className="flex gap-4 items-center pt-px mt-9 text-sm leading-6 text-neutral-600">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1f19d56aed7317c65f482ebdf9ba616a89f35c5052a4a412d84a078eeeef1d9c?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0" alt="Customer satisfaction rating" className="object-contain shrink-0 self-stretch my-auto aspect-[2.62] w-[131px]" />
              <p className="flex flex-col justify-center self-stretch my-auto w-[163px]">
                32k Satisfied Customers
              </p>
            </div>
          </div>
          <div className="flex flex-1 shrink items-center self-stretch my-auto basis-0 min-w-[240px] max-md:max-w-full">
            <div className="flex justify-center items-center self-stretch my-auto bg-orange-300 rounded-3xl min-w-[240px] w-[540px]">
              <div className="flex flex-col flex-1 shrink self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                <div className="flex flex-col justify-center h-[356px] max-md:max-w-full">
                  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/f6faeccce27196208810871449c72c2f75db818a98ae2cfba17dc4a64f077abc?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0" alt="Illustration of registered agent services" className="object-contain w-full aspect-[1.48] max-md:max-w-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;