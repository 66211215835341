import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import CreditCardServices from "../../services/strapiService";
import AddCity from "./AddProduct";
import DownloadPdf from "../../utils/downloadPdf";
import SideBarMenu from "../../common/Sidebar";
import ShiftsServices from "../../services/demoServices";
import { Checkbox } from "primereact/checkbox";
import { TabView, TabPanel } from "primereact/tabview";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import SuccessImage from "../../Assets/Images/icons8-success-96.png";
import ErroImage from "../../Assets/Images/error-icon.png";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import encryption from "../../services/CryptoClass";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Password } from "primereact/password";
import AddressService from "../../services/addressService";
import ContactService from "../../services/ContactService";
import MasterServices from "../../services/coreServices";
import OrderServices from "../../services/orderServices";
import { useNavigate } from "react-router-dom";
import companyMasterService from "../../services/companyMasterService";
import AddCompanyMember from "../Company/AddCompanyComponets/AddMember";
import authContext from "../../common/authContext";
import { Accordion, AccordionTab } from "primereact/accordion";
import AddressTemplate from "../../utils/AddressTemplate";
import AddCompanyLocation from "../Company/AddCompanyComponets/AddLocation";
import { InputTextarea } from "primereact/inputtextarea";
import PaymentSuccess from "../Payment/PaymentSuccess";
import PaymentFailure from "../Payment/PaymentFailure";
import CustomModal from "../../utils/CustomModal";
import PaymentOptions from "../Payment/PaymentOptions";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import propertiesApp from "../../utils/propertiesApp";
import { Toast } from "primereact/toast";
import validationConfig from "../../utils/UsaStatesRegex";
import AddCompany from "./AddCompany";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { showToast } from "../../utils/toastUtils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import KeyPersonnelIndividualCard from "../../utils/KeyPersonnelIndividualCard";
import { commonGetInitials } from "../../utils/getInitials";
import { stateWithCounty } from "../../utils/stateWithCounty";

const AddOrderFormService = () => {
  const registeredTabRef = useRef(null);

  const handleScroll = () => {
    if (registeredTabRef.current) {
      registeredTabRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      console.error("registeredTabRef is null");
    }
  };

  const {
    loginUserName,
    setLoginUserName,
    addonServiceRedirectedData,
    globalCompany,
    setOrderContinue,
    isLoading,
    addOnServiceData,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh,
    entityDash,
    stateDash,
    paymentMethod,
    setPaymentMethod,
    orderTypeId,
    setOrderTypeId,
    paymentData,
    setPaymentData,
    paymentOrderData,
    setPaymentOrderData,
    wizardScroll,
    setWizardScroll,
  } = useContext(authContext);

  const [allState, setAllState] = useState([]);

  const stripePromise = loadStripe(
    "pk_test_51PyTdiKhsNfb7YuLO8XP7SHE3FUn3rspK3sI7OTbOIulKp9XdM7WtU4gqjxiKVDVBldIKIEBvL8PLjdKkW1h8f1100bw75rZPd"
  );

  const toast = useRef(null);

  const [contactId, setContactId] = useState(null);

  const history = useNavigate();

  const steps = [
    {
      label: "Select campaign settings",
      description: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
    },
    {
      label: "Create an ad group",
      description:
        "An ad group contains one or more ads which target a shared set of keywords.",
    },
    {
      label: "Create an ad",
      description: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
    },
  ];

  const [selectedCheckBoxData, setSelectedCheckBoxData] = useState([]);
  const [hasError, setHasError] = useState(false);

  const [checkedState, setCheckedState] = useState({});

  const [strapiId, setStrapiId] = useState(null);

  const [loading1, setLoading1] = useState(false);

  const [updatedOrderLineItems, setUpdatedOrderLineItems] = useState([]);

  const [keyPersonalCompany, setKeyPersonalCompany] = useState(null);

  const [isFirstDraft, setIsFirstDraft] = useState(false);

  const [showSkeleton, setShowSkeleton] = useState(false);

  const navigate = useNavigate();
  const [superAdminId, setSuperAdminId] = useState(null);
  const [IsEmployee, setIsEmployee] = useState(null);

  useEffect(() => {
    if (user?.companyRoles?.length) {
      const allRoles = user.companyRoles.flatMap(
        (companyRole) => companyRole.roles || []
      );

      const id = checkIdsExist(allRoles, [1, 4]);
      const empId = checkIdsExist(allRoles, [4]);

      setSuperAdminId(id);

      if (empId) {
        setIsEmployee(empId);
      }
    }
  }, []);

  function checkIdsExist(arr, idsToCheck) {
    const foundIds = idsToCheck.filter((idToCheck) =>
      arr.some((item) => item.id === idToCheck)
    );
    return foundIds.length > 0 ? foundIds : null;
  }

  useEffect(() => {
    // Check if the page is being refreshed
    const isRefreshed = sessionStorage.getItem("isRefreshed");

    if (isRefreshed) {
      // If refresh detected, redirect to '/order'
      navigate("/dashboard");
      sessionStorage.removeItem("isRefreshed"); // Clear the flag after redirect
    }

    const handleBeforeUnload = () => {
      // Set flag in sessionStorage on page unload (refresh or close)
      sessionStorage.setItem("isRefreshed", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  const tempdata = [
    {
      item: "State",
      getAllUrl: "/core/api/stateMaster/getAll",
      label: "stateFullDesc",
      optionValue: "stateShortName",
      getAditionalUrl: [
        {
          field: "countryMaster",
          url: "/core/api/stateMasters/getAll",
          label: "stateFullDesc",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Country",
      getAllUrl: "/core/api/countryMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/countryMasters/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Contact Source Master",
      getAllUrl: "/core/api/contactSourceMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },

    {
      item: "Industry Master",
      getAllUrl: "/core/api/industryMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Record Type Master",
      getAllUrl: "/core/api/recordTypeMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },

    {
      item: "Permission Master",
      getAllUrl: "/core/api/permissionMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "",
        },
      ],
      saveUrl: "",
    },

    {
      item: "Status Master",
      getAllUrl: "/core/api/statusMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "statusForId",
          url: "/core/api/statusForMasters/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },
    {
      item: "Record Type Master",
      getAllUrl: "/core/api/recordTypeMaster/getAll",
      label: "countryFullDesc",
      optionValue: "countryShortName",
      getAditionalUrl: [
        {
          field: "",
          url: "/core/api/permissionMaster/getAll",
          label: "statusForName",
        },
      ],
      saveUrl: "",
    },
  ];

  const [formData, setFormData] = useState(null);

  const [allAddressType, setAllAddressType] = useState([]);

  const [keyPersonalAddressType, setKeyPersonalAddressType] = useState(null);

  const [IsCheckedAgent, setIsCheckedAgent] = useState(true);

  const [entityTypes, setEntityTypes] = useState([]);

  const [visible2, setVisible2] = useState(false);

  const [visible3, setVisible3] = useState(false);

  const [previewData, setPriviewData] = useState(null);

  const [memberType, setMemberType] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));

  const [optionsMap, setOptionsMap] = useState({});

  const [allBilingAddress, setAllBilingAddress] = useState([]);

  const [allShippingAddress, setAllShippingAddress] = useState([]);

  const [bilingAddress, setBilingAddress] = useState(null);

  const [shippingAddress, setShippingAddress] = useState(null);

  const [closeProgresbar2, setCloseProgresbar2] = useState(false);

  const [closeProgresbar3, setCloseProgresbar3] = useState(false);

  const [entityErr, setEntityErr] = useState("");

  const [stateErr, setStateErr] = useState("");

  const [visible9, setVisible9] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const localStorageData = JSON.parse(localStorage.getItem("user"));
  const roles = localStorageData?.companyRoles[0]?.roles?.[0]?.roleName;
  const isSuperAdmin = roles === "SUPER_ADMINISTRATOR" || "Employee";

  const setOptionsForField = (fieldKey, options) => {
    setOptionsMap((prev) => ({
      ...prev,
      [fieldKey]: options,
    }));
  };

  useEffect(() => {
    companyMasterService
      .getAllParentCompanyList()
      .then((res) => {
        if (Array.isArray(res.data)) {
          setAllParentCompany(res.data);
        }
      })
      .catch((err) => {});
  }, []);

  // useEffect(() => {
  //   MasterServices.getAll("/core/api/entityTypeMaster/getAll")
  //     .then((res) => {
  //       if (res.data !== "") {
  //         setEntityTypes(res.data);
  //       }
  //     })
  //     .catch((err) => {});

  useEffect(()=>{
    ContactService.getAllContact()
    .then((res) => {
      let data = res.data;

      const tempdata = data.filter(
        (val) => val.contactDetails.emailId === user.username
      );

      if (tempdata.length > 0) {
        if (tempdata[0].contactDetails !== undefined) {
          setContactId(tempdata[0].contactDetails.id);
        }
      }
    })
    .catch((err) => {});

    AddressService.getAllAddressType()
      .then((res) => {
        if (res.data !== undefined) {
          if (res.data !== "") {
            if (Array.isArray(res.data)) {
              setAllAddressType(res.data);
            }
          }
        }
      })
      .catch((err) => {});

  },[])
   
    
  // }, []);

  const [enityName, setEntityName] = useState(null);
  const [state, setState] = useState(null);
  const [county, setCounty] = useState(null);

// Get entity types on mount
useEffect(() => {
  MasterServices.getAll("/core/api/entityTypeMaster/getAll")
    .then((res) => {
      if (res.data !== "") {
        setEntityTypes(res.data);
      }
    });
}, []);

// Get counties when state is selected
useEffect(() => {
  if (state) {
    AddressService.getAllCountyByState(state.id)
      .then((res) => {
        setAllCounty(res.data);
      })
      .catch((err) => {
      });
  }
}, [state]);

// Get products if entityName and state are defined, and if the state requires a county then a county is selected
useEffect(() => {
  if (
    enityName &&
    state &&
    (!stateWithCounty.includes(state.stateFullDesc) || (stateWithCounty.includes(state.stateFullDesc) && county))
  ) {
    MasterServices.getAllProduct(
      orderTypeId,
      enityName.id,
      state.id,
      county ? county.id : 0
    )
      .then((res) => {
        setShowSkeleton(true);
        if (Array.isArray(res.data)) {
          const sortedData = res.data.sort(
            (a, b) => a.product.displayPreference - b.product.displayPreference
          );
          setServiceData(sortedData);
          const temp = res.data.filter(
            (val) => val?.product?.orderTypeMaster === orderTypeId
          );
          setSelectedCheckBoxData(temp);
        } else {
          setServiceData([]);
        }
      })
      .catch((err) => {
        setShowSkeleton(true);
      });
  }
}, [enityName, state, county]);


  
  const [allKeyPersonnelType, setAllKeyPersonnelType] = useState([]);

  useEffect(() => {
    companyMasterService
      .getAllKeyPersonnelType()
      .then((res) => {
        if (res.data !== "") {
          setAllKeyPersonnelType(res.data);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (globalCompany) {
      if (globalCompany.companyDetails) {
        companyMasterService
          .getAllKeyPersonnelByCompanyId(globalCompany.companyDetails.id)
          .then((res) => {
            if (res.data !== "") {
              if (Array.isArray(res.data)) {
                const temp = res.data.filter((val) => val?.addressMasterId);

                setAllOrganizer(temp);
                setAllInCorporator(temp);
                setAllBilingAddress(temp);
                setAllShippingAddress(temp);
                setAllOfficer(temp);
                setAllDirector(temp);
              }
            }
          })
          .catch((err) => {});
      }
    }
  }, [visible2, globalCompany]);

  const handleCheckedChange = (e, serviceData) => {
    const { checked } = e.target;

    setCheckedState((prev) => ({
      ...prev,
      [serviceData.product.displayName]: checked,
    }));

    if (checked) {
      setSelectedCheckBoxData((prev) => [...prev, serviceData]);
    } else {
      setSelectedCheckBoxData((prev) =>
        prev.filter(
          (item) => item.product.displayName !== serviceData.product.displayName
        )
      );
    }
  };

  // Initialize Bootstrap tooltips
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }, []);

  // const totalAmount = selectedCheckBoxData.reduce(
  //     (acc, item) => acc + parseFloat(item.amount.replace("$", "")),
  //     0
  // );

  const [creditAll, setCreditAll] = useState([]);

  const [creditInfo, setCreditInfo] = useState(null);



  const [inputValue, setInputValue] = useState("");
  const [isAvailable, setIsAvailable] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [llcMember, setLLCMember] = useState([]);

  const [llc, setLlc] = useState(null);

  const [allLLC, setAllLLC] = useState([]);

  const [llcName, setLLCName] = useState(null);

  const [visible, setVisible] = useState(false);

  const [selectCheckBoxData, setSelectCheckBoxData] = useState([]);

  const [IsChecked1, setIsChecked1] = useState(false);

  const [IsChecked2, setIsChecked2] = useState(false);

  const [IsChecked3, setIsChecked3] = useState(false);

  const [IsChecked4, setIsChecked4] = useState(false);

  const [IsChecked5, setIsChecked5] = useState(false);

  const [IsChecked6, setIsChecked6] = useState(false);

  const [IsChecked7, setIsChecked7] = useState(false);

  const [IsChecked8, setIsChecked8] = useState(false);

  const [IsChecked9, setIsChecked9] = useState(false);

  const [IsChecked10, setIsChecked10] = useState(false);

  const [IsChecked11, setIsChecked11] = useState(false);

  const [currentTab, setCurrentTab] = useState(0);

  const [closeProgresbar, setCloseProgresbar] = useState(false);

  const [closeProgresbar1, setCloseProgresbar1] = useState(false);

  const [dba, setDba] = useState("");

  const [name, setName] = useState("");

  const [AlternateName, setAlternateName] = useState("");

  const [llcAddress, setLLCAddress] = useState("");

  const [mailingAddress, setMailingAddress] = useState("");

  const [initData, setInitData] = useState(null);

  const [initData1, setInitData1] = useState(null);

  const [initData2, setInitData2] = useState(null);

  const [allInitData, setAllInitData] = useState([]);

  const [inputFormData, setInputFormData] = useState([]);

  const [allModels, setAllModels] = useState([]);

  const [modelData, setModelData] = useState([]);

  const [modelData1, setModelData1] = useState([]);

  const [IsSuccessful, setIsSuccessFul] = useState(false);

  const [modelName, setModelName] = useState(null);

  const [currentStep, setCurrentStep] = useState(1);

  const [activeStep, setActiveStep] = React.useState(0);

  const [globalModel, setGlobalModel] = useState([]);

  const [allCounty, setAllCounty] = useState([]);


  const [allComponents, setAllComponents] = useState([]);

  const [mainModel, setMainModel] = useState(null);

  const [rawModel, setRawModel] = useState([]);

  const [groupInitialData, setGroupInitialData] = useState({});

  const [showDescription, setShowDescription] = useState(false);

  const [organizerData, setOrganizerData] = useState(null);

  const [allOrganizer, setAllOrganizer] = useState([]);

  const [pageRefresh, setPageRefresh] = useState(false);

  const [allCompany, setAllCompany] = useState([]);

  const [company, setCompany] = useState(null);

  const [allLocation, setAllLocation] = useState([]);

  const [location, setLocation] = useState(null);

  const [allInCorporator, setAllInCorporator] = useState([]);

  const [director, setDirector] = useState(null);

  const [allDirector, setAllDirector] = useState([]);

  const [officer, setOfficer] = useState(null);

  const [allOfficer, setAllOfficer] = useState([]);

  const [incorporator, setIncorporator] = useState(null);

  const [allCountries, setAllCountries] = useState([]);

  const [agentLocation, setAgentLocation] = useState(null);

  const [countyErr, setCountyErr] = useState("");

  const [servicesData, setServiceData] = useState([]);

  const [allAdminCompany, setAllAdminCompany] = useState([]);

  const [adminCompany, setAdminCompany] = useState(null);

  const [serviceDataTemp, setServiceDataTemp] = useState([]);

  const [totalStep, setTotalStep] = useState(1);
  const [allParentCompany, setAllParentCompany] = useState([]);

  // useEffect(() => {
  //   // console.log('first')
  //   // if (servicesData.length > 0) {
  //   //     const temp = servicesData.filter((val) => val.product.isDefault === true)
  //   //     setSelectedCheckBoxData(temp)
  //   // }
  //   if (enityName && state) {
  //     MasterServices.getAllProduct(
  //       orderTypeId,
  //       enityName.id,
  //       state.id,
  //       county ? county.id : 0
  //     )
  //       .then((res) => {
  //         setShowSkeleton(true);
  //         if (Array.isArray(res.data)) {
  //           const sortedData = res.data.sort(
  //             (a, b) =>
  //               a.product.displayPreference - b.product.displayPreference
  //           );
  //           setServiceData(sortedData);
  //           const temp = res.data.filter(
  //             (val) => val?.product?.orderTypeMaster === orderTypeId
  //           );
  //           setSelectedCheckBoxData(temp);
  //         } else {
  //           setServiceData([]);
  //         }
  //       })
  //       .catch((err) => {
  //         setShowSkeleton(true);
  //       });
  //     AddressService.getAllCountyByState(state.id)
  //       .then((res) => {
  //         setAllCounty(res.data);
  //       })
  //       .catch((err) => {});
  //   }
  // }, [enityName, state, county]);

  useEffect(() => {
    if ((stateDash, entityDash)) {
      setEntityName(entityDash);

      setState(stateDash);
      setValue("state", stateDash);
    }
  }, [entityDash, stateDash]);

  useEffect(() => {
    companyMasterService
      .getAllCompany()
      .then((res) => {
        let data = res.data;
        setAllCompany(res.data);
        if (Array.isArray(res.data)) {
          const temp = res.data.filter(
            (val) => val.companyName === "vState Filings"
          );
          if (globalCompany) {
            if (globalCompany.companyDetails) {
              const keyCompany = res.data.find(
                (val) => val.id === globalCompany.companyDetails.id
              );
              setKeyPersonalCompany(keyCompany);
            }
          }
          if (temp.length > 0) {
            setCompany(temp[0]);
          }
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (globalCompany) {
      AddressService.getAllAddressByCompanyId(globalCompany.companyDetails.id)
        .then((res) => {
          if (res.data !== "") {
            if (Array.isArray(res.data)) {
              const temp = res.data.filter(
                (val) => val.addressType.type === "PRINCIPAL"
              );
              const temp1 = res.data.filter(
                (val) => val.addressType.type === "BILLING"
              );
              const temp2 = res.data.filter(
                (val) => val.addressType.type === "SHIPPING"
              );

              if (temp.length > 0) {
                setAllLocation(temp);
              }
              // if (temp1.length > 0) {
              //     setAllBilingAddress(temp1)
              // }
              // if (temp2.length > 0) {
              //     setAllShippingAddress(temp2)
              // }
            }
            // if (Array.isArray(res.data)) {
            //     const temp = res.data.filter((val) => val.addressType.type === "Principle")
            //     console.log(temp)
            //     if (temp.length > 0) {
            //         setPrinciPalAddress(temp[0])
            //         setAddr1(temp[0].addressLine1)
            //         setAddr2(temp[0].addressLine2)
            //         setCity(temp[0].city)
            //         setZip(temp[0].postalCode)
            //         setCountryData(temp[0].countryId)
            //         setStates(temp[0].stateId)
            //         setAddressType(temp[0].addressType)
            //     }
            //     else {
            //         setPrinciPalAddress(null)

            //     }
            // }
          } else {
            setAllLocation([]);
            setAllBilingAddress([]);
            setAllShippingAddress([]);
          }
        })
        .catch((err) => {});

      // if (globalCompany) {
      //   companyMasterService
      //     .getAllChildCompanyByParentId(globalCompany?.companyDetails?.id)
      //     .then((res) => {
      //       if (res.data !== "") {
      //         let data = res.data;
      //         setAllAdminCompany(data);
      //       }
      //     })
      //     .catch((err) => {});
      // }
    }
  }, [globalCompany, isTabRefresh, isLoading]);

  useEffect(() => {
    companyMasterService
      .getAllParentCompanyList()
      .then((res) => {
        if (Array.isArray(res.data)) {
          setAllParentCompany(res.data);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (superAdminId && companyName) {
      companyMasterService
        .getAllChildCompanyByParentId(companyName?.company?.id)
        .then((res) => {
          if (res.data !== "") {
            let data = res.data;
            if (Array.isArray(data)) {
              // const filteredCompanies = data.filter(
              //   (val) =>
              //     val.domesticStateId === state?.id &&
              //     val.entityType === enityName?.id
              // );
              setAllAdminCompany(data);
            }
          }
        })
        .catch((err) => {});
    } else {
      if (globalCompany) {
        companyMasterService
          .getAllChildCompanyByParentId(globalCompany?.companyDetails?.id)
          .then((res) => {
            if (res.data !== "") {
              let data = res.data;
              if (Array.isArray(data)) {
                // const filteredCompanies = data.filter(
                //   (val) =>
                //     val.domesticStateId === state?.id &&
                //     val.entityType === enityName?.id
                // );
                setAllAdminCompany(data);
              }
            }
          })
          .catch((err) => {});
      }
    }
  }, [globalCompany, isTabRefresh, isLoading, pageRefresh, companyName]);

  useEffect(() => {
    tempdata.forEach((matchedItem) => {
      if (matchedItem && matchedItem.getAllUrl) {
        MasterServices.getAll(matchedItem.getAllUrl)
          .then((fetchedData) => {
            if (Array.isArray(fetchedData.data)) {
              const labelKey = matchedItem.label || "name";
              const newOptions = fetchedData.data;
              setOptionsMap((prev) => ({
                ...prev,
                [matchedItem.item]: newOptions,
              }));
            }
          })
          .catch((err) => {});
      }
    });
  }, []);

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const [formCount, setFormCount] = useState(1);

  const handleRepeatForm = () => {
    setFormCount((prevCount) => prevCount + 1);
  };

  const handleNext = (data) => {
    if (activeStep === data.length - 1) {
      nextPrev(1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    watch,
    reset,
    clearErrors,
  } = useForm("");

  useEffect(() => {
    AddressService.getAllCountry()
      .then((res) => {
        if (res.data !== "") {
          setAllCountries(res.data);
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (company) {
      AddressService.getAllAddressByCompanyId(company.id)
        .then((res) => {
          if (res.data !== "") {
            setAllLocation(res.data);
            if (Array.isArray(res.data)) {
              const temp = res.data.filter(
                (val) => val.addressType.type === "PRINCIPAL"
              );

              if (temp.length > 0) {
                setAgentLocation(temp[0]);
              }
            }
          }
        })
        .catch((err) => {});
    }
  }, [company, IsCheckedAgent]);

  const stateFilter = (id) => {
    const temp = allState.filter((val) => val.id === id);

    if (temp.length > 0) {
      return temp[0].stateShortName;
    } else {
      return "";
    }
  };

  const countryFilter = (id) => {
    const temp = allCountries.filter((val) => val.id === id);

    if (temp.length > 0) {
      return temp[0].countryShortName;
    } else {
      return "";
    }
  };

  function extractTenDigitPhoneNumber(input) {
    if (typeof input === "string") {
      const temp3 = input.split(" ");
      if (Array.isArray(temp3)) {
        if (temp3.length > 0) {
          return temp3.slice(1).join(" ");
        }
      }
    }
  }

  useEffect(() => {
    if (organizerData) {
      setValue("Org_Name", organizerData.keyPersonnelName);
      setValue("Org_Email_Address", organizerData.emailId);
      setValue(
        "Org_Contact_No",
        extractTenDigitPhoneNumber(organizerData.contactNo)
      );
      setValue("Org_Address_Line1", organizerData.addressMasterId.addressLine1);
      setValue("Org_Address_Line2", organizerData.addressMasterId.addressLine2);
      setValue("Org_City", organizerData.addressMasterId.city);
      setValue("Org_State", stateFilter(organizerData.addressMasterId.stateId));
      setValue(
        "Org_Country",
        countryFilter(organizerData.addressMasterId.countryId)
      );
      setValue("Org_Zip_Code", organizerData.addressMasterId.postalCode);
    }
  }, [setValue, organizerData]);

  useEffect(() => {
    if (incorporator) {
      setValue("Inc_Name", incorporator.keyPersonnelName);
      setValue("Inc_Email_Address", incorporator.emailId);
      setValue(
        "Inc_Contact_No",
        extractTenDigitPhoneNumber(incorporator.contactNo)
      );
      setValue("Inc_Address_Line1", incorporator.addressMasterId.addressLine1);
      setValue("Inc_Address_Line2", incorporator.addressMasterId.addressLine2);
      setValue("Inc_City", incorporator.addressMasterId.city);
      setValue("Inc_State", stateFilter(incorporator.addressMasterId.stateId));
      setValue(
        "Inc_Country",
        countryFilter(incorporator.addressMasterId.countryId)
      );
      setValue("Inc_Zip_Code", incorporator.addressMasterId.postalCode);
    }
  }, [setValue, incorporator]);

  useEffect(() => {
    if (officer) {
      setValue("Off_Name", officer.keyPersonnelName);
      setValue("Off_Email_Address", officer.emailId);
      setValue("Off_Contact_No", extractTenDigitPhoneNumber(officer.contactNo));
      setValue("Of_Address_Line1", officer.addressMasterId.addressLine1);
      setValue("Of_Address_Line2", officer.addressMasterId.addressLine2);
      setValue("Of_City", officer.addressMasterId.city);
      setValue("Of_State", stateFilter(officer.addressMasterId.stateId));
      setValue("Of_Country", countryFilter(officer.addressMasterId.countryId));
      setValue("Of_Zip_Code", officer.addressMasterId.postalCode);
    }
  }, [setValue, officer]);

  useEffect(() => {
    if (director) {
      setValue("Dir_Name", director.keyPersonnelName);
      setValue("Dir_Email_Address", director.emailId);
      setValue(
        "Dir_Contact_No",
        extractTenDigitPhoneNumber(director.contactNo)
      );
      setValue("Dir_Address_Line1", director.addressMasterId.addressLine1);
      setValue("Dir_Address_Line2", director.addressMasterId.addressLine2);
      setValue("Dir_City", director.addressMasterId.city);
      setValue("Dir_State", stateFilter(director.addressMasterId.stateId));
      setValue(
        "Dir_Country",
        countryFilter(director.addressMasterId.countryId)
      );
      setValue("Dir_Zip_Code", director.addressMasterId.postalCode);
    }
  }, [setValue, director]);

  useEffect(() => {
    if (location) {
      setValue("PA_Address_Line1", location.addressLine1);
      setValue("PA_Address_Line2", location.addressLine2);
      setValue("PA_City", location.city);
      setValue("PA_State", stateFilter(location.stateId));

      setValue("PA_Country", countryFilter(location.countryId));
      setValue("PA_Zip_Code", location.postalCode);
    }
  }, [setValue, location]);

  useEffect(() => {
    if (company && IsCheckedAgent) {
      setValue("RA_Name", company.companyName);
      setValue("RA_Email_Address", company.companyEmail);
      setValue("RA_Contact_No", extractTenDigitPhoneNumber(company.phoneNo));
      if (agentLocation) {
        setValue("RA_Address_Line1", agentLocation.addressLine1);
        setValue("RA_Address_Line2", agentLocation.addressLine2);
        setValue("RA_City", agentLocation.city);
        setValue("RA_State", stateFilter(agentLocation.stateId));
        setValue("RA_Country", countryFilter(agentLocation.countryId));
        setValue("RA_Zip_Code", agentLocation.postalCode);
      }
    }
  }, [company, IsCheckedAgent, agentLocation]);

  useEffect(() => {
    if (adminCompany) {
      setValue("X_Name_of_Entity", adminCompany.companyName);

      if (adminCompany.formationDate) {
        setValue("CF_Year_of_formation", adminCompany.formationDate);
      }

      // Clear error messages
      const keys = ["X_Name_of_Entity"];
      keys.forEach((fieldKey) => {
        const errorMessageTag = document.getElementById(`${fieldKey}--error`);
        if (errorMessageTag) {
          errorMessageTag.textContent = "";
        }
      });
    }
  }, [adminCompany, setValue]);

  useEffect(() => {
    const data = watch();

    // if (state) {
    //     setValue("CI_State", state.stateFullDesc)
    // }

    const tem = allModels.map((val) => {
      const temps = Object.keys(data).reduce((acc, key) => {
        if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      const convertdata = val.apiId;

      return {
        model: convertdata,
        data: temps,
      };
    });

    const tempsData = allModels.map((val) => {
      const temps = Object.keys(data).reduce((acc, key) => {
        if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
          acc[key] = data[key];
        }

        return acc;
      }, {});

      const convertdata = val.uid;

      if (convertdata === "momm.name-and-address") {
        const addressFields = [
          "MM_Address_Line1",
          "MM_Address_Line2",
          "MM_City",
          "MM_State",
          "MM_Country",
          "MM_Postal_Code",
        ];
        const formData = Object.keys(data).reduce((acc, key) => {
          if (key.includes("-")) {
            const [baseKey, index] = key.split("-");

            if (baseKey) {
              // Initialize an object for this index if it doesn't exist
              if (!acc[index]) {
                acc[index] = { __temp_key__: parseInt(index), Address: {} };
              }

              if (addressFields.includes(baseKey)) {
                // If it's an address field, add it to the Address object
                acc[index].Address[baseKey] = data[key];
              } else {
                // Otherwise, add it directly to the object
                acc[index][baseKey] = data[key];
              }
            }
          }

          return acc;
        }, []);

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      } else {
        const formData = Object.keys(data).map((keys, j) => {
          if (keys.includes("-")) {
            const baseKey = keys.split("-")[0];

            if (
              baseKey &&
              Object.keys(val.schema.attributes).some((ele) => ele === baseKey)
            ) {
              return {
                __temp_key__: j + 1,
                [baseKey]: data[keys],
              };
            }
          }
        });

        const formDatas = formData.filter((val) => val !== undefined);

        if (formDatas.length > 0) {
          return {
            model: convertdata,
            data: formDatas,
          };
        } else {
          return {
            model: convertdata,
            data: temps,
          };
        }
      }
    });

    setModelData(tem);
    setModelData1(tempsData);
  }, [getValues, setValue, currentTab, formCount]);

  useEffect(() => {
    setIsLoading(true);
    AddressService.getAllStates()
      .then((res) => {
        if (res.data !== "") {
          setAllState(res.data);
        }
      })
      .catch((err) => {});
    ShiftsServices.getInit()
      .then((res) => {
        // console.log(res.data)

        // const decryptData = encryption.decryptData(res.data);

        // const tempdata = res.data.data.contentTypes
        const tempdata = [];
        //
        setAllInitData(res.data.data);
        setIsLoading(false);

        // if (tempdata.length > 0) {
        //     const temp = tempdata.filter((val) => val.apiID === "llc");
        //     const temp1 = tempdata.filter((val) => val.apiID === "llc-member");
        //     const temp2 = tempdata.filter(
        //         (val) => val.apiID === "credit-card-info"
        //     );
        //
        //     if (temp.length > 0) {
        //         temp.map((val) => {
        //             delete val.attributes.id;
        //         });
        //         const temps = temp[0].attributes;

        //         setInitData(temps);
        //     }
        //     if (temp1.length > 0) {
        //         temp1.map((val) => {
        //             delete val.attributes.id;
        //         });
        //         const temps = temp1[0].attributes;

        //         setInitData1(temps);
        //     }
        //     if (temp2.length > 0) {
        //         temp2.map((val) => {
        //             delete val.attributes.id;
        //         });
        //         const temps = temp2[0].attributes;

        //         setInitData2(temps);
        //     }
        // }
      })
      .catch((err) => {
        setIsLoading(false);
      });

    ShiftsServices.getInit()
      .then((res) => {
        setRawModel(res.data.data);
      })
      .catch((err) => {});
    CreditCardServices.getComponent()
      .then((res) => {
        setAllComponents(res.data.data);
      })
      .catch((err) => {});
  }, []);

  function flattenArray(array) {
    return array.map((item) => {
      const result = {};
      for (const key in item) {
        if (Array.isArray(item[key])) {
          result[key] = item[key].flat();
        }
      }
      return result;
    });
  }

  // function reorderAttributes(a, b) {
  //     const aObj = a[0];

  //     return b.map(item => {
  //         const apiID = item.apiID;
  //         if (aObj[apiID]) {
  //             const order = aObj[apiID].map(attr => attr.name);
  //             const newAttributes = {};

  //             order.forEach(key => {
  //                 if (item.attributes[key]) {
  //                     newAttributes[key] = item.attributes[key];
  //                 }
  //             });

  //             for (const key in item.attributes) {
  //                 if (!newAttributes[key]) {
  //                     newAttributes[key] = item.attributes[key];
  //                 }
  //             }

  //             item.attributes = newAttributes;
  //         }

  //         return item;
  //     });
  // }

  function reorderAttributes(a, b) {
    const temp = b.map((item) => {
      for (let i = 0; i < a.length; i++) {
        const aObj = a[i];

        const apiID = item.schema.singularName;

        if (aObj[apiID]) {
          const order = aObj[apiID].map((attr) => attr.name);
          const newAttributes = {};

          order.forEach((key) => {
            if (item.schema.attributes[key] && key) {
              newAttributes[key] = item.schema.attributes[key];
            }
          });

          // for (const key in item.schema.attributes) {
          //     if (!newAttributes[key]) {
          //         newAttributes[key] = item.schema.attributes[key];
          //     }
          // }

          item.schema.attributes = newAttributes;
        }
      }
      return item;
    });

    return temp;
  }

  useEffect(() => {
    if (addOnServiceData) {
      const firstData =
        state !== null && enityName !== null
          ? typeof enityName.entityShortName === "string" &&
            typeof state.strapiDisplayName === "string"
            ? state.strapiDisplayName.toLowerCase() +
              "-" +
              enityName.entityShortName.toLowerCase() +
              "-" +
              addOnServiceData
            : ""
          : "";
          console.log(firstData,"firstData")

      const getTargetModels = (attributes) => {
        return Object.keys(attributes).flatMap((key) => {
          if (
            attributes[key].type === "component" &&
            attributes[key].component
          ) {
            const targetModel = attributes[key].component;

            return targetModel;
          }
          return [];
        });
      };
      console.log(getTargetModels,"getTargetModels")


      const fetchConfigurations = async (models) => {
        const fetchPromises = models.map(async (model) => {
          try {
            const res = await CreditCardServices.getConfiguration(
              `api::${model.uid}`
            );
            const response = res.data;
            const tempdata = model.uid;

            if (response.data !== undefined) {
              return {
                [tempdata]:
                  response.data.contentType.layouts.edit !== undefined
                    ? response.data.contentType.layouts.edit
                    : {},
              };
            }
          } catch (error) {
            console.log(
              `Error fetching configuration for ${model.uid}:`,
              error
            );
          }
        });

        const results = await Promise.all(fetchPromises);
        console.log(results,"result")
        return results.filter((val) => val !== undefined);
      };

      const processModels = async (models, processedModels = new Set()) => {
        let allModelsData = [...models];

        console.log(processModels,"processModels")

        const targetModels = models.flatMap((model) =>
          getTargetModels(model.schema.attributes)
        );
        console.log(targetModels,"targetModels")


        const uniqueTargetModels = [...new Set(targetModels)];

        if (uniqueTargetModels.length === 0) return allModelsData;

        const targetModelsData = allComponents.filter(
          (val) =>
            uniqueTargetModels.includes(val.uid) &&
            !processedModels.has(val.uid)
        );

        allModelsData = [...allModelsData, ...targetModelsData];
        targetModelsData.forEach((val) => processedModels.add(val.uid));

        const configurations = await fetchConfigurations(targetModelsData);

        const flatArr = flattenArray(configurations);

        // Replace attributes with nested relations
        const updatedModels = await replaceNestedRelations(
          flatArr,
          allModelsData
        );

        return updatedModels;
      };

      const processAttributes = async (attributes, processedModels) => {
        const result = {};
        for (let key in attributes) {
          if (attributes[key].type === "component") {
            // const targetModel = attributes[key].target.split("::")[1].split(".")[0];
            const targetModel = attributes[key].component;

            const relatedModel = allComponents.find(
              (model) => model.uid === targetModel
            );

            if (relatedModel && !processedModels.has(relatedModel.uid)) {
              processedModels.add(relatedModel.uid);
              result[key] = await processModel(relatedModel, processedModels);
            } else {
              result[key] = {};
            }
          } else {
            result[key] = attributes[key];
          }
        }
        return result;
      };

      const processModel = async (model, processedModels) => {
        const newModel = { ...model };

        newModel.schema.attributes = await processAttributes(
          newModel.schema.attributes,
          processedModels
        );
        return newModel;
      };

      const replaceNestedRelations = async (flatArr, allModelsData) => {
        const result = [];
        for (const model of allModelsData) {
          const newModel = await processModel(model, new Set());

          result.push(newModel);
        }
        return result;
      };

      if (firstData !== "") {
        if (enityName !== "" && state !== null) {
          setLoading1(false);
        }

        const initialModels = allInitData.filter(
          (val) => val.apiID === firstData
        );

        const tempInitial = rawModel.filter((val) => val.apiID === firstData);

        if (tempInitial.length > 0) {
          setGroupInitialData(tempInitial[0].schema.attributes);
        }

        console.log(initialModels.length,"model length")

        console.log(mainModel,"model")
        if (initialModels.length > 0) {
          setMainModel(initialModels[0]);
          processModels(initialModels)
            .then((finalModels) => {
              // const cleanedData = finalModels.reduce((acc, model) => {
              //     acc[model.apiID] = model;
              //     return acc;
              // }, {});
              const temp = finalModels.filter((val) => val.apiID === firstData);
              console.log(temp,"finalModalFIlter")
              if (temp.length > 0) {
                setLoading1(false);
              }
              setInputFormData(temp);
            })
            .catch((err) => {});
        }
      }
    }
  }, [enityName, state, addOnServiceData]);

  // useEffect(() => {
  //     const firstData =
  //         typeof enityName === "string" && typeof state === "string"
  //             ? state.toLowerCase() + "-" + enityName.toLowerCase()
  //             : "";

  //     const getTargetModels = (attributes) => {
  //         return Object.keys(attributes).flatMap((key) => {
  //             if (attributes[key].type === "relation" && attributes[key].target) {
  //                 const targetModel = attributes[key].target.split("::")[1].split(".")[0];
  //                 return targetModel;
  //             }
  //             return [];
  //         });
  //     };

  //     const fetchConfigurations = async (models) => {
  //         const fetchPromises = models.map(async (model) => {
  //             try {
  //                 const res = await CreditCardServices.getConfiguration(
  //                     `api::${model.apiID}.${model.apiID}`
  //                 );
  //                 const response = res.data;
  //                 const tempdata = model.apiID;

  //                 if (response.data !== undefined) {
  //                     return {
  //                         [tempdata]:
  //                             response.data.contentType.layouts.edit !== undefined
  //                                 ? response.data.contentType.layouts.edit
  //                                 : {},
  //                     };
  //                 }
  //             } catch (error) {
  //
  //             }
  //         });

  //         const results = await Promise.all(fetchPromises);
  //         return results.filter((val) => val !== undefined);
  //     };

  //     const processModels = async (models, processedModels = new Set()) => {
  //         let allModelsData = [...models];
  //         console.log(allModelsData)
  //         const targetModels = models.flatMap((model) => getTargetModels(model.schema.attributes));
  //         console.log(targetModels)

  //         const tempFieldArr = allInitData.filter((val) => val.apiID === firstData);

  //         // const temps = Object.keys(tempAtributes).map((key, i) => {
  //         //     if (tempAtributes[key].target !== undefined) {
  //         //         const temp = tempAtributes[key].target.split("::");
  //         //         if (temp.length > 0) {
  //         //             const temps = temp[1].split(".");
  //         //             if (temps.length > 0) {
  //         //                 return temps[0];
  //         //             }
  //         //         }
  //         //     }

  //         // });

  //         // const tempsFormData = temps.filter((val) => val !== undefined);
  //         const uniqueTargetModels = [...new Set(targetModels)];

  //         if (uniqueTargetModels.length === 0) return allModelsData;

  //         const targetModelsData = allInitData.filter((val) =>
  //             uniqueTargetModels.includes(val.apiID) && !processedModels.has(val.apiID)
  //         );

  //         allModelsData = [...allModelsData, ...targetModelsData];
  //         targetModelsData.forEach(val => processedModels.add(val.apiID));

  //         const configurations = await fetchConfigurations(targetModelsData);
  //         const flatArr = flattenArray(configurations);

  //         // Reorder attributes and clean up
  //         const updatedB = reorderAttributes(flatArr, allModelsData);
  //         const finalAttributes = updatedB.map((val) => {
  //             return val.schema.attributes;
  //         });

  //         const finalData = finalAttributes.map((attributes) => {
  //             delete attributes.id;
  //             return attributes;
  //         });

  //         console.log(targetModelsData)
  //         console.log(processedModels)

  //         // Recursive call for nested relations

  //         return processModels(targetModelsData, processedModels);
  //     };

  //     if (firstData !== "") {
  //
  //         const initialModels = allInitData.filter((val) => val.apiID === firstData);

  //         if (initialModels.length > 0) {
  //             processModels(initialModels)
  //                 .then((finalModels) => {
  //

  //                     // Collect all attributes including nested ones
  //                     const finalAttributes = finalModels.map((model) => model.schema.attributes);
  //                     const cleanedData = finalAttributes.map((attributes) => {
  //                         delete attributes.id;
  //                         return attributes;
  //                     });

  //
  //                     // setAllModels(finalModels);
  //                     const tempFieldArr = allInitData.filter((val) => val.apiID === firstData);
  //
  //                     if (tempFieldArr.length > 0) {
  //                         const tempAtributes = tempFieldArr[0].schema.attributes;
  //

  //                         const temps = Object.keys(tempAtributes).map((key, i) => {
  //                             if (tempAtributes[key].target !== undefined) {
  //                                 const temp = tempAtributes[key].target.split("::");
  //                                 if (temp.length > 0) {
  //                                     const temps = temp[1].split(".");
  //                                     if (temps.length > 0) {
  //                                         return temps[0];
  //                                     }
  //                                 }
  //                             }

  //                             // return tempAtributes[key].model
  //                         });

  //

  //                         const tempsFormData = temps.filter((val) => val !== undefined);
  //

  //                         const tempsFormDataType = allInitData.filter((val) => {
  //                             return tempsFormData.some((ele) => val.apiID === ele);
  //                         });

  //                         setAllModels(tempsFormDataType);

  //                         const tempd = tempsFormDataType.map(async (val) => {
  //                             try {
  //                                 const res = await CreditCardServices.getConfiguration(
  //                                     `api::${val.apiID}.${val.apiID}`
  //                                 );

  //                                 const response = (res.data);
  //                                 const tempdata = val.apiID;
  //
  //

  //                                 if (response.data !== undefined) {
  //                                     return {
  //                                         [tempdata]:
  //                                             response.data.contentType.layouts.edit !== undefined
  //                                                 ? response.data.contentType.layouts.edit
  //                                                 : {},
  //                                     };
  //                                 }
  //                             } catch (error) {
  //                                 console.log(
  //                                     `Error fetching configuration for ${val.apiID}:`,
  //                                     error
  //                                 );
  //                                 // return { [val.apiID]: {} };
  //                             }
  //                         });

  //

  //                         Promise.all(tempd)
  //                             .then((results) => {
  //
  //                                 const tempres = results.filter((val) => val !== undefined);

  //                                 const flatArr = flattenArray(tempres);
  //
  //

  //                                 const updatedB = reorderAttributes(flatArr, tempsFormDataType);
  //
  //                                 const tempsFormDataTypeAtribute = updatedB.map((val) => {
  //                                     return val.schema.attributes;
  //                                 });

  //

  //                                 const finalData = updatedB.map((val) => {
  //                                     delete val.schema.attributes.id;
  //                                     return val;
  //                                 });

  //
  //                                 console.log(cleanedData)
  //                                 const finalTempData = cleanedData.concat(finalData)
  //                                 console.log(finalTempData)
  //                                 setInputFormData(finalData);
  //
  //                             })
  //                             .catch((err) => {
  //
  //                             });
  //                     }

  //                 })
  //                 .catch((err) => {
  //
  //                 });
  //         }
  //     }
  // }, [enityName, state]);

  useEffect(() => {
    const firstData =
      typeof enityName === "string" && typeof state === "string"
        ? state.toLowerCase() + "-" + enityName.toLowerCase()
        : "";
    if (firstData !== "") {
      setModelName(firstData);
    }
  }, [enityName, state]);

  const fetchOptions = async (url) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return [];
    }
  };

  const checkAvailability = async (value) => {
    setLoading(true);
    setError("");
    try {
      // const response = await axios.get(`https://api.example.com/check-availability?value=${value}`);
      // setIsAvailable(response.data.isAvailable);
    } catch (err) {
      setError("Error checking availability");
    }
    setLoading(false);
  };
  // Current tab is set to be the first tab (0)
  useEffect(() => {
    showTab(currentTab);
  }, [currentTab]);

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="error-msg font-fam-for-all ">
        {errors[name].message}
      </small>
    ) : (
      <small className="error-msg "></small>
    );
  };

  const calculatePrice = (serviceData, quantity) => {
    if (serviceData.product.isBundlePriceApplicable) {
      const { noOfItemsInBundle, priceOfBundle, priceForAddlItemsOverBundle } =
        serviceData.product;

      if (quantity <= noOfItemsInBundle) {
        return priceOfBundle;
      } else {
        const bundledPrice = priceOfBundle;
        const additionalItemsPrice =
          priceForAddlItemsOverBundle * (quantity - noOfItemsInBundle);
        return bundledPrice + additionalItemsPrice;
      }
    } else {
      // return parseFloat(serviceData.product.unitPrice).toFixed(2);
      // console.log( parseFloat(serviceData.product.unitPrice).toFixed(2),"serviceData" )
      // return Number(parseFloat(serviceData.product.unitPrice).toFixed(2));
      // let data = Number(serviceData.product.unitPrice)
      // return parseFloat(parseFloat(serviceData.product.unitPrice).toFixed(2));
      return serviceData.product.unitPrice;
    }
  };

  const calculateTotalAmount = () => {
    return selectedCheckBoxData.reduce((total, val) => {
      const quantity = 4;
      const itemTotal = calculatePrice(val, quantity);
      return parseFloat(Number(total) + Number(itemTotal)).toFixed(2);
    }, 0);
  };

  function convertName(originalString) {
    if (!originalString.includes("_")) {
      return originalString;
    }

    const parts = originalString.split("_");

    const relevantParts = parts.slice(1);

    // Capitalize the first letter of each part and join them with a space
    const formattedString = relevantParts
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(" ");

    return formattedString;
  }

  const renderFormField = (column, key, val, value, modelname) => {
    const errorMessageTag = document.getElementById(`${key}--error`);

    const tempval = convertName(key);
    const options = optionsMap?.[tempval] || [];

    // if (options.length > 0) {
    //     console.log(options)
    //     const matchedItem = tempdata.find(item => item.item === tempval);

    //     if (matchedItem) {
    //         if (matchedItem.label==="stateFullDesc") {
    //             if (state) {
    //                 setValue(val,state.stateFullDesc)
    //             }
    //         }
    //         if (matchedItem.label==="countyName") {
    //             if (county) {
    //                 setValue(val,county.countyName)
    //             }
    //         }
    //         if (matchedItem.label==="countryShortName") {
    //             if (state) {
    //                 setValue(val,state.countryMaster.countryShortName)
    //             }
    //         }
    //     }
    // }

    switch (column.type) {
      case "string":
        switch (column.default) {
          case "drop-down":
            if (options.length > 0) {
              const matchedItem = tempdata.find(
                (item) => item.item === tempval
              );

              return (
                <div className="input-fields-container">
                  <div className="input-fields-main">
                    <div className="justify-content-center dialog-form-field-space w-full">
                      <label htmlFor="" className="form-label font-fam-for-all">
                        {convertName(key)}
                        {column.required !== undefined &&
                        column.required === true ? (
                          <span className="form-field-mandatory">*</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <span className="">
                        <Controller
                          name={val}
                          control={control}
                          rules={
                            column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                              ? {
                                  required: `${convertName(key)} Required.`,
                                  pattern: {
                                    value: new RegExp(column.regex),
                                    message: `Please Enter Valid ${convertName(
                                      key
                                    )}`,
                                  },
                                }
                              : column.required !== undefined &&
                                column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                          }
                          render={({ field }) => {
                            const defaultValue =
                              matchedItem !== undefined
                                ? matchedItem.label === "stateFullDesc" && state
                                  ? state.stateFullDesc
                                  : matchedItem.label === "countyName" && county
                                  ? county.countyName
                                  : matchedItem.label === "countryShortName" &&
                                    state
                                  ? state.countryMaster.countryShortName
                                  : ""
                                : field.value;

                            return (
                              <Dropdown
                                id={field.name}
                                filter
                                name={val}
                                // value={field.value || matchedItem !== undefined ? matchedItem.label === "stateFullDesc" ? state ? state.stateFullDesc : "" : matchedItem.label === "countyName" ? county ? county.countyName : "" : matchedItem.label === "countryShortName" ? state ? state.countryMaster.countryShortName : "" : "" : ""}

                                value={field.value}
                                resetFilterOnHide
                                options={options}
                                optionValue={
                                  matchedItem !== undefined
                                    ? matchedItem.optionValue
                                    : "name"
                                }
                                disabled={
                                  modelname === "Principle_Address" ||
                                  modelname === "Organizer_Information" ||
                                  modelname === "Incorporator_Information" ||
                                  modelname === "Registered_Agent" ||
                                  (matchedItem !== undefined
                                    ? matchedItem.label === "stateFullDesc"
                                      ? true
                                      : false
                                    : false)
                                }
                                optionLabel={
                                  matchedItem !== undefined
                                    ? matchedItem.label
                                    : "name"
                                } // Dynamically set label for the dropdown
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  errorMessageTag &&
                                    (errorMessageTag.textContent = "");
                                }}
                                maxSelectedLabels={3}
                                style={{ flexGrow: "1" }}
                                className="dialog-form-input-field-wizard"
                              />
                            );
                          }}
                        />
                      </span>
                      <small
                        id={`${key}--error`}
                        className="error-msg font-fam-for-all"
                      ></small>
                      {getFormErrorMessage(key)}
                    </div>
                  </div>
                </div>
              );
            }
          case "currency":
            return (
              <>
                <div className="input-fields-container">
                  <div className="input-fields-main">
                    <div className="justify-content-center dialog-form-field-space w-full">
                      <label htmlFor="" className="form-label font-fam-for-all">
                        {convertName(key)}
                        {column.required !== undefined &&
                        column.required === true ? (
                          <span className="form-field-mandatory">*</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <span className="">
                        <Controller
                          name={val}
                          control={control}
                          rules={
                            column.required !== undefined &&
                            column.required === true
                              ? {
                                  required: `${convertName(key)} Required.`,
                                }
                              : {}
                          }
                          render={({ field, fieldState }) => (
                            <input
                              type="text"
                              id={field.name}
                              {...field}
                              disabled={
                                modelname === "Principal_Address" ||
                                modelname === "Organizer_Information" ||
                                modelname === "Incorporator_Information" ||
                                (IsCheckedAgent &&
                                  modelname === "Registered_Agent") ||
                                modelname === "Director_Information" ||
                                modelname === "Officer_Information" ||
                                modelname === "Filer_Information" ||
                                modelname === "ucc_Debtor_Details" ||
                                modelname === "ucc_Secured_Party_Details" ||
                                modelname === "Beneficial_Owner_Information" ||
                                modelname === "Company_Applicant_Information"
                              }
                              onInput={(e) => {
                                let value = e.target.value.replace(/,/g, ""); // Remove existing commas
                                if (value.length > 10) {
                                  value = value.slice(0, 10); // Allow only 10 digits
                                }
                                const formattedValue = new Intl.NumberFormat(
                                  "en-US"
                                ).format(value); // Add commas

                                e.target.value = formattedValue;
                                field.onChange(value.toString()); // Save raw value as a string
                                clearErrors(key);
                                errorMessageTag &&
                                  (errorMessageTag.textContent = "");
                              }}
                              style={{
                                flexGrow: "1",
                                paddingLeft: "10px",
                                paddingBottom: "5px",
                                borderRadius: "6px",
                              }}
                              className="dialog-form-input-field-wizard p-inputtext"
                            />
                          )}
                        />
                      </span>
                      <small
                        id={`${val}--error`}
                        className="error-msg font-fam-for-all"
                      ></small>
                      {getFormErrorMessage(key)}
                    </div>
                  </div>
                </div>
              </>
            );
          case "zipcode": {
            return (
              <>
                <div className="input-fields-container">
                  <div className="input-fields-main">
                    <div className="justify-content-center dialog-form-field-space w-full">
                      <label htmlFor="" className="form-label font-fam-for-all">
                        {convertName(key)}{" "}
                        {column.required ? (
                          <span className="form-field-mandatory">*</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <span>
                        <Controller
                          name={val}
                          control={control}
                          rules={
                            column.required
                              ? {
                                  required: `${convertName(key)} Required.`,
                                  validate: (value) =>
                                    value.length === 5 ||
                                    "Please enter a valid 5-digit zipcode.",
                                }
                              : {}
                          }
                          render={({ field, fieldState }) => (
                            <input
                              type="text"
                              id={field.name}
                              {...field}
                              maxLength={5}
                              disabled={
                                val === "Reg_Name" ||
                                val === "Reg_Email_Address" ||
                                val === "Reg_Contact_No" ||
                                val === "Reg_Address_Line_1" ||
                                val === "Reg_Address_Line_2" ||
                                val === "Reg_City" ||
                                val === "Reg_State" ||
                                val === "Reg_Zip_Code" ||
                                modelname === "Principal_Address" ||
                                modelname === "Organizer_Information" ||
                                modelname === "Incorporator_Information" ||
                                (IsCheckedAgent &&
                                  modelname === "Registered_Agent") ||
                                modelname === "Director_Information" ||
                                modelname === "Officer_Information" ||
                                modelname === "Filer_Information" ||
                                modelname === "Mailing_Information" ||
                                modelname === "Billing_Information" ||
                                modelname === "ucc_Debtor_Details" ||
                                modelname === "ucc_Secured_Party_Details" ||
                                modelname === "Beneficial_Owner_Information" ||
                                modelname === "Company_Applicant_Information"
                              }
                              onChange={(e) => {
                                const inputValue = e.target.value.replace(
                                  /\D/g,
                                  ""
                                ); // Remove all non-digit characters
                                field.onChange(inputValue);
                                clearErrors(key);
                                if (errorMessageTag)
                                  errorMessageTag.textContent = "";
                              }}
                              style={{
                                flexGrow: "1",
                                paddingLeft: "10px",
                                paddingBottom: "5px",
                                borderRadius: "6px",
                              }}
                              className="dialog-form-input-field-wizard p-inputtext"
                            />
                          )}
                        />
                      </span>
                      <small
                        id={`${val}--error`}
                        className="error-msg font-fam-for-all"
                      ></small>
                      {getFormErrorMessage(key)}
                    </div>
                  </div>
                </div>
              </>
            );
          }
          case "date":
            return (
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className="justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}
                      {column.required ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <span className="">
                      <Controller
                        name={val}
                        control={control}
                        defaultValue={dayjs().format("YYYYMMDD")}
                        rules={
                          column.required
                            ? { required: `${convertName(key)} Required.` }
                            : {}
                        }
                        render={({ field }) => (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              className="strapi-date-picker"
                              style={{ width: "480px" }}
                              value={field.value ? dayjs(field.value) : null}
                              defaultValue={dayjs().format("YYYYMMDD")}
                              disabled={
                                val === "CF_Year_of_formation" ||
                                modelname === "Principal_Address" ||
                                modelname === "Organizer_Information" ||
                                modelname === "Incorporator_Information" ||
                                (IsCheckedAgent &&
                                  modelname === "Registered_Agent") ||
                                modelname === "Director_Information" ||
                                modelname === "Officer_Information" ||
                                modelname === "Filer_Information" ||
                                modelname === "Mailing_Information" ||
                                modelname === "Billing_Information" ||
                                modelname === "ucc_Debtor_Details" ||
                                modelname === "ucc_Secured_Party_Details" ||
                                modelname === "Beneficial_Owner_Information" ||
                                modelname === "Company_Applicant_Information"
                              }
                              onChange={(newValue) => {
                                if (newValue && newValue.isValid()) {
                                  const formattedDate =
                                    newValue.format("YYYYMMDD");
                                  field.onChange(formattedDate);
                                } else {
                                  field.onChange(null);
                                }
                                errorMessageTag &&
                                  (errorMessageTag.textContent = "");
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              disableFuture
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </span>
                    <small
                      id={`${val}--error`}
                      className="error-msg font-fam-for-all"
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            );
          default:
            return (
              <>
                <div className="input-fields-container">
                  <div className="input-fields-main">
                    <div className=" justify-content-center dialog-form-field-space w-full">
                      <label htmlFor="" className="form-label font-fam-for-all">
                        {convertName(key)}{" "}
                        {column.required !== undefined &&
                        column.required === true ? (
                          <span className="form-field-mandatory">*</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <span className=" ">
                        <Controller
                          name={val}
                          control={control}
                          rules={
                            column.required !== undefined &&
                            column.required === true &&
                            column.regex !== undefined
                              ? {
                                  required: `${convertName(key)} Required.`,
                                  pattern: {
                                    value: new RegExp(column.regex),
                                    message: `Please Enter Valid ${convertName(
                                      key
                                    )}`,
                                  },
                                }
                              : column.required !== undefined &&
                                column.required === true
                              ? { required: `${convertName(key)} Required.` }
                              : {}
                          }
                          render={({ field, fieldState }) => (
                            <InputText
                              id={field.name}
                              {...field}
                              autoComplete={false}
                              onChange={(e) => {
                                field.onChange(e);
                                clearErrors(key);
                                errorMessageTag &&
                                  (errorMessageTag.textContent = "");
                              }}
                              disabled={
                                modelname === "Principle_Address" ||
                                modelname === "Organizer_Information" ||
                                modelname === "Incorporator_Information" ||
                                modelname === "Registered_Agent" ||
                                (val === "X_Name_of_Entity" &&
                                  orderTypeId !== 5)
                              }
                              style={{
                                flexGrow: "1",
                                paddingLeft: "10px",
                                paddingBottom: "5px",
                              }}
                              className="dialog-form-input-field-wizard"
                              maxLength={50}
                            />
                          )}
                        />
                      </span>
                      <small
                        id={`${key}--error`}
                        className="error-msg font-fam-for-all "
                      ></small>
                      {getFormErrorMessage(key)}
                    </div>
                  </div>
                </div>
              </>
            );
        }
      case "text":
        return (
          <>
            <div className="input-fields-container">
              <div className="input-fields-main">
                <div className=" justify-content-center dialog-form-field-space w-full">
                  <label htmlFor="" className="form-label font-fam-for-all">
                    {convertName(key)}{" "}
                    {column.required !== undefined &&
                    column.required === true ? (
                      <span className="form-field-mandatory">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <span className=" ">
                    <Controller
                      name={val}
                      control={control}
                      rules={
                        column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                          ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                          : column.required !== undefined &&
                            column.required === true
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                      }
                      render={({ field, fieldState }) => (
                        <InputTextarea
                          id={field.name}
                          {...field}
                          autoComplete={false}
                          rows={4}
                          cols={100}
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors(key);
                            errorMessageTag &&
                              (errorMessageTag.textContent = "");
                          }}
                          disabled={
                            modelname === "Principle_Address" ||
                            modelname === "Organizer_Information" ||
                            modelname === "Incorporator_Information" ||
                            modelname === "Registered_Agent"
                          }
                          style={{
                            flexGrow: "1",
                            paddingLeft: "10px",
                            paddingBottom: "5px",
                          }}
                          className="dialog-form-input-area-field-wizard"
                          maxLength={50}
                        />
                      )}
                    />
                  </span>
                  <small
                    id={`${key}--error`}
                    className="error-msg font-fam-for-all "
                  ></small>
                  {getFormErrorMessage(key)}
                </div>
              </div>
            </div>
          </>
        );
      // case "biginteger":
      //     return<>
      //     <input type="number" />
      //     </>
      case "biginteger":
        return (
          <>
            <div className="input-fields-container">
              <div className="input-fields-main">
                <div className="justify-content-center dialog-form-field-space w-full">
                  <label htmlFor="" className="form-label font-fam-for-all">
                    {convertName(key)}
                    {column.required !== undefined &&
                    column.required === true ? (
                      <span className="form-field-mandatory">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <span className=" ">
                    <Controller
                      name={val}
                      control={control}
                      rules={
                        column.required !== undefined &&
                        column.required === true
                          ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: /^\d{1,10}$/, // Allow only up to 5 digits
                                message:
                                  "Please Enter Valid No Of Positions (1-5 digits)",
                              },
                            }
                          : {}
                      }
                      render={({ field, fieldState }) => (
                        <input
                          type="number"
                          id={field.name}
                          {...field}
                          disabled={
                            modelname === "Principal_Address" ||
                            modelname === "Organizer_Information" ||
                            modelname === "Incorporator_Information" ||
                            (IsCheckedAgent &&
                              (modelname === "Registered_Agent" ||
                                modelname === "Director_Information" ||
                                modelname === "Officer_Information" ||
                                modelname === "Filer_Information"))
                          }
                          onInput={(e) => {
                            if (e.target.value.length > 10) {
                              e.target.value = e.target.value.slice(0, 10); // Allow only 5 digits
                            }
                            field.onChange(e);
                            clearErrors(key);
                            errorMessageTag &&
                              (errorMessageTag.textContent = "");
                          }}
                          style={{
                            flexGrow: "1",
                            paddingLeft: "10px",
                            paddingBottom: "5px",
                            borderRadius: "6px",
                          }}
                          className="dialog-form-input-field-wizard p-inputtext"
                        />
                      )}
                    />
                  </span>
                  <small
                    id={`${val}--error`}
                    className="error-msg font-fam-for-all "
                  ></small>
                  {getFormErrorMessage(key)}
                </div>
              </div>
            </div>
          </>
        );

      case "decimal":
        return (
          <>
            <div className="input-fields-container">
              <div className="input-fields-main">
                <div className=" justify-content-center dialog-form-field-space w-full">
                  <label htmlFor="" className="form-label font-fam-for-all">
                    {convertName(key)}{" "}
                    {column.required !== undefined &&
                    column.required === true ? (
                      <span className="form-field-mandatory">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <span className=" ">
                    <Controller
                      name={val}
                      control={control}
                      rules={
                        column.required !== undefined &&
                        column.required === true
                          ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: /^\d+$/,
                                message: "Please Enter Valid No Of Positions",
                              },
                            }
                          : {}
                      }
                      render={({ field, fieldState }) => (
                        <input
                          type="float"
                          id={field.name}
                          {...field}
                          disabled={
                            modelname === "Principle_Address" ||
                            modelname === "Organizer_Information" ||
                            modelname === "Incorporator_Information" ||
                            modelname === "Registered_Agent"
                          }
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors(key);
                            errorMessageTag &&
                              (errorMessageTag.textContent = "");
                          }}
                          style={{
                            flexGrow: "1",
                            paddingLeft: "10px",
                            paddingBottom: "5px",
                            borderRadius: "6px",
                          }}
                          className="dialog-form-input-field-wizard p-inputtext"
                        />
                      )}
                    />
                  </span>
                  <small
                    id={`${key}--error`}
                    className="error-msg font-fam-for-all "
                  ></small>
                  {getFormErrorMessage(key)}
                </div>
              </div>
            </div>
          </>
        );

      case "integer":
        return (
          <>
            <div className="input-fields-container">
              <div className="input-fields-main">
                <div className=" justify-content-center dialog-form-field-space w-full ">
                  <label htmlFor="" className="form-label font-fam-for-all">
                    {convertName(key)}{" "}
                    {column.required !== undefined &&
                    column.required === true ? (
                      <span className="form-field-mandatory">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <span className=" ">
                    <Controller
                      name={val}
                      control={control}
                      rules={
                        column.required !== undefined &&
                        column.required === true
                          ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: /^\d+$/,
                                message: "Please Enter Valid No Of Positions",
                              },
                            }
                          : {}
                      }
                      render={({ field, fieldState }) => (
                        <input
                          type="number"
                          id={field.name}
                          {...field}
                          disabled={
                            modelname === "Principle_Address" ||
                            modelname === "Organizer_Information" ||
                            modelname === "Incorporator_Information" ||
                            modelname === "Registered_Agent"
                          }
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors(key);
                            errorMessageTag &&
                              (errorMessageTag.textContent = "");
                          }}
                          style={{
                            flexGrow: "1",
                            paddingLeft: "10px",
                            paddingBottom: "5px",
                            borderRadius: "6px",
                          }}
                          className="dialog-form-input-field-wizard p-inputtext"
                        />
                      )}
                    />
                  </span>
                  <small
                    id={`${key}--error`}
                    className="error-msg font-fam-for-all "
                  ></small>
                  {getFormErrorMessage(key)}
                </div>
              </div>
            </div>
          </>
        );

      case "email":
        return (
          <>
            <div className="input-fields-container">
              <div className="input-fields-main">
                <div className=" justify-content-center dialog-form-field-space w-full">
                  <label htmlFor="" className="form-label font-fam-for-all">
                    {convertName(key)}{" "}
                    {column.required !== undefined &&
                    column.required === true ? (
                      <span className="form-field-mandatory">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <span className=" ">
                    <Controller
                      name={val}
                      control={control}
                      rules={
                        column.required !== undefined &&
                        column.required === true
                          ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Please Enter Valid Email",
                              },
                            }
                          : {}
                      }
                      render={({ field, fieldState }) => (
                        <input
                          type="email"
                          id={field.name}
                          {...field}
                          disabled={
                            modelname === "Principle_Address" ||
                            modelname === "Organizer_Information" ||
                            modelname === "Incorporator_Information" ||
                            modelname === "Registered_Agent"
                          }
                          onChange={(e) => {
                            field.onChange(e);
                            clearErrors(key);
                            errorMessageTag &&
                              (errorMessageTag.textContent = "");
                          }}
                          style={{
                            flexGrow: "1",
                            paddingLeft: "10px",
                            paddingBottom: "5px",
                            borderRadius: "6px",
                          }}
                          className="dialog-form-input-field-wizard p-inputtext"
                        />
                      )}
                    />
                  </span>
                  <small
                    id={`${key}--error`}
                    className="error-msg font-fam-for-all "
                  ></small>
                  {getFormErrorMessage(key)}
                </div>
              </div>
            </div>
          </>
        );
      case "password":
        return (
          <>
            <div className="input-fields-container">
              <div className="input-fields-main">
                <div className=" justify-content-center dialog-form-field-space w-full">
                  <label htmlFor="" className="form-label font-fam-for-all">
                    {convertName(key)}{" "}
                    {column.required !== undefined &&
                    column.required === true ? (
                      <span className="form-field-mandatory">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <span className=" ">
                    <Controller
                      control={control}
                      name={val}
                      rules={
                        column.required !== undefined &&
                        column.required === true
                          ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value:
                                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                message:
                                  "Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.",
                              },
                            }
                          : {}
                      }
                      render={({ field, fieldState }) => (
                        <Password
                          id={field.name}
                          {...field}
                          toggleMask
                          inputRef={field.ref}
                          autoComplete={false}
                          className="dialog-form-input-field-wizard"
                        />
                      )}
                    />
                  </span>
                  <small
                    id={`${key}--error`}
                    className="error-msg font-fam-for-all "
                  ></small>
                  {getFormErrorMessage(key)}
                </div>
              </div>
            </div>
          </>
        );
      // case "date":
      //     return <>
      //         <div className=" justify-content-center dialog-form-field-space ml-1">
      //             <div className="field">
      //                 <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label><span className=" ">
      //                     <Controller name={key}
      //                         control={control}
      //                         rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertName(key)} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.` } : {}}
      //                         render={({ field, fieldState }) => (
      //                             <LocalizationProvider dateAdapter={AdapterDayjs} >

      //                                 <DatePicker
      //                                     id={field.name} {...field}
      //                                     // value={values}
      //                                     // onChange={handleOpenDate}
      //                                     // required

      //                                     slotProps={{
      //                                         textField: {
      //                                             size: 'small',
      //                                             error: false,
      //                                             fullWidth: true
      //                                         },
      //                                     }}

      //                                     className='dialog-form-dropdown-field'

      //                                 />

      //                             </LocalizationProvider>
      //                         )} />

      //                 </span>
      //                 {/* {getFormErrorMessage('opendate')} */}
      //                 <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
      //                 {getFormErrorMessage(key)}
      //             </div>
      //         </div>
      //     </>
      case "enumeration":
        if (options.length > 0) {
          const matchedItem = tempdata.find((item) => item.item === tempval);

          return (
            <div className="input-fields-container">
              <div className="input-fields-main">
                <div className="justify-content-center dialog-form-field-space w-full">
                  <label htmlFor="" className="form-label font-fam-for-all">
                    {convertName(key)}
                    {column.required !== undefined &&
                    column.required === true ? (
                      <span className="form-field-mandatory">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <span className="">
                    <Controller
                      name={val}
                      control={control}
                      rules={
                        column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                          ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                          : column.required !== undefined &&
                            column.required === true
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                      }
                      render={({ field }) => (
                        <Dropdown
                          id={field.name}
                          filter
                          name={val}
                          value={field.value}
                          options={options}
                          resetFilterOnHide
                          optionValue={
                            matchedItem !== undefined
                              ? matchedItem.label
                              : "name"
                          }
                          disabled={
                            modelname === "Principle_Address" ||
                            modelname === "Organizer_Information" ||
                            modelname === "Incorporator_Information" ||
                            modelname === "Registered_Agent"
                          }
                          optionLabel={
                            matchedItem !== undefined
                              ? matchedItem.label
                              : "name"
                          } // Dynamically set label for the dropdown
                          onChange={(e) => {
                            field.onChange(e.value);
                            errorMessageTag &&
                              (errorMessageTag.textContent = "");
                          }}
                          maxSelectedLabels={3}
                          style={{ flexGrow: "1" }}
                          className="dialog-form-input-field-wizard"
                        />
                      )}
                    />
                  </span>
                  <small
                    id={`${key}--error`}
                    className="error-msg font-fam-for-all"
                  ></small>
                  {getFormErrorMessage(key)}
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <>
              <div className="input-fields-container">
                <div className="input-fields-main">
                  <div className=" justify-content-center dialog-form-field-space w-full">
                    <label htmlFor="" className="form-label font-fam-for-all">
                      {convertName(key)}{" "}
                      {column.required !== undefined &&
                      column.required === true ? (
                        <span className="form-field-mandatory">*</span>
                      ) : (
                        ""
                      )}
                    </label>

                    <span className=" ">
                      <Controller
                        name={val}
                        control={control}
                        // onChange={(e) => errorMessageTag.textContent = ''}

                        rules={
                          column.required !== undefined &&
                          column.required === true &&
                          column.regex !== undefined
                            ? {
                                required: `${convertName(key)} Required.`,
                                pattern: {
                                  value: new RegExp(column.regex),
                                  message: `Please Enter Valid ${convertName(
                                    key
                                  )}`,
                                },
                              }
                            : column.required !== undefined &&
                              column.required === true
                            ? { required: `${convertName(key)} Required.` }
                            : {}
                        }
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            resetFilterOnHide
                            filter
                            name="jd"
                            value={field.value}
                            options={
                              column.enum !== undefined ? column.enum : []
                            }
                            onChange={(e) => {
                              field.onChange(e.value);
                              errorMessageTag &&
                                (errorMessageTag.textContent = "");
                            }}
                            maxSelectedLabels={3}
                            style={{ flexGrow: "1" }}
                            className="dialog-form-input-field-wizard"
                          />
                        )}
                      />
                    </span>
                    <small
                      id={`${key}--error`}
                      className="error-msg font-fam-for-all "
                    ></small>
                    {getFormErrorMessage(key)}
                  </div>
                </div>
              </div>
            </>
          );
        }

      default:
        return null;
    }
  };

  const renderFormField1 = (column, key, val, value, optionsMap) => {
    const errorMessageTag = document.getElementById(`${key}--error`);

    const options = optionsMap?.[key] || [];

    if (options.length > 0) {
    }
    if (options.length > 0) {
      switch (column.type) {
        case "enumeration":
          const matchedItem = tempdata.find((item) => item.item === key);
          return (
            <div className="input-fields-container">
              <div className="input-fields-main">
                <div className="justify-content-center dialog-form-field-space w-full">
                  <label htmlFor="" className="form-label font-fam-for-all">
                    {convertName(key)}
                    {column.required !== undefined &&
                    column.required === true ? (
                      <span className="form-field-mandatory">*</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <span className="">
                    <Controller
                      name={val}
                      control={control}
                      rules={
                        column.required !== undefined &&
                        column.required === true &&
                        column.regex !== undefined
                          ? {
                              required: `${convertName(key)} Required.`,
                              pattern: {
                                value: new RegExp(column.regex),
                                message: `Please Enter Valid ${convertName(
                                  key
                                )}`,
                              },
                            }
                          : column.required !== undefined &&
                            column.required === true
                          ? { required: `${convertName(key)} Required.` }
                          : {}
                      }
                      render={({ field }) => (
                        <Dropdown
                          id={field.name}
                          filter
                          name={val}
                          value={field.value}
                          options={options}
                          resetFilterOnHide
                          optionValue=""
                          optionLabel={matchedItem.label || "name"} // Dynamically set label for the dropdown
                          onChange={(e) => {
                            field.onChange(e.value);
                            errorMessageTag.textContent = "";
                          }}
                          maxSelectedLabels={3}
                          style={{ flexGrow: "1" }}
                          className="dialog-form-input-field-wizard"
                        />
                      )}
                    />
                  </span>
                  <small
                    id={`${key}--error`}
                    className="error-msg font-fam-for-all"
                  ></small>
                  {getFormErrorMessage(key)}
                </div>
              </div>
            </div>
          );
        default:
          return null;
      }
    }
  };

  let currentTabs = 0;
  let currentSteps = 1;
  function showTab(n) {
    // This function will display the specified tab of the form...
    var x = document.getElementsByClassName("tab");

    if (x.length > 0) {
      if (x[n] !== undefined) {
        x[n].style.display = "block";
      }

      //... and fix the Previous/Next buttons:
      if (n == 0) {
        document.getElementById("prevBtn").style.display = "none";
      } else {
        document.getElementById("prevBtn").style.display = "flex";
      }
      if (n == x.length - 1 && inputFormData.length > 0) {
        // document.getElementById("nextBtn").innerHTML = "Submit";
        document.getElementById("nextBtn").style.display = "none";
        document.getElementById("submitBtn").style.display = "flex";
      } else {
        document.getElementById("nextBtn").innerHTML = "Save & Next";
        document.getElementById("nextBtn").style.display = "flex";
        document.getElementById("submitBtn").style.display = "none";
      }
      //... and run a function that will display the correct step indicator:
      fixStepIndicator(n);
    }
  }

  function deepCompareObjects(obj1, obj2) {
    if (typeof obj1 !== typeof obj2) return false;

    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      if (obj1.length !== obj2.length) return false;
      for (let i = 0; i < obj1.length; i++) {
        if (!deepCompareObjects(obj1[i], obj2[i])) return false;
      }
      return true;
    }

    if (typeof obj1 === "object" && obj1 !== null && obj2 !== null) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;
      for (let key of keys1) {
        if (!deepCompareObjects(obj1[key], obj2[key])) return false;
      }
      return true;
    }

    return obj1 === obj2;
  }

  const nextPrev = (n) => {
    // This function will figure out which tab to display
    setWizardScroll(!wizardScroll);
    setStateErr("");
    setEntityErr("");
    setCountyErr("");

    if (!state && !enityName) {
      setStateErr("State required");
      setEntityErr("Entity type required");
      return;
    }

    if (!state) {
      setStateErr("State required");
      return;
    }
    if (!enityName) {
      setEntityErr("Entity type required");
      return;
    }
    // if (state && state.stateFullDesc === "New York") {
    //   if (!county) {
    //     setCountyErr("County required");
    //     return; // Exit the function if county is required and not provided for New York
    //   }
    // }
    if (state && stateWithCounty.includes(state.stateFullDesc)) {
      if (!county) {
          setCountyErr("County required");
          return; 
      }
  }
    if (selectedCheckBoxData.length > 0) {
      var x = document.getElementsByClassName("tab");

      // Validate the form
      if (x.length > 0) {
        if (n === 1 && !validateForm()) return false; // Stop if form validation fails

        // Hide current tab
        if (x[currentTab] !== undefined) {
          x[currentTab].style.display = "none";
        }

        // Check if the user is moving to the next page
        if (n === 1) {
          if (currentStep < inputFormData.length + 5) {
            setCurrentStep(currentStep + 1);
          }
        }

        // Check if the user is moving to the previous page
        if (n === -1) {
          if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
          }
        }

        // Move to the new tab
        const newTab = currentTab + n;
        setCurrentTab(newTab);
        showTab(newTab);

        if (mainModel && n === 1) {
          // Key personnel and other processing
          const keyPersonalArr = [];
          if (incorporator?.id) keyPersonalArr.push(incorporator.id);
          if (officer?.id) keyPersonalArr.push(officer.id);
          if (director?.id) keyPersonalArr.push(director.id);
          if (organizerData?.id) keyPersonalArr.push(organizerData.id);
          if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
          if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);

          const keyPersonalStrapiArr = [];
          if (incorporator?.id)
            keyPersonalStrapiArr.push({
              data: incorporator,
              type: "INCORPORATOR",
            });
          if (officer?.id)
            keyPersonalStrapiArr.push({ data: officer, type: "OFFICER" });
          if (director?.id)
            keyPersonalStrapiArr.push({ data: director, type: "DIRECTOR" });
          if (organizerData?.id)
            keyPersonalStrapiArr.push({
              data: organizerData,
              type: "ORGANIZER",
            });
          if (bilingAddress?.id)
            keyPersonalStrapiArr.push({ data: bilingAddress, type: "BILLING" });
          if (shippingAddress?.id)
            keyPersonalStrapiArr.push({
              data: shippingAddress,
              type: "SHIPPING",
            });

          // Date processing
          let d = new Date();
          const futureDate = new Date(d);
          futureDate.setDate(d.getDate() + 7);

          let day = d.getDate();
          let month = d.getMonth() + 1;
          let year = d.getFullYear();

          let day1 = futureDate.getDate();
          let month1 = futureDate.getMonth();
          let year1 = futureDate.getFullYear();

          if (day < 10) day = "0" + day;
          if (month < 10) month = `0${month}`;
          let newDate = year + "-" + month + "-" + day;

          if (day1 < 10) day1 = "0" + day1;
          if (month < 10) month1 = `0${month1}`;
          let newDate1 = year1 + "-" + month1 + "-" + day1;

          const upadatedProduct = selectedCheckBoxData.map((val) => {
            return {
              productListing: val.product,
              quantity: 1,
              unitPrice: parseFloat(val.product.unitPrice).toFixed(2),
            };
          });

          const finalData1 = buildFinalData(
            mainModel.schema.attributes,
            modelData1
          );

          const jsonAutomation = {
            EntityType: enityName,
            State: state,
            County: county,
            Payload: finalData1,
            Header: addonServiceRedirectedData
              ? addonServiceRedirectedData
              : null,
            legalName:
              getValues("X_Name_of_Entity") !== undefined
                ? getValues("X_Name_of_Entity")
                : globalCompany
                ? globalCompany.companyDetails
                  ? globalCompany.companyDetails.companyName
                  : "Order"
                : "Order",
            addOnService: addOnServiceData,
            billingAddress: bilingAddress,
            shipingAddress: shippingAddress,
            keyPersonalStrapiArr: keyPersonalStrapiArr,
            step: currentStep,
            totalStep: totalStep,
          };

          const orderData = {
            contactId: contactId,
            companyId: adminCompany
              ? adminCompany.id
              : globalCompany
              ? globalCompany.companyDetails
                ? globalCompany.companyDetails.id
                : null
              : null,
            orderTitle:
              getValues("X_Name_of_Entity") !== undefined
                ? getValues("X_Name_of_Entity")
                : globalCompany
                ? globalCompany.companyDetails
                  ? globalCompany.companyDetails.companyName
                  : "Order"
                : "Order",
            orderId: null,
            billingAddress: bilingAddress
              ? bilingAddress.addressMasterId.id
              : null,
            statusId: 7,
            orderAmount: parseFloat(calculateTotalAmount()),
            orderDate: newDate,
            // "tentativeFulfillmentDate": newDate1,
            actualCompletionDate: null,
            strapiOrderFormJson: JSON.stringify(jsonAutomation),
            strapiOrderId: null,
            strapiOrderType: "Standard",
            orderTypeId: orderTypeId,
            stateId: state ? state.id : null,
            county: county ? county.id : null,
            transaction_id: null,
            entityTypeId: enityName ? enityName.id : null,
            isPayLater: paymentMethod === "Pay later" ? true : false,
            domicileStateId: state ? state.id : null,
            hubspotDealId: null,
            orderUpdateNotes: "Draft order created...",
            documentsId: null,
            orderUpdatedBy: loginUserName,
            keyPersonalId: keyPersonalArr,
            orderLineItems: upadatedProduct,
          };

          const saveOrder = () => {
            if (formData) {
              const areIdentical = deepCompareObjects(
                serviceDataTemp,
                selectedCheckBoxData
              );

              if (!areIdentical && serviceDataTemp.length > 0) {
                const temp = formData;
                temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
                temp["orderLineItems"] = upadatedProduct;
                temp["billingAddress"] = bilingAddress
                  ? bilingAddress.addressMasterId.id
                  : null;
                temp["shippingAddress"] = shippingAddress
                  ? shippingAddress.addressMasterId.id
                  : bilingAddress
                  ? bilingAddress.addressMasterId.id
                  : null;
                temp["orderTitle"] =
                  getValues("X_Name_of_Entity") !== undefined
                    ? getValues("X_Name_of_Entity")
                    : globalCompany
                    ? globalCompany.companyDetails
                      ? globalCompany.companyDetails.companyName
                      : "Order"
                    : "Order";
                temp["stateId"] = state ? state.id : null;
                temp["countyId"] = county ? county.id : null;
                temp["keyPersonalId"] = keyPersonalArr;
                temp["orderAmount"] = parseFloat(calculateTotalAmount());
                return OrderServices.deleteOrderLineItemById(formData.id)
                  .then((res) => {
                    // Save order data and handle error

                    return OrderServices.saveAllOrder(temp)
                      .then((res) => {
                        setPaymentOrderData(res.data);
                        setFormData(res.data.orderDetails);
                        setUpdatedOrderLineItems(res.data.orderLineItem);
                        setIsTabRefresh(false);
                      })
                      .catch((err) => {
                        setIsTabRefresh(false);
                        // if (toast.current) {
                        //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                        // }
                        showToast("error", "Something went wrong");

                        return false; // Prevent navigation on error
                      });
                  })
                  .catch((err) => {
                    setIsTabRefresh(false);
                  });
              } else {
                const temp = formData;
                temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
                temp["orderLineItems"] = updatedOrderLineItems;
                temp["billingAddress"] = bilingAddress
                  ? bilingAddress.addressMasterId.id
                  : null;
                temp["shippingAddress"] = shippingAddress
                  ? shippingAddress.addressMasterId.id
                  : bilingAddress
                  ? bilingAddress.addressMasterId.id
                  : null;
                temp["orderTitle"] =
                  getValues("X_Name_of_Entity") !== undefined
                    ? getValues("X_Name_of_Entity")
                    : globalCompany
                    ? globalCompany.companyDetails
                      ? globalCompany.companyDetails.companyName
                      : "Order"
                    : "Order";
                temp["stateId"] = state ? state.id : null;
                temp["countyId"] = county ? county.id : null;
                temp["keyPersonalId"] = keyPersonalArr;
                temp["orderAmount"] = parseFloat(calculateTotalAmount());
                setIsTabRefresh(true);

                return OrderServices.saveAllOrder(temp)
                  .then((res) => {
                    setPaymentOrderData(res.data);
                    setFormData(res.data.orderDetails);
                    setUpdatedOrderLineItems(res.data.orderLineItem);
                    setIsTabRefresh(false);
                  })
                  .catch((err) => {
                    setIsTabRefresh(false);
                    // if (toast.current) {
                    //   toast.current.show({
                    //     severity: "warn",
                    //     summary: "Oops",
                    //     detail: "Something went wrong",
                    //     life: 2800,
                    //   });
                    // }
                    showToast("error", "Something went wrong");
                    return false; // Prevent navigation on error
                  });
              }
            } else {
              setIsTabRefresh(true);
              return OrderServices.saveAllOrder(orderData)
                .then((res) => {
                  setOrderContinue(true);
                  setPaymentOrderData(res.data);
                  setFormData(res.data.orderDetails);
                  setUpdatedOrderLineItems(res.data.orderLineItem);
                  setIsTabRefresh(false);
                  setIsFirstDraft(true);

                  if (res.status === 201) {
                    // toast.current.show({
                    //   severity: "success",
                    //   summary: "Success",
                    //   detail: `Your order is saved in draft with temporary order id ${res.data.orderDetails.id}`,
                    //   life: 3000,
                    // });
                    showToast(
                      "success",
                      `Your order is saved in draft with temporary order id ${res.data.orderDetails.id}`
                    );
                  }
                })
                .catch((err) => {
                  setIsTabRefresh(false);
                  //   if (toast.current) {
                  //     toast.current.show({
                  //       severity: "warn",
                  //       summary: "Oops",
                  //       detail: "Something went wrong",
                  //       life: 2800,
                  //     });
                  //   }
                  showToast("error", `Something went wrong`);

                  return false; // Prevent navigation on error
                });
            }
          };

          saveOrder().then((success) => {
            if (success !== false) {
              // Only proceed to next page if save was successful
              setCurrentTab(newTab);
              showTab(newTab);
            }
          });
        }
      }
    } else {
      if (toast.current !== undefined) {
        // toast.current.show({
        //   severity: "warn",
        //   summary: "Warning",
        //   detail: "Please select at least one product",
        //   life: 3000,
        // });
        showToast("error", `Please select at least one product`);
      }
    }

    // Smooth scroll to top after click
    document.getElementById("nextBtn").addEventListener("click", function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Smooth scroll
      });
    });
  };

  const stepLabels = Array.from(
    { length: inputFormData.length + 5 },
    (_, i) => `Step ${i + 1} of ${inputFormData.length + 5}`
  );

  function validateForm() {
    const x = document.getElementsByClassName("tab");
    let valid = true;

    if (x.length > 0 && x[currentTab] !== undefined) {
      const y = x[currentTab].getElementsByTagName("input");
      const dropdowns = x[currentTab].getElementsByClassName("p-dropdown");

      // A loop that checks every input field in the current tab:
      for (let i = 0; i < y.length; i++) {
        const input = y[i];

        const column = allModels.find((field) => {
          const temp = field.schema.attributes;

          return Object.keys(temp).includes(input.name);
        });

        if (column && column !== undefined) {
          const fieldData = column.schema.attributes[input.name];

          const errorMessageTag = document.getElementById(
            `${input.name}--error`
          );

          // Reset previous error message
          if (errorMessageTag) {
            errorMessageTag.textContent = "";
          }

          // Check if the input field is empty and required:

          if (fieldData.required && input.value.trim() === "") {
            valid = false;
            if (errorMessageTag) {
              errorMessageTag.textContent = `${convertName(
                input.name
              )} required`;
            }
          }

          // Check regex validation if provided:
          if (fieldData.regex && input.value.trim() !== "") {
            const regex = new RegExp(fieldData.regex);
            if (!regex.test(input.value.trim())) {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `Enter Valid ${convertName(
                  input.name
                )} `;
              }
            }
          }
          if (input.type === "text" || input.type === "textarea") {
            if (fieldData.maxLength && input.value.trim() !== "") {
              if (input.value.length > fieldData.maxLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    input.name
                  )} should have a maximum length of ${fieldData.maxLength}`;
                }
              }
            }
            if (fieldData.minLength && input.value.trim() !== "") {
              if (input.value.length < fieldData.minLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    input.name
                  )} should have a minimum length of ${fieldData.minLength}`;
                }
              }
            }
          } else if (input.type === "number") {
            const value = parseInt(input.value, 10);

            if (fieldData.max && !isNaN(value) && value > fieldData.max) {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `${convertName(
                  input.name
                )} should be ${fieldData.max.length} digits`;
              }
            }
            if (fieldData.min && !isNaN(value) && value < fieldData.min) {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `${convertName(
                  input.name
                )} should be at least ${fieldData.min.length} digits`;
              }
            }
          }
        } else {
          if (inputFormData.length === 0) {
            valid = false;
          }
        }
      }

      // A loop that checks every dropdown field in the current tab:
      for (let i = 0; i < dropdowns.length; i++) {
        const dropdown = dropdowns[i];
        const inputName = dropdown.id;
        const inputValue = dropdown
          .querySelector(".p-dropdown-label")
          .textContent.trim();

        if (inputName) {
          const column = allModels.find((field) => {
            const temp = field.schema.attributes;
            return Object.keys(temp).includes(inputName);
          });

          if (column && column !== undefined) {
            const fieldData = column.schema.attributes[inputName];
            const errorMessageTag = document.getElementById(
              `${inputName}--error`
            );

            // Reset previous error message
            if (errorMessageTag) {
              errorMessageTag.textContent = "";
            }

            // Check if the dropdown field is empty and required:
            if (fieldData.required && inputValue === "empty") {
              valid = false;
              if (errorMessageTag) {
                errorMessageTag.textContent = `${convertName(
                  inputName
                )} required`;
              }
            }

            // Check regex validation if provided:
            if (fieldData.regex && inputValue !== "") {
              const regex = new RegExp(fieldData.regex);
              if (!regex.test(inputValue)) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `Enter Valid ${convertName(
                    inputName
                  )} `;
                }
              }
            }
            if (fieldData.maxLength && inputValue !== "") {
              if (inputValue.length > fieldData.maxLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    inputName
                  )} Should be Max Length ${fieldData.maxLength} `;
                }
              }
            }
            if (fieldData.minLength && inputValue !== "") {
              if (inputValue.length < fieldData.minLength) {
                valid = false;
                if (errorMessageTag) {
                  errorMessageTag.textContent = `${convertName(
                    inputName
                  )} Should be Min Length ${fieldData.minLength} `;
                }
              }
            }
          } else {
            if (inputFormData.length === 0) {
              valid = false;
            }
          }
        }
      }

      // If the valid status is true, mark the step as finished and valid:
      if (valid) {
        const stepElements = document.getElementsByClassName("step");

        if (stepElements[currentTab] !== undefined) {
          stepElements[currentTab].className += " finish";
        }
      }
    }

    return valid; // return the valid status
  }

  function fixStepIndicator(n) {
    // This function removes the "active" class of all steps...
    var i,
      x = document.getElementsByClassName("step");
    for (i = 0; i < x.length; i++) {
      x[i].className = x[i].className
        .replace(" active", "")
        .replace(" error", "");
    }
    //... and adds the "active" class on the current step:
    if (x[n] !== undefined) {
      x[n].className += " active";
    }

    // For error
    if (hasError) {
      x[n].className += " error";
    }
  }

  const handleComapnyName = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Only check availability if the input is not empty
    if (value) {
      checkAvailability(value);
    } else {
      setIsAvailable(null);
    }
  };

  const handleLLCMember = (e) => {
    setLlc(e.target.value);
  };

  function processSchema(schema, dataMap) {
    const result = {};

    if (schema !== undefined) {
      Object.keys(schema.attributes).forEach((attrKey) => {
        const attr = schema.attributes[attrKey];

        if (attr.apiId !== undefined && mainModel) {
          const mainSchema = mainModel.schema.attributes;

          // Use find to get the first matching key instead of filtering
          const componentKeys = Object.keys(mainSchema).map((k) =>
            Object.keys(mainSchema[k].schema.attributes).filter(
              (key) => mainSchema[k].schema.attributes[key].uid === attr.uid
            )
          );

          const componentKey = componentKeys.flat();

          if (componentKey) {
            const componentData = dataMap.get(attr.uid);

            if (componentData) {
              // Ensure mainSchema[componentKey] exists before recursion
              if (mainSchema[componentKey] && mainSchema[componentKey].schema) {
                result[attrKey] = processSchema(
                  mainSchema[componentKey].schema,
                  dataMap
                );
                Object.assign(result[attrKey], componentData);
              } else {
                result[attrKey] = componentData;
              }
            }
          }
        } else {
          const parentData = dataMap.get(attrKey);

          if (parentData) {
            result[attrKey] = parentData;
          }
        }
      });
    }

    return result;
  }

  function buildFinalData(temps, tempsData) {
    const finalData = {};

    const dataMap = new Map(tempsData.map((d) => [d.model, d.data]));
    if (mainModel) {
      Object.keys(temps).forEach((key) => {
        const schema = temps[key].schema;

        const data = dataMap.get(temps[key].uid);

        finalData[key] = processSchema(schema, dataMap);

        if (data) {
          if (Array.isArray(data)) {
            finalData[key] = [...data];
          } else {
            Object.assign(finalData[key], data);
          }
        }
      });
    }

    return finalData;
  }

  useEffect(() => {
    if (mainModel && modelData1.length > 0) {
      if (mainModel.schema !== undefined) {
        if (mainModel.schema.attributes !== undefined) {
          const finalData1 = buildFinalData(
            mainModel.schema.attributes,
            modelData1
          );
          setPriviewData(finalData1);
        }
      }
    }
  }, [mainModel, modelData1]);



  const getState = (id) => {
    const temp = allState.find((val) => val.id === id);
    if (temp !== undefined) {
      return temp.stateFullDesc;
    } else {
      return null;
    }
  };

  const getCountry = (id) => {
    const temp = allCountries.find((val) => val.id === id);
    if (temp !== undefined) {
      return temp.countryFullDesc;
    } else {
      return null;
    }
  };

  const stripePay = async (item, orderData) => {
    try {
      const stripe = await stripePromise;

      const data = {
        product: item,
      };

      const productPaydata = item.map((val) => {
        return {
          name: val.productListing.displayName,
          unitAmount: Math.round(val.productListing.unitPrice * 100),
          quantity: 1,
        };
      });

      const paytemp = {
        orderId: orderData?.orderDetails?.orderId
          ? (orderData?.orderDetails?.orderId).toString()
          : "",
        lineItems: productPaydata,
      };

      console.log(
        orderData?.orderDetails?.orderId,
        "orderData?.orderDetails?.orderId"
      );

      // const response = await axios.post(`$/checkout`, data);
      setIsLoading(true);
      setIsTabRefresh(true);

      console.log(paytemp, "paytemp");
      OrderServices.paymentOrder(paytemp)
        .then((response) => {
          localStorage.setItem("sessionId", response.data.sessionId);
          // setPaySessionId(res.data.sessionId)

          // const sessionId = response.data.id;
          // console.log(sessionId)

          const localData = {
            data: orderData,
            res: response.data,
            orderLineItem: item,
            updatedBy: loginUserName,
          };
          localStorage.setItem("payment", JSON.stringify(localData));
          setIsLoading(false);
          setIsTabRefresh(false);
          setTimeout(() => {
            window.location.href = response.data.checkoutUrl;
          }, 100); // Small timeout to ensure data is saved
          // const { error } = await stripe.redirectToCheckout({
          //     sessionId: sessionId
          // });
          // if (error) {
          //     console.error("Stripe Checkout Error:", error);
          // }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsTabRefresh(false);
        });
    } catch (err) {
      console.error("Error during checkout process:", err);
    }
  };

  // const stripePay = async (item, orderData) => {

  //     try {

  //         const stripe = await stripePromise;

  //         const data = {

  //             product: item
  //         }

  //         const response = await axios.post(`${propertiesApp.stripe_host}/checkout`, data);
  //         console.log(response)

  //         const sessionId = response.data.id;
  //         console.log(sessionId)

  //         const localData = {
  //             data: orderData,
  //             res: response.data,
  //             orderLineItem: item,
  //             updatedBy: loginUserName
  //         }
  //         localStorage.setItem("payment", JSON.stringify(localData))
  //         const { error } = await stripe.redirectToCheckout({
  //             sessionId: sessionId
  //         });
  //         if (error) {
  //             console.error("Stripe Checkout Error:", error);
  //         }
  //     } catch (err) {
  //         console.error("Error during checkout process:", err);
  //     }
  // }

  // Function to handle confirmation
  const handleConfirm = async () => {
    if (mainModel) {
      const keyPersonalArr = [];
      if (incorporator?.id) keyPersonalArr.push(incorporator.id);
      if (officer?.id) keyPersonalArr.push(officer.id);
      if (director?.id) keyPersonalArr.push(director.id);
      if (organizerData?.id) keyPersonalArr.push(organizerData.id);
      if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
      if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);

      const keyPersonalStrapiArr = [];
      if (incorporator?.id)
        keyPersonalStrapiArr.push({ data: incorporator, type: "INCORPORATOR" });
      if (officer?.id)
        keyPersonalStrapiArr.push({ data: officer, type: "OFFICER" });
      if (director?.id)
        keyPersonalStrapiArr.push({ data: director, type: "DIRECTOR" });
      if (organizerData?.id)
        keyPersonalStrapiArr.push({ data: organizerData, type: "ORGANIZER" });
      if (bilingAddress?.id)
        keyPersonalStrapiArr.push({ data: bilingAddress, type: "BILLING" });
      if (shippingAddress?.id)
        keyPersonalStrapiArr.push({ data: shippingAddress, type: "SHIPPING" });

      let d = new Date();
      const futureDate = new Date(d);
      futureDate.setDate(d.getDate() + 7);

      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();

      let day1 = futureDate.getDate();
      let month1 = futureDate.getMonth() + 1;
      let year1 = futureDate.getFullYear();

      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = `0${month}`;
      } else {
        month = month;
      }
      let newDate = year + "-" + month + "-" + day;

      if (day1 < 10) {
        day1 = "0" + day1;
      }
      if (month < 10) {
        month1 = `0${month1}`;
      } else {
        month1 = month1;
      }
      let newDate1 = year1 + "-" + month1 + "-" + day1;

      const upadatedProduct = selectedCheckBoxData.map((val) => {
        return {
          productListing: val.product,
          quantity: 1,
          unitPrice: calculateTotalAmount(),
        };
      });

      const finalData1 = buildFinalData(
        mainModel.schema.attributes,
        modelData1
      );

      const tempData = {
        data: finalData1,
      };
      const jsonAutomation = {
        EntityType: enityName,
        State: state,
        County: county,
        Payload: finalData1,
        legalName:
          getValues("CD_LLC_Name") !== undefined
            ? getValues("CD_LLC_Name")
            : getValues("CD_Corporation_Name") !== undefined
            ? getValues("CD_Corporation_Name")
            : null,
        addOnService: addOnServiceData,
        billingAddress: bilingAddress,
        shipingAddress: shippingAddress,
        keyPersonalStrapiArr: keyPersonalStrapiArr,

      };
      setIsTabRefresh(true);

      setIsTabRefresh(true);
      if (paymentMethod === "Pay later") {
        const temp = formData;
        temp["statusId"] = 14;
        temp["orderLineItems"] = updatedOrderLineItems;
        temp["orderUpdateNotes"] = "Order created successfully";
        temp["orderUpdatedBy"] = loginUserName;
        temp["shippingAddress"] = shippingAddress
          ? shippingAddress.addressMasterId.id
          : bilingAddress
          ? bilingAddress.addressMasterId.id
          : null;
        temp["strapiOrderId"] = 1;
        temp["orderDate"] = newDate;
        temp["tentativeFulfillmentDate"] = newDate1;
        temp["orderAmount"] = parseFloat(calculateTotalAmount());
        temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
        temp["stateId"] = state ? state.id : null;
        temp["countyId"] = county ? county.id : null;
        temp["keyPersonalId"] = keyPersonalArr;
        OrderServices.saveAllOrder(temp)
          .then((res) => {
            const temp = res.data?.orderDetails;
            temp["statusId"] = 1000;
            temp["orderLineItems"] = updatedOrderLineItems;
            temp["orderUpdateNotes"] = "Approval Pending";
            temp["orderUpdatedBy"] = loginUserName;
            temp["shippingAddress"] = shippingAddress
              ? shippingAddress.addressMasterId.id
              : bilingAddress
              ? bilingAddress.addressMasterId.id
              : null;
            temp["strapiOrderId"] = strapiId;
            temp["stateId"] = state ? state.id : null;
            temp["countyId"] = county ? county.id : null;
            temp["keyPersonalId"] = keyPersonalArr;
            setIsTabRefresh(true);
            OrderServices.saveAllOrder(temp)
              .then((res) => {
                setOrderContinue(false);
                setIsTabRefresh(false);
                history("/order");
              })
              .catch((err) => {
                setCloseProgresbar2(false);
                setCloseProgresbar3(false);
                setCloseProgresbar1(true);
                setIsTabRefresh(false);

                showToast("error", `Something went wrong`);
              });
          })
          .catch((err) => {
            setCloseProgresbar2(false);
            setCloseProgresbar3(false);
            setCloseProgresbar1(true);
            setIsTabRefresh(false);
            // if (toast.current) {
            //   toast.current.show({
            //     severity: "warn",
            //     summary: "Oops",
            //     detail: "Something went wrong",
            //     life: 2800,
            //   });
            // }
            showToast("error", `Something went wrong`);
          });
      }
  //      else {
  //       const temp = formData;
  //       temp["statusId"] = 14;
  //       temp["orderLineItems"] = updatedOrderLineItems;
  //       temp["orderUpdateNotes"] = "Order created successfully.";
  //       temp["orderUpdatedBy"] = loginUserName;
  //       temp["shippingAddress"] = shippingAddress
  //         ? shippingAddress.addressMasterId.id
  //         : bilingAddress
  //         ? bilingAddress.addressMasterId.id
  //         : null;
  //       temp["strapiOrderId"] = strapiId;
  //       temp["stateId"] = state ? state.id : null;
  //       temp["countyId"] = county ? county.id : null;
  //       temp["keyPersonalId"] = keyPersonalArr;
  //       temp["orderDate"] = newDate;
  //       temp["tentativeFulfillmentDate"] = newDate1;
  //       setIsTabRefresh(true);
  //       OrderServices.saveAllOrder(temp)
  //         .then((res) => {
  //           setOrderContinue(false);
  //           const orderPayData = res.data;
  //           const tempNew = res.data.orderDetails;
  //           tempNew["shippingAddress"] = shippingAddress
  //             ? shippingAddress.addressMasterId.id
  //             : bilingAddress
  //             ? bilingAddress.addressMasterId.id
  //             : null;
  //           tempNew["orderLineItems"] = updatedOrderLineItems;
  //           const invoiceData = {
  //             orderId: tempNew,
  //             invoiceNo: `INV ${res.data.orderDetails.orderId}`,
  //             totalAmount: calculateTotalAmount(),
  //             orderLineItems: updatedOrderLineItems,
  //           };

  //           const temp = res.data.orderDetails;

  //           // async function processInvoice(invoiceData, updatedOrderLineItems, orderPayData) {
  //           //   try {
  //           //     const res = await OrderServices.saveAllInvoice(invoiceData);
                
  //           //     // setCloseProgresbar2(false);
  //           //     // setCloseProgresbar3(false);
  //           //     // setCloseProgresbar(true);
                
  //           //     const saveTransactionPayload = {
  //           //       // bankName:"",
  //           //       // checkNumber: null,
  //           //       // transactionId: null,
  //           //       // transactionDate: "",
  //           //       transactionType: "CARD",
  //           //       transactionStatus: 30,
  //           //       amount: res.data?.totalAmount,
  //           //       orderDetails: res.data?.orderId,
  //           //       invoiceDetails: res.data,
  //           //     };
            
  //           //     const transactionRes = await OrderServices.saveTransaction(saveTransactionPayload);
  //           //     localStorage.setItem("transactiondata", JSON.stringify(transactionRes.data));
                
  //           //     setIsTabRefresh(false);
  //           //     stripePay(updatedOrderLineItems, orderPayData);
                
  //           //   } catch (err) {
  //           //     setCloseProgresbar2(false);
  //           //     setCloseProgresbar3(false);
  //           //     stripePay(updatedOrderLineItems, orderPayData);
  //           //     setIsTabRefresh(false);
  //           //     showToast("error", "Something went wrong");
  //           //   }
  //           // }
  //           // processInvoice(invoiceData, updatedOrderLineItems, orderPayData)

  //           OrderServices.saveAllInvoice(invoiceData)
  // .then(res => {
  //   const saveTransactionPayload = {
  //     transactionType: "CARD",
  //     transactionStatus: 30,
  //     amount: res.data?.totalAmount,
  //     orderDetails: res.data?.orderId,
  //     invoiceDetails: res.data,
  //   };
  //   return OrderServices.saveTransaction(saveTransactionPayload);
  // })
  // .then(transactionRes => {
  //   localStorage.setItem("transactiondata", JSON.stringify(transactionRes.data));
  //   setIsTabRefresh(false);
  //   stripePay(updatedOrderLineItems, orderPayData);
  // })
  // .catch(err => {
  //   setCloseProgresbar2(false);
  //   setCloseProgresbar3(false);
  //   stripePay(updatedOrderLineItems, orderPayData);
  //   setIsTabRefresh(false);
  //   showToast("error", "Something went wrong");
  // });

  //           setIsTabRefresh(false);

  //           stripePay(updatedOrderLineItems, orderPayData);
  //         })
  //         .catch((err) => {
  //           setCloseProgresbar2(false);
  //           setCloseProgresbar3(false);
  //           // setCloseProgresbar1(true)
  //           setIsTabRefresh(false);
  //           // if (toast.current) {
  //           //   toast.current.show({
  //           //     severity: "warn",
  //           //     summary: "Oops",
  //           //     detail: "Something went wrong",
  //           //     life: 2800,
  //           //   });
  //           // }
  //           showToast("error", `Something went wrong`);
  //         });
  //     }

  else {
    const temp = { ...formData };
    temp["statusId"] = 14;
    temp["orderLineItems"] = updatedOrderLineItems;
    temp["orderUpdateNotes"] = "Order created successfully.";
    temp["orderUpdatedBy"] = loginUserName;
    temp["shippingAddress"] = shippingAddress
      ? shippingAddress.addressMasterId.id
      : bilingAddress
      ? bilingAddress.addressMasterId.id
      : null;
    temp["strapiOrderId"] = strapiId;
    temp["stateId"] = state ? state.id : null;
    temp["countyId"] = county ? county.id : null;
    temp["keyPersonalId"] = keyPersonalArr;
    temp["orderDate"] = newDate;
    temp["tentativeFulfillmentDate"] = newDate1;
    
    setIsTabRefresh(true);
    
    OrderServices.saveAllOrder(temp)
      .then((res) => {
        setOrderContinue(false);
        const orderPayData = res.data;
        const tempNew = res.data.orderDetails;
        tempNew["shippingAddress"] = shippingAddress
          ? shippingAddress.addressMasterId.id
          : bilingAddress
          ? bilingAddress.addressMasterId.id
          : null;
        tempNew["orderLineItems"] = updatedOrderLineItems;
        
        const invoiceData = {
          orderId: tempNew,
          invoiceNo: `INV ${tempNew.orderId}`,
          totalAmount: calculateTotalAmount(),
          orderLineItems: updatedOrderLineItems,
        };

        const PaidOrderdata = {
          orderId: tempNew,
          invoiceNo: `INV ${res.data.orderDetails.orderId}`,
          totalAmount: calculateTotalAmount(),

          statusId: res.data.statusId.id,
       
          orderLineItems: updatedOrderLineItems,
          base64Data: "",
          invoiceUpdateNotes: "Payment Done",
          invoiceUpdatedBy: "null",
        };
        
        // Chain the invoice saving
        return OrderServices.saveAllInvoice(PaidOrderdata).then((invoiceRes) => ({
          orderPayData,
          invoiceRes,
        }));
      })
      .then(({ orderPayData, invoiceRes }) => {
        // Build payload for the transaction call
        const saveTransactionPayload = {
          transactionType: "CARD",
          transactionStatus: 30,
          amount: invoiceRes.data?.totalAmount,
          orderDetails: invoiceRes.data?.orderId,
          invoiceDetails: invoiceRes.data,
        };
        return OrderServices.saveTransaction(saveTransactionPayload).then((transactionRes) => ({
          orderPayData,
          transactionRes,
        }));
      })
      .then(({ orderPayData, transactionRes }) => {
        localStorage.setItem("transactiondata", JSON.stringify(transactionRes.data));
        setIsTabRefresh(false);
        stripePay(updatedOrderLineItems, orderPayData);
      })
      .catch((err) => {
        setCloseProgresbar2(false);
        setCloseProgresbar3(false);
        setIsTabRefresh(false);
        showToast("error", "Something went wrong");
      });
  } 
    }
  };

  // const onSubmit = (data) => {
  //   if (validateForm()) {
  //     if (mainModel && bilingAddress) {
  //       if (paymentMethod === "Pay later") {
  //         setCloseProgresbar2(true);
  //       } else {
  //         setCloseProgresbar3(true);
  //         // setIsTabRefresh(false);
  //       }
  //     } else {
  //       if (!bilingAddress) {
  //         // if (toast.current) {
  //         //   toast.current.show({
  //         //     severity: "warn",
  //         //     summary: "Warning",
  //         //     detail: "Billing address required",
  //         //     life: 2800,
  //         //   });
  //         // }
  //         showToast("error", `Billing address required`);
  //       }
  //     }
  //   }else {
  //     const stepElements = document.getElementsByClassName("step");
  //     for (let index = 0; index < stepElements.length; index++) {
  //       if (!stepElements[index].classList.contains("finish")) {
  //         stepElements[index].classList.add("error");
  //       }
  //     }
  //     if (stepElements[currentTab] !== undefined) {
  //       stepElements[currentTab].className += "error";
  //     }
  //   }
  // };

  const onSubmit = (data) => {
    if (validateForm()) {
      if (mainModel && bilingAddress) {
        if (paymentMethod === "Pay later") {
          setCloseProgresbar2(true);
        } else {
          setCloseProgresbar3(true);
          // setIsTabRefresh(false);
        }
      } else {
        if (!bilingAddress) {
          showToast("error", "Billing address required");

          // Add the error class to the current tab if the billing address is missing
          const stepElements = document.getElementsByClassName("step");
          if (
            stepElements[currentTab] !== undefined &&
            !stepElements[currentTab].classList.contains("finish")
          ) {
            stepElements[currentTab].classList.add("error");
          }
        }
      }
    } else {
      const stepElements = document.getElementsByClassName("step");

      for (let index = 0; index < stepElements.length; index++) {
        if (!stepElements[index].classList.contains("finish")) {
          stepElements[index].classList.add("error");
        }
      }

      if (
        stepElements[currentTab] !== undefined &&
        !stepElements[currentTab].classList.contains("finish")
      ) {
        stepElements[currentTab].classList.add("error");
      }
    }
  };

  const onInvalid = (errors) => {
    const stepElements = document.getElementsByClassName("step");
    for (let index = 0; index < stepElements.length; index++) {
      if (!stepElements[index].classList.contains("finish")) {
        stepElements[index].classList.add("error");
      }
    }
    if (stepElements[currentTab] !== undefined) {
      stepElements[currentTab].className += "error";
    }
    showToast("error", `Please enter required information`);
  };

  const convertToTitleCase = (str) => {
    return str
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2")
      .replace(/_/g, " ");
  };

  const handleChecked1 = (e, data, checkboxIndex) => {
    const { checked } = e.target;

    if (checked) {
      // Add data to selectedCheckBoxData
      setSelectedCheckBoxData((prev) => [...prev, data]);
    } else {
      // Remove data from selectedCheckBoxData
      setSelectedCheckBoxData((prev) =>
        prev.filter((item) => item.service !== data.service)
      );
    }

    // Update individual checkbox state
    if (checkboxIndex === 1) {
      setIsChecked1(checked);
    } else if (checkboxIndex === 2) {
      setIsChecked2(checked);
    } else if (checkboxIndex === 3) {
      setIsChecked3(checked);
    } else if (checkboxIndex === 4) {
      setIsChecked4(checked);
    } else if (checkboxIndex === 5) {
      setIsChecked5(checked);
    } else if (checkboxIndex === 6) {
      setIsChecked6(checked);
    } else if (checkboxIndex === 7) {
      setIsChecked7(checked);
    } else if (checkboxIndex === 8) {
      setIsChecked8(checked);
    } else if (checkboxIndex === 9) {
      setIsChecked9(checked);
    } else if (checkboxIndex === 10) {
      setIsChecked10(checked);
    }
  };

  // const totalAmount = selectCheckBoxData.reduce((total, item) => {

  //     const amount = parseFloat(item.amount.replace("$", ""));
  //     return total + amount;
  // }, 0);

  const toPascalCase = (str) => {
    return str
      .replace(/-/g, " ")
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const removeDuplicates = (data) => {
    const result = {};

    Object.keys(data).forEach((key) => {
      // Create a Set to store unique `uid` values
      const uniqueItems = new Map();

      data[key].form.forEach((item) => {
        // Use `uid` as the key to ensure uniqueness
        if (!uniqueItems.has(item.uid)) {
          uniqueItems.set(item.uid, item);
        }
      });

      // Convert the Map values back to an array
      result[key] = {
        mode: data[key].mode,
        form: Array.from(uniqueItems.values()),
      };
    });

    return result;
  };

  const groupsedData = inputFormData.reduce((acc, value) => {
    if (typeof value.schema.attributes === "object") {
      const tmp = value.schema.attributes;
      if (tmp !== undefined) {
        const temp = Object.keys(tmp).map((key, i) => {
          Object.keys(groupInitialData).map((keys, m) => {
            if (
              key === keys &&
              groupInitialData[keys].type === "component" &&
              groupInitialData[keys].repeatable === true
            ) {
              if (tmp[key].schema.attributes !== undefined) {
                if (typeof tmp[key].schema.attributes === "object") {
                  const tempdata = Object.keys(tmp[key].schema.attributes).map(
                    (keyval, i) => {
                      if (
                        tmp[key].schema.attributes[keyval].uid !== undefined
                      ) {
                        return tmp[key].schema.attributes[keyval];
                      } else {
                        return tmp[key];
                      }
                    }
                  );

                  acc[key] = {
                    form: tempdata,
                    mode: {
                      repeat: groupInitialData[keys].repeatable,
                    },
                  };
                }
              }
            } else {
              if (tmp[key].schema !== undefined) {
                if (
                  tmp[key].schema.attributes !== undefined &&
                  groupInitialData[keys].repeatable === false &&
                  key === keys
                ) {
                  if (typeof tmp[key].schema.attributes === "object") {
                    const tempdata = Object.keys(
                      tmp[key].schema.attributes
                    ).map((keyval, i) => {
                      if (
                        tmp[key].schema.attributes[keyval].uid !== undefined
                      ) {
                        return tmp[key].schema.attributes[keyval];
                      } else {
                        return tmp[key];
                      }
                    });

                    acc[key] = {
                      form: tempdata,
                      mode: null,
                    };
                  }
                }
              }
            }
          });
        });
      }
    }
    // if (value.apiID === "entity-detail" || value.apiID === "address") {
    //     if (!acc["contact_and_address"]) {
    //         acc["contact_and_address"] = [];
    //     }
    //     acc["contact_and_address"].push(value);
    // } else {
    //     acc[value.apiID] = [value];
    // }

    return acc;
  }, {});

  const groupedData = removeDuplicates(groupsedData);

  useEffect(() => {
    const temp = Object.keys(groupedData).map((key, i) => {
      return groupedData[key].form;
    });

    const tempdata = temp.flat();

    setAllModels(tempdata);
  }, [enityName, state, currentTab]);

  const handleKeyPersonal = (data) => {
    setVisible2(true);
    const temp = allKeyPersonnelType.filter(
      (val) => val.keyPersonalType === data
    );
    if (temp.length > 0) {
      setMemberType(temp[0]);
    }

    const temp1 = allAddressType.filter((val) => val.type === data);
    if (temp1.length > 0) {
      setKeyPersonalAddressType(temp1[0]);
    }
  };

  const RecursiveRender = ({ data, parentKey }) => {
    return (
      <>
        {Array.isArray(data) ? (
          // Handle array of objects
          data.map((item, idx) => (
            <div key={idx} className="p-6 grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <div
                  className="px-4 py-2 rounded-t-lg"
                  style={{
                    backgroundColor: "#F0F5FF",
                    fontFamily: "Inter",
                    fontSize: "16px",
                  }}
                >
                  <h2 className="text-lg text-gray-800">
                    {" "}
                    {convertToTitleCase(parentKey)}{" "}
                  </h2>
                </div>
              </div>
              {Object.keys(item).map((key, i) => (
                <div key={i} className="col-span-4">
                  {typeof item[key] === "object" ? (
                    <div className="border border-gray-300 rounded-lg shadow-sm bg-white">
                      <div
                        className="px-4 py-2 rounded-t-lg"
                        style={{
                          backgroundColor: "#F0F5FF",
                          fontFamily: "Inter",
                          fontSize: "16px",
                        }}
                      >
                        <h2 className="text-lg text-gray-800">
                          {convertToTitleCase(key)}
                        </h2>
                      </div>
                      {/* Recursively render the nested object */}
                      <RecursiveRender data={item[key]} parentKey={key} />
                    </div>
                  ) : (
                    <>
                      <div
                        className="font-medium text-gray-700 mb-1"
                        style={{ fontFamily: "Inter", fontSize: "14px" }}
                      >
                        {convertName(key)}
                      </div>
                      <div
                        className="text-gray-900"
                        style={{ fontFamily: "Inter", fontSize: "14px" }}
                      >
                        {item[key]}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))
        ) : typeof data === "object" && data !== null ? (
          <div className="p-6 grid grid-cols-12 gap-6">
            {Object.keys(data).map((key, i) => (
              <div key={i} className="col-span-4">
                {typeof data[key] === "object" ? (
                  <div className="border border-gray-300 rounded-lg shadow-sm bg-white">
                    <div
                      className="px-4 py-2 rounded-t-lg"
                      style={{
                        backgroundColor: "#F0F5FF",
                        fontFamily: "Inter",
                        fontSize: "16px",
                      }}
                    >
                      <h2 className="text-lg text-gray-800">
                        {convertToTitleCase(key)}
                      </h2>
                    </div>
                    {/* Recursively render the child object */}
                    <RecursiveRender data={data[key]} parentKey={key} />
                  </div>
                ) : (
                  <>
                    <div
                      className="font-medium text-gray-700 mb-1"
                      style={{ fontFamily: "Inter", fontSize: "14px" }}
                    >
                      {convertName(key)}
                    </div>
                    <div
                      className="text-gray-900"
                      style={{ fontFamily: "Inter", fontSize: "14px" }}
                    >
                      {data[key]}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div
            className="font-medium text-gray-700 mb-1"
            style={{ fontFamily: "Inter", fontSize: "14px" }}
          >
            {convertName(parentKey)}
            <div
              className="text-gray-900"
              style={{ fontFamily: "Inter", fontSize: "14px" }}
            >
              {data}
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (groupedData) {
      setTotalStep(Object.keys(groupedData).length + 3);
      Object.keys(groupedData).map((key, i) => {
        const isRepeatMode =
          groupedData[key].mode && groupedData[key].mode.repeat === true;

        const processAttributes = (val, l = "") => {
          const tempval = convertName(val);
          const options = optionsMap?.[tempval] || [];

          if (options.length > 0) {
            const matchedItem = tempdata.find((item) => item.item === tempval);
            const defaultValue =
              matchedItem !== undefined
                ? matchedItem.label === "stateFullDesc" && state
                  ? state.stateShortName
                  : matchedItem.label === "countyName" && county
                  ? county.countyName
                  : matchedItem.label === "countryShortName" && state
                  ? state.countryMaster.countryShortName
                  : ""
                : "";

            if (defaultValue) {
              if (l) {
                setValue(`${val}${l ? `-${l}` : ""}`, defaultValue);
              } else {
                setValue(val, defaultValue);
              }
            }
          }
        };

        if (isRepeatMode) {
          // Repeat mode case
          return Array.from({ length: formCount }).map((_, l) => {
            return groupedData[key].form.map((value, j) => {
              return Object.keys(value.schema.attributes).map((val, k) => {
                processAttributes(val, l); // Pass the index `l` for repeated fields
              });
            });
          });
        } else {
          // Non-repeat mode case
          return groupedData[key].form.map((value, j) => {
            return Object.keys(value.schema.attributes).map((val, k) => {
              processAttributes(val); // No index `l` for non-repeated fields
            });
          });
        }
      });
    }
  }, [groupedData]);

  const keyPersonalMemberData = [
    "Organizer_Information",
    "Incorporator_Information",
    "Registered_Agent",
  ];

  const goToStep = (stepNumber) => {
    const totalSteps = Object.keys(groupedData).length + 3; // Total steps
    if (stepNumber >= 0 && stepNumber < totalSteps) {
      const tabs = document.getElementsByClassName("tab");
      if (tabs[currentTab]) {
        tabs[currentTab].style.display = "none";
      }

      setCurrentTab(stepNumber);
      setCurrentStep(stepNumber + 1);

      showTab(stepNumber);

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const items = Array.from({ length: 5 }, (v, i) => i);

  const handlers = {
    setOrganizerData,

    setBilingAddress,
    setOfficer,
    setIncorporator,
    setDirector,
  };

  return (
    <div>
      <Toast ref={toast} />
      {loading1 ? (
        <div className="spinner">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="data-container">
          <div className="grid font-fam-for-all">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <div
                className="card-container-wizard font-fam-for-all"
                id="card-container-wizard-id"
              >
                <div className="wizard-header">
                  <div className="wizard-header-container">
                    <div className="page-wizard-header">
                      <div className="page-wizard-content">
                        <div className="page-text-header">
                          <div className="page-text-header-p">
                            {addonServiceRedirectedData
                              ? toPascalCase(
                                  addonServiceRedirectedData.itemName
                                )
                              : ""}
                          </div>
                          <div className="page-text-header-p1">
                            {addonServiceRedirectedData
                              ? addonServiceRedirectedData.description
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="wizard-content-container"
                    ref={registeredTabRef}
                    id="registered-tab-section"
                  >
                    <div className="wizard-content-main">
                      <div className="wizard-content-frame">
                        <div className="wizard-content-card">
                          <div className="wizard-form-card-header-container">
                            <div className="wizard-form-card-header-actiob-container">
                              <div className="wizard-form-card-frame">
                                {Object.keys(groupedData).length > 0 ? (
                                  <div className="wizard-form-card-header-text1">
                                    {`Step ${currentStep} of ${
                                      Object.keys(groupedData).length + 3
                                    }`}
                                  </div>
                                ) : (
                                  <div className="wizard-form-card-header-text1">
                                    {`Step 1 of 1`}
                                  </div>
                                )}
                                {inputFormData.length > 0 ? (
                                  <div className="wizard-form-card-header-text2">
                                    Total due today
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="wizard-form-card-frame">
                                <div className="wizard-form-card-header-text3">
                                  Entity Details
                                </div>
                                {inputFormData.length > 0 ? (
                                  <div className="wizard-form-card-header-text4">
                                    {`$${calculateTotalAmount()}`}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="wizard-bar">
                            <span
                              class={
                                Object.keys(groupedData).length > 0
                                  ? "step"
                                  : "step "
                              }
                              style={{ cursor: "pointer" }}
                              onClick={() => goToStep(0)}
                            >
                              <div className="mt-2 wizard-form-card-header-text3">
                                Entity Details
                              </div>
                            </span>
                            {Object.keys(groupedData).map((key, i) => (
                              <span
                                class="step"
                                onClick={() => goToStep(i + 1)}
                                style={{ cursor: "pointer" }}
                                key={`extra-${i}`}
                              >
                                <div className="mt-2 wizard-step-content wizard-form-card-header-text3">
                                  {toPascalCase(key)}
                                </div>
                              </span>
                            ))}

                            {inputFormData.length > 0 ? (
                              <>
                                <span
                                  class={
                                    Object.keys(groupedData).length > 0
                                      ? "step"
                                      : "step"
                                  }
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    goToStep(
                                      Object.keys(groupedData).length + 1
                                    )
                                  }
                                >
                                  <div className="mt-2 wizard-form-card-header-text3">
                                    Billing Address Information
                                  </div>
                                </span>

                                <span
                                  class={
                                    Object.keys(groupedData).length > 0
                                      ? "step"
                                      : "step"
                                  }
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    goToStep(
                                      Object.keys(groupedData).length + 2
                                    )
                                  }
                                >
                                  <div className="mt-2 wizard-form-card-header-text3">
                                    Preview
                                  </div>
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="wizard-main-content font-fam-for-all mt-4">
                            <div className="wizard-main-sub-content">
                              <form
                                className="form-wizard"
                                // onSubmit={handleSubmit(onSubmit)}
                              >
                                <div className="tab px-4 hover-card slide-in font-fam-for-all">
                                  <div
                                    className="wizard-content-sub-card-header my-3"
                                    id="header"
                                  >
                                    <div className="wizard-content-sub-card-text-frame">
                                      <div className="wizard-content-sub-card-text">
                                        Select State & Entity
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6">
                                    {superAdminId && (
                                      <div className="field">
                                        <label
                                          htmlFor=""
                                          className="form-label font-fam-for-all"
                                        >
                                          Select Company
                                          <span className="form-field-mandatory">
                                            *
                                          </span>
                                        </label>
                                        <span>
                                          <Controller
                                            name="entity"
                                            control={control}
                                            render={({ field }) => (
                                              <Dropdown
                                                value={companyName}
                                                options={allParentCompany}
                                                disabled={isFirstDraft}
                                                onChange={(e) => {
                                                  setCompanyName(e.value);
                                                }}
                                                resetFilterOnHide
                                                optionLabel="company.companyName"
                                                placeholder="Select Company"
                                                style={{
                                                  width: "100%",
                                                  height: "47px",
                                                }}
                                                filter
                                                className="dialog-form-input-field-wizard"
                                                required
                                              />
                                            )}
                                          />
                                        </span>
                                        <small
                                          id="entity--error"
                                          className="error-msg font-fam-for-all"
                                        >
                                          {entityErr}
                                        </small>
                                        {getFormErrorMessage("entity")}
                                      </div>
                                    )}
                                  </div>

                                  <div className="flex flex-column justify-content-center">
                                    <div className="input-fields-main ">
                                      <div className="grid w-full justify-content-center">
                                        <div
                                          className={
                                            state 
                                            && stateWithCounty.includes(state.stateFullDesc)
                                              ? "col-4"
                                              : "col-6"
                                          }
                                        >
                                          <div className=" justify-content-center dialog-form-field-space w-full">
                                            <div className="field">
                                              <label
                                                htmlFor=""
                                                className="form-label font-fam-for-all"
                                              >
                                                Select Entity Type
                                                <span className="form-field-mandatory">
                                                  *
                                                </span>
                                              </label>
                                              <span>
                                                <Controller
                                                  name="entity"
                                                  control={control}
                                                  render={({ field }) => (
                                                    <Dropdown
                                                      value={enityName}
                                                      options={entityTypes}
                                                      resetFilterOnHide
                                                      onChange={(e) =>
                                                        setEntityName(e.value)
                                                      }
                                                      optionLabel="entityShortName"
                                                      placeholder="Select Entity Type"
                                                      disabled={isFirstDraft}
                                                      style={{
                                                        width: "100%",
                                                        height: "47px",
                                                      }}
                                                      filter
                                                      className="dialog-form-input-field-wizard"
                                                      required
                                                    />
                                                  )}
                                                />
                                              </span>
                                              <small
                                                id={`entity--error`}
                                                className="error-msg font-fam-for-all "
                                              >
                                                {entityErr}
                                              </small>
                                              {getFormErrorMessage("entity")}
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={
                                            state && (stateWithCounty.includes(state.stateFullDesc))
                                              ? "col-4"
                                              : "col-6"
                                          }
                                        >
                                          <div className=" justify-content-center dialog-form-field-space w-full">
                                            <div className="field">
                                              <label
                                                htmlFor=""
                                                className="form-label font-fam-for-all"
                                              >
                                                State of Formation{" "}
                                                <span className="form-field-mandatory">
                                                  *
                                                </span>
                                              </label>
                                              <span>
                                                <Controller
                                                  name="state"
                                                  control={control}
                                                  render={({ field }) => (
                                                    <Dropdown
                                                      value={field.value}
                                                      id={field.name}
                                                      resetFilterOnHide
                                                      options={allState}
                                                      disabled={isFirstDraft}
                                                      onChange={(e) => {
                                                        field.onChange(e.value);
                                                        setState(e.value);
                                                      }}
                                                      optionLabel="stateFullDesc"
                                                      style={{
                                                        width: "100%",
                                                        height: "47px",
                                                      }}
                                                      placeholder="Select State"
                                                      filter
                                                      className="dialog-form-input-field-wizard"
                                                      required
                                                    />
                                                  )}
                                                />
                                              </span>
                                              <small
                                                id={`state--error`}
                                                className="error-msg font-fam-for-all "
                                              >
                                                {stateErr}
                                              </small>
                                              {getFormErrorMessage("state")}
                                            </div>
                                          </div>
                                        </div>

                                        {state && (stateWithCounty.includes(state.stateFullDesc)) ? (
                                          <div className="col-4">
                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                              <div className="field">
                                                <label
                                                  htmlFor=""
                                                  className="form-label font-fam-for-all"
                                                >
                                                  County
                                                  <span className="form-field-mandatory">
                                                    *
                                                  </span>
                                                </label>
                                                <span className=" ">
                                                  <Controller
                                                    name="county"
                                                    control={control}
                                                    render={({ field }) => (
                                                      <Dropdown
                                                        value={field.value}
                                                        id={field.name}
                                                        options={allCounty}
                                                        disabled={isFirstDraft}
                                                        resetFilterOnHide
                                                        onChange={(e) => {
                                                          field.onChange(
                                                            e.value
                                                          );
                                                          setCounty(e.value);
                                                        }}
                                                        optionLabel="countyName"
                                                        style={{
                                                          width: "100%",
                                                          height: "47px",
                                                        }}
                                                        placeholder="Select County"
                                                        filter
                                                        className="dialog-form-input-field-wizard"
                                                      />
                                                    )}
                                                  />
                                                </span>
                                                <small
                                                  id={`county--error`}
                                                  className="error-msg font-fam-for-all "
                                                >
                                                  {countyErr}
                                                </small>
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>

                                    {state && enityName && (
                                      <div
                                        className="wizard-content-sub-card-header my-3"
                                        id="header"
                                      >
                                        <div className="wizard-content-sub-card-text-frame">
                                          <div className="wizard-content-sub-card-text">
                                            Choose your preferred services from
                                            the options below
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {state && enityName ? (
                                      showSkeleton ? (
                                        servicesData.map(
                                          (serviceData, index) => (
                                            <div
                                              key={index}
                                              className="wizard-card-checkbox-container hover-card slide-in"
                                            >
                                              <div className="wizard-card-checkbox-main pb-0">
                                                <div className="wizard-card-checkbox-text-main">
                                                  <div className="wizard-card-checkbox-action-container">
                                                    <Checkbox
                                                      className="mt-2"
                                                      disabled={
                                                        serviceData?.product
                                                          ?.orderTypeMaster ===
                                                        orderTypeId
                                                        // || checkedState[serviceData.product.displayName]
                                                      }
                                                      checked={
                                                        checkedState[
                                                          serviceData.product
                                                            .displayName
                                                        ] ||
                                                        serviceData?.product
                                                          ?.orderTypeMaster ===
                                                          orderTypeId
                                                      }
                                                      onChange={(e) =>
                                                        handleCheckedChange(
                                                          e,
                                                          serviceData
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div className="w-full grid">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-11 p-3">
                                                      <div className="wizard-card-checkbox-text1 font-fam-for-all text-start">
                                                        <div className="flex align-items-center">
                                                          {
                                                            serviceData.product
                                                              .displayName
                                                          }
                                                          <button
                                                            className="ml-2 bg-transparent border-none cursor-pointer"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-placement="right"
                                                            title={
                                                              serviceData
                                                                .product
                                                                .productMaster
                                                                .productAdditionalInfo
                                                            }
                                                          >
                                                            <i
                                                              className="pi pi-info-circle"
                                                              style={{
                                                                fontSize:
                                                                  "1.2rem",
                                                                color:
                                                                  "#007bff",
                                                              }}
                                                            ></i>
                                                          </button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="wizard-card-checkbox-text2 justify-content-end col-12 col-sm-12 col-md-12 col-lg-1 flex align-items-center">
                                                      {serviceData.product
                                                        .unitPrice === 0
                                                        ? "Included"
                                                        : `$${parseFloat(
                                                            serviceData.product
                                                              .unitPrice
                                                          ).toFixed(2)}`}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )
                                      ) : (
                                        <>
                                          <DataTable
                                            value={items}
                                            inline
                                            emptyMessage="No Candidate Found"
                                            selectionMode="single"
                                            metaKeySelection={false}
                                          >
                                            <Column
                                              body={<Skeleton />}
                                              className="datatable-body-icon"
                                            ></Column>
                                          </DataTable>
                                        </>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>

                                {Object.keys(groupedData).map((key, i) => (
                                  <div className="tab px-4" key={i}>
                                    <div
                                      className="wizard-content-sub-card-header my-3"
                                      id="header"
                                    >
                                      <div className="wizard-content-sub-card-text-frame">
                                        <div className="wizard-content-sub-card-text">
                                          {toPascalCase(key)}
                                        </div>
                                      </div>
                                    </div>

                                    <div>
                                      {i === 0 && orderTypeId !== 5 ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Company
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="orgaineriInfoId"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={adminCompany}
                                                          options={
                                                            allAdminCompany
                                                          }
                                                          resetFilterOnHide
                                                          onChange={(e) =>
                                                            setAdminCompany(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="companyName"
                                                          placeholder="Select Company Name"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Company"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  setVisible9(true)
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div></div>
                                        </>
                                      )}
                                      {key === "Organizer_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Organizer
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="orgaineriInfoId"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={organizerData}
                                                          resetFilterOnHide
                                                          options={allOrganizer}
                                                          onChange={(e) =>
                                                            setOrganizerData(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Entity Type"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 justify-content-end align-items-center">
                                              <button
                                                className="datatable-highlight-color"
                                                onClick={() =>
                                                  handleKeyPersonal("ORGANIZER")
                                                }
                                              >
                                                <div className="flex align-items-center webapp-global-sm-size">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                  >
                                                    <path
                                                      d="M10.0003 4.16669V15.8334M4.16699 10H15.8337"
                                                      stroke="#004EEC"
                                                      stroke-width="2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                  </svg>
                                                  <div className="font-medium">
                                                    Add Member
                                                  </div>
                                                </div>
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div></div>
                                        </>
                                      )}
                                      {key === "Principle_Address" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Principal Address
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="principalAddress"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={location}
                                                          options={allLocation}
                                                          resetFilterOnHide
                                                          onChange={(e) =>
                                                            setLocation(e.value)
                                                          }
                                                          optionLabel="addressLine1"
                                                          placeholder="Select Address"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 justify-content-end align-items-center">
                                              {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("owners")}>
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Member</div>
                                                                                                </div>

                                                                                            </button> */}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {key === "Incorporator_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select InCorporator
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={incorporator}
                                                          options={
                                                            allInCorporator
                                                          }
                                                          resetFilterOnHide
                                                          onChange={(e) =>
                                                            setIncorporator(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Incorporator"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 justify-content-end align-items-center">
                                              <button
                                                className="datatable-highlight-color"
                                                onClick={() =>
                                                  handleKeyPersonal(
                                                    "INCORPORATOR"
                                                  )
                                                }
                                              >
                                                <div className="flex align-items-center webapp-global-sm-size">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                  >
                                                    <path
                                                      d="M10.0003 4.16669V15.8334M4.16699 10H15.8337"
                                                      stroke="#004EEC"
                                                      stroke-width="2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                  </svg>
                                                  <div className="font-medium">
                                                    Add Member
                                                  </div>
                                                </div>
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Director_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Director
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={director}
                                                          options={allDirector}
                                                          resetFilterOnHide
                                                          onChange={(e) =>
                                                            setDirector(e.value)
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Incorporator"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 justify-content-end align-items-center">
                                              <button
                                                className="datatable-highlight-color"
                                                onClick={() =>
                                                  handleKeyPersonal("DIRECTOR")
                                                }
                                              >
                                                <div className="flex align-items-center webapp-global-sm-size">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                  >
                                                    <path
                                                      d="M10.0003 4.16669V15.8334M4.16699 10H15.8337"
                                                      stroke="#004EEC"
                                                      stroke-width="2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                  </svg>
                                                  <div className="font-medium">
                                                    Add Member
                                                  </div>
                                                </div>
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Officer_Information" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Select Officer
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={officer}
                                                          options={allOfficer}
                                                          resetFilterOnHide
                                                          onChange={(e) =>
                                                            setOfficer(e.value)
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Incorporator"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 justify-content-end align-items-center">
                                              <button
                                                className="datatable-highlight-color"
                                                onClick={() =>
                                                  handleKeyPersonal("OFFICER")
                                                }
                                              >
                                                <div className="flex align-items-center webapp-global-sm-size">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                  >
                                                    <path
                                                      d="M10.0003 4.16669V15.8334M4.16699 10H15.8337"
                                                      stroke="#004EEC"
                                                      stroke-width="2"
                                                      stroke-linecap="round"
                                                      stroke-linejoin="round"
                                                    />
                                                  </svg>
                                                  <div className="font-medium">
                                                    Add Member
                                                  </div>
                                                </div>
                                              </button>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {key === "Registered_Agent" ? (
                                        <>
                                          <div className="grid w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                                              <div className="flex justify-content-start mb-3 ml-1">
                                                <Checkbox
                                                  checked={IsCheckedAgent}
                                                  onChange={(e) =>
                                                    setIsCheckedAgent(e.checked)
                                                  }
                                                  inputProps={{
                                                    "aria-label": "controlled",
                                                  }}
                                                />

                                                <label
                                                  htmlFor="binary"
                                                  className="font-fam-for-all ml-2 srch-cand-text"
                                                >
                                                  Select if registered agent is
                                                  vState User
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 justify-content-end align-items-center">
                                              {key === "Registered_Agent" ? (
                                                <Button
                                                  type="button"
                                                  className="mt-2   vstate-reverse-button font-fam-for-all text-center  justify-content-center"
                                                  onClick={() => nextPrev(1)}
                                                >
                                                  Skip
                                                </Button>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    {groupedData[key].mode &&
                                    groupedData[key].mode.repeat === true ? (
                                      <div>
                                        <div className="vstate-card p-4 mb-3">
                                          <div className="col-12 p-0">
                                            <h2 className="entity-header-strapi">
                                              {toPascalCase(
                                                groupedData[key].form[0].schema
                                                  .displayName
                                              )}
                                            </h2>
                                            {groupedData[key].mode &&
                                              groupedData[key].mode.repeat ===
                                                true}
                                          </div>

                                          {Array.from({
                                            length: formCount,
                                          }).map((_, l) =>
                                            groupedData[key].form.map(
                                              (value, j) => (
                                                <>
                                                  <div>
                                                    {
                                                      <div className="vstate-card p-4 mb-3">
                                                        <div
                                                          className="grid"
                                                          key={l}
                                                        >
                                                          {Object.keys(
                                                            value.schema
                                                              .attributes
                                                          ).map((val, k) => (
                                                            <div
                                                              className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                              key={k}
                                                            >
                                                              {value.schema
                                                                .attributes[val]
                                                                .type !==
                                                              undefined
                                                                ? renderFormField(
                                                                    value.schema
                                                                      .attributes[
                                                                      val
                                                                    ],
                                                                    val,
                                                                    `${val}-${l}`,
                                                                    value,
                                                                    key
                                                                  )
                                                                : ""}
                                                            </div>
                                                          ))}
                                                        </div>
                                                      </div>
                                                    }
                                                  </div>
                                                  <div className="card-devider"></div>
                                                </>
                                              )
                                            )
                                          )}
                                        </div>

                                        {groupedData[key].mode &&
                                          groupedData[key].mode.repeat ===
                                            true && (
                                            <div className="flex justify-content-center w-full">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                label="Add Entry"
                                                className="vstate-button font-fam-for-all text-center  justify-content-center"
                                                onClick={() =>
                                                  handleRepeatForm()
                                                }
                                              ></Button>
                                            </div>
                                          )}
                                      </div>
                                    ) : (
                                      groupedData[key].form.map((value, j) => (
                                        <>
                                          <div className="vstate-card p-4 mb-3">
                                            <div className="grid " key={j}>
                                              <div className="col-12 pb-0">
                                                <h2 className="entity-header-strapi">
                                                  {toPascalCase(
                                                    value.schema.displayName
                                                  )}
                                                </h2>
                                              </div>
                                              {Object.keys(
                                                value.schema.attributes
                                              ).map((val, k) => (
                                                <div
                                                  className="col-12 col-sm-12 col-md-12 col-lg-6"
                                                  key={k}
                                                >
                                                  {value.schema.attributes[val]
                                                    .type !== undefined
                                                    ? renderFormField(
                                                        value.schema.attributes[
                                                          val
                                                        ],
                                                        val,
                                                        val,
                                                        value,
                                                        key
                                                      )
                                                    : ""}
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </>
                                      ))
                                    )}
                                  </div>
                                ))}

                                {inputFormData.length > 0 ? (
                                  <>
                                    <div className="tab px-4">
                                      <div
                                        className="wizard-content-sub-card-header mb-3"
                                        id="header"
                                      >
                                        <div className="wizard-content-sub-card-text-frame">
                                          <div className="wizard-content-sub-card-text">
                                            Billing Address Information
                                          </div>
                                        </div>
                                      </div>
                                      <div className="wizard-checkoust-container hover-card slide-in">
                                        <div className="wizard-checkout-main">
                                          <div className="grid px-4  w-full justify-content-between">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                              <div className=" justify-content-center dialog-form-field-space w-full">
                                                <div className="field">
                                                  <label
                                                    htmlFor=""
                                                    className="form-label font-fam-for-all"
                                                  >
                                                    Billing Address Contact
                                                    <span className="form-field-mandatory">
                                                      *
                                                    </span>
                                                  </label>
                                                  <span className=" ">
                                                    <Controller
                                                      name="incorporator"
                                                      control={control}
                                                      render={({ field }) => (
                                                        <Dropdown
                                                          value={bilingAddress}
                                                          options={
                                                            allBilingAddress
                                                          }
                                                          resetFilterOnHide
                                                          onChange={(e) =>
                                                            setBilingAddress(
                                                              e.value
                                                            )
                                                          }
                                                          optionLabel="keyPersonnelName"
                                                          placeholder="Select Billing Address"
                                                          style={{
                                                            width: "100%",
                                                            height: "47px",
                                                          }}
                                                          filter
                                                          className="dialog-form-input-field-wizard"
                                                          required
                                                        />
                                                      )}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 justify-content-start align-items-center flex">
                                              <Button
                                                type="button"
                                                icon="pi pi-plus"
                                                style={{ fontSize: "14px" }}
                                                label="Add Billing Information"
                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                onClick={() =>
                                                  handleKeyPersonal("BILLING")
                                                }
                                              ></Button>
                                            </div>
                                          </div>
                                          <div className="grid w-full wizard-checkout-body">
                                            {bilingAddress && (
                                              <KeyPersonnelIndividualCard
                                                data={bilingAddress}
                                                getState={getState}
                                                type={"Billing Address"}
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div
                                                                                className="wizard-content-sub-card-header mb-3 mt-3"
                                                                                id="header"
                                                                            >
                                                                                <div className="wizard-content-sub-card-text-frame">
                                                                                    <div className="wizard-content-sub-card-text">
                                                                                        Shipping Address Contact
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizard-checkoust-container hover-card slide-in">
                                                                                <div className="wizard-checkout-main">
                                                                                    <div className='grid justify-content-start ml-4 mt-4 mb-3'>
                                                                                        <div className=' p-0'>
                                                                                            <div className='flex justify-content-center'>
                                                                                                <Checkbox
                                                                                                    checked={IsCheckedAgent}
                                                                                                    onChange={(e) => {
                                                                                                        setIsCheckedAgent(e.checked)
                                                                                                    }}
                                                                                                    inputProps={{ 'aria-label': 'controlled' }} />

                                                                                                <label htmlFor="binary" className='font-fam-for-all ml-2 srch-cand-text' >Same as Billing address
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="grid px-4  w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Shipping Address
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className=" ">
                                                                                                        <Controller
                                                                                                            name="incorporator"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={shippingAddress}
                                                                                                                    options={allShippingAddress}
                                                                                                                    onChange={(e) => setShippingAddress(e.value)}
                                                                                                                    disabled={IsCheckedAgent ? true : false}
                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                    placeholder="Select Billing Address"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex" >
                                                                                        
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Shipping Address"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => handleKeyPersonal("SHIPPING")}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className="wizard-checkout-body">
                                                                                        {IsCheckedAgent ? <AddressTemplate data={bilingAddress} /> : <AddressTemplate data={shippingAddress} />}
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                    </div>

                                    <div class="tab px-4">
                                      <div
                                        className="wizard-content-sub-card-header mb-3"
                                        id="header"
                                      >
                                        <div className="wizard-content-sub-card-text-frame">
                                          <div className="wizard-content-sub-card-text">
                                            To comply with state filling
                                            requirements, please provide
                                            following details
                                          </div>
                                        </div>
                                      </div>

                                      {/* {previewData ? <div className="min-h-screen bg-gray-50 py-5  flex justify-center items-start">
                                                                                <div className="w-full max-w-5xl ">
                                                                                    <div className="bg-white border border-gray-200 rounded-lg shadow-lg p-8">
                                                                                        <h1 className="text-3xl font-semibold text-gray-800 text-center mb-10" style={{ fontFamily: 'Inter' }}>
                                                                                            Order Preview
                                                                                        </h1>
                                                                                        <div className="space-y-8">
                                                                                            

                                                                                            {Object.keys(previewData).map((sectionKey, index) => (
                                                                                                <div key={index} className="border border-gray-300 rounded-lg shadow-sm bg-white">
                                                                                                    <div
                                                                                                        className="px-4 py-2 rounded-t-lg"
                                                                                                        style={{ backgroundColor: '#F0F5FF', fontFamily: 'Inter', fontSize: '16px' }}
                                                                                                    >
                                                                                                        <h2 className="text-lg text-gray-800">{convertToTitleCase(sectionKey)}</h2>
                                                                                                    </div>

                                                                                                    <div className="p-6 grid grid-cols-12 gap-6">
                                                                                                        {Array.isArray(previewData[sectionKey]) ? (
                                                                                                            previewData[sectionKey].map((item, idx) => (
                                                                                                                <div key={idx} className="col-span-12">

                                                                                                                    <div
                                                                                                                        className="px-4 py-2 rounded-t-lg"
                                                                                                                        style={{ backgroundColor: '#F0F5FF', fontFamily: 'Inter', fontSize: '16px' }}
                                                                                                                    >
                                                                                                                        <h2 className="text-lg text-gray-800"> {convertToTitleCase(sectionKey)} </h2>
                                                                                                                    </div>
                                                                                                                    <RecursiveRender data={item} parentKey={sectionKey} />
                                                                                                                </div>
                                                                                                            ))
                                                                                                        ) : typeof previewData[sectionKey] === 'object' ? (
                                                                                                            Object.keys(previewData[sectionKey]).map((key, i) => (
                                                                                                                <div key={i} className="col-span-4">
                                                                                                                    {typeof previewData[sectionKey][key] === 'object' ? (
                                                                                                                        <div className="border border-gray-300 rounded-lg shadow-sm bg-white">
                                                                                                                            <div
                                                                                                                                className="px-4 py-2 rounded-t-lg"
                                                                                                                                style={{ backgroundColor: '#F0F5FF', fontFamily: 'Inter', fontSize: '16px' }}
                                                                                                                            >
                                                                                                                                <h2 className="text-lg text-gray-800">{convertToTitleCase(key)}</h2>
                                                                                                                            </div>
                                                                                                                            <RecursiveRender data={previewData[sectionKey][key]} parentKey={key} />
                                                                                                                        </div>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <div
                                                                                                                                className="font-medium text-gray-700 mb-1"
                                                                                                                                style={{ fontFamily: 'Inter', fontSize: '14px' }}
                                                                                                                            >
                                                                                                                                {convertName(key)}
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                className="text-gray-900"
                                                                                                                                style={{ fontFamily: 'Inter', fontSize: '14px' }}
                                                                                                                            >
                                                                                                                                {previewData[sectionKey][key]}
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            ))
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <div
                                                                                                                    className="font-medium text-gray-700 mb-1"
                                                                                                                    style={{ fontFamily: 'Inter', fontSize: '14px' }}
                                                                                                                >
                                                                                                                    {convertName(sectionKey)}
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className="text-gray-900"
                                                                                                                    style={{ fontFamily: 'Inter', fontSize: '14px' }}
                                                                                                                >
                                                                                                                    {previewData[sectionKey]}
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))}


                                                                                            <div className="border border-gray-300 rounded-lg shadow-sm bg-white">

                                                                                                <div className="px-4 py-2 rounded-t-lg" style={{ backgroundColor: '#F0F5FF', fontFamily: 'Inter', fontSize: '16px' }}>
                                                                                                    <h2 className="text-lg  text-gray-800">Billing Address</h2>
                                                                                                </div>

                                                                                                <div className="p-6 grid grid-cols-12 ">
                                                                                                    {bilingAddress ?
                                                                                                        <>
                                                                                                            <div className="col-4">

                                                                                                                <div className="font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Address Line 1
                                                                                                                </div>

                                                                                                                <div className="text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {bilingAddress.addressLine1}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-4">

                                                                                                                <div className="font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Address Line2
                                                                                                                </div>

                                                                                                                <div className="text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {bilingAddress.addressLine2}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-4">

                                                                                                                <div className="font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    City
                                                                                                                </div>

                                                                                                                <div className="text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {bilingAddress.city}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-4">

                                                                                                                <div className="font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    State
                                                                                                                </div>

                                                                                                                <div className="text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {getState(bilingAddress.stateId)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-4">

                                                                                                                <div className="font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Country
                                                                                                                </div>

                                                                                                                <div className="text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-4">

                                                                                                                <div className="font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Zip Code
                                                                                                                </div>

                                                                                                                <div className="text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {bilingAddress.postalCode}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>

                                                                                                        : <>
                                                                                                            <div>No billing address found</div>
                                                                                                        </>}
                                                                                                </div>
                                                                                            </div>




                                                                                            <div className="border border-gray-300 rounded-lg shadow-sm bg-white">

                                                                                                <div className="px-4 py-2 rounded-t-lg" style={{ backgroundColor: '#F0F5FF', fontFamily: 'Inter', fontSize: '16px' }}>
                                                                                                    <h2 className="text-lg  text-gray-800">Shipping Address</h2>
                                                                                                </div>

                                                                                                <div className="p-6 grid grid-cols-12 ">
                                                                                                    {shippingAddress ?
                                                                                                        <>
                                                                                                            <div className="col-4">

                                                                                                                <div className="font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Address Line 1
                                                                                                                </div>

                                                                                                                <div className="text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {shippingAddress.addressLine1}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-4">

                                                                                                                <div className="font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Address Line2
                                                                                                                </div>

                                                                                                                <div className="text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {shippingAddress.addressLine2}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-4">

                                                                                                                <div className="font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    City
                                                                                                                </div>

                                                                                                                <div className="text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {shippingAddress.city}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-4">

                                                                                                                <div className="font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    State
                                                                                                                </div>

                                                                                                                <div className="text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {getState(shippingAddress.stateId)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-4">

                                                                                                                <div className="font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Country
                                                                                                                </div>

                                                                                                                <div className="text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-4">

                                                                                                                <div className="font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Zip Code
                                                                                                                </div>

                                                                                                                <div className="text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {shippingAddress.postalCode}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>

                                                                                                        : <>
                                                                                                            <div>No shipping address found</div>
                                                                                                        </>}
                                                                                                </div>
                                                                                            </div>



                                                                                            <div className="border border-gray-300 rounded-lg shadow-sm bg-white">

                                                                                                <div className="px-4 py-2 rounded-t-lg" style={{ backgroundColor: '#F0F5FF', fontFamily: 'Inter', fontSize: '16px' }}>
                                                                                                    <h2 className="text-lg  text-gray-800">Selected Services</h2>
                                                                                                </div>

                                                                                                <div className="p-6 space-y-4">
                                                                                                    {selectedCheckBoxData.map((service, index) => (
                                                                                                        <div key={index} className="border border-gray-200 rounded-md p-4">
                                                                                                            <h3 className="text-md font-semibold" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                {service.service}
                                                                                                            </h3>
                                                                                                            <p className="text-gray-700 mt-2" style={{ fontFamily: 'Inter', fontSize: '12px' }}>
                                                                                                                {service.description}
                                                                                                            </p>
                                                                                                            <div className="text-right mt-4 font-bold text-gray-800" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                {service.amount}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> : <>
                                                                                <div>Data not found</div>
                                                                            </>} */}

                                      <div className="flex p-4 justify-between font-fam-for-all">
                                        <div className="datatable-highlight-color">
                                          {enityName
                                            ? enityName.entityFullDesc
                                            : ""}
                                        </div>
                                        <div className="datatable-highlight-color">
                                          {state ? state.stateFullDesc : ""}
                                          <span className="ml-2">
                                            {county &&
                                              `(${county?.countyName})`}
                                          </span>
                                        </div>
                                      </div>

                                      {previewData ? (
                                        <div className="min-h-screen bg-gray-50  flex justify-center items-start">
                                          <div className="w-full ">
                                            <div className="bg-white py-2">
                                              {/* <h1 className="text-3xl font-semibold text-gray-800 text-center mb-10" style={{ fontFamily: 'Inter' }}>
                                                                                            Order Preview
                                                                                        </h1> */}
                                              <div className="space-y-8">
                                                {/* {Object.keys(previewData).map((sectionKey, index) => (
                                                                                                <div key={index} className="bg-white">
                                                                                                    <div
                                                                                                        className="px-4 py-2 "
                                                                                                        style={{ backgroundColor: '#F9FAFB', fontFamily: 'Inter', fontSize: '16px' }}
                                                                                                    >
                                                                                                        <h2 className="text-lg text-gray-800">{convertToTitleCase(sectionKey)}</h2>
                                                                                                    </div>

                                                                                                    <div className="p-6 grid grid-cols-12 gap-6">
                                                                                                        {Array.isArray(previewData[sectionKey]) ? (
                                                                                                            previewData[sectionKey].map((item, idx) => (
                                                                                                                <div key={idx} className="col-span-12">

                                                                                                                    <div
                                                                                                                        className="px-4 py-2 rounded-t-lg"
                                                                                                                        style={{ backgroundColor: '#F9FAFB', fontFamily: 'Inter', fontSize: '16px' }}
                                                                                                                    >
                                                                                                                        <h2 className="text-lg text-gray-800"> {convertToTitleCase(sectionKey)} </h2>
                                                                                                                    </div>
                                                                                                                    <RecursiveRender data={item} parentKey={sectionKey} />
                                                                                                                </div>
                                                                                                            ))
                                                                                                        ) : typeof previewData[sectionKey] === 'object' ? (
                                                                                                            Object.keys(previewData[sectionKey]).map((key, i) => (
                                                                                                                <div key={i} className="col-span-4">
                                                                                                                    {typeof previewData[sectionKey][key] === 'object' ? (
                                                                                                                        <div className="border border-gray-300 rounded-lg shadow-sm bg-white">
                                                                                                                            <div
                                                                                                                                className="px-4 py-2"
                                                                                                                                style={{ backgroundColor: '#F9FAFB', fontFamily: 'Inter', fontSize: '16px' }}
                                                                                                                            >
                                                                                                                                <h2 className="text-lg text-gray-800">{convertToTitleCase(key)}</h2>
                                                                                                                            </div>
                                                                                                                            <RecursiveRender data={previewData[sectionKey][key]} parentKey={key} />
                                                                                                                        </div>
                                                                                                                    ) : (
                                                                                                                        <>
                                                                                                                            <div
                                                                                                                                className="font-medium text-gray-700 mb-1"
                                                                                                                                style={{ fontFamily: 'Inter', fontSize: '14px' }}
                                                                                                                            >
                                                                                                                                {convertName(key)}
                                                                                                                            </div>
                                                                                                                            <div
                                                                                                                                className="text-gray-900"
                                                                                                                                style={{ fontFamily: 'Inter', fontSize: '14px' }}
                                                                                                                            >
                                                                                                                                {previewData[sectionKey][key]}
                                                                                                                            </div>
                                                                                                                        </>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            ))
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                <div
                                                                                                                    className="font-medium text-gray-700 mb-1"
                                                                                                                    style={{ fontFamily: 'Inter', fontSize: '14px' }}
                                                                                                                >
                                                                                                                    {convertName(sectionKey)}
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className="text-gray-900"
                                                                                                                    style={{ fontFamily: 'Inter', fontSize: '14px' }}
                                                                                                                >
                                                                                                                    {previewData[sectionKey]}
                                                                                                                </div>
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            ))} */}

                                                <div className="bg-white">
                                                  <div
                                                    className="px-4 py-2 rounded-t-lg"
                                                    style={{
                                                      backgroundColor:
                                                        "#F9FAFB",
                                                      fontFamily: "Inter",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    <h2 className="text-lg  text-gray-800">
                                                      Selected Services
                                                    </h2>
                                                  </div>

                                                  <div className="p-4 space-y-4">
                                                    {selectedCheckBoxData.map(
                                                      (service, index) => (
                                                        <div
                                                          key={index}
                                                          className="border grid border-gray-200 rounded-md p-2"
                                                        >
                                                          <div className="col-10">
                                                            <h3
                                                              className="text-md font-semibold"
                                                              style={{
                                                                fontFamily:
                                                                  "Inter",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            >
                                                              {
                                                                service.product
                                                                  .displayName
                                                              }
                                                            </h3>
                                                            <p
                                                              className="text-gray-700 mt-2"
                                                              style={{
                                                                fontFamily:
                                                                  "Inter",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            >
                                                              {/* {service.product.productMaster.additionalInfo} */}
                                                            </p>
                                                          </div>
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                                                            <div
                                                              className="text-right  font-bold text-blue-800"
                                                              style={{
                                                                fontFamily:
                                                                  "Inter",
                                                                fontSize:
                                                                  "14px",
                                                              }}
                                                            >
                                                              {service.product
                                                                .unitPrice === 0
                                                                ? "Included"
                                                                : `$${parseFloat(
                                                                    service
                                                                      .product
                                                                      .unitPrice
                                                                  ).toFixed(
                                                                    2
                                                                  )}`}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </div>

                                                {Object.keys(previewData).map(
                                                  (sectionKey, index) => (
                                                    <>
                                                      {!keyPersonalMemberData.includes(
                                                        sectionKey
                                                      ) ? (
                                                        <>
                                                          <div
                                                            key={index}
                                                            className="bg-white"
                                                          >
                                                            <div
                                                              className="px-4 py-2"
                                                              style={{
                                                                backgroundColor:
                                                                  "#F9FAFB",
                                                                fontFamily:
                                                                  "Inter",
                                                                fontSize:
                                                                  "16px",
                                                              }}
                                                            >
                                                              <h2 className="text-lg text-gray-800">
                                                                {convertToTitleCase(
                                                                  sectionKey
                                                                )}
                                                              </h2>
                                                            </div>

                                                            <div className="px-4 py-4">
                                                              {/* Render Key-Value Pair for Each Section */}
                                                              {Array.isArray(
                                                                previewData[
                                                                  sectionKey
                                                                ]
                                                              ) ? (
                                                                // Handling Array Data as Key-Value Pairs
                                                                previewData[
                                                                  sectionKey
                                                                ].map(
                                                                  (
                                                                    item,
                                                                    idx
                                                                  ) => (
                                                                    <div
                                                                      key={idx}
                                                                      className=""
                                                                    >
                                                                      <h3 className="text-lg text-gray-800 mb-2">
                                                                        {convertToTitleCase(
                                                                          sectionKey
                                                                        )}{" "}
                                                                        {idx +
                                                                          1}
                                                                      </h3>
                                                                      {Object.keys(
                                                                        item
                                                                      ).map(
                                                                        (
                                                                          itemKey
                                                                        ) => (
                                                                          <div
                                                                            className="grid gap-3 mb-2"
                                                                            key={
                                                                              itemKey
                                                                            }
                                                                          >
                                                                            {/* Display each key-value pair for array item */}
                                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-2 order-preview-key">
                                                                              {convertName(
                                                                                itemKey
                                                                              )}
                                                                            </div>
                                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-preview-value">
                                                                              {
                                                                                item[
                                                                                  itemKey
                                                                                ]
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                        )
                                                                      )}
                                                                      {/* Divider between array items */}
                                                                      <div className="border-t border-gray-300 my-4"></div>
                                                                    </div>
                                                                  )
                                                                )
                                                              ) : previewData[
                                                                  sectionKey
                                                                ] !== null &&
                                                                typeof previewData[
                                                                  sectionKey
                                                                ] ===
                                                                  "object" ? (
                                                                // Handling Object Data as Key-Value Pairs
                                                                Object.keys(
                                                                  previewData[
                                                                    sectionKey
                                                                  ]
                                                                ).map(
                                                                  (key, i) => (
                                                                    <div
                                                                      key={i}
                                                                      className=""
                                                                    >
                                                                      {previewData[
                                                                        sectionKey
                                                                      ][key] !==
                                                                        null &&
                                                                      typeof previewData[
                                                                        sectionKey
                                                                      ][key] ===
                                                                        "object" ? (
                                                                        <div>
                                                                          <h3 className="text-lg text-gray-800 mb-2">
                                                                            {convertToTitleCase(
                                                                              key
                                                                            )}
                                                                          </h3>

                                                                          {/* Recursive Rendering for Nested Objects */}
                                                                          {Object.keys(
                                                                            previewData[
                                                                              sectionKey
                                                                            ][
                                                                              key
                                                                            ]
                                                                          ).map(
                                                                            (
                                                                              nestedKey
                                                                            ) => (
                                                                              <div
                                                                                key={
                                                                                  nestedKey
                                                                                }
                                                                                className="grid gap-3 mb-2"
                                                                              >
                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 order-preview-key">
                                                                                  {convertName(
                                                                                    nestedKey
                                                                                  )}
                                                                                </div>
                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-preview-value">
                                                                                  {
                                                                                    previewData[
                                                                                      sectionKey
                                                                                    ][
                                                                                      key
                                                                                    ][
                                                                                      nestedKey
                                                                                    ]
                                                                                  }
                                                                                </div>
                                                                              </div>
                                                                            )
                                                                          )}

                                                                          {/* Divider for Nested Data */}
                                                                          <div className="border-t border-gray-300 my-4"></div>
                                                                        </div>
                                                                      ) : (
                                                                        <div className="grid gap-3 mb-2">
                                                                          {/* Key-Value Pair for Flat Object */}
                                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-2 order-preview-key">
                                                                            {convertName(
                                                                              key
                                                                            )}
                                                                          </div>
                                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-preview-value">
                                                                            {
                                                                              previewData[
                                                                                sectionKey
                                                                              ][
                                                                                key
                                                                              ]
                                                                            }
                                                                          </div>
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  )
                                                                )
                                                              ) : (
                                                                // Single Key-Value Pair if the data is neither object nor array
                                                                <div className="grid gap-3 mb-2">
                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-2 order-preview-key">
                                                                    {convertName(
                                                                      sectionKey
                                                                    )}
                                                                  </div>
                                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 order-preview-value">
                                                                    {
                                                                      previewData[
                                                                        sectionKey
                                                                      ]
                                                                    }
                                                                  </div>
                                                                </div>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </>
                                                  )
                                                )}

                                                <div className="bg-white">
                                                  <div
                                                    className="px-4 py-2"
                                                    style={{
                                                      backgroundColor:
                                                        "#F9FAFB",
                                                      fontFamily: "Inter",
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    <h2 className="text-lg  text-gray-800">
                                                      Billing Address
                                                      Information
                                                    </h2>
                                                  </div>

                                                  <div className="p-4 ">
                                                    <div className="grid gap-2 justify-content-start">
                                                      {bilingAddress ? (
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                                                          <div className="order-contact-card-container">
                                                            <div className="order-contact-card-main">
                                                              <div className="order-contact-card-header-main">
                                                                <Avatar
                                                                  label={commonGetInitials(
                                                                    bilingAddress.keyPersonnelName
                                                                  )}
                                                                  icon="pi pi-user"
                                                                  size="large"
                                                                  shape="circle"
                                                                  className="custom-avatar"
                                                                />

                                                                <div>
                                                                  <div className="order-contact-card-header-text1">
                                                                    {
                                                                      bilingAddress.keyPersonnelName
                                                                    }
                                                                  </div>
                                                                  <div className="order-contact-card-header-text2">
                                                                    {IsCheckedAgent
                                                                      ? "Billing Contact"
                                                                      : "Billing Contact"}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="card-devider">
                                                                {" "}
                                                              </div>
                                                              <div className="order-contact-card-text-main">
                                                                <div>
                                                                  <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20"
                                                                    height="20"
                                                                    viewBox="0 0 20 20"
                                                                    fill="none"
                                                                  >
                                                                    <g clip-path="url(#clip0_966_10329)">
                                                                      <path
                                                                        d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z"
                                                                        stroke="#175CD3"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                      />
                                                                    </g>
                                                                    <defs>
                                                                      <clipPath id="clip0_966_10329">
                                                                        <rect
                                                                          width="20"
                                                                          height="20"
                                                                          fill="white"
                                                                        />
                                                                      </clipPath>
                                                                    </defs>
                                                                  </svg>
                                                                </div>
                                                                <div className="order-contact-card-text1">
                                                                  {
                                                                    bilingAddress.contactNo
                                                                  }
                                                                </div>
                                                              </div>

                                                              <div className="order-contact-card-text-main">
                                                                <div>
                                                                  <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20"
                                                                    height="20"
                                                                    viewBox="0 0 20 20"
                                                                    fill="none"
                                                                  >
                                                                    <path
                                                                      d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004"
                                                                      stroke="#175CD3"
                                                                      stroke-width="2"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                    />
                                                                  </svg>
                                                                </div>
                                                                <div className="order-contact-card-text1">
                                                                  {
                                                                    bilingAddress.emailId
                                                                  }
                                                                </div>
                                                              </div>

                                                              <div className="order-contact-card-text-main">
                                                                <div>
                                                                  <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20"
                                                                    height="20"
                                                                    viewBox="0 0 20 20"
                                                                    fill="none"
                                                                  >
                                                                    <path
                                                                      d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z"
                                                                      stroke="#175CD3"
                                                                      stroke-width="1.66667"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                      d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z"
                                                                      stroke="#175CD3"
                                                                      stroke-width="1.66667"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                    />
                                                                  </svg>
                                                                </div>
                                                                <div className="order-contact-card-text1 text-wrap">
                                                                  {bilingAddress
                                                                    .addressMasterId
                                                                    .addressLine1 !==
                                                                  ""
                                                                    ? `${
                                                                        bilingAddress
                                                                          .addressMasterId
                                                                          .addressLine1
                                                                      },${
                                                                        bilingAddress
                                                                          .addressMasterId
                                                                          .addressLine2
                                                                          ? `${bilingAddress.addressMasterId.addressLine2},`
                                                                          : ""
                                                                      }${
                                                                        bilingAddress
                                                                          .addressMasterId
                                                                          .city
                                                                      },${getState(
                                                                        bilingAddress
                                                                          .addressMasterId
                                                                          .stateId
                                                                      )},${
                                                                        bilingAddress
                                                                          .addressMasterId
                                                                          .postalCode
                                                                      }`
                                                                    : ""}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}

                                                      {/* {
                                                                                                            shippingAddress ? <div className='col-5'>

                                                                                                                <div className='order-contact-card-container'>
                                                                                                                    <div className='order-contact-card-main'>
                                                                                                                        <div className='order-contact-card-header-main'>

                                                                                                                            <Avatar label={commonGetInitials(shippingAddress.keyPersonnelName)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                                                            <div>
                                                                                                                                <div className='order-contact-card-header-text1'>
                                                                                                                                    {shippingAddress.keyPersonnelName}
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-header-text2'>
                                                                                                                                    Shipping Contact
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                        <div className='card-devider'> </div>
                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <g clip-path="url(#clip0_966_10329)">
                                                                                                                                        <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </g>
                                                                                                                                    <defs>
                                                                                                                                        <clipPath id="clip0_966_10329">
                                                                                                                                            <rect width="20" height="20" fill="white" />
                                                                                                                                        </clipPath>
                                                                                                                                    </defs>
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1'>
                                                                                                                                {shippingAddress.contactNo}
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1'>
                                                                                                                                {shippingAddress.emailId}
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1 text-wrap'>
                                                                                                                                {shippingAddress.addressMasterId.addressLine1 !== "" ? `${shippingAddress.addressMasterId.addressLine1},${shippingAddress.addressMasterId.addressLine2 ? `${shippingAddress.addressMasterId.addressLine2},` : ""}${shippingAddress.addressMasterId.city},${getState(shippingAddress.addressMasterId.stateId)},${shippingAddress.addressMasterId.postalCode}` : ""}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""
                                                                                                        } */}
                                                    </div>
                                                  </div>
                                                </div>

                                                {/* <div className="bg-white">

                                                                                                <div className="px-4 py-2" style={{ backgroundColor: '#F9FAFB', fontFamily: 'Inter', fontSize: '16px' }}>
                                                                                                    <h2 className="text-lg  text-gray-800">Billing Address</h2>
                                                                                                </div>

                                                                                                <div className="p-4 ">
                                                                                                    {bilingAddress ?
                                                                                                        <>
                                                                                                            <div className="grid">

                                                                                                                <div className="col-2 font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Address Line 1
                                                                                                                </div>

                                                                                                                <div className="col-6 text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {bilingAddress.addressLine1}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="grid">

                                                                                                                <div className="col-2 font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Address Line2
                                                                                                                </div>

                                                                                                                <div className="col-6 text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {bilingAddress.addressLine2}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="grid">

                                                                                                                <div className="col-2 font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    City
                                                                                                                </div>

                                                                                                                <div className="col-6 text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {bilingAddress.city}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="grid">

                                                                                                                <div className="col-2 font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    State
                                                                                                                </div>

                                                                                                                <div className="col-6 text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {getState(bilingAddress.stateId)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="grid">

                                                                                                                <div className="col-2 font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Country
                                                                                                                </div>

                                                                                                                <div className="col-6 text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {getCountry(bilingAddress.countryId)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="grid">

                                                                                                                <div className="col-2 font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Zip Code
                                                                                                                </div>

                                                                                                                <div className="col-6 text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {bilingAddress.postalCode}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>

                                                                                                        : <>
                                                                                                            <div>No billing address found</div>
                                                                                                        </>}
                                                                                                </div>
                                                                                            </div>




                                                                                            <div className=" bg-white">

                                                                                                <div className="px-4 py-2" style={{ backgroundColor: '#F9FAFB', fontFamily: 'Inter', fontSize: '16px' }}>
                                                                                                    <h2 className="text-lg  text-gray-800">Shipping Address</h2>
                                                                                                </div>

                                                                                                <div className="p-4 ">
                                                                                                    {shippingAddress ?
                                                                                                        <>
                                                                                                            <div className="grid">

                                                                                                                <div className="col-2 font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Address Line 1
                                                                                                                </div>

                                                                                                                <div className="col-6 text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {shippingAddress.addressLine1}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="grid">

                                                                                                                <div className="col-2 font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Address Line2
                                                                                                                </div>

                                                                                                                <div className="col-6 text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {shippingAddress.addressLine2}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="grid">

                                                                                                                <div className="col-2 font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    City
                                                                                                                </div>

                                                                                                                <div className="col-6 text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {shippingAddress.city}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="grid">

                                                                                                                <div className="col-2 font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    State
                                                                                                                </div>

                                                                                                                <div className="col-6 text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {getState(shippingAddress.stateId)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="grid">

                                                                                                                <div className="col-2 font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Country
                                                                                                                </div>

                                                                                                                <div className="col-6 text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {getCountry(shippingAddress.countryId)}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="grid">

                                                                                                                <div className="col-2 font-medium text-gray-700 mb-1" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    Zip Code
                                                                                                                </div>

                                                                                                                <div className="col-6 text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {shippingAddress.postalCode}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>

                                                                                                        : <>
                                                                                                            <div>No shipping address found</div>
                                                                                                        </>}
                                                                                                </div>
                                                                                            </div> */}

                                                <div className="px-4">
                                                  <PaymentOptions />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          <div>Data not found</div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                <div className="flex flex-col justify-content-end gap-2 px-4">
                                  {/* {
                                                                        key === "Registered_Agent" ? <Button type='button' id="nextBtn" className="mt-2   vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => nextPrev(1)} >Next</Button>
                                                                            : ""
                                                                    } */}
                                  <Button
                                    type="button"
                                    id="nextBtn"
                                    disabled={showSkeleton ? false : true}
                                    className="mt-2   vstate-button font-fam-for-all text-center  justify-content-center"
                                    onClick={() => nextPrev(1)}
                                  >
                                    Save & Next
                                  </Button>
                                  <Button
                                    type="button"
                                    id="submitBtn"
                                    className="mt-2   vstate-button font-fam-for-all text-center justify-content-center"
                                    onClick={(e) => {
                                      handleSubmit(onSubmit, onInvalid)(e);
                                      handleScroll();
                                    }}
                                  >
                                    Checkout
                                  </Button>
                                  <Button
                                    type="button"
                                    id="prevBtn"
                                    onClick={() => nextPrev(-1)}
                                    className="mt-2 vstate-reverse-button   font-fam-for-all text-center justify-content-center"
                                  >
                                    Back
                                  </Button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 flex flex-column gap-2">
              <div className="main-cart-summary">
                <div className=" font-fam-for-all">
                  <div className="wizard-checkout-container">
                    <div className="wizard-checkout-main">
                      <div className="wizard-checkout-header justify-content-between">
                        <div className="wizard-checkout-text-main">
                          <div className="wizard-checkout-text">
                            CART SUMMARY
                          </div>
                        </div>
                      </div>

                      {/* <div className="wizard-checkout-body">

                                            <React.Fragment >
                                                <div className="wizard-checkout-content-main">
                                                    {selectedCheckBoxData.length > 0 ? selectedCheckBoxData.map((val, index) => (
                                                        <div className="wizard-checkout-content-price-main" key={index}>
                                                            <div className="wizard-price-content1">
                                                                {val.displayName}
                                                            </div>
                                                            <div className="wizard-price-content2">
                                                                ${val.unitPrice}
                                                            </div>
                                                        </div>
                                                    )) : <>
                                                        <div className="wizard-price-content1 flex justify-content-center">No items added</div>
                                                    </>}
                                                </div>

                                    
                                            </React.Fragment>

                                        </div>

                                        <div className="wizard-checkout-footer">
                                            <div className="wizard-checkout-text-footer">
                                                <div className="wizard-checkout-footer-txt">
                                                    TOTAL (TAX DEDUCTIBLE)
                                                </div>
                                            </div>
                                            <div className="wizard-total-price">
                                                {`$${totalAmount}`}
                                            </div>
                                        </div> */}

                      <div className="wizard-checkout-body">
                        <React.Fragment>
                          <div className="wizard-checkout-content-main">
                            {selectedCheckBoxData.length > 0 ? (
                              selectedCheckBoxData
                                .sort(
                                  (a, b) =>
                                    a.product.displayPreference -
                                    b.product.displayPreference
                                )
                                .map((val, index) => {
                                  const baseProduct = val.product.baseProduct;
                                  const isPriceExcluded =
                                    val.product.isPriceIncludedInBaseProduct;
                                  let productPrice = parseFloat(
                                    val.product.unitPrice
                                  ).toFixed(2);

                                  // Handle bundle pricing
                                  // if (val.isBundlePriceApplicable) {
                                  //     const additionalItems = Math.max(0, selectedQuantity - val.noOfItemsInBundle);
                                  //     productPrice = (val.priceOfBundle * val.noOfItemsInBundle) +
                                  //         (additionalItems * val.priceForAddlItemsOverBundle);
                                  // }

                                  return (
                                    <>
                                      {!baseProduct && (
                                        <div className="wizard-checkout-content-price-main">
                                          <div className="wizard-price-content1">
                                            {val.product.displayName}
                                          </div>
                                          <div className="wizard-price-content2">
                                            {!isPriceExcluded
                                              ? `$${parseFloat(
                                                  productPrice
                                                ).toFixed(2)}`
                                              : "Included"}
                                          </div>
                                        </div>
                                      )}

                                      {baseProduct && (
                                        <div className="wizard-checkout-content-price-main ml-4">
                                          <div className="wizard-price-content1">
                                            {val.product.displayName}
                                          </div>
                                          <div className="wizard-price-content2">
                                            {!isPriceExcluded
                                              ? `$${parseFloat(
                                                  productPrice
                                                ).toFixed(2)}`
                                              : `Included under ${baseProduct.displayName}`}
                                          </div>
                                        </div>
                                        // <div className="ml-4 text-muted">
                                        //     Included under {baseProduct.displayName}
                                        // </div>
                                      )}
                                    </>
                                  );
                                })
                            ) : (
                              <div className="wizard-price-content1 flex justify-content-center">
                                No items added
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      </div>

                      <div className="wizard-checkout-footer">
                        <div className="wizard-checkout-text-footer">
                          <div className="wizard-checkout-footer-txt">
                            TOTAL (TAX DEDUCTIBLE)
                          </div>
                        </div>
                        <div className="wizard-total-price">
                          {`$${calculateTotalAmount()}`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AddCity
              visible={visible}
              setVisible={setVisible}
              credit={creditInfo}
              llc={llc}
              state={state}
              enityName={enityName}
              llcDetails={llcName}
              modelData={modelData}
            />
            <AddCompany
              visible={visible9}
              setVisible={setVisible9}
              pageRefresh={pageRefresh}
              setPageRefresh={setPageRefresh}
            />

            <AddCompanyMember
              visible={visible2}
              setVisible={setVisible2}
              pageRefresh={pageRefresh}
              setPageRefresh={setPageRefresh}
              memberType={memberType}
              data={keyPersonalCompany}
              keyPersonalAddressType={keyPersonalAddressType}
              handlers={handlers}
              state={state}
            />

            <AddCompanyLocation
              companyId={globalCompany ? globalCompany.companyDetails : null}
              visible={visible3}
              setVisible={setVisible3}
            />

            {/* <Dialog
                            visible={closeProgresbar1}
                            modal={false}
                            onHide={() => setCloseProgresbar1(false)}
                            className="font-fam-for-all"
                        >
                           
                            <PaymentFailure setCloseProgresbar1={setCloseProgresbar1}/>
                        </Dialog> */}
            {/* 
                        <CustomModal visible={closeProgresbar1} onHide={() => setCloseProgresbar1(false)}>
                            <PaymentFailure setCloseProgresbar1={setCloseProgresbar1} />
                        </CustomModal> */}

            {closeProgresbar2 && (
              <Dialog
                className="payment-dialog rounded-lg"
                visible={closeProgresbar2}
                onHide={() => setCloseProgresbar2(false)}
              >
                <section className="flex overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                  <div className="payment-icon-confirm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="24"
                      viewBox="0 0 14 24"
                      fill="none"
                    >
                      <path
                        d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1"
                        stroke="#039855"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                    <header className="flex flex-col w-full max-md:max-w-full">
                      <h2 className="text-lg font-medium leading-loose text-gray-900 max-md:max-w-full">
                        Pay Later
                      </h2>
                      <p className="mt-2 text-sm leading-5 text-gray-500 w-[500px] text-wrap">
                        You have selected the <strong>Pay Later</strong> option.
                        Your order will be received, and upon approval from
                        vState Filings, your order will be submitted.
                      </p>
                    </header>
                    <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                      <div className="flex gap-3 items-start justify-content-end self-stretch my-auto min-w-[240px]">
                        <Button
                          className="overflow-hidden gap-2 self-stretch px-5 py-2.5 rounded-lg border border-solid shadow-sm bg-white text-gray-700 border-gray-300"
                          label="Cancel"
                          onClick={() => setCloseProgresbar2(false)}
                        />
                        <Button
                          className="overflow-hidden gap-2 self-stretch px-5 py-2.5 rounded-lg border border-solid shadow-sm bg-blue-600 text-white border-blue-600"
                          label="Submit for Approval"
                          onClick={handleConfirm}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </Dialog>
            )}
            {closeProgresbar3 && (
              <Dialog
                className="payment-dialog rounded-lg"
                visible={closeProgresbar3}
                onHide={() => setCloseProgresbar3(false)}
              >
                <section className="flex rounded-lg overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                  <div className="payment-icon-confirm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="24"
                      viewBox="0 0 14 24"
                      fill="none"
                    >
                      <path
                        d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1"
                        stroke="#039855"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                    <header className="flex flex-col w-full max-md:max-w-full">
                      <h2 className="text-lg font-medium leading-loose text-gray-900 max-md:max-w-full">
                        Pay Now
                      </h2>
                      <p className="mt-2 text-sm leading-5 text-gray-500 w-[500px] text-wrap">
                        You selected the <strong>Pay Now</strong> option. Please
                        complete your payment to finalize your order.
                      </p>
                    </header>
                    <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                      <div className="flex justify-content-end gap-3 items-start self-stretch my-auto min-w-[240px]">
                        <Button
                          className="vstate-reverse-button"
                          label="Cancel"
                          onClick={() => setCloseProgresbar3(false)}
                        />
                        <Button
                          className="vstate-button"
                          label="Proceed to payment"
                          onClick={handleConfirm}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </Dialog>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddOrderFormService;
