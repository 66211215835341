import React from 'react';
import ServiceCard from './ServiceCard';
import pic1 from '../../Assets/Images/Img - creating an llc.png'
import pic2 from "../../Assets/Images/Img - virtual mailbox.png"
import pic3 from "../../Assets/Images/Img - virtual mailbox (1).png"
import pic4 from "../../Assets/Images/Img - virtual mailbox (2).png"
import pic5 from "../../Assets/Images/Img - virtual mailbox (3).png"
import pic6 from "../../Assets/Images/Img - virtual mailbox (4).png"
import { useNavigate } from 'react-router-dom';

const Services = () => {

  const navigate=useNavigate()

  const services = [
    {
      path: "/corporatefilings",
      image: pic2,
      title: "Corporate Filings",
      description: "Corporate filings cover key services like foreign qualifications, mergers, conversions, and dissolutions, ensuring legal business operations across jurisdictions during major changes and expansions.",
    },
    {
      path: "/compliance",
      image: pic3,
      title: "Compliance",
      description: "Compliance services help your business meet regulatory requirements, such as filing annual reports, reinstating entities, and fulfilling publication obligations.",
    },
    {
      path: "/subscription",
      image: pic4,
      title: "Subscriptions",
      description: "Subscription services, including registered agent services, annual reports, and independent managers or directors, provide ongoing support to ensure your business remains compliant.",
    },
    {
      path: "/uccfilings",
      image: pic1,
      title: "UCC Filings",
      description: "UCC filings and lien searches protect the rights of creditors by publicly recording a claim on a debtor's assets. These filings are essential for businesses engaged in lending, leasing, or selling on credit.",
    },
    {
      path: "/documentretrieval",
      image: pic5,
      title: "Document Retrieval",
      description: "Document retrieval services provide you with certified copies of critical business documents, including certificates of good standing, formation documents, and amendment documents. ",
    },
    {
      path: "/corporatesuplies",
      image: pic6,
      title: "Corporate Supplies",
      description: "Corporate supplies and publications include essential items like corporate kits, seals, and stock/membership certificates. These items are necessary for formalizing business operations and keeping official records.",
    }

  ];

  return (
    <section className="flex flex-col justify-center items-center p-2.5 mt-24 max-w-full w-[1240px] max-md:px-5 max-md:mt-10">
      <h2 className="text-5xl font-medium tracking-tighter text-center capitalize leading-[52.8px] text-slate-700 max-md:max-w-full">
        <span className="font-web-color">Incorporation</span> <span className="font-web-color1">Services</span>
      </h2>
      <p className="mt-5 text-xl text-center text-gray-800 w-[552px] max-md:max-w-full">
        We offer comprehensive services to help you establish and operate your US LLC or corporation.
      </p>
      <main className="flex flex-col px-20 max-md:px-5">
        <section className="flex justify-center w-full max-md:max-w-full">
          <div className="flex flex-wrap flex-1 shrink justify-center items-start p-8 basis-0 min-w-[240px] size-full max-md:px-5 max-md:max-w-full">
            <article className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full">
              <section className="flex overflow-hidden flex-col w-full rounded-lg shadow-lg bg-white bg-opacity-0 max-md:max-w-full">
                <div className="flex overflow-hidden flex-col justify-center w-full max-md:max-w-full">
                  <figure className="flex overflow-hidden flex-col w-full min-h-[280px] max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/46fdef97ac40ba8ef7e4d6d62f7878033432b5d4b785ef8189790ff00a3e539a?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
                      alt="Entity Formation Services"
                      className="object-contain w-full aspect-[3.17] max-md:max-w-full"
                    />
                  </figure>
                  <section className="flex overflow-hidden flex-col p-5 w-full font-medium text-center bg-slate-50 text-slate-800 max-md:max-w-full">
                    <h2 className="flex flex-col pb-4 w-full text-3xl leading-none max-md:max-w-full">
                      <span className="px-12 w-full max-md:px-5 max-md:max-w-full">
                        Entity Formation
                      </span>
                    </h2>
                    <p className="flex flex-col pb-4 w-full text-base font-light leading-7 max-md:max-w-full">
                      <span className="px-1 w-full max-md:max-w-full">
                        Entity Formation services help establish businesses like
                        Corporations (Corp), LLCs, PCs, and PLLCs, offering legal
                        protection and tax benefits.
                      </span>
                    </p>
                    <div className="flex flex-col items-center px-32 w-full text-base leading-none text-white max-md:px-5 max-md:max-w-full">
                      <button className="justify-center px-7 py-3.5 mt-4 text-base leading-4 text-white bg-web-color rounded border-2 border-white border-solid shadow-sm max-md:px-5" onClick={() => navigate("/webcompany")}>
                        More Details
                      </button>
                    </div>
                  </section>
                </div>
              </section>
            </article>
          </div>
        </section>
      </main>
      <div className="justify-center p-4  max-w-full w-[950px] max-md:px-5">
        <div className="flex grid justify-content-center  max-md:flex-col max-md:gap-0">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;