import React, { useContext, useEffect } from 'react'
import { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from "primereact/radiobutton";
import { Message } from 'primereact/message'
import { useForm, Controller } from "react-hook-form";
import { classNames } from 'primereact/utils';
import ShiftsServices from '../../services/demoServices';
import { Dropdown } from 'primereact/dropdown';
import { Sidebar } from 'primereact/sidebar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Checkbox } from 'primereact/checkbox';
import { Avatar } from 'primereact/avatar';
import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';
import errorMessages, { globalMessages } from '../../utils/errorMessages';
import { InputMask } from 'primereact/inputmask';
import CreditCardServices from '../../services/strapiService';
import UserServices from '../../services/userService';
import templateCsv from "../../Assets/users.csv"


import { ProgressBar } from 'primereact/progressbar';

import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import authContext from '../../common/authContext';
import { showToast } from '../../utils/toastUtils';

const UserUpload = ({ visible, setVisible, pageRefresh, setPageRefresh }) => {
    const { permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading } = useContext(authContext);

    const [value, setValue] = useState('');
    const [ingredient, setIngredient] = useState('');
    const toast = useRef(null);
    const [upload, setUpload] = useState('')
    const [isSuccessful, setIsSuccessful] = useState(false)
    const [checked, setChecked] = useState(true)
    const [isEqualAddress, setIsEqualAddress] = useState(true)
    const [allCustomer, setAllCustomer] = useState([])



    const countries = [
        { name: 'United States', code: 'US', dialCode: '+1' },


    ];
    const [passwordType, setPasswordType] = useState("password");
    const [passwordType1, setPasswordType1] = useState("password");

    const [password, setPassword] = useState("")
    const [confirmedPassword, setConfirmPassword] = useState("")
    const [email, setEmail] = useState("")
    const [cityErr, setcityErr] = useState('')

    const toastCenter = useRef(null);

    const [selectedSkills, setSelectedSkills] = useState(null);
    const navigate = useNavigate()


    const [firstName, setFirstName] = useState("")

    const [lastName, setLastName] = useState("")

    const [website, setWebsite] = useState("")

    const [company, setCompany] = useState("")

    const [phone, setPhone] = useState("")

    const [address, setAddress] = useState("")

    const [city, setCity] = useState("")

    const [state, setState] = useState("")

    const [zip, setZip] = useState("")

    const [emailErr, setEmailErr] = useState("")

    const [passwordErr, setPasswordErr] = useState("")

    const [confirmPasswordErr, setConfirmPasswordErr] = useState("")

    const [firstNameErr, setFirstNameErr] = useState("")

    const [lastNameErr, setLastNameErr] = useState("")

    const [phoneErr, setPhoneErr] = useState("")

    const [selectedCountry, setSelectedCountry] = useState(null);

    const [roleId, setRoleId] = useState(null)

    const [companyId, setCompanyId] = useState(null)

    const [globalErr, setGlobalErr] = useState("")

    const [isFocused, setIsFocused] = useState(false);

    const [isDisabled, setIsDisabled] = useState(false)

    const [superAdminId, setSuperAdminId] = useState(null)

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const fileInputRef = useRef(null)

    const [isContentVisible, setIsContentVisible] = useState(true);


    const [totalSize, setTotalSize] = useState(0);

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        console.log(file)
        if (file) {
            setSelectedFile(file);
        }
    };


    const user = JSON.parse(localStorage.getItem('user'));


    function checkIdsExist(arr, idsToCheck) {
        // Check if any of the idsToCheck are found in arr
        const foundIds = idsToCheck.filter(idToCheck => arr.some(item => item.id === idToCheck));
        return foundIds.length > 0 ? foundIds : null;
    }

    useEffect(() => {
        if (user) {
            console.log(globalCompany);
            if (globalCompany !== null) {
                if (globalCompany.roles.length > 0) {
                    // Check for both 1 and 4
                    const ids = checkIdsExist(globalCompany.roles, [1, 4]);
                    console.log(ids);
                    if (ids) {
                        setSuperAdminId(ids); // Set the array of matched IDs
                    }
                    if (!ids || ids.length === 0) {

                        setSuperAdminId(null)
                    }

                }
            }
        }
    }, [globalCompany]);

    console.log(selectedFile)

    const handleClick = () => {
        document.getElementById('fileInput').click();
    };



    const dialogView = () => setVisible(false)
    const cancelView = () => {
        setVisible(false)
        setFirstName("")
        setLastName("")
        setEmail("")
        setIngredient("")
        setPhone("")
        setPassword("")
        setConfirmPassword("")
        setFirstNameErr("")
        setLastNameErr("")
        setPasswordErr("")
        setConfirmPasswordErr("")
        setPhoneErr("")
        setcityErr("")
        setEmailErr("")
        setSelectedFile(null)
    }




    let newObject = window.localStorage.getItem("user");
    let updatedUser = Object.assign({}, JSON.parse(newObject));


    useEffect(() => {
        setSelectedCountry({ name: 'United States', code: 'US', dialCode: '+1' })

        if (updatedUser) {
            if (updatedUser.companyRoles !== undefined) {
                if (updatedUser.companyRoles.length > 0) {
                    if (updatedUser.companyRoles[0].roles.length > 0) {
                        setRoleId(updatedUser.companyRoles[0].roles[0].id)
                        if (updatedUser.companyRoles[0].companyDetails) {
                            setCompanyId(updatedUser.companyRoles[0]?.companyDetails?.id)
                        }
                    }
                }
            }

        }

    }, [])










    const onFileUpload = (e) => {

        e.preventDefault();

        let formData = new FormData();

        formData.append("file", selectedFile);

        console.log(selectedFile)

        // if (selectedFile === null) {
        //     toast.current.show({ severity: 'error', summary: 'Error', detail: "Please choose the file", life: 2500 });
        // }

        if (selectedFile) {
            if (selectedFile.size < 256000) {
                setIsLoading(true)
                UserServices.bulkUserUpload(formData, roleId, companyId)
                    .then((response) => {
                        console.log(response);

                        if (response.status === 201) {

                            setPageRefresh(!pageRefresh)
                            setIsLoading(false)
                            // toast.current.show({ severity: 'success', summary: 'Success', detail: "File uploaded successfully", life: 3000 });
                            // setTimeout(() => {
                            //     setVisible(false)
                            // }, 3000);
                                showToast("success",`File uploaded successfully`)
                            
                        }
                        if (response.status === 500) {

                            // toast.current.show({ severity: 'warn', summary: 'Failed to upload', detail: "Network error, Please try again!", life: 3000 });
                            showToast("error",`Network error, Please try again!`)

                        }
                        if (response.status === 504) {

                            // toast.current.show({ severity: 'warn', summary: 'Failed to upload', detail: "Gateway timeout!", life: 3000 });

                            showToast("error",`Failed to upload`)
                        }
                        




                    }).catch((e) => {
                        setIsLoading(false)
                        if (e.response !== undefined) {
                            if (e.response.status === 701) {
                                // if (toast.current !== undefined) {
                                //     toast.current.show({ severity: 'warn', summary: 'Failed to upload', detail: "Data already exist", life: 3000 });

                                // }

                            showToast("error",`Data already exist`)

                            }
                            else {
                                // if (toast.current !== undefined) {
                                //     toast.current.show({ severity: 'warn', summary: 'Failed to upload', detail: "Something went wrong", life: 3000 });

                                // }
                            showToast("error",`Something went wrong`)

                            }

                        }
                        // toast.current.show({ severity: 'success', summary: 'Failed to upload', detail: e.response.data.errorMessage, life: 3000 });
                        console.log(e)

                    })
            }
            else {
                // toast.current.show({ severity: 'warn', summary: 'Failed to upload', detail: "File size is larger than 250kb", life: 3000 });
                showToast("error",`File size is larger than 250kb`)
            }

        }

    }


    function bytesToKilobytes(bytes) {
        if (typeof bytes !== 'number' || isNaN(bytes) || bytes < 0) {
            console.log("Its not number")
        }
        else {
            return bytes / 1024;
        }
    }




    const customHeader = (
        <div className="flex align-items-center gap-2">
            <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" />
            <span className="font-bold">Amy Elsner</span>
        </div>
    );



    return (
        <div>
            <Toast ref={toast} />
            <Toast ref={toastCenter} position='center' />
            <Sidebar position='right' header={customHeader} visible={visible} className='dialog-box' onHide={() => setVisible(false)} >
                <div className='grid custom-sidebar-header'>
                    <div className='grid col-12 col-sm-12 col-md-12 col-lg-11 px-4 pt-4 pb-0'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 sidebar-header-text1 pb-0'>
                            Bulk Upload {superAdminId ? "Clients" : "Users"}
                        </div>

                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-1 flex justify-content-end pt-4 pb-0'>
                        <button onClick={dialogView}><i className='pi pi-times'></i></button>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 sidebar-header-text2 px-4'>
                        Upload bulk {superAdminId ? "clients" : "users"} by listing them in a CSV file. Make sure it’s as per the template provided here.</div>
                </div>



                <form id='myform' className='form-spacing mt-4' onSubmit={onFileUpload}>
                    <div className='input-sidebar-card mt-4'>
                        <div className='px-6 py-4'>
                            <div className='flex' style={{ justifyContent: "space-between" }}>
                                <div className='font-fam-for-all'>
                                    <span><i className='pi pi-address-book'></i></span>
                                    Bulk upload contacts
                                </div>
                            </div>
                            <div>
                                Manage your company’s {superAdminId ? "clients" : "users"} and their access with simple file upload.
                            </div>
                            <div className='card pt-4 px-2 mt-4'>
                                {/* <div className='bulk-upload-card-header'>
                                    <a href={templateCsv} download="template.csv" className='flex gap-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5M5.83333 8.33333L10 12.5M10 12.5L14.1667 8.33333M10 12.5V2.5" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <div className='bulk-upload-card-header-text'>
                                            Download Template
                                        </div>
                                    </a>

                                </div> */}
                                <div className="flex justify-content-center align-items-center pb-2">
                                    <div className="text-center">
                                        <Button
                                            icon={selectedFile ? 'pi pi-upload' : 'pi pi-upload'}
                                            // label={selectedFile ? selectedFile.name : ''}
                                            className="p-button-rounded bg-gray-400 border-gray-400 p-button-primary p-mb-3"
                                            aria-label="Upload"
                                            onClick={handleClick}
                                            disabled={selectedFile ? true : false}
                                        />
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                            accept=".csv"
                                        />
                                        <div>
                                            <button className=" font-fam-for-all datatable-highlight-color webapp-global-sm-size  hover:text-blue-700" onClick={handleClick} >Click here to upload</button>
                                        </div>
                                        <div className="upload-rules font-fam-for-all">
                                            <p className='webapp-global-sm-size'>CSV (max. 250kb)</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-devider'></div>
                                <div className='flex align-items-center justify-content-center py-2'>
                                    {/* <a href={templateCsv} download="template.csv" className='bulk-user-download-template-button-main'>
                                        <div className='bulk-user-download-template-button-text'>
                                            Download Template
                                        </div>
                                    </a> */}
                                    <a href={templateCsv} download="template.csv" className='flex gap-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5M5.83333 8.33333L10 12.5M10 12.5L14.1667 8.33333M10 12.5V2.5" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <div className='bulk-upload-card-header-text'>
                                            Download Template
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        selectedFile ? <>
                            <div className='bulk-upload-status-container mt-4'>
                                <div className='bulk-upload-status-card1 input-sidebar-card'>
                                    <div className='bulk-upload-status-card1-content1'>
                                        <div className='bulk-upload-status-card1-content1-button-main1'>
                                            <div className='bulk-upload-status-card1-content1-button-main1-base'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M8.6665 1.3335H3.99984C3.64622 1.3335 3.30708 1.47397 3.05703 1.72402C2.80698 1.97407 2.6665 2.31321 2.6665 2.66683V13.3335C2.6665 13.6871 2.80698 14.0263 3.05703 14.2763C3.30708 14.5264 3.64622 14.6668 3.99984 14.6668H11.9998C12.3535 14.6668 12.6926 14.5264 12.9426 14.2763C13.1927 14.0263 13.3332 13.6871 13.3332 13.3335V6.00016M8.6665 1.3335L13.3332 6.00016M8.6665 1.3335V6.00016H13.3332" stroke="#145EF0" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className='bulk-upload-status-card1-content1-button-main2'>
                                            <div className='bulk-upload-status-card1-content1-button-main2-base'>
                                                <div className='bulk-upload-status-text1'>
                                                    {selectedFile.name}
                                                </div>
                                                <div className='bulk-upload-status-text2'>
                                                    {bytesToKilobytes(selectedFile.size)}Kb
                                                </div>
                                            </div>
                                            <div className='bulk-upload-status-card1-content1-button-main2-base1'>
                                                <div className='progress-bar-container'>
                                                    {/* <div className='progress-bar-empty'>

                                                    </div> */}
                                                    <div className='progress-bar-fill'>

                                                    </div>
                                                </div>
                                                <div className='progress-bar-label'>
                                                    100%
                                                </div>
                                            </div>
                                        </div>

                                        <div className=''>
                                            <button onClick={() => {
                                                setSelectedFile(null)
                                                if (fileInputRef.current) {
                                                    fileInputRef.current.value = null;
                                                }
                                            }}><i className='pi pi-times'></i></button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </> : <></>
                    }

                    <div className='flex justify-content-end dialog-form-md-group-Btn my-5'>
                        <Button type="button" className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3" label='Cancel' style={{ height: "40px" }} onClick={cancelView} ></Button>
                        <Button type='submit' className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Submit' style={{ height: "40px" }}  ></Button>
                    </div>
                </form>

                {/* <div className='input-sidebar-card mt-4'>
                    <div className='px-6 py-4'>
                        <Toast ref={toast}></Toast>

                        <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                        <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                        <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                        <FileUpload ref={fileUploadRef} name="demo[]" url="/api/upload" multiple accept="file/*" maxFileSize={1000000}
                            onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                            headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                            chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                    </div>
                </div> */}




            </Sidebar>
        </div>
    )
}

export default UserUpload