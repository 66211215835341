import React, { useState, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import OrderServices from "../../../../services/orderServices";
import "../InvoicePayment/InvoicePayment.css";
import authContext from "../../../../common/authContext";
import { MultiSelect } from "primereact/multiselect";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export default function SubscriptionTable({ searchQuery, setTotalCount }) {
  const { globalCompany } = useContext(authContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [error, setError] = useState(null);

  const columns = [
    { field: "subscriptionName", header: "Subscription Name" },
    { field: "entityName", header: "Entity Name" ,},
    { field: "subscriptionDate", header: "Subscription Date", format: true },
    {
      field: "subscriptionExpiryDate",
      header: "Subscription Expiry Date",
      format: true,
    },
    { field: "orderNumber", header: "Order Number" },
  ];
  const entityTemplate = (rowData) => (
    <span className="capitalize ">
      {rowData.entityName}
    </span>
  );
  const [visibleColumns, setVisibleColumns] = useState(columns);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await OrderServices.getClientSubscription(
          globalCompany?.companyDetails?.id,
          0
        );
        const formattedData =
          response.data?.map((item) => ({
            id: item?.lineItemId || 0,
            subscriptionName: item?.subscriptionName || "N/A",
            entityName: item?.entityName || "N/A",
            subscriptionDate: item?.orderDate ? new Date(item.orderDate) : null,
            subscriptionExpiryDate: item?.subscriptionExpiryDate
              ? new Date(item.subscriptionExpiryDate)
              : null,
            orderNumber: item?.orderNumber || "N/A",
          })) || [];

        setData(formattedData);
        setTotalCount(formattedData.length);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    initFilters();
  }, [globalCompany]);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: "contains" },
    });
    setGlobalFilterValue(""); // Reset input field
  };
  

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters({ ...filters, global: { value, matchMode: "contains" } });
    setGlobalFilterValue(value);
  };

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );

    setVisibleColumns(orderedSelectedColumns);
  };

  const renderHeader = () => (
    <Row>
      {/* <Col xl={6} md={6} sm={12} xs={12}>
        <div>
          <MultiSelect
            value={visibleColumns}
            options={columns}
            optionLabel="header"
            onChange={onColumnToggle}
            className="w-full w-20rem sm:w-16rem ms-2"
            display="chip"
          />
        </div>
      </Col> */}
      <Col xl={12} md={12} sm={12} xs={12}>
        <div className="main-dash-btnn-class flex justify-content-end p-2">
          {/* <div>
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Clear"
              outlined
              onClick={initFilters}
              className="common-blue-btnn-for me-2"
            />
          </div> */}
          <div>
            {/* <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Global Search"
            /> */}
            <InputGroup className="mb-0">
              <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
              <Form.Control
                placeholder="Global Search"
                aria-describedby="basic-addon1"
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
              />
            </InputGroup>
          </div>
        </div>
      </Col>
    </Row>
  );

  const formatDate = (date) =>
    date ? date.toLocaleDateString("en-US") : "N/A";

  // return (
  //   <div className="main-table-class-datatable">
  //     <div className="card">
  //       {error && <div className="alert alert-danger">{error}</div>}
  //       <DataTable
  //         value={data}
  //         paginator
  //         rows={5}
  //         loading={loading}
  //         filters={filters}
  //         globalFilterFields={["subscriptionName", "entityName", "orderNumber"]}
  //         header={renderHeader()}
  //         emptyMessage="No records found."
  //         scrollable
  //         scrollHeight="flex"
  //         scrollWidth="100%"
  //       >
  //         {visibleColumns.map((col) => (
  //           <Column
  //             sortable
  //             key={col.field}
  //             field={col.field}
  //             header={col.header}
  //             body={
  //               col.format ? (rowData) => formatDate(rowData[col.field]) : null
  //             }
  //             // filter
  //             // filterMatchMode="contains"
  //           />
  //         ))}
  //       </DataTable>
  //     </div>
  //   </div>
  // );

  return (
    <div className="main-table-class-datatable">
      <div className="card">
        {error && <div className="alert alert-danger">{error}</div>}
        <DataTable
          value={data}
          paginator
          rows={5}
          loading={loading}
          filters={filters}
          globalFilterFields={["subscriptionName", "entityName", "orderNumber"]}
          header={renderHeader()}
          emptyMessage="No records found."
          scrollable
          scrollHeight="flex"
          scrollWidth="100%"
        >
          {visibleColumns.map((col) => {
            if (col.field === "subscriptionDate" || col.field === "subscriptionExpiryDate") {
              return (
                <Column
                  sortable
                  key={col.field}
                  field={col.field}
                  header={col.header}
                  body={(rowData) => formatDate(rowData[col.field])}
                  className="font-fam-for-all datatable-lg-col"
                />
              );
            }
            if (col.field === "entityName") {
              return (
                <Column
                sortable
                  key="entityName"
                  field="entityName"
                  header="Entity Name"
                  body={entityTemplate}
                  className="font-fam-for-all datatable-lg-col"
                />
              );
            }
            return (
              <Column
                sortable
                key={col.field}
                field={col.field}
                header={col.header}
              />
            );
          })}
        </DataTable>
      </div>
    </div>
  );
}
