import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import companyMasterService from '../../services/companyMasterService'
import AddressService from '../../services/addressService'
import MapWithStateAndCounty from '../../utils/CountyMap'

const OrderPreview = ({ data }) => {
    const [strapiJSON, setStrapiJSON] = useState(null)

    const [previewData, setPreviewData] = useState(null)

    const [allKeyPersonnel, setAllKeyPersonal] = useState([])

    const [allStates, setAllStates] = useState([])
    console.log("recieved data", data)


    useEffect(() => {
        if (data) {
            const parsedJsonData = JSON.parse(data.orderDetails.strapiOrderFormJson)
            console.log(parsedJsonData)
            setStrapiJSON(parsedJsonData)
            setPreviewData(parsedJsonData.Payload)
        }
    }, [data])


    useEffect(() => {
        companyMasterService.getAllKeyPersonnelType()
            .then((res) => {
                console.log(res)
                if (Array.isArray(res.data)) {
                    setAllKeyPersonal(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })

        AddressService.getAllStates()
            .then((res) => {
                console.log(res)
                setAllStates(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    const getState = (id) => {
        const temp = allStates.find((val) => val.id === id)
        if (temp !== undefined) {
            return temp.stateFullDesc;
        }
        else {
            return null
        }

    }

    const getKeyPersonel = (id) => {
        console.log(id)
        if (id) {
            const temp = allKeyPersonnel.filter((val) => val.id === id)
            console.log(temp)
            if (temp.length > 0) {
                return temp[0].keyPersonalType
            }
            else {
                return null
            }
        }
        return null
    }

    const getInitials = (name) => {
        const nameArray = name.trim().split(" ");
        if (nameArray.length >= 2) {
            const firstNameInitial = nameArray[0].charAt(0).toUpperCase();
            const lastNameInitial = nameArray[1].charAt(0).toUpperCase();
            return firstNameInitial + lastNameInitial;
        } else {

            const initial = nameArray[0].charAt(0).toUpperCase();
            return initial + initial;
        }
    };

    const convertToTitleCase = (str) => {
        return str
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2").replace(/_/g, ' ');
    };

    function convertName(originalString) {

        if (!originalString.includes('_')) {
            return originalString;
        }


        const parts = originalString.split('_');


        const relevantParts = parts.slice(1);

        // Capitalize the first letter of each part and join them with a space
        const formattedString = relevantParts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');

        return formattedString;
    }

    const toPascalCase = (str) => {
        return str.replace(/-/g, ' ').replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const keyPersonalMemberData = ["Organizer_Information", "Incorporator_Information", "Director_Information", "Officer_Information", "Filer_Information", "President_Information", "Treasurer_Information", "Secretary"]

    console.log(previewData)
    return (
        <div>
            {previewData && data !== null ?
                <>
                    <div
                        className="wizard-content-sub-card-header mb-3"
                        id="header"
                    >
                        {/* <div className="wizard-content-sub-card-text-frame">
                            <div className="wizard-content-sub-card-text">
                                Preview
                            </div>
                        </div> */}
                    </div>


                    <div className="flex p-4 justify-between font-fam-for-all">
                        <div className="datatable-highlight-color">
                            {data.entityTypeId ? data.entityTypeId.entityFullDesc : ""}
                        </div>
                        <div className="datatable-highlight-color">
                            {data.state ? data.state.stateFullDesc : ""}
                        </div>
                        {/* <div className='card'>
                            <MapWithStateAndCounty locationName={"California"} />
                        </div> */}
                    </div>
                    <div className="min-h-screen bg-gray-50  flex justify-center items-start">
                        <div className="w-full">
                            <div className="bg-white py-2">
                                <div className="space-y-8">
                                    {
                                        Array.isArray(data.orderLineItem) && <div className="bg-white">

                                            <div className="px-4 py-2 rounded-t-lg" style={{ backgroundColor: '#F9FAFB', fontFamily: 'Inter', fontSize: '16px' }}>
                                                <h2 className="text-lg  text-gray-800">Selected Services</h2>
                                            </div>

                                            <div className="p-4 space-y-4">
                                                {data.orderLineItem.map((service, index) => (
                                                    <div key={index} className="border grid border-gray-200 rounded-md p-2">
                                                        <div className="col-10">
                                                            <h3 className="text-md font-semibold" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                {service.productListing.displayName}
                                                            </h3>
                                                            <p className="text-gray-700 mt-2" style={{ fontFamily: 'Inter', fontSize: '12px' }}>
                                                                {/* {service.productListing.productListingMaster.additionalInfo} */}
                                                            </p>
                                                        </div>
                                                        <div className="col-2">
                                                            <div className="text-right  font-bold text-blue-800" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                {service.productListing.unitPrice === 0 ? "Included" : `$${service.productListing.unitPrice}`}
                                                            </div>
                                                        </div>


                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    }

                                    {Object.keys(previewData).map((sectionKey, index) => (
                                        <>
                                            {!keyPersonalMemberData.includes(sectionKey) ? (
                                                <div key={index} className="bg-white">
                                                    <div
                                                        className="px-4 py-2"
                                                        style={{ backgroundColor: "#F9FAFB", fontFamily: "Inter", fontSize: "16px" }}
                                                    >
                                                        <h2 className="text-lg text-gray-800">{toPascalCase(sectionKey)}</h2>
                                                    </div>

                                                    <div className="px-4 py-4">
                                                        <div className="grid gap-3 mb-2">
                                                            {!(previewData[sectionKey] !== null && typeof previewData[sectionKey] === "object") && !Array.isArray(previewData[sectionKey]) && (
                                                                <>
                                                                    <div className="col-3">
                                                                        <div className="order-preview-key">{convertName(sectionKey)}</div>
                                                                        <div className="order-preview-value">{previewData[sectionKey]}</div>
                                                                    </div>
                                                                    <div className="col-3">

                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>


                                                        {previewData[sectionKey] !== null && typeof previewData[sectionKey] === "object" && !Array.isArray(previewData[sectionKey]) && (
                                                            <div>
                                                                <div className="grid preview-card p-4 mb-2">
                                                                    {Object.keys(previewData[sectionKey])
                                                                        .filter(key => typeof previewData[sectionKey][key] !== "object")
                                                                        .map((key, i) => (
                                                                            <>
                                                                                <div className="col-3">
                                                                                    <div className="order-preview-key">{convertName(key)}</div>
                                                                                    <div className="order-preview-value">{previewData[sectionKey][key]}</div>
                                                                                </div>
                                                                                <div className="col-3">

                                                                                </div>

                                                                            </>
                                                                        ))
                                                                    }
                                                                </div>


                                                                {Object.keys(previewData[sectionKey])
                                                                    .filter(key => {
                                                                        const value = previewData[sectionKey][key];
                                                                        // Check if the key is an object and doesn't only contain '__temp_key__'
                                                                        return value !== null && typeof value === "object" && (Object.keys(value).length > 1 || !("__temp_key__" in value));
                                                                    })
                                                                    .map((key, i) => (
                                                                        <div key={i} className="">
                                                                            {Array.isArray(previewData[sectionKey][key]) ? (
                                                                                <div>
                                                                                    <h3 className="text-lg text-gray-800 mb-2">{convertToTitleCase(key)}</h3>

                                                                                    {/* Iterate over the array elements */}
                                                                                    {previewData[sectionKey][key].map((arrayItem, arrayIndex) => (
                                                                                        <div key={arrayIndex} className="mb-2">
                                                                                            {typeof arrayItem === 'object' ? (
                                                                                                <div>
                                                                                                    {/* If the array contains objects, iterate over the keys of the object */}
                                                                                                    <div className="grid preview-card p-4 mb-2">
                                                                                                        {Object.keys(arrayItem).map((nestedKey) => (
                                                                                                            <>
                                                                                                                {nestedKey !== "__temp_key__" && (
                                                                                                                    <>
                                                                                                                        <div className="col-3">
                                                                                                                            <div className="order-preview-key">
                                                                                                                                {convertName(nestedKey)}
                                                                                                                            </div>
                                                                                                                            <div className="order-preview-value">
                                                                                                                                {arrayItem[nestedKey]}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="col-3"></div>
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </>
                                                                                                        ))}
                                                                                                    </div>

                                                                                                    {/* Divider for Array Item */}
                                                                                                    <div className="border-t border-gray-300 my-4"></div>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div className="grid gap-3 mb-2">
                                                                                                    {/* If the array contains non-object values, display them directly */}
                                                                                                    <div className="col-2 order-preview-key">
                                                                                                        {`Item ${arrayIndex + 1}`}
                                                                                                    </div>
                                                                                                    <div className="col-6 order-preview-value">
                                                                                                        {arrayItem}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    ))}

                                                                                    {/* Divider for Array Data */}
                                                                                    <div className="border-t border-gray-300 my-4"></div>
                                                                                </div>
                                                                            ) : previewData[sectionKey][key] !== null && typeof previewData[sectionKey][key] === 'object' ? (
                                                                                <div>
                                                                                    <h3 className="text-lg text-gray-800 mb-2">{convertToTitleCase(key)}</h3>
                                                                                    <div className="grid preview-card p-4 mb-2">
                                                                                        {Object.keys(previewData[sectionKey][key]).map((nestedKey) => (
                                                                                            <>
                                                                                                {nestedKey !== "__temp_key__" && (
                                                                                                    <>
                                                                                                        <div className="col-3">
                                                                                                            <div className="order-preview-key">
                                                                                                                {convertName(nestedKey)}
                                                                                                            </div>
                                                                                                            <div className="order-preview-value">
                                                                                                                {previewData[sectionKey][key][nestedKey]}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-3"></div>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        ))}
                                                                                    </div>

                                                                                    {/* Divider for Nested Data */}
                                                                                    <div className="border-t border-gray-300 my-4"></div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="grid gap-3 mb-2">
                                                                                    {/* Key-Value Pair for Flat Object */}
                                                                                    <div className="col-2 order-preview-key">
                                                                                        {convertName(key)}
                                                                                    </div>
                                                                                    <div className="col-6 order-preview-value">
                                                                                        {previewData[sectionKey][key]}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ))}

                                                            </div>
                                                        )}



                                                        {Array.isArray(previewData[sectionKey]) &&
                                                            previewData[sectionKey].map((item, idx) => {
                                                                const primitiveKeys = [];
                                                                const objectKeys = [];
                                                                const arrayKeys = [];

                                                                console.log(item)

                                                                Object.keys(item).forEach((itemKey) => {
                                                                    console.log(itemKey)
                                                                    if (itemKey !== "__temp_key__") {
                                                                        if (typeof item[itemKey] === "object" && item[itemKey] !== null) {
                                                                            if (Array.isArray(item[itemKey])) {
                                                                                arrayKeys.push(itemKey);
                                                                            } else {
                                                                                objectKeys.push(itemKey);
                                                                            }
                                                                        } else {
                                                                            primitiveKeys.push(itemKey);
                                                                        }
                                                                    }
                                                                });

                                                                console.log(primitiveKeys)
                                                                console.log(objectKeys)
                                                                console.log(arrayKeys)

                                                                return (
                                                                    <div key={idx}>
                                                                        <h3 className="text-lg text-gray-800 mb-2">
                                                                            {convertToTitleCase(sectionKey)} {idx + 1}
                                                                        </h3>

                                                                        {/* Primitive Keys */}
                                                                        {primitiveKeys.length > 0 && primitiveKeys.map((itemKey) => (
                                                                            <div className="grid gap-3 mb-2" key={itemKey}>
                                                                                <div className="col-2 order-preview-key">{convertName(itemKey)}</div>
                                                                                <div className="col-6 order-preview-value">
                                                                                    {typeof item[itemKey] === "string" || typeof item[itemKey] === "number"
                                                                                        ? item[itemKey]
                                                                                        : JSON.stringify(item[itemKey])}
                                                                                </div>
                                                                            </div>
                                                                        ))}

                                                                        {/* Object Keys */}
                                                                        <div className="grid preview-card p-4 mb-2">
                                                                            {objectKeys.length > 0 && objectKeys.map((itemKey) => (
                                                                                <div className="col-3" key={itemKey}>
                                                                                    <div className="order-preview-key">{convertName(itemKey)}</div>
                                                                                    <div className="order-preview-value">
                                                                                        {item[itemKey] && typeof item[itemKey] === "object" ? (
                                                                                            Object.keys(item[itemKey]).map((subKey) => (
                                                                                                <div key={subKey}>
                                                                                                    <strong>{convertName(subKey)}:</strong> {item[itemKey][subKey]}
                                                                                                </div>
                                                                                            ))
                                                                                        ) : (
                                                                                            JSON.stringify(item[itemKey])
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>

                                                                        {/* Array Keys */}
                                                                        <div className="grid preview-card p-4 mb-2">
                                                                            {arrayKeys.length > 0 && arrayKeys.map((itemKey) => (
                                                                                <div className="col-3" key={itemKey}>
                                                                                    <div className="order-preview-key">{convertName(itemKey)}</div>
                                                                                    <div className="order-preview-value">
                                                                                        {Array.isArray(item[itemKey]) &&
                                                                                            item[itemKey].map((subItem, subIdx) => (
                                                                                                <div key={subIdx} className="ml-4">
                                                                                                    {typeof subItem === "object" ? (
                                                                                                        Object.keys(subItem).map((subKey) => (
                                                                                                            <div key={subKey}>
                                                                                                                <strong>{convertName(subKey)}:</strong> {subItem[subKey]}
                                                                                                            </div>
                                                                                                        ))
                                                                                                    ) : (
                                                                                                        <div>{subItem}</div>
                                                                                                    )}
                                                                                                </div>
                                                                                            ))}
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>

                                                                        <div className="border-t border-gray-300 my-4"></div>
                                                                    </div>
                                                                );
                                                            })}

                                                    </div>
                                                </div>
                                            ) : null}
                                        </>
                                    ))}

                                    <div className="bg-white">

                                        <div className="px-4 py-2" style={{ backgroundColor: '#F9FAFB', fontFamily: 'Inter', fontSize: '16px' }}>
                                            <h2 className="text-lg  text-gray-800">Contacts</h2>
                                        </div>

                                        <div className="p-4 ">
                                            <div className='grid gap-2 px-4 py-6'>
                                                {
                                                    data.companyKeyPersonal.length > 0 ? data.companyKeyPersonal.map((val) => {
                                                        if (val) {
                                                            // Check if there's no keyPersonnel with keyPersonnelTypeId 5
                                                            const isShipping = data.companyKeyPersonal.every(
                                                                (person) => person.companyKeyPersonnel.keyPersonnelTypeId !== 5
                                                            ) && val.companyKeyPersonnel.keyPersonnelTypeId === 4;

                                                            return (
                                                                <div className='col-4' key={val.companyKeyPersonnel.keyPersonnelTypeId}>
                                                                    <div className='order-contact-card-container'>
                                                                        <div className='order-contact-card-main p-4'>
                                                                            <div className='order-contact-card-header-main'>
                                                                                <Avatar label={getInitials(val.companyKeyPersonnel.keyPersonnelName)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />
                                                                                <div>
                                                                                    <div className='order-contact-card-header-text1'>
                                                                                        {val.companyKeyPersonnel.keyPersonnelName}
                                                                                    </div>
                                                                                    <div className='order-contact-card-header-text2'>
                                                                                        {getKeyPersonel(val.companyKeyPersonnel.keyPersonnelTypeId)}
                                                                                        {/* {isShipping && ' & SHIPPING'} */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='card-devider'></div>
                                                                            <div className='order-contact-card-text-main'>
                                                                                <div>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                        <g clipPath="url(#clip0_966_10329)">
                                                                                            <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_966_10329">
                                                                                                <rect width="20" height="20" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                </div>
                                                                                <div className='order-contact-card-text1'>
                                                                                    {val.companyKeyPersonnel.contactNo}
                                                                                </div>
                                                                            </div>

                                                                            <div className='order-contact-card-text-main'>
                                                                                <div>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                        <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className='order-contact-card-text1'>
                                                                                    {val.companyKeyPersonnel.emailId}
                                                                                </div>
                                                                            </div>

                                                                            <div className='order-contact-card-text-main'>
                                                                                <div>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                        <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                                                        <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className='order-contact-card-text1'>
                                                                                    {val.companyKeyPersonnel.addressMasterId.addressLine1 !== "" ? `${val.companyKeyPersonnel.addressMasterId.addressLine1},${val.companyKeyPersonnel.addressMasterId.addressLine2?`${val.companyKeyPersonnel.addressMasterId.addressLine2},`:""}${val.companyKeyPersonnel.addressMasterId.city},${getState(val.companyKeyPersonnel.addressMasterId.stateId)},${(val.companyKeyPersonnel.addressMasterId.postalCode)}` : ""}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    }) : <p>No personnel found</p>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : ""}


        </div>
    )
}

export default OrderPreview