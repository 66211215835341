import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { DataScroller } from "primereact/datascroller";
import { Rating } from "primereact/rating";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { Chips } from "primereact/chips";

import { Checkbox } from "primereact/checkbox";
import { Avatar } from "primereact/avatar";

import { MultiSelect } from "primereact/multiselect";
import { Accordion, AccordionTab } from "primereact/accordion";

import { SplitButton } from "primereact/splitbutton";

import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";

import { Dropdown } from "primereact/dropdown";

import { DataView } from "primereact/dataview";

import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TabView, TabPanel } from "primereact/tabview";
import { Sidebar } from "primereact/sidebar";
import { OverlayPanel } from "primereact/overlaypanel";
import authContext from "../common/authContext";
import { useNavigate } from "react-router-dom";
import UserServices from "../services/userService";
import { RadioButton } from "primereact/radiobutton";
import Sidesbar from "../common/Sidesbar";
import Headers from "../common/Headers";
import ContactService from "../services/ContactService";
import AddCustomUser from "./AddComponents/AddCutsomUser";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import AddEmployee from "./AddComponents/AddEmployee";
import GroupDataTable from "./GroupDataTable";
import { Switch } from "@mui/material";
import errorMessages, { globalMessages } from "../utils/errorMessages";
import UserUpload from "./AddComponents/UserUpload";
import { Toast } from "primereact/toast";
import companyMasterService from "../services/companyMasterService";
import rolesMasterService from "../services/rolesMaster";
import { InputMask } from "primereact/inputmask";
import ForgotPasswordService from "../services/forgotPasswordService";
import AddressService from "../services/addressService";
import MasterServices from "../services/coreServices";
import { showToast } from "../utils/toastUtils";

export default function AccountManagerContactDashboard() {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh,
    redirectedData,
    setRedirectedData,
  } = useContext(authContext);

  console.log(globalCompany);

  const hasPermission = (permission) => permissions.includes(permission);

  console.log(hasPermission("AllowCreateUser"));

  let newObject = window.localStorage.getItem("user");

  let updatedUser = Object.assign({}, JSON.parse(newObject));

  const [filteredData, setFilteredData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const [sortName, setSortName] = useState(null);

  const [chipsData, setChipsData] = useState([]);

  const [chipsData1, setChipsData1] = useState([]);

  const [filterChipData, setFilterChipData] = useState([]);

  const [selectedData, setSelectedData] = useState([]);

  const [allCities, setAllCities] = useState([]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    // name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

    id: { value: null, matchMode: FilterMatchMode.IN },
    "contactDetails.firstName": {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
    "contactDetails.lastName": {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
    "contactDetails.mobileNo": {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
    "contactDetails.emailId": {
      value: null,
      matchMode: FilterMatchMode.EQUALS,
    },
  });

  // const [city, setCity] = useState([])

  const [exp1, setExp1] = useState(null);

  const [exp2, setExp2] = useState(null);

  const [skills, setSkills] = useState([]);
  const [skillError, setSkillError] = useState("");
  const [skillARR, setSkillARR] = useState([]);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const [qualification, setQualification] = useState([]);
  const [qualificationArr, setQualificationArr] = useState([]);

  const [visible, setVisible] = useState(false);

  const [visible1, setVisible1] = useState(false);

  const [visible2, setVisible2] = useState(false);

  const [visible4, setVisible4] = useState(false);

  const [visible5, setVisible5] = useState(false);

  const [visible6, setVisible6] = useState(false);

  const [visibleContent, setVisibleContent] = useState(false);

  const [allPosition, setAllPosition] = useState([]);

  const [position, setPosition] = useState(null);

  const [positionData, setPositionData] = useState(null);

  const [candidate, setCandidate] = useState(null);

  const [closeProgresbar, setCloseProgresbar] = useState(false);

  const [closeProgresbar1, setCloseProgresbar1] = useState(false);

  const [globalKeyValue, setGlobalKeyValue] = useState("");

  const [AllCandidate, setAllCandidate] = useState([]);

  const [showSkeleton, setShowSkeleton] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [data, setData] = useState(null);

  const op = useRef(null);

  const downloadop = useRef(null);

  const toast = useRef(null);

  const [isRemoveChip, setIsRemoveChip] = useState(false);

  const [fback, setFback] = useState([]);

  const [candidateFback, setCandidateFback] = useState(null);

  const [interviewLevel1, setInterviewLevel1] = useState([]);

  const [hrPanelList, setHrPanelList] = useState([]);

  const [hrpanel, setHrPanel] = useState(null);

  const [selectedPanelData, setSelectedPanelData] = useState([]);

  const [selectedPanelList, setSelectedPanelList] = useState([]);

  const [showPanel, setShowPanel] = useState(false);

  const [globalSearchValue, setGlobalSearchValue] = useState("");

  const history = useNavigate();

  const [isShowContent, setIsShowContent] = useState(false);

  const [isShowContent1, setIsShowContent1] = useState(false);

  const [isShowContent2, setIsShowContent2] = useState(false);

  const [isShowContent3, setIsShowContent3] = useState(false);

  const [salutation, setSalutation] = useState("");

  const [firstName, setFirstName] = useState(null);

  const [firstNameErr, setFirstNameErr] = useState("");

  const [middleName, setMiddleName] = useState(null);

  const [middleNameErr, setMiddleNameErr] = useState("");

  const [lastName, setLastName] = useState(null);

  const [lastNameErr, setLastNameErr] = useState("");

  const [phoneNumber, setPhoneNumber] = useState(null);

  const [phoneErr, setPhoneErr] = useState("");

  const [altPhoneNumber, setAltPhoneNumber] = useState(null);

  const [altPhoneErr, setAltPhoneErr] = useState("");

  const [email, setEmail] = useState(null);

  const [emailErr, setEmailErr] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const [altEmail, setAltEmail] = useState("");

  const [altEmailErr, setAltEmailErr] = useState("");

  const [fax, setFax] = useState(null);

  const [faxErr, setFaxErr] = useState("");

  const [altFax, setAltFax] = useState("");

  const [altFaxErr, setAltFaxErr] = useState("");

  const [addr1, setAddr1] = useState("");

  const [addr1Err, setAddr1Err] = useState("");

  const [addr2, setAddr2] = useState("");

  const [addr2Err, setAddr2Err] = useState("");

  const [addr3, setAddr3] = useState("");

  const [region, setRegion] = useState("");

  const [regionErr, setRegionErr] = useState("");

  const [city, setCity] = useState("");

  const [cityErr, setcityErr] = useState("");

  const [states, setStates] = useState(null);

  const [statesErr, setStatesErr] = useState("");

  const [zip, setZip] = useState("");

  const [zipErr, setZipErr] = useState("");

  const [pageRefresh, setPageRefresh] = useState(false);

  const [dataId, setDataId] = useState(null);

  const [countryErr, setCountryErr] = useState("");

  const [addressTypeErr, setAddressTypeErr] = useState("");

  const [companyErr, setCompanyErr] = useState("");

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [rolesName, setRolesName] = useState("");

  const [userId, setUserId] = useState(null);

  const [passwordErr, setPasswordErr] = useState("");

  const [oldpasswordErr, setOldPasswordErr] = useState("");

  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const [allCompanies, setAllCompanies] = useState([]);

  const [company, setCompany] = useState(null);

  const [allRoles, setAllRoles] = useState([]);

  const [role, setRole] = useState(null);

  const [tempProduct, setTempProduct] = useState([]);

  const [isSort, setIsSort] = useState(false);

  const [addressData, setAddressData] = useState(null);

  const [contactDetails, setContactDeatils] = useState(null);

  const [companyDetails, setCompanyDetails] = useState(null);

  const [allAddressType, setAllAddressType] = useState([]);

  const [addressType, setAddressType] = useState(null);

  const [allStates, setAllStates] = useState([]);

  const [allCountries, setAllCountries] = useState([]);

  const [countryData, setCountryData] = useState(null);

  const [stateValue, setStateValue] = useState(null);

  const [countryValue, setCountryValue] = useState(null);

  const [filteredInput, setFilteredInput] = useState("");

  const [superAdminId, setSuperAdminId] = useState(null);

  const [superAdminMainId, setSuperAdminMainId] = useState(null);

  const [isPrimary, setIsPrimary] = useState([]);

  const [activeIndex, setActiveIndex] = useState(0);

  const [phoneData, setPhoneData] = useState("");

  const [altPhoneData, setAltPhoneData] = useState("");

  const [checked, setChecked] = useState(true);

  const [checked1, setChecked1] = useState(true);

  const [isClient, setIsClient] = useState(false);

  const [allStatus, setAllStatus] = useState([]);

  const [submitted, setSubmitted] = useState(false);

  const [managerId, setManagerId] = useState(null);

  // Function to extract dial code from phone number
  const extractDialCode = (phoneNumber) => {
    const dialCode = phoneNumber.match(/^\+\d+/);
    return dialCode ? dialCode[0] : null;
  };

  // Function to find country by dial code
  const findCountryByDialCode = (dialCode) => {
    return countries.find((country) => country.dialCode === dialCode);
  };

  useEffect(() => {
    MasterServices.getAll("/core/api/statusMaster/getAll")
      .then((res) => {
        console.log(res);
        if (Array.isArray(res.data)) {
          const temp = res.data.filter(
            (val) => val.statusForId.statusForName === "USER"
          );
          console.log(temp);

          if (Array.isArray(temp)) {
            if (superAdminId || superAdminMainId) {
              setAllStatus(temp);
            } else {
              const tempNew = temp.filter(
                (val) => val.statusName !== "BLOCKED"
              );
              setAllStatus(tempNew);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [globalCompany, superAdminId]);

  const handleCheck = (event) => {
    setChecked(event.target.checked);
    if (data && dataId) {
      const actualdata = data.contactDetails;
      actualdata["statusId"] = event.target.checked === true ? 1 : 2;

      console.log(actualdata);

      ContactService.updateContact(actualdata, dataId)
        .then((res) => {
          console.log(res);
          setIsEditing1(false);
          setPageRefresh(!pageRefresh);
        })
        .catch((err) => {
          console.log(err);
        });
      const statusId = event.target.checked === true ? 1 : 2;
      UserServices.updateUserStatus(userId, statusId)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCheck1 = (event) => {
    setChecked1(event.target.checked);
    if (data && dataId) {
      const actualdata = data.contactDetails;
      actualdata["statusId"] = event.target.checked === true ? 1 : 2;

      console.log(actualdata);

      ContactService.updateContact(actualdata, dataId)
        .then((res) => {
          console.log(res);
          setIsEditing1(false);
          setPageRefresh(!pageRefresh);
        })
        .catch((err) => {
          console.log(err);
        });
      const statusId = event.target.checked === true ? 1 : 3;
      UserServices.blockedUser(userId)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [products, setProducts] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];

  console.log(superAdminId);

  useEffect(() => {
    setCountryData(3);
    companyMasterService
      .getAllCompany()
      .then((res) => {
        console.log(res);
        setAllCompanies(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    rolesMasterService
      .getAllRoles()
      .then((res) => {
        console.log(res);
        if (superAdminId) {
          const temp = res.data.filter(
            (val) => val.id === 2 || val.id === 3 || val.id === 1
          );
          setAllRoles(temp);
        } else {
          const temp = res.data.filter((val) => val.id === 2 || val.id === 3);
          console.log(temp);
          setAllRoles(temp);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    AddressService.getAllAddressType()
      .then((res) => {
        setAllAddressType(res.data);
        console.log(res.data);
        if (Array.isArray(res.data)) {
          const temp = res.data.filter((val) => val.type === "CONTACT");
          if (temp.length > 0) {
            setAddressType(temp[0]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    AddressService.getAllCountry()
      .then((res) => {
        console.log(res);
        setAllCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    AddressService.getAllStates()
      .then((res) => {
        console.log(res);
        setAllStates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log("first");
    AddressService.getAllAddressByContactId(dataId)
      .then((res) => {
        console.log(res);
        if (res.data !== "") {
          const tempArr = res.data;
          const temp = tempArr.filter(
            (val) => val.addressType.type === "CONTACT"
          );
          console.log(temp);
          if (temp.length > 0) {
            setAddressData(temp[0]);
            setAddr1(temp[0].addressLine1);
            setAddr2(temp[0].addressLine2);
            setCity(temp[0].city);
            setZip(temp[0].postalCode);
            setStates(temp[0].stateId);
            // setCountryData(temp[0].countryId)
            setAddressType(temp[0].addressType);
            setContactDeatils(temp[0].contactDetails);
            const temps = allStates.filter((val) => val.id === temp[0].stateId);
            const temp1 = allCountries.filter(
              (val) => val.id === temp[0].countryId
            );
            console.log(temps);
            if (temps.length > 0) {
              setStateValue(temps[0].stateFullDesc);
            }
            if (temp1.length > 0) {
              setCountryValue(temp1[0].countryFullDesc);
            }
            // setActiveIndex(0)
          }
        } else {
          setAddressData(null);
          setAddr1("");
          setAddr2("");
          setCity("");
          setZip("");
          setStates(null);
          // setCountryData(null)
          setStateValue(null);
          setCountryValue(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [data, dataId, isTabRefresh]);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    console.log("user", user);
    if (user) {
      UserServices.getEmployeeByUserId(user.id)
        .then((res) => {
          console.log(res);
          if (res.data) {
            setManagerId(res.data.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  const [loginDetails, setLoginDetails] = useState([
    { key: "Password", value: "dhdhdhdhhdhd" },
  ]);

  const [editLoginValues, setEditLoginValues] = useState(
    loginDetails.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {})
  );

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);

    setFilteredData(value);
    setGlobalFilterValue(value);
  };

  const handleLoginChange = (key, value) => {
    setEditLoginValues({ ...editLoginValues, [key]: value });
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        {selectedCountry ? (
          <span>
            <b>{selectedCountry.name}</b> selected.
          </span>
        ) : (
          "No country selected."
        )}
      </div>
    );
  };

  const [isEditing, setIsEditing] = useState(false);
  const [isEditing1, setIsEditing1] = useState(false);
  const [isEditing2, setIsEditing2] = useState(false);
  const [editValues, setEditValues] = useState();

  function checkIdsExist(arr, idsToCheck) {
    // Check if any of the idsToCheck are found in arr
    const foundIds = idsToCheck.filter((idToCheck) =>
      arr.some((item) => item.id === idToCheck)
    );
    return foundIds.length > 0 ? foundIds : null;
  }

  useEffect(() => {
    if (user) {
      console.log(globalCompany);
      if (globalCompany !== null && !managerId) {
        if (globalCompany.roles.length > 0) {
          // Check for both 1 and 4
          const ids = checkIdsExist(globalCompany.roles, [1, 4]);
          const superId = checkIdsExist(globalCompany.roles, [1]);
          console.log(ids);
          if (ids) {
            setSuperAdminId(ids); // Set the array of matched IDs
          }
          if (!ids || ids.length === 0) {
            setIsClient(true);
            setSuperAdminId(null);
          }
          if (superId) {
            setSuperAdminMainId(superId);
          } else {
            setSuperAdminMainId(null);
          }
        }
      } else {
        if (user && Array.isArray(user.companyRoles)) {
          const globalCompany = user.companyRoles[0];
          console.log(globalCompany);
          if (globalCompany.roles.length > 0) {
            // Check for both 1 and 4
            const ids = checkIdsExist(globalCompany.roles, [4]);
            const superId = checkIdsExist(globalCompany.roles, [1]);
            const superIds = checkIdsExist(globalCompany.roles, [1, 2]);

            console.log(ids);
            if (ids) {
              setSuperAdminId(ids); // Set the array of matched IDs
            }
            if (!ids || ids.length === 0) {
              setIsClient(true);
              setSuperAdminId(null);
            }
            if (superId) {
              setSuperAdminMainId(superId);
            } else {
              setSuperAdminMainId(null);
            }
          }
        }
      }
    }
  }, [globalCompany, managerId]);

  useEffect(() => {
    if (managerId) {
      companyMasterService
        .getAllCompanyByAccountManagerId(managerId)
        .then((res) => {
          console.log(res);
          console.log(res);
          let data = res.data;
          const allContacts = [];

          data.forEach((company) => {
            if (Array.isArray(company.contactDetails)) {
              company.contactDetails.forEach((contact) => {
                allContacts.push(contact);
              });
            }
          });
          console.log(allContacts);
          const sortedData = allContacts.sort(
            (a, b) =>
              new Date(b.contactDetails.lastModifiedDate) -
              new Date(a.contactDetails.lastModifiedDate)
          );
          console.log(sortedData);
          setProducts(sortedData);
          setTempProduct(sortedData);
          setShowSkeleton(true);
          console.log(data);
          if (allContacts.length > 0) {
            setSelectedProduct(allContacts[0]);

            if (allContacts[0].contactDetails !== undefined) {
              console.log(allContacts[0].contactDetails);
              setContactDeatils(allContacts[0].contactDetails);
              setDataId(allContacts[0].contactDetails.id);
              setUserId(allContacts[0].contactDetails.userId);
              setData(allContacts[0]);

              setSalutation(allContacts[0].contactDetails.salutation);
              setFirstName(allContacts[0].contactDetails.firstName);
              setLastName(allContacts[0].contactDetails.lastName);
              const matchingCompanies = allContacts[0].companyDetails.filter(
                (company) =>
                  company.primaryContactId?.id ===
                  allContacts[0].contactDetails.id
              );
              setIsPrimary(matchingCompanies);

              setEmail(allContacts[0].contactDetails.emailId);

              console.log(allContacts[0].contactDetails.phone);

              if (allContacts[0].contactDetails.phone) {
                const temp1 = allContacts[0].contactDetails.phone.split(" ");
                if (Array.isArray(temp1)) {
                  if (temp1.length > 0) {
                    // setSelectedCountry(temp1[0])
                    setAltPhoneNumber(temp1.slice(1).join(" "));
                  }
                }
              }
              if (allContacts[0].contactDetails.fax) {
                const temp2 = allContacts[0].contactDetails.fax.split(" ");
                if (Array.isArray(temp2)) {
                  if (temp2.length > 0) {
                    // setSelectedCountry(temp2[0])
                    setFax(temp2.slice(1).join(" "));
                  }
                }
              }

              setPhoneData(allContacts[0].contactDetails.mobileNo);

              setAltPhoneData(allContacts[0].contactDetails.phone);

              if (allContacts[0].contactDetails.mobileNo) {
                const temp3 = allContacts[0].contactDetails.mobileNo.split(" ");
                if (Array.isArray(temp3)) {
                  if (temp3.length > 0) {
                    setSelectedCountry(temp3[0]);
                    setPhoneNumber(temp3.slice(1).join(" "));
                  }
                }
              }

              if (allContacts[0].contactDetails.statusId === 1) {
                setChecked(true);
                setChecked1(true);
              } else if (allContacts[0].contactDetails.statusId === 2) {
                setChecked(false);
                setChecked1(true);
              } else if (allContacts[0].contactDetails.statusId === 3) {
                setChecked(false);
                setChecked1(false);
              }
            }

            if (allContacts[0].companyDetails !== undefined) {
              setCompanyDetails(allContacts[0].companyDetails);
              console.log(allContacts[0].companyDetails);
              if (Array.isArray(allContacts[0].companyDetails)) {
                console.log(globalCompany);
                if (globalCompany) {
                  const temp = allContacts[0].companyDetails.filter(
                    (val) => val.id === globalCompany.companyDetails.id
                  );
                  console.log(temp);
                  if (temp.length > 0) {
                    setCompany(temp[0]);
                  }
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [pageRefresh, superAdminId, isClient, superAdminMainId, managerId]);

  useEffect(() => {
    setActiveIndex(0);
  }, [selectedProduct]);

  useEffect(() => {
    if (data) {
      console.log(data);
      if (data.contactDetails !== undefined) {
        ContactService.getAllContactById(data.contactDetails.id)
          .then((res) => {
            console.log(res.data);
            if (res.data !== "") {
              console.log(res.data);

              setFirstName(res.data.contactDetails.firstName);
              setLastName(res.data.contactDetails.lastName);
              const matchingCompanies = data.companyDetails.filter(
                (company) =>
                  company.primaryContactId?.id === res.data.contactDetails.id
              );
              console.log(matchingCompanies);
              setIsPrimary(matchingCompanies);
              // setPhoneNumber(res.data.contactDetails.mobileNo)
              setEmail(res.data.contactDetails.emailId);
              // setFax(res.data.contactDetails.fax)
              setSalutation(res.data.contactDetails.salutation);
              console.log(res.data.contactDetails.phone);

              setPhoneData(res.data.contactDetails.mobileNo);

              setAltPhoneData(res.data.contactDetails.phone);

              if (res.data.contactDetails.phone) {
                const temp1 = res.data.contactDetails.phone.split(" ");
                if (Array.isArray(temp1)) {
                  if (temp1.length > 0) {
                    // setSelectedCountry(temp1[0])
                    setAltPhoneNumber(temp1.slice(1).join(" "));
                  }
                }
              } else {
                setAltPhoneNumber(null);
              }
              if (res.data.contactDetails.fax) {
                const temp2 = res.data.contactDetails.fax.split(" ");
                if (Array.isArray(temp2)) {
                  if (temp2.length > 0) {
                    // setSelectedCountry(temp2[0])
                    setFax(temp2.slice(1).join(" "));
                  }
                }
              } else {
                setFax(null);
              }

              if (res.data.contactDetails.mobileNo) {
                const temp3 = res.data.contactDetails.mobileNo.split(" ");
                if (Array.isArray(temp3)) {
                  if (temp3.length > 0) {
                    setSelectedCountry(temp3[0]);
                    setPhoneNumber(temp3.slice(1).join(" "));
                  }
                }
              } else {
                setPhoneNumber(null);
              }

              if (res.data.contactDetails.statusId === 1) {
                setChecked(true);
                setChecked1(true);
              } else if (res.data.contactDetails.statusId === 2) {
                setChecked(false);
                setChecked1(true);
              } else if (res.data.contactDetails.statusId === 3) {
                setChecked(false);
                setChecked1(false);
              }
              setUserId(res.data.contactDetails.userId);
              // setActiveIndex(0)
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [data, isTabRefresh]);

  const handleFilter = () => {
    if (tempProduct.length > 0 && company && !role) {
      const filteredContacts = tempProduct.filter((contact) =>
        contact.companyRoles.some(
          (role) => role.companyDetails.id === company.id
        )
      );
      console.log(filteredContacts);
      setProducts(filteredContacts);
      if (filteredContacts.length > 0) {
        setSelectedProduct(filteredContacts[0]);

        if (filteredContacts[0].contactDetails !== undefined) {
          setContactDeatils(filteredContacts[0].contactDetails);
          setDataId(filteredContacts[0].contactDetails.id);
          setUserId(filteredContacts[0].contactDetails.userId);
          setData(filteredContacts[0]);
          if (filteredContacts[0].contactDetails.mobileNo !== undefined) {
            const dialCode = extractDialCode(
              filteredContacts[0].contactDetails.mobileNo
            );
            console.log(dialCode);
            if (dialCode) {
              const country = findCountryByDialCode(dialCode);
              if (country) {
                setSelectedCountry(country);
              }
            }
          }
        }

        if (filteredContacts[0].companyDetails !== undefined) {
          setCompanyDetails(filteredContacts[0].companyDetails);
        }
      }
    }
    if (tempProduct.length > 0 && role && !company) {
      const filteredContactsByRole = tempProduct.filter((contact) =>
        contact.companyRoles.some((companyRole) =>
          companyRole.roles.some((r) => r.id === role.id)
        )
      );
      setProducts(filteredContactsByRole);
      if (filteredContactsByRole.length > 0) {
        setSelectedProduct(filteredContactsByRole[0]);

        if (filteredContactsByRole[0].contactDetails !== undefined) {
          setContactDeatils(filteredContactsByRole[0].contactDetails);
          setDataId(filteredContactsByRole[0].contactDetails.id);
          setUserId(filteredContactsByRole[0].contactDetails.userId);
          setData(filteredContactsByRole[0]);
          if (filteredContactsByRole[0].contactDetails.mobileNo !== undefined) {
            const dialCode = extractDialCode(
              filteredContactsByRole[0].contactDetails.mobileNo
            );
            console.log(dialCode);
            if (dialCode) {
              const country = findCountryByDialCode(dialCode);
              if (country) {
                setSelectedCountry(country);
              }
            }
          }
        }

        if (filteredContactsByRole[0].companyDetails !== undefined) {
          setCompanyDetails(filteredContactsByRole[0].companyDetails);
        }
      }
    }
    if (tempProduct.length > 0 && company && role) {
      const filteredContactsByRole = tempProduct.filter((contact) =>
        contact.companyRoles.some(
          (companyRole) =>
            companyRole.roles.some((r) => r.id === role.id) &&
            contact.companyRoles.some(
              (role) => role.companyDetails.id === company.id
            )
        )
      );
      setProducts(filteredContactsByRole);
      if (filteredContactsByRole.length > 0) {
        setSelectedProduct(filteredContactsByRole[0]);

        if (filteredContactsByRole[0].contactDetails !== undefined) {
          setContactDeatils(filteredContactsByRole[0].contactDetails);
          setDataId(filteredContactsByRole[0].contactDetails.id);
          setUserId(filteredContactsByRole[0].contactDetails.userId);
          setData(filteredContactsByRole[0]);
          if (filteredContactsByRole[0].contactDetails.mobileNo !== undefined) {
            const dialCode = extractDialCode(
              filteredContactsByRole[0].contactDetails.mobileNo
            );
            console.log(dialCode);
            if (dialCode) {
              const country = findCountryByDialCode(dialCode);
              if (country) {
                setSelectedCountry(country);
              }
            }
          }
        }

        if (filteredContactsByRole[0].companyDetails !== undefined) {
          setCompanyDetails(filteredContactsByRole[0].companyDetails);
        }
      }
    }
    if (filteredInput !== "") {
      const temp = products.filter((val) => {
        const fullName =
          `${val.contactDetails.firstName} ${val.contactDetails.lastName}`.toLowerCase();
        return fullName.includes(filteredInput.toLowerCase());
      });
      setProducts(temp);
      if (temp.length > 0) {
        setSelectedProduct(temp[0]);

        if (temp[0].contactDetails !== undefined) {
          setContactDeatils(temp[0].contactDetails);
          setDataId(temp[0].contactDetails.id);
          setUserId(temp[0].contactDetails.userId);
          setData(temp[0]);
          if (temp[0].contactDetails.mobileNo !== undefined) {
            const dialCode = extractDialCode(temp[0].contactDetails.mobileNo);
            console.log(dialCode);
            if (dialCode) {
              const country = findCountryByDialCode(dialCode);
              if (country) {
                setSelectedCountry(country);
              }
            }
          }
        }

        if (temp[0].companyDetails !== undefined) {
          setCompanyDetails(temp[0].companyDetails);
        }
      }
    }
  };

  const handleSearch = () => {
    if (filteredInput !== "") {
      const lowerCaseInput = filteredInput.toLowerCase();

      const searchNestedObject = (obj) => {
        for (const key in obj) {
          if (obj[key] !== null) {
            if (typeof obj[key] === "object") {
              if (searchNestedObject(obj[key])) {
                return true;
              }
            } else if (
              typeof obj[key] === "string" &&
              obj[key].toLowerCase().includes(lowerCaseInput)
            ) {
              return true;
            } else if (
              typeof obj[key] === "number" &&
              obj[key] == lowerCaseInput
            ) {
              return true;
            }
          }
        }
        return false;
      };

      const temp = tempProduct.filter((val) => searchNestedObject(val));
      setProducts(temp);
      if (temp.length > 0) {
        setSelectedProduct(temp[0]);

        if (temp[0].contactDetails !== undefined) {
          setContactDeatils(temp[0].contactDetails);
          setDataId(temp[0].contactDetails.id);
          setUserId(temp[0].contactDetails.userId);
          setData(temp[0]);
          if (temp[0].contactDetails.mobileNo !== undefined) {
            const dialCode = extractDialCode(temp[0].contactDetails.mobileNo);
            console.log(dialCode);
            if (dialCode) {
              const country = findCountryByDialCode(dialCode);
              if (country) {
                setSelectedCountry(country);
              }
            }
          }
        }

        if (temp[0].companyDetails !== undefined) {
          setCompanyDetails(temp[0].companyDetails);
        }
      }
    } else {
      setProducts(tempProduct);
    }
  };

  const handleClearFilter = () => {
    setFilteredInput("");
    setRole(null);
    setCompany(null);
    setProducts(tempProduct);
  };

  const handleAddressEdit = () => {
    console.log(addressData);
    if (addressData) {
      const actualdata = addressData;
      actualdata["addressLine1"] = addr1;
      actualdata["addressLine2"] = addr2;
      actualdata["city"] = city;
      actualdata["stateId"] = states;
      actualdata["countryId"] = countryData;
      actualdata["postalCode"] = zip;

      actualdata["addressType"] = addressType;

      if (
        addr1 !== "" &&
        addr2 !== "" &&
        city !== "" &&
        zip !== "" &&
        contactDetails &&
        addressType
      ) {
        setIsTabRefresh(true);
        AddressService.updateAllAddress(actualdata)
          .then((res) => {
            console.log(res.data);
            setIsEditing2(false);
            setIsTabRefresh(false);
            // if (toast.current !== undefined) {
            //     toast.current.show({
            //         severity: 'success', summary: 'Address updated successfully', life: 3000,
            //         content: (props) => (
            //             <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
            //                 <div className="flex align-items-center gap-2">
            //                     {/* <Avatar shape="circle" style={{ backgroundColor: '#0047FF', color: '#ffffff' }}>
            //                         <div dangerouslySetInnerHTML={{ __html: svgContent }} />
            //                     </Avatar> */}
            //                     <span className="p-toast-summary">vState Filings</span>
            //                 </div>
            //                 <div className="p-toast-detail">{props.message.summary}</div>
            //                 {/* <Button className=" signup-btn vstate-button flex" label="Ok" severity="success" onClick={() => navigate("/signin")}></Button> */}
            //             </div>
            //         )
            //     });
            // }

            showToast("success", "Address updated successfully");
          })
          .catch((err) => {
            console.log(err);
            setIsTabRefresh(false);
            // if (toast.current !== undefined) {
            //     toast.current.show({
            //         severity: 'warn', summary: 'Something went wrong', life: 3000

            //     })
            // }
            showToast("error", "Something went wrong");
          });
      } else {
        if (addr1 === "") {
          setAddr1Err(errorMessages.address1Required);
        }
        if (addr2 === "") {
          setAddr2Err(errorMessages.address2Required);
        }
        if (city === "") {
          setcityErr(errorMessages.cityRequired);
        }
        if (zip === "") {
          setZipErr(errorMessages.zipRequired);
        }
        if (!company) {
          setCompanyErr(errorMessages.companyRequired);
        }

        if (!addressType) {
          setAddressTypeErr(errorMessages.addressTypeRequired);
        }

        if (!states) {
          setStatesErr(errorMessages.statesRequired);
        }

        if (!countryData) {
          setCountryErr(errorMessages.countryRequired);
        }
      }
    }
  };

  const handleAddressSave = () => {
    const actualdata = {
      addressLine1: addr1,
      addressLine2: addr2,
      city: city,
      stateId: states,
      postalCode: zip,
      countryId: countryData,
      contactDetails: contactDetails,
      companyDetails: null,
      addressType: addressType,
    };

    if (
      addr1 !== "" &&
      addr2 !== "" &&
      city !== "" &&
      zip !== "" &&
      contactDetails &&
      addressType &&
      countryData &&
      states
    ) {
      setIsTabRefresh(true);
      AddressService.saveAllAddress(actualdata)
        .then((res) => {
          console.log(res);
          setIsEditing2(false);
          setIsTabRefresh(false);
          // if (toast.current !== undefined) {
          //     toast.current.show({
          //         severity: 'success', summary: 'Address added successfully', life: 3000,
          //         content: (props) => (
          //             <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
          //                 <div className="flex align-items-center gap-2">
          //                     {/* <Avatar shape="circle" style={{ backgroundColor: '#0047FF', color: '#ffffff' }}>
          //                         <div dangerouslySetInnerHTML={{ __html: svgContent }} />
          //                     </Avatar> */}
          //                     <span className="p-toast-summary">vState Filings</span>
          //                 </div>
          //                 <div className="p-toast-detail">{props.message.summary}</div>
          //                 {/* <Button className=" signup-btn vstate-button flex" label="Ok" severity="success" onClick={() => navigate("/signin")}></Button> */}
          //             </div>
          //         )
          //     });
          // }

          showToast("success", "Address added successfully");
        })
        .catch((err) => {
          console.log(err);
          setIsTabRefresh(false);
          // if (toast.current !== undefined) {
          //     toast.current.show({
          //         severity: 'warn', summary: 'Something went wrong', life: 3000

          //     })
          // }
          showToast("error", "Something went wrong");
        });
    } else {
      if (addr1 === "") {
        setAddr1Err(errorMessages.address1Required);
      }
      if (addr2 === "") {
        setAddr2Err(errorMessages.address2Required);
      }
      if (city === "") {
        setcityErr(errorMessages.cityRequired);
      }
      if (zip === "") {
        setZipErr(errorMessages.zipRequired);
      }

      if (!company) {
        setCompanyErr(errorMessages.companyRequired);
      }

      if (!addressType) {
        setAddressTypeErr(errorMessages.addressTypeRequired);
      }

      if (!states) {
        setStatesErr(errorMessages.statesRequired);
      }

      if (!countryData) {
        setCountryErr(errorMessages.countryRequired);
      }
    }
  };

  const handleRedirectData = (data) => {
    setRedirectedData(data);
    history("/companydash");
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleEdit1 = (rowdata) => {
    setIsEditing1(true);
    if (rowdata.contactDetails !== undefined) {
      setDataId(rowdata.contactDetails.id);
    }
  };

  const handleEdit2 = () => {
    setIsEditing2(true);
  };

  const handlePassword = (e) => {
    setPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setPasswordErr(errorMessages.passwordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setPasswordErr(errorMessages.passwordValidation);
      }
    }
    setPassword(e.target.value);
  };

  const handleOldPassword = (e) => {
    setOldPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setOldPasswordErr(errorMessages.oldPasswordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setOldPasswordErr(errorMessages.oldPasswordValidation);
      }
    }
    setOldPassword(e.target.value);
  };

  const handleEmail = (e) => {
    setEmailErr("");
    let value = e.target.value;
    if (value === "") {
      setEmailErr(errorMessages.emailRequired);
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      setEmailErr(errorMessages.emailValidation);
    }
    setEmail(e.target.value);
  };

  const handleLastName = (e) => {
    setLastNameErr("");
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/^[a-zA-Z]+([a-zA-Z]+)*$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setLastName(e.target.value);
  };

  const handleFirstName = (e) => {
    setFirstNameErr("");
    let value = e.target.value;
    if (value === "") {
      setFirstNameErr(errorMessages.firstNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/^[a-zA-Z]+([a-zA-Z]+)*$/.test(value)) {
        setFirstNameErr(errorMessages.firstNameValidation);
      }
    }
    setFirstName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;
    if (value === "") {
      setPhoneErr(errorMessages.phoneRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(value)
      ) {
        setPhoneErr(errorMessages.phoneValidation);
      }
    }
    setPhoneNumber(e.target.value);
  };

  const handleAlternatePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;

    if (value && value.trim() !== "") {
      if (
        !/^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(value)
      ) {
        setPhoneErr(errorMessages.phoneValidation);
      }
    }
    setAltPhoneNumber(e.target.value);
  };

  const handleFax = (e) => {
    setFaxErr("");
    let value = e.target.value;

    if (value && value.trim() !== "") {
      if (
        !/^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(value)
      ) {
        setFaxErr(errorMessages.faxValidation);
      }
    }
    setFax(e.target.value);
  };

  const handleZip = (e) => {
    setZipErr("");
    let value = e.target.value;

    if (value && value.trim() !== "") {
      if (!/^\d+$/.test(value)) {
        setZipErr("Enter valid zip code");
      }
    }
    setZip(e.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };

  const handleConfirmPassword = (e) => {
    setConfirmPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
          value
        )
      ) {
        setConfirmPasswordErr(errorMessages.confirmPasswordValidation);
      }
    }
    setConfirmPassword(e.target.value);
  };

  const handleChange = (key, value) => {
    setEditValues({ ...editValues, [key]: value });
  };

  const handleSave2 = () => {
    console.log(addressData);
    if (addressData) {
      handleAddressEdit();
    } else {
      handleAddressSave();
    }
  };

  const handleSave = () => {
    console.log(selectedCountry);

    if (data && dataId) {
      const actualdata = data.contactDetails;
      const actualdata1 = {
        id: data.contactDetails.id,
        salutation: salutation,
        jobTitle: "Java Developer",
        contactSourceId: null,
        recordTypeId: null,
        avatarImgPath: null,
        phone: altPhoneNumber ? `${selectedCountry} ${altPhoneNumber}` : null,
        fax: fax ? `${selectedCountry} ${fax}` : null,
        statusId: 1,
      };

      console.log(actualdata);

      if (firstName && lastName && phoneNumber && email) {
        setIsTabRefresh(true);
        ContactService.updateContact(actualdata1, dataId)
          .then((res) => {
            console.log(res);
            setIsEditing1(false);
            setIsTabRefresh(false);
            setPageRefresh(!pageRefresh);
            // if (toast.current !== undefined) {
            //     toast.current.show({
            //         severity: 'success', summary: 'Profile updated successfully', life: 3000,
            //         content: (props) => (
            //             <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
            //                 <div className="flex align-items-center gap-2">
            //                     {/* <Avatar shape="circle" style={{ backgroundColor: '#0047FF', color: '#ffffff' }}>
            //                         <div dangerouslySetInnerHTML={{ __html: svgContent }} />
            //                     </Avatar> */}
            //                     <span className="p-toast-summary">vState Filings</span>
            //                 </div>
            //                 <div className="p-toast-detail">{props.message.summary}</div>
            //                 {/* <Button className=" signup-btn vstate-button flex" label="Ok" severity="success" onClick={() => navigate("/signin")}></Button> */}
            //             </div>
            //         )
            //     });
            // }

            showToast("success", "Profile updated successfully");
          })
          .catch((err) => {
            console.log(err);
            setIsTabRefresh(false);
            // if (toast.current !== undefined) {
            //     toast.current.show({
            //         severity: 'warn', summary: 'Something went wrong', life: 3000

            //     })
            // }
            showToast("error", "Something went wrong");
          });
      } else {
        if (!firstName) {
          setFirstNameErr(errorMessages.firstNameRequired);
        }
        if (!lastName) {
          setLastNameErr(errorMessages.lastNameRequired);
        }
        if (!phoneNumber) {
          setPhoneErr(errorMessages.phoneRequired);
        }
        if (!email) {
          setEmailErr(errorMessages.emailRequired);
        }
      }
    }
  };

  const handleToggle = () => {
    setIsShowContent(!isShowContent);
  };

  const handleToggle1 = () => {
    setIsShowContent1(!isShowContent1);
  };

  const handleToggle2 = () => {
    setIsShowContent2(!isShowContent2);
  };

  const handleToggle3 = () => {
    setIsShowContent3(!isShowContent3);
  };

  const sortData = (field) => {
    if (field === sortField) {
      // If clicking on the same field, toggle the sort order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // If clicking on a different field, set the new field and default to ascending order
      setSortField(field);
      setSortOrder("asc");
    }
    const sortedData = [...products].sort((a, b) => {
      console.log(a);
      if (a.contactDetails !== undefined && b.contactDetails !== undefined) {
        const valueA = a.contactDetails[field];
        const valueB = b.contactDetails[field];

        if (valueA < valueB) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortOrder === "asc" ? 1 : -1;
        }
      }

      return 0;
    });
    console.log(sortedData);

    setProducts(sortedData);
  };

  const handleFbackHeader = () => {
    return (
      <>
        <div className="font-fam-for-all form-md-heading form-sm-close-Btn">
          Pre-Screening Feedback
          <button className="form-close-Btn" onClick={() => setVisible6(false)}>
            <i className="pi pi-times"></i>
          </button>
        </div>
      </>
    );
  };

  const handleSkill = (e) => {
    setSkills(e.target.value);
  };

  const handleQualification = (e) => {
    setQualification(e.target.value);
  };

  const PasswordText = (text) => {
    const maskedText = text.replace(/./g, "*");
    return maskedText;
  };

  const handleSave1 = () => {
    const newData = loginDetails.map((item) => ({
      ...item,
      value: editLoginValues[item.key],
    }));
    setLoginDetails(newData);

    if (
      password !== "" &&
      confirmedPassword !== "" &&
      password === confirmedPassword
    ) {
      setIsTabRefresh(true);
      ForgotPasswordService.resetPassword(confirmedPassword, email)
        .then((res) => {
          console.log(res);
          setIsEditing(false);
          setIsTabRefresh(false);
          setPassword("");
          setConfirmPassword("");
          if (toast.current !== null) {
            if (res.data === "Password changed successfully!") {
              // toast.current.show({ severity: 'success', summary: 'Success', detail: 'Password updated successfully', life: 2800 });
              showToast("success", "Password updated successfully");
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setIsTabRefresh(false);
          if (err.response !== undefined) {
            if (err.response.status !== undefined) {
              if (err.response.status === 701) {
                // if (toast.current !== undefined) {
                //     toast.current.show({
                //         severity: 'warn', summary: 'Warning',

                //         content: (props) => (
                //             <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
                //                 <div className="flex align-items-center gap-2">

                //                     <span className="p-toast-summary">vState Filings</span>
                //                 </div>
                //                 <div className="p-toast-detail">Password has been used recently</div>
                //             </div>
                //         ),
                //         life: 3000,
                //     });

                // }
                showToast("error", "Something went wrong");
              }
            }
          }
        });
    } else {
      if (confirmedPassword === "") {
        setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
      }
      if (password === "") {
        setPasswordErr(errorMessages.passwordRequired);
      }

      if (password !== confirmedPassword) {
        // setVisible(true)
        // if (toast.current !== undefined) {
        //     toast.current.show({
        //         severity: 'warn', summary: 'Warning',

        //         content: (props) => (
        //             <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
        //                 <div className="flex align-items-center gap-2">

        //                     <span className="p-toast-summary">vState Filings</span>
        //                 </div>
        //                 <div className="p-toast-detail">{errorMessages.passwordMatched}</div>
        //             </div>
        //         ),
        //         life: 3000
        //     });

        // }
        showToast("error", `${errorMessages.passwordMatched}`);

        // setGlobalErr(errorMessages.passwordMatched)
      }
    }
  };

  const allSortName = ["name", "location", "experienc"];

  const handleSortName = (e) => {
    setSortName(e.target.value);
    console.log(e.target.value);
    sortData(e.target.value);
  };

  const handlePosition = (e) => {
    const temp = e.target.value;
    setPosition(e.target.value);
    if (temp.jobdescription.skill !== null) {
      setSkills(temp.jobdescription.skill);
    }
    if (temp.jobdescription.experience !== null) {
      const tempexp = temp.jobdescription.experience;
      const arrexp = tempexp.split("-");
      console.log(arrexp);
      if (arrexp.length === 2) {
        setExp1(arrexp[0]);
        setExp2(arrexp[1]);
      }
    }
  };

  const handleClearPosition = () => {
    setVisible1(false);
    setVisible2(false);
    setPosition(null);
    setPositionData(null);
  };

  const handlePositionData = (e) => {
    setProducts(AllCandidate);
    const temp = e.target.value;
    setPositionData(e.target.value);
  };

  const SortingFilter = () => {
    return (
      <>
        <div>
          <Dropdown
            value={sortName}
            options={allSortName}
            onChange={handleSortName}
            placeholder="Sorting By"
            filter
            resetFilterOnHide
            className="filter-dropdown-field"
            required
          />
        </div>
      </>
    );
  };

  const handleExp1 = (e) => {
    setProducts(AllCandidate);
    setExp1(e.target.value);
  };

  const handleExp2 = (e) => {
    setProducts(AllCandidate);
    setExp2(e.target.value);
  };

  const listExp = [];
  for (let i = 0; i < 50; i++) {
    listExp.push(`${i}`);
  }

  const filterData = (event) => {
    const filteredItems = products.filter((item) => {
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const element = item[key].toString();
          console.log(element);

          if (
            element.toLowerCase().includes(event.target.value.toLowerCase())
          ) {
            return item;
          }
        }
      }
    });
    setFilteredData(filteredItems);
  };

  // effect of filter all

  function checkAllExist(a, b) {
    // Convert all elements of array b to lowercase for case-insensitive comparison
    const lowercaseB = b.map((item) => item.toLowerCase());

    for (const element of a) {
      // Convert element to lowercase for case-insensitive comparison
      const lowercaseElement = element.toLowerCase();

      if (!lowercaseB.includes(lowercaseElement)) {
        return false; // If any element of a is not found in b, return false
      }
    }
    return true; // All elements of a were found in b
  }

  function convertMonthsToYears(years) {
    return years * 12;
  }

  // event on checkbox
  const onCheckboxChange = (event, data) => {
    console.log(event.checked);
    console.log(data);
    if (event.checked) {
      setSelectedData([...selectedData, data]);
    } else {
      setSelectedData(selectedData.filter((item) => item !== data));
    }
  };

  const handleChecked = (e) => {
    const checked = e.checked;
    if (checked) {
      setSelectedData(products);
    } else {
      setSelectedData([]);
    }
  };

  console.log(selectedData);

  // DataTable columns
  const cols = [
    { field: "id", header: "id" },
    { field: "fullName", header: "Name" },
    { field: "skill", header: "Skills" },
    { field: "experienceInYears", header: "Experience" },
    { field: "city", header: "Location" },
  ];

  // Export DataTable data
  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const CloseProgresbarheader1 = () => {
    setCloseProgresbar1(false);
  };

  const handleClick3 = () => {
    setVisible(false);
  };

  // Function to check if object exists in array
  function objectExists(array, obj) {
    return array.some((item) => JSON.stringify(item) === JSON.stringify(obj));
  }

  const getInitials = (name) => {
    const nameArray = name.trim().split(" ");
    if (nameArray.length >= 2) {
      const firstNameInitial = nameArray[0].charAt(0).toUpperCase();
      const lastNameInitial = nameArray[1].charAt(0).toUpperCase();
      return firstNameInitial + lastNameInitial;
    } else {
      const initial = nameArray[0].charAt(0).toUpperCase();
      return initial + initial;
    }
  };

  const getStatus = (id) => {
    if (allStatus.length > 0) {
      const temp = allStatus.find((val) => val.id === id);
      if (temp) {
        return temp.statusName;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const itemTemplate = (data) => {
    console.log(data);
    console.log(selectedData);

    if (data.contactDetails !== undefined && data.companyRoles !== undefined) {
      const fullName = `${data.contactDetails.firstName} ${data.contactDetails.lastName}`;
      return (
        <div className="srch-cand-card font-fam-for-all">
          <div className="grid w-full">
            <div className="col-8 flex gap-2" style={{ width: "250px" }}>
              <div>
                <Avatar
                  label={
                    data.contactDetails.avatarImgPath
                      ? ""
                      : getInitials(fullName)
                  }
                  image={
                    data.contactDetails.avatarImgPath
                      ? data.contactDetails.avatarImgPath
                      : ""
                  }
                  icon="pi pi-user"
                  size="large"
                  shape="circle"
                  className="custom-avatar"
                />
              </div>
              <div className="grid">
                <div className="col-12 pb-0">
                  <p className=" srch-cand-text1">{`${data.contactDetails.firstName} ${data.contactDetails.lastName}`}</p>
                </div>
                <div className="col-12 pb-0">
                  <p className=" srch-cand-text">
                    {data.companyRoles.length > 0
                      ? data.companyRoles[0].roles.length > 0
                        ? data.companyRoles[0].roles[0].roleName
                        : ""
                      : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 flex align-items-center justify-content-end">
              <>
                <div className="badge-container">
                  <div
                    className="badge-main grid"
                    style={
                      data.contactDetails.statusId !== 1
                        ? { background: "#FEF3F2" }
                        : { background: "#ECFDF3" }
                    }
                  >
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 flex align-items-center p-0">
                      {data.contactDetails.statusId !== 1 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                        >
                          <circle cx="4" cy="4" r="3" fill="#F04438" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                        >
                          <circle cx="4" cy="4" r="3" fill="#12B76A" />
                        </svg>
                      )}
                      <span
                        className="badge-label ml-1"
                        style={
                          data.contactDetails.statusId !== 1
                            ? { color: "#B42318" }
                            : { color: "#027A48" }
                        }
                      >
                        {" "}
                        {getStatus(data.contactDetails.statusId)}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const CloseProgresbarheader = () => {
    setCloseProgresbar(false);
  };

  const searchCandHeader = () => {
    return (
      <div className="font-fam-for-all flex flex-column gap-3">
        <p className="srch-cand-header mb-0 table-cand">
          {superAdminId ? "Clients" : "Users"}
          <Tag
            className="badge-cand"
            value={
              filteredData.length > 0 ? filterData.length : products.length
            }
          ></Tag>
        </p>

        <div className="card-devider"></div>

        <div className="srch-cand-card font-fam-for-all ">
          {/* <Checkbox className='mb-1'
                        checked={selectedData.length === products.length}
                        onChange={handleChecked}
                    ></Checkbox> */}

          <div style={{ width: "400px", paddingLeft: "1rem" }}>
            <button
              style={{ backgroundColor: "#ffff" }}
              className="table-cand srch-cand-text"
              onClick={() => {
                sortData("firstName");
                setIsSort(!isSort);
              }}
            >
              Name
              <i
                className={
                  isSort
                    ? "pi pi-arrow-up srch-cand-text ml-1"
                    : "pi pi-arrow-down srch-cand-text ml-1"
                }
                style={{ fontSize: "14px" }}
              ></i>
            </button>

            {/* <button style={{ backgroundColor: '#ffff' }} className='table-cand' onClick={() => sortData("firstName")}> <i className='pi pi-paperclip ml-3'></i> Attach Profiles</button> */}
          </div>
        </div>
      </div>
    );
  };

  const onRowSelect = (rowData) => {
    console.log(rowData);
    setData(rowData.data);
    if (rowData.data.contactDetails !== undefined) {
      setContactDeatils(rowData.data.contactDetails);
      setDataId(rowData.data.contactDetails.id);
      if (rowData.data.contactDetails.emailId) {
        setEmail(rowData.data.contactDetails.emailId);
        if (rowData.data.contactDetails.mobileNo !== undefined) {
          const dialCode = extractDialCode(
            rowData.data.contactDetails.mobileNo
          );
          console.log(dialCode);
          if (dialCode) {
            const country = findCountryByDialCode(dialCode);
            if (country) {
              setSelectedCountry(country);
            }
          }
        }
      }
    }
    if (rowData.data.companyDetails !== undefined) {
      setCompanyDetails(rowData.data.companyDetails);
    }
  };

  console.log(data);

  const sidebarHeader = () => (
    <>
      <div
        style={{
          backgroundColor: "#002592",
          width: "contentFit",
          height: "40px",
          position: "sticky",
        }}
        className="sidebar-head"
      >
        <Button
          className="pi pi-times sidebar-close-btn"
          onClick={() => setFilters(false)}
        ></Button>
      </div>
    </>
  );

  const items = Array.from({ length: 5 }, (v, i) => i);

  function capitalizeFirstLetter(str) {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <>
      <Toast ref={toast} />

      <div className="grid webapp-content-container">
        <div className="col-12 ">
          <div className="card search-cand-card2">
            <div className="col-12 grid font-fam-for-all py-3 px-3">
              <div
                className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7"
                style={{ paddingLeft: "20px" }}
              >
                <p className="search-cand-header mb-0">
                  {superAdminId ? "Clients" : "Users"}
                </p>
                <p className="search-cand-sub-header mb-0">
                  {superAdminId
                    ? "Create new clients, manage client account details."
                    : "Create new users, customize user permissions or remove users from your account."}
                </p>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                {hasPermission("AllowCreateUser") ? (
                  <div className="flex align-items-center justify-content-end gap-2 mt-3">
                    <Button
                      className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                      icon="pi pi-download"
                      label="Bulk Upload"
                      onClick={(e) => setVisible4(true)}
                    ></Button>
                    <Button
                      className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
                      label={superAdminId ? "Add New Client" : "Add New User"}
                      icon="pi pi-plus"
                      onClick={() => setVisible(true)}
                    ></Button>
                  </div>
                ) : (
                  <div className="flex align-items-center justify-content-end gap-2 mt-3">
                    <Button
                      className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                      icon="pi pi-download"
                      label="Bulk Upload"
                      onClick={(e) => setVisible4(true)}
                    ></Button>
                    <Button
                      className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
                      label={superAdminId ? "Add New Client" : "Add New User"}
                      icon="pi pi-plus"
                      onClick={() => setVisible(true)}
                    ></Button>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="col-12 " style={{ padding: '0px', height: '60px' }}>
                    <p className='panel-headers font-fam-for-all datatable-sm d-flex align-items-center justify-content-center'>Search Candidate</p>
                </div> */}

            <div className="card-devider"></div>
            <div
              className="col-12"
              style={{ height: "fit-content", padding: "20px 20px 0px 30px" }}
            >
              <div className="col-12 grid">
                <div
                  className="flex flex-wrap align-items-center justify-content-start gap-3"
                  style={{ width: "1300px" }}
                >
                  {/* <span className="p-input-icon-left" >
                                        <i className="pi pi-search" />
                                        <InputText placeholder="Keyword Search" value={globalFilterValue} onChange={onGlobalFilterChange}  style={{ borderRadius: "6px", width: "352px" }} />
                                    </span> */}

                  <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                      style={{ borderRadius: "6px" }}
                      value={filteredInput}
                      placeholder="Search"
                      className="vstate-input-field-fixed"
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          handleSearch();
                        }
                      }}
                      onChange={(e) => {
                        setFilteredInput(e.target.value);
                        if (e.target.value === "") {
                          setProducts(tempProduct);
                        }
                      }}
                    />
                  </span>
                  {superAdminId ? (
                    <span>
                      <Dropdown
                        options={allCompanies}
                        resetFilterOnHide
                        optionLabel="companyName"
                        filter
                        className="vstate-input-field-fixed"
                        value={company}
                        onChange={(e) => setCompany(e.value)}
                        placeholder="Filtered By Company"
                        style={{ borderRadius: "8px", width: "200px" }}
                      />
                    </span>
                  ) : (
                    ""
                  )}

                  <span>
                    <Dropdown
                      options={allRoles}
                      resetFilterOnHide
                      optionLabel="roleName"
                      filter
                      className="vstate-input-field-fixed"
                      value={role}
                      onChange={(e) => setRole(e.value)}
                      placeholder="Filtered By Access Level"
                      style={{ borderRadius: "8px", width: "200px" }}
                    />
                  </span>
                  <span>
                    <Button
                      type="button"
                      // icon="pi pi-plus"
                      style={{ fontSize: "14px" }}
                      className=" vstate-button font-fam-for-all text-center"
                      onClick={handleFilter}
                    >
                      Filter
                    </Button>
                  </span>
                  <span>
                    <Button
                      type="button"
                      // icon="pi pi-plus"
                      style={{ fontSize: "14px" }}
                      className="  font-fam-for-all text-center vstate-reverse-button"
                      onClick={handleClearFilter}
                    >
                      Clear Filter
                    </Button>
                  </span>
                </div>
              </div>
            </div>

            <div style={{ padding: "30px" }}>
              <div className="grid">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 pt-0 ">
                  <div className="">
                    <div className="vstate-card">
                      {showSkeleton ? (
                        <DataTable
                          header={searchCandHeader}
                          className="master-datatable"
                          value={products}
                          rows={5}
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          inline
                          paginator
                          emptyMessage="No user found"
                          filters={filters}
                          globalFilterFields={[
                            "contactDetails.firstName",
                            "contactDetails.lastName",
                            "contactDetails.mobileNo",
                            "contactDetails.emailId",
                          ]}
                          selectionMode="single"
                          selection={selectedProduct}
                          onSelectionChange={(e) => setSelectedProduct(e.value)}
                          onRowSelect={onRowSelect}
                          metaKeySelection={false}
                        >
                          <Column
                            body={itemTemplate}
                            className="datatable-body-icon"
                          ></Column>
                        </DataTable>
                      ) : (
                        <DataTable
                          header={searchCandHeader}
                          value={items}
                          rows={5}
                          rowsPerPageOptions={[5, 10, 25, 50]}
                          inline
                          paginator
                          emptyMessage="No User Found"
                          selectionMode="single"
                          selection={selectedProduct}
                          onSelectionChange={(e) => setSelectedProduct(e.value)}
                          onRowSelect={onRowSelect}
                          metaKeySelection={false}
                        >
                          <Column
                            body={<Skeleton />}
                            className="datatable-body-icon"
                          ></Column>
                        </DataTable>
                      )}
                    </div>
                  </div>
                </div>
                {data !== null &&
                data.contactDetails !== undefined &&
                data.companyRoles !== undefined ? (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 pt-0">
                    <div className="vstate-card col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 srch-cand-card">
                        <Avatar
                          className="ml-3 custom-avatar"
                          label={
                            data.contactDetails.avatarImgPath
                              ? ""
                              : getInitials(
                                  `${data.contactDetails.firstName} ${data.contactDetails.lastName}`
                                )
                          }
                          image={
                            data.contactDetails.avatarImgPath
                              ? data.contactDetails.avatarImgPath
                              : ""
                          }
                          size="large"
                          shape="circle"
                        />

                        <div className="col-11 grid pb-0">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
                            <div className="flex">
                              <div className=" srch-cand-text2">{`${data.contactDetails.firstName} ${data.contactDetails.lastName}`}</div>
                              <div className="badge-container ml-2">
                                <div
                                  className="badge-main m-0 grid"
                                  style={
                                    data.contactDetails.statusId !== 1
                                      ? { background: "#FEF3F2" }
                                      : { background: "#ECFDF3" }
                                  }
                                >
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 flex align-items-center p-0">
                                    {data.contactDetails.statusId !== 1 ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                      >
                                        <circle
                                          cx="4"
                                          cy="4"
                                          r="3"
                                          fill="#F04438"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8"
                                        height="8"
                                        viewBox="0 0 8 8"
                                        fill="none"
                                      >
                                        <circle
                                          cx="4"
                                          cy="4"
                                          r="3"
                                          fill="#12B76A"
                                        />
                                      </svg>
                                    )}
                                    <span
                                      className="badge-label ml-1"
                                      style={
                                        data.contactDetails.statusId !== 1
                                          ? { color: "#B42318" }
                                          : { color: "#027A48" }
                                      }
                                    >
                                      {" "}
                                      {getStatus(data.contactDetails.statusId)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <p className="srch-cand-text">
                              {data.companyRoles.length > 0
                                ? data.companyRoles[0].roles.length > 0
                                  ? capitalizeFirstLetter(
                                      data.companyRoles[0].roles[0].roleName
                                    )
                                  : ""
                                : ""}
                              <span>
                                {isPrimary.length > 0
                                  ? " | Primary contact for "
                                  : ""}
                              </span>
                              {isPrimary.length > 0 &&
                                isPrimary.map((company, index) => (
                                  <button
                                    key={index}
                                    className="datatable-highlight-color"
                                    onClick={() => handleRedirectData(company)}
                                  >
                                    {company.companyName}
                                    {index !== isPrimary.length - 1 ? "," : ""}
                                  </button>
                                ))}
                            </p>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 d-flex justify-content-end align-items-center"></div>
                        </div>
                      </div>

                      <div className="card-devider"></div>
                      <TabView
                        activeIndex={activeIndex}
                        onTabChange={(e) => {
                          setActiveIndex(e.index);
                          setIsEditing(false);
                          setIsEditing1(false);
                          setIsEditing2(false);
                        }}
                      >
                        <TabPanel header="Login Details" leftIcon=" mr-2">
                          <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 "
                            style={{
                              backgroundColor: "#fbfcfa",
                              padding: "15px",
                            }}
                          >
                            {isEditing ? (
                              <>
                                <div className=" grid- font-fam-for-all slide-in1 card my-3">
                                  <div className="px-4 pt-4 pb-0">
                                    <div className="grid">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 mt-2">
                                        <h6
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "18px",
                                          }}
                                        >
                                          Login Details
                                        </h6>
                                        {/* <p>Change Password Here</p> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="grid">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                                      <div className="justify-content-center dialog-form-field-space grid mt-3">
                                        <div className="field col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 p-0">
                                          <span className="relative ">
                                            <InputText
                                              id="floating_outlined9"
                                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                              placeholder=""
                                              type={passwordType}
                                              value={password}
                                              onChange={handlePassword}
                                              style={{
                                                width: "100%",
                                                height: "40px",
                                              }}
                                            />
                                            <i
                                              className="absolute right-3 top-1/2 transform -translate-y-1/2 pi"
                                              style={{
                                                fontSize: "1rem",
                                                cursor: "pointer",
                                              }}
                                              onClick={togglePassword}
                                            >
                                              <i
                                                className={
                                                  passwordType === "password"
                                                    ? "pi pi-eye"
                                                    : "pi pi-eye-slash"
                                                }
                                              ></i>
                                            </i>

                                            <label
                                              htmlFor="floating_outlined9"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              New Password
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </span>
                                          {passwordErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {passwordErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}

                                          <ul>
                                            <li
                                              className=" font-fam-for-all pt-2"
                                              style={{
                                                fontSize: "12px",
                                                color: "#667085",
                                              }}
                                            >
                                              {
                                                globalMessages.passwordValidText1
                                              }
                                            </li>
                                            <li
                                              className=" font-fam-for-all pt-2"
                                              style={{
                                                fontSize: "12px",
                                                color: "#667085",
                                              }}
                                            >
                                              {
                                                globalMessages.passwordValidText2
                                              }
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                                      <div className="justify-content-center dialog-form-field-space grid mt-3">
                                        <div className="field col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 p-0">
                                          <span className="relative ">
                                            <InputText
                                              id="floating_outlined"
                                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                              placeholder=""
                                              type={passwordType1}
                                              value={confirmedPassword}
                                              onChange={handleConfirmPassword}
                                              style={{
                                                width: "100%",
                                                height: "40px",
                                              }}
                                            />
                                            <i
                                              className="absolute right-3 top-1/2 transform -translate-y-1/2 pi"
                                              style={{
                                                fontSize: "1rem",
                                                cursor: "pointer",
                                              }}
                                              onClick={togglePassword1}
                                            >
                                              <i
                                                className={
                                                  passwordType1 === "password"
                                                    ? "pi pi-eye"
                                                    : "pi pi-eye-slash"
                                                }
                                              ></i>
                                            </i>

                                            <label
                                              htmlFor="floating_outlined"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              Confirm Password
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </span>
                                          {confirmPasswordErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {confirmPasswordErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="mb-2">
                                    <div className="flex justify-content-end dialog-form-md-group-Btn">
                                      <Button
                                        className="mt-2  font-fam-for-all text-center vstate-reverse-button mr-2"
                                        style={{ height: "40px" }}
                                        onClick={() => {
                                          setIsEditing(false);
                                          setOldPassword("");
                                          setPassword("");
                                          setConfirmPassword("");
                                          setOldPasswordErr("");
                                          setPasswordErr("");
                                          setConfirmPasswordErr("");
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        className="mt-2  font-fam-for-all text-center vstate-button mr-3"
                                        style={{ height: "40px" }}
                                        onClick={handleSave1}
                                      >
                                        Submit
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className=" font-fam-for-all slide-in hover-card card my-3">
                                  <div className="px-4 pt-4 pb-0">
                                    <div className="grid">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 mt-2">
                                        <h6
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "18px",
                                          }}
                                        >
                                          Login Details
                                        </h6>
                                        {/* <p style={{ fontSize: "14px" }}>Change Password Here</p> */}
                                      </div>

                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 flex justify-content-end">
                                        <div className="flex justify-content-end dialog-form-md-group-Btn">
                                          <button
                                            className="datatable-highlight-color webapp-global-sm-size"
                                            onClick={handleEdit}
                                          >
                                            Reset password?
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="grid-row">
                                    <div className="grid px-2">
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 p-4"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Email Address
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 p-4"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {data.contactDetails.emailId}
                                      </div>
                                    </div>
                                  </div>

                                  {loginDetails.map((item, index) => (
                                    <div key={index} className="grid-row">
                                      <div className="grid px-2">
                                        <div
                                          className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 p-4"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {item.key}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 p-4">
                                          {`${
                                            item.key === "Password"
                                              ? PasswordText(item.value)
                                              : item.value
                                          }`}
                                        </div>
                                      </div>
                                    </div>
                                  ))}

                                  <div className="grid-row">
                                    <div className="grid px-2">
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 flex align-items-center p-4"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Status
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 p-4"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <Switch
                                          checked={checked}
                                          onChange={handleCheck}
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                        />
                                        {/* <div className='flex justify-content-center  font-fam-for-all' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                    Active
                                                                                </div> */}
                                      </div>
                                    </div>
                                  </div>
                                  {superAdminId ? (
                                    <div className="grid-row">
                                      <div className="grid px-2">
                                        <div
                                          className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 flex align-items-center p-4"
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "14px",
                                          }}
                                        >
                                          Blocked
                                        </div>
                                        <div
                                          className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 p-4"
                                          style={{ fontSize: "14px" }}
                                        >
                                          <Switch
                                            checked={checked1}
                                            onChange={handleCheck1}
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                          {/* <div className='flex justify-content-center  font-fam-for-all' style={{ fontWeight: "500", fontSize: "14px" }}>
                Active
            </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </TabPanel>
                        <TabPanel header="Profile Info">
                          <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 "
                            style={{
                              backgroundColor: "#fbfcfa",
                              padding: "15px",
                            }}
                          >
                            <div className="font-fam-for-all">
                              <div>
                                {isEditing1 ? (
                                  <div className="card my-3 slide-in">
                                    <div className="p-4">
                                      <h2
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "18px",
                                        }}
                                      >
                                        Profile Information
                                      </h2>
                                      <div style={{ fontSize: "14px" }}>
                                        Update your photo and personal details
                                        here.
                                      </div>
                                    </div>

                                    <div className="grid px-4 py-2">
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <span className=" mr-3">
                                          <div class="relative">
                                            <Dropdown
                                              value={salutation}
                                              options={[
                                                "Mr",
                                                "Ms",
                                                "Mrs",
                                                "Dr",
                                              ]}
                                              onChange={(e) =>
                                                setSalutation(e.target.value)
                                              }
                                              // optionLabel="code"
                                              placeholder="Select Salutation"
                                              filter
                                              resetFilterOnHide
                                              className="p-dropdown font-fam-for-all vstate-input-field"
                                            />
                                            <label
                                              htmlFor="floating_outlined25"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              Salutation{" "}
                                              <span className="form-field-mandatory"></span>
                                            </label>
                                          </div>
                                          {/* {statesErr !== "" ? <p className="error-msg font-fam-for-all">{statesErr}</p> : ""} */}
                                        </span>
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                        style={{ fontSize: "14px" }}
                                      >
                                        <span className=" mr-3">
                                          <div class="relative">
                                            <InputText
                                              maxLength={50}
                                              value={firstName}
                                              id="floating_outlined3"
                                              placeholder=""
                                              disabled
                                              className="vstate-input-field block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                              onChange={handleFirstName}
                                              name="cityName"
                                            />

                                            <label
                                              htmlFor="floating_outlined3"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              First Name
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          {firstNameErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {firstNameErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <span className=" mr-3">
                                          <div class="relative">
                                            <InputText
                                              maxLength={50}
                                              value={lastName}
                                              id="floating_outlined5"
                                              placeholder=""
                                              className="block vstate-input-field px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                              disabled
                                              onChange={handleLastName}
                                              name="cityName"
                                            />
                                            <label
                                              htmlFor="floating_outlined5"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              Last Name{" "}
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          {lastNameErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {lastNameErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {/* <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}> */}

                                        <span className=" mr-3">
                                          <div class="relative">
                                            <InputText
                                              maxLength={50}
                                              value={email}
                                              onChange={handleEmail}
                                              name="cityName"
                                              id="floating_outlined6"
                                              placeholder=""
                                              disabled
                                              className="block vstate-input-field px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            />
                                            <label
                                              htmlFor="floating_outlined6"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              Email Address{" "}
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          {emailErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {emailErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                        {/* </div> */}
                                      </div>
                                    </div>

                                    <div className="grid px-4 py-2">
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <div
                                          className="p-inputgroup flex-1"
                                          style={{
                                            height: "40px",
                                            width: "100%",
                                          }}
                                        >
                                          <Dropdown
                                            value={selectedCountry}
                                            options={countries}
                                            onChange={(e) =>
                                              setSelectedCountry(e.value)
                                            }
                                            optionLabel="code"
                                            optionValue="dialCode"
                                            disabled
                                            filter
                                            resetFilterOnHide
                                            valueTemplate={
                                              selectedCountryTemplate
                                            }
                                            itemTemplate={countryOptionTemplate}
                                            style={{
                                              width: "120px",
                                              borderRadius: "6px 0px 0px 6px",
                                            }}
                                            className="p-dropdown font-fam-for-all"
                                          />
                                          <span className="">
                                            <div class="relative">
                                              <InputMask
                                                maxLength={50}
                                                value={phoneNumber}
                                                id="floating_outlined10"
                                                placeholder=""
                                                mask={
                                                  isFocused
                                                    ? "(999) 999-9999"
                                                    : "(999) 999-9999"
                                                }
                                                onFocus={() =>
                                                  setIsFocused(true)
                                                }
                                                onBlur={() =>
                                                  setIsFocused(false)
                                                }
                                                className="block vstate-input-field px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                disabled
                                                onChange={handlePhone}
                                                name="cityName"
                                              />
                                              <label
                                                htmlFor="floating_outlined10"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                              >
                                                Phone Number{" "}
                                                <span className="form-field-mandatory">
                                                  *
                                                </span>
                                              </label>
                                            </div>
                                            {/* {phoneErr !== "" ? <p className='error-msg font-fam-for-all'>{phoneErr}</p> : ""} */}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                        style={{ fontSize: "14px" }}
                                      >
                                        <div
                                          className="p-inputgroup flex-1"
                                          style={{
                                            height: "40px",
                                            width: "100%",
                                          }}
                                        >
                                          <Dropdown
                                            value={selectedCountry}
                                            options={countries}
                                            onChange={(e) =>
                                              setSelectedCountry(e.value)
                                            }
                                            optionLabel="code"
                                            valueTemplate={
                                              selectedCountryTemplate
                                            }
                                            itemTemplate={countryOptionTemplate}
                                            optionValue="dialCode"
                                            filter
                                            resetFilterOnHide
                                            style={{
                                              width: "120px",
                                              borderRadius: "6px 0px 0px 6px",
                                            }}
                                            className="p-dropdown font-fam-for-all"
                                          />
                                          <span className="">
                                            <div class="relative">
                                              <InputMask
                                                maxLength={50}
                                                value={altPhoneNumber}
                                                id="floating_outlined11"
                                                placeholder=""
                                                className="block vstate-input-field px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                mask={
                                                  isFocused
                                                    ? "(999) 999-9999"
                                                    : "(999) 999-9999"
                                                }
                                                onFocus={() =>
                                                  setIsFocused(true)
                                                }
                                                onBlur={() =>
                                                  setIsFocused(false)
                                                }
                                                onChange={handleAlternatePhone}
                                                name="cityName"
                                              />
                                              <label
                                                htmlFor="floating_outlined11"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                              >
                                                Alt. Phone Number{" "}
                                                <span className="form-field-mandatory"></span>
                                              </label>
                                            </div>
                                            {phoneErr !== "" ? (
                                              <p className="error-msg font-fam-for-all">
                                                {phoneErr}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <div
                                          className="p-inputgroup flex-1"
                                          style={{
                                            height: "40px",
                                            width: "100%",
                                          }}
                                        >
                                          <Dropdown
                                            value={selectedCountry}
                                            options={countries}
                                            onChange={(e) =>
                                              setSelectedCountry(e.value)
                                            }
                                            optionLabel="code"
                                            filter
                                            resetFilterOnHide
                                            valueTemplate={
                                              selectedCountryTemplate
                                            }
                                            itemTemplate={countryOptionTemplate}
                                            optionValue="dialCode"
                                            style={{
                                              width: "120px",
                                              borderRadius: "6px 0px 0px 6px",
                                            }}
                                            className="p-dropdown font-fam-for-all"
                                          />
                                          <span>
                                            <div class="relative">
                                              <InputMask
                                                maxLength={16}
                                                value={fax}
                                                onChange={handleFax}
                                                name="cityName"
                                                id="floating_outlined8"
                                                mask={
                                                  isFocused
                                                    ? "(999) 999-9999"
                                                    : "(999) 999-9999"
                                                }
                                                onFocus={() =>
                                                  setIsFocused(true)
                                                }
                                                onBlur={() =>
                                                  setIsFocused(false)
                                                }
                                                placeholder=""
                                                className="block vstate-input-field px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                              />
                                              <label
                                                htmlFor="floating_outlined8"
                                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                              >
                                                Fax{" "}
                                                <span className="form-field-mandatory"></span>
                                              </label>
                                            </div>
                                            {faxErr !== "" ? (
                                              <p className="error-msg font-fam-for-all">
                                                {faxErr}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="mb-3">
                                      {isEditing || isEditing1 || isEditing2 ? (
                                        <div className="flex justify-content-end pl-3 dialog-form-md-group-Btn">
                                          <Button
                                            className="mt-2  font-fam-for-all text-center vstate-reverse-button mr-2"
                                            style={{ height: "40px" }}
                                            onClick={() => {
                                              setIsEditing(false);
                                              setIsEditing1(false);
                                              setIsEditing2(false);
                                              setFirstNameErr("");
                                              setLastNameErr("");
                                              setPhoneErr("");
                                              setEmailErr("");
                                            }}
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            className="mt-2  font-fam-for-all text-center vstate-button mr-3"
                                            style={{ height: "40px" }}
                                            onClick={handleSave}
                                          >
                                            Submit
                                          </Button>
                                        </div>
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <div>
                                      <div className="card my-3 hover-card slide-in">
                                        <div
                                          className=" flex px-4 pt-4 pb-0"
                                          style={{
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <h2
                                            className="text-lg "
                                            style={{ fontWeight: "500" }}
                                          >
                                            Profile Information
                                          </h2>
                                          <div>
                                            {isEditing ? (
                                              <div></div>
                                            ) : (
                                              <div className="flex justify-content-end dialog-form-md-group-Btn">
                                                <Button
                                                  onClick={() =>
                                                    handleEdit1(data)
                                                  }
                                                  className="edit-button1"
                                                  icon="pi pi-pencil"
                                                  style={{
                                                    border: "none",
                                                    height: "40px",
                                                  }}
                                                ></Button>
                                              </div>
                                            )}
                                          </div>
                                        </div>

                                        <div className="grid px-4 py-2">
                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "14px",
                                            }}
                                          >
                                            Salutation
                                          </div>
                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "14px",
                                            }}
                                          >
                                            First Name
                                          </div>
                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "14px",
                                            }}
                                          >
                                            Last Name
                                          </div>
                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "14px",
                                            }}
                                          >
                                            Email Address
                                          </div>

                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {salutation}
                                          </div>
                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                            style={{ fontSize: "14px" }}
                                          >
                                            {firstName}
                                          </div>
                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {lastName}
                                          </div>
                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {email}
                                          </div>
                                        </div>
                                        <div className="grid px-4 py-2">
                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "14px",
                                            }}
                                          >
                                            Phone Number
                                          </div>
                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "14px",
                                            }}
                                          >
                                            Alt. Phone Number
                                          </div>

                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "14px",
                                            }}
                                          >
                                            Fax
                                          </div>

                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                            style={{
                                              fontWeight: "500",
                                              fontSize: "14px",
                                            }}
                                          ></div>

                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {phoneData}
                                          </div>
                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                            style={{ fontSize: "14px" }}
                                          >
                                            {altPhoneData}
                                          </div>

                                          <div
                                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                            style={{ fontSize: "14px" }}
                                          >
                                            {fax}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel header="Address Details">
                          <div
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 "
                            style={{
                              backgroundColor: "#fbfcfa",
                              padding: "15px",
                            }}
                          >
                            <div className="font-fam-for-all">
                              {isEditing2 ? (
                                <>
                                  <div className="card my-3 hover-card slide-in">
                                    <div className="p-4">
                                      <h2
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "18px",
                                        }}
                                      >
                                        Address Details
                                      </h2>
                                    </div>

                                    <div className="grid px-4 py-2">
                                      {/* <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>


                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <Dropdown value={addressType} onChange={(e) => setAddressType(e.value)} options={allAddressType} optionLabel="type" placeholder="Select Address Type"
                                                                                            className="w-full rounded-lg" style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}

                                                                                        />
                                                                                        <label htmlFor="floating_outlined25"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Address Type <span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {addressTypeErr !== "" ? <p className="error-msg font-fam-for-all">{addressTypeErr}</p> : ""}

                                                                                </span>

                                                                            </div>

                                                                            <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>


                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <Dropdown value={company} onChange={(e) => setCompany(e.value)} options={companyDetails} optionLabel="companyName" placeholder="Select Company"
                                                                                            className="w-full rounded-lg " style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}

                                                                                        />
                                                                                        <label htmlFor="floating_outlined25"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Company <span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {companyErr !== "" ? <p className="error-msg font-fam-for-all">{companyErr}</p> : ""}

                                                                                </span>

                                                                            </div> */}

                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                        style={{ fontSize: "14px" }}
                                      >
                                        <span className=" mr-3">
                                          <div class="relative">
                                            <InputText
                                              style={{
                                                height: "40px",
                                                borderRadius: "0px 6px 6px 0px",
                                              }}
                                              maxLength={50}
                                              value={addr1}
                                              onChange={(e) =>
                                                setAddr1(e.target.value)
                                              }
                                              name="cityName"
                                              id="floating_outlined21"
                                              placeholder=""
                                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            />
                                            <label
                                              htmlFor="floating_outlined21"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              Address Line 1{" "}
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          {addr1Err !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {addr1Err}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <span className=" mr-3">
                                          <div class="relative">
                                            <InputText
                                              style={{
                                                height: "40px",
                                                borderRadius: "0px 6px 6px 0px",
                                              }}
                                              maxLength={50}
                                              value={addr2}
                                              onChange={(e) =>
                                                setAddr2(e.target.value)
                                              }
                                              name="cityName"
                                              id="floating_outlined22"
                                              placeholder=""
                                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            />
                                            <label
                                              htmlFor="floating_outlined22"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              Address Line 2{" "}
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          {addr2Err !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {addr2Err}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>

                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                        style={{ fontSize: "14px" }}
                                      >
                                        <span className=" mr-3">
                                          <div class="relative">
                                            <InputText
                                              style={{
                                                height: "40px",
                                                borderRadius: "0px 6px 6px 0px",
                                              }}
                                              maxLength={50}
                                              value={city}
                                              onChange={(e) =>
                                                setCity(e.target.value)
                                              }
                                              name="cityName"
                                              id="floating_outlined24"
                                              placeholder=""
                                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            />
                                            <label
                                              htmlFor="floating_outlined24"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              City{" "}
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          {cityErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {cityErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>

                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <span className=" mr-3">
                                          <div class="relative">
                                            <Dropdown
                                              value={states}
                                              filter
                                              resetFilterOnHide
                                              onChange={(e) =>
                                                setStates(e.value)
                                              }
                                              options={allStates}
                                              optionValue="id"
                                              optionLabel="stateFullDesc"
                                              placeholder="Select a State"
                                              className="w-full "
                                              style={{
                                                height: "40px",
                                                borderRadius: "6px",
                                              }}
                                            />
                                            <label
                                              htmlFor="floating_outlined25"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              State{" "}
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          {statesErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {statesErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <span className=" mr-3">
                                          <div class="relative">
                                            <Dropdown
                                              value={countryData}
                                              filter
                                              resetFilterOnHide
                                              onChange={(e) =>
                                                setCountryData(e.value)
                                              }
                                              options={allCountries}
                                              optionValue="id"
                                              optionLabel="countryFullDesc"
                                              placeholder="Select a Country"
                                              className="w-full"
                                              style={{
                                                height: "40px",
                                                borderRadius: "0px 6px 6px 0px",
                                              }}
                                            />
                                            <label
                                              htmlFor="floating_outlined25"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              Country{" "}
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          {countryErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {countryErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{ fontSize: "14px" }}
                                      >
                                        <span className=" mr-3">
                                          <div class="relative">
                                            <InputText
                                              style={{
                                                height: "40px",
                                                borderRadius: "0px 6px 6px 0px",
                                              }}
                                              maxLength={5}
                                              value={zip}
                                              onChange={handleZip}
                                              name="cityName"
                                              id="floating_outlined26"
                                              placeholder=""
                                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                            />
                                            <label
                                              htmlFor="floating_outlined26"
                                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                            >
                                              Zip Code{" "}
                                              <span className="form-field-mandatory">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          {zipErr !== "" ? (
                                            <p className="error-msg font-fam-for-all">
                                              {zipErr}
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="mb-3">
                                      {isEditing || isEditing1 || isEditing2 ? (
                                        <div className="flex justify-content-end pl-3 dialog-form-md-group-Btn">
                                          <Button
                                            className="mt-2  font-fam-for-all text-center  vstate-reverse-button mr-2"
                                            style={{ height: "40px" }}
                                            onClick={() => {
                                              setIsEditing(false);
                                              setIsEditing1(false);
                                              setIsEditing2(false);
                                              setAddr1("");
                                              setAddr1Err("");
                                              setAddr2("");
                                              setAddr2Err("");
                                              setCity("");
                                              setcityErr("");
                                              setRegion("");
                                              setRegionErr("");
                                              setStates("");
                                              setStatesErr("");
                                              setZip("");
                                              setZipErr("");

                                              setAddressTypeErr("");
                                              setCompanyErr("");
                                              setCountryErr("");
                                            }}
                                          >
                                            Cancel
                                          </Button>
                                          <Button
                                            className="mt-2  font-fam-for-all text-center vstate-button mr-3"
                                            style={{ height: "40px" }}
                                            onClick={handleSave2}
                                          >
                                            Submit
                                          </Button>
                                        </div>
                                      ) : (
                                        <div></div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="card my-3 hover-card slide-in">
                                    <div
                                      className=" flex px-4 pt-4 pb-0"
                                      style={{
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <h2
                                        className="text-lg "
                                        style={{ fontWeight: "500" }}
                                      >
                                        Address Details
                                      </h2>
                                      <div>
                                        {isEditing ? (
                                          <div></div>
                                        ) : (
                                          <div className="flex justify-content-end dialog-form-md-group-Btn">
                                            <Button
                                              onClick={handleEdit2}
                                              className="edit-button1"
                                              icon="pi pi-pencil"
                                              style={{
                                                border: "none",
                                                height: "40px",
                                              }}
                                            ></Button>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="grid px-4 py-2">
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Address Line 1
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Address Line 2
                                      </div>

                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        City
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        State
                                      </div>

                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {addr1}
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                        style={{ fontSize: "14px" }}
                                      >
                                        {addr2}
                                      </div>

                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {city}
                                      </div>

                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {stateValue}
                                      </div>
                                    </div>
                                    <div className="grid px-4 py-2">
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Country
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      >
                                        Zip Code
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      ></div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                        }}
                                      ></div>

                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 "
                                        style={{ fontSize: "14px" }}
                                      >
                                        {countryValue}
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{ fontSize: "14px" }}
                                      >
                                        {zip}
                                      </div>
                                      <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3"
                                        style={{ fontSize: "14px" }}
                                      ></div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </TabPanel>

                        {/* <TabPanel header="Manage Permission">
                                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ' style={{ backgroundColor: '#fbfcfa', padding: '15px' }}>


                                                        <div className="font-fam-for-all">

                                                            <div className='card my-3 hover-card slide-in1'>


                                                                <div className='p-4'>
                                                                    <h2 style={{ fontWeight: "500", fontSize: "18px" }}>Manage Permission</h2>
                                                                   
                                                                </div>
                                                                <div className='grid p-4'>
                                                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                                        <GroupDataTable data={selectedProduct} />

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel> */}
                      </TabView>
                    </div>
                  </div>
                ) : showSkeleton ? (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 pt-0 ">
                    <div
                      className="card col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                      style={{ height: "255px" }}
                    >
                      <div
                        className="grid justify-content-center align-items-center"
                        style={{ height: "255px" }}
                      >
                        No user found
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className=" card col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 pt-0">
                    <div className="border-round border-1 surface-border p-4 surface-card">
                      <div className="flex mb-3">
                        <Skeleton
                          shape="circle"
                          size="4rem"
                          className="mr-2"
                        ></Skeleton>
                        <div>
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                          <Skeleton height=".5rem"></Skeleton>
                        </div>
                      </div>
                      <Skeleton width="100%" height="150px"></Skeleton>
                      <div className="flex justify-content-between mt-3">
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                        <Skeleton width="4rem" height="2rem"></Skeleton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <AddEmployee
            visible={visible}
            setVisible={setVisible}
            pageRefresh={pageRefresh}
            setPageRefresh={setPageRefresh}
          />

          <UserUpload
            visible={visible4}
            setVisible={setVisible4}
            pageRefresh={pageRefresh}
            setPageRefresh={setPageRefresh}
          />

          <Dialog
            header={handleFbackHeader}
            visible={visible6}
            onHide={() => {
              setVisible6(false);
            }}
            className="dialog-box"
          ></Dialog>

          <Dialog
            visible={closeProgresbar}
            modal={false}
            onHide={CloseProgresbarheader}
            className="font-fam-for-all"
          >
            <p className="m-0 p-3">
              <div className="d-flex align-items-center">
                <p className="font-fam-for-all successMessage-font">
                  Data Added Successfully
                </p>
              </div>
              <div className="flex justify-content-end dialog-form-md-group-Btn">
                <Button
                  type="button"
                  className="mt-2 form-Btn form-Btn-Label font-fam-for-all  text-lg  mr-2"
                  onClick={CloseProgresbarheader}
                >
                  Ok
                </Button>
              </div>
            </p>
          </Dialog>

          <Dialog
            visible={closeProgresbar1}
            modal={false}
            onHide={CloseProgresbarheader1}
            className="font-fam-for-all"
          >
            <p className="m-0 p-3">
              <div className="d-flex align-items-center">
                <p className="font-fam-for-all successMessage-font">
                  Data Added Failed
                </p>
              </div>
              <div className="flex justify-content-end dialog-form-md-group-Btn">
                <Button
                  type="button"
                  className="mt-2 form-Btn form-Btn-Label font-fam-for-all  text-lg  mr-2"
                  onClick={CloseProgresbarheader1}
                >
                  Ok
                </Button>
              </div>
            </p>
          </Dialog>
        </div>
      </div>
    </>
  );
}
