import * as React from "react";
import { TestimonialCard } from "./TestimonialCard";


export const testimonialData = [
  {
    id: 1,
    quote: "handles everything with professionalism",
    content: "vState Filings has been invaluable to our business, facilitating payroll tax registration for employee hiring. They're a responsive partner, crucial to our business expansion. I highly recommend their services!",
    author: "Neil Sims",
    role: "CEO, Company_Name",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/5cf65183d65497c22fcfa2d6d05544c8bb23a149d9cfa6f72aff08258dd3ee11?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
  },
  {
    id: 2,
    quote: "attention to detail and timely reminders",
    content: "vState Filings has been invaluable to our business, facilitating payroll tax registration for employee hiring. They're a responsive partner, crucial to our business expansion. I highly recommend their services!",
    author: "Micheal Gough",
    role: "CEO, Company_Name",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/9df19ffd7d1f2257aa438b942d1c8f837f04f0ee761366746ee94f660c06b39b?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
  },
  {
    id: 3,
    quote: "customer service is outstanding",
    content: "vState Filings has been invaluable to our business, facilitating payroll tax registration for employee hiring. They're a responsive partner, crucial to our business expansion. I highly recommend their services!",
    author: "Helene Engels",
    role: "CEO, Company_Name",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/2d0e7e39ee7f52e9579d45a2df36dab423e6b3be1b0321645f6364daa9ddee35?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
  },
  {
    id: 4,
    quote: "seamless and stress-free",
    content: "vState Filings has been invaluable to our business, facilitating payroll tax registration for employee hiring. They're a responsive partner, crucial to our business expansion. I highly recommend their services!",
    author: "Karen Nelson",
    role: "CEO, Company_Name",
    image: "https://cdn.builder.io/api/v1/image/assets/TEMP/c03f7b7c3fcfb035baf1a501a8fda928f9b84981fc340ef25f0a66ad47d31875?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
  }
];

export default function Testimonial() {
  return (
    <section className="flex flex-col pt-24 rounded-lg">
      <div className="flex flex-wrap gap-16 justify-center items-start w-full max-md:max-w-full">


        <div className="flex flex-col grow shrink items-center rounded-lg min-w-[240px] w-[912px] max-md:max-w-full">
          <header className="flex flex-col grow shrink text-center min-w-[240px] w-[912px] max-md:max-w-full">
            <h2 className="text-5xl font-medium tracking-tight leading-none font-web-color max-md:max-w-full">
              Testimonials
            </h2>
            <p className="mt-4 text-xl text-slate-700 max-md:max-w-full">
              Trusted by businesses nationwide for seamless filing & compliance solutions.
            </p>
          </header>
          <div className="flex flex-wrap max-w-full w-[1140px]">
            <div className="flex flex-col flex-1 shrink justify-center basis-0 min-w-[240px] max-md:max-w-full">
              <TestimonialCard {...testimonialData[0]} />
            </div>
            <div className="flex shrink-0 w-px bg-gray-400 h-[345px]" />
            <div className="flex flex-col flex-1 shrink justify-center basis-0 min-w-[240px] max-md:max-w-full">
              <TestimonialCard {...testimonialData[1]} />
            </div>
          </div>

          <div className="flex flex-wrap max-w-full min-h-[327px] w-[1140px]">
            <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
              <div className="flex w-full bg-gray-400 min-h-[1px] max-md:max-w-full" />
              <TestimonialCard {...testimonialData[2]} />
            </div>
            <div className="flex shrink-0 w-px bg-gray-400 h-[327px]" />
            <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
              <div className="flex w-full bg-gray-400 min-h-[1px] max-md:max-w-full" />
              <TestimonialCard {...testimonialData[3]} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}