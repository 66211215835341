import React from 'react';
import FeatureItem from './FeatureItem';

const Features = () => {
  const features = [
    {
      number: "1",
      title: "A modern, easy-to-use, and intuitive platform all in one place",
      description: "Say goodbye to tracking multiple websites and wasting time switching between them. With a single sign-in, you can handle all your company's administrative tasks in one place."
    },
    {
      number: "2",
      title: "Register your company in minutes.",
      description: "vState specializes in expediting the business formation process, offering a streamlined approach that allows you to submit your application within minutes. With our expert guidance and efficient workflow, we ensure that all necessary paperwork is completed accurately and promptly."
    },
    {
      number: "3",
      title: "Comprehensive Expertise",
      description: "vState Filings offers in-depth knowledge and expertise in regulatory compliance across various industries, ensuring accurate and timely filings to reduce the risk of non-compliance and penalties."
    },
    {
      number: "4",
      title: "Personalized Customer Support",
      description: "vState Filings offers dedicated account managers and 24/7 customer support, ensuring clients receive tailored assistance and prompt responses for a smooth and stress-free filing experience."
    }
  ];

  return (
    <section className="flex flex-col px-8 pt-20 mt-24 max-w-full w-[1240px] max-md:px-5 max-md:mt-10 ">
      <div className="flex flex-col p-2.5 max-md:max-w-full">
        {/* <div className="self-start px-6 pt-2.5 pb-2 text-sm font-semibold leading-3 text-white uppercase bg-gray-800 rounded max-md:px-5">one stop solution</div> */}
        <h2 className="mt-5 text-5xl font-bold tracking-tighter text-blue-700 text-center max-md:max-w-full">
          <span className="font-medium font-web-color1 leading-[66px] text-slate-700">Helping businesses to operate with </span>
          <span className="font-medium font-web-color  leading-[66px]">flexibility</span>
        </h2>
      </div>
      <div className="justify-center mt-11 max-md:pl-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex grid justify-content-center  max-md:flex-col max-md:gap-0">
          {features.map((feature, index) => (
            <FeatureItem key={index} {...feature} isEven={index % 2 === 0} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;