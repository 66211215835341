export function formatDate(date) {
    return new Intl.DateTimeFormat('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(new Date(date));
  }
  
  export function getMonthAbbreviations() {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  }


 export function formatISODate(isoString) {
    const date = new Date(isoString);

    // Options for displaying date and time in a more readable format
    const options = {
        year: 'numeric',
        month: 'long',  // "long" for full month name, "short" for abbreviated
        day: 'numeric',
       
    };

    // Convert to readable format
    return date.toLocaleString('en-US', options);
}