import React, { useContext } from "react";
import authContext from "../../common/authContext";

function WelcomeSection() {
  const { loginUserName } = useContext(authContext)
  return (
    <section className="flex flex-col w-full max-md:max-w-full">
      <div className="flex gap-4 items-start w-full max-md:max-w-full">
        <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full">
          <h1 className="text-3xl font-medium leading-none text-gray-900 max-md:max-w-full">
            Welcome, {loginUserName}!
          </h1>
          <p className="mt-1 mb-0 text-base text-gray-500 max-md:max-w-full">
            View updates on your applications, check the status of ongoing filings, or start a new application.
          </p>
        </div>
      </div>
    </section>
  );
}

export default WelcomeSection;