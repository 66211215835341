import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useContext, useEffect, useState } from 'react'
import rolesMasterService from '../services/rolesMaster'
import EditCompanyLocation from './Company/EditCompanyComponents/EditCompanyLocation'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import authContext from '../common/authContext'
import AddressService from '../services/addressService'

const CompanyLocation = ({ companyData }) => {

    const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh } = useContext(authContext);


    const [products, setProducts] = useState([

    ])

    const [allStates, setAllStates] = useState([])

    useEffect(() => {
        rolesMasterService.getAllState()
            .then((res) => {
                console.log(res)
                if (res.data !== "") {
                    setAllStates(res.data)
                }

            })
            .catch((err) => {
                console.log(err)
            })
        if (companyData !== null) {
            AddressService.getAllAddressByCompanyId(companyData.id)
                .then((res) => {
                    console.log(res)
                    if (res.data !== "") {
                        const validTypes = ["PRINCIPAL", "COMPANY", "BILLING", "SHIPPING", "BRANCH","REGISTERED AGENT"];
                        const tempdata = res.data.filter(({ addressType }) => validTypes.includes(addressType.type));
                        setProducts(tempdata)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [companyData, isTabRefresh])

    const [company, setCompany] = useState(null)

    const [visible, setVisible] = useState(false)

    const [data, setData] = useState(null)

    const handleEdit = (rowData) => {
        setData(rowData)
        setCompany(rowData.companyDetails)
        setVisible(true)
    }

    const iconBodyTemplate = (rowdata) => {
        console.log(rowdata)
        return (
            <>
                <button className="pi pi-pencil image-btn-icon" data-toggle="tooltip" data-placement="bottom" title="Edit" onClick={() => handleEdit(rowdata)}></button>
                {/* <button className="pi pi-trash image-btn-icon-delete" data-toggle="tooltip" data-placement="bottom" title="Delete" onClick={() => confirm('top')} ></button> */}
            </>
        )
    }

    const addressBody = (rowData) => {
        console.log(rowData)
        if (rowData.stateId !== null) {
            const state = allStates.filter((val) => rowData.stateId === val.id)
            console.log(state)
            if (state.length > 0) {
                return (
                    <>
                        <div className='font-fam-for-all'>
                            {`${rowData.addressLine1},${rowData.addressLine2?`${rowData.addressLine2},`:""}${rowData.city},${state[0].stateFullDesc},${rowData.postalCode}`}
                        </div>
                    </>
                )
            }
            else {
                return <></>
            }
        }
        else {
            return (
                <>
                    <div className='font-fam-for-all'>
                        {`${rowData.addressLine1},${rowData.addressLine2?`${rowData.addressLine2},`:""}${rowData.city},${rowData.postalCode}`}
                    </div>
                </>
            )
        }

    }

    const contactBody = (rowData) => {
        console.log(rowData)
        if (rowData.contactDetails !== null) {
            return (
                <>
                </>
            )
        }
        else {
            return (
                <>
                </>
            )
        }

    }
    const nameBody = (rowData) => {
        if (rowData.companyDetails !== null) {
            return (
                <>
                    <div>{rowData.companyDetails.companyName}</div>
                </>
            )
        }
        else {
            return (
                <>
                </>
            )
        }

    }

    const accept = () => {
        console.log('first')
    }

    const reject = () => {
        console.log('second')
    }

    const confirm = (position) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            position,
            accept,
            reject
        });
    };


    return (
        <div>
            <ConfirmDialog />
            <DataTable value={products} scrollable
                dataKey='name' paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}
                className='font-fam-for-all datatable-sm'

                globalFilterFields={["djjddjjd"]}>
                <Column field='name' header="Name" className='font-fam-for-all datatable-lg-col webapp-sm-size' body={nameBody}></Column>
                <Column field='address' header="Address" className='font-fam-for-all datatable-lg-col webapp-sm-size' body={addressBody}></Column>
                <Column field='addressType.type' header="Address Type" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>

                {/* <Column field='contactInfo' header="Contact Info" className='font-fam-for-all datatable-lg-col webapp-sm-size' body={contactBody}></Column> */}
                <Column body={iconBodyTemplate} header="Action" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>


            </DataTable>

            <EditCompanyLocation companyId={company} visible={visible} setVisible={setVisible} data={data} />
        </div>
    )
}

export default CompanyLocation