import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useState } from "react";
import OrderServices from "../../services/orderServices";
import MasterServices from "../../services/coreServices";
import authContext from "../../common/authContext";
import { useNavigate } from "react-router-dom";
import { formatISODate } from "../../utils/dateUtils";
import { Skeleton } from "primereact/skeleton";
import useTableData from "../../redux/Reducers/useTableData";

const CompanyOrders = ({ data }) => {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh,
    redirectedData,
    setRedirectedData,
  } = useContext(authContext);

  const [products, setProducts] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const navigate = useNavigate();

  const [allStatus, setAllStatus] = useState([]);

  const [showSkeleton, setShowSkeleton] = useState(false);

  const getBadgeBackgroundColor = (status) => {
    switch (status) {
      case "ORDER CREATED":
        return "#ECFDF3";
      case "ORDER SUBMITTED":
        return "#ECFDF3";
      case "ORDER IN PROGRESS":
        return "#ECFDF3";
      case "ORDER COMPLETED":
        return "#ECFDF3";
      case "ORDER RESUBMITTED":
        return "#ECFDF3";
      case "PAY LATER REQUEST APPROVED":
        return "#ECFDF3";
      case "PAY LATER REQUEST REJECTED":
        return "#F9FAFB";
      case "DRAFT":
        return "#F9FAFB";
      default:
        return "#FFFAEB";
    }
  };

  const getBadgeTextColor = (status) => {
    switch (status) {
      case "ORDER CREATED":
        return "#027A48";
      case "ORDER SUBMITTED":
        return "#027A48";
      case "ORDER IN PROGRESS":
        return "#027A48";
      case "ORDER COMPLETED":
        return "#027A48";
      case "ORDER RESUBMITTED":
        return "#027A48";
      case "PAY LATER REQUEST APPROVED":
        return "#027A48";
      case "PAY LATER REQUEST REJECTED":
        return "#344054";
      case "DRAFT":
        return "#344054";
      default:
        return "#B54708";
    }
  };




  useEffect(() => {
    if (data) {
      OrderServices.getAllOrderByCompanyId(data.id)
        .then((res) => {
          console.log(res.data, "Vendor")
          if (res.data !== "") {
            setShowSkeleton(true);
            setProducts(res.data);
          } else {
            setShowSkeleton(true);
            setProducts([]);
          }
        })
        .catch((err) => {
          
          setShowSkeleton(true);
        });
    }
  }, [data, isTabRefresh]);

  const handleView = (rowData) => {
    
    setRedirectedData(rowData?.orderId);
    navigate("/order");
  };

  const iconBodyTemplate = (rowdata) => {
    return (
      <>
        <button
          className="pi pi-eye-slash image-btn-icon"
          data-toggle="tooltip"
          data-placement="bottom"
          title="View Order"
          onClick={() => handleView(rowdata)}
        ></button>
        {/* <button className="pi pi-trash image-btn-icon-delete" data-toggle="tooltip" data-placement="bottom" title="Delete" ></button> */}
      </>
    );
  };

  const statusBody = (rowdata) => {
    return (
      <div style={{
        color: getBadgeTextColor(
          rowdata.statusName
        ),
      }}>
        {rowdata.statusName}

      </div>
    );
  };

  const orderBody = (rowdata) => {
    return (
      <p className=" srch-cand-text1">{`${rowdata.orderNumber !== "" ? rowdata.orderNumber : rowdata.orderId
        }`}</p>
    );
  };

  const orderdateBody = (rowdata) => (
    <div className="webapp-sm-size">
      {rowdata.orderDate ? formatISODate(rowdata.orderDate) : ""}
    </div>
  );

  const items = Array.from({ length: 5 }, (v, i) => i);

  

  return (
    <div>
      {showSkeleton ? (
        <DataTable
          value={products}
          scrollable
          emptyMessage="No Order Found"
          dataKey="name"
          paginator
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          className='font-fam-for-all '
          scrollHeight="flex"
          scrollWidth="100%"
          globalFilterFields={["djjddjjd"]}
        >
          <Column
            header="Order Number"
            body={orderBody}
            className="font-fam-for-all datatable-lg-col webapp-sm-size"
          ></Column>
          <Column
            field="orderTypeName"
            header="Order Type"
            className="font-fam-for-all datatable-lg-col webapp-sm-size"
          ></Column>
          <Column
            field="displayName"
            header="Entity Name"
            className="font-fam-for-all  webapp-sm-size"
          ></Column>
          <Column
            field="stateName"
            header="State"
            className="font-fam-for-all  webapp-sm-size"
          ></Column>
          <Column
            field="orderDate"
            header="Order Date"
            body={orderdateBody}
            className="font-fam-for-all datatable-lg-col webapp-sm-size"
          ></Column>
          <Column
            header="Status"
            body={statusBody}
            className="font-fam-for-all datatable-lg-col webapp-sm-size"
          ></Column>
          <Column
            body={iconBodyTemplate}
            header="Action"
            className="font-fam-for-all datatable-lg-col webapp-sm-size"
          ></Column>
        </DataTable>
      ) : (
        <DataTable
          value={items}
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          inline
          paginator
          emptyMessage="No Candidate Found"
          selectionMode="single"
          metaKeySelection={false}
        >
          <Column body={<Skeleton />} className="datatable-body-icon"></Column>
        </DataTable>
      )}
    </div>
  );
};

export default CompanyOrders;
