import * as React from "react";

const HeaderTop = () => (
  <div className="flex justify-center items-center px-16 py-1 w-full bg-blue-700 max-md:px-5 max-md:max-w-full">
    <div className="flex gap-5 justify-center max-w-full w-[1150px] max-md:flex-wrap">
      <div className="flex flex-col justify-center my-auto text-sm font-light leading-7 text-white max-md:max-w-full">
        <div className="flex flex-wrap gap-5 justify-between">
          <div className="flex gap-2.5 whitespace-nowrap">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7c14f19d02279a47aeefaa200553eca83cd7aac07a492b28b391e0b6ef607d2c?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="" className="shrink-0 my-auto  aspect-square" />
            <div>support@businessanywhere.io</div>
          </div>
          <div className="flex gap-2.5 max-md:flex-wrap">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/0cf01cb6732d9d4dae2aa86275dbfdc1d74486f8d857ff804484de3fb4073638?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="" className="shrink-0 my-auto  aspect-square" />
            <div>1740 Dell Range Blvd Suite H13 Cheyenne, WY 82009</div>
          </div>
        </div>
      </div>
      <div className="flex gap-2.5">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3144f4d7daa3172a5677b125a550514ff4f4da18e406cbe3eb507a3681f4a122?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="Social media icon" className="shrink-0 aspect-square w-[31px]" />
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/5ca1b862dc714f255ceca849a1a6748fffe38f89317c22b22afea56af555b959?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="Social media icon" className="shrink-0 my-auto max-w-full aspect-[6.67] w-[119px]" />
      </div>
    </div>
  </div>
);

const NavigationMenu = () => (
  <nav className="flex justify-center items-center px-16 py-4 w-full text-sm capitalize bg-white max-md:px-5 max-md:max-w-full">
    <div className="flex gap-5 justify-center px-0.5 max-md:flex-wrap">
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc54abb75d118aa012c842ae3568963d371d1365c1591ffba5288162c0ee0aff?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="Company logo" className="shrink-0 max-w-full aspect-[3.57] w-[164px]" />
      <div className="flex flex-wrap gap-4 justify-center py-3.5 pr-4 font-medium leading-[143%] text-indigo-950">
        <div className="flex gap-2">
          <a href="#home">Home</a>
          <a href="#about">About Us</a>
        </div>
        <div className="flex gap-4 max-md:flex-wrap">
          <a href="#services" className="text-blue-700">Services</a>
          <a href="#registration">Registration Agent</a>
          <a href="#filings">Filings & Document</a>
          <a href="#attorney">Attorney</a>
        </div>
        <div className="flex gap-2">
          <a href="#contact">Contact Us</a>
          <a href="#payment">Payment</a>
        </div>
      </div>
      <button className="flex flex-col justify-center px-6 py-3 text-center text-white whitespace-nowrap leading-[100%] bg-gray-800 rounded-[30px] max-md:pl-5">
        <div className="flex gap-0 justify-between py-px">
          <span>Login/Register</span>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/f33eb701982a2a668b0a1968d47f3b6307a791cbcc65d50a68fddddde03779f3?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="" className="shrink-0 self-start w-3.5 aspect-square" />
        </div>
      </button>
    </div>
  </nav>
);

const HeroSection = () => (
  <section className="justify-center py-2.5 mt-16 max-w-full w-[1140px] max-md:mt-10">
    <div className="flex gap-5 max-md:flex-col max-md:gap-0">
      <div className="flex flex-col w-[55%] max-md:ml-0 max-md:w-full">
        <div className="flex flex-col justify-center self-stretch py-2.5 pl-2.5 my-auto font-semibold max-md:mt-10 max-md:max-w-full">
          <h1 className="text-6xl font-bold tracking-tighter text-blue-700 capitalize leading-[64px] max-md:max-w-full max-md:text-4xl max-md:leading-10">
            <span className="text-black">One-Stop Business</span>
            <br />
            <span className="text-blue-700">Formation</span>
            <span className="text-black"> Service</span>
          </h1>
          <p className="mt-5 text-2xl leading-8 text-slate-800 max-md:max-w-full">
            Online business registration in all 50 US
            <br />
            States with ease!{" "}
          </p>
          <form className="flex flex-col justify-center p-1 mt-5 text-sm text-gray-800 rounded-lg border-2 border-blue-700 border-solid max-md:max-w-full">
            <div className="flex flex-wrap gap-1 justify-center items-start py-1 pl-0.5">
              <input
                type="text"
                placeholder="What's the name of your business?"
                aria-label="Business name"
                className="flex-1 justify-center px-4 py-2.5 bg-white rounded shadow-sm"
              />
              <select className="flex-1 justify-center px-3.5 py-2 rounded shadow-sm bg-gray-400 bg-opacity-50">
                <option value="llc">LLC</option>
              </select>
              <button type="submit" className="flex-1 justify-center self-stretch px-7 py-4 font-medium text-center text-white bg-blue-700 rounded-md shadow-sm leading-[100%] max-md:px-5">
                Register Now
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="flex flex-col ml-5 w-[45%] max-md:ml-0 max-md:w-full">
        <div className="flex flex-col grow justify-center self-stretch p-1 w-full rounded-2xl border-4 border-white border-solid shadow-sm bg-white bg-opacity-0 max-md:mt-5 max-md:max-w-full">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd4e6647bb58484eb177e2659d771556ef95f3d9f5d68a1fadeb62b6740a6467?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="Business formation illustration" className="w-full aspect-[1.49] max-w-[496px] max-md:max-w-full" />
        </div>
      </div>
    </div>
  </section>
);

const FeatureSection = () => (
  <section className="flex flex-col justify-center px-16 mt-7 max-w-full bg-white w-[1200px] max-md:px-5">
    <div className="flex gap-0 max-w-[1080px] max-md:flex-wrap max-md:max-w-full">
      <div className="shrink-0 w-px bg-slate-600 bg-opacity-10 h-[250px]" />
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/8cb7f87e1cacef6e889c546d0aa1398a1b1596e79f6f345f1bfa648d0b825797?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="Feature illustration" className="z-10 self-start mt-14 w-full aspect-[7.69] max-md:mt-10 max-md:max-w-full" />
      <div className="shrink-0 w-px bg-slate-600 bg-opacity-10 h-[250px]" />
    </div>
  </section>
);

const WhyChooseUsSection = () => (
  <section className="flex flex-col justify-center px-9 pt-16 pb-20 mt-7 max-w-full w-[1200px] max-md:px-5">
    <h2 className="self-center text-4xl font-semibold text-center text-blue-700 leading-[50px] w-[669px] max-md:max-w-full">
      Why Choose <span className="text-blue-700">vState Filings</span> for{" "}
      <span className="text-blue-700">Business Formation</span> Needs?
    </h2>
    <div className="justify-center pt-5 pb-2.5 mt-10 max-md:pr-5 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col justify-center p-5 mt-1 max-md:mt-10 max-md:max-w-full">
            {[
              { title: "Processing Time", description: "We can get your company registered within 2 business days, so your start-up plans can move forward without delay!" },
              { title: "Price", description: "vState Filings provides affordable services to help you quickly form your company. Select from our range of packages to find the perfect support for your needs." },
              { title: "Registered Agent", description: "In many states, a registered agent is required for forming a company. They receive legal documents on your company's behalf. All our vState Filings packages include this service." },
              { title: "Security", description: "When forming an LLC, personal information is required. With vState Filings, we prioritize security to protect your data." }
            ].map((item, index) => (
              <div key={index}>
                <div className="flex gap-4 mx-5 mt-4 max-md:flex-wrap max-md:mr-2.5">
                  <div className="my-auto text-xl font-black leading-5 text-center text-blue-700"> </div>
                  <h3 className="flex-1 text-2xl font-semibold leading-8 text-black">{item.title}</h3>
                </div>
                <p className="mx-5 mt-4 text-base font-light leading-7 text-justify text-black max-md:mr-2.5 max-md:max-w-full">
                  {item.description}
                </p>
                {index < 3 && <div className="shrink-0 mt-4 h-px border-t border-solid border-zinc-300 max-md:max-w-full" />}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/25480421dc7dcddd03be7d9bf611c637a16d43f47019abd0ca99fa079b581d2c?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="Business formation illustration" className="grow w-full shadow-sm aspect-[0.67] max-w-[444px] max-md:mt-10 max-md:max-w-full" />
        </div>
      </div>
    </div>
  </section>
);

const StepsSection = () => {
  const steps = [
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/93c28916bf88494453755cb5f08653af1b3bc58596917b43464461008390835c?apiKey=02362ed84d214b928bb0b669d3dcf7f8&", title: "Create Your Account", description: "Sign up to access your personalized dashboard." },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8c9a1b3267f0f64141d356fc5762bb745369141b963f9377c12bc5ab2582c1e3?apiKey=02362ed84d214b928bb0b669d3dcf7f8&", title: "Fill Out Our Form", description: "Now simply fill out the online form with all your details. Our straightforward form is easy to understand. It only takes a couple of minutes." },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c2781bd24506bd8fea86f1cc47613bf27b9523160f41ef0407481898f25fe9a5?apiKey=02362ed84d214b928bb0b669d3dcf7f8&", title: "Choose Your Services", description: "Every business is unique. That's why we provide various packages and services. Explore our compact yet powerful selection of packages and select the option that best fits your needs." },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/44fcd37c41a383cf729dc732d007bbf0fee99e7ea7005c5cbc7b3386022968bc?apiKey=02362ed84d214b928bb0b669d3dcf7f8&", title: "Make A Payment", description: "You can use our secure payment gateway with confidence, knowing there are no hidden fees or surprises. what you see is what you'll pay." },
    { icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/017cc8920a154822f6bd22f5236f5c2e45f7e3bbf09f78924c8ebba5f83adff8?apiKey=02362ed84d214b928bb0b669d3dcf7f8&", title: "Bingo!", description: "Once your company is officially registered, we'll handover the documents and certificates to you." }
  ];

  return (
    <section className="flex flex-col justify-center px-8 py-20 mt-7 max-w-full bg-white w-[1200px] max-md:px-5">
      <h2 className="self-center text-5xl font-semibold text-center text-blue-700 leading-[60px] w-[806px] max-md:max-w-full max-md:text-4xl max-md:leading-[59px]">
        Follow few steps and Register your Business{" "}
        <span className="text-blue-700">in minutes</span>!
      </h2>
      <div className="justify-center py-2.5 pl-2.5 mt-10 max-w-[1140px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          {steps.slice(0, 3).map((step, index) => (
            <StepCard key={index} {...step} stepNumber={index + 1} />
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center px-16 py-2.5 mt-5 max-w-[1140px] max-md:px-5 max-md:max-w-full">
        <div className="max-w-full w-[764px]">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            {steps.slice(3).map((step, index) => (
              <StepCard key={index + 3} {...step} stepNumber={index + 4} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const StepCard = ({ icon, title, description, stepNumber }) => (
  <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
    <div className="flex flex-col flex-wrap grow justify-center self-stretch p-2.5 w-full text-center bg-white shadow-sm rounded-[40px_0px] max-md:px-5 max-md:mt-8">
      <img loading="lazy" src={icon} alt={`Step ${stepNumber} icon`} className="self-center aspect-square w-[50px]" />
      <div className="flex flex-col px-2 mt-6">
        <h3 className="text-2xl font-bold leading-8 text-gray-800">
          <span className="text-blue-700">Step {stepNumber}: </span>
          <span className="font-semibold text-gray-800">{title}</span>
        </h3>
        <p className="text-lg font-light leading-7 text-black">{description}</p>
      </div>
    </div>
  </div>
);
const CallToAction = () => (
  <section className="flex overflow-hidden relative flex-col justify-center mt-7 max-w-full font-medium text-center text-white min-h-[478px] w-[1200px]">
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a1dfeeca078f10ea9a0056b362d2a595f241bd845699d50f0eea90384bb798b1?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="Background image" className="object-cover absolute inset-0 size-full" />
    <div className="flex relative flex-col justify-center px-8 py-20 bg-gray-800 max-md:px-5 max-md:max-w-full">
      <div className="flex justify-center items-center px-2.5 pt-2.5 pb-2.5 my-4 max-md:px-5 max-md:max-w-full">
        <div className="flex flex-col items-center max-w-full w-[770px]">
          <h2 className="self-stretch text-5xl font-semibold leading-[60px] max-md:max-w-full max-md:text-4xl max-md:leading-[59px]">
            Are you finally ready to start new <br /> business ventures?
          </h2>
          <p className="mt-6 text-2xl tracking-wider leading-none max-md:max-w-full">
            Get your business registration process started today.
          </p>
          <a href="#registration-form" className="flex flex-col justify-center px-7 py-5 mt-16 max-w-full text-base leading-4 capitalize bg-blue-700 border-2 border-white border-solid shadow-sm rounded-[30px] w-[338px] max-md:px-5 max-md:mt-10">
            <div className="flex gap-0 justify-between py-px">
              <span>Take Me To The Registration Form</span>
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/5376ef3db81e85f879c6f79863d73413ffefa632f5cb3b0c91d2376e65b25790?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="" className="shrink-0 self-start w-4 aspect-square" />
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
);

const FAQSection = () => {
  const faqs = [
    { question: "What is a limited liability company (LLC)?", isOpen: false },
    { question: "What is limited liability?", isOpen: false },
    { question: "Do you offer an annual or a monthly subscription?", isOpen: false },
    { question: "Which states do you operate in?", isOpen: false },
    { question: "Do you have a physical office?", isOpen: false },
    { question: "Which engagement type suits my organization best?", isOpen: false },
    { question: "What regions do you serve?", isOpen: false }
  ];

  return (
    <section className="mt-7 max-w-full w-[1140px]">
      <h2 className="text-5xl font-semibold text-center text-gray-800 leading-[59.8px] max-md:text-4xl">FAQ</h2>
      <p className="mt-5 text-base font-light leading-5 text-center text-black">Most frequent questions and answers</p>
      <div className="flex flex-col mt-20 max-w-full text-base font-semibold leading-4 text-black uppercase bg-white max-md:mt-10">
        {faqs.map((faq, index) => (
          <FAQItem key={index} question={faq.question} isOpen={faq.isOpen} />
        ))}
      </div>
    </section>
  );
};

const FAQItem = ({ question, isOpen }) => (
  <div className="flex flex-col justify-center mt-3.5 shadow-lg bg-white bg-opacity-0 max-md:max-w-full">
    <div className="flex gap-5 justify-between px-4 py-3 bg-white max-md:flex-wrap max-md:px-5 max-md:max-w-full">
      <h3>{question}</h3>
      <img loading="lazy" src={isOpen ? "https://cdn.builder.io/api/v1/image/assets/TEMP/2ccb17937d24c6ff4b5c7527232f6885c441e07922f82bc4b3d42846e864d286?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" : "https://cdn.builder.io/api/v1/image/assets/TEMP/df9b96e42f73e7025d32e61ee343e793f3193b013084e8211fd709d9f26c2987?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"} alt={isOpen ? "Collapse" : "Expand"} className="shrink-0 self-start  aspect-square" />
    </div>
  </div>
);

const Footer = () => (
  <footer className="flex justify-center items-center self-stretch px-16 py-16 mt-44 w-full bg-gray-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
    <div className="justify-center px-0.5 max-w-full w-[1140px]">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-[36%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow justify-center self-stretch px-2.5 py-2 text-sm font-light leading-7 text-white text-opacity-70 max-md:mt-10">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a13de2561477db57c820ee915162132b7b38b784136173f46910c91246c20682?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="Company logo" className="max-w-full aspect-[4] w-[258px]" />
            <p className="mt-5">
              We effectively manage your business formation, <br /> virtual mailbox, provide legal and advisory <br /> assistance. vState Filings sets up your <br /> company in a few steps.
            </p>
            <div className="flex gap-5 mt-5 whitespace-nowrap">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/964a966dc8908560b33cbf86574bbe4e42d118fcc580bc062bb40d10689797e1?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="" className="shrink-0 my-auto w-4 aspect-square" />
              <a href="mailto:support@vstatefilings.com">support@vstatefilings.com</a>
            </div>
            <div className="flex gap-5 mt-1.5">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe41a9d3b4615f6ac2c895d1306d341c4f0cc0d2af86bbcfaa81fe82a325ab14?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="" className="shrink-0 my-auto w-4 aspect-square" />
              <div className="underline">
                <a href="tel:866-638-3309" className="underline" target="_blank" rel="noopener noreferrer">(866) 638-3309</a> | <a href="tel:718-569-2703" className="underline" target="_blank" rel="noopener noreferrer">(718) 569-2703</a>
              </div>
            </div>
            <div className="flex gap-5">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/f3345c82ef9ff77bb4460d2e125fd5cd3833d47c99e7af1b2c2a3648e17f8b32?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="" className="shrink-0 my-auto w-4 aspect-square" />
              <address className="not-italic">301 Mill Road, Suite U5, Hewlett, NY 11557</address>
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[64%] max-md:ml-0 max-md:w-full">
          <div className="grow max-md:mt-10 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow self-stretch p-2.5 text-sm font-light leading-7 text-white text-opacity-70">
                  <h3 className="text-lg font-medium leading-6 text-white">Services</h3>
                  <nav className="mt-5">
                    <ul>
                      <li>C-Corp</li>
                      <li>PC or PLLC</li>
                      <li>LP</li>
                      <li>LLP</li>
                      <li>Conversions</li>
                      <li>Tax ID - EIN</li>
                      <li>UCC Searches & Filings</li>
                      <li>S-Corp</li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow self-stretch p-2.5">
                  <h3 className="text-lg font-medium leading-6 text-white">Disclaimer</h3>
                  <p className="mt-5 text-sm font-light leading-7 text-white text-opacity-70">
                    The information contained on this site is not <br /> and should not be construed as legal advice. <br /> vState Filings provides business registration, registered agent, Attorney and remote online services based on a variety of legal, economic, geographic and other criteria.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

const Copyright = () => (
  <div className="flex justify-center items-center self-stretch px-16 py-px w-full bg-gray-800 border-t border-solid border-white border-opacity-20 max-md:px-5 max-md:max-w-full">
    <div className="flex gap-5 justify-center p-2.5 max-md:flex-wrap">
      <div className="flex gap-0 pb-px my-auto text-xs font-light leading-4 text-white text-opacity-70 max-md:flex-wrap">
        <span>© Copyright 2023 | </span>
        <span>BusinessAnywhere LLC </span>
        <span>| </span>
        <a href="#terms">Terms</a>
        <span> | All Rights Reserved</span>
      </div>
      <div className="flex gap-2.5 pl-20 max-md:flex-wrap">
        <a href="#facebook" className="flex justify-center items-center p-2.5 bg-blue-700 h-[33px] rounded-[50px] w-[33px]">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/092d1dff1cd24a1386c6cc391eda28d26fb20914e1c1ca56fde41a0fb7d6a1f1?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="Facebook" className="aspect-square w-[15px]" />
        </a>
        <a href="#twitter" className="flex justify-center items-center p-2.5 bg-blue-700 h-[33px] rounded-[50px] w-[33px]">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/22145ed9c944e0033f6b77761c109055cdf40c4755cde7a7d1d1b0d05ff3cd63?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="Twitter" className="aspect-square w-[15px]" />
        </a>
        <a href="#linkedin" className="flex justify-center items-center p-2.5 bg-blue-700 h-[33px] rounded-[50px] w-[33px]">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/12990e676cbb4cca063868d602b027f5bceb5722ac8d236385bb7cc1f00adaea?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" alt="LinkedIn" className="aspect-square w-[15px]" />
        </a>
      </div>
    </div>
  </div>
);

function HomeLandingPage() {
  return (
    <div className="flex flex-col">
      <header>
        <HeaderTop />
        <NavigationMenu />
      </header>
      <main style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
        <HeroSection />
        <FeatureSection />
        <WhyChooseUsSection />
        <StepsSection />
        <CallToAction />
        <FAQSection />
      </main>
      <Footer />
      <Copyright />
    </div>
  );
}

export default HomeLandingPage;