import React from "react";

function StatCard({ title, value }) {
  return (
    <div className="flex flex-col flex-1 shrink self-stretch p-4 bg-white rounded-lg border border-gray-200 shadow-sm basis-0 min-w-[200px] max-md:px-5">
      <div className="flex gap-2 items-start w-full">
        <h3 className="flex-1 shrink text-base font-medium text-gray-900 basis-0">
          {title}
        </h3>
      </div>
      <div className="flex gap-4 items-end mt-3 w-full">
        <div className="flex flex-col flex-1 shrink w-full basis-0">
          <div className="text-3xl font-semibold tracking-tighter leading-none text-gray-900">
            {value}
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatCard;
