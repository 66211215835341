import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";
import imgsrc from "../../../../Assets/Images/supplies.png";
import AOS from "aos";
import "aos/dist/aos.css";

const services = [
  {
    title: "Legal Compliance",
    description:
      "Having the proper corporate supplies ensures your business complies with legal requirements for record-keeping and issuance of shares or membership units.",
  },
  {
    title: "Professional Presentation",
    description:
      "Corporate kits and seals enhance the professional image of your business when conducting official transactions or presentations.",
  },
  {
    title: "Simplified Organization",
    description:
      "Corporate kits provide organized storage for essential documents, making it easy to reference them when needed.",
  },
  {
    title: "Customizable Solutions",
    description:
      "Our supplies can be customized to meet the specific needs of your business, from branded stock certificates to personalized corporate seals.",
  },
];

const CorporateSuppliesSec = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <section className="main-section-class">
      <div className="entity-formation">
        <Container>
          <CommonHeadingTitle
            heading={"Why source"}
            blueheading={" corporate supplies?"}
          />
          <div className="text-center mt-4 mb-4"></div>
          <Row className="mt-5">
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <Row>
                {services.map((service, index) => (
                  <Col lg={12} md={12} sm={12} xs={12} key={index}>
                    <div className="main-class-div">
                      <h4 className="medium-level-heading">{service.title}</h4>
                      <div className="grey-border mt-3 mb-3"></div>
                      <p className="normal-common-txt">{service.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}  data-aos="fade-right"
              data-aos-duration="3000">
              <img loading="lazy" className="user-img-buisness" src={imgsrc} />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default CorporateSuppliesSec;
