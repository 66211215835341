
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataScroller } from 'primereact/datascroller';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { Chips } from 'primereact/chips';


import { Checkbox } from 'primereact/checkbox';
import { Avatar } from 'primereact/avatar';

import { MultiSelect } from 'primereact/multiselect';
import { Accordion, AccordionTab } from 'primereact/accordion';


import { SplitButton } from 'primereact/splitbutton';


import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';

import { Dropdown } from 'primereact/dropdown';

import { DataView } from 'primereact/dataview';

import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabView, TabPanel } from 'primereact/tabview';
import { Sidebar } from 'primereact/sidebar';
import { OverlayPanel } from 'primereact/overlaypanel';
import authContext from '../common/authContext';
import { useNavigate } from 'react-router-dom';
import UserServices from '../services/userService';
import { RadioButton } from 'primereact/radiobutton';
import Sidesbar from '../common/Sidesbar';
import Headers from '../common/Headers';
import ContactService from '../services/ContactService';
import AddCustomUser from './AddComponents/AddCutsomUser';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import AddEmployee from './AddComponents/AddEmployee';
import GroupDataTable from './GroupDataTable';
import CompanyLocation from './CompanyLocation';
import CompanyOwners from './Company/CompanyOwners';
import PrimaryContact from './Company/PrimaryContact';
import ShipingContact from './Company/ShipingContact';
import BilingContact from './Company/BilingContact';
import GeneralPersonal from './Company/GeneralPersonal';
import AddCompany from './AddComponents/AddCompany';
import companyMasterService from '../services/companyMasterService';
import CompanyOrders from './Company/CompanyOrders';
import AddCompanyLocation from './Company/AddCompanyComponets/AddLocation';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddressService from '../services/addressService';
import errorMessages from '../utils/errorMessages';
import rolesMasterService from '../services/rolesMaster';
import AddVendor from './AddComponents/AddVendor';
import { commonGetInitials } from '../utils/getInitials';




export default function VendorDashboard() {

    const { ip, permissions, accessToken, setFeedbackData, setEmployee, setFileType, setDocBase64, setLevel, setId, posts, setPosts, offerLetId, setOfferLetId, open, setOpen, open1, setOpen1, setResumeUrl, setOpen2, open3, setOpen3, open5, setOpen5, open6, setOpen6, open7, setOpen7, setFeedback } = useContext(authContext);
    let newObject = window.localStorage.getItem("user");
    let updatedUser = Object.assign({}, JSON.parse(newObject));

    const hasPermission = (permission) => permissions.includes(permission);

    const [filteredData, setFilteredData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

    const [sortName, setSortName] = useState(null)

    const [chipsData, setChipsData] = useState([]);

    const [chipsData1, setChipsData1] = useState([]);

    const [filterChipData, setFilterChipData] = useState([])

    const [selectedData, setSelectedData] = useState([]);

    const [allCities, setAllCities] = useState([])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

        id: { value: null, matchMode: FilterMatchMode.IN },
        companyName: { value: null, matchMode: FilterMatchMode.EQUALS },
        lastName: { value: null, matchMode: FilterMatchMode.EQUALS },
        mobileNo: { value: null, matchMode: FilterMatchMode.EQUALS },
        emailId: { value: null, matchMode: FilterMatchMode.EQUALS },

    });

    // const [city, setCity] = useState([])

    const [exp1, setExp1] = useState(null)

    const [exp2, setExp2] = useState(null)

    const [skills, setSkills] = useState([]);
    const [skillError, setSkillError] = useState("");
    const [skillARR, setSkillARR] = useState([]);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    const [qualification, setQualification] = useState([])
    const [qualificationArr, setQualificationArr] = useState([])

    const [visible, setVisible] = useState(false)

    const [visible1, setVisible1] = useState(false)

    const [visible2, setVisible2] = useState(false)

    const [visible4, setVisible4] = useState(false)

    const [visible5, setVisible5] = useState(false)

    const [visible6, setVisible6] = useState(false)

    const [visibleContent, setVisibleContent] = useState(false)

    const [allPosition, setAllPosition] = useState([])

    const [position, setPosition] = useState(null)

    const [positionData, setPositionData] = useState(null)

    const [candidate, setCandidate] = useState(null)

    const [closeProgresbar, setCloseProgresbar] = useState(false)

    const [closeProgresbar1, setCloseProgresbar1] = useState(false)

    const [globalKeyValue, setGlobalKeyValue] = useState("")

    const [AllCandidate, setAllCandidate] = useState([])

    const [showSkeleton, setShowSkeleton] = useState(false)

    const [selectedProduct, setSelectedProduct] = useState(null)

    const [data, setData] = useState(null)

    const op = useRef(null);

    const downloadop = useRef(null)

    const [isRemoveChip, setIsRemoveChip] = useState(false)

    const [fback, setFback] = useState([])

    const [candidateFback, setCandidateFback] = useState(null)

    const [interviewLevel1, setInterviewLevel1] = useState([])

    const [hrPanelList, setHrPanelList] = useState([])

    const [hrpanel, setHrPanel] = useState(null)

    const [selectedPanelData, setSelectedPanelData] = useState([])

    const [selectedPanelList, setSelectedPanelList] = useState([])

    const [showPanel, setShowPanel] = useState(false)

    const [globalSearchValue, setGlobalSearchValue] = useState("")

    const history = useNavigate()

    const [isShowContent, setIsShowContent] = useState(false)

    const [isShowContent1, setIsShowContent1] = useState(false)

    const [isShowContent2, setIsShowContent2] = useState(false)

    const [isShowContent3, setIsShowContent3] = useState(false)

    const [salutation, setSalutation] = useState("")

    const [companyName, setCompanyName] = useState("")

    const [domicileSate, setdomicileSate] = useState("")

    const [DomicileCity, setDomicileCity] = useState("")


    const [firstName, setFirstName] = useState("")

    const [lastName, setLastName] = useState("")

    const [website, setWebsite] = useState("")

    const [ingredient, setIngredient] = useState('');

    const [emailErr, setEmailErr] = useState("")

    const [passwordErr, setPasswordErr] = useState("")

    const [confirmPasswordErr, setConfirmPasswordErr] = useState("")

    const [firstNameErr, setFirstNameErr] = useState("")

    const [lastNameErr, setLastNameErr] = useState("")

    const [FormationDate, setFormationDate] = useState(dayjs(null))

    const [DissolutionDate, setDissolutionDate] = useState("")

    const [serviceState, setServiceState] = useState(null)

    const [domesticState, setDomesticState] = useState(null)

    const [industryType, setIndustryType] = useState(null)

    const [phone, setPhone] = useState("")

    const [phoneErr, setPhoneErr] = useState("")

    const [EINNumber, setEINNumber] = useState("")

    const [entityNumber, setEntityNumber] = useState("")

    const [texYearEnd, setTaxYearEnd] = useState("")

    const [allIndustryType, setAllIndustryType] = useState([

    ])

    const [businessPurpose, setBusinessPurpose] = useState("")

    const [businessStructure, setBusinessStructure] = useState(null)

    const [openDateErr, setOpenDateErr] = useState("")

    const [admin, setAdmin] = useState(null)

    const [email, setEmail] = useState("")

    const [altEmail, setAltEmail] = useState("")

    const [fax, setFax] = useState("")

    const [altFax, setAltFax] = useState("")

    const [addr1, setAddr1] = useState("")

    const [addr1Err, setAddr1Err] = useState("")

    const [addr2, setAddr2] = useState("")

    const [addr2Err, setAddr2Err] = useState("")

    const [addr3, setAddr3] = useState("")

    const [region, setRegion] = useState("")

    const [regionErr, setRegionErr] = useState("")


    const [states, setStates] = useState(null)

    const [zipErr, setZipErr] = useState("")

    const [zip, setZip] = useState("")

    const [pageRefresh, setPageRefresh] = useState(false)

    const [dataId, setDataId] = useState(null)


    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [rolesName, setRolesName] = useState("")

    const [statesErr, setStatesErr] = useState("")

    const [tempProduct, setTempProduct] = useState([])

    const [allAddressType, setAllAddressType] = useState([])

    const [addressType, setAddressType] = useState(null)

    const [cityErr, setcityErr] = useState('')

    const [city, setCity] = useState(null)

    const [allCountries, setAllCountries] = useState([])

    const [countryData, setCountryData] = useState(null)

    const [stateValue, setStateValue] = useState(null)

    const [countryValue, setCountryValue] = useState(null)

    const [allLocation, setAllLocation] = useState([])

    const [serviceStateValue, setServiceStateValue] = useState(null)

    const [domesticStateValue, setDomesticStateValue] = useState(null)


    const [products, setProducts] = useState([
    ]);

    const [principalAddress, setPrinciPalAddress] = useState(null)
    const [selectedCountry, setSelectedCountry] = useState(null);
    const countries = [
        { name: 'Australia', code: 'AU' },
        { name: 'Brazil', code: 'BR' },
        { name: 'China', code: 'CN' },
        { name: 'Egypt', code: 'EG' },
        { name: 'France', code: 'FR' },
        { name: 'Germany', code: 'DE' },
        { name: 'India', code: 'IN' },
        { name: 'Japan', code: 'JP' },
        { name: 'Spain', code: 'ES' },
        { name: 'United States', code: 'US' }
    ];


    const [allStates, setAllStates] = useState([

    ]);

    const entityTypes = [
        { label: "LLC", value: "LLC" },
        { label: "Corp", value: "Corp" }
    ];

    const [entityType, setEntityType] = useState(null)

    const [filteredStateValue, setFilteredStateValue] = useState(null)

    const user = JSON.parse(localStorage.getItem('user'));

    const [loginDetails, setLoginDetails] = useState([

        { key: "Password", value: 'dhdhdhdhhdhd' },
    ])

    const [editLoginValues, setEditLoginValues] = useState(
        loginDetails.reduce((acc, item) => {
            acc[item.key] = item.value;
            return acc;
        }, {})
    )

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setFilteredData(value)
        setGlobalFilterValue(value);
    };

    const handleLoginChange = (key, value) => {
        setEditLoginValues({ ...editLoginValues, [key]: value });
    };



    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div>{option.name}</div>
            </div>
        );
    };

    const panelFooterTemplate = () => {
        return (
            <div className="py-2 px-3">
                {selectedCountry ? (
                    <span>
                        <b>{selectedCountry.name}</b> selected.
                    </span>
                ) : (
                    'No country selected.'
                )}
            </div>
        );
    };





    const [isEditing, setIsEditing] = useState(false);
    const [isEditing1, setIsEditing1] = useState(false);
    const [isEditing2, setIsEditing2] = useState(false);
    const [editValues, setEditValues] = useState(

    );


    const item = [
        {
            label: 'Name',
            // icon: 'pi pi-refresh',
            command: () => {
                sortData('fullName')

            }
        },

        {
            label: 'Experience',

            command: () => {
                sortData('experienceInYears')

            }
        },

    ];


    useEffect(() => {
        companyMasterService.getAllVendor()
            .then((res) => {
                console.log(res)
                let data = res.data
                setProducts(res.data)
                setTempProduct(res.data)
                setShowSkeleton(true)
                if (data.length > 0) {
                    setSelectedProduct(data[0])
                    console.log(data)
                    setDataId(data[0].id)
                    setIngredient(data[0].email)
                    setIndustryType(data[0].industryId)
                    setPhone(data[0].phone)
                    setWebsite(data[0].websiteUrl)
                    setCompanyName(data[0].companyName)
                    setBusinessStructure(data[0].entityType)
                    setFormationDate(data[0].formationDate)
                    setDissolutionDate(data[0].dissolutionDate)
                    const temp = allStates.filter((val) => val.id === data[0].serviceStateId)
                    const temp1 = allStates.filter((val) => val.id === data[0].domesticStateId)
                    if (temp.length > 0) {
                        setServiceState(temp[0])
                    }
                    if (temp1.length > 0) {
                        setDomesticState(temp1[0])
                    }

                    if (data[0].phone) {
                        const temp3 = data[0].phone.split(" ")
                        if (Array.isArray(temp3)) {
                            if (temp3.length > 0) {
                                setSelectedCountry(temp3[0])
                                setPhone(temp3.slice(1).join(" "))
                            }
                        }
                    }

                    setData(data[0])
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [pageRefresh])


    useEffect(() => {
        if (data) {
            setCompanyName(data.firstName)
            setDomicileCity(data.DomicileCity)
            setFormationDate(data.mobileNo)
            setEmail(data.emailId)
        }
    }, [data])

    useEffect(() => {
        if (dataId) {
            companyMasterService.getAllCompanyById(dataId)
                .then((res) => {
                    console.log(res)
                    if (res.data !== "") {
                        console.log(res.data)
                        //         setAllLocation(res.data)
                        setCompanyName(res.data.companyName)
                        setBusinessStructure(res.data.entityType)
                        // setFormationDate(res.data.formationDate)
                        setDissolutionDate(res.data.dissolutionDate)
                        setIngredient(res.data.email)
                        setIndustryType(res.data.industryId)
                        setPhone(res.data.phone)
                        setWebsite(res.data.websiteUrl)
                        setFax(res.data.fax)
                        const temp = allStates.filter((val) => val.id === res.data.serviceStateId)
                        console.log(temp)
                        const temp1 = allStates.filter((val) => val.id === res.data.domesticStateId)
                        if (temp.length > 0) {
                            setServiceState(temp[0].id)
                            setServiceStateValue(temp[0].stateFullDesc)
                        }
                        if (temp1.length > 0) {
                            setDomesticState(temp1[0].id)
                            setDomesticStateValue(temp1[0].stateFullDesc)
                        }
                        if (res.data.phone) {
                            const temp3 = res.data.phone.split(" ")
                            if (Array.isArray(temp3)) {
                                if (temp3.length > 0) {
                                    setSelectedCountry(temp3[0])
                                    setPhone(temp3.slice(1).join(" "))
                                }
                            }
                        }

                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            AddressService.getAllAddressByCompanyId(dataId)
                .then((res) => {
                    console.log(res)
                    if (res.data !== "") {
                        console.log(res.data)
                        setAllLocation(res.data)
                        if (Array.isArray(res.data)) {
                            const temp = res.data.filter((val) => val.addressType.type === "Principal")
                            console.log(temp)
                            if (temp.length > 0) {
                                setPrinciPalAddress(temp[0])
                                setAddr1(temp[0].addressLine1)
                                setAddr2(temp[0].addressLine2)
                                setCity(temp[0].city)
                                setZip(temp[0].postalCode)
                                setCountryData(temp[0].countryId)
                                setStates(temp[0].stateId)
                                setAddressType(temp[0].addressType)
                            }
                            else {
                                setPrinciPalAddress(null)
                            }
                        }

                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }




    }, [dataId])

    useEffect(() => {
        if (data) {
            setDataId(data.id)
        }

    }, [data])

    console.log(data)

    // useEffect(() => {

    //     AddressService.getAllAddressType()
    //         .then((res) => {
    //             console.log(res)
    //             if (res.data !== "") {
    //                 setAllAddressType(res.data)
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })

    //     rolesMasterService.getAllIndustryType()
    //         .then((res) => {
    //             console.log(res)
    //             if (res.data !== "") {
    //                 console.log(res)
    //                 setAllIndustryType(res.data)
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })

    //     AddressService.getAllCountry()
    //         .then((res) => {
    //             console.log(res)
    //             if (res.data !== "") {
    //                 setAllCountries(res.data)
    //             }

    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })



    //     AddressService.getAllStates()
    //         .then((res) => {
    //             console.log(res)
    //             if (res.data !== "") {
    //                 setAllStates(res.data)
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }, [])



    console.log(dataId)







    const handleFilter = () => {
        if (products.length > 0 && filteredStateValue && !entityType) {
            console.log(products)
            const filteredContacts = products.filter(contact =>
                contact.serviceStateId === filteredStateValue
            );
            setProducts(filteredContacts)
        }
        if (products.length > 0 && entityType && !filteredStateValue) {
            const filteredContactsByRole = products.filter(contact => contact.entityType === entityType

            )
            console.log(filteredContactsByRole)

            setProducts(filteredContactsByRole)
        }
        if (products.length > 0 && entityType && filteredStateValue) {
            const filteredContactsByRole = products.filter(contact =>
                contact.entityType === entityType && contact.serviceStateId === filteredStateValue
            )
            setProducts(filteredContactsByRole)
        }
    }


    const handleClearFilter = () => {
        setProducts(tempProduct)
        setFilteredStateValue(null)
        setEntityType(null)
    }


    const handleEmail = (e) => {
        setEmailErr('')
        const value = e.target.value
        if (value === "") {
            setEmailErr(errorMessages.emailRequired)
        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
            setEmailErr(errorMessages.emailValidation);
        }
        setIngredient(e.target.value)
    }



    const handleEntityNumber = (e) => {
        let value = e.target.value
        // if (value === '') {
        //     setLastNameErr(errorMessages.lastNameRequired)

        // }
        // if (value && value.trim() !== '') {
        //     if (!/[A-Za-z]+$/.test(value)) {
        //         setLastNameErr(errorMessages.lastNameValidation)
        //     }

        // }
        setEntityNumber(e.target.value)
    }

    const handleEINNumber = (e) => {
        let value = e.target.value
        // if (value === '') {
        //     setLastNameErr(errorMessages.lastNameRequired)

        // }
        // if (value && value.trim() !== '') {
        //     if (!/[A-Za-z]+$/.test(value)) {
        //         setLastNameErr(errorMessages.lastNameValidation)
        //     }

        // }
        setEINNumber(e.target.value)
    }

    const handleTaxYearEnd = (e) => {
        let value = e.target.value
        // if (value === '') {
        //     setLastNameErr(errorMessages.lastNameRequired)

        // }
        // if (value && value.trim() !== '') {
        //     if (!/[A-Za-z]+$/.test(value)) {
        //         setLastNameErr(errorMessages.lastNameValidation)
        //     }

        // }
        setTaxYearEnd(e.target.value)
    }

    const handleFax = (e) => {
        let value = e.target.value

        setFax(e.target.value)
    }



    const handleWebsite = (e) => {
        let value = e.target.value

        setWebsite(e.target.value)
    }

    const handleFirstName = (e) => {
        setFirstNameErr("")
        let value = e.target.value
        if (value === '') {
            setFirstNameErr(errorMessages.firstNameRequired)

        }
        if (value && value.trim() !== '') {
            if (!/[A-Za-z]+$/.test(value)) {
                setFirstNameErr(errorMessages.firstNameValidation)
            }

        }
        setFirstName(e.target.value)
    }


    const handleEdit = (data) => {
        setDataId(data.id)
        setIsEditing1(true);
    };

    const handleEdit1 = (data) => {
        console.log(data.id)
        setDataId(data.id)
        setIsEditing(true);
    };

    const handleEdit2 = (data) => {
        setDataId(data.id)
        setIsEditing2(true);
    };

    const handlePhone = (e) => {
        setPhoneErr("");
        let value = e.target.value;

        let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
        if (inputValue.length > 10) {
            inputValue = inputValue.slice(0, 10); // Limit to 10 digits
        }
        const formattedValue = inputValue.replace(
            /(\d{3})(\d{3})(\d{4})/,
            (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
        );
        if (inputValue.length === 10) {
            // Only show formatted value after 10 digits
            e.target.value = formattedValue;
            setPhone(formattedValue); // Update state with formatted number
        } else {
            e.target.value = inputValue;
            setPhone(inputValue);
        }

        if (value === "") {
            setPhoneErr(errorMessages.phoneRequired);
        }
        if (value && value.trim() !== "" && inputValue.length <= 9) {
            if (
                !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(value)
            ) {
                setPhoneErr(errorMessages.phoneValidation);
            }
        }
        setPhone(e.target.value);
    };


    const onCountryChange = (e) => {
        setSelectedCountry(e.value);

    };


    const handleOpenDate = (newValue) => {
        setOpenDateErr('')
        console.log(newValue)
        if (newValue && dayjs(newValue).isValid()) {
            // setFormationDate(newValue);
            if (newValue !== null) {
                setFormationDate(newValue)
                // const d = new Date(newValue.$d)
                // if (isNaN(d.getDay()) || isNaN(d.getMonth()) || isNaN(d.getFullYear())) {
                //     setOpenDateErr("Select Valid Position Opened Date")
                // }


            }
            else {
                setOpenDateErr('Position Open Date Required')
            }
        } else {
            setFormationDate(null); // or handle the invalid case
        }


    }

    const handleChange = (key, value) => {
        setEditValues({ ...editValues, [key]: value });
    };
    // const handleSave = () => {
    //     const newData = data.map((item) => ({
    //         ...item,
    //         value: editValues[item.key],
    //     }));
    //     setData(newData);
    //     setIsEditing(false);
    // };

    const handleSave = () => {


        if (data && dataId) {
            const actualdata = data
            console.log(data)
            actualdata["companyName"] = companyName
            actualdata["entityType"] = businessStructure ? businessStructure.value : null

            actualdata["serviceStateId"] = serviceState
            actualdata["domesticStateId"] = domesticState
            actualdata["industryId"] = industryType
            actualdata["address"] = principalAddress

            console.log(actualdata)
            // actualdata["formationDate"] = FormationDate
            // actualdata["dissolutionDate"] = DissolutionDate




            companyMasterService.updateCompany(actualdata)
                .then((res) => {
                    console.log(res)
                    setIsEditing1(false)
                    setPageRefresh(!pageRefresh)

                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }

    console.log(data)

    const handleSave2 = () => {


        if (data && dataId) {
            const actualdata = data
            console.log(data)
            actualdata["registeredAgentContactNo"] = phone
            actualdata["registeredAgentEmail"] = ingredient

            actualdata["fax"] = fax
            actualdata["websiteUrl"] = website





            companyMasterService.updateCompany(actualdata)
                .then((res) => {
                    console.log(res)
                    setIsEditing2(false)
                    setPageRefresh(!pageRefresh)

                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }

    const handleToggle = () => {
        setIsShowContent(!isShowContent)
    }

    const handleToggle1 = () => {
        setIsShowContent1(!isShowContent1)
    }

    const handleToggle2 = () => {
        setIsShowContent2(!isShowContent2)
    }

    const handleToggle3 = () => {
        setIsShowContent3(!isShowContent3)
    }



    const sortData = (field) => {
        if (field === sortField) {
            // If clicking on the same field, toggle the sort order
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If clicking on a different field, set the new field and default to ascending order
            setSortField(field);
            setSortOrder('asc');
        }
        const sortedData = [...products].sort((a, b) => {
            console.log(a)
            if (a.candidateDetails !== undefined && b.candidateDetails !== undefined) {
                const valueA = a.candidateDetails[field];
                const valueB = b.candidateDetails[field];

                if (valueA < valueB) {
                    return sortOrder === 'asc' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortOrder === 'asc' ? 1 : -1;
                }
            }

            return 0;

        });
        console.log(sortedData)

        setProducts(sortedData);
    };










    const handleFbackHeader = () => {
        return <>

            <div className='font-fam-for-all form-md-heading form-sm-close-Btn' >Pre-Screening Feedback
                <button className='form-close-Btn' onClick={() => setVisible6(false)}>
                    <i className='pi pi-times'>
                    </i>
                </button>
            </div>

        </>
    }


    const handleCity = (e) => {

        setCity(e.target.value)
    }

    const handleSkill = (e) => {

        setSkills(e.target.value)
    }

    const handleQualification = (e) => {

        setQualification(e.target.value)
    }

    const PasswordText = (text) => {
        const maskedText = text.replace(/./g, '*');
        return maskedText;
    };

    const handleSave1 = () => {
        const newData = loginDetails.map((item) => ({
            ...item,
            value: editLoginValues[item.key],
        }));
        setLoginDetails(newData);
        setIsEditing(false);
    };

    const handleHr = (e) => {
        setHrPanel(e.target.value)

        const newD = e.target.value

        const tempHr = selectedPanelList.filter((val) => {
            return newD.some((ele) => {
                return ele.fullName === val.employee.fullName
            })
        })
        console.log(tempHr)
        if (tempHr.length > 0) {
            setSelectedPanelData(tempHr)
        }

    }



    const LocationFilter = () => {
        return (
            <>
                <div>

                    <MultiSelect
                        value={city}
                        options={allCities}
                        onChange={handleCity}
                        optionLabel="cityName"
                        placeholder="Select Job Location"
                        display="chip"
                        filter
                        className='filter-dropdown-field' required />
                </div>
            </>
        )

    }

    const SkillFilter = () => {
        return (
            <>
                <div>

                    <MultiSelect
                        value={skills}
                        options={skillARR}
                        onChange={handleSkill}
                        optionLabel="skillName"
                        placeholder="Select Skill"
                        display="chip"
                        filter
                        className='filter-dropdown-field' required />
                </div>
            </>
        )

    }

    const QualificationFilter = () => {
        return (
            <>
                <div>

                    <MultiSelect
                        value={qualification}
                        options={qualificationArr}
                        onChange={handleQualification}
                        optionLabel="name"
                        placeholder="Select Qualification"
                        display="chip"
                        filter
                        className='filter-dropdown-field' required />
                </div>
            </>
        )

    }

    const allSortName = ["name", "location", "experienc"]

    const handleSortName = (e) => {
        setSortName(e.target.value)
        console.log(e.target.value)
        sortData(e.target.value)
    }

    const handlePosition = (e) => {
        const temp = e.target.value
        setPosition(e.target.value)
        if (temp.jobdescription.skill !== null) {
            setSkills(temp.jobdescription.skill)
        }
        if (temp.jobdescription.experience !== null) {
            const tempexp = temp.jobdescription.experience
            const arrexp = tempexp.split("-")
            console.log(arrexp)
            if (arrexp.length === 2) {
                setExp1(arrexp[0])
                setExp2(arrexp[1])
            }
        }


    }

    const handleClearPosition = () => {
        setVisible1(false)
        setVisible2(false)
        setPosition(null)
        setPositionData(null)
    }

    const handlePositionData = (e) => {
        setProducts(AllCandidate)
        const temp = e.target.value
        setPositionData(e.target.value)
    }

    const SortingFilter = () => {
        return (
            <>
                <div>
                    <Dropdown
                        value={sortName}
                        options={allSortName}
                        onChange={handleSortName}
                        placeholder="Sorting By"
                        filter
                        className='filter-dropdown-field' required />
                </div>
            </>
        )
    }

    console.log(position)

    let skill;
    if (position !== null) {
        if (position.jobdescription.skill !== null) {
            skill = position.jobdescription.skill.map((val) => {
                return val.skillName
            })
        }
    }

    const PositionFilter = () => {
        let skill;
        if (position !== null) {
            if (position.jobdescription.skill !== null) {
                skill = position.jobdescription.skill.map((val) => {
                    return val.skillName
                })
            }
        }
        return (
            <>
                <div className='mt-2 ml-3'>
                    <Dropdown
                        value={position}
                        options={allPosition}
                        onChange={handlePosition}
                        optionLabel='name'
                        placeholder="Select Position"
                        filter
                        className='filter-input-field1' required />

                </div>
                {position && <> <div className='grid ml-2 mt-2'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5'>
                        Skills :
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7'>
                        {skill !== undefined ? skill.toString() : ''}

                    </div>
                </div>

                    <div className='grid ml-2 mt-2'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-5'>
                            Experience :
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-7'>
                            {position !== null ? position.jobdescription.experience !== null ? `${position.jobdescription.experience} Years` : '' : ''}

                        </div>
                    </div></>}

            </>
        )
    }


    const handleExp1 = (e) => {
        setProducts(AllCandidate)
        setExp1(e.target.value)
    }

    const handleExp2 = (e) => {
        setProducts(AllCandidate)
        setExp2(e.target.value)
    }

    const listExp = []
    for (let i = 0; i < 50; i++) {
        listExp.push(`${i}`)
    }


    console.log(filters)



    const ExperinceFilter = () => {


        return (
            <>
                <label htmlFor='' className='form-label font-fam-for-all' style={{ marginLeft: '16px' }}>Experience <span className='form-field-mandatory'>*</span></label>
                <div className='grid'>


                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 ml-3">
                        <div className="field">



                            <span className="p-float-label ">
                                <Dropdown
                                    className='filter-input-field2' filter
                                    value={exp1} onChange={handleExp1} options={listExp} name='name'
                                />

                                {/* <InputText
                                    className='filter-input-field2'
                                    value={exp1} onChange={handleExp1} name='name'
                                /> */}
                            </span>

                        </div>
                    </div>

                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 p-2'>
                        <p className='mt-2 text-center'>To</p>

                    </div>



                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 pl-0">
                        <div className="field">

                            {/* <label htmlFor='' className='form-label font-fam-for-all'>Highest Exp. <span className='form-field-mandatory'>*</span></label> */}
                            <span className="p-float-label ">

                                <Dropdown
                                    className='filter-input-field2' filter
                                    value={exp2} onChange={handleExp2} options={listExp} name='name'
                                />

                                {/* <InputText
                                    className='filter-input-field2'
                                    value={exp2} onChange={handleExp2} name='name'
                                /> */}
                            </span>

                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-1'>

                    </div>

                </div>
            </>
        )
    }

    const ExpandedComponent = ({ item }) => {
        return (
            <div className='p-2' style={{ borderTop: '1px solid #ddd' }}>
                <p>{item.details}</p>
                {/* Add your expanded component content here */}
                {item.id === 1 ? <LocationFilter /> : ''}
            </div>
        );
    };



    useEffect(() => {
        setProducts(AllCandidate)
        // handleRemoveSearch()
        console.log('first')
        if (filterChipData.length === 0) {
            setProducts(AllCandidate)
        }

    }, [filterChipData])

    console.log(products)



    const filterData = (event) => {
        const filteredItems = products.filter((item) => {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    const element = (item[key]).toString();
                    console.log(element)

                    if (element.toLowerCase().includes(event.target.value.toLowerCase())) {
                        return item;
                    }


                }
            }

        });
        setFilteredData(filteredItems);
    };


    // effect of filter all

    function checkAllExist(a, b) {
        // Convert all elements of array b to lowercase for case-insensitive comparison
        const lowercaseB = b.map(item => item.toLowerCase());

        for (const element of a) {
            // Convert element to lowercase for case-insensitive comparison
            const lowercaseElement = element.toLowerCase();

            if (!lowercaseB.includes(lowercaseElement)) {
                return false; // If any element of a is not found in b, return false
            }
        }
        return true; // All elements of a were found in b
    }


    function convertMonthsToYears(years) {
        return years * 12;
    }






    const handleRemoveSearch = () => {

        const filteredItems = products.filter((item) => {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    if (item[key] !== null && item[key] !== undefined) {
                        console.log(key)
                        // const element = (item[key]).toString();

                        if (key === 'candidateDetails') {
                            const candkey = item[key]
                            console.log(candkey)
                            for (const keys in candkey) {
                                if (candkey.hasOwnProperty(keys)) {
                                    // if (globalKeyValue !== "") {
                                    //     if (candkey[keys] !== null) {

                                    //         if (typeof candkey[keys] === 'string') {
                                    //             const element = (candkey[keys]).toString();
                                    //             console.log(globalKeyValue)
                                    //             if (element.toLowerCase().includes(globalKeyValue.toLowerCase())) {
                                    //                 return item;
                                    //             }
                                    //         }
                                    //     }
                                    // }


                                    if (candkey[keys] !== null && candkey[keys] !== undefined && exp1 !== null && exp2 !== null) {

                                        const experience1 = parseInt(exp1)
                                        const experience2 = parseInt(exp2)
                                        const currentExp = parseInt(item.candidateDetails.experienceInYears)
                                        if (currentExp >= experience1 && currentExp <= experience2) {
                                            if (Array.isArray(candkey[keys])) {
                                                const element = candkey[keys]
                                                for (let i = 0; i < element.length; i++) {
                                                    console.log(element[i])
                                                    for (const key in element[i]) {
                                                        if (Object.hasOwnProperty.call(element[i], key)) {
                                                            const elem = element[i][key];

                                                            if (typeof elem === 'string') {
                                                                console.log(elem)
                                                                console.log(skills)
                                                                for (let j = 0; j < skills.length; j++) {

                                                                    if (elem.toLowerCase().includes(skills[j].skillName.toLowerCase())) {
                                                                        return item;
                                                                    }
                                                                }
                                                            }

                                                        }
                                                    }


                                                }
                                            }
                                        }

                                    }

                                    //


                                    if (chipsData.length > 0 && exp1 !== null && exp2 !== null) {

                                        const experience1 = parseInt(exp1)
                                        const experience2 = parseInt(exp2)
                                        const currentExp = parseInt(item.candidateDetails.experienceInYears)
                                        // if (currentExp >= experience1 && currentExp <= experience2) {
                                        //     return item;
                                        // }
                                        if (currentExp >= experience1 && currentExp <= experience2) {
                                            for (let i = 0; i < chipsData.length; i++) {
                                                if (typeof candkey[keys] === 'string') {
                                                    const element = (candkey[keys]).toString();
                                                    if (element.toLowerCase().includes(chipsData[i].toLowerCase())) {
                                                        return item;
                                                    }
                                                }


                                                if (Array.isArray(candkey[keys])) {
                                                    const element = candkey[keys]
                                                    console.log(keys)
                                                    console.log(element)

                                                    if (keys === "skill") {
                                                        const objSkills = element.map(skillObj => skillObj.skillName);
                                                        console.log(objSkills)
                                                        console.log(chipsData)
                                                        const isExist = checkAllExist(chipsData, objSkills)
                                                        if (isExist) {
                                                            return item;
                                                        }
                                                    }

                                                }

                                            }
                                        }

                                    }


                                }
                            }
                        }

                    }
                }
            }

        });



        console.log(filteredItems)
        if (chipsData1.length === 0) {
            setProducts(filteredItems)
        }

    }




    const getSeverity = (product) => {
        switch (product.inventoryStatus) {
            case 'INSTOCK':
                return 'success';

            case 'LOWSTOCK':
                return 'warning';

            case 'OUTOFSTOCK':
                return 'danger';

            default:
                return null;
        }
    };

    // event on checkbox
    const onCheckboxChange = (event, data) => {
        console.log(event.checked)
        console.log(data)
        if (event.checked) {
            setSelectedData([...selectedData, data]);
        } else {
            setSelectedData(selectedData.filter(item => item !== data));
        }
    };

    const handleChecked = (e) => {
        const checked = e.checked;
        if (checked) {
            setSelectedData(products)
        }
        else {
            setSelectedData([])
        }
    }

    console.log(selectedData)

    // DataTable columns
    const cols = [
        { field: 'id', header: 'id' },
        { field: 'fullName', header: 'Name' },
        { field: 'skill', header: 'Skills' },
        { field: 'experienceInYears', header: 'Experience' },
        { field: 'city', header: 'Location' },


    ];



    const updateData = selectedData.map((elem) => {

    })



    const updateDataDownload = updateData.map((elem) => {

    })

    console.log(updateDataDownload)

    // Export DataTable data
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
    // const exportCSV = (selectionOnly) => {
    //     dt.current.exportCSV({ selectionOnly });
    // };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, updateDataDownload);
                doc.save('Candidate.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(updateDataDownload);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Candidate');
        });
    };

    const exportExcelPreScreening = (data) => {
        console.log(data)
        const updatedata = data.map((elem) => {
            const { candidateId, positionId, interviewLevelId, ...rest } = elem

            delete rest["createdBy"]
            delete rest["creationDate"]
            delete rest["lastModifiedDate"]
            delete rest["lastModifiedBy"]
            delete rest["id"]
            rest['panelDetails'] = (elem.panelDetails.map((e) => {
                return e.employee.fullName
            })).toString()
            if (typeof (elem.detailFeedback) === "object") {

                rest['location'] = elem.detailFeedback !== null ? elem.detailFeedback.location : null
                rest['preferLocation'] = elem.detailFeedback !== null ? elem.detailFeedback.preferLocation : null
                rest['domain'] = elem.detailFeedback !== null ? elem.detailFeedback.domain : null
                rest['Gap In Experience'] = elem.detailFeedback !== null ? elem.detailFeedback.gapExp : null
                rest['Communication'] = elem.detailFeedback !== null ? elem.detailFeedback.communication : null
                rest['Remarks'] = elem.detailFeedback !== null ? elem.detailFeedback.remarks : null
                rest['certifiaction'] = elem.detailFeedback !== null ? elem.detailFeedback.certification : null
            }
            if (Array.isArray(elem.technicalFeedback)) {
                elem.technicalFeedback.map((val) => {
                    rest[val.label] = val.value !== null ? val.value : null
                })
            }
            rest['candidateId'] = elem.candidateId !== null ? elem.candidateId.fullName : null
            rest['positionId'] = elem.positionId !== null ? elem.positionId.name : null
            rest['interviewLevelId'] = elem.interviewLevelId !== null ? elem.interviewLevelId.levelName : null
            return {
                candidateId,
                positionId, interviewLevelId,
                ...rest

            }
        })

        console.log(updatedata)
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(updatedata);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Pre-Screening-details');
        });
    }
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    };




    console.log(selectedPanelData)



    const CloseProgresbarheader1 = () => {
        setCloseProgresbar1(false)
    }


    const handleClick3 = () => {
        setVisible(false)
    }

    const handleProfile = (data) => {
        setVisible2(true)
        console.log(data)
        setCandidate(data)
    }

    const handleMoreClick = (data) => {
        // const tgl = document.getElementById("Togle-btn")
        // if (tgl.textContent === "More Details") {
        //     setVisibleContent(true)
        // }
        // if (tgl.textContent === "Hide Details") {
        //     setVisibleContent(false)
        // }

        // Get the button and content element
        const toggleButton = document.getElementById(`toggleButton${data.candidateDetails.id}`);
        const content = document.getElementById(`content${data.candidateDetails.id}`);
        // Toggle the 'hidden' class on the content element
        content.classList.toggle('hidden');

        // Change button text based on content visibility
        if (content.classList.contains('hidden')) {
            toggleButton.textContent = '(More Details)';
        } else {
            toggleButton.textContent = '(Hide Details)';
        }


    }

    console.log(candidate)

    const handleSubmit = (e) => {
        e.preventDefault()

        let actualdata;
        if (filteredData.length > 0) {
            const selectedDatas = selectedData.map(obj => {
                const newObj = { ...obj };
                delete newObj["code"];
                return newObj.candidateDetails;
            });
            actualdata = {
                position: positionData,
                candidateDetails: selectedDatas,
                isActive: true
            }
        }
        else {
            const selectedDatas = selectedData.map(obj => {
                const newObj = { ...obj };
                return newObj.candidateDetails;
            });
            actualdata = {
                position: positionData,
                candidateDetails: selectedDatas,
                isActive: true
            }
        }

        const tempLevel = interviewLevel1.length > 0 ? interviewLevel1.filter((val) => val.preference === 1) : []

        const request =

        {
            "currentCtc": "",
            "positionId": position,
            "expectedCtc": "",
            "interviewDate": new Date(),
            "noticePeriod": "",
            "earliestAvailability": "",
            "detailFeedback": "",
            "behavourialFeedback": "",
            "technicalFeedback": "",
            "feedbackSummary": "",
            "offerLetter": "",
            "finalStatus": "Scheduled",
            "panelDetails": selectedPanelData,


            "candidateId": {

                "id": candidate.candidateDetails.id,
                "fullName": candidate.candidateDetails.fullName,
                "email": candidate.candidateDetails.email,
                "contactNo": candidate.candidateDetails.contactNo,
                "gender": candidate.candidateDetails.gender,
                "linkedInUrl": candidate.candidateDetails.linkedInUrl,
                "resumeUrl": candidate.candidateDetails.resumeUrl,
                "experienceInYears": candidate.candidateDetails.experienceInYears,
                "profileScannedOn": candidate.candidateDetails.profileScannedOn,

                "qualification": candidate.candidateDetails.qualification,
                "passingYear": candidate.candidateDetails.passingYear,
                "birthDate": candidate.candidateDetails.birthDate,
                "altContactNo": candidate.candidateDetails.altContactNo,
                "whatsappNo": candidate.candidateDetails.whatsappNo,
                "currentCompanyName": candidate.candidateDetails.currentCompanyName,
                "profileReferance": candidate.candidateDetails.profileReferance,


                "statusId": {
                    "id": candidate.candidateDetails.statusId.id,
                    "statusName": candidate.candidateDetails.statusId.statusName,
                    "statusType": candidate.candidateDetails.statusId.statusType,
                    // "isActive": statuscandidate.isActive,

                },
                "skill": candidate.candidateDetails.skill,


                "city": candidate.candidateDetails.city,

            },

            "interviewLevelId": tempLevel.length > 0 ? tempLevel[0] : null
        }



        if (actualdata !== undefined) {


        }
    }


    const handleSubmit1 = (e) => {
        e.preventDefault()

        let actualdata;
        if (filteredData.length > 0) {
            delete candidate["code"];
            let temp = candidate.candidateDetails

            actualdata = {
                position: positionData,
                candidateDetails: [temp],
                isActive: true
            }
        }
        else {
            let temp = candidate.candidateDetails
            actualdata = {
                position: positionData,
                candidateDetails: [temp],
                isActive: true
            }
        }

        console.log(actualdata)

        const tempLevel = interviewLevel1.length > 0 ? interviewLevel1.filter((val) => val.preference === 1) : []

        const request =

        {
            "currentCtc": "",
            "positionId": positionData,
            "expectedCtc": "",
            "interviewDate": new Date(),
            "noticePeriod": "",
            "earliestAvailability": "",
            "detailFeedback": "",
            "behavourialFeedback": "",
            "technicalFeedback": "",
            "feedbackSummary": "",
            "offerLetter": "",
            "finalStatus": "Scheduled",
            "panelDetails": selectedPanelData,


            "candidateId": {

                "id": candidate.candidateDetails.id,
                "fullName": candidate.candidateDetails.fullName,
                "email": candidate.candidateDetails.email,
                "contactNo": candidate.candidateDetails.contactNo,
                "gender": candidate.candidateDetails.gender,
                "linkedInUrl": candidate.candidateDetails.linkedInUrl,
                "resumeUrl": candidate.candidateDetails.resumeUrl,
                "experienceInYears": candidate.candidateDetails.experienceInYears,
                "profileScannedOn": candidate.candidateDetails.profileScannedOn,

                "qualification": candidate.candidateDetails.qualification,
                "passingYear": candidate.candidateDetails.passingYear,
                "birthDate": candidate.candidateDetails.birthDate,
                "altContactNo": candidate.candidateDetails.altContactNo,
                "whatsappNo": candidate.candidateDetails.whatsappNo,
                "currentCompanyName": candidate.candidateDetails.currentCompanyName,
                "profileReferance": candidate.candidateDetails.profileReferance,


                "statusId": {
                    "id": candidate.candidateDetails.statusId.id,
                    "statusName": candidate.candidateDetails.statusId.statusName,
                    "statusType": candidate.candidateDetails.statusId.statusType,
                    // "isActive": statuscandidate.isActive,

                },
                "skill": candidate.candidateDetails.skill,


                "city": candidate.candidateDetails.city,

            },

            "interviewLevelId": tempLevel.length > 0 ? tempLevel[0] : null
        }


        if (actualdata !== undefined && selectedPanelData.length > 0) {


        }
    }


    // Function to check if object exists in array
    function objectExists(array, obj) {
        return array.some(item => JSON.stringify(item) === JSON.stringify(obj));
    }







    const itemTemplate = (data) => {

        console.log(data)
        console.log(selectedData)


        const fullName = `${data.companyName}`

        return (
            <div className="srch-cand-card font-fam-for-all">
                <div className='srch-cand-checkbox'>
                    {/* 
                    <Checkbox
                        onChange={(e) => onCheckboxChange(e, data)}
                        checked={selectedData.includes(data)}
                    ></Checkbox> */}
                </div>
                <Avatar label={data.avatarImgPath ? '' : commonGetInitials(fullName)} image={data.avatarImgPath ? data.avatarImgPath : ''} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />
                <div className='grid'>
                    <div className='col-12 pb-0' >
                        <p className=' srch-cand-text1' >{`${data.companyName}`}</p>


                    </div>
                    <div className='col-12 pb-0'>
                        <p className=' srch-cand-text' >{data.email}</p>


                    </div>


                </div>


                {/* 
                {
                    data.candidateFeedback.length > 0 ?
                        data.candidateFeedback.map((val, i, arr) => (
                            <>
                                <div className='badge-container'>
                                    <div className='badge-main grid' style={(i === (data.candidateFeedback.length - 1)) ? val.finalStatus === "Rejected" ? { background: "#FEF3F2" } : { background: '#ECFDF3' } : { background: '#ffff' }}>
                                        <div className="col-12 p-0">
                                            {(i === (data.candidateFeedback.length - 1)) ? val.finalStatus === "Rejected" ? <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                                <circle cx="4" cy="4" r="3" fill="#F04438" />
                                            </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                                                <circle cx="4" cy="4" r="3" fill="#12B76A" />
                                            </svg> : ''}
                                            <span className='badge-label ml-1' style={(i === (data.candidateFeedback.length - 1)) ? val.finalStatus === "Rejected" ? { color: "#B42318" } : { color: '#027A48' } : { color: '#ffff' }}>{(i === (data.candidateFeedback.length - 1)) ? val.interviewLevelId.levelName : ''}</span>
                                        </div>

                                    </div>
                                </div>
                            </>
                        )
                        ) : ''

                } */}


            </div>
        );
    };




    const handleHeader = () => {
        return <>

            <div className='font-fam-for-all form-sm-heading form-sm-close-Btn' >Attach Profile <button className='form-close-Btn' onClick={() => setVisible1(false)}><i className='pi pi-times'></i></button></div>

        </>
    }


    const handleHeader1 = () => {
        return <>

            <div className='font-fam-for-all form-sm-heading form-sm-close-Btn' >Attach Profile <button className='form-close-Btn' onClick={() => setVisible2(false)}><i className='pi pi-times'></i></button></div>

        </>

    }


    const CloseProgresbarheader = () => {
        setCloseProgresbar(false)
    }


    const searchCandHeader = () => {
        return (
            <div className='font-fam-for-all flex flex-column gap-3'>

                <p className='srch-cand-header table-cand'>Vendors  <Tag className='badge-cand' value={filteredData.length > 0 ? filterData.length : products.length}></Tag></p>



                <div className='card-devider'></div>

                <div className="srch-cand-card font-fam-for-all " >
                    {/* <Checkbox className='mb-1'
                        checked={selectedData.length === products.length}
                        onChange={handleChecked}
                    ></Checkbox> */}

                    {/* <div style={{ width: '400px' }}>

                        <button style={{ backgroundColor: '#ffff' }} className='table-cand'> <i className='pi pi-envelope ml-3'></i> Bulk Email</button>

                        <button style={{ backgroundColor: '#ffff' }} className='table-cand' onClick={() => setVisible1(true)}> <i className='pi pi-paperclip ml-3'></i> Attach Profiles</button>
                    </div> */}

                </div>
            </div>
        )
    }

    const onRowSelect = (rowData) => {
        setData(rowData.data)

        console.log(rowData)

    }

    console.log(data)

    const sidebarHeader = () => (
        <>
            <div style={{ backgroundColor: '#002592', width: 'contentFit', height: '40px', position: 'sticky' }} className="sidebar-head">
                <Button className="pi pi-times sidebar-close-btn" onClick={() => setFilters(false)}></Button>
            </div>
        </>
    )

    const items = Array.from({ length: 5 }, (v, i) => i);


    console.log(fback)

    useEffect(() => {
        if (fback.length > 0) {
            const temp = fback.filter((val) => val.interviewLevelId.preference === 1)
            if (temp.length > 0) {

                setCandidateFback(temp[0])
                setFeedbackData(temp)

            }
        }
    }, [fback])




    console.log(data)




    return (
        <>

            <div className='grid webapp-content-container'>


                <div className='col-12 '>

                    <div className='card search-cand-card2'>

                        <div className='col-12 grid font-fam-for-all py-3 px-3'>
                            <div className='col-12 col-sm-12- col-md-12 col-lg-12 col-xl-7' style={{ paddingLeft: '20px' }}>
                                <p className='search-cand-header mb-0'>Vendors</p>
                                <p className='search-cand-sub-header'>Manage all your vendors here.</p>
                            </div>
                            <div className='col-12 col-sm-12- col-md-12 col-lg-12 col-xl-5'>
                                <div className="flex align-items-center justify-content-end gap-2 mt-3">
                                    {/* <Button className="  vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3" icon="pi pi-download" label='Bulk Upload' onClick={(e) => setVisible(true)} ></Button> */}
                                    {hasPermission("AllowVendorsWithUpdate") && <Button className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Add new vendor' icon="pi pi-plus" onClick={() => setVisible(true)} ></Button>}
                                </div>


                            </div>
                        </div>

                        {/* <div className="col-12 " style={{ padding: '0px', height: '60px' }}>
                    <p className='panel-headers font-fam-for-all datatable-sm d-flex align-items-center justify-content-center'>Search Candidate</p>
                </div> */}

                        <div className='card-devider'>

                        </div>
                        <div className='col-12' style={{ height: 'fit-content', padding: "20px 20px 0px 30px" }}>
                            <div className='col-12 grid'>
                                <div className="flex align-items-center justify-content-start gap-3">
                                    <span className="p-input-icon-left" >
                                        <i className="pi pi-search" />
                                        <InputText placeholder="Vendor Name" value={globalFilterValue} onChange={onGlobalFilterChange} style={{ borderRadius: "8px", width: "352px" }} />
                                    </span>
                                    {/* <span>
                                        <Dropdown placeholder='Filtered By Domicile' value={filteredStateValue} onChange={(e) => setFilteredStateValue(e.value)} optionValue='id' optionLabel='stateFullDesc' options={allStates} style={{ borderRadius: "8px" }} />
                                    </span>
                                    <span>
                                        <Dropdown placeholder='Filtered By Buisness Structure' value={entityType} optionLabel='value' onChange={(e) => setEntityType(e.value)} options={entityTypes} style={{ borderRadius: "8px" }} />
                                    </span>
                                    <span>
                                        <Button
                                            type="button"
                                            // icon="pi pi-plus"
                                            style={{ fontSize: "14px" }}
                                            className=" vstate-button font-fam-for-all text-center"
                                            onClick={handleFilter}
                                        >Filter</Button>
                                    </span>
                                    <span>
                                        <Button
                                            type="button"
                                            // icon="pi pi-plus"
                                            style={{ fontSize: "14px" }}
                                            onClick={handleClearFilter}
                                            className="  font-fam-for-all text-center vstate-reverse-button"
                                        >Clear Filter</Button>
                                    </span> */}
                                </div>


                            </div>







                        </div>



                        <div style={{ padding: '30px' }}>

                            <div className='grid' >

                                <div className='col-12 col-sm-12- col-md-12 col-lg-12 col-xl-3 pt-0 '>
                                    <div className="">
                                        <div className='vstate-card'>

                                            {showSkeleton ? <DataTable header={searchCandHeader} value={products} rows={25} rowsPerPageOptions={[25, 50, 75, 100]} inline paginator emptyMessage='No Vendor Found' filters={filters} className='master-datatable'
                                                globalFilterFields={['companyName', 'lastName', 'mobileNo', 'emailId']} selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)}
                                                onRowSelect={onRowSelect} metaKeySelection={false}>
                                                <Column body={itemTemplate} className='datatable-body-icon'></Column>
                                            </DataTable> : <DataTable header={searchCandHeader} value={items} rows={25} rowsPerPageOptions={[25, 50, 75, 100]} inline paginator emptyMessage='No Candidate Found'
                                                selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)}
                                                onRowSelect={onRowSelect} metaKeySelection={false}>
                                                <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            </DataTable>}
                                        </div>
                                    </div>
                                </div>
                                {
                                    data !== null ? <div className='col-12 col-sm-12- col-md-12 col-lg-12 col-xl-9 pt-0' >
                                        <div className='vstate-card mb-4 company-card-content-header-card'>
                                            <div className='company-card-content-header-card-container'>
                                                <div className='company-card-content-header-card-main'>
                                                    <div className='company-card-content-header-card-main-x'>
                                                        <div className='company-card-content-header-card-main-text'>
                                                            <div className='srch-cand-text2'>
                                                                Welcome to your vendor’s profile
                                                            </div>
                                                            <div className='srch-cand-text'>
                                                                View vendor details and track vendor orders here.
                                                            </div>

                                                        </div>
                                                        {/* <div className='devider-y'>

                                                        </div> */}
                                                    </div>
                                                    {/* <div className='company-card-content-header-card-main-x'>
                                                        <div className='company-card-content-header-card-main-text'>
                                                            <div className='srch-cand-text2'>
                                                                Update missing or outdated information.
                                                            </div>
                                                            <div className='srch-cand-text'>
                                                                If we need more details for your filings, we'll notify you promptly to ensure you provide any missing information.                                                                </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='vstate-card col-12'>


                                            <div className='col-12 srch-cand-card'>
                                                <Avatar className='ml-3 custom-avatar' label={data.avatarImgPath ? '' : commonGetInitials(`${data.companyName}`)} image={data.avatarImgPath ? data.avatarImgPath : ''} size="large" shape="circle" />

                                                <div className='col-11 grid pb-0' >
                                                    <div className='col-12 col-sm-12- col-md-12 col-lg-12 col-xl-8'>

                                                        <p className=' srch-cand-text2' >{`${data.companyName}`}</p>
                                                        <p className=' srch-cand-text' > {`Limited Liability Company`}</p>
                                                    </div>
                                                    <div className='col-12 col-sm-12- col-md-12 col-lg-12 col-xl-4 d-flex justify-content-end align-items-center'>

                                                        {/* <Button className='font-fam-for-all vstate-reverse-button'>Manage Permission</Button> */}
                                                        {/* <i className='pi pi-plus'></i> */}
                                                    </div>
                                                </div>




                                            </div>

                                            <div className='card-devider'>

                                            </div>



                                            <TabView>


                                                <TabPanel header="Details">
                                                    <div className='col-12 ' style={{ backgroundColor: '#fbfcfa', padding: '15px' }}>


                                                        <div className="font-fam-for-all">

                                                            <div>



                                                                {
                                                                    isEditing2 ? <div className='card my-3 slide-in'>
                                                                        <div className='p-4'>
                                                                            <h2 className='srch-cand-text2'>Contact Info</h2>

                                                                        </div>
                                                                        <div className='grid px-4 justify-content-start'>
                                                                            <div className='col-8'>
                                                                                <div className=" justify-content-center dialog-form-field-space grid  mt-2">
                                                                                    <div className="field col-12 py-0">



                                                                                        <span className=" ">
                                                                                            <div class="relative">
                                                                                                <InputText
                                                                                                    // className='dialog-form-input-field'
                                                                                                    id="floating_outlined71"
                                                                                                    className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                                                    style={{ width: '100%', height: '40px' }}
                                                                                                    placeholder=''
                                                                                                    maxLength={50} value={ingredient} onChange={handleCity} name='cityName'
                                                                                                />


                                                                                                <label
                                                                                                    htmlFor="floating_outlined71"
                                                                                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                                >
                                                                                                    Company Email <span className="form-field-mandatory">*</span>
                                                                                                </label>
                                                                                            </div>
                                                                                            {emailErr !== "" ? <p className='error-msg font-fam-for-all'>{emailErr}</p> : ""}
                                                                                        </span>

                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>


                                                                        <div className='grid px-4 justify-content-start'>

                                                                            <div className='col-8'>
                                                                                <div className="justify-content-center dialog-form-field-space">
                                                                                    <div className="field py-0">
                                                                                        <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}>
                                                                                            {/* <Dropdown
                                                                                                value={selectedCountry}
                                                                                                options={countries}
                                                                                                onChange={onCountryChange}
                                                                                                optionLabel="code"
                                                                                                valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}

                                                                                                style={{ width: '160px', borderRadius: '6px 0px 0px 6px' }}
                                                                                                className="p-dropdown font-fam-for-all"
                                                                                            /> */}

                                                                                            <span className="" style={{ width: "100%" }}>
                                                                                                <div className="relative custom-input-mask">
                                                                                                    {/* <InputText
                                                                                                        id="floating_outlined72"
                                                                                                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                                                        style={{ height: '40px', borderRadius: '0px 6px 6px 0px', width: "100%" }}
                                                                                                        maxLength={50}
                                                                                                        value={phone}

                                                                                                        onChange={handlePhone}
                                                                                                        autoClear={false}
                                                                                                        placeholder=''
                                                                                                        name="cityName"
                                                                                                    /> */}
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        id="floating_outlined218"
                                                                                                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                                                                                                        style={{
                                                                                                            height: "40px",
                                                                                                            borderRadius: "0px 6px 6px 0px",
                                                                                                            width: "100%",
                                                                                                        }}
                                                                                                        maxLength={50}
                                                                                                        value={phone}
                                                                                                        onChange={handlePhone}
                                                                                                        autoClear={false}
                                                                                                        placeholder=""
                                                                                                        name="cityName"
                                                                                                    />
                                                                                                    <label
                                                                                                        htmlFor="floating_outlined218"
                                                                                                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                                    >
                                                                                                        Company  Phone <span className="form-field-mandatory">*</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                {phoneErr !== "" ? <p className='error-msg font-fam-for-all'>{phoneErr}</p> : ""}
                                                                                            </span>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>


                                                                        <div className='grid px-4   justify-content-center'>



                                                                            <div className='col-8'>
                                                                                <div className=" justify-content-center dialog-form-field-space grid  mt-2">
                                                                                    <div className="field col-12 py-0">



                                                                                        <span className=" ">
                                                                                            <div class="relative">
                                                                                                <InputText
                                                                                                    // className='dialog-form-input-field'
                                                                                                    id="floating_outlined81"
                                                                                                    className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                                                    style={{ width: '100%', height: '40px' }}
                                                                                                    placeholder=''
                                                                                                    maxLength={50} value={website} onChange={handleWebsite} name='cityName'
                                                                                                />


                                                                                                <label
                                                                                                    htmlFor="floating_outlined81"
                                                                                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                                >
                                                                                                    Website
                                                                                                </label>
                                                                                            </div>
                                                                                            {emailErr !== "" ? <p className='error-msg font-fam-for-all'>{emailErr}</p> : ""}
                                                                                        </span>

                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <div className='col-4'>

                                                                            </div>

                                                                        </div>

                                                                        <div className="mb-3">
                                                                            {isEditing || isEditing1 || isEditing2 ? (
                                                                                <div className='flex justify-content-end pl-3 dialog-form-md-group-Btn' >
                                                                                    <Button className="mt-2  font-fam-for-all text-center text-lg bg-primary-reverse mr-2" style={{ height: "40px" }} onClick={() => {
                                                                                        setIsEditing(false)
                                                                                        setIsEditing1(false)
                                                                                        setIsEditing2(false)
                                                                                    }}  >Cancel</Button>
                                                                                    <Button className="mt-2  font-fam-for-all text-center text-lg mr-3" style={{ height: "40px" }} onClick={handleSave2} >Submit</Button>
                                                                                </div>
                                                                            ) : (
                                                                                <div></div>
                                                                            )}
                                                                        </div>


                                                                    </div> : <>
                                                                        <div>
                                                                            {

                                                                                <div className='card my-3 hover-card slide-in'>


                                                                                    <div className=' flex px-4 pt-4 pb-0' style={{ justifyContent: 'space-between' }}>
                                                                                        <h2 className='srch-cand-text2'>Contact Info</h2>
                                                                                        <div >
                                                                                            {/* {isEditing2 ? (
                                                                                                <div>

                                                                                                </div>
                                                                                            ) : (
                                                                                                <div className='flex justify-content-end dialog-form-md-group-Btn'>
                                                                                                    <Button onClick={() => handleEdit2(data)} className="edit-button1" icon="pi pi-pencil" style={{ border: 'none', height: "40px" }}></Button>

                                                                                                </div>
                                                                                            )} */}
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="grid px-4 py-2">


                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Company Name
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Contact Name
                                                                                        </div>


                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Company Email
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Company Phone
                                                                                        </div>


                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                            {data.companyName}
                                                                                        </div>
                                                                                        <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                                            {data.contactName}
                                                                                        </div>

                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                            {data.email}
                                                                                        </div>
                                                                                        <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                                            {data.phone}
                                                                                        </div>




                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Website
                                                                                        </div>
                                                                                        <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>

                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>

                                                                                        </div>
                                                                                        <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>

                                                                                        </div>

                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                            {data.websiteUrl}
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>

                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>

                                                                                        </div>
                                                                                        <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>

                                                                                        </div>



                                                                                    </div>




                                                                                </div>

                                                                            }

                                                                        </div>
                                                                    </>
                                                                }







                                                            </div>




                                                        </div>


                                                    </div>
                                                </TabPanel>




                                                <TabPanel header="Orders">
                                                    <div className='col-12 ' style={{ backgroundColor: '#fbfcfa', padding: '15px' }}>


                                                        <div className="font-fam-for-all">

                                                            <div className='card my-3 hover-card slide-in1'>


                                                                <div className='p-4'>
                                                                    <h2 className='srch-cand-text2'>Orders</h2>
                                                                    <div className='srch-cand-text'>
                                                                        View all your company's filing orders in one place. Track the status and details of each order to ensure timely and accurate processing    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <CompanyOrders data={data} />

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>





                                            </TabView>
                                        </div>



                                    </div> : showSkeleton ? <div className='col-8 pt-0 ' >
                                        <div className='card col-12' style={{ height: '255px' }}>
                                            <div className='grid justify-content-center align-items-center' style={{ height: '255px' }}>
                                                No vendor found
                                            </div>
                                        </div></div> : <div className=" card col-8 pt-0">
                                        <div className="border-round border-1 surface-border p-4 surface-card">
                                            <div className="flex mb-3">
                                                <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                                                <div>
                                                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                                                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                    <Skeleton height=".5rem"></Skeleton>
                                                </div>
                                            </div>
                                            <Skeleton width="100%" height="150px"></Skeleton>
                                            <div className="flex justify-content-between mt-3">
                                                <Skeleton width="4rem" height="2rem"></Skeleton>
                                                <Skeleton width="4rem" height="2rem"></Skeleton>
                                            </div>
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>
                    </div>


                    {visible && <AddVendor visible={visible} setVisible={setVisible} pageRefresh={pageRefresh} setPageRefresh={setPageRefresh} />}

                    {visible1 && <AddCompanyLocation companyId={data} visible={visible1} setVisible={setVisible1} pageRefresh={pageRefresh} setPageRefresh={setPageRefresh} />}


                    <Dialog header={handleFbackHeader} visible={visible6} onHide={() => {
                        setVisible6(false)

                    }}
                        className='dialog-box'
                    >
                        {/* <AddPreScreeningFeedback allFeedbackData={fback} feedback={candidateFback} lastFbackData={null} /> */}
                    </Dialog>




                    <Dialog visible={closeProgresbar} modal={false} onHide={CloseProgresbarheader} className='font-fam-for-all'>
                        <p className="m-0 p-3">
                            <div className='d-flex align-items-center'>
                                {/* <img src={SuccessImage} width='60px' height='60px' /> */}
                                {/* <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
                                <p className='font-fam-for-all successMessage-font'>Data Added Successfully</p>
                            </div>
                            <div className='flex justify-content-end dialog-form-md-group-Btn'>
                                <Button type='button' className="mt-2 form-Btn form-Btn-Label font-fam-for-all  text-lg  mr-2" onClick={CloseProgresbarheader} >Ok</Button>
                            </div>
                        </p>
                    </Dialog>


                    <Dialog visible={closeProgresbar1} modal={false} onHide={CloseProgresbarheader1} className='font-fam-for-all'>
                        <p className="m-0 p-3">
                            <div className='d-flex align-items-center'>
                                {/* <img src={ErroImage} width='60px' height='60px' /> */}
                                {/* <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
                                <p className='font-fam-for-all successMessage-font'>Data Added Failed</p>
                            </div>
                            <div className='flex justify-content-end dialog-form-md-group-Btn'>
                                <Button type='button' className="mt-2 form-Btn form-Btn-Label font-fam-for-all  text-lg  mr-2" onClick={CloseProgresbarheader1} >Ok</Button>
                            </div>
                        </p>
                    </Dialog>

                    {/* <EditCandidateDetails visible={visible5} setVisible={setVisible5} setCloseProgresbar={setCloseProgresbar} setCloseProgresbar1={setCloseProgresbar1} /> */}
                    {/* <AddCandidate visible={visible4} setVisible={setVisible4} /> */}

                </div>
            </div >
        </>
    )
}
