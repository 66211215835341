import React, { useState, useRef } from 'react';

const OTPInputBox = ({ length, onChange, clearOtp }) => {
  const [otp, setOtp] = useState(Array(length).fill(''));
  const inputRefs = useRef([]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    const newOtp = [...otp];

    if (value === '' || /^[0-9]$/.test(value)) {
      newOtp[index] = value;
      setOtp(newOtp);
      onChange(newOtp.join(''));

      if (value !== '' && index < length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (otp[index] === '') {
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      } else {
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
        onChange(newOtp.join(''));
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').trim().slice(0, length); 
    if (/^\d+$/.test(pasteData)) {
      const newOtp = pasteData.split('');
      while (newOtp.length < length) {
        newOtp.push('');
      }
      setOtp(newOtp);
      onChange(newOtp.join(''));

      inputRefs.current[Math.min(newOtp.length - 1, length - 1)].focus();
    }
  };

  React.useEffect(() => {
    if (clearOtp) {
      setOtp(Array(length).fill(''));
      inputRefs.current[0].focus(); 
      onChange(''); 
    }
  }, [clearOtp, length]);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      {Array.from({ length }).map((_, i) => (
        <input
          key={i}
          type="text"
          maxLength="1"
          value={otp[i]}
          onChange={(e) => handleChange(e, i)}
          onKeyDown={(e) => handleKeyDown(e, i)}
          onPaste={handlePaste} // Handle paste event
          ref={(el) => (inputRefs.current[i] = el)}
          style={{
            width: '3rem',
            height: '3rem',
            margin: '0.5rem',
            textAlign: 'center',
            fontSize: '1.5rem',
            borderRadius: '4px',
            border: '1px solid #ccc',
          }}
        />
      ))}
    </div>
  );
};

export default OTPInputBox;
