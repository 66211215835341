import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import React, { useContext, useEffect, useRef, useState } from 'react'
import authContext from '../../common/authContext'
import { Skeleton } from 'primereact/skeleton'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'
import RequestCompanyOwnership from './AddCompanyComponets/RequestCompanyOwnership'
import companyMasterService from '../../services/companyMasterService'
import { formatISODate } from '../../utils/dateUtils'
import MasterServices from '../../services/coreServices'
import { Toast } from 'primereact/toast'
import { Dialog } from 'primereact/dialog'
import { showToast } from '../../utils/toastUtils'
import useTableData from '../../redux/Reducers/useTableData'

const TransferOwnership = () => {
    const { globalCompany, clearData, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh, redirectedData, setRedirectedData } = useContext(authContext);

    const { data: fetchedStatusData, isLoading: statusLoading, isError: isStatusError, error: statusError, refetch: reftechStatusData } = useTableData("statusMaster");


    const [superAdminId, setSuperAdminId] = useState(null)

    const [superAdminMainId, setSuperAdminMainId] = useState(null)

    const [selectedProduct, setSelectedProduct] = useState(null)

    const [products, setProducts] = useState([])

    const [data, setData] = useState(null)

    const [visible, setVisible] = useState(false)

    const user = JSON.parse(localStorage.getItem('user'));

    const [allStatus, setAllStatus] = useState([])

    const [closeProgresbar5, setCloseProgresbar5] = useState(false)

    const [rowId, setRowId] = useState(null)

    const toast = useRef(null);

    const [statusId, setStatusId] = useState(null);

    const [filteredInput, setFilteredInput] = useState("");

    const [tempProduct, setTempProduct] = useState([])





    useEffect(() => {
        if (fetchedStatusData) {
            setAllStatus(fetchedStatusData)
        }
    }, [fetchedStatusData])


    const getStatus = (id) => {
        if (allStatus.length > 0) {
            const temp = allStatus.find((val) => val.id === id)
            if (temp) {
                return temp.statusName;
            }
            else {
                return null;
            }

        }
        else {
            return null;
        }
    }


    const onRowSelect = (rowData) => {
        setData(rowData.data)
    }

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: { value: null, matchMode: FilterMatchMode.IN },
        companyName: { value: null, matchMode: FilterMatchMode.EQUALS },
        lastName: { value: null, matchMode: FilterMatchMode.EQUALS },
        mobileNo: { value: null, matchMode: FilterMatchMode.EQUALS },
        emailId: { value: null, matchMode: FilterMatchMode.EQUALS },

    });

    const [showSkeleton, setShowSkeleton] = useState(false)

    function checkIdsExist(arr, idsToCheck) {
        // Check if any of the idsToCheck are found in arr
        const foundIds = idsToCheck.filter(idToCheck => arr.some(item => item.id === idToCheck));
        return foundIds.length > 0 ? foundIds : null;
    }

    useEffect(() => {

        if (globalCompany !== null) {
            if (globalCompany.roles.length > 0) {
                // Check for both 1 and 4
                const ids = checkIdsExist(globalCompany.roles, [1, 4]);
                const superId = checkIdsExist(globalCompany.roles, [1]);
                console.log(ids);
                if (ids) {
                    setSuperAdminId(ids); // Set the array of matched IDs
                }
                if (!ids || ids.length === 0) {

                    setSuperAdminId(null)
                }
                if (superId) {
                    setSuperAdminMainId(superId)
                }
                else {
                    setSuperAdminMainId(null)
                }


                if (ids) {
                    companyMasterService.getAllCompanyTransfer()
                        .then((res) => {
                            console.log(res)
                            if (Array.isArray(res.data)) {
                                setProducts(res.data)
                                setShowSkeleton(true)
                            }
                            else {
                                setShowSkeleton(true)
                            }

                        })
                        .catch((err) => {
                            console.log(err)
                            setShowSkeleton(true)
                        })
                }
                else {
                    companyMasterService.getAllCompanyById(globalCompany?.companyDetails?.id)
                        .then((response) => {
                            console.log(response)
                            if (response.data !== "") {
                                const data = response.data
                                companyMasterService.getAllCompanyTransferByUserId(data?.adminContactId?.id)
                                    .then((res) => {
                                        console.log(res)
                                        if (Array.isArray(res.data)) {
                                            const filteredProduct = res.data.filter(
                                                (val) =>
                                                    val.statusId !== 25 ||
                                                    (val.statusId === 25 && val.requestedBy === data.adminContactId.id)
                                            );
                                            console.log(filteredProduct)
                                            if (filteredProduct && Array.isArray(filteredProduct)) {
                                                setProducts(filteredProduct)
                                                setTempProduct(filteredProduct)
                                            }
                                        }
                                        setShowSkeleton(true)
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                        setShowSkeleton(true)
                                    })
                            }

                        })
                        .catch((err) => {
                            console.log(err)
                            setShowSkeleton(true)
                        })
                }
            }


        }

    }, [globalCompany, isTabRefresh]);


    const orderdateBody = (rowdata) => (
        <div className='webapp-sm-size'>
            {rowdata.requestDate ? formatISODate(rowdata.requestDate) : ""}
        </div>
    )

    const items = Array.from({ length: 5 }, (v, i) => i);

    const getBadgeTextColor = (status) => {
        switch (status) {
            case "ORDER CREATED":
                return "#027A48";
            case "ORDER SUBMITTED":
                return "#027A48";
            case "ORDER IN PROGRESS":
                return "#027A48";
            case "ORDER COMPLETED":
                return "#027A48";
            case "ORDER RESUBMITTED":
                return "#027A48";
            case "PAY LATER REQUEST APPROVED":
                return "#027A48";
            case "PAY LATER REQUEST REJECTED":
                return "#344054";
            case "DRAFT":
                return "#344054";
            default:
                return "#B54708";
        }
    };

    const statusBody = (rowdata) => {
        return (
            <div style={{ color: '#027A48' }}>{getStatus(rowdata.statusId)}</div>
        )
    }


    const requestByBody = (rowdata) => {
        console.log(rowdata)
        return <div className='webapp-sm-size'>
            {`${rowdata?.transferFrom?.adminContactId?.firstName} ${rowdata?.transferFrom?.adminContactId?.lastName}`}
        </div>
    }

    const updateStatusCancel = (id) => {
        setIsTabRefresh(true)
        if (superAdminId) {
            companyMasterService.TransferCancelRequest(id)
                .then((res) => {
                    console.log(res)
                    setIsTabRefresh(false)
                    setCloseProgresbar5(false)
                    // if (toast.current !== undefined) {
                    //     toast.current.show({
                    //         severity: 'success', summary: 'company transfer cancelled', life: 3000

                    //     })
                    // }
                    showToast("success", `company transfer cancelled`)

                })
                .catch((err) => {
                    console.log(err)
                    setIsTabRefresh(false)
                })

        } else {
            companyMasterService.updateTranferWithCancel(id)
                .then((res) => {
                    console.log(res)
                    setIsTabRefresh(false)
                    setCloseProgresbar5(false)
                    // if (toast.current !== undefined) {
                    //     toast.current.show({
                    //         severity: 'success', summary: 'company transfer cancelled', life: 3000

                    //     })
                    // }
                    showToast("success", `company transfer cancelled`)

                })
                .catch((err) => {
                    console.log(err)
                    setIsTabRefresh(false)
                })

        }

    }

    const updateStatusApproved = (id) => {
        setIsTabRefresh(true)
        companyMasterService.updateTranferWithApproved(id)
            .then((res) => {
                console.log(res)
                setIsTabRefresh(false)
                setCloseProgresbar5(false)
                // if (toast.current !== undefined) {
                //     toast.current.show({
                //         severity: 'success', summary: 'company transfer approved', life: 3000

                //     })
                // }
                showToast("success", `company transfer approved`)

            })
            .catch((err) => {
                console.log(err)
                setIsTabRefresh(false)
            })
    }

    const updateStatusRejected = (id) => {
        setIsTabRefresh(true)
        companyMasterService.updateTranferWithRejected(id)
            .then((res) => {
                console.log(res)
                setIsTabRefresh(false)
                setCloseProgresbar5(false)
                // if (toast.current !== undefined) {
                //     toast.current.show({
                //         severity: 'success', summary: 'company transfer rejected', life: 3000

                //     })
                // }
                showToast("success", `company transfer rejected`)

            })
            .catch((err) => {
                console.log(err)
                setIsTabRefresh(false)
            })
    }

    const updateStatusCompleted = (id) => {
        setIsTabRefresh(true)
        companyMasterService.updateTranferWithCompleted(id)
            .then((res) => {
                console.log(res)
                setIsTabRefresh(false)
                setCloseProgresbar5(false)
                // if (toast.current !== undefined) {
                //     toast.current.show({
                //         severity: 'success', summary: 'company transfer completed', life: 3000

                //     })
                // }
                showToast("success", `company transfer completed`)

            })
            .catch((err) => {
                console.log(err)
                setIsTabRefresh(false)
            })
    }

    const statusDialogEvent = (id, statusId) => {
        setRowId(id)
        setStatusId(statusId)
        setCloseProgresbar5(true)
    }

    const handleConfirm = () => {
        if (statusId === "Migrate") {
            updateStatusCompleted(rowId)
        }
        if (statusId === "Approved") {
            updateStatusApproved(rowId)
        }
        if (statusId === "Rejected") {
            updateStatusRejected(rowId)
        }
        if (statusId === "Cancelled") {
            updateStatusCancel(rowId)
        }
    }



    const iconBodyTemplate = (rowdata) => {
        console.log(rowdata)
        const isRaisedByUser = rowdata.transferFrom?.adminContactId?.emailId === user?.username
        console.log(isRaisedByUser)
        switch (rowdata?.statusId) {
            case 21:
                if (superAdminId) {
                    return (
                        <span
                            className="status-icon approved text-gray-600"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Pending"
                        >
                            Approval Pending
                        </span>
                    );
                }
                else {
                    if (isRaisedByUser) {
                        return (
                            <button
                                className="pi pi-times image-btn-icon"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Cancel"
                                onClick={() => statusDialogEvent(rowdata?.id, "Cancelled")}
                            ></button>
                        );
                    } else {
                        return (
                            <>
                                <button
                                    className="pi pi-check image-btn-icon"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Accept"
                                    onClick={() => statusDialogEvent(rowdata?.id, "Approved")}
                                ></button>
                                <button
                                    className="pi pi-times image-btn-icon"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Reject"
                                    onClick={() => statusDialogEvent(rowdata?.id, "Rejected")}
                                ></button>
                            </>
                        );
                    }
                }


            case 22:
                if (superAdminId) {
                    return (
                        <>
                            <button
                                className="pi pi-check image-btn-icon"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Migrate Company"
                                onClick={() => statusDialogEvent(rowdata?.id, "Migrate")}
                            ></button>
                            <button
                                className="pi pi-times image-btn-icon"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Cancel"
                                onClick={() => statusDialogEvent(rowdata?.id, "Cancelled")}
                            ></button>
                        </>
                    );
                }
                else {
                    return (
                        <span
                            className="status-icon approved text-blue-600"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Approved"
                        >
                            Approved
                        </span>
                    );
                }


            case 23:
                return (
                    <span
                        className="status-icon rejected text-red-600"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Rejected"
                    >
                        Rejected
                    </span>
                );

            case 24:
                return (
                    <span
                        className="status-icon completed text-green-600"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Completed"
                    >
                        Completed
                    </span>
                );

            case 25:
                return (
                    <span
                        className="status-icon cancelled text-orange-600"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancelled"
                    >
                        Cancelled
                    </span>
                );

            default:
                return null;
        }
    };


    const childCompanyBody = (rowdata) => {
        console.log(rowdata);
        if (Array.isArray(rowdata.details)) {
            return rowdata.details
                .map((val) => val?.companyToTransfer?.companyName)
                .filter(Boolean)
                .join(', ');
        }
        return '';
    };


    const handleSearch = () => {
        if (filteredInput !== "") {
            const lowerCaseInput = filteredInput.toLowerCase();

            console.log(lowerCaseInput);

            console.log(products);

            const searchNestedObject = (obj) => {
                for (const key in obj) {
                    if (obj[key] !== null) {
                        if (typeof obj[key] === "object") {
                            if (searchNestedObject(obj[key])) {
                                return true;
                            }
                        } else if (
                            typeof obj[key] === "string" &&
                            obj[key].toLowerCase().includes(lowerCaseInput)
                        ) {
                            return true;
                        } else if (
                            typeof obj[key] === "number" &&
                            obj[key] == lowerCaseInput
                        ) {
                            return true;
                        }
                    }
                }
                return false;
            };

            const temp = tempProduct.filter((val) => searchNestedObject(val));

            console.log(temp);

            setProducts(temp);

        } else {
            setProducts(tempProduct);
        }
    };

    const confirmationMessage = (() => {
        if (statusId === "Approved") {
            return "Are you sure, you want to accept the ownership transfer request?";
        } else if (statusId === "Rejected") {
            return "Are you sure, you want to reject the ownership transfer request?";
        } else if (statusId === "Migrate") {
            return "Are you sure, you want to complete the company migration request?";
        } else if (statusId === "Cancelled" && superAdminId) {
            return "Are you sure, you want to cancel the company migration request?";
        } else if (statusId === "Cancelled") {
            return "Are you sure, you want to cancel your ownership transfer request?";
        }
        return null;
    })();

    return (

        <div className='grid webapp-content-container'>
            <Toast ref={toast} />


            <div className='col-12 '>

                <div className='card search-cand-card2'>

                    <div className='col-12 grid font-fam-for-all py-3 px-3'>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-7' style={{ paddingLeft: '20px' }}>
                            <p className='search-cand-header mb-0'>Transfer Ownership</p>
                            <p className='search-cand-sub-header mb-0'>Track all requests for transferring ownership between entities.</p>
                        </div>
                        <div className='col-12 col-sm-12 col-md-12 col-lg-5'>
                            <div className="flex align-items-center justify-content-end gap-2 mt-3">
                                {!(superAdminId && superAdminMainId) && <Button className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Request New Transfer' onClick={() => setVisible(true)} ></Button>}
                            </div>


                        </div>
                    </div>



                    <div className='card-devider'>

                    </div>
                    <div className='col-12' style={{ height: 'fit-content', padding: "20px 20px 0px 30px" }}>
                        <div className='col-12 grid'>
                            <div className="flex flex-wrap align-items-center justify-content-start gap-3" style={{ width: "1300px" }}>
                                <span className="p-input-icon-left" >
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search" className='vstate-input-field-fixed'
                                        value={filteredInput}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                handleSearch()
                                            }
                                        }}
                                        onChange={(e) => {
                                            setFilteredInput(e.target.value)
                                            if (e.target.value === "") {
                                                setProducts(tempProduct)

                                            }
                                        }}
                                        style={{ borderRadius: "8px", }} />
                                </span>

                                {/* <span>
                                    <Button
                                        type="button"
                                        // icon="pi pi-plus"
                                        style={{ fontSize: "14px" }}
                                        className=" vstate-button font-fam-for-all text-center"
                                    // onClick={handleFilter}
                                    >Filter</Button>
                                </span>
                                <span>
                                    <Button
                                        type="button"
                                        // icon="pi pi-plus"
                                        style={{ fontSize: "14px" }}
                                        // onClick={handleClearFilter}
                                        className="  font-fam-for-all text-center vstate-reverse-button"
                                    >Clear Filter</Button>
                                </span> */}
                            </div>
                        </div>
                    </div>


                    <div style={{ padding: '30px' }}>

                        <div className='grid' >
                            <div className='col-12 pt-0 '>
                                <div className="">
                                    <div className='vstate-card'>

                                    {showSkeleton ? (
  <DataTable 
      value={products} 
      rows={25} 
      rowsPerPageOptions={[5, 10, 25, 50]} 
      inline 
      paginator 
      emptyMessage="No Data Found" 
      filters={filters}
      globalFilterFields={['companyName', 'lastName', 'mobileNo', 'emailId']} 
      selectionMode="single" 
      selection={selectedProduct} 
      onSelectionChange={(e) => setSelectedProduct(e.value)}
      onRowSelect={onRowSelect} 
      metaKeySelection={false} 
      sortField="requestDate"    // Default sort field
      sortOrder={1}              // 1 for ascending, -1 for descending
  >
      <Column 
          field="requestDate" 
          header="Transfer Date" 
          body={orderdateBody} 
          sortable 
          className="font-fam-for-all datatable-lg-col webapp-sm-size" 
      />
      <Column 
          field="transferFrom.companyName" 
          header="Company Owner" 
          sortable 
          className="font-fam-for-all datatable-lg-col webapp-sm-size" 
      />
      <Column 
          field="transferTo.companyName" 
          header="New Owner" 
          sortable 
          className="font-fam-for-all datatable-lg-col webapp-sm-size" 
      />
      <Column 
          header="Transfered Company" 
          body={childCompanyBody} 
        //   sortable
          className="font-fam-for-all datatable-lg-col webapp-sm-size" 
      />
      <Column 
          header="Requested By" 
          body={requestByBody} 
        //   sortable
          className="font-fam-for-all datatable-lg-col webapp-sm-size" 
      />
      <Column 
          field="requestedStatus" 
          header="Requested Status" 
          body={statusBody} 
        //   sortable 
          className="font-fam-for-all datatable-lg-col webapp-sm-size" 
      />
      <Column 
          field="requestMessage" 
          header="Comments" 
          sortable 
          className="font-fam-for-all datatable-lg-col webapp-sm-size" 
      />
      <Column 
          header="Action" 
          body={iconBodyTemplate} 
          className="font-fam-for-all datatable-lg-col webapp-sm-size" 
      />
  </DataTable>
) : (
  <DataTable 
      value={items} 
      rows={5} 
      rowsPerPageOptions={[5, 10, 25, 50]} 
      inline 
      paginator 
      emptyMessage="No Candidate Found"
      selectionMode="single" 
      selection={selectedProduct} 
      onSelectionChange={(e) => setSelectedProduct(e.value)}
      onRowSelect={onRowSelect} 
      metaKeySelection={false}  
      sortField="transferDate" 
      sortOrder={1}
  >
      {/* Skeleton columns */}
      <Column body={<Skeleton />} className="datatable-body-icon" />
      <Column body={<Skeleton />} className="datatable-body-icon" />
      <Column body={<Skeleton />} className="datatable-body-icon" />
      <Column body={<Skeleton />} className="datatable-body-icon" />
      <Column body={<Skeleton />} className="datatable-body-icon" />
      <Column body={<Skeleton />} className="datatable-body-icon" />
      <Column body={<Skeleton />} className="datatable-body-icon" />
      <Column body={<Skeleton />} className="datatable-body-icon" />
      <Column body={<Skeleton />} className="datatable-body-icon" />
  </DataTable>
)}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RequestCompanyOwnership visible={visible} setVisible={setVisible} />
            {closeProgresbar5 && (
                <Dialog
                    className="payment-dialog rounded-lg"
                    visible={closeProgresbar5}
                    onHide={() => setCloseProgresbar5(false)}
                >
                    <section className="flex rounded-lg overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                        <div className="vstate-warning-icon-main">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M11.9998 7.99996V12M11.9998 16H12.0098M10.2898 2.85996L1.81978 17C1.64514 17.3024 1.55274 17.6453 1.55177 17.9945C1.55079 18.3437 1.64127 18.6871 1.8142 18.9905C1.98714 19.2939 2.2365 19.5467 2.53748 19.7238C2.83847 19.9009 3.18058 19.9961 3.52978 20H20.4698C20.819 19.9961 21.1611 19.9009 21.4621 19.7238C21.7631 19.5467 22.0124 19.2939 22.1854 18.9905C22.3583 18.6871 22.4488 18.3437 22.4478 17.9945C22.4468 17.6453 22.3544 17.3024 22.1798 17L13.7098 2.85996C13.5315 2.56607 13.2805 2.32308 12.981 2.15444C12.6814 1.98581 12.3435 1.89722 11.9998 1.89722C11.656 1.89722 11.3181 1.98581 11.0186 2.15444C10.7191 2.32308 10.468 2.56607 10.2898 2.85996Z"
                                    stroke="#DC6803"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                            <header className="flex flex-col w-full max-md:max-w-full">
                                <p className="text-lg mb-0 font-fam-for-all font-medium leading-loose text-red-900 max-md:max-w-full">
                                    Warning
                                </p>
                                <p className="mt-2 text-md leading-5 text-gray-700 w-[500px] font-fam-for-all text-wrap">
                                    {confirmationMessage}
                                </p>
                            </header>
                            <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                                <div className="flex justify-content-end gap-3 items-start self-stretch my-auto min-w-[240px]">
                                    <Button
                                        className="vstate-reverse-button"
                                        label="Cancel"
                                        onClick={() => {
                                            setCloseProgresbar5(false);
                                        }}
                                    />
                                    <Button
                                        className="vstate-button"
                                        label="Confirm"
                                        onClick={() => handleConfirm()}
                                    />

                                </div>
                            </div>
                        </div>
                    </section>
                </Dialog>
            )}
        </div>
    )
}

export default TransferOwnership