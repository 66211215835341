

const errorMessages = {
    emailRequired: "Email required",
    emailValidation: "Enter valid email",
    firstNameRequired: "First name required",
    lastNameRequired:"Last name required",
    firstNameValidation:"Enter valid first name",
    lastNameValidation:"Enter valid last name",
    phoneRequired:"Phone number required",
    phoneValidation:"Enter valid phone number",
    passwordRequired:"Password required",
    passwordValidation:"Enter valid pasword",
    confirmPasswordRequired:"Confirm password required",
    confirmPasswordValidation:"Enter valid confirm password",
    oldPasswordRequired:"Old password required",
    oldPasswordValidation:"Enter valid old password",
    passwordMatched:"Both password should be same",
    somethingWrong:"Something went wrong",
    userAlreadyPresent:"User is already registered",
    invalidCredential:"Invalid Credential",
    address1Required:"Address line 1 required",
    address2Required:"Address line 2 required",
    cityRequired:"City required",
    addressTypeRequired:"Address type required",
    companyRequired:"Company required",
    zipRequired:"Zip code required",
    companyNameValidation:"Enter valid company name",
    faxValidation:"Enter valid fax",
    statesRequired:"State required",
    countryRequired:"Country required"
};


export const globalMessages={
    passwordValidText1:"Must be alphanumeric with at least 8 characters and at least one Capital/small letter.",
    passwordValidText2:"It must include at least one special charater like $,#,_,%,!,@,&"
}

export default errorMessages;