import React, { useState } from 'react';
import PaymentModal from './PaymentModal';
import { Button } from 'primereact/button';


const PaymentComponents = ({ data,orderLineItem }) => {
    const [showPayment, setShowPayment] = useState(false);
    console.log(data)
    return (
        <div>
            <Button className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
                onClick={() => setShowPayment(true)} label='Make payment'>
            </Button>

            <PaymentModal
                show={showPayment}
                onHide={() => setShowPayment(false)}
                orderId={data?.orderId}
                amount={data?.orderAmount}
                data={data}
                orderLineItem={orderLineItem}
            />
        </div>
    );
};

export default PaymentComponents;