import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CommonStepCard = ({ stepNumber, title, description, icon }) => {
  return (
    <section className="main-section-class">
      <div className="common-card-main-step ">
        <FontAwesomeIcon icon={icon} className="font-iconnn" />
        <h2 className="card-title mt-4">
          <span className="step-color">Step {stepNumber}:</span> {title}
        </h2>
        <p className="normal-common-txt mt-4">{description}</p>
      </div>
    </section>
  );
};

export default CommonStepCard;
