import { Dialog } from 'primereact/dialog';
import React from 'react';
import DelaWareCertificateOfFormation from '../utils/StatePdf/Delaware';
import { Button } from 'primereact/button';
import ArizonaCorp from '../utils/StatePdf/ArizonaCorp';
import ConnecticutCorp from '../utils/StatePdf/ConnecticutCorp';
import MainCorp from '../utils/StatePdf/MainCorp';
import DelawareCorp from '../utils/StatePdf/DelawareCorp';
import ArizonaLLC from '../utils/StatePdf/ArizonaLLC';
import ConnecticutOne from '../utils/StatePdf/ConnecticutOne';
import MaineLLC from '../utils/StatePdf/MaineLLC';
 
const StatePdfComponent = ({ visible, setVisible, data, orderData }) => {
    
 
    const renderContent = () => {
        switch (`${data?.state?.stateFullDesc}-${data?.entityTypeId?.entityShortName}`) {
           
            // case "Delaware-LLC":
            //     return <DelaWareCertificateOfFormation orderData={orderData} />;
            // case "Arizona-LLC":
            //     return <ArizonaLLC orderData={orderData} />;
            // case "Connecticut-LLC":
            //     return <ConnecticutOne orderData={orderData} />;
            // case "Maine-LLC":
            //     return <MaineLLC orderData={orderData} />;
            // case "Arizona-CORP":
            //     return <ArizonaCorp orderData={orderData}/>
            // case "Connecticut-CORP":
            //     return <ConnecticutCorp orderData={orderData}/>
            // case "Maine-CORP":
            //     return <MainCorp orderData={orderData}/>
            // case "Delaware-CORP":
            //     return <DelawareCorp orderData={orderData}/>

            case "Delaware-LLC":
    return <DelaWareCertificateOfFormation orderData={orderData} />;
case "Arizona-LLC":
    return <ArizonaLLC orderData={orderData} />;
case "Connecticut-LLC":
    return <ConnecticutOne orderData={orderData} />;
case "Maine-LLC":
    return <MaineLLC orderData={orderData} />;
case "Nevada-LLC":
    return <DelaWareCertificateOfFormation orderData={orderData} />;
case "Pennsylvania-LLC":
    return <DelaWareCertificateOfFormation orderData={orderData} />;
case "Texas-LLC":
    return <DelaWareCertificateOfFormation orderData={orderData} />;
case "WestVirginia-LLC":
    return <DelaWareCertificateOfFormation orderData={orderData} />;
case "Wisconsin-LLC":
    return <DelaWareCertificateOfFormation orderData={orderData} />;
case "California-LLC":
    return <DelaWareCertificateOfFormation orderData={orderData} />;

case "Arizona-CORP":
    return <ArizonaCorp orderData={orderData} />;
case "Connecticut-CORP":
    return <ConnecticutCorp orderData={orderData} />;
case "Maine-CORP":
    return <MainCorp orderData={orderData} />;
case "Delaware-CORP":
    return <DelawareCorp orderData={orderData} />;
case "Nevada-CORP":
    return <DelawareCorp orderData={orderData} />;
case "Pennsylvania-CORP":
    return <DelawareCorp orderData={orderData} />;
case "Texas-CORP":
    return <DelawareCorp orderData={orderData} />;
case "WestVirginia-CORP":
    return <DelawareCorp orderData={orderData} />;
case "Wisconsin-CORP":
    return <DelawareCorp orderData={orderData} />;
case "California-CORP":
    return <DelawareCorp orderData={orderData} />;



            default:
                return <p className='mt-2 text-md leading-5 font-fam-for-all text-gray-700 w-[500px] text-wrap'>No Data Available</p>;
        }
    };
 
    return (
        <div>
            <Dialog visible={visible} onHide={() => setVisible(false)}>
                {renderContent()}
                <div className='flex w-full justify-content-center p-2'>
                    <Button
                        className="form-Btn-Label vstate-reverse-button font-fam-for-all text-center form-label mr-3"
                        label="Cancel"
                        onClick={() => setVisible(false)}
                    />
                </div>
            </Dialog>
        </div>
    );
};
 
export default StatePdfComponent;
 