import React from "react";
import { Modal } from "react-bootstrap";
import CommonTourBlue from "./../CommonTourComponent/CommonTourBlue";
import CommonTourWhite from "./../CommonTourComponent/CommonTourWhite";
import "./../CommonStyleTour/CommonStyleTour.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";
import CompanyTourModal from "./../CompanyTourModal";
import emp_client_s1 from "../../../Assets/Images/tour/admin_comp_s1.png";
import emp_client_s3 from "../../../Assets/Images/tour/emp_client_s3.png";
import emp_client_s4 from "../../../Assets/Images/tour/emp_client_s4.png";
import emp_client_s2 from "../../../Assets/Images/tour/emp_client_s2.png";
import emp_client_s5 from "../../../Assets/Images/tour/emp_client_s5.png";

const EmployeeClientModal = (props) => {
  const [modalsecondShow, setModalSecondShow] = React.useState(false);

  const handleStartClick = () => {
    setModalSecondShow(true);
    props.onHide();
  };
  return (
    <section>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="common-modal-class"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Clients</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 1 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    {" "}
                    Clients: Client Overview & Management{" "}
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to manage all clients within your
                    system.{" "}
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/emp_client_s1_8be3c26956.png"
                    src={emp_client_s1}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> View Client List: </span>{" "}
                      View a list of all clients within your organization.{" "}
                    </li>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Filtering & Sorting:{" "}
                      </span>{" "}
                      Filter the list of clients by access level and use the
                      search bar to quickly find specific users.{" "}
                    </li>
                    <li>
                      <span className="blkkk-textt"> Add New Client: </span>{" "}
                      Create new client profile by clicking "Add New Client" or
                      using the bulk upload feature.{" "}
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  {/* <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  /> */}
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 2 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Clients: Manage Login Details
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to manage a user's login
                    credentials.{" "}
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/emp_client_s2_bc66d18e18.png"
                    src={emp_client_s2}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> View Email Address: </span>{" "}
                      View the user's associated email address.
                    </li>

                    <li>
                      <span className="blkkk-textt"> Reset Password: </span>{" "}
                      Reset the user's password if necessary.
                    </li>

                    <li>
                      <span className="blkkk-textt"> Update Status: </span>{" "}
                      Activate or deactivate client whenever needed.{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt"> Block Client: </span> Block
                      or unblock client whenever needed.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  {/* <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  /> */}
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 3 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Clients: Manage Profile Information{" "}
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to view and edit a client’s profile
                    information.{" "}
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/emp_client_s3_8fd9d68001.png"
                    src={emp_client_s3}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        View Profile Details:{" "}
                      </span>{" "}
                      See the client’s full name, email address, phone number,
                      and other relevant information.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  {/* <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  /> */}
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 4 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Clients: Manage Address Details{" "}
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to view and edit a client’s address
                    information.{" "}
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/emp_client_s4_b96877cbd2.png"
                    src={emp_client_s4}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> View Address:</span> See
                      the client’s complete address, including street address,
                      city, state, country, and zip code.{" "}
                    </li>

                    <li>
                      <span className="blkkk-textt"> Edit Address:. </span>{" "}
                      Update the client’s address information as needed
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  {/* <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  /> */}
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            {/*  */}
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 5 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Clients: Manage Client Permissions{" "}
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to control the level of access a
                    client has within the platform.{" "}
                  </p>
                </div>
                <div>
                  <img
                    loading="lazy"
                    // src="https://strapi.redberyltest.in/uploads/emp_client_s5_239121a53d.png"
                    src={emp_client_s5}
                    className="tour-img"
                  />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        View Current Permissions:{" "}
                      </span>{" "}
                       See the client’s current access level (e.g., Administrator, User, etc.).
                    </li>

                    {/* <li>
                      <span className="blkkk-textt"> Modify Permissions: </span>{" "}
                      Change the client’s access level to grant or restrict
                      access to specific features or areas of the platform.
                    </li> */}
                  </ul>
                </div>
                <div className="mt-5">
                  {/* <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  /> */}
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Modal.Body>
      </Modal>
      <CompanyTourModal
        show={modalsecondShow}
        onHide={() => setModalSecondShow(false)}
      />
    </section>
  );
};

export default EmployeeClientModal;
