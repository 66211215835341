import { Avatar } from 'primereact/avatar'
import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useState } from 'react'

const RolesAndPermission = () => {

    const data = [{
        permissionName: "AllowBlockingLoginFunctionality",
        description: "hshshshsh"
    },
    {
        permissionName: "AllowBlockingRegistrationFunctionality",
        description: "hdhhdh"
    },
    {
        permissionName: "AllowCreateUser",
        description: "dhhdhdh"
    },
    {
        permissionName: "AllowExistingUserModification",
        description: "ndndjdj"
    }]

    const [selectedProducts, setSelectedProducts] = useState(null);

    const header=()=>{
        return(
            <>
            <div>

            </div>
            </>
        )
    }



    return (
        <div>
            <div className='roles-perm-header'>
                <div className='dashboard-data-header' style={{ marginBottom: "10px", marginTop: "10px" }}>
                    <div className='dashboard-data-sub-header' style={{ paddingLeft: "0px", paddingRight: "15px" }}>
                        <div className='sub-header-css1'>
                            <div className='header-content'>
                                <div className='text-header-container'>
                                    {/* <button onClick={() => history("/contact")}>Back</button> */}

                                    <div className='text-header1'>Users</div>
                                    <div className='text-header2'>Manage Your Users Here</div>
                                </div>
                                <div className='text-header-container1'>

                                    <div className='action-header-container'>
                                        <Button icon="pi pi-cog" className='header-button'></Button>

                                        <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '20px', marginTop: '10px' }}>
                                            <Badge value="2"></Badge>
                                        </i>
                                    </div>
                                    <div className='avatar-header-container'>
                                        <Avatar icon="pi pi-user" style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} shape="circle" />
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className='divider-x my-4'></div>
            <div>
                <DataTable value={data} editMode="row" dataKey="id" selectionMode={'checkbox'} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} className='datatable-sm  font-fam-for-all' header={header}

                    globalFilterFields={['name', 'cityName', 'id',]}   >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} className='permission-datatable'></Column>
                    
                    <Column field="permissionName" header="Permission Name" className='font-fam-for-all permission-datatable datatable-sm-col'
                        sortable ></Column>
                    <Column field="description" header="Description" className='font-fam-for-all permission-datatable datatable-sm-col'
                        sortable ></Column>


                    
                </DataTable>
            </div>
        </div>
    )
}

export default RolesAndPermission