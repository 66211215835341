import { Avatar } from '@mui/material';
import { Button } from 'primereact/button';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import MasterServices from '../../services/coreServices';
import ContactService from '../../services/ContactService';
import authContext from '../../common/authContext';
import { commonGetInitials } from '../../utils/getInitials';

const UploadImage = ({ setImageVisible, data }) => {
  const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, fileBase64, setFileBase64 } = useContext(authContext);

  const [visible, setVisible] = useState(true);
  const [visible1, setVisible1] = useState(true);
  const [useCamera, setUseCamera] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [username, setUserName] = useState("");
  const [avatarImage, setAvatarImage] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const webcamRef = useRef(null);



  useEffect(() => {
    if (data) {
      if (data.contactDetails !== undefined) {
        setUserName(`${data.contactDetails.firstName} ${data.contactDetails.lastName}`);
        if (fileBase64) {
          setAvatarImage(`data:image/jpeg;base64,${fileBase64}`);
        }
      }
    }
  }, [data, fileBase64]);

  console.log(avatarImage);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileData(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarImage(reader.result);
        setVisible1(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleUseCamera = () => {
    setUseCamera(true);
    setVisible(false);
  };

  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setFileData(dataURLtoBlob(imageSrc));
    setAvatarImage(imageSrc);
    setUseCamera(false);
    setVisible1(false);
  };

  const handleClick1 = () => {
    setVisible(false);
  };

  const handleCancelCamera = () => {
    setUseCamera(false);
    setVisible(true);
  };

  // When delete is clicked, show a confirmation popup
  const handleRemoveImage = () => {
    setShowDeleteConfirm(true);
  };

  // If user confirms deletion, we reset the image and file data.
  const confirmDelete = () => {
    // Reset local states
    setFileData(null);
    setAvatarImage(null);
    setFileBase64(null);
    setVisible1(true);
    setShowDeleteConfirm(false);
  
    // Immediately call the update API with a null avatarImgPath
    setIsLoading(true);
    const actualdata1 = {
      "id": data.contactDetails.id,
      "salutation": data.contactDetails.salutation,
      "jobTitle": "Java Developer",
      "contactSourceId": null,
      "recordTypeId": null,
      "avatarImgPath": null, 
      "phone": data.contactDetails.mobileNo,
      "fax": data.contactDetails.fax,
      "statusId": 1
    };
    ContactService.updateContact(actualdata1, data.contactDetails.id)
      .then((res) => {
        console.log(res.data);
        setImageVisible(false);
        setIsLoading(false);
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  

  const cancelDelete = () => {
    setShowDeleteConfirm(false);
  };

  const handleSave = () => {
    setIsLoading(true);

    // If fileData exists, we assume it's an upload action.
    if (fileData) {
      const formdata = new FormData();
      formdata.append("file", fileData);
      MasterServices.saveFile("AvatarImagePath", formdata)
        .then((res) => {
          console.log(res);
          if (data) {
            const actualdata1 = {
              "id": data.contactDetails.id,
              "salutation": data.contactDetails.salutation,
              "jobTitle": "Java Developer",
              "contactSourceId": null,
              "recordTypeId": null,
              "avatarImgPath": res.data,
              "phone": data.contactDetails.mobileNo,
              "fax": data.contactDetails.fax,
              "statusId": 1
            };
            ContactService.updateContact(actualdata1, data.contactDetails.id)
              .then((res) => {
                console.log(res.data);
                setImageVisible(false);
                setIsLoading(false);
                window.location.reload(false);
              })
              .catch((err) => {
                console.log(err);
                setIsLoading(false);
              });
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      if (data) {
        const actualdata1 = {
          "id": data.contactDetails.id,
          "salutation": data.contactDetails.salutation,
          "jobTitle": "Java Developer",
          "contactSourceId": null,
          "recordTypeId": null,
          "avatarImgPath": null, 
          "phone": data.contactDetails.mobileNo,
          "fax": data.contactDetails.fax,
          "statusId": 1
        };
        ContactService.updateContact(actualdata1, data.contactDetails.id)
          .then((res) => {
            console.log(res.data);
            // After update, ensure the frontend shows initials by clearing the image state.
            setAvatarImage(null);
            setImageVisible(false);
            setIsLoading(false);
            window.location.reload(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
        } 
    }
  };

  return (
    <>
      <div className='view-profile-container'>
        <div className='view-profile-header-main'>
          <div className='view-profile-header-content-main'>
            <div className='view-profile-header-content-text-main'>
              <p className='view-profile-header-content-text1 m-0'>Profile photo</p>
              <p className='view-profile-header-content-text2'>Update or change your profile picture here.</p>
            </div>
            <div className='view-profile-header-content-close-main'>
              <button onClick={() => setImageVisible(false)}><i className='pi pi-times'></i></button>
            </div>
          </div>
        </div>
        <div className='card-devider'></div>
        <div>
          {useCamera ? (
            <div className="webcam-wrapper" style={{ position: 'relative', display: 'inline-block' }}>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="custom-webcam"
              />
              <button
                className="pi pi-camera"
                onClick={handleCapture}
                style={{
                  position: 'absolute',
                  top: '90%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '2rem',
                  color: 'white',
                  opacity: 0.7,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  border: '2px solid white',
                  borderRadius: '50%',
                  padding: '1rem',
                  cursor: 'pointer',
                }}
              />
            </div>
          ) : (
            <Avatar
              alt="Profile Picture"
              src={avatarImage || ""}
              className='custom-avatar'
              sx={{ width: 124, height: 124 }}
            >
              {!avatarImage && commonGetInitials(username)}
            </Avatar>
          )}
        </div>
        <div className='view-profile-footer-container'>
          <div className='card-devider'></div>
          {visible ? (
            <div className='view-profile-footer-main'>
              <div
                className="flex items-center gap-2 cursor-pointer hover:text-red-500"
                onClick={handleClick1}
              >
                <div className="w-6 h-6">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z" stroke="#D0D5DD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <span className="text-gray-600 transition-colors">
                  Upload
                </span>
              </div>

              {/* {avatarImage && ( */}
      <div
        className="flex items-center gap-2 cursor-pointer hover:text-red-500"
        onClick={handleRemoveImage}
      >
        <div className="w-6 h-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className="w-full h-full"
          >
            <path
              d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M10 11V17M14 11V17"
              stroke="#D0D5DD"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <span className="text-gray-600 hover:text-red-500 transition-colors">
          Delete
        </span>
      </div>
    {/* )} */}

            </div>
          ) : (
            <div className='view-profile-footer-container1'>
              <div className='view-profile-footer-container1-main'>
                {visible1 ? (
                  <>
                    {!useCamera && (
                      <Button className="mt-2 vstate-reverse-button font-fam-for-all text-center mr-2" style={{ height: "40px" }} onClick={handleUseCamera}>
                        Use camera
                      </Button>
                    )}
                    {!useCamera && (
                      <Button className="mt-2 vstate-button font-fam-for-all text-center mr-3" style={{ height: "40px" }} onClick={handleClick}>
                        Upload photo
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Button className="mt-2 vstate-reverse-button font-fam-for-all text-center mr-2" style={{ height: "40px" }} onClick={() => setImageVisible(false)}>
                      Cancel
                    </Button>
                    <Button className="mt-2 vstate-button font-fam-for-all text-center mr-3" style={{ height: "40px" }} onClick={handleSave}>
                      Save Photo
                    </Button>
                  </>
                )}
              </div>
            </div>
          )}

          {showDeleteConfirm && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-[#fff] p-6 rounded-2xl shadow-lg text-center">
                <h2 className="text-lg font-semibold mb-4">Are you sure?</h2>
                <p className="text-gray-600 mb-4">Do you really want to delete this photo? This action cannot be undone.</p>
                <div className="flex justify-center gap-4">
                  <button
                    className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
                    onClick={cancelDelete}
                  >
                    Cancel
                  </button>
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                    onClick={confirmDelete}
                  >
                    Yes, Delete
                  </button>
                </div>
              </div>
            </div>
          )}

        </div>
        <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleFileChange}
        />
      </div>
    </>
  );
};

export default UploadImage;
