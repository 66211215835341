import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import "./ProfileModalComponent.css";
import CommonTourWhite from "../TourGuideComponent/CommonTourComponent/CommonTourWhite";
import CommonTourBlue from "../TourGuideComponent/CommonTourComponent/CommonTourBlue";
import ContactService from "../../services/ContactService";

const options = [
  { value: "Mr ", label: "Mr" },
  { value: "Mrs.", label: "Mrs" },
  { value: "Ms", label: "Ms" },
];

const stateOptions = [
  { value: "NY", label: "New York" },
  { value: "CA", label: "California" },
  { value: "TX", label: "Texas" },
];

const user = JSON.parse(localStorage.getItem("user"));

const ProfileModalComponent = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedDomesticState, setSelectedDomesticState] = useState(null);
  const [selectedServiceState, setSelectedServiceState] = useState(null);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");

  useEffect(() => {
    if (user?.id) {
      ContactService.getAllContactByUserId(user?.id).then((res) => {
        if (res.data) {
          const contact = res.data?.contactDetails;

          const company = res.data?.companyDetails[0]?.companyName;
          console.log(company, "companydetails");
          if (contact) {
            setUserName(`${contact.firstName} ${contact.lastName}`);
            setEmail(contact.emailId);
            setPhoneNumber(contact.phoneNumber);
          }

          // if (company) {
          //   setCompanyName(company)
          // }
        }
      });
    }
  }, []);

  return (
    <div>
      {" "}
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="profile-modal-compnent"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Complete your profile
            <p className="header-subititle">
              Enter below essential details to complete your profile. This will
              help you to avail all services seamlessly.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div>
              <h5 className="section-title ms-2">Profile Info</h5>
              <Col lg={3} md={3} sm={6} xs={12}>
                <Form.Group controlId="salutationSelect">
                  <Form.Label>Salutation</Form.Label>
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                  />
                </Form.Group>
              </Col>
              <Row>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group controlId="firstNameInput">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={userName.split(" ")[0]}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group controlId="lastNameInput">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={userName.split(" ")[1] || ""}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group controlId="emailInput">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email" value={email} disabled />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group controlId="phoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="tel" value={phoneNumber} disabled />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group controlId="phoneNumber">
                    <Form.Label>Alt. Phone Number</Form.Label>
                    <Form.Control type="tel" />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group controlId="phoneNumber">
                    <Form.Label>Fax</Form.Label>
                    <Form.Control type="tel" />
                  </Form.Group>
                </Col>
              </Row>

              {/* <h5 className="section-title ms-2">Company Details</h5>
              <Row>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group controlId="companyName">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control type="text" value={companyName} disabled />
                  </Form.Group>
                </Col>
              </Row> */}
              <div className="grey-border mt-3"></div>
            </div>
            <div className="mt-4">
              <h5 className="section-title ms-2">Address Details</h5>
              <Row>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="addressLine1">
                    <Form.Label>
                      <span className="star-img">*</span> Address Line 1
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address Line 1"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="addressLine2">
                    <Form.Label>Address Line 2</Form.Label>
                    <Form.Control type="text" placeholder="Address Line 2" />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="city">
                    <Form.Label>
                      <span className="star-img">*</span> City
                    </Form.Label>
                    <Form.Control type="text" placeholder="City" required />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="state">
                    <Form.Label>
                      <span className="star-img">*</span> State
                    </Form.Label>
                    <Form.Control type="text" placeholder="State" required />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="country">
                    <Form.Label>
                      <span className="star-img">*</span> Country
                    </Form.Label>
                    <Form.Control type="text" placeholder="Country" required />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="zipCode">
                    <Form.Label>
                      <span className="star-img">*</span> Zip Code
                    </Form.Label>
                    <Form.Control type="text" placeholder="Zip Code" required />
                  </Form.Group>
                </Col>
              </Row>
              <div className="grey-border"></div>
            </div>
            <div className="mt-4">
              <h5 className="section-title ms-2">Company Details</h5>
              <Row>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="entityName">
                    <Form.Label>
                      <span className="star-img">*</span>Entity Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Entity Name"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="businessStructure">
                    <Form.Label>
                      <span className="star-img">*</span>Business Structure
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Business Structure"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="formationDate">
                    <Form.Label>
                      <span className="star-img">*</span>Formation Date
                    </Form.Label>
                    <Form.Control type="date" required />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="dissolutionDate">
                    <Form.Label>
                      <span className="star-img">*</span>Dissolution Date
                    </Form.Label>
                    <Form.Control type="date" required />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="serviceState">
                    <Form.Label>
                      <span className="star-img">*</span>Service State
                    </Form.Label>
                    <Select
                      defaultValue={selectedServiceState}
                      onChange={setSelectedServiceState}
                      options={stateOptions}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="domesticState">
                    <Form.Label>
                      <span className="star-img">*</span>Domestic State
                    </Form.Label>
                    <Select
                      defaultValue={selectedDomesticState}
                      onChange={setSelectedDomesticState}
                      options={stateOptions}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="companyEmail">
                    <Form.Label>
                      <span className="star-img">*</span>Company Email
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Company Email"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="phoneNumber">
                    <Form.Label>
                      <span className="star-img">*</span>Phone Number
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter Phone Number"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="fax">
                    <Form.Label>Fax</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Fax (if applicable)"
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="website">
                    <Form.Label>Website</Form.Label>
                    <Form.Control type="url" placeholder="Enter Website URL" />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="einNumber">
                    <Form.Label>EIN Number</Form.Label>
                    <Form.Control type="text" placeholder="Enter EIN Number" />
                  </Form.Group>
                </Col>
              </Row>
              <div className="grey-border"></div>
            </div>

            <div className="mt-4">
              <h5 className="section-title ms-2">Company Principle Address</h5>
              <Row>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="addressLine1">
                    <Form.Label>
                      <span className="star-img">*</span> Address Line 1
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address Line 1"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="addressLine2">
                    <Form.Label>Address Line 2</Form.Label>
                    <Form.Control type="text" placeholder="Address Line 2" />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="city">
                    <Form.Label>
                      <span className="star-img">*</span> City
                    </Form.Label>
                    <Form.Control type="text" placeholder="City" required />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="state">
                    <Form.Label>
                      <span className="star-img">*</span> State
                    </Form.Label>
                    <Form.Control type="text" placeholder="State" required />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="country">
                    <Form.Label>
                      <span className="star-img">*</span> Country
                    </Form.Label>
                    <Form.Control type="text" placeholder="Country" required />
                  </Form.Group>
                </Col>
                <Col lg={3} md={4} sm={6} xs={12}>
                  <Form.Group className="mb-3" controlId="zipCode">
                    <Form.Label>
                      <span className="star-img">*</span> Zip Code
                    </Form.Label>
                    <Form.Control type="text" placeholder="Zip Code" required />
                  </Form.Group>
                </Col>
              </Row>
              <div className="grey-border"></div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="main-class-tour ">
            <div className="d-flex justify-content-center">
              <CommonTourBlue label={"Submit"} />
              <CommonTourWhite label={"Skip"} onclick={props.onHide} />
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProfileModalComponent;
