import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import "../../CommonSection/CommonStyle/CommonStyle.css";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";

const UccFillingFaq = () => {
  const faqItems = [
    {
      key: "0",
      question: "What is a UCC filing?",
      answer:
        "A UCC filing is a legal form used to secure an interest in personal property for business transactions or loans.",
    },
    {
      key: "1",
      question: "When do I need a UCC amendment?",
      answer:
        "A UCC amendment is required when changes occur in the initial filing, such as updates to collateral or parties involved.",
    },
    {
      key: "2",
      question: "How does a UCC search work?",
      answer:
        "A UCC search checks existing filings to see if any liens are placed on assets you’re acquiring or using as collateral.",
    },
    {
      key: "3",
      question: "Why is UCC filing important for lenders?",
      answer:
        "UCC filings provide legal assurance that the lender has a secured interest in the borrower’s collateral.",
    },
    {
      key: "4",
      question: "How long does a UCC filing last?",
      answer:
        "UCC filings generally last for five years, but they can be renewed if the loan or agreement continues.",
    },
    {
      key: "5",
      question:
        "Can I file a UCC myself?",
      answer:
        "Yes, but professional services ensure the filing is done accurately, minimizing legal risk.",
    },
    {
        key: "6",
        question:
          "What is a lien search?",
        answer:
          "A lien search helps identify existing claims on assets, ensuring there are no conflicts before a transaction.",
      },
      {
        key: "7",
        question:
          "Can I update a UCC filing?",
        answer:
          "Yes, UCC filings can be amended as business transactions evolve or new collateral is added.",
      },
  ];

  // Split FAQs into two columns
  const firstColumnFaqs = faqItems.filter((_, index) => index % 2 === 0);
  const secondColumnFaqs = faqItems.filter((_, index) => index % 2 !== 0);

  return (
    <section className="main-section-class">
      <div className="common-faq">
        <Container>
          <div className="mb-5 mt-5">
            <CommonHeadingTitle heading={"FAQ"} />
          </div>
          <Accordion defaultActiveKey="0">
            <Row>
              <Col lg={6} md={6} sm={12}>
                {firstColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
              <Col lg={6} md={6} sm={12}>
                {secondColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
            </Row>
          </Accordion>
        </Container>
      </div>
    </section>
  );
};

export default UccFillingFaq;
