import React from "react";
import { Modal } from "react-bootstrap";
import CommonTourBlue from "./../CommonTourComponent/CommonTourBlue";
import CommonTourWhite from "./../CommonTourComponent/CommonTourWhite";
import "./../CommonStyleTour/CommonStyleTour.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";
import CompanyTourModal from "./../CompanyTourModal";
import EmployeeOrderTourModal from "./EmployeeOrderTourModal";

const EmployeeDashboardModal = (props) => {
  const [modalsecondShow, setModalSecondShow] = React.useState(false);

  const handleStartClick = () => {
    setModalSecondShow(true);
    props.onHide();
  };
  return (
    <section>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="common-modal-class"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Dashboard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 1 Of 4</span>
                </div>
                <div>
                  <h4 className="tour-heading"> Dashboard: Overview</h4>
                  <p className="tour-text-holder">
                  Get an overview of your client updates, ongoing orders, and client activities.
                  </p>
                </div>
                <div>
                  <img  loading="lazy" src="https://strapi.redberyltest.in/uploads/emp_dashboard_s1_565ae52e06.png" className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Filter Orders by Timeline: </span>{" "}
                      Easily filter order data by different timelines, such as 12, 6, 3, and 1 month views.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Track Key Order Metrics: </span>{" "}
                      Gain insights into total orders, completed orders, in-progress orders, and pending actions through concise KPIs.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Monitor Order Status Activities: </span>{" "}
                      Stay informed as orders move through statuses like draft, under review, created, submitted, pay later and more.                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 2 Of 4</span>
                </div>
                <div>
                  <h4 className="tour-heading">Dashboard: Active Clients</h4>
                  <p className="tour-text-holder">
                  Visualize order distribution across the USA.                  </p>
                </div>
                <div>
                  <img loading="lazy" src="https://strapi.redberyltest.in/uploads/emp_dashboard_s2_9edabc6721.png" className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                    Use a comprehensive map of the USA to track ongoing orders by selecting a state and reviewing order numbers and statuses at a glance.
                    </li>
                   
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 3 Of 4</span>
                </div>
                <div>
                  <h4 className="tour-heading">Dashboard: Client Invoice payments</h4>
                  <p className="tour-text-holder">
                  Manage client invoice payments.                  </p>
                </div>
                <div>
                  <img loading="lazy" src="https://strapi.redberyltest.in/uploads/emp_dashboard_s3_9c43fe9817.png" className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                    Access a detailed view of client invoice payments with columns like client name, order number, state, order type, and entity name. Take action on overdue invoices by sending payment reminders directly from the dashboard.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 4 Of 4</span>
                </div>
                <div>
                  <h4 className="tour-heading">Dashboard: Client Subscriptions</h4>
                  <p className="tour-text-holder">
                  Monitor client subscriptions                  </p>
                </div>
                <div>
                  <img loading="lazy" src="https://strapi.redberyltest.in/uploads/emp_dashboard_s4_88e4a8f22a.png" className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Get a comprehensive view for client subscriptions, featuring details like client company name, subscription name, entity name, subscription date, and order number. Manage notifications effortlessly with options to mute/unmute email updates and send reminders to clients for subscription-related activities
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
           
          </Swiper>
        </Modal.Body>
      </Modal>
      <EmployeeOrderTourModal
        show={modalsecondShow}
        onHide={() => setModalSecondShow(false)}
      />
    </section>
  );
};

export default EmployeeDashboardModal;
