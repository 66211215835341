import { Button } from "primereact/button";
import React, { useRef } from "react";

import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import { PDFDocument } from "pdf-lib";

function MainCorp({ orderData   }) {
  const componentRef = useRef();

  if (!orderData) {
    return <div>No order data available.</div>;
  }

  
  // const item = .items;  
  const strapiOrderFormJson = orderData.items[1].orderId.strapiOrderFormJson;
  const parsedData = JSON.parse(strapiOrderFormJson)
  const entityData = parsedData["Payload"].Entity_Formation;

  const formattedDate = new Date()
  const  date = formattedDate.toLocaleDateString();

//   const {
//     Name,
//     Principal_Address,
//     Registered_Agent,
//     Incorporator_Information,
//     Stock_Details
//   } = entityData;
  

//   const {
//     CD_Corporation_Name
//   } = Name;
  
//   const {
//     PA_Address_Line_1,
//     PA_Address_Line_2,
//     PA_City,
//     PA_State,
//     PA_Zip_Code
//   } = Principal_Address;
  
//   const {
//     RA_Name,
//     RA_Email_Address,
//     RA_Contact_No,
//     Address: {
//       RA_Address_Line_1,
//       RA_Address_Line_2,
//       RA_City,
//       RA_State,
//       RA_Zip_Code
//     }
//   } = Registered_Agent;
  
//   const {
//     Incorporator_Details: {
//       Inc_Name,
//       Inc_Email_Address,
//       Inc_Contact_No
//     },
//     Address: {
//       Inc_Address_Line_1,
//       Inc_Address_Line_2,
//       Inc_City,
//       Inc_Zip_Code,
//       Inc_State
//     }
//   } = Incorporator_Information;

//   const {
//     SI_Number_Of_Shares,
//     SI_Par_Value
//   } = Stock_Details;
  
//   const {
//     line1 = "",
//     line2 = "",
//     line3 = "",
//     llc_name = "",
//     cra_public_num = "",
//     clientName = "",
//     clerk_name = "",
//     non_clerk_name = "",
//     unknown_lable2 = "",
//     corp_name = "",
//     mailing_address = "",
//     auth_share_no = "",
//     class_name = "",
//     unknown_lable1 = "",
//     regAddress = "",
//     orderDate = "",
//     phy_location = "",
//     share = "",
//     unknown_lable3 = "",
//     unknown_lable4 = "",
//     unknown_lable5 = "",
//     unknown_lable6 = "",
//     name = "",
//     label = "",
//     fillings_fee = "",
//     name_of_contact_person = "",
//     daytime_telephone_number = "",
//     email_address = "",
//     name_of_attested_receipt = "",
//     firm_or_company = "",
//     city_state_zip = ""
// } = orderData;

  

const {
  Name = {},
  Principal_Address = {},
  Registered_Agent = {},
  Incorporator_Information = {},
  Stock_Details = {}
} = entityData || {};

const {
  CD_Corporation_Name = ""
} = Name;

const {
  PA_Address_Line_1 = "",
  PA_Address_Line_2 = "",
  PA_City = "",
  PA_State = "",
  PA_Zip_Code = ""
} = Principal_Address;

const {
  RA_Name = "",
  RA_Email_Address = "",
  RA_Contact_No = "",
  Address: {
    RA_Address_Line_1 = "",
    RA_Address_Line_2 = "",
    RA_City = "",
    RA_State = "",
    RA_Zip_Code = ""
  } = {}
} = Registered_Agent;

const {
  Incorporator_Details: {
    Inc_Name = "",
    Inc_Email_Address = "",
    Inc_Contact_No = ""
  } = {},
  Address: {
    Inc_Address_Line_1 = "",
    Inc_Address_Line_2 = "",
    Inc_City = "",
    Inc_Zip_Code = "",
    Inc_State = ""
  } = {}
} = Incorporator_Information;

const {
  SI_Number_Of_Shares = "",
  SI_Par_Value = ""
} = Stock_Details;

const {
  line1 = "",
  line2 = "",
  line3 = "",
  llc_name = "",
  cra_public_num = "",
  clientName = "",
  clerk_name = "",
  non_clerk_name = "",
  unknown_lable2 = "",
  corp_name = "",
  mailing_address = "",
  auth_share_no = "",
  class_name = "",
  unknown_lable1 = "",
  regAddress = "",
  orderDate = "",
  phy_location = "",
  share = "",
  unknown_lable3 = "",
  unknown_lable4 = "",
  unknown_lable5 = "",
  unknown_lable6 = "",
  name = "",
  label = "",
  fillings_fee = "",
  name_of_contact_person = "",
  daytime_telephone_number = "",
  email_address = "",
  name_of_attested_receipt = "",
  firm_or_company = "",
  city_state_zip = ""
} = orderData || {};




  // const billingAddress = [billingInfo.city, billingInfo.state, billingInfo.zip]
  //   .filter(Boolean)
  //   .join(", ");

  const downloadPDF = async () => {
    const input = document.getElementById("content-to-pdf"); // Main content container
    const sections = Array.from(input.querySelectorAll(".section")); // Get all sections with class 'section'
    const pageWidth = 595.28; // A4 width in points
    const pageHeight = 841.89; // A4 height in points
    const pdfDoc = await PDFDocument.create();
    const scale = 2; // Scale for better resolution
    const pageMargin = 20; // Page margin

    // Function to render content of a section to a page
    const renderSection = async (section, startY) => {
      // Capture the section's content using html2canvas
      const canvas = await html2canvas(section, {
        scale: scale,
        y: startY, // Start rendering from the current vertical position
        height: pageHeight * scale + 1000, // Limit height to the size of one page
      });

      const imgData = canvas.toDataURL("image/png");
      const img = await pdfDoc.embedPng(imgData);

      const imgWidth = img.width * (pageWidth / canvas.width) - 30; // Scale image to fit the page width
      const imgHeight = img.height * (pageHeight / canvas.height); // Scale image to fit the page height

      // Add a new page to the PDF
      const page = pdfDoc.addPage([pageWidth, pageHeight]);

      // Draw the image onto the page
      page.drawImage(img, {
        x: pageMargin,
        y: pageHeight - imgHeight - pageMargin, // Y position from the top of the page
        width: imgWidth,
        height: imgHeight,
      });
    };

    // Render each section on a new page
    for (let i = 0; i < sections.length; i++) {
      await renderSection(sections[i], 0);
    }

    // Serialize the document to bytes
    const pdfBytes = await pdfDoc.save();

    // Trigger the download of the PDF
    const link = document.createElement("a");
    link.href = URL.createObjectURL(
      new Blob([pdfBytes], { type: "application/pdf" })
    );
    link.download = "generated-document.pdf";
    link.click();
  };

  return (
    <div className="px-6" ref={componentRef} id="content-to-pdf">
      {/* page1 */}
      <div className="section">
        <div className="flex gap-2">
          <div className="py-8 w-1/2 flex  flex-col gap-2">
            <p className="text-gray-900 text-center font-inter text-2xl font-semibold leading-7">
              DOMESTIC{" "}
            </p>

            <p className="text-gray-900 text-center font-inter text-2xl font-semibold leading-7">
              BUSINESS CORPORATION
            </p>

            <p className="text-gray-900 text-center font-inter text-2xl font-semibold leading-7">
              STATE OF MAINE
            </p>

            <p className="text-gray-900 text-center font-inter text-3xl font-semibold leading-7">
              ARTICLES OF INCORPORATION{" "}
            </p>
          </div>
          <div className="w-1/2 mt-4 flex  flex-col">
            <p className="text-gray-900 mb-4 text-left font-inter text-lg font-bold leading-7">
              Filing Fee $145.00{" "}
            </p>

            <div className="border border-black  pt-16  px-12 w-1/7">
              <div className="flex flex-col justify-center items-center py-14 ">
                <div className="  w-1/2 flex-center  mb-8 border-t-2 border-black">
                  <p className="text-gray-900 mt-4 text-center font-inter text-lg font-base ">
                    Deputy Secretary of State
                  </p>
                </div>

                <div className="w-full mt-8 border-t mt-8   border-black">
                  <p className="text-gray-900 text-center mt-2 font-inter text-xl font-bold leading-7">
                    A True Copy When Attested By Signature
                  </p>
                </div>

                <div className="  w-1/2 flex-center mt-14  border-t-2 border-black">
                  <p className="text-gray-900 mt-4 text-center font-inter text-lg font-base ">
                    Deputy Secretary of State
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}

        {/* page 2 */}
        <div className="mt-4 ">
          <p className="text-gray-900 font-inter text-xl font-medium leading-7">
            Pursuant to{" "}
            <span className="text-blue-500 font-inter text-xl font-medium leading-7 underline">
              <a
                href="https://www.mainelegislature.org/legis/statutes/13-C/title13-Csec202.html"
                target="_blank"
              >
                13-C MRSA §202
              </a>
            </span>{" "}
            and/or
            <span className="text-blue-500 font-inter text-xl font-medium leading-7 underline">
              <a
                href="https://legislature.maine.gov/statutes/13-C/title13-Csec1803.html"
                target="_blank"
              >
                {" "}
                §1803,{" "}
              </a>
            </span>{" "}
            the undersigned executes and delivers the following Articles of
            Incorporation:
          </p>

          {/* First */}
          <div className="p-2 flex gap-12 mt-2">
            <div>
              <p className="text-gray-900 font-inter text-lg font-medium leading-7">
                First &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
              </p>
            </div>

            <div className="w-full flex  gap-8 items-center">
              <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                The name of the corporation is{" "}
              </p>

              {/* <input
                type="text"
                id="corp-name"
                value={corp_name}
                className="  mt-2 w-1/6   border-b p-2 border-b-4 border-gray-900  "
              /> */}

              <p className="w-1/2 font-bold text-gray-900 border-b">
                {CD_Corporation_Name}
              </p>
            </div>
          </div>

          {/* Second */}
          <div className="p-2 flex gap-12">
            <div>
              <p className="text-gray-900 font-inter text-lg font-medium leading-7">
                Second &nbsp; &nbsp;
              </p>
            </div>

            <div className="w-full  gap-8">
              <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                (“X” only if applicable){" "}
              </p>
              <div className="flex items-center my-2">
                <input type="checkbox" className="m-2" id="second" />
                <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                  This is a professional corporation** formed pursuant to
                  <span className="text-blue-500 font-inter text-base font-medium leading-6">
                    <a
                      href="https://www.mainelegislature.org/legis/statutes/13/title13ch22-Asec0.html"
                      target="_blank"
                    >
                      {" "}
                      13 MRSA Chapter 22-A{" "}
                    </a>
                  </span>
                  to provide the following professional services:{" "}
                </p>
              </div>
              {/* <input
                type="text"
                id="corp-name"
                className="  mt-2
             w-1/5   border-b p-2 border-b-4 border-gray-900  "
              /> */}

              <p className="w-1/2 font-bold text-gray-900 border-b">
                {CD_Corporation_Name}
              </p>
            </div>
          </div>

          {/* third */}

          <div className="p-2 flex gap-12">
            <div>
              <p className="text-gray-900 font-inter text-lg font-medium leading-7">
                Third &nbsp; &nbsp;&nbsp; &nbsp;
              </p>
            </div>

            <div className="w-full  gap-8">
              <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                (“X” only if applicable){" "}
              </p>
              <div className="flex items-center my-2">
                <input type="checkbox" className="m-2   " id="third" />
                <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                  This is a benefit corporation formed pursuant to{" "}
                  <span className="text-blue-500 font-inter text-base font-medium leading-6">
                    <a
                      href="https://legislature.maine.gov/statutes/13-C/title13-Csec1803.html"
                      target="_blank"
                    >
                      13-C MRSA §1803.{" "}
                    </a>
                  </span>
                  This election has been adopted by at least the minimum status
                  vote as defined in{" "}
                  <span className="text-blue-500 font-inter text-base font-medium leading-6">
                    <a
                      href="https://legislature.maine.gov/statutes/13-C/title13-Csec1802.html"
                      target="_blank"
                    >
                      13-C MRSA §1802.7.{" "}
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* fourth */}

          <div className="p-2 flex gap-12">
            <div>
              <p className="text-gray-900 font-inter text-lg font-medium leading-7">
                Fourth &nbsp; &nbsp;
              </p>
            </div>

            <div className="w-full  gap-8">
              <p className="text-gray-900 font-inter  text-base font-medium  leading-6">
                The Clerk is a: (select either a Commercial or Noncommercial
                Clerk – Person must be a Maine resident){" "}
              </p>
              <div className="flex mx-2 gap-32">
                <div className="text-center mt-2  flex items-center justify-center">
                  <input type="checkbox" id="clerk" className="mr-2" />
                  <span className="text-[#101828] font-inter text-base font-medium leading-[24px]">
                    Commercial Clerk
                  </span>
                </div>

                <div className="flex items-center justify-center">
                  <span className="text-[#101828] font-inter text-base font-medium leading-[24px]">
                    CRA Public Number
                  </span>
                  {/* <input
                    type="text"
                    className="  mt-2 m-2   border-b p-2 border-b-4 border-gray-900  "
                    id="cra"
                  /> */}

                  <span className="  mt-2 m-2  font-bold  border-b p-2 border-b-4 border-gray-900  ">
                    {cra_public_num}
                  </span>
                </div>
              </div>
              <div className="  w-full mt-2 m-2  font-bold  border-b p-2 border-b-4 border-gray-900  ">
                {clerk_name}
              </div>

              <div className=" mx-2 gap-32">
                <div className="flex gap-2 items-center mt-2">
                  <input type="checkbox" id="clerk" className="  " />{" "}
                  <span className="text-[#101828] font-inter text-base font-medium leading-[24px]">
                    Noncommercial Clerk
                  </span>
                </div>
                {/*  non-com-clerk*/}
                {/* <input
                  type="text"
                  className="  w-full mt-2 m-2   border-b p-2 border-b-4 border-gray-900  "
                  placeholder="$name of noncommercial clerk"
                  id="non-clerk"
                />{" "} */}

                <div className="w-full mt-2 m-2 font-bold   border-b p-2 border-b-4 border-gray-900">
                  {non_clerk_name}
                </div>
                {/*  phy*/}
                {/* <input
                  type="text"
                  className="  w-full mt-2 m-2   border-b p-2 border-b-4 border-gray-900  "
                  placeholder="$physical location, not P.O. Box – street, city, state, and zip code"
                  id="non-clerk"
                />{" "} */}

                <div className="w-full mt-2 m-2  font-bold  border-b p-2 border-b-4 border-gray-900">
                  {phy_location}
                </div>
                {/* mail */}
                {/* <input
                  type="text"
                  className="  w-full mt-2 m-2   border-b p-2 border-b-4 border-gray-900  "
                  placeholder="$mailing address if different from above"
                  id="non-clerk"
                />{" "} */}

                <div className="w-full mt-2 m-2 font-bold   border-b p-2 border-b-4 border-gray-900">
                  {mailing_address}
                </div>
              </div>
            </div>
          </div>

          {/* fifth */}
          <div className="p-2 flex gap-12">
            <div>
              <p className="text-gray-900 font-inter text-lg font-medium leading-7">
                Fifth &nbsp; &nbsp; &nbsp; &nbsp;
              </p>
            </div>

            <div className="w-full flex gap-8">
              <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                The Clerk is a: (select either a Commercial or Noncommercial
                Clerk – Person must be a Maine resident){" "}
              </p>
            </div>
          </div>

          {/* sixth */}

          <div className="p-2 flex gap-12">
            <div>
              <p className="text-gray-900 font-inter text-lg font-medium leading-7">
                Sixth &nbsp; &nbsp;&nbsp; &nbsp;
              </p>
            </div>

            <div className="w-full  gap-8">
              <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                (“X” only if applicable){" "}
              </p>
              <div className="flex items-center my-2">
                <input type="checkbox" className="m-2   " id="second" />
                <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                  There shall be only one class of shares. The number of
                  authorized shares is
                </p>
                {/* <input
                  type="text"
                  id="authorized-no"
                  placeholder="$number of authorized share"
                  className="  ml-2  mt-2
             w-1/3   border-b p-2 border-b-4 border-gray-900  "
                /> */}

                <span
                  className="  ml-2  mt-2
             w-1/3   border-b p-2 border-b-4 border-gray-900  "
                >
                  {SI_Number_Of_Shares}
                </span>
              </div>

              <div className="flex px-8 items-center">
                {/* <input type="checkbox" className="m-2   " id="second" /> */}
                <p className="text-gray-900 font-inter   text-base font-medium leading-6">
                  (Optional) Name of class:{" "}
                </p>
                {/* <input
                  type="text"
                  id="class-name"
                  placeholder="$Name of class"
                  className="  ml-2  mt-2
             w-1/2    border-b p-2 border-b-4 border-gray-900  "
                /> */}

                <span
                  // type="text"
                  // id="class-name"
                  // placeholder="$Name of class"
                  className="  ml-2 w-1/2    border-b p-2 border-b-4 border-gray-900  "
                >
                  {class_name}
                </span>
              </div>

              <div className="flex  items-center">
                <input type="checkbox" className="m-2   " id="sixth" />
                <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                  There shall be two or more classes or series of shares. The
                  information required by
                  <span className="text-blue-500 font-inter text-base font-medium leading-7 underline">
                    <a
                      href="https://www.mainelegislature.org/legis/statutes/13-c/title13-csec601.html"
                      target="_blank"
                    >
                      {" "}
                      13-C MRSA §601
                    </a>
                  </span>{" "}
                  concerning each such class and series is set forth in Exhibit
                  {/* <input
                    id="label"
                    placeholder="$Label?"
                    className="  ml-2  mt-2
             w-1/4    border-b p-2 border-b-4 border-gray-900  "
                  /> */}
                  <span
                    className={` inline-block mt-2 m-2 border-b font-bold p-2 border-b-4 border-gray-900 
  ${unknown_lable1 ? "w-1/6" : "w-1/6"}`}
                  >
                    {unknown_lable1}
                  </span>
                  <span className="ml-2">
                    attached hereto and made a part hereof.
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* seventh */}
          <div className="p-2 flex gap-12">
            <div>
              <p className="text-gray-900 font-inter text-lg font-medium leading-7">
                Seventh
              </p>
            </div>

            <div className="w-full  gap-8">
              <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                (“X” one box only){" "}
              </p>
              <div className="flex items-center my-2">
                <input type="checkbox" className="m-2" id="bord-of-dir" />
                <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                  The corporation will have a board of directors.
                </p>
              </div>

              <div className="flex items-center my-2">
                <input type="checkbox" className="m-2   " id="bord-of-dir" />
                <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                  There will be no directors; the business of the Corporation
                  will be managed by shareholders
                  <span className="text-blue-500 font-inter text-base font-medium leading-6">
                    {" "}
                    <a
                      href="https://www.mainelegislature.org/legis/statutes/13-c/title13-csec743.html"
                      target="_blank"
                    >
                      (13-C MRSA §743)
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* eight */}
          <div className="p-2 flex gap-12">
            <div>
              <p className="text-gray-900 font-inter text-lg font-medium leading-7">
                Eighth &nbsp; &nbsp;&nbsp;
              </p>
            </div>

            <div className="w-full  gap-8">
              <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                (For corporations with directors, each of the following
                provisions is optional – &quot;X&quot; only if applicable){" "}
              </p>
              <div className="flex items-center my-2">
                <input type="checkbox" className="m-2" id="num-of-dir" />
                <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                  The number of directors is limited as follows: not fewer than{" "}
                </p>
                {/* <input
                  type="text"
                  placeholder="Lable?"
                  className="m-2 underline w-20"
                /> */}
                <div className="m-2 inline-block  font-bold  border-b w-1/12">
                  <span>{unknown_lable2}</span>
                </div>
                nor more than
                {/* <input
                  type="text"
                  placeholder="Lable?"
                  className="m-2 underline w-20"
                /> */}

<div className="m-2 inline-block font-bold  border-b w-1/12">
                  <span>{unknown_lable3}</span>
                </div>
                nor more than directors.
                <span className="text-blue-500 font-inter text-base font-medium leading-6">
                  <a
                    href="https://www.mainelegislature.org/legis/statutes/13-c/title13-csec803.html"
                    target="_blank"
                  >
                    (13-C MRSA §803)
                  </a>
                </span>
              </div>

              <div className="flex">
                <input type="checkbox" className="m-2   " id="bord-of-dir" />
                <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                  To the fullest extent permitted by
                  <span className="text-blue-500 font-inter text-base font-medium leading-6">
                    {" "}
                    <a
                      href="https://www.mainelegislature.org/legis/statutes/13-C/title13-Csec202.html"
                      target="_blank"
                    >
                      13-C MRSA §202.2.D,{" "}
                    </a>
                  </span>
                  a director shall have no liability to the Corporation or its
                  shareholders for money damages for an action taken or a
                  failure to take an action as a director.
                </p>
              </div>

              <div className="flex items-center my-2">
                <input type="checkbox" className="m-2   " id="bord-of-dir" />
                <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                  Except as otherwise specified by contract or in its bylaws,
                  the Corporation shall in all cases provide indemnification
                  (including advances of expenses) to its directors and officers
                  to the fullest extent permitted by law.{" "}
                  <span className="text-blue-500 font-inter text-base font-medium leading-6">
                    {" "}
                    <a
                      href="https://www.mainelegislature.org/legis/statutes/13-C/title13-Csec202.html"
                      target="_blank"
                    >
                      (13-C MRSA §§202,
                    </a>
                    <a
                      href="https://www.mainelegislature.org/legis/statutes/13-c/title13-csec857.html"
                      target="_blank"
                    >
                      857
                    </a>
                    <span className="text-gray-900 font-inter  text-base font-medium">
                      {" "}
                      and{" "}
                    </span>
                    <a href="https://www.mainelegislature.org/legis/statutes/13-c/title13-csec859.html">
                      859)
                    </a>{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* nineth */}
          <div className="p-2 flex gap-12">
            <div>
              <p className="text-gray-900 font-inter text-lg font-medium leading-7">
                Ninth&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </p>
            </div>

            <div className="w-full  gap-8">
              <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                (“X” one box only){" "}
              </p>

              <div className="flex items-center my-2">
                <input type="checkbox" className="m-2   " id="rights" />
                <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                  The Corporation elects to have preemptive rights as defined in
                  <span className="text-blue-500 font-inter text-base font-medium leading-6">
                    {" "}
                    <a
                      href="https://www.mainelegislature.org/legis/statutes/13-c/title13-csec641.html"
                      target="_blank"
                    >
                      13-C MRSA §641.
                    </a>{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* tenth */}
          <div className="p-2 flex gap-12">
            <div>
              <p className="text-gray-900 font-inter text-lg font-medium leading-7">
                Tenth&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
              </p>
            </div>

            <div className="w-full  gap-8">
              <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                (&quot;X&quot; only if applicable){" "}
              </p>

              <div className="flex items-center my-2">
                <input type="checkbox" className="m-2   " id="incorp" />
                <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                  Additional provisions of these Articles of Incorporation are
                  set forth in Exhibit
                  {/*  */}
                  {/* <input
                    type="text"
                    placeholder="Lable?"
                    className="m-2 underline w-20"
                  /> */}

<div className="m-2 inline-block min-h-12 font-bold  border-b w-1/12">
                  <span>{unknown_lable4}</span>
                </div>
                  attached hereto and made a part hereof. (
                  <span className="text-blue-500 font-inter text-base font-medium leading-7 underline">
                    <a
                      href="https://www.mainelegislature.org/legis/statutes/13-C/title13-Csec202.html"
                      target="_blank"
                    >
                      13-C MRSA §202
                    </a>
                  </span>{" "}
                  and{" "}
                  <span className="text-blue-500 font-inter text-base font-medium leading-7 underline">
                    <a
                      href="https://www.mainelegislature.org/legis/statutes/13-C/title13-Csec202.html"
                      target="_blank"
                    >
                      13-C MRSA §1811
                    </a>{" "}
                  </span>
                  )
                </p>
              </div>
            </div>
          </div>

          {/* Eleventh */}
          <div className="p-2 flex gap-12">
            <div>
              <p className="text-gray-900 font-inter text-lg font-medium leading-7">
                Eleventh
              </p>
            </div>

            <div className="w-full">
              <div className="flex items-center my-2">
                <input type="checkbox" className="m-2   " id="name-corp-add" />
                <p className="text-gray-900 font-inter  text-base font-medium leading-6">
                  Name and address of additional Incorporators is set forth on
                  Exhibit
{/*                   
                  <input
                    type="text"
                    placeholder="Lable?"
                    id="incorp-forth"
                    className="m-2 underline w-20"
                  /> */}

                <div className="m-2 inline-block min-h-12 font-bold  border-b w-1/12">
                  <span>{unknown_lable5}</span>
                </div>
                  attached hereto.
                </p>
              </div>
            </div>
          </div>

          {/* date */}
          <div className="flex p-1">
            <div className="p-2 flex w-1/2 ">
              <span className="text-gray-900 mt-4 font-inter text-lg font-medium">
                Dated
              </span>

              {/* <input
                type="text"
                className="flex mt-0 w-[222px] px-4 py-0 items-center content-center underline gap-2 flex-wrap"
                placeholder="Label?"
              /> */}

<div className="m-4 ml-8 flex justify-center min-h-12 font-bold text-center   border-b w-1/12">
                  <span>{unknown_lable6}</span>
                </div>
            </div>

            <div className="flex flex-col gap-2 w-full">
              <div className="w-full flex gap-9">
                <span className="mr-4">
                  By &nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>

                <span className="flex border-b border-black    items-center gap-2 flex-1 flex-wrap"></span>
              </div>

              <div className="w-full flex gap-9 mt-4">
                <span className="mr-4">
                  Type or print name of incorporator{" "}
                </span>

                <span className="flex border-b border-black    items-center gap-2 flex-1 flex-wrap"></span>
              </div>
            </div>
          </div>
          {/*  */}
          <div className="mt-4 px-2">
            <span className="text-gray-900 mt-4 font-inter text-lg font-medium">
              The professional corporation name must contain one of the
              following: “chartered,” “professional corporation,” “professional
              association” or “service corporation” or the abbreviation “P.C.,”
              “P.A.” or “S.C.”. Examples of professional service corporations
              are accountants, attorneys, chiropractors, dentists, registered
              nurses and veterinarians. (This is not an inclusive list – see
              <span className="text-primary-700 font-inter text-base font-medium leading-6">
                13 MRSA §723
              </span>
              .){" "}
            </span>
          </div>
          {/*  */}
          <div className="mt-4 px-2 flex flex-col gap-0">
            <p className="text-gray-900 mt-4 font-inter text-lg font-medium">
              {" "}
              *These articles must be dated and executed pursuant to
              {/* </p> */}
              <span className="text-blue-500 font-inter text-base font-medium leading-6">
                {" "}
                <a
                  href="https://www.mainelegislature.org/legis/statutes/13-C/title13-Csec121.html"
                  target="_blank"
                >
                  13-C MRSA §121.5.{" "}
                </a>
              </span>
              by an incorporator.
            </p>

            <p className="text-gray-900 mt-4 font-inter text-lg font-medium">
              {" "}
              Please remit your payment made payable to the Maine Secretary of
              State.
            </p>
          </div>
          {/*  */}
          <div className="px-2 py-2">
            <div className="flex justify-between mt-4">
              <div>
                <p className="text-gray-900 font-inter text-lg font-medium leading-7">
                  Submit completed form to:
                </p>
              </div>

              <div>
                <p className="text-grey-900 font-inter text-base font-bold leading-6">
                  Secretary of State
                </p>

                <p className="text-grey-900 font-inter text-base font-bold leading-6">
                  Division of Corporations, UCC and Commissions
                </p>

                <p className="text-grey-900 font-inter text-base font-bold leading-6">
                  101 State House Station, Augusta, ME 04333-0101{" "}
                </p>

                <p className="text-grey-900 font-inter text-base font-bold leading-6">
                  Augusta, ME 04333-0101
                </p>
                <p className="text-grey-900 font-inter text-base font-bold leading-6">
                  <span className="text-grey-900 font-inter text-base font-medium leading-6">
                    Telephone Inquiries:
                  </span>{" "}
                  (207) 624-7752{" "}
                  <span className="ml-4">
                    Email Inquiries:
                    <span className="text-blue-500 font-inter text-lg font-normal leading-7 underline">
                      {" "}
                      <a
                        href="mailto:CEC.Corporations@Maine.gov"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CEC.Corporations@Maine.gov
                      </a>
                    </span>
                  </span>
                </p>
              </div>
            </div>

            <div className="flex mt-12">
              {/* <p>
            Email Inquiries:
            <span className="text-blue-500 font-inter text-lg font-normal leading-7 underline">
              <a
                href="mailto:CEC.Corporations@Maine.gov"
                target="_blank"
                rel="noopener noreferrer"
              >
                CEC.Corporations@Maine.gov
              </a>
            </span>
          </p> */}
            </div>
          </div>
        </div>

        <div className="w-full border-t-2 my-8 border-black"></div>

        {/* new page */}

        <div className="px-8 section">
          {/* heading */}
          <div className="py-10 px-10">
            <p className="text-gray-900 text-center font-inter text-2xl font-semibold leading-[30px]">
              Filer Contact Cover Letter{" "}
            </p>
          </div>

          {/*  */}

          <div className="flex gap-8">
            <div>
              <p className="text-gray-900 font-inter text-lg font-medium leading-7">
                To &nbsp; &nbsp; &nbsp;&nbsp;
              </p>
            </div>

            <div>
              <div className="w-full">
                <div className="flex justify-between">
                  <p className="text-grey-900 font-inter text-base font-bold leading-6">
                    Department of the Secretary of State{" "}
                  </p>

                  <p className="text-grey-900 font-inter text-base font-base leading-6">
                    Tel. (207) 624-7752{" "}
                  </p>
                </div>

                <p className="text-grey-900 my-1 font-inter text-base font-bold leading-6">
                  Division of Corporations, UCC and Commissions{" "}
                </p>

                <p className="text-grey-900 my-1 font-inter text-base font-bold leading-6">
                  101 State House Station{" "}
                </p>

                <p className="text-grey-900 my-1 font-inter text-base font-bold leading-6">
                  <span className="text-grey-900 font-inter text-base font-medium leading-6">
                    Augusta, ME 04333-0101{" "}
                  </span>{" "}
                </p>
              </div>

              <p className="text-grey-900 my-1 font-inter text-base font-bold leading-6">
                <span className="text-grey-900 font-inter text-base font-medium leading-6">
                  Name of Entity (s):{" "}
                </span>{" "}
              </p>

              <div className="flex p-[0px_14px]  my-1 border-b-2 border-grey-900 mt-4 items-center content-center gap-2 flex-1 flex-wrap">
                {/* <input
                  className="text-[#667085] w-full  font-inter text-sm font-normal leading-[24px]"
                  placeholder="$name"
                /> */}
              
              <span
                  className="text-[#667085] w-full   font-inter text-sm font-bold leading-[24px]"
                  placeholder="$name">

                    {CD_Corporation_Name}
                  </span>
              </div>

              <div className="flex p-[0px_14px] my-2 border-b-2 border-grey-900 mt-4 items-center content-center gap-2 flex-1 flex-wrap">
                {/* <input
                  className="text-[#667085] w-full font-inter text-sm font-normal leading-[24px]"
                  placeholder="$name"
                /> */}
                   <span
                  className="text-[#667085] w-full   font-inter text-sm font-bold leading-[24px]"
                  placeholder="$name">
                    
                    {name}
                  </span>
              </div>

              {/*  */}

              <p className="text-grey-900 font-inter  text-base font-base mt-4 leading-6">
                <span className="text-grey-900 font-inter text-base font-medium leading-6">
                  List type of filing(s) enclosed (i.e. Articles of
                  Incorporation, Articles of Merger, Articles of Amendment,
                  Certificate of Correction, etc.) Attach additional pages as
                  needed.{" "}
                </span>{" "}
              </p>

              <div className="flex p-[0px_14px] border-b-2 border-grey-900 mt-4 items-center content-center gap-2 flex-1 flex-wrap">
                {/* <input
                  className="text-[#667085]  w-full font-inter text-sm font-normal leading-[24px]"
                  placeholder="$lable"
                /> */}

<span className="text-[#667085] w-full   font-inter text-sm font-bold leading-[24px]"> {label} </span>
              </div>

              <div className="flex p-[0px_14px] border-b-2 border-grey-900 mt-4 items-center content-center gap-2 flex-1 flex-wrap">
                {/* <input
                  className="text-[#667085]  w-full  font-inter text-sm font-normal leading-[24px]"
                  placeholder="$lable"
                /> */}

<span className="text-[#667085] w-full   font-inter text-sm font-bold leading-[24px]"> {label} </span>
              </div>

              <div className="flex flex-col gap-3">
                <p className="text-grey-900 font-inter text-base font-base mt-4 leading-6">
                  <span className="text-grey-900 font-inter text-base font-medium leading-6">
                    Special handling request(s): (check all that apply)
                  </span>{" "}
                </p>

                <div className="flex gap-4 items-center">
                  <input type="checkbox" className=" "></input>
                  <p>Hold for pick up</p>
                </div>

                <div className="flex gap-4 items-center">
                  <input type="checkbox" className=""></input>
                  <p>
                    Expedited filing - 24 hour service ($50 additional filing
                    fee per entity, per service)
                  </p>
                </div>

                <div className="flex gap-4 items-center">
                  <input type="checkbox" className="  "></input>
                  <p>
                    Expedited filing - Immediate service ($100 additional filing
                    fee per entity, per service)
                  </p>
                </div>
              </div>

              <div className="flex gap-3">
                <p className="text-grey-900 font-inter text-base font-base mt-4 leading-6">
                  <span className="text-grey-900 font-inter text-base font-medium leading-6">
                    <pre className="font-inter">
                      Total filing fee(s) enclosed: $
                    </pre>
                  </span>{" "}
                </p>

                <div className="w-full border-b-2 border-grey-900 mt-4">
                  {/* <input
                    type="text"
                    placeholder="$filing fee"
                    id="fillings-fee"
                    className="w-full"
                  /> */}

<span className="text-[#667085] w-full   font-inter text-sm font-bold leading-[24px]"> {fillings_fee} </span>
                </div>
              </div>

              <div className="mt-8">
                <span className="text-[#101828] font-inter text-lg font-bold leading-[24px]">
                  Contact Information – questions regarding the above filing(s),
                  please call or email:
                </span>{" "}
                <span className="text-[#101828] font-inter text-base font-medium leading-[24px]">
                  (Failure to provide a contact name and telephone number or
                  email address will result in the return of the erroneous
                  filing(s) by the Secretary of State’s office)
                </span>
              </div>

              <div className="w-full">
                <div className="flex gap-4">
                  <div className="w-1/2 border-b-2  mt-6 border-[#D0D5DD] flex justify-center">
                    {/* <input
                      type="text"
                      placeholder="$name_of_contact_person"
                      id="name-contact-per"
                      className="w-full text-center"
                    /> */}

<div className="text-[#667085] w-full text-center   font-inter text-sm font-bold leading-[24px]"> {name_of_contact_person} </div>
                  </div>

                  <div className="w-1/2 border-b-2 border-[#D0D5DD] flex justify-center">
                    {/* <input
                      type="text"
                      placeholder="$daytime_telephone_number"
                      id="person-tel"
                      className="w-full text-center"
                    /> */}
                    <div className="text-[#667085] w-full text-center   font-inter text-sm font-bold leading-[24px]"> {daytime_telephone_number} </div>
                  </div>
                </div>

                <div className="w-full border-b-2 border-[#D0D5DD] flex justify-center mt-6">
                  {/* <input
                    type="text"
                    placeholder="$email address"
                    id="person-mail"
                    className="w-full text-center"
                  /> */}

<div className="text-[#667085] w-full text-center   font-inter text-sm font-bold leading-[24px]"> {email_address} </div>

                  
                </div>
              </div>

              <div className="mt-8">
                <p className="text-[#101828] font-inter text-base font-medium leading-[24px]">
                  The enclosed filing(s) and fee(s) are submitted for filing.
                  Please return the attested copy to the following address:
                </p>

                <div className="w-full mt-2  border-b-2 border-[#D0D5DD] flex justify-center mt-6">
                  {/* <input
                    type="text"
                    placeholder="$name_of_attested_receipt"
                    id="name_of_attested_receipt"
                    className="w-full text-center"
                  /> */}

<div className="text-[#667085] w-full text-center   font-inter text-sm font-bold leading-[24px]"> {name_of_attested_receipt} </div>
                </div>

                <div className="w-full mt-2  border-b-2 border-[#D0D5DD] flex justify-center mt-6">
                  {/* <input
                    type="text"
                    placeholder="$firm_or_company"
                    id="firm_or_company"
                    className="w-full text-center"
                  /> */}

<div className="text-[#667085] w-full text-center   font-inter text-sm font-bold leading-[24px]"> {firm_or_company} </div>

                </div>

                <div className="w-full mt-2  border-b-2 border-[#D0D5DD] flex justify-center mt-6">
                  {/* <input
                    type="text"
                    placeholder="$mailing_address"
                    id="mailing_address"
                    className="w-full text-center"
                  /> */}

<div className="text-[#667085] w-full text-center   font-inter text-sm font-bold leading-[24px]"> {mailing_address} </div>

                </div>

                <div className="w-full mt-2 border-b-2 border-[#D0D5DD] flex justify-center mt-6">
                  {/* <input
                    type="text"
                    placeholder="$city_state_zip"
                    id="city_state_zip"
                    className="w-full text-center"
                  /> */}

<div className="text-[#667085] w-full text-center   font-inter text-sm font-bold leading-[24px]"> {city_state_zip} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-content-center p-2">
        <Button
          className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
          label="Download PDF"
          icon="pi pi-download"
          onClick={downloadPDF}
        ></Button>
      </div>
    </div>
  );
}

export default MainCorp;
