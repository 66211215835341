import React from "react";

function PaymentItem({ productListing,unitPrice }) {
  console.log(productListing)
  return (
    <div className="flex flex-col mt-3 w-full max-md:max-w-full">
      <div className="flex gap-1 w-full text-base font-medium text-slate-700 max-md:max-w-full">
        <div className="flex flex-wrap flex-1 shrink gap-1 items-end basis-0 min-w-[240px] size-full max-md:max-w-full">
          <div className="flex-1 shrink leading-10 basis-0 max-md:max-w-full">{productListing.displayName}</div>
          <div className="tracking-tight leading-10">${unitPrice}</div>
        </div>
      </div>
      {/* <p className="mt-1 text-sm leading-5 text-gray-500 max-md:max-w-full">{description}</p> */}
      {/* {additionalInfo && (
        <p className="mt-1 text-sm leading-none text-gray-500">{additionalInfo}</p>
      )} */}
    </div>
  );
}

export default PaymentItem;