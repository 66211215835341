export const stateWithCounty = [
    "New York",
    "Arizona",
    "Alabama",
    "Illinois",
    "Maryland",
    "North Carolina",
    "New Hampshire",
    "Tennessee",
    "Georgia"
];