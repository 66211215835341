import React from 'react';
import VerticalBarDemo from './BarChart';
import DoughnutChartDemo from './DougnutChart';

function ActiveClientsMap({startDate,endDate}) {
  return (

    <div className="flex flex-col mt-6 w-full max-md:max-w-full">
     <div className="flex overflow-hidden flex-col w-full bg-white rounded-lg border border-gray-200 shadow-sm max-md:max-w-full">
        <div className="flex flex-col p-4 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col pb-px w-full font-medium max-md:max-w-full">
            <div className="flex flex-wrap gap-4 items-center w-full max-md:max-w-full">
              <h3 className="flex-1 shrink self-stretch my-auto text-lg leading-loose text-gray-900 basis-0 max-md:max-w-full">
                Services Ordered
              </h3>
              <div className="flex gap-3 items-center self-stretch my-auto text-sm leading-none text-slate-700">
                {/* <button className="flex items-start self-stretch my-auto rounded-lg">
                  <div className="overflow-hidden gap-2 self-stretch px-4 py-2.5 bg-white rounded-lg border border-gray-300 border-solid shadow-sm">
                    Real-Time Report
                  </div>
                </button> */}
              </div>
            </div>
            <hr className="mt-4 w-full bg-gray-200 min-h-[1px] max-md:max-w-full" />
          </div>
          <div className="grid">
            <div className='col-12 col-sm-12 col-md-12 col-lg-8'>
              <VerticalBarDemo />
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-4'>
              <DoughnutChartDemo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveClientsMap;