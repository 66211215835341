import React, { useContext, useEffect, useRef, useState } from "react";
import PaymentDetails from "./PaymentDetails";
import ActionButtons from "./ActionButtons";
import ReactToPrint from "react-to-print";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import authContext from "../../common/authContext";
import OrderServices from "../../services/orderServices";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { showToast } from "../../utils/toastUtils";

function PaymentSuccess() {
  const {
    paySessionId,
    permissions,
    loginUserName,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setRedirectedData,
    isTabRefresh,
    setIsTabRefresh,
    isReviewDone,
    setIsReviewDone,
  } = useContext(authContext);

  const printRef = useRef();
  const toast = useRef(null);
  const history = useNavigate();
  const paymentData = localStorage.getItem("payment");
  const sessionId = localStorage.getItem("sessionId");
  const jsonPayment = JSON.parse(paymentData);
  const [orderPayData, setOrderPayData] = useState(null);
  const [paytData, setPayData] = useState(null);
  const [isDataExist, setIsDataExist] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("");
  const convertCentsToDollars = (cents) => {
    return (cents / 100).toFixed(2);
  };
  const convertTimestampToDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);

    const options = {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate;
  };
  const convertTimestampToDate1 = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);

    return date.toISOString();
  };

  // useEffect(() => {
  //   if (
  //     jsonPayment &&
  //     sessionId &&
  //     !paytData &&
  //     !orderPayData &&
  //     !isDataExist
  //   ) {
  //     setIsDataExist(jsonPayment);

 
  //     async function processPayment(sessionId) {

  //       setIsTabRefresh(true);
  //       try {
  //         // Define transactionData from localStorage (this fixes the 'transactionData' not defined error)
  //         const transactionData = JSON.parse(localStorage.getItem("transactiondata"));
  //         console.log(transactionData);
      
  //         const paymentResponse = await OrderServices.paymentSession(sessionId);
      
  //         if (
  //           jsonPayment.data !== undefined &&
  //           jsonPayment.data &&
  //           !paytData &&
  //           !orderPayData
  //         ) {
  //           const tempdata = jsonPayment.data.orderDetails;
            
  //           if (
  //             jsonPayment.data.statusId.id !== 14 &&
  //             jsonPayment.data.statusId.id !== 1003
  //           ) {
  //             // Prepare data for order update
  //             const sessionResponse = await OrderServices.searchSession(
  //               jsonPayment.data.orderDetails.orderId
  //             );
              
  //             tempdata.transactionId = paymentResponse.data.payment_intent;
  //             tempdata.generateReceipt = true;
  //             tempdata.orderLineItems = jsonPayment.orderLineItem;
  //             tempdata.orderUpdatedBy = jsonPayment.updatedBy;
  //             tempdata.paymentMethod = sessionResponse?.data?.paymentMethodType || "Card";

  //             tempdata.orderUpdateNotes = "Payment done successfully";
  //             tempdata.stateId = jsonPayment.data.state ? jsonPayment.data.state.id : null;
  //             tempdata.countyId = jsonPayment.data.orderDetails
  //               ? jsonPayment.data.orderDetails.countyId
  //               : null;
  //             tempdata.transactionDate = convertTimestampToDate1(paymentResponse.data.created);
      
  //             const orderUpdateResponse = await OrderServices.orderUpdate(tempdata);
  //             setRedirectedData(orderUpdateResponse.data.orderDetails.id);
  //             setIsReviewDone(false);
  //             setOrderPayData(orderUpdateResponse.data);
  //             setPayData(paymentResponse.data);
  //             localStorage.removeItem("payment");
  //             showToast("success", "Order updated successfully");
      
  //             try {
  //               const orderRecieptData = {
  //                 id: orderUpdateResponse.data.orderDetails.id,
  //                 generateReceiptValue: true,
  //               };
  //               await OrderServices.saveAllSalesReciept(orderRecieptData);
  //             } catch (err) {
  //             }
      
  //             // Handle invoice & transaction saving
  //             const invoiceResponse = await OrderServices.isInvoiceAvailable(
  //               jsonPayment.data.orderDetails.id
  //             );
      
  //             if (invoiceResponse.status === 204) {
  //               const PaidOrderdata = {
  //                 orderId: jsonPayment.data.orderDetails,
  //                 invoiceNo: `INV ${jsonPayment.data.orderDetails.orderId}`,
  //                 totalAmount: jsonPayment.data.orderDetails.orderAmount,
  //                 statusId: jsonPayment.data.statusId.id,
  //                 hubspotId: jsonPayment.data.statusId.hubspotId,
  //                 orderLineItems: jsonPayment?.data?.orderDetails?.orderLineItems,
  //                 base64Data: "",
  //                 invoiceUpdateNotes: "Payment Done",
  //                 invoiceUpdatedBy: "null",
  //               };
      
  //               const invoiceSaveResponse = await OrderServices.saveAllInvoice(PaidOrderdata);
  //               if (invoiceSaveResponse.data) {
  //                 const saveTransactionPayload = {
  //                   id: transactionData?.id,
  //                   transactionId: tempdata?.transactionId,
  //                   transactionDate: tempdata?.transactionDate,
  //                   transactionType: paymentMethod.toUpperCase() || "CARD",
  //                   transactionStatus: 31,
  //                   amount: invoiceSaveResponse.data?.totalAmount,
  //                   orderDetails: invoiceSaveResponse.data?.orderId,
  //                   invoiceDetails: invoiceSaveResponse.data,
  //                 };

                  
      
  //                 await OrderServices.saveTransaction(saveTransactionPayload);
      
  //                 // If necessary, save invoice again
  //                 await OrderServices.saveAllInvoice(PaidOrderdata);
  //               }
  //             } else {
  //               const PaidOrderdata = {
  //                 id: invoiceResponse.data.id,
  //                 orderId: jsonPayment.data.orderDetails,
  //                 invoiceNo: `INV ${jsonPayment.data.orderDetails.orderId}`,
  //                 totalAmount: jsonPayment.data.orderDetails.orderAmount,
  //                 statusId: jsonPayment.data.statusId.id,
  //                 quickbooksId: invoiceResponse?.data?.quickbooksId,
  //                 hubspotId: jsonPayment.data.statusId.hubspotId,
  //                 orderLineItems: jsonPayment?.data?.orderDetails?.orderLineItems,
  //                 base64Data: "",
  //                 invoiceUpdateNotes: "Payment Done",
  //                 invoiceUpdatedBy: "null",
  //               };
      
  //               const invoiceSaveResponse = await OrderServices.saveAllInvoice(PaidOrderdata);
  //               if (invoiceSaveResponse.data) {
  //                 const saveTransactionPayload = {
  //                   id: transactionData?.id,
  //                   transactionId: tempdata?.transactionId,
  //                   transactionDate: tempdata?.transactionDate,
  //                   transactionType: paymentMethod.toUpperCase() || "CARD",
  //                   transactionStatus: 31,
  //                   amount: invoiceSaveResponse.data?.totalAmount,
  //                   orderDetails: invoiceSaveResponse.data?.orderId,
  //                   invoiceDetails: invoiceSaveResponse.data,
  //                 };
  //                 await OrderServices.saveTransaction(saveTransactionPayload);
  //               }
  //             }
  //           } else if (
  //             jsonPayment.data.statusId.id === 14 ||
  //             jsonPayment.data.statusId.id === 1003
  //           ) {
  //             // Under review case
  //             const sessionResponse = await OrderServices.searchSession(
  //               jsonPayment.data.orderDetails.orderId
  //             );
      
  //             setPaymentMethod(sessionResponse?.data?.paymentMethodType || "Card");
      
  //             // Update tempdata for review
  //             tempdata.statusId = 9;
  //             tempdata.paymentMethod = sessionResponse?.data?.paymentMethodType || "Card";
  //             tempdata.orderUpdateNotes = "Order is under review.";
  //             tempdata.transactionId = paymentResponse.data.payment_intent;
  //             tempdata.generateReceipt = true;
  //             tempdata.orderLineItems = jsonPayment.orderLineItem;
  //             tempdata.orderUpdatedBy = jsonPayment.updatedBy;
  //             tempdata.stateId = jsonPayment.data.state ? jsonPayment.data.state.id : null;
  //             tempdata.countyId = jsonPayment.data.orderDetails
  //               ? jsonPayment.data.orderDetails.countyId
  //               : null;
  //             tempdata.transactionDate = convertTimestampToDate1(paymentResponse.data.created);
      
  //             const orderSaveResponse = await OrderServices.saveAllOrder(tempdata);
  //             setRedirectedData(orderSaveResponse.data.orderDetails?.id);
  //             setIsReviewDone(false);
  //             setOrderPayData(orderSaveResponse.data);
  //             setPayData(paymentResponse.data);
  //             localStorage.removeItem("payment");
  //             showToast("success", "Order updated successfully");
      
  //             // Handle invoice and transaction saving under review
  //             const invoiceResponse = await OrderServices.isInvoiceAvailable(
  //               jsonPayment.data.orderDetails.id
  //             );
  //             if (invoiceResponse.status === 204) {
  //               const PaidOrderdata = {
  //                 orderId: jsonPayment.data.orderDetails,
  //                 invoiceNo: `INV ${jsonPayment.data.orderDetails.orderId}`,
  //                 totalAmount: jsonPayment.data.orderDetails.orderAmount,
  //                 statusId: jsonPayment.data.statusId.id,
  //                 orderLineItems: jsonPayment?.data?.orderDetails?.orderLineItems,
  //                 base64Data: "",
  //                 invoiceUpdateNotes: "Payment Done",
  //                 invoiceUpdatedBy: "null",
  //               };
      
  //               const invoiceSaveResponse = await OrderServices.saveAllInvoice(PaidOrderdata);
  //               if (invoiceSaveResponse.data) {
  //                 const saveTransactionPayload = {
  //                   id: transactionData?.id,
  //                   transactionId: tempdata?.transactionId,
  //                   transactionDate: tempdata?.transactionDate,
  //                   transactionType: paymentMethod.toUpperCase() || "CARD",
  //                   transactionStatus: 31,
  //                   amount: invoiceSaveResponse.data?.totalAmount,
  //                   orderDetails: invoiceSaveResponse.data?.orderId,
  //                   invoiceDetails: invoiceSaveResponse.data,
  //                 };
  //                 await OrderServices.saveTransaction(saveTransactionPayload);
  //                 await OrderServices.saveAllInvoice(PaidOrderdata);
  //               }
  //             } else {
  //               const PaidOrderdata = {
  //                 id: invoiceResponse?.data?.id,
  //                 orderId: jsonPayment.data.orderDetails,
  //                 invoiceNo: `INV ${jsonPayment.data.orderDetails.orderId}`,
  //                 totalAmount: jsonPayment.data.orderDetails.orderAmount,
  //                 statusId: jsonPayment.data.statusId.id,
  //                 quickbooksId: invoiceResponse?.data?.quickbooksId,
  //                 hubspotId: jsonPayment.data.statusId.hubspotId,
  //                 orderLineItems: jsonPayment?.data?.orderDetails?.orderLineItems,
  //                 base64Data: "",
  //                 invoiceUpdateNotes: "Payment Done",
  //                 invoiceUpdatedBy: "null",
  //               };
      
  //               const invoiceSaveResponse = await OrderServices.saveAllInvoice(PaidOrderdata);
  //               if (invoiceSaveResponse.data) {
  //                 const saveTransactionPayload = {
  //                   id: transactionData?.id,
  //                   transactionId: tempdata?.transactionId,
  //                   transactionDate: tempdata?.transactionDate,
  //                   transactionType: paymentMethod.toUpperCase() || "CARD",
  //                   transactionStatus: 31,
  //                   amount: invoiceSaveResponse.data?.totalAmount,
  //                   orderDetails: invoiceSaveResponse.data?.orderId,
  //                   invoiceDetails: invoiceSaveResponse.data,
  //                 };
  //                 await OrderServices.saveTransaction(saveTransactionPayload);
  //               }
  //             }
  //           }
  //         }
  //       } catch (error) {
  //         showToast("error", "Order update failed");
  //       } finally {
  //         // Ensure the refresh flag is turned off after all processing is complete
  //         setTimeout(() => {
  //           setIsTabRefresh(false);
  //         }, 2000);
  //       }
  //     }
      
  //     // Call the function with your sessionId
  //     processPayment(sessionId);
      
 
  //   }
  // }, []);



  // useEffect(() => {
  //   if (jsonPayment && sessionId && !paytData && !orderPayData && !isDataExist) {
  //     setIsDataExist(jsonPayment);
  
  //     // Helper function to get the session response and wait for it.
  //     async function getSessionResponse(orderId) {
  //       try {
  //         const response = await OrderServices.searchSession(orderId);
  //         return response;
  //       } catch (error) {
  //         showToast("error", "Failed to get session details");
  //         throw error;
  //       }
  //     }
  
  //     async function processPayment(sessionId) {
  //       setIsTabRefresh(true);
  //       try {
  //         // Retrieve transactionData from localStorage.
  //         const transactionData = JSON.parse(localStorage.getItem("transactiondata"));
  //         console.log(transactionData);
  
  //         const paymentResponse = await OrderServices.paymentSession(sessionId);
  
  //         if (jsonPayment.data !== undefined && jsonPayment.data && !paytData && !orderPayData) {
  //           const tempdata = jsonPayment.data.orderDetails;
            
  //           // Get sessionResponse and wait for it before continuing.
  //           const sessionResponse = await getSessionResponse(jsonPayment.data.orderDetails.orderId);
  //           // Use the payment method from sessionResponse or default to "Card".
  //           const paymentMethodValue = sessionResponse?.data?.paymentMethodType || "Card";
  
  //           // Check for the normal payment success case.
  //           if (
  //             jsonPayment.data.statusId.id !== 14 &&
  //             jsonPayment.data.statusId.id !== 1003
  //           ) {
  //             tempdata.transactionId = paymentResponse.data.payment_intent;
  //             tempdata.generateReceipt = true;
  //             tempdata.orderLineItems = jsonPayment.orderLineItem;
  //             tempdata.orderUpdatedBy = jsonPayment.updatedBy;
  //             tempdata.paymentMethod = paymentMethodValue;
  //             tempdata.orderUpdateNotes = "Payment done successfully";
  //             tempdata.stateId = jsonPayment.data.state ? jsonPayment.data.state.id : null;
  //             tempdata.countyId = jsonPayment.data.orderDetails ? jsonPayment.data.orderDetails.countyId : null;
  //             tempdata.transactionDate = convertTimestampToDate1(paymentResponse.data.created);
  
  //             const orderUpdateResponse = await OrderServices.orderUpdate(tempdata);
  //             setRedirectedData(orderUpdateResponse.data.orderDetails.id);
  //             setIsReviewDone(false);
  //             setOrderPayData(orderUpdateResponse.data);
  //             setPayData(paymentResponse.data);
  //             localStorage.removeItem("payment");
  //             showToast("success", "Order updated successfully");
  
  //             // Handle invoice and transaction saving
  //             const invoiceResponse = await OrderServices.isInvoiceAvailable(jsonPayment.data.orderDetails.id);
  //             if (invoiceResponse.status === 204) {
  //               const PaidOrderdata = {
  //                 orderId: jsonPayment.data.orderDetails,
  //                 invoiceNo: `INV ${jsonPayment.data.orderDetails.orderId}`,
  //                 totalAmount: jsonPayment.data.orderDetails.orderAmount,
  //                 statusId: jsonPayment.data.statusId.id,
  //                 hubspotId: jsonPayment.data.statusId.hubspotId,
  //                 orderLineItems: jsonPayment?.data?.orderDetails?.orderLineItems,
  //                 base64Data: "",
  //                 invoiceUpdateNotes: "Payment Done",
  //                 invoiceUpdatedBy: "null",
  //               };
  
  //               const invoiceSaveResponse = await OrderServices.saveAllInvoice(PaidOrderdata);
  //               if (invoiceSaveResponse.data) {
  //                 const saveTransactionPayload = {
  //                   id: transactionData?.id,
  //                   transactionId: tempdata?.transactionId,
  //                   transactionDate: tempdata?.transactionDate,
  //                   transactionType: paymentMethod.toUpperCase() || "CARD",
  //                   transactionStatus: 31,
  //                   amount: invoiceSaveResponse.data?.totalAmount,
  //                   orderDetails: invoiceSaveResponse.data?.orderId,
  //                   invoiceDetails: invoiceSaveResponse.data,
  //                 };
  
  //                 await OrderServices.saveTransaction(saveTransactionPayload);
  //                 // Optionally, save invoice again if needed.
  //                 await OrderServices.saveAllInvoice(PaidOrderdata);
  //               }
  //             } else {
  //               const PaidOrderdata = {
  //                 id: invoiceResponse.data.id,
  //                 orderId: jsonPayment.data.orderDetails,
  //                 invoiceNo: `INV ${jsonPayment.data.orderDetails.orderId}`,
  //                 totalAmount: jsonPayment.data.orderDetails.orderAmount,
  //                 statusId: jsonPayment.data.statusId.id,
  //                 quickbooksId: invoiceResponse?.data?.quickbooksId,
  //                 hubspotId: jsonPayment.data.statusId.hubspotId,
  //                 orderLineItems: jsonPayment?.data?.orderDetails?.orderLineItems,
  //                 base64Data: "",
  //                 invoiceUpdateNotes: "Payment Done",
  //                 invoiceUpdatedBy: "null",
  //               };
  
  //               const invoiceSaveResponse = await OrderServices.saveAllInvoice(PaidOrderdata);
  //               if (invoiceSaveResponse.data) {
  //                 const saveTransactionPayload = {
  //                   id: transactionData?.id,
  //                   transactionId: tempdata?.transactionId,
  //                   transactionDate: tempdata?.transactionDate,
  //                   transactionType: paymentMethod.toUpperCase() || "CARD",
  //                   transactionStatus: 31,
  //                   amount: invoiceSaveResponse.data?.totalAmount,
  //                   orderDetails: invoiceSaveResponse.data?.orderId,
  //                   invoiceDetails: invoiceSaveResponse.data,
  //                 };
  //                 await OrderServices.saveTransaction(saveTransactionPayload);
  //               }
  //             }
  //           } else if (
  //             jsonPayment.data.statusId.id === 14 ||
  //             jsonPayment.data.statusId.id === 1003
  //           ) {
  //             // Under review case: wait for searchSession again before proceeding.
  //             const sessionResponse = await getSessionResponse(jsonPayment.data.orderDetails.orderId);
  //             setPaymentMethod(sessionResponse?.data?.paymentMethodType || "Card");
  
  //             // Update tempdata for under review case.
  //             tempdata.statusId = 9;
  //             tempdata.paymentMethod = sessionResponse?.data?.paymentMethodType || "Card";
  //             tempdata.orderUpdateNotes = "Order is under review.";
  //             tempdata.transactionId = paymentResponse.data.payment_intent;
  //             tempdata.generateReceipt = true;
  //             tempdata.orderLineItems = jsonPayment.orderLineItem;
  //             tempdata.orderUpdatedBy = jsonPayment.updatedBy;
  //             tempdata.stateId = jsonPayment.data.state ? jsonPayment.data.state.id : null;
  //             tempdata.countyId = jsonPayment.data.orderDetails ? jsonPayment.data.orderDetails.countyId : null;
  //             tempdata.transactionDate = convertTimestampToDate1(paymentResponse.data.created);
  
  //             const orderSaveResponse = await OrderServices.saveAllOrder(tempdata);
  //             setRedirectedData(orderSaveResponse.data.orderDetails?.id);
  //             setIsReviewDone(false);
  //             setOrderPayData(orderSaveResponse.data);
  //             setPayData(paymentResponse.data);
  //             localStorage.removeItem("payment");
  //             showToast("success", "Order updated successfully");
  
  //             // Handle invoice and transaction saving for the under review case.
  //             const invoiceResponse = await OrderServices.isInvoiceAvailable(jsonPayment.data.orderDetails.id);
  //             if (invoiceResponse.status === 204) {
  //               const PaidOrderdata = {
  //                 orderId: jsonPayment.data.orderDetails,
  //                 invoiceNo: `INV ${jsonPayment.data.orderDetails.orderId}`,
  //                 totalAmount: jsonPayment.data.orderDetails.orderAmount,
  //                 statusId: jsonPayment.data.statusId.id,
  //                 orderLineItems: jsonPayment?.data?.orderDetails?.orderLineItems,
  //                 base64Data: "",
  //                 invoiceUpdateNotes: "Payment Done",
  //                 invoiceUpdatedBy: "null",
  //               };
  
  //               const invoiceSaveResponse = await OrderServices.saveAllInvoice(PaidOrderdata);
  //               if (invoiceSaveResponse.data) {
  //                 const saveTransactionPayload = {
  //                   id: transactionData?.id,
  //                   transactionId: tempdata?.transactionId,
  //                   transactionDate: tempdata?.transactionDate,
  //                   transactionType: paymentMethod.toUpperCase() || "CARD",
  //                   transactionStatus: 31,
  //                   amount: invoiceSaveResponse.data?.totalAmount,
  //                   orderDetails: invoiceSaveResponse.data?.orderId,
  //                   invoiceDetails: invoiceSaveResponse.data,
  //                 };
  //                 await OrderServices.saveTransaction(saveTransactionPayload);
  //                 await OrderServices.saveAllInvoice(PaidOrderdata);
  //               }
  //             } else {
  //               const PaidOrderdata = {
  //                 id: invoiceResponse?.data?.id,
  //                 orderId: jsonPayment.data.orderDetails,
  //                 invoiceNo: `INV ${jsonPayment.data.orderDetails.orderId}`,
  //                 totalAmount: jsonPayment.data.orderDetails.orderAmount,
  //                 statusId: jsonPayment.data.statusId.id,
  //                 quickbooksId: invoiceResponse?.data?.quickbooksId,
  //                 hubspotId: jsonPayment.data.statusId.hubspotId,
  //                 orderLineItems: jsonPayment?.data?.orderDetails?.orderLineItems,
  //                 base64Data: "",
  //                 invoiceUpdateNotes: "Payment Done",
  //                 invoiceUpdatedBy: "null",
  //               };
  
  //               const invoiceSaveResponse = await OrderServices.saveAllInvoice(PaidOrderdata);
  //               if (invoiceSaveResponse.data) {
  //                 const saveTransactionPayload = {
  //                   id: transactionData?.id,
  //                   transactionId: tempdata?.transactionId,
  //                   transactionDate: tempdata?.transactionDate,
  //                   transactionType: paymentMethod.toUpperCase() || "CARD",
  //                   transactionStatus: 31,
  //                   amount: invoiceSaveResponse.data?.totalAmount,
  //                   orderDetails: invoiceSaveResponse.data?.orderId,
  //                   invoiceDetails: invoiceSaveResponse.data,
  //                 };
  //                 await OrderServices.saveTransaction(saveTransactionPayload);
  //               }
  //             }
  //           }
  //         }
  //       } catch (error) {
  //         showToast("error", "Order update failed");
  //       } finally {
  //         // Turn off refresh flag after processing completes.
  //         setTimeout(() => {
  //           setIsTabRefresh(false);
  //         }, 2000);
  //       }
  //     }
  
  //     // Start the payment processing with the provided sessionId.
  //     processPayment(sessionId);
  //   }
  // }, []);
  


  useEffect(() => {

    function withTimeout(promise, ms) {
      const timeout = new Promise((_, reject) =>
        setTimeout(() => reject(new Error("getSessionResponse timed out")), ms)
      );
      return Promise.race([promise, timeout]);
    }

    
    if (jsonPayment && sessionId && !paytData && !orderPayData && !isDataExist) {
      setIsDataExist(jsonPayment);
  
      // Helper function that returns the searchSession promise.
      function getSessionResponse(orderId) {
        return OrderServices.searchSession(orderId).catch((error) => {
          showToast("error", "Failed to get session details");
          return Promise.reject(error);
        });
      }
  
      function processPayment(sessionId) {
        setIsTabRefresh(true);
        const transactionData = JSON.parse(localStorage.getItem("transactiondata"));
        console.log(transactionData);
  
        OrderServices.paymentSession(sessionId)
          .then((paymentResponse) => {
            if (jsonPayment.data !== undefined && jsonPayment.data && !paytData && !orderPayData) {
              const tempdata = jsonPayment.data.orderDetails;
              // First call to searchSession.
              withTimeout(getSessionResponse(jsonPayment.data.orderDetails.orderId),2000)
                .then((sessionResponse) => {
                  const paymentMethodValue = sessionResponse?.data?.paymentMethodType || "Card";
  
                  // Normal payment success case.
                  if (
                    jsonPayment.data.statusId.id !== 14 &&
                    jsonPayment.data.statusId.id !== 1003
                  ) {
                    tempdata.transactionId = paymentResponse.data.payment_intent;
                    tempdata.generateReceipt = true;
                    tempdata.orderLineItems = jsonPayment.orderLineItem;
                    tempdata.orderUpdatedBy = jsonPayment.updatedBy;
                    tempdata.paymentMethod = paymentMethodValue;
                    tempdata.orderUpdateNotes = "Payment done successfully";
                    tempdata.stateId = jsonPayment.data.state ? jsonPayment.data.state.id : null;
                    tempdata.countyId = jsonPayment.data.orderDetails ? jsonPayment.data.orderDetails.countyId : null;
                    tempdata.transactionDate = convertTimestampToDate1(paymentResponse.data.created);
  
                    OrderServices.orderUpdate(tempdata)
                      .then((orderUpdateResponse) => {
                        setRedirectedData(orderUpdateResponse.data.orderDetails.id);
                        setIsReviewDone(false);
                        setOrderPayData(orderUpdateResponse.data);
                        setPayData(paymentResponse.data);
                        localStorage.removeItem("payment");
                        showToast("success", "Order updated successfully");
  
                        // Handle invoice and transaction saving.
                        OrderServices.isInvoiceAvailable(jsonPayment.data.orderDetails.id)
                          .then((invoiceResponse) => {
                            if (invoiceResponse.status === 204) {
                              const PaidOrderdata = {
                                orderId: jsonPayment.data.orderDetails,
                                invoiceNo: `INV ${jsonPayment.data.orderDetails.orderId}`,
                                totalAmount: jsonPayment.data.orderDetails.orderAmount,
                                statusId: jsonPayment.data.statusId.id,
                                hubspotId: jsonPayment.data.statusId.hubspotId,
                                orderLineItems: jsonPayment?.data?.orderDetails?.orderLineItems,
                                base64Data: "",
                                invoiceUpdateNotes: "Payment Done",
                                invoiceUpdatedBy: "null",
                              };
  
                              OrderServices.saveAllInvoice(PaidOrderdata)
                                .then((invoiceSaveResponse) => {
                                  if (invoiceSaveResponse.data) {
                                    const saveTransactionPayload = {
                                      id: transactionData?.id,
                                      transactionId: tempdata?.transactionId,
                                      transactionDate: tempdata?.transactionDate,
                                      transactionType: paymentMethod.toUpperCase() || "CARD",
                                      transactionStatus: 31,
                                      amount: invoiceSaveResponse.data?.totalAmount,
                                      orderDetails: invoiceSaveResponse.data?.orderId,
                                      invoiceDetails: invoiceSaveResponse.data,
                                    };
  
                                    OrderServices.saveTransaction(saveTransactionPayload)
                                      .then(() => {
                                        // Optionally, save invoice again if needed.
                                        OrderServices.saveAllInvoice(PaidOrderdata);
                                      })
                                      .catch(() => {});
                                  }
                                })
                                .catch(() => {});
                            } else {
                              const PaidOrderdata = {
                                id: invoiceResponse.data.id,
                                orderId: jsonPayment.data.orderDetails,
                                invoiceNo: `INV ${jsonPayment.data.orderDetails.orderId}`,
                                totalAmount: jsonPayment.data.orderDetails.orderAmount,
                                statusId: jsonPayment.data.statusId.id,
                                quickbooksId: invoiceResponse?.data?.quickbooksId,
                                hubspotId: jsonPayment.data.statusId.hubspotId,
                                orderLineItems: jsonPayment?.data?.orderDetails?.orderLineItems,
                                base64Data: "",
                                invoiceUpdateNotes: "Payment Done",
                                invoiceUpdatedBy: "null",
                              };
  
                              OrderServices.saveAllInvoice(PaidOrderdata)
                                .then((invoiceSaveResponse) => {
                                  if (invoiceSaveResponse.data) {
                                    const saveTransactionPayload = {
                                      id: transactionData?.id,
                                      transactionId: tempdata?.transactionId,
                                      transactionDate: tempdata?.transactionDate,
                                      transactionType: paymentMethod.toUpperCase() || "CARD",
                                      transactionStatus: 31,
                                      amount: invoiceSaveResponse.data?.totalAmount,
                                      orderDetails: invoiceSaveResponse.data?.orderId,
                                      invoiceDetails: invoiceSaveResponse.data,
                                    };
                                    OrderServices.saveTransaction(saveTransactionPayload).catch(() => {});
                                  }
                                })
                                .catch(() => {});
                            }
                          })
                          .catch(() => {});
                      })
                      .catch(() => {
                        showToast("error", "Order update failed");
                      });
                  }
                  // Under review case.
                  else if (
                    jsonPayment.data.statusId.id === 14 ||
                    jsonPayment.data.statusId.id === 1003
                  ) {
                    // Call searchSession again to ensure the response is fresh.
                    // getSessionResponse(jsonPayment.data.orderDetails.orderId)
                    withTimeout(getSessionResponse(jsonPayment.data.orderDetails.orderId),2000)

                      .then((sessionResponse) => {
                        setPaymentMethod(sessionResponse?.data?.paymentMethodType || "Card");
                        tempdata.statusId = 9;
                        tempdata.paymentMethod = sessionResponse?.data?.paymentMethodType || "Card";
                        tempdata.orderUpdateNotes = "Order is under review.";
                        tempdata.transactionId = paymentResponse.data.payment_intent;
                        tempdata.generateReceipt = true;
                        tempdata.orderLineItems = jsonPayment.orderLineItem;
                        tempdata.orderUpdatedBy = jsonPayment.updatedBy;
                        tempdata.stateId = jsonPayment.data.state ? jsonPayment.data.state.id : null;
                        tempdata.countyId = jsonPayment.data.orderDetails ? jsonPayment.data.orderDetails.countyId : null;
                        tempdata.transactionDate = convertTimestampToDate1(paymentResponse.data.created);
  
                        OrderServices.saveAllOrder(tempdata)
                          .then((orderSaveResponse) => {
                            setRedirectedData(orderSaveResponse.data.orderDetails?.id);
                            setIsReviewDone(false);
                            setOrderPayData(orderSaveResponse.data);
                            setPayData(paymentResponse.data);
                            localStorage.removeItem("payment");
                            showToast("success", "Order updated successfully");
  
                            OrderServices.isInvoiceAvailable(jsonPayment.data.orderDetails.id)
                              .then((invoiceResponse) => {
                                if (invoiceResponse.status === 204) {
                                  const PaidOrderdata = {
                                    orderId: jsonPayment.data.orderDetails,
                                    invoiceNo: `INV ${jsonPayment.data.orderDetails.orderId}`,
                                    totalAmount: jsonPayment.data.orderDetails.orderAmount,
                                    statusId: jsonPayment.data.statusId.id,
                                    orderLineItems: jsonPayment?.data?.orderDetails?.orderLineItems,
                                    base64Data: "",
                                    invoiceUpdateNotes: "Payment Done",
                                    invoiceUpdatedBy: "null",
                                  };
  
                                  OrderServices.saveAllInvoice(PaidOrderdata)
                                    .then((invoiceSaveResponse) => {
                                      if (invoiceSaveResponse.data) {
                                        const saveTransactionPayload = {
                                          id: transactionData?.id,
                                          transactionId: tempdata?.transactionId,
                                          transactionDate: tempdata?.transactionDate,
                                          transactionType: paymentMethod.toUpperCase() || "CARD",
                                          transactionStatus: 31,
                                          amount: invoiceSaveResponse.data?.totalAmount,
                                          orderDetails: invoiceSaveResponse.data?.orderId,
                                          invoiceDetails: invoiceSaveResponse.data,
                                        };
                                        OrderServices.saveTransaction(saveTransactionPayload)
                                          .then(() => {
                                            OrderServices.saveAllInvoice(PaidOrderdata);
                                          })
                                          .catch(() => {});
                                      }
                                    })
                                    .catch(() => {});
                                } else {
                                  const PaidOrderdata = {
                                    id: invoiceResponse?.data?.id,
                                    orderId: jsonPayment.data.orderDetails,
                                    invoiceNo: `INV ${jsonPayment.data.orderDetails.orderId}`,
                                    totalAmount: jsonPayment.data.orderDetails.orderAmount,
                                    statusId: jsonPayment.data.statusId.id,
                                    quickbooksId: invoiceResponse?.data?.quickbooksId,
                                    hubspotId: jsonPayment.data.statusId.hubspotId,
                                    orderLineItems: jsonPayment?.data?.orderDetails?.orderLineItems,
                                    base64Data: "",
                                    invoiceUpdateNotes: "Payment Done",
                                    invoiceUpdatedBy: "null",
                                  };
  
                                  OrderServices.saveAllInvoice(PaidOrderdata)
                                    .then((invoiceSaveResponse) => {
                                      if (invoiceSaveResponse.data) {
                                        const saveTransactionPayload = {
                                          id: transactionData?.id,
                                          transactionId: tempdata?.transactionId,
                                          transactionDate: tempdata?.transactionDate,
                                          transactionType: paymentMethod.toUpperCase() || "CARD",
                                          transactionStatus: 31,
                                          amount: invoiceSaveResponse.data?.totalAmount,
                                          orderDetails: invoiceSaveResponse.data?.orderId,
                                          invoiceDetails: invoiceSaveResponse.data,
                                        };
                                        OrderServices.saveTransaction(saveTransactionPayload).catch(() => {});
                                      }
                                    })
                                    .catch(() => {});
                                }
                              })
                              .catch(() => {});
                          })
                          .catch(() => {
                            showToast("error", "Order update failed");
                          });
                      })
                      .catch(() => {
                        showToast("error", "Failed to get session details");
                      });
                  }
                })
                .catch(() => {
                  showToast("error", "Failed to get session details");
                });
            }
          })
          .catch(() => {
            showToast("error", "Payment session failed");
          })
          .finally(() => {
            setTimeout(() => {
              setIsTabRefresh(false);
            }, 2000);
          });
      }
  
      processPayment(sessionId);
    }
  }, []);

  

  return (
    <div
      className="flex justify-content-center w-full"
      style={{ backdropFilter: "blur(10px)" }}
    >
      <Toast ref={toast} />

      {paytData && orderPayData ? (
        <main
          className="flex flex-col justify-content-center items-center px-4 pt-4 pb-12 rounded-lg shadow-lg font-fam-for-all max-w-[550px] max-md:px-5"
          style={{ backgroundColor: "#F6F8FE" }}
        >
          <div
            ref={printRef}
            className="flex flex-col w-full max-w-[604px] max-md:max-w-full"
          >
            <header className="flex flex-col w-full text-3xl font-semibold leading-none text-center text-gray-900 max-md:max-w-full">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4037cf5e45d4070eeb20b065c07dc779584b236aed93f5e32daa680d965b5c3?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
                alt=""
                className="object-contain self-center w-14 aspect-square"
              />
              <h3 className="mt-4 w-full max-md:max-w-full">
                Payment Successful
              </h3>
            </header>

            <section className="flex flex-col items-start mt-2 w-full text-base text-center text-gray-500 max-md:max-w-full">
              <div className="flex flex-wrap justify-center items-start self-stretch mt-1 w-full max-md:max-w-full">
                {/*
                 <div className="w-full">
                <p className="leading-6">Transaction ID:</p>
                <p className="font-bold break-words whitespace-normal">
                  {paytData.payment_intent}
                </p>
              </div> 
              */}
              </div>

              <div className="flex flex-wrap justify-center items-start self-stretch mt-1 w-full max-md:max-w-full">
                <div>
                  <p className="max-md:max-w-full mb-2">Amount</p>
                  <p className="mt-1 mb-2 text-2xl font-semibold text-blue-500 leading-none text-gray-900 max-md:max-w-full">
                    ${convertCentsToDollars(paytData.amount_total)}
                  </p>
                </div>
              </div>

              <div className="flex flex-wrap justify-center items-start self-stretch gap-4  w-full max-md:max-w-full">
                <p>{convertTimestampToDate(paytData.created)}</p>
                <p className="leading-6">
                  Payment Method{" "}
                  <span className="ml-1 font-bold capitalize">
                    {paymentMethod ? paymentMethod : "Card"}
                  </span>
                </p>
              </div>
            </section>

            <section className="flex flex-col mt-4 w-full max-md:max-w-full">
              <div className="flex flex-col w-full bg-white rounded-lg border border-gray-200 border-solid max-md:max-w-full">
                <header className="flex gap-1 items-center py-2 pr-5 pl-4 w-full text-sm font-medium leading-6 text-white bg-blue-600 rounded-lg border border-blue-300 border-solid max-md:max-w-full">
                  <h6 className="flex-1 shrink gap-4 self-stretch my-auto w-full min-w-[240px] max-md:max-w-full">
                    Payment Details for Order #
                    <span
                      style={{ color: "white", fontWeight: "900" }}
                      className="underline"
                    >
                      {orderPayData.orderDetails.orderId}
                    </span>
                  </h6>
                </header>

                <div className="flex flex-col p-4 w-full max-md:max-w-full">
                  <div className="flex flex-wrap justify-between items-center mb-1">
                    <p className="leading-2">Transaction ID:</p>
                    <p className=" break-words whitespace-normal">
                      {paytData.payment_intent}
                    </p>
                  </div>

                  <div className="flex flex-wrap justify-between items-center mb-1">
                    {/* <p>{orderPayData.orderDetails.orderTitle}</p> */}
                    <p className="leading-2">Order Type </p>
                    <span className="ml-1 ">
                      {orderPayData.orderTypeID.orderShortName}
                    </span>
                  </div>

                  <div className="flex flex-wrap justify-between items-center mb-1">
                    {/* <p>{orderPayData.orderDetails.orderShortName}</p> */}
                    <p className="leading-2">Entity Name </p>
                    <span className="ml-1 ">
                      {orderPayData.orderDetails.orderTitle}
                    </span>
                  </div>

                  <div className="flex flex-wrap justify-between items-center mb-1">
                    {/* <p>{orderPayData.orderDetails.state}</p> */}
                    <p className="leading-2">State </p>
                    <span className="ml-1 ">
                      {orderPayData.state.stateFullDesc}
                    </span>
                  </div>
                </div>
              </div>
            </section>
            <PaymentDetails
              data={orderPayData.orderLineItem}
              order={orderPayData.orderDetails}
            />
          </div>
          <div className="flex gap-4 items-start mt-2 w-full text-sm font-medium leading-none max-md:max-w-full">
            <div className="flex flex-wrap flex-1 shrink gap-3 items-start w-full basis-0 min-w-[240px] max-md:max-w-full">
              <button
                className="flex flex-1 shrink items-start whitespace-nowrap rounded-lg basis-0 min-w-[240px] text-slate-700"
                onClick={() => history("/order")}
              >
                <span className="flex overflow-hidden flex-1 shrink gap-2 justify-center items-center px-4 py-2.5 w-full bg-white-600 rounded-lg border border-blue-600 border-solid shadow-sm basis-0 min-w-[240px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    viewBox="0 0 1 20"
                    fill="none"
                  ></svg>
                  <span className="self-stretch my-auto">Close</span>
                </span>
              </button>
              <ReactToPrint
                trigger={() => (
                  <button className="flex flex-1 shrink items-start text-center text-white rounded-lg basis-0 min-w-[240px]">
                    <span className="flex overflow-hidden flex-1 shrink gap-2 justify-center items-center px-4 py-2.5 w-full bg-blue-600 rounded-lg border border-blue-600 border-solid shadow-sm basis-0 min-w-[240px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_2635_44422)">
                          <path
                            d="M4.99984 7.49984V1.6665H14.9998V7.49984M4.99984 14.9998H3.33317C2.89114 14.9998 2.46722 14.8242 2.15466 14.5117C1.8421 14.1991 1.6665 13.7752 1.6665 13.3332V9.1665C1.6665 8.72448 1.8421 8.30055 2.15466 7.98799C2.46722 7.67543 2.89114 7.49984 3.33317 7.49984H16.6665C17.1085 7.49984 17.5325 7.67543 17.845 7.98799C18.1576 8.30055 18.3332 8.72448 18.3332 9.1665V13.3332C18.3332 13.7752 18.1576 14.1991 17.845 14.5117C17.5325 14.8242 17.1085 14.9998 16.6665 14.9998H14.9998M4.99984 11.6665H14.9998V18.3332H4.99984V11.6665Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2635_44422">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="self-stretch my-auto">
                        Print receipt
                      </span>
                    </span>
                  </button>
                )}
                content={() => printRef.current}
              />
            </div>
          </div>
        </main>
      ) : (
        <main
          className="flex flex-col justify-between items-center px-4 pt-4 pb-12 rounded-lg shadow-lg font-fam-for-all max-w-[550px] max-md:px-5"
          style={{
            backgroundColor: "#F6F8FE",
            width: "500px",
            height: "800px",
          }}
        >
          <Skeleton className="mb-2"></Skeleton>
          <Skeleton width="10rem" className="mb-2"></Skeleton>
          <Skeleton width="5rem" className="mb-2"></Skeleton>
          <Skeleton height="2rem" className="mb-2"></Skeleton>
          <Skeleton width="10rem" height="4rem"></Skeleton>
          <Skeleton className="mb-2"></Skeleton>
          <Skeleton width="10rem" className="mb-2"></Skeleton>
          <Skeleton width="5rem" className="mb-2"></Skeleton>
          <Skeleton height="2rem" className="mb-2"></Skeleton>
          <Skeleton width="10rem" height="4rem"></Skeleton>
          <Skeleton className="mb-2"></Skeleton>
          <Skeleton width="10rem" className="mb-2"></Skeleton>
          <Skeleton width="5rem" className="mb-2"></Skeleton>
          <Skeleton height="2rem" className="mb-2"></Skeleton>
          <Skeleton width="10rem" height="4rem"></Skeleton>
        </main>
      )}
    </div>
  );
}

export default PaymentSuccess;
