import React from "react";
import { Modal } from "react-bootstrap";
import CommonTourBlue from "./../CommonTourComponent/CommonTourBlue";
// import CommonTourWhite from "./CommonTourComponent/CommonTourWhite";
import "./../CommonStyleTour/CommonStyleTour.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";
import OrderTourModal from "./../OrderTourModal";
import Slide_1_Dashboard_4 from "../../../Assets/Images/tour/Slide_1_Dashboard_4.png";
import slide_1_dashboard_3 from "../../../Assets/Images/tour/slide_1_dashboard_3.png";
import Slide_1_Dashboard_1 from "../../../Assets/Images/tour/Slide_1_Dashboard_1.png";
import Slide_1_Dashboard_2 from "../../../Assets/Images/tour/Slide_1_Dashboard_2.png";

const UserDashboardModal = (props) => {
  const [modalsecondShow, setModalSecondShow] = React.useState(false);

  const handleStartClick = () => {
    setModalSecondShow(true);
    props.onHide();
  };
  return (
    <section>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="common-modal-class"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Dashboard
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 1 Of 4</span>
                </div>
                <div>
                  <h4 className="tour-heading">Dashboard: Services Section</h4>
                  <p className="tour-text-holder">
                    This section prominently features the Company Formation
                    Service, allowing you to quickly start the process of
                    forming a business entity.
                  </p>
                </div>
                <div>
                  <img loading="lazy" 
                  // src='https://strapi.redberyltest.in/uploads/Slide_1_Dashboard_1_20603ec744.png' 
                  src={Slide_1_Dashboard_1}
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Use the <span className="blkkk-textt">Entity Type</span>{" "}
                      and{" "}
                      <span className="blkkk-textt">State of Formation</span>{" "}
                      dropdown menus to make your selections.
                    </li>
                    <li>
                      Click <span className="blkkk-textt"> Register Now</span>{" "}
                      to initiate the process.
                    </li>
                    <li>
                      Tabs below provide access to other services like
                      Compliance, Corporate Filings and more.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 2 Of 4</span>
                </div>
                <div>
                  <h4 className="tour-heading">Dashboard: Account Summary</h4>
                  <p className="tour-text-holder">
                    Get a quick overview of your account activity, including
                    total orders and their statuses. Easily track progress and
                    manage actions for draft, completed, or in-progress orders.
                  </p>
                </div>
                <div>
                  <img loading="lazy" 
                  // src="https://strapi.redberyltest.in/uploads/Slide_1_Dashboard_2_3c98df32d9.png" 
                  src={Slide_1_Dashboard_2}
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>Total Orders: Shows the number of orders processed.</li>
                    <li>
                      Status Breakdown: View orders in{" "}
                      <span className="blkkk-textt">
                        Draft, Created, Completed,
                      </span>{" "}
                      In Progress and more statuses.
                    </li>
                    <li>
                      Easily navigate through these categories to track progress
                      or manage actions.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                    <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 3 Of 4</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Dashboard: Invoices Due for Payment
                  </h4>
                  <p className="tour-text-holder">
                    Stay on top of your payments with a clear and organized list
                    of invoices due.
                  </p>
                </div>
                <div>
                  <img loading="lazy" 
                  // src="https://strapi.redberyltest.in/uploads/Slide_1_Dashboard_3_6c31c35481.png" 
                  src={slide_1_dashboard_3}
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Access a complete overview of invoices associated with
                      your orders.
                    </li>
                    <li>
                      Use filters to <span className="blkkk-textt"> sort </span>{" "}
                      and <span className="blkkk-textt">search </span> by Order
                      Number, State, Order Type, Entity Name, Order Date, Order
                      Amount, or Invoice Number.
                    </li>
                    <li>
                      <span className="blkkk-textt">Quickly </span> pay
                      outstanding invoices directly from the dashboard.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                    <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 4 Of 4</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Dashboard: Subscription Details
                  </h4>
                  <p className="tour-text-holder">
                    Stay informed about your active subscriptions, renewal
                    dates and mute notifications to streamline your
                    subscription management.
                  </p>
                </div>
                <div>
                  <img loading="lazy" 
                  // src="https://strapi.redberyltest.in/uploads/Slide_1_Dashboard_4_1a027a835d.png" 
                  src={Slide_1_Dashboard_4}
                  className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Get a{" "}
                      <span className="blkkk-textt">centralized view</span> of
                      all your subscription information.{" "}
                    </li>
                    <li>
                      Quickly see what services you're currently subscribed to,
                      when those subscriptions started and when they'll expire
                      and the associated order information.
                    </li>
                    <li>
                      Easy <span className="blkkk-textt">tracking</span> and{" "}
                      <span className="blkkk-textt">management</span> of your
                      ongoing services.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                    <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Modal.Body>
      </Modal>
      <OrderTourModal
        show={modalsecondShow}
        onHide={() => setModalSecondShow(false)}
      />
    </section>
  );
};

export default UserDashboardModal;
