import React from 'react';

function NotificationBanner() {
  return (
    <div className="flex flex-wrap gap-3 items-start p-4 w-full text-sm leading-none rounded-lg border border-blue-300 border-solid bg-slate-50 max-md:max-w-full">
      {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/df4d06639fb7a30182b5cbbddadfe6f13e80f65a703a69e436092e4d17c5d912?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0" className="object-contain shrink-0 w-5 aspect-square" alt="" /> */}
      <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
        <div className="font-medium text-blue-700 max-md:max-w-full">
          We've just released a new feature
        </div>
        <div className="mt-1 text-blue-600 max-md:max-w-full">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam.
        </div>
      </div>
      {/* <button aria-label="Close notification" className="object-contain shrink-0 w-5 aspect-square">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6c860be330861b7ee4a1d4b7f1b84251c5e656c082263a8af44657ca14a733ae?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0" alt="" />
      </button> */}
    </div>
  );
}

export default NotificationBanner;