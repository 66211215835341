import React from 'react';

function StatCard({ title, value}) {
  return (
    <div className="flex flex-col flex-1 shrink self-stretch p-4  bg-white rounded-lg border border-gray-200 border-solid shadow basis-0 min-w-[200px] max-md:px-5">
      <div className="flex gap-2 items-start w-full">
        <h3 className="flex-1 shrink text-base font-medium text-gray-900 basis-0">{title}</h3>
        {/* <button aria-label="More information" className="flex justify-content-end flex-col w-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M10.0003 10.8335C10.4606 10.8335 10.8337 10.4604 10.8337 10.0002C10.8337 9.53993 10.4606 9.16683 10.0003 9.16683C9.54009 9.16683 9.16699 9.53993 9.16699 10.0002C9.16699 10.4604 9.54009 10.8335 10.0003 10.8335Z" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.0003 5.00016C10.4606 5.00016 10.8337 4.62707 10.8337 4.16683C10.8337 3.70659 10.4606 3.3335 10.0003 3.3335C9.54009 3.3335 9.16699 3.70659 9.16699 4.16683C9.16699 4.62707 9.54009 5.00016 10.0003 5.00016Z" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.0003 16.6668C10.4606 16.6668 10.8337 16.2937 10.8337 15.8335C10.8337 15.3733 10.4606 15.0002 10.0003 15.0002C9.54009 15.0002 9.16699 15.3733 9.16699 15.8335C9.16699 16.2937 9.54009 16.6668 10.0003 16.6668Z" stroke="#98A2B3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </button> */}
      </div>
      <div className="flex gap-4 items-end mt-3 w-full">
        <div className="flex flex-col flex-1 shrink w-full basis-0">
          <div className="text-4xl font-semibold tracking-tighter leading-none text-gray-900">{value}</div>
        </div>
      </div>
    </div>
  );
}

export default StatCard;