import React, { useEffect } from "react";
import { useState, useRef, useContext } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
// import { InputGroup } from 'primereact/inputgroup';
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { Message } from "primereact/message";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import { useNavigate } from "react-router";
import { Checkbox } from "primereact/checkbox";
import Switch from "@mui/material/Switch";
import CreditCardServices from "../../services/strapiService";
import errorMessages, { globalMessages } from "../../utils/errorMessages";
import { Card } from "primereact/card";
import "../../flag.css";
import { Avatar } from "primereact/avatar";
import authContext from "../../common/authContext";
import ForgotPasswordService from "../../services/forgotPasswordService";
import { createSearchParams } from "react-router-dom";
import { showToast } from "../../utils/toastUtils";
import { checkDomain } from "../../utils/dnsEmailChecker";

export const AddUser = ({ vendorId }) => {
  

  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    signUpPayload,
    setSignUpPayload,
    setIsTabRefresh,
  } = useContext(authContext);

  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [value, setValue] = useState("");
  const [ingredient, setIngredient] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [cityErr, setcityErr] = useState("");
  const toast = useRef(null);
  const toastCenter = useRef(null);
  const [upload, setUpload] = useState("");
  const [selectedSkills, setSelectedSkills] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  const [firstName, setFirstName] = useState("");

  const [lastName, setLastName] = useState("");

  const [website, setWebsite] = useState("");

  const [company, setCompany] = useState("");

  const [phone, setPhone] = useState("");

  const [address, setAddress] = useState("");

  const [city, setCity] = useState("");

  const [state, setState] = useState("");

  const [zip, setZip] = useState("");

  const [emailErr, setEmailErr] = useState("");

  const [passwordErr, setPasswordErr] = useState("");

  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [firstNameErr, setFirstNameErr] = useState("");

  const [lastNameErr, setLastNameErr] = useState("");

  const [phoneErr, setPhoneErr] = useState("");

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [visible, setVisible] = useState(false);

  const [globalErr, setGlobalErr] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const [companyName, setCompanyName] = useState(null);

  const [comppanyErr, setCompanyErr] = useState("");

  const [checkboxErr, setCheckboxErr] = useState("");

  // const showCard = () => {
  //     setVisible(true);
  // };

  // const hideCard = () => {
  //     setVisible(false);
  // };

  const handleChange = (event) => {
    setCheckboxErr("");
    setChecked(event.target.checked);
  };

  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
  };

  // const dialogView = () => setVisible1(false)
  // const cancelView = () => {
  //     setcityErr('')
  //     setVisible1(false)

  // }

  let newObject = window.localStorage.getItem("user");
  let updatedUser = Object.assign({}, JSON.parse(newObject));

  useEffect(() => {
    setSelectedCountry({ name: "United States", code: "US", dialCode: "+1" });
  }, []);

  // const handleCity = (e) => {
  //   setEmailErr("");
  //   const value = e.target.value;
  //   if (value === "") {
  //     setEmailErr(errorMessages.emailRequired);
  //   }
  //   if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(value)) {
  //     setEmailErr(errorMessages.emailValidation);
  //   }
  //   setIngredient(e.target.value);
  // };

  
  const handleCity = async (e) => {
    setEmailErr('')
    const value = e.target.value
    setIngredient(e.target.value)
    if (value === "") {
        setEmailErr(errorMessages.emailRequired)
    }
    // if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
    //     setEmailErr(errorMessages.emailValidation);
    // }
    // const domain = value.split("@")[1]; 
    // const isDomainValid = await checkDomain(domain);
    // if (!isDomainValid) {
    //     setEmailErr("Please enter valid email address");
    // }
    
   
}

  const handleCompany = (e) => {
    setCompanyErr("");
    const value = e.target.value;
    if (value !== "") {
      if (!/^[a-zA-Z0-9\s\-.,&']+$/.test(value)) {
        setCompanyErr(errorMessages.companyNameValidation);
      }
    }

    setCompanyName(e.target.value);
  };

  const handleEmail = (e) => {
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setEmail(e.target.value);
  };

  const handleFirstName = (e) => {
    setFirstNameErr("");
    let value = e.target.value;
    if (value === "") {
      setFirstNameErr(errorMessages.firstNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/^[a-zA-Z]+([a-zA-Z]+)*$/.test(value)) {
        setFirstNameErr(errorMessages.firstNameValidation);
      }
    }
    setFirstName(e.target.value);
  };

  const handlePassword = (e) => {
    setPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setPasswordErr(errorMessages.passwordRequired);
    }
    if (value && value.trim() !== "") {
      // if (
      //   !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
      //     value
      //   )
      // ) {
      //   setPasswordErr(errorMessages.passwordValidation);
      // }
      let errors = [];

      if (!/[A-Z]/.test(value)) {
        errors.push("at least one uppercase letter");
      }
      if (!/[a-z]/.test(value)) {
        errors.push("at least one lowercase letter");
      }
      if (!/\d/.test(value)) {
        errors.push("at least one digit");
      }
      if (!/[@$!%*#?&_]/.test(value)) {
        errors.push("at least one special character (@$!%*#?&_)");
      }
      if (value.length < 8) {
        errors.push("a minimum length of 8 characters");
      }

      if (errors.length > 0) {
        setPasswordErr(`Password must contain ${errors.join(", ")}.`);
      }
    }

    setPassword(e.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const handleConfirmPassword = (e) => {
    setConfirmPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
    }

    if (value && value.trim() !== "") {
      // if (
      //   !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(
      //     value
      //   )
      // ) {
      //   setConfirmPasswordErr(errorMessages.confirmPasswordValidation);
      // }
      let errors = [];

      if (!/[A-Z]/.test(value)) {
        errors.push("at least one uppercase letter");
      }
      if (!/[a-z]/.test(value)) {
        errors.push("at least one lowercase letter");
      }
      if (!/\d/.test(value)) {
        errors.push("at least one digit");
      }
      if (!/[@$!%*#?&_]/.test(value)) {
        errors.push("at least one special character (@$!%*#?&_)");
      }
      if (value.length < 8) {
        errors.push("a minimum length of 8 characters");
      }

      if (errors.length > 0) {
        setConfirmPasswordErr(`Password must contain ${errors.join(", ")}.`);
      }
    }
    setConfirmPassword(e.target.value);
  };

  const handleLastName = (e) => {
    setLastNameErr("");
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/^[a-zA-Z]+([a-zA-Z]+)*$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setLastName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;

    let inputValue = value.replace(/\D/g, ""); // Remove non-digit characters

    if (inputValue.length > 10) {
        inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }

    let formattedValue = inputValue;
    if (inputValue.length === 10) {
        formattedValue = inputValue.replace(
            /(\d{3})(\d{3})(\d{4})/,
            "($1) $2-$3"
        );
    }

    // Update input field value and state
    e.target.value = formattedValue;
    setPhone(formattedValue);

    // Check if input is empty
    if (value.trim() === "") {
        setPhoneErr(errorMessages.phoneRequired);
        return; // Stop further execution
    }

    

    // Validate phone number format only if length is 10
    if (inputValue.length <= 9) {
        if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formattedValue)) {
            setPhoneErr(errorMessages.phoneValidation);
        }
    }
};

  // const handlePhone = (e) => {
  //   setPhoneErr("");
  //   let value = e.target.value;

  //   let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
  //   if (inputValue.length > 10) {
  //     inputValue = inputValue.slice(0, 10); // Limit to 10 digits
  //   }
  //   const formattedValue = inputValue.replace(
  //     /(\d{3})(\d{3})(\d{4})/,
  //     (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
  //   );
  //   if (inputValue.length === 10) {
  //     // Only show formatted value after 10 digits
  //     e.target.value = formattedValue;
  //     setPhone(formattedValue); // Update state with formatted number
  //   } else {
  //     e.target.value = inputValue;
  //     setPhone(inputValue);
  //   }

  //   if (value === "") {
  //     setPhoneErr(errorMessages.phoneRequired);
  //   }
  //   if (value && value.trim() !== "" && inputValue.length <= 9) {
  //     if (
  //       !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
  //         value
  //       )
  //     ) {
  //       setPhoneErr(errorMessages.phoneValidation);
  //     }
  //   }
  //   setPhone(e.target.value);
  // };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none">
    <circle cx="9.79519" cy="2.00625" r="2.00625" fill="#A3D5C9"/>
<circle cx="7.67096" cy="6.13675" r="1.06213" fill="#A3D5C9"/>
<circle cx="1.23915" cy="11.5064" r="1.23915" fill="#A3D5C9"/>
<circle cx="15.2829" cy="12.4506" r="1.47518" fill="#A3D5C9"/>
<circle cx="19.0004" cy="8.26101" r="1.06213" fill="#A3D5C9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6886 0.650298C15.7808 1.14561 15.3314 1.88426 15.3314 2.88125C15.3314 3.25051 15.4132 3.69897 15.5172 3.90007C15.6192 4.09751 15.6675 4.29412 15.6244 4.33672C15.5812 4.37932 14.4255 5.56549 13.0562 6.97258L10.5663 9.5309L10.2051 9.33783C9.72642 9.08209 8.5438 9.08256 8.0643 9.33889L7.70094 9.53302L6.31214 8.14057L4.92323 6.74811L5.10155 6.40316C5.40803 5.8106 5.4491 5.28143 5.23785 4.6484C4.87201 3.5524 4.02785 2.93907 2.885 2.93907C1.62401 2.93907 0.628439 3.83469 0.499331 5.08529C0.309446 6.92561 2.08686 8.27074 3.82911 7.60537L4.36643 7.40014L5.74319 8.76298L7.11995 10.1258L6.91815 10.5489C6.61756 11.1792 6.65663 12.0617 7.01421 12.7184C8.17748 14.8542 11.3541 14.1761 11.5336 11.7535C11.5703 11.2579 11.5252 10.9367 11.3712 10.5952L11.1581 10.1225L13.751 7.52949L16.3439 4.93659L16.8074 5.13037C17.5107 5.42411 18.5064 5.31601 19.138 4.87711C20.5128 3.92202 20.5099 1.83623 19.1323 0.87901C18.7142 0.58834 18.4875 0.520836 17.8341 0.49204C17.2702 0.467257 16.9408 0.512693 16.6886 0.650298Z" fill="white"/>
</svg>
 `;

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setIsDisabled(true);
  //   const actualdata = {
  //     properties: [
  //       { property: "firstname", value: firstName },
  //       { property: "lastname", value: lastName },
  //       { property: "website", value: website },
  //       { property: "company", value: company },
  //       { property: "phone", value: phone },
  //       { property: "address", value: address },
  //       { property: "city", value: city },
  //       { property: "state", value: state },
  //       { property: "zip", value: zip },
  //     ],
  //   };

  //   const tempActualData = {
  //     salution: null,
  //     username: ingredient,
  //     password: password,
  //     firstName: firstName,
  //     lastName: lastName,
  //     mobileNo: `${phone}`,
  //     rolesId: 2,
  //     statusId: 1,
  //     companyName: companyName && companyName !== "" ? companyName : null,
  //     affiliationCode: vendorId ? vendorId : null,
  //   };

  //   
  //   if (
  //     ingredient !== "" &&
  //     password !== "" &&
  //     firstName !== "" &&
  //     lastName !== "" &&
  //     phone !== "" &&
  //     confirmedPassword !== "" &&
  //     companyName &&
  //     password === confirmedPassword &&
  //     passwordErr === "" &&
  //     confirmPasswordErr === "" &&
  //     firstNameErr === "" &&
  //     lastNameErr === "" &&
  //     emailErr === "" &&
  //     phoneErr === "" &&
  //     checked
  //   ) {
  //     setIsLoading(true);
  //     setSignUpPayload(tempActualData);

  //     localStorage.setItem("usermail", JSON.stringify(ingredient));
  //     ForgotPasswordService.validateEmail(
  //       ingredient,
  //       `${firstName} ${lastName}`
  //     )
  //       .then((res) => {
  //         
  //         setIsTabRefresh(false);
  //         if (res.status === 201) {
  //           if (res.data === "We have send OTP to your email") {
  //             // if (toast.current !== null) {
  //             //   toast.current.show({
  //             //     severity: "success",
  //             //     summary: "Info",
  //             //     detail: "OTP Sent  To Your Register Mail Address",
  //             //     life: 2800,
  //             //   });
  //             // }
  //             showToast("success", "OTP sent to your registered mail address.");
  //           }
  //           setIsDisabled(false);
  //           setIsLoading(false);

  //           const param = {
  //             name: `${firstName} ${lastName}`,
  //           };

  //           navigate({
  //             pathname: "/otp",
  //             search: `?${createSearchParams(param)}`,
  //           });
  //         }
  //       })
  //       .catch((err) => {
  //         
  //         setIsLoading(false);

  //         setIsDisabled(false);
  //         if (err.response !== undefined) {
  //           if (err.response.status !== undefined) {
  //             if (err.response.status === 404) {
  //               // if (toast.current !== null) {
  //               //   toast.current.show({
  //               //     severity: "warn",
  //               //     summary: "Oops",
  //               //     detail: "Email is not registered",
  //               //     life: 2800,
  //               //   });
  //               // }
  //               showToast("error", "Email is not registered");
  //             }
  //           }

  //           if (err.response.status !== undefined) {
  //             if (err.response.status === 500) {
  //               // if (toast.current !== null) {
  //               //   toast.current.show({
  //               //     severity: "warn",
  //               //     summary: "Oops",
  //               //     detail: "Something went wrong",
  //               //     life: 2800,
  //               //   });
  //               // }
  //               showToast("error", "Something went wrong");
  //             }
  //           }

  //           if (err.response !== undefined) {
  //             if (err.response.status === 700) {
  //               if (err.response.data !== undefined) {
  //                 // if (toast.current !== undefined) {
  //                 //   toast.current.show({
  //                 //     severity: "warn",
  //                 //     summary: "Warning",
  //                 //     detail: `${errorMessages.emailValidation}`,
  //                 //     life: 3000,
  //                 //   });
  //                 // }
  //                 showToast("error", `${errorMessages.emailValidation}`);
  //               }
  //             }
  //           }
  //         }
  //       });
  //   } else {
  //     setIsDisabled(false);
  //     if (ingredient === "") {
  //       
  //       setEmailErr(errorMessages.emailRequired);
  //     }
  //     if (password === "") {
  //       setPasswordErr(errorMessages.passwordRequired);
  //     }
  //     if (firstName === "") {
  //       setFirstNameErr(errorMessages.firstNameRequired);
  //     }
  //     if (lastName === "") {
  //       setLastNameErr(errorMessages.lastNameRequired);
  //     }
  //     if (phone === "") {
  //       setPhoneErr(errorMessages.phoneRequired);
  //     }
  //     if (confirmedPassword === "") {
  //       setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
  //     }
  //     if (!companyName) {
  //       setCompanyErr("Company name required");
  //     }
  //     if (!checked) {
  //       setCheckboxErr("To proceed, please acknowledge our Privacy Statement.");
  //     }
  //     if (
  //       password &&
  //       confirmedPassword &&
  //       password !== confirmedPassword &&
  //       passwordErr === "" &&
  //       confirmPasswordErr === ""
  //     ) {
  //       // setVisible(true)
  //       // if (toast.current !== undefined) {
  //       // toast.current.show({
  //       //   severity: "warn",
  //       //   summary: "Warning",
  //       //   content: (props) => (
  //       //     <div
  //       //       className="flex flex-column align-items-left"
  //       //       style={{ flex: "1" }}
  //       //     >
  //       //       <div className="flex align-items-center gap-2">
  //       //         <Avatar
  //       //           shape="circle"
  //       //           style={{ backgroundColor: "#0047FF", color: "#ffffff" }}
  //       //         >
  //       //           <div dangerouslySetInnerHTML={{ __html: svgContent }} />
  //       //         </Avatar>
  //       //         <span className="p-toast-summary">vState Filings</span>
  //       //       </div>
  //       //       <div className="p-toast-detail">
  //       //         {errorMessages.passwordMatched}
  //       //       </div>
  //       //     </div>
  //       //   ),
  //       //   life: 2800,
  //       // });

  //       // }
  //       // showToast("error", `${errorMessages.passwordMatched}`);
  //       // setGlobalErr(errorMessages.passwordMatched)
  //     }
  //   }
  // };

  const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true);
    
  
    // Extract the domain from the email (ingredient)
    const domain = ingredient.split('@')[1];
    if (!domain) {
      setEmailErr(errorMessages.emailValidation);
      setIsDisabled(false);
      return;
    }

    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(ingredient)) {
      setEmailErr(errorMessages.emailValidation);
      setIsDisabled(false);
      return;
    }
  
    // Check if the domain is valid
    const isDomainValid = await checkDomain(domain);
    if (!isDomainValid) {
      setEmailErr(errorMessages.emailValidation);

      setIsDisabled(false);
      return;
    }
  
    // Proceed only if all validations pass
    if (
      ingredient !== "" &&
      password !== "" &&
      firstName !== "" &&
      lastName !== "" &&
      phone !== "" &&
      confirmedPassword !== "" &&
      companyName &&
      password === confirmedPassword &&
      passwordErr === "" &&
      confirmPasswordErr === "" &&
      firstNameErr === "" &&
      lastNameErr === "" &&
      emailErr === "" &&
      phoneErr === "" &&
      checked
    ) {
      setIsLoading(true);
      setSignUpPayload({
        salution: null,
        username: ingredient,
        password: password,
        firstName: firstName,
        lastName: lastName,
        mobileNo: `${phone}`,
        rolesId: 2,
        statusId: 1,
        companyName: companyName && companyName !== "" ? companyName : null,
        affiliationCode: vendorId ? vendorId : null,
      });
  
      localStorage.setItem("usermail", JSON.stringify(ingredient));
  
      try {
        const res = await ForgotPasswordService.validateEmail(
          ingredient,
          `${capitalize(firstName)} ${capitalize(lastName)}`
        );
        
        setIsTabRefresh(false);
        if (res.status === 201 && res.data === "We have send OTP to your email") {
          showToast("success", "OTP sent to your registered mail address.");
        }
        setIsDisabled(false);
        setIsLoading(false);
        navigate({
          pathname: "/otp",
          search: `?${createSearchParams({ name: `${firstName} ${lastName}` })}`,
        });
      } catch (err) {
        
        setIsLoading(false);
        setIsDisabled(false);
        if (err.response) {
          if (err.response.status === 404) {
            showToast("error", "Email is not registered");
          } else if (err.response.status === 500) {
            showToast("error", "Something went wrong");
          } else if (err.response.status === 700 || err.response.status === 701 && err.response.data) {
            // showToast("error", err?.response?.data?.errorMessage);
            showToast("error", "User already exist")
          }
        }
      }
    } else {
      setIsDisabled(false);
      // Set error messages for missing or invalid fields
      if (ingredient === "") {
        setEmailErr(errorMessages.emailRequired);
      }
      if (password === "") {
        setPasswordErr(errorMessages.passwordRequired);
      }
      if (firstName === "") {
        setFirstNameErr(errorMessages.firstNameRequired);
      }
      if (lastName === "") {
        setLastNameErr(errorMessages.lastNameRequired);
      }
      if (phone === "") {
        setPhoneErr(errorMessages.phoneRequired);
      }
      if (confirmedPassword === "") {
        setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
      }
      if (!companyName) {
        setCompanyErr("Company name required");
      }
      if (!checked) {
        setCheckboxErr("To proceed, please acknowledge our Privacy Statement.");
      }

    }
  };

  

  const handleClick = () => {
    setcityErr("");
    // setVisible1(false)
  };

  // handle header form
  const handleHeader = () => {
    return (
      <>
        <div className="font-fam-for-all form-sm-heading form-sm-close-Btn">
          Update City
          <button className="form-close-Btn" onClick={handleClick}>
            <i className="pi pi-times"></i>
          </button>
        </div>
      </>
    );
  };

  const handleCopy = (event) => {
    event.preventDefault();
  };

  const handleCut = (event) => {
    event.preventDefault();
  };

  const handlePaste = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <form id="myform" onSubmit={handleSubmit}>
        <Toast ref={toast} />
        <Toast ref={toastCenter} position="center" />
        <div className="form-content-signup">
          <div className="grid gap-3 justify-content-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className=" justify-content-center dialog-form-field-space">
                <div className="field">
                  {/* <label htmlFor='' className='form-label font-fam-for-all'>First Name <span className='form-field-mandatory'>*</span></label> */}
                  {/* <span className="p-float-label ">

                                        <div className="floating-label-input">
                                            <span className={`p-float-label ${value ? 'p-input-filled' : ''}`}>
                                                <InputText
                                                    // className='dialog-form-input-field'
                                                    style={{ height: '40px', width: "100%" }}
                                                    maxLength={50} value={firstName} onChange={(e) => setFirstName(e.target.value)} name='cityName'
                                                />
                                                <label htmlFor='input' className='form-label font-fam-for-all'>First Name <span className='form-field-mandatory'>*</span></label>
                                            </span>
                                        </div>
                                    </span> */}

                  <span className=" ">
                    <div class="relative">
                      <InputText
                        // className='dialog-form-input-field'
                        id="floating_outlined1"
                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        style={{ height: "40px", width: "100%" }}
                        placeholder=""
                        maxLength={50}
                        value={firstName}
                        onChange={handleFirstName}
                        name="firstName"
                      />
                      <label
                        htmlFor="floating_outlined1"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        First Name
                        <span className="form-field-mandatory">*</span>
                      </label>
                    </div>
                    {firstNameErr !== "" ? (
                      <p className="error-msg font-fam-for-all">
                        {firstNameErr}
                      </p>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-5">
              <div className=" justify-content-center dialog-form-field-space">
                <div className="field">
                  {/* <label htmlFor='' className='form-label font-fam-for-all'>Last Name <span className='form-field-mandatory'>*</span></label> */}
                  <div class="relative">
                    <InputText
                      // className='dialog-form-input-field'
                      id="floating_outlined2"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      style={{ height: "40px", width: "100%" }}
                      maxLength={50}
                      value={lastName}
                      onChange={handleLastName}
                      name="lastName"
                      placeholder=""
                    />

                    <label
                      htmlFor="floating_outlined2"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Last Name<span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {lastNameErr !== "" ? (
                    <p className="error-msg font-fam-for-all">{lastNameErr}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className=" justify-content-center dialog-form-field-space grid  mt-2">
            <div className="field col-12 col-sm-12 col-md-12 col-lg-10 p-0">
              {/* <label htmlFor='' className='form-label font-fam-for-all'>Email <span className='form-field-mandatory'>*</span></label> */}
              {/* <span className="p-float-label ">



                                <div className="floating-label-input">
                                    <span className={`p-float-label ${value ? 'p-input-filled' : ''}`}>
                                        <InputText
                                            style={{ width: '100%', height: '40px' }}
                                            maxLength={50} value={ingredient} onChange={handleCity} name='cityName'
                                        />
                                        <label htmlFor='input' className='form-label font-fam-for-all'>Email <span className='form-field-mandatory'>*</span></label>
                                    </span>
                                </div>


                                <p className='error-msg font-fam-for-all'>{emailErr}</p>
                            </span> */}

              <span className=" ">
                <div class="relative">
                  <InputText
                    // className='dialog-form-input-field'
                    id="floating_outlined3"
                    className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    style={{ width: "100%", height: "40px" }}
                    placeholder=""
                    maxLength={50}
                    value={ingredient}
                    onChange={handleCity}
                    name="email"
                  />

                  <label
                    htmlFor="floating_outlined3"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Email<span className="form-field-mandatory">*</span>
                  </label>
                </div>
                {emailErr !== "" ? (
                  <p className="error-msg font-fam-for-all">{emailErr}</p>
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>

          <div className="justify-content-center dialog-form-field-space grid mt-3">
            <div className="field col-12 col-sm-12 col-md-12 col-lg-10 p-0">
              <div
                className="p-inputgroup flex-1"
                style={{ height: "40px", width: "100%" }}
              >
                <Dropdown
                  value={selectedCountry}
                  options={countries}
                  onChange={onCountryChange}
                  resetFilterOnHide
                  optionLabel="code"
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                  style={{ width: "160px", borderRadius: "6px 0px 0px 6px" }}
                  className="p-dropdown font-fam-for-all"
                />
                {/* <span className="p-float-label">
                                    <InputText
                                        style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                        maxLength={50}
                                        
                                        value={phone}
                                        onChange={handlePhone}
                                        name="cityName"
                                    />
                                    <label htmlFor="input" className="form-label font-fam-for-all">
                                        Phone <span className="form-field-mandatory">*</span>
                                    </label>



                                </span> */}
                <span className="" style={{ width: "100%" }}>
                  <div className="relative custom-input-mask">
                    <input
                      type="text"
                      id="floating_outlined67"
                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                      style={{
                        height: "40px",
                        borderRadius: "0px 6px 6px 0px",
                        width: "100%",
                      }}
                      maxLength={50}
                      value={phone}
                      onChange={handlePhone}
                      autoClear={false}
                      placeholder=""
                      name="phone"
                    />
                    <label
                      htmlFor="floating_outlined67"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Phone<span className="form-field-mandatory">*</span>
                    </label>
                  </div>
                  {phoneErr !== "" ? (
                    <p className="error-msg font-fam-for-all">{phoneErr}</p>
                  ) : (
                    ""
                  )}
                </span>
              </div>
              {/* <p className='error-msg font-fam-for-all'>{phoneErr}</p> */}
            </div>
          </div>

          <div className=" justify-content-center dialog-form-field-space grid  mt-3">
            <div className="field col-12 col-sm-12 col-md-12 col-lg-10 p-0">
              <span className=" ">
                <div class="relative">
                  <InputText
                    // className='dialog-form-input-field'
                    id="floating_outlined61"
                    className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    style={{ width: "100%", height: "40px" }}
                    placeholder=""
                    maxLength={50}
                    value={companyName}
                    onChange={handleCompany}
                    name="company"
                  />

                  <label
                    htmlFor="floating_outlined61"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Company Name<span className="form-field-mandatory">*</span>
                  </label>
                </div>
                {comppanyErr !== "" ? (
                  <p className="error-msg font-fam-for-all">{comppanyErr}</p>
                ) : (
                  ""
                )}
              </span>
            </div>
          </div>

          <div className="flex flex-col">
            {/* Password Input */}
            <div className="flex justify-center dialog-form-field-space grid mt-3">
              <div className="field col-12 col-sm-12 col-md-12 col-lg-10 p-0">
                <span className=" ">
                  <div className="relative w-full">
                    {/* <input
                      id="floating_outlined6"
                      className={`block w-full px-2.5 pb-2.5 pt-2 pr-10 h-10 text-sm text-gray-900 bg-transparent rounded-lg border appearance-none dark:text-white focus:outline-none focus:ring-0 peer ${
                        passwordErr
                          ? "border-red-500 focus:border-red-500 dark:border-red-500 dark:focus:border-red-500"
                          : "border-gray-300 focus:border-blue-600 dark:border-gray-600 dark:focus:border-blue-500"
                      }`}
                      placeholder=""
                      type={passwordType}
                      value={password}
                      onChange={handlePassword}
                    /> */}

<input
  id="floating_outlined6"
  className={`block w-full px-2.5 pb-2.5 pt-2 pr-10 h-10 text-sm text-gray-900 bg-transparent rounded-lg border appearance-none dark:text-white focus:outline-none focus:ring-0 peer ${
    passwordErr
      ? "border-red-500 focus:border-red-500 dark:border-red-500 dark:focus:border-red-500"
      : "border-gray-300 focus:border-blue-600 dark:border-gray-600 dark:focus:border-blue-500"
  }`}
  placeholder=""
  type={passwordType}
  value={password}
  onChange={handlePassword}
  onCopy={(e) => e.preventDefault()}
  onCut={(e) => e.preventDefault()}
  onPaste={(e) => e.preventDefault()}
  onContextMenu={(e) => e.preventDefault()} // Prevent right-click
/>

                    <label
                      htmlFor="floating_outlined6"
                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >
                      Password<span className="form-field-mandatory">*</span>
                    </label>

                    {/* Eye Icon */}
                    <i
                      className={`absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer pi ${
                        passwordType === "password" ? "pi-eye" : "pi-eye-slash"
                      }`}
                      style={{ fontSize: "1rem" }}
                      onClick={togglePassword}
                    ></i>
                  </div>
                  {passwordErr && (
                    <p className="error-msg font-fam-for-all w-[400px]">
                      {passwordErr}
                    </p>
                  )}
                </span>
              </div>
            </div>

            {/* Confirm Password Input */}
            <div className="flex justify-center dialog-form-field-space grid mt-3">
              <div className="field col-12 col-sm-12 col-md-12 col-lg-10 p-0">
                <div className="relative">
                  <input
                    id="floating_outlined7"
                    className={`block w-full px-2.5 pb-2.5 pt-2 pr-10 h-10 text-sm text-gray-900 bg-transparent rounded-lg border appearance-none dark:text-white focus:outline-none focus:ring-0 peer ${
                      confirmPasswordErr ||
                      (password !== confirmedPassword &&
                        confirmedPassword &&
                        password)
                        ? "border-red-500 focus:border-red-500 dark:border-red-500 dark:focus:border-red-500"
                        : "border-gray-300 focus:border-blue-600 dark:border-gray-600 dark:focus:border-blue-500"
                    }`}
                    placeholder=""
                    type={passwordType1}
                    value={confirmedPassword}
                    style={{ width: "100%", height: "40px" }}
                    onChange={handleConfirmPassword}
                  />
                  <label
                    htmlFor="floating_outlined7"
                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                  >
                    Confirm Password
                    <span className="form-field-mandatory">*</span>
                  </label>

                  {/* Eye Icon */}
                  <i
                    className={`absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer pi ${
                      passwordType1 === "password" ? "pi-eye" : "pi-eye-slash"
                    }`}
                    style={{ fontSize: "1rem" }}
                    onClick={togglePassword1}
                  ></i>
                </div>

                {confirmPasswordErr !== "" ? (
                  <p className="error-msg font-fam-for-all w-[400px] ">
                    {confirmPasswordErr}
                  </p>
                ) : password !== confirmedPassword &&
                  password &&
                  confirmedPassword ? (
                  <p className="error-msg font-fam-for-all w-[400px] ">
                    Passwords do not match
                  </p>
                ) : password === confirmedPassword &&
                  password &&
                  confirmedPassword ? (
                  <p className="text-green-500 success-msg font-fam-for-all">
                    Passwords matched
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="grid justify-content-start ml-4 mt-3 mb-3">
          <div className="ml-3 p-0">
            <div className="flex justify-content-center">
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />

              <label
                htmlFor="binary"
                className="font-fam-for-all mx-2 srch-cand-text w-[400px] "
              >
                I accept the{" "}
                <span
                  class="text-blue-600 hover:text-blue-500 hover:underline cursor-pointer"
                  onClick={() => window.open("/#/privacy", "_blank")}
                >
                  Privacy Statement
                </span>
                . To proceed, please acknowledge our privacy statement by
                selecting the checkbox.
              </label>
            </div>
            {checkboxErr !== "" ? (
              <p className="error-msg font-fam-for-all mb-1 w-[400px]">
                {checkboxErr}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="grid justify-content-center">
          <div className="col-12 col-sm-12 col-md-12 col-lg-10 p-0">
            <div className="flex justify-content-center">
              <Button
                type="submit"
                className="signup-btn vstate-button font-fam-for-all"
                disabled={isDisabled}
                style={{ width: "400px" }}
              >
                Create Account
              </Button>
              {/* <Button type='button' className="mt-2 form-Btn form-Btn-Label font-fam-for-all text-center text-lg bg-primary-reverse mr-2" onClick={cancelView} >Cancel</Button> */}
            </div>
          </div>
        </div>

        <div className="flex justify-content-center mt-3 dialog-form-md-group-Btn">
          <span className="font-fam-for-all " style={{ fontSize: "14px" }}>
            Already have an account?{" "}
            <a
              href="/#/signin"
              className="font-fam-for-all text-blue-500 hover:text-blue-700"
            >
              {" "}
              Sign in{" "}
            </a>
          </span>
        </div>
        <div className="flex justify-content-center dialog-form-md-group-Btn mt-3">
          <span className="font-fam-for-all" style={{ fontSize: "14px" }}>
            Need Support?
            <a href="mailto:support@vstatefilings.com" className="inline-flex">
              <button
                type="button"
                className="font-fam-for-all text-blue-500 hover:text-blue-700 ms-1"
              >
                Contact us
              </button>
            </a>
          </span>
        </div>
      </form>

      <style>
        {`
          .error-card-container {
            position: fixed;
            top: 20px;
            right: 20px;
            z-index: 1000;
            animation: fadeIn 0.5s;
            border-radius: 8px;
          }
          .error-card {
            width: 300px;
            
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            animation: slideIn 0.5s;
          }
          .error-card p {
            color: #ff4d4d;
          }
          @keyframes fadeIn {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @keyframes slideIn {
            from {
              transform: translateX(100%);
            }
            to {
              transform: translateX(0);
            }
          }
        `}
      </style>
    </div>
  );
};