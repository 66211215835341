import React from 'react';

const Dashboard = () => {
  return (
    <section className="flex flex-col px-8 pt-20 mt-24 max-w-full rounded-lg w-[1240px] max-md:px-5 max-md:mt-10">
      <div className="flex justify-center items-center px-16 pt-20 mt-24 max-w-full bg-violet-50 rounded-lg w-[1200px] max-md:px-5 max-md:mt-10">
        <div className="flex flex-col flex-wrap justify-center px-1.5 pt-1.5 mt-8 max-w-full bg-white rounded-lg shadow-lg w-[760px]">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5af064a43855c9a1ce38df2f77c9314f46b14782b817033dabb655bf1b4cea8?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="w-full aspect-[2.08] max-w-[748px] max-md:max-w-full" alt="Dashboard preview" />
        </div>
      </div>
      <div className="flex justify-center items-center px-16 py-3 mt-28 max-w-full text-center w-[1140px] max-md:px-5 max-md:mt-10">
        <div className="flex flex-col items-center max-w-full w-[569px]">
          <h2 className="text-5xl font-bold tracking-tighter text-blue-700 capitalize leading-[52.8px] max-md:max-w-full">
            <span className="font-medium font-web-color1">Easy to use </span>
            <span className="font-medium font-web-color">Dashboard</span>
          </h2>
          <p className="self-stretch mt-3 text-lg font-light leading-7 text-slate-700 max-md:max-w-full">
            We are the only platform to offer a user friendly dashboard with essential services like company formation, registered agent service, non-profit services, document retrieval and attorneys!
          </p>
          <button className="flex flex-col justify-center px-4 py-3 mt-7 max-w-full text-base font-medium leading-4 text-white capitalize bg-web-color rounded border-2 border-white border-solid shadow-sm w-[190px] max-md:px-5">
            <div className="flex gap-0 justify-between py-px">
              <div>register now</div>
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/32126a7af913a0e697c2caaa8aecdc14109c7421ccd700443351742ec0439994?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="shrink-0 self-start aspect-square" alt="" />
            </div>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;