import React, { memo, useContext, useEffect, useState } from "react";
import Logo from "../components/Website/Logo";
import pic1 from "../Assets/Images/Test Joint Logo 5 (1).png";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import { OverlayPanel } from "primereact/overlaypanel";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import logoutService from "../services/logoutService";
import ContactService from "../services/ContactService";
import authContext from "./authContext";
import companyMasterService from "../services/companyMasterService";
import { Dropdown } from "primereact/dropdown";
import UserServices from "../services/userService";
import MasterServices from "../services/coreServices";
import Offcanvas from "react-bootstrap/Offcanvas";
import Sidesbar from "./Sidesbar";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { commonGetInitials } from "../utils/getInitials";
import { PrimeIcons } from 'primereact/api';

const Headers = memo (() => {
  const overlayPanel = useRef(null);
  const history = useNavigate();

  const {
    globalCompany,
    setGlobalCompany,
    setIsTabRefresh,
    isTabRefresh,
    setLoginUserName,
    isLoading,
    fileBase64,
    setFileBase64,
    setIsLoading,
    clearData,
    orderContinue,
    loginUserName
  } = useContext(authContext);

  const [data, setData] = useState(null);

  const clientName = "John Doe";
  const roles = ["Admin", "User"];

  const [products, setProducts] = useState([]);

  const [userName, setUserName] = useState("");

  const [email, setEmail] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  const [allCompany, setAllCompany] = useState([]);

  const [defaultCompany, setDefaultCompany] = useState(null);

  const [IsEmployee, setIsEmployee] = useState(null);

  const [filesBase64, setFilesBase64] = useState(null);

  const [superAdminId, setSuperAdminId] = useState(null);

  const [allParentCompany, setAllParentCompany] = useState([]);

  const [IsRequest, setIsRequest] = useState(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function checkIdsExist(arr, idsToCheck) {
    const foundIds = idsToCheck.filter((idToCheck) =>
      arr.some((item) => item.id === idToCheck)
    );
    return foundIds.length > 0 ? foundIds : null;
  }
  
  const [badgeCount, setBadgeCount] = useState(0);
  
  useEffect(() => {
    if (user?.companyRoles?.length) {
      const allRoles = user.companyRoles.flatMap((companyRole) => companyRole.roles || []);
  
      const id = checkIdsExist(allRoles, [1, 4]);
      const empId = checkIdsExist(allRoles, [4]);
  
      
      setSuperAdminId(id);
  
      if (empId) {
        setIsEmployee(empId);
      }
    }
  }, []);
  

  useEffect(() => {
    setIsTabRefresh(true);
    if (allParentCompany) {
      ContactService.getAllContactByUserId(user?.id)
        .then((res) => {
          
          let data = res.data;

          if (user && allParentCompany) {
            if (Array.isArray(user.companyRoles)) {
              const filteredData = user.companyRoles.filter((val) =>
                allParentCompany.some(
                  (ele) => ele?.company?.id === val?.companyDetails?.id
                )
              );
              setAllCompany(filteredData);
              if (Array.isArray(filteredData)) {
                if (filteredData.length > 0) {
                  
                  setGlobalCompany(filteredData[0]);
                  setIsTabRefresh(false);
                }
              }
            }
          }

          if (res.data) {
            setUserName(
              `${res.data?.contactDetails?.firstName} ${res.data?.contactDetails?.lastName}`
            );
            setLoginUserName(
              `${res.data?.contactDetails?.firstName} ${res.data?.contactDetails?.lastName}`
            );
            setEmail(res.data?.contactDetails?.emailId);
            setData(res.data);

            if (res.data?.contactDetails?.avatarImgPath) {
              MasterServices.getFile(res.data?.contactDetails?.avatarImgPath)
                .then((res) => {
                  
                  setFilesBase64(res.data);
                  setFileBase64(res.data);
                  localStorage.setItem("avatar", res.data);
                })
                .catch((err) => {
                  
                });
            } else {
              setFilesBase64(null);
              setFileBase64(null);
            }
          }
        })
        .catch((err) => {
          
          setIsTabRefresh(false);
        });
    }

    if (IsEmployee && user) {
      setFilesBase64(null);
      setFileBase64(null);

      UserServices.getEmployeeByUserId(user.id)
        .then((res) => {
          
          if (res.data !== "") {
            if (
              res.data.firstName !== undefined &&
              res.data.lastName !== undefined
            ) {
              setLoginUserName(res.data.firstName + " " + res.data.lastName);
              setIsTabRefresh(false);
            }
          }
        })
        .catch((err) => {
          
          setIsTabRefresh(false);
        });
    }

    
  }, [IsEmployee,allParentCompany]);

  useEffect(() => {
    companyMasterService
      .getAllParentCompanyList()
      .then((res) => {
        
        if (Array.isArray(res.data)) {
          setAllParentCompany(res.data);
        }
      })
      .catch((err) => {
        
      });
  }, []);

  useEffect(() => {
    if (globalCompany) {
      MasterServices.getAllPendingRequest(globalCompany?.companyDetails?.id)
        .then((res) => {
          const data = res?.data;
          if (Array.isArray(data)) {
            const temp = data.filter(
              (val) => val?.companyId === globalCompany?.companyDetails?.id
            );
            if (temp.length > 0) {
              setIsRequest(temp);
            }
            const filterCount = temp.filter(
              ({ statusId }) => statusId?.id === 26
            ).length;
            setBadgeCount(filterCount);
          } else {
            console.warn("Unexpected response format:", res);
          }
        })
        .catch((err) => {
          console.error("Error fetching pending requests:", err.message || err);
        });
    }
  }, [globalCompany]);

  // useEffect(() => {
  //   if (user && allParentCompany) {
  //     console.log(allParentCompany)
  //     if (Array.isArray(user.companyRoles)) {
  //       const filteredData = user.companyRoles.filter((val) => allParentCompany.some((ele) => ele?.company?.id === val?.companyDetails?.id))
  //       setAllCompany(filteredData)
  //       if (Array.isArray(filteredData)) {
  //         if (filteredData.length > 0) {
  //           console.log(user.companyRoles)
  //           setGlobalCompany(filteredData[0])
  //         }
  //       }
  //     }
  //   }
  // }, [allParentCompany])

 

  function checkIdExists(arr, idToCheck) {
    const result = arr.find((item) => item.id === idToCheck);
    return result ? result.id : null;
  }

  const handleLogout = () => {
    setIsLoading(true)
    logoutService
      .logout()
      .then((res) => {
        
        setLoginUserName(null);
        localStorage.clear();
        setIsLoading(false)
        history("/signin");

      })
      .catch((err) => {
        setLoginUserName(null);
        localStorage.clear();
        setIsLoading(false)
        history("/signin");        
        setIsLoading(false)
      });
  };

  const handleClickImage=()=>{
    if (user) {
      if (superAdminId) {
        history("/admindash")
      }
      else{
        history("/dashboard")
      }
    }
    else{
      history("/")
    }
  }

  
  return (
    <header className="flex flex-col  self-stretch w-full  bg-white bg-opacity-0  max-md:max-w-full scroll-x">
      <div
        className="flex  items-center  py-3 w-full webapp-content-header text-sm capitalize bg-white max-md:px-5 max-md:max-w-full"
        style={{
          justifyContent: "space-between",
          backdropFilter: "blur(10px)",
          position: "fixed",
          top: "0",
          right: "0",
          left: "0",
          zIndex: "1000",
        }}
      >
        <div className="flex gap-5 pr-1 ml-3 max-w-full max-md:flex-wrap" >
          <img src={pic1} alt="img" className="header-logo cursor" onClick={handleClickImage} />
        </div>
        <div className="text-header-container1 pr-7">
          <div className="action-header-container">
            {globalCompany && !superAdminId ? (
              // <span className=" mr-3">
              //   <div class="relative">
              //     <Dropdown
              //       value={globalCompany}
              //       disabled={orderContinue}
              //       onChange={(e) => setGlobalCompany(e.value)}
              //       options={allCompany}
              //       optionLabel="companyDetails.companyName"
              //       placeholder="Select a company"
              //       style={{
              //         height: "40px",
              //         border: "none",
              //         fontFamily: "Inter, sans-serif",
              //         fontWeight: 500,
              //         color: "#004EEC",
              //       }}
              //       className="w-full  custom-dropdown"
              //       panelClassName="custom-dropdown-panel"
              //     />
              //   </div>
              // </span>
              <span className="mr-3">
                  <div className="relative flex items-center">
                    {globalCompany ? (
                      <>
                        {/* Use a font icon instead of an image */}
                        <div className="flex items-baseline">
                  {/* <span className="text-[#004EEC]  text-[16px]"> */}
                  <span className="font-fam-for-all datatable-highlight-color mr-0 capitalize">
                    {globalCompany.companyDetails.companyName}
                  </span>
                  <i className={`${PrimeIcons.BUILDING} text-[#004EEC] text-xl ml-1`} />
                </div>

                    </>
                  ) : (
                    // Fallback message when no company is selected
                    <span className="text-gray-400 italic">No company selected</span>
                  )}
              </div>
            </span>
            ) : (
              ""
            )}


          </div>
          <div className="avatar-header-container flex">

            <div className="font-fam-for-all datatable-highlight-color mr-2 capitalize">Welcome, {loginUserName}</div>

            {filesBase64 ? (
              <img
                src={`data:image/jpeg;base64,${filesBase64}`}
                alt={userName}
                className="custom-avatar-img"
                style={{ borderRadius: "50%", width: "40px", height: "40px" }}
                onClick={(e) => overlayPanel.current.toggle(e)}
              />
            ) : (
              <Avatar
                icon={!data ? "pi pi-user" : ""}
                label={
                  data !== null && data.contactDetails !== undefined
                    ? commonGetInitials(userName)
                    : ""
                }
                className="custom-avatar"
                onClick={(e) => overlayPanel.current.toggle(e)}
                shape="circle"
              />
            )}
          </div>
          <div className="mobile-view-menu-btnnn">
            <button onClick={handleShow}>
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>
        </div>
      </div>
      <OverlayPanel ref={overlayPanel} dismissable>
        <div className="overlay-panel-header">
          <div className="flex gap-2">
            <div className="avatar-header-container align-items-center">
              <Avatar
                icon={!filesBase64 && !data ? "pi pi-user" : ""}
                image={`data:image/jpeg;base64,${filesBase64}`}
                label={
                  !filesBase64 &&
                    data !== null &&
                    data.contactDetails !== undefined
                    ? commonGetInitials(userName)
                    : ""
                }
                className="custom-avatar"
                onClick={(e) => overlayPanel.current.toggle(e)}
                shape="circle"
              />{" "}
            </div>
            <div className="">
              <div className="overlay-panel-header-text1 capitalize">{userName}</div>
              <div className="overlay-panel-header-text2">{email}</div>
            </div>
          </div>
        </div>

        <div className="card-devider"></div>
        <div className="p-0">
          <ul>
            {!IsEmployee && <li
              className="profile-content-list-button px-3 py-2"
              onClick={(e) => {
                history("/profile");
                overlayPanel.current.toggle(e);
              }}
            >
              {/* <button> View Profile</button> */}
              <button> My Profile</button>

            </li>
            }
            {!IsEmployee && <li
              className="profile-content-list-button px-3 py-2"
              onClick={(e) => {
                history("/company/self");
                overlayPanel.current.toggle(e);
              }}
            >
              <button>My Company</button>
            </li>

            }
            <div className="card-devider"></div>
            <li
              className="profile-content-list-button px-3 py-2"
              onClick={handleLogout}
            >
              <button>Logout</button>
            </li>
          </ul>
        </div>
      </OverlayPanel>
      <div className="main-class-offcanvas">
        <Offcanvas show={show} onHide={handleClose} placement="end" className="main-class-offcanvas">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Menu</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Sidesbar />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </header>
  );
});

export default Headers;
