import React, { useContext, useEffect, useState } from "react";
import PaymentOption from "./PaymentOption";
import authContext from "../../common/authContext";

const PaymentOptions = () => {

  const { paymentMethod, setPaymentMethod, addOnServiceData } = useContext(authContext);


  const initialOptions = (addOnServiceData === "independent-manager")
    ? [{ label: "Pay later", isSelected: true }]
    : [
      { label: "Pay now", isSelected: true },
      { label: "Pay later", isSelected: false },
    ];

  const [options, setOptions] = useState(initialOptions);

  useEffect(() => {
    if ((addOnServiceData === "independent-manager")) {
      setPaymentMethod("Pay later")
    }
    else {
      setPaymentMethod("Pay now")
    }
  }, [addOnServiceData])

  const [selectedOption, setSelectedOption] = useState(addOnServiceData === "independent-manager" ? "Pay later" : "Pay now"); // Store selected option

  const handleOptionClick = (index) => {
    const updatedOptions = options.map((option, i) => ({
      ...option,
      isSelected: i === index, // Only the clicked option is selected
    }));

    console.log(updatedOptions)

    setOptions(updatedOptions);
    setPaymentMethod(updatedOptions[index].label)
    setSelectedOption(updatedOptions[index].label); // Capture the selected option
  };

  return (
    <section className="flex flex-col gap-3 text-sm font-medium leading-none">
      {options.map((option, index) => (
        <PaymentOption
          key={index}
          label={option.label}
          isSelected={option.isSelected}
          onClick={() => handleOptionClick(index)}
        />
      ))}
      {/* <div className="mt-4">
        <strong>Selected Payment Option:</strong> {selectedOption}
      </div> */}
    </section>
  );
};

export default PaymentOptions;
