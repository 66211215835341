import React, { useState, useRef, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import AddPermission from "./AddComponents/AddPermission";
import ContactService from "../services/ContactService";
import authContext from "../common/authContext";
import UserServices from "../services/userService";
import companyMasterService from "../services/companyMasterService";

export default function EmployeeCompany({ data }) {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh,
  } = useContext(authContext);

  const [companyData, setCompanyData] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setCompanyData([]);
    if (data) {
      companyMasterService
        .getAllCompanyByAccountManagerId(data.id)
        .then((res) => {
          
          
          if (res.data !== "") {
            setCompanyData(res.data);
          }
        })
        .catch((err) => {
          
        });
    }
  }, [data]);

  

  return (
    <div className="card p-fluid">
      <DataTable
        value={companyData}
        className="font-fam-for-all"
        scrollable
        scrollHeight="flex"
        scrollWidth="100%"
      >
        <Column
          field="companyDetails.companyName"
          header="Company Name"
          className="font-fam-for-all datatable-lg-col webapp-sm-size"
        />
        <Column
          field="companyDetails.companyEmail"
          header="Company Email"
          className="font-fam-for-all datatable-lg-col webapp-sm-size"
        />
        <Column
          field="companyDetails.phoneNo"
          header="Company Phone Number"
          className="font-fam-for-all datatable-lg-col webapp-sm-size"
        />
        {/* <Column header="Action" className='font-fam-for-all datatable-lg-col webapp-sm-size' /> */}
      </DataTable>
    </div>
  );
}
