
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataScroller } from 'primereact/datascroller';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { Chips } from 'primereact/chips';


import { Checkbox } from 'primereact/checkbox';
import { Avatar } from 'primereact/avatar';

import { MultiSelect } from 'primereact/multiselect';
import { Accordion, AccordionTab } from 'primereact/accordion';


import { SplitButton } from 'primereact/splitbutton';


import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';

import { Dropdown } from 'primereact/dropdown';

import { DataView } from 'primereact/dataview';

import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabView, TabPanel } from 'primereact/tabview';
import { Sidebar } from 'primereact/sidebar';
import { OverlayPanel } from 'primereact/overlaypanel';
import authContext from '../common/authContext';
import { useNavigate } from 'react-router-dom';
import UserServices from '../services/userService';
import { RadioButton } from 'primereact/radiobutton';
import Sidesbar from '../common/Sidesbar';
import Headers from '../common/Headers';
import ContactService from '../services/ContactService';
import AddCustomUser from './AddComponents/AddCutsomUser';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import AddEmployee from './AddComponents/AddEmployee';
import GroupDataTable from './GroupDataTable';
import { Switch } from '@mui/material';
import errorMessages, { globalMessages } from '../utils/errorMessages';
import UserUpload from './AddComponents/UserUpload';
import { Toast } from 'primereact/toast';
import companyMasterService from '../services/companyMasterService';
import rolesMasterService from '../services/rolesMaster';
import { InputMask } from 'primereact/inputmask';
import ForgotPasswordService from '../services/forgotPasswordService';
import AddressService from '../services/addressService';
import { Timeline } from 'primereact/timeline';
import { Card } from 'primereact/card';
import Documents from './Orders/Documents';

import RoleMasterDemo from './Master/RoleMasterDemo';
import MasterServices from '../services/coreServices';



export default function MasterDashboardDemo() {

    const { setFeedbackData, permissions } = useContext(authContext);

    console.log(permissions)

    const hasPermission = (permission) => permissions.includes(permission);

    console.log(hasPermission("AllowCreateUser"))

    let newObject = window.localStorage.getItem("user");

    let updatedUser = Object.assign({}, JSON.parse(newObject));


    const [filteredData, setFilteredData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

    const [sortName, setSortName] = useState(null)

    const [chipsData, setChipsData] = useState([]);

    const [chipsData1, setChipsData1] = useState([]);

    const [filterChipData, setFilterChipData] = useState([])

    const [selectedData, setSelectedData] = useState([]);

    const [allCities, setAllCities] = useState([])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

        id: { value: null, matchMode: FilterMatchMode.IN },
        "contactDetails.firstName": { value: null, matchMode: FilterMatchMode.EQUALS },
        "contactDetails.lastName": { value: null, matchMode: FilterMatchMode.EQUALS },
        "contactDetails.mobileNo": { value: null, matchMode: FilterMatchMode.EQUALS },
        "contactDetails.emailId": { value: null, matchMode: FilterMatchMode.EQUALS },

    });

    // const [city, setCity] = useState([])

    const [exp1, setExp1] = useState(null)

    const [exp2, setExp2] = useState(null)

    const [skills, setSkills] = useState([]);
    const [skillError, setSkillError] = useState("");
    const [skillARR, setSkillARR] = useState([]);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    const [qualification, setQualification] = useState([])
    const [qualificationArr, setQualificationArr] = useState([])

    const [visible, setVisible] = useState(false)

    const [visible1, setVisible1] = useState(false)

    const [visible2, setVisible2] = useState(false)

    const [visible4, setVisible4] = useState(false)

    const [visible5, setVisible5] = useState(false)

    const [visible6, setVisible6] = useState(false)

    const [visibleContent, setVisibleContent] = useState(false)

    const [allPosition, setAllPosition] = useState([])

    const [position, setPosition] = useState(null)

    const [positionData, setPositionData] = useState(null)

    const [candidate, setCandidate] = useState(null)

    const [closeProgresbar, setCloseProgresbar] = useState(false)

    const [closeProgresbar1, setCloseProgresbar1] = useState(false)

    const [globalKeyValue, setGlobalKeyValue] = useState("")

    const [AllCandidate, setAllCandidate] = useState([])

    const [showSkeleton, setShowSkeleton] = useState(true)

    const [selectedProduct, setSelectedProduct] = useState(null)

    const [data, setData] = useState(null)

    const op = useRef(null);

    const downloadop = useRef(null)

    const toast = useRef(null)

    const [isRemoveChip, setIsRemoveChip] = useState(false)

    const [fback, setFback] = useState([])

    const [candidateFback, setCandidateFback] = useState(null)

    const [interviewLevel1, setInterviewLevel1] = useState([])

    const [hrPanelList, setHrPanelList] = useState([])

    const [hrpanel, setHrPanel] = useState(null)

    const [selectedPanelData, setSelectedPanelData] = useState([])

    const [selectedPanelList, setSelectedPanelList] = useState([])

    const [showPanel, setShowPanel] = useState(false)

    const [globalSearchValue, setGlobalSearchValue] = useState("")

    const history = useNavigate()

    const [isShowContent, setIsShowContent] = useState(false)

    const [isShowContent1, setIsShowContent1] = useState(false)

    const [isShowContent2, setIsShowContent2] = useState(false)

    const [isShowContent3, setIsShowContent3] = useState(false)

    const [salutation, setSalutation] = useState("")

    const [firstName, setFirstName] = useState(null)

    const [firstNameErr, setFirstNameErr] = useState("")

    const [middleName, setMiddleName] = useState(null)

    const [middleNameErr, setMiddleNameErr] = useState("")

    const [lastName, setLastName] = useState(null)

    const [lastNameErr, setLastNameErr] = useState("")

    const [phoneNumber, setPhoneNumber] = useState(null)

    const [phoneErr, setPhoneErr] = useState("")

    const [altPhoneNumber, setAltPhoneNumber] = useState(null)

    const [altPhoneErr, setAltPhoneErr] = useState("")

    const [email, setEmail] = useState(null)

    const [emailErr, setEmailErr] = useState("")

    const [isFocused, setIsFocused] = useState(false);

    const [altEmail, setAltEmail] = useState("")

    const [altEmailErr, setAltEmailErr] = useState("")

    const [fax, setFax] = useState(null)

    const [faxErr, setFaxErr] = useState("")

    const [altFax, setAltFax] = useState("")

    const [altFaxErr, setAltFaxErr] = useState("")

    const [addr1, setAddr1] = useState("")

    const [addr1Err, setAddr1Err] = useState("")

    const [addr2, setAddr2] = useState("")

    const [addr2Err, setAddr2Err] = useState("")

    const [addr3, setAddr3] = useState("")

    const [region, setRegion] = useState("")

    const [regionErr, setRegionErr] = useState("")

    const [city, setCity] = useState("")

    const [cityErr, setcityErr] = useState("")

    const [states, setStates] = useState(null)

    const [statesErr, setStatesErr] = useState("")

    const [zip, setZip] = useState("")

    const [zipErr, setZipErr] = useState("")

    const [pageRefresh, setPageRefresh] = useState(false)

    const [dataId, setDataId] = useState(null)


    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [rolesName, setRolesName] = useState("")

    const [userId, setUserId] = useState(null)


    const [passwordErr, setPasswordErr] = useState("")

    const [oldpasswordErr, setOldPasswordErr] = useState("")

    const [confirmPasswordErr, setConfirmPasswordErr] = useState("")


    const [passwordType, setPasswordType] = useState("password");
    const [passwordType1, setPasswordType1] = useState("password");
    const [passwordType2, setPasswordType2] = useState("password");

    const [password, setPassword] = useState("")
    const [confirmedPassword, setConfirmPassword] = useState("")
    const [oldPassword, setOldPassword] = useState("")

    const [allCompanies, setAllCompanies] = useState([])

    const [company, setCompany] = useState(null)

    const [allRoles, setAllRoles] = useState([])

    const [role, setRole] = useState(null)

    const [tempProduct, setTempProduct] = useState([])

    const [isSort, setIsSort] = useState(false)

    const [addressData, setAddressData] = useState(null)

    const [contactDetails, setContactDeatils] = useState(null)

    const [companyDetails, setCompanyDetails] = useState(null)

    const [allAddressType, setAllAddressType] = useState([])

    const [addressType, setAddressType] = useState(null)

    const [allStates, setAllStates] = useState([])

    const [allCountries, setAllCountries] = useState([])

    const [countryData, setCountryData] = useState(null)

    const [stateValue, setStateValue] = useState(null)

    const [countryValue, setCountryValue] = useState(null)





    const [checked, setChecked] = useState(true)
    const [submitted, setSubmitted] = useState(false);

    // Function to extract dial code from phone number
    const extractDialCode = (phoneNumber) => {
        const dialCode = phoneNumber.match(/^\+\d+/);
        return dialCode ? dialCode[0] : null;
    };

    // Function to find country by dial code
    const findCountryByDialCode = (dialCode) => {
        return countries.find(country => country.dialCode === dialCode);
    };


    const handleCheck = (event) => {
        setChecked(event.target.checked);
        if (data && dataId) {
            const actualdata = data.contactDetails
            actualdata["statusId"] = event.target.checked === true ? 1 : 2


            console.log(actualdata)


            ContactService.updateContact(actualdata, dataId)
                .then((res) => {
                    console.log(res)
                    setIsEditing1(false)
                    setPageRefresh(!pageRefresh)

                })
                .catch((err) => {
                    console.log(err)
                })



        }
    };

    const [products, setProducts] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const countries = [
        { name: 'United States', code: 'US', dialCode: '+1' }
    ];

    useEffect(() => {
        companyMasterService.getAllCompany()
            .then((res) => {
                console.log(res)
                setAllCompanies(res.data)
            })
            .catch((err) => {
                console.log(err)
            })

        rolesMasterService.getAllRoles()
            .then((res) => {
                console.log(res)
                setAllRoles(res.data)
            })
            .catch((err) => {
                console.log(err)
            })

        AddressService.getAllAddressType()
            .then((res) => {
                setAllAddressType(res.data)
                console.log(res.data)
            })
            .catch((err) => {
                console.log(err)
            })

        AddressService.getAllCountry()
            .then((res) => {
                console.log(res)
                setAllCountries(res.data)
            })
            .catch((err) => {
                console.log(err)
            })

        AddressService.getAllStates()
            .then((res) => {
                console.log(res)
                setAllStates(res.data)
            })
            .catch((err) => {
                console.log(err)
            })

            MasterServices.getAllMasterManager()
            .then((res)=>{
                console.log(res)
                setProducts(res.data)
                const proddata=res.data
                if (proddata.length>0) {
                    setData(proddata[0])
                    setSelectedData(proddata[0])
                }
            })
            .catch((err)=>{
                console.log(err)
            })

            
    }, [])


    useEffect(() => {
       
        AddressService.getAllAddressByContactId(dataId)
            .then((res) => {
                console.log(res)
                if (res.data !== "") {
                    const tempArr = res.data
                    const temp = tempArr.filter((val) => val.addressType.type === "Contact")
                    console.log(temp)
                    if (temp.length > 0) {
                        setAddressData(temp[0])
                        setAddr1(temp[0].addressLine1)
                        setAddr2(temp[0].addressLine2)
                        setCity(temp[0].city)
                        setZip(temp[0].postalCode)
                        setStates(temp[0].stateId)
                        setCountryData(temp[0].countryId)
                        setAddressType(temp[0].addressType)
                        setContactDeatils(temp[0].contactDetails)
                        const temps = allStates.filter((val) => val.id === temp[0].stateId)
                        const temp1 = allCountries.filter((val) => val.id === temp[0].countryId)
                        console.log(temps)
                        if (temps.length > 0 && temp1.length > 0) {
                            setStateValue(temps[0].stateFullDesc)
                            setCountryValue(temp1[0].countryFullDesc)
                        }
                    }
                }
                else {
                    setAddressData(null)
                    setAddr1('')
                    setAddr2('')
                    setCity('')
                    setZip('')
                    setStates(null)
                    setCountryData(null)
                }

            })
            .catch((err) => {
                console.log(err)
            })
    }, [dataId])

    const user = JSON.parse(localStorage.getItem('user'));

    const automationJSON = JSON.parse(localStorage.getItem('automation'));

    const [loginDetails, setLoginDetails] = useState([

        { key: "Password", value: 'dhdhdhdhhdhd' },
    ])

    const [editLoginValues, setEditLoginValues] = useState(
        loginDetails.reduce((acc, item) => {
            acc[item.key] = item.value;
            return acc;
        }, {})
    )

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };







    const [isEditing, setIsEditing] = useState(false);
    const [isEditing1, setIsEditing1] = useState(false);
    const [isEditing2, setIsEditing2] = useState(false);
    const [editValues, setEditValues] = useState(

    );






    useEffect(() => {
        if (data) {
            if (data.contactDetails !== undefined) {
                setFirstName(data.contactDetails.firstName)
                setLastName(data.contactDetails.lastName)
                setPhoneNumber(data.contactDetails.mobileNo)
                setEmail(data.contactDetails.emailId)
                setFax(data.contactDetails.fax)
                setSalutation(data.contactDetails.salutation)
                setAltPhoneNumber(data.contactDetails.phone1)
                if (data.contactDetails.statusId === 1) {
                    setChecked(true)
                }
                else {
                    setChecked(false)
                }
            }

        }
    }, [data])



    const handleFilter = () => {
        if (products.length > 0 && company && !role) {
            const filteredContacts = products.filter(contact =>
                contact.companyRoles.some(role => role.companyId === company.id)
            );
            setProducts(filteredContacts)
        }
        if (products.length > 0 && role && !company) {
            const filteredContactsByRole = products.filter(contact =>
                contact.companyRoles.some(companyRole =>
                    companyRole.roles.some(r => r.id === role.id)
                ))
            setProducts(filteredContactsByRole)
        }
        if (products.length > 0 && company && role) {
            const filteredContactsByRole = products.filter(contact =>
                contact.companyRoles.some(companyRole =>
                    companyRole.roles.some(r => r.id === role.id) && contact.companyRoles.some(role => role.companyId === company.id)
                ))
            setProducts(filteredContactsByRole)
        }
    }


    const handleClearFilter = () => {
        setProducts(tempProduct)
        setRole(null)
        setCompany(null)
    }













    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleEdit1 = (rowdata) => {
        setIsEditing1(true);
        if (rowdata.contactDetails !== undefined) {
            setDataId(rowdata.contactDetails.id)
        }
    };

    const handleEdit2 = () => {
        setIsEditing2(true);
    };

    const handlePassword = (e) => {
        setPasswordErr("")
        let value = e.target.value
        if (value === '') {
            setPasswordErr(errorMessages.passwordRequired)

        }
        if (value && value.trim() !== '') {
            if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(value)) {
                setPasswordErr(errorMessages.passwordValidation)
            }

        }
        setPassword(e.target.value)
    }

    const handleOldPassword = (e) => {
        setOldPasswordErr("")
        let value = e.target.value
        if (value === '') {
            setOldPasswordErr(errorMessages.oldPasswordRequired)

        }
        if (value && value.trim() !== '') {
            if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(value)) {
                setOldPasswordErr(errorMessages.oldPasswordValidation)
            }

        }
        setOldPassword(e.target.value)
    }


    const handleEmail = (e) => {
        setEmailErr("")
        let value = e.target.value
        if (value === '') {
            setEmailErr(errorMessages.emailRequired)

        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
            setEmailErr(errorMessages.emailValidation);
        }
        setEmail(e.target.value)
    }



    const handleLastName = (e) => {
        setLastNameErr("")
        let value = e.target.value
        if (value === '') {
            setLastNameErr(errorMessages.lastNameRequired)

        }
        if (value && value.trim() !== '') {
            if (!/[A-Za-z]+$/.test(value)) {
                setLastNameErr(errorMessages.lastNameValidation)
            }

        }
        setLastName(e.target.value)
    }

    const handleFirstName = (e) => {
        setFirstNameErr("")
        let value = e.target.value
        if (value === '') {
            setFirstNameErr(errorMessages.firstNameRequired)

        }
        if (value && value.trim() !== '') {
            if (!/[A-Za-z]+$/.test(value)) {
                setFirstNameErr(errorMessages.firstNameValidation)
            }

        }
        setFirstName(e.target.value)
    }

    const handlePhone = (e) => {
        setPhoneErr("")
        let value = e.target.value
        if (value === '') {
            setPhoneErr(errorMessages.phoneRequired)

        }
        if (value && value.trim() !== '') {
            if (!/^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(value)) {
                setPhoneErr(errorMessages.phoneValidation)
            }

        }
        setPhoneNumber(e.target.value)
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    const togglePassword1 = () => {
        if (passwordType1 === "password") {
            setPasswordType1("text")
            return;
        }
        setPasswordType1("password")
    }

    const togglePassword2 = () => {
        if (passwordType2 === "password") {
            setPasswordType2("text")
            return;
        }
        setPasswordType2("password")
    }

    const handleConfirmPassword = (e) => {
        setConfirmPasswordErr("")
        let value = e.target.value
        if (value === '') {
            setConfirmPasswordErr(errorMessages.confirmPasswordRequired)

        }
        if (value && value.trim() !== '') {
            if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/.test(value)) {
                setConfirmPasswordErr(errorMessages.confirmPasswordValidation)
            }

        }
        setConfirmPassword(e.target.value)
    }

    const handleChange = (key, value) => {
        setEditValues({ ...editValues, [key]: value });
    };






    const sortData = (field) => {
        if (field === sortField) {
            // If clicking on the same field, toggle the sort order
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If clicking on a different field, set the new field and default to ascending order
            setSortField(field);
            setSortOrder('asc');
        }
        const sortedData = [...products].sort((a, b) => {
            console.log(a)
            if (a.contactDetails !== undefined && b.contactDetails !== undefined) {
                const valueA = a.contactDetails[field];
                const valueB = b.contactDetails[field];

                if (valueA < valueB) {
                    return sortOrder === 'asc' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortOrder === 'asc' ? 1 : -1;
                }
            }

            return 0;

        });
        console.log(sortedData)

        setProducts(sortedData);
    };


    const handleFbackHeader = () => {
        return <>

            <div className='font-fam-for-all form-md-heading form-sm-close-Btn' >Pre-Screening Feedback
                <button className='form-close-Btn' onClick={() => setVisible6(false)}>
                    <i className='pi pi-times'>
                    </i>
                </button>
            </div>

        </>
    }









    const allSortName = ["name", "location", "experienc"]

    const handleSortName = (e) => {
        setSortName(e.target.value)
        console.log(e.target.value)
        sortData(e.target.value)
    }

    const handlePosition = (e) => {
        const temp = e.target.value
        setPosition(e.target.value)
        if (temp.jobdescription.skill !== null) {
            setSkills(temp.jobdescription.skill)
        }
        if (temp.jobdescription.experience !== null) {
            const tempexp = temp.jobdescription.experience
            const arrexp = tempexp.split("-")
            console.log(arrexp)
            if (arrexp.length === 2) {
                setExp1(arrexp[0])
                setExp2(arrexp[1])
            }
        }


    }

    const handleClearPosition = () => {
        setVisible1(false)
        setVisible2(false)
        setPosition(null)
        setPositionData(null)
    }

    const handlePositionData = (e) => {
        setProducts(AllCandidate)
        const temp = e.target.value
        setPositionData(e.target.value)
    }

    const SortingFilter = () => {
        return (
            <>
                <div>
                    <Dropdown
                        value={sortName}
                        options={allSortName}
                        onChange={handleSortName}
                        placeholder="Sorting By"
                        filter
                        className='filter-dropdown-field' required />
                </div>
            </>
        )
    }








    const handleExp1 = (e) => {
        setProducts(AllCandidate)
        setExp1(e.target.value)
    }

    const handleExp2 = (e) => {
        setProducts(AllCandidate)
        setExp2(e.target.value)
    }

    const listExp = []
    for (let i = 0; i < 50; i++) {
        listExp.push(`${i}`)
    }















    const filterData = (event) => {
        const filteredItems = products.filter((item) => {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    const element = (item[key]).toString();
                    console.log(element)

                    if (element.toLowerCase().includes(event.target.value.toLowerCase())) {
                        return item;
                    }


                }
            }

        });
        setFilteredData(filteredItems);
    };


    // effect of filter all

    function checkAllExist(a, b) {

        const lowercaseB = b.map(item => item.toLowerCase());

        for (const element of a) {

            const lowercaseElement = element.toLowerCase();

            if (!lowercaseB.includes(lowercaseElement)) {
                return false;
            }
        }
        return true;
    }


    function convertMonthsToYears(years) {
        return years * 12;
    }

    // event on checkbox
    const onCheckboxChange = (event, data) => {
        console.log(event.checked)
        console.log(data)
        if (event.checked) {
            setSelectedData([...selectedData, data]);
        } else {
            setSelectedData(selectedData.filter(item => item !== data));
        }
    };

    const handleChecked = (e) => {
        const checked = e.checked;
        if (checked) {
            setSelectedData(products)
        }
        else {
            setSelectedData([])
        }
    }

    console.log(selectedData)

    // DataTable columns
    const cols = [
        { field: 'id', header: 'id' },
        { field: 'fullName', header: 'Name' },
        { field: 'skill', header: 'Skills' },
        { field: 'experienceInYears', header: 'Experience' },
        { field: 'city', header: 'Location' },


    ];









    // Export DataTable data
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));





    const CloseProgresbarheader1 = () => {
        setCloseProgresbar1(false)
    }


    const handleClick3 = () => {
        setVisible(false)
    }




    // Function to check if object exists in array
    function objectExists(array, obj) {
        return array.some(item => JSON.stringify(item) === JSON.stringify(obj));
    }


    const getInitials = (name) => {
        const nameArray = name.trim().split(" ");
        if (nameArray.length >= 2) {
            const firstNameInitial = nameArray[0].charAt(0).toUpperCase();
            const lastNameInitial = nameArray[1].charAt(0).toUpperCase();
            return firstNameInitial + lastNameInitial;
        } else {

            const initial = nameArray[0].charAt(0).toUpperCase();
            return initial + initial;
        }
    };




    const itemTemplate = (data) => {

        console.log(data)
        console.log(selectedData)


        if (data) {

            return (
                <div className="srch-cand-card  font-fam-for-all">


                    <div className='flex justify-content-center' >
                        <div className=' srch-cand-text1 ' >{data.displayName}</div>
                    </div>

                </div>
            );
        }
        else {
            return (
                <>
                </>
            )
        }

    };






    const CloseProgresbarheader = () => {
        setCloseProgresbar(false)
    }


    const searchCandHeader = () => {
        return (
            <>
            <div className='font-fam-for-all ml-3'>Administrator</div>
            </>
        )
    }


    const handleOnlineForm = () => {
        console.log(automationJSON)
        if (automationJSON) {
            UserServices.callAutomation(automationJSON)
                .then((res) => {
                    console.log(res)
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }

    const onRowSelect = (rowData) => {
        console.log(rowData)
        setData(rowData.data)


    }

    console.log(data)

    const sidebarHeader = () => (
        <>
            <div style={{ backgroundColor: '#002592', width: 'contentFit', height: '40px', position: 'sticky' }} className="sidebar-head">
                <Button className="pi pi-times sidebar-close-btn" onClick={() => setFilters(false)}></Button>
            </div>
        </>
    )

    const items = Array.from({ length: 5 }, (v, i) => i);




    useEffect(() => {
        if (fback.length > 0) {
            const temp = fback.filter((val) => val.interviewLevelId.preference === 1)
            if (temp.length > 0) {

                setCandidateFback(temp[0])
                setFeedbackData(temp)

            }
        }
    }, [fback])


    const events = [
        { status: 'Ordered', date: '15/10/2020 10:30', icon: 'pi pi-shopping-cart', color: '#9C27B0', image: 'game-controller.jpg' },
        { status: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7' },
        { status: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
        { status: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' }
    ];

    const customizedMarker = (item) => {
        return (
            <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" >
                <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
                    <path d="M29.8337 14.7733V16C29.832 18.8752 28.901 21.6729 27.1794 23.9758C25.4579 26.2786 23.038 27.9633 20.2808 28.7785C17.5236 29.5938 14.5767 29.4959 11.8796 28.4994C9.18257 27.503 6.87986 25.6615 5.31494 23.2494C3.75001 20.8374 3.00671 17.9841 3.19589 15.1151C3.38508 12.2461 4.4966 9.51509 6.3647 7.32942C8.2328 5.14375 10.7574 3.6205 13.5619 2.98685C16.3664 2.35321 19.3007 2.64311 21.927 3.81333M29.8337 5.33333L16.5003 18.68L12.5003 14.68" stroke="#039855" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </span>
        );
    };


    const customizedContent = (item) => {
        return (
            <div className='order-details-card-text-container'>
                <div className='order-details-card-text-container-header'>
                    <div className='order-details-card-text-container-header-text1'>
                        Order created
                    </div>
                    <div className='order-details-card-text-container-header-text2'>
                        Mar 26, 2024
                    </div>
                </div>
                <ul>
                    <li className='order-details-card-text-list'>Order has been placed successfully.</li>

                </ul>

            </div>
        );
    };





    return (
        <>
            <Toast ref={toast} />

            <div className='grid webapp-content-container'>
                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl
                -12 '>

                    <div className='card search-cand-card2'>





                        <div style={{ padding: '30px' }}>

                            <div className='grid' >

                                <div className='col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 pt-0 '>                                                                    
                                    <div className="">
                                        <div className='vstate-card'>

                                            {showSkeleton ? <DataTable header={searchCandHeader} scrollable scrollHeight="700px" value={products}  inline emptyMessage='No Menu Items Found' filters={filters} className='master-datatable'
                                                globalFilterFields={['contactDetails.firstName', 'contactDetails.lastName', 'contactDetails.mobileNo', 'contactDetails.emailId']} selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)}
                                                onRowSelect={onRowSelect} metaKeySelection={false}>
                                                <Column body={itemTemplate} className='datatable-body-icon'></Column>
                                            </DataTable> : <DataTable header={searchCandHeader} value={items} rows={5} rowsPerPageOptions={[5, 10, 25, 50]} inline paginator emptyMessage='No Candidate Found'
                                                selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)}
                                                onRowSelect={onRowSelect} metaKeySelection={false}>
                                                <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            </DataTable>}
                                        </div>
                                    </div>
                                </div>
                                {
                                    (data !== null) ? <div className='col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 pt-0' >
                                        <div className='col-12 grid font-fam-for-all py-3 px-3'>
                                            <div className='col-12 col-sm-12 col-md-12 col-lg-9 col-xl-7' style={{ paddingLeft: '20px' }}>
                                                <p className='search-cand-header mb-0'>{data.displayName}</p>
                                                <p className='search-cand-sub-header'>Manage {data.displayName} here.</p>
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5'>

                                                
                                            </div>
                                        </div>



                                        <div className='vstate-card col-12'>

                                            <RoleMasterDemo data={data} items= {products} />
                                        </div>



                                    </div> : showSkeleton ? <div className='col-12 col-sm-12 col-md-12 col-lg-8 pt-0 ' >
                                        <div className='card col-12'>
                                            <div className='grid justify-content-center align-items-center' >
                                                No content found
                                            </div>
                                        </div></div> : <div className=" card col-12 col-sm-12 col-md-12 col-lg-8 pt-0">
                                        <div className="border-round border-1 surface-border p-4 surface-card">
                                            <div className="flex mb-3">
                                                <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                                                <div>
                                                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                                                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                    <Skeleton height=".5rem"></Skeleton>
                                                </div>
                                            </div>
                                            <Skeleton width="100%" height="150px"></Skeleton>
                                            <div className="flex justify-content-between mt-3">
                                                <Skeleton width="4rem" height="2rem"></Skeleton>
                                                <Skeleton width="4rem" height="2rem"></Skeleton>
                                            </div>
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>
                    </div>




                    <Dialog header={handleFbackHeader} visible={visible6} onHide={() => {
                        setVisible6(false)

                    }}
                        className='dialog-box'
                    >
                        {/* <AddPreScreeningFeedback allFeedbackData={fback} feedback={candidateFback} lastFbackData={null} /> */}
                    </Dialog>



                    <Dialog visible={closeProgresbar} modal={false} onHide={CloseProgresbarheader} className='font-fam-for-all'>
                        <p className="m-0 p-3">
                            <div className='d-flex align-items-center'>
                                {/* <img src={SuccessImage} width='60px' height='60px' /> */}
                                {/* <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
                                <p className='font-fam-for-all successMessage-font'>Data Added Successfully</p>
                            </div>
                            <div className='flex justify-content-end dialog-form-md-group-Btn'>
                                <Button type='button' className="mt-2 form-Btn form-Btn-Label font-fam-for-all  text-lg  mr-2" onClick={CloseProgresbarheader} >Ok</Button>
                            </div>
                        </p>
                    </Dialog>


                    <Dialog visible={closeProgresbar1} modal={false} onHide={CloseProgresbarheader1} className='font-fam-for-all'>
                        <p className="m-0 p-3">
                            <div className='d-flex align-items-center'>
                                {/* <img src={ErroImage} width='60px' height='60px' /> */}
                                {/* <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
                                <p className='font-fam-for-all successMessage-font'>Data Added Failed</p>
                            </div>
                            <div className='flex justify-content-end dialog-form-md-group-Btn'>
                                <Button type='button' className="mt-2 form-Btn form-Btn-Label font-fam-for-all  text-lg  mr-2" onClick={CloseProgresbarheader1} >Ok</Button>
                            </div>
                        </p>
                    </Dialog>

                    {/* <EditCandidateDetails visible={visible5} setVisible={setVisible5} setCloseProgresbar={setCloseProgresbar} setCloseProgresbar1={setCloseProgresbar1} /> */}
                    {/* <AddCandidate visible={visible4} setVisible={setVisible4} /> */}

                </div>
            </div>
        </>
    )
}
