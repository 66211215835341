import axios from "axios";
import authHeader, { initAuth } from "./authHeader";
import encryption from "./CryptoClass";
import propertiesApp from "../utils/propertiesApp";



const getAllContact = (data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/contactDetails/getAll`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            // return axios.get(`/decrypt?data=${dataToEncrypt}`,
            return axios.post(`/decrypt`,dataToEncrypt,

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllContactByCompanyId = (companyId) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/contactDetails/getByCompanyId/${companyId}`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            // return axios.get(`/decrypt?data=${dataToEncrypt}`,
            return axios.post(`/decrypt`,dataToEncrypt,

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllContactById = (id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/contactDetails/getById/${id}`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            // return axios.get(`/decrypt?data=${dataToEncrypt}`,
            return axios.post(`/decrypt`,dataToEncrypt,

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const getAllContactByUserId = (userId) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/contactDetails/getByUserId/${userId}`,
        payload: null,
        type:"get",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            // return axios.get(`/decrypt?data=${dataToEncrypt}`,
            return axios.post(`/decrypt`,dataToEncrypt,

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const updateContact = (data,id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/contactDetails/update`,
        payload: data,
        type:"put",
        auth:authHeader()
      };

      const encryptedData=encryption.encryptData(dataToEncrypt)
      console.log(encryptedData)
    try {
        
            // return axios.get(`/decrypt?data=${dataToEncrypt}`,
            return axios.post(`/decrypt`,dataToEncrypt,

        { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const ContactService={
    getAllContact,
    updateContact,
    getAllContactByCompanyId,
    getAllContactByUserId,
    getAllContactById
}

export default ContactService;