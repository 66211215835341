import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import "../../CommonSection/CommonStyle/CommonStyle.css";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";


const CorporateFillingFaq = () => {
  const faqItems = [
    {
      key: "0",
      question: "What is foreign qualification?",
      answer:
        "It’s the process of registering your business in another state to legally operate outside your home state.",
    },
    {
      key: "1",
      question: "Can I amend my business's original formation documents?",
      answer:
        "Yes, amendments allow you to update important business information like your company’s name or management structure.",
    },
    {
      key: "2",
      question: "What is an S-Corp election?",
      answer:
        "Electing S-Corp status can provide tax benefits for certain businesses by allowing profits to pass through to owners.",
    },
    {
      key: "3",
      question: "Why would I need a merger filing?",
      answer:
        "A merger filing legally combines two entities into one, streamlining operations and resources.",
    },
    {
      key: "4",
      question: "What is the process for dissolving my business?",
      answer:
        "Dissolution involves officially closing a business with the state, ensuring all liabilities are settled.",
    },
    {
      key: "5",
      question:
        "How do I convert my business entity?",
      answer:
        "Entity conversion allows you to change from one business type (e.g., LLC to Corp) to another as your business evolves.",
    },
    {
        key: "6",
        question:
          "What is a certificate of reinstatement?",
        answer:
          "A certificate of reinstatement is a document that reactivates a dissolved business, restoring its legal status.",
      },
      {
        key: "7",
        question:
          "Is it recommended that a professional handle corporate filings?",
        answer:
          "Yes, using professionals ensures filings are accurate and completed on time, avoiding legal pitfalls.",
      },
  ];

  // Split FAQs into two columns
  const firstColumnFaqs = faqItems.filter((_, index) => index % 2 === 0);
  const secondColumnFaqs = faqItems.filter((_, index) => index % 2 !== 0);

  return (
    <section className="main-section-class">
      <div className="common-faq">
        <Container>
          <div className="mb-5 mt-5">
            <CommonHeadingTitle heading={"FAQ"} />
          </div>
          <Accordion defaultActiveKey="0">
            <Row>
              <Col lg={6} md={6} sm={12}>
                {firstColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
              <Col lg={6} md={6} sm={12}>
                {secondColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
            </Row>
          </Accordion>
        </Container>
      </div>
    </section>
  );
};

export default CorporateFillingFaq;
