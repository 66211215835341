import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import ShiftsServices from '../../services/demoServices';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import CreditCardServices from '../../services/strapiService';
import { Dialog } from 'primereact/dialog';


const DynamicForm = ({visible,setVisible}) => {
    const [inputFormData, setInputFormData] = useState([])
    const [allInitData, setAllInitData] = useState([])

    const [allModels, setAllModels] = useState([])

    const [modelData, setModelData] = useState([])

    const [modelData1, setModelData1] = useState([])


    const [IsSuccessful, setIsSuccessFul] = useState(false)

    const [modelName, setModelName] = useState(null)


    const {
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        setValue,
        watch,
        reset
    } = useForm('');

    useEffect(() => {
        ShiftsServices.getInit()
            .then((res) => {
                console.log(res.data)

                // const tempdata = res.data.data.contentTypes
                const tempdata = res.data.data
                console.log(tempdata)
                setAllInitData(tempdata)

                if (tempdata.length > 0) {
                    const temp = tempdata.filter((val) => val.apiID === "llc")
                    const temp1 = tempdata.filter((val) => val.apiID === "llc-member")
                    const temp2 = tempdata.filter((val) => val.apiID === "credit-card-info")

                }
            })
            .catch((err) => {
                console.log(err)
            })



    }, [])

    function flattenArray(array) {
        return array.map(item => {
            const result = {};
            for (const key in item) {
                if (Array.isArray(item[key])) {
                    result[key] = item[key].flat();
                }
            }
            return result;
        });
    }

    function reorderAttributes(a, b) {
        console.log(a)

        const temp = b.map(item => {
            for (let i = 0; i < a.length; i++) {
                const aObj = a[i];
                console.log(aObj)
                const apiID = item.apiID;
                console.log(aObj[apiID])
                if (aObj[apiID]) {
                    const order = aObj[apiID].map(attr => attr.name);
                    const newAttributes = {};


                    order.forEach(key => {
                        if (item.attributes[key]) {
                            newAttributes[key] = item.attributes[key];
                        }
                    });


                    for (const key in item.attributes) {
                        if (!newAttributes[key]) {
                            newAttributes[key] = item.attributes[key];
                        }
                    }


                    item.attributes = newAttributes;
                }


            }
            return item;
        });

        console.log(temp)
        return temp;


    }


    useEffect(() => {

        const firstData = "contact"
        if (firstData !== "") {




            console.log(firstData)
            console.log(allInitData)
            setModelName(firstData)
            const tempFieldArr = allInitData.filter((val) => val.apiID === firstData)
            console.log(tempFieldArr)
            if (tempFieldArr.length > 0) {
                const tempAtributes = tempFieldArr[0].attributes

                console.log(tempAtributes)

                const temps = Object.keys(tempAtributes).map((key, i) => {
                    return tempAtributes[key].model
                })
                console.log(temps)

                const tempsFormData = temps.filter((val) => val !== undefined)
                console.log(tempsFormData)

                const tempsFormDataType = allInitData.filter((val) => val.apiID===firstData)

                console.log(tempsFormDataType)

                setAllModels(tempsFormDataType)

                const tempd = tempsFormDataType.map(async (val) => {

                    const response = await CreditCardServices.getConfiguration(`application::${val.apiID}.${val.apiID}`)



                    const tempdata = val.apiID
                    console.log(response)

                    if (response.data !== undefined) {
                        return {
                            [tempdata]: response.data.data.contentType.layouts.edit !== undefined ? response.data.data.contentType.layouts.edit : {}
                        }
                    }

                })

                console.log(tempd)

                Promise.all(tempd).then((results) => {
                    console.log(results);

                    const flatArr = flattenArray(results)
                    console.log(flatArr)
                    console.log(tempsFormDataType)

                    const updatedB = reorderAttributes(flatArr, tempsFormDataType);
                    console.log(updatedB)
                    const tempsFormDataTypeAtribute = updatedB.map((val) => {
                        return val.attributes
                    })

                    const finalData = tempsFormDataTypeAtribute.map((val) => {
                        delete val.id
                        return val;
                    })

                    console.log(finalData)
                    setInputFormData(finalData)
                    console.log(JSON.stringify(updatedB, null, 2));


                }).catch((err) => {
                    console.log(err);
                });


            }

        }


    }, [allInitData])

    const convertToTitleCase = (str) => {
        return str.replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
    }


    
    const getFormErrorMessage = (name) => {

        return errors[name] ? <small className="error-msg font-fam-for-all ">{errors[name].message}</small> : <small className="error-msg "></small>;
    };


    const renderFormField = (column, key) => {

        const errorMessageTag = document.getElementById(`${key}--error`);

        // if (column.default) {
        //     setValue(key,column.default)
        // }

        console.log(column.regex)
        switch (column.type) {
            case 'string':
                return <>
                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space ">
                                <div className="field">
                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label ">

                                        <Controller name={key}
                                            control={control}
                                            rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertToTitleCase(key)} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertToTitleCase(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertToTitleCase(key)} Required.` } : {}}
                                            render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field}
                                                    autoComplete={false}
                                                    onChange={(e) => errorMessageTag.textContent = ''}
                                                    style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', fontWeight: 'bold' }}
                                                    className='dialog-form-input-field-wizard'
                                                    maxLength={50} />
                                            )} />



                                    </span>
                                    <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}
                                </div>
                            </div>

                        </div>

                    </div>
                </>;
            // case "biginteger":
            //     return<>
            //     <input type="number" />
            //     </>
            case 'biginteger':
                return <>
                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space ">
                                <div className="field">
                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label ">

                                        <Controller name={key}
                                            control={control}
                                            rules={column.required !== undefined && column.required === true ? { required: `${convertToTitleCase(key)} Required.`, pattern: { value: /^\d+$/, message: 'Please Enter Valid No Of Positions' } } : {}}
                                            render={({ field, fieldState }) => (
                                                <input type='number' id={field.name} {...field}

                                                    onChange={(e) => errorMessageTag.textContent = ''}
                                                    style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', fontWeight: 'bold', borderRadius: '6px' }}
                                                    className='dialog-form-input-field-wizard p-inputtext'
                                                />
                                            )} />



                                    </span>
                                    <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}
                                </div>
                            </div>

                        </div>

                    </div>
                </>;

            case 'decimal':
                return <>
                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space ">
                                <div className="field">
                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label ">

                                        <Controller name={key}
                                            control={control}
                                            rules={column.required !== undefined && column.required === true ? { required: `${convertToTitleCase(key)} Required.`, pattern: { value: /^\d+$/, message: 'Please Enter Valid No Of Positions' } } : {}}
                                            render={({ field, fieldState }) => (
                                                <input type='number' id={field.name} {...field}

                                                    onChange={(e) => errorMessageTag.textContent = ''}
                                                    style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', fontWeight: 'bold', borderRadius: '6px' }}
                                                    className='dialog-form-input-field-wizard p-inputtext'
                                                />
                                            )} />



                                    </span>
                                    <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}
                                </div>
                            </div>

                        </div>

                    </div>
                </>;

            case 'email':
                return <>
                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space ">
                                <div className="field">
                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label ">

                                        <Controller name={key}
                                            control={control}
                                            rules={column.required !== undefined && column.required === true ? { required: `${convertToTitleCase(key)} Required.`, pattern: { value: /^\d+$/, message: 'Please Enter Valid No Of Positions' } } : {}}
                                            render={({ field, fieldState }) => (
                                                <input type='email' id={field.name} {...field}

                                                    onChange={(e) => errorMessageTag.textContent = ''}
                                                    style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', fontWeight: 'bold', borderRadius: '6px' }}
                                                    className='dialog-form-input-field-wizard p-inputtext'
                                                />
                                            )} />



                                    </span>
                                    <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}
                                </div>
                            </div>

                        </div>

                    </div>
                </>;
            case "password":
                return <>

                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space ">
                                <div className="field">
                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label " >
                                        <Controller
                                            control={control}
                                            name={key}
                                            rules={column.required !== undefined && column.required === true ? { required: `${convertToTitleCase(key)} Required.`, pattern: { value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, message: 'Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.' } } : {}}
                                            render={({ field, fieldState }) => (
                                                <Password
                                                    id={field.name} {...field}
                                                    toggleMask
                                                    inputRef={field.ref}
                                                    autoComplete={false}
                                                    className='dialog-form-input-field-wizard'
                                                />
                                            )} />



                                    </span>
                                    <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            // case "date":
            //     return <>
            //         <div className=" justify-content-center dialog-form-field-space ml-1">
            //             <div className="field">
            //                 <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label><span className="p-float-label ">
            //                     <Controller name={key}
            //                         control={control}
            //                         rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertToTitleCase(key)} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertToTitleCase(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertToTitleCase(key)} Required.` } : {}}
            //                         render={({ field, fieldState }) => (
            //                             <LocalizationProvider dateAdapter={AdapterDayjs} >

            //                                 <DatePicker
            //                                     id={field.name} {...field}
            //                                     // value={values}
            //                                     // onChange={handleOpenDate}
            //                                     // required

            //                                     slotProps={{
            //                                         textField: {
            //                                             size: 'small',
            //                                             error: false,
            //                                             fullWidth: true
            //                                         },
            //                                     }}


            //                                     className='dialog-form-dropdown-field'

            //                                 />

            //                             </LocalizationProvider>
            //                         )} />





            //                 </span>
            //                 {/* {getFormErrorMessage('opendate')} */}
            //                 <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
            //                 {getFormErrorMessage(key)}
            //             </div>
            //         </div>
            //     </>
            case "enumeration":
                return <>
                    <div className=" justify-content-center dialog-form-md-field-space">
                        <div className="field">
                            <label htmlFor='' className='form-label font-fam-for-all'>{convertToTitleCase(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>

                            <span className="p-float-label ">
                                <Controller
                                    name={key}
                                    control={control}
                                    onChange={(e) => errorMessageTag.textContent = ''}

                                    rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertToTitleCase(key)} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertToTitleCase(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertToTitleCase(key)} Required.` } : {}}
                                    render={({ field }) => <Dropdown id={field.name} filter name="jd" value={field.value} options={column.enum !== undefined ? column.enum : []} onChange={(e) => field.onChange(e.value)} placeholder={`Select ${key}`} maxSelectedLabels={3} style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', fontWeight: 'bold' }}
                                        className='dialog-form-input-field-wizard' />}
                                />
                            </span>
                            <small id={`${key}--error`} className="error-msg font-fam-for-all "></small>
                            {getFormErrorMessage(key)}
                        </div>
                    </div>
                </>

            default:
                return null;
        }
    };

console.log(inputFormData)

    return (
        <div>
             <Dialog visible={visible} className='dialog-box' onHide={() => setVisible(false)}>
            {
                inputFormData.map((value, i) => (
                    <div className="tab" key={i}>



                        <div className='grid'>
                            {
                                Object.keys(value).map((val, i) =>


                                (
                                    <>

                                        <div className='col-12 col-sm-12 col-md-12 col-lg-6'>
                                            {
                                                renderFormField(value[val], val)
                                            }
                                        </div>





                                    </>
                                )
                                )
                            }
                        </div>
                    </div>
                ))

            }
            </Dialog>
        </div>
    )
}

export default DynamicForm