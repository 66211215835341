import React from 'react';
import ServiceCard from './ServiceCard';
import pic1 from '../../Assets/Images/Img - creating an llc.png'

const Services = () => {
  const services = [
    {
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/600dfb23cb4947bd934f599d4e0685b3f8c697512d1364d8d3acb301be69f5ed?apiKey=02362ed84d214b928bb0b669d3dcf7f8&",
      title: "Company Formation",
      description: "You have an opportunity to register a limited liability company (LLC) or corporation in all 50 US states in less than 10 minutes.",
    },
    {
      image: pic1,
      title: "Registered Agent Services",
      description: "A Registered Agent is a party, such as vState Filings, designated to receive service of process notices within the state.",
    },
    {
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/907c5842241f69f3e703032767cf6e2f0c858817e187fbafe89af4eea605a49e?apiKey=02362ed84d214b928bb0b669d3dcf7f8&",
      title: "Filings & Document",
      description: "Let us help you make a difference! Our expert team provides comprehensive services to file and maintain your Documents.",
    },
    {
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/056c9f4504e609d093782dcdac31c97f3120a96c7bd184af49cf8b6d19ba37c6?apiKey=02362ed84d214b928bb0b669d3dcf7f8&",
      title: "Attorneys",
      description: "vState specializes in supporting lawyers, accountants, business consultants and notaries with an array of fast and reliable filing and document retrieval services.",
    }
  

  ];

  return (
    <section className="flex flex-col justify-center items-center p-2.5 mt-24 max-w-full w-[1240px] max-md:px-5 max-md:mt-10">
      <h2 className="text-5xl font-medium tracking-tighter text-center capitalize leading-[52.8px] text-slate-700 max-md:max-w-full">
        <span className="font-web-color">Incorporation</span> <span className="font-web-color1">Services</span>
      </h2>
      <p className="mt-5 text-xl text-center text-gray-800 w-[552px] max-md:max-w-full">
        We offer comprehensive services to help you establish and operate your US LLC or corporation.
      </p>
      <div className="justify-center p-8 mt-5 max-w-full w-[950px] max-md:px-5">
        <div className="flex grid justify-content-center  max-md:flex-col max-md:gap-0">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;