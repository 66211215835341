import React, { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Avatar } from 'primereact/avatar';
import Typewriter from '../utils/TypeWritter';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import html2canvas from 'html2canvas';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import authContext from '../common/authContext';
import pic1 from "../Assets/Images/Frame 31284.png"

const Chatbot = () => {

    const { isEmailExist, setIsEmailExist } = useContext(authContext)
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [isActive, setIsActive] = useState(false);
    const chatWindowRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [suggestionData, setSuggestionData] = useState('');
    const [subSuggestionsData, setSubSuggestionData] = useState('');
    const [isEmailId, setIsEmailId] = useState(false)

    const followUpTimer = useRef(null);

    const predefinedSuggestions = [
        "Primary Services",
        "Entity Formation",
        "Non-Profit-Services",
        "Registration Agent",
        "Attorneys",
        "Beneficial Ownership Information",
        "Others"
    ];

    const subSuggestions = {
        "Primary Services": [

            "DBA Registration",
            "Name Availability",
            "Conversion",
            "Corporate Kit and Seal",
            "UCC Filings",
            "Dissolution"
        ],
        "Entity Formation": [
            "LLC",
            "S-Corp",
            "C-Corp",
            "Tax ID EIN",
            "PC or PLLC",
        ],
        "Non-Profit-Services": [
            "Formations of Nonforprofit -501c3",
            "Application For Exemption",
            "State Tax Exemption",
            "Foreign State Registration"
        ],

        "Registration Agent": [
            "Registered Agent",
            "Independent Director",
            "Entity Monitoring"
        ],
        "Beneficial Ownership Information": [],
        "Attorneys": [],
        "Others": []
    };

    useEffect(() => {
        const botMessage = { sender: 'bot', type: "first-msg", text: "Can you provide your name,email and contact number. So that if we get disconnected, we can reach out to you", flag: 'bot-text', time: getCurrentTimeWithAMPM() };
        const botSuggestions = {
            sender: 'bot',
            type: 'first-msg',
            suggestions: predefinedSuggestions,
            time: getCurrentTimeWithAMPM()
        };
        setMessages([...messages, botMessage]);
    }, []);

    useEffect(() => {
        // Scroll to bottom whenever messages change
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }, [messages]);

    function getCurrentTimeWithAMPM() {
        const now = new Date();
        let hours = now.getHours();
        const minutes = now.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        hours = hours % 12;
        hours = hours ? hours : 12;
        const hoursStr = hours < 10 ? '0' + hours : hours;
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;

        const timeString = hoursStr + ':' + minutesStr + ' ' + ampm;
        return timeString;
    }

    // const handleSend = async () => {
    //     setIsActive(true);

    //     if (input.trim()) {
    //         const userMessage = { sender: 'user', text: input, flag: 'user-text', time: getCurrentTimeWithAMPM() };
    //         setMessages([...messages, userMessage]);

    //         setIsLoading(true); 

    //         const inputData = {
    //             question: input,
    //             page: subSuggestionsData === "" ? "global" : subSuggestionsData
    //         }

    //         try {
    //             const response = await axios.post('http://192.168.1.108:9500/chat_with_us', inputData);
    //             const botMessage = { sender: 'bot', text: response.data.answer, flag: 'bot-text', time: getCurrentTimeWithAMPM() };

    //             // Delay to simulate typing effect
    //             await new Promise(resolve => setTimeout(resolve, 1500));

    //             setMessages((prevMessages) => [...prevMessages, botMessage]);
    //         } catch (error) {
    //             console.error('Error sending message:', error);
    //             const botMessage = { sender: 'bot', text: "Failed to generate. Please try again.", flag: 'bot-text', time: getCurrentTimeWithAMPM() };
    //             setMessages((prevMessages) => [...prevMessages, botMessage]);
    //         }

    //         setIsLoading(false); 
    //         setIsActive(false);
    //         setInput('');
    //     }
    // };



    const handleSend = async () => {
        setIsActive(true);

        if (input.trim()) {
            const userMessage = { sender: 'user', text: input, flag: 'user-text', time: getCurrentTimeWithAMPM() };
            setMessages([...messages, userMessage]);

            setIsLoading(true);

            const inputData = {
                question: input,
                page: suggestionData === "Others" ? "global" : subSuggestionsData === "" ? suggestionData : subSuggestionsData
            }

            try {
                const response = await axios.post('http://localhost:8765:9500/bot/chat_with_us', inputData);
                const botMessage = { sender: 'bot', text: response.data.answer, flag: 'bot-text', time: getCurrentTimeWithAMPM() };

                await new Promise(resolve => setTimeout(resolve, 1500));

                setMessages((prevMessages) => [...prevMessages, botMessage]);

                setIsLoading(false);
                setIsActive(false);
                setInput('');

                // Reset the follow-up timer
                if (followUpTimer.current) {
                    clearTimeout(followUpTimer.current);
                }

                if (!isActive) {
                    followUpTimer.current = setTimeout(() => {
                        const followUpMessage = {
                            sender: 'bot',
                            text: 'Do you want to know anything else?',
                            flag: 'bot-text',
                            type: 'follow-up',
                            time: getCurrentTimeWithAMPM()
                        };
                        setMessages((prevMessages) => [...prevMessages, followUpMessage]);
                        setIsActive(true)
                    }, 120000);
                }


            } catch (error) {
                console.error('Error sending message:', error);
                const botMessage = { sender: 'bot', text: `Sorry for the inconvenience. We are not able to answer your query. Please contact us at 866-638-3309 for further assistance.`, flag: 'bot-text', time: getCurrentTimeWithAMPM() };
                setMessages((prevMessages) => [...prevMessages, botMessage]);

                setIsLoading(false);
                setIsActive(false);
                setInput('');
                // Reset the follow-up timer
                if (followUpTimer.current) {
                    clearTimeout(followUpTimer.current);
                }
                if (!isActive) {
                    followUpTimer.current = setTimeout(() => {
                        const followUpMessage = {
                            sender: 'bot',
                            text: 'Do you want to know anything else?',
                            flag: 'bot-text',
                            type: 'follow-up',
                            time: getCurrentTimeWithAMPM()
                        };
                        setMessages((prevMessages) => [...prevMessages, followUpMessage]);
                        setIsActive(true)
                    }, 120000);
                }

            }

        }
    };

    const handleSend1 = async () => {
        setIsActive(true);
        const userMessage = { sender: 'user', text: input, flag: 'user-text', time: getCurrentTimeWithAMPM() };

        const inputData = {
            "input_data": input
        }

        try {
            const response = await axios.post('http://localhost:8765:9500/auth/login', inputData);
            // const botMessage = { sender: 'bot', text: response.data.answer, flag: 'bot-text', time: getCurrentTimeWithAMPM() };


            // await new Promise(resolve => setTimeout(resolve, 1500));

            // setMessages((prevMessages) => [...prevMessages, botMessage]);

            const botSuggestions = {
                sender: 'bot',
                type: 'suggestions',
                suggestions: predefinedSuggestions,
                time: getCurrentTimeWithAMPM()
            };
            setMessages([...messages, userMessage, botSuggestions]);
            setIsEmailId(true)
            setIsEmailExist(true)
            setInput("")



        } catch (error) {
            console.log(error)
            if (error.response !== undefined) {
                if (error.response.data !== undefined) {
                    if (error.response.data.detail !== undefined) {
                        const botMessage = { sender: 'bot', text: error.response.data.detail, flag: 'bot-text', time: getCurrentTimeWithAMPM() };
                        setMessages((prevMessages) => [...prevMessages, botMessage]);
                    }
                }
                else {
                    const botMessage = { sender: 'bot', text: `Sorry for the inconvenience. We are not able to answer your query. Please contact us at 866-638-3309 for further assistance.`, flag: 'bot-text', time: getCurrentTimeWithAMPM() };
                    setMessages((prevMessages) => [...prevMessages, botMessage]);
                }
            }
            else {
                const botMessage = { sender: 'bot', text: `Sorry for the inconvenience. We are not able to answer your query. Please contact us at 866-638-3309 for further assistance.`, flag: 'bot-text', time: getCurrentTimeWithAMPM() };
                setMessages((prevMessages) => [...prevMessages, botMessage]);
            }



        }
        setIsLoading(false);
        setIsActive(false);
        setInput('');

    }

    const handleBackClick = () => {
        setSuggestionData("")
        setSubSuggestionData("")
        const botMessage = { sender: 'bot', text: "Hello! Welcome To Vstate Filings", flag: 'bot-text', time: getCurrentTimeWithAMPM() };
        setIsActive(true)
        const botSuggestions = {
            sender: 'bot',
            type: 'suggestions',
            suggestions: predefinedSuggestions,
            time: getCurrentTimeWithAMPM()
        };
        setMessages([...messages, botSuggestions]);
    };

    // const handleSuggestionClick = (suggestion) => {
    //     setSuggestionData(suggestion);
    //     setIsActive(true);
    //     const userMessage = { sender: 'user', text: suggestion, flag: 'user-text', time: getCurrentTimeWithAMPM() };
    //     setMessages((prevMessages) => [...prevMessages, userMessage]);

    //     const botSubSuggestions = {
    //         sender: 'bot',
    //         type: 'sub-suggestions',
    //         suggestions: subSuggestions[suggestion],
    //         time: getCurrentTimeWithAMPM()
    //     };
    //     setMessages((prevMessages) => [...prevMessages, botSubSuggestions]);

    //     setIsActive(false);
    // };

    const handleSuggestionClick = (suggestion) => {
        setSuggestionData(suggestion);
        setIsActive(true);
        const userMessage = { sender: 'user', text: suggestion, flag: 'user-text', time: getCurrentTimeWithAMPM() };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        if (subSuggestions[suggestion].length > 0) {
            const botSubSuggestions = {
                sender: 'bot',
                type: 'sub-suggestions',
                suggestions: subSuggestions[suggestion],
                time: getCurrentTimeWithAMPM()
            };
            setMessages((prevMessages) => [...prevMessages, botSubSuggestions]);
        } else {
            const botMessage = {
                sender: 'bot',
                text: suggestion === "Others" ? "Please specify your query so that we can assist you better." : `How can I help on ${suggestion}?`,
                flag: 'bot-text',
                time: getCurrentTimeWithAMPM()
            };
            setMessages((prevMessages) => [...prevMessages, botMessage]);
        }


    };

    const handleSubSuggestionClick = async (subSuggestion) => {
        setSubSuggestionData(subSuggestion);
        setIsActive(true);
        const userMessage = { sender: 'user', text: subSuggestion, flag: 'user-text', time: getCurrentTimeWithAMPM() };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        setIsLoading(true); // Start loading for current message

        try {
            // Simulating typing delay
            await new Promise(resolve => setTimeout(resolve, 1500));
            const m = "Please specify your query so that we can assist you better."
            if (subSuggestion === "Others") {
                const botMessage = { sender: 'bot', text: m, flag: 'bot-text', time: getCurrentTimeWithAMPM() };
                setMessages((prevMessages) => [...prevMessages, botMessage]);
            }
            else {
                const botMessage = { sender: 'bot', text: `What do you like to know about ${subSuggestion} ?`, flag: 'bot-text', time: getCurrentTimeWithAMPM() };
                setMessages((prevMessages) => [...prevMessages, botMessage]);
            }
        } catch (error) {
            console.error('Error sending message:', error);
            const m = "Please specify your query so that we can assist you better."
            if (subSuggestion === "Others") {
                const botMessage = { sender: 'bot', text: m, flag: 'bot-text', time: getCurrentTimeWithAMPM() };
                setMessages((prevMessages) => [...prevMessages, botMessage]);
            }
            else {
                const botMessage = { sender: 'bot', text: `What do you like to know about ${subSuggestion} ?`, flag: 'bot-text', time: getCurrentTimeWithAMPM() };
                setMessages((prevMessages) => [...prevMessages, botMessage]);
            }


        }

        setIsLoading(false);
        setIsActive(false);
    };

    const handleFollowUpResponse = (response) => {
        if (response === 'no') {
            const thankYouMessage = {
                sender: 'bot',
                text: 'Thanks for your time. I hope, you got the required information. Have a nice day!',
                flag: 'bot-text',
                time: getCurrentTimeWithAMPM()
            };
            setMessages((prevMessages) => [...prevMessages, thankYouMessage]);
        } else if (response === 'yes') {
            // const botSuggestions = {
            //     sender: 'bot',
            //     type: 'suggestions',
            //     suggestions: predefinedSuggestions,
            //     time: getCurrentTimeWithAMPM()
            // };
            // setMessages((prevMessages) => [...prevMessages, botSuggestions]);
            setIsActive(false)
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSend();
        }
    };


    const exportChat = async () => {
        try {
            const chatWindow = chatWindowRef.current;
            const originalHeight = chatWindow.style.height;
            const originalOverflow = chatWindow.style.overflow;

            // Temporarily set height and overflow to capture the entire chat content
            chatWindow.style.height = 'auto';
            chatWindow.style.maxHeight = 'none';
            chatWindow.style.overflow = 'visible';

            // Allow the browser to reflow before capturing the screenshot
            await new Promise(resolve => setTimeout(resolve, 100));

            // Capture the full chat window
            const canvas = await html2canvas(chatWindow, {
                scale: 2,
                useCORS: true,
                allowTaint: true,
                logging: true,
                width: chatWindow.scrollWidth,
                height: chatWindow.scrollHeight,
            });

            // Revert styles back to original
            chatWindow.style.height = originalHeight;
            chatWindow.style.maxHeight = '';
            chatWindow.style.overflow = originalOverflow;

            const imgData = canvas.toDataURL('image/png');
            const htmlContent = `
            <html>
                <head>
                    <style>
                        .chat-image {
                            width: 100%;
                            height: auto;
                        }
                    </style>
                </head>
                <body>
                    <img src="${imgData}" class="chat-image" alt="Chat Export" />
                </body>
            </html>
        `;

            const blob = new Blob([htmlContent], { type: 'text/html' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'chat.html';
            link.click();
        } catch (error) {
            console.error('Error exporting chat:', error);
        }
    };

    console.log(messages)

    const svgContent = `
       <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none">
       <circle cx="9.79519" cy="2.00625" r="2.00625" fill="#A3D5C9"/>
<circle cx="7.67096" cy="6.13675" r="1.06213" fill="#A3D5C9"/>
<circle cx="1.23915" cy="11.5064" r="1.23915" fill="#A3D5C9"/>
<circle cx="15.2829" cy="12.4506" r="1.47518" fill="#A3D5C9"/>
<circle cx="19.0004" cy="8.26101" r="1.06213" fill="#A3D5C9"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6886 0.650298C15.7808 1.14561 15.3314 1.88426 15.3314 2.88125C15.3314 3.25051 15.4132 3.69897 15.5172 3.90007C15.6192 4.09751 15.6675 4.29412 15.6244 4.33672C15.5812 4.37932 14.4255 5.56549 13.0562 6.97258L10.5663 9.5309L10.2051 9.33783C9.72642 9.08209 8.5438 9.08256 8.0643 9.33889L7.70094 9.53302L6.31214 8.14057L4.92323 6.74811L5.10155 6.40316C5.40803 5.8106 5.4491 5.28143 5.23785 4.6484C4.87201 3.5524 4.02785 2.93907 2.885 2.93907C1.62401 2.93907 0.628439 3.83469 0.499331 5.08529C0.309446 6.92561 2.08686 8.27074 3.82911 7.60537L4.36643 7.40014L5.74319 8.76298L7.11995 10.1258L6.91815 10.5489C6.61756 11.1792 6.65663 12.0617 7.01421 12.7184C8.17748 14.8542 11.3541 14.1761 11.5336 11.7535C11.5703 11.2579 11.5252 10.9367 11.3712 10.5952L11.1581 10.1225L13.751 7.52949L16.3439 4.93659L16.8074 5.13037C17.5107 5.42411 18.5064 5.31601 19.138 4.87711C20.5128 3.92202 20.5099 1.83623 19.1323 0.87901C18.7142 0.58834 18.4875 0.520836 17.8341 0.49204C17.2702 0.467257 16.9408 0.512693 16.6886 0.650298Z" fill="white"/>
</svg>
    `;

    return (
        <div className="chatbot" >
            <div className="chat-window" ref={chatWindowRef}>
                <div className='bot-text grid'>
                    <div className='col-1'>
                        <Avatar className='mr-2' style={{ backgroundColor: '#0047FF', color: '#ffffff' }} shape="circle" >
                            <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                        </Avatar>
                    </div>
                    <div className='col-10'>
                        <div className='mt-1 ml-1'>
                            {/* <h6 >vState</h6> */}

                            <div className={`message bot font-fam-for-all`} >
                                Hello! Welcome To vState Filings

                            </div>

                        </div>
                    </div>
                </div>
                {messages.map((msg, index) => (
                    <React.Fragment key={index}>


                        {(msg.sender === "bot") && (msg.type !== 'suggestions') && (msg.type !== 'sub-suggestions') && (msg.type !== 'first-msg') && (msg.type !== 'follow-up') ? (
                            <div className='bot-text grid'>
                                <div className='col-1'>
                                    <Avatar className='mr-2' style={{ backgroundColor: '#0047FF', color: '#ffffff' }} shape="circle" >
                                        <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                                    </Avatar>

                                </div>
                                <div className='col-10 pb-0'>
                                    <div className='mt-1 ml-1'>


                                        <div className={`message bot font-fam-for-all p-0`} >
                                            <div className='p-3'>
                                                <Typewriter text={msg.text} speed={50} setIsActive={setIsActive} />
                                            </div>



                                            <div className='flex justify-content-center' style={{ borderTop: "1px solid #ccc" }}>
                                                <Button label='Back To Main Menu' style={{ backgroundColor: "transparent", border: "none", color: "blue", fontSize: "12px" }} onClick={handleBackClick} />
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        ) : msg.sender === "bot" && msg.type === 'first-msg' ? (<>
                            <div className='bot-text grid'>
                                <div className='col-1'>
                                    <Avatar className='mr-2' style={{ backgroundColor: '#0047FF', color: '#ffffff' }} shape="circle" >
                                        <div dangerouslySetInnerHTML={{ __html: svgContent }} />

                                    </Avatar>

                                </div>
                                <div className='col-10 pb-0'>
                                    <div className='mt-1 ml-1'>


                                        <div className={`message bot font-fam-for-all p-0`} >
                                            <div className='p-3'>
                                                <Typewriter text={msg.text} speed={50} setIsActive={setIsActive} />
                                            </div>



                                        </div>

                                    </div>
                                </div>


                            </div>
                        </>) : msg.sender === "bot" && msg.type === 'suggestions' ? (
                            <div className='bot-text grid'>
                                <div className='col-1'>
                                    <Avatar className='mr-2' style={{ backgroundColor: '#0047FF', color: '#ffffff' }} shape="circle" >
                                        <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                                    </Avatar>
                                </div>
                                <div className='col-10 pb-0'>
                                    <div className='mt-1 ml-1'>
                                        {/* <h6>vState</h6> */}
                                        <div className={`message bot font-fam-for-all`} >
                                            <p>How can I assist you with following vState Filings Services?</p>
                                            {msg.suggestions.map((suggestion, idx) => (
                                                <div key={idx} className='p-1'>
                                                    <div className='flex justify-content-center chip-main font-fam-for-all'>
                                                        <Chip label={suggestion} onClick={() => handleSuggestionClick(suggestion)} className='suggestion-chip flex-grow-1' />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : msg.sender === "bot" && msg.type === 'sub-suggestions' ? (
                            <div className='bot-text grid'>
                                <div className='col-1'>
                                    <Avatar className='mr-2' style={{ backgroundColor: '#0047FF', color: '#ffffff' }} shape="circle" >
                                        <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                                    </Avatar>
                                </div>
                                <div className='col-10 pb-0'>
                                    <div className='mt-1 ml-1'>
                                        {/* <h6>vState</h6> */}
                                        <div className={`message bot font-fam-for-all`} >
                                            <p>Which of the below services, you would like to know more?</p>
                                            {msg.suggestions.map((subSuggestion, idx) => (
                                                <div key={idx} className='p-1'>
                                                    <div className='flex justify-content-center chip-main font-fam-for-all'>
                                                        <Chip label={subSuggestion} onClick={() => handleSubSuggestionClick(subSuggestion)} className='suggestion-chip flex-grow-1' />
                                                    </div>
                                                </div>
                                            ))}
                                            <div className='flex justify-content-center' style={{ borderTop: "1px solid #ccc" }}>
                                                <Button label='Back To Main Menu' style={{ backgroundColor: "transparent", border: "none", color: "blue", fontSize: "12px" }} onClick={handleBackClick} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : msg.sender === "bot" && msg.type === 'follow-up' ? (<>

                            <div className='bot-text grid'>
                                <div className='col-1'>
                                    <Avatar className='mr-2' style={{ backgroundColor: '#0047FF', color: '#ffffff' }} shape="circle" >
                                        <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                                    </Avatar>
                                </div>
                                <div className='col-10 pb-0'>
                                    <div className='mt-1 ml-1'>


                                        <div className={`message bot font-fam-for-all p-0`} >
                                            <div className='p-3'>
                                                We haven’t heard from you for a while, can you confirm if you want to continue?
                                            </div>

                                            {msg.type === 'follow-up' && (
                                                <>

                                                    <div className='p-1'>
                                                        <div className='flex justify-content-center chip-main font-fam-for-all'>
                                                            <Chip label="Yes" onClick={() => handleFollowUpResponse('yes')} className='suggestion-chip flex-grow-1' />

                                                        </div>

                                                    </div>
                                                    <div className='p-1'>
                                                        <div className='flex justify-content-center chip-main font-fam-for-all'>
                                                            <Chip label="No" onClick={() => handleFollowUpResponse('no')} className='suggestion-chip flex-grow-1' />

                                                        </div>

                                                    </div>
                                                </>
                                            )}

                                            <div className='flex justify-content-center' style={{ borderTop: "1px solid #ccc" }}>
                                                <Button label='Back To Main Menu' style={{ backgroundColor: "transparent", border: "none", color: "blue", fontSize: "12px" }} onClick={handleBackClick} />
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </>) : (
                            <div className='grid'>
                                <div className='col-2'></div>
                                <div className='col-10 pb-0'>
                                    <div className={`msg ${msg.flag} font-fam-for-all`}>
                                        <div className={`message ${msg.sender} font-fam-for-all`}>
                                            {msg.text}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )


                        }
                        <div className={`msg ${msg.flag} mb-2 font-fam-for-all`}>
                            <small style={msg.sender === "user" ? { alignSelf: 'flex-end', fontSize: '12px' } : { alignSelf: 'flex-start', fontSize: '12px', marginLeft: "40px" }}>
                                {msg.time}
                            </small>
                        </div>




                        {
                            index === messages.length - 1 && isLoading ?
                                <div className='bot-text grid'>
                                    <div className='col-1'>
                                        <Avatar className='mr-2' style={{ backgroundColor: '#0047FF', color: '#ffffff' }} shape="circle" >
                                            <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                                        </Avatar>
                                    </div>
                                    <div className='col-10 pb-0'>
                                        <div className='mt-1 '>
                                            {/* <h6>vState</h6> */}

                                            <div className="is-typing bot-text ml-6">
                                                <div className="jump1"></div>
                                                <div className="jump2"></div>
                                                <div className="jump3"></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                : ""}

                        {/* {
                            isLoading === false ? <div className={`msg ${msg.flag} font-fam-for-all`}>
                                <small style={msg.sender === "user" ? { alignSelf: 'flex-end', fontSize: '12px' } : { alignSelf: 'flex-start', fontSize: '12px', marginLeft: "40px" }}>
                                    {msg.time}
                                </small>
                            </div> : ''
                        } */}

                    </React.Fragment>
                ))}
            </div>
            {/* <div className='input-area'>
                {!isEmailExist && (
                    <div>
                        <InputText
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Please provide email id"
                            className="input-field"
                        />
                        <Button label="Send" icon="pi pi-send" onClick={handleSend1} />
                    </div>
                )}
                {isEmailExist && (
                    <div className="flex">
                        <InputText
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            placeholder="Type a message..."
                            className="input-field"
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !isLoading) {
                                    handleSend();
                                }
                            }}
                        />
                        <Button label="Send" icon="pi pi-send" onClick={handleSend} />
                    </div>
                )}
            </div> */}
            <div className="input-area">
                {!isEmailExist && (
                    <>

                        <InputTextarea
                            type="text"
                            value={input}
                            disabled={isActive}
                            cols={35}
                            rows={1}
                            style={{ border: "none", fontSize: "14px" }}
                            className='font-fam-for-all'
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !isLoading && !e.shiftKey) {
                                    handleSend1();

                                }
                            }}
                            placeholder="Type a message..."
                        />
                        <Button icon="pi pi-send" className='mr-0 ' tooltip="Send" tooltipOptions={{ position: 'bottom' }} style={{ backgroundColor: 'transparent', color: "#8F9195" }} disabled={isActive || input === ""} onClick={handleSend1}>

                        </Button>
                        <Button className='mr-0 p-0' icon="pi pi-download" style={{ backgroundColor: 'transparent', color: "#8F9195" }} tooltip="Download" tooltipOptions={{ position: 'left' }} onClick={exportChat}>
                            {/* <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <g clip-path="url(#clip0_121_1238)">
                                        <path d="M9.33731 1.46066C8.89774 1.37566 8.45103 1.33302 8.00331 1.33333C4.31998 1.33333 1.33331 4.18933 1.33331 7.71133C1.33331 9.40333 2.02265 10.9413 3.14665 12.082C3.39465 12.3333 3.55998 12.6767 3.49331 13.03C3.38459 13.6037 3.13538 14.1415 2.76798 14.5953C3.72961 14.7713 4.72249 14.6169 5.58531 14.1573C5.88865 13.9967 6.03998 13.916 6.14665 13.9C6.25331 13.884 6.40665 13.912 6.71331 13.9693C7.13954 14.0493 7.56954 14.0893 8.00331 14.0893C11.5853 14.0893 14.5086 11.388 14.6666 8" stroke="#8F9195" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10.6666 5C10.994 5.33733 11.8666 6.66666 12.3333 6.66666M12.3333 6.66666C12.8 6.66666 13.6726 5.33733 14 5M12.3333 6.66666V1.33333M7.99731 8H8.00265M5.33331 8H5.33931" stroke="#8F9195" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_121_1238">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div> */}
                        </Button>
                    </>
                )}

                {isEmailExist && (
                    <>

                        <InputTextarea
                            type="text"
                            value={input}
                            disabled={isActive || suggestionData === ""}
                            style={{ border: "none", fontSize: "14px" }}
                            className='font-fam-for-all'
                            cols={35}
                            rows={1}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !isLoading && !e.shiftKey) {
                                    handleSend();
                                }
                            }}
                            placeholder="Type a message..."
                        />
                        <Button icon="pi pi-send" className='mr-0 ' tooltip="Send" tooltipOptions={{ position: 'bottom' }} style={{ backgroundColor: 'transparent', color: "#475467", fontWeight: "700", fontSize: "16px" }} disabled={isActive || input === ""} onClick={handleSend}>

                        </Button>
                        <Button className='mr-0 p-0' icon="pi pi-download" style={{ backgroundColor: 'transparent', color: "#475467" }} tooltip="Download" tooltipOptions={{ position: 'left' }} onClick={exportChat}>
                            {/* <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <g clip-path="url(#clip0_121_1238)">
                                        <path d="M9.33731 1.46066C8.89774 1.37566 8.45103 1.33302 8.00331 1.33333C4.31998 1.33333 1.33331 4.18933 1.33331 7.71133C1.33331 9.40333 2.02265 10.9413 3.14665 12.082C3.39465 12.3333 3.55998 12.6767 3.49331 13.03C3.38459 13.6037 3.13538 14.1415 2.76798 14.5953C3.72961 14.7713 4.72249 14.6169 5.58531 14.1573C5.88865 13.9967 6.03998 13.916 6.14665 13.9C6.25331 13.884 6.40665 13.912 6.71331 13.9693C7.13954 14.0493 7.56954 14.0893 8.00331 14.0893C11.5853 14.0893 14.5086 11.388 14.6666 8" stroke="#475467" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10.6666 5C10.994 5.33733 11.8666 6.66666 12.3333 6.66666M12.3333 6.66666C12.8 6.66666 13.6726 5.33733 14 5M12.3333 6.66666V1.33333M7.99731 8H8.00265M5.33331 8H5.33931" stroke="#475467" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_121_1238">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div> */}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default Chatbot;
