import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import CreditCardServices from "../../services/strapiService";
import DownloadPdf from "../../utils/downloadPdf";
import SideBarMenu from "../../common/Sidebar";
import ShiftsServices from "../../services/demoServices";
import { Checkbox } from "primereact/checkbox";
import { TabView, TabPanel } from "primereact/tabview";
import { useForm, Controller } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import SuccessImage from "../../Assets/Images/icons8-success-96.png";
import ErroImage from "../../Assets/Images/error-icon.png";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import encryption from "../../services/CryptoClass";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Password } from "primereact/password";
import AddressService from "../../services/addressService";
import ContactService from "../../services/ContactService";
import MasterServices from "../../services/coreServices";
import OrderServices from "../../services/orderServices";
import { useNavigate, useSearchParams } from "react-router-dom";
import companyMasterService from "../../services/companyMasterService";
import AddCompanyMember from "../Company/AddCompanyComponets/AddMember";
import authContext from "../../common/authContext";
import { Accordion, AccordionTab } from "primereact/accordion";
import AddressTemplate from "../../utils/AddressTemplate";
import AddCompanyLocation from "../Company/AddCompanyComponets/AddLocation";
import { InputTextarea } from "primereact/inputtextarea";
import PaymentOptions from "../Payment/PaymentOptions";
import { loadStripe } from '@stripe/stripe-js';
import axios from "axios";
import propertiesApp from "../../utils/propertiesApp";
import { Toast } from "primereact/toast";
import { Sidebar } from "primereact/sidebar";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Switch, TextField } from "@mui/material";
import { Editor } from "primereact/editor";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddBOIMember from "../Company/AddCompanyComponets/AddBOIMember";
import validationConfig from "../../utils/UsaStatesRegex";
import AddCompany from "../AddComponents/AddCompany";


const EditOrderForm = () => {


    const { setRedirectedData, globalCompany, loginUserName, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh, editPayloadData, setEditPayloadData, paymentMethod, setOrderContinue, setPaymentOrderData, wizardScroll, setWizardScroll } = useContext(authContext);

    const [allState, setAllState] = useState([

    ]);



    const [contactId, setContactId] = useState(null)

    const history = useNavigate()

    const [selectedCheckBoxData, setSelectedCheckBoxData] = useState([]);
    const [checkedState, setCheckedState] = useState({});

    const [loading1, setLoading1] = useState(false);

    const stripePromise = loadStripe("pk_test_51PyTdiKhsNfb7YuLO8XP7SHE3FUn3rspK3sI7OTbOIulKp9XdM7WtU4gqjxiKVDVBldIKIEBvL8PLjdKkW1h8f1100bw75rZPd");

    const [county, setCounty] = useState(null)

    const [isTransactionIdExist, setIsTransactionIdExist] = useState(null)

    const navigate = useNavigate()


    useEffect(() => {
        // Check if the page is being refreshed
        const isRefreshed = sessionStorage.getItem('isRefreshed');

        if (isRefreshed) {
            // If refresh detected, redirect to '/order'
            navigate('/order');
            sessionStorage.removeItem('isRefreshed'); // Clear the flag after redirect
        }

        const handleBeforeUnload = () => {
            // Set flag in sessionStorage on page unload (refresh or close)
            sessionStorage.setItem('isRefreshed', 'true');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [navigate]);


    const toast = useRef(null)
    const tempdata = [
        {
            item: "Roles Master",
            getAllUrl: "/core/api/roleMaster/getAll",
            label: "",
            getAditionalUrl: [
                {
                    field: "",
                    url: "/core/api/permissionMaster/getAll",
                    label: "statusForName"
                }
            ],
            saveUrl: "/core/api/roleMasters/save",

        },

        {
            item: "State",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },

        {
            item: "State To Search",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "debt_State",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "ANR_State_of_Formation",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "coad_State",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "uccAmend_State",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "ad_State",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "sp_State",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "State Text",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "State Of Filing",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "State Of Entity",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "BNR_State_Of_Formation",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "State Of Formation",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "Country",
            getAllUrl: "/core/api/countryMaster/getAll",
            label: "countryShortName",
            getAditionalUrl: [
                {
                    field: "",
                    url: "/core/api/countryMasters/getAll",
                    label: "statusForName"
                }
            ],
            saveUrl: "",
        },
        {
            item: "County",
            getAllUrl: "/core/api/countyMaster/getAll",
            label: "countyName",
            getAditionalUrl: [
                {
                    field: "",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "County To Search",
            getAllUrl: `/core/api/countyMaster/getAll`,
            label: "countyName",
            getAditionalUrl: [
                {
                    field: "",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "Contact Source Master",
            getAllUrl: "/core/api/contactSourceMaster/getAll",
            label: "countryFullDesc",
            getAditionalUrl: [
                {
                    field: "",
                    url: "/core/api/permissionMaster/getAll",
                    label: "statusForName"
                }
            ],
            saveUrl: "",

        },

        {
            item: "Industry Master",
            getAllUrl: "/core/api/industryMaster/getAll",
            label: "countryFullDesc",
            getAditionalUrl: [
                {
                    field: "",
                    url: "/core/api/permissionMaster/getAll",
                    label: "statusForName"
                }
            ],
            saveUrl: "",
        },
        {
            item: "Record Type Master",
            getAllUrl: "/core/api/recordTypeMaster/getAll",
            label: "countryFullDesc",
            getAditionalUrl: [
                {
                    field: "",
                    url: "/core/api/permissionMaster/getAll",
                    label: "statusForName"
                }
            ],
            saveUrl: "",
        },


        {
            item: "Permission Master",
            getAllUrl: "/core/api/permissionMaster/getAll",
            label: "countryFullDesc",
            getAditionalUrl: [
                {
                    field: "",
                    url: "/core/api/permissionMaster/getAll",
                    label: ""
                }
            ],
            saveUrl: "",

        },

        {
            item: "Status Master",
            getAllUrl: "/core/api/statusMaster/getAll",
            label: "countryFullDesc",
            getAditionalUrl: [
                {
                    field: "statusForId",
                    url: "/core/api/statusForMasters/getAll",
                    label: "statusForName"
                }
            ],
            saveUrl: "",
        },
        {
            item: "Record Type Master",
            getAllUrl: "/core/api/recordTypeMaster/getAll",
            label: "countryFullDesc",
            getAditionalUrl: [
                {
                    field: "",
                    url: "/core/api/permissionMaster/getAll",
                    label: "statusForName"
                }
            ],
            saveUrl: "",
        },
        {
            item: "State Or Province",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "Issuing Jurisdiction State",
            getAllUrl: "/core/api/stateMaster/getAll",
            label: "stateFullDesc",
            getAditionalUrl: [
                {
                    field: "countryMaster",
                    url: "/core/api/stateMasters/getAll",
                    label: "stateFullDesc"
                }
            ],
            saveUrl: "",
        },
        {
            item: "Issuing Jurisdiction Country",
            getAllUrl: "/core/api/countryMaster/getAll",
            label: "countryFullDesc",
            getAditionalUrl: [
                {
                    field: "",
                    url: "/core/api/countryMasters/getAll",
                    label: "statusForName"
                }
            ],
            saveUrl: "",
        },

    ]

    const [IsCheckedAgent, setIsCheckedAgent] = useState(false)
    const [IsCheckedAgent1, setIsCheckedAgent1] = useState(false)

    const [entityTypes, setEntityTypes] = useState([])

    const [visible2, setVisible2] = useState(false)

    const [visible3, setVisible3] = useState(false)

    const [previewData, setPriviewData] = useState(null)

    const [memberType, setMemberType] = useState("")

    const user = JSON.parse(localStorage.getItem('user'));

    const [keyPersonalCompany, setKeyPersonalCompany] = useState(null)

    const [optionsMap, setOptionsMap] = useState({});

    const [allBilingAddress, setAllBilingAddress] = useState([])

    const [allShippingAddress, setAllShippingAddress] = useState([])

    const [bilingAddress, setBilingAddress] = useState(null)

    const [shippingAddress, setShippingAddress] = useState(null)

    const [strapiId, setStrapiId] = useState(null)

    const [formData, setFormData] = useState(null);

    const [updatedOrderLineItems, setUpdatedOrderLineItems] = useState([])

    const [visible8, setVisible8] = useState(false)

    const [closeProgresbar2, setCloseProgresbar2] = useState(false);

    const [closeProgresbar3, setCloseProgresbar3] = useState(false);

    const [isPaymentDone, setIsPaymentDone] = useState(null)

    const [orderTypeId, setOrderTypeId] = useState(0)

    const [allAssignee, setAllAssignee] = useState([])

    const [assigneeData, setAssigneeData] = useState(null)

    const [sidebarVisible, setSidebarVisible] = useState(true);

    const [allDocuments, setAllDocuments] = useState([])

    const [orderHeader, setOrderHeader] = useState(null)

    const [visible9, setVisible9] = useState(false)

    const [IsCheckedAgent2,setIsCheckedAgent2]=useState(false)


    const setOptionsForField = (fieldKey, options) => {
        setOptionsMap(prev => ({
            ...prev,
            [fieldKey]: options
        }));
    };

    useEffect(() => {
        MasterServices.getAll("/core/api/entityTypeMaster/getAll")
            .then((res) => {
                console.log(res.data)
                if (res.data !== "") {
                    setEntityTypes(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
        ContactService.getAllContact()
            .then((res) => {
                console.log(res)
                let data = res.data

                const tempdata = data.filter((val) => val.contactDetails.emailId === user.username)
                console.log(tempdata)
                if (tempdata.length > 0) {
                    if (tempdata[0].contactDetails !== undefined) {
                        setContactId(tempdata[0].contactDetails.id)
                    }

                }


            })
            .catch((err) => {
                console.log(err)
            })
        AddressService.getAllAddressType()
            .then((res) => {
                if (res.data !== undefined) {
                    if (res.data !== "") {

                        if (Array.isArray(res.data)) {
                            setAllAddressType(res.data)

                        }
                    }
                }


            })
            .catch((err) => {
                console.log(err)
            })

        MasterServices.getAllDocuments()
            .then((res) => {
                console.log(res)
                if (Array.isArray(res.data)) {
                    setAllDocuments(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])



    const [allKeyPersonnelType, setAllKeyPersonnelType] = useState([])

    const [allAddressType, setAllAddressType] = useState([])

    const [keyPersonalAddressType, setKeyPersonalAddressType] = useState(null)



    useEffect(() => {
        companyMasterService.getAllKeyPersonnelType()
            .then((res) => {
                console.log(res)
                if (res.data !== "") {
                    setAllKeyPersonnelType(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])




    const handleCheckedChange = (e, serviceData) => {
        const { checked } = e.target;

        setCheckedState((prev) => ({
            ...prev,
            [serviceData.product.displayName]: checked,
        }));

        if (checked) {
            setSelectedCheckBoxData((prev) => [...prev, serviceData]);
        } else {
            setSelectedCheckBoxData((prev) =>
                prev.filter((item) => item.product.displayName !== serviceData.product.displayName)
            );
        }
    };

    // Initialize Bootstrap tooltips
    useEffect(() => {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltipTriggerList.forEach((tooltipTriggerEl) => {
            new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
    }, []);



    const calculatePrice = (serviceData, quantity) => {
        if (serviceData.product.isBundlePriceApplicable) {

            const { noOfItemsInBundle, priceOfBundle, priceForAddlItemsOverBundle } = serviceData.product;

            if (quantity <= noOfItemsInBundle) {

                return priceOfBundle;
            } else {

                const bundledPrice = priceOfBundle;
                const additionalItemsPrice = priceForAddlItemsOverBundle * (quantity - noOfItemsInBundle);
                return bundledPrice + additionalItemsPrice;
            }
        } else {

            return serviceData.product.unitPrice;
        }
    };


    const calculateTotalAmount = () => {
        return selectedCheckBoxData.reduce((total, val) => {
            const quantity = formCount;
            const itemTotal = calculatePrice(val, quantity);
            return total + itemTotal;
        }, 0);
    };



    const [creditAll, setCreditAll] = useState([]);

    const [creditInfo, setCreditInfo] = useState(null);

    const [enityName, setEntityName] = useState(null);

    const [state, setState] = useState(null);

    const [inputValue, setInputValue] = useState("");
    const [isAvailable, setIsAvailable] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const [llcMember, setLLCMember] = useState([]);

    const [llc, setLlc] = useState(null);

    const [allLLC, setAllLLC] = useState([]);

    const [llcName, setLLCName] = useState(null);

    const [visible, setVisible] = useState(false);

    const [selectCheckBoxData, setSelectCheckBoxData] = useState([]);

    const [IsChecked1, setIsChecked1] = useState(false);

    const [IsChecked2, setIsChecked2] = useState(false);

    const [IsChecked3, setIsChecked3] = useState(false);

    const [IsChecked4, setIsChecked4] = useState(false);

    const [IsChecked5, setIsChecked5] = useState(false);

    const [IsChecked6, setIsChecked6] = useState(false);

    const [IsChecked7, setIsChecked7] = useState(false);

    const [IsChecked8, setIsChecked8] = useState(false);

    const [IsChecked9, setIsChecked9] = useState(false);

    const [IsChecked10, setIsChecked10] = useState(false);

    const [IsChecked11, setIsChecked11] = useState(false);

    const [currentTab, setCurrentTab] = useState(0);

    const [closeProgresbar, setCloseProgresbar] = useState(false);

    const [closeProgresbar1, setCloseProgresbar1] = useState(false);

    const [dba, setDba] = useState("");

    const [name, setName] = useState("");

    const [AlternateName, setAlternateName] = useState("");

    const [llcAddress, setLLCAddress] = useState("");

    const [mailingAddress, setMailingAddress] = useState("");

    const [initData, setInitData] = useState(null);

    const [initData1, setInitData1] = useState(null);

    const [initData2, setInitData2] = useState(null);

    const [allInitData, setAllInitData] = useState([]);

    const [inputFormData, setInputFormData] = useState([]);

    const [allModels, setAllModels] = useState([]);

    const [modelData, setModelData] = useState([]);

    const [modelData1, setModelData1] = useState([]);

    const [IsSuccessful, setIsSuccessFul] = useState(false);

    const [modelName, setModelName] = useState(null);

    const [currentStep, setCurrentStep] = useState(1);

    const [activeStep, setActiveStep] = React.useState(0);

    const [globalModel, setGlobalModel] = useState([])


    const [allComponents, setAllComponents] = useState([])

    const [mainModel, setMainModel] = useState(null)

    const [rawModel, setRawModel] = useState([])

    const [groupInitialData, setGroupInitialData] = useState({})

    const [showDescription, setShowDescription] = useState(false);

    const [organizerData, setOrganizerData] = useState(null)

    const [allOrganizer, setAllOrganizer] = useState([])


    const [director, setDirector] = useState(null)

    const [allDirector, setAllDirector] = useState([])

    const [officer, setOfficer] = useState(null)

    const [allOfficer, setAllOfficer] = useState([])

    const [pageRefresh, setPageRefresh] = useState(false)

    const [allCompany, setAllCompany] = useState([])

    const [company, setCompany] = useState(null)

    const [allLocation, setAllLocation] = useState([])

    const [location, setLocation] = useState(null)

    const [allInCorporator, setAllInCorporator] = useState([])

    const [incorporator, setIncorporator] = useState(null)

    const [allCountries, setAllCountries] = useState([])

    const [agentLocation, setAgentLocation] = useState(null)

    const [allCounty, setAllCounty] = useState([])

    const [servicesData, setServiceData] = useState([])

    const [addOnData, setAddOnData] = useState(null)

    const [aallDebtor, setAllDebtor] = useState([])

    const [allSecuredParty, setAllSecuredParty] = useState([])


    const [allCompanyApplicant, setAllCompanyApplicant] = useState([])

    const [allBeneficialOwner, setAllBeneficialOwner] = useState([])


    const [allFiler, setAllFiler] = useState([])

    const [filer, setFiler] = useState(null)

    const [allPresident, setAllPresident] = useState([])

    const [president, setPresident] = useState(null)

    const [allTreasurer, setAllTreasurer] = useState([])

    const [treasurer, setTreasurer] = useState(null)

    const [allSecretary, setAllSecretary] = useState([])

    const [secretary, setSecretary] = useState(null)


    const [allAdminCompany, setAllAdminCompany] = useState([])

    const [adminCompany, setAdminCompany] = useState(null)

    const [allBilingInfo, setAllBilingInfo] = useState([])

    const [bilingInfo, setBilingInfo] = useState(null)

    const [allMailingInfo, setAllMailingInfo] = useState([])

    const [mailingInfo, setMailingInfo] = useState(null)


    useEffect(() => {
        companyMasterService.getAllCompany()
            .then((res) => {
                console.log(res)
                let data = res.data
                setAllCompany(res.data)
                if (Array.isArray(res.data)) {
                    const temp = res.data.filter((val) => val.companyName === "vState Filings")

                    if (globalCompany) {
                        if (globalCompany.companyDetails) {
                            console.log(globalCompany)
                            const keyCompany = res.data.find((val) => val.id === globalCompany.companyDetails.id)
                            setKeyPersonalCompany(keyCompany)
                        }


                    }
                    if (temp.length > 0) {
                        setCompany(temp[0])
                    }
                }

            })
            .catch((err) => {
                console.log(err)
            })
        if (user) {
            ContactService.getAllContactByUserId(user.id)
                .then((res) => {
                    console.log(res)
                    if (res.data !== "") {
                        let data = res.data.companyDetails
                        setAllAdminCompany(res.data.companyDetails)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [globalCompany])



    useEffect(() => {

        if (enityName && state && selectedCheckBoxData.length === 0) {
            console.log(state)
            MasterServices.getAllProduct(orderTypeId, enityName.id, state.id, county ? county.id : 0)
                .then((res) => {
                    console.log(res)
                    if (Array.isArray(res.data)) {
                        const sortedData = res.data.sort((a, b) => a.product.displayPreference - b.product.displayPreference)
                        setServiceData(sortedData)
                        const temp = res.data.filter((val) => val.product.isDefault === true)
                        // setSelectedCheckBoxData(temp)

                    }
                    else {
                        setServiceData([])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
            AddressService.getAllCountyByState(state.id)
                .then((res) => {
                    console.log(res)
                    setAllCounty(res.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }, [enityName, state, orderTypeId])


    useEffect(() => {

        if (editPayloadData) {
            if (Array.isArray(editPayloadData.orderLineItem)) {
                if (editPayloadData.orderLineItem.length > 0) {
                    console.log(servicesData)
                    console.log(editPayloadData.orderLineItem)
                    const temp = servicesData.filter((val) => editPayloadData.orderLineItem.some((ele) => ele.productListing.id === val.product.id))
                    console.log("filter product", temp)
                    if (temp.length > 0) {
                        temp.forEach((serviceData) => {
                            setCheckedState((prev) => ({
                                ...prev,
                                [serviceData.product.displayName]: true,
                            }));
                        })
                    }
                    setSelectedCheckBoxData(temp)
                }
            }
        }


    }, [servicesData])

    useEffect(() => {
        if (globalCompany) {
            AddressService.getAllAddressByCompanyId(globalCompany.companyDetails.id)
                .then((res) => {
                    console.log(res)
                    if (res.data !== "") {
                        console.log(res.data)
                        if (Array.isArray(res.data)) {
                            const temp = res.data.filter((val) => val.addressType.type === "PRINCIPAL")
                            const temp1 = res.data.filter((val) => val.addressType.type === "BILLING")
                            const temp2 = res.data.filter((val) => val.addressType.type === "SHIPPING")

                            if (temp.length > 0) {
                                setAllLocation(temp)
                            }

                        }


                    }
                    else {

                        setAllLocation([])
                        setAllBilingAddress([])
                        setAllShippingAddress([])
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }, [globalCompany, isTabRefresh])



    useEffect(() => {
        tempdata.forEach(matchedItem => {
            if (matchedItem && matchedItem.getAllUrl) {
                MasterServices.getAll(matchedItem.getAllUrl)
                    .then(fetchedData => {
                        if (Array.isArray(fetchedData.data)) {
                            const labelKey = matchedItem.label || "name";
                            const newOptions = fetchedData.data;
                            setOptionsMap(prev => ({ ...prev, [matchedItem.item]: newOptions }));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    }, []);




    useEffect(() => {
        if (globalCompany) {
            if (globalCompany.companyDetails) {
                companyMasterService.getAllKeyPersonnelByCompanyId(globalCompany.companyDetails.id)
                    .then((res) => {
                        console.log(res)
                        if (res.data !== "") {
                            if (Array.isArray(res.data)) {

                                const temp = res.data.filter((val) => val.keyPersonnelTypeId === 1)
                                const temp1 = res.data.filter((val) => val.keyPersonnelTypeId === 13)

                                const temp2 = res.data.filter((val) => val.keyPersonnelTypeId === 4)

                                const temp3 = res.data.filter((val) => val.keyPersonnelTypeId === 5)

                                const temp4 = res.data.filter((val) => val.keyPersonnelTypeId === 3)

                                const temp5 = res.data.filter((val) => val.keyPersonnelTypeId === 12)

                                const temp6 = res.data.filter((val) => val.keyPersonnelTypeId === 10)

                                const temp7 = res.data.filter((val) => val.keyPersonnelTypeId === 9)

                                const temp8 = res.data.filter((val) => val.keyPersonnelTypeId === 11)

                                const temp9 = res.data.filter((val) => val.keyPersonnelTypeId === 14)

                                const temp10 = res.data.filter((val) => val.keyPersonnelTypeId === 15)

                                const temp11 = res.data.filter((val) => val.keyPersonnelTypeId === 16)

                                const temp12 = res.data.filter((val) => val.keyPersonnelTypeId === 1000)

                                const temp13 = res.data.filter((val) => val.keyPersonnelTypeId === 1001)

                                const temp14 = res.data.filter((val) => val.keyPersonnelTypeId === 1002)


                                const temp15 = res.data.filter((val) => val.keyPersonnelTypeId === 20)

                                const temp16 = res.data.filter((val) => val.keyPersonnelTypeId === 21)

                                console.log(temp)
                                setAllOrganizer(temp)
                                setAllInCorporator(temp1)
                                setAllBilingAddress(temp2)
                                setAllShippingAddress(temp3)
                                setAllOfficer(temp5)
                                setAllDirector(temp4)
                                setAllSecuredParty(temp7)
                                setAllDebtor(temp6)
                                setAllCompanyApplicant(temp9)
                                setAllBeneficialOwner(temp10)
                                setAllAssignee(temp8)
                                setAllFiler(temp11)
                                setAllPresident(temp12)
                                setAllTreasurer(temp13)
                                setAllSecretary(temp14)
                                setAllBilingInfo(temp15)
                                setAllMailingInfo(temp16)
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        }
    }, [visible2, globalCompany])



    const toggleDescription = () => {
        setShowDescription(!showDescription);
    };

    const [formCount, setFormCount] = useState(
        1
    );

    const handleRepeatForm = (key) => {
        // if (orderTypeId !== 1 && formCount < 2 || groupedData[key].form[0].apiId !== "boi-company-applicant-details") {
        //     setFormCount((prevCount) => prevCount + 1);
        // }
        if (formCount < 2) {
            setFormCount((prevCount) => prevCount + 1);
        }
    };

    const handleRemoveRepeatForm = (key) => {
        const fieldNames = groupedData[key].form.flatMap((value) => Object.keys(value.schema.attributes));
        if (formCount > 1) {
            fieldNames.forEach((field) => {
                unregister(`${field}-${formCount - 1}`);
            });
            setFormCount(prevCount => prevCount - 1);
        }
    };

    const handleNext = (data) => {
        console.log(errors)
        if (activeStep === data.length - 1) {
            nextPrev(1);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        setValue,
        watch,
        reset,
        unregister,
        clearErrors
    } = useForm("");




    useEffect(() => {

        AddressService.getAllCountry()
            .then((res) => {
                console.log(res)
                if (res.data !== "") {
                    setAllCountries(res.data)
                }

            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if (company && state) {
            AddressService.getAllAddressByCompanyId(company.id)
                .then((res) => {
                    console.log(res)
                    if (res.data !== "") {

                        if (Array.isArray(res.data)) {
                            const temp = res.data.filter((val) => val.addressType.type === "REGISTERED AGENT")
                            console.log(temp)
                            if (temp.length > 0 && state) {
                                const Registred_Address = temp.find((val) => val.stateId === state.id)
                                if (Registred_Address) {
                                    setAgentLocation(Registred_Address)
                                }
                            }

                        }

                    }

                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }, [company, IsCheckedAgent, state])

    const stateFilter = (id) => {
        const temp = allState.filter((val) => val.id === id)
        console.log(temp)
        if (temp.length > 0) {
            return temp[0].stateFullDesc
        }
        else {
            return ""
        }

    }

    const countryFilter = (id) => {
        const temp = allCountries.filter((val) => val.id === id)
        console.log(temp)
        if (temp.length > 0) {
            return temp[0].countryShortName
        }
        else {
            return ""
        }

    }


    function numberToString(number) {
        return number.toString();
    }

    function extractTenDigitPhoneNumber(phoneNumber) {
        const digitsOnly = phoneNumber.replace(/\D/g, '');
        
    
        const tenDigitNumber = digitsOnly.slice(-10);
        
        return Number(tenDigitNumber);
    }


    useEffect(() => {
        if (organizerData) {
            console.log(organizerData)
            setValue("Org_Name", organizerData.keyPersonnelName)
            setValue("Org_Email_Address", organizerData.emailId)
            setValue("Org_Contact_No", extractTenDigitPhoneNumber(organizerData.contactNo))
            setValue("Org_Address_Line_1", organizerData.addressMasterId.addressLine1)
            setValue("Org_Address_Line_2", organizerData.addressMasterId.addressLine2)
            setValue("Org_City", organizerData.addressMasterId.city)
            setValue("Org_State", stateFilter(organizerData.addressMasterId.stateId))
            setValue("Org_Country", countryFilter(organizerData.addressMasterId.countryId))
            setValue("Org_Zip_Code", numberToString(organizerData.addressMasterId.postalCode))

        }
    }, [setValue, organizerData])

    useEffect(() => {
        if (incorporator) {
            console.log(incorporator)
            setValue("Inc_Name", incorporator.keyPersonnelName)
            setValue("Inc_Email_Address", incorporator.emailId)
            setValue("Inc_Contact_No", extractTenDigitPhoneNumber(incorporator.contactNo))
            setValue("Inc_Address_Line_1", incorporator.addressMasterId.addressLine1)
            setValue("Inc_Address_Line_2", incorporator.addressMasterId.addressLine2)
            setValue("Inc_City", incorporator.addressMasterId.city)
            setValue("Inc_State", stateFilter(incorporator.addressMasterId.stateId))
            setValue("Inc_Country", countryFilter(incorporator.addressMasterId.countryId))
            setValue("Inc_Zip_Code", numberToString(incorporator.addressMasterId.postalCode))
        }
    }, [setValue, incorporator])

    useEffect(() => {
        if (officer) {
            console.log(officer)
            setValue("Off_Name", officer.keyPersonnelName)
            setValue("Off_Email_Address", officer.emailId)
            setValue("Off_Contact_No", extractTenDigitPhoneNumber(officer.contactNo))
            setValue("Of_Address_Line_1", officer.addressMasterId.addressLine1)
            setValue("Of_Address_Line_2", officer.addressMasterId.addressLine2)
            setValue("Of_City", officer.addressMasterId.city)
            setValue("Of_State", stateFilter(officer.addressMasterId.stateId))
            setValue("Of_Country", countryFilter(officer.addressMasterId.countryId))
            setValue("Of_Zip_Code", numberToString(officer.addressMasterId.postalCode))
        }
    }, [setValue, officer])

    useEffect(() => {
        if (director) {
            console.log(director)
            setValue("Dir_Name", director.keyPersonnelName)
            setValue("Dir_Email_Address", director.emailId)
            setValue("Dir_Contact_No", extractTenDigitPhoneNumber(director.contactNo))
            setValue("Dir_Address_Line_1", director.addressMasterId.addressLine1)
            setValue("Dir_Address_Line_2", director.addressMasterId.addressLine2)
            setValue("Dir_City", director.addressMasterId.city)
            setValue("Dir_State", stateFilter(director.addressMasterId.stateId))
            setValue("Dir_Country", countryFilter(director.addressMasterId.countryId))
            setValue("Dir_Zip_Code", numberToString(director.addressMasterId.postalCode))
        }
    }, [setValue, director])


    useEffect(() => {
        if (location) {
            console.log(location)
            setValue("PA_Address_Line_1", location.addressLine1)
            setValue("PA_Address_Line_2", location.addressLine2)
            setValue("PA_City", location.city)
            setValue("PA_State", stateFilter(location.stateId))

            setValue("PA_Country", countryFilter(location.countryId))
            setValue("PA_Zip_Code", numberToString(location.postalCode))
        }
    }, [setValue, location])


    useEffect(() => {
        if (assigneeData) {

            setValue("ad_Name_of_Assignee", assigneeData.keyPersonnelName)

            setValue("ad_Address_Line_1", assigneeData.addressMasterId.addressLine1)
            setValue("ad_Address_Line_2", assigneeData.addressMasterId.addressLine2)
            setValue("ad_City", assigneeData.addressMasterId.city)
            setValue("ad_State", stateFilter(assigneeData.addressMasterId.stateId))
            setValue("ad_Zip_Code", numberToString(assigneeData.addressMasterId.postalCode))
        }
    }, [setValue, assigneeData])


    useEffect(() => {
        if (filer) {
            setValue("Filer_Name", filer.keyPersonnelName)
            setValue("Filer_Email_Address", filer.emailId)
            setValue("Filer_Contact_No", extractTenDigitPhoneNumber(filer.contactNo))
            setValue("filer_Address_Line_1", filer.addressMasterId.addressLine1)
            setValue("filer_Address_Line_2", filer.addressMasterId.addressLine2)
            setValue("filer_City", filer.addressMasterId.city)
            setValue("Filer_State", stateFilter(filer.addressMasterId.stateId))
            setValue("Filer_Country", countryFilter(filer.addressMasterId.countryId))
            setValue("filer_Zip_Code", numberToString(filer.addressMasterId.postalCode))
        }
    }, [setValue, filer])

    const handleDebtorChange = (selectedDebtor, index) => {
        console.log("stateid_debtor", selectedDebtor.addressMasterId.stateId)
        setValue(`debt_Name_Of_Debtor-${index}`, selectedDebtor.keyPersonnelName);
        setValue(`debt_Address_Line_1-${index}`, selectedDebtor.addressMasterId.addressLine1);
        setValue(`debt_Address_Line_2-${index}`, selectedDebtor.addressMasterId.addressLine2);
        setValue(`debt_City-${index}`, selectedDebtor.addressMasterId.city);
        setValue(`debt_State-${index}`, stateFilter(selectedDebtor.addressMasterId.stateId));
        setValue(`debt_Zip_Code-${index}`, numberToString(selectedDebtor.addressMasterId.postalCode));
    };



    const handleCompanyApplicantChange = (selectedDebtor, index) => {
        console.log(selectedDebtor)
        setValue(`boica_First_Name-${index}`, selectedDebtor.firstName);
        setValue(`boica_Middle_Name-${index}`, selectedDebtor.middleName);
        setValue(`boica_Last_Name-${index}`, selectedDebtor.lastName);
        setValue(`boica_Suffix-${index}`, selectedDebtor.suffix);
        setValue(`boica_Email-${index}`, selectedDebtor.emailId);
        setValue(`boica_Date_of_Birth-${index}`, selectedDebtor.dateOfBirth);
        setValue(`boica_Residential_Address-${index}`, `${selectedDebtor.addressMasterId.addressLine1},${selectedDebtor.addressMasterId.addressLine2}`);
        // setValue(`debt_Address_Line_2-${index}`, selectedDebtor.addressMasterId.addressLine2);
        setValue(`boica_City-${index}`, selectedDebtor.addressMasterId.city);
        setValue(`boica_Contact_No-${index}`, extractTenDigitPhoneNumber(selectedDebtor.contactNo))
        setValue(`boica_State_or_Province-${index}`, stateFilter(selectedDebtor.addressMasterId.stateId));
        setValue(`boica_Country-${index}`, countryFilter(selectedDebtor.addressMasterId.countryId))
        setValue(`boica_Zip_Code-${index}`, numberToString(selectedDebtor.addressMasterId.postalCode));
        if (Array.isArray(selectedDebtor.documentIds)) {
            const filteredDocuments = allDocuments.filter(doc => selectedDebtor.documentIds.includes(doc.id));
            const uploadIdDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id"));
            const uploadIdPageDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id_page_of_your_document"));
            if (uploadIdDoc) {
                setValue(`boica_Upload_ID-${index}`, uploadIdDoc.documentPath)
            }
            if (uploadIdPageDoc) {
                setValue(`boica_Upload_the_ID_page_of_your_document-${index}`, uploadIdPageDoc.documentPath)
            }
        }
    };



    const handleBeneficialOwnerChange = (selectedDebtor, index) => {

        console.log(selectedDebtor)
        setValue(`boi_First_Name-${index}`, selectedDebtor.firstName);
        setValue(`boi_Middle_Name-${index}`, selectedDebtor.middleName);
        setValue(`boi_Last_Name-${index}`, selectedDebtor.lastName);
        setValue(`boi_Suffix-${index}`, selectedDebtor.suffix);
        setValue(`boi_Email-${index}`, selectedDebtor.emailId);
        setValue(`boi_Date_of_Birth-${index}`, selectedDebtor.dateOfBirth);
        setValue(`boi_Residential_Address-${index}`, `${selectedDebtor.addressMasterId.addressLine1},${selectedDebtor.addressMasterId.addressLine2}`);
        // setValue(`debt_Address_Line_2-${index}`, selectedDebtor.addressMasterId.addressLine2);
        setValue(`boi_City-${index}`, selectedDebtor.addressMasterId.city);
        setValue(`boi_Contact_No-${index}`, extractTenDigitPhoneNumber(selectedDebtor.contactNo))
        setValue(`boi_State-${index}`, stateFilter(selectedDebtor.addressMasterId.stateId));
        setValue(`boi_Country-${index}`, countryFilter(selectedDebtor.addressMasterId.countryId))
        setValue(`boi_Zip_Code-${index}`, numberToString(selectedDebtor.addressMasterId.postalCode));
        if (Array.isArray(selectedDebtor.documentIds)) {
            const filteredDocuments = allDocuments.filter(doc => selectedDebtor.documentIds.includes(doc.id));
            const uploadIdDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id"));
            const uploadIdPageDoc = filteredDocuments.find(doc => doc.documentName.includes("upload_id_page_of_your_document"));
            if (uploadIdDoc) {
                setValue(`boi_Upload_ID-${index}`, uploadIdDoc.documentPath)
            }
            if (uploadIdPageDoc) {
                setValue(`boi_Upload_the_ID_page_of_your_document-${index}`, uploadIdPageDoc.documentPath)
            }
        }
    };


    const handleAdditionalDebtorChange = (selectedDebtor, index) => {
        setValue(`uccAmend_Name_of_Debtor-${index}`, selectedDebtor.keyPersonnelName);
        setValue(`uccAmend_Address_Line_1-${index}`, selectedDebtor.addressMasterId.addressLine1);
        setValue(`uccAmend_Address_Line_2-${index}`, selectedDebtor.addressMasterId.addressLine2);
        setValue(`uccAmend_City-${index}`, selectedDebtor.addressMasterId.city);
        setValue(`uccAmend_State-${index}`, stateFilter(selectedDebtor.addressMasterId.stateId));
        setValue(`uccAmend_Zip_Code-${index}`, numberToString(selectedDebtor.addressMasterId.postalCode));
    };

    const handleAdditionalSecuredPartyChange = (selectedDebtor, index) => {
        console.log(selectedDebtor.addressMasterId.stateId)
        setValue(`sp_Name_of_Secured_Party-${index}`, selectedDebtor.keyPersonnelName);
        setValue(`sp_Address_Line_1-${index}`, selectedDebtor.addressMasterId.addressLine1);
        setValue(`sp_Address_Line_2-${index}`, selectedDebtor.addressMasterId.addressLine2);
        setValue(`sp_City-${index}`, selectedDebtor.addressMasterId.city);
        setValue(`sp_State-${index}`, stateFilter(selectedDebtor.addressMasterId.stateId));
        setValue(`sp_Zip_Code-${index}`, numberToString(selectedDebtor.addressMasterId.postalCode));
    };

    const handleAdditionalSecuredPartyChange1 = (selectedDebtor, index) => {
        console.log(selectedDebtor.addressMasterId.stateId)
        setValue(`sec_Name_Of_Secured_Party-${index}`, selectedDebtor.keyPersonnelName);
        setValue(`sec_Address_Line_1-${index}`, selectedDebtor.addressMasterId.addressLine1);
        setValue(`sec_Address_Line_2-${index}`, selectedDebtor.addressMasterId.addressLine2);
        setValue(`sec_City-${index}`, selectedDebtor.addressMasterId.city);
        setValue(`sec_State-${index}`, stateFilter(selectedDebtor.addressMasterId.stateId));
        setValue(`sec_Zip_Code-${index}`, numberToString(selectedDebtor.addressMasterId.postalCode));
    };



    useEffect(() => {
        if (president) {
            setValue("Pre_Name", president.keyPersonnelName)
            setValue("Pre_Address", president.addressMasterId.addressLine1)
            setValue("Pre_City", president.addressMasterId.city)
            setValue("Pre_Zip_Code", numberToString(president.addressMasterId.postalCode))
        }
    }, [setValue, president])


    useEffect(() => {
        if (treasurer) {
            setValue("Tre_Name", treasurer.keyPersonnelName)
            setValue("Tre_Address", treasurer.addressMasterId.addressLine1)
            setValue("Tre_City", treasurer.addressMasterId.city)
            setValue("Tre_Zip_Code", numberToString(treasurer.addressMasterId.postalCode))
        }
    }, [setValue, treasurer])


    useEffect(() => {
        if (secretary) {
            setValue("Sec_Name", secretary.keyPersonnelName)
            setValue("Sec_Address", secretary.addressMasterId.addressLine1)
            setValue("Sec_City", secretary.addressMasterId.city)
            setValue("Sec_Zip_Code", numberToString(secretary.addressMasterId.postalCode))
        }
    }, [setValue, secretary])


    useEffect(() => {
        if (bilingInfo) {
            console.log(bilingInfo)
            setValue("BI_Name", bilingInfo.keyPersonnelName)
            setValue("BI_Email_Address", bilingInfo.emailId)
            setValue("BI_Contact_No", extractTenDigitPhoneNumber(bilingInfo.contactNo))
            setValue("BI_Address_Line_1", bilingInfo.addressMasterId.addressLine1)
            setValue("BI_Address_Line_2", bilingInfo.addressMasterId.addressLine2)
            setValue("BI_City", bilingInfo.addressMasterId.city)
            setValue("BI_State", stateFilter(bilingInfo.addressMasterId.stateId))
            setValue("BI_Country", countryFilter(bilingInfo.addressMasterId.countryId))
            setValue("BI_Zip_Code", numberToString(bilingInfo.addressMasterId.postalCode))
        }
    }, [setValue, bilingInfo])


    useEffect(() => {
        if (mailingInfo) {
            console.log(mailingInfo)
            setValue("MI_Name", mailingInfo.keyPersonnelName)
            setValue("MI_Email_Address", mailingInfo.emailId)
            setValue("MI_Contact_No", extractTenDigitPhoneNumber(mailingInfo.contactNo))
            setValue("MI_Address_Line_1", mailingInfo.addressMasterId.addressLine1)
            setValue("MI_Address_Line_2", mailingInfo.addressMasterId.addressLine2)
            setValue("MI_City", mailingInfo.addressMasterId.city)
            setValue("MI_State", stateFilter(mailingInfo.addressMasterId.stateId))
            setValue("MI_Country", countryFilter(mailingInfo.addressMasterId.countryId))
            setValue("MI_Zip_Code", numberToString(mailingInfo.addressMasterId.postalCode))
        }
    }, [setValue, mailingInfo])


    useEffect(() => {
        const keys = [
            "MI_Name",
            "MI_Email_Address",
            "MI_Contact_No",
            "MI_Address_Line_1",
            "MI_Address_Line_2",
            "MI_City",
            "MI_State",
            "MI_Country",
            "MI_Zip_Code"
        ];
        if (bilingInfo && IsCheckedAgent1) {
            setValue("MI_Name", bilingInfo.keyPersonnelName)
            setValue("MI_Email_Address", bilingInfo.emailId)
            setValue("MI_Contact_No", extractTenDigitPhoneNumber(bilingInfo.contactNo))
            setValue("MI_Address_Line_1", bilingInfo.addressMasterId.addressLine1)
            setValue("MI_Address_Line_2", bilingInfo.addressMasterId.addressLine2)
            setValue("MI_City", bilingInfo.addressMasterId.city)
            setValue("MI_State", stateFilter(bilingInfo.addressMasterId.stateId))
            setValue("MI_Country", countryFilter(bilingInfo.addressMasterId.countryId))
            setValue("MI_Zip_Code", numberToString(bilingInfo.addressMasterId.postalCode))
            keys.forEach((fieldKey) => {
                const errorMessageTag = document.getElementById(`${fieldKey}--error`);
                if (errorMessageTag) {
                    errorMessageTag.textContent = "";
                }
            });
        }
        else {
            // Clear values when conditions aren't met
            keys.forEach((fieldKey) => setValue(fieldKey, ""));
            // if (state) {
            //     setValue("RA_State", stateFilter(state.id));
            // }
        }
    }, [setValue, IsCheckedAgent1])

    useEffect(() => {
        const keys = [
            "RA_Name",
            "RA_Email_Address",
            "RA_Contact_No",
            "RA_Address_Line_1",
            "RA_Address_Line_2",
            "RA_City",
            "RA_State",
            "RA_Country",
            "RA_Zip_Code"
        ];

        if (company && IsCheckedAgent) {
            console.log(company);
            setValue("RA_Name", company.companyName);
            setValue("RA_Email_Address", company.companyEmail);
            setValue("RA_Contact_No", extractTenDigitPhoneNumber(company.phoneNo));

            if (agentLocation) {
                console.log(agentLocation);
                setValue("RA_Address_Line_1", agentLocation.addressLine1);
                setValue("RA_Address_Line_2", agentLocation.addressLine2);
                setValue("RA_City", agentLocation.city);
                setValue("RA_State", stateFilter(agentLocation.stateId));
                setValue("RA_Country", countryFilter(agentLocation.countryId));
                setValue("RA_Zip_Code", numberToString(agentLocation.postalCode));
            }

            // Clear error messages for each key
            keys.forEach((fieldKey) => {
                const errorMessageTag = document.getElementById(`${fieldKey}--error`);
                if (errorMessageTag) {
                    errorMessageTag.textContent = "";
                }
            });
        }
        if (company && !IsCheckedAgent && getValues("RA_Name") === "vState Filings") {
            keys.forEach((fieldKey) => setValue(fieldKey, ""));
        }
    }, [company, IsCheckedAgent, agentLocation]);

    
    useEffect(() => {
        const keys = [
            "MI_Name",
            "MI_Email_Address",
            "MI_Contact_No",
            "MI_Address_Line_1",
            "MI_Address_Line_2",
            "MI_City",
            "MI_State",
            "MI_Country",
            "MI_Zip_Code"
        ];

        if (company && IsCheckedAgent2) {
            console.log(company);
            setValue("MI_Name", company.companyName);
            setValue("MI_Email_Address", company.companyEmail);
            setValue("MI_Contact_No", extractTenDigitPhoneNumber(company.phoneNo));

            if (agentLocation) {
                console.log(agentLocation);
                setValue("MI_Address_Line_1", agentLocation.addressLine1);
                setValue("MI_Address_Line_2", agentLocation.addressLine2);
                setValue("MI_City", agentLocation.city);
                setValue("MI_State", stateFilter(agentLocation.stateId));
                setValue("MI_Country", countryFilter(agentLocation.countryId));
                setValue("MI_Zip_Code", numberToString(agentLocation.postalCode));
            }

            // Clear error messages for each key
            keys.forEach((fieldKey) => {
                const errorMessageTag = document.getElementById(`${fieldKey}--error`);
                if (errorMessageTag) {
                    errorMessageTag.textContent = "";
                }
            });
        } else {
            // Clear values when conditions aren't met
            keys.forEach((fieldKey) => setValue(fieldKey, ""));
           
        }
    }, [company, IsCheckedAgent2, agentLocation]);


    useEffect(() => {
        const keys = [
            "X_Name_of_Entity"
        ];
        if (adminCompany) {
            setValue("X_Name_of_Entity", adminCompany)
            // Clear error messages for each key
            keys.forEach((fieldKey) => {
                const errorMessageTag = document.getElementById(`${fieldKey}--error`);
                if (errorMessageTag) {
                    errorMessageTag.textContent = "";
                }
            });
        }

    }, [adminCompany])

    useEffect(() => {
        if (getValues("X_Name_of_Entity") !== undefined) {
            setAdminCompany(getValues("X_Name_of_Entity"))
        }
    }, [getValues, setValue])




    function flattenObject(obj) {
        let result = {};
        let localFormCount;

        function recurse(current, tempKeySuffix = '') {
            console.log(current)
            for (let key in current) {
                console.log(current)
                if (current.hasOwnProperty(key)) {
                    if (Array.isArray(current[key])) {

                        localFormCount = current[key].length


                        current[key].forEach(item => {
                            if (item.hasOwnProperty("__temp_key__")) {
                                const tempKey = item["__temp_key__"];
                                recurse(item, `-${tempKey}`);
                            }
                        });
                    } else if (typeof current[key] === 'object' && current[key] !== null) {
                        console.log(current)
                        recurse(current[key], tempKeySuffix);
                        localFormCount = 1
                    } else {

                        result[`${key}${tempKeySuffix}`] = current[key];
                        localFormCount = 1
                    }
                }
            }
        }

        recurse(obj);


        return { flattenedData: result, localFormCount };
    }


    console.log("checked state", checkedState)



    const getInitials = (name) => {
        const nameArray = name.trim().split(" ");
        if (nameArray.length >= 2) {
            const firstNameInitial = nameArray[0].charAt(0).toUpperCase();
            const lastNameInitial = nameArray[1].charAt(0).toUpperCase();
            return firstNameInitial + lastNameInitial;
        } else {

            const initial = nameArray[0].charAt(0).toUpperCase();
            return initial + initial;
        }
    };

    const handleFile = (e, data) => {
        console.log(e.target.files[0])
        const errorMessageTag = document.getElementById(`${data}--error`);
        if (errorMessageTag) {
            errorMessageTag.textContent = ""
        }
        const formdata = new FormData();
        formdata.append("file", e.target.files[0])
        MasterServices.saveFile("orderdoc", formdata)
            .then((res) => {
                console.log(res)
                setValue(data, res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getCounty = (id) => {
        if (id) {
            const temp = allCounty.find((val) => val.id === id)
            console.log(temp)
            if (temp) {
                return temp;
            }
        }
        return null;
    }

    useEffect(() => {
        console.log("edit data", editPayloadData)
        if (editPayloadData && allInitData.length > 0) {
            setFormData(editPayloadData.orderDetails)
            setUpdatedOrderLineItems(editPayloadData.orderLineItem)
            const jsonPayloadData = JSON.parse(editPayloadData.orderDetails.strapiOrderFormJson)
            console.log(jsonPayloadData)
            if (jsonPayloadData) {
                console.log(jsonPayloadData)
                setEntityName(jsonPayloadData.EntityType)
                setOrderHeader(jsonPayloadData.Header)
                setState(jsonPayloadData.State)
                setCounty(jsonPayloadData.County)
                setValue("state", jsonPayloadData.State)
                setValue("county", jsonPayloadData.County)
                console.log(editPayloadData.companyKeyPersonal)

                setIsPaymentDone(editPayloadData.orderDetails.transactionId)
                const strapiData = jsonPayloadData.Payload
                setStrapiId(editPayloadData.orderDetails.strapiOrderId)
                setOrderTypeId(editPayloadData.orderDetails.orderTypeId)
                setBilingAddress(jsonPayloadData.billingAddress)
                setShippingAddress(jsonPayloadData.shipingAddress ? jsonPayloadData.shipingAddress : jsonPayloadData.billingAddress)
                setAddOnData(jsonPayloadData.addOnService)
                console.log(strapiData)
                const { flattenedData, localFormCount } = flattenObject(strapiData);
                console.log(localFormCount)
                console.log(flattenedData)
                setFormCount(localFormCount)
                if (typeof flattenedData === "object") {
                    Object.keys(flattenedData).map((key, i) => {
                        setValue(key, flattenedData[key])
                    })
                }
                if (Array.isArray(editPayloadData.companyKeyPersonal)) {
                    editPayloadData.companyKeyPersonal.forEach((val) => {
                        console.log(val)

                        if (val.companyKeyPersonnel.keyPersonnelTypeId === 1) {
                            setOrganizerData(val.companyKeyPersonnel)
                        }
                        if (val.companyKeyPersonnel.keyPersonnelTypeId === 12) {
                            setOfficer(val.companyKeyPersonnel)
                        }
                        if (val.companyKeyPersonnel.keyPersonnelTypeId === 13) {
                            setIncorporator(val.companyKeyPersonnel)
                        }
                        if (val.companyKeyPersonnel.keyPersonnelTypeId === 3) {
                            setDirector(val.companyKeyPersonnel)
                        }
                        if (val.companyKeyPersonnel.keyPersonnelTypeId === 16) {
                            setFiler(val.companyKeyPersonnel)
                        }
                        if (val.companyKeyPersonnel.keyPersonnelTypeId === 1000) {
                            setPresident(val.companyKeyPersonnel)
                        }
                        if (val.companyKeyPersonnel.keyPersonnelTypeId === 1001) {
                            setTreasurer(val.companyKeyPersonnel)
                        }
                        if (val.companyKeyPersonnel.keyPersonnelTypeId === 1002) {
                            setSecretary(val.companyKeyPersonnel)
                        }

                    })
                    const beneficialArr = editPayloadData.companyKeyPersonal.filter((val) => val.companyKeyPersonnel.keyPersonnelTypeId === 15)
                    const comapnyApplicannt = editPayloadData.companyKeyPersonal.filter((val) => val.companyKeyPersonnel.keyPersonnelTypeId === 14)
                    if (beneficialArr.length > 0) {
                        beneficialArr.forEach((val, l) => {
                            setValue(`ucc_Beneficial_Dropdown-${l}`, val.companyKeyPersonnel)
                        })
                    }
                    if (comapnyApplicannt.length > 0) {
                        comapnyApplicannt.forEach((val, l) => {
                            setValue(`company_applicant_item_${l}`, val.companyKeyPersonnel)
                        })
                    }
                }
            }
        }
    }, [editPayloadData, allInitData])

    useEffect(() => {
        const data = watch();
        console.log(data);


        const tem = allModels.map((val) => {
            console.log(val);
            const temps = Object.keys(data).reduce((acc, key) => {
                if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
                    acc[key] = data[key];
                }
                return acc;
            }, {});

            const convertdata = val.apiId;

            return {
                model: convertdata,
                data: temps,
            };
        });


        console.log(allModels)
        const tempsData = allModels.map((val) => {
            console.log(val);
            const temps = Object.keys(data).reduce((acc, key) => {
                console.log(key)

                if (Object.keys(val.schema.attributes).some((ele) => ele === key)) {
                    acc[key] = data[key];
                }


                return acc;
            }, {});

            console.log(temps)



            const convertdata = val.uid;
            console.log(convertdata)

            if (convertdata === "momm.name-and-address" || convertdata === "momm.option-name-address") {

                const addressFields = ["MM_Address_Line_1", "MM_Address_Line_2", "MM_City", "MM_State", "MM_Country", "MM_Zip_Code"];
                const formData = Object.keys(data).reduce((acc, key) => {
                    if (key.includes('-')) {
                        const [baseKey, index] = key.split('-');
                        console.log(baseKey)

                        if (baseKey) {
                            // Initialize an object for this index if it doesn't exist
                            if (!acc[index]) {
                                acc[index] = { __temp_key__: parseInt(index), Address: {} };
                            }

                            if (addressFields.includes(baseKey)) {
                                // If it's an address field, add it to the Address object
                                acc[index].Address[baseKey] = data[key];
                            } else {
                                // Otherwise, add it directly to the object
                                acc[index][baseKey] = data[key];
                            }
                        }
                    }

                    return acc;
                }, []);

                console.log(formData)

                const formDatas = formData.filter((val) => val !== undefined)
                console.log(formDatas)

                if (formDatas.length > 0) {
                    return {
                        model: convertdata,
                        data: formDatas,
                    };
                }
                else {
                    return {
                        model: convertdata,
                        data: temps,
                    };
                }
            }
            else if (convertdata === "debtor.debtor") {

                const addressFields = ["debt_Address_Line_1", "debt_Address_Line_2", "debt_City", "debt_State", "debt_Country", "debt_Zip_Code"];
                const formData = Object.keys(data).reduce((acc, key) => {
                    if (key.includes('-')) {
                        const [baseKey, index] = key.split('-');
                        console.log(baseKey)

                        if (baseKey) {
                            // Initialize an object for this index if it doesn't exist
                            if (!acc[index]) {
                                acc[index] = { __temp_key__: parseInt(index), ucc_Address_Of_Debtor: {} };
                            }

                            if (addressFields.includes(baseKey)) {
                                // If it's an address field, add it to the Address object
                                acc[index].ucc_Address_Of_Debtor[baseKey] = data[key];
                            } else if (baseKey.startsWith("debt") && !addressFields.includes(baseKey)) {
                                // Otherwise, add it directly to the object
                                acc[index][baseKey] = data[key];
                            }
                        }
                    }

                    return acc;
                }, []);

                console.log(formData)

                const formDatas = formData.filter((val) => val !== undefined)
                console.log(formDatas)

                if (formDatas.length > 0) {
                    return {
                        model: convertdata,
                        data: formDatas,
                    };
                }
                else {
                    return {
                        model: convertdata,
                        data: temps,
                    };
                }
            }
            else if (convertdata === "ucc-amend.uuc-amnd-what-are-amending") {

                const addressFields = ["uccAmend_Address_Line_1", "uccAmend_Address_Line_2", "uccAmend_City", "uccAmend_State", "uccAmend_Country", "uccAmend_Zip_Code", "uccAmend_Name_of_Debtor"];

                const spAddressFields = ["sp_Name_of_Secured_Party", "sp_Address_Line_1", "sp_Address_Line_2", "sp_City", "sp_State", "sp_Zip_Code"]

                const formData = Object.keys(data).reduce((acc, key) => {
                    if (key.includes('-')) {
                        const [baseKey, index] = key.split('-');
                        console.log(baseKey);

                        if (baseKey) {

                            // Initialize the uccAmend_Additional_Debtor_Details array if not already created
                            if (!acc.Additional_Secured_Party_Details) {
                                acc.Additional_Secured_Party_Details = [];
                            }

                            // Find the corresponding object in Additional_Secured_Party_Details or create a new one
                            let debtorDetails1 = acc.Additional_Secured_Party_Details.find(item => item.__temp_key__ === parseInt(index));


                            if (!debtorDetails1) {
                                debtorDetails1 = { __temp_key__: parseInt(index) };
                                acc.Additional_Secured_Party_Details.push(debtorDetails1);
                            }

                            // Initialize the uccAmend_Additional_Debtor_Details array if not already created
                            if (!acc.uccAmend_Additional_Debtor_Details) {
                                acc.uccAmend_Additional_Debtor_Details = [];
                            }

                            // Find the corresponding object in uccAmend_Additional_Debtor_Details or create a new one
                            let debtorDetails = acc.uccAmend_Additional_Debtor_Details.find(item => item.__temp_key__ === parseInt(index));
                            console.log(debtorDetails)

                            if (!debtorDetails) {
                                debtorDetails = { __temp_key__: parseInt(index) };
                                acc.uccAmend_Additional_Debtor_Details.push(debtorDetails);
                            }
                            if (baseKey.startsWith("sp")) {
                                // Add address fields to debtorDetails
                                if (spAddressFields.includes(baseKey)) {
                                    debtorDetails1[baseKey] = data[key];
                                }
                            }
                            else {
                                // Add address fields to debtorDetails
                                if (addressFields.includes(baseKey)) {
                                    debtorDetails[baseKey] = data[key];
                                }
                            }


                        }
                    } else if (key.startsWith("uccAmend") && !addressFields.includes(key)) {
                        // For non-indexed fields, add them directly to the main object
                        acc[key] = data[key];
                    }

                    return acc;
                }, {});

                console.log(formData)

                return {
                    model: convertdata,
                    data: formData
                };

            }
            else if (convertdata === "secured-party.secured-party") {

                const addressFields = ["sec_Address_Line_1", "sec_Address_Line_2", "sec_City", "sec_State", "sec_Country", "sec_Zip_Code"];
                const formData = Object.keys(data).reduce((acc, key) => {
                    if (key.includes('-')) {
                        const [baseKey, index] = key.split('-');
                        console.log(baseKey)

                        if (baseKey) {
                            // Initialize an object for this index if it doesn't exist
                            if (!acc[index]) {
                                acc[index] = { __temp_key__: parseInt(index), ucc_Address_Of_Secured_Party: {} };
                            }

                            if (addressFields.includes(baseKey)) {
                                // If it's an address field, add it to the Address object
                                acc[index].ucc_Address_Of_Secured_Party[baseKey] = data[key];
                            } else if (baseKey.startsWith("sec") && !addressFields.includes(baseKey)) {
                                // Otherwise, add it directly to the object
                                acc[index][baseKey] = data[key];
                            }
                        }
                    }

                    return acc;
                }, []);

                console.log(formData)

                const formDatas = formData.filter((val) => val !== undefined)
                console.log(formDatas)

                if (formDatas.length > 0) {
                    return {
                        model: convertdata,
                        data: formDatas,
                    };
                }
                else {
                    return {
                        model: convertdata,
                        data: temps,
                    };
                }
            }
            else if (convertdata === "boi.boi-company-applicant-details") {

                const companyApplicantDetails = ["boica_First_Name", "boica_Middle_Name", "boica_Last_Name", "boica_Suffix", "boica_Date_of_Birth", "boica_Email", "boica_Contact_No", "boica_Residential_Address", "boica_Country",
                    "boica_City", "boica_State_or_Province", "boica_Zip_Code", "boica_Choose_Identity_document_type_to_upload", "boica_Upload_the_ID_page_of_your_document", "boica_Upload_ID"
                ];
                console.log(data)
                const formData = Object.keys(data).reduce((acc, key) => {
                    if (key.includes('-')) {
                        const [baseKey, index] = key.split('-');
                        console.log(baseKey)

                        if (baseKey) {

                            if (!acc[index]) {
                                acc[index] = { __temp_key__: parseInt(index) };
                            }

                            if (companyApplicantDetails.includes(baseKey)) {
                                acc[index][baseKey] = data[key];
                            }

                        }
                    }

                    return acc;
                }, []);

                console.log(formData)

                const formDatas = formData.filter((val) => val !== undefined)
                console.log(formDatas)

                if (formDatas.length > 0) {
                    return {
                        model: convertdata,
                        data: formDatas,
                    };
                }
                else {
                    return {
                        model: convertdata,
                        data: temps,
                    };
                }
            }
            else if (convertdata === "boi-except-entity-form") {

                const companyApplicantDetails = ["boi_Last_Name_or_Business_Legal_Name"];
                console.log(data)
                const formData = Object.keys(data).reduce((acc, key) => {
                    if (key.includes('-')) {
                        const [baseKey, index] = key.split('-');
                        console.log(baseKey)

                        if (baseKey) {

                            if (!acc[index]) {
                                acc[index] = { __temp_key__: parseInt(index), boi_Exempt_Entity_Information: {} };
                            }

                            if (companyApplicantDetails.includes(baseKey)) {
                                acc[index].boi_Exempt_Entity_Information[baseKey] = data[key];
                            }

                        }
                    }

                    return acc;
                }, []);

                console.log(formData)

                const formDatas = formData.filter((val) => val !== undefined)
                console.log(formDatas)

                if (formDatas.length > 0) {
                    return {
                        model: convertdata,
                        data: formDatas,
                    };
                }
                else {
                    return {
                        model: convertdata,
                        data: temps,
                    };
                }
            }
            else if (convertdata === "boi.boi-beneficial-owner-form") {

                const companyApplicantDetails = ["boi_First_Name", "boi_Middle_Name", "boi_Last_Name", "boi_Suffix", "boi_Date_of_Birth", "boi_Email", "boi_Contact_No", "boi_Residential_Address", "boi_Country",
                    "boi_City", "boi_State", "boi_Zip_Code", "boi_Choose_Identity_document_type_to_upload", "boi_Upload_the_ID_page_of_your_document", "boi_Upload_ID", "boi_Have_a_FinCEN_ID", "boi_FinCEN_ID"
                ];
                console.log(data)
                const formData = Object.keys(data).reduce((acc, key) => {
                    if (key.includes('-')) {
                        const [baseKey, index] = key.split('-');
                        console.log(baseKey)

                        if (baseKey) {
                            if (!acc[index]) {
                                acc[index] = { __temp_key__: parseInt(index), boi_Beneficial_Owner_Information: {} };
                            }

                            if (companyApplicantDetails.includes(baseKey)) {
                                console.log(baseKey)
                                acc[index].boi_Beneficial_Owner_Information[baseKey] = data[key];
                            }
                            else if (baseKey.startsWith("boi") && !baseKey.startsWith("boica") && !companyApplicantDetails.includes(baseKey)) {
                                console.log(baseKey);
                                acc[index][baseKey] = data[key];
                            }
                        }
                    }

                    return acc;
                }, []);

                console.log(formData)

                const formDatas = formData.filter((val) => val !== undefined)
                console.log(formDatas)

                if (formDatas.length > 0) {
                    return {
                        model: convertdata,
                        data: formDatas,
                    };
                }
                else {
                    return {
                        model: convertdata,
                        data: temps,
                    };
                }
            }
            else {

                const formData = Object.keys(data).map((keys, j) => {
                    if (keys.includes('-')) {
                        console.log(keys)
                        const baseKey = keys.split('-')[0];
                        console.log(baseKey)

                        if (baseKey && Object.keys(val.schema.attributes).some((ele) => ele === baseKey)) {
                            console.log(data[keys])
                            return {
                                __temp_key__: j + 1,
                                [baseKey]: data[keys]
                            }
                        }

                    }

                });

                const formDatas = formData.filter((val) => val !== undefined)
                console.log(formDatas)

                if (formDatas.length > 0) {
                    return {
                        model: convertdata,
                        data: formDatas,
                    };
                }
                else {
                    return {
                        model: convertdata,
                        data: temps,
                    };
                }
            }


        });

        console.log(tem);
        console.log(tempsData)
        setModelData(tem);
        setModelData1(tempsData);

    }, [getValues, setValue, currentTab, formCount]);

    useEffect(() => {
        setIsLoading(true)
        AddressService.getAllStates()
            .then((res) => {
                console.log(res)
                if (res.data !== "") {
                    setAllState(res.data)
                }

            })
            .catch((err) => {
                console.log(err)
            })
        ShiftsServices.getInit()
            .then((res) => {
                console.log(res);
                setAllInitData(res.data.data);
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false)
            });

        // ShiftsServices.getInit()
        //     .then((res) => {
        //         console.log(res)
        //         setRawModel(res.data.data)
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })
        CreditCardServices.getComponent()
            .then((res) => {
                console.log(res)
                setAllComponents(res.data.data)
            })
            .catch((err) => {
                console.log(err)


            })
    }, []);

    console.log(rawModel)

    function flattenArray(array) {
        return array.map((item) => {
            const result = {};
            for (const key in item) {
                if (Array.isArray(item[key])) {
                    result[key] = item[key].flat();
                }
            }
            return result;
        });
    }

    // function reorderAttributes(a, b) {
    //     const aObj = a[0];

    //     return b.map(item => {
    //         const apiID = item.apiID;
    //         if (aObj[apiID]) {
    //             const order = aObj[apiID].map(attr => attr.name);
    //             const newAttributes = {};

    //             order.forEach(key => {
    //                 if (item.attributes[key]) {
    //                     newAttributes[key] = item.attributes[key];
    //                 }
    //             });

    //             for (const key in item.attributes) {
    //                 if (!newAttributes[key]) {
    //                     newAttributes[key] = item.attributes[key];
    //                 }
    //             }

    //             item.attributes = newAttributes;
    //         }

    //         return item;
    //     });
    // }

    function reorderAttributes(a, b) {
        console.log(a);

        const temp = b.map((item) => {
            for (let i = 0; i < a.length; i++) {
                const aObj = a[i];
                console.log(aObj);
                console.log(item);
                const apiID = item.schema.singularName;
                console.log(aObj[apiID]);
                if (aObj[apiID]) {
                    const order = aObj[apiID].map((attr) => attr.name);
                    const newAttributes = {};

                    console.log(order);
                    order.forEach((key) => {
                        console.log(key);
                        console.log(item.schema.attributes);
                        if (item.schema.attributes[key] && key) {
                            newAttributes[key] = item.schema.attributes[key];
                            console.log(newAttributes[key]);
                        }
                    });

                    console.log(newAttributes);

                    // for (const key in item.schema.attributes) {
                    //     if (!newAttributes[key]) {
                    //         newAttributes[key] = item.schema.attributes[key];
                    //     }
                    // }

                    item.schema.attributes = newAttributes;
                }
            }
            return item;
        });

        console.log(temp);
        return temp;
    }

    useEffect(() => {
        console.log(addOnData)
        const firstData =
            addOnData ? (addOnData === "kit") || (addOnData === "cgs") ? state !== null && enityName !== null ?
                typeof enityName.entityShortName === "string" && typeof state.strapiDisplayName === "string"
                    ? state.strapiDisplayName.toLowerCase() + "-" + enityName.entityShortName.toLowerCase() + "-" + addOnData
                    : "" : "" : addOnData : state !== null && enityName !== null ?
                typeof enityName.entityShortName === "string" && typeof state.strapiDisplayName === "string"
                    ? state.strapiDisplayName.toLowerCase() + "-" + enityName.entityShortName.toLowerCase()
                    : "" : "";

        console.log(firstData)


        const getTargetModels = (attributes) => {
            return Object.keys(attributes).flatMap((key) => {
                if (attributes[key].type === "component" && attributes[key].component) {
                    const targetModel = attributes[key].component;
                    console.log(targetModel)
                    return targetModel;
                }
                return [];
            });
        };

        const fetchConfigurations = async (models) => {
            const fetchPromises = models.map(async (model) => {
                try {
                    const res = await CreditCardServices.getConfiguration(
                        `api::${model.uid}`
                    );
                    const response = res.data;
                    const tempdata = model.uid;

                    if (response.data !== undefined) {
                        return {
                            [tempdata]:
                                response.data.contentType.layouts.edit !== undefined
                                    ? response.data.contentType.layouts.edit
                                    : {},
                        };
                    }
                } catch (error) {
                    console.log(`Error fetching configuration for ${model.uid}:`, error);
                }
            });

            const results = await Promise.all(fetchPromises);
            return results.filter((val) => val !== undefined);
        };

        const processModels = async (models, processedModels = new Set()) => {
            let allModelsData = [...models];
            console.log(allModelsData)
            const targetModels = models.flatMap((model) => getTargetModels(model.schema.attributes));

            console.log(targetModels)
            const uniqueTargetModels = [...new Set(targetModels)];
            console.log(uniqueTargetModels)
            if (uniqueTargetModels.length === 0) return allModelsData;
            console.log(allComponents)
            const targetModelsData = allComponents.filter((val) =>
                uniqueTargetModels.includes(val.uid) && !processedModels.has(val.uid)
            );
            console.log(targetModelsData)

            allModelsData = [...allModelsData, ...targetModelsData];
            targetModelsData.forEach(val => processedModels.add(val.uid));
            console.log(targetModelsData)

            const configurations = await fetchConfigurations(targetModelsData);
            console.log(configurations)
            const flatArr = flattenArray(configurations);
            console.log(flatArr)

            // Replace attributes with nested relations
            const updatedModels = await replaceNestedRelations(flatArr, allModelsData);
            console.log(updatedModels)
            return updatedModels;
        };

        const processAttributes = async (attributes, processedModels) => {
            const result = {};
            for (let key in attributes) {
                if (attributes[key].type === "component") {
                    // const targetModel = attributes[key].target.split("::")[1].split(".")[0];
                    const targetModel = attributes[key].component;

                    const relatedModel = allComponents.find((model) => model.uid === targetModel);
                    console.log(relatedModel)

                    if (relatedModel && !processedModels.has(relatedModel.uid)) {
                        processedModels.add(relatedModel.uid);
                        result[key] = await processModel(relatedModel, processedModels);
                    } else {
                        result[key] = {};
                    }
                } else {
                    result[key] = attributes[key];
                }
            }
            return result;
        };

        const processModel = async (model, processedModels) => {
            const newModel = { ...model };
            console.log(newModel)
            newModel.schema.attributes = await processAttributes(newModel.schema.attributes, processedModels);
            return newModel;
        };

        const replaceNestedRelations = async (flatArr, allModelsData) => {
            const result = [];
            for (const model of allModelsData) {
                const newModel = await processModel(model, new Set());
                console.log(newModel)
                result.push(newModel);
            }
            return result;
        };

        if (firstData !== "") {
            console.log(allInitData)
            if (enityName !== "" && state !== null) {
                setLoading1(false)
            }

            console.log(firstData)

            const initialModels = allInitData.filter((val) => val.apiID === firstData);
            console.log(allInitData)
            console.log(initialModels)
            // console.log(rawModel)
            // const tempInitial = rawModel.filter((val) => val.apiID === firstData)
            // console.log(tempInitial)
            if (initialModels.length > 0) {
                setGroupInitialData(initialModels[0].schema.attributes)
            }

            if (initialModels.length > 0) {
                setMainModel(initialModels[0])
                processModels(initialModels)
                    .then((finalModels) => {
                        console.log(finalModels)
                        // const cleanedData = finalModels.reduce((acc, model) => {
                        //     acc[model.apiID] = model;
                        //     return acc;
                        // }, {});
                        const temp = finalModels.filter((val) => val.apiID === firstData)
                        if (temp.length > 0) {
                            setLoading1(false)
                        }
                        setInputFormData(temp);
                        console.log('Final data:', temp);
                    })
                    .catch((err) => {
                        console.log('Error processing models:', err);
                    });
            }
        }
    }, [enityName, state, allInitData, rawModel, editPayloadData, addOnData]);






    console.log(inputFormData)


    useEffect(() => {
        const firstData =
            typeof enityName === "string" && typeof state === "string"
                ? state.toLowerCase() + "-" + enityName.toLowerCase()
                : "";
        if (firstData !== "") {
            console.log(firstData);
            console.log(allInitData);
            setModelName(firstData);

        }
    }, [enityName, state]);


    const fetchOptions = async (url) => {
        try {
            const response = await fetch(url);
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
            return [];
        }
    };




    const checkAvailability = async (value) => {
        setLoading(true);
        setError("");
        try {
            // const response = await axios.get(`https://api.example.com/check-availability?value=${value}`);
            // setIsAvailable(response.data.isAvailable);
        } catch (err) {
            setError("Error checking availability");
        }
        setLoading(false);
    };
    // Current tab is set to be the first tab (0)
    useEffect(() => {
        showTab(currentTab);
    }, [currentTab]);



    const getFormErrorMessage = (name) => {
        return errors[name] ? (
            <small className="error-msg font-fam-for-all ">
                {errors[name].message}
            </small>
        ) : (
            <small className="error-msg "></small>
        );
    };

    function convertName(originalString) {

        if (!originalString.includes('_')) {
            return originalString;
        }


        const parts = originalString.split('_');


        const relevantParts = parts.slice(1);

        // Capitalize the first letter of each part and join them with a space
        const formattedString = relevantParts.map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');

        return formattedString;
    }



    const renderFormField = (column, key, val, value, modelname) => {

        const errorMessageTag = document.getElementById(`${val}--error`);
        console.log(key)
        console.log(optionsMap)
        const tempval = convertName(key)
        console.log(tempval)
        const options = optionsMap?.[tempval] || [];
        console.log(options)


        // if (options.length > 0) {
        //     console.log(options)
        //     const matchedItem = tempdata.find(item => item.item === tempval);

        //     if (matchedItem) {
        //         if (matchedItem.label==="stateFullDesc") {
        //             if (state) {
        //                 setValue(val,state.stateFullDesc)
        //             }
        //         }
        //         if (matchedItem.label==="countyName") {
        //             if (county) {
        //                 setValue(val,county.countyName)
        //             }
        //         }
        //         if (matchedItem.label==="countryShortName") {
        //             if (state) {
        //                 setValue(val,state.countryMaster.countryShortName)
        //             }
        //         }
        //     }
        // }

        console.log(val)

        const COA_What_Are_We_Amending = getValues("COA_What_Are_We_Amending")

        const What_Are_We_Changing = getValues("COC_What_Are_We_Changing")

        const cc_What_Type_Document = getValues("cc_What_Type_Document")

        const ucc_UCC_Collateral_Type = getValues("ucc_UCC_Collateral_Type")

        const finCenKey = `boi_Have_a_FinCEN_ID-${val.split('-')[1] || 0}`;
        const finCenId = getValues(finCenKey);

        console.log(finCenId)

        const excludedValues = [
            "COA_New_Name_To_Be_Used",
            "COA_What_Are_We_Changing",
            "COA_Please_Tell_Us_What_You_Want_To_Amend",
            "COC_Please_Include_New_County",
            "COC_Please_Include_New_Address",
            "cc_Specify_Other_Document",
            "ucc_Type_Your_Collateral_Statement",
            "ucc_Collateral_Statement",
            `boi_FinCEN_ID-${val.split('-')[1] || 0}`
            // Add more values as needed
        ];

        if (!excludedValues.includes(val)) {
            switch (column.type) {
                case 'string':
                    switch (column.default) {
                        case "drop-down":
                            if (options.length > 0) {
                                const matchedItem = tempdata.find(item => item.item === tempval);

                                console.log(matchedItem)
                                return (
                                    <div className='input-fields-container'>
                                        <div className='input-fields-main'>
                                            <div className="justify-content-center dialog-form-field-space w-full">
                                                <label htmlFor='' className='form-label font-fam-for-all'>
                                                    {convertName(key)}
                                                    {column.required !== undefined && column.required === true ?
                                                        <span className='form-field-mandatory'>*</span> : ''}
                                                </label>
                                                <span className="p-float-label">
                                                    <Controller
                                                        name={val}
                                                        control={control}
                                                        rules={column.required !== undefined && column.required === true && column.regex !== undefined
                                                            ? {
                                                                required: `${convertName(key)} Required.`,
                                                                pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }
                                                            }
                                                            : column.required !== undefined && column.required === true
                                                                ? { required: `${convertName(key)} Required.` }
                                                                : {}
                                                        }
                                                        render={({ field }) => {
                                                            const defaultValue = matchedItem !== undefined

                                                                ? matchedItem.label === "countyName" && allCounty.length > 0
                                                                    ? allCounty : options : options;

                                                            return (
                                                                <Dropdown
                                                                    id={field.name}
                                                                    filter
                                                                    name={val}
                                                                    // value={field.value || matchedItem !== undefined ? matchedItem.label === "stateFullDesc" ? state ? state.stateFullDesc : "" : matchedItem.label === "countyName" ? county ? county.countyName : "" : matchedItem.label === "countryShortName" ? state ? state.countryMaster.countryShortName : "" : "" : ""}

                                                                    value={field.value}

                                                                    options={defaultValue}
                                                                    optionValue={matchedItem !== undefined ? matchedItem.label : "name"}
                                                                    disabled={modelname === "Principal_Address" || modelname === "Organizer_Information" || modelname === "Incorporator_Information" || (IsCheckedAgent && (IsCheckedAgent && modelname === "Registered_Agent")) || modelname === "ucc_Debtor_Details" || modelname === "ucc_Secured_Party_Details"}

                                                                    optionLabel={matchedItem !== undefined ? matchedItem.label : "name"} // Dynamically set label for the dropdown
                                                                    onChange={(e) => {
                                                                        field.onChange(e.value);
                                                                        errorMessageTag && (errorMessageTag.textContent = '');
                                                                    }}
                                                                    maxSelectedLabels={3}
                                                                    style={{ flexGrow: '1' }}
                                                                    className='dialog-form-input-field-wizard'
                                                                />
                                                            )
                                                        }
                                                        }
                                                    />
                                                </span>
                                                <small id={`${val}--error`} className="error-msg font-fam-for-all"></small>
                                                {getFormErrorMessage(key)}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }

                        case "media":
                            return <>
                                <div className='input-fields-container'>
                                    <div className='input-fields-main'>
                                        <div className="justify-content-center dialog-form-field-space w-full">
                                            <label htmlFor='' className='form-label font-fam-for-all'>
                                                {convertName(key)}
                                                {column.required !== undefined && column.required === true ?
                                                    <span className='form-field-mandatory'>*</span> : ''}
                                            </label>
                                            <span className="p-float-label">
                                                <Controller
                                                    name={val}
                                                    control={control}
                                                    rules={column.required !== undefined && column.required === true && column.regex !== undefined
                                                        ? {
                                                            required: `${convertName(key)} Required.`,
                                                            pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }
                                                        }
                                                        : column.required !== undefined && column.required === true
                                                            ? { required: `${convertName(key)} Required.` }
                                                            : {}
                                                    }
                                                    render={({ field }) => (
                                                        // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                                                        <input type="file" onChange={(e) => handleFile(e, val)} />
                                                    )}
                                                />
                                            </span>
                                            {getValues(val) !== undefined && typeof (getValues(val)) === "string" && <p className='font-web-color font-fam-for-all'>{getValues(val).split('/').pop()}</p>}
                                            <small id={`${val}--error`} className="error-msg font-fam-for-all"></small>
                                            {getFormErrorMessage(key)}
                                        </div>
                                    </div>
                                </div>
                            </>


                        case "date":
                            return (
                                <div className='input-fields-container'>
                                    <div className='input-fields-main'>
                                        <div className="justify-content-center dialog-form-field-space w-full">
                                            <label htmlFor='' className='form-label font-fam-for-all'>
                                                {convertName(key)}
                                                {column.required ? <span className='form-field-mandatory'>*</span> : ''}
                                            </label>
                                            <span className="p-float-label">
                                                <Controller
                                                    name={val}
                                                    control={control}
                                                    rules={column.required ? { required: `${convertName(key)} Required.` } : {}}
                                                    render={({ field }) => (
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                className="w-full strapi-date-picker"
                                                                value={field.value ? dayjs(field.value) : null} // Convert string to Dayjs
                                                                onChange={(newValue) => {
                                                                    // Check if newValue is a valid Dayjs object
                                                                    if (newValue && newValue.isValid()) {
                                                                        // Convert to ISO string
                                                                        const isoString = newValue.toISOString();
                                                                        field.onChange(isoString);
                                                                    } else {
                                                                        field.onChange(null); // Handle invalid date case
                                                                    }
                                                                    errorMessageTag && (errorMessageTag.textContent = '');
                                                                }}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                disableFuture={column.disableFuture || false}
                                                            />
                                                        </LocalizationProvider>
                                                    )}
                                                />
                                            </span>
                                            <small id={`${val}--error`} className="error-msg font-fam-for-all"></small>
                                            {getFormErrorMessage(key)}
                                        </div>
                                    </div>
                                </div>
                            );


                        default:
                            return <>
                                <div className='input-fields-container'>
                                    <div className='input-fields-main'>
                                        <div className=" justify-content-center dialog-form-field-space w-full">

                                            <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                            <span className="p-float-label ">

                                                <Controller name={val}
                                                    control={control}
                                                    rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertName(key)} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.` } : {}}
                                                    render={({ field, fieldState }) => (
                                                        <InputText id={field.name} {...field}
                                                            autoComplete={false}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                clearErrors(key);
                                                                errorMessageTag && (errorMessageTag.textContent = "")
                                                            }} disabled={modelname === "Principal_Address" || modelname === "Organizer_Information" || modelname === "Incorporator_Information" || (IsCheckedAgent && modelname === "Registered_Agent") || (val === "X_Name_of_Entity" && orderTypeId !== 5)}
                                                            style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', }}
                                                            className='dialog-form-input-field-wizard'
                                                            maxLength={50} />
                                                    )} />



                                            </span>
                                            <small id={`${val}--error`} className="error-msg font-fam-for-all "></small>
                                            {getFormErrorMessage(key)}

                                        </div>

                                    </div>

                                </div>
                            </>;
                    }
                case 'text':
                    return <>
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className=" justify-content-center dialog-form-field-space w-full">

                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label ">

                                        <Controller name={val}
                                            control={control}
                                            rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertName(key)} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.` } : {}}
                                            render={({ field, fieldState }) => (
                                                <InputTextarea id={field.name} {...field}
                                                    autoComplete={false} rows={4} cols={100}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        clearErrors(key);
                                                        errorMessageTag && (errorMessageTag.textContent = "")
                                                    }} disabled={modelname === "Principal_Address" || modelname === "Organizer_Information" || modelname === "Incorporator_Information" || (IsCheckedAgent && modelname === "Registered_Agent")}
                                                    style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', }}
                                                    className='dialog-form-input-area-field-wizard'
                                                    maxLength={50} />
                                            )} />



                                    </span>
                                    <small id={`${val}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}

                                </div>

                            </div>

                        </div>
                    </>;
                // case "biginteger":
                //     return<>
                //     <input type="number" />
                //     </>
                case 'biginteger':
                    return <>
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className="justify-content-center dialog-form-field-space w-full">
                
                                    <label htmlFor='' className='form-label font-fam-for-all'>
                                        {convertName(key)} 
                                        {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}
                                    </label>
                                    <span className="p-float-label ">
                
                                        <Controller name={val}
                                            control={control}
                                            rules={column.required !== undefined && column.required === true 
                                                ? { 
                                                    required: `${convertName(key)} Required.`, 
                                                    pattern: { 
                                                        value: /^\d{1,10}$/, // Allow only up to 5 digits
                                                        message: 'Please Enter Valid No Of Positions (1-5 digits)' 
                                                    } 
                                                  } 
                                                : {}}
                                            render={({ field, fieldState }) => (
                                                <input 
                                                    type="number" 
                                                    id={field.name} 
                                                    {...field} 
                                                    disabled={modelname === "Principal_Address" || 
                                                              modelname === "Organizer_Information" || 
                                                              modelname === "Incorporator_Information" || 
                                                              (IsCheckedAgent && 
                                                               (modelname === "Registered_Agent" || 
                                                                modelname === "Director_Information" || 
                                                                modelname === "Officer_Information" || 
                                                                modelname === "Filer_Information"))}
                                                    onInput={(e) => {
                                                        if (e.target.value.length > 10) {
                                                            e.target.value = e.target.value.slice(0, 10); // Allow only 5 digits
                                                        }
                                                        field.onChange(e);
                                                        clearErrors(key);
                                                        errorMessageTag && (errorMessageTag.textContent = "");
                                                    }}
                                                    style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px' }}
                                                    className='dialog-form-input-field-wizard p-inputtext'
                                                />
                                            )}
                                        />
                                    </span>
                                    <small id={`${val}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}
                                </div>
                            </div>
                        </div>
                    </>;
                case 'integer':
                    return <>
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className=" justify-content-center dialog-form-field-space w-full ">

                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label ">

                                        <Controller name={val}
                                            control={control}
                                            rules={column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.`, pattern: { value: /^\d+$/, message: 'Please Enter Valid No Of Positions' } } : {}}
                                            render={({ field, fieldState }) => (
                                                <input type='number' id={field.name} {...field}
                                                    disabled={modelname === "Principal_Address" || modelname === "Organizer_Information" || modelname === "Incorporator_Information" || (IsCheckedAgent && modelname === "Registered_Agent")}

                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        clearErrors(key);
                                                        errorMessageTag && (errorMessageTag.textContent = "")
                                                    }} style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px' }}
                                                    className='dialog-form-input-field-wizard p-inputtext'
                                                />
                                            )} />



                                    </span>
                                    <small id={`${val}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}

                                </div>

                            </div>

                        </div>
                    </>;
                case 'decimal':
                    return <>
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className=" justify-content-center dialog-form-field-space w-full">

                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label ">

                                        <Controller name={val}
                                            control={control}
                                            rules={column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.`, pattern: { value: /^\d+$/, message: 'Please Enter Valid No Of Positions' } } : {}}
                                            render={({ field, fieldState }) => (
                                                <input type='float' id={field.name} {...field}
                                                    disabled={modelname === "Principal_Address" || modelname === "Organizer_Information" || modelname === "Incorporator_Information" || (IsCheckedAgent && modelname === "Registered_Agent")}

                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        clearErrors(key);
                                                        errorMessageTag && (errorMessageTag.textContent = "")
                                                    }} style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px' }}
                                                    className='dialog-form-input-field-wizard p-inputtext'
                                                />
                                            )} />



                                    </span>
                                    <small id={`${val}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}

                                </div>

                            </div>

                        </div>
                    </>;

                case 'email':
                    return <>
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className=" justify-content-center dialog-form-field-space w-full">

                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label ">

                                        <Controller name={val}
                                            control={control}
                                            rules={column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.`, pattern: { value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: 'Please Enter Valid Email' } } : {}}
                                            render={({ field, fieldState }) => (
                                                <input type='email' id={field.name} {...field}
                                                    disabled={modelname === "Principal_Address" || modelname === "Organizer_Information" || modelname === "Incorporator_Information" || (IsCheckedAgent && modelname === "Registered_Agent")}

                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        clearErrors(key);
                                                        errorMessageTag && (errorMessageTag.textContent = "")
                                                    }} style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', borderRadius: '6px' }}
                                                    className='dialog-form-input-field-wizard p-inputtext'
                                                />
                                            )} />



                                    </span>
                                    <small id={`${val}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}

                                </div>

                            </div>

                        </div>
                    </>;
                case "password":
                    return <>

                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className=" justify-content-center dialog-form-field-space w-full">

                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label " >
                                        <Controller
                                            control={control}
                                            name={val}
                                            rules={column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.`, pattern: { value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, message: 'Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.' } } : {}}
                                            render={({ field, fieldState }) => (
                                                <Password
                                                    id={field.name} {...field}
                                                    toggleMask
                                                    inputRef={field.ref}
                                                    autoComplete={false}
                                                    className='dialog-form-input-field-wizard'
                                                />
                                            )} />



                                    </span>
                                    <small id={`${val}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}

                                </div>
                            </div>
                        </div>
                    </>


                case "richtext":
                    return <>

                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className=" justify-content-center dialog-form-field-space w-full">

                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label " >
                                        <Controller
                                            control={control}
                                            name={val}
                                            rules={column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.`, pattern: { value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, message: 'Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.' } } : {}}
                                            render={({ field, fieldState }) => (
                                                // <Password
                                                //     id={field.name} {...field}
                                                //     toggleMask
                                                //     inputRef={field.ref}
                                                //     autoComplete={false}
                                                //     className='dialog-form-input-field-wizard'
                                                // />
                                                <Editor
                                                    id={field.name} {...field}

                                                    inputRef={field.ref} style={{ height: '320px' }} />
                                            )} />



                                    </span>
                                    <small id={`${val}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}

                                </div>
                            </div>
                        </div>
                    </>
                case "boolean":
                    return (
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className="justify-content-center dialog-form-field-space w-full">
                                    <label htmlFor='' className='form-label font-fam-for-all'>
                                        {convertName(key)}
                                        {column.required ? <span className='form-field-mandatory'>*</span> : ''}
                                    </label>
                                    <span className="p-float-label">
                                        <Controller
                                            name={val}
                                            control={control}
                                            defaultValue={false}
                                            rules={column.required ? { required: `${convertName(key)} Required.` } : {}}
                                            render={({ field }) => (
                                                <Switch
                                                    checked={field.value ?? false}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.checked);

                                                    }}
                                                    name={val}
                                                    color="primary"
                                                />
                                            )}
                                        />
                                    </span>
                                    <small id={`${val}--error`} className="error-msg font-fam-for-all"></small>
                                    {getFormErrorMessage(key)}
                                </div>
                            </div>
                        </div>
                    );

                case "date":
                    return (
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className="justify-content-center dialog-form-field-space w-full">
                                    <label htmlFor='' className='form-label font-fam-for-all'>
                                        {convertName(key)}
                                        {column.required ? <span className='form-field-mandatory'>*</span> : ''}
                                    </label>
                                    <span className="p-float-label">
                                        <Controller
                                            name={val}
                                            control={control}
                                            rules={column.required ? { required: `${convertName(key)} Required.` } : {}}
                                            render={({ field }) => (
                                                <DatePicker
                                                    label={convertName(key)}
                                                    value={field.value || null}
                                                    onChange={(newValue) => {
                                                        field.onChange(newValue);
                                                        errorMessageTag && (errorMessageTag.textContent = '');
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    disableFuture={column.disableFuture || false} // Example of a possible column config
                                                />
                                            )}
                                        />
                                    </span>
                                    <small id={`${val}--error`} className="error-msg font-fam-for-all"></small>
                                    {getFormErrorMessage(key)}
                                </div>
                            </div>
                        </div>
                    );
                case "enumeration":
                    if (options.length > 0) {
                        const matchedItem = tempdata.find(item => item.item === tempval);
                        console.log(matchedItem)
                        return (
                            <div className='input-fields-container'>
                                <div className='input-fields-main'>
                                    <div className="justify-content-center dialog-form-field-space w-full">
                                        <label htmlFor='' className='form-label font-fam-for-all'>
                                            {convertName(key)}
                                            {column.required !== undefined && column.required === true ?
                                                <span className='form-field-mandatory'>*</span> : ''}
                                        </label>
                                        <span className="p-float-label">
                                            <Controller
                                                name={val}
                                                control={control}
                                                rules={column.required !== undefined && column.required === true && column.regex !== undefined
                                                    ? {
                                                        required: `${convertName(key)} Required.`,
                                                        pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }
                                                    }
                                                    : column.required !== undefined && column.required === true
                                                        ? { required: `${convertName(key)} Required.` }
                                                        : {}
                                                }
                                                render={({ field }) => (
                                                    <Dropdown
                                                        id={field.name}
                                                        filter
                                                        name={val}
                                                        value={field.value}
                                                        options={options}
                                                        optionValue={matchedItem !== undefined ? matchedItem.label : "name"}
                                                        disabled={modelname === "Principal_Address" || modelname === "Organizer_Information" || modelname === "Incorporator_Information" || (IsCheckedAgent && modelname === "Registered_Agent")}

                                                        optionLabel={matchedItem !== undefined ? matchedItem.label : "name"} // Dynamically set label for the dropdown
                                                        onChange={(e) => {
                                                            field.onChange(e.value);
                                                            errorMessageTag && (errorMessageTag.textContent = '');
                                                        }}
                                                        maxSelectedLabels={3}
                                                        style={{ flexGrow: '1' }}
                                                        className='dialog-form-input-field-wizard'
                                                    />
                                                )}
                                            />
                                        </span>
                                        <small id={`${val}--error`} className="error-msg font-fam-for-all"></small>
                                        {getFormErrorMessage(key)}
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    else {
                        return <>
                            <div className='input-fields-container'>
                                <div className='input-fields-main'>
                                    <div className=" justify-content-center dialog-form-field-space w-full">

                                        <label htmlFor='' className='form-label font-fam-for-all'>{convertName(key)} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>

                                        <span className="p-float-label ">
                                            <Controller
                                                name={val}
                                                control={control}

                                                rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertName(key)} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.` } : {}}
                                                render={({ field }) => <Dropdown id={field.name} filter name="jd" value={field.value} options={column.enum !== undefined ? column.enum : []} onChange={(e) => {
                                                    field.onChange(e.value)
                                                    errorMessageTag && (errorMessageTag.textContent = '')
                                                }
                                                } maxSelectedLabels={3} style={{ flexGrow: '1' }}
                                                    className='dialog-form-input-field-wizard' />}
                                            />
                                        </span>
                                        <small id={`${val}--error`} className="error-msg font-fam-for-all "></small>
                                        {getFormErrorMessage(key)}
                                    </div>
                                </div>
                            </div>

                        </>
                    }

                default:
                    return null;
            }
        }
        else if (What_Are_We_Changing !== undefined && (val !== "COA_New_Name_To_Be_Used") && (val !== "COA_What_Are_We_Changing")) {
            console.log(column)
            switch (What_Are_We_Changing) {
                case "County":

                    return <>
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className=" justify-content-center dialog-form-field-space w-full">

                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertName("COC_Please_Include_New_County")} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>

                                    <span className="p-float-label ">
                                        <Controller
                                            name={"COC_Please_Include_New_County"}
                                            control={control}

                                            rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertName(key)} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.` } : {}}
                                            render={({ field }) => <Dropdown id={field.name} filter name="jd" value={field.value} options={column.enum !== undefined ? column.enum : []} onChange={(e) => {
                                                field.onChange(e.value)
                                                errorMessageTag && (errorMessageTag.textContent = '')
                                            }
                                            } maxSelectedLabels={3} style={{ flexGrow: '1' }}
                                                className='dialog-form-input-field-wizard' />}
                                        />
                                    </span>
                                    <small id={`${val}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}
                                </div>
                            </div>
                        </div>

                    </>



                default:
                    break;
            }
        }

        else if (COA_What_Are_We_Amending !== undefined && (val !== "COA_New_Name_To_Be_Used") && (val !== "COA_What_Are_We_Changing")) {
            switch (COA_What_Are_We_Amending) {
                case "Name":


                    return <div>
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className=" justify-content-center dialog-form-field-space w-full">

                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertName("COA_New_Name_To_Be_Used")} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label ">

                                        <Controller name="COA_New_Name_To_Be_Used"
                                            control={control}
                                            rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertName(key)} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.` } : {}}
                                            render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field}
                                                    autoComplete={false}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        clearErrors(key);
                                                        errorMessageTag && (errorMessageTag.textContent = "")
                                                    }} disabled={modelname === "Principal_Address" || modelname === "Organizer_Information" || modelname === "Incorporator_Information" || (IsCheckedAgent && modelname === "Registered_Agent")}
                                                    style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', }}
                                                    className='dialog-form-input-field-wizard'
                                                    maxLength={50} />
                                            )} />

                                    </span>
                                    <small id={`${val}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}
                                </div>
                            </div>

                        </div>

                    </div>;

                case "Provisions":
                    return <div>
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className=" justify-content-center dialog-form-field-space w-full">

                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertName("COA_What_Are_We_Changing")} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label ">

                                        <Controller name="COA_What_Are_We_Changing"
                                            control={control}
                                            rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertName(key)} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.` } : {}}
                                            render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field}
                                                    autoComplete={false}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        clearErrors(key);
                                                        errorMessageTag && (errorMessageTag.textContent = "")
                                                    }} disabled={modelname === "Principal_Address" || modelname === "Organizer_Information" || modelname === "Incorporator_Information" || (IsCheckedAgent && modelname === "Registered_Agent")}
                                                    style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', }}
                                                    className='dialog-form-input-field-wizard'
                                                    maxLength={50} />
                                            )} />



                                    </span>
                                    <small id={`${val}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}

                                </div>

                            </div>

                        </div>
                    </div>;


                case "Other":
                    return <div>
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className=" justify-content-center dialog-form-field-space w-full">

                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertName("COA_Please_Tell_Us_What_You_Want_To_Amend")} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label ">

                                        <Controller name="COA_Please_Tell_Us_What_You_Want_To_Amend"
                                            control={control}
                                            rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertName(key)} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.` } : {}}
                                            render={({ field, fieldState }) => (
                                                <InputText id={field.name} {...field}
                                                    autoComplete={false}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        clearErrors(key);
                                                        errorMessageTag && (errorMessageTag.textContent = "")
                                                    }} disabled={modelname === "Principal_Address" || modelname === "Organizer_Information" || modelname === "Incorporator_Information" || (IsCheckedAgent && modelname === "Registered_Agent")}
                                                    style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', }}
                                                    className='dialog-form-input-field-wizard'
                                                    maxLength={50} />
                                            )} />



                                    </span>
                                    <small id={`${val}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage(key)}

                                </div>

                            </div>

                        </div>
                    </div>;
                default:
                    break;


            }


        }
        else if (cc_What_Type_Document !== undefined && cc_What_Type_Document === "Other - Please specify") {
            return (
                <>
                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space w-full">

                                <label htmlFor='' className='form-label font-fam-for-all'>{convertName("cc_Specify_Other_Document")} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                <span className="p-float-label ">

                                    <Controller name="cc_Specify_Other_Document"
                                        control={control}
                                        rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertName("cc_Specify_Other_Document")} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.` } : {}}
                                        render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field}
                                                autoComplete={false}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    clearErrors(key);
                                                    errorMessageTag && (errorMessageTag.textContent = "")
                                                }} disabled={modelname === "Principal_Address" || modelname === "Organizer_Information" || modelname === "Incorporator_Information" || (IsCheckedAgent && modelname === "Registered_Agent")}
                                                style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', }}
                                                className='dialog-form-input-field-wizard'
                                                maxLength={50} />
                                        )} />



                                </span>
                                <small id={`${"cc_Specify_Other_Document"}--error`} className="error-msg font-fam-for-all "></small>
                                {getFormErrorMessage("cc_Specify_Other_Document")}

                            </div>

                        </div>

                    </div>


                </>
            )
        }
        else if (ucc_UCC_Collateral_Type !== undefined && (val !== "ucc_Collateral_Statement")) {
            switch (ucc_UCC_Collateral_Type) {
                case "Upload Their Own Collateral Statement":


                    return <div>
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className="justify-content-center dialog-form-field-space w-full">
                                    <label htmlFor='' className='form-label font-fam-for-all'>
                                        {convertName("ucc_Collateral_Statement")}
                                        {column.required !== undefined && column.required === true ?
                                            <span className='form-field-mandatory'>*</span> : ''}
                                    </label>
                                    <span className="p-float-label">
                                        <Controller
                                            name={"ucc_Collateral_Statement"}
                                            control={control}
                                            rules={column.required !== undefined && column.required === true && column.regex !== undefined
                                                ? {
                                                    required: `${convertName("ucc_Collateral_Statement")} Required.`,
                                                    pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName("ucc_Collateral_Statement")}` }
                                                }
                                                : column.required !== undefined && column.required === true
                                                    ? { required: `${convertName("ucc_Collateral_Statement")} Required.` }
                                                    : {}
                                            }
                                            render={({ field }) => (
                                                // <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="file/*" maxFileSize={1000000} onUpload={onUpload} />
                                                <input type="file" onChange={(e) => handleFile(e, val)} />
                                            )}
                                        />
                                    </span>
                                    <small id={`${"ucc_Collateral_Statement"}--error`} className="error-msg font-fam-for-all"></small>
                                    {getFormErrorMessage("ucc_Collateral_Statement")}
                                </div>
                            </div>
                        </div>
                    </div>;

                case "Type Their Own Collateral Statement":
                    return <div>
                        <div className='input-fields-container'>
                            <div className='input-fields-main'>
                                <div className=" justify-content-center dialog-form-field-space w-full">

                                    <label htmlFor='' className='form-label font-fam-for-all'>{convertName("ucc_Type_Your_Collateral_Statement")} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                    <span className="p-float-label " >
                                        <Controller
                                            control={control}
                                            name={"ucc_Type_Your_Collateral_Statement"}
                                            rules={column.required !== undefined && column.required === true ? { required: `${convertName("ucc_Type_Your_Collateral_Statement")} Required.`, pattern: { value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, message: 'Please Enter Valid Password. Use 8 Or More Characters With  Mix Of Letters, Numbers & Symbols.' } } : {}}
                                            render={({ field, fieldState }) => (
                                                // <Password
                                                //     id={field.name} {...field}
                                                //     toggleMask
                                                //     inputRef={field.ref}
                                                //     autoComplete={false}
                                                //     className='dialog-form-input-field-wizard'
                                                // />
                                                <Editor
                                                    id={field.name} {...field}

                                                    inputRef={field.ref} style={{ height: '320px' }} />
                                            )} />



                                    </span>
                                    <small id={`${"ucc_Type_Your_Collateral_Statement"}--error`} className="error-msg font-fam-for-all "></small>
                                    {getFormErrorMessage("ucc_Type_Your_Collateral_Statement")}

                                </div>
                            </div>
                        </div>
                    </div>;

                default:
                    break;
            }
        }
        else if (finCenId && val === `boi_FinCEN_ID-${val.split('-')[1] || 0}`) {
            return (
                <>
                    <div className='input-fields-container'>
                        <div className='input-fields-main'>
                            <div className=" justify-content-center dialog-form-field-space w-full">

                                <label htmlFor='' className='form-label font-fam-for-all'>{convertName("boi_FinCEN_ID")} {column.required !== undefined && column.required === true ? <span className='form-field-mandatory'>*</span> : ''}</label>
                                <span className="p-float-label ">

                                    <Controller name={`boi_FinCEN_ID-${val.split('-')[1] || 0}`}
                                        control={control}

                                        rules={column.required !== undefined && column.required === true && column.regex !== undefined ? { required: `${convertName("boi_FinCEN_ID")} Required.`, pattern: { value: new RegExp(column.regex), message: `Please Enter Valid ${convertName(key)}` }, } : column.required !== undefined && column.required === true ? { required: `${convertName(key)} Required.` } : {}}
                                        render={({ field, fieldState }) => (
                                            <InputText id={field.name} {...field}
                                                autoComplete={false}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    clearErrors(key);
                                                    errorMessageTag && (errorMessageTag.textContent = "")
                                                }} disabled={modelname === "Principal_Address" || modelname === "Organizer_Information" || modelname === "Incorporator_Information" || (IsCheckedAgent && modelname === "Registered_Agent")}
                                                style={{ flexGrow: '1', paddingLeft: '10px', paddingBottom: '5px', }}
                                                className='dialog-form-input-field-wizard'
                                                maxLength={50} />
                                        )} />



                                </span>
                                <small id={`${"boi_FinCEN_ID"}--error`} className="error-msg font-fam-for-all "></small>
                                {getFormErrorMessage("boi_FinCEN_ID")}

                            </div>

                        </div>

                    </div>


                </>
            )
        }
        else {
            console.log('return null')
            return null;
        }
    };


    console.log(selectCheckBoxData);

    let currentTabs = 0;
    let currentSteps = 1;
    function showTab(n) {
        // This function will display the specified tab of the form...
        var x = document.getElementsByClassName("tab");
        console.log(x)
        if (x.length > 0) {
            if (x[n] !== undefined) {
                x[n].style.display = "block";
            }

            //... and fix the Previous/Next buttons:
            if (n == 0) {
                document.getElementById("prevBtn").style.display = "none";
            } else {
                document.getElementById("prevBtn").style.display = "flex";
            }
            if (n == (x.length - 1) && inputFormData.length > 0) {
                // document.getElementById("nextBtn").innerHTML = "Submit";
                document.getElementById("nextBtn").style.display = "none"
                document.getElementById("submitBtn").style.display = "flex"

            } else {
                document.getElementById("nextBtn").innerHTML = "Next";
                document.getElementById("nextBtn").style.display = "flex"
                document.getElementById("submitBtn").style.display = "none"

            }
            //... and run a function that will display the correct step indicator:
            fixStepIndicator(n)
        }

    }

    console.log(selectCheckBoxData)

    const nextPrev = (n) => {
        setWizardScroll(!wizardScroll)
        setOrderContinue(true)
        if (strapiId) {
            var x = document.getElementsByClassName("tab");
            if (n === 1) {
                if (currentStep < inputFormData.length + 5) {
                    setCurrentStep(currentStep + 1);
                }
            }

            if (n === -1) {
                if (currentStep > 1) {
                    setCurrentStep(currentStep - 1);
                }
            }

            // Exit the function if any field in the current tab is invalid:
            console.log(n)
            console.log(x)
            if (x.length > 0) {
                console.log(!validateForm())
                if (n == 1 && !validateForm()) return false;
                // Hide the current tab:
                console.log(currentTab)
                if (x[currentTab] !== undefined) {
                    x[currentTab].style.display = "none";
                }

                // Increase or decrease the current tab by 1:
                const newTab = currentTab + n;

                console.log(newTab)
                setCurrentTab(newTab)

                showTab(newTab);


            }
            document.getElementById('nextBtn').addEventListener('click', function () {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // Smooth scroll
                });
            });
        }
        else {
            if (selectedCheckBoxData.length > 0) {
                var x = document.getElementsByClassName("tab");

                // Check if the user is moving to the next page
                if (n === 1) {
                    if (currentStep < inputFormData.length + 5) {
                        setCurrentStep(currentStep + 1);
                    }
                }

                // Check if the user is moving to the previous page
                if (n === -1) {
                    if (currentStep > 1) {
                        setCurrentStep(currentStep - 1);
                    }
                }

                // Validate the form
                if (x.length > 0) {
                    if (n === 1 && !validateForm()) return false; // Stop if form validation fails

                    // Hide current tab
                    if (x[currentTab] !== undefined) {
                        x[currentTab].style.display = "none";
                    }

                    // Move to the new tab
                    const newTab = currentTab + n;
                    setCurrentTab(newTab);
                    showTab(newTab);

                    if (mainModel && n === 1) {
                        // Key personnel and other processing
                        const keyPersonalArr = [];
                        if (incorporator?.id) keyPersonalArr.push(incorporator.id);
                        if (officer?.id) keyPersonalArr.push(officer.id);
                        if (director?.id) keyPersonalArr.push(director.id);
                        if (organizerData?.id) keyPersonalArr.push(organizerData.id);
                        if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
                        if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);
                        if (president?.id) keyPersonalArr.push(president.id);
                        if (treasurer?.id) keyPersonalArr.push(treasurer.id);
                        if (secretary?.id) keyPersonalArr.push(secretary.id);
                        if (formCount) {
                            Array.from({ length: formCount }).map((_, l) => {
                                if (getValues(`ucc_Beneficial_Dropdown-${l}`) !== undefined) {
                                    keyPersonalArr.push(getValues(`ucc_Beneficial_Dropdown-${l}`).id)
                                }
                                if (getValues(`company_applicant_item_${l}`) !== undefined) {
                                    keyPersonalArr.push(getValues(`company_applicant_item_${l}`).id)
                                }
                            })
                        }

                        // Date processing
                        let d = new Date();
                        const futureDate = new Date(d);
                        futureDate.setDate(d.getDate() + 7);

                        let day = d.getDate();
                        let month = d.getMonth() + 1;
                        let year = d.getFullYear();

                        let day1 = futureDate.getDate();
                        let month1 = futureDate.getMonth();
                        let year1 = futureDate.getFullYear();

                        if (day < 10) day = '0' + day;
                        if (month < 10) month = `0${month}`;
                        let newDate = year + "-" + month + "-" + day;

                        if (day1 < 10) day1 = '0' + day1;
                        if (month < 10) month1 = `0${month1}`;
                        let newDate1 = year1 + "-" + month1 + "-" + day1;

                        console.log(selectedCheckBoxData)

                        const upadatedProduct = selectedCheckBoxData.map((val) => {
                            return {
                                productListing: val.product,
                                quantity: 1,
                                unitPrice: val.product.unitPrice
                            };
                        });

                        const finalData1 = buildFinalData(mainModel.schema.attributes, modelData1);

                        const jsonAutomation = {
                            "EntityType": enityName,
                            "State": state,
                            "County": county,
                            "Header": orderHeader,
                            "Payload": finalData1,
                            "legalName": getValues("CD_LLC_Name") !== undefined ? getValues("CD_LLC_Name") : getValues("CD_CORP_Name") !== undefined ? getValues("CD_CORP_Name") : getValues("X_Name_of_Entity") !== undefined ? getValues("X_Name_of_Entity") : globalCompany ? globalCompany.companyDetails ? globalCompany.companyDetails.companyName : "Order" : "Order",
                            "addOnService": addOnData,
                            "billingAddress": bilingAddress,
                            "shipingAddress": shippingAddress
                        };

                        const orderData = {
                            "contactId": contactId,
                            "companyId": globalCompany ? globalCompany.companyDetails ? globalCompany.companyDetails.id : null : null,
                            "orderTitle": getValues("CD_LLC_Name") !== undefined ? getValues("CD_LLC_Name") : getValues("CD_CORP_Name") !== undefined ? getValues("CD_CORP_Name") : getValues("X_Name_of_Entity") !== undefined ? getValues("X_Name_of_Entity") : globalCompany ? globalCompany.companyDetails ? globalCompany.companyDetails.companyName : "Order" : "Order",
                            "orderId": null,
                            "billingAddress": bilingAddress ? bilingAddress.addressMasterId.id : null,
                            "statusId": 7,
                            "orderAmount": parseFloat(calculateTotalAmount()),
                            "orderDate": newDate,
                            // "tentativeFulfillmentDate": newDate1,
                            "actualCompletionDate": null,
                            "strapiOrderFormJson": JSON.stringify(jsonAutomation),
                            "strapiOrderId": null,
                            "strapiOrderType": "Standard",
                            "orderTypeId": 1,
                            "stateId": state ? state.id : null,
                            "county": county ? county.id : null,
                            "transaction_id": null,
                            "entityTypeId": enityName ? enityName.id : null,
                            "isPayLater": paymentMethod === "Pay later" ? true : false,
                            "domicileStateId": state ? state.id : null,
                            "hubspotDealId": null,
                            "orderUpdateNotes": "Draft order created...",
                            "documentsId": null,
                            "orderUpdatedBy": loginUserName,
                            "keyPersonalId": keyPersonalArr,
                            "orderLineItems": upadatedProduct
                        };

                        const saveOrder = () => {
                            if (formData) {
                                const temp = formData;
                                temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation);
                                temp["orderLineItems"] = updatedOrderLineItems;
                                temp["billingAddress"] = bilingAddress ? bilingAddress.addressMasterId.id : null;
                                temp["shippingAddress"] = shippingAddress ? shippingAddress.addressMasterId.id : null;
                                temp["stateId"] = state ? state.id : null;
                                temp["countyId"] = county ? county.id : null;
                                temp["keyPersonalId"] = keyPersonalArr;
                                temp["orderUpdateNotes"] = "Draft order created"
                                temp["orderUpdatedBy"] = loginUserName
                                temp["orderTitle"] = getValues("CD_LLC_Name") !== undefined ? getValues("CD_LLC_Name") : getValues("CD_CORP_Name") !== undefined ? getValues("CD_CORP_Name") : getValues("X_Name_of_Entity") !== undefined ? getValues("X_Name_of_Entity") : globalCompany ? globalCompany.companyDetails ? globalCompany.companyDetails.companyName : "Order" : "Order"


                                // Save order data and handle error
                                setIsTabRefresh(true);
                                return OrderServices.saveAllOrder(temp)
                                    .then((res) => {
                                        console.log(res.data);
                                        setPaymentOrderData(res.data);
                                        setFormData(res.data.orderDetails);
                                        setUpdatedOrderLineItems(res.data.orderLineItem);
                                        setIsTabRefresh(false);

                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        setIsTabRefresh(false);
                                        if (toast.current) {
                                            toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                                        }
                                        return false; // Prevent navigation on error
                                    });
                            } else {
                                setIsTabRefresh(true);
                                return OrderServices.saveAllOrder(orderData)
                                    .then((res) => {
                                        console.log(res);
                                        setPaymentOrderData(res.data);
                                        setFormData(res.data.orderDetails);
                                        setUpdatedOrderLineItems(res.data.orderLineItem);
                                        setIsTabRefresh(false);

                                        if (res.status === 201) {
                                            toast.current.show({ severity: 'success', summary: 'Success', detail: `Your order is saved in draft with temporary order id ${res.data.orderDetails.id}`, life: 3000 });
                                        }
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                        setIsTabRefresh(false);
                                        if (toast.current) {
                                            toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                                        }
                                        return false; // Prevent navigation on error
                                    });
                            }
                        };

                        saveOrder().then((success) => {
                            if (success !== false) {
                                // Only proceed to next page if save was successful
                                setCurrentTab(newTab);
                                showTab(newTab);
                            }
                        });
                    }
                }
            }
            else {
                if (toast.current !== undefined) {
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: "Please select at least one product", life: 3000 });

                }
            }
        }

    }

    const stepLabels = Array.from({ length: inputFormData.length + 5 }, (_, i) => `Step ${i + 1} of ${inputFormData.length + 5}`);

    console.log(currentTab)

    function stripSuffix(inputName) {
        return inputName.replace(/-\d+$/, "");
    }

    function validateForm() {
        console.log(currentTab);
        const x = document.getElementsByClassName("tab");
        let valid = true;

        console.log(x);

        if (x.length > 0 && x[currentTab] !== undefined) {
            const y = x[currentTab].getElementsByTagName("input");
            const dropdowns = x[currentTab].getElementsByClassName("p-dropdown");
            const fileInputs = x[currentTab].querySelectorAll('input[type="file"]');


            // A loop that checks every input field in the current tab:
            for (let i = 0; i < y.length; i++) {
                const input = y[i];
                const inputName = stripSuffix(input.name);
                console.log(inputFormData);
                const column = allModels.find(field => {
                    const temp = field.schema.attributes;
                    console.log(temp);
                    return Object.keys(temp).includes(inputName);
                });
                console.log(column);

                if (column && column !== undefined) {
                    const fieldData = column.schema.attributes[inputName];
                    console.log(fieldData);
                    const errorMessageTag = document.getElementById(`${input.name}--error`);

                    // Reset previous error message
                    if (errorMessageTag) {
                        errorMessageTag.textContent = "";
                    }

                    // Check if the input field is empty and required:
                    console.log(fieldData.required);
                    console.log(input.value.trim());
                    if (fieldData.required && input.value.trim() === "") {
                        valid = false;
                        if (errorMessageTag) {
                            errorMessageTag.textContent = `${convertName(inputName)} required`;
                        }
                        console.log(valid);
                    }
                    if (input.name === "CD_LLC_Name" || input.name === "CD_CORP_Name") {
                        if (enityName && state) {
                            const entityRegex = validationConfig[enityName.entityShortName]?.[state.stateFullDesc];

                            if (entityRegex && !entityRegex.test(input.value.trim())) {
                                valid = false;
                                if (errorMessageTag) {
                                    const patternText = entityRegex.source
                                        .split('|')
                                        .join(', ')
                                        .replace(/[()\$]/g, ''); // Remove special characters: (), \, ., $

                                    errorMessageTag.textContent = `Enter a valid ${convertName(inputName)} ending with ${patternText}`;
                                }
                            }
                        }
                    }

                    // Check regex validation if provided:
                    if (fieldData.regex && input.value.trim() !== "") {
                        const regex = new RegExp(fieldData.regex);
                        if (!regex.test(input.value.trim())) {
                            valid = false;
                            if (errorMessageTag) {
                                errorMessageTag.textContent = `Enter Valid ${convertName(inputName)} `;
                            }
                        }
                    }
                    if (input.type === "text" || input.type === "textarea") {
                        if (fieldData.maxLength && input.value.trim() !== "") {
                            if (input.value.length > fieldData.maxLength) {
                                valid = false;
                                if (errorMessageTag) {
                                    errorMessageTag.textContent = `${convertName(inputName)} should have a maximum length of ${(fieldData.maxLength)}`;
                                }
                            }
                        }
                        if (fieldData.minLength && input.value.trim() !== "") {
                            if (input.value.length < fieldData.minLength) {
                                valid = false;
                                if (errorMessageTag) {
                                    errorMessageTag.textContent = `${convertName(inputName)} should have a minimum length of ${fieldData.minLength}`;
                                }
                            }
                        }
                    } else if (input.type === "number") {
                        console.log(input.value)
                        const value = parseInt(input.value, 10);
                        console.log(fieldData)
                        if (fieldData.max && !isNaN(value) && value > fieldData.max) {
                            valid = false;
                            if (errorMessageTag) {
                                errorMessageTag.textContent = `${convertName(inputName)} should be ${(fieldData.max).length} digits`;
                            }
                        }
                        if (fieldData.min && !isNaN(value) && value < fieldData.min) {
                            valid = false;
                            if (errorMessageTag) {
                                errorMessageTag.textContent = `${convertName(inputName)} should be at least ${(fieldData.min).length} digits`;
                            }
                        }
                    }
                } else {
                    console.log(column);
                    console.log(inputFormData);
                    console.log(valid);
                    if (inputFormData.length === 0) {
                        valid = false;
                    }
                }
            }

            // A loop that checks every dropdown field in the current tab:
            for (let i = 0; i < dropdowns.length; i++) {
                const dropdown = dropdowns[i];
                const inputName = dropdown.id;
                const inputWithoutSuffix = stripSuffix(dropdown.id)
                const inputValue = dropdown.querySelector('.p-dropdown-label').textContent.trim();
                console.log(inputValue)

                if (inputName) {
                    const column = allModels.find(field => {
                        const temp = field.schema.attributes;
                        return Object.keys(temp).includes(inputWithoutSuffix);
                    });

                    if (column && column !== undefined) {
                        const fieldData = column.schema.attributes[inputWithoutSuffix];
                        const errorMessageTag = document.getElementById(`${inputName}--error`);

                        // Reset previous error message
                        if (errorMessageTag) {
                            errorMessageTag.textContent = "";
                        }

                        // Check if the dropdown field is empty and required:
                        if (fieldData.required && inputValue === "empty") {
                            valid = false;
                            if (errorMessageTag) {
                                errorMessageTag.textContent = `${convertName(inputWithoutSuffix)} required`;
                            }
                        }

                        // Check regex validation if provided:
                        if (fieldData.regex && inputValue !== "") {
                            const regex = new RegExp(fieldData.regex);
                            if (!regex.test(inputValue)) {
                                valid = false;
                                if (errorMessageTag) {
                                    errorMessageTag.textContent = `Enter Valid ${convertName(inputWithoutSuffix)} `;
                                }
                            }
                        }
                        if (fieldData.maxLength && inputValue !== "") {
                            if (inputValue.length > fieldData.maxLength) {
                                valid = false;
                                if (errorMessageTag) {
                                    errorMessageTag.textContent = `${convertName(inputWithoutSuffix)} Should be Max Length ${fieldData.maxLength} `;
                                }
                            }
                        }
                        if (fieldData.minLength && inputValue !== "") {
                            if (inputValue.length < fieldData.minLength) {
                                valid = false;
                                if (errorMessageTag) {
                                    errorMessageTag.textContent = `${convertName(inputWithoutSuffix)} Should be Min Length ${fieldData.minLength} `;
                                }
                            }
                        }
                    } else {
                        if (inputFormData.length === 0) {
                            valid = false;
                        }
                    }
                }
            }


            // A loop that checks each file input field in the current tab:
            for (let i = 0; i < fileInputs.length; i++) {
                const fileInput = fileInputs[i];
                // const fileName = fileInput.name;
                const fileName = stripSuffix(fileInput.name);

                console.log(fileName)
                const column = allModels.find(field => {
                    const temp = field.schema.attributes;
                    return Object.keys(temp).includes(fileName);
                });

                if (column && column !== undefined) {
                    const fieldData = column.schema.attributes[fileName];
                    const errorMessageTag = document.getElementById(`${fileInput.name}--error`);

                    // Reset previous error message
                    if (errorMessageTag) {
                        errorMessageTag.textContent = "";
                    }

                    // Check if the file input is required and no file is selected:
                    if (fieldData.required && fileInput.files.length === 0) {
                        valid = false;
                        if (errorMessageTag) {
                            errorMessageTag.textContent = `${convertName(fileName)} required`;
                        }
                    }
                }
            }

            // If the valid status is true, mark the step as finished and valid:
            if (valid) {
                const stepElements = document.getElementsByClassName("step");

                if (stepElements[currentTab] !== undefined) {
                    stepElements[currentTab].className += " finish";


                }


            }
        }

        console.log(valid);

        return valid; // return the valid status
    }



    function fixStepIndicator(n) {
        // This function removes the "active" class of all steps...
        var i, x = document.getElementsByClassName("step");
        for (i = 0; i < x.length; i++) {
            x[i].className = x[i].className.replace(" active", "");
        }
        //... and adds the "active" class on the current step:
        if (x[n] !== undefined) {
            x[n].className += " active";
        }

    }


    const handleComapnyName = (e) => {
        const value = e.target.value;
        setInputValue(value);

        // Only check availability if the input is not empty
        if (value) {
            checkAvailability(value);
        } else {
            setIsAvailable(null);
        }
    };

    const handleLLCMember = (e) => {
        setLlc(e.target.value);
    };

    console.log(modelData);


    function processSchema(schema, dataMap, depth = 0, maxDepth = 10) {
        if (depth > maxDepth) {
            console.warn('Maximum recursion depth exceeded');
            return {};
        }

        const result = {};

        if (schema !== undefined) {
            Object.keys(schema.attributes).forEach(attrKey => {
                const attr = schema.attributes[attrKey];

                if (attr.apiId !== undefined && mainModel) {
                    const mainSchema = mainModel.schema.attributes;
                    const componentKeys = Object.keys(mainSchema).map(k => {
                        const attributes = mainSchema[k]?.schema?.attributes;
                        if (attributes && Object.keys(attributes).length > 0) {
                            return Object.keys(attributes).filter(
                                (key) => attributes[key]?.uid === attr.uid
                            );
                        }
                        return [];
                    });
                    const componentKey = componentKeys.flat();
                    const componentData = dataMap.get(attr.uid);

                    if (componentData) {
                        if (mainSchema[componentKey] && mainSchema[componentKey].schema) {
                            result[attrKey] = processSchema(mainSchema[componentKey].schema, dataMap, depth + 1, maxDepth);
                            Object.assign(result[attrKey], componentData);
                        } else {
                            result[attrKey] = componentData;
                        }
                    }
                } else {
                    const parentData = dataMap.get(attrKey);
                    if (parentData) {
                        result[attrKey] = parentData;
                    }
                }
            });
        }

        return result;
    }

    function buildFinalData(temps, tempsData) {
        const finalData = {};

        // Create a map for efficient data lookup
        const dataMap = new Map(tempsData.map(d => [d.model, d.data]));

        if (mainModel) {
            Object.keys(temps).forEach(key => {
                const schema = temps[key].schema;
                const data = dataMap.get(temps[key].uid);

                // Process the schema directly without memoization
                finalData[key] = processSchema(schema, dataMap);

                if (data) {
                    if (Array.isArray(data)) {
                        finalData[key] = [...data]; // Copy array
                    } else {
                        Object.assign(finalData[key], data); // Merge object data
                    }
                }
            });
        }

        return finalData;
    }


    useEffect(() => {
        if (mainModel && modelData1.length > 0) {
            if (mainModel.schema !== undefined) {
                if (mainModel.schema.attributes !== undefined) {
                    const finalData1 = buildFinalData(mainModel.schema.attributes, modelData1);
                    setPriviewData(finalData1)
                }
            }


        }
    }, [mainModel, modelData1])





    const getState = (id) => {
        const temp = allState.find((val) => val.id === id)
        if (temp !== undefined) {
            return temp.stateFullDesc;
        }
        else {
            return null
        }

    }


    const stripePay = async (item, orderData) => {

        try {

            const stripe = await stripePromise;

            const data = {

                product: item
            }

            const productPaydata = item.map((val) => {
                return {
                    name: val.productListing.displayName,
                    unitAmount: Math.round(val.productListing.unitPrice * 100),
                    quantity: 1
                };
            });

            const paytemp = {
                "lineItems": productPaydata
            }

            // const response = await axios.post(`$/checkout`, data);
            setIsLoading(true)
            setIsTabRefresh(true)
            OrderServices.paymentOrder(paytemp)
                .then((response) => {
                    console.log(response)

                    localStorage.setItem("sessionId", response.data.sessionId)
                    // setPaySessionId(res.data.sessionId)

                    // const sessionId = response.data.id;
                    // console.log(sessionId)

                    const localData = {
                        data: orderData,
                        res: response.data,
                        orderLineItem: item,
                        updatedBy: loginUserName
                    }
                    localStorage.setItem("payment", JSON.stringify(localData))
                    setIsLoading(false)
                    setIsTabRefresh(false)
                    setTimeout(() => {
                        window.location.href = response.data.checkoutUrl;
                    }, 100); // Small timeout to ensure data is saved
                    // const { error } = await stripe.redirectToCheckout({
                    //     sessionId: sessionId
                    // });
                    // if (error) {
                    //     console.error("Stripe Checkout Error:", error);
                    // }
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    setIsTabRefresh(false)
                })


        } catch (err) {
            console.error("Error during checkout process:", err);
        }
    }



    // Function to handle confirmation
    const handleConfirm = async () => {
        if (mainModel) {
            const jsonAutomation = {
                "EntityType": enityName,
                "State": state,
                "Payload": formData,
                "addOnService": null,
                "legalName": getValues("CD_LLC_Name") !== undefined ? getValues("CD_LLC_Name") : getValues("CD_CORP_Name") !== undefined ? getValues("CD_CORP_Name") : null,
                "billingAddress": bilingAddress,
                "shipingAddress": shippingAddress
            }

            const keyPersonalArr = []

            if (incorporator?.id) keyPersonalArr.push(incorporator.id);
            if (officer?.id) keyPersonalArr.push(officer.id);
            if (director?.id) keyPersonalArr.push(director.id);
            if (organizerData?.id) keyPersonalArr.push(organizerData.id);
            if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
            if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);



            let d = new Date();
            const futureDate = new Date(d);
            futureDate.setDate(d.getDate() + 7);
            console.log(futureDate)

            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();

            let day1 = futureDate.getDate();
            let month1 = futureDate.getMonth() + 1;
            let year1 = futureDate.getFullYear();


            if (day < 10) {
                day = '0' + day;
            }
            if (month < 10) {
                month = `0${month}`;
            }
            else {
                month = month
            }
            let newDate = year + "-" + month + "-" + day


            if (day1 < 10) {
                day1 = '0' + day1;
            }
            if (month < 10) {
                month1 = `0${month1}`;
            }
            else {
                month1 = month1
            }
            let newDate1 = year1 + "-" + month1 + "-" + day1

            const upadatedProduct = selectedCheckBoxData.map((val) => {
                return {
                    productListing: val.product,
                    quantity: 1,
                    unitPrice: calculateTotalAmount()
                }
            })

            setIsTabRefresh(true)
            if (paymentMethod === "Pay later") {
                if (formData) {
                    const temp = formData
                    temp["statusId"] = 1000
                    temp["orderLineItems"] = updatedOrderLineItems
                    temp["orderUpdateNotes"] = "Approval Pending"
                    temp["orderUpdatedBy"] = loginUserName
                    temp["shippingAddress"] = shippingAddress ? shippingAddress.addressMasterId.id : bilingAddress ? bilingAddress.addressMasterId.id : null
                    temp["stateId"] = state ? state.id : null
                    temp["countyId"] = county ? county.id : null
                    temp["strapiOrderId"] = strapiId
                    temp["keyPersonalId"] = keyPersonalArr
                    setIsTabRefresh(true);
                    OrderServices.saveAllOrder(temp)
                        .then((res) => {
                            console.log(res)
                            setOrderContinue(false)
                            setIsTabRefresh(false)
                            history("/order")
                        })
                        .catch((err) => {
                            console.log(err)
                            setCloseProgresbar2(false)
                            setCloseProgresbar3(false)
                            setCloseProgresbar1(true)
                            setIsTabRefresh(false)
                            // if (toast.current) {
                            //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                            // }
                        })
                }


            }
            else {

                if (editPayloadData) {
                    const temp = formData
                    temp["statusId"] = 14
                    temp["orderLineItems"] = updatedOrderLineItems
                    temp["orderUpdateNotes"] = "Order created successfully."
                    temp["orderUpdatedBy"] = loginUserName
                    temp["shippingAddress"] = shippingAddress ? shippingAddress.addressMasterId.id : bilingAddress ? bilingAddress.addressMasterId.id : null
                    temp["stateId"] = state ? state.id : null
                    temp["billingAddress"] = bilingAddress ? bilingAddress.addressMasterId.id : null;
                    temp["countyId"] = county ? county.id : null
                    temp["orderDate"] = newDate
                    temp["tentativeFulfillmentDate"] = newDate1
                    temp["strapiOrderId"] = strapiId
                    temp["keyPersonalId"] = keyPersonalArr
                    setIsTabRefresh(true);
                    OrderServices.saveAllOrder(temp)
                        .then((res) => {
                            console.log(res)
                            setOrderContinue(false)
                            const orderPayData = res.data
                            const tempNew = res.data.orderDetails
                            tempNew["shippingAddress"] = shippingAddress ? shippingAddress.addressMasterId.id : bilingAddress ? bilingAddress.addressMasterId.id : null
                            tempNew["orderLineItems"] = updatedOrderLineItems
                            const invoiceData = {
                                "orderId": tempNew,
                                "invoiceNo": `INV ${res.data.orderDetails.id}${newDate}`,
                                "totalAmount": calculateTotalAmount(),
                                "orderLineItems": updatedOrderLineItems
                            }

                            const temp = res.data.orderDetails

                            OrderServices.saveAllInvoice(invoiceData)
                                .then((res) => {
                                    console.log(res)
                                    // setCloseProgresbar2(false)
                                    // setCloseProgresbar3(false)
                                    // setCloseProgresbar(true)
                                    setIsTabRefresh(false)
                                    // orderData["orderId"] = temp.orderId
                                    // orderData["id"] = temp.id
                                    stripePay(updatedOrderLineItems, orderPayData)


                                })
                                .catch((err) => {
                                    console.log(err)
                                    setCloseProgresbar2(false)
                                    setCloseProgresbar3(false)
                                    // setCloseProgresbar1(true)
                                    setIsTabRefresh(false)
                                    stripePay(updatedOrderLineItems, orderPayData)
                                    // if (toast.current) {
                                    //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                                    // }
                                })
                        })
                        .catch((err) => {
                            console.log(err)
                            setCloseProgresbar2(false)
                            setCloseProgresbar3(false)
                            // setCloseProgresbar1(true)
                            setIsTabRefresh(false)
                            // if (toast.current) {
                            //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                            // }
                        })
                }
            }

        }
        console.log("Form Data Submitted:", formData);

    };



    const onSubmit = (data) => {
        console.log(data);

        console.log(mainModel)

        console.log(globalCompany)

        if (mainModel && bilingAddress) {
            console.log(mainModel.schema.attributes)
            console.log(modelData1)
           
                const keyPersonalArr = []
                if (incorporator?.id) keyPersonalArr.push(incorporator.id);
                if (officer?.id) keyPersonalArr.push(officer.id);
                if (director?.id) keyPersonalArr.push(director.id);
                if (filer?.id) keyPersonalArr.push(filer.id);
                if (organizerData?.id) keyPersonalArr.push(organizerData.id);
                if (bilingAddress?.id) keyPersonalArr.push(bilingAddress.id);
                if (shippingAddress?.id) keyPersonalArr.push(shippingAddress.id);

                console.log(keyPersonalArr)
                const finalData1 = buildFinalData(mainModel.schema.attributes, modelData1);

                console.log(finalData1)
                console.log(mainModel)
                const tempData = {
                    data: finalData1,
                };
                const jsonAutomation = {
                    "EntityType": enityName,
                    "State": state,
                    "County": county,
                    "Payload": finalData1,
                    "legalName": getValues("CD_LLC_Name") !== undefined ? getValues("CD_LLC_Name") : getValues("CD_CORP_Name") !== undefined ? getValues("CD_CORP_Name") : null,
                    "addOnService": addOnData,
                    "billingAddress": bilingAddress,
                    "shipingAddress": shippingAddress
                }



                let d = new Date();
                const futureDate = new Date(d);
                futureDate.setDate(d.getDate() + 7);
                console.log(futureDate)

                let day = d.getDate();
                let month = d.getMonth() + 1;
                let year = d.getFullYear();

                let day1 = futureDate.getDate();
                let month1 = futureDate.getMonth() + 1;
                let year1 = futureDate.getFullYear();


                if (day < 10) {
                    day = '0' + day;
                }
                if (month < 10) {
                    month = `0${month}`;
                }
                else {
                    month = month
                }
                let newDate = year + "-" + month + "-" + day


                if (day1 < 10) {
                    day1 = '0' + day1;
                }
                if (month < 10) {
                    month1 = `0${month1}`;
                }
                else {
                    month1 = month1
                }
                let newDate1 = year1 + "-" + month1 + "-" + day1

                const upadatedProduct = selectedCheckBoxData.map((val) => {
                    return {
                        productListing: val.product,
                        quantity: 1,
                        unitPrice: calculateTotalAmount()
                    }
                })
                if (strapiId) {
                    setIsTabRefresh(true);
                    CreditCardServices.updateAlabamaLLC(tempData, mainModel.schema.pluralName, strapiId)
                        .then((res) => {
                            console.log(res)
                            setStrapiId(res.data.data.id)

                            if (editPayloadData) {
                                const temp = editPayloadData.orderDetails
                                // temp["statusId"] = 14
                                temp["orderLineItems"] = updatedOrderLineItems
                                temp["orderUpdateNotes"] = "Strapi data updated successfully"
                                temp["orderUpdatedBy"] = loginUserName
                                temp["shippingAddress"] = shippingAddress ? shippingAddress.addressMasterId.id : bilingAddress ? bilingAddress.addressMasterId.id : null
                                temp["keyPersonalId"] = keyPersonalArr
                                temp["billingAddress"] = bilingAddress ? bilingAddress.addressMasterId.id : null;
                                temp["stateId"] = state ? state.id : null
                                temp["countyId"] = county ? county.id : null
                                temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation)
                                OrderServices.saveAllOrder(temp)
                                    .then((res) => {
                                        console.log(res)
                                        const tempNew = res.data.orderDetails
                                        tempNew["shippingAddress"] = shippingAddress ? shippingAddress.addressMasterId.id : bilingAddress ? bilingAddress.addressMasterId.id : null
                                        tempNew["orderLineItems"] = updatedOrderLineItems
                                        const invoiceData = {
                                            "orderId": tempNew,
                                            "invoiceNo": `INV ${res.data.orderDetails.id}${newDate}`,
                                            "totalAmount": calculateTotalAmount(),
                                            "orderLineItems": updatedOrderLineItems
                                        }

                                        const temp = res.data.orderDetails

                                        OrderServices.saveAllInvoice(invoiceData)
                                            .then((res) => {
                                                console.log(res)
                                                setIsTabRefresh(false)
                                                setRedirectedData(temp)
                                                navigate("/order")
                                                setRedirectedData(temp)

                                            })
                                            .catch((err) => {
                                                console.log(err)
                                                // setCloseProgresbar2(false)
                                                // setCloseProgresbar3(false)
                                                // setCloseProgresbar1(true)
                                                setIsTabRefresh(false)
                                                setRedirectedData(temp)
                                                navigate("/order")
                                                setRedirectedData(temp)
                                                // if (toast.current) {
                                                //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                                                // }
                                            })

                                    })
                                    .catch((err) => {
                                        console.log(err)
                                        // setCloseProgresbar2(false)
                                        // setCloseProgresbar3(false)
                                        setCloseProgresbar1(true)
                                        setIsTabRefresh(false)
                                        // if (toast.current) {
                                        //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                                        // }
                                    })
                            }


                        })
                        .catch((err) => {
                            console.log(err)
                            setIsTabRefresh(false);
                            // if (toast.current) {
                            //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                            // }

                        })
                }
                else {
                    setIsTabRefresh(true);
                    if (orderTypeId !== 18) {
                        CreditCardServices.createAlabamaLLC(tempData, mainModel.schema.pluralName)
                            .then((res) => {
                                console.log(res)

                                setStrapiId(res.data.data.id)
                                if (paymentMethod === "Pay later") {
                                    console.log('first')
                                    if (editPayloadData) {
                                        const temp = editPayloadData.orderDetails
                                        temp["statusId"] = 14
                                        temp["orderLineItems"] = updatedOrderLineItems
                                        temp["orderUpdateNotes"] = "Order created successfully"
                                        temp["orderUpdatedBy"] = loginUserName
                                        temp["shippingAddress"] = shippingAddress ? shippingAddress.addressMasterId.id : bilingAddress ? bilingAddress.addressMasterId.id : null
                                        temp["stateId"] = state ? state.id : null
                                        temp["billingAddress"] = bilingAddress ? bilingAddress.addressMasterId.id : null;
                                        temp["countyId"] = county ? county.id : null
                                        temp["keyPersonalId"] = keyPersonalArr
                                        temp["orderDate"] = newDate
                                        temp["tentativeFulfillmentDate"] = newDate1
                                        temp["strapiOrderId"] = res.data.data.id
                                        temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation)
                                        OrderServices.saveAllOrder(temp)
                                            .then((res) => {
                                                console.log(res)
                                                const tempNew = res.data.orderDetails
                                                tempNew["shippingAddress"] = shippingAddress ? shippingAddress.addressMasterId.id : bilingAddress ? bilingAddress.addressMasterId.id : null
                                                tempNew["orderLineItems"] = updatedOrderLineItems
                                                const invoiceData = {
                                                    "orderId": tempNew,
                                                    "invoiceNo": 1234567890,
                                                    "totalAmount": calculateTotalAmount(),
                                                    "orderLineItems": updatedOrderLineItems
                                                }

                                                const temp = res.data.orderDetails

                                                OrderServices.saveAllInvoice(invoiceData)
                                                    .then((res) => {
                                                        console.log(res)
                                                        // setCloseProgresbar2(false)
                                                        // setCloseProgresbar3(false)
                                                        // setCloseProgresbar(true)
                                                        setIsTabRefresh(false)
                                                        setPaymentOrderData(res.data)
                                                        setFormData(res.data.orderId)
                                                        setCloseProgresbar2(true)

                                                    })
                                                    .catch((err) => {
                                                        console.log(err)
                                                        setCloseProgresbar2(true)
                                                        setCloseProgresbar3(false)
                                                        // setCloseProgresbar1(true)
                                                        setIsTabRefresh(false)
                                                        // if (toast.current) {
                                                        //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                                                        // }
                                                    })


                                            })
                                            .catch((err) => {
                                                console.log(err)
                                                setCloseProgresbar2(false)
                                                setCloseProgresbar3(false)
                                                setCloseProgresbar1(true)
                                                setIsTabRefresh(false)
                                                // if (toast.current) {
                                                //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                                                // }
                                            })
                                    }

                                }
                                else {
                                    setCloseProgresbar3(true)
                                    setIsTabRefresh(false);
                                }

                            })
                            .catch((err) => {
                                console.log(err)
                                setIsTabRefresh(false)
                                // if (toast.current) {
                                //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                                // }
                            })
                    }
                    else {
                        if (paymentMethod === "Pay later") {
                            console.log('first')
                            if (editPayloadData) {
                                const temp = editPayloadData.orderDetails
                                temp["statusId"] = 14
                                temp["orderLineItems"] = updatedOrderLineItems
                                temp["orderUpdateNotes"] = "Order created successfully"
                                temp["orderUpdatedBy"] = loginUserName
                                temp["shippingAddress"] = shippingAddress ? shippingAddress.addressMasterId.id : bilingAddress ? bilingAddress.addressMasterId.id : null
                                temp["stateId"] = state ? state.id : null
                                temp["billingAddress"] = bilingAddress ? bilingAddress.addressMasterId.id : null;
                                temp["countyId"] = county ? county.id : null
                                temp["keyPersonalId"] = keyPersonalArr
                                temp["orderDate"] = newDate
                                temp["tentativeFulfillmentDate"] = newDate1
                                temp["strapiOrderFormJson"] = JSON.stringify(jsonAutomation)
                                OrderServices.saveAllOrder(temp)
                                    .then((res) => {
                                        console.log(res)
                                        const tempNew = res.data.orderDetails
                                        tempNew["shippingAddress"] = shippingAddress ? shippingAddress.addressMasterId.id : bilingAddress ? bilingAddress.addressMasterId.id : null
                                        tempNew["orderLineItems"] = updatedOrderLineItems
                                        const invoiceData = {
                                            "orderId": tempNew,
                                            "invoiceNo": 1234567890,
                                            "totalAmount": calculateTotalAmount(),
                                            "orderLineItems": updatedOrderLineItems
                                        }

                                        const temp = res.data.orderDetails

                                        OrderServices.saveAllInvoice(invoiceData)
                                            .then((res) => {
                                                console.log(res)
                                                // setCloseProgresbar2(false)
                                                // setCloseProgresbar3(false)
                                                // setCloseProgresbar(true)
                                                setIsTabRefresh(false)
                                                setPaymentOrderData(res.data)
                                                setFormData(res.data.orderId)
                                                setCloseProgresbar2(true)

                                            })
                                            .catch((err) => {
                                                console.log(err)
                                                setCloseProgresbar2(true)
                                                setCloseProgresbar3(false)
                                                // setCloseProgresbar1(true)
                                                setIsTabRefresh(false)
                                                // if (toast.current) {
                                                //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                                                // }
                                            })


                                    })
                                    .catch((err) => {
                                        console.log(err)
                                        setCloseProgresbar2(false)
                                        setCloseProgresbar3(false)
                                        setCloseProgresbar1(true)
                                        setIsTabRefresh(false)
                                        // if (toast.current) {
                                        //     toast.current.show({ severity: 'warn', summary: 'Oops', detail: 'Something went wrong', life: 2800 });
                                        // }
                                    })
                            }

                        }
                        else {
                            setCloseProgresbar3(true)
                            setIsTabRefresh(false);
                        }
                    }

                }
            
        }
        else {
            if (!bilingAddress) {
                if (toast.current) {
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: 'Billing address required', life: 2800 });
                }
            }
        }

    };

    const convertToTitleCase = (str) => {
        return str
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2").replace(/_/g, ' ');
    };



    // const totalAmount = selectCheckBoxData.reduce((total, item) => {

    //     const amount = parseFloat(item.amount.replace("$", ""));
    //     return total + amount;
    // }, 0);


    const toPascalCase = (str) => {
        return str.replace(/-/g, ' ').replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const removeDuplicates = (data) => {
        const result = {};

        Object.keys(data).forEach((key) => {
            // Create a Set to store unique `uid` values
            const uniqueItems = new Map();

            data[key].form.forEach(item => {
                // Use `uid` as the key to ensure uniqueness
                if (!uniqueItems.has(item.uid)) {
                    uniqueItems.set(item.uid, item);
                }
            });

            // Convert the Map values back to an array
            result[key] = {
                mode: data[key].mode,
                form: Array.from(uniqueItems.values())
            }
        });

        return result;
    };


    console.log(inputFormData)


    const groupsedData = inputFormData.reduce((acc, value) => {
        console.log(value)
        if (typeof (value.schema.attributes) === "object") {
            console.log(value.schema.attributes)
            const tmp = value.schema.attributes
            if (tmp !== undefined) {
                const temp = Object.keys(tmp).map((key, i) => {
                    console.log(key)
                    Object.keys(groupInitialData).map((keys, m) => {
                        if (key === keys && groupInitialData[keys].type === "component" && groupInitialData[keys].repeatable === true) {
                            console.log('first')
                            if (tmp[key].schema !== undefined && tmp[key].schema.attributes !== undefined) {
                                if (typeof (tmp[key].schema.attributes) === "object") {
                                    const tempdata = Object.keys(tmp[key].schema.attributes).map((keyval, i) => {
                                        if (tmp[key].schema.attributes[keyval].uid !== undefined) {
                                            return tmp[key].schema.attributes[keyval]

                                        }
                                        else {
                                            return tmp[key]
                                        }
                                    })
                                    console.log(tempdata)
                                    acc[key] = {
                                        form: tempdata,
                                        mode: {
                                            repeat: groupInitialData[keys].repeatable
                                        }
                                    }
                                    console.log(acc)
                                }
                            }



                        }
                        else {
                            if (tmp[key].schema !== undefined && tmp[key].schema.attributes !== undefined && groupInitialData[keys].repeatable === false && key === keys) {
                                if (typeof (tmp[key].schema.attributes) === "object") {
                                    const tempdata = Object.keys(tmp[key].schema.attributes).map((keyval, i) => {
                                        if (tmp[key].schema.attributes[keyval].uid !== undefined) {
                                            return tmp[key].schema.attributes[keyval]

                                        }
                                        else {
                                            return tmp[key]
                                        }
                                    })
                                    console.log(tempdata)
                                    acc[key] = {
                                        form: tempdata,
                                        mode: null
                                    }
                                }
                            }
                        }

                    })

                })
            }

        }
        // if (value.apiID === "entity-detail" || value.apiID === "address") {
        //     if (!acc["contact_and_address"]) {
        //         acc["contact_and_address"] = [];
        //     }
        //     acc["contact_and_address"].push(value);
        // } else {
        //     acc[value.apiID] = [value];
        // }

        return acc;
    }, {});

    console.log(groupsedData)
    console.log(groupInitialData)



    const groupedData = removeDuplicates(groupsedData);
    console.log(formCount)
    console.log(groupedData)
    useEffect(() => {
        const temp = Object.keys(groupedData).map((key, i) => {
            console.log(groupedData[key])
            return groupedData[key].form
        })
        console.log(temp)
        const tempdata = temp.flat()
        console.log(tempdata)
        setAllModels(tempdata)
    }, [enityName, state, currentTab])

    const handleKeyPersonal = (data) => {
        setVisible2(true)
        const temp = allKeyPersonnelType.filter((val) => val.keyPersonalType === data)
        if (temp.length > 0) {
            setMemberType(temp[0])
        }

        const temp1 = allAddressType.filter((val) => val.type === data)
        if (temp1.length > 0) {
            setKeyPersonalAddressType(temp1[0])
        }

    }

    const handleBOIKeyPersonal = (data) => {
        setVisible8(true)
        const temp = allKeyPersonnelType.filter((val) => val.keyPersonalType === data)
        if (temp.length > 0) {
            setMemberType(temp[0])
        }

        const temp1 = allAddressType.filter((val) => val.type === data)
        if (temp1.length > 0) {
            setKeyPersonalAddressType(temp1[0])
        }

    }


    const RecursiveRender = ({ data, parentKey }) => {
        return (
            <>
                {Array.isArray(data) ? (
                    // Handle array of objects
                    data.map((item, idx) => (
                        <div key={idx} className="p-6 grid grid-cols-12 gap-6">
                            <div className="col-span-12">

                                <div
                                    className="px-4 py-2 rounded-t-lg"
                                    style={{ backgroundColor: '#F0F5FF', fontFamily: 'Inter', fontSize: '16px' }}
                                >
                                    <h2 className="text-lg text-gray-800"> {convertToTitleCase(parentKey)} </h2>
                                </div>
                            </div>
                            {Object.keys(item).map((key, i) => (
                                <div key={i} className="col-span-4">
                                    {typeof item[key] === 'object' ? (
                                        <div className="border border-gray-300 rounded-lg shadow-sm bg-white">
                                            <div
                                                className="px-4 py-2 rounded-t-lg"
                                                style={{ backgroundColor: '#F0F5FF', fontFamily: 'Inter', fontSize: '16px' }}
                                            >
                                                <h2 className="text-lg text-gray-800">{convertToTitleCase(key)}</h2>
                                            </div>
                                            {/* Recursively render the nested object */}
                                            <RecursiveRender data={item[key]} parentKey={key} />
                                        </div>
                                    ) : (
                                        <>
                                            <div
                                                className="font-medium text-gray-700 mb-1"
                                                style={{ fontFamily: 'Inter', fontSize: '14px' }}
                                            >
                                                {convertName(key)}
                                            </div>
                                            <div
                                                className="text-gray-900"
                                                style={{ fontFamily: 'Inter', fontSize: '14px' }}
                                            >
                                                {item[key]}
                                            </div>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))
                ) : typeof data === 'object' && data !== null ? (
                    <div className="p-6 grid grid-cols-12 gap-6">
                        {Object.keys(data).map((key, i) => (
                            <div key={i} className="col-span-4">
                                {typeof data[key] === 'object' ? (
                                    <div className="border border-gray-300 rounded-lg shadow-sm bg-white">
                                        <div
                                            className="px-4 py-2 rounded-t-lg"
                                            style={{ backgroundColor: '#F0F5FF', fontFamily: 'Inter', fontSize: '16px' }}
                                        >
                                            <h2 className="text-lg text-gray-800">{convertToTitleCase(key)}</h2>
                                        </div>
                                        {/* Recursively render the child object */}
                                        <RecursiveRender data={data[key]} parentKey={key} />
                                    </div>
                                ) : (
                                    <>
                                        <div
                                            className="font-medium text-gray-700 mb-1"
                                            style={{ fontFamily: 'Inter', fontSize: '14px' }}
                                        >
                                            {convertName(key)}
                                        </div>
                                        <div
                                            className="text-gray-900"
                                            style={{ fontFamily: 'Inter', fontSize: '14px' }}
                                        >
                                            {data[key]}
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div
                        className="font-medium text-gray-700 mb-1"
                        style={{ fontFamily: 'Inter', fontSize: '14px' }}
                    >
                        {convertName(parentKey)}
                        <div className="text-gray-900" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                            {data}
                        </div>
                    </div>
                )}
            </>
        );
    }

    const keyPersonalMemberData = ["Organizer_Information", "Incorporator_Information", "Director_Information", "Officer_Information", "Filer_Information", "President_Information", "Treasurer_Information", "Secretary"]

    console.log(orderTypeId)


    return (
        <div>
            <Toast ref={toast} />
            {loading1 ? (
                <div className="spinner">

                    <div className="loader"></div>
                </div>
            ) : (
                <div className="data-container">



                    <div className="grid font-fam-for-all">
                        <div className="col-8" >
                            <div className="card-container-wizard font-fam-for-all" id="card-container-wizard-id">
                                <div className="wizard-header">
                                    <div className="wizard-header-container">
                                        <div className="page-wizard-header">
                                            <div className="page-wizard-content">
                                                <div className="page-text-header">
                                                    <div className="page-text-header-p">{orderHeader ? toPascalCase(orderHeader.itemName) : ""}</div>
                                                    <div className="page-text-header-p1">
                                                        {orderHeader ? (orderHeader.description1) : ""}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="wizard-content-container">
                                        <div className="wizard-content-main">
                                            <div className="wizard-content-frame">
                                                <div className="wizard-content-card">

                                                    <div className="wizard-form-card-header-container">
                                                        <div className="wizard-form-card-header-actiob-container">
                                                            <div className="wizard-form-card-frame">
                                                                {Object.keys(groupedData).length > 0 ? (
                                                                    <div className="wizard-form-card-header-text1">
                                                                        {`Step ${currentStep} of ${Object.keys(groupedData).length + 3
                                                                            }`}
                                                                    </div>
                                                                ) : (
                                                                    <div className="wizard-form-card-header-text1">
                                                                        {`Step 1 of 1`}
                                                                    </div>
                                                                )}
                                                                {inputFormData.length > 0 ? (
                                                                    <div className="wizard-form-card-header-text2">
                                                                        Total due today
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                            <div className="wizard-form-card-frame">
                                                                <div className="wizard-form-card-header-text3">
                                                                    Entity Details
                                                                </div>
                                                                {inputFormData.length > 0 ? (
                                                                    <div className="wizard-form-card-header-text4">
                                                                        {`$${calculateTotalAmount()}`}
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="wizard-bar"
                                                    >
                                                        <span class={Object.keys(groupedData).length > 0 ? "step" : "step "}>
                                                            <div className="mt-2 wizard-form-card-header-text3">Entity Details</div>
                                                        </span>
                                                        {Object.keys(groupedData).map((key, i) => (
                                                            <span class="step">
                                                                <div className="mt-2 wizard-step-content wizard-form-card-header-text3">{toPascalCase(key)}</div>
                                                            </span>
                                                        ))}

                                                        {inputFormData.length > 0 ? (
                                                            <>
                                                                <span class={Object.keys(groupedData).length > 0 ? "step" : "step"}>
                                                                    <div className="mt-2 wizard-form-card-header-text3">Contact Address</div>
                                                                </span>

                                                                <span class={Object.keys(groupedData).length > 0 ? "step" : "step"}>
                                                                    <div className="mt-2 wizard-form-card-header-text3">Preview</div>
                                                                </span>

                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>

                                                    <div className="wizard-main-content font-fam-for-all mt-4">
                                                        <div className="wizard-main-sub-content">
                                                            <form
                                                                className="form-wizard"
                                                            // onSubmit={handleSubmit(onSubmit)}
                                                            >
                                                                <div className="tab hover-card slide-in font-fam-for-all">
                                                                    <div
                                                                        className="wizard-content-sub-card-header my-3"
                                                                        id="header"
                                                                    >
                                                                        <div className="wizard-content-sub-card-text-frame">
                                                                            <div className="wizard-content-sub-card-text">
                                                                                Select State & Entity
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="flex flex-column justify-content-center">


                                                                        <div className="input-fields-main ">
                                                                            <div className="grid w-full justify-content-center">
                                                                                <div className={state && ((state.stateFullDesc === "New York") || (state.stateFullDesc === "Maryland") || (state.stateFullDesc === "Illinois")) ? "col-4" : "col-6"}>
                                                                                    <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                        <div className="field">
                                                                                            <label
                                                                                                htmlFor=""
                                                                                                className="form-label font-fam-for-all"
                                                                                            >
                                                                                                Select Entity Type
                                                                                                <span className="form-field-mandatory">
                                                                                                    *
                                                                                                </span>
                                                                                            </label>
                                                                                            <span className="p-float-label ">
                                                                                                <Controller
                                                                                                    name="state"
                                                                                                    control={control}
                                                                                                    render={({ field }) => (
                                                                                                        <Dropdown

                                                                                                            value={enityName}
                                                                                                            options={entityTypes}
                                                                                                            onChange={(e) => setEntityName(e.value)}
                                                                                                            optionLabel="entityShortName"
                                                                                                            placeholder="Select Entity Type"
                                                                                                            disabled
                                                                                                            style={{
                                                                                                                width: "100%",
                                                                                                                height: "47px",
                                                                                                            }}

                                                                                                            filter
                                                                                                            className="dialog-form-input-field-wizard"
                                                                                                            required
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className={state && ((state.stateFullDesc === "New York") || (state.stateFullDesc === "Maryland") || (state.stateFullDesc === "Illinois")) ? "col-4" : "col-6"}>
                                                                                    <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                        <div className="field">
                                                                                            <label
                                                                                                htmlFor=""
                                                                                                className="form-label font-fam-for-all"
                                                                                            >
                                                                                                State of Formation{" "}
                                                                                                <span className="form-field-mandatory">
                                                                                                    *
                                                                                                </span>
                                                                                            </label>
                                                                                            <span className="p-float-label ">
                                                                                                <Controller
                                                                                                    name="state"
                                                                                                    control={control}
                                                                                                    render={({ field }) => (
                                                                                                        <Dropdown
                                                                                                            value={field.value}
                                                                                                            id={field.name}
                                                                                                            options={allState}
                                                                                                            onChange={(e) => {
                                                                                                                field.onChange(e.value);
                                                                                                                setState(e.value);
                                                                                                            }}
                                                                                                            disabled
                                                                                                            optionLabel="stateFullDesc"
                                                                                                            style={{
                                                                                                                width: "100%",
                                                                                                                height: "47px",
                                                                                                            }}
                                                                                                            placeholder="Select State"
                                                                                                            filter
                                                                                                            className="dialog-form-input-field-wizard"
                                                                                                            required
                                                                                                        />
                                                                                                    )}
                                                                                                />
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                {
                                                                                    state && ((state.stateFullDesc === "New York") || (state.stateFullDesc === "Maryland") || (state.stateFullDesc === "Illinois")) ? <div className="col-4">
                                                                                        <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                            <div className="field">
                                                                                                <label
                                                                                                    htmlFor=""
                                                                                                    className="form-label font-fam-for-all"
                                                                                                >
                                                                                                    County
                                                                                                    <span className="form-field-mandatory">
                                                                                                        *
                                                                                                    </span>
                                                                                                </label>
                                                                                                <span className="p-float-label ">
                                                                                                    <Controller
                                                                                                        name="county"
                                                                                                        control={control}
                                                                                                        render={({ field }) => (
                                                                                                            <Dropdown
                                                                                                                value={field.value}
                                                                                                                id={field.name}
                                                                                                                options={allCounty}
                                                                                                                onChange={(e) => {
                                                                                                                    field.onChange(e.value);
                                                                                                                    setCounty(e.value);
                                                                                                                }}
                                                                                                                disabled
                                                                                                                optionLabel="countyName"
                                                                                                                style={{
                                                                                                                    width: "100%",
                                                                                                                    height: "47px",
                                                                                                                }}
                                                                                                                placeholder="Select County"
                                                                                                                filter
                                                                                                                className="dialog-form-input-field-wizard"

                                                                                                            />
                                                                                                        )}
                                                                                                    />
                                                                                                </span>

                                                                                            </div>

                                                                                        </div>
                                                                                    </div> : ""
                                                                                }

                                                                            </div>
                                                                        </div>

                                                                        <div
                                                                            className="wizard-content-sub-card-header my-3"
                                                                            id="header"
                                                                        >
                                                                            <div className="wizard-content-sub-card-text-frame">
                                                                                <div className="wizard-content-sub-card-text">
                                                                                    Choose your preferred services from the options below
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {servicesData.map((serviceData, index) => (
                                                                            <div
                                                                                key={index}
                                                                                className="wizard-card-checkbox-container hover-card slide-in"
                                                                            >
                                                                                <div className="wizard-card-checkbox-main pb-0">
                                                                                    <div className="wizard-card-checkbox-text-main">
                                                                                        <div className="wizard-card-checkbox-action-container">
                                                                                            <Checkbox
                                                                                                className="mt-2"
                                                                                                disabled={checkedState[serviceData.product.displayName] || serviceData.product.isDefault || (isPaymentDone ? true : false)}
                                                                                                checked={checkedState[serviceData.product.displayName] || serviceData.product.isDefault}
                                                                                                onChange={(e) =>
                                                                                                    handleCheckedChange(e, serviceData)
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        <div className="w-full grid">
                                                                                            <div className="col-11 p-3">
                                                                                                <div className="wizard-card-checkbox-text1 font-fam-for-all text-start">
                                                                                                    <div className="flex align-items-center">
                                                                                                        {serviceData.product.displayName}
                                                                                                        <button
                                                                                                            className="ml-2 bg-transparent border-none cursor-pointer"
                                                                                                            data-bs-toggle="tooltip"
                                                                                                            data-bs-placement="right"
                                                                                                            title={serviceData.product.productMaster.productAdditionalInfo}
                                                                                                        >
                                                                                                            <i
                                                                                                                className="pi pi-info-circle"
                                                                                                                style={{
                                                                                                                    fontSize: "1.2rem",
                                                                                                                    color: "#007bff",
                                                                                                                }}
                                                                                                            ></i>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="wizard-card-checkbox-text2 justify-content-end col-1 flex align-items-center">
                                                                                                {serviceData.product.unitPrice === 0 ? "Included" : `$${serviceData.product.unitPrice}`}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>



                                                                {Object.keys(groupedData).map((key, i) => (
                                                                    <div className="tab" key={i}>
                                                                        <div className="wizard-content-sub-card-header my-3" id="header">
                                                                            <div className="wizard-content-sub-card-text-frame">
                                                                                <div className="wizard-content-sub-card-text">
                                                                                    {toPascalCase(key)}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            {
                                                                                i === 0 && orderTypeId !== 5 && orderTypeId !== 1 && orderTypeId !== 18 ? <>
                                                                                    <div className="grid w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Select Company
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className="p-float-label ">
                                                                                                        <Controller
                                                                                                            name="orgaineriInfoId"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={adminCompany}
                                                                                                                    options={allAdminCompany}
                                                                                                                    onChange={(e) => setAdminCompany(e.value)}
                                                                                                                    optionLabel="companyName"
                                                                                                                    optionValue="companyName"
                                                                                                                    placeholder="Select Company Name"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex">

                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Company"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => setVisible9(true)}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>
                                                                                </> : <>
                                                                                    <div></div>
                                                                                </>
                                                                            }
                                                                            {

                                                                                key === "Organizer_Information" ? <>
                                                                                    <div className="grid w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Select Organizer
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className="p-float-label ">
                                                                                                        <Controller
                                                                                                            name="orgaineriInfoId"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={organizerData}
                                                                                                                    options={allOrganizer}
                                                                                                                    onChange={(e) => setOrganizerData(e.value)}
                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                    placeholder="Select Entity Type"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex">
                                                                                            {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("ORGANIZER")}>
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Organizer</div>
                                                                                                </div>

                                                                                            </button> */}
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Organizer"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => handleKeyPersonal("ORGANIZER")}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>
                                                                                </> : <>
                                                                                    <div></div>
                                                                                </>
                                                                            }
                                                                            {
                                                                                key === "Principal_Address" ? <>
                                                                                    <div className="grid w-full justify-content-start">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Select Principal Address
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className="p-float-label ">
                                                                                                        <Controller
                                                                                                            name="principalAddress"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={location}
                                                                                                                    options={allLocation}
                                                                                                                    onChange={(e) => setLocation(e.value)}
                                                                                                                    optionLabel="addressLine1"
                                                                                                                    placeholder="Select Address"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-5 justify-content-start align-items-center flex">
                                                                                            {/* <button className="datatable-highlight-color" onClick={() => setVisible3(true)}>
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Principle Address</div>
                                                                                                </div>

                                                                                            </button> */}
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Principal Address"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => setVisible3(true)}

                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>
                                                                                </> : ""
                                                                            }
                                                                            {
                                                                                key === "Incorporator_Information" ? <>
                                                                                    <div className="grid w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Select Incorporator
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className="p-float-label ">
                                                                                                        <Controller
                                                                                                            name="incorporator"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={incorporator}
                                                                                                                    options={allInCorporator}
                                                                                                                    onChange={(e) => setIncorporator(e.value)}
                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                    placeholder="Select Incorporator"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex">
                                                                                            {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("INCORPORATOR")}>
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Incorporator</div>
                                                                                                </div>

                                                                                            </button> */}
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Incorporator"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => handleKeyPersonal("INCORPORATOR")}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>
                                                                                </> : ""
                                                                            }

                                                                            {
                                                                                key === "Director_Information" ? <>
                                                                                    <div className="grid w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Select Director
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className="p-float-label ">
                                                                                                        <Controller
                                                                                                            name="incorporator"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={director}
                                                                                                                    options={allDirector}
                                                                                                                    onChange={(e) => setDirector(e.value)}
                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                    placeholder="Select Director"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex">
                                                                                            {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DIRECTOR")}>
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Director</div>
                                                                                                </div>

                                                                                            </button> */}
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Director"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => handleKeyPersonal("DIRECTOR")}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>
                                                                                </> : ""
                                                                            }

                                                                            {
                                                                                key === "Officer_Information" ? <>
                                                                                    <div className="grid w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Select Officer
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className="p-float-label ">
                                                                                                        <Controller
                                                                                                            name="incorporator"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={officer}
                                                                                                                    options={allOfficer}
                                                                                                                    onChange={(e) => setOfficer(e.value)}
                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                    placeholder="Select Incorporator"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex">
                                                                                            {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("OFFICER")}>
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Officer</div>
                                                                                                </div>

                                                                                            </button> */}
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Officer"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => handleKeyPersonal("OFFICER")}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>
                                                                                </> : ""
                                                                            }

                                                                            {
                                                                                key === "Filer_Information" ? <>
                                                                                    <div className="grid w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Select Filer
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className="p-float-label ">
                                                                                                        <Controller
                                                                                                            name="incorporator"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={filer}
                                                                                                                    options={allFiler}
                                                                                                                    onChange={(e) => setFiler(e.value)}
                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                    placeholder="Select Incorporator"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex">
                                                                                            {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("OFFICER")}>
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Officer</div>
                                                                                                </div>

                                                                                            </button> */}
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Filer"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => handleKeyPersonal("FILER")}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>
                                                                                </> : ""
                                                                            }

                                                                            {
                                                                                key === "President_Information" ? <>
                                                                                    <div className="grid w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Select President
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className="p-float-label ">
                                                                                                        <Controller
                                                                                                            name="incorporator"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={president}
                                                                                                                    options={allPresident}
                                                                                                                    onChange={(e) => setPresident(e.value)}
                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                    placeholder="Select President"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex">

                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add President"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => handleKeyPersonal("PRESIDENT")}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>
                                                                                </> : ""
                                                                            }

                                                                            {
                                                                                key === "Treasurer_Information" ? <>
                                                                                    <div className="grid w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Select Treasurer
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className="p-float-label ">
                                                                                                        <Controller
                                                                                                            name="incorporator"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={treasurer}
                                                                                                                    options={allTreasurer}
                                                                                                                    onChange={(e) => setTreasurer(e.value)}
                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                    placeholder="Select Treasurer"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex">

                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Treasurer"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => handleKeyPersonal("TREASURER")}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>
                                                                                </> : ""
                                                                            }

                                                                            {
                                                                                key === "Secretary" ? <>
                                                                                    <div className="grid w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Select Secretary
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className="p-float-label ">
                                                                                                        <Controller
                                                                                                            name="incorporator"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={secretary}
                                                                                                                    options={allSecretary}
                                                                                                                    onChange={(e) => setSecretary(e.value)}
                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                    placeholder="Select Secretary"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex">

                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Secretary"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => handleKeyPersonal("SECRETARY")}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>
                                                                                </> : ""
                                                                            }
                                                                            {
                                                                                key === "Registered_Agent" ? <>
                                                                                    <div className="grid w-full justify-content-between">
                                                                                        <div className="col-8">
                                                                                            <div className='flex justify-content-start mb-3 ml-1'>
                                                                                                <Checkbox
                                                                                                    checked={IsCheckedAgent}
                                                                                                    onChange={(e) => setIsCheckedAgent(e.checked)}
                                                                                                    inputProps={{ 'aria-label': 'controlled' }} />

                                                                                                <label htmlFor="binary" className='font-fam-for-all ml-2 srch-cand-text' >Select vState as your Registered Agent
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                        {/* <div className="col-2 justify-content-end align-items-center flex">
                                                                                            {
                                                                                                key === "Registered_Agent" ? <Button type='button' className="mt-2   vstate-reverse-button font-fam-for-all text-center  justify-content-center" onClick={() => nextPrev(1)} >Skip</Button>
                                                                                                    : ""
                                                                                            }
                                                                                        </div> */}
                                                                                    </div>
                                                                                </> : ""
                                                                            }
                                                                        </div>
                                                                        {
                                                                            orderTypeId === 1 ? <>
                                                                                {
                                                                                    groupedData[key].mode && groupedData[key].mode.repeat === true ? <div>
                                                                                        <div className="vstate-card p-4 mb-3" >
                                                                                            <div className="col-12 p-0">
                                                                                                <h2 className="entity-header-strapi">{toPascalCase(groupedData[key].form[0].schema.displayName)}</h2>
                                                                                                {groupedData[key].mode && groupedData[key].mode.repeat === true}

                                                                                            </div>
                                                                                            {
                                                                                                Array.from({ length: formCount }).map((_, l) => (
                                                                                                    groupedData[key].form.map((value, j) => (
                                                                                                        <>
                                                                                                            <div>

                                                                                                                {

                                                                                                                    <div className="vstate-card p-4 mb-3">
                                                                                                                        <div className="grid" key={l}>

                                                                                                                            {Object.keys(value.schema.attributes).map((val, k) => {
                                                                                                                                const content = value.schema.attributes[val].type !== undefined
                                                                                                                                    ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key)
                                                                                                                                    : null;

                                                                                                                                return content ? (
                                                                                                                                    <div className="col-6" key={k}>
                                                                                                                                        {content}
                                                                                                                                    </div>
                                                                                                                                ) : null;
                                                                                                                            })}
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="card-devider"></div>
                                                                                                        </>


                                                                                                    ))
                                                                                                ))
                                                                                            }

                                                                                        </div>


                                                                                        {groupedData[key].mode && groupedData[key].mode.repeat === true && (
                                                                                            <div className="flex gap-2 justify-content-center w-full">
                                                                                                <Button type="button" icon="pi pi-plus" label="Add Entry" className="vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => handleRepeatForm()}></Button>
                                                                                                <Button type="button" icon="pi pi-minus" disabled={formCount === 1} label="Remove Entry" className="vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => handleRemoveRepeatForm(key)}></Button>

                                                                                            </div>

                                                                                        )}
                                                                                    </div> : groupedData[key].form.map((value, j) => (
                                                                                        <>
                                                                                            {
                                                                                                value.schema.displayName === "Billing_Information" ? <>
                                                                                                    <div className="grid w-full justify-content-between">
                                                                                                        <div className="col-6">
                                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                                <div className="field">
                                                                                                                    <label
                                                                                                                        htmlFor=""
                                                                                                                        className="form-label font-fam-for-all"
                                                                                                                    >
                                                                                                                        Select Billing Info
                                                                                                                        <span className="form-field-mandatory">
                                                                                                                            *
                                                                                                                        </span>
                                                                                                                    </label>
                                                                                                                    <span className="p-float-label ">
                                                                                                                        <Controller
                                                                                                                            name="incorporator"
                                                                                                                            control={control}
                                                                                                                            render={({ field }) => (
                                                                                                                                <Dropdown

                                                                                                                                    value={bilingInfo}
                                                                                                                                    options={allBilingInfo}
                                                                                                                                    onChange={(e) => setBilingInfo(e.value)}
                                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                                    placeholder="Select Billing info"
                                                                                                                                    style={{
                                                                                                                                        width: "100%",
                                                                                                                                        height: "47px",
                                                                                                                                    }}

                                                                                                                                    filter
                                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                                    required
                                                                                                                                />
                                                                                                                            )}
                                                                                                                        />
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-6 justify-content-start align-items-center flex">

                                                                                                            <Button
                                                                                                                type="button"
                                                                                                                icon="pi pi-plus"
                                                                                                                style={{ fontSize: "14px" }}
                                                                                                                label="Add Billing Info"
                                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                                onClick={() => handleKeyPersonal("RA-BILLING")}
                                                                                                            ></Button>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </> : ""
                                                                                            }

                                                                                            {
                                                                                                value.schema.displayName === "Mailing_Information" ? <>
                                                                                                    <div className="grid w-full justify-content-between">
                                                                                                        <div className="col-6">
                                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                                <div className="field">
                                                                                                                    <label
                                                                                                                        htmlFor=""
                                                                                                                        className="form-label font-fam-for-all"
                                                                                                                    >
                                                                                                                        Select Mailing Info
                                                                                                                        <span className="form-field-mandatory">
                                                                                                                            *
                                                                                                                        </span>
                                                                                                                    </label>
                                                                                                                    <span className="p-float-label ">
                                                                                                                        <Controller
                                                                                                                            name="incorporator"
                                                                                                                            control={control}
                                                                                                                            render={({ field }) => (
                                                                                                                                <Dropdown

                                                                                                                                    value={mailingInfo}
                                                                                                                                    options={allMailingInfo}
                                                                                                                                    onChange={(e) => setMailingInfo(e.value)}
                                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                                    placeholder="Select Mailing Info"
                                                                                                                                    style={{
                                                                                                                                        width: "100%",
                                                                                                                                        height: "47px",
                                                                                                                                    }}

                                                                                                                                    filter
                                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                                    required
                                                                                                                                />
                                                                                                                            )}
                                                                                                                        />
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-6 justify-content-start align-items-center flex">

                                                                                                            <Button
                                                                                                                type="button"
                                                                                                                icon="pi pi-plus"
                                                                                                                style={{ fontSize: "14px" }}
                                                                                                                label="Add Mailing Info"
                                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                                onClick={() => handleKeyPersonal("RA-MAILING")}
                                                                                                            ></Button>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </> : ""
                                                                                            }
                                                                                             {
                                                                                        value.schema.displayName === "Mailing_Information" && <div className='grid justify-content-start ml-2 mt-2 mb-3'>
                                                                                            <div className=' p-0'>
                                                                                                <div className='flex justify-content-center'>
                                                                                                    <Checkbox
                                                                                                        checked={IsCheckedAgent2}
                                                                                                        onChange={(e) => {
                                                                                                            setIsCheckedAgent2(e.checked)
                                                                                                        }}
                                                                                                        inputProps={{ 'aria-label': 'controlled' }} />

                                                                                                    <label htmlFor="binary" className='font-fam-for-all ml-2 srch-cand-text' >Same as Registered Agent
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                            {
                                                                                                value.schema.displayName === "Mailing_Information" && <div className='grid justify-content-start ml-2 mt-2 mb-3'>
                                                                                                    <div className=' p-0'>
                                                                                                        <div className='flex justify-content-center'>
                                                                                                            <Checkbox
                                                                                                                checked={IsCheckedAgent1}
                                                                                                                onChange={(e) => {
                                                                                                                    setIsCheckedAgent1(e.checked)
                                                                                                                }}
                                                                                                                inputProps={{ 'aria-label': 'controlled' }} />

                                                                                                            <label htmlFor="binary" className='font-fam-for-all ml-2 srch-cand-text' >Same as Billing Info
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }
                                                                                            <div className="vstate-card p-4 mb-3">


                                                                                                <div className="grid " key={j}>

                                                                                                    <div className="col-12 pb-0">
                                                                                                        <h2 className="entity-header-strapi">{toPascalCase(value.schema.displayName)}</h2>

                                                                                                    </div>
                                                                                                    {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                        <div className="col-6" key={k}>
                                                                                                            {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, val, value, key) : ""}
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    ))}
                                                                            </> : <>

                                                                                {
                                                                                    groupedData[key].mode && groupedData[key].mode.repeat === true ? <div>
                                                                                        <div className="vstate-card p-4 mb-3" >
                                                                                            <div className="col-12 p-0">
                                                                                                <h2 className="entity-header-strapi">{toPascalCase(groupedData[key].form[0].schema.displayName)}</h2>
                                                                                                {groupedData[key].mode && groupedData[key].mode.repeat === true}

                                                                                            </div>

                                                                                            {
                                                                                                Array.from({ length: formCount }).map((_, l) => (
                                                                                                    <>

                                                                                                        {
                                                                                                            key === "ucc_Debtor_Details" ? <>
                                                                                                                <div className="grid w-full justify-content-between">
                                                                                                                    <div className="col-6">
                                                                                                                        <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                                            <div className="field">
                                                                                                                                <label
                                                                                                                                    htmlFor=""
                                                                                                                                    className="form-label font-fam-for-all"
                                                                                                                                >
                                                                                                                                    Select Debtor
                                                                                                                                    <span className="form-field-mandatory">
                                                                                                                                        *
                                                                                                                                    </span>
                                                                                                                                </label>
                                                                                                                                <span className="p-float-label ">
                                                                                                                                    <Controller
                                                                                                                                        name={`ucc_Debtor_Details_Dropdown-${l}`}
                                                                                                                                        control={control}
                                                                                                                                        render={({ field }) => (
                                                                                                                                            <Dropdown

                                                                                                                                                value={field.value}
                                                                                                                                                options={aallDebtor}

                                                                                                                                                onChange={(e) => {
                                                                                                                                                    field.onChange(e.value);

                                                                                                                                                    handleDebtorChange(e.value, l);
                                                                                                                                                }}
                                                                                                                                                optionLabel="keyPersonnelName"
                                                                                                                                                placeholder="Select Debtor"
                                                                                                                                                style={{
                                                                                                                                                    width: "100%",
                                                                                                                                                    height: "47px",
                                                                                                                                                }}

                                                                                                                                                filter
                                                                                                                                                className="dialog-form-input-field-wizard"
                                                                                                                                                required
                                                                                                                                            />
                                                                                                                                        )}
                                                                                                                                    />
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-6 justify-content-start align-items-center mt-3">
                                                                                                                        {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                        </svg>
                                                                                                                        <div className='font-medium'>Add Debtor</div>
                                                                                                                    </div>

                                                                                                                </button> */}
                                                                                                                        <Button
                                                                                                                            type="button"
                                                                                                                            icon="pi pi-plus"
                                                                                                                            style={{ fontSize: "14px" }}
                                                                                                                            label="Add Debtor"
                                                                                                                            className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                                            onClick={() => handleKeyPersonal("DEBTOR")}
                                                                                                                        ></Button>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </> : ""
                                                                                                        }


                                                                                                        {
                                                                                                            key === "Company_Applicant_Information" ? <>
                                                                                                                <div className="grid w-full justify-content-between">
                                                                                                                    <div className="col-6">
                                                                                                                        <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                                            <div className="field">
                                                                                                                                <label
                                                                                                                                    htmlFor=""
                                                                                                                                    className="form-label font-fam-for-all"
                                                                                                                                >
                                                                                                                                    Select Company Applicant
                                                                                                                                    <span className="form-field-mandatory">
                                                                                                                                        *
                                                                                                                                    </span>
                                                                                                                                </label>
                                                                                                                                <span className="p-float-label ">
                                                                                                                                    <Controller
                                                                                                                                        name={`company_applicant_item_${l}`}
                                                                                                                                        control={control}
                                                                                                                                        render={({ field }) => (
                                                                                                                                            <Dropdown

                                                                                                                                                value={field.value}
                                                                                                                                                options={allCompanyApplicant}

                                                                                                                                                onChange={(e) => {
                                                                                                                                                    field.onChange(e.value);

                                                                                                                                                    handleCompanyApplicantChange(e.value, l);
                                                                                                                                                }}
                                                                                                                                                optionLabel="keyPersonnelName"
                                                                                                                                                placeholder="Select Company Applicant"
                                                                                                                                                style={{
                                                                                                                                                    width: "100%",
                                                                                                                                                    height: "47px",
                                                                                                                                                }}

                                                                                                                                                filter
                                                                                                                                                className="dialog-form-input-field-wizard"
                                                                                                                                                required
                                                                                                                                            />
                                                                                                                                        )}
                                                                                                                                    />
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-6 justify-content-start align-items-center mt-3">
                                                                                                                        {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                        </svg>
                                                                                                                        <div className='font-medium'>Add Debtor</div>
                                                                                                                    </div>

                                                                                                                </button> */}
                                                                                                                        <Button
                                                                                                                            type="button"
                                                                                                                            icon="pi pi-plus"
                                                                                                                            style={{ fontSize: "14px" }}
                                                                                                                            label="Add Company Applicant"
                                                                                                                            className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                                            onClick={() => handleBOIKeyPersonal("COMPANY APPLICANT")}
                                                                                                                        ></Button>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </> : ""
                                                                                                        }


                                                                                                        {
                                                                                                            key === "ucc_Secured_Party_Details" ? <div className="grid w-full justify-content-between">
                                                                                                                <div className="col-6">
                                                                                                                    <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                                        <div className="field">
                                                                                                                            <label
                                                                                                                                htmlFor=""
                                                                                                                                className="form-label font-fam-for-all"
                                                                                                                            >
                                                                                                                                Select Additional SecuredParty
                                                                                                                                <span className="form-field-mandatory">
                                                                                                                                    *
                                                                                                                                </span>
                                                                                                                            </label>
                                                                                                                            <span className="p-float-label ">
                                                                                                                                <Controller
                                                                                                                                    name={`uccAmend_Additional_secured_party_Details-${l}`}
                                                                                                                                    control={control}
                                                                                                                                    render={({ field }) => (
                                                                                                                                        <Dropdown

                                                                                                                                            value={field.value}
                                                                                                                                            options={allSecuredParty}

                                                                                                                                            onChange={(e) => {
                                                                                                                                                field.onChange(e.value);

                                                                                                                                                handleAdditionalSecuredPartyChange1(e.value, l);
                                                                                                                                            }}
                                                                                                                                            optionLabel="keyPersonnelName"
                                                                                                                                            placeholder="Select Secured Party"
                                                                                                                                            style={{
                                                                                                                                                width: "100%",
                                                                                                                                                height: "47px",
                                                                                                                                            }}

                                                                                                                                            filter
                                                                                                                                            className="dialog-form-input-field-wizard"
                                                                                                                                            required
                                                                                                                                        />
                                                                                                                                    )}
                                                                                                                                />
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-6 justify-content-start align-items-center mt-3">
                                                                                                                    {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("SECUERED_PARTY")}>
                                                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                </svg>
                                                                                                                <div className='font-medium'>Add Additional Secured Party</div>
                                                                                                            </div>

                                                                                                        </button> */}
                                                                                                                    <Button
                                                                                                                        type="button"
                                                                                                                        icon="pi pi-plus"
                                                                                                                        style={{ fontSize: "14px" }}
                                                                                                                        label="Add Additional Secured Party"
                                                                                                                        className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                                        onClick={() => handleKeyPersonal("SECUERED PARTY")}
                                                                                                                    ></Button>
                                                                                                                </div>

                                                                                                            </div> : ""
                                                                                                        }




                                                                                                        {
                                                                                                            groupedData[key].form.map((value, j) => (
                                                                                                                <>
                                                                                                                    <div>


                                                                                                                        {
                                                                                                                            getValues("COC_What_Are_We_Changing") === "Address" && value.apiId === "address" ?

                                                                                                                                <div className="vstate-card p-4 mb-3">

                                                                                                                                    <div className="grid" key={l}>

                                                                                                                                        {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                                            <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`}>
                                                                                                                                                {value.schema.attributes[val].type !== undefined
                                                                                                                                                    ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key)
                                                                                                                                                    : ""}
                                                                                                                                            </div>
                                                                                                                                        ))}
                                                                                                                                    </div>
                                                                                                                                </div> : ""

                                                                                                                        }
                                                                                                                        {
                                                                                                                            getValues(`boi_Exempt_Entity-${l}`) === undefined && value.apiId !== "address" && value.apiId !== "boi-beneficial-owwner-details" && value.apiId !== "boi-except-entity-form" && value.apiId !== "boi-beneficial-owner-form"
                                                                                                                                && value.apiId !== "ucc-amnd-additional-debtor" ?

                                                                                                                                <div className="vstate-card p-4 mb-3">

                                                                                                                                    <div className="grid" key={l}>

                                                                                                                                        {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                                            <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`}>
                                                                                                                                                {value.schema.attributes[val].type !== undefined
                                                                                                                                                    ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key)
                                                                                                                                                    : ""}
                                                                                                                                            </div>
                                                                                                                                        ))}
                                                                                                                                    </div>
                                                                                                                                </div> : ""

                                                                                                                        }
                                                                                                                        {
                                                                                                                            getValues(`boi_Exempt_Entity-${l}`) === undefined && value.apiId === "address" && value.apiId !== "boi-beneficial-owwner-details" && value.apiId !== "boi-except-entity-form" && value.apiId !== "boi-beneficial-owner-form" ?

                                                                                                                                <div className="vstate-card p-4 mb-3">

                                                                                                                                    <div className="grid" key={l}>

                                                                                                                                        {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                                            <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`}>
                                                                                                                                                {value.schema.attributes[val].type !== undefined
                                                                                                                                                    ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key)
                                                                                                                                                    : ""}
                                                                                                                                            </div>
                                                                                                                                        ))}
                                                                                                                                    </div>
                                                                                                                                </div> : ""

                                                                                                                        }
                                                                                                                        {
                                                                                                                            value.apiId === "boi-beneficial-owner-form" && value.apiId !== "boi-beneficial-owner-details" && value.apiId !== "boi-except-entity-form" ?

                                                                                                                                <div className="vstate-card p-4 mb-3">

                                                                                                                                    <div className="grid" key={l}>

                                                                                                                                        {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                                            <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`}>
                                                                                                                                                {value.schema.attributes[val].type !== undefined
                                                                                                                                                    ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key)
                                                                                                                                                    : ""}
                                                                                                                                            </div>
                                                                                                                                        ))}
                                                                                                                                    </div>
                                                                                                                                </div> : ""

                                                                                                                        }
                                                                                                                        {
                                                                                                                            value.apiId === "boi-company-applicant-details" ?
                                                                                                                                <div className="vstate-card p-4 mb-3">

                                                                                                                                    <div className="grid" key={l}>

                                                                                                                                        {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                                            <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`}>
                                                                                                                                                {value.schema.attributes[val].type !== undefined
                                                                                                                                                    ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key)
                                                                                                                                                    : ""}
                                                                                                                                            </div>
                                                                                                                                        ))}
                                                                                                                                    </div>
                                                                                                                                </div> : ""
                                                                                                                        }


                                                                                                                        {/* boi-beneficial-owwner-details for Exempt Entity true */}
                                                                                                                        {
                                                                                                                            (getValues(`boi_Exempt_Entity-${l}`) === false) && value.apiId === "boi-beneficial-owner-details" ? (
                                                                                                                                <>
                                                                                                                                    <div className="vstate-card p-4 mb-3">


                                                                                                                                        <div className="grid" key={j}>

                                                                                                                                            <div className="col-12 pb-0">
                                                                                                                                                <h2 className="entity-header-strapi">{toPascalCase(value.schema.displayName)}</h2>

                                                                                                                                            </div>
                                                                                                                                            <div className="grid w-full justify-content-between">
                                                                                                                                                <div className="col-6">
                                                                                                                                                    <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                                                                        <div className="field">
                                                                                                                                                            <label
                                                                                                                                                                htmlFor=""
                                                                                                                                                                className="form-label font-fam-for-all"
                                                                                                                                                            >
                                                                                                                                                                Select Beneficial Owner
                                                                                                                                                                <span className="form-field-mandatory">
                                                                                                                                                                    *
                                                                                                                                                                </span>
                                                                                                                                                            </label>
                                                                                                                                                            <span className="p-float-label ">
                                                                                                                                                                <Controller
                                                                                                                                                                    name={`ucc_Beneficial_Dropdown-${l}`}
                                                                                                                                                                    control={control}
                                                                                                                                                                    render={({ field }) => (
                                                                                                                                                                        <Dropdown

                                                                                                                                                                            value={field.value}
                                                                                                                                                                            options={allBeneficialOwner}

                                                                                                                                                                            onChange={(e) => {
                                                                                                                                                                                field.onChange(e.value);

                                                                                                                                                                                handleBeneficialOwnerChange(e.value, l);
                                                                                                                                                                            }}
                                                                                                                                                                            optionLabel="keyPersonnelName"
                                                                                                                                                                            placeholder="Select Beneficial Owner"
                                                                                                                                                                            style={{
                                                                                                                                                                                width: "100%",
                                                                                                                                                                                height: "47px",
                                                                                                                                                                            }}

                                                                                                                                                                            filter
                                                                                                                                                                            className="dialog-form-input-field-wizard"
                                                                                                                                                                            required
                                                                                                                                                                        />
                                                                                                                                                                    )}
                                                                                                                                                                />
                                                                                                                                                            </span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                <div className="col-6 justify-content-start align-items-center mt-3">
                                                                                                                                                    {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                        </svg>
                                                                                                                        <div className='font-medium'>Add Debtor</div>
                                                                                                                    </div>

                                                                                                                </button> */}
                                                                                                                                                    <Button
                                                                                                                                                        type="button"
                                                                                                                                                        icon="pi pi-plus"
                                                                                                                                                        style={{ fontSize: "14px" }}
                                                                                                                                                        label="Add Beneficial Owner"
                                                                                                                                                        className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                                                                        onClick={() => handleBOIKeyPersonal("BENEFICIAL OWNER")}
                                                                                                                                                    ></Button>
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                                                <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                                                    {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) : ""}
                                                                                                                                                </div>
                                                                                                                                            ))}
                                                                                                                                        </div>
                                                                                                                                    </div>

                                                                                                                                </>
                                                                                                                            ) : ""
                                                                                                                        }

                                                                                                                        {/* boi-beneficial-owwner-details for Exempt Entity false */}
                                                                                                                        {

                                                                                                                            (getValues(`boi_Exempt_Entity-${l}`)) && value.apiId === "boi-except-entity-form" ? (
                                                                                                                                <>
                                                                                                                                    <div className="vstate-card p-4 mb-3">

                                                                                                                                        <div className="grid" key={j}>

                                                                                                                                            <div className="col-12 pb-0">
                                                                                                                                                <h2 className="entity-header-strapi">{toPascalCase(value.schema.displayName)}</h2>

                                                                                                                                            </div>
                                                                                                                                            {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                                                <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                                                    {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) : ""}
                                                                                                                                                </div>
                                                                                                                                            ))}
                                                                                                                                        </div>
                                                                                                                                    </div>

                                                                                                                                </>
                                                                                                                            ) : ""
                                                                                                                        }

                                                                                                                        {
                                                                                                                            (getValues("uccAmend_What_are_we_amending") === "ADDITIONAL DEBTOR") && value.apiId === "ucc-amnd-additional-debtor" ?
                                                                                                                                (
                                                                                                                                    <>
                                                                                                                                        <div className="vstate-card p-4 mb-3">

                                                                                                                                            <div className="grid" key={j}>

                                                                                                                                                <div className="col-12 pb-0">
                                                                                                                                                    <h2 className="entity-header-strapi">{toPascalCase(value.schema.displayName)}</h2>

                                                                                                                                                </div>
                                                                                                                                                <div className="grid w-full justify-content-between">
                                                                                                                                                    <div className="col-6">
                                                                                                                                                        <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                                                                            <div className="field">
                                                                                                                                                                <label
                                                                                                                                                                    htmlFor=""
                                                                                                                                                                    className="form-label font-fam-for-all"
                                                                                                                                                                >
                                                                                                                                                                    Select Additional Debtor
                                                                                                                                                                    <span className="form-field-mandatory">
                                                                                                                                                                        *
                                                                                                                                                                    </span>
                                                                                                                                                                </label>
                                                                                                                                                                <span className="p-float-label ">
                                                                                                                                                                    <Controller
                                                                                                                                                                        name={`uccAmend_Additional_Debtor_Details-${l}`}
                                                                                                                                                                        control={control}
                                                                                                                                                                        render={({ field }) => (
                                                                                                                                                                            <Dropdown

                                                                                                                                                                                value={field.value}
                                                                                                                                                                                options={aallDebtor}

                                                                                                                                                                                onChange={(e) => {
                                                                                                                                                                                    field.onChange(e.value);

                                                                                                                                                                                    handleAdditionalDebtorChange(e.value, l);
                                                                                                                                                                                }}
                                                                                                                                                                                optionLabel="keyPersonnelName"
                                                                                                                                                                                placeholder="Select Debtor"
                                                                                                                                                                                style={{
                                                                                                                                                                                    width: "100%",
                                                                                                                                                                                    height: "47px",
                                                                                                                                                                                }}

                                                                                                                                                                                filter
                                                                                                                                                                                className="dialog-form-input-field-wizard"
                                                                                                                                                                                required
                                                                                                                                                                            />
                                                                                                                                                                        )}
                                                                                                                                                                    />
                                                                                                                                                                </span>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                    <div className="col-6 justify-content-start align-items-center mt-3">
                                                                                                                                                        {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                                        </svg>
                                                                                                                                                        <div className='font-medium'>Add Additional Debtor</div>
                                                                                                                                                    </div>

                                                                                                                                                </button> */}
                                                                                                                                                        <Button
                                                                                                                                                            type="button"
                                                                                                                                                            icon="pi pi-plus"
                                                                                                                                                            style={{ fontSize: "14px" }}
                                                                                                                                                            label="Add Additionaal Debtor"
                                                                                                                                                            className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                                                                            onClick={() => handleKeyPersonal("DEBTOR")}
                                                                                                                                                        ></Button>
                                                                                                                                                    </div>

                                                                                                                                                </div>
                                                                                                                                                {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                                                    <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                                                        {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) : ""}
                                                                                                                                                    </div>
                                                                                                                                                ))}
                                                                                                                                            </div>
                                                                                                                                        </div>

                                                                                                                                    </>
                                                                                                                                ) : ""
                                                                                                                        }

                                                                                                                        {
                                                                                                                            (getValues("uccAmend_What_are_we_amending") === "ADDITIONAL SECURED") && value.apiId === "ucc-amend-additional-secured-party-details" ?
                                                                                                                                (
                                                                                                                                    <>
                                                                                                                                        <div className="vstate-card p-4 mb-3">

                                                                                                                                            <div className="grid" key={j}>

                                                                                                                                                <div className="col-12 pb-0">
                                                                                                                                                    <h2 className="entity-header-strapi">{toPascalCase(value.schema.displayName)}</h2>

                                                                                                                                                </div>
                                                                                                                                                {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                                                    <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                                                        {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) : ""}
                                                                                                                                                    </div>
                                                                                                                                                ))}
                                                                                                                                            </div>
                                                                                                                                        </div>

                                                                                                                                    </>
                                                                                                                                ) : ""
                                                                                                                        }



                                                                                                                    </div>

                                                                                                                </>
                                                                                                            ))
                                                                                                        }</>
                                                                                                ))
                                                                                            }

                                                                                        </div >


                                                                                        {
                                                                                            groupedData[key].mode && groupedData[key].mode.repeat === true && (
                                                                                                <div className="flex gap-2 justify-content-center w-full">
                                                                                                    <Button type="button" icon="pi pi-plus" disabled={
                                                                                                        groupedData[key].form.length > 0 &&
                                                                                                        groupedData[key].form[0].apiId === "boi-company-applicant-details" &&
                                                                                                        formCount >= 2
                                                                                                    } label="Add Entry" className="vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => handleRepeatForm(key)}></Button>
                                                                                                    <Button type="button" icon="pi pi-minus" disabled={formCount === 1} label="Remove Entry" className="vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => handleRemoveRepeatForm(key)}></Button>


                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div> : groupedData[key].form.map((value, j) => (
                                                                                        <>
                                                                                            {

                                                                                                (getValues("uccAmend_What_are_we_amending") === "ADDITIONAL DEBTOR") && value.apiId === "ucc-amnd-additional-debtor" && value.apiId !== "uucAmnd-What-are-amending" ? Array.from({ length: formCount }).map((_, l) => (

                                                                                                    <>
                                                                                                        <div className="vstate-card p-4 mb-3">

                                                                                                            <div className="grid" key={j}>

                                                                                                                <div className="col-12 pb-0">
                                                                                                                    <h2 className="entity-header-strapi">{toPascalCase(value.schema.displayName)}</h2>

                                                                                                                </div>
                                                                                                                <div className="grid w-full justify-content-between">
                                                                                                                    <div className="col-6">
                                                                                                                        <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                                            <div className="field">
                                                                                                                                <label
                                                                                                                                    htmlFor=""
                                                                                                                                    className="form-label font-fam-for-all"
                                                                                                                                >
                                                                                                                                    Select Additional Debtor
                                                                                                                                    <span className="form-field-mandatory">
                                                                                                                                        *
                                                                                                                                    </span>
                                                                                                                                </label>
                                                                                                                                <span className="p-float-label ">
                                                                                                                                    <Controller
                                                                                                                                        name={`uccAmend_Additional_Debtor_Details-${l}`}
                                                                                                                                        control={control}
                                                                                                                                        render={({ field }) => (
                                                                                                                                            <Dropdown

                                                                                                                                                value={field.value}
                                                                                                                                                options={aallDebtor}

                                                                                                                                                onChange={(e) => {
                                                                                                                                                    field.onChange(e.value);

                                                                                                                                                    handleAdditionalDebtorChange(e.value, l);
                                                                                                                                                }}
                                                                                                                                                optionLabel="keyPersonnelName"
                                                                                                                                                placeholder="Select Debtor"
                                                                                                                                                style={{
                                                                                                                                                    width: "100%",
                                                                                                                                                    height: "47px",
                                                                                                                                                }}

                                                                                                                                                filter
                                                                                                                                                className="dialog-form-input-field-wizard"
                                                                                                                                                required
                                                                                                                                            />
                                                                                                                                        )}
                                                                                                                                    />
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-6 justify-content-start align-items-center mt-3">
                                                                                                                        {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                        </svg>
                                                                                                                        <div className='font-medium'>Add Additional Debtor</div>
                                                                                                                    </div>

                                                                                                                </button> */}
                                                                                                                        <Button
                                                                                                                            type="button"
                                                                                                                            icon="pi pi-plus"
                                                                                                                            style={{ fontSize: "14px" }}
                                                                                                                            label="Add Additional Debtor"
                                                                                                                            className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                                            onClick={() => handleKeyPersonal("DEBTOR")}
                                                                                                                        ></Button>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                                {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                    <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                        {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) : ""}
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                        </div>


                                                                                                        <div className="flex gap-2 justify-content-center w-full">
                                                                                                            <Button type="button" icon="pi pi-plus" label="Add Entry" className="vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => handleRepeatForm()}></Button>
                                                                                                            <Button type="button" icon="pi pi-minus" disabled={formCount === 1} label="Remove Entry" className="vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => handleRemoveRepeatForm()}></Button>


                                                                                                        </div>
                                                                                                    </>


                                                                                                )) : ""

                                                                                            }

                                                                                            {

                                                                                                (getValues("uccAmend_What_are_we_amending") === "ADDITIONAL SECURED") && value.apiId === "ucc-amend-additional-secured-party-details" && value.apiId !== "uucAmnd-What-are-amending" ? Array.from({ length: formCount }).map((_, l) => (

                                                                                                    <>
                                                                                                        <div className="vstate-card p-4 mb-3">

                                                                                                            <div className="grid" key={j}>

                                                                                                                <div className="col-12 pb-0">
                                                                                                                    <h2 className="entity-header-strapi">{toPascalCase(value.schema.displayName)}</h2>

                                                                                                                </div>
                                                                                                                <div className="grid w-full justify-content-between">
                                                                                                                    <div className="col-6">
                                                                                                                        <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                                            <div className="field">
                                                                                                                                <label
                                                                                                                                    htmlFor=""
                                                                                                                                    className="form-label font-fam-for-all"
                                                                                                                                >
                                                                                                                                    Select Additional SecuredParty
                                                                                                                                    <span className="form-field-mandatory">
                                                                                                                                        *
                                                                                                                                    </span>
                                                                                                                                </label>
                                                                                                                                <span className="p-float-label ">
                                                                                                                                    <Controller
                                                                                                                                        name={`uccAmend_Additional_secured_party_Details-${l}`}
                                                                                                                                        control={control}
                                                                                                                                        render={({ field }) => (
                                                                                                                                            <Dropdown

                                                                                                                                                value={field.value}
                                                                                                                                                options={allSecuredParty}

                                                                                                                                                onChange={(e) => {
                                                                                                                                                    field.onChange(e.value);

                                                                                                                                                    handleAdditionalSecuredPartyChange(e.value, l);
                                                                                                                                                }}
                                                                                                                                                optionLabel="keyPersonnelName"
                                                                                                                                                placeholder="Select Secured Party"
                                                                                                                                                style={{
                                                                                                                                                    width: "100%",
                                                                                                                                                    height: "47px",
                                                                                                                                                }}

                                                                                                                                                filter
                                                                                                                                                className="dialog-form-input-field-wizard"
                                                                                                                                                required
                                                                                                                                            />
                                                                                                                                        )}
                                                                                                                                    />
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col-6 justify-content-start align-items-center mt-3">
                                                                                                                        {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("SECUERED_PARTY")}>
                                                                                                                    <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                            <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                        </svg>
                                                                                                                        <div className='font-medium'>Add Additional Secured Party</div>
                                                                                                                    </div>

                                                                                                                </button> */}
                                                                                                                        <Button
                                                                                                                            type="button"
                                                                                                                            icon="pi pi-plus"
                                                                                                                            style={{ fontSize: "14px" }}
                                                                                                                            label="Add Additional Secured Party"
                                                                                                                            className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                                            onClick={() => handleKeyPersonal("SECUERED PARTY")}
                                                                                                                        ></Button>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                                {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                    <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                        {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, `${val}-${l}`, value, key) : ""}
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                            </div>
                                                                                                        </div>


                                                                                                        <div className="flex gap-2 justify-content-center w-full">
                                                                                                            <Button type="button" icon="pi pi-plus" label="Add Entry" className="vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => handleRepeatForm()}></Button>
                                                                                                            <Button type="button" icon="pi pi-minus" disabled={formCount === 1} label="Remove Entry" className="vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => handleRemoveRepeatForm()}></Button>


                                                                                                        </div>
                                                                                                    </>


                                                                                                )) : ""

                                                                                            }

                                                                                            {

                                                                                                (getValues("uccAmend_What_are_we_amending") === "ASSIGNMENT") && value.apiId === "ucc-assignee-details" && value.apiId !== "uucAmnd-What-are-amending" ?
                                                                                                    <div className="vstate-card p-4 mb-3">


                                                                                                        <div className="grid " key={j}>

                                                                                                            <div className="col-12 pb-0">
                                                                                                                <h2 className="entity-header-strapi">{toPascalCase(value.schema.displayName)}</h2>

                                                                                                            </div>
                                                                                                            <div className="grid w-full justify-content-between">
                                                                                                                <div className="col-6">
                                                                                                                    <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                                        <div className="field">
                                                                                                                            <label
                                                                                                                                htmlFor=""
                                                                                                                                className="form-label font-fam-for-all"
                                                                                                                            >
                                                                                                                                Select Assignee
                                                                                                                                <span className="form-field-mandatory">
                                                                                                                                    *
                                                                                                                                </span>
                                                                                                                            </label>
                                                                                                                            <span className="p-float-label ">
                                                                                                                                <Controller
                                                                                                                                    name={`uccAmend_Additional_Debtor_Details`}
                                                                                                                                    control={control}
                                                                                                                                    render={({ field }) => (
                                                                                                                                        <Dropdown

                                                                                                                                            value={assigneeData}
                                                                                                                                            options={allAssignee}

                                                                                                                                            onChange={(e) => {

                                                                                                                                                setAssigneeData(e.value)

                                                                                                                                            }}
                                                                                                                                            optionLabel="keyPersonnelName"
                                                                                                                                            placeholder="Select Assignee"
                                                                                                                                            style={{
                                                                                                                                                width: "100%",
                                                                                                                                                height: "47px",
                                                                                                                                            }}

                                                                                                                                            filter
                                                                                                                                            className="dialog-form-input-field-wizard"
                                                                                                                                            required
                                                                                                                                        />
                                                                                                                                    )}
                                                                                                                                />
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="col-6 justify-content-start align-items-center mt-3">
                                                                                                                    {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("ASSIGNEE")}>
                                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                    </svg>
                                                                                                                    <div className='font-medium'>Add Assignee</div>
                                                                                                                </div>

                                                                                                            </button> */}
                                                                                                                    <Button
                                                                                                                        type="button"
                                                                                                                        icon="pi pi-plus"
                                                                                                                        style={{ fontSize: "14px" }}
                                                                                                                        label="Add Assignee"
                                                                                                                        className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                                        onClick={() => handleKeyPersonal("ASSIGNEE")}
                                                                                                                    ></Button>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                    {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, val, value, key) : ""}
                                                                                                                </div>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    </div> : ""

                                                                                            }
                                                                                            {
                                                                                                getValues("COC_What_Are_We_Changing") === "Address" && value.apiId === "address" ?
                                                                                                    <>
                                                                                                        <Sidebar position='right' visible={sidebarVisible} className='dialog-box' onHide={() => setSidebarVisible(false)} >
                                                                                                            <div className="vstate-card p-4 mb-3">


                                                                                                                <div className="grid custom-sidebar-header" key={j}>

                                                                                                                    <div className="col-12 pb-0">
                                                                                                                        <h2 className="entity-header-strapi">{toPascalCase(value.schema.displayName)}</h2>

                                                                                                                    </div>
                                                                                                                    {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                        <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                            {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, val, value, key) : ""}
                                                                                                                        </div>
                                                                                                                    ))}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className='flex justify-content-end dialog-form-md-group-Btn my-5'>
                                                                                                                <Button className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Submit' style={{ height: "40px" }} onClick={() => setSidebarVisible(false)} ></Button>
                                                                                                            </div>
                                                                                                        </Sidebar>
                                                                                                    </> : ""

                                                                                            }

                                                                                            {
                                                                                                getValues("COC_What_Are_We_Changing") === "Registered Agent" && value.apiId === "registered-agent" ?
                                                                                                    <>
                                                                                                        <Sidebar position='right' visible={sidebarVisible} className='dialog-box' onHide={() => setSidebarVisible(false)} >
                                                                                                            <div className="vstate-card p-4 mb-3">


                                                                                                                <div className="grid custom-sidebar-header" key={j}>

                                                                                                                    <div className="col-12 pb-0">
                                                                                                                        <h2 className="entity-header-strapi">{toPascalCase(value.schema.displayName)}</h2>

                                                                                                                    </div>
                                                                                                                    {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                        <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                            {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, val, value, key) : ""}
                                                                                                                        </div>
                                                                                                                    ))}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className='flex justify-content-end dialog-form-md-group-Btn my-5'>
                                                                                                                <Button className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Submit' style={{ height: "40px" }} onClick={() => setSidebarVisible(false)} ></Button>
                                                                                                            </div>
                                                                                                        </Sidebar>
                                                                                                    </> : ""

                                                                                            }

                                                                                            {

                                                                                                value.apiId !== "address" && value.apiId !== "ucc-amnd-additional-debtor" && value.apiId !== "ucc-amend-additional-secured-party-details" && value.apiId !== "ucc-assignee-details" ?
                                                                                                    <div className="vstate-card p-4 mb-3">


                                                                                                        <div className="grid " key={j}>

                                                                                                            <div className="col-12 pb-0">
                                                                                                                <h2 className="entity-header-strapi">{toPascalCase(value.schema.displayName)}</h2>

                                                                                                            </div>
                                                                                                            {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                    {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, val, value, key) : ""}
                                                                                                                </div>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    </div> : ""

                                                                                            }

                                                                                            {

                                                                                                value.apiId === "address" ?
                                                                                                    <div className="vstate-card p-4 mb-3">


                                                                                                        <div className="grid " key={j}>

                                                                                                            <div className="col-12 pb-0">
                                                                                                                <h2 className="entity-header-strapi">{toPascalCase(value.schema.displayName)}</h2>

                                                                                                            </div>
                                                                                                            {Object.keys(value.schema.attributes).map((val, k) => (
                                                                                                                <div className={`col-6 dummy ${!renderFormField(value.schema.attributes[val], val, `${val}`, value, key) ? 'hidden' : ''}`} key={k}>
                                                                                                                    {value.schema.attributes[val].type !== undefined ? renderFormField(value.schema.attributes[val], val, val, value, key) : ""}
                                                                                                                </div>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    </div> : ""

                                                                                            }

                                                                                        </>
                                                                                    ))}</>
                                                                        }
                                                                    </div>
                                                                ))}

                                                                {inputFormData.length > 0 ? (
                                                                    <>


                                                                        <div className="tab">
                                                                            <div
                                                                                className="wizard-content-sub-card-header mb-3"
                                                                                id="header"
                                                                            >
                                                                                <div className="wizard-content-sub-card-text-frame">
                                                                                    <div className="wizard-content-sub-card-text">
                                                                                        Contact
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizard-checkoust-container hover-card slide-in">
                                                                                <div className="wizard-checkout-main">
                                                                                    <div className="grid px-4  w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Billing Address Contact
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className="p-float-label ">
                                                                                                        <Controller
                                                                                                            name="incorporator"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={bilingAddress}
                                                                                                                    options={allBilingAddress}
                                                                                                                    onChange={(e) => setBilingAddress(e.value)}
                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                    placeholder="Select Billing Address"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex" >
                                                                                            {/* <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("BILLING")} >
                                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg>
                                                                                                    <div className='font-medium'>Add Billing Address</div>
                                                                                                </div>

                                                                                            </button> */}
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Billing Address"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => handleKeyPersonal("BILLING")}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="wizard-checkout-body">
                                                                                        <AddressTemplate data={bilingAddress} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div
                                                                                className="wizard-content-sub-card-header mb-3 mt-3"
                                                                                id="header"
                                                                            >
                                                                                <div className="wizard-content-sub-card-text-frame">
                                                                                    <div className="wizard-content-sub-card-text">
                                                                                        Shipping Address Contact
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wizard-checkoust-container hover-card slide-in">
                                                                                <div className="wizard-checkout-main">
                                                                                    <div className='grid justify-content-start ml-4 mt-4 mb-3'>
                                                                                        <div className=' p-0'>
                                                                                            <div className='flex justify-content-center'>
                                                                                                <Checkbox
                                                                                                    checked={IsCheckedAgent1}
                                                                                                    onChange={(e) => {
                                                                                                        setIsCheckedAgent1(e.checked)
                                                                                                    }}
                                                                                                    inputProps={{ 'aria-label': 'controlled' }} />

                                                                                                <label htmlFor="binary" className='font-fam-for-all ml-2 srch-cand-text' >Same as Billing address
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="grid px-4  w-full justify-content-between">
                                                                                        <div className="col-6">
                                                                                            <div className=" justify-content-center dialog-form-field-space w-full">
                                                                                                <div className="field">
                                                                                                    <label
                                                                                                        htmlFor=""
                                                                                                        className="form-label font-fam-for-all"
                                                                                                    >
                                                                                                        Shipping Address
                                                                                                        <span className="form-field-mandatory">
                                                                                                            *
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className="p-float-label ">
                                                                                                        <Controller
                                                                                                            name="incorporator"
                                                                                                            control={control}
                                                                                                            render={({ field }) => (
                                                                                                                <Dropdown

                                                                                                                    value={shippingAddress}
                                                                                                                    options={allShippingAddress}
                                                                                                                    onChange={(e) => setShippingAddress(e.value)}
                                                                                                                    disabled={IsCheckedAgent1}
                                                                                                                    optionLabel="keyPersonnelName"
                                                                                                                    placeholder="Select Billing Address"
                                                                                                                    style={{
                                                                                                                        width: "100%",
                                                                                                                        height: "47px",
                                                                                                                    }}

                                                                                                                    filter
                                                                                                                    className="dialog-form-input-field-wizard"
                                                                                                                    required
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-6 justify-content-start align-items-center flex" >
                                                                                   
                                                                                            <Button
                                                                                                type="button"
                                                                                                icon="pi pi-plus"
                                                                                                style={{ fontSize: "14px" }}
                                                                                                label="Add Shipping Address"
                                                                                                className=" vstate-button font-fam-for-all text-center mt-4"
                                                                                                onClick={() => handleKeyPersonal("SHIPPING")}
                                                                                            ></Button>
                                                                                        </div>

                                                                                    </div>

                                                                                    <div className="wizard-checkout-body">
                                                                                        {IsCheckedAgent1 ? <AddressTemplate data={bilingAddress} /> : <AddressTemplate data={shippingAddress} />}
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                        </div>

                                                                        <div class="tab">
                                                                            <div
                                                                                className="wizard-content-sub-card-header mb-3"
                                                                                id="header"
                                                                            >
                                                                                <div className="wizard-content-sub-card-text-frame">
                                                                                    <div className="wizard-content-sub-card-text">
                                                                                        Preview
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="flex p-4 justify-between font-fam-for-all">
                                                                                <div className="datatable-highlight-color">
                                                                                    {enityName ? enityName.entityFullDesc : ""}
                                                                                </div>
                                                                                <div className="datatable-highlight-color">
                                                                                    {state ? state.stateFullDesc : ""}
                                                                                </div>
                                                                            </div>

                                                                            {previewData ? <div className="min-h-screen bg-gray-50  flex justify-center items-start">
                                                                                <div className="w-full max-w-5xl ">
                                                                                    <div className="bg-white py-2">
                                                                                        <div className="space-y-8">
                                                                                            <div className="bg-white">

                                                                                                <div className="px-4 py-2 rounded-t-lg" style={{ backgroundColor: '#F9FAFB', fontFamily: 'Inter', fontSize: '16px' }}>
                                                                                                    <h2 className="text-lg  text-gray-800">Selected Services</h2>
                                                                                                </div>

                                                                                                <div className="p-4 space-y-4">
                                                                                                    {selectedCheckBoxData.map((service, index) => (
                                                                                                        <div key={index} className="border grid border-gray-200 rounded-md p-2">
                                                                                                            <div className="col-10">
                                                                                                                <h3 className="text-md font-semibold" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {service.product.displayName}
                                                                                                                </h3>
                                                                                                                <p className="text-gray-700 mt-2" style={{ fontFamily: 'Inter', fontSize: '12px' }}>
                                                                                                                    {/* {service.product.productMaster.additionalInfo} */}
                                                                                                                </p>
                                                                                                            </div>
                                                                                                            <div className="col-2">
                                                                                                                <div className="text-right  font-bold text-blue-800" style={{ fontFamily: 'Inter', fontSize: '14px' }}>
                                                                                                                    {service.product.unitPrice === 0 ? "Included" : `$${service.product.unitPrice}`}
                                                                                                                </div>
                                                                                                            </div>


                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </div>

                                                                                            {Object.keys(previewData).map((sectionKey, index) => (

                                                                                                <>
                                                                                                    {!(keyPersonalMemberData.includes(sectionKey)) ? <>
                                                                                                        <div key={index} className="bg-white">

                                                                                                            <div
                                                                                                                className="px-4 py-2"
                                                                                                                style={{ backgroundColor: '#F9FAFB', fontFamily: 'Inter', fontSize: '16px' }}
                                                                                                            >
                                                                                                                <h2 className="text-lg text-gray-800">{convertToTitleCase(sectionKey)}</h2>
                                                                                                            </div>


                                                                                                            <div className="px-4 py-4">

                                                                                                                {Array.isArray(previewData[sectionKey]) ? (

                                                                                                                    previewData[sectionKey].map((item, idx) => {
                                                                                                                        const primitiveKeys = [];
                                                                                                                        const objectKeys = [];
                                                                                                                        const arrayKeys = [];


                                                                                                                        Object.keys(item).forEach((itemKey) => {
                                                                                                                            if (itemKey !== '__temp_key__') {
                                                                                                                                if (typeof item[itemKey] === 'object' && item[itemKey] !== null) {
                                                                                                                                    if (Array.isArray(item[itemKey])) {
                                                                                                                                        arrayKeys.push(itemKey);
                                                                                                                                    } else {
                                                                                                                                        objectKeys.push(itemKey);
                                                                                                                                    }
                                                                                                                                } else {
                                                                                                                                    primitiveKeys.push(itemKey);
                                                                                                                                }
                                                                                                                            }
                                                                                                                        });

                                                                                                                        return (
                                                                                                                            <div key={idx} className="">
                                                                                                                                <h3 className="text-lg text-gray-800 mb-2">
                                                                                                                                    {convertToTitleCase(sectionKey)} {idx + 1}
                                                                                                                                </h3>


                                                                                                                                {primitiveKeys.map((itemKey) => (
                                                                                                                                    <div className="grid gap-3 mb-2" key={itemKey}>
                                                                                                                                        <div className="col-2 order-preview-key">{convertName(itemKey)}</div>
                                                                                                                                        <div className="col-6 order-preview-value">{item[itemKey]}</div>
                                                                                                                                    </div>
                                                                                                                                ))}

                                                                                                                                <div className="grid preview-card p-4 mb-2">
                                                                                                                                    {objectKeys.map((itemKey) => (
                                                                                                                                        <>
                                                                                                                                            <div className="col-3">
                                                                                                                                                <div className="order-preview-key">{convertName(itemKey)}</div>
                                                                                                                                                <div className="order-preview-value">
                                                                                                                                                    {Object.keys(item[itemKey]).map((subKey) => (
                                                                                                                                                        <div key={subKey}>
                                                                                                                                                            <strong>{convertName(subKey)}:</strong> {item[itemKey][subKey]}
                                                                                                                                                        </div>
                                                                                                                                                    ))}
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="col-3"></div>
                                                                                                                                        </>


                                                                                                                                    ))}
                                                                                                                                </div>

                                                                                                                                <div className="grid preview-card p-4 mb-2" >
                                                                                                                                    {arrayKeys.map((itemKey) => (
                                                                                                                                        <>
                                                                                                                                            <div className="col-3">
                                                                                                                                                <div className="order-preview-key">{convertName(itemKey)}</div>
                                                                                                                                                <div className="order-preview-value">
                                                                                                                                                    {item[itemKey].map((subItem, subIdx) => (
                                                                                                                                                        <div key={subIdx} className="ml-4">
                                                                                                                                                            {typeof subItem === 'object' ? (
                                                                                                                                                                Object.keys(subItem).map((subKey) => (
                                                                                                                                                                    <div key={subKey}>
                                                                                                                                                                        <strong>{convertName(subKey)}:</strong> {subItem[subKey]}
                                                                                                                                                                    </div>
                                                                                                                                                                ))
                                                                                                                                                            ) : (
                                                                                                                                                                <div>{subItem}</div>
                                                                                                                                                            )}
                                                                                                                                                        </div>
                                                                                                                                                    ))}
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                            <div className="col-3">

                                                                                                                                            </div>
                                                                                                                                        </>
                                                                                                                                    ))}
                                                                                                                                </div>


                                                                                                                                <div className="border-t border-gray-300 my-4"></div>
                                                                                                                            </div>
                                                                                                                        );
                                                                                                                    })
                                                                                                                ) : previewData[sectionKey] !== null && typeof previewData[sectionKey] === 'object' ? (

                                                                                                                    Object.keys(previewData[sectionKey]).map((key, i) => (
                                                                                                                        <div key={i} className="">
                                                                                                                            {Array.isArray(previewData[sectionKey][key]) ? (
                                                                                                                                <div>
                                                                                                                                    <h3 className="text-lg text-gray-800 mb-2">{convertToTitleCase(key)}</h3>

                                                                                                                                    {/* Iterate over the array elements */}
                                                                                                                                    {previewData[sectionKey][key].map((arrayItem, arrayIndex) => (
                                                                                                                                        <div key={arrayIndex} className="mb-2">
                                                                                                                                            {typeof arrayItem === 'object' ? (
                                                                                                                                                <div>
                                                                                                                                                    {/* If the array contains objects, iterate over the keys of the object */}
                                                                                                                                                    <div className="grid preview-card p-4 mb-2">
                                                                                                                                                        {Object.keys(arrayItem).map((nestedKey) => (
                                                                                                                                                            <>
                                                                                                                                                                <div className="col-3">
                                                                                                                                                                    <div className="order-preview-key">
                                                                                                                                                                        {convertName(nestedKey)}
                                                                                                                                                                    </div>
                                                                                                                                                                    <div className="order-preview-value">
                                                                                                                                                                        {arrayItem[nestedKey]}
                                                                                                                                                                    </div>
                                                                                                                                                                </div>
                                                                                                                                                                <div className="col-3"></div>
                                                                                                                                                            </>


                                                                                                                                                        ))}
                                                                                                                                                    </div>

                                                                                                                                                    {/* Divider for Array Item */}
                                                                                                                                                    <div className="border-t border-gray-300 my-4"></div>
                                                                                                                                                </div>
                                                                                                                                            ) : (
                                                                                                                                                <div className="grid gap-3 mb-2">
                                                                                                                                                    {/* If the array contains non-object values, display them directly */}
                                                                                                                                                    <div className="col-2 order-preview-key">
                                                                                                                                                        {`Item ${arrayIndex + 1}`}
                                                                                                                                                    </div>
                                                                                                                                                    <div className="col-6 order-preview-value">
                                                                                                                                                        {arrayItem}
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                            )}
                                                                                                                                        </div>
                                                                                                                                    ))}

                                                                                                                                    {/* Divider for Array Data */}
                                                                                                                                    <div className="border-t border-gray-300 my-4"></div>
                                                                                                                                </div>
                                                                                                                            ) : previewData[sectionKey][key] !== null && typeof previewData[sectionKey][key] === 'object' ? (
                                                                                                                                <div>
                                                                                                                                    <h3 className="text-lg text-gray-800 mb-2">{convertToTitleCase(key)}</h3>
                                                                                                                                    <div className="grid preview-card p-4 mb-2">
                                                                                                                                        {Object.keys(previewData[sectionKey][key]).map((nestedKey) => (
                                                                                                                                            <>
                                                                                                                                                <div className="col-3">
                                                                                                                                                    <div className="order-preview-key">
                                                                                                                                                        {convertName(nestedKey)}
                                                                                                                                                    </div>
                                                                                                                                                    <div className="order-preview-value">
                                                                                                                                                        {previewData[sectionKey][key][nestedKey]}
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                <div className="col-3"></div>
                                                                                                                                            </>

                                                                                                                                        ))}
                                                                                                                                    </div>

                                                                                                                                    {/* Divider for Nested Data */}
                                                                                                                                    <div className="border-t border-gray-300 my-4"></div>
                                                                                                                                </div>
                                                                                                                            ) : (
                                                                                                                                <div className="grid gap-3 mb-2">
                                                                                                                                    {/* Key-Value Pair for Flat Object */}
                                                                                                                                    <div className="col-2 order-preview-key">
                                                                                                                                        {convertName(key)}
                                                                                                                                    </div>
                                                                                                                                    <div className="col-6 order-preview-value">
                                                                                                                                        {previewData[sectionKey][key]}
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                    ))
                                                                                                                ) : (
                                                                                                                    // Single Key-Value Pair if the data is neither object nor array
                                                                                                                    <div className="grid gap-3 mb-2">
                                                                                                                        <div className="col-2 order-preview-key">
                                                                                                                            {convertName(sectionKey)}
                                                                                                                        </div>
                                                                                                                        <div className="col-6 order-preview-value">
                                                                                                                            {previewData[sectionKey]}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </> : ""}
                                                                                                </>


                                                                                            ))}

                                                                                            {
                                                                                                (organizerData || incorporator || director || officer || president || secretary || treasurer || filer) &&
                                                                                                <div className="bg-white">

                                                                                                    <div className="px-4 py-2" style={{ backgroundColor: '#F9FAFB', fontFamily: 'Inter', fontSize: '16px' }}>
                                                                                                        <h2 className="text-lg  text-gray-800">Contacts</h2>
                                                                                                    </div>

                                                                                                    <div className="p-4 ">
                                                                                                        <div className='grid gap-2 justify-content-start'>
                                                                                                            {
                                                                                                                organizerData ? <div className='col-5'>

                                                                                                                    <div className='order-contact-card-container'>
                                                                                                                        <div className='order-contact-card-main'>
                                                                                                                            <div className='order-contact-card-header-main'>

                                                                                                                                <Avatar label={getInitials(organizerData.keyPersonnelName)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                                                                <div>
                                                                                                                                    <div className='order-contact-card-header-text1'>
                                                                                                                                        {organizerData.keyPersonnelName}
                                                                                                                                    </div>
                                                                                                                                    <div className='order-contact-card-header-text2'>
                                                                                                                                        Organizer
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                            <div className='card-devider'> </div>
                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect width="20" height="20" fill="white" />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {organizerData.contactNo}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {organizerData.emailId}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1 text-wrap'>
                                                                                                                                    {organizerData.addressMasterId.addressLine1 !== "" ? `${organizerData.addressMasterId.addressLine1},${organizerData.addressMasterId.addressLine2 ? `${organizerData.addressMasterId.addressLine2},` : ""}${organizerData.addressMasterId.city},${getState(organizerData.addressMasterId.stateId)},${organizerData.addressMasterId.postalCode}` : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""
                                                                                                            }

                                                                                                            {
                                                                                                                incorporator ? <div className='col-5'>

                                                                                                                    <div className='order-contact-card-container'>
                                                                                                                        <div className='order-contact-card-main'>
                                                                                                                            <div className='order-contact-card-header-main'>

                                                                                                                                <Avatar label={getInitials(incorporator.keyPersonnelName)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                                                                <div>
                                                                                                                                    <div className='order-contact-card-header-text1'>
                                                                                                                                        {incorporator.keyPersonnelName}
                                                                                                                                    </div>
                                                                                                                                    <div className='order-contact-card-header-text2'>
                                                                                                                                        Incorporator
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                            <div className='card-devider'> </div>
                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect width="20" height="20" fill="white" />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {incorporator.contactNo}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {incorporator.emailId}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1 text-wrap'>
                                                                                                                                    {incorporator.addressMasterId.addressLine1 !== "" ? `${incorporator.addressMasterId.addressLine1},${incorporator.addressMasterId.addressLine2 ? `${incorporator.addressMasterId.addressLine2},` : ""}${incorporator.addressMasterId.city},${getState(incorporator.addressMasterId.stateId)},${incorporator.addressMasterId.postalCode}` : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""
                                                                                                            }

                                                                                                            {
                                                                                                                officer ? <div className='col-5'>

                                                                                                                    <div className='order-contact-card-container'>
                                                                                                                        <div className='order-contact-card-main'>
                                                                                                                            <div className='order-contact-card-header-main'>

                                                                                                                                <Avatar label={getInitials(officer.keyPersonnelName)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                                                                <div>
                                                                                                                                    <div className='order-contact-card-header-text1'>
                                                                                                                                        {officer.keyPersonnelName}
                                                                                                                                    </div>
                                                                                                                                    <div className='order-contact-card-header-text2'>
                                                                                                                                        Officer
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                            <div className='card-devider'> </div>
                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect width="20" height="20" fill="white" />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {officer.contactNo}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {officer.emailId}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1 text-wrap'>
                                                                                                                                    {officer.addressMasterId.addressLine1 !== "" ? `${officer.addressMasterId.addressLine1},${officer.addressMasterId.addressLine2 ? `${officer.addressMasterId.addressLine2},` : ""}${officer.addressMasterId.city},${getState(officer.addressMasterId.stateId)},${officer.addressMasterId.postalCode}` : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""
                                                                                                            }

                                                                                                            {
                                                                                                                director ? <div className='col-5'>

                                                                                                                    <div className='order-contact-card-container'>
                                                                                                                        <div className='order-contact-card-main'>
                                                                                                                            <div className='order-contact-card-header-main'>

                                                                                                                                <Avatar label={getInitials(director.keyPersonnelName)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                                                                <div>
                                                                                                                                    <div className='order-contact-card-header-text1'>
                                                                                                                                        {director.keyPersonnelName}
                                                                                                                                    </div>
                                                                                                                                    <div className='order-contact-card-header-text2'>
                                                                                                                                        Director
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                            <div className='card-devider'> </div>
                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect width="20" height="20" fill="white" />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {director.contactNo}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {director.emailId}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1 text-wrap'>
                                                                                                                                    {director.addressMasterId.addressLine1 !== "" ? `${director.addressMasterId.addressLine1},${director.addressMasterId.addressLine2 ? `${director.addressMasterId.addressLine2},` : ""}${director.addressMasterId.city},${getState(director.addressMasterId.stateId)},${director.addressMasterId.postalCode}` : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""
                                                                                                            }


                                                                                                            {
                                                                                                                filer ? <div className='col-5'>

                                                                                                                    <div className='order-contact-card-container'>
                                                                                                                        <div className='order-contact-card-main'>
                                                                                                                            <div className='order-contact-card-header-main'>

                                                                                                                                <Avatar label={getInitials(filer.keyPersonnelName)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                                                                <div>
                                                                                                                                    <div className='order-contact-card-header-text1'>
                                                                                                                                        {filer.keyPersonnelName}
                                                                                                                                    </div>
                                                                                                                                    <div className='order-contact-card-header-text2'>
                                                                                                                                        Director
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                            <div className='card-devider'> </div>
                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect width="20" height="20" fill="white" />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {filer.contactNo}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {filer.emailId}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1 text-wrap'>
                                                                                                                                    {filer.addressMasterId.addressLine1 !== "" ? `${filer.addressMasterId.addressLine1},${filer.addressMasterId.addressLine2 ? `${filer.addressMasterId.addressLine2},` : ""}${filer.addressMasterId.city},${getState(filer.addressMasterId.stateId)},${filer.addressMasterId.postalCode}` : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""
                                                                                                            }

                                                                                                            {
                                                                                                                president ? <div className='col-5'>

                                                                                                                    <div className='order-contact-card-container'>
                                                                                                                        <div className='order-contact-card-main'>
                                                                                                                            <div className='order-contact-card-header-main'>

                                                                                                                                <Avatar label={getInitials(president.keyPersonnelName)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                                                                <div>
                                                                                                                                    <div className='order-contact-card-header-text1'>
                                                                                                                                        {president.keyPersonnelName}
                                                                                                                                    </div>
                                                                                                                                    <div className='order-contact-card-header-text2'>
                                                                                                                                        President
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                            <div className='card-devider'> </div>
                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect width="20" height="20" fill="white" />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {president.contactNo}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {president.emailId}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1 text-wrap'>
                                                                                                                                    {president.addressMasterId.addressLine1 !== "" ? `${president.addressMasterId.addressLine1},${president.addressMasterId.addressLine2 ? `${president.addressMasterId.addressLine2},` : ""}${president.addressMasterId.city},${getState(president.addressMasterId.stateId)},${president.addressMasterId.postalCode}` : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""
                                                                                                            }

                                                                                                            {
                                                                                                                treasurer ? <div className='col-5'>

                                                                                                                    <div className='order-contact-card-container'>
                                                                                                                        <div className='order-contact-card-main'>
                                                                                                                            <div className='order-contact-card-header-main'>

                                                                                                                                <Avatar label={getInitials(treasurer.keyPersonnelName)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                                                                <div>
                                                                                                                                    <div className='order-contact-card-header-text1'>
                                                                                                                                        {treasurer.keyPersonnelName}
                                                                                                                                    </div>
                                                                                                                                    <div className='order-contact-card-header-text2'>
                                                                                                                                        Treasurer
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                            <div className='card-devider'> </div>
                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect width="20" height="20" fill="white" />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {treasurer.contactNo}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {treasurer.emailId}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1 text-wrap'>
                                                                                                                                    {treasurer.addressMasterId.addressLine1 !== "" ? `${treasurer.addressMasterId.addressLine1},${treasurer.addressMasterId.addressLine2 ? `${treasurer.addressMasterId.addressLine2},` : ""}${treasurer.addressMasterId.city},${getState(treasurer.addressMasterId.stateId)},${treasurer.addressMasterId.postalCode}` : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""
                                                                                                            }

                                                                                                            {
                                                                                                                secretary ? <div className='col-5'>

                                                                                                                    <div className='order-contact-card-container'>
                                                                                                                        <div className='order-contact-card-main'>
                                                                                                                            <div className='order-contact-card-header-main'>

                                                                                                                                <Avatar label={getInitials(secretary.keyPersonnelName)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                                                                <div>
                                                                                                                                    <div className='order-contact-card-header-text1'>
                                                                                                                                        {secretary.keyPersonnelName}
                                                                                                                                    </div>
                                                                                                                                    <div className='order-contact-card-header-text2'>
                                                                                                                                        Secretary
                                                                                                                                    </div>
                                                                                                                                </div>

                                                                                                                            </div>
                                                                                                                            <div className='card-devider'> </div>
                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                                                                            <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        </g>
                                                                                                                                        <defs>
                                                                                                                                            <clipPath id="clip0_966_10329">
                                                                                                                                                <rect width="20" height="20" fill="white" />
                                                                                                                                            </clipPath>
                                                                                                                                        </defs>
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {secretary.contactNo}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1'>
                                                                                                                                    {secretary.emailId}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div className='order-contact-card-text-main'>
                                                                                                                                <div>
                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                        <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                        <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </svg>
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-text1 text-wrap'>
                                                                                                                                    {secretary.addressMasterId.addressLine1 !== "" ? `${secretary.addressMasterId.addressLine1},${secretary.addressMasterId.addressLine2 ? `${secretary.addressMasterId.addressLine2},` : ""}${secretary.addressMasterId.city},${getState(secretary.addressMasterId.stateId)},${secretary.addressMasterId.postalCode}` : ""}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div> : ""
                                                                                                            }


                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }


                                                                                            <div className="bg-white">

                                                                                                <div className="px-4 py-2" style={{ backgroundColor: '#F9FAFB', fontFamily: 'Inter', fontSize: '16px' }}>
                                                                                                    <h2 className="text-lg  text-gray-800">Biling Contact Address</h2>
                                                                                                </div>

                                                                                                <div className="p-4 ">
                                                                                                    <div className='grid gap-2 justify-content-start'>
                                                                                                        {
                                                                                                            bilingAddress ? <div className='col-5'>

                                                                                                                <div className='order-contact-card-container'>
                                                                                                                    <div className='order-contact-card-main'>
                                                                                                                        <div className='order-contact-card-header-main'>

                                                                                                                            <Avatar label={getInitials(bilingAddress.keyPersonnelName)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                                                            <div>
                                                                                                                                <div className='order-contact-card-header-text1'>
                                                                                                                                    {bilingAddress.keyPersonnelName}
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-header-text2'>
                                                                                                                                    {IsCheckedAgent1 ? "Biling Contact" : "Biling Contact"}
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                        <div className='card-devider'> </div>
                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <g clip-path="url(#clip0_966_10329)">
                                                                                                                                        <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </g>
                                                                                                                                    <defs>
                                                                                                                                        <clipPath id="clip0_966_10329">
                                                                                                                                            <rect width="20" height="20" fill="white" />
                                                                                                                                        </clipPath>
                                                                                                                                    </defs>
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1'>
                                                                                                                                {bilingAddress.contactNo}
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1'>
                                                                                                                                {bilingAddress.emailId}
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1 text-wrap'>
                                                                                                                                {bilingAddress.addressMasterId.addressLine1 !== "" ? `${bilingAddress.addressMasterId.addressLine1},${bilingAddress.addressMasterId.addressLine2 ? `${bilingAddress.addressMasterId.addressLine2},` : ""}${bilingAddress.addressMasterId.city},${getState(bilingAddress.addressMasterId.stateId)},${bilingAddress.addressMasterId.postalCode}` : ""}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""
                                                                                                        }

                                                                                                        {
                                                                                                            shippingAddress ? <div className='col-5'>

                                                                                                                <div className='order-contact-card-container'>
                                                                                                                    <div className='order-contact-card-main'>
                                                                                                                        <div className='order-contact-card-header-main'>

                                                                                                                            <Avatar label={getInitials(shippingAddress.keyPersonnelName)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                                                            <div>
                                                                                                                                <div className='order-contact-card-header-text1'>
                                                                                                                                    {shippingAddress.keyPersonnelName}
                                                                                                                                </div>
                                                                                                                                <div className='order-contact-card-header-text2'>
                                                                                                                                    Shipping Contact
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                        <div className='card-devider'> </div>
                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <g clip-path="url(#clip0_966_10329)">
                                                                                                                                        <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    </g>
                                                                                                                                    <defs>
                                                                                                                                        <clipPath id="clip0_966_10329">
                                                                                                                                            <rect width="20" height="20" fill="white" />
                                                                                                                                        </clipPath>
                                                                                                                                    </defs>
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1'>
                                                                                                                                {shippingAddress.contactNo}
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1'>
                                                                                                                                {shippingAddress.emailId}
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                        <div className='order-contact-card-text-main'>
                                                                                                                            <div>
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                                                                    <path d="M17.5 8.33337C17.5 14.1667 10 19.1667 10 19.1667C10 19.1667 2.5 14.1667 2.5 8.33337C2.5 6.34425 3.29018 4.4366 4.6967 3.03007C6.10322 1.62355 8.01088 0.833374 10 0.833374C11.9891 0.833374 13.8968 1.62355 15.3033 3.03007C16.7098 4.4366 17.5 6.34425 17.5 8.33337Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                    <path d="M10 10.8334C11.3807 10.8334 12.5 9.71409 12.5 8.33337C12.5 6.95266 11.3807 5.83337 10 5.83337C8.61929 5.83337 7.5 6.95266 7.5 8.33337C7.5 9.71409 8.61929 10.8334 10 10.8334Z" stroke="#175CD3" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className='order-contact-card-text1 text-wrap'>
                                                                                                                                {shippingAddress.addressMasterId.addressLine1 !== "" ? `${shippingAddress.addressMasterId.addressLine1},${shippingAddress.addressMasterId.addressLine2 ? `${shippingAddress.addressMasterId.addressLine2},` : ""}${shippingAddress.addressMasterId.city},${getState(shippingAddress.addressMasterId.stateId)},${shippingAddress.addressMasterId.postalCode}` : ""}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div> : ""
                                                                                                        }


                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            {
                                                                                                (isPaymentDone || strapiId) ? "" : <div className="px-4">
                                                                                                    <PaymentOptions />

                                                                                                </div>
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> : <>
                                                                                <div>Data not found</div>
                                                                            </>}

                                                                            {/* <div className='flex justify-content-end pl-3 dialog-form-md-group-Btn'>

                                                                                <Button type='button' className="mt-2 vstate-button font-fam-for-all text-center   justify-content-center" onClick={() => setVisible(true)} >Preview</Button>

                                                                            </div> */}
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    ""
                                                                )}

                                                                <div className='flex flex-col justify-content-end gap-2 px-4' >
                                                                    {/* {
                                                                        key === "Registered_Agent" ? <Button type='button' id="nextBtn" className="mt-2   vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => nextPrev(1)} >Next</Button>
                                                                            : ""
                                                                    } */}
                                                                    <Button type='button' id="nextBtn" className="mt-2   vstate-button font-fam-for-all text-center  justify-content-center" onClick={() => nextPrev(1)} >Next</Button>
                                                                    <Button type='button' id="submitBtn" className="mt-2   vstate-button font-fam-for-all text-center justify-content-center"
                                                                        onClick={handleSubmit(onSubmit)}

                                                                    >{isPaymentDone ? "Submit" : "Checkout"}</Button>
                                                                    <Button type='button' id="prevBtn" onClick={() => nextPrev(-1)} className="mt-2 vstate-reverse-button   font-fam-for-all text-center justify-content-center"   >Back</Button>


                                                                </div>


                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 flex flex-column gap-2">
                            <div className=" font-fam-for-all">

                                <div className="wizard-checkout-container">
                                    <div className="wizard-checkout-main">
                                        <div className="wizard-checkout-header justify-content-between">
                                            <div className="wizard-checkout-text-main">
                                                <div className="wizard-checkout-text">
                                                    CART SUMMARY
                                                </div>
                                            </div>

                                        </div>



                                        <div className="wizard-checkout-body">
                                            <React.Fragment>
                                                <div className="wizard-checkout-content-main">
                                                    {selectedCheckBoxData.length > 0 ? selectedCheckBoxData.sort((a, b) => a.product.displayPreference - b.product.displayPreference).map((val, index) => {
                                                        const baseProduct = val.product.baseProduct;
                                                        const isPriceExcluded = val.product.isPriceIncludedInBaseProduct;
                                                        let productPrice = val.product.unitPrice;

                                                        // Handle bundle pricing
                                                        // if (val.isBundlePriceApplicable) {
                                                        //     const additionalItems = Math.max(0, selectedQuantity - val.noOfItemsInBundle);
                                                        //     productPrice = (val.priceOfBundle * val.noOfItemsInBundle) +
                                                        //         (additionalItems * val.priceForAddlItemsOverBundle);
                                                        // }

                                                        return (
                                                            < >

                                                                {!baseProduct && (
                                                                    <div className="wizard-checkout-content-price-main">
                                                                        <div className="wizard-price-content1">
                                                                            {val.product.displayName}
                                                                        </div>
                                                                        <div className="wizard-price-content2">
                                                                            {!isPriceExcluded ? `$${productPrice}` : "Included"}
                                                                        </div>
                                                                    </div>
                                                                )}


                                                                {baseProduct && (
                                                                    <div className="wizard-checkout-content-price-main ml-4">
                                                                        <div className="wizard-price-content1">
                                                                            {val.product.displayName}
                                                                        </div>
                                                                        <div className="wizard-price-content2">
                                                                            {!isPriceExcluded ? `$${productPrice}` : `Included under ${baseProduct.displayName}`}
                                                                        </div>
                                                                    </div>
                                                                    // <div className="ml-4 text-muted">
                                                                    //     Included under {baseProduct.displayName}
                                                                    // </div>
                                                                )}
                                                            </>
                                                        );
                                                    }) : (
                                                        <div className="wizard-price-content1 flex justify-content-center">No items added</div>
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        </div>

                                        <div className="wizard-checkout-footer">
                                            <div className="wizard-checkout-text-footer">
                                                <div className="wizard-checkout-footer-txt">TOTAL (TAX DEDUCTIBLE)</div>
                                            </div>
                                            <div className="wizard-total-price">
                                                {`$${calculateTotalAmount()}`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <AddCompany visible={visible9} setVisible={setVisible9} pageRefresh={pageRefresh} setPageRefresh={setPageRefresh} />


                        <AddCompanyMember visible={visible2} setVisible={setVisible2} pageRefresh={pageRefresh} setPageRefresh={setPageRefresh} memberType={memberType} data={keyPersonalCompany} keyPersonalAddressType={keyPersonalAddressType} />

                        <AddBOIMember visible={visible8} setVisible={setVisible8} pageRefresh={pageRefresh} setPageRefresh={setPageRefresh} memberType={memberType} data={keyPersonalCompany} keyPersonalAddressType={keyPersonalAddressType} />


                        <AddCompanyLocation companyId={globalCompany ? globalCompany.companyDetails : null} visible={visible3} setVisible={setVisible3} />


                        {
                            closeProgresbar2 && (
                                <Dialog className="payment-dialog rounded-lg" visible={closeProgresbar2} onHide={() => setCloseProgresbar2(false)}>
                                    <section className="flex overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                                        <div className="payment-icon-confirm">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" fill="none">
                                                <path d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1" stroke="#039855" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                                            <header className="flex flex-col w-full max-md:max-w-full">
                                                <h2 className="text-lg font-medium leading-loose text-gray-900 max-md:max-w-full">
                                                    Pay Later
                                                </h2>
                                                <p className="mt-2 text-sm leading-5 text-gray-500 w-[500px] text-wrap">
                                                    You have selected the <strong>Pay Later</strong> option. Your order will be received, and upon approval from vState Filings, your order will be submitted.</p>
                                            </header>
                                            <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                                                <div className="flex gap-3 items-start justify-content-end self-stretch my-auto min-w-[240px]">
                                                    <Button className="overflow-hidden gap-2 self-stretch px-5 py-2.5 rounded-lg border border-solid shadow-sm bg-white text-gray-700 border-gray-300" label="Cancel" onClick={() => setCloseProgresbar2(false)} />
                                                    <Button className="overflow-hidden gap-2 self-stretch px-5 py-2.5 rounded-lg border border-solid shadow-sm bg-blue-600 text-white border-blue-600" label="Submit for Approval" onClick={handleConfirm} />

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </Dialog>
                            )
                        }
                        {
                            closeProgresbar3 && (
                                <Dialog className="payment-dialog rounded-lg" visible={closeProgresbar3} onHide={() => setCloseProgresbar3(false)}>
                                    <section className="flex rounded-lg overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                                        <div className="payment-icon-confirm">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" fill="none">
                                                <path d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1" stroke="#039855" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </div>
                                        <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                                            <header className="flex flex-col w-full max-md:max-w-full">
                                                <h2 className="text-lg font-medium leading-loose text-gray-900 max-md:max-w-full">
                                                    Pay Now
                                                </h2>
                                                <p className="mt-2 text-sm leading-5 text-gray-500 w-[500px] text-wrap">
                                                    You selected the <strong>Pay Now</strong> option. Please complete your payment to finalize your order.</p>
                                            </header>
                                            <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                                                <div className="flex justify-content-end gap-3 items-start self-stretch my-auto min-w-[240px]">
                                                    <Button className="vstate-reverse-button" label="Cancel" onClick={() => setCloseProgresbar3(false)} />
                                                    <Button className="vstate-button" label="Proceed to payment" onClick={handleConfirm} />

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </Dialog>
                            )
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditOrderForm;
