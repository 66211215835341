import logo from './logo.svg';
import React, { useEffect, useState } from "react";
import './App.css';
import "primeflex/primeflex.css"
import "primereact/resources/themes/lara-light-indigo/theme.css";



//core
import "primereact/resources/primereact.min.css";

//icons
import "primeicons/primeicons.css";
import { BrowserRouter, Route, Routes, useNavigate, HashRouter } from "react-router-dom";
import City from './components/Product';
import LLC from './components/AddComponents/LLC';
import DemoLLC from './components/AddComponents/demoLLC';
import UserSignUp from './components/UserSignUp';
import SignIn from './components/SignIn';
import LandingPage from './components/AddComponents/LandingPage';
import HomePage from './components/HomePage';
import DataDashboard from './components/Dashboard';
import Subscription from './components/User';
import Contact from './components/Contact';
import Customers from './components/Customers';
import EditForm from './components/EditComponents/EditForm';
import AddForm from './components/AddComponents/AddForm';
import EditorTemplate from './components/EditorTemplate';
import Invoice from './components/Invoice';
import Chatbot from './components/ChatBot';
import Profile from './components/Profile';
import ProfileComp from './components/ProfileComp';
import Client from './components/Client';
import HomeLandingPage from './components/HomeLandingPage';
import Layout from './components/Website/Layout';
import MainContent from './components/Website/MainContent';
import LayoutApp from './components/LayoutApp';
import RolesAndAccess from './components/RolesAndAccess';
import RolesAndPermission from './components/RolesAndPermission';
import RolesMaster from './components/Master/RolesMaster';
import RolesWithPermission from './components/Master/RolesWithPermission';
import RolesWithPermissionChild from './components/Master/RolesWithPermissionChild';
import authContext from './common/authContext';
import ContactDashboard from './components/ContactDashboard';
import ForgotPassword from './components/ForgotPassword';
import ChangePassword from './components/ChangePassword';
import CompanyDashboard from './components/CompanyDashboard';
import extractPermissions from './utils/extractPermission';
import AddOrderForm from './components/AddComponents/AddOrderForm';
import OrderDashboard from './components/OrderDashboard';
import AddOrderDemo from './components/AddComponents/AddOrderDemo';
import ProductCatalog from './utils/productCatalog';
import MasterDashboard from './components/MasterDashboard';
import EmployeeDashboard from './components/EmployeeDashboard';
import AffiliationPartner from './components/AffiliationPartner';
import VendorDashboard from './components/VendorDashBoard';
import VstatePartner from './components/VstatePartner';
import ErrorBoundary from './utils/ErrorBoundary';
import EditOrderForm from './components/EditComponents/EditOrderForm';
import DashboardMain from './components/Dashboard/Dashboard';
import AddServiceForm from './components/AddComponents/AddOnServiceForm';
import MasterDashboardDemo from './components/MasterDashBoardDemo';
import PaymentSuccess from './components/Payment/PaymentSuccess';
import PaymentFailure from './components/Payment/PaymentFailure';
import AddOrderFormService from './components/AddComponents/AddOrderFormService';
import Dashboard from './components/Dashboard/AdminDashboard/Dashboard';
import FormWithConfirmation from './utils/PendingRequest';
import PaymentElement from './utils/PaymentElements';
import WebsiteCompanyMain from './components/Website/WebsiteComapny/WebsiteCompanyMain';
import RegistredAgentMain from './components/Website/RegistredAgentService/RegistredAgentMain';
import ContactSection from './components/Website/ContactSection/ContactSection';
import { AddOtpForm } from './components/AddComponents/AddOtpForm';
import AccountManagerCompanyDashboard from './components/AccountManagerCompanyDashboard';
import AccountManagerContactDashboard from './components/AccountManagerContact';
import AccountManagerOrderDashboard from './components/AccountManagerOrder';
import OrderDashboardMain from './components/OrderDashboardMain';
import OrderDashboardMainDemo from './components/OrderDashboardDemo2';
import ServiceContent from './components/Website/ContentService/MainContent';






function App() {
  const user = JSON.parse(localStorage.getItem('user'));
  const [isEmailExist, setIsEmailExist] = useState(false)
  const [pageRefreshMain, setPageRefreshMain] = useState(false)
  const [permissions, setPermissions] = useState([]);
  const [globalCompany, setGlobalCompany] = useState(null)

  const [isTabRefresh, setIsTabRefresh] = useState(false)

  const [isLoading, setIsLoading] = useState(false);

  const [editPayloadData, setEditPayloadData] = useState(null)

  const [addOnServiceData, setAddOnServiceData] = useState("")

  const [isNameRendered, setIsNameRendered] = useState(false)

  const [entityDash, setEntityDash] = useState(null)

  const [stateDash, setStateDash] = useState(null)

  const [paymentMethod, setPaymentMethod] = useState("Pay now")

  const [loginUserName, setLoginUserName] = useState("")

  const [orderTypeId, setOrderTypeId] = useState(null)

  const [paymentData, setPaymentData] = useState(null)

  const [paymentOrderData, setPaymentOrderData] = useState(null)

  const [signUpPayload,setSignUpPayload]=useState(null)

  const [redirectedData,setRedirectedData]=useState(null)

  const [isReviewDone,setIsReviewDone]=useState(true)

  const [paySessionId,setPaySessionId]=useState(null)

  const [accountManagerId,setAccountManagerId]=useState(null)

  const [addonServiceRedirectedData,setAddOnServiceRedirectedData]=useState(null)

  const [filterOrderStatusId,setFilterStatusId]=useState(null)

  const [fileBase64,setFileBase64]=useState(null)

  

  const clearData=()=>{
    setStateDash(null)
    setEntityDash(null)
    // setRedirectedData(null)
    setOrderTypeId(null)
    // setFilterStatusId(null)
    setPaymentMethod("Pay now")
    // setFileBase64(null)
    setAddOnServiceRedirectedData(null)
    setAddOnServiceData("")
    setEditPayloadData(null)
  }



  useEffect(() => {
    if (user && globalCompany) {
      console.log(globalCompany)
      const userPermissions = extractPermissions(globalCompany);
      console.log(userPermissions)
      setPermissions(userPermissions);
    }

  }, [pageRefreshMain,globalCompany]);


  return (
    <div className="App">
      <authContext.Provider value={{
        isEmailExist: isEmailExist, setIsEmailExist: setIsEmailExist, permissions: permissions, setPermissions: setPermissions,
        pageRefreshMain: pageRefreshMain, setPageRefreshMain: setPageRefreshMain, globalCompany: globalCompany, setGlobalCompany: setGlobalCompany, isLoading: isLoading,
        setIsLoading: setIsLoading, isTabRefresh: isTabRefresh, setIsTabRefresh: setIsTabRefresh, editPayloadData: editPayloadData, setEditPayloadData: setEditPayloadData,
        addOnServiceData: addOnServiceData, setAddOnServiceData: setAddOnServiceData, isNameRendered: isNameRendered, setIsNameRendered: setIsNameRendered,
        stateDash: stateDash, setStateDash: setStateDash, entityDash: entityDash, setEntityDash: setEntityDash, paymentMethod: paymentMethod, setPaymentMethod: setPaymentMethod,
        loginUserName: loginUserName, setLoginUserName: setLoginUserName, orderTypeId: orderTypeId, setOrderTypeId: setOrderTypeId, paymentData: paymentData, setPaymentData: setPaymentData,
        setPaymentOrderData: setPaymentOrderData, paymentOrderData: paymentOrderData,signUpPayload:signUpPayload,setSignUpPayload:setSignUpPayload,redirectedData:redirectedData,
        setRedirectedData:setRedirectedData,isReviewDone:isReviewDone,setIsReviewDone:setIsReviewDone,paySessionId:paySessionId,setPaySessionId:setPaySessionId,
        accountManagerId:accountManagerId,setAccountManagerId:setAccountManagerId,addonServiceRedirectedData:addonServiceRedirectedData,setAddOnServiceRedirectedData:setAddOnServiceRedirectedData,
        filterOrderStatusId:filterOrderStatusId,setFilterStatusId:setFilterStatusId,clearData:clearData,fileBase64:fileBase64,setFileBase64:setFileBase64
      }}>


        <HashRouter basename='/'>
          <ErrorBoundary>
            <Routes>
              <Route path="/demo" element={<City />} />

              <Route path="/signup" element={<UserSignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/landing" element={<LandingPage />} />
              <Route path="/hom" element={<HomePage />} />
              <Route path="/forgotpwd" element={<ForgotPassword />} />
              <Route path="/user" element={<Subscription />} />
              <Route path="/changepwd" element={<ChangePassword />} />
            
           
              <Route path="/template" element={<EditorTemplate />} />
              <Route path="/chat" element={<Chatbot />} />
              <Route path="/partner" element={<VstatePartner />} />
              <Route path="/otp" element={<AddOtpForm />} />




              <Route path="/company" element={<HomeLandingPage />} />


              <Route path="/" element={<Layout />}>
                <Route path="/" element={<MainContent />} />
                <Route path="/webcompany" element={<WebsiteCompanyMain />} />
                <Route path="/registeredcompany" element={<RegistredAgentMain />} />
                <Route path="/webcontact" element={<ContactSection />} />
                <Route path="/service" element={<ServiceContent />} />

              </Route>

              <Route path="/" element={<LayoutApp />}>
                <Route path="/dashboard" element={<DashboardMain />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/client" element={<Client />} />
                <Route path="/profile" element={<ProfileComp />} />
                <Route path="/permission" element={<RolesAndPermission />} />
                <Route path="/roles" element={<RolesMaster />} />
                <Route path="/roleswithpermission" element={<RolesWithPermission />} />
                <Route path="/roleswithpermissionchild" element={<RolesWithPermissionChild />} />
                <Route path="/contactdash" element={<ContactDashboard />} />
                <Route path="/companydash" element={<CompanyDashboard />} />
                <Route path="/llc" element={<DemoLLC />} />
                <Route path="/orderform" element={<AddOrderForm />} />
                <Route path="/edit/orderform" element={<EditOrderForm />} />
                <Route path="/order" element={<OrderDashboardMainDemo />} />
                <Route path="/orderdemo" element={<OrderDashboard />} />
                <Route path="/product" element={<ProductCatalog />} />
                <Route path="/admin" element={<MasterDashboardDemo />} />
                <Route path="/employee" element={<EmployeeDashboard />} />
                <Route path="/vendor" element={<VendorDashboard />} />
                <Route path="/dash" element={<DashboardMain />} />
                <Route path="/addonservice" element={<AddServiceForm />} />
                <Route path="/orderformcgs" element={<AddOrderFormService />} />
                <Route path="/admindash" element={<Dashboard />} />
                <Route path="/pendingreq" element={<FormWithConfirmation />} />
                <Route path="/stripe" element={<PaymentElement />} />
                <Route path="/payment-success" element={<PaymentSuccess />} />
                <Route path="/company/accountmanager" element={<AccountManagerCompanyDashboard />} />
                <Route path="/contact/accountmanager" element={<AccountManagerContactDashboard />} />
                <Route path="/order/accountmanager" element={<AccountManagerOrderDashboard />} />

              </Route>


            </Routes>
          </ErrorBoundary>
        </HashRouter>
      </authContext.Provider>
    </div>
  );
}

export default App;
