import React, { useContext, useEffect } from "react";
import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { Message } from "primereact/message";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";

import { Dropdown } from "primereact/dropdown";
import { Sidebar } from "primereact/sidebar";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";
import { Avatar } from "primereact/avatar";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";

import { InputMask } from "primereact/inputmask";

import { ProgressBar } from "primereact/progressbar";

import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import UserServices from "../../../services/userService";
import CreditCardServices from "../../../services/strapiService";
import errorMessages from "../../../utils/errorMessages";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import companyMasterService from "../../../services/companyMasterService";
import AddressService from "../../../services/addressService";
import rolesMasterService from "../../../services/rolesMaster";
import authContext from "../../../common/authContext";
import MasterServices from "../../../services/coreServices";
import { Switch } from "@mui/material";
import OrderServices from "../../../services/orderServices";
import { showToast } from "../../../utils/toastUtils";
import useTableData from "../../../redux/Reducers/useTableData";
import { checkDomain } from "../../../utils/dnsEmailChecker";

const AddBOIMember = ({
  visible,
  setVisible,
  pageRefresh,
  setPageRefresh,
  memberType,
  data,
  keyPersonalAddressType,
}) => {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    isTabRefresh,
    setIsTabRefresh, handlers = {}, state = null
  } = useContext(authContext);



  const { setOrganizerData,
    setBilingInfo,
    setMailingInfo,
    setBilingAddress,
    setOfficer,
    setIncorporator,
    setFiler,
    setDirector,
    setPresident,
    setTreasurer,
    setSecretary, setMemberData, setKeyPersonnelValue } = handlers;


  const { data: fetchedStateData, isLoading: stateLoading, isError: isStateError, error: stateError, refetch: reftechStateData } = useTableData("state");

  const { data: fetchedCountryData, isLoading: countryLoading, isError: isCountryError, error: countryError, refetch: reftechCountryData } = useTableData("countryMaster");

  const { data: fetchedAddressTypeData, isLoading: addressTypeLoading, isError: isAddressTypeError, error: addressTypeError, refetch: reftechAddressTypeData } = useTableData("addressType");

  const { data: fetchedKeyPersonnelTypeData, isLoading: keyPersonnelTypeLoading, isError: isKeyPersonnelTypeError, error: keyPersonnelTypeError, refetch: reftechKeyPersonnelTypeData } = useTableData("applicantType");

  const [value, setValue] = useState("");
  const [ingredient, setIngredient] = useState("");
  const toast = useRef(null);
  const [upload, setUpload] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isEqualAddress, setIsEqualAddress] = useState(true);
  const [allCustomer, setAllCustomer] = useState([]);

  console.log(data);

  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [cityErr, setcityErr] = useState("");

  const toastCenter = useRef(null);

  const [selectedSkills, setSelectedSkills] = useState(null);
  const navigate = useNavigate();

  const [salutation, setSalutation] = useState("");

  const [firstName, setFirstName] = useState("");

  const [lastName, setLastName] = useState("");

  const [website, setWebsite] = useState("");

  const [company, setCompany] = useState("");

  const [phone, setPhone] = useState("");

  const [address, setAddress] = useState("");

  const [city, setCity] = useState("");


  const [zip, setZip] = useState("");

  const [emailErr, setEmailErr] = useState("");

  const [passwordErr, setPasswordErr] = useState("");

  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [firstNameErr, setFirstNameErr] = useState("");

  const [lastNameErr, setLastNameErr] = useState("");

  const [phoneErr, setPhoneErr] = useState("");

  const [selectedCountry, setSelectedCountry] = useState("+1");

  const [roleId, setRoleId] = useState(null);

  const [isShowContent2, setIsShowContent2] = useState(false);

  const [isShowContent3, setIsShowContent3] = useState(false);

  const [globalErr, setGlobalErr] = useState("");

  const [isFocused, setIsFocused] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const [openDateErr, setOpenDateErr] = useState("");

  const [keyPersonnelNameErr, setkeyPersonnelNameErr] = useState("");

  const [addr1, setAddr1] = useState("");

  const [addr1Err, setAddr1Err] = useState("");

  const [addr2, setAddr2] = useState("");

  const [addr2Err, setAddr2Err] = useState("");

  const [addr3, setAddr3] = useState("");

  const [region, setRegion] = useState("");

  const [regionErr, setRegionErr] = useState("");

  const [states, setStates] = useState(null);

  const [statesErr, setStatesErr] = useState("");

  const [zipErr, setZipErr] = useState("");

  const [companyDetails, setCompanyDetails] = useState(null);

  const [allAddressType, setAllAddressType] = useState([]);

  const [addressType, setAddressType] = useState(null);

  const [allStates, setAllStates] = useState([]);

  const [allCountries, setAllCountries] = useState([]);

  const [countryData, setCountryData] = useState(null);

  const [stateValue, setStateValue] = useState(null);

  const [countryValue, setCountryValue] = useState(null);

  const [allAddressList, setAddressList] = useState([]);
  const [keyPersonnelName, setkeyPersonnelName] = useState("");

  const [keyPersonnelTitle, setkeyPersonnelTitle] = useState("");

  const [ownershipPercentage, setownershipPercentage] = useState("");

  const [FormationDate, setFormationDate] = useState("");

  const [dateOfTransfer, setdateOfTransfer] = useState(dayjs(null));

  const [addressData, setAddressData] = useState(null);

  const [allKeyPersonnelType, setAllKeyPersonnelType] = useState([]);

  const [keyPersonnelType, setKeyPersonnelType] = useState(null);

  const [percentageErr, setPercentageErr] = useState("");

  const [countryErr, setCountryErr] = useState("");

  const [suffix, setSuffix] = useState(null);

  const [middleName, setMiddleName] = useState(null);

  const [dateOfBirth, setDateOfBirth] = useState(dayjs(null));

  const [dateOfTransferErr, setDateOfTransferErr] = useState("");

  const [fileIds, setFileIds] = useState([]);

  const [IsExempt, setIsExempt] = useState(false);

  const [haveAFinCenId, setHaveFinCenId] = useState(false);

  const [identifyingDocumentNo, setIdentifyingDocumentNo] = useState(null);

  const fileInputRef = useRef(null)

  const [lastNameOrBusinessLegalName, setLastNameOrBusinessLegalName] =
    useState(null);

  const [finCenIdNumber, setFinCenIdNumber] = useState(null);

  const [allDocumentType, setAllDocumentType] = useState([
    "US Passport",
    "State Issued Driver_License",
    "US Government ID",
    "Foreign Passport",
  ]);

  const [documentType, setDocumentType] = useState(null);

  const [errors, setErrors] = useState({
    contactNumber: "",
    email: "",
    zip: "",
  });

  useEffect(() => {
    console.log(keyPersonalAddressType);
    if (!addressType && keyPersonalAddressType) {
      setAddressType(keyPersonalAddressType);
    }
  }, []);

  // useEffect(() => {
  //     if (Array.isArray(allLocation)) {
  //         setAddressList(allLocation)
  //     }
  // }, [allLocation])


  useEffect(() => {
    if (fetchedStateData) {
      setAllStates(fetchedStateData);
    }
  }, [fetchedStateData])


  useEffect(() => {
    setCountryData("US");

    if (fetchedCountryData) {
      setAllCountries(fetchedCountryData);
    }
  }, [fetchedCountryData])

  useEffect(() => {
    if (fetchedKeyPersonnelTypeData) {
      setAllKeyPersonnelType(fetchedKeyPersonnelTypeData)
    }
  }, [fetchedKeyPersonnelTypeData])

  useEffect(() => {
    if (fetchedAddressTypeData) {
      setAllAddressType(fetchedAddressTypeData)
    }
  }, [fetchedAddressTypeData])



  useEffect(() => {
    if (memberType) {
      setKeyPersonnelType(memberType);
    }
  }, [memberType]);

  useEffect(() => {
    if (keyPersonalAddressType) {
      setAddressType(keyPersonalAddressType);
    }
  }, [keyPersonalAddressType]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [isContentVisible, setIsContentVisible] = useState(true);

  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    // toast.current.show({
    //   severity: "info",
    //   summary: "Success",
    //   detail: "File uploaded",
    // });
    showToast("success", `File uploaded`)

  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 1 MB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}
          ></ProgressBar>
        </div>
      </div>
    );
  };


  useEffect(() => {
    if (state) {
      setStates(state?.id)
    }
  }, [state])

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-file mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop File Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-file",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  const toggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const handlePostalCode = (e) => {
    setZipErr("");
    setErrors((prevErrors) => ({
      ...prevErrors,
      ["zipCode"]: "",
    }));
    let value = e.target.value;
    if (value === "") {
      setZipErr("Zip code required");
    }
    if (value && value.trim() !== "") {
      if (!/^\d+$/.test(value)) {
        setZipErr("Enter valid zip code");
      }
    }
    setZip(e.target.value);
  };

  const dialogView = () => setVisible(false);
  const cancelView = () => {
    setVisible(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setIngredient("");
    setPhone("");
    setCity("");
    setDateOfBirth(null);
    setPassword("");
    setConfirmPassword("");
    setFirstNameErr("");
    setLastNameErr("");
    setPasswordErr("");
    setConfirmPasswordErr("");
    setkeyPersonnelName("");
    setPhoneErr("");
    setcityErr("");
    setEmailErr("");
    setPhoneErr("");
    setcityErr("");
    setZip("");
    setZipErr("");
    setEmailErr("");
    setAddr2("");
    setcityErr("");
    setStatesErr("");
    setStates(null);
    // setCountryData(null)
    setCountryErr("");
    setAddr1("");
    setAddr1Err("");
    setAddr2Err("");
    setMiddleName("");
    setCity("");
    setSuffix(null);
    setPercentageErr("");
    setkeyPersonnelNameErr("");
    setOpenDateErr("");
    setdateOfTransfer(null);
    setownershipPercentage("");
    setDocumentType(null);
    setIdentifyingDocumentNo(null);
    setFinCenIdNumber(null);
    setIsExempt(false);
    setHaveFinCenId(false);
    setLastNameOrBusinessLegalName(null);
    setErrors({});
  };


  let newObject = window.localStorage.getItem("user");
  let updatedUser = Object.assign({}, JSON.parse(newObject));

  useEffect(() => {
    setSelectedCountry("+1");

    if (updatedUser) {
      if (updatedUser.companyRoles !== undefined) {
        if (updatedUser.companyRoles.length > 0) {
          if (updatedUser.companyRoles[0].roles.length > 0) {
            setRoleId(updatedUser.companyRoles[0].roles[0].id);
          }
        }
      }
    }
  }, []);

  const handleOpenDate = (newValue) => {
    setDateOfTransferErr("");
    console.log(newValue);
    if (newValue && dayjs(newValue).isValid()) {
      // setFormationDate(newValue);
      if (newValue !== null) {
        setdateOfTransfer(newValue);
        // const d = new Date(newValue.$d)
        // if (isNaN(d.getDay()) || isNaN(d.getMonth()) || isNaN(d.getFullYear())) {
        //     setOpenDateErr("Select Valid Position Opened Date")
        // }
      } else {
        setOpenDateErr("Date of transfer required");
      }
    } else {
      setFormationDate(null); // or handle the invalid case
    }
  };

  // const handleCity = (e) => {
  //   setEmailErr("");
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     ["email"]: "",
  //   }));
  //   const value = e.target.value;
  //   if (value === "") {
  //     setEmailErr(errorMessages.emailRequired);
  //   }
  //   if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
  //     setEmailErr(errorMessages.emailValidation);
  //   }
  //   setIngredient(e.target.value);
  // };

  const handleCity = async (e) => {
    setEmailErr('')
    const value = e.target.value
    setIngredient(e.target.value)
    if (value === "") {
        setEmailErr(errorMessages.emailRequired)
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
        setEmailErr(errorMessages.emailValidation);
    }
    const domain = value.split("@")[1]; 
    const isDomainValid = await checkDomain(domain);
    if (!isDomainValid) {
        setEmailErr("Email domain is not exist");
    }
    
   
}

  const handleEmail = (e) => {
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setEmail(e.target.value);
  };

  const handleDOB = (newValue) => {
    setOpenDateErr("");
    setErrors((prevErrors) => ({
      ...prevErrors,
      ["dateOfBirth"]: "",
    }));
    console.log(newValue);
    if (newValue && dayjs(newValue).isValid()) {
      // setFormationDate(newValue);
      if (newValue !== null) {
        setDateOfBirth(newValue);
        // const d = new Date(newValue.$d)
        // if (isNaN(d.getDay()) || isNaN(d.getMonth()) || isNaN(d.getFullYear())) {
        //     setOpenDateErr("Select Valid Position Opened Date")
        // }
      } else {
        setOpenDateErr("Date of birth required");
      }
    } else {
      setDateOfBirth(null); // or handle the invalid case
    }
  };

  const handlePercentage = (e) => {
    setPercentageErr("");
    const input = e.target.value;
    const regex = /^(\d{1,2}|100)(\.\d{1,2})?%?$/;

    if (regex.test(input) || input === "") {
      setownershipPercentage(input);
    } else {
      setPercentageErr("Enter valid owner percentage");
    }
  };

  const onCountryChange = (e) => {
    console.log(e.value);
    setSelectedCountry(e.value);
  };
  
  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;

    let inputValue = value.replace(/\D/g, ""); // Remove non-digit characters

    if (inputValue.length > 10) {
        inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }

    let formattedValue = inputValue;
    if (inputValue.length === 10) {
        formattedValue = inputValue.replace(
            /(\d{3})(\d{3})(\d{4})/,
            "($1) $2-$3"
        );
    }

    // Update input field value and state
    e.target.value = formattedValue;
    setPhone(formattedValue);

    // Check if input is empty
    if (value.trim() === "") {
        setPhoneErr(errorMessages.phoneRequired);
        return; // Stop further execution
    }

    console.log("Input Length:", inputValue.length);

    // Validate phone number format only if length is 10
    if (inputValue.length <= 9) {
        if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formattedValue)) {
            setPhoneErr(errorMessages.phoneValidation);
        }
    }
};

  // const handlePhone = (e) => {
  //   setPhoneErr("");
  //   let value = e.target.value;

  //   let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
  //   if (inputValue.length > 10) {
  //     inputValue = inputValue.slice(0, 10); // Limit to 10 digits
  //   }
  //   const formattedValue = inputValue.replace(
  //     /(\d{3})(\d{3})(\d{4})/,
  //     (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
  //   );
  //   if (inputValue.length === 10) {
  //     // Only show formatted value after 10 digits
  //     e.target.value = formattedValue;
  //     setPhone(formattedValue); // Update state with formatted number
  //   } else {
  //     e.target.value = inputValue;
  //     setPhone(inputValue);
  //   }

  //   if (value === "") {
  //     setPhoneErr(errorMessages.phoneRequired);
  //   }
  //   if (value && value.trim() !== "" && inputValue.length <= 9) {
  //     if (
  //       !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
  //         value
  //       )
  //     ) {
  //       setPhoneErr(errorMessages.phoneValidation);
  //     }
  //   }
  //   setPhone(e.target.value);
  // };

  const handleToggle2 = () => {
    setIsShowContent2(!isShowContent2);
  };

  const handleToggle3 = () => {
    setIsShowContent3(!isShowContent3);
  };

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        {selectedCountry ? (
          <span>
            <b>{selectedCountry.name}</b> selected.
          </span>
        ) : (
          "No country selected."
        )}
      </div>
    );
  };

  const handleHAaveFinCenId = (event) => {
    console.log(event);
    setHaveFinCenId(event.target.checked);
  };

  const handleExemptEntity = (event) => {
    setIsExempt(event.target.checked);
  };

  const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="14" viewBox="0 0 21 14" fill="none">
    <circle cx="9.79519" cy="2.00625" r="2.00625" fill="#A3D5C9"/>
<circle cx="7.67096" cy="6.13675" r="1.06213" fill="#A3D5C9"/>
<circle cx="1.23915" cy="11.5064" r="1.23915" fill="#A3D5C9"/>
<circle cx="15.2829" cy="12.4506" r="1.47518" fill="#A3D5C9"/>
<circle cx="19.0004" cy="8.26101" r="1.06213" fill="#A3D5C9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6886 0.650298C15.7808 1.14561 15.3314 1.88426 15.3314 2.88125C15.3314 3.25051 15.4132 3.69897 15.5172 3.90007C15.6192 4.09751 15.6675 4.29412 15.6244 4.33672C15.5812 4.37932 14.4255 5.56549 13.0562 6.97258L10.5663 9.5309L10.2051 9.33783C9.72642 9.08209 8.5438 9.08256 8.0643 9.33889L7.70094 9.53302L6.31214 8.14057L4.92323 6.74811L5.10155 6.40316C5.40803 5.8106 5.4491 5.28143 5.23785 4.6484C4.87201 3.5524 4.02785 2.93907 2.885 2.93907C1.62401 2.93907 0.628439 3.83469 0.499331 5.08529C0.309446 6.92561 2.08686 8.27074 3.82911 7.60537L4.36643 7.40014L5.74319 8.76298L7.11995 10.1258L6.91815 10.5489C6.61756 11.1792 6.65663 12.0617 7.01421 12.7184C8.17748 14.8542 11.3541 14.1761 11.5336 11.7535C11.5703 11.2579 11.5252 10.9367 11.3712 10.5952L11.1581 10.1225L13.751 7.52949L16.3439 4.93659L16.8074 5.13037C17.5107 5.42411 18.5064 5.31601 19.138 4.87711C20.5128 3.92202 20.5099 1.83623 19.1323 0.87901C18.7142 0.58834 18.4875 0.520836 17.8341 0.49204C17.2702 0.467257 16.9408 0.512693 16.6886 0.650298Z" fill="white"/>
</svg>
 `;

  const handleFile = (e, data, key) => {
    console.log(e.target.files[0]);
    const formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    setIsTabRefresh(true);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [key]: "",
    }));

    console.log(key)


    if (key === "uploadIDPage") {
      OrderServices.customBOIUpload(formdata)
        .then((res) => {
          console.log(res)
          const data = res.data
          console.log(data)
          if (fileInputRef.current) {
            fileInputRef.current.value = null;
          }
          if (data) {
            if (data?.document_type !== "None") {
              setDocumentType(data?.document_type);
            } else {
              setDocumentType(null);
            }

            if (data?.data_first_name !== "None") {
              setFirstName(data?.data_first_name);
            } else {
              setFirstName("");
            }

            if (data?.data_last_name !== "None") {
              setLastName(data?.data_last_name);
            } else {
              setLastName("");
            }

            if (data?.data_Email !== "None") {
              setEmail(data?.data_Email);
            } else {
              setEmail("");
            }

            if (data?.data_Phone !== "None") {
              setPhone(data?.data_Phone);
            } else {
              setPhone("");
            }
            const validDayJsDate = dayjs(data?.data_date_of_birth, "YYYY-MM-DD");
            console.log(validDayJsDate)
            if (data?.data_date_of_birth !== "None" && validDayJsDate.isValid()) {
              setDateOfBirth(validDayJsDate);
            } else {
              setDateOfBirth(null);
            }

            if (data?.data_Address !== "None") {
              setAddr1(data?.data_Address);
            } else {
              setAddr1("");
            }

            if (data?.data_city !== "None") {
              setCity(data?.data_city);
            } else {
              setCity("");
            }

            if (data?.data_state !== "None") {
              setStates(data?.data_state);
            } else {
              setStates(null);
            }

            if (data?.data_zip_code !== "None") {
              setZip(data?.data_zip_code);
            } else {
              setZip("");
            }

            if (data?.data_license_number !== "None") {
              setIdentifyingDocumentNo(data?.data_license_number);
            } else {
              setIdentifyingDocumentNo(null);
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }

    MasterServices.saveFile("companyDoc", formdata)
      .then((res) => {
        console.log(res);

        if (res.status === 201 || res.status === 200) {
          if (data) {
            const orderDoc = {
              documentPath: res.data,
              documentName: `${data}-${e.target.files[0].name}`,
            };

            MasterServices.saveDocument(orderDoc)
              .then((res) => {
                console.log(res);
                setIsTabRefresh(false);
                // Save data as key and documentPath as value
                setFileIds((prevFileIds) => ({
                  ...prevFileIds,
                  [data]: res.data.documentPath,
                }));
              })
              .catch((err) => {
                console.log(err);
                setIsTabRefresh(false);
              });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        setIsTabRefresh(false);
      });
  };

  console.log(roleId);

  function capitalizeFirstLetter(string) {
    if (!string) return ""; // handle empty or undefined strings

    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  function lowerAllLetter(string) {
    if (!string) return ""; // handle empty or undefined strings
    return string.charAt(0).toLowerCase() + string.slice(1).toLowerCase();
  }

  const validateForm = (formData) => {
    let isValid = true;
    const newErrors = {};

    console.log(formData)

    const requiredFields = [
      "firstName",
      "lastName",
      "contactNumber",
      "email",
      "finCenIdNumber",
    ];

    const requiredFieldsWithOutFinCenid = [
      "firstName",
      "lastName",
      "contactNumber",
      "email",
      "uploadIDPage",
      "residentialAddress",
      "identifyingDocumentNo",
      "documentType",
      "dateOfBirth",
      "city",
      "state",
      "country",
      "zipCode",
    ];

    const formatLabel = (field) =>
      field
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase())
        .replace("No", "Number");

    if (!IsExempt && !haveAFinCenId) {
      requiredFieldsWithOutFinCenid.forEach((field) => {
        if (!formData[field]) {
          newErrors[field] = `${formatLabel(field)} is required.`;
          isValid = false;
        }
      });
      // Validate phone number format
      const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
      if (formData.contactNo && !phoneRegex.test(formData.contactNo)) {
        newErrors.contactNo =
          "Invalid phone number format. Use (XXX) XXX-XXXX.";
        isValid = false;
      }

      // Validate email format
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (formData.email && !emailRegex.test(formData.email)) {
        newErrors.email = "Invalid email address.";
        isValid = false;
      }

      // Validate ZIP code format
      const zipRegex = /^\d{5}$/;
      if (formData.zipCode && !zipRegex.test(formData.zipCode)) {
        newErrors.zipCode = "Invalid ZIP code. Must be 5 digits.";
        isValid = false;
      }
    } else if (!IsExempt && haveAFinCenId) {
      requiredFields.forEach((field) => {
        if (!formData[field]) {
          newErrors[field] = `${formatLabel(field)} is required.`;
          isValid = false;
        }
      });
      // Validate phone number format
      const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
      if (formData.contactNo && !phoneRegex.test(formData.contactNo)) {
        newErrors.contactNo =
          "Invalid phone number format. Use (XXX) XXX-XXXX.";
        isValid = false;
      }

      // Validate email format
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (formData.email && !emailRegex.test(formData.email)) {
        newErrors.email = "Invalid email address.";
        isValid = false;
      }

      // Validate ZIP code format
      const zipRegex = /^\d{5}$/;
      if (formData.zipCode && !zipRegex.test(formData.zipCode)) {
        newErrors.zipCode = "Invalid ZIP code. Must be 5 digits.";
        isValid = false;
      }
    } else {
      if (!formData["lastNameOrBusinessLegalName"]) {
        newErrors["lastNameOrBusinessLegalName"] = `${formatLabel(
          "lastNameOrBusinessLegalName"
        )} is required.`;
      }
    }

    if (keyPersonnelType?.name === "COMPANY APPLICANT" && (!formData.haveFinCenId) && (!formData.uploadId)) {
      newErrors["uploadId"] = "Upload ID required"
      isValid = false;
    }

    // Set errors in the component state (assuming setErrors is available)
    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsDisabled(true);
    console.log(parseInt(identifyingDocumentNo, 10))
    const boiData = {
      firstName: IsExempt ? lastNameOrBusinessLegalName : firstName,
      middleName: middleName,
      lastName: lastName,
      suffix: suffix,
      email: ingredient,
      contactNumber: phone,
      exemptEntity: IsExempt,
      dateOfBirth: dateOfBirth,
      country: countryData,
      state: states,
      issuingJurisdictionState: states,
      issuingJurisdictionCountry: countryData,
      residentialAddress: addr1,
      city: city,
      zipCode: parseInt(zip, 10),
      uploadIDPage: fileIds.upload_id_page || null,
      documentType: documentType,
      identifyingDocumentNo: identifyingDocumentNo,
      lastNameOrBusinessLegalName: lastNameOrBusinessLegalName,
      haveFinCenId: haveAFinCenId,
      finCenIdDocument: fileIds.fincenId_doc || null,
      finCenIdNumber: parseInt(finCenIdNumber, 10),
      uploadId: fileIds.upload_id || null,
      companyId: data ? data.id : 0,
      applicantType: keyPersonnelType,
    };



    const isValid = validateForm(boiData);

    console.log(isValid)

    if (isValid) {
      setIsTabRefresh(true);
      companyMasterService
        .saveOwnerInfo(boiData)
        .then((res) => {
          console.log(res);
          setIsDisabled(false);

          setFirstName("");
          setLastName("");
          setEmail("");
          setIngredient("");
          setPhone("");
          setPassword("");
          setConfirmPassword("");
          setFirstNameErr("");
          setLastNameErr("");
          setPasswordErr("");
          setConfirmPasswordErr("");
          setPhoneErr("");
          setcityErr("");
          setEmailErr("");
          setPhoneErr("");
          setcityErr("");
          setZip("");
          setZipErr("");
          setEmailErr("");
          setAddr2("");
          setcityErr("");
          setStatesErr("");
          setStates(null);
          // setCountryData(null)
          setCountryErr("");
          setAddr1("");
          setAddr1Err("");
          setAddr2Err("");
          setdateOfTransfer(null);
          setownershipPercentage("");
          setPercentageErr("");
          setkeyPersonnelNameErr("");
          setOpenDateErr("");
          setIsTabRefresh(false);
          setkeyPersonnelName("");
          setkeyPersonnelTitle("");
          setMiddleName("");
          setCity("");
          setSuffix(null);
          setDateOfBirth(null);
          setDocumentType(null);
          setIdentifyingDocumentNo(null);
          setFinCenIdNumber(null);
          setIsExempt(false);
          setHaveFinCenId(false);
          setLastNameOrBusinessLegalName(null);

          if (keyPersonnelType) {
            const typeMapping = {
              ORGANIZER: setOrganizerData,
              FILER: setFiler,
              INCORPORATOR: setIncorporator,
              OFFICER: setOfficer,
              DIRECTOR: setDirector,
              BILLING: setBilingAddress,
              PRESIDENT: setPresident,
              TREASURER: setTreasurer,
              SECRETARY: setSecretary,
              "RA-BILLING": setBilingInfo,
              "RA-MAILING": setMailingInfo,
              "MEMBER_OR_MANAGER": setKeyPersonnelValue,
              "BENEFICIAL OWNER":setKeyPersonnelValue,
              "COMPANY APPLICANT":setKeyPersonnelValue,
              "DEBTOR":setKeyPersonnelValue,
              "SECUERED PARTY":setKeyPersonnelValue
            };

            const updateFunction = typeMapping[keyPersonnelType?.keyPersonalType];
            if (updateFunction) {
              updateFunction(res.data);
            }

          }

          // if (toast.current !== undefined) {
          //   toast.current.show({
          //     severity: "success",
          //     summary: `${capitalizeFirstLetter(
          //       keyPersonnelType.name
          //     )} added successfully`,
          //     life: 3000,
          //     content: (props) => (
          //       <div
          //         className="flex flex-column align-items-left"
          //         style={{ flex: "1" }}
          //       >
          //         <div className="flex align-items-center gap-2">
          //           <Avatar
          //             shape="circle"
          //             style={{ backgroundColor: "#0047FF", color: "#ffffff" }}
          //           >
          //             <div dangerouslySetInnerHTML={{ __html: svgContent }} />
          //           </Avatar>
          //           <span className="p-toast-summary">vState Filings</span>
          //         </div>
          //         <div className="p-toast-detail">{props.message.summary}</div>
          //         {/* <Button className=" signup-btn vstate-button flex" label="Ok" severity="success" onClick={() => navigate("/signin")}></Button> */}
          //       </div>
          //     ),
          //   });
          // }

          showToast("success", `${capitalizeFirstLetter(keyPersonnelType.name)} added successfully`)

          setTimeout(() => {
            setVisible(false);
          }, 3000);
          // navigate("/signin")
        })
        .catch((err) => {
          console.log(err);
          setIsDisabled(false);
          setVisible(true);
          setIsTabRefresh(false);
          // if (toast.current !== null) {
          //   toast.current.show({
          //     severity: "warn",
          //     summary: "Oops",
          //     detail: "Something went wrong",
          //     life: 2800,
          //   });
          // }
          showToast("error", `Something went wrong`)
        });
    }
  };

  const customHeader = (
    <div className="flex align-items-center gap-2">
      <Avatar
        image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
        shape="circle"
      />
      <span className="font-bold">Amy Elsner</span>
    </div>
  );

  console.log(fileIds);

  return (
    <div>
      <Toast ref={toast} />
      <Toast ref={toastCenter} />
      <Sidebar
        position="right"
        header={customHeader}
        visible={visible}
        className="dialog-box"
        onHide={cancelView}
      >
        <div className="grid custom-sidebar-header">
          <div className="grid col-12 col-sm-12 col-md-12 col-lg-11 px-4 pt-4 pb-0">
            <div className="col-12 sidebar-header-text1 pb-0">
              Add{" "}
              {keyPersonnelType
                ? capitalizeFirstLetter(keyPersonnelType.name)
                : "Member"}{" "}
              Information
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-1 flex justify-content-end pt-4 pb-0">
            <button onClick={dialogView}>
              <i className="pi pi-times"></i>
            </button>
          </div>
          <div className="col-12 sidebar-header-text2 px-4">
            Fill the below details to add a{" "}
            {keyPersonnelType
              ? lowerAllLetter(keyPersonnelType.keyPersonalType)
              : "member"}{" "}
            information of your business.
          </div>
        </div>

        <div className="grid justify-content-center w-full mt-3">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
            <span className=" mr-3">
              <div class="relative">
                <Dropdown
                  value={keyPersonnelType}
                  options={allKeyPersonnelType}
                  filter
                  resetFilterOnHide
                  onChange={(e) => setKeyPersonnelType(e.value)}
                  optionLabel="name"
                  disabled
                  style={{ borderRadius: "6px", width: "100%" }}
                  className=" font-fam-for-all"
                />

                <label
                  htmlFor="floating_outlined3"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Key Personnel Type
                  <span className="form-field-mandatory">*</span>
                </label>
              </div>
            </span>
          </div>
        </div>

        <form id="myform" className="form-spacing mt-4" onSubmit={handleSubmit}>
          <Toast ref={toast} />
          {(
            <div className=" input-sidebar-card mt-4">
              <div className="form-content-signup px-5 pb-3 pt-3">
                <div className="grid  py-2">
                  {
                    keyPersonnelType?.name === "BENEFICIAL OWNER" && <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                      <span className=" mr-3">
                        <div class="relative">
                          <Switch
                            checked={IsExempt}
                            onChange={handleExemptEntity}
                            inputProps={{ "aria-label": "controlled" }}
                          />

                          <label
                            htmlFor="floating_outlined3"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Exempt Entity
                            <span className="form-field-mandatory"></span>
                          </label>
                        </div>
                        {errors.exemptEntity && (
                          <small className="error-msg font-fam-for-all">
                            {errors.exemptEntity}
                          </small>
                        )}
                      </span>
                    </div>
                  }
                  {
                    !IsExempt && <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="justify-content-center ">
                        <div className="field py-0">
                          <div
                            className="p-inputgroup flex-1"
                            style={{ height: "40px", width: "100%" }}
                          >
                            <span className="" style={{ width: "100%" }}>
                              <div className="relative custom-input-mask">
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    handleFile(
                                      e,
                                      "upload_id_page",
                                      "uploadIDPage"
                                    )
                                  }
                                />
                                <label
                                  htmlFor="floating_outlined8"
                                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                >
                                  UploadID Page{" "}
                                  <span className="form-field-mandatory">
                                    *
                                  </span>
                                </label>
                              </div>
                              {errors.uploadIDPage && (
                                <small className="error-msg font-fam-for-all">
                                  {errors.uploadIDPage}
                                </small>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>

                {IsExempt && (
                  <div className="grid py-2">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                      <span className=" ">
                        <div class="relative">
                          <InputText
                            id="floating_outlined7"
                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            style={{ width: "100%", height: "40px" }}
                            placeholder=""
                            maxLength={50}
                            value={lastNameOrBusinessLegalName}
                            onChange={(e) => {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                ["lastNameOrBusinessLegalName"]: "",
                              }));
                              setLastNameOrBusinessLegalName(e.target.value);
                            }}
                            name="cityName"
                          />

                          <label
                            htmlFor="floating_outlined7"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Last Name or Business Legal Name{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {errors.lastNameOrBusinessLegalName && (
                          <small className="error-msg font-fam-for-all">
                            {errors.lastNameOrBusinessLegalName}
                          </small>
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {!IsExempt && (
            <div className=" input-sidebar-card mt-4">
              <div className="form-content-signup px-5 pb-5">
                <div className="grid  py-2">
                  {
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                      <span className=" mr-3">
                        <div class="relative">
                          <Switch
                            checked={haveAFinCenId}
                            inputProps={{ "aria-label": "controlled" }}
                            onChange={handleHAaveFinCenId}
                          />

                          <label
                            htmlFor="floating_outlined3"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Have A FinCEN ID
                            <span className="form-field-mandatory"></span>
                          </label>
                        </div>
                      </span>
                    </div>
                  }
                  {<div className="col-12 col-sm-12 col-md-12 col-lg-6"></div>}
                  {haveAFinCenId && (
                    <>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                        <span className=" ">
                          <div class="relative">
                            <input
                              type="number"
                              // className='dialog-form-input-field'
                              id="floating_outlined7"
                              className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              style={{ width: "100%", height: "40px" }}
                              placeholder=""
                              maxLength={50}
                              value={finCenIdNumber}
                              onChange={(e) => {
                                setErrors((prevErrors) => ({
                                  ...prevErrors,
                                  ["finCenIdNumber"]: "",
                                }));
                                setFinCenIdNumber(e.target.value);
                              }}
                              name="cityName"
                            />

                            <label
                              htmlFor="floating_outlined7"
                              className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                            >
                              FinCEN ID Number{" "}
                              <span className="form-field-mandatory">*</span>
                            </label>
                          </div>
                          {errors.finCenIdNumber && (
                            <small className="error-msg font-fam-for-all">
                              {errors.finCenIdNumber}
                            </small>
                          )}
                        </span>
                      </div>

                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <div className="justify-content-center ">
                          <div className="field py-0">
                            <div
                              className="p-inputgroup flex-1"
                              style={{ height: "40px", width: "100%" }}
                            >
                              <span className="" style={{ width: "100%" }}>
                                <div className="relative custom-input-mask">
                                  <input
                                    type="file"
                                    onChange={(e) =>
                                      handleFile(
                                        e,
                                        "fincenId_doc",
                                        "finCenIdDocument"
                                      )
                                    }
                                  />
                                  <label
                                    htmlFor="floating_outlined8"
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                  >
                                    Upload the ID page of your document{" "}
                                    <span className="form-field-mandatory"></span>
                                  </label>
                                </div>
                                {errors.finCenIdDocument && (
                                  <small className="error-msg font-fam-for-all">
                                    {errors.finCenIdDocument}
                                  </small>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {!IsExempt && (
            <div className=" input-sidebar-card mt-4">
              <div className="px-5 pt-4">
                <div
                  className="flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="font-fam-for-all srch-cand-text2">
                    <span>
                      <i className="pi pi-address-book"></i>
                    </span>
                    {keyPersonnelType
                      ? capitalizeFirstLetter(keyPersonnelType.name)
                      : "Member"}{" "}
                    Details
                  </div>
                </div>
              </div>

              <div className="form-content-signup px-5 pb-5">
                <div className="grid  py-2">
                  {/* {
                                    keyPersonnelType && (keyPersonnelType.id === 15 || keyPersonnelType.id === 14) ? <> */}
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{ height: "40px", width: "100%" }}
                          maxLength={50}
                          value={firstName}
                          id="floating_outlined3001"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          onChange={(e) => {
                            setFirstNameErr("");
                            setFirstName(e.target.value);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              ["firstName"]: "",
                            }));
                          }}
                          name="cityName"
                        />

                        <label
                          htmlFor="floating_outlined3001"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          First name
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {errors.firstName && (
                        <small className="error-msg font-fam-for-all">
                          {errors.firstName}
                        </small>
                      )}
                    </span>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{ height: "40px", width: "100%" }}
                          maxLength={50}
                          value={middleName}
                          id="floating_outlined3003"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          onChange={(e) => setMiddleName(e.target.value)}
                          name="cityName"
                        />

                        <label
                          htmlFor="floating_outlined3003"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Middle Name
                          <span className="form-field-mandatory"></span>
                        </label>
                      </div>
                    </span>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                    <span className=" mr-3">
                      <div class="relative">
                        <InputText
                          style={{ height: "40px", width: "100%" }}
                          maxLength={50}
                          value={lastName}
                          id="floating_outlined3004"
                          placeholder=""
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          onChange={(e) => {
                            setLastNameErr("");
                            setLastName(e.target.value);
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              ["lastName"]: "",
                            }));
                          }}
                          name="cityName"
                        />

                        <label
                          htmlFor="floating_outlined3004"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Last Name
                          <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {errors.lastName && (
                        <small className="error-msg font-fam-for-all">
                          {errors.lastName}
                        </small>
                      )}
                    </span>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                    <span className=" mr-3">
                      <div class="relative">
                        <Dropdown
                          style={{ borderRadius: "6px", width: "100%" }}
                          value={suffix}
                          filter
                          resetFilterOnHide
                          placeholder="Select Suffix"
                          options={["Jr.", "Sr.", "I", "II", "III"]}
                          onChange={(e) => {
                            setSuffix(e.value);
                          }}
                          name="cityName"
                        />

                        <label
                          htmlFor="floating_outlined3005"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Suffix<span className="form-field-mandatory"></span>
                        </label>
                      </div>
                    </span>
                  </div>

                  {/* </> : ""
                                } */}

                  {!haveAFinCenId && (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                      <span className=" mr-3">
                        <div class="relative">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={dateOfBirth}
                              onChange={handleDOB}
                              disableFuture
                              slotProps={{
                                textField: {
                                  size: "small",
                                  error: false,
                                  fullWidth: true,
                                },
                              }}
                              className="dialog-form-dropdown-field"
                            />
                          </LocalizationProvider>
                          <label
                            htmlFor="floating_outlined10"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Date Of Birth{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {errors.dateOfBirth && (
                          <small className="error-msg font-fam-for-all">
                            {errors.dateOfBirth}
                          </small>
                        )}
                      </span>
                    </div>
                  )}

                  <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                    <span className=" ">
                      <div class="relative">
                        <InputText
                          // className='dialog-form-input-field'
                          id="floating_outlined7"
                          className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          style={{ width: "100%", height: "40px" }}
                          placeholder=""
                          maxLength={50}
                          value={ingredient}
                          onChange={handleCity}
                          name="cityName"
                        />

                        <label
                          htmlFor="floating_outlined7"
                          className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                          Email <span className="form-field-mandatory">*</span>
                        </label>
                      </div>
                      {errors.email && (
                        <small className="error-msg font-fam-for-all">
                          {errors.email}
                        </small>
                      )}
                    </span>
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div className="justify-content-center ">
                      <div className="field py-0">
                        <div
                          className="p-inputgroup flex-1"
                          style={{ height: "40px", width: "100%" }}
                        >
                          <Dropdown
                            value={selectedCountry}
                            filter
                            resetFilterOnHide
                            options={countries}
                            onChange={onCountryChange}
                            optionLabel="code"
                            optionValue="dialCode"
                            valueTemplate={selectedCountryTemplate}
                            itemTemplate={countryOptionTemplate}
                            style={{
                              width: "160px",
                              borderRadius: "6px 0px 0px 6px",
                            }}
                            className="p-dropdown font-fam-for-all"
                          />

                          <span className="" style={{ width: "100%" }}>
                            <div className="relative custom-input-mask">
                              <input
                                type="text"
                                id="floating_outlined211"
                                className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                                style={{
                                  height: "40px",
                                  borderRadius: "0px 6px 6px 0px",
                                  width: "100%",
                                }}
                                maxLength={50}
                                value={phone}
                                onChange={handlePhone}
                                autoClear={false}
                                placeholder=""
                                name="cityName"
                              />
                              <label
                                htmlFor="floating_outlined211"
                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                              >
                                Phone{" "}
                                <span className="form-field-mandatory">*</span>
                              </label>
                            </div>
                            {errors.contactNumber && (
                              <small className="error-msg font-fam-for-all">
                                {errors.contactNumber}
                              </small>
                            )}
                          </span>
                        </div>
                        {/* <p className='error-msg font-fam-for-all'>{phoneErr}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!IsExempt && !haveAFinCenId && (
            <div>
              <div className=" input-sidebar-card mt-4">
                <div className="px-5 pt-4">
                  <div
                    className="flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="font-fam-for-all srch-cand-text2">
                      <span>
                        <i className="pi pi-address-book"></i>
                      </span>
                      Address
                    </div>
                  </div>
                </div>

                <div className="form-content-signup px-5 pb-5">
                  <div className="grid  py-2">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 " style={{ fontSize: "14px" }}>
                      <span className=" mr-3">
                        <div class="relative">
                          <InputText
                            style={{
                              height: "40px",
                              borderRadius: "0px 6px 6px 0px",
                            }}
                            maxLength={50}
                            value={addr1}
                            onChange={(e) => {
                              setAddr1Err("");
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                ["residentialAddress"]: "",
                              }));
                              setAddr1(e.target.value);
                            }}
                            name="cityName"
                            id="floating_outlined2100009"
                            placeholder=""
                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          />
                          <label
                            htmlFor="floating_outlined2100009"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Residential Address{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {errors.residentialAddress && (
                          <small className="error-msg font-fam-for-all">
                            {errors.residentialAddress}
                          </small>
                        )}
                      </span>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 " style={{ fontSize: "14px" }}>
                      <span className=" mr-3">
                        <div class="relative">
                          <InputText
                            style={{
                              height: "40px",
                              borderRadius: "0px 6px 6px 0px",
                            }}
                            maxLength={50}
                            value={city}
                            onChange={(e) => {
                              setcityErr("");
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                ["city"]: "",
                              }));
                              setCity(e.target.value);
                            }}
                            name="cityName"
                            id="floating_outlined246768686"
                            placeholder=""
                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          />
                          <label
                            htmlFor="floating_outlined246768686"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            City <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {errors.city && (
                          <small className="error-msg font-fam-for-all">
                            {errors.city}
                          </small>
                        )}
                      </span>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                      <span className=" mr-3">
                        <div class="relative">
                          <Dropdown
                            value={states}
                            filter
                            resetFilterOnHide
                            onChange={(e) => {
                              setStatesErr("");
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                ["state"]: "",
                              }));
                              setStates(e.value);
                            }}
                            options={allStates}
                            optionValue="stateShortName"
                            optionLabel="stateFullDesc"
                            placeholder="Select a State"
                            style={{ height: "40px" }}
                            className="w-full"
                          />
                          <label
                            htmlFor="floating_outlined25"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            State{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {errors.state && (
                          <small className="error-msg font-fam-for-all">
                            {errors.state}
                          </small>
                        )}
                      </span>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                      <span className=" mr-3">
                        <div class="relative">
                          <Dropdown
                            disabled
                            value={countryData}
                            filter
                            resetFilterOnHide
                            onChange={(e) => {
                              setCountryErr("");
                              setCountryData(e.value);
                            }}
                            options={allCountries}
                            optionValue="countryShortName"
                            optionLabel="countryFullDesc"
                            placeholder="Select a Country"
                            style={{ height: "40px" }}
                            className="w-full"
                          />
                          <label
                            htmlFor="floating_outlined25"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Country{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                      </span>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                      <span className=" mr-3">
                        <div class="relative">
                          <InputText
                            style={{
                              height: "40px",
                              borderRadius: "0px 6px 6px 0px",
                            }}
                            maxLength={5}
                            value={zip}
                            onChange={handlePostalCode}
                            name="cityName"
                            id="floating_outlined26"
                            placeholder=""
                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                          />
                          <label
                            htmlFor="floating_outlined26"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Zip Code{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {errors.zipCode && (
                          <small className="error-msg font-fam-for-all">
                            {errors.zipCode}
                          </small>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!IsExempt && !haveAFinCenId && (
            <div>
              <div className=" input-sidebar-card mt-4">
                <div className="px-5 pt-4">
                  <div
                    className="flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="font-fam-for-all srch-cand-text2">
                      <span>
                        <i className="pi pi-address-book"></i>
                      </span>
                      Additional Documents
                    </div>
                  </div>
                </div>

                <div className="form-content-signup px-5 pb-5">
                  <div className="grid py-2">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                      <span className=" mr-3">
                        <div class="relative">
                          <Dropdown
                            value={documentType}
                            filter
                            resetFilterOnHide
                            onChange={(e) => {
                              setDocumentType(e.value);
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                ["documentType"]: "",
                              }));
                            }}
                            options={allDocumentType}
                            placeholder="Select Document Type"
                            style={{ height: "40px" }}
                            className="w-full"
                          />
                          <label
                            htmlFor="floating_outlined25"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Choose Documnet Type{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {errors.documentType && (
                          <small className="error-msg font-fam-for-all">
                            {errors.documentType}
                          </small>
                        )}
                      </span>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-6" style={{ fontSize: "14px" }}>
                      <span className=" ">
                        <div class="relative">
                          <input
                            type="text"
                            // className='dialog-form-input-field'
                            id="floating_outlined70001"
                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            style={{ width: "100%", height: "40px" }}
                            placeholder=""
                            maxLength={50}
                            value={identifyingDocumentNo}
                            onChange={(e) => {
                              setErrors((prevErrors) => ({
                                ...prevErrors,
                                ["identifyingDocumentNo"]: "",
                              }));
                              setIdentifyingDocumentNo(e.target.value);
                            }}
                            name="cityName"
                          />

                          <label
                            htmlFor="floating_outlined70001"
                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                          >
                            Identifying Document No{" "}
                            <span className="form-field-mandatory">*</span>
                          </label>
                        </div>
                        {errors.identifyingDocumentNo && (
                          <small className="error-msg font-fam-for-all">
                            {errors.identifyingDocumentNo}
                          </small>
                        )}
                      </span>
                    </div>

                    {keyPersonnelType?.name === "COMPANY APPLICANT" && (
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <div className="justify-content-center ">
                          <div className="field py-0">
                            <div
                              className="p-inputgroup flex-1"
                              style={{ height: "40px", width: "100%" }}
                            >
                              <span className="" style={{ width: "100%" }}>
                                <div className="relative custom-input-mask">
                                  <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={(e) =>
                                      handleFile(e, "upload_id", "uploadId")
                                    }
                                  />
                                  <label
                                    htmlFor="floating_outlined8"
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                  >
                                    Upload ID{" "}
                                    <span className="form-field-mandatory">
                                      *
                                    </span>
                                  </label>
                                </div>
                                {errors.uploadId && (
                                  <small className="error-msg font-fam-for-all">
                                    {errors.uploadId}
                                  </small>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                      <div className="justify-content-center ">
                        <div className="field py-0">
                          <div
                            className="p-inputgroup flex-1"
                            style={{ height: "40px", width: "100%" }}
                          >
                            <span className="" style={{ width: "100%" }}>
                              <div className="relative custom-input-mask">
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    handleFile(
                                      e,
                                      "upload_id_page",
                                      "uploadIDPage"
                                    )
                                  }
                                />
                                <label
                                  htmlFor="floating_outlined8"
                                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                >
                                  UploadID Page{" "}
                                  <span className="form-field-mandatory">
                                    *
                                  </span>
                                </label>
                              </div>
                              {errors.uploadIDPage && (
                                <small className="error-msg font-fam-for-all">
                                  {errors.uploadIDPage}
                                </small>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div> */}

                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-content-end dialog-form-md-group-Btn my-5">
            <Button
              type="button"
              className="vstate-reverse-button form-Btn-Label font-fam-for-all text-center  form-label mr-3"
              label="Cancel"
              style={{ height: "40px" }}
              onClick={cancelView}
            ></Button>
            <Button
              type="submit"
              className="form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
              label="Submit"
              style={{ height: "40px" }}
            ></Button>
          </div>
        </form>
      </Sidebar>
    </div>
  );
};

export default AddBOIMember;
