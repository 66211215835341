import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BlueButton from "../CommonSection/BlueButton";
import SuccessfulModal from "../SuccessfulModal/SuccessfulModal";
import errorMessages from "../../../utils/errorMessages";

const ContactForm = () => {
  const [modalShow, setModalShow] = useState(false);
  const [phoneErr, setPhoneErr] = useState("");
  const [phone, setPhone] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    website: "",
    service: null,
    message: "",
    captcha: null,
  });

  const [errors, setErrors] = useState({});

  const serviceOptions = [
    { label: "Entity Formation", value: "entity-formation" },
    {
      label: "Certificate Of Good Standing",
      value: "certificate-of-good-standing",
    },
    { label: "Corp Kit", value: "corp-kit" },
    { label: "LLC Publication", value: "llc-publication" },
    { label: "Litigation Search", value: "litigation-search" },
    { label: "Annual Report", value: "annual-report" },
    { label: "Biennial Report", value: "biennial-report" },
    { label: "Certificate Of Amendment", value: "certificate-of-amendment" },
    { label: "Certificate Of Change", value: "certificate-of-change" },
    {
      label: "DBA – Certificate Of Assumed Name",
      value: "dba-certificate-of-assumed-name",
    },
    {
      label: "Certificate Of Dissolution",
      value: "certificate-of-dissolution",
    },
    { label: "Corporate Copies", value: "corporate-copies" },
    { label: "Independent Manager", value: "independent-manager" },
    { label: "Liquor License Publishing", value: "liquor-license-publishing" },
    { label: "UCC Filing", value: "ucc-filing" },
    { label: "UCC Search", value: "ucc-search" },
    { label: "UCC Amendment", value: "ucc-amendment" },
    { label: "Certified Copies", value: "certified-copies" },
    { label: "UCC Termination", value: "ucc-termination" },
    { label: "Expedited Service", value: "expedited-service" },
    { label: "EIN", value: "ein" },
    { label: "S-Election", value: "s-election" },
    { label: "Registered Agent", value: "registered-agent" },
    { label: "App. of Authority", value: "app-of-authority" },
    { label: "Beneficial Ownership", value: "beneficial-ownership" },
  ];

  const isValidUSPhoneNumber = (phone) => {
    const phoneRegex =
      /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phoneRegex.test(phone);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Validate a field and return an error message if needed.
  const validateField = (name, value) => {
    let error = "";
    if (name === "firstName" && !value.trim()) {
      error = "First Name is required";
    }
    if (name === "lastName" && !value.trim()) {
      error = "Last Name is required";
    }
    if (name === "email") {
      if (!value.trim()) {
        error = "Email is required";
      } else if (!isValidEmail(value)) {
        error = "Invalid email format";
      }
    }
    if (name === "phone") {
      if (!value.trim()) {
        error = "Phone number is required";
      } else if (!isValidUSPhoneNumber(value)) {
        error = "Invalid phone number";
      }
    }
    return error;
  };

  // const handlePhone = (e) => {
  //   setPhoneErr("");
  //   let value = e.target.value;

  //   let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
  //   if (inputValue.length > 10) {
  //     inputValue = inputValue.slice(0, 10); // Limit to 10 digits
  //   }
  //   const formattedValue = inputValue.replace(
  //     /(\d{3})(\d{3})(\d{4})/,
  //     (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
  //   );
  //   if (inputValue.length === 10) {
  //     // Only show formatted value after 10 digits
  //     e.target.value = formattedValue;
  //     setPhone(formattedValue); // Update state with formatted number
  //   } else {
  //     e.target.value = inputValue;
  //     setPhone(inputValue);
  //   }

  //   if (value === "") {
  //     setPhoneErr(errorMessages.phoneRequired);
  //   }
  //   if (value && value.trim() !== "" && inputValue.length <= 9) {
  //     if (
  //       !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
  //         value
  //       )
  //     ) {
  //       setPhoneErr(errorMessages.phoneValidation);
  //     }
  //   }
  //   setPhone(e.target.value);
  // };


  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;
    let inputValue = value.replace(/\D/g, ""); // Remove non-digit characters
  
    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }
  
    const formattedValue = inputValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
    );
  
    // Only show formatted value after 10 digits
    const newPhoneValue = inputValue.length === 10 ? formattedValue : inputValue;
    e.target.value = newPhoneValue;
  
    // Update both the local state and formData state
    setPhone(newPhoneValue);
    setFormData((prevState) => ({
      ...prevState,
      phone: newPhoneValue,
    }));
  
    // Validate phone number and set errors if needed
    if (value === "") {
      setPhoneErr(errorMessages.phoneRequired);
    } else if (value && value.trim() !== "" && inputValue.length <= 9) {
      if (
        !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
          value
        )
      ) {
        setPhoneErr(errorMessages.phoneValidation);
      }
    }
  };

  

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  // Updated handleChange function for live validation.
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Validate the field as the user types.
    const errorMessage = validateField(name, value);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  const handleRecaptchaChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      captcha: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, captcha: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let validationErrors = {};

    // Run validations on submit as well
    if (!formData.firstName.trim())
      validationErrors.firstName = "First Name is required";
    if (!formData.lastName.trim())
      validationErrors.lastName = "Last Name is required";
    if (!formData.email.trim()) validationErrors.email = "Email is required";
    else if (!isValidEmail(formData.email))
      validationErrors.email = "Invalid email format";

    if (!formData.phone.trim())
      validationErrors.phone = "Phone number is required";
    else if (!isValidUSPhoneNumber(formData.phone))
      validationErrors.phone = "Invalid US phone number";

    if (!formData.captcha)
      validationErrors.captcha = "Please complete the CAPTCHA";

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const postData = {
      lead_name: formData.service || "N/A",
      first_name: formData.firstName,
      last_name: formData.lastName || null,
      email: formData.email || null,
      phone_number: formData.phone.replace(/\D/g, ""), // Remove non-digit characters
      token: formData.captcha || null,
    };

    console.log(postData, "postdata");

    try {
      const response = await fetch(
        "https://chatbot.redberyltest.in/leads/add_leads_data",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(postData),
        }
      );

      if (response.ok) {
        setModalShow(true); // Show modal only on successful response
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          company: "",
          website: "",
          service: null,
          message: "",
          captcha: null,
        });
        setErrors({});
      } else {
        console.error("Error: API response not OK");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    setSelectedCountry({ name: "United States", code: "US", dialCode: "+1" });
  }, []);

  return (
    <div className="flex flex-1 shrink justify-center items-start min-h-[1px] min-w-[240px] max-md:max-w-full">
      <ToastContainer />
      <div className="flex flex-col flex-1 justify-center px-2.5 pt-2.5 w-full min-w-[240px] max-md:max-w-full">
        <h1 className="text-4xl font-semibold text-start text-gray-800">
          Let's <span className="font-bold text-[#0047FF]">talk</span>
        </h1>
        <p className="normal-common-txt">
          Please provide your contact information below, and one of our team
          members will reach out to you within 24 hours.
        </p>
        <div className="main-contact-form">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col w-full max-md:max-w-full"
          >
            <div className="grid w-full">
              {[
                {
                  name: "firstName",
                  label: "* First Name",
                  type: "text",
                },
                {
                  name: "lastName",
                  label: "* Last Name",
                  type: "text",
                },
                {
                  name: "email",
                  label: "* Email Address",
                  type: "email",
                },
                // {
                //   name: "phone",
                //   label: "* Phone Number",
                //   type: "number",
                // },
                { name: "company", label: "Company Name", type: "text" },
                { name: "website", label: "Website URL", type: "url" },
              ].map((input) => (
                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-6 w-full p-1"
                  key={input.name}
                >
                  <label
                    htmlFor={input.name}
                    className="block font-medium text-gray-700"
                  >
                    {input.label}
                  </label>
                  <InputText
                    id={input.name}
                    name={input.name}
                    type={input.type}
                    value={formData[input.name]}
                    onChange={handleChange}
                    className="p-inputtext-sm w-full"
                  />
                  {errors[input.name] && (
                    <p className="text-red-500 text-sm">{errors[input.name]}</p>
                  )}
                </div>
              ))}
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 w-full p-1">
                <label
                  htmlFor="service"
                  className="block font-medium text-gray-700"
                >
                  Choose service
                </label>
                <Dropdown
                  id="service"
                  name="service"
                  options={serviceOptions}
                  optionLabel="label"
                  optionValue="value"
                  value={formData.service}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      service: e.value,
                    }))
                  }
                  placeholder="Select a service"
                  className="w-full"
                />
                {errors.service && (
                  <p className="text-red-500 text-sm">{errors.service}</p>
                )}
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-12  w-full">
                <label
                  htmlFor="service"
                  className="block font-medium text-gray-700"
                >
                  Phone Number
                </label>
                <div
                  className="p-inputgroup flex-1"
                  style={{ height: "40px", width: "100%" }}
                >
                  <Dropdown
                    value={selectedCountry}
                    options={countries}
                    resetFilterOnHide
                    onChange={onCountryChange}
                    filter
                    optionLabel="code"
                    valueTemplate={selectedCountryTemplate}
                    itemTemplate={countryOptionTemplate}
                    style={{
                      width: "160px",
                      borderRadius: "6px 0px 0px 6px",
                    }}
                    className="p-dropdown font-fam-for-all"
                  />
                  <span className="" style={{ width: "100%" }}>
                    <div className="relative custom-input-mask">
                      <input
                        type="text"
                        id="floating_outlined101"
                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                        style={{
                          height: "40px",
                          borderRadius: "0px 6px 6px 0px",
                          width: "100%",
                        }}
                        maxLength={50}
                        value={phone}
                        onChange={handlePhone}
                        autoClear={false}
                        placeholder=""
                        name="cityName"
                      />
                      <label
                        htmlFor="floating_outlined101"
                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                      >
                        Phone<span className="form-field-mandatory">*</span>
                      </label>
                    </div>
                    {phoneErr !== "" ? (
                      <p className="error-msg font-fam-for-all">{phoneErr}</p>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>

              <div className="col-12 w-full p-1">
                <label
                  htmlFor="message"
                  className="block font-medium text-gray-700"
                >
                  Message
                </label>
                <InputTextarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={5}
                  cols={30}
                  autoResize
                  className="w-full"
                />
                {errors.message && (
                  <p className="text-red-500 text-sm">{errors.message}</p>
                )}
              </div>
            </div>
            <div className="w-full p-1">
              <ReCAPTCHA
                sitekey="6LeYwM0qAAAAAC-arhPe0I2PEaCHs0AjXEBMjnNh"
                onChange={handleRecaptchaChange}
              />
              {errors.captcha && (
                <p className="text-red-500 text-sm">{errors.captcha}</p>
              )}
            </div>

            <div className="w-full p-1">
              <Button label="Submit" type="submit" className="vstate-button" />
            </div>
          </form>

        
        </div>
      </div>
      <SuccessfulModal
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
    </div>
  );
};

export default ContactForm;
