import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import OrderServices from '../../../services/orderServices';
import MasterServices from '../../../services/coreServices';

export default function VerticalBarDemo() {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [serviceData, setServiceData] = useState([]);
    const [category, setCategory] = useState([]);

    useEffect(() => {
        OrderServices.getAllServiceForDashbaord()
            .then((res) => {
                if (Array.isArray(res.data)) {
                    setServiceData(res.data);
                }
            })
            .catch((err) => console.log(err));

        MasterServices.getAllProductCatagory()
            .then((res) => {
                if (Array.isArray(res.data)) {
                    setCategory(res.data);
                }
            })
            .catch((err) => console.log(err));
    }, []);

    const getCategory = (id) => {
        const foundCategory = category.find((val) => val.id === id);
        return foundCategory ? foundCategory.productName : "Unknown";
    };

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        const colorPalette = ["#02359F", "#145EF0", "#518CFE", "#84ADFF", "#B3CDFF"];

        // Aggregate total counts by category ID
        const categoryTotals = serviceData.reduce((acc, order) => {
            const categoryId = order.productCategoryId.id;
            acc[categoryId] = (acc[categoryId] || 0) + order.totalProductCount;
            return acc;
        }, {});

        // Top 5 categories by total product count
        const topCategories = Object.entries(categoryTotals)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 5)
            .map(([categoryId]) => parseInt(categoryId));

        const datasets = topCategories.map((categoryId, index) => {
            const monthlyOrderCost = Array(12).fill(0);

            // Populate monthlyOrderCost based on categoryId
            serviceData.forEach(order => {
                if (order.productCategoryId.id === categoryId) {
                    const monthIndex = order.orderMonth - 1; // Adjust for zero-based index
                    monthlyOrderCost[monthIndex] += order.totalProductCount;
                }
            });

            console.log(`Category ID: ${categoryId}, Monthly Data: `, monthlyOrderCost); // Debugging log

            return {
                type: 'bar',
                label: getCategory(categoryId),
                backgroundColor: colorPalette[index % colorPalette.length],
                data: monthlyOrderCost,
            };
        });

        const data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            datasets,
        };

        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                tooltip: {
                    mode: 'index',
                    intersect: false,
                },
                legend: {
                    labels: {
                        color: textColor,
                    },
                },
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
                y: {
                    stacked: true,
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
            },
        };

        setChartData(data);
        setChartOptions(options);
    }, [serviceData, category]);

    return (
        <div className="card">
            <Chart type="bar" data={chartData} options={chartOptions} />
        </div>
    );
}
