import React from 'react';

function ComplianceImage({ images }) {
  return (
    <div className="flex flex-wrap justify-center items-start p-2.5 h-full min-w-[240px] w-[575px] max-md:max-w-full">
      <div className="flex flex-col grow shrink min-w-[240px] w-[444px]">
        <div className="flex flex-col items-center px-14 w-full max-md:px-5 max-md:max-w-full">
          <div className="flex justify-center max-w-full min-h-[665px] w-[444px]">
            <div className="flex overflow-hidden relative flex-col flex-1 shrink justify-center items-center px-11 py-72 w-full rounded-3xl basis-0 max-w-[444px] min-h-[665px] min-w-[240px] shadow-[0px_0px_10px_rgba(0,0,0,0.5)] max-md:px-5 max-md:py-24 max-md:max-w-full">
              <img
                loading="lazy"
                src={images.backgroundImage}
                alt=""
                className="object-cover absolute inset-0 size-full"
              />
              <img
                loading="lazy"
                src={images.iconImage}
                alt=""
                className="object-contain w-20 aspect-square"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComplianceImage;
