import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import useTableData from '../redux/Reducers/useTableData'
import authContext from '../common/authContext'
import EditCompanyMember from '../components/Company/EditCompanyComponents/EditKeyPersonnelType'
import companyMasterService from '../services/companyMasterService'
import { Checkbox } from "primereact/checkbox";
import { InputText } from 'primereact/inputtext'
import { Skeleton } from 'primereact/skeleton'


const KeyPersonnelOrderBOIDataTable = ({ data, setterFunction, responseValue, keyValue, formCounts, setFormCounts }) => {
    const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh } = useContext(authContext);

    const { data: fetchedStateData, isLoading: stateLoading, isError: isStateError, error: stateError, refetch: reftechStateData } = useTableData("state");

    const [tempProduct, setTempProduct] = useState([])

    const [showSkeleton, setShowSkeleton] = useState(false);


    useEffect(() => {
        if (globalCompany) {
            if (globalCompany.companyDetails) {
                companyMasterService
                    .getAllOwnerInformation(globalCompany.companyDetails.id)
                    .then((res) => {
                        
                        if (Array.isArray(res.data)) {
                            if (keyValue === "Beneficial_Owner_Information") {
                                const temp2 = res.data.filter(
                                    (val) => val.applicantType.name === "BENEFICIAL OWNER"
                                );
                                setProducts(temp2);
                                setShowSkeleton(true)

                            }
                            if (keyValue === "Company_Applicant_Information") {
                                const temp1 = res.data.filter(
                                    (val) => val.applicantType.name === "COMPANY APPLICANT"
                                );
                                setProducts(temp1);
                                setShowSkeleton(true)
                            }
                            setShowSkeleton(true)
                        }
                        else {
                            setShowSkeleton(true)
                        }
                    })
                    .catch((err) => {
                        
                        setShowSkeleton(true)
                    });

            }
        }
    }, [globalCompany, isTabRefresh]);


    const getState = (id) => {

        if (fetchedStateData && Array.isArray(fetchedStateData)) {
            const data = fetchedStateData.find((val) => val.id === id)
            if (data) {
                return data?.stateFullDesc
            }
        }

    }

    const formattedData = data.map((item) => Object.values(item)[0]);


    const [products, setProducts] = useState([

    ])

    const [filteredInput, setFilteredInput] = useState("");


    const [selectedData, setSelectedData] = useState([]);

    const hasPermission = (permission) => permissions.includes(permission);


    useEffect(() => {
        console.log(data, "all memeber data")
        if (Array.isArray(data) && data.length > 0) {
            const formattedData = data.map((item) => Object.values(item)[0]);
            
            setSelectedData(formattedData);
        } else {
            
            setSelectedData([]);
        }
    }, []);



    const [visible, setVisible] = useState(false)

    const [keyPersonalData, setKeyPersonnelData] = useState(null)

    const handleEdit = (rowdata) => {
        console.log(rowdata)
        setVisible(true)
        setKeyPersonnelData(rowdata)
    }

    const addressBody = (rowdata) => {

        const address = [
            rowdata?.residentialAddress,
            rowdata?.city,
            getState(rowdata?.state),
            rowdata?.zipCode
        ].filter(Boolean).join(", ");

        return (
            <div>
                {address}
            </div>
        );
    };

    const iconBodyTemplate = (rowdata) => {
        return (
            <>

                <Button
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Edit"
                    onClick={() => handleEdit(rowdata)}
                    // icon="pi pi-file-edit"
                    style={{
                        border: "none",
                        height: "40px",
                        backgroundColor: "transparent",
                        color: "blue",
                    }}
                    className="border-none h-10 bg-transparent text-#175CD3 hover:bg-#175CD3 hover:text-blue-800 transition duration-200"
                >
                    {" "}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                        <g clip-path="url(#clip0_1308_27815)">
                            <path
                                d="M9.16675 3.33332H3.33341C2.89139 3.33332 2.46746 3.50891 2.1549 3.82147C1.84234 4.13403 1.66675 4.55796 1.66675 4.99999V16.6667C1.66675 17.1087 1.84234 17.5326 2.1549 17.8452C2.46746 18.1577 2.89139 18.3333 3.33341 18.3333H15.0001C15.4421 18.3333 15.866 18.1577 16.1786 17.8452C16.4912 17.5326 16.6667 17.1087 16.6667 16.6667V10.8333M15.4167 2.08332C15.7483 1.7518 16.1979 1.56555 16.6667 1.56555C17.1356 1.56555 17.5852 1.7518 17.9167 2.08332C18.2483 2.41484 18.4345 2.86448 18.4345 3.33332C18.4345 3.80216 18.2483 4.2518 17.9167 4.58332L10.0001 12.5L6.66675 13.3333L7.50008 9.99999L15.4167 2.08332Z"
                                stroke="#004EEC"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_1308_27815">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <span
                        style={{
                            marginLeft: "5px",
                        }}
                        className="text-#175CD3 font-inter text-sm font-medium leading-5"
                    >
                        Edit
                    </span>
                </Button>

                {/* <Button
                    type="button"
                    icon="pi pi-minus"
                    disabled={
                        (formCounts[key] || 1) === 1
                    }
                    className="vstate-button font-fam-for-all text-center justify-content-center"
                    onClick={() =>
                        handleRemoveRepeatForm(key)
                    }
                /> */}

            </>
        )
    }

    useEffect(() => {
        if (responseValue) {
            const currentCount = formCounts[keyValue] || 1;
            setSelectedData((prevData) => {
                return [...prevData, responseValue]
            })
            setFormCounts((prevCounts) => ({
                ...prevCounts,
                [keyValue]: (prevCounts[keyValue] || 1) + 1,
            }));
            if (currentCount > 1) {
                if (keyValue === "Member_Or_Manager_Details") {
                    setterFunction((prevData) => {
                        const updatedMember = {
                            [`member_or_manger_details-${currentCount - 1}`]: responseValue,
                        };
                        return [
                            ...prevData.filter(
                                (data) => !data[`member_or_manger_details-${currentCount - 1}`]
                            ),
                            updatedMember,
                        ];
                    });
                }
            }
            else {
                if (keyValue === "Member_Or_Manager_Details") {
                    setterFunction((prevData) => {
                        const updatedMember = {
                            [`member_or_manger_details-0`]: responseValue,
                        };
                        return [
                            ...prevData.filter(
                                (data) => !data[`member_or_manger_details-0`]
                            ),
                            updatedMember,
                        ];
                    });
                }
            }
        }
    }, [responseValue])

    const contactBody = (rowdata) => {
        console.log(rowdata)
        return (
            <>
                <div >
                    {`${rowdata.email},${rowdata.contactNumber}`}
                </div>
            </>
        )
    }

    const handleSearch = () => {
        if (filteredInput.trim() !== "") {
            const lowerCaseInput = filteredInput.toLowerCase();

            
            

            const searchNestedObject = (obj) => {
                for (const key in obj) {
                    if (obj[key] !== null) {
                        if (typeof obj[key] === "object") {
                            if (searchNestedObject(obj[key])) {
                                return true;
                            }
                        } else if (
                            typeof obj[key] === "string" &&
                            obj[key].toLowerCase().includes(lowerCaseInput)
                        ) {
                            return true;
                        } else if (
                            typeof obj[key] === "number" &&
                            obj[key].toString().includes(lowerCaseInput)
                        ) {
                            return true;
                        }
                    }
                }
                return false;
            };

            const temp = tempProduct.filter((val) => searchNestedObject(val));
            setProducts(temp);
        } else {
            setProducts(tempProduct);
        }
    };

    useEffect(() => {
        handleSearch();
    }, [filteredInput]);


    const header = () => {
        return (
            <div className="flex flex-wrap align-items-center justify-content-start gap-3 ml-3">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder="Search"
                        style={{ borderRadius: "6px", width: "200px" }}
                        value={filteredInput}
                        className="vstate-input-field-fixed"
                        onChange={(e) => {
                            setFilteredInput(e.target.value);

                            if (e.target.value.trim() === "") {
                                setProducts(tempProduct);
                            }
                        }}
                    />
                </span>
            </div>

        )
    }

    const onCheckboxChange = (event, datas, index) => {
        const currentCount = formCounts[keyValue] || 1;

        if (event.checked) {
            setSelectedData([...selectedData, datas]);

            setFormCounts((prevCounts) => ({
                ...prevCounts,
                [keyValue]: (prevCounts[keyValue] || 1) + 1,
            }));
            if (currentCount > 1) {
                if (keyValue === "Member_Or_Manager_Details") {
                    setterFunction((prevData) => {
                        const updatedMember = {
                            [`member_or_manger_details-${currentCount - 1}`]: datas,
                        };
                        return [
                            ...prevData.filter(
                                (data) => !data[`member_or_manger_details-${currentCount - 1}`]
                            ),
                            updatedMember,
                        ];
                    });
                }
                else if (keyValue === "ucc_Debtor_Details") {
                    setterFunction((prevData) => {
                        const updatedMember = {
                            [`debtor-${currentCount - 1}`]: datas,
                        };
                        return [
                            ...prevData.filter(
                                (data) => !data[`debtor-${currentCount - 1}`]
                            ),
                            updatedMember,
                        ];
                    });
                }
                else if (keyValue === "Beneficial_Owner_Information") {
                    setterFunction((prevData) => {
                        const updatedMember = {
                            [`beneficial_${currentCount - 1}`]: datas,
                        };
                        return [
                            ...prevData.filter(
                                (data) => !data[`beneficial_${currentCount - 1}`]
                            ),
                            updatedMember,
                        ];
                    });
                }
                else if (keyValue === "Company_Applicant_Information") {
                    setterFunction((prevData) => {
                        const updatedMember = {
                            [`company_applicant_${currentCount - 1}`]: datas,
                        };
                        return [
                            ...prevData.filter(
                                (data) => !data[`company_applicant_${currentCount - 1}`]
                            ),
                            updatedMember,
                        ];
                    });
                }
            }
            else {
                if (keyValue === "Member_Or_Manager_Details") {
                    setterFunction((prevData) => {
                        const updatedMember = {
                            [`member_or_manger_details-0`]: datas,
                        };
                        return [
                            ...prevData.filter(
                                (data) => !data[`member_or_manger_details-0`]
                            ),
                            updatedMember,
                        ];
                    });
                }
                else if (keyValue === "ucc_Debtor_Details") {
                    setterFunction((prevData) => {
                        const updatedMember = {
                            [`debtor-0`]: datas,
                        };
                        return [
                            ...prevData.filter(
                                (data) => !data[`debtor-0`]
                            ),
                            updatedMember,
                        ];
                    });
                }
                else if (keyValue === "Beneficial_Owner_Information") {
                    setterFunction((prevData) => {
                        const updatedMember = {
                            [`beneficial_0`]: datas,
                        };
                        return [
                            ...prevData.filter(
                                (data) => !data[`beneficial_0`]
                            ),
                            updatedMember,
                        ];
                    });
                }
                else if (keyValue === "Company_Applicant_Information") {
                    setterFunction((prevData) => {
                        const updatedMember = {
                            [`company_applicant_0`]: datas,
                        };
                        return [
                            ...prevData.filter(
                                (data) => !data[`company_applicant_0`]
                            ),
                            updatedMember,
                        ];
                    });
                }
            }


        } else {
            console.log(currentCount)

            setSelectedData((prevSelected) =>
                prevSelected.filter((item) => item.id !== datas.id)
            );
            setFormCounts((prevCounts) => ({
                ...prevCounts,
                [keyValue]: currentCount - 1,
            }));
            setterFunction((prevData) => {
                return prevData.filter((item) => {
                    const itemData = Object.values(item)[0];
                    return itemData.id !== datas.id;
                });
            });



        }
    };

    console.log(selectedData, "selected_data")

    const checkBoxBody = (datas, index) => {
        const formattedData = data.map((item) => Object.values(item)[0]?.id);

        console.log(data, "formated data")

        console.log(formattedData, "checkbox formatted data")

        return (
            <div className='srch-cand-checkbox'>
                <Checkbox
                    onChange={(e) => onCheckboxChange(e, datas, index)}
                    checked={formattedData.includes(datas?.id) || selectedData.includes(datas)}
                />
            </div>
        );
    };

    const nameBody = (rowdata) => {
        return (<>{rowdata?.firstName + " " + rowdata?.lastName}</>)
    }


    const items = Array.from({ length: 5 }, (v, i) => i);



    return (
        <div className='vstate-card'>

            {showSkeleton ? <DataTable value={products} scrollable
                dataKey='name'
                className='font-fam-for-all datatable-sm'
                scrollHeight="400px"
                scrollWidth="100%"
                header={header}
                globalFilterFields={["djjddjjd"]}>

                <Column
                    body={(data, { rowIndex }) => checkBoxBody(data, rowIndex)}
                    className='font-fam-for-all datatable-xs-col'
                />
                <Column field='exemptEntity' header="Exempt Entity" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                <Column field='finCenIdNumber' header="FinCEN ID" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                <Column header="Name" body={nameBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                <Column header="Contact Info" body={contactBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                <Column header="Address" body={addressBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>

                {/* <Column field="keyPersonnelName" header="Name" className='font-fam-for-all datatable-lg-col webapp-sm-size' sortable />
                <Column field="keyPersonnelTitle" header="Title" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
                <Column field="contactNo" header="Contact No" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
                <Column field="emailId" header="Email" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
                <Column field="companyName" header="Company Name" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
                <Column field="primaryContact" header="Primary Contact" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
                <Column field="companyPhone" header="Company Phone" className='font-fam-for-all datatable-lg-col webapp-sm-size' />
                <Column field="address" header="Address" className='font-fam-for-all datatable-lg-col webapp-sm-size' /> */}

                {/* {hasPermission("AllowViewAndUpdateCompanies") && <Column body={iconBodyTemplate} header="Action" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>} */}


            </DataTable> : (
                <DataTable
                    value={items}
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    inline
                    paginator
                    emptyMessage="No Candidate Found"
                    selectionMode="single"
                    metaKeySelection={false}
                >
                    <Column body={<Skeleton />} className="datatable-body-icon"></Column>
                </DataTable>
            )}
            {visible && <EditCompanyMember visible={visible} setVisible={setVisible} data={keyPersonalData} />}

        </div>
    )
}

export default KeyPersonnelOrderBOIDataTable;