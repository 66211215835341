import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

// Load the Stripe.js script using your public key
const stripePromise = loadStripe('pk_test_51PyTdiKhsNfb7YuLO8XP7SHE3FUn3rspK3sI7OTbOIulKp9XdM7WtU4gqjxiKVDVBldIKIEBvL8PLjdKkW1h8f1100bw75rZPd');

const Payment = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handlePayment = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    setPaymentProcessing(true);

    try {
      // 1. Create Payment Intent by sending amount and orderId to backend
      const response = await fetch('http://localhost:8000/pay', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          amount: 5000, // Example amount in cents ($50.00)
          currency: 'usd',
          orderId: '123456'  // Example order ID
        })
      });

      const { clientSecret, paymentIntentId } = await response.json();

      // 2. Confirm the payment using clientSecret and card details
      const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
        }
      });

      if (error) {
        // setError(Payment failed: ${error.message});
        setPaymentProcessing(false);
      } else if (paymentIntent.status === 'succeeded') {
        // Payment succeeded! Get transaction ID and other details
        setPaymentSuccess(true);
        console.log('Transaction ID:', paymentIntent.id);
        console.log('Amount:', paymentIntent.amount);
        console.log('Order ID:', paymentIntent.metadata.orderId);
      }
    } catch (err) {
      // setError(Error: ${err.message});
    } finally {
      setPaymentProcessing(false);
    }
  };

  return (
    <div className='w-[500px] h-[400px]'>
      <form onSubmit={handlePayment}>
        <CardElement />
        <button type="submit" disabled={!stripe || paymentProcessing}>
          {paymentProcessing ? 'Processing...' : 'Pay Now'}
        </button>
      </form>
      {paymentSuccess && <p>Payment Successful!</p>}
      {error && <p>{error}</p>}
    </div>


  );
};

export default Payment;