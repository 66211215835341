import React from "react";
import { Modal } from "react-bootstrap";
import CommonTourBlue from "./CommonTourComponent/CommonTourBlue";
import CommonTourWhite from "./CommonTourComponent/CommonTourWhite";
import "./CommonStyleTour/CommonStyleTour.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import pic1 from "../../Assets/Images/tour/c1.png";
import pic2 from "../../Assets/Images/tour/c2.png";
import pic3 from "../../Assets/Images/tour/c3.png";
import pic4 from "../../Assets/Images/tour/c4.png";
import pic5 from "../../Assets/Images/tour/c5.png";
import pic6 from "../../Assets/Images/tour/c6.png";
import pic7 from "../../Assets/Images/tour/c7.png";
import pic8 from "../../Assets/Images/tour/c8.png";
import pic9 from "../../Assets/Images/tour/c9.png";
import pic10 from "../../Assets/Images/tour/c10.png";
import pic11 from "../../Assets/Images/tour/admin_comp_s1.png";

import OrderTourModal from "./OrderTourModal";
import UserTourModal from "./UserTourModal";

const CompanyTourModal = (props) => {
  const [modalsecondShow, setModalSecondShow] = React.useState(false);

  const handleStartClick = () => {
    setModalSecondShow(true);
    props.onHide();
  };
  return (
    <section>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="common-modal-class"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 1 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Companies: Manage Your Companies
                  </h4>
                  <p className="tour-text-holder">
                    This section empowers you to efficiently manage your
                    business entities, providing centralized access to critical
                    company information and updates.
                  </p>
                </div>
                <div>
                  {/* <img loading="lazy" src='https://strapi.redberyltest.in/uploads/admin_comp_s1_401c6619f6.png' className="tour-img" /> */}
                  <img loading="lazy" src={pic11} className="tour-img" />
             
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Company List Overview:{" "}
                      </span>{" "}
                      Displays a list of your registered entities along with key
                      identifiers such as entity type, state of registration,
                      and current status.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Search & Filters: </span>{" "}
                      Use intuitive search and filters to sort by state,
                      business structure, or other parameters for quick
                      navigation.
                    </li>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Permission Based Ownership Transfer{" "}
                      </span>{" "}
                      Complete the ownership transfer request
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 2 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading"> Company Details</h4>
                  <p className="tour-text-holder">
                    Easily manage and update essential details about your legal
                    entity for seamless compliance and record keeping.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={pic2} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt">Entity Overview: </span>{" "}
                      View the entity name, business structure (LLC, Corp,
                      etc.), formation date, service state, and industry type to
                      stay informed about your company status at a glance
                    </li>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Contact Information:{" "}
                      </span>{" "}
                      Quickly access or update email, phone, and website details
                      to ensure smooth communication.
                    </li>
                    <li>
                      <span className="blkkk-textt"> IRS & Accounting: </span>{" "}
                      Keep track of your EIN, entity number, and tax year end
                      for accurate financial and legal compliance
                    </li>
                    <li>
                      <span className="blkkk-textt"> Principal Address: </span>{" "}
                      Review or modify the official address, including street
                      details, city, state, country, and ZIP code to maintain
                      accurate records
                    </li>
                    <li>
                      <span className="blkkk-textt"> Edit Features: </span>{" "}
                      Update outdated details with ease using the edit option to
                      stay compliant with regulations.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 3 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">Companies: Company Details</h4>
                  <p className="tour-text-holder">
                    Effortlessly manage and organize company contacts to ensure
                    effective communication and collaboration.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={pic3} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Add Contacts: </span>{" "}
                      Easily create or manage company contacts by filling in
                      essential details such as name, email, phone number (with
                      country code), and designation for streamlined
                      communication and role assignment.
                    </li>
                    <li>
                      <span className="blkkk-textt"> User Management: </span>{" "}
                      Select existing users from the dropdown or create new ones
                      while assigning specific roles to contacts for improved
                      responsibility tracking and management.
                    </li>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Security and credentials:{" "}
                      </span>{" "}
                      Securely set up and confirm passwords for each contact to
                      ensure privacy, accuracy, and easy account access.
                    </li>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Edit and review feature:{" "}
                      </span>{" "}
                      Update or modify contact details and review assigned roles
                      anytime through a user-friendly interface to maintain
                      clear and accurate records.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 4 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                   Companies: Manage
                    Company Locations
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to manage all locations associated
                    with your company, including offices, branches and
                    affiliates.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={pic4} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Add Location: </span> Add a
                      new location to your company's profile.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Edit Location: </span>{" "}
                      Modify existing location details, such as address, contact
                      information and address type.
                    </li>
                    {/* <li>
                      <span className="blkkk-textt"> Delete Location: </span>{" "}
                      Remove a location from your company's profile.
                    </li> */}
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 5 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                   Companies: Manage Key
                    Personnel
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to manage all key personnel
                    associated with your company, including owners, members,
                    directors, officers and more.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={pic5} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    {/* <li>
                      <span className="blkkk-textt"> Key Actions: </span> Add,
                      edit and remove personnel from your company's records.
                    </li> */}
                    <li>
                      <span className="blkkk-textt">Personnel Types: </span>{" "}
                      Manage various personnel types such as owners, members,
                      directors, officers, registered agents and beneficial
                      owners.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 6 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                   Companies: Manage
                    Company Records
                  </h4>
                  <p className="tour-text-holder">
                    This section provides a central repository for all important
                    documents related to your company.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={pic6} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Add Document: </span>{" "}
                      Upload new documents, such as articles of incorporation,
                      operating agreements, or other legal filings.
                    </li>
                    <li>
                      <span className="blkkk-textt"> View Documents: </span>{" "}
                      Access existing documents stored in the system.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Download Documents: </span>{" "}
                      Download copies of stored documents for your records.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 7 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                   Companies: View and
                    Manage Company Orders
                  </h4>
                  <p className="tour-text-holder">
                    This section provides a central hub for all orders placed
                    for your company, including filings, compliance services,
                    and other requests.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={pic7} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> View Order Details: </span>{" "}
                      Access detailed information about each order, including
                      order number, type, date, status and associated
                      documents.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Track Order Status: </span>{" "}
                      Monitor the progress of your orders in real-time.
                    </li>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Manage Order History:{" "}
                      </span>{" "}
                      View and search through past orders for reference.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 8 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                   Companies: Manage
                    Company Subscriptions
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to view and manage all active
                    subscriptions related to your company.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={pic8} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        View Active Subscriptions:{" "}
                      </span>{" "}
                      See a list of your current subscriptions and their
                      details.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Explore New Plans: </span>{" "}
                      Discover new subscription plans that can benefit your
                      business.
                    </li>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Manage Subscription Renewals:{" "}
                      </span>{" "}
                      Review and manage upcoming subscription renewals.
                    </li>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Cancel Subscriptions:{" "}
                      </span>{" "}
                      Cancel existing subscriptions as needed.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 9 Of 10</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                   Companies: Manage
                    Company Subscriptions
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to view and manage all active
                    subscriptions related to your company.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={pic9} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        View Active Subscriptions:{" "}
                      </span>{" "}
                      See a list of your current subscriptions and their
                      details.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Explore New Plans: </span>{" "}
                      Discover new subscription plans that can benefit your
                      business.
                    </li>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Manage Subscription Renewals:{" "}
                      </span>{" "}
                      Review and manage upcoming subscription renewals.
                    </li>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Cancel Subscriptions:{" "}
                      </span>{" "}
                      Cancel existing subscriptions as needed.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                </div>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 9 Of 9</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                   Companies: Explore
                    Available Services
                  </h4>
                  <p className="tour-text-holder">
                    This section provides an overview of the various services
                    available to support your company's needs.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={pic10} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Browse Services: </span>{" "}
                      Explore a range of services, including company formation,
                      compliance filings, legal document preparation, and more.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Modal.Body>
      </Modal>
      <UserTourModal
        show={modalsecondShow}
        onHide={() => setModalSecondShow(false)}
      />
    </section>
  );
};

export default CompanyTourModal;
