
import axios from 'axios';
import encryption from '../services/CryptoClass';



const axiosInstance = axios.create();


axiosInstance.interceptors.request.use((config) => {
    
    if (config.data.payload) {
        if (config.data.payload.username) {
            config.data.payload.username = encryption.encryptData(config.data.payload.username);
        }
        if (config.data.payload.mobileNo) {
            config.data.payload.mobileNo = encryption.encryptData(config.data.payload.mobileNo);
        }
    }
    return config;
}, (error) => {
    
    return Promise.reject(error);
});

export default axiosInstance;
