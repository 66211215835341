// import React from "react";
// import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
// import { Container } from "react-bootstrap";
// import "../CommonSection/CommonStyle/CommonStyle.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
// import video from "../../../Assets/Video/map video.gif";

// const ServicesBanner = () => {
//   return (
//     <section className="main-section-class-home ">
//       <div className="services-banner">
//         <img src={video} className="video-main-class" />
//         <Container>
//           <div className="main-txtt text-center">
//             <div>
//               <h3 className="headinf-banner">
//                 One-stop business <span className="blue-txtt">formation</span>service
//               </h3>
//             </div>
//             <h3>
//               Register your online business effortlessly in all 50 US states!
//             </h3>
//             <div className="main-class-blue mt-5 mb-5">
//               <a href="/#/signup" target="_blank">
//                 <button className="blue-btnnn">
//                   <span>
//                     Start Your Business{" "}
//                     <FontAwesomeIcon icon={faArrowRightLong} />
//                   </span>
//                 </button>
//               </a>
//             </div>
//           </div>
//         </Container>
//         <div className="overlay"></div>
//       </div>
//     </section>
//   );
// };

// export default ServicesBanner;




import React from "react";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import { Container,} from "react-bootstrap";
import "../CommonSection/CommonStyle/CommonStyle.css";
import BlueButton from "../CommonSection/BlueButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const ServicesBanner = () => {
  return (
    <section className="main-section-class-home ">
      <div className="services-banner mt-5">
        <Container>
          <div className="text-center">
            <CommonHeadingTitle
              heading={"One-stop business"}
              blueheading={"formation"}
            />
            <CommonHeadingTitle heading={"service"} />
            <h3>
              Register your online business effortlessly in all 50 US states!
            </h3>
            {/* <div className="mt-5 mb-5">
              <BlueButton
                label={"Start Your Buisness"}
                onclick={"/signup"}
                icon={<FontAwesomeIcon icon={faArrowRightLong} />}
              />
            </div> */}
            <div className="main-class-blue mt-5 mb-5">
              <a href="/#/signup" target="_blank">
                <button className="blue-btnnn">
                  <span>
                    Start Your Business{" "}
                    <FontAwesomeIcon icon={faArrowRightLong} />
                  </span>
                </button>
              </a>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default ServicesBanner;
