import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";
import imgscr from "../../../../Assets/Images/girl.png";
import AOS from "aos";
import "aos/dist/aos.css";

const services = [
  {
    title: "Avoid Penalties",
    description:
      "By staying compliant, your business avoids fines, penalties, or suspension due to missed deadlines.",
  },
  {
    title: "Legal Protection",
    description:
      "Compliance ensures your business continues to operate within legal requirements, safeguarding it from potential legal action.",
  },
  {
    title: "Increased Credibility",
    description:
      "Maintaining compliance boosts your business's credibility with regulators, clients, and partners to ensure smooth operations.",
  },
  {
    title: "Streamlined Processes",
    description:
      "Professional compliance services simplify required filings, reducing the burden on your team and saving you time.",
  },
];

const ComplienceSec = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="main-section-class" id="learnmore">
      <div className="entity-formation">
        <Container>
          <CommonHeadingTitle
            heading={"Why "}
            blueheading={"compliance matters?"}
          />
          <Row className="mt-5">
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              data-aos="fade-left"
              data-aos-duration="3000"
            >
              <Row>
                {services.map((service, index) => (
                  <Col lg={12} md={12} sm={12} xs={12} key={index}>
                    <div className="main-class-div">
                      <h4 className="medium-level-heading">{service.title}</h4>
                      <div className="grey-border mt-3 mb-3"></div>
                      <p className="normal-common-txt">{service.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              <img loading="lazy" className="user-img-buisness" src={imgscr} />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default ComplienceSec;
