import propertiesApp from "../utils/propertiesApp";

export default function authHeader() {



    const user = JSON.parse(localStorage.getItem('user'));
    // const TENANTID = JSON.parse(localStorage.getItem("X-TenantID"));

    if (user && user.token) {
    //   return { Authorization: 'Bearer ' + user.accessToken ,
      
    // };
    return user.token
    } else {
      return '';
    }
}

export function initAuth() {
    return{
        Authorization: 'Bearer ' + propertiesApp.strapi
    }
}