import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import CommonStepCard from "../CommonSection/CommonStepCard";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  faBook,
  faCircleCheck,
  faMoneyCheckDollar,
  faUserNurse,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

const RegisterStep = () => {
  const steps = [
    {
      stepNumber: 1,
      title: "Create your account",
      description: "Sign up to access your personalized dashboard.",
      icon: faUserPlus,
    },
    {
      stepNumber: 2,
      title: "Fill out our Form",
      description: "Simply complete our user-friendly online form with your details. It's straightforward and takes just a few minutes.",
      icon: faBook,
    },
    {
      stepNumber: 3,
      title: "Choose your services",
      description: "Every business is unique. That's why we provide various packages and services. Explore our compact yet powerful selection of packages and select the option that best fits your needs.",
      icon: faUserNurse,
    },
    {
      stepNumber: 4,
      title: "Make a payment",
      description: "You can use our secure payment gateway with confidence, knowing there are no hidden fees or surprises. What you see is what you'll pay.",
      icon: faMoneyCheckDollar,
    },
    {
      stepNumber: 5,
      title: "Done!",
      description: "Once your company is officially registered, we'll hand over the documents and certificates to you.",
      icon: faCircleCheck,
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="main-section-class" data-aos="fade-left"
    data-aos-duration="3000">
      <Container>
        <CommonHeadingTitle heading={"You can register your business in just a"} />
        <CommonHeadingTitle blueheading={"few minutes"} />
        <Row className="justify-content-center">
          {steps.map((step) => (
            <Col key={step.stepNumber} lg={4} md={6} sm={12} xs={12}>
              <CommonStepCard
                stepNumber={step.stepNumber}
                title={step.title}
                description={step.description}
                icon={step.icon}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default RegisterStep;
