import { Avatar } from "primereact/avatar";
import * as React from "react";

export function TestimonialCard({ quote, content, author, role, image }) {
  return (
    <article className="flex flex-col justify-center p-12 w-full max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col w-full text-center text-slate-700 max-md:max-w-full">
        <h3 className="text-2xl font-medium leading-8 max-md:max-w-full">
          "{quote}"
        </h3>
        <p className="mt-4 w-full text-lg font-light leading-7 max-md:max-w-full">
          {content}
        </p>
      </div>
      <footer className="flex gap-3.5 justify-center items-center mt-6 w-full leading-none max-md:max-w-full">
        <Avatar image={image} style={{width:"100px",height:"50px"}}/>
        {/* <img
          loading="lazy"
          src={image}
          alt={`Portrait of ${author}`}
          className="object-contain shrink-0 self-stretch my-auto w-10 aspect-square rounded-[100px]"
        /> */}
        <div className="flex flex-col self-stretch my-auto">
          <h4 className="text-lg font-semibold text-slate-700 m-0">{author}</h4>
          <p className="mt-1 text-sm font-light text-gray-500">{role}</p>
        </div>
      </footer>
    </article>
  );
}