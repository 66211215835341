import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { setTableData } from "../tableSlice";
import axios from "axios";
import { tableJson } from "../../utils/tableConfig"; 
import authHeader, { initAuth } from "../../services/authHeader";
import { useEffect } from "react";
import encryption from "../../services/CryptoClass";



const fetchTableData = async (tableName) => {
    if (!tableJson[tableName]){
        console.log(`error in ${tableName}`)
    }
  
    const dataToEncrypt = {
      endpoint: tableJson[tableName], 
      payload: null,
      type: "get",
      auth: authHeader(), 
    };
  
    const encryptedData = encryption.encryptData(dataToEncrypt);
    console.log("Encrypted Data:", encryptedData);
  
    try {
      const response = await axios.post("/decrypt", dataToEncrypt, { headers: initAuth() });
      return response.data;
    } catch (error) {
      console.error(`Error fetching ${tableName}:`, error);
      throw error;
    }
  };
  
  const useTableData = (tableName) => {
    const dispatch = useDispatch();
    const tableData = useSelector((state) => state.table[tableName]);
  
    const {
      data,
      isLoading,
      isError,
      error,
      refetch, 
    } = useQuery({
      queryKey: [tableName],
      queryFn: async () => {
        if (tableData) return tableData;
        const data = await fetchTableData(tableName);
        dispatch(setTableData({ tableName, data })); 
        return data;
      },
      staleTime: 14400000, 
      enabled: !!tableJson[tableName], 
      onError: (err) => {
        console.error(`Error fetching ${tableName}:`, err.message);
      },
    });
  
    useEffect(() => {
      if (isError) {
        console.error(`Failed to load ${tableName}:`, error.message);
      }
    }, [isError, error, tableName]);
  
    return { data, isLoading, isError, error, refetch };
  };
  
  export default useTableData;