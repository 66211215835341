import React, { useRef } from "react";
import RegisteredBanner from "./RegisteredBanner";
import RegisteredSecond from "./RegisteredSecond";
import RegisteredStep from "./RegisteredStep";
import OurTrustedSection from "../CommonSection/OurTrustedSection";
import bannerimg from "../../../Assets/Images/Img - creating an llc.png";
import CommonRegister from "../CommonSection/CommonRegister";
import RegisteredFaq from "./RegisteredFaq";
import RegisteredTab from "./RegisteredTab";
import { Container } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import ServiceForm from "../CommonSection/ServiceForm";
import RegisteredAgentService from "./RegisteredAgentService";

const RegistredAgentMain = () => {
  const registeredTabRef = useRef(null);

  const handleScroll = () => {
    if (registeredTabRef.current) {
      registeredTabRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      console.error("registeredTabRef is null");
    }
  };

  return (
    <div>
      <RegisteredBanner
        title="Expert registered agent solutions for legal and "
        highlightText="compliance assurance"
        subtitle=""
        buttonLabel="Learn More"
        onButtonClick={handleScroll} // ✅ Pass as prop
        bannerImage={bannerimg}
      />

      <RegisteredSecond />
      <div ref={registeredTabRef} id="registered-tab-section">
        <RegisteredTab />
      </div>
      <RegisteredAgentService />
      {/* <OurTrustedSection /> */}
      {/* <RegisteredStep /> */}

      <div className="entity-formation">
        <Container>
          <div className="text-center mt-5 mb-5">
            <CommonHeadingTitle heading="Want to know more about our " />
            <CommonHeadingTitle blueheading="registered agent services?" />
          </div>
          <ServiceForm />
        </Container>
      </div>

      <CommonRegister
        title="Ready to get a registered agent?"
        buttonLabel="Start Your Business"
        buttonLink="/signup"
      />
      <RegisteredFaq />
    </div>
  );
};

export default RegistredAgentMain;
