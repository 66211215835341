import React from 'react';

const RegistrationSteps = () => {
  const steps = [
    { title: 'Step 1: Create your account', description: 'Sign up to access your personalized dashboard.', icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/075a788016c6841e06d1a87b4795e46624626d9df77ed9470e46a2c4b86bfe3a?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0' },
    { title: 'Step 2: Fill out our Form', description: 'Simply complete our user-friendly online form with your details. Its straightforward and takes just a few minutes.', icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/0cfcf1a58ff42972beff1d78eeaa21b77330902a4ba1d000f8fcf818adfe18da?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0' },
    { title: 'Step 3: Choose your services', description: 'Every business is unique. Thats why we provide various packages and services. Explore our compact yet powerful selection of packages and select the option that best fits your needs.', icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/28a61c725dd495a9bdba871d6d6814e831f9f1068c1fd44dee1614b7a305b939?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0' },
    { title: 'Step 4: Make a payment', description: 'You can use our secure payment gateway with confidence, knowing there are no hidden fees or surprises. What you see is What youll pay.', icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/62a1a5b2250554cbe50c085ef8ea1498587c084164196b70797802bf7ab8b8e5?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0' },
    { title: 'Step 5: Done!', description: 'Once your company is officially registered, well hand over the documents and certificates to you.', icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/5e7e841ea52d4f1daa754cdec18650bef2025023a081c97bdf89b6a2ff64a5b2?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0' }
  ];

  return (
    <section className="flex flex-col justify-center items-center py-20 pr-96 pl-96 mt-7 w-full text-center bg-white max-w-[1903px] max-md:px-5 max-md:max-w-full">
      <div className="flex justify-center w-full">
        <div className="flex flex-col flex-1 shrink justify-center px-2.5 pt-2.5 pb-2.5 w-full basis-0 min-w-[240px] max-md:max-w-full">
          <h2 className="flex flex-col justify-center pb-5 w-full text-4xl font-semibold text-gray-800 h-[140px] max-md:max-w-full">
            <div className="flex flex-col px-40 w-full max-md:px-5 max-md:max-w-full">
              <div className="pb-px w-full max-md:max-w-full">
                You can register your business in just a<br />
                <span className="text-blue-700">few minutes</span>
              </div>
            </div>
          </h2>
          <div className="flex flex-col justify-center pt-5 w-full min-h-[423px] max-md:max-w-full">
            <div className="flex flex-col items-center w-full min-h-[403px] max-md:max-w-full">
              <div className="flex flex-wrap justify-center w-full max-w-[1140px] max-md:max-w-full">
                {steps.map((step, index) => (
                  <div key={index} className="flex flex-1 shrink justify-center p-2.5  basis-0 ">
                    <div className="flex flex-wrap flex-1 shrink justify-center p-2.5 bg-white rounded basis-0 min-w-[240px] shadow-[0px_0px_10px_rgba(0,0,0,0.5)] size-full">
                      <div className="flex flex-col flex-1 shrink justify-start px-4 py-9 w-full basis-0 min-w-[240px]">
                        <div className="flex flex-col w-full">
                          <img loading="lazy" src={step.icon} alt={`Icon for ${step.title}`} className="object-contain self-center aspect-square w-[50px]" />
                          <div className="flex flex-col mt-6 w-full">
                            <h3 className="px-10 w-full text-2xl font-bold leading-8 text-indigo-600 max-md:px-5">
                              <span className="text-blue-700">{step.title.split(':')[0]}: </span>
                              <span className="font-semibold text-gray-800">{step.title.split(':')[1]}</span>
                            </h3>
                            <p className="px-2 w-full text-lg font-light leading-7 text-gray-800">
                              {step.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegistrationSteps;