import { Avatar } from 'primereact/avatar'
import { Badge } from 'primereact/badge'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const RolesWithPermissionChild = () => {
    const [user] = useSearchParams()
    const history = useNavigate()
    console.log(user)
    const params = Object.fromEntries([...user]);
    console.log(params)

    // Function to convert the string to an array of objects
    // Function to convert the object to an array of objects
    function convertObjectToArray(obj) {
        const permissions = [];
        const temp = {};

        Object.keys(obj).forEach(key => {
            const match = key.match(/permissions\[(\d+)\]\[(.+?)\]/);
            if (match) {
                const index = match[1];
                const prop = match[2];

                if (!temp[index]) {
                    temp[index] = {};
                }
                temp[index][prop] = obj[key];
            }
        });

        // Convert temp object to array
        Object.keys(temp).forEach(index => {
            permissions.push(temp[index]);
        });

        return permissions;
    }
    const temp = convertObjectToArray(params)

    console.log(temp)

    const header =()=>{
        return(
            <>
             <div>
                <div className="flex align-items-center justify-content-end gap-2">

                </div>
                <div className="flex align-items-center justify-content-start gap-5">
                    <span className="p-input-icon-left" >
                        <i className="pi pi-search" />
                        <InputText  placeholder="Keyword Search" className='keyword-search' />
                    </span>


                </div>
            </div>
            </>
        )
    }



    return (
        <div>
            <div className='dashboard-data-header' style={{ marginBottom: "10px", marginTop: "10px" }}>
                <div className='dashboard-data-sub-header' style={{ paddingLeft: "0px", paddingRight: "15px" }}>
                    <div className='sub-header-css1'>
                        <div className='header-content'>
                            <div className='text-header-container'>
                                <button onClick={() => history("/roleswithpermission")}>Back</button>

                                <div className='text-header1'>Users</div>
                                <div className='text-header2'>Manage Your Users Here</div>
                            </div>
                            <div className='text-header-container1'>

                                <div className='action-header-container'>
                                    <Button icon="pi pi-cog" className='header-button'></Button>

                                    <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '20px', marginTop: '10px' }}>
                                        <Badge value="2"></Badge>
                                    </i>
                                </div>
                                <div className='avatar-header-container'>
                                    <Avatar icon="pi pi-user" style={{ backgroundColor: '#9c27b0', color: '#ffffff' }} shape="circle" />
                                </div>

                            </div>

                        </div>
                    </div>


                </div>
            </div>

            <div className='divider-x my-4'></div>

            <div className='m-4'>
                <div className='card p-6'>
                    <div className='grid'>
                        <div className='col-4'>
                            <div>
                                Access Name
                            </div>
                            <div>
                                Description
                            </div>
                        </div>
                        <div className='col-8'>
                            <div>
                                Admin
                            </div>
                            <div>
                                kskdkdkkd
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='m-4'>
                <DataTable value={temp} editMode="row" dataKey="id" header={header}
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} className='datatable-sm  font-fam-for-all'

                    globalFilterFields={['name', 'cityName', 'id',]}   >


                    <Column field="permissionName" header="Permission Name" className='font-fam-for-all permission-datatable datatable-sm-col'
                        sortable ></Column>
                    <Column field="description" header="Description" className='font-fam-for-all permission-datatable datatable-sm-col'
                        sortable ></Column>



                </DataTable>
            </div>
        </div>
    )
}

export default RolesWithPermissionChild