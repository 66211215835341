import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";
import ServiceCommonCard from "../../CommonSection/ServiceCommonCard";
import BlueButton from "../../CommonSection/BlueButton";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";

const ComplianceThird = () => {
  const [showAll, setShowAll] = useState(false);

  const services = [
    {
      title: "Beneficial Ownership (BOI)",
      description:
        "This service ensures compliance with regulations that require businesses to disclose information about individuals who have significant control over or ownership in the company. Properly documenting beneficial ownership helps in transparency and reduces the risk of financial crimes.",
    },
    {
      title: "Annual Report",
      description:
        "An Annual Report is a comprehensive report on a company's activities throughout the preceding year. This document is often required by state law and serves to inform stakeholders about the company's financial performance and operational developments.",
    },
    {
      title: "Reinstatement",
      description:
        "Reinstatement services assist businesses in reinstating their entity status after it has been suspended or forfeited due to non-compliance with state regulations. This often involves filing necessary documents and fees to restore the business to good standing.",
    },
    {
      title: "Biennial Report",
      description:
        "Similar to the Annual Report, the Biennial Report is typically required every two years. It updates state authorities on the company's current business information, ensuring ongoing compliance with state laws.",
    },
    {
      title: "LLC Publication",
      description:
        "Some states require newly formed Limited Liability Companies (LLCs) to publish a notice of their formation in local newspapers. This service handles the publication requirements to ensure compliance with state regulations.",
    },
    {
      title: "Liquor License Publication",
      description:
        "Businesses applying for a liquor license may need to publish a notice of their application in local newspapers as part of the licensing process. This service ensures that the necessary legal notices are published appropriately.",
    },
    {
      title: "Foundation Publication",
      description:
        "This service involves publishing notices related to the formation or existence of a non-profit foundation. It is often required by state law to ensure public awareness and compliance with legal requirements.",
    },
    {
      title: "Sidewalk Cafe Publication",
      description:
        "This is a specialized service for businesses wishing to operate sidewalk cafes, requiring them to publish notices in local media. Compliance with publication requirements is essential for obtaining the necessary permits.",
    },
    {
      title: "DBA Publication",
      description:
        "When a business operates under a name other than its legal name (Doing Business As), many states require that this name be published in local newspapers to notify the public. This service ensures compliance with such regulations.",
    },
    {
      title: "Employer Identification Number (EIN)",
      description:
        "Employer Identification Number (EIN) is a unique identification number that is assigned to a business entity so that it can easily be identified by the Internal Revenue Service (IRS). The Employer Identification Number is commonly used by employers for the purpose of reporting taxes.",
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section data-aos="fade-up" data-aos-duration="3000">
      <Container>
        <CommonHeadingTitle blueheading={"Compliance services"} />
        <div className="text-center mt-5 mb-5">
          <h4 className="subheading">
            Compliance services ensure that your business adheres to regulatory
            requirements, including filing annual reports, reinstating inactive
            entities, and completing publication obligations. Compliance is
            essential to maintain your business's good standing and avoid legal
            or financial penalties.
          </h4>
        </div>
        <Row className="justify-content-center">
          {services
            .slice(0, showAll ? services.length : 3)
            .map((service, index) => (
              <Col key={index} lg={4} md={4} sm={6} xs={12}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <ServiceCommonCard
                    title={service.title}
                    description={service.description}
                  />
                </motion.div>
              </Col>
            ))}
        </Row>
        <div className="text-center mt-4 mb-5">
          <motion.button
            className="btn btn-primary"
            onClick={() => setShowAll(!showAll)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {showAll ? "Read Less" : "Read More"}
          </motion.button>
        </div>
      </Container>
    </section>
  );
};

export default ComplianceThird;
